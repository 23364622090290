import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import CheckOutlined from '@ant-design/icons/CheckOutlined';

import {
  CustomSeparator,
  EmptyNumber,
  Number,
  SecondNumber,
  StepWrapper,
  Title,
  Wrapper,
} from './StepsHeader.styled';

interface StepsHeaderComponentProps {
  isSecondStep: boolean;
  isCustomerOrg: boolean;
}

export const StepsHeaderComponent: FC<StepsHeaderComponentProps> = ({ isSecondStep, isCustomerOrg }) => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <StepWrapper>
        {isSecondStep ? (
          <EmptyNumber>
            <CheckOutlined style={{ color: '#6C79DE' }} />
          </EmptyNumber>
        ) : (
          <Number>1</Number>
        )}
        <Title isSecondStep>
          {t('Booking Details')}
        </Title>
      </StepWrapper>

      {isCustomerOrg ? (
        <>
          <CustomSeparator isSecondStep={isSecondStep} />

          <StepWrapper>
            <SecondNumber isSecondStep={isSecondStep}>2</SecondNumber>
            <Title isSecondStep={isSecondStep}>
              {t('Review and Confirm')}
            </Title>
          </StepWrapper>
        </>
      ) : null}
    </Wrapper>
  );
};
