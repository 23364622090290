import styled from 'styled-components';

import { EmptyScrollbar, divFlex } from 'app-wrapper/view/themes/mixin';
import { Button } from 'app-wrapper/view/components';

export const CIWrapper = styled.div.attrs({
  'data-class': 'CIWrapper',
})`
  ${divFlex}
  width: 100%;
  flex-direction: column;

  background-color: ${({ theme }) => theme?.themesColors?.neutralBranding3};
`;

export const CIWrap = styled.div.attrs({
  'data-class': 'CIWrap',
})`
  ${divFlex}
  ${EmptyScrollbar}

  flex-direction: column;
  width: 100%;

  background-color: ${({ theme }) => theme.themesColors.neutralBranding3};
`;

export const CIContent = styled.div.attrs({
  'data-class': 'CIContent',
})`
  ${divFlex}

  flex-direction: column;
  width: 100%;
  height: 100%;

  color: ${({ theme }) => theme.themesColors.neutralBranding10};
  background-color: ${({ theme }) => theme.themesColors.neutralBranding1};
`;

export const CIContentHeader = styled.div.attrs({
  'data-class': 'CIContentHeader',
})`
  ${divFlex}

  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height:80px;
`;

export const CIIcon = styled.div.attrs({
  'data-class': 'CIIcon',
})`
  ${divFlex}

  align-items: stretch;
  justify-content: stretch;
  width: 160px;
  height: 80px;
  font-size: 14px;

  .ant-upload.ant-upload-drag .anticon-plus {
    color: ${({ theme }) => theme.themesColors.neutralBranding10};
  }

  span:not([role='img']) {
    width: 100%;
    height: 100%;
  };

  p {
    color: ${({ theme }) => theme.themesColors.darkGrayMiddle};
  }

  svg {
    width: 11px;
    height: 11px;
  }
`;

export const CINameBox = styled.div.attrs({
  'data-class': 'CINameBox',
})`
  ${divFlex}

  flex-flow: column nowrap;
  justify-content: center;
  align-items: flex-start;
  height: 100%;
  margin-left: 16px;

  .ant-tooltip-arrow {
    top: -13px;
  }
`;

export const CITypeBox = styled.div.attrs({
  'data-class': 'CITypeBox',
})`
  ${divFlex}
  justify-content: center;
  align-items: center;

  margin-left: auto;
`;

export const CITypeBoxSecond = styled.div.attrs({
  'data-class': 'CITypeBoxSecond',
})`
  ${divFlex}
  flex-direction: column;

  margin-left: 16px;
`;

export const CITypeBoxTitle = styled.div.attrs({
  'data-class': 'CITypeBoxTitle',
})`
  ${divFlex}

  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
  color: ${({ theme }) => theme.themesColors.secondaryDot45};
`;

export const CITypeBoxName = styled.div.attrs({
  'data-class': 'CITypeBoxName',
})`
  ${divFlex}

  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: ${({ theme }) => theme.themesColors.neutralBranding10};
`;

export const CIName = styled.div.attrs({
  'data-class': 'CIName',
})`
  ${divFlex}

  font-size: 20px;
  line-height: 28px;
  font-weight: 500;
`;

export const CIButton = styled(Button).attrs({
  'data-class': 'CIButton',
})`
  margin-left: auto;
  margin-right: 0;
`;

export const CIContentCards = styled.div.attrs({
  'data-class': 'CIContentCards',
})`
  ${divFlex}

  width: 100%;
  height: 100%;

  overflow: hidden;
`;

export const CIContentLeft = styled.div.attrs({
  'data-class': 'CIContentLeft',
})`
  ${divFlex}

  flex-direction: column;
  width: 50%;
  height: 100%;

  overflow: hidden;
  padding: 16px;
  border-right: 1px dashed ${({ theme }) => theme.themesColors.neutralBranding4};
`;

export const CIStatusRow = styled.div`
  display: flex;
  flex-direction: row;
`;

export const CIContentRight = styled.div.attrs({
  'data-class': 'CIContentRight',
})`
  ${divFlex}

  flex-direction: column;
  width: 50%;
  height: 100%;

  overflow: hidden;
  padding: 16px;
`;

export const CIContentDivider = styled.div.attrs({
  'data-class': 'CIContentDivider',
})`
  ${divFlex}
  height: 1px;
  width: 100%;

  border-bottom: 1px solid ${({ theme }) => theme.themesColors.neutralBranding4};
  margin: 16px 0;
`;

export const CIContentDividerDashed = styled.div.attrs({
  'data-class': 'CIContentDividerDashed',
})`
  ${divFlex}
  height: 1px;
  width: 100%;

  border-bottom: 1px dashed ${({ theme }) => theme.themesColors.neutralBranding4};
  margin: 16px 0;
`;
