import styled from 'styled-components';

import { divFlex } from 'app-wrapper/view/themes/mixin';

export const UMCSpanEllipsis = styled.span.attrs({
  'data-class': 'UMCSpanEllipsis',
})`
  max-width: 100%;
  position: relative;
  display: inline;

  text-overflow: ellipsis;
  overflow: clip;
  white-space: nowrap;
`;

export const UMCContentTable = styled.div.attrs({
  'data-class': 'UMCContentTable',
})`
  ${divFlex}
  flex-direction: column
`;

export const UMCContentTableHeader = styled.div.attrs({
  'data-class': 'UMCContentTableHeader',
})`
  ${divFlex}

  height: 28px;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.themesColors.neutralBranding3};
  padding-bottom: 10px;

  > div:not(:first-child) {
    margin-left: 8px;
  }
`;

export const UMCContentTableHeaderItem = styled.div.attrs((props: {
  isCenter?: boolean,
  width?: string,
  paddingLeft?: string,
  marginLeft?: string,
  marginRight?: string,
}) => ({
  isCenter: props.isCenter,
  width: props.width,
  paddingLeft: props.paddingLeft,
  marginLeft: props.marginLeft,
  marginRight: props.marginRight,
  'data-class': 'UMCContentTableHeaderItem',
}))`
  ${divFlex}
  align-items: center;
  justify-content: ${({ isCenter }) => (isCenter ? 'center' : 'left')};

  width: ${({ width }) => (width || 'auto')};
  padding-left: ${({ paddingLeft }) => (paddingLeft || '0')};
  margin-left: ${({ marginLeft }) => (marginLeft || '0')};
  margin-right: ${({ marginRight }) => (marginRight || '0')};

  color: ${({ theme }) => theme.themesColors.secondaryDot45};
  font-size: 12px;
  font-weight: 400;
`;

export const UMCContentTableContentItem = styled.div.attrs({
  'data-class': 'UMCContentTableContentItem',
})`
  ${divFlex}
  flex-direction: column;
  width: 100%;

  border-bottom: 1px solid ${({ theme }) => theme.themesColors.neutralBranding3}
`;

export const UMCContentTableContentItemTop = styled.div.attrs({
  'data-class': 'UMCContentTableContentItemTop',
})`
  ${divFlex}

  height: 55px;

  > div:not(:first-child) {
    margin-left: 8px;
  }

  > div {
    width: 18%;
  }

  > div:nth-child(5) {
    width: 10%;
  }
`;

export const UMCContentTableContentTitle = styled.div.attrs((props: {
  isActive?: boolean,
  isCapitalize?: boolean,
}) => ({
  isActive: props.isActive,
  isCapitalize: props.isCapitalize,
  'data-class': 'UMCContentTableContentTitle',
}))`
  ${divFlex}
  width: 18%;
  align-items: center;
  justify-content: left;

  margin-left: 10px;
  color: ${({ theme }) => theme.themesColors.characterBrandingTitle85};
  color: ${({ theme, isActive }) => (isActive ? theme.themesColors.primaryBranding6 : theme.themesColors.characterBrandingTitle85)};
  font-size: 14px;
  font-weight: 400;

  ${({ isCapitalize }) => isCapitalize && 'text-transform: capitalize'};
`;

export const UMCContentTableCreatedAtItem = styled(UMCContentTableContentTitle).attrs({
  'data-class': 'non-verified-customers-created-at-cell',
})`
  width: 10%;
`;

export const UMCContentTableContentLink = styled.div.attrs({
  'data-class': 'UMCContentTableContentLink',
})`
  ${divFlex}
  width: 18%;
  align-items: center;
  justify-content: left;

  color: ${({ theme }) => theme.themesColors.primaryBranding7};
  font-size: 14px;
  font-weight: 400;

  text-overflow: ellipsis;
  overflow: clip;
  white-space: nowrap;
  cursor: pointer;

  > span:first-letter {
    text-transform: uppercase;
  }
`;
