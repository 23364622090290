import React from 'react';
import { observer } from 'mobx-react-lite';

import { useMobxStore } from 'app-wrapper/mobxStores';
import { UC } from 'shipment-operations/controllers';

import { StepsFormComponent } from './StepsForm.component';

export const StepsForm = observer(() => {
  const { shipmentServices } = useMobxStore();

  return (
    <StepsFormComponent
      documentActivities={shipmentServices.state.documentActivities}
      othersDocumentActivities={shipmentServices.state.othersDocumentActivities}
      inputITNActivity={shipmentServices.getInputITNActivity}
      inputISFActivity={shipmentServices.getInputISFActivity}
      inputMRNActivity={shipmentServices.getInputMRNActivity}
      step={shipmentServices.state.step}
      setStep={UC.shipmentServices.setStep}
      isManager={shipmentServices.state.isManager}
    />
  );
});
