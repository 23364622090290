import {
  IsEnum, IsString, IsDefined, IsNumber, ValidateNested, IsBoolean, IsOptional,
} from 'class-validator';
import { observable } from 'mobx';
import { Type } from 'class-transformer';

import i18n from 'app-wrapper/i18n/i18n';
import { BaseDTM } from 'proto/BaseDTM';
import { EShipmentOrganizationRole } from 'user-management/constants';
import {
  ChargeCodeDesignation,
  DocumentType,
  EServiceActivityType,
  ExportClearanceType,
} from 'shipment-operations/constants';
import { DateDtm } from 'app-wrapper/models/dtm';
import {
  CustomsClearanceDTM,
  ICustomsClearanceDTM,
  ContainerDocumentDTM,
} from 'shipment-operations/models/dtm';
import { EStatusType } from 'app-wrapper/view/guideline';

export interface IAdditionalServiceResponsiblePartyDTM {
  organizationId: number;
  role: EShipmentOrganizationRole;
}

export class AdditionalServiceResponsiblePartyDTM extends BaseDTM<IAdditionalServiceResponsiblePartyDTM> {
  @IsDefined()
  @IsNumber()
  organizationId: number;

  @IsDefined()
  @IsEnum(EShipmentOrganizationRole)
  role: EShipmentOrganizationRole;
}

export interface IAdditionalServiceDocumentActivityDTM {
  type: EServiceActivityType;
  providers: IAdditionalServiceResponsiblePartyDTM[];
  dueDate?: DateDtm;
  isRequired: boolean;
  templateUrl?: string;
  code: DocumentType;
  providedDocuments: ContainerDocumentDTM[];
}

export class AdditionalServiceDocumentActivityDTM extends BaseDTM<IAdditionalServiceDocumentActivityDTM> {
  @IsDefined()
  @IsEnum(EServiceActivityType)
  type: EServiceActivityType;

  @IsDefined()
  @ValidateNested({ each: true })
  @Type(() => AdditionalServiceResponsiblePartyDTM)
  providers: AdditionalServiceResponsiblePartyDTM[];

  @IsOptional()
  @ValidateNested()
  @Type(() => DateDtm)
  dueDate?: DateDtm;

  @IsDefined()
  @IsBoolean()
  isRequired: boolean;

  @IsOptional()
  @IsString()
  templateUrl?: string;

  @IsDefined()
  @IsEnum(DocumentType)
  code: DocumentType;

  @IsDefined()
  @ValidateNested({ each: true })
  @Type(() => ContainerDocumentDTM)
  @observable
  providedDocuments: ContainerDocumentDTM[];

  static calculateIfRequiredActivitiesCompleted = (documentActivities: AdditionalServiceDocumentActivityDTM[]): boolean => {
    const requiredActivities = documentActivities.filter(({ isRequired }) => isRequired);

    return requiredActivities.every((activity) => activity.isCompleted());
  };

  public getProvidedDocument = (): ContainerDocumentDTM | null => this.providedDocuments[0] || null;

  public isCompleted = (): boolean => Boolean(this.providedDocuments.length);
}

export interface IAdditionalServiceInputActivityDTM {
  type: EServiceActivityType;
  providers: IAdditionalServiceResponsiblePartyDTM[];
  dueDate?: DateDtm;
  isRequired: boolean;
  code: ExportClearanceType;
  providedInputs: ICustomsClearanceDTM[];
}

export class AdditionalServiceInputActivityDTM extends BaseDTM<IAdditionalServiceInputActivityDTM> {
  @IsDefined()
  @IsEnum(EServiceActivityType)
  type: EServiceActivityType;

  @IsDefined()
  @ValidateNested({ each: true })
  @Type(() => AdditionalServiceResponsiblePartyDTM)
  providers: AdditionalServiceResponsiblePartyDTM[];

  @IsOptional()
  @ValidateNested()
  @Type(() => DateDtm)
  dueDate?: DateDtm;

  @IsDefined()
  @IsBoolean()
  isRequired: boolean;

  @IsDefined()
  @IsEnum(ExportClearanceType)
  code: ExportClearanceType;

  @IsDefined()
  @ValidateNested({ each: true })
  @Type(() => CustomsClearanceDTM)
  providedInputs: CustomsClearanceDTM[];

  static calculateIfRequiredActivitiesCompleted = (inputActivities: AdditionalServiceInputActivityDTM[]): boolean => {
    const requiredActivities = inputActivities.filter(({ isRequired }) => isRequired);

    return requiredActivities.every((activity) => activity.isCompleted());
  };

  public getProvidedInput = (): CustomsClearanceDTM | null => this.providedInputs[0] || null;

  public getProvidedInputValue = (): string => this.getProvidedInput()?.number || '';

  public isCompleted = (): boolean => Boolean(this.providedInputs.length);
}

export interface IAdditionalServiceDTM {
  id: number;
  quantity?: number;
  code: string;
  name: string;
  info: string;
  manager: IAdditionalServiceResponsiblePartyDTM;
  phase: ChargeCodeDesignation;
  documentActivities: IAdditionalServiceDocumentActivityDTM[];
  inputActivities: IAdditionalServiceInputActivityDTM[];
}

export class AdditionalServiceDTM extends BaseDTM<IAdditionalServiceDTM> {
  @IsDefined()
  @IsNumber()
  id: number;

  @IsOptional()
  @IsNumber()
  quantity?: number;

  @IsDefined()
  @IsString()
  code: string;

  @IsDefined()
  @IsString()
  name: string;

  @IsDefined()
  @IsString()
  info: string;

  @IsDefined()
  @ValidateNested()
  @Type(() => AdditionalServiceResponsiblePartyDTM)
  manager: AdditionalServiceResponsiblePartyDTM;

  @IsDefined()
  @IsEnum(ChargeCodeDesignation)
  phase: ChargeCodeDesignation;

  @IsDefined()
  @ValidateNested({ each: true })
  @Type(() => AdditionalServiceDocumentActivityDTM)
  documentActivities: AdditionalServiceDocumentActivityDTM[];

  @IsDefined()
  @ValidateNested({ each: true })
  @Type(() => AdditionalServiceInputActivityDTM)
  inputActivities: AdditionalServiceInputActivityDTM[];

  public getAreAllActivitiesNotCompleted = (): boolean => {
    const completedActivities = [
      ...this.documentActivities,
      ...this.inputActivities,
    ].filter((activity) => activity.isCompleted());

    return !completedActivities.length;
  }

  public getAreAllActivitiesCompleted = (): boolean => {
    const notCompletedActivities = [
      ...this.documentActivities,
      ...this.inputActivities,
    ].filter((activity) => activity.isRequired && !activity.isCompleted());

    return !notCompletedActivities.length;
  }

  public getServiceStatusInfo = (): { statusText: string; status: EStatusType } => {
    const areAllActivitiesNotCompleted = this.getAreAllActivitiesNotCompleted();

    if (areAllActivitiesNotCompleted) {
      return {
        statusText: i18n.t('Not Started'),
        status: EStatusType.NOT_ACTIVE,
      };
    }

    const areAllActivitiesCompleted = this.getAreAllActivitiesCompleted();

    if (areAllActivitiesCompleted) {
      return {
        statusText: i18n.t('Completed'),
        status: EStatusType.COMPLETED,
      };
    }

    return {
      statusText: i18n.t('In Progress'),
      status: EStatusType.IN_PROGRESS,
    };
  }
}
