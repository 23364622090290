import React, { FC } from 'react';

import {
  Controller,
  FieldPath,
  useFormContext,
} from 'react-hook-form';
import { SelectProps } from 'antd/es/select';

import { useResponsiveSize } from 'app-wrapper/hooks';

import { Select } from './SelectController.styled';

type BaseSelectProps = SelectProps<any>

interface ISelectControllerProps extends BaseSelectProps {
  name: FieldPath<any>;
  nullValue?: any;
}

export const SelectController: FC<ISelectControllerProps> = ({
  name,
  nullValue,
  showSearch,
  onSearch,
  onChange: onChangeCustom,
  onBlur: onBlurCustom,
  ...other
}) => {
  const { control } = useFormContext();
  const size = useResponsiveSize();

  return (
    <Controller
      control={control}
      name={name}
      render={({
        field: {
          ref, value, onChange, onBlur,
        },
      }) => {
        const handleChange: BaseSelectProps['onChange'] = (selectedValue, option) => {
          const newValue = selectedValue === undefined ? nullValue : selectedValue;
          if (onChangeCustom) {
            onChangeCustom(newValue, option);
          }
          onChange(newValue);
        };

        const handleOnSearch = (newValue: string) => {
          if (onSearch) {
            onSearch(newValue);
          }
        };

        const handleBlur: BaseSelectProps['onBlur'] = (event) => {
          if (onBlurCustom) {
            onBlurCustom(event);
          }
          onBlur();
        };

        return (
          <Select
            className="Select"
            ref={ref}
            // @ts-ignore
            name={name}
            value={value}
            onChange={handleChange}
            onBlur={handleBlur}
            showSearch={showSearch}
            onSearch={showSearch ? handleOnSearch : undefined}
            getPopupContainer={(triggerNode) => triggerNode.parentElement || document.body}
            size={size}
            {...other}
          />
        );
      }}
    />
  );
};

export const { Option } = Select;
