import React, {
  FC, memo, useCallback, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import Radio, { RadioChangeEvent } from 'antd/es/radio';

import { VerticalFormItemSvg } from 'app-wrapper/view/icons';
import themesColors from 'app-wrapper/view/themes/themesColors';
import { useOnclickOutsideHook } from 'app-wrapper/hooks';

import {
  IAppFiltersCheckboxGroupDTM, AppFiltersCheckboxGroupDTM,
} from 'app-wrapper/models/dtm';

import {
  FREWrapper,
  FREHeader,
  FREHeaderUpdateDot,
  FREHeaderName,
  FREModal,
  FREModalWrapper,
  FREModalRadio,
  FREModalRadioGroup,
  FREModalRadioGroupItem,
  FREModalButtons,
  FREModalButtonReset,
  FREModalButtonApply,
  FREHeaderArrow,
  FREHeaderNameWrapper,
} from './FilterRadioElement.styled';

interface IFilterRadioElementProps {
  valueGroup: string
  name: string
  group: IAppFiltersCheckboxGroupDTM[]
  disabledReset?: boolean
  onApply: () => void
  onReset?: () => void
  onChangeGroup: (value: AppFiltersCheckboxGroupDTM) => void
}

const FilterRadioElement: FC<IFilterRadioElementProps> = (props) => {
  const {
    valueGroup,
    name,
    group,
    disabledReset,
    onApply,
    onReset,
    onChangeGroup,
  } = props;
  const { t } = useTranslation();
  const [isActive, setIsActive] = useState(false);

  const handleClickOutside = useCallback(
    () => {
      setIsActive((prev) => {
        if (prev) {
          onApply();
        }

        return false;
      });
    },
    [setIsActive, onApply],
  );
  const refWrapper = useOnclickOutsideHook(handleClickOutside);

  const onClickWrapper = useCallback(
    () => setIsActive((prev) => !prev),
    [setIsActive],
  );

  const onChangeGroupHandler = (checkedValues: RadioChangeEvent) => {
    onChangeGroup(AppFiltersCheckboxGroupDTM.fromPlain({
      name: (checkedValues.target as { 'data-name'?: string })?.['data-name'] || '',
      value: checkedValues.target?.value || '',
      checked: checkedValues.target?.checked || false,
    }));
  };

  const onApplyHandler = useCallback(
    () => {
      setIsActive(false);

      onApply();
    },
    [onApply, disabledReset],
  );

  const onResetHandler = useCallback(() => {
    setIsActive(false);

    if (onReset) {
      onReset();
    }
  }, [onReset]);

  return (
    <FREWrapper ref={refWrapper}>
      <FREHeader>
        <FREHeaderNameWrapper
          onClick={onClickWrapper}
        >
          {!disabledReset && (
            <FREHeaderUpdateDot />
          )}
          <FREHeaderName
            isActive={isActive}
          >
            {name}
          </FREHeaderName>
          <FREHeaderArrow
            isActive={isActive}
          >
            <VerticalFormItemSvg
              style={{
                color: isActive ? themesColors?.primaryBranding6 : themesColors?.neutralBranding7,
              }}
              rotate={isActive ? 180 : 0}
            />
          </FREHeaderArrow>
        </FREHeaderNameWrapper>
        {isActive && (
          <FREModal>
            <FREModalWrapper>
              <FREModalRadio>
                <FREModalRadioGroup>
                  <Radio.Group onChange={onChangeGroupHandler} value={valueGroup}>
                    {group.map((checkItem) => (
                      <FREModalRadioGroupItem
                        key={`FREModalRadioGroupItem_${checkItem.id || ''}`}
                        value={checkItem.value}
                        name={checkItem.name}
                        data-name={checkItem.name}
                        checked={checkItem.checked}
                      >
                        {checkItem.name}
                      </FREModalRadioGroupItem>
                    ))}
                  </Radio.Group>
                </FREModalRadioGroup>
              </FREModalRadio>
              {onReset && (
                <FREModalButtons>
                  <FREModalButtonReset
                    onClick={onResetHandler}
                    disabled={disabledReset}
                  >
                    {t('reset')}
                  </FREModalButtonReset>
                  <FREModalButtonApply
                    onClick={onApplyHandler}
                  >
                    {t('apply')}
                  </FREModalButtonApply>
                </FREModalButtons>
              )}
            </FREModalWrapper>
          </FREModal>
        )}
      </FREHeader>
    </FREWrapper>
  );
};

const FilterRadioElementCached = memo(FilterRadioElement);

export { FilterRadioElementCached as FilterRadioElement };
