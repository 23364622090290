import AntLayout from 'antd/es/layout';
import styled from 'styled-components';

import { colors } from 'app-wrapper/view/themes/themesColors';

export const ShipmentContainersScaffoldLayout = styled(AntLayout).attrs({
  'data-class': 'ShipmentContainersScaffoldLayout',
})`
  display: flex;
  flex-direction: column;
  gap: 0;
  height: calc(100vh - 48px - 84px);
  background-color: ${colors.gray35};
`;

export const ShipmentContainersScaffoldContent = styled(AntLayout.Content).attrs({
  'data-class': 'ShipmentContainersScaffoldContent',
})`
  display: flex;
  flex-flow: column nowrap;
  justify-content: stretch;
  width: 100%;
  height: 100%;
`;

interface IShipmentContainersScaffoldBodyProps {
  isHeaderBig?: boolean
}

export const ShipmentContainersScaffoldBody = styled.div.attrs({
  'data-class': 'ShipmentContainersScaffoldBody',
})<IShipmentContainersScaffoldBodyProps>`
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 8px;
  padding-top: 8px;
  height: ${({ isHeaderBig }) => (isHeaderBig ? 'calc(100% - 100px)' : 'calc(100% - 50px)')};

  color: inherit;
  background-color: inherit;
`;
