import styled from 'styled-components';
import Typography from 'antd/es/typography';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 489px;
`;

export const Title = styled(Typography)`
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
`;

export const Text = styled(Typography)`
  margin-top: 8px;
  font-size: 14px;
  line-height: 22px;
  color: rgba(115, 129, 155, 1);
`;

export const Image = styled.img`
  width: 208px;
  height: auto;
  margin-left: auto;
`;
