import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 16px;
  background-color: white;
  border: 1px solid #EAEEF8;
  border-radius: 3px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

export const DateWrapper = styled.div`
  width: 24%;
  display: flex;
  flex-direction: column;
`;

export const Label = styled.span`
  font-size: 12px;
  color: #202C3C;
`;
