import { fetchConfig } from './fetchConfig';

export const addPrefix = (url: string) => {
  const prefix = fetchConfig.prefix ? `/${fetchConfig.prefix}` : '';
  return new URL(prefix + url, fetchConfig.host).toString();
};

export const getBaseUrl = () => {
  const prefix = fetchConfig.prefix ? `/${fetchConfig.prefix}` : '';
  return new URL(prefix, fetchConfig.host).toString();
};
