import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { MissingTextValue } from 'app-wrapper/view/guideline';

import {
  SBLCarrierInformation,
  SBLCarrierTitle,
  SBLCarrierRow,
  SBLCarrierItem,
  SBLCarrierItemTitle,
  SBLCarrierItemSubTitle,
} from './SBLCarrierInformation.styled';

interface ISBLCarrierInformationComponentProps {
  items: Array<{
    title: string;
    subtitle?: string;
  }>;
}

export const SBLCarrierInformationComponent: FC<ISBLCarrierInformationComponentProps> = ({ items }) => {
  const { t } = useTranslation();

  return (
    <SBLCarrierInformation>
      <SBLCarrierTitle>
        {t('Carrier')}
      </SBLCarrierTitle>

      <SBLCarrierRow>
        {items.map(({ title, subtitle }) => (
          <SBLCarrierItem
            key={title}
            style={{
              width: `${100 / items.length}%`,
            }}
          >
            <SBLCarrierItemTitle>
              {title}
            </SBLCarrierItemTitle>

            <SBLCarrierItemSubTitle>
              {subtitle || <MissingTextValue />}
            </SBLCarrierItemSubTitle>
          </SBLCarrierItem>
        ))}
      </SBLCarrierRow>
    </SBLCarrierInformation>
  );
};
