import styled from 'styled-components';

import { colors } from 'app-wrapper/view/themes/themesColors';

export const ShippingPartiesCardContainer = styled.div`
  height: max-content;
  padding: 16px;

  background: #FFFFFF;
  border: 1px solid ${colors.purple15};
  border-radius: 3px;
`;
