import { BadRequestError, ConflictError } from 'app-wrapper/models/errors';
import i18n from 'i18next';
import { BaseController, controller } from 'proto/BaseController';
import moment from 'moment/moment';
import { Moment } from 'moment';

import {
  BookingWizardContainersErrorsDTM,
  CargoBaseDTM,
  CargoDTM,
  CargoErrorsDTM,
  CommodityDTM,
  ContainerDocumentDTM,
  HazmatDTM,
  FullCompanyDTM,
  TemperatureControlDTM,
  ShippingPartyDTM,
  ShipmentConfirmationDTM,
  ShipmentAccountStatsCustomer, ShippingPartyReference,
  ShipmentReferenceDTM,
} from 'shipment-operations/models/dtm';
import { R } from 'shipment-operations/repository';
import { UC } from 'shipment-operations/controllers';

import { R as userManagementR } from 'user-management/repository';

import { validateDecimalFraction } from 'app-wrapper/utils';
import { RouteNames } from 'app-wrapper/constants';
import { ValidationErrorDTM, ValidationErrorType } from 'app-wrapper/types';

import { validateRequiredField } from 'shipment-operations/controllers/CargoController/validateRequiredField';
import { validateReferences } from 'shipment-operations/controllers/CargoController/validateReferences';
import { validateHazmat } from 'shipment-operations/controllers/CargoController/validateHazmat';
import { DateDtm } from 'app-wrapper/models/dtm';
import {
  EShipmentConfirmationTypes, EShippingPartyTypes, OrganizationPaymentMethod, PAYABLES,
} from 'shipment-operations/constants';
import { v4 as uuidv4 } from 'uuid';
import { AdditionalServiceCheckedDTM } from 'monetary/models/dtm/Quotas';
import { AdditionalDrawersUseCase } from 'monetary/usecases/AdditionalDrawers.useCase';

const FORBIDDEN_HS_CODE = '000000';

@controller
export class BookingWizardController extends BaseController {
  public getCargos = async (shipmentId: string) => {
    const hasTemperatureControl = R.selectors.bookingWizard.getHasTemperatureControl(this.store.getState());
    let cargos: CargoDTM[] = [];
    const alreadyHasCargos = !!R.selectors.bookingWizard.getCargos(this.store.getState()).length;

    cargos = await R.services.cargo.getCargos(+shipmentId);

    cargos = cargos.map((cargo) => ({
      ...cargo,
      code: cargo.code !== FORBIDDEN_HS_CODE ? cargo.code : undefined,
      packageType: alreadyHasCargos ? cargo.packageType : undefined,
    }));

    if (hasTemperatureControl) {
      cargos = cargos.map((cargo) => ({
        ...cargo,
        hsValidationStatus: 'REQUEST_SENT_AND_VALID',
      }));

      const defaultCargo = R.selectors.bookingWizard.getDefaultCargo(this.store.getState());
      const { code, name } = cargos[0];

      this.dispatch(R.actions.bookingWizard.setDefaultCargo(CargoBaseDTM.fromPlain({
        ...defaultCargo,
        code,
        name,
      })));
    }

    if (!cargos.length) {
      this.dispatch(R.actions.bookingWizard.addCargo());
    }

    if (cargos.length) {
      this.dispatch(R.actions.bookingWizard.setCargos(cargos));
    }

    const cargosInStore = R.selectors.bookingWizard.getCargos(this.store.getState());
    const commodityPromises: Promise<CommodityDTM[]>[] = [];

    cargosInStore.forEach(({ code }) => {
      if (!code) {
        return;
      }

      commodityPromises.push(R.services.commodity.getCommodities(code,
        {
          query: code,
          size: 10,
        }));
    });

    const commoditySearchResults = await Promise.all(commodityPromises);

    const commodities = commoditySearchResults.map((commodityList, i) => {
      const commodity = commodityList.find((_commodity) => _commodity.code === cargosInStore[i].code)!;
      if (commodity && commodity.code !== FORBIDDEN_HS_CODE) {
        this.dispatch(R.actions.bookingWizard.setHsCode({
          code: commodity.code,
          name: commodity.name,
          errorsCode: validateRequiredField(commodity.code),
          errorsName: validateRequiredField(commodity.name),
          cargoId: Number(cargosInStore[i]?.id),
        }));
      }
      return commodity;
    }).filter((v) => !!v);

    let temperatureControl: TemperatureControlDTM | null = null;

    if (hasTemperatureControl) {
      temperatureControl = await R.services.temperatureControl.getTemperatureControlData(shipmentId);
    }

    if (temperatureControl) {
      this.dispatch(R.actions.temperatureControl.setTemperatureControlData(temperatureControl));
    }

    this.dispatch(R.actions.commodity.setCommodities(commodities));
  }

  public init = async (shipmentId: string) => {
    this.dispatch(R.actions.bookingWizard.setIsLoading(true));
    this.dispatch(R.actions.shipmentDocumentsAll.setQuoteIndex(undefined));

    const shipment = await R.services.shipment.getShipmentShortById(+shipmentId);

    if (shipment) {
      this.dispatch(R.actions.bookingWizard.setShipmentData(shipment));
      if (shipment.earliestEmptyReleaseDate) {
        this.dispatch(R.actions.bookingWizard.setEmptyReleaseDate(DateDtm.fromPlain({
          date: shipment.earliestEmptyReleaseDate,
          offset: moment.parseZone(shipment.earliestEmptyReleaseDate).utcOffset(),
        })));
      }
      this.dispatch(R.actions.bookingWizard.setHasTemperatureControl(shipment.hasReeferContainer));
      this.dispatch(R.actions.bookingWizard.setHasSOC(shipment.hasSelfOwnedContainer));
      this.dispatch(R.actions.bookingWizard.setContainersAmount(shipment.containerCount));
    }

    if (shipment && shipment.hasSelfOwnedContainer) {
      const containers = await R.services.shipmentContainers.getContainersList(shipmentId);
      this.dispatch(R.actions.bookingWizard.setContainers(containers));
    }

    await this.getCargos(shipmentId);
    const cargosInStore = R.selectors.bookingWizard.getCargos(this.store.getState());
    const hasAnyHazmats = cargosInStore.some(({ isHazmat }) => isHazmat);
    this.dispatch(R.actions.bookingWizard.setShouldHaveHazmats(hasAnyHazmats));

    const currentOrganization = userManagementR.selectors.userOrganizationData.getUserOrganization(this.store.getState());
    if (currentOrganization) {
      this.dispatch(R.actions.bookingWizard.setCurrentOrganization(currentOrganization));
    }

    const companiesList = await R.services.contacts.getCompanyList();
    this.dispatch(R.actions.bookingWizard.setCompaniesList(companiesList));

    let accountStats: ShipmentAccountStatsCustomer | undefined;
    this.dispatch(R.actions.bookingWizard.setIsHaveAccountLimit(false));

    const organization = await userManagementR.services.organization.getCurrentOrganization();

    if (organization?.paymentMethod?.type === OrganizationPaymentMethod.PREPAYMENT) {
      this.dispatch(R.actions.bookingWizard.setIsShowAccountLimit(true));
      this.dispatch(R.actions.bookingWizard.setIsHaveAccountLimit(false));
    }

    if (organization?.paymentMethod?.type === OrganizationPaymentMethod.DEFERRED_PAYMENT) {
      accountStats = await R.services.shipment.getOrganizationAccountStats(PAYABLES);
      const accountBalance = accountStats?.accountBalance < 0 ? (-1 * accountStats?.accountBalance) : accountStats?.accountBalance;

      if (accountBalance && organization?.paymentMethod?.creditLimit && accountBalance > organization?.paymentMethod?.creditLimit) {
        this.dispatch(R.actions.bookingWizard.setIsShowAccountLimit(true));
        this.dispatch(R.actions.bookingWizard.setIsHaveAccountLimit(true));
      }
    }

    this.dispatch(R.actions.bookingWizard.setIsLoading(false));
  }

  public setEmptyReleaseDate = (releaseDate: Moment | null) => {
    this.dispatch(R.actions.bookingWizard.setEmptyReleaseDateError(''));

    if (!releaseDate) {
      this.dispatch(R.actions.bookingWizard.setEmptyReleaseDate(null));

      return;
    }

    this.dispatch(R.actions.bookingWizard.setEmptyReleaseDate(DateDtm.fromPlain({
      date: releaseDate?.format(),
      offset: moment.parseZone(releaseDate).utcOffset(),
    })));
  }

  public setHsCode = async (shipmentIdProps: number, hsCode: CargoDTM['code'], cargoId: number) => {
    const shipmentId = R.selectors.bookingWizard.getShipmentId(this.store.getState());

    const { commodities } = R.selectors.commodity.getCommodity(this.store.getState());
    let code = hsCode;
    let name;

    const commodity = commodities.find((item) => item.code === hsCode);
    if (commodity) {
      code = commodity.code;
      name = commodity.name;
    }

    this.dispatch(R.actions.bookingWizard.setHsCodeValidationStatus({
      status: 'REQUEST_NOT_SENT',
      cargoId,
    }));

    this.dispatch(R.actions.bookingWizard.setHsCode({
      code,
      name,
      errorsCode: validateRequiredField(code),
      errorsName: validateRequiredField(name),
      cargoId,
    }));

    const temperatureControl = R.selectors.bookingWizard.getHasTemperatureControl(this.store.getState());

    if (temperatureControl) {
      return;
    }

    if (!code) {
      return;
    }

    try {
      await R.services.hsValidation.getHsGroup(+shipmentId, code);
    } catch (error: unknown) {
      if (error instanceof BadRequestError || error instanceof ConflictError) {
        this.dispatch(R.actions.bookingWizard.setHsCodeError({
          cargoId,
          code: ValidationErrorDTM.fromPlain({
            type: ValidationErrorType.ALERT,
            message: error.getErrorMessage(),
          }),
        }));
      }

      this.dispatch(R.actions.bookingWizard.setHsCodeValidationStatus({
        status: 'REQUEST_SENT_AND_ERROR',
        cargoId,
      }));

      throw error;
    }

    this.dispatch(R.actions.bookingWizard.setHsCodeValidationStatus({
      status: 'REQUEST_SENT_AND_VALID',
      cargoId,
    }));
  };

  public touchCargoField = (fieldName: string, cargoId: number) => {
    this.dispatch(R.actions.bookingWizard.touchCargoField({
      field: fieldName,
      cargoId,
    }));
  }

  public setDescription = (description: string, cargoId: number) => {
    this.dispatch(R.actions.bookingWizard.setDescription({
      description: description?.slice(0, 512),
      cargoId,
    }));
  };

  public setPackageType = (packageType: CargoDTM['packageType'], cargoId: number) => {
    this.dispatch(R.actions.bookingWizard.setPackageType({
      packageType,
      cargoId,
      error: validateRequiredField(packageType),
    }));
  };

  public setPackagesNumber = (packagesNumber: CargoDTM['packagesNumber'], cargoId: number) => {
    const slicedPackagesNumber = packagesNumber ? (
      packagesNumber
        .replace(/^0+/, '')
        .replace(/[^0-9]/g, '')
        .slice(0, 6)
    ) : undefined;

    this.dispatch(R.actions.bookingWizard.setPackagesNumber({
      packagesNumber: slicedPackagesNumber,
      error: validateRequiredField(slicedPackagesNumber),
      cargoId,
    }));
  };

  public setWeight = (weight: CargoDTM['weight'], cargoId: number) => {
    const slicedWeight = weight ? validateDecimalFraction(weight, 11, 3, {
      withoutZero: true,
      integerMaxLength: 7,
    }) : undefined;

    this.dispatch(R.actions.bookingWizard.setWeight({
      weight: slicedWeight,
      error: validateRequiredField(weight),
      cargoId,
    }));
  };

  public setVolume = (volume: CargoDTM['volume'], cargoId: number) => {
    const slicedVolume = volume ? validateDecimalFraction(volume, 8, 3, {
      withoutZero: true,
      integerMaxLength: 4,
    }) : undefined;

    this.dispatch(R.actions.bookingWizard.setVolume({
      volume: slicedVolume || '',
      cargoId,
    }));
  };

  public removeCargo = (cargoId: number) => {
    this.dispatch(R.actions.bookingWizard.removeCargoById(cargoId));
    this.dispatch(R.actions.bookingWizard.addCargosToRemoveId(String(cargoId)));
  }

  public addNewCargo = () => {
    const cargos = R.selectors.bookingWizard.getCargos(this.store.getState());
    const [initialCargo] = cargos;

    const hasTemperatureControl = R.selectors.bookingWizard.getHasTemperatureControl(this.store.getState());

    if (hasTemperatureControl && initialCargo && initialCargo.code) {
      this.dispatch(R.actions.bookingWizard.addCargo(initialCargo.code));
      return;
    }

    this.dispatch(R.actions.bookingWizard.addCargo());
  }

  public openHazmatSectionByCargoId = (cargoId: number) => {
    this.dispatch(R.actions.bookingWizard.addToggledHazmatCargoId(cargoId));
  }

  public closeHazmatSectionByCargoId = (cargoId: number) => {
    this.dispatch(R.actions.bookingWizard.removeToggledHazmatCargoId(cargoId));
  }

  public setImoClass = (imoClass: CargoDTM['imoClass'], cargoId: number) => {
    this.dispatch(R.actions.bookingWizard.setImoClass({
      imoClass,
      cargoId,
    }));

    const isCargoForcedToBeHazmat = R.selectors.bookingWizard.getIsCargoForcedToBeHazmat(this.store.getState());

    this.updateHazmatStatus(cargoId, isCargoForcedToBeHazmat);
    this.updateHazmatErrors(cargoId);
    this.checkCargoHazmatEmptynessCriteria();
  }

  public setUnNumber = (unNumber: CargoDTM['unNumber'], cargoId: number) => {
    const slicedUnNumber = unNumber?.replace(/\D/g, '').slice(0, 4);
    this.dispatch(R.actions.bookingWizard.setUnNumber({
      unNumber: slicedUnNumber,
      cargoId,
    }));

    const isCargoForcedToBeHazmat = R.selectors.bookingWizard.getIsCargoForcedToBeHazmat(this.store.getState());

    this.updateHazmatStatus(cargoId, isCargoForcedToBeHazmat);
    this.updateHazmatErrors(cargoId);
    this.checkCargoHazmatEmptynessCriteria();
  }

  public setPackingGroup = (packingGroup: CargoDTM['packingGroup'], cargoId: number) => {
    this.dispatch(R.actions.bookingWizard.setPackingGroup({
      packingGroup,
      cargoId,
    }));

    const isCargoForcedToBeHazmat = R.selectors.bookingWizard.getIsCargoForcedToBeHazmat(this.store.getState());

    this.updateHazmatStatus(cargoId, isCargoForcedToBeHazmat);
    this.updateHazmatErrors(cargoId);
    this.checkCargoHazmatEmptynessCriteria();
  }

  public setShippingName = (shippingName: CargoDTM['shippingName'], cargoId: number) => {
    const slicedShippingName = shippingName?.slice(0, 90);
    this.dispatch(R.actions.bookingWizard.setShippingName({
      shippingName: slicedShippingName,
      cargoId,
    }));

    const isCargoForcedToBeHazmat = R.selectors.bookingWizard.getIsCargoForcedToBeHazmat(this.store.getState());

    this.updateHazmatStatus(cargoId, isCargoForcedToBeHazmat);
    this.updateHazmatErrors(cargoId);
    this.checkCargoHazmatEmptynessCriteria();
  }

  public setShipperCompany = (companyId: number) => {
    const companiesList = R.selectors.bookingWizard.getCompaniesList(this.store.getState());
    const company = companiesList.find(({ id }) => id === companyId);

    this.dispatch(R.actions.bookingWizard.setSelectedCompany(company || null));
    this.dispatch(R.actions.bookingWizard.setCompanyError(false));
  }

  public setConsigneeCompany = (companyId: number) => {
    const companiesList = R.selectors.bookingWizard.getCompaniesList(this.store.getState());
    const company = companiesList.find(({ id }) => id === companyId);

    this.dispatch(R.actions.bookingWizard.setSelectedConsigneeCompany(company || null));
    this.dispatch(R.actions.bookingWizard.setConsigneeCompanyError(false));
  }

  public onChangeShippingPartyReference = (value: string) => {
    const validValue = value
      .replace(/[^\w]/g, '')
      .slice(0, 16);

    this.dispatch(R.actions.bookingWizard.setShipmentPartyReference(validValue));

    this.onValidateShippingPartyReference();
  }

  public onValidateShippingPartyReference = () => {
    const reference = R.selectors.bookingWizard.getShipmentReference(this.store.getState());

    if (reference) {
      this.dispatch(R.actions.bookingWizard.setShipmentPartyReferenceError(false));
    } else {
      this.dispatch(R.actions.bookingWizard.setShipmentPartyReferenceError(true));
    }
  }

  public onChangeConsigneeReference = (value: string) => {
    const validValue = value
      .replace(/[^\w]/g, '')
      .slice(0, 16);

    this.dispatch(R.actions.bookingWizard.setShipmentConsigneeReference(validValue));

    this.onValidateConsigneeReference();
  }

  public onValidateConsigneeReference = () => {
    const reference = R.selectors.bookingWizard.getShipmentConsigneeReference(this.store.getState());

    if (reference) {
      this.dispatch(R.actions.bookingWizard.setShipmentConsigneeReferenceError(false));
    } else {
      this.dispatch(R.actions.bookingWizard.setShipmentConsigneeReferenceError(true));
    }
  }

  public onChangeReference = (value: string) => {
    const validValue = value
      .replace(/[^\w]/g, '')
      .slice(0, 16);

    this.dispatch(R.actions.bookingWizard.setShipmentReference(validValue));

    this.onValidateReference();
  }

  public onValidateReference = () => {
    const reference = R.selectors.bookingWizard.getShipmentReference(this.store.getState());

    if (reference) {
      this.dispatch(R.actions.bookingWizard.setShipmentReferenceError(false));
    } else {
      this.dispatch(R.actions.bookingWizard.setShipmentReferenceError(true));
    }
  }

  public downloadContainerDocument = (containerId: string) => {
    const shipmentId = R.selectors.bookingWizard.getShipmentId(this.store.getState());
    const { seaworthyCertificate } = R.selectors.bookingWizard.getContainerById(containerId)(this.store.getState());

    if (!seaworthyCertificate) {
      return;
    }

    R.services.shipmentDocument.getShipmentDocument(+shipmentId, seaworthyCertificate.response.id, seaworthyCertificate.response.name);
  }

  public downloadMsdsDocument = (shipmentIdProps: number, cargoId: number) => {
    const shipmentId = R.selectors.bookingWizard.getShipmentId(this.store.getState());
    const cargos = R.selectors.bookingWizard.getCargos(this.store.getState());
    const cargo = cargos.find(({ id }) => id === cargoId);

    if (!cargo) {
      return;
    }

    const { msdsDocument } = cargo;

    if (msdsDocument[0].status !== 'done') {
      return;
    }

    const documentId = msdsDocument[0].response.id;
    const documentName = msdsDocument[0].response.name;

    R.services.shipmentDocument.getShipmentDocument(+shipmentId, documentId, documentName);
  };

  public setMsdsDocument = (msdsDocument: CargoDTM['msdsDocument'], cargoId: number) => {
    this.dispatch(R.actions.bookingWizard.setMsdsDocument({
      msdsDocument,
      cargoId,
    }));

    const isCargoForcedToBeHazmat = R.selectors.bookingWizard.getIsCargoForcedToBeHazmat(this.store.getState());

    this.updateHazmatStatus(cargoId, isCargoForcedToBeHazmat);
    this.updateHazmatErrors(cargoId);
    this.checkCargoHazmatEmptynessCriteria();
  }

  public setContainerDocument = (document: ContainerDocumentDTM | null, containerId: string) => {
    this.dispatch(R.actions.bookingWizard.setContainerDocument({
      document,
      containerId,
    }));

    this.validateContainer(containerId);
  }

  public goSecondStep = async () => {
    this.dispatch(R.actions.bookingWizard.setIsLoading(true));
    this.dispatch(R.actions.bookingWizard.setIsContentUpdating(true));
    const shipmentId = R.selectors.bookingWizard.getShipmentId(this.store.getState());
    const temperatureControl = R.selectors.temperatureControl.getTemperatureControl(this.store.getState());
    const hasTemperatureControl = R.selectors.bookingWizard.getHasTemperatureControl(this.store.getState());
    const hasSOC = R.selectors.bookingWizard.getHasSOC(this.store.getState());
    const emptyReleaseDate = R.selectors.bookingWizard.getEmptyReleaseDate(this.store.getState());
    const shouldShowShipperInformation = R.selectors.bookingWizard.getShouldShowShipperSection(this.store.getState());
    const shouldShowConsigneeInformation = R.selectors.bookingWizard.getShouldShowConsigneeSection(this.store.getState());
    const reference = R.selectors.bookingWizard.getShipmentReference(this.store.getState());

    this.validateCargosRequiredFields();
    this.validateCargosHazmat();
    this.validateShipperOrConsigneeInformation();

    if (hasSOC) {
      this.validateContainers();
    }

    if (hasTemperatureControl) {
      UC.temperatureControl.updateTemperatureErrors();
      this.dispatch(R.actions.temperatureControl.setUpdateAttemptStatus(true));
    }

    const hasErrorsAtAnyCargo = R.selectors.bookingWizard.hasErrorsAtAnyCargo(this.store.getState());
    const hasEmptyReleaseDateError = !!R.selectors.bookingWizard.getEmptyReleaseDateError(this.store.getState());
    const isHazmatErrorVisible = R.selectors.bookingWizard.getIsHazmatErrorVisible(this.store.getState());
    const hasErrorsAtAnyContainers = R.selectors.bookingWizard.hasErrorsAtAnyContainers(this.store.getState());
    const temperatureControlErrors = R.selectors.temperatureControl.getTemperatureControlErrors(this.store.getState());
    const shipperError = R.selectors.bookingWizard.getCompanyError(this.store.getState());
    const hasTemperatureErrors = temperatureControlErrors.temperature || temperatureControlErrors.flowRate || temperatureControlErrors.flowRateUnit;

    if (!hasErrorsAtAnyCargo
      && !hasEmptyReleaseDateError
      && !shipperError
      && !isHazmatErrorVisible
      && (!hasTemperatureControl || (hasTemperatureControl && !hasTemperatureErrors))
      && (!hasSOC || (hasSOC && !hasErrorsAtAnyContainers))
    ) {
      this.updateAdditionalServices();

      await this.updateAllCargos(+shipmentId);

      if (hasSOC) {
        await this.updateAllContainers(shipmentId);
      }

      if (hasTemperatureControl) {
        await this.updateTemperatureControl(temperatureControl);
      }

      if (reference) {
        await this.saveShipmentReference();
      }

      if (shouldShowShipperInformation || shouldShowConsigneeInformation) {
        await this.saveShipperOrConsigneeCompanyInformation();
      }

      if (emptyReleaseDate) {
        try {
          await R.services.shipment.updateEmptyReleaseDate(shipmentId, emptyReleaseDate.format());
        } catch (e) {
          const error = e as Error;

          this.dispatch(R.actions.bookingWizard.setEmptyReleaseDateError(error.message));
          this.dispatch(R.actions.bookingWizard.setWasInformationSavedOnce(true));
          this.dispatch(R.actions.bookingWizard.setIsContentUpdating(false));
          this.dispatch(R.actions.bookingWizard.setIsLoading(false));
          return;
        }
      }

      const isCustomerOrg = R.selectors.bookingWizard.getIsCurrentOrganizationCustomer(this.store.getState());

      if (isCustomerOrg) {
        this.dispatch(R.actions.bookingWizard.setStep(2));
        this.dispatch(R.actions.bookingWizard.setWasInformationSavedOnce(true));
      } else {
        await this.agreeAndBook();
      }
    }

    this.dispatch(R.actions.bookingWizard.setIsLoading(false));
    this.dispatch(R.actions.bookingWizard.setIsContentUpdating(false));
  }

  public validateShipperOrConsigneeInformation = () => {
    const shouldShowShipperSection = R.selectors.bookingWizard.getShouldShowShipperSection(this.store.getState());
    const shouldShowConsigneeSection = R.selectors.bookingWizard.getShouldShowConsigneeSection(this.store.getState());
    const shipperOrConsigneeCompany = R.selectors.bookingWizard.getSelectedCompany(this.store.getState());

    if ((shouldShowConsigneeSection || shouldShowShipperSection) && !shipperOrConsigneeCompany) {
      this.dispatch(R.actions.bookingWizard.setCompanyError(true));
    }
  };

  public saveShipperOrConsigneeCompanyInformation = async () => {
    const shipperCompany = R.selectors.bookingWizard.getSelectedCompany(this.store.getState());
    const selectedConsigneeCompany = R.selectors.bookingWizard.getSelectedConsigneeCompany(this.store.getState());
    const shipmentPartyReference = R.selectors.bookingWizard.getShipmentPartyReference(this.store.getState());
    const shipmentConsigneeReference = R.selectors.bookingWizard.getShipmentConsigneeReference(this.store.getState());

    if (!shipperCompany) {
      return;
    }

    let fullCompaniesList: FullCompanyDTM[] = [];
    let newShippingParty: ShippingPartyDTM | null = null;

    fullCompaniesList = await R.services.contacts.getFullCompanyList();

    if (fullCompaniesList) {
      if (shipperCompany) {
        newShippingParty = await this.saveShippingParty(shipperCompany.id, EShippingPartyTypes.HOUSE_SHIPPER, shipmentPartyReference, fullCompaniesList);
      }

      if (selectedConsigneeCompany) {
        newShippingParty = await this.saveShippingParty(selectedConsigneeCompany.id, EShippingPartyTypes.HOUSE_CONSIGNEE, shipmentConsigneeReference, fullCompaniesList);
      }

      if (newShippingParty?.id) {
        this.dispatch(R.actions.bookingWizard.setNewBookPartiesId(newShippingParty.id));
      }
    }
  };

  private saveShippingParty = async (shipperCompanyId: number, role: EShippingPartyTypes, shipmentPartyReference: string | undefined, fullCompaniesList: FullCompanyDTM[]) => {
    const fullCompany = fullCompaniesList.find((company) => company.id === shipperCompanyId);

    let newShippingParty: ShippingPartyDTM | null = null;
    let addressId = 0;
    let contactId = 0;

    if (!fullCompany) {
      return null;
    }

    const { addresses } = fullCompany;
    const primaryAddress = addresses.find(({ primary }) => primary);

    if (!primaryAddress) {
      return null;
    }

    const { contacts } = primaryAddress;
    const primaryContacts = contacts.find(({ primary }) => primary);

    addressId = primaryAddress ? primaryAddress.id : 0;
    contactId = primaryContacts ? primaryContacts.id : 0;

    const shipmentId = R.selectors.bookingWizard.getShipmentId(this.store.getState());
    const newBookPartiesId = R.selectors.bookingWizard.getNewBookPartiesId(this.store.getState());

    const references = shipmentPartyReference
      ? [ShippingPartyReference.fromPlain({ id: uuidv4(), value: shipmentPartyReference })]
      : [];

    const address = await R.services.contacts.getAddress(shipperCompanyId, addressId);
    const contact = address.contacts?.find(({ primary }) => primary);

    const shippingPartyToSave = ShippingPartyDTM.fromPlain({
      id: newBookPartiesId,
      role,
      company: {
        name: fullCompany.name,
        id: shipperCompanyId,
      },
      address: {
        country: address.country,
        city: address.city,
        address1: address.address1,
        postalCode: address.postalCode,
        closestPort: address.closestPort,
        id: addressId,
      },
      contact: {
        id: contactId,
        fullName: contact?.fullName || '',
        email: contact?.email || '',
        phone: contact?.phone || '',
      },
      addressList: [],
      contactList: [],
      references,
      description: '',
    });

    if (newBookPartiesId) {
      newShippingParty = await R.services.shippingParties.putShippingParty(+shipmentId, shippingPartyToSave);
    } else {
      newShippingParty = await R.services.shippingParties.postShippingParty(+shipmentId, shippingPartyToSave);
    }

    return newShippingParty;
  }

  public async agreeAndBook() {
    const shipmentId = R.selectors.bookingWizard.getShipmentId(this.store.getState());
    const isCustomerOrg = R.selectors.bookingWizard.getIsCurrentOrganizationCustomer(this.store.getState());
    const shipment = R.selectors.bookingWizard.getShipment(this.store.getState());

    this.dispatch(R.actions.bookingWizard.setIsContentUpdating(true));
    this.dispatch(R.actions.bookingWizard.setIsLoading(true));

    if (isCustomerOrg) {
      if (shipment?.isUsShipmentOriginOrDestination()) {
        await R.services.shipment.postConfirmations(shipmentId, ShipmentConfirmationDTM.fromPlain({
          type: EShipmentConfirmationTypes.NEGOTIATED_RATE_ARRANGEMENT,
        }));
      }

      await R.services.shipment.postConfirmations(shipmentId, ShipmentConfirmationDTM.fromPlain({
        type: EShipmentConfirmationTypes.TERMS_AND_CONDITIONS,
      }));
    }

    let isBookingSuccessful = false;

    try {
      isBookingSuccessful = await R.services.inttra.submitBooking(shipmentId);
    } catch (e) {
      this.dispatch(R.actions.bookingWizard.setIsContentUpdating(false));
      this.dispatch(R.actions.bookingWizard.setIsLoading(false));

      throw e;
    }

    this.closeWizard();
    this.navigate(RouteNames.SHIPMENT_OVERVIEW(shipmentId));
    this.dispatch(R.actions.bookingWizard.setIsContentUpdating(false));
    this.dispatch(R.actions.bookingWizard.setIsLoading(false));

    if (isBookingSuccessful) {
      this.dispatch(R.actions.overview.setBookingRequestSuccessful(true));
      return;
    }

    this.dispatch(R.actions.overview.setBookingRequestSuccessful(false));
  }

  public updateTemperatureControl = async (temperatureControl: TemperatureControlDTM) => {
    const shipmentId = R.selectors.bookingWizard.getShipmentId(this.store.getState());
    const temperatureControlRes = await R.services.temperatureControl.putTemperatureControlData(shipmentId, temperatureControl);

    if (temperatureControlRes) {
      this.dispatch(R.actions.temperatureControl.setTemperatureControlData(temperatureControlRes));
    }
  }

  public updateAdditionalServices = async () => {
    const shipmentId = R.selectors.bookingWizard.getShipmentId(this.store.getState());
    const additionalServicesDrawerChecked = this.mobxStore?.additionalServicesDrawerStore?.getAddAdditionalServicesBookingDrawerChecked;

    if (additionalServicesDrawerChecked) {
      const additionalServices: AdditionalServiceCheckedDTM[] = [];

      additionalServicesDrawerChecked.forEach((item) => {
        item.chargeCode.phases?.forEach((itemPhase) => {
          if (itemPhase === item.designation) {
            const newService = AdditionalServiceCheckedDTM.fromPlain({
              code: item.chargeCode.code,
              phase: itemPhase,
              quantity: item.countDocument ? Number(item.countDocument) : 1,
            });

            additionalServices.push(newService);
          }
        });
      });

      await R.services.shipmentAdditionalServices.postShipmentByIdAdditionalServices(shipmentId, additionalServices);
    }
  }

  public saveShipmentReference = async () => {
    const shipmentId = R.selectors.bookingWizard.getShipmentId(this.store.getState());
    const reference = R.selectors.bookingWizard.getShipmentReference(this.store.getState());
    const referenceData = R.selectors.bookingWizard.getShipmentReferenceData(this.store.getState());

    let responseReference: ShipmentReferenceDTM | undefined = referenceData;

    if (!reference) {
      return;
    }

    if (!responseReference) {
      responseReference = await R.services.shipmentDocument.postAdditionalDetails(+shipmentId, {
        id: null,
        references: [{
          id: null,
          value: reference,
        }],
      });
    } else {
      responseReference = await R.services.shipmentDocument.putAdditionalDetails(+shipmentId, {
        id: responseReference?.id || null,
        references: [{
          id: responseReference?.references?.[0]?.id || null,
          value: reference,
        }],
      });
    }

    this.dispatch(R.actions.bookingWizard.setShipmentReferenceData(responseReference));
  }

  public goFirstStep = () => {
    this.dispatch(R.actions.bookingWizard.setStep(1));
  }

  public closeWizard = () => {
    this.dispatch(R.actions.bookingWizard.setIsWizardOpened(false));
    this.dispatch(R.actions.bookingWizard.reset());
    this.dispatch(R.actions.temperatureControl.clear());

    new AdditionalDrawersUseCase(this).resetBySubmitStateAddAdditionalServicesBookingDrawer();
  }

  public updateAllContainers = async (shipmentId: string) => {
    const containers = R.selectors.bookingWizard.getContainers(this.store.getState());

    await Promise.all(containers.map((container) => R.services.shipmentContainers.putContainer(shipmentId, container)));
  }

  public updateAllCargos = async (shipmentId: number) => {
    const cargos = [...R.selectors.bookingWizard.getCargos(this.store.getState())];
    const cargosToDeleteIds = R.selectors.bookingWizard.getCargosToDeleteIds(this.store.getState());
    const createdCargosIds = R.selectors.bookingWizard.getCreatedCargosIds(this.store.getState());

    const cargosToUpdateOrCreate = cargos.filter(({ id }) => !cargosToDeleteIds.includes(String(id)));
    const cargosToDelete = cargosToDeleteIds.filter((id) => cargosToDeleteIds.includes(id) && !createdCargosIds.includes(id));

    await Promise.all(cargosToUpdateOrCreate.map((cargo) => this.updateCargo(shipmentId, cargo)));
    await Promise.all(cargosToDelete.map((cargoId) => this.deleteCargo(shipmentId, Number(cargoId))));

    this.dispatch(R.actions.bookingWizard.clearCreatedCargosIds());
    this.dispatch(R.actions.bookingWizard.clearCargosToRemoveId());

    await this.getCargos(String(shipmentId));
  };

  public deleteCargo = async (shipmenId: number, cargoId: number) => {
    await R.services.cargo.deleteCargo(shipmenId, cargoId);
  };

  public updateCargo = async (shipmenId: number, cargo: CargoDTM) => {
    const createdCargosIds = R.selectors.bookingWizard.getCreatedCargosIds(this.store.getState());
    const isNewCargo = createdCargosIds.includes(String(cargo?.id));

    if (cargo.errors.hasErrors()) {
      return null;
    }

    let response = null;

    if (!isNewCargo) {
      response = await R.services.cargo.putCargo(shipmenId, cargo);
    }
    if (isNewCargo) {
      response = await R.services.cargo.postCargo(shipmenId, cargo);
    }

    return response;
  };

  public setIsTermsAndConditionsChecked = (value: boolean) => {
    this.dispatch(R.actions.bookingWizard.setIsTermsAndConditionsChecked(value));
  };

  public setIsNRAChecked = (value: boolean) => {
    this.dispatch(R.actions.bookingWizard.setIsNRAChecked(value));
  };

  public setContainerNumber = (containerId: string, containerNumber: string) => {
    this.dispatch(R.actions.bookingWizard.setContainerNumber({
      id: containerId,
      containerNumber,
    }));

    this.validateContainer(containerId);
  };

  public setSealNumber = (containerId: string, sealNumber: string) => {
    this.dispatch(R.actions.bookingWizard.setSealNumber({
      id: containerId,
      sealNumber,
    }));
  };

  public setEmptyReleaseDateError = (error: string) => {
    this.dispatch(R.actions.bookingWizard.setEmptyReleaseDateError(error));
  }

  private validateContainer = (containerId: string) => {
    const { seaworthyCertificate, number } = R.selectors.bookingWizard.getContainerById(containerId)(this.store.getState());

    this.dispatch(R.actions.bookingWizard.setContainersError({
      containerId,
      errors: BookingWizardContainersErrorsDTM.createEmpty(containerId),
    }));

    if (!seaworthyCertificate) {
      this.dispatch(R.actions.bookingWizard.setContainersErrorByKey({
        containerId,
        errorKey: 'certificate',
        error: ValidationErrorDTM.fromPlain({
          type: ValidationErrorType.DEFAULT,
          message: 'shipmentContainerErrors.REFERENCE_TYPE_REQUIRED_MESSAGE',
        }),
      }));
    }

    const containerNumberValidation = this.validateShipmentContainerNumber(number);

    if (containerNumberValidation) {
      this.dispatch(R.actions.bookingWizard.setContainersErrorByKey({
        containerId,
        errorKey: 'number',
        error: containerNumberValidation,
      }));
    }
  }

  private validateContainers = () => {
    const containers = R.selectors.bookingWizard.getContainers(this.store.getState());

    containers.forEach(({
      seaworthyCertificate,
      number,
      id,
    }) => {
      if (!seaworthyCertificate) {
        this.dispatch(R.actions.bookingWizard.setContainersErrorByKey({
          containerId: id,
          errorKey: 'certificate',
          error: ValidationErrorDTM.fromPlain({
            type: ValidationErrorType.DEFAULT,
            message: 'shipmentContainerErrors.REFERENCE_TYPE_REQUIRED_MESSAGE',
          }),
        }));
      }

      const containerNumberValidation = this.validateShipmentContainerNumber(number);

      if (containerNumberValidation) {
        this.dispatch(R.actions.bookingWizard.setContainersErrorByKey({
          containerId: id,
          errorKey: 'number',
          error: containerNumberValidation,
        }));
      }
    });
  }

  private validateCargosHazmat = () => {
    const cargos = R.selectors.bookingWizard.getCargos(this.store.getState());
    const shouldHaveAnyHazmats = R.selectors.bookingWizard.getShouldHaveAnyHazmats(this.store.getState());

    if (!shouldHaveAnyHazmats) {
      return;
    }

    cargos.forEach((cargo) => {
      if (cargo && cargo.id) {
        this.updateHazmatErrors(cargo.id);
      }
    });
  }

  private validateCargosRequiredFields = () => {
    const cargos = R.selectors.bookingWizard.getCargos(this.store.getState());

    cargos.forEach((cargo) => this.validateCargoRequiredFields(cargo));
  }

  private validateCargoRequiredFields = (cargo: CargoDTM) => {
    const {
      code,
      packagesNumber,
      packageType,
      weight,
    } = cargo;

    if (!code) {
      this.dispatch(R.actions.bookingWizard.setCargoError({
        cargoId: Number(cargo?.id),
        field: 'code',
        error: this.generateRequiredError(),
      }));
    }

    if (!packagesNumber) {
      this.dispatch(R.actions.bookingWizard.setCargoError({
        cargoId: Number(cargo?.id),
        field: 'packagesNumber',
        error: this.generateRequiredError(),
      }));
    }

    if (!packageType) {
      this.dispatch(R.actions.bookingWizard.setCargoError({
        cargoId: Number(cargo?.id),
        field: 'packageType',
        error: this.generateRequiredError(),
      }));
    }

    if (!weight) {
      this.dispatch(R.actions.bookingWizard.setCargoError({
        cargoId: Number(cargo?.id),
        field: 'weight',
        error: this.generateRequiredError(),
      }));
    }
  }

  private generateRequiredError = () => ValidationErrorDTM.fromPlain({
    type: ValidationErrorType.DEFAULT,
    message: i18n.t('basicErrors.REQUIRED_MESSAGE'),
  });

  private updateHazmatStatus = (cargoId: number, forceToBeHazmat?: boolean) => {
    const cargos = R.selectors.bookingWizard.getCargos(this.store.getState());
    const cargo = cargos.find(({ id }) => id === cargoId);

    if (!cargo) {
      return;
    }

    const isHazmat = this.getHazmatStatus(cargo);

    this.dispatch(R.actions.bookingWizard.setIsHazmat({
      isHazmat: forceToBeHazmat || isHazmat,
      cargoId,
    }));
  }

  private checkCargoHazmatEmptynessCriteria = () => {
    const cargos = R.selectors.bookingWizard.getCargos(this.store.getState());
    const shouldHaveAnyHazmats = R.selectors.bookingWizard.getShouldHaveAnyHazmats(this.store.getState());

    if (!shouldHaveAnyHazmats) {
      return;
    }

    const areAllCargoHazmatsEmpty = cargos.every(({
      imoClass,
      unNumber,
      packingGroup,
      shippingName,
      msdsDocument,
    }) => !unNumber && !imoClass && !packingGroup && !shippingName && !msdsDocument.length);

    this.dispatch(R.actions.bookingWizard.setIsHazmatErrorVisible(areAllCargoHazmatsEmpty));
  }

  private updateHazmatErrors = (cargoId: number) => {
    const cargos = R.selectors.bookingWizard.getCargos(this.store.getState());
    const cargo = cargos.find(({ id }) => id === cargoId);

    if (!cargo) {
      return;
    }

    const {
      isHazmat,
      imoClass,
      unNumber,
      packingGroup,
      shippingName,
      msdsDocument,
    } = cargo;

    if (!isHazmat) {
      this.dispatch(R.actions.bookingWizard.clearHazmatErrors(cargoId));
      return;
    }

    const errors = CargoErrorsDTM.fromPlain(validateHazmat(
      unNumber,
      imoClass,
      packingGroup,
      shippingName,
      msdsDocument,
    ));

    if (!errors.hasErrors()) {
      this.dispatch(R.actions.bookingWizard.setIsHazmatErrorVisible(false));
    }

    if (errors.hasErrors() && cargo.id) {
      const isToggled = R.selectors.bookingWizard.getIsHazmatToggledByCargoId(cargo.id)(this.store.getState());

      if (!isToggled) {
        this.dispatch(R.actions.bookingWizard.addToggledHazmatCargoId(cargo.id));
      }
    }

    this.dispatch(R.actions.bookingWizard.setHazmatErrors({
      ...errors,
      cargoId,
    }));
  }

  private updateCargoErrors = (index: number) => {
    const cargo = R.selectors.bookingWizard.getCargos(this.store.getState())[index];

    if (!cargo) {
      return;
    }

    const {
      code,
      name,
      packageType,
      packagesNumber,
      weight,
      references,
    } = cargo;

    let cargoErrors = CargoErrorsDTM.fromPlain({
      code: validateRequiredField(code),
      name: validateRequiredField(name),
      packageType: validateRequiredField(packageType),
      packagesNumber: validateRequiredField(packagesNumber),
      weight: validateRequiredField(weight),
      references: validateReferences(references),
    });

    if (cargo.isHazmat) {
      const {
        unNumber,
        imoClass,
        packingGroup,
        shippingName,
        msdsDocument,
      } = cargo;

      cargoErrors = CargoErrorsDTM.fromPlain({
        ...cargoErrors,
        ...validateHazmat(
          unNumber,
          imoClass,
          packingGroup,
          shippingName,
          msdsDocument,
        ),
      });
    }

    this.dispatch(R.actions.bookingWizard.setCargoErrors({
      index,
      errors: cargoErrors,
    }));
  };

  private getHazmatStatus = (cargo: HazmatDTM) => {
    const {
      unNumber,
      imoClass,
      packingGroup,
      shippingName,
      msdsDocument,
    } = cargo;

    const msdsStatus = (
      msdsDocument
      && !!msdsDocument.length
      && msdsDocument[0].status === 'done'
      && !!msdsDocument[0].response.id
      && !!msdsDocument[0].response.name
      && !!msdsDocument[0].response.type
    );

    const isHazmat = (
      msdsStatus
      || !!imoClass
      || !!unNumber
      || !!packingGroup
      || !!shippingName
    );

    return isHazmat;
  }

  private validateShipmentContainerNumber = (containerNumber?: string) => {
    if (!containerNumber) {
      return ValidationErrorDTM.fromPlain({
        type: ValidationErrorType.DEFAULT,
        message: 'shipmentContainerErrors.REFERENCE_TYPE_REQUIRED_MESSAGE',
      });
    }

    if (!containerNumber.match(/^[a-zA-Z]{4}\d{7}$/)) {
      return ValidationErrorDTM.fromPlain({
        type: ValidationErrorType.ALERT,
        message: 'shipmentContainerErrors.INVALID_CONTAINER_NUMBER',
      });
    }

    return undefined;
  }
}
