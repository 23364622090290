import styled, { createGlobalStyle } from 'styled-components';
import AntDropdown from 'antd/es/dropdown';

import { divFlex } from 'app-wrapper/view/themes/mixin';
import { emptyScrollbar } from 'app-wrapper/view/themes/components';

export const AddAdditionalServicesSelectItemWrapStyle = createGlobalStyle`
  div[data-class='AddAdditionalServicesSelectItemWrap'] {
    .anticon-ellipsis {
      margin-left: 0;
    }
  }
`;

export const AddAdditionalServicesSelectItemWrap = styled.div.attrs({
  'data-class': 'AddAdditionalServicesSelectItemWrap',
})`
  ${divFlex}
  align-items: center;
  justify-content: center;

  padding: 16px;
  margin-top: 8px;
  height: 64px;
  width: 100%;
  background-color: ${({ theme }) => theme.themesColors.neutralBranding1};
  border: 1px solid ${({ theme }) => theme.themesColors.neutralBranding4};
  border-radius: 4px;
`;

export const AddAdditionalServicesSelectItemContent = styled.div.attrs({
  'data-class': 'AddAdditionalServicesSelectItemContent',
})`
  ${divFlex}
  ${emptyScrollbar}
  flex-direction: column;
  overflow: auto;

  font-weight: 400;
  font-size: 12px;
  color: ${({ theme }) => theme.themesColors.neutralBranding10};

  padding: 16px;
  height: 100%;
  width: 100%;
  border: 1px solid ${({ theme }) => theme.themesColors.neutralBranding4};
  background-color: ${({ theme }) => theme.themesColors.neutralBranding1};
`;

export const AddAdditionalServicesSelectItemCheckedWrap = styled.div.attrs({
  'data-class': 'AddAdditionalServicesSelectItemCheckedWrap',
})`
  ${divFlex}
`;

export const AddAdditionalServicesSelectItemCheckedWrapText = styled.div.attrs((props: {
  isFirst: boolean
  'data-class'?: string
}) => ({
  isFirst: props.isFirst,
  'data-class': props?.['data-class'] || 'AddAdditionalServicesSelectItemCheckedWrapText',
}))`
  ${divFlex}

  font-weight: 500;
  font-size: 14px;
  margin-left: ${({ isFirst }) => (isFirst ? '0' : '8px')};
`;

export const AddAdditionalServicesSelectItemInputWrap = styled.div.attrs({
  'data-class': 'AddAdditionalServicesSelectItemInputWrap',
})`
  ${divFlex}

  margin-left: auto;
`;

export const AddAdditionalServicesSelectItemInputName = styled.div.attrs({
  'data-class': 'AddAdditionalServicesSelectItemInputName',
})`
  ${divFlex}
  align-items: center;

  font-weight: 400;
  font-size: 14px;
  color: ${({ theme }) => theme.themesColors.secondaryDot45};
`;

export const AddAdditionalServicesSelectItemInputBlock = styled.div.attrs({
  'data-class': 'AddAdditionalServicesSelectItemInputBlock',
})`
  ${divFlex}

  width: 64px;
  margin-left: 8px;
`;

export const AddAdditionalServicesSelectItemInputSubCount = styled.div.attrs({
  'data-class': 'AddAdditionalServicesSelectItemInputCount',
})`
  ${divFlex}
  align-items: center;

  margin-left: 8px;
  font-weight: 400;
  font-size: 14px;
  color: ${({ theme }) => theme.themesColors.secondaryDot45};
`;

export const AddAdditionalServicesSelectItemInputCount = styled.div.attrs({
  'data-class': 'AddAdditionalServicesSelectItemInputCount',
})`
  ${divFlex}
  align-items: center;

  margin-left: 8px;
  font-weight: 500;
  font-size: 14px;
  color: ${({ theme }) => theme.themesColors.neutralBranding10};
`;

export const AddAdditionalServicesSelectItemPopover = styled.div.attrs({
  'data-class': 'AddAdditionalServicesSelectItemPopover',
})`
  ${divFlex}

  margin-left: 16px;
`;

export const AddAdditionalServicesSelectItemPopoverTextWrap = styled.div.attrs({
  'data-class': 'AddAdditionalServicesSelectItemPopoverTextWrap',
})`
  ${divFlex}
  width: 96px;
  height: 22px;
  justify-content: center;
  align-items: center;
  padding: 5px 8px;
  padding-right: 2px;

  background-color: ${({ theme }) => theme.themesColors.yellowBranding1};
  border: 1px solid ${({ theme }) => theme.themesColors.yellowBranding4};
  border-radius: 2px;
  cursor: pointer;

  svg {
    margin-right: 5px;
    color: ${({ theme }) => theme.themesColors.yellowBranding7};
  }
`;

export const AddAdditionalServicesSelectItemPopoverText = styled.div.attrs({
  'data-class': 'AddAdditionalServicesSelectItemPopoverText',
})`
  ${divFlex}
  justify-content: center;
  align-items: center;

  font-weight: 400;
  font-size: 12px;
  color: ${({ theme }) => theme.themesColors.yellowBranding8};

  svg {
    width: 4px;
    height: 6px;
    margin-left: 3px;
    margin-top: 1px;
    color: ${({ theme }) => theme.themesColors.yellowBranding8};
  }
`;

export const AddAdditionalServicesSelectItemTitle = styled.div.attrs({
  'data-class': 'AddAdditionalServicesSelectItemTitle',
})`
  ${divFlex}
  justify-content: start;

  font-weight: 400;
  font-size: 12px;
  color: ${({ theme }) => theme.themesColors.neutralBranding10};
`;

export const AddAdditionalServicesSelectItemDivider = styled.div.attrs({
  'data-class': 'AddAdditionalServicesSelectItemDivider',
})`
  ${divFlex}
  width: 100%;

  margin-top: 16px;
  margin-bottom: 16px;
  border: 1px dashed ${({ theme }) => theme.themesColors.neutralBranding4};
`;

export const AddAdditionalServicesSelectItemFooter = styled.div.attrs({
  'data-class': 'AddAdditionalServicesSelectItemFooter',
})`
  ${divFlex}
  justify-content: end;
  z-index: 4;
  width: calc(100% + 48px);
  height: 64px;
  bottom: 0px;
  align-items: center;
  padding: 14px 24px;

  background-color: ${({ theme }) => theme.themesColors.neutralBranding1};
  box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px rgba(0, 0, 0, 0.08), 0px 9px 28px 8px rgba(0, 0, 0, 0.05);
`;

export const AddAdditionalServicesDropdown = styled(AntDropdown).attrs({
  'data-class': 'AddAdditionalServicesDropdown',
})`
  .anticon-ellipsis {
    margin-left: 0;
  }
`;

export const DropdownIcon = styled.div.attrs({
  'data-class': 'DropdownIcon',
})`
  ${divFlex}
  justify-content: center;
  margin-left: 16px;
  cursor: pointer;

  svg:hover {
    color: ${({ theme }) => theme.themesColors.neutralBranding6};
  }
`;

export const AddAdditionalServicesDropdownOverlay = styled.div.attrs({
  'data-class': 'AddAdditionalServicesDropdownOverlay',
})`
  width: 118px;
  background-color: ${({ theme }) => theme.themesColors.neutralBranding1};
  box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05);
`;

export const AddAdditionalServicesRemoveOption = styled.div.attrs({
  'data-class': 'AddAdditionalServicesRemoveOption',
})`
  padding: 5px 12px;
  font-size: 14px;
  line-height: 22px;
  width: 100%;
  height: 32px;
  color: rgba(255, 77, 79, 1);
  transition: all ease-in .2s;
  cursor: pointer;

  &:hover {
    background-color: rgba(245, 245, 245, 1);
  }
`;

export const AddAdditionalServicesDivider = styled.div.attrs({
  'data-class': 'AddAdditionalServicesDivider',
})`
  ${divFlex}
  margin-left: 16px;
  width: 1px;
  height: 100%;
  border-left: 1px dotted ${({ theme }) => theme.themesColors.neutralBranding6};
`;
