import React from 'react';
import { useSelector } from 'react-redux';

import { UC } from 'monetary/controllers';
import { R } from 'monetary/repository';

import { TableComponent } from './Table.component';

const TableContainer = ({ type }: { type: string }) => {
  const data = useSelector(R.selectors.payments.getDataForTable);
  const totalElements = useSelector(R.selectors.payments.getTotalElements);
  const activePage = useSelector(R.selectors.payments.getActivePage);

  return (
    <TableComponent
      data={data}
      totalElements={totalElements}
      onChangePage={UC.payments.onChangeActivePage}
      type={type}
      activePage={activePage}
    />
  );
};

export { TableContainer as Table };
