import React from 'react';

import { R } from 'shipment-operations/repository';
import { useAppSelector } from 'app-wrapper/hooks';

import { FeesComponent } from './Fees.component';

const Fees = () => {
  const data = useAppSelector(R.selectors.createCreditNote.getFeesDataSelected);

  return (
    <FeesComponent data={data[0]} />
  );
};

export { Fees };
