import React, {
  useEffect,
  useState,
  useCallback,
} from 'react';

import {
  useWatch,
  FieldArrayPath,
  useFormContext,
  FieldPath,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Divider from 'antd/es/divider';
import Row from 'antd/es/row';
import DeleteOutlined from '@ant-design/icons/lib/icons/DeleteOutlined';
import debounce from 'lodash/debounce';

import { InputController } from 'app-wrapper/view/controllers/InputController';
import { SelectController, Option } from 'app-wrapper/view/controllers/SelectController';
import TypeSwitchController from 'app-wrapper/view/controllers/TypeSwitchController/TypeSwitchController';
import { Col } from 'app-wrapper/view/components/Col/Col';
import { FormStatus } from 'app-wrapper/view/components/FormStatus';
import { Button } from 'app-wrapper/view/components/Button';
import { PlacesAutocomplete } from 'app-wrapper/view/components/PlacesAutocomplete';
import { RequiredShipmentRoles } from 'app-wrapper/constants/RequiredShipmentRoles.const';
import { fetchShipmentRoles, getShipmentRolesByName } from 'app-wrapper/data/shipmentsParties/shipmentsPartySlice';
import Company from 'app-wrapper/types/Company';
import { useAppSelector, useAppDispatch } from 'app-wrapper/hooks';
import Address, { GeoParsedAddress } from 'app-wrapper/types/Address';
import CompanyContact from 'app-wrapper/types/CompanyContact';
import Role from 'app-wrapper/enums/Roles';
import {
  getCompanies,
  searchCompanies,
  isCompaniesLoading,
  createCompany,
  getCompanyAddress,
  getCompanyContacts,
  getContacts,
  isContactsLoading,
  createCompanyContact,
  getAddresses,
  createCompanyAddress,
} from 'app-wrapper/data/company';

import { FormTitle } from 'shipment-operations/view/pages/Booking/styled';
import { ShippingPartyReferenceForm } from 'shipment-operations/view/pages/Booking/ShippingParties/ShippingPartyReferenceForm';
import { IShippingPartyForms, IShippingPartyForm } from 'shipment-operations/view/pages/Booking/ShippingParties/types';

import { DropdownMenuWithNewItem } from './DropdownMenuWithNewItem';
import {
  ShippingFormFieldLabel,
  ShippingFormSectionTitle,
  ShippingPartiesForm,
  ShippingFormHeaderRow,
  ShippingFormReferenceContainer,
} from './styled';

const RED_BORDER_HIGHLIGHTING = {
  border: '1px solid red',
  boxShadow: 'none',
};

const availableRolesForRemove = [
  Role.IMPORT_BROKER,
  Role.EXPORT_BROKER,
  Role.SECOND_NOTIFY_PARTY,
];
const dividerMargins = {
  margin: '16px 0',
};

interface IShipmentPartyFormProps {
  selectedShipmentParty: number;
  onRemoveRole: () => void;
}

// TODO: divide view and logic

export const ShippingPartyForm: React.FC<IShipmentPartyFormProps> = ({
  selectedShipmentParty,
  onRemoveRole,
}) => {
  const formMethods = useFormContext<IShippingPartyForms>();
  useWatch({ control: formMethods.control, name: 'shipmentParties' });

  const getName = useCallback((field: FieldPath<IShippingPartyForm>) => (
    `shipmentParties.${(selectedShipmentParty)}.${field}` as FieldPath<IShippingPartyForms>
  ), [selectedShipmentParty]);

  const getErrorOutline = useCallback((field: FieldPath<IShippingPartyForm>) => {
    const { error, isTouched } = formMethods.getFieldState(getName(field));
    const touched = isTouched;

    return (
      touched && error
        ? RED_BORDER_HIGHLIGHTING
        : undefined
    );
  }, [selectedShipmentParty]);

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(fetchShipmentRoles({}));
    dispatch(searchCompanies({
      urlGetParams: [
        'page=1',
        'size=20',
      ],
    }));
  }, [dispatch]);

  const formStatus = (
    formMethods.getFieldState(`shipmentParties.${selectedShipmentParty}`).error
      ? 'incomplete'
      : 'complete'
  );
  const [companyQuery, setCompanyQuery] = useState('');
  const roleByName = useAppSelector(getShipmentRolesByName);

  const companies = useAppSelector(getCompanies);
  const companiesLoading = useAppSelector(isCompaniesLoading);
  const contacts = useAppSelector(getContacts);
  const contactsLoading = useAppSelector(isContactsLoading);
  const addresses = useAppSelector(getAddresses);

  const handleCompanyChange = async (companyId: number) => {
    const selectedCompany = companies.find((item: Company) => item.id === companyId);

    dispatch(getCompanyAddress({ urlId: String(companyId) }))
      .unwrap()
      .then((companyAddresses: Address[]) => {
        dispatch(getCompanyContacts({ urlId: String(companyId) }))
          .unwrap()
          .then((companyContacts: CompanyContact[]) => {
            formMethods.setValue(
              `shipmentParties.${selectedShipmentParty}.company`,
              selectedCompany || undefined,
            );
            formMethods.setValue(
              `shipmentParties.${selectedShipmentParty}.contact`,
              // @ts-ignore
              companyContacts?.length === 1 ? companyContacts[0] : {
                email: undefined,
                fullName: undefined,
                phone: undefined,
                phone2: undefined,
              },
            );
            formMethods.setValue(
              `shipmentParties.${selectedShipmentParty}.address`,
              // @ts-ignore
              companyAddresses?.length === 1 ? companyAddresses[0] : {
                address1: undefined,
              },
            );
          });
      });
  };
  const selectedCompany = formMethods.getValues(`shipmentParties.${selectedShipmentParty}.company`);

  const handleContactChange = useCallback((contactId: number) => {
    const foundContact = contacts.find((item: CompanyContact) => item.id === contactId);
    formMethods.setValue(`shipmentParties.${selectedShipmentParty}.contact`, foundContact);
  }, [formMethods, selectedShipmentParty]);

  const handleAddressChange = useCallback((addressId: number) => {
    const foundAddress = addresses.find((item: Address) => item.id === addressId);
    formMethods.setValue(`shipmentParties.${selectedShipmentParty}.address`, foundAddress);
  }, [formMethods, selectedShipmentParty]);

  const handleCompanySearch = debounce((newValue: string) => {
    setCompanyQuery(newValue);
    dispatch(searchCompanies({
      urlGetParams: [
        `query=${encodeURIComponent(newValue)}`,
        'page=1',
        'size=20',
      ],
    }));
  }, 300);

  const handleCreateCompany = useCallback(() => {
    dispatch(createCompany({ name: companyQuery }))
      .unwrap()
      .then((newCompany: Company) => {
        if (newCompany) {
          formMethods.setValue(`shipmentParties.${selectedShipmentParty}.company`, newCompany);
        }
      });
  }, []);

  const handleCreateContact = useCallback(() => {
    dispatch(createCompanyContact({
      id: selectedCompany?.id,
      body: {
        fullName: companyQuery,
      },
    }))
      .unwrap()
      .then((newContact: CompanyContact) => {
        if (newContact) {
          formMethods.setValue(`shipmentParties.${selectedShipmentParty}.contact`, newContact);
        }
      });
  }, [dispatch, companyQuery, formMethods, selectedShipmentParty, selectedCompany]);

  const handleCreateAddress = useCallback((address: GeoParsedAddress) => {
    dispatch(createCompanyAddress({
      id: selectedCompany?.id,
      body: {
        postalCode: address.postalCode,
        country: address.country,
        city: address.city,
        closestPort: 'USCA2',
        state: address.state,
        address1: `${address.streetNumber || ''} ${address.route}`,
      },
    }))
      .unwrap()
      .then((createdAddress: Address) => {
        formMethods.setValue(`shipmentParties.${selectedShipmentParty}.address`, createdAddress);
      });
  }, [selectedCompany]);

  const handleSwitchCurrentOrganization = useCallback(
    (value) => {
      formMethods.setValue(`shipmentParties.${selectedShipmentParty}.currentOrganization`, value);
    }, [selectedShipmentParty, formMethods],
  );

  const renderCompanyMenu = (menu: React.ReactElement) => (
    <DropdownMenuWithNewItem menu={menu} onClick={handleCreateCompany} label={t('Add Company')} />
  );

  const renderContactMenu = (menu: React.ReactElement) => (
    <DropdownMenuWithNewItem menu={menu} onClick={handleCreateContact} label={t('Add Contact')} />
  );

  const companySelected = Boolean(selectedCompany?.id);
  const disabledContact = !companySelected;
  const roleValue = formMethods.getValues(`shipmentParties.${selectedShipmentParty}.role`) as Role;
  const requiredRole = RequiredShipmentRoles.includes(roleValue);
  const hasAddress = addresses.length > 0;

  return (
    <ShippingPartiesForm id="step_form_0">
      <Row justify="space-between">
        <ShippingFormHeaderRow>
          <FormTitle level={5}>
            {roleByName[roleValue]}{requiredRole && '*'}
          </FormTitle>
          <FormStatus type={formStatus} />
        </ShippingFormHeaderRow>
        <div style={{ display: 'flex' }}>
          <TypeSwitchController
            controllerProps={{ control: formMethods.control, name: `shipmentParties.${selectedShipmentParty}.currentOrganization` }}
            leftText={t('Same as my organization')}
            checkedChildren="On"
            unCheckedChildren="Off"
            disabledbg="true"
            onChange={handleSwitchCurrentOrganization}
          />
          {
            availableRolesForRemove.includes(roleValue) && (
              <Button size="small" type="dashed" icon={<DeleteOutlined />} onClick={onRemoveRole}>{t('Remove')}</Button>
            )
          }
        </div>
      </Row>
      <Divider dashed style={dividerMargins} />
      <ShippingFormSectionTitle>{t('Company')}</ShippingFormSectionTitle>
      <Row gutter={8}>
        <Col span={12}>
          <ShippingFormFieldLabel>{`${t('Company name')}*`}</ShippingFormFieldLabel>
          <SelectController
            showSearch
            loading={companiesLoading}
            name={getName('company.name')}
            autoClearSearchValue={false}
            onSearch={handleCompanySearch}
            placeholder={`${t('Your company name')} *`}
            notFoundContent={<span>{t('Validations.string.noResults')}</span>}
            showArrow={false}
            filterOption={false}
            onChange={handleCompanyChange}
            dropdownRender={renderCompanyMenu}
            style={{
              width: '100%',
              ...getErrorOutline('company.name'),
            }}
          >
            {
              companies.map((company: Company) => (
                <Option key={company.id} value={company.id}>{company.name}</Option>
              ))
            }
          </SelectController>
        </Col>
        <Col span={12}>
          <ShippingFormFieldLabel>{`${t('Company Address')}*`}</ShippingFormFieldLabel>
          {
            hasAddress
              ? (
                <SelectController
                  loading={companiesLoading}
                  disabled={disabledContact}
                  name={getName('address.address1')}
                  autoClearSearchValue={false}
                  placeholder={`${t('Your company address')} *`}
                  onChange={handleAddressChange}
                  style={{
                    width: '100%',
                    ...getErrorOutline('address.address1'),
                  }}
                >
                  {
                    addresses.map((address: Address) => (
                      <Option key={address.id} value={address.id}>{address.address1}</Option>
                    ))
                  }
                </SelectController>
              )
              : (
                <PlacesAutocomplete
                  defaultValue=""
                  disabled={disabledContact}
                  placeholder={t('Your company address')}
                  style={getErrorOutline('address.address1')}
                  onSelectPlace={handleCreateAddress}
                />
              )
          }
        </Col>
      </Row>
      <Divider dashed style={dividerMargins} />
      <ShippingFormSectionTitle>{t('Contact Person')}</ShippingFormSectionTitle>
      <Row gutter={[8, 16]}>
        <Col span={12}>
          <ShippingFormFieldLabel>{`${t('Contact Person')}*`}</ShippingFormFieldLabel>
          <SelectController
            showSearch
            disabled={disabledContact}
            loading={contactsLoading}
            name={getName('contact.fullName')}
            autoClearSearchValue={false}
            onSearch={handleCompanySearch}
            placeholder={`${t('Сontact person name')} *`}
            notFoundContent={<span>{t('Validations.string.noResults')}</span>}
            showArrow={false}
            filterOption={false}
            onChange={handleContactChange}
            dropdownRender={renderContactMenu}
            style={{
              width: '100%',
              ...getErrorOutline('contact.fullName'),
            }}
          >
            {
              contacts.map((contact: CompanyContact) => (
                <Option key={contact.id} value={contact.id}>{contact.fullName}</Option>
              ))
            }
          </SelectController>
        </Col>
        <Col span={12}>
          <ShippingFormFieldLabel>{`${t('Email')}*`}</ShippingFormFieldLabel>
          <InputController
            disabled={disabledContact}
            name={getName('contact.email')}
            placeholder={t('Contact Email')}
            style={getErrorOutline('contact.email')}
          />
        </Col>
        <Col span={12}>
          <ShippingFormFieldLabel>{`${t('Phone')}*`}</ShippingFormFieldLabel>
          <InputController
            disabled={disabledContact}
            name={getName('contact.phone')}
            placeholder={t('Phone example')}
            style={getErrorOutline('contact.phone')}
          />
        </Col>
        <Col span={12}>
          <ShippingFormFieldLabel>{`${t('Additional Phone')}`}</ShippingFormFieldLabel>
          <InputController
            disabled={disabledContact}
            name={getName('contact.phone2')}
            placeholder={t('Phone example')}
          />
        </Col>
      </Row>
      <Divider dashed style={dividerMargins} />
      <Row gutter={[8, 10]} wrap>
        <Col span={12}>
          <ShippingFormFieldLabel>{`${t('USCI Number')}*`}</ShippingFormFieldLabel>
          <InputController
            disabled={disabledContact}
            name={getName('company.usci')}
            placeholder={t('USCI Number Example')}
            style={getErrorOutline('company.usci')}
          />
        </Col>
        <Col span={12}>
          <ShippingFormFieldLabel>{`${t('Tax ID Number')}*`}</ShippingFormFieldLabel>
          <InputController
            disabled={disabledContact}
            name={getName('company.taxId')}
            placeholder={t('Tax ID Number Example')}
            style={getErrorOutline('company.taxId')}
          />
        </Col>
      </Row>
      <Divider dashed style={dividerMargins} />
      <Row gutter={[8, 10]}>
        <Col span={10}>
          <ShippingFormFieldLabel>{`${t('Reference Number')}`}</ShippingFormFieldLabel>
          <ShippingFormReferenceContainer>
            <ShippingPartyReferenceForm
              disabled={disabledContact}
              formControl={formMethods}
              fieldName={getName('references') as FieldArrayPath<IShippingPartyForms>}
            />
          </ShippingFormReferenceContainer>
        </Col>
      </Row>
    </ShippingPartiesForm>
  );
};
