import styled from 'styled-components';

import { colors } from 'app-wrapper/view/themes/themesColors';

export const IconFrame = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  width: 32px;
  height: 32px;

  border: 1px solid ${colors.purple10};
  border-radius: 4px;

  > * {
    color: #4A55AD;
  }
`;
