import React, { forwardRef } from 'react';
import { CheckboxProps } from 'antd/es/checkbox';

import { Checkbox as AntCheckbox } from './Checkbox.styled';

// TODO: fix ref type
export const Checkbox = forwardRef<any, CheckboxProps>((props, ref) => (
  <AntCheckbox
    type="checkbox"
    ref={ref}
    {...props}
  />
));
