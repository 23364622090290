import React, { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { R } from 'shipment-operations/repository';
import { UC } from 'shipment-operations/controllers';
import { PermissionAttributePolicy } from 'shipment-operations/constants';

import { ExportClearanceTypeSelectComponent } from './ExportClearanceTypeSelect.component';

const ExportClearanceTypeSelectContainer: FC = () => {
  const { type } = useSelector(R.selectors.exportClearance.getExportClearance);
  const { type: typeError } = useSelector(R.selectors.exportClearance.getErrors);
  const touchedFields = useSelector(R.selectors.exportClearance.getTouchedFields);
  const wasUpdateAttempted = useSelector(R.selectors.exportClearance.getUpdateAttemptStatus);
  const permissions = useSelector(R.selectors.shipment.getExportClearancePermissions);

  const isDisabled = useMemo(() => permissions.exportClearance !== PermissionAttributePolicy.WRITE, [permissions]);

  const isErrorDisplayed = !!typeError && (touchedFields.exportClearanceType || wasUpdateAttempted);

  return (
    <ExportClearanceTypeSelectComponent
      exportClearanceType={type}
      error={isErrorDisplayed}
      setExportClearanceType={UC.exportClearance.setExportClearanceType}
      touchField={UC.exportClearance.touchField}
      disabled={isDisabled}
    />
  );
};

export {
  ExportClearanceTypeSelectContainer as ExportClearanceTypeSelect,
};
