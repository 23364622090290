import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ShipmentBookingStatusEnum } from 'shipment-operations/constants';

import {
  WarningButton, WarningIcon, WarningText, WarningWrap,
} from './ContainersChangesWarning.styled';

interface IContainersChangesWarningProps {
  status?: ShipmentBookingStatusEnum;
  handleDrawerOpen: () => void;
  isRequestTheSame?: boolean;
  isCustomer?: boolean;
  isMaersk?: boolean;
  partnerWarning?: boolean;
}

const CONFIRMED_STATUSES = [
  ShipmentBookingStatusEnum.BOOKING_AMENDMENT_CONFIRMED,
  ShipmentBookingStatusEnum.BOOKING_CONFIRMED,
];

const ContainersChangesWarning: FC<IContainersChangesWarningProps> = ({
  status, handleDrawerOpen, isRequestTheSame, isCustomer, isMaersk,
  partnerWarning,
}) => {
  const { t } = useTranslation();

  const generateText = useMemo(() => {
    if (!status) {
      return {};
    }
    if (isCustomer && CONFIRMED_STATUSES.includes(status) && !isRequestTheSame) {
      return {
        text: t('You have made amendments to the shipment'),
        buttonText: t('ReviewSubmit'),
      };
    }
    if (isCustomer && status === ShipmentBookingStatusEnum.BOOKING_AMENDMENT_SUBMITTED) {
      return {
        text: t('You have made amendments waiting'),
        buttonText: t('seeDetails'),
      };
    }
    if (!isCustomer && (status === ShipmentBookingStatusEnum.BOOKING_SUBMITTED || status === ShipmentBookingStatusEnum.BOOKING_AMENDMENT_SUBMITTED) && isMaersk) {
      return {
        text: t('Booking request and confirmation'),
        buttonText: t('seeDetails'),
      };
    }
    if (partnerWarning) {
      return {
        text: t('Carrier has confirmed'),
        buttonText: t('seeDetails'),
      };
    }
    return {};
  }, [status, isRequestTheSame, isMaersk, partnerWarning, isCustomer]);

  return (
    <WarningWrap>
      <WarningIcon />

      <WarningText>
        {generateText.text}
      </WarningText>

      <WarningButton
        size="small"
        onClick={handleDrawerOpen}
      >
        {generateText.buttonText}
      </WarningButton>
    </WarningWrap>
  );
};

export { ContainersChangesWarning };
