import React, { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import EditOutlined from '@ant-design/icons/EditOutlined';
import ExclamationCircleFilled from '@ant-design/icons/ExclamationCircleFilled';

import { RouteNames } from 'app-wrapper/constants';
import {
  RoadShipSvg, UserPersonSvg, VerticalFormItemSvg,
} from 'app-wrapper/view/icons';
import themesColors from 'app-wrapper/view/themes/themesColors';
import { dateFormatETA } from 'app-wrapper/utils';
import { Tooltip } from 'app-wrapper/view/components';

import {
  ShipmentBookingStatusColors, ShipmentBookingStatusEnum, ShipmentBookingStatusNames, ShipmentStatusColors, ShipmentStatusNames,
} from 'shipment-operations/constants';
import { ShipmentAllStatsDTM, ShipmentPreviewDTM } from 'shipment-operations/models/dtm';

import { StatusLight, FreightTypeLocationIconComponent } from 'app-wrapper/view/guideline';
import {
  ShipmentCardContainer, ShipmentCardSectionCarrier, ShipmentCardSectionCustomer, ShipmentCardSectionDestination, ShipmentCardSectionIcon, ShipmentCardSectionLinkById, ShipmentCardSectionOrigin, ShipmentCardSectionShipment, ShipmentCardSectionShipmentReferenceIcon, ShipmentCardSectionShipmentReferenceText, ShipmentCardSectionShipmentReferenceWrap, ShipmentCardSectionStatus, ShipmentCardSectionStatusDescription, ShipmentCardSectionStatusTitle, ShipmentCardSectionStatusTitleSpan, ShipmentCardSectionTasks, ShipmentCardSectionTasksIcon, ShipmentCardSectionVerticalDivider, ShipmentCardSectionVerticalDividerIcon, ShipmentListLoadingCardStyled, ShipmentListSpan,
} from './ShipmentCard.styled';
import { SCDescriptions } from './ShipmentCardDescriptions';
import { ModalShipmentReferenceUpdate } from '../ModalShipmentReferenceUpdate';

interface IShipmentCardProps {
  shipmentData?: ShipmentPreviewDTM
  shipmentStats?: ShipmentAllStatsDTM;
  $isClickable?: boolean
  isReference?: boolean
  isReferenceHeader?: boolean
  onClickToCustomer?: () => void
  onChangeIsOpenModalShipmentReference?: (value: boolean) => void
}

export const ShipmentCard: FC<IShipmentCardProps> = ({
  shipmentData,
  shipmentStats,
  $isClickable = true,
  isReference,
  isReferenceHeader,
  onClickToCustomer,
  onChangeIsOpenModalShipmentReference,
}) => {
  const { t } = useTranslation();

  const onClickToCustomerHandler = useCallback(
    (event: React.MouseEvent<HTMLAnchorElement>) => {
      event.preventDefault();

      if (onClickToCustomer) {
        onClickToCustomer();
      }
    },
    [onClickToCustomer],
  );

  const customerComponent = useMemo(() => {
    if (!shipmentData?.customer?.companyName && !shipmentData?.customer?.name && !shipmentData?.customer?.email) {
      return (
        <SCDescriptions
          title={t('InformationIsMissing')}
          content={<ShipmentCardSectionLinkById onClick={onClickToCustomerHandler}>+ {t('addCustomer')}</ShipmentCardSectionLinkById>}
        />
      );
    }

    return (
      <SCDescriptions
        title={shipmentData.customer?.companyName}
        content={shipmentData.customer.name}
        footer={[shipmentData.customer?.email || '']}
      />
    );
  }, [shipmentData?.customer, shipmentData?.destination]);

  const bookingStatus = useMemo(() => {
    if (shipmentData?.bookingStatus === ShipmentBookingStatusEnum.BOOKING_AMENDMENT_SUBMITTED || shipmentData?.bookingStatus === ShipmentBookingStatusEnum.BOOKING_DECLINED || shipmentData?.bookingStatus === ShipmentBookingStatusEnum.BOOKING_AMENDMENT_DECLINED) {
      return ShipmentBookingStatusNames[shipmentData.bookingStatus];
    }

    return null;
  }, [shipmentData?.bookingStatus]);

  const transactionStatus = useMemo(() => {
    if (shipmentData?.transactionStatus?.displayValue) {
      return shipmentData?.transactionStatus.displayValue;
    }

    return null;
  }, [shipmentData?.transactionStatus?.displayValue]);

  const bookingStatusColorText = useMemo(() => {
    if (shipmentData?.transactionStatus?.displayValue) {
      return themesColors.secondaryDot45;
    }

    if (shipmentData?.bookingStatus) {
      return ShipmentBookingStatusColors[shipmentData.bookingStatus];
    }

    return themesColors.neutralBranding10;
  }, [shipmentData?.transactionStatus?.displayValue, shipmentData?.bookingStatus]);

  const originDate = useMemo(() => {
    if (shipmentData?.origin?.realDate) {
      return [shipmentData.origin?.realDate ? `${t('ATD')} ${dateFormatETA(shipmentData.origin?.realDate?.getDateAsMomentWithOffset().format())}` : ''];
    }

    if (shipmentData?.origin?.estimatedDate) {
      return [shipmentData.origin?.estimatedDate ? `${t('ETD')} ${dateFormatETA(shipmentData.origin?.estimatedDate?.getDateAsMomentWithOffset().format())}` : ''];
    }

    return [];
  }, [shipmentData?.origin?.realDate, shipmentData?.origin?.estimatedDate]);

  const destinationDate = useMemo(() => {
    if (shipmentData?.destination?.realDate) {
      return [shipmentData.destination?.realDate?.getDateAsMomentWithOffset() ? `${t('ATA')} ${dateFormatETA(shipmentData.destination?.realDate?.getDateAsMomentWithOffset().format())}` : ''];
    }

    if (shipmentData?.destination?.estimatedDate) {
      return [shipmentData.destination?.estimatedDate ? `${t('ETA')} ${dateFormatETA(shipmentData.destination?.estimatedDate?.getDateAsMomentWithOffset().format())}` : ''];
    }

    return [];
  }, [shipmentData?.destination?.realDate, shipmentData?.destination?.estimatedDate]);

  const disableOnClick = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      e.preventDefault();
    },
    [],
  );

  const onChangeIsOpenModalShipmentReferenceHandle = useCallback(
    () => {
      if (onChangeIsOpenModalShipmentReference) {
        onChangeIsOpenModalShipmentReference(true);
      }
    },
    [onChangeIsOpenModalShipmentReference],
  );

  const isShipmentAllAlerts = useMemo(() => shipmentStats?.getShipmentAllAlerts() && Number(shipmentStats?.getShipmentAllAlerts()) > 0, [shipmentStats]);

  return (shipmentData ? (
    <ShipmentCardContainer
      data-cy="shipmentListCard"
      className="ant-card-hoverable"
      $isClickable={$isClickable}
      onClick={!$isClickable ? disableOnClick : undefined}
      to={$isClickable ? RouteNames.SHIPMENT_OVERVIEW(shipmentData.id) : ''}
    >
      <ModalShipmentReferenceUpdate />
      <ShipmentCardSectionShipment
        $isClickable={$isClickable}
      >
        <SCDescriptions
          title={isReference ? null : undefined}
          content={shipmentData.shipmentName || ''}
          footer={isReference && (
            <ShipmentCardSectionShipmentReferenceWrap isReferenceHeader={isReferenceHeader}>
              <ShipmentCardSectionShipmentReferenceText isReferenceHeader={isReferenceHeader}>
                {shipmentData.reference || ''}
                {isReferenceHeader && (
                  <ShipmentCardSectionShipmentReferenceIcon
                    onClick={onChangeIsOpenModalShipmentReferenceHandle}
                  >
                    {shipmentData.reference && <EditOutlined />}
                  </ShipmentCardSectionShipmentReferenceIcon>
                )}
              </ShipmentCardSectionShipmentReferenceText>
            </ShipmentCardSectionShipmentReferenceWrap>
          )}
          isVerticalCenter
        />
      </ShipmentCardSectionShipment>

      <ShipmentCardSectionVerticalDivider />

      <ShipmentCardSectionOrigin>
        <ShipmentCardSectionIcon>
          <FreightTypeLocationIconComponent type={shipmentData.origin.type} />
        </ShipmentCardSectionIcon>
        <SCDescriptions
          title={shipmentData.origin.name || ''}
          content={shipmentData.origin.address}
          footer={originDate}
        />
      </ShipmentCardSectionOrigin>

      <ShipmentCardSectionVerticalDividerIcon>
        <VerticalFormItemSvg
          style={{
            transform: 'rotate(-90deg)',
            position: 'relative',
            top: '-3px',
            color: themesColors.characterBrandingDisabled025,
          }}
        />
      </ShipmentCardSectionVerticalDividerIcon>

      <ShipmentCardSectionDestination>
        <ShipmentCardSectionIcon>
          <FreightTypeLocationIconComponent type={shipmentData.destination?.type} />
        </ShipmentCardSectionIcon>
        <SCDescriptions
          title={shipmentData.destination.name || ''}
          content={shipmentData.destination.address}
          footer={destinationDate}
        />
      </ShipmentCardSectionDestination>

      <ShipmentCardSectionVerticalDivider />

      <ShipmentCardSectionCustomer>
        <ShipmentCardSectionIcon>
          <UserPersonSvg fill={themesColors.neutralBranding6} />
        </ShipmentCardSectionIcon>
        {customerComponent}
      </ShipmentCardSectionCustomer>

      <ShipmentCardSectionVerticalDivider />

      <ShipmentCardSectionCarrier>
        <ShipmentCardSectionIcon>
          <RoadShipSvg fill={themesColors.neutralBranding6} />
        </ShipmentCardSectionIcon>
        <SCDescriptions
          title={(
            <ShipmentListSpan>
              {shipmentData.mainVesselName?.toLocaleLowerCase() || `${t('Vessel')}: ${t('TBD')}`}
            </ShipmentListSpan>
          )}
          content={shipmentData.carrier}
          footer={[`${t('Ref')}: ${shipmentData.carrierReferenceNumber || t('TBD')}`]}
        />
      </ShipmentCardSectionCarrier>

      <ShipmentCardSectionVerticalDivider />

      <ShipmentCardSectionTasks>
        {isShipmentAllAlerts ? (
          <ShipmentCardSectionTasksIcon colorSvg={themesColors.characterBrandingDanger}>
            <Tooltip
              className="ShipmentCardSectionTasksTooltip"
              placement="top"
              isGetPopupContainerDefault
              title={(
                <>
                  {t('Critical tasks/alerts')}: {shipmentStats?.getShipmentAllAlertsTasks() || 0}
                  <br />
                  {t('Notifications')}: {shipmentStats?.notificationStats.unreadCritical || 0}
                </>
              )}
            >
              <ExclamationCircleFilled />
            </Tooltip>
          </ShipmentCardSectionTasksIcon>
        )
          : null}
      </ShipmentCardSectionTasks>

      <ShipmentCardSectionVerticalDivider />
      <ShipmentCardSectionStatus>
        <ShipmentCardSectionStatusTitle>
          <StatusLight colorIcon={ShipmentStatusColors[shipmentData.status] || themesColors.secondaryDot45} />
          <ShipmentCardSectionStatusTitleSpan>
            {ShipmentStatusNames[shipmentData.status]}
          </ShipmentCardSectionStatusTitleSpan>
        </ShipmentCardSectionStatusTitle>
        <ShipmentCardSectionStatusDescription colorText={bookingStatusColorText}>
          {transactionStatus}
          {/* temporarily hidden */}
          {false && bookingStatus}
        </ShipmentCardSectionStatusDescription>
      </ShipmentCardSectionStatus>

    </ShipmentCardContainer>
  ) : (
    <ShipmentListLoadingCardStyled loading />
  ));
};
