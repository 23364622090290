import React from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';
import { UC } from 'shipment-operations/controllers';

import { ShipmentPeopleComponent } from './ShipmentPeople.component';

export const ShipmentPeople = () => {
  const isLoading = useSelector(R.selectors.shipmentPeople.getIsLoading);
  const workgroups = useSelector(R.selectors.shipmentPeople.getWorkgroups);
  const currentOrganizationId = useSelector(R.selectors.shipmentPeople.getCurrentOrganizationId);
  const permissions = useSelector(R.selectors.shipment.getWorkgroupPermissions);

  return (
    <ShipmentPeopleComponent
      isLoading={isLoading}
      workgroups={workgroups}
      init={UC.shipmentPeople.init}
      openAddMemberDrawer={UC.shipmentPeople.openAddMemberDrawer}
      currentOrganizationId={currentOrganizationId}
      removeMember={UC.shipmentPeople.removeWorkgroupMember}
      companyNameVisibility={permissions.companyNameVisibility}
    />
  );
};
