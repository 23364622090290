import { ValidationErrorType, ValidationErrorDTM } from 'app-wrapper/types';

const EXPORT_CLEARANCE_NUMBER_FORMAT = /^X[0-9]{14}/;

export const validateRequiredField = (value?: unknown) => {
  if (!value) {
    return ValidationErrorDTM.fromPlain({
      type: ValidationErrorType.DEFAULT,
      message: 'basicErrors.REQUIRED_MESSAGE',
    });
  }

  return undefined;
};

export const validateExportClearanceNumber = (exportClearanceNumber?: string) => {
  const matchResult = exportClearanceNumber?.match(EXPORT_CLEARANCE_NUMBER_FORMAT);

  if (!matchResult) {
    return ValidationErrorDTM.fromPlain({
      type: ValidationErrorType.ALERT,
      message: 'shipmentContainerErrors.INVALID_EXPORT_ClEARANCE_NUMBER_MESSAGE',
    });
  }

  return undefined;
};
