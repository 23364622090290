import React from 'react';
import isEmpty from 'lodash/fp/isEmpty';

import { useAppSelector } from 'app-wrapper/hooks';
import { R } from 'shipment-operations/repository';
import { UC } from 'shipment-operations/controllers';

import { CreateCreditNoteComponent } from './CreateCreditNote.component';

const CreateCreditNoteContainer = () => {
  const selected = useAppSelector(R.selectors.shipmentBillingInvoice.getSelectedItems);

  return (
    <CreateCreditNoteComponent
      onClose={UC.shipmentBillingInvoice.closeCreateCreditNote}
      showFooter={!isEmpty(selected)}
      setListUpdatedItems={UC.shipmentBillingInvoice.setListUpdatedItems}
      clearUpdatedItems={UC.shipmentBillingInvoice.clearUpdatedItems}
    />
  );
};

export { CreateCreditNoteContainer as CreateCreditNote };
