import {
  IsDefined, IsNumber, IsString, IsOptional,
} from 'class-validator';

type Address = {
  id: number
  country?: string
  state?: string
  city?: string
  address1: string
  address2?: string
  postalCode?: string
  closestPort?: string
};

export class AddressDTM implements Address {
  @IsNumber()
  @IsDefined()
  id: number;

  @IsString()
  @IsOptional()
  country?: string;

  @IsString()
  @IsOptional()
  state?: string;

  @IsString()
  @IsOptional()
  city?: string;

  @IsString()
  @IsDefined()
  address1: string;

  @IsString()
  @IsOptional()
  address2?: string;

  @IsString()
  @IsOptional()
  postalCode?: string;

  @IsString()
  @IsOptional()
  closestPort?: string;
}

export type GeoParsedAddress = {
  postalCode?: string
  country?: string
  city?: string
  streetNumber: string
  route: string
  state: string
};

export default Address;
