import { createSelector } from 'reselect';
import { RootState } from 'app-wrapper/store';

import { PaymentTableDTM } from 'monetary/models/dtm';

const localState = (state: RootState) => state.payments;

const getIsLoading = createSelector(
  localState,
  (state) => state.isLoading,
);

const getIsError = createSelector(
  localState,
  (state) => state.isError,
);

const getData = createSelector(
  localState,
  (state) => state.data,
);

const getDataForTable = createSelector(
  getData,
  (data) => data.map((item) => (PaymentTableDTM.fromPlain({
    id: item.id,
    key: item.id,
    number: item.number,
    postedDate: item.createdAt,
    vendorName: item.creditor?.name ? item.creditor.name : '',
    debtorName: item.debtor?.name ? item.debtor.name : '',
    status: item.status,
    total: item.amount,
    totalLeft: item.balance,
  }))),
);

const getTotalElements = createSelector(
  localState,
  (state) => state.totalElements,
);

const getActivePage = createSelector(
  localState,
  (state) => state.activePage,
);

const getFirstTimeLoading = createSelector(
  localState,
  (state) => state.firstTimeLoading,
);

export const paymentsSelectors = {
  getIsLoading,
  getIsError,
  getDataForTable,
  getTotalElements,
  getActivePage,
  getFirstTimeLoading,
};
