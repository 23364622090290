import React, { FC, useCallback } from 'react';

import { MAX_LENGTH_50 } from 'app-wrapper/constants';

import { StyledInput } from './AddressLine1Input.styled';

interface ITaxIDInputComponentProps {
  addressLine?: string;
  setAddressLine: (taxId: string) => void;
  error?: boolean;
}

export const AddressLine1InputComponent: FC<ITaxIDInputComponentProps> = ({
  addressLine,
  setAddressLine,
  error,
}) => {
  const handleChange = useCallback((e) => {
    setAddressLine(e.target.value);
  }, [setAddressLine]);

  return (
    <StyledInput
      value={addressLine}
      onChange={handleChange}
      maxLength={MAX_LENGTH_50}
      error={error && !addressLine?.length}
    />
  );
};
