import React from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';

import { StepsHeaderComponent } from './StepsHeader.component';

const StepsHeaderContainer = () => {
  const isSecondStep = useSelector(R.selectors.bookingWizard.getIsSecondStep);
  const isCustomerOrg = useSelector(R.selectors.bookingWizard.getIsCurrentOrganizationCustomer);

  return (
    <StepsHeaderComponent isSecondStep={isSecondStep} isCustomerOrg={isCustomerOrg} />
  );
};

export { StepsHeaderContainer as StepsHeader };
