import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';
import { UC } from 'shipment-operations/controllers';

import { ShipmentContainersHeaderComponent } from './ShipmentContainersHeader.component';

const ShipmentContainersHeaderContainer: FC = () => {
  const isLoading = useSelector(R.selectors.shipmentContainers.getLoadingState);
  const containerBasicType = useSelector(R.selectors.shipment.getContainersBasicType);
  const disable = useSelector(R.selectors.shipment.getIsDisableChanges);

  return (
    <ShipmentContainersHeaderComponent
      isLoading={isLoading}
      containerBasicType={containerBasicType}
      fetchTemperatureControlData={UC.temperatureControl.fetchTemperatureControl}
      clearTemperatureControlData={UC.temperatureControl.clear}
      disable={disable}
    />
  );
};

export {
  ShipmentContainersHeaderContainer as ShipmentContainersHeader,
};
