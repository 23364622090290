import styled from 'styled-components';

export const Textarea = styled.textarea`
  padding-left: 12px;
  padding-top: 5px;
  border-color: rgba(222, 225, 233, 1);
  width: 100%;
  min-height: 70px;
  font-size: 14px;
  line-height: 22px;
  color: #202C3C;
  outline: none;
  
  &::placeholder {
    color: rgba(115, 129, 155, 1);
  }
`;
