import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';

import { WeightInputComponent } from './WeightInput.component';

interface WeightInputContainerProps {
  cargoId: number;
}

const WeightInputContainer: FC<WeightInputContainerProps> = ({ cargoId }) => {
  const { weight = '', errors } = useSelector(R.selectors.manualBookingWizard.getCargoById(cargoId));
  const { weight: error } = errors;

  return (
    <WeightInputComponent
      weight={weight}
      error={error}
    />
  );
};

export { WeightInputContainer as WeightInput };
