import React, { FC, useCallback, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import Spin from 'antd/es/spin';

import { RouteNames } from 'app-wrapper/constants';
import {
  BackTitleIcon,
  EStatusType,
  SpinnerWrap,
  Status,
} from 'app-wrapper/view/guideline';
import { AdditionalServiceDTM } from 'shipment-operations/models/dtm';

import { ServiceWorkflow, WorkflowDetails } from './components';
import {
  Page,
  Content,
  PageHeader,
  PageHeaderTitle,
  PageHeaderRightRow,
} from './ShipmentAdditionalService.styled';

interface IShipmentAdditionalServiceProps {
  isLoading?: boolean;
  service?: AdditionalServiceDTM;
  init: (shipmentId: string, serviceId: string) => void;
  statusType: EStatusType;
  statusText: string;
}

export const ShipmentAdditionalService: FC<IShipmentAdditionalServiceProps> = ({
  isLoading,
  service,
  init,
  statusType,
  statusText,
}) => {
  const { shipmentId = '', serviceId = '' } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    init(shipmentId, serviceId);
  }, []);

  const handleGoBack = useCallback(() => navigate(RouteNames.SHIPMENT_ADDITIONAL_SERVICES(shipmentId)), [navigate, shipmentId]);

  if (isLoading || !service) {
    return (
      <SpinnerWrap>
        <Spin size="large" />
      </SpinnerWrap>
    );
  }

  return (
    <Page>
      <PageHeader>
        <PageHeaderTitle onClick={handleGoBack}>
          <BackTitleIcon />

          {service?.name}
        </PageHeaderTitle>

        <PageHeaderRightRow>
          <Status text={statusText} type={statusType} />
        </PageHeaderRightRow>
      </PageHeader>

      <Content>
        <WorkflowDetails />
        <ServiceWorkflow />
      </Content>
    </Page>
  );
};
