import toNumber from 'lodash/fp/toNumber';

import { ValidationErrorDTM, ValidationErrorType } from 'app-wrapper/types';

import { DocumentsDistributionDTM, DocumentsDistributionErrorsDTM } from 'shipment-operations/models/dtm';

export const validateRequiredField = (value?: unknown) => {
  if (!value) {
    return {
      type: ValidationErrorType.DEFAULT,
      message: 'basicErrors.REQUIRED_MESSAGE',
    };
  }

  return undefined;
};

export const validateOriginals = (distribution: DocumentsDistributionDTM[]) => {
  const totalAmount = distribution.reduce((result, { amount }) => {
    const amountAsNumber = toNumber(amount);

    if (amountAsNumber) {
      return result + amountAsNumber;
    }

    return result;
  }, 0);

  if (totalAmount > 3) {
    return distribution.map(() => (DocumentsDistributionErrorsDTM.fromPlain({
      amount: {
        type: ValidationErrorType.ALERT,
        message: 'shipmentContainerErrors.TOO_MUCH_ORIGINALS_MESSAGE',
      },
    })));
  }

  const errors: DocumentsDistributionErrorsDTM[] = [];

  distribution.forEach(({ party, amount }, i) => {
    const error = DocumentsDistributionErrorsDTM.fromPlain({});

    if (!!party && !amount) {
      error.amount = ValidationErrorDTM.fromPlain({
        type: ValidationErrorType.DEFAULT,
        message: 'shipmentContainerErrors.REFERENCE_TYPE_REQUIRED_MESSAGE',
      });
    }

    if (!party) {
      error.party = ValidationErrorDTM.fromPlain({
        type: ValidationErrorType.DEFAULT,
        message: 'shipmentContainerErrors.REFERENCE_VALUE_REQUIRED_MESSAGE',
      });
    }

    if (!!amount && (toNumber(amount) < 1 || toNumber(amount) > 3)) {
      error.amount = ValidationErrorDTM.fromPlain({
        type: ValidationErrorType.DEFAULT,
        message: 'shipmentContainerErrors.TOO_MUCH_ORIGINALS_MESSAGE',
      });
    }

    if (error.party || error.amount) {
      errors[i] = error;
    }
  });

  return errors;
};

export const validateCopies = (distribution: DocumentsDistributionDTM[]) => {
  const totalAmount = distribution.reduce((result, { amount }) => {
    const amountAsNumber = toNumber(amount);
    if (amountAsNumber) {
      return result + amountAsNumber;
    }

    return result;
  }, 0);

  if (totalAmount > 3) {
    return distribution.map(() => (DocumentsDistributionErrorsDTM.fromPlain({
      amount: {
        type: ValidationErrorType.ALERT,
        message: 'shipmentContainerErrors.TOO_MUCH_COPIES_MESSAGE',
      },
    })));
  }

  const errors: DocumentsDistributionErrorsDTM[] = [];

  distribution.forEach(({ party, amount }, i) => {
    const error = DocumentsDistributionErrorsDTM.fromPlain({});

    if (!!party && !amount) {
      error.amount = ValidationErrorDTM.fromPlain({
        type: ValidationErrorType.DEFAULT,
        message: 'shipmentContainerErrors.REFERENCE_TYPE_REQUIRED_MESSAGE',
      });
    }

    if (!party) {
      error.party = ValidationErrorDTM.fromPlain({
        type: ValidationErrorType.DEFAULT,
        message: 'shipmentContainerErrors.REFERENCE_VALUE_REQUIRED_MESSAGE',
      });
    }

    if (!!amount && (toNumber(amount) < 1 || toNumber(amount) > 3)) {
      error.amount = ValidationErrorDTM.fromPlain({
        type: ValidationErrorType.DEFAULT,
        message: 'shipmentContainerErrors.TOO_MUCH_COPIES_MESSAGE',
      });
    }

    if (error.amount || error.party) {
      errors[i] = error;
    }
  });

  return errors;
};
