import { Type } from 'class-transformer';
import {
  IsDefined,
  IsEnum,
  IsNumber, IsOptional,
  ValidateNested,
} from 'class-validator';
import { BaseDTM } from 'proto/BaseDTM';

import { EAccountDepartmentType } from 'user-management/constants';
import {
  ITeamDTM,
  TeamDTM,
  TOrganizationMemberDTM,
  OrganizationMemberDTM,
} from 'user-management/models/dtm';

export interface ITeamMemberDTM {
  id: number;
  team?: ITeamDTM;
  member?: TOrganizationMemberDTM;
  role: `${EAccountDepartmentType}`;
}

export class TeamMemberDTM extends BaseDTM<ITeamMemberDTM> {
  @IsDefined()
  @IsNumber()
  id: number;

  @IsOptional()
  @ValidateNested()
  @Type(() => TeamDTM)
  team?: TeamDTM;

  @IsOptional()
  @ValidateNested()
  @Type(() => OrganizationMemberDTM)
  member?: OrganizationMemberDTM;

  @IsDefined()
  @IsEnum(EAccountDepartmentType)
  role: EAccountDepartmentType;
}
