import styled from 'styled-components';

import { Button as ComponentButton } from 'app-wrapper/view/components';

export const Container = styled.div`
  position: absolute;
  z-index: 3;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 64px;
  padding: 12px 24px;
  background: #FFFFFF;
  box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px rgba(0, 0, 0, 0.08), 0px 9px 28px 8px rgba(0, 0, 0, 0.05);
`;

export const ContainerWrap = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

export const Button = styled(ComponentButton)`
  height: 40px;
`;
