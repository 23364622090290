import styled from 'styled-components';
import AntDropdown from 'antd/es/dropdown';
import Menu from 'antd/es/menu';

import { ActionMenuIcon } from '../ActionMenuIcon';

export const Dropdown = styled(AntDropdown)``;

export const MenuItem = styled(Menu.Item)`
  min-width: 143px;
`;

export const MenuRemoveItem = styled(MenuItem)`
  color: rgba(255, 77, 79, 1);
`;

export const Icon = styled(ActionMenuIcon)`
  cursor: pointer;
`;
