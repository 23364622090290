import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Field, FieldLabel } from 'app-wrapper/view/guideline';

import { Input } from './ContractNumber.styled';

interface IContractNumberComponentProps {
  contractNumber?: string;
  setContractNumber: (contractNumber: string) => void;
}

export const ContractNumberComponent: FC<IContractNumberComponentProps> = ({
  contractNumber,
  setContractNumber,
}) => {
  const { t } = useTranslation();

  const handleChange = useCallback((e) => {
    setContractNumber(e.target.value);
  }, [setContractNumber]);

  return (
    <Field>
      <FieldLabel>
        {t('Contract Number')}
      </FieldLabel>

      <Input
        value={contractNumber}
        onChange={handleChange}
      />
    </Field>
  );
};
