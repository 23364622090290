import React from 'react';
import i18n from 'app-wrapper/i18n/i18n';

import {
  Wrap,
  Card,
  Image,
  Title,
  Text,
} from './InformationCards.styled';
import scheduleImageSrc from './assets/schedule-image.png';
import bookImageSrc from './assets/book-image.png';
import trackImageSrc from './assets/track-image.png';
import creditImageSrc from './assets/credit-image.png';

const CARDS_ITEMS = [
  {
    image: scheduleImageSrc,
    title: i18n.t('Schedule Smart & Quote Instantly'),
    text: i18n.t('Access instant quotes with just a few clicks'),
  },
  {
    image: bookImageSrc,
    title: i18n.t('Booking & Shipment Management'),
    text: i18n.t('Book your shipments precisely on our easy-to-use platform'),
  },
  {
    image: trackImageSrc,
    title: i18n.t('Track & Trace'),
    text: i18n.t('Stay updated with the latest status of your shipments.'),
  },
  {
    image: creditImageSrc,
    title: i18n.t('Meet your support team!'),
    text: i18n.t('Experience our customer-first approach with Skypace\'s dedicated support team.'),
  },
];

export const InformationCards = () => (
  <Wrap>
    {CARDS_ITEMS.map(({ image, title, text }) => (
      <Card key={image}>
        <Image src={image} />

        <Title>
          {title}
        </Title>

        <Text>
          {text}
        </Text>
      </Card>
    ))}
  </Wrap>
);
