import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { R as RApp } from 'app-wrapper/repository';

import { UC } from 'user-management/controllers';
import { R } from 'user-management/repository';
import { ContactsAddressBlockComponent } from 'user-management/view/components';
import { ICompanyAddressDTM } from 'user-management/models/dtm';

interface IAddressesAndContactsEditAddressAddressItemContainerProps {
  largeInput: boolean
  addressIndex: number
  companyListStateIndex: string
  isFirst: boolean
  isLast: boolean
  primaryComponent?: React.ReactNode
  address?: ICompanyAddressDTM
}

const AddressesAndContactsEditAddressAddressItemContainer: FC<IAddressesAndContactsEditAddressAddressItemContainerProps> = (props) => {
  const {
    largeInput,
    addressIndex,
    companyListStateIndex,
    isFirst,
    isLast,
    primaryComponent,
    address,
  } = props;

  const countriesList = useSelector(RApp.selectors.countriesList.getCountriesList);

  const errors = useSelector(R.selectors.contactBook.getContactBookUpdateErrorsAddressesById(address?.customId || ''));

  return (
    <ContactsAddressBlockComponent
      largeInput={largeInput}
      countriesList={countriesList}
      isFirst={isFirst}
      isLast={isLast}
      address={address}
      addressIndex={addressIndex}
      primaryComponent={primaryComponent}
      companyListStateIndex={companyListStateIndex}
      errors={errors}
      onChangeAddressLineFirst={UC.contactBook.onChangeUpdateAddressesAddressLineFirstByIndex}
      onBlurAddressLineFirst={UC.contactBook.onBlurUpdateAddressesAddressLineFirstByIndex}
      onFocusAddressLineFirst={UC.contactBook.onFocusUpdateAddressesAddressLineFirstByIndex}
      onChangeAddressLineSecond={UC.contactBook.onChangeUpdateAddressesAddressLineSecondByIndex}
      onBlurAddressLineSecond={UC.contactBook.onBlurUpdateAddressesAddressLineSecondByIndex}
      onFocusAddressLineSecond={UC.contactBook.onFocusUpdateAddressesAddressLineSecondByIndex}
      onChangeCity={UC.contactBook.onChangeUpdateAddressesCityByIndex}
      onBlurCity={UC.contactBook.onBlurUpdateAddressesCityByIndex}
      onFocusCity={UC.contactBook.onFocusUpdateAddressesCityByIndex}
      onChangeCountry={UC.contactBook.onChangeUpdateAddressesCountryByIndex}
      onBlurCountry={UC.contactBook.onBlurUpdateAddressesCountryByIndex}
      onFocusCountry={UC.contactBook.onFocusUpdateAddressesCountryByIndex}
      onClearCountry={UC.contactBook.onClearUpdateAddressesCountryByIndex}
      onChangeState={UC.contactBook.onChangeUpdateAddressesStateByIndex}
      onClearState={UC.contactBook.onClearUpdateAddressesStateByIndex}
      onBlurState={UC.contactBook.onBlurUpdateAddressesStateByIndex}
      onFocusState={UC.contactBook.onFocusUpdateAddressesStateByIndex}
      onChangePostalCode={UC.contactBook.onChangeUpdateAddressesPostalCodeByIndex}
      onBlurPostalCode={UC.contactBook.onBlurUpdateAddressesPostalCodeByIndex}
      onFocusPostalCode={UC.contactBook.onFocusUpdateAddressesPostalCodeByIndex}
      addAddressItem={UC.contactBook.setUpdateAddCompanyListStateAddressItem}
      onRemoveAddress={UC.contactBook.setUpdateRemoveCompanyListStateAddressItem}
    />
  );
};

export { AddressesAndContactsEditAddressAddressItemContainer as AddressesAndContactsEditAddressAddressItem };
