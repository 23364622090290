import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Moment } from 'moment';
import Skeleton from 'antd/es/skeleton';

import { ContainerDTM } from 'shipment-operations/models/dtm';

import {
  Section,
  Row,
  SectionTitle,
  DatePickerLabel,
  DatePickerWrapper,
  DatePickerStyled,
  HeaderContent,
  HeaderText,
  Content,
  ContentRow,
  Warning,
  WarningText,
  WarningIcon,
} from './ContainersDetails.styled';
import {
  ContainerName,
  ContainerNumberInput,
  SealNumberInput,
  SOCDocumentUpload,
} from './components';

interface ContainersDetailsComponentProps {
  isPending: boolean;
  hasSOC: boolean;
  date?: Moment;
  onChange: (value: Moment | null) => void;
  containers: ContainerDTM[];
  error: string;
}

export const ContainersDetailsComponent: FC<ContainersDetailsComponentProps> = ({
  date,
  hasSOC,
  onChange,
  isPending,
  containers,
  error,
}) => {
  const { t } = useTranslation();

  return isPending ? (
    <Skeleton />
  ) : (
    <>
      {error ? (
        <Warning>
          <WarningIcon />

          <WarningText>
            {error}
          </WarningText>
        </Warning>
      ) : null}

      <Section>
        <Row
          style={{
            paddingBottom: hasSOC ? 3 : 0,
            height: 30,
          }}
        >
          <SectionTitle>
            {t('Containers Details')}
          </SectionTitle>

          <DatePickerWrapper>
            <DatePickerLabel>
              {t('Empty Release Date')}
            </DatePickerLabel>

            <DatePickerStyled
              value={date}
              onChange={onChange}
            />
          </DatePickerWrapper>
        </Row>

        {hasSOC ? (
          <>
            <HeaderContent>
              <HeaderText>
                {t('Container Type')}
              </HeaderText>
              <HeaderText>
                {t('Container Number *')}
              </HeaderText>
              <HeaderText>
                {t('Seal Number')}
              </HeaderText>
              <HeaderText>
                {t('Seaworthiness Certificate *')}
              </HeaderText>
            </HeaderContent>

            <Content>
              {containers.map((container) => (
                <ContentRow key={`ContainersDetailsComponent_${container.id}`}>
                  <ContainerName containerId={container.id} />
                  <ContainerNumberInput containerId={container.id} />
                  <SealNumberInput containerId={container.id} />
                  <SOCDocumentUpload containerId={container.id} />
                </ContentRow>
              ))}
            </Content>
          </>
        ) : null}
      </Section>
    </>
  );
};
