import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { R } from 'user-management/repository';
import { UC } from 'user-management/controllers';

import { UserOrganizationComponent } from './UserOrganization.component';

const UserOrganizationContainer: FC = () => {
  const organization = useSelector(R.selectors.userOrganizationData.getUserOrganization);

  return (
    <UserOrganizationComponent
      organization={organization}
      onGoToWizard={UC.newOrganizationWizard.goToWizard}
    />
  );
};

export { UserOrganizationContainer as UserOrganization };
