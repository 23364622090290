import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';

import { SBLHazmatTableComponent } from './SBLHazmatTable.component';

const SBLHazmatTableContainer = () => {
  const cargos = useSelector(R.selectors.billOfLadingCommon.getCargos);

  const hazmatCargos = useMemo(() => cargos.filter((cargo) => cargo.isHazmat), [cargos]);

  return (
    <SBLHazmatTableComponent cargos={hazmatCargos} />
  );
};

export { SBLHazmatTableContainer as SBLHazmatTable };
