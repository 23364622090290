import {
  IsOptional,
  ValidateNested, IsDefined, IsNumber,
} from 'class-validator';
import { plainToInstance, Type } from 'class-transformer';

import { IValidationErrorDTM, ValidationErrorDTM } from 'app-wrapper/types';
import { BaseDTM } from 'proto/BaseDTM';

interface IManualBookingTransportationErrorsDTM {
  transportationId: number;
  etd?: IValidationErrorDTM;
  eta?: IValidationErrorDTM;
}

export class ManualBookingTransportationErrorsDTM extends BaseDTM<IManualBookingTransportationErrorsDTM> {
  @IsDefined()
  @IsNumber()
  transportationId: number;

  @IsOptional()
  @ValidateNested()
  @Type(() => ValidationErrorDTM)
  etd?: ValidationErrorDTM;

  @IsOptional()
  @ValidateNested()
  @Type(() => ValidationErrorDTM)
  eta?: ValidationErrorDTM;

  public hasErrors = () => this.etd
    || this.eta;

  static createEmpty = (transportationId: number) => plainToInstance(ManualBookingTransportationErrorsDTM, { transportationId, etd: undefined, eta: undefined });
}

interface IManualBookingErrorsDTM {
  carrierReferenceNumber?: IValidationErrorDTM;
  confirmationDocument?: IValidationErrorDTM;
}

export class ManualBookingErrorsDTM extends BaseDTM<IManualBookingErrorsDTM> {
  @IsOptional()
  @ValidateNested()
  @Type(() => ValidationErrorDTM)
  carrierReferenceNumber?: ValidationErrorDTM;

  @IsOptional()
  @ValidateNested()
  @Type(() => ValidationErrorDTM)
  confirmationDocument?: ValidationErrorDTM;

  public hasErrors = () => this.carrierReferenceNumber || this.confirmationDocument;

  static createEmpty = () => plainToInstance(ManualBookingErrorsDTM, {});
}
