import React, {
  FC, memo, useCallback, useRef, useState,
} from 'react';

import { Collapse, Panel } from 'app-wrapper/view/components/Collapse';
import { ExpandIcon } from 'app-wrapper/view/components/ExpandIcon';
import { CollapseOnChangeHandler } from 'app-wrapper/hooks';
import themesColors from 'app-wrapper/view/themes/themesColors';

import { IDetailAccessorialsDTM } from 'monetary/models/dtm';

import {
  AccessorialsTabItemRowItemWrapper,
} from './AccessorialsTabItem.styled';
import { GetStepGetStepAccessorialsTabItemComponentComponent } from './components';

const AccessorialsTabItem: FC<IDetailAccessorialsDTM> = ({
  fieldName, accessorials, penalties, isLast,
}) => {
  const endVisible = useRef<HTMLElement>(null);
  const [
    changeCollapseState, setChangeCollapseState,
  ] = useState<{ [key: string]: string[] }>({ [fieldName]: [] });

  const onExpandIcon = useCallback(
    ({ isActive }) => ExpandIcon({
      isActive,
      color: `${isActive ? themesColors.primaryBranding6 : themesColors.neutralBranding7}`,
    }),
    [],
  );

  const onCollapseChangeHandler = useCallback(
    (name: string) => CollapseOnChangeHandler(
      endVisible.current, () => {
        setChangeCollapseState((prev) => ({
          ...prev,
          [name]: prev?.[name]?.length ? [] : [name],
        }
        ));
      },
    ),
    [],
  );

  return (
    <AccessorialsTabItemRowItemWrapper borderShow={!isLast}>
      <Collapse
        expandIcon={onExpandIcon}
        onChange={onCollapseChangeHandler(fieldName)}
        activeKey={changeCollapseState[fieldName]}
      >
        <Panel
          header={fieldName}
          key={fieldName}
        >
          <GetStepGetStepAccessorialsTabItemComponentComponent
            fieldName={fieldName}
            nameComponent="Accessorials"
            dataComponent={accessorials}
          />
          <GetStepGetStepAccessorialsTabItemComponentComponent
            fieldName={fieldName}
            nameComponent="Penalties"
            dataComponent={penalties}
          />
        </Panel>
      </Collapse>
    </AccessorialsTabItemRowItemWrapper>
  );
};

const CashedAccessorialsTabItem = memo(AccessorialsTabItem);

export {
  CashedAccessorialsTabItem as AccessorialsTabItem,
};
