import React, { FC } from 'react';
import { useTranslation, TFunction } from 'react-i18next';

import { CargoDTM, ContainerCargoShortItemDTM } from 'shipment-operations/models/dtm';

import { SBLGoodsTable, SBLHazmatTable, SBLContainersTable } from './components';
import {
  SBLCargoLayout,
  SBLCargoWarningWrap,
  SBLWarningIcon,
  SBLCargoWarningTitle,
  SBLCargoWarningText,
  SBLCargoWarningColumn,
} from './SBLCargoTables.styled';

const getCargoWarningMessage = (cargos: CargoDTM[], allCargoItems: ContainerCargoShortItemDTM[], t: TFunction<'translation'>): string => {
  let warningMessage = '';

  const cargoSum = cargos.reduce((prev, next) => ({
    volume: prev.volume + Number(next.volume || 0),
    weight: prev.weight + Number(next.weight || 0),
    packagesNumber: prev.packagesNumber + Number(next.packagesNumber || 0),
  }), {
    volume: 0,
    weight: 0,
    packagesNumber: 0,
  });

  const cargoItemSum = allCargoItems.reduce((prev, next) => ({
    volume: +prev.volume + +next.volume,
    weight: +prev.weight + +next.weight,
    packagesNumber: +prev.packagesNumber + +next.packagesNumber,
  }), {
    volume: 0,
    weight: 0,
    packagesNumber: 0,
  });

  if (cargoSum.weight !== cargoItemSum.weight) {
    warningMessage = `${warningMessage}${t('warningMessageCargoTotalWeight', { itemWeight: cargoItemSum.weight, cargoWeight: cargoSum.weight })}
    `;
  }

  if (cargoSum.volume !== cargoItemSum.volume) {
    warningMessage = `${warningMessage}${t('warningMessageCargoTotalVolume', { itemVolume: cargoItemSum.volume, cargoVolume: cargoSum.volume })}
    `;
  }

  if (cargoSum.packagesNumber !== cargoItemSum.packagesNumber) {
    warningMessage = `${warningMessage}${t('warningMessageCargoTotalPackagesNumber', { itemPackages: cargoItemSum.packagesNumber, cargoPackages: cargoSum.packagesNumber })}
    `;
  }

  return warningMessage.trim();
};

interface ISBLCargoTablesComponentProps {
  cargos: CargoDTM[];
  allCargoItems: ContainerCargoShortItemDTM[];
}

export const SBLCargoTablesComponent: FC<ISBLCargoTablesComponentProps> = ({
  allCargoItems,
  cargos,
}) => {
  const { t } = useTranslation();

  const warningMessage = getCargoWarningMessage(cargos, allCargoItems, t);

  return (
    <SBLCargoLayout>
      {warningMessage ? (
        <SBLCargoWarningWrap>
          <SBLWarningIcon />

          <SBLCargoWarningColumn>
            <SBLCargoWarningTitle>
              {t('Please Verify Your Load Plan')}
            </SBLCargoWarningTitle>
            <SBLCargoWarningText>
              {warningMessage}
            </SBLCargoWarningText>
          </SBLCargoWarningColumn>
        </SBLCargoWarningWrap>
      ) : null}

      <SBLGoodsTable />
      <SBLHazmatTable />
      <SBLContainersTable />
    </SBLCargoLayout>
  );
};
