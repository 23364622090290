import DatePicker from 'antd/es/date-picker';
import styled from 'styled-components';

import themesColors from 'app-wrapper/view/themes/themesColors';
import { Select } from 'app-wrapper/view/components';

export const RateDownloadModalTitle = styled.span.attrs({
  'data-class': 'RateDownloadModalTitle',
})`
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
`;

export const RateDownloadModalBody = styled.div.attrs({
  'data-class': 'RateDownloadModalBody',
})`
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: stretch;
  gap: 8px;
  
  width: 100%;
  height: 100%;
`;

export const RateDownloadModalFooter = styled.div.attrs({
  'data-class': 'RateDownloadModalFooter',
})`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: center;
`;

export const RateDownloadModalSelect = styled(Select).attrs((props: {
  isError: boolean
}) => ({
  isError: props.isError,
  'data-class': 'RateDownloadModal',
}))`
  .ant-select-selector {
    ${({ isError, theme }) => isError && `border-color: ${theme.themesColors.characterBrandingDanger} !important;`};
    ${({ isError }) => isError && 'z-index: 2;'};
  }

  input {
    color: ${({ theme }) => theme.themesColors.neutralBranding10}
  }
`;

export const RateDownloadModalItem = styled.div.attrs({
  'data-class': 'RateDownloadModalItem',
})`
  display: flex;
  flex-flow: column nowrap;
  min-height: 58px;
  width: 100%;
  position: relative;
  font-size: 12px;

  & > span {
    margin-bottom: 4px;
    height: 20px;
    line-height: 20px;
  }
  
  svg {
    margin-left: 4px;
    height: 10px;
    width: 10px;
    color: ${themesColors.secondaryDot45};
    
    &:hover {
      cursor: pointer;
    }
  }
`;

export const RateDownloadModalDatePicker = styled(DatePicker).attrs({
  'data-class': 'RateDownloadModalDatePicker',
})`
  height: 32px;

  > .ant-picker-input {
    > input {
      font-size: 12px;
    }
  }
`;
