import map from 'lodash/fp/map';
import uniq from 'lodash/fp/uniq';

import { ChargeDTM } from 'shipment-operations/models/dtm';
import { chargeCodeCode } from 'monetary/constants';

export const prepareDataForTable = (data: ChargeDTM[], includedCharges?: string[]) => map((item) => ({
  key: item.id,
  service: item.chargeCode.description.toLowerCase(),
  measure: item.measureBy,
  cost: item.costPerUnit.toFixed(2),
  quantity: item.numberOfUnits,
  total: item.totalCost.toFixed(2),
  includedCharges: item.chargeCode.code === chargeCodeCode.FRT ? uniq(includedCharges) : [],
}), data);

export const collectKeys = (data: ChargeDTM[]) => map((item) => item.id, data);

export const parseCharges = (data: ChargeDTM[], includedCharges?: string[]) => {
  const tableData = prepareDataForTable(data, includedCharges);
  const keys = collectKeys(data);
  return { tableData, keys };
};
