import {
  IsEnum,
  IsOptional,
} from 'class-validator';
import { BaseDTM } from 'proto/BaseDTM';

import { PermissionAttributePolicy } from 'shipment-operations/constants';

export type TCargosPermissionsDtm = {
  cargosAvailability?: PermissionAttributePolicy;
}

export class CargosPermissionsDtm extends BaseDTM<TCargosPermissionsDtm> {
  @IsOptional()
  @IsEnum(PermissionAttributePolicy)
  cargosAvailability?: PermissionAttributePolicy;;
}
