import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import TeamOutlined from '@ant-design/icons/TeamOutlined';
import BorderlessTableOutlined from '@ant-design/icons/BorderlessTableOutlined';

import { OrganizationDTM, EOrganizationStatus } from 'user-management/models/dtm';

import {
  UserOrganizationData,
  UserOrganizationHeader,
  UserOrganizationIconBG,
  UserOrganizationItem,
  UserOrganizationRow,
  UserOrganizationTitle,
  UserOrganizationValue,
  UserOrganizationWrapper,
  Warning,
  WarningIcon,
  WarningText,
  WarningButton,
} from './UserOrganization.styled';

interface IUserOrganizationComponentProps {
  organization?: OrganizationDTM
  onGoToWizard: () => void;
}

export const UserOrganizationComponent: FC<IUserOrganizationComponentProps> = ({
  organization,
  onGoToWizard,
}) => {
  const { t } = useTranslation();

  const isInDraft = useMemo(() => !organization || organization?.status === EOrganizationStatus.DRAFT, [organization]);

  return (
    <UserOrganizationWrapper>
      <UserOrganizationHeader>{t('Organization')}</UserOrganizationHeader>
      <UserOrganizationRow>
        {isInDraft ? (
          <Warning>
            <WarningIcon />

            <WarningText>
              {t('Please, complete the registration')}
            </WarningText>

            <WarningButton onClick={onGoToWizard}>
              {t('Create Organization')}
            </WarningButton>
          </Warning>
        ) : (
          <>
            <UserOrganizationItem>
              <UserOrganizationIconBG>
                <TeamOutlined />
              </UserOrganizationIconBG>
              <UserOrganizationData>
                <UserOrganizationTitle>{t('Organization Name')}</UserOrganizationTitle>
                <UserOrganizationValue>{organization?.name}</UserOrganizationValue>
              </UserOrganizationData>
            </UserOrganizationItem>
            <UserOrganizationItem>
              <UserOrganizationIconBG>
                <BorderlessTableOutlined />
              </UserOrganizationIconBG>
              <UserOrganizationData>
                <UserOrganizationTitle>{t('Organization ID')}</UserOrganizationTitle>
                <UserOrganizationValue>{organization?.id}</UserOrganizationValue>
              </UserOrganizationData>
            </UserOrganizationItem>
          </>
        )}
      </UserOrganizationRow>
    </UserOrganizationWrapper>
  );
};
