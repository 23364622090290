import React, { FC, HTMLAttributes } from 'react';

export const SkypaceTeamIcon: FC<HTMLAttributes<SVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="14"
    fill="none"
    viewBox="0 0 14 14"
    {...props}
  >
    <g clipPath="url(#clip0_25509_12764)">
      <g clipPath="url(#clip1_25509_12764)">
        <path
          fill="#73819B"
          fillRule="evenodd"
          d="M7 1.361a5.639 5.639 0 100 11.278A5.639 5.639 0 007 1.36zM.196 7a6.806 6.806 0 1113.611 0A6.806 6.806 0 01.196 7zM9.49 5.07a.621.621 0 100-1.243.621.621 0 000 1.243zm-3.33 4.99h2.805c.947 0 1.695-.804 1.695-1.78S9.912 6.5 8.965 6.5H5.037c-.365 0-.697-.336-.697-.782 0-.447.332-.782.697-.782h2.745c.278 0 .5-.221.5-.5 0-.277-.222-.499-.5-.499H5.037c-.947 0-1.695.805-1.695 1.78 0 .976.748 1.781 1.695 1.781h3.928c.365 0 .696.336.696.782s-.331.781-.696.781H6.16c-.278 0-.5.222-.5.5s.222.5.5.5zm-1.721.135a.621.621 0 100-1.243.621.621 0 000 1.243z"
          clipRule="evenodd"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_25509_12764">
        <path fill="#fff" d="M0 0H14V14H0z" />
      </clipPath>
      <clipPath id="clip1_25509_12764">
        <path fill="#fff" d="M0 0H14V14H0z" />
      </clipPath>
    </defs>
  </svg>
);
