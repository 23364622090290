import { apiWorker } from 'app-wrapper/repository/utilsServices';

import {
  TransportPlanMissMatchDTM,
  ITransportPlanMissMatchDTM,
  TransportPlanMissMatchesDTM,
  BookingMissMatchesDTM,
  IBookingMissMatchDTM,
  BookingMissMatchDTM,
} from 'shipment-operations/models/dtm';
import {
  IGetTransportPlanMissMatchesResponse,
  IGetBookingMissMatchesResponse,
} from 'shipment-operations/models/contracts';

export class ShipmentMissMatchesService {
  public getBookingMissMatches = async (shipmentId: string): Promise<BookingMissMatchesDTM> => {
    let missMatches: BookingMissMatchDTM[] = [];
    let id = 0;

    try {
      const response = await apiWorker.requestGet<IGetBookingMissMatchesResponse>(this.formatUrl(shipmentId, '/mismatches'));

      if (response.status === 200) {
        missMatches = this.formatBookingMissMatches(response.data);
        id = response.data.id;
      }
    } catch (e) {
      throw new Error('ShipmentMissMatchesService: getBookingMissMatches error');
    }

    const { containerMissMatches, cargoMissMatches } = BookingMissMatchesDTM.groupMissMatches(missMatches);

    return BookingMissMatchesDTM.fromPlain({
      cargoMissMatches,
      containerMissMatches,
      id,
    });
  };

  public acceptBookingPlanMissMatches = async (shipmentId: string, missMatchesId: string) => {
    try {
      await apiWorker.requestPost(this.formatUrl(shipmentId, `/mismatches/${missMatchesId}/accept`));
    } catch (e) {
      throw new Error('ShipmentMissMatchesService: acceptBookingPlanMissMatches error');
    }
  };

  public getTransportPlanMissMatches = async (shipmentId: string): Promise<TransportPlanMissMatchesDTM> => {
    let missMatches: TransportPlanMissMatchDTM[] = [];
    let id = 0;

    try {
      const response = await apiWorker.requestGet<IGetTransportPlanMissMatchesResponse>(this.formatUrl(shipmentId, '/location-mismatches'));

      if (response.status === 200) {
        missMatches = this.formatTransportPlanMissMatches(response.data);
        id = response.data.id;
      }
    } catch (e) {
      throw new Error('ShipmentMissMatchesService: getTransportPlanMissMatches error');
    }

    return TransportPlanMissMatchesDTM.fromPlain({
      missMatches,
      id,
    });
  };

  public acceptTransportPlanMissMatches = async (shipmentId: string, missMatchesId: string) => {
    try {
      await apiWorker.requestPost(this.formatUrl(shipmentId, `/location-mismatches/${missMatchesId}/accept`));
    } catch (e) {
      throw new Error('ShipmentMissMatchesService: acceptTransportPlanMissMatches error');
    }
  };

  private formatTransportPlanMissMatches = (response: IGetTransportPlanMissMatchesResponse): TransportPlanMissMatchDTM[] => response.mismatches.map((missmatch: ITransportPlanMissMatchDTM) => TransportPlanMissMatchDTM.fromPlain(missmatch));

  private formatBookingMissMatches = (response: IGetBookingMissMatchesResponse): BookingMissMatchDTM[] => response.mismatches.map((missmatch: IBookingMissMatchDTM) => BookingMissMatchDTM.fromPlain(missmatch));

  private formatUrl = (shipmentId: string, url: string) => (
    `/shipment-service/api/v1/shipments/${shipmentId}${url}`
  );
}
