import {
  IsString,
  IsDefined,
  IsNumber,
  ValidateNested,
  IsOptional,
} from 'class-validator';
import { Type } from 'class-transformer';

import { DateDtm } from 'app-wrapper/models/dtm';
import { BaseDTM } from 'proto/BaseDTM';
import { DocumentType } from 'shipment-operations/constants';

export interface IDocumentsCollapseItemDTM {
  id: number;
  name: string;
  uploadedDate: DateDtm | null;
  objectName: string;
  createdBy: string;
  type: string;
}

export class DocumentsCollapseItemDTM extends BaseDTM<IDocumentsCollapseItemDTM> {
  @IsDefined()
  @IsNumber()
  id: number;

  @IsDefined()
  @IsString()
  name: string;

  @IsOptional()
  @ValidateNested()
  @Type(() => DateDtm)
  uploadedDate: DateDtm | null;

  @IsDefined()
  @IsString()
  objectName: string;

  @IsDefined()
  @IsString()
  createdBy: string;

  @IsDefined()
  @IsString()
  type: string;
}

export interface IAdditionalDocumentFileDTM {
  id: number;
  name: string;
  type: string;
}

export class AdditionalDocumentFileDTM extends BaseDTM<IAdditionalDocumentFileDTM> {
  @IsDefined()
  @IsNumber()
  id: number;

  @IsDefined()
  @IsString()
  name: string;

  @IsDefined()
  @IsString()
  type: string;
}

export interface IDocumentDTM {
  id: number;
  name: string;
  type: `${DocumentType}`;
  createdAt?: DateDtm;
  createdBy: string;
}

export class DocumentDTM extends BaseDTM<IDocumentDTM> {
  @IsNumber()
  id: number;

  @IsString()
  name: string;

  @IsString()
  type: DocumentType;

  @IsOptional()
  @ValidateNested()
  @Type(() => DateDtm)
  createdAt?: DateDtm;

  @IsDefined()
  @IsString()
  createdBy: string;
}
