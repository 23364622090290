import { RefObject } from 'react';
import useOnclickOutside from 'react-cool-onclickoutside';

interface Callback<T extends Event = Event> {
  (event: T): void;
}

interface Options {
  refs?: RefObject<HTMLElement>[];
  disabled?: boolean;
  eventTypes?: string[];
  excludeScrollbar?: boolean;
  ignoreClass?: string | string[];
  detectIFrame?: boolean;
}

interface Return {
  (element: HTMLElement | null): void;
}

export const useOnclickOutsideHook = (callback: Callback<Event>, options?: Options): Return => useOnclickOutside(callback, options);
