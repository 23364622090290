import React, { FC, useCallback, useEffect } from 'react';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import Spin from 'antd/es/spin';

import { AdditionalServiceDTM } from 'shipment-operations/models/dtm';
import {
  SpinnerWrap,
  SubPageHeaderTitle,
} from 'app-wrapper/view/guideline';

import { NoServices, ServicesList, AddServiceButton } from './components';
import {
  Page,
  PageHeader,
} from './ShipmentAdditionalServices.styled';

interface IShipmentAdditionalServicesProps {
  isLoading?: boolean;
  init: (shipmentId: string) => void;
  addServices: (shipmentId: string) => void;
  services: AdditionalServiceDTM[];
}

export const ShipmentAdditionalServices: FC<IShipmentAdditionalServicesProps> = ({
  isLoading,
  init,
  addServices,
  services,
}) => {
  const { shipmentId = '' } = useParams();
  const { t } = useTranslation();

  useEffect(() => {
    init(shipmentId);
  }, []);

  const addServicesHandler = useCallback(
    () => {
      addServices(shipmentId);
    },
    [addServices, shipmentId],
  );

  return (
    <Page>
      <PageHeader>
        <SubPageHeaderTitle>
          {t('Services')}
        </SubPageHeaderTitle>

        <AddServiceButton
          onClick={addServicesHandler}
        />
      </PageHeader>

      {isLoading ? (
        <SpinnerWrap>
          <Spin size="large" />
        </SpinnerWrap>
      ) : (
        <>
          {services.length ? (
            <ServicesList />
          ) : (
            <NoServices
              onAddServices={addServicesHandler}
            />
          )}
        </>
      )}
    </Page>
  );
};
