import React from 'react';

import { IPropsIcon } from 'app-wrapper/interfaces/icon';
import { MessageResponseDialog, MessageResponseDialogDots, MessageResponse } from 'app-wrapper/view/icons';

import {
  MessageResponseFullDialog,
  MessageResponseFullDialogDots,
  MessageResponseFullDiv,
  MessageResponseFullMsg,
} from './styled';

export const MessageResponseFull = (props: IPropsIcon) => {
  const { className } = props;
  return (
    <MessageResponseFullDiv className={className}>
      <MessageResponseFullDialog>
        <MessageResponseDialog />
        <MessageResponseFullDialogDots>
          <MessageResponseDialogDots />
        </MessageResponseFullDialogDots>
      </MessageResponseFullDialog>

      <MessageResponseFullMsg>
        <MessageResponse />
      </MessageResponseFullMsg>
    </MessageResponseFullDiv>
  );
};
