import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { R } from 'shipment-operations/repository';
import { UC } from 'shipment-operations/controllers';

import { FooterComponent } from './Footer.component';

const FooterContainer = () => {
  const isLoading = useSelector(R.selectors.rollShipmentWizard.getIsLoading);
  const planIds = useSelector(R.selectors.rollShipmentWizard.getSchedulePlansIds);
  const { shipmentId = '0' } = useParams();

  return (
    <FooterComponent
      shipmentId={shipmentId}
      isLoading={isLoading}
      planIds={planIds}
      onRollShipment={UC.rollShipment.submitShipmentRoll}
    />
  );
};

export { FooterContainer as Footer };
