import i18n from 'app-wrapper/i18n/i18n';
import { plainToInstance } from 'class-transformer';
import {
  IsBoolean, IsEnum, IsNumber, IsOptional, IsString,
} from 'class-validator';
import { BaseDTM } from 'proto/BaseDTM';
import { v4 as uuidv4 } from 'uuid';

import { EMembershipType } from 'user-management/constants';

export type TOrganizationMembershipDtm = {
  id: string
  type?: `${EMembershipType}`
  isNew: boolean
  value?: string
  maxValue?: number
  minLength?: number
}

const preg = /[0-9]/;

export class OrganizationMembershipDtm extends BaseDTM<TOrganizationMembershipDtm> {
  @IsString()
  id: string

  @IsOptional()
  @IsEnum(EMembershipType)
  type?: EMembershipType;

  @IsBoolean()
  isNew: boolean;

  @IsOptional()
  @IsString()
  value?: string;

  @IsOptional()
  @IsNumber()
  maxLength?: number;

  @IsOptional()
  @IsNumber()
  minLength?: number;

  static createEmpty = () => plainToInstance(OrganizationMembershipDtm, {
    id: uuidv4(),
    type: EMembershipType.WCA_ID,
    isNew: true,
  })

  static createEmptyWithSelectedType = (type: EMembershipType) => plainToInstance(OrganizationMembershipDtm, {
    type,
    id: uuidv4(),
    isNew: true,
    maxLength: 9,
  })

  hasError = () => !!this.value && this.getErrorMessage() !== ''

  getErrorMessage = () => {
    const requiredLength = this.type === EMembershipType.WCA_ID ? 6 : 9;

    if (this.value && this.value.length !== requiredLength) {
      return i18n.t('basicErrors.LENGTH_NOT_EQUAL', { count: requiredLength });
    }

    if (this.value && !preg.test(this.value)) {
      return i18n.t('basicErrors.ONLY_TRUE_NUMBERS');
    }

    return '';
  }

  getValidValue = () => {
    if (this.value === '' || this.value === null) {
      return this.value;
    }

    const patternValue = this.value?.replace(/[^0-9]/g, '') || '';
    return patternValue;
  }
}
