import _sanitizeHtml from 'sanitize-html';

const options = {
  allowedTags: [
    'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'blockquote', 'dd', 'dl', 'dt', 'li', 'ul', 'ol', 'br', 'span', 'u',
    'p', 'b', 'i', 'em', 'strong', 's', 'font'],
  allowedAttributes: {
    font: ['size', 'color', 'face'],
  },
  allowedIframeHostnames: [],
};

export const sanitizeHtml = (html: string) => _sanitizeHtml(html, options);
