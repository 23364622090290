import { createSelector } from 'reselect';

import { RootState } from 'app-wrapper/store';

const localState = (state: RootState) => state.shipmentAccessorial;

const getIsLoading = createSelector(
  localState,
  (state) => state.isLoading,
);

const getError = createSelector(
  localState,
  (state) => state.error,
);

const getData = createSelector(
  localState,
  (state) => state.data,
);

const getNotAppliedCharges = createSelector(
  getData,
  (charges) => charges && charges?.filter((item) => !item.applied && ['ACCESSORIAL', 'EXCEPTION', 'FREE_TIME'].includes(item?.chargeCode?.subType || '') && item.subjectTo !== 'INCLUDED'),
);

export const getAccessorial = createSelector(
  getNotAppliedCharges,
  (charges) => charges?.filter((item) => item?.chargeCode?.subType === 'ACCESSORIAL'),
);

export const getPenalties = createSelector(
  getNotAppliedCharges,
  (charges) => charges?.filter((item) => item?.chargeCode?.subType === 'EXCEPTION'),
);

export const shipmentAccessorialSelectors = {
  getIsLoading,
  getError,
  getData,
  getAccessorial,
  getPenalties,
  getNotAppliedCharges,
};
