import styled from 'styled-components';
import Panel from 'antd/es/collapse/CollapsePanel';

export const Wrapper = styled.div`
  .ant-collapse {
    padding: 0 !important;
    background: white !important;
  }
  .ant-collapse:last-child {
    margin-bottom: 0 !important;
  }
  padding: 0 16px;
`;

export const StyledPanel = styled(Panel)`
  .ant-collapse-content-box {
    padding: 0 !important;
  }
  .ant-collapse-header {
    padding-left: 0 !important;
  }
`;

export const StyledInnerPanel = styled(Panel)`
  .ant-collapse-header {
    padding-left: 16px !important;
  }
`;

export const StyledHeaderInner = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

export const FreeDays = styled.span`
  color: #73819B;
  font-size: 12px;
`;

export const InnerTable = styled.div`
  padding-right: 16px;
  padding-left: 30px;
`;

export const InnerTableHeader = styled.div`
  color: #73819B;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 8px 0;
  position: relative;
  font-size: 12px;
`;

export const InnerTableRow = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding: 10px 0;
  position: relative;
`;

export const TablePointer = styled.div`
  width: 9px;
  height: 15px;
  border-left: 1px solid #D1D7E2;
  border-bottom: 1px solid #D1D7E2;
  position: absolute;
  left: -20px;
  top: 5px;
`;

export const RowRestWrapper = styled.div`
  width: 80%;
  display: flex;
  justify-content: space-between;
`;

export const Separator = styled.div`
  width: 100%;
  height: 1px;
  background-color: #EAEEF8 !important;
  padding: 0 !important;
`;
