import { createSlice } from 'app-wrapper/createSlice';

import { IShipmentTrackerRoutesState } from 'shipment-operations/models/states';

const initialState: IShipmentTrackerRoutesState = {
  containers: [],
  isLoading: true,
  error: false,
  schedules: [],
  isLoadingSchedules: false,
  errorSchedules: false,
  plan: [],
  legs: [],
  isLoadingUpdate: false,
  isUpdateSuccess: false,
  editableSchedules: {
    terminalCutOff: '',
    vgmCutOff: '',
    documentCutOff: '',
  },
  originalSchedules: {
    terminalCutOff: '',
    vgmCutOff: '',
    documentCutOff: '',
  },
  terminalCutOffError: false,
  vesselErrorIndexes: [],
  voyageErrorIndexes: [],
  isChangedTransportPlan: false,
  validationLoading: false,
  validationLoaded: false,
  savedCharges: [],
  estimatedCharges: [],
  showAddChargeButton: false,
  incoterm: '',
};

export const ShipmentTrackerRoutesSlice = createSlice({
  name: 'shipmentTrackerRoutes',
  initialState,
  reducers: {
    setLoading: (state, { payload }) => {
      state.isLoading = payload;
    },
    setError: (state, { payload }) => {
      state.isLoading = false;
      state.containers = [];
      state.error = payload;
    },
    setContainers: (state, { payload }) => {
      state.isLoading = false;
      state.containers = payload;
      state.error = false;
    },
    setLoadingSchedules: (state, { payload }) => {
      state.isLoadingSchedules = payload;
    },
    setErrorSchedules: (state) => {
      state.isLoadingSchedules = false;
      state.schedules = [];
      state.errorSchedules = true;
    },
    setSchedules: (state, { payload }) => {
      state.isLoadingSchedules = false;
      state.schedules = payload;
      state.errorSchedules = false;
    },
    setEditableSchedules: (state, { payload }) => {
      state.editableSchedules = payload;
    },
    setOriginalSchedules: (state, { payload }) => {
      state.originalSchedules = payload;
    },
    setPlan: (state, { payload }) => {
      state.plan = payload;
    },
    setLegs: (state, { payload }) => {
      state.legs = payload;
    },
    setIsLoadingUpdate: (state, { payload }) => {
      state.isLoadingUpdate = payload;
    },
    setIsUpdateSuccess: (state, { payload }) => {
      state.isUpdateSuccess = payload;
    },
    clearEditPlan: (state) => {
      state.legs = [];
      state.isLoadingUpdate = false;
      state.isUpdateSuccess = false;
      state.vesselErrorIndexes = [];
      state.voyageErrorIndexes = [];
      state.editableSchedules = state.originalSchedules;
      state.terminalCutOffError = false;
      state.showAddChargeButton = false;
      state.isChangedTransportPlan = false;
      state.validationLoading = false;
      state.validationLoaded = false;
      state.savedCharges = [];
      state.estimatedCharges = [];
      state.validationLoaded = false;
      state.incoterm = '';
    },
    setTerminalCutOffError: (state, { payload }) => {
      state.terminalCutOffError = payload;
    },
    setVesselErrorIndex: (state, { payload }) => {
      state.vesselErrorIndexes = payload;
    },
    setVoyageErrorIndex: (state, { payload }) => {
      state.voyageErrorIndexes = payload;
    },
    setIsChangedTransportPlan: (state, { payload }) => {
      state.isChangedTransportPlan = payload;
    },
    setValidationLoading: (state, { payload }) => {
      state.validationLoading = payload;
    },
    setValidationLoaded: (state, { payload }) => {
      state.validationLoaded = payload;
    },
    setSavedCharges: (state, { payload }) => {
      state.savedCharges = payload;
    },
    setEstimatedCharges: (state, { payload }) => {
      state.estimatedCharges = payload;
    },
    setShowAddChargeButton: (state, { payload }) => {
      state.showAddChargeButton = payload;
    },
    setIncoterm: (state, { payload }) => {
      state.incoterm = payload;
    },
    clear: () => (initialState),
  },
});

export const shipmentTrackerRoutesActions = ShipmentTrackerRoutesSlice.actions;
export const shipmentTrackerRoutesReducer = ShipmentTrackerRoutesSlice.reducer;
