import styled, { css } from 'styled-components';

export const textColorFirst = css`
  color: ${({ theme }) => theme.themesColors.characterBrandingTitle85};
`;

export const divFlex = css`
  display: flex;
  position: relative;
  ${textColorFirst}
`;

// Div

export const MessageResponseFullDiv = styled.div.attrs({
  'data-class': 'MessageResponseFullDiv',
})`
  ${divFlex}
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;

// Content

export const MessageResponseFullDialog = styled.div`
  ${divFlex}
  position: absolute;
  right: -21px;
  top: -21px;
  justify-content: end;
`;

export const MessageResponseFullDialogDots = styled.div`
  ${divFlex}
  position: absolute;
  right: -7px;
  top: 9px;
`;

export const MessageResponseFullMsg = styled.div`
  ${divFlex}
`;
