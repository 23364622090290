import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';
import { UC } from 'shipment-operations/controllers';

import { ControlButtonsComponent } from './ControlButtons.component';

const ControlButtonsContainer: FC = () => {
  const isInDraft = useSelector(R.selectors.temperatureControl.getTemperatureControlDraft);
  const isLoading = useSelector(R.selectors.temperatureControl.getLoading);

  return (
    <ControlButtonsComponent
      isLoading={isLoading}
      isInDraft={isInDraft}
      resetTemperatureControl={UC.temperatureControl.resetTemperatureControl}
      updateTemperatureControl={UC.temperatureControl.sendTemperatureControl}
    />
  );
};

export {
  ControlButtonsContainer as ControlButtons,
};
