import React, { useCallback, useEffect, FC } from 'react';
import Alert from 'antd/es/alert';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { TableSkeleton } from 'app-wrapper/view/components';
import { CreateInvoice } from 'shipment-operations/view/pages/CreateInvoice';
import {
  Transportation, Services, Fees, Additional,
} from 'shipment-operations/view/pages/ShipmentCharges/components';
import {
  TableContainer,
  HeaderTitle,
  TableHeader,
} from 'shipment-operations/view/pages/ShipmentCharges/ShipmentCharges.styled';
import { ProfitSwitcherContainer } from 'shipment-operations/view/pages/ShipmentCharges/components/ProfitSwitcher';

interface IMainContainerProps {
  clearStore: () => void
  success: boolean
  loadCharges: (shipmentId?: string) => void
  isLoading: boolean
  error: boolean
  onClean: () => void
  isOpenModal: string
  toggleOpenModal: (state: string) => void
  mode: string
}

const MainContainerComponent: FC<IMainContainerProps> = ({
  clearStore, success, loadCharges, isLoading, onClean,
  isOpenModal, toggleOpenModal, mode, error,
}) => {
  const { t } = useTranslation();
  const { shipmentId } = useParams<'shipmentId'>();

  const closeModal = useCallback(() => toggleOpenModal(''), []);

  useEffect(() => {
    if (success) {
      closeModal();
      loadCharges(shipmentId);
    }
  }, [success]);

  useEffect(() => {
    loadCharges(shipmentId);
  }, [shipmentId, mode]);

  useEffect(() => () => {
    onClean();
    clearStore();
  }, []);

  if (error) {
    return (
      <>
        <Alert
          message={t('basicErrors.REQUEST_COMMON.title')}
          description={t('basicErrors.REQUEST_COMMON.subTitle')}
          type="error"
        />
      </>
    );
  }

  return (
    <>
      {isOpenModal && (<CreateInvoice closeModal={closeModal} />)}
      {isLoading ? (
        <TableSkeleton />
      ) : (
        <TableContainer>
          <TableHeader>
            <HeaderTitle>{t('charges')}</HeaderTitle>
            <ProfitSwitcherContainer />
          </TableHeader>
          <Transportation />
          <Services />
          <Fees />
          <Additional />
        </TableContainer>
      )}
    </>
  );
};

export { MainContainerComponent };
