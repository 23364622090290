import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { UC } from 'monetary/controllers';
import { R } from 'monetary/repository';

import { PAYABLES, RECEIVABLES } from 'shipment-operations/constants';
import { InvoicesComponent } from './Invoices.component';

interface InvoicesContainerProps {
  type: typeof PAYABLES | typeof RECEIVABLES
}

const InvoicesContainer: FC<InvoicesContainerProps> = ({ type }) => {
  const page = useSelector(R.selectors.invoices.getActivePage);
  const isFirstTimeLoading = useSelector(R.selectors.invoices.getFirstTimeLoading);
  const data = useSelector(R.selectors.invoices.getDataForTable);
  const isLoading = useSelector(R.selectors.invoices.getIsLoading);

  return (
    <InvoicesComponent
      loadData={UC.invoices.fetchData}
      onClear={UC.invoices.onClear}
      onChangePagination={UC.invoices.onChangeActivePage}
      page={page}
      type={type}
      isEmptyData={!data?.length}
      isLoading={isLoading}
      isFirstTimeLoading={isFirstTimeLoading}
    />
  );
};

export { InvoicesContainer as Invoices };
