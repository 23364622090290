import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Tag from 'antd/es/tag';
import Typography from 'antd/es/typography';

import { colors } from 'app-wrapper/view/themes/themesColors';
import { Badge } from 'app-wrapper/view/components';
import { ShipmentBookingStatusEnum, ShipmentBookingStatusNames, ShipmentBookingStatusColors } from 'shipment-operations/constants';
import { SubPageHeader, SubPageHeaderContainer, SubPageHeaderTitle } from 'app-wrapper/view/guideline';

import {
  ShipmentTransportationHeaderStatus,
  ShipmentTransportationTitleContainer,
} from './ShipmentTransportationHeader.styled';

interface IShipmentTransportationHeaderComponentProps {
  carrierName?: string
  bookingNumber?: string
  status?: ShipmentBookingStatusEnum
}

export const ShipmentTransportationHeaderComponent: FC<IShipmentTransportationHeaderComponentProps> = ({
  carrierName,
  bookingNumber,
  status,
}) => {
  const { t } = useTranslation();

  return (
    <SubPageHeader>
      <SubPageHeaderContainer>
        <ShipmentTransportationTitleContainer>
          <SubPageHeaderTitle>
            {carrierName || t('No carrier specified')}
          </SubPageHeaderTitle>
          {/* temporarily hidden */}
          {!!bookingNumber && false && (
          <Tag color="default">
            <Typography.Text style={{ color: colors.gray61 }}>
              {t('Booking number')}
            </Typography.Text>
            <Typography.Text>
              {` #${bookingNumber}`}
            </Typography.Text>
          </Tag>
          )}
        </ShipmentTransportationTitleContainer>

        {status ? (
          <ShipmentTransportationHeaderStatus>
            {ShipmentBookingStatusNames[status]}
            <Badge inlineColor={ShipmentBookingStatusColors[status]} />
          </ShipmentTransportationHeaderStatus>
        ) : null}
      </SubPageHeaderContainer>
    </SubPageHeader>
  );
};
