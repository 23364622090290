import styled from 'styled-components';
import AntLayout from 'antd/es/layout';

export const ShipmentListContainer = styled(AntLayout.Content).attrs({
  'data-class': 'ShipmentListContainer',
})`
  margin: 0;
  height: 100%;

  background: #F4F5F8;
`;
