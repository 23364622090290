import React from 'react';

import {
  AgreementsDrawer,
} from 'app-wrapper/view/drawers';

import { GapHorizontalContainerStyled } from 'app-wrapper/view/guideline/GapHorzontalContainer';
import { GapVerticalContainerStyled } from 'app-wrapper/view/guideline/GapVerticalContainer';
import {
  ScheduleAndTrack,
  ArrivingShipment,
  DepartingShipment,
  PendingConfirmationShipment,
  AlertsTable,
  StatisticsTasksOverview,
} from './components';

export const Dashboard = () => (
  <>
    <AgreementsDrawer />

    <GapVerticalContainerStyled>
      <StatisticsTasksOverview />
      <AlertsTable />
      <GapHorizontalContainerStyled>
        <DepartingShipment />
        <ArrivingShipment />
      </GapHorizontalContainerStyled>
      <ScheduleAndTrack />
      <PendingConfirmationShipment />
    </GapVerticalContainerStyled>
  </>
);
