import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { UC } from 'monetary/controllers';
import { R } from 'monetary/repository';

import { PAYABLES, RECEIVABLES } from 'shipment-operations/constants';
import { PaymentsComponent } from './Payments.component';

interface PaymentsContainerProps {
  type: typeof PAYABLES | typeof RECEIVABLES
}

const PaymentsContainer: FC<PaymentsContainerProps> = ({ type }) => {
  const page = useSelector(R.selectors.payments.getActivePage);
  const isFirstTimeLoading = useSelector(R.selectors.payments.getFirstTimeLoading);
  const data = useSelector(R.selectors.payments.getDataForTable);
  const isLoading = useSelector(R.selectors.payments.getIsLoading);

  return (
    <PaymentsComponent
      loadData={UC.payments.fetchData}
      onClear={UC.payments.onClear}
      onChangePagination={UC.payments.onChangeActivePage}
      page={page}
      type={type}
      isEmptyData={!data?.length}
      isLoading={isLoading}
      isFirstTimeLoading={isFirstTimeLoading}
    />
  );
};

export { PaymentsContainer as Payments };
