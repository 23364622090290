import styled from 'styled-components';

import themesColors from 'app-wrapper/view/themes/themesColors';

export const OrganizationProfileContent = styled.div`
  display: grid;
  grid-template-columns: 1fr 1px 1fr;
  grid-template-rows: 80px 1px 132px 1px auto;
  grid-template-areas: 'OrganizationProfileHeader OrganizationProfileHeader OrganizationProfileHeader'
  'OrganizationProfileHorizontalSeparator OrganizationProfileHorizontalSeparator OrganizationProfileHorizontalSeparator'
  'OrganizationProfileLeftTopBox OrganizationProfileVerticalSeparator OrganizationProfileRightTopBox'
  'OrganizationProfileHorizontalDashedSeparator OrganizationProfileVerticalSeparator OrganizationProfileHorizontalDashedSeparatorRight'
  'OrganizationProfileLeftBottomBox OrganizationProfileVerticalSeparator OrganizationProfileRightBox'
  'OrganizationProfileLeftCoverageAreaBox OrganizationProfileVerticalSeparator OrganizationProfileRightCoverageAreaBox';
  column-gap: 16px;
  row-gap: 16px;

  font-size: 14px;
`;

export const OrganizationProfileContentHeader = styled.div`
  grid-area: OrganizationProfileHeader;
`;

export const OrganizationProfileLeftTopBox = styled.div`
  grid-area: OrganizationProfileLeftTopBox;
`;

export const OrganizationProfileLeftBottomBox = styled.div`
  grid-area: OrganizationProfileLeftBottomBox;
`;

export const OrganizationProfileLeftCoverageAreaBox = styled.div`
  grid-area: OrganizationProfileLeftCoverageAreaBox;
`;

export const OrganizationProfileRightCoverageAreaBox = styled.div`
  grid-area: OrganizationProfileRightCoverageAreaBox;
`;

export const OrganizationProfileRightTopBox = styled.div`
  grid-area: OrganizationProfileRightTopBox;
`;

export const OrganizationProfileRightBox = styled.div`
  grid-area: OrganizationProfileRightBox;
`;

export const OrganizationProfileHorizontalSeparator = styled.div`
  grid-area: OrganizationProfileHorizontalSeparator;

  background-color: ${themesColors.neutralBranding1};
  border-top: 1px solid ${themesColors.neutralBranding4};

  height: 1px;
`;

export const OrganizationProfileHorizontalDashedSeparator = styled.div`
  grid-area: OrganizationProfileHorizontalDashedSeparator;

  background-color: ${themesColors.neutralBranding1};
  border-top: 1px dashed ${themesColors.neutralBranding4};

  height: 1px;
`;

export const OrganizationProfileHorizontalDashedSeparatorRight = styled.div`
  grid-area: OrganizationProfileHorizontalDashedSeparatorRight;

  background-color: ${themesColors.neutralBranding1};
  border-top: 1px dashed ${themesColors.neutralBranding4};

  height: 1px;
`;

export const OrganizationProfileVerticalSeparator = styled.div`
  grid-area: OrganizationProfileVerticalSeparator;

  background-color: ${themesColors.neutralBranding1};
  border-left: 1px dashed ${themesColors.neutralBranding4};

  width: 1px;
`;
