import React, {
  FC,
  useMemo,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { Moment } from 'moment';
import get from 'lodash/fp/get';
import DatePicker from 'antd/es/date-picker';
import Alert from 'antd/es/alert';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';

import { Button } from 'app-wrapper/view/components';
import { DateDtm } from 'app-wrapper/models/dtm';
import {
  FREE_DATE,
  ACTUAL,
  EXPECTED,
  DEFINED_DATE,
  PermissionAttributePolicy,
  MISSING_DATES_SEARCH_PARAM,
} from 'shipment-operations/constants';
import { InnerSteps } from 'shipment-operations/view/pages/ShipmentTransportationTracker/components/InnerSteps';
import { ShipmentTrackerTableDTM, TrackerContainersTableDTM } from 'shipment-operations/models/dtm';
import { useToggleCollapse } from 'shipment-operations/view/hooks';

import {
  StyledExpandIcon,
} from 'shipment-operations/view/pages/ShipmentTransportationCharges/components/CustomTable/CustomTable.styled';
import { ContainerTitleWrapper, ContainerType } from 'shipment-operations/view/components/ExpandPanel/ExpandPanel.styled';

import { Footer } from 'app-wrapper/view/guideline';
import { SkeletonSection } from 'app-wrapper/view/components/Skeleton/Skeleton.component';
import {
  Container, StyledTable, StyledButton, StyledType,
} from './ContainersTable.styled';

export interface IContainersTableProps {
  isLoading: boolean
  error: boolean
  containers: TrackerContainersTableDTM[]
  fetchData: (shipmentId?: string) => void
  onChangeDate: (id: string, type: string, date: Moment) => void
  isUpdatedDates: boolean
  onResetChanges: () => void
  onPostDates: (shipmentId?: string) => void
  isLoadingUpdate: boolean
  trackerAvailability?: PermissionAttributePolicy
  trackerWarningsAvailability?: PermissionAttributePolicy
}

const TIME_FORMAT = 'D MMM YYYY, HH:mm';

const ContainersTableComponent: FC<IContainersTableProps> = ({
  isLoading, error, containers, fetchData, onChangeDate, isUpdatedDates,
  onResetChanges, onPostDates, isLoadingUpdate, trackerAvailability, trackerWarningsAvailability,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const { shipmentId } = useParams<'shipmentId'>();
  const { t } = useTranslation();
  const { openedKeys, onToggleCollapse } = useToggleCollapse();

  const [isMissingDatesCase, setIsMissingDatesCase] = useState(Boolean(searchParams.get(MISSING_DATES_SEARCH_PARAM)));

  useEffect(() => {
    const indexesToToggle: number[] = [];

    if (containers.length && isMissingDatesCase) {
      containers.forEach((container, index) => {
        const { events } = container;

        if (events) {
          events.forEach((step) => {
            if (step.withWarning && !step.actualDate) {
              // adding container index containing this missing date
              indexesToToggle.push(index);
            }
          });
        }
      });

      // finding first container index with missing date in order to toggle
      const firstIndex = Math.min(...indexesToToggle);
      setIsMissingDatesCase(false);
      onToggleCollapse(firstIndex);
    }

    setSearchParams('');
  }, [containers]);

  const isAbleToEdit = useMemo(() => trackerAvailability === PermissionAttributePolicy.WRITE, [trackerAvailability]);
  const isUpdateDisabled = useMemo(() => !isUpdatedDates || !isAbleToEdit, [isAbleToEdit, isUpdatedDates]);

  const handleChangeDate = useCallback((id, type, date) => {
    onChangeDate(id, type, date);
  }, []);

  const renderCalendar = useCallback((record, type, selectedKey, date?: DateDtm) => {
    const key = get(['key'], record);
    const editable = isAbleToEdit && get(['editable'], record);
    const dateType = get([selectedKey], record);
    const eventId = get(['eventId'], record);

    if (dateType === FREE_DATE) {
      return (
        <DatePicker
          allowClear={false}
          format={TIME_FORMAT}
          disabled={openedKeys.includes(key) || !editable}
          showTime
          onChange={(newDate) => handleChangeDate(eventId, type, newDate)}
        />
      );
    }

    return dateType === DEFINED_DATE ? (
      <DatePicker
        allowClear={false}
        format={TIME_FORMAT}
        value={date?.getDateAsMomentWithOffset()}
        disabled={openedKeys.includes(key) || !editable}
        showTime
        onChange={(newDate) => handleChangeDate(eventId, type, newDate)}
      />
    ) : null;
  }, [openedKeys, containers, isAbleToEdit]);

  const renderEventName = useCallback((name) => name && t(`eventsCodes.${name}`), []);

  const handleFetchData = useCallback(() => {
    fetchData(shipmentId);
  }, []);

  const handlePostDates = useCallback(() => {
    onPostDates(shipmentId);
  }, []);

  const columns = useMemo(() => [
    {
      title: 'Container',
      dataIndex: 'number',
      key: 'number',
      width: '15%',
      render: (number: string, record: ShipmentTrackerTableDTM | {}) => {
        const key = get(['key'], record);
        const type = get(['type'], record);
        return (
          <StyledType onClick={() => onToggleCollapse(key)} isOpen={openedKeys.includes(key)}>
            <StyledExpandIcon isActive={openedKeys.includes(key)} />
            <ContainerTitleWrapper>
              <ContainerType active={openedKeys.includes(key)}>
                {type} &apos;
              </ContainerType>
            </ContainerTitleWrapper>
            {number}
          </StyledType>
        );
      },
    },
    {
      title: 'Previous Event',
      dataIndex: 'previousEvent',
      key: 'previousEvent',
      width: '17.5%',
      render: renderEventName,
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      width: '15%',
      render: (date: DateDtm) => (date ? date?.getDateAsMomentWithOffset().format(TIME_FORMAT) : ''),
    },
    {
      title: 'Next Event',
      dataIndex: 'nextEvent',
      key: 'nextEvent',
      width: '17.5%',
      render: renderEventName,
    },
    {
      title: 'Estimated Date',
      dataIndex: 'estimatedDate',
      key: 'estimatedDate',
      width: '17.5%',
      render: (date: DateDtm, record) => renderCalendar(record, EXPECTED, 'estimatedDateType', date),
    },
    {
      title: 'Actual Date',
      dataIndex: 'actualDate',
      key: 'actualDate',
      width: '17.5%',
      render: (date: DateDtm, record) => renderCalendar(record, ACTUAL, 'actualDateType', date),
    },
  ], [openedKeys]);

  const expandRender = (record: TrackerContainersTableDTM | {}) => {
    const events = get(['events'], record);
    const editable = isAbleToEdit && get(['editable'], record);

    return (
      <InnerSteps
        data={events}
        editable={editable}
        onChangeDate={onChangeDate}
        trackerWarningsAvailability={trackerWarningsAvailability}
      />
    );
  };

  if (error) {
    return (
      <Container>
        <Alert
          message={t('creditNote.errorMsg')}
          description={t('creditNote.errorDescription')}
          type="error"
          action={(
            <StyledButton size="middle" type="primary" onClick={handleFetchData}>
              {t('reload')}
            </StyledButton>
          )}
        />
      </Container>
    );
  }

  if (isLoading) {
    return (
      <SkeletonSection height="244px" />
    );
  }

  return (
    <>
      <Container>
        <StyledTable
          columns={columns}
          dataSource={containers}
          pagination={false}
          expandedRowClassName={() => 'customExpandRow'}
          expandable={{
            expandedRowRender: expandRender,
            expandedRowKeys: openedKeys,
            expandIconColumnIndex: -1,
          }}
        />
      </Container>
      {isUpdatedDates && (
        <Footer>
          <Button
            disabled={!isAbleToEdit}
            type="default"
            onClick={onResetChanges}
            size="large"
          >
            {t('Discard Changes')}
          </Button>
          <Button
            htmlType="submit"
            size="large"
            style={{ marginLeft: '12px' }}
            disabled={isUpdateDisabled}
            onClick={handlePostDates}
            loading={isLoadingUpdate}
          >
            {t('Update Tracker')}
          </Button>
        </Footer>
      )}
    </>
  );
};

export { ContainersTableComponent };
