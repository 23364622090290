import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import {
  YellowTag,
  BlueTag,
  GreenTag,
} from 'app-wrapper/view/guideline';

interface IStatusTagComponentProps {
  isPendingSubmission?: boolean;
  isSubmitted?: boolean;
  isConfirmed?: boolean;
  isCleared?: boolean;
  isRejectedStatus?: boolean;
  isFailedStatus?: boolean;
}

export const StatusTag: FC<IStatusTagComponentProps> = ({
  isPendingSubmission,
  isSubmitted,
  isConfirmed,
  isCleared,
  isRejectedStatus,
  isFailedStatus,
}) => {
  const { t } = useTranslation();

  return (
    <>
      {isPendingSubmission ? (
        <YellowTag>
          {t('Pending Submission')}
        </YellowTag>
      ) : null}

      {isSubmitted || isRejectedStatus || isFailedStatus ? (
        <BlueTag>
          {t('Submitted')}
        </BlueTag>
      ) : null}

      {isConfirmed && !isRejectedStatus && !isFailedStatus ? (
        <BlueTag>
          {t('Confirmed')}
        </BlueTag>
      ) : null}

      {isCleared ? (
        <GreenTag>
          {t('Cleared')}
        </GreenTag>
      ) : null}
    </>
  );
};
