import React from 'react';
import { observer } from 'mobx-react-lite';

import { useMobxStore } from 'app-wrapper/mobxStores';
import { ContainersChargesChangesTableComponent } from 'app-wrapper/view/guideline';

const ContainersTableContainer = observer(() => {
  const { shipmentCostChangesDrawer } = useMobxStore();

  return (
    <ContainersChargesChangesTableComponent
      dataSource={shipmentCostChangesDrawer.state.containers}
    />
  );
});

export { ContainersTableContainer as ContainersTable };
