import React, { FC, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { DocumentType } from 'shipment-operations/constants';
import { CustomerDocumentsMap } from 'user-management/models/dtm';

import {
  Wrap,
  Header,
  Title,
  TitleSecond,
} from './Documents.styled';
import { DocumentsSection } from '../DocumentsSection';

interface DocumentsComponentProps {
  documentTypesMap: CustomerDocumentsMap;
  openDocumentUploadDrawer: (documentType: DocumentType) => void;
  onDownload: (organizationId: string, documentId: number) => void;
  onDelete: (organizationId: string, documentId: number) => void;
  isOrgAdmin: boolean;
  isBLICRequired: boolean;
}

export const DocumentsComponent: FC<DocumentsComponentProps> = ({
  documentTypesMap,
  openDocumentUploadDrawer,
  onDownload,
  onDelete,
  isOrgAdmin,
  isBLICRequired,
}) => {
  const { t } = useTranslation();

  const documentALO = useMemo(() => documentTypesMap[DocumentType.ALO] || [], [documentTypesMap]);
  const documentCCI = useMemo(() => documentTypesMap[DocumentType.CCI] || [], [documentTypesMap]);
  const documentBLIC = useMemo(() => documentTypesMap[DocumentType.BLIC] || [], [documentTypesMap]);
  const documentTCER = useMemo(() => documentTypesMap[DocumentType.TCER] || [], [documentTypesMap]);
  const documentMSC = useMemo(() => documentTypesMap[DocumentType.MISC] || [], [documentTypesMap]);

  const openDocumentALOUploadDrawer = useCallback((e) => {
    e.stopPropagation();

    openDocumentUploadDrawer(DocumentType.ALO);
  }, [openDocumentUploadDrawer]);
  const openDocumentCCIUploadDrawer = useCallback((e) => {
    e.stopPropagation();

    openDocumentUploadDrawer(DocumentType.CCI);
  }, [openDocumentUploadDrawer]);
  const openDocumentBLICUploadDrawer = useCallback((e) => {
    e.stopPropagation();

    openDocumentUploadDrawer(DocumentType.BLIC);
  }, [openDocumentUploadDrawer]);
  const openDocumentTCERUploadDrawer = useCallback((e) => {
    e.stopPropagation();

    openDocumentUploadDrawer(DocumentType.TCER);
  }, [openDocumentUploadDrawer]);
  const openDocumentMSCUploadDrawer = useCallback((e) => {
    e.stopPropagation();

    openDocumentUploadDrawer(DocumentType.MISC);
  }, [openDocumentUploadDrawer]);

  return (
    <Wrap>
      <Header>
        <Title>
          {t('Group')}
        </Title>

        <TitleSecond>
          {t('Action')}
        </TitleSecond>
      </Header>

      <DocumentsSection
        sectionName={t('Bill stating Name & Address of Organization')}
        documents={documentALO}
        openDocumentUploadDrawer={openDocumentALOUploadDrawer}
        onDownload={onDownload}
        onRemove={onDelete}
        isOrgAdmin={isOrgAdmin}
      />
      <DocumentsSection
        sectionName={t('Copy of Company Identity/Business Card')}
        documents={documentCCI}
        openDocumentUploadDrawer={openDocumentCCIUploadDrawer}
        onDownload={onDownload}
        onRemove={onDelete}
        isOrgAdmin={isOrgAdmin}
      />

      {isBLICRequired ? (
        <DocumentsSection
          sectionName={t('Business License')}
          documents={documentBLIC}
          openDocumentUploadDrawer={openDocumentBLICUploadDrawer}
          onDownload={onDownload}
          onRemove={onDelete}
          isOrgAdmin={isOrgAdmin}
        />
      ) : null}

      <DocumentsSection
        sectionName={t('Tax Registration')}
        documents={documentTCER}
        openDocumentUploadDrawer={openDocumentTCERUploadDrawer}
        onDownload={onDownload}
        onRemove={onDelete}
        isOrgAdmin={isOrgAdmin}
      />
      <DocumentsSection
        sectionName={t('Other')}
        documents={documentMSC}
        openDocumentUploadDrawer={openDocumentMSCUploadDrawer}
        onDownload={onDownload}
        onRemove={onDelete}
        isOrgAdmin={isOrgAdmin}
      />
    </Wrap>
  );
};
