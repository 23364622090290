import React, { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { RcFile, UploadProps } from 'antd/es/upload/interface';
import AntdUploadExports from 'antd/es/upload';
import DeleteOutlined from '@ant-design/icons/DeleteOutlined';
import UploadOutlined from '@ant-design/icons/UploadOutlined';

import { useResponsiveSize } from 'app-wrapper/hooks';
import { showWarning } from 'app-wrapper/utils/showWarning';
import { addPrefix } from 'app-wrapper/utils';
import { PermissionAttributePolicy } from 'shipment-operations/constants';
import { ShipmentDeclarationFileDTM } from 'shipment-operations/models/dtm';

import {
  Upload,
  Button,
  Wrapper,
  Title,
} from './IMODeclarationUpload.styled';

const MAX_FILE_SIZE = 1024 * 1024 * 10; // 10MB
const { LIST_IGNORE } = AntdUploadExports;

interface SeaworthyDocumentUploadComponentProps {
  authToken: string
  shipmentId: string;
  containerDocument: ShipmentDeclarationFileDTM | null
  setContainerDocument: (document: ShipmentDeclarationFileDTM | null) => void
  downloadDocument: (shipmentId: string) => void
  documentType: string
  error: boolean;
  isPending: boolean;
  containersAvailability?: PermissionAttributePolicy
}

// TODO Refactoring request by @AliaksandrKatovich FRTUN-2166
export const IMODeclarationUploadComponent: FC<SeaworthyDocumentUploadComponentProps> = ({
  authToken,
  containerDocument,
  setContainerDocument,
  downloadDocument,
  shipmentId,
  documentType,
  error,
  isPending,
  containersAvailability,
}) => {
  const { t } = useTranslation();
  const size = useResponsiveSize();

  const isDisabled = useMemo(() => containersAvailability !== PermissionAttributePolicy.WRITE || isPending, [containersAvailability, isPending]);

  const setDocument = useCallback((value: ShipmentDeclarationFileDTM[]) => {
    setContainerDocument(value[0] || null);
  }, [setContainerDocument]);

  const downloadContainerDocument = useCallback(() => downloadDocument(shipmentId), [downloadDocument, shipmentId]);

  const handleBeforeUpload = useCallback((file: RcFile) => {
    const isAcceptableSize = file.size < MAX_FILE_SIZE;
    if (!isAcceptableSize) {
      showWarning({ message: t('File is too large'), duration: 4 });
    }
    return isAcceptableSize || LIST_IGNORE;
  }, []);

  const handleChange: UploadProps['onChange'] = useCallback(({ file }) => {
    if (file.status === 'removed') {
      setDocument([]);
      return;
    }

    if (file.response) {
      file.url = '/';
    }

    if (file.response && file.status === 'error') {
      file.error = new Error(file.response?.message);
      file.response = file.response.message ? file.response.message : {};
    }

    setDocument([ShipmentDeclarationFileDTM.fromPlain({
      name: file.name,
      url: file.url,
      uid: file.uid,
      response: file.response,
      status: file.status,
      fileId: file?.response?.id,
    })]);
  }, [setDocument]);

  const handleRemove: UploadProps['onRemove'] = useCallback(() => {
    setDocument([]);
  }, [setDocument]);

  const postUrl = addPrefix(`/shipment-service/api/v1/shipments/${shipmentId}/documents?type=${documentType}`);

  return (
    <Wrapper>
      <Title>
        {t('IMO Declaration')}
      </Title>

      <Upload
        maxCount={1}
        multiple={false}
        showUploadList={{ showRemoveIcon: true, removeIcon: <DeleteOutlined /> }}
        beforeUpload={handleBeforeUpload}
        action={postUrl}
        headers={{ Authorization: authToken }}
        fileList={containerDocument ? [containerDocument] : []}
        onPreview={downloadContainerDocument}
        onChange={handleChange}
        onRemove={handleRemove}
        disabled={isDisabled}
      >
        <Button
          disabled={isDisabled}
          icon={<UploadOutlined />}
          type="dashed"
          size={size}
          hasError={error}
        >
          {t('Upload document')}
        </Button>
      </Upload>
    </Wrapper>
  );
};
