import styled from 'styled-components';
import { colors } from 'app-wrapper/view/themes/themesColors';

import UnstyledTag from 'antd/es/tag';

export const ShipmentTitleTag = styled(UnstyledTag)`
  padding: 1px 8px;
  height: 22px;
  background: ${colors.gray35};
  border-color: ${colors.purple15};
`;
