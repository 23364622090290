import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { UC } from 'user-management/controllers';
import { R } from 'user-management/repository';

import { UserManagementProfileComponent } from './UserManagementProfile.component';

const UserManagementProfileContainer: FC = () => {
  const isLoading = useSelector(R.selectors.userManagement.getIsLoading);
  const permissions = useSelector(R.selectors.moduleFunctionalityPermissions.getUserManagementProfilePermissions);

  return (
    <UserManagementProfileComponent
      isLoading={isLoading}
      init={UC.userManagement.initUserManagementProfile}
      cleanup={UC.userManagement.clearUserManagementMemberProfile}
      userDepartmentsAndTeamsViewPermissions={permissions?.userDepartmentsAndTeamsView}
    />
  );
};

export {
  UserManagementProfileContainer as UserManagementProfile,
};
