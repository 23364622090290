import React, { FC, memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import Modal from 'antd/es/modal';

import { CompanyContactDTM, IContactBookUpdateStateContactPersonsErrorsDTM } from 'user-management/models/dtm';
import { ContactPersonBlockComponent } from 'user-management/view/components';

import { useWindowSize } from 'app-wrapper/hooks';
import { TypeSwitch } from 'app-wrapper/view/components';

import {
  CBByIdAddCPBlockButtonCancel,
  CBByIdAddCPBlockButtonSave,
  CBByIdAddCPBlockContent,
  CBByIdAddCPBlockFooter,
  CBByIdAddCPBlockWrap,
} from './ContactBookByIdEditContactPerson.styled';
import { CBByIdContentBodyFormFooter } from '../ContactBookByIdAddAddress/ContactBookByIdAddAddress.styled';

interface IContactBookByIdEditContactPersonComponentProps {
  contactPerson?: CompanyContactDTM
  isDisabledCancelBtn?: boolean
  isDisabledSaveBtn?: boolean
  firstBlockName?: string
  errors?: IContactBookUpdateStateContactPersonsErrorsDTM
  onCancelClick: () => void
  onSaveClick: (companyId: string, addressId: string) => Promise<void>
  removeContact: (companyId: string, addressId: string) => Promise<void>
  onChangeAddressIsPrimary: (idContacts: string) => (isPrimary: boolean) => void
  onChangeContactPerson: (idContacts: string) => (contactPerson: string) => void
  onBlurContactPerson: (idContacts: string) => () => void
  onFocusContactPerson: (idContacts: string) => () => void
  onChangeAdditionalPhone: (idContacts: string) => (additionalPhone: string) => void
  onBlurAdditionalPhone: (idContacts: string) => () => void
  onFocusAdditionalPhone: (idContacts: string) => () => void
  onChangeEmail: (idContacts: string) => (email: string) => void
  onBlurEmail: (idContacts: string) => () => void
  onFocusEmail: (idContacts: string) => () => void
  onChangePhone: (idContacts: string) => (phone: string) => void
  onBlurPhone: (idContacts: string) => () => void
  onFocusPhone: (idContacts: string) => () => void
}

const ContactBookByIdEditContactPersonComponent: FC<IContactBookByIdEditContactPersonComponentProps> = (props) => {
  const {
    isDisabledCancelBtn,
    isDisabledSaveBtn,
    errors,
    firstBlockName,
    contactPerson,
    onChangeAddressIsPrimary,
    onCancelClick,
    onSaveClick,
    removeContact,
    onChangeContactPerson,
    onBlurContactPerson,
    onFocusContactPerson,
    onChangeAdditionalPhone,
    onBlurAdditionalPhone,
    onFocusAdditionalPhone,
    onChangeEmail,
    onBlurEmail,
    onFocusEmail,
    onChangePhone,
    onBlurPhone,
    onFocusPhone,
  } = props;
  const { t } = useTranslation();
  const largeInput = useWindowSize().isFullMediaWidth;
  const { contactId, addressId } = useParams();

  const handleClickRemove = useCallback(() => {
    Modal.confirm({
      title: t('Are you sure you want to remove this Contact from the company?'),
      okText: 'Yes',
      cancelText: 'No',
      onOk: () => removeContact(contactId || '', addressId || ''),
    });
  }, [removeContact, t]);

  const handleSave = useCallback(() => {
    onSaveClick(contactId || '', addressId || '');
  }, [contactId, addressId]);

  return (
    <CBByIdAddCPBlockWrap>
      <CBByIdAddCPBlockContent>

        <ContactPersonBlockComponent
          largeInput={largeInput}
          contactPerson={contactPerson}
          firstBlockName={firstBlockName}
          errorsName={errors?.contactPerson}
          errorsEmail={errors?.email}
          errorsPhone={errors?.phone}
          errorsPhone2={errors?.additionalPhone}
          onChangeContactPerson={onChangeContactPerson(`${contactPerson?.customId}`)}
          onBlurContactPerson={onBlurContactPerson(`${contactPerson?.customId}`)}
          onFocusContactPerson={onFocusContactPerson(`${contactPerson?.customId}`)}
          onChangeAdditionalPhone={onChangeAdditionalPhone(`${contactPerson?.customId}`)}
          onBlurAdditionalPhone={onBlurAdditionalPhone(`${contactPerson?.customId}`)}
          onFocusAdditionalPhone={onFocusAdditionalPhone(`${contactPerson?.customId}`)}
          onChangeEmail={onChangeEmail(`${contactPerson?.customId}`)}
          onBlurEmail={onBlurEmail(`${contactPerson?.customId}`)}
          onFocusEmail={onFocusEmail(`${contactPerson?.customId}`)}
          onChangePhone={onChangePhone(`${contactPerson?.customId}`)}
          onBlurPhone={onBlurPhone(`${contactPerson?.customId}`)}
          onFocusPhone={onFocusPhone(`${contactPerson?.customId}`)}
          primaryComponent={(
            <TypeSwitch
              onChange={onChangeAddressIsPrimary(`${contactPerson?.customId}`)}
              data-class="ContactBookByIdEditContactPersonAddressItem"
              rightText={t('Primary')}
              checkedChildren="On"
              unCheckedChildren="Off"
              value={!!contactPerson?.primary}
              disabledbg={contactPerson?.primaryDefault ? '' : 'true'}
              d
            />
          )}
        />

      </CBByIdAddCPBlockContent>

      <CBByIdAddCPBlockFooter>
        <CBByIdContentBodyFormFooter
          saveName={t('addChargeMenu.save')}
          discardName={t('addChargeMenu.cancel')}
          hasRemove
          discardChanges={onCancelClick}
          disabledDiscard={isDisabledCancelBtn}
          updateForm={handleSave}
          removeChanges={handleClickRemove}
          disabledUpdate={isDisabledSaveBtn}
        />
        <CBByIdAddCPBlockButtonCancel
          onClick={onCancelClick}
          disabled={isDisabledCancelBtn}
        >
          {t('addChargeMenu.cancel')}
        </CBByIdAddCPBlockButtonCancel>
        <CBByIdAddCPBlockButtonSave
          onClick={handleSave}
          disabled={isDisabledSaveBtn}
        >
          {t('addChargeMenu.save')}
        </CBByIdAddCPBlockButtonSave>
      </CBByIdAddCPBlockFooter>
    </CBByIdAddCPBlockWrap>
  );
};

const ContactBookByIdEditContactPersonComponentCached = memo(ContactBookByIdEditContactPersonComponent);

export { ContactBookByIdEditContactPersonComponentCached as ContactBookByIdEditContactPersonComponent };
