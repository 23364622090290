import {
  ValidateNested,
  IsOptional,
  IsDefined,
  IsString,
  IsNumber,
  IsEnum,
  IsBoolean, IsArray,
} from 'class-validator';
import { Type } from 'class-transformer';

import {
  AppFiltersCheckboxDTM, IAppFiltersRadioDTM, AppFiltersRadioDTM, IAppFiltersCheckboxDTM,
} from 'app-wrapper/models/dtm';
import { BaseDTM } from 'proto/BaseDTM';
import { ShipmentStatusEnum } from 'shipment-operations/constants';
import { EPageDirectionGeneral, EPageSortGeneral } from 'app-wrapper/models/contracts';

export interface IShipmentListFiltersCheckboxResponseCodesDTM {
  code?: string
  name?: string
}

export class ShipmentListFiltersCheckboxResponseCodesDTM extends BaseDTM<IShipmentListFiltersCheckboxResponseCodesDTM> {
  @IsOptional()
  @IsString()
  code?: string;

  @IsDefined()
  @IsOptional()
  name?: string;
}

export interface IShipmentListFiltersCheckboxResponseCoordinatesDTM {
  lat: number
  lng: number
}

export class ShipmentListFiltersCheckboxResponseCoordinatesDTM extends BaseDTM<IShipmentListFiltersCheckboxResponseCoordinatesDTM> {
  @IsDefined()
  @IsNumber()
  lat: number;

  @IsDefined()
  @IsNumber()
  lng: number;
}

export interface IShipmentListFiltersCheckboxResponseDTM {
  id?: number
  country?: IShipmentListFiltersCheckboxResponseCodesDTM
  state?: IShipmentListFiltersCheckboxResponseCodesDTM
  coordinates?: IShipmentListFiltersCheckboxResponseCoordinatesDTM
  timezoneId?: string
  code?: string
  type: string
  name: string
}

export class ShipmentListFiltersCheckboxResponseDTM extends BaseDTM<IShipmentListFiltersCheckboxResponseDTM> {
  @IsOptional()
  @IsNumber()
  id?: number;

  @IsOptional()
  @ValidateNested()
  @Type(() => ShipmentListFiltersCheckboxResponseCodesDTM)
  country?: ShipmentListFiltersCheckboxResponseCodesDTM;

  @IsOptional()
  @ValidateNested()
  @Type(() => ShipmentListFiltersCheckboxResponseCodesDTM)
  state?: ShipmentListFiltersCheckboxResponseCodesDTM;

  @IsOptional()
  @ValidateNested()
  @Type(() => ShipmentListFiltersCheckboxResponseCoordinatesDTM)
  coordinates?: ShipmentListFiltersCheckboxResponseCoordinatesDTM;

  @IsOptional()
  @IsString()
  timezoneId?: string;

  @IsOptional()
  @IsString()
  code?: string;

  @IsDefined()
  @IsString()
  type: string;

  @IsDefined()
  @IsString()
  name: string;
}

export interface IShipmentListFiltersCheckboxIntervalResponseDTM {
  from: string
  to: string
}

export class ShipmentListFiltersCheckboxIntervalResponseDTM extends BaseDTM<IShipmentListFiltersCheckboxIntervalResponseDTM> {
  @IsDefined()
  @IsString()
  from: string;

  @IsDefined()
  @IsString()
  to: string;
}

export interface IShipmentListFiltersCheckboxDTM {
  values: IAppFiltersCheckboxDTM
}

export class ShipmentListFiltersCheckboxDTM extends BaseDTM<IShipmentListFiltersCheckboxDTM> {
  @IsDefined()
  @ValidateNested()
  @Type(() => AppFiltersCheckboxDTM)
  values: AppFiltersCheckboxDTM;
}

export interface IShipmentListFiltersCheckboxIntervalDTM {
  values: IAppFiltersCheckboxDTM
  date: IShipmentListFiltersCheckboxIntervalResponseDTM
}

export class ShipmentListFiltersCheckboxIntervalDTM extends BaseDTM<IShipmentListFiltersCheckboxIntervalDTM> {
  @IsDefined()
  @ValidateNested()
  @Type(() => AppFiltersCheckboxDTM)
  values: AppFiltersCheckboxDTM;

  @IsDefined()
  @ValidateNested()
  @Type(() => ShipmentListFiltersCheckboxIntervalResponseDTM)
  date: ShipmentListFiltersCheckboxIntervalResponseDTM;
}

export interface IShipmentListFiltersCheckboxLocationDTM {
  values: IAppFiltersCheckboxDTM
  response: IShipmentListFiltersCheckboxResponseDTM[]
}

export class ShipmentListFiltersCheckboxLocationDTM extends BaseDTM<IShipmentListFiltersCheckboxDTM> {
  @IsDefined()
  @ValidateNested()
  @Type(() => AppFiltersCheckboxDTM)
  values: AppFiltersCheckboxDTM;

  @IsDefined()
  @ValidateNested({ each: true })
  @Type(() => ShipmentListFiltersCheckboxResponseDTM)
  response: ShipmentListFiltersCheckboxResponseDTM[];
}

export interface IShipmentListFiltersCheckboxCompaniesContractsDTM {
  id?: number
  customId?: string
  fullName?: string
  email?: string
  phone?: string
  phone2?: string
}

export class ShipmentListFiltersCheckboxCompaniesContractsDTM extends BaseDTM<IShipmentListFiltersCheckboxCompaniesContractsDTM> {
  @IsOptional()
  @IsNumber()
  id?: number;

  @IsOptional()
  @IsString()
  customId?: string;

  @IsOptional()
  @IsString()
  fullName?: string;

  @IsOptional()
  @IsString()
  email?: string;

  @IsOptional()
  @IsString()
  phone?: string;

  @IsOptional()
  @IsString()
  phone2?: string;
}

export interface IShipmentListFiltersCheckboxCompaniesAddressesDTM {
  id?: number
  customId?: string
  country?: string
  state?: string
  city?: string
  address1?: string
  address2?: string
  postalCode?: string
  closestPort?: string
}

export class ShipmentListFiltersCheckboxCompaniesAddressesDTM extends BaseDTM<IShipmentListFiltersCheckboxCompaniesAddressesDTM> {
  @IsOptional()
  @IsNumber()
  id?: number;

  @IsOptional()
  @IsString()
  customId?: string;

  @IsOptional()
  @IsString()
  country?: string;

  @IsOptional()
  @IsString()
  state?: string;

  @IsOptional()
  @IsString()
  city?: string;

  @IsOptional()
  @IsString()
  address1?: string;

  @IsOptional()
  @IsString()
  address2?: string;

  @IsOptional()
  @IsString()
  postalCode?: string;

  @IsOptional()
  @IsString()
  closestPort?: string;
}

export interface IShipmentListFiltersCheckboxCompaniesDTM {
  id?: number
  customId?: string
  name?: string
  phone?: string
  phone2?: string
  email?: string
  taxId?: string
  organizationId?: number
  type?: string
  companyType?: string
  isPrimary?: boolean
  contacts?: IShipmentListFiltersCheckboxCompaniesContractsDTM[]
  addresses?: IShipmentListFiltersCheckboxCompaniesAddressesDTM[]
}

export class ShipmentListFiltersCheckboxCompaniesDTM extends BaseDTM<IShipmentListFiltersCheckboxCompaniesDTM> {
  @IsOptional()
  @IsNumber()
  id?: number;

  @IsOptional()
  @IsNumber()
  organizationId?: number;

  @IsOptional()
  @IsString()
  type?: string;

  @IsOptional()
  @IsString()
  companyType?: string;

  @IsOptional()
  @IsBoolean()
  isPrimary?: boolean;

  @IsOptional()
  @IsString()
  customId?: string;

  @IsOptional()
  @IsString()
  name?: string;

  @IsOptional()
  @IsString()
  phone?: string;

  @IsOptional()
  @IsString()
  phone2?: string;

  @IsOptional()
  @IsString()
  email?: string;

  @IsOptional()
  @IsString()
  taxId?: string;

  @IsOptional()
  @ValidateNested({ each: true })
  @Type(() => ShipmentListFiltersCheckboxCompaniesContractsDTM)
  contacts?: ShipmentListFiltersCheckboxCompaniesContractsDTM[];
}

export interface IShipmentListFiltersCheckboxShippingPartyDTM {
  values: IAppFiltersCheckboxDTM
  response: IShipmentListFiltersCheckboxCompaniesDTM[]
}

export class ShipmentListFiltersCheckboxShippingPartyDTM extends BaseDTM<IShipmentListFiltersCheckboxShippingPartyDTM> {
  @IsDefined()
  @ValidateNested()
  @Type(() => AppFiltersCheckboxDTM)
  values: AppFiltersCheckboxDTM;

  @IsDefined()
  @ValidateNested({ each: true })
  @Type(() => ShipmentListFiltersCheckboxCompaniesDTM)
  response: ShipmentListFiltersCheckboxCompaniesDTM[];
}

export interface IShipmentListFiltersCheckboxContainerTypeDTM {
  values: IAppFiltersCheckboxDTM
  response: string[]
}

export class ShipmentListFiltersCheckboxContainerTypeDTM extends BaseDTM<IShipmentListFiltersCheckboxContainerTypeDTM> {
  @IsDefined()
  @ValidateNested()
  @Type(() => AppFiltersCheckboxDTM)
  values: AppFiltersCheckboxDTM;

  @IsDefined()
  @IsString({ each: true })
  response: string[];
}

export interface IShipmentListFiltersCheckboxStatusDTM {
  values: IAppFiltersCheckboxDTM
  response: ShipmentStatusEnum[]
}

export class ShipmentListFiltersCheckboxStatusDTM extends BaseDTM<IShipmentListFiltersCheckboxContainerTypeDTM> {
  @IsDefined()
  @ValidateNested()
  @Type(() => AppFiltersCheckboxDTM)
  values: AppFiltersCheckboxDTM;

  @IsDefined()
  @IsArray()
  response: ShipmentStatusEnum[];
}

export interface IShipmentListFiltersRadioSortByResponseDTM {
  sort?: EPageSortGeneral
  direction?: EPageDirectionGeneral
}

export class ShipmentListFiltersRadioSortByResponseDTM extends BaseDTM<IShipmentListFiltersRadioSortByResponseDTM> {
  @IsOptional()
  @IsEnum(EPageSortGeneral)
  sort?: EPageSortGeneral;

  @IsOptional()
  @IsEnum(EPageDirectionGeneral)
  direction?: EPageDirectionGeneral;
}
export interface IShipmentListFiltersRadioSortByDTM {
  values: IAppFiltersRadioDTM
  response: IShipmentListFiltersRadioSortByResponseDTM
}

export class ShipmentListFiltersRadioSortByDTM extends BaseDTM<IShipmentListFiltersRadioSortByDTM> {
  @IsDefined()
  @ValidateNested()
  @Type(() => AppFiltersRadioDTM)
  values: AppFiltersRadioDTM;

  @IsDefined()
  response: ShipmentListFiltersRadioSortByResponseDTM;

  urlParams() {
    if (EPageSortGeneral.CREATED_AT === this.values.value || !this.values?.value) {
      return undefined;
    }
    return [this.values.value];
  }
}

export interface IShipmentListFiltersDTM {
  filterLocation?: IShipmentListFiltersCheckboxLocationDTM
  filterDepartureInterval?: IShipmentListFiltersCheckboxIntervalDTM
  filterArrivalInterval?: IShipmentListFiltersCheckboxIntervalDTM
  filterShippingParty?: IShipmentListFiltersCheckboxShippingPartyDTM
  filterShippingCarrier?: IShipmentListFiltersCheckboxShippingPartyDTM
  filterCarrier?: IShipmentListFiltersCheckboxDTM
  filterContainerType?: IShipmentListFiltersCheckboxContainerTypeDTM
  filterStatus?: IShipmentListFiltersCheckboxStatusDTM
  filterSortBy?: IShipmentListFiltersRadioSortByDTM
}

export class ShipmentListFiltersDTM extends BaseDTM<IShipmentListFiltersDTM> {
  @IsOptional()
  @ValidateNested()
  @Type(() => ShipmentListFiltersCheckboxLocationDTM)
  filterLocation?: ShipmentListFiltersCheckboxLocationDTM;

  @IsOptional()
  @ValidateNested()
  @Type(() => ShipmentListFiltersCheckboxShippingPartyDTM)
  filterShippingParty?: ShipmentListFiltersCheckboxShippingPartyDTM;

  @IsOptional()
  @ValidateNested()
  @Type(() => ShipmentListFiltersCheckboxShippingPartyDTM)
  filterShippingCarrier?: ShipmentListFiltersCheckboxShippingPartyDTM;

  @IsOptional()
  @ValidateNested()
  @Type(() => ShipmentListFiltersCheckboxDTM)
  filterCarrier?: ShipmentListFiltersCheckboxDTM;

  @IsOptional()
  @ValidateNested()
  @Type(() => ShipmentListFiltersCheckboxContainerTypeDTM)
  filterContainerType?: ShipmentListFiltersCheckboxContainerTypeDTM;

  @IsOptional()
  @ValidateNested()
  @Type(() => ShipmentListFiltersCheckboxStatusDTM)
  filterStatus?: ShipmentListFiltersCheckboxStatusDTM;

  @IsOptional()
  @ValidateNested()
  @Type(() => ShipmentListFiltersRadioSortByDTM)
  filterSortBy?: ShipmentListFiltersRadioSortByDTM;
}
