import { createSelector } from 'reselect';

import { RootState } from 'app-wrapper/store';

const localState = (state: RootState) => state.passwordResetNewPassword;

const getLoading = createSelector(
  localState,
  (state) => state.isLoading,
);

const getEmail = createSelector(
  localState,
  (state) => state.email,
);

const getCode = createSelector(
  localState,
  (state) => state.code,
);

const getCodeError = createSelector(
  localState,
  (state) => state.codeError,
);

const getNewPassword = createSelector(
  localState,
  (state) => state.newPassword,
);

const getNewPasswordError = createSelector(
  localState,
  (state) => state.newPasswordError,
);

const getConfirmNewPassword = createSelector(
  localState,
  (state) => state.confirmNewPassword,
);

const getConfirmNewPasswordError = createSelector(
  localState,
  (state) => state.confirmNewPasswordError,
);

const wasAnyFormFieldChanged = createSelector(
  localState,
  (state) => (
    !state.code
    || !state.email
    || !!state.codeError
    || !state.newPassword
    || !!state.newPasswordError
    || !state.confirmNewPassword
    || !!state.confirmNewPasswordError
  ),
);

export const passwordResetNewPasswordSelectors = {
  getCode,
  getCodeError,
  getConfirmNewPassword,
  getConfirmNewPasswordError,
  getEmail,
  getLoading,
  getNewPassword,
  getNewPasswordError,
  wasAnyFormFieldChanged,
};
