import React, { FC } from 'react';
import { PageHeaderProps } from 'antd/es/page-header';

import { PageHeader } from './PageHeader.styled';

interface IPageHeaderComponentProps extends PageHeaderProps {
  contentRight?: React.ReactElement;
}

const PageHeaderComponent: FC<IPageHeaderComponentProps> = (props) => {
  const { children, contentRight } = props;

  return (
    <PageHeader className="page-header" {...props}>
      {children || null}
      {contentRight || null}
    </PageHeader>
  );
};

export { PageHeaderComponent as PageHeader };
