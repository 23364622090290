import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Wrapper, Title } from 'monetary/view/components';

const HeaderComponent: FC = () => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <Title>{t('invoices')}</Title>
    </Wrapper>
  );
};

export { HeaderComponent };
