import React, { FC } from 'react';
import isEmpty from 'lodash/fp/isEmpty';

import { InnerContainer } from 'shipment-operations/view/pages/ShipmentCharges/ShipmentCharges.styled';
import { ChargeViewDtm, IGroupedChargesDTM } from 'shipment-operations/models/dtm';
import { ContainersTable, TotalFooter } from 'shipment-operations/view/pages/ShipmentCharges/components';
import { TotalWrapper } from 'shipment-operations/view/pages/ShipmentTransportationCharges/ShipmentTransportationCharges.styled';

interface ITransportationComponentProps {
  containers: ChargeViewDtm[]
  innerData: IGroupedChargesDTM
  totalData: {
    apTotalCost: number
    arTotalCost: number
    profit: number
    totalCost: number
  }
}

const TransportationComponent: FC<ITransportationComponentProps> = ({ containers, innerData, totalData }) => {
  if (isEmpty(containers)) {
    return null;
  }

  return (
    <>
      <InnerContainer>
        <ContainersTable dataSource={containers} innerTableData={innerData} subTitle="Transportation" />
      </InnerContainer>
      <TotalWrapper>
        <TotalFooter dataSource={totalData} />
      </TotalWrapper>
    </>
  );
};

export { TransportationComponent };
