import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';
import { UC } from 'shipment-operations/controllers';

import { GensetFieldComponent } from './GensetField.component';

interface IGensetFieldContainerProps {
  isBookingRoute?: boolean
}

const GensetFieldContainer: FC<IGensetFieldContainerProps> = ({ isBookingRoute }) => {
  const { genset } = useSelector(R.selectors.temperatureControl.getTemperatureControl);
  const withGenset = useSelector(R.selectors.bookingWizard.getIsWithGensetCharges);
  const withGensetShipment = useSelector(R.selectors.temperatureControl.getIsWithGensetCharges);

  return (
    <GensetFieldComponent
      genset={genset}
      setGenset={UC.temperatureControl.setGenset}
      applyGenset={UC.temperatureControl.applyNewTotalCost}
      isBookingRoute={isBookingRoute}
      withGenset={isBookingRoute ? withGenset : withGensetShipment}
    />
  );
};

export {
  GensetFieldContainer as GensetField,
};
