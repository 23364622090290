import React, { FC, useMemo, useCallback } from 'react';
import { useParams } from 'react-router';
import moment from 'moment';
import isNumber from 'lodash/fp/isNumber';
import getOr from 'lodash/fp/getOr';
import PaperClipOutlined from '@ant-design/icons/PaperClipOutlined';
import { useTranslation } from 'react-i18next';

import { normalizationCost } from 'app-wrapper/utils';
import { ChargeViewDtm, ChargeHistoryViewDTM } from 'shipment-operations/models/dtm';
import { ChargesTypes } from 'shipment-operations/constants';

import {
  StyledTable, StyledDescription, StyledArrow, StyledCell, DescriptionDate, Content,
  Document, DoubleCell, StyledDelta,
} from './HistoryTable.styled';

const timeFormat = 'D MMM YYYY';

interface IHistoryTableProps {
  data: ChargeViewDtm | {}
  onDownloadDocument: (shipmentId: string, id: number, name: string) => void
  shortVersion?: boolean
}

const HistoryTable: FC<IHistoryTableProps> = ({ data, onDownloadDocument, shortVersion }) => {
  const { t } = useTranslation();
  const { shipmentId } = useParams<'shipmentId'>();
  const handleDownload = useCallback((id, name) => {
    if (shipmentId) {
      onDownloadDocument(shipmentId, id, name);
    }
  }, []);
  const tableData = useMemo(() => {
    const history = getOr([], 'history', data);
    return history.map((item: ChargeHistoryViewDTM) => ({
      date: item.createdAt,
      action: item.action,
      createdBy: item.createdBy,
      description: item.description,
      documents: item.documents,
      deltaCostPerUnit: item.deltaCostPerUnit,
      deltaNumberOfUnits: item.deltaNumberOfUnits,
      deltaTotalCost: item.deltaTotalCost,
      buyCostPerUnit: item.type === ChargesTypes.AP ? item.costPerUnit : null,
      buyQuantity: item.type === ChargesTypes.AP ? item.numberOfUnits : null,
      buyTotalCost: item.type === ChargesTypes.AP ? item.totalCost : null,
      costPerUnit: item.type === ChargesTypes.AR ? item.costPerUnit : null,
      quantity: item.type === ChargesTypes.AR ? item.numberOfUnits : null,
      totalCost: item.type === ChargesTypes.AR ? item.totalCost : null,
    }));
  }, [data]);

  const renderCostPerUnit = useCallback((val, record) => {
    const delta = record.deltaCostPerUnit;
    return (
      <DoubleCell>
        {isNumber(val) && <StyledCell>{`$ ${normalizationCost(val, { thousandthSeparatorComma: true, toFixed: 2 })}`}</StyledCell>}
        {!!delta && isNumber(val) && (<StyledDelta>{`($ ${delta > 0 ? '+' : ''}${normalizationCost(delta, { thousandthSeparatorComma: true, toFixed: 2 })})`}</StyledDelta>)}
      </DoubleCell>
    );
  }, []);

  const renderQuantity = useCallback((val, record) => {
    const delta = record.deltaNumberOfUnits;
    return (
      <DoubleCell>
        {isNumber(val) && <StyledCell>{val}</StyledCell>}
        {!!delta && isNumber(val) && (<StyledDelta>{`(${delta > 0 ? '+' : ''}${delta})`}</StyledDelta>)}
      </DoubleCell>
    );
  }, []);

  const renderTotalCost = useCallback((val, record) => {
    const delta = record.deltaTotalCost;
    return (
      <DoubleCell>
        {isNumber(val) && <StyledCell>{`$ ${normalizationCost(val, { thousandthSeparatorComma: true, toFixed: 2 })}`}</StyledCell>}
        {!!delta && isNumber(val) && (<StyledDelta>{`($ ${delta > 0 ? '+' : ''}${normalizationCost(delta, { thousandthSeparatorComma: true, toFixed: 2 })})`}</StyledDelta>)}
      </DoubleCell>
    );
  }, []);

  const renderDescription = useCallback((text, record) => (
    <StyledDescription>
      <StyledArrow />
      <Content>
        <DescriptionDate>{moment(record.date).format(timeFormat)}</DescriptionDate>
        <div>{`${t(`${record.action}`)} by ${record.createdBy}`}</div>
        <div>{text}</div>
        <div>
          {record.documents && record.documents.map((document: any) => (
            <div key={`HistoryTable_${document.id}`} style={{ display: 'flex', alignItems: 'center' }}>
              <PaperClipOutlined />
              <Document key={document.id} onClick={() => handleDownload(document.id, document.name)}>{document.name}</Document>
            </div>
          ))}
        </div>
      </Content>
    </StyledDescription>
  ), []);

  const columns = useMemo(() => [
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      width: '24%',
      align: 'left' as 'left',
      render: renderDescription,
    },
    {
      title: 'Unit',
      dataIndex: 'unit',
      key: 'unit',
      width: '19%',
      render: () => null,
    },
    {
      title: 'Cost per Unit',
      dataIndex: 'buyCostPerUnit',
      key: 'buyCostPerUnit',
      align: 'right' as 'right',
      width: '19%',
      render: renderCostPerUnit,
    },
    {
      title: 'Quantity',
      dataIndex: 'buyQuantity',
      key: 'buyQuantity',
      width: '19%',
      align: 'right' as 'right',
      render: renderQuantity,
    },
    {
      title: 'Buy',
      dataIndex: 'buyTotalCost',
      key: 'buyTotalCost',
      align: 'right' as 'right',
      width: '19%',
      render: renderTotalCost,
    },
  ], []);

  const fullColumns = useMemo(() => [
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      width: '32%',
      align: 'left' as 'left',
      render: renderDescription,
    },
    {
      title: 'Unit',
      dataIndex: 'unit',
      key: 'unit',
      width: '3%',
      render: () => null,
    },
    {
      title: 'Cost per Unit',
      dataIndex: 'buyCostPerUnit',
      key: 'buyCostPerUnit',
      align: 'right' as 'right',
      width: '10%',
      render: renderCostPerUnit,
    },
    {
      title: 'Quantity',
      dataIndex: 'buyQuantity',
      key: 'buyQuantity',
      width: '5%',
      align: 'right' as 'right',
      render: renderQuantity,
    },
    {
      title: 'Buy',
      dataIndex: 'buyTotalCost',
      key: 'buyTotalCost',
      align: 'right' as 'right',
      width: '10%',
      render: renderTotalCost,
    },
    {
      title: 'Cost per Unit',
      dataIndex: 'costPerUnit',
      key: 'costPerUnit',
      align: 'right' as 'right',
      width: '10%',
      render: renderCostPerUnit,
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity',
      width: '5%',
      align: 'right' as 'right',
      render: renderQuantity,
    },
    {
      title: 'Sell',
      dataIndex: 'totalCost',
      key: 'totalCost',
      width: '10%',
      align: 'right' as 'right',
      render: renderTotalCost,
    },
    {
      title: 'Profit',
      dataIndex: 'profit',
      key: 'profit',
      align: 'right' as 'right',
      width: '15%',
      render: () => null,
    },
  ], []);

  return (
    <StyledTable
      dataSource={tableData}
      columns={shortVersion ? columns : fullColumns}
      pagination={false}
      showHeader={false}
      style={{ marginTop: '10px' }}
    />
  );
};

export { HistoryTable };
