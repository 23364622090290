import React from 'react';
import { useTranslation } from 'react-i18next';

import InfoIcon from 'shipment-operations/view/components/HBLDocumentPDF/assets/Info.svg';

import {
  HeaderWrapper,
  Cell,
  Bold,
  Span,
} from './Header.styled';

export const HeaderComponent = () => {
  const { t } = useTranslation();

  return (
    <HeaderWrapper>
      <Cell />
      <Cell justifyContent="center">
        <Bold>{t('Shipper Load and Count')}</Bold>
      </Cell>
      <Cell justifyContent="flex-end">
        <img
          src={InfoIcon}
          height="12"
          alt=""
        />
        <Span>{t('CONTINUATION PAGE(S) FOLLOWS')}</Span>
      </Cell>
    </HeaderWrapper>
  );
};
