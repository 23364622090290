import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'app-wrapper/createSlice';
import { TUsersState } from 'user-management/models/states/Users.state';

export const initialState: TUsersState = {
  isLoading: false,
};

export const UsersStore = createSlice({
  name: 'Users',
  initialState,
  reducers: {
    setIsLoading: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      isLoading: payload,
    }),
  },
});

export const usersReducer = UsersStore.reducer;
export const usersActions = UsersStore.actions;
