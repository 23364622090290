import React from 'react';
import { useSelector } from 'react-redux';

import { R } from 'user-management/repository';
import { UC } from 'shipment-operations/controllers';

import { TradeReferencesComponent } from './TradeReferences.component';

const TradeReferencesContainer = () => {
  const organization = useSelector(R.selectors.customer.getOrganization);

  return (
    <TradeReferencesComponent
      tradeReference={organization?.tradeReference || null}
      openEditDrawer={UC.shipmentDrawer.openEditTradeReferenceDrawer}
    />
  );
};

export { TradeReferencesContainer as TradeReferences };
