import styled from 'styled-components';
import InputNumber from 'antd/es/input-number';

import {
  FieldName,
} from 'shipment-operations/view/components/ActiveCharge';

interface IInput {
  error?: number
}

export const HorizontalFieldName = styled(FieldName)`
  margin-top: 0;
  margin-bottom: 0;
`;

export const StyledInput = styled(InputNumber)<IInput>`
  width: 100%;
  border-color: ${({ error }) => error && 'red'};
  text-align: right;
  :hover {
    border-color: ${({ error }) => error && 'red'};
  }

  .ant-input-number-input {
    text-align: right;
  }
`;

export const InputsWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

export const InputsRow = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
`;

export const InputItem = styled.div`
  width: 31%;
  display: flex;
`;

export const InputItemRight = styled(InputItem)`
  justify-content: end;
`;

export const InputItemsTitle = styled(InputItemRight)`
  font-weight: 500;
  font-size: 14px;
  color: #202C3C;
`;
