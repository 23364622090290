import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import Upload from 'antd/es/upload';
import { RcFile } from 'antd/es/upload/interface';

import {
  DownloadTemplateSelect,
  NumberedStep,
  UploadMarkupPolicyButton,
} from 'app-wrapper/view/guideline';

import {
  Content,
  StepTitle,
  Divider,
} from './UploadMarkup.styled';

export interface IUploadMarkupComponentProps {
  uploadMarkupPolicy: (file: File, customerId: string) => void;
}

export const UploadMarkupComponent: FC<IUploadMarkupComponentProps> = ({ uploadMarkupPolicy }) => {
  const { t } = useTranslation();
  const { customerId = '' } = useParams();

  const handleUpload = useCallback((file: RcFile) => {
    uploadMarkupPolicy(file, customerId);
  }, [customerId, uploadMarkupPolicy]);

  return (
    <Content>
      <NumberedStep
        stepNumber={1}
        content={(
          <DownloadTemplateSelect setValue={() => null} />
        )}
      />

      <Divider />

      <NumberedStep
        stepNumber={2}
        content={(
          <StepTitle>
            {t('Complete Template Manually')}
          </StepTitle>
        )}
      />

      <Divider />

      <NumberedStep
        stepNumber={3}
        content={(
          <Upload
            showUploadList={false}
            multiple={false}
            customRequest={() => ''}
            beforeUpload={handleUpload}
          >
            <UploadMarkupPolicyButton />
          </Upload>
        )}
      />
    </Content>
  );
};
