import styled from 'styled-components';

import { Input, Select } from 'app-wrapper/view/components';
import { responsive } from 'app-wrapper/view/themes/mixin';

export const CarriersSelect = styled(Select).attrs((props: {
  isError: boolean
  'data-class'?: string
}) => ({
  isError: props.isError,
  'data-class': props?.['data-class'] || 'CarriersSelect',
}))`
  .ant-select-selector {
    ${({ isError, theme }) => isError && `border-color: ${theme.themesColors.characterBrandingDanger} !important;`};
    ${({ isError }) => isError && 'z-index: 2;'};
  }
`;

export const CarriersInput = styled(Input).attrs((props: {
  isError: boolean
  'data-class'?: string
}) => ({
  isError: props.isError,
  'data-class': props?.['data-class'] || 'CarriersInput',
}))`
  ${({ isError, theme }) => isError && `border-color: ${theme.themesColors.characterBrandingDanger} !important;`};
  ${({ isError }) => isError && 'z-index: 2;'};
`;

export const CarriersWrapper = styled.div.attrs((props: {
  isNotCollapsed: boolean
}) => ({
  isNotCollapsed: props.isNotCollapsed,
  'data-class': 'CarriersWrapper',
}))`

  ${({ isNotCollapsed }) => isNotCollapsed && `
    .RFRFormRequest__Row__Containers__content__col_add {
      padding-bottom: 7px;
    }
    .ant-col-18 {
      .RFRFormRequest__Row__Containers__content_title {
        padding-top: 4px;
      }
    }
  `}

  .RFRFormRequest {
    &__Row {
      &__Containers {

        ${responsive.forFullDesktop`
          margin-top: 0px;
        `}

        ${responsive.forFullDesktopMax`
          .ant-collapse-content-box {
            padding: 0;
          }
          .ant-collapse-content-active {
            border-top: none;
          }
          .RFRFormRequest__Row__col_both_col__title_owned {
            .TypeSwitchController__text-left {
              margin-right: 6px;
            }

            .isMedium:nth-child(1) {
              max-width: fit-content;
              margin-left: 0 !important;
            }

            .ant-switch {
              margin-left: 8px;
            }
          }

          .isMedium {
            max-width: fit-content;
            margin-left: auto !important;

            .TypeSwitchController {
              margin-left: auto;
              display: flex;
              justify-content: end;
              align-items: center;

              .Switch {
                margin-right: 0;
              }
            }
          }
        `}

        &__content__col_add {
          background-color: unset;
        }

        &__parent_content {
          &_item {
            &__medium {
              background-color: #f4f5f8;
              padding: 15px;
              border-radius: 2px;

              .Row {
                background-color: #f4f5f8;
              }

              .Col {
                background-color: #f4f5f8;
              }

              > .Col {
                margin-left: auto !important;
              }

              .ant-col:not(:first-child) {
                margin-left: auto;
              }

              .RFRFormRequest__Row__Containers__content__col_add {
                margin-left: auto !important;
                display: flex;
                align-items: center;
                justify-content: end;

                &_btn {
                  position: relative;
                  width: 24px;
                  height: 24px;
                  min-height: 24px;
                  background-color: white;
                  border-radius: 0;
                }
              }
            }
          }
        }

        .RFRFormRequest__Row__Containers__content__col_add_btn__medium {
          position: relative;
          height: 100%;
          min-height: unset;
          display: flex;
          justify-content: start;
          background: none;
          width: 125px;
          padding: 2px 5px;
          padding-left: 9px;
          border-radius: 3px;

          .addContainer__title {
            font-size: 12px;
            text-transform: capitalize;
            font-weight: 400;
            margin-left: 10px;
            color: #202c3c;
            letter-spacing: normal;
          }

          svg {
            width: 11px;
            height: 11px;
            color: #202c3c;
          }
        }

        &__content {
          &__row {
            &_second {
              margin-top: 12px;
              .ant-col-8 {
                max-width: 32%;
              }
            }
          }
        }
      }

      &__col {
        &__containersPanel {
          .ant-collapse {
            background-color: white;
            border-left: none;
            border-right: none;
            border-top: none;
            .ant-collapse-item {
              border-top: none;
            }
            .ant-collapse-header {
              padding: 16px 0px 16px 0;
            }
          }
        }
      }
    }

    .RFRFormRequest__Row__Containers__content_title {
      svg {
        color: #73819B;
      }
    }
  }

  .addContainer__title {
    font-size: 12px;
    margin-left: 10px;
  }

  .RFRFormRequest__Row__col_both__title {
    .ant-typography {
      color: ${({ theme }) => theme.themesColors.neutralBranding10};
      ${({ theme }) => theme?.fonts?.BodyTextMedium};
    }
  }

  .RFRFormRequest__Row__Containers__content .RFRFormRequest__Row__Containers__content_title .ant-typography {
    color: ${({ theme }) => theme.themesColors.neutralBranding10};
  }

  .RFRFormRequest__Row__Containers__parent_content_item input {
    color: ${({ theme }) => theme.themesColors.neutralBranding10};
  }
`;
