import React, {
  ChangeEvent,
  FC, useCallback,
} from 'react';

import { ShippingPartyReference, ShippingPartyReferenceInput } from './ShippingPartyReferenceInput.styled';

interface IShippingPartiesCompanyNameComponentProps {
  value?: string
  isError?: boolean
  onChange: (value: string) => void
}

export const ShippingPartyReferenceInputComponent: FC<IShippingPartiesCompanyNameComponentProps> = ({
  value,
  isError,
  onChange,
}) => {
  const onChangeHandle = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value);
  }, [onChange]);

  return (
    <ShippingPartyReference>
      <ShippingPartyReferenceInput
        value={value}
        onChange={onChangeHandle}
        error={isError}
      />
    </ShippingPartyReference>
  );
};
