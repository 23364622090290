import styled from 'styled-components';

import { StyledTable } from 'shipment-operations/view/components/CustomTable/CustomTable.styled';

export const TeamsTable = styled(StyledTable).attrs({
  'data-class': 'TeamsTableComponent_TeamsTable',
})`
  max-width: 1614px;
  width: 100%;
  margin: 0 auto;

  .ant-table table {
    padding: 8px 16px 0 16px;
  }

  .ant-table-thead .ant-table-cell {
    padding-top: 8px;
    padding-bottom: 8px;
    border-bottom: 1px solid #EAEEF8;
    color: #73819B;
    font-size: 12px;
    font-weight: 400;
    
    &:last-of-type {
      text-align: center;
      padding-right: 7px;
    }
  }

  .ant-table-tbody .ant-table-cell {
    padding: 15.5px 16px;
  }

  .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    display: none;
  }

  .ant-table-thead > tr > th {
    background: white;
    border-bottom: none;
  }

  .ant-table-container table > thead > tr:first-child th:last-child, .ant-table-tbody .ant-table-cell:last-child {
    padding-right: 0;
  }

  .ant-table-container table > thead > tr:first-child th:first-child {
    padding-left: 0;
  }
`;
