import React from 'react';

import { IPropsIcon } from 'app-wrapper/interfaces/icon';
import themesColors from 'app-wrapper/view/themes/themesColors';

export const EditPanSvg = (props: IPropsIcon) => {
  const { width, height, style } = props;

  return (
    <svg
      className="EditPanSvg"
      width={width || '16'}
      height={height || '15'}
      viewBox="0 0 16 15"
      color={style?.color || themesColors.secondaryDot45}
      style={style || undefined}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M3.45921 11.784C3.49492 11.784 3.53064 11.7805 3.56635 11.7751L6.56992 11.2483C6.60564 11.2412 6.63957 11.2251 6.66457 11.1983L14.2342 3.62868C14.2508 3.61216 14.2639 3.59254 14.2729 3.57094C14.2818 3.54934 14.2864 3.52618 14.2864 3.50279C14.2864 3.4794 14.2818 3.45625 14.2729 3.43464C14.2639 3.41304 14.2508 3.39342 14.2342 3.3769L11.2664 0.407254C11.2324 0.373326 11.1878 0.355469 11.1396 0.355469C11.0914 0.355469 11.0467 0.373326 11.0128 0.407254L3.44314 7.9769C3.41635 8.00368 3.40028 8.03583 3.39314 8.07154L2.86635 11.0751C2.84898 11.1708 2.85519 11.2692 2.88443 11.362C2.91368 11.4547 2.96509 11.5389 3.03421 11.6073C3.15207 11.7215 3.30028 11.784 3.45921 11.784ZM4.66278 8.66975L11.1396 2.19475L12.4485 3.50368L5.97171 9.97868L4.38421 10.259L4.66278 8.66975ZM14.5717 13.284H1.42885C1.11278 13.284 0.857422 13.5394 0.857422 13.8555V14.4983C0.857422 14.5769 0.921708 14.6412 1.00028 14.6412H15.0003C15.0789 14.6412 15.1431 14.5769 15.1431 14.4983V13.8555C15.1431 13.5394 14.8878 13.284 14.5717 13.284Z" fill="currentColor" />
    </svg>
  );
};
