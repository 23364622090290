import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { EmptyStateForPages, TableSkeleton } from 'app-wrapper/view/components';
import { PAYABLES, RECEIVABLES } from 'shipment-operations/constants';
import { Layout } from 'app-wrapper/view/guideline';

import { Header, Table } from './components';
import { EmptyState } from './Invoices.styled';

interface IInvoicesComponentProps {
  loadData: (type: string) => void
  page: number
  onClear: () => void
  onChangePagination: (page: number) => void
  isFirstTimeLoading: boolean
  isEmptyData?: boolean
  isLoading?: boolean
  type: typeof PAYABLES | typeof RECEIVABLES
}

const InvoicesComponent: FC<IInvoicesComponentProps> = ({
  loadData, page, onClear, onChangePagination, isFirstTimeLoading, type,
  isEmptyData,
  isLoading,
}) => {
  const { t } = useTranslation();
  useEffect(() => {
    if (!isFirstTimeLoading) {
      loadData(type);
    }
  }, [page]);

  useEffect(() => {
    if (page === 1) {
      loadData(type);
    }
    onChangePagination(1);
  }, [type]);

  useEffect(() => () => onClear(), []);

  if (isLoading) {
    return (
      <>
        <Header />
        <Layout>
          <TableSkeleton />
        </Layout>
      </>
    );
  }

  return (
    <>
      <Header />
      {!isLoading && isEmptyData ? (
        <EmptyState>
          <EmptyStateForPages
            headerText={t('No invoices yet.')}
            contentText={t('You have no invoices at the moment. New invoices will be displayed here when they are issued.')}
          />
        </EmptyState>
      ) : <Table type={type} />}
    </>
  );
};

export { InvoicesComponent };
