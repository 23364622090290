import styled from 'styled-components';
import AntLayout from 'antd/es/layout';

import themesColors from 'app-wrapper/view/themes/themesColors';

export const AccountLayout = styled(AntLayout)`
  display: flex;
  flex-direction: column;

  margin: 0;
  height: calc(100vh - 48px);
  overflow: hidden;
  background: ${({ theme }) => theme?.themesColors?.lightGrayBorder};

  .ant-layout-sider-children {
    button {
      z-index: 10;
    }
  }
`;

export const AccountHeader = styled(AntLayout.Header)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0;
  padding: 14px 24px;
  height: 56px;
  
  box-shadow: inset 0px -1px 0px #EAEEF8;
  background-color: ${themesColors.neutralBranding1};
  
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
`;

export const AccountContent = styled(AntLayout)`
  margin: 0;
  height: 100%;
  background: ${({ theme }) => theme?.themesColors?.lightGrayBorder};
`;
