import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import { SelectUseCase } from 'app-wrapper/usecases/Select.useCase';
import { BaseController } from 'proto/BaseController';
import { TOrganizationFieldsErrorsFieldDTM } from 'user-management/models/dtm';

export class CountrySelectUseCase extends SelectUseCase {
  setStateValue: ActionCreatorWithPayload<string | undefined, string>

  constructor(
    controller: BaseController,
    setValue: ActionCreatorWithPayload<string | undefined, string>,
    setError: ActionCreatorWithPayload<TOrganizationFieldsErrorsFieldDTM, string>,
    validate: () => void,
    setStateValue: ActionCreatorWithPayload<string | undefined, string>,
  ) {
    super(controller, setValue, setError, validate);
    this.setStateValue = setStateValue;
  }

  onChange(value: string) {
    const validValue = value.slice(0, 50);
    this.controller.dispatch(this.setValue(validValue));

    this.controller.dispatch(this.setError({
      isFocus: true,
      isBlur: false,
    }));

    this.controller.dispatch(this.setStateValue(undefined));

    this.validate();
  }

  onClear() {
    this.controller.dispatch(this.setValue(undefined));

    this.controller.dispatch(this.setError({
      isFocus: true,
      isBlur: false,
    }));

    this.controller.dispatch(this.setStateValue(undefined));

    this.validate();
  }
}
