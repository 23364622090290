import React, {
  FC,
  useRef,
  useCallback,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useReactToPrint } from 'react-to-print';

import { InvoicePDFDocument } from 'shipment-operations/view/components/ShareInvoice/components';

import { MenuItem } from './PrintInvoice.styled';

interface ShareInvoiceComponentProps {
  shipmentId: string;
  fileNamePrint?: string;
  invoiceId?: number;
  isLoaded: boolean;
  isLoading: boolean;
  fetchData: (shipmentId: string, invoiceId?: number) => void;
  isAPInvoiceType: boolean;
}

const OLD_TITLE = document.title;

export const PrintInvoiceComponent: FC<ShareInvoiceComponentProps> = ({
  shipmentId,
  fileNamePrint,
  invoiceId,
  isLoaded,
  isLoading,
  fetchData,
  isAPInvoiceType,
}) => {
  const { t } = useTranslation();
  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    // @ts-ignore
    content: () => componentRef.current,
    onAfterPrint: () => {
      // support chrome
      document.title = OLD_TITLE;
    },
  });

  const handleClick = useCallback(async () => {
    if (isLoading) {
      return;
    }

    await fetchData(shipmentId, invoiceId);
    // support chrome
    document.title = fileNamePrint || OLD_TITLE;
    handlePrint();
  }, [shipmentId, invoiceId, fetchData, isLoading, handlePrint, fileNamePrint]);

  return (
    <>
      <div style={{ display: 'none' }}>
        {/* @ts-ignore */}
        {isLoaded && (
          <InvoicePDFDocument
            fileNamePrint={fileNamePrint}
            ref={componentRef}
            isAPInvoiceType={isAPInvoiceType}
          />
        )}
      </div>

      <MenuItem onClick={handleClick} disabled={isLoading}>
        {t('Print')}
      </MenuItem>
    </>
  );
};
