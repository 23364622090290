import React, {
  ChangeEvent, FC, useCallback,
} from 'react';
import { useTranslation } from 'react-i18next';

import { Input, Tooltip } from 'app-wrapper/view/components';

import {
  ChangePasswordItem,
} from '../../UserEditProfile.styled';

interface ITransportationLocationDataComponentProps {
  isLoading: boolean
  value?: string
  error?: Error
  onChange: (value: string) => void
  onBlur: () => void
  title: string
  dataClass?: string
}

export const InputComponent: FC<ITransportationLocationDataComponentProps> = ({
  isLoading,
  value,
  error,
  onChange,
  onBlur,
  title,
  dataClass,
}) => {
  const onFirstNameChangeHandler = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value);
  }, []);
  const { t } = useTranslation();

  const onFirstNameBlurHandler = useCallback(() => {
    onBlur();
  }, [onBlur]);

  const isVisibleTooltip = !!error && error?.message !== t('basicErrors.REQUIRED_MESSAGE');

  return (
    <ChangePasswordItem>
      <span>{title}</span>
      <Tooltip mode="danger" title={error?.message} visible={isVisibleTooltip}>
        <Input
          data-class={dataClass}
          maxLength={50}
          disabled={isLoading}
          value={value}
          error={!!error}
          onChange={onFirstNameChangeHandler}
          onBlur={onFirstNameBlurHandler}
        />
      </Tooltip>
    </ChangePasswordItem>
  );
};
