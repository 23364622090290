import { createSelector } from 'reselect';

import { RootState } from 'app-wrapper/store';

const localState = (state: RootState) => state.monetaryModuleFunctionalityPermissions;

const getRFQNewQuotaPermissions = createSelector(
  localState,
  (state) => state.permissions?.RFQNewQuotaAvailability,
);

const getRequestAllQuotasPermissions = createSelector(
  localState,
  (state) => state.permissions?.requestAllQuotas,
);

const getShipmentChargesPermissions = createSelector(
  localState,
  (state) => state.permissions?.shipmentCharges.shipmentChargesAvailability,
);

const getChargesEditPayablesPaymentPermissions = createSelector(
  localState,
  (state) => state.permissions?.shipmentCharges.editPayablesPayment,
);

const getRatesClearCacheAvailabilityPermissions = createSelector(
  localState,
  (state) => state.permissions?.ratesUpload?.ratesClearCacheAvailability,
);

export const moduleFunctionalityPermissionsSelectors = {
  getRFQNewQuotaPermissions,
  getRequestAllQuotasPermissions,
  getShipmentChargesPermissions,
  getRatesClearCacheAvailabilityPermissions,
  getChargesEditPayablesPaymentPermissions,
};
