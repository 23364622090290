import { plainToInstance, Type } from 'class-transformer';
import {
  IsOptional,
  IsString,
  IsBoolean,
  ValidateNested,
  IsNumber,
} from 'class-validator';
import { BaseDTM } from 'proto/BaseDTM';

import {
  AdditionalServiceCheckedDTM, AdditionalServiceDTM, IAdditionalServiceCheckedDTM, IAdditionalServiceDTM, IQuotaBookmark, QuotaBookmark,
} from '../Quotas';
import { FreightSelectFieldDTM, IFreightSelectFieldDTM } from './freightSelectField.dtm';

interface IFreightFromLocationStateDatePortDTM {
  earliestDate?: string
  latestDate?: string
}

export class FreightFromLocationStateDatePortDTM extends BaseDTM<IFreightFromLocationStateDatePortDTM> {
  @IsOptional()
  @IsString()
  earliestDate?: string;

  @IsOptional()
  @IsString()
  latestDate?: string;
}

export interface IFreightFromLocationStateDateDoorDTM {
  pickupTime?: string
  dropTime?: string
}

export class FreightFromLocationStateDateDoorDTM extends BaseDTM<IFreightFromLocationStateDateDoorDTM> {
  @IsOptional()
  @IsString()
  pickupTime?: string;

  @IsOptional()
  @IsString()
  dropTime?: string;
}

export interface IFreightFromLocationStateDTM {
  isPort?: boolean
  location?: IFreightSelectFieldDTM
  locationTimezoneId?: string
  locationValues?: IFreightSelectFieldDTM[]
  datePort?: IFreightFromLocationStateDatePortDTM
  dateDoor?: IFreightFromLocationStateDateDoorDTM
}

export class FreightFromLocationStateDTM extends BaseDTM<IFreightFromLocationStateDTM> {
  @IsOptional()
  @IsBoolean()
  isPort?: boolean;

  @IsOptional()
  @ValidateNested()
  @Type(() => FreightSelectFieldDTM)
  location?: FreightSelectFieldDTM;

  @IsOptional()
  @IsString()
  locationTimezoneId?: string;

  @IsOptional()
  @ValidateNested({ each: true })
  @Type(() => FreightSelectFieldDTM)
  locationValues?: FreightSelectFieldDTM[];

  @IsOptional()
  @ValidateNested()
  @Type(() => FreightFromLocationStateDatePortDTM)
  datePort?: FreightFromLocationStateDatePortDTM;

  @IsOptional()
  @ValidateNested()
  @Type(() => FreightFromLocationStateDateDoorDTM)
  dateDoor?: FreightFromLocationStateDateDoorDTM;
}

interface IFreightFromCommodityValuesStateDTM {
  id?: string
  hsCode?: IFreightSelectFieldDTM
  goodsValue?: string
  IMOClass?: string
  UNNumber?: string
}

export class FreightFromCommodityValuesStateDTM extends BaseDTM<IFreightFromCommodityValuesStateDTM> {
  @IsOptional()
  @IsString()
  id?: string;

  @IsOptional()
  @ValidateNested()
  @Type(() => FreightSelectFieldDTM)
  hsCode?: FreightSelectFieldDTM;

  @IsOptional()
  @IsString()
  goodsValue?: string;

  @IsOptional()
  @IsString()
  IMOClass?: string;

  @IsOptional()
  @IsString()
  UNNumber?: string;
}

export interface IFreightFromCommodityStateDTM {
  temperatureControl?: boolean
  values?: IFreightFromCommodityValuesStateDTM[]
  code?: string
  description?: string
}

export class FreightFromCommodityStateDTM extends BaseDTM<IFreightFromCommodityStateDTM> {
  @IsOptional()
  @IsBoolean()
  temperatureControl?: boolean;

  @IsOptional()
  @ValidateNested({ each: true })
  @Type(() => FreightFromCommodityValuesStateDTM)
  values?: FreightFromCommodityValuesStateDTM[];

  @IsOptional()
  @IsString()
  code?: string;

  @IsOptional()
  @IsString()
  description?: string;
}

interface IFreightFromContainersValuesStateDTM {
  id?: string
  type?: IFreightSelectFieldDTM
  quantity?: string
  weight?: string
  volume?: string
}

export class FreightFromContainersValuesStateDTM extends BaseDTM<IFreightFromContainersValuesStateDTM> {
  @IsOptional()
  @IsString()
  id?: string;

  @IsOptional()
  @ValidateNested()
  @Type(() => FreightSelectFieldDTM)
  type?: FreightSelectFieldDTM;

  @IsOptional()
  @IsString()
  quantity?: string;

  @IsOptional()
  @IsString()
  weight?: string;

  @IsOptional()
  @IsString()
  volume?: string;
}

interface IFreightFromContainersStateDTM {
  isMetric?: boolean
  socControl?: boolean
  gensetControl?: boolean
  values?: IFreightFromContainersValuesStateDTM[]
}

export class FreightFromContainersStateDTM extends BaseDTM<IFreightFromContainersStateDTM> {
  @IsOptional()
  @IsBoolean()
  isMetric?: boolean;

  @IsOptional()
  @IsBoolean()
  socControl?: boolean;

  @IsOptional()
  @IsBoolean()
  gensetControl?: boolean;

  @IsOptional()
  @ValidateNested({ each: true })
  @Type(() => FreightFromContainersValuesStateDTM)
  values?: FreightFromContainersValuesStateDTM[];
}

interface IFreightFromServicesStateDTM {
  status?: string
  isReuseRequest?: boolean
  printedQuotaId?: number
  values?: IAdditionalServiceDTM[]
  valuesChecked?: string[]
  valuesServiceChecked?: IAdditionalServiceCheckedDTM[]
}

export class FreightFromServicesStateDTM extends BaseDTM<IFreightFromServicesStateDTM> {
  @IsOptional()
  @IsString()
  status?: string;

  @IsOptional()
  @IsBoolean()
  isReuseRequest?: boolean;

  @IsOptional()
  @ValidateNested({ each: true })
  @Type(() => AdditionalServiceDTM)
  values?: AdditionalServiceDTM[];

  @IsOptional()
  @IsNumber()
  printedQuotaId?: number;

  @IsOptional()
  @IsString()
  valuesChecked?: string[];

  @IsOptional()
  @ValidateNested({ each: true })
  @Type(() => AdditionalServiceCheckedDTM)
  valuesServiceChecked?: AdditionalServiceCheckedDTM[];
}

interface IFreightFromClientStateDTM {
  companyName?: IFreightSelectFieldDTM
}

export class FreightFromClientStateDTM extends BaseDTM<IFreightFromClientStateDTM> {
  @IsOptional()
  @ValidateNested()
  @Type(() => FreightSelectFieldDTM)
  companyName?: FreightSelectFieldDTM;
}

export interface IFreightFromStateDTM {
  origin?: IFreightFromLocationStateDTM
  destination?: IFreightFromLocationStateDTM
  commodity?: IFreightFromCommodityStateDTM
  containers?: IFreightFromContainersStateDTM
  services?: IFreightFromServicesStateDTM
  client?: IFreightFromClientStateDTM
  bookmark?: IQuotaBookmark
  isIncludeRelatedPort?: boolean
}

export class FreightFromStateDTM extends BaseDTM<IFreightFromStateDTM> {
  @IsOptional()
  @ValidateNested()
  @Type(() => FreightFromLocationStateDTM)
  origin?: FreightFromLocationStateDTM;

  @IsOptional()
  @ValidateNested()
  @Type(() => FreightFromLocationStateDTM)
  destination?: FreightFromLocationStateDTM;

  @IsOptional()
  @ValidateNested()
  @Type(() => FreightFromCommodityStateDTM)
  commodity?: FreightFromCommodityStateDTM;

  @IsOptional()
  @ValidateNested()
  @Type(() => FreightFromContainersStateDTM)
  containers?: FreightFromContainersStateDTM;

  @IsOptional()
  @ValidateNested()
  @Type(() => FreightFromServicesStateDTM)
  services?: FreightFromServicesStateDTM;

  @IsOptional()
  @ValidateNested()
  @Type(() => FreightFromClientStateDTM)
  client?: FreightFromClientStateDTM;

  @IsOptional()
  @ValidateNested()
  @Type(() => QuotaBookmark)
  bookmark?: QuotaBookmark;

  @IsOptional()
  @IsBoolean()
  isIncludeRelatedPort?: boolean;

  static createEmpty = () => plainToInstance(FreightFromStateDTM, {
    origin: {
      isPort: true,
    },
    destination: {
      isPort: true,
    },
    commodity: {
      temperatureControl: false,
    },
    containers: {
      isMetric: true,
      socControl: false,
    },
    isIncludeRelatedPort: false,
  });
}
