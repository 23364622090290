import styled from 'styled-components';
import { MessageOutlined } from '@ant-design/icons';

import { Tooltip } from 'app-wrapper/view/components';

export const TooltipStyled = styled(Tooltip)`
  display: flex;
  margin-left: 8px;

  &.ant-tooltip-placement-bottom {
    padding-top: 0;
  }
`;

export const Icon = styled(MessageOutlined)`
  color: rgba(115, 129, 155, 1);

  svg {
    margin-top: 3px;
  }
`;
