import { QUOTAS_STATUS } from 'app-wrapper/constants';

import { IARQQuotesItem } from '../AllRequestedQuotesComponentQuotesItem';

export const testDataAllRequestedQuotesMock: IARQQuotesItem[] = [
  {
    name: 'name',
    id: 123567,
    status: QUOTAS_STATUS.complete,
    type: 'port',
    origin: {
      name: 'Origin',
      address: 'Manhattan, NY, US',
      timezone: 'ETD',
      date: '20 Feb, 8:21 AM',
      type: 'door',
    },
    destination: {
      name: 'Destination',
      address: 'Nha Trang, VM',
      timezone: 'ETA',
      date: '28 Feb, 8:21 AM',
      type: 'port',
    },
    container: {
      type: 'FLC',
      count: 2,
    },
    containersType: [
      'PO5565234',
      'PO5565234',
      'PO5565234',
      'PO5565234',
    ],
    profile: {
      name: 'Vladimir Nikolaev',
      position: 'Maxton Shipping',
    },
  },
  {
    name: 'name',
    id: 123567,
    status: QUOTAS_STATUS.executing,
    type: 'port',
    origin: {
      name: 'Origin',
      address: 'Manhattan, NY, US',
      timezone: 'ETD',
      date: '20 Feb, 8:21 AM',
      type: 'door',
    },
    destination: {
      name: 'Destination',
      address: 'Nha Trang, VM',
      timezone: 'ETA',
      date: '28 Feb, 8:21 AM',
      type: 'port',
    },
    container: {
      type: 'FLC',
      count: 2,
    },
    containersType: [
      'PO5565234',
      'PO5565234',
      'PO5565234',
      'PO5565234',
    ],
    profile: {
      name: 'Vladimir Nikolaev',
      position: 'Maxton Shipping',
    },
  },
  {
    name: 'name',
    id: 123567,
    status: QUOTAS_STATUS.failed,
    type: 'port',
    origin: {
      name: 'Origin',
      address: 'Manhattan, NY, US',
      timezone: 'ETD',
      date: '20 Feb, 8:21 AM',
      type: 'door',
    },
    destination: {
      name: 'Destination',
      address: 'Nha Trang, VM',
      timezone: 'ETA',
      date: '28 Feb, 8:21 AM',
      type: 'port',
    },
    container: {
      type: 'FLC',
      count: 2,
    },
    containersType: [
      'PO5565234',
      'PO5565234',
      'PO5565234',
      'PO5565234',
    ],
    profile: {
      name: 'Vladimir Nikolaev',
      position: 'Maxton Shipping',
    },
  },
  {
    name: 'name',
    id: 123567,
    status: QUOTAS_STATUS.pending,
    type: 'port',
    origin: {
      name: 'Origin',
      address: 'Manhattan, NY, US',
      timezone: 'ETD',
      date: '20 Feb, 8:21 AM',
      type: 'door',
    },
    destination: {
      name: 'Destination',
      address: 'Nha Trang, VM',
      timezone: 'ETA',
      date: '28 Feb, 8:21 AM',
      type: 'port',
    },
    container: {
      type: 'FLC',
      count: 2,
    },
    containersType: [
      'PO5565234',
      'PO5565234',
      'PO5565234',
      'PO5565234',
    ],
    profile: {
      name: 'Vladimir Nikolaev',
      position: 'Maxton Shipping',
    },
  },
];
