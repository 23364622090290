import React, { FC, useMemo } from 'react';

import {
  ContainerReeferTypes,
  ContainerTypesConst,
  ContainerUsualTypes,
} from 'shipment-operations/constants';

import {
  Wrapper,
  Text,
  ContainerIconStyled,
} from './ContainerName.styled';

interface ContainerNameComponentProps {
  containerType: ContainerReeferTypes | ContainerUsualTypes;
}

export const ContainerNameComponent: FC<ContainerNameComponentProps> = ({ containerType }) => {
  const containerName = useMemo(() => `${ContainerTypesConst[containerType]}${'`'}`, [containerType]);

  return (
    <Wrapper>
      <ContainerIconStyled />

      <Text>
        {containerName}
      </Text>
    </Wrapper>
  );
};
