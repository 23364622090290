import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { UC } from 'monetary/controllers';
import { R } from 'monetary/repository';
import { FreightQuotaContentDTM } from 'monetary/models/dtm';

import { UC as shipmentOperationsUC } from 'shipment-operations/controllers';
import { R as shipmentOperationsR } from 'shipment-operations/repository';

import { PrintNRAAgreementRFQComponent } from './PrintNRAAgreementRFQ.component';

interface PrintNRAAgreementRFQContainerProps {
  showAsLink?: boolean;
  shouldPrintCustomerToAdminNRA?: boolean;
  shouldPrintContractOwnerToAdminNRA?: boolean;
  shouldPrintAdminToAdminNRA?: boolean;
}

const PrintNRAAgreementRFQContainer: FC<PrintNRAAgreementRFQContainerProps> = ({
  showAsLink,
  shouldPrintCustomerToAdminNRA,
  shouldPrintAdminToAdminNRA,
  shouldPrintContractOwnerToAdminNRA,
}) => {
  const isLoading = useSelector(shipmentOperationsR.selectors.shipmentDocumentsAll.getIsRFQLoading);
  const quotaIndex = useSelector(shipmentOperationsR.selectors.shipmentDocumentsAll.getQuoteIndex);
  const shipmentIdForRFQCase = useSelector(shipmentOperationsR.selectors.bookingWizard.getShipmentId);
  const getShipmentNRAFileNamePrint = useSelector(shipmentOperationsR.selectors.bookingWizard.getShipmentNRAFileNamePrint);
  const allQuotasByIndexFileNamePrint = useSelector(R.selectors.freightQuote.getFreightQuoteCurrentAllQuotasByIndex(quotaIndex));
  const quota = FreightQuotaContentDTM.fromPlain({
    ...allQuotasByIndexFileNamePrint,
  });
  const fileName = `${getShipmentNRAFileNamePrint}.${quota?.getOriginLocationUNLOCK()} - ${quota?.getDestinationLocationUNLOCK()}`;

  return (
    <PrintNRAAgreementRFQComponent
      isLoading={isLoading}
      fileNamePrint={fileName}
      quotaIndex={quotaIndex}
      onPrint={UC.FreightQuote.onPrintAtDocumentsAllPage}
      onClearCustomerInfo={UC.FreightQuote.onClearCustomerInfo}
      loadData={shipmentOperationsUC.shipmentDocumentsAll.loadQuoteDocumentInformation}
      loadDataForLinkCase={shipmentOperationsUC.nraAgreement.loadData}
      showAsLink={showAsLink}
      shipmentIdForRFQCase={shipmentIdForRFQCase}
      shouldPrintCustomerToAdminNRA={shouldPrintCustomerToAdminNRA}
      shouldPrintAdminToAdminNRA={shouldPrintAdminToAdminNRA}
      shouldPrintContractOwnerToAdminNRA={shouldPrintContractOwnerToAdminNRA}
    />
  );
};

export { PrintNRAAgreementRFQContainer as PrintNRAAgreementRFQ };
