import styled from 'styled-components';

export const ShippingPartiesForm = styled.form`
  height: 100%;
  background: ${({ theme }) => theme.bookingPage.shippingPartiesStep.bg};
  color: ${({ theme }) => theme.bookingPage.shippingPartiesStep.textColor};
  padding: 16px 16px 30px 16px;
  overflow-y: auto;
  position: absolute;
  right: 4px;
  left: 4px;

  .ant-input,
  .ant-select,
  .ant-select-selection-item {
    font-size: 12px !important;
  }
`;
