import styled from 'styled-components';

import { SubPageContent, FlexRow } from 'app-wrapper/view/guideline';

export const Content = styled(SubPageContent)``;

export const Header = styled(FlexRow)`
  margin-bottom: 16px;
  justify-content: space-between;
`;
