import { createSelector } from 'reselect';

import { RootState } from 'app-wrapper/store';
import { DocumentType } from 'shipment-operations/constants';
import { EOrganizationType } from 'user-management/constants';

const localState = (state: RootState) => state.newOrganizationDocuments;

const getLoading = createSelector(
  localState,
  (state) => state.isLoading,
);

const getIsUploadLoading = createSelector(
  localState,
  (state) => state.isUploadLoading,
);

const getDocumentsTypesMap = createSelector(
  localState,
  (state) => state.documentsTypeMap,
);

const getOrganizationId = createSelector(
  localState,
  (state) => state.organizationId,
);

const getDocumentToUpload = createSelector(
  localState,
  (state) => state.documentToUpload,
);

const getDocumentToUploadType = createSelector(
  localState,
  (state) => state.documentToUploadType,
);

const getDocuments = createSelector(
  localState,
  (state) => state.documents,
);

const getDocumentById = (documentId: number) => createSelector(
  localState,
  getDocuments,
  (state, documents) => documents.find((document) => document.id === documentId),
);

const getIsOrganizationAdmin = createSelector(
  localState,
  (state) => state.isOrganizationAdmin,
);

const getHasRequiredDocuments = createSelector(
  getDocuments,
  (documents) => {
    const docsALO = documents.find(({ type }) => type === DocumentType.ALO);
    const docsCCI = documents.find(({ type }) => type === DocumentType.CCI);
    const docsBLIC = documents.find(({ type }) => type === DocumentType.BLIC);
    const docsTCER = documents.find(({ type }) => type === DocumentType.TCER);

    return Boolean(docsALO && docsCCI && docsBLIC && docsTCER);
  },
);

const getOrganizationType = createSelector(
  localState,
  (state) => state.organizationType,
);

const getShouldRequireBLICDocument = createSelector(
  getOrganizationType,
  (orgType) => Boolean(orgType && (orgType === EOrganizationType.NVOCC || orgType === EOrganizationType.FF)),
);

const getIsRequiredWarningShown = createSelector(
  localState,
  (state) => state.isRequiredWarningShown,
);

const getDocumentsToDeleteIds = createSelector(
  localState,
  (state) => state.documentsToRemoveIds,
);

export const newOrganizationDocumentsSelectors = {
  getLoading,
  getIsUploadLoading,
  getDocumentsTypesMap,
  getDocumentToUpload,
  getDocumentToUploadType,
  getDocuments,
  getDocumentById,
  getIsOrganizationAdmin,
  getOrganizationId,
  getHasRequiredDocuments,
  getOrganizationType,
  getShouldRequireBLICDocument,
  getIsRequiredWarningShown,
  getDocumentsToDeleteIds,
};
