import React, { FC, useEffect } from 'react';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { PlusOutlined } from '@ant-design/icons';
import Spin from 'antd/es/spin';

import {
  OrganizationMemberDTM,
  TeamDTM,
  TeamMemberDTM,
} from 'user-management/models/dtm';
import { Button } from 'app-wrapper/view/components';
import { Content } from 'user-management/view/pages/TeamPage/components/TeamMembers/TeamMembers.styled';
import { SpinnerWrap, TeamMemberCard } from 'app-wrapper/view/guideline';
import { TeamView, AddOrChangeTeamDrawer } from 'user-management/view/components';

import {
  Page,
  NoTeam,
  NoTeamImage,
  NoTeamPage,
  NoTeamTitle,
} from './CustomerInternalTeam.styled';
import noTeamImageSrc from './no-team.png';

interface ICustomerTeamViewComponentProps {
  team?: TeamDTM;
  teamMembers: TeamMemberDTM[];
  accountManager?: OrganizationMemberDTM;
  isLoading: boolean;
  init: (customerId: string) => void;
  openAddTeamDrawer: () => void;
  isAdmin?: boolean;
}

export const CustomerInternalTeamComponent: FC<ICustomerTeamViewComponentProps> = ({
  team,
  accountManager,
  isLoading,
  openAddTeamDrawer,
  teamMembers,
  init,
  isAdmin,
}) => {
  const { customerId = '' } = useParams();
  const { t } = useTranslation();

  useEffect(() => {
    init(customerId);
  }, []);

  return !isLoading ? (
    <Page>
      <AddOrChangeTeamDrawer />

      {team ? (
        <>
          <TeamView
            accountManager={accountManager}
            teamName={team?.name}
            changeTeam={openAddTeamDrawer}
            isAdmin={isAdmin}
          />

          <Content>
            {teamMembers.map((member) => (
              <TeamMemberCard key={member.id} teamMember={member} />
            ))}
          </Content>
        </>
      ) : (
        <NoTeamPage>
          <NoTeam>
            <NoTeamImage src={noTeamImageSrc} />
            <NoTeamTitle>
              {t('Teams have not yet been selected')}
            </NoTeamTitle>

            <Button
              onClick={openAddTeamDrawer}
              icon={<PlusOutlined />}
            >
              {t('Add Team')}
            </Button>
          </NoTeam>
        </NoTeamPage>
      )}
    </Page>
  ) : (
    <SpinnerWrap>
      <Spin size="large" />
    </SpinnerWrap>
  );
};
