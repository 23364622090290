export enum PackageType {
  AEROSOL = 'AEROSOL',
  AMPOULE_NON_PROTECTED = 'AMPOULE_NON_PROTECTED',
  AMPOULE_PROTECTED = 'AMPOULE_PROTECTED',
  ATOMIZER = 'ATOMIZER',
  BAG = 'BAG',
  BALE_COMPRESSED = 'BALE_COMPRESSED',
  BALE_NON_COMPRESSED = 'BALE_NON_COMPRESSED',
  BALLOON_NON_PROTECTED = 'BALLOON_NON_PROTECTED',
  BALLOON_PROTECTED = 'BALLOON_PROTECTED',
  BAR = 'BAR',
  BARREL = 'BARREL',
  BARS_IN_BUNDLE_BUNCH_TRUSS = 'BARS_IN_BUNDLE_BUNCH_TRUSS',
  BASKET = 'BASKET',
  BEER_CRATE = 'BEER_CRATE',
  BIN = 'BIN',
  BOARD = 'BOARD',
  BOARD_IN_BUNDLE_BUNCH_TRUSS = 'BOARD_IN_BUNDLE_BUNCH_TRUSS',
  BOBBIN = 'BOBBIN',
  BOLT = 'BOLT',
  BOTTLE_CRATE = 'BOTTLE_CRATE',
  BOTTLE_NON_PROTECTED_BULBOUS = 'BOTTLE_NON_PROTECTED_BULBOUS',
  BOTTLE_NON_PROTECTED_CYLINDRICAL = 'BOTTLE_NON_PROTECTED_CYLINDRICAL',
  BOTTLE_PROTECTED_BULBOUS = 'BOTTLE_PROTECTED_BULBOUS',
  BOTTLE_PROTECTED_CYLINDRICAL = 'BOTTLE_PROTECTED_CYLINDRICAL',
  BOX = 'BOX',
  BUCKET = 'BUCKET',
  BULK_GAS_1031_MBAR_15C = 'BULK_GAS_1031_MBAR_15C',
  BULK_LIQUEFIED_GAS_ABNORMAL_TEMPERATURE_PRESSURE = 'BULK_LIQUEFIED_GAS_ABNORMAL_TEMPERATURE_PRESSURE',
  BULK_LIQUID = 'BULK_LIQUID',
  BULK_SOLID_FINE_PARTICLES_POWDERS = 'BULK_SOLID_FINE_PARTICLES_POWDERS',
  BULK_SOLID_GRANULAR_PARTICLES_GRAINS = 'BULK_SOLID_GRANULAR_PARTICLES_GRAINS',
  BULK_SOLID_LARGE_PARTICLES_NODULES = 'BULK_SOLID_LARGE_PARTICLES_NODULES',
  BUNCH = 'BUNCH',
  BUNDLE = 'BUNDLE',
  BUTT = 'BUTT',
  CAGE = 'CAGE',
  CANISTER = 'CANISTER',
  CANVAS = 'CANVAS',
  CAN_CYLINDRICAL = 'CAN_CYLINDRICAL',
  CAN_RECTANGULAR = 'CAN_RECTANGULAR',
  CARBOY_NON_PROTECTED = 'CARBOY_NON_PROTECTED',
  CARBOY_PROTECTED = 'CARBOY_PROTECTED',
  CARTON = 'CARTON',
  CASE = 'CASE',
  CASK = 'CASK',
  CHEST = 'CHEST',
  CHURN = 'CHURN',
  COFFER = 'COFFER',
  COFFIN = 'COFFIN',
  COIL = 'COIL',
  COVER = 'COVER',
  CRATE = 'CRATE',
  CREEL = 'CREEL',
  CUP = 'CUP',
  CYLINDER = 'CYLINDER',
  DEMIJOHN_NON_PROTECTED = 'DEMIJOHN_NON_PROTECTED',
  DEMIJOHN_PROTECTED = 'DEMIJOHN_PROTECTED',
  DRUM = 'DRUM',
  ENVELOPE = 'ENVELOPE',
  FILM_PACK = 'FILM_PACK',
  FIRKIN = 'FIRKIN',
  FLASK = 'FLASK',
  FOOTLOCKER = 'FOOTLOCKER',
  FRAME = 'FRAME',
  FRAMED_CRATE = 'FRAMED_CRATE',
  FRUIT_CRATE = 'FRUIT_CRATE',
  GAS_BOTTLE = 'GAS_BOTTLE',
  GIRDER = 'GIRDER',
  GIRDERS_IN_BUNDLE_BUNCH_TRUSS = 'GIRDERS_IN_BUNDLE_BUNCH_TRUSS',
  HAMPER = 'HAMPER',
  HOGSHEAD = 'HOGSHEAD',
  INGOT = 'INGOT',
  INGOTS_IN_BUNDLE_BUNCH_TRUSS = 'INGOTS_IN_BUNDLE_BUNCH_TRUSS',
  JAR = 'JAR',
  JERRI_CAN_CYLINDRICAL = 'JERRI_CAN_CYLINDRICAL',
  JERRI_CAN_RECTANGULAR = 'JERRI_CAN_RECTANGULAR',
  JUG = 'JUG',
  JUTE_BAG = 'JUTE_BAG',
  KEG = 'KEG',
  LOG = 'LOG',
  LOGS_IN_BUNDLE_BUNCH_TRUSS = 'LOGS_IN_BUNDLE_BUNCH_TRUSS',
  MAT = 'MAT',
  MATCH_BOX = 'MATCH_BOX',
  MILK_CRATE = 'MILK_CRATE',
  MULTIPLY_BAG = 'MULTIPLY_BAG',
  MULTIWALL_SACK = 'MULTIWALL_SACK',
  NEST = 'NEST',
  NET = 'NET',
  PALLET = 'PALLET',
  PACKET = 'PACKET',
  PAIL = 'PAIL',
  PARCEL = 'PARCEL',
  PIPE = 'PIPE',
  PITCHER = 'PITCHER',
  PIECE = 'PIECE',
  PIECES = 'PIECES',
  PLANK = 'PLANK',
  PLANKS_PIPES_IN_BUNDLE_BUNCH_TRUSS = 'PLANKS_PIPES_IN_BUNDLE_BUNCH_TRUSS',
  PLATE = 'PLATE',
  PLATES_IN_BUNDLE_BUNCH_TRUSS = 'PLATES_IN_BUNDLE_BUNCH_TRUSS',
  POT = 'POT',
  POUCH = 'POUCH',
  REDNET = 'REDNET',
  REEL = 'REEL',
  RING = 'RING',
  ROD = 'ROD',
  RODS_IN_BUNDLE_BUNCH_TRUSS = 'RODS_IN_BUNDLE_BUNCH_TRUSS',
  ROLL = 'ROLL',
  SACHET = 'SACHET',
  SACK = 'SACK',
  SEA_CHEST = 'SEA_CHEST',
  SHALLOW_CRATE = 'SHALLOW_CRATE',
  SHEET = 'SHEET',
  SHEETMETAL = 'SHEETMETAL',
  SHEETS_IN_BUNDLE_BUNCH_TRUSS = 'SHEETS_IN_BUNDLE_BUNCH_TRUSS',
  SHRINKWRAPPED = 'SHRINKWRAPPED',
  SKELETON_CASE = 'SKELETON_CASE',
  SLIPSHEET = 'SLIPSHEET',
  SPINDLE = 'SPINDLE',
  SUITCASE = 'SUITCASE',
  TANK_CYLINDRICAL = 'TANK_CYLINDRICAL',
  TANK_RECTANGULAR = 'TANK_RECTANGULAR',
  TEA_CHEST = 'TEA_CHEST',
  TIN = 'TIN',
  TRAY = 'TRAY',
  TRUNK = 'TRUNK',
  TRUSS = 'TRUSS',
  TUB = 'TUB',
  TUBE = 'TUBE',
  TUBES_IN_BUNDLE_BUNCH_TRUSS = 'TUBES_IN_BUNDLE_BUNCH_TRUSS',
  TUBE_COLLAPSIBLE = 'TUBE_COLLAPSIBLE',
  TUN = 'TUN',
  UNPACKED = 'UNPACKED',
  VACUUM_PACKED = 'VACUUM_PACKED',
  VAT = 'VAT',
  VIAL = 'VIAL',
  WICKER_BOTTLE = 'WICKER_BOTTLE',
}

export const PackageTypeNames = {
  [PackageType.AEROSOL]: 'Aerosol',
  [PackageType.AMPOULE_NON_PROTECTED]: 'Ampoule, non-protected',
  [PackageType.AMPOULE_PROTECTED]: 'Ampoule, protected',
  [PackageType.ATOMIZER]: 'Atomizer',
  [PackageType.BAG]: 'Bag',
  [PackageType.BAG]: 'Bag',
  [PackageType.BALE_COMPRESSED]: 'Bale, compressed',
  [PackageType.BALE_NON_COMPRESSED]: 'Bale, non-compressed',
  [PackageType.BALLOON_NON_PROTECTED]: 'Balloon, non-protected',
  [PackageType.BALLOON_PROTECTED]: 'Balloon, protected',
  [PackageType.BAR]: 'Bar',
  [PackageType.BARREL]: 'Barrel',
  [PackageType.BARS_IN_BUNDLE_BUNCH_TRUSS]: 'Bars, in bundle/bunch/truss',
  [PackageType.BASKET]: 'Basket',
  [PackageType.BEER_CRATE]: 'Beer crate',
  [PackageType.BIN]: 'Bin',
  [PackageType.BOARD_IN_BUNDLE_BUNCH_TRUSS]: 'Board, inbundle/bunch/truss',
  [PackageType.BOARD]: 'Board',
  [PackageType.BOBBIN]: 'Bobbin',
  [PackageType.BOLT]: 'Bolt',
  [PackageType.BOTTLE_CRATE]: 'Bottle crate, bottle rack',
  [PackageType.BOTTLE_NON_PROTECTED_BULBOUS]: 'Bottle, non-protected,bulbous',
  [PackageType.BOTTLE_NON_PROTECTED_CYLINDRICAL]: 'Bottle, non-protected,cylindrical',
  [PackageType.BOTTLE_PROTECTED_BULBOUS]: 'Bottle, protected bulbous',
  [PackageType.BOTTLE_PROTECTED_CYLINDRICAL]: 'Bottle, protected cylindrical',
  [PackageType.BOX]: 'Box',
  [PackageType.BUCKET]: 'Bucket',
  [PackageType.BULK_GAS_1031_MBAR_15C]: 'Bulk, gas (at 1031 mbar and 15°C)',
  [PackageType.BULK_LIQUEFIED_GAS_ABNORMAL_TEMPERATURE_PRESSURE]: 'Bulk, liquefied gas (at abnormal temperature/pressure)',
  [PackageType.BULK_LIQUID]: 'Bulk, liquid',
  [PackageType.BULK_SOLID_FINE_PARTICLES_POWDERS]: 'Bulk, solid, fine particles(“powders”)',
  [PackageType.BULK_SOLID_GRANULAR_PARTICLES_GRAINS]: 'Bulk, solid, granular particles(“grains”)',
  [PackageType.BULK_SOLID_LARGE_PARTICLES_NODULES]: 'Bulk, solid, large particles(“nodules”)',
  [PackageType.BUNCH]: 'Bunch',
  [PackageType.BUNDLE]: 'Bundle',
  [PackageType.BUTT]: 'Butt',
  [PackageType.CAGE]: 'Cage',
  [PackageType.CAN_CYLINDRICAL]: 'Can, cylindrical',
  [PackageType.CAN_RECTANGULAR]: 'Can, rectangular',
  [PackageType.CANISTER]: 'Canister',
  [PackageType.CANVAS]: 'Canvas',
  [PackageType.CARBOY_NON_PROTECTED]: 'Carboy, non-protected',
  [PackageType.CARBOY_PROTECTED]: 'Carboy, protected',
  [PackageType.CARTON]: 'Carton',
  [PackageType.CASE]: 'Case',
  [PackageType.CASK]: 'Cask',
  [PackageType.CHEST]: 'Chest',
  [PackageType.CHURN]: 'Churn',
  [PackageType.COFFER]: 'Coffer',
  [PackageType.COFFIN]: 'Coffin',
  [PackageType.COIL]: 'Coil',
  [PackageType.COVER]: 'Cover',
  [PackageType.CRATE]: 'Crate',
  [PackageType.CREEL]: 'Creel',
  [PackageType.CUP]: 'Cup',
  [PackageType.CYLINDER]: 'Cylinder',
  [PackageType.DEMIJOHN_NON_PROTECTED]: 'Demijohn, non-protected',
  [PackageType.DEMIJOHN_PROTECTED]: 'Demijohn, protected',
  [PackageType.DRUM]: 'Drum',
  [PackageType.ENVELOPE]: 'Envelope',
  [PackageType.FILM_PACK]: 'Film pack',
  [PackageType.FIRKIN]: 'Firkin',
  [PackageType.FLASK]: 'Flask',
  [PackageType.FOOTLOCKER]: 'Footlocker',
  [PackageType.FRAME]: 'Frame',
  [PackageType.FRAMED_CRATE]: 'Framed crate',
  [PackageType.FRUIT_CRATE]: 'Fruit crate',
  [PackageType.GAS_BOTTLE]: 'Gas bottle',
  [PackageType.GIRDER]: 'Girder',
  [PackageType.GIRDERS_IN_BUNDLE_BUNCH_TRUSS]: 'Girders, inbundle/bunch/truss',
  [PackageType.HAMPER]: 'Hamper',
  [PackageType.HOGSHEAD]: 'Hogshead',
  [PackageType.INGOT]: 'Ingot',
  [PackageType.INGOTS_IN_BUNDLE_BUNCH_TRUSS]: 'Ingots, inbundle/bunch/truss',
  [PackageType.JAR]: 'Jar',
  [PackageType.JERRI_CAN_CYLINDRICAL]: 'Jerri can, cylindrical',
  [PackageType.JERRI_CAN_RECTANGULAR]: 'Jerri can, rectangular',
  [PackageType.JUG]: 'Jug',
  [PackageType.JUTE_BAG]: 'Jute bag',
  [PackageType.KEG]: 'Keg',
  [PackageType.LOG]: 'Log',
  [PackageType.LOGS_IN_BUNDLE_BUNCH_TRUSS]: 'Logs, in bundle/bunch/truss',
  [PackageType.MAT]: 'Mat',
  [PackageType.MATCH_BOX]: 'Match box',
  [PackageType.MILK_CRATE]: 'Milk crate',
  [PackageType.MULTIPLY_BAG]: 'Multiply bag',
  [PackageType.MULTIWALL_SACK]: 'Multiwall sack',
  [PackageType.NEST]: 'Nest',
  [PackageType.NET]: 'Net',
  [PackageType.PALLET]: 'Pallet',
  [PackageType.PACKET]: 'Packet',
  [PackageType.PAIL]: 'Pail',
  [PackageType.PARCEL]: 'Parcel',
  [PackageType.PIPE]: 'Pipe',
  [PackageType.PITCHER]: 'Pitcher',
  [PackageType.PIECE]: 'Piece',
  [PackageType.PIECES]: 'Pieces',
  [PackageType.PLANK]: 'Plank',
  [PackageType.PLANKS_PIPES_IN_BUNDLE_BUNCH_TRUSS]: 'Planks, pipes, in bundle/bunch/truss',
  [PackageType.PLATE]: 'Plate',
  [PackageType.PLATES_IN_BUNDLE_BUNCH_TRUSS]: 'Plates, inbundle/bunch/truss',
  [PackageType.POT]: 'Pot',
  [PackageType.POUCH]: 'Pouch',
  [PackageType.REDNET]: 'Rednet',
  [PackageType.REEL]: 'Reel',
  [PackageType.RING]: 'Ring',
  [PackageType.ROD]: 'Rod',
  [PackageType.RODS_IN_BUNDLE_BUNCH_TRUSS]: 'Rods, inbundle/bunch/truss',
  [PackageType.ROLL]: 'Roll',
  [PackageType.SACHET]: 'Sachet',
  [PackageType.SACK]: 'Sack',
  [PackageType.SEA_CHEST]: 'Sea-chest',
  [PackageType.SHALLOW_CRATE]: 'Shallowcrate',
  [PackageType.SHEET]: 'Sheet',
  [PackageType.SHEETMETAL]: 'Sheetmetal',
  [PackageType.SHEETS_IN_BUNDLE_BUNCH_TRUSS]: 'Sheets, inbundle/bunch/truss',
  [PackageType.SHRINKWRAPPED]: 'Shrinkwrapped',
  [PackageType.SKELETON_CASE]: 'Skeletoncase',
  [PackageType.SLIPSHEET]: 'Slipsheet',
  [PackageType.SPINDLE]: 'Spindle',
  [PackageType.SUITCASE]: 'Suitcase',
  [PackageType.TANK_CYLINDRICAL]: 'Tank, cylindrical',
  [PackageType.TANK_RECTANGULAR]: 'Tank, rectangular',
  [PackageType.TEA_CHEST]: 'Tea-chest',
  [PackageType.TIN]: 'Tin',
  [PackageType.TRAY]: 'Tray',
  [PackageType.TRUNK]: 'Trunk',
  [PackageType.TRUSS]: 'Truss',
  [PackageType.TUB]: 'Tub',
  [PackageType.TUBE_COLLAPSIBLE]: 'Tube, collapsible',
  [PackageType.TUBE]: 'Tube',
  [PackageType.TUBES_IN_BUNDLE_BUNCH_TRUSS]: 'Tubes,in bundle/bunch/truss',
  [PackageType.TUN]: 'Tun',
  [PackageType.UNPACKED]: 'Unpacked or unpackaged',
  [PackageType.VACUUM_PACKED]: 'Vacuum-packed',
  [PackageType.VAT]: 'Vat',
  [PackageType.VIAL]: 'Vial',
  [PackageType.WICKER_BOTTLE]: 'Wicker bottle',
};

export const PackageTypesCodes = {
  [PackageType.AEROSOL]: 'AE',
  [PackageType.AMPOULE_NON_PROTECTED]: 'AM',
  [PackageType.AMPOULE_PROTECTED]: 'AP',
  [PackageType.ATOMIZER]: 'AT',
  [PackageType.BARREL]: 'BA',
  [PackageType.BOBBIN]: 'BB',
  [PackageType.BOTTLE_CRATE]: 'BC',
  [PackageType.BOX]: 'BX',
  [PackageType.BOARD]: 'BD',
  [PackageType.BUNDLE]: 'BE',
  [PackageType.BALLOON_NON_PROTECTED]: 'BF',
  [PackageType.BAG]: 'BG',
  [PackageType.BUNCH]: 'BH',
  [PackageType.BIN]: 'BI',
  [PackageType.BUCKET]: 'BJ',
  [PackageType.BASKET]: 'BK',
  [PackageType.BALE_COMPRESSED]: 'BL',
  [PackageType.BALE_NON_COMPRESSED]: 'BN',
  [PackageType.BOTTLE_NON_PROTECTED_CYLINDRICAL]: 'BO',
  [PackageType.BALLOON_PROTECTED]: 'BP',
  [PackageType.BOTTLE_PROTECTED_CYLINDRICAL]: 'BQ',
  [PackageType.BAR]: 'BR',
  [PackageType.BOTTLE_NON_PROTECTED_BULBOUS]: 'BS',
  [PackageType.BOLT]: 'BT',
  [PackageType.BUTT]: 'BU',
  [PackageType.BOTTLE_PROTECTED_BULBOUS]: 'BV',
  [PackageType.BOARD_IN_BUNDLE_BUNCH_TRUSS]: 'BY',
  [PackageType.BARS_IN_BUNDLE_BUNCH_TRUSS]: 'BZ',
  [PackageType.CAN_RECTANGULAR]: 'CA',
  [PackageType.BEER_CRATE]: 'CB',
  [PackageType.CHURN]: 'CC',
  [PackageType.CREEL]: 'CE',
  [PackageType.COFFER]: 'CF',
  [PackageType.CAGE]: 'CG',
  [PackageType.CHEST]: 'CH',
  [PackageType.CANISTER]: 'CI',
  [PackageType.COFFIN]: 'CJ',
  [PackageType.CASK]: 'CK',
  [PackageType.COIL]: 'CL',
  [PackageType.CARBOY_NON_PROTECTED]: 'CO',
  [PackageType.CARBOY_PROTECTED]: 'CP',
  [PackageType.CRATE]: 'CR',
  [PackageType.CASE]: 'CS',
  [PackageType.CARTON]: 'CT',
  [PackageType.CUP]: 'CU',
  [PackageType.COVER]: 'CV',
  [PackageType.CAN_CYLINDRICAL]: 'CX',
  [PackageType.CYLINDER]: 'CY',
  [PackageType.CANVAS]: 'CZ',
  [PackageType.DEMIJOHN_NON_PROTECTED]: 'DJ',
  [PackageType.DEMIJOHN_PROTECTED]: 'DP',
  [PackageType.DRUM]: 'DR',
  [PackageType.ENVELOPE]: 'EN',
  [PackageType.FRUIT_CRATE]: 'FC',
  [PackageType.FRAMED_CRATE]: 'FD',
  [PackageType.FIRKIN]: 'FI',
  [PackageType.FLASK]: 'FL',
  [PackageType.FOOTLOCKER]: 'FO',
  [PackageType.FILM_PACK]: 'FP',
  [PackageType.FRAME]: 'FR',
  [PackageType.GAS_BOTTLE]: 'GB',
  [PackageType.GIRDER]: 'GI',
  [PackageType.GIRDERS_IN_BUNDLE_BUNCH_TRUSS]: 'GZ',
  [PackageType.HOGSHEAD]: 'HG',
  [PackageType.HAMPER]: 'HR',
  [PackageType.INGOT]: 'IN',
  [PackageType.INGOTS_IN_BUNDLE_BUNCH_TRUSS]: 'IZ',
  [PackageType.JERRI_CAN_RECTANGULAR]: 'JC',
  [PackageType.JUG]: 'JG',
  [PackageType.JAR]: 'JR',
  [PackageType.JUTE_BAG]: 'JT',
  [PackageType.JERRI_CAN_CYLINDRICAL]: 'JY',
  [PackageType.KEG]: 'KG',
  [PackageType.LOG]: 'LG',
  [PackageType.LOGS_IN_BUNDLE_BUNCH_TRUSS]: 'LZ',
  [PackageType.MULTIPLY_BAG]: 'MB',
  [PackageType.MILK_CRATE]: 'MC',
  [PackageType.MULTIWALL_SACK]: 'MS',
  [PackageType.MAT]: 'MT',
  [PackageType.MATCH_BOX]: 'MX',
  [PackageType.UNPACKED]: 'NE',
  [PackageType.NEST]: 'NS',
  [PackageType.NET]: 'NT',
  [PackageType.PACKET]: 'PA',
  [PackageType.PARCEL]: 'PC',
  [PackageType.PLATE]: 'PG',
  [PackageType.PITCHER]: 'PH',
  [PackageType.PIPE]: 'PI',
  [PackageType.PIECE]: 'PP',
  [PackageType.PIECES]: 'PCS',
  [PackageType.PALLET]: 'PK',
  [PackageType.PAIL]: 'PL',
  [PackageType.PLANK]: 'PN',
  [PackageType.POUCH]: 'PO',
  [PackageType.POT]: 'PT',
  [PackageType.TRAY]: 'PU',
  [PackageType.PLATES_IN_BUNDLE_BUNCH_TRUSS]: 'PY',
  [PackageType.PLANKS_PIPES_IN_BUNDLE_BUNCH_TRUSS]: 'PZ',
  [PackageType.ROD]: 'RD',
  [PackageType.RING]: 'RG',
  [PackageType.REEL]: 'RL',
  [PackageType.ROLL]: 'RO',
  [PackageType.REDNET]: 'RT',
  [PackageType.RODS_IN_BUNDLE_BUNCH_TRUSS]: 'RZ',
  [PackageType.SACK]: 'SA',
  [PackageType.SHALLOW_CRATE]: 'SC',
  [PackageType.SPINDLE]: 'SD',
  [PackageType.SEA_CHEST]: 'SE',
  [PackageType.SACHET]: 'SH',
  [PackageType.SKELETON_CASE]: 'SK',
  [PackageType.SLIPSHEET]: 'SL',
  [PackageType.SHEETMETAL]: 'SM',
  [PackageType.SHEET]: 'ST',
  [PackageType.SUITCASE]: 'SU',
  [PackageType.SHRINKWRAPPED]: 'SW',
  [PackageType.SHEETS_IN_BUNDLE_BUNCH_TRUSS]: 'SZ',
  [PackageType.TUB]: 'TB',
  [PackageType.TEA_CHEST]: 'TC',
  [PackageType.TUBE_COLLAPSIBLE]: 'TD',
  [PackageType.TANK_RECTANGULAR]: 'TK',
  [PackageType.TIN]: 'TN',
  [PackageType.TUN]: 'TO',
  [PackageType.TRUNK]: 'TR',
  [PackageType.TRUSS]: 'TS',
  [PackageType.TUBE]: 'TU',
  [PackageType.TANK_CYLINDRICAL]: 'TY',
  [PackageType.TUBES_IN_BUNDLE_BUNCH_TRUSS]: 'TZ',
  [PackageType.VAT]: 'VA',
  [PackageType.BULK_GAS_1031_MBAR_15C]: 'VG',
  [PackageType.VIAL]: 'VI',
  [PackageType.BULK_LIQUID]: 'VL',
  [PackageType.BULK_SOLID_LARGE_PARTICLES_NODULES]: 'VO',
  [PackageType.VACUUM_PACKED]: 'VP',
  [PackageType.BULK_LIQUEFIED_GAS_ABNORMAL_TEMPERATURE_PRESSURE]: 'VQ',
  [PackageType.BULK_SOLID_GRANULAR_PARTICLES_GRAINS]: 'VR',
  [PackageType.BULK_SOLID_FINE_PARTICLES_POWDERS]: 'VY',
  [PackageType.WICKER_BOTTLE]: 'WB',
};

export const ReversePackageTypesCodes = {
  AE: PackageType.AEROSOL,
  AM: PackageType.AMPOULE_NON_PROTECTED,
  AP: PackageType.AMPOULE_PROTECTED,
  AT: PackageType.ATOMIZER,
  BA: PackageType.BARREL,
  BB: PackageType.BOBBIN,
  BC: PackageType.BOTTLE_CRATE,
  BX: PackageType.BOX,
  BD: PackageType.BOARD,
  BE: PackageType.BUNDLE,
  BF: PackageType.BALLOON_NON_PROTECTED,
  BG: PackageType.BAG,
  BH: PackageType.BUNCH,
  BI: PackageType.BIN,
  BJ: PackageType.BUCKET,
  BK: PackageType.BASKET,
  BL: PackageType.BALE_COMPRESSED,
  BN: PackageType.BALE_NON_COMPRESSED,
  BO: PackageType.BOTTLE_NON_PROTECTED_CYLINDRICAL,
  BP: PackageType.BALLOON_PROTECTED,
  BQ: PackageType.BOTTLE_PROTECTED_CYLINDRICAL,
  BR: PackageType.BAR,
  BS: PackageType.BOTTLE_NON_PROTECTED_BULBOUS,
  BT: PackageType.BOLT,
  BU: PackageType.BUTT,
  BV: PackageType.BOTTLE_PROTECTED_BULBOUS,
  BY: PackageType.BOARD_IN_BUNDLE_BUNCH_TRUSS,
  BZ: PackageType.BARS_IN_BUNDLE_BUNCH_TRUSS,
  CA: PackageType.CAN_RECTANGULAR,
  CB: PackageType.BEER_CRATE,
  CC: PackageType.CHURN,
  CE: PackageType.CREEL,
  CF: PackageType.COFFER,
  CG: PackageType.CAGE,
  CH: PackageType.CHEST,
  CI: PackageType.CANISTER,
  CJ: PackageType.COFFIN,
  CK: PackageType.CASK,
  CL: PackageType.COIL,
  CO: PackageType.CARBOY_NON_PROTECTED,
  CP: PackageType.CARBOY_PROTECTED,
  CR: PackageType.CRATE,
  CS: PackageType.CASE,
  CT: PackageType.CARTON,
  CU: PackageType.CUP,
  CV: PackageType.COVER,
  CX: PackageType.CAN_CYLINDRICAL,
  CY: PackageType.CYLINDER,
  CZ: PackageType.CANVAS,
  DJ: PackageType.DEMIJOHN_NON_PROTECTED,
  DP: PackageType.DEMIJOHN_PROTECTED,
  DR: PackageType.DRUM,
  EN: PackageType.ENVELOPE,
  FC: PackageType.FRUIT_CRATE,
  FD: PackageType.FRAMED_CRATE,
  FI: PackageType.FIRKIN,
  FL: PackageType.FLASK,
  FO: PackageType.FOOTLOCKER,
  FP: PackageType.FILM_PACK,
  FR: PackageType.FRAME,
  GB: PackageType.GAS_BOTTLE,
  GI: PackageType.GIRDER,
  GZ: PackageType.GIRDERS_IN_BUNDLE_BUNCH_TRUSS,
  HG: PackageType.HOGSHEAD,
  HR: PackageType.HAMPER,
  IN: PackageType.INGOT,
  IZ: PackageType.INGOTS_IN_BUNDLE_BUNCH_TRUSS,
  JC: PackageType.JERRI_CAN_RECTANGULAR,
  JG: PackageType.JUG,
  JR: PackageType.JAR,
  JT: PackageType.JUTE_BAG,
  JY: PackageType.JERRI_CAN_CYLINDRICAL,
  KG: PackageType.KEG,
  LG: PackageType.LOG,
  LZ: PackageType.LOGS_IN_BUNDLE_BUNCH_TRUSS,
  MB: PackageType.MULTIPLY_BAG,
  MC: PackageType.MILK_CRATE,
  MS: PackageType.MULTIWALL_SACK,
  MT: PackageType.MAT,
  MX: PackageType.MATCH_BOX,
  NE: PackageType.UNPACKED,
  NS: PackageType.NEST,
  NT: PackageType.NET,
  PA: PackageType.PACKET,
  PC: PackageType.PARCEL,
  PG: PackageType.PLATE,
  PH: PackageType.PITCHER,
  PI: PackageType.PIPE,
  PP: PackageType.PIECE,
  PCS: PackageType.PIECES,
  PK: PackageType.PALLET,
  PL: PackageType.PAIL,
  PN: PackageType.PLANK,
  PO: PackageType.POUCH,
  PT: PackageType.POT,
  PU: PackageType.TRAY,
  PY: PackageType.PLATES_IN_BUNDLE_BUNCH_TRUSS,
  PZ: PackageType.PLANKS_PIPES_IN_BUNDLE_BUNCH_TRUSS,
  RD: PackageType.ROD,
  RG: PackageType.RING,
  RL: PackageType.REEL,
  RO: PackageType.ROLL,
  RT: PackageType.REDNET,
  RZ: PackageType.RODS_IN_BUNDLE_BUNCH_TRUSS,
  SA: PackageType.SACK,
  SC: PackageType.SHALLOW_CRATE,
  SD: PackageType.SPINDLE,
  SE: PackageType.SEA_CHEST,
  SH: PackageType.SACHET,
  SK: PackageType.SKELETON_CASE,
  SL: PackageType.SLIPSHEET,
  SM: PackageType.SHEETMETAL,
  ST: PackageType.SHEET,
  SU: PackageType.SUITCASE,
  SW: PackageType.SHRINKWRAPPED,
  SZ: PackageType.SHEETS_IN_BUNDLE_BUNCH_TRUSS,
  TB: PackageType.TUB,
  TC: PackageType.TEA_CHEST,
  TD: PackageType.TUBE_COLLAPSIBLE,
  TK: PackageType.TANK_RECTANGULAR,
  TN: PackageType.TIN,
  TO: PackageType.TUN,
  TR: PackageType.TRUNK,
  TS: PackageType.TRUSS,
  TU: PackageType.TUBE,
  TY: PackageType.TANK_CYLINDRICAL,
  TZ: PackageType.TUBES_IN_BUNDLE_BUNCH_TRUSS,
  VA: PackageType.VAT,
  VG: PackageType.BULK_GAS_1031_MBAR_15C,
  VI: PackageType.VIAL,
  VL: PackageType.BULK_LIQUID,
  VO: PackageType.BULK_SOLID_LARGE_PARTICLES_NODULES,
  VP: PackageType.VACUUM_PACKED,
  VQ: PackageType.BULK_LIQUEFIED_GAS_ABNORMAL_TEMPERATURE_PRESSURE,
  VR: PackageType.BULK_SOLID_GRANULAR_PARTICLES_GRAINS,
  VY: PackageType.BULK_SOLID_FINE_PARTICLES_POWDERS,
  WB: PackageType.WICKER_BOTTLE,
};
