import React from 'react';
import { IPropsIcon } from 'app-wrapper/interfaces/icon';

export const StarSvg = (props: IPropsIcon) => {
  const { width, height, style } = props;
  return (
    <svg
      className="StarSvg"
      width={width || '15'}
      height={height || '14'}
      viewBox="0 0 15 14"
      color={style?.color || '#99A3B5'}
      style={style || undefined}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M13.6892 5.01836L9.72203 4.4418L7.94859 0.84649C7.90015 0.748053 7.82046 0.668365 7.72203 0.619928C7.47515 0.498053 7.17515 0.599615 7.05171 0.84649L5.27828 4.4418L1.31109 5.01836C1.20171 5.03399 1.10171 5.08555 1.02515 5.16368C0.932592 5.25881 0.881587 5.3868 0.883345 5.51952C0.885103 5.65225 0.939479 5.77884 1.03453 5.87149L3.90484 8.66993L3.22671 12.6215C3.21081 12.7134 3.22098 12.808 3.25608 12.8944C3.29117 12.9808 3.34978 13.0557 3.42526 13.1105C3.50074 13.1653 3.59007 13.1979 3.68312 13.2045C3.77617 13.2112 3.86922 13.1916 3.95171 13.1481L7.50015 11.2824L11.0486 13.1481C11.1455 13.1996 11.258 13.2168 11.3658 13.1981C11.6377 13.1512 11.8205 12.8934 11.7736 12.6215L11.0955 8.66993L13.9658 5.87149C14.0439 5.79493 14.0955 5.69493 14.1111 5.58555C14.1533 5.31211 13.9627 5.05899 13.6892 5.01836ZM9.88765 8.27618L10.4517 11.5621L7.50015 10.0121L4.54859 11.5637L5.11265 8.27774L2.72515 5.94962L6.02515 5.46993L7.50015 2.48087L8.97515 5.46993L12.2752 5.94962L9.88765 8.27618Z" fill="currentColor" />
    </svg>
  );
};
