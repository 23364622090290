import React, { FC, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { RouteNames } from 'app-wrapper/constants';

import {
  ShipmentTitleArrowIcon,
  ShipmentTitleBlock,
  ShipmentTitleContainer,
  ShipmentTitleText,
  ShipmentTitleTag,
} from './styled';

interface IShipmentTitleProps {
  shipmentName?: string
  reference?: string
}

export const ShipmentTitle: FC<IShipmentTitleProps> = ({
  shipmentName,
  reference,
}) => {
  const navigate = useNavigate();

  const onReturn = useCallback(() => navigate(RouteNames.SHIPMENTS_ACTIVE()), [navigate]);

  return (
    <ShipmentTitleBlock>
      <ShipmentTitleArrowIcon onClick={onReturn} />
      <ShipmentTitleContainer>
        {shipmentName && (
          <ShipmentTitleText level={4}>{shipmentName}</ShipmentTitleText>
        )}
        {
          reference && (
            <ShipmentTitleTag>
              {reference}
            </ShipmentTitleTag>
          )
        }
      </ShipmentTitleContainer>
    </ShipmentTitleBlock>
  );
};
