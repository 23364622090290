import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from 'app-wrapper/view/components';

import { FooterWrapper } from './Footer.styled';

interface FooterProps {
  onCancel: () => void;
  onSubmit: () => void;
  disabled?: boolean;
}

export const FooterComponent: FC<FooterProps> = ({
  onSubmit,
  onCancel,
  disabled,
}) => {
  const { t } = useTranslation();

  return (
    <FooterWrapper>
      <Button
        onClick={onCancel}
        type="dashed"
        style={{
          marginRight: '8px',
        }}
      >
        {t('Cancel')}
      </Button>

      <Button onClick={onSubmit} disabled={disabled}>
        {t('Save')}
      </Button>
    </FooterWrapper>
  );
};
