import React, { FC, useCallback } from 'react';

import { Option, SelectWithError } from 'app-wrapper/view/components';
import states from 'app-wrapper/constants/states.json';

type State = {
 code: string, name: string
}

type States = {
  [key: string]: State[]
}

interface IEditProfileOrganizationAddressProps {
  dataClass?: string
  value?: string
  hasError?: boolean
  disabled?: boolean
  allowClear: boolean
  onChange: (state: string) => void
  onBlur: () => void
  onFocus?: () => void
  onClear: () => void
  showSearch?: boolean
  largeInput?: boolean
  placeholder?: string
  country?: string
}

export const OrganizationStateSelectComponent: FC<IEditProfileOrganizationAddressProps> = (props) => {
  const {
    placeholder,
    dataClass,
    value,
    hasError,
    disabled,
    allowClear,
    onChange,
    onBlur,
    onClear,
    onFocus,
    showSearch,
    largeInput,
    country,
  } = props;

  const handleChange = useCallback((newValue: string) => {
    onChange(newValue);
  }, []);

  const handleClear = useCallback(() => {
    onClear();
  }, []);

  const handleBlur = useCallback(() => {
    onBlur();
  }, []);

  const handleFocus = useCallback(() => {
    if (onFocus) {
      onFocus();
    }
  }, []);

  const options = country ? (states as States)[country] : [] as State[];

  return (

    <SelectWithError
      data-class={dataClass || 'EditProfileOrganizationSelectCountryState'}
      value={value}
      disabled={disabled}
      hasError={hasError}
      showSearch={showSearch}
      allowClear={allowClear}
      onChange={handleChange}
      onClear={handleClear}
      onBlur={handleBlur}
      onFocus={handleFocus}
      largeInput={largeInput}
      placeholder={placeholder}
      optionFilterProp="children"
    >
      {options?.map((item) => (
        <Option
          value={item.code}
          key={`EditProfileOrganizationSelect_${item.code}`}
        >
          {item.name}
        </Option>
      ))}
    </SelectWithError>
  );
};
