import React, { FC } from 'react';

import { UC } from 'shipment-operations/controllers';

import { CardVolumeFieldComponent } from './CardVolumeField.component';

interface ICardVolumeFieldContainerProps {
  cargoId: string
  value: string
  isError: boolean
  disabled: boolean
}

const CardVolumeFieldContainer: FC<ICardVolumeFieldContainerProps> = ({
  cargoId,
  value,
  isError,
  disabled,
}) => (
  <CardVolumeFieldComponent
    cargoId={cargoId}
    value={value}
    isErrorShowed={isError}
    disabled={disabled}
    onChange={UC.shipmentContainers.updateChangedCargoVolume}
    touchField={UC.shipmentContainers.onBlurUsedCargoVolume}
  />
);

export {
  CardVolumeFieldContainer as CardVolumeField,
};
