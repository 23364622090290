import AntTable from 'antd/es/table';
import styled from 'styled-components';
import Menu from 'antd/es/menu';

import { ActionMenuIcon } from 'shipment-operations/view/pages/ShipmentDocumentsAll/components/DocumentsSection/components/ActionMenuIcon/ActionMenuIcon.component';

export const TableCell = styled.span``;

export const TableCellNumber = styled(TableCell)`
  color: ${({ theme }) => theme.themesColors.primaryBranding7};
`;

export const TableWrap = styled.div`
  padding: 16px;
  background: white;
`;

export const CustomTable = styled(AntTable)`
  .ant-table-content table {
    table-layout: fixed !important;
  }

  .ant-table-thead > tr > th {
    border-bottom: none;
  }

  .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    display: none;
  }

  .ant-table-thead > tr > th {
    border-top: 0 !important;
    padding-top: 0;
    padding-bottom: 8px;
  }

  .ant-table-thead > tr > th:first-of-type, .ant-table-tbody > tr > td:first-of-type {
    padding-left: 0;
  }

  .ant-table-thead .ant-table-cell {
    border-top: 1px solid rgba(234, 238, 248, 1);
    border-bottom: 1px solid rgba(234, 238, 248, 1);
    color: rgba(115, 129, 155, 1);
    background-color: transparent;
    font-size: 12px;
    line-height: 20px;
    font-weight: 400;

    &:last-of-type {
      padding-right: 12px;
    }
  }

  .ant-table-tbody .ant-table-cell {
    background-color: white !important;
    color: rgba(32, 44, 60, 1);
    font-size: 14px;
    line-height: 22px;
    border-bottom: 1px solid rgba(234, 238, 248, 1);

    &:last-of-type {
      padding-right: 12px;
    }
  }

  .ant-table-cell:first-of-type {
    & > article {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;

export const DeleteMenuItem = styled(Menu.Item)`
  color: #ff4d4f;

  &.ant-dropdown-menu-item-disabled {
    color: rgba(0, 0, 0, 0.25);
  }
`;

export const MenuIcon = styled(ActionMenuIcon)`
  cursor: pointer;
`;
