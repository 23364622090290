import styled, { css } from 'styled-components';
import AntdTag from 'antd/es/tag';

import { colors } from 'app-wrapper/view/themes/themesColors';

interface IStepsInnerComponentProps {
  active?: number
  $mLeft?: string
  $mRight?: string
}

const recolorWhenActive = css<IStepsInnerComponentProps>`
  ${({ active }) => (
    active ? colors.purple60 : colors.gray43
  )}
`;

export const Tag = styled(AntdTag)<IStepsInnerComponentProps>`
  margin-right: ${({ $mRight }) => $mRight || ''};
  margin-left: ${({ $mLeft }) => $mLeft || ''};

  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  border: 1px solid ${({ active }) => (active ? '#D3D7F5' : '#F4F5F8')};
  color: ${({ active }) => (active ? '#6C79DE' : '#73819B')};
`;

export const StepsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const StepsDivider = styled.div<IStepsInnerComponentProps>`
  height: 1px;
  width: 100%;

  background-color: ${recolorWhenActive};
`;

export const LittleCircle = styled.div<IStepsInnerComponentProps>`
  min-width: 8px;
  height: 8px;
  border-radius: 50%;
  cursor: pointer;

  background-color: ${({ active }) => (active ? '#6C79DE' : '#D1D7E2')};
`;
