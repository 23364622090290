import React, { FC, useMemo } from 'react';

import { Tooltip } from 'app-wrapper/view/components';
import { NotificationTaskContentAssigneeDTM } from 'app-wrapper/models/dtm';

import {
  ContentTableTasksAssigneeUsersWrap,
  ContentTableTasksAssigneeUserItem,
  ContentTableTasksAssigneeHideUser,
  ContentTableTasksAssigneeNoUsersIcon,
} from './ContentTableTasksSection.styled';

interface IContentTableTasksAssigneeUsersProps {
  users?: NotificationTaskContentAssigneeDTM[];
}

export const ContentTableTasksAssigneeUsers: FC<IContentTableTasksAssigneeUsersProps> = (props) => {
  const {
    users,
  } = props;
  const [hiddenUsers, visibleUsers] = useMemo(() => {
    const hidden: NotificationTaskContentAssigneeDTM[] = [...users || []];
    const visible: NotificationTaskContentAssigneeDTM[] = hidden.splice(0, 3);

    return [hidden, visible];
  }, [users]);

  const hiddenUsersEmails = useMemo(() => hiddenUsers.reduce((accumulator, currentValue) => (!accumulator.length ? `${currentValue.email}` : `${accumulator}, ${currentValue.email}`), ''), [hiddenUsers]);

  return users?.length ? (
    <ContentTableTasksAssigneeUsersWrap>
      {visibleUsers.map((user) => (
        <Tooltip
          isGetPopupContainerDefault
          key={`ContentTableTasksAssigneeUsers_${user.email}`}
          title={(
            <>
              {user.getFullName() || ''}<br />
              {user.email || ''}<br />
              {user.phone || ''}
            </>
          )}
          placement="top"
        >
          <ContentTableTasksAssigneeUserItem>{user.getFirstLetterOfName()}</ContentTableTasksAssigneeUserItem>
        </Tooltip>
      ))}

      {hiddenUsers.length ? (
        <Tooltip title={hiddenUsersEmails} placement="topLeft">
          <ContentTableTasksAssigneeHideUser>
            {`+${hiddenUsers.length}`}
          </ContentTableTasksAssigneeHideUser>
        </Tooltip>
      ) : null}
    </ContentTableTasksAssigneeUsersWrap>
  ) : (
    <ContentTableTasksAssigneeUsersWrap>
      <ContentTableTasksAssigneeNoUsersIcon />
    </ContentTableTasksAssigneeUsersWrap>
  );
};
