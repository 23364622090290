import React, { FC } from 'react';
import CheckOutlined from '@ant-design/icons/CheckOutlined';

import { useTranslation } from 'react-i18next';
import {
  Wrapper, StepWrapper, Number, Title, CustomSeparator, SecondNumber, EmptyNumber,
} from './StepsTitle.styled';

interface IStepsTitle {
  isSecondStep: boolean
}

const StepsTitle: FC<IStepsTitle> = ({ isSecondStep }) => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <StepWrapper>
        {isSecondStep ? (
          <EmptyNumber>
            <CheckOutlined style={{ color: '#6C79DE' }} />
          </EmptyNumber>
        ) : (
          <Number>1</Number>
        )}
        <Title isSecondStep>{t('chooseCharges')}</Title>
      </StepWrapper>
      <CustomSeparator isSecondStep={isSecondStep} />
      <StepWrapper>
        <SecondNumber isSecondStep={isSecondStep}>2</SecondNumber>
        <Title isSecondStep={isSecondStep}>{t('specifyCosts')}</Title>
      </StepWrapper>
    </Wrapper>
  );
};

export { StepsTitle };
