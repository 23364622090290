import styled from 'styled-components';

import {
  StyledTable,
} from 'shipment-operations/view/components/CustomTable/CustomTable.styled';

export const FooterStyled = styled(StyledTable)`
  .ant-table-cell {
    background: #F9F9FB;
    padding-top: 18px !important;
    padding-bottom: 18px !important;
  }
  .ant-table-tbody > tr.ant-table-row:hover > td {
    background: #F9F9FB !important;
  }
  .ant-table-tbody tr .ant-table-cell:nth-child(2)::before,
  .ant-table-tbody tr .ant-table-cell:nth-child(5)::before,
  .ant-table-tbody tr .ant-table-cell:nth-child(8)::before {
    position: absolute;
    top: 50%;
    right: 0;
    width: 1px;
    height: 1.6em;
    background-color: #EBEEF7;
    transform: translateY(-50%);
    transition: background-color 0.3s;
    content: '';
  }
`;

export const StyledCell = styled.span`
  font-weight: 500;
  color: #202C3C;
`;
