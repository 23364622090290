import map from 'lodash/fp/map';
import uniq from 'lodash/fp/uniq';

import { InvoicesChargeDTM, CreditNoteItemDTM, InvoiceDataTypeDTM } from 'shipment-operations/models/dtm';
import { chargeCodeCode } from 'monetary/constants';

export const prepareDataForTable = (data: InvoicesChargeDTM[], includedCharges?: string[]) => map((item) => ({
  key: item.id,
  service: item.charge.chargeCode.description.toLowerCase(),
  measure: item.charge.measureBy,
  cost: item.costPerUnit.toFixed(2),
  quantity: item.numberOfUnits,
  total: item.amount.toFixed(2),
  includedCharges: item.charge.chargeCode.code === chargeCodeCode.FRT ? uniq(includedCharges) : [],
}), data);

export const prepareDataForEditableTable = (data: InvoicesChargeDTM[]) => map((item) => (InvoiceDataTypeDTM.fromPlain({
  key: item.id,
  service: item.charge.chargeCode.description.toLowerCase(),
  measure: item.charge.measureBy,
  cost: item.costPerUnit.toFixed(2),
  quantity: item.numberOfUnits,
  total: item.amount.toFixed(2),
  invoiced: item.invoiced.toFixed(2),
  adjustment: item.invoiced.toFixed(2),
})), data);

export const prepareCreditNotesForTable = (data: CreditNoteItemDTM[]) => map((item) => ({
  key: item.id,
  total: item.amount.toFixed(2),
  service: item.invoiceItem.charge.chargeCode.description.toLowerCase(),
}), data);

export const collectKeys = (data: InvoicesChargeDTM[]) => map((item) => item.id, data);

export const parseCharges = (data: InvoicesChargeDTM[]) => {
  const tableData = prepareDataForTable(data);
  const keys = collectKeys(data);
  return { tableData, keys };
};
