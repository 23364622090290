import React, { FC } from 'react';

import { PaymentDocumentDTM } from 'shipment-operations/models/dtm';

import { PaymentDocument } from './components';
import {
  Wrap,
} from './PaymentDocuments.styled';

interface IPaymentDocumentsComponentProps {
  documents: PaymentDocumentDTM[];
  accountId: number;
  downloadPaymentDocument: (accountId: number, documentId: number, documentName: string) => void;
}

export const PaymentDocumentsComponent: FC<IPaymentDocumentsComponentProps> = ({ documents, accountId, downloadPaymentDocument }) => (
  <Wrap>
    {documents.map((document) => <PaymentDocument key={`PaymentDocumentsComponent_${accountId}`} document={document} accountId={accountId} downloadPaymentDocument={downloadPaymentDocument} />)}
  </Wrap>
);
