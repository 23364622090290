import styled from 'styled-components';
import Typography from 'antd/es/typography';
import AntModal from 'antd/es/modal';

export const Modal = styled(AntModal)`
  .ant-modal-header {
    padding: 32px 32px 0px 32px;
    border-bottom: 0px;
  }

  .ant-modal-close {
    display: none;
  }

  .ant-modal-title {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #202C3C;
  }

  .ant-modal-body {
    padding: 8px 32px 0px 32px;
  }

  .ant-modal-footer {
    padding: 24px 32px;
    border-top: 0;
    
    button {
      border-radius: 3px;
    }
    
    button:first-of-type {
      border: 1px solid #DEE1E9;
      color: #202C3C;
    }

    button:last-of-type {
      background: #4E5D70;
      border-color: #4E5D70;
    }
  }
`;

export const ModalWrap = styled.div`
  width: 416px;
`;

export const SubTitle = styled(Typography)`
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #202C3C;
`;

export const TextArea = styled.textarea`
  width: 352px;
  padding: 5px 12px 5px 12px;
  border: 1px solid #DEE1E9;
  border-radius: 2px;
  margin-top: 4px;
  min-height: 98px;
  outline: none;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  
  ::placeholder {
    color: #73819B;
  }
`;
