import React, {
  FC, memo, useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import Skeleton from 'antd/es/skeleton';
import UserOutlined from '@ant-design/icons/UserOutlined';
import { useParams } from 'react-router';

import { REQUEST_STATUS } from 'app-wrapper/constants';
import { EditPanSvg, PlusSvg, UserPersonCalendarSvg } from 'app-wrapper/view/icons';
import { Tooltip } from 'app-wrapper/view/components';

import { CompanyAddressDTM, CompanyContactDTM, IContactBookDefaultStateCompanyListStateDTM } from 'user-management/models/dtm';

import {
  CBByIdContentTable,
  CBByIdContentTableContentLink,
  CBByIdContentTableContentItem,
  CBByIdContentTableContentItemTop,
  CBByIdContentTableContentTitle,
  CBByIdContentTableHeader,
  CBByIdContentTableHeaderItem,
  CBByIdSpanEllipsis,
  CBByIdContentTableTitle,
  CBByIdContentTableTitleWrap,
  CBByIdContentTableHeaderAddBtn,
  CBByIdContentTableContact,
  CBByIdContentTableContactWrap,
  CBByIdContentTableContentPrimary,
  CBByIdContentTableTitleHeader,
  CBByIdContentTableTitleHeaderTitle,
  CBByIdContentTableTitleHeaderFirst,
  CBByIdContentTableTitleHeaderTaxId,
  CBByIdContentTableTitleHeaderAccount,
  CBByIdContentTableTitleHeaderSpan,
  CBByIdContentTableTitleHeaderSpan14,
  CBByIdContentTableHeaderEditCompanyBtn,
  CBByIdContentTableTitleHeaderEdit,
  CBByIdContentTableTitleHeaderAccountIcon,
  CBByIdContentTableTitleHeaderAccountWrap,
  CBByIdContentTableTitleHeaderCompanyIcon,
} from './ContactBookByIdListTable.styled';

interface ICBByIdTableProps {
  onStart: () => void;
  nextPageTasks: () => void;
  onClickAddressItem: (companyId: string, addressId: string) => () => void;
  onClickAddAddress: () => void;
  onClickEditCompany: () => void;
  onClickEditAddress: (id: string) => () => void;
  isLoad?: boolean;
  isLoadAdd?: string;
  companyListState: IContactBookDefaultStateCompanyListStateDTM[]
  companyListAddresses?: CompanyAddressDTM[]
  companyListContactPersons?: CompanyContactDTM[]
  isEnd?: boolean;
  emptyState?: React.ReactNode;
}

const CBByIdTable: FC<ICBByIdTableProps> = (props) => {
  const {
    onStart,
    nextPageTasks,
    onClickAddressItem,
    onClickEditCompany,
    onClickAddAddress,
    onClickEditAddress,
    companyListState,
    isLoad,
    isLoadAdd,
    companyListAddresses,
    companyListContactPersons,
    isEnd,
    emptyState,
  } = props;
  const { contactId = '0' } = useParams();

  const { t } = useTranslation();

  const company = companyListState?.[0];
  const primaryContact = companyListContactPersons?.filter((item) => item.primary)?.[0];

  useEffect(() => {
    onStart();
  }, []);

  return (
    <CBByIdContentTable>
      <CBByIdContentTableTitleHeader>
        <CBByIdContentTableTitleHeaderCompanyIcon>
          <UserPersonCalendarSvg />
        </CBByIdContentTableTitleHeaderCompanyIcon>
        <CBByIdContentTableTitleHeaderFirst>
          <CBByIdContentTableTitleHeaderTitle>
            {company.name}
          </CBByIdContentTableTitleHeaderTitle>
          <CBByIdContentTableTitleHeaderTaxId>
            {t('TaxID')}{`${company.taxId ? `: ${company.taxId}` : ''}`}
          </CBByIdContentTableTitleHeaderTaxId>
        </CBByIdContentTableTitleHeaderFirst>

        {/* Now is hide */}
        {false && (
          <CBByIdContentTableTitleHeaderAccount>
            <CBByIdContentTableTitleHeaderAccountIcon>
              <UserOutlined />
            </CBByIdContentTableTitleHeaderAccountIcon>
            <CBByIdContentTableTitleHeaderAccountWrap>
              <CBByIdContentTableTitleHeaderSpan>
                {t('Account Manager(s)')}
              </CBByIdContentTableTitleHeaderSpan>
              <CBByIdContentTableTitleHeaderSpan14>
                {primaryContact?.fullName || ''}
              </CBByIdContentTableTitleHeaderSpan14>
            </CBByIdContentTableTitleHeaderAccountWrap>
          </CBByIdContentTableTitleHeaderAccount>
        )}

        <CBByIdContentTableTitleHeaderEdit>
          <CBByIdContentTableHeaderEditCompanyBtn
            type="ghost"
            onClick={onClickEditCompany}
          >
            <EditPanSvg />
            <span>{t('Edit Company Details')}</span>
          </CBByIdContentTableHeaderEditCompanyBtn>
        </CBByIdContentTableTitleHeaderEdit>
      </CBByIdContentTableTitleHeader>

      {emptyState || null}

      {!emptyState ? (
        <CBByIdContentTableTitleWrap>
          <CBByIdContentTableTitle>{t('Addresses')}</CBByIdContentTableTitle>

          <CBByIdContentTableHeaderAddBtn
            onClick={onClickAddAddress}
          >
            <PlusSvg />
            <span>{t('AddAddress')}</span>
          </CBByIdContentTableHeaderAddBtn>
        </CBByIdContentTableTitleWrap>
      ) : null}

      {!emptyState ? (
        <CBByIdContentTableHeader>
          <CBByIdContentTableHeaderItem
            width="42.7%"
          >
            {t('Address')}
          </CBByIdContentTableHeaderItem>
          <CBByIdContentTableHeaderItem
            width="17.8%"
          // isCenter
          >
            {t('Country')}
          </CBByIdContentTableHeaderItem>
          <CBByIdContentTableHeaderItem
            width="17.8%"
          // isCenter
          >
            {t('City')}
          </CBByIdContentTableHeaderItem>
          <CBByIdContentTableHeaderItem
            width="17.8%"
            isCenter
          >
            {t('Contact')}
          </CBByIdContentTableHeaderItem>
        </CBByIdContentTableHeader>
      ) : null}

      <div>

        <InfiniteScroll
          dataLength={Number(companyListAddresses?.length || 0)}
          next={nextPageTasks}
          hasMore={!isEnd}
          loader={
            isLoad
            && (<Skeleton />)
          }
          scrollableTarget="Layout"
        >
          {companyListAddresses?.map((item) => (
            <CBByIdContentTableContentItem key={`CBByIdContentTableContentItem_${item.id}`}>
              <CBByIdContentTableContentItemTop>
                <CBByIdContentTableContentLink
                  onClick={onClickAddressItem(contactId, `${item.id || ''}`)}
                >
                  <CBByIdSpanEllipsis>
                    {item.getBothAddressLine()}
                  </CBByIdSpanEllipsis>
                  {item.isPrimary && (<CBByIdContentTableContentPrimary>{t('Primary')}</CBByIdContentTableContentPrimary>)}
                </CBByIdContentTableContentLink>
                <CBByIdContentTableContentTitle>
                  <CBByIdSpanEllipsis>
                    {item.country}
                  </CBByIdSpanEllipsis>
                </CBByIdContentTableContentTitle>
                <CBByIdContentTableContentTitle>
                  <CBByIdSpanEllipsis>
                    {item?.city || ''}
                  </CBByIdSpanEllipsis>
                </CBByIdContentTableContentTitle>
                <CBByIdContentTableContactWrap>
                  <CBByIdContentTableContact>
                    <Tooltip
                      title={(
                        <>
                          {item?.primaryContact?.fullName || ''}<br />
                          {item?.primaryContact?.email || ''}<br />
                          {item?.primaryContact?.phone || ''}<br />
                        </>
                      )}
                      placement="top"
                    >
                      {!!item?.primaryContact?.fullName && item?.primaryContact?.fullName.slice(0, 1).toLocaleUpperCase()}
                    </Tooltip>
                  </CBByIdContentTableContact>
                </CBByIdContentTableContactWrap>
                <CBByIdContentTableContentLink
                  width="3.9%"
                  onClick={onClickEditAddress(item.customId || '')}
                >
                  <EditPanSvg />
                </CBByIdContentTableContentLink>
              </CBByIdContentTableContentItemTop>

            </CBByIdContentTableContentItem>
          ))}
        </InfiniteScroll>
        {isLoadAdd === REQUEST_STATUS.pending && (<Skeleton />)}
      </div>

    </CBByIdContentTable>
  );
};

const CBByIdTableCache = memo(CBByIdTable);

export { CBByIdTableCache as CBByIdTable };
