import React from 'react';
import { useSelector } from 'react-redux';

import { UC } from 'shipment-operations/controllers';
import { R } from 'shipment-operations/repository';
import { hasAccess } from 'app-wrapper/utils';
import { PermissionAttributePolicy } from 'shipment-operations/constants';
import { ShipmentTransportationTrackerComponent } from './ShipmentTransportationTracker.component';

const ShipmentTransportationTrackerContainer = () => {
  const errorUpdate = useSelector(R.selectors.shipmentTracker.getIsErrorUpdate);
  const permissions = useSelector(R.selectors.shipment.getTransportationPermissions);
  const isLoading = useSelector(R.selectors.shipmentTracker.getIsLoadingPage);

  return (
    <ShipmentTransportationTrackerComponent
      loadData={UC.shipmentTracker.loadContainers}
      isLoading={isLoading}
      onClosePage={UC.shipmentTracker.onClosePage}
      resetError={UC.shipmentTracker.resetErrorPostDates}
      errorUpdate={errorUpdate}
      withEdit={hasAccess(permissions.trackerAvailability, PermissionAttributePolicy.WRITE)}
    />
  );
};

export { ShipmentTransportationTrackerContainer as ShipmentTransportationTracker };
