import React, { FC, ReactNode } from 'react';

import {
  MBLScaffoldWrapper,
  CompanyBody,
  ScaffoldRow,
  CarrierBody,
  TransportBody,
  ShippingPartiesBody,
  BOLInstructionsBody,
  RoutingBody,
  RoutingInstructionsBody,
  ReferencesBody,
  GoodsTableBody,
  HazmatTableBody,
  ContainersTableBody,
  ChargesBody,
  BLReleaseBody,
  ShippedOnBoardBody,
  ClausesBody,
  AdditionalNotesBody,
  TermsAndConditionsBody,
} from './MBLScaffold.styled';

interface ISBLScaffoldComponentProps {
  companyBody?: ReactNode;
  carrierBody?: ReactNode;
  transportBody?: ReactNode;
  shippingPartiesBody?: ReactNode;
  billOfLadingInstructionsBody?: ReactNode;
  routingBody?: ReactNode;
  routingInstructionsBody?: ReactNode;
  referencesBody?: ReactNode;
  goodsTableBody?: ReactNode;
  hazmatTableBody?: ReactNode;
  containersTableBody?: ReactNode;
  chargesBody?: ReactNode;
  billOfLadingReleaseBody?: ReactNode;
  shippedOnBoardBody?: ReactNode;
  clausesBody?: ReactNode;
  additionalNotesBody?: ReactNode;
  termsAndConditionsBody?: ReactNode;
}

const MBLScaffoldComponent: FC<ISBLScaffoldComponentProps> = ({
  companyBody,
  carrierBody,
  transportBody,
  shippingPartiesBody,
  billOfLadingInstructionsBody,
  routingBody,
  routingInstructionsBody,
  referencesBody,
  goodsTableBody,
  hazmatTableBody,
  containersTableBody,
  chargesBody,
  billOfLadingReleaseBody,
  shippedOnBoardBody,
  clausesBody,
  additionalNotesBody,
  termsAndConditionsBody,
}) => (
  <MBLScaffoldWrapper>
    <CompanyBody>
      {companyBody}
    </CompanyBody>

    <ScaffoldRow>
      <CarrierBody>
        {carrierBody}
      </CarrierBody>

      <TransportBody>
        {transportBody}
      </TransportBody>
    </ScaffoldRow>

    <ShippingPartiesBody>
      {shippingPartiesBody}
    </ShippingPartiesBody>

    <BOLInstructionsBody>
      {billOfLadingInstructionsBody}
    </BOLInstructionsBody>

    <RoutingBody>
      {routingBody}
    </RoutingBody>

    {routingInstructionsBody ? (
      <RoutingInstructionsBody>
        {routingInstructionsBody}
      </RoutingInstructionsBody>
    ) : null}

    <ReferencesBody>
      {referencesBody}
    </ReferencesBody>

    <GoodsTableBody>
      {goodsTableBody}
    </GoodsTableBody>

    {hazmatTableBody ? (
      <HazmatTableBody>
        {hazmatTableBody}
      </HazmatTableBody>
    ) : null}

    <ContainersTableBody>
      {containersTableBody}
    </ContainersTableBody>

    {chargesBody ? (
      <ChargesBody>
        {chargesBody}
      </ChargesBody>
    ) : null}

    <ScaffoldRow>
      <BLReleaseBody>
        {billOfLadingReleaseBody}
      </BLReleaseBody>

      <ShippedOnBoardBody>
        {shippedOnBoardBody}
      </ShippedOnBoardBody>
    </ScaffoldRow>

    <ScaffoldRow>
      <ClausesBody>
        {clausesBody}
      </ClausesBody>

      <AdditionalNotesBody>
        {additionalNotesBody}
      </AdditionalNotesBody>
    </ScaffoldRow>

    {termsAndConditionsBody ? (
      <TermsAndConditionsBody>
        {termsAndConditionsBody}
      </TermsAndConditionsBody>
    ) : null}
  </MBLScaffoldWrapper>
);

export { MBLScaffoldComponent as MBLScaffold };
