import styled from 'styled-components';

export const TableRow = styled.div`
    display: flex;
    border-bottom: 0.5px dashed #D1D7E2;
    font-size: 0.7rem;
    padding: 5px 0 5px 0;
`;

export const Sign = styled.span<{fontSize?: number}>`
  position: absolute;
  top: 0;
  right: 0;
  background: #F4F5F8;
  border: 1px solid #EAEEF8;
  padding: 2px;
  border-radius: 4px;
  font-size: ${({ fontSize }) => fontSize || '0.45'}rem;
  font-weight: 600;
`;

export const NestedTableRow = styled(TableRow)`
  width: 98%;
  position: relative;
  font-size: 0.6rem;

  &:first-child::before {
    content: '';
    position: absolute;
    width: 5px;
    height: 8px;
    left: -12px;
    top: 4px;
    border-left: 1px solid #202C3C;
    border-bottom: 1px solid #202C3C;
  }

  &:last-child {
    border: none;
  }
`;

export const Nested = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const MarksAndNumbersWrapper = styled.div`
  margin-top: 4px;
`;

export const StyledSign = styled(Sign)`
  position: relative;
`;

export const Total = styled.td`
  border: 1px solid #D1D7E2;
  border-left: none;
  border-right: none;
  padding: 10px;
`;

export const GoodsDescriptionItemWrapper = styled.div`
  border-bottom: 0.5px dashed #D1D7E2;
`;
export const GoodsDescriptionStyledTableRow = styled.tr`
  &:nth-last-child(2) {
    ${GoodsDescriptionItemWrapper} {
      border-bottom: none;
    }
  }
`;
export const ContainersLoadStyledTableRow = styled.tr`
  &:last-child {
    ${GoodsDescriptionItemWrapper} {
      border-bottom: none;
    }
  }
`;

export const TitleWrapper = styled.div`
  display: grid;
  place-items: center;
  font-size: 0.7rem;
  margin: 5px 0 8px 0;
`;

export const TableHeader = styled.div`
  display: flex;
  background: #F4F5F8;
  border-radius: 4px;
  margin-bottom: 5px;
  padding: 3px;
  align-items: center;
  font-size: 0.65rem;
`;

export const Cell = styled.div<{justifyContent?: string; flex?: number}>`
  display: flex;
  align-items: center;
  justify-content: ${({ justifyContent }) => justifyContent};
  align-items: flex-start;
  flex: ${({ flex = 1 }) => flex};
  flex-wrap: wrap;
  gap: 4px 4px;
  text-transform: capitalize;
`;
