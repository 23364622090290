import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { groupBy } from 'lodash/fp';

import { ChargeDTM } from 'shipment-operations/models/dtm';
import { ChargeCodeDesignation } from 'shipment-operations/constants';
import { Bold, Span } from 'shipment-operations/view/components/HBLDocumentPDF/components';

import {
  ChargesWrapper,
  TableHeader,
  Cell,
} from './FreightAndChargesBreakdown.styled';
import { ChargeItem } from './component';

const chargeOrder = [ChargeCodeDesignation.ORIGIN, ChargeCodeDesignation.FREIGHT, ChargeCodeDesignation.DESTINATION];

interface FreightAndChargesBreakdownProps {
  charges: ChargeDTM[];
}

// TODO: Refactor in nearest future
export const FreightAndChargesBreakdown: FC<FreightAndChargesBreakdownProps> = ({ charges }) => {
  const { t } = useTranslation();

  const groupedCharges = groupBy(({ designation }) => designation, charges);

  return (
    <table style={{ width: '100%' }}>
      <thead>
        <tr>
          <td>
            <TableHeader>
              <Cell
                flex={3}
                justifyContent="flex-start"
              >
                <Bold>{t('Charge')}</Bold>
              </Cell>
              <Cell justifyContent="flex-start"><Bold>{t('Payment Term')}</Bold></Cell>
              <Cell justifyContent="flex-start"><Bold>{t('Currency')}</Bold></Cell>
              <Cell justifyContent="flex-end"><Bold>{t('Total')}</Bold></Cell>
            </TableHeader>
          </td>
        </tr>
      </thead>
      <tbody>
        {
          chargeOrder.map(
            (group) => (
              <tr key={group.toLowerCase()}>
                <td>
                  <Span fontSize={0.7}>
                    <Bold>{group.toLowerCase()}</Bold>
                  </Span>
                  <ChargesWrapper>
                    {groupedCharges[group] ? groupedCharges[group].map((charge) => (
                      <ChargeItem key={charge.id} charge={charge as ChargeDTM} />
                    )) : null}
                  </ChargesWrapper>
                </td>
              </tr>
            ),
          )
        }
      </tbody>
    </table>
  );
};
