import React, { useMemo, FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { checkSign } from 'app-wrapper/utils';
import { FooterStyled, StyledCell } from './TotalFooter.styled';

interface ITotalFooterProps {
  dataSource: {
    apTotalCost: number
    arTotalCost: number
    profit: number
  }[]
  isPercentage: boolean
}

const TotalFooterComponent: FC<ITotalFooterProps> = ({ dataSource, isPercentage }) => {
  const { t } = useTranslation();
  const renderProfit = useCallback((value, record) => {
    if (isPercentage) {
      const percent = record.profitPercentage;
      return (
        <StyledCell>
          {checkSign(percent, '%')}
        </StyledCell>
      );
    }
    return (
      <StyledCell>
        {checkSign(value, '$')}
      </StyledCell>
    );
  }, [isPercentage]);

  const columns = useMemo(() => [
    {
      width: '22%',
      key: 11,
      render: () => <StyledCell>{t('total')}</StyledCell>,
    },
    { width: '13%', key: 1 },
    { width: '10%', key: 2, align: 'right' as 'right' },
    {
      width: '5%',
      align: 'right' as 'right',
    },
    {
      dataIndex: 'buyTotalCost',
      key: 'buyTotalCost',
      align: 'right' as 'right',
      width: '10%',
      render: (value: number) => <StyledCell>$ {value.toFixed(2)}</StyledCell>,
    },
    {
      width: '10%',
      align: 'right' as 'right',
      key: 13,
    },
    {
      width: '5%',
      align: 'right' as 'right',
      render: () => null,
    },
    {
      width: '10%',
      dataIndex: 'totalCost',
      key: 'totalCost',
      align: 'right' as 'right',
      render: (value: number) => <StyledCell>$ {value.toFixed(2)}</StyledCell>,
    },
    {
      dataIndex: 'profit',
      key: 'profit',
      align: 'right' as 'right',
      width: '15%',
      render: renderProfit,
    },
  ], [isPercentage]);

  return (
    <FooterStyled
      pagination={false}
      showHeader={false}
      columns={columns}
      dataSource={dataSource}
      data-name="totalTable"
    />
  );
};

export { TotalFooterComponent };
