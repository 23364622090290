import React from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';

import { TemperatureControlComponent } from './TemperatureControl.component';

const TemperatureControlContainer = () => {
  const isPending = useSelector(R.selectors.manualBookingWizard.getIsLoading);
  const hasTemperatureControl = useSelector(R.selectors.manualBookingWizard.getHasTemperatureControl);

  return (
    <TemperatureControlComponent
      isUpdatePending={isPending}
      hasTemperatureControl={hasTemperatureControl}
    />
  );
};

export { TemperatureControlContainer as TemperatureControl };
