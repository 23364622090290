import React, { FC } from 'react';

import { ExpandIcon } from 'app-wrapper/view/components';
import themesColors from 'app-wrapper/view/themes/themesColors';

interface IStyledExpandIconProps {
  isActive?: boolean | undefined
}

export const StyledExpandIcon: FC<IStyledExpandIconProps> = ({ isActive }) => (
  <ExpandIcon color={isActive ? themesColors.primaryBranding6 : ''} isActive={isActive} />
);
