import React from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';
import { UC } from 'shipment-operations/controllers';

import { AddressLine1InputComponent } from './AddressLine1Input.component';

const AddressLine1Input = () => {
  const addressLine = useSelector(R.selectors.shippingParties.getAddressLine1);
  const requiredError = useSelector(R.selectors.shippingParties.getIsRequiredErrorVisible);

  return (
    <AddressLine1InputComponent
      error={requiredError}
      addressLine={addressLine}
      setAddressLine={UC.shippingParties.setAddressLine1}
    />
  );
};

const AddressLine1InputCached = React.memo(AddressLine1Input);

export { AddressLine1InputCached as AddressLine1Input };
