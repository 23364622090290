import {
  IsOptional,
  IsString,
  IsEnum,
} from 'class-validator';
import { BaseDTM } from 'proto/BaseDTM';

import { EReviewAction, EReviewReason } from 'user-management/constants';

export interface IOrganizationReviewDTM {
  action: `${EReviewAction}`;
  reason?: `${EReviewReason}`;
  note?: string;
}

export class OrganizationReviewDTM extends BaseDTM<IOrganizationReviewDTM> {
  @IsEnum(EReviewAction)
  action: EReviewAction;

  @IsOptional()
  @IsEnum(EReviewReason)
  reason?: EReviewReason;

  @IsOptional()
  @IsString()
  note?: string;
}
