import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'antd/es/skeleton';

import { Footer } from 'app-wrapper/view/drawers/components';
import { DrawerFields } from 'app-wrapper/view/guideline';

import { ContractNumber, ContractCategory } from 'app-wrapper/view/drawers/CreateContractDrawer/components';
import { Drawer } from './EditContractDrawer.styled';

const DRAWER_WIDTH = 400;

interface EditContractDrawerComponentProps {
  onClose: () => void
  isLoading: boolean
  isAbleToAccept: boolean
  onAccept: () => void
}

export const EditContractDrawerComponent: FC<EditContractDrawerComponentProps> = ({
  onClose,
  onAccept,
  isLoading,
  isAbleToAccept,
}) => {
  const { t } = useTranslation();

  return (
    <Drawer
      visible
      title={t('Edit Contract')}
      onClose={onClose}
      maskClosable={false}
      width={DRAWER_WIDTH}
    >
      {isLoading ? (
        <Skeleton />
      ) : (
        <DrawerFields>
          <ContractNumber />
          <ContractCategory />
        </DrawerFields>
      )}

      <Footer
        onAccept={onAccept}
        closeDrawer={onClose}
        isLoading={isLoading}
        isAbleToAccept={isAbleToAccept}
        okText={t('Save')}
      />
    </Drawer>
  );
};
