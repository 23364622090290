import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';

import { CarrierCardComponent } from './CarrierCard.component';

const CarrierCardContainer: FC = () => {
  const isLoading = useSelector(R.selectors.transportationOverview.getLoading);
  const transporationOverview = useSelector(R.selectors.transportationOverview.getTransportationOverview);
  const permissions = useSelector(R.selectors.shipment.getTransportationPermissions);
  const oceanCarrierName = useSelector(R.selectors.transportationOverview.getOceanCarrierName);

  const carrierSCAC = useSelector(R.selectors.shipment.getShipmentCarrierSCAC);

  return (
    <CarrierCardComponent
      isLoading={isLoading}
      name={oceanCarrierName}
      carrierSCAC={carrierSCAC}
      reference={transporationOverview.carrierReference}
      termsAndConditions={transporationOverview.termsAndConditions}
      comments={transporationOverview.comments}
      tocAvailability={permissions.carrierTOCAvailability}
      commentsAvailability={permissions.carrierCommentsAvailability}
    />
  );
};

export {
  CarrierCardContainer as CarrierCard,
};
