import { AppFiltersCheckboxGroupDTM } from 'app-wrapper/models/dtm';
import { NotificationMessageError } from 'app-wrapper/models/errors/NotificationMessageError';
import { DrawersUseCase } from 'app-wrapper/usecases/Drawers.useCase';
import { BaseController, controller } from 'proto/BaseController';
import debounce from 'lodash/debounce';

import { R as monetaryR } from 'monetary/repository';
import { ContractDTM } from 'shipment-operations/models/dtm';
import { R as userManagementR } from 'user-management/repository';
import { UC } from 'app-wrapper/controllers';
import { ECarrierSCAC, CarrierSCACNames } from 'monetary/constants';
import { EContractCategory } from 'shipment-operations/constants';

const CONTRACTS_PAGE_SIZE = 50;
const CARRIERS_SCAC_LIST = Object.values(ECarrierSCAC);
const DEFAULT_CARRIERS_FILTERS_GROUP = CARRIERS_SCAC_LIST.map((carrierSCAC) => AppFiltersCheckboxGroupDTM.fromPlain({
  name: CarrierSCACNames[carrierSCAC],
  value: carrierSCAC,
  checked: false,
}));

const DEFAULT_CATEGORIES_FILTERS_GROUP = [
  AppFiltersCheckboxGroupDTM.fromPlain({
    name: EContractCategory.A,
    value: EContractCategory.A,
    checked: false,
  }),
  AppFiltersCheckboxGroupDTM.fromPlain({
    name: EContractCategory.B,
    value: EContractCategory.B,
    checked: false,
  }),
  AppFiltersCheckboxGroupDTM.fromPlain({
    name: EContractCategory.C,
    value: EContractCategory.C,
    checked: false,
  }),
  AppFiltersCheckboxGroupDTM.fromPlain({
    name: EContractCategory.D,
    value: EContractCategory.D,
    checked: false,
  }),
];

@controller
export class ContractsController extends BaseController {
  public init = async () => {
    this.mobxStore.contracts.setIsLoading(true);

    const contracts = await monetaryR.services.contracts.getContracts({
      page: 0,
      size: CONTRACTS_PAGE_SIZE,
    });

    this.mobxStore.contracts.setWasPaginationRequestLast(contracts.length < CONTRACTS_PAGE_SIZE);
    this.mobxStore.contracts.setContracts(contracts);
    this.mobxStore.contracts.setCategoryFiltersGroup(DEFAULT_CATEGORIES_FILTERS_GROUP);
    this.mobxStore.contracts.setCarriersFiltersGroup(DEFAULT_CARRIERS_FILTERS_GROUP);
    this.mobxStore.contracts.setInitialCategoriesFilters(DEFAULT_CATEGORIES_FILTERS_GROUP);
    this.mobxStore.contracts.setInitialCarriersFilters(DEFAULT_CARRIERS_FILTERS_GROUP);
    this.mobxStore.contracts.setIsLoading(false);
  }

  public loadMore = async () => {
    this.mobxStore.contracts.setIsPaginationLoading(true);

    const chosenCategoryFiltersValues = this.mobxStore.contracts.chosenCategoryFiltersGroup.map(({ value }) => value);
    const chosenCarriersFiltersValues = this.mobxStore.contracts.chosenCarriersFiltersGroup.map(({ value }) => value);
    const {
      searchQuery,
      page,
      contracts: prevPagesContracts,
    } = this.mobxStore.contracts.state;

    const contracts = await monetaryR.services.contracts.getContracts({
      categories: chosenCategoryFiltersValues.length ? chosenCategoryFiltersValues.join(', ') : undefined,
      carriers: chosenCarriersFiltersValues.length ? chosenCarriersFiltersValues.join(', ') : undefined,
      query: searchQuery || undefined,
      page: page + 1,
      size: CONTRACTS_PAGE_SIZE,
    });

    this.mobxStore.contracts.setContracts([
      ...prevPagesContracts,
      ...contracts,
    ]);
    this.mobxStore.contracts.setPage(page + 1);
    this.mobxStore.contracts.setWasPaginationRequestLast(contracts.length < CONTRACTS_PAGE_SIZE);
    this.mobxStore.contracts.setIsPaginationLoading(false);
  }

  public resetCarriersFilters = async () => {
    this.mobxStore.contracts.setCarriersFiltersGroup(DEFAULT_CARRIERS_FILTERS_GROUP);
    this.mobxStore.contracts.setInitialCarriersFilters(DEFAULT_CARRIERS_FILTERS_GROUP);

    await this.applyFilters(true);
  };

  public setCarrierFilterByIndex = (index: number, filter: AppFiltersCheckboxGroupDTM) => {
    this.mobxStore.contracts.setCarriersFiltersByIndex(index, filter);
  };

  public setSearchQuery = async (query: string) => {
    this.mobxStore.contracts.setSearchQuery(query);

    await this.debouncedSearchByQuery();
  };

  public clearAllFilters = async () => {
    this.mobxStore.contracts.setCarriersFiltersGroup(DEFAULT_CARRIERS_FILTERS_GROUP);
    this.mobxStore.contracts.setInitialCarriersFilters(DEFAULT_CARRIERS_FILTERS_GROUP);
    this.mobxStore.contracts.setCategoryFiltersGroup(DEFAULT_CATEGORIES_FILTERS_GROUP);
    this.mobxStore.contracts.setInitialCategoriesFilters(DEFAULT_CATEGORIES_FILTERS_GROUP);

    await this.applyFilters(true);
  };

  public resetCategoryFilters = async () => {
    this.mobxStore.contracts.setCategoryFiltersGroup(DEFAULT_CATEGORIES_FILTERS_GROUP);
    this.mobxStore.contracts.setInitialCategoriesFilters(DEFAULT_CATEGORIES_FILTERS_GROUP);

    await this.applyFilters(true);
  }

  public setCategoryFilterByIndex = (index: number, filter: AppFiltersCheckboxGroupDTM) => {
    this.mobxStore.contracts.setCategoryFilterByIndex(index, filter);
  };

  debouncedSearchByQuery = debounce(async () => {
    await this.applyFilters(true);
  }, 500);

  public applyFilters = async (isReset?: boolean) => {
    if (!isReset && !this.mobxStore.contracts.hasCarriersFiltersChanged && !this.mobxStore.contracts.hasCategoryFiltersChanged) {
      return;
    }

    this.mobxStore.contracts.setIsLoading(true);

    const chosenCategoryFiltersValues = this.mobxStore.contracts.chosenCategoryFiltersGroup.map(({ value }) => value);
    const chosenCarriersFiltersValues = this.mobxStore.contracts.chosenCarriersFiltersGroup.map(({ value }) => value);
    const { searchQuery } = this.mobxStore.contracts.state;

    const contracts = await monetaryR.services.contracts.getContracts({
      categories: chosenCategoryFiltersValues.length ? chosenCategoryFiltersValues.join(', ') : undefined,
      carriers: chosenCarriersFiltersValues.length ? chosenCarriersFiltersValues.join(', ') : undefined,
      query: searchQuery || undefined,
      page: 0,
      size: CONTRACTS_PAGE_SIZE,
    });

    this.mobxStore.contracts.setInitialCategoriesFilters(this.mobxStore.contracts.state.categoryFiltersGroup);
    this.mobxStore.contracts.setInitialCarriersFilters(this.mobxStore.contracts.state.carriersFiltersGroup);
    this.mobxStore.contracts.setWasPaginationRequestLast(contracts.length < CONTRACTS_PAGE_SIZE);
    this.mobxStore.contracts.setPage(0);
    this.mobxStore.contracts.setContracts(contracts);
    this.mobxStore.contracts.setIsLoading(false);
  };

  public setContractNumber = (contractNumber: string) => {
    this.mobxStore.contracts.setContractNumber(contractNumber);
  };

  public setContractCarrier = (carrier: ECarrierSCAC) => {
    this.mobxStore.contracts.setContractCarrier(carrier);
  };

  public setContractCategory = (category: EContractCategory) => {
    this.mobxStore.contracts.setContractCategory(category);
  };

  public openCreateContractDrawer = () => {
    this.mobxStore.contracts.clearDrawerForm();
    UC.drawer.openCreateContractDrawer();
  }

  public openEditContractDrawer = (contract: ContractDTM) => {
    this.mobxStore.contracts.clearDrawerForm();
    this.mobxStore.contracts.setContractCategory(contract.category);
    this.mobxStore.contracts.setContractNumber(contract.number);
    this.mobxStore.contracts.setContractToEdit(contract);

    new DrawersUseCase(this).openEditContractDrawer();
  }

  public createContract = async () => {
    this.mobxStore.contracts.setIsDrawerLoading(true);

    const currentOrg = userManagementR.selectors.userOrganizationData.getUserOrganization(this.store.getState());
    const { contractNumber, contractCarrier, contractCategory } = this.mobxStore.contracts.state;

    if (!contractNumber || !contractCarrier || !contractCategory || !currentOrg) {
      return;
    }

    try {
      await monetaryR.services.contracts.createContract({
        number: contractNumber,
        name: contractNumber,
        scac: contractCarrier,
        category: contractCategory,
        organizationId: currentOrg.id,
      });
    } catch (e) {
      this.mobxStore.contracts.setIsDrawerLoading(false);
      new DrawersUseCase(this).closeDrawer();

      throw e;
    }

    this.mobxStore.contracts.setIsDrawerLoading(false);
    new DrawersUseCase(this).closeDrawer();
    await this.init();
  }

  public saveContract = async () => {
    this.mobxStore.contracts.setIsDrawerLoading(true);

    const {
      contractToEdit,
      contractCategory,
      contractNumber,
    } = this.mobxStore.contracts.state;

    if (!contractNumber || !contractToEdit || !contractCategory) {
      return;
    }

    let contract: ContractDTM;

    try {
      contract = await monetaryR.services.contracts.editContract({
        ...contractToEdit,
        number: contractNumber,
        name: contractNumber,
        category: contractCategory,
      });
    } catch (e) {
      this.mobxStore.contracts.setIsDrawerLoading(false);
      new DrawersUseCase(this).closeDrawer();

      throw e;
    }

    const contracts = [...this.mobxStore.contracts.state.contracts];
    const targetIndex = contracts.findIndex(({ id }) => id === contract.id);

    if (targetIndex !== -1) {
      contracts.splice(targetIndex, 1, contract);
    }

    this.mobxStore.contracts.setContracts(contracts);
    this.mobxStore.contracts.setIsDrawerLoading(false);

    new DrawersUseCase(this).closeDrawer();
  }

  public deleteContractById = async (contractId: number) => {
    this.mobxStore.contracts.setIsLoading(true);

    try {
      await monetaryR.services.contracts.deleteContract(contractId);
    } catch (e) {
      this.mobxStore.contracts.setIsLoading(false);

      throw new NotificationMessageError((e as Error).message);
    }

    const contracts = [...this.mobxStore.contracts.state.contracts];
    const targetIndex = contracts.findIndex(({ id }) => id === contractId);

    if (targetIndex !== -1) {
      contracts.splice(targetIndex, 1);
    }

    this.mobxStore.contracts.setContracts(contracts);
    this.mobxStore.contracts.setIsLoading(false);
  };
}
