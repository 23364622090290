import styled from 'styled-components';

import { Button } from 'app-wrapper/view/components';

export const ShipmentContainersFormLoadPlanContainer = styled.div.attrs({
  'data-class': 'ShipmentContainersFormLoadPlanContainer',
})`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  margin: 8px 0;
  gap: 16px;
  height: 20px;
  width: 100%;
`;

export const ShipmentContainersFormLoadPlanTitle = styled.span.attrs({
  'data-class': 'ShipmentContainersFormLoadPlanTitle',
})`
  font-size: 14px;
  font-weight: 500;
`;

export const ShipmentContainersFormLoadPlanButton = styled(Button).attrs({
  'data-class': 'ShipmentContainersFormLoadPlanButton',
})`
  font-size: 12px;
`;
