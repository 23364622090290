import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { PlusOutlined } from '@ant-design/icons';

import { TeamDTM } from 'user-management/models/dtm';
import { CreateOrEditTeamDrawer } from 'user-management/view/components';

import {
  SubPageHeader,
  SubPageHeaderContainer,
  SubPageHeaderTitle,
  Wrapper,
} from 'app-wrapper/view/guideline';
import { Button, EmptyStateForPages, TableSkeleton } from 'app-wrapper/view/components';
import { TeamsTable } from './components';

interface ITeamsComponentProps {
  init: () => void;
  teams: TeamDTM[];
  isLoading: boolean;
  openCreateDrawer: () => void;
  isAdmin?: boolean;
}

export const TeamsComponent: FC<ITeamsComponentProps> = ({
  init,
  teams,
  isLoading,
  openCreateDrawer,
  isAdmin,
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    init();
  }, []);

  return (
    <Wrapper>
      <CreateOrEditTeamDrawer />

      {isLoading ? (
        <TableSkeleton />
      ) : (
        <>
          <SubPageHeader>
            <SubPageHeaderContainer>
              <SubPageHeaderTitle>
                {t('Teams')}
              </SubPageHeaderTitle>

              {isAdmin ? (
                <Button
                  data-class="teams-create-team"
                  icon={<PlusOutlined />}
                  onClick={openCreateDrawer}
                >
                  {t('Create Team')}
                </Button>
              ) : null}
            </SubPageHeaderContainer>
          </SubPageHeader>

          {!isLoading && !teams?.length ? (
            <EmptyStateForPages
              headerText={t('No teams yet.')}
              contentText={t('Begin creating and assigning teams to your customers.')}
              buttonText={t('Create team')}
              onClickButton={openCreateDrawer}
            />
          ) : <TeamsTable teams={teams} />}
        </>
      )}
    </Wrapper>
  );
};
