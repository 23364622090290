import React, { FC } from 'react';
import { useParams, Navigate } from 'react-router';

import { RouteNames } from 'app-wrapper/constants';

export const ShipmentDocumentsDefaultNavigate: FC = () => {
  const { shipmentId } = useParams();

  return (
    <Navigate to={RouteNames.SHIPMENT_DOCUMENTS_ALL(shipmentId)} />
  );
};
