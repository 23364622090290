import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { R } from 'shipment-operations/repository';
import { UC } from 'shipment-operations/controllers';

import { ShipmentDocumentsSeaworthyCertificatesComponent } from './ShipmentDocumentsSeaworthyCertificates.component';

const ShipmentDocumentsSeaworthyCertificatesContainer: FC = () => {
  const { shipmentId = '' } = useParams();
  const containers = useSelector(R.selectors.shipmentDocumentsSeaworthyCertificates.getContainers);
  const isLoading = useSelector(R.selectors.shipmentDocumentsSeaworthyCertificates.getIsUpdateLoading);
  const hasSOC = useSelector(R.selectors.shipmentDocumentsSeaworthyCertificates.getHasSOC);
  const hasLoadingHappened = useSelector(R.selectors.shipmentDocumentsSeaworthyCertificates.getHasLoadingHappened);
  const permissions = useSelector(R.selectors.shipment.getDocumentsPermissions);

  return (
    <ShipmentDocumentsSeaworthyCertificatesComponent
      containers={containers}
      isLoading={isLoading}
      setInitialState={UC.shipmentDocumentsSeaworthyCertificates.setInitialState}
      goBackToDocuments={UC.shipmentDocumentsSeaworthyCertificates.goBackToDocuments}
      shipmentId={shipmentId}
      hasSOC={hasSOC}
      hasLoadingHappened={hasLoadingHappened}
      seaworthyCertificatesPermission={permissions.seaworthyCertificate}
    />
  );
};

export { ShipmentDocumentsSeaworthyCertificatesContainer as ShipmentDocumentsSeaworthyCertificates };
