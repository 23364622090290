import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { R } from 'shipment-operations/repository';
import { normalizationCost } from 'app-wrapper/utils';
import { store } from 'app-wrapper/store';
import { PaymentTermsDTM, PaymentTermsPayeeCompaniesDTM } from 'shipment-operations/models/dtm';
import { R as userManagementR } from 'user-management/repository';
import { EOrganizationMemberRole } from 'user-management/constants';

import { SBLPaymentTermsComponent } from './SBLPaymentTerms.component';

const SBLPaymentTermsContainer = () => {
  const { t } = useTranslation();
  const paymentTerms = useSelector(R.selectors.billOfLadingCommon.getPaymentTerms);
  const shippingPartiesMap = useSelector(R.selectors.billOfLadingCommon.getShippingPartiesAsMap);
  const calculatedAmountMBLPaymentTerms = useSelector(R.selectors.billOfLadingCommon.getCalculatedAmountsForMBLPaymentTerms);
  const calculatedAmountHBLPaymentTerms = useSelector(R.selectors.billOfLadingCommon.getCalculatedAmountsForHBLPaymentTerms);
  const shortShipment = R.selectors.shipment.getShipment(store.getState());
  const contractOwner = R.selectors.billOfLadingCommon.getContractOwner(store.getState());
  const isHBLPage = useSelector(R.selectors.billOfLadingCommon.getIsHBLPage);
  const organization = useSelector(userManagementR.selectors.userOrganizationData.getUserOrganization);

  const isCustomerOrg = useMemo(() => !!(organization && organization.role === EOrganizationMemberRole.CUSTOMER), [organization]);

  const calculatedAmounts = useMemo(() => {
    if (!isHBLPage) {
      return calculatedAmountMBLPaymentTerms;
    }

    return calculatedAmountHBLPaymentTerms;
  }, [isHBLPage]);

  const payeeCompanies = useMemo(() => {
    if (!shortShipment || !paymentTerms) {
      return PaymentTermsPayeeCompaniesDTM.fromPlain({
        origin: '',
        freight: '',
        destination: '',
      });
    }

    const { isSelfService } = shortShipment;
    const { tradeType } = paymentTerms;

    return PaymentTermsDTM.getPayeeCompanyNames(paymentTerms ? paymentTerms.incoterm : '', shippingPartiesMap, tradeType, contractOwner, !!isSelfService, isHBLPage);
  }, [paymentTerms, shippingPartiesMap, paymentTerms, shortShipment, contractOwner, isHBLPage]);

  const paymentsItems = useMemo(() => {
    if (!paymentTerms || !shortShipment) {
      return [];
    }

    const { isSelfService } = shortShipment;
    const { tradeType, incoterm } = paymentTerms;
    const paymentState = PaymentTermsDTM.getCurrentAvailablePayments(incoterm, tradeType, contractOwner, !!isSelfService, isHBLPage);

    return [
      {
        title: t('Incoterms'),
        subtitle: paymentTerms.incoterm,
      },
      {
        title: t('Origin'),
        subtitle: isCustomerOrg && !isHBLPage ? `${String(paymentState.origin)}` : `${String(paymentState.origin)} $${normalizationCost(calculatedAmounts.origin, { toFixed: 2 })}`,
        subtitleGrey: payeeCompanies.origin,
        isSubtitleInTitle: true,
      },
      {
        title: t('Freight'),
        subtitle: isCustomerOrg && !isHBLPage ? `${String(paymentState.freight)}` : `${String(paymentState.freight)} $${normalizationCost(calculatedAmounts.freight, { toFixed: 2 })}`,
        subtitleGrey: payeeCompanies.freight,
        isSubtitleInTitle: true,
      },
      {
        title: t('Destination'),
        subtitle: isCustomerOrg && !isHBLPage ? `${String(paymentState.destination)}` : `${String(paymentState.destination)} $${normalizationCost(calculatedAmounts.destination, { toFixed: 2 })}`,
        subtitleGrey: payeeCompanies.destination,
        isSubtitleInTitle: true,
      },
    ];
  }, [paymentTerms, t, calculatedAmounts, payeeCompanies, shippingPartiesMap, shortShipment, contractOwner, isHBLPage, isCustomerOrg]);

  return (
    <SBLPaymentTermsComponent
      items={paymentsItems}
      onOpenEdit={undefined}
      isEditable={false}
    />
  );
};

export { SBLPaymentTermsContainer as SBLPaymentTerms };
