import { REQUEST_STATUS } from 'app-wrapper/constants';
import i18n from 'app-wrapper/i18n/i18n';
import { BaseController, controller } from 'proto/BaseController';
import { SDCFMainContentFormDocumentConst } from 'shipment-operations/constants';
import { putShipmentDocumentsAdditionalDetailsResponse } from 'shipment-operations/models/contracts';

import {
  IShipmentDocumentDefaultStateDocumentsDTM, ShipmentDocumentDefaultStateDTM, IShipmentDocumentFileListDTM,
  IShipmentDocumentFileListStateDTM, IShipmentDocumentStateChangeDTM, ShipmentDocumentUpdateStateDTM,
  IShipmentDocumentUpdateStateErrorsDTM, TShipmentDocumentErrorsFieldKeys, ShipmentDocumentDefaultStateDocumentsDTM,
} from 'shipment-operations/models/dtm';
import { R } from 'shipment-operations/repository';

@controller
export class ShipmentDocumentAdditionalController extends BaseController {
  private setChangeState = (documents: IShipmentDocumentStateChangeDTM) => {
    this.dispatch(R.actions.shipmentDocument.setChangeState(documents));
  };

  private setUpdate = (documents: ShipmentDocumentUpdateStateDTM) => {
    this.dispatch(R.actions.shipmentDocument.setUpdate(documents));

    this.compareUpdateState();
  };

  private setDefault = (documents: ShipmentDocumentDefaultStateDTM) => {
    this.dispatch(R.actions.shipmentDocument.setDefault(documents));

    this.compareUpdateState();
  };

  public setUpdateDocuments = (documents: IShipmentDocumentDefaultStateDocumentsDTM[]) => {
    this.dispatch(R.actions.shipmentDocument.setUpdateDocuments(documents));

    this.validateDocumentsFiles();

    this.compareUpdateState();
  };

  public setUpdateAddDocuments = (documents: IShipmentDocumentDefaultStateDocumentsDTM) => {
    this.dispatch(R.actions.shipmentDocument.setUpdateAddDocuments(documents));

    this.validateDocumentsFiles();

    this.compareUpdateState();
  };

  public setUpdateAddDocumentsItem = () => {
    this.dispatch(R.actions.shipmentDocument.setUpdateAddDocuments({
      documentType: null,
      uploadFiles: '',
      fileName: '',
    }));

    this.compareUpdateState();
  };

  public setUpdateDeleteDocumentBy = (index: number) => {
    this.dispatch(R.actions.shipmentDocument.setUpdateDeleteDocumentBy(index));

    this.validateDocumentsFiles();

    this.compareUpdateState();
  };

  public setUpdateDocumentsFromDefaultState = () => {
    this.dispatch(R.actions.shipmentDocument.setUpdateDocumentsFromDefaultState());

    this.compareUpdateState();
  };

  public setUpdateDocumentByIndex = (document: IShipmentDocumentDefaultStateDocumentsDTM, index: number) => {
    this.dispatch(R.actions.shipmentDocument.setUpdateDocumentByIndex({
      document,
      index: Number(index),
    }));

    this.validateDocumentsFiles();

    this.compareUpdateState();
  };

  public setUpdateDocumentTypeByIndex = (documentType: string, index: number) => {
    this.dispatch(R.actions.shipmentDocument.setUpdateDocumentTypeByIndex({
      documentType,
      index: Number(index),
    }));

    this.setErrorDocumentTypeByIndex(
      'isVisited',
      index,
      true,
    );

    this.validateDocumentsFiles();

    this.compareUpdateState();
  };

  public setClearDocumentTypeByIndex = (index: number) => {
    this.dispatch(R.actions.shipmentDocument.setUpdateDocumentTypeByIndex({
      documentType: null,
      index: Number(index),
    }));

    this.validateDocumentsFiles();

    this.compareUpdateState();
  };

  public setIsBlurDocumentTypeByIndex = (index: number) => {
    this.setErrorDocumentTypeByIndex(
      'isBlur',
      index,
      true,
    );
    this.setErrorDocumentTypeByIndex(
      'isFocus',
      index,
      false,
    );

    this.validateDocumentsFiles();
  };

  public setIsFocusDocumentTypeByIndex = (index: number) => {
    this.setErrorDocumentTypeByIndex(
      'isBlur',
      index,
      false,
    );
    this.setErrorDocumentTypeByIndex(
      'isFocus',
      index,
      true,
    );

    this.validateDocumentsFiles();
  };

  public setUpdateDocumentFileByIndex = (documentFile: { value: string, name: string }, index: number) => {
    const { value, name } = documentFile;
    this.dispatch(R.actions.shipmentDocument.setUpdateDocumentFileByIndex({
      documentFile: value.slice(0, 512),
      fileName: name.slice(0, 512),
      index: Number(index),
    }));

    this.setErrorDocumentFileByIndex(
      'isVisited',
      index,
      true,
    );

    this.validateDocumentsFiles();

    this.compareUpdateState();
  };

  public setIsBlurDocumentFileByIndex = (index: number) => {
    this.setErrorDocumentFileByIndex(
      'isBlur',
      index,
      true,
    );
    this.setErrorDocumentFileByIndex(
      'isFocus',
      index,
      false,
    );

    this.validateDocumentsFiles();
  };

  public setIsFocusDocumentFileByIndex = (index: number) => {
    this.setErrorDocumentFileByIndex(
      'isBlur',
      index,
      false,
    );
    this.setErrorDocumentFileByIndex(
      'isFocus',
      index,
      true,
    );

    this.validateDocumentsFiles();
  };

  public setUpdateNotes = (notes: string) => {
    this.dispatch(R.actions.shipmentDocument.setUpdateNotes(notes.slice(0, 512)));

    this.compareUpdateState();
  };

  public setUpdateReference = (reference: string) => {
    this.dispatch(R.actions.shipmentDocument.setUpdateReference(reference.slice(0, 16)));

    this.compareUpdateState();
  };

  public setUpdateFileList = (key: string,
    file: IShipmentDocumentFileListDTM[]) => {
    this.dispatch(R.actions.shipmentDocument.setUpdateFileList({ key, file }));

    this.validateDocumentsFiles();
  };

  public setUpdateFileListHandler = (key: string) => (file: IShipmentDocumentFileListDTM[]) => {
    this.dispatch(R.actions.shipmentDocument.setUpdateFileList({ key, file }));

    this.validateDocumentsFiles();
  };

  public onRemoveUpdateDocumentItem = async (index: number) => {
    this.setDefaultGetManualAdditionalRequestStatus(REQUEST_STATUS.pending);

    const { shipmentDocument, shipment } = this.store.getState();
    this.setUpdateDeleteDocumentBy(index);

    const shipmentId = shipment.shipment?.shipmentName;
    const keyIndex = SDCFMainContentFormDocumentConst(index);

    try {
      // TODO: need async/await foreach
      // eslint-disable-next-line no-restricted-syntax
      for await (const itemFile of shipmentDocument?.fileList[keyIndex] || []) {
        await this.onRemoveFile(`${shipmentId}`, itemFile.fileId);
        await this.setUpdateRemoveFileListById(index, keyIndex, itemFile.fileId || undefined);
      }
    } catch (error) {
      console.error('onRemoveUpdateDocumentItem', error);
      this.setDefaultGetManualAdditionalRequestStatus(REQUEST_STATUS.rejected);
    }

    this.setDefaultGetManualAdditionalRequestStatus(REQUEST_STATUS.complete);
    this.setUpdateFixAfterRemoveFileListById(index);
    this.setUpdateFixErrorsDocumentItems(index);
  };

  public setUpdateRemoveFileListById = async (id: number, key: string, fileId?: string) => {
    const { shipment: { shipment } } = this.store.getState();
    this.dispatch(R.actions.shipmentDocument.setUpdateRemoveFileListById({ key }));

    this.dispatch(R.actions.shipmentDocument.setUpdateDocumentFileByIndex({
      index: id,
      documentFile: '',
      fileName: '',
      documentType: fileId ? null : undefined,
    }));

    this.validateDocumentsFiles();
    if (fileId) {
      await this.onDeleteUpdateFiles();
      await this.getRunAdditionalDetails(Number(shipment?.shipmentName));
      this.validateDocumentsFiles();
    }
  };

  public setUpdateRemoveFileListByIdHandler = (id: number, key: string) => async (fileId?: string) => {
    await this.setUpdateRemoveFileListById(id, key, fileId);
  };

  public setUpdateFixAfterRemoveFileListById = (index: number) => {
    const { shipmentDocument } = this.store.getState();
    const newFileList: IShipmentDocumentFileListStateDTM = {};

    Object.keys(shipmentDocument.fileList)?.forEach((keyList) => {
      shipmentDocument?.fileList[keyList]?.forEach((itemFile: IShipmentDocumentFileListDTM) => {
        let newIndex = Number(itemFile?.idIndex);
        let newItemFile = [...shipmentDocument?.fileList[keyList] || []];

        if (Number(itemFile?.idIndex) >= index) {
          newIndex = Number(itemFile?.idIndex);
          newItemFile = [...shipmentDocument?.fileList[keyList].map((item) => ({ ...item, idIndex: newIndex })) || []];
        }

        newFileList[SDCFMainContentFormDocumentConst(newIndex)] = newItemFile;
      });
    });

    this.dispatch(R.actions.shipmentDocument.setUpdateFileListAll(newFileList));
  };

  public setUpdateFixResetFileList = () => {
    const { shipmentDocument } = this.store.getState();
    const newFileList: IShipmentDocumentFileListStateDTM = {};

    const fileIds = shipmentDocument.defaultState.documents.map((document) => Number(document.uploadFiles) || []);

    Object.keys(shipmentDocument.fileList)?.forEach((keyList) => {
      shipmentDocument?.fileList[keyList]?.forEach((itemFile: IShipmentDocumentFileListDTM) => {
        const newIndex = Number(itemFile?.idIndex);
        let newItemFile = [...shipmentDocument?.fileList[keyList] || []];

        if (fileIds.includes(Number(itemFile.fileId))
          && shipmentDocument.defaultState.documents[newIndex]?.uploadFiles) {
          newItemFile = [...shipmentDocument?.fileList[keyList].map((item) => ({ ...item, idIndex: newIndex })) || []];

          newFileList[SDCFMainContentFormDocumentConst(newIndex)] = newItemFile;
        }
      });
    });

    this.dispatch(R.actions.shipmentDocument.setUpdateFileListAll(newFileList));
  };

  public setUpdateFixErrorsDocumentItems = (index?: number, deleteIndex?: number) => {
    const { shipmentDocument: { updateState: { errors, documents } } } = this.store.getState();
    let errorDocuments: IShipmentDocumentUpdateStateErrorsDTM['documents'] = [];

    if (index) {
      errors.documents.forEach((error, indexError) => {
        if (documents.length > indexError) {
          errorDocuments[indexError] = error;
        }
      });
    } else if (deleteIndex) {
      errorDocuments = errors.documents.filter((document, indexError) => indexError !== deleteIndex);
    }

    this.dispatch(R.actions.shipmentDocument.setUpdateChangeErrors({
      ...errors,
      documents: errorDocuments,
    }));
  };

  public resetUpdateFileList = () => {
    this.dispatch(R.actions.shipmentDocument.resetUpdateFileList());
  };

  public resetUpdateFileListToDefault = () => {
    const { shipmentDocument } = this.store.getState();
    const newFileList: IShipmentDocumentFileListStateDTM = {};
    const sameFileIds: number[] = [];
    const fileIds = shipmentDocument.defaultState.documents.map((document) => Number(document.uploadFiles) || []);

    Object.keys(shipmentDocument.fileList)?.forEach((keyList) => {
      shipmentDocument?.fileList[keyList]?.forEach((itemFile: IShipmentDocumentFileListDTM) => {
        let newIndex = Number(itemFile?.idIndex);
        let newItemFile = [...shipmentDocument?.fileList[keyList] || []];

        if (fileIds.includes(Number(itemFile?.fileId))
          && !sameFileIds.includes(Number(itemFile?.fileId))) {
          newIndex = Number(itemFile?.idIndex);
          newItemFile = [...shipmentDocument?.fileList[keyList].map((item) => ({ ...item, idIndex: newIndex })) || []];
          sameFileIds.push(Number(itemFile?.fileId));

          newFileList[SDCFMainContentFormDocumentConst(newIndex)] = newItemFile;
        }
      });
    });

    this.dispatch(R.actions.shipmentDocument.setUpdateFileListAll(newFileList));
  };

  public downloadFileDocument = async (documentId: number | null, documentName: string) => {
    const { shipment } = this.store.getState();
    const shipmentId = Number(shipment.shipment?.shipmentName);

    if (!documentId) {
      console.error('PREVIEW downloadFileDocument: CONTROLLER ERROR');

      return;
    }

    try {
      R.services.shipmentDocument.getShipmentDocument(shipmentId, documentId, documentName);
    } catch (e) {
      console.error('PREVIEW downloadFileDocument: CONTROLLER ERROR');
    }
  }

  public onRemoveFileHandler = (shipmentId: string | number, id: number, key: string) => async (fileId?: string | null) => {
    if (fileId) {
      this.setDefaultGetManualAdditionalRequestStatus(REQUEST_STATUS.pending);
      const { shipmentDocument } = this.store.getState();

      try {
        const fileIds = shipmentDocument.defaultState.documents.map((document) => Number(document.uploadFiles) || []);
        await R.services.shipmentDocument
          .onRemoveFile(Number(shipmentId), fileId || '');

        if (fileIds.includes(Number(fileId))) {
          await this.setUpdateRemoveFileListById(id, key, fileId);
          this.setUpdateFixResetFileList();
        }

        this.setDefaultGetManualAdditionalRequestStatus(REQUEST_STATUS.complete);
      } catch (error) {
        console.error('onRemoveFileHandler', error);

        this.setDefaultGetManualAdditionalRequestStatus(REQUEST_STATUS.rejected);
      }
    }
  };

  public onRemoveFile = async (shipmentId: string | number, fileId?: string | null) => {
    await R.services.shipmentDocument.onRemoveFile(shipmentId, fileId || '');
  };

  public getAdditionalDetails = (shipmentId: number) => {
    R.services.shipmentDocument.getAdditionalDetails(shipmentId);
  };

  public getRunAdditionalDetailsWithCheck = async (shipmentId: number) => {
    const state = this.store.getState();
    const shipment = R.selectors.shipment.getShipment(state);
    const shipmentDocumentDefault = R.selectors.shipmentDocuments.getShipmentDocumentDefault(state);

    if (!shipment?.shipmentName || shipmentDocumentDefault.isRequest) {
      return Promise.resolve();
    }

    return this.getRunAdditionalDetails(shipmentId);
  }

  public getRunAdditionalDetails = async (shipmentId: number) => {
    let response: putShipmentDocumentsAdditionalDetailsResponse | null = null;

    try {
      this.dispatch(R.actions.shipmentDocument.setDefaultGetAdditionalRequestStatus(REQUEST_STATUS.pending));

      response = await R.services.shipmentDocument.getAdditionalDetails(shipmentId);
      const fileList: IShipmentDocumentFileListStateDTM = {};

      if (response === null) {
        this.dispatch(R.actions.shipmentDocument.setDefaultIsRequest(true));
        this.dispatch(R.actions.shipmentDocument.setDefaultIsEmptyRequest(true));

        this.dispatch(R.actions.shipmentDocument.setDefaultGetAdditionalRequestStatus(REQUEST_STATUS.complete));
        return;
      }

      // TODO: Refactor to use DTM
      const documents = response?.files.length
        ? response?.files.map((file, index) => {
          fileList[SDCFMainContentFormDocumentConst(index)] = [{
            fileId: `${file.id}`,
            idIndex: index,
            name: file.name,
            uid: `${file.id}`,
          }];

          return ShipmentDocumentDefaultStateDocumentsDTM.fromPlain({
            documentType: file.type,
            fileName: file.name,
            uploadFiles: `${file.id || ''}`,
          });
        })
        : [ShipmentDocumentDefaultStateDocumentsDTM.fromPlain({
          documentType: null,
          uploadFiles: '',
          fileName: '',
        })];

      this.setDefault(ShipmentDocumentDefaultStateDTM.fromPlain({
        additionalId: response?.id || null,
        notes: response?.notes || '',
        reference: response?.references[0]?.value || '',
        referenceId: response?.references[0]?.id || null,
        documents,
        isEmptyRequest: false,
        isRequest: true,
        getAdditionalStatus: REQUEST_STATUS.complete,
        getManualAdditionalStatus: REQUEST_STATUS.complete,
      }));

      this.setUpdate(ShipmentDocumentUpdateStateDTM.fromPlain({
        additionalId: response?.id || null,
        notes: response?.notes || '',
        reference: response?.references[0]?.value || '',
        referenceId: response?.references[0]?.id || null,
        isUpdate: false,
        documents,
        errors: {
          documents: [],
        },
      }));

      this.dispatch(R.actions.shipmentDocument.setUpdateFileListAll(fileList));

      this.compareUpdateState();

      return;
    } catch (e: any) {
      this.dispatch(R.actions.shipmentDocument.setDefaultGetAdditionalRequestStatus(REQUEST_STATUS.rejected));

      console.error(e);
    }
  };

  public putAdditionalDetails = async (shipmentId: number) => {
    const { shipmentDocument } = this.store.getState();
    const references = shipmentDocument.updateState.reference
      ? [{
        id: shipmentDocument.updateState.referenceId,
        value: shipmentDocument.updateState.reference,
      }]
      : null;

    try {
      await R.services.shipmentDocument
        .putAdditionalDetails(shipmentId, {
          id: shipmentDocument.updateState.additionalId,
          notes: shipmentDocument.updateState.notes,
          references,
          files: shipmentDocument.updateState.documents
            .filter((document) => document.uploadFiles)
            .map((document) => ({
              id: Number(document.uploadFiles),
              name: document.fileName,
              type: document.documentType || '',
            })),
        });

      await this.getRunAdditionalDetails(shipmentId);

      await this.compareUpdateState();
    } catch (e) {
      console.error(e);
    }
  };

  public putUpdateAllFileTypeAdditionalDetails = async (shipmentId: number) => {
    const { shipmentDocument: { defaultState, updateState } } = this.store.getState();
    const backState: {
      id: number
      document: IShipmentDocumentDefaultStateDocumentsDTM
    }[] = [];

    try {
      await updateState.documents?.forEach((document, indexDocument) => {
        if (defaultState?.documents?.[indexDocument].documentType
          && defaultState?.documents?.[indexDocument].documentType !== document.documentType) {
          backState.push({
            id: indexDocument,
            document: defaultState?.documents?.[indexDocument],
          });
          R.services.shipmentDocument
            .putUpdateFileTypeAdditionalDetails({
              shipmentId,
              documentId: document.uploadFiles,
              newType: document?.documentType || '',
            });
        }
      });
    } catch (e) {
      console.error(e);

      if (backState.length) {
        backState.forEach((document) => {
          this.dispatch(R.actions.shipmentDocument.setDefaultDocumentsById({
            id: document.id,
            document: document.document,
          }));
        });
        this.compareUpdateState();
      }
      return;
    }

    updateState.documents?.forEach((document, indexDocument) => {
      if (defaultState?.documents?.[indexDocument].documentType
        && defaultState?.documents?.[indexDocument].documentType !== document.documentType) {
        this.dispatch(R.actions.shipmentDocument.setDefaultDocumentsById({
          id: indexDocument,
          document: {
            ...defaultState?.documents?.[indexDocument],
            documentType: document?.documentType || '',
          },
        }));
      }
    });

    this.compareUpdateState();
  };

  public postUpdateAllFileTypeAdditionalDetailsWithoutDefault = async (shipmentId: number) => {
    const { shipmentDocument: { updateState, fileList } } = this.store.getState();
    const backState: {
      id: number
      document: IShipmentDocumentDefaultStateDocumentsDTM
    }[] = [];

    try {
      await Object.values(fileList)?.forEach((valueList) => {
        valueList?.forEach((itemFile: IShipmentDocumentFileListDTM) => {
          const indexDocument = Number(itemFile?.idIndex);

          if (itemFile.response?.type !== updateState.documents?.[indexDocument]?.documentType) {
            backState.push({
              id: indexDocument,
              document: updateState.documents?.[indexDocument],
            });

            R.services.shipmentDocument
              .putUpdateFileTypeAdditionalDetails({
                shipmentId,
                documentId: updateState.documents?.[indexDocument].uploadFiles,
                newType: updateState.documents?.[indexDocument]?.documentType || '',
              });
          }
        });
      });
    } catch (e) {
      console.error('putUpdateAllFileTypeAdditionalDetailsWithoutDefault', e);

      if (backState.length) {
        backState.forEach((document) => {
          this.dispatch(R.actions.shipmentDocument.setDefaultDocumentsById({
            id: document.id,
            document: document.document,
          }));
        });
        this.compareUpdateState();
      }
      return;
    }

    this.compareUpdateState();
  };

  public postAdditionalDetails = async (shipmentId: number) => {
    const { shipmentDocument } = this.store.getState();
    const references = shipmentDocument.updateState.referenceId
      || shipmentDocument.updateState.reference
      ? [{
        id: shipmentDocument.updateState.referenceId,
        value: shipmentDocument.updateState.reference,
      }]
      : null;

    try {
      await R.services.shipmentDocument
        .postAdditionalDetails(shipmentId, {
          id: shipmentDocument.updateState.additionalId,
          notes: shipmentDocument.updateState.notes,
          references,
          files: shipmentDocument.updateState.documents
            .filter((document) => document.uploadFiles)
            .map((document) => ({
              id: Number(document.uploadFiles),
              name: document.fileName,
              type: document.documentType || '',
            })),
        });

      this.dispatch(R.actions.shipmentDocument.setDefaultIsEmptyRequest(false));

      await this.getRunAdditionalDetails(shipmentId);

      await this.compareUpdateState();
    } catch (e) {
      console.error(e);
    }
  };

  public onUpdateAdditional = async () => {
    const { shipmentDocument, shipment } = this.store.getState();
    const { defaultState: shipmentDocumentDefault } = shipmentDocument;
    const shipmentId = Number(shipment.shipment?.shipmentName);

    if (this.validateDocumentsFiles()) {
      this.setAllVisited();
      return;
    }
    this.setAllVisited(false);

    try {
      const isCheckChange = this.checkChangeDocumentType();

      if (shipmentDocumentDefault.isRequest && shipmentDocumentDefault.isEmptyRequest) {
        if (this.checkChangeDocumentTypeWithoutDefault()) {
          await this.postUpdateAllFileTypeAdditionalDetailsWithoutDefault(shipmentId);
        }

        await this
          .postAdditionalDetails(shipmentId);
        this.onDiscardHandler();
      } else {
        if (isCheckChange) {
          await this.putUpdateAllFileTypeAdditionalDetails(shipmentId);
        }

        if (this.compareUpdateStateWithoutDocumentsType()) {
          await this
            .putAdditionalDetails(shipmentId);
          this.onDiscardHandler();
        }
      }
    } catch (error) {
      console.error('onUpdateAdditional', error);
    }
  };

  private checkChangeDocumentTypeWithoutDefault = () => {
    const { shipmentDocument: { updateState, fileList } } = this.store.getState();
    let isChanged = false;

    Object.values(fileList)?.forEach((valueList) => {
      valueList?.forEach((itemFile: IShipmentDocumentFileListDTM) => {
        const indexDocument = Number(itemFile?.idIndex);

        if (!isChanged
          && itemFile.response?.type !== updateState.documents?.[indexDocument]?.documentType) {
          isChanged = true;
        }
      });
    });

    return isChanged;
  }

  private checkChangeDocumentType = () => {
    const { shipmentDocument: { defaultState, updateState } } = this.store.getState();
    let isChanged = false;

    updateState.documents.forEach((document, index) => {
      const documentDefault = defaultState?.documents?.[index];

      if (!isChanged
        && (
          (!documentDefault || document.documentType !== documentDefault?.documentType)
          && (!document || document?.fileName === documentDefault?.fileName)
          && (!document || document?.uploadFiles === documentDefault?.uploadFiles)
        )) {
        isChanged = true;
      }
    });

    return isChanged;
  }

  private validateDocumentsFiles = () => {
    const { shipmentDocument: { updateState } } = this.store.getState();
    const errors = { ...updateState.errors };
    const errorDocuments = [...errors.documents];
    let hasError = false;

    updateState.documents.forEach((document, index) => {
      if (document.uploadFiles && !document.documentType) {
        hasError = true;

        this.setErrorDocumentTypeByIndex(
          'message',
          index,
          i18n.t('Validations.mixed.required'),
        );
      } else if (errorDocuments[index]) {
        this.setErrorDocumentTypeByIndex(
          'message',
          index,
          '',
        );
      }

      if (document.documentType && !document.uploadFiles) {
        hasError = true;

        this.setErrorDocumentFileByIndex(
          'message',
          index,
          i18n.t('Validations.mixed.required'),
        );
      } else if (errorDocuments[index]) {
        this.setErrorDocumentFileByIndex(
          'message',
          index,
          '',
        );
      }
    });

    return hasError;
  }

  private setAllVisited = (flag = true) => {
    const { shipmentDocument: { updateState: { errors } } } = this.store.getState();

    errors.documents.forEach((document, index) => {
      this.setErrorDocumentTypeByIndex(
        'isVisited',
        index,
        flag,
      );

      this.setErrorDocumentFileByIndex(
        'isVisited',
        index,
        flag,
      );
    });
  }

  private setErrorDocumentTypeByIndex = (key: TShipmentDocumentErrorsFieldKeys, index: number, value: any) => {
    const { shipmentDocument: { updateState } } = this.store.getState();
    const errors = { ...updateState.errors };
    const errorDocuments = [...errors.documents];

    if (errorDocuments[index]) {
      errorDocuments[index] = {
        ...errorDocuments[index],
        documentType: {
          ...errorDocuments[index]?.documentType || {},
          [key]: value,
        },
      };
    } else {
      errorDocuments.push({
        documentType: {
          message: i18n.t('Validations.mixed.required'),
        },
      });
    }

    this.dispatch(R.actions.shipmentDocument.setUpdateChangeErrors({
      ...errors,
      documents: errorDocuments,
    }));

    this.compareUpdateState();
  }

  private setErrorDocumentFileByIndex = (key: TShipmentDocumentErrorsFieldKeys, index: number, value: any) => {
    const { shipmentDocument: { updateState } } = this.store.getState();
    const errors = { ...updateState.errors };
    const errorDocuments = [...errors.documents];

    errorDocuments[index] = {
      ...errorDocuments[index],
      uploadFiles: {
        ...errorDocuments[index]?.uploadFiles || {},
        [key]: value,
      },
    };

    this.dispatch(R.actions.shipmentDocument.setUpdateChangeErrors({
      ...errors,
      documents: errorDocuments,
    }));

    this.compareUpdateState();
  }

  private resetUpdateAllError = () => {
    this.dispatch(R.actions.shipmentDocument.resetUpdateAllError());
  }

  public onDiscardHandler = () => {
    this.onDeleteUpdateFiles();

    this.resetUpdateFileListToDefault();
    this.resetUpdateAllError();
    this.setUpdateDocumentsFromDefaultState();

    this.setUpdateFixResetFileList();
    this.compareUpdateState();
  };

  public resetDefaultDocumentsFromState = () => {
    this.dispatch(R.actions.shipmentDocument.resetDefaultDocumentsFromState());
  }

  public setDefaultIsRequest = (flag: boolean) => {
    this.dispatch(R.actions.shipmentDocument.setDefaultIsRequest(flag));
  }

  public setDefaultGetAdditionalRequestStatus = (status: REQUEST_STATUS) => {
    this.dispatch(R.actions.shipmentDocument.setDefaultGetAdditionalRequestStatus(status));
  }

  public setDefaultGetManualAdditionalRequestStatus = (status: REQUEST_STATUS) => {
    this.dispatch(R.actions.shipmentDocument.setDefaultGetManualAdditionalRequestStatus(status));
  }

  public resetBothDocumentsFromState = () => {
    this.dispatch(R.actions.shipmentDocument.resetDefaultDocumentsFromState());

    this.dispatch(R.actions.shipmentDocument.setUpdateDocumentsFromDefaultState());

    this.compareUpdateState();
  }

  private onDeleteUpdateFiles = async () => {
    const { shipmentDocument, shipment } = this.store.getState();

    const fileIds = shipmentDocument.defaultState.documents.map((document) => Number(document.uploadFiles) || []);

    // TODO: need async/await foreach
    // eslint-disable-next-line no-restricted-syntax
    for await (const keyList of Object.keys(shipmentDocument.fileList)) {
      // eslint-disable-next-line no-restricted-syntax
      for await (const itemFile of shipmentDocument?.fileList[keyList] || []) {
        if (!fileIds.includes(Number(itemFile.fileId))) {
          await this.onRemoveFile(Number(shipment.shipment?.shipmentName), itemFile?.fileId);
        }
      }
    }
  }

  public onEnterPage = () => {
    this.resetBothDocumentsFromState();
    this.resetUpdateFileList();
  }

  public onLeavePage = () => {
    this.onDeleteUpdateFiles();

    this.resetBothDocumentsFromState();
    this.resetUpdateFileList();
  }

  private compareUpdateState = () => {
    const { shipmentDocument: { updateState, defaultState } } = this.store.getState();
    let isUpdate = false;
    if (updateState.additionalId !== defaultState.additionalId
      || updateState.referenceId !== defaultState.referenceId
      || updateState.reference !== defaultState.reference
      || updateState.notes !== defaultState.notes
    ) {
      isUpdate = true;
    }

    updateState.documents.forEach((document, index) => {
      const documentDefault = defaultState?.documents?.[index];

      if (!isUpdate
        && (
          (!documentDefault || document.documentType !== documentDefault?.documentType)
          || (!document || document.fileName !== documentDefault.fileName)
          || (!document || document.uploadFiles !== documentDefault.uploadFiles)
        )) {
        isUpdate = true;
      }
    });
    this.dispatch(R.actions.shipmentDocument.setIsUpdate(isUpdate));

    return isUpdate;
  }

  private compareUpdateStateWithoutDocumentsType = () => {
    const { shipmentDocument: { updateState, defaultState } } = this.store.getState();
    let isUpdate = false;

    if (updateState.additionalId !== defaultState.additionalId
      || updateState.referenceId !== defaultState.referenceId
      || updateState.reference !== defaultState.reference
      || updateState.notes !== defaultState.notes
    ) {
      isUpdate = true;
    }

    updateState.documents.forEach((document, index) => {
      let documentDefault: IShipmentDocumentDefaultStateDocumentsDTM | null = defaultState?.documents?.[index];
      if (index === 0 && defaultState?.documents?.[index]?.documentType === null) {
        documentDefault = null;
      }

      if (!isUpdate
        && (
          (!documentDefault
            || (document.documentType === documentDefault?.documentType))
          && ((!document || document?.fileName !== documentDefault?.fileName)
            || (!document || document?.uploadFiles !== documentDefault?.uploadFiles))
        )) {
        isUpdate = true;
      }
    });

    return isUpdate;
  }
}
