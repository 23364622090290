import React from 'react';
import { IPropsIcon } from 'app-wrapper/interfaces/icon';

export const FlagSvg = (props: IPropsIcon) => {
  const { width, height, style } = props;
  return (
    <svg
      className="FlagSvg"
      width={width || '14'}
      height={height || '14'}
      viewBox="0 0 14 14"
      color={style?.color || '#6C79DE'}
      style={style || undefined}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M2.87598 3.62695H8.62598V8.87695H2.87598V3.62695Z" fill="#F0F2FC" />
      <path d="M9.75098 9.87695C9.75098 9.9457 9.69473 10.002 9.62598 10.002H7.87598V11.1426H13.126V5.89258H9.75098V9.87695Z" fill="#F0F2FC" />
      <path d="M12.751 3.76562H8.75098V2C8.75098 1.72344 8.52754 1.5 8.25098 1.5H1.87598V0.875C1.87598 0.80625 1.81973 0.75 1.75098 0.75H0.875977C0.807227 0.75 0.750977 0.80625 0.750977 0.875V13.125C0.750977 13.1938 0.807227 13.25 0.875977 13.25H1.75098C1.81973 13.25 1.87598 13.1938 1.87598 13.125V9H5.75098V10.7656C5.75098 11.0422 5.97441 11.2656 6.25098 11.2656H12.751C13.0275 11.2656 13.251 11.0422 13.251 10.7656V4.26562C13.251 3.98906 13.0275 3.76562 12.751 3.76562ZM1.87598 7.875V2.625H7.62598V7.875H1.87598ZM12.126 10.1406H6.87598V9H8.62598C8.69473 9 8.75098 8.94375 8.75098 8.875V4.89062H12.126V10.1406Z" fill="currentColor" />
    </svg>
  );
};
