import styled from 'styled-components';

import { SubPageContent } from 'app-wrapper/view/guideline';

export const Content = styled(SubPageContent)`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 64px;
`;

export const Title = styled.span`
  width: fit-content;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
`;

export const Block = styled.div`
  width: fit-content;
`;
