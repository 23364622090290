import styled from 'styled-components';
import Button from 'antd/es/button';

export const StyledButton = styled(Button)`
  background-color: #4E5D70;
  border: none;

  &:hover {
    background-color: #798aa2;
  }
`;
