import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import {
  ShipmentContainersFormLoadPlanContainer,
  ShipmentContainersFormLoadPlanTitle,
} from './ShipmentContainersFormLoadPlan.styled';

export const ShipmentContainersFormLoadPlanComponent: FC = () => {
  const { t } = useTranslation();

  return (
    <ShipmentContainersFormLoadPlanContainer>
      <ShipmentContainersFormLoadPlanTitle>
        {t('Load plan')}
      </ShipmentContainersFormLoadPlanTitle>
    </ShipmentContainersFormLoadPlanContainer>
  );
};
