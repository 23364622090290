import { BaseUseCase } from 'app-wrapper/usecases/Base.useCase';
import { BaseController } from 'proto/BaseController';

export class DownloadFileUseCase extends BaseUseCase {
  controller: BaseController

  // eslint-disable-next-line class-methods-use-this
  execute(fileName: string = 'document', blob: Blob) {
    if (!blob) {
      return;
    }

    const url = window.URL.createObjectURL(blob);
    const anchor = document.createElement('a');

    anchor.href = url;
    anchor.download = fileName;
    anchor.click();

    window.URL.revokeObjectURL(url);
    anchor.remove();
  }
}
