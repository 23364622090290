import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import {
  PackageTypeNames,
  PackageType,
  ReversePackageTypesCodes,
  PackageTypesCodes,
} from 'app-wrapper/types/PackageType';
import { Option } from 'app-wrapper/view/components';
import { CargoDTM } from 'shipment-operations/models/dtm';

import { SelectStyled } from './PackTypeSelect.styled';

interface PackTypeSelectComponentProps {
  packageType: CargoDTM['packageType']
  touchCargoField: (field: string, cargoId: number) => void
  setCargoPackageType: (value: PackageType, cargoId: number) => void
  cargoId: number;
  error?: boolean
}

export const PackTypeSelectComponent: FC<PackTypeSelectComponentProps> = ({
  packageType,
  touchCargoField,
  setCargoPackageType,
  cargoId,
  error,
}) => {
  const { t } = useTranslation();

  const setPackageType = useCallback((value: PackageType) => {
    setCargoPackageType(PackageTypesCodes[value as keyof typeof PackageTypeNames] as PackageType, cargoId);
  }, [setCargoPackageType, cargoId]);

  const touchField = useCallback((field: string) => {
    touchCargoField(field, cargoId);
  }, [touchCargoField, cargoId]);

  const handleBlur = useCallback(() => {
    touchField('packageType');
  }, [touchField]);

  return (
    <SelectStyled
      data-class="package-type-select"
      placeholder={t('Type')}
      value={packageType ? ReversePackageTypesCodes[packageType as unknown as keyof typeof ReversePackageTypesCodes] : undefined}
      error={error}
      allowClear={!!packageType}
      onBlur={handleBlur}
      onChange={setPackageType}
      size="middle"
      showSearch
      listHeight={200}
    >
      {Object.keys(PackageTypeNames).map((type) => (
        <Option key={type} value={type}>
          {t(PackageTypeNames[type as PackageType])}
        </Option>
      ))}
    </SelectStyled>
  );
};
