import {
  IsEnum, IsString, IsDefined, IsOptional, ValidateNested, IsBoolean, IsNumber,
} from 'class-validator';
import { Type } from 'class-transformer';
import { ENotificationCriticality } from 'app-wrapper/constants';
import { BaseDTM } from 'proto/BaseDTM';
import { ETasksType, MISSING_DATES_SEARCH_PARAM } from 'shipment-operations/constants';
import { DateDtm } from './Date';
import { INotificationTaskContentAssigneeDTM, NotificationTaskContentAssigneeDTM } from './NotificationTask.dtm';

export interface ICommandCenterComponentTaskDTM {
  id: string;
  customId?: string;
  priority?: ENotificationCriticality;
  title?: string;
  description?: string;
  dueDate?: DateDtm;
  completedAt?: DateDtm;
  datePriority?: ENotificationCriticality;
  dateWarning?: boolean;
  isViewed?: boolean;
  viewedId?: string;
  shipmentType?: string;
  rateRequestType?: string;
  rateOrganizationType?: string;
  shipmentName?: string;
  rateRequestName?: string;
  rateOrganizationIdName?: string;
  shipmentId?: number;
  requestId?: number;
  organizationId?: number;
  module?: string;
  assignee?: INotificationTaskContentAssigneeDTM;
  assigneeUsers?: INotificationTaskContentAssigneeDTM[];
  actionLink?: string;
  taskType?: string;
}

export class CommandCenterComponentTaskDTM extends BaseDTM<ICommandCenterComponentTaskDTM> {
  @IsDefined()
  @IsString()
  id: string;

  @IsOptional()
  @IsString()
  customId?: string;

  @IsOptional()
  @IsEnum(ENotificationCriticality)
  priority?: ENotificationCriticality;

  @IsOptional()
  @IsString()
  title?: string;

  @IsOptional()
  @IsString()
  description?: string;

  @IsOptional()
  @IsNumber()
  shipmentId?: number;

  @IsOptional()
  @IsNumber()
  requestId?: number;

  @IsOptional()
  @IsNumber()
  organizationId?: number;

  @IsOptional()
  @ValidateNested()
  @Type(() => DateDtm)
  dueDate?: DateDtm;

  @IsOptional()
  @ValidateNested()
  @Type(() => DateDtm)
  completedAt?: DateDtm;

  @IsOptional()
  @IsEnum(ENotificationCriticality)
  datePriority?: ENotificationCriticality;

  @IsOptional()
  @IsBoolean()
  dateWarning?: boolean;

  @IsOptional()
  @IsBoolean()
  isViewed?: boolean;

  @IsOptional()
  @IsString()
  viewedId?: string;

  @IsOptional()
  @IsString()
  shipmentType?: string;

  @IsOptional()
  @IsString()
  rateRequestType?: string;

  @IsOptional()
  @IsString()
  rateOrganizationType?: string;

  @IsOptional()
  @IsString()
  shipmentName?: string;

  @IsOptional()
  @IsString()
  rateRequestName?: string;

  @IsOptional()
  @IsString()
  rateOrganizationIdName?: string;

  @IsOptional()
  @IsString()
  module?: string;

  @IsOptional()
  @ValidateNested()
  @Type(() => NotificationTaskContentAssigneeDTM)
  assignee?: NotificationTaskContentAssigneeDTM;

  @IsOptional()
  @ValidateNested({ each: true })
  @Type(() => NotificationTaskContentAssigneeDTM)
  assigneeUsers?: NotificationTaskContentAssigneeDTM[];

  @IsOptional()
  @IsString()
  actionLink?: string;

  @IsOptional()
  @IsString()
  taskType?: string;

  link(): string {
    const link = (this.actionLink && this.actionLink.startsWith('/') ? this.actionLink : `/${this.actionLink}`) || '';

    if (this.taskType === ETasksType.MISSING_DATES) {
      return `${link}?${MISSING_DATES_SEARCH_PARAM}=true`;
    }

    return link;
  }
}
