import styled from 'styled-components';
import CopyOutlined from '@ant-design/icons/CopyOutlined';

import { Select } from 'app-wrapper/view/components';
import { divFlex, responsive } from 'app-wrapper/view/themes/mixin';

export const CustomerSelect = styled(Select).attrs((props: {
  isError: boolean
  'data-class'?: string
}) => ({
  isError: props.isError,
  'data-class': props?.['data-class'] || 'CustomerSelect',
}))`
  .ant-select-selector {
    ${({ isError, theme }) => isError && `border-color: ${theme.themesColors.characterBrandingDanger} !important;`};
    ${({ isError }) => isError && 'z-index: 2;'};
  }
`;

export const CustomerNameBlockWrap = styled.div.attrs((props: {
  isCollapse: boolean
  'data-class'?: string
}) => ({
  isCollapse: props.isCollapse,
  'data-class': props?.['data-class'] || 'CustomerNameBlockWrap',
}))`
  ${divFlex}
  ${({ isCollapse }) => isCollapse && 'flex-direction: column;'};
  ${({ isCollapse }) => !isCollapse && 'margin-top: 8px;'};
  width: 100%;
`;

export const CopyIcon = styled(CopyOutlined)`
  color: #73819B;
  font-size: 12px;
  margin-left: 3px;
  cursor: pointer;
`;

export const CustomerNameBlock = styled.div.attrs((props: {
  isCollapse: boolean
  isMarginLeft: boolean
  isLast: boolean
  isWidth: boolean
  'data-class'?: string
}) => ({
  isCollapse: props.isCollapse,
  isMarginLeft: props.isMarginLeft,
  isLast: props.isLast,
  isWidth: props.isWidth,
  'data-class': props?.['data-class'] || 'CustomerNameBlock',
}))`
  ${divFlex}

  color: ${({ theme }) => theme.themesColors.neutralBranding10};
  border: 1px solid ${({ theme }) => theme.themesColors.neutralBranding4};
  border-radius: 3px;
  padding: 10px;
  min-width: 300px;
  ${({ isWidth }) => (isWidth ? 'width: 100%' : 'width: 48.4%')};
  background-color: ${({ theme }) => theme.themesColors.neutralBranding1};

  ${({ isMarginLeft }) => (isMarginLeft && 'margin-left: auto;')};
  ${({ isCollapse, isLast }) => (isCollapse && isLast ? 'margin-top: 16px;' : '')};
`;

export const CustomerNameImage = styled.div.attrs((props: {
  isError: boolean
  'data-class'?: string
}) => ({
  isError: props.isError,
  'data-class': props?.['data-class'] || 'CustomerNameImage',
}))`
  ${divFlex}

  padding-top: 2px;
  svg {
    color: ${({ theme }) => theme.themesColors.secondaryDot45};
  }
`;

export const CustomerNameContent = styled.div.attrs((props: {
  isError: boolean
  'data-class'?: string
}) => ({
  isError: props.isError,
  'data-class': props?.['data-class'] || 'CustomerNameContent',
}))`
  ${divFlex}
  flex-direction: column;

  margin-left: 8px;
  justify-content: center;
`;

export const CustomerNameTitle = styled.div.attrs((props: {
  isError: boolean
  'data-class'?: string
}) => ({
  isError: props.isError,
  'data-class': props?.['data-class'] || 'CustomerNameTitle',
}))`
  ${divFlex}
  align-items: center;

  color: ${({ theme }) => theme.themesColors.secondaryDot45};
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
`;

export const CustomerNameText = styled.div.attrs((props: {
  isError: boolean
  'data-class'?: string
}) => ({
  isError: props.isError,
  'data-class': props?.['data-class'] || 'CustomerNameText',
}))`
  ${divFlex}
  align-items: center;

  color: ${({ theme }) => theme.themesColors.neutralBranding10};
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
`;

export const CustomerWrapper = styled.div.attrs((props: {
  isNotCollapsed: boolean
}) => ({
  isNotCollapsed: props.isNotCollapsed,
  'data-class': 'CustomerWrapper',
}))`

  ${({ isNotCollapsed }) => isNotCollapsed && `
    .RFRFormRequest__Row__Containers__content__col_add {
      padding-bottom: 7px;
    }
    .ant-col-18 {
      .RFRFormRequest__Row__Containers__content_title {
        padding-top: 4px;
      }
    }
  `}

  .RFRFormRequest {
    &__Row {
      &__Containers {

        ${responsive.forFullDesktop`
          margin-top: 0px;
        `}

        ${responsive.forFullDesktopMax`
          .ant-collapse-content-box {
            padding: 0;
          }
          .ant-collapse-content-active {
            border-top: none;
          }
          .RFRFormRequest__Row__col_both_col__title_owned {
            .TypeSwitchController__text-left {
              margin-right: 6px;
            }

            .isMedium:nth-child(1) {
              max-width: fit-content;
              margin-left: 0 !important;
            }

            .ant-switch {
              margin-left: 8px;
            }
          }

          .isMedium {
            max-width: fit-content;
            margin-left: auto !important;

            .TypeSwitchController {
              margin-left: auto;
              display: flex;
              justify-content: end;
              align-items: center;

              .Switch {
                margin-right: 0;
              }
            }
          }
        `}

        &__content__col_add {
          background-color: unset;
        }

        &__parent_content {
          &_item {
            &__medium {
              > .Col {
                margin-left: auto !important;
              }

              .ant-col:not(:first-child) {
                margin-left: auto;
              }

              .RFRFormRequest__Row__Containers__content__col_add {
                margin-left: auto !important;
                display: flex;
                align-items: center;
                justify-content: end;

                &_btn {
                  position: relative;
                  width: 24px;
                  height: 24px;
                  min-height: 24px;
                  background-color: white;
                  border-radius: 0;
                }
              }
            }
          }
        }

        .RFRFormRequest__Row__Containers__content__col_add_btn__medium {
          position: relative;
          height: 100%;
          min-height: unset;
          display: flex;
          justify-content: start;
          background: none;
          width: 125px;
          padding: 2px 5px;
          padding-left: 9px;
          border-radius: 3px;

          .addContainer__title {
            font-size: 12px;
            text-transform: capitalize;
            font-weight: 400;
            margin-left: 10px;
            color: #202c3c;
            letter-spacing: normal;
          }

          svg {
            width: 11px;
            height: 11px;
            color: #202c3c;
          }
        }

        &__content {
          &__row {
            &_second {
              margin-top: 12px;
              .ant-col-8 {
                max-width: 32%;
              }
            }
          }
        }
      }

      &__col {
        &__containersPanel {
          .ant-collapse {
            background-color: white;
            border-left: none;
            border-right: none;
            border-top: none;
            .ant-collapse-item {
              border-top: none;
            }
            .ant-collapse-header {
              padding: 16px 0px 16px 0;
            }
          }
        }
      }
    }

    .RFRFormRequest__Row__Containers__content_title {
      svg {
        color: #73819B;
      }
    }
  }

  .addContainer__title {
    font-size: 12px;
    margin-left: 10px;
  }

  .RFRFormRequest__Row__col_both__title {
    .ant-typography {
      color: ${({ theme }) => theme.themesColors.neutralBranding10};
      ${({ theme }) => theme?.fonts?.BodyTextMedium};
    }
  }

  .RFRFormRequest__Row__Containers__content .RFRFormRequest__Row__Containers__content_title .ant-typography {
    color: ${({ theme }) => theme.themesColors.neutralBranding10};
  }

  .RFRFormRequest__Row__Containers__parent_content_item input {
    color: ${({ theme }) => theme.themesColors.neutralBranding10};
  }
`;
