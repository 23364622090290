import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ContainerWithCargoDTM, TemperatureControlDTM } from 'shipment-operations/models/dtm';
import { Bold, Row } from 'shipment-operations/view/components/HBLDocumentPDF/components';

import { AirVentUnitsTypeText } from 'shipment-operations/constants/AirVentUnitsTypeText.const';
import {
  ContainersLoadStyledTableRow,
  TitleWrapper,
  TableHeader,
  Cell,
} from './CargoTables.styled';
import { ContainersLoadItem } from './components';

interface CargoTablesProps {
  containers: ContainerWithCargoDTM[]
  temperatureControl?: TemperatureControlDTM
}

export const CargoTables: FC<CargoTablesProps> = ({ containers, temperatureControl }) => {
  const { t } = useTranslation();

  return (
    <table data-class="CargoTables" style={{ width: '100%' }}>
      <thead>
        <tr>
          <td>
            <TitleWrapper>
              <Bold>{t('Container’s Load List')}</Bold>
            </TitleWrapper>
            {temperatureControl ? (
              <Row>
                <Cell justifyContent="flex-start" flex={4}>
                  <Bold>
                    {t('Temperature controlled (General Settings)')}
                  </Bold>
                </Cell>
                <Cell justifyContent="flex-end">
                  {t('Temperature')}: {temperatureControl.temperature}&deg;C
                </Cell>
                <Cell justifyContent="flex-end">
                  {t('Air')}: {temperatureControl.airVent.flowRate} {temperatureControl?.airVent?.flowRateUnit && AirVentUnitsTypeText()[temperatureControl.airVent.flowRateUnit]}
                </Cell>
                <Cell justifyContent="flex-end">
                  {t('Genset')}: {temperatureControl.genset ? t('Yes') : t('No')}
                </Cell>
              </Row>
            ) : null}
            <TableHeader>
              <Cell justifyContent="flex-start"><Bold>{t('Container')}</Bold></Cell>
              <Cell justifyContent="flex-start"><Bold>{t('No.')}</Bold></Cell>
              <Cell justifyContent="flex-start"><Bold>{t('Seal')}</Bold></Cell>
              <Cell justifyContent="flex-end"><Bold>{t('Pack(s)')}</Bold></Cell>
              <Cell justifyContent="center"><Bold>{t('PCS')}</Bold></Cell>
              <Cell justifyContent="flex-end"><Bold>{t('Gross Weight, kg')}</Bold></Cell>
              <Cell justifyContent="flex-end"><Bold>{t('Volume, cbm')}</Bold></Cell>
            </TableHeader>
          </td>
        </tr>
      </thead>
      <tbody>
        {containers.map((item) => (
          <ContainersLoadStyledTableRow style={{ pageBreakInside: 'avoid' }} key={item.id}>
            <td>
              <ContainersLoadItem item={item} />
            </td>
          </ContainersLoadStyledTableRow>
        ))}
      </tbody>
    </table>
  );
};
