import React, { FC } from 'react';

import { EditableRow } from 'shipment-operations/view/components';
import { IChangesTable } from 'shipment-operations/models/dtm';
import { useTable } from '../CustomTable/useTable';
import {
  EditableTableWrapper,
} from '../CustomTable/CustomTable.styled';
import { EditableCell } from './EditableCell';
import { useTableEditable } from './useTableEdit';

interface IEditableTableProps {
  data: IChangesTable[]
  type: string
  withThreeColumns: boolean
  withMismatches: boolean
  isRequestTheSameWithShipment?: boolean
  onChange: (row: IChangesTable) => void
  isCustomer?: boolean
  mismatchesTable?: boolean
}

const EditableTable: FC<IEditableTableProps> = ({
  data, type, withThreeColumns, withMismatches, isRequestTheSameWithShipment,
  onChange, isCustomer, mismatchesTable,
}) => {
  const { header } = useTable(withThreeColumns, type, withMismatches, isRequestTheSameWithShipment, isCustomer, mismatchesTable);
  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const { dataSource, columns } = useTableEditable(data, header, onChange);
  return (
    <EditableTableWrapper
      pagination={false}
      components={components}
      columns={columns}
      dataSource={dataSource}
    />
  );
};

export { EditableTable };
