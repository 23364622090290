import React, {
  FC,
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import Typography from 'antd/es/typography';
import { SizeType } from 'antd/es/config-provider/SizeContext';
import SolutionOutlined from '@ant-design/icons/SolutionOutlined';
import MailOutlined from '@ant-design/icons/MailOutlined';

import {
  Row,
  Col,
  ExpandIcon,
  Panel,
  Collapse,
} from 'app-wrapper/view/components';
import {
  useWindowSize, useCollapseOnChangeHandler,
} from 'app-wrapper/hooks';
import themesColors from 'app-wrapper/view/themes/themesColors';

import {
  CopyIcon,
  CustomerNameBlock, CustomerNameBlockWrap, CustomerNameContent, CustomerNameImage, CustomerNameText, CustomerNameTitle, CustomerWrapper,
} from './Customer.styled';

export interface ICustomerComponentProps {
  isAllDisabled?: boolean
  formLocalState: { isCollapsed?: boolean }
  forceCollapse?: boolean
  isCollapsed?: boolean
  customerName?: string
  customerEmail?: string
  isCustomerError?: boolean
  isSubmitVisited?: boolean
  isSubmitClicked?: boolean
}

export const CustomerComponent: FC<ICustomerComponentProps> = ((props) => {
  const {
    formLocalState,
    isAllDisabled,
    forceCollapse,
    isCollapsed,
    customerName,
    customerEmail,
    isSubmitVisited,
    isSubmitClicked,
    isCustomerError,
  } = props;

  const { isFullMediaWidth } = useWindowSize();
  const [isResponsive, setIsResponsive] = useState(isFullMediaWidth);
  useEffect(() => {
    if (forceCollapse) {
      setIsResponsive(false);
    } else {
      setIsResponsive(!!formLocalState?.isCollapsed);
    }
  }, [forceCollapse, formLocalState?.isCollapsed]);
  const sizeSelect: SizeType = useMemo(() => (isFullMediaWidth ? 'large' : 'middle'), [isFullMediaWidth]);
  const { t } = useTranslation();

  const headerIsFullComponent = useMemo(() => isResponsive && (
    <>
      <Row className="RFRFormRequest__Row__Containers__content">
        <Col span={10}>
          <div className="RFRFormRequest__Row__Containers__content_title">
            <Typography.Text>{`${''}`}</Typography.Text>
          </div>
        </Col>
        <Col span={1} style={{ minWidth: isFullMediaWidth ? '40px' : '32px' }} />
      </Row>
    </>
  ), [isFullMediaWidth, isResponsive, t]);

  const onCopyEmail = useCallback(() => navigator.clipboard.writeText(customerEmail || ''), [customerEmail]);

  const containerTypeComponent = useCallback(() => (
    <CustomerNameBlockWrap isCollapse={!isCollapsed}>
      <CustomerNameBlock isMarginLeft={false} isWidth={!isCollapsed}>
        <CustomerNameImage>
          <SolutionOutlined />
        </CustomerNameImage>
        <CustomerNameContent>
          <CustomerNameTitle>
            {t('Company Name')}
          </CustomerNameTitle>

          <CustomerNameText>
            {customerName || t('N/A')}
          </CustomerNameText>
        </CustomerNameContent>
      </CustomerNameBlock>

      <CustomerNameBlock isCollapse={!isCollapsed} isWidth={!isCollapsed} isMarginLeft={isCollapsed} isLast>
        <CustomerNameImage>
          <MailOutlined />
        </CustomerNameImage>
        <CustomerNameContent>
          <CustomerNameTitle>
            {t('Email')}
          </CustomerNameTitle>

          <CustomerNameText>
            {customerEmail}
            <CopyIcon onClick={onCopyEmail} />
          </CustomerNameText>
        </CustomerNameContent>
      </CustomerNameBlock>
    </CustomerNameBlockWrap>
  ), [
    isCollapsed,
    customerName,
    customerEmail,
    isAllDisabled,
    isSubmitVisited,
    sizeSelect, t,
  ]);

  const itemCarrierIsFull = useCallback((index) => (
    <Row
      className={`${index === 0 ? 'RFRFormRequest__Row__Containers__parent_content_item_first' : ''} RFRFormRequest__Row__Containers__content RFRFormRequest__Row__Containers__parent_content_item`}
      key={`RFRFormRequest__Row__Containers__parent_content_item_${index}`}
    >
      <Col span={24}>
        {containerTypeComponent()}
      </Col>
    </Row>
  ), [
    containerTypeComponent,
    isAllDisabled,
  ]);

  const itemCarrierIsMedium = useCallback((index) => (
    <Row
      className={`${index === 0 ? 'RFRFormRequest__Row__Containers__parent_content_item_first' : ''} RFRFormRequest__Row__Containers__content RFRFormRequest__Row__Containers__parent_content_item
        RFRFormRequest__Row__Containers__parent_content_item__medium`}
      key={`RFRFormRequest__Row__Containers__parent_content_item_${index}`}
    >
      <Col span={24}>
        <Row>
          <Col span={24}>
            {containerTypeComponent()}
          </Col>
        </Row>
      </Col>
    </Row>
  ), [
    containerTypeComponent,
    isAllDisabled, t,
  ]);

  const CustomerComponentMemo = useMemo(() => (
    <>
      {isResponsive && (
        <Row justify="space-between" className="RFRFormRequest__Row__col_both__title RFRFormRequest__Row__Containers__temperature">
          <div>
            <Typography.Text>{t('Customer')}</Typography.Text>
          </div>
        </Row>
      )}

      {headerIsFullComponent}
      {isResponsive ? itemCarrierIsFull(0) : itemCarrierIsMedium(0)}
    </>
  ), [
    t,
    headerIsFullComponent,
    isResponsive,
    itemCarrierIsFull,
    itemCarrierIsMedium,
  ]);
  const endVisible = useRef<HTMLElement>(null);

  const [
    changeErrorIsCollapseState, setChangeErrorIsCollapseState,
  ] = useState<Array<string>>([]);

  useEffect(() => {
    if (isSubmitVisited && isCustomerError) {
      setChangeErrorIsCollapseState(['1']);
    }
  }, [isSubmitVisited, isCustomerError, isSubmitClicked]);

  const changeErrorIsCollapseCallback = useCallback(
    () => {
      setChangeErrorIsCollapseState((prev) => (prev.length ? [] : ['1']));
    },
    [],
  );

  const collapseOnChangeHandler = useCollapseOnChangeHandler(
    endVisible.current, changeErrorIsCollapseCallback,
  );

  const collapseComponent = useMemo(() => (
    <Collapse
      expandIcon={({ isActive }) => ExpandIcon({
        isActive,
        color: `${changeErrorIsCollapseState.length ? themesColors.primaryBranding6 : themesColors.neutralBranding7}`,
      })}
      onChange={collapseOnChangeHandler}
      activeKey={changeErrorIsCollapseState}
    >
      <Panel
        header={t('Customer')}
        key="1"
      >
        {CustomerComponentMemo}
      </Panel>
    </Collapse>
  ), [changeErrorIsCollapseState, collapseOnChangeHandler, CustomerComponentMemo, t]);

  return (
    <CustomerWrapper isNotCollapsed={!isCollapsed}>
      <Row className="RFRFormRequest__Row__col_both RFRFormRequest__Row__Containers RFRFormRequest__Row__col__containersPanel">
        <Col span={24}>
          {!isResponsive ? (
            collapseComponent
          ) : (
            CustomerComponentMemo
          )}
          <span
            id="RFRFormRequest__Row__col__containerssPanel__ref_endVisible"
            ref={endVisible}
          />
        </Col>
      </Row>
    </CustomerWrapper>
  );
});
