import React, { memo } from 'react';
import { useSelector } from 'react-redux';

import { R } from 'user-management/repository';
import { UC } from 'user-management/controllers';

import { CustomerAccountingComponent } from './CustomerAccounting.component';

const CustomerAccountingContainer = () => {
  const isLoading = useSelector(R.selectors.customer.getLoading);

  return (
    <CustomerAccountingComponent
      isLoading={isLoading}
      initData={UC.customer.initData}
    />
  );
};

const CustomerAccountingContainerCached = memo(CustomerAccountingContainer);

export { CustomerAccountingContainerCached as CustomerAccountingPage };
