import { createSlice } from 'app-wrapper/createSlice';

import { IShipmentFreeTimeState } from 'shipment-operations/models/states';

const initialState: IShipmentFreeTimeState = {
  isLoading: false,
  error: false,
  data: [],
};

export const ShipmentFreeTimeSlice = createSlice({
  name: 'shipmentFreeTime',
  initialState,
  reducers: {
    setIsLoading: (state, { payload }) => ({
      ...state,
      isLoading: payload,
    }),
    setError: (state, { payload }) => ({
      ...state,
      isLoading: false,
      error: payload,
    }),
    setData: (state, { payload }) => ({
      ...state,
      isLoading: false,
      error: false,
      data: payload,
    }),
  },
});

export const shipmentFreeTimeReducer = ShipmentFreeTimeSlice.reducer;
export const shipmentFreeTimeActions = ShipmentFreeTimeSlice.actions;
