import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'antd/es/skeleton';

import { CargoDTM } from 'shipment-operations/models/dtm';

import {
  Commodity,
  CommodityHeader,
  CommodityRowsHeader,
  CommodityRowsHeaderTitle,
  CommodityRowsHeaderNoHazmat,
} from './Commodity.styled';
import { CommodityRow } from './components';

interface CommodityComponentProps {
  cargos: CargoDTM[]
  hasAnyHazmats: boolean
  isPending: boolean
}

export const CommodityComponent: FC<CommodityComponentProps> = ({
  cargos,
  hasAnyHazmats,
  isPending,
}) => {
  const { t } = useTranslation();

  const headerContent = useMemo(() => (
    <>
      <CommodityRowsHeaderTitle>
        {t('H.S. or Commodity Name *')}
      </CommodityRowsHeaderTitle>

      <CommodityRowsHeaderTitle>
        {t('Description')}
      </CommodityRowsHeaderTitle>

      <CommodityRowsHeaderTitle>
        {t('Pack *')}
      </CommodityRowsHeaderTitle>

      <CommodityRowsHeaderTitle>
        {t('QTY *')}
      </CommodityRowsHeaderTitle>

      <CommodityRowsHeaderTitle>
        {t('Weight kg *')}
      </CommodityRowsHeaderTitle>

      <CommodityRowsHeaderTitle>
        {t('Volume cbm')}
      </CommodityRowsHeaderTitle>
    </>
  ), [t]);

  return isPending ? (
    <Skeleton />
  ) : (
    <Commodity>
      <CommodityHeader>
        {t('Commodity')}
      </CommodityHeader>

      {hasAnyHazmats ? (
        <CommodityRowsHeader>
          {headerContent}
        </CommodityRowsHeader>
      ) : (
        <CommodityRowsHeaderNoHazmat>
          {headerContent}
        </CommodityRowsHeaderNoHazmat>
      )}

      {cargos.map(((cargo) => (
        <CommodityRow
          key={String(cargo.id)}
          cargoId={cargo.id ? cargo.id : 0}
        />
      )))}
    </Commodity>
  );
};
