import React from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';

import { DashboardComponent } from 'shipment-operations/view/components';

const DashboardContainer = () => {
  const loading = useSelector(R.selectors.shipmentTransportationCharges.getIsLoading);
  const totalProfit = useSelector(R.selectors.shipmentTransportationCharges.getTotalProfit);
  const totalProfitPercent = useSelector(R.selectors.shipmentTransportationCharges.getTotalProfitPercent);
  const totalCost = useSelector(R.selectors.shipmentTransportationCharges.getTotalCost);
  const buyTotalCost = useSelector(R.selectors.shipmentTransportationCharges.getBuyTotalCost);
  const arBudgetTotalCost = useSelector(R.selectors.shipmentTransportationCharges.getArBudgetTotalCost);
  const apBudgetTotalCost = useSelector(R.selectors.shipmentTransportationCharges.getApBudgetTotalCost);
  const receivablesDifferent = useSelector(R.selectors.shipmentTransportationCharges.getReceivablesDifferent);
  const payablesDifferent = useSelector(R.selectors.shipmentTransportationCharges.getPayablesDifferent);
  const receivablesDifferentPercent = useSelector(R.selectors.shipmentTransportationCharges.getReceivablesDifferentPercent);
  const payablesDifferentPercent = useSelector(R.selectors.shipmentTransportationCharges.getPayablesDifferentPercent);

  return (
    <DashboardComponent
      loading={loading}
      totalProfit={totalProfit}
      totalProfitPercent={totalProfitPercent}
      totalCost={totalCost}
      buyTotalCost={buyTotalCost}
      arBudgetTotalCost={arBudgetTotalCost}
      apBudgetTotalCost={apBudgetTotalCost}
      receivablesDifferent={receivablesDifferent}
      receivablesDifferentPercent={receivablesDifferentPercent}
      payablesDifferent={payablesDifferent}
      payablesDifferentPercent={payablesDifferentPercent}
    />
  );
};

export { DashboardContainer as Dashboard };
