export const DeferredPaymentValues = [
  7,
  14,
  21,
  30,
  45,
  60,
  75,
  90,
  120,
];
