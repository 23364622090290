import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Spin from 'antd/es/spin';

import { OrganizationMemberDTM } from 'user-management/models/dtm';
import { Option, Drawer, Select } from 'app-wrapper/view/components';
import { DEPARTMENT_TYPE_NAMES, EAccountDepartmentType } from 'user-management/constants';
import { RoleSelect } from 'user-management/view/components/CreateOrEditTeamDrawer/components/RoleSelect';
import {
  Content,
  FieldLabel,
  FieldWrap,
  SpinnerWrap,
} from 'user-management/view/components/CreateOrEditTeamDrawer/CreateOrEditTeamDrawer.styled';

import { DrawerFooter } from './components';
import { Fields } from './AddWorkgroupMember.styled';

const DRAWER_WIDTH = 400;

interface IAddOrChangeTeamDrawerComponentProps {
  isVisible: boolean;
  onClose: () => void;
  isLoading: boolean;
  isCustomerView: boolean;
  members: OrganizationMemberDTM[];
  tempMember?: string;
  setTempMember: (email: string) => void;
  rolesToEmailsState: Record<EAccountDepartmentType, string | undefined>;
  setEmailByRole: (role: EAccountDepartmentType, email: string) => void;
  rolesToEdit: EAccountDepartmentType[];
}

export const AddWorkgroupMemberComponent: FC<IAddOrChangeTeamDrawerComponentProps> = ({
  isVisible,
  onClose,
  isLoading,
  members,
  tempMember,
  setTempMember,
  isCustomerView,
  rolesToEdit,
  setEmailByRole,
  rolesToEmailsState,
}) => {
  const { t } = useTranslation();

  return (
    <Drawer
      visible={isVisible}
      onClose={onClose}
      maskClosable={false}
      width={DRAWER_WIDTH}
      push={false}
      title={isCustomerView ? t('Add Member') : t('Edit Workgroup')}
    >
      <Content>
        {isLoading ? (
          <SpinnerWrap>
            <Spin size="large" />
          </SpinnerWrap>
        ) : (
          <>
            <Fields>
              <FieldWrap>
                {isCustomerView ? (
                  <FieldLabel>
                    {t('Select Member')}
                  </FieldLabel>
                ) : null}

                {isCustomerView ? (
                  <Select
                    data-class="add-member-select"
                    placeholder={t('Choose Member')}
                    allowClear
                    value={tempMember}
                    onChange={setTempMember}
                  >
                    {members.map((member) => (
                      <Option
                        data-class="team-member-option"
                        value={member.email}
                        key={member.email}
                      >
                        {member.getFullName()}
                      </Option>
                    ))}
                  </Select>
                ) : (
                  <>
                    {rolesToEdit.map((role) => (
                      <RoleSelect
                        key={role}
                        email={rolesToEmailsState[role]}
                        role={role}
                        label={DEPARTMENT_TYPE_NAMES[role]}
                        setRoleEmail={setEmailByRole}
                        members={members}
                        disableClear
                      />
                    ))}
                  </>
                )}
              </FieldWrap>
            </Fields>

            <DrawerFooter />
          </>
        )}
      </Content>
    </Drawer>
  );
};
