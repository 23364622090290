import React from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';

import { ShipmentOverviewContainersComponent } from './ShipmentOverviewContainers.component';

const ShipmentOverviewContainersContainer = () => {
  const containersSumWithType = useSelector(R.selectors.overview.getContainersSumWithType);
  const isLoading = useSelector(R.selectors.overview.getLoadingStatus);

  return (
    <ShipmentOverviewContainersComponent containersSumWithType={containersSumWithType} isLoading={isLoading} />
  );
};

export { ShipmentOverviewContainersContainer as ShipmentOverviewContainers };
