import React from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';

import { PDFCargoAndContainersComponent } from './PDFCargoAndContainers.component';

const PDFCargoAndContainersContainer = () => {
  const cargos = useSelector(R.selectors.bookingConfirmationPDF.getCargos);
  const containersItems = useSelector(R.selectors.bookingConfirmationPDF.getContainersItems);

  return (
    <PDFCargoAndContainersComponent
      cargos={cargos}
      containersItems={containersItems}
    />
  );
};

export { PDFCargoAndContainersContainer as PDFCargoAndContainers };
