import { createSelector } from 'reselect';

import { RootState } from 'app-wrapper/store';

const localState = (state: RootState) => state.accountDrawer;

const getContentType = createSelector(
  localState,
  (state) => state.content,
);

const getLoadingState = createSelector(
  localState,
  (state) => state.isLoading,
);

const getVisibleState = createSelector(
  localState,
  (state) => state.isVisible,
);

const getDynamicDrawerTitle = createSelector(
  localState,
  (state) => state.dynamicDrawerTitle,
);

const getType = createSelector(
  localState,
  (state) => state.type,
);

export const accountDrawerSelectors = {
  getContentType,
  getLoadingState,
  getVisibleState,
  getDynamicDrawerTitle,
  getType,
};
