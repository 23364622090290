import i18n from 'app-wrapper/i18n/i18n';
import { Type } from 'class-transformer';
import {
  IsEnum,
  IsNumber, IsOptional, IsString, ValidateNested,
} from 'class-validator';
import { BaseDTM } from 'proto/BaseDTM';

import { EAccountDepartmentType } from 'user-management/constants';
import { OrganizationMemberDTM, TOrganizationMemberDTM } from '../OrganizationMember.dtm';

export interface IAccountDepartmentDTM {
  id?: number
  customId?: string
  type: EAccountDepartmentType
  email: string
  phone: string
  createdBy?: string
  createdAt?: string
  members?: TOrganizationMemberDTM[]
  membersCount?: number
}

export class AccountDepartmentDTM extends BaseDTM<IAccountDepartmentDTM> {
  @IsOptional()
  @IsNumber()
  id?: number

  @IsOptional()
  @IsString()
  customId?: string

  @IsEnum(EAccountDepartmentType)
  type: EAccountDepartmentType

  @IsString()
  email: string

  @IsString()
  phone: string

  @IsOptional()
  @IsString()
  createdBy?: string

  @IsOptional()
  @IsString()
  createdAt?: string

  @IsOptional()
  @ValidateNested({ each: true })
  @Type(() => OrganizationMemberDTM)
  members?: OrganizationMemberDTM[]

  @IsOptional()
  @IsNumber()
  membersCount?: number

  getTypeName = () => {
    switch (this.type) {
      case EAccountDepartmentType.ACCOUNTING_PAYABLES:
        return i18n.t('Accounting (Payables)');
      case EAccountDepartmentType.ACCOUNTING_RECEIVABLES:
        return i18n.t('Accounting (Receivables)');
      case EAccountDepartmentType.ACCOUNT_MANAGEMENT:
        return i18n.t('Account Management');
      case EAccountDepartmentType.BOOKING_OPS:
        return i18n.t('Booking Ops');
      case EAccountDepartmentType.CUSTOMER_ONBOARDING:
        return i18n.t('Customer Onboarding');
      case EAccountDepartmentType.DOCUMENTATION_OPS_EXPORT:
        return i18n.t('Documentation Ops (Export)');
      case EAccountDepartmentType.DOCUMENTATION_OPS_IMPORT:
        return i18n.t('Documentation Ops (Import)');
      case EAccountDepartmentType.DRAYAGE_EXPORT:
        return i18n.t('Drayage (Export)');
      case EAccountDepartmentType.DRAYAGE_IMPORT:
        return i18n.t('Drayage (Import)');
      case EAccountDepartmentType.PRICING:
        return i18n.t('Pricing');
      case EAccountDepartmentType.RATE_MANAGEMENT:
        return i18n.t('Rate Management');
      case EAccountDepartmentType.SALES:
        return i18n.t('Sales');

      default:
        return '';
    }
  }
}
