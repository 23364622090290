import React, { FC, useMemo, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';

import { CargoDTM } from 'shipment-operations/models/dtm';
import { RouteNames } from 'app-wrapper/constants';
import { SBLGoodsTableComponent } from 'shipment-operations/view/pages/ShipmentBillOfLading/components/ShipmentInstructionsContent/components/SBLCargoTables/components/SBLGoodsTable';

import { SkeletonSection } from 'app-wrapper/view/components/Skeleton/Skeleton.component';
import { ShipmentOverviewCargosWrap } from './ShipmentOverviewCargos.styled';
import { ShipmentOverviewMoreDetails } from '../index';

interface ShipmentOverviewCargosComponentProps {
  cargos: CargoDTM[];
  isLoading: boolean;
}

export const ShipmentOverviewCargosComponent: FC<ShipmentOverviewCargosComponentProps> = ({ cargos, isLoading }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { shipmentId = '' } = useParams();

  const cargosRedirectURL = useMemo(() => RouteNames.SHIPMENT_CARGO(shipmentId), [shipmentId]);

  const goToCargos = useCallback(() => {
    navigate(cargosRedirectURL);
  }, [cargosRedirectURL, navigate]);

  if (isLoading) {
    return <SkeletonSection height="221px" />;
  }

  return (
    <ShipmentOverviewCargosWrap>
      <SBLGoodsTableComponent
        title={t('Cargo')}
        redirectURL={cargosRedirectURL}
        cargos={cargos}
        footer={<ShipmentOverviewMoreDetails onClick={goToCargos} />}
        isNested
        showHazmatTag
      />
    </ShipmentOverviewCargosWrap>
  );
};
