import React from 'react';

import { Tooltip } from 'app-wrapper/view/components';
import { ReferenceDTM } from 'app-wrapper/types/Reference';

import { WhiteTag, Tag, TagContainer } from './styled';

export interface IReferenceTagsProps {
  references?: ReferenceDTM[],
  maxElements?: number,
  referenceTypeNamesMap: {
    [key: string]: string
  },
}

export const ReferenceTags: React.FC<IReferenceTagsProps> = ({
  referenceTypeNamesMap,
  references = [],
  maxElements = 3,
}) => {
  const referencesFiltered = references.filter((ref) => ref.value && ref.type);

  const fullyDisplayedReferences = referencesFiltered.slice(0, maxElements);
  const restOfReferences = referencesFiltered.slice(maxElements);

  return (
    <TagContainer>
      {fullyDisplayedReferences.map((ref) => (
        <Tag key={ref.id}>
          {referenceTypeNamesMap[ref.type]}{ref.value}
        </Tag>
      ))}
      {restOfReferences.length > 0 ? (
        <Tooltip title={restOfReferences.map((ref) => `${referenceTypeNamesMap[ref.type]}${ref.value}`).join(', ')}>
          <WhiteTag key="rest">+{restOfReferences.length}</WhiteTag>
        </Tooltip>
      ) : null}
    </TagContainer>
  );
};
