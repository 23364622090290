import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import map from 'lodash/fp/map';
import filter from 'lodash/fp/filter';
import Dropdown from 'antd/es/dropdown';
import Menu from 'antd/es/menu';
import DownOutlined from '@ant-design/icons/DownOutlined';
import UpOutlined from '@ant-design/icons/UpOutlined';

import { useAppSelector } from 'app-wrapper/hooks';
import { Button } from 'app-wrapper/view/components/Button';
import { getAllShipmentRoles } from 'app-wrapper/data/shipmentsParties/shipmentsPartySlice';
import ShipmentPartyRole from 'app-wrapper/types/ShipmentPartyRole';

// TODO: move to common components
interface MenuInfo {
  key: string;
  keyPath: string[];
  domEvent: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>;
}
interface ICreateNewItemProps {
  addedRoles: string[],
  onClick(value: string): void
}

// TODO: separate view and logic

export const CreateNewItem: FC<ICreateNewItemProps> = ({ addedRoles, onClick }) => {
  const { t } = useTranslation();
  const [menuVisible, setMenuVisible] = useState(false);
  const roles = useAppSelector(getAllShipmentRoles) || [];
  const uniqueRoles = filter(
    (item: ShipmentPartyRole) => addedRoles.indexOf(item.name) === -1,
    roles,
  );
  const menu = () => (
    <Menu
      onClick={(info: MenuInfo) => {
        onClick(info.key);
        setMenuVisible(false);
      }}
    >
      {map((role: ShipmentPartyRole) => (
        <Menu.Item key={role.name}>{role.title}</Menu.Item>
      ), uniqueRoles)}
    </Menu>
  );
  return (
    <Dropdown
      arrow
      trigger={['click']}
      overlay={menu}
      onVisibleChange={setMenuVisible}
    >
      <Button>
        {menuVisible ? <UpOutlined /> : <DownOutlined />}
        {t('Add Role')}
      </Button>
    </Dropdown>
  );
};
