import React from 'react';
import { useSelector } from 'react-redux';

import { R } from 'app-wrapper/repository';
import { UC } from 'app-wrapper/controllers';

import { TrackerComponent } from './Tracker.component';

const TrackerContainer = () => {
  const trackerValue = useSelector(R.selectors.overview.getTrackerValue);
  const options = useSelector(R.selectors.overview.getTrackerOptions);
  const shipmentOptions = useSelector(R.selectors.overview.getTrackerShipmentOptions);

  return (
    <TrackerComponent
      value={trackerValue}
      setValue={UC.overview.setTrackerValue}
      onSearch={UC.overview.debouncedSearch}
      options={options}
      shipmentOptions={shipmentOptions}
    />
  );
};

export { TrackerContainer as Tracker };
