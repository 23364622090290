import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { UC } from 'shipment-operations/controllers';
import { R } from 'shipment-operations/repository';

import { FooterComponent } from './Footer.component';

export const Footer = () => {
  const { shipmentId = '0' } = useParams();
  const document = useSelector(R.selectors.shipmentDocumentsAll.getAdditionalDocument);
  const documentType = useSelector(R.selectors.shipmentDocumentsAll.getAdditionalType);

  const isDisabled = useMemo(() => !document || !documentType, [document, documentType]);

  return (
    <FooterComponent
      onCancel={UC.shipmentDrawer.closeDrawer}
      onSubmit={UC.shipmentDocumentsAll.uploadAdditionalDocument}
      shipmentId={shipmentId}
      disabled={isDisabled}
    />
  );
};
