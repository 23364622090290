import * as yup from 'yup';
import i18n from 'i18next';

yup.addMethod(yup.string, 'minLength', function minLength(min) {
  return this.test(
    'minLength',
    `${i18n.t('Validations.string.minLength')} ${min}`,
    (value) => !!value?.length && value.length >= min,
  );
});

declare module 'yup' {
  interface StringSchema {
    minLength(min?: number): StringSchema;
  }
}
