import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { R as userManagementR } from 'user-management/repository';
import { R } from 'shipment-operations/repository';
import { UC } from 'shipment-operations/controllers';
import { EOrganizationMemberRole } from 'user-management/constants';

import { SubmittedContentComponent } from './SubmittedContent.component';

export const SubmittedContent = React.memo(() => {
  const { status, mblDraftId } = useSelector(R.selectors.billOfLadingCommon.getSIDetails);
  const isLoading = useSelector(R.selectors.billOfLadingCommon.getIsFlowStepLoading);
  const organization = useSelector(userManagementR.selectors.userOrganizationData.getUserOrganization);
  const isOriginPartner = useSelector(R.selectors.shipment.getIsOriginPartner);
  const isDestinationPartner = useSelector(R.selectors.shipment.getIsDestinationPartner);

  const isCustomerOrg = useMemo(() => !!(organization && organization.role === EOrganizationMemberRole.CUSTOMER), [organization]);

  return (
    <SubmittedContentComponent
      siStatus={status}
      isLoading={isLoading}
      isCustomer={isCustomerOrg}
      isOriginPartner={isOriginPartner}
      isDestinationPartner={isDestinationPartner}
      approveDraftMBL={UC.billOfLading.approveDraftMBL}
      openReviewAndApproveEMBLDrawer={UC.billOfLading.openReviewAndApproveEMBLDrawer}
      hasMBLDraft={!!mblDraftId}
    />
  );
});
