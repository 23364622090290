import styled from 'styled-components';

import { divFlex } from 'app-wrapper/view/themes/mixin';

export const ServicesDivider = styled.div.attrs((props: {
  noMargin: boolean
  }) => ({
  'data-class': 'ServicesDivider',
  noMargin: props.noMargin,
}))`
  ${divFlex}
  width: 100%;

  margin: ${({ noMargin }) => (noMargin ? '0' : '16px 0')};
  border: 1px dashed ${({ theme }) => theme.themesColors.neutralBranding4};
`;
