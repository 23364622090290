import styled from 'styled-components';
import Typography from 'antd/es/typography';

import { colors } from 'app-wrapper/view/themes/themesColors';

interface ICargoTitleTextProps {
  gray: number,
}

export const CargoTitleText = styled(Typography.Title)<ICargoTitleTextProps>`
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;

  height: 42px;
  ${({ gray }) => !!gray && `color: ${colors.gray43} !important`};
  font-size: 16px !important;
  margin-bottom: 0 !important;
  overflow: hidden;
`;
