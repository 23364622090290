import React from 'react';

import { R } from 'shipment-operations/repository';
import { useAppSelector } from 'app-wrapper/hooks';

import { FeesComponent } from './Fees.component';

const FeesContainer = () => {
  const data = useAppSelector(R.selectors.shipmentTransportationCharges.getFeesDataForTable);
  const totalData = useAppSelector(R.selectors.shipmentTransportationCharges.getFeesDataTotal);

  return (
    <FeesComponent
      data={data}
      totalData={totalData}
    />
  );
};

export { FeesContainer as Fees };
