import React from 'react';

import { IPropsIcon } from 'app-wrapper/interfaces/icon';
import themesColors from 'app-wrapper/view/themes/themesColors';

export const PlusSvg = (props: IPropsIcon) => {
  const { width, height, style } = props;

  return (
    <svg
      className="PlusSvg"
      width={width || '10'}
      height={height || '10'}
      viewBox="0 0 10 10"
      color={style?.color || themesColors.neutralBranding9}
      style={style || undefined}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M5.40168 0.179199H4.59811C4.52668 0.179199 4.49097 0.214914 4.49097 0.286342V4.4917H0.500209C0.428781 4.4917 0.393066 4.52741 0.393066 4.59884V5.40241C0.393066 5.47384 0.428781 5.50956 0.500209 5.50956H4.49097V9.71491C4.49097 9.78634 4.52668 9.82206 4.59811 9.82206H5.40168C5.47311 9.82206 5.50882 9.78634 5.50882 9.71491V5.50956H9.50021C9.57164 5.50956 9.60735 5.47384 9.60735 5.40241V4.59884C9.60735 4.52741 9.57164 4.4917 9.50021 4.4917H5.50882V0.286342C5.50882 0.214914 5.47311 0.179199 5.40168 0.179199Z" fill="currentColor" />
    </svg>
  );
};
