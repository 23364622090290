import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Option } from 'app-wrapper/view/components';

import { OrganizationDTM, OrganizationFieldsErrorsDTM, OrganizationFieldsErrorsFieldDTM } from 'user-management/models/dtm';
import {
  EOrganizationIndustry, EOrganizationType, OrganizationIndustryNames, OrganizationTypeNames,
} from 'user-management/constants';

import {
  EditProfileOrganizationGeneralInformationName,
  EditProfileOrganizationGeneralInformationWrapper,
  EditProfileOrganizationGeneralInformationHeader,
  EditProfileOrganizationGeneralInformationInput,
  EditProfileOrganizationGeneralInformationSelect,
  EditProfileOrganizationGeneralInformationSpaceColumn,
  EditProfileOrganizationGeneralInformationSpaceRow,
  EditProfileOrganizationGeneralInformationTitle,
} from './EditProfileOrganizationGeneralInformation.styled';

interface IEditProfileOrganizationAddressGeneralInformationComponentProps {
  organization?: OrganizationDTM
  organizationIndustry?: EOrganizationIndustry[]
  organizationFieldsErrors?: OrganizationFieldsErrorsDTM
  isIndustryDisabled?: boolean
  onChangeUpdateName: (name: string) => void
  onBlurUpdateName: () => void
  onChangeUpdateType: (type: EOrganizationType) => void
  onBlurUpdateType: () => void
  onChangeUpdateIndustry: (value: string) => void
}

export const EditProfileOrganizationGeneralInformationComponent: FC<IEditProfileOrganizationAddressGeneralInformationComponentProps> = ({
  onBlurUpdateName,
  onBlurUpdateType,
  onChangeUpdateName,
  onChangeUpdateType,
  onChangeUpdateIndustry,
  organization,
  organizationIndustry,
  organizationFieldsErrors,
  isIndustryDisabled,
}) => {
  const { t } = useTranslation();

  const onChangeName = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    onChangeUpdateName(event.target.value);
  }, []);

  const onBlurUpdateNameHandler = useCallback(() => {
    onBlurUpdateName();
  }, []);

  const onChangeTypeHandler = useCallback((value: EOrganizationType) => {
    onChangeUpdateType(value);
  }, []);

  const onBlurTypeHandler = useCallback(() => {
    onBlurUpdateType();
  }, []);

  const getHasErrorField = useCallback(
    (filed?: OrganizationFieldsErrorsFieldDTM) => !!(filed?.isVisited && filed?.message),
    [],
  );

  return (
    <EditProfileOrganizationGeneralInformationWrapper>
      <EditProfileOrganizationGeneralInformationHeader>
        {t('General Information')}
      </EditProfileOrganizationGeneralInformationHeader>
      <EditProfileOrganizationGeneralInformationName>
        <EditProfileOrganizationGeneralInformationTitle>
          {`${t('OrganizationsName')} *`}
        </EditProfileOrganizationGeneralInformationTitle>
        <EditProfileOrganizationGeneralInformationInput
          value={organization?.name}
          hasError={getHasErrorField(organizationFieldsErrors?.name)}
          onChange={onChangeName}
          onBlur={onBlurUpdateNameHandler}
        />
      </EditProfileOrganizationGeneralInformationName>
      <EditProfileOrganizationGeneralInformationSpaceRow>
        <EditProfileOrganizationGeneralInformationSpaceColumn widthProp="50%">
          <EditProfileOrganizationGeneralInformationTitle>
            {`${t(' Type')}`} *
          </EditProfileOrganizationGeneralInformationTitle>
          <EditProfileOrganizationGeneralInformationSelect
            value={organization?.type}
            showSearch
            error={getHasErrorField(organizationFieldsErrors?.type)}
            onChange={onChangeTypeHandler}
            onBlur={onBlurTypeHandler}
            optionFilterProp="children"
            hasError={getHasErrorField(organizationFieldsErrors?.type)}
          >
            <Option value={EOrganizationType.BCO}>{OrganizationTypeNames[EOrganizationType.BCO]}</Option>
            <Option value={EOrganizationType.NVOCC}>{OrganizationTypeNames[EOrganizationType.NVOCC]}</Option>
            <Option value={EOrganizationType.FF}>{OrganizationTypeNames[EOrganizationType.FF]}</Option>
          </EditProfileOrganizationGeneralInformationSelect>
        </EditProfileOrganizationGeneralInformationSpaceColumn>

        <EditProfileOrganizationGeneralInformationSpaceColumn widthProp="50%">
          <EditProfileOrganizationGeneralInformationTitle>
            {`${t(' Industry')}`}
          </EditProfileOrganizationGeneralInformationTitle>
          <EditProfileOrganizationGeneralInformationSelect
            value={organization?.industry || null}
            showSearch
            onChange={onChangeUpdateIndustry}
            onBlur={() => {}}
            optionFilterProp="children"
            disabled={!organization?.type || isIndustryDisabled}
            hasError={getHasErrorField(organizationFieldsErrors?.industry)}
          >
            {organizationIndustry?.map((item) => (
              <Option
                key={`EditProfileOrganizationGeneralInformationSelect_second_${item}`}
                value={item}
              >{OrganizationIndustryNames[item]}
              </Option>
            ))}
          </EditProfileOrganizationGeneralInformationSelect>
        </EditProfileOrganizationGeneralInformationSpaceColumn>
      </EditProfileOrganizationGeneralInformationSpaceRow>
    </EditProfileOrganizationGeneralInformationWrapper>
  );
};
