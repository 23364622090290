import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';
import { UC } from 'shipment-operations/controllers';

import { ShipmentOverviewAlertBarComponent } from './ShipmentOverviewAlertBar.component';

const ShipmentOverviewAlertBarContainer: FC = () => {
  const error = useSelector(R.selectors.overview.getServerError);
  const {
    wasBookingRequestSuccessful,
    wasShipmentInstructionRequestSuccessful,
  } = useSelector(R.selectors.overview.getRequestsResult);
  const wasManualBookingConfirmationSuccessful = useSelector(R.selectors.overview.getWasManualBookingConfirmationSuccessful);

  return (
    <ShipmentOverviewAlertBarComponent
      wasManualBookingConfirmationSuccessful={wasManualBookingConfirmationSuccessful}
      wasBookShipmentRequestSuccessful={wasBookingRequestSuccessful}
      wasSubmitShipmentInstructionRequestSuccessful={wasShipmentInstructionRequestSuccessful}
      error={error}
      reset={UC.overview.closeAlert}
    />
  );
};

export {
  ShipmentOverviewAlertBarContainer as ShipmentOverviewAlertBar,
};
