import React from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';
import { R as userManagementR } from 'user-management/repository';
import { UC } from 'shipment-operations/controllers';

import { CancelShipmentModalComponent } from './CancelShipmentModal.component';

const CancelShipmentModalContainer = () => {
  const organizationRole = useSelector(userManagementR.selectors.userOrganizationData.getUserOrganisationRole);
  const isOpened = useSelector(R.selectors.overview.getIsCancelShipmentModalOpened);
  const cancellationType = useSelector(R.selectors.overview.getShipmentCancellationType);
  const cancellationMessage = useSelector(R.selectors.overview.getShipmentCancellationMessage);
  const isCancelShipmentPending = useSelector(R.selectors.overview.getIsCancelShipmentPending);

  return (
    <CancelShipmentModalComponent
      organizationRole={organizationRole}
      isCancelShipmentPending={isCancelShipmentPending}
      cancellationMessage={cancellationMessage}
      cancellationType={cancellationType}
      setCancellationType={UC.overview.setShipmentCancellationType}
      setCancellationMessage={UC.overview.setShipmentCancellationMessage}
      isOpen={isOpened}
      onClose={UC.overview.closeCancelShipmentModal}
      onOk={UC.overview.cancelShipment}
    />
  );
};

export { CancelShipmentModalContainer as CancelShipmentModal };
