import Layout from 'antd/es/layout';
import styled from 'styled-components';

import { CustomScrollbar } from 'app-wrapper/view/styles/mixins';

export const ShipmentDetailsFormWrapper = styled.div.attrs({
  'data-class': 'ShipmentDetailsFormWrapper',
})`
  position: relative;
  box-sizing: border-box;
  height: 100%;
  padding: 0;

  border: 1px solid #EAEEF8;
  border-radius: 3px;

  background: white;
  color: black;
  overflow-y: auto;
`;

export const ShipmentDetailsFormScroll = styled.div.attrs({
  'data-class': 'ShipmentDetailsFormScroll',
})`
  height: calc(100% - 64px);
  overflow-y: auto;
  ${CustomScrollbar}
`;

export const ShipmentDetailsFormContainer = styled.form.attrs({
  'data-class': 'ShipmentDetailsFormContainer',
})`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  box-sizing: border-box;
`;

export const ShipmentDetailsFormFooter = styled(Layout.Footer).attrs({
  'data-class': 'ShipmentDetailsFormFooter',
})`
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
  height: 64px;
  width: 100%;
  padding: 0px 24px;

  z-index: 2;
  color: inherit;
  background-color: white;
  box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px rgba(0, 0, 0, 0.08), 0px 9px 28px 8px rgba(0, 0, 0, 0.05);
`;
