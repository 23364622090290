import {
  ACTUAL, IN_PROGRESS, NOT_ACHIEVED, PASSED, EXPECTED,
} from 'shipment-operations/constants';
import { UpdatedDateDTM } from 'shipment-operations/models/dtm';

export const getEventStatus = (eventIndex: number, targetIndex: number) => {
  if (eventIndex === targetIndex) {
    return IN_PROGRESS;
  }
  if (eventIndex < targetIndex) {
    return PASSED;
  }
  return NOT_ACHIEVED;
};

export const getSyncedActualDate = (updatedDates: UpdatedDateDTM[], id?: string) => updatedDates.find((elem) => (elem.id === id) && elem.type === ACTUAL)?.time;
export const getSyncedEstimatedDate = (updatedDates: UpdatedDateDTM[], id?: string) => updatedDates.find((elem) => (elem.id === id) && elem.type === EXPECTED)?.time;
