import React from 'react';
import { useSelector } from 'react-redux';

import { UC } from 'user-management/controllers';
import { R } from 'user-management/repository';

import { PartnerComponent } from './Partner.component';

const PartnerContainer = () => {
  const isLoading = useSelector(R.selectors.customer.getLoading);
  const organization = useSelector(R.selectors.customer.getOrganization);
  const isAdmin = useSelector(R.selectors.customer.getIsOrganizationAdmin);
  const hasRequestedAdditionalInformation = useSelector(R.selectors.customer.getHasRequestedAdditionalInfo);
  const reviewNote = useSelector(R.selectors.customer.getReviewNote);
  const reviewAction = useSelector(R.selectors.customer.getReviewAction);

  return (
    <PartnerComponent
      isLoading={isLoading}
      isAdmin={isAdmin}
      organizationStatus={organization?.status}
      initData={UC.customer.initData}
      onApprove={UC.customer.approveOrganization}
      onReject={UC.customer.openRejectModal}
      onRequestAdditionalInfo={UC.customer.openAdditionalInformationModal}
      hasRequestedAdditionalInfo={hasRequestedAdditionalInformation}
      reviewNote={reviewNote}
      reviewAction={reviewAction}
    />
  );
};

export { PartnerContainer as Partner };
