import { createSlice } from 'app-wrapper/createSlice';
import reduce from 'lodash/fp/reduce';

import Company from 'app-wrapper/types/Company';
import CompanyContact from 'app-wrapper/types/CompanyContact';
import Role from 'app-wrapper/enums/Roles';

import { RootState } from 'app-wrapper/store';
import Address from 'app-wrapper/types/Address';

import {
  searchCompanies, getCompanyContacts, createCompany, getCompanyAddress, createCompanyAddress,
} from './actions';

interface CompanyFormState {
  status: string
  contactsStatus: string
  companies: Company[]
  companiesById: Record<number, Company>
  contacts: CompanyContact[]
  addresses: Address[]
}
export interface ICompanyState {
  currentRole: Role,
  [Role.CONSIGNEE]: CompanyFormState,
  [Role.EXPORT_BROKER]: CompanyFormState,
  [Role.IMPORT_BROKER]: CompanyFormState,
  [Role.NOTIFY_PARTY]: CompanyFormState,
  [Role.SECOND_NOTIFY_PARTY]: CompanyFormState,
  [Role.SHIPPER]: CompanyFormState,
}

export interface IEntitySearchParams {
  query?: string
  page?: number
  size?: number
}

export interface IEntityGetParams {
  urlId: string
}

export interface INewCompanyParams {
  name: string
}

export interface INewContactParams {
  fullName: string
}

export interface INewCompanyAddressParams {
  country: string
  city: string
  address1: string
  postalCode: string
}

const initialState: ICompanyState = {
  currentRole: Role.SHIPPER,
  [Role.SHIPPER]: {
    status: '',
    contactsStatus: '',
    companies: [],
    companiesById: {},
    contacts: [],
    addresses: [],
  },
  [Role.CONSIGNEE]: {
    status: '',
    contactsStatus: '',
    companies: [],
    companiesById: {},
    contacts: [],
    addresses: [],
  },
  [Role.EXPORT_BROKER]: {
    status: '',
    contactsStatus: '',
    companies: [],
    companiesById: {},
    contacts: [],
    addresses: [],
  },
  [Role.IMPORT_BROKER]: {
    status: '',
    contactsStatus: '',
    companies: [],
    companiesById: {},
    contacts: [],
    addresses: [],
  },
  [Role.NOTIFY_PARTY]: {
    status: '',
    contactsStatus: '',
    companies: [],
    companiesById: {},
    contacts: [],
    addresses: [],
  },
  [Role.SECOND_NOTIFY_PARTY]: {
    status: '',
    contactsStatus: '',
    companies: [],
    companiesById: {},
    contacts: [],
    addresses: [],
  },
};

export const companySlice = createSlice({
  name: 'companies',
  initialState,
  reducers: {
    setCurrentRole: (state, { payload }) => {
      state.currentRole = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(searchCompanies.pending, (state) => {
        state[state.currentRole].status = 'loading';
      })
      .addCase(searchCompanies.fulfilled, (state, { payload }) => {
        state[state.currentRole].status = 'idle';
        state[state.currentRole].companies = payload;
        state[state.currentRole].companiesById = reduce(
          (acc: any, item: Company) => {
            acc[item.id] = item;
            return acc;
          },
          {},
          payload,
        );
      })
      .addCase(searchCompanies.rejected, (state) => {
        state[state.currentRole].status = 'rejected';
        state[state.currentRole].companies = [];
        state[state.currentRole].companiesById = {};
      })
      .addCase(createCompany.pending, (state) => {
        state[state.currentRole].status = 'loading';
      })
      .addCase(createCompany.fulfilled, (state, { payload }) => {
        state[state.currentRole].status = 'idle';
        state[state.currentRole].companies.push(payload);
        state[state.currentRole].companiesById[payload.id] = payload;
      })
      .addCase(getCompanyContacts.pending, (state) => {
        state[state.currentRole].contactsStatus = 'loading';
      })
      .addCase(getCompanyContacts.fulfilled, (state, { payload }) => {
        state[state.currentRole].contactsStatus = 'idle';
        state[state.currentRole].contacts = payload;
      })
      .addCase(getCompanyContacts.rejected, (state) => {
        state[state.currentRole].contactsStatus = 'rejected';
        state[state.currentRole].contacts = [];
      })
      .addCase(getCompanyAddress.fulfilled, (state, { payload }) => {
        state[state.currentRole].addresses = payload;
      })
      .addCase(createCompanyAddress.fulfilled, (state, { payload }) => {
        state[state.currentRole].addresses = [payload];
      });
  },
});

export const getCompanies = (state: RootState) => state.company[state.company.currentRole].companies;
export const getContacts = (state: RootState) => state.company[state.company.currentRole].contacts;
export const getAddresses = (state: RootState) => state.company[state.company.currentRole].addresses;
export const isCompaniesLoading = (state: RootState) => state.company[state.company.currentRole].status === 'loading';
export const isContactsLoading = (state: RootState) => state.company[state.company.currentRole].contactsStatus === 'loading';

export const companySliceReducer = companySlice.reducer;

export const {
  setCurrentRole,
} = companySlice.actions;
