export const freightLoadType = {
  FCL: 'FCL',
  LCL: 'LCL',
  Breakbulk: 'Breakbulk',
};

export enum EFreightLoadType {
  FCL = 'FCL',
  LCL = 'LCL',
  Breakbulk = 'Breakbulk',
}
