import React from 'react';

import { IPropsIcon } from 'app-wrapper/interfaces/icon';

export const BellWarningGoldSvg = (props: IPropsIcon) => {
  const { width, height, style } = props;
  return (
    <svg
      className="BellWarningGoldSvg"
      width={width || '16'}
      height={height || '21'}
      viewBox="0 0 16 21"
      color={style?.color || '#F3BE01'}
      style={style || undefined}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path fillRule="evenodd" clipRule="evenodd" d="M14.5455 16.3182H14V8.59091C14 5.38409 11.6295 2.73409 8.54545 2.29318V1.40909C8.54545 0.906818 8.13864 0.5 7.63636 0.5C7.13409 0.5 6.72727 0.906818 6.72727 1.40909V2.29318C3.64318 2.73409 1.27273 5.38409 1.27273 8.59091V16.3182H0.727273C0.325 16.3182 0 16.6432 0 17.0455V17.7727C0 17.8727 0.0818182 17.9545 0.181818 17.9545H5.09091C5.09091 19.3591 6.23182 20.5 7.63636 20.5C9.04091 20.5 10.1818 19.3591 10.1818 17.9545H15.0909C15.1909 17.9545 15.2727 17.8727 15.2727 17.7727V17.0455C15.2727 16.6432 14.9477 16.3182 14.5455 16.3182ZM7.63636 19.0455C7.03409 19.0455 6.54545 18.5568 6.54545 17.9545H8.72727C8.72727 18.5568 8.23864 19.0455 7.63636 19.0455ZM2.90909 16.3182V8.59091C2.90909 7.32727 3.4 6.14091 4.29318 5.24773C5.18636 4.35455 6.37273 3.86364 7.63636 3.86364C8.9 3.86364 10.0864 4.35455 10.9795 5.24773C11.8727 6.14091 12.3636 7.32727 12.3636 8.59091V16.3182H2.90909ZM6.75564 5.90302C6.74907 5.73292 6.88519 5.59144 7.05542 5.59144H8.3399C8.5105 5.59144 8.64676 5.73352 8.63963 5.90397L8.42316 11.0818C8.41644 11.2424 8.28423 11.3692 8.12342 11.3692H7.25534C7.09416 11.3692 6.96178 11.2419 6.95556 11.0808L6.75564 5.90302ZM7.63455 12.5907C7.0209 12.5907 6.52344 13.0882 6.52344 13.7018C6.52344 14.3155 7.0209 14.813 7.63455 14.813C8.2482 14.813 8.74566 14.3155 8.74566 13.7018C8.74566 13.0882 8.2482 12.5907 7.63455 12.5907Z" fill="currentColor" />
    </svg>
  );
};
