import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { Moment } from 'moment';

import { Button, Option, Select } from 'app-wrapper/view/components';
import { DateDtm } from 'app-wrapper/models/dtm';

import { CountryDTM, ShipmentRouteDTM, WaypointDTM } from 'shipment-operations/models/dtm';
import { FormInput } from 'shipment-operations/view/components';

import {
  ShipmentTransportationLocationDataContainer,
  ShipmentTransportationLocationDataFooter,
  ShipmentTransportationLocationDataItem,
  ShipmentTransportationLocationDataWrapper,
  ShipmentTransportationLocationDataDatePicker,
  ShipmentTransportationLocationDataGrey,
} from './TransportationLocationData.styled';

interface ITransportationLocationDataComponentProps {
  isLoading: boolean
  timeFormat?: string
  countriesList: CountryDTM[]
  data?: ShipmentRouteDTM
  hideDate?: boolean
  wasAnyFormFieldChanged: boolean
  shipmentOriginWaypoint?: WaypointDTM
  releaseDateLabel: string
  portCutoff?: DateDtm
  terminalNameError?: Error
  terminalNameChange: (value: string) => void
  terminalNameBlur: () => void
  passCodeError?: Error
  passCodeChange: (value: string) => void
  passCodeBlur: () => void
  address1Error?: Error
  address1Change: (value: string) => void
  address1Blur: () => void
  address2Error?: Error
  address2Change: (value: string) => void
  address2Blur: () => void
  address3Error?: Error
  address3Change: (value: string) => void
  address3Blur: () => void
  address4Error?: Error
  address4Change: (value: string) => void
  address4Blur: () => void
  cityError?: Error
  cityChange: (value: string) => void
  cityBlur: () => void
  stateError?: Error
  stateChange: (value: string) => void
  stateBlur: () => void
  postcodeError?: Error
  postcodeChange: (value: string) => void
  postcodeBlur: () => void
  countryError?: Error
  countryChange: (value: string) => void
  countryBlur: () => void
  releaseDateChange: (value: Moment | null) => void
  releaseDateBlur: () => void
  onOk: (shipmentId?: string) => void
  onCancel: () => void
}

export const TransportationLocationDataComponent: FC<ITransportationLocationDataComponentProps> = ({
  isLoading,
  countriesList,
  data,
  shipmentOriginWaypoint,
  wasAnyFormFieldChanged,
  releaseDateLabel,
  portCutoff,
  hideDate = false,
  timeFormat = 'DD/MM/YYYY, HH:mm',
  // TODO: move to model, when models become classes
  terminalNameError,
  passCodeError,
  address1Error,
  address2Error,
  address3Error,
  address4Error,
  cityError,
  stateError,
  postcodeError,
  countryError,
  terminalNameChange,
  passCodeChange,
  address1Change,
  address2Change,
  address3Change,
  address4Change,
  cityChange,
  stateChange,
  postcodeChange,
  countryChange,
  releaseDateChange,
  terminalNameBlur,
  passCodeBlur,
  address1Blur,
  address2Blur,
  address3Blur,
  address4Blur,
  cityBlur,
  stateBlur,
  postcodeBlur,
  countryBlur,
  releaseDateBlur,
  onOk,
  onCancel,
}) => {
  const { t } = useTranslation();
  const { shipmentId } = useParams<'shipmentId'>();
  const resultTime = portCutoff && portCutoff.getDateAsMomentWithOffset().clone();

  if (resultTime) {
    resultTime.subtract(2, 'hours'); // should be resolved before origin cutoff port date (2 hours)
  }

  const onAddress3ChangeHandler = useCallback((address: string) => {
    address3Change(address);
  }, []);

  const onAddress3BlurHandler = useCallback(() => {
    address3Blur();
  }, []);

  const onAddress4ChangeHandler = useCallback((address: string) => {
    address4Change(address);
  }, []);

  const onAddress4BlurHandler = useCallback(() => {
    address4Blur();
  }, []);

  const onOkHandler = useCallback(() => {
    onOk(shipmentId);
  }, [shipmentId]);

  return (
    <ShipmentTransportationLocationDataWrapper>
      <ShipmentTransportationLocationDataContainer>
        <ShipmentTransportationLocationDataItem>
          <span>{t('Terminal Name')}</span>
          <FormInput
            disabled={isLoading}
            value={data?.terminalName}
            error={!!terminalNameError}
            onChange={terminalNameChange}
            onBlur={terminalNameBlur}
          />
        </ShipmentTransportationLocationDataItem>

        <ShipmentTransportationLocationDataItem>
          <span>{t('Pass Trough Code')}</span>
          <FormInput
            disabled={isLoading}
            value={data?.passCode}
            error={!!passCodeError}
            onChange={passCodeChange}
            onBlur={passCodeBlur}
          />
        </ShipmentTransportationLocationDataItem>

        <ShipmentTransportationLocationDataItem>
          <span>{t('Address line 1')} *</span>
          <FormInput
            disabled={isLoading}
            value={data?.address1}
            error={!!address1Error}
            onChange={address1Change}
            onBlur={address1Blur}
          />
        </ShipmentTransportationLocationDataItem>

        <ShipmentTransportationLocationDataItem>
          <span>{t('Address line 2')}</span>
          <FormInput
            disabled={isLoading}
            value={data?.address2}
            error={!!address2Error}
            onChange={address2Change}
            onBlur={address2Blur}
          />
        </ShipmentTransportationLocationDataItem>

        <ShipmentTransportationLocationDataItem>
          <span>{t('Address line 3')}</span>
          <FormInput
            disabled={isLoading}
            value={data?.address3}
            error={!!address3Error}
            onChange={onAddress3ChangeHandler}
            onBlur={onAddress3BlurHandler}
          />
        </ShipmentTransportationLocationDataItem>

        <ShipmentTransportationLocationDataItem>
          <span>{t('Address line 4')}</span>
          <FormInput
            disabled={isLoading}
            value={data?.address4}
            error={!!address4Error}
            onChange={onAddress4ChangeHandler}
            onBlur={onAddress4BlurHandler}
          />
        </ShipmentTransportationLocationDataItem>

        <ShipmentTransportationLocationDataItem>
          <span>{t('City')} *</span>
          <FormInput
            disabled={isLoading}
            value={data?.city}
            error={!!cityError}
            onChange={cityChange}
            onBlur={cityBlur}
          />
        </ShipmentTransportationLocationDataItem>

        <ShipmentTransportationLocationDataItem>
          <span>
            {t('State')}
            <ShipmentTransportationLocationDataGrey> {t('US Required')}</ShipmentTransportationLocationDataGrey>
          </span>
          <FormInput
            disabled={isLoading}
            value={data?.state}
            error={!!stateError}
            onChange={stateChange}
            onBlur={stateBlur}
          />
        </ShipmentTransportationLocationDataItem>

        <ShipmentTransportationLocationDataItem>
          <span>{t('Post Code')} *</span>
          <FormInput
            disabled={isLoading}
            value={data?.postcode}
            error={!!postcodeError}
            onChange={postcodeChange}
            onBlur={postcodeBlur}
          />
        </ShipmentTransportationLocationDataItem>

        <ShipmentTransportationLocationDataItem>
          <span>{t('Country')} *</span>
          <Select
            disabled={isLoading}
            value={data?.country}
            error={!!countryError}
            onChange={countryChange}
            onBlur={countryBlur}
          >
            {
              countriesList.map(({ name, code }) => (
                <Option key={code} value={code}>{name}</Option>
              ))
            }
          </Select>
        </ShipmentTransportationLocationDataItem>

        {!hideDate ? (
          <ShipmentTransportationLocationDataItem>
            <span>{t(releaseDateLabel)}</span>
            <ShipmentTransportationLocationDataDatePicker
              showTime
              disabled={isLoading}
              value={data?.releaseDate?.getDateAsMomentWithOffset()}
              format={timeFormat}
              onChange={releaseDateChange}
              onBlur={releaseDateBlur}
              allowClear={false}
            />
            <ShipmentTransportationLocationDataGrey>
              {t('Should be before')}{` ${resultTime?.format('D MMM, HH:mm')} `}{`(${shipmentOriginWaypoint?.address})`}
            </ShipmentTransportationLocationDataGrey>
          </ShipmentTransportationLocationDataItem>
        ) : null}
      </ShipmentTransportationLocationDataContainer>

      <ShipmentTransportationLocationDataFooter>
        <Button type="dashed" onClick={onCancel}>{t('Cancel')}</Button>
        <Button
          disabled={!wasAnyFormFieldChanged}
          loading={isLoading}
          onClick={onOkHandler}
        >
          {t('Save')}
        </Button>
      </ShipmentTransportationLocationDataFooter>
    </ShipmentTransportationLocationDataWrapper>
  );
};
