import React from 'react';
import { IPropsIcon } from 'app-wrapper/interfaces/icon';

export const BellGoldSvg = (props: IPropsIcon) => {
  const { width, height, style } = props;
  return (
    <svg
      className="BellGoldSvg"
      width={width || '16'}
      height={height || '16'}
      viewBox="0 0 16 16"
      color={style?.color || '#F3BE01'}
      style={style || undefined}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M12.75 12H12.375V6.6875C12.375 4.48281 10.7453 2.65938 8.625 2.35625V1.75C8.625 1.40469 8.34531 1.125 8 1.125C7.65469 1.125 7.375 1.40469 7.375 1.75V2.35625C5.25469 2.65938 3.625 4.48281 3.625 6.6875V12H3.25C2.97344 12 2.75 12.2234 2.75 12.5V13C2.75 13.0688 2.80625 13.125 2.875 13.125H6.25C6.25 14.0906 7.03438 14.875 8 14.875C8.96562 14.875 9.75 14.0906 9.75 13.125H13.125C13.1938 13.125 13.25 13.0688 13.25 13V12.5C13.25 12.2234 13.0266 12 12.75 12ZM8 13.875C7.58594 13.875 7.25 13.5391 7.25 13.125H8.75C8.75 13.5391 8.41406 13.875 8 13.875Z" fill="#F3BE01" />
    </svg>
  );
};
