import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { hasReadAccess } from 'app-wrapper/utils';
import { DocumentsCollapseItemDTM } from 'shipment-operations/models/dtm';
import { CargoWiseDocumentTypes, PermissionAttributePolicy } from 'shipment-operations/constants';
import { EOrganizationMemberRole } from 'user-management/constants';
import { SkeletonSection } from 'app-wrapper/view/components/Skeleton/Skeleton.component';

import {
  Documents,
  SectionTitleWrap,
  SectionTitle,
  UploadIcon,
  InvoiceSpinnerWrap,
  InvoiceSpinner,
} from './ShipmentDocumentsAll.styled';
import { DocumentsSection } from './components';

interface ShipmentDocumentsAllComponentProps {
  fetchData: (shipmentId: string) => void;
  currentOrgRole?: EOrganizationMemberRole;
  clean: () => void;
  isLoading: boolean;
  isInvoiceLoading: boolean;
  isRFQLoading: boolean;
  additionalDocumentsMap: Record<string, DocumentsCollapseItemDTM[]>;
  skypaceDownloadDocumentsMap?: Record<string, DocumentsCollapseItemDTM[]>;
  onDownload: (shipmentId: string, documentId: number, documentName: string) => void;
  onRemoveAdditional: (shipmentId: string, document: DocumentsCollapseItemDTM) => void;
  onUploadAdditional: () => void;
  bookingConfirmationDocument: DocumentsCollapseItemDTM | null;
  payableInvoices: DocumentsCollapseItemDTM[];
  receivableInvoices: DocumentsCollapseItemDTM[];
  documentHBL: DocumentsCollapseItemDTM | null;
  documentNRA: DocumentsCollapseItemDTM | null;
  documentNRABetweenContractOwnerAndAdmin: DocumentsCollapseItemDTM | null;
  documentNRABetweenAdminAndAdmin: DocumentsCollapseItemDTM | null;
  quoteDocument: DocumentsCollapseItemDTM | null;
  documentSkypaceTerms: DocumentsCollapseItemDTM | null;
  carrierBookingConfirmation: DocumentsCollapseItemDTM | null;
  viewInvoiceDocumentNameWithPrefix?: PermissionAttributePolicy;
}

export const ShipmentDocumentsAllComponent: FC<ShipmentDocumentsAllComponentProps> = ({
  fetchData,
  clean,
  isLoading,
  currentOrgRole,
  isRFQLoading,
  isInvoiceLoading,
  additionalDocumentsMap,
  skypaceDownloadDocumentsMap,
  onDownload,
  onRemoveAdditional,
  onUploadAdditional,
  payableInvoices,
  receivableInvoices,
  bookingConfirmationDocument,
  quoteDocument,
  documentHBL,
  documentNRA,
  documentNRABetweenContractOwnerAndAdmin,
  documentNRABetweenAdminAndAdmin,
  documentSkypaceTerms,
  carrierBookingConfirmation,
  viewInvoiceDocumentNameWithPrefix,
}) => {
  const { shipmentId = '' } = useParams();
  const { t } = useTranslation();

  const isAbleToViewInvoicesDocumentsPrefixes = viewInvoiceDocumentNameWithPrefix && hasReadAccess(viewInvoiceDocumentNameWithPrefix);

  useEffect(() => {
    fetchData(shipmentId);

    return () => {
      clean();
    };
  }, []);

  if (isLoading) {
    return (
      <SkeletonSection height="360px" />
    );
  }

  return (
    <Documents>
      {carrierBookingConfirmation || documentHBL || documentNRA || bookingConfirmationDocument || quoteDocument || payableInvoices.length || receivableInvoices.length || skypaceDownloadDocumentsMap?.length ? (
        <>
          <SectionTitleWrap>
            <SectionTitle>
              {t('Documents from Skypace')}
            </SectionTitle>
          </SectionTitleWrap>

          {carrierBookingConfirmation ? (
            <DocumentsSection
              shipmentId={shipmentId}
              sectionName={t('Carrier Booking Confirmation')}
              onDownload={onDownload}
              documents={[carrierBookingConfirmation]}
            />
          ) : null}

          {payableInvoices.length ? (
            <DocumentsSection
              shipmentId={shipmentId}
              sectionName={!isAbleToViewInvoicesDocumentsPrefixes ? t('Invoices') : t('AP Invoices')}
              documents={payableInvoices}
              showInvoicePrintMenuItem
              isAPInvoiceType
            />
          ) : null}

          {receivableInvoices.length ? (
            <DocumentsSection
              shipmentId={shipmentId}
              sectionName={!isAbleToViewInvoicesDocumentsPrefixes ? t('Invoices') : t('AR Invoices')}
              documents={receivableInvoices}
              showInvoicePrintMenuItem
            />
          ) : null}

          {documentNRA ? (
            <DocumentsSection
              shipmentId={shipmentId}
              sectionName={currentOrgRole === EOrganizationMemberRole.admin ? t('NRA Agreement (to Customer)') : t('NRA Agreement')}
              documents={[documentNRA]}
              showNRAPrintMenuItem
            />
          ) : null}

          {documentNRABetweenContractOwnerAndAdmin ? (
            <DocumentsSection
              shipmentId={shipmentId}
              sectionName={t('NRA Agreement (from Partner) ')}
              documents={[documentNRABetweenContractOwnerAndAdmin]}
              showNRAPrintContractOwnerToAdminMenuItem
            />
          ) : null}

          {documentNRABetweenAdminAndAdmin ? (
            <DocumentsSection
              shipmentId={shipmentId}
              sectionName={t('NRA Agreement (from Skypace) ')}
              documents={[documentNRABetweenAdminAndAdmin]}
              showNRAPrintAdminToAdminMenuItem
            />
          ) : null}

          {quoteDocument ? (
            <DocumentsSection
              shipmentId={shipmentId}
              sectionName={t('Quote')}
              documents={[quoteDocument]}
              showQuotePrintMenuItem
            />
          ) : null}

          {bookingConfirmationDocument ? (
            <DocumentsSection
              shipmentId={shipmentId}
              sectionName={t('Booking Confirmation')}
              documents={[bookingConfirmationDocument]}
              showBCPrintMenuItem
            />
          ) : null}

          {documentHBL ? (
            <DocumentsSection
              shipmentId={shipmentId}
              sectionName={t('House Bill Of Lading')}
              documents={[documentHBL]}
              showHBLPrintMenuItem
            />
          ) : null}

          {documentSkypaceTerms ? (
            <DocumentsSection
              shipmentId={shipmentId}
              sectionName={t('Terms and Conditions of Service')}
              documents={[documentSkypaceTerms]}
              showSkypaceTermsPrintMenuItem
            />
          ) : null}

          {skypaceDownloadDocumentsMap ? Object.keys(skypaceDownloadDocumentsMap).map((documentType) => {
            const sectionNameObj = CargoWiseDocumentTypes.find(({ code }) => code === documentType);

            return (
              <DocumentsSection
                key={`ShipmentDocumentsAllComponent_${documentType}`}
                shipmentId={shipmentId}
                sectionName={sectionNameObj ? sectionNameObj.description : ''}
                documents={skypaceDownloadDocumentsMap[documentType]}
                onDownload={onDownload}
                onRemove={onRemoveAdditional}
              />
            );
          }) : null}
        </>
      ) : null}

      {isInvoiceLoading || isRFQLoading ? (
        <InvoiceSpinnerWrap>
          <InvoiceSpinner />
        </InvoiceSpinnerWrap>
      ) : null}

      <SectionTitleWrap>
        <SectionTitle>
          {t('Additional Documents')}
        </SectionTitle>

        <UploadIcon onClick={onUploadAdditional} />
      </SectionTitleWrap>

      {Object.keys(additionalDocumentsMap).map((documentType) => {
        const sectionNameObj = CargoWiseDocumentTypes.find(({ code }) => code === documentType);

        return (
          <DocumentsSection
            key={`ShipmentDocumentsAllComponent_additionalDocumentsMap_${documentType}`}
            shipmentId={shipmentId}
            sectionName={sectionNameObj ? sectionNameObj.description : ''}
            documents={additionalDocumentsMap[documentType]}
            onDownload={onDownload}
            onRemove={onRemoveAdditional}
          />
        );
      })}
    </Documents>
  );
};
