import {
  IsNumber, IsString, IsOptional, ValidateNested,
} from 'class-validator';
import { Type } from 'class-transformer';
import { BaseDTM } from 'proto/BaseDTM';

export interface IShipmentReferenceFilesDTM {
  id: number
  name: string
  type: string
  createdAt?: string
  createdBy?: string
}

export class ShipmentReferenceFilesDTM extends BaseDTM<IShipmentReferenceFilesDTM> {
  @IsNumber()
  id: number

  @IsString()
  name: string

  @IsString()
  type: string

  @IsOptional()
  @IsString()
  createdAt?: string

  @IsOptional()
  @IsString()
  createdBy?: string
}

export interface IShipmentReferenceReferenceDTM {
  id?: number
  value: string
}

export class ShipmentReferenceReferenceDTM extends BaseDTM<IShipmentReferenceDTM> {
  @IsOptional()
  @IsNumber()
  id?: number

  @IsString()
  value: string
}

export interface IShipmentReferenceDTM {
  id?: number
  notes?: string
  references?: IShipmentReferenceReferenceDTM[]
  files?: IShipmentReferenceFilesDTM[]
}

export class ShipmentReferenceDTM extends BaseDTM<IShipmentReferenceDTM> {
  @IsOptional()
  @IsNumber()
  id?: number

  @IsOptional()
  @IsString()
  notes?: string

  @IsOptional()
  @ValidateNested({ each: true })
  @Type(() => ShipmentReferenceReferenceDTM)
  references?: ShipmentReferenceReferenceDTM[]

  @IsOptional()
  @ValidateNested({ each: true })
  @Type(() => ShipmentReferenceFilesDTM)
  files?: ShipmentReferenceFilesDTM[]
}
