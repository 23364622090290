import React, { memo } from 'react';
import { useSelector } from 'react-redux';

import { R } from 'user-management/repository';

import { OrganizationCoverageAreasComponent } from './OrganizationCoverageAreas.component';

export const OrganizationCoverageAreasContainer = memo(() => {
  const organization = useSelector(R.selectors.userOrganizationData.getInternalOrganization);

  return (
    <OrganizationCoverageAreasComponent
      coverageAreas={organization?.coverageAreas}
    />
  );
});
