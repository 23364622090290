import { BaseController, controller } from 'proto/BaseController';

import { R } from 'user-management/repository';
import { OrganizationDTM } from 'user-management/models/dtm';

@controller
export class UserMenuController extends BaseController {
  public uploadData = async () => {
    let organization: OrganizationDTM | undefined;

    this.dispatch(R.actions.userOrganizationData.setOrganizationDataLoading(true));

    try {
      organization = await R.services.organization.getCurrentOrganization();
    } catch (e) {
      console.error(e);

      this.dispatch(R.actions.userOrganizationData.setOrganizationDataLoading(false));

      return;
    }
    if (!organization) {
      console.error('UserMenuController.uploadData: no organization');

      this.dispatch(R.actions.userOrganizationData.setOrganizationDataLoading(false));

      return;
    }

    this.dispatch(R.actions.userOrganizationData.setUserOrganization(organization));
    this.dispatch(R.actions.userOrganizationData.setOrganizationDataLoading(false));
  };

  public clear = () => {
    this.dispatch(R.actions.userOrganizationData.clear());
  }
}
