import React, {
  ChangeEvent,
  FC,
  useCallback,
  useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';

import { Input, Tooltip } from 'app-wrapper/view/components';

interface IExportClearanceNumberInputComponentProps {
  exportClearanceNumber?: string,
  error?: boolean,
  setExportClearanceNumber: (exportClearanceNumber: string) => void
  disabled: boolean;
}

export const ExportClearanceNumberInputComponent: FC<IExportClearanceNumberInputComponentProps> = ({
  exportClearanceNumber,
  error,
  setExportClearanceNumber,
  disabled,
}) => {
  const { t } = useTranslation();

  const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setExportClearanceNumber(event.target.value);
  }, []);

  const input = useMemo(() => (
    <Input
      placeholder={t('None')}
      value={exportClearanceNumber}
      error={error}
      onChange={handleChange}
      disabled={disabled}
      maxLength={15}
    />
  ), [t, exportClearanceNumber, error, handleChange, disabled]);

  return (
    <Tooltip
      visible={error}
      mode="danger"
      title={t('ITN Number must begin with X, followed by 14 digits.')}
    >
      {input}
    </Tooltip>
  );
};
