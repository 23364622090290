import React, {
  FC, memo, useCallback, useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'antd/es/skeleton';
import useInfiniteScroll from 'react-infinite-scroll-hook';

import { Tooltip } from 'app-wrapper/view/components';

import { ICompanyAddressDTM, IContactBookDefaultStateCompanyListStateDTM } from 'user-management/models/dtm';

import {
  ContactBookListTableDiv,
  ContactBookListTableContent,
  ContactBookListTableContentLink,
  ContactBookListTableContentItem,
  ContactBookListTableContentItemTop,
  ContactBookListTableContentTitle,
  ContactBookListTableHeader,
  ContactBookListTableHeaderItem,
  AACSpanEllipsis,
  ContactBookListTableContact,
  ContactBookListTableContactWrap,
  ContactBookListTableContentPrimary,
} from './ContactBookListTable.styled';

interface IContactBookListTableProps {
  onStart: () => void;
  onClickCompanyItem: (companyId: string) => () => void;
  ContactBookNextPage: () => void;
  isLoadAdd?: boolean;
  isLoading?: boolean;
  hasNextPage?: boolean;
  allData?: IContactBookDefaultStateCompanyListStateDTM[];
  companyListAddresses?: ICompanyAddressDTM[]
  isFooterContent?: React.ReactNode;
}

const ContactBookListTable: FC<IContactBookListTableProps> = (props) => {
  const {
    onStart,
    onClickCompanyItem,
    ContactBookNextPage,
    isLoadAdd,
    isLoading,
    hasNextPage,
    allData,
    companyListAddresses,
    isFooterContent,
  } = props;

  const { t } = useTranslation();

  useEffect(() => {
    onStart();
  }, []);

  const addressPrimary = useCallback(
    (id: string[]) => companyListAddresses?.filter((itemAddress) => id.includes(itemAddress?.customId || '') && itemAddress.isPrimary)?.[0],
    [companyListAddresses],
  );

  const [sentryRef] = useInfiniteScroll({
    loading: !!(isLoading || isLoadAdd),
    hasNextPage: !!hasNextPage,
    onLoadMore: ContactBookNextPage,
    // scrollableTarget: 'Layout'
  });

  return (
    <ContactBookListTableDiv>

      <ContactBookListTableHeader>
        <ContactBookListTableHeaderItem
          width="42.7%"
        >
          {t('Company')}
        </ContactBookListTableHeaderItem>
        <ContactBookListTableHeaderItem
          width="17.8%"
          marginLeft="10px"
        >
          {t('Country')}
        </ContactBookListTableHeaderItem>
        <ContactBookListTableHeaderItem
          width="17.8%"
          marginLeft="10px"
        >
          {t('City')}
        </ContactBookListTableHeaderItem>
        <ContactBookListTableHeaderItem
          width="17.8%"
          isCenter
        >
          {t('Contact Person')}
        </ContactBookListTableHeaderItem>
      </ContactBookListTableHeader>

      <ContactBookListTableContent id="ContactBookListTableContent">

        {allData?.map((item) => (
          <ContactBookListTableContentItem key={`ContactBookListTableContentItem_${item.customId}`}>
            <ContactBookListTableContentItemTop>
              <ContactBookListTableContentLink
                onClick={onClickCompanyItem(`${item.id || ''}`)}
              >
                <AACSpanEllipsis>
                  {item.name}
                </AACSpanEllipsis>
                {item.isPrimary && (<ContactBookListTableContentPrimary>{t('Primary')}</ContactBookListTableContentPrimary>)}
              </ContactBookListTableContentLink>
              <ContactBookListTableContentTitle>
                <AACSpanEllipsis>
                  {addressPrimary(item?.addressesIds || [])?.country || ''}
                </AACSpanEllipsis>
              </ContactBookListTableContentTitle>
              <ContactBookListTableContentTitle>
                <AACSpanEllipsis>
                  {addressPrimary(item?.addressesIds || [])?.city || ''}
                </AACSpanEllipsis>
              </ContactBookListTableContentTitle>
              <ContactBookListTableContactWrap>
                <ContactBookListTableContact>
                  <Tooltip
                    title={(
                      <>
                        {item.primaryContactCompany?.contactPerson || ''}<br />
                        {item.primaryContactCompany?.email || ''}<br />
                        {item.primaryContactCompany?.phone || ''}<br />
                      </>
                    )}
                    placement="top"
                  >
                    {item.primaryContactCompany?.contactPerson.slice(0, 1).toLocaleUpperCase() || ''}
                  </Tooltip>
                </ContactBookListTableContact>
              </ContactBookListTableContactWrap>
            </ContactBookListTableContentItemTop>

          </ContactBookListTableContentItem>
        ))}
        {(isLoadAdd || hasNextPage) && (
          <div ref={sentryRef}>
            {isLoadAdd && (<Skeleton />)}
          </div>
        )}
        {isFooterContent || null}
      </ContactBookListTableContent>

    </ContactBookListTableDiv>
  );
};

const ContactBookListTableCache = memo(ContactBookListTable);

export { ContactBookListTableCache as ContactBookListTable };
