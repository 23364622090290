import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'app-wrapper/createSlice';

import { IManualBookingDrawerState } from 'shipment-operations/models/states';
import {
  RFQServiceByIdContentRoutesTransportation,
} from 'monetary/models/dtm/Quotas';
import {
  RouteLegDTM,
  ManualBookingTransportationErrorsDTM,
  ManualBookingErrorsDTM,
  ContainerDocumentDTM,
  CargoBaseDTM,
  TemperatureControlDTM,
  CargoDTM,
  TransportPlanDTM, ContainerDTM,
} from 'shipment-operations/models/dtm';
import { ValidationErrorDTM } from 'app-wrapper/types';

const initialState: IManualBookingDrawerState = {
  isLoading: false,
  isWizardOpened: false,
  isCarrierReferenceDisabled: false,
  transportations: [],
  legs: [],
  transportationsErrors: [],
  errors: ManualBookingErrorsDTM.createEmpty(),
  carrierReferenceNumber: '',
  bookingConfirmationDocument: null,
  cargos: [],
  defaultCargo: CargoBaseDTM.createEmpty(),
  hasHazmats: false,
  toggledHazmatCargoIds: [],
  hasTemperatureControl: false,
  temperatureControl: null,
  transportPlan: null,
  responseErrorMessage: '',
  containers: [],
};

export const manualBookingWizardSlice = createSlice({
  name: 'manualBookingWizardSlice',
  initialState,
  reducers: {
    setIsLoading: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      isLoading: payload,
    }),
    setIsDrawerOpened: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      isWizardOpened: payload,
    }),
    setRouteLegs: (state, { payload }: PayloadAction<RouteLegDTM[]>) => ({
      ...state,
      legs: [...payload],
    }),
    setTransportations: (state, { payload }: PayloadAction<RFQServiceByIdContentRoutesTransportation[]>) => ({
      ...state,
      transportations: [...payload],
    }),
    setTransportationById: (state, { payload: transportation }: PayloadAction<RFQServiceByIdContentRoutesTransportation>) => {
      const { transportations } = state;
      const transportationIndex = transportations.findIndex(({ id }) => id === transportation.id);

      state.transportations[transportationIndex] = RFQServiceByIdContentRoutesTransportation.fromPlain({
        ...transportation,
      });
    },
    setIsCarrierReferenceDisabled: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      isCarrierReferenceDisabled: payload,
    }),
    clearTransportationsErrors: (state) => ({
      ...state,
      transportationsErrors: state.transportationsErrors.map(({ transportationId }) => ManualBookingTransportationErrorsDTM.createEmpty(transportationId)),
    }),
    setTransportationsErrors: (state, { payload }: PayloadAction<ManualBookingTransportationErrorsDTM[]>) => ({
      ...state,
      transportationsErrors: [...payload],
    }),
    setResponseErrorMessage: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      responseErrorMessage: payload,
    }),
    setTransportationETDErrorById: (state, { payload }: PayloadAction<{ transportationId: number; error?: ValidationErrorDTM }>) => {
      const errorsIndex = state.transportationsErrors.findIndex(({ transportationId }) => transportationId === payload.transportationId);

      state.transportationsErrors[errorsIndex] = ManualBookingTransportationErrorsDTM.fromPlain({
        ...state.transportationsErrors[errorsIndex],
        etd: payload.error,
      });
    },
    setTransportationETAErrorById: (state, { payload }: PayloadAction<{ transportationId: number; error?: ValidationErrorDTM }>) => {
      const errorsIndex = state.transportationsErrors.findIndex(({ transportationId }) => transportationId === payload.transportationId);

      state.transportationsErrors[errorsIndex] = ManualBookingTransportationErrorsDTM.fromPlain({
        ...state.transportationsErrors[errorsIndex],
        eta: payload.error,
      });
    },
    setCarrierReferenceNumber: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      carrierReferenceNumber: payload,
    }),
    setCarrierReferenceNumberError: (state, { payload }: PayloadAction<ValidationErrorDTM | undefined>) => {
      state.errors = ManualBookingErrorsDTM.fromPlain({
        ...state.errors,
        carrierReferenceNumber: payload,
      });
    },
    setBookingConfirmationError: (state, { payload }: PayloadAction<ValidationErrorDTM | undefined>) => {
      state.errors = ManualBookingErrorsDTM.fromPlain({
        ...state.errors,
        confirmationDocument: payload,
      });
    },
    setBookingConfirmationDocument: (state, { payload }: PayloadAction<ContainerDocumentDTM | null>) => ({
      ...state,
      bookingConfirmationDocument: payload,
    }),
    setCargos: (state, { payload }: PayloadAction<CargoDTM[]>) => ({
      ...state,
      cargos: payload,
    }),
    setTransportPlan: (state, { payload }: PayloadAction<TransportPlanDTM>) => ({
      ...state,
      transportPlan: payload,
    }),
    setHasHazmats: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      hasHazmats: payload,
    }),
    setHasTemperatureControl: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      hasTemperatureControl: payload,
    }),
    addToggledHazmatCargoId: (state, { payload }: PayloadAction<number>) => ({
      ...state,
      toggledHazmatCargoIds: [
        ...state.toggledHazmatCargoIds,
        payload,
      ],
    }),
    removeToggledHazmatCargoId: (state, { payload }: PayloadAction<number>) => {
      const cargoIds = [...state.toggledHazmatCargoIds];
      const targetIndex = cargoIds.findIndex((id) => id === payload) as number;

      cargoIds.splice(targetIndex, 1);

      state.toggledHazmatCargoIds = [
        ...cargoIds,
      ];
    },
    setContainers: (state, { payload }: PayloadAction<ContainerDTM[]>) => ({
      ...state,
      containers: [...payload],
    }),
    setDefaultCargo: (state, action: PayloadAction<CargoBaseDTM>) => {
      state.defaultCargo = {
        ...action.payload,
      };
    },
    setTemperatureControl: (state, { payload }: PayloadAction<TemperatureControlDTM | null>) => ({
      ...state,
      temperatureControl: payload,
    }),
    reset: () => ({
      ...initialState,
    }),
  },
});

export const manualBookingWizardActions = manualBookingWizardSlice.actions;
export const manualBookingWizardReducer = manualBookingWizardSlice.reducer;
