import { apiWorker } from 'app-wrapper/repository/utilsServices';
import { ReferenceDTM } from 'app-wrapper/types/Reference';
import { IDocumentDTM } from 'app-wrapper/models/dtm';

import {
  GetShortContainersContract,
  TGetContainerListResponse,
  TPostContainerBulkRequest,
  TPostContainerRequest,
  TPostContainerResponse,
  TPutContainerRequest,
  TPutContainerResponse,
} from 'shipment-operations/models/contracts';
import { ContainerDTM, ShortContainerDTM, ContainerDocumentDTM } from 'shipment-operations/models/dtm';
import { ContainerReeferTypes, ContainerUsualTypes } from 'shipment-operations/constants';

export class ShipmentContainersService {
  private base = '/shipment-service/api/v1/shipments';

  public getContainersList = async (shipmentId: string, removeSignal?: boolean) => {
    let list: ContainerDTM[] | null = null;

    const rawResponse = await apiWorker.requestGet<TGetContainerListResponse>(`${this.base}/${shipmentId}/containers`, {}, removeSignal);
    const response = rawResponse.data;
    const parsedResponse = response.map((item) => {
      const cargoItems = item.cargoItems.map((cargoItem) => ({
        id: `${cargoItem.id}`,
        cargoId: `${cargoItem.cargoId}`,
        packagesNumber: `${cargoItem.packagesNumber}`,
        weight: `${cargoItem.weight}`,
        volume: `${cargoItem.volume}`,
      }));

      const references = item.references.map((ref) => (ReferenceDTM.fromPlain({
        id: `${ref.id}`,
        type: ref.type,
        value: ref.value,
      })));

      const parsedItem = ContainerDTM.fromPlain({
        cargoItems,
        references,
        id: `${item.id}`,
        type: item.type as ContainerReeferTypes | ContainerUsualTypes,
        number: item.number || undefined,
        sealNumber: item.sealNumber || '',
        ownContainer: item.ownContainer,
        rateId: `${item.rateId}`,
        planId: `${item.planId}`,
        name: item.number || '',
        seaworthyCertificate: this.convertDocument(item.seaworthyCertificate),
        isInDraft: false,
        isVirtual: false,
        vgm: item.vgm ? {
          ...item.vgm,
        } : undefined,
        railBilling: item.railBilling ? {
          ...item.railBilling,
        } : undefined,
      });

      if (!parsedItem.isValid()) {
        console.error('Data from API does not match with contract');
      }
      return parsedItem;
    });
    list = parsedResponse.filter((el) => el !== null) as ContainerDTM[];

    return list;
  }

  public putContainer = async (shipmentId: string, container: ContainerDTM) => {
    let result: ContainerDTM | null = null;

    const requestBody: TPutContainerRequest = {
      type: container.type,
      number: container.number ? container.number : null,
      sealNumber: container.sealNumber,
      ownContainer: container.ownContainer,
      rateId: +container.rateId,
      planId: container.planId,
      cargoItems: container.cargoItems.map((item) => ({
        id: +item.id,
        cargoId: +item.cargoId,
        packagesNumber: +item.packagesNumber,
        weight: +item.weight,
        volume: +item.volume,
      })),
      references: container.references.map((item) => ({
        id: +item.id,
        type: item.type,
        value: item.value,
      })),
      seaworthyCertificate: container.seaworthyCertificate ? container.seaworthyCertificate.response : null,
      vgm: container.vgm ? {
        status: container.vgm.status,
        method: container.vgm.method,
        value: container.vgm.value,
      } : null,
      railBilling: container.railBilling ? {
        status: container.railBilling.status,
      } : null,
    };

    const response = await apiWorker.requestPut<TPutContainerResponse>(`${this.base}/${shipmentId}/containers/${container.id}`, requestBody);

    const cargoItems = response.data.cargoItems.map((cargoItem) => ({
      id: `${cargoItem.id}`,
      cargoId: `${cargoItem.cargoId}`,
      packagesNumber: `${cargoItem.packagesNumber}`,
      weight: `${cargoItem.weight}`,
      volume: `${cargoItem.volume}`,
    }));

    const references = response.data.references.map((ref) => (ReferenceDTM.fromPlain({
      id: `${ref.id}`,
      type: ref.type,
      value: ref.value,
    })));

    result = ContainerDTM.fromPlain({
      cargoItems,
      references,
      id: `${response.data.id}`,
      type: response.data.type as ContainerReeferTypes | ContainerUsualTypes,
      number: response.data.number || undefined,
      sealNumber: response.data.sealNumber || '',
      ownContainer: response.data.ownContainer,
      rateId: `${response.data.rateId}`,
      planId: `${response.data.planId}`,
      name: response.data.number || '',
      seaworthyCertificate: this.convertDocument(response.data.seaworthyCertificate),
      isInDraft: false,
      isVirtual: false,
      vgm: response.data.vgm ? {
        ...response.data.vgm,
      } : undefined,
    });

    if (!result.isValid()) {
      console.error(result.validate());
    }

    return result;
  }

  // public updateCargoItemForContainer = async (shipmentId: string, containerId: string) => {
  //   const requestBody: IPostContainerRequest = {
  //     type: containerType,
  //   };
  //
  //   try {
  //     const rawResponse = await post(`${this.base}/${shipmentId}/containers`, requestBody);
  //     if (!rawResponse.ok) {
  //       // get error message from body or default to response status
  //       const error = (rawResponse && rawResponse.body) || rawResponse.status;
  //
  //       return Promise.reject(error);
  //     }
  //   } catch (e) {
  //     throw new Error('Shipment getting error');
  //   }
  //
  //   return undefined;
  // }

  public postContainer = async (shipmentId: string, container: ContainerDTM) => {
    let result: ContainerDTM | null = null;
    const requestBody: TPostContainerRequest = {
      type: container.type,
      number: container.number ? container.number : null,
      sealNumber: container.sealNumber,
      ownContainer: container.ownContainer,
      planId: container.planId,
      rateId: +container.rateId,
      cargoItems: container.cargoItems.map((item) => ({
        id: +item.id,
        cargoId: +item.cargoId,
        packagesNumber: +item.packagesNumber,
        weight: +item.weight,
        volume: +item.volume,
      })),
      references: container.references.map((item) => ({
        id: +item.id,
        type: item.type,
        value: item.value,
      })),
      seaworthyCertificate: container.seaworthyCertificate ? container.seaworthyCertificate.response : null,
    };

    const response = await apiWorker.requestPost<TPostContainerResponse>(`${this.base}/${shipmentId}/containers`, requestBody);

    const cargoItems = response.data.cargoItems.map((cargoItem) => ({
      id: `${cargoItem.id}`,
      cargoId: `${cargoItem.cargoId}`,
      packagesNumber: `${cargoItem.packagesNumber}`,
      weight: `${cargoItem.weight}`,
      volume: `${cargoItem.volume}`,
    }));

    const references = response.data.references.map((ref) => (ReferenceDTM.fromPlain({
      id: `${ref.id}`,
      type: ref.type,
      value: ref.value,
    })));

    result = ContainerDTM.fromPlain({
      cargoItems,
      references,
      id: `${response.data.id}`,
      type: response.data.type as ContainerReeferTypes | ContainerUsualTypes,
      number: response.data.number || undefined,
      sealNumber: response.data.sealNumber || '',
      ownContainer: response.data.ownContainer,
      rateId: `${response.data.rateId}`,
      planId: `${response.data.planId}`,
      name: response.data.number || '',
      seaworthyCertificate: this.convertDocument(response.data.seaworthyCertificate),
      isInDraft: false,
      isVirtual: false,
    });

    if (!result.isValid()) {
      console.error(result.validate());
    }

    return result;
  }

  public postContainerBulk = async (shipmentId: string, containerType: ContainerReeferTypes | ContainerUsualTypes, qty: number) => {
    const requestBody: TPostContainerBulkRequest = {
      containers: [],
    };
    const newContainer = {
      type: containerType,
    };

    for (let i = 0; i < qty; i += 1) {
      requestBody.containers.push({ ...newContainer });
    }

    await apiWorker.requestPost(`${this.base}/${shipmentId}/containers/bulk`, requestBody);

    return undefined;
  }

  public getShortContainers = async (shipmentId: string) => {
    let shipmentData: ShortContainerDTM[];

    try {
      const rawResponse = await apiWorker.requestGet<GetShortContainersContract[]>(`/shipment-service/api/v1/shipments/${shipmentId}/containers`);

      const parsedResponse = rawResponse.data.map((item) => {
        const parsedItem = ShortContainerDTM.fromPlain({
          id: item.id,
          number: item.number,
          type: item.type,
        });
        if (!parsedItem.isValid()) {
          console.error('Data from API does not match with contract');
        }
        return parsedItem;
      });
      shipmentData = parsedResponse.filter((el) => el !== null) as ShortContainerDTM[];
    } catch (e) {
      throw new Error('ShipmentContainersService containers getting error');
    }

    return shipmentData;
  }

  public deleteContainer = async (shipmentId: string, containerId: string) => {
    await apiWorker.requestDelete(`/shipment-service/api/v1/shipments/${shipmentId}/containers/${containerId}`);

    return true;
  }

  private convertDocument = (document: IDocumentDTM | null) => {
    if (!document) {
      return null;
    }

    return ContainerDocumentDTM.fromPlain({
      uid: document.id.toString(),
      name: document.name,
      status: 'done',
      response: document,
      url: '/',
    });
  }
}
