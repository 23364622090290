import { DownloadFileUseCase } from 'app-wrapper/usecases/DownloadFile.useCase';
import { hasReadAccess } from 'app-wrapper/utils';

import { BaseController, controller } from 'proto/BaseController';
import { R as userManagementR } from 'user-management/repository';
import { R as AuthR } from 'authentication/repository';

@controller
export class UsersController extends BaseController {
  public async init() {
    this.dispatch(userManagementR.actions.users.setIsLoading(true));

    await AuthR.services.auth.setLoggedIn();

    const userResponse = await AuthR.services.auth.onCheckUser();

    if (!userResponse) {
      this.navigate('/');
      return;
    }

    const organization = await userManagementR.services.organization.getCurrentOrganization(true);

    const organizationMember = await userManagementR.services.organization.getOrganizationMember(organization.id, userResponse.email);

    const userManagementFunctionalityAvailability = userManagementR.services.moduleFunctionalityPermissions.getFunctionalityPermissions(organizationMember.role, organization?.role, organization?.status);

    this.dispatch(userManagementR.actions.moduleFunctionalityPermissions.setPermissions(userManagementFunctionalityAvailability));

    if (!hasReadAccess(userManagementFunctionalityAvailability.users.page)) {
      this.navigate('/');
    }

    this.dispatch(userManagementR.actions.users.setIsLoading(false));
  }

  public async downloadUsersXml() {
    new DownloadFileUseCase(this).execute(
      'all_users.xlsx',
      await userManagementR.services.users.downloadUsersXml(),
    );
  }
}
