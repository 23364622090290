import React, {
  FC, useContext,
} from 'react';
import { useTranslation } from 'react-i18next';

import {
  InputComponent,
} from 'user-management/view/pages/Account/components/AccountDrawer/components/UserEditProfile/components/Input/Input.component';
import { observer } from 'mobx-react-lite';
import { UC } from 'user-management/controllers';
import { MobxStoresContext } from 'app-wrapper/mobxStores';

export const FirstNameContainer: FC = observer(() => {
  const { userEditProfileStore } = useContext(MobxStoresContext);
  const { isLoading } = userEditProfileStore.state;

  const { t } = useTranslation();

  return (
    <InputComponent
      title={t('First Name *')}
      dataClass="FirstNameInput"
      isLoading={isLoading}
      value={userEditProfileStore.state.firstName}
      error={userEditProfileStore.state.firstNameError}
      onChange={UC.userEditProfile.setFirstName}
      onBlur={UC.userEditProfile.onBlurFirstName}
    />
  );
});
