import React, { FC, useCallback } from 'react';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';

import { Button } from 'app-wrapper/view/components';
import { ContainerDocumentDTM } from 'shipment-operations/models/dtm';

import { FooterWrapper } from './Footer.styled';

interface FooterProps {
  onCancel: (shipmentId: string) => void;
  onSubmit: (shipmentId: string, serviceId: string) => void;
  document: ContainerDocumentDTM | null;
}

export const FooterComponent: FC<FooterProps> = ({
  onSubmit,
  onCancel,
  document,
}) => {
  const { shipmentId = '', serviceId = '' } = useParams();
  const { t } = useTranslation();

  const handleSubmit = useCallback(() => {
    onSubmit(shipmentId, serviceId);
  }, [onSubmit, serviceId, shipmentId]);

  const handleCancel = useCallback(() => {
    onCancel(shipmentId);
  }, [onCancel, shipmentId]);

  const hasDocumentError = Boolean(document && typeof document?.response === 'string');

  return (
    <FooterWrapper>
      <Button
        onClick={handleCancel}
        type="dashed"
        style={{
          marginRight: '8px',
        }}
      >
        {t('Cancel')}
      </Button>

      <Button onClick={handleSubmit} disabled={!document || !document.response || hasDocumentError}>
        {t('Upload')}
      </Button>
    </FooterWrapper>
  );
};
