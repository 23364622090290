import React from 'react';
import { useSelector } from 'react-redux';

import { UC } from 'app-wrapper/controllers';
import { R } from 'app-wrapper/repository';

import { FooterComponent } from './Footer.component';

const FooterContainer = () => {
  const isLoading = useSelector(R.selectors.agreements.getIsLoading);
  const IsNRAConfirmed = useSelector(R.selectors.agreements.getIsNRAChecked);
  const IsTermsAndConditionsConfirmed = useSelector(R.selectors.agreements.getIsTermsChecked);
  const isNRARequired = useSelector(R.selectors.agreements.getIsNRARequired);

  const isAbleToAccept = IsTermsAndConditionsConfirmed && ((isNRARequired && IsNRAConfirmed) || !isNRARequired);

  return (
    <FooterComponent
      isLoading={isLoading}
      isAbleToAccept={isAbleToAccept}
      acceptSecondStep={UC.agreements.agreeWithNRAandTerms}
      closeDrawer={UC.agreements.closeAgreementsDrawer}
    />
  );
};

export { FooterContainer as Footer };
