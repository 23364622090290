import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  Wrap,
  Content,
  Image,
  Title,
  Text,
} from './OrganizationActive.styled';
import illustrationSrc from './assets/illustration.png';

export const OrganizationActive = () => {
  const { t } = useTranslation();

  return (
    <Wrap>
      <Content>
        <Title>
          {t('Your organization has been successfully approved')}
        </Title>

        <Text>
          {t('You have successfully confirmed your organization and we have opened access to all the features of the platform.')}
        </Text>
      </Content>

      <Image src={illustrationSrc} />
    </Wrap>
  );
};
