import styled from 'styled-components';
import AntDivider from 'antd/es/divider';

import { FlexRow } from 'app-wrapper/view/guideline';

export const Content = styled(FlexRow)`
  gap: 16px;
  margin-bottom: 8px;
  padding: 24px;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.themesColors.neutralBranding3};
  justify-content: space-between;
`;

export const StepTitle = styled.span`
  min-width: 182px;
`;

export const Divider = styled(AntDivider)`
  min-width: unset;
`;
