import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { CargoDTM } from 'shipment-operations/models/dtm';
import { CargoTitleText } from './CargoFormTitle.styled';

interface ICargoFormTitleComponentProps {
  description?: CargoDTM['description']
  name?: CargoDTM['name']
}

export const CargoFormTitleComponent: FC<ICargoFormTitleComponentProps> = ({
  description,
  name,
}) => {
  const { t } = useTranslation();
  const titleText = description || name;

  return (
    <CargoTitleText gray={+!titleText}>
      {titleText || t('Add cargo details')}
    </CargoTitleText>
  );
};
