import { R } from 'monetary/repository';
import React, {
  FC,
} from 'react';
import { useSelector } from 'react-redux';
import { CustomerComponent } from './Customer.component';

interface ICustomerComponentProps {
  formLocalState: { isCollapsed?: boolean }
  forceCollapse?: boolean
}

export const Customer: FC<ICustomerComponentProps> = (({
  formLocalState,
  forceCollapse,
}) => {
  const {
    isSubmitVisited,
    isSubmitClicked,
    isAllFieldDisabled,
    isCollapse,
    RFRById,
  } = useSelector(R.selectors.freightQuote.getFreightQuoteCurrent);

  return (
    <CustomerComponent
      formLocalState={formLocalState}
      isAllDisabled={isAllFieldDisabled}
      forceCollapse={forceCollapse}
      isCollapsed={isCollapse}
      customerName={RFRById?.customerName}
      customerEmail={RFRById?.createdBy}
      isSubmitVisited={isSubmitVisited}
      isSubmitClicked={isSubmitClicked}
    />
  );
});
