import { BaseController, controller } from 'proto/BaseController';
import { RouteNames } from 'app-wrapper/constants';

@controller
export class ShipmentTransportationOverviewController extends BaseController {
  onNoAccess = (shipmentId: string) => {
    this.navigate(RouteNames.SHIPMENT_TRANSPORTATION_TRACKER(shipmentId));
  }

  onNoAccessOceanBooking = (shipmentId: string) => {
    this.navigate(RouteNames.SHIPMENT_TRANSPORTATION_OCEAN_BOOKING_TRACKER(shipmentId));
  }
}
