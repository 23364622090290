import React, { FC } from 'react';
import Tabs from 'antd/es/tabs';
import { useTranslation } from 'react-i18next';

import { RFQItemDetailRoutingComponent } from 'monetary/view/components/RFQResponse/RFQItemDetailRouting';
import {
  SubPageTabs, FeesAndServicesChargeChangesTable, ContainersChargesChangesTableComponent, SubPageContent,
} from 'app-wrapper/view/guideline';
import { DetailRoutingDTM } from 'monetary/models/dtm';
import { ContainerWithChangeChargesDTM, ShipmentChargeChangeDTM } from 'app-wrapper/models/dtm';

interface IExpandRenderComponentProps {
  origin: DetailRoutingDTM[]
  freight: DetailRoutingDTM[]
  destination: DetailRoutingDTM[]
  chargesTableBol: ShipmentChargeChangeDTM[]
  chargesTableContainers: ContainerWithChangeChargesDTM[]
}

const ExpandRenderComponent: FC<IExpandRenderComponentProps> = ({
  origin, freight, destination, chargesTableContainers, chargesTableBol,
}) => {
  const { t } = useTranslation();

  return (
    <SubPageTabs>
      <Tabs.TabPane tab={t('Routing')} key="1">
        <RFQItemDetailRoutingComponent
          origin={origin}
          freight={freight}
          destination={destination}
        />
      </Tabs.TabPane>
      <Tabs.TabPane tab={t('Charges')} key="2">
        <SubPageContent style={{ padding: 0, paddingTop: '16px' }}>
          <ContainersChargesChangesTableComponent dataSource={chargesTableContainers} />
          <FeesAndServicesChargeChangesTable charges={chargesTableBol} />
        </SubPageContent>
      </Tabs.TabPane>
    </SubPageTabs>
  );
};

export { ExpandRenderComponent };
