import styled from 'styled-components';

import { divFlex } from 'app-wrapper/view/themes/mixin';
import Table from 'antd/es/table';

export const SDHLayout = styled.div.attrs({
  'data-class': 'SDHLayout',
})`
  display: flex;
  overflow-y: scroll;
  flex-direction: column;
  padding: 16px;
  background-color: ${({ theme }) => theme?.themesColors?.neutralBranding1};
  border-radius: 3px;
`;

export const SDHTitle = styled.div`
  width: 100%;
  font-size: 16px;
  font-weight: 500;
  padding-bottom: 14px;
  border-bottom: 1px solid  ${({ theme }) => theme.themesColors.neutralBranding3};
`;

export const SDHLoaderWrapper = styled.div.attrs({
  'data-class': 'SDHLoaderWrapper',
})`
  ${divFlex}
  padding-top: 10px;
  align-items: center;
  justify-content: center;
`;

export const SDHTable = styled(Table)`
  .ant-table-thead > tr > th {
    background: white;
  }

  .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    display: none;
  }

  .ant-table-thead .ant-table-cell {
    color: #73819B;
    font-size: 12px;
    font-weight: 400;
  }
`;
