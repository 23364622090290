import styled from 'styled-components';
import InputNumber from 'antd/es/input-number';
import Typography from 'antd/es/typography';

import { Input, Select } from 'app-wrapper/view/components';
import { divFlex } from 'app-wrapper/view/themes/mixin';

export const Content = styled.div.attrs({
  'data-class': 'TradeReferencesComponentContent',
})`
  height: 100%;
  display: flex;
  flex-direction: column;
  transition: .2s height ease;
  overflow: hidden;
`;

export const Field = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const FieldSelect = styled(Select).attrs((props: {
  isError: boolean
  'data-class'?: string
}) => ({
  isError: props.isError,
  'data-class': props?.['data-class'] || 'TradeReferencesComponentFieldSelect',
}))`
  .ant-select-selector {
    ${({ isError, theme }) => isError && `border-color: ${theme.themesColors.characterBrandingDanger} !important;`};
    ${({ isError }) => isError && 'z-index: 2;'};
  }

  input {
    color: ${({ theme }) => theme.themesColors.neutralBranding10};
  }
`;

export const StyledInput = styled(Input)`
  width: 100%;
  font-size: 12px;
  font-weight: 400;
  color: ${({ theme }) => theme?.themesColors?.neutralBranding10};
  min-height: 32px;
`;

export const StyledInputNumber = styled(InputNumber).attrs((props: {
  isError: boolean
  isValue: boolean
  'data-class'?: string
}) => ({
  isError: props.isError,
  isValue: props.isValue,
  'data-class': props?.['data-class'] || 'TradeReferencesComponentStyledInputNumber',
}))`
  width: 100%;
  font-size: 12px;
  ${({ isError, theme }) => isError && `border-color: ${theme.themesColors.characterBrandingDanger} !important;`};
  ${({ isError }) => isError && 'z-index: 2;'};
  .ant-input-prefix {
    ${({ isValue, theme }) => isValue && `color: ${theme.themesColors.secondaryDot45};`};
  }
`;

export const Label = styled(Typography).attrs((props: {
  marginTop: string
}) => ({
  marginTop: props.marginTop,
  'data-class': 'TradeReferencesComponentStyledLabel',
}))`
  ${divFlex}

  ${({ marginTop }) => marginTop && `margin-top: ${marginTop}`};
  margin-bottom: 4px;
  font-size: 12px;
  line-height: 22px;
`;

export const LabelSpan = styled.span`
  ${divFlex}

  color: ${({ theme }) => theme?.themesColors?.secondaryDot45};
  margin-left: 4px;
  font-size: 12px;
  line-height: 22px;
`;
