import React, { FC } from 'react';

import { IReferenceTagsProps, ReferenceTags } from 'app-wrapper/view/components';

import { CargoReferenceTypeNamesShort } from 'shipment-operations/constants';
import { CargoReferenceDTM } from 'shipment-operations/models/dtm';

import { Divider } from './CargoCardMiddle.styled';

interface ICargoCardMiddleProps {
  references: CargoReferenceDTM[]
}

export const CargoCardMiddle: FC<ICargoCardMiddleProps> = ({ references }) => {
  const filteredRefs = references.filter((reference) => !!(reference.type && reference.value));

  if (!filteredRefs.length) {
    return <Divider dashed />;
  }

  return (
    <>
      <Divider dashed />
      <ReferenceTags
        referenceTypeNamesMap={CargoReferenceTypeNamesShort}
        references={references as IReferenceTagsProps['references']}
      />
      <Divider dashed />
    </>
  );
};
