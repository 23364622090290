import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';
import { UC } from 'shipment-operations/controllers';

import { ShipmentComponent } from './Shipment.component';

const ShipmentContainer: FC = () => {
  const isSubmittedChanges = useSelector(R.selectors.shipmentChanges.getIsChangedConfirm);
  const shouldRefresh = useSelector(R.selectors.shipmentChanges.getShouldUpdateShipment);

  return (
    <ShipmentComponent
      onUpload={UC.shipment.loadData}
      onClear={UC.shipment.clearState}
      isSubmittedChanges={isSubmittedChanges}
      shouldRefresh={shouldRefresh}
    />
  );
};

export {
  ShipmentContainer as Shipment,
};
