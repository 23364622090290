import React, { FC, memo } from 'react';
import { useSelector } from 'react-redux';

import { R } from 'user-management/repository';

import { OrganizationCoverageAreasComponent } from 'user-management/view/pages/OrganizationProfile/components/OrganizationCovarageAreas/OrganizationCoverageAreas.component';
import { hasReadAccess } from 'app-wrapper/utils';

export const OrganizationCoverageAreasContainer: FC = memo(() => {
  const organization = useSelector(R.selectors.userOrganizationData.getUserOrganization);
  const accountOrganizationProfile = useSelector(R.selectors.moduleFunctionalityPermissions.getAccountOrganizationProfilePermissions);

  if (!hasReadAccess(accountOrganizationProfile?.coverageAreas)) {
    return null;
  }

  return (
    <OrganizationCoverageAreasComponent
      coverageAreas={organization?.coverageAreas}
    />
  );
});
