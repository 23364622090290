import { BaseUseCase } from 'app-wrapper/usecases/Base.useCase';

import { UC as shipmentUC } from 'shipment-operations/controllers';

import { UC } from 'monetary/controllers';
import { AdditionalServiceCheckedDTM } from 'monetary/models/dtm/Quotas';

export class AdditionalDrawersUseCase extends BaseUseCase {
  public resetStateAddAdditionalServicesDrawer() {
    this.mobxStores.additionalServicesDrawerStore.setAddAdditionalServicesDrawerServices(undefined);
    this.mobxStores.additionalServicesDrawerStore.setAddAdditionalServicesDrawerServicesChecked(undefined);
    this.mobxStores.additionalServicesDrawerStore.setAddAdditionalServicesDrawerBookingRFQFormState(undefined);
    this.mobxStores.additionalServicesDrawerStore.setAddAdditionalServicesDrawerServicesBookingChecked(undefined);
    this.mobxStores.additionalServicesDrawerStore.setAddAdditionalServicesDrawerServicesGetQuote(undefined);
    this.mobxStores.additionalServicesDrawerStore.setAddAdditionalServicesDrawerServicesBooking(undefined);
  }

  public resetBySubmitStateAddAdditionalServicesBookingDrawer() {
    const addAdditionalServicesDrawer = this.mobxStores.additionalServicesDrawerStore.state.servicesGetQuote?.map((item) => ({ ...item }));
    const addAdditionalServicesDrawerChecked = this.mobxStores.additionalServicesDrawerStore.state.servicesGetQuote?.map((item) => ({ ...item }));

    this.mobxStores.additionalServicesDrawerStore.setAddAdditionalServicesDrawerServicesBooking(addAdditionalServicesDrawer);
    this.mobxStores.additionalServicesDrawerStore.setAddAdditionalServicesDrawerServicesBookingChecked(addAdditionalServicesDrawerChecked);
  }

  public setStartAdditionalServiceDrawer(rateService: AdditionalServiceCheckedDTM[]) {
    this.controller.mobxStore.additionalServicesDrawerStore.clear();
    UC.AdditionalServices.setStartAdditionalServiceDrawer(rateService);
  }

  public updateShipmentAdditionalPage(shipmentId: string) {
    this.mobxStores.additionalServicesDrawerStore?.setAddAdditionalServicesDrawerServices(undefined);
    shipmentUC.shipmentServices.initServices(shipmentId);
  }
}
