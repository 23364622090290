import React, { FC } from 'react';

import { useAppSelector } from 'app-wrapper/hooks';
import { R } from 'shipment-operations/repository';
import { UC } from 'shipment-operations/controllers';
import { SecondStepComponent } from './SecondStep.component';

interface ISecondStepProps {
  onClickBack: () => void
  onCloseCreditNote: () => void
}

const SecondStepContainer: FC<ISecondStepProps> = ({ onClickBack, onCloseCreditNote }) => {
  const error = useAppSelector(R.selectors.shipmentBillingInvoice.getCreditNoteError);
  const errorTitle = useAppSelector(R.selectors.shipmentBillingInvoice.getCreditNoteErrorTitle);
  const isLoading = useAppSelector(R.selectors.shipmentBillingInvoice.getCreditNoteLoading);
  const success = useAppSelector(R.selectors.shipmentBillingInvoice.getCreditNoteSuccess);

  return (
    <SecondStepComponent
      onClickBack={onClickBack}
      postData={UC.shipmentBillingInvoice.createCreditNote}
      error={error}
      resetError={UC.shipmentBillingInvoice.resetError}
      isLoading={isLoading}
      success={success}
      onCloseCreditNote={onCloseCreditNote}
      errorTitle={errorTitle}
    />
  );
};

export { SecondStepContainer as SecondStep };
