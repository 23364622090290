import React from 'react';

import { IPropsIcon } from 'app-wrapper/interfaces/icon';

export const WarningSvg = (props: IPropsIcon) => {
  const {
    width, height, style, fill,
  } = props;

  return (
    <svg
      className="WarningSvg"
      width={width || '14'}
      height={height || '15'}
      viewBox="0 0 14 15"
      color={fill || '#FF4D4F'}
      style={style || undefined}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_893_93413)">
        <path d="M13.0666 12.2031L7.37906 2.35938C7.29429 2.21309 7.148 2.14062 7.00035 2.14062C6.85269 2.14062 6.70503 2.21309 6.62163 2.35938L0.934136 12.2031C0.765971 12.4957 0.976518 12.8594 1.31285 12.8594H12.6878C13.0242 12.8594 13.2347 12.4957 13.0666 12.2031ZM6.56285 6.1875C6.56285 6.12734 6.61206 6.07812 6.67222 6.07812H7.32847C7.38863 6.07812 7.43784 6.12734 7.43784 6.1875V8.70312C7.43784 8.76328 7.38863 8.8125 7.32847 8.8125H6.67222C6.61206 8.8125 6.56285 8.76328 6.56285 8.70312V6.1875ZM7.00035 11C6.82862 10.9965 6.6651 10.9258 6.54489 10.8031C6.42468 10.6804 6.35735 10.5155 6.35735 10.3438C6.35735 10.172 6.42468 10.0071 6.54489 9.88438C6.6651 9.76169 6.82862 9.69101 7.00035 9.6875C7.17207 9.69101 7.33559 9.76169 7.4558 9.88438C7.57601 10.0071 7.64334 10.172 7.64334 10.3438C7.64334 10.5155 7.57601 10.6804 7.4558 10.8031C7.33559 10.9258 7.17207 10.9965 7.00035 11Z" fill=" currentColor" />
      </g>
      <defs>
        <clipPath id="clip0_893_93413">
          <rect width="14" height="14" fill="white" transform="translate(0 0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
};
