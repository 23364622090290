import React, {
  FC, useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';
import FilterFilled from '@ant-design/icons/FilterFilled';

import {

  ICompanyAddressDTM, IContactBookDefaultStateCompanyListStateDTM,
} from 'user-management/models/dtm';

import { PlusSvg } from 'app-wrapper/view/icons';
import { EmptyStateForPages, TableSkeleton } from 'app-wrapper/view/components';
import themesColors from 'app-wrapper/view/themes/themesColors';

import { Layout } from 'app-wrapper/view/guideline';
import {
  ContactBookListTable,
} from './components';

import {
  CBContentBodyList,
  CBLayoutHeader,
  CBWrapper,
  HeaderWrapper,
} from './styled';
import { ContactBookListTableHeaderAddBtn, ContactBookListTableTitleWrap } from './components/ContactBookListTable/ContactBookListTable.styled';
import { AccountDrawer } from '../Account/components';

interface IContactBookComponentProps {
  companyListState: IContactBookDefaultStateCompanyListStateDTM[]
  companyListAddresses?: ICompanyAddressDTM[]
  isLoading: boolean
  isLoadingNextPage: boolean
  hasNextPage?: boolean
  runComponent: () => void
  clickListItem: (companyId: string) => () => void
  onOpenContactBookAddContact: () => void
  ContactBookNextPage: () => void
}

export const ContactBookComponent: FC<IContactBookComponentProps> = (props) => {
  const {
    companyListState,
    companyListAddresses,
    isLoading,
    isLoadingNextPage,
    hasNextPage,
    runComponent,
    clickListItem,
    onOpenContactBookAddContact,
    ContactBookNextPage,
  } = props;
  const { t } = useTranslation();

  useEffect(runComponent, []);

  return (
    <CBWrapper>
      <AccountDrawer />
      <HeaderWrapper>
        <CBLayoutHeader
          title={t('Contact Book')}
          extra={(
            <ContactBookListTableTitleWrap>
              <ContactBookListTableHeaderAddBtn
                onClick={onOpenContactBookAddContact}
              >
                <PlusSvg />
                <span>{t('Add Company')}</span>
              </ContactBookListTableHeaderAddBtn>
            </ContactBookListTableTitleWrap>
          )}
          ghost={false}
        />
      </HeaderWrapper>
      <Layout>
        {isLoading
          ? (<TableSkeleton />)
          : null}
        {!isLoading && (companyListState.length || isLoadingNextPage)
          ? (
            <CBContentBodyList>
              <ContactBookListTable
                allData={companyListState}
                companyListAddresses={companyListAddresses}
                onClickCompanyItem={clickListItem}
                ContactBookNextPage={ContactBookNextPage}
                onStart={() => ''}
                isLoadAdd={isLoadingNextPage}
                isLoading={isLoading}
                hasNextPage={hasNextPage}
              />
            </CBContentBodyList>
          )
          : null}

        {!companyListState.length && !isLoading && !isLoadingNextPage ? (
          <EmptyStateForPages
            icon={(
              <FilterFilled
                width={28}
                height={22}
                style={{
                  color: themesColors.neutralBranding65,
                  width: '28px',
                  height: '22px',
                }}
              />
            )}
            headerText={t('No contacts yet.')}
            contentText={t('It seems like your contact book is empty. Start adding your contacts to easily manage and access them.')}
            buttonText={t('Create contact')}
            onClickButton={onOpenContactBookAddContact}
          />
        ) : null}
      </Layout>
    </CBWrapper>
  );
};
