import React, { FC } from 'react';

import { MissingTextValue } from 'app-wrapper/view/guideline';
import themesColors from 'app-wrapper/view/themes/themesColors';
import {
  BiggerPriceIcon,
  DifferencePrice,
  Row,
  SmallerPriceIcon,
} from 'shipment-operations/view/components/RollShipmentDrawer/components/RollOption/RollOption.styled';

import { Wrap } from './CostChangeDifference.styled';

interface CostChangeDifferenceProps {
  prevCost: number;
  newCost: number;
}

export const CostChangeDifference: FC<CostChangeDifferenceProps> = ({
  prevCost,
  newCost,
}) => {
  const priceDifferenceAmount = Math.abs(prevCost - newCost);
  const arePricesEquals = newCost === prevCost;
  const isBiggerThanTotalCost = (prevCost - newCost) < 0;

  return (
    <Wrap>
      {!arePricesEquals ? (
        <Row>
          {isBiggerThanTotalCost ? <BiggerPriceIcon style={{ color: themesColors.yellowBranding8 }} /> : <SmallerPriceIcon style={{ color: themesColors.characterBrandingSuccess65 }} />}

          <DifferencePrice
            style={{
              color: isBiggerThanTotalCost ? themesColors.yellowBranding8 : themesColors.characterBrandingSuccess65,
            }}
          >
            $ {priceDifferenceAmount.toFixed(2)}
          </DifferencePrice>
        </Row>
      ) : <MissingTextValue />}
    </Wrap>
  );
};
