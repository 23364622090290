import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Wrap,
  Rejected,
  RejectedStub,
  RejectedText,
  Content,
  Reason,
  ReasonText,
  ReasonTitle,
  AdminCommentWrap,
  AdminComment,
  AdminCommentText,
  AdminCommentTitle,
  CommentIcon,
} from './AccountRejected.styled';
import commentSrc from './assets/comment.svg';

interface AccountRejectedComponentProps {
  title: string;
  subtitle: string;
  adminComment?: string;
}

export const AccountRejected: FC<AccountRejectedComponentProps> = ({
  title,
  subtitle,
  adminComment,
}) => {
  const { t } = useTranslation();

  return (
    <Wrap>
      <Rejected>
        <RejectedStub />

        <RejectedText>
          {t('Account Rejected')}
        </RejectedText>
      </Rejected>

      <Content>
        <Reason>
          <ReasonTitle>
            {title}
          </ReasonTitle>

          <ReasonText>
            {subtitle}
          </ReasonText>
        </Reason>

        {adminComment ? (
          <AdminCommentWrap>
            <CommentIcon src={commentSrc} />

            <AdminComment>
              <AdminCommentTitle>
                {t('Administrator\'s Сomment')}
              </AdminCommentTitle>

              <AdminCommentText>
                {adminComment}
              </AdminCommentText>
            </AdminComment>
          </AdminCommentWrap>
        ) : null}
      </Content>
    </Wrap>
  );
};
