import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { WarningBlock } from 'app-wrapper/view/components';
import { OrganizationDTM, EOrganizationStatus } from 'user-management/models/dtm';

interface OrganizationWarningComponentProps {
  organization?: OrganizationDTM;
}

export const OrganizationWarningComponent: FC<OrganizationWarningComponentProps> = ({ organization }) => {
  const { t } = useTranslation();

  if (!organization || organization?.status !== EOrganizationStatus.WAITING_APPROVAL) {
    return null;
  }

  return (
    <WarningBlock
      title={t('We are currently verifying your Organization.')}
      text={t('Your organization’s details will be reviewed within 2 days. We will contact you via email, and if the review is successful, you will be officially registered on the platform.')}
    />
  );
};
