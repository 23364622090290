import React from 'react';
import { useSelector } from 'react-redux';

import i18n from 'app-wrapper/i18n/i18n';
import { R as UserR } from 'user-management/repository';
import { R } from 'app-wrapper/repository';
import { UC } from 'app-wrapper/controllers';

import { SkypaceTermsAndConditionsComponent } from './SkypaceTermsAndConditions.component';

const SkypaceTermsAndConditionsContainer = () => {
  const isChecked = useSelector(R.selectors.agreements.getIsTermsChecked);
  const currentOrg = useSelector(UserR.selectors.userOrganizationData.getUserOrganization);
  const adminAddress = useSelector(R.selectors.agreements.getCurrentOrgRelatedAdminAddress);
  const bookingDepartment = useSelector(R.selectors.agreements.getAccountHolderBookingOpsDepartment);
  const headerData = useSelector(R.selectors.agreements.getSkypaceAdminData(bookingDepartment, i18n.t('Booking')));

  return (
    <SkypaceTermsAndConditionsComponent
      isChecked={isChecked}
      header={{
        company: headerData,
      }}
      createdByName={isChecked ? currentOrg?.getPrimaryFullName() : undefined}
      adminAddress={adminAddress}
      onChange={UC.agreements.setIsTermsChecked}
      fetchDataBeforePrinting={UC.agreements.fetchDataBeforePrinting}
    />
  );
};

export { SkypaceTermsAndConditionsContainer as SkypaceTermsAndConditions };
