import { RootState } from 'app-wrapper/store';
import { createSelector } from 'reselect';

const localState = (state: RootState) => state.shipmentNewContainer;

const getSelectedType = createSelector(
  localState,
  (state) => state.newContainerType,
);

const getTypeError = createSelector(
  localState,
  (state) => state.newContainerTypeError,
);

const getSelectedQTY = createSelector(
  localState,
  (state) => state.newContainerQTY,
);

const getQTYError = createSelector(
  localState,
  (state) => state.newContainerQTYError,
);

const isDataChanged = createSelector(
  localState,
  (state) => !!state.newContainerType || !!state.newContainerQTY,
);

const getLoadingState = createSelector(
  localState,
  (state) => state.isLoading,
);

const getAddContainerModalState = createSelector(
  localState,
  (state) => state.isAddContainerModalOpen,
);

export const shipmentNewContainerSelectors = {
  getAddContainerModalState,
  getLoadingState,
  getQTYError,
  getSelectedQTY,
  getSelectedType,
  getTypeError,
  isDataChanged,
};
