import React, { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Option,
  Select,
  Tooltip,
} from 'app-wrapper/view/components';
import {
  BillOfLadingType,
  BillOfLadingTypeNames,
  PermissionAttributePolicy,
} from 'shipment-operations/constants';

import {
  BillOfLadingLabel,
  BillOfLadingTypeSelectContainer,
  InfoIcon,
} from './BillOfLadingTypeSelect.styled';

const NOT_DEPARTED_VESSEL_OPTIONS = [BillOfLadingType.EBL, BillOfLadingType.OBO, BillOfLadingType.OBR];

interface IBillOfLadingTypeSelectComponentProps {
  billOfLadingType?: BillOfLadingType
  initialBLType?: BillOfLadingType
  setBillOfLadingType: (type: BillOfLadingType) => void
  touchField: (field: string) => void
  billOfLadingPermission?: PermissionAttributePolicy
  isVesselDeparted: boolean;
  isEnoughTimeBeforeVesselArrival: boolean;
}

export const BillOfLadingTypeSelectComponent: FC<IBillOfLadingTypeSelectComponentProps> = ({
  billOfLadingType,
  initialBLType,
  setBillOfLadingType,
  touchField,
  billOfLadingPermission,
  isVesselDeparted,
  isEnoughTimeBeforeVesselArrival,
}) => {
  const { t } = useTranslation();

  const touchTypeSelect = useCallback(() => {
    touchField('type');
  }, []);

  const isDisabled = useMemo(() => billOfLadingPermission !== PermissionAttributePolicy.WRITE || (!isEnoughTimeBeforeVesselArrival && initialBLType === BillOfLadingType.EBL), [billOfLadingPermission, isEnoughTimeBeforeVesselArrival, initialBLType]);

  const options = useMemo(() => {
    if (!isVesselDeparted) {
      return NOT_DEPARTED_VESSEL_OPTIONS;
    }

    if (initialBLType === BillOfLadingType.OBO || initialBLType === BillOfLadingType.OBR) {
      return [
        ...(initialBLType ? [initialBLType] : []),
        BillOfLadingType.TEL,
      ];
    }

    if (initialBLType === BillOfLadingType.EBL && isEnoughTimeBeforeVesselArrival) {
      return [initialBLType, BillOfLadingType.OBO, BillOfLadingType.OBR];
    }

    return initialBLType ? [initialBLType] : [];
  }, [isVesselDeparted, isEnoughTimeBeforeVesselArrival, initialBLType]);

  return (
    <BillOfLadingTypeSelectContainer>
      <BillOfLadingLabel>
        {t('Type')}

        {!isEnoughTimeBeforeVesselArrival && initialBLType === BillOfLadingType.EBL ? (
          <Tooltip
            title={t('Type cannot be updated as there are less than 7 days left until the vessel\'s arrival at the Port of Discharge. Please contact your account manager for further actions.')}
          >
            <InfoIcon />
          </Tooltip>
        ) : null}
      </BillOfLadingLabel>

      <Select
        value={billOfLadingType}
        onChange={setBillOfLadingType}
        onBlur={touchTypeSelect}
        disabled={isDisabled}
      >
        {options.map((type) => (
          <Option key={type} value={type}>
            {BillOfLadingTypeNames[type]}
          </Option>
        ))}
      </Select>
    </BillOfLadingTypeSelectContainer>
  );
};
