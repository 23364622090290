import React, { FC, ReactNode } from 'react';

import {
  NewOrganizationWizardWrapperScaffoldContainer,
  NewOrganizationWizardWrapperScaffoldContent,
  NewOrganizationWizardWrapperScaffoldAside,
  NewOrganizationWizardWrapperScaffoldHeader,
} from './NewOrganizationWizardWrapperScaffold.styled';

interface NewOrganizationWizardWrapperScaffoldProps {
  aside: ReactNode
  content: ReactNode
  header: ReactNode
}

export const NewOrganizationWizardWrapperScaffold: FC<NewOrganizationWizardWrapperScaffoldProps> = ({
  aside,
  content,
  header,
}) => (
  <NewOrganizationWizardWrapperScaffoldContainer>
    <NewOrganizationWizardWrapperScaffoldHeader>
      {header}
    </NewOrganizationWizardWrapperScaffoldHeader>
    <NewOrganizationWizardWrapperScaffoldContent>
      {content}
    </NewOrganizationWizardWrapperScaffoldContent>
    <NewOrganizationWizardWrapperScaffoldAside>
      {aside}
    </NewOrganizationWizardWrapperScaffoldAside>
  </NewOrganizationWizardWrapperScaffoldContainer>
);
