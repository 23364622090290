import styled from 'styled-components';

import { colors } from 'app-wrapper/view/themes/themesColors';

interface IShipmentDetailsCardSmallProps {
  selected?: boolean,
  disabled?: boolean,
  hasError?: boolean,
}

export const ShipmentDetailsCardSmall = styled.div<IShipmentDetailsCardSmallProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  width: 100%;
  height: 72px;
  padding: 28px 16px;

  border: 1px solid ${colors.purple15};
  border-radius: 3px;

  cursor: ${({ onClick, disabled }) => {
    if (disabled) {
      return 'not-allowed';
    }

    if (onClick) {
      return 'pointer';
    }

    return 'default';
  }};
  background: ${colors.white};

  ${({ selected, disabled }) => {
    if (selected && !disabled) {
      return `box-shadow: 0px 4px 4px rgba(32, 44, 60, 0.12), inset 5px 0px 0px ${colors.boxShadow}`;
    }

    if (selected && disabled) {
      return 'box-shadow: 0px 4px 4px rgba(32, 44, 60, 0.12), inset 5px 0px 0px rgba(32, 44, 60, .4)';
    }

    return '';
  }};
  ${({ disabled }) => (disabled ? 'background-color: rgb(250, 250, 250);' : '')};
  
  transition: background-color linear .3s;

  font-size: 12px;
  color: #73819B;
`;
