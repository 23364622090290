import styled from 'styled-components';
import { InfoCircleOutlined } from '@ant-design/icons';
import Typography from 'antd/es/typography';

export const Content = styled.div`
  width: 100%;
  height: calc(100vh - 56px);
  padding-bottom: 100px;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const Warning = styled.div`
  display: flex;
  margin-bottom: 8px;
  width: 100%;
  padding: 8px;
  flex-direction: row;
  align-items: center;
  background-color: #FFFBE6;
  border: 1px solid #FFE58F;
  border-radius: 2px;
`;

export const WarningIcon = styled(InfoCircleOutlined)`
  margin-right: 8px;
  color: #FAAD14;
  font-size: 16px;
`;

export const WarningText = styled(Typography)`
  margin-top: 2px;
  font-size: 13px;
  line-height: 22px;
`;
