import styled from 'styled-components';
import Divider from 'antd/es/divider';
import AntDrawer from 'antd/es/drawer';

import { colors } from 'app-wrapper/view/themes/themesColors';

export const TrackerHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  padding: 16px 16px 8px 16px;

  background: #FFFFFF;
  border: 1px solid ${colors.purple15};
  border-radius: 3px;
`;

export const TitleWrapper = styled.div`
  margin-bottom: 8px;
  height: 25px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const TrackerHeaderWaypoints = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const TrackerHeaderDivider = styled(Divider)`
  margin: 0;
  margin-top: 8px;
`;

export const EditWrapper = styled.div`
  border: 1.5px dashed #DEE1E9;
  padding: 2px 4px;
  font-size: 12px;
  position: absolute;
  right: 0;
  &:hover {
    cursor: pointer;
  }
`;

export const ShipmentDrawerWrapper = styled(AntDrawer)`
  .ant-drawer-header-title {
    flex-direction: row-reverse;
  }

  .ant-drawer-body{
    background-color: #F4F5F8;
  }

  .ant-drawer-close {
    margin-right: 0;
  }
`;
