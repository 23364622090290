import React, { FC, useEffect } from 'react';
import { useParams } from 'react-router';
import Spinner from 'antd/es/spin';

import { ShipmentDrawer } from 'shipment-operations/view/pages/Shipment/components';

import {
  Wrap,
  SpinnerWrap,
  ContentWrap,
} from './CustomerDocuments.styled';
import { Documents } from './components';

interface CustomerDocumentsComponentProps {
  initData: (customerId: string) => void;
  isLoading: boolean;
}

export const CustomerDocumentsComponent: FC<CustomerDocumentsComponentProps> = ({
  initData,
  isLoading,
}) => {
  const { customerId = '' } = useParams();

  useEffect(() => {
    initData(customerId);
  }, []);

  return (
    <Wrap>
      {isLoading ? (
        <SpinnerWrap>
          <Spinner size="large" />
        </SpinnerWrap>
      ) : (
        <ContentWrap>
          <Documents />
        </ContentWrap>
      )}

      <ShipmentDrawer />
    </Wrap>
  );
};
