import { ChargeCodeDTM, CreateChargeDTM, ShortContainerDTM } from 'shipment-operations/models/dtm';
import { apiWorker } from 'app-wrapper/repository/utilsServices';
import { GetChargeCodesContract, GetShortContainersContract } from 'shipment-operations/models/contracts';

export class ShipmentActiveChargeService {
  public getFullListCharges = async () => {
    let result: ChargeCodeDTM[] | null;

    try {
      const response = await apiWorker.requestGet<GetChargeCodesContract[]>('/rate-service/api/v1/charge-codes');

      const parsedRes = response.data.map((item) => {
        const parsedItem = (ChargeCodeDTM.fromPlain({
          code: item.code,
          description: item.description,
          mode: item.mode,
          type: item.type,
          subType: item.subType,
          occurrence: item.occurrence,
          loadType: item.loadType,
          status: item.status,
          phases: item.phases,
        }));
        if (!parsedItem.isValid()) {
          console.error('Data from API does not match with contract');
        }
        return parsedItem;
      });
      result = parsedRes.filter((el) => el !== null) as ChargeCodeDTM[];
    } catch (e) {
      throw new Error('Something wrong, please try again');
    }
    return result;
  }

  public getContainers = async (shipmentId: string) => {
    let shipmentData: ShortContainerDTM[] | null;

    try {
      const rawResponse = await apiWorker.requestGet<GetShortContainersContract[]>(`/shipment-service/api/v1/shipments/${shipmentId}/containers`);

      const parsedResponse = rawResponse.data.map((item) => {
        const parsedItem = ShortContainerDTM.fromPlain({
          id: item.id,
          number: item.number,
          type: item.type,
        });
        if (!parsedItem.isValid()) {
          console.error('Data from API does not match with contract');
        }
        return parsedItem;
      });
      shipmentData = parsedResponse.filter((el) => el !== null) as ShortContainerDTM[];
    } catch (e) {
      throw new Error('Something wrong, please try again');
    }

    return shipmentData;
  }

  public onAddCharge = async (shipmentId: string, body: CreateChargeDTM) => {
    await apiWorker.requestPost(
      `/billing-service/api/v1/shipments/${shipmentId}/charge-pairs`,
      body,
    );
    return true;
  }

  public onAddChargeBulk = async (shipmentId: string, body: CreateChargeDTM[]) => {
    await apiWorker.requestPost(
      `/billing-service/api/v1/shipments/${shipmentId}/charge-pairs/bulk`,
      body,
    );
    return true;
  }

  public onEditCharge = async (shipmentId: string, body: CreateChargeDTM, chargeId?: number) => {
    await apiWorker.requestPut(
      `/billing-service/api/v1/shipments/${shipmentId}/charge-pairs/${chargeId}`,
      body,
    );
    return true;
  }

  public onDeleteCharge = async (shipmentId: string, chargeId: number | null) => {
    await apiWorker.requestDelete(
      `/billing-service/api/v1/shipments/${shipmentId}/charge-pairs/${chargeId}`,
    );
    return true;
  }
}
