import React from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';

import { SBLCargoTablesComponent } from './SBLCargoTables.component';

const SBLCargoTablesContainer = () => {
  const allCargoItems = useSelector(R.selectors.billOfLadingCommon.getAllCargoItems);
  const cargos = useSelector(R.selectors.billOfLadingCommon.getCargos);

  return (
    <SBLCargoTablesComponent
      allCargoItems={allCargoItems}
      cargos={cargos}
    />
  );
};

export { SBLCargoTablesContainer as SBLCargoTables };
