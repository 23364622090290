import React, { FC, useCallback } from 'react';
import DeleteOutlined from '@ant-design/icons/lib/icons/DeleteOutlined';
import { useTranslation } from 'react-i18next';

import { ContainerDTM } from 'shipment-operations/models/dtm';

import { ContainerTypesConst } from 'shipment-operations/constants';
import {
  ShipmentContainersFormTitleButton,
  ShipmentContainersFormTitleContainer,
  ShipmentContainersFormTitleFirstRow,
  ShipmentContainersFormTitleGrayName,
  ShipmentContainersFormTitleName,
} from './ShipmentContainersFormTitle.styled';

interface ICargoTitleComponentProps {
  isRemoveLoading: boolean
  container?: ContainerDTM
  onRemove: (shipmentId: string | undefined) => void
  disable: boolean
  shipmentId: string | undefined
}

export const ShipmentContainersFormTitleComponent: FC<ICargoTitleComponentProps> = ({
  isRemoveLoading,
  container,
  onRemove,
  disable,
  shipmentId,
}) => {
  const { t } = useTranslation();

  const onRemoveHandler = useCallback(() => {
    onRemove(shipmentId);
  }, [shipmentId]);

  const containerName = container?.name
    ? container.name
    : <ShipmentContainersFormTitleGrayName>({t('Number pending entry')})</ShipmentContainersFormTitleGrayName>;
  const title = container?.isVirtual ? t('New Container') : containerName;

  return (
    <ShipmentContainersFormTitleContainer>
      <ShipmentContainersFormTitleFirstRow>
        <ShipmentContainersFormTitleName>
          {container ? <>{ContainerTypesConst[container.type]}&apos;</> : null} {title}
        </ShipmentContainersFormTitleName>
        <ShipmentContainersFormTitleButton
          icon={<DeleteOutlined />}
          type="dashed"
          size="small"
          onClick={onRemoveHandler}
          loading={isRemoveLoading}
          disabled={disable}
        >
          {t('Remove')}
        </ShipmentContainersFormTitleButton>
      </ShipmentContainersFormTitleFirstRow>
    </ShipmentContainersFormTitleContainer>
  );
};
