import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import {
  CargoWeight as CargoWeightIcon,
  PackagesNumber as PackagesNumberIcon,
  Vector as VectorIcon,
} from 'app-wrapper/view/icons';

import { CargoCardBottomContainer } from './ContainerCardBottom.styled';

interface ICargoCardBottomProps {
  packagesNumber?: number
  weight?: number
  volume?: number
}

export const ContainerCardBottom: FC<ICargoCardBottomProps> = ({
  packagesNumber,
  weight,
  volume,
}) => {
  const { t } = useTranslation();

  return (
    <CargoCardBottomContainer>
      <span><PackagesNumberIcon />{packagesNumber || 0} {t('pcs')}</span>
      <span><CargoWeightIcon />{weight || 0} {t('kg')}</span>
      <span><VectorIcon />{volume || 0} {t('cbm')}</span>
    </CargoCardBottomContainer>
  );
};
