import {
  IsDefined, IsString, IsOptional, ValidateNested, IsArray, IsBoolean, IsNumber,
} from 'class-validator';
import { plainToInstance, Type } from 'class-transformer';

import { DateDtm, IDateDTM } from 'app-wrapper/models/dtm';
import { BaseDTM } from 'proto/BaseDTM';
import { AddressDTM, IAddressDTM } from 'shipment-operations/models/dtm';

import { IShippingPartyReference, ShippingPartyReference } from './ShippingParty.dtm';

export interface IShippingPartyOverviewDTM {
  id?: number;
  companyName?: string
  addressLine?: string
  contactPerson?: string
  email?: string
  phone?: string
  additionalPhone?: string
  usciNumber?: string
  references: IShippingPartyReference[]
  taxId?: string
  address?: IAddressDTM;
  isFreightForwarderOrgType?: boolean
  scac?: string
}

export class ShippingPartyOverviewDTM extends BaseDTM<IShippingPartyOverviewDTM> {
  @IsOptional()
  @IsNumber()
  id?: number;

  @IsOptional()
  @IsString()
  companyName?: string;

  @IsOptional()
  @IsString()
  addressLine?: string;

  @IsOptional()
  @IsString()
  contactPerson?: string;

  @IsOptional()
  @IsString()
  email?: string;

  @IsOptional()
  @Type(() => AddressDTM)
  @ValidateNested()
  address?: AddressDTM;

  @IsOptional()
  @IsString()
  phone?: string;

  @IsOptional()
  @IsString()
  additionalPhone?: string;

  @IsOptional()
  @IsString()
  usciNumber?: string;

  @IsOptional()
  @IsString()
  taxId?: string;

  @IsDefined()
  @ValidateNested()
  @Type(() => ShippingPartyReference)
  references: ShippingPartyReference[]

  @IsOptional()
  @IsBoolean()
  isFreightForwarderOrgType?: boolean;

  @IsOptional()
  @IsString()
  scac?: string;

  public getCompanyName = () => this.companyName || '';

  static createEmpty = () => plainToInstance(ShippingPartyOverviewDTM, { references: [] });
}

export interface ICarrierOverviewDTM {
  carrierName: string
  carrierReference: string
  termsAndConditions: string
  comments: string

  contactName: string
  phoneList: string[]
  emailList: string[]
}

export class CarrierOverviewDTM extends BaseDTM<ICarrierOverviewDTM> {
  @IsDefined()
  @IsString()
  carrierName: string;

  @IsDefined()
  @IsString()
  carrierReference: string;

  @IsDefined()
  @IsString()
  termsAndConditions: string;

  @IsDefined()
  @IsString()
  comments: string;

  @IsDefined()
  @IsString()
  contactName: string;

  @IsArray()
  @IsString({ each: true })
  phoneList: string[];

  @IsArray()
  @IsString({ each: true })
  emailList: string[];
}

export interface IReferencesOverviewDTM {
  mblNumber?: string
  inttraReferenceNumber?: string
  contractNumber?: string
  agentsReference?: string
}

export class ReferencesOverviewDTM extends BaseDTM<IReferencesOverviewDTM> {
  @IsOptional()
  @IsString()
  mblNumber?: string

  @IsOptional()
  @IsString()
  inttraReferenceNumber?: string

  @IsOptional()
  @IsString()
  contractNumber?: string

  @IsOptional()
  @IsString()
  agentsReference?: string
}

export interface ICutoffsDTM {
  documentationCarrierDate?: IDateDTM // TZ shift
  portCarrierDate?: IDateDTM // TZ shift
  vgmCarrierDate?: IDateDTM // TZ shift
  hazmatCarrierDate?: IDateDTM // TZ shift
}

export class CutoffsDTM extends BaseDTM<ICutoffsDTM> {
  @IsOptional()
  @ValidateNested()
  @Type(() => DateDtm)
  documentationCarrierDate?: DateDtm;

  @IsOptional()
  @ValidateNested()
  @Type(() => DateDtm)
  portCarrierDate?: DateDtm;

  @IsOptional()
  @ValidateNested()
  @Type(() => DateDtm)
  vgmCarrierDate?: DateDtm;

  @IsOptional()
  @ValidateNested()
  @Type(() => DateDtm)
  hazmatCarrierDate?: DateDtm;

  static fromPlane = (input: ICutoffsDTM) => plainToInstance(CutoffsDTM, input);
}

export interface ITransportationOverviewDTM extends ICutoffsDTM, ICarrierOverviewDTM, IReferencesOverviewDTM {
  shipper: IShippingPartyOverviewDTM
  consignee: IShippingPartyOverviewDTM
  notifyParty: IShippingPartyOverviewDTM
  freightuneCutoffs?: ICutoffsDTM
}

export class TransportationOverviewDTM extends BaseDTM<ITransportationOverviewDTM> {
  @IsOptional()
  @ValidateNested()
  @Type(() => DateDtm)
  documentationCarrierDate?: DateDtm;

  @IsOptional()
  @ValidateNested()
  @Type(() => DateDtm)
  portCarrierDate?: DateDtm;

  @IsOptional()
  @ValidateNested()
  @Type(() => DateDtm)
  vgmCarrierDate?: DateDtm;

  @IsOptional()
  @ValidateNested()
  @Type(() => DateDtm)
  hazmatCarrierDate?: DateDtm;

  @IsDefined()
  @IsString()
  carrierName: string;

  @IsDefined()
  @IsString()
  carrierReference: string;

  @IsDefined()
  @IsString()
  termsAndConditions: string;

  @IsDefined()
  @IsString()
  comments: string;

  @IsDefined()
  @IsString()
  contactName: string;

  @IsDefined()
  @IsArray()
  @IsString({ each: true })
  phoneList: string[];

  @IsDefined()
  @IsArray()
  @IsString({ each: true })
  emailList: string[];

  @IsOptional()
  @IsString()
  mblNumber?: string

  @IsOptional()
  @IsString()
  inttraReferenceNumber?: string

  @IsOptional()
  @IsString()
  contractNumber?: string

  @IsOptional()
  @IsString()
  agentsReference?: string

  @IsDefined()
  @ValidateNested()
  @Type(() => ShippingPartyOverviewDTM)
  shipper: ShippingPartyOverviewDTM;

  @IsDefined()
  @ValidateNested()
  @Type(() => ShippingPartyOverviewDTM)
  consignee: ShippingPartyOverviewDTM;

  @IsDefined()
  @ValidateNested()
  @Type(() => ShippingPartyOverviewDTM)
  notifyParty: ShippingPartyOverviewDTM;

  @IsDefined()
  @ValidateNested()
  @Type(() => CutoffsDTM)
  freightuneCutoffs: CutoffsDTM

  static createEmpty = () => plainToInstance(TransportationOverviewDTM, {
    carrierName: '',
    carrierReference: '',
    termsAndConditions: '',
    comments: '',
    contactName: '',
    emailList: [],
    phoneList: [],
    documentationCarrierDate: undefined,
    portCarrierDate: undefined,
    vgmCarrierDate: undefined,
    hazmatCarrierDate: undefined,
    shipper: ShippingPartyOverviewDTM.createEmpty(),
    consignee: ShippingPartyOverviewDTM.createEmpty(),
    notifyParty: ShippingPartyOverviewDTM.createEmpty(),
  });
}
