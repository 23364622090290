import {
  IsDefined,
  IsNumber,
  IsOptional,
  IsString,
  IsBoolean,
  ValidateNested,
} from 'class-validator';
import { Type } from 'class-transformer';

import i18n from 'app-wrapper/i18n/i18n';
import { validationEmail, validationPhone } from 'app-wrapper/utils';
import { BaseDTM } from 'proto/BaseDTM';

export interface ICompanyContactDTM {
  id: number
  customId?: string
  fullName: string
  email: string
  phone: string
  phone2: string
  primary?: boolean
  primaryDefault?: boolean
}

export interface IContactBookStateErrorsFieldDTM {
  isBlur?: boolean
  isFocus?: boolean
  message?: string
  isVisited?: boolean
  isTooltipVisible?: boolean
}

export class ContactBookStateErrorsFieldDTM extends BaseDTM<IContactBookStateErrorsFieldDTM> implements IContactBookStateErrorsFieldDTM {
  @IsOptional()
  @IsBoolean()
  isBlur: boolean

  @IsOptional()
  @IsBoolean()
  isFocus: boolean

  @IsOptional()
  @IsString()
  message: string

  @IsOptional()
  @IsBoolean()
  isVisited: boolean

  @IsOptional()
  @IsBoolean()
  isTooltipVisible: boolean
}

export class CompanyContactDTM extends BaseDTM<ICompanyContactDTM> implements ICompanyContactDTM {
  @IsNumber()
  id: number

  @IsString()
  customId: string

  @IsString()
  fullName: string

  @IsOptional()
  @ValidateNested()
  @Type(() => ContactBookStateErrorsFieldDTM)
  fullNameErrors: ContactBookStateErrorsFieldDTM

  @IsString()
  email: string

  @IsOptional()
  @ValidateNested()
  @Type(() => ContactBookStateErrorsFieldDTM)
  emailErrors: ContactBookStateErrorsFieldDTM

  @IsString()
  phone: string

  @IsOptional()
  @ValidateNested()
  @Type(() => ContactBookStateErrorsFieldDTM)
  phoneErrors: ContactBookStateErrorsFieldDTM

  @IsString()
  phone2: string

  @IsOptional()
  @ValidateNested()
  @Type(() => ContactBookStateErrorsFieldDTM)
  phone2Errors: ContactBookStateErrorsFieldDTM

  @IsOptional()
  @IsBoolean()
  primary: boolean

  @IsOptional()
  @IsBoolean()
  primaryDefault: boolean

  getValidateFullName = () => {
    const value = this.fullName;
    const error = this.fullNameErrors;
    let newError = ContactBookStateErrorsFieldDTM.fromPlain({});

    if (!value) {
      newError = ContactBookStateErrorsFieldDTM.fromPlain({
        ...error,
        message: i18n.t('basicErrors.REQUIRED_MESSAGE'),
      });
    } else {
      newError = ContactBookStateErrorsFieldDTM.fromPlain({
        ...error,
        message: '',
      });
    }

    return newError;
  }

  hasErrorFullName = () => {
    const value = this.getValidateFullName();

    return !!value.message;
  }

  getValidateEmail = () => {
    const value = this.email;
    const error = this.emailErrors;
    let newError = ContactBookStateErrorsFieldDTM.fromPlain({});
    const { errorMessage: errorMessageEmail } = validationEmail(value);

    if (!value || errorMessageEmail) {
      newError = ContactBookStateErrorsFieldDTM.fromPlain({
        ...error,
        message: errorMessageEmail || i18n.t('basicErrors.REQUIRED_MESSAGE'),
        isTooltipVisible: true,
      });
    } else {
      newError = ContactBookStateErrorsFieldDTM.fromPlain({
        ...error,
        message: '',
        isTooltipVisible: false,
      });
    }

    return newError;
  }

  hasErrorEmail = () => {
    const value = this.getValidateEmail();

    return !!value.message;
  }

  getValidatePhone = () => {
    const value = this.phone;
    const error = this.phoneErrors;
    let newError = ContactBookStateErrorsFieldDTM.fromPlain({});
    const { errorMessage: errorMessagePhone } = validationPhone(value);

    if (!value || errorMessagePhone) {
      newError = ContactBookStateErrorsFieldDTM.fromPlain({
        ...error,
        message: errorMessagePhone || i18n.t('basicErrors.REQUIRED_MESSAGE'),
        isTooltipVisible: true,
      });
    } else {
      newError = ContactBookStateErrorsFieldDTM.fromPlain({
        ...error,
        message: '',
        isTooltipVisible: false,
      });
    }

    return newError;
  }

  hasErrorPhone = () => {
    const value = this.getValidatePhone();

    return !!value.message;
  }

  getValidatePhone2 = () => {
    const value = this.phone2;
    const error = this.phone2Errors;
    let newError = ContactBookStateErrorsFieldDTM.fromPlain({});
    const { errorMessage: errorMessagePhone } = validationPhone(value);

    if (!!value && !!errorMessagePhone) {
      newError = ContactBookStateErrorsFieldDTM.fromPlain({
        ...error,
        message: errorMessagePhone,
        isTooltipVisible: true,
      });
    } else {
      newError = ContactBookStateErrorsFieldDTM.fromPlain({
        ...error,
        message: '',
        isTooltipVisible: false,
      });
    }

    return newError;
  }

  hasErrorPhone2 = () => {
    const value = this.getValidatePhone2();

    return !!value.message;
  }
}

export interface ICompanyAddressDTM {
  id: number
  customId?: string
  country: string
  state?: string
  city: string
  address1: string
  address2: string
  postalCode: string
  closestPort: string
  company: string
  isPrimary?: boolean
  primaryDefault?: boolean
  contacts?: ICompanyContactDTM[]
  contactsIds?: string[]
  primaryContact?: ICompanyContactDTM
}

export class CompanyAddressDTM extends BaseDTM<ICompanyAddressDTM> implements ICompanyAddressDTM {
  @IsNumber()
  id: number

  @IsString()
  customId: string

  @IsString()
  country: string

  @IsOptional()
  @IsString()
  state: string

  @IsString()
  city: string

  @IsString()
  address1: string

  @IsString()
  address2: string

  @IsString()
  postalCode: string

  @IsString()
  closestPort: string

  @IsString()
  company: string

  @IsOptional()
  @IsBoolean()
  isPrimary: boolean

  @IsOptional()
  @IsBoolean()
  primaryDefault: boolean

  @IsOptional()
  @ValidateNested({ each: true })
  @Type(() => CompanyContactDTM)
  contacts?: CompanyContactDTM[]

  @IsOptional()
  @ValidateNested()
  @Type(() => CompanyContactDTM)
  primaryContact: CompanyContactDTM

  getAddressLine = () => {
    let fullAddress = this.address1;

    if (this.address2) {
      fullAddress += `, ${this.address2}`;
    }

    fullAddress += `, ${this.city}`;

    if (this.state) {
      fullAddress += `, ${this.state}`;
    }

    fullAddress += `, ${this.postalCode}, ${this.country}`;

    return fullAddress;
  }

  getBothAddressLine = () => {
    let fullAddress = this.address1;

    if (this.address2) {
      fullAddress += `, ${this.address2}`;
    }

    return fullAddress;
  }

  getAddressCityCountry = () => {
    let fullAddress = this.city;

    fullAddress += `, ${this.country}`;

    return fullAddress;
  }
}

export interface ICompanyDTM {
  id: number
  customId?: string
  organizationId?: number
  name: string
  phone?: string
  phone2?: string
  email?: string
  usci?: string
  taxId?: string
  scac?: string
  isPrimary?: boolean
  companyType?: string
  addressesIds?: string[]
  contactPersonsIds?: string[]
  contacts?: ICompanyContactDTM[]
  addresses?: ICompanyAddressDTM[]
  primaryContact?: ICompanyContactDTM
}

export class CompanyDTM extends BaseDTM<ICompanyDTM> implements ICompanyDTM {
  @IsDefined()
  @IsNumber()
  id: number;

  @IsOptional()
  @IsString()
  customId?: string;

  @IsOptional()
  @IsNumber()
  organizationId?: number;

  @IsDefined()
  @IsString()
  name: string;

  @IsOptional()
  @IsString()
  phone?: string;

  @IsOptional()
  @IsString()
  phone2?: string;

  @IsOptional()
  @IsString()
  email?: string;

  @IsOptional()
  @IsString()
  usci?: string;

  @IsOptional()
  @IsString()
  taxId?: string;

  @IsOptional()
  @IsString()
  scac?: string;

  @IsOptional()
  @IsString()
  companyType: string

  @IsOptional()
  @IsBoolean()
  isPrimary: boolean

  @IsOptional()
  @IsString({ each: true })
  addressesIds: string[]

  @IsOptional()
  @IsString({ each: true })
  contactPersonsIds: string[]

  @ValidateNested({ each: true })
  @Type(() => CompanyContactDTM)
  contacts: CompanyContactDTM[]

  @ValidateNested({ each: true })
  @Type(() => CompanyAddressDTM)
  addresses: CompanyAddressDTM[]

  @ValidateNested()
  @Type(() => CompanyContactDTM)
  primaryContact: CompanyContactDTM
}

export interface IFullCompanyContactDTM {
  id: number;
  primary: boolean;
}

export class FullCompanyContactDTM extends BaseDTM<IFullCompanyContactDTM> implements IFullCompanyContactDTM {
  @IsNumber()
  id: number;

  @IsBoolean()
  primary: boolean;
}

export interface IFullCompanyAddressDTM {
  id: number;
  primary: boolean;
  contacts: IFullCompanyContactDTM[];
}

export class FullCompanyAddressDTM extends BaseDTM<IFullCompanyAddressDTM> implements IFullCompanyAddressDTM {
  @IsNumber()
  id: number;

  @IsBoolean()
  primary: boolean;

  @ValidateNested()
  @Type(() => FullCompanyContactDTM)
  contacts: FullCompanyContactDTM[];
}

export interface IFullCompanyDTM {
  id: number;
  name: string;
  phone?: string;
  phone2?: string;
  email?: string;
  taxId?: string;
  addresses: IFullCompanyAddressDTM[];
  contacts: IFullCompanyContactDTM[];
}

export class FullCompanyDTM extends BaseDTM<IFullCompanyDTM> implements IFullCompanyDTM {
  @IsDefined()
  @IsNumber()
  id: number;

  @IsDefined()
  @IsString()
  name: string;

  @IsOptional()
  @IsString()
  phone?: string;

  @IsOptional()
  @IsString()
  phone2?: string;

  @IsOptional()
  @IsString()
  email?: string;

  @IsOptional()
  @IsString()
  taxId?: string;

  @ValidateNested({ each: true })
  @Type(() => FullCompanyAddressDTM)
  addresses: FullCompanyAddressDTM[];

  @ValidateNested({ each: true })
  @Type(() => FullCompanyContactDTM)
  contacts: FullCompanyContactDTM[];
}
