import styled from 'styled-components';
import AntCheckbox from 'antd/es/checkbox';

import { colors } from 'app-wrapper/view/themes/themesColors';

export const CardSelectCheckbox = styled(AntCheckbox).attrs({
  'data-class': 'CardSelectCheckbox',
})`
  &.ant-checkbox-checked .ant-checkbox-inner {
    border-color: ${colors.buttonPrimaryColor};
    background-color: ${colors.buttonPrimaryColor};
  }
`;
