import {
  FreightQuotaContentContainerDTM, FreightQuotaContentContractsDTM, FreightQuotaContentCostDTM, FreightQuotaContentCustomerDTM, FreightQuotaContentDTM, FreightQuotaContentRouteLegDTM, FreightQuotaContentRoutesDTM, FreightQuotaContentRoutesLocationDTM, FreightQuotaContentSchedulesChargesChargeCodeDTM, FreightQuotaContentSchedulesChargesDTM, FreightQuotaContentSchedulesDTM, FreightQuotaContentValidPeriodDTM, FreightQuotaTransportationDTM, FreightQuotaTransportationScheduleDTM, FreightQuotaTransportationTransportDTM, FreightSelectFieldCoordinatesDTM, FreightSelectFieldCountryDTM, QuotaServiceByIdContentRoutesLegsLocationDTM,
} from 'monetary/models/dtm';
import { IGetQuotaRequestServiceByIdResponse } from 'monetary/models/contracts/Quotas/getQuotaRequestServiceByIdResponse';
import { EFreightIncoterms, EFreightIncotermsTrade } from 'monetary/constants';
import { QuotaBookmark } from 'monetary/models/dtm/Quotas';

export const dataRFQItem: IGetQuotaRequestServiceByIdResponse = {
  content: [
    {
      id: 5939,
      tradeType: EFreightIncotermsTrade.IMPORT,
      incoterm: EFreightIncoterms.EXW,
      cost: {
        totalCost: 70000,
        originTotalCost: 7000,
        freightTotalCost: 9000,
        destinationTotalCost: 67000,
      },
      routes: [
        {
          origin: {
            type: 'PORT',
            id: 86212,
            country: {
              code: 'US',
              name: 'United States',
            },
            state: {
              code: 'NY',
              name: 'NY',
            },
            name: 'New York',
            code: 'USNYC',
            timeZoneId: 'America/New_York',
            coordinates: {
              lat: 40.7000007629395,
              lng: -74,
            },
          },
          destination: {
            type: 'PORT',
            id: 86211,
            country: {
              code: 'AR',
              name: 'Argentina',
            },
            state: {
              code: 'S',
              name: 'S',
            },
            name: 'Rosario',
            code: 'ARROS',
            timeZoneId: 'America/Cordoba',
            coordinates: {
              lat: -32.977,
              lng: -60.61863,
            },
          },
          legs: [
            {
              id: 7650,
              phase: 'FREIGHT',
              arrivalLocation: {
                type: 'PORT',
                id: 86213,
                country: {
                  code: 'UY',
                  name: 'Uruguay',
                },
                state: {},
                name: 'Montevideo',
                code: 'UYMVD',
                timeZoneId: 'America/Montevideo',
                coordinates: {
                  lat: -34.89977,
                  lng: -56.1958,
                },
              },
              departureLocation: {
                type: 'PORT',
                id: 86212,
                country: {
                  code: 'US',
                  name: 'United States',
                },
                state: {
                  code: 'NY',
                  name: 'New York',
                },
                name: 'New York',
                code: 'USNYC',
                timeZoneId: 'America/New_York',
                coordinates: {
                  lat: 40.7000007629395,
                  lng: -74,
                },
              },
            },
            {
              id: 7651,
              phase: 'FREIGHT',
              arrivalLocation: {
                type: 'PORT',
                id: 86211,
                country: {
                  code: 'AR',
                  name: 'Argentina',
                },
                state: {
                  code: 'S',
                  name: 'Santa Fe',
                },
                name: 'Rosario',
                code: 'ARROS',
                timeZoneId: 'America/Cordoba',
                coordinates: {
                  lat: -32.977,
                  lng: -60.61863,
                },
              },
              departureLocation: {
                type: 'PORT',
                id: 86214,
                country: {
                  code: 'UY',
                  name: 'Uruguay',
                },
                state: {},
                name: 'Montevideo',
                code: 'UYMVD',
                timeZoneId: 'America/Montevideo',
                coordinates: {
                  lat: -34.89977,
                  lng: -56.1958,
                },
              },
            },
          ],
          containerIds: [
            48796,
            48797,
            48798,
          ],
        },
      ],
      schedules: [
        {
          id: 37148,
          oceanScheduleId: 902,
          recommended: true,
          departureTime: '2022-08-20T17:00:00-04:00',
          arrivalTime: '2022-09-24T18:00:00-03:00',
          totalDuration: 50400,
          terminalCutOff: '2022-08-17T16:00:00-04:00',
          documentCutOff: '2022-08-16T12:00:00-04:00',
          hazmatCutOff: '',
          plans: [],
          charges: [
            {
              unitType: '22G0',
              currency: 'USD',
              costPerUnit: 3.5,
              numberOfUnits: 1,
              totalCost: 3.5,
              containerId: 48796,
              contractId: 6283,
              transportationIds: [
                48917,
                48918,
              ],
              applied: true,
              designation: 'ORIGIN',
              measureBy: 'KM_MILE',
              priceBy: 'CONTAINER',
              chargeCode: {
                code: 'PKP',
                description: 'Pickup',
                mode: 'ROAD',
                occurrence: 'MANDATORY',
                type: 'CHARGE',
                subType: 'ACCESSORIAL',
              },
            },
            {
              unitType: '22G0',
              currency: 'USD',
              costPerUnit: 3.5,
              numberOfUnits: 1,
              totalCost: 3.5,
              containerId: 48797,
              contractId: 6283,
              transportationIds: [
                48917,
                48918,
              ],
              applied: true,
              designation: 'ORIGIN',
              measureBy: 'KM_MILE',
              priceBy: 'CONTAINER',
              chargeCode: {
                code: 'PKP',
                description: 'Pickup',
                mode: 'ROAD',
                occurrence: 'MANDATORY',
                type: 'CHARGE',
                subType: 'ACCESSORIAL',
              },
            },
            {
              unitType: '',
              currency: 'USD',
              costPerUnit: 100,
              numberOfUnits: 1,
              totalCost: 100,
              containerId: 48796,
              contractId: 6283,
              transportationIds: [
                48917,
                48918,
              ],
              applied: true,
              designation: 'ORIGIN',
              measureBy: 'DAY',
              priceBy: 'CONTAINER',
              chargeCode: {
                code: 'CHAOR',
                description: 'Origin Chassis Fee',
                mode: 'ROAD',
                occurrence: 'MANDATORY',
                type: 'SURCHARGE',
                subType: 'ACCESSORIAL',
              },
            },
            {
              unitType: '',
              currency: 'USD',
              costPerUnit: 100,
              numberOfUnits: 1,
              totalCost: 100,
              containerId: 48797,
              contractId: 6283,
              transportationIds: [
                48917,
                48918,
              ],
              applied: true,
              designation: 'ORIGIN',
              measureBy: 'DAY',
              priceBy: 'CONTAINER',
              chargeCode: {
                code: 'CHAOR',
                description: 'Origin Chassis Fee',
                mode: 'ROAD',
                occurrence: 'MANDATORY',
                type: 'SURCHARGE',
                subType: 'ACCESSORIAL',
              },
            },
            {
              unitType: '',
              subjectTo: 'APPLICABLE',
              currency: 'USD',
              costPerUnit: 4,
              numberOfUnits: 1,
              totalCost: 4,
              containerId: 48796,
              contractId: 6283,
              transportationIds: [
                48917,
                48918,
              ],
              applied: true,
              designation: 'ORIGIN',
              measureBy: 'FLAT',
              priceBy: 'BOL',
              chargeCode: {
                code: 'OISPS',
                description: 'Origin Port ISPS',
                mode: 'OCEAN',
                occurrence: 'MANDATORY',
                type: 'SURCHARGE',
                subType: 'ACCESSORIAL',
              },
            },
            {
              unitType: '',
              subjectTo: 'APPLICABLE',
              currency: 'USD',
              costPerUnit: 4,
              numberOfUnits: 1,
              totalCost: 4,
              containerId: 48797,
              contractId: 6283,
              transportationIds: [
                48917,
                48918,
              ],
              applied: true,
              designation: 'ORIGIN',
              measureBy: 'FLAT',
              priceBy: 'BOL',
              chargeCode: {
                code: 'OISPS',
                description: 'Origin Port ISPS',
                mode: 'OCEAN',
                occurrence: 'MANDATORY',
                type: 'SURCHARGE',
                subType: 'ACCESSORIAL',
              },
            },
            {
              unitType: '',
              subjectTo: 'APPLICABLE',
              currency: 'USD',
              costPerUnit: 1000,
              numberOfUnits: 1,
              totalCost: 1000,
              containerId: 48796,
              contractId: 6283,
              transportationIds: [
                48917,
                48918,
              ],
              applied: true,
              designation: 'ORIGIN',
              measureBy: 'FLAT',
              priceBy: 'BOL',
              chargeCode: {
                code: 'OTHC',
                description: 'Origin Terminal Handling Charge',
                mode: 'OCEAN',
                occurrence: 'MANDATORY',
                type: 'SURCHARGE',
                subType: 'ACCESSORIAL',
              },
            },
            {
              unitType: '',
              subjectTo: 'APPLICABLE',
              currency: 'USD',
              costPerUnit: 1000,
              numberOfUnits: 1,
              totalCost: 1000,
              containerId: 48797,
              contractId: 6283,
              transportationIds: [
                48917,
                48918,
              ],
              applied: true,
              designation: 'ORIGIN',
              measureBy: 'FLAT',
              priceBy: 'BOL',
              chargeCode: {
                code: 'OTHC',
                description: 'Origin Terminal Handling Charge',
                mode: 'OCEAN',
                occurrence: 'MANDATORY',
                type: 'SURCHARGE',
                subType: 'ACCESSORIAL',
              },
            },
            {
              unitType: '',
              subjectTo: 'APPLICABLE',
              currency: 'USD',
              costPerUnit: 50,
              numberOfUnits: 1,
              totalCost: 50,
              containerId: 48796,
              contractId: 6283,
              transportationIds: [
                48917,
                48918,
              ],
              applied: true,
              designation: 'ORIGIN',
              measureBy: 'FLAT',
              priceBy: 'BOL',
              chargeCode: {
                code: 'EODOC',
                description: 'Electronic Origin Documentation Fee',
                mode: 'OCEAN',
                occurrence: 'MANDATORY',
                type: 'FEE',
                subType: 'ACCESSORIAL',
              },
            },
            {
              unitType: '',
              subjectTo: 'APPLICABLE',
              currency: 'USD',
              costPerUnit: 50,
              numberOfUnits: 1,
              totalCost: 50,
              containerId: 48797,
              contractId: 6283,
              transportationIds: [
                48917,
                48918,
              ],
              applied: true,
              designation: 'ORIGIN',
              measureBy: 'FLAT',
              priceBy: 'BOL',
              chargeCode: {
                code: 'EODOC',
                description: 'Electronic Origin Documentation Fee',
                mode: 'OCEAN',
                occurrence: 'MANDATORY',
                type: 'FEE',
                subType: 'ACCESSORIAL',
              },
            },
            {
              unitType: '',
              currency: 'USD',
              costPerUnit: 120,
              numberOfUnits: 1,
              totalCost: 120,
              containerId: 48796,
              contractId: 6283,
              transportationIds: [
                48917,
                48918,
              ],
              applied: true,
              designation: 'ORIGIN',
              measureBy: 'FLAT',
              priceBy: 'BOL',
              chargeCode: {
                code: 'AMS',
                description: 'Filing Charge',
                mode: 'ALL',
                occurrence: 'ADDITIONAL',
                type: 'CHARGE',
                subType: 'ACCESSORIAL',
              },
            },
            {
              unitType: '',
              currency: 'USD',
              costPerUnit: 120,
              numberOfUnits: 1,
              totalCost: 120,
              containerId: 48797,
              contractId: 6283,
              transportationIds: [
                48917,
                48918,
              ],
              applied: true,
              designation: 'ORIGIN',
              measureBy: 'FLAT',
              priceBy: 'BOL',
              chargeCode: {
                code: 'AMS',
                description: 'Filing Charge',
                mode: 'ALL',
                occurrence: 'ADDITIONAL',
                type: 'CHARGE',
                subType: 'ACCESSORIAL',
              },
            },
            {
              unitType: '22G0',
              currency: 'USD',
              costPerUnit: 1000,
              numberOfUnits: 1,
              totalCost: 1000,
              containerId: 48796,
              contractId: 6283,
              transportationIds: [
                48917,
                48918,
              ],
              applied: true,
              designation: 'FREIGHT',
              measureBy: 'CONTAINER_TYPE',
              priceBy: 'CONTAINER',
              chargeCode: {
                code: 'FRT',
                description: 'Freight',
                mode: 'OCEAN',
                occurrence: 'MANDATORY',
                type: 'CHARGE',
                subType: 'ACCESSORIAL',
              },
            },
            {
              unitType: '22G0',
              currency: 'USD',
              costPerUnit: 1000,
              numberOfUnits: 1,
              totalCost: 1000,
              containerId: 48797,
              contractId: 6283,
              transportationIds: [
                48917,
                48918,
              ],
              applied: true,
              designation: 'FREIGHT',
              measureBy: 'CONTAINER_TYPE',
              priceBy: 'CONTAINER',
              chargeCode: {
                code: 'FRT',
                description: 'Freight',
                mode: 'OCEAN',
                occurrence: 'MANDATORY',
                type: 'CHARGE',
                subType: 'ACCESSORIAL',
              },
            },
            {
              unitType: '22G0',
              subjectTo: 'APPLICABLE',
              currency: 'USD',
              costPerUnit: 15,
              numberOfUnits: 1,
              totalCost: 15,
              containerId: 48796,
              contractId: 6283,
              transportationIds: [
                48917,
                48918,
              ],
              applied: true,
              designation: 'FREIGHT',
              measureBy: 'CONTAINER_TYPE',
              priceBy: 'CONTAINER',
              chargeCode: {
                code: 'CSF',
                description: 'Carrier Security Surcharge',
                mode: 'OCEAN',
                occurrence: 'MANDATORY',
                type: 'SURCHARGE',
                subType: 'ACCESSORIAL',
              },
            },
            {
              unitType: '22G0',
              subjectTo: 'APPLICABLE',
              currency: 'USD',
              costPerUnit: 15,
              numberOfUnits: 1,
              totalCost: 15,
              containerId: 48797,
              contractId: 6283,
              transportationIds: [
                48917,
                48918,
              ],
              applied: true,
              designation: 'FREIGHT',
              measureBy: 'CONTAINER_TYPE',
              priceBy: 'CONTAINER',
              chargeCode: {
                code: 'CSF',
                description: 'Carrier Security Surcharge',
                mode: 'OCEAN',
                occurrence: 'MANDATORY',
                type: 'SURCHARGE',
                subType: 'ACCESSORIAL',
              },
            },
            {
              unitType: '',
              subjectTo: 'INCLUDED',
              currency: 'USD',
              costPerUnit: 0,
              numberOfUnits: 1,
              totalCost: 0,
              containerId: 48796,
              contractId: 6283,
              transportationIds: [
                48917,
                48918,
              ],
              applied: true,
              designation: 'FREIGHT',
              measureBy: 'CONTAINER_TYPE',
              priceBy: 'CONTAINER',
              chargeCode: {
                code: 'ECA',
                description: 'Emission Control Charge',
                mode: 'OCEAN',
                occurrence: 'MANDATORY',
                type: 'SURCHARGE',
                subType: 'ACCESSORIAL',
              },
            },
            {
              unitType: '',
              subjectTo: 'INCLUDED',
              currency: 'USD',
              costPerUnit: 0,
              numberOfUnits: 1,
              totalCost: 0,
              containerId: 48797,
              contractId: 6283,
              transportationIds: [
                48917,
                48918,
              ],
              applied: true,
              designation: 'FREIGHT',
              measureBy: 'CONTAINER_TYPE',
              priceBy: 'CONTAINER',
              chargeCode: {
                code: 'ECA',
                description: 'Emission Control Charge',
                mode: 'OCEAN',
                occurrence: 'MANDATORY',
                type: 'SURCHARGE',
                subType: 'ACCESSORIAL',
              },
            },
            {
              unitType: '',
              subjectTo: 'INCLUDED',
              currency: 'USD',
              costPerUnit: 0,
              numberOfUnits: 1,
              totalCost: 0,
              containerId: 48796,
              contractId: 6283,
              transportationIds: [
                48917,
                48918,
              ],
              applied: true,
              designation: 'FREIGHT',
              measureBy: 'CONTAINER_TYPE',
              priceBy: 'CONTAINER',
              chargeCode: {
                code: 'EMS',
                description: 'Emergency Surcharge - Piracy Surcharge',
                mode: 'OCEAN',
                occurrence: 'MANDATORY',
                type: 'SURCHARGE',
                subType: 'ACCESSORIAL',
              },
            },
            {
              unitType: '',
              subjectTo: 'INCLUDED',
              currency: 'USD',
              costPerUnit: 0,
              numberOfUnits: 1,
              totalCost: 0,
              containerId: 48797,
              contractId: 6283,
              transportationIds: [
                48917,
                48918,
              ],
              applied: true,
              designation: 'FREIGHT',
              measureBy: 'CONTAINER_TYPE',
              priceBy: 'CONTAINER',
              chargeCode: {
                code: 'EMS',
                description: 'Emergency Surcharge - Piracy Surcharge',
                mode: 'OCEAN',
                occurrence: 'MANDATORY',
                type: 'SURCHARGE',
                subType: 'ACCESSORIAL',
              },
            },
            {
              unitType: '',
              subjectTo: 'APPLICABLE',
              currency: 'USD',
              costPerUnit: 377,
              numberOfUnits: 1,
              totalCost: 377,
              containerId: 48796,
              contractId: 6283,
              transportationIds: [
                48917,
                48918,
              ],
              applied: true,
              designation: 'FREIGHT',
              measureBy: 'CONTAINER_TYPE',
              priceBy: 'CONTAINER',
              chargeCode: {
                code: 'PCC',
                description: 'Panama Canal Surcharge',
                mode: 'OCEAN',
                occurrence: 'MANDATORY',
                type: 'SURCHARGE',
                subType: 'ACCESSORIAL',
              },
            },
            {
              unitType: '',
              subjectTo: 'APPLICABLE',
              currency: 'USD',
              costPerUnit: 377,
              numberOfUnits: 1,
              totalCost: 377,
              containerId: 48797,
              contractId: 6283,
              transportationIds: [
                48917,
                48918,
              ],
              applied: true,
              designation: 'FREIGHT',
              measureBy: 'CONTAINER_TYPE',
              priceBy: 'CONTAINER',
              chargeCode: {
                code: 'PCC',
                description: 'Panama Canal Surcharge',
                mode: 'OCEAN',
                occurrence: 'MANDATORY',
                type: 'SURCHARGE',
                subType: 'ACCESSORIAL',
              },
            },
            {
              unitType: '',
              subjectTo: 'APPLICABLE',
              currency: 'USD',
              costPerUnit: 31,
              numberOfUnits: 1,
              totalCost: 31,
              containerId: 48796,
              contractId: 6283,
              transportationIds: [
                48917,
                48918,
              ],
              applied: false,
              designation: 'FREIGHT',
              measureBy: 'FLAT',
              priceBy: 'BOL',
              chargeCode: {
                code: 'ACT',
                description: 'Alameda Corridor Surcharge',
                mode: 'OCEAN',
                occurrence: 'CONDITIONAL',
                type: 'SURCHARGE',
                subType: 'ACCESSORIAL',
              },
            },
            {
              unitType: '',
              subjectTo: 'APPLICABLE',
              currency: 'USD',
              costPerUnit: 31,
              numberOfUnits: 1,
              totalCost: 31,
              containerId: 48797,
              contractId: 6283,
              transportationIds: [
                48917,
                48918,
              ],
              applied: false,
              designation: 'FREIGHT',
              measureBy: 'FLAT',
              priceBy: 'BOL',
              chargeCode: {
                code: 'ACT',
                description: 'Alameda Corridor Surcharge',
                mode: 'OCEAN',
                occurrence: 'CONDITIONAL',
                type: 'SURCHARGE',
                subType: 'ACCESSORIAL',
              },
            },
            {
              unitType: '',
              currency: 'USD',
              costPerUnit: 4.5,
              numberOfUnits: 1,
              totalCost: 4.5,
              containerId: 48796,
              contractId: 6283,
              transportationIds: [
                48917,
                48918,
              ],
              applied: true,
              designation: 'DESTINATION',
              measureBy: 'KM_MILE',
              priceBy: 'CONTAINER',
              chargeCode: {
                code: 'DLV',
                description: 'Delivery',
                mode: 'ROAD',
                occurrence: 'MANDATORY',
                type: 'CHARGE',
                subType: 'ACCESSORIAL',
              },
            },
            {
              unitType: '',
              currency: 'USD',
              costPerUnit: 4.5,
              numberOfUnits: 1,
              totalCost: 4.5,
              containerId: 48797,
              contractId: 6283,
              transportationIds: [
                48917,
                48918,
              ],
              applied: true,
              designation: 'DESTINATION',
              measureBy: 'KM_MILE',
              priceBy: 'CONTAINER',
              chargeCode: {
                code: 'DLV',
                description: 'Delivery',
                mode: 'ROAD',
                occurrence: 'MANDATORY',
                type: 'CHARGE',
                subType: 'ACCESSORIAL',
              },
            },
            {
              unitType: '',
              subjectTo: 'APPLICABLE',
              currency: 'USD',
              costPerUnit: 100,
              numberOfUnits: 1,
              totalCost: 100,
              containerId: 48796,
              contractId: 6283,
              transportationIds: [
                48917,
                48918,
              ],
              applied: true,
              designation: 'DESTINATION',
              measureBy: 'DAY',
              priceBy: 'CONTAINER',
              chargeCode: {
                code: 'CHADE',
                description: 'Destination Chassis Fee',
                mode: 'ROAD',
                occurrence: 'CONDITIONAL',
                type: 'SURCHARGE',
                subType: 'ACCESSORIAL',
              },
            },
            {
              unitType: '',
              subjectTo: 'APPLICABLE',
              currency: 'USD',
              costPerUnit: 100,
              numberOfUnits: 1,
              totalCost: 100,
              containerId: 48797,
              contractId: 6283,
              transportationIds: [
                48917,
                48918,
              ],
              applied: true,
              designation: 'DESTINATION',
              measureBy: 'DAY',
              priceBy: 'CONTAINER',
              chargeCode: {
                code: 'CHADE',
                description: 'Destination Chassis Fee',
                mode: 'ROAD',
                occurrence: 'CONDITIONAL',
                type: 'SURCHARGE',
                subType: 'ACCESSORIAL',
              },
            },
            {
              unitType: '',
              subjectTo: 'APPLICABLE',
              currency: 'USD',
              costPerUnit: 450,
              numberOfUnits: 1,
              totalCost: 450,
              containerId: 48796,
              contractId: 6283,
              transportationIds: [
                48917,
                48918,
              ],
              applied: true,
              designation: 'DESTINATION',
              measureBy: 'FLAT',
              priceBy: 'BOL',
              chargeCode: {
                code: 'DDOC',
                description: 'Destination Documentation Fee ( Manuel)',
                mode: 'OCEAN',
                occurrence: 'MANDATORY',
                type: 'FEE',
                subType: 'ACCESSORIAL',
              },
            },
            {
              unitType: '',
              subjectTo: 'APPLICABLE',
              currency: 'USD',
              costPerUnit: 450,
              numberOfUnits: 1,
              totalCost: 450,
              containerId: 48797,
              contractId: 6283,
              transportationIds: [
                48917,
                48918,
              ],
              applied: true,
              designation: 'DESTINATION',
              measureBy: 'FLAT',
              priceBy: 'BOL',
              chargeCode: {
                code: 'DDOC',
                description: 'Destination Documentation Fee ( Manuel)',
                mode: 'OCEAN',
                occurrence: 'MANDATORY',
                type: 'FEE',
                subType: 'ACCESSORIAL',
              },
            },
            {
              unitType: '',
              subjectTo: 'APPLICABLE',
              currency: 'USD',
              costPerUnit: 120,
              numberOfUnits: 1,
              totalCost: 120,
              containerId: 48796,
              contractId: 6283,
              transportationIds: [
                48917,
                48918,
              ],
              applied: true,
              designation: 'DESTINATION',
              measureBy: 'FLAT',
              priceBy: 'BOL',
              chargeCode: {
                code: 'HBL',
                description: 'House Bill of Lading Fee',
                mode: 'ALL',
                occurrence: 'MANDATORY',
                type: 'FEE',
                subType: 'ACCESSORIAL',
              },
            },
            {
              unitType: '',
              subjectTo: 'APPLICABLE',
              currency: 'USD',
              costPerUnit: 120,
              numberOfUnits: 1,
              totalCost: 120,
              containerId: 48797,
              contractId: 6283,
              transportationIds: [
                48917,
                48918,
              ],
              applied: true,
              designation: 'DESTINATION',
              measureBy: 'FLAT',
              priceBy: 'BOL',
              chargeCode: {
                code: 'HBL',
                description: 'House Bill of Lading Fee',
                mode: 'ALL',
                occurrence: 'MANDATORY',
                type: 'FEE',
                subType: 'ACCESSORIAL',
              },
            },
            {
              unitType: '',
              subjectTo: 'APPLICABLE',
              currency: 'USD',
              costPerUnit: 541,
              numberOfUnits: 1,
              totalCost: 541,
              containerId: 48796,
              contractId: 6283,
              transportationIds: [
                48917,
                48918,
              ],
              applied: true,
              designation: 'DESTINATION',
              measureBy: 'FLAT',
              priceBy: 'BOL',
              chargeCode: {
                code: 'OTHC',
                description: 'Origin Terminal Handling ChargE',
                mode: 'OCEAN',
                occurrence: 'MANDATORY',
                type: 'SURCHARGE',
                subType: 'ACCESSORIAL',
              },
            },
            {
              unitType: '',
              subjectTo: 'APPLICABLE',
              currency: 'USD',
              costPerUnit: 541,
              numberOfUnits: 1,
              totalCost: 541,
              containerId: 48797,
              contractId: 6283,
              transportationIds: [
                48917,
                48918,
              ],
              applied: true,
              designation: 'DESTINATION',
              measureBy: 'FLAT',
              priceBy: 'BOL',
              chargeCode: {
                code: 'OTHC',
                description: 'Origin Terminal Handling ChargE',
                mode: 'OCEAN',
                occurrence: 'MANDATORY',
                type: 'SURCHARGE',
                subType: 'ACCESSORIAL',
              },
            },
            {
              unitType: '',
              currency: 'USD',
              costPerUnit: 140,
              numberOfUnits: 1,
              totalCost: 140,
              containerId: 48796,
              contractId: 6283,
              transportationIds: [
                48917,
                48918,
              ],
              applied: true,
              designation: 'DESTINATION',
              measureBy: 'FLAT',
              priceBy: 'BOL',
              chargeCode: {
                code: 'CCLR',
                description: 'Customs Clearance',
                mode: 'ALL',
                occurrence: 'ADDITIONAL',
                type: 'CHARGE',
                subType: 'ACCESSORIAL',
              },
            },
            {
              unitType: '',
              currency: 'USD',
              costPerUnit: 140,
              numberOfUnits: 1,
              totalCost: 140,
              containerId: 48797,
              contractId: 6283,
              transportationIds: [
                48917,
                48918,
              ],
              applied: true,
              designation: 'DESTINATION',
              measureBy: 'FLAT',
              priceBy: 'BOL',
              chargeCode: {
                code: 'CCLR',
                description: 'Customs Clearance',
                mode: 'ALL',
                occurrence: 'ADDITIONAL',
                type: 'CHARGE',
                subType: 'ACCESSORIAL',
              },
            },
            {
              unitType: '42G0',
              currency: 'USD',
              costPerUnit: 0.5,
              numberOfUnits: 1,
              totalCost: 0.5,
              containerId: 48798,
              contractId: 6283,
              transportationIds: [
                48917,
                48918,
              ],
              applied: true,
              designation: 'ORIGIN',
              measureBy: 'KM_MILE',
              priceBy: 'CONTAINER',
              chargeCode: {
                code: 'PKP',
                description: 'Pickup',
                mode: 'ROAD',
                occurrence: 'MANDATORY',
                type: 'CHARGE',
                subType: 'ACCESSORIAL',
              },
            },
            {
              unitType: '',
              currency: 'USD',
              costPerUnit: 10,
              numberOfUnits: 1,
              totalCost: 10,
              containerId: 48798,
              contractId: 6283,
              transportationIds: [
                48917,
                48918,
              ],
              applied: true,
              designation: 'ORIGIN',
              measureBy: 'DAY',
              priceBy: 'CONTAINER',
              chargeCode: {
                code: 'CHAOR',
                description: 'Origin Chassis Fee',
                mode: 'ROAD',
                occurrence: 'MANDATORY',
                type: 'SURCHARGE',
                subType: 'ACCESSORIAL',
              },
            },
            {
              unitType: '',
              subjectTo: 'APPLICABLE',
              currency: 'USD',
              costPerUnit: 43,
              numberOfUnits: 1,
              totalCost: 43,
              containerId: 48798,
              contractId: 6283,
              transportationIds: [
                48917,
                48918,
              ],
              applied: true,
              designation: 'ORIGIN',
              measureBy: 'FLAT',
              priceBy: 'BOL',
              chargeCode: {
                code: 'OISPS',
                description: 'Origin Port ISPS',
                mode: 'OCEAN',
                occurrence: 'MANDATORY',
                type: 'SURCHARGE',
                subType: 'ACCESSORIAL',
              },
            },
            {
              unitType: '',
              subjectTo: 'APPLICABLE',
              currency: 'USD',
              costPerUnit: 100,
              numberOfUnits: 1,
              totalCost: 100,
              containerId: 48798,
              contractId: 6283,
              transportationIds: [
                48917,
                48918,
              ],
              applied: true,
              designation: 'ORIGIN',
              measureBy: 'FLAT',
              priceBy: 'BOL',
              chargeCode: {
                code: 'OTHC',
                description: 'Origin Terminal Handling Charge',
                mode: 'OCEAN',
                occurrence: 'MANDATORY',
                type: 'SURCHARGE',
                subType: 'ACCESSORIAL',
              },
            },
            {
              unitType: '',
              subjectTo: 'APPLICABLE',
              currency: 'USD',
              costPerUnit: 5,
              numberOfUnits: 1,
              totalCost: 5,
              containerId: 48798,
              contractId: 6283,
              transportationIds: [
                48917,
                48918,
              ],
              applied: true,
              designation: 'ORIGIN',
              measureBy: 'FLAT',
              priceBy: 'BOL',
              chargeCode: {
                code: 'EODOC',
                description: 'Electronic Origin Documentation Fee',
                mode: 'OCEAN',
                occurrence: 'MANDATORY',
                type: 'FEE',
                subType: 'ACCESSORIAL',
              },
            },
            {
              unitType: '',
              currency: 'USD',
              costPerUnit: 12,
              numberOfUnits: 1,
              totalCost: 12,
              containerId: 48798,
              contractId: 6283,
              transportationIds: [
                48917,
                48918,
              ],
              applied: true,
              designation: 'ORIGIN',
              measureBy: 'FLAT',
              priceBy: 'BOL',
              chargeCode: {
                code: 'AMS',
                description: 'Filing Charge',
                mode: 'ALL',
                occurrence: 'ADDITIONAL',
                type: 'CHARGE',
                subType: 'ACCESSORIAL',
              },
            },
            {
              unitType: '42G0',
              currency: 'USD',
              costPerUnit: 100,
              numberOfUnits: 1,
              totalCost: 100,
              containerId: 48798,
              contractId: 6283,
              transportationIds: [
                48917,
                48918,
              ],
              applied: true,
              designation: 'FREIGHT',
              measureBy: 'CONTAINER_TYPE',
              priceBy: 'CONTAINER',
              chargeCode: {
                code: 'FRT',
                description: 'Freight',
                mode: 'OCEAN',
                occurrence: 'MANDATORY',
                type: 'CHARGE',
                subType: 'ACCESSORIAL',
              },
            },
            {
              unitType: '42G0',
              subjectTo: 'APPLICABLE',
              currency: 'USD',
              costPerUnit: 1,
              numberOfUnits: 1,
              totalCost: 1,
              containerId: 48798,
              contractId: 6283,
              transportationIds: [
                48917,
                48918,
              ],
              applied: true,
              designation: 'FREIGHT',
              measureBy: 'CONTAINER_TYPE',
              priceBy: 'CONTAINER',
              chargeCode: {
                code: 'CSF',
                description: 'Carrier Security Surcharge',
                mode: 'OCEAN',
                occurrence: 'MANDATORY',
                type: 'SURCHARGE',
                subType: 'ACCESSORIAL',
              },
            },
            {
              unitType: '',
              subjectTo: 'INCLUDED',
              currency: 'USD',
              costPerUnit: 0,
              numberOfUnits: 1,
              totalCost: 0,
              containerId: 48798,
              contractId: 6283,
              transportationIds: [
                48917,
                48918,
              ],
              applied: true,
              designation: 'FREIGHT',
              measureBy: 'CONTAINER_TYPE',
              priceBy: 'CONTAINER',
              chargeCode: {
                code: 'ECA',
                description: 'Emission Control Charge',
                mode: 'OCEAN',
                occurrence: 'MANDATORY',
                type: 'SURCHARGE',
                subType: 'ACCESSORIAL',
              },
            },
            {
              unitType: '',
              subjectTo: 'INCLUDED',
              currency: 'USD',
              costPerUnit: 0,
              numberOfUnits: 1,
              totalCost: 0,
              containerId: 48798,
              contractId: 6283,
              transportationIds: [
                48917,
                48918,
              ],
              applied: true,
              designation: 'FREIGHT',
              measureBy: 'CONTAINER_TYPE',
              priceBy: 'CONTAINER',
              chargeCode: {
                code: 'EMS',
                description: 'Emergency Surcharge - Piracy Surcharge',
                mode: 'OCEAN',
                occurrence: 'MANDATORY',
                type: 'SURCHARGE',
                subType: 'ACCESSORIAL',
              },
            },
            {
              unitType: '',
              subjectTo: 'APPLICABLE',
              currency: 'USD',
              costPerUnit: 37,
              numberOfUnits: 1,
              totalCost: 37,
              containerId: 48798,
              contractId: 6283,
              transportationIds: [
                48917,
                48918,
              ],
              applied: true,
              designation: 'FREIGHT',
              measureBy: 'CONTAINER_TYPE',
              priceBy: 'CONTAINER',
              chargeCode: {
                code: 'PCC',
                description: 'Panama Canal Surcharge',
                mode: 'OCEAN',
                occurrence: 'MANDATORY',
                type: 'SURCHARGE',
                subType: 'ACCESSORIAL',
              },
            },
            {
              unitType: '',
              subjectTo: 'APPLICABLE',
              currency: 'USD',
              costPerUnit: 3,
              numberOfUnits: 1,
              totalCost: 3,
              containerId: 48798,
              contractId: 6283,
              transportationIds: [
                48917,
                48918,
              ],
              applied: false,
              designation: 'FREIGHT',
              measureBy: 'FLAT',
              priceBy: 'BOL',
              chargeCode: {
                code: 'ACT',
                description: 'Alameda Corridor Surcharge',
                mode: 'OCEAN',
                occurrence: 'CONDITIONAL',
                type: 'SURCHARGE',
                subType: 'ACCESSORIAL',
              },
            },
            {
              unitType: '',
              currency: 'USD',
              costPerUnit: 0.5,
              numberOfUnits: 1,
              totalCost: 0.5,
              containerId: 48798,
              contractId: 6283,
              transportationIds: [
                48917,
                48918,
              ],
              applied: true,
              designation: 'DESTINATION',
              measureBy: 'KM_MILE',
              priceBy: 'CONTAINER',
              chargeCode: {
                code: 'DLV',
                description: 'Delivery',
                mode: 'ROAD',
                occurrence: 'MANDATORY',
                type: 'CHARGE',
                subType: 'ACCESSORIAL',
              },
            },
            {
              unitType: '',
              subjectTo: 'APPLICABLE',
              currency: 'USD',
              costPerUnit: 10,
              numberOfUnits: 1,
              totalCost: 10,
              containerId: 48798,
              contractId: 6283,
              transportationIds: [
                48917,
                48918,
              ],
              applied: true,
              designation: 'DESTINATION',
              measureBy: 'DAY',
              priceBy: 'CONTAINER',
              chargeCode: {
                code: 'CHADE',
                description: 'Destination Chassis Fee',
                mode: 'ROAD',
                occurrence: 'CONDITIONAL',
                type: 'SURCHARGE',
                subType: 'ACCESSORIAL',
              },
            },
            {
              unitType: '',
              subjectTo: 'APPLICABLE',
              currency: 'USD',
              costPerUnit: 45,
              numberOfUnits: 1,
              totalCost: 45,
              containerId: 48798,
              contractId: 6283,
              transportationIds: [
                48917,
                48918,
              ],
              applied: true,
              designation: 'DESTINATION',
              measureBy: 'FLAT',
              priceBy: 'BOL',
              chargeCode: {
                code: 'DDOC',
                description: 'Destination Documentation Fee ( Manuel)',
                mode: 'OCEAN',
                occurrence: 'MANDATORY',
                type: 'FEE',
                subType: 'ACCESSORIAL',
              },
            },
            {
              unitType: '',
              subjectTo: 'APPLICABLE',
              currency: 'USD',
              costPerUnit: 12,
              numberOfUnits: 1,
              totalCost: 12,
              containerId: 48798,
              contractId: 6283,
              transportationIds: [
                48917,
                48918,
              ],
              applied: true,
              designation: 'DESTINATION',
              measureBy: 'FLAT',
              priceBy: 'BOL',
              chargeCode: {
                code: 'HBL',
                description: 'House Bill of Lading Fee',
                mode: 'ALL',
                occurrence: 'MANDATORY',
                type: 'FEE',
                subType: 'ACCESSORIAL',
              },
            },
            {
              unitType: '',
              subjectTo: 'APPLICABLE',
              currency: 'USD',
              costPerUnit: 54.1,
              numberOfUnits: 1,
              totalCost: 54.1,
              containerId: 48798,
              contractId: 6283,
              transportationIds: [
                48917,
                48918,
              ],
              applied: true,
              designation: 'DESTINATION',
              measureBy: 'FLAT',
              priceBy: 'BOL',
              chargeCode: {
                code: 'OTHC',
                description: 'Origin Terminal Handling ChargE',
                mode: 'OCEAN',
                occurrence: 'MANDATORY',
                type: 'SURCHARGE',
                subType: 'ACCESSORIAL',
              },
            },
            {
              unitType: '',
              currency: 'USD',
              costPerUnit: 14,
              numberOfUnits: 1,
              totalCost: 14,
              containerId: 48798,
              contractId: 6283,
              transportationIds: [
                48917,
                48918,
              ],
              applied: true,
              designation: 'DESTINATION',
              measureBy: 'FLAT',
              priceBy: 'BOL',
              chargeCode: {
                code: 'CCLR',
                description: 'Customs Clearance',
                mode: 'ALL',
                occurrence: 'ADDITIONAL',
                type: 'CHARGE',
                subType: 'ACCESSORIAL',
              },
            },
          ],
          transportations: [
            {
              id: 48917,
              transport: {
                number: 'UA231A',
                name: 'MSC BARBARA',
                type: 'SEA',
              },
              schedule: {
                type: 'OCEAN',
                departureTime: '2022-08-20T17:00:00-04:00',
                arrivalTime: '2022-09-18T18:00:00-03:00',
                transitDuration: 41760,
                terminalCutOff: '2022-08-17T16:00:00-04:00',
                documentCutOff: '2022-08-16T12:00:00-04:00',
              },
              arrivalTerminal: {
                name: 'TERMINAL CUENCA DEL PLATA',
              },
              departureTerminal: {
                name: 'GLOBAL CONTAINER TERMINAL - NEW YORK',
              },
              containers: [
                48796,
                48797,
                48798,
              ],
              transportLeg: 7650,
              voyageCode: '1121-058W',
            },
            {
              id: 48918,
              transport: {
                number: 'TBN',
                name: 'TBN',
                type: 'SEA',
              },
              schedule: {
                type: 'OCEAN',
                departureTime: '2022-09-21T18:00:00-03:00',
                arrivalTime: '2022-09-24T18:00:00-03:00',
                transitDuration: 4320,
              },
              arrivalTerminal: {
                name: 'TERMINAL PORTUARIA ROSARIO',
              },
              departureTerminal: {
                name: 'MONTECON',
              },
              containers: [
                48796,
                48797,
                48798,
              ],
              transportLeg: 7651,
              voyageCode: '1121-058W',
            },
          ],
        },
        {
          id: 37148,
          oceanScheduleId: 902,
          recommended: false,
          plans: [],
          departureTime: '2022-08-20T17:00:00-04:00',
          arrivalTime: '2022-09-24T18:00:00-03:00',
          totalDuration: 50400,
          terminalCutOff: '2022-08-17T16:00:00-04:00',
          documentCutOff: '2022-08-16T12:00:00-04:00',
          hazmatCutOff: '',
          charges: [],
          transportations: [
            {
              id: 48917,
              transport: {
                number: 'UA231A',
                name: 'MSC BARBARA',
                type: 'SEA',
              },
              schedule: {
                type: 'OCEAN',
                departureTime: '2022-08-20T17:00:00-04:00',
                arrivalTime: '2022-09-18T18:00:00-03:00',
                transitDuration: 41760,
                terminalCutOff: '2022-08-17T16:00:00-04:00',
                documentCutOff: '2022-08-16T12:00:00-04:00',
              },
              arrivalTerminal: {
                name: 'TERMINAL CUENCA DEL PLATA',
              },
              departureTerminal: {
                name: 'GLOBAL CONTAINER TERMINAL - NEW YORK',
              },
              containers: [
                48796,
                48797,
                48798,
              ],
              transportLeg: 7650,
              voyageCode: '1121-058W',
            },
            {
              id: 48918,
              transport: {
                number: 'TBN',
                name: 'TBN',
                type: 'SEA',
              },
              schedule: {
                type: 'OCEAN',
                departureTime: '2022-09-21T18:00:00-03:00',
                arrivalTime: '2022-09-24T18:00:00-03:00',
                transitDuration: 4320,
              },
              arrivalTerminal: {
                name: 'TERMINAL PORTUARIA ROSARIO',
              },
              departureTerminal: {
                name: 'MONTECON',
              },
              containers: [
                48796,
                48797,
                48798,
              ],
              transportLeg: 7651,
              voyageCode: '1121-058W',
            },
          ],
        },
        {
          id: 37148,
          oceanScheduleId: 902,
          recommended: false,
          departureTime: '2022-08-20T17:00:00-04:00',
          arrivalTime: '2022-09-24T18:00:00-03:00',
          totalDuration: 50400,
          terminalCutOff: '2022-08-17T16:00:00-04:00',
          documentCutOff: '2022-08-16T12:00:00-04:00',
          hazmatCutOff: '',
          charges: [],
          plans: [],
          transportations: [
            {
              id: 48917,
              transport: {
                number: 'UA231A',
                name: 'MSC BARBARA',
                type: 'SEA',
              },
              schedule: {
                type: 'OCEAN',
                departureTime: '2022-08-20T17:00:00-04:00',
                arrivalTime: '2022-09-18T18:00:00-03:00',
                transitDuration: 41760,
                terminalCutOff: '2022-08-17T16:00:00-04:00',
                documentCutOff: '2022-08-16T12:00:00-04:00',
              },
              arrivalTerminal: {
                name: 'TERMINAL CUENCA DEL PLATA',
              },
              departureTerminal: {
                name: 'GLOBAL CONTAINER TERMINAL - NEW YORK',
              },
              containers: [
                48796,
                48797,
                48798,
              ],
              transportLeg: 7650,
              voyageCode: '1121-058W',
            },
            {
              id: 48918,
              transport: {
                number: 'TBN',
                name: 'TBN',
                type: 'SEA',
              },
              schedule: {
                type: 'OCEAN',
                departureTime: '2022-09-21T18:00:00-03:00',
                arrivalTime: '2022-09-24T18:00:00-03:00',
                transitDuration: 4320,
              },
              arrivalTerminal: {
                name: 'TERMINAL PORTUARIA ROSARIO',
              },
              departureTerminal: {
                name: 'MONTECON',
              },
              containers: [
                48796,
                48797,
                48798,
              ],
              transportLeg: 7651,
              voyageCode: '1121-058W',
            },
          ],
        },
        {
          id: 37148,
          oceanScheduleId: 902,
          recommended: false,
          departureTime: '2022-08-20T17:00:00-04:00',
          arrivalTime: '2022-09-24T18:00:00-03:00',
          totalDuration: 50400,
          terminalCutOff: '2022-08-17T16:00:00-04:00',
          documentCutOff: '2022-08-16T12:00:00-04:00',
          hazmatCutOff: '',
          charges: [],
          plans: [],
          transportations: [
            {
              id: 48917,
              transport: {
                number: 'UA231A',
                name: 'MSC BARBARA',
                type: 'SEA',
              },
              schedule: {
                type: 'OCEAN',
                departureTime: '2022-08-20T17:00:00-04:00',
                arrivalTime: '2022-09-18T18:00:00-03:00',
                transitDuration: 41760,
                terminalCutOff: '2022-08-17T16:00:00-04:00',
                documentCutOff: '2022-08-16T12:00:00-04:00',
              },
              arrivalTerminal: {
                name: 'TERMINAL CUENCA DEL PLATA',
              },
              departureTerminal: {
                name: 'GLOBAL CONTAINER TERMINAL - NEW YORK',
              },
              containers: [
                48796,
                48797,
                48798,
              ],
              transportLeg: 7650,
              voyageCode: '1121-058W',
            },
            {
              id: 48918,
              transport: {
                number: 'TBN',
                name: 'TBN',
                type: 'SEA',
              },
              schedule: {
                type: 'OCEAN',
                departureTime: '2022-09-21T18:00:00-03:00',
                arrivalTime: '2022-09-24T18:00:00-03:00',
                transitDuration: 4320,
              },
              arrivalTerminal: {
                name: 'TERMINAL PORTUARIA ROSARIO',
              },
              departureTerminal: {
                name: 'MONTECON',
              },
              containers: [
                48796,
                48797,
                48798,
              ],
              transportLeg: 7651,
              voyageCode: '1121-058W',
            },
          ],
        },
      ],
      contracts: [
        {
          id: 6283,
          number: '31',
          name: 'MSCU julia2',
          scac: 'MSCU',
        },
      ],
      containers: [
        {
          id: 48796,
          type: '22G0',
          ownContainer: false,
          rateId: 151,
          weight: 3,
          volume: 4,
          commodities: [],
        },
        {
          id: 48797,
          type: '22G0',
          ownContainer: false,
          rateId: 151,
          weight: 3,
          volume: 4,
          commodities: [],
        },
        {
          id: 48798,
          type: '42G0',
          ownContainer: false,
          rateId: 152,
          weight: 38,
          volume: 46,
          commodities: [],
        },
      ],
      customer: {},
      bookmark: QuotaBookmark.fromPlain({
        id: 0,
        name: 'itemContent?.bookmark?.name',
        createdAt: 'itemContent?.bookmark?.createdAt',
        createdBy: 'itemContent?.bookmark?.createdBy',
      }),
      validPeriod: {},
    },
  ],
  pageable: {
    sort: {
      empty: true,
      sorted: false,
      unsorted: true,
    },
    offset: 0,
    pageNumber: 0,
    pageSize: 6,
    paged: true,
    unpaged: false,
  },
  last: true,
  totalPages: 1,
  totalElements: 1,
  size: 6,
  number: 0,
  sort: {
    empty: true,
    sorted: false,
    unsorted: true,
  },
  first: true,
  numberOfElements: 1,
  empty: false,
};

export const data = FreightQuotaContentDTM.fromPlain({
  id: 5939,
  cost: FreightQuotaContentCostDTM.fromPlain({
    totalCost: 70000,
    originTotalCost: 7000,
    freightTotalCost: 9000,
    destinationTotalCost: 67000,
  }),
  routes: [
    FreightQuotaContentRoutesDTM.fromPlain({
      origin: FreightQuotaContentRoutesLocationDTM.fromPlain({
        type: 'PORT',
        id: 86212,
        country: FreightSelectFieldCountryDTM.fromPlain({
          code: 'US',
          name: 'United States',
        }),
        state: FreightSelectFieldCountryDTM.fromPlain({
          code: 'NY',
          name: 'NY',
        }),
        city: undefined,
        name: 'New York',
        code: 'USNYC',
        address: undefined,
        postalCode: undefined,
        timeZoneId: 'America/New_York',
        coordinates: FreightSelectFieldCoordinatesDTM.fromPlain({
          lat: '40.7000007629395',
          lng: '-74',
        }),
      }),
      destination: FreightQuotaContentRoutesLocationDTM.fromPlain({
        type: 'PORT',
        id: 86211,
        country: FreightSelectFieldCountryDTM.fromPlain({
          code: 'AR',
          name: 'Argentina',
        }),
        state: FreightSelectFieldCountryDTM.fromPlain({
          code: 'S',
          name: 'S',
        }),
        city: undefined,
        name: 'Rosario',
        code: 'ARROS',
        address: undefined,
        postalCode: undefined,
        timeZoneId: 'America/Cordoba',
        coordinates: FreightSelectFieldCoordinatesDTM.fromPlain({
          lat: '-32.977',
          lng: '-60.61863',
        }),
      }),
      legs: [
        FreightQuotaContentRouteLegDTM.fromPlain({
          id: 7650,
          phase: 'FREIGHT',
          arrivalLocation: QuotaServiceByIdContentRoutesLegsLocationDTM.fromPlain({
            type: 'PORT',
            id: 86213,
            country: FreightSelectFieldCountryDTM.fromPlain({
              code: 'UY',
              name: 'Uruguay',
            }),
            state: undefined,
            coordinates: FreightSelectFieldCoordinatesDTM.fromPlain({
              lat: '-34.89977',
              lng: '-56.1958',
            }),
            timeZoneId: 'America/Montevideo',
            code: 'UYMVD',
            name: 'Montevideo',
          }),
          departureLocation: QuotaServiceByIdContentRoutesLegsLocationDTM.fromPlain({
            type: 'PORT',
            id: 86212,
            country: FreightSelectFieldCountryDTM.fromPlain({
              code: 'US',
              name: 'United States',
            }),
            state: FreightSelectFieldCountryDTM.fromPlain({
              code: 'NY',
              name: 'New York',
            }),
            coordinates: FreightSelectFieldCoordinatesDTM.fromPlain({
              lat: '40.7000007629395',
              lng: '-74',
            }),
            timeZoneId: 'America/New_York',
            code: 'USNYC',
            name: 'New York',
          }),
        }),
        FreightQuotaContentRouteLegDTM.fromPlain({
          id: 7651,
          phase: 'FREIGHT',
          arrivalLocation: QuotaServiceByIdContentRoutesLegsLocationDTM.fromPlain({
            type: 'PORT',
            id: 86211,
            country: FreightSelectFieldCountryDTM.fromPlain({
              code: 'AR',
              name: 'Argentina',
            }),
            state: FreightSelectFieldCountryDTM.fromPlain({
              code: 'S',
              name: 'Santa Fe',
            }),
            coordinates: FreightSelectFieldCoordinatesDTM.fromPlain({
              lat: '-32.977',
              lng: '-60.61863',
            }),
            timeZoneId: 'America/Cordoba',
            code: 'ARROS',
            name: 'Rosario',
          }),
          departureLocation: QuotaServiceByIdContentRoutesLegsLocationDTM.fromPlain({
            type: 'PORT',
            id: 86214,
            country: FreightSelectFieldCountryDTM.fromPlain({
              code: 'UY',
              name: 'Uruguay',
            }),
            state: undefined,
            coordinates: FreightSelectFieldCoordinatesDTM.fromPlain({
              lat: '-34.89977',
              lng: '-56.1958',
            }),
            timeZoneId: 'America/Montevideo',
            code: 'UYMVD',
            name: 'Montevideo',
          }),
        }),
      ],
      containerIds: [
        48796,
        48797,
        48798,
      ],
    }),
  ],
  schedules: [
    FreightQuotaContentSchedulesDTM.fromPlain({
      id: 37148,
      oceanScheduleId: 902,
      recommended: true,
      departureTime: '2022-08-20T17:00:00-04:00',
      arrivalTime: '2022-09-24T18:00:00-03:00',
      totalDuration: 50400,
      terminalCutOff: '2022-08-17T16:00:00-04:00',
      documentCutOff: '2022-08-16T12:00:00-04:00',
      plans: [],
      hazmatCutOff: '',
      charges: [
        FreightQuotaContentSchedulesChargesDTM.fromPlain({
          unitType: '22G0',
          currency: 'USD',
          costPerUnit: 3.5,
          numberOfUnits: 1,
          totalCost: 3.5,
          containerId: 48796,
          contractId: 6283,
          transportationIds: [
            48917,
            48918,
          ],
          applied: true,
          designation: 'ORIGIN',
          measureBy: 'KM_MILE',
          priceBy: 'CONTAINER',
          chargeCode: FreightQuotaContentSchedulesChargesChargeCodeDTM.fromPlain({
            code: 'PKP',
            description: 'Pickup',
            mode: 'ROAD',
            occurrence: 'MANDATORY',
            type: 'CHARGE',
            subType: 'ACCESSORIAL',
          }),
        }),
        FreightQuotaContentSchedulesChargesDTM.fromPlain({
          unitType: '22G0',
          currency: 'USD',
          costPerUnit: 3.5,
          numberOfUnits: 1,
          totalCost: 3.5,
          containerId: 48797,
          contractId: 6283,
          transportationIds: [
            48917,
            48918,
          ],
          applied: true,
          designation: 'ORIGIN',
          measureBy: 'KM_MILE',
          priceBy: 'CONTAINER',
          chargeCode: FreightQuotaContentSchedulesChargesChargeCodeDTM.fromPlain({
            code: 'PKP',
            description: 'Pickup',
            mode: 'ROAD',
            occurrence: 'MANDATORY',
            type: 'CHARGE',
            subType: 'ACCESSORIAL',
          }),
        }),
        FreightQuotaContentSchedulesChargesDTM.fromPlain({
          unitType: '',
          subjectTo: undefined,
          currency: 'USD',
          costPerUnit: 100,
          numberOfUnits: 1,
          totalCost: 100,
          containerId: 48796,
          contractId: 6283,
          transportationIds: [
            48917,
            48918,
          ],
          applied: true,
          designation: 'ORIGIN',
          measureBy: 'DAY',
          priceBy: 'CONTAINER',
          chargeCode: FreightQuotaContentSchedulesChargesChargeCodeDTM.fromPlain({
            code: 'CHAOR',
            description: 'Origin Chassis Fee',
            mode: 'ROAD',
            occurrence: 'MANDATORY',
            type: 'SURCHARGE',
            subType: 'ACCESSORIAL',
          }),
        }),
        FreightQuotaContentSchedulesChargesDTM.fromPlain({
          unitType: '',
          subjectTo: undefined,
          currency: 'USD',
          costPerUnit: 100,
          numberOfUnits: 1,
          totalCost: 100,
          containerId: 48797,
          contractId: 6283,
          transportationIds: [
            48917,
            48918,
          ],
          applied: true,
          designation: 'ORIGIN',
          measureBy: 'DAY',
          priceBy: 'CONTAINER',
          chargeCode: FreightQuotaContentSchedulesChargesChargeCodeDTM.fromPlain({
            code: 'CHAOR',
            description: 'Origin Chassis Fee',
            mode: 'ROAD',
            occurrence: 'MANDATORY',
            type: 'SURCHARGE',
            subType: 'ACCESSORIAL',
          }),
        }),
        FreightQuotaContentSchedulesChargesDTM.fromPlain({
          unitType: '',
          subjectTo: 'APPLICABLE',
          currency: 'USD',
          costPerUnit: 4,
          numberOfUnits: 1,
          totalCost: 4,
          containerId: 48796,
          contractId: 6283,
          transportationIds: [
            48917,
            48918,
          ],
          applied: true,
          designation: 'ORIGIN',
          measureBy: 'FLAT',
          priceBy: 'BOL',
          chargeCode: FreightQuotaContentSchedulesChargesChargeCodeDTM.fromPlain({
            code: 'OISPS',
            description: 'Origin Port ISPS',
            mode: 'OCEAN',
            occurrence: 'MANDATORY',
            type: 'SURCHARGE',
            subType: 'ACCESSORIAL',
          }),
        }),
        FreightQuotaContentSchedulesChargesDTM.fromPlain({
          unitType: '',
          subjectTo: 'APPLICABLE',
          currency: 'USD',
          costPerUnit: 4,
          numberOfUnits: 1,
          totalCost: 4,
          containerId: 48797,
          contractId: 6283,
          transportationIds: [
            48917,
            48918,
          ],
          applied: true,
          designation: 'ORIGIN',
          measureBy: 'FLAT',
          priceBy: 'BOL',
          chargeCode: FreightQuotaContentSchedulesChargesChargeCodeDTM.fromPlain({
            code: 'OISPS',
            description: 'Origin Port ISPS',
            mode: 'OCEAN',
            occurrence: 'MANDATORY',
            type: 'SURCHARGE',
            subType: 'ACCESSORIAL',
          }),
        }),
        FreightQuotaContentSchedulesChargesDTM.fromPlain({
          unitType: '',
          subjectTo: 'APPLICABLE',
          currency: 'USD',
          costPerUnit: 1000,
          numberOfUnits: 1,
          totalCost: 1000,
          containerId: 48796,
          contractId: 6283,
          transportationIds: [
            48917,
            48918,
          ],
          applied: true,
          designation: 'ORIGIN',
          measureBy: 'FLAT',
          priceBy: 'BOL',
          chargeCode: FreightQuotaContentSchedulesChargesChargeCodeDTM.fromPlain({
            code: 'OTHC',
            description: 'Origin Terminal Handling Charge',
            mode: 'OCEAN',
            occurrence: 'MANDATORY',
            type: 'SURCHARGE',
            subType: 'ACCESSORIAL',
          }),
        }),
        FreightQuotaContentSchedulesChargesDTM.fromPlain({
          unitType: '',
          subjectTo: 'APPLICABLE',
          currency: 'USD',
          costPerUnit: 1000,
          numberOfUnits: 1,
          totalCost: 1000,
          containerId: 48797,
          contractId: 6283,
          transportationIds: [
            48917,
            48918,
          ],
          applied: true,
          designation: 'ORIGIN',
          measureBy: 'FLAT',
          priceBy: 'BOL',
          chargeCode: FreightQuotaContentSchedulesChargesChargeCodeDTM.fromPlain({
            code: 'OTHC',
            description: 'Origin Terminal Handling Charge',
            mode: 'OCEAN',
            occurrence: 'MANDATORY',
            type: 'SURCHARGE',
            subType: 'ACCESSORIAL',
          }),
        }),
        FreightQuotaContentSchedulesChargesDTM.fromPlain({
          unitType: '',
          subjectTo: 'APPLICABLE',
          currency: 'USD',
          costPerUnit: 50,
          numberOfUnits: 1,
          totalCost: 50,
          containerId: 48796,
          contractId: 6283,
          transportationIds: [
            48917,
            48918,
          ],
          applied: true,
          designation: 'ORIGIN',
          measureBy: 'FLAT',
          priceBy: 'BOL',
          chargeCode: FreightQuotaContentSchedulesChargesChargeCodeDTM.fromPlain({
            code: 'EODOC',
            description: 'Electronic Origin Documentation Fee',
            mode: 'OCEAN',
            occurrence: 'MANDATORY',
            type: 'FEE',
            subType: 'ACCESSORIAL',
          }),
        }),
        FreightQuotaContentSchedulesChargesDTM.fromPlain({
          unitType: '',
          subjectTo: 'APPLICABLE',
          currency: 'USD',
          costPerUnit: 50,
          numberOfUnits: 1,
          totalCost: 50,
          containerId: 48797,
          contractId: 6283,
          transportationIds: [
            48917,
            48918,
          ],
          applied: true,
          designation: 'ORIGIN',
          measureBy: 'FLAT',
          priceBy: 'BOL',
          chargeCode: FreightQuotaContentSchedulesChargesChargeCodeDTM.fromPlain({
            code: 'EODOC',
            description: 'Electronic Origin Documentation Fee',
            mode: 'OCEAN',
            occurrence: 'MANDATORY',
            type: 'FEE',
            subType: 'ACCESSORIAL',
          }),
        }),
        FreightQuotaContentSchedulesChargesDTM.fromPlain({
          unitType: '',
          subjectTo: undefined,
          currency: 'USD',
          costPerUnit: 120,
          numberOfUnits: 1,
          totalCost: 120,
          containerId: 48796,
          contractId: 6283,
          transportationIds: [
            48917,
            48918,
          ],
          applied: true,
          designation: 'ORIGIN',
          measureBy: 'FLAT',
          priceBy: 'BOL',
          chargeCode: FreightQuotaContentSchedulesChargesChargeCodeDTM.fromPlain({
            code: 'AMS',
            description: 'Filing Charge',
            mode: 'ALL',
            occurrence: 'ADDITIONAL',
            type: 'CHARGE',
            subType: 'ACCESSORIAL',
          }),
        }),
        FreightQuotaContentSchedulesChargesDTM.fromPlain({
          unitType: '',
          subjectTo: undefined,
          currency: 'USD',
          costPerUnit: 120,
          numberOfUnits: 1,
          totalCost: 120,
          containerId: 48797,
          contractId: 6283,
          transportationIds: [
            48917,
            48918,
          ],
          applied: true,
          designation: 'ORIGIN',
          measureBy: 'FLAT',
          priceBy: 'BOL',
          chargeCode: FreightQuotaContentSchedulesChargesChargeCodeDTM.fromPlain({
            code: 'AMS',
            description: 'Filing Charge',
            mode: 'ALL',
            occurrence: 'ADDITIONAL',
            type: 'CHARGE',
            subType: 'ACCESSORIAL',
          }),
        }),
        FreightQuotaContentSchedulesChargesDTM.fromPlain({
          unitType: '22G0',
          currency: 'USD',
          costPerUnit: 1000,
          numberOfUnits: 1,
          totalCost: 1000,
          containerId: 48796,
          contractId: 6283,
          transportationIds: [
            48917,
            48918,
          ],
          applied: true,
          designation: 'FREIGHT',
          measureBy: 'CONTAINER_TYPE',
          priceBy: 'CONTAINER',
          chargeCode: FreightQuotaContentSchedulesChargesChargeCodeDTM.fromPlain({
            code: 'FRT',
            description: 'Freight',
            mode: 'OCEAN',
            occurrence: 'MANDATORY',
            type: 'CHARGE',
            subType: 'ACCESSORIAL',
          }),
        }),
        FreightQuotaContentSchedulesChargesDTM.fromPlain({
          unitType: '22G0',
          currency: 'USD',
          costPerUnit: 1000,
          numberOfUnits: 1,
          totalCost: 1000,
          containerId: 48797,
          contractId: 6283,
          transportationIds: [
            48917,
            48918,
          ],
          applied: true,
          designation: 'FREIGHT',
          measureBy: 'CONTAINER_TYPE',
          priceBy: 'CONTAINER',
          chargeCode: FreightQuotaContentSchedulesChargesChargeCodeDTM.fromPlain({
            code: 'FRT',
            description: 'Freight',
            mode: 'OCEAN',
            occurrence: 'MANDATORY',
            type: 'CHARGE',
            subType: 'ACCESSORIAL',
          }),
        }),
        FreightQuotaContentSchedulesChargesDTM.fromPlain({
          unitType: '22G0',
          subjectTo: 'APPLICABLE',
          currency: 'USD',
          costPerUnit: 15,
          numberOfUnits: 1,
          totalCost: 15,
          containerId: 48796,
          contractId: 6283,
          transportationIds: [
            48917,
            48918,
          ],
          applied: true,
          designation: 'FREIGHT',
          measureBy: 'CONTAINER_TYPE',
          priceBy: 'CONTAINER',
          chargeCode: FreightQuotaContentSchedulesChargesChargeCodeDTM.fromPlain({
            code: 'CSF',
            description: 'Carrier Security Surcharge',
            mode: 'OCEAN',
            occurrence: 'MANDATORY',
            type: 'SURCHARGE',
            subType: 'ACCESSORIAL',
          }),
        }),
        FreightQuotaContentSchedulesChargesDTM.fromPlain({
          unitType: '22G0',
          subjectTo: 'APPLICABLE',
          currency: 'USD',
          costPerUnit: 15,
          numberOfUnits: 1,
          totalCost: 15,
          containerId: 48797,
          contractId: 6283,
          transportationIds: [
            48917,
            48918,
          ],
          applied: true,
          designation: 'FREIGHT',
          measureBy: 'CONTAINER_TYPE',
          priceBy: 'CONTAINER',
          chargeCode: FreightQuotaContentSchedulesChargesChargeCodeDTM.fromPlain({
            code: 'CSF',
            description: 'Carrier Security Surcharge',
            mode: 'OCEAN',
            occurrence: 'MANDATORY',
            type: 'SURCHARGE',
            subType: 'ACCESSORIAL',
          }),
        }),
        FreightQuotaContentSchedulesChargesDTM.fromPlain({
          unitType: '',
          subjectTo: 'INCLUDED',
          currency: 'USD',
          costPerUnit: 0,
          numberOfUnits: 1,
          totalCost: 0,
          containerId: 48796,
          contractId: 6283,
          transportationIds: [
            48917,
            48918,
          ],
          applied: true,
          designation: 'FREIGHT',
          measureBy: 'CONTAINER_TYPE',
          priceBy: 'CONTAINER',
          chargeCode: FreightQuotaContentSchedulesChargesChargeCodeDTM.fromPlain({
            code: 'ECA',
            description: 'Emission Control Charge',
            mode: 'OCEAN',
            occurrence: 'MANDATORY',
            type: 'SURCHARGE',
            subType: 'ACCESSORIAL',
          }),
        }),
        FreightQuotaContentSchedulesChargesDTM.fromPlain({
          unitType: '',
          subjectTo: 'INCLUDED',
          currency: 'USD',
          costPerUnit: 0,
          numberOfUnits: 1,
          totalCost: 0,
          containerId: 48797,
          contractId: 6283,
          transportationIds: [
            48917,
            48918,
          ],
          applied: true,
          designation: 'FREIGHT',
          measureBy: 'CONTAINER_TYPE',
          priceBy: 'CONTAINER',
          chargeCode: FreightQuotaContentSchedulesChargesChargeCodeDTM.fromPlain({
            code: 'ECA',
            description: 'Emission Control Charge',
            mode: 'OCEAN',
            occurrence: 'MANDATORY',
            type: 'SURCHARGE',
            subType: 'ACCESSORIAL',
          }),
        }),
        FreightQuotaContentSchedulesChargesDTM.fromPlain({
          unitType: '',
          subjectTo: 'INCLUDED',
          currency: 'USD',
          costPerUnit: 0,
          numberOfUnits: 1,
          totalCost: 0,
          containerId: 48796,
          contractId: 6283,
          transportationIds: [
            48917,
            48918,
          ],
          applied: true,
          designation: 'FREIGHT',
          measureBy: 'CONTAINER_TYPE',
          priceBy: 'CONTAINER',
          chargeCode: FreightQuotaContentSchedulesChargesChargeCodeDTM.fromPlain({
            code: 'EMS',
            description: 'Emergency Surcharge - Piracy Surcharge',
            mode: 'OCEAN',
            occurrence: 'MANDATORY',
            type: 'SURCHARGE',
            subType: 'ACCESSORIAL',
          }),
        }),
        FreightQuotaContentSchedulesChargesDTM.fromPlain({
          unitType: '',
          subjectTo: 'INCLUDED',
          currency: 'USD',
          costPerUnit: 0,
          numberOfUnits: 1,
          totalCost: 0,
          containerId: 48797,
          contractId: 6283,
          transportationIds: [
            48917,
            48918,
          ],
          applied: true,
          designation: 'FREIGHT',
          measureBy: 'CONTAINER_TYPE',
          priceBy: 'CONTAINER',
          chargeCode: FreightQuotaContentSchedulesChargesChargeCodeDTM.fromPlain({
            code: 'EMS',
            description: 'Emergency Surcharge - Piracy Surcharge',
            mode: 'OCEAN',
            occurrence: 'MANDATORY',
            type: 'SURCHARGE',
            subType: 'ACCESSORIAL',
          }),
        }),
        FreightQuotaContentSchedulesChargesDTM.fromPlain({
          unitType: '',
          subjectTo: 'APPLICABLE',
          currency: 'USD',
          costPerUnit: 377,
          numberOfUnits: 1,
          totalCost: 377,
          containerId: 48796,
          contractId: 6283,
          transportationIds: [
            48917,
            48918,
          ],
          applied: true,
          designation: 'FREIGHT',
          measureBy: 'CONTAINER_TYPE',
          priceBy: 'CONTAINER',
          chargeCode: FreightQuotaContentSchedulesChargesChargeCodeDTM.fromPlain({
            code: 'PCC',
            description: 'Panama Canal Surcharge',
            mode: 'OCEAN',
            occurrence: 'MANDATORY',
            type: 'SURCHARGE',
            subType: 'ACCESSORIAL',
          }),
        }),
        FreightQuotaContentSchedulesChargesDTM.fromPlain({
          unitType: '',
          subjectTo: 'APPLICABLE',
          currency: 'USD',
          costPerUnit: 377,
          numberOfUnits: 1,
          totalCost: 377,
          containerId: 48797,
          contractId: 6283,
          transportationIds: [
            48917,
            48918,
          ],
          applied: true,
          designation: 'FREIGHT',
          measureBy: 'CONTAINER_TYPE',
          priceBy: 'CONTAINER',
          chargeCode: FreightQuotaContentSchedulesChargesChargeCodeDTM.fromPlain({
            code: 'PCC',
            description: 'Panama Canal Surcharge',
            mode: 'OCEAN',
            occurrence: 'MANDATORY',
            type: 'SURCHARGE',
            subType: 'ACCESSORIAL',
          }),
        }),
        FreightQuotaContentSchedulesChargesDTM.fromPlain({
          unitType: '',
          subjectTo: 'APPLICABLE',
          currency: 'USD',
          costPerUnit: 31,
          numberOfUnits: 1,
          totalCost: 31,
          containerId: 48796,
          contractId: 6283,
          transportationIds: [
            48917,
            48918,
          ],
          applied: false,
          designation: 'FREIGHT',
          measureBy: 'FLAT',
          priceBy: 'BOL',
          chargeCode: FreightQuotaContentSchedulesChargesChargeCodeDTM.fromPlain({
            code: 'ACT',
            description: 'Alameda Corridor Surcharge',
            mode: 'OCEAN',
            occurrence: 'CONDITIONAL',
            type: 'SURCHARGE',
            subType: 'ACCESSORIAL',
          }),
        }),
        FreightQuotaContentSchedulesChargesDTM.fromPlain({
          unitType: '',
          subjectTo: 'APPLICABLE',
          currency: 'USD',
          costPerUnit: 31,
          numberOfUnits: 1,
          totalCost: 31,
          containerId: 48797,
          contractId: 6283,
          transportationIds: [
            48917,
            48918,
          ],
          applied: false,
          designation: 'FREIGHT',
          measureBy: 'FLAT',
          priceBy: 'BOL',
          chargeCode: FreightQuotaContentSchedulesChargesChargeCodeDTM.fromPlain({
            code: 'ACT',
            description: 'Alameda Corridor Surcharge',
            mode: 'OCEAN',
            occurrence: 'CONDITIONAL',
            type: 'SURCHARGE',
            subType: 'ACCESSORIAL',
          }),
        }),
        FreightQuotaContentSchedulesChargesDTM.fromPlain({
          unitType: '',
          subjectTo: undefined,
          currency: 'USD',
          costPerUnit: 4.5,
          numberOfUnits: 1,
          totalCost: 4.5,
          containerId: 48796,
          contractId: 6283,
          transportationIds: [
            48917,
            48918,
          ],
          applied: true,
          designation: 'DESTINATION',
          measureBy: 'KM_MILE',
          priceBy: 'CONTAINER',
          chargeCode: FreightQuotaContentSchedulesChargesChargeCodeDTM.fromPlain({
            code: 'DLV',
            description: 'Delivery',
            mode: 'ROAD',
            occurrence: 'MANDATORY',
            type: 'CHARGE',
            subType: 'ACCESSORIAL',
          }),
        }),
        FreightQuotaContentSchedulesChargesDTM.fromPlain({
          unitType: '',
          subjectTo: undefined,
          currency: 'USD',
          costPerUnit: 4.5,
          numberOfUnits: 1,
          totalCost: 4.5,
          containerId: 48797,
          contractId: 6283,
          transportationIds: [
            48917,
            48918,
          ],
          applied: true,
          designation: 'DESTINATION',
          measureBy: 'KM_MILE',
          priceBy: 'CONTAINER',
          chargeCode: FreightQuotaContentSchedulesChargesChargeCodeDTM.fromPlain({
            code: 'DLV',
            description: 'Delivery',
            mode: 'ROAD',
            occurrence: 'MANDATORY',
            type: 'CHARGE',
            subType: 'ACCESSORIAL',
          }),
        }),
        FreightQuotaContentSchedulesChargesDTM.fromPlain({
          unitType: '',
          subjectTo: 'APPLICABLE',
          currency: 'USD',
          costPerUnit: 100,
          numberOfUnits: 1,
          totalCost: 100,
          containerId: 48796,
          contractId: 6283,
          transportationIds: [
            48917,
            48918,
          ],
          applied: true,
          designation: 'DESTINATION',
          measureBy: 'DAY',
          priceBy: 'CONTAINER',
          chargeCode: FreightQuotaContentSchedulesChargesChargeCodeDTM.fromPlain({
            code: 'CHADE',
            description: 'Destination Chassis Fee',
            mode: 'ROAD',
            occurrence: 'CONDITIONAL',
            type: 'SURCHARGE',
            subType: 'ACCESSORIAL',
          }),
        }),
        FreightQuotaContentSchedulesChargesDTM.fromPlain({
          unitType: '',
          subjectTo: 'APPLICABLE',
          currency: 'USD',
          costPerUnit: 100,
          numberOfUnits: 1,
          totalCost: 100,
          containerId: 48797,
          contractId: 6283,
          transportationIds: [
            48917,
            48918,
          ],
          applied: true,
          designation: 'DESTINATION',
          measureBy: 'DAY',
          priceBy: 'CONTAINER',
          chargeCode: FreightQuotaContentSchedulesChargesChargeCodeDTM.fromPlain({
            code: 'CHADE',
            description: 'Destination Chassis Fee',
            mode: 'ROAD',
            occurrence: 'CONDITIONAL',
            type: 'SURCHARGE',
            subType: 'ACCESSORIAL',
          }),
        }),
        FreightQuotaContentSchedulesChargesDTM.fromPlain({
          unitType: '',
          subjectTo: 'APPLICABLE',
          currency: 'USD',
          costPerUnit: 450,
          numberOfUnits: 1,
          totalCost: 450,
          containerId: 48796,
          contractId: 6283,
          transportationIds: [
            48917,
            48918,
          ],
          applied: true,
          designation: 'DESTINATION',
          measureBy: 'FLAT',
          priceBy: 'BOL',
          chargeCode: FreightQuotaContentSchedulesChargesChargeCodeDTM.fromPlain({
            code: 'DDOC',
            description: 'Destination Documentation Fee ( Manuel)',
            mode: 'OCEAN',
            occurrence: 'MANDATORY',
            type: 'FEE',
            subType: 'ACCESSORIAL',
          }),
        }),
        FreightQuotaContentSchedulesChargesDTM.fromPlain({
          unitType: '',
          subjectTo: 'APPLICABLE',
          currency: 'USD',
          costPerUnit: 450,
          numberOfUnits: 1,
          totalCost: 450,
          containerId: 48797,
          contractId: 6283,
          transportationIds: [
            48917,
            48918,
          ],
          applied: true,
          designation: 'DESTINATION',
          measureBy: 'FLAT',
          priceBy: 'BOL',
          chargeCode: FreightQuotaContentSchedulesChargesChargeCodeDTM.fromPlain({
            code: 'DDOC',
            description: 'Destination Documentation Fee ( Manuel)',
            mode: 'OCEAN',
            occurrence: 'MANDATORY',
            type: 'FEE',
            subType: 'ACCESSORIAL',
          }),
        }),
        FreightQuotaContentSchedulesChargesDTM.fromPlain({
          unitType: '',
          subjectTo: 'APPLICABLE',
          currency: 'USD',
          costPerUnit: 120,
          numberOfUnits: 1,
          totalCost: 120,
          containerId: 48796,
          contractId: 6283,
          transportationIds: [
            48917,
            48918,
          ],
          applied: true,
          designation: 'DESTINATION',
          measureBy: 'FLAT',
          priceBy: 'BOL',
          chargeCode: FreightQuotaContentSchedulesChargesChargeCodeDTM.fromPlain({
            code: 'HBL',
            description: 'House Bill of Lading Fee',
            mode: 'ALL',
            occurrence: 'MANDATORY',
            type: 'FEE',
            subType: 'ACCESSORIAL',
          }),
        }),
        FreightQuotaContentSchedulesChargesDTM.fromPlain({
          unitType: '',
          subjectTo: 'APPLICABLE',
          currency: 'USD',
          costPerUnit: 120,
          numberOfUnits: 1,
          totalCost: 120,
          containerId: 48797,
          contractId: 6283,
          transportationIds: [
            48917,
            48918,
          ],
          applied: true,
          designation: 'DESTINATION',
          measureBy: 'FLAT',
          priceBy: 'BOL',
          chargeCode: FreightQuotaContentSchedulesChargesChargeCodeDTM.fromPlain({
            code: 'HBL',
            description: 'House Bill of Lading Fee',
            mode: 'ALL',
            occurrence: 'MANDATORY',
            type: 'FEE',
            subType: 'ACCESSORIAL',
          }),
        }),
        FreightQuotaContentSchedulesChargesDTM.fromPlain({
          unitType: '',
          subjectTo: 'APPLICABLE',
          currency: 'USD',
          costPerUnit: 541,
          numberOfUnits: 1,
          totalCost: 541,
          containerId: 48796,
          contractId: 6283,
          transportationIds: [
            48917,
            48918,
          ],
          applied: true,
          designation: 'DESTINATION',
          measureBy: 'FLAT',
          priceBy: 'BOL',
          chargeCode: FreightQuotaContentSchedulesChargesChargeCodeDTM.fromPlain({
            code: 'OTHC',
            description: 'Origin Terminal Handling ChargE',
            mode: 'OCEAN',
            occurrence: 'MANDATORY',
            type: 'SURCHARGE',
            subType: 'ACCESSORIAL',
          }),
        }),
        FreightQuotaContentSchedulesChargesDTM.fromPlain({
          unitType: '',
          subjectTo: 'APPLICABLE',
          currency: 'USD',
          costPerUnit: 541,
          numberOfUnits: 1,
          totalCost: 541,
          containerId: 48797,
          contractId: 6283,
          transportationIds: [
            48917,
            48918,
          ],
          applied: true,
          designation: 'DESTINATION',
          measureBy: 'FLAT',
          priceBy: 'BOL',
          chargeCode: FreightQuotaContentSchedulesChargesChargeCodeDTM.fromPlain({
            code: 'OTHC',
            description: 'Origin Terminal Handling ChargE',
            mode: 'OCEAN',
            occurrence: 'MANDATORY',
            type: 'SURCHARGE',
            subType: 'ACCESSORIAL',
          }),
        }),
        FreightQuotaContentSchedulesChargesDTM.fromPlain({
          unitType: '',
          subjectTo: undefined,
          currency: 'USD',
          costPerUnit: 140,
          numberOfUnits: 1,
          totalCost: 140,
          containerId: 48796,
          contractId: 6283,
          transportationIds: [
            48917,
            48918,
          ],
          applied: true,
          designation: 'DESTINATION',
          measureBy: 'FLAT',
          priceBy: 'BOL',
          chargeCode: FreightQuotaContentSchedulesChargesChargeCodeDTM.fromPlain({
            code: 'CCLR',
            description: 'Customs Clearance',
            mode: 'ALL',
            occurrence: 'ADDITIONAL',
            type: 'CHARGE',
            subType: 'ACCESSORIAL',
          }),
        }),
        FreightQuotaContentSchedulesChargesDTM.fromPlain({
          unitType: '',
          subjectTo: undefined,
          currency: 'USD',
          costPerUnit: 140,
          numberOfUnits: 1,
          totalCost: 140,
          containerId: 48797,
          contractId: 6283,
          transportationIds: [
            48917,
            48918,
          ],
          applied: true,
          designation: 'DESTINATION',
          measureBy: 'FLAT',
          priceBy: 'BOL',
          chargeCode: FreightQuotaContentSchedulesChargesChargeCodeDTM.fromPlain({
            code: 'CCLR',
            description: 'Customs Clearance',
            mode: 'ALL',
            occurrence: 'ADDITIONAL',
            type: 'CHARGE',
            subType: 'ACCESSORIAL',
          }),
        }),
        FreightQuotaContentSchedulesChargesDTM.fromPlain({
          unitType: '42G0',
          currency: 'USD',
          costPerUnit: 0.5,
          numberOfUnits: 1,
          totalCost: 0.5,
          containerId: 48798,
          contractId: 6283,
          transportationIds: [
            48917,
            48918,
          ],
          applied: true,
          designation: 'ORIGIN',
          measureBy: 'KM_MILE',
          priceBy: 'CONTAINER',
          chargeCode: FreightQuotaContentSchedulesChargesChargeCodeDTM.fromPlain({
            code: 'PKP',
            description: 'Pickup',
            mode: 'ROAD',
            occurrence: 'MANDATORY',
            type: 'CHARGE',
            subType: 'ACCESSORIAL',
          }),
        }),
        FreightQuotaContentSchedulesChargesDTM.fromPlain({
          unitType: '',
          subjectTo: undefined,
          currency: 'USD',
          costPerUnit: 10,
          numberOfUnits: 1,
          totalCost: 10,
          containerId: 48798,
          contractId: 6283,
          transportationIds: [
            48917,
            48918,
          ],
          applied: true,
          designation: 'ORIGIN',
          measureBy: 'DAY',
          priceBy: 'CONTAINER',
          chargeCode: FreightQuotaContentSchedulesChargesChargeCodeDTM.fromPlain({
            code: 'CHAOR',
            description: 'Origin Chassis Fee',
            mode: 'ROAD',
            occurrence: 'MANDATORY',
            type: 'SURCHARGE',
            subType: 'ACCESSORIAL',
          }),
        }),
        FreightQuotaContentSchedulesChargesDTM.fromPlain({
          unitType: '',
          subjectTo: 'APPLICABLE',
          currency: 'USD',
          costPerUnit: 43,
          numberOfUnits: 1,
          totalCost: 43,
          containerId: 48798,
          contractId: 6283,
          transportationIds: [
            48917,
            48918,
          ],
          applied: true,
          designation: 'ORIGIN',
          measureBy: 'FLAT',
          priceBy: 'BOL',
          chargeCode: FreightQuotaContentSchedulesChargesChargeCodeDTM.fromPlain({
            code: 'OISPS',
            description: 'Origin Port ISPS',
            mode: 'OCEAN',
            occurrence: 'MANDATORY',
            type: 'SURCHARGE',
            subType: 'ACCESSORIAL',
          }),
        }),
        FreightQuotaContentSchedulesChargesDTM.fromPlain({
          unitType: '',
          subjectTo: 'APPLICABLE',
          currency: 'USD',
          costPerUnit: 100,
          numberOfUnits: 1,
          totalCost: 100,
          containerId: 48798,
          contractId: 6283,
          transportationIds: [
            48917,
            48918,
          ],
          applied: true,
          designation: 'ORIGIN',
          measureBy: 'FLAT',
          priceBy: 'BOL',
          chargeCode: FreightQuotaContentSchedulesChargesChargeCodeDTM.fromPlain({
            code: 'OTHC',
            description: 'Origin Terminal Handling Charge',
            mode: 'OCEAN',
            occurrence: 'MANDATORY',
            type: 'SURCHARGE',
            subType: 'ACCESSORIAL',
          }),
        }),
        FreightQuotaContentSchedulesChargesDTM.fromPlain({
          unitType: '',
          subjectTo: 'APPLICABLE',
          currency: 'USD',
          costPerUnit: 5,
          numberOfUnits: 1,
          totalCost: 5,
          containerId: 48798,
          contractId: 6283,
          transportationIds: [
            48917,
            48918,
          ],
          applied: true,
          designation: 'ORIGIN',
          measureBy: 'FLAT',
          priceBy: 'BOL',
          chargeCode: FreightQuotaContentSchedulesChargesChargeCodeDTM.fromPlain({
            code: 'EODOC',
            description: 'Electronic Origin Documentation Fee',
            mode: 'OCEAN',
            occurrence: 'MANDATORY',
            type: 'FEE',
            subType: 'ACCESSORIAL',
          }),
        }),
        FreightQuotaContentSchedulesChargesDTM.fromPlain({
          unitType: '',
          subjectTo: undefined,
          currency: 'USD',
          costPerUnit: 12,
          numberOfUnits: 1,
          totalCost: 12,
          containerId: 48798,
          contractId: 6283,
          transportationIds: [
            48917,
            48918,
          ],
          applied: true,
          designation: 'ORIGIN',
          measureBy: 'FLAT',
          priceBy: 'BOL',
          chargeCode: FreightQuotaContentSchedulesChargesChargeCodeDTM.fromPlain({
            code: 'AMS',
            description: 'Filing Charge',
            mode: 'ALL',
            occurrence: 'ADDITIONAL',
            type: 'CHARGE',
            subType: 'ACCESSORIAL',
          }),
        }),
        FreightQuotaContentSchedulesChargesDTM.fromPlain({
          unitType: '42G0',
          currency: 'USD',
          costPerUnit: 100,
          numberOfUnits: 1,
          totalCost: 100,
          containerId: 48798,
          contractId: 6283,
          transportationIds: [
            48917,
            48918,
          ],
          applied: true,
          designation: 'FREIGHT',
          measureBy: 'CONTAINER_TYPE',
          priceBy: 'CONTAINER',
          chargeCode: FreightQuotaContentSchedulesChargesChargeCodeDTM.fromPlain({
            code: 'FRT',
            description: 'Freight',
            mode: 'OCEAN',
            occurrence: 'MANDATORY',
            type: 'CHARGE',
            subType: 'ACCESSORIAL',
          }),
        }),
        FreightQuotaContentSchedulesChargesDTM.fromPlain({
          unitType: '42G0',
          subjectTo: 'APPLICABLE',
          currency: 'USD',
          costPerUnit: 1,
          numberOfUnits: 1,
          totalCost: 1,
          containerId: 48798,
          contractId: 6283,
          transportationIds: [
            48917,
            48918,
          ],
          applied: true,
          designation: 'FREIGHT',
          measureBy: 'CONTAINER_TYPE',
          priceBy: 'CONTAINER',
          chargeCode: FreightQuotaContentSchedulesChargesChargeCodeDTM.fromPlain({
            code: 'CSF',
            description: 'Carrier Security Surcharge',
            mode: 'OCEAN',
            occurrence: 'MANDATORY',
            type: 'SURCHARGE',
            subType: 'ACCESSORIAL',
          }),
        }),
        FreightQuotaContentSchedulesChargesDTM.fromPlain({
          unitType: '',
          subjectTo: 'INCLUDED',
          currency: 'USD',
          costPerUnit: 0,
          numberOfUnits: 1,
          totalCost: 0,
          containerId: 48798,
          contractId: 6283,
          transportationIds: [
            48917,
            48918,
          ],
          applied: true,
          designation: 'FREIGHT',
          measureBy: 'CONTAINER_TYPE',
          priceBy: 'CONTAINER',
          chargeCode: FreightQuotaContentSchedulesChargesChargeCodeDTM.fromPlain({
            code: 'ECA',
            description: 'Emission Control Charge',
            mode: 'OCEAN',
            occurrence: 'MANDATORY',
            type: 'SURCHARGE',
            subType: 'ACCESSORIAL',
          }),
        }),
        FreightQuotaContentSchedulesChargesDTM.fromPlain({
          unitType: '',
          subjectTo: 'INCLUDED',
          currency: 'USD',
          costPerUnit: 0,
          numberOfUnits: 1,
          totalCost: 0,
          containerId: 48798,
          contractId: 6283,
          transportationIds: [
            48917,
            48918,
          ],
          applied: true,
          designation: 'FREIGHT',
          measureBy: 'CONTAINER_TYPE',
          priceBy: 'CONTAINER',
          chargeCode: FreightQuotaContentSchedulesChargesChargeCodeDTM.fromPlain({
            code: 'EMS',
            description: 'Emergency Surcharge - Piracy Surcharge',
            mode: 'OCEAN',
            occurrence: 'MANDATORY',
            type: 'SURCHARGE',
            subType: 'ACCESSORIAL',
          }),
        }),
        FreightQuotaContentSchedulesChargesDTM.fromPlain({
          unitType: '',
          subjectTo: 'APPLICABLE',
          currency: 'USD',
          costPerUnit: 37,
          numberOfUnits: 1,
          totalCost: 37,
          containerId: 48798,
          contractId: 6283,
          transportationIds: [
            48917,
            48918,
          ],
          applied: true,
          designation: 'FREIGHT',
          measureBy: 'CONTAINER_TYPE',
          priceBy: 'CONTAINER',
          chargeCode: FreightQuotaContentSchedulesChargesChargeCodeDTM.fromPlain({
            code: 'PCC',
            description: 'Panama Canal Surcharge',
            mode: 'OCEAN',
            occurrence: 'MANDATORY',
            type: 'SURCHARGE',
            subType: 'ACCESSORIAL',
          }),
        }),
        FreightQuotaContentSchedulesChargesDTM.fromPlain({
          unitType: '',
          subjectTo: 'APPLICABLE',
          currency: 'USD',
          costPerUnit: 3,
          numberOfUnits: 1,
          totalCost: 3,
          containerId: 48798,
          contractId: 6283,
          transportationIds: [
            48917,
            48918,
          ],
          applied: false,
          designation: 'FREIGHT',
          measureBy: 'FLAT',
          priceBy: 'BOL',
          chargeCode: FreightQuotaContentSchedulesChargesChargeCodeDTM.fromPlain({
            code: 'ACT',
            description: 'Alameda Corridor Surcharge',
            mode: 'OCEAN',
            occurrence: 'CONDITIONAL',
            type: 'SURCHARGE',
            subType: 'ACCESSORIAL',
          }),
        }),
        FreightQuotaContentSchedulesChargesDTM.fromPlain({
          unitType: '',
          subjectTo: undefined,
          currency: 'USD',
          costPerUnit: 0.5,
          numberOfUnits: 1,
          totalCost: 0.5,
          containerId: 48798,
          contractId: 6283,
          transportationIds: [
            48917,
            48918,
          ],
          applied: true,
          designation: 'DESTINATION',
          measureBy: 'KM_MILE',
          priceBy: 'CONTAINER',
          chargeCode: FreightQuotaContentSchedulesChargesChargeCodeDTM.fromPlain({
            code: 'DLV',
            description: 'Delivery',
            mode: 'ROAD',
            occurrence: 'MANDATORY',
            type: 'CHARGE',
            subType: 'ACCESSORIAL',
          }),
        }),
        FreightQuotaContentSchedulesChargesDTM.fromPlain({
          unitType: '',
          subjectTo: 'APPLICABLE',
          currency: 'USD',
          costPerUnit: 10,
          numberOfUnits: 1,
          totalCost: 10,
          containerId: 48798,
          contractId: 6283,
          transportationIds: [
            48917,
            48918,
          ],
          applied: true,
          designation: 'DESTINATION',
          measureBy: 'DAY',
          priceBy: 'CONTAINER',
          chargeCode: FreightQuotaContentSchedulesChargesChargeCodeDTM.fromPlain({
            code: 'CHADE',
            description: 'Destination Chassis Fee',
            mode: 'ROAD',
            occurrence: 'CONDITIONAL',
            type: 'SURCHARGE',
            subType: 'ACCESSORIAL',
          }),
        }),
        FreightQuotaContentSchedulesChargesDTM.fromPlain({
          unitType: '',
          subjectTo: 'APPLICABLE',
          currency: 'USD',
          costPerUnit: 45,
          numberOfUnits: 1,
          totalCost: 45,
          containerId: 48798,
          contractId: 6283,
          transportationIds: [
            48917,
            48918,
          ],
          applied: true,
          designation: 'DESTINATION',
          measureBy: 'FLAT',
          priceBy: 'BOL',
          chargeCode: FreightQuotaContentSchedulesChargesChargeCodeDTM.fromPlain({
            code: 'DDOC',
            description: 'Destination Documentation Fee ( Manuel)',
            mode: 'OCEAN',
            occurrence: 'MANDATORY',
            type: 'FEE',
            subType: 'ACCESSORIAL',
          }),
        }),
        FreightQuotaContentSchedulesChargesDTM.fromPlain({
          unitType: '',
          subjectTo: 'APPLICABLE',
          currency: 'USD',
          costPerUnit: 12,
          numberOfUnits: 1,
          totalCost: 12,
          containerId: 48798,
          contractId: 6283,
          transportationIds: [
            48917,
            48918,
          ],
          applied: true,
          designation: 'DESTINATION',
          measureBy: 'FLAT',
          priceBy: 'BOL',
          chargeCode: FreightQuotaContentSchedulesChargesChargeCodeDTM.fromPlain({
            code: 'HBL',
            description: 'House Bill of Lading Fee',
            mode: 'ALL',
            occurrence: 'MANDATORY',
            type: 'FEE',
            subType: 'ACCESSORIAL',
          }),
        }),
        FreightQuotaContentSchedulesChargesDTM.fromPlain({
          unitType: '',
          subjectTo: 'APPLICABLE',
          currency: 'USD',
          costPerUnit: 54.1,
          numberOfUnits: 1,
          totalCost: 54.1,
          containerId: 48798,
          contractId: 6283,
          transportationIds: [
            48917,
            48918,
          ],
          applied: true,
          designation: 'DESTINATION',
          measureBy: 'FLAT',
          priceBy: 'BOL',
          chargeCode: FreightQuotaContentSchedulesChargesChargeCodeDTM.fromPlain({
            code: 'OTHC',
            description: 'Origin Terminal Handling ChargE',
            mode: 'OCEAN',
            occurrence: 'MANDATORY',
            type: 'SURCHARGE',
            subType: 'ACCESSORIAL',
          }),
        }),
        FreightQuotaContentSchedulesChargesDTM.fromPlain({
          unitType: '',
          subjectTo: undefined,
          currency: 'USD',
          costPerUnit: 14,
          numberOfUnits: 1,
          totalCost: 14,
          containerId: 48798,
          contractId: 6283,
          transportationIds: [
            48917,
            48918,
          ],
          applied: true,
          designation: 'DESTINATION',
          measureBy: 'FLAT',
          priceBy: 'BOL',
          chargeCode: FreightQuotaContentSchedulesChargesChargeCodeDTM.fromPlain({
            code: 'CCLR',
            description: 'Customs Clearance',
            mode: 'ALL',
            occurrence: 'ADDITIONAL',
            type: 'CHARGE',
            subType: 'ACCESSORIAL',
          }),
        }),
      ],
      transportations: [
        FreightQuotaTransportationDTM.fromPlain({
          id: 48917,
          transport: FreightQuotaTransportationTransportDTM.fromPlain({
            number: 'UA231A',
            name: 'MSC BARBARA',
            type: 'SEA',
          }),
          schedule: FreightQuotaTransportationScheduleDTM.fromPlain({
            type: 'OCEAN',
            departureTime: '2022-08-20T17:00:00-04:00',
            arrivalTime: '2022-09-18T18:00:00-03:00',
            transitDuration: 41760,
            terminalCutOff: '2022-08-17T16:00:00-04:00',
            documentCutOff: '2022-08-16T12:00:00-04:00',
            hazmatCutOff: undefined,
          }),
          arrivalTerminal: 'TERMINAL CUENCA DEL PLATA',
          departureTerminal: 'GLOBAL CONTAINER TERMINAL - NEW YORK',
          // containers: [
          //   48796,
          //   48797,
          //   48798,
          // ],
          transportLeg: 7650,
        }),
        FreightQuotaTransportationDTM.fromPlain({
          id: 48918,
          transport: FreightQuotaTransportationTransportDTM.fromPlain({
            number: 'TBN',
            name: 'TBN',
            type: 'SEA',
          }),
          schedule: FreightQuotaTransportationScheduleDTM.fromPlain({
            type: 'OCEAN',
            departureTime: '2022-09-21T18:00:00-03:00',
            arrivalTime: '2022-09-24T18:00:00-03:00',
            transitDuration: 4320,
            terminalCutOff: undefined,
            documentCutOff: undefined,
            hazmatCutOff: undefined,
          }),
          arrivalTerminal: 'TERMINAL PORTUARIA ROSARIO',
          departureTerminal: 'MONTECON',
          // containers: [
          //   48796,
          //   48797,
          //   48798,
          // ],
          transportLeg: 7651,
        }),
      ],
    }),
    FreightQuotaContentSchedulesDTM.fromPlain({
      id: 37148,
      oceanScheduleId: 902,
      recommended: false,
      departureTime: '2022-08-20T17:00:00-04:00',
      arrivalTime: '2022-09-24T18:00:00-03:00',
      totalDuration: 50400,
      terminalCutOff: '2022-08-17T16:00:00-04:00',
      documentCutOff: '2022-08-16T12:00:00-04:00',
      plans: [],
      hazmatCutOff: '',
      charges: [],
      transportations: [
        FreightQuotaTransportationDTM.fromPlain({
          id: 48917,
          transport: FreightQuotaTransportationTransportDTM.fromPlain({
            number: 'UA231A',
            name: 'MSC BARBARA',
            type: 'SEA',
          }),
          schedule: FreightQuotaTransportationScheduleDTM.fromPlain({
            type: 'OCEAN',
            departureTime: '2022-08-20T17:00:00-04:00',
            arrivalTime: '2022-09-18T18:00:00-03:00',
            transitDuration: 41760,
            terminalCutOff: '2022-08-17T16:00:00-04:00',
            documentCutOff: '2022-08-16T12:00:00-04:00',
            hazmatCutOff: undefined,
          }),
          arrivalTerminal: 'TERMINAL CUENCA DEL PLATA',
          departureTerminal: 'GLOBAL CONTAINER TERMINAL - NEW YORK',
          // containers: [
          //   48796,
          //   48797,
          //   48798,
          // ],
          transportLeg: 7650,
        }),
        FreightQuotaTransportationDTM.fromPlain({
          id: 48918,
          transport: FreightQuotaTransportationTransportDTM.fromPlain({
            number: 'TBN',
            name: 'TBN',
            type: 'SEA',
          }),
          schedule: FreightQuotaTransportationScheduleDTM.fromPlain({
            type: 'OCEAN',
            departureTime: '2022-09-21T18:00:00-03:00',
            arrivalTime: '2022-09-24T18:00:00-03:00',
            transitDuration: 4320,
            terminalCutOff: undefined,
            documentCutOff: undefined,
            hazmatCutOff: undefined,
          }),
          arrivalTerminal: 'TERMINAL PORTUARIA ROSARIO',
          departureTerminal: 'MONTECON',
          // containers: [
          //   48796,
          //   48797,
          //   48798,
          // ],
          transportLeg: 7651,
        }),
      ],
    }),
    FreightQuotaContentSchedulesDTM.fromPlain({
      id: 37148,
      oceanScheduleId: 902,
      recommended: false,
      departureTime: '2022-08-20T17:00:00-04:00',
      arrivalTime: '2022-09-24T18:00:00-03:00',
      totalDuration: 50400,
      terminalCutOff: '2022-08-17T16:00:00-04:00',
      documentCutOff: '2022-08-16T12:00:00-04:00',
      hazmatCutOff: '',
      charges: [],
      plans: [],
      transportations: [
        FreightQuotaTransportationDTM.fromPlain({
          id: 48917,
          transport: FreightQuotaTransportationTransportDTM.fromPlain({
            number: 'UA231A',
            name: 'MSC BARBARA',
            type: 'SEA',
          }),
          schedule: FreightQuotaTransportationScheduleDTM.fromPlain({
            type: 'OCEAN',
            departureTime: '2022-08-20T17:00:00-04:00',
            arrivalTime: '2022-09-18T18:00:00-03:00',
            transitDuration: 41760,
            terminalCutOff: '2022-08-17T16:00:00-04:00',
            documentCutOff: '2022-08-16T12:00:00-04:00',
            hazmatCutOff: undefined,
          }),
          arrivalTerminal: 'TERMINAL CUENCA DEL PLATA',
          departureTerminal: 'GLOBAL CONTAINER TERMINAL - NEW YORK',
          // containers: [
          //   48796,
          //   48797,
          //   48798,
          // ],
          transportLeg: 7650,
        }),
        FreightQuotaTransportationDTM.fromPlain({
          id: 48918,
          transport: FreightQuotaTransportationTransportDTM.fromPlain({
            number: 'TBN',
            name: 'TBN',
            type: 'SEA',
          }),
          schedule: FreightQuotaTransportationScheduleDTM.fromPlain({
            type: 'OCEAN',
            departureTime: '2022-09-21T18:00:00-03:00',
            arrivalTime: '2022-09-24T18:00:00-03:00',
            transitDuration: 4320,
            terminalCutOff: undefined,
            documentCutOff: undefined,
            hazmatCutOff: undefined,
          }),
          arrivalTerminal: 'TERMINAL PORTUARIA ROSARIO',
          departureTerminal: 'MONTECON',
          // containers: [
          //   48796,
          //   48797,
          //   48798,
          // ],
          transportLeg: 7651,
        }),
      ],
    }),
    FreightQuotaContentSchedulesDTM.fromPlain({
      id: 37148,
      oceanScheduleId: 902,
      recommended: false,
      departureTime: '2022-08-20T17:00:00-04:00',
      arrivalTime: '2022-09-24T18:00:00-03:00',
      totalDuration: 50400,
      terminalCutOff: '2022-08-17T16:00:00-04:00',
      documentCutOff: '2022-08-16T12:00:00-04:00',
      hazmatCutOff: '',
      charges: [],
      plans: [],
      transportations: [
        FreightQuotaTransportationDTM.fromPlain({
          id: 48917,
          transport: FreightQuotaTransportationTransportDTM.fromPlain({
            number: 'UA231A',
            name: 'MSC BARBARA',
            type: 'SEA',
          }),
          schedule: FreightQuotaTransportationScheduleDTM.fromPlain({
            type: 'OCEAN',
            departureTime: '2022-08-20T17:00:00-04:00',
            arrivalTime: '2022-09-18T18:00:00-03:00',
            transitDuration: 41760,
            terminalCutOff: '2022-08-17T16:00:00-04:00',
            documentCutOff: '2022-08-16T12:00:00-04:00',
            hazmatCutOff: undefined,
          }),
          arrivalTerminal: 'TERMINAL CUENCA DEL PLATA',
          departureTerminal: 'GLOBAL CONTAINER TERMINAL - NEW YORK',
          // containers: [
          //   48796,
          //   48797,
          //   48798,
          // ],
          transportLeg: 7650,
        }),
        FreightQuotaTransportationDTM.fromPlain({
          id: 48918,
          transport: FreightQuotaTransportationTransportDTM.fromPlain({
            number: 'TBN',
            name: 'TBN',
            type: 'SEA',
          }),
          schedule: FreightQuotaTransportationScheduleDTM.fromPlain({
            type: 'OCEAN',
            departureTime: '2022-09-21T18:00:00-03:00',
            arrivalTime: '2022-09-24T18:00:00-03:00',
            transitDuration: 4320,
            terminalCutOff: undefined,
            documentCutOff: undefined,
            hazmatCutOff: undefined,
          }),
          arrivalTerminal: 'TERMINAL PORTUARIA ROSARIO',
          departureTerminal: 'MONTECON',
          // containers: [
          //   48796,
          //   48797,
          //   48798,
          // ],
          transportLeg: 7651,
        }),
      ],
    }),
  ],
  contracts: [
    FreightQuotaContentContractsDTM.fromPlain({
      id: 6283,
      number: '31',
      name: 'MSCU julia2',
      scac: 'MSCU',
    }),
  ],
  containers: [
    FreightQuotaContentContainerDTM.fromPlain({
      id: 48796,
      type: '22G0',
      ownContainer: false,
      rateId: 151,
      weight: 3,
      volume: 4,
      commodities: [],
    }),
    FreightQuotaContentContainerDTM.fromPlain({
      id: 48797,
      type: '22G0',
      ownContainer: false,
      rateId: 151,
      weight: 3,
      volume: 4,
      commodities: [],
    }),
    FreightQuotaContentContainerDTM.fromPlain({
      id: 48798,
      type: '42G0',
      ownContainer: false,
      rateId: 152,
      weight: 38,
      volume: 46,
      commodities: [],
    }),
  ],
  customer: FreightQuotaContentCustomerDTM.fromPlain({}),
  validPeriod: FreightQuotaContentValidPeriodDTM.fromPlain({}),
});
