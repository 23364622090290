import Modal from 'antd/es/modal';
import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { UNSAFE_NavigationContext as NavigationContext } from 'react-router-dom';
import type { History, Transition } from 'history';

interface IUseBlockerProps {
  title?: string;
}

export const useBlocker = (onPageLeave: () => void, onContinue: () => void, isActive = false, props?: IUseBlockerProps) => {
  const {
    title,
  } = props || {};
  const navigator = useContext(NavigationContext)?.navigator as History;
  const { t } = useTranslation();

  useEffect(() => {
    if (!isActive || !navigator) {
      return () => '';
    }

    const unblock = navigator.block((tx: Transition) => {
      if (!isActive || !navigator) {
        unblock();

        return;
      }

      Modal.confirm({
        title: title || t('leavePageQuestion'),
        okText: 'Yes',
        cancelText: 'No',
        onOk: () => {
          onPageLeave();

          unblock();

          tx.retry();
        },
        onCancel: onContinue,
      });
    });

    return unblock;
  }, [navigator, isActive]);
};
