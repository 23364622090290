import moment, { Moment } from 'moment';
import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'antd/es/modal';

import { Button } from 'app-wrapper/view/components';
import { DateDtm } from 'app-wrapper/models/dtm';

import { ECarrierSCAC, CarrierSCACNames } from 'monetary/constants';

import {
  RateDownloadModalBody,
  RateDownloadModalDatePicker,
  RateDownloadModalFooter,
  RateDownloadModalItem,
  RateDownloadModalSelect,
  RateDownloadModalTitle,
} from './RateDownloadModal.styled';

const timeFormat = 'DD/MM/YYYY';

const carrierSCACOptions = Object.keys(ECarrierSCAC).map((ECarrierSCACItem) => {
  const item = ECarrierSCACItem as ECarrierSCAC;
  return {
    label: CarrierSCACNames[item],
    value: item,
  };
});

const modalWidth = 416;

interface IOceanRateItemComponentProps {
  isOpen: boolean
  isLoading: boolean
  isFormDataFilled: boolean
  carrierSCACList: ECarrierSCAC[]
  date?: DateDtm

  onDateChange: (date: DateDtm | null) => void
  onDateBlur: () => void
  onCarrierSCACChange: (scacList: ECarrierSCAC[]) => void
  onDownload: () => void
  onClose: () => void
}

export const RateDownloadModalComponent: FC<IOceanRateItemComponentProps> = ({
  carrierSCACList,
  date,
  isFormDataFilled,
  isLoading,
  isOpen,
  onCarrierSCACChange,
  onClose,
  onDateBlur,
  onDateChange,
  onDownload,
}) => {
  const { t } = useTranslation();

  const onDateChangeHandler = useCallback((dateValue: Moment | null) => {
    const newDate = dateValue ? DateDtm.fromPlain({
      date: dateValue?.format(),
      offset: moment().utcOffset(),
    }) : null;

    onDateChange(newDate);
  }, []);

  const onDateBlurHandler = useCallback(() => {
    onDateBlur();
  }, []);

  const onCarrierSCACChangeHandler = useCallback((values: string[]) => {
    onCarrierSCACChange(values as ECarrierSCAC[]);
  }, []);

  const onDownloadHandler = useCallback(() => {
    onDownload();
  }, []);

  const onCloseHandler = useCallback(() => {
    onClose();
  }, []);

  return (
    <Modal
      visible={isOpen}
      closable={false}
      width={modalWidth}
      onCancel={onCloseHandler}
      footer={(
        <RateDownloadModalFooter>
          <Button
            type="ghost"
            disabled={isLoading}
            onClick={onCloseHandler}
          >
            {t('Cancel')}
          </Button>
          <Button
            onClick={onDownloadHandler}
            loading={isLoading}
            disabled={!isFormDataFilled}
          >
            {t('Download')}
          </Button>
        </RateDownloadModalFooter>
      )}
    >
      <RateDownloadModalBody>
        <RateDownloadModalTitle>{t('Download Rates')}</RateDownloadModalTitle>
        <RateDownloadModalItem>
          <span>{t('Date')} *</span>
          <RateDownloadModalDatePicker
            disabled={isLoading}
            value={date?.getDateAsMomentWithOffset() || null}
            format={timeFormat}
            // isError={!!effectiveDateError}
            onChange={onDateChangeHandler}
            onBlur={onDateBlurHandler}
            allowClear={false}
          />
        </RateDownloadModalItem>
        <RateDownloadModalItem>
          <span>{t('Carrier SCAC')}</span>
          <RateDownloadModalSelect
            showSearch
            mode="multiple"
            disabled={isLoading}
            options={carrierSCACOptions}
            showArrow={false}
            filterOption={false}
            value={carrierSCACList}
            onChange={onCarrierSCACChangeHandler}
          />
        </RateDownloadModalItem>
      </RateDownloadModalBody>
    </Modal>
  );
};
