import React from 'react';

import { IPropsIcon } from 'app-wrapper/interfaces/icon';

export const PriorityMediumSvg = (props: IPropsIcon) => {
  const { width, height, style } = props;
  return (
    <svg
      className="PriorityMediumSvg"
      width={width || '16'}
      height={height || '17'}
      viewBox="0 0 16 17"
      color={style?.color || '#F1AE00'}
      style={style || undefined}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect y="0.5" width="16" height="16" rx="2" fill="#FEF9E6" />
      <path fillRule="evenodd" clipRule="evenodd" d="M3.81601 10.7397C4.16315 11.0868 4.72596 11.0868 5.07309 10.7397L8.00011 7.81263L10.9271 10.7397C11.2743 11.0868 11.8371 11.0868 12.1842 10.7397C12.5313 10.3925 12.5313 9.8297 12.1842 9.48257L8.62865 5.92702C8.28152 5.57988 7.7187 5.57988 7.37157 5.92702L3.81601 9.48257C3.46888 9.8297 3.46888 10.3925 3.81601 10.7397Z" fill="#F1AE00" />
    </svg>
  );
};
