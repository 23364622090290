import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { UC } from 'shipment-operations/controllers';
import { R } from 'shipment-operations/repository';

import { ShipmentBillOfLadingComponent } from 'shipment-operations/view/pages/ShipmentBillOfLading/ShipmentBillOfLading.component';

const ShipmentBillOfLadingContainer: FC = () => {
  const isLoading = useSelector(R.selectors.billOfLadingCommon.getIsLoading);
  const isHBLPage = useSelector(R.selectors.billOfLadingCommon.getIsHBLPage);

  return (
    <ShipmentBillOfLadingComponent
      isLoading={isLoading}
      fetchBillOfLadingPageInformation={UC.billOfLading.fetchInformationForBillOfLadingPage}
      cleanup={UC.billOfLading.handleCleanup}
      isHBLPage={isHBLPage}
    />
  );
};

export { ShipmentBillOfLadingContainer as ShipmentBillOfLading };
