import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'app-wrapper/createSlice';

import { IOverviewState } from 'app-wrapper/models/states';
import { NotificationTaskContentDTM } from 'app-wrapper/models/dtm';
import { ShipmentAllStatsDTM, ShipmentPreviewDTM } from 'shipment-operations/models/dtm';

const initialState: IOverviewState = {
  isLoading: false,
  isLoadingTasksStatistics: false,
  isLoadingNotificationsStatistics: false,
  shipmentByIdStats: undefined,
  hasOwnRequestedQuotas: false,
  hasOwnBookedShipments: false,
  isUserOnboarded: false,
  isUserInvited: false,
  isVisibleTermsAndConditionDrawer: false,
  step: 1,
  tasks: [],
  notifications: [],
  trackerValue: '',
  trackerOptions: [],
  trackerShipmentOptions: [],
  isUserTheOnlyMember: false,
  isUserPrimary: false,
};

export const overviewStore = createSlice({
  name: 'overviewStore',
  initialState,
  reducers: {
    setIsLoading: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      isLoading: payload,
    }),
    setIsLoadingTasksBoard: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      isLoadingTasksBoard: payload,
    }),
    setIsLoadingTasksStatistics: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      isLoadingTasksStatistics: payload,
    }),
    setIsLoadingNotificationsStatistics: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      isLoadingNotificationsStatistics: payload,
    }),
    setHasOwnRequestedQuotas: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      hasOwnRequestedQuotas: payload,
    }),
    setHasOwnBookedShipments: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      hasOwnBookedShipments: payload,
    }),
    setTrackerShipmentOptions: (state, { payload }: PayloadAction<ShipmentPreviewDTM[]>) => ({
      ...state,
      trackerShipmentOptions: payload,
    }),
    setIsUserOnboarded: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      isUserOnboarded: payload,
    }),
    setIsUserTheOnlyMember: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      isUserTheOnlyMember: payload,
    }),
    setIsUserPrimary: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      isUserPrimary: payload,
    }),
    setIsUserInvited: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      isUserInvited: payload,
    }),
    setStep: (state, { payload }: PayloadAction<number>) => ({
      ...state,
      step: payload,
    }),
    setTasks: (state, { payload }: PayloadAction<NotificationTaskContentDTM[]>) => ({
      ...state,
      tasks: payload,
    }),
    setCountAllTasks: (state, { payload }: PayloadAction<number | undefined>) => ({
      ...state,
      countAllTasks: payload,
    }),
    setNotifications: (state, { payload }: PayloadAction<NotificationTaskContentDTM[]>) => ({
      ...state,
      notifications: payload,
    }),

    onCheckNotificationViewedById: (state, action: PayloadAction<{
      id: string
      viewedId: string
    }>) => {
      const { id, viewedId } = action.payload;

      const newContent = state.notifications.map((item) => {
        if (`${item.id}` === id) {
          return {
            ...item,
            view: true,
            viewId: viewedId,
          };
        }

        return item;
      });

      return {
        ...state,
        notifications: newContent,
      };
    },

    onCheckUnviewedNotificationViewedById: (state, action: PayloadAction<string>) => {
      const { payload: id } = action;

      const newContent = state.notifications.map((item) => {
        if (`${item.id}` === id) {
          return {
            ...item,
            view: false,
            viewId: undefined,
          };
        }

        return item;
      });

      return {
        ...state,
        notifications: newContent,
      };
    },
    setTrackerValue: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      trackerValue: payload,
    }),
    setTrackerOptions: (state, { payload }: PayloadAction<string[]>) => ({
      ...state,
      trackerOptions: payload,
    }),
    setShipmentByIdStats: (state, { payload }: PayloadAction<ShipmentAllStatsDTM>) => ({
      ...state,
      shipmentByIdStats: payload,
    }),
    setTasksStats: (state, { payload }: PayloadAction<ShipmentAllStatsDTM>) => ({
      ...state,
      tasksStats: payload,
    }),
    setTasksStatsInterval: (state, { payload }: PayloadAction<ReturnType<typeof setInterval> | undefined>) => ({
      ...state,
      tasksStatsInterval: payload,
    }),
    setVisibleTermsAndConditionDrawer: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      isVisibleTermsAndConditionDrawer: payload,
    }),

    setInfoCountAllTasks: (state, action: PayloadAction<number>) => {
      const { payload: infoCountAllTasks } = action;

      return {
        ...state,
        infoCountAllTasks,
      };
    },

    setInfoCountOverdueTasks: (state, action: PayloadAction<number>) => {
      const { payload: infoCountOverdueTasks } = action;

      return {
        ...state,
        infoCountOverdueTasks,
      };
    },

    setInfoCountDueTasks: (state, action: PayloadAction<number>) => {
      const { payload: infoCountDueTasks } = action;

      return {
        ...state,
        infoCountDueTasks,
      };
    },

    setInfoCountPlannedTasks: (state, action: PayloadAction<number>) => {
      const { payload: infoCountPlannedTasks } = action;

      return {
        ...state,
        infoCountPlannedTasks,
      };
    },

    setCountUnreadNotifications: (state, action: PayloadAction<number>) => {
      const { payload: countUnreadNotifications } = action;

      return {
        ...state,
        countUnreadNotifications,
      };
    },

    setCountImportantNotifications: (state, action: PayloadAction<number>) => {
      const { payload: countImportantNotifications } = action;

      return {
        ...state,
        countImportantNotifications,
      };
    },

    setCountRegularNotifications: (state, action: PayloadAction<number>) => {
      const { payload: countRegularNotifications } = action;

      return {
        ...state,
        countRegularNotifications,
      };
    },
  },
});

export const overviewReducer = overviewStore.reducer;
export const overviewActions = overviewStore.actions;
