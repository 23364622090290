import { apiWorker } from 'app-wrapper/repository/utilsServices';
import { TransportPlanDTM } from 'shipment-operations/models/dtm';

export class PlansService {
  private base = '/planning-service/api/v1/plans';

  public getShipmentPlans = async (planId: string) => {
    const responseRaw = await apiWorker.requestGetBySchema(`${this.base}?ids=${planId}` as '/api/v1/plans');
    const response = responseRaw.data;

    // @ts-ignore no transportation.containers
    const plans = response.map((item) => TransportPlanDTM.fromPlain({
      ...item,
    }));

    // plans.forEach((plan) => {
    //   if (!plan.isValid()) {
    //     console.log(plan.validate());
    //     throw new Error(`getShipmentPlans: Data from API does not match with contract ${plan.validate()}`);
    //   }
    // });

    return plans;
  };
}
