import styled, { createGlobalStyle } from 'styled-components';

import { divFlex } from 'app-wrapper/view/themes/mixin';

export const CustomModalWrapperStyle = createGlobalStyle`
  section > div[data-class='CustomModal_Wrapper']:nth-child(1) {
    z-index: 12;
  }
`;

export const ShipmentAdditionalServices = styled.div.attrs({
  'data-class': 'ShipmentAdditionalServices',
})`
  ${divFlex}
  height: 100%;
  flex-direction: column;
`;

export const ShipmentAdditionalServicesWrap = styled.div.attrs({
  'data-class': 'ShipmentAdditionalServicesWrap',
})`
  ${divFlex}
  flex-direction: column;
  align-items: center;

  margin-top: 8px;
  width: 100%;
  background-color: ${({ theme }) => theme.themesColors.neutralBranding1};
  border: 1px solid ${({ theme }) => theme.themesColors.neutralBranding4};
`;

export const ShipmentAdditionalServicesContent = styled.div.attrs({
  'data-class': 'ShipmentAdditionalServicesContent',
})`
  ${divFlex}
  flex-direction: column;
  overflow: auto;

  font-weight: 400;
  font-size: 12px;
  color: ${({ theme }) => theme.themesColors.neutralBranding10};

  padding: 16px;
  height: 100%;
  width: 100%;
  background-color: ${({ theme }) => theme.themesColors.neutralBranding1};
`;

export const ShipmentAdditionalServiceHeader = styled.div.attrs({
  'data-class': 'ShipmentAdditionalServiceHeader',
})`
  ${divFlex}
  height: 30px;
`;

export const ShipmentAdditionalServicesContentItem = styled.div.attrs({
  'data-class': 'ShipmentAdditionalServicesContentItem',
})`
  ${divFlex}
  flex-direction: column;
  margin-bottom: 16px;
`;

export const ShipmentAdditionalServicesSectionButton = styled.div.attrs({
  'data-class': 'ShipmentAdditionalServicesSectionButton',
})`
  ${divFlex}

  margin-left: auto;
  .ShipmentAdditionalServicesSectionButton {
    color: ${({ theme }) => theme.themesColors.neutralBranding5};
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  &:hover {
    .ShipmentAdditionalServicesSectionButton {
      color: inherit;
    }
  }

  svg {
    color: inherit;
    margin-right: 8px;
  }
`;

export const ShipmentAdditionalServicesFooter = styled.div.attrs({
  'data-class': 'ShipmentAdditionalServicesFooter',
})`
  ${divFlex}
  margin-right: auto;
`;
