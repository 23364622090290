import { createSelector } from 'reselect';

import { RootState } from 'app-wrapper/store';
import { GEN } from 'shipment-operations/constants';

const localState = (state: RootState) => state.temperatureControl;

const getAllTemperatureControlData = createSelector(
  localState,
  (state) => state,
);

const getTemperatureControl = createSelector(
  localState,
  (state) => state.temperatureControl,
);

const getTemperatureControlErrors = createSelector(
  localState,
  (state) => state.errors,
);

const getTemperatureControlTouchedFields = createSelector(
  localState,
  (state) => state.touchedFields,
);

const getTemperatureControlInitialState = createSelector(
  localState,
  (state) => state.initialState,
);

const getTemperatureControlDraft = createSelector(
  getTemperatureControlInitialState,
  getTemperatureControl,
  (initialState, control) => !(
    initialState.temperature === control.temperature
    && initialState.genset === control.genset
    && initialState.airVent.flowRateUnit === control.airVent.flowRateUnit
    && initialState.airVent.flowRate === control.airVent.flowRate
  ),
);

const getUpdateAttemptStatus = createSelector(
  localState,
  (state) => state.wasUpdateAttempted,
);

const getLoading = createSelector(
  localState,
  (state) => state.isLoading,
);

const getTemperatureFieldError = createSelector(
  localState,
  ({ errors }) => (
    errors.temperature
  ),
);

const isTemperatureErrorFieldIsActive = createSelector(
  getUpdateAttemptStatus,
  getTemperatureControlTouchedFields,
  getTemperatureFieldError,
  (wasUpdateAttempted, touchedFields, error) => (
    !!error && (touchedFields.temperature || wasUpdateAttempted)
  ),
);

const getFlowRateFieldError = createSelector(
  localState,
  ({ errors }) => (
    errors.flowRate
  ),
);

const getFlowRateUnitFieldError = createSelector(
  localState,
  ({ errors }) => (
    errors.flowRateUnit
  ),
);

const isFlowRateErrorFieldIsActive = createSelector(
  getUpdateAttemptStatus,
  getTemperatureControlTouchedFields,
  getFlowRateFieldError,
  (wasUpdateAttempted, touchedFields, error) => (
    !!error && (touchedFields.flowRate || wasUpdateAttempted)
  ),
);

const isFlowRateUnitErrorFieldIsActive = createSelector(
  getUpdateAttemptStatus,
  getTemperatureControlTouchedFields,
  getFlowRateUnitFieldError,
  (wasUpdateAttempted, touchedFields, error) => (
    !!error && (touchedFields.flowRateUnit || wasUpdateAttempted)
  ),
);

const getCharges = createSelector(
  localState,
  (state) => state.charges,
);

const getIsWithGensetCharges = createSelector(
  getCharges,
  (charges) => !!charges?.some((item) => item?.chargeCode?.code === GEN),
);

const getSumOfGenset = createSelector(
  getCharges,
  (charges) => charges?.reduce((acc, item) => {
    if (item?.chargeCode?.code === GEN) {
      return acc + (item.buyTotalCost || 0);
    }
    return acc;
  }, 0),
);

export const temperatureControlSelectors = {
  getAllTemperatureControlData,
  getLoading,
  isTemperatureErrorFieldIsActive,
  getTemperatureControl,
  getTemperatureControlDraft,
  getTemperatureControlErrors,
  getTemperatureControlInitialState,
  getTemperatureControlTouchedFields,
  getTemperatureFieldError,
  getUpdateAttemptStatus,
  isFlowRateErrorFieldIsActive,
  isFlowRateUnitErrorFieldIsActive,
  getIsWithGensetCharges,
  getSumOfGenset,
};
