import React, { forwardRef } from 'react';

import { InvoicePDFDocumentComponent } from './InvoicePDFDocument.component';

interface InvoicePDFDocumentContainerProps {
  isAPInvoiceType: boolean;
  fileNamePrint?: string;
}

const InvoicePDFDocumentContainer = forwardRef((props: InvoicePDFDocumentContainerProps, ref) => (
  <InvoicePDFDocumentComponent
    ref={ref}
    fileNamePrint={props?.fileNamePrint}
    isAPInvoiceType={props?.isAPInvoiceType}
  />
));

export { InvoicePDFDocumentContainer as InvoicePDFDocument };
