import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import Skeleton from 'antd/es/skeleton';

import {
  Container,
  ContainerWrap,
  Actions,
  Button,
} from './Footer.styled';

interface FooterComponentProps {
  acceptTermsAndConditions: () => void
  closeDrawer: () => void
  isLoading: boolean
}

export const FooterComponent: FC<FooterComponentProps> = ({
  acceptTermsAndConditions,
  closeDrawer,
  isLoading,
}) => {
  const { t } = useTranslation();

  const handleAcceptTermsAndConditions = useCallback(() => {
    acceptTermsAndConditions();
  }, []);

  return (
    <Container>
      {isLoading ? <Skeleton /> : (
        <ContainerWrap>
          <Actions>
            <Button
              onClick={closeDrawer}
              type="default"
            >
              {t('Cancel')}
            </Button>

            <Button onClick={handleAcceptTermsAndConditions} disabled={false}>
              {t('Accept')}
            </Button>
          </Actions>
        </ContainerWrap>
      )}
    </Container>
  );
};
