import awsConfig from './awsConfig';

const amplifyConfig = {
  Auth: {
    authenticationFlowType: 'USER_SRP_AUTH',
    mandatorySignIn: true,
    region: awsConfig.cognito.REGION,
    userPoolId: awsConfig.cognito.USER_POOL_ID,
    userPoolWebClientId: awsConfig.cognito.APP_CLIENT_ID,
    oauth: {
      scope: ['email', 'openid', 'phone', 'profile', 'aws.cognito.signin.user.admin'],
      redirectSignIn: 'http://localhost:3000/',
      redirectSignOut: 'http://localhost:3000/',
      responseType: 'code',
    },
  },
};

export default amplifyConfig;
