import React, { FC, useContext, useMemo } from 'react';

import 'typeface-roboto';

import { Routes } from 'app-wrapper/view/routes';

import { AdditionalServicesDrawerStore } from 'monetary/repository/mobxStores/AdditionalServicesDrawer';

import './fonts.css';
import { initUC as initAuthenticationUC } from 'authentication/controllers';
import { useLocation, useNavigate, useParams } from 'react-router';
import { initUC as initMonetaryUC } from 'monetary/controllers';
import { initUC as initShipmentOperationsUC } from 'shipment-operations/controllers';
import { initUC as initUserManagementUC } from 'user-management/controllers';
import { initUC as initAppWrapperUC } from 'app-wrapper/controllers';
import { ReactReduxContext } from 'react-redux';
import { UserEditProfileStore } from 'user-management/repository/mobxStores/UserEditProfile';
import { CustomerSalesStore } from 'user-management/repository/mobxStores/CustomerSales';
import { AdditionalServicesStore } from 'shipment-operations/repository/mobxStores';
import { MobxStoresContext, MobxStoresInterface } from 'app-wrapper/mobxStores';
import { ControllerParams } from 'proto/BaseController';
import { DrawerStore } from 'app-wrapper/repository/mobxStores/DrawerStore';
import { ContractsStore } from 'app-wrapper/repository/mobxStores/Contracts';
import { OverviewShipmentStore } from 'app-wrapper/repository/mobxStores/OverviewShipmentStore';
import { ShipmentCostChangesDrawerStore } from 'app-wrapper/repository/mobxStores/ShipmentCostChangesDrawerStore';
import { RepositoriesContext, RepositoriesInterface } from 'app-wrapper/view/repositories';
import { OrganizationRepository } from 'user-management/repositories/OrganizationRepository';
import { ShipmentPermissionsRepository } from 'shipment-operations/repositories/ShipmentPermissionsRepository';
import { AllRequestsStore } from 'monetary/repository/mobxStores/AllRequestsStore';
import { AppWrapper } from './App.styled';

export const App: FC = () => {
  const { store } = useContext(ReactReduxContext);
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();

  const mobxStores = useMemo<MobxStoresInterface>(() => ({
    overviewDepartingShipmentStore: new OverviewShipmentStore(),
    overviewArrivingShipmentStore: new OverviewShipmentStore(),
    overviewPendingConfirmationsShipmentStore: new OverviewShipmentStore(),
    userEditProfileStore: new UserEditProfileStore(),
    additionalServicesDrawerStore: new AdditionalServicesDrawerStore(),
    drawerStore: new DrawerStore(),
    shipmentServices: new AdditionalServicesStore(),
    customerSales: new CustomerSalesStore(),
    contracts: new ContractsStore(),
    shipmentCostChangesDrawer: new ShipmentCostChangesDrawerStore(),
    allRequestsStore: new AllRequestsStore(),
  }), []);

  const repositories = useMemo<RepositoriesInterface>(() => ({
    organizationRepository: new OrganizationRepository(store),
    shipmentPermissionsRepository: new ShipmentPermissionsRepository(store),
  }), []);

  const controllerParams: ControllerParams = {
    store, mobxStores, navigate, params, location, repositories,
  };

  initAppWrapperUC(controllerParams);
  initAuthenticationUC(controllerParams);
  initMonetaryUC(controllerParams);
  initShipmentOperationsUC(controllerParams);
  initUserManagementUC(controllerParams);
  return (
    <MobxStoresContext.Provider value={mobxStores}>
      <RepositoriesContext.Provider value={repositories}>
        <AppWrapper>
          <Routes />
        </AppWrapper>
      </RepositoriesContext.Provider>
    </MobxStoresContext.Provider>
  );
};
