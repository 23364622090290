import React, { FC, useCallback } from 'react';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';

import { Button } from 'app-wrapper/view/components';

import { Footer } from './DrawerFooter.styled';

interface ITeamDrawerFooterComponentProps {
  onClose: () => void;
  onSave: (shipmentId: string) => void;
  isAbleToSave: boolean;
}

export const DrawerFooterComponent: FC<ITeamDrawerFooterComponentProps> = ({
  onClose,
  onSave,
  isAbleToSave,
}) => {
  const { shipmentId = '' } = useParams();
  const { t } = useTranslation();

  const handleSave = useCallback(() => {
    onSave(shipmentId);
  }, [shipmentId, onSave]);

  return (
    <Footer>
      <Button type="default" onClick={onClose}>
        {t('Cancel')}
      </Button>

      <Button
        onClick={handleSave}
        disabled={!isAbleToSave}
      >
        {t('Select')}
      </Button>
    </Footer>
  );
};
