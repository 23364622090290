import React from 'react';
import { useSelector } from 'react-redux';

import { useAppSelector } from 'app-wrapper/hooks';

import { R as AppR } from 'app-wrapper/repository';
import { R } from 'shipment-operations/repository';
import { UC } from 'shipment-operations/controllers';

import { ShipmentHeaderComponent } from './ShipmentHeader.component';

const ShipmentHeaderContainer = () => {
  const shipment = useAppSelector(R.selectors.shipment.getShipment);
  const shipmentByIdStats = useSelector(AppR.selectors.overview.getShipmentByIdStats);
  const isUnconfirmedChanges = useSelector(R.selectors.shipment.getIsUnconfirmedChanges);

  return (
    <ShipmentHeaderComponent
      shipment={shipment}
      shipmentStats={shipmentByIdStats}
      isUnconfirmedChanges={isUnconfirmedChanges}
      onClickToCustomer={UC.shipmentList.onClickToCustomer}
      onChangeIsOpenModalShipmentReference={UC.overview.onChangeIsOpenModalShipmentReference}
    />
  );
};

export {
  ShipmentHeaderContainer as ShipmentHeader,
};
