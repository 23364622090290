import React, { memo } from 'react';
import { useSelector } from 'react-redux';

import { UC } from 'user-management/controllers';
import { R } from 'user-management/repository';

import { UsersComponent } from 'user-management/view/pages/Users/Users.component';

const UsersContainer = memo(() => {
  const usersPermissions = useSelector(R.selectors.moduleFunctionalityPermissions.getUsersPermissions);
  const isLoading = useSelector(R.selectors.users.getIsLoading);

  return (
    <UsersComponent
      usersPermissions={usersPermissions}
      init={UC.users.init}
      downloadUsersFile={UC.users.downloadUsersXml}
      isLoading={isLoading}
    />
  );
});

export { UsersContainer as UsersPage };
