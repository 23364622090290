import React, {
  FC, memo, useCallback, useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import WarningOutlined from '@ant-design/icons/WarningOutlined';
import CloseCircleOutlined from '@ant-design/icons/CloseCircleOutlined';
import FieldTimeOutlined from '@ant-design/icons/FieldTimeOutlined';
import ClockCircleOutlined from '@ant-design/icons/ClockCircleOutlined';
import { DataNode } from 'antd/es/tree';
import FilterFilled from '@ant-design/icons/FilterFilled';

import { REQUEST_STATUS, RouteNames } from 'app-wrapper/constants';
import {
  EmptyStateForPages,
  PageHeader,
  TasksBlockSummaryComponents,
  TasksFilterHeaderComponent,
} from 'app-wrapper/view/components';
import { AgreementsDrawer } from 'app-wrapper/view/drawers';
import {
  AppFiltersCheckboxGroupDTM,
  CommandCenterComponentTaskDTM,
  NotificationTaskFilterDatesDTM,
  NotificationViewedFiltersDTM,
} from 'app-wrapper/models/dtm';
import themesColors from 'app-wrapper/view/themes/themesColors';

import { Layout } from 'app-wrapper/view/guideline';
import {
  CCContent,
  CCContentFilters,
  CCContentHeader,
  CCContentHeaderItem,
  CCContentHeaderItemActiveTab,
  CCContentStatistics,
  CCContentStatisticsBlock,
  CCWrapHeader,
} from './CommandCenter.styled';
import { ContentTableTasks } from './components';

interface ICommandCenterTasksComponentProps {
  onResetTaskPage: () => void;
  onStart: () => Promise<void>;
  nextPageTasks: () => void;
  checkAnotherDownloadNextPageTask: (parentHeight: number, elementHeight: number) => void;
  onApplyAssignee?: () => void
  onResetAssignee?: () => void
  onChangeGroupAssignee?: (id: number, value: AppFiltersCheckboxGroupDTM) => void
  onApplyObject?: () => void
  onResetObject?: () => void
  onChangeGroupObject?: (checkedKeysValue: string[]) => void
  onApplyReceivedDate?: () => void
  onResetReceivedDate?: () => void
  dateOnReceivedDateChange?: (dates: NotificationTaskFilterDatesDTM) => void
  onChangeGroupModule?: (id: number, value: AppFiltersCheckboxGroupDTM) => void
  onApplyModule?: () => void
  onResetModule?: () => void
  onClearAllFilters?: () => void
  openAgreementsDrawer: (shipmentId: string) => void;
  onChangeFilterShowOnlyUnread?: (value: boolean) => void
  openVGMDrawer: (shipmentId: string) => void;
  openRailBillingDrawer: (shipmentId: string) => void;
  openShipmentCostChangesDrawer: (taskId: string) => void;
  isLoad?: string;
  isAlerts?: boolean;
  isLoadAdd?: string;
  hideHeader?: boolean;
  titleLink2?: string;
  titleHeader?: string;
  emptyContentTextNoTasks?: string;
  titleStatisticsBlockFirst?: string;
  titleFilterBlockAll?: string;
  titleNameFirstOverdue?: string;
  titleNameFirstColumn?: string;
  overdueTasks?: CommandCenterComponentTaskDTM[];
  todayTasks?: CommandCenterComponentTaskDTM[];
  thisWeekTasks?: CommandCenterComponentTaskDTM[];
  allTasks?: CommandCenterComponentTaskDTM[];
  laterTasks?: CommandCenterComponentTaskDTM[];
  countTasksOverdue?: number;
  countTasksToday?: number;
  countTasksThisWeek?: number;
  countTasksLater?: number;
  countTasks?: number;
  isHaveNotification?: boolean;
  infoCountAllTasks?: number;
  infoCountOverdueTasks?: number;
  infoCountDueTasks?: number;
  infoCountPlannedTasks?: number;
  isVisibleClearAll?: boolean;
  filters?: NotificationViewedFiltersDTM;
  treeDataObject?: DataNode[]
  openMaerskDrawer?: () => void;
  setShipmentId?: (shipmentId: string) => void;
  openMismatchDrawer?: () => void;
  openChangesDrawer?: (withValidate?: boolean) => void;
  openBookingConfirmationDrawer?: () => void;
}

const CommandCenterTasksComponent: FC<ICommandCenterTasksComponentProps> = (props) => {
  const {
    onResetTaskPage,
    onStart,
    nextPageTasks,
    checkAnotherDownloadNextPageTask,
    onApplyAssignee,
    onResetAssignee,
    onChangeGroupAssignee,
    onApplyObject,
    onResetObject,
    onChangeGroupObject,
    onApplyReceivedDate,
    onResetReceivedDate,
    dateOnReceivedDateChange,
    onChangeGroupModule,
    onApplyModule,
    onResetModule,
    onClearAllFilters,
    openAgreementsDrawer,
    onChangeFilterShowOnlyUnread,
    openVGMDrawer,
    openRailBillingDrawer,
    openShipmentCostChangesDrawer,
    isLoad,
    isAlerts,
    isLoadAdd,
    hideHeader,
    emptyContentTextNoTasks,
    titleLink2,
    titleHeader,
    titleStatisticsBlockFirst,
    titleFilterBlockAll,
    titleNameFirstOverdue,
    titleNameFirstColumn,
    overdueTasks,
    todayTasks,
    thisWeekTasks,
    allTasks,
    laterTasks,
    countTasksOverdue,
    countTasksToday,
    countTasksThisWeek,
    countTasksLater,
    isHaveNotification,
    countTasks,
    infoCountAllTasks,
    infoCountOverdueTasks,
    infoCountDueTasks,
    infoCountPlannedTasks,
    isVisibleClearAll,
    filters,
    treeDataObject,
    openMaerskDrawer,
    setShipmentId,
    openMismatchDrawer,
    openChangesDrawer,
    openBookingConfirmationDrawer,
  } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();

  const title = titleHeader || t('Tasks');

  useEffect(() => {
    const foo = async () => {
      onResetTaskPage();

      onStart();
    };

    foo();
  }, []);

  const clickTabNotification = useCallback(
    () => {
      navigate(titleLink2 || RouteNames.COMMAND_CENTER_TASKS_COMPLETED());
    },
    [titleLink2, nextPageTasks],
  );

  const isHasTasks = !!(overdueTasks?.length || todayTasks?.length || thisWeekTasks?.length || laterTasks?.length);

  const onChangeFilterShowOnlyUnreadEmptyTasks = useCallback(
    () => {
      if (onChangeFilterShowOnlyUnread) {
        onChangeFilterShowOnlyUnread(true);
      }
    },
    [onChangeFilterShowOnlyUnread],
  );

  const onClearAllFiltersHandler = useCallback(
    () => {
      if (onClearAllFilters) {
        onClearAllFilters();
      }
    },
    [onClearAllFilters],
  );

  return (
    <>
      <AgreementsDrawer />

      {!hideHeader && (
        <CCWrapHeader>
          <PageHeader title={title} />
        </CCWrapHeader>
      )}

      <Layout id="Layout">
        <CCContent>
          <CCContentHeader>
            <CCContentHeaderItem isActive>
              {t('Active')}
            </CCContentHeaderItem>
            <CCContentHeaderItem onClick={clickTabNotification} isActive={false}>
              {t('Completed')}
              {/* Currently is hide */}
              {(isHaveNotification && false) && (<CCContentHeaderItemActiveTab />)}
            </CCContentHeaderItem>
          </CCContentHeader>

          {isAlerts ? null : (
            <CCContentStatistics>
              <CCContentStatisticsBlock>
                <TasksBlockSummaryComponents
                  titleName={titleStatisticsBlockFirst || t('All Tasks')}
                  count={`${infoCountAllTasks || 0}`}
                  iconInfo={<WarningOutlined style={{ color: themesColors.secondaryDot45 }} />}
                />
              </CCContentStatisticsBlock>
              <CCContentStatisticsBlock isNext>
                <TasksBlockSummaryComponents
                  titleName={t('Overdue')}
                  count={`${infoCountOverdueTasks || 0}`}
                  iconInfo={<CloseCircleOutlined style={{ color: infoCountOverdueTasks ? themesColors.characterBrandingDanger : themesColors.secondaryDot45 }} />}
                />
              </CCContentStatisticsBlock>
              <CCContentStatisticsBlock isNext>
                <TasksBlockSummaryComponents
                  titleName={t('Due')}
                  titleSubName={t('Less Than 72 h')}
                  count={`${infoCountDueTasks || 0}`}
                  iconInfo={<FieldTimeOutlined style={{ color: infoCountDueTasks ? themesColors.characterBrandingWarning : themesColors.secondaryDot45 }} />}
                />
              </CCContentStatisticsBlock>
              <CCContentStatisticsBlock isNext>
                <TasksBlockSummaryComponents
                  titleName={t('Planned')}
                  count={`${infoCountPlannedTasks || 0}`}
                  iconInfo={<ClockCircleOutlined style={{ color: themesColors.secondaryDot45 }} />}
                />
              </CCContentStatisticsBlock>
            </CCContentStatistics>
          )}

          <CCContentFilters>
            <TasksFilterHeaderComponent
              isVisibleAssignee
              isVisibleObject
              isVisibleSearch
              isVisibleShowOnlyUnread
              shownOnlyName={titleFilterBlockAll || t('View Organization Tasks')}
              valueShowOnlyUnread={filters?.isOnlyUnread}
              isDisabledShowOnlyUnread={filters?.isDisabledOnlyUnread}
              groupAssignee={filters?.filterAssigneeGroup}
              disabledModalAssignee={filters?.filterAssignee?.disabledModal}
              disabledModalAssigneeOrganization={filters?.filterAssigneeOrganization?.disabledModal}
              isDisabledResetAssignee={filters?.filterAssignee?.disableReset}
              groupObject={filters?.filterTreeDataObject?.group}
              isDisabledResetObject={filters?.filterTreeDataObject?.disableReset}
              onChangeFilterShowOnlyUnread={onChangeFilterShowOnlyUnread}
              onApplyAssignee={onApplyAssignee}
              onChangeGroupAssignee={onChangeGroupAssignee}
              onResetAssignee={onResetAssignee}
              onApplyObject={onApplyObject}
              onChangeGroupObject={onChangeGroupObject}
              onResetObject={onResetObject}
              isVisibleDates
              receivedName={t('Due date')}
              receivedDates={filters?.filterReceivedDates}
              isVisibleModule
              isVisibleClearAll={isVisibleClearAll}
              isDisabledResetModule={filters?.filterModule?.disableReset}
              groupModule={filters?.filterModule?.group}
              treeDataObject={treeDataObject}
              onApplyReceivedDate={onApplyReceivedDate}
              onResetReceivedDate={onResetReceivedDate}
              dateOnReceivedDateChange={dateOnReceivedDateChange}
              onChangeGroupModule={onChangeGroupModule}
              onApplyModule={onApplyModule}
              onResetModule={onResetModule}
              onClearAllFilters={onClearAllFilters}
            />
          </CCContentFilters>

          {isHasTasks || isLoad === REQUEST_STATUS.pending ? (
            <ContentTableTasks
              infiniteScrollId="Layout"
              onStart={() => ''}
              checkAnotherDownloadNextPageTask={checkAnotherDownloadNextPageTask}
              openAgreementsDrawer={openAgreementsDrawer}
              openVGMDrawer={openVGMDrawer}
              openRailBillingDrawer={openRailBillingDrawer}
              isLoad={isLoad}
              isLoadAdd={isLoadAdd}
              allTasks={allTasks}
              overdueTasks={overdueTasks}
              todayTasks={todayTasks}
              thisWeekTasks={thisWeekTasks}
              laterTasks={laterTasks}
              countTasksOverdue={countTasksOverdue}
              countTasksToday={countTasksToday}
              countTasksThisWeek={countTasksThisWeek}
              countTasksLater={countTasksLater}
              countTasks={countTasks}
              titleNameFirstOverdue={titleNameFirstOverdue}
              titleNameFirstColumn={titleNameFirstColumn}
              openMaerskDrawer={openMaerskDrawer}
              setShipmentId={setShipmentId}
              openMismatchDrawer={openMismatchDrawer}
              openChangesDrawer={openChangesDrawer}
              openBookingConfirmationDrawer={openBookingConfirmationDrawer}
              openShipmentCostChangesDrawer={openShipmentCostChangesDrawer}
            />
          ) : null}

          {!isVisibleClearAll && !isHasTasks && isLoad !== REQUEST_STATUS.pending ? (
            <EmptyStateForPages
              icon={(
                <FilterFilled
                  width="28px"
                  height="22px"
                  style={{
                    color: themesColors.neutralBranding65,
                    width: '28px',
                    height: '22px',
                  }}
                />
              )}
              headerText={t('You’re all caught up.')}
              contentText={emptyContentTextNoTasks || t(' There are no tasks assigned to you right now. See how you can assist your colleagues.')}
              buttonText={t('View organization tasks')}
              onClickButton={onChangeFilterShowOnlyUnreadEmptyTasks}
            />
          ) : null}
          {isVisibleClearAll && !isHasTasks && isLoad !== REQUEST_STATUS.pending ? (
            <EmptyStateForPages
              icon={(
                <FilterFilled
                  width={28}
                  height={22}
                  color={themesColors.neutralBranding65}
                />
              )}
              headerText={t('No tasks found')}
              contentText={t('Filters maybe to restrictive')}
              buttonText={t('Clear filters')}
              onClickButton={onClearAllFiltersHandler}
            />
          ) : null}

        </CCContent>
      </Layout>
    </>
  );
};

const CommandCenterTasksComponentCache = memo(CommandCenterTasksComponent);

export { CommandCenterTasksComponentCache as CommandCenterTasksComponent };
