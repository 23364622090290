import {
  IsDefined, IsNumber, IsOptional, IsString, ValidateNested,
} from 'class-validator';
import { Type } from 'class-transformer';
import { BaseDTM } from 'proto/BaseDTM';

import { ShipmentBillingInvoiceDtm } from 'shipment-operations/models/dtm';

export interface IInvoicesResponseDTM {
  totalElements: number
  data: ShipmentBillingInvoiceDtm[]
}

export class InvoicesResponseDTM extends BaseDTM<IInvoicesResponseDTM> {
  @IsNumber()
  @IsDefined()
  totalElements: number;

  @ValidateNested()
  @Type(() => ShipmentBillingInvoiceDtm)
  data: ShipmentBillingInvoiceDtm[]
}

export interface IInvoicesTableDTM {
  id?: number
  key?: number
  debtorName?: string
  vendor: string
  number: string
  created: string
  dueDate: string
  status: string
  total: number
  balance: number
  shipment?: number
  shipmentName?: string
}

export class InvoicesTableDTM extends BaseDTM<IInvoicesTableDTM> {
  @IsNumber()
  @IsOptional()
  id?: number;

  @IsNumber()
  @IsOptional()
  key?: number;

  @IsString()
  @IsOptional()
  debtorName?: string;

  @IsString()
  @IsDefined()
  vendor: string;

  @IsString()
  @IsDefined()
  number: string;

  @IsString()
  @IsDefined()
  created: string;

  @IsString()
  @IsDefined()
  dueDate: string;

  @IsString()
  @IsDefined()
  status: string;

  @IsNumber()
  @IsDefined()
  total: number;

  @IsNumber()
  @IsDefined()
  balance: number;

  @IsNumber()
  @IsOptional()
  shipment: number;

  @IsString()
  @IsOptional()
  shipmentName: string;
}
