import styled from 'styled-components';
import Typography from 'antd/es/typography';

import { divFlex } from 'app-wrapper/view/themes/mixin';

export const SBLInformation = styled('div').attrs({
  'data-class': 'SBLRoutingInformation',
})`
  ${divFlex}
  flex-direction: column;
`;

export const SBLHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const SBLTitle = styled(Typography)`
  width: 100%;
  margin-bottom: 12px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin-right: auto;
`;

export const SBLRow = styled('div')`
  ${divFlex}
  flex-direction: row;
`;

export const SBLItem = styled('div')`
  ${divFlex}
  width: 25%;
  flex-direction: column;
`;

export const SBLItemTitle = styled(Typography)`
  font-size: 12px;
  line-height: 20px;
  color: ${({ theme }) => theme.themesColors.secondaryDot45};
`;

export const SBLItemSubTitle = styled(Typography)`
  font-size: 14px;
  line-height: 22px;
`;

export const SBLItemSubTitleGrey = styled(Typography)`
  margin-left: 4px;
  font-size: 14px;
  line-height: 22px;
  color: ${({ theme }) => theme.themesColors.secondaryDot45};
`;
