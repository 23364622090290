import { createSelector } from 'reselect';
import { RootState } from 'app-wrapper/store';

const localState = (state: RootState) => state.users;

const getIsLoading = createSelector(
  localState,
  (state) => state.isLoading,
);

export const usersSelectors = {
  getIsLoading,
};
