import React, {
  ChangeEvent,
  FC, useCallback,
} from 'react';
import { useTranslation } from 'react-i18next';

import { Modal } from 'app-wrapper/view/components';

import {
  ModalRateCancelBtn,
  ModalRateContent, ModalShipmentReferenceUpdate, ModalShipmentReferenceUpdateSelect, ModalShipmentReferenceUpdateTitle, ModalRateFooter, ModalRateHeader, ModalRateSaveBtn, Wrapper, ModalRateContentInput,
} from './ModalShipmentReferenceUpdate.styled';

interface IModalShipmentReferenceUpdateProps {
  shipmentReference?: string;
  isModalOpen?: boolean;
  isErrorReference?: boolean;
  onClickCloseModal: (value: boolean) => void;
  saveShipmentReference: () => void
  onChangeShipmentReference: (value: string) => void
}

const ModalShipmentReferenceUpdateComponent: FC<IModalShipmentReferenceUpdateProps> = ({
  shipmentReference,
  isModalOpen,
  isErrorReference,
  onClickCloseModal,
  saveShipmentReference,
  onChangeShipmentReference,
}) => {
  const { t } = useTranslation();

  const onCloseHandler = useCallback(
    () => '',
    [],
  );

  const onCancelHandler = useCallback(
    () => onClickCloseModal(false),
    [onClickCloseModal],
  );

  const renderFooter = useCallback(() => <></>, []);

  const onChangeReferenceHandle = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    onChangeShipmentReference(event.target.value);
  }, [onChangeShipmentReference]);

  return (
    <Wrapper>
      <Modal
        title=""
        open={!!isModalOpen}
        onClose={onCloseHandler}
        renderFooter={renderFooter}
      >
        <ModalRateContent>
          <ModalRateHeader>
            {t('References')}
          </ModalRateHeader>

          <ModalShipmentReferenceUpdate>
            <ModalShipmentReferenceUpdateTitle>
              {`${t('Shipment Reference')}`}
            </ModalShipmentReferenceUpdateTitle>

            <ModalShipmentReferenceUpdateSelect>
              <ModalRateContentInput
                value={shipmentReference}
                isError={isErrorReference}
                onChange={onChangeReferenceHandle}
              />
            </ModalShipmentReferenceUpdateSelect>
          </ModalShipmentReferenceUpdate>

          <ModalRateFooter>
            <ModalRateCancelBtn
              onClick={onCancelHandler}
            >
              {t('addChargeMenu.cancel')}
            </ModalRateCancelBtn>
            <ModalRateSaveBtn
              onClick={saveShipmentReference}
            >
              {t('save')}
            </ModalRateSaveBtn>
          </ModalRateFooter>

        </ModalRateContent>
      </Modal>
    </Wrapper>
  );
};

export { ModalShipmentReferenceUpdateComponent };
