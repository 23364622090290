import styled from 'styled-components';

import { divFlex } from 'app-wrapper/view/themes/mixin';

export const TasksBlockSummaryComponentsWrap = styled.div.attrs({
  'data-class': 'TasksBlockSummaryComponentsWrap',
})`
  ${divFlex}

  width: 100%;
  height: 81px;
  flex-direction: column;
  border-radius: 3px;
  background-color: ${({ theme }) => theme.themesColors.neutralBranding1};
  border: 1px solid ${({ theme }) => theme.themesColors.neutralBranding4};
  padding: 12px 16px;
`;

export const TasksBlockSummaryComponentsTitleWrap = styled.div.attrs({
  'data-class': 'TasksBlockSummaryComponentsTitleWrap',
})`
  ${divFlex}

`;

export const TasksBlockSummaryComponentsTitle = styled.div.attrs({
  'data-class': 'TasksBlockSummaryComponentsTitle',
})`
  ${divFlex}

  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  color: ${({ theme }) => theme.themesColors.secondaryDot45};
`;

export const TasksBlockSummaryComponentsSubTitle = styled.div.attrs({
  'data-class': 'TasksBlockSummaryComponentsSubTitle',
})`
  ${divFlex}

  margin-left: 4px;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  color: ${({ theme }) => theme.themesColors.neutralBranding6};
`;

export const TasksBlockSummaryComponentsTitleContent = styled.div.attrs({
  'data-class': 'TasksBlockSummaryComponentsTitleContent',
})`
  ${divFlex}

  margin-top: 3px;
`;

export const TasksBlockSummaryComponentsTitleContentIcon = styled.div.attrs({
  'data-class': 'TasksBlockSummaryComponentsTitleContentIcon',
})`
  ${divFlex}

  align-items: center;
`;

export const TasksBlockSummaryComponentsTitleContentCount = styled.div.attrs({
  'data-class': 'TasksBlockSummaryComponentsTitleContentCount',
})`
  ${divFlex}

  margin-left: 8px;
  font-size: 24px;
  font-weight: 400;
`;
