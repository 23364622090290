import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ShipmentStatsCustomer } from 'shipment-operations/models/dtm';
import { SkeletonSection } from 'app-wrapper/view/components/Skeleton/Skeleton.component';
import { GapHorizontalContainerStyled } from 'app-wrapper/view/guideline';
import { InnerItem } from './InnerItem';

interface IDashboardComponentProps {
  loading: boolean
  totalProfit: number
  totalProfitPercent: number
  totalCost: number
  arBudgetTotalCost: number
  apBudgetTotalCost: number
  receivablesDifferent: number
  receivablesDifferentPercent: number
  buyTotalCost: number
  payablesDifferent: number
  payablesDifferentPercent: number
  withExtra?: boolean
  arStats?: ShipmentStatsCustomer
  apStats?: ShipmentStatsCustomer
}

const DashboardComponent: FC<IDashboardComponentProps> = ({
  loading, totalProfit, totalProfitPercent, totalCost, arBudgetTotalCost,
  receivablesDifferent, receivablesDifferentPercent, buyTotalCost, apBudgetTotalCost,
  payablesDifferent, payablesDifferentPercent, withExtra = false, arStats, apStats,
}) => {
  const { t } = useTranslation();

  return (
    <GapHorizontalContainerStyled>
      {
        loading
          ? (
            <>
              <SkeletonSection height="53px" />
              <SkeletonSection height="53px" />
              <SkeletonSection height="53px" />
            </>
          )
          : (
            <>
              <InnerItem
                title={t('Payables')}
                mainValue={buyTotalCost}
                withBudget
                budgetPlan={apBudgetTotalCost}
                difference={payablesDifferent}
                differencePercent={payablesDifferentPercent}
                payable
                mainValueDataTestId="payablesMainValue"
                withExtra={withExtra}
                stats={apStats}
              />
              <InnerItem
                title={t('Receivables')}
                mainValue={totalCost}
                withBudget
                budgetPlan={arBudgetTotalCost}
                difference={receivablesDifferent}
                differencePercent={receivablesDifferentPercent}
                mainValueDataTestId="receivablesMainValue"
                withExtra={withExtra}
                stats={arStats}
              />
              <InnerItem
                title={t('Profit')}
                mainValue={totalProfit}
                mainValuePercent={totalProfitPercent}
                mainValueDataTestId="profitMainValue"
              />
            </>
          )
      }
    </GapHorizontalContainerStyled>
  );
};

export { DashboardComponent };
