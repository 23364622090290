import React from 'react';
import { observer } from 'mobx-react-lite';

import { useMobxStore } from 'app-wrapper/mobxStores';
import { UC } from 'shipment-operations/controllers';

import { UploadDocumentsComponent } from './UploadDocuments.component';

export const UploadDocuments = observer(() => {
  const { shipmentServices } = useMobxStore();

  return (
    <UploadDocumentsComponent
      isManager={shipmentServices.state.isManager}
      documentActivities={shipmentServices.state.documentActivities}
      openDocumentUploadDrawer={UC.shipmentServices.openDocumentUploadDrawer}
      downloadDocument={UC.shipmentDocumentsAll.downloadDocument}
      removeServiceDocument={UC.shipmentServices.removeShipmentDocument}
    />
  );
});
