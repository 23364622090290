import React from 'react';

import { useAppSelector } from 'app-wrapper/hooks';
import { R } from 'shipment-operations/repository';
import { UC } from 'shipment-operations/controllers';
import { HeaderComponent } from 'shipment-operations/view/components/PaymentHeader';
import moment from 'moment';

const HeaderContainer = () => {
  const invoice = useAppSelector(R.selectors.shipmentBillingInvoice.getInvoice);
  const date = useAppSelector(R.selectors.makePayment.getEditedDate);
  const reference = useAppSelector(R.selectors.makePayment.getEditedReference);
  const amount = useAppSelector(R.selectors.makePayment.getEditedTotalAmount);
  const isShowError = useAppSelector(R.selectors.makePayment.getIsShowAmountError);

  return (
    <HeaderComponent
      invoice={invoice}
      onChangeReceivedAmount={UC.makePayment.onChangeEditTotalAmount}
      onChangePaymentDate={UC.makePayment.onChangeEditDate}
      onChangeReference={UC.makePayment.onChangeEditReference}
      receivedAmount={amount}
      savedDate={moment(date)}
      savedReference={reference}
      isShowAmountError={isShowError}
    />
  );
};

export { HeaderContainer as Header };
