import React, { forwardRef } from 'react';

import {
  InvoicePDFHeader,
  InvoicePDFGeneralInfo,
  InovicePDFShipmentDetails,
  InvoicePDFBankDetails,
  InvoicePDFCharges,
} from './components';
import { Document } from './InvoicePDFDocument.styled';

interface InvoicePDFDocumentComponentProps {
  isAPInvoiceType: boolean;
  fileNamePrint?: string;
}

export const InvoicePDFDocumentComponent = forwardRef((props: InvoicePDFDocumentComponentProps, ref) => (
  <table
    ref={ref as React.RefObject<any>}
    style={{
      minWidth: '100%',
      padding: 0,
    }}
  >
    {props?.fileNamePrint ? (<title>{props?.fileNamePrint}</title>) : null}
    <thead
      style={{ minWidth: '100vw', padding: 0 }}
    >
      <th style={{ minWidth: '100vw', padding: 0 }}>
        <td style={{ minWidth: '100vw', padding: 0 }}>
          <InvoicePDFHeader />
        </td>
      </th>
    </thead>
    <tbody>
      <tr>
        <td>
          <Document>
            <InvoicePDFGeneralInfo isAPInvoiceType={props.isAPInvoiceType} />
            <InovicePDFShipmentDetails />
            <InvoicePDFCharges />
            <InvoicePDFBankDetails />
          </Document>
        </td>
      </tr>
    </tbody>
  </table>
));
