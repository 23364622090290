import React from 'react';
import { useSelector } from 'react-redux';

import { UC } from 'monetary/controllers';
import { UC as shipmentOperationsUC } from 'shipment-operations/controllers';
import { R as shipmentOperationsR } from 'shipment-operations/repository';
import { R as freightQuotaR } from 'monetary/repository';

import { PrintQuoteRFQComponent } from './PrintQuoteRFQ.component';

const PrintQuoteRFQContainer = () => {
  const isLoading = useSelector(shipmentOperationsR.selectors.shipmentDocumentsAll.getIsRFQLoading);
  const quotaIndex = useSelector(shipmentOperationsR.selectors.shipmentDocumentsAll.getQuoteIndex);
  const allQuotasByIndexFileNamePrint = useSelector(freightQuotaR.selectors.freightQuote.getFreightQuoteCurrentAllQuotasByIndexFileNamePrint(quotaIndex));

  return (
    <PrintQuoteRFQComponent
      isLoading={isLoading}
      quotaIndex={quotaIndex}
      fileNamePrint={allQuotasByIndexFileNamePrint}
      onPrint={UC.FreightQuote.onPrintAtDocumentsAllPage}
      onClearCustomerInfo={UC.FreightQuote.onClearCustomerInfo}
      loadData={shipmentOperationsUC.shipmentDocumentsAll.loadQuoteDocumentInformation}
    />
  );
};

export { PrintQuoteRFQContainer as PrintQuoteRFQ };
