import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';
import { PermissionAttributePolicy } from 'shipment-operations/constants';

import { SBLContainersTableComponent } from './SBLContainersTable.component';

const SBLContainersTableContainer = () => {
  const containers = useSelector(R.selectors.billOfLadingCommon.getContainers);
  const temperatureControl = useSelector(R.selectors.billOfLadingCommon.getTemperatureControl);
  const permissions = useSelector(R.selectors.shipment.getSIPermissions);
  const isHBLPage = useSelector(R.selectors.billOfLadingCommon.getIsHBLPage);

  const isAbleToEdit = useMemo(() => !isHBLPage && permissions.editSISections === PermissionAttributePolicy.WRITE, [permissions, isHBLPage]);

  return (
    <SBLContainersTableComponent
      containers={containers}
      temperatureControl={temperatureControl}
      isAbleToEdit={isAbleToEdit}
    />
  );
};

export { SBLContainersTableContainer as SBLContainersTable };
