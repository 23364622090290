import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Option, SelectWithError, InputWithError } from 'app-wrapper/view/components';

import {
  EditOrganizationProfileDrawerDtm,
  EditPartnerOrganizationProfileDrawerDtm,
  OrganizationDTM,
  OrganizationFieldsErrorsDTM,
  OrganizationFieldsErrorsFieldDTM,
} from 'user-management/models/dtm';
import {
  EOrganizationIndustry, EOrganizationType, OrganizationIndustryNames, OrganizationTypeNames,
} from 'user-management/constants';

import {
  EditProfileOrganizationCoverageAreaContainer,
} from 'user-management/view/pages/Account/components/AccountDrawer/components/EditProfileOrganization/components/EditProfileOrganizationCoverageArea';
import { hasReadAccess } from 'app-wrapper/utils';
import {
  EditProfileOrganizationGeneralInformationName,
  EditProfileOrganizationGeneralInformationWrapper,
  EditProfileOrganizationGeneralInformationHeader,
  EditProfileOrganizationGeneralInformationSpaceColumn,
  EditProfileOrganizationGeneralInformationSpaceRow,
  EditProfileOrganizationGeneralInformationTitle, EditProfileOrganizationGeneralInformationSpaceColumnRows,
} from './EditProfileOrganizationGeneralInformation.styled';

interface IEditProfileOrganizationAddressGeneralInformationComponentProps {
  organization?: OrganizationDTM
  organizationIndustry?: EOrganizationIndustry[]
  organizationDisabledFieldsErrors?: boolean
  organizationEditProfileIdentifiersIndustryErrors?: boolean
  organizationFieldsErrors?: OrganizationFieldsErrorsDTM
  onChangeUpdateName: (name: string) => void
  onBlurUpdateName: () => void
  onChangeUpdateType: (type: EOrganizationType) => void
  onChangeUpdateIndustry: (value: string) => void
  formPermissions?: EditOrganizationProfileDrawerDtm | EditPartnerOrganizationProfileDrawerDtm
}

export const EditProfileOrganizationGeneralInformationComponent: FC<IEditProfileOrganizationAddressGeneralInformationComponentProps> = ({
  organization,
  organizationIndustry,
  organizationDisabledFieldsErrors,
  organizationEditProfileIdentifiersIndustryErrors,
  organizationFieldsErrors,
  onChangeUpdateName,
  onBlurUpdateName,
  onChangeUpdateType,
  onChangeUpdateIndustry,
  formPermissions,
}) => {
  const { t } = useTranslation();

  const onChangeName = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    onChangeUpdateName(event.target.value);
  }, []);

  const onBlurUpdateNameHandler = useCallback(() => {
    onBlurUpdateName();
  }, []);

  const onChangeTypeHandler = useCallback((value: EOrganizationType) => {
    onChangeUpdateType(value);
  }, []);

  const onBlurTypeHandler = useCallback(() => {
    // onBlurUpdatePrimaryEmail();
  }, []);

  const getHasErrorField = useCallback(
    (filed?: OrganizationFieldsErrorsFieldDTM) => !!(filed?.isVisited && filed?.message),
    [],
  );

  return (
    <EditProfileOrganizationGeneralInformationWrapper>
      <EditProfileOrganizationGeneralInformationSpaceRow>
        <EditProfileOrganizationGeneralInformationSpaceColumn>
          <EditProfileOrganizationGeneralInformationSpaceColumnRows>
            <EditProfileOrganizationGeneralInformationHeader>
              {t('General Information')}
            </EditProfileOrganizationGeneralInformationHeader>
            <EditProfileOrganizationGeneralInformationName>
              <EditProfileOrganizationGeneralInformationTitle>
                {`${t('OrganizationsName')} *`}
              </EditProfileOrganizationGeneralInformationTitle>
              <InputWithError
                data-class="InputWithErrorOrganizationName"
                value={organization?.name}
                hasError={getHasErrorField(organizationFieldsErrors?.name)}
                onChange={onChangeName}
                onBlur={onBlurUpdateNameHandler}
              />
            </EditProfileOrganizationGeneralInformationName>
          </EditProfileOrganizationGeneralInformationSpaceColumnRows>
        </EditProfileOrganizationGeneralInformationSpaceColumn>
      </EditProfileOrganizationGeneralInformationSpaceRow>
      <EditProfileOrganizationGeneralInformationSpaceRow>
        <EditProfileOrganizationGeneralInformationSpaceColumn widthProp="50%">
          <EditProfileOrganizationGeneralInformationTitle>
            {`${t(' Type')} *`}
          </EditProfileOrganizationGeneralInformationTitle>
          <SelectWithError
            value={organization?.type}
            showSearch
            disabled={organizationDisabledFieldsErrors}
            onChange={onChangeTypeHandler}
            onBlur={onBlurTypeHandler}
            optionFilterProp="children"
            hasError={getHasErrorField(organizationFieldsErrors?.type)}
          >
            <Option value={EOrganizationType.BCO}>{OrganizationTypeNames[EOrganizationType.BCO]}</Option>
            <Option value={EOrganizationType.NVOCC}>{OrganizationTypeNames[EOrganizationType.NVOCC]}</Option>
            <Option value={EOrganizationType.FF}>{OrganizationTypeNames[EOrganizationType.FF]}</Option>
          </SelectWithError>
        </EditProfileOrganizationGeneralInformationSpaceColumn>
        <EditProfileOrganizationGeneralInformationSpaceColumn widthProp="50%">
          <EditProfileOrganizationGeneralInformationTitle>
            {`${t(' Industry')}`}
          </EditProfileOrganizationGeneralInformationTitle>
          <SelectWithError
            value={organization?.industry || null}
            showSearch
            onChange={onChangeUpdateIndustry}
            onBlur={() => {}}
            optionFilterProp="children"
            disabled={!organization?.type || organizationEditProfileIdentifiersIndustryErrors}
            hasError={getHasErrorField(organizationFieldsErrors?.industry)}
          >
            {organizationIndustry?.map((item) => (
              <Option
                key={`EditProfileOrganizationGeneralInformationSelect_${item}`}
                value={item}
              >
                {OrganizationIndustryNames[item]}
              </Option>
            ))}
          </SelectWithError>
        </EditProfileOrganizationGeneralInformationSpaceColumn>
      </EditProfileOrganizationGeneralInformationSpaceRow>
      {
          hasReadAccess(formPermissions?.coverageAreas)
            ? (
              <EditProfileOrganizationGeneralInformationSpaceRow>
                <EditProfileOrganizationGeneralInformationSpaceColumn widthProp="100%">
                  <EditProfileOrganizationGeneralInformationSpaceColumnRows>
                    <EditProfileOrganizationGeneralInformationHeader>
                      {t('Coverage Area')}
                    </EditProfileOrganizationGeneralInformationHeader>

                    <EditProfileOrganizationGeneralInformationName>
                      <EditProfileOrganizationGeneralInformationTitle>
                        {`${t('Countries')} *`}
                      </EditProfileOrganizationGeneralInformationTitle>
                      <EditProfileOrganizationCoverageAreaContainer permission={formPermissions?.coverageAreas} />
                    </EditProfileOrganizationGeneralInformationName>

                  </EditProfileOrganizationGeneralInformationSpaceColumnRows>
                </EditProfileOrganizationGeneralInformationSpaceColumn>
              </EditProfileOrganizationGeneralInformationSpaceRow>
            )
            : null
        }
    </EditProfileOrganizationGeneralInformationWrapper>
  );
};
