import React from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';

import { DocumentsFormComponent } from './DocumentsForm.component';

const DocumentsFormContainer = () => {
  const isOwnContainer = useSelector(R.selectors.shipmentContainers.getIsOwnContainer);
  const hasHazmat = useSelector(R.selectors.shipmentContainers.getHasHazmat);

  return (
    <DocumentsFormComponent
      isOwnContainer={isOwnContainer}
      hasHazmat={hasHazmat}
    />
  );
};

export { DocumentsFormContainer as DocumentsForm };
