import styled from 'styled-components';

import { divFlex } from 'app-wrapper/view/themes/mixin';

export const SCDescriptionsContainer = styled.div.attrs((props: {
  isVerticalCenter: boolean,
}) => ({
  isVerticalCenter: props.isVerticalCenter,
  'data-class': 'SCDescriptionsContainer',
}))`
  ${divFlex}
  flex-direction: column;
  ${({ isVerticalCenter }) => isVerticalCenter && 'justify-content: center;'};

  padding: 8px 0 10px 0;
  width: 100%;
  height:  100%;
`;

export const SCDescriptionsContainerSpanClip = styled.span.attrs({
  'data-class': 'SCDescriptionsContainerSpanClip',
})`
  display: inline;
  max-width: 80%;

  text-overflow: ellipsis;
  overflow: clip;
  white-space: nowrap;
`;

export const SCDescriptionsContainerTitle = styled.div.attrs({
  'data-class': 'SCDescriptionsContainerTitle',
})`
  ${divFlex}
  height: 22px;
  align-items: center;

  color: ${({ theme }) => theme.themesColors.secondaryDot45};
  font-size: 12px;
  font-weight: 400;

  span {
    line-height: 18px;
  }
`;

export const SCDescriptionsContainerContent = styled.div.attrs({
  'data-class': 'SCDescriptionsContainerContent',
})`
  ${divFlex}
  height: 16px;
  align-items: center;

  color: ${({ theme }) => theme.themesColors.neutralBranding10};
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;

  span {
    line-height: 16px;
  }
`;

export const SCDescriptionsContainerFooter = styled.div.attrs({
  'data-class': 'SCDescriptionsContainerFooter',
})`
  ${divFlex}
  height: 22px;
  align-items: center;

  color: ${({ theme }) => theme.themesColors.secondaryDot45};
  font-size: 12px;
  font-weight: 400;

  span {
    line-height: 20px;
  }
`;
