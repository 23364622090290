import PlusOutlined from '@ant-design/icons/PlusOutlined';
import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { UserManagementPermissionsDtm } from 'user-management/models/dtm';

import { SubPageHeaderContainer, SubPageHeaderTitle } from 'app-wrapper/view/guideline';
import { Button } from 'app-wrapper/view/components';

interface IUserManagementHeaderComponentProps {
  userManagementAvailability?: UserManagementPermissionsDtm
  onUserInvite: () => void
}

export const UserManagementHeaderComponent: FC<IUserManagementHeaderComponentProps> = ({ userManagementAvailability, onUserInvite }) => {
  const { t } = useTranslation();

  const onUserInviteHandler = useCallback(() => {
    onUserInvite();
  }, []);

  return (
    <SubPageHeaderContainer>
      <SubPageHeaderTitle>
        {t('User Management')}
      </SubPageHeaderTitle>
      <Button
        disabled={!userManagementAvailability?.userInviteButton}
        icon={<PlusOutlined />}
        onClick={onUserInviteHandler}
      >
        {t('Invite User')}
      </Button>
    </SubPageHeaderContainer>
  );
};
