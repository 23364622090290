import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';
import { UC } from 'shipment-operations/controllers';

import { ShipmentDocumentsHazmatComponent } from './ShipmentDocumentsHazmat.component';

const ShipmentDocumentsHazmatContainer: FC = () => {
  const containers = useSelector(R.selectors.shipmentDocumentsHazmat.getContainers);
  const isLoading = useSelector(R.selectors.shipmentDocumentsHazmat.getIsLoading);

  return (
    <ShipmentDocumentsHazmatComponent
      fetchContainers={UC.shipmentDocuments.getShipmentContainers}
      containers={containers}
      isLoading={isLoading}
    />
  );
};

export { ShipmentDocumentsHazmatContainer as ShipmentDocumentsHazmat };
