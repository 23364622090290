import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'antd/es/skeleton';

import {
  Section,
  Title,
} from './Carrier.styled';
import { CarrierReferenceNumber } from './components';

interface CarrierComponentProps {
  isPending: boolean;
}

export const CarrierComponent: FC<CarrierComponentProps> = ({ isPending }) => {
  const { t } = useTranslation();

  return isPending ? <Skeleton /> : (
    <Section>
      <Title>
        {t('Carrier')}
      </Title>

      <CarrierReferenceNumber />
    </Section>
  );
};
