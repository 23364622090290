import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { UC } from 'user-management/controllers';
import { R } from 'user-management/repository';

import { NewOrganizationWizardWrapperAsideComponent } from './NewOrganizationWizardWrapperAside.component';

const NewOrganizationWizardWrapperAsideContainer: FC = () => {
  const hasRequiredDocuments = useSelector(R.selectors.newOrganizationDocuments.getHasRequiredDocuments);
  const isFirstStepDisabled = useSelector(R.selectors.newOrganizationWizardSteps.isFirstStepDisabled);
  const isSecondStepDisabled = useSelector(R.selectors.newOrganizationWizardSteps.isSecondStepDisabled);
  const isThirdStepDisabled = useSelector(R.selectors.newOrganizationWizardSteps.isThirdStepDisabled);
  const status = useSelector(R.selectors.newOrganizationWizardSteps.getStatus);
  const percent = useSelector(R.selectors.newOrganizationWizardSteps.getPercent);
  const activeStepNumber = useSelector(R.selectors.newOrganizationWizardSteps.getActiveStepNumber);

  return (
    <NewOrganizationWizardWrapperAsideComponent
      status={status}
      percent={percent}
      isFirsStepDisabled={isFirstStepDisabled}
      isSecondStepDisabled={isSecondStepDisabled}
      isThirdStepDisabled={isThirdStepDisabled || !hasRequiredDocuments}
      activeStepNumber={activeStepNumber}
      onStepClick={UC.newOrganizationWizard.stepClick}
      onStepChange={UC.newOrganizationWizard.onStepChange}
    />
  );
};

export { NewOrganizationWizardWrapperAsideContainer as NewOrganizationWizardWrapperAside };
