import styled from 'styled-components';
import Typography from 'antd/es/typography';
import Spin from 'antd/es/spin';

import { divFlex } from 'app-wrapper/view/themes/mixin';

export const Form = styled.div.attrs({
  'data-class': 'BOLEditComments',
})`
  height: 100%;
  padding: 24px;
`;

export const FormItem = styled.div`
  ${divFlex}
  flex-direction: column;
  margin-bottom: 10px;
`;

export const FormItemLabel = styled.div`
  ${divFlex}
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 4px;
`;

export const Label = styled(Typography)`
  font-size: 12px;
  line-height: 20px;
`;

export const SpinnerWrap = styled.div`
  ${divFlex}
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
`;

export const Spinner = styled(Spin)`
  margin: auto;
`;
