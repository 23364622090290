import React from 'react';

import { IPropsIcon } from 'app-wrapper/interfaces/icon';

export const Vector5Svg = (props: IPropsIcon) => {
  const { width, height, fill } = props;
  return (
    <svg
      className="Vector5Svg"
      width={width || '10'}
      height={height || '16'}
      viewBox="0 0 10 16"
      fill={fill || 'none'}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M1 0V15H10" stroke="#D1D7E2" />
    </svg>
  );
};
