import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Item,
  Text,
  CheckIcon,
  WarningIcon,
  Tag,
} from './DataItem.styled';

interface IDataItemProps {
  isRequiredDataFilled?: boolean;
  name: string;
  isThereMissMatches?: boolean;
}

export const DataItem: FC<IDataItemProps> = ({
  name,
  isRequiredDataFilled,
  isThereMissMatches,
}) => {
  const { t } = useTranslation();

  return (
    <Item>
      {isRequiredDataFilled ? <CheckIcon /> : <WarningIcon />}

      <Text>
        {name}
      </Text>

      {isThereMissMatches ? (
        <Tag>
          {t('Amended')}
        </Tag>
      ) : null}
    </Item>
  );
};
