import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { OrganizationMemberDTM } from 'user-management/models/dtm';
import { TabPane } from 'app-wrapper/view/components';
import {
  SubPageHeader,
  SubPageHeaderContainer,
  SubPageHeaderTitle,
  Wrapper,
  SubPageTabs,
} from 'app-wrapper/view/guideline';
import {
  UserContacts,
  UserData,
} from 'user-management/view/pages/UserProfile/components';
import { TeamsAndDepartmentsComponent } from 'user-management/view/pages/UserManagementProfile/components/TeamsAndDepartments/TeamsAndDepartments.component';
import {
  UserProfileContent,
  UserProfileContentHeader,
  UserProfileHorizontalSeparator,
  UserProfileRightBox,
  UserProfileLeftBox,
  UserProfileVerticalSeparator,
} from 'user-management/view/pages/UserProfile/components/UserProfileScaffold/UserProfileScaffold.styled';
import { ContentWrapTitle } from 'user-management/view/pages/UserManagementProfile/UserManagementProfile.styled';
import { MemberTeamsTableComponent } from 'user-management/view/pages/UserManagementProfile/components/MemberTeamsTable/MemberTeamsTable.component';

import { Content, DepartmentsWrap } from './PartnerUserProfile.styled';

interface IPartnerUserProfileComponentProps {
  user?: OrganizationMemberDTM;
}

export const PartnerUserProfileComponent: FC<IPartnerUserProfileComponentProps> = ({ user }) => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <SubPageHeader>
        <SubPageHeaderContainer>
          <SubPageHeaderTitle>
            {t('Profile')}
          </SubPageHeaderTitle>
        </SubPageHeaderContainer>
      </SubPageHeader>

      <SubPageTabs
        defaultActiveKey="OVERVIEW"
      >
        <TabPane
          tab={t('Overview')}
          key="OVERVIEW"
        >
          <Content>
            <UserProfileContent>
              <UserProfileContentHeader>
                <UserData />
              </UserProfileContentHeader>
              <UserProfileHorizontalSeparator />
              <UserProfileLeftBox>
                <UserContacts />
              </UserProfileLeftBox>
              <UserProfileVerticalSeparator />
              <UserProfileRightBox>
                <DepartmentsWrap>
                  <ContentWrapTitle>
                    {t('Departments')}
                  </ContentWrapTitle>

                  <TeamsAndDepartmentsComponent departments={user?.departments || []} />
                </DepartmentsWrap>
              </UserProfileRightBox>
            </UserProfileContent>
          </Content>
        </TabPane>

        <TabPane
          tab={t('Teams')}
          key="TEAMS"
        >
          <MemberTeamsTableComponent teams={user?.teams || []} />
        </TabPane>
      </SubPageTabs>
    </Wrapper>
  );
};
