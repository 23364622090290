import styled from 'styled-components';
import Button from 'antd/es/button';

import { divFlex } from 'app-wrapper/view/themes/mixin';

export const ButtonMinusWrapper = styled(Button).attrs((props) => ({
  size: props.size,
  'data-class': 'ButtonMinusWrapper',
}))`
  ${divFlex}
  justify-content: center;
  align-items: center;
  padding: 0;
  height: 32px;
  width: 32px;
  min-width: 32px;

  ${({ size }) => `${size === 'large' && 'height: 40px; width: 40px; min-width: 40px;'}`};
`;

export const ButtonMinusName = styled.div.attrs({
  'data-class': 'ButtonMinusName',
})`
  ${divFlex}
  font-size: 12px;
  text-transform: capitalize;
  font-weight: 400;
  margin-left: 10px;
  color: ${({ theme }) => theme?.themesColors?.neutralBranding10};
  letter-spacing: normal;
`;
