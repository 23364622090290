import React, {
  FC, useCallback, useEffect,
} from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { Button } from 'app-wrapper/view/components';

import {
  FirstNameContainer,
  LastNameContainer,
  PhoneInput,
  JobTitleInput,
} from './components';
import {
  ChangePasswordContainer,
  ChangePasswordFooter,
  ChangePasswordWrapper,
} from './UserEditProfile.styled';

interface ITransportationLocationDataComponentProps {
  isLoading: boolean
  onFormOpen: () => void
  onOk: () => void
  onCancel: () => void
}

export const UserEditProfileComponent: FC<ITransportationLocationDataComponentProps> = observer(({
  isLoading,
  onFormOpen,
  onOk,
  onCancel,
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    onFormOpen();
  }, []);

  const onOkHandler = useCallback(() => {
    onOk();
  }, []);

  return (
    <ChangePasswordWrapper>
      <ChangePasswordContainer>
        <FirstNameContainer />

        <LastNameContainer />

        <PhoneInput />

        <JobTitleInput />
      </ChangePasswordContainer>

      <ChangePasswordFooter>
        <Button type="default" onClick={onCancel}>{t('Cancel')}</Button>
        <Button
          data-class="SubmitUserProfileEdit"
          loading={isLoading}
          onClick={onOkHandler}
        >
          {t('Save')}
        </Button>
      </ChangePasswordFooter>
    </ChangePasswordWrapper>
  );
});
