import { RootState } from 'app-wrapper/store';
import { v4 as uuidv4 } from 'uuid';

import { FAKExceptionsDTM } from 'monetary/models/dtm/Quotas';

export const addKey = (data: FAKExceptionsDTM[]) => data.map((item) => {
  item.key = uuidv4();
  item.title = item.description;
  if (item.children && Array.isArray(item.children)) {
    item.children = addKey(item.children);
  }
  return item;
});

const getData = (state: RootState) => state.fakException;

export const fakExceptionSelectors = {
  getData,
};
