import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Alert from 'antd/es/alert';

import { EStatusType } from 'app-wrapper/view/guideline';
import { AdditionalServiceDTM } from 'shipment-operations/models/dtm';

import provideImageSrc from './provide-image.png';
import completeImageSrc from './complete-image.png';
import {
  Details,
  Card,
  CardNumber,
  AlertsWrap,
  CardNumberWrap,
  Divider,
  Image,
  Information,
  InformationTitle,
  InformationText,
} from './WorkflowDetails.styled';

interface IWorkflowDetailsComponentProps {
  isManager: boolean;
  isProvider: boolean;
  isInEditMode: boolean;
  service?: AdditionalServiceDTM;
}

export const WorkflowDetailsComponent: FC<IWorkflowDetailsComponentProps> = ({
  isManager,
  isProvider,
  isInEditMode,
  service,
}) => {
  const { t } = useTranslation();

  if (!service) {
    return null;
  }

  if (!isInEditMode && service) {
    if (isManager) {
      return null;
    }

    const { status } = service.getServiceStatusInfo();

    if (status === EStatusType.COMPLETED) {
      return (
        <AlertsWrap>
          <Alert
            message="Service is completed"
            description="Our team has successfully completed the service. All related information can be found below."
            type="success"
            showIcon
          />
        </AlertsWrap>
      );
    }

    if (status === EStatusType.IN_PROGRESS) {
      return (
        <AlertsWrap>
          <Alert
            message="Work is in progress"
            description="Our team has received your information and is currently working on service execution. You will be informed once the service is completed."
            type="warning"
            showIcon
          />
        </AlertsWrap>
      );
    }

    return null;
  }

  if (isProvider && !isManager) {
    return (
      <Details>
        <Card>
          <CardNumberWrap>
            <CardNumber>
              01
            </CardNumber>
          </CardNumberWrap>

          <Divider
            dashed
            type="vertical"
          />

          <Image src={provideImageSrc} />

          <Information>
            <InformationTitle>
              {t('Provide required information')}
            </InformationTitle>

            <InformationText>
              {t('Please submit all the necessary information and documents specified below. This ensures that our team has everything needed to proceed with your service request.')}
            </InformationText>
          </Information>
        </Card>

        <Card>
          <CardNumberWrap>
            <CardNumber>
              02
            </CardNumber>
          </CardNumberWrap>

          <Divider
            dashed
            type="vertical"
          />

          <Image src={completeImageSrc} />

          <Information>
            <InformationTitle>
              {t('Skypace will handle and complete the service')}
            </InformationTitle>

            <InformationText>
              {t('After our team reviews and verifies your information, they will proceed with executing the service. Upon completion, all related documents and information will be available for viewing and downloading')}
            </InformationText>
          </Information>
        </Card>
      </Details>
    );
  }

  if (isManager) {
    return (
      <Details>
        <Card>
          <CardNumberWrap>
            <CardNumber>
              01
            </CardNumber>
          </CardNumberWrap>

          <Divider
            dashed
            type="vertical"
          />

          <Image src={provideImageSrc} />

          <Information>
            <InformationTitle>
              {t('Review submitted information')}
            </InformationTitle>

            <InformationText>
              {t('Please, review your information for completeness and accuracy before proceeding with service execution')}
            </InformationText>
          </Information>
        </Card>

        <Card>
          <CardNumberWrap>
            <CardNumber>
              02
            </CardNumber>
          </CardNumberWrap>

          <Divider
            dashed
            type="vertical"
          />

          <Image src={completeImageSrc} />

          <Information>
            <InformationTitle>
              {t('Execute and complete the service')}
            </InformationTitle>

            <InformationText>
              {t('Please proceed with the activities required for service execution. Once done, add the information specified below and finalize the service.')}
            </InformationText>
          </Information>
        </Card>
      </Details>
    );
  }

  return null;
};
