import styled from 'styled-components';

export const ShipmentContainersFormCardsListHeaderContainer = styled.div.attrs({
  'data-class': 'ShipmentContainersFormCardsListHeaderContainer',
})`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: center;
  margin: 8px 0 8px 0;
  height: 20px;
  padding-right: 16px;
  gap: 4px;
  
  > * {
    width: 92px;
    font-size: 12px;
    font-weight: 400;
  }
`;
