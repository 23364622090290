import styled from 'styled-components';
import Typography from 'antd/es/typography';

export const Wrap = styled.div`
  padding: 0 24px 24px 24px;
  display: flex;
  flex-direction: column;
  page-break-inside: avoid;
`;

export const Title = styled(Typography)`
  margin-bottom: 7.2px;
  font-size: 12px;
  line-height: 19.2px;
  font-weight: 600;
`;

export const ItemsList = styled.div`
  margin-top: 7.2px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  border-radius: 4px;
  border: 1px solid rgba(209, 215, 226, 1);
  padding: 0 8px 8px 8px;
`;

export const Item = styled.div`
  margin-top: 8px;
  width: 25%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const ItemTitle = styled(Typography)`
  font-size: 9.6px;
  line-height: 14.4px;
  font-weight: 700;
`;

export const ItemSubTitle = styled(Typography)`
  font-size: 9.6px;
  line-height: 12px;
`;
