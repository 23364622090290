import styled from 'styled-components';

export const Wrapper = styled.div<{justifyContent?: string}>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: ${({ justifyContent = 'flex-start' }) => justifyContent};
  width: 100%;
  height: 100%;
  position: relative;
`;

export const Sign = styled.span<{fontSize?: number}>`
  position: absolute;
  top: 0;
  right: 0;
  background: #F4F5F8;
  border: 1px solid #EAEEF8;
  padding: 2px;
  border-radius: 4px;
  font-size: ${({ fontSize }) => fontSize || '0.45'}rem;
  font-weight: 600;
`;
