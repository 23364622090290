import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { ShippingInstructionsRoutes } from 'shipment-operations/constants';

import { TypeSwitch } from 'app-wrapper/view/components';

import {
  Navigation,
  SwitchWrap,
  TabPane,
  Tabs,
} from './NavigationBar.styled';

interface NavigationBarComponentProps {
  isFreightedView: boolean;
  setIsFreightedView: (ifFreighted: boolean) => void;
  isFreightSwitchHidden: boolean;
  goToMBL: () => void;
  goToHBL: () => void;
  isHBLPage: boolean;
}

export const NavigationBarComponent: FC<NavigationBarComponentProps> = ({
  isFreightedView,
  setIsFreightedView,
  isFreightSwitchHidden,
  goToHBL,
  goToMBL,
  isHBLPage,
}) => {
  const { t } = useTranslation();

  const handleChange = useCallback((key: string) => {
    if (key === ShippingInstructionsRoutes.HBL_DRAFT) {
      goToHBL();

      return;
    }

    goToMBL();
  }, [goToHBL, goToMBL]);

  return (
    <Navigation>
      <Tabs onChange={handleChange}>
        <Tabs.TabPane
          active={!isHBLPage}
          key={ShippingInstructionsRoutes.MBL_SI}
          tab={(
            <TabPane>
              {t('Master Bill of Lading')}
            </TabPane>
          )}
        />

        <Tabs.TabPane
          active={isHBLPage}
          key={ShippingInstructionsRoutes.HBL_DRAFT}
          tab={(
            <TabPane>
              {t('House Bill of Lading')}
            </TabPane>
          )}
        />
      </Tabs>

      {isHBLPage && !isFreightSwitchHidden ? (
        <SwitchWrap>
          <TypeSwitch
            value={isFreightedView}
            onChange={setIsFreightedView}
            leftText={t('View Freighted Version')}
            checkedChildren={t('On')}
            unCheckedChildren={t('Off')}
            disabled={false}
          />
        </SwitchWrap>
      ) : null}
    </Navigation>
  );
};
