import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Select, Option } from 'app-wrapper/view/components';

import { ExportClearanceType, ExportClearanceTypeNames } from 'shipment-operations/constants';

interface IExportClearanceTypeSelectComponentProps {
  exportClearanceType?: ExportClearanceType,
  error?: boolean,
  setExportClearanceType: (exportClearanceType: ExportClearanceType) => void
  touchField: (field: string) => void
  disabled: boolean;
}

export const ExportClearanceTypeSelectComponent: FC<IExportClearanceTypeSelectComponentProps> = ({
  exportClearanceType,
  error,
  setExportClearanceType,
  touchField,
  disabled,
}) => {
  const { t } = useTranslation();

  const handleBlur = useCallback(() => {
    touchField('exportClearanceType');
  }, []);

  return (
    <Select
      placeholder={t('None')}
      value={exportClearanceType}
      error={error}
      allowClear={!!exportClearanceType}
      onChange={setExportClearanceType}
      onBlur={handleBlur}
      disabled={disabled || true}
    >
      {Object.keys(ExportClearanceTypeNames).map((type) => (
        <Option key={type} value={type}>
          {t(ExportClearanceTypeNames[type as ExportClearanceType])}
        </Option>
      ))}
    </Select>
  );
};
