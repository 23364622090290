import React, { FC } from 'react';

import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { R } from 'shipment-operations/repository';
import { UC } from 'shipment-operations/controllers';
import { SHIPMENT_CONTAINER } from 'app-wrapper/models/routes';

import { ShipmentContainersFormFooterComponent } from './ShipmentContainersFormFooter.component';

const ShipmentContainersFormFooterContainer: FC = () => {
  const { shipmentId, containerId } = useParams<SHIPMENT_CONTAINER>();
  const isLoading = useSelector(R.selectors.shipmentContainers.getUpdateLoadingState);
  const selectedContainer = useSelector(R.selectors.shipmentContainers.getSelectedContainer);
  const isContainerDataUpdated = useSelector(R.selectors.shipmentContainers.isContainerChanged);
  const isContainerDataHaveErrors = useSelector(R.selectors.shipmentContainers.isContainerDataHaveErrors);
  const permissions = useSelector(R.selectors.shipment.getContainersPermissions);

  return (
    <ShipmentContainersFormFooterComponent
      isLoading={isLoading}
      selectedContainer={selectedContainer}
      isContainerDataUpdated={isContainerDataUpdated}
      isContainerDataHaveErrors={isContainerDataHaveErrors}
      discardChanges={UC.shipmentContainers.discardChangesForContainer}
      updateParties={UC.shipmentContainers.updateContainer}
      shipmentId={shipmentId}
      containerId={containerId}
      containersAvailability={permissions.containersAvailability}
    />
  );
};

export { ShipmentContainersFormFooterContainer as ShipmentContainersFormFooter };
