import React, { FC } from 'react';
import { SwitchChangeEventHandler } from 'antd/es/switch';

import { Switch, SwitchWrapper, SwitchWrapperDisabled } from './BillOfLaddingFreightedSwitch.styled';

interface BillOfLaddingFreightedSwitchProps {
  checked: boolean;
  onChange: SwitchChangeEventHandler;
  disabled?: boolean;
}

export const BillOfLadingFreightedSwitch: FC<BillOfLaddingFreightedSwitchProps> = ({ checked, onChange, disabled }) => (disabled ? (
  <SwitchWrapperDisabled>
    <Switch
      disabled={disabled}
      checked={checked}
      onChange={onChange}
    />
  </SwitchWrapperDisabled>
) : (
  <SwitchWrapper>
    <Switch
      disabled={disabled}
      checked={checked}
      onChange={onChange}
    />
  </SwitchWrapper>
));
