import React from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';

import { PDFGeneralInfoComponent } from './PDFGeneralInfo.component';

const PDFGeneralInfoContainer = () => {
  const generalInfo = useSelector(R.selectors.bookingConfirmationPDF.getGeneralInfo);
  const totalAmount = useSelector(R.selectors.bookingConfirmationPDF.getGeneralInfoTotalAmount);
  const shipment = useSelector(R.selectors.shipment.getShipment);

  return (
    <PDFGeneralInfoComponent
      shipmentName={shipment?.shipmentName || ''}
      generalInfo={generalInfo}
      total={totalAmount}
    />
  );
};

export { PDFGeneralInfoContainer as PDFGeneralInfo };
