import React, { createContext } from 'react';
import Form, { FormInstance } from 'antd/es/form';

interface EditableRowProps {
  index: number
}

const EditableContext = createContext<FormInstance<any> | null>(null);

const EditableRow: React.FC<EditableRowProps> = ({ ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

export { EditableRow, EditableContext };
