import React, { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';

import { TemperatureFieldComponent } from './TemperatureField.component';

const TemperatureFieldContainer: FC = () => {
  const temperatureControl = useSelector(R.selectors.manualBookingWizard.getTemperatureControl);

  const temperature = useMemo(() => (temperatureControl ? temperatureControl.temperature : undefined), []);

  return (
    <TemperatureFieldComponent temperature={temperature} />
  );
};

export {
  TemperatureFieldContainer as TemperatureField,
};
