import { UsersService } from 'user-management/repository/services/Users';
import { ContactsService } from './Contacts';
import { OrganizationService } from './Organization';
import { ModuleFunctionalityPermissionsService } from './ModuleFunctionalityPermissions';
import { OrganizationDocumentsService } from './OrganizationDocuments';
import { AccountDepartmentService } from './AccountDepartment';
import { OrganizationTeamsService } from './OrganizationTeams';
import { MarkupPolicyService } from './MarkupPolicy';

export const services = {
  contacts: new ContactsService(),
  organization: new OrganizationService(),
  moduleFunctionalityPermissions: new ModuleFunctionalityPermissionsService(),
  organizationDocuments: new OrganizationDocumentsService(),
  users: new UsersService(),
  accountDepartment: new AccountDepartmentService(),
  teams: new OrganizationTeamsService(),
  markupPolicy: new MarkupPolicyService(),
};
