import React, { FC } from 'react';

import { CargoDTM } from 'shipment-operations/models/dtm';
import { ShipmentDetailsList, ShipmentDetailsCardWithThreeSections } from 'shipment-operations/view/components';

import { CargoCardBottom, CargoCardMiddle, CargoCardTop } from './components';

interface ICargoListComponentProps {
  cargos: CargoDTM[]
  cargoDraftStatuses: boolean[]
  selectedCargo: number
  selectCargo: (index: number) => void
}

export const CargoListComponent: FC<ICargoListComponentProps> = ({
  cargos,
  cargoDraftStatuses,
  selectedCargo,
  selectCargo,
}) => {
  const createSelectCargoHandler = (i: number) => () => {
    selectCargo(i);
  };

  return (
    <ShipmentDetailsList>
      {cargos.map((cargo, i) => {
        const isSelected = selectedCargo === i;
        const isValidationPassed = !cargo.errors.hasErrors();
        const isHsCodeValidated = cargo.hsCodeValidationStatus === 'REQUEST_SENT_AND_VALID';
        const handleClick = createSelectCargoHandler(i);

        return (
          <ShipmentDetailsCardWithThreeSections
            key={`card__${cargo.renderId}`}
            isSelected={isSelected}
            onClick={handleClick}
          >
            <CargoCardTop
              name={cargo.name}
              description={cargo.description}
              code={cargo.code}
              isInDraft={cargoDraftStatuses[i]}
              isError={!(isValidationPassed && isHsCodeValidated)}
            />
            <CargoCardMiddle references={cargo.references} />
            <CargoCardBottom
              packageType={cargo.packageType}
              packagesNumber={cargo.packagesNumber}
              weight={cargo.weight}
              isHazmat={cargo.isHazmat}
            />
          </ShipmentDetailsCardWithThreeSections>
        );
      })}
    </ShipmentDetailsList>
  );
};
