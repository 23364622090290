import {
  IsDefined,
  IsEnum,
  IsNumber,
  IsOptional,
  IsString,
} from 'class-validator';
import { BaseDTM } from 'proto/BaseDTM';

import {
  ContractOwner,
  EShippingPartyTypes,
  IncotermsType,
  PaymentsType,
  TradeType,
} from 'shipment-operations/constants';
import { ShippingPartyOverviewDTM } from 'shipment-operations/models/dtm';

interface IPayeeCompaniesDTM {
  origin: EShippingPartyTypes;
  freight: EShippingPartyTypes;
  destination: EShippingPartyTypes;
}

export class PayeeCompaniesDTM extends BaseDTM<IPayeeCompaniesDTM> {
  @IsEnum(EShippingPartyTypes)
  origin: EShippingPartyTypes;

  @IsEnum(EShippingPartyTypes)
  freight: EShippingPartyTypes;

  @IsEnum(EShippingPartyTypes)
  destination: EShippingPartyTypes;
}

interface IPaymentStateDTM {
  origin: string;
  freight: string;
  destination: string;
}

export class PaymentStateDTM extends BaseDTM<IPaymentStateDTM> {
  @IsString()
  origin: PaymentsType;

  @IsString()
  freight: PaymentsType;

  @IsString()
  destination: PaymentsType;
}

const IMPORT_INCOTERMS = [
  IncotermsType.EXW,
  IncotermsType.FCA,
  IncotermsType.FAS,
  IncotermsType.FOB,
];
const EXPORT_INCOTERMS = [
  IncotermsType.DPU,
  IncotermsType.DAP,
  IncotermsType.CFR,
  IncotermsType.CIF,
];

const SI_EXPORT_NONSELF_ORIGIN = {
  paymentState: {
    [IncotermsType.DAP]: PaymentStateDTM.fromPlain({
      origin: PaymentsType.PREPAID,
      freight: PaymentsType.PREPAID,
      destination: PaymentsType.PREPAID,
    }),
    [IncotermsType.DPU]: PaymentStateDTM.fromPlain({
      origin: PaymentsType.PREPAID,
      freight: PaymentsType.PREPAID,
      destination: PaymentsType.PREPAID,
    }),
    [IncotermsType.CFR]: PaymentStateDTM.fromPlain({
      origin: PaymentsType.PREPAID,
      freight: PaymentsType.PREPAID,
      destination: PaymentsType.COLLECT,
    }),
    [IncotermsType.CIF]: PaymentStateDTM.fromPlain({
      origin: PaymentsType.PREPAID,
      freight: PaymentsType.PREPAID,
      destination: PaymentsType.COLLECT,
    }),
  } as Record<IncotermsType, PaymentStateDTM>,
  // Prepaid = Shipper
  // Collect = Consignee
  payeeCompanies: {
    [IncotermsType.DAP]: PayeeCompaniesDTM.fromPlain({
      origin: EShippingPartyTypes.SHIPPER,
      freight: EShippingPartyTypes.SHIPPER,
      destination: EShippingPartyTypes.SHIPPER,
    }),
    [IncotermsType.DPU]: PayeeCompaniesDTM.fromPlain({
      origin: EShippingPartyTypes.SHIPPER,
      freight: EShippingPartyTypes.SHIPPER,
      destination: EShippingPartyTypes.SHIPPER,
    }),
    [IncotermsType.CFR]: PayeeCompaniesDTM.fromPlain({
      origin: EShippingPartyTypes.SHIPPER,
      freight: EShippingPartyTypes.SHIPPER,
      destination: EShippingPartyTypes.CONSIGNEE,
    }),
    [IncotermsType.CIF]: PayeeCompaniesDTM.fromPlain({
      origin: EShippingPartyTypes.SHIPPER,
      freight: EShippingPartyTypes.SHIPPER,
      destination: EShippingPartyTypes.CONSIGNEE,
    }),
  } as Record<IncotermsType, PayeeCompaniesDTM>,
};
const SI_EXPORT_NONSELF_DESTINATION = {
  paymentState: {
    [IncotermsType.DAP]: PaymentStateDTM.fromPlain({
      origin: PaymentsType.PREPAID,
      freight: PaymentsType.PREPAID,
      destination: PaymentsType.PREPAID,
    }),
    [IncotermsType.DPU]: PaymentStateDTM.fromPlain({
      origin: PaymentsType.PREPAID,
      freight: PaymentsType.PREPAID,
      destination: PaymentsType.PREPAID,
    }),
    [IncotermsType.CFR]: PaymentStateDTM.fromPlain({
      origin: PaymentsType.PREPAID,
      freight: PaymentsType.PREPAID,
      destination: PaymentsType.COLLECT,
    }),
    [IncotermsType.CIF]: PaymentStateDTM.fromPlain({
      origin: PaymentsType.PREPAID,
      freight: PaymentsType.PREPAID,
      destination: PaymentsType.COLLECT,
    }),
  } as Record<IncotermsType, PaymentStateDTM>,
  payeeCompanies: {
    [IncotermsType.DAP]: PayeeCompaniesDTM.fromPlain({
      origin: EShippingPartyTypes.SHIPPER,
      freight: EShippingPartyTypes.SHIPPER,
      destination: EShippingPartyTypes.SHIPPER,
    }),
    [IncotermsType.DPU]: PayeeCompaniesDTM.fromPlain({
      origin: EShippingPartyTypes.SHIPPER,
      freight: EShippingPartyTypes.SHIPPER,
      destination: EShippingPartyTypes.SHIPPER,
    }),
    [IncotermsType.CFR]: PayeeCompaniesDTM.fromPlain({
      origin: EShippingPartyTypes.SHIPPER,
      freight: EShippingPartyTypes.SHIPPER,
      destination: EShippingPartyTypes.CONSIGNEE,
    }),
    [IncotermsType.CIF]: PayeeCompaniesDTM.fromPlain({
      origin: EShippingPartyTypes.SHIPPER,
      freight: EShippingPartyTypes.SHIPPER,
      destination: EShippingPartyTypes.CONSIGNEE,
    }),
  } as Record<IncotermsType, PayeeCompaniesDTM>,
};
const SI_IMPORT_NONSELF_ORIGIN = {
  paymentState: {
    [IncotermsType.EXW]: PaymentStateDTM.fromPlain({
      origin: PaymentsType.COLLECT,
      freight: PaymentsType.COLLECT,
      destination: PaymentsType.COLLECT,
    }),
    [IncotermsType.FCA]: PaymentStateDTM.fromPlain({
      origin: PaymentsType.PREPAID,
      freight: PaymentsType.COLLECT,
      destination: PaymentsType.COLLECT,
    }),
    [IncotermsType.FAS]: PaymentStateDTM.fromPlain({
      origin: PaymentsType.PREPAID,
      freight: PaymentsType.COLLECT,
      destination: PaymentsType.COLLECT,
    }),
    [IncotermsType.FOB]: PaymentStateDTM.fromPlain({
      origin: PaymentsType.PREPAID,
      freight: PaymentsType.COLLECT,
      destination: PaymentsType.COLLECT,
    }),
  } as Record<IncotermsType, PaymentStateDTM>,
  payeeCompanies: {
    [IncotermsType.EXW]: PayeeCompaniesDTM.fromPlain({
      origin: EShippingPartyTypes.CONSIGNEE,
      freight: EShippingPartyTypes.CONSIGNEE,
      destination: EShippingPartyTypes.CONSIGNEE,
    }),
    [IncotermsType.FCA]: PayeeCompaniesDTM.fromPlain({
      origin: EShippingPartyTypes.HOUSE_SHIPPER,
      freight: EShippingPartyTypes.CONSIGNEE,
      destination: EShippingPartyTypes.CONSIGNEE,
    }),
    [IncotermsType.FAS]: PayeeCompaniesDTM.fromPlain({
      origin: EShippingPartyTypes.HOUSE_SHIPPER,
      freight: EShippingPartyTypes.CONSIGNEE,
      destination: EShippingPartyTypes.CONSIGNEE,
    }),
    [IncotermsType.FOB]: PayeeCompaniesDTM.fromPlain({
      origin: EShippingPartyTypes.HOUSE_SHIPPER,
      freight: EShippingPartyTypes.CONSIGNEE,
      destination: EShippingPartyTypes.CONSIGNEE,
    }),
  } as Record<IncotermsType, PayeeCompaniesDTM>,
};
const SI_IMPORT_NONSELF_DESTINATION = {
  paymentState: {
    [IncotermsType.EXW]: PaymentStateDTM.fromPlain({
      origin: PaymentsType.COLLECT,
      freight: PaymentsType.COLLECT,
      destination: PaymentsType.COLLECT,
    }),
    [IncotermsType.FCA]: PaymentStateDTM.fromPlain({
      origin: PaymentsType.PREPAID,
      freight: PaymentsType.COLLECT,
      destination: PaymentsType.COLLECT,
    }),
    [IncotermsType.FAS]: PaymentStateDTM.fromPlain({
      origin: PaymentsType.PREPAID,
      freight: PaymentsType.COLLECT,
      destination: PaymentsType.COLLECT,
    }),
    [IncotermsType.FOB]: PaymentStateDTM.fromPlain({
      origin: PaymentsType.PREPAID,
      freight: PaymentsType.COLLECT,
      destination: PaymentsType.COLLECT,
    }),
  } as Record<IncotermsType, PaymentStateDTM>,
  payeeCompanies: {
    [IncotermsType.EXW]: PayeeCompaniesDTM.fromPlain({
      origin: EShippingPartyTypes.CONSIGNEE,
      freight: EShippingPartyTypes.CONSIGNEE,
      destination: EShippingPartyTypes.CONSIGNEE,
    }),
    [IncotermsType.FCA]: PayeeCompaniesDTM.fromPlain({
      origin: EShippingPartyTypes.SHIPPER,
      freight: EShippingPartyTypes.CONSIGNEE,
      destination: EShippingPartyTypes.CONSIGNEE,
    }),
    [IncotermsType.FAS]: PayeeCompaniesDTM.fromPlain({
      origin: EShippingPartyTypes.SHIPPER,
      freight: EShippingPartyTypes.CONSIGNEE,
      destination: EShippingPartyTypes.CONSIGNEE,
    }),
    [IncotermsType.FOB]: PayeeCompaniesDTM.fromPlain({
      origin: EShippingPartyTypes.SHIPPER,
      freight: EShippingPartyTypes.CONSIGNEE,
      destination: EShippingPartyTypes.CONSIGNEE,
    }),
  } as Record<IncotermsType, PayeeCompaniesDTM>,
};

// Self service cases
const SI_EXPORT_SELF_ORIGIN = {
  paymentState: {
    [IncotermsType.DAP]: PaymentStateDTM.fromPlain({
      origin: PaymentsType.PREPAID,
      freight: PaymentsType.PREPAID,
      destination: PaymentsType.PREPAID,
    }),
    [IncotermsType.DPU]: PaymentStateDTM.fromPlain({
      origin: PaymentsType.PREPAID,
      freight: PaymentsType.PREPAID,
      destination: PaymentsType.PREPAID,
    }),
    [IncotermsType.CFR]: PaymentStateDTM.fromPlain({
      origin: PaymentsType.PREPAID,
      freight: PaymentsType.PREPAID,
      destination: PaymentsType.COLLECT,
    }),
    [IncotermsType.CIF]: PaymentStateDTM.fromPlain({
      origin: PaymentsType.PREPAID,
      freight: PaymentsType.PREPAID,
      destination: PaymentsType.COLLECT,
    }),
  } as Record<IncotermsType, PaymentStateDTM>,
  payeeCompanies: {
    [IncotermsType.DAP]: PayeeCompaniesDTM.fromPlain({
      origin: EShippingPartyTypes.CUSTOMER,
      freight: EShippingPartyTypes.CUSTOMER,
      destination: EShippingPartyTypes.CUSTOMER,
    }),
    [IncotermsType.DPU]: PayeeCompaniesDTM.fromPlain({
      origin: EShippingPartyTypes.CUSTOMER,
      freight: EShippingPartyTypes.CUSTOMER,
      destination: EShippingPartyTypes.CUSTOMER,
    }),
    [IncotermsType.CFR]: PayeeCompaniesDTM.fromPlain({
      origin: EShippingPartyTypes.CUSTOMER,
      freight: EShippingPartyTypes.CUSTOMER,
      destination: EShippingPartyTypes.HOUSE_CONSIGNEE,
    }),
    [IncotermsType.CIF]: PayeeCompaniesDTM.fromPlain({
      origin: EShippingPartyTypes.CUSTOMER,
      freight: EShippingPartyTypes.CUSTOMER,
      destination: EShippingPartyTypes.HOUSE_CONSIGNEE,
    }),
  } as Record<IncotermsType, PayeeCompaniesDTM>,
};
const SI_EXPORT_SELF_DESTINATION = {
  paymentState: {
    [IncotermsType.DAP]: PaymentStateDTM.fromPlain({
      origin: PaymentsType.COLLECT,
      freight: PaymentsType.COLLECT,
      destination: PaymentsType.COLLECT,
    }),
    [IncotermsType.DPU]: PaymentStateDTM.fromPlain({
      origin: PaymentsType.COLLECT,
      freight: PaymentsType.COLLECT,
      destination: PaymentsType.COLLECT,
    }),
    [IncotermsType.CFR]: PaymentStateDTM.fromPlain({
      origin: PaymentsType.COLLECT,
      freight: PaymentsType.COLLECT,
      destination: PaymentsType.COLLECT,
    }),
    [IncotermsType.CIF]: PaymentStateDTM.fromPlain({
      origin: PaymentsType.COLLECT,
      freight: PaymentsType.COLLECT,
      destination: PaymentsType.COLLECT,
    }),
  } as Record<IncotermsType, PaymentStateDTM>,
  payeeCompanies: {
    [IncotermsType.DAP]: PayeeCompaniesDTM.fromPlain({
      origin: EShippingPartyTypes.CUSTOMER,
      freight: EShippingPartyTypes.CUSTOMER,
      destination: EShippingPartyTypes.CUSTOMER,
    }),
    [IncotermsType.DPU]: PayeeCompaniesDTM.fromPlain({
      origin: EShippingPartyTypes.CUSTOMER,
      freight: EShippingPartyTypes.CUSTOMER,
      destination: EShippingPartyTypes.CUSTOMER,
    }),
    [IncotermsType.CFR]: PayeeCompaniesDTM.fromPlain({
      origin: EShippingPartyTypes.CUSTOMER,
      freight: EShippingPartyTypes.CUSTOMER,
      destination: EShippingPartyTypes.HOUSE_CONSIGNEE,
    }),
    [IncotermsType.CIF]: PayeeCompaniesDTM.fromPlain({
      origin: EShippingPartyTypes.CUSTOMER,
      freight: EShippingPartyTypes.CUSTOMER,
      destination: EShippingPartyTypes.HOUSE_CONSIGNEE,
    }),
  } as Record<IncotermsType, PayeeCompaniesDTM>,
};
const SI_IMPORT_SELF_ORIGIN = {
  paymentState: {
    [IncotermsType.EXW]: PaymentStateDTM.fromPlain({
      origin: PaymentsType.PREPAID,
      freight: PaymentsType.PREPAID,
      destination: PaymentsType.PREPAID,
    }),
    [IncotermsType.FCA]: PaymentStateDTM.fromPlain({
      origin: PaymentsType.PREPAID,
      freight: PaymentsType.PREPAID,
      destination: PaymentsType.PREPAID,
    }),
    [IncotermsType.FAS]: PaymentStateDTM.fromPlain({
      origin: PaymentsType.PREPAID,
      freight: PaymentsType.PREPAID,
      destination: PaymentsType.PREPAID,
    }),
    [IncotermsType.FOB]: PaymentStateDTM.fromPlain({
      origin: PaymentsType.PREPAID,
      freight: PaymentsType.PREPAID,
      destination: PaymentsType.PREPAID,
    }),
  } as Record<IncotermsType, PaymentStateDTM>,
  payeeCompanies: {
    [IncotermsType.EXW]: PayeeCompaniesDTM.fromPlain({
      origin: EShippingPartyTypes.CUSTOMER,
      freight: EShippingPartyTypes.CUSTOMER,
      destination: EShippingPartyTypes.CUSTOMER,
    }),
    [IncotermsType.FCA]: PayeeCompaniesDTM.fromPlain({
      origin: EShippingPartyTypes.HOUSE_SHIPPER,
      freight: EShippingPartyTypes.CUSTOMER,
      destination: EShippingPartyTypes.CUSTOMER,
    }),
    [IncotermsType.FAS]: PayeeCompaniesDTM.fromPlain({
      origin: EShippingPartyTypes.HOUSE_SHIPPER,
      freight: EShippingPartyTypes.CUSTOMER,
      destination: EShippingPartyTypes.CUSTOMER,
    }),
    [IncotermsType.FOB]: PayeeCompaniesDTM.fromPlain({
      origin: EShippingPartyTypes.HOUSE_SHIPPER,
      freight: EShippingPartyTypes.CUSTOMER,
      destination: EShippingPartyTypes.CUSTOMER,
    }),
  } as Record<IncotermsType, PayeeCompaniesDTM>,
};
const SI_IMPORT_SELF_DESTINATION = {
  paymentState: {
    [IncotermsType.EXW]: PaymentStateDTM.fromPlain({
      origin: PaymentsType.COLLECT,
      freight: PaymentsType.COLLECT,
      destination: PaymentsType.COLLECT,
    }),
    [IncotermsType.FCA]: PaymentStateDTM.fromPlain({
      origin: PaymentsType.PREPAID,
      freight: PaymentsType.COLLECT,
      destination: PaymentsType.COLLECT,
    }),
    [IncotermsType.FAS]: PaymentStateDTM.fromPlain({
      origin: PaymentsType.PREPAID,
      freight: PaymentsType.COLLECT,
      destination: PaymentsType.COLLECT,
    }),
    [IncotermsType.FOB]: PaymentStateDTM.fromPlain({
      origin: PaymentsType.PREPAID,
      freight: PaymentsType.COLLECT,
      destination: PaymentsType.COLLECT,
    }),
  } as Record<IncotermsType, PaymentStateDTM>,
  payeeCompanies: {
    [IncotermsType.EXW]: PayeeCompaniesDTM.fromPlain({
      origin: EShippingPartyTypes.CUSTOMER,
      freight: EShippingPartyTypes.CUSTOMER,
      destination: EShippingPartyTypes.CUSTOMER,
    }),
    [IncotermsType.FCA]: PayeeCompaniesDTM.fromPlain({
      origin: EShippingPartyTypes.HOUSE_SHIPPER,
      freight: EShippingPartyTypes.CUSTOMER,
      destination: EShippingPartyTypes.CUSTOMER,
    }),
    [IncotermsType.FAS]: PayeeCompaniesDTM.fromPlain({
      origin: EShippingPartyTypes.HOUSE_SHIPPER,
      freight: EShippingPartyTypes.CUSTOMER,
      destination: EShippingPartyTypes.CUSTOMER,
    }),
    [IncotermsType.FOB]: PayeeCompaniesDTM.fromPlain({
      origin: EShippingPartyTypes.HOUSE_SHIPPER,
      freight: EShippingPartyTypes.CUSTOMER,
      destination: EShippingPartyTypes.CUSTOMER,
    }),
  } as Record<IncotermsType, PayeeCompaniesDTM>,
};
// HBL Page rules
const HBL_EXPORT_ORIGIN = {
  paymentState: {
    [IncotermsType.DAP]: PaymentStateDTM.fromPlain({
      origin: PaymentsType.PREPAID,
      freight: PaymentsType.PREPAID,
      destination: PaymentsType.PREPAID,
    }),
    [IncotermsType.DPU]: PaymentStateDTM.fromPlain({
      origin: PaymentsType.PREPAID,
      freight: PaymentsType.PREPAID,
      destination: PaymentsType.PREPAID,
    }),
    [IncotermsType.CFR]: PaymentStateDTM.fromPlain({
      origin: PaymentsType.PREPAID,
      freight: PaymentsType.PREPAID,
      destination: PaymentsType.COLLECT,
    }),
    [IncotermsType.CIF]: PaymentStateDTM.fromPlain({
      origin: PaymentsType.PREPAID,
      freight: PaymentsType.PREPAID,
      destination: PaymentsType.COLLECT,
    }),
  } as Record<IncotermsType, PaymentStateDTM>,
  payeeCompanies: {
    [IncotermsType.DAP]: PayeeCompaniesDTM.fromPlain({
      origin: EShippingPartyTypes.CUSTOMER,
      freight: EShippingPartyTypes.CUSTOMER,
      destination: EShippingPartyTypes.CUSTOMER,
    }),
    [IncotermsType.DPU]: PayeeCompaniesDTM.fromPlain({
      origin: EShippingPartyTypes.CUSTOMER,
      freight: EShippingPartyTypes.CUSTOMER,
      destination: EShippingPartyTypes.CUSTOMER,
    }),
    [IncotermsType.CFR]: PayeeCompaniesDTM.fromPlain({
      origin: EShippingPartyTypes.CUSTOMER,
      freight: EShippingPartyTypes.CUSTOMER,
      destination: EShippingPartyTypes.HOUSE_CONSIGNEE,
    }),
    [IncotermsType.CIF]: PayeeCompaniesDTM.fromPlain({
      origin: EShippingPartyTypes.CUSTOMER,
      freight: EShippingPartyTypes.CUSTOMER,
      destination: EShippingPartyTypes.HOUSE_CONSIGNEE,
    }),
  } as Record<IncotermsType, PayeeCompaniesDTM>,
};
const HBL_EXPORT_DESTINATION = {
  paymentState: {
    [IncotermsType.DAP]: PaymentStateDTM.fromPlain({
      origin: PaymentsType.PREPAID,
      freight: PaymentsType.PREPAID,
      destination: PaymentsType.PREPAID,
    }),
    [IncotermsType.DPU]: PaymentStateDTM.fromPlain({
      origin: PaymentsType.PREPAID,
      freight: PaymentsType.PREPAID,
      destination: PaymentsType.PREPAID,
    }),
    [IncotermsType.CFR]: PaymentStateDTM.fromPlain({
      origin: PaymentsType.PREPAID,
      freight: PaymentsType.PREPAID,
      destination: PaymentsType.COLLECT,
    }),
    [IncotermsType.CIF]: PaymentStateDTM.fromPlain({
      origin: PaymentsType.PREPAID,
      freight: PaymentsType.PREPAID,
      destination: PaymentsType.COLLECT,
    }),
  } as Record<IncotermsType, PaymentStateDTM>,
  payeeCompanies: {
    [IncotermsType.DAP]: PayeeCompaniesDTM.fromPlain({
      origin: EShippingPartyTypes.CUSTOMER,
      freight: EShippingPartyTypes.CUSTOMER,
      destination: EShippingPartyTypes.CUSTOMER,
    }),
    [IncotermsType.DPU]: PayeeCompaniesDTM.fromPlain({
      origin: EShippingPartyTypes.CUSTOMER,
      freight: EShippingPartyTypes.CUSTOMER,
      destination: EShippingPartyTypes.CUSTOMER,
    }),
    [IncotermsType.CFR]: PayeeCompaniesDTM.fromPlain({
      origin: EShippingPartyTypes.CUSTOMER,
      freight: EShippingPartyTypes.CUSTOMER,
      destination: EShippingPartyTypes.HOUSE_CONSIGNEE,
    }),
    [IncotermsType.CIF]: PayeeCompaniesDTM.fromPlain({
      origin: EShippingPartyTypes.CUSTOMER,
      freight: EShippingPartyTypes.CUSTOMER,
      destination: EShippingPartyTypes.HOUSE_CONSIGNEE,
    }),
  } as Record<IncotermsType, PayeeCompaniesDTM>,
};
const HBL_IMPORT_ORIGIN = {
  paymentState: {
    [IncotermsType.EXW]: PaymentStateDTM.fromPlain({
      origin: PaymentsType.COLLECT,
      freight: PaymentsType.COLLECT,
      destination: PaymentsType.COLLECT,
    }),
    [IncotermsType.FCA]: PaymentStateDTM.fromPlain({
      origin: PaymentsType.PREPAID,
      freight: PaymentsType.COLLECT,
      destination: PaymentsType.COLLECT,
    }),
    [IncotermsType.FAS]: PaymentStateDTM.fromPlain({
      origin: PaymentsType.PREPAID,
      freight: PaymentsType.COLLECT,
      destination: PaymentsType.COLLECT,
    }),
    [IncotermsType.FOB]: PaymentStateDTM.fromPlain({
      origin: PaymentsType.PREPAID,
      freight: PaymentsType.COLLECT,
      destination: PaymentsType.COLLECT,
    }),
  } as Record<IncotermsType, PaymentStateDTM>,
  payeeCompanies: {
    [IncotermsType.EXW]: PayeeCompaniesDTM.fromPlain({
      origin: EShippingPartyTypes.CUSTOMER,
      freight: EShippingPartyTypes.CUSTOMER,
      destination: EShippingPartyTypes.CUSTOMER,
    }),
    [IncotermsType.FCA]: PayeeCompaniesDTM.fromPlain({
      origin: EShippingPartyTypes.HOUSE_SHIPPER,
      freight: EShippingPartyTypes.CUSTOMER,
      destination: EShippingPartyTypes.CUSTOMER,
    }),
    [IncotermsType.FAS]: PayeeCompaniesDTM.fromPlain({
      origin: EShippingPartyTypes.HOUSE_SHIPPER,
      freight: EShippingPartyTypes.CUSTOMER,
      destination: EShippingPartyTypes.CUSTOMER,
    }),
    [IncotermsType.FOB]: PayeeCompaniesDTM.fromPlain({
      origin: EShippingPartyTypes.HOUSE_SHIPPER,
      freight: EShippingPartyTypes.CUSTOMER,
      destination: EShippingPartyTypes.CUSTOMER,
    }),
  } as Record<IncotermsType, PayeeCompaniesDTM>,
};
const HBL_IMPORT_DESTINATION = {
  paymentState: {
    [IncotermsType.EXW]: PaymentStateDTM.fromPlain({
      origin: PaymentsType.COLLECT,
      freight: PaymentsType.COLLECT,
      destination: PaymentsType.COLLECT,
    }),
    [IncotermsType.FCA]: PaymentStateDTM.fromPlain({
      origin: PaymentsType.PREPAID,
      freight: PaymentsType.COLLECT,
      destination: PaymentsType.COLLECT,
    }),
    [IncotermsType.FAS]: PaymentStateDTM.fromPlain({
      origin: PaymentsType.PREPAID,
      freight: PaymentsType.COLLECT,
      destination: PaymentsType.COLLECT,
    }),
    [IncotermsType.FOB]: PaymentStateDTM.fromPlain({
      origin: PaymentsType.PREPAID,
      freight: PaymentsType.COLLECT,
      destination: PaymentsType.COLLECT,
    }),
  } as Record<IncotermsType, PaymentStateDTM>,
  payeeCompanies: {
    [IncotermsType.EXW]: PayeeCompaniesDTM.fromPlain({
      origin: EShippingPartyTypes.CUSTOMER,
      freight: EShippingPartyTypes.CUSTOMER,
      destination: EShippingPartyTypes.CUSTOMER,
    }),
    [IncotermsType.FCA]: PayeeCompaniesDTM.fromPlain({
      origin: EShippingPartyTypes.HOUSE_SHIPPER,
      freight: EShippingPartyTypes.CUSTOMER,
      destination: EShippingPartyTypes.CUSTOMER,
    }),
    [IncotermsType.FAS]: PayeeCompaniesDTM.fromPlain({
      origin: EShippingPartyTypes.HOUSE_SHIPPER,
      freight: EShippingPartyTypes.CUSTOMER,
      destination: EShippingPartyTypes.CUSTOMER,
    }),
    [IncotermsType.FOB]: PayeeCompaniesDTM.fromPlain({
      origin: EShippingPartyTypes.HOUSE_SHIPPER,
      freight: EShippingPartyTypes.CUSTOMER,
      destination: EShippingPartyTypes.CUSTOMER,
    }),
  } as Record<IncotermsType, PayeeCompaniesDTM>,
};

interface IPaymentTermsPayeeCompaniesDTM {
  origin: string;
  freight: string;
  destination: string;
}

export class PaymentTermsPayeeCompaniesDTM extends BaseDTM<IPaymentTermsPayeeCompaniesDTM> {
  @IsDefined()
  @IsString()
  origin: string;

  @IsDefined()
  @IsString()
  freight: string;

  @IsDefined()
  @IsString()
  destination: string;

  static createEmpty = () => PaymentTermsPayeeCompaniesDTM.fromPlain({
    origin: '',
    freight: '',
    destination: '',
  });
}

export interface IPaymentTermsDTM {
  id: number;
  incoterm: IncotermsType;
  tradeType: TradeType;
  origin: PaymentsType;
  freight: PaymentsType;
  destination: PaymentsType;
}

export class PaymentTermsDTM extends BaseDTM<IPaymentTermsDTM> {
  @IsOptional()
  @IsNumber()
  id: number;

  @IsDefined()
  @IsEnum(IncotermsType)
  incoterm: IncotermsType;

  @IsDefined()
  @IsEnum(TradeType)
  tradeType: TradeType;

  @IsDefined()
  @IsEnum(PaymentsType)
  origin: PaymentsType;

  @IsDefined()
  @IsEnum(PaymentsType)
  freight: PaymentsType;

  @IsDefined()
  @IsEnum(PaymentsType)
  destination: PaymentsType;

  static getAvailableIncoterms = (tradeType: TradeType) => {
    if (tradeType === TradeType.IMPORT) {
      return IMPORT_INCOTERMS;
    }

    return EXPORT_INCOTERMS;
  }

  static getCurrentAvailablePayments = (incoterm: IncotermsType, tradeType: TradeType, contractOwner: ContractOwner, isSelfService: boolean, isHBLPage?: boolean) => {
    if (isHBLPage) {
      if (tradeType === TradeType.EXPORT && contractOwner === ContractOwner.ORIGIN) {
        return HBL_EXPORT_ORIGIN.paymentState[incoterm];
      }

      if (tradeType === TradeType.EXPORT && contractOwner === ContractOwner.DESTINATION) {
        return HBL_EXPORT_DESTINATION.paymentState[incoterm];
      }

      if (tradeType === TradeType.IMPORT && contractOwner === ContractOwner.ORIGIN) {
        return HBL_IMPORT_ORIGIN.paymentState[incoterm];
      }

      if (tradeType === TradeType.IMPORT && contractOwner === ContractOwner.DESTINATION) {
        return HBL_IMPORT_DESTINATION.paymentState[incoterm];
      }

      return PaymentStateDTM.fromPlain({
        origin: '',
        freight: '',
        destination: '',
      });
    }

    // SI page cases
    if (!isSelfService) {
      if (tradeType === TradeType.EXPORT && contractOwner === ContractOwner.ORIGIN) {
        return SI_EXPORT_NONSELF_ORIGIN.paymentState[incoterm];
      }

      if (tradeType === TradeType.EXPORT && contractOwner === ContractOwner.DESTINATION) {
        return SI_EXPORT_NONSELF_DESTINATION.paymentState[incoterm];
      }

      if (tradeType === TradeType.IMPORT && contractOwner === ContractOwner.ORIGIN) {
        return SI_IMPORT_NONSELF_ORIGIN.paymentState[incoterm];
      }

      if (tradeType === TradeType.IMPORT && contractOwner === ContractOwner.DESTINATION) {
        return SI_IMPORT_NONSELF_DESTINATION.paymentState[incoterm];
      }

      return PaymentStateDTM.fromPlain({
        origin: '',
        freight: '',
        destination: '',
      });
    }

    if (tradeType === TradeType.EXPORT && contractOwner === ContractOwner.ORIGIN) {
      return SI_EXPORT_SELF_ORIGIN.paymentState[incoterm];
    }

    if (tradeType === TradeType.EXPORT && contractOwner === ContractOwner.DESTINATION) {
      return SI_EXPORT_SELF_DESTINATION.paymentState[incoterm];
    }

    if (tradeType === TradeType.IMPORT && contractOwner === ContractOwner.ORIGIN) {
      return SI_IMPORT_SELF_ORIGIN.paymentState[incoterm];
    }

    if (tradeType === TradeType.IMPORT && contractOwner === ContractOwner.DESTINATION) {
      return SI_IMPORT_SELF_DESTINATION.paymentState[incoterm];
    }

    return PaymentStateDTM.fromPlain({
      origin: '',
      freight: '',
      destination: '',
    });
  };

  static getPayeeShippingParties = (incoterm: IncotermsType, tradeType: TradeType, contractOwner: ContractOwner, isSelfService: boolean, isHBLPage?: boolean) => {
    if (isHBLPage) {
      if (tradeType === TradeType.EXPORT && contractOwner === ContractOwner.ORIGIN) {
        return HBL_EXPORT_ORIGIN.payeeCompanies[incoterm];
      }

      if (tradeType === TradeType.EXPORT && contractOwner === ContractOwner.DESTINATION) {
        return HBL_EXPORT_DESTINATION.payeeCompanies[incoterm];
      }

      if (tradeType === TradeType.IMPORT && contractOwner === ContractOwner.ORIGIN) {
        return HBL_IMPORT_ORIGIN.payeeCompanies[incoterm];
      }

      if (tradeType === TradeType.IMPORT && contractOwner === ContractOwner.DESTINATION) {
        return HBL_IMPORT_DESTINATION.payeeCompanies[incoterm];
      }

      return PayeeCompaniesDTM.fromPlain({
        origin: '' as EShippingPartyTypes,
        freight: '' as EShippingPartyTypes,
        destination: '' as EShippingPartyTypes,
      });
    }

    // SI page cases

    if (!isSelfService) {
      if (tradeType === TradeType.EXPORT && contractOwner === ContractOwner.ORIGIN) {
        return SI_EXPORT_NONSELF_ORIGIN.payeeCompanies[incoterm];
      }

      if (tradeType === TradeType.EXPORT && contractOwner === ContractOwner.DESTINATION) {
        return SI_EXPORT_NONSELF_DESTINATION.payeeCompanies[incoterm];
      }

      if (tradeType === TradeType.IMPORT && contractOwner === ContractOwner.ORIGIN) {
        return SI_IMPORT_NONSELF_ORIGIN.payeeCompanies[incoterm];
      }

      if (tradeType === TradeType.IMPORT && contractOwner === ContractOwner.DESTINATION) {
        return SI_IMPORT_NONSELF_DESTINATION.payeeCompanies[incoterm];
      }

      return PayeeCompaniesDTM.fromPlain({
        origin: '' as EShippingPartyTypes,
        freight: '' as EShippingPartyTypes,
        destination: '' as EShippingPartyTypes,
      });
    }

    if (tradeType === TradeType.EXPORT && contractOwner === ContractOwner.ORIGIN) {
      return SI_EXPORT_SELF_ORIGIN.payeeCompanies[incoterm];
    }

    if (tradeType === TradeType.EXPORT && contractOwner === ContractOwner.DESTINATION) {
      return SI_EXPORT_SELF_DESTINATION.payeeCompanies[incoterm];
    }

    if (tradeType === TradeType.IMPORT && contractOwner === ContractOwner.ORIGIN) {
      return SI_IMPORT_SELF_ORIGIN.payeeCompanies[incoterm];
    }

    if (tradeType === TradeType.IMPORT && contractOwner === ContractOwner.DESTINATION) {
      return SI_IMPORT_SELF_DESTINATION.payeeCompanies[incoterm];
    }

    return PayeeCompaniesDTM.fromPlain({
      origin: '' as EShippingPartyTypes,
      freight: '' as EShippingPartyTypes,
      destination: '' as EShippingPartyTypes,
    });
  };

  static getPayeeCompanyNames = (incoterm: IncotermsType | '', shippingPartiesMap: Record<EShippingPartyTypes, ShippingPartyOverviewDTM>, tradeType: TradeType, contractOwner: ContractOwner, isSelfService: boolean, isHBLPage?: boolean) => {
    if (!incoterm) {
      return PaymentTermsPayeeCompaniesDTM.createEmpty();
    }

    const { origin, freight, destination } = PaymentTermsDTM.getPayeeShippingParties(incoterm, tradeType, contractOwner, isSelfService, isHBLPage);

    const originPayee = shippingPartiesMap[origin];
    const freightPayee = shippingPartiesMap[freight];
    const destinationPayee = shippingPartiesMap[destination];

    return PaymentTermsPayeeCompaniesDTM.fromPlain({
      origin: originPayee ? originPayee.getCompanyName() : '',
      freight: freightPayee ? freightPayee.getCompanyName() : '',
      destination: destinationPayee ? destinationPayee.getCompanyName() : '',
    });
  };
}

interface IPaymentTermsChargesAmountDTM {
  origin: number;
  freight: number;
  destination: number;
}

export class PaymentTermsChargesAmountDTM extends BaseDTM<IPaymentTermsChargesAmountDTM> {
  @IsDefined()
  @IsNumber()
  origin: number;

  @IsDefined()
  @IsNumber()
  freight: number;

  @IsDefined()
  @IsNumber()
  destination: number;
}
