import React from 'react';

import { IPropsIcon } from 'app-wrapper/interfaces/icon';

import { Icon } from './EmptyWindowForIconsSvg.styled';

export const EmptyWindowForIconsSvg = (props: IPropsIcon) => {
  const { width, height, fill } = props;
  return (
    <Icon
      className="EmptyWindowForIconsSvg"
      width={width || '90'}
      height={height || '69'}
      viewBox="0 0 90 69"
      fill={fill || 'currentColor'}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M86.4548 3.01733H2.60767C1.16749 3.01733 0 4.18483 0 5.625V65.8379C0 67.278 1.16749 68.4455 2.60767 68.4455H86.4548C87.895 68.4455 89.0625 67.278 89.0625 65.8379V5.625C89.0625 4.18483 87.895 3.01733 86.4548 3.01733Z" fill="#E4EBF7" />
      <path d="M79.5217 14.8042H8.52255C7.97507 14.8042 7.53125 15.248 7.53125 15.7955V61.6166C7.53125 62.1641 7.97507 62.608 8.52255 62.608H79.5217C80.0692 62.608 80.513 62.1641 80.513 61.6166V15.7955C80.513 15.248 80.0692 14.8042 79.5217 14.8042Z" fill="white" />
      <path d="M2.3856 0H86.6322C87.9497 0 89.0178 1.04587 89.0178 2.33603V9.29738H0V2.33603C0 1.04587 1.06807 0 2.3856 0Z" fill="#4E5C93" />
      <path d="M5.09921 6.316C6.0396 6.316 6.80193 5.56951 6.80193 4.64866C6.80193 3.72782 6.0396 2.98132 5.09921 2.98132C4.15882 2.98132 3.39648 3.72782 3.39648 4.64866C3.39648 5.56951 4.15882 6.316 5.09921 6.316Z" fill="#A3B5D6" />
      <path d="M11.4586 6.316C12.399 6.316 13.1613 5.56951 13.1613 4.64866C13.1613 3.72782 12.399 2.98132 11.4586 2.98132C10.5182 2.98132 9.75586 3.72782 9.75586 4.64866C9.75586 5.56951 10.5182 6.316 11.4586 6.316Z" fill="#E4EBF7" />
      <path d="M17.8199 6.316C18.7603 6.316 19.5226 5.56951 19.5226 4.64866C19.5226 3.72782 18.7603 2.98132 17.8199 2.98132C16.8795 2.98132 16.1172 3.72782 16.1172 4.64866C16.1172 5.56951 16.8795 6.316 17.8199 6.316Z" fill="#E4EBF7" />
    </Icon>
  );
};
