import React, {
  FC, useCallback, useMemo,
} from 'react';
import { useTranslation, Trans } from 'react-i18next';
import Alert from 'antd/es/alert';
import { useParams } from 'react-router';

import { Button } from 'app-wrapper/view/components';
import { normalizationCost } from 'app-wrapper/utils';

interface IValidateComponentProps {
  onValidate: (shipmentId: string, type: string) => void;
  loading: boolean;
  isChangedCharges: boolean;
  isLoaded: boolean;
  currentTotal: number;
  newTotal: number;
  showAddCharge: boolean;
  openAddChargesDrawer: () => void;
  openChangesCost: () => void;
  typeDrawer: string
  savedShipmentId: string
}

const ValidateComponent: FC<IValidateComponentProps> = ({
  onValidate, loading, isChangedCharges, isLoaded, currentTotal, openChangesCost,
  newTotal, showAddCharge, openAddChargesDrawer, typeDrawer, savedShipmentId,
}) => {
  const { t } = useTranslation();
  const { shipmentId } = useParams<'shipmentId'>();

  const handleValidate = useCallback(() => {
    if (shipmentId || savedShipmentId) {
      onValidate(shipmentId || savedShipmentId, typeDrawer);
    }
  }, [savedShipmentId]);

  const title = useMemo(() => {
    if (showAddCharge) {
      return t('addRatesTitle');
    }
    if (!isLoaded) {
      return t('verifyRatesTitle');
    }
    if (isLoaded && isChangedCharges) {
      return (
        <Trans
          i18nKey="updatedCostTitle"
          values={{
            currentTotal: `$ ${normalizationCost(currentTotal, { toFixed: 2, thousandthSeparatorComma: true })}`,
            newTotal: `$ ${normalizationCost(newTotal, { toFixed: 2, thousandthSeparatorComma: true })}`,
          }}
          components={{ strong: <strong /> }}
        />
      );
    }
    if (isLoaded && !isChangedCharges) {
      return t('sameCostTitle');
    }
    return '';
  }, [isChangedCharges, isLoaded, showAddCharge, loading]);

  const type = useMemo(() => {
    if (showAddCharge) {
      return 'error';
    }
    if (isLoaded && !isChangedCharges) {
      return 'success';
    }
    return 'warning';
  }, [isChangedCharges, isLoaded, showAddCharge, loading]);

  const button = useMemo(() => {
    if (showAddCharge) {
      return (
        <Button
          size="middle"
          type="default"
          onClick={openAddChargesDrawer}
          style={{ background: '#FF4D4F', color: 'white' }}
        >
          {t('addRate')}
        </Button>
      );
    }
    if (!isLoaded) {
      return (
        <Button size="middle" type="primary" onClick={handleValidate} loading={loading}>
          {t('verifyRates')}
        </Button>
      );
    }
    if (isLoaded && isChangedCharges) {
      return (
        <Button size="middle" type="primary" onClick={openChangesCost}>
          {t('viewDetails')}
        </Button>
      );
    }
    return null;
  }, [isChangedCharges, isLoaded, showAddCharge, loading]);

  return (
    <Alert
      showIcon
      type={type}
      message={title}
      action={button}
      style={{ marginBottom: '10px' }}
    />
  );
};

export { ValidateComponent };
