import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'app-wrapper/createSlice';

import { INewOrganizationWizardStepsState } from 'user-management/models/states';
import { TStepStatus } from 'user-management/constants';

const initialState: INewOrganizationWizardStepsState = {
  percent: 0,
  firstStepIsDisabled: false,
  secondStepIsDisabled: false,
  thirdStepIsDisabled: false,
  status: undefined,
  activeStepNumber: undefined,
};

export const newOrganizationWizardStepsStore = createSlice({
  name: 'NewOrganizationWizardSteps',
  initialState,
  reducers: {
    setFirstStepDisable: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      firstStepIsDisabled: payload,
    }),
    setSecondStepDisable: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      secondStepIsDisabled: payload,
    }),
    setThirdStepDisable: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      thirdStepIsDisabled: payload,
    }),
    setActiveStepNumber: (state, { payload }: PayloadAction<number>) => ({
      ...state,
      activeStepNumber: payload,
    }),
    setPercent: (state, { payload }: PayloadAction<number>) => ({
      ...state,
      percent: payload,
    }),
    setStatus: (state, { payload }: PayloadAction<TStepStatus>) => ({
      ...state,
      status: payload,
    }),
  },
});

export const newOrganizationWizardStepsActions = newOrganizationWizardStepsStore.actions;

export const newOrganizationWizardStepsReducer = newOrganizationWizardStepsStore.reducer;
