import { useTranslation } from 'react-i18next';
import React, { useCallback, useMemo } from 'react';
import { StyledCell } from 'shipment-operations/view/pages/ShipmentCharges/components/TotalFooter/TotalFooter.styled';
import { checkSign } from 'app-wrapper/utils';
import { ShipmentChargesPermissionsDtm } from 'shipment-operations/models/dtm';
import { CUSTOMER_VIEW, PermissionAttributePolicy } from 'shipment-operations/constants';

const useTable = (permissions: ShipmentChargesPermissionsDtm, mode: string, isPercentage: boolean) => {
  const { t } = useTranslation();

  const renderProfit = useCallback((value, record) => {
    if (isPercentage) {
      const percent = record.profitPercentage;
      return (
        <StyledCell>
          {checkSign(percent, '%')}
        </StyledCell>
      );
    }
    return (
      <StyledCell>
        {checkSign(value, '$')}
      </StyledCell>
    );
  }, [isPercentage]);

  const fullColumns = useMemo(() => [
    {
      width: '19%',
      key: 1,
      render: () => <StyledCell>{t('total')}</StyledCell>,
    },
    {
      width: '12%',
      dataIndex: 'unitType',
      render: () => null,
    },
    {
      align: 'right' as 'right',
      width: '10%',
      render: () => null,
    },
    {
      width: '4%',
      align: 'right' as 'right',
      render: () => null,
    },
    {
      dataIndex: 'buyTotalCost',
      key: 'buyTotalCost',
      align: 'right' as 'right',
      width: '9%',
      render: (value: number) => <StyledCell>$ {value.toFixed(2)}</StyledCell>,
    },
    {
      width: '8%',
      dataIndex: 'arTotalCost',
      key: 'arTotalCost',
      render: () => null,
    },
    {
      align: 'right' as 'right',
      width: '10%',
      render: () => null,
    },
    {
      width: '4%',
      align: 'right' as 'right',
      render: () => null,
    },
    {
      width: '9%',
      dataIndex: 'totalCost',
      key: 'totalCost',
      align: 'right' as 'right',
      render: (value: number) => <StyledCell>$ {value.toFixed(2)}</StyledCell>,
    },
    {
      width: '5%',
      render: () => null,
    },
    {
      dataIndex: 'profit',
      key: 'profit',
      align: 'right' as 'right',
      width: '10%',
      render: renderProfit,
    },
  ], [isPercentage]);

  const columns = useMemo(() => [
    {
      width: '24%',
      key: 1,
      render: () => <StyledCell>{t('total')}</StyledCell>,
    },
    {
      width: '19%',
      key: 2,
      render: () => null,
    },
    {
      width: '19%',
      key: 3,
      render: () => null,
    },
    {
      width: '19%',
      key: 4,
      render: () => null,
    },
    {
      width: '19%',
      key: 'buyTotalCost',
      dataIndex: 'buyTotalCost',
      align: 'right' as 'right',
      render: (value: number) => <StyledCell>$ {value.toFixed(2)}</StyledCell>,
    },
  ], [isPercentage]);

  if (mode === CUSTOMER_VIEW) {
    return { columns };
  }

  return { columns: permissions.shipmentChargesAvailability === PermissionAttributePolicy.WRITE ? fullColumns : columns };
};

export { useTable };
