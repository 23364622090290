import React from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';

import { TransportPlanComponent } from './TransportPlan.component';

const TransportPlanContainer = () => {
  const transportations = useSelector(R.selectors.manualBookingWizard.getTransportations);
  const isPending = useSelector(R.selectors.manualBookingWizard.getIsLoading);

  return (
    <TransportPlanComponent transportations={transportations} isPending={isPending} />
  );
};

export { TransportPlanContainer as TransportPlan };
