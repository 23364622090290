import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'app-wrapper/createSlice';

import { TCustomerState } from 'user-management/models/states';
import { DocumentDTM, ContainerDocumentDTM } from 'shipment-operations/models/dtm';
import { DocumentType } from 'shipment-operations/constants';
import {
  CustomerDocumentsMap, OrganizationDTM, OrganizationPaymentMethodDTM, OrganizationTradeReferenceDTM,
} from 'user-management/models/dtm';

export const initialState: TCustomerState = {
  isLoading: false,
  isModalPending: false,
  isUploadLoading: false,
  isRejectModalOpened: false,
  rejectReason: '',
  isAdditionalModalOpened: false,
  additionalInfoNote: '',
  isOrganizationAdmin: false,
  organizationId: 0,
  organization: null,
  documents: [],
  tradeReference: OrganizationTradeReferenceDTM.createEmpty(),
  wasPaymentMethodOnceSubmitted: false,
  wasTradeReferenceOnceSubmitted: false,
  isTradeReferencePending: false,
  rejectionNote: '',
  documentsTypeMap: {
    [DocumentType.ALO]: [],
    [DocumentType.CCI]: [],
    [DocumentType.BLIC]: [],
    [DocumentType.TCER]: [],
    [DocumentType.MISC]: [],
  },
  documentToUpload: null,
};

export const customerStore = createSlice({
  name: 'customer',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setIsTradeReferencePending: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      isTradeReferencePending: payload,
    }),
    setIsUploadLoading: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      isUploadLoading: payload,
    }),
    setIsModalPending: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      isModalPending: payload,
    }),
    setIsRejectModalOpened: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      isRejectModalOpened: payload,
    }),
    setIsAdditionalModalOpened: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      isAdditionalModalOpened: payload,
    }),
    setRejectReason: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      rejectReason: payload,
    }),
    setIsOrganizationAdmin: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      isOrganizationAdmin: payload,
    }),
    setAdditionalInfoNote: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      additionalInfoNote: payload,
    }),
    setOrganizationId: (state, { payload }: PayloadAction<number>) => ({
      ...state,
      organizationId: payload,
    }),
    setRejectionNote: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      rejectionNote: payload,
    }),
    setOrganization: (state, { payload }: PayloadAction<OrganizationDTM | null>) => ({
      ...state,
      organization: payload,
    }),
    setTradeReference: (state, { payload }: PayloadAction<OrganizationTradeReferenceDTM>) => ({
      ...state,
      tradeReference: payload,
    }),
    setPaymentMethod: (state, { payload }: PayloadAction<OrganizationPaymentMethodDTM>) => ({
      ...state,
      paymentMethod: payload,
    }),
    setPaymentMethodForm: (state, { payload }: PayloadAction<OrganizationPaymentMethodDTM>) => ({
      ...state,
      paymentMethodForm: payload,
    }),
    setWasTradeReferenceOnceSubmitted: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      wasTradeReferenceOnceSubmitted: payload,
    }),
    setWasPaymentMethodOnceSubmitted: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      wasPaymentMethodOnceSubmitted: payload,
    }),
    setDocuments: (state, { payload }: PayloadAction<DocumentDTM[]>) => ({
      ...state,
      documents: payload,
    }),
    setDocumentsTypeMap: (state, { payload }: PayloadAction<CustomerDocumentsMap>) => ({
      ...state,
      documentsTypeMap: payload,
    }),
    setDocumentTypeMapDocuments: (state, { payload }: PayloadAction<{ type: DocumentType, documents: DocumentDTM[] }>) => {
      const { documentsTypeMap } = state;
      documentsTypeMap[payload.type] = [...payload.documents];

      return {
        ...state,
        documentsTypeMap,
      };
    },
    setDocumentToUpload: (state, { payload }: PayloadAction<ContainerDocumentDTM | null>) => ({
      ...state,
      documentToUpload: payload,
    }),
    setDocumentToUploadType: (state, { payload }: PayloadAction<DocumentType>) => ({
      ...state,
      documentToUploadType: payload,
    }),
    clearDocumentUploadFormData: (state) => ({
      ...state,
      documentToUpload: null,
      documentToUploadType: undefined,
    }),
    clear: () => (initialState),
  },
});

export const customerActions = customerStore.actions;
export const customerReducer = customerStore.reducer;
