import styled from 'styled-components';

export const Wrapper = styled.div`
  position: fixed;
  bottom: 0;
  right: 0;
  left: 16%;
  height: 64px;
  background-color: white;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px rgba(0, 0, 0, 0.08), 0px 9px 28px 8px rgba(0, 0, 0, 0.05);
  z-index: 110;
  padding: 0 24px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: #202C3C;
  font-weight: 500;
  font-size: 20px;
`;
