import { BaseUseCase } from 'app-wrapper/usecases/Base.useCase';
import { R } from 'user-management/repository';
import { EOrganizationStatus, TeamDTM } from 'user-management/models/dtm';

const CUSTOMERS_COUNT_PER_PAGE = 1000;

export class TeamsUseCase extends BaseUseCase {
  public fullFilTeamWithCustomers = async (team: TeamDTM): Promise<TeamDTM> => {
    const customers = await R.services.organization.getOrganizations(0, CUSTOMERS_COUNT_PER_PAGE, EOrganizationStatus.ACTIVE, undefined, team.id);

    return TeamDTM.fromPlain({
      ...team,
      customers,
    });
  };
}
