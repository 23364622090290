import React from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';

import { PDFCutoffDetailsComponent } from './PDFCutoffDetails.component';

const PDFCutoffDetailsContainer = () => {
  const cutoffDetails = useSelector(R.selectors.bookingConfirmationPDF.getCutoffItems);

  return (
    <PDFCutoffDetailsComponent
      items={cutoffDetails}
    />
  );
};

export { PDFCutoffDetailsContainer as PDFCutoffDetails };
