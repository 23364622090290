import styled from 'styled-components';
import Typography from 'antd/es/typography';
import Spin from 'antd/es/spin';

import { Select as BaseSelect } from 'app-wrapper/view/components';
import { divFlex } from 'app-wrapper/view/themes/mixin';

export const Form = styled.div.attrs({
  'data-class': 'BOLFormEditClauses',
})`
  height: 100%;
  padding: 24px;
`;

export const FormItem = styled.div`
  ${divFlex}
  flex-direction: column;
  margin-bottom: 10px;
`;

export const FormItemLabel = styled.div`
  ${divFlex}
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 4px;
`;

export const Label = styled(Typography)`
  font-size: 12px;
  line-height: 20px;
`;

export const SpinnerWrap = styled.div`
  ${divFlex}
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
`;

export const Spinner = styled(Spin)`
  margin: auto;
`;

export const Select = styled(BaseSelect)`
  font-size: 12px;
  line-height: 22px;
  
  span.ant-select-selection-item {
    max-height: 22px;
    line-height: 22px;
    background-color: ${({ theme }) => theme.themesColors.neutralBranding3};
    border-color: ${({ theme }) => theme.themesColors.neutralBranding4};
  }
  
  .ant-select-selection-item-content {
    font-size: 12px;
    line-height: 20px;
    color: ${({ theme }) => theme.themesColors.characterSecondaryDot45};
  }
  
  .ant-select-selection-item-remove {
    font-size: 9px;
    transform: translateY(-6.5px);
  }

  div.ant-select-selection-overflow-item-suffix {
    height: 22px;
    max-height: 22px;
    line-height: 22px;
    
    & > div.ant-select-selection-search {
      margin-top: 0;
      margin-bottom: 0;
      height: 22px;
      max-height: 22px;
      line-height: 22px;
    }
  }

  input.ant-select-selection-search-input {
    height: 22px;
    max-height: 22px;
    line-height: 22px;
  }
`;
