import React, { FC, ReactNode } from 'react';

import {
  SubPageContent,
  SubPageHeader, SubPageHeaderContainer, SubPageHeaderTitle, Wrapper,
} from 'app-wrapper/view/guideline';
import {
  OrganizationProfileContent,
  OrganizationProfileContentHeader,
  OrganizationProfileHorizontalSeparator,
  OrganizationProfileHorizontalDashedSeparator,
  OrganizationProfileRightBox,
  OrganizationProfileLeftTopBox,
  OrganizationProfileLeftBottomBox,
  OrganizationProfileVerticalSeparator,
  OrganizationProfileRightTopBox,
  OrganizationProfileHorizontalDashedSeparatorRight,
  OrganizationProfileLeftCoverageAreaBox,
} from './OrganizationProfileScaffold.styled';

interface IOrganizationProfileScaffoldComponentProps {
  title: ReactNode
  warningBlock: ReactNode
  header: ReactNode
  leftTopContentBox: ReactNode
  leftBottomContentBox: ReactNode
  rightTopContentBox: ReactNode
  rightContentBox: ReactNode
  coverageArea: ReactNode
}

export const OrganizationProfileScaffold: FC<IOrganizationProfileScaffoldComponentProps> = ({
  title,
  header,
  warningBlock,
  leftTopContentBox,
  leftBottomContentBox,
  rightTopContentBox,
  rightContentBox,
  coverageArea,
}) => (
  <Wrapper>
    <SubPageHeader>
      <SubPageHeaderContainer>
        <SubPageHeaderTitle>
          {title}
        </SubPageHeaderTitle>
      </SubPageHeaderContainer>
    </SubPageHeader>

    {warningBlock}

    <SubPageContent>
      <OrganizationProfileContent>
        <OrganizationProfileContentHeader>
          {header}
        </OrganizationProfileContentHeader>
        <OrganizationProfileHorizontalSeparator />
        <OrganizationProfileLeftTopBox>
          {leftTopContentBox}
        </OrganizationProfileLeftTopBox>
        <OrganizationProfileHorizontalDashedSeparator />
        <OrganizationProfileLeftBottomBox>
          {leftBottomContentBox}
        </OrganizationProfileLeftBottomBox>
        <OrganizationProfileLeftCoverageAreaBox>
          {coverageArea}
        </OrganizationProfileLeftCoverageAreaBox>
        <OrganizationProfileVerticalSeparator />
        <OrganizationProfileRightTopBox>
          {rightTopContentBox}
        </OrganizationProfileRightTopBox>
        <OrganizationProfileHorizontalDashedSeparatorRight />
        <OrganizationProfileRightBox>
          {rightContentBox}
        </OrganizationProfileRightBox>
      </OrganizationProfileContent>
    </SubPageContent>
  </Wrapper>
);
