import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';
import { UC } from 'shipment-operations/controllers';

import { PackQTYInputComponent } from './PackQTYInput.component';

interface DescriptionInputContainerProps {
  cargoId: number;
}

const PackQTYInputContainer: FC<DescriptionInputContainerProps> = ({ cargoId }) => {
  const { packagesNumber = '', errors } = useSelector(R.selectors.bookingWizard.getCargoById(cargoId));
  const { packagesNumber: error } = errors;

  return (
    <PackQTYInputComponent
      cargoId={cargoId}
      packagesQTY={packagesNumber}
      touchCargoField={UC.bookingWizard.touchCargoField}
      setPackagesQTY={UC.bookingWizard.setPackagesNumber}
      error={error}
    />
  );
};

export { PackQTYInputContainer as PackQTYInput };
