import { createSelector } from 'reselect';
import { RootState } from 'app-wrapper/store';

const localState = (state: RootState) => state.rates;

const getIsLoading = createSelector(
  localState,
  (state) => state.isLoading,
);

const getFileSend = createSelector(
  localState,
  (state) => state.fileSend,
);

const getRatesList = createSelector(
  localState,
  (state) => state.ratesList,
);

export const ratesSelectors = {
  getIsLoading,
  getFileSend,
  getRatesList,
};
