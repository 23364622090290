import { createSelector } from 'reselect';

import { IPostOrganizationTeamContract } from 'user-management/models/contracts';
import { RootState } from 'app-wrapper/store';
import { EOrganizationMemberRole, EAccountDepartmentType } from 'user-management/constants';

const localState = (state: RootState) => state.organizationTeams;

const getIsLoading = createSelector(
  localState,
  (state) => state.isLoading,
);

const getIsDrawerOpened = createSelector(
  localState,
  (state) => state.isDrawerOpened,
);

const getTeams = createSelector(
  localState,
  (state) => state.teams,
);

const getTeam = createSelector(
  localState,
  (state) => state.team,
);

const getIsCurrentUserAdmin = createSelector(
  localState,
  (state) => {
    const user = state.currentUser;

    if (!user) {
      return false;
    }

    return user.role === EOrganizationMemberRole.admin;
  },
);

const getIsDrawerInEditMode = createSelector(
  localState,
  (state) => state.isDrawerInEditMode,
);

const getIsDrawerLoading = createSelector(
  localState,
  (state) => state.isDrawerLoading,
);

const getCustomers = createSelector(
  localState,
  (state) => state.customers,
);

const getMembers = createSelector(
  localState,
  (state) => state.members,
);

const getOrganizationId = createSelector(
  localState,
  (state) => state.organizationId,
);

const getTempCustomers = createSelector(
  localState,
  (state) => state.tempCustomers,
);

const getTempMembers = createSelector(
  localState,
  (state) => state.tempMembers,
);

const getTempTeamName = createSelector(
  localState,
  (state) => state.tempTeamName,
);

const getCustomerTeam = createSelector(
  localState,
  (state) => state.customerTeam,
);

const getIsAddTeamDrawerOpened = createSelector(
  localState,
  (state) => state.isAddTeamDrawerOpened,
);

const getIsAddTeamDrawerLoading = createSelector(
  localState,
  (state) => state.isAddTeamDrawerLoading,
);

const getTeamToAddOrChangeId = createSelector(
  localState,
  (state) => state.teamToAddOrChangeId,
);

const getAccountTeam = createSelector(
  localState,
  (state) => state.accountTeam,
);

const getAccountManager = createSelector(
  localState,
  (state) => state.accountManager,
);

const getCustomerOrganization = createSelector(
  localState,
  (state) => state.customerOrganization,
);

const getTempAccountManager = createSelector(
  localState,
  (state) => state.tempAccountManager,
);

const getTempAccountTeam = createSelector(
  localState,
  (state) => state.tempAccountTeam,
);

const getAccountManagersMembers = createSelector(
  localState,
  (state) => state.members.filter(({ departments }) => departments.find(({ type }) => type === EAccountDepartmentType.ACCOUNT_MANAGEMENT)),
);

const getIsAddOrChangeDrawerInEditMode = createSelector(
  localState,
  (state) => !!state.accountTeam,
);

const getIsAbleToSaveAddOrChangeTeam = createSelector(
  localState,
  (state) => {
    const { tempAccountTeam, tempAccountManager, customerOrganization } = state;

    if (!customerOrganization) {
      return false;
    }

    return (!!tempAccountTeam && !!tempAccountManager) && ((tempAccountTeam !== customerOrganization?.accountTeam?.id) || (tempAccountManager !== customerOrganization?.accountManager?.email));
  },
);

const getRolesToEmailsState = createSelector(
  localState,
  (state) => state.rolesToEmailsState,
);

const getEmailValueByRole = (role: EAccountDepartmentType) => createSelector(
  getRolesToEmailsState,
  (rolesToEmails) => rolesToEmails[role],
);

const getIsCustomersSelectActive = createSelector(
  getRolesToEmailsState,
  (rolesToEmails) => !!rolesToEmails[EAccountDepartmentType.BOOKING_OPS]
      && !!rolesToEmails[EAccountDepartmentType.DOCUMENTATION_OPS_IMPORT]
      && !!rolesToEmails[EAccountDepartmentType.DOCUMENTATION_OPS_EXPORT]
      && !!rolesToEmails[EAccountDepartmentType.DRAYAGE_EXPORT]
      && !!rolesToEmails[EAccountDepartmentType.DRAYAGE_IMPORT],
);

const getIsAbleToCreateOrSaveTeam = createSelector(
  localState,
  getIsCustomersSelectActive,
  getRolesToEmailsState,
  (state, isCustomerSelectActive) => !!state.tempTeamName.length && isCustomerSelectActive,
);

const getTeamMembersWithRoles = createSelector(
  getRolesToEmailsState,
  (rolesToEmails) => {
    const members: IPostOrganizationTeamContract['members'] = [];

    Object.keys(rolesToEmails).forEach((role) => {
      const email = rolesToEmails[role as EAccountDepartmentType];

      if (email) {
        members.push({
          role: role as EAccountDepartmentType,
          member: {
            user: {
              email,
            },
          },
        });
      }
    });

    return members;
  },
);

const getTeamMembers = createSelector(
  localState,
  (state) => state.teamMembers,
);

export const teamsSelectors = {
  getIsLoading,
  getTeams,
  getTeam,
  getIsCurrentUserAdmin,
  getIsDrawerOpened,
  getIsDrawerInEditMode,
  getIsDrawerLoading,
  getCustomers,
  getMembers,
  getOrganizationId,
  getIsCustomersSelectActive,
  getTempCustomers,
  getTempMembers,
  getTempTeamName,
  getIsAbleToCreateOrSaveTeam,
  getCustomerTeam,
  getIsAddTeamDrawerOpened,
  getIsAddTeamDrawerLoading,
  getTeamToAddOrChangeId,
  getAccountTeam,
  getAccountManager,
  getCustomerOrganization,
  getTempAccountManager,
  getTempAccountTeam,
  getAccountManagersMembers,
  getIsAbleToSaveAddOrChangeTeam,
  getIsAddOrChangeDrawerInEditMode,
  getRolesToEmailsState,
  getEmailValueByRole,
  getTeamMembersWithRoles,
  getTeamMembers,
};
