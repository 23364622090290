import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import {
  NewOrganizationWizardWrapperHeaderContainer,
} from './NewOrganizationWizardWrapperHeader.styled';

export const NewOrganizationWizardWrapperHeader: FC = () => {
  const { t } = useTranslation();

  return (
    <NewOrganizationWizardWrapperHeaderContainer>
      {t('Create Organization')}
    </NewOrganizationWizardWrapperHeaderContainer>
  );
};
