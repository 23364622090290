import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';

import { ContainerNameComponent } from './ContainerName.component';

interface ContainerNameContainerProps {
  containerId: string;
}

const ContainerNameContainer: FC<ContainerNameContainerProps> = ({ containerId }) => {
  const container = useSelector(R.selectors.bookingWizard.getContainerById(containerId));

  return (
    <ContainerNameComponent containerType={container.type} />
  );
};

export { ContainerNameContainer as ContainerName };
