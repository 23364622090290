import React, { memo } from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';
import { UC } from 'shipment-operations/controllers';

import { SubmitAmendmentsComponent } from './SubmitAmendments.component';

const SubmitAmendmentsContainer = memo(() => {
  const loading = useSelector(R.selectors.shipmentChanges.getActionLoading);

  return (
    <SubmitAmendmentsComponent
      loading={loading}
      onSubmit={UC.shipmentChanges.submitAmendments}
    />
  );
});

export { SubmitAmendmentsContainer as SubmitAmendments };
