import styled from 'styled-components';

export const TabsWrapper = styled.div`
  width: 70%;
  margin: 0 auto 20px;
`;

export const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 52px;
`;

export const SearchWrapper = styled.div`
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #4E5D70;
  margin-right: 20px;
  cursor: pointer;
  border-radius: 4px;
`;

export const GlobalLoader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
  width: 100%;
`;
