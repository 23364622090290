import { createSelector } from 'reselect';

import { RootState } from 'app-wrapper/store';

import { ShipmentRoutesType } from 'shipment-operations/constants';

const localState = (state: RootState) => state.shipmentTransportationLocation;

const getShipmentRoutes = createSelector(
  localState,
  (state) => state.routes,
);

const getLoading = createSelector(
  localState,
  (state) => state.isLoading,
);

const getOriginContainerYard = createSelector(
  getShipmentRoutes,
  (routes) => routes.find((item) => item.type === ShipmentRoutesType.ORIGIN_CONTAINER_YARD),
);

const getOriginContainerTerminal = createSelector(
  getShipmentRoutes,
  (routes) => routes.find((item) => item.type === ShipmentRoutesType.ORIGIN_CONTAINER_TERMINAL),
);

const getDestinationContainerYard = createSelector(
  getShipmentRoutes,
  (routes) => routes.find((item) => item.type === ShipmentRoutesType.DESTINATION_CONTAINER_YARD),
);

const getDestinationContainerTerminal = createSelector(
  getShipmentRoutes,
  (routes) => routes.find((item) => item.type === ShipmentRoutesType.DESTINATION_CONTAINER_TERMINAL),
);

export const shipmentTransportationLocationSelectors = {
  getDestinationContainerTerminal,
  getDestinationContainerYard,
  getLoading,
  getOriginContainerTerminal,
  getOriginContainerYard,
  getShipmentRoutes,
};
