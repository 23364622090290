import { ValidationErrorDTM, ValidationErrorType } from 'app-wrapper/types';

export const validateShipmentContainerNumber = (containerNumber?: string) => {
  if (!containerNumber || !containerNumber.match(/^[a-zA-Z]{4}\d{7}$/)) {
    return ValidationErrorDTM.fromPlain({
      type: ValidationErrorType.ALERT,
      message: 'shipmentContainerErrors.INVALID_CONTAINER_NUMBER',
    });
  }

  return undefined;
};
