import React, { FC, memo, useMemo } from 'react';
import { useLocation } from 'react-router';
import {
  MainContentRight, MainContentRightBg, MainContentSidebar,
} from 'app-wrapper/view/themes/mixin';

import { RouteNames } from 'app-wrapper/constants';
import { useWindowSize } from 'app-wrapper/hooks';
import { AlertBlock } from 'app-wrapper/view/components';

import { FreightQuoteFormRequest, RFQResponse, RCQNoQuoteErrorComponent } from 'monetary/view/components';
import { QuotaRFRRequestOptionsDTM } from 'monetary/models/dtm/Quotas';

import {
  AllRequestedSecondStepDiv,
  AllRequestedSecondStepFirst,
  AllRequestedSecondStepMainContentRightWrapperFullCollapse,
  AllRequestedSecondStepMainContentSidebarFullCollapse,
  AllRequestedSecondStepSecond,
  AllRequestedSecondStepWrapper,
} from './AllRequestedSecondStep.styled';

interface IAllRequestedSecondStepComponentProps {
  isCollapse?: boolean;
  isEmptyBg?: boolean;
  isCollapseAndEmptyQuota?: boolean;
  quotasCreateFailedMessage?: string;
  quotaId?: string;
  isQuotaEmptyError?: boolean;
  isLoadingCompleteStep1?: boolean;
  isLoadingCompleteStep2?: boolean;
  isLoadingCompleteStep3?: boolean;
  isLoadingCompleteStep4?: boolean;
  quotaRFRRequestOptions?: QuotaRFRRequestOptionsDTM;
  onSubmitReuseRequest?: () => void
  onClickOpenModal: () => void;
  onClickEmptyQuota: () => void;
  onClickModifyQuota: () => void;
}

const AllRequestedSecondStepComponent: FC<IAllRequestedSecondStepComponentProps> = (props) => {
  const {
    isCollapse,
    isEmptyBg,
    isCollapseAndEmptyQuota,
    quotasCreateFailedMessage,
    quotaId,
    isQuotaEmptyError,
    isLoadingCompleteStep1,
    isLoadingCompleteStep2,
    isLoadingCompleteStep3,
    isLoadingCompleteStep4,
    quotaRFRRequestOptions,
    onSubmitReuseRequest,
    onClickOpenModal,
    onClickEmptyQuota,
    onClickModifyQuota,
  } = props;
  const location = useLocation();
  const quotaSearchId = useMemo(() => {
    const url = new URLSearchParams(location.search);
    return url.get('quotaid');
  }, [location.search]);
  const { isFullMediaWidth } = useWindowSize();

  const quotasCreateFailedMessageComponent = useMemo(() => {
    if (isQuotaEmptyError) {
      return (
        <RCQNoQuoteErrorComponent
          onClickOpenModal={onClickOpenModal}
          onClickEmptyQuotaAndSchedule={onClickEmptyQuota}
          onClickModifyQuotaAndSchedule={onClickModifyQuota}
          onClickPullSchedule={onClickEmptyQuota}
          isOpenModalRate={!!quotaRFRRequestOptions?.isOpenModalRate}
          step1={!!quotaRFRRequestOptions?.step1}
          step2={quotaRFRRequestOptions?.step2}
          step3={quotaRFRRequestOptions?.step3}
          isLoading={quotaRFRRequestOptions?.isLoading}
        />
      );
    }

    return (quotasCreateFailedMessage
      ? (<AlertBlock type="warning" message={quotasCreateFailedMessage} />)
      : null);
  }, [quotasCreateFailedMessage, isQuotaEmptyError, quotaId, quotaRFRRequestOptions]);

  return (
    <AllRequestedSecondStepDiv>

      <AllRequestedSecondStepWrapper />
      <MainContentRightBg
        isActive={(isCollapse && !isFullMediaWidth) || isCollapse}
      />
      <AllRequestedSecondStepFirst>
        <AllRequestedSecondStepMainContentSidebarFullCollapse isCollapse={isCollapse} className="FreightQuoteContent">
          <FreightQuoteFormRequest
            stayUrl={RouteNames.ALL_REQUESTED_QUOTES()}
            quotaSearchId={quotaSearchId}
          />
        </AllRequestedSecondStepMainContentSidebarFullCollapse>
      </AllRequestedSecondStepFirst>
      <AllRequestedSecondStepSecond isEmptyBg={isEmptyBg} isCollapseAndEmptyQuota={isCollapseAndEmptyQuota}>
        <MainContentSidebar />
        <AllRequestedSecondStepMainContentRightWrapperFullCollapse>
          <MainContentRight>
            <RFQResponse
              isAllRequestPage={!!onSubmitReuseRequest}
              NoneContentRecommended={quotasCreateFailedMessageComponent}
              isLoadingCompleteStep1={isLoadingCompleteStep1}
              isLoadingCompleteStep2={isLoadingCompleteStep2}
              isLoadingCompleteStep3={isLoadingCompleteStep3}
              isLoadingCompleteStep4={isLoadingCompleteStep4}
            />
          </MainContentRight>
        </AllRequestedSecondStepMainContentRightWrapperFullCollapse>
      </AllRequestedSecondStepSecond>
    </AllRequestedSecondStepDiv>
  );
};

const CachedAllRequestedSecondStepComponent = memo(AllRequestedSecondStepComponent);

export { CachedAllRequestedSecondStepComponent as AllRequestedSecondStepComponent };
