import React, { FC, useMemo } from 'react';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';

import { Tooltip } from 'app-wrapper/view/components';
import { RouteNames } from 'app-wrapper/constants';
import { CargoBaseDTM } from 'shipment-operations/models/dtm';
import { IncompleteStub } from 'shipment-operations/view/components';
import { PackingGroupTypeShortNames } from 'shipment-operations/constants';
import { IMOClassShortTypeNames } from 'app-wrapper/types/IMOClassType';

import {
  SDLTableLayout,
  SDLTable,
  SDLTableCell,
  SDLTableHeader,
  SDLTableIncompleteCell,
  SDLTableCellEllipsis,
  SDLTableCellName,
} from './SBLHazmatTable.styled';

interface ISBLHazmatTableComponentProps {
  cargos: CargoBaseDTM[];
  isNested?: boolean;
}

const EMPTY_CELL = '-';

export const SBLHazmatTableComponent: FC<ISBLHazmatTableComponentProps> = ({
  cargos,
  isNested,
}) => {
  const { t } = useTranslation();
  const { shipmentId = '' } = useParams();

  const emergencyContactRedirectURL = useMemo(() => RouteNames.SHIPMENT_CARGO(shipmentId), [shipmentId]);

  const columns = useMemo(() => [
    {
      title: t('Proper Shipping Name'),
      dataIndex: 'shipping-name',
      key: 'shipping-name',
      width: isNested ? '22%' : '36%',
      render: (item: string, record: CargoBaseDTM | {}) => {
        const cargo = record as CargoBaseDTM;
        const title = cargo.shippingName ? cargo.shippingName : EMPTY_CELL;

        return (
          <Tooltip title={title} placement="top">
            <SDLTableCellName>
              <SDLTableCellEllipsis>
                {title}
              </SDLTableCellEllipsis>
            </SDLTableCellName>
          </Tooltip>
        );
      },
    },
    {
      title: t('UN Number'),
      dataIndex: 'un-number',
      key: 'un-number',
      width: '10%',
      render: (item: string, record: CargoBaseDTM | {}) => {
        const cargo = record as CargoBaseDTM;

        return (
          <SDLTableCell>
            {cargo.unNumber ? cargo.unNumber : EMPTY_CELL}
          </SDLTableCell>
        );
      },
    },
    {
      title: t('Class'),
      dataIndex: 'class',
      key: 'class',
      width: '6%',
      align: 'center' as 'center',
      render: (item: string, record: CargoBaseDTM | {}) => {
        const cargo = record as CargoBaseDTM;

        return (
          <SDLTableCell>
            {cargo.imoClass ? IMOClassShortTypeNames[cargo.imoClass] : EMPTY_CELL}
          </SDLTableCell>
        );
      },
    },
    {
      title: t('Packing Group'),
      dataIndex: 'packaging-group',
      key: 'packaging-group',
      width: '10%',
      align: 'center' as 'center',
      render: (item: string, record: CargoBaseDTM | {}) => {
        const cargo = record as CargoBaseDTM;

        return (
          <SDLTableCell>
            {cargo.packingGroup ? PackingGroupTypeShortNames[cargo.packingGroup] : EMPTY_CELL}
          </SDLTableCell>
        );
      },
    },
    {
      title: t('Emergency Contact Name'),
      dataIndex: 'emergency-contact-name',
      key: 'emergency-contact-name',
      width: isNested ? '18%' : '20%',
      align: 'right' as 'right',
      render: (item: string, record: CargoBaseDTM | {}) => {
        const cargo = record as CargoBaseDTM;

        return cargo.contactName ? (
          <SDLTableCell>
            {cargo.contactName}
          </SDLTableCell>
        ) : (
          <SDLTableIncompleteCell>
            <IncompleteStub redirectURL={emergencyContactRedirectURL} />
          </SDLTableIncompleteCell>
        );
      },
    },
    {
      title: t('Emergency Contact Number'),
      dataIndex: 'emergency-contact-number',
      key: 'emergency-contact-number',
      width: isNested ? '18%' : '16%',
      align: 'right' as 'right',
      render: (item: string, record: CargoBaseDTM | {}) => {
        const cargo = record as CargoBaseDTM;

        return cargo.contactNumber ? (
          <SDLTableCell>
            {cargo.contactNumber}
          </SDLTableCell>
        ) : (
          <SDLTableIncompleteCell>
            <IncompleteStub redirectURL={emergencyContactRedirectURL} />
          </SDLTableIncompleteCell>
        );
      },
    },
  ], [t, isNested]);

  return (
    <SDLTableLayout>
      {cargos.length ? (
        <>
          <SDLTableHeader isNested={isNested}>
            {t('Dangerous Goods')}
          </SDLTableHeader>
          <SDLTable
            dataSource={cargos}
            pagination={false}
            columns={columns}
          />
        </>
      ) : null}
    </SDLTableLayout>
  );
};
