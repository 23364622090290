import moment from 'moment/moment';
import { EMarkupPolicyStatus } from 'user-management/constants';
import { MarkupPolicyDTM } from 'user-management/models/dtm';
import { DateDtm } from 'app-wrapper/models/dtm';
import { downloadBlobAsFile } from 'app-wrapper/utils';
import { apiWorker } from 'app-wrapper/repository/utilsServices';

export class MarkupPolicyService {
  private baseUrl = 'rate-service/api/v1/markup/policies';

  public getMarkupPolicies = async (customerOrgId: string) => {
    const response = await apiWorker.requestGetBySchema(`${this.baseUrl}?organizationId=${customerOrgId}` as '/api/v1/markup/policies');

    return response.data.map((markupPolicy) => MarkupPolicyDTM.fromPlain({
      ...markupPolicy,
      id: markupPolicy.id as number,
      status: markupPolicy.status as EMarkupPolicyStatus,
      organizationId: markupPolicy.organizationId as number,
      createdBy: markupPolicy.createdBy as string,
      createdAt: markupPolicy.createdAt ? DateDtm.fromPlain({
        date: markupPolicy.createdAt,
        offset: moment.parseZone(markupPolicy.createdAt).utcOffset(),
      }) : undefined,
      validPeriod: {
        from: markupPolicy.validPeriod?.from ? DateDtm.fromPlain({
          date: markupPolicy.validPeriod?.from,
          offset: moment.parseZone(markupPolicy.validPeriod?.from).utcOffset(),
        }) : undefined,
        to: markupPolicy.validPeriod?.to ? DateDtm.fromPlain({
          date: markupPolicy.validPeriod?.to,
          offset: moment.parseZone(markupPolicy.validPeriod?.to).utcOffset(),
        }) : undefined,
      },
    }));
  };

  public uploadMarkupPolicyFile = async (file: File, customerId: string) => {
    const formData = new FormData();

    formData.append('file', file);
    formData.append('organizationId', customerId);

    await apiWorker.requestPost(`${this.baseUrl}/upload`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  public downloadMarkupPolicyFile = async (customerId: number) => {
    apiWorker.requestGet(`${this.baseUrl}/download?organizationId=${customerId}`, {
      responseType: 'blob',
    }).then((response) => {
      downloadBlobAsFile(response.data as Blob);
    });
  };
}
