import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import {
  UserContacts,
  UserData,
  UserOrganization,
  UserProfileScaffold,
  PartnerUserProfile,
} from './components';

interface IUserProfileComponentProps {
  onUpload: () => void;
  isCustomerOrg: boolean;
}

export const UserProfileComponent: FC<IUserProfileComponentProps> = ({ onUpload, isCustomerOrg }) => {
  const { t } = useTranslation();

  useEffect(() => {
    onUpload();
  }, []);

  if (isCustomerOrg) {
    return (
      <UserProfileScaffold
        title={t('Profile')}
        header={<UserData />}
        leftContentBox={<UserContacts />}
        rightContentBox={<UserOrganization />}
      />
    );
  }

  return <PartnerUserProfile />;
};
