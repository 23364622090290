import React from 'react';

import { R } from 'shipment-operations/repository';
import { useAppSelector } from 'app-wrapper/hooks';

import { FeesComponent } from 'shipment-operations/view/pages/CreateCreditNote/components/FirstStep/components/Fees/Fees.component';

const Fees = () => {
  const data = useAppSelector(R.selectors.createCreditNote.getFeesData);
  const sum = useAppSelector(R.selectors.createCreditNote.getFeesDataSum);

  return (
    <FeesComponent data={data[0]} sum={sum} />
  );
};

export { Fees };
