import { apiWorker } from 'app-wrapper/repository/utilsServices';

import { DocumentDTM, ShipmentDeclarationDTM, ShipmentDeclarationFileDTM } from 'shipment-operations/models/dtm';
import { DocumentType } from 'shipment-operations/constants';
import { GetShipmentDeclarationContract } from 'shipment-operations/models/contracts';
import moment from 'moment/moment';
import { DateDtm } from 'app-wrapper/models/dtm';

export class ShipmentDeclarationService {
  public getDeclarationByContainerId = async (shipmentId: number, containerId: number) => {
    try {
      const rawResponse = await apiWorker.requestGet<GetShipmentDeclarationContract>(`${this.containersUrl(shipmentId, containerId)}/declaration`);

      if (rawResponse.status === 200) {
        return ShipmentDeclarationDTM.fromPlain(
          {
            ...rawResponse.data,
            document: DocumentDTM.fromPlain({
              ...rawResponse.data.document,
              type: rawResponse.data.document.type as DocumentType,
              createdAt: DateDtm.fromPlain({
                date: rawResponse.data.document.createdAt,
                offset: moment().utcOffset(),
              }),
            }),
            shipmentId: Number(shipmentId),
            containerId: Number(containerId),
          },
        );
      }

      return undefined;
    } catch (e) {
      throw new Error('Something wrong, please try again: getDeclarationByContainerId');
    }
  };

  public getDeclarationsForContainers = async (shipmentId: number, containersIDs: number[]) => {
    const responses = await Promise.all(containersIDs.map((containerId) => this.getDeclarationByContainerId(shipmentId, containerId)));

    return responses;
  };

  public createContainerDeclaration = async (shipmentId: number, containerId: number, type: DocumentType, file: ShipmentDeclarationFileDTM) => {
    try {
      const rawResponse = await apiWorker.requestPost<ShipmentDeclarationDTM>(`${this.containersUrl(shipmentId, containerId)}/declaration`, {
        id: file.fileId,
        document: {
          id: file.fileId,
          name: file.name,
          type,
        },
      });

      return rawResponse.data;
    } catch (e) {
      throw new Error('Something wrong, please try again: createContainerDeclaration');
    }
  };

  public deleteContainerDeclaration = async (shipmentId: number, containerId: number) => {
    try {
      await apiWorker.requestDelete(`${this.containersUrl(shipmentId, containerId)}/declaration`);
    } catch (e) {
      throw new Error('Something wrong, please try again: deleteContainerDeclaration');
    }
  };

  private baseUrl = (shipmentId: number) => (
    `/shipment-service/api/v1/shipments/${shipmentId}`
  )

  private containersUrl = (shipmentId: number, containerId: number) => (
    `${this.baseUrl(shipmentId)}/containers/${containerId}`
  )
}
