import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { R } from 'monetary/repository';

import { UC } from 'shipment-operations/controllers';
import { PaymentFooter } from './Footer.component';

const FooterContainer = () => {
  const { invoiceType = '' } = useParams();
  const permissions = useSelector(R.selectors.moduleFunctionalityPermissions.getShipmentChargesPermissions);
  const editPayablesPermissions = useSelector(R.selectors.moduleFunctionalityPermissions.getChargesEditPayablesPaymentPermissions);

  return (
    <PaymentFooter
      onClick={UC.makePayment.openEditMode}
      buttonName="payment.edit"
      permissions={permissions}
      paymentType={invoiceType}
      editPayablesPermissions={editPayablesPermissions}
    />
  );
};

export { FooterContainer as Footer };
