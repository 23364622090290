import styled from 'styled-components';
import Panel from 'antd/es/collapse/CollapsePanel';

interface IPanelTitleWrapperProps {
  active: boolean
  withCheckboxes?: boolean
}

export const PanelTitleWrapper = styled.div<IPanelTitleWrapperProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${({ active }) => (active ? '#6C79DE' : '#202C3C')};
  font-size: 14px;
  font-weight: 400;
  width: 100%;
  margin-left: -8px;
`;

export const MainPanelSum = styled.span<IPanelTitleWrapperProps>`
  color: ${({ active }) => (active ? '#73819B' : '#202C3C')};
`;

export const ContainerTitleWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const ContainerType = styled.div<IPanelTitleWrapperProps>`
  height: 18px;
  background: ${({ active }) => (active ? '#6C79DE' : '#73819B')};
  color: white;
  box-sizing: border-box;
  border-right: 3px solid white;
  border-left: 3px solid white;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 3px solid ${({ active }) => (active ? '#6C79DE' : '#73819B')};
  border-bottom: 3px solid ${({ active }) => (active ? '#6C79DE' : '#73819B')};
  width: fit-content;
  padding: 0 3px;
  font-size: 10px;
  margin-right: 4px;
  font-weight: 700;
`;

export const StyledPanel = styled(Panel)`
  .ant-collapse-header {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .ant-collapse-content-box {
    padding-right: 0;
  }
  .ant-collapse-header {
    padding-right: 0 !important;
  }
`;

export const CheckboxWrapper = styled.div`
  display: flex;
`;
