enum Role {
  SHIPPER = 'SHIPPER',
  CONSIGNEE = 'CONSIGNEE',
  NOTIFY_PARTY = 'NOTIFY_PARTY',
  EXPORT_BROKER = 'EXPORT_BROKER',
  IMPORT_BROKER = 'IMPORT_BROKER',
  SECOND_NOTIFY_PARTY = 'SECOND_NOTIFY_PARTY',
}

export default Role;
