import styled from 'styled-components';

export const Wrapper = styled.div`
  margin-top: 8px;
  background-color: white;
  width: 100%;
  padding: 16px;
`;

export const Title = styled.p`
  font-weight: 500;
  font-size: 16px;
  color: #202C3C;
  margin: 0 0 14px 0;
`;
