import styled from 'styled-components';

import { colors } from 'app-wrapper/view/themes/themesColors';
import { Section, SectionHeader } from 'shipment-operations/view/pages/ShipmentShippingParties/components/ShippingPartiesForm/components/CompanySection/CompanySection.styled';
import { Label, Divider } from 'shipment-operations/view/pages/ShipmentShippingParties/components/ShippingPartiesForm/ShippingPartiesForm.styled';

export const AddressSection = styled(Section)`
  gap: 16px;
`;

export const AddressSectionHeader = styled(SectionHeader)`
  margin-bottom: 0px;
`;

export const SectionTitle = styled.span`
  font-size: 14px;
  line-height: 22px;
  font-weight: 500;
`;

export const LabelGrey = styled.span`
  color: ${colors.gray61};
`;

export { Label, Divider };
