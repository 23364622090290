import React, { FC, memo } from 'react';
import { useSelector } from 'react-redux';

import { UC } from 'monetary/controllers';
import { R } from 'monetary/repository';

import { RFRTabsComponent } from './RFRTabs.component';

const RFRTabsContainer: FC = () => {
  const {
    loadType,
  } = useSelector(R.selectors.freightQuote.getFreightQuoteCurrent);

  return (
    <RFRTabsComponent
      loadType={loadType || ''}
      onChangeFreightLoadType={UC.FreightQuote.onChangeFreightLoadType}
    />
  );
};

const RFRTabsContainerCache = memo(RFRTabsContainer);

export { RFRTabsContainerCache as RFRTabs };
