import React, {
  ChangeEvent,
  FC, useCallback,
} from 'react';

import {
  BookingDrawerCompanyNameShipperReference, BookingDrawerCompanyNameShipperReferenceInput,
} from './ShipperReferenceInput.styled';

interface IShippingPartiesCompanyNameComponentProps {
  reference?: string
  isError?: boolean
  onChangeReference: (value: string) => void
}

export const ShipperReferenceInputComponent: FC<IShippingPartiesCompanyNameComponentProps> = ({
  reference,
  isError,
  onChangeReference,
}) => {
  const onChangeReferenceHandle = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    onChangeReference(event.target.value);
  }, [onChangeReference]);

  return (
    <BookingDrawerCompanyNameShipperReference>
      <BookingDrawerCompanyNameShipperReferenceInput
        value={reference}
        onChange={onChangeReferenceHandle}
        error={isError}
      />
    </BookingDrawerCompanyNameShipperReference>
  );
};
