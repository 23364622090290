import styled from 'styled-components';

import { Button as BaseButton } from 'app-wrapper/view/components';
import { BaseContent } from 'shipment-operations/view/pages/ShipmentBillOfLading/components/MBLFlowManagement/MBLFlowManagement.styled';
import {
  FlexCol,
  BodyTextMedium,
  BodyTextRegular,
  TitleText,
} from 'app-wrapper/view/guideline';

export const Content = styled(BaseContent)`
  margin-bottom: 16px;
  gap: 20px;
`;

export const Image = styled.img`
  width: 60px;
  height: auto;
`;

export const Column = styled(FlexCol)`
  gap: 4px;
`;

export const Title = styled.span`
  ${BodyTextMedium}
`;

export const Text = styled.span`
  ${BodyTextRegular}
`;

export const Button = styled(BaseButton)`
  ${TitleText}
  font-weight: 400;
  margin-left: auto;
  height: 40px;

  &:disabled {
    background-color: #F4F5F8;
    border-color: #DEE1E9;
    color: #D1D7E2;
  }
`;
