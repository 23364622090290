import React from 'react';

import { IPropsIcon } from 'app-wrapper/interfaces/icon';

export const MessageResponseDialogDots = (props: IPropsIcon) => {
  const { width, height, fill } = props;
  return (
    <svg
      className="MessageResponseDialogDots"
      width={width || '30'}
      height={height || '24'}
      viewBox="0 0 30 24"
      fill={fill || 'currentColor'}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M13.7526 4.056C14.7877 4.056 15.6269 3.22684 15.6269 2.20402C15.6269 1.18121 14.7877 0.352051 13.7526 0.352051C12.7174 0.352051 11.8782 1.18121 11.8782 2.20402C11.8782 3.22684 12.7174 4.056 13.7526 4.056Z" fill="white" />
      <path fillRule="evenodd" clipRule="evenodd" d="M3.91311 3.82428H0.164429L2.07101 0.583496L3.91311 3.82428ZM6.25588 0.583496H9.53548V3.82428H6.25588V0.583496Z" fill="white" />
    </svg>
  );
};
