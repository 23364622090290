import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import CloseCircleOutlined from '@ant-design/icons/CloseCircleOutlined';
import FieldTimeOutlined from '@ant-design/icons/FieldTimeOutlined';
import ClockCircleOutlined from '@ant-design/icons/ClockCircleOutlined';
import EyeOutlined from '@ant-design/icons/EyeOutlined';
import BellOutlined from '@ant-design/icons/BellOutlined';
import { useParams } from 'react-router';

import { RouteNames } from 'app-wrapper/constants';
import themesColors from 'app-wrapper/view/themes/themesColors';
import { BellWarningGoldSvg } from 'app-wrapper/view/icons';

import { R } from 'app-wrapper/repository';
import { R as shipmentOperationsR } from 'shipment-operations/repository';
import { StatisticsTasksOverviewComponent } from './index';

const StatisticsTasksContainer = memo(() => {
  const {
    infoCountDueTasks,
    infoCountOverdueTasks,
    infoCountPlannedTasks,
    countImportantNotifications,
    countRegularNotifications,
    countUnreadNotifications,
  } = useSelector(R.selectors.overview.getOverview);
  const isLoading = useSelector(shipmentOperationsR.selectors.overview.getLoadingStatus);
  const { t } = useTranslation();
  const { shipmentId = '' } = useParams();

  return (
    <StatisticsTasksOverviewComponent
      isLoading={isLoading}
      firstHeaderNameTasks={t('Overdue Tasks')}
      secondHeaderNameTasks={t('Due')}
      secondHeaderSubNameTasks={t('Less Than 72 h')}
      thirdHeaderNameTasks={t('Planned')}
      firstHeaderLinkTasks={RouteNames.SHIPMENT_CENTER_TASKS(shipmentId)}
      secondHeaderLinkTasks={RouteNames.SHIPMENT_CENTER_TASKS(shipmentId)}
      thirdHeaderLinkTasks={RouteNames.SHIPMENT_CENTER_TASKS(shipmentId)}
      firstHeaderIconTasks={(<CloseCircleOutlined style={{ color: infoCountOverdueTasks ? themesColors.characterBrandingDanger : themesColors.secondaryDot45 }} />)}
      secondHeaderIconTasks={(<FieldTimeOutlined style={{ color: infoCountDueTasks ? themesColors.characterBrandingWarning : themesColors.secondaryDot45 }} />)}
      thirdHeaderIconTasks={(<ClockCircleOutlined style={{ color: themesColors.secondaryDot45 }} />)}
      firstHeaderCountTasks={`${infoCountOverdueTasks || 0}`}
      secondHeaderCountTasks={`${infoCountDueTasks || 0}`}
      thirdHeaderCountTasks={`${infoCountPlannedTasks || 0}`}
      firstHeaderNameNotifications={t('Unread Notifications')}
      secondHeaderNameNotifications={t('Important')}
      thirdHeaderNameNotifications={t('Regular')}
      firstHeaderLinkNotifications={RouteNames.SHIPMENT_CENTER_NOTIFICATION(shipmentId)}
      secondHeaderLinkNotifications={RouteNames.SHIPMENT_CENTER_NOTIFICATION(shipmentId)}
      thirdHeaderLinkNotifications={RouteNames.SHIPMENT_CENTER_NOTIFICATION(shipmentId)}
      firstHeaderIconNotifications={(<EyeOutlined style={{ color: themesColors.secondaryDot45 }} />)}
      secondHeaderIconNotifications={(<BellWarningGoldSvg style={{ color: countImportantNotifications ? themesColors.characterBrandingDanger : themesColors.secondaryDot45 }} />)}
      thirdHeaderIconNotifications={(<BellOutlined style={{ color: themesColors.secondaryDot45 }} />)}
      firstHeaderCountNotifications={`${countUnreadNotifications || 0}`}
      secondHeaderCountNotifications={`${countImportantNotifications || 0}`}
      thirdHeaderCountNotifications={`${countRegularNotifications || 0}`}
    />
  );
});

const StatisticsTasksCached = memo(StatisticsTasksContainer);

export { StatisticsTasksCached as StatisticsTasksOverview };
