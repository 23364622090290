import React from 'react';
import { IPropsIcon } from 'app-wrapper/interfaces/icon';

export const EmptySvg = ({ width, height }: IPropsIcon) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill="none"
    viewBox="0 0 109 60"
  >
    <path
      fill="url(#paint0_linear_12776_184505)"
      fillRule="evenodd"
      d="M25.314 11.149c1-3.144 1.93-5.102 2.79-5.874 2.379-2.136 5.67-.973 6.37-.807 2.457.587 1.658-3.273 3.766-4.057 1.405-.523 2.56.116 3.468 1.917.803-1.68 2.026-2.45 3.67-2.312 2.467.207 3.33 8.496 6.72 6.681 3.39-1.815 7.545-2.23 9.32.468.384.583.531-.322 3.141-3.413C67.17.661 69.771-.7 75.11 1.1c2.428.817 4.425 3.033 5.992 6.648 0 5.158 3.814 8.212 11.442 9.16 11.442 1.42 2.56 13.66-11.442 17.244-14.004 3.585-46.243 5.52-63.739-3.537C5.7 24.577 8.35 18.088 25.313 11.15z"
      clipRule="evenodd"
      opacity="0.8"
    />
    <path
      fill="url(#paint1_linear_12776_184505)"
      d="M54.004 56.734c11.652 0 21.098-2.176 21.098-4.859s-9.446-4.858-21.098-4.858-21.098 2.175-21.098 4.858 9.446 4.859 21.098 4.859z"
    />
    <path
      fill="url(#paint2_linear_12776_184505)"
      fillRule="evenodd"
      d="M89.625 57.937c-15.863 3.959-70.01-.569-75.773-2.637-2.805-1.007-5.333-2.9-7.584-5.682A2.11 2.11 0 016.79 46.5c.34-.213.733-.326 1.135-.325h94.618c1.964 5.203-2.342 9.124-12.918 11.763z"
      clipRule="evenodd"
      opacity="0.675"
    />
    <path
      fill="url(#paint3_linear_12776_184505)"
      d="M70.198 38.854l-7.215-8.394a1.835 1.835 0 00-1.385-.645H46.084a1.84 1.84 0 00-1.385.645l-7.215 8.394v4.608h32.714v-4.608z"
    />
    <path
      fill="url(#paint4_linear_12776_184505)"
      d="M68.156 43.632l-6.315-7.311a1.609 1.609 0 00-1.222-.544h-13.55c-.465 0-.92.192-1.223.544l-6.315 7.31v4.016h28.625v-4.015z"
    />
    <path
      fill="url(#paint5_linear_12776_184505)"
      d="M70.198 48.743c0 .64-.29 1.215-.746 1.6l-.093.076c-.362.273-.804.42-1.259.42H39.583c-.258 0-.504-.047-.732-.132l-.113-.045a2.093 2.093 0 01-1.254-1.92v-9.837h7.938c.876 0 1.583.718 1.583 1.588v.011c0 .872.714 1.575 1.591 1.575h10.49c.422 0 .826-.167 1.124-.463a1.58 1.58 0 00.468-1.117c0-.875.707-1.594 1.583-1.594h7.938v9.838z"
    />
    <ellipse
      cx="54.393"
      cy="18.652"
      fill="#fff"
      rx="4.533"
      ry="4.537"
    />
    <path
      fill="#FAAD14"
      d="M54.336 11.594a7 7 0 100 14 7 7 0 000-14zm-.5 3.625c0-.069.056-.125.125-.125h.75c.069 0 .125.056.125.125v4.25a.125.125 0 01-.125.125h-.75a.125.125 0 01-.125-.125v-4.25zm.5 6.875a.75.75 0 010-1.5.75.75 0 010 1.5z"
    />
    <defs>
      <linearGradient
        id="paint0_linear_12776_184505"
        x1="56.064"
        x2="56.064"
        y1="27.712"
        y2="-6.55"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#DEDEDE" stopOpacity="0" />
        <stop offset="1" stopColor="#A9A9A9" stopOpacity="0.3" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_12776_184505"
        x1="51.806"
        x2="51.806"
        y1="56.734"
        y2="47.017"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#fff" stopOpacity="0" />
        <stop offset="1" stopColor="#96A1C5" stopOpacity="0.373" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_12776_184505"
        x1="54.401"
        x2="54.401"
        y1="59.507"
        y2="43.55"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#fff" stopOpacity="0" />
        <stop offset="1" stopColor="#919191" stopOpacity="0.15" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_12776_184505"
        x1="53.841"
        x2="53.841"
        y1="29.815"
        y2="35.95"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#8390FA" />
        <stop offset="1" stopColor="#505BB5" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_12776_184505"
        x1="57.664"
        x2="57.664"
        y1="47.647"
        y2="35.146"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#DCE9FF" />
        <stop offset="1" stopColor="#A5AEE8" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_12776_184505"
        x1="53.842"
        x2="53.842"
        y1="38.905"
        y2="50.839"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#6C79DE" />
        <stop offset="1" stopColor="#C4D6FC" />
      </linearGradient>
    </defs>
  </svg>
);
