import React, { FC } from 'react';
import get from 'lodash/fp/get';
import { FieldErrors } from 'react-hook-form';

import { Errors, Error } from './Errors.styled';

export interface IErrorsProps {
  name: string
  errors: FieldErrors
}

const ErrorsComponent: FC<IErrorsProps> = ({ errors, name }) => {
  const fieldErrors = get(name, errors);
  const filteredErrors = fieldErrors
    ? Object.entries(fieldErrors.types)
    : [] as [key: string, value: string][];

  return (
    <Errors>
      {filteredErrors.map(([, error]) => (
        <Error key={error as string}>
          {error as string}
        </Error>
      ))}
    </Errors>
  );
};

export { ErrorsComponent as Errors };
