import React, { FC, useCallback } from 'react';
import Tooltip from 'antd/es/tooltip';

import { TooltipErrorWrapper } from './TooltipError.styled';

interface ITooltipErrorProps {
  message: string
  visible?: boolean
}

const TooltipErrorComponent: FC<ITooltipErrorProps> = (props) => {
  const { children, message, visible } = props;

  const getPopupContainer = useCallback(
    (triggerNode) => triggerNode.parentElement || document.body,
    [],
  );

  return (
    <TooltipErrorWrapper>
      <Tooltip
        title={message}
        placement="bottom"
        color="white"
        visible={visible}
        getPopupContainer={getPopupContainer}
      >
        {children}
      </Tooltip>
    </TooltipErrorWrapper>
  );
};

export { TooltipErrorComponent as TooltipError };
