import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  UserOutlined,
  MailOutlined,
  PhoneOutlined,
  SyncOutlined,
} from '@ant-design/icons';

import { Button } from 'app-wrapper/view/components';
import { OrganizationMemberDTM } from 'user-management/models/dtm';

import {
  Wrap,
  TeamName,
  TeamNameWrap,
  Manager,
  ManagerItem,
  ManagerItemContent,
  ManagerItemContentSubTitle,
  ManagerItemContentTitle,
  ManagerItemIcon,
} from './TeamView.styled';

interface ITeamViewProps {
  accountManager?: OrganizationMemberDTM;
  teamName?: string;
  changeTeam?: () => void;
  isAdmin?: boolean;
}

export const TeamView: FC<ITeamViewProps> = ({
  accountManager,
  teamName,
  changeTeam,
  isAdmin,
}) => {
  const { t } = useTranslation();

  return (
    <Wrap>
      {teamName && changeTeam ? (
        <TeamNameWrap>
          <TeamName>
            {teamName}
          </TeamName>

          {isAdmin ? (
            <Button
              onClick={changeTeam}
              icon={<SyncOutlined />}
            >
              {t('Update Team')}
            </Button>
          ) : null}
        </TeamNameWrap>
      ) : null}

      <Manager>
        <ManagerItem data-class="account-manager-name">
          <ManagerItemIcon>
            <UserOutlined />
          </ManagerItemIcon>

          <ManagerItemContent>
            <ManagerItemContentTitle>
              {t('Account Manager')}
            </ManagerItemContentTitle>

            <ManagerItemContentSubTitle>
              {accountManager ? accountManager.getFullName() : '-'}
            </ManagerItemContentSubTitle>
          </ManagerItemContent>
        </ManagerItem>

        <ManagerItem data-class="account-manager-email">
          <ManagerItemIcon>
            <MailOutlined />
          </ManagerItemIcon>

          <ManagerItemContent>
            <ManagerItemContentTitle>
              {t('Manager Email')}
            </ManagerItemContentTitle>

            <ManagerItemContentSubTitle>
              {accountManager?.email || '-'}
            </ManagerItemContentSubTitle>
          </ManagerItemContent>
        </ManagerItem>

        <ManagerItem data-class="account-manager-phone">
          <ManagerItemIcon>
            <PhoneOutlined />
          </ManagerItemIcon>

          <ManagerItemContent>
            <ManagerItemContentTitle>
              {t('Manager Phone')}
            </ManagerItemContentTitle>

            <ManagerItemContentSubTitle>
              {accountManager?.phone || '-'}
            </ManagerItemContentSubTitle>
          </ManagerItemContent>
        </ManagerItem>
      </Manager>
    </Wrap>
  );
};
