import { Type } from 'class-transformer';
import {
  IsDefined,
  IsNumber,
  IsEnum,
  IsString,
  ValidateNested, IsOptional,
} from 'class-validator';
import { BaseDTM } from 'proto/BaseDTM';

import {
  TOrganizationDTM,
  OrganizationDTM,
  TOrganizationMemberDTM,
  OrganizationMemberDTM,
} from 'user-management/models/dtm';
import { ETeamStatus } from 'user-management/constants';

export interface ITeamDTM {
  id?: number;
  status?: `${ETeamStatus}`;
  name: string;
  membersCount: number;
  customersCount: number;
  customers: TOrganizationDTM[];
  members: TOrganizationMemberDTM[];
}

export class TeamDTM extends BaseDTM<ITeamDTM> {
  @IsOptional()
  @IsNumber()
  id: number;

  @IsDefined()
  @IsString()
  name: string;

  @IsOptional()
  @IsEnum(ETeamStatus)
  status?: ETeamStatus;

  @IsDefined()
  @ValidateNested()
  @Type(() => OrganizationDTM)
  customers: OrganizationDTM[];

  @IsDefined()
  @ValidateNested()
  @Type(() => OrganizationMemberDTM)
  members: OrganizationMemberDTM[];

  @IsDefined()
  @IsNumber()
  customersCount: number;

  @IsDefined()
  @IsNumber()
  membersCount: number;
}
