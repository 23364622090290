import React, { FC } from 'react';

import {
  ShipmentDetailsListScroll,
  ShipmentDetailsListItems,
  ShipmentDetailsListSkeleton,
} from './ShipmentDetailsList.styled';

interface IShipmentDetailsList {
  isLoading?: boolean
}

export const ShipmentDetailsList: FC<IShipmentDetailsList> = ({
  children,
  isLoading = false,
}) => (
  <ShipmentDetailsListScroll>
    {
      isLoading ? <ShipmentDetailsListSkeleton /> : (
        <ShipmentDetailsListItems>
          {children}
        </ShipmentDetailsListItems>
      )
    }
  </ShipmentDetailsListScroll>
);
