import React from 'react';

import { R } from 'shipment-operations/repository';
import { useAppSelector } from 'app-wrapper/hooks';

import { ServicesComponent } from './Services.component';

const Services = () => {
  const tableData = useAppSelector(R.selectors.shipmentBillingInvoice.getServicesDataRest);
  const sum = useAppSelector(R.selectors.shipmentBillingInvoice.getServicesDataSum);
  const containers = useAppSelector(R.selectors.shipmentBillingInvoice.uniqServicesContainers);
  const data = useAppSelector(R.selectors.shipmentBillingInvoice.groupedServicesContainers);

  return (
    <ServicesComponent
      data={data}
      sum={sum}
      containers={containers}
      tableData={tableData}
    />
  );
};

export { Services };
