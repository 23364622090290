import themesColors from 'app-wrapper/view/themes/themesColors';
import styled from 'styled-components';
import AntLayout from 'antd/es/layout';

export const Sider = styled(AntLayout.Sider).attrs({
  'data-class': 'Sider',
})`
  height: 100%;
  background-color: ${themesColors.neutralBranding2};
  
  .ant-layout-sider-children {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: transparent;
    border-right: 1px solid #f0f0f0;
  }
`;
