import React from 'react';
import { useSelector } from 'react-redux';

import { R } from 'user-management/repository';
import { UC as shipmentOperationsUC } from 'shipment-operations/controllers';
import { UC } from 'user-management/controllers';

import { DocumentsComponent } from './Documents.component';

const DocumentsContainer = () => {
  const organizationId = useSelector(R.selectors.newOrganizationDocuments.getOrganizationId);
  const documentsTypesMap = useSelector(R.selectors.newOrganizationDocuments.getDocumentsTypesMap);
  const isBLICRequired = useSelector(R.selectors.newOrganizationDocuments.getShouldRequireBLICDocument);

  return (
    <DocumentsComponent
      organizationId={organizationId}
      openDocumentUploadDrawer={shipmentOperationsUC.shipmentDrawer.openNewOrganizationDocumentUploadDrawer}
      documentTypesMap={documentsTypesMap}
      onDownload={UC.newOrganizationDocuments.downloadDocumentById}
      onDelete={UC.newOrganizationDocuments.deleteDocumentWithUpdate}
      isBLICRequired={isBLICRequired}
    />
  );
};

export { DocumentsContainer as Documents };
