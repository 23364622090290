export const IMOClass: Array<{
  name: string;
  value: string;
}> = [
  {
    name: 'Class 2',
    value: 'CLASS_2',
  },
  {
    name: 'Class 2.1',
    value: 'CLASS_2_1',
  },
  {
    name: 'Class 2.2',
    value: 'CLASS_2_2',
  },
  {
    name: 'Class 2.3',
    value: 'CLASS_2_3',
  },
  {
    name: 'Class 3',
    value: 'CLASS_3',
  },
  {
    name: 'Class 4',
    value: 'CLASS_4',
  },
  {
    name: 'Class 4.1',
    value: 'CLASS_4_1',
  },
  {
    name: 'Class 4.2',
    value: 'CLASS_4_2',
  },
  {
    name: 'Class 4.3',
    value: 'CLASS_4_3',
  },
  {
    name: 'Class 5',
    value: 'class_5',
  },
  {
    name: 'Class 5.1',
    value: 'CLASS_5_1',
  },
  {
    name: 'Class 5.2',
    value: 'CLASS_5_2',
  },
  {
    name: 'Class 6',
    value: 'CLASS_6',
  },
  {
    name: 'Class 6.1',
    value: 'CLASS_6_1',
  },
  {
    name: 'Class 6.2',
    value: 'CLASS_6_2',
  },
  {
    name: 'Class 8',
    value: 'CLASS_8',
  },
  {
    name: 'Class 9',
    value: 'CLASS_9',
  },
];

export const Class = '';
