import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { UC } from 'shipment-operations/controllers';
import { R } from 'shipment-operations/repository';
import { PermissionAttributePolicy } from 'shipment-operations/constants';

import { ShippingPartiesAddressLineComponent } from './ShippingPartiesAddressLine.component';

const ShippingPartiesAddressLineContainer: FC = () => {
  const address = useSelector(R.selectors.shippingParties.getSelectedAddress);
  const currentAddress = useSelector(R.selectors.shippingParties.getSelectedCurrentAddress);
  const addressList = useSelector(R.selectors.shippingParties.getAddressList);
  const addressError = useSelector(R.selectors.shippingParties.getAddressErrorFromSelectedForm);
  const isSelectedCompanyRelatesToUser = useSelector(R.selectors.shippingParties.getIsSelectedCompanyRelatesToUser);

  return (
    <ShippingPartiesAddressLineComponent
      addressList={addressList}
      availability={PermissionAttributePolicy.WRITE}
      selectedAddress={address}
      selectedCurrentAddress={currentAddress}
      addressError={addressError}
      setAddress={UC.shippingParties.setAddress}
      onBlur={UC.shippingParties.validateAddress}
      openAddAddressDrawer={UC.bookingWizardDrawer.openSPAddAddressDrawer}
      isSelectedCompanyRelatesToUser={isSelectedCompanyRelatesToUser}
    />
  );
};

export {
  ShippingPartiesAddressLineContainer as ShippingPartiesAddressLine,
};
