import React, {
  ChangeEvent, FC, memo, useCallback,
} from 'react';
import { useTranslation } from 'react-i18next';

import {
  Button, FilterCheckboxElement, FilterRadioElement, InputSearch,
} from 'app-wrapper/view/components';
import {
  AppFiltersCheckboxGroupDTM, IAppFiltersCheckboxDTM, IAppFiltersCheckboxGroupDTM, IAppFiltersRadioDTM,
} from 'app-wrapper/models/dtm';

import ClearOutlined from '@ant-design/icons/ClearOutlined';
import {
  SLWSortBy, SLWStatus, SLWTitle, ShipmentListFiltersWrap, FiltersContainer, InputSearchContainer,
} from './ShipmentListFilters.styled';

interface IShipmentListFiltersComponentProps {
  onApplyFilterLocation: () => void
  onChangeFilterLocation: (id: number, value: AppFiltersCheckboxGroupDTM) => void
  onResetFilterLocation: () => void
  onApplyFilterShippingParty: () => void
  onChangeFilterShippingParty: (id: number, value: AppFiltersCheckboxGroupDTM) => void
  onResetFilterShippingParty: () => void
  onApplyFilterShippingCarrier: () => void
  onChangeFilterShippingCarrier: (id: number, value: AppFiltersCheckboxGroupDTM) => void
  onResetFilterShippingCarrier: () => void
  onApplyFilterContainerType: () => void
  onChangeFilterContainerType: (id: number, value: AppFiltersCheckboxGroupDTM) => void
  onResetFilterContainerType: () => void
  onApplyFilterStatus: () => void
  onChangeFilterStatus: (id: number, value: AppFiltersCheckboxGroupDTM) => void
  onResetFilterStatus: () => void
  onApplyFilterSortBy: () => void
  onChangeFilterSortBy: (value: IAppFiltersCheckboxGroupDTM) => void
  onSearch: (value: string) => void
  filterLocation?: IAppFiltersCheckboxDTM
  filterShippingParty?: IAppFiltersCheckboxDTM
  filterShippingCarrier?: IAppFiltersCheckboxDTM
  filterContainerType?: IAppFiltersCheckboxDTM
  filterStatus?: IAppFiltersCheckboxDTM
  filterSortBy?: IAppFiltersRadioDTM
  searchValue: string
  clearAllFilters: () => void
}

const ShipmentListFiltersComponent: FC<IShipmentListFiltersComponentProps> = (props) => {
  const {
    onApplyFilterLocation,
    onChangeFilterLocation,
    onResetFilterLocation,
    onApplyFilterShippingParty,
    onChangeFilterShippingParty,
    onResetFilterShippingParty,
    onApplyFilterShippingCarrier,
    onChangeFilterShippingCarrier,
    onResetFilterShippingCarrier,
    onApplyFilterContainerType,
    onChangeFilterContainerType,
    onResetFilterContainerType,
    onApplyFilterStatus,
    onChangeFilterStatus,
    onResetFilterStatus,
    onApplyFilterSortBy,
    onChangeFilterSortBy,
    onSearch,
    filterLocation,
    filterShippingParty,
    filterShippingCarrier,
    filterContainerType,
    filterStatus,
    filterSortBy,
    searchValue,
    clearAllFilters,
  } = props;
  const { t } = useTranslation();

  const handleSearch = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    onSearch(event.target.value);
  }, []);

  const handleClearAllFilters = useCallback(() => {
    clearAllFilters();
  }, []);

  const isClearAllVisible = filterLocation && (!filterLocation?.disableReset
    || !filterShippingParty?.disableReset
    || !filterShippingCarrier?.disableReset
    || !filterContainerType?.disableReset
    || !filterStatus?.disableReset);

  return (
    <ShipmentListFiltersWrap>
      <FiltersContainer>
        <SLWTitle>
          {t('Filters')}
        </SLWTitle>
        {filterLocation && (
        <FilterCheckboxElement
          name={t('Location')}
          isRight
          onApply={onApplyFilterLocation}
          onReset={onResetFilterLocation}
          group={filterLocation.group || []}
          disabledReset={!!filterLocation.disableReset}
          onChangeGroup={onChangeFilterLocation}
        />
        )}
        {filterShippingParty && (
        <FilterCheckboxElement
          name={t('ShippingParty')}
          onApply={onApplyFilterShippingParty}
          onReset={onResetFilterShippingParty}
          group={filterShippingParty.group || []}
          disabledReset={!!filterShippingParty.disableReset}
          onChangeGroup={onChangeFilterShippingParty}
        />
        )}
        {filterShippingCarrier && (
        <FilterCheckboxElement
          name={t('Carrier')}
          onApply={onApplyFilterShippingCarrier}
          onReset={onResetFilterShippingCarrier}
          group={filterShippingCarrier.group || []}
          disabledReset={!!filterShippingCarrier.disableReset}
          onChangeGroup={onChangeFilterShippingCarrier}
        />
        )}
        {filterContainerType && (
        <FilterCheckboxElement
          name={t('Container Type')}
          onApply={onApplyFilterContainerType}
          onReset={onResetFilterContainerType}
          group={filterContainerType.group || []}
          disabledReset={!!filterContainerType.disableReset}
          onChangeGroup={onChangeFilterContainerType}
        />
        )}
        {filterStatus?.group && filterStatus?.group?.length > 1 && (
        <SLWStatus>
          <FilterCheckboxElement
            name={t('Status')}
            onApply={onApplyFilterStatus}
            onReset={onResetFilterStatus}
            group={filterStatus.group || []}
            disabledReset={!!filterStatus.disableReset}
            onChangeGroup={onChangeFilterStatus}
          />
        </SLWStatus>
        )}

        { isClearAllVisible && (
        <div data-class="SLWClearAll" style={{ paddingLeft: '33px' }}>
          <Button onClick={handleClearAllFilters} size="small" type="default" icon={<ClearOutlined />}>
            {t('Clear All')}
          </Button>
        </div>
        ) }

      </FiltersContainer>
      <FiltersContainer>
        <InputSearchContainer>
          <InputSearch
            size="small"
            placeholder={t('SearchByReference')}
            onChange={handleSearch}
            value={searchValue}
          />
        </InputSearchContainer>
        <SLWSortBy>
          {filterSortBy && (
            <FilterRadioElement
              valueGroup={filterSortBy.value || ''}
              name={t('SortBy')}
              onApply={onApplyFilterSortBy}
              group={filterSortBy.group || []}
              disabledReset={!filterSortBy.isUpdate}
              onChangeGroup={onChangeFilterSortBy}
            />
          )}
        </SLWSortBy>
      </FiltersContainer>
    </ShipmentListFiltersWrap>
  );
};

const ShipmentListFiltersComponentCached = memo(ShipmentListFiltersComponent);

export { ShipmentListFiltersComponentCached as ShipmentListFiltersComponent };
