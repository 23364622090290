import styled from 'styled-components';

import { Input, Select } from 'app-wrapper/view/components';
import { colors } from 'app-wrapper/view/themes/themesColors';
import { divFlex } from 'app-wrapper/view/themes/mixin';

export const ContainerNumberSelectWrapper = styled.div.attrs({
  'data-class': 'ContainerNumberSelectWrapper',
})`
  width: 100%;
  font-size: 12px;
  color: ${colors.gray80};
`;

export const ContainerNumberSelect = styled(Select).attrs({
  'data-class': 'ContainerNumberSelect',
})`
  margin-top: 4px;
  width: 100%;
  height: 32px;
  min-height: 32px;
  font-size: 12px;

  .ant-select-item-empty {
    min-height: unset;
  }

  &.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
    min-height: 32px;
    height: 32px;

    & .ant-select-selection-item {
      line-height: 32px;
    }
  }

  &.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-placeholder {
    line-height: 32px;
  }
`;

export const ContainerNumberNotFound = styled.span.attrs({
  'data-class': 'ContainerNumberNotFound',
})`
  color: ${({ theme }) => theme.themesColors.neutralBranding13};
  margin-top: 4px;
  width: 100%;
  height: 32px;
  min-height: 32px;
  font-size: 12px;
`;

export const ContainerNumberFooter = styled.div.attrs({
  'data-class': 'ContainerNumberFooter',
})`
  ${divFlex}
  align-items: center;
  z-index: 10;
  height: 32px;

  margin-top: 4px;
  margin-bottom: 8px;
  padding: 5px 12px;
  color: ${({ theme }) => theme?.themesColors?.primaryBranding6};
`;

export const ContainerNumberFooterManualNumber = styled(Input).attrs({
  'data-class': 'ContainerNumberFooterManualNumber',
})`
  max-width: 200px;
  width: 60%;
  height: 32px;
  min-height: 32px;
  font-size: 12px;

  input {
    border-color: ${({ theme }) => theme?.themesColors?.neutralBranding6};
  }
`;

export const ContainerNumberFooterTextWrap = styled.div.attrs({
  'data-class': 'ContainerNumberFooterTextWrap',
})`
  ${divFlex}
  align-items: center;

  margin-left: 14px;
`;

export const ContainerNumberFooterText = styled.div.attrs({
  'data-class': 'ContainerNumberFooterText',
})`
  ${divFlex}

  margin-left: 8px;
  font-size: 12px;
  font-weight: 400;
  color: ${({ theme }) => theme?.themesColors?.primaryBranding6};
`;

export const ContainerNumberTextManual = styled.div.attrs({
  'data-class': 'ContainerNumberTextManual',
})`
  ${divFlex}
  display: inline-flex;

  margin-left: 4px;
  font-size: 12px;
  font-weight: 400;
  color: ${({ theme }) => theme?.themesColors?.secondaryDot45};
`;
