import React, { FC, useMemo } from 'react';

import { OrganizationDTM } from 'user-management/models/dtm';
import { Tooltip } from 'app-wrapper/view/components';

import {
  Customers,
  HiddenCustomers,
  NoCustomersIcon,
} from './TeamCustomers.styled';

interface ITeamCustomersProps {
  customers: OrganizationDTM[];
}

export const TeamCustomers: FC<ITeamCustomersProps> = ({ customers }) => {
  const [hiddenCustomers, visibleCustomers] = useMemo(() => {
    const hidden: OrganizationDTM[] = [...customers];
    const visible: OrganizationDTM[] = hidden.splice(0, 3);

    return [hidden, visible];
  }, [customers]);

  const visibleCustomersNames = useMemo(() => visibleCustomers.reduce((accumulator, currentValue) => (!accumulator.length ? `${currentValue.name}` : `${accumulator}, ${currentValue.name}`), ''), [visibleCustomers]);
  const hiddenCustomersNames = useMemo(() => hiddenCustomers.reduce((accumulator, currentValue) => (!accumulator.length ? `${currentValue.name}` : `${accumulator}, ${currentValue.name}`), ''), [hiddenCustomers]);

  return (
    <Customers>
      {customers.length ? (
        <>
          {visibleCustomersNames}

          {hiddenCustomers.length ? (
            <Tooltip
              title={hiddenCustomersNames}
              placement="topLeft"
            >
              <HiddenCustomers>
                {`+${hiddenCustomers.length}`}
              </HiddenCustomers>
            </Tooltip>
          ) : null}
        </>
      ) : <NoCustomersIcon />}
    </Customers>
  );
};
