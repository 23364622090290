import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { OrganizationReviewDTM } from 'user-management/models/dtm';
import { EReviewReason } from 'user-management/constants';

import { Wrap } from './RejectedScreens.styled';
import {
  ContactUs,
  OutsideServiceableRegion,
  SecurityOrSafetyConcerns,
  OrganizationAlreadyExists,
} from './components';

interface RejectedScreensComponentProps {
  review?: OrganizationReviewDTM;
}

export const RejectedScreensComponent: FC<RejectedScreensComponentProps> = ({ review }) => {
  const { t } = useTranslation();

  if (!review || !review.reason) {
    return null;
  }

  const { reason } = review;

  return (
    <Wrap>
      {reason === EReviewReason.OUTSIDE_SERVICEABLE_REGION ? <OutsideServiceableRegion note={review?.note} /> : null}
      {reason === EReviewReason.SECURITY_CONCERNS ? <SecurityOrSafetyConcerns note={review?.note} /> : null}
      {reason === EReviewReason.ALREADY_EXISTS ? <OrganizationAlreadyExists note={review?.note} /> : null}
      {reason === EReviewReason.OTHER ? t('Rejected for OTHER reason') : null}

      <ContactUs />
    </Wrap>
  );
};
