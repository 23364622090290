import React from 'react';

export const EmailSvg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
    <path d="M43.7027 18.3955H4.31641V35.3549H43.7027V18.3955Z" fill="#253368" />
    <path d="M22.5121 3.97376L4.33203 18.3941L42.6691 19.386C42.7515 19.3859 42.8336 19.3767 42.914 19.3586C43.3951 19.2379 43.7306 18.8897 43.7251 18.3941L24.9654 3.92653C24.2197 3.44142 23.2578 3.48865 22.5121 3.97376Z" fill="#253368" />
    <path d="M4.66406 44.0891L23.9827 33.6504" stroke="white" strokeWidth="5.4" />
    <path d="M43.3968 44.1365L24.0781 33.5562" stroke="white" strokeWidth="5.4" />
    <path d="M38.786 15.938H9.31906C9.09539 15.938 8.91406 16.1193 8.91406 16.343V36.0797C8.91406 36.3034 9.09539 36.4847 9.31906 36.4847H38.786C39.0096 36.4847 39.191 36.3034 39.191 36.0797V16.343C39.191 16.1193 39.0096 15.938 38.786 15.938Z" fill="white" />
    <path d="M25.0655 33.4698C24.415 33.6871 23.7008 33.5952 23.1264 33.2205L4.28516 18.394V43.3712C4.28516 43.9243 4.83002 44.3727 5.41257 44.3727H42.6689C43.2524 44.3727 43.7255 43.9243 43.7255 43.3712V18.394L25.7334 33.102C25.5339 33.2626 25.3079 33.3871 25.0655 33.4698Z" fill="#CCD7EE" />
    <path d="M33.3086 18.9136H23.4909C23.2673 18.9136 23.0859 19.0949 23.0859 19.3186V20.0201C23.0859 20.2437 23.2673 20.4251 23.4909 20.4251H33.3086C33.5322 20.4251 33.7136 20.2437 33.7136 20.0201V19.3186C33.7136 19.0949 33.5322 18.9136 33.3086 18.9136Z" fill="#ADC6FF" />
    <path d="M33.3086 21.9365H23.4909C23.2673 21.9365 23.0859 22.1178 23.0859 22.3415V23.043C23.0859 23.2667 23.2673 23.448 23.4909 23.448H33.3086C33.5322 23.448 33.7136 23.2667 33.7136 23.043V22.3415C33.7136 22.1178 33.5322 21.9365 33.3086 21.9365Z" fill="#ADC6FF" />
  </svg>
);
