import { createSelector } from 'reselect';
import uniqBy from 'lodash/fp/uniqBy';
import groupBy from 'lodash/fp/groupBy';
import partition from 'lodash/fp/partition';
import isEmpty from 'lodash/fp/isEmpty';

import { RootState } from 'app-wrapper/store';
import {
  ChargesHistoryAction,
  ChargesTypes,
  occurrenceAdditional,
  priceByBol,
  priceByContainer,
  typeFee,
} from 'shipment-operations/constants';
import {
  ChargeDTM,
  ChargeVersionsDTM,
  ChargeViewDtm,
  ContainerViewDTM,
} from 'shipment-operations/models/dtm';

const countTotalData = (data: ChargeDTM[]) => {
  const counter = data.reduce((acc, cur) => ({
    ...acc,
    apTotalCost: acc.apTotalCost + (cur.buyTotalCost || 0),
    arTotalCost: acc.arTotalCost + (cur.totalCost || 0),
    profit: acc.profit + cur.profitAmount,
    totalCost: acc.totalCost + (cur.totalCost || 0),
    buyTotalCost: acc.buyTotalCost + (cur.buyTotalCost || 0),
  }), {
    apTotalCost: 0,
    arTotalCost: 0,
    profit: 0,
    totalCost: 0,
    buyTotalCost: 0,
  });
  const totalPercentage = ((counter.arTotalCost - counter.apTotalCost) / counter.apTotalCost) * 100;
  return ({
    ...counter,
    key: 1,
    profitPercentage: totalPercentage,
  });
};

const parseDataForExpandTable = (data: ChargeDTM[], containers: ContainerViewDTM[]) => {
  const preparedContainers = containers.map((item) => {
    const filteredData = data.filter((elem) => elem.container.id === item.id && elem.active);

    const counter = filteredData.reduce((acc, cur) => ({
      ...acc,
      apBudget: acc.apBudget + (cur?.apBudget?.totalCost || 0),
      apTotalCost: acc.apTotalCost + (cur.buyTotalCost || 0),
      arBudget: acc.arBudget + (cur?.arBudget?.totalCost || 0),
      arTotalCost: acc.arTotalCost + (cur.totalCost || 0),
      profit: acc.profit + cur.profitAmount,
      totalCost: acc.totalCost + (cur.totalCost || 0),
      buyTotalCost: acc.buyTotalCost + (cur.buyTotalCost || 0),
    }), {
      apBudget: 0,
      apTotalCost: 0,
      arBudget: 0,
      arTotalCost: 0,
      profit: 0,
      totalCost: 0,
      buyTotalCost: 0,
    });

    const totalPercentage = ((counter.arTotalCost - counter.apTotalCost) / counter.apTotalCost) * 100;

    const preparedItem = (ChargeViewDtm.fromPlain({
      key: item.id,
      description: item.number,
      type: item.type,
      profitPercentage: totalPercentage === Infinity ? '' : totalPercentage,
      ...counter,
    }));
    if (preparedItem.isValid()) {
      return preparedItem;
    }
    console.error('parseDataForExpandTable function try to return invalid value');
    return null;
  });
  return preparedContainers.filter((item) => item) as ChargeViewDtm[];
};

export const generateHistoryTitle = (isCreated: boolean, isRemoved: boolean) => {
  if (isCreated) {
    return ChargesHistoryAction.CREATED;
  }
  if (isRemoved) {
    return ChargesHistoryAction.REMOVED;
  }
  return ChargesHistoryAction.UPDATED;
};

export const slicedVersions = (versions: ChargeVersionsDTM[]) => {
  const index = versions.slice().reverse().findIndex((item) => item.active);
  if (index !== -1) {
    return versions.slice(versions.length - 1 - index);
  }
  return [];
};

export const prepareChargeHistory = (arVersions?: ChargeVersionsDTM[], apVersions?: ChargeVersionsDTM[], additional?: boolean) => {
  if (isEmpty(arVersions) && isEmpty(apVersions)) {
    return [];
  }
  if ((arVersions && arVersions.length < 1) && (apVersions && apVersions.length < 1)) {
    return [];
  }
  const sortedAr = arVersions ? arVersions.sort((a, b) => (b.createdAt > a.createdAt ? 1 : -1)) : [];
  const sortedAp = apVersions ? apVersions.sort((a, b) => (b.createdAt > a.createdAt ? 1 : -1)) : [];
  const slicedAr = slicedVersions(sortedAr);
  const slicedAp = slicedVersions(sortedAp);
  let preparedArVersions = slicedAr ? slicedAr.map((item, index) => ({
    ...item,
    type: ChargesTypes.AR,
    action: generateHistoryTitle(index === (slicedAr.length - 1), item.removed),
    deltaCostPerUnit: index === (slicedAr.length - 1) ? 0 : item.costPerUnit - (slicedAr[index + 1]?.costPerUnit || 0),
    deltaNumberOfUnits: index === (slicedAr.length - 1) ? 0 : item.numberOfUnits - (slicedAr[index + 1]?.numberOfUnits || 0),
    deltaTotalCost: index === (slicedAr.length - 1) ? 0 : item.totalCost - (slicedAr[index + 1]?.totalCost || 0),
  })) : [];
  let preparedApVersions = slicedAp ? slicedAp.map((item, index) => ({
    ...item,
    type: ChargesTypes.AP,
    action: generateHistoryTitle(index === (slicedAp.length - 1), item.removed),
    deltaCostPerUnit: index === (slicedAp.length - 1) ? 0 : item.costPerUnit - (slicedAp[index + 1]?.costPerUnit || 0),
    deltaNumberOfUnits: index === (slicedAp.length - 1) ? 0 : item.numberOfUnits - (slicedAp[index + 1]?.numberOfUnits || 0),
    deltaTotalCost: index === (slicedAp.length - 1) ? 0 : item.totalCost - (slicedAp[index + 1]?.totalCost || 0),
  })) : [];
  const combined = [...preparedArVersions, ...preparedApVersions].sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1));
  if (additional) {
    return combined;
  }
  if (preparedArVersions.length <= 1) {
    preparedArVersions = [];
  }
  if (preparedApVersions.length <= 1) {
    preparedApVersions = [];
  }
  if ([...preparedArVersions, ...preparedApVersions].length <= 1) {
    return [];
  }
  return combined;
};

const parseChargeView = (data: ChargeDTM[]) => data.map((item) => (ChargeViewDtm.fromPlain({
  key: item.id,
  active: item.active,
  description: item.chargeCode.description,
  apBudget: (item?.apBudget?.totalCost || 0),
  apTotalCost: item.buyTotalCost || 0,
  arBudget: (item?.arBudget?.totalCost || 0),
  arTotalCost: item.totalCost || 0,
  profit: item.profitAmount,
  profitPercentage: item.profitPercent,
  unitType: item.measureBy,
  buyCostPerUnit: item.buyCostPerUnit,
  buyQuantity: item.buyNumberOfUnits,
  buyTotalCost: item.buyTotalCost,
  costPerUnit: item.costPerUnit,
  quantity: item.numberOfUnits,
  totalCost: item.totalCost,
  history: prepareChargeHistory(item.arVersions, item.apVersions, item.additional),
})));

const localState = (state: RootState) => state.shipmentTransportationCharges;

const getIsLoading = createSelector(
  localState,
  (state) => state.isLoading,
);

const getIsError = createSelector(
  localState,
  (state) => state.error,
);

const getIsPercentage = createSelector(
  localState,
  (state) => state.isPercentage,
);

const getNotFilteredCharges = createSelector(
  localState,
  (state) => state.data,
);

const getCharges = createSelector(
  getNotFilteredCharges,
  (charges) => charges.filter((item) => item.applied),
);

const getAdditionalData = createSelector(
  getCharges,
  (charges) => partition((item) => item.additional, charges),
);

const getServicesData = createSelector(
  getAdditionalData,
  (data) => partition((item) => item.chargeCode.occurrence === occurrenceAdditional, data[1]),
);

const getFeesData = createSelector(
  getServicesData,
  (data) => partition((item) => (item.chargeCode.type === typeFee || item.priceBy === priceByBol), data[1]),
);

const getTransportationData = createSelector(
  getFeesData,
  (data) => data[1],
);

const getNotAppliedCharges = createSelector(
  getNotFilteredCharges,
  (charges) => charges.filter((item) => !item.applied),
);

const getFeesDataForTable = createSelector(
  getFeesData,
  (data) => parseChargeView(data[0]),
);

const getFeesDataTotal = createSelector(
  getFeesData,
  (data) => {
    const filteredData = data[0].filter((item) => item.active);
    return countTotalData(filteredData);
  },
);

const getTransportationContainers = createSelector(
  getTransportationData,
  (data) => (data.length ? data.map((item) => ContainerViewDTM.fromPlain({
    number: item.container?.number,
    type: item.container?.type,
    id: item.container?.id,
  })) : []),
);

const uniqTransportationContainers = createSelector(
  getTransportationContainers,
  (containers) => uniqBy('id', containers),
);

const getTransportationContainersTable = createSelector(
  getTransportationData,
  uniqTransportationContainers,
  (data, containers) => parseDataForExpandTable(data, containers),
);

const groupedTransportationContainers = createSelector(
  getTransportationData,
  (data) => groupBy((item) => item.container.id, data),
);

const getTransportationDataTotal = createSelector(
  getTransportationData,
  (data) => {
    const filteredData = data.filter((item) => item.active);
    return countTotalData(filteredData);
  },
);

const getServicesContainersData = createSelector(
  getServicesData,
  (charges) => charges[0].filter((item) => item.priceBy === priceByContainer),
);

const getServicesDataRest = createSelector(
  getServicesData,
  (charges) => charges[0].filter((item) => item.priceBy !== priceByContainer),
);

const getPreparedServicesContainersData = createSelector(
  getServicesContainersData,
  (data) => (data.length ? data.map((item) => (ContainerViewDTM.fromPlain({
    number: item.container?.number,
    type: item.container?.type,
    id: item.container?.id,
  }))) : []),
);

const uniqServicesContainers = createSelector(
  getPreparedServicesContainersData,
  (containers) => uniqBy('id', containers),
);

const groupedServicesContainers = createSelector(
  getServicesContainersData,
  (data) => groupBy((item) => item.container.id, data),
);

const getServicesContainersTable = createSelector(
  getServicesContainersData,
  uniqServicesContainers,
  (data, containers) => parseDataForExpandTable(data, containers),
);

const getServicesDataForTable = createSelector(
  getServicesDataRest,
  (data) => parseChargeView(data),
);

const getServicesDataTotal = createSelector(
  getServicesData,
  (data) => {
    const filteredData = data[0].filter((item) => item.active);
    return countTotalData(filteredData);
  },
);

const getAdditionalContainersData = createSelector(
  getAdditionalData,
  (data) => data[0].filter((item) => item.priceBy === priceByContainer),
);

const getAdditionalRestData = createSelector(
  getAdditionalData,
  (data) => data[0].filter((item) => item.priceBy !== priceByContainer),
);

const getAdditionalContainers = createSelector(
  getAdditionalContainersData,
  (data) => (data.length ? data.map((item) => (ContainerViewDTM.fromPlain({
    number: item.container?.number,
    type: item.container?.type,
    id: item.container?.id,
  }))) : []),
);

const uniqAdditionalContainers = createSelector(
  getAdditionalContainers,
  (containers) => uniqBy('id', containers),
);

const groupedAdditionalContainers = createSelector(
  getAdditionalContainersData,
  (data) => groupBy((item) => item.container.id, data),
);

const getAdditionalContainersTable = createSelector(
  getAdditionalContainersData,
  uniqAdditionalContainers,
  (data, containers) => parseDataForExpandTable(data, containers),
);

const getAdditionalDataForTable = createSelector(
  getAdditionalRestData,
  (data) => parseChargeView(data),
);

const getAdditionalDataTotal = createSelector(
  getAdditionalData,
  (data) => {
    const filteredData = data[0].filter((item) => item.active);
    return countTotalData(filteredData);
  },
);

const getAppliedCharges = createSelector(
  getNotFilteredCharges,
  (charges) => charges.filter((item) => item.applied),
);

const getBuyTotalCost = createSelector(
  getAppliedCharges,
  (charges) => charges.reduce((acc, cur) => acc + cur.buyTotalCost, 0),
);

const getTotalCost = createSelector(
  getAppliedCharges,
  (charges) => charges.reduce((acc, cur) => acc + cur.totalCost, 0),
);

const getTotalProfit = createSelector(
  getBuyTotalCost,
  getTotalCost,
  (buyTotalCost, totalCost) => totalCost - buyTotalCost,
);

const getTotalProfitPercent = createSelector(
  getTotalProfit,
  getBuyTotalCost,
  (profit, totalCost) => (profit / totalCost) * 100,
);

const getArBudgetTotalCost = createSelector(
  getAppliedCharges,
  (charges) => {
    const filtered = charges.filter((item) => !item.additional);
    return filtered.reduce((acc, cur) => acc + (cur.arBudget?.totalCost || 0), 0);
  },
);

const getApBudgetTotalCost = createSelector(
  getAppliedCharges,
  (charges) => {
    const filtered = charges.filter((item) => !item.additional);
    return filtered.reduce((acc, cur) => acc + (cur.apBudget?.totalCost || 0), 0);
  },
);

const getReceivablesDifferent = createSelector(
  getTotalCost,
  getArBudgetTotalCost,
  (totalCost, arBudget) => totalCost - arBudget,
);

const getPayablesDifferent = createSelector(
  getBuyTotalCost,
  getApBudgetTotalCost,
  (totalCost, arBudget) => totalCost - arBudget,
);

const getReceivablesDifferentPercent = createSelector(
  getReceivablesDifferent,
  getArBudgetTotalCost,
  (delta, arBudget) => (delta / arBudget) * 100,
);

const getPayablesDifferentPercent = createSelector(
  getPayablesDifferent,
  getApBudgetTotalCost,
  (delta, arBudget) => (delta / arBudget) * 100,
);

export const shipmentTransportationChargesSelectors = {
  getIsError,
  getIsLoading,
  getFeesData,
  getFeesDataForTable,
  uniqTransportationContainers,
  getTransportationContainersTable,
  groupedTransportationContainers,
  groupedServicesContainers,
  getServicesContainersTable,
  getServicesDataForTable,
  groupedAdditionalContainers,
  getAdditionalContainersTable,
  getAdditionalDataForTable,
  getFeesDataTotal,
  getTransportationDataTotal,
  getServicesDataTotal,
  getAdditionalDataTotal,
  getNotAppliedCharges,
  getCharges,
  getNotFilteredCharges,
  getIsPercentage,
  getTotalProfit,
  getTotalProfitPercent,
  getTotalCost,
  getArBudgetTotalCost,
  getReceivablesDifferent,
  getReceivablesDifferentPercent,
  getPayablesDifferent,
  getPayablesDifferentPercent,
  getBuyTotalCost,
  getApBudgetTotalCost,
};
