import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { UC } from 'shipment-operations/controllers';
import { R } from 'shipment-operations/repository';

import { LocationData } from '../LocationData';

export const OriginContainerYard: FC = () => {
  const locationData = useSelector(R.selectors.shipmentTransportationLocation.getOriginContainerYard);
  const isLoading = useSelector(R.selectors.shipmentTransportationLocation.getLoading);
  const countriesList = useSelector(R.selectors.shipmentTransportationLocationData.getCountriesList);
  const permissions = useSelector(R.selectors.shipment.getTransportationPermissions);

  return (
    <LocationData
      title="Container Yard"
      subTitle="Empty Container Pick-up"
      releaseDateLabel="Earliest CY Empty Release"
      isLoading={isLoading}
      locationData={locationData}
      countriesList={countriesList}
      onEdit={UC.shipmentDrawer.openShipmentTransportationLocationOriginContainerYard}
      locationsAvailability={permissions.locationsAvailability}
    />
  );
};
