import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { InnerItem } from 'shipment-operations/view/components';
import { GapHorizontalContainerStyled } from 'app-wrapper/view/guideline';
import { SkeletonSection } from 'app-wrapper/view/components/Skeleton/Skeleton.component';

interface IDashboardsCustomerComponentProps {
  totalInvoiced: number
  totalInvoicedPercent: number
  totalPaid: number
  totalPaidPercent: number
  loading: boolean
  buyTotalCost: number
  apBudgetTotalCost: number
  payablesDifferent: number,
  payablesDifferentPercent: number
}

const DashboardsCustomerComponent: FC<IDashboardsCustomerComponentProps> = ({
  totalInvoiced, totalInvoicedPercent, totalPaid, totalPaidPercent, loading,
  buyTotalCost, apBudgetTotalCost, payablesDifferent, payablesDifferentPercent,
}) => {
  const { t } = useTranslation();

  return (
    <GapHorizontalContainerStyled>
      {
        loading
          ? (
            <>
              <SkeletonSection height="53px" />
              <SkeletonSection height="53px" />
              <SkeletonSection height="53px" />
            </>
          )
          : (
            <>
              <InnerItem
                title={t('Invoiced')}
                mainValue={totalInvoiced}
                withProgressBar
                progressValue={totalInvoicedPercent}
              />
              <InnerItem
                title={t('paid')}
                mainValue={totalPaid}
                withProgressBar
                progressValue={totalPaidPercent}
              />
              <InnerItem
                title={t('totalPrice')}
                mainValue={buyTotalCost}
                withBudget
                budgetPlan={apBudgetTotalCost}
                difference={payablesDifferent}
                differencePercent={payablesDifferentPercent}
                payable
              />
            </>
          )
      }
    </GapHorizontalContainerStyled>
  );
};

export { DashboardsCustomerComponent };
