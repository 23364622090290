import styled from 'styled-components';
import AntLayout from 'antd/es/layout';

export const ShipmentHeaderContainer = styled(AntLayout.Header).attrs({
  'data-class': 'ShipmentHeaderContainer',
})`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0;
  height: 76px;

  padding: 12px 24px;

  overflow: hidden;

  line-height: inherit !important;

  color: inherit;
  background-color: white;
  box-shadow: inset 0px -1px 0px #F4F5F8;

  div[data-class="SCDescriptionsContainerContent"] {
    span[data-class="SCDescriptionsContainerSpanClip"] {
      max-width: 95%;
    }
  }

  div[data-class="ShipmentCardSectionShipment"] {
    div[data-class="SCDescriptionsContainerContent"] {
      span[data-class="SCDescriptionsContainerSpanClip"] {
        height: 19px;
      }
    }
  }
`;

export const ShipmentHeaderStatus = styled.span.attrs({
  'data-class': 'ShipmentHeaderStatus',
})`
  display: flex;
  flex-direction: row;
  align-self: flex-start;
  gap: 4px;

  margin-left: auto;

  white-space: nowrap;

  > .ant-badge-status-text {
    width: 0;
    margin: 0;
  }
`;

export const ShipmentHeaderStatusText = styled.span.attrs({
  'data-class': 'ShipmentHeaderStatusText',
})`
  overflow: hidden;
  max-width: 116px;
  text-overflow: ellipsis;
`;
