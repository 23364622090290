import { R } from 'monetary/repository';
import { UC } from 'monetary/controllers';
import React, {
  FC,
} from 'react';
import { useSelector } from 'react-redux';
import { ContainersComponent } from './Containers.component';

interface IContainersComponentProps {
  formLocalState: { isCollapsed?: boolean }
  setFormLocalState: React.Dispatch<{ isCollapsed?: boolean }>
  forceCollapse?: boolean
}

export const Containers: FC<IContainersComponentProps> = (({
  formLocalState,
  setFormLocalState,
  forceCollapse,
}) => {
  // New
  const {
    form,
    isSubmitVisited,
    isSubmitClicked,
    status,
    isAllFieldDisabled,
    isCollapse,
  } = useSelector(R.selectors.freightQuote.getFreightQuoteCurrent);
  const getFreightQuoteCurrentFormContainersValues = useSelector(R.selectors.freightQuote.getFreightQuoteCurrentFormContainersValues);
  const getErrors = useSelector(R.selectors.freightQuote.getFreightQuoteErrors);
  const getQuantitySummaryContainers = useSelector(R.selectors.freightQuote.getQuantitySummaryContainers);

  const quantityInfoBlock = (getQuantitySummaryContainers || 0) > 99;

  return (
    <ContainersComponent
      formLocalState={formLocalState}
      isAllDisabled={isAllFieldDisabled}
      setFormLocalState={setFormLocalState}
      forceCollapse={forceCollapse}
      quantityInfoBlock={quantityInfoBlock}
      isCollapsed={isCollapse}
      // new
      isMetric={form?.containers?.isMetric}
      socControl={form?.containers?.socControl}
      gensetControl={form?.containers?.gensetControl}
      containersValues={getFreightQuoteCurrentFormContainersValues}
      errorsContainers={getErrors?.containers?.values}
      isContainersError={getErrors?.containers?.isContainersError}
      isSubmitVisited={isSubmitVisited}
      isSubmitClicked={isSubmitClicked}
      quotaStatus={status}
      temperatureControl={form?.commodity?.temperatureControl}
      onChangeContainersIsMetric={UC.FreightQuote.onChangeContainersIsMetric}
      onChangeContainersGensetControl={UC.FreightQuote.onChangeContainersGensetControl}
      onChangeContainersSocControl={UC.FreightQuote.onChangeContainersSocControl}
      onAddContainersValuesItem={UC.FreightQuote.onAddContainersValuesItem}
      onRemoveContainersValuesItem={UC.FreightQuote.onRemoveContainersValuesItem}
      // Type
      onChangeContainersType={UC.FreightQuote.onChangeContainersType}
      onClearContainersType={UC.FreightQuote.onClearContainersType}
      onFocusContainersType={UC.FreightQuote.onFocusContainersType}
      onBlurContainersType={UC.FreightQuote.onBlurContainersType}
      // Quantity
      onChangeContainersQuantity={UC.FreightQuote.onChangeContainersQuantity}
      onFocusContainersQuantity={UC.FreightQuote.onFocusContainersQuantity}
      onBlurContainersQuantity={UC.FreightQuote.onBlurContainersQuantity}
      // Weight
      onChangeContainersWeight={UC.FreightQuote.onChangeContainersWeight}
      onFocusContainersWeight={UC.FreightQuote.onFocusContainersWeight}
      onBlurContainersWeight={UC.FreightQuote.onBlurContainersWeight}
      // Volume
      onChangeContainersVolume={UC.FreightQuote.onChangeContainersVolume}
      onFocusContainersVolume={UC.FreightQuote.onFocusContainersVolume}
      onBlurContainersVolume={UC.FreightQuote.onBlurContainersVolume}
    />
  );
});
