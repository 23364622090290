import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { InputStyled } from './DescriptionInput.styled';

interface DescriptionInputComponentProps {
  description: string;
}

export const DescriptionInputComponent: FC<DescriptionInputComponentProps> = ({
  description,
}) => {
  const { t } = useTranslation();

  return (
    <InputStyled
      placeholder={t('Enter description')}
      value={description}
      disabled
    />
  );
};
