import React, {
  FC,
  memo,
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import FilterFilled from '@ant-design/icons/FilterFilled';

import { QUOTAS_STATUS } from 'app-wrapper/constants';
import {
  AppFiltersCheckboxGroupDTM, IAppFiltersCheckboxGroupDTM,
} from 'app-wrapper/models/dtm';
import { EmptyStateForPages, TypeSwitch } from 'app-wrapper/view/components';

import {
  IFreightQuotaContentDTM, IFreightQuotasStateDTM,
} from 'monetary/models/dtm';
import { getCarrierSCACNamesRFQ } from 'monetary/constants';
import { RFQLoaderComponent } from 'monetary/view/components';

import { TermsAndConditionsDrawerContainer } from 'app-wrapper/view/pages/Overview/components/StepsManager/components';
import themesColors from 'app-wrapper/view/themes/themesColors';
import { SkeletonRFQ } from 'app-wrapper/view/components/Skeleton/Skeleton.component';
import { RFQItem } from '../RFQItem';
import {
  RFQContentDiv,
  RFQContentWrapper,
  RFQContentWrapperHeader,
  RFQContentWrapperHeaderFilters,
  RFQContentWrapperHeaderFiltersItem,
  RFQContentWrapperHeaderFiltersCheckboxItem,
  RFQContentWrapperHeaderFound,
  RFQContentWrapperInfiniteScroll,
  RFQContentWrapperHeaderSchedule,
  RFQContentWrapperHeaderScheduleButton,
} from './RFQContent.styled';
import { RFQNotFoundRecommended } from '../RFQNotFoundRecommended';

interface IFilterMinMax {
  filterMin: string
  filterMax: string
  limitMin: number
  limitMax: number
  disabledReset: boolean
  isUpdate: boolean
  onChangeFilterMin: (filterMin: string) => void
  onChangeFilterMax: (filterMax: string) => void
  onApply: () => void
  onReset: () => void
}

interface IFilterCheckbox {
  group: IAppFiltersCheckboxGroupDTM[]
  disabledReset: boolean
  isUpdate: boolean
  onChangeGroup: (id: number, value: AppFiltersCheckboxGroupDTM) => void
  onApply: () => void
  onReset: () => void
}

export interface IRFQContentComponentProps {
  NoneContentRecommended?: React.ReactNode
  isRequestFilters?: boolean
  isVisibleCheckSchedule?: boolean
  isVisibleIsIncludeRelatedPortRequest?: boolean
  isFooter?: boolean
  requestFiltersStatus?: string
  isAllRequestPage?: boolean
  foundText?: string
  filters?: {
    isRequestFilters?: boolean
    isHaveFiltersParams?: boolean
    hideFilters?: boolean
    filterIsFavorite?: boolean
    filterIsIncludeRelatedPortRequest?: boolean
    visibleFilterIsFavorite?: boolean
    onChangeFilterIsFavorite: (value: boolean) => void
    onChangeFilterIsIncludeRelatedPortRequest: (value: boolean) => void
    filterPrice?: IFilterMinMax
    filterTransitTime?: IFilterMinMax
    carrier?: IFilterCheckbox
  }
  // new
  quotaRequestServiceByIdData?: IFreightQuotaContentDTM[]
  quotaRequestServiceById?: IFreightQuotasStateDTM
  fullCompleteStatus?: string
  isLoadingCompleteStep1?: boolean;
  isLoadingCompleteStep2?: boolean;
  isLoadingCompleteStep3?: boolean;
  hideBook?: boolean
  hideFavorite?: boolean
  onRunRFQContentComponent: () => void
  onScrollNextInfinityPage: () => void
}

const RFQContentComponent: FC<IRFQContentComponentProps> = (props) => {
  const {
    NoneContentRecommended,
    foundText,
    filters,
    quotaRequestServiceByIdData,
    quotaRequestServiceById,
    fullCompleteStatus,
    isRequestFilters,
    isVisibleCheckSchedule,
    isVisibleIsIncludeRelatedPortRequest,
    isFooter,
    requestFiltersStatus,
    isAllRequestPage,
    isLoadingCompleteStep1,
    isLoadingCompleteStep2,
    isLoadingCompleteStep3,
    hideBook,
    hideFavorite,
    onRunRFQContentComponent,
    onScrollNextInfinityPage,
  } = props;
  const { t } = useTranslation();
  const [isRunScroll, setIsRunScroll] = useState(false);

  useEffect(() => {
    setIsRunScroll(false);
    onRunRFQContentComponent();
  }, []);

  const nextScrollOnHandler = useCallback(
    () => {
      if (!isRunScroll) setIsRunScroll(true);
      onScrollNextInfinityPage();
    },
    [isRunScroll, onScrollNextInfinityPage],
  );

  const scrollOnOpenDetails = useCallback(
    (top) => {
      document.getElementsByClassName('infinite-scroll-component')[0].scroll({
        top,
        behavior: 'smooth',
      });
    },
    [],
  );

  const onApplyPriceHandler = useCallback(() => {
    filters?.filterPrice?.onApply();
  }, [filters?.filterPrice?.onApply]);

  const isVisibleHeader = !!quotaRequestServiceByIdData?.length
    && quotaRequestServiceById?.quotasOption?.fullCompleteStatus !== QUOTAS_STATUS.failed;
  const isVisibleTotalElements = isVisibleHeader && !!quotaRequestServiceById?.totalElements && !filters?.visibleFilterIsFavorite;

  const isVisibleFilters = (isRequestFilters && ((
    (filters?.filterPrice?.isUpdate
      || filters?.filterTransitTime?.isUpdate
      || filters?.carrier?.isUpdate)
  )
    || filters?.isHaveFiltersParams
    || (isVisibleHeader && !!quotaRequestServiceById?.totalElements)));

  const isVisibleIsIncludeRelatedPortRequestFlag = (isVisibleIsIncludeRelatedPortRequest && !!quotaRequestServiceByIdData?.length)
    || (
      filters?.isRequestFilters
      && isVisibleIsIncludeRelatedPortRequest
      && !quotaRequestServiceByIdData?.length
    );

  const filterPriceComponent = useMemo(() => (
    <>
      {!filters?.hideFilters && filters?.filterPrice && (
        <RFQContentWrapperHeaderFiltersItem
          name={t('price')}
          onApply={onApplyPriceHandler}
          onReset={filters.filterPrice.onReset}
          valueMin={filters.filterPrice.filterMin}
          valueMax={filters.filterPrice.filterMax}
          disabledReset={filters.filterPrice.disabledReset}
          placeholderMin={`${t('min')} $ ${filters.filterPrice.limitMin || ''}`}
          placeholderMax={`${t('max')} $ ${filters.filterPrice.limitMax || ''}`}
          onChangeMin={filters.filterPrice.onChangeFilterMin}
          onChangeMax={filters.filterPrice.onChangeFilterMax}
        />
      )}
    </>
  ), [filters, onApplyPriceHandler]);

  const isNoneContent = (
    (!quotaRequestServiceByIdData?.length
      && (
        fullCompleteStatus === QUOTAS_STATUS.complete
        || fullCompleteStatus === QUOTAS_STATUS.failed
      )
      && (
        NoneContentRecommended || <RFQNotFoundRecommended />
      )));

  const isEmptyFilters = filters?.isHaveFiltersParams
    && fullCompleteStatus !== QUOTAS_STATUS.pending
    && !quotaRequestServiceByIdData?.length;

  const onChangeFilterIsFavoriteHandler = useCallback(
    (value) => {
      if (filters?.onChangeFilterIsFavorite) {
        filters.onChangeFilterIsFavorite(value);
      }
    },
    [filters],
  );

  const onChangeFilterIsIncludeRelatedPortRequestHandler = useCallback(
    (value) => {
      if (filters?.onChangeFilterIsIncludeRelatedPortRequest) {
        filters.onChangeFilterIsIncludeRelatedPortRequest(!value);
      }
    },
    [filters],
  );

  const filtersGroup = useMemo<IAppFiltersCheckboxGroupDTM[]>(() => (filters?.carrier?.group?.map((item) => ({
    ...item,
    name: getCarrierSCACNamesRFQ(item.value),
  })) || []), [filters?.carrier?.group]);

  const isLoading = (requestFiltersStatus === QUOTAS_STATUS.pending)
    || quotaRequestServiceById?.quotasOption?.nextRequestStatus === QUOTAS_STATUS.pending;

  return (
    <RFQContentDiv>
      <TermsAndConditionsDrawerContainer />
      <RFQContentWrapper>
        <RFQContentWrapperHeader>
          <RFQContentWrapperHeaderFound>
            {isVisibleTotalElements && `${foundText || t('ResultsFound')}: ${quotaRequestServiceById?.totalElements || ''}`}
            {!filters?.hideFilters && filters?.visibleFilterIsFavorite && (
              <TypeSwitch
                onChange={onChangeFilterIsFavoriteHandler}
                data-class="RFQContentWrapperHeaderFiltersShowOnlyFavorites"
                rightText={t('ShowOnlyFavorites')}
                checkedChildren="On"
                unCheckedChildren="Off"
                value={!!filters?.filterIsFavorite}
                disabledbg="true"
                disabled={fullCompleteStatus === QUOTAS_STATUS.pending}
              />
            )}
            {isVisibleIsIncludeRelatedPortRequestFlag && (
              <TypeSwitch
                onChange={onChangeFilterIsIncludeRelatedPortRequestHandler}
                disabled={requestFiltersStatus === QUOTAS_STATUS.pending}
                data-class="RFQContentWrapperHeaderFiltersIsVisibleIsIncludeRelatedPortRequest"
                rightText={t('Hide Related Ports')}
                checkedChildren="On"
                unCheckedChildren="Off"
                value={!filters?.filterIsIncludeRelatedPortRequest}
                disabledbg="true"
              />
            )}
          </RFQContentWrapperHeaderFound>
          {isVisibleFilters && (
            <RFQContentWrapperHeaderFilters>
              {filterPriceComponent}
              {!filters?.hideFilters && filters?.filterTransitTime && (
                <RFQContentWrapperHeaderFiltersItem
                  name={t('transitTime')}
                  onApply={filters.filterTransitTime.onApply}
                  onReset={filters.filterTransitTime.onReset}
                  valueMin={filters.filterTransitTime.filterMin}
                  valueMax={filters.filterTransitTime.filterMax}
                  disabledReset={filters.filterTransitTime.disabledReset}
                  placeholderMin={`${t('min')} ${filters.filterTransitTime.limitMin || ''} ${t('Day(s)')}`}
                  placeholderMax={`${t('max')} ${filters.filterTransitTime.limitMax || ''} ${t('Day(s)')}`}
                  onChangeMin={filters.filterTransitTime.onChangeFilterMin}
                  onChangeMax={filters.filterTransitTime.onChangeFilterMax}
                />
              )}
              {!filters?.hideFilters && filters?.carrier && (
                <RFQContentWrapperHeaderFiltersCheckboxItem
                  name={t('carrier')}
                  onApply={filters.carrier.onApply}
                  onReset={filters.carrier.onReset}
                  group={filtersGroup || []}
                  disabledReset={filters.carrier.disabledReset}
                  onChangeGroup={filters.carrier.onChangeGroup}
                />
              )}
            </RFQContentWrapperHeaderFilters>
          )}
          {isVisibleCheckSchedule && (
            <RFQContentWrapperHeaderSchedule>
              <RFQContentWrapperHeaderScheduleButton type="default">
                {t('Check Schedules')}
              </RFQContentWrapperHeaderScheduleButton>
            </RFQContentWrapperHeaderSchedule>
          )}
        </RFQContentWrapperHeader>

        {!isEmptyFilters ? (
          <RFQContentWrapperInfiniteScroll>{(isNoneContent
                || (!!NoneContentRecommended
                  && fullCompleteStatus === QUOTAS_STATUS.pending
                  && (
                    <RFQLoaderComponent
                      nameLogo="Searching Quotes"
                      isLoadingCompleteStep1={isLoadingCompleteStep1}
                      isLoadingCompleteStep2={isLoadingCompleteStep2}
                      isLoadingCompleteStep3={isLoadingCompleteStep3}
                    />
                  )))
              || (
                <InfiniteScroll
                  dataLength={Number(quotaRequestServiceByIdData?.length || 0)}
                  next={nextScrollOnHandler}
                  hasMore
                  loader={
                    isLoading
                    && <SkeletonRFQ isLoading rowCount={1} />
                  }
                  scrollableTarget="scrollableDiv"
                  height={isFooter ? '76vh' : '83vh'}
                  style={{ gap: '18px', display: 'flex', flexDirection: 'column' }}
                >
                  <SkeletonRFQ isLoading={isLoading && quotaRequestServiceByIdData?.length === 0}>
                    {quotaRequestServiceByIdData?.map((item, index) => (
                      <RFQItem
                        isAllRequestPage={isAllRequestPage}
                        idIndex={index}
                        key={`quotaRequestServiceById_${item.id}_${index + 0}`}
                        onScroll={scrollOnOpenDetails}
                        hideBook={hideBook}
                        hideFavorite={hideFavorite}
                      />
                    ))}
                  </SkeletonRFQ>
                </InfiniteScroll>
              )}
          </RFQContentWrapperInfiniteScroll>
        )
          : (
            <EmptyStateForPages
              icon={(
                <FilterFilled
                  style={{
                    color: themesColors.neutralBranding65,
                    width: '25px',
                    height: '23px',
                  }}
                />
              )}
              headerText={t('No quotes found')}
              contentText={t('Filters maybe to restrictive.')}
              buttonText={t('Clear filters')}
            />
          )}
      </RFQContentWrapper>
    </RFQContentDiv>
  );
};

const cachedRFQContentComponent = memo(RFQContentComponent);

export { cachedRFQContentComponent as RFQContentComponent };
