import React, { FC, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ClearOutlined from '@ant-design/icons/ClearOutlined';
import Spin from 'antd/es/spin';

import { RatesListDtm } from 'monetary/models/dtm';
import { ERateTypes } from 'monetary/constants';
import { EOrganizationMemberRole } from 'user-management/constants';
import { Tooltip } from 'app-wrapper/view/components';
import { SpinnerWrap } from 'app-wrapper/view/guideline';

import {
  OceanRateDownloadModal,
  RateDownloadModal,
  RateItem,
  SurchargesRateDownloadModal,
} from './components';

import {
  RatesUploadContent, RatesUploadHeader, RatesUploadLayout, InnerHeader, RatesUploadClearWrap, RatesUploadButton,
} from './RatesUpload.styled';

interface IRatesUploadComponentProps {
  userOrgRole?: EOrganizationMemberRole;
  ratesList?: RatesListDtm
  isPending: boolean
  isAllowedClearCache?: boolean
  isDisableClearBtn?: boolean
  dataUpload: () => void
  onClear: () => void
  openOceanRatesDownload: (rateType: ERateTypes) => void
  openRatesDownload: (rateType: ERateTypes) => void
  openSurchargesRatesDownload: () => void
  onRatesClear: () => void
}

export const RatesUploadComponent: FC<IRatesUploadComponentProps> = ({
  ratesList,
  isPending,
  userOrgRole,
  isAllowedClearCache,
  isDisableClearBtn,
  dataUpload,
  onClear,
  openOceanRatesDownload,
  openRatesDownload,
  openSurchargesRatesDownload,
  onRatesClear,
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    dataUpload();

    return onClear;
  }, []);

  const onRatesClearHandler = useCallback(() => {
    onRatesClear();
  }, [onRatesClear]);

  const isPartner = userOrgRole === EOrganizationMemberRole.PARTNER;

  return (
    <RatesUploadLayout>
      <RatesUploadHeader>
        <InnerHeader>
          {t('Rates')}
        </InnerHeader>
        {isAllowedClearCache ? (
          <RatesUploadClearWrap>
            <Tooltip
              title={t('We send prices within an hour after uploading. You can use Clear Cache to fix your documents, but you will lose previously uploaded data within an hour.')}
              placement="leftBottom"
            >
              <RatesUploadButton
                type="ghost"
                icon={<ClearOutlined />}
                onClick={onRatesClearHandler}
                disabled={isDisableClearBtn}
              >
                {t('Clear Cache')}
              </RatesUploadButton>
            </Tooltip>
          </RatesUploadClearWrap>
        )
          : null}
      </RatesUploadHeader>

      {isPending ? (
        <SpinnerWrap>
          <Spin size="large" />
        </SpinnerWrap>
      ) : (
        <>
          {
            ratesList && (
              <RatesUploadContent>
                <RateItem
                  key={ERateTypes.OCEAN_BASE_RATE}
                  rateItem={ratesList[ERateTypes.OCEAN_BASE_RATE]}
                  openRatesDownload={openOceanRatesDownload}
                />
                <RateItem
                  key={ERateTypes.OCEAN_SURCHARGES}
                  rateItem={ratesList[ERateTypes.OCEAN_SURCHARGES]}
                  openRatesDownload={openSurchargesRatesDownload}
                />
                {!isPartner ? (
                  <>
                    <RateItem
                      key={ERateTypes.SERVICE_RATES}
                      rateItem={ratesList[ERateTypes.SERVICE_RATES]}
                      openRatesDownload={openRatesDownload}
                    />
                    <RateItem
                      key={ERateTypes.FEES}
                      rateItem={ratesList[ERateTypes.FEES]}
                      openRatesDownload={openRatesDownload}
                    />
                    <RateItem
                      key={ERateTypes.PENALTIES_AND_FREE_TIME}
                      rateItem={ratesList[ERateTypes.PENALTIES_AND_FREE_TIME]}
                      openRatesDownload={openRatesDownload}
                    />
                  </>
                ) : null}
              </RatesUploadContent>
            )
          }
        </>
      )}
      <RateDownloadModal />
      <OceanRateDownloadModal />
      <SurchargesRateDownloadModal />
    </RatesUploadLayout>
  );
};
