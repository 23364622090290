import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { R } from 'monetary/repository';
import { UC } from 'monetary/controllers';

import { ClientInformationComponent } from './ClientInformation.component';

interface IClientInformationContainerProps {
  formLocalState: { isCollapsed?: boolean };
  forceCollapse?: boolean;
}

const ClientInformationContainer: FC<IClientInformationContainerProps> = (props) => {
  const {
    formLocalState,
    forceCollapse,
  } = props;
  // New
  const {
    form, companyName, status, isAllFieldDisabled, isSubmitVisited,
  } = useSelector(R.selectors.freightQuote.getFreightQuoteCurrent);
  const getErrors = useSelector(R.selectors.freightQuote.getFreightQuoteErrors);

  return (
    <ClientInformationComponent
      formLocalState={formLocalState}
      forceCollapse={forceCollapse}
      isAllDisabled={isAllFieldDisabled}
      isSubmitVisited={isSubmitVisited}
      companyNameValue={form?.client?.companyName?.code || null}
      companyNameData={companyName?.valuesCompanyName?.dataValues || []}
      errorsClientInformation={getErrors?.client?.companyName}
      requestStatus={status}
      updateCompanyName={UC.FreightQuote.onChangeCompanyNameValue}
      clearCompanyName={UC.FreightQuote.onClearCompanyName}
      blurCompanyName={UC.FreightQuote.onBlurCompanyName}
      focusCompanyName={UC.FreightQuote.onFocusCompanyName}
    />
  );
};

export { ClientInformationContainer as ClientInformation };
