import { MenuInfo } from 'rc-menu/lib/interface';
import React, { FC, useCallback, useMemo } from 'react';
import { useParams } from 'react-router';

import { SHIPMENT_CONTAINERS } from 'app-wrapper/models/routes';

import { ContainerDTM } from 'shipment-operations/models/dtm';
import {
  CommonContainerTypes,
  ContainerReeferTypes, ContainerUsualTypes,
  ContainerBasicTypes,
  ContainerReeferTypesNamesLongConst,
  ContainerUsualTypesNamesLongConst,
} from 'shipment-operations/constants';

import {
  ShipmentContainersTabsBadge, ShipmentContainersTabsMenu, ShipmentContainersTabsMenuItem,
} from './ShipmentContainersTabs.styled';

interface IShipmentContainersTabsComponentProps {
  containers?: {
    [k in CommonContainerTypes]: ContainerDTM[]
  }
  containersBasicType?: ContainerBasicTypes
  selectedItem?: ContainerReeferTypes | ContainerUsualTypes
  setSelectedItem: (type: CommonContainerTypes, shipmentId?: string) => void
}

export const ShipmentContainersTabsComponent: FC<IShipmentContainersTabsComponentProps> = ({
  containersBasicType,
  containers,
  selectedItem,
  setSelectedItem,
}) => {
  const { shipmentId } = useParams<SHIPMENT_CONTAINERS>();
  const menuItems = useMemo(
    () => (
      containersBasicType === ContainerBasicTypes.USUAL
        ? ContainerUsualTypesNamesLongConst
        : ContainerReeferTypesNamesLongConst
    ),
    [containersBasicType],
  );

  const menuItemsWithData = useMemo(
    () => (
      containers && Object.entries(menuItems)
        .filter((item) => containers[item[0] as CommonContainerTypes].length !== 0)
    ),
    [containers, menuItems],
  );

  const menuItemClickHandler = useCallback((info: MenuInfo) => {
    if (!info) {
      return;
    }

    setSelectedItem(info.keyPath[0] as CommonContainerTypes, shipmentId);
  }, [menuItems]);

  if (!containersBasicType || !selectedItem) {
    return null;
  }

  return (
    <ShipmentContainersTabsMenu onClick={menuItemClickHandler} selectedKeys={[selectedItem]} mode="horizontal">
      {
        containers && menuItemsWithData && menuItemsWithData.map(([type, name]) => (
          <ShipmentContainersTabsMenuItem key={type}>
            {name}
            <ShipmentContainersTabsBadge
              count={containers[type as CommonContainerTypes].length}
              style={{
                backgroundColor: 'transparent',
                color: '#73819B',
                border: '1px solid #73819B',
              }}
            />
          </ShipmentContainersTabsMenuItem>
        ))
      }
    </ShipmentContainersTabsMenu>
  );
};
