import { IAccountDepartmentsStateStateDTM } from 'user-management/models/dtm';

export const AccountDepartmentsInitialState: IAccountDepartmentsStateStateDTM = {
  defaultState: {
    departmentList: [],
    isRequest: false,
  },
  updateState: {
    departmentList: [],
    isUpdate: false,
  },
};
