import styled from 'styled-components';

import themesColors from 'app-wrapper/view/themes/themesColors';

export const UserStatusWrapper = styled.div.attrs((props: {
  isActive: boolean
}) => ({
  isActive: props.isActive,
  'data-class': 'UserStatusWrapper',
}))`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  width: 100%;
  height: 100%;
  
  color: ${({ isActive }) => (isActive ? themesColors.neutralBranding10 : themesColors.secondaryDot45)};
`;
