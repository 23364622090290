import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ValidationErrorDTM } from 'app-wrapper/types';

import { InputStyled } from './PackQTYInput.styled';

interface PackQTYInputComponentProps {
  packagesQTY: string;
  error?: ValidationErrorDTM
}

export const PackQTYInputComponent: FC<PackQTYInputComponentProps> = ({
  packagesQTY,
  error,
}) => {
  const { t } = useTranslation();

  return (
    <InputStyled
      placeholder={t('Quantity')}
      value={packagesQTY}
      disabled
      error={!!error}
    />
  );
};
