import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { CustomModal } from 'shipment-operations/view/components';

import {
  TransportPlan,
  Carrier,
  DocumentSection,
  Commodity,
  TemperatureControl,
  Containers,
  Footer,
} from './components';
import {
  Content,
  Warning,
  WarningIcon,
  WarningText,
} from './ManualBookingDrawer.styled';

interface ManualBookingDrawerComponentProps {
  isOpen: boolean;
  onClose: () => void;
  init: (shipmentId: string) => void;
  shipmentId: string;
  errorMessage: string;
}

export const ManualBookingDrawerComponent: FC<ManualBookingDrawerComponentProps> = ({
  isOpen,
  onClose,
  shipmentId,
  init,
  errorMessage,
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (isOpen) {
      init(shipmentId);
    }
  }, [shipmentId, isOpen]);

  return isOpen ? (
    <CustomModal
      onClose={onClose}
      title={t('Manual Confirmation')}
      contentStyles={{
        left: 'unset',
        width: '1137px',
      }}
      childrenWrapperStyles={{
        position: 'relative',
        height: 'calc(100vh - 56px)',
        overflow: 'hidden',
      }}
    >
      <Content>
        {errorMessage ? (
          <Warning>
            <WarningIcon />
            <WarningText>
              {errorMessage}
            </WarningText>
          </Warning>
        ) : null}

        <Carrier />
        <TransportPlan />
        <DocumentSection />
        <Commodity />
        <TemperatureControl />
        <Containers />
      </Content>

      <Footer />
    </CustomModal>
  ) : null;
};
