import React from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';

import { SBLChargesTableComponent } from './SBLChargesTable.component';

const SBLChargesTableContainer = () => {
  const chargesHBL = useSelector(R.selectors.billOfLadingCommon.getHBLCharges);

  return (
    <SBLChargesTableComponent chargesItems={chargesHBL} />
  );
};

export { SBLChargesTableContainer as SBLChargesTable };
