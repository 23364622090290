import React from 'react';

import { useAppSelector } from 'app-wrapper/hooks';
import { R } from 'shipment-operations/repository';
import { UC } from 'shipment-operations/controllers';

import { MainContainerComponent } from './MainContainer.component';

const MainContainerContainer = () => {
  const success = useAppSelector(R.selectors.createInvoiceCharges.getCreateInvoiceSuccess);
  const isLoading = useAppSelector(R.selectors.shipmentCharges.getIsLoading);
  const error = useAppSelector(R.selectors.shipmentCharges.getError);
  const isOpenModal = useAppSelector(R.selectors.createInvoiceCharges.getIsOpenCreateInvoiceModal);
  const mode = useAppSelector(R.selectors.shipmentCharges.getTabOwner);

  return (
    <MainContainerComponent
      clearStore={UC.createInvoiceCharges.resetStore}
      toggleOpenModal={UC.createInvoiceCharges.toggleCreateInvoiceModal}
      success={success}
      loadCharges={UC.shipmentCharges.onLoadPage}
      onClean={UC.shipmentCharges.clean}
      isLoading={isLoading}
      error={error}
      isOpenModal={isOpenModal}
      mode={mode}
    />
  );
};

export { MainContainerContainer as MainContainer };
