import React, { FC, useCallback } from 'react';
import { SelectProps } from 'antd/es/select';

import {
  SelectSearchOption,
  // SelectSearchOptionDescription,
  SelectSearchOptionTitle,
  SelectSearchWrapper,
} from './SelectSearch.styled';

type BaseSelectProps = SelectProps<any>

interface ISelectSearch extends BaseSelectProps {
  optionsData?: {
    code: string
    description: string
  }[]
  hasError?: boolean
}

export const SelectSearch: FC<ISelectSearch> = (props) => {
  const {
    optionsData,
    hasError,
    ...other
  } = props;

  const getPopupContainerHandler = useCallback(
    (triggerNode) => triggerNode.parentElement || document.body,
    [],
  );

  return (
    <SelectSearchWrapper
      className="SelectSearch"
      hasError={hasError}
      // TODO: next validation task this
      // name={name}
      // value={value}
      // onChange={handleChange}
      // onBlur={handleBlur}
      // onSearch={showSearch ? handleOnSearch : undefined}
      getPopupContainer={getPopupContainerHandler}
      // size={size}
      {...other}
    >
      {
        optionsData?.map((itemState, indexState: number): React.ReactNode => (
          <SelectSearchOption
            key={`${itemState.code}_${indexState + 0}_hsCodeServiceState`}
            value={itemState.code}
            className="hsCode_option"
          >
            <SelectSearchOptionTitle className="hsCode_option_title">{itemState?.description || ''}</SelectSearchOptionTitle>
            {/* <SelectSearchOptionDescription className="hsCode_option_description">{itemState?.description || ''}</SelectSearchOptionDescription> */}
          </SelectSearchOption>
        ))
      }
    </SelectSearchWrapper>
  );
};
