import React from 'react';

import { useTranslation } from 'react-i18next';

import { StepTitle } from 'shipment-operations/view/pages/Booking/styled';

export const Containers = () => {
  const { t } = useTranslation();

  return (
    <StepTitle level={4}>{t('Containers')}</StepTitle>
  );
};
