import React from 'react';
import { useSelector } from 'react-redux';

import { R } from 'user-management/repository';
import { EOrganizationMemberRole } from 'user-management/constants';

import { hasReadAccess } from 'app-wrapper/utils';

import { AccountMenuComponent } from './AccountMenu.component';

const AccountMenuContainer = () => {
  const isInDraft = useSelector(R.selectors.userOrganizationData.getIsOrganizationInDraft);
  const isCustomerOrg = useSelector(R.selectors.userOrganizationData.getIsCustomerOrganization);
  const isPartnerOrg = useSelector(R.selectors.userOrganizationData.getIsPartnerOrganization);
  const isAdmin = useSelector(R.selectors.userOrganizationData.getUserOrganisationRole) === EOrganizationMemberRole.admin;
  const isInWaitingForApproval = useSelector(R.selectors.userOrganizationData.getIsOrganizationInWaitingForApproval);
  const userPermissions = useSelector(R.selectors.moduleFunctionalityPermissions.getUsersPermissions);
  const hasOrganizationTeamOrManager = useSelector(R.selectors.userOrganizationData.getHasOrganizationTeamOrManager);

  return (
    <AccountMenuComponent
      isCustomerOrg={isCustomerOrg}
      isPartnerOrg={isPartnerOrg}
      isInDraft={isInDraft}
      isAdmin={isAdmin}
      hasOrganizationTeamOrManager={hasOrganizationTeamOrManager}
      isInWaitingForApproval={isInWaitingForApproval}
      pageIsAvailable={hasReadAccess(userPermissions?.page)}
    />
  );
};

export { AccountMenuContainer as AccountMenu };
