import {
  CCContentTableContent,
} from 'app-wrapper/view/pages/CommandCenterPage/CommandCenter/components/ContentTableTasks/ContentTableTasks.styled';
import React, {
  FC, memo, useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';

import { MakeReadSvg } from 'app-wrapper/view/icons';
import { Button, TableSkeleton } from 'app-wrapper/view/components';
import { CommandCenterComponentTaskDTM } from 'app-wrapper/models/dtm';
import { REQUEST_STATUS } from 'app-wrapper/constants';

import {
  ContentTableNotificationItem,
} from 'app-wrapper/view/pages/CommandCenterPage/CommandCenter/components/ContentTableNotification/ContentTableNotificationItem';
import {
  CCContentTableNotification,
  CCContentTableNotificationHeader,
  CCContentTableNotificationHeaderItem,
  CCContentTableNotificationHeaderItemBtn,
} from './ContentTableNotification.styled';

interface IContentTableNotificationProps {
  onStartNotifications: () => void;
  nextPageTasks: () => void;
  onHoverNotificationItem: (id: string, shipmentId?: string) => void;
  onUnviewedNotificationItem: (notificationId: string, viewId: string, shipmentId?: string) => void;
  onAllNotificationMakeViews: () => void;
  isLoad?: string;
  shipmentId?: string;
  isLoadAdd?: string;
  allTasks?: CommandCenterComponentTaskDTM[];
  infiniteScrollId: string;
}

const ContentTableNotification: FC<IContentTableNotificationProps> = (props) => {
  const {
    onStartNotifications,
    nextPageTasks,
    onHoverNotificationItem,
    onUnviewedNotificationItem,
    onAllNotificationMakeViews,
    isLoad,
    shipmentId,
    isLoadAdd,
    allTasks,
    infiniteScrollId,
  } = props;

  const { t } = useTranslation();

  useEffect(() => {
    onStartNotifications();
  }, []);

  return (
    <CCContentTableNotification>
      <CCContentTableNotificationHeader>
        <CCContentTableNotificationHeaderItem
          width="57%"
        >
          {t('Notification')}
          <CCContentTableNotificationHeaderItemBtn>
            <Button
              size="small"
              type="default"
              onClick={onAllNotificationMakeViews}
            ><MakeReadSvg />{t('Mark All as Read')}
            </Button>
          </CCContentTableNotificationHeaderItemBtn>
        </CCContentTableNotificationHeaderItem>
        <CCContentTableNotificationHeaderItem
          width="14.3%"
        >
          {t('ReceivedDate')}
        </CCContentTableNotificationHeaderItem>
        <CCContentTableNotificationHeaderItem
          width="14.3%"
        >
          {t('object')}
        </CCContentTableNotificationHeaderItem>
        <CCContentTableNotificationHeaderItem
          width="14%"
        >
          {t('module')}
        </CCContentTableNotificationHeaderItem>
      </CCContentTableNotificationHeader>

      <CCContentTableContent>

        <InfiniteScroll
          dataLength={Number(allTasks?.length)}
          next={nextPageTasks}
          hasMore
          loader={
            isLoad === REQUEST_STATUS.pending
            && (<TableSkeleton />)
          }
          scrollableTarget={infiniteScrollId}
        >
          {allTasks?.map((item) => (
            <ContentTableNotificationItem
              key={item.id}
              shipmentId={shipmentId}
              item={item}
              onHoverNotificationItem={onHoverNotificationItem}
              onUnviewedNotificationItem={onUnviewedNotificationItem}
            />
          ))}
        </InfiniteScroll>
        {isLoadAdd === REQUEST_STATUS.pending && (<TableSkeleton />)}
      </CCContentTableContent>

    </CCContentTableNotification>
  );
};

const ContentTableNotificationCache = memo(ContentTableNotification);

export { ContentTableNotificationCache as ContentTableNotification };
