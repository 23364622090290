import styled from 'styled-components';
import Layout from 'antd/es/layout';

import { Button } from 'app-wrapper/view/components';
import { divFlex } from 'app-wrapper/view/themes/mixin';

export const UserManagementFormFooterActionPanel = styled(Layout.Footer).attrs({
  'data-class': 'UserManagementFormFooterActionPanel',
})`
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
  margin: auto;
  width: 100%;
  height: 64px;
  padding: 0px 24px;

  z-index: 2;
  color: inherit;
  background-color: white;
  box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px rgba(0, 0, 0, 0.08), 0px 9px 28px 8px rgba(0, 0, 0, 0.05);
`;

export const UserManagementFormFooterRemoveButton = styled(Button).attrs({
  'data-class': 'UserManagementFormFooterRemoveButton',
})`
  ${divFlex}

  align-items: center;
  justify-content: center;
  margin-right: auto;
  padding: 5px 16px;

  border-color: ${({ theme }) => theme?.themesColors?.characterBrandingDanger50};
  color: ${({ theme }) => theme?.themesColors?.characterBrandingDanger};
`;
