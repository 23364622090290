import styled from 'styled-components';
import Table from 'antd/es/table';

export const CustomHeader = styled(Table)`
  .ant-table-thead > tr > th {
    background: white;
    border-bottom: none;
  }
  .ant-table-thead > tr:last-child > th:last-child {
    padding-right: 0;
  }
  .ant-table-container table > thead > tr:first-child th:last-child,
  .ant-table-tbody .ant-table-cell:last-child {
    padding-right: 0;
  }
  .ant-table-thead .ant-table-cell {
    padding-top: 8px;
    padding-bottom: 8px;
    border-bottom: 1px solid #EAEEF8;
    border-top: 1px solid #EAEEF8;
  }
  .ant-table-thead .ant-table-cell {
    color: #73819B;
    font-size: 12px;
    font-weight: 400;
  }
  .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    display: none;
  }
  .ant-table-container table > thead > tr:first-child th:first-child {
    padding-left: 0;
  }
  .ant-table-container table > thead > tr:last-child th:first-child {
    padding-left: 0;
  }

  .ant-table-expanded-row .ant-table-cell {
    background: white;
  }

  .ant-table-tbody > .ant-table-cell {
    padding-top: 16px;
    padding-bottom: 16px;
  }
  .ant-table-tbody .ant-table-cell {
    font-size: 14px;
    color: #202C3C;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .ant-table-tbody > tr.ant-table-row:hover > td {
    background: none !important;
  }
  .ant-table-tbody > tr >  .ant-table-cell:first-child {
    padding-left: 0;
  }
  .ant-table-cell {
    vertical-align: text-top;
  }
`;

export const EditableTableWrapper = styled(CustomHeader)`
  .ant-table-cell {
    vertical-align: middle;
  }
`;

export const ContentKey = styled.span`
  color: #73819B;
`;

export const WithIcon = styled.div`
  display: flex;
  align-items: center;
`;

export const EmptyRound = styled.div`
  width: 14px;
  height: 14px;
  border-radius: 50%;
  border: 2px solid #6C79DE;
  margin-right: 5px;
`;

export const FilledRound = styled.div`
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background-color: #6C79DE;
  margin-right: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
