import styled from 'styled-components';
import { SizeType } from 'antd/es/config-provider/SizeContext';
import Typography from 'antd/es/typography';

import { colors } from 'app-wrapper/view/themes/themesColors';

export const BillOfLadingOriginalsDistributionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const BillOfLadingAmountLabel = styled(Typography.Text)`
  display: flex;
  flex-direction: row;
  gap: 4px;

  > :nth-child(2) {
    color: ${colors.gray61};
  }
`;

interface IBillOfLadingOriginalsDistributionEntryProps {
  size?: SizeType
}

export const BillOfLadingOriginalsDistributionEntry = styled.div<IBillOfLadingOriginalsDistributionEntryProps>`
  display: grid;
  grid-template-columns: 1.4fr 90px 52px ${({ size }) => {
    if (size === 'large') {
      return 40;
    }
    return 32;
  }}px;
  gap: 4px;

  > .ant-typography {
    font-size: 12px;
  }
`;
