import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

import Checkbox from 'antd/es/checkbox/Checkbox';
import { Button } from 'app-wrapper/view/components';

interface IStyledCheckboxProps {
  mLeft?: string
}

export const ShipmentChargesContainer = styled.div.attrs({
  'data-class': 'ShipmentChargesContainer',
})`
  display: flex;
  gap: 8px;
  flex-direction: column;
`;

export const Container = styled.div`
  max-width: 1500px;
  flex-direction: column;
  display: flex;
`;

export const HeaderTitle = styled.p`
  font-size: 20px;
  font-weight: 500;
  margin: 0;
`;

export const StyledCheckbox = styled(Checkbox)<IStyledCheckboxProps>`
  margin-right: 8px;
  margin-left: ${({ mLeft }) => (mLeft || 0)};
  position: relative;
  z-index: 100;
`;

export const StyledButton = styled(Button)`
  font-size: 14px;
`;

export const NameOfInvoice = styled(NavLink)`
  color: #202C3C;
  text-decoration: underline;
  cursor: pointer;
  margin: 0 4px;
  
  &:hover {
    color: #202C3C;
    text-decoration: none;
  }
`;

export const InnerContainer = styled.div`
  width: 100%;
  padding: 16px 16px 0 16px;
`;

export const Title = styled.p`
  font-weight: 500;
  font-size: 16px;
  color: #202C3C;
  margin-bottom: 14px;
`;

export const TableContainer = styled.div`
  background-color: white;
  margin-top: 5px;
`;

export const TableHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 16px 0 16px;
`;
