import React from 'react';
import { observer } from 'mobx-react-lite';

import { useMobxStore } from 'app-wrapper/mobxStores';
import { UC } from 'shipment-operations/controllers';

import { ShipmentAdditionalService } from './ShipmentAdditionalService.component';

const ShipmentAdditionalServiceContainer = observer(() => {
  const { shipmentServices } = useMobxStore();
  const { status, statusText } = shipmentServices.getServicesStatus;

  return (
    <ShipmentAdditionalService
      isLoading={shipmentServices.state.isLoading}
      service={shipmentServices.state.service}
      init={UC.shipmentServices.initServiceById}
      statusText={statusText}
      statusType={status}
    />
  );
});

export { ShipmentAdditionalServiceContainer as ShipmentAdditionalService };
