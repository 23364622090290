import React, { FC, useMemo } from 'react';

import { ECarrierSCAC } from 'monetary/constants';

import logoACLU from './assets/ACLU.svg';
import logoSmallACLU from './assets/ACLU-small.svg';
import logoCMDU from './assets/CMDU.svg';
import logoSmallCMDU from './assets/CMDU-small.svg';
import logoCOSU from './assets/COSU.svg';
import logoSmallCOSU from './assets/COSU-small.svg';
import logoECUW from './assets/ECUW.svg';
import logoSmallECUW from './assets/ECUW-small.svg';
import logoEGLV from './assets/EGLV.svg';
import logoSmallEGLV from './assets/EGLV-small.svg';
import logoHDMU from './assets/HDMU.svg';
import logoSmallHDMU from './assets/HDMU-small.svg';
import logoHLCU from './assets/HLCU.svg';
import logoSmallHLCU from './assets/HLCU-small.svg';
import logoMAEU from './assets/MAEU.svg';
import logoSmallMAEU from './assets/MAEU-small.svg';
import logoMSCU from './assets/MSCU.svg';
import logoSmallMSCU from './assets/MSCU-small.svg';
import logoONEY from './assets/ONEY.svg';
import logoSmallONEY from './assets/ONEY-small.svg';
import logoSUDU from './assets/SUDU.svg';
import logoSmallSUDU from './assets/SUDU-small.svg';
import logoYMLU from './assets/YMLU.svg';
import logoSmallYMLU from './assets/YMLU-small.svg';
import logoZIMU from './assets/ZIMU.svg';
import logoSmallZIMU from './assets/ZIMU-small.svg';

const CARRIER_SCAC_TO_LOGO_PATH_MAP: Partial<Record<ECarrierSCAC, string>> = {
  [ECarrierSCAC.ACLU]: logoACLU,
  [ECarrierSCAC.CMDU]: logoCMDU,
  [ECarrierSCAC.COSU]: logoCOSU,
  [ECarrierSCAC.ECUW]: logoECUW,
  [ECarrierSCAC.EGLV]: logoEGLV,
  [ECarrierSCAC.HDMU]: logoHDMU,
  [ECarrierSCAC.HLCU]: logoHLCU,
  [ECarrierSCAC.MAEU]: logoMAEU,
  [ECarrierSCAC.MSCU]: logoMSCU,
  [ECarrierSCAC.ONEY]: logoONEY,
  [ECarrierSCAC.SUDU]: logoSUDU,
  [ECarrierSCAC.YMLU]: logoYMLU,
  [ECarrierSCAC.ZIMU]: logoZIMU,
};

const CARRIER_SCAC_TO_SMALL_LOGO_PATH_MAP: Partial<Record<ECarrierSCAC, string>> = {
  [ECarrierSCAC.ACLU]: logoSmallACLU,
  [ECarrierSCAC.CMDU]: logoSmallCMDU,
  [ECarrierSCAC.COSU]: logoSmallCOSU,
  [ECarrierSCAC.ECUW]: logoSmallECUW,
  [ECarrierSCAC.EGLV]: logoSmallEGLV,
  [ECarrierSCAC.HDMU]: logoSmallHDMU,
  [ECarrierSCAC.HLCU]: logoSmallHLCU,
  [ECarrierSCAC.MAEU]: logoSmallMAEU,
  [ECarrierSCAC.MSCU]: logoSmallMSCU,
  [ECarrierSCAC.ONEY]: logoSmallONEY,
  [ECarrierSCAC.SUDU]: logoSmallSUDU,
  [ECarrierSCAC.YMLU]: logoSmallYMLU,
  [ECarrierSCAC.ZIMU]: logoSmallZIMU,
};

interface CarrierLogoProps {
  carrierSCAC: ECarrierSCAC;
  small?: boolean;
}

export const CarrierLogo: FC<CarrierLogoProps> = ({ carrierSCAC, small }) => {
  const logoImg = useMemo(() => {
    if (!carrierSCAC) {
      return null;
    }

    if (small) {
      return CARRIER_SCAC_TO_SMALL_LOGO_PATH_MAP[carrierSCAC];
    }

    return CARRIER_SCAC_TO_LOGO_PATH_MAP[carrierSCAC];
  }, [small, carrierSCAC]);

  if (!logoImg) {
    return null;
  }

  return (
    <img alt="" src={logoImg} />
  );
};
