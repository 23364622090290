import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { BadInttraRequestError } from 'shipment-operations/models/errors';

import { AlertBlock } from './ShipmentOverviewAlertBar.styled';

interface IShipmentOverviewAlertBarComponentProps {
  wasSubmitShipmentInstructionRequestSuccessful?: boolean
  wasBookShipmentRequestSuccessful?: boolean
  wasManualBookingConfirmationSuccessful?: boolean
  error?: BadInttraRequestError
  reset: () => void
}

export const ShipmentOverviewAlertBarComponent: FC<IShipmentOverviewAlertBarComponentProps> = ({
  wasSubmitShipmentInstructionRequestSuccessful,
  wasBookShipmentRequestSuccessful,
  wasManualBookingConfirmationSuccessful,
  error,
  reset,
}) => {
  const { t } = useTranslation();

  if (wasManualBookingConfirmationSuccessful) {
    return (
      <AlertBlock
        closable
        showIcon
        type="success"
        message={t('Booking has been successfully confirmed.')}
        onClick={reset}
      />
    );
  }

  if (wasSubmitShipmentInstructionRequestSuccessful) {
    return (
      <AlertBlock
        closable
        showIcon
        type="success"
        message={t('Your Shipping Instructions have been successfully submitted')}
        onClick={reset}
      />
    );
  }

  if (wasBookShipmentRequestSuccessful) {
    return (
      <AlertBlock
        closable
        showIcon
        type="success"
        message={t('We received your Shipment Request. In the next 24 hours we will check all details with the carrier and notify you once everything is confirmed.')}
        onClick={reset}
      />
    );
  }

  if (error) {
    return (
      <AlertBlock
        closable
        showIcon
        type="warning"
        message={error.message}
        onClick={reset}
      />
    );
  }

  return null;
};
