import styled from 'styled-components';
import Layout from 'antd/es/layout';

import { colors } from 'app-wrapper/view/themes/themesColors';

export const ShipmentDetailsContainer = styled(Layout).attrs({
  'data-class': 'ShipmentDetailsContainer',
})`
  display: flex;
  flex-direction: column;
  gap: 0;
  padding: 16px 24px 0;
  height: calc(100vh - 48px - 84px);
  background-color: ${colors.gray35};
  max-width: 1308px;
  margin: 0 auto;
`;

export const ShipmentDetailsContent = styled(Layout.Content).attrs({
  'data-class': 'ShipmentDetailsContent',
})<{ gap?: number }>`
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 8px;
  width: 100%;
  height: 100%;
`;

export const ShipmentDetailsTitle = styled(Layout.Header).attrs({
  'data-class': 'ShipmentDetailsTitle',
})`
  display: flex;
  align-items: center;
  height: auto;
  padding: 0;
  margin-bottom: 10px;

  color: inherit;
  background-color: inherit;
`;
