import styled from 'styled-components';

export const ShipmentContainerTitleLeftPart = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const DisableButton = styled.div`
  border-radius: 3px;
  border: 1px solid var(--neutral-branding-5, #DEE1E9);
  background: var(--neutral-branding-3, #F4F5F8);
  cursor: not-allowed;
  color: #D1D7E2;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 8px;
`;
