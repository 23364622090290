import React, { FC } from 'react';
import { getOr } from 'lodash/fp';

import { ShippingPartyDTM } from 'shipment-operations/models/dtm';
import { Bold } from 'shipment-operations/view/components/HBLDocumentPDF/components';

import { ParticipantCardWrapper, Phone } from './ParticipantCard.styled';

interface ParticipantCardProps {
  data: ShippingPartyDTM
  onBehalfTitle?: string;
}

export const ParticipantCard: FC<ParticipantCardProps> = ({ data, onBehalfTitle }: ParticipantCardProps) => {
  const {
    role,
    company,
    address,
    contact,
  } = data;

  const preparedRole = role.replace('HOUSE_', '').split('_').join(' ');
  const city = getOr('', ['city'], address);
  const postalCode = getOr('', ['postalCode'], address);
  const state = getOr('', ['state'], address);
  const addressLine = [city, postalCode, state].filter((item) => !!item).join(', ');

  return (
    <ParticipantCardWrapper>
      <Bold bottomMargin>{preparedRole.toLocaleLowerCase()}</Bold>
      {onBehalfTitle ? (
        <span>{onBehalfTitle}</span>
      ) : (
        <>
          {company ? <span>{company.name}</span> : null}
        </>
      )}
      {
        !!address && (
        <>
          <span>{address.address1}</span>
          {!!address.address2 && <span>{address.address2}</span>}
          <span>{addressLine} {address.country}</span>
        </>
        )
      }
      {
        !!contact && (
        <>
          <span>{contact.fullName}</span>
          {!!contact.email && <span>{contact.email}</span>}
          <Phone>{contact.phone}</Phone>
        </>
        )
      }
    </ParticipantCardWrapper>
  );
};
