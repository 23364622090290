import styled from 'styled-components';

import { divFlex } from 'app-wrapper/view/themes/mixin';
import { PageHeader } from 'app-wrapper/view/components';

export const CBWrapper = styled.div.attrs({
  'data-class': 'CBWrapper',
})`
  ${divFlex}
  flex-direction: column;

  background-color: ${({ theme }) => theme?.themesColors?.neutralBranding3};
  height: 100%;
`;

export const CBContentBodyList = styled.div.attrs({
  'data-class': 'CBContentBodyList',
})`
  ${divFlex}
  flex-direction: column;
  width: 100%;

  > div {
    margin-top: 6px;
  }
  > div:first-child {
    margin-top: 0;
  }
`;

export const HeaderWrapper = styled.div`
  width: 100%;
  background-color: white;
`;

export const CBLayoutHeader = styled(PageHeader).attrs({
  'data-class': 'CBLayoutHeader',
})`
  width: 100%;
  height: 56px;
  padding-left: 24px;
  max-width: 1660px;
  margin: 0 auto;

  .ant-page-header-heading {
    ${divFlex}
    align-items: center;
    height: 100%;
  }

  .ant-page-header-heading-title {
    font-size: 20px;
    font-weight: 500;
  }
`;
