import React, { FC } from 'react';
import { useTranslation, Trans } from 'react-i18next';

import { ShipmentDrawerWrapper } from 'shipment-operations/view/pages/Shipment/components/ShipmentDrawer/ShipmentDrawer.styled';
import { FeesAndServicesChargeChangesTable, ContainersChargesChangesTableComponent, SubPageContent } from 'app-wrapper/view/guideline';
import { ContainerWithChangeChargesDTM, ShipmentChargeChangeDTM } from 'app-wrapper/models/dtm';

import { normalizationCost } from 'app-wrapper/utils';
import { StyledWrapper, Message } from './ChargesCostChanges.styled';

interface IChargesCostChangesComponentProps {
  chargesBol: ShipmentChargeChangeDTM[];
  chargesContainer: ContainerWithChangeChargesDTM[];
  onClose: () => void;
  currentTotal: number;
  newTotal: number;
}

const ChargesCostChangesComponent: FC<IChargesCostChangesComponentProps> = ({
  chargesBol, onClose, chargesContainer, currentTotal, newTotal,
}) => {
  const { t } = useTranslation();

  return (
    <ShipmentDrawerWrapper
      title={t('viewPricingChanges')}
      visible
      width="1137px"
      closable
      maskClosable={false}
      onClose={onClose}
    >
      <StyledWrapper>
        <Message>
          <Trans
            i18nKey="updatedCostTitle"
            values={{
              currentTotal: `$ ${normalizationCost(currentTotal, { toFixed: 2, thousandthSeparatorComma: true })}`,
              newTotal: `$ ${normalizationCost(newTotal, { toFixed: 2, thousandthSeparatorComma: true })}`,
            }}
            components={{ strong: <strong /> }}
          />
        </Message>
        <SubPageContent style={{ padding: 0, paddingTop: '16px' }}>
          <ContainersChargesChangesTableComponent dataSource={chargesContainer} />
          <FeesAndServicesChargeChangesTable charges={chargesBol} />
        </SubPageContent>
      </StyledWrapper>
    </ShipmentDrawerWrapper>
  );
};

export { ChargesCostChangesComponent };
