import styled from 'styled-components';
import AntLayout from 'antd/es/layout';
import Spin from 'antd/es/spin';
import UserOutlined from '@ant-design/icons/UserOutlined';
import QuestionOutlined from '@ant-design/icons/QuestionOutlined';

import themesColors from 'app-wrapper/view/themes/themesColors';
import { Button, MenuTrigger } from 'app-wrapper/view/components';

const { Content } = AntLayout;

export const Layout = styled(AntLayout)`
  height: 100vh;

  h5 {
    color: #202c3c;
  }
`;

export const SpinnerWrap = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
`;

export const Spinner = styled(Spin)`
  margin: auto;
`;

export const HomeContent = styled(Content)`
  background-color: white;
  display: flex;
  flex-direction: column;
`;

export const Header = styled(AntLayout.Header)`
  background-color: #f9f9fb;
  color: #ffffff;
  display: flex;
  justify-content: space-between;
  height: 48px;
  padding: 0 16px 0 24px;
`;

export const HeaderBadge = styled.div`
  display: flex;
  align-items: center;
  padding-right: 12px;
  margin-left: auto;
`;

export const Sider = styled(AntLayout.Sider).attrs({
  'data-class': 'Sider',
})`
  background-color: ${themesColors.neutralBranding1};
  z-index: 7;
  position: absolute;
  height: 100%;
  
  border-right: 1px solid ${themesColors.lightGrayBorder};

  @media screen and (min-width: 1366px) {
    position: relative;
    height: auto;
  }

  .ant-layout-sider {
    background-color: transparent;
  }
  
  .ant-layout-sider-children {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: transparent;
  }
`;

export const MenuTriggerStickyContainer = styled.div.attrs({
  'data-class': 'MenuTriggerStickyContainer',
})`
  background-color: white;
  width: 100%;
  height: 40px;
`;

export const SubMenuTriggerStickyContainer = styled.div.attrs({
  'data-class': 'MenuTriggerStickyContainer',
})`
  background-color: ${themesColors.neutralBranding2};
  width: 100%;
  height: 40px;
`;

export const MenuTriggerSticky = styled(MenuTrigger).attrs({
  'data-class': 'MenuTriggerSticky',
})``;

export const LayoutContent = styled(AntLayout)`
  position: relative;
  margin-left: 47px;
  overflow: hidden;

  @media screen and (min-width: 1366px) {
    margin-left: inherit;
  }
`;

export const LayoutMenuBorder = styled.div`
  position: absolute;
  z-index: 2;
  border: 1px solid #f4f5f8;
  height: 100%;
  left: 47px;
`;

export const MainMenuLogo = styled.div`
  display: flex;
  padding-top: 0;
  margin-top: -3px;
  transition: padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
`;

export const MainMenuLogoCollapsed = styled(MainMenuLogo)`
  padding-top: 0;
  margin-top: -3px;
`;

export const MainMenuLogoTitle = styled.div`
  display: flex;
  align-items: center;
  margin-left: 15px;

  h5 {
    font-size: 17px;
    font-weight: 700;
    margin-bottom: 0;
  }
`;

export const UserMenuButton = styled(Button)`
  color: ${themesColors.darkGrayMiddle};
`;

export const UserSettingWrapper = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
  margin-left: auto;
`;

export const HelpIcon = styled.div`
  width: 14px;
  height: 14px;
  border: 1px solid #73819B;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all .2s ease;
`;

export const StyledQuestion = styled(QuestionOutlined)`
  color: #73819B;
  font-size: 9px;
  transition: all .2s ease;
`;

export const HelpWrapper = styled.div`
  width: 71px;
  height: 32px;
  border: 1px solid #EAEEF8;
  border-radius: 360px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #73819B;
  cursor: pointer;
  gap: 7px;
  font-size: 12px;
  font-weight: 400;
  transition: all .3s ease;
  
  &:hover {
    border-color: #D3D7F5;
    color: #6C79DE;
    ${HelpIcon} {
      border-color: #6C79DE;
    }
    ${StyledQuestion} {
      color: #6C79DE;
    }
  }
`;

export const UserWrapper = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: #F0F2FC;
  border: 1px solid #EAEEF8;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all .3s ease;
  
  &:hover {
    border-color: #D3D7F5;
    background-color: transparent;
  }
`;

export const StyledUser = styled(UserOutlined)`
  color: #828DE3;
  transition: all .2s ease;
  &:hover {
    color: #6C79DE;
  }
`;
