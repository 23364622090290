import React, { forwardRef } from 'react';
import AntRow, { RowProps } from 'antd/es/row';

// TODO: fix ref types
export const Row = forwardRef<any, RowProps>((props, ref) => (
  <AntRow
    className="Row"
    ref={ref as React.RefObject<any>}
    {...props}
  />
));
