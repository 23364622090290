import React from 'react';

import { useAppSelector } from 'app-wrapper/hooks';
import { R } from 'shipment-operations/repository';

import { PaymentTable } from 'shipment-operations/view/components';

const MainContainer = () => {
  const data = useAppSelector(R.selectors.shipmentBillingInvoice.getInvoiceForPayment);
  const receivedAmount = useAppSelector(R.selectors.makePayment.getReceivedAmount);
  const usedAmount = useAppSelector(R.selectors.shipmentBillingInvoice.getUsedAmount);
  const leftAmount = useAppSelector(R.selectors.shipmentBillingInvoice.getLeftAmount);

  return (
    <PaymentTable
      data={data}
      receivedAmount={receivedAmount}
      usedAmount={usedAmount}
      leftAmount={leftAmount}
      viewMode={false}
    />
  );
};

export { MainContainer as Main };
