import React, { FC, useMemo, CSSProperties } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Menu from 'antd/es/menu';
import SolutionOutlined from '@ant-design/icons/SolutionOutlined';
import UserOutlined from '@ant-design/icons/UserOutlined';
import TeamOutlined from '@ant-design/icons/TeamOutlined';

import { SkypaceTeamIcon } from 'app-wrapper/view/icons';
import { RouteNames } from 'app-wrapper/constants';

import { StyledMenu } from 'shipment-operations/view/pages/Shipment/components/ShipmentMenu/ShipmentMenu.styled';
import { CustomersIcon } from './components';
import {
  StyledItem,
  StyledSubMenu,
  Link,
  LinkText,
  LinkLock,
} from './AccountMenu.styled';

const getHigherLevelUrls = (url: string, result: string[] = [url]): string[] => {
  const lastDirectoryStart = url.lastIndexOf('/');
  if (lastDirectoryStart === -1) {
    return result;
  }
  const higherLevelUrl = url.substring(0, lastDirectoryStart);

  if (!higherLevelUrl.length) {
    return result;
  }

  return getHigherLevelUrls(higherLevelUrl, [...result, higherLevelUrl]);
};

const { Item } = Menu;
const SHIPMENT_DETAILS_KEY = 'shipment-details';

interface AccountMenuComponentProps {
  isInDraft: boolean
  isInWaitingForApproval: boolean
  isCustomerOrg: boolean
  isPartnerOrg: boolean
  hasOrganizationTeamOrManager: boolean;
  pageIsAvailable?: boolean
  isAdmin?: boolean
}

export const AccountMenuComponent: FC<AccountMenuComponentProps> = ({
  isInDraft,
  isInWaitingForApproval,
  isCustomerOrg,
  hasOrganizationTeamOrManager,
  isPartnerOrg,
  pageIsAvailable,
  isAdmin,
}) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const defaultOpenedKeys = useMemo(() => {
    if (pathname.includes(RouteNames.ACCOUNT_DOCUMENTS()) || pathname.includes(RouteNames.ORGANIZATION_PROFILE()) || pathname.includes(RouteNames.USER_MANAGEMENT()) || pathname.includes(RouteNames.ADDRESSES_CONTACTS())) {
      return [SHIPMENT_DETAILS_KEY];
    }

    return [];
  }, []);

  const linkDisablingStyles = useMemo<CSSProperties>(() => (isInWaitingForApproval ? {
    pointerEvents: 'none',
    cursor: 'initial',
    paddingRight: 8,
  } : { paddingRight: 8 }), [isInWaitingForApproval]);

  return (
    <StyledMenu
      selectedKeys={getHigherLevelUrls(pathname)}
      mode="inline"
      defaultOpenKeys={defaultOpenedKeys}
    >
      <StyledItem
        key={RouteNames.USER_PROFILE()}
        icon={<UserOutlined />}
      >
        <NavLink to={RouteNames.USER_PROFILE()}>{t('Profile')}</NavLink>
      </StyledItem>

      {!isInDraft ? (
        <>
          <StyledSubMenu
            key={SHIPMENT_DETAILS_KEY}
            icon={<TeamOutlined />}
            title={t('My Organization')}
          >
            <Item key={RouteNames.ORGANIZATION_PROFILE()}>
              <NavLink to={RouteNames.ORGANIZATION_PROFILE()}>{t('Organization Profile')}</NavLink>
            </Item>
            <Item key={RouteNames.ACCOUNT_DOCUMENTS()}>
              <NavLink to={RouteNames.ACCOUNT_DOCUMENTS()}>{t('Documents')}</NavLink>
            </Item>
            <Item key={RouteNames.USER_MANAGEMENT()} style={linkDisablingStyles}>
              <Link to={RouteNames.USER_MANAGEMENT()}>
                <LinkText>
                  {t('User management')}
                </LinkText>

                {isInWaitingForApproval ? (
                  <LinkLock />
                ) : null}
              </Link>
            </Item>
            <Item key={RouteNames.ADDRESSES_CONTACTS()} style={linkDisablingStyles}>
              <Link to={RouteNames.ADDRESSES_CONTACTS()}>
                <LinkText>
                  {t('Addresses & Contacts')}
                </LinkText>

                {isInWaitingForApproval ? (
                  <LinkLock />
                ) : null}
              </Link>
            </Item>
            {(isPartnerOrg || isAdmin) ? (
              <Item key={RouteNames.ACCOUNT_DEPARTMENTS()} style={linkDisablingStyles}>
                <Link to={RouteNames.ACCOUNT_DEPARTMENTS()}>
                  <LinkText>
                    {t('Departments')}
                  </LinkText>

                  {isInWaitingForApproval ? (
                    <LinkLock />
                  ) : null}
                </Link>
              </Item>
            ) : null}

            {!isCustomerOrg ? (
              <Item key={RouteNames.ACCOUNT_TEAMS()}>
                <NavLink to={RouteNames.ACCOUNT_TEAMS()}>{t('Teams')}</NavLink>
              </Item>
            ) : null}
          </StyledSubMenu>

          {isCustomerOrg && hasOrganizationTeamOrManager ? (
            <StyledItem
              data-class="account-menu-my-team"
              icon={<SkypaceTeamIcon />}
              key={RouteNames.ACCOUNT_MY_TEAM()}
            >
              <NavLink to={RouteNames.ACCOUNT_MY_TEAM()}>{t('Skypace Team')}</NavLink>
            </StyledItem>
          ) : null}

          {!isCustomerOrg ? (
            <>
              <StyledItem
                data-class="account-menu-customers"
                icon={<CustomersIcon />}
                key={RouteNames.ACCOUNT_CUSTOMERS()}
              >
                <NavLink to={RouteNames.ACCOUNT_CUSTOMERS()}>{t('Customers')}</NavLink>
              </StyledItem>

              {!isPartnerOrg ? (
                <StyledItem
                  data-class="account-menu-partners"
                  icon={<CustomersIcon />}
                  key={RouteNames.ACCOUNT_PARTNERS()}
                >
                  <NavLink to={RouteNames.ACCOUNT_PARTNERS()}>{t('Partners')}</NavLink>
                </StyledItem>
              ) : null}
            </>
          ) : null}
        </>
      ) : null}

      {pageIsAvailable
        ? (
          <StyledItem
            key={RouteNames.ACCOUNT_USERS()}
            icon={<SolutionOutlined />}
          >
            <NavLink to={RouteNames.ACCOUNT_USERS()}>{t('All users')}</NavLink>
          </StyledItem>
        )
        : null}
    </StyledMenu>
  );
};
