import React from 'react';
import { useSelector } from 'react-redux';

import { UC } from 'shipment-operations/controllers';
import { R } from 'shipment-operations/repository';
import { UploaderComponent } from './Uploader.component';

const UploaderContainer = () => {
  const documents = useSelector(R.selectors.shipmentActiveCharge.getActiveChargeDocuments);

  return (
    <UploaderComponent
      documents={documents}
      onDownload={UC.shipmentActiveCharge.downloadDocument}
      addDocument={UC.shipmentActiveCharge.addDocumentToCharge}
      removeDocument={UC.shipmentActiveCharge.removeDocumentFromCharge}
      setIsDocumentUploading={UC.shipmentActiveCharge.setIsDocumentUploading}
    />
  );
};

export { UploaderContainer as Uploader };
