import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { FormStatus } from 'app-wrapper/view/components';
import { FormStatusType } from 'app-wrapper/types/FormStatusType';
import { SkeletonSection } from 'app-wrapper/view/components/Skeleton/Skeleton.component';
import { ShippingPartyDTM } from 'shipment-operations/models/dtm';
import { ShipmentDetailsList, ShipmentDetailsCardSmall } from 'shipment-operations/view/components';
import {
  RequiredShippingParties,
  EShippingPartyTypes,
  SHIPPING_PARTIES_PAGE_NAMES,
} from 'shipment-operations/constants';

import { UnalignedBadge, ShipmentPartyCardTitle } from './ShippingPartiesList.styled';

interface IShippingPartiesListComponentProps {
  isLoading: boolean
  selectedShippingParty: EShippingPartyTypes
  selectShippingParty: (shippingParty: EShippingPartyTypes) => void
  shippingPartiesCurrentData: {
    [EShippingPartyTypes.SHIPPER]: ShippingPartyDTM
    [EShippingPartyTypes.CONSIGNEE]: ShippingPartyDTM
    [EShippingPartyTypes.NOTIFY_PARTY]: ShippingPartyDTM
    [EShippingPartyTypes.CUSTOMER]: ShippingPartyDTM
    [EShippingPartyTypes.HOUSE_SHIPPER]: ShippingPartyDTM
    [EShippingPartyTypes.HOUSE_CONSIGNEE]: ShippingPartyDTM
    [EShippingPartyTypes.HOUSE_NOTIFY_PARTY]: ShippingPartyDTM
    [EShippingPartyTypes.EXPORT_BROKER]: ShippingPartyDTM
    [EShippingPartyTypes.IMPORT_BROKER]: ShippingPartyDTM
    [EShippingPartyTypes.SECOND_NOTIFY_PARTY]: ShippingPartyDTM
    [EShippingPartyTypes.FORWARDER_AGENT]: ShippingPartyDTM
    [EShippingPartyTypes.DELIVERY_AGENT]: ShippingPartyDTM
    [EShippingPartyTypes.ACCOUNT_HOLDER]: ShippingPartyDTM
    [EShippingPartyTypes.ULTIMATE_CUSTOMER]: ShippingPartyDTM
    [EShippingPartyTypes.OCEAN_CARRIER]: ShippingPartyDTM
    [EShippingPartyTypes.BOOKING_AGENT]: ShippingPartyDTM
    [EShippingPartyTypes.ORIGIN_PARTNER_AGENT]: ShippingPartyDTM
    [EShippingPartyTypes.DESTINATION_PARTNER_AGENT]: ShippingPartyDTM
  }
  shippingPartiesDraftState: {
    [EShippingPartyTypes.SHIPPER]: boolean
    [EShippingPartyTypes.CONSIGNEE]: boolean
    [EShippingPartyTypes.NOTIFY_PARTY]: boolean
    [EShippingPartyTypes.CUSTOMER]: boolean
    [EShippingPartyTypes.HOUSE_SHIPPER]: boolean
    [EShippingPartyTypes.HOUSE_CONSIGNEE]: boolean
    [EShippingPartyTypes.HOUSE_NOTIFY_PARTY]: boolean
    [EShippingPartyTypes.EXPORT_BROKER]: boolean
    [EShippingPartyTypes.IMPORT_BROKER]: boolean
    [EShippingPartyTypes.SECOND_NOTIFY_PARTY]: boolean
    [EShippingPartyTypes.FORWARDER_AGENT]: boolean
    [EShippingPartyTypes.DELIVERY_AGENT]: boolean
    [EShippingPartyTypes.ACCOUNT_HOLDER]: boolean
    [EShippingPartyTypes.ULTIMATE_CUSTOMER]: boolean
    [EShippingPartyTypes.OCEAN_CARRIER]: boolean
    [EShippingPartyTypes.BOOKING_AGENT]: boolean
    [EShippingPartyTypes.ORIGIN_PARTNER_AGENT]: boolean
    [EShippingPartyTypes.DESTINATION_PARTNER_AGENT]: boolean
  }
  tabParties: EShippingPartyTypes[]
}

export const ShippingPartiesListComponent: FC<IShippingPartiesListComponentProps> = ({
  isLoading,
  selectedShippingParty,
  selectShippingParty,
  shippingPartiesCurrentData,
  shippingPartiesDraftState,
  tabParties,
}) => {
  const { t } = useTranslation();

  const createShippingPartySelectionHandler = (party: EShippingPartyTypes) => () => {
    if (isLoading) {
      return;
    }

    selectShippingParty(party);
  };

  if (isLoading && !tabParties.length) {
    return (
      <ShipmentDetailsList>
        <SkeletonSection height="72px" />
        <SkeletonSection height="72px" />
        <SkeletonSection height="72px" />
      </ShipmentDetailsList>
    );
  }

  return (
    <ShipmentDetailsList>
      {
        tabParties.map((party) => {
          let formStatusType: FormStatusType = 'complete';

          if (
            !shippingPartiesCurrentData[party].company
            && !shippingPartiesCurrentData[party].address
            && !shippingPartiesCurrentData[party].contact
          ) {
            formStatusType = 'incomplete';
          }

          const { company } = shippingPartiesCurrentData[party];

          return (
            <ShipmentDetailsCardSmall
              data-class={`navigation-shipping-party-${party}`}
              id={party}
              key={party}
              disabled={isLoading}
              selected={selectedShippingParty === party}
              onClick={createShippingPartySelectionHandler(party)}
            >
              <ShipmentPartyCardTitle>
                {shippingPartiesDraftState[party] && <span>{`${t(SHIPPING_PARTIES_PAGE_NAMES[party])}${RequiredShippingParties.includes(party) ? ' * ' : ''}`}<UnalignedBadge color="red" /></span>}
                {!shippingPartiesDraftState[party] && `${t(SHIPPING_PARTIES_PAGE_NAMES[party])}${RequiredShippingParties.includes(party) ? ' * ' : ''}`}
                <FormStatus type={formStatusType} />
              </ShipmentPartyCardTitle>
              {company?.name}
            </ShipmentDetailsCardSmall>
          );
        })
      }
    </ShipmentDetailsList>
  );
};
