import React, { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  ICompanyAddressDTM, ICompanyContactDTM,
} from 'user-management/models/dtm';

import { useWindowSize } from 'app-wrapper/hooks';
import { TypeSwitch } from 'app-wrapper/view/components';

import {
  AACBlockButtonCancel,
  AACBlockButtonSave,
  AACBlockContent,
  AACBlockFooter,
  AACBlockWrap,
} from './AddressesAndContactsAddAddress.styled';
import { AddressesAndContactsAddressItem, AddressesAndContactsContactPersonItem } from './components';

interface IAddressesAndContactsComponentProps {
  companyListAddresses?: ICompanyAddressDTM[]
  companyListContactPersons?: ICompanyContactDTM[]
  companyListStateIndex: string
  isDisabledCancelBtn?: boolean
  isDisabledSaveBtn?: boolean
  onCancelClick: () => void
  onSaveClick: () => void
  onChangeAddressIsPrimary: (isPrimary: boolean) => void
}

const AddressesAndContactsComponent: FC<IAddressesAndContactsComponentProps> = (props) => {
  const {
    companyListAddresses,
    companyListContactPersons,
    companyListStateIndex,
    isDisabledCancelBtn,
    isDisabledSaveBtn,
    onCancelClick,
    onSaveClick,
    onChangeAddressIsPrimary,
  } = props;
  const { t } = useTranslation();
  const largeInput = useWindowSize().isFullMediaWidth;

  return (
    <AACBlockWrap>
      <AACBlockContent>

        {companyListAddresses?.map((addressItem, addressIndex) => (
          <AddressesAndContactsAddressItem
            key={`AddressesAndContactsAddressItem-${addressItem.customId}`}
            largeInput={largeInput}
            isFirst
            primaryComponent={(
              <TypeSwitch
                onChange={onChangeAddressIsPrimary}
                data-class="AddressesAndContactsAddressIsPrimaryItem"
                rightText={t('Primary')}
                checkedChildren="On"
                unCheckedChildren="Off"
                value={!!companyListAddresses?.[addressIndex].isPrimary}
                disabledbg="true"
              />
            )}
            isLast={false}
            addressIndex={addressIndex}
            companyListStateIndex={companyListStateIndex}
            address={addressItem}
          />
        ))}

        {companyListContactPersons?.map((personItem, personIndex) => (
          <AddressesAndContactsContactPersonItem
            key={`AddressesAndContactsContactPersonItem-${personItem.customId}`}
            largeInput={largeInput}
            isFirst={personIndex === 0}
            isLast={personIndex === companyListContactPersons.length - 1}
            contactIndex={personIndex}
            personItem={personItem}
            companyListStateIndex={companyListStateIndex}
          />
        ))}
      </AACBlockContent>

      <AACBlockFooter>
        <AACBlockButtonCancel
          onClick={onCancelClick}
          disabled={isDisabledCancelBtn}
        >
          {t('addChargeMenu.cancel')}
        </AACBlockButtonCancel>
        <AACBlockButtonSave
          onClick={onSaveClick}
          disabled={isDisabledSaveBtn}
        >
          {t('addChargeMenu.add')}
        </AACBlockButtonSave>
      </AACBlockFooter>
    </AACBlockWrap>
  );
};

const AddressesAndContactsComponentCached = memo(AddressesAndContactsComponent);

export { AddressesAndContactsComponentCached as AddressesAndContactsComponent };
