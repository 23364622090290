import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Container } from 'shipment-operations/view/components';
import {
  Transportation, Services, Fees, Additional,
} from 'shipment-operations/view/pages/CreateCreditNote/components/FirstStep/components';
import { Button } from 'app-wrapper/view/components';
import { StickyFooter } from 'shipment-operations/view/pages/CreateCreditNote/CreateCreditNote.styled';

interface IFirstStepProps {
  showFooter: boolean
  onClickNext: () => void
}

const FirstStep: FC<IFirstStepProps> = ({ showFooter, onClickNext }) => {
  const { t } = useTranslation();

  return (
    <Container mBottom="60px">
      <Transportation />
      <Services />
      <Fees />
      <Additional />
      {showFooter && (
        <StickyFooter>
          <Button size="large" onClick={onClickNext}>{t('next')}</Button>
        </StickyFooter>
      )}
    </Container>
  );
};

export { FirstStep };
