import { createSelector } from 'reselect';

import { RootState } from 'app-wrapper/store';

import { CargoBaseDTM, CargoDTM } from 'shipment-operations/models/dtm';

const localState = (state: RootState) => state.cargo;

const getCargoBase = <T extends CargoBaseDTM>(cargo: T) => ({
  code: cargo.code,
  name: cargo.name,
  description: cargo.description,
  value: cargo.value,
  packageType: cargo.packageType,
  packagesNumber: cargo.packagesNumber,
  weight: cargo.weight,
  volume: cargo.volume,
  references: cargo.references.map((ref) => ({ type: ref.type, value: ref.value })),
  marks: cargo.marks,

  unNumber: cargo.unNumber,
  imoClass: cargo.imoClass,
  packingGroup: cargo.packingGroup,
  shippingName: cargo.shippingName,
  msdsDocument: cargo.msdsDocument,
  contactName: cargo.contactName,
  contactNumber: cargo.contactNumber,
});

const isCargoInDraft = (cargo: CargoDTM) => {
  const cargoBase = getCargoBase(cargo);
  const initialStateBase = getCargoBase(cargo.initialState);

  const areReferencesEqual = JSON.stringify(cargoBase.references) === JSON.stringify(initialStateBase.references);
  const areMSDSDocumentsEqual = JSON.stringify(cargoBase.msdsDocument) === JSON.stringify(initialStateBase.msdsDocument);

  return !(
    cargoBase.code === initialStateBase.code
    && cargoBase.description === initialStateBase.description
    && cargoBase.value === initialStateBase.value
    && cargoBase.packageType === initialStateBase.packageType
    && cargoBase.packagesNumber === initialStateBase.packagesNumber
    && cargoBase.weight === initialStateBase.weight
    && cargoBase.volume === initialStateBase.volume
    && areReferencesEqual
    && cargoBase.marks === initialStateBase.marks
    && cargoBase.unNumber === initialStateBase.unNumber
    && cargoBase.imoClass === initialStateBase.imoClass
    && cargoBase.packingGroup === initialStateBase.packingGroup
    && cargoBase.shippingName === initialStateBase.shippingName
    && areMSDSDocumentsEqual
    && cargoBase.contactName === initialStateBase.contactName
    && cargoBase.contactNumber === initialStateBase.contactNumber
  );
};

const getDraftStatusOfEachCargo = createSelector(
  localState,
  (state) => state.cargos.map(isCargoInDraft),
);

const getCargoDraftStatus = createSelector(
  localState,
  (state) => {
    if (state.isLoading) {
      return false;
    }

    return isCargoInDraft(state.cargos[state.selectedCargo]);
  },
);

const getAllCargoInformation = createSelector(
  localState,
  (state) => state,
);

const getCargos = createSelector(
  localState,
  (state) => state.cargos,
);

const getCargo = createSelector(
  localState,
  (state) => state.cargos[state.selectedCargo],
);

const getSelectedCargo = createSelector(
  localState,
  (state) => state.selectedCargo,
);

const getTemperatureControl = createSelector(
  localState,
  (state) => state.temperatureControl,
);

const getLoading = createSelector(
  localState,
  (state) => state.isLoading,
);

export const cargoSelectors = {
  getAllCargoInformation,
  getCargos,
  getDraftStatusOfEachCargo,
  getSelectedCargo,
  getCargo,
  getCargoDraftStatus,
  getTemperatureControl,
  getLoading,
};
