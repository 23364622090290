import { EOrganizationMemberRole, EPermissionAttributePolicy } from 'monetary/constants';
import { ModuleFunctionalityPermissionsDtm } from 'monetary/models/dtm';
import { PermissionAttributePolicy } from 'shipment-operations/constants';

export class ModuleFunctionalityPermissionsService {
  public getFunctionalityPermissions = (userRole?: EOrganizationMemberRole) => {
    if (userRole === EOrganizationMemberRole.CUSTOMER) {
      return ModuleFunctionalityPermissionsDtm.fromPlain({
        requestAllQuotas: {
          quotaItemContractName: undefined,
          organizationsListLoad: undefined,
        },
        RFQNewQuotaAvailability: {
          quotaItemContractName: undefined,
          rfqFormClientInformation: undefined,
          newQuotaClientAdmin: EPermissionAttributePolicy.READ,
          selfService: EPermissionAttributePolicy.WRITE,
        },
        shipmentCharges: {
          shipmentChargesAvailability: EPermissionAttributePolicy.READ,
          editPayablesPayment: undefined,
        },
        ratesUpload: {
        },
      });
    }

    if (userRole === EOrganizationMemberRole.admin) {
      return ModuleFunctionalityPermissionsDtm.fromPlain({
        requestAllQuotas: {
          quotaItemContractName: EPermissionAttributePolicy.WRITE,
          organizationsListLoad: EPermissionAttributePolicy.WRITE,
        },
        RFQNewQuotaAvailability: {
          quotaItemContractName: EPermissionAttributePolicy.WRITE,
          rfqFormClientInformation: EPermissionAttributePolicy.READ,
          newQuotaClientAdmin: EPermissionAttributePolicy.WRITE,
          selfService: undefined,
        },
        shipmentCharges: {
          shipmentChargesAvailability: EPermissionAttributePolicy.WRITE,
          editPayablesPayment: undefined,
        },
        ratesUpload: {
          ratesClearCacheAvailability: EPermissionAttributePolicy.WRITE,
        },
      });
    }

    if (userRole === EOrganizationMemberRole.PARTNER) {
      return ModuleFunctionalityPermissionsDtm.fromPlain({
        requestAllQuotas: {
          quotaItemContractName: EPermissionAttributePolicy.WRITE,
          organizationsListLoad: EPermissionAttributePolicy.WRITE,
        },
        RFQNewQuotaAvailability: {
          quotaItemContractName: EPermissionAttributePolicy.WRITE,
          rfqFormClientInformation: EPermissionAttributePolicy.READ,
          newQuotaClientAdmin: EPermissionAttributePolicy.WRITE,
          selfService: undefined,
        },
        shipmentCharges: {
          shipmentChargesAvailability: EPermissionAttributePolicy.WRITE,
          editPayablesPayment: PermissionAttributePolicy.WRITE,
        },
        ratesUpload: {
          ratesClearCacheAvailability: EPermissionAttributePolicy.WRITE,
        },
      });
    }

    // default return - member setting
    return ModuleFunctionalityPermissionsDtm.fromPlain({
      requestAllQuotas: {
        quotaItemContractName: EPermissionAttributePolicy.WRITE,
        organizationsListLoad: EPermissionAttributePolicy.WRITE,
      },
      RFQNewQuotaAvailability: {
        quotaItemContractName: EPermissionAttributePolicy.WRITE,
        newQuotaClientAdmin: undefined,
        selfService: undefined,
      },
      shipmentCharges: {
        shipmentChargesAvailability: EPermissionAttributePolicy.WRITE,
        editPayablesPayment: undefined,
      },
      ratesUpload: {
      },
    });
  }
}
