import styled from 'styled-components';

export const Errors = styled.div.attrs({
  'data-class': 'errors',
})`
  position: absolute;
  z-index: 2;
  color:  #ff0000;
`;

export const Error = styled.p.attrs({
  'data-class': 'error',
})`
  margin: 1px 1px;
`;
