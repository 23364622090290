import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Tooltip } from 'app-wrapper/view/components';
import { FlexCol } from 'app-wrapper/view/guideline';
import { ShippingPartyOverviewDTM } from 'shipment-operations/models/dtm';

import { Wrap, TextRow, WarningIcon } from './ShippingPartyInfo.styled';

const MAX_ROW_LENGTH = 35;

interface IShippingPartyInfoComponentProps {
  shippingParty: ShippingPartyOverviewDTM;
}

export const ShippingPartyInfo: FC<IShippingPartyInfoComponentProps> = ({
  shippingParty,
}) => {
  const { t } = useTranslation();
  const {
    address,
    companyName,
    contactPerson,
    email,
    phone,
    references,
    taxId = '',
  } = shippingParty;
  const {
    address1 = '',
    address2 = '',
    city = '',
    state = '',
    country = '',
    postalCode = '',
  } = address || {};
  const joinedReferences = useMemo(() => references.map((reference) => reference.value).join(', '), [references]);

  const rows = useMemo(() => [
    companyName,
    address1,
    address2,
    `${city}${state ? `, ${state}` : ''}, ${country}, ${postalCode}`,
    contactPerson,
    `${email}, ${phone}`,
    joinedReferences,
    taxId,
  ], [companyName, address1, address2, city, state, country, postalCode, contactPerson, email, phone, references, taxId, joinedReferences]);

  return (
    <Wrap>
      {rows.map((row, index) => {
        if (!row) {
          return null;
        }
        const allowedLengthText = row.substring(0, MAX_ROW_LENGTH);
        const textOverLimit = row.substring(MAX_ROW_LENGTH);

        return (
          <FlexCol
            key={String(index)}
          >
            <TextRow>{allowedLengthText}</TextRow>

            {textOverLimit ? (
              <TextRow>
                {textOverLimit}

                <Tooltip title={t('Some of your information exceeds 35 letters limit')}>
                  <WarningIcon />
                </Tooltip>
              </TextRow>
            ) : null}
          </FlexCol>
        );
      })}
    </Wrap>
  );
};
