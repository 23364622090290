import { apiWorker } from 'app-wrapper/repository/utilsServices';
import { IGetContractsFiltersBody, IPostContractContract } from 'monetary/models/contracts';
import { ContractDTM } from 'shipment-operations/models/dtm';

export class ContractsService {
  private baseUrl = 'rate-service/api/v1/ocean/contracts';

  public getContracts = async (body: IGetContractsFiltersBody) => {
    let contracts: ContractDTM[] = [];

    const response = await apiWorker.requestGetBySchema(this.baseUrl as '/api/v1/ocean/contracts', {
      params: body,
    });

    contracts = response.data.map((contract) => ContractDTM.fromPlain({
      ...contract,
      number: contract.number as string,
      id: contract.id as number,
    }));

    return contracts;
  }

  public createContract = async (body: IPostContractContract) => {
    await apiWorker.requestPostBySchema(this.baseUrl as '/api/v1/ocean/contracts', body);
  }

  public editContract = async (contract: ContractDTM) => {
    const response = await apiWorker.requestPutBySchema(`${this.baseUrl}/${contract.id}` as '/api/v1/ocean/contracts/{contractId}', contract);

    return ContractDTM.fromPlain({
      ...response.data,
      number: contract.number as string,
      id: contract.id as number,
    });
  }

  public deleteContract = async (contractId: number) => {
    await apiWorker.requestDeleteBySchema(`${this.baseUrl}/${contractId}` as '/api/v1/ocean/contracts/{contractId}');
  }
}
