import React, { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  RFQServiceByIdContentRoutesTransportation,
} from 'monetary/models/dtm/Quotas';

import {
  Wrapper,
  Text,
  InputStyled,
} from './VoyageInput.styled';

interface VesselInputComponentProps {
  transportation: RFQServiceByIdContentRoutesTransportation;
  onChange: (value: string, transportationId: number) => void;
}

export const VoyageInputComponent: FC<VesselInputComponentProps> = ({ transportation, onChange }) => {
  const { t } = useTranslation();

  const voyageName = useMemo(() => transportation.transport.number || '', [transportation]);

  const handleChange = useCallback((e) => onChange(e.target.value, transportation.id), [onChange, transportation]);

  return (
    <Wrapper>
      <Text>
        {t('Voyage')}
      </Text>

      <InputStyled
        onChange={handleChange}
        value={voyageName}
      />
    </Wrapper>
  );
};
