import {
  IsArray,
  IsDefined,
  IsNumber,
  IsString,
} from 'class-validator';
import { BaseDTM } from 'proto/BaseDTM';

export interface IShareInvoiceShipmentDetailsDTM {
  title: string;
  subtitles: string[];
}

export class ShareInvoiceShipmentDetailsDTM extends BaseDTM<IShareInvoiceShipmentDetailsDTM> {
  @IsDefined()
  @IsString()
  title: string;

  @IsDefined()
  @IsArray()
  subtitles: string[];
}

export interface IShareInvoiceChargeDTM {
  chargeName: string;
  currency: string;
  unit: string;
  costPerUnit: number;
  quantity: number;
  total: number;
}

export class ShareInvoiceChargeDTM extends BaseDTM<IShareInvoiceChargeDTM> {
  @IsString()
  @IsDefined()
  chargeName: string;

  @IsDefined()
  @IsString()
  currency: string;

  @IsDefined()
  @IsString()
  unit: string;

  @IsDefined()
  @IsNumber()
  costPerUnit: number;

  @IsDefined()
  @IsNumber()
  quantity: number;

  @IsDefined()
  @IsNumber()
  total: number;
}
