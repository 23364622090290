import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import {
  SBLInformation,
  SBLTitle,
  SBLRow,
  SBLItem,
  SBLItemTitle,
  SBLItemSubTitle,
  SBLHeader,
  SBLItemSubTitleGrey,
} from './SBLPaymentTerms.styled';
import { SBLEditButton } from '../SBLEditButton';

interface ISBLPaymentTermsComponentProps {
  items: Array<{
    title: string;
    subtitle: string;
    subtitleGrey?: string;
    isSubtitleInTitle?: boolean;
  }>;
  onOpenEdit?: () => void;
  isEditable: boolean;
}

export const SBLPaymentTermsComponent: FC<ISBLPaymentTermsComponentProps> = ({
  items,
  onOpenEdit,
  isEditable,
}) => {
  const { t } = useTranslation();

  return (
    <SBLInformation>
      <SBLHeader>
        <SBLTitle>
          {t('Payments')}
        </SBLTitle>

        {isEditable && onOpenEdit ? (
          <SBLEditButton onClick={onOpenEdit}>
            {t('Edit')}
          </SBLEditButton>
        ) : null}
      </SBLHeader>

      <SBLRow>
        {items.map(({
          title, subtitle, subtitleGrey, isSubtitleInTitle,
        }) => (
          <SBLItem key={title}>
            <SBLItemTitle>
              {title}{isSubtitleInTitle && subtitleGrey ? ` (${subtitleGrey})` : null}
            </SBLItemTitle>

            <SBLRow>
              <SBLItemSubTitle>
                {subtitle}
              </SBLItemSubTitle>

              {subtitleGrey && !isSubtitleInTitle ? (
                <SBLItemSubTitleGrey>
                  {subtitleGrey}
                </SBLItemSubTitleGrey>
              ) : null}
            </SBLRow>
          </SBLItem>
        ))}
      </SBLRow>
    </SBLInformation>
  );
};
