import React from 'react';
import { useSelector } from 'react-redux';

import { R } from 'app-wrapper/repository';
import { UC } from 'app-wrapper/controllers';
import { UC as ShipmentUC } from 'shipment-operations/controllers';

import { AlertsTableComponent } from './AlertsTable.component';

const AlertsTableContainer = () => {
  const {
    isLoadingTasksBoard,
  } = useSelector(R.selectors.overview.getOverview);
  const tasks = useSelector(R.selectors.overview.getTasksToRender);
  const countAllTasks = useSelector(R.selectors.overview.getCountAllTasks);

  return (
    <AlertsTableComponent
      openAgreementsDrawer={UC.agreements.openDrawerFromTasks}
      openVGMDrawer={UC.drawer.openVGMTaskDrawer}
      openRailBillingDrawer={UC.drawer.openRailBillingDrawer}
      openMismatchDrawer={UC.drawer.openShipmentMismatches}
      openMaerskDrawer={UC.drawer.openMaerskChanges}
      openChangesDrawer={UC.drawer.openShipmentChanges}
      openShipmentCostChangesDrawer={UC.drawer.openShipmentCostChangesDrawer}
      openBookingConfirmationDrawer={UC.drawer.openBookingConfirmation}
      allTasks={tasks}
      countAllTasks={countAllTasks}
      isLoading={isLoadingTasksBoard}
      setShipmentId={ShipmentUC.overview.setShipmentId}
    />
  );
};

export { AlertsTableContainer as AlertsTable };
