import React from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';
import { UC } from 'shipment-operations/controllers';

import { ShipmentFreeTime } from './ShipmentFreeTime.component';

const ShipmentFreeTimeContainer = () => {
  const isLoading = useSelector(R.selectors.shipmentFreeTime.getIsLoading);
  const isError = useSelector(R.selectors.shipmentFreeTime.getError);
  const originData = useSelector(R.selectors.shipmentFreeTime.getGroupedOrigin);
  const freightData = useSelector(R.selectors.shipmentFreeTime.getGroupedFreight);
  const destinationData = useSelector(R.selectors.shipmentFreeTime.getGroupedDestination);
  const permissions = useSelector(R.selectors.shipment.getShipmentChargesPermissions);

  return (
    <ShipmentFreeTime
      fetchData={UC.shipmentFreeTime.loadData}
      isLoading={isLoading}
      originData={originData}
      freightData={freightData}
      destinationData={destinationData}
      permissions={permissions}
      isError={isError}
    />
  );
};

export { ShipmentFreeTimeContainer as ShipmentFreeTime };
