import React, { FC, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Spinner,
  SpinnerWrap,
  SubWrapper, SubHeader,
} from 'app-wrapper/view/components/Layout';
import { UsersPermissionsDtm } from 'user-management/models/dtm/ModuleFunctionalityPermissions/UsersPermissions.dtm';
import { Button } from 'app-wrapper/view/components';
import { hasAccess } from 'app-wrapper/utils';
import { PermissionAttributePolicy } from 'shipment-operations/constants';
import Row from 'antd/es/row';
import Col from 'antd/es/col';

interface UsersComponentProps {
  init: () => void;
  usersPermissions?: UsersPermissionsDtm
  isLoading: boolean
  downloadUsersFile: () => void;
}

export const UsersComponent: FC<UsersComponentProps> = ({
  init, usersPermissions, isLoading, downloadUsersFile,
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    init();
  }, []);

  const handleClickDownloadButton = useCallback(() => {
    downloadUsersFile();
  }, []);

  return (
    <SubWrapper>
      <SubHeader>
        {isLoading ? (
          <SpinnerWrap>
            <Spinner />
          </SpinnerWrap>
        ) : (
          <Row justify="space-between">
            <Col span={4} style={{ textAlign: 'left' }}>
              {t('All users')}
            </Col>
            <Col span={4}>
              <Row justify="end">
                {hasAccess(usersPermissions?.downloadUsersButton, PermissionAttributePolicy.WRITE)
                  ? <Button onClick={handleClickDownloadButton}>{t('Export Users')}</Button> : null}
              </Row>
            </Col>
          </Row>
        )}
      </SubHeader>
    </SubWrapper>
  );
};
