import React, { FC, memo } from 'react';
import { useSelector } from 'react-redux';

import { R } from 'user-management/repository';
import { UC } from 'user-management/controllers';

import { REQUEST_STATUS } from 'app-wrapper/constants';

import { AddressesAndContactsComponent } from './AddressesAndContacts.component';

const AddressesAndContactsContainer: FC = () => {
  const getDefaultCompanyPrimaryState = useSelector(R.selectors.contactBook.getDefaultCompanyPrimaryState);
  const contactBookDefault = useSelector(R.selectors.contactBook.getContactBookDefault);
  const requestStatus = contactBookDefault?.getRequestStatus;
  const addresses = useSelector(R.selectors.contactBook.getContactBookDefaultCompanyListAddressesByIds(getDefaultCompanyPrimaryState?.addressesIds || []));

  return (
    <AddressesAndContactsComponent
      addresses={addresses}
      isLoading={requestStatus === REQUEST_STATUS.pending}
      runComponent={UC.contactBook.onRunAddressAndContactsPage}
      onClickAddressItem={UC.contactBook.onNextPageAddressesAndContactsAddressById}
      onClickAddAddress={UC.contactBook.openAddressesAndContactsAddAddressDrawer}
      onClickEditAddress={UC.contactBook.onEditAddressesAndContactsAddressById}
    />
  );
};

const AddressesAndContactsContainerCached = memo(AddressesAndContactsContainer);

export { AddressesAndContactsContainerCached as AddressesAndContactsPage };
