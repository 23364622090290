import React, { FC } from 'react';

import { useAppSelector } from 'app-wrapper/hooks';
import { R } from 'shipment-operations/repository';

import { HeaderComponent } from 'shipment-operations/view/components/ShipmentEntities';

interface HeaderProps {
  isAPInvoiceType: boolean;
  isLoading?: boolean;
}

const Header: FC<HeaderProps> = ({ isAPInvoiceType, isLoading }) => {
  const status = useAppSelector(R.selectors.shipmentBillingInvoice.getStatus);
  const number = useAppSelector(R.selectors.shipmentBillingInvoice.getNumber);
  const invoice = useAppSelector(R.selectors.shipmentBillingInvoice.getInvoice);

  return (
    <HeaderComponent
      invoiceId={invoice?.id}
      status={status}
      number={number}
      title="invoiceTitle"
      isAPInvoiceType={isAPInvoiceType}
      isLoading={isLoading}
    />
  );
};

export { Header };
