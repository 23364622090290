import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  Popover,
  InfoIcon,
  Content,
} from './OptionalIcon.styled';

export const OptionalIcon = () => {
  const { t } = useTranslation();

  return (
    <Popover
      overlayInnerStyle={{
        padding: 0,
        backgroundColor: 'rgba(32, 44, 60, 1)',
      }}
      placement="bottom"
      content={(
        <Content>
          {t('If you have any additional documents that will allow us to speed up verification, please attach them in this section')}
        </Content>
      )}
    >
      <InfoIcon />
    </Popover>
  );
};
