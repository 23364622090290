import { divFlex } from 'app-wrapper/view/themes/mixin';
import styled, { css } from 'styled-components';

// Content

const placeholderText = css`
  color: ${({ theme }) => theme.themesColors.secondaryDot45};
  opacity: 1;
`;

const borderColor = css`
  border-color: ${({ theme }) => theme.themesColors.middleGrayDisable} !important;
`;

const titleText = css`
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  color: ${({ theme }) => theme.themesColors.neutralBranding10};
  margin-top: 16px;
  margin-bottom: 8px;
`;

export const AllRequestedQuotesComponentFormDiv = styled.div.attrs({
  'data-class': 'AllRequestedQuotesComponentFormDiv',
})`
  ${divFlex}
  width: 100%;
  height: 100%;
  padding-right: 24px;
  .FreightQuoteFormRequest__Row {
    margin-bottom: -1px;
  }
`;

export const AllRequestedQuotesComponentFormSearch = styled.div`
  ${divFlex}
`;

export const AllRequestedQuotesComponentFormSearchInput = styled.div`
  ${divFlex}
  width: 100%;
  input, button {
    ${borderColor}
  }
  input::placeholder {
    ${placeholderText}
  }
  svg {
    color: ${({ theme }) => theme.themesColors.neutralBranding10}
  }
`;

export const AllRequestedQuotesComponentFormOrigin = styled.div`
  ${divFlex}
  flex-direction: column;
`;

export const AllRequestedQuotesComponentFormOriginText = styled.div`
  ${divFlex}
  ${titleText}
`;

export const AllRequestedQuotesComponentFormOriginSelect = styled.div`
  ${divFlex}
  width: 100%;
  .Select {
    width: 100%;
  }
  .ant-select-selection-placeholder {
    ${placeholderText}
  }
  .ant-select-selector {
    ${borderColor}
  }
`;

export const AllRequestedQuotesComponentFormDestination = styled.div`
  ${divFlex}
  flex-direction: column;
`;

export const AllRequestedQuotesComponentFormDestinationText = styled.div`
  ${divFlex}
  ${titleText}
`;

export const AllRequestedQuotesComponentFormDestinationSelect = styled.div`
  ${divFlex}
  width: 100%;
  .Select {
    width: 100%;
  }
  .ant-select-selection-placeholder {
    ${placeholderText}
  }
  .ant-select-selector {
    ${borderColor}
  }
`;

export const AllRequestedQuotesComponentFormDate = styled.div`
  ${divFlex}
  flex-direction: column;
`;

export const AllRequestedQuotesComponentFormDateText = styled.div`
  ${divFlex}
  ${titleText}
`;

export const AllRequestedQuotesComponentFormDateRange = styled.div`
  ${divFlex}
  width: 100%;
  input::placeholder {
    ${placeholderText}
  }
  svg {
    color: ${({ theme }) => theme.themesColors.middleGraySecondary}
  }
  .ant-picker {
    color: ${({ theme }) => theme.themesColors.middleGraySecondary}
    ${borderColor}
  }
`;
