import { apiWorker } from 'app-wrapper/repository/utilsServices';
import {
  GetPaymentTermsResponse,
  PostPaymentTermsResponse,
  PutPaymentTermsResponse,
} from 'shipment-operations/models/contracts';
import { PaymentTermsDTM } from 'shipment-operations/models/dtm';

export class PaymentTermsService {
  public getShipmentPaymentTerms = async (shipmentId: string, removeSignal?: boolean): Promise<PaymentTermsDTM | null> => {
    let paymentTerms: PaymentTermsDTM | null = null;

    const response = await apiWorker.requestGet<GetPaymentTermsResponse>(this.formatUrl(shipmentId), {}, removeSignal);

    if (response.status !== 204) {
      paymentTerms = PaymentTermsDTM.fromPlain(response.data);
    }

    return paymentTerms;
  }

  public postShipmentPaymentTerms = async (shipmentId: string, paymentTermsBody: PaymentTermsDTM): Promise<PaymentTermsDTM> => {
    let paymentTerms: PaymentTermsDTM | null = null;

    try {
      const response = await apiWorker.requestPost<PostPaymentTermsResponse>(this.formatUrl(shipmentId), paymentTermsBody);

      if (response.status === 200) {
        paymentTerms = PaymentTermsDTM.fromPlain(response.data);
      }
    } catch (e) {
      throw new Error('PaymentTermsService: getShipmentPaymentTerms error');
    }

    return paymentTerms as PaymentTermsDTM;
  }

  public putShipmentPaymentTerms = async (shipmentId: string, paymentTermsBody: PaymentTermsDTM): Promise<PaymentTermsDTM> => {
    let paymentTerms: PaymentTermsDTM | null = null;

    try {
      const response = await apiWorker.requestPut<PutPaymentTermsResponse>(this.formatUrl(shipmentId), paymentTermsBody);

      if (response.status === 200) {
        paymentTerms = PaymentTermsDTM.fromPlain(response.data);
      }
    } catch (e) {
      throw new Error('PaymentTermsService: getShipmentPaymentTerms error');
    }

    return paymentTerms as PaymentTermsDTM;
  }

  private formatUrl = (shipmentId?: string) => (
    `/shipment-service/api/v1/shipments/${shipmentId}/payment-terms`
  );
}
