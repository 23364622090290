import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { CustomCell } from 'shipment-operations/view/components/CustomTable/CustomTable.styled';
import { measureByConst } from 'shipment-operations/constants';
import getOr from 'lodash/fp/getOr';

import { DataType } from 'shipment-operations/view/components';
import isEmpty from 'lodash/fp/isEmpty';
import Tooltip from 'antd/es/tooltip';
import InfoCircleOutlined from '@ant-design/icons/InfoCircleOutlined';

const useTableColumns = () => {
  const { t } = useTranslation();

  const fullColumns = useMemo(() => [
    {
      title: t('createInvoiceTable.service'),
      dataIndex: 'service',
      key: 'service',
      width: '30%',
      render: (title: string, record: DataType | {}) => {
        const includedCharges = getOr([], ['includedCharges'], record);
        return (
          <>
            <CustomCell>{title}</CustomCell>
            {!isEmpty(includedCharges) && (
              <Tooltip
                title={includedCharges.map((item: string) => <p key={`createInvoiceTable_${item}`} style={{ margin: '0 3px 0 0', textTransform: 'capitalize' }}>{item}</p>)}
                placement="top"
              >
                <InfoCircleOutlined style={{ marginLeft: '7px', cursor: 'pointer' }} />
              </Tooltip>
            )}
          </>
        );
      },
    },
    {
      title: t('createInvoiceTable.measure'),
      dataIndex: 'measure',
      key: 'measure',
      width: '20%',
      render: (title: string) => <span>{t(`createInvoiceTable.${measureByConst[title as keyof typeof measureByConst]}`)}</span>,
    },
    {
      title: t('createInvoiceTable.cost'),
      dataIndex: 'cost',
      key: 'cost',
      align: 'right' as 'right',
      width: '16.5%',
      render: (sum: number) => `$ ${sum}`,
    },
    {
      title: t('createInvoiceTable.quantity'),
      dataIndex: 'quantity',
      key: 'quantity',
      align: 'right' as 'right',
      width: '16.5%',
    },
    {
      title: t('createInvoiceTable.total'),
      dataIndex: 'total',
      key: 'total',
      align: 'right' as 'right',
      width: '16.5%',
      render: (sum: number) => `$ ${sum}`,
    },
  ], []);

  const columns = useMemo(() => [
    {
      title: t('createInvoiceTable.service'),
      dataIndex: 'service',
      key: 'service',
      width: '80%',
      render: (title: string, record: DataType | {}) => {
        const includedCharges = getOr([], ['includedCharges'], record);
        return (
          <>
            <CustomCell>{title}</CustomCell>
            {!isEmpty(includedCharges) && (
              <Tooltip
                title={includedCharges.map((item: string) => <p key={`createInvoiceTable_columns_${item}`} style={{ margin: '0 3px 0 0', textTransform: 'capitalize' }}>{item}</p>)}
                placement="top"
              >
                <InfoCircleOutlined style={{ marginLeft: '7px', cursor: 'pointer' }} />
              </Tooltip>
            )}
          </>
        );
      },
    },
    {
      title: t('createInvoiceTable.total'),
      dataIndex: 'total',
      key: 'total',
      align: 'right' as 'right',
      width: '20%',
      render: (sum: number) => `$ ${sum}`,
    },
  ], []);

  return { columns, fullColumns };
};

export { useTableColumns };
