import styled from 'styled-components';

import { FlexRow } from 'app-wrapper/view/guideline';

export const Wrap = styled(FlexRow)`
  gap: 16px;
`;

export const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  width: 32px;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.themesColors.primaryBranding1};
  
  svg {
    color: ${({ theme }) => theme.themesColors.primaryBranding6};
    font-size: 16px;
  }
`;

export const Label = styled.span`
  font-size: 12px;
  line-height: 20px;
  color: ${({ theme }) => theme.themesColors.characterSecondaryDot45};
`;

export const Value = styled.span`
  font-size: 14px;
  line-height: 22px;
`;
