import { RootState } from 'app-wrapper/store';
import { createSelector } from 'reselect';

const localState = (state: RootState) => state.signUpSuccess;

const getEmail = createSelector(
  localState,
  (state) => state.email,
);

export const signUpSuccessSelectors = {
  getEmail,
};
