import { RootState } from 'app-wrapper/store';

const getShipmentDocument = (state: RootState) => (
  state.shipmentDocument
);

const getShipmentDocumentDefault = (state: RootState) => (
  state.shipmentDocument.defaultState
);

const getShipmentDocumentUpdate = (state: RootState) => (
  state.shipmentDocument.updateState
);

const getShipmentDocumentUpdateIsUpdate = (state: RootState) => (
  state.shipmentDocument.updateState.isUpdate
);

export const shipmentDocumentSelectors = {
  getShipmentDocument,
  getShipmentDocumentDefault,
  getShipmentDocumentUpdate,
  getShipmentDocumentUpdateIsUpdate,
};
