import React, { FC, useMemo } from 'react';

import { ContainerWithCargoDTM, CargoDTM } from 'shipment-operations/models/dtm';
import { CONTAINER_TYPES_TO_NAME } from 'shipment-operations/constants';
import { Bold } from 'shipment-operations/view/components/HBLDocumentPDF/components';

import { GoodsDescriptionItem } from '../GoodsDescriptionItem';
import { TableRow, Cell, StyledSign } from './ContainersLoadItem.styled';

interface ContainersLoadItemProps {
  item: ContainerWithCargoDTM
}

export const ContainersLoadItem: FC<ContainersLoadItemProps> = ({ item }) => {
  const {
    packagesNumber,
    weight,
    volume,
  } = useMemo(() => item.cargoItems.reduce((start, next) => ({
    packagesNumber: Number(start.packagesNumber) + Number(next.packagesNumber),
    weight: Number(start.weight) + Number(next.weight),
    volume: Number(start.volume) + Number(next.volume),
  }), { packagesNumber: 0, weight: 0, volume: 0 }), []);

  return (
    <>
      <TableRow>
        <Cell justifyContent="flex-start">
          <StyledSign key={item.number}>{CONTAINER_TYPES_TO_NAME[item.type]}</StyledSign>
        </Cell>
        <Cell justifyContent="flex-start"><Bold>{item.number}</Bold></Cell>
        <Cell justifyContent="flex-start"><Bold>{item.sealNumber}</Bold></Cell>
        <Cell justifyContent="flex-end" />
        <Cell justifyContent="center"><Bold>{packagesNumber}</Bold></Cell>
        <Cell justifyContent="flex-end"><Bold>{weight.toFixed(2)}</Bold></Cell>
        <Cell justifyContent="flex-end"><Bold>{volume.toFixed(2)}</Bold></Cell>
      </TableRow>
      {item.cargoItems?.map((cargoItem) => (
        <GoodsDescriptionItem
          key={`${item.id}-${cargoItem.id}`}
          item={CargoDTM.fromPlain({
            ...cargoItem.cargo,
            packagesNumber: cargoItem.packagesNumber,
            weight: cargoItem.weight,
            volume: cargoItem.volume,
          })}
          forContainers
        />
      ))}
    </>
  );
};
