import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Option } from 'app-wrapper/view/components';
import { EPolicyTemplateSize, PolicyTemplateSizesNames } from 'user-management/constants';

import {
  LinkIcon,
  Select,
  OptionContent,
} from './DownloadTemplateSelect.styled';

const OPTIONS = [
  {
    value: EPolicyTemplateSize.S,
    description: PolicyTemplateSizesNames[EPolicyTemplateSize.S],
    href: 'https://docs.google.com/spreadsheets/d/17hHFnlktAd1IjyvJBR7OoVdoN7eM9NDj/edit?gid=149780108#gid=149780108',
  },
  {
    value: EPolicyTemplateSize.SM,
    description: PolicyTemplateSizesNames[EPolicyTemplateSize.SM],
    href: 'https://docs.google.com/spreadsheets/d/1Z3i-oFeTQUQMMtSHMbvJRUPtZkv5uI2p/edit?gid=149780108#gid=149780108',
  },
  {
    value: EPolicyTemplateSize.M,
    description: PolicyTemplateSizesNames[EPolicyTemplateSize.M],
    href: 'https://docs.google.com/spreadsheets/d/1VDijWobMn1rUwWd0CDcz2seI23C669D3/edit?gid=149780108#gid=149780108',
  },
  {
    value: EPolicyTemplateSize.L,
    description: PolicyTemplateSizesNames[EPolicyTemplateSize.L],
    href: 'https://docs.google.com/spreadsheets/d/1eALL4Itw-4xChpM-JmmxNdru1P-IxVYV/edit?gid=149780108#gid=149780108',
  },
];

interface IDownloadTemplateSelectProps {
  value?: EPolicyTemplateSize;
  setValue: (value: EPolicyTemplateSize) => void;
}

export const DownloadTemplateSelect: FC<IDownloadTemplateSelectProps> = ({ value, setValue }) => {
  const { t } = useTranslation();

  const handleLinkClick = useCallback((e) => {
    e.stopPropagation();
  }, []);

  return (
    <Select
      value={value}
      onChange={setValue}
      placeholder={t('Select & Download Template')}
    >
      {OPTIONS.map(({ value: _value, description, href }) => (
        <Option value={_value} key={value}>
          <OptionContent>
            {description}

            <a
              onClick={handleLinkClick}
              rel="noreferrer"
              href={href}
              target="_blank"
            >
              <LinkIcon />
            </a>
          </OptionContent>
        </Option>
      ))}
    </Select>
  );
};
