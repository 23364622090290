import React, { FC, memo } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { R } from 'user-management/repository';
import { UC } from 'user-management/controllers';

import { CustomersInternalComponent } from '../CustomersInternal/CustomersInternal.component';

export const PartnerOrganizationContainer: FC = memo(() => {
  const organization = useSelector(R.selectors.userOrganizationData.getInternalOrganization);
  const isAdmin = useSelector(R.selectors.userOrganizationData.isAdmin);

  const { customerId } = useParams<'customerId'>();
  const partnerOrganizationProfile = useSelector(R.selectors.moduleFunctionalityPermissions.getPartnerOrganizationProfilePermissions);

  return (
    <CustomersInternalComponent
      onStart={UC.organizationProfile.onStartCustomerInternalPage(customerId || '')}
      onEnd={UC.organizationProfile.onEndCustomerInternalPage}
      organization={organization}
      isAdmin={isAdmin}
      permissions={partnerOrganizationProfile}
      onEditProfileOrganization={UC.organizationProfile.openEditPartnerProfileOrganization}
    />
  );
});
