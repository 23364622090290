import { createSelector } from 'reselect';

import { RootState } from 'app-wrapper/store';
import { FRT } from 'monetary/constants';

const localState = (state: RootState) => state.addRates;

export const getIsLoading = createSelector(
  localState,
  (state) => state.isLoading,
);

export const getChargeCodes = createSelector(
  localState,
  (state) => state.chargeCodes,
);

export const getChargeCodesOptions = createSelector(
  getChargeCodes,
  (chargeCodes) => chargeCodes.map((chargeCode) => ({
    value: chargeCode.code,
    label: chargeCode.description,
  })),
);

export const getUniqContainers = createSelector(
  localState,
  (state) => state.uniqContainers,
);

export const getEditableDataByContainer = createSelector(
  localState,
  (state) => state.editableDataByContainer,
);

export const getEditableDataByShipment = createSelector(
  localState,
  (state) => state.editableDataByShipment,
);

export const getContractNumber = createSelector(
  localState,
  (state) => state.contractNumber,
);

export const getValidFrom = createSelector(
  localState,
  (state) => state.validFrom,
);

export const getValidTo = createSelector(
  localState,
  (state) => state.validTo,
);

export const getFRTChargeCode = createSelector(
  localState,
  (state) => state.frtCharge,
);

export const getFRTCharge = createSelector(
  getEditableDataByContainer,
  (data) => data.find((item) => item.chargeCode?.code === FRT),
);

export const getTransportationPlans = createSelector(
  localState,
  (state) => state.transportationPlans,
);

export const getApplicationLevel = createSelector(
  getTransportationPlans,
  (transportationPlans) => (
    {
      type: 'ROUTE',
      origin: transportationPlans?.route.origin.code,
      destination: transportationPlans?.route.destination.code,
    }
  ),
);

export const getTransportLegs = createSelector(
  getTransportationPlans,
  (transportationPlans) => (
    transportationPlans?.route.legs.map((leg) => ({
      departureLocation: {
        code: leg.departureLocation.code,
      },
      arrivalLocation: {
        code: leg.arrivalLocation.code,
      },
      sequence: leg.sequence,
    }))
  ),
);

export const getBookValidity = createSelector(
  localState,
  (state) => state.bookValidity,
);

export const getHeaderErrors = createSelector(
  localState,
  (state) => state.headerErrors,
);

export const getIsShipmentTabError = createSelector(
  localState,
  (state) => state.shipmentTabError,
);

export const getIsContainerTabError = createSelector(
  localState,
  (state) => state.containerTabError,
);

export const getIsTableError = createSelector(
  getIsShipmentTabError,
  getIsContainerTabError,
  (shipmentTabError, containerTabError) => shipmentTabError || containerTabError,
);

const getIsLoadingAddCharges = createSelector(
  localState,
  (state) => state.addChargesLoading,
);

export const addRatesSelectors = {
  getIsLoading,
  getChargeCodesOptions,
  getEditableDataByContainer,
  getUniqContainers,
  getEditableDataByShipment,
  getContractNumber,
  getFRTChargeCode,
  getFRTCharge,
  getValidFrom,
  getValidTo,
  getChargeCodes,
  getApplicationLevel,
  getTransportLegs,
  getBookValidity,
  getHeaderErrors,
  getIsTableError,
  getIsShipmentTabError,
  getIsContainerTabError,
  getIsLoadingAddCharges,
};
