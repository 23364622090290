import React, { FC } from 'react';

import {
  TradeReferences,
  Footer,
} from './components';
import {
  Form,
  Spinner,
  SpinnerWrap,
  Content,
} from './EditTradeAccounting.styled';

interface EditBillOfLadingComponentProps {
  isPending: boolean;
}

export const EditTradeAccountingComponent: FC<EditBillOfLadingComponentProps> = ({
  isPending,
}) => (
  <Form>
    {isPending ? (
      <SpinnerWrap>
        <Spinner />
      </SpinnerWrap>
    ) : (
      <>
        <Content>
          <TradeReferences />
        </Content>

        <Footer />
      </>
    )}
  </Form>
);
