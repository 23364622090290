import styled from 'styled-components';

export const TypeSwitchController = styled.div.attrs({
  'data-class': 'type-switch-controller',
})`
  .Switch {
    width: 28px;
    margin: 0 8px;
  }

  .ant-switch-checked {
    background-color: #73819B;
  }

  .ant-switch:disabled {
    background-color: #73819B;
  }
`;

export const TypeSwitchControllerWithText = styled(TypeSwitchController)`
  .Switch {
    min-width: 41px;

    .ant-switch-inner {
      margin: -3px 7px 0 17px;
    }

    &.ant-switch-checked .ant-switch-inner {
      margin: -3px 17px 0 7px;
    }
  }
`;

export const TypeSwitchControllerText = styled.span`
  font-size: 12px;
  line-height: 20px;
`;

export const TypeSwitchControllerTextInactive = styled(TypeSwitchControllerText)`
  color: #73819b;
`;
