import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Option } from 'app-wrapper/view/components';

import { AirVentUnitsType, AirVentUnitsNamesConst } from 'shipment-operations/constants';

import {
  AirVentFieldWrapper,
  AirVentInput,
  AirVentLabel,
  AirVentSelect,
} from './AirVentField.styled';

interface IAirVentFieldComponentProps {
  flowRate?: string
  flowRateUnit?: AirVentUnitsType
}

export const AirVentFieldComponent: FC<IAirVentFieldComponentProps> = ({
  flowRate,
  flowRateUnit,
}) => {
  const { t } = useTranslation();

  return (
    <AirVentFieldWrapper>
      <AirVentLabel>{t('Air Vent Settings')}</AirVentLabel>
      <AirVentInput
        type="number"
        value={flowRate}
        disabled
      />
      <AirVentSelect
        allowClear={!!flowRateUnit}
        value={flowRateUnit}
        disabled
      >
        <Option value={AirVentUnitsType.CUBIC_FEET_PER_MINUTE}>{AirVentUnitsNamesConst[AirVentUnitsType.CUBIC_FEET_PER_MINUTE]}</Option>
        <Option value={AirVentUnitsType.CUBIC_METERS_PER_HOUR}>{AirVentUnitsNamesConst[AirVentUnitsType.CUBIC_METERS_PER_HOUR]}</Option>
        <Option value={AirVentUnitsType.PERCENT}>{AirVentUnitsNamesConst[AirVentUnitsType.PERCENT]}</Option>
      </AirVentSelect>
    </AirVentFieldWrapper>
  );
};
