import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';
import { PermissionAttributePolicy } from 'shipment-operations/constants';

import { SBLGoodsTableComponent } from './SBLGoodsTable.component';

const SBLGoodsTableContainer = () => {
  const cargos = useSelector(R.selectors.billOfLadingCommon.getCargos);
  const allCargoItems = useSelector(R.selectors.billOfLadingCommon.getAllCargoItems);
  const permissions = useSelector(R.selectors.shipment.getSIPermissions);
  const isHBLPage = useSelector(R.selectors.billOfLadingCommon.getIsHBLPage);

  const isAbleToEdit = useMemo(() => !isHBLPage && permissions.editSISections === PermissionAttributePolicy.WRITE, [permissions, isHBLPage]);

  return (
    <SBLGoodsTableComponent
      cargos={cargos}
      cargoItems={allCargoItems}
      hideNoDataAddButton={!isAbleToEdit}
    />
  );
};

export { SBLGoodsTableContainer as SBLGoodsTable };
