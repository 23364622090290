import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { UC } from 'user-management/controllers';
import { R } from 'user-management/repository';

import { UserManagementHeaderComponent } from './UserManagementHeader.component';

const UserManagementHeaderContainer: FC = () => {
  const userManagementPermissions = useSelector(R.selectors.moduleFunctionalityPermissions.getUserManagementPermissions);

  return (
    <UserManagementHeaderComponent
      userManagementAvailability={userManagementPermissions}
      onUserInvite={UC.userManagement.openUserInviteDrawer}
    />
  );
};

export { UserManagementHeaderContainer as UserManagementHeader };
