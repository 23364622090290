import moment from 'moment';
import { BaseController, controller } from 'proto/BaseController';

import { R } from 'shipment-operations/repository';
import { R as monetaryR } from 'monetary/repository';
import {
  IPostCreateQuotaParamsQuotaCommoditiesRequest,
  postCreateQuotaParamsRequest,
} from 'monetary/models/contracts';
import {
  FreightQuotaContentContainerDTM,
  FreightQuotaContentDTM,
  FreightQuotaContentSchedulesDTM,
} from 'monetary/models/dtm';
import { QUOTAS_STATUS } from 'app-wrapper/constants';
import { IGetShipmentPlansResponse } from 'shipment-operations/models/contracts';
import { apiWorker } from 'app-wrapper/repository/utilsServices';
import { ELocationType } from 'app-wrapper/types/LocationType';
import { R as userManagementR } from 'user-management/repository';
import {
  CargoDTM,
  ChargeDTM,
  ContainerDTM,
  ContainerWithCargoDTM,
  PaymentTermsDTM,
  ShipmentPreviewDTM,
  ShipmentTrackerDTM,
  TemperatureControlDTM,
  ShippingPartyDTM,
  RollWarningInfoDTM,
} from 'shipment-operations/models/dtm';
import { ECarrierSCAC, EFreightLoadType, ORGANIZATION } from 'monetary/constants';
import { EShippingPartyTypes, ShipmentFreightMode } from 'shipment-operations/constants';
import { IRFQQuotasDTM } from 'monetary/models/dtm/Quotas';
import { EOrganizationMemberRole } from 'user-management/constants';
import { DateDtm } from 'app-wrapper/models/dtm';

const MAX_QUOTE_REQUEST_TIMEOUT = 180000;
const QUOTE_REQUEST_STATUS_CHECK_INTERVAL = 1000;

@controller
export class RollShipmentWizardController extends BaseController {
  public openRollShipmentWizard = () => {
    this.dispatch(R.actions.rollShipmentWizard.setIsDrawerOpened(true));
  }

  public closeRollShipmentWizard = () => {
    this.dispatch(R.actions.rollShipmentWizard.setIsDrawerOpened(false));
  };

  public closeDrawerDueToError = () => {
    this.dispatch(R.actions.rollShipmentWizard.setIsLoading(false));
    this.dispatch(R.actions.rollShipmentWizard.setIsDrawerOpened(false));
  };

  public getInitialRFQRequestData = async (shipmentId: string, isOpen: boolean) => {
    if (!isOpen) {
      return;
    }

    this.dispatch(R.actions.rollShipmentWizard.setIsLoading(true));

    let shippingParties: ShippingPartyDTM[] = [];

    try {
      shippingParties = await R.services.shippingParties.getList(shipmentId);
    } catch (e) {
      console.error('RollShipmentWizardController: getShippingParties');
    }

    const customer = shippingParties.find(({ role }) => role === EShippingPartyTypes.CUSTOMER);

    if (!customer) {
      console.error('RollShipmentWizardController: customer is missing');
      this.closeDrawerDueToError();

      return;
    }

    let charges: ChargeDTM[] = [];

    try {
      charges = await R.services.shipmentCharges.getCustomerCharges(shipmentId, Number(customer?.company?.id)) || [];
    } catch (e) {
      console.error('RollShipmentWizardController: getInitialRFQRequestData getCharges');
      this.closeDrawerDueToError();

      return;
    }

    const appliedActiveCharges = charges.filter(({ applied, active }) => applied && active);
    const shipmentAdditionalCharges = appliedActiveCharges.filter(({ additional }) => additional);
    const shipmentAdditionalChargesTotalCost = shipmentAdditionalCharges.reduce((acc, item) => acc + (item.buyTotalCost || 0), 0);
    const shipmentAppliedActiveChargesTotalCost = appliedActiveCharges.reduce((acc, item) => acc + (item.buyTotalCost || 0), 0);

    this.dispatch(R.actions.rollShipmentWizard.setSavedCharges(charges));
    this.dispatch(R.actions.rollShipmentWizard.setShipmentAdditionalChargesTotalCost(+shipmentAdditionalChargesTotalCost.toFixed(2)));
    this.dispatch(R.actions.rollShipmentWizard.setShipmentAppliedChargesTotalCost(+shipmentAppliedActiveChargesTotalCost.toFixed(2)));

    let paymentTerms: PaymentTermsDTM | null = null;

    try {
      paymentTerms = await R.services.paymentTerms.getShipmentPaymentTerms(shipmentId);
    } catch (e) {
      console.error('RollShipmentWizardController: getInitialRFQRequestData getShipmentPaymentTerms');
      this.closeDrawerDueToError();

      return;
    }

    if (!paymentTerms) {
      this.closeDrawerDueToError();

      return;
    }

    this.dispatch(R.actions.rollShipmentWizard.setPaymentTerms(paymentTerms));

    let containers: ContainerDTM[] = [];
    let cargos: CargoDTM[];

    try {
      containers = await R.services.shipmentContainers.getContainersList(shipmentId);
      cargos = await R.services.cargo.getCargos(+shipmentId);
    } catch (e) {
      console.error('RollShipmentWizardController: getInitialRFQRequestData getContainersList and getCargos');
      this.closeDrawerDueToError();

      return;
    }

    const commodities = cargos.map((cargo) => (cargo.isHazmat ? {
      code: cargo.code,
      description: cargo.description || cargo.name,
      imoClass: cargo.imoClass,
      unNumber: cargo.unNumber,
      value: cargo.value,
      name: cargo.name,
    } : {
      code: cargo.code,
      description: cargo.description || cargo.name,
      imoClass: cargo.imoClass,
      unNumber: null,
      value: cargo.value,
      name: cargo.name,
    })) as IPostCreateQuotaParamsQuotaCommoditiesRequest[];

    const containersWithCargos = containers.map((container) => (ContainerWithCargoDTM.fromPlain({
      ...container,
      cargoItems: container.cargoItems.map((cargoItem) => {
        const cargo = cargos.find(({ id = 0 }) => +cargoItem.cargoId === id) as CargoDTM;

        return {
          ...cargoItem,
          cargo,
        };
      }),
    })));

    if (!containersWithCargos.length) {
      this.closeDrawerDueToError();

      return;
    }

    let plans: IGetShipmentPlansResponse[] = [];

    if (containersWithCargos[0].planId) {
      try {
        plans = await R.services.shipmentPlans.getShipmentPlans(containersWithCargos[0].planId);
      } catch (e) {
        console.error('RollShipmentWizardController: getInitialRFQRequestData getShipmentPlans');
        this.closeDrawerDueToError();

        return;
      }
    }

    const plan = plans[0];

    if (!plan) {
      this.closeDrawerDueToError();

      return;
    }

    // fetching temperature control part

    let temperatureControl: TemperatureControlDTM | null = null;

    try {
      temperatureControl = await R.services.temperatureControl.getTemperatureControlData(shipmentId);
    } catch (e) {
      console.error('RollShipmentWizardController: getInitialRFQRequestData getTemperatureControlData');
      this.closeDrawerDueToError();

      return;
    }

    const additionalServices = await R.services.additionalService.getAdditionalServices(shipmentId);

    const shipment = R.selectors.shipment.getShipment(this.store.getState());

    const body: postCreateQuotaParamsRequest = {
      isSelfServiceRequest: false,
      includeRelatedPorts: false,
      freightMode: ShipmentFreightMode.SEA,
      incoterm: paymentTerms.incoterm,
      loadType: EFreightLoadType.FCL,
      tradeType: paymentTerms.tradeType,
      customer: null,
      additionalServices: additionalServices.map((service) => ({
        code: service.code,
        phase: service.phase,
        quantity: service.quantity,
      })) as postCreateQuotaParamsRequest['additionalServices'],
      oceanCarriers: shipment ? [shipment.scac as ECarrierSCAC] : [],
      containerRequests: containersWithCargos.map((container) => {
        const { route } = plan;
        const { origin, destination } = route;
        const { weight, volume } = container.cargoItems.reduce((acc, item) => ({ weight: +acc.weight + +item.weight, volume: +acc.volume + +item.volume }), { weight: 0, volume: 0 });

        return {
          container: {
            commodities,
            ownContainer: container.ownContainer,
            temperatureControl: !!temperatureControl,
            type: container.type,
            volume: volume > 0 ? volume : 1,
            weight: weight > 0 ? weight : 1,
          },
          origin: {
            earliestDate: this.getContainerEarliestDateAsUTC(),
            latestDate: this.getContainerLatestDateAsUTC(),
            location: {
              code: origin.code,
              type: ELocationType.PORT,
            },
            type: ELocationType.PORT,
          },
          destination: {
            earliestDate: null,
            latestDate: null,
            location: {
              code: destination.code,
              type: ELocationType.PORT,
            },
            type: ELocationType.PORT,
          },
          referencePlanId: +container.planId,
        };
      }),
    };

    let requestsResult: { id: number } | null = null;

    const currentOrganization = userManagementR.selectors.userOrganizationData.getUserOrganization(this.store.getState());

    if (shipment && currentOrganization && currentOrganization.role !== EOrganizationMemberRole.CUSTOMER) {
      body.customer = {
        type: ORGANIZATION,
        organizationId: shipment.customerOrgId,
      };
    }

    try {
      requestsResult = await monetaryR.services.RFQServiceById.postCreateQuota(body);
    } catch (e) {
      console.error('RollShipmentWizardController: getInitialRFQRequestData postCreateQuota');
      this.closeDrawerDueToError();

      return;
    }

    const requestId = requestsResult?.id;
    const isDrawerOpened = R.selectors.rollShipmentWizard.getIsWizardOpened(this.store.getState());

    if (!requestId || !isDrawerOpened) {
      this.closeDrawerDueToError();

      return;
    }

    this.dispatch(R.actions.rollShipmentWizard.setQuotaRequestId(requestId));

    let isTimeExceeded = false;

    try {
      await new Promise((resolve, reject) => {
        const timeoutId = window.setTimeout(() => {
          isTimeExceeded = true;
        }, MAX_QUOTE_REQUEST_TIMEOUT);

        this.dispatch(R.actions.rollShipmentWizard.setTimeoutId(timeoutId));

        const intervalId = window.setInterval(async () => {
          if (isTimeExceeded) {
            clearTimeout(timeoutId);
            clearInterval(intervalId);
            reject();
          }

          let isStatusComplete = false;

          try {
            isStatusComplete = await this.getIsRQFRequestCheckStatusComplete(requestId);
          } catch (e) {
            clearTimeout(timeoutId);
            clearInterval(intervalId);
            reject();
          }

          if (isStatusComplete) {
            clearTimeout(timeoutId);
            clearInterval(intervalId);
            resolve(null);
          }
        }, QUOTE_REQUEST_STATUS_CHECK_INTERVAL);

        this.dispatch(R.actions.rollShipmentWizard.setIntervalId(intervalId));
      });
    } catch (e) {
      console.error('RollShipmentWizardController: getInitialRFQRequestData request status check');
      this.closeDrawerDueToError();

      return;
    }

    let service: IRFQQuotasDTM | null = null;

    try {
      service = await monetaryR.services.RFQServiceById.getRFQServiceById({
        serviceId: `${requestId}`,
      });
    } catch (e) {
      console.error('RollShipmentWizardController: getInitialRFQRequestData getRFQServiceById');
      this.closeDrawerDueToError();

      return;
    }

    if (service && service.content && service.content.length) {
      const quotas = service.content.map((item) => FreightQuotaContentDTM.fromPlain(item));
      const quota = quotas[0];

      if (quota) {
        this.dispatch(R.actions.rollShipmentWizard.setQuotaId(quota.id));
      }

      const schedules: FreightQuotaContentSchedulesDTM[] = quotas.reduce((acc, item) => [...acc, ...(item.schedules || [])], [] as FreightQuotaContentSchedulesDTM[]);
      const quotasContainers = quotas.reduce((acc, item) => [...acc, ...(item.containers || [])], [] as FreightQuotaContentContainerDTM[]);
      const sortedSchedules = schedules.sort((a, b) => ((a?.departureTime || '') > (b?.departureTime || '') ? 1 : -1));
      const schedule = sortedSchedules.length ? sortedSchedules[0] : null;

      this.dispatch(R.actions.rollShipmentWizard.setQuotas(quotas));
      this.dispatch(R.actions.rollShipmentWizard.setSchedules(sortedSchedules));
      this.dispatch(R.actions.rollShipmentWizard.setContainers(quotasContainers));

      if (schedule) {
        this.dispatch(R.actions.rollShipmentWizard.setChosenScheduleId(Number(schedule.id)));
      }
    }

    this.dispatch(R.actions.rollShipmentWizard.setIsLoading(false));
  };

  public chooseScheduleById = (scheduleId: number) => {
    this.dispatch(R.actions.rollShipmentWizard.setChosenScheduleId(scheduleId));
  };

  public toggleScheduleExpanding = (scheduleId: number) => {
    this.dispatch(R.actions.rollShipmentWizard.toggleExpandedScheduleId(scheduleId));
  };

  public submitShipmentRoll = async (shipmentId: string) => {
    this.dispatch(R.actions.rollShipmentWizard.setIsLoading(true));
    const quotas = R.selectors.rollShipmentWizard.getQuotas(this.store.getState());
    const scheduleId = R.selectors.rollShipmentWizard.getChosenScheduleId(this.store.getState());
    const quotaRequestId = R.selectors.rollShipmentWizard.getQuotaRequestId(this.store.getState());
    const quota = quotas.find(({ schedules }) => schedules?.map(({ id }) => id).includes(scheduleId));

    if (!quota) {
      this.dispatch(R.actions.rollShipmentWizard.setIsLoading(false));

      return;
    }

    await R.services.shipment.rollShipment(shipmentId, quota.id, scheduleId, quotaRequestId);
    await this.updateOverviewInformation(shipmentId);
    await this.updateRollWarningInformation();

    this.dispatch(R.actions.rollShipmentWizard.setIsLoading(false));
    this.dispatch(R.actions.rollShipmentWizard.setIsDrawerOpened(false));
  };

  public updateOverviewInformation = async (shipmentId: string) => {
    let shipment: ShipmentPreviewDTM | null = null;

    try {
      shipment = await R.services.shipment.getShipmentShortById(+shipmentId);
    } catch (e) {
      console.error('OverviewController.loadData: error');
    }

    if (shipment) {
      this.dispatch(R.actions.rollShipmentWizard.setShipment(shipment));
      this.dispatch(R.actions.shipment.setShipment(shipment));
    }

    let containers: ShipmentTrackerDTM[];

    try {
      containers = await R.services.shipmentTracker.getContainers(shipmentId);
    } catch {
      console.error('RollShipmentWizardController: updateOverviewInformation');

      return;
    }

    this.dispatch(R.actions.shipmentTracker.setContainers(containers));
    this.dispatch(R.actions.shipmentTrackerRoutes.setContainers(containers));

    if (!containers.length) {
      return;
    }

    const [container] = containers;
    const { planId } = container;

    let plans;

    try {
      plans = await R.services.shipmentTracker.getSchedules(planId);
    } catch {
      console.error('RollShipmentWizardController: updateOverviewInformation');
    }

    this.dispatch(R.actions.shipmentTrackerRoutes.setSchedules(plans));
  };

  public updateRollWarningInformation = async () => {
    const shipment = R.selectors.shipment.getShipment(this.store.getState());

    if (!shipment || !shipment.rollPlanId) {
      return;
    }

    let plans;

    try {
      plans = await R.services.shipmentPlans.getShipmentPlans(String(shipment.rollPlanId));
    } catch (e) {
      console.error('OverviewController Error: getPlans');
    }

    if (plans) {
      const { origin, destination } = shipment;
      const [plan] = plans;
      const { transportations } = plan;
      const firstTransportation = transportations[0];
      const lastTransportation = transportations[transportations.length - 1];

      this.dispatch(R.actions.overview.setRollWarningInfo(RollWarningInfoDTM.fromPlain({
        shouldShowWarning: true,
        prevLocation: origin.address,
        nextLocation: destination.address,
        prevETD: origin.estimatedDate ? origin.estimatedDate.getFormatDMMMHHmmWithOffset() : '',
        prevETA: destination.estimatedDate ? destination.estimatedDate.getFormatDMMMHHmmWithOffset() : '',
        nextETD: firstTransportation && firstTransportation.schedule
          ? DateDtm.fromPlain({
            date: firstTransportation.schedule.departureTime,
            offset: moment.parseZone(firstTransportation.schedule.departureTime).utcOffset(),
          }).getFormatDMMMHHmmWithOffset()
          : '',
        nextETA: lastTransportation && lastTransportation.schedule
          ? DateDtm.fromPlain({
            date: lastTransportation.schedule.arrivalTime,
            offset: moment.parseZone(lastTransportation.schedule.arrivalTime).utcOffset(),
          }).getFormatDMMMHHmmWithOffset()
          : '',
      })));
    }
  };

  public clearRequestsTimeoutsAndIntervals = () => {
    const intervalId = R.selectors.rollShipmentWizard.getIntervalId(this.store.getState());
    const timeoutId = R.selectors.rollShipmentWizard.getTimeoutId(this.store.getState());

    apiWorker.abortAllRequests();

    if (intervalId) {
      clearInterval(intervalId);
      this.dispatch(R.actions.rollShipmentWizard.setIntervalId(0));
    }

    if (timeoutId) {
      clearTimeout(timeoutId);
      this.dispatch(R.actions.rollShipmentWizard.setTimeoutId(0));
    }
  };

  private getIsRQFRequestCheckStatusComplete = async (id: number) => {
    const requestStatus = await monetaryR.services.RFQServiceById.getQuotasMakeCheckStatus(id);

    return requestStatus === QUOTAS_STATUS.complete;
  };

  private getContainerEarliestDateAsUTC = () => {
    const today = moment.now();

    return moment(today).add('days', 4).utc().format();
  };

  private getContainerLatestDateAsUTC = () => {
    const earliestDate = moment(moment.now()).add('days', 4);

    return moment(earliestDate).add('weeks', 4).utc().format();
  };
}
