import React from 'react';

import { IPropsIcon } from 'app-wrapper/interfaces/icon';

import { Icon } from './WeightIconSvg.styled';

export const WeightIconSvg = (props: IPropsIcon) => {
  const { width, height, fill } = props;
  return (
    <Icon
      className="WeightIconSvg"
      width={width || '15'}
      height={height || '15'}
      viewBox="0 0 15 15"
      fill={fill || 'currentColor'}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M14.4453 12.9453L12.4492 4.96094C12.3399 4.55078 11.9844 4.27734 11.6016 4.27734H9.6055C9.7969 3.92188 9.90628 3.56641 9.90628 3.18359C9.90628 1.84375 8.81253 0.777344 7.50003 0.777344C6.16018 0.777344 5.09378 1.84375 5.09378 3.18359C5.09378 3.56641 5.17581 3.92188 5.36722 4.27734H3.37112C2.98831 4.27734 2.63284 4.55078 2.52347 4.96094L0.527371 12.9453C0.308621 13.875 0.937528 14.75 1.81253 14.75H13.1602C14.0352 14.75 14.6641 13.875 14.4453 12.9453ZM6.40628 3.15625C6.40628 2.55469 6.87112 2.0625 7.50003 2.0625C8.10159 2.0625 8.59378 2.55469 8.59378 3.15625C8.59378 3.78516 8.10159 4.25 7.50003 4.25C6.87112 4.25 6.40628 3.78516 6.40628 3.15625ZM13.1328 13.4375H1.83987C1.81253 13.4375 1.78518 13.3555 1.81253 13.2734L3.72659 5.5625H11.2461L13.1602 13.2734C13.1875 13.3555 13.1602 13.4375 13.1328 13.4375Z" fill="currentColor" />
    </Icon>
  );
};
