import { createSelector } from 'reselect';

import { RootState } from 'app-wrapper/store';

const getLocalState = (state: RootState) => state.shipmentList;

const getList = createSelector(
  getLocalState,
  (state) => state.list,
);

const getLoadingState = createSelector(
  getLocalState,
  (state) => state.isLoading,
);

const getLoadingFiltersState = createSelector(
  getLocalState,
  (state) => state.isLoadingFilters,
);

const getPage = createSelector(
  getLocalState,
  (state) => state.page,
);

const getWasLastResponseEmpty = createSelector(
  getLocalState,
  (state) => state.wasLastResponseEmpty,
);

const geShipmentFilterLocation = createSelector(
  getLocalState,
  (state) => state.filters?.filterLocation,
);

const geShipmentFilterShippingParty = createSelector(
  getLocalState,
  (state) => state.filters?.filterShippingParty,
);

const geShipmentFilterShippingCarrier = createSelector(
  getLocalState,
  (state) => state.filters?.filterShippingCarrier,
);

const geShipmentFilterContainerType = createSelector(
  getLocalState,
  (state) => state.filters?.filterContainerType,
);

const geShipmentFilterStatus = createSelector(
  getLocalState,
  (state) => state.filters?.filterStatus,
);

const geShipmentFilterSortBy = createSelector(
  getLocalState,
  (state) => state.filters?.filterSortBy,
);

const getSearchValue = createSelector(
  getLocalState,
  (state) => state.searchValue,
);

const getIsClearAllVisible = createSelector(
  getLocalState,
  (state) => state.filters?.filterLocation && (!state.filters?.filterLocation?.values?.disableReset
    || !state.filters?.filterShippingParty?.values?.disableReset
    || !state.filters?.filterShippingCarrier?.values?.disableReset
    || !state.filters?.filterContainerType?.values?.disableReset
    || !state.filters?.filterStatus?.values?.disableReset),
);

export const shipmentListSelectors = {
  getList,
  getLoadingState,
  getLoadingFiltersState,
  getPage,
  getWasLastResponseEmpty,
  geShipmentFilterLocation,
  geShipmentFilterShippingParty,
  geShipmentFilterShippingCarrier,
  geShipmentFilterContainerType,
  geShipmentFilterStatus,
  geShipmentFilterSortBy,
  getSearchValue,
  getIsClearAllVisible,
};
