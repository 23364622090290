import React, { FC, memo } from 'react';
import { useSelector } from 'react-redux';

import { R as appR } from 'app-wrapper/repository';
import { R as userManagementR } from 'user-management/repository';
import { MainMenu } from 'app-wrapper/view/components';

interface MainMenuProps {
  isCollapsed: boolean;
}

const MainMenuHome: FC<MainMenuProps> = ({ isCollapsed }) => {
  const isCustomer = useSelector(appR.selectors.home.getIsCustomer);
  const isOrganizationStatusActive = useSelector(userManagementR.selectors.userOrganizationData.getIsOrganizationActive);
  const isOrganizationInDraft = useSelector(userManagementR.selectors.userOrganizationData.getIsOrganizationInDraft);
  const isOrganizationInRejected = useSelector(userManagementR.selectors.userOrganizationData.getIsOrganizationRejected);

  return (
    <MainMenu
      isCustomer={isCustomer}
      isCollapsed={isCollapsed}
      isOrganizationActive={isOrganizationStatusActive}
      isOrganizationInDraft={isOrganizationInDraft}
      isOrganizationInRejected={isOrganizationInRejected}
    />
  );
};

const MainMenuContainer = memo(MainMenuHome);

export { MainMenuContainer };
