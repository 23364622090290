import React, { FC } from 'react';

import { R } from 'authentication/repository';
import { UC } from 'authentication/controllers';

import { useAppSelector } from 'app-wrapper/hooks';
import { UC as routesUC } from 'app-wrapper/controllers';

import { RoutesComponent } from './Routes.component';

const RoutesContainer: FC = () => {
  const loggedIn = useAppSelector(R.selectors.auth.getIsLoggedIn);
  const isLoading = useAppSelector(R.selectors.auth.getIsLoading);

  return (
    <RoutesComponent
      isLoading={isLoading}
      isLoggedIn={loggedIn}
      checkAuth={UC.auth.checkAuth}
      saveLastPath={routesUC.routes.saveLastPath}
      onChangePath={routesUC.routes.onChangePath}
      showNotificationNewAppVersion={routesUC.routes.showNotificationNewAppVersion}
      onRunLoadStatistics={routesUC.routes.onRunLoadStatistics}
      clearIntervalLoadStatistics={routesUC.routes.clearIntervalLoadStatistics}
    />
  );
};

export { RoutesContainer as Routes };
