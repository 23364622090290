import React, { FC } from 'react';
import isEmpty from 'lodash/fp/isEmpty';
import { useTranslation } from 'react-i18next';

import { InvoicesChargeDTM } from 'shipment-operations/models/dtm';
import {
  NotesInnerContainer, Title,
} from 'shipment-operations/view/components';
import {
  CustomTableEditable,
} from 'shipment-operations/view/pages/CreateCreditNote/components/SecondStep/components';

interface IFeesComponentProps {
  data: InvoicesChargeDTM[]
}

const FeesComponent: FC<IFeesComponentProps> = ({ data }) => {
  const { t } = useTranslation();

  return (!isEmpty(data) ? (
    <NotesInnerContainer>
      <Title>{t('fees')}</Title>
      <CustomTableEditable data={data} />
    </NotesInnerContainer>
  ) : null);
};

export { FeesComponent };
