import React from 'react';
import { observer } from 'mobx-react-lite';

import { UC } from 'app-wrapper/controllers';
import { useMobxStore } from 'app-wrapper/mobxStores';

import { ContractCategoryComponent } from './ContractCategory.component';

export const ContractCategory = observer(() => {
  const { contracts } = useMobxStore();

  return (
    <ContractCategoryComponent
      category={contracts.state.contractCategory}
      setCategory={UC.contracts.setContractCategory}
    />
  );
});
