import React from 'react';

import { R } from 'shipment-operations/repository';
import { useAppSelector } from 'app-wrapper/hooks';

import { HeaderComponent } from './Header.component';

const Header = () => {
  const invoiceNumber = useAppSelector(R.selectors.shipmentBillingInvoice.getNumber);

  return (
    <HeaderComponent number={invoiceNumber} />
  );
};

export { Header };
