import moment from 'moment/moment';
import {
  filter,
  find,
  groupBy,
  head,
  last,
  map,
  pathOr,
  prop,
  propOr,
  reduce,
  sortBy,
  uniq,
} from 'lodash/fp';

import { ForbiddenError } from 'app-wrapper/models/errors/httpErrors/ForbiddenError/Forbidden.error';
import { ReferenceDTM } from 'app-wrapper/types';
import { R as appR } from 'app-wrapper/repository';
import { QUOTAS_STATUS, REQUEST_STATUS } from 'app-wrapper/constants';
import { DateDtm } from 'app-wrapper/models/dtm';
import { ETransportationType } from 'monetary/constants';
import { FreightQuotaContentDTM } from 'monetary/models/dtm';
import { R as monetaryR } from 'monetary/repository';
import { BaseController, controller } from 'proto/BaseController';
import {
  ChargeCodeDesignation,
  EInvoiceType,
  EShipmentConfirmationTypes,
  EShippingPartyTypes, ExportClearanceType,
  PAYABLES,
  RECEIVABLES,
  ShipmentBookingStatusEnum,
  ShipmentFreightMode,
  ShippingInstructionsStatusEnum,
  TRouteLegPhase,
} from 'shipment-operations/constants';
import {
  getShipmentDocumentsSiDetailsResponse,
  GetShipmentResponse,
  IGetShipmentPlansResponse,
  putShipmentDocumentsAdditionalDetailsResponse,
} from 'shipment-operations/models/contracts';
import {
  BillOfLadingDTM,
  CargoDTM,
  ChargeDTM,
  ContainerDocumentDTM,
  ContainerWithCargoDTM, CustomsClearanceDTM,
  DocumentsCollapseItemDTM,
  FullShipmentDTM,
  HBLDocumentBOLDTM,
  IHBLMarksAndNumbersDTM,
  IRouteLegDTM,
  ParsedInvoiceDTM,
  ShipmentConfirmationDTM,
  ShipmentPreviewDTM,
  ShippingPartyDTM,
  ShortChargeDTM,
} from 'shipment-operations/models/dtm';
import { R } from 'shipment-operations/repository';
import { EAccountDepartmentType, EOrganizationMemberRole } from 'user-management/constants';
import { OrganizationDTM, OrganizationMemberDTM } from 'user-management/models/dtm';
import { R as userManagementR } from 'user-management/repository';

const HBL_REPORT_UNAVAILABLE_STATUSES = [
  ShippingInstructionsStatusEnum.SI_PREPARATION,
  ShippingInstructionsStatusEnum.SI_FAILED,
  ShippingInstructionsStatusEnum.SI_AMENDMENT_SUBMITTED,
  ShippingInstructionsStatusEnum.SI_DECLINED,
];
const BOOKING_STATUSES_TO_SHOW_BOOKING_CONFIRMATION_DOCUMENT = [
  ShipmentBookingStatusEnum.BOOKING_CONFIRMED,
  ShipmentBookingStatusEnum.BOOKING_MANUAL_CONFIRMED,
  ShipmentBookingStatusEnum.BOOKING_AMENDMENT_CONFIRMED,
  ShipmentBookingStatusEnum.BOOKING_AMENDMENT_MANUAL_CONFIRMED,
];

@controller
export class ShipmentDocumentsAllController extends BaseController {
  public initData = async (shipmentId: string) => {
    this.dispatch(R.actions.shipmentDocumentsAll.clear());
    this.dispatch(R.actions.shipmentDocumentsAll.setIsLoading(true));

    this.dispatch(R.actions.shipmentDocumentsAll.clearBookingConfirmationDocument());

    await this.getAdditionalDocuments(shipmentId);
    await this.getSystemDocuments(shipmentId);
    await this.initOrganizationData();

    this.dispatch(R.actions.shipmentDocumentsAll.setIsLoading(false));
  };

  public initOrganizationData = async () => {
    let organization: OrganizationDTM | null = null;

    this.dispatch(R.actions.overview.setIsLoading(true));

    organization = await userManagementR.services.organization.getCurrentOrganization(true);

    if (organization) {
      this.dispatch(userManagementR.actions.userOrganizationData.setUserOrganization(organization));
    }
  }

  public getSystemDocuments = async (shipmentId: string) => {
    let shipment: ShipmentPreviewDTM | null = null;
    let fullShipment: FullShipmentDTM | null = null;

    try {
      fullShipment = await R.services.shipment.getFullShipment(shipmentId);
    } catch (e) {
      console.error('ShipmentDocumentsAllController Error: getFullShipment');
    }

    try {
      shipment = await R.services.shipment.getShipmentShortById(+shipmentId);
    } catch (e) {
      console.error('ShipmentDocumentsAllController Error: getShipmentShortById');
    }

    this.dispatch(R.actions.shipmentDocumentsAll.setShipment(shipment || undefined));

    if (shipment) {
      const organization = userManagementR.selectors.userOrganizationData.getUserOrganization(this.store.getState());
      const {
        bookingStatus,
        siStatus,
        createdAt,
        manualConfirmationDocument,
      } = shipment;

      await this.initQuoteAndBookingDocsData(shipment);

      if (manualConfirmationDocument && organization && (organization.role === EOrganizationMemberRole.PARTNER || organization.role === EOrganizationMemberRole.admin)) {
        this.dispatch(R.actions.shipmentDocumentsAll.setCarrierBookingConfirmation(DocumentsCollapseItemDTM.fromPlain({
          name: manualConfirmationDocument.name,
          id: manualConfirmationDocument.id,
          objectName: '-',
          createdBy: manualConfirmationDocument.createdBy,
          uploadedDate: manualConfirmationDocument.createdAt || null,
          type: manualConfirmationDocument.type,
        })));
      }

      if (BOOKING_STATUSES_TO_SHOW_BOOKING_CONFIRMATION_DOCUMENT.includes(bookingStatus)) {
        this.dispatch(R.actions.shipmentDocumentsAll.setBookingConfirmationDocument(DocumentsCollapseItemDTM.fromPlain({
          name: `BC for ${shipment.shipmentName}`,
          id: +shipmentId,
          objectName: '-',
          createdBy: 'System',
          uploadedDate: null,
          type: '',
        })));

        await this.getBookingConfirmationDocumentInformation(shipmentId, fullShipment);
      }

      if (!HBL_REPORT_UNAVAILABLE_STATUSES.includes(siStatus)) {
        this.dispatch(R.actions.shipmentDocumentsAll.setDocumentHBL(DocumentsCollapseItemDTM.fromPlain({
          name: `HBL for ${shipment.shipmentName}`,
          id: +shipmentId,
          objectName: '-',
          createdBy: 'System',
          uploadedDate: null,
          type: '',
        })));

        await this.getHBLDocumentInformation(shipmentId, fullShipment);
      }

      this.dispatch(R.actions.shipmentDocumentsAll.setQuoteDocument(DocumentsCollapseItemDTM.fromPlain({
        name: `Quote ${shipment.shipmentName}`,
        id: +shipmentId,
        objectName: '-',
        createdBy: 'System',
        uploadedDate: createdAt || null,
        type: '',
      })));
    }

    const incotermsTrade = fullShipment?.paymentTerms.tradeType;

    this.dispatch(R.actions.bookingWizard.setIncotermsTrade(incotermsTrade || ''));
    await this.initNRADocumentsAccordingToOrganizationRole(shipmentId, shipment);
    await this.getInvoicesDocumentsInformation(shipmentId);
  };

  public initQuoteAndBookingDocsData = async (shortShipment: ShipmentPreviewDTM) => {
    const currentOrg = userManagementR.selectors.userOrganizationData.getUserOrganization(this.store.getState());
    const { accountHolderOrgId } = shortShipment;

    if (!currentOrg) {
      return;
    }

    const pricingDepartment = await this.getDepartmentByOrganizationId(accountHolderOrgId, EAccountDepartmentType.PRICING);
    const docsOpsExportDepartment = await this.getDepartmentByOrganizationId(accountHolderOrgId, EAccountDepartmentType.DOCUMENTATION_OPS_EXPORT);
    const currentOrgRelatedAdmin = await userManagementR.services.organization.getRelatedAdminPublicInfo(currentOrg.id);

    if (currentOrg.accountHolderId) {
      const bookingOpsDepartment = await this.getDepartmentByOrganizationId(currentOrg.accountHolderId, EAccountDepartmentType.BOOKING_OPS);

      this.dispatch(R.actions.shipmentDocumentsAll.setAccountHolderBookingOpsDepartment(bookingOpsDepartment || null));
    }

    this.dispatch(R.actions.shipmentDocumentsAll.setAccountHolderPricingDepartment(pricingDepartment || null));
    this.dispatch(R.actions.shipmentDocumentsAll.setAccountHolderDocOpsExportDepartment(docsOpsExportDepartment || null));
    this.dispatch(R.actions.shipmentDocumentsAll.setCurrentOrgRelatedAdminPublicInfo(currentOrgRelatedAdmin));
    this.dispatch(R.actions.documentHBLPDF.setAccountHolderRelatedDocsExportDepartment(docsOpsExportDepartment || null));
    this.dispatch(R.actions.documentHBLPDF.setCurrentOrgRelatedAdminPublicInfo(currentOrgRelatedAdmin));
  }

  public initNRADocumentsAccordingToOrganizationRole = async (shipmentId: string, shortShipment: ShipmentPreviewDTM | null) => {
    const organization = userManagementR.selectors.userOrganizationData.getUserOrganization(this.store.getState());
    const shipment = await R.services.shipment.getShipmentById(+shipmentId);

    if (!organization || !shortShipment || !shipment) {
      return;
    }

    const { contractOwnerOrgId, customerOrgId } = shortShipment;

    // customer-admin NRA: customer view
    if (organization.role === EOrganizationMemberRole.CUSTOMER) {
      await this.getNRADocumentInformationForCustomer(shipmentId, shortShipment);

      const adminInfo = await userManagementR.services.organization.getRelatedAdminPublicInfo(customerOrgId);

      if (adminInfo) {
        const pricingDepartment = await this.getDepartmentByOrganizationId(adminInfo.id, EAccountDepartmentType.PRICING);

        this.dispatch(R.actions.shipmentDocumentsAll.setCustomerRelatedAdminPricingDepartment(pricingDepartment || null));
      }

      this.dispatch(R.actions.shipmentDocumentsAll.setCustomerRelatedAdminPublicInfo(adminInfo));
    }

    // contract owner-admin NRA: contract owner view
    if (organization.role === EOrganizationMemberRole.PARTNER && contractOwnerOrgId === organization.id) {
      // case between admin and contract owner - for partner
      const adminInfo = await userManagementR.services.organization.getRelatedAdminPublicInfo(contractOwnerOrgId);
      const contractOwner = await userManagementR.services.organization.getOrganizationById(contractOwnerOrgId);
      const pricingDepartment = await this.getDepartmentByOrganizationId(contractOwner.id, EAccountDepartmentType.PRICING);

      this.dispatch(R.actions.shipmentDocumentsAll.setContractOwnerPricingDepartment(pricingDepartment || null));
      this.dispatch(R.actions.shipmentDocumentsAll.setContractOwnerRelatedAdminPublicInfo(adminInfo));
      this.dispatch(R.actions.shipmentDocumentsAll.setContractOwnerOrganization(contractOwner));
      this.dispatch(R.actions.shipmentDocumentsAll.setDocumentNRABetweenContractOwnerAndAdmin(DocumentsCollapseItemDTM.fromPlain({
        name: `NRA for ${shortShipment.shipmentName} (from Partner)`,
        id: +shipment.quotaId,
        objectName: '-',
        createdBy: 'System',
        uploadedDate: shipment.createdAt ? DateDtm.fromPlain({
          date: shipment.createdAt,
          offset: moment.parseZone(shipment.createdAt).utcOffset(),
        }) : null,
        type: '',
      })));
    }

    if (organization.role === EOrganizationMemberRole.admin) {
      let customer: OrganizationDTM | undefined;
      let contractOwner: OrganizationDTM | undefined;
      let isAdminRelatedToCustomer = false;
      let isAdminRelatedToContractOwner = false;
      const customerRelatedAdmin = await userManagementR.services.organization.getRelatedAdminPublicInfo(customerOrgId);
      const contractOwnerRelatedAdmin = await userManagementR.services.organization.getRelatedAdminPublicInfo(contractOwnerOrgId);

      this.dispatch(R.actions.shipmentDocumentsAll.setCustomerRelatedAdminPublicInfo(customerRelatedAdmin));
      this.dispatch(R.actions.shipmentDocumentsAll.setContractOwnerRelatedAdminPublicInfo(contractOwnerRelatedAdmin));

      try {
        customer = await userManagementR.services.organization.getOrganizationById(customerOrgId);

        isAdminRelatedToCustomer = !!customer;
      } catch (e) {
        if (e instanceof ForbiddenError) {
          isAdminRelatedToCustomer = false;
        } else {
          throw e;
        }
      }

      try {
        contractOwner = await userManagementR.services.organization.getOrganizationById(contractOwnerOrgId);

        isAdminRelatedToContractOwner = !!contractOwner;
      } catch (e) {
        if (e instanceof ForbiddenError) {
          isAdminRelatedToContractOwner = false;
        } else {
          throw e;
        }
      }

      // customer-admin NRA: Admin view
      if (isAdminRelatedToCustomer) {
        if (customerRelatedAdmin?.id) {
          const pricingDepartment = await this.getDepartmentByOrganizationId(customerRelatedAdmin.id, EAccountDepartmentType.PRICING);

          this.dispatch(R.actions.shipmentDocumentsAll.setCustomerRelatedAdminPricingDepartment(pricingDepartment));
        }

        await this.getNRADocumentInformationForCustomer(shipmentId, shortShipment);

        this.dispatch(R.actions.shipmentDocumentsAll.setNRADocument(DocumentsCollapseItemDTM.fromPlain({
          name: `NRA for ${shortShipment.shipmentName} (from Customer)`,
          id: +shipment.quotaId,
          objectName: '-',
          createdBy: 'System',
          uploadedDate: shipment.createdAt ? DateDtm.fromPlain({
            date: shipment.createdAt,
            offset: moment.parseZone(shipment.createdAt).utcOffset(),
          }) : null,
          type: '',
        })));
      }

      // contract owner-admin NRA: Admin view
      if (isAdminRelatedToContractOwner && contractOwner) {
        const pricingDepartment = await this.getDepartmentByOrganizationId(contractOwner.id, EAccountDepartmentType.PRICING);

        this.dispatch(R.actions.shipmentDocumentsAll.setContractOwnerPricingDepartment(pricingDepartment));
        this.dispatch(R.actions.shipmentDocumentsAll.setContractOwnerOrganization(contractOwner));
        this.dispatch(R.actions.shipmentDocumentsAll.setDocumentNRABetweenContractOwnerAndAdmin(DocumentsCollapseItemDTM.fromPlain({
          name: `NRA for ${shortShipment.shipmentName} (from Partner)`,
          id: +shipment.quotaId,
          objectName: '-',
          createdBy: 'System',
          uploadedDate: shipment.createdAt ? DateDtm.fromPlain({
            date: shipment.createdAt,
            offset: moment.parseZone(shipment.createdAt).utcOffset(),
          }) : null,
          type: '',
        })));
      }

      // admin-admin NRA: Admin view
      if (!(isAdminRelatedToContractOwner && isAdminRelatedToCustomer)) {
        if (contractOwnerRelatedAdmin) {
          const pricingDepartment = await this.getDepartmentByOrganizationId(contractOwnerRelatedAdmin.id, EAccountDepartmentType.PRICING);

          this.dispatch(R.actions.shipmentDocumentsAll.setContractOwnerRelatedAdminPricingDepartment(pricingDepartment));
        }

        this.dispatch(R.actions.shipmentDocumentsAll.setDocumentNRABetweenAdminAndAdmin(DocumentsCollapseItemDTM.fromPlain({
          name: `NRA for ${shortShipment.shipmentName} (from Skypace)`,
          id: +shipment.quotaId,
          objectName: '-',
          createdBy: 'System',
          uploadedDate: shipment.createdAt ? DateDtm.fromPlain({
            date: shipment.createdAt,
            offset: moment.parseZone(shipment.createdAt).utcOffset(),
          }) : null,
          type: '',
        })));
      }
    }
  };

  public getDepartmentByOrganizationId = async (organizationId: number, departmentType: EAccountDepartmentType) => {
    const departments = await userManagementR.services.accountDepartment.getOrganizationDepartments({ organizationId: String(organizationId) });

    return departments.find(({ type }) => type === departmentType) || null;
  };

  public getNRADocumentInformationForCustomer = async (shipmentId: string, shortShipment: ShipmentPreviewDTM | null, preventAddingDocumentInstance?: boolean) => {
    let customer = R.selectors.shipmentDocumentsAll.getCustomer(this.store.getState());
    let customerUltimate = R.selectors.shipmentDocumentsAll.getCustomerUltimate(this.store.getState());
    let houseShipper = R.selectors.shipmentDocumentsAll.getCustomerUltimate(this.store.getState());
    let houseConsignee = R.selectors.shipmentDocumentsAll.getCustomerUltimate(this.store.getState());
    let shipment: GetShipmentResponse | null = null;

    if (!customer) {
      let shippingParties: ShippingPartyDTM[] = [];

      try {
        shippingParties = await R.services.shippingParties.getList(shipmentId);
      } catch (e) {
        console.error('ShipmentDocumentsAllController Error: getList');
      }

      customer = shippingParties.find(({ role }) => role === EShippingPartyTypes.CUSTOMER) || null;
      customerUltimate = shippingParties.find(({ role }) => role === EShippingPartyTypes.ULTIMATE_CUSTOMER) || null;
      houseShipper = shippingParties.find(({ role }) => role === EShippingPartyTypes.HOUSE_SHIPPER) || null;
      houseConsignee = shippingParties.find(({ role }) => role === EShippingPartyTypes.HOUSE_CONSIGNEE) || null;
    }

    this.dispatch(R.actions.shipmentDocumentsAll.setCustomer(customer));
    this.dispatch(R.actions.shipmentDocumentsAll.setCustomerUltimate(customerUltimate));
    this.dispatch(R.actions.shipmentDocumentsAll.setHouseShipper(houseShipper));
    this.dispatch(R.actions.shipmentDocumentsAll.setHouseConsignee(houseConsignee));

    try {
      shipment = await R.services.shipment.getShipmentById(+shipmentId);
    } catch (e) {
      console.error('ShipmentDocumentsAllController: getQuoteDocumentInformation');
    }

    let confirmations: ShipmentConfirmationDTM[] = [];
    let confirmationNRA: ShipmentConfirmationDTM | undefined;
    let isNRAConfirmed = false;

    try {
      confirmations = await R.services.shipment.getConfirmations(shipmentId);
    } catch (e) {
      console.error('getConfirmations');
    }

    const termsConfirmation = confirmations.find(({ type }) => type === EShipmentConfirmationTypes.TERMS_AND_CONDITIONS);

    this.dispatch(R.actions.shipmentDocumentsAll.setTermsAndConditionsConfirmation(termsConfirmation || null));

    if (confirmations.length && shortShipment?.isUsShipmentOriginOrDestination()) {
      confirmationNRA = confirmations?.filter((item) => item.type === EShipmentConfirmationTypes.NEGOTIATED_RATE_ARRANGEMENT).reduce((prev, current) => {
        if (current.id > prev.id) {
          return current;
        }

        return prev;
      });
      isNRAConfirmed = !!confirmationNRA;
    }

    const currentOrganization = userManagementR.selectors.userOrganizationData.getUserOrganization(this.store.getState());
    const memberId = currentOrganization?.role === EOrganizationMemberRole.PARTNER ? customer?.company?.organizationId : currentOrganization?.id;

    if (confirmationNRA && memberId) {
      const { createdBy } = confirmationNRA;
      let organizationMember: OrganizationMemberDTM | undefined;

      try {
        organizationMember = await userManagementR.services.organization.getOrganizationMember(memberId, createdBy);
      } catch (e) {
        console.error('CustomerController ERROR: getCurrentOrganization');
      }

      if (organizationMember) {
        this.dispatch(R.actions.shipmentDocumentsAll.setCreatedByNameNRA(organizationMember.getFullName()));
      }

      if (confirmations) {
        this.dispatch(R.actions.shipmentDocumentsAll.setCreatedByNameConfirmationNRA(confirmationNRA.getCreatedByFullName()));
      }

      this.dispatch(R.actions.shipmentDocumentsAll.setCreatedAtNRA(DateDtm.fromPlain({
        date: confirmationNRA.createdAt,
        offset: moment.parseZone(confirmationNRA.createdAt).utcOffset(),
      }).getDateAsMomentWithOffset().format('MMMM D, YYYY')));
    }

    if (!preventAddingDocumentInstance && isNRAConfirmed && shipment && shortShipment?.isUsShipmentOriginOrDestination()) {
      this.dispatch(R.actions.shipmentDocumentsAll.setNRADocument(DocumentsCollapseItemDTM.fromPlain({
        name: `NRA for ${shipment.shipmentName}`,
        id: +shipment.quotaId,
        objectName: '-',
        createdBy: 'System',
        uploadedDate: confirmationNRA ? DateDtm.fromPlain({
          date: confirmationNRA.createdAt,
          offset: moment.parseZone(confirmationNRA.createdAt).utcOffset(),
        }) : null,
        type: '',
      })));
    }
  };

  public getHBLDocumentInformation = async (shipmentId: string, fullShipment: FullShipmentDTM | null) => {
    if (!fullShipment) {
      return;
    }

    let shippingParties: ShippingPartyDTM[] = [];
    let billOfLading: BillOfLadingDTM | undefined;
    let charges: ChargeDTM[] = [];

    try {
      shippingParties = await R.services.shippingParties.getList(shipmentId);
    } catch (e) {
      console.error('ShipmentDocumentsAllController Error: getList');
    }

    try {
      billOfLading = await R.services.billOfLading.getBillOfLading(shipmentId);
    } catch (e) {
      console.error('ShipmentDocumentsAllController Error: getBillOfLading');
    }

    const customer = shippingParties.find(({ role }) => role === EShippingPartyTypes.CUSTOMER);

    if (customer && customer.company && customer.company.id) {
      this.dispatch(R.actions.shipmentDocumentsAll.setCustomer(customer));

      charges = await R.services.shipmentCharges.getCustomerCharges(shipmentId, customer.company.id);
    }

    const customs = await R.services.exportClearance.getExportClearance(shipmentId);
    const customsClearanceITN = customs.find(({ type }) => type === ExportClearanceType.ITN);

    this.dispatch(R.actions.documentHBLPDF.setHBLDocumentBOL(this.getHBLBillOfLading(fullShipment, charges, shippingParties, billOfLading, customsClearanceITN)));
  };

  public getInvoicesDocumentsInformation = async (shipmentId: string) => {
    let payebleInvoices: ParsedInvoiceDTM[] = [];
    let receivableInvoices: ParsedInvoiceDTM[] = [];

    try {
      payebleInvoices = await R.services.shipmentBilling.getShipmentEntities(shipmentId, PAYABLES);
      receivableInvoices = await R.services.shipmentBilling.getShipmentEntities(shipmentId, RECEIVABLES);
    } catch (e) {
      console.error('ShipmentDocumentsAllController Error: getInvoicesDocumentsInformation');
    }

    const payebleDocuments = payebleInvoices.filter(({ type }) => type.toUpperCase() === EInvoiceType.INVOICE).map((invoice) => DocumentsCollapseItemDTM.fromPlain({
      name: `Invoice ${invoice.id}`,
      id: invoice.id,
      objectName: '-',
      createdBy: 'System',
      uploadedDate: DateDtm.fromPlain({
        date: invoice.createdAt,
        offset: moment.parseZone(invoice.createdAt).utcOffset(),
      }),
      type: '',
    }));
    const receivableDocuments = receivableInvoices.filter(({ type }) => type.toUpperCase() === EInvoiceType.INVOICE).map((invoice) => DocumentsCollapseItemDTM.fromPlain({
      name: `Invoice ${invoice.id}`,
      id: invoice.id,
      objectName: '-',
      createdBy: 'System',
      uploadedDate: DateDtm.fromPlain({
        date: invoice.createdAt,
        offset: moment.parseZone(invoice.createdAt).utcOffset(),
      }),
      type: '',
    }));

    this.dispatch(R.actions.shipmentDocumentsAll.setPayableInvoices(payebleDocuments));
    this.dispatch(R.actions.shipmentDocumentsAll.setReceivableInvoices(receivableDocuments));
  };

  public getBookingConfirmationDocumentInformation = async (shipmentId: string, fullShipment: FullShipmentDTM | null) => {
    this.dispatch(R.actions.bookingConfirmationPDF.setIsLoadingFinished(false));
    this.dispatch(R.actions.bookingConfirmationPDF.setIsLoading(true));

    if (!fullShipment) {
      this.dispatch(R.actions.bookingConfirmationPDF.setIsLoadingFinished(false));
      this.dispatch(R.actions.bookingConfirmationPDF.setIsLoading(false));

      return;
    }

    if (fullShipment) {
      const { transportationPlans } = fullShipment;
      const [plan] = transportationPlans;
      const { route, transportations } = plan;
      const { legs } = route;

      const lowestLeg = this.findLowestSequenceLeg(legs);
      const seaLegs = this.getSeaLegsByTransportation(legs, transportations);
      const lowestSeaLeg = this.findLowestSequenceLeg(seaLegs);
      const highestSeaLeg = this.findHighestSequenceLeg(seaLegs);
      const highestLeg = this.findHighestSequenceLeg(legs);
      const lowestSeaTransportation = this.findTransportationByLeg(lowestSeaLeg, transportations);
      const lowestTransportation = this.findTransportationByLeg(lowestLeg, transportations);
      const highestTransportation = this.findTransportationByLeg(highestLeg, transportations);
      const highestSeaTransportation = this.findTransportationByLeg(highestSeaLeg, transportations);

      this.dispatch(R.actions.bookingConfirmationPDF.setLowestSequenceLeg(lowestLeg));
      this.dispatch(R.actions.bookingConfirmationPDF.setHighestSequenceLeg(highestLeg));
      this.dispatch(R.actions.bookingConfirmationPDF.setLowestSequenceSeaLeg(lowestSeaLeg));
      this.dispatch(R.actions.bookingConfirmationPDF.setHighestSequenceSeaLeg(highestSeaLeg));
      this.dispatch(R.actions.bookingConfirmationPDF.setLowestSequenceTransportation(lowestTransportation));
      this.dispatch(R.actions.bookingConfirmationPDF.setLowestSeaSequenceTransportation(lowestSeaTransportation));
      this.dispatch(R.actions.bookingConfirmationPDF.setHighestSequenceTransportation(highestTransportation));
      this.dispatch(R.actions.bookingConfirmationPDF.setHighestSeaSequenceTransportation(highestSeaTransportation));
    }

    let shippingParties: ShippingPartyDTM[] = [];

    try {
      shippingParties = await R.services.shippingParties.getList(shipmentId);
    } catch (e) {
      console.error('BookingConfirmationPDF Controller: fetchData - getShippingParties');
    }

    let charges: ShortChargeDTM[] = [];
    let chargesPairs: ChargeDTM[] = [];

    const customer = shippingParties.find(({ role }) => role === EShippingPartyTypes.CUSTOMER);

    try {
      charges = await R.services.createInvoiceCharges.getCharges(shipmentId, PAYABLES);

      if (customer && customer.company && customer.company.id) {
        chargesPairs = await R.services.shipmentCharges.getCustomerCharges(shipmentId, customer.company.id);
      }
    } catch (e) {
      console.error('BookingConfirmationPDF Controller: fetchData - getCharges');
      this.dispatch(R.actions.bookingConfirmationPDF.setIsLoadingFinished(false));
      this.dispatch(R.actions.bookingConfirmationPDF.setIsLoading(false));

      return;
    }

    this.dispatch(R.actions.bookingConfirmationPDF.setCharges(charges));
    this.dispatch(R.actions.bookingConfirmationPDF.setChargesPairs(chargesPairs));
    this.dispatch(R.actions.bookingConfirmationPDF.setShippingParties(shippingParties));
    this.dispatch(R.actions.bookingConfirmationPDF.setFullShipment(fullShipment));
    this.dispatch(R.actions.bookingConfirmationPDF.setIsLoadingFinished(true));
    this.dispatch(R.actions.bookingConfirmationPDF.setIsLoading(false));
  }

  public getFileNamePrint = (shipmentName?: string, quota?: FreightQuotaContentDTM, isNra?: boolean) => {
    let fileName = 'Skypace. Quote #';

    if (isNra) {
      fileName = 'Skypace. NRA for Shipment #';
    }

    if (shipmentName && isNra) {
      fileName += `${shipmentName}`;
    }

    if (quota?.id && !isNra) {
      fileName += `${quota?.id}`;
    }

    fileName += `.${quota?.getOriginLocationUNLOCK()}`;
    fileName += ` - ${quota?.getDestinationLocationUNLOCK()}`;

    return fileName;
  }

  public loadQuoteDocumentInformation = async (shipmentId?: string, isNra?: boolean) => {
    if (!shipmentId) {
      return {
        id: 0,
        fileName: '',
      };
    }

    let shipment: GetShipmentResponse | null = null;

    this.dispatch(R.actions.shipmentDocumentsAll.setIsRFQLoading(true));

    try {
      shipment = await R.services.shipment.getShipmentById(+shipmentId);
    } catch (e) {
      console.error('ShipmentDocumentsAllController: getQuoteDocumentInformation');
    }

    let quota: FreightQuotaContentDTM | null = null;

    if (shipment) {
      const { quotaRequestId, quotaId } = shipment;

      try {
        quota = await monetaryR.services.RFQServiceById.getQuotaById(quotaRequestId, quotaId);
      } catch (e) {
        console.error('ShipmentDocumentsAllController: getQuoteDocumentInformation');
      }
    }

    if (quota) {
      this.dispatch(monetaryR.actions.freightQuoteActions.setCurrentStateFileNamePrint(this.getFileNamePrint(shipment?.shipmentName, quota, isNra)));
      const allQuotas = monetaryR.selectors.freightQuote.getFreightQuoteCurrentAllQuotas(this.store.getState());

      if (!allQuotas || !allQuotas.length) {
        this.dispatch(monetaryR.actions.freightQuoteActions.setCurrentStateQuotasAllQuotas([quota]));
        this.dispatch(R.actions.shipmentDocumentsAll.setQuoteIndex(0));
      } else {
        const targetQuotaIndex = allQuotas.findIndex(({ id }) => id === quota?.id);

        if (targetQuotaIndex === -1) {
          this.dispatch(monetaryR.actions.freightQuoteActions.setCurrentStateQuotasAllQuotas([quota]));
          this.dispatch(R.actions.shipmentDocumentsAll.setQuoteIndex(0));
        } else {
          this.dispatch(R.actions.shipmentDocumentsAll.setQuoteIndex(targetQuotaIndex));
        }
      }

      this.dispatch(monetaryR.actions.freightQuoteActions.setCurrentStateFormServicesPrintedQuotaId(quota.id));
      this.dispatch(R.actions.shipmentDocumentsAll.setQuoteIndexNRA(quota.id));
    }

    const shortShipment = await R.services.shipment.getShipmentShortById(+shipmentId);

    this.dispatch(appR.actions.agreements.setShipment(shortShipment));

    this.dispatch(monetaryR.actions.freightQuoteActions.setCurrentStateQuotasFullCompleteStatus(QUOTAS_STATUS.complete));
    this.dispatch(monetaryR.actions.freightQuoteActions.setCurrentStateQuotasStatus(REQUEST_STATUS.complete));
    this.dispatch(R.actions.shipmentDocumentsAll.setIsRFQLoading(false));

    const quotaIndex = R.selectors.shipmentDocumentsAll.getQuoteIndex(this.store.getState());

    return {
      id: !quotaIndex || quotaIndex === -1 ? 0 : quotaIndex,
      fileName: this.getFileNamePrint(shipment?.shipmentName, quota || undefined, isNra),
    };
  };

  public getAdditionalDocuments = async (shipmentId: string) => {
    let response: putShipmentDocumentsAdditionalDetailsResponse | null = null;
    let responseMblDocuments: getShipmentDocumentsSiDetailsResponse | null = null;

    try {
      response = await R.services.shipmentDocument.getAdditionalDetails(+shipmentId);
    } catch (e) {
      console.error('ShipmentDocumentsAllController ERROR: getAdditionalDetails');
    }

    responseMblDocuments = await R.services.shipmentDocument.getSiDetails(+shipmentId);

    const additionalMap: Record<string, DocumentsCollapseItemDTM[]> = {};
    const skypaceDownloadDocumentsMap: Record<string, DocumentsCollapseItemDTM[]> = {};

    if (response && response.files.length) {
      this.applyDocumentsToMap(response.files, additionalMap);
    }

    if (responseMblDocuments && responseMblDocuments?.draftMblDocument?.type) {
      const document = this.formatDocumentsCollapseItemFromAdditionalFile(
        responseMblDocuments?.draftMblDocument.id,
        responseMblDocuments?.draftMblDocument?.name,
        responseMblDocuments?.draftMblDocument.type,
        responseMblDocuments?.draftMblDocument?.createdBy || '',
        responseMblDocuments?.draftMblDocument?.createdAt ? DateDtm.fromPlain({
          date: responseMblDocuments?.draftMblDocument?.createdAt,
          offset: moment.parseZone(responseMblDocuments?.draftMblDocument?.createdAt).utcOffset(),
        }) : null,
      );

      skypaceDownloadDocumentsMap[responseMblDocuments.draftMblDocument.type] = [document];
    }

    if (responseMblDocuments && responseMblDocuments?.finalMblDocument?.type) {
      const document = this.formatDocumentsCollapseItemFromAdditionalFile(
        responseMblDocuments?.finalMblDocument.id,
        responseMblDocuments?.finalMblDocument?.name,
        responseMblDocuments?.finalMblDocument.type,
        responseMblDocuments?.finalMblDocument?.createdBy || '',
        responseMblDocuments?.finalMblDocument?.createdAt ? DateDtm.fromPlain({
          date: responseMblDocuments?.finalMblDocument?.createdAt,
          offset: moment.parseZone(responseMblDocuments?.finalMblDocument?.createdAt).utcOffset(),
        }) : null,
      );

      skypaceDownloadDocumentsMap[responseMblDocuments.finalMblDocument.type] = [document];
    }

    if (response) {
      this.dispatch(R.actions.shipmentDocumentsAll.setNotes(response.notes));
      this.dispatch(R.actions.shipmentDocumentsAll.setAdditionalDetailsId(response.id));
      this.dispatch(R.actions.shipmentDocumentsAll.setReferences(response.references.map((reference) => ReferenceDTM.fromPlain({
        id: String(reference.id),
        value: reference.value,
        type: '',
      }))));
    }

    if (!response) {
      this.dispatch(R.actions.shipmentDocumentsAll.setShouldUsePostForAdditionalDetails(!response));
    }

    this.dispatch(R.actions.shipmentDocumentsAll.setAdditionalDocumentsMap(additionalMap));
    this.dispatch(R.actions.shipmentDocumentsAll.setSkypaceDownloadDocumentsMap(skypaceDownloadDocumentsMap));
  };

  public setAdditionalDocType = (type: string) => {
    this.dispatch(R.actions.shipmentDocumentsAll.setAdditionalType(type));
  };

  public setAdditionalDocument = (document: ContainerDocumentDTM | null) => {
    this.dispatch(R.actions.shipmentDocumentsAll.setAdditionalDocument(document));
  };

  public downloadAdditionalDocument = (shipmentId: string) => {
    const document = R.selectors.shipmentDocumentsAll.getAdditionalDocument(this.store.getState());

    if (document) {
      this.downloadDocument(shipmentId, document.response.id, document.response.name);
    }
  };

  public uploadAdditionalDocument = async (shipmentId: string) => {
    const fileList = R.selectors.shipmentDocumentsAll.getAdditionalFileListToSave(this.store.getState());
    const notes = R.selectors.shipmentDocumentsAll.getNotes(this.store.getState());
    const additionalDetalsId = R.selectors.shipmentDocumentsAll.getAdditionalDetailsId(this.store.getState());
    const references = R.selectors.shipmentDocumentsAll.getReferences(this.store.getState());
    const shouldUsePOSTRequest = R.selectors.shipmentDocumentsAll.getShouldUsePostForAdditionalDetails(this.store.getState());

    this.dispatch(R.actions.shipmentDocumentsAll.setIsLoading(true));

    let response: putShipmentDocumentsAdditionalDetailsResponse | null = null;

    try {
      if (shouldUsePOSTRequest) {
        const rawResponse = await R.services.shipmentDocument.postAdditionalDetails(+shipmentId, {
          id: additionalDetalsId,
          files: fileList,
          notes,
          references: references.map((reference) => ({
            value: reference.value,
            id: +reference.id,
          })),
        });

        response = rawResponse as putShipmentDocumentsAdditionalDetailsResponse;
      } else {
        response = await R.services.shipmentDocument.putAdditionalDetails(+shipmentId, {
          id: additionalDetalsId,
          files: fileList,
          notes,
          references: references.map((reference) => ({
            value: reference.value,
            id: +reference.id,
          })),
        }) as putShipmentDocumentsAdditionalDetailsResponse;
      }
    } catch (e) {
      console.error('ShipmentDocumentsAllController CONTROLLER: putAdditionalDetails');
    }

    const additionalMap: Record<string, DocumentsCollapseItemDTM[]> = {};

    if (response && response.files.length) {
      this.applyDocumentsToMap(response.files, additionalMap);
    }

    if (response) {
      this.dispatch(R.actions.shipmentDocumentsAll.setNotes(response.notes));
      this.dispatch(R.actions.shipmentDocumentsAll.setAdditionalDetailsId(response.id));
      this.dispatch(R.actions.shipmentDocumentsAll.setReferences(response.references.map((reference) => ReferenceDTM.fromPlain({
        id: String(reference.id),
        value: reference.value,
        type: '',
      }))));
    }

    this.dispatch(R.actions.shipmentDocumentsAll.setAdditionalDocumentsMap(additionalMap));
    this.dispatch(R.actions.shipmentDocumentsAll.setShouldUsePostForAdditionalDetails(false));

    this.dispatch(R.actions.shipmentDrawer.closeDrawer());
    this.dispatch(R.actions.shipmentDocumentsAll.setIsLoading(false));
  };

  public downloadDocument = async (shipmentId: string, documentId: number, documentName: string) => {
    try {
      R.services.shipmentDocument.getShipmentDocument(+shipmentId, documentId, documentName);
    } catch (e) {
      console.error('ShipmentDocumentsAllController CONTROLLER: downloadDocument');
    }
  }

  public onRemoveAdditionalFile = async (shipmentId: string | number, document: DocumentsCollapseItemDTM) => {
    const { id } = document;

    try {
      await R.services.shipmentDocument.onRemoveFile(shipmentId, String(id));

      this.dispatch(R.actions.shipmentDocumentsAll.removeAdditionalDocumentFromMap(document));
    } catch (e) {
      console.error('ShipmentDocumentsAllController CONTROLLER: onRemoveFile');
    }
  };

  public cleanup = () => {
    this.dispatch(R.actions.shipmentDocumentsAll.setQuoteIndex(0));
  };

  private formatDocumentsCollapseItemFromAdditionalFile = (id: number, name: string, type: string, createdBy: string, createdAt: DateDtm | null) => DocumentsCollapseItemDTM.fromPlain({
    id,
    name,
    uploadedDate: createdAt,
    objectName: '-',
    createdBy: createdBy || '-',
    type,
  });

  private applyDocumentsToMap = (files: putShipmentDocumentsAdditionalDetailsResponse['files'], documentsMap: Record<string, DocumentsCollapseItemDTM[]>) => {
    if (files && files.length) {
      files.forEach(({
        id,
        name,
        type,
        createdBy,
        createdAt,
      }) => {
        const document = this.formatDocumentsCollapseItemFromAdditionalFile(
          id,
          name,
          type,
          createdBy,
          createdAt ? DateDtm.fromPlain({
            date: createdAt,
            offset: moment.parseZone(createdAt).utcOffset(),
          }) : null,
        );

        if (documentsMap[type]) {
          documentsMap[type].push(document);
        } else {
          documentsMap[type] = [document];
        }
      });
    }
  };

  private findLowestSequenceLeg = (legs: IRouteLegDTM[]): IRouteLegDTM => {
    let lowestSequence = legs[0].sequence;
    let lowestLeg = legs[0];

    legs.forEach((leg) => {
      if (leg.sequence < lowestSequence) {
        lowestSequence = leg.sequence;
        lowestLeg = leg;
      }
    });

    return lowestLeg;
  }

  private findHighestSequenceLeg = (legs: IRouteLegDTM[]): IRouteLegDTM => {
    let highestSequence = legs[0].sequence;
    let highestLeg = legs[0];

    legs.forEach((leg) => {
      if (leg.sequence > highestSequence) {
        highestSequence = leg.sequence;
        highestLeg = leg;
      }
    });

    return highestLeg;
  }

  private findTransportationByLeg = (leg: IRouteLegDTM, transportations: IGetShipmentPlansResponse['transportations']) => {
    const transportation = transportations.find(({ transportLeg }) => transportLeg === leg.id);

    return transportation;
  }

  private getSeaLegsByTransportation = (legs: IRouteLegDTM[], transportations: IGetShipmentPlansResponse['transportations']) => {
    const seaTransportations = transportations.filter(({ transport }) => transport.type === ShipmentFreightMode.SEA);

    return seaTransportations.map(({ transportLeg }) => legs.find(({ id }) => id === transportLeg) as IRouteLegDTM);
  }

  private getChargesSum = (charges: ChargeDTM[]) => reduce((acc, item) => {
    acc += propOr(0, 'buyTotalCost', item);

    return acc;
  }, 0, charges);

  private getChargesCurrency = (charges: ChargeDTM[]): string => {
    const headCharge = head(charges || []);

    return propOr('USD', 'currency', headCharge);
  }

  private getCombinedCharge = (charges: ChargeDTM[]) => ChargeDTM.fromPlain({
    ...charges[0],
    buyTotalCost: charges.reduce((acc, next) => acc + next.buyTotalCost, 0),
  });

  private getCalculatedCharges = (charges: ChargeDTM[]) => {
    const actualCharges = charges.filter(({ applied }) => applied);
    const codeToChargesMap: Record<string, ChargeDTM[]> = {};
    const finalCharges: ChargeDTM[] = [];

    actualCharges.forEach((charge) => {
      const { code } = charge.chargeCode;

      if (codeToChargesMap[code]) {
        codeToChargesMap[code].push(charge);
      } else {
        codeToChargesMap[code] = [charge];
      }
    });

    Object.keys(codeToChargesMap).forEach((chargeCode) => {
      const localCharges = codeToChargesMap[chargeCode];
      const originalDescriptionToChargesMap: Record<string, ChargeDTM[]> = {};

      localCharges.forEach((charge) => {
        const key = charge.metadata && charge.metadata.originalDescription ? charge.metadata.originalDescription : 'none';

        if (originalDescriptionToChargesMap[key]) {
          originalDescriptionToChargesMap[key].push(charge);
        } else {
          originalDescriptionToChargesMap[key] = [charge];
        }
      });

      Object.values(originalDescriptionToChargesMap).forEach((_charges) => {
        const freightCharges = _charges.filter(({ designation }) => designation === TRouteLegPhase.FREIGHT);
        const originCharges = _charges.filter(({ designation }) => designation === TRouteLegPhase.ORIGIN);
        const destinationCharges = _charges.filter(({ designation }) => designation === TRouteLegPhase.DESTINATION);

        if (freightCharges.length) {
          finalCharges.push(this.getCombinedCharge(freightCharges));
        }

        if (originCharges.length) {
          finalCharges.push(this.getCombinedCharge(originCharges));
        }

        if (destinationCharges.length) {
          finalCharges.push(this.getCombinedCharge(destinationCharges));
        }
      });
    });

    return [
      ...finalCharges.filter(({ designation }) => designation === ChargeCodeDesignation.ORIGIN),
      ...finalCharges.filter(({ designation }) => designation === ChargeCodeDesignation.FREIGHT),
      ...finalCharges.filter(({ designation }) => designation === ChargeCodeDesignation.DESTINATION),
    ];
  }

  private getHBLBillOfLading = (fullShipment: FullShipmentDTM, charges: ChargeDTM[], shipmentParties: ShippingPartyDTM[], billOfLading?: BillOfLadingDTM, exportCustoms?: CustomsClearanceDTM) => {
    const {
      id,
      paymentTerms,
      organization,
      transportationPlans,
      cargos,
      containers,
      siDetails,
    } = fullShipment;
    const { clauses } = siDetails;
    const [transportationPlan] = transportationPlans;
    const { transportations, route } = transportationPlan;
    const { legs } = route;

    const sortedTransportations = sortBy(prop('transportLeg'), transportations);
    const seaTransportations = filter(({ transport }) => transport.type === 'SEA', sortedTransportations);
    const firstSeaTransportation = head(seaTransportations);
    const firstSeaLeg = find(({ id: _id }) => _id === firstSeaTransportation?.transportLeg, legs);
    const lastSeaTransportations = last(seaTransportations);
    const lastSeaLeg = find(({ id: _id }) => _id === lastSeaTransportations?.transportLeg, legs);
    const sortedLegs = sortBy(prop('id'), legs);
    const firstLeg = head(sortedLegs);
    const firstLegTransportation = find(({ transportLeg }) => transportLeg === firstLeg?.id, transportations);
    const lastLeg = last(sortedLegs);
    const lastLegTransportation = find(({ transportLeg }) => transportLeg === lastLeg?.id, transportations);

    const vesselName = pathOr('', ['transport', 'name'], firstSeaTransportation);
    const voyagerNumber = pathOr('', ['transport', 'number'], firstSeaTransportation);
    const preCarriageLocation = pathOr('', ['departureLocation', 'name'], firstSeaLeg);
    const onCarriageLocation = pathOr('', ['arrivalLocation', 'name'], lastSeaLeg);
    const placeOfReceipt = pathOr('', ['departureLocation', 'name'], firstLeg);
    const portOfLoading = preCarriageLocation;
    const portOfDischarge = onCarriageLocation;
    const placeOfDelivery = pathOr('', ['arrivalLocation', 'name'], lastLeg);
    const firstLegTransportType = pathOr('ROAD', ['transport', 'type'], firstLegTransportation) as ETransportationType;
    const lastLegTransportType = pathOr('ROAD', ['transport', 'type'], lastLegTransportation) as ETransportationType;
    const firstSeaLegTransportType = pathOr('ROAD', ['transport', 'type'], firstSeaTransportation) as ETransportationType;
    const lastSeaLegTransportType = pathOr('ROAD', ['transport', 'type'], lastSeaTransportations) as ETransportationType;

    const transportationPlanInfo = {
      vesselName,
      voyagerNumber,
      preCarriageLocation,
      onCarriageLocation,
      placeOfReceipt,
      portOfLoading,
      portOfDischarge,
      placeOfDelivery,
      firstLegTransportType,
      lastLegTransportType,
      firstSeaLegTransportType,
      lastSeaLegTransportType,
    };

    let departureTimeString: string | undefined;
    transportationPlans.find((_transportationPlan) => {
      departureTimeString = _transportationPlan.transportations.find((transportation) => transportation.transport.type === ETransportationType.SEA)?.schedule.departureTime;
      return departureTimeString;
    });

    const departureTime = departureTimeString ? DateDtm.fromPlain({
      date: departureTimeString,
      offset: moment.parseZone(departureTimeString).utcOffset(),
    }) : undefined;

    const cargoValues = reduce((acc, item) => {
      const {
        packagesNumber,
        weight,
        volume,
      } = acc;
      acc = {
        packagesNumber: Number(packagesNumber) + Number(item.packagesNumber),
        netWeight: 0,
        weight: Number(weight) + Number(item.weight),
        volume: Number(volume) + Number(item.volume),
      };

      return acc;
    }, {
      packagesNumber: 0,
      netWeight: 0,
      weight: 0,
      volume: 0,
    }, cargos);

    const marksAndNumbers = reduce<CargoDTM, IHBLMarksAndNumbersDTM>((acc, item) => {
      const { references, marks } = acc;

      let newMarks = [...marks];

      if (item.marks) {
        newMarks = [...newMarks, item.marks];
      }

      acc = {
        references: [...references, ...item.references],
        marks: newMarks,
      };

      return acc;
    }, { references: [], marks: [] }, cargos);

    const cargo = {
      ...cargoValues,
      references: marksAndNumbers.references.map((item) => (item.value ? item.value : '')),
      marks: marksAndNumbers.marks,
    };
    const containerTypes = map(({ type }) => type, containers);
    const container = {
      quantity: containers.length,
      containerTypes: uniq(containerTypes),
      marks: marksAndNumbers.marks,
      references: marksAndNumbers.references,
    };
    const mappedContainers = map((_container) => {
      const cargoItems = map((item) => {
        const originalCargo = find((_cargo) => String(item.cargoId) === String(_cargo.id), cargos);

        return ({
          ...originalCargo,
          ...item,
        });
      }, _container.cargoItems);

      return ({
        ..._container,
        cargoItems,
      });
    }, containers);
    const filteredCharges = filter(({ applied }) => applied, charges);
    const groupedCharges = groupBy(prop('designation'), filteredCharges);

    const originChargesSum = this.getChargesSum(groupedCharges.ORIGIN);
    const originCurrency = this.getChargesCurrency(groupedCharges.ORIGIN);
    const freightChargesSum = this.getChargesSum(groupedCharges.FREIGHT);
    const freightCurrency = this.getChargesCurrency(groupedCharges.FREIGHT);
    const destinationChargesSum = this.getChargesSum(groupedCharges.DESTINATION);
    const destinationCurrency = this.getChargesCurrency(groupedCharges.DESTINATION);
    const cargoSum = reduce((acc, item) => {
      acc += +propOr(0, 'value', item);

      return acc;
    }, 0, cargos);
    const chargesInfo = {
      originChargesSum: [originCurrency, String(originChargesSum.toFixed(2))].join(' '),
      freightChargesSum: [freightCurrency, String(freightChargesSum.toFixed(2))].join(' '),
      destinationChargesSum: [destinationCurrency, String(destinationChargesSum.toFixed(2))].join(' '),
      cargoSum: `USD ${cargoSum.toFixed(2)}`,
    };

    const containersWithCargos = containers.map((_container) => (ContainerWithCargoDTM.fromPlain({
      ..._container,
      cargoItems: _container.cargoItems.map((cargoItem) => {
        const targetCargo = cargos.find(({ id: _id }) => +cargoItem.cargoId === _id) as CargoDTM;

        return {
          ...cargoItem,
          cargo: targetCargo,
        };
      }),
    })));

    return HBLDocumentBOLDTM.fromPlain({
      id,
      type: '',
      paymentTerms,
      billOfLading,
      organization,
      shipmentParties,
      transportationPlanInfo,
      // @ts-ignore fix it
      exportCustoms,
      importCustoms: undefined,
      cargo,
      cargos,
      container,
      temperatureControl: fullShipment.temperatureControl || undefined,
      containersWithCargo: containersWithCargos,
      containers: mappedContainers,
      legs,
      chargesInfo,
      charges: this.getCalculatedCharges(charges),
      clauses,
      departureTime,
    });
  }
}
