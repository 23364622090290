export enum ChargesTypes {
  AP = 'AP',
  AR = 'AR',
}

export enum ChargesHistoryAction {
  UPDATED = 'updated',
  CREATED = 'created',
  REMOVED = 'removed',
}
