import { Type } from 'class-transformer';
import {
  IsBoolean, IsDefined, IsEnum, IsOptional, IsString, ValidateNested,
} from 'class-validator';
import { BaseDTM } from 'proto/BaseDTM';

import { EOrganizationMemberRole } from 'user-management/constants';
import {
  AccountDepartmentDTM,
  IAccountDepartmentDTM,
  ITeamMemberDTM,
  TeamMemberDTM,
} from 'user-management/models/dtm';

import { EOrganizationStatus } from './OrganizationDTM/OrganizationStatus.dtm';

export type TOrganizationMemberDTM = {
  email: string
  role?: EOrganizationMemberRole
  status?: EOrganizationStatus
  sub?: string
  enabled?: boolean
  primary?: boolean
  firstName?: string
  lastName?: string
  phone?: string
  phone2?: string
  id?: string
  customId?: string
  onboarded?: boolean
  invitedBy?: string
  createdAt?: string
  jobTitle?: string
  teams: ITeamMemberDTM[];
  departments: IAccountDepartmentDTM[];
}

export class OrganizationMemberDTM extends BaseDTM<TOrganizationMemberDTM> {
  @IsDefined()
  @IsString()
  // TODO: disabled due to out of sync with the backend
  // @IsEmail()
  email: string

  @IsDefined()
  @IsEnum(EOrganizationMemberRole)
  role: EOrganizationMemberRole

  @IsOptional()
  @IsString()
  sub: string

  @IsOptional()
  @IsBoolean()
  enabled: boolean

  @IsOptional()
  @IsBoolean()
  primary: boolean

  @IsOptional()
  @IsBoolean()
  onboarded: boolean

  @IsOptional()
  @IsString()
  phone?: string

  @IsOptional()
  @IsString()
  phone2?: string

  @IsOptional()
  @IsString()
  id?: string

  @IsOptional()
  @IsString()
  firstName: string

  @IsOptional()
  @IsString()
  lastName: string

  @IsOptional()
  @IsEnum(EOrganizationStatus)
  status?: EOrganizationStatus

  @IsOptional()
  @IsString()
  invitedBy?: string

  @IsOptional()
  @IsString()
  createdAt?: string

  @IsOptional()
  @IsString()
  jobTitle?: string;

  @IsDefined()
  @ValidateNested({ each: true })
  @Type(() => AccountDepartmentDTM)
  departments: AccountDepartmentDTM[];

  @IsDefined()
  @ValidateNested({ each: true })
  @Type(() => TeamMemberDTM)
  teams: TeamMemberDTM[];

  getFullName = () => {
    const firstName = this.firstName || '';
    const lastName = this.lastName || '';
    const isNameSet = this.firstName && this.lastName;

    return isNameSet ? `${firstName} ${lastName}` : '';
  }

  public getFirstLetterOfName = () => (this.firstName.length ? this.firstName[0].toUpperCase() : '');
}

export type TMemberRoleForEdit = {
  role: string
  email?: string
  onboarded?: boolean
}

export class MemberRoleForEdit extends BaseDTM<TMemberRoleForEdit> {
  @IsDefined()
  @IsString()
  role: string

  @IsOptional()
  @IsString()
  email: string

  @IsOptional()
  @IsBoolean()
  onboarded: boolean
}
