import React, { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Dropdown from 'antd/es/dropdown';
import Menu from 'antd/es/menu';
import Modal from 'antd/es/modal';
import InfiniteScroll from 'react-infinite-scroll-component';

import { ContractDTM } from 'shipment-operations/models/dtm';
import { ECarrierSCAC, CarrierSCACNames } from 'monetary/constants';
import { PermissionAttributePolicy } from 'shipment-operations/constants';
import { TableSkeleton } from 'app-wrapper/view/components';
import { hasAccess } from 'app-wrapper/utils';

import {
  TableCell,
  TableCellNumber,
  TableWrap,
  CustomTable,
  MenuIcon,
  DeleteMenuItem,
} from './ContractsTable.styled';

interface IContractsTableComponentProps {
  contracts: ContractDTM[];
  deleteContractById: (contractId: number) => void;
  openEditContractDrawer: (contract: ContractDTM) => void;
  contractsPermissions?: PermissionAttributePolicy;
  loadMore: () => void;
  wasLastPaginationRequestLast: boolean;
  isLoading: boolean;
}

export const ContractsTableComponent: FC<IContractsTableComponentProps> = ({
  contracts,
  deleteContractById,
  openEditContractDrawer,
  contractsPermissions,
  loadMore,
  wasLastPaginationRequestLast,
  isLoading,
}) => {
  const { t } = useTranslation();

  const handleRemove = useCallback((contractId: number) => {
    Modal.confirm({
      title: t('Are you sure you want to remove this contract?'),
      okText: 'Yes',
      cancelText: 'No',
      onOk: () => {
        if (deleteContractById) {
          deleteContractById(contractId);
        }
      },
    });
  }, [deleteContractById]);

  const isAbleToEditContracts = Boolean(contractsPermissions && hasAccess(contractsPermissions, PermissionAttributePolicy.WRITE));

  const columns = useMemo(() => [
    {
      title: t('Contract Number'),
      width: '30%',
      key: 'object',
      align: 'left' as 'left',
      render: (item: string, record: ContractDTM | {}) => {
        const contract = record as ContractDTM;

        return (
          <TableCellNumber>
            {contract.number}
          </TableCellNumber>
        );
      },
    },
    {
      title: t('Category'),
      width: '30%',
      key: 'object',
      align: 'left' as 'left',
      render: (item: string, record: ContractDTM | {}) => {
        const contract = record as ContractDTM;

        return (
          <TableCell>
            {contract.category || t('-')}
          </TableCell>
        );
      },
    },
    {
      title: t('Carrier'),
      width: '30%',
      key: 'object',
      align: 'left' as 'left',
      render: (item: string, record: ContractDTM | {}) => {
        const contract = record as ContractDTM;

        return (
          <TableCell>
            {CarrierSCACNames[contract.scac as ECarrierSCAC]}
          </TableCell>
        );
      },
    },
    {
      title: t('Actions'),
      width: '10%',
      key: 'object',
      align: 'right' as 'right',
      render: (item: string, record: ContractDTM | {}) => {
        const contract = record as ContractDTM;

        return (
          <TableCell>
            <Dropdown
              overlay={(
                <Menu>
                  <Menu.Item onClick={() => openEditContractDrawer(contract)} disabled={!isAbleToEditContracts}>
                    {t('Edit')}
                  </Menu.Item>

                  <DeleteMenuItem onClick={() => handleRemove(contract.id)} disabled={!isAbleToEditContracts}>
                    {t('Remove')}
                  </DeleteMenuItem>
                </Menu>
              )}
            >
              <MenuIcon />
            </Dropdown>
          </TableCell>
        );
      },
    },
  ], [t, isAbleToEditContracts]);

  return (
    <TableWrap>
      <InfiniteScroll
        dataLength={contracts.length}
        hasMore={!wasLastPaginationRequestLast}
        next={loadMore}
        loader={isLoading ? <TableSkeleton /> : null}
        scrollableTarget="Wrapper"
      >
        <CustomTable
          columns={columns}
          dataSource={contracts}
          pagination={false}
        />
      </InfiniteScroll>
    </TableWrap>
  );
};
