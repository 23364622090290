import React, { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'antd/es/skeleton';

import { Button, SelectItemTitle } from 'app-wrapper/view/components';
import { PlusSvg } from 'app-wrapper/view/icons';

import { AdditionalRatesServiceDTM } from 'monetary/models/dtm/Quotas';

import { BookingAddAdditionalServicesSelectItem } from 'user-management/view/components';

import {
  CustomModalWrapperStyle,
  ShipmentAdditionalServiceHeader,
  ShipmentAdditionalServicesContent, ShipmentAdditionalServicesContentItem, ShipmentAdditionalServicesSectionButton, ShipmentAdditionalServicesWrap,
} from './ShipmentAdditionalServices.styled';

interface IShipmentAdditionalServicesComponentProps {
  servicesChecked?: AdditionalRatesServiceDTM[];
  isPending?: boolean;
  onDeletedCheckedService: (id?: string) => () => void;
  addServicesDrawer: () => void;
}

const ShipmentAdditionalServicesComponent: FC<IShipmentAdditionalServicesComponentProps> = ((props) => {
  const {
    servicesChecked,
    isPending,
    onDeletedCheckedService,
    addServicesDrawer,
  } = props;
  const { t } = useTranslation();

  return isPending ? (
    <Skeleton />
  ) : (
    <ShipmentAdditionalServicesWrap>
      <CustomModalWrapperStyle />

      <ShipmentAdditionalServicesContent>
        <ShipmentAdditionalServiceHeader>
          <SelectItemTitle>
            {t('Value Added Services')}
          </SelectItemTitle>
          <ShipmentAdditionalServicesSectionButton>
            <Button
              type="default"
              htmlType="submit"
              disabled={false}
              loading={false}
              onClick={addServicesDrawer}
            >
              <span className="ShipmentAdditionalServicesSectionButton">
                <PlusSvg />
              </span>
              {t('Add Service(s)')}
            </Button>
          </ShipmentAdditionalServicesSectionButton>
        </ShipmentAdditionalServiceHeader>

        {servicesChecked?.length ? (
          <ShipmentAdditionalServicesContentItem>

            {servicesChecked?.map((item) => (
              <BookingAddAdditionalServicesSelectItem
                key={item.customId}
                isHideCheckbox
                services={item}
                onDeletedService={onDeletedCheckedService}
              />
            ))}
          </ShipmentAdditionalServicesContentItem>
        ) : null}
      </ShipmentAdditionalServicesContent>
    </ShipmentAdditionalServicesWrap>
  );
});

const cachedShipmentAdditionalServicesComponent = memo(ShipmentAdditionalServicesComponent);

export { cachedShipmentAdditionalServicesComponent as ShipmentAdditionalServicesComponent };
