import React, { FC, memo } from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';

import { UC } from 'shipment-operations/controllers';
import { ShipmentTransportationOverviewComponent } from 'shipment-operations/view/pages/ShipmentTransportationOverview';

export const TransportationOceanBookingOverview: FC = memo(() => {
  const permissions = useSelector(R.selectors.shipment.getTransportationPermissions);
  const isTransportationOverviewLoading = useSelector(R.selectors.transportationOverview.getLoading);
  const isShipmentLoading = useSelector(R.selectors.shipment.getLoadingState);

  const isLoading = isShipmentLoading || isTransportationOverviewLoading;

  return (
    <ShipmentTransportationOverviewComponent
      tabAvailability={permissions.overviewTab}
      onNoAccess={UC.shipmentTransportationOverview.onNoAccessOceanBooking}
      isLoading={isLoading}
    />
  );
});
