import styled from 'styled-components';

import themesColors from 'app-wrapper/view/themes/themesColors';
import {
  InputPassword, Button,
} from 'app-wrapper/view/components';

export const PasswordResetNewPasswordWrapper = styled.div.attrs({
  'data-class': 'PasswordResetNewPasswordWrapper',
})`
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-around;
  align-items: center;

  width: 100%;
  height: 100%;
`;

export const PasswordResetNewPasswordForm = styled.div.attrs({
  'data-class': 'PasswordResetNewPasswordForm',
})`
  margin-top: auto;
  width: 360px;
  height: fit-content;
`;

export const LogoWrapper = styled.div.attrs({
  'data-class': 'PasswordResetNewPasswordButton',
})`
  margin: 0 auto 12px auto;
  width: fit-content;
`;

export const PasswordResetNewPasswordTitle = styled.p.attrs({
  'data-class': 'PasswordResetNewPasswordTitle',
})`
  margin: auto auto 24px auto;

  color: ${themesColors.secondaryDot45};
  font-size: 14px;
  line-height: 22px;
  text-align: center;
`;

export const PasswordResetNewPasswordMessageHeader = styled.p.attrs({
  'data-class': 'PasswordResetNewPasswordMessageHeader',
})`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
  
  margin: auto auto 12px auto;

  color: ${themesColors.neutralBranding10};
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: left;
  
  & > span {
    margin-bottom: 4px;
    height: 20px;
    line-height: 20px;
  }
  
  svg {
    margin-left: 4px;
    height: 10px;
    width: 10px;
    color: ${themesColors.secondaryDot45};
    
    &:hover {
      cursor: pointer;
    }
  }}
`;

export const ErrorWrapper = styled.span.attrs({
  'data-class': 'PasswordResetNewPasswordButton',
})`
  text-align: center;
`;

export const PasswordResetNewPasswordPasswordInput = styled(InputPassword).attrs((props: {
  'data-class'?: string,
}) => ({
  'data-class': props?.['data-class'],
}))`
  margin-bottom: 12px;
  color: ${themesColors.secondaryDot45};
  
  .ant-input {
    color: ${themesColors.secondaryDot45};
  }

  .ant-input::placeholder {
    color: ${themesColors.secondaryDot45};
  }
`;

export const PasswordResetNewPasswordButton = styled(Button).attrs({
  'data-class': 'PasswordResetNewPasswordButton',
})`
  width: 100%;
  margin-bottom: 12px;
`;

export const PasswordResetNewPasswordPreviousLinkWrapper = styled.div.attrs({
  'data-class': 'PasswordResetNewPasswordPreviousLinkWrapper',
})`
  text-align: center;

  a {
    color: ${themesColors.primaryBranding7};
  }
  
  svg {
    margin-right: 8px;
    height: 12px;
  }
`;

export const BottomMessage = styled.p.attrs({
  'data-class': 'BottomMessage',
})`
  margin: auto auto 20px auto;
  
  text-align: center;
  font-size: 14px;
  color: ${themesColors.secondaryDot45};
`;
