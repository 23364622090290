import React, { useState, FC, useCallback } from 'react';
import Dropdown from 'antd/es/dropdown';
import Menu from 'antd/es/menu';
import DownOutlined from '@ant-design/icons/DownOutlined';
import UpOutlined from '@ant-design/icons/UpOutlined';
import { Button } from 'app-wrapper/view/components';
import { useTranslation } from 'react-i18next';

interface ICreateInvoiceButtonProps {
  openCreateInvoice: () => void
  openCreateAPInvoice: () => void
}

const CreateInvoiceButton: FC<ICreateInvoiceButtonProps> = ({ openCreateInvoice, openCreateAPInvoice }) => {
  const { t } = useTranslation();
  const [menuVisible, setMenuVisible] = useState(false);

  const onCreateAP = useCallback(() => {
    openCreateAPInvoice();
    setMenuVisible(false);
  }, []);

  const onCreateAR = useCallback(() => {
    openCreateInvoice();
    setMenuVisible(false);
  }, []);

  return (
    <Dropdown
      arrow
      trigger={['click']}
      onVisibleChange={setMenuVisible}
      overlay={(
        <Menu>
          <Menu.Item
            onClick={onCreateAR}
            key={1}
          >
            {t('Create A/R Invoice')}
          </Menu.Item>
          <Menu.Item
            onClick={onCreateAP}
            key={2}
          >
            {t('Create A/P Invoice')}
          </Menu.Item>
        </Menu>
        )}
    >
      <Button type="primary" icon={menuVisible ? <UpOutlined style={{ fontSize: '8px' }} /> : <DownOutlined style={{ fontSize: '8px' }} />}>
        {t('invoicesActions')}
      </Button>
    </Dropdown>
  );
};

export { CreateInvoiceButton };
