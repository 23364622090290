import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Divider from 'antd/es/divider';

import { AdminPublicInfoDTM } from 'user-management/models/dtm';

import {
  Content,
  PartnerBlock,
  Title,
  ProviderName,
  HorizontalDivider,
} from './PartnersInformation.styled';

interface IPartnersInformationComponentProps {
  adminInfo: AdminPublicInfoDTM | null;
  relatedPartnerInfo: AdminPublicInfoDTM | null;
}

export const PartnersInformationComponent: FC<IPartnersInformationComponentProps> = ({ adminInfo, relatedPartnerInfo }) => {
  const { t } = useTranslation();

  if (!adminInfo || !adminInfo.address || !relatedPartnerInfo) {
    return null;
  }

  const { address } = adminInfo;

  return (
    <Content>
      <PartnerBlock>
        <Title>
          {t('Service Provider')}
        </Title>

        <ProviderName>
          {adminInfo.name}
        </ProviderName>

        <HorizontalDivider dashed />

        <Title>
          {address.address1}{address.address2 ? `, ${address.address2}` : ''} <br />
          {`${address.city}, ${address.state || ''} ${address.postalCode}, ${address.country || ''}`}{adminInfo?.registrationNumber ? `, ${adminInfo.registrationType} ${adminInfo.registrationNumber}` : ''}
        </Title>
      </PartnerBlock>

      <Divider
        style={{
          height: '100%',
        }}
        type="vertical"
        dashed
      />

      <PartnerBlock>
        <Title>
          {t('Partner of')}
          &nbsp;
          {adminInfo.name}
        </Title>

        <ProviderName>
          {relatedPartnerInfo.name}
        </ProviderName>

        <HorizontalDivider dashed />

        <Title>
          {t('The partner of the Service provider for your future orders will be')}
          &nbsp;
          {relatedPartnerInfo.name},
          &nbsp;
          {adminInfo.getIsAdminFromUS() ? t('EIN') : t('NIP')}
          &nbsp;
          {relatedPartnerInfo.registrationNumber}.
          &nbsp;
          {t('They will directly handle the transportation and related services.')}
        </Title>
      </PartnerBlock>
    </Content>
  );
};
