import { divFlex } from 'app-wrapper/view/themes/mixin';
import styled from 'styled-components';

export const StatusLight = styled.div.attrs((props: {
    colorIcon?: string,
  }) => ({
  colorIcon: props.colorIcon,
  'data-class': 'ShipmentCardSectionStatusIcon',
}))`
  ${divFlex}
  justify-content: center;
  align-items: center;

  width: 7px;
  height: 7px;
  border-radius: 50%;
  margin-right: 8px;
  ${({ colorIcon }) => colorIcon && `background-color: ${colorIcon}`};
`;
