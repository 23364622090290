import React, { FC } from 'react';

import {
  Overlay, ModalHeader, ModalContent, ModalFooter, ModalDialog,
} from './Modal.styled';

export interface IModalProps {
  title: string;
  open: boolean;
  onClose(): void;
  renderHeader?(): React.ReactElement;
  renderFooter(): React.ReactElement;
}

export const Modal: FC<IModalProps> = ({
  title,
  children,
  open,
  renderHeader,
  renderFooter, onClose,
}) => {
  if (!open) {
    return null;
  }

  return (
    <Overlay onClick={onClose}>
      <ModalDialog data-testid="modal">
        <ModalHeader data-testid="modalHeader">
          {renderHeader ? renderHeader() : title}
        </ModalHeader>
        <ModalContent data-testid="modalContent">
          {children}
        </ModalContent>
        <ModalFooter data-testid="modalFooter">
          {renderFooter()}
        </ModalFooter>
      </ModalDialog>
    </Overlay>
  );
};
