import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { UC } from 'user-management/controllers';
import { R } from 'user-management/repository';
import { ContactsContactPersonBlockComponent } from 'user-management/view/components';
import { ICompanyContactDTM } from 'user-management/models/dtm';

interface IAddressesAndContactsContactPersonItemContainerProps {
  largeInput: boolean
  personItem: ICompanyContactDTM
  contactIndex: number
  companyListStateIndex: string
  isFirst: boolean
  isLast: boolean
}

const AddressesAndContactsContactPersonItemContainer: FC<IAddressesAndContactsContactPersonItemContainerProps> = (props) => {
  const {
    largeInput,
    personItem,
    contactIndex,
    companyListStateIndex,
    isFirst,
    isLast,
  } = props;

  const errors = useSelector(R.selectors.contactBook.getContactBookUpdateErrorsContactPersonsById(personItem?.customId || ''));

  return (
    <ContactsContactPersonBlockComponent
      largeInput={largeInput}
      isFirst={isFirst}
      isLast={isLast}
      contactPerson={personItem}
      contactIndex={contactIndex}
      companyListStateIndex={companyListStateIndex}
      errors={errors}
      onChangeContactPerson={UC.contactBook.onChangeUpdateAACContactsContactPersonByIndex}
      onBlurContactPerson={UC.contactBook.onBlurUpdateAACContactsContactPersonByIndex}
      onFocusContactPerson={UC.contactBook.onFocusUpdateAACContactsContactPersonByIndex}
      onChangeAdditionalPhone={UC.contactBook.onChangeUpdateAACContactsAdditionalPhoneByIndex}
      onBlurAdditionalPhone={UC.contactBook.onBlurUpdateAACContactsAdditionalPhoneByIndex}
      onFocusAdditionalPhone={UC.contactBook.onFocusUpdateAACContactsAdditionalPhoneByIndex}
      onChangeEmail={UC.contactBook.onChangeUpdateAACContactsEmailByIndex}
      onBlurEmail={UC.contactBook.onBlurUpdateAACContactsEmailByIndex}
      onFocusEmail={UC.contactBook.onFocusUpdateAACContactsEmailByIndex}
      onChangePhone={UC.contactBook.onChangeUpdateAACContactsPhoneByIndex}
      onBlurPhone={UC.contactBook.onBlurUpdateAACContactsPhoneByIndex}
      onFocusPhone={UC.contactBook.onFocusUpdateAACContactsPhoneByIndex}
      addContactPersonItem={UC.contactBook.setUpdateAACAddCompanyListStateContactPersonByIndex}
      onRemoveContactPerson={UC.contactBook.setUpdateAACRemoveCompanyListStateContactPersonItem}
    />
  );
};

export { AddressesAndContactsContactPersonItemContainer as AddressesAndContactsContactPersonItem };
