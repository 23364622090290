import React, { FC, useMemo } from 'react';
import filter from 'lodash/fp/filter';

import { UC } from 'shipment-operations/controllers';
import { R } from 'shipment-operations/repository';
import { useAppSelector } from 'app-wrapper/hooks';

import { InvoicesChargeDTM } from 'shipment-operations/models/dtm';
import { CustomTableSelectable } from 'shipment-operations/view/components';
import { parseCharges } from 'shipment-operations/view/pages/ShipmentBillingInvoice/utils/prepareDataForTable';

interface ICustomTableProps {
  data: InvoicesChargeDTM[]
  fullTable: boolean
}

const CustomTable: FC<ICustomTableProps> = ({ data, fullTable }) => {
  const selectedCharges = useAppSelector(R.selectors.shipmentBillingInvoice.getSelectedItems);

  const originData = useMemo(() => {
    const filtered = filter((item) => item.charge.designation === 'ORIGIN', data);
    return parseCharges(filtered);
  }, [data]);

  const freightData = useMemo(() => {
    const filtered = filter((item) => item.charge.designation === 'FREIGHT', data);
    return parseCharges(filtered);
  }, [data]);

  const destinationData = useMemo(() => {
    const filtered = filter((item) => item.charge.designation === 'DESTINATION', data);
    return parseCharges(filtered);
  }, [data]);

  return (
    <CustomTableSelectable
      fullTable={fullTable}
      onItemSelect={UC.shipmentBillingInvoice.setSelectedItem}
      onSubTableSelect={UC.shipmentBillingInvoice.setGroupSelectedItems}
      selectedCharges={selectedCharges}
      originData={originData}
      freightData={freightData}
      destinationData={destinationData}
    />
  );
};

export { CustomTable };
