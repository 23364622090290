import React, { FC, useMemo } from 'react';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import Menu from 'antd/es/menu';

import {
  Dropdown,
  MenuItem,
  MenuRemoveItem,
  Icon,
} from './ActionMenu.styled';
import {
  PrintQuoteRFQ,
  PrintInvoice,
  PrintHBL,
  PrintNRAAgreementRFQ,
  SkypaceTermsAndConditions,
  BookingConfirmationPDF,
} from './components';

interface ActionMenuComponentProps {
  onDownload?: () => void;
  onDelete?: () => void;
  showBCPrintMenuItem?: boolean;
  showQuotePrintMenuItem?: boolean;
  showInvoicePrintMenuItem?: boolean;
  showHBLPrintMenuItem?: boolean;
  showNRAPrintMenuContractOwnerToAdminItem?: boolean;
  showNRAPrintMenuAdminToAdminItem?: boolean;
  showNRAPrintMenuItem?: boolean;
  showSkypaceTermsPrintMenuItem?: boolean;
  invoiceId?: number;
  isAPInvoiceType?: boolean;
}

export const ActionMenu: FC<ActionMenuComponentProps> = ({
  onDownload,
  onDelete,
  showBCPrintMenuItem,
  showQuotePrintMenuItem,
  showInvoicePrintMenuItem,
  showHBLPrintMenuItem,
  showNRAPrintMenuItem,
  showNRAPrintMenuAdminToAdminItem,
  showNRAPrintMenuContractOwnerToAdminItem,
  showSkypaceTermsPrintMenuItem,
  invoiceId,
  isAPInvoiceType,
}) => {
  const { shipmentId = '' } = useParams();
  const { t } = useTranslation();

  const menu = useMemo(() => (
    <Menu>
      {onDownload ? (
        <MenuItem onClick={onDownload}>
          {t('Download')}
        </MenuItem>
      ) : null}
      {onDelete ? (
        <MenuRemoveItem onClick={onDelete}>
          {t('Delete')}
        </MenuRemoveItem>
      ) : null}
      {showBCPrintMenuItem ? (
        <BookingConfirmationPDF />
      ) : null}
      {showQuotePrintMenuItem ? (
        <PrintQuoteRFQ />
      ) : null}
      {showInvoicePrintMenuItem ? (
        <PrintInvoice shipmentId={shipmentId} invoiceId={invoiceId} isAPInvoiceType={!!isAPInvoiceType} />
      ) : null}
      {showNRAPrintMenuItem ? (
        <PrintNRAAgreementRFQ shouldPrintCustomerToAdminNRA />
      ) : null}
      {showNRAPrintMenuContractOwnerToAdminItem ? (
        <PrintNRAAgreementRFQ shouldPrintContractOwnerToAdminNRA />
      ) : null}
      {showNRAPrintMenuAdminToAdminItem ? (
        <PrintNRAAgreementRFQ shouldPrintAdminToAdminNRA />
      ) : null}
      {showHBLPrintMenuItem ? (
        <PrintHBL />
      ) : null}
      {showSkypaceTermsPrintMenuItem ? (
        <SkypaceTermsAndConditions />
      ) : null}
    </Menu>
  ), [onDownload, onDelete, showBCPrintMenuItem, shipmentId, showQuotePrintMenuItem, invoiceId, showHBLPrintMenuItem, showSkypaceTermsPrintMenuItem, isAPInvoiceType]);

  return (
    <Dropdown
      placement="bottomRight"
      overlay={menu}
    >
      <Icon />
    </Dropdown>
  );
};
