import styled from 'styled-components';

export const GapHorizontalContainerStyled = styled.div.attrs({
  'data-class': 'GapHorizontalContainerStyled',
})`
  display: flex;
  flex-direction: row;
  gap: 8px;
  width: 100%;
  justify-content: flex-start;
`;
