import i18n from 'app-wrapper/i18n/i18n';

const t = i18n.t.bind(i18n);

export const ClausesList = [
  {
    name: t('Shipper\'s Load and Count'),
    id: 'C01',
  },
  {
    name: t('Shipper\'s Load, Stowage and Count'),
    id: 'C02',
  },
  {
    name: t('Laden on Board'),
    id: 'C03',
  },
  {
    name: t('Laden on Board Vessel'),
    id: 'C04',
  },
  {
    name: t('Vessel Not Responsible for Freezing'),
    id: 'C05',
  },
  {
    name: t('Container(s) Sealed by Shipper'),
    id: 'C06',
  },
  {
    name: t('On Deck at Shipper\'s Risk'),
    id: 'C07',
  },
  {
    name: t('Short-Shipped'),
    id: 'C08',
  },
  {
    name: t('Sea Waybill'),
    id: 'C09',
  },
  {
    name: t('This Shipment is Effected under a Sea Waybill'),
    id: 'C10',
  },
  {
    name: t('Memo Bill of Lading Only'),
    id: 'C11',
  },
  {
    name: t('Refrigerated Cargo'),
    id: 'C12',
  },
  {
    name: t('Cool Cargo'),
    id: 'C13',
  },
  {
    name: t('Freeze Cargo'),
    id: 'C14',
  },
  {
    name: t('Inland Transportation Arranged as Agents Only with such Arranged Transportation Being Solely with such Arranged Transportation Being Solely for Account and Risk of Cargo'),
    id: 'C15',
  },
  {
    name: t('Sea-Air Cargo'),
    id: 'C16',
  },
  {
    name: t('Freight Prepaid'),
    id: 'C17',
  },
  {
    name: t('Freight Collect'),
    id: 'C18',
  },
  {
    name: t('Freight as Agreed No Shipper\'s Export Declaration Required (Section 30.39)'),
    id: 'C19',
  },
  {
    name: t('No Shipper\'s Export Declaration Required (Section 30.39)'),
    id: 'C20',
  },
  {
    name: t('Carrier Reserves the Right to Place Container(s) in Heated Warehouse at a Set Cost'),
    id: 'C21',
  },
  {
    name: t('On Board Rail'),
    id: 'C22',
  },
  {
    name: t('On Board Truck'),
    id: 'C23',
  },
  {
    name: t('On Board Vessel'),
    id: 'C24',
  },
  {
    name: t('Received For Shipment'),
    id: 'C25',
  },
  {
    name: t('On Board Container'),
    id: 'C26',
  },
  {
    name: t('Emergency Response Statement'),
    id: 'C29',
  },
  {
    name: t('International Maritime Organization Certification'),
    id: 'C30',
  },
  {
    name: t('Destination Control Statements'),
    id: 'C32',
  },
  {
    name: t('Producing Country of Origin'),
    id: 'C33',
  },
  {
    name: t('Laden on Board Named Vessel'),
    id: 'C34',
  },
  {
    name: t('Age of Vessel'),
    id: 'C35',
  },
  {
    name: t('Certification Statements'),
    id: 'C38',
  },
  {
    name: t('Destination Country Final destination of the shipment'),
    id: 'C39',
  },
  {
    name: t('Title Passage Clause'),
    id: 'C40',
  },
  {
    name: t('Container Safety Act'),
    id: 'C41',
  },
  {
    name: t('General Agreement on Tariff and Trade (GATT)'),
    id: 'C45',
  },
  {
    name: t('Prior Damage Remarks'),
    id: 'C46',
  },
  {
    name: t('Caribbean Basin Initiative (CBI)'),
    id: 'CB',
  },
  {
    name: t('Custom'),
    id: 'CC',
  },
  {
    name: t('Container Packing Certificate'),
    id: 'CP',
  },
  {
    name: t('Disclaimer'),
    id: 'DC',
  },
  {
    name: t('Delivery Order Liability Clause'),
    id: 'DV',
  },
  {
    name: t('General System of Preferences (GSP) IS Israeli Free Trade Agreement'),
    id: 'GS',
  },
  {
    name: t('North American Free Trade Agreement (NAFTA)'),
    id: 'NF',
  },
];
