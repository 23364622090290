import React from 'react';
import { useSelector } from 'react-redux';

import { UC } from 'shipment-operations/controllers';
import { R } from 'shipment-operations/repository';

import { UploaderComponent } from './Uploader.component';

const UploaderContainer = () => {
  const document = useSelector(R.selectors.shipmentChanges.getDocument);

  return (
    <UploaderComponent
      setDocument={UC.shipmentChanges.setDocument}
      document={document ? [document] : []}
      downloadDocument={UC.shipmentChanges.downloadDocument}
    />
  );
};

export { UploaderContainer as Uploader };
