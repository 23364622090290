import { apiWorker } from 'app-wrapper/repository/utilsServices';
import { BaseController, controller } from 'proto/BaseController';
import {
  ShipmentDeclarationDTM,
  ShipmentDeclarationFileDTM,
  ShipmentHazmatContainerDTM,
  CargoDTM,
} from 'shipment-operations/models/dtm';
import { R } from 'shipment-operations/repository';
import { DocumentType } from 'shipment-operations/constants';

@controller
export class ShipmentDocumentsController extends BaseController {
  public fetchHasHazmatInfo = async (shipmentId: string) => {
    let cargos: CargoDTM[] = [];

    this.dispatch(R.actions.shipmentDocumentsHazmat.setIsHasHazmatPending(true));

    try {
      cargos = await R.services.cargo.getCargos(+shipmentId);
    } catch (e) {
      console.error('Controller error ShipmentDocumentsController: fetchHasHazmatInfo');

      return;
    }

    const hasHazmat = cargos.some(({ isHazmat }) => isHazmat);

    this.dispatch(R.actions.shipmentDocumentsHazmat.setHasHazmat(hasHazmat));
    this.dispatch(R.actions.shipmentDocumentsHazmat.setIsHasHazmatPending(false));
  };

  public getShipmentContainers = async (shipmentId?: string) => {
    if (!shipmentId) {
      return;
    }

    let containers: ShipmentHazmatContainerDTM[] = [];
    let declarations: Array<ShipmentDeclarationDTM | undefined> = [];

    this.dispatch(R.actions.shipmentDocumentsHazmat.setIsLoading(true));

    try {
      containers = await R.services.shipmentContainers.getShortContainers(shipmentId);
      declarations = await R.services.shipmentDeclaration.getDeclarationsForContainers(Number(shipmentId), containers.map(({ id }) => id));
    } catch (e) {
      this.dispatch(R.actions.shipmentDocumentsHazmat.setIsLoading(false));
      return;
    }

    containers = containers.map((container, index) => ({
      ...container,
      file: declarations[index] ? this.convertBEDocumentFileToFEHazmatFile(declarations[index] as ShipmentDeclarationDTM) : undefined,
    }));

    this.dispatch(R.actions.shipmentDocumentsHazmat.setContainers(containers));
    this.dispatch(R.actions.shipmentDocumentsHazmat.setIsLoading(false));
  }

  public setIsHazmatLoading = (isLoading: boolean) => {
    this.dispatch(R.actions.shipmentDocumentsHazmat.setIsLoading(isLoading));
  }

  public setHazmatContainerFile = (containerId: number, file?: ShipmentDeclarationFileDTM) => {
    this.dispatch(R.actions.shipmentDocumentsHazmat.setContainerFile({ containerId, file }));
  };

  public addHazmatDocument = async (shipmentId: number, containerId: number, file: ShipmentDeclarationFileDTM) => {
    let declarationFile: ShipmentDeclarationDTM | undefined;

    try {
      declarationFile = await R.services.shipmentDeclaration.createContainerDeclaration(shipmentId, containerId, DocumentType.IMO, file);
    } catch (e) {
      console.error('ADD HAZMAT DOCUMENT: CONTROLLER ERROR', e);
      return;
    }

    if (declarationFile) {
      this.setHazmatContainerFile(containerId, this.convertBEDocumentFileToFEHazmatFile(declarationFile));
    }
  };

  public removeHazmatDocument = async (shipmentId: number, containerId: number, file?: ShipmentDeclarationFileDTM) => {
    this.setHazmatContainerFile(containerId, file);

    try {
      await R.services.shipmentDeclaration.deleteContainerDeclaration(shipmentId, containerId);
    } catch (e) {
      console.error('DELETE HAZMAT DOCUMENT: CONTROLLER ERROR', e);
    }
  };

  public cleanup = () => {
    apiWorker.abortAllRequests();
  };

  private convertBEDocumentFileToFEHazmatFile = (rawFile: ShipmentDeclarationDTM) => (ShipmentDeclarationFileDTM.fromPlain({
    name: rawFile.document.name,
    uid: String(rawFile.document.id),
    fileId: String(rawFile.document.id),
    idIndex: 0,
  }));
}
