import React, { FC, useMemo, useCallback } from 'react';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import get from 'lodash/fp/get';
import EditOutlined from '@ant-design/icons/EditOutlined';

import { TableSkeleton } from 'app-wrapper/view/components';
import { OrganizationMemberDTM } from 'user-management/models/dtm';
import { EOrganizationMemberRole, OrganizationMemberRoleNames } from 'user-management/constants';
import { RouteNames } from 'app-wrapper/constants';

import {
  ClockCircleOutlinedEmail,
  ClockCircleOutlinedIconStyled,
  UserManagementContentEditButton,
  UserManagementContentTable,
  UserName,
  InvitedName,
} from './UserManagementContent.styled';

import { UserStatus } from './components';

interface IUserManagementContentComponentProps {
  usersList: OrganizationMemberDTM[]
  onEdit: (id: string) => void
  isLoading: boolean
  isAdmin?: boolean
  authEmail: string
}

export const UserManagementContentComponent: FC<IUserManagementContentComponentProps> = ({
  usersList,
  onEdit,
  isLoading,
  isAdmin,
  authEmail,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleGoToProfile = useCallback((email: string) => {
    navigate(RouteNames.USER_MANAGEMENT_PROFILE(email));
  }, [navigate]);

  const columns = useMemo(() => [
    {
      title: t('Name'),
      dataIndex: 'firstName',
      key: 'name',
      width: '30%',
      render: (value: undefined, render: {} | OrganizationMemberDTM) => {
        const model = render as OrganizationMemberDTM;

        return model.enabled !== null ? (
          <UserName onClick={() => handleGoToProfile(model.email)}>
            {model.getFullName()}
          </UserName>
        ) : (
          <>
            <ClockCircleOutlinedIconStyled />

            <InvitedName>
              {t('Name Pending')}
            </InvitedName>
          </>
        );
      },
    },
    {
      title: t('Email'),
      dataIndex: 'email',
      key: 'email',
      width: '30%',
      render: (value: string, render: {} | OrganizationMemberDTM) => {
        const model = render as OrganizationMemberDTM;

        return (<ClockCircleOutlinedEmail isInvited={model.enabled === null}>{value}</ClockCircleOutlinedEmail>);
      },
    },
    {
      title: t('Status'),
      dataIndex: 'enabled',
      key: 'status',
      width: '17%',
      render: (value?: boolean) => (<UserStatus enabled={value} />),
    },
    {
      title: t('Role'),
      dataIndex: 'role',
      key: 'role',
      width: '17%',
      render: (value: EOrganizationMemberRole) => (OrganizationMemberRoleNames[value]),
    },
    {
      title: '',
      key: 'actions',
      width: '6%',
      render: (item: string, record: {} | OrganizationMemberDTM) => {
        const id = get('id', record);
        const email = get('email', record);
        return (isAdmin && authEmail !== email) ? (<UserManagementContentEditButton onClick={() => onEdit(id)} icon={<EditOutlined />} type="text" />) : null;
      },
    },
  ], [isAdmin, handleGoToProfile]);

  if (isLoading) {
    return <TableSkeleton />;
  }

  return (
    <UserManagementContentTable
      columns={columns}
      dataSource={usersList}
      pagination={false}
    />
  );
};
