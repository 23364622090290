import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Tooltip from 'antd/es/tooltip';

import { ShippingPartyReference } from 'shipment-operations/models/dtm';

import {
  HeaderLayout,
  HeaderShippingParty,
  HeaderCompanyName,
  HeaderSection,
  ReferenceTag,
  WhiteReferenceTag,
} from './Header.styled';

interface IHeaderProps {
  shippingParty: string
  companyName?: string
  references: ShippingPartyReference[]
}

export const Header: FC<IHeaderProps> = ({
  shippingParty,
  companyName,
  references,
}) => {
  const { t } = useTranslation();

  return (
    <HeaderLayout>
      <HeaderSection>
        <HeaderShippingParty>{t(shippingParty)}</HeaderShippingParty>
        {companyName && companyName !== '-' && <HeaderCompanyName>{companyName}</HeaderCompanyName>}
      </HeaderSection>
      {references.length > 0 ? (
        <HeaderSection>
          {references[0].value ? (
            <ReferenceTag>
              {references[0].value}
            </ReferenceTag>
          ) : null}
          {references.length > 1 ? (
            <Tooltip title={references.map((reference) => reference.value).join(', ')}>
              <WhiteReferenceTag>
                +{references.length - 1}
              </WhiteReferenceTag>
            </Tooltip>
          ) : null}
        </HeaderSection>
      ) : null}
    </HeaderLayout>
  );
};
