import styled from 'styled-components';
import AntTable from 'antd/es/table';
import AntTag from 'antd/es/tag';
import Typography from 'antd/es/typography';

export const Table = styled(AntTable)`
  .ant-table-content table {
    table-layout: fixed !important;
  }

  .ant-table-thead > tr > th {
    background: white;
  }

  .ant-table-thead > tr > th:first-of-type {
    padding-left: 0;
  }

  .ant-table-tbody > tr > td:first-of-type {
    padding-left: 0px;
  }

  .ant-table-tbody > tr > td {
    padding-left: 8px;
    padding-top: 5.5px;
    padding-bottom: 7.5px;
  }

  .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    display: none;
  }

  .ant-table-thead .ant-table-cell {
    color: #73819B;
    font-size: 12px;
    line-height: 20px;
    font-weight: 400;
    padding: 8px;
  }

  .ant-table-tbody > tr:last-of-type > td {
    border-bottom: none;
  }

  .ant-table-cell:first-of-type {
    & > article {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;

export const ObjectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 44px;
`;

export const ObjectWrapperTitle = styled(Typography)`
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #73819B;
`;

export const ObjectWrapperSubtitle = styled(Typography)`
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #202C3C;
`;

export const RowWrapper = styled.div`
  margin-top: 1px;
  display: flex;
  flex-direction: row;
`;

export const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const KeyText = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #73819B;
`;

export const ValueText = styled.div`
  margin-left: 4px;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #202C3C;
`;

export const Tag = styled(AntTag)`
  background-color: #F4F5F8;
  border: 1px solid #EAEEF8;
  color: #73819B;
  font-weight: 400;
  font-size: 11px;
  line-height: 18px;
`;
