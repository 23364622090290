import { AuthActions, AuthReducer, AuthSelectors } from './Auth';
import { passwordResetEmailSendActions, passwordResetEmailSendReducer, passwordResetEmailSendSelectors } from './PasswordResetEmailSend';
import { passwordResetNewPasswordActions, passwordResetNewPasswordReducer, passwordResetNewPasswordSelectors } from './PasswordResetNewPassword';
import { signUpActions, signUpReducer, signUpSelectors } from './SignUp';
import { signUpSuccessActions, signUpSuccessReducer, signUpSuccessSelectors } from './SignUpSuccess';

export const actions = {
  auth: AuthActions,
  passwordResetEmailSend: passwordResetEmailSendActions,
  passwordResetNewPassword: passwordResetNewPasswordActions,
  signUp: signUpActions,
  signUpSuccess: signUpSuccessActions,
};

export const reducers = {
  auth: AuthReducer,
  passwordResetEmailSend: passwordResetEmailSendReducer,
  passwordResetNewPassword: passwordResetNewPasswordReducer,
  signUp: signUpReducer,
  signUpSuccess: signUpSuccessReducer,
};

export const selectors = {
  auth: AuthSelectors,
  passwordResetEmailSend: passwordResetEmailSendSelectors,
  passwordResetNewPassword: passwordResetNewPasswordSelectors,
  signUp: signUpSelectors,
  signUpSuccess: signUpSuccessSelectors,
};
