import styled from 'styled-components';

export const Wrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 34px;
  min-width: 34px;
  height: 18px;
  border-radius: 2px;
  border: 1px solid ${({ theme }) => theme.themesColors.neutralBranding3};
  background-color: white;
  color: ${({ theme }) => theme.themesColors.characterSecondaryDot45};
`;

export const Text = styled.span`
  font-size: 12px;
  line-height: 16px;
`;
