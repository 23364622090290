export const shipmentTransportationLocationAddressLine1Validate = (addressLine1?: string) => {
  if (!addressLine1) {
    return new Error('Address Line 1 is required');
  }

  return null;
};

export const shipmentTransportationLocationCityValidate = (city?: string) => {
  if (!city) {
    return new Error('City is required');
  }

  return null;
};

export const shipmentTransportationLocationStateValidate = (state?: string) => {
  if (!state) {
    return new Error('State is required');
  }

  return null;
};

export const shipmentTransportationLocationPostcodeValidate = (postcode?: string) => {
  if (!postcode) {
    return new Error('Postcode is required');
  }

  return null;
};

export const shipmentTransportationLocationCountryValidate = (country?: string) => {
  if (!country) {
    return new Error('Country is required');
  }

  return null;
};
