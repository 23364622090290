import React from 'react';
import { observer } from 'mobx-react-lite';

import { useMobxStore } from 'app-wrapper/mobxStores';
import { UC } from 'shipment-operations/controllers';
import { useAuthToken } from 'app-wrapper/hooks';

import { DocumentUploadComponent } from './DocumentUpload.component';

const DocumentUploadContainer = observer(() => {
  const { shipmentServices } = useMobxStore();
  const authToken = useAuthToken();

  return (
    <DocumentUploadComponent
      authToken={authToken}
      containerDocument={shipmentServices.state.documentToUpload}
      setContainerDocument={UC.shipmentServices.setDocumentToUpload}
      downloadDocument={UC.shipmentDocumentsAll.downloadDocument}
      addDocumentToDeleteId={UC.shipmentServices.addDocumentToDeleteId}
      documentType={shipmentServices.state.documentToUploadType}
    />
  );
});

export { DocumentUploadContainer as DocumentUpload };
