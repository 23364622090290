import { createSelector } from 'reselect';
import { RootState } from 'app-wrapper/store';

const localState = (state: RootState) => state.userManagement;

const getUserList = createSelector(
  localState,
  (state) => state.usersList,
);

const getIsLoading = createSelector(
  localState,
  (state) => state.isLoading,
);

const getSelectedMemberRole = createSelector(
  localState,
  (state) => state.selectedMemberRole,
);

const getSelectedMemberId = createSelector(
  localState,
  (state) => state.selectedMemberId,
);

const getUpdateLoading = createSelector(
  localState,
  (state) => state.updateLoading,
);

const getUpdateError = createSelector(
  localState,
  (state) => state.updateError,
);

const getUpdateSuccess = createSelector(
  localState,
  (state) => state.updateSuccess,
);

const getDeleteLoading = createSelector(
  localState,
  (state) => state.deleteLoading,
);

const getDeleteError = createSelector(
  localState,
  (state) => state.deleteError,
);

const getDeleteSuccess = createSelector(
  localState,
  (state) => state.deleteSuccess,
);

const getUserProfile = createSelector(
  localState,
  (state) => state.userProfile,
);

const getUserOrgId = createSelector(
  localState,
  (state) => state.userOrgId,
);

const getTeams = createSelector(
  localState,
  (state) => state.teams,
);

const getAvailableTeams = createSelector(
  localState,
  (state) => state.availableTeams,
);

const getDepartments = createSelector(
  localState,
  (state) => state.departments,
);

const getAvailableDepertments = createSelector(
  localState,
  (state) => state.availableDepartments,
);

const getDepartmentsError = createSelector(
  localState,
  (state) => state.departmentsError,
);

const getJobTitle = createSelector(
  localState,
  (state) => state.jobTitle,
);

export const userManagementSelectors = {
  getUserList,
  getIsLoading,
  getSelectedMemberRole,
  getSelectedMemberId,
  getUpdateLoading,
  getUpdateError,
  getUpdateSuccess,
  getDeleteLoading,
  getDeleteError,
  getDeleteSuccess,
  getUserProfile,
  getUserOrgId,
  getTeams,
  getAvailableTeams,
  getDepartments,
  getAvailableDepertments,
  getDepartmentsError,
  getJobTitle,
};
