import {
  IsEnum, IsString, IsDefined, IsNumber, IsOptional, ValidateNested,
} from 'class-validator';
import { Type } from 'class-transformer';
import { BaseDTM } from 'proto/BaseDTM';

import { DateDtm } from 'app-wrapper/models/dtm';
import { DocumentType } from 'shipment-operations/constants';

export interface IDocumentDTM {
  id: number;
  name: string;
  type: `${DocumentType}`;
  createdAt?: DateDtm;
}

export class DocumentDTM extends BaseDTM<IDocumentDTM> {
  @IsDefined()
  @IsNumber()
  id: number

  @IsDefined()
  @IsString()
  name: string

  @IsDefined()
  @IsEnum(DocumentType)
  type: DocumentType

  @IsOptional()
  @ValidateNested()
  @Type(() => DateDtm)
  createdAt?: DateDtm;
}
