import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { R } from 'monetary/repository';

import { REQUEST_STATUS } from 'app-wrapper/constants';

import { FreightQuoteFooterComponent } from './FreightQuoteFooter.component';

export interface IFreightQuoteFooterProps {
  isFormCollapsed?: boolean
  isReuseRequest?: boolean
  hideButton?: boolean
}

export const FreightQuoteFooter: FC<IFreightQuoteFooterProps> = ({
  isFormCollapsed,
  isReuseRequest,
  hideButton,
}) => {
  // New
  const {
    disabledSubmit, isAllFieldDisabled, status, quotas,
  } = useSelector(R.selectors.freightQuote.getFreightQuoteCurrent);
  const getCountContainersTotal = useSelector(R.selectors.freightQuote.getCountContainersTotal);
  const getCountContainersWeightTotal = useSelector(R.selectors.freightQuote.getCountContainersWeightTotal);
  const getCountContainersVolumeTotal = useSelector(R.selectors.freightQuote.getCountContainersVolumeTotal);

  const quotasCreateFailedMessage = quotas?.quotasOption?.quotasCreateFailedMessage;
  const isDisabledSubmit = disabledSubmit || isAllFieldDisabled || status === REQUEST_STATUS.pending;
  const isLoadSubmit = status === REQUEST_STATUS.pending;

  return (
    <FreightQuoteFooterComponent
      disabledSubmit={isDisabledSubmit}
      isLoadSubmit={isLoadSubmit}
      quotasCreateFailedMessage={quotasCreateFailedMessage}
      isFormCollapsed={isFormCollapsed}
      isReuseRequest={isReuseRequest}
      hideButton={hideButton}
      countsContainersTotal={getCountContainersTotal}
      weightTotal={getCountContainersWeightTotal}
      volumeTotal={getCountContainersVolumeTotal}
    />
  );
};
