import React, { FC, useEffect } from 'react';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import Spin from 'antd/es/spin';

import { hasReadAccess } from 'app-wrapper/utils';
import { WorkgroupDTM } from 'shipment-operations/models/dtm';
import { SpinnerWrap } from 'app-wrapper/view/guideline';
import { PermissionAttributePolicy } from 'shipment-operations/constants';

import { Workgroup, AddWorkgroupMember } from './components';
import {
  Page,
  Title,
} from './ShipmentPeople.styled';

interface IShipmentPeopleComponentProps {
  isLoading?: boolean;
  init: (shipmentId: string) => void;
  workgroups: WorkgroupDTM[];
  openAddMemberDrawer: (workgroup?: WorkgroupDTM) => void;
  removeMember: (memberId: number) => void;
  currentOrganizationId?: number;
  companyNameVisibility?: PermissionAttributePolicy;
}

export const ShipmentPeopleComponent: FC<IShipmentPeopleComponentProps> = ({
  isLoading,
  init,
  workgroups,
  openAddMemberDrawer,
  removeMember,
  currentOrganizationId,
  companyNameVisibility,
}) => {
  const { shipmentId = '' } = useParams();
  const { t } = useTranslation();

  const isAbleToViewCompanyName = hasReadAccess(companyNameVisibility);

  useEffect(() => {
    init(shipmentId);
  }, []);

  return (
    <Page>
      <AddWorkgroupMember />

      {isLoading ? (
        <SpinnerWrap>
          <Spin size="large" />
        </SpinnerWrap>
      ) : (
        <>
          <Title>
            {t('People')}
          </Title>

          {workgroups.map((workgroup) => (
            <Workgroup
              key={`ShipmentPeopleComponent_${workgroup.type}`}
              workgroup={workgroup}
              onRemove={removeMember}
              openAddMemberDrawer={openAddMemberDrawer}
              currentOrganizationId={currentOrganizationId}
              isAbleToViewCompanyName={isAbleToViewCompanyName}
            />
          ))}
        </>
      )}
    </Page>
  );
};
