import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ContainerTypeWithQTYDTM } from 'shipment-operations/models/dtm';
import { commonContainersTypesLong } from 'shipment-operations/constants';

import {
  Wrapper,
  Header,
  HeaderTitle,
  ContainerRow,
  InputStyled,
} from './ContainersTable.styled';

interface ContainersTableComponentProps {
  containers: ContainerTypeWithQTYDTM[];
}

export const ContainersTableComponent: FC<ContainersTableComponentProps> = ({ containers }) => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <Header>
        <HeaderTitle>
          {t('Containers *')}
        </HeaderTitle>
        <HeaderTitle>
          {t('QTY *')}
        </HeaderTitle>
      </Header>

      {containers.map((container) => (
        <ContainerRow key={`ContainersTableComponent_${container.quantity}`}>
          <InputStyled value={commonContainersTypesLong[container.type]} disabled />
          <InputStyled value={container.quantity} disabled />
        </ContainerRow>
      ))}
    </Wrapper>
  );
};
