import moment from 'moment';

import { apiWorker } from 'app-wrapper/repository/utilsServices';
import { DateDtm } from 'app-wrapper/models/dtm';

import {
  IPutShipmentRouteRequest,
  IPutShipmentRouteResponse,
  IPostShipmentRouteRequest,
  IPostShipmentRouteResponse,
  TGetShipmentRoutesListResponse,
} from 'shipment-operations/models/contracts';
import { ShipmentRouteDTM } from 'shipment-operations/models/dtm';
import { ShipmentRoutesType } from 'shipment-operations/constants';

export class ShipmentRoutesService {
  public getList = async (shipmentId: string) => {
    let list: ShipmentRouteDTM[] = [];

    try {
      const rawResponse = await apiWorker.requestGet<TGetShipmentRoutesListResponse>(this.formatUrl(shipmentId));

      if (rawResponse.data.length > 0) {
        const parsedResponse = rawResponse.data.map((item) => {
          const parsedItem = ShipmentRouteDTM.fromPlain({
            id: `${item.id}`,
            type: item.type as ShipmentRoutesType,
            terminalName: item.terminalName,
            passCode: item.passCode,
            address1: item.address1,
            address2: item.address2,
            address3: item.address3,
            address4: item.address4,
            city: item.city,
            state: item.state,
            postcode: item.postcode,
            country: item.country,
            releaseDate: item.time ? DateDtm.fromPlain({
              date: item.time,
              offset: moment.parseZone(item.time).utcOffset(),
            }) : undefined,
          });
          if (!parsedItem.isValid()) {
            console.error('Data from API does not match with contract');
          }
          return parsedItem;
        });
        list = parsedResponse.filter((el) => el !== null) as ShipmentRouteDTM[];
      }
    } catch (e) {
      throw new Error('Shipment routes list getting error');
    }

    return list;
  }

  public putRoute = async (shipmentId: string, routeData: ShipmentRouteDTM) => {
    let result: ShipmentRouteDTM | null = null;

    const data: IPutShipmentRouteRequest = {
      id: routeData.id,
      type: routeData.type,
      terminalName: routeData.terminalName,
      passCode: routeData.passCode,
      address1: routeData.address1,
      address2: routeData.address2,
      address3: routeData.address3,
      address4: routeData.address4,
      city: routeData.city,
      state: routeData.state,
      postcode: routeData.postcode,
      country: routeData.country,
      time: routeData.releaseDate ? routeData.releaseDate.getDateAsMomentWithOffset().format() : undefined,
    };

    try {
      const rawResponse = await apiWorker.requestPut<IPutShipmentRouteResponse>(`${this.formatUrl(shipmentId)}/${routeData.id}`, data);
      const response = rawResponse.data;

      result = ShipmentRouteDTM.fromPlain({
        id: `${response.id}`,
        type: response.type as ShipmentRoutesType,
        terminalName: response.terminalName,
        passCode: response.passCode,
        address1: response.address1,
        address2: response.address2,
        address3: response.address3,
        address4: response.address4,
        city: response.city,
        state: response.state,
        postcode: response.postcode,
        country: response.country,
        releaseDate: response.time ? DateDtm.fromPlain({
          date: response.time,
          offset: moment.parseZone(response.time).utcOffset(),
        }) : undefined,
      });
    } catch (e) {
      throw new Error('Shipment routes list getting error');
    }

    return result;
  }

  public postRoute = async (shipmentId: string, routeData: ShipmentRouteDTM) => {
    let result: ShipmentRouteDTM | null = null;

    const data: IPostShipmentRouteRequest = {
      id: routeData.id,
      type: routeData.type,
      terminalName: routeData.terminalName,
      passCode: routeData.passCode,
      address1: routeData.address1,
      address2: routeData.address2,
      address3: routeData.address3,
      address4: routeData.address4,
      city: routeData.city,
      state: routeData.state,
      postcode: routeData.postcode,
      country: routeData.country,
      time: routeData.releaseDate ? routeData.releaseDate.getDateAsMomentWithOffset().format() : undefined,
    };

    try {
      const rawResponse = await apiWorker.requestPost<IPostShipmentRouteResponse>(this.formatUrl(shipmentId), data);
      const response = rawResponse.data;

      result = ShipmentRouteDTM.fromPlain({
        id: `${response.id}`,
        type: response.type as ShipmentRoutesType,
        terminalName: response.terminalName,
        passCode: response.passCode,
        address1: response.address1,
        address2: response.address2,
        address3: response.address3,
        address4: response.address4,
        city: response.city,
        state: response.state,
        postcode: response.postcode,
        country: response.country,
        releaseDate: response.time ? DateDtm.fromPlain({
          date: response.time,
          offset: moment.parseZone(response.time).utcOffset(),
        }) : undefined,
      });
    } catch (e) {
      throw new Error('Shipment routes list getting error');
    }

    return result;
  }

  private formatUrl = (shipmentId: string) => (
    `/shipment-service/api/v1/shipments/${shipmentId}/locations`
  )

  private getShiftedDate = (date?: string | null) => {
    if (!date) {
      return undefined;
    }

    let docTz = date?.slice(19, 25);
    if (!docTz) {
      docTz = '0';
    }

    const docTime = moment(date);

    return date ? docTime.utcOffset(docTz) : undefined;
  }
}
