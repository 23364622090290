import React, {
  FC, useState, useCallback,
} from 'react';
import { useTranslation } from 'react-i18next';

import { CustomModal, Container } from 'shipment-operations/view/components';
import {
  StepsTitle, Header, FirstStep, SecondStep,
} from 'shipment-operations/view/pages/CreateCreditNote/components';

interface ICreditNote {
  onClose: () => void
  showFooter: boolean
  setListUpdatedItems: () => void
  clearUpdatedItems: () => void
}

const CreateCreditNoteComponent: FC<ICreditNote> = ({
  onClose, showFooter, setListUpdatedItems, clearUpdatedItems,
}) => {
  const { t } = useTranslation();
  const [isSecondStep, setIsSecondStep] = useState(false);

  const moveToSecondStep = useCallback(() => {
    setIsSecondStep(true);
    setListUpdatedItems();
  }, []);

  const moveToFirstStep = useCallback(() => {
    setIsSecondStep(false);
    clearUpdatedItems();
  }, []);

  return (
    <CustomModal onClose={onClose} title={t('createCreditNote')} customId="creditNoteWrapper">
      <StepsTitle isSecondStep={isSecondStep} />
      <Container mBottom="20px">
        <Header />
      </Container>
      {isSecondStep ? (
        <SecondStep onClickBack={moveToFirstStep} onCloseCreditNote={onClose} />
      ) : (
        <FirstStep showFooter={showFooter} onClickNext={moveToSecondStep} />
      )}
    </CustomModal>
  );
};

export { CreateCreditNoteComponent };
