import { EShippingPartyTypes } from './ShippingParty.enum';

export const RequiredShippingParties = [
  EShippingPartyTypes.SHIPPER,
  EShippingPartyTypes.CONSIGNEE,
  EShippingPartyTypes.NOTIFY_PARTY,
  EShippingPartyTypes.HOUSE_SHIPPER,
  EShippingPartyTypes.HOUSE_CONSIGNEE,
  EShippingPartyTypes.HOUSE_NOTIFY_PARTY,
];
