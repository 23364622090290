import * as yup from 'yup';
import i18n from 'i18next';

yup.addMethod(yup.string, 'maxLength', function maxLength(max) {
  return this.test(
    'maxLength',
    `${i18n.t('Validations.string.maxLength')} ${max}`,
    (value) => !!value?.length && value.length <= max,
  );
});

declare module 'yup' {
  interface StringSchema {
    maxLength(max?: number): StringSchema;
  }
}
