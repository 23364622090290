import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';
import { UC } from 'shipment-operations/controllers';

import { ShipmentContainersListComponent } from './ShipmentContainersList.component';

const ShipmentContainersListContainer: FC = () => {
  const isLoading = useSelector(R.selectors.shipmentContainers.getLoadingState);
  const containers = useSelector(R.selectors.shipmentContainers.getLists);
  const cargos = useSelector(R.selectors.shipmentContainers.getCargoList);
  const selectedContainerId = useSelector(R.selectors.shipmentContainers.getSelectedContainerId);
  const selectedContainerType = useSelector(R.selectors.shipmentContainers.getSelectedContainersType);
  const commonRef = useSelector(R.selectors.shipmentContainers.getCommonChangedReferencesForSelectedContainer);
  const commonPackagesNumber = useSelector(R.selectors.shipmentContainers.getCommonChangedPackagesNumber);
  const commonWeight = useSelector(R.selectors.shipmentContainers.getCommonChangedWeight);
  const commonVolume = useSelector(R.selectors.shipmentContainers.getCommonChangedVolume);
  const iMODeclarationsList = useSelector(R.selectors.shipmentContainers.getIMODeclarationsList);

  return (
    <ShipmentContainersListComponent
      isLoading={isLoading}
      containers={containers}
      cargos={cargos}
      selectedContainerCommonReferences={commonRef}
      selectedContainerCommonPackagesNumber={commonPackagesNumber}
      selectedContainerCommonWeight={commonWeight}
      selectedContainerCommonVolume={commonVolume}
      selectedContainerType={selectedContainerType}
      selectedContainerId={selectedContainerId}
      selectContainer={UC.shipmentContainers.selectContainer}
      listUploadHandler={UC.shipmentContainers.firstDataUpload}
      iMODeclarationsList={iMODeclarationsList}
    />
  );
};

export {
  ShipmentContainersListContainer as ShipmentContainersList,
};
