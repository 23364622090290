import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { MissingTextValue } from 'app-wrapper/view/guideline';
import { AdminPublicInfoDTM } from 'user-management/models/dtm';
import { UK_BANK_INFORMATION, US_BANK_INFORMATION } from 'shipment-operations/constants';

import {
  BankDetailsWrap,
  BankDetails,
  Title,
  Items,
  Item,
  ItemTitle,
  ItemText,
  ReferenceWrap,
  ReferenceBold,
} from './InvoicePDFBankDetails.styled';

interface InvoicePDFGeneralInfoComponentProps {
  invoiceNumber: string;
  relatedAdmin: AdminPublicInfoDTM | null;
}

export const InvoicePDFBankDetailsComponent: FC<InvoicePDFGeneralInfoComponentProps> = ({ invoiceNumber, relatedAdmin }) => {
  const { t } = useTranslation();

  return (
    <BankDetailsWrap>
      <ReferenceWrap>
        {t('Please specify this reference')}
        &nbsp;
        <ReferenceBold>
          {invoiceNumber}
        </ReferenceBold>
        &nbsp;
        {t('when paying at your bank to track your payment in our system.')}
      </ReferenceWrap>

      <BankDetails>
        <Title>
          {t('Bank Details')}
        </Title>

        <Items>
          <Item>
            <ItemTitle>
              {t('Account Number')}
            </ItemTitle>

            <ItemText>
              {!relatedAdmin ? (
                <MissingTextValue />
              ) : (
                <>
                  {relatedAdmin.getIsAdminFromUS() ? US_BANK_INFORMATION.accountNumber : UK_BANK_INFORMATION.accountNumber}
                </>
              )}
            </ItemText>
          </Item>

          <Item>
            <ItemTitle>
              {t('Routing Number')}
            </ItemTitle>

            <ItemText>
              {!relatedAdmin ? (
                <MissingTextValue />
              ) : (
                <>
                  {relatedAdmin.getIsAdminFromUS() ? US_BANK_INFORMATION.routingNumber : UK_BANK_INFORMATION.routingNumber}
                </>
              )}
            </ItemText>
          </Item>

          <Item>
            <ItemTitle>
              {t('SWIFT/BIC')}
            </ItemTitle>

            <ItemText>
              {!relatedAdmin ? (
                <MissingTextValue />
              ) : (
                <>
                  {relatedAdmin.getIsAdminFromUS() ? US_BANK_INFORMATION.swiftOrBIC : UK_BANK_INFORMATION.swiftOrBIC}
                </>
              )}
            </ItemText>
          </Item>

          <Item>
            <ItemTitle>
              {t('Beneficiary Bank')}
            </ItemTitle>

            <ItemText>
              {!relatedAdmin ? (
                <MissingTextValue />
              ) : (
                <>
                  {relatedAdmin.getIsAdminFromUS() ? US_BANK_INFORMATION.beneficiaryBank : UK_BANK_INFORMATION.beneficiaryBank}
                </>
              )}
            </ItemText>
          </Item>
        </Items>
      </BankDetails>
    </BankDetailsWrap>
  );
};
