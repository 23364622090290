import styled from 'styled-components';
import { colors } from 'app-wrapper/view/themes/themesColors';

import UnstyledTag from 'antd/es/tag';

export const Tag = styled(UnstyledTag)`
  width: 100%;
  height: 100% !important;
  background: ${colors.gray35};
  border-color: ${colors.purple15};
`;
