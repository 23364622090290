import React from 'react';
import { useSelector } from 'react-redux';

import { R } from 'user-management/repository';
import { DocumentType } from 'shipment-operations/constants';

import { NewOrganizationDocumentUploadComponent } from './NewOrganizationDocumentUpload.component';

const NewOrganizationDocumentUploadContainer = () => {
  const type = useSelector(R.selectors.newOrganizationDocuments.getDocumentToUploadType) as DocumentType;
  const isLoading = useSelector(R.selectors.newOrganizationDocuments.getIsUploadLoading);

  return (
    <NewOrganizationDocumentUploadComponent
      documentType={type}
      isLoading={isLoading}
    />
  );
};

export { NewOrganizationDocumentUploadContainer as NewOrganizationDocumentUpload };
