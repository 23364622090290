import React, { FC, HTMLAttributes } from 'react';

import { colors } from 'app-wrapper/view/themes/themesColors';

interface IIconProps extends HTMLAttributes<SVGElement> {
  color?: string
  height?: number | string
  width?: number | string
}

export const ShipIcon: FC<IIconProps> = ({
  color = colors.purple30,
  width = 20,
  height = 17,
  ...other
}) => (
  <svg width={width} height={height} viewBox="0 0 20 17" {...other}>
    <path d="M15.125 12.0972L17.4688 10.1285C18.3438 9.37848 18.0625 7.94098 16.9688 7.56598L15.25 6.97223V2.97223C15.25 2.56598 14.9063 2.22223 14.5 2.22223H13V0.972229C13 0.565979 12.6563 0.222229 12.25 0.222229H7.75C7.3125 0.222229 7 0.565979 7 0.972229V2.22223H5.5C5.0625 2.22223 4.75 2.56598 4.75 2.97223V6.97223L3 7.56598C1.90625 7.94098 1.625 9.37848 2.5 10.1285L4.84375 12.0972C4.09375 13.691 2.625 14.7222 0.375 14.7222C0.15625 14.7222 0 14.9097 0 15.0972V15.8472C0 16.066 0.15625 16.2222 0.375 16.2222C2.28125 16.2222 3.9375 15.691 5.28125 14.1597C5.46875 15.3472 6.5 16.2222 7.75 16.2222H12.25C13.4688 16.2222 14.5 15.3472 14.6875 14.1597C16.0312 15.7222 17.6875 16.2222 19.625 16.2222C19.8125 16.2222 20 16.066 20 15.8472V15.0972C20 14.9097 19.8125 14.7222 19.625 14.7222C17.375 14.7222 15.875 13.7222 15.125 12.0972ZM8.25 1.47223H11.75V2.22223H8.25V1.47223ZM6.25 3.72223H13.75V6.44098L10.4688 5.31598C10.3438 5.28473 10.125 5.22223 9.96875 5.22223C9.84375 5.22223 9.625 5.28473 9.5 5.31598L6.25 6.44098V3.72223ZM13.25 13.7222C13.25 14.2847 12.7813 14.7222 12.25 14.7222H7.75C7.1875 14.7222 6.75 14.2847 6.75 13.7222V11.7222L3.5 8.97223L10 6.72223L16.5 8.97223L13.25 11.7222V13.7222Z" fill={color} />
  </svg>
);
