import React, { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { R } from 'shipment-operations/repository';
import { EOrganizationMemberRole } from 'user-management/constants';
import { R as userManagementR } from 'user-management/repository';

import { SBLCarrierInformationComponent } from './SBLCarrierInformation.component';

interface ISBLCarrierInformationContainerProps {}

const SBLCarrierInformationContainer: FC<ISBLCarrierInformationContainerProps> = () => {
  const { t } = useTranslation();
  const organization = useSelector(userManagementR.selectors.userOrganizationData.getUserOrganization);
  const isHBLPage = useSelector(R.selectors.billOfLadingCommon.getIsHBLPage);
  const shipment = useSelector(R.selectors.shipment.getShipment);
  const { contractNumber } = useSelector(R.selectors.billOfLadingCommon.getBookingInformation);
  const oceanCarrierName = useSelector(R.selectors.billOfLadingCommon.getCarrierBookingOffice);
  const bookingAgentName = useSelector(R.selectors.billOfLadingCommon.getBookingAgentOffice);
  const currentOrgRelatedAdmin = useSelector(R.selectors.billOfLadingCommon.getCurrentOrgRelatedAdminPublicInfo);

  const bookingOffice = useMemo(() => (isHBLPage ? currentOrgRelatedAdmin?.getOnBehalfAdminName(shipment?.isUsShipmentOriginOrDestination()) : oceanCarrierName), [shipment, isHBLPage, oceanCarrierName, bookingAgentName, currentOrgRelatedAdmin]);
  const isCustomerOrg = useMemo(() => !!(organization && organization.role === EOrganizationMemberRole.CUSTOMER), [organization]);
  const contractNumberItem = useMemo(() => (isHBLPage || isCustomerOrg ? undefined : contractNumber), [isHBLPage, contractNumber, isCustomerOrg]);

  const items = useMemo(() => [
    {
      title: t('Booking Office'),
      subtitle: bookingOffice || undefined,
    },
    {
      title: t('Booking Reference'),
      subtitle: shipment?.carrierReferenceNumber || undefined,
    },
    {
      title: t('Contract Reference'),
      subtitle: contractNumberItem || undefined,
    },
  ], [t, bookingOffice, contractNumberItem, shipment, isCustomerOrg]);

  return <SBLCarrierInformationComponent items={items} />;
};

export { SBLCarrierInformationContainer as SBLCarrierInformation };
