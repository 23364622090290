import React, { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';

import { InvoicePDFGeneralInfoComponent } from './InvoicePDFGeneralInfo.component';

interface InvoicePDFGeneralInfoContainerProps {
  isAPInvoiceType: boolean;
}

const InvoicePDFGeneralInfoContainer: FC<InvoicePDFGeneralInfoContainerProps> = ({ isAPInvoiceType }) => {
  const companyNameFrom = useSelector(R.selectors.shareInvoice.getBillFromCompanyName);
  const addressFrom = useSelector(R.selectors.shareInvoice.getBillFromAddress);
  const addressLineFrom = useSelector(R.selectors.shareInvoice.getBillFromAddressLine);
  const companyNameTo = useSelector(R.selectors.shareInvoice.getBillToCompanyName);
  const addressTo = useSelector(R.selectors.shareInvoice.getBillToAddress);
  const addressLineTo = useSelector(R.selectors.shareInvoice.getBillToAddressLine);
  const isVATVisible = useSelector(R.selectors.shareInvoice.getIsVATVisible);

  const invoiceNumber = useSelector(R.selectors.shareInvoice.getInvoiceNumber);
  const createdAt = useSelector(R.selectors.shareInvoice.getInvoiceCreatedAt);
  const dueDate = useSelector(R.selectors.shareInvoice.getInvoiceDueDate);
  const billedAmount = useSelector(R.selectors.shareInvoice.getInvoiceBilledAmount);

  const isTermVisible = useSelector(R.selectors.shareInvoice.getIsTermVisible);
  const customerCreditTerm = useSelector(R.selectors.shareInvoice.getCustomerCreditTerm);

  const companyName = useMemo(() => (isAPInvoiceType ? companyNameFrom : companyNameTo), [companyNameFrom, companyNameTo, isAPInvoiceType]);
  const address = useMemo(() => (isAPInvoiceType ? addressFrom : addressTo), [addressFrom, addressTo, isAPInvoiceType]);
  const addressLine = useMemo(() => (isAPInvoiceType ? addressLineFrom : addressLineTo), [addressLineFrom, addressLineTo, isAPInvoiceType]);

  return (
    <InvoicePDFGeneralInfoComponent
      invoiceNumber={invoiceNumber}
      companyName={companyName}
      address={address}
      addressLine={addressLine}
      createdAt={createdAt}
      dueDate={dueDate}
      total={billedAmount}
      isTermVisible={isTermVisible}
      customerCreditTerm={customerCreditTerm}
      isVATVisible={isVATVisible}
    />
  );
};

export { InvoicePDFGeneralInfoContainer as InvoicePDFGeneralInfo };
