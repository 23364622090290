import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import isEmpty from 'lodash/fp/isEmpty';

import { CustomTable, TotalFooter } from 'shipment-operations/view/pages/ShipmentTransportationCharges/components';
import { ChargeViewDtm } from 'shipment-operations/models/dtm';
import { InnerContainer } from 'shipment-operations/view/components';
import { TotalWrapper } from 'shipment-operations/view/pages/ShipmentTransportationCharges/ShipmentTransportationCharges.styled';

interface IFeesComponentProps {
  data: ChargeViewDtm[]
  totalData: {
    apTotalCost: number
    arTotalCost: number
    profit: number
    key: number
  }
}

const FeesComponent: FC<IFeesComponentProps> = ({ data, totalData }) => {
  const { t } = useTranslation();

  if (isEmpty(data)) {
    return null;
  }

  return (
    <>
      <InnerContainer>
        <CustomTable data={data} subTitle={t('fees')} />
      </InnerContainer>
      <TotalWrapper>
        <TotalFooter dataSource={[totalData]} />
      </TotalWrapper>
    </>
  );
};

export { FeesComponent };
