import styled from 'styled-components';
import Spin from 'antd/es/spin';

export const Layout = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;

  background-color: rgba(249, 249, 251, 1);
`;

export const Header = styled.div`
  width: 100%;
  font-size: 20px;
  line-height: 28px;
  font-weight: 500;
  background-color: rgba(255, 255, 255, 1);
`;

export const InnerHeader = styled.div`
  padding: 14px 24px;
  max-width: 1660px;
  margin: 0 auto;
`;

export const Content = styled.div`
  max-width: 1680px;
  margin: 0 auto;
  width: 100%;
`;

export const SpinnerWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 100%;
  min-height: 600px;
`;

export const Spinner = styled(Spin)`
  margin: auto;
`;

export const SubWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  gap: 14px;
  width: 100%;
  height: 100%;
  padding: 14px 0 0 24px;
  max-width: 1660px;
  margin: 0 auto;
  margin-right: 24px;
`;

export const SubHeader = styled.div`
  width: 100%;
  height: 28px;

  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
`;
