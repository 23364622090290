import React, { FC, useEffect } from 'react';
import { useParams } from 'react-router';
import Spinner from 'antd/es/spin';
import { useTranslation } from 'react-i18next';

import { Validate } from 'shipment-operations/view/components';
import {
  SubTitle, TableWrapper, CarrierWrapper, InputsWrapper, InputTitle, StyledInput, UploadWrapper,
} from 'shipment-operations/view/drawers/ShipmentChanges/ShipmentChanges.styled';
import {
  CustomTable, EditableTable, TransportPlan, Uploader,
} from 'shipment-operations/view/drawers/ShipmentChanges/components';
import { IChangesTable, ITransportPlanChanges } from 'shipment-operations/models/dtm';
import {
  CARGO, CHANGES, CONTAINER, CUTOFF, LOCATIONS, ShipmentBookingStatusEnum,
} from 'shipment-operations/constants';
import { GlobalLoader } from 'shipment-operations/view/drawers/MaerskChanges/MaerskChanges.styled';

interface IThirdStepComponentProps {
  loadData: (shipmentId: string) => void
  loading: boolean
  cargoTable: IChangesTable[]
  containersTable: IChangesTable[]
  transportPlanCurrent: ITransportPlanChanges[]
  transportPlanPrevious: ITransportPlanChanges[]
  cutOffTable: IChangesTable[];
  locationsTable: IChangesTable[];
  inttraNumber: string;
  carrierNumber: string;
  onChangeLocation: (row: IChangesTable) => void;
  onChangeCutOff: (row: IChangesTable) => void;
  savedShipmentId: string;
  syncIntraNumber: () => void;
  bookingStatus?: ShipmentBookingStatusEnum;
  savedIntraNumber?: string | null;
  updatedIntraNumber?: string | null;
  updatedBookingStatus?: ShipmentBookingStatusEnum;
  showValidate: boolean;
}

const ThirdStepComponent: FC<IThirdStepComponentProps> = ({
  loadData, loading, cargoTable, containersTable, transportPlanCurrent, transportPlanPrevious,
  cutOffTable, locationsTable, inttraNumber, onChangeLocation, onChangeCutOff, carrierNumber, savedShipmentId, syncIntraNumber,
  bookingStatus, savedIntraNumber, updatedIntraNumber, updatedBookingStatus,
  showValidate,
}) => {
  const { t } = useTranslation();
  const { shipmentId } = useParams();

  useEffect(() => {
    if ((bookingStatus === ShipmentBookingStatusEnum.BOOKING_SUBMITTED && savedIntraNumber) || (updatedBookingStatus === ShipmentBookingStatusEnum.BOOKING_SUBMITTED && updatedIntraNumber)) {
      syncIntraNumber();
    }
    if (shipmentId || savedShipmentId) {
      loadData(shipmentId || savedShipmentId);
    }
  }, [shipmentId, savedShipmentId, savedIntraNumber, updatedIntraNumber]);

  if (loading) {
    return (
      <GlobalLoader>
        <Spinner />
      </GlobalLoader>
    );
  }

  return (
    <>
      <>
        <CarrierWrapper>
          <SubTitle style={{ marginBottom: '0' }}>{t('carrier')}</SubTitle>
          <InputsWrapper>
            <InputTitle>{t('inttraReferenceNumber')}</InputTitle>
            <StyledInput
              style={{ marginRight: '15px' }}
              value={inttraNumber}
              disabled
            />
            <InputTitle>{t('carrierReferenceNumber')}</InputTitle>
            <StyledInput
              value={carrierNumber}
              disabled
            />
          </InputsWrapper>
        </CarrierWrapper>
        <UploadWrapper>
          <SubTitle>{t('bookingConfirmationDocument')}</SubTitle>
          <Uploader />
        </UploadWrapper>
      </>
      <TableWrapper>
        <SubTitle>{t('cargo')}</SubTitle>
        <CustomTable
          data={cargoTable}
          type={CARGO}
          withThreeColumns={false}
          withMismatches
          isRequestTheSameWithShipment
          isCustomer={false}
        />
      </TableWrapper>
      <TableWrapper>
        <SubTitle>{t('Containers')}</SubTitle>
        <CustomTable
          data={containersTable}
          type={CONTAINER}
          withThreeColumns={false}
          withMismatches
          isRequestTheSameWithShipment
          isCustomer={false}
        />
      </TableWrapper>
      <TableWrapper>
        <SubTitle>{t('transportPlan')}</SubTitle>
        <TransportPlan
          requestedTitle={t('currentlyConfirmed')}
          mismatchedTitle={t('changesRequested')}
          requestedData={transportPlanPrevious}
          mismatchedData={transportPlanCurrent}
          withMismatch
        />
      </TableWrapper>
      {showValidate && (
        <div style={{ marginTop: '10px' }}>
          <Validate typeDrawer={CHANGES} />
        </div>
      )}
      <TableWrapper>
        <SubTitle>{t('Cutoffs')}</SubTitle>
        <EditableTable
          data={cutOffTable}
          type={CUTOFF}
          withThreeColumns={false}
          withMismatches={false}
          isRequestTheSameWithShipment={false}
          onChange={onChangeCutOff}
          mismatchesTable
        />
      </TableWrapper>
      <TableWrapper>
        <SubTitle>{t('Locations')}</SubTitle>
        <EditableTable
          data={locationsTable}
          type={LOCATIONS}
          withThreeColumns={false}
          withMismatches={false}
          isRequestTheSameWithShipment={false}
          onChange={onChangeLocation}
          mismatchesTable
        />
      </TableWrapper>
    </>
  );
};

export { ThirdStepComponent };
