import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import {
  CanceledContent,
  CanceledHeader,
  CanceledIcon, CanceledText, CanceledWrap,
} from './ShipmentCanceled.styled';

interface IShipmentCanceledProps {
  message?: string;
}

const ShipmentCanceled: FC<IShipmentCanceledProps> = ({
  message,
}) => {
  const { t } = useTranslation();

  return (
    <CanceledWrap>
      <CanceledIcon />

      <CanceledContent>
        <CanceledHeader>
          {t('Shipment is cancelled')}
        </CanceledHeader>

        <CanceledText>
          {message}
        </CanceledText>
      </CanceledContent>

    </CanceledWrap>
  );
};

export { ShipmentCanceled };
