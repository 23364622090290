import React, { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { UploadOutlined } from '@ant-design/icons';

import { Button } from './UploadMarkupPolicyButton.styled';

export const UploadMarkupPolicyButton: FC = memo(() => {
  const { t } = useTranslation();

  return (
    <Button icon={<UploadOutlined />}>
      {t('Upload Markup Policy')}
    </Button>
  );
});
