import React from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';

import { EditBillOfLadingComponent } from './EditBillOfLading.component';

const EditBillOfLadingContainer = () => {
  const isPending = useSelector(R.selectors.billOfLading.getLoading);
  const { type } = useSelector(R.selectors.billOfLading.getAllBillOfLadingInformation);

  return (
    <EditBillOfLadingComponent
      isPending={isPending}
      billOfLadingType={type}
    />
  );
};

export { EditBillOfLadingContainer as EditBillOfLading };
