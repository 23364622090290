import React, { FC, useCallback } from 'react';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';

import { Button } from 'app-wrapper/view/components';

import { ControlButtonsWrapper } from './ControlButtons.styled';

interface IControlButtonsComponentProps {
  isInDraft: boolean
  isLoading: boolean
  resetTemperatureControl: () => void
  updateTemperatureControl: (shipmentId: string) => void
}

export const ControlButtonsComponent: FC<IControlButtonsComponentProps> = ({
  isInDraft,
  isLoading,
  resetTemperatureControl,
  updateTemperatureControl,
}) => {
  const { t } = useTranslation();
  const { shipmentId = '0' } = useParams();

  const handleUpdate = useCallback(() => {
    updateTemperatureControl(shipmentId);
  }, []);

  return (
    <ControlButtonsWrapper>
      <Button disabled={!isInDraft} size="small" type="dashed" onClick={resetTemperatureControl}>
        {t('Discard')}
      </Button>
      <Button disabled={!isInDraft} loading={isLoading} size="small" type="primary" onClick={handleUpdate}>
        {t('Update')}
      </Button>
    </ControlButtonsWrapper>
  );
};
