import React, { FC, memo } from 'react';
import { CommandCenterNotification } from './CommandCenter';

const CommandCenterNotificationPage: FC = () => (
  <CommandCenterNotification />
);

const CommandCenterNotificationPageCache = memo(CommandCenterNotificationPage);

export { CommandCenterNotificationPageCache as CommandCenterNotificationPage };
