import styled from 'styled-components';
import Typography from 'antd/es/typography';

import { divFlex } from 'app-wrapper/view/themes/mixin';

export const SBLCarrierInformation = styled('div').attrs({
  'data-class': 'SBLCarrierInformation',
})`
  ${divFlex}
  flex-direction: column;
`;

export const SBLCarrierTitle = styled(Typography)`
  width: 100%;
  margin-bottom: 12px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
`;

export const SBLCarrierRow = styled('div')`
  ${divFlex}
  flex-direction: row;
  justify-content: space-between;
`;

export const SBLCarrierItem = styled('div')`
  ${divFlex}
  flex-direction: column;
`;

export const SBLCarrierItemTitle = styled(Typography)`
  font-size: 12px;
  line-height: 20px;
  color: ${({ theme }) => theme.themesColors.secondaryDot45};
`;

export const SBLCarrierItemSubTitle = styled(Typography)`
  font-size: 14px;
  line-height: 22px;
`;
