import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { YellowTag, GreenTag } from 'app-wrapper/view/guideline';

interface IStatusTagComponentProps {
  isCleared?: boolean;
}

export const StatusTag: FC<IStatusTagComponentProps> = ({ isCleared }) => {
  const { t } = useTranslation();

  return (
    <>
      {isCleared ? (
        <GreenTag>
          {t('Cleared')}
        </GreenTag>
      ) : (
        <YellowTag>
          {t('Draft')}
        </YellowTag>
      )}
    </>
  );
};
