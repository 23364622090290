export const RouteNames = {
  // routes for authorized users
  HOME: () => '/',
  REGISTER_ORGANISATION: () => '/register_organisation',
  OVERVIEW: () => '/overview',

  ACCOUNT: () => '/account',
  USER_PROFILE: () => '/account/user-profile',
  ORGANIZATION_PROFILE: () => '/account/organization-profile',
  USER_MANAGEMENT: () => '/account/user-management',
  USER_MANAGEMENT_PROFILE: (email: string = ':email') => `/account/user-management/${email}`,
  ADDRESSES_CONTACTS: () => '/account/addresses-contacts',
  ACCOUNT_DEPARTMENTS: () => '/account/departments',
  ACCOUNT_DEPARTMENTS_BY_ID: (departmentId: number | string = ':departmentId') => `/account/departments/${departmentId}`,
  ADDRESSES_CONTACTS_SECOND: (addressId: number | string = ':addressId') => `/account/addresses-contacts/${addressId}`,
  ACCOUNT_CUSTOMERS: () => '/account/customers',
  ACCOUNT_TEAMS: () => '/account/teams',
  ACCOUNT_TEAM: (teamId: number | string = ':teamId') => `/account/teams/${teamId}`,
  ACCOUNT_MY_TEAM: () => '/account/my-team',
  ACCOUNT_PARTNERS: () => '/account/partners',
  ACCOUNT_DOCUMENTS: () => '/account/documents',
  ACCOUNT_USERS: () => '/account/users',
  ACCOUNT_CUSTOMER: (customerId: number | string = ':customerId') => `/account/customers/${customerId}`,
  ACCOUNT_CUSTOMER_GENERAL_INFO: (customerId: number | string = ':customerId') => `/account/customers/${customerId}/general-info`,
  ACCOUNT_CUSTOMER_DOCUMENTS: (customerId: number | string = ':customerId') => `/account/customers/${customerId}/documents`,
  ACCOUNT_CUSTOMER_NOTES: (customerId: number | string = ':customerId') => `/account/customers/${customerId}/notes`,
  ACCOUNT_CUSTOMER_ACCOUNTING: (customerId: number | string = ':customerId') => `/account/customers/${customerId}/accounting`,
  ACCOUNT_CUSTOMER_TEAM: (customerId: number | string = ':customerId') => `/account/customers/${customerId}/team`,
  ACCOUNT_CUSTOMER_SALES: (customerId: number | string = ':customerId') => `/account/customers/${customerId}/sales`,

  ACCOUNT_PARTNER: (partnerId: number | string = ':customerId') => `/account/partners/${partnerId}`,
  ACCOUNT_PARTNER_GENERAL_INFO: (partnerId: number | string = ':customerId') => `/account/partners/${partnerId}/general-info`,
  ACCOUNT_PARTNER_DOCUMENTS: (partnerId: number | string = ':customerId') => `/account/partners/${partnerId}/documents`,
  ACCOUNT_PARTNER_NOTES: (partnerId: number | string = ':customerId') => `/account/partners/${partnerId}/notes`,
  ACCOUNT_PARTNER_ACCOUNTING: (partnerId: number | string = ':customerId') => `/account/partners/${partnerId}/accounting`,

  COMMAND_CENTER: () => '/action-center',
  COMMAND_CENTER_TASKS: () => '/action-center/tasks',
  COMMAND_CENTER_TASKS_COMPLETED: () => '/action-center/tasks-completed',
  COMMAND_CENTER_TASKS_ALERTS: () => '/action-center/alerts',
  COMMAND_CENTER_TASKS_ALERTS_COMPLETED: () => '/action-center/alerts-completed',
  COMMAND_CENTER_NOTIFICATION: () => '/action-center/notification',

  FREIGHT_QUOTE: () => '/freight_quote',
  CUSTOM_QUOTE: () => '/custom_quote',
  CUSTOM_QUOTE_REQUEST: (customQuotaId: number | string = ':customQuotaId') => `/custom_quote/${customQuotaId}`,
  REQUEST_QUOTA: () => '/request_quota',
  ALL_REQUESTED_QUOTES: (quotaId?: string | number) => (quotaId
    ? `/all-requests?quotaid=${quotaId}`
    : '/all-requests'),
  HISTORY_REQUESTED_QUOTES: () => '/history_requested_quotes',

  BOOKING_NEW: () => '/booking/new', // will be fixed after SHIPMENTS and SHIPMENT implementation

  SHIPMENTS: () => '/shipments',
  SHIPMENTS_ACTIVE: () => '/shipments/active',
  SHIPMENTS_COMPLETED: () => '/shipments/completed',
  SHIPMENTS_CANCELLED: () => '/shipments/cancelled',
  SHIPMENT: (shipmentId: number | string = ':shipmentId') => `/shipments/${shipmentId}`,
  SHIPMENT_OVERVIEW: (shipmentId: number | string = ':shipmentId') => `/shipments/${shipmentId}/overview`,
  SHIPMENT_CENTER_TASKS: (shipmentId: number | string = ':shipmentId') => `/shipments/${shipmentId}/action-center/tasks`,
  SHIPMENT_CENTER_TASKS_COMPLETED: (shipmentId: number | string = ':shipmentId') => `/shipments/${shipmentId}/action-center/tasks-completed`,
  SHIPMENT_CENTER_ALERTS: (shipmentId: number | string = ':shipmentId') => `/shipments/${shipmentId}/action-center/alerts`,
  SHIPMENT_CENTER_ALERTS_COMPLETED: (shipmentId: number | string = ':shipmentId') => `/shipments/${shipmentId}/action-center/alerts-completed`,
  SHIPMENT_CENTER_NOTIFICATION: (shipmentId: number | string = ':shipmentId') => `/shipments/${shipmentId}/action-center/notification`,
  SHIPMENT_SHIPPING_PARTIES: (shipmentId: number | string = ':shipmentId') => `/shipments/${shipmentId}/shipping-parties`,
  SHIPMENT_CARGO: (shipmentId: number | string = ':shipmentId') => `/shipments/${shipmentId}/cargo`,
  SHIPMENT_LOCATIONS: (shipmentId: number | string = ':shipmentId') => `/shipments/${shipmentId}/locations`,
  SHIPMENT_CONTAINERS: (shipmentId: number | string = ':shipmentId') => `/shipments/${shipmentId}/containers`,
  SHIPMENT_PEOPLE: (shipmentId: number | string = ':shipmentId') => `/shipments/${shipmentId}/people`,
  SHIPMENT_ADDITIONAL_SERVICES: (shipmentId: number | string = ':shipmentId') => `/shipments/${shipmentId}/additional-services`,
  SHIPMENT_ADDITIONAL_SERVICE: (shipmentId: number | string = ':shipmentId', serviceId: number | string = ':serviceId') => `/shipments/${shipmentId}/additional-services/${serviceId}`,
  SHIPMENT_CONTAINER: (
    shipmentId: number | string = ':shipmentId', containerId: number | string = ':containerId',
  ) => `/shipments/${shipmentId}/containers/${containerId}`,
  SHIPMENT_DOCUMENTS: (shipmentId: number | string = ':shipmentId') => `/shipments/${shipmentId}/documents`,
  SHIPMENT_DOCUMENTS_ALL: (shipmentId: number | string = ':shipmentId') => `/shipments/${shipmentId}/documents/all`,
  SHIPMENT_DOCUMENTS_HAZMAT: (shipmentId: number | string = ':shipmentId') => `/shipments/${shipmentId}/documents/hazmat`,
  SHIPMENT_DOCUMENTS_ADDITIONAL: (shipmentId: number | string = ':shipmentId') => `/shipments/${shipmentId}/documents/additional`,
  SHIPMENT_DOCUMENTS_SEAWORTHY_CERTIFICATE: (shipmentId: number | string = ':shipmentId') => `/shipments/${shipmentId}/documents/seaworthy-certificate`,
  SHIPMENT_TRANSPORTATION_OCEAN_BOOKING: (shipmentId: number | string = ':shipmentId') => `/shipments/${shipmentId}/transportation/ocean-booking`,
  SHIPMENT_TRANSPORTATION_OCEAN_BOOKING_OVERVIEW: (shipmentId: number | string = ':shipmentId') => `/shipments/${shipmentId}/transportation/ocean-booking/overview`,
  SHIPMENT_TRANSPORTATION_OCEAN_BOOKING_TRACKER: (shipmentId: number | string = ':shipmentId') => `/shipments/${shipmentId}/transportation/ocean-booking/tracker`,
  SHIPMENT_TRANSPORTATION_OCEAN_BOOKING_CHARGES: (shipmentId: number | string = ':shipmentId') => `/shipments/${shipmentId}/transportation/ocean-booking/charges`,
  SHIPMENT_TRANSPORTATION_OCEAN_BOOKING_ACCESSORIALS: (shipmentId: number | string = ':shipmentId') => `/shipments/${shipmentId}/transportation/ocean-booking/accessorials`,
  SHIPMENT_TRANSPORTATION_OCEAN_BOOKING_FREE_TIME: (shipmentId: number | string = ':shipmentId') => `/shipments/${shipmentId}/transportation/ocean-booking/free-time`,
  SHIPMENT_TRANSPORTATION: (shipmentId: number | string = ':shipmentId') => `/shipments/${shipmentId}/transportation`,
  SHIPMENT_TRANSPORTATION_OVERVIEW: (shipmentId: number | string = ':shipmentId') => `/shipments/${shipmentId}/transportation/overview`,
  SHIPMENT_TRANSPORTATION_TRACKER: (shipmentId: number | string = ':shipmentId') => `/shipments/${shipmentId}/transportation/tracker`,
  SHIPMENT_TRANSPORTATION_CHARGES: (shipmentId: number | string = ':shipmentId') => `/shipments/${shipmentId}/transportation/charges`,
  SHIPMENT_TRANSPORTATION_ACCESSORIALS: (shipmentId: number | string = ':shipmentId') => `/shipments/${shipmentId}/transportation/accessorials`,
  SHIPMENT_TRANSPORTATION_FREE_TIME: (shipmentId: number | string = ':shipmentId') => `/shipments/${shipmentId}/transportation/free-time`,
  SHIPMENT_EXPORT_CLEARANCE: (shipmentId: number | string = ':shipmentId') => `/shipments/${shipmentId}/export-clearance`,
  SHIPMENT_BILL_OF_LADING: (shipmentId: number | string = ':shipmentId') => `/shipments/${shipmentId}/bill-of-lading`,
  SHIPMENT_CHARGES: (shipmentId: number | string = ':shipmentId') => `/shipments/${shipmentId}/charges`,
  SHIPMENT_CHARGES_ACCESSORIALS: (shipmentId: number | string = ':shipmentId') => `/shipments/${shipmentId}/charges/accessorials`,
  SHIPMENT_CHARGES_FREE_TIME: (shipmentId: number | string = ':shipmentId') => `/shipments/${shipmentId}/charges/free-time`,
  SHIPMENT_BILLING: (shipmentId: number | string = ':shipmentId') => `/shipments/${shipmentId}/billing`,
  SHIPMENT_BILLING_INVOICE: (
    shipmentId: number | string = ':shipmentId', invoiceId: string | number = ':invoiceId', invoiceType: string = ':invoiceType',
  ) => `/shipments/${shipmentId}/billing/invoice/${invoiceType}/${invoiceId}`,
  SHIPMENT_CREDIT_NOTE: (
    shipmentId: number | string = ':shipmentId', creditNoteId: string | number = ':creditNoteId', invoiceType: string = ':invoiceType',
  ) => `/shipments/${shipmentId}/billing/credit-note/${invoiceType}/${creditNoteId}`,
  SHIPMENT_DISPUTES: (shipmentId: number | string = ':shipmentId') => `/shipments/${shipmentId}/disputes`,
  SHIPMENT_ACTIVITY_LOG: (shipmentId: number | string = ':shipmentId') => `/shipments/${shipmentId}/activity-log`,

  SHIPMENT_TRANSPORTATION_EXPORT: (shipmentId: number | string = ':shipmentId') => `/shipments/${shipmentId}/transportation/export`,
  SHIPMENT_TRANSPORTATION_EXPORT_OVERVIEW: (shipmentId: number | string = ':shipmentId') => `/shipments/${shipmentId}/transportation/export/overview`,
  SHIPMENT_TRANSPORTATION_EXPORT_TRACKER: (shipmentId: number | string = ':shipmentId') => `/shipments/${shipmentId}/transportation/export/tracker`,
  SHIPMENT_TRANSPORTATION_EXPORT_CHARGES: (shipmentId: number | string = ':shipmentId') => `/shipments/${shipmentId}/transportation/export/charges`,
  SHIPMENT_TRANSPORTATION_IMPORT: (shipmentId: number | string = ':shipmentId') => `/shipments/${shipmentId}/transportation/import`,
  SHIPMENT_TRANSPORTATION_IMPORT_OVERVIEW: (shipmentId: number | string = ':shipmentId') => `/shipments/${shipmentId}/transportation/import/overview`,
  SHIPMENT_TRANSPORTATION_IMPORT_TRACKER: (shipmentId: number | string = ':shipmentId') => `/shipments/${shipmentId}/transportation/import/tracker`,
  SHIPMENT_TRANSPORTATION_IMPORT_CHARGES: (shipmentId: number | string = ':shipmentId') => `/shipments/${shipmentId}/transportation/import/charges`,

  CONTACT_BOOK: () => '/contact-book',
  CONTACT_BOOK_BY_ID: (contactId: number | string = ':contactId') => `/contact-book/${contactId}`,
  CONTACT_BOOK_BY_ID_CONTACT_BY_ID: (contactId: number | string = ':contactId', addressId: number | string = ':addressId') => `/contact-book/${contactId}/contacts/${addressId}`,
  CUSTOMERS: () => '/customers',
  CUSTOMERS_INTERNAL: (companyId: number | string = ':companyId') => `/customers/internal/${companyId}`,

  CONTRACTS: () => '/contracts',
  RATES_UPLOAD: () => '/rates-upload',
  RATES_FREIGHT_LIST: () => '/rates-requests',
  RATES_FREIGHT_BY_ID: (customQuotaId: number | string = ':customQuotaId') => `/rates-requests/${customQuotaId}`,

  NEW_ORGANIZATION_WIZARD: () => '/new-organization-wizard',
  ADD_COMPANY_DETAILS_WIZARD_ITEM: () => '/new-organization-wizard/add-company-details',
  SUBMIT_PROOF_DOCUMENTS_WIZARD_ITEM: () => '/new-organization-wizard/submit-proof-documents',
  NEW_ORGANIZATION_REVIEW_AND_CONFIRM_WIZARD_ITEM: () => '/new-organization-wizard/review-and-confirm',
  NEW_ORGANIZATION_SUCCESS: () => '/new-organization-success',

  // routes for unauthorised users
  SIGN_IN: () => '/sign-in',
  SIGN_UP: () => '/sign_up',
  'SIGN-UP': () => '/sign-up',
  FORGOT_PASSWORD_EMAIL_SEND: () => '/forgot-password-email-send',
  FORGOT_PASSWORD_NEW_PASSWORD: () => '/forgot-password-new-password',
  FORGOT_PASSWORD: () => '/forgot-password',
  SIGN_UP_SUCCESS: () => '/sign-up-success',
  SIGN_UP_EMAIL_CONFIRMATION: () => '/sign-up/email_confirmation',

  FINANCES_INVOICES: () => '/finances/invoices',
  FINANCES_AP_INVOICES: () => '/finances/ap-invoices',
  FINANCES_PAYMENTS: () => '/finances/payments/receivables',
  FINANCES_AP_PAYMENTS: () => '/finances/payments/payables',
  FINANCES_PAYMENT_VIEW: (
    paymentId: string | number = ':paymentId', invoiceType: string = ':invoiceType',
  ) => `/finances/payments/${invoiceType}/${paymentId}`,
  GUIDELINE: () => '/guideline',
  GUIDELINE_ELEMENTS: () => '/guideline/elements',
  GUIDELINE_MARKUP: () => '/guideline/markup',
  GUIDELINE_SECTIONS: () => '/guideline/sections',
  GUIDELINE_ICONS: () => '/guideline/icons',
};
