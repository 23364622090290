import { ChargeCodeMeasureBy } from 'shipment-operations/constants';
import i18n from 'app-wrapper/i18n/i18n';

export const WM = 'WM';
export const KG_LB = 'KG_LB';
export const CBM_CF = 'CBM_CF';
export const HOUR = 'HOUR';
export const DAY = 'DAY';
export const KM_MILE = 'KM_MILE';
export const FLAT = 'FLAT';
export const DOCUMENT = 'DOCUMENT';
export const CONTAINER_TYPE = 'CONTAINER_TYPE';

export const measureByConst = {
  WM,
  KG_LB,
  CBM_CF,
  HOUR,
  DAY,
  KM_MILE,
  FLAT,
  DOCUMENT,
  CONTAINER_TYPE,
};

export const measureByOptions = [
  {
    value: WM,
    label: 'WM',
  },
  {
    value: KG_LB,
    label: 'Kg/lb',
  },
  {
    value: CBM_CF,
    label: 'Cbm/cf',
  },
  {
    value: HOUR,
    label: 'Hour',
  },
  {
    value: DAY,
    label: 'Day',
  },
  {
    value: KM_MILE,
    label: 'Km/mile',
  },
  {
    value: FLAT,
    label: 'Flat',
  },
  {
    value: DOCUMENT,
    label: 'Document',
  },
  {
    value: CONTAINER_TYPE,
    label: 'Container',
  },
];

export const measureByWithoutContainerOptions = [
  {
    value: WM,
    label: 'WM',
  },
  {
    value: KG_LB,
    label: 'Kg/lb',
  },
  {
    value: CBM_CF,
    label: 'Cbm/cf',
  },
  {
    value: HOUR,
    label: 'Hour',
  },
  {
    value: DAY,
    label: 'Day',
  },
  {
    value: KM_MILE,
    label: 'Km/mile',
  },
  {
    value: FLAT,
    label: 'Flat',
  },
  {
    value: DOCUMENT,
    label: 'Document',
  },
];

export const CHARGE_MEASURE_BY_NAMES: Record<ChargeCodeMeasureBy, string> = {
  WM: i18n.t('WM'),
  KG_LB: i18n.t('Kg/lb'),
  CBM_CF: i18n.t('Cbm/cf'),
  HOUR: i18n.t('Hour'),
  DAY: i18n.t('Day'),
  KM_MILE: i18n.t('Km/mile'),
  FLAT: i18n.t('Flat'),
  CONTAINER_TYPE: i18n.t('Container'),
};
