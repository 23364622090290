import styled from 'styled-components';
import Typography from 'antd/es/typography';
import Spin from 'antd/es/spin';

import { divFlex } from 'app-wrapper/view/themes/mixin';

export const Header = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 8px;
  margin-bottom: 10px;
  border-bottom: 1px solid #EAEEF8;
`;

export const Form = styled.div.attrs({
  'data-class': 'BOLFormEditPayments',
})`
  height: 100%;
  padding: 24px;
`;

export const FormItem = styled.div`
  ${divFlex}
  flex-direction: column;
  margin-bottom: 10px;
`;

export const FormItemLabel = styled.div`
  ${divFlex}
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 4px;
`;

export const Label = styled(Typography)`
  font-size: 12px;
  line-height: 20px;
`;

export const LabelGrey = styled(Label)`
  color: #73819B;
`;

export const LabelPayee = styled(LabelGrey)`
  margin-right: 4px;
`;

export const LabelRow = styled.div`
  display: flex;
  flex-direction: row;
`;

export const SpinnerWrap = styled.div`
  ${divFlex}
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
`;

export const Spinner = styled(Spin)`
  margin: auto;
`;
