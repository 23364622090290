import styled from 'styled-components';

import { Sign as OriginalSign } from 'shipment-operations/view/components/HBLDocumentPDF/components/Cell/Cell.styled';

export const Sign = styled(OriginalSign)`
  position: relative;
  margin-left: 4px;
  text-transform: uppercase;
`;

export const Row = styled.div`
  margin-bottom: 4px;
`;
