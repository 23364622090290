import { createSlice } from 'app-wrapper/createSlice';

import { IFAKExceptionState } from 'monetary/models/states';

const initialState: IFAKExceptionState = {};

export const FAKExceptionSlice = createSlice({
  name: 'FAKException',
  initialState,
  reducers: {
    setIsLoading: (state, { payload }) => {
      state[payload.id] = {
        ...state[payload.id],
        isLoading: payload.isLoading,
      };
    },
    setError: (state, { payload }) => {
      state[payload.id] = {
        ...state[payload.id],
        isLoading: false,
        isError: payload.isError,
      };
    },
    setData: (state, { payload }) => {
      state[payload.id] = {
        ...state[payload.id],
        data: payload.data,
        isLoading: false,
        isError: false,
      };
    },
    setEmptyData: (state, { payload }) => {
      state[payload.id] = {
        ...state[payload.id],
        isEmptyData: payload.isEmptyData,
        isLoading: false,
        isError: false,
        data: [],
      };
    },
  },
});

export const FAKExceptionActions = FAKExceptionSlice.actions;
export const FAKExceptionReducer = FAKExceptionSlice.reducer;
