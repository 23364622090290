import styled from 'styled-components';
import { PaperClipOutlined } from '@ant-design/icons';

export const DocumentItem = styled.div`
  width: fit-content;
  height: 22px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const DocumentIcon = styled(PaperClipOutlined)`
  margin-right: 8px;
`;

export const DocumentItemName = styled.span`
  color: #6C79DE;
  cursor: pointer;
  font-size: 14px;
`;
