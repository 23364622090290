import styled from 'styled-components';
import Typography from 'antd/es/typography';
import Badge from 'antd/es/badge';

import { colors } from 'app-wrapper/view/themes/themesColors';

export const ContainerCardTopContainer = styled.div`
  width: 100%;
`;

export const ContainerCardTitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
`;

export const ContainerCardHsCode = styled(Typography.Text)`
  font-size: 12px;
  color: #73819B;
`;

interface IContainerTitleTextProps {
  $gray: boolean,
}

export const ContainerTitleText = styled(Typography.Title)<IContainerTitleTextProps>`
  ${({ $gray }) => !!$gray && `color: ${colors.gray61} !important`};
  font-size: 14px !important;
  margin-bottom: 0 !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ContainerTitleTextWithDraft = styled.span`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  gap: 0;
  width: calc(100% - 24px);
  height: 18px;
`;

export const UnalignedBadge = styled(Badge)`
  margin-left: 4px;
  > * { top: -4px !important; }
`;
