import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import { RouteNames } from 'app-wrapper/constants';

import {
  Wrap,
  Content,
  Image,
  Title,
  Text,
} from './RequestFirstQuota.styled';
import illustrationSrc from './assets/illustration.png';
import { StepManagerButton } from '../../StepsManager.styled';

export const RequestFirstQuota = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const goToRFQ = useCallback(() => {
    navigate(RouteNames.FREIGHT_QUOTE());
  }, [navigate]);

  return (
    <Wrap>
      <Content>
        <Title>
          {t('Browse Pricing')}
        </Title>

        <Text>
          {t('Use our smart calendar and live schedules to access the best instant quotes that fit your business expectations. Select the option that serves your purposes logistically at all levels of business execution.')}
        </Text>

        <StepManagerButton onClick={goToRFQ}>
          {t('Browse Pricing')}
        </StepManagerButton>
      </Content>

      <Image src={illustrationSrc} />
    </Wrap>
  );
};
