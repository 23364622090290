import styled from 'styled-components';

import { divFlex } from 'app-wrapper/view/themes/mixin';

export const RFRCompletedRequestWrap = styled.div.attrs({
  'data-class': 'RFRCompletedRequestWrap',
})`
  ${divFlex}
  width: 580px;
  height: 80vh;
  align-items: center;
`;

export const RFRCompletedRequestContentWrap = styled.div.attrs({
  'data-class': 'RFRCompletedRequestContentWrap',
})`
  ${divFlex}
  flex-direction: column;
  align-items: center;
  justify-content: center;

  height: 258px;
  width: 100%;
`;

export const RFRCompletedRequestContentWrapImage = styled.div.attrs((props: {
  image: string
}) => ({
  image: props.image,
  'data-class': 'RFRCompletedRequestContentWrapImage',
}))`
  ${divFlex}
`;

export const RFRCompletedRequestHeader = styled.div.attrs({
  'data-class': 'RFRCompletedRequestHeader',
})`
  ${divFlex}
  align-items: center;

  font-weight: 500;
  font-size: 20px;
  margin-top: 8px;
`;

export const RFRCompletedRequestHeaderTitle = styled.div.attrs({
  'data-class': 'RFRCompletedRequestHeaderTitle',
})`
  ${divFlex}
  align-items: center;

  font-weight: 400;
  font-size: 14px;
  color: ${({ theme }) => theme.themesColors.secondaryDot45};
`;

export const RFRCompletedRequestContent = styled.div.attrs({
  'data-class': 'RFRCompletedRequestContent',
})`
  ${divFlex}
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;

  height: 82px;
  margin-top: 20px;
`;

export const RFRCompletedRequestTwoBtn = styled.div.attrs({
  'data-class': 'RFRCompletedRequestTwoBtn',
})`
  ${divFlex}
  gap: 8px;
`;

export const RFRCompletedRequestContentLink = styled.div.attrs({
  'data-class': 'RFRCompletedRequestContentLink',
})`
  ${divFlex}

  font-weight: 400;
  font-size: 16px;
  margin-top: 0px;
`;
