import styled from 'styled-components';

import {
  SubPageContent,
  TitleText,
  FlexRow,
} from 'app-wrapper/view/guideline';

export const Section = styled(SubPageContent)`
  padding: 20px 20px 0 20px;
  gap: 16px;
`;

export const Title = styled.span`
  ${TitleText}
`;

export const Header = styled(FlexRow)`
  justify-content: space-between;
`;
