import {
  IsDefined, IsString,
} from 'class-validator';
import { BaseDTM } from 'proto/BaseDTM';

export interface IReferenceDTM {
  id: string
  type: string
  value: string
}

export class ReferenceDTM extends BaseDTM<IReferenceDTM> {
  @IsString()
  @IsDefined()
  id: string;

  @IsString()
  @IsDefined()
  type: string;

  @IsString()
  @IsDefined()
  value: string;
}
