import styled from 'styled-components';

import { divFlex } from 'app-wrapper/view/themes/mixin';

export const TasksFilterHeaderComponentWrap = styled.div.attrs({
  'data-class': 'TasksFilterHeaderComponentWrap',
})`
  ${divFlex}

  width: 100%;
  align-items: center;
`;

export const TasksFilterHeaderComponentSeparate = styled.div.attrs({
  'data-class': 'TasksFilterHeaderComponentSeparate',
})`
  ${divFlex}

  margin-left: 32px;
`;
