import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { HBLDocumentTransportationPlanDTM } from 'shipment-operations/models/dtm';

import { Cell } from '../Cell';
import { Column, Row, ShipmentTransportWrapper } from '../Common.styled';

enum TYPES {
  'SEA' = 'CY',
  'WATER' = 'CY',
  'RAIL' = 'RL',
  'AIR' = 'CY',
  'ROAD' = 'TR',
  'OCEAN' = 'CY',
}

interface ShipmentTransportProps {
  transportationPlanInfo: HBLDocumentTransportationPlanDTM
}

export const ShipmentTransport: FC<ShipmentTransportProps> = ({ transportationPlanInfo }) => {
  const { t } = useTranslation();

  return (
    <ShipmentTransportWrapper>
      <Row>
        <Column>
          <Cell label="Vessel" value={transportationPlanInfo.vesselName} />
        </Column>
        <Column>
          <Cell label="Voyage" value={transportationPlanInfo.voyagerNumber} />
        </Column>
        <Column>
          <Cell label="Pre-carriage Location" value="N/A" sign="DOOR" />
        </Column>
        <Column>
          <Cell label="On-carriage Location" value="N/A" sign="DOOR" />
        </Column>
      </Row>
      <Row>
        <Column>
          <Cell
            label={t('Place of Receipt')}
            value={transportationPlanInfo.placeOfReceipt}
            sign={TYPES[transportationPlanInfo.firstLegTransportType]}
          />
        </Column>
        <Column>
          <Cell
            label={t('Port of Loading')}
            value={transportationPlanInfo.portOfLoading}
            sign={TYPES[transportationPlanInfo.lastLegTransportType]}
          />
        </Column>
        <Column>
          <Cell
            label={t('Port of Discharge')}
            value={transportationPlanInfo.portOfDischarge}
            sign={TYPES[transportationPlanInfo.firstSeaLegTransportType]}
          />
        </Column>
        <Column>
          <Cell
            label={t('Place of Delivery')}
            value={transportationPlanInfo.placeOfDelivery}
            sign={TYPES[transportationPlanInfo.lastSeaLegTransportType]}
          />
        </Column>
      </Row>
    </ShipmentTransportWrapper>
  );
};
