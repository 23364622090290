import { createSelector } from 'reselect';

import { RootState } from 'app-wrapper/store';
import moment from 'moment';
import { ENotificationCriticality } from 'app-wrapper/constants';
import { CommandCenterComponentTaskDTM, INotificationTaskContentDTM } from 'app-wrapper/models/dtm';

const selectSelf = (state: RootState) => state;

const getCommandCenter = (state: RootState) => (
  state.commandCenter
);

const getCommandCenterCurrent = createSelector(
  selectSelf,
  (data) => data.commandCenter.currentState,
);

const getCommandCenterCurrentStatus = createSelector(
  selectSelf,
  (data) => data.commandCenter.currentState?.status,
);

const getCommandCenterCurrentStatusAdd = createSelector(
  selectSelf,
  (data) => data.commandCenter.currentState?.statusAdd,
);

const getCommandCenterNotificationCurrent = createSelector(
  selectSelf,
  (data) => data.commandCenter.notificationCurrent,
);

const getCommandCenterNotificationCurrentIsClearAllFilters = createSelector(
  selectSelf,
  (data) => (data.commandCenter.notificationCurrent?.filters?.isOnlyUnread
    || !data.commandCenter.notificationCurrent?.filters?.filterModule?.disableReset
    || !data.commandCenter.notificationCurrent?.filters?.filterPriority?.disableReset
    || !data.commandCenter.notificationCurrent?.filters?.filterReceivedDates?.disableReset
  ),
);

const getCommandCenterActionCurrentIsClearAllFiltersTasks = createSelector(
  selectSelf,
  (data) => (data.commandCenter.currentState?.filters?.isOnlyUnread
    || !data.commandCenter.currentState?.filters?.filterAssignee?.disableReset
    || !data.commandCenter.currentState?.filters?.filterReceivedDates?.disableReset
    || !data.commandCenter.currentState?.filters?.filterTreeDataObject?.disableReset
    || !data.commandCenter.currentState?.filters?.filterModule?.disableReset
  ),
);

const getCommandCenterActionCurrentIsClearAllFiltersTasksCompleted = createSelector(
  selectSelf,
  (data) => (data.commandCenter.currentState?.filters?.isOnlyUnread
    || !data.commandCenter.currentState?.filters?.filterAssignee?.disableReset
    || !data.commandCenter.currentState?.filters?.filterCompletionDates?.disableReset
    || !data.commandCenter.currentState?.filters?.filterTreeDataObject?.disableReset
    || !data.commandCenter.currentState?.filters?.filterModule?.disableReset
  ),
);

const getCommandCenterActionCurrentIsClearAllFiltersShipmentTasks = createSelector(
  selectSelf,
  (data) => (data.commandCenter.currentState?.filters?.isOnlyUnread
    || !data.commandCenter.currentState?.filters?.filterAssignee?.disableReset
    || !data.commandCenter.currentState?.filters?.filterAssigneeOrganization?.disableReset
    || !data.commandCenter.currentState?.filters?.filterReceivedDates?.disableReset
    || !data.commandCenter.currentState?.filters?.filterModule?.disableReset
  ),
);

const getCommandCenterActionCurrentIsClearAllFiltersShipmentTasksCompleted = createSelector(
  selectSelf,
  (data) => (data.commandCenter.currentState?.filters?.isOnlyUnread
    || !data.commandCenter.currentState?.filters?.filterAssignee?.disableReset
    || !data.commandCenter.currentState?.filters?.filterAssigneeOrganization?.disableReset
    || !data.commandCenter.currentState?.filters?.filterCompletionDates?.disableReset
    || !data.commandCenter.currentState?.filters?.filterModule?.disableReset
  ),
);

const getCommandCenterNotificationCurrentStatus = createSelector(
  selectSelf,
  (data) => data.commandCenter.notificationCurrent?.status,
);

const getCommandCenterIsHaveActiveNotification = createSelector(
  selectSelf,
  (data) => !!(data.commandCenter.notificationCurrent.totalElements),
);

const getCommandCenterIsHaveActiveTasks = createSelector(
  selectSelf,
  (data) => !!(data.commandCenter.currentState.totalElements || data.commandCenter.currentState.totalElementsOverdue),
);

const getCommandCenterNotificationCurrentStatusAdd = createSelector(
  selectSelf,
  (data) => data.commandCenter.notificationCurrent?.statusAdd,
);

const getDatePriority = (date?: number): ENotificationCriticality => {
  if (!date) return ENotificationCriticality.LOW;

  const dateCreate = moment(date);
  const dateNow = moment(Date.now());

  const dateDiff = dateNow.endOf('day').unix() - dateCreate.endOf('day').unix();

  if (dateDiff === 0) {
    return ENotificationCriticality.HIGH;
  }

  if ((dateDiff * -1) < (4 * 24 * 60 * 60)) {
    return ENotificationCriticality.MEDIUM;
  }

  return ENotificationCriticality.LOW;
};

const getTaskModule = (value?: string) => {
  if (value?.indexOf('_AND') !== -1) {
    return value?.replace(/_/g, ' ')?.toLocaleLowerCase();
  }

  return value?.replace(/_/g, ' & ')?.toLocaleLowerCase();
};

const getCommandCenterTasks = (tasks?: INotificationTaskContentDTM[]) => tasks?.map((item) => (CommandCenterComponentTaskDTM.fromPlain({
  id: `${item.id}`,
  customId: item.customId,
  priority: item.criticality,
  title: item.title,
  description: item.description,
  dueDate: item.dueDate,
  datePriority: getDatePriority(item.dueDate?.date?.setMilliseconds(0)),
  dateWarning: false,
  shipmentName: `${item?.metadata?.shipmentName || ''}`,
  shipmentId: item.objectReference?.shipmentId,
  requestId: item.objectReference?.rateRequestId,
  organizationId: item.objectReference?.organizationId,
  rateRequestName: `${item.objectReference?.rateRequestId || ''}`,
  rateOrganizationIdName: `${item.objectReference?.organizationId || ''}`,
  shipmentType: `${item.objectReference?.type || ''}`,
  rateRequestType: 'Request',
  rateOrganizationType: 'Organization',
  taskType: item.metadata?.taskType,
  module: getTaskModule(item?.domain),
  assignee: item.assignee,
  assigneeUsers: item.assigneeUsers,
  actionLink: item?.actions?.find((itemAction) => itemAction.type === 'LINK')?.link || '',
})));

const getCommandCenterCurrentAllTasks = createSelector(
  selectSelf,
  (data) => data.commandCenter.currentState.content?.map((item) => (CommandCenterComponentTaskDTM.fromPlain({
    id: `${item.id}`,
    customId: item.customId,
    priority: item.criticality,
    title: item.title,
    description: item.description,
    dueDate: item.dueDate,
    completedAt: item.completedAt,
    datePriority: item.completedAt ? ENotificationCriticality.LOW : getDatePriority(item.dueDate?.date?.setMilliseconds(0)),
    dateWarning: false,
    shipmentName: `${item?.metadata?.shipmentName || ''}`,
    shipmentId: item.objectReference?.shipmentId,
    requestId: item.objectReference?.rateRequestId,
    organizationId: item.objectReference?.organizationId,
    rateRequestName: `${item.objectReference?.rateRequestId || ''}`,
    rateOrganizationIdName: `${item.objectReference?.organizationId || ''}`,
    shipmentType: `${item.objectReference?.type || ''}`,
    rateRequestType: 'Request',
    rateOrganizationType: 'Organization',
    module: getTaskModule(item?.domain),
    assignee: item.assignee,
    assigneeUsers: item.assigneeUsers,
    actionLink: item?.actions?.find((itemAction) => itemAction.type === 'LINK')?.link || '',
    taskType: item.metadata?.taskType,
  }))),
);

const getCommandCenterCurrentOverdueTasks = createSelector(
  selectSelf,
  (data) => data.commandCenter.currentState.contentOverdue?.map((item) => (CommandCenterComponentTaskDTM.fromPlain({
    id: `${item.id}`,
    customId: item.customId,
    priority: item.criticality,
    title: item.title,
    description: item.description,
    dueDate: item.dueDate,
    datePriority: ENotificationCriticality.HIGH,
    dateWarning: true,
    shipmentName: `${item?.metadata?.shipmentName || ''}`,
    shipmentId: item.objectReference?.shipmentId,
    requestId: item.objectReference?.rateRequestId,
    organizationId: item.objectReference?.organizationId,
    rateRequestName: `${item.objectReference?.rateRequestId || ''}`,
    rateOrganizationIdName: `${item.objectReference?.organizationId || ''}`,
    shipmentType: `${item.objectReference?.type || ''}`,
    rateRequestType: 'Request',
    rateOrganizationType: 'Organization',
    module: getTaskModule(item?.domain),
    assignee: item.assignee,
    assigneeUsers: item.assigneeUsers,
    actionLink: item?.actions?.find((itemAction) => itemAction.type === 'LINK')?.link || '',
    taskType: item.metadata?.taskType,
  }))),
);

const getCommandCenterCurrentTodayTasks = createSelector(
  selectSelf,
  (data) => getCommandCenterTasks(data.commandCenter.currentState.contentToday),
);

const getCommandCenterCurrentThisWeekTasks = createSelector(
  selectSelf,
  (data) => getCommandCenterTasks(data.commandCenter.currentState.contentThisWeek),
);

const getCommandCenterCurrentLaterTasks = createSelector(
  selectSelf,
  (data) => getCommandCenterTasks(data.commandCenter.currentState.contentLater),
);

const getCommandCenterCurrentAllNotifications = createSelector(
  selectSelf,
  (data): CommandCenterComponentTaskDTM[] => data.commandCenter.notificationCurrent.content?.map((item) => CommandCenterComponentTaskDTM.fromPlain({
    id: `${item.id}`,
    customId: item.customId,
    priority: item.criticality,
    title: item.title,
    description: item.description,
    dueDate: item.createdAt,
    datePriority: getDatePriority(item.dueDate?.date?.setMilliseconds(0)),
    dateWarning: false,
    shipmentName: `${item?.metadata?.shipmentName || ''}`,
    shipmentId: item.objectReference?.shipmentId,
    requestId: item.objectReference?.rateRequestId,
    organizationId: item.objectReference?.organizationId,
    rateRequestName: `${item.objectReference?.rateRequestId || ''}`,
    rateOrganizationIdName: `${item.objectReference?.organizationId || ''}`,
    shipmentType: `${item.objectReference?.type || ''}`,
    rateRequestType: 'Request',
    rateOrganizationType: 'Organization',
    module: item?.domain?.replace('_', ' & ')?.toLocaleLowerCase(),
    isViewed: !!item.view,
    viewedId: `${item.viewId || ''}`,
    taskType: item.metadata?.taskType,
  })),
);

export const commandCenterSelectors = {
  getCommandCenter,
  getCommandCenterCurrent,
  getCommandCenterCurrentStatus,
  getCommandCenterCurrentStatusAdd,
  getCommandCenterNotificationCurrent,
  getCommandCenterNotificationCurrentIsClearAllFilters,
  getCommandCenterActionCurrentIsClearAllFiltersTasks,
  getCommandCenterActionCurrentIsClearAllFiltersTasksCompleted,
  getCommandCenterActionCurrentIsClearAllFiltersShipmentTasks,
  getCommandCenterActionCurrentIsClearAllFiltersShipmentTasksCompleted,
  getCommandCenterNotificationCurrentStatus,
  getCommandCenterIsHaveActiveTasks,
  getCommandCenterIsHaveActiveNotification,
  getCommandCenterNotificationCurrentStatusAdd,
  getCommandCenterCurrentAllTasks,
  getCommandCenterCurrentOverdueTasks,
  getCommandCenterCurrentTodayTasks,
  getCommandCenterCurrentThisWeekTasks,
  getCommandCenterCurrentLaterTasks,
  getCommandCenterCurrentAllNotifications,
};
