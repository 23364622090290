import Intercom from '@intercom/messenger-js-sdk';
import { useSelector } from 'react-redux';
import { R } from 'authentication/repository';
import { useEffect } from 'react';

export const useIntercom = () => {
  const {
    email, firstName, lastName, userId,
  } = useSelector(R.selectors.auth.getUser);

  useEffect(() => {
    // @ts-ignore only for e2e test
    if (process.env.NODE_ENV === 'production' && !window.Cypress) {
      Intercom({
        app_id: 'peknjinv',
        user_id: userId,
        name: `${firstName} ${lastName}`,
        email,
        created_at: new Date().getTime(),
        custom_launcher_selector: '#intercom_id_hook',
        hide_default_launcher: true,
      });
    }
  }, [userId]);
};
