import {
  IsOptional, IsEnum, IsString, IsDefined, ValidateNested, IsNumber, IsBoolean, IsObject,
} from 'class-validator';
import { plainToInstance, Type } from 'class-transformer';
import { UploadFileStatus } from 'antd/lib/upload/interface';

import { IDocumentDTM, DocumentDTM } from 'app-wrapper/models/dtm';
import { IMOClassType } from 'app-wrapper/types/IMOClassType';
import { PackageType } from 'app-wrapper/types/PackageType';
import { IValidationErrorDTM, ValidationErrorDTM } from 'app-wrapper/types';
import { BaseDTM } from 'proto/BaseDTM';

import { CargoReferenceType, PackingGroupType } from 'shipment-operations/constants';

export type HsCodeValidationStatus = 'REQUEST_NOT_SENT' | 'REQUEST_SENT_AND_VALID' | 'REQUEST_SENT_AND_ERROR'

interface IMSDSDocumentDTM {
  uid: string
  response: IDocumentDTM
  name: string
  url: string
  status: UploadFileStatus
}

export class MSDSDocumentDTM extends BaseDTM<IMSDSDocumentDTM> {
  @IsDefined()
  @IsString()
  uid: string

  @IsDefined()
  @ValidateNested()
  @Type(() => DocumentDTM)
  response: DocumentDTM

  @IsDefined()
  @IsString()
  name: string

  @IsDefined()
  @IsString()
  url: string

  @IsDefined()
  @IsString()
  status: UploadFileStatus
}

export interface IHazmatDTM {
  contactName?: string
  contactNumber?: string
  imoClass?: IMOClassType
  unNumber?: string
  packingGroup?: PackingGroupType
  shippingName?: string
  msdsDocument: IMSDSDocumentDTM[]
}

export class HazmatDTM<T extends IHazmatDTM = IHazmatDTM> extends BaseDTM<T> {
  @IsOptional()
  @IsString()
  contactName: string

  @IsOptional()
  @IsString()
  contactNumber: string

  @IsOptional()
  @IsEnum(IMOClassType)
  imoClass?: IMOClassType

  @IsOptional()
  @IsString()
  unNumber?: string

  @IsOptional()
  @IsEnum(PackingGroupType)
  packingGroup?: PackingGroupType

  @IsOptional()
  @IsString()
  shippingName?: string

  @IsDefined()
  @ValidateNested()
  @Type(() => MSDSDocumentDTM)
  msdsDocument: MSDSDocumentDTM[]
}

export interface ICargoReferenceDTM { // TODO: should be fixed  to IReference
  id: string
  type?: CargoReferenceType
  value?: string
}

export class CargoReferenceDTM extends BaseDTM<ICargoReferenceDTM> {
  @IsString()
  @IsDefined()
  id: string

  @IsString()
  @IsOptional()
  value?: string

  @IsOptional()
  @IsEnum(CargoReferenceType)
  type?: CargoReferenceType
}

export interface ILoadSummaryDTM {
  packagesNumber: number
  volume: number
  weight: number
}

class LoadSummaryDTM extends BaseDTM<ILoadSummaryDTM> {
  @IsDefined()
  @IsNumber()
  packagesNumber: number

  @IsDefined()
  @IsNumber()
  volume: number

  @IsDefined()
  @IsNumber()
  weight: number
}

export interface ICargoBaseDTM extends IHazmatDTM {
  baseId?: string // TODO: will be used instead of ICargoDTM id, is equal with cargoId
  references: ICargoReferenceDTM[]
  code?: string
  name?: string
  description?: string
  value?: string
  packageType?: PackageType
  packagesNumber?: string
  weight?: string
  volume?: string
  marks?: string
  loadSummary: ILoadSummaryDTM
}

export class CargoBaseDTM extends HazmatDTM<ICargoBaseDTM> {
  @IsOptional()
  @IsString()
  baseId?: string

  @IsDefined()
  @ValidateNested()
  @Type(() => CargoReferenceDTM)
  references: CargoReferenceDTM[]

  @IsOptional()
  @IsString()
  code?: string

  @IsOptional()
  @IsString()
  name?: string

  @IsOptional()
  @IsString()
  description?: string

  @IsOptional()
  @IsString()
  value?: string

  @IsOptional()
  @IsEnum(PackageType)
  packageType?: PackageType

  @IsOptional()
  @IsString()
  packagesNumber?: string

  @IsOptional()
  @IsString()
  weight?: string

  @IsOptional()
  @IsString()
  volume?: string

  @IsOptional()
  @IsString()
  marks?: string

  @IsDefined()
  @ValidateNested()
  @Type(() => LoadSummaryDTM)
  loadSummary: LoadSummaryDTM

  static createEmpty = () => plainToInstance(CargoBaseDTM, {
    references: [{ id: '0' }],
    msdsDocument: [],
    loadSummary: {
      packagesNumber: 0,
      volume: 0,
      weight: 0,
    },
    code: '',
    contactName: '',
    contactNumber: '',
    description: '',
    imoClass: '',
    marks: '',
    name: '',
    packageType: '',
    packagesNumber: '',
    packingGroup: '',
    shippingName: '',
    unNumber: '',
    value: '',
    volume: '',
    weight: '',
  });
}

export interface ICargoReferenceErrorsDTM {
  type?: IValidationErrorDTM
  value?: IValidationErrorDTM
}

export class CargoReferenceErrorsDTM extends BaseDTM<ICargoReferenceErrorsDTM> {
  @IsOptional()
  @ValidateNested()
  @Type(() => ValidationErrorDTM)
  type?: ValidationErrorDTM

  @IsOptional()
  @ValidateNested()
  @Type(() => ValidationErrorDTM)
  value?: ValidationErrorDTM
}

export interface ICargoErrorsDTM {
  references?: ICargoReferenceErrorsDTM[]
  imoClass?: IValidationErrorDTM
  unNumber?: IValidationErrorDTM
  packingGroup?: IValidationErrorDTM
  shippingName?: IValidationErrorDTM
  msdsDocument?: IValidationErrorDTM
  code?: IValidationErrorDTM
  name?: IValidationErrorDTM
  packageType?: IValidationErrorDTM
  packagesNumber?: IValidationErrorDTM
  weight?: IValidationErrorDTM
}

export class CargoErrorsDTM extends BaseDTM<ICargoErrorsDTM> {
  @IsOptional()
  @ValidateNested()
  @Type(() => CargoReferenceErrorsDTM)
  references?: CargoReferenceErrorsDTM[]

  @IsOptional()
  @ValidateNested()
  @Type(() => ValidationErrorDTM)
  imoClass?: ValidationErrorDTM

  @IsOptional()
  @ValidateNested()
  @Type(() => ValidationErrorDTM)
  unNumber?: ValidationErrorDTM

  @IsOptional()
  @ValidateNested()
  @Type(() => ValidationErrorDTM)
  packingGroup?: ValidationErrorDTM

  @IsOptional()
  @ValidateNested()
  @Type(() => ValidationErrorDTM)
  shippingName?: ValidationErrorDTM

  @IsOptional()
  @ValidateNested()
  @Type(() => ValidationErrorDTM)
  msdsDocument?: ValidationErrorDTM

  @IsOptional()
  @ValidateNested()
  @Type(() => ValidationErrorDTM)
  code?: ValidationErrorDTM

  @IsOptional()
  @ValidateNested()
  @Type(() => ValidationErrorDTM)
  name?: ValidationErrorDTM

  @IsOptional()
  @ValidateNested()
  @Type(() => ValidationErrorDTM)
  packageType?: ValidationErrorDTM

  @IsOptional()
  @ValidateNested()
  @Type(() => ValidationErrorDTM)
  packagesNumber?: ValidationErrorDTM

  @IsOptional()
  @ValidateNested()
  @Type(() => ValidationErrorDTM)
  weight?: ValidationErrorDTM

  static createEmpty = () => plainToInstance(CargoErrorsDTM, {});

  public hasErrors = () => !!(
    (this.references ? this.references?.filter(({ type, value }) => type || value).length : 0) > 0
    || this.imoClass
    || this.unNumber
    || this.packingGroup
    || this.shippingName
    || this.msdsDocument
    || this.code
    || this.name
    || this.packageType
    || this.packagesNumber
    || this.weight
  )
}

export interface ICargoDTM extends ICargoBaseDTM {
  renderId: string
  isHazmat: boolean
  isHazmatCollapseOpen: boolean
  errors: ICargoErrorsDTM
  hsCodeValidationStatus: HsCodeValidationStatus
  touchedFields: { [key: string]: boolean }
  initialState: ICargoBaseDTM
  wasUpdateAttempted: boolean
  id?: number
}

export class CargoDTM extends BaseDTM<ICargoDTM> {
  @IsDefined()
  @IsString()
  renderId: string

  @IsDefined()
  @IsBoolean()
  isHazmat: boolean

  @IsDefined()
  @IsBoolean()
  isHazmatCollapseOpen: boolean

  @IsDefined()
  @ValidateNested()
  @Type(() => CargoErrorsDTM)
  errors: CargoErrorsDTM

  @IsDefined()
  @IsString()
  hsCodeValidationStatus: HsCodeValidationStatus

  @IsObject()
  touchedFields: {
    [key: string]: boolean
  }

  @IsDefined()
  @ValidateNested()
  @Type(() => CargoBaseDTM)
  initialState: CargoBaseDTM

  @IsDefined()
  @IsBoolean()
  wasUpdateAttempted: boolean

  @IsOptional()
  @IsNumber()
  id?: number

  @IsOptional()
  @IsString()
  baseId?: string

  @IsDefined()
  @ValidateNested()
  @Type(() => CargoReferenceDTM)
  references: CargoReferenceDTM[]

  @IsOptional()
  @IsString()
  code?: string

  @IsOptional()
  @IsString()
  name?: string

  @IsOptional()
  @IsString()
  description?: string

  @IsOptional()
  @IsString()
  value?: string

  @IsOptional()
  @IsEnum(PackageType)
  packageType?: PackageType

  @IsOptional()
  @IsString()
  packagesNumber?: string

  @IsOptional()
  @IsString()
  weight?: string

  @IsOptional()
  @IsString()
  volume?: string

  @IsOptional()
  @IsString()
  marks?: string

  @IsDefined()
  @ValidateNested()
  @Type(() => LoadSummaryDTM)
  loadSummary: LoadSummaryDTM

  @IsOptional()
  @IsString()
  contactName: string

  @IsOptional()
  @IsString()
  contactNumber: string

  @IsOptional()
  @IsEnum(IMOClassType)
  imoClass?: IMOClassType

  @IsOptional()
  @IsString()
  unNumber?: string

  @IsOptional()
  @IsEnum(PackingGroupType)
  packingGroup?: PackingGroupType

  @IsOptional()
  @IsString()
  shippingName?: string

  @IsDefined()
  @ValidateNested()
  @Type(() => MSDSDocumentDTM)
  msdsDocument: MSDSDocumentDTM[]
}

export interface IContainerCargoDTM extends ICargoBaseDTM {
  id: string // is equal with container cargo item id ( not cargoId! )
  packagesNumberValue: string
  packagesNumberValueError?: Error
  weightValue: string
  weightValueError?: Error
  volumeValue: string
  volumeValueError?: Error
}

export class ContainerCargoDTM extends BaseDTM<IContainerCargoDTM> {
  @IsDefined()
  @IsString()
  id: string

  @IsDefined()
  @IsString()
  packagesNumberValue: string

  @IsOptional()
  @IsObject()
  packagesNumberValueError?: Error

  @IsDefined()
  @IsString()
  weightValue: string

  @IsOptional()
  @IsObject()
  weightValueError?: Error

  @IsDefined()
  @IsString()
  volumeValue: string

  @IsOptional()
  @IsObject()
  volumeValueError?: Error

  @IsOptional()
  @IsString()
  baseId?: string

  @IsDefined()
  @ValidateNested()
  @Type(() => CargoReferenceDTM)
  references: CargoReferenceDTM[]

  @IsOptional()
  @IsString()
  code?: string

  @IsOptional()
  @IsString()
  name?: string

  @IsOptional()
  @IsString()
  description?: string

  @IsOptional()
  @IsString()
  value?: string

  @IsOptional()
  @IsEnum(PackageType)
  packageType?: PackageType

  @IsOptional()
  @IsString()
  packagesNumber?: string

  @IsOptional()
  @IsString()
  weight?: string

  @IsOptional()
  @IsString()
  volume?: string

  @IsOptional()
  @IsString()
  marks?: string

  @IsDefined()
  @ValidateNested()
  @Type(() => LoadSummaryDTM)
  loadSummary: LoadSummaryDTM

  @IsOptional()
  @IsString()
  contactName: string

  @IsOptional()
  @IsString()
  contactNumber: string

  @IsOptional()
  @IsEnum(IMOClassType)
  imoClass?: IMOClassType

  @IsOptional()
  @IsString()
  unNumber?: string

  @IsOptional()
  @IsEnum(PackingGroupType)
  packingGroup?: PackingGroupType

  @IsOptional()
  @IsString()
  shippingName?: string

  @IsDefined()
  @ValidateNested()
  @Type(() => MSDSDocumentDTM)
  msdsDocument: MSDSDocumentDTM[]
}
