import styled from 'styled-components';
import { ActiveAnimation } from 'app-wrapper/view/components/Skeleton';

export const Wrapper = styled.div`
  padding: 16px;
  background-color: white;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const RowItem = styled.div.attrs((props: {
  width: string,
}) => ({
  width: props.width,
}))`
  display: flex;
  align-items: center;
  width: ${(props) => props.width};
  margin: 15px 0;
`;

export const Paragraph = styled.div.attrs((props: {
  width: string,
}) => ({
  width: props.width,
}))`
  height: 16px;
  background-color: #F4F5F8;
  width: ${(props) => props.width};
  ${ActiveAnimation};
`;

export const Separator = styled.div`
  height: 1px;
  width: 100%;
  background-color: #F0F2FC;
  margin: 16px 0;
`;
