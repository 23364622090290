import React, { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import momentTimezone, { Moment } from 'moment-timezone';

import {
  RFQServiceByIdContentRoutesTransportation,
} from 'monetary/models/dtm/Quotas';
import { RouteLegDTM } from 'shipment-operations/models/dtm';
import { DateDtm } from 'app-wrapper/models/dtm';
import { Tooltip } from 'app-wrapper/view/components';

import {
  Wrapper,
  Text,
  DatePickerStyled,
  DatePickerError,
} from './DatePicker.styled';

interface DatePickerComponentProps {
  type: 'ETA' | 'ETD';
  transportation: RFQServiceByIdContentRoutesTransportation;
  leg: RouteLegDTM;
  setETDDate: (utcDate: string, transportationId: number) => void;
  setETADate: (utcDate: string, transportationId: number) => void;
  errorETD: boolean;
  errorETA: boolean;
}

export const DatePickerComponent: FC<DatePickerComponentProps> = ({
  transportation,
  type,
  leg,
  setETADate,
  setETDDate,
  errorETA,
  errorETD,
}) => {
  const { t } = useTranslation();

  const label = useMemo(() => (type === 'ETA' ? t('ETA') : t('ETD')), [type, t]);

  const getDateWithDateShift = useCallback((timeZoneId: string, dateString: string) => {
    const date = DateDtm.fromPlain({
      date: dateString,
      offset: momentTimezone().tz(timeZoneId) ? momentTimezone().tz(timeZoneId).utcOffset() : 0,
    });

    return date;
  }, []);

  const dateValue = useMemo(() => {
    const { carrierArrivalTime, carrierDepartureTime } = transportation.schedule;

    return type === 'ETD' ? getDateWithDateShift(leg.departureLocation.timeZoneId || '', carrierDepartureTime) : getDateWithDateShift(leg.arrivalLocation.timeZoneId || '', carrierArrivalTime);
  }, [transportation, leg, type]);

  const handleDateChange = useCallback((date: Moment | null) => {
    if (!date) {
      return;
    }

    if (type === 'ETD') {
      setETDDate(date?.format(), transportation.id);
      return;
    }

    setETADate(date?.format(), transportation.id);
  }, [type, setETADate, setETDDate, transportation]);

  const error = useMemo(() => (type === 'ETA' ? errorETA : errorETD), [errorETA, errorETD, type]);

  const dataTestID = useMemo(() => `datepicker-${transportation.id}-${type}-testId`, [type, transportation]);

  return (
    <Wrapper>
      <Text>
        {label}
      </Text>

      {error ? (
        <Tooltip
          mode="danger"
          placement="top"
          title={t('This date must not be after the next transport leg point')}
        >
          <DatePickerError
            data-testid={dataTestID}
            value={dateValue.getDateAsMomentWithOffset()}
            onChange={handleDateChange}
            format="D MMM YYYY, HH:mm"
            allowClear={false}
            showTime
          />
        </Tooltip>
      ) : (
        <DatePickerStyled
          data-testid={dataTestID}
          value={dateValue.getDateAsMomentWithOffset()}
          onChange={handleDateChange}
          format="D MMM YYYY, HH:mm"
          allowClear={false}
          showTime
        />
      )}
    </Wrapper>
  );
};
