import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Status, EStatusType } from 'app-wrapper/view/guideline';
import { Title } from 'user-management/view/pages/CustomerSales/components/SalesRepresentative/SalesRepresentative.styled';

import { UploadMarkup, MarkupPolicyResult } from './components';
import { Content, Header } from './MarkupPolicy.styled';

interface IMarkupPolicyComponentProps {
  hasMarkupPolicy: boolean;
}

export const MarkupPolicyComponent: FC<IMarkupPolicyComponentProps> = ({ hasMarkupPolicy }) => {
  const { t } = useTranslation();

  return (
    <Content>
      <Header>
        <Title>
          {t('Manually Upload Markup Policy')}
        </Title>

        <Status
          text={hasMarkupPolicy ? t('Markup set') : t('Markup not set')}
          type={hasMarkupPolicy ? EStatusType.COMPLETED : EStatusType.NOT_ACTIVE}
        />
      </Header>

      <UploadMarkup />
      <MarkupPolicyResult />
    </Content>
  );
};
