import * as yup from 'yup';
import i18n from 'i18next';

yup.addMethod(yup.string, 'portFormat', function isValidPort(translation?: string) {
  return this.matches(/^[A-Z0-9]{5}$/, {
    message: translation || i18n.t('Validations.string.portFormat'),
    excludeEmptyString: true,
  });
});

declare module 'yup' {
  interface StringSchema {
    portFormat(translation?: string): StringSchema;
  }
}
