import React from 'react';

import { useAppSelector } from 'app-wrapper/hooks';

import { R } from 'shipment-operations/repository';

import { MainComponent } from './Main.component';

const MainContainer = () => {
  const data = useAppSelector(R.selectors.makePayment.getDataForPaymentEditTable);
  const totalAmount = useAppSelector(R.selectors.makePayment.getEditedTotalAmount);
  const usedAmount = useAppSelector(R.selectors.makePayment.getEditSumTransactions);
  const leftAmount = useAppSelector(R.selectors.makePayment.getEditAmountLeft);

  return (
    <MainComponent
      data={data}
      totalAmount={totalAmount}
      usedAmount={usedAmount}
      leftAmount={leftAmount}
    />
  );
};

export { MainContainer as Main };
