import React from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';
import { UC } from 'shipment-operations/controllers';
import { NRAAgreementComponent } from 'app-wrapper/view/drawers/AgreementsDrawer/components/NRAAgreement';

const NRAAgreementContainer = () => {
  const isChecked = useSelector(R.selectors.overview.getIsNRAConfirmed);

  return (
    <NRAAgreementComponent isChecked={isChecked} onChange={UC.overview.setIsNRAConfirmed} />
  );
};

export { NRAAgreementContainer as NRAAgreement };
