import React, {
  ChangeEvent,
  FC,
  useCallback,
  useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';

import { Option } from 'app-wrapper/view/components';
import {
  AirVentUnitsType,
  AirVentUnitsNamesConst,
  PermissionAttributePolicy,
} from 'shipment-operations/constants';

import {
  AirVentFieldWrapper,
  AirVentInput,
  AirVentLabel,
  AirVentSelect,
} from './AirVentField.styled';

interface IAirVentFieldComponentProps {
  flowRate?: string
  flowRateError: boolean
  flowRateUnit?: AirVentUnitsType
  flowRateUnitError: boolean
  setFlowRate: (flowRate?: string) => void
  setFlowRateUnit: (flowRateUnit?: AirVentUnitsType) => void
  touchField: (field: string) => void
  containersAvailability?: PermissionAttributePolicy
}

export const AirVentFieldComponent: FC<IAirVentFieldComponentProps> = ({
  flowRate,
  flowRateError,
  flowRateUnit,
  flowRateUnitError,
  setFlowRate,
  setFlowRateUnit,
  touchField,
  containersAvailability,
}) => {
  const { t } = useTranslation();

  const handleSetFlowRate = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setFlowRate(event.target.value);
  }, []);

  const handleBlurFlowRate = useCallback(() => {
    touchField('flowRate');
  }, []);

  const handleBlurFlowRateUnit = useCallback(() => {
    touchField('flowRateUnit');
  }, []);

  const isDisabled = useMemo(() => containersAvailability !== PermissionAttributePolicy.WRITE, [containersAvailability]);

  return (
    <AirVentFieldWrapper>
      <AirVentLabel>{t('Air Vent Settings')}</AirVentLabel>
      <AirVentInput
        type="number"
        error={flowRateError}
        value={flowRate}
        onChange={handleSetFlowRate}
        onBlur={handleBlurFlowRate}
        disabled={isDisabled}
      />
      <AirVentSelect
        allowClear={!!flowRateUnit}
        error={flowRateUnitError}
        value={flowRateUnit}
        onChange={setFlowRateUnit}
        onBlur={handleBlurFlowRateUnit}
        disabled={isDisabled}
      >
        <Option value={AirVentUnitsType.CUBIC_METERS_PER_HOUR}>{AirVentUnitsNamesConst[AirVentUnitsType.CUBIC_METERS_PER_HOUR]}</Option>
      </AirVentSelect>
    </AirVentFieldWrapper>
  );
};
