import styled from 'styled-components';

import { Wrapper } from 'app-wrapper/view/guideline';

export const Page = styled(Wrapper)`
  padding: 8px 24px;
`;

export const Title = styled.span`
  font-size: 20px;
  line-height: 28px;
  font-weight: 500;
  margin-bottom: 14px;
`;
