import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import filter from 'lodash/fp/filter';
import isEmpty from 'lodash/fp/isEmpty';

import { InvoicesChargeDTM } from 'shipment-operations/models/dtm';
import {
  CustomCell, StyledTable, StyledSection, TableHeader,
} from 'shipment-operations/view/components/CustomTable/CustomTable.styled';
import { prepareDataForEditableTable } from 'shipment-operations/view/pages/ShipmentBillingInvoice/utils/prepareDataForTable';

import { EditableCell } from 'shipment-operations/view/pages/CreateCreditNote/components/SecondStep/components/CustomTableEditable/components';
import { EditableRow } from 'shipment-operations/view/components';

import { useTableEditable } from './useTableEditable';

interface ICustomTableEditableProps {
  data: InvoicesChargeDTM[]
}

const CustomTableEditable: FC<ICustomTableEditableProps> = ({ data }) => {
  const { t } = useTranslation();

  const originData = useMemo(() => {
    const filtered = filter((item) => item.charge.designation === 'ORIGIN', data);
    return prepareDataForEditableTable(filtered);
  }, [data]);

  const freightData = useMemo(() => {
    const filtered = filter((item) => item.charge.designation === 'FREIGHT', data);
    return prepareDataForEditableTable(filtered);
  }, [data]);

  const destinationData = useMemo(() => {
    const filtered = filter((item) => item.charge.designation === 'DESTINATION', data);
    return prepareDataForEditableTable(filtered);
  }, [data]);

  const defaultColumns = useMemo(() => [
    {
      title: t('createInvoiceTable.service'),
      dataIndex: 'service',
      key: 'service',
      width: '40%',
      render: (title: string) => <CustomCell>{title}</CustomCell>,
    },
    {
      title: t('createInvoiceTable.total'),
      dataIndex: 'total',
      key: 'total',
      align: 'right' as 'right',
      width: '20%',
      render: (sum: number) => `$ ${sum}`,
    },
    {
      title: 'Balance',
      dataIndex: 'invoiced',
      key: 'invoiced',
      align: 'right' as 'right',
      width: '20%',
      render: (sum: number) => `$ ${sum}`,
    },
    {
      title: 'Adjustment',
      dataIndex: 'adjustment',
      key: 'adjustment',
      align: 'right' as 'right',
      width: '14%',
      editable: true,
    },
  ], [t]);

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const {
    dataSource: originDataSource,
    columns: originColumns,
  } = useTableEditable(originData, defaultColumns);
  const {
    dataSource: freightDataSource,
    columns: freightColumns,
  } = useTableEditable(freightData, defaultColumns);
  const {
    dataSource: destinationDataSource,
    columns: destinationColumns,
  } = useTableEditable(destinationData, defaultColumns);

  return (
    <>
      <TableHeader columns={defaultColumns} />
      {!isEmpty(originDataSource) && (
        <>
          <StyledSection>{t('Origin')}</StyledSection>
          <StyledTable
            components={components}
            pagination={false}
            showHeader={false}
            dataSource={originDataSource}
            columns={originColumns}
          />
        </>
      )}
      {!isEmpty(freightDataSource) && (
        <>
          <StyledSection>{t('Freight')}</StyledSection>
          <StyledTable
            components={components}
            pagination={false}
            showHeader={false}
            dataSource={freightDataSource}
            columns={freightColumns}
          />
        </>
      )}
      {!isEmpty(destinationData) && (
        <>
          <StyledSection>{t('Destination')}</StyledSection>
          <StyledTable
            components={components}
            pagination={false}
            showHeader={false}
            dataSource={destinationDataSource}
            columns={destinationColumns}
          />
        </>
      )}
    </>
  );
};

export { CustomTableEditable };
