import styled from 'styled-components';

export const CompanyLogoContainer = styled.div.attrs({
  'data-class': 'CompanyLogoContainer',
})`
  width: 27%;
  height: 100%;
`;

export const CompanyLogo = styled.div`
  width: 196px;
  height: 75px;

  svg {
    width: 100%;
    height: 100%;
  }
`;
