import { BaseController, controller } from 'proto/BaseController';

import { R } from 'user-management/repository';
import { OrganizationDTM, CustomerDocumentsMap } from 'user-management/models/dtm';
import { DocumentType } from 'shipment-operations/constants';
import { ContainerDocumentDTM, DocumentDTM } from 'shipment-operations/models/dtm';
import { UC as shipmentOperationsUC } from 'shipment-operations/controllers';
import { RouteNames } from 'app-wrapper/constants';

@controller
export class NewOrganizationDocumentsController extends BaseController {
  public initData = async () => {
    this.dispatch(R.actions.newOrganizationDocuments.setLoading(true));

    await this.fetchOrganizationDocuments();

    this.dispatch(R.actions.newOrganizationDocuments.setLoading(false));
  };

  public fetchOrganizationDocuments = async () => {
    let organization: OrganizationDTM | null = null;

    try {
      organization = await R.services.organization.getCurrentOrganization();
    } catch (e) {
      console.error('CustomerDocumentsController ERROR: getOrganization');
    }

    if (organization?.documents) {
      this.dispatch(R.actions.newOrganizationDocuments.setOrganizationType(organization?.type));
      this.dispatch(R.actions.newOrganizationDocuments.setOrganizationId(organization?.id));
      this.dispatch(R.actions.newOrganizationDocuments.setDocuments(organization?.documents));
      this.dispatch(R.actions.newOrganizationDocuments.setDocumentsTypeMap(this.formatDocumentsTypesMap(organization?.documents)));
      this.dispatch(R.actions.newOrganizationWizardSteps.setPercent(this.calculateStepProcessPercentage()));
    }
  };

  public setDocumentToUpload = (document: ContainerDocumentDTM | null) => {
    this.dispatch(R.actions.newOrganizationDocuments.setDocumentToUpload(document));
  };

  public downloadDocumentToUpload = (organizationId: string | number) => {
    const document = R.selectors.customer.getDocumentToUpload(this.store.getState());

    if (document) {
      this.downloadDocument(organizationId, document?.response.id, document?.response.name);
    }
  };

  public downloadDocumentById = async (organizationId: string | number, documentId: number) => {
    const document = R.selectors.customer.getDocumentById(documentId)(this.store.getState());

    if (document) {
      try {
        R.services.organizationDocuments.downloadOrganizationDocument(organizationId, document.id, document.name);
      } catch (e) {
        console.error('CustomerDocumentsController CONTROLLER: downloadDocument');
      }
    }
  }

  public downloadDocument = async (organizationId: string | number, documentId: number, documentName: string) => {
    try {
      R.services.organizationDocuments.downloadOrganizationDocument(organizationId, documentId, documentName);
    } catch (e) {
      console.error('CustomerDocumentsController CONTROLLER: downloadDocument');
    }
  }

  public addDocumentsToRemoveId = (documentId: number) => {
    this.dispatch(R.actions.newOrganizationDocuments.addDocumentToRemoveId(documentId));
  };

  public deleteDocumentWithUpdate = async (organizationId: string | number, documentId: number) => {
    await this.deleteDocument(organizationId, documentId);
    await this.fetchOrganizationDocuments();

    this.dispatch(R.actions.newOrganizationWizardSteps.setPercent(this.calculateStepProcessPercentage()));
  }

  public deleteDocument = async (organizationId: string | number, documentId: number) => {
    try {
      await R.services.organizationDocuments.deleteDocument(organizationId, documentId);
    } catch (e) {
      console.error('CustomerDocumentsController error: deleteDocument');
    }
  }

  public cleanupDeletedDocuments = async () => {
    const organizationId = R.selectors.newOrganizationDocuments.getOrganizationId(this.store.getState());
    const documentsToDeleteIds = R.selectors.newOrganizationDocuments.getDocumentsToDeleteIds(this.store.getState());

    this.dispatch(R.actions.newOrganizationDocuments.setIsUploadLoading(true));

    await Promise.all(documentsToDeleteIds.map((id) => this.deleteDocument(organizationId, id)));

    this.dispatch(R.actions.newOrganizationDocuments.clearDocumentsToRemoveIds());

    this.dispatch(R.actions.newOrganizationDocuments.setIsUploadLoading(false));
  };

  public cancelDocumentUpload = async () => {
    await this.cleanupDeletedDocuments();

    shipmentOperationsUC.shipmentDrawer.closeDrawer();
  }

  public saveUploadedDocument = async () => {
    this.dispatch(R.actions.newOrganizationDocuments.setIsUploadLoading(true));

    const documentToUpload = R.selectors.newOrganizationDocuments.getDocumentToUpload(this.store.getState());

    if (documentToUpload?.response?.id) {
      const documentsToDeleteIds = [...R.selectors.newOrganizationDocuments.getDocumentsToDeleteIds(this.store.getState())];

      const targetIdIndex = documentsToDeleteIds.findIndex((id) => id === documentToUpload?.response?.id);
      documentsToDeleteIds.splice(targetIdIndex, 1);

      this.dispatch(R.actions.newOrganizationDocuments.setDocumentsToRemoveIds(documentsToDeleteIds));
      await this.cleanupDeletedDocuments();
    }

    await this.fetchOrganizationDocuments();
    this.dispatch(R.actions.newOrganizationWizardSteps.setPercent(this.calculateStepProcessPercentage()));

    this.dispatch(R.actions.newOrganizationDocuments.setIsRequiredWarningShown(false));
    this.dispatch(R.actions.newOrganizationDocuments.setIsUploadLoading(false));
    shipmentOperationsUC.shipmentDrawer.closeDrawer();
  }

  public goNextStep = () => {
    const documents = R.selectors.newOrganizationDocuments.getDocuments(this.store.getState());
    const shouldRequireBLICDocument = R.selectors.newOrganizationDocuments.getShouldRequireBLICDocument(this.store.getState());
    const hasBLICDocuments = !!documents.filter((document) => document.type === DocumentType.BLIC).length;
    const hasALODocuments = !!documents.filter((document) => document.type === DocumentType.ALO).length;
    const hasCCIDocuments = !!documents.filter((document) => document.type === DocumentType.CCI).length;
    const hasTCERDocuments = !!documents.filter((document) => document.type === DocumentType.TCER).length;

    if (!hasALODocuments || !hasCCIDocuments || !hasTCERDocuments || (shouldRequireBLICDocument && !hasBLICDocuments)) {
      this.dispatch(R.actions.newOrganizationDocuments.setIsRequiredWarningShown(true));

      return;
    }

    this.navigate(RouteNames.NEW_ORGANIZATION_REVIEW_AND_CONFIRM_WIZARD_ITEM());
  }

  public goPrevStep = () => {
    this.navigate(RouteNames.ADD_COMPANY_DETAILS_WIZARD_ITEM());
  }

  public cancelWizard = () => {
    this.navigate(RouteNames.OVERVIEW());
  }

  private formatDocumentsTypesMap = (documents: DocumentDTM[]) => {
    const documentsTypesMap: CustomerDocumentsMap = {
      [DocumentType.ALO]: documents.filter((document) => document.type === DocumentType.ALO),
      [DocumentType.CCI]: documents.filter((document) => document.type === DocumentType.CCI),
      [DocumentType.BLIC]: documents.filter((document) => document.type === DocumentType.BLIC),
      [DocumentType.TCER]: documents.filter((document) => document.type === DocumentType.TCER),
      [DocumentType.MISC]: documents.filter((document) => document.type === DocumentType.MISC),
    };

    return documentsTypesMap;
  };

  private calculateStepProcessPercentage = () => {
    const documents = R.selectors.newOrganizationDocuments.getDocuments(this.store.getState());
    let totalPercentage = 0;

    if (documents.some((document) => document.type === DocumentType.ALO)) {
      totalPercentage += 20;
    }
    if (documents.some((document) => document.type === DocumentType.CCI)) {
      totalPercentage += 20;
    }
    if (documents.some((document) => document.type === DocumentType.BLIC)) {
      totalPercentage += 20;
    }
    if (documents.some((document) => document.type === DocumentType.TCER)) {
      totalPercentage += 20;
    }
    if (documents.some((document) => document.type === DocumentType.MISC)) {
      totalPercentage += 20;
    }

    return totalPercentage;
  };
}
