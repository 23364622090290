import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { OrganizationDocumentsNames } from 'shipment-operations/constants';

import {
  Wrapper,
  Title,
  Select,
  Subtitle,
  Spinner,
} from './OrganizationDocumentUpload.styled';
import { Footer, DocumentUpload } from './components';

interface OrganizationDocumentUploadComponentProps {
  documentType: string;
  isLoading: boolean;
}

export const OrganizationDocumentUploadComponent: FC<OrganizationDocumentUploadComponentProps> = ({ documentType, isLoading }) => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      {isLoading ? (
        <Spinner size="large" />
      ) : (
        <>
          <Title>
            {t('Organization Documents')}
          </Title>
          <Subtitle>
            {t('Document Type')}
          </Subtitle>
          <Select
            optionsData={OrganizationDocumentsNames}
            allowClear
            value={documentType}
            placeholder={t('SelectDocumentType')}
            disabled
          />
          <Subtitle>
            {t('Document')}
          </Subtitle>
          <DocumentUpload />
          <Footer />
        </>
      )}
    </Wrapper>
  );
};
