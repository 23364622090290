import { BaseRepository } from 'proto/BaseRepository';
import { OrganizationDTM } from 'user-management/models/dtm';
import { R as userManagementR } from 'user-management/repository';

export class OrganizationRepository extends BaseRepository<OrganizationDTM> {
  async getRequest() {
    const result = await userManagementR.services.organization.getCurrentOrganization(true);

    return result;
  }

  getStore() {
    return this.store.getState().userOrganizationData.organization;
  }
}
