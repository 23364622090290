import { createSlice } from 'app-wrapper/createSlice';
import reduce from 'lodash/fp/reduce';

import { RootState } from 'app-wrapper/store';
import { asyncThunkGet } from 'app-wrapper/utils';
import ShipmentPartyRole from 'app-wrapper/types/ShipmentPartyRole';

export interface ShipmentPartyRolesState {
  status: string;
  shipmentPartyRoles: ShipmentPartyRole[];
  shipmentPartyRoleByName: {};
}

const initialState: ShipmentPartyRolesState = {
  status: '',
  shipmentPartyRoles: [],
  shipmentPartyRoleByName: {},
};

export const fetchShipmentRoles = asyncThunkGet<any, ShipmentPartyRole[]>('shipmentPartyRoles', '/shipment-service/api/v1/shipment-party-roles');

export const shipmentPartyRolesSlice = createSlice({
  name: 'shipmentParty',
  initialState: initialState as any,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchShipmentRoles.pending, (state, { meta }) => {
        state.status = 'pending';
        state.shipmentPartyRoles = [];
        state.currentRequestId = meta.requestId;
      })
      .addCase(fetchShipmentRoles.fulfilled, (state, { payload, meta }) => {
        const { requestId } = meta;
        if (state.status === 'pending' && state.currentRequestId === requestId) {
          state.status = 'idle';
          state.shipmentPartyRoles = payload;
          state.currentRequestId = undefined;
          state.shipmentPartyRoleByName = reduce(
            (acc: any, item: ShipmentPartyRole) => {
              acc[item.name] = item.title;
              return acc;
            },
            {},
            payload,
          );
        }
      })
      .addCase(fetchShipmentRoles.rejected, (state, { error, meta }) => {
        const { requestId } = meta;
        if (state.status === 'pending' && error?.name !== 'AbortError' && state.currentRequestId === requestId) {
          state.status = 'rejected';
          state.shipmentPartyRoles = null;
          state.currentRequestId = undefined;
        }
      });
  },
});

export const getAllShipmentRoles = (state: RootState) => state.shipmentParty.shipmentPartyRoles;
export const getShipmentRolesByName = (state: RootState) => (
  state.shipmentParty.shipmentPartyRoleByName
);

export const shipmentPartyRolesSliceReducer = shipmentPartyRolesSlice.reducer;
