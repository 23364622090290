import styled from 'styled-components';

export const ControlButtonsWrapper = styled.div.attrs({
  'data-class': 'ControlButtonsWrapper',
})`
  display: flex;
  flex-direction: row;
  gap: 8px;
  margin-left: auto;
`;
