import themesColors, { colors } from './themesColors';
import responsive from './mixin/responsive';
import { rgba } from './rgba';
import {
  BodyTextBold,
  BodyTextMedium,
  BodyTextRegular,
  SmallTextFootnoteDescription,
  SmallTextFootnoteMedium,
  TitleText,
} from '../guideline';

const typography = {
  heading1: {},
};

const dimensions = {
  freightQuotePage: {
    height: {
      heightPageHeader: '72px',
      topHeaderContent: '56px',
      topHeader: '48px',
    },
  },
};

const inputConst = {
  defaultHeight: '32px',
  hdHeight: '40px',
};

export default {
  typography,
  colors,
  themesColors,
  fonts: {
    TitleText,
    BodyTextRegular,
    BodyTextMedium,
    BodyTextBold,
    SmallTextFootnoteDescription,
    SmallTextFootnoteMedium,
  },
  responsive,

  dimensions,
  inputConst,
  button: {
    default: {
      bg: themesColors.neutralBranding1,
      border: themesColors.neutralBranding5,
      shadow: colors.buttonPrimaryShadow,
      text: themesColors.characterBrandingTitle85,
      activeText: themesColors.primaryBranding6,
      activeBg: themesColors.neutralBranding1,
      activeBorder: themesColors.primaryBranding6,
      hoveredBorder: themesColors.primaryBranding4,
      hoveredBg: themesColors.neutralBranding1,
      hoveredText: themesColors.primaryBranding5,
      disabledBg: themesColors.neutralBranding3,
      disabledBorder: themesColors.neutralBranding5,
      disabledText: themesColors.characterBrandingDisabled025,
      icon: {
        bg: themesColors.characterBrandingDisabled025,
      },
    },
    primary: {
      bg: themesColors.neutralBranding8,
      border: themesColors.neutralBranding8,
      shadow: colors.buttonPrimaryShadow,
      text: themesColors.neutralBranding1,
      activeText: themesColors.neutralBranding1,
      activeBg: themesColors.neutralBranding10,
      activeBorder: themesColors.neutralBranding10,
      hoveredBorder: themesColors.neutralBranding8,
      hoveredBg: colors.buttonPrimaryColor,
      hoveredText: themesColors.neutralBranding1,
      disabledBg: themesColors.neutralBranding3,
      disabledBorder: themesColors.neutralBranding5,
      disabledText: themesColors.characterBrandingDisabled025,
      icon: {
        bg: themesColors.characterBrandingDisabled025,
      },
    },
    secondary: {},
    ghost: {},
    dashed: {
      bg: themesColors.neutralBranding1,
      border: themesColors.neutralBranding5,
      shadow: 'transparent',
      text: themesColors.characterBrandingTitle85,
      activeText: themesColors.primaryBranding6,
      activeBg: themesColors.neutralBranding1,
      activeBorder: themesColors.primaryBranding6,
      hoveredBorder: themesColors.primaryBranding4,
      hoveredBg: themesColors.neutralBranding1,
      hoveredText: themesColors.primaryBranding5,
      disabledBg: themesColors.neutralBranding3,
      disabledBorder: themesColors.neutralBranding5,
      disabledText: themesColors.characterBrandingDisabled025,
      icon: {
        bg: themesColors.characterBrandingDisabled025,
      },
    },
    link: {},
    text: {},
  },
  dropdown: {
    disabledBg: themesColors.neutralBranding3,
    disabledBorder: themesColors.neutralBranding5,
    disabledText: themesColors.neutralBranding6,
  },
  boxComponent: {
    bg: themesColors.neutralBranding1,
    border: themesColors.neutralBranding4,
    dropShadow: rgba(themesColors.characterBrandingTitle85, 0.12),
    innerShadow: colors.boxShadow,
  },
  step: {
    stepColor: themesColors.characterBrandingDisabled025,
    status: {
      default: colors.boxShadow,
      wait: themesColors.characterBrandingDisabled025,
    },
  },
  bookingPage: {
    contentBg: themesColors.neutralBranding3,
    shippingPartiesStep: {
      bg: themesColors.neutralBranding1,
      textColor: themesColors.characterBrandingTitle85,
      descriptionColor: themesColors.secondaryDot45,
      roleInProgressBg: themesColors.characterBrandingDisabled025,
      roleCompletedBg: themesColors.characterBrandingSuccess,
    },
    cargoStep: {
      bg: themesColors.neutralBranding1,
      textColor: themesColors.characterBrandingTitle85,
    },
    documentsStep: {
      bg: themesColors.neutralBranding1,
      textColor: themesColors.characterBrandingTitle85,
    },
    billingStep: {
      bg: themesColors.neutralBranding1,
      textColor: themesColors.characterBrandingTitle85,
    },
    containersStep: {
      bg: themesColors.neutralBranding1,
      textColor: themesColors.characterBrandingTitle85,
    },
    drayageStep: {
      bg: themesColors.neutralBranding1,
      textColor: themesColors.characterBrandingTitle85,
    },
    bookingModal: {
      header: {
        closeIcon: {
          innerBorder: themesColors.neutralBranding5,
          innerIcon: themesColors.characterBrandingTitle85,
        },
      },
    },
  },
  freightQuotePage: {
    height: {
      heightPageHeader: '72px',
      topHeaderContent: '56px',
      topHeader: '48px',
      filterHeader: '46px',
    },
    padding: {
      leftPaddingContent: '24px',
      rightPaddingContent: '23px',
    },
    bg: themesColors.neutralBranding3,
  },
};
