import styled from 'styled-components';
import ClockCircleOutlined from '@ant-design/icons/ClockCircleOutlined';

import { divFlex } from 'app-wrapper/view/themes/mixin';
import { CustomScrollbar } from 'app-wrapper/view/styles/mixins';

export const CCSpanEllipsis = styled.span.attrs({
  'data-class': 'CCSpanEllipsis',
})`
  max-width: 100%;
  position: relative;
  display: inline;

  text-overflow: ellipsis;
  overflow: clip;
  white-space: nowrap;
`;

export const CCContentTable = styled.div.attrs({
  'data-class': 'CCContentTable',
})`
  ${divFlex}
  height: calc(100vh - 48px - 56px - 46px - 13px - 13px);
  flex-direction: column;

  margin: 13px 24px 13px 0;
  padding: 16px;
  background-color: ${({ theme }) => theme.themesColors.neutralBranding1};
`;

export const CCContentTableHeader = styled.div.attrs({
  'data-class': 'CCContentTableHeader',
})`
  ${divFlex}

  height: 28px;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.themesColors.neutralBranding3};
  padding-bottom: 10px;
`;

export const CCContentTableHeaderItem = styled.div.attrs((props: {
  isCenter?: boolean,
  width?: string,
  paddingLeft?: string,
}) => ({
  isCenter: props.isCenter,
  width: props.width,
  paddingLeft: props.paddingLeft,
  'data-class': 'CCContentTableHeaderItem',
}))`
  ${divFlex}
  align-items: center;
  justify-content: ${({ isCenter }) => (isCenter ? 'center' : 'left')};

  width: ${({ width }) => (width || 'auto')};
  padding-left: ${({ paddingLeft }) => (paddingLeft || '0')};

  color: ${({ theme }) => theme.themesColors.secondaryDot45};
  font-size: 12px;
  font-weight: 400;
`;

export const CCContentTableContent = styled.div.attrs({
  'data-class': 'CCContentTableContent',
})`
  ${divFlex}
  ${CustomScrollbar}
  flex-direction: column;
`;

export const CCContentTableContentHeaderBlock = styled.div.attrs({
  'data-class': 'CCContentTableContentHeaderBlock',
})`
  ${divFlex}
  align-items: center;

  color: ${({ theme }) => theme.themesColors.neutralBranding10};
  font-size: 16px;
  font-weight: 500;
  border-bottom: 1px solid ${({ theme }) => theme.themesColors.neutralBranding3};
  padding-top: 16px;
  padding-bottom: 6px;
`;

export const CCContentTableContentHeaderSubBlock = styled.div.attrs({
  'data-class': 'CCContentTableContentHeaderSubBlock',
})`
  ${divFlex}
  height: 19px;

  min-width: 23px;
  justify-content: center;
  align-items: center;

  color: ${({ theme }) => theme.themesColors.secondaryDot45};
  background-color: ${({ theme }) => theme.themesColors.neutralBranding3};
  font-size: 12px;
  font-weight: 400;

  border-radius: 100px;
  margin-left: 4px;
  padding-left: 8px;
  padding-right: 8px;
`;

export const CCContentTableContentExpandButtonWrap = styled.div.attrs({
  'data-class': 'CCContentTableContentExpandButtonWrap',
})`
  ${divFlex}
  align-items: center;
  margin: 12px 0;
`;

export const CCContentTableContentExpandButton = styled.div.attrs({
  'data-class': 'CCContentTableContentExpandButton',
})`
  ${divFlex}
  align-items: center;
  min-width: 128px;
  height: 32px;

  color: ${({ theme }) => theme.themesColors.neutralBranding10};
  font-size: 14px;
  font-weight: 400;
  border: 1px solid ${({ theme }) => theme.themesColors.neutralBranding5};
  border-radius: 3px;
  padding: 5px 16px;
  cursor: pointer;

  svg {
    margin-right: 12px;
  }
`;

export const CCContentTableContentExpandButtonCount = styled.div.attrs({
  'data-class': 'CCContentTableContentExpandButtonCount',
})`
  ${divFlex}
  font-size: 14px;
  font-weight: 400;
  color: ${({ theme }) => theme.themesColors.secondaryDot45};
  margin-left: 5px;
`;

export const CCContentTableContentItem = styled.div.attrs({
  'data-class': 'CCContentTableContentItem',
})`
  ${divFlex}
  flex-direction: column;
  width: 100%;

  border-bottom: 1px solid ${({ theme }) => theme.themesColors.neutralBranding3}
`;

export const CCContentTableContentItemTop = styled.div.attrs({
  'data-class': 'CCContentTableContentItemTop',
})`
  ${divFlex}

  height: 55px;
`;

export const CCContentTableContentExpand = styled.div.attrs((props: {
  isActive?: boolean,
  isEmpty?: boolean,
}) => ({
  isActive: props.isActive,
  isEmpty: props.isEmpty,
  'data-class': 'CCContentTableContentExpand',
}))`
  ${divFlex}
  align-items: center;
  width: 8px;

  margin-left: 4px;
  cursor: ${({ isEmpty }) => (isEmpty ? 'pointer' : 'auto')};
  height: 100%;
`;

export const CCContentTableContentPriority = styled.div.attrs((props: {
  isActive?: boolean,
}) => ({
  isActive: props.isActive,
  'data-class': 'CCContentTableContentPriority',
}))`
  ${divFlex}
  align-items: center;
  width: 16px;

  height: 100%;
  margin-left: 15px;
`;

export const CCContentTableContentTitle = styled.div.attrs((props: {
  isActive?: boolean,
}) => ({
  isActive: props.isActive,
  'data-class': 'CCContentTableContentTitle',
}))`
  ${divFlex}
  /* temporarily hidden */
  /* width: calc(41% - 12px - 31px - 10px); */
  width: calc(41% - 12px - 31px - 10px + 35px);
  align-items: center;
  justify-content: left;

  margin-left: 10px;
  color: ${({ theme }) => theme.themesColors.characterBrandingTitle85};
  color: ${({ theme, isActive }) => (isActive ? theme.themesColors.primaryBranding6 : theme.themesColors.characterBrandingTitle85)};
  font-size: 14px;
  font-weight: 500;
`;

export const CCContentTableContentDueDate = styled.div.attrs((props: {
  isWarning?: boolean,
}) => ({
  isWarning: props.isWarning,
  'data-class': 'CCContentTableContentDueDate',
}))`
  ${divFlex}
  width: 14.5%;
  align-items: center;
  justify-content: left;

  > div {
    min-width: ${({ isWarning }) => (isWarning ? '109px' : '92px')};
  }
`;

export const CCContentTableContentLink = styled.div.attrs({
  'data-class': 'CCContentTableContentLink',
})`
  ${divFlex}
  width: 14.5%;
  align-items: center;
  justify-content: left;

  color: ${({ theme }) => theme.themesColors.neutralBranding10};
  font-size: 14px;
  font-weight: 400;

  text-overflow: ellipsis;
  overflow: clip;
  white-space: nowrap;

  > span:first-letter {
    text-transform: uppercase;
  }
`;

export const TextLink = styled(CCSpanEllipsis)`
  color: rgba(74, 85, 173, 1);
  cursor: pointer;
`;

export const CCContentTableContentModule = styled.div.attrs({
  'data-class': 'CCContentTableContentModule',
})`
  ${divFlex}
  width: 16%;
  align-items: center;
  justify-content: left;

  color: ${({ theme }) => theme.themesColors.characterBrandingTitle85};
  font-size: 14px;
  font-weight: 400;

  text-transform: capitalize;
`;

export const CCContentTableContentAssignee = styled.div.attrs((props: {
  isBgNone?: boolean,
}) => ({
  isBgNone: props.isBgNone,
  'data-class': 'CCContentTableContentAssignee',
}))`
  ${divFlex}
  width: 5%;
  align-items: center;
  justify-content: center;
  min-width: 24px;
  width: 24px;
  height: 24px;

  margin: auto;
  color: ${({ theme }) => theme.themesColors.primaryBranding5};
  background-color: ${({ theme, isBgNone }) => (isBgNone ? 'none' : theme.themesColors.primaryBranding1)};
  font-size: 12px;
  font-weight: 400;
  border-radius: 100px;
`;

export const CCContentTableContentAction = styled.div.attrs({
  'data-class': 'CCContentTableContentAction',
})`
  ${divFlex}
  width: 5%;
  align-items: center;
  justify-content: center;
  min-width: 24px;
  width: 24px;
  height: 24px;

  cursor: pointer;
  margin: auto;
  color: ${({ theme }) => theme.themesColors.neutralBranding1};
  background-color: ${({ theme }) => theme.themesColors.neutralBranding9};
  border-radius: 100px;

  a {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const CCContentTableContentItemBottom = styled.div.attrs((props: {
  isActive?: boolean,
}) => ({
  isActive: props.isActive,
  'data-class': 'CCContentTableContentItemBottom',
}))`
  ${divFlex}
  flex-direction: column;
  ${({ isActive }) => isActive && 'margin-top: -11px;'};

  .ant-collapse-header {
    display: none !important;
  }
  .ant-collapse-content-box {
    padding: 0;
  }
  .ant-collapse.ant-collapse-icon-position-left,
  .ant-collapse-content.ant-collapse-content-active,
  .ant-collapse-item.ant-collapse-item-active,
  .ant-collapse-item {
    border: none !important;
    background: none;
  }
`;

export const CCContentTableContentItemBottomDescription = styled.div.attrs({
  'data-class': 'CCContentTableContentItemBottomDescription',
})`
  display: block;
  width: calc(41% - 12px - 31px - 10px);

  color: ${({ theme }) => theme.themesColors.secondaryDot45};
  font-size: 14px;
  font-weight: 400;
  margin-left: 53px;
  margin-bottom: 12px;
  padding-right: 8px;
`;

export const ContentTableTasksAssigneeUsersWrap = styled.div.attrs({
  'data-class': 'ContentTableTasksAssigneeUsersWrap',
})`
  ${divFlex}
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const ContentTableTasksAssigneeUserItem = styled.div.attrs({
  'data-class': 'ContentTableTasksAssigneeUserItem',
})`
  ${divFlex}

  width: 24px;
  height: 24px;
  border: 2px solid ${({ theme }) => theme.themesColors.neutralBranding1};
  font-size: 12px;
  line-height: 20px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.themesColors.primaryBranding5};
  background-color: ${({ theme }) => theme.themesColors.primaryBranding1};
  border: none;
`;

export const ContentTableTasksAssigneeHideUser = styled.span.attrs({
  'data-class': 'ContentTableTasksAssigneeHideUser',
})`
  ${divFlex}

  margin-left: 4px;
  font-size: 12px;
  line-height: 20px;
  color: ${({ theme }) => theme.themesColors.primaryBranding5};
`;

export const ContentTableTasksAssigneeNoUsersIcon = styled(ClockCircleOutlined).attrs({
  'data-class': 'ContentTableTasksAssigneeNoUsersIcon',
})`
  ${divFlex}

  margin-left: -7px;
  color: rgba(209, 215, 226, 1);
`;
