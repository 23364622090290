import React from 'react';

import { IPropsIcon } from 'app-wrapper/interfaces/icon';

import { Icon } from './CubeSvg.styled';

export const CubeSvg = (props: IPropsIcon) => {
  const { width, height, fill } = props;
  return (
    <Icon
      className="CubeSvg"
      width={width || '14'}
      height={height || '16'}
      viewBox="0 0 14 16"
      fill={fill || 'currentColor'}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path fillRule="evenodd" clipRule="evenodd" d="M7.03359 0L13.7339 3.94826V11.8216L7.03359 15.7698L0.333252 11.8216V3.94826L7.03359 0ZM1.66659 11.0596V5.80141L6.36694 8.48733V13.8294L1.66659 11.0596ZM7.70028 13.8294L12.4006 11.0596V5.80139L7.70028 8.48728V13.8294ZM7.03359 1.5476L12.0177 4.48454L7.03356 7.33259L2.04949 4.48455L7.03359 1.5476Z" fill="currentColor" />
    </Icon>
  );
};
