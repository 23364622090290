import React, { FC, HTMLAttributes } from 'react';

export const CustomersIcon: FC<HTMLAttributes<SVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="14"
    fill="currentColor"
    viewBox="0 0 14 14"
    {...props}
  >
    <g clipPath="url(#clip0_17877_3517)">
      <g clipPath="url(#clip1_17877_3517)">
        <path
          d="M13.5 1.497H.5a.5.5 0 00-.5.5v10a.5.5 0 00.5.5h13a.5.5 0 00.5-.5v-10a.5.5 0 00-.5-.5zm-.625 9.875H1.125v-8.75h11.75v8.75zm-4.34-4.937h1.93c.02 0 .035-.057.035-.125v-.75c0-.07-.016-.125-.036-.125H8.536c-.02 0-.036.056-.036.125v.75c0 .068.016.125.036.125zm.076 2.25h2.901c.061 0 .111-.057.111-.125v-.75c0-.07-.05-.125-.11-.125H8.61c-.061 0-.111.056-.111.125v.75c0 .068.05.125.11.125zM2.5 9.513h.686a.125.125 0 00.123-.117A1.53 1.53 0 014.83 7.98c.8 0 1.458.625 1.518 1.415a.125.125 0 00.123.117h.686a.125.125 0 00.125-.132 2.472 2.472 0 00-1.165-1.97c.293-.323.455-.743.454-1.18 0-.967-.78-1.75-1.74-1.75-.961 0-1.74.783-1.74 1.75 0 .455.17.868.454 1.18a2.47 2.47 0 00-1.166 1.97.12.12 0 00.122.132zm2.328-4.094a.81.81 0 01.808.812.81.81 0 01-.808.813.81.81 0 01-.808-.813.81.81 0 01.808-.812z"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_17877_3517">
        <path fill="#fff" d="M0 0H14V14H0z" />
      </clipPath>
      <clipPath id="clip1_17877_3517">
        <path fill="#fff" d="M0 0H14V14H0z" />
      </clipPath>
    </defs>
  </svg>
);
