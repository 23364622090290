import { divFlex } from 'app-wrapper/view/themes/mixin';
import styled from 'styled-components';

export const CIAddressWrapper = styled.div.attrs({
  'data-class': 'CIAddressWrapper',
})`
  ${divFlex}
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const CIAddressIconBG = styled.div.attrs({
  'data-class': 'CIAddressIconBG',
})`
  ${divFlex}
  align-items: center;
  justify-content: center;

  width: 32px;
  min-width: 32px;
  height: 32px;
  min-height: 32px;

  background-color: transparent;
  border-radius: 4px;
  color: ${({ theme }) => theme.themesColors.primaryBranding5};
  border: 1px solid ${({ theme }) => theme.themesColors.neutralBranding4};
  font-size: 14px;

  svg {
    color: ${({ theme }) => theme.themesColors.primaryBranding7};
  }
`;

export const CIAddressRow = styled.div.attrs({
  'data-class': 'CIAddressRow',
})`
  ${divFlex}
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;

  width: 100%;
  margin-top: 12px;
`;

export const CIAddressItem = styled.div.attrs({
  'data-class': 'UserOrganizationIconBG',
})`
  ${divFlex}
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;

  width: 50%;
`;

export const CIAddressHeader = styled.div.attrs({
  'data-class': 'CIAddressHeader',
})`
  ${divFlex}

  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
`;

export const CIAddressData = styled.div.attrs({
  'data-class': 'CIAddressData',
})`
  ${divFlex}
  flex-flow: column nowrap;
  justify-content: center;
  align-items: flex-start;

  margin-left: 16px;
`;

export const CIAddressTitle = styled.div.attrs({
  'data-class': 'CIAddressTitle',
})`
  ${divFlex}

  font-size: 12px;
  color: ${({ theme }) => theme.themesColors.darkGrayMiddle};
  line-height: 20px;
  font-weight: 400;
`;

export const CIAddressValue = styled.div.attrs({
  'data-class': 'CIAddressValue',
})`
  ${divFlex}

  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
`;
