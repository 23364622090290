import React, { FC } from 'react';

import { UC } from 'shipment-operations/controllers';
import { useAppSelector } from 'app-wrapper/hooks';
import { R } from 'shipment-operations/repository';

import { CreateInvoiceComponent } from './CreateInvoice.component';

interface ICreateInvoiceProps {
  closeModal: () => void
}

const CreateInvoiceContainer: FC<ICreateInvoiceProps> = ({ closeModal }) => {
  const sum = useAppSelector(R.selectors.createInvoiceCharges.getSumSelectedCharges);
  const errorCharges = useAppSelector(R.selectors.createInvoiceCharges.getError);
  const errorCompanies = useAppSelector(R.selectors.createInvoiceCharges.getLoadCompaniesError);
  const isLoadingCharges = useAppSelector(R.selectors.createInvoiceCharges.getIsLoading);
  const isLoadingCompanies = useAppSelector(R.selectors.createInvoiceCharges.getIsLoadingCompanies);
  const isEmptyData = useAppSelector(R.selectors.createInvoiceCharges.getIsEmptyData);
  const createInvoiceModalOwner = useAppSelector(R.selectors.createInvoiceCharges.getIsOpenCreateInvoiceModal);

  return (
    <CreateInvoiceComponent
      loadData={UC.createInvoiceCharges.loadDataForCreateInvoice}
      onClose={UC.createInvoiceCharges.closeCreateInvoiceModal}
      resetSuccess={UC.createInvoiceCharges.onClearSuccess}
      sum={sum}
      closeModal={closeModal}
      errorCharges={errorCharges}
      errorCompanies={errorCompanies}
      isLoading={isLoadingCharges || isLoadingCompanies}
      isEmptyData={isEmptyData}
      modalOwner={createInvoiceModalOwner}
    />
  );
};

export { CreateInvoiceContainer as CreateInvoice };
