import React, { FC, memo } from 'react';
import { CommandCenter } from './CommandCenter';

const CommandCenterPage: FC = () => (
  <CommandCenter />
);

const CommandCenterPageCache = memo(CommandCenterPage);

export { CommandCenterPageCache as CommandCenterPage };
