import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 124px;
  justify-content: space-between;
  max-width: 1260px;
  margin: 0 auto;
  margin-bottom: 10px;
`;

export const Card = styled.div`
  width: 32.5%;
  height: 100%;
  background-color: white;
  padding: 12px 16px;
`;

export const SkeletonCard = styled(Card)`
  background-color: transparent;
  padding: 0;
`;

export const FieldName = styled.p`
  font-weight: 400;
  font-size: 12px;
  color: #73819B;
  margin: 0;
`;

export const FieldValue = styled.p`
  font-weight: 400;
  font-size: 14px;
  color: #202C3C;
  margin: 0 0 4px 0;
`;

export const DateWrapper = styled.div`
  display: flex;
`;

export const PaymentDate = styled.div`
  margin-right: 60px;
`;

export const PostedDate = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TotalTitle = styled.p`
  font-weight: 400;
  font-size: 16px;
  color: #202C3C;
  margin: 0;
  width: 100%;
  text-align: right;
`;

export const TotalValue = styled.p`
  margin: 8px 0 0 0;
  color: #202C3C;
  font-weight: 500;
  font-size: 24px;
  width: 100%;
  text-align: right;
`;
