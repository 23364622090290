import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import {
  TemperatureFieldWrapper,
  TemperatureInput,
  TemperatureLabel,
} from './TemperatureField.styled';

interface ITemperatureFieldComponentProps {
  temperature?: string
}

export const TemperatureFieldComponent: FC<ITemperatureFieldComponentProps> = ({
  temperature,
}) => {
  const { t } = useTranslation();

  return (
    <TemperatureFieldWrapper>
      <TemperatureLabel>
        {`${t('Temperature')} `}{'\u2103'}
      </TemperatureLabel>
      <TemperatureInput
        value={temperature}
        disabled
      />
    </TemperatureFieldWrapper>
  );
};
