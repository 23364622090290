import React, { FC, useContext } from 'react';
import { useSelector } from 'react-redux';
import { observer } from 'mobx-react-lite';

import { MobxStoresContext } from 'app-wrapper/mobxStores';

import { R as shipmentR } from 'shipment-operations/repository';

import { UC } from 'monetary/controllers';

import { ShipmentAdditionalServicesComponent } from './ShipmentAdditionalServices.component';

interface IShipmentAdditionalServicesContainerProps {
}

const ShipmentAdditionalServicesContainer: FC<IShipmentAdditionalServicesContainerProps> = observer(() => {
  const { additionalServicesDrawerStore } = useContext(MobxStoresContext);
  const addAdditionalServicesDrawerChecked = additionalServicesDrawerStore?.getAddAdditionalServicesBookingDrawerChecked;
  const isContentUpdating = useSelector(shipmentR.selectors.bookingWizard.getIsContentUpdating);

  return (
    <ShipmentAdditionalServicesComponent
      servicesChecked={addAdditionalServicesDrawerChecked}
      isPending={isContentUpdating}
      onDeletedCheckedService={UC.AdditionalServices.onUnCheckedAdditionalServiceBookingDrawerItemById}
      addServicesDrawer={UC.AdditionalServices.openAdditionalServiceBookingDrawer}
    />
  );
});

export { ShipmentAdditionalServicesContainer as ShipmentAdditionalServices };
