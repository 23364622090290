import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';
import { UC } from 'shipment-operations/controllers';

import { RollOptionComponent } from './RollOption.component';

interface RollOptionContainerProps {
  scheduleId: number;
}

const RollOptionContainer: FC<RollOptionContainerProps> = ({ scheduleId }) => {
  const isChosen = useSelector(R.selectors.rollShipmentWizard.getIsScheduleChosen(scheduleId));
  const isExpanded = useSelector(R.selectors.rollShipmentWizard.getIsScheduleExpandedById(scheduleId));
  const etd = useSelector(R.selectors.rollShipmentWizard.getScheduleETD(scheduleId));
  const eta = useSelector(R.selectors.rollShipmentWizard.getScheduleETA(scheduleId));
  const terminalCutoffDate = useSelector(R.selectors.rollShipmentWizard.getScheduleTerminalCutoffDate(scheduleId));
  const documentCutoffDate = useSelector(R.selectors.rollShipmentWizard.getScheduleDocumentCutoffDate(scheduleId));
  const hazmatCutoffDate = useSelector(R.selectors.rollShipmentWizard.getScheduleHazmatCutoffDate(scheduleId));
  const scheduleTotalCost = useSelector(R.selectors.rollShipmentWizard.getScheduleTotalCost(scheduleId));
  const shipmentAdditionalChargesTotalCost = useSelector(R.selectors.rollShipmentWizard.getShipmentAdditionalChargesTotalCost);
  const shipmentAppliedChargesTotalCost = useSelector(R.selectors.rollShipmentWizard.getShipmentAppliedChargesTotalCost);

  return (
    <RollOptionComponent
      scheduleId={scheduleId}
      isChosen={isChosen}
      isExpanded={isExpanded}
      scheduleTotalCost={scheduleTotalCost}
      chooseSchedule={UC.rollShipment.chooseScheduleById}
      toggleScheduleExpanding={UC.rollShipment.toggleScheduleExpanding}
      etd={etd}
      eta={eta}
      terminalCutoffDate={terminalCutoffDate}
      documentCutoffDate={documentCutoffDate}
      hazmatCutoffDate={hazmatCutoffDate}
      shipmentAdditionalChargesTotalCost={shipmentAdditionalChargesTotalCost}
      shipmentAppliedChargesTotalCost={shipmentAppliedChargesTotalCost}
    />
  );
};

export { RollOptionContainer as RollOption };
