import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'app-wrapper/view/components';
import InfoCircleOutlined from '@ant-design/icons/InfoCircleOutlined';
import CheckOutlined from '@ant-design/icons/CheckOutlined';
import CaretUpOutlined from '@ant-design/icons/CaretUpOutlined';
import CaretDownOutlined from '@ant-design/icons/CaretDownOutlined';

import { normalizationCost } from 'app-wrapper/utils';

import { ShipmentStatsCustomer } from 'shipment-operations/models/dtm';
import {
  InnerWrapper, Main, Budget, SubTitle, MainValue, Percent, TooltipInner, CheckShadowMain, CheckShadow,
  IconShadow, StyledDiff, StyledBar, StyledProgress, ExtraWrapper, ExtraItem, ExtraTitle, ExtraValue,
  MainWrapper, InnerBudget, FlexWrapper,
} from './Dashboard.styled';

interface IInnerItemProps {
  title: string
  mainValue: number
  mainValuePercent?: number
  withBudget?: boolean
  budgetPlan?: number
  difference?: number
  differencePercent?: number
  payable?: boolean
  withProgressBar?: boolean
  progressValue?: number
  mainValueDataTestId?: string
  withExtra?: boolean
  stats?: ShipmentStatsCustomer
}

const CheckStyled = () => (
  <CheckShadowMain>
    <CheckShadow>
      <CheckOutlined style={{ color: 'white', fontSize: '10px' }} />
    </CheckShadow>
  </CheckShadowMain>
);

const InnerItem: FC<IInnerItemProps> = ({
  title, mainValue, mainValuePercent, withBudget, budgetPlan,
  difference, differencePercent, payable, withProgressBar = false,
  progressValue = 0, mainValueDataTestId, withExtra = false, stats,
}) => {
  const { t } = useTranslation();

  const renderPercent = () => {
    if (differencePercent === Infinity) {
      return '- %';
    }
    return `${differencePercent?.toFixed(2)} %`;
  };

  return (
    <InnerWrapper withProgress={withProgressBar} withExtra={withExtra}>
      <MainWrapper>
        <Main withBudget={withBudget}>
          <SubTitle>{title}</SubTitle>
          <MainValue id={mainValueDataTestId}>
            $ {normalizationCost(mainValue, { toFixed: 2, thousandthSeparatorComma: true })}
            {mainValuePercent && !Number.isNaN(mainValuePercent) && mainValuePercent !== Infinity && (
              <Percent> ({mainValuePercent.toFixed(2)}%)</Percent>
            )}
          </MainValue>
        </Main>
        {withBudget && (
          <Budget>
            <InnerBudget>
              <SubTitle>Budget Plan</SubTitle>
              <Tooltip
                title={(
                  <TooltipInner>
                    <span>Budget Plan: $ {normalizationCost(budgetPlan || 0, { toFixed: 2, thousandthSeparatorComma: true })}</span>
                    <span>Difference: $ {normalizationCost(difference || 0, { toFixed: 2, thousandthSeparatorComma: true })}</span>
                  </TooltipInner>
                )}
                placement="top"
              >
                <InfoCircleOutlined style={{ color: '#73819B', fontSize: '12px', cursor: 'pointer' }} />
              </Tooltip>
            </InnerBudget>
            {budgetPlan === mainValue && (
              <CheckStyled />
            )}
            {(budgetPlan || 0) < mainValue && payable && (
              <FlexWrapper>
                <StyledDiff>{renderPercent}</StyledDiff>
                <IconShadow>
                  <CaretUpOutlined style={{ color: '#F1AE00', fontSize: '12px' }} />
                </IconShadow>
              </FlexWrapper>
            )}
            {(budgetPlan || 0) > mainValue && payable && (
              <FlexWrapper>
                <StyledDiff>{renderPercent}</StyledDiff>
                <IconShadow style={{ background: '#F6FFED' }}>
                  <CaretDownOutlined style={{ color: '#75CB3F', fontSize: '12px' }} />
                </IconShadow>
              </FlexWrapper>
            )}
            {(budgetPlan || 0) > mainValue && !payable && (
              <FlexWrapper>
                <StyledDiff>{renderPercent}</StyledDiff>
                <IconShadow style={{ background: '#FFFBE6' }}>
                  <CaretDownOutlined style={{ color: '#F1AE00', fontSize: '12px' }} />
                </IconShadow>
              </FlexWrapper>
            )}
            {(budgetPlan || 0) < mainValue && !payable && (
              <FlexWrapper>
                <StyledDiff>{renderPercent}</StyledDiff>
                <IconShadow style={{ background: '#F6FFED' }}>
                  <CaretUpOutlined style={{ color: '#75CB3F', fontSize: '12px' }} />
                </IconShadow>
              </FlexWrapper>
            )}
          </Budget>
        )}
      </MainWrapper>
      {withProgressBar && (
        <StyledBar>
          <StyledProgress width={progressValue || 0} />
        </StyledBar>
      )}
      {withExtra && (
        <ExtraWrapper>
          <ExtraItem>
            <ExtraTitle>{t('Invoiced')}</ExtraTitle>
            <ExtraValue>$ {normalizationCost(stats?.totalInvoiced || 0, { toFixed: 2, thousandthSeparatorComma: true })}</ExtraValue>
          </ExtraItem>
          <ExtraItem>
            <ExtraTitle>{t('Uninvoiced')}</ExtraTitle>
            <ExtraValue>$ {normalizationCost(stats?.totalUnInvoiced || 0, { toFixed: 2, thousandthSeparatorComma: true })}</ExtraValue>
          </ExtraItem>
          <ExtraItem>
            <ExtraTitle>{t('paid')}</ExtraTitle>
            <ExtraValue>$ {normalizationCost(stats?.totalPaid || 0, { toFixed: 2, thousandthSeparatorComma: true })}</ExtraValue>
          </ExtraItem>
        </ExtraWrapper>
      )}
    </InnerWrapper>
  );
};

export { InnerItem };
