import {
  IsEnum,
  IsOptional,
} from 'class-validator';
import { BaseDTM } from 'proto/BaseDTM';

import { PermissionAttributePolicy } from 'shipment-operations/constants';

export type TDocumentsPermissionsDtm = {
  imoDeclarations?: PermissionAttributePolicy;
  additionalDocuments?: PermissionAttributePolicy;
  billOfLadingDocuments?: PermissionAttributePolicy;
  seaworthyCertificate?: PermissionAttributePolicy;
  viewInvoiceDocumentNameWithPrefix?: PermissionAttributePolicy;
}

export class DocumentsPermissionsDtm extends BaseDTM<TDocumentsPermissionsDtm> {
  @IsOptional()
  @IsEnum(PermissionAttributePolicy)
  imoDeclarations?: PermissionAttributePolicy

  @IsOptional()
  @IsEnum(PermissionAttributePolicy)
  additionalDocuments?: PermissionAttributePolicy

  @IsOptional()
  @IsEnum(PermissionAttributePolicy)
  billOfLadingDocuments?: PermissionAttributePolicy

  @IsOptional()
  @IsEnum(PermissionAttributePolicy)
  seaworthyCertificate?: PermissionAttributePolicy;

  @IsOptional()
  @IsEnum(PermissionAttributePolicy)
  viewInvoiceDocumentNameWithPrefix?: PermissionAttributePolicy;
}
