import React from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';

import { ShipperReferenceComponent } from './ShipperReference.component';

const ShipperReferenceContainer = () => {
  const isContentUpdating = useSelector(R.selectors.bookingWizard.getIsContentUpdating);

  return (
    <ShipperReferenceComponent
      isPending={isContentUpdating}
    />
  );
};

export { ShipperReferenceContainer as ShipperReference };
