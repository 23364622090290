import { createSelector } from 'reselect';
import filter from 'lodash/fp/filter';
import includes from 'lodash/fp/includes';
import uniqBy from 'lodash/fp/uniqBy';
import groupBy from 'lodash/fp/groupBy';
import partition from 'lodash/fp/partition';

import { RootState } from 'app-wrapper/store';
import {
  occurrenceAdditional, priceByBol, priceByContainer, typeFee,
} from 'shipment-operations/constants';
import { calculateInvoiceSum } from 'shipment-operations/view/pages/ShipmentBillingInvoice/utils/calculateSum';
import { InvoicesChargeDTM, ContainerViewDTM } from 'shipment-operations/models/dtm';

const parseContainers = (data: InvoicesChargeDTM[]) => {
  const filtered = data.filter((item) => item.charge.container);
  if (filtered.length) {
    const prepared = filtered.map((item) => (item.charge.container ? ({
      number: item.charge.container.number,
      type: item.charge.container.type,
      id: item.charge.container.id,
    }) : null));
    return prepared.length ? prepared.filter((el) => el) as ContainerViewDTM[] : [];
  }
  return [];
};

const localState = (state: RootState) => state.shipmentBillingInvoice;

const getInvoice = createSelector(
  localState,
  (state) => state.invoice,
);

const getCharges = createSelector(
  getInvoice,
  (invoice) => {
    const charges = invoice?.charges;
    return filter((item) => item.invoiced > 0, charges);
  },
);

const getAppliedCharges = createSelector(
  getCharges,
  (data) => data?.filter((item) => item.charge.applied),
);

const getCreditNote = createSelector(
  localState,
  (state) => state.creditNote,
);

const getSelectedItems = createSelector(
  getCreditNote,
  (creditNote) => creditNote.selectedItems,
);

const getInvoicedItems = createSelector(
  getAppliedCharges,
  (items) => filter((item) => item.invoiced > 0, items),
);

const getAdditionalData = createSelector(
  getCharges,
  (charges) => partition((item) => item.charge.additional, charges),
);

const getServicesData = createSelector(
  getAdditionalData,
  ([, data]) => partition((item) => item.charge.chargeCode.occurrence === occurrenceAdditional, data),
);

const getFeesData = createSelector(
  getServicesData,
  ([, data]) => partition((item) => (item.charge.chargeCode.type === typeFee || item.charge.priceBy === priceByBol), data),
);

const getTransportationData = createSelector(
  getFeesData,
  ([, data]) => data,
);

const getServicesContainersData = createSelector(
  getServicesData,
  ([charges]) => charges.filter((item) => item.charge.priceBy === priceByContainer),
);

const getServicesDataRest = createSelector(
  getServicesData,
  ([charges]) => charges.filter((item) => item.charge.priceBy !== priceByContainer),
);

const getAdditionalContainersData = createSelector(
  getAdditionalData,
  ([data]) => data.filter((item) => item.charge.priceBy === priceByContainer),
);

const getAdditionalRestData = createSelector(
  getAdditionalData,
  ([data]) => data.filter((item) => item.charge.priceBy !== priceByContainer),
);

const getTransportationDataSum = createSelector(
  getTransportationData,
  (data) => calculateInvoiceSum(data),
);

const getServicesDataSum = createSelector(
  getServicesData,
  ([data]) => calculateInvoiceSum(data),
);

const getFeesDataSum = createSelector(
  getFeesData,
  ([data]) => calculateInvoiceSum(data),
);

const getAdditionalDataSum = createSelector(
  getAdditionalData,
  ([data]) => calculateInvoiceSum(data),
);

const getPreparedServicesContainersData = createSelector(
  getServicesContainersData,
  (data) => parseContainers(data),
);

const getTransportationContainers = createSelector(
  getTransportationData,
  (data) => parseContainers(data),
);

const getAdditionalContainers = createSelector(
  getAdditionalContainersData,
  (data) => parseContainers(data),
);

const uniqTransportationContainers = createSelector(
  getTransportationContainers,
  (containers) => uniqBy('id', containers),
);

const uniqAdditionalContainers = createSelector(
  getAdditionalContainers,
  (containers) => uniqBy('id', containers),
);

const uniqServicesContainers = createSelector(
  getPreparedServicesContainersData,
  (containers) => uniqBy('id', containers),
);

const groupedTransportationContainers = createSelector(
  getTransportationData,
  (data) => groupBy((item) => item.charge.container?.id, data),
);

const groupedAdditionalContainers = createSelector(
  getAdditionalContainersData,
  (data) => groupBy((item) => item.charge.container?.id, data),
);

const groupedServicesContainers = createSelector(
  getServicesContainersData,
  (data) => groupBy((item) => item.charge.container?.id, data),
);

const getFullListSelectedItems = createSelector(
  getInvoicedItems,
  getSelectedItems,
  (charges, selected) => filter((item) => includes(item.id, selected), charges),
);

const getAdditionalDataSelected = createSelector(
  getFullListSelectedItems,
  (charges) => partition((item) => item.charge.additional, charges),
);

const getServicesDataSelected = createSelector(
  getAdditionalDataSelected,
  ([, data]) => partition((item) => item.charge.chargeCode.occurrence === occurrenceAdditional, data),
);

const getFeesDataSelected = createSelector(
  getServicesDataSelected,
  ([, data]) => partition((item) => (item.charge.chargeCode.type === typeFee || item.charge.priceBy === priceByBol), data),
);

const getTransportationDataSelected = createSelector(
  getFeesDataSelected,
  ([, data]) => data,
);

const getServicesContainersDataSelected = createSelector(
  getServicesDataSelected,
  ([charges]) => charges.filter((item) => item.charge.priceBy === priceByContainer),
);

const getServicesDataRestSelected = createSelector(
  getServicesDataSelected,
  ([charges]) => charges.filter((item) => item.charge.priceBy !== priceByContainer),
);

const getAdditionalContainersDataSelected = createSelector(
  getAdditionalDataSelected,
  ([data]) => data.filter((item) => item.charge.priceBy === priceByContainer),
);

const getAdditionalRestDataSelected = createSelector(
  getAdditionalDataSelected,
  ([data]) => data.filter((item) => item.charge.priceBy !== priceByContainer),
);

const getTransportationDataSumSelected = createSelector(
  getTransportationDataSelected,
  (data) => calculateInvoiceSum(data),
);

const getServicesDataSumSelected = createSelector(
  getServicesDataSelected,
  ([data]) => calculateInvoiceSum(data),
);

const getFeesDataSumSelected = createSelector(
  getFeesDataSelected,
  ([data]) => calculateInvoiceSum(data),
);

const getAdditionalDataSumSelected = createSelector(
  getAdditionalDataSelected,
  ([data]) => calculateInvoiceSum(data),
);

const getPreparedServicesContainersDataSelected = createSelector(
  getServicesContainersDataSelected,
  (data) => parseContainers(data),
);

const getTransportationContainersSelected = createSelector(
  getTransportationDataSelected,
  (data) => parseContainers(data),
);

const getAdditionalContainersSelected = createSelector(
  getAdditionalContainersDataSelected,
  (data) => parseContainers(data),
);

const uniqTransportationContainersSelected = createSelector(
  getTransportationContainersSelected,
  (containers) => uniqBy('id', containers),
);

const uniqAdditionalContainersSelected = createSelector(
  getAdditionalContainersSelected,
  (containers) => uniqBy('id', containers),
);

const uniqServicesContainersSelected = createSelector(
  getPreparedServicesContainersDataSelected,
  (containers) => uniqBy('id', containers),
);

const groupedTransportationContainersSelected = createSelector(
  getTransportationDataSelected,
  (data) => groupBy((item) => item.charge.container?.id, data),
);

const groupedAdditionalContainersSelected = createSelector(
  getAdditionalContainersDataSelected,
  (data) => groupBy((item) => item.charge.container?.id, data),
);

const groupedServicesContainersSelected = createSelector(
  getServicesContainersDataSelected,
  (data) => groupBy((item) => item.charge.container?.id, data),
);

export const createCreditNoteSelector = {
  uniqTransportationContainersSelected,
  groupedTransportationContainersSelected,
  getTransportationDataSumSelected,
  getServicesDataRestSelected,
  getServicesDataSumSelected,
  uniqServicesContainersSelected,
  groupedServicesContainersSelected,
  getFeesDataSelected,
  getFeesDataSumSelected,
  uniqAdditionalContainersSelected,
  groupedAdditionalContainersSelected,
  getAdditionalDataSumSelected,
  getAdditionalRestDataSelected,
  getAdditionalRestData,
  getServicesDataRest,
  uniqTransportationContainers,
  uniqAdditionalContainers,
  uniqServicesContainers,
  groupedTransportationContainers,
  groupedAdditionalContainers,
  groupedServicesContainers,
  getTransportationDataSum,
  getServicesDataSum,
  getFeesDataSum,
  getAdditionalDataSum,
  getFeesData,
  getInvoicedItems,
};
