import React from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';

import { ContainersComponent } from './Containers.component';

const ContainersContainer = () => {
  const isPending = useSelector(R.selectors.manualBookingWizard.getIsLoading);
  const hasContainers = useSelector(R.selectors.manualBookingWizard.getHasContainers);
  const containers = useSelector(R.selectors.manualBookingWizard.getContainersWithQuantity);

  return (
    <ContainersComponent
      isPending={isPending}
      hasContainers={hasContainers}
      containersQTY={containers.length}
    />
  );
};

export { ContainersContainer as Containers };
