import { emptyScrollbar } from 'app-wrapper/view/themes/components/emptyScrollbar';
import styled from 'styled-components';

import { divFlex } from 'app-wrapper/view/themes/mixin';
import { Button } from 'app-wrapper/view/components';

export const AACSpanEllipsis = styled.span.attrs({
  'data-class': 'AACSpanEllipsis',
})`
  max-width: 100%;
  position: relative;
  display: inline;

  text-overflow: ellipsis;
  overflow: clip;
  white-space: nowrap;
`;

export const AACContentTable = styled.div.attrs({
  'data-class': 'AACContentTable',
})`
  ${divFlex}
  height: calc(100vh - 48px - 56px - 46px - 13px - 13px);
  flex-direction: column;
  width: 100%;

  margin: 13px 24px 13px 0;
  padding: 16px;
  background-color: ${({ theme }) => theme.themesColors.neutralBranding1};
`;

export const AACContentTableTitleWrap = styled.div.attrs({
  'data-class': 'AACContentTableTitleWrap',
})`
  ${divFlex}

  height: 48px;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.themesColors.neutralBranding3};
  padding-bottom: 16px;
`;

export const AACContentTableTitle = styled.div.attrs({
  'data-class': 'AACContentTableTitle',
})`
  ${divFlex}
  width: 100%;
  align-items: center;

  color: ${({ theme }) => theme.themesColors.neutralBranding10};
  font-size: 16px;
  font-weight: 500;
`;

export const AACContentTableHeader = styled.div.attrs({
  'data-class': 'AACContentTableHeader',
})`
  ${divFlex}
  align-items: center;

  height: 36px;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.themesColors.neutralBranding3};

  div[data-class="AACContentTableHeaderItem"]:not(:first-child) {
    margin-left: 8px;
  }
`;

export const AACContentTableHeaderAddBtn = styled(Button).attrs({
  'data-class': 'AACContentTableHeaderAddBtn',
})`
  ${divFlex}
  width: 179px;
  height: 32px;

  svg {
    color: ${({ theme }) => theme.themesColors.neutralBranding1};
    margin-right: 9px;
  }
  span {
    color: ${({ theme }) => theme.themesColors.neutralBranding1};
  }
`;

export const AACContentTableHeaderItem = styled.div.attrs((props: {
  isCenter?: boolean,
  width?: string,
  paddingLeft?: string,
}) => ({
  isCenter: props.isCenter,
  width: props.width,
  paddingLeft: props.paddingLeft,
  'data-class': 'AACContentTableHeaderItem',
}))`
  ${divFlex}
  align-items: center;
  justify-content: ${({ isCenter }) => (isCenter ? 'center' : 'left')};

  width: ${({ width }) => (width || 'auto')};
  padding-left: ${({ paddingLeft }) => (paddingLeft || '0')};

  color: ${({ theme }) => theme.themesColors.secondaryDot45};
  font-size: 12px;
  font-weight: 400;
`;

export const AACContentTableContent = styled.div.attrs({
  'data-class': 'AACContentTableContent',
})`
  ${divFlex}
  ${emptyScrollbar}
  flex-direction: column;

  overflow-y: auto;

  .infinite-scroll-component {
    min-height: 50vh;
  }
`;

export const AACContentTableContentItem = styled.div.attrs({
  'data-class': 'AACContentTableContentItem',
})`
  ${divFlex}
  flex-direction: column;
  width: 100%;

  border-bottom: 1px solid ${({ theme }) => theme.themesColors.neutralBranding3};
`;

export const AACContentTableContentItemTop = styled.div.attrs({
  'data-class': 'AACContentTableContentItemTop',
})`
  ${divFlex}
  ${emptyScrollbar}

  height: 55px;
`;

export const AACContentTableContentTitle = styled.div.attrs((props: {
  isActive?: boolean,
  isCapitalize?: boolean,
  isFirst?: boolean,
}) => ({
  isActive: props.isActive,
  isCapitalize: props.isCapitalize,
  isFirst: props.isFirst,
  'data-class': 'AACContentTableContentTitle',
}))`
  ${divFlex}
  width: calc(23.6%);
  align-items: center;
  justify-content: start;

  margin-left: ${({ isFirst }) => (isFirst ? '0' : '8px')};
  color: ${({ theme }) => theme.themesColors.characterBrandingTitle85};
  color: ${({ theme, isActive }) => (isActive ? theme.themesColors.primaryBranding6 : theme.themesColors.characterBrandingTitle85)};
  font-size: 14px;
  font-weight: 400;

  ${({ isCapitalize }) => isCapitalize && 'text-transform: capitalize'};
`;

export const AACContentTableContentLink = styled.div.attrs((props: {
  width?: string,
  justifyContent?: string,
}) => ({
  width: props.width,
  justifyContent: props.justifyContent,
  'data-class': 'AACContentTableContentLink',
}))`
  ${divFlex}
  width: ${({ width }) => (width || '23.6%')};
  align-items: center;
  justify-content: ${({ justifyContent }) => (justifyContent || 'left')};

  color: ${({ theme }) => theme.themesColors.primaryBranding7};
  font-size: 14px;
  font-weight: 400;

  text-overflow: ellipsis;
  overflow: clip;
  white-space: nowrap;
  cursor: pointer;

  > span:first-letter {
    text-transform: uppercase;
  }
`;

export const AACContentTableContentPrimary = styled.div.attrs({
  'data-class': 'AACContentTableContentPrimary',
})`
  ${divFlex}
  width: 58px;
  height: 22px;
  align-items: center;
  justify-content: center;

  background-color: ${({ theme }) => theme.themesColors.polarGreen1};
  color: ${({ theme }) => theme.themesColors.polarGreen7};
  font-size: 12px;
  font-weight: 400;
  border: 1px solid ${({ theme }) => theme.themesColors.polarGreen3};
  margin-left: 8px;
  border-radius: 3px;
`;

export const AACContentTableContactWrap = styled.div.attrs({
  'data-class': 'AACContentTableContactWrap',
})`
  ${divFlex}
  width: 23.6%;
  align-items: center;
  justify-content: center;
`;

export const AACContentTableContact = styled.div.attrs({
  'data-class': 'AACContentTableContact',
})`
  ${divFlex}
  align-items: center;
  justify-content: center;
  min-width: 24px;
  width: 24px;
  height: 24px;

  margin: auto;
  color: ${({ theme }) => theme.themesColors.primaryBranding5};
  background-color: ${({ theme }) => theme.themesColors.primaryBranding1};
  font-size: 12px;
  font-weight: 400;
  border-radius: 100px;
`;
