import React, { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';

import { GensetFieldComponent } from './GensetField.component';

const GensetFieldContainer: FC = () => {
  const temperatureControl = useSelector(R.selectors.manualBookingWizard.getTemperatureControl);

  const genset = useMemo(() => (temperatureControl ? temperatureControl.genset : false), [temperatureControl]);

  return (
    <GensetFieldComponent
      genset={genset}
    />
  );
};

export {
  GensetFieldContainer as GensetField,
};
