import styled from 'styled-components';

import { Wrapper } from 'app-wrapper/view/guideline';

export const Page = styled(Wrapper)``;

export const Title = styled.span`
  font-size: 20px;
  line-height: 28px;
  font-weight: 500;
`;

export const Section = styled.div`
  margin-top: 8px;
  padding: 16px;
  min-height: 336px;
  border-radius: 3px;
  display: flex;
  flex-direction: row;
  gap: 8px;
  border: 1px solid ${({ theme }) => theme.themesColors.neutralBranding4};
  background-color: ${({ theme }) => theme.themesColors.neutralBranding1};
`;

export const LocationWrap = styled.div`
  width: 50%;
  min-height: 100%;
  border: 1px solid ${({ theme }) => theme.themesColors.neutralBranding4};
`;
