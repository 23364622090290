import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { UploadFile } from 'antd/lib/upload/interface';

import { useAuthToken } from 'app-wrapper/hooks';
import { UC } from 'shipment-operations/controllers';
import { R } from 'shipment-operations/repository';

import { SDHUploadDocumentComponent } from './SDHUploadDocument.component';

interface ISDHUploadDocumentContainerProps {
  file?: UploadFile;
  containerId: number;
}

const SDHUploadDocumentContainer: FC<ISDHUploadDocumentContainerProps> = ({ file: propsFile, containerId }) => {
  const authToken = useAuthToken();
  const permissions = useSelector(R.selectors.shipment.getDocumentsPermissions);

  return (
    <SDHUploadDocumentComponent
      authToken={authToken}
      containerId={containerId}
      file={propsFile}
      previewDocument={UC.shipmentDocumentAdditional.downloadFileDocument}
      setIsLoading={UC.shipmentDocuments.setIsHazmatLoading}
      setContainerFile={UC.shipmentDocuments.setHazmatContainerFile}
      deleteHazmatDocument={UC.shipmentDocuments.removeHazmatDocument}
      addHazmatDocument={UC.shipmentDocuments.addHazmatDocument}
      imoDeclarationPermission={permissions.imoDeclarations}
    />
  );
};

export { SDHUploadDocumentContainer as SDHUploadDocument };
