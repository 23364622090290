import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Divider from 'antd/es/divider';
import Typography from 'antd/es/typography';
import SolutionOutlined from '@ant-design/icons/SolutionOutlined';
import EnvironmentOutlined from '@ant-design/icons/EnvironmentOutlined';
import UserOutlined from '@ant-design/icons/UserOutlined';
import MailOutlined from '@ant-design/icons/MailOutlined';
import PhoneOutlined from '@ant-design/icons/PhoneOutlined';
import ProfileOutlined from '@ant-design/icons/ProfileOutlined';
import ReconciliationOutlined from '@ant-design/icons/ReconciliationOutlined';

import { ShippingPartyReference } from 'shipment-operations/models/dtm';

import { Collapse, Header } from './components';
import {
  ShippingPartyGrid,
  ShippingPartyFieldContent,
  ShippingPartyField,
  ShippingPartyFieldLabel,
} from './ShippingPartyCard.styled';

interface IShippingPartyCardProps {
  selectionKey: string | number
  header: string
  companyName?: string
  addressLine?: string
  contactPerson?: string
  email?: string
  phone?: string
  additionalPhone?: string
  usciNumber?: string
  taxId?: string
  defaultActive?: boolean
  references: ShippingPartyReference[]
}

export const ShippingPartyCard: FC<IShippingPartyCardProps> = ({
  defaultActive,
  selectionKey,
  header,
  usciNumber,
  taxId,
  additionalPhone,
  companyName = '-',
  addressLine = '-',
  contactPerson = '-',
  email = '-',
  phone = '-',
  references,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Divider dashed style={{ marginTop: '16px', marginBottom: '16px' }} />
      <Collapse
        defaultActive={defaultActive}
        selectionKey={selectionKey}
        header={(
          <Header
            shippingParty={header}
            companyName={companyName}
            references={references}
          />
        )}
      >
        <ShippingPartyGrid>
          <ShippingPartyField>
            <SolutionOutlined />
            <ShippingPartyFieldContent>
              <ShippingPartyFieldLabel>{t('Company Name')}</ShippingPartyFieldLabel>
              <Typography.Text>{companyName}</Typography.Text>
            </ShippingPartyFieldContent>
          </ShippingPartyField>
          <ShippingPartyField>
            <EnvironmentOutlined />
            <ShippingPartyFieldContent>
              <ShippingPartyFieldLabel>{t('Company Address')}</ShippingPartyFieldLabel>
              <Typography.Text>{addressLine}</Typography.Text>
            </ShippingPartyFieldContent>
          </ShippingPartyField>
          <ShippingPartyField>
            <UserOutlined />
            <ShippingPartyFieldContent>
              <ShippingPartyFieldLabel>{t('Contact Person')}</ShippingPartyFieldLabel>
              <Typography.Text>{contactPerson}</Typography.Text>
            </ShippingPartyFieldContent>
          </ShippingPartyField>
          <ShippingPartyField>
            <MailOutlined />
            <ShippingPartyFieldContent>
              <ShippingPartyFieldLabel>{t('Email')}</ShippingPartyFieldLabel>
              <Typography.Text>{email}</Typography.Text>
            </ShippingPartyFieldContent>
          </ShippingPartyField>
          <ShippingPartyField>
            <PhoneOutlined />
            <ShippingPartyFieldContent>
              <ShippingPartyFieldLabel>{t('Phone')}</ShippingPartyFieldLabel>
              <Typography.Text>{phone}</Typography.Text>
            </ShippingPartyFieldContent>
          </ShippingPartyField>
          {additionalPhone && (
            <ShippingPartyField>
              <PhoneOutlined />
              <ShippingPartyFieldContent>
                <ShippingPartyFieldLabel>{t('Additional Phone')}</ShippingPartyFieldLabel>
                <Typography.Text>{additionalPhone}</Typography.Text>
              </ShippingPartyFieldContent>
            </ShippingPartyField>
          )}
          {usciNumber && (
            <ShippingPartyField>
              <ProfileOutlined />
              <ShippingPartyFieldContent>
                <ShippingPartyFieldLabel>{t('USCI Number')}</ShippingPartyFieldLabel>
                <Typography.Text>{usciNumber}</Typography.Text>
              </ShippingPartyFieldContent>
            </ShippingPartyField>
          )}
          {taxId && (
            <ShippingPartyField>
              <ReconciliationOutlined />
              <ShippingPartyFieldContent>
                <ShippingPartyFieldLabel>{t('Tax ID Number')}</ShippingPartyFieldLabel>
                <Typography.Text>{taxId}</Typography.Text>
              </ShippingPartyFieldContent>
            </ShippingPartyField>
          )}
        </ShippingPartyGrid>
      </Collapse>
    </>
  );
};
