import { emptyScrollbar } from 'app-wrapper/view/themes/components/emptyScrollbar';
import styled from 'styled-components';

import { divFlex } from 'app-wrapper/view/themes/mixin';
import { Button } from 'app-wrapper/view/components';

export const CBByIdAddCPBlockEllipsis = styled.span.attrs({
  'data-class': 'CBByIdAddCPBlockEllipsis',
})`
  max-width: 100%;
  position: relative;
  display: inline;

  text-overflow: ellipsis;
  overflow: clip;
  white-space: nowrap;
`;

export const CBByIdAddCPBlockWrap = styled.div.attrs({
  'data-class': 'CBByIdAddCPBlockWrap',
})`
  ${divFlex}
  ${emptyScrollbar}
  flex-direction: column;
  align-items: center;

  padding: 0;
  height: 100%;
  width: 100%;
  background-color: ${({ theme }) => theme.themesColors.neutralBranding3};

  .ant-input, &#CBByIdAddCPBlockWrap .ant-select-selector {
    border-color: ${({ theme }) => theme.themesColors.neutralBranding6};
  }
`;

export const CBByIdAddCPBlockContent = styled.div.attrs({
  'data-class': 'CBByIdAddCPBlockContent',
})`
  ${divFlex}
  ${emptyScrollbar}
  flex-direction: column;
  overflow: auto;

  font-weight: 400;
  font-size: 12px;
  color: ${({ theme }) => theme.themesColors.neutralBranding10};

  padding: 16px;
  height: 100%;
  width: 100%;
  border: none;
  background-color: ${({ theme }) => theme.themesColors.neutralBranding1};
`;

export const CBByIdAddCPBlockFooter = styled.div.attrs({
  'data-class': 'CBByIdAddCPBlockFooter',
})`
  ${divFlex}
  justify-content: end;
  z-index: 4;
  width: calc(100%);
  height: 64px;
  bottom: 0px;
  align-items: center;
  padding: 14px 24px;

  background-color: ${({ theme }) => theme.themesColors.neutralBranding1};
  box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px rgba(0, 0, 0, 0.08), 0px 9px 28px 8px rgba(0, 0, 0, 0.05);
`;

export const CBByIdAddCPBlockButtonRemove = styled(Button).attrs({
  'data-class': 'CBByIdAddCPBlockButtonRemove',
})`
  ${divFlex}

  font-size: 16px;
  font-weight: 400;
  color: ${({ theme }) => theme?.themesColors?.characterBrandingDanger};
  background: ${({ theme }) => theme?.themesColors?.neutralBranding1};
  border: 1px solid ${({ theme }) => theme?.themesColors?.characterBrandingDanger50};
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
  border-radius: 3px;

  width: 90px;
  height: 40px;
  align-items: center;
  justify-content: center;
  right: 0;
  margin-right: auto;
`;

export const CBByIdAddCPBlockButtonCancel = styled(Button).attrs({
  'data-class': 'CBByIdAddCPBlockButtonCancel',
})`
  ${divFlex}

  font-size: 16px;
  font-weight: 400;
  color: ${({ theme }) => theme?.themesColors?.neutralBranding10};
  background: ${({ theme }) => theme?.themesColors?.neutralBranding1};
  border: 1px solid ${({ theme }) => theme?.themesColors?.neutralBranding5};
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
  border-radius: 3px;

  width: 67px;
  height: 40px;
  align-items: center;
  justify-content: center;
  right: 0;
`;

export const CBByIdAddCPBlockButtonSave = styled(Button).attrs({
  'data-class': 'CBByIdAddCPBlockButtonSave',
})`
  ${divFlex}

  font-size: 16px;
  font-weight: 400;
  color: ${({ theme }) => theme?.themesColors?.neutralBranding1};
  background-color: ${({ theme }) => theme?.themesColors?.neutralBranding8};
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
  border-radius: 3px;

  width: 67px;
  height: 40px;
  align-items: center;
  justify-content: center;
  right: 0;
  margin-left: 8px;
`;
