import React, {
  FC, useContext, useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';

import { ShipmentAllStatsDTM, ShipmentPreviewDTM } from 'shipment-operations/models/dtm';
import { ShipmentCard } from 'shipment-operations/view/components';

import type { History, Transition } from 'history';
import Modal from 'antd/es/modal';
import { UNSAFE_NavigationContext as NavigationContext } from 'react-router-dom';
import {
  ShipmentHeaderContainer,
} from './ShipmentHeader.styled';
import { ShipmentTitle } from './ShipmentTitle';

interface IShipmentHeaderComponentProps {
  shipment?: ShipmentPreviewDTM;
  shipmentStats?: ShipmentAllStatsDTM;
  onClickToCustomer: (id?: number) => () => void
  onChangeIsOpenModalShipmentReference: (value: boolean) => void
  isUnconfirmedChanges?: boolean
}

export const ShipmentHeaderComponent: FC<IShipmentHeaderComponentProps> = ({
  shipment,
  shipmentStats,
  onClickToCustomer,
  onChangeIsOpenModalShipmentReference,
  isUnconfirmedChanges,
}) => {
  const { t } = useTranslation();
  const navigator = useContext(NavigationContext)?.navigator as History;

  useEffect(() => {
    if (isUnconfirmedChanges) {
      const unblock = navigator.block((tx: Transition) => {
        const nextUrl = tx.location.pathname;

        if (nextUrl.startsWith('/shipments/')) {
          unblock();
          tx.retry();

          return;
        }

        Modal.confirm({
          title: t('unconfirmedChanges'),
          okText: t('Yes'),
          cancelText: t('No'),
          onOk: () => {
            unblock();
            tx.retry();
          },
        });
      });

      return unblock;
    }

    return () => {};
  }, [navigator.location.pathname, isUnconfirmedChanges]);

  return (
    <ShipmentHeaderContainer>
      {
        shipment && (
          <>
            <ShipmentTitle />
            <ShipmentCard
              key={shipment.shipmentName}
              shipmentData={shipment}
              shipmentStats={shipmentStats}
              $isClickable={false}
              isReference={!!shipment.reference}
              isReferenceHeader
              onClickToCustomer={onClickToCustomer(shipment.id)}
              onChangeIsOpenModalShipmentReference={onChangeIsOpenModalShipmentReference}
            />
          </>
        )
      }
    </ShipmentHeaderContainer>
  );
};
