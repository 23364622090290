import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { UC } from 'user-management/controllers';
import { R } from 'user-management/repository';
import { R as authR } from 'authentication/repository';

import { UserManagementContentComponent } from './UserManagementContent.component';

const UserManagementContentContainer: FC = () => {
  const userList = useSelector(R.selectors.userManagement.getUserList);
  const isLoading = useSelector(R.selectors.userManagement.getIsLoading);
  const isAdmin = useSelector(R.selectors.moduleFunctionalityPermissions.getIsAdmin);
  const authEmail = useSelector(authR.selectors.auth.getEmail);

  return (
    <UserManagementContentComponent
      usersList={userList}
      isLoading={isLoading}
      onEdit={UC.userManagement.openEditPanel}
      isAdmin={isAdmin}
      authEmail={authEmail}
    />
  );
};

export { UserManagementContentContainer as UserManagementContent };
