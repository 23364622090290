import React, { FC, useCallback } from 'react';
import SettingOutlined from '@ant-design/icons/SettingOutlined';
import UserOutlined from '@ant-design/icons/UserOutlined';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { RouteNames } from 'app-wrapper/constants';
import { Button } from 'app-wrapper/view/components';

import {
  UserDataContainer,
  UserIconContainer,
  UserName,
  UserEmail,
  MyAccount,
  SignOut,
  SignOutButton,
  UserIconDefaultBG,
} from './UserMenuItem.styled';

interface IUserMenuItemComponentProps {
  userName: string
  userEmail: string
  onSignOut: () => void
  onClosePopover: () => void
}

export const UserMenuItemComponent: FC<IUserMenuItemComponentProps> = ({
  userName, userEmail, onSignOut, onClosePopover,
}) => {
  const { t } = useTranslation();

  const onSignOutClick = useCallback(() => {
    onSignOut();
    onClosePopover();
  }, []);

  return (
    <UserDataContainer>
      <UserIconContainer>
        <UserIconDefaultBG>
          <UserOutlined />
        </UserIconDefaultBG>
      </UserIconContainer>
      <UserName>
        {userName}
      </UserName>
      <UserEmail>
        {userEmail}
      </UserEmail>
      <MyAccount>
        <NavLink to={RouteNames.ACCOUNT()}>
          <Button size="small" icon={<SettingOutlined />} onClick={onClosePopover}>
            {t('My Account')}
          </Button>
        </NavLink>
      </MyAccount>
      <SignOut>
        <SignOutButton type="ghost" size="small" onClick={onSignOutClick}>
          {t('Sign Out')}
        </SignOutButton>
      </SignOut>
    </UserDataContainer>
  );
};
