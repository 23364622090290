import {
  IsBoolean,
  IsDefined, IsNumber, IsOptional, IsString, ValidateNested,
} from 'class-validator';
import { Type } from 'class-transformer';
import { BaseDTM } from 'proto/BaseDTM';

import { ContactDTM } from './Contact.dtm';

export interface IAddressDTM {
  id: number
  country: string
  state?: string
  city: string
  address1: string
  address2?: string
  postalCode: string
  closestPort: string
  contacts?: ContactDTM[]
  primary?: boolean
}

export class AddressDTM extends BaseDTM<IAddressDTM> {
  @IsDefined()
  @IsNumber()
  id: number;

  @IsDefined()
  @IsString()
  country: string;

  @IsOptional()
  @IsString()
  state?: string;

  @IsDefined()
  @IsString()
  city: string;

  @IsDefined()
  @IsString()
  address1: string;

  @IsOptional()
  @IsString()
  address2?: string;

  @IsDefined()
  @IsString()
  postalCode: string;

  @IsDefined()
  @IsString()
  closestPort: string;

  @IsOptional()
  @ValidateNested({ each: true })
  @Type(() => ContactDTM)
  contacts?: ContactDTM[];

  @IsOptional()
  @IsBoolean()
  primary?: boolean;

  getAddressLine = () => {
    let addressLine = this.city;
    if (this.state) {
      addressLine += `, ${this.state}`;
    }
    addressLine += `, ${this.country}, ${this.address1}, ${this.postalCode}`;

    return addressLine;
  }
}
