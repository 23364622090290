import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { UC } from 'shipment-operations/controllers';
import { R } from 'shipment-operations/repository';

import { ShipperReferenceInputComponent } from './ShipperReferenceInput.component';

const ShipperReferenceInputContainer: FC = () => {
  const reference = useSelector(R.selectors.bookingWizard.getShipmentReference);
  const getShipmentReferenceError = useSelector(R.selectors.bookingWizard.getShipmentReferenceError);

  return (
    <ShipperReferenceInputComponent
      reference={reference}
      isError={getShipmentReferenceError}
      onChangeReference={UC.bookingWizard.onChangeReference}
    />
  );
};

export { ShipperReferenceInputContainer as ShipperReferenceInput };
