import {
  IsEnum,
  IsOptional,
} from 'class-validator';
import { BaseDTM } from 'proto/BaseDTM';

import { PermissionAttributePolicy } from 'shipment-operations/constants';

export interface IWorkgroupPermissionsDTM {
  companyNameVisibility?: PermissionAttributePolicy;
}

export class WorkgroupPermissionsDTM extends BaseDTM<IWorkgroupPermissionsDTM> {
  @IsOptional()
  @IsEnum(PermissionAttributePolicy)
  companyNameVisibility?: PermissionAttributePolicy;
}
