import React, { FC } from 'react';

import { UC } from 'shipment-operations/controllers';
import { R } from 'shipment-operations/repository';
import { useAppSelector } from 'app-wrapper/hooks';

import { ContainerViewDTM } from 'shipment-operations/models/dtm';
import { ExpandPanelSelectable } from 'shipment-operations/view/components';

interface IExpandPanelProps {
  container: ContainerViewDTM
  sum: number
  keys: number[]
}

const ExpandPanel: FC<IExpandPanelProps> = ({
  container, sum, keys, children,
}) => {
  const selectedCharges = useAppSelector(R.selectors.createInvoiceCharges.getSelectedCharges);

  return (
    <ExpandPanelSelectable
      container={container}
      sum={sum}
      selectedCharges={selectedCharges}
      onSubTableSelect={UC.createInvoiceCharges.setGroupSelectedCharges}
      keys={keys}
    >
      {children}
    </ExpandPanelSelectable>
  );
};

export { ExpandPanel };
