import styled from 'styled-components';
import Typography from 'antd/es/typography';

export const Commodity = styled.div`
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  padding: 16px 16px 8px 16px;
  background-color: white;
`;

export const CommodityHeader = styled(Typography)`
  width: 100%;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  padding-bottom: 16px;
  margin-bottom: 16px;
  border-bottom: 1px solid #EAEEF8;
`;

export const CommodityRowsHeader = styled.div`
  margin-bottom: 4px;
  display: grid;
  gap: 4px;
  grid-template-columns: 255px 255px 95.5px 95.5px 95.5px 95.5px 105px 32px;
`;

export const CommodityRowsHeaderNoHazmat = styled.div`
  margin-bottom: 4px;
  display: grid;
  gap: 4px;
  grid-template-columns: 255px 255px 122.75px 122.75px 122.75px 122.75px 32px;
`;

export const CommodityRowsHeaderTitle = styled.div`
  width: 100%;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
`;
