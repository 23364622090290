import React, {
  FC,
  SyntheticEvent,
  useMemo,
  useCallback,
} from 'react';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';

import { FlexRow } from 'app-wrapper/view/guideline';
import { ADDITIONAL_SERVICES_DOCUMENTS_NAMES, DocumentType } from 'shipment-operations/constants';
import { ContainerDocumentDTM } from 'shipment-operations/models/dtm';
import { Tag, ClockIcon } from 'shipment-operations/view/pages/ShipmentAdditionalService/components/ServiceWorkflow/сomponents/ServiceDetails/components/InputResults/components/InputResult/InputResult.styled';

import {
  DocumentCollapse,
  CollapsePanel,
  CollapseIcon,
  CollapseIconActive,
  CollapseLabel,
  CollapseLabelText,
  OptionalLabelText,
  TableCell,
  TableDocuments,
  BoldText,
  UploadIcon,
  SuccessIcon,
  WaitingIcon,
  EndAligned,
  DownloadTemplate,
} from './DocumentsSection.styled';
import { ActionMenu, OptionalIcon } from './components';

interface DocumentsSectionProps {
  isCollapsed?: boolean;
  documents: ContainerDocumentDTM[];
  type: DocumentType;
  onDownload: (shipmentId: string, documentId: number, documentName: string) => void;
  onRemove?: (shipmentId: string, document: ContainerDocumentDTM) => void;
  openDocumentUploadDrawer?: () => void;
  isOptional?: boolean;
  hideStatus?: boolean;
  showWaitingFromSkypace?: boolean;
  templateURL?: string;
}

export const DocumentsSection: FC<DocumentsSectionProps> = ({
  documents,
  isCollapsed,
  onDownload,
  onRemove,
  openDocumentUploadDrawer,
  type,
  isOptional,
  hideStatus,
  showWaitingFromSkypace,
  templateURL,
}) => {
  const { shipmentId = '' } = useParams();
  const { t } = useTranslation();

  const documentName = useMemo(() => ADDITIONAL_SERVICES_DOCUMENTS_NAMES.find(({ code }) => code === type)?.description || t('Document'), [type, t]);

  const handleOpenDocumentUploadDrawer = useCallback((e: SyntheticEvent) => {
    e.stopPropagation();

    if (openDocumentUploadDrawer) {
      openDocumentUploadDrawer();
    }
  }, [openDocumentUploadDrawer]);

  const handleDownloadTemplate = useCallback((e) => {
    e.stopPropagation();
  }, []);

  const columns = useMemo(() => [
    {
      title: t('Document Name'),
      width: '80%',
      key: 'object',
      align: 'left' as 'left',
      render: (item: string, record: ContainerDocumentDTM | {}) => {
        const document = record as ContainerDocumentDTM;

        return (
          <BoldText>
            {document.name}
          </BoldText>
        );
      },
    },
    {
      title: t('Action'),
      width: '20%',
      key: 'object',
      align: 'right' as 'right',
      render: (item: string, record: ContainerDocumentDTM | {}) => {
        const document = record as ContainerDocumentDTM;

        const handleDelete = onRemove ? () => onRemove(shipmentId, document) : undefined;
        const handleDownload = onDownload ? () => onDownload(shipmentId, document.response.id, document.response.name) : undefined;

        return (
          <TableCell>
            <ActionMenu
              onDownload={handleDownload}
              onDelete={handleDelete}
            />
          </TableCell>
        );
      },
    },
  ], [t, shipmentId]);

  return (
    <DocumentCollapse>
      <CollapsePanel
        key={documentName}
        header={(
          <CollapseLabel>
            {isCollapsed ? (
              <CollapseIconActive />
            ) : (
              <CollapseIcon />
            )}

            <CollapseLabelText isCollapsed={isCollapsed}>
              {documentName}

              {!hideStatus ? (
                <>
                  {isOptional && !documents.length ? (
                    <FlexRow>
                      <OptionalLabelText>
                        {t('(Optional)')}
                      </OptionalLabelText>

                      {type === DocumentType.PCKL ? (
                        <OptionalIcon />
                      ) : null}
                    </FlexRow>
                  ) : (
                    <>
                      {documents.length ? (
                        <SuccessIcon />
                      ) : (
                        <WaitingIcon />
                      )}
                    </>
                  )}
                </>
              ) : (
                <>
                  {documents.length ? (
                    <SuccessIcon />
                  ) : null}
                </>
              )}
            </CollapseLabelText>

            <EndAligned>
              {templateURL && !showWaitingFromSkypace ? (
                <DownloadTemplate onClick={handleDownloadTemplate} link={templateURL}>
                  {t('Download template')}
                </DownloadTemplate>
              ) : null}

              {openDocumentUploadDrawer ? (
                <UploadIcon onClick={handleOpenDocumentUploadDrawer} />
              ) : null}

              {showWaitingFromSkypace && !documents.length ? (
                <Tag icon={<ClockIcon />}>
                  {t('Waiting from Skypace')}
                </Tag>
              ) : null}
            </EndAligned>
          </CollapseLabel>
        )}
      >
        <TableDocuments
          pagination={false}
          columns={columns}
          dataSource={documents}
        />
      </CollapsePanel>
    </DocumentCollapse>
  );
};
