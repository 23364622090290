import React from 'react';

import { IPropsIcon } from 'app-wrapper/interfaces/icon';

import { Icon } from './MessageResponseDialog.styled';

export const MessageResponseDialog = (props: IPropsIcon) => {
  const { width, height, fill } = props;
  return (
    <Icon
      className="MessageResponseDialog"
      width={width || '30'}
      height={height || '24'}
      viewBox="0 0 30 24"
      fill={fill || 'currentColor'}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path fillRule="evenodd" clipRule="evenodd" d="M6.81647 21.9026L2.32304 23.6461C2.20761 23.6909 2.08185 23.7023 1.96022 23.6789C1.8386 23.6555 1.72607 23.5982 1.63555 23.5137C1.54504 23.4292 1.48022 23.3208 1.44856 23.2011C1.41691 23.0813 1.41969 22.9551 1.45659 22.8368L2.73093 18.7533C1.02764 16.8164 0.0276489 14.4546 0.0276489 11.9059C0.0276489 5.33026 6.68422 0 14.8961 0C23.1059 0 29.7632 5.33026 29.7632 11.9059C29.7632 18.4816 23.1066 23.8118 14.8954 23.8118C11.9165 23.8118 9.14278 23.1105 6.81647 21.9026Z" fill="currentColor" />
    </Icon>
  );
};
