import React, {
  FC,
  memo,
  useCallback,
  useMemo,
} from 'react';
import sortBy from 'lodash/fp/sortBy';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import Skeleton from 'antd/es/skeleton';

import { REQUEST_STATUS } from 'app-wrapper/constants';
import { OrganizationStatus } from 'user-management/view/components';
import { OrganizationDTM } from 'user-management/models/dtm';
import { EReviewAction } from 'user-management/constants';

import {
  UMCContentTable,
  UMCContentTableContentLink,
  UMCContentTableContentItem,
  UMCContentTableContentItemTop,
  UMCContentTableContentTitle,
  UMCContentTableCreatedAtItem,
  UMCContentTableHeader,
  UMCContentTableHeaderItem,
  UMCSpanEllipsis,
} from './CustomerNotVerifiedTable.styled';

interface ICustomerNotVerifiedTableProps {
  paginate: () => void;
  onClickCustomer: (id: string) => void;
  isPending?: string;
  isPendingAdd?: string;
  customers?: OrganizationDTM[];
  isEnd?: boolean;
}

const CustomerNotVerifiedTable: FC<ICustomerNotVerifiedTableProps> = ({
  paginate,
  onClickCustomer,
  isPending,
  isPendingAdd,
  customers,
  isEnd,
}) => {
  const { t } = useTranslation();

  const onClickCustomerInternalHandler = useCallback(
    (id: string) => () => {
      onClickCustomer(id);
    },
    [onClickCustomer],
  );

  const sortedItems = useMemo(() => {
    if (!customers) {
      return [];
    }

    return sortBy((item) => item?.createdAt?.date, [...customers]).reverse();
  }, [customers]);

  return (
    <UMCContentTable>
      <UMCContentTableHeader>
        <UMCContentTableHeaderItem
          width="18%"
        >
          {t('Company')}
        </UMCContentTableHeaderItem>
        <UMCContentTableHeaderItem
          width="18%"
        >
          {t('Location')}
        </UMCContentTableHeaderItem>
        <UMCContentTableHeaderItem
          width="18%"
        >
          {t('Phone')}
        </UMCContentTableHeaderItem>
        <UMCContentTableHeaderItem
          width="18%"
        >
          {t('Email')}
        </UMCContentTableHeaderItem>
        <UMCContentTableHeaderItem
          width="10%"
          marginLeft="0 !important"
          marginRight="8px"
        >
          {t('Date created')}
        </UMCContentTableHeaderItem>
        <UMCContentTableHeaderItem
          width="18%"
          marginLeft="0 !important"
          marginRight="8px"
        >
          {t('Status')}
        </UMCContentTableHeaderItem>
      </UMCContentTableHeader>

      <>
        <InfiniteScroll
          dataLength={Number(customers?.length || 0)}
          next={paginate}
          hasMore={!isEnd}
          loader={
            isPending === REQUEST_STATUS.pending
            && (<Skeleton />)
          }
          scrollableTarget="Wrapper"
        >
          {sortedItems?.map((item) => (
            <UMCContentTableContentItem key={item.customId}>
              <UMCContentTableContentItemTop>
                <UMCContentTableContentLink
                  onClick={onClickCustomerInternalHandler(`${item.id || ''}`)}
                >
                  <UMCSpanEllipsis>
                    {item.name}
                  </UMCSpanEllipsis>
                </UMCContentTableContentLink>

                <UMCContentTableContentTitle>
                  <UMCSpanEllipsis>
                    {item.getAddressCityCountry()}
                  </UMCSpanEllipsis>
                </UMCContentTableContentTitle>

                <UMCContentTableContentTitle>
                  <UMCSpanEllipsis>
                    {item?.phone || ''}
                  </UMCSpanEllipsis>
                </UMCContentTableContentTitle>

                <UMCContentTableContentTitle>
                  <UMCSpanEllipsis>
                    {item?.email || ''}
                  </UMCSpanEllipsis>
                </UMCContentTableContentTitle>

                <UMCContentTableCreatedAtItem>
                  <UMCSpanEllipsis>
                    {item?.createdAt?.getDateMMMDYYYY() || ''}
                  </UMCSpanEllipsis>
                </UMCContentTableCreatedAtItem>

                <UMCContentTableContentTitle isCapitalize>
                  <UMCSpanEllipsis>
                    {item.status ? (
                      <OrganizationStatus
                        status={item.status}
                        isAdditionalInfoTooltip={item.review?.action === EReviewAction.REQUEST_ADDITIONAL_INFO}
                        tooltipName={item.review?.note}
                      />
                    ) : null}
                  </UMCSpanEllipsis>
                </UMCContentTableContentTitle>
              </UMCContentTableContentItemTop>
            </UMCContentTableContentItem>
          ))}
        </InfiniteScroll>
        {isPendingAdd === REQUEST_STATUS.pending && (<Skeleton />)}
      </>

    </UMCContentTable>
  );
};

const CustomerNotVerifiedTableCache = memo(CustomerNotVerifiedTable);

export { CustomerNotVerifiedTableCache as CustomerNotVerifiedTable };
