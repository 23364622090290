import styled from 'styled-components';
import Button from 'antd/es/button';

import { divFlex, responsive } from 'app-wrapper/view/themes/mixin';
import { Collapse, Panel } from 'app-wrapper/view/components';

export const AdditionalServicesComponentWrap = styled.div.attrs((props: {
  hasError: boolean,
}) => ({
  hasError: props.hasError,
  'data-class': 'AdditionalServicesComponentWrap',
}))`
  ${divFlex}
  width: 100%;
  flex-direction: column;

  .ant-collapse-item {
    flex-direction: column;
  }

  .ant-select-selector {
    min-width: ${({ theme }) => theme?.inputConst?.defaultHeight};
    ${({ hasError, theme }) => hasError && `border-color: ${theme.themesColors.characterBrandingDanger} !important; border-style: solid;`};
  }
  ${responsive.forFullMedia`
    .ant-select-selector {
      min-width: ${({ theme }: { theme: { inputConst: { hdHeight: string } } }) => theme?.inputConst?.hdHeight};
    }
  `}

  .ant-collapse-content-box {
    ${divFlex}
  }
`;

export const AdditionalServicesCollapse = styled(Collapse).attrs((props: {
  hasError: boolean,
}) => ({
  hasError: props.hasError,
  'data-class': 'AdditionalServicesCollapse',
}))`
  ${divFlex}
  width: 100%;

  background-color: ${({ theme }) => theme?.themesColors?.neutralBranding1};
  border-left: none;
  border-right: none;
  border-top: none;
  .ant-collapse-content-active {
    border: none;
  }

  .ant-collapse-item > .ant-collapse-header {
    padding: 16px 16px 16px 0;
  }

  .anticon-right.ant-collapse-arrow {
    width: 8px;
  }
  .ant-collapse-content > .ant-collapse-content-box {
    padding-top: 0px;
  }
`;

export const AdditionalServicesPanel = styled(Panel).attrs({
  'data-class': 'AdditionalServicesPanel',
})`
  ${divFlex}
  width: 100%;
`;

export const AdditionalServicesService = styled.div.attrs({
  'data-class': 'AdditionalServicesService',
})`
  ${divFlex}
  height: 22px;
  padding: 1px 8px;
  margin-right: 8px;
  align-items: center;
  margin-bottom: 8px;

  font-size: 12px;
  font-weight: 400;
  background-color: ${({ theme }) => theme?.themesColors?.neutralBranding3};
  border: 1px solid  ${({ theme }) => theme?.themesColors?.neutralBranding4};
  border-radius: 2px;

  svg {
    color: ${({ theme }) => theme?.themesColors?.secondaryDot45};
    margin-left: 4px;
    cursor: pointer;
  }
`;

export const AdditionalServicesServiceTitleWrap = styled.div.attrs({
  'data-class': 'AdditionalServicesServiceTitleWrap',
})`
  ${divFlex}
  width: 100%;

  box-shadow: inset 0px -1px 0px #f4f5f8;
  padding-bottom: 9px;
  padding-top: 8px;
  margin-bottom: 0px;
  margin-top: 8px;
`;

export const AdditionalServicesServiceTitle = styled.div.attrs({
  'data-class': 'AdditionalServicesServiceTitle',
})`
  ${divFlex}
  margin-right: 8px;

  ${({ theme }) => theme?.fonts?.BodyTextMedium};
`;

export const AdditionalServicesServiceContent = styled.div.attrs((props: {
  isCollapse: boolean
  'data-class'?: string
}) => ({
  isCollapse: props.isCollapse,
  'data-class': props?.['data-class'] || 'AdditionalServicesServiceContent',
}))`
  ${divFlex}
  flex-wrap: wrap;
  margin-top: ${({ isCollapse }) => (isCollapse ? '6px' : '16px')};
  margin-bottom: 0px;
`;

export const AdditionalServicesAddService = styled(Button).attrs({
  'data-class': 'AdditionalServicesAddService',
})`
  ${divFlex}
  height: 22px;
  padding: 1px 8px;
  margin-right: 8px;
  align-items: center;

  font-size: 12px;
  font-weight: 400;
  background-color: ${({ theme }) => theme?.themesColors?.neutralBranding1};
  border: 1px dashed  ${({ theme }) => theme?.themesColors?.neutralBranding4};
  border-radius: 2px;

  svg {
    margin-right: 2px;
    color: inherit;
  }
`;

export const AdditionalServicesEndSpan = styled.span.attrs({
  'data-class': 'AdditionalServicesEndSpan',
})`
`;
