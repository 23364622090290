import React, { FC } from 'react';

import { UC } from 'shipment-operations/controllers';

import { CardWeightFieldComponent } from './CardWeightField.component';

interface ICardWeightFieldContainerProps {
  cargoId: string
  value: string
  isError: boolean
  disabled: boolean
}

const CardWeightFieldContainer: FC<ICardWeightFieldContainerProps> = ({
  cargoId,
  value,
  isError,
  disabled,
}) => (
  <CardWeightFieldComponent
    cargoId={cargoId}
    value={value}
    isErrorShowed={isError}
    disabled={disabled}
    onChange={UC.shipmentContainers.updateChangedCargoWeight}
    touchField={UC.shipmentContainers.onBlurUsedCargoWeight}
  />
);

export {
  CardWeightFieldContainer as CardWeightField,
};
