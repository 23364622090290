import React, { ChangeEvent, FC, useCallback } from 'react';

import { Input } from 'app-wrapper/view/components';

interface IFormInputProps {
  disabled: boolean
  value?: string
  error?: boolean
  onChange: (value: string) => void
  onBlur: () => void
}

export const FormInput: FC<IFormInputProps> = ({
  disabled,
  value,
  error,
  onChange,
  onBlur,
}) => {
  const handleChangeValue = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value);
  }, []);

  const handleBlur = useCallback(() => {
    onBlur();
  }, []);

  return (
    <Input
      disabled={disabled}
      value={value}
      error={error}
      onChange={handleChangeValue}
      onBlur={handleBlur}
    />
  );
};
