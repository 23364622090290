import React, { FC } from 'react';
import Divider from 'antd/es/divider';
import { useTranslation } from 'react-i18next';
import isEmpty from 'lodash/fp/isEmpty';

import { calculateChargesSum } from 'shipment-operations/view/pages/ShipmentCharges/utils/calculateSum';
import { ContainerViewDTM, IGroupedChargesDTM } from 'shipment-operations/models/dtm';

import {
  InnerContainer, Title, StyledTotal, HeaderTableWrapper,
} from 'shipment-operations/view/components';
import { CustomTable, ExpandPanel } from 'shipment-operations/view/pages/CreateInvoice/components';
import { TotalFooter } from 'app-wrapper/view/components';

interface ITransportationComponent {
  containers: ContainerViewDTM[]
  data: IGroupedChargesDTM
  sum: number
}

const TransportationComponent: FC<ITransportationComponent> = ({
  sum, data, containers,
}) => {
  const { t } = useTranslation();

  if (isEmpty(data) && isEmpty(containers)) {
    return null;
  }

  return (
    <>
      <InnerContainer>
        <Title>{t('transportation')}</Title>
        <Divider style={{ margin: '0' }} />
        <HeaderTableWrapper>
          <span>{t('Containers')}</span>
          <span>{t('total')}</span>
        </HeaderTableWrapper>
        <Divider style={{ margin: '0' }} />
        {containers.map((item) => (
          <ExpandPanel
            container={item}
            sum={calculateChargesSum(data[item.id])}
            key={item.id}
            keys={data[item.id].map((elem) => elem.id)}
          >
            <CustomTable data={data[item.id]} fullTable withTooltip />
          </ExpandPanel>
        ))}
      </InnerContainer>

      <TotalFooter>
        <StyledTotal>{t('total')}</StyledTotal>
        <StyledTotal>$ {sum.toFixed(2)}</StyledTotal>
      </TotalFooter>
    </>
  );
};

export { TransportationComponent };
