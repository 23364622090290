import React, { FC, useEffect } from 'react';
import notification from 'antd/es/notification';
import { useTranslation } from 'react-i18next';

import { CustomModal } from 'shipment-operations/view/components';

import { Header, Footer, Main } from './components';

interface IEditPaymentComponentProps {
  onClose: () => void
  isErrorEdit: boolean
  onCloseError: () => void
  errorType: string
}

const EditPaymentComponent: FC<IEditPaymentComponentProps> = ({
  onClose, isErrorEdit, onCloseError, errorType,
}) => {
  const { t } = useTranslation();
  useEffect(() => {
    if (isErrorEdit) {
      notification.error({
        message: t(`payment.error.${errorType}.title`),
        description: t(`payment.error.${errorType}.subTitle`),
        placement: 'bottomRight',
        bottom: 60,
        onClose: onCloseError,
      });
    }
  }, [isErrorEdit]);

  return (
    <CustomModal
      onClose={onClose}
      title={t('Edit Payment')}
      footer={<Footer />}
    >
      <Header />
      <Main />
    </CustomModal>
  );
};

export { EditPaymentComponent };
