import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { UC } from 'user-management/controllers';
import { R } from 'user-management/repository';
import { ContactsContactPersonBlockComponent } from 'user-management/view/components';
import { ICompanyContactDTM } from 'user-management/models/dtm';

interface IContactBookContactPersonItemContainerProps {
  largeInput: boolean
  personItem: ICompanyContactDTM
  contactIndex: number
  companyListStateIndex: string
  isFirst: boolean
  isLast: boolean
}

export const ContactBookContactPersonItemContainer: FC<IContactBookContactPersonItemContainerProps> = (props) => {
  const {
    largeInput,
    personItem,
    contactIndex,
    companyListStateIndex,
    isFirst,
    isLast,
  } = props;

  const errors = useSelector(R.selectors.contactBook.getContactBookUpdateErrorsContactPersonsById(personItem?.customId || ''));

  return (
    <ContactsContactPersonBlockComponent
      largeInput={largeInput}
      isFirst={isFirst}
      isLast={isLast}
      contactPerson={personItem}
      contactIndex={contactIndex}
      companyListStateIndex={companyListStateIndex}
      errors={errors}
      onChangeContactPerson={UC.contactBookDrawers.onChangeUpdateContactsContactPersonByIndex(UC.contactBookDrawers.onChangeContactBookByIdAddAddressDrawer)}
      onBlurContactPerson={UC.contactBookDrawers.onBlurUpdateContactsContactPersonByIndex(UC.contactBookDrawers.onChangeValidateContactBookByIdAddAddressDrawer)}
      onFocusContactPerson={UC.contactBookDrawers.onFocusUpdateContactsContactPersonByIndex(UC.contactBookDrawers.onChangeValidateContactBookByIdAddAddressDrawer)}
      onChangeAdditionalPhone={UC.contactBookDrawers.onChangeUpdateContactsAdditionalPhoneByIndex(UC.contactBookDrawers.onChangeContactBookByIdAddAddressDrawer)}
      onBlurAdditionalPhone={UC.contactBookDrawers.onBlurUpdateContactsAdditionalPhoneByIndex(UC.contactBookDrawers.onChangeValidateContactBookByIdAddAddressDrawer)}
      onFocusAdditionalPhone={UC.contactBookDrawers.onFocusUpdateContactsAdditionalPhoneByIndex(UC.contactBookDrawers.onChangeValidateContactBookByIdAddAddressDrawer)}
      onChangeEmail={UC.contactBookDrawers.onChangeUpdateContactsEmailByIndex(UC.contactBookDrawers.onChangeContactBookByIdAddAddressDrawer)}
      onBlurEmail={UC.contactBookDrawers.onBlurUpdateContactsEmailByIndex(UC.contactBookDrawers.onChangeValidateContactBookByIdAddAddressDrawer)}
      onFocusEmail={UC.contactBookDrawers.onFocusUpdateContactsEmailByIndex(UC.contactBookDrawers.onChangeValidateContactBookByIdAddAddressDrawer)}
      onChangePhone={UC.contactBookDrawers.onChangeUpdateContactsPhoneByIndex(UC.contactBookDrawers.onChangeContactBookByIdAddAddressDrawer)}
      onBlurPhone={UC.contactBookDrawers.onBlurUpdateContactsPhoneByIndex(UC.contactBookDrawers.onChangeValidateContactBookByIdAddAddressDrawer)}
      onFocusPhone={UC.contactBookDrawers.onFocusUpdateContactsPhoneByIndex(UC.contactBookDrawers.onChangeValidateContactBookByIdAddAddressDrawer)}
      addContactPersonItem={UC.contactBookDrawers.setUpdateAddActiveCompanyEditContactPerson(UC.contactBookDrawers.onValidateContactBookByIdAddAddressDrawer)}
      onRemoveContactPerson={UC.contactBookDrawers.setUpdateRemoveActiveCompanyEditContactPersonItem(UC.contactBookDrawers.onValidateContactBookByIdAddAddressDrawer)}
    />
  );
};

export { ContactBookContactPersonItemContainer as ContactBookContactPersonItem };
