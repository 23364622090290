import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
  padding: 0 194px;
  height: 100%;
  background: ${({ theme }) => theme.bookingPage.contentBg};
`;
