import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ContainerDocumentDTM, AdditionalServiceDocumentActivityDTM } from 'shipment-operations/models/dtm';
import { DocumentType } from 'shipment-operations/constants';

import { DocumentsSection } from './components';
import { Title, Content } from './UploadDocuments.styled';

interface IUploadDocumentsComponentProps {
  isManager: boolean;
  documentActivities: AdditionalServiceDocumentActivityDTM[];
  openDocumentUploadDrawer: (type: DocumentType, document: ContainerDocumentDTM | null) => void;
  removeServiceDocument: (shipmentId: string, document: ContainerDocumentDTM) => void;
  downloadDocument: (shipmentId: string, documentId: number, documentName: string) => void;
}

export const UploadDocumentsComponent: FC<IUploadDocumentsComponentProps> = ({
  isManager,
  documentActivities,
  openDocumentUploadDrawer,
  removeServiceDocument,
  downloadDocument,
}) => {
  const { t } = useTranslation();

  const requiredDocumentActivities = useMemo(() => documentActivities.filter((activity) => activity.isRequired), [documentActivities]);
  const optionalDocumentActivities = useMemo(() => documentActivities.filter((activity) => !activity.isRequired), [documentActivities]);

  return (
    <Content>
      <Title>
        {isManager ? t('Add Documents') : t('Upload Documents')}
      </Title>

      {requiredDocumentActivities.map((activity) => (
        <DocumentsSection
          templateURL={activity.templateUrl}
          key={activity.type}
          type={activity.code}
          documents={activity.providedDocuments}
          onDownload={downloadDocument}
          onRemove={removeServiceDocument}
          isOptional={!activity.isRequired}
          openDocumentUploadDrawer={() => openDocumentUploadDrawer(activity.code, activity.getProvidedDocument())}
        />
      ))}

      {optionalDocumentActivities.map((activity) => (
        <DocumentsSection
          templateURL={activity.templateUrl}
          key={activity.type}
          type={activity.code}
          documents={activity.providedDocuments}
          onDownload={downloadDocument}
          onRemove={removeServiceDocument}
          isOptional={!activity.isRequired}
          openDocumentUploadDrawer={() => openDocumentUploadDrawer(activity.code, activity.getProvidedDocument())}
        />
      ))}
    </Content>
  );
};
