import React, { FC } from 'react';
import { IPropsIcon } from 'app-wrapper/interfaces/icon';

export const ClockCircleSvg: FC<IPropsIcon> = (props) => {
  const {
    width, height, style, fill,
  } = props;

  return (
    <svg
      className="ClockCircleSvg"
      width={width || '16'}
      height={height || '16'}
      viewBox="0 0 14 14"
      style={style || undefined}
      fill={fill || 'currentColor'}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M7 0C3.13437 0 0 3.13437 0 7C0 10.8656 3.13437 14 7 14C10.8656 14 14 10.8656 14 7C14 3.13437 10.8656 0 7 0ZM7 12.8125C3.79063 12.8125 1.1875 10.2094 1.1875 7C1.1875 3.79063 3.79063 1.1875 7 1.1875C10.2094 1.1875 12.8125 3.79063 12.8125 7C12.8125 10.2094 10.2094 12.8125 7 12.8125Z" fill="currentColor" />
      <path d="M9.72981 8.97666L7.50168 7.36572V3.49854C7.50168 3.42979 7.44543 3.37354 7.37668 3.37354H6.62512C6.55637 3.37354 6.50012 3.42979 6.50012 3.49854V7.80166C6.50012 7.84229 6.51887 7.87979 6.55168 7.90322L9.13606 9.7876C9.19231 9.82822 9.27043 9.81572 9.31106 9.76104L9.75793 9.15166C9.79856 9.09385 9.78606 9.01572 9.72981 8.97666Z" fill="currentColor" />
    </svg>
  );
};
