import {
  IsOptional,
  IsDefined,
  IsString,
} from 'class-validator';
import { BaseDTM } from 'proto/BaseDTM';

interface IFreightDoorsAutocompleteDTM {
  description: string
  placeId: string
  id?: string
}

export class FreightDoorsAutocompleteDTM extends BaseDTM<IFreightDoorsAutocompleteDTM> {
  @IsDefined()
  @IsString()
  description: string;

  @IsDefined()
  @IsString()
  placeId: string;

  @IsOptional()
  @IsString()
  id?: string;
}
