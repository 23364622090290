import React from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { MessageResponseFull } from 'app-wrapper/view/icons';
import { Button } from 'app-wrapper/view/components';
import { RouteNames } from 'app-wrapper/constants';

import {
  RFQNotFoundRecommendedDiv,
  RFQNotFoundRecommendedDivWrapper,
  RFQNotFoundRecommendedText,
  RFQNotFoundRecommendedTextButton,
  RFQNotFoundRecommendedTextFirst,
  RFQNotFoundRecommendedTextSecond,
} from './RFQNotFoundRecommended.styled';

export const RFQNotFoundRecommended = () => {
  const { t } = useTranslation();

  return (
    <RFQNotFoundRecommendedDiv>
      <RFQNotFoundRecommendedDivWrapper>
        <MessageResponseFull />
        <RFQNotFoundRecommendedText>
          <RFQNotFoundRecommendedTextFirst>
            {t('RFQNotFoundRecommendedFirst')}
          </RFQNotFoundRecommendedTextFirst>
          <RFQNotFoundRecommendedTextSecond>
            {t('RFQNotFoundRecommendedSecond')}
          </RFQNotFoundRecommendedTextSecond>
        </RFQNotFoundRecommendedText>
        <RFQNotFoundRecommendedTextButton>
          <NavLink to={RouteNames.ALL_REQUESTED_QUOTES()}>
            <Button
              htmlType="submit"
              size="large"
            >{t('RequestAQuote')}
            </Button>
          </NavLink>
        </RFQNotFoundRecommendedTextButton>
      </RFQNotFoundRecommendedDivWrapper>
    </RFQNotFoundRecommendedDiv>
  );
};
