import Card from 'antd/es/card';
import styled from 'styled-components';
import Divider from 'antd/es/divider';
import { NavLink } from 'react-router-dom';

import { divFlex } from 'app-wrapper/view/themes/mixin';

export const ARQItemSubCardContainer = styled(NavLink).attrs((props: {
  $isClickable?: boolean,
}) => ({
  $isClickable: props.$isClickable,
  'data-class': 'ARQItemSubCardContainer',
}))`
  ${divFlex}
  padding: 0;

  width: 100%;
  height:  76px;

  border: 1px solid ${({ theme }) => theme.themesColors.neutralBranding4};
  border-radius: 4px;

  background-color: ${({ theme }) => theme.themesColors.neutralBranding1};
  line-height: inherit !important;
  color: ${({ theme }) => theme.themesColors.neutralBranding13} !important;

  ${({ $isClickable }) => !$isClickable && 'cursor: auto;'};
  ${({ $isClickable }) => !$isClickable && 'border: none;'};
  ${({ $isClickable }) => !$isClickable && 'box-shadow: none;'};

  &:hover {
    ${({ $isClickable }) => !$isClickable && 'border: none;'};
    ${({ $isClickable }) => !$isClickable && 'box-shadow: none;'};
  }
`;

export const ARQItemSubCardSectionRequest = styled.div.attrs((props: {
  $isClickable?: boolean,
  width?: string,
}) => ({
  $isClickable: props.$isClickable,
  width: props.width,
  'data-class': 'ARQItemSubCardSectionRequest',
}))`
  ${divFlex}
  width: ${({ width }) => (width || '20%')};
  /* padding-left: 16px; */
  justify-content: center;

  div[data-class="ARQItemSubCardDescriptionsContainerContent"] span[data-class="ARQItemSubCardDescriptionsContainerSpanClip"] {
    color: ${({ theme, $isClickable }) => ($isClickable ? theme.themesColors.primaryBranding7 : theme.themesColors.neutralBranding10)};

    ${({ $isClickable }) => !$isClickable && 'font-size: 20px;'};
  }
  ${({ $isClickable }) => !$isClickable && 'padding-left: 0;'};
`;

export const ARQItemSubCardSectionContainerAndCommodity = styled.div.attrs((props: {
  minWidth?: string,
}) => ({
  minWidth: props.minWidth,
  'data-class': 'ARQItemSubCardSectionContainerAndCommodity',
}))`
  ${divFlex}
  width: 17.2%;
  ${({ minWidth }) => minWidth && `min-width: ${minWidth};`};
`;

export const ARQItemSubCardSectionContainerCountsText = styled.div.attrs({
  'data-class': 'ARQItemSubCardSectionContainerCountsText',
})`
  ${divFlex}

  font-size: 14px;
  font-weight: 500;
  color: ${({ theme }) => theme.themesColors.neutralBranding10};

  justify-content: start;
  align-items: center;
`;

export const ARQItemSubCardSectionContainerCounts = styled.div.attrs({
  'data-class': 'ARQItemSubCardSectionContainerCounts',
})`
  ${divFlex}

  width: 30px;
  height: 22px;
  font-size: 12px;
  font-weight: 400;
  color: ${({ theme }) => theme.themesColors.secondaryDot45};

  margin-left: 4px;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.themesColors.neutralBranding3};
  border: 1px solid ${({ theme }) => theme.themesColors.neutralBranding4};
  border-radius: 2px;
`;

export const ARQItemSubCardSectionCommodity = styled.div.attrs((props: {
  minWidth?: string,
}) => ({
  minWidth: props.minWidth,
  'data-class': 'ARQItemSubCardSectionCommodity',
}))`
  ${divFlex}

  width: 120%;
  ${({ minWidth }) => minWidth && `min-width: ${minWidth};`};
`;

export const ARQItemSubCardSectionCustomerTitle = styled.div.attrs({
  'data-class': 'ARQItemSubCardSectionCustomerTitle',
})`
  ${divFlex}

  font-size: 12px;
  font-weight: 400;
  color: ${({ theme }) => theme.themesColors.secondaryDot45};
`;

export const ARQItemSubCardSectionVerticalDivider = styled.div.attrs({
  'data-class': 'ARQItemSubCardSectionVerticalDivider',
})`
  ${divFlex}
  width: 0;
  height: 52px;

  margin: auto 0;
  border-left: 1px dashed ${({ theme }) => theme.themesColors.neutralBranding5};
`;

export const ARQItemSubCardSectionVerticalDividerIcon = styled.div.attrs({
  'data-class': 'ARQItemSubCardSectionVerticalDividerIcon',
})`
  ${divFlex}
  justify-content: center;
  align-items: center;
  height: 100%;

  margin: 0;
`;

export const ARQItemSubCardSectionOrigin = styled.div.attrs({
  'data-class': 'ARQItemSubCardSectionOrigin',
})`
  ${divFlex}
  width: 17.2%;

  div[data-class="SCDescriptionsContainer"] {
    width: calc(100% - 16px - 16px - 8px);
  }
`;

export const ARQItemSubCardSectionDestination = styled.div.attrs({
  'data-class': 'ARQItemSubCardSectionDestination',
})`
  ${divFlex}
  width: 17.2%;

  div[data-class="SCDescriptionsContainer"] {
    width: calc(100% - 16px - 16px - 8px);
  }
`;

export const ARQItemSubCardSectionCustomer = styled.div.attrs({
  'data-class': 'ARQItemSubCardSectionCustomer',
})`
  ${divFlex}
  width: calc(17.2% - 8px);
`;

export const ARQItemSubCardSectionTasks = styled.div.attrs({
  'data-class': 'ARQItemSubCardSectionTasks',
})`
  ${divFlex}
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 10%;
`;

export const ARQItemSubCardSectionTasksIcon = styled.div.attrs((props: {
  colorSvg?: string,
  marginBottomSvg?: string,
}) => ({
  colorSvg: props.colorSvg,
  marginBottomSvg: props.marginBottomSvg,
  'data-class': 'ARQItemSubCardSectionTasksIcon',
}))`
  ${divFlex}

  svg {
    ${({ colorSvg }) => colorSvg && `color: ${colorSvg}`};
    ${({ marginBottomSvg }) => marginBottomSvg && `margin-bottom: ${marginBottomSvg}`};
  }
`;

export const ARQItemSubCardSectionTasksText = styled.div.attrs((props: {
  colorBg?: string,
}) => ({
  colorBg: props.colorBg,
  'data-class': 'ARQItemSubCardSectionTasksText',
}))`
  ${divFlex}
  min-width: 23px;
  justify-content: center;

  color: ${({ theme }) => theme.themesColors.secondaryDot45};
  font-size: 12px;
  font-weight: 400;

  border-radius: 100px;
  ${({ colorBg }) => colorBg && `background-color: ${colorBg}`};
`;

export const ARQItemSubCardSectionIcon = styled.div.attrs((props: {
  marginLeft?: string,
  marginRight?: string,
}) => ({
  marginLeft: props.marginLeft,
  marginRight: props.marginRight,
  'data-class': 'ARQItemSubCardSectionIcon',
}))`
  ${divFlex}

  justify-content: center;
  align-items: center;
  margin-left: ${({ marginLeft }) => (marginLeft || '16px')};
  margin-right: ${({ marginRight }) => (marginRight || '8px')};
`;

export const ARQItemSubCardSectionContentDescriptions = styled.div.attrs({
  'data-class': 'ARQItemSubCardSectionContentDescriptions',
})`
  ${divFlex}

  justify-content: center;
  align-items: center;
  margin-left: 16px;
  margin-right: 8px;
`;

export const ARQItemSubCardSectionDivider = styled(Divider).attrs({
  'data-class': 'ARQItemSubCardSectionDivider',
})`
  ${divFlex}
  width: 100%;
  margin: 0;
`;

export const ARQListLoadingCardStyled = styled(Card).attrs({
  'data-class': 'ARQListLoadingCardStyled',
})`
  ${divFlex}
  width: 100%;
`;
