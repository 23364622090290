import styled from 'styled-components';

import { divFlex } from 'app-wrapper/view/themes/mixin';

export const GetStepAccessorialsTabItemComponentRowItem = styled.div`
${divFlex}
`;

export const GetStepAccessorialsTabItemComponentRowItemContainerItem = styled.div`
  ${divFlex}
  width: 100%;
  height: 32px;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid  ${({ theme }) => theme.themesColors.neutralBranding4};
  &:nth-last-child(1) {
    border-bottom: none;
  }
`;

export const GetStepAccessorialsTabItemComponentRowItemContainerItemSvg = styled.div`
  ${divFlex}
  transform: translateY(-50%);
  position: absolute;
  left: -21px;
`;

export const GetStepAccessorialsTabItemComponentRowItemContainerItemText = styled.div`
  ${divFlex}
  font-size: 12px;
  font-weight: 400;
  align-items: center;
  text-transform: capitalize;
`;

export const GetStepAccessorialsTabItemComponentRowItemContainerItemTextInfo = styled.div`
  ${divFlex}
  font-size: 12px;
  font-weight: 400;
  align-items: center;
`;

export const GetStepAccessorialsTabItemComponentRowItemContainerItemCost = styled.div`
  ${divFlex}
  font-size: 12px;
  font-weight: 400;
  color: ${({ theme }) => theme.themesColors.darkGrayMiddle};
  align-items: center;
  margin-left: auto;
`;

export const GetStepAccessorialsTabItemComponentRowItemContainerItemTotalCost = styled.div`
  ${divFlex}
  font-size: 12px;
  font-weight: 400;
  color: ${({ theme }) => theme.themesColors.neutralBranding10};
  align-items: center;
  margin-left: 8px;
`;
