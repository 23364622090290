import React, {
  FC, memo, useCallback, useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import Skeleton from 'antd/es/skeleton';
import MailOutlined from '@ant-design/icons/MailOutlined';
import PhoneOutlined from '@ant-design/icons/PhoneOutlined';
import DeleteOutlined from '@ant-design/icons/DeleteOutlined';
import ClockCircleOutlined from '@ant-design/icons/ClockCircleOutlined';

import { REQUEST_STATUS } from 'app-wrapper/constants';
import { MissingTextValue } from 'app-wrapper/view/guideline';
import { AccountDepartmentDTM } from 'user-management/models/dtm';
import { NoDataLineItem } from 'user-management/view/components';

import {
  AccountDprtmByIdContentTableContentLink,
  AccountDprtmByIdContentTableContentItem,
  AccountDprtmByIdContentTableContentItemTop,
  AccountDprtmByIdContentTableContentTitle,
  AccountDprtmByIdContentTableHeader,
  AccountDprtmByIdContentTableHeaderItem,
  AccountDprtmByIdContentTableSpanEllipsis,
  AccountDprtmByIdContentTableTitleWrap,
  AccountDprtmByIdPrimaryContact,
  AccountDprtmByIdPrimaryContactIconBG,
  AccountDprtmByIdPrimaryContactData,
  AccountDprtmByIdPrimaryContactTitle,
  AccountDprtmByIdPrimaryContactValue,
  AccountDprtmByIdCopyIcon,
} from './AccountDprtmByIdTable.styled';

interface IAccountDprtmByIdTableProps {
  onStart: () => void;
  nextPageTasks: () => void;
  onClickRemoveDepartment: (memberId?: string) => void;
  isLoad?: boolean;
  isAdmin?: boolean;
  isLoadAdd?: string;
  department?: AccountDepartmentDTM
  isEnd?: boolean;
}

const AccountDprtmByIdTable: FC<IAccountDprtmByIdTableProps> = (props) => {
  const {
    onStart,
    nextPageTasks,
    onClickRemoveDepartment,
    isLoad,
    isAdmin,
    isLoadAdd,
    department,
    isEnd,
  } = props;

  const { t } = useTranslation();

  useEffect(() => {
    onStart();
  }, []);

  const onClickRemoveDepartmentHandler = useCallback(
    (memberId?: string) => () => {
      onClickRemoveDepartment(memberId);
    },
    [],
  );

  const onCopyEmail = useCallback(() => {
    navigator.clipboard.writeText(department?.email || '');
  }, [department?.email]);

  const onCopyPhone = useCallback(() => {
    navigator.clipboard.writeText(department?.phone || '');
  }, [department?.phone]);

  return (
    <>
      <AccountDprtmByIdContentTableTitleWrap>
        <AccountDprtmByIdPrimaryContact>
          <AccountDprtmByIdPrimaryContactIconBG>
            <MailOutlined />
          </AccountDprtmByIdPrimaryContactIconBG>
          <AccountDprtmByIdPrimaryContactData>
            <AccountDprtmByIdPrimaryContactTitle>
              {t('Department Email')}
            </AccountDprtmByIdPrimaryContactTitle>
            <AccountDprtmByIdPrimaryContactValue>
              {department?.email || <NoDataLineItem />}
              {department?.email && (
                <AccountDprtmByIdCopyIcon onClick={onCopyEmail} />
              )}
            </AccountDprtmByIdPrimaryContactValue>
          </AccountDprtmByIdPrimaryContactData>
        </AccountDprtmByIdPrimaryContact>

        <AccountDprtmByIdPrimaryContact>
          <AccountDprtmByIdPrimaryContactIconBG>
            <PhoneOutlined />
          </AccountDprtmByIdPrimaryContactIconBG>
          <AccountDprtmByIdPrimaryContactData>
            <AccountDprtmByIdPrimaryContactTitle>
              {t('Department Phone')}
            </AccountDprtmByIdPrimaryContactTitle>
            <AccountDprtmByIdPrimaryContactValue>
              {department?.phone || <NoDataLineItem />}
              {department?.phone && (
                <AccountDprtmByIdCopyIcon onClick={onCopyPhone} />
              )}
            </AccountDprtmByIdPrimaryContactValue>
          </AccountDprtmByIdPrimaryContactData>
        </AccountDprtmByIdPrimaryContact>
      </AccountDprtmByIdContentTableTitleWrap>

      <AccountDprtmByIdContentTableHeader>
        <AccountDprtmByIdContentTableHeaderItem
          width="24%"
        >
          {t('Member Name')}
        </AccountDprtmByIdContentTableHeaderItem>
        <AccountDprtmByIdContentTableHeaderItem
          width="24%"
        >
          {t('Job Title')}
        </AccountDprtmByIdContentTableHeaderItem>
        <AccountDprtmByIdContentTableHeaderItem
          width="24%"
        >
          {t('Email')}
        </AccountDprtmByIdContentTableHeaderItem>
        <AccountDprtmByIdContentTableHeaderItem
          width="24%"
        >
          {t('Phone')}
        </AccountDprtmByIdContentTableHeaderItem>
        {/* temporarily hidden */}
        {false && (
          <AccountDprtmByIdContentTableHeaderItem
            width="21%"
          >
            {t('Team(s)')}
          </AccountDprtmByIdContentTableHeaderItem>
        )}
      </AccountDprtmByIdContentTableHeader>

      <>

        <InfiniteScroll
          dataLength={Number(department?.members?.length || 0)}
          next={nextPageTasks}
          hasMore={!isEnd}
          loader={
            isLoad
            && (<Skeleton />)
          }
          scrollableTarget="Wrapper"
        >
          {department?.members?.map((item) => (
            <AccountDprtmByIdContentTableContentItem key={`AccountDprtmByIdContentTableContentItem_${item.id}`}>
              <AccountDprtmByIdContentTableContentItemTop>
                <AccountDprtmByIdContentTableContentTitle isLeft>
                  <AccountDprtmByIdContentTableSpanEllipsis>
                    {item.getFullName() || <MissingTextValue />}
                  </AccountDprtmByIdContentTableSpanEllipsis>
                </AccountDprtmByIdContentTableContentTitle>
                <AccountDprtmByIdContentTableContentTitle isLeft>
                  <AccountDprtmByIdContentTableSpanEllipsis>
                    {item?.jobTitle || <MissingTextValue />}
                  </AccountDprtmByIdContentTableSpanEllipsis>
                </AccountDprtmByIdContentTableContentTitle>
                <AccountDprtmByIdContentTableContentTitle isLeft>
                  <AccountDprtmByIdContentTableSpanEllipsis>
                    {item?.email || ''}
                  </AccountDprtmByIdContentTableSpanEllipsis>
                </AccountDprtmByIdContentTableContentTitle>
                <AccountDprtmByIdContentTableContentTitle isLeft>
                  <AccountDprtmByIdContentTableSpanEllipsis>
                    {item?.phone || ''}
                  </AccountDprtmByIdContentTableSpanEllipsis>
                </AccountDprtmByIdContentTableContentTitle>
                {/* temporarily hidden */}
                {false && (
                  <AccountDprtmByIdContentTableContentTitle isLeft>
                    <AccountDprtmByIdContentTableSpanEllipsis>
                      {(item?.departments?.map((itemDepartment) => itemDepartment.createdBy)?.join(' ')) || (<ClockCircleOutlined />)}
                    </AccountDprtmByIdContentTableSpanEllipsis>
                  </AccountDprtmByIdContentTableContentTitle>
                )}
                {isAdmin && (
                  <AccountDprtmByIdContentTableContentLink
                    width="3.9%"
                    onClick={onClickRemoveDepartmentHandler(item?.id)}
                  >
                    <DeleteOutlined />
                  </AccountDprtmByIdContentTableContentLink>
                )}
              </AccountDprtmByIdContentTableContentItemTop>

            </AccountDprtmByIdContentTableContentItem>
          ))}
        </InfiniteScroll>
        {isLoadAdd === REQUEST_STATUS.pending && (<Skeleton />)}
      </>

    </>
  );
};

const AccountDprtmByIdTableCache = memo(AccountDprtmByIdTable);

export { AccountDprtmByIdTableCache as AccountDprtmByIdTable };
