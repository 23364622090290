import React, { FC, ReactNode } from 'react';
import { useSelector } from 'react-redux';

import { R as userManagementR, R } from 'user-management/repository';
import { UC as monetaryUC } from 'monetary/controllers';
import { UC } from 'user-management/controllers';

import { useTranslation } from 'react-i18next';
import { DraftOrganizationPopoverComponent } from 'monetary/view/components/DraftOrganizationPopoverComponent';

interface DraftOrganizationPopoverContainerProps {
  children: ReactNode;
}

const DraftOrganizationPopoverContainer: FC<DraftOrganizationPopoverContainerProps> = ({ children }) => {
  const { t } = useTranslation();
  const organization = useSelector(userManagementR.selectors.userOrganizationData.getUserOrganization);
  const isOrganizationInDraft = useSelector(R.selectors.userOrganizationData.getIsOrganizationInDraft);
  const isOrganizationInWaitingForApproval = useSelector(R.selectors.userOrganizationData.getIsOrganizationInWaitingForApproval);

  let onButtonClick: (() => void) | undefined;
  let buttonText: string | undefined;
  let popoverText: string | undefined;

  if (isOrganizationInDraft) {
    popoverText = t('To make a booking, complete your registration.');
    onButtonClick = UC.newOrganizationWizard.goToWizard;
    buttonText = t('Create Organization');
  }

  if (isOrganizationInWaitingForApproval) {
    popoverText = t('You will be able to make a booking once we verified your Organization.');
  }

  if (organization?.mustAccepTermsAndConditions()) {
    popoverText = t('To make a booking, please agree on Terms of Service.');
    onButtonClick = monetaryUC.FreightQuote.showTermsOfConditionDrawer;
    buttonText = t('View');
  }

  return (
    <DraftOrganizationPopoverComponent
      popoverText={popoverText}
      onButtonClick={onButtonClick}
      buttonText={buttonText}
    >
      {children}
    </DraftOrganizationPopoverComponent>
  );
};

export { DraftOrganizationPopoverContainer as DraftOrganizationPopover };
