import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Option } from 'app-wrapper/view/components';

import { OrganizationDTM, OrganizationFieldsErrorsDTM, OrganizationFieldsErrorsFieldDTM } from 'user-management/models/dtm';
import {
  EOrganizationIndustry, EOrganizationType, OrganizationIndustryNames, OrganizationTypeNames,
} from 'user-management/constants';
import { CustomerAddInternalTeam } from 'user-management/view/pages/Account/components/AccountDrawer/components/CustomerAddInternal/components/CustomerAddInternalTeam';

import {
  CustomerAddInternalGeneralInformationName,
  CustomerAddInternalGeneralInformationWrapper,
  CustomerAddInternalGeneralInformationHeader,
  CustomerAddInternalGeneralInformationInput,
  CustomerAddInternalGeneralInformationSelect,
  CustomerAddInternalGeneralInformationSpaceColumn,
  CustomerAddInternalGeneralInformationSpaceRow,
  CustomerAddInternalGeneralInformationTitle,
} from './CustomerAddInternalGeneralInformation.styled';

interface ICustomerAddInternalGeneralInformationComponentProps {
  organization?: OrganizationDTM
  organizationIndustry?: EOrganizationIndustry[]
  organizationFieldsErrors?: OrganizationFieldsErrorsDTM
  organizationDisabledFieldsErrors?: boolean
  organizationEditProfileIdentifiersIndustryErrors?: boolean
  onChangeUpdateName: (name: string) => void
  onBlurUpdateName: () => void
  onChangeUpdateType: (type: EOrganizationType) => void
  onChangeUpdateIndustry: (value: string) => void
}

export const CustomerAddInternalGeneralInformationComponent: FC<ICustomerAddInternalGeneralInformationComponentProps> = ({
  organization,
  organizationIndustry,
  organizationFieldsErrors,
  organizationDisabledFieldsErrors,
  organizationEditProfileIdentifiersIndustryErrors,
  onChangeUpdateName,
  onBlurUpdateName,
  onChangeUpdateType,
  onChangeUpdateIndustry,
}) => {
  const { t } = useTranslation();

  const onChangeName = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    onChangeUpdateName(event.target.value);
  }, []);

  const onBlurUpdateNameHandler = useCallback(() => {
    onBlurUpdateName();
  }, []);

  const onChangeTypeHandler = useCallback((value: EOrganizationType) => {
    onChangeUpdateType(value);
  }, []);

  const onBlurTypeHandler = useCallback(() => {
    // onBlurUpdatePrimaryEmail();
  }, []);

  const getHasErrorField = useCallback(
    (filed?: OrganizationFieldsErrorsFieldDTM) => !!(filed?.isVisited && filed?.message),
    [],
  );

  return (
    <CustomerAddInternalGeneralInformationWrapper>
      <CustomerAddInternalGeneralInformationHeader>
        {t('Company General Content')}
      </CustomerAddInternalGeneralInformationHeader>

      <CustomerAddInternalGeneralInformationName>
        <CustomerAddInternalGeneralInformationTitle>
          {`${t('OrganizationsName')} *`}
        </CustomerAddInternalGeneralInformationTitle>

        <CustomerAddInternalGeneralInformationInput
          value={organization?.name}
          hasError={getHasErrorField(organizationFieldsErrors?.name)}
          onChange={onChangeName}
          onBlur={onBlurUpdateNameHandler}
        />
      </CustomerAddInternalGeneralInformationName>

      <CustomerAddInternalTeam />

      <CustomerAddInternalGeneralInformationSpaceRow>
        <CustomerAddInternalGeneralInformationSpaceColumn widthProp="50%">
          <CustomerAddInternalGeneralInformationTitle>
            {`${t(' Type')} *`}
          </CustomerAddInternalGeneralInformationTitle>

          <CustomerAddInternalGeneralInformationSelect
            value={organization?.type}
            showSearch
            disabled={organizationDisabledFieldsErrors}
            onChange={onChangeTypeHandler}
            onBlur={onBlurTypeHandler}
            optionFilterProp="children"
            hasError={getHasErrorField(organizationFieldsErrors?.type)}
          >
            <Option key={EOrganizationType.BCO} value={EOrganizationType.BCO}>{OrganizationTypeNames[EOrganizationType.BCO]}</Option>
            <Option key={EOrganizationType.NVOCC} value={EOrganizationType.NVOCC}>{OrganizationTypeNames[EOrganizationType.NVOCC]}</Option>
            <Option key={EOrganizationType.FF} value={EOrganizationType.FF}>{OrganizationTypeNames[EOrganizationType.FF]}</Option>
          </CustomerAddInternalGeneralInformationSelect>
        </CustomerAddInternalGeneralInformationSpaceColumn>

        <CustomerAddInternalGeneralInformationSpaceColumn widthProp="50%">
          <CustomerAddInternalGeneralInformationTitle>
            {`${t(' Industry')}`}
          </CustomerAddInternalGeneralInformationTitle>
          <CustomerAddInternalGeneralInformationSelect
            data-class="CustomerAddInternalGeneralInformationSelectIndustry"
            value={organization?.industry || null}
            showSearch
            onChange={onChangeUpdateIndustry}
            onBlur={() => {}}
            optionFilterProp="children"
            disabled={!organization?.type || organizationEditProfileIdentifiersIndustryErrors}
            hasError={getHasErrorField(organizationFieldsErrors?.industry)}
          >
            {organizationIndustry?.map((item) => (
              <Option key={item} value={item}>{OrganizationIndustryNames[item]}</Option>
            ))}
          </CustomerAddInternalGeneralInformationSelect>
        </CustomerAddInternalGeneralInformationSpaceColumn>
      </CustomerAddInternalGeneralInformationSpaceRow>
    </CustomerAddInternalGeneralInformationWrapper>
  );
};
