import React, { FC, memo } from 'react';
import { useSelector } from 'react-redux';

import { UC } from 'user-management/controllers';
import { R } from 'user-management/repository';

import { ContactBookByIdAddAddressComponent } from './ContactBookByIdAddAddress.component';

const ContactBookByIdAddAddressContainer: FC = () => {
  const contactBook = useSelector(R.selectors.contactBook.getContactBookUpdate);

  const listIndex = contactBook.activeListTabIndex;

  const companyListState = contactBook.activeCompanyEdit;

  const addressesIds = companyListState?.addressesIds || [];
  const contactPersonsIds = companyListState?.contactPersonsIds || [];

  const companyListAddresses = useSelector(R.selectors.contactBook.getContactBookUpdateCompanyListAddressesByIds(addressesIds));
  const companyListContactPersons = useSelector(R.selectors.contactBook.getContactBookUpdateCompanyListContactPersonsByIds(contactPersonsIds));

  const onChangeAddressIsPrimary = UC.contactBookDrawers.onChangeUpdateAddressesIsPrimaryByIndex(UC.contactBookDrawers.onChangeContactBookByIdAddAddressDrawer);

  return (
    <ContactBookByIdAddAddressComponent
      isPrimaryValue={companyListAddresses?.[0]?.isPrimary}
      companyListAddresses={companyListAddresses}
      companyListContactPersons={companyListContactPersons}
      companyListStateIndex={listIndex}
      updateForm={UC.contactBookDrawers.onAddCBByIdAddress}
      onDiscard={UC.contactBookDrawers.onCancelContactBookByIdAddAddress}
      onChangeAddressIsPrimary={onChangeAddressIsPrimary}
    />
  );
};

const ContactBookByIdAddAddressContainerCache = memo(ContactBookByIdAddAddressContainer);

export { ContactBookByIdAddAddressContainerCache as ContactBookByIdAddAddressPage };
