import styled from 'styled-components';

import { FlexRow, FlexCenter } from 'app-wrapper/view/guideline';

export const StepWrap = styled(FlexRow)`
  gap: 16px;
`;

export const StepNumber = styled(FlexCenter)`
  min-width: 24px;
  min-height: 24px;
  border-radius: 50%;
  border: 1px solid ${({ theme }) => theme.themesColors.characterBrandingDisabled025};
  font-size: 14px;
  line-height: 22px;
`;
