import React from 'react';
import { useSelector } from 'react-redux';

import { useAuthToken } from 'app-wrapper/hooks';
import { UC } from 'shipment-operations/controllers';
import { R } from 'shipment-operations/repository';

import { UploadPaymentDocumentComponent } from './UploadPaymentDocument.component';

const UploadPaymentDocumentContainer = () => {
  const authToken = useAuthToken();
  const accountIdToAttachDocuments = useSelector(R.selectors.makePayment.getAccountIdToAttachDocuments);
  const documents = useSelector(R.selectors.makePayment.getTempDocuments);

  return (
    <UploadPaymentDocumentComponent
      authToken={authToken}
      error={false}
      accountId={accountIdToAttachDocuments}
      downloadDocument={UC.makePayment.downloadPaymentDocument}
      addDocument={UC.makePayment.addUploadingPaymentDocument}
      documents={documents}
      setContainerDocument={() => null}
      deleteDocument={UC.makePayment.deleteDocument}
    />
  );
};

export { UploadPaymentDocumentContainer as UploadPaymentDocument };
