import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { CarrierSCACNames, ECarrierSCAC } from 'monetary/constants';
import { Field, FieldLabel } from 'app-wrapper/view/guideline';

import { Select } from './ContractCarrier.styled';

const OPTIONS = Object.keys(ECarrierSCAC).map((ECarrierSCACItem) => {
  const item = ECarrierSCACItem as ECarrierSCAC;

  return {
    label: CarrierSCACNames[item],
    value: item,
  };
});

interface IContractCarrierComponentProps {
  carrier?: ECarrierSCAC;
  setCarrier: (carrier: ECarrierSCAC) => void;
}

export const ContractCarrierComponent: FC<IContractCarrierComponentProps> = ({
  carrier,
  setCarrier,
}) => {
  const { t } = useTranslation();

  return (
    <Field>
      <FieldLabel>
        {t('Carrier')}
      </FieldLabel>

      <Select
        placeholder=""
        value={carrier}
        onChange={setCarrier}
        options={OPTIONS}
      />
    </Field>
  );
};
