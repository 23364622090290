import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from 'app-wrapper/view/components';
import { PermissionAttributePolicy } from 'shipment-operations/constants';

interface IShipmentDocumentsFooterProps {
  isLoading?: boolean
  disabledUpdate?: boolean
  updateParties: () => void
  additionalDocumentsPermission?: PermissionAttributePolicy
  disabledDiscard?: boolean
  discardChanges: () => void
}

export const ShipmentDocumentsFooter: FC<IShipmentDocumentsFooterProps> = ({
  isLoading,
  disabledUpdate,
  updateParties,
  disabledDiscard,
  discardChanges,
  additionalDocumentsPermission,
}) => {
  const { t } = useTranslation();

  const isDisabled = useMemo(() => additionalDocumentsPermission !== PermissionAttributePolicy.WRITE, [additionalDocumentsPermission]);

  return (
    <>
      <Button
        size="large"
        type="default"
        onClick={discardChanges}
        disabled={isDisabled || disabledDiscard || isLoading}
      >
        {t('Discard Changes')}
      </Button>

      <Button
        size="large"
        onClick={updateParties}
        disabled={isDisabled || disabledUpdate}
        loading={isLoading}
      >
        {t('updateDocuments')}
      </Button>
    </>
  );
};
