import React, {
  FC, memo, useCallback, useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import { RouteNames } from 'app-wrapper/constants';
import { OrganizationDTM } from 'user-management/models/dtm';

import {
  BackTitleIcon,
  SubPageContent,
  SubPageHeader,
  SubPageHeaderContainer,
  SubPageHeaderTitle,
  Wrapper,
} from 'app-wrapper/view/guideline';
import { PartnerContentTable } from './components';

interface IPartnersComponentProps {
  isLoading: boolean;
  partners: OrganizationDTM[];
  getPartners: () => void;
  clearPartners: () => void;
  goToOrganization: (id: string) => void;
  paginatePartners: () => void;
  isPaginationEnded: boolean;
}

const PartnersListComponent: FC<IPartnersComponentProps> = ({
  isLoading,
  partners,
  clearPartners,
  getPartners,
  goToOrganization,
  paginatePartners,
  isPaginationEnded,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    getPartners();

    return () => {
      clearPartners();
    };
  }, []);

  const handleGoBack = useCallback(() => navigate(RouteNames.USER_PROFILE()), [navigate]);

  return (
    <Wrapper>
      <SubPageHeader>
        <SubPageHeaderContainer>
          <SubPageHeaderTitle>
            <BackTitleIcon onClick={handleGoBack} />
            {t('Partners')}
          </SubPageHeaderTitle>
        </SubPageHeaderContainer>
      </SubPageHeader>

      <SubPageContent>
        <PartnerContentTable
          paginate={paginatePartners}
          goToOrganization={goToOrganization}
          isLoading={isLoading}
          partners={partners}
          isEnd={isPaginationEnded}
        />
      </SubPageContent>
    </Wrapper>
  );
};

const PartnersComponentCache = memo(PartnersListComponent);

export { PartnersComponentCache as PartnersComponent };
