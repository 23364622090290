import React, { FC, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import notification from 'antd/es/notification';
import { useParams } from 'react-router-dom';

import { Container } from 'shipment-operations/view/components';
import { Button } from 'app-wrapper/view/components';
import { StickyFooter } from 'shipment-operations/view/pages/CreateCreditNote/CreateCreditNote.styled';

import {
  Transportation, Services, Fees, Additional, Notes,
} from 'shipment-operations/view/pages/CreateCreditNote/components/SecondStep/components';

interface ISecondStepProps {
  onClickBack: () => void
  postData: (shipmentId?: string, invoiceId?: string, type?: string) => void
  error: boolean
  resetError: () => void
  isLoading: boolean
  success: boolean
  onCloseCreditNote: () => void
  errorTitle: string
}

const SecondStepComponent: FC<ISecondStepProps> = ({
  onClickBack, postData, error, resetError, isLoading,
  success, onCloseCreditNote, errorTitle,
}) => {
  const { t } = useTranslation();
  const { shipmentId } = useParams<'shipmentId'>();
  const { invoiceId } = useParams<'invoiceId'>();
  const { invoiceType } = useParams<'invoiceType'>();

  useEffect(() => {
    if (error) {
      notification.error({
        message: t(`creditNote.${errorTitle}.errorTitle`),
        description: t(`creditNote.${errorTitle}.errorSubTitle`),
        placement: 'bottomRight',
        bottom: 60,
        onClose: resetError,
      });
    }
  }, [error, t]);

  useEffect(() => {
    if (success) {
      onCloseCreditNote();
    }
  }, [success]);

  const handlePostData = useCallback(() => {
    postData(shipmentId, invoiceId, invoiceType);
  }, []);

  return (
    <>
      <Container mBottom="20px">
        <Transportation />
        <Services />
        <Fees />
        <Additional />
      </Container>
      <Container mBottom="60px">
        <Notes />
      </Container>
      <StickyFooter>
        <Button
          type="default"
          size="large"
          onClick={onClickBack}
        >
          {t('back')}
        </Button>
        <Button
          size="large"
          onClick={handlePostData}
          style={{ marginLeft: '10px' }}
          loading={isLoading}
        >
          {t('createCreditNote')}
        </Button>
      </StickyFooter>
    </>
  );
};

export { SecondStepComponent };
