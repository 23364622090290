import i18n from 'app-wrapper/i18n/i18n';

export const validationPhone = (phone: string, option?: {
  maxValue?: number
  minValue?: number
  onlyTrueSymbol?: boolean
  isRequired?: boolean
}) => {
  const optionProps = option || {};
  const isRequired = optionProps.isRequired !== undefined ? option?.isRequired : true;
  const onlyTrueSymbol = optionProps.onlyTrueSymbol === undefined ? true : option?.onlyTrueSymbol;
  const minValue = optionProps.minValue || 7;
  const maxValue = optionProps.maxValue || 20;

  let value = phone?.slice(0, maxValue) || '';
  let errorMessage = '';

  if (onlyTrueSymbol) {
    value = value.replace(/[^0-9. ()+-]/g, '').replace('*', '');
  }

  if (!value && isRequired) {
    errorMessage = i18n.t('basicErrors.REQUIRED_MESSAGE');
    return { errorMessage, phone: value };
  }

  if (!value && !isRequired) {
    return { errorMessage, phone: value };
  }

  if (phone.length < minValue) {
    errorMessage = i18n.t('basicErrors.MIN_LENGTH', { count: minValue });
    return { errorMessage, phone: value };
  }

  if (phone.length > maxValue) {
    errorMessage = i18n.t('basicErrors.MAX_LENGTH', { count: maxValue });
    return { errorMessage, phone: value };
  }

  if (!/^\+?[0-9. ()-]{7,19}$/.test(value)) {
    errorMessage = i18n.t('basicErrors.ONLY_TRUE_LETTERS_PHONE');
    return { errorMessage, phone: value };
  }

  return { errorMessage, phone: value };
};
