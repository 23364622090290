import React from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';
import { UC } from 'shipment-operations/controllers';

import { NRAAgreementComponent } from './NRAAgreement.component';

const NRAAgreementContainer = () => {
  const isChecked = useSelector(R.selectors.bookingWizard.getIsNRAChecked);

  return (
    <NRAAgreementComponent isChecked={isChecked} onChange={UC.bookingWizard.setIsNRAChecked} />
  );
};

export { NRAAgreementContainer as NRAAgreement };
