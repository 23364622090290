import styled, { css } from 'styled-components';

import { divFlex, responsive } from 'app-wrapper/view/themes/mixin';
import { Input, Select } from 'app-wrapper/view/components';

const inputCss = css`
  font-size: 12px;
  font-weight: 400;
  color: ${({ theme }) => theme?.themesColors?.neutralBranding10};
  min-height: 32px;
`;

const inputTopMarginCss = css`
  margin-top: 4px;
`;

const inputLargeCss = css`
  height: 40px;
`;

export const CustomerAddInternalGeneralInformationName = styled.div.attrs({
  'data-class': 'CustomerAddInternalGeneralInformationName',
})`
  ${divFlex}
  flex-direction: column;
  justify-content: center;
  
  margin-bottom: 16px;
`;

export const CustomerAddInternalGeneralInformationTitle = styled.div.attrs({
  'data-class': 'CustomerAddInternalGeneralInformationTitle',
})`
  ${divFlex}
  justify-content: start;

  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  color: ${({ theme }) => theme.themesColors.neutralBranding10};
`;

export const CustomerAddInternalGeneralInformationHeader = styled.div.attrs({
  'data-class': 'CustomerAddInternalGeneralInformationHeader',
})`
  ${divFlex}
  justify-content: start;

  margin-bottom: 16px;
  font-weight: 500;
  font-size: 14px;
  color: ${({ theme }) => theme.themesColors.neutralBranding10};
`;

export const CustomerAddInternalGeneralInformationInput = styled(Input).attrs((props: {
  hasError: boolean,
  largeInput: boolean,
}) => ({
  hasError: props.hasError,
  largeInput: props.largeInput,
  'data-class': 'CustomerAddInternalGeneralInformationInput',
}))`
  ${divFlex}
  ${inputCss}
  ${inputTopMarginCss}
  ${({ largeInput }) => largeInput && inputLargeCss};
  width: 100%;

  ${responsive.forFullMedia`
    min-height: ${({ theme }: { theme: { inputConst: { hdHeight: string } } }) => theme?.inputConst?.hdHeight};
  `}
  ${({ hasError, theme }) => hasError && `border-color: ${theme.themesColors.characterBrandingDanger} !important; border-style: solid;`};
`;

export const CustomerAddInternalGeneralInformationSelect = styled(Select).attrs((props: {
  hasError: boolean,
  largeInput: boolean,
  'data-class': string,
}) => ({
  hasError: props.hasError,
  largeInput: props.largeInput,
  'data-class': props['data-class'] || 'CustomerAddInternalGeneralInformationSelect',
}))`
  ${divFlex}
  ${inputCss}
  ${inputTopMarginCss}
  ${({ largeInput }) => largeInput && inputLargeCss};
  width: 100%;


  .ant-select-item-option-content {
    font-size: 12px;
    color: ${({ theme }) => theme.themesColors.neutralBranding10};
  }

  border-width: 0px;
  .ant-select-item-option-content {
    font-family: "Roboto";
  }

  .ant-select-selector {
    min-width: ${({ theme }) => theme?.inputConst?.defaultHeight};
    ${({ hasError, theme }) => hasError && `border-color: ${theme.themesColors.characterBrandingDanger} !important; border-style: solid;`};
  }
  ${responsive.forFullMedia`
    .ant-select-selector {
      min-width: ${({ theme }: { theme: { inputConst: { hdHeight: string } } }) => theme?.inputConst?.hdHeight};
    }
  `}
  ${({ hasError, theme }) => hasError && `border-color: ${theme.themesColors.characterBrandingDanger} !important; border-style: solid;`};
`;

export const CustomerAddInternalGeneralInformationSpaceInput = styled.div.attrs({
  'data-class': 'CustomerAddInternalGeneralInformationSpaceInput',
})`
  ${divFlex}
  width: 100%;

  margin-bottom: 16px;
`;

export const CustomerAddInternalGeneralInformationSpaceRow = styled.div.attrs({
  'data-class': 'CustomerAddInternalGeneralInformationSpaceRow',
})`
  ${divFlex}
  width: 100%;

  > div:not(:first-child) {
    margin-left: 8px;
  }
`;

export const CustomerAddInternalGeneralInformationSpaceColumn = styled.div.attrs((props: {
  widthProp: string,
}) => ({
  widthProp: props.widthProp,
  'data-class': 'CustomerAddInternalGeneralInformationSpaceColumn',
}))`
  ${divFlex}
  flex-direction: column;

  ${({ widthProp }) => widthProp && `width: ${widthProp}`};
`;

export const CustomerAddInternalGeneralInformationWrapper = styled.div.attrs({
  'data-class': 'CustomerAddInternalGeneralInformationWrapper',
})`
  ${divFlex}
  flex-direction: column;
  justify-content: center;
`;
