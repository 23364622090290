import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { MoreDetailsButton, MoreDetailsButtonIcon } from './ShipmentOverviewMoreDetails.styled';

interface ShipmentOverviewMoreDetailsProps {
  onClick: () => void;
}

export const ShipmentOverviewMoreDetails: FC<ShipmentOverviewMoreDetailsProps> = ({ onClick }) => {
  const { t } = useTranslation();

  return (
    <MoreDetailsButton
      icon={<MoreDetailsButtonIcon />}
      onClick={onClick}
    >
      {t('More Details')}
    </MoreDetailsButton>
  );
};
