import React, {
  FC, useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { CompanyAddressDTM } from 'user-management/models/dtm';

import { TableSkeleton } from 'app-wrapper/view/components';
import { Layout } from 'app-wrapper/view/guideline';
import { CBLayoutHeader, CBWrapper, HeaderWrapper } from 'user-management/view/pages/ContactBook/styled';

import { CBByIdTable } from './components';
import {
  CBByIdContentBody,
  CBByIdContentTitle,
  CBByIdContentTitleArrowIcon,
  CBByIdContentTitleWrap,
} from './ContactBookByIdContactPerson.styled';
import { AccountDrawer } from '../Account/components';

interface IContactBookComponentProps {
  companyListAddresses?: CompanyAddressDTM
  isLoading: boolean
  runComponent: (idCompany: string, idAddress: string) => void
  onClickEditContact: (id: string) => () => void
  onClickAddContact: () => void
  onClickArrow: (companyId: string) => () => void
}

export const ContactBookByIdContactPersonComponent: FC<IContactBookComponentProps> = (props) => {
  const {
    companyListAddresses,
    isLoading,
    runComponent,
    onClickEditContact,
    onClickAddContact,
    onClickArrow,
  } = props;
  const { t } = useTranslation();
  const { contactId = '0', addressId = '0' } = useParams();

  useEffect(() => {
    runComponent(contactId, addressId);
  }, []);

  return (
    <CBWrapper>
      <AccountDrawer />
      <HeaderWrapper>
        <CBLayoutHeader
          title={(
            <CBByIdContentTitleWrap>
              <CBByIdContentTitleArrowIcon onClick={onClickArrow(contactId)} />
              <CBByIdContentTitle>{t('Contact Book')}</CBByIdContentTitle>
            </CBByIdContentTitleWrap>
          )}
          ghost={false}
        />
      </HeaderWrapper>
      <Layout>
        {isLoading
          ? (<TableSkeleton />)
          : (
            <CBByIdContentBody>
              <CBByIdTable
                companyListAddresses={companyListAddresses}
                nextPageTasks={() => ''}
                onClickEditAddress={onClickEditContact}
                onClickAddAddress={onClickAddContact}
                onStart={() => ''}
              />
            </CBByIdContentBody>
          )}

      </Layout>
    </CBWrapper>
  );
};
