import styled from 'styled-components';
import AntDrawer from 'antd/es/drawer';

export const Drawer = styled(AntDrawer)`
  .ant-drawer-body{
    padding: 0;
  }

  .ant-drawer-header-title {
    flex-direction: row-reverse;
  }

  .ant-drawer-close {
    margin-right: 0;
  }
`;
