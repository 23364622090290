import React, { FC, useCallback } from 'react';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';

import { hasAccess } from 'app-wrapper/utils';
import { PermissionAttributePolicy } from 'shipment-operations/constants';
import { Spin } from 'shipment-operations/view/pages/ShipmentBillOfLading/components/HBLFlowManagement/components/ClearedContent/ClearedContent.styled';
import { Button } from 'shipment-operations/view/pages/ShipmentBillOfLading/components/MBLFlowManagement/components/SubmittedContent/SubmittedContent.styled';

import { DataItem } from './components';
import {
  Content,
  Row,
} from './PendingSubmissionContent.styled';

interface IPendingSubmissionContentComponentProps {
  isLoading: boolean;
  submitSIAndApprove: (shipmentId: string) => void;
  permissionsSI?: PermissionAttributePolicy;
  isAbleToSubmitSI: boolean;
  isShippingPartiesDataFilled: boolean;
  isCargosDataFilled: boolean;
  isContainersFilled: boolean;
  isBLReleaseTypeFilled: boolean;
  isEntryNumberFilled: boolean;
  isCargoLoadPlanFilled: boolean;
  isHazmatDataFilled: boolean;
  isOriginUS: boolean;
}

export const PendingSubmissionContentComponent: FC<IPendingSubmissionContentComponentProps> = ({
  isLoading,
  submitSIAndApprove,
  isAbleToSubmitSI,
  permissionsSI,
  isOriginUS,
  isShippingPartiesDataFilled,
  isContainersFilled,
  isCargosDataFilled,
  isEntryNumberFilled,
  isHazmatDataFilled,
  isCargoLoadPlanFilled,
  isBLReleaseTypeFilled,
}) => {
  const { shipmentId = '' } = useParams();
  const { t } = useTranslation();

  const handleSubmit = useCallback(() => {
    submitSIAndApprove(shipmentId);
  }, [shipmentId, submitSIAndApprove]);

  const isAbleToEdit = permissionsSI && hasAccess(permissionsSI, PermissionAttributePolicy.WRITE);

  return (
    <Content>
      <Row>
        <DataItem
          name={t('Shipping parties')}
          isRequiredDataFilled={isShippingPartiesDataFilled}
        />

        <DataItem
          name={t('Cargo')}
          isRequiredDataFilled={isCargosDataFilled && isHazmatDataFilled && isCargoLoadPlanFilled}
        />

        <DataItem
          name={t('Containers')}
          isRequiredDataFilled={isContainersFilled}
        />

        <DataItem
          name={t('BL Release Type')}
          isRequiredDataFilled={isBLReleaseTypeFilled}
        />

        {isOriginUS ? (
          <DataItem
            name={t('Entry Number')}
            isRequiredDataFilled={isEntryNumberFilled}
          />
        ) : null}
      </Row>

      <Button
        style={{
          minWidth: 82,
        }}
        disabled={!isAbleToEdit || !isAbleToSubmitSI}
        onClick={!isLoading ? handleSubmit : undefined}
      >
        {isLoading ? <Spin size="small" /> : t('Submit')}
      </Button>
    </Content>
  );
};
