import { useEffect, useState } from 'react';

import { currentSession } from 'app-wrapper/utils';

export const useAuthToken = () => {
  const [token, setToken] = useState<string>('');
  useEffect(() => {
    currentSession.getIdToken().then(setToken);
  }, []);
  return `Bearer ${token}`;
};
