import React from 'react';
import {
  ContentSection,
  ContentSectionTitle,
  GapVerticalContainerStyled,
  SubPageContent,
  ContentGap,
  InfoCardComponent,
  CompanyLogoComponent,
  GapHorizontalContainerStyled,
  TableList,
  TableListHeader,
  TableListHeaderItem,
  TableListContent,
} from 'app-wrapper/view/guideline';
import { useTranslation } from 'react-i18next';
import { ECarrierSCAC } from 'monetary/constants';
import { ContentSectionContainer } from 'app-wrapper/view/guideline/ContentSectionContainer';
import BorderlessTableOutlined from '@ant-design/icons/BorderlessTableOutlined';
import { RoadSvg } from 'app-wrapper/view/icons';
import { ServicesDivider } from 'app-wrapper/view/components';
import EnvironmentOutlined from '@ant-design/icons/EnvironmentOutlined';
import UserOutlined from '@ant-design/icons/UserOutlined';
import {
  DepartureContainerComponent,
} from './components/DepartureContainer';

export const Overview = () => {
  const { t } = useTranslation();
  return (
    <GapVerticalContainerStyled>
      <SubPageContent>
        <ContentSectionTitle>
          {t('Supplier’s Details')}
        </ContentSectionTitle>
        <GapHorizontalContainerStyled>
          <ContentSection style={{ width: '70%' }}>
            <GapVerticalContainerStyled width="100%">
              <ContentSectionContainer>
                {t('Maxton Shipping')}
              </ContentSectionContainer>
              <ContentSectionContainer>
                <ServicesDivider noMargin />
              </ContentSectionContainer>
              <ContentSectionContainer>
                <ContentGap>
                  <ContentSectionContainer>
                    <InfoCardComponent
                      icon={<EnvironmentOutlined />}
                      title={t('Supplier’s Address')}
                      value={(
                        <>
                          701 New Dock Street Berth 212-215, <br />
                          Terminal Islands, California, <br />
                          USA (US)
                        </>
                      )}
                    />
                  </ContentSectionContainer>
                  <ContentSectionContainer>
                    <InfoCardComponent
                      icon={<UserOutlined />}
                      title={t('Loading Time')}
                      value={(
                        <>
                          Alexander Heles<br />
                          info@maxtonshipping.com<br />
                          + 1 650 410 8900<br />
                          +1 650 410 8902
                        </>
                      )}
                    />
                  </ContentSectionContainer>
                </ContentGap>
              </ContentSectionContainer>
            </GapVerticalContainerStyled>
          </ContentSection>
          <ContentSection style={{ width: '30%' }}>
            <GapVerticalContainerStyled width="100%">
              <ContentSectionContainer>
                {t('References')}
              </ContentSectionContainer>
              <ContentSectionContainer>
                <ServicesDivider noMargin />
              </ContentSectionContainer>
              <ContentSectionContainer>
                <InfoCardComponent
                  title={t('Shipper\'s Reference')}
                  value="ASDAD 516551"
                />
              </ContentSectionContainer>
              <ContentSectionContainer>
                <ServicesDivider noMargin />
              </ContentSectionContainer>
              <ContentSectionContainer>
                <InfoCardComponent
                  title={t('On Behalf Of')}
                  value="ASDAD 516551"
                />
              </ContentSectionContainer>
            </GapVerticalContainerStyled>
          </ContentSection>
        </GapHorizontalContainerStyled>
      </SubPageContent>
      <SubPageContent>
        <ContentSectionTitle>
          {t('Loading')}
        </ContentSectionTitle>
        <GapHorizontalContainerStyled>
          <ContentSection>
            <GapVerticalContainerStyled width="100%">
              <ContentSectionContainer>
                {t('Shipping Method')}
              </ContentSectionContainer>
              <ContentSectionContainer>
                <ServicesDivider noMargin />
              </ContentSectionContainer>
              <ContentSectionContainer>
                <ContentGap>
                  <ContentSectionContainer>
                    <InfoCardComponent
                      title={t('Loading Method')}
                      value="Live Load"
                    />
                  </ContentSectionContainer>
                  <ContentSectionContainer>
                    <InfoCardComponent
                      title={t('Loading Time')}
                      value="2 hours"
                    />
                  </ContentSectionContainer>
                  <ContentSectionContainer>
                    <InfoCardComponent
                      title={t('Receiving Method')}
                      value="First Come / First Served"
                    />
                  </ContentSectionContainer>
                </ContentGap>
              </ContentSectionContainer>
            </GapVerticalContainerStyled>
          </ContentSection>
          <ContentSection>
            <GapVerticalContainerStyled width="100%">
              <ContentSectionContainer>
                {t('Supplier’s Schedule')}
              </ContentSectionContainer>
              <ContentSectionContainer>
                <ServicesDivider noMargin />
              </ContentSectionContainer>
              <ContentSectionContainer>
                <ContentGap>
                  <ContentSectionContainer>
                    <InfoCardComponent
                      title={t('Cargo Ready Date')}
                      value="10 Nov 2025, 18:00"
                    />
                  </ContentSectionContainer>
                  <ContentSectionContainer>
                    <InfoCardComponent
                      title={t('Earliest Pickup')}
                      value="10 Nov 2025, 18:00"
                    />
                  </ContentSectionContainer>
                  <ContentSectionContainer>
                    <InfoCardComponent
                      title={t('Latest Pickup')}
                      value="12 Nov 2025, 18:00"
                    />
                  </ContentSectionContainer>
                </ContentGap>
              </ContentSectionContainer>
            </GapVerticalContainerStyled>
          </ContentSection>
        </GapHorizontalContainerStyled>
      </SubPageContent>
      <SubPageContent>
        <ContentSectionTitle>
          {t('Carrier\'s Details')}
        </ContentSectionTitle>
        <GapVerticalContainerStyled>
          <ContentSection>
            <ContentGap>
              <ContentSectionContainer>
                <CompanyLogoComponent carrierSCAC={ECarrierSCAC.CMDU} />
              </ContentSectionContainer>
              <ContentSectionContainer>
                <InfoCardComponent
                  title={t('Carrier')}
                  value="Maersk"
                  icon={<RoadSvg />}
                />
              </ContentSectionContainer>
              <ContentSectionContainer>
                <InfoCardComponent
                  title={t('Booking Reference')}
                  value="ASDAD 516551"
                  icon={<BorderlessTableOutlined />}
                />
              </ContentSectionContainer>
            </ContentGap>
          </ContentSection>
          <ContentSection>
            <GapVerticalContainerStyled width="100%">
              <ContentSectionContainer>
                {t('Cutoff')}
              </ContentSectionContainer>
              <ContentSectionContainer>
                <ServicesDivider noMargin />
              </ContentSectionContainer>
              <ContentSectionContainer>
                <ContentGap>
                  <ContentSectionContainer>
                    <InfoCardComponent
                      title={t('Latest Return (Port Cutoff)')}
                      value="10 Nov 2025, 18:00"
                    />
                  </ContentSectionContainer>
                  <ContentSectionContainer>
                    <InfoCardComponent
                      title={t('VGM Cutoff')}
                      value="10 Nov 2025, 18:00"
                    />
                  </ContentSectionContainer>
                  <ContentSectionContainer>
                    <InfoCardComponent
                      title={t('Documentation Cutoff')}
                      value="10 Nov 2025, 18:00"
                    />
                  </ContentSectionContainer>
                  <ContentSectionContainer>
                    <InfoCardComponent
                      title={t('Dangerous Goods Cutoff')}
                      value="10 Nov 2025, 18:00"
                    />
                  </ContentSectionContainer>
                </ContentGap>
              </ContentSectionContainer>
            </GapVerticalContainerStyled>
          </ContentSection>
        </GapVerticalContainerStyled>
      </SubPageContent>
      <SubPageContent>
        <GapVerticalContainerStyled width="100%">
          <ContentSectionTitle>
            {t('Departure(s)')}
          </ContentSectionTitle>
          <ContentSectionContainer>
            <ServicesDivider noMargin />
          </ContentSectionContainer>
          <ContentSectionContainer>
            <TableList>
              <TableListHeader>
                <TableListHeaderItem
                  width="20%"
                  align="left"
                >
                  {t('Container ID')}
                </TableListHeaderItem>
                <TableListHeaderItem
                  width="20%"
                  align="left"
                >
                  {t('Number')}
                </TableListHeaderItem>
                <TableListHeaderItem
                  width="20%"
                  align="left"
                >
                  {t('Empty Arrival')}
                </TableListHeaderItem>
                <TableListHeaderItem
                  width="20%"
                  align="left"
                >
                  {t('Full Departure')}
                </TableListHeaderItem>
                <TableListHeaderItem
                  width="10%"
                  align="left"
                >
                  {t('Dispatch Order')}
                </TableListHeaderItem>
                <TableListHeaderItem
                  width="10%"
                  align="right"
                >
                  {t('Status')}
                </TableListHeaderItem>
              </TableListHeader>

              <TableListContent id="CCContentTableContent">
                <DepartureContainerComponent />
              </TableListContent>
            </TableList>
          </ContentSectionContainer>
        </GapVerticalContainerStyled>
      </SubPageContent>
    </GapVerticalContainerStyled>
  );
};
