import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { UC } from 'user-management/controllers';
import { R } from 'user-management/repository';

import { FooterComponent } from './Footer.component';

export const Footer = () => {
  const documentToUpload = useSelector(R.selectors.newOrganizationDocuments.getDocumentToUpload);

  const isDisabled = useMemo(() => !documentToUpload || !documentToUpload.response, [documentToUpload]);

  return (
    <FooterComponent
      onCancel={UC.newOrganizationDocuments.cancelDocumentUpload}
      onSubmit={UC.newOrganizationDocuments.saveUploadedDocument}
      disabled={isDisabled}
    />
  );
};
