import React, { FC, CSSProperties } from 'react';
import { VerticalFormItemSvg } from 'app-wrapper/view/icons';

interface IExpandIconProps {
  isActive?: boolean;
  color?: string;
  className?: string
  styles?: CSSProperties
}

export const ExpandIcon: FC<IExpandIconProps> = ({
  isActive,
  color,
  className,
  styles = {},
}) => (
  <span
    className={`anticon anticon-right ant-collapse-arrow ${className}`}
    style={{
      position: 'relative',
      ...styles,
    }}
  >
    <VerticalFormItemSvg
      style={{
        transform: `rotate(${isActive ? 0 : -90}deg)`,
        position: 'relative',
        top: '-3px',
        color,
      }}
    />
  </span>
);
