import { apiWorker } from 'app-wrapper/repository/utilsServices';

export class OrganizationDocumentsService {
  public url = '/organization-service/api/v1/organizations';

  public deleteDocument = async (organizationId: number | string, documentId: number) => {
    try {
      await apiWorker.requestDelete(`${this.url}/${organizationId}/documents/${documentId}`);
    } catch (e) {
      throw Error('OrganizationDocumentsService: deleteDocument');
    }
  };

  public downloadOrganizationDocument = async (
    organizationId: number | string,
    documentId: number,
    fileName = 'document',
  ) => {
    try {
      const response = await apiWorker.requestGet<Blob>(`${this.url}/${organizationId}/documents/${documentId}`, {
        responseType: 'blob',
      });
      const blob = response.data;

      if (!blob) {
        return;
      }

      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement('a');

      anchor.href = url;
      anchor.download = fileName;
      anchor.click();

      window.URL.revokeObjectURL(url);
      anchor.remove();
    } catch (e) {
      throw new Error('OrganizationDocumentsService: downloadOrganizationDocument');
    }
  };
}
