import React, { FC } from 'react';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';

import { useAuthToken } from 'app-wrapper/hooks';
import { UC } from 'shipment-operations/controllers';
import { R } from 'shipment-operations/repository';
import { ContainerDocumentDTM } from 'shipment-operations/models/dtm';
import { DocumentType } from 'shipment-operations/constants';

import { DocumentUploadComponent } from './UploadDocument.component';

interface ISDHUploadDocumentContainerProps {
  file: ContainerDocumentDTM | null;
  containerId: string;
  disabled?: boolean;
}

const UploadDocumentContainer: FC<ISDHUploadDocumentContainerProps> = ({ file: propsFile, containerId, disabled }) => {
  const authToken = useAuthToken();
  const { shipmentId = '' } = useParams();
  const hasError = useSelector(R.selectors.shipmentDocumentsSeaworthyCertificates.getHasErrorById(containerId));

  return (
    <DocumentUploadComponent
      shipmentId={shipmentId}
      authToken={authToken}
      error={hasError}
      containerId={containerId}
      containerDocument={propsFile}
      documentType={DocumentType.CSW}
      downloadDocument={UC.shipmentDocumentsSeaworthyCertificates.downloadDocument}
      setContainerDocument={UC.shipmentDocumentsSeaworthyCertificates.setContainerDocument}
      disabled={disabled}
    />
  );
};

export { UploadDocumentContainer as UploadDocument };
