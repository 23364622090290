import { ControllerParams } from 'proto/BaseController';
import { AdditionalServicesController, FreightQuoteController, FreightRFRQuoteController } from './FreightQuoteController';

import { InvoicesController } from './InvoicesController';
import { OceanRatesModalController } from './OceanRatesModalController';
import { PaymentsController } from './PaymentsController';
import { RatesController } from './RatesController';
import { RatesModalController } from './RatesModalController';
import { SurchargesRatesModalController } from './SurchargesRatesModalController';
import { FAKExceptionController } from './FAKException';

type UseCases = {
  FreightQuote: FreightQuoteController
  FreightRFRQuote: FreightRFRQuoteController
  AdditionalServices: AdditionalServicesController
  invoices: InvoicesController
  oceanRatesModal: OceanRatesModalController
  payments: PaymentsController
  rates: RatesController
  ratesModal: RatesModalController
  surchargesRatesModal: SurchargesRatesModalController
  fakException: FAKExceptionController
}

// @ts-ignore
export const UC: UseCases = {};

export const initUC = (controllerParams: ControllerParams) => {
  UC.FreightQuote = new FreightQuoteController(controllerParams);
  UC.FreightRFRQuote = new FreightRFRQuoteController(controllerParams);
  UC.AdditionalServices = new AdditionalServicesController(controllerParams);
  UC.invoices = new InvoicesController(controllerParams);
  UC.oceanRatesModal = new OceanRatesModalController(controllerParams);
  UC.payments = new PaymentsController(controllerParams);
  UC.rates = new RatesController(controllerParams);
  UC.ratesModal = new RatesModalController(controllerParams);
  UC.surchargesRatesModal = new SurchargesRatesModalController(controllerParams);
  UC.fakException = new FAKExceptionController(controllerParams);
};
