import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { GapHorizontalContainerStyled, GapVerticalContainerStyled, Layout } from 'app-wrapper/view/guideline';

import { SkeletonSection } from 'app-wrapper/view/components/Skeleton/Skeleton.component';
import {
  OverviewLayout,
  OverviewHeader,
  InnerHeader,
} from './Overview.styled';
import {
  GettingStarted,
  ExploreCommandCenter,
  InformationCards,
  StepsManager,
  Dashboard,
  RejectedScreens,
} from './components';

interface OverviewComponentProps {
  init: () => void;
  isLoading: boolean;
  isUserOnboarded: boolean;
  isOrganizationRejected: boolean;
  showTermsAccept?: boolean;
}

export const OverviewComponent: FC<OverviewComponentProps> = ({
  init,
  isLoading,
  isUserOnboarded,
  isOrganizationRejected,
  showTermsAccept,
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    init();
  }, []);

  return (
    <OverviewLayout>
      <OverviewHeader>
        <InnerHeader>
          {t('Overview')}
        </InnerHeader>
      </OverviewHeader>

      <Layout>
        {isLoading ? (
          <GapVerticalContainerStyled>
            <GapHorizontalContainerStyled>
              <SkeletonSection height="81px" />
              <SkeletonSection height="81px" />
            </GapHorizontalContainerStyled>
            <SkeletonSection height="327px" />
            <GapHorizontalContainerStyled>
              <SkeletonSection height="294px" />
              <SkeletonSection height="294px" />
            </GapHorizontalContainerStyled>
            <GapHorizontalContainerStyled>
              <SkeletonSection height="76px" />
              <SkeletonSection height="76px" />
            </GapHorizontalContainerStyled>
            <SkeletonSection height="294px" />
          </GapVerticalContainerStyled>
        ) : (
          <>
            {isUserOnboarded && !showTermsAccept ? (
              <Dashboard />
            ) : (
              <>
                {isOrganizationRejected ? <RejectedScreens /> : (
                  <>
                    <GettingStarted />
                    <StepsManager />
                    <ExploreCommandCenter />
                    <InformationCards />
                  </>
                )}
              </>
            )}
          </>
        )}
      </Layout>
    </OverviewLayout>
  );
};
