import React, {
  ChangeEvent,
  FC,
  useCallback,
} from 'react';
import { useTranslation } from 'react-i18next';

import { InputStyled } from './DescriptionInput.styled';

interface DescriptionInputComponentProps {
  cargoId: number;
  description: string;
  touchCargoField: (field: string, cargoId: number) => void
  setDescription: (description: string, cargoId: number) => void
}

export const DescriptionInputComponent: FC<DescriptionInputComponentProps> = ({
  cargoId,
  description,
  touchCargoField,
  setDescription,
}) => {
  const { t } = useTranslation();

  const handleChangeDescription = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setDescription(event.target.value, cargoId);
  }, [setDescription, cargoId]);

  const handleBlur = useCallback(() => {
    touchCargoField('description', cargoId);
  }, [touchCargoField, cargoId]);

  return (
    <InputStyled
      placeholder={t('Enter description')}
      value={description}
      onChange={handleChangeDescription}
      onBlur={handleBlur}
    />
  );
};
