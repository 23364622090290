import styled from 'styled-components';

import { Button } from 'app-wrapper/view/components';

import { divFlex } from './main';

export const RFQItemFooter = styled.div.attrs({
  'data-class': 'RFQItemFooter',
})`
  ${divFlex}
  padding: 0px 16px;
  height: 48px;
  justify-content: center;
`;

export const RFQItemFooterDetail = styled
  .div
  .attrs(
    (props: {
      isDetail: boolean,
    }) => ({
      isDetail: props.isDetail,
    }),
  )`
${divFlex}
align-items: center;
justify-content: center;
button {
  background-color: ${({ theme }) => theme.themesColors.neutralBranding1};
  width: 97px;
  height: 32px;
  font-size: 14px;
  font-weight: 400;
  border: 1px solid;
  border-color: ${({ theme, isDetail }) => (isDetail ? theme.themesColors.purpleSecondary : theme.themesColors.neutralBranding5)} !important;
  border-radius: 3px;
  text-transform: capitalize;
  span, svg {
    color: ${({ theme, isDetail }) => (isDetail ? theme.themesColors.primaryBranding6 : theme.themesColors.neutralBranding10)};
  }
  &:hover, &:active {
    span, svg {
      color: ${({ theme, isDetail }) => (isDetail ? theme.themesColors.primaryBranding4 : theme.themesColors.primaryBranding5)} !important;
    }
    background: none !important;
    border-color: ${({ theme, isDetail }) => (isDetail ? theme.themesColors.primaryBranding3 : theme.themesColors.primaryBranding4)} !important;
  }
  &:focus {
    background: none !important;
    box-shadow: 0 0 3px ${({ theme }) => theme.themesColors.primaryBranding6}
  }
  svg {
    position: relative;
    top: -1px;
    left: -8px;
  }
}
`;

export const RFQItemFooterDetailExpired = styled.div.attrs((props: {
  isExpired: boolean,
}) => ({
  ...props,
  isExpired: props.isExpired,
  'data-class': 'RFQItemFooterDetailExpired',
}))`
  ${divFlex}

  font-size: 14px;
  font-weight: 400;
  margin-left: 8px;
  color: ${({ theme, isExpired }) => (isExpired ? theme.themesColors.characterBrandingDanger : theme.themesColors.darkGrayMiddle)};
  justify-content: start;
`;

export const RFQItemFooterDetailExpiredDate = styled.div.attrs((props: {
  isExpired: boolean,
}) => ({
  ...props,
  isExpired: props.isExpired,
  'data-class': 'RFQItemFooterDetailExpiredDate',
}))`
  ${divFlex}

  font-size: 14px;
  font-weight: 400;
  margin-left: 4px;
  color: ${({ theme, isExpired }) => (isExpired ? theme.themesColors.characterBrandingDanger : theme.themesColors.neutralBranding10)};
`;

export const RFQItemFooterBook = styled.div.attrs({
  'data-class': 'RFQItemFooterBook',
})`
  ${divFlex}
  align-items: center;
  justify-content: center;
  margin-left: auto;
`;

export const RFQItemFooterFavoriteButton = styled(Button).attrs({
  'data-class': 'RFQItemFooterFavoriteButton',
})`
  ${divFlex}
  width: 32px;
  align-items: center;
  justify-content: center;
  padding: 0;

  color: ${({ theme }) => theme.themesColors.neutralBranding1};
  background-color: ${({ theme }) => theme.themesColors.neutralBranding1};
  height: 32px;
  border: 2px solid ${({ theme }) => theme.colors.grayOpacity4};
  border-radius: 3px;
  text-transform: capitalize;
  border: none;
`;

export const RFQItemFooterFavoritePrint = styled(Button).attrs({
  'data-class': 'RFQItemFooterFavoritePrint',
})`
  ${divFlex}
  align-items: center;
  justify-content: center;

  color: ${({ theme }) => theme.themesColors.neutralBranding10};
  background-color: ${({ theme }) => theme.themesColors.neutralBranding1};
  width: 64px;
  height: 32px;
  border: 1px solid ${({ theme }) => theme.themesColors.neutralBranding5};
  border-radius: 3px;
  text-transform: capitalize;
  margin-left: 10px;
`;

export const RFQItemFooterBookButton = styled(Button).attrs((props: {
  isFavorite: boolean,
}) => ({
  ...props,
  isFavorite: props.isFavorite,
  'data-class': 'RFQItemFooterBookButton',
}))`
  ${divFlex}
  align-items: center;
  justify-content: center;

  color: ${({ theme }) => theme.themesColors.neutralBranding1};
  background-color: ${({ theme }) => theme.themesColors.neutralBranding8};
  width: 64px;
  height: 32px;
  border: 2px solid ${({ theme }) => theme.colors.grayOpacity4};
  border-radius: 3px;
  text-transform: capitalize;
  border: none;
  margin-left: 10px;
`;
