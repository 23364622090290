import styled from 'styled-components';
import { divFlex } from 'app-wrapper/view/themes/mixin';

export const UMCWrapTabsLink = styled.div.attrs({
  'data-class': 'UMCWrapTabsLink',
})`
  ${divFlex}

  height: 28px;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.themesColors.neutralBranding4};
  padding-bottom: 10px;
  margin-bottom: 13px;

  > div:not(:first-child) {
    margin-left: 32px;
  }
`;

export const UMCWrapLinkText = styled.div.attrs((props: {
  isActive: boolean
  'data-class'?: string
}) => ({
  isActive: props.isActive,
  'data-class': props?.['data-class'] || 'UMCWrapLinkText',
}))`
  ${divFlex}

  position: relative;
  height: 28px;
  color: ${({ isActive, theme }) => (isActive ? theme.themesColors.neutralBranding10 : theme.themesColors.secondaryDot45)};
  padding-bottom: 10px;
  cursor: pointer;

  ::after {
    ${({ isActive }) => (isActive ? 'content: ""' : 'none')};
    bottom: 0px;
    position: absolute;
    height: 2px;
    width: 100%;
    background-color: ${({ theme }) => theme.themesColors.primaryBranding6};
  }
`;

export const UMCTextAlert = styled.div`
  position: absolute;
  top: 0;
  right: -6px;
  min-width: 5px;
  min-height: 5px;
  background-color: #4A55AD;
  border-radius: 50%;
`;

export const UMCContent = styled.div.attrs({
  'data-class': 'UMCContent',
})`
  ${divFlex}

  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
  color: ${({ theme }) => theme.themesColors.neutralBranding10};
`;
