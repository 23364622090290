import React, { FC, useCallback } from 'react';
import { OrganizationFieldsErrorsFieldDTM } from 'user-management/models/dtm';

import {
  OrganizationStateSelectComponent,
} from 'user-management/view/components/OrganizationStateSelect';
import { UC } from 'user-management/controllers';
import { useSelector } from 'react-redux';
import { R } from 'user-management/repository';
import { useTranslation } from 'react-i18next';

export const CustomerAddInternalStateContainer: FC = () => {
  const organization = useSelector(R.selectors.userOrganizationData.getCustomersAddInternal);
  const organizationFieldsErrors = useSelector(R.selectors.userOrganizationData.getCustomersAddInternalErrors);

  const getHasErrorField = useCallback(
    (filed?: OrganizationFieldsErrorsFieldDTM) => !!(filed?.isVisited && filed?.message),
    [],
  );

  const { t } = useTranslation();

  return (
    <OrganizationStateSelectComponent
      data-class="OrganizationStateSelect"
      value={organization?.address?.state}
      hasError={getHasErrorField(organizationFieldsErrors?.addressState)}
      showSearch
      allowClear={!!organization?.address.country}
      placeholder={t('Please select')}
      country={organization?.address?.country}
      onChange={UC.organizationProfile.onChangeCustomerAddInternalAddressState}
      onClear={UC.organizationProfile.onClearCustomerAddInternalAddressState}
      onBlur={UC.organizationProfile.onBlurCustomerAddInternalAddressState}
    />
  );
};
