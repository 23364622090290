import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';

import { PackQTYInputComponent } from './PackQTYInput.component';

interface DescriptionInputContainerProps {
  cargoId: number;
}

const PackQTYInputContainer: FC<DescriptionInputContainerProps> = ({ cargoId }) => {
  const { packagesNumber = '', errors } = useSelector(R.selectors.manualBookingWizard.getCargoById(cargoId));
  const { packagesNumber: error } = errors;

  return (
    <PackQTYInputComponent
      packagesQTY={packagesNumber}
      error={error}
    />
  );
};

export { PackQTYInputContainer as PackQTYInput };
