import {
  IsNumber, IsString, IsDefined, ValidateNested, IsOptional, IsBoolean,
} from 'class-validator';
import { Type } from 'class-transformer';

import { FreightSelectFieldDTM } from 'monetary/models/dtm';
import { BaseDTM } from 'proto/BaseDTM';
import { TransportPlanDTM, ITransportPlanDTM } from 'shipment-operations/models/dtm/Plans.dtm';
import { Moment } from 'moment';

interface ICountryDTM {
  code: string
  name: string
}

class CountryDTM extends BaseDTM<ICountryDTM> {
  @IsString()
  @IsDefined()
  code: string;

  @IsString()
  @IsDefined()
  name: string;
}

interface IStateDTM {
  code?: string
  name: string
}

class StateDTM extends BaseDTM<IStateDTM> {
  @IsString()
  @IsOptional()
  code?: string;

  @IsString()
  @IsDefined()
  name: string;
}

interface ICoordinatesDTM {
  lat: number
  lng: number
}

class CoordinatesDTM extends BaseDTM<ICoordinatesDTM> {
  @IsNumber()
  @IsDefined()
  lat: number;

  @IsNumber()
  @IsDefined()
  lng: number;
}

export interface ILocationDTM {
  code: string
  name: string
  id: number
  country: ICountryDTM
  state?: IStateDTM
  coordinates?: ICoordinatesDTM
  timeZoneId?: string
}

export class LocationDTM extends BaseDTM<ILocationDTM> {
  @IsString()
  @IsDefined()
  code: string;

  @IsString()
  @IsDefined()
  name: string;

  @IsNumber()
  @IsDefined()
  id: number;

  @IsDefined()
  @ValidateNested()
  @Type(() => CountryDTM)
  country: CountryDTM;

  @IsOptional()
  @ValidateNested()
  @Type(() => StateDTM)
  state?: StateDTM;

  @IsOptional()
  @ValidateNested()
  @Type(() => CoordinatesDTM)
  coordinates?: CoordinatesDTM;

  @IsString()
  @IsOptional()
  timeZoneId?: string;
}

interface ILoadPlanDTM {
  previous: number | null
  current: number | null
  field: string
  id: string
  relatesToType: string
  valueType: string
}

export class LoadPlanDTM extends BaseDTM<ILoadPlanDTM> {
  @IsNumber()
  @IsOptional()
  previous: number | null;

  @IsNumber()
  @IsOptional()
  current: number | null;

  @IsString()
  @IsDefined()
  field: string;

  @IsString()
  @IsDefined()
  id: string;

  @IsString()
  @IsDefined()
  relatesToType: string;

  @IsString()
  @IsDefined()
  valueType: string;
}

export interface IShipmentChangesDtm {
  previous?: string | number | ILocationDTM | ITransportPlanDTM | null | ILoadPlanDTM[]
  current?: string | number | ILocationDTM | ITransportPlanDTM | null | ILoadPlanDTM[]
  mismatchesCurrent?: string | number | ILocationDTM | ITransportPlanDTM | null | ILoadPlanDTM[]
  mismatchesPrevious?: string | number | ILocationDTM | ITransportPlanDTM | null | ILoadPlanDTM[]
  id?: string
  valueType: string,
  field?: string,
  relatesToType: string,
  mismatch: boolean
  key: string
  location?: ILocationDTM
}

export class ShipmentChangesDtm extends BaseDTM<IShipmentChangesDtm> {
  @IsString()
  @IsOptional()
  id?: string;

  @IsOptional()
  previous?: LocationDTM | number | string | TransportPlanDTM | null | LoadPlanDTM[];

  @IsOptional()
  current?: LocationDTM | number | string | TransportPlanDTM | null | LoadPlanDTM[];

  @IsOptional()
  mismatchesCurrent?: LocationDTM | number | string | TransportPlanDTM | null | LoadPlanDTM[];

  @IsOptional()
  mismatchesPrevious?: LocationDTM | number | string | TransportPlanDTM | null | LoadPlanDTM[];

  @IsString()
  @IsDefined()
  valueType: string;

  @IsString()
  @IsOptional()
  field?: string;

  @IsString()
  @IsDefined()
  relatesToType: string;

  @IsOptional()
  @IsBoolean()
  mismatch: boolean;

  @IsString()
  @IsDefined()
  key: string;

  @IsOptional()
  @ValidateNested()
  @Type(() => LocationDTM)
  location?: LocationDTM;
}

export interface IChangesTable {
  title: string | {
    name: string
    value: string
  }
  key?: string
  label?: string
  isDate?: boolean
  isMismatch?: boolean
  fieldName?: string
  isError?: boolean
  type?: string
  emptyRender?: boolean
  confirmed?: ({
    [key: string]: string | number | LocationDTM | TransportPlanDTM | null | Moment | undefined
  } | null)[] | string | null | number | Moment | ({
    // [key: string]: string | number | LocationDTM | TransportPlanDTM | null | Moment | undefined
  })
  requested?: ({
    [key: string]: string | number | LocationDTM | TransportPlanDTM | null | Moment | undefined
  } | null)[] | string | null | number | Moment | ({
    // [key: string]: string | number | LocationDTM | TransportPlanDTM | null | Moment | undefined
  })
  carrierConfirmed?: ({
    [key: string]: string | number | LocationDTM | TransportPlanDTM | null | Moment | undefined
  } | null)[] | string | null | number | Moment | ({
    // [key: string]: string | number | LocationDTM | TransportPlanDTM | null | Moment | undefined
  })
}

export interface ITransportPlanChanges {
  customId: string
  id: number
  type: string
  location: string
  locationType: string
  isUpdatedLocation: boolean
  locationLoading: boolean
  locationList: FreightSelectFieldDTM[]
  nextLocation: string
  nextLocationType: string
  isUpdatedNextLocation: boolean
  nextLocationLoading: boolean
  nextLocationList: FreightSelectFieldDTM[]
  etdTime: Moment
  etaTime: Moment
  vessel: string
  voyage: string
  number: string
}
