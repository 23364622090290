import { GetFAKExceptionsContract } from 'monetary/models/contracts';
import { apiWorker } from 'app-wrapper/repository/utilsServices';
import { FAKExceptionsDTM } from 'monetary/models/dtm/Quotas';

export class FAKExceptionService {
  public getData = async (codes: string[]) => {
    let result: FAKExceptionsDTM[];
    try {
      const response = await apiWorker.requestGet<GetFAKExceptionsContract[]>(`commodity-service/api/v1/commodity-items/tree?codes=${codes}`);
      const parsedResponse = response.data.map((parsedRes) => {
        const parsedItem = FAKExceptionsDTM.fromPlain({
          code: parsedRes.code,
          description: parsedRes.description,
          itemType: parsedRes.itemType,
          children: parsedRes.children,
        });
        if (!parsedItem.isValid()) {
          console.error('Data from API does not match with contract');
        }
        return parsedItem;
      });
      result = parsedResponse.filter((el) => el !== null) as FAKExceptionsDTM[];
    } catch (e) {
      throw new Error('Something wrong, please try again');
    }
    return result;
  }
}
