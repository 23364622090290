import styled from 'styled-components';
import InputNumber from 'antd/es/input-number';

import { DatePicker } from 'app-wrapper/view/components';

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 152px;
  justify-content: space-between;
`;

export const Payment = styled.div`
  width: 65%;
  background-color: white;
  padding: 16px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const InputWrapper = styled.div`
  width: 49%;
  margin-bottom: 8px;
`;

export const Total = styled.div`
  height: 100%;
  width: 34%;
  background-color: white;
  padding: 28px 16px 0 16px;
  text-align: right;
`;

export const FieldName = styled.p`
  font-weight: 400;
  font-size: 12px;
  color: #202C3C;
  margin: 0 0 4px 0;
`;

export const TotalTitle = styled.p`
  font-weight: 400;
  font-size: 16px;
  color: #202C3C;
  margin: 0;
`;

export const TotalSubTitle = styled.p`
  font-weight: 400;
  font-size: 12px;
  color: #73819B;
  margin: 0 0 20px 0;
`;

export const StyledInputNumber = styled(InputNumber).attrs((props: {
  'data-class'?: string
}) => ({
  'data-class': props['data-class'] || 'StyledInputNumber',
}))`
  text-align: right;
  font-size: 16px;
  width: 100%;
  
  input {
    text-align: right;
  }
`;

export const StyledDatePicker = styled(DatePicker)`
  height: 32px;
  
  input {
    font-size: 12px;
  }
`;
