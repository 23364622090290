import React from 'react';
import { observer } from 'mobx-react-lite';

import { useMobxStore } from 'app-wrapper/mobxStores';
import { UC } from 'shipment-operations/controllers';

import { InputResultsComponent } from './InputResults.component';

export const InputResults = observer(() => {
  const { shipmentServices } = useMobxStore();

  return (
    <InputResultsComponent
      relatedActivities={shipmentServices.state.inputActivities}
      othersActivities={shipmentServices.state.othersInputActivities}
      startEditingFlowFromInputActivity={UC.shipmentServices.startEditingFlowFromInputActivity}
    />
  );
});
