import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { R } from 'shipment-operations/repository';
import { UC } from 'shipment-operations/controllers';

import { ManualBookingDrawerComponent } from './ManualBookingDrawer.component';

// temporary not used component

const ManualBookingDrawerContainer = () => {
  const { shipmentId = '0' } = useParams();
  const isWizardOpened = useSelector(R.selectors.manualBookingWizard.getIsWizardOpened);
  const errorMessage = useSelector(R.selectors.manualBookingWizard.getResponseErrorMessage);

  return (
    <ManualBookingDrawerComponent
      errorMessage={errorMessage}
      onClose={UC.manualBookingWizard.closeWizard}
      isOpen={isWizardOpened}
      shipmentId={shipmentId}
      init={UC.manualBookingWizard.init}
    />
  );
};

export { ManualBookingDrawerContainer as ManualBookingDrawer };
