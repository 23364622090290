import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'app-wrapper/createSlice';

import { TChangePasswordState } from 'user-management/models/states';

export const initialState: TChangePasswordState = {
  isLoading: false,
};

export const changePasswordStore = createSlice({
  name: 'changePassword',
  initialState,
  reducers: {
    setCurrentPassword: (state, action: PayloadAction<string>) => {
      state.currentPassword = action.payload;
    },
    setCurrentPasswordError: (state, action: PayloadAction<Error>) => {
      state.currentPasswordError = action.payload;
    },
    clearCurrentPasswordError: (state) => {
      state.currentPasswordError = undefined;
    },
    setNewPassword: (state, action: PayloadAction<string>) => {
      state.newPassword = action.payload;
    },
    setNewPasswordError: (state, action: PayloadAction<Error>) => {
      state.newPasswordError = action.payload;
    },
    clearNewPasswordError: (state) => {
      state.newPasswordError = undefined;
    },
    setConfirmNewPassword: (state, action: PayloadAction<string>) => {
      state.confirmNewPassword = action.payload;
    },
    setConfirmNewPasswordError: (state, action: PayloadAction<Error>) => {
      state.confirmNewPasswordError = action.payload;
    },
    clearConfirmNewPasswordError: (state) => {
      state.confirmNewPasswordError = undefined;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    clear: () => (initialState),
  },
});

export const changePasswordActions = changePasswordStore.actions;
export const changePasswordReducer = changePasswordStore.reducer;
