import React, { FC } from 'react';

import { CloseSvg } from 'app-wrapper/view/icons';

import { AccountDrawerContentNamesList, EAccountDrawerContent } from 'user-management/constants';

import {
  AACContactAddContactPersonPage,
  AACContactEditContactPersonPage,
  AccountDepartmentEditPage,
  AddressesAndContactsEditAddressPage,
  AddressesAndContactsPage,
  AddressesAndContactsSecondPage,
  BookingAddCompanyPage,
  ChangePassword,
  ContactBookAddContactPage,
  ContactBookByIdAddAddressPage,
  ContactBookByIdAddContactPersonPage,
  ContactBookByIdEditAddressPage,
  ContactBookByIdEditCompanyPage,
  ContactBookByIdEditContactPersonPage,
  EditProfileOrganization,
  ShipmentPartyAddAddressPage,
  ShipmentPartyAddCompanyPage,
  ShipmentPartyAddContactPage,
  UserEditProfile,
  UserInvite,
  UserManagementEdit,
} from './components';

import { AccountDrawerCloset, AccountDrawerWrapper } from './AccountDrawer.styled';
import { CustomerAddInternalPage } from './components/CustomerAddInternal';

interface IShipmentDrawerComponentProps {
  isVisible: boolean
  activeContent?: EAccountDrawerContent
  dynamicDrawerTitle?: string
  onCloseOrganization: () => void
  onCloseAccountDepartmentEdit: () => void
  onCloseOrganizationAddInternal: () => void
  closeAddressesAndContactsAddAddressDrawer: () => void
  closeAddressesAndContactsEditAddressDrawer: () => void
  closeAACContactEditContactPersonDrawer: () => void
  closeAACContactAddContactPersonDrawer: () => void
  closeCBAddContactDrawer: () => void
  closeCBByIdAddAddressDrawer: () => void
  closeCBByIdEditCompanyDrawer: () => void
  closeCBByIdEditAddressDrawer: () => void
  closeCBByIdAddContactDrawer: () => void
  closeCBByIdEditContactDrawer: () => void
  closeUserManagementEdit: () => void
  closeBookingAddCompany: () => void
  closeShipmentPartyAddCompany: () => void
  closeShipmentPartyAddAddress: () => void
  closeShipmentPartyAddContact: () => void
}

export const AccountDrawerComponent: FC<IShipmentDrawerComponentProps> = ({
  isVisible,
  activeContent,
  dynamicDrawerTitle,
  onCloseOrganization,
  onCloseAccountDepartmentEdit,
  onCloseOrganizationAddInternal,
  closeAddressesAndContactsAddAddressDrawer,
  closeAddressesAndContactsEditAddressDrawer,
  closeAACContactEditContactPersonDrawer,
  closeAACContactAddContactPersonDrawer,
  closeCBAddContactDrawer,
  closeCBByIdAddAddressDrawer,
  closeCBByIdEditCompanyDrawer,
  closeCBByIdEditAddressDrawer,
  closeCBByIdAddContactDrawer,
  closeCBByIdEditContactDrawer,
  closeUserManagementEdit,
  closeBookingAddCompany,
  closeShipmentPartyAddCompany,
  closeShipmentPartyAddAddress,
  closeShipmentPartyAddContact,
}) => {
  const title = activeContent
    ? (dynamicDrawerTitle || AccountDrawerContentNamesList[activeContent])
    : undefined;
  let width;
  let extra;

  switch (activeContent) {
    case EAccountDrawerContent.USER_EDIT_PROFILE_ORGANIZATION:
      width = '736px';
      break;
    case EAccountDrawerContent.USER_ADDRESSES_AND_CONTACTS_ADD_ADDRESS:
      width = '736px';
      break;
    case EAccountDrawerContent.USER_ADDRESSES_AND_CONTACTS_EDIT_ADDRESS:
      width = '736px';
      break;
    case EAccountDrawerContent.USER_ADDRESSES_AND_CONTACTS_SECOND:
      width = '736px';
      break;
    case EAccountDrawerContent.USER_AAC_CONTACT_ADD_CONTACT_PERSON:
      width = '378px';
      break;
    case EAccountDrawerContent.USER_AAC_CONTACT_EDIT_CONTACT_PERSON:
      width = '378px';
      break;
    case EAccountDrawerContent.USER_CUSTOMER_ADD_INTERNAL:
      width = '736px';
      break;
    case EAccountDrawerContent.CONTACT_BOOK_ADD_CONTACT:
      width = '736px';
      break;
    case EAccountDrawerContent.CONTACT_BOOK_BY_ID_ADD_ADDRESS:
      width = '736px';
      break;
    case EAccountDrawerContent.CONTACT_BOOK_BY_ID_EDIT_ADDRESS:
      width = '736px';
      break;
    case EAccountDrawerContent.BOOKING_ADD_COMPANY:
      width = '736px';
      break;
    case EAccountDrawerContent.SHIPMENT_PARTY_ADD_COMPANY:
      width = '736px';
      break;
    case EAccountDrawerContent.SHIPMENT_PARTY_ADD_ADDRESS:
      width = '736px';
      break;
    case EAccountDrawerContent.SHIPMENT_PARTY_ADD_CONTACT:
      width = '736px';
      break;
    default:
      width = '378px';
      break;
  }

  switch (activeContent) {
    case EAccountDrawerContent.USER_EDIT_PROFILE_ORGANIZATION:
      extra = <AccountDrawerCloset onClick={onCloseOrganization}><CloseSvg /></AccountDrawerCloset>;
      break;
    case EAccountDrawerContent.USER_ACCOUNT_DEPARTMENT_EDIT:
      extra = <AccountDrawerCloset onClick={onCloseAccountDepartmentEdit}><CloseSvg /></AccountDrawerCloset>;
      break;
    case EAccountDrawerContent.USER_CUSTOMER_ADD_INTERNAL:
      extra = <AccountDrawerCloset onClick={onCloseOrganizationAddInternal}><CloseSvg /></AccountDrawerCloset>;
      break;
    case EAccountDrawerContent.USER_ADDRESSES_AND_CONTACTS_ADD_ADDRESS:
      extra = <AccountDrawerCloset onClick={closeAddressesAndContactsAddAddressDrawer}><CloseSvg /></AccountDrawerCloset>;
      break;
    case EAccountDrawerContent.USER_ADDRESSES_AND_CONTACTS_EDIT_ADDRESS:
      extra = <AccountDrawerCloset onClick={closeAddressesAndContactsEditAddressDrawer}><CloseSvg /></AccountDrawerCloset>;
      break;
    case EAccountDrawerContent.USER_AAC_CONTACT_ADD_CONTACT_PERSON:
      extra = <AccountDrawerCloset onClick={closeAACContactAddContactPersonDrawer}><CloseSvg /></AccountDrawerCloset>;
      break;
    case EAccountDrawerContent.USER_AAC_CONTACT_EDIT_CONTACT_PERSON:
      extra = <AccountDrawerCloset onClick={closeAACContactEditContactPersonDrawer}><CloseSvg /></AccountDrawerCloset>;
      break;
    case EAccountDrawerContent.CONTACT_BOOK_ADD_CONTACT:
      extra = <AccountDrawerCloset onClick={closeCBAddContactDrawer}><CloseSvg /></AccountDrawerCloset>;
      break;
    case EAccountDrawerContent.CONTACT_BOOK_BY_ID_ADD_ADDRESS:
      extra = <AccountDrawerCloset onClick={closeCBByIdAddAddressDrawer}><CloseSvg /></AccountDrawerCloset>;
      break;
    case EAccountDrawerContent.CONTACT_BOOK_BY_ID_EDIT_COMPANY:
      extra = <AccountDrawerCloset onClick={closeCBByIdEditCompanyDrawer}><CloseSvg /></AccountDrawerCloset>;
      break;
    case EAccountDrawerContent.CONTACT_BOOK_BY_ID_EDIT_ADDRESS:
      extra = <AccountDrawerCloset onClick={closeCBByIdEditAddressDrawer}><CloseSvg /></AccountDrawerCloset>;
      break;
    case EAccountDrawerContent.CONTACT_BOOK_BY_ID_ADD_CONTACT:
      extra = <AccountDrawerCloset onClick={closeCBByIdAddContactDrawer}><CloseSvg /></AccountDrawerCloset>;
      break;
    case EAccountDrawerContent.CONTACT_BOOK_BY_ID_EDIT_CONTACT:
      extra = <AccountDrawerCloset onClick={closeCBByIdEditContactDrawer}><CloseSvg /></AccountDrawerCloset>;
      break;
    case EAccountDrawerContent.USER_MANAGEMENT_EDIT:
      extra = <AccountDrawerCloset onClick={closeUserManagementEdit}><CloseSvg /></AccountDrawerCloset>;
      break;
    case EAccountDrawerContent.BOOKING_ADD_COMPANY:
      extra = <AccountDrawerCloset onClick={closeBookingAddCompany}><CloseSvg /></AccountDrawerCloset>;
      break;
    case EAccountDrawerContent.SHIPMENT_PARTY_ADD_COMPANY:
      extra = <AccountDrawerCloset onClick={closeShipmentPartyAddCompany}><CloseSvg /></AccountDrawerCloset>;
      break;
    case EAccountDrawerContent.SHIPMENT_PARTY_ADD_ADDRESS:
      extra = <AccountDrawerCloset onClick={closeShipmentPartyAddAddress}><CloseSvg /></AccountDrawerCloset>;
      break;
    case EAccountDrawerContent.SHIPMENT_PARTY_ADD_CONTACT:
      extra = <AccountDrawerCloset onClick={closeShipmentPartyAddContact}><CloseSvg /></AccountDrawerCloset>;
      break;
    default:
      break;
  }

  return (
    <AccountDrawerWrapper
      title={title}
      width={width}
      visible={isVisible}
      closable={false}
      maskClosable={false}
      extra={extra}
    >
      {
        activeContent === EAccountDrawerContent.USER_EDIT_PROFILE && (
          <UserEditProfile />
        )
      }
      {
        activeContent === EAccountDrawerContent.USER_EDIT_PROFILE_ORGANIZATION && (
          <EditProfileOrganization />
        )
      }
      {
        activeContent === EAccountDrawerContent.USER_ACCOUNT_DEPARTMENT_EDIT && (
          <AccountDepartmentEditPage />
        )
      }
      {
        activeContent === EAccountDrawerContent.USER_INVITE && (
          <UserInvite />
        )
      }
      {
        activeContent === EAccountDrawerContent.USER_PASSWORD_CHANGE && (
          <ChangePassword />
        )
      }
      {
        activeContent === EAccountDrawerContent.USER_CUSTOMER_ADD_INTERNAL && (
          <CustomerAddInternalPage />
        )
      }
      {
        activeContent === EAccountDrawerContent.USER_ADDRESSES_AND_CONTACTS_ADD_ADDRESS && (
          <AddressesAndContactsPage />
        )
      }
      {
        activeContent === EAccountDrawerContent.USER_ADDRESSES_AND_CONTACTS_SECOND && (
          <AddressesAndContactsSecondPage />
        )
      }
      {
        activeContent === EAccountDrawerContent.USER_ADDRESSES_AND_CONTACTS_EDIT_ADDRESS && (
          <AddressesAndContactsEditAddressPage />
        )
      }
      {
        activeContent === EAccountDrawerContent.USER_AAC_CONTACT_ADD_CONTACT_PERSON && (
          <AACContactAddContactPersonPage />
        )
      }
      {
        activeContent === EAccountDrawerContent.USER_AAC_CONTACT_EDIT_CONTACT_PERSON && (
          <AACContactEditContactPersonPage />
        )
      }
      {
        activeContent === EAccountDrawerContent.CONTACT_BOOK_ADD_CONTACT && (
          <ContactBookAddContactPage />
        )
      }
      {
        activeContent === EAccountDrawerContent.CONTACT_BOOK_BY_ID_ADD_ADDRESS && (
          <ContactBookByIdAddAddressPage />
        )
      }
      {
        activeContent === EAccountDrawerContent.CONTACT_BOOK_BY_ID_EDIT_COMPANY && (
          <ContactBookByIdEditCompanyPage />
        )
      }
      {
        activeContent === EAccountDrawerContent.CONTACT_BOOK_BY_ID_EDIT_ADDRESS && (
          <ContactBookByIdEditAddressPage />
        )
      }
      {
        activeContent === EAccountDrawerContent.CONTACT_BOOK_BY_ID_ADD_CONTACT && (
          <ContactBookByIdAddContactPersonPage />
        )
      }
      {
        activeContent === EAccountDrawerContent.CONTACT_BOOK_BY_ID_EDIT_CONTACT && (
          <ContactBookByIdEditContactPersonPage />
        )
      }
      {
        activeContent === EAccountDrawerContent.USER_MANAGEMENT_EDIT && (
          <UserManagementEdit />
        )
      }
      {
        activeContent === EAccountDrawerContent.BOOKING_ADD_COMPANY && (
          <BookingAddCompanyPage />
        )
      }
      {
        activeContent === EAccountDrawerContent.SHIPMENT_PARTY_ADD_COMPANY && (
          <ShipmentPartyAddCompanyPage />
        )
      }
      {
        activeContent === EAccountDrawerContent.SHIPMENT_PARTY_ADD_ADDRESS && (
          <ShipmentPartyAddAddressPage />
        )
      }
      {
        activeContent === EAccountDrawerContent.SHIPMENT_PARTY_ADD_CONTACT && (
          <ShipmentPartyAddContactPage />
        )
      }
    </AccountDrawerWrapper>
  );
};
