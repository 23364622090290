import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

export const Wrapper = styled.div.attrs(({ 'data-class': 'PaymentTableWrapper' }))`
  max-width: 1260px;
  margin: 0 auto;
  background-color: white;
  width: 100%;
  padding: 16px;
`;

export const SkeletonWrapper = styled(Wrapper)`
  background-color: transparent;
  padding: 0;
`;

export const Title = styled.p`
  font-weight: 500;
  font-size: 16px;
  color: #202C3C;
  margin: 0 0 14px 0;
`;

export const StyledCell = styled.div`
  border: 1px solid #D1D7E2;
  border-radius: 2px;
  background: #F4F5F8;
  width: 100%;
  font-weight: 400;
  font-size: 12px;
  color: #73819B;
  padding: 6px 12px;
`;

export const BottomWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding-bottom: 20px;
  gap: 32px;
`;

export const UploadWrapper = styled.div`
  width: 50%;
`;

export const AmountWrapper = styled.div`
  width: 50%;
`;

export const AmountLine = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

export const AmountKey = styled.span`
  font-weight: 500;
  font-size: 14px;
  color: #202C3C;
`;

export const AmountValue = styled.span`
  font-weight: 400;
  font-size: 14px;
  color: #202C3C;
`;

export const NameOfDocument = styled(NavLink)`
  color: #4A55AD;
  cursor: pointer;
`;
