import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ShareInvoiceShipmentDetailsDTM } from 'shipment-operations/models/dtm';

import {
  Wrap,
  DropoffDetails,
  Title,
  Section,
  Item,
  ItemTitle,
  ItemText,
  Divider,
} from './PDFDropoffDetails.styled';

interface PDFDropoffDetailsComponentProps {
  details: Array<ShareInvoiceShipmentDetailsDTM | undefined>;
}

export const PDFDropoffDetailsComponent: FC<PDFDropoffDetailsComponentProps> = ({
  details,
}: PDFDropoffDetailsComponentProps) => {
  const { t } = useTranslation();
  const [
    emptyContainer,
    emptyRelease,
    fullContainer,
    latestDate,
  ] = details;

  return details.length ? (
    <Wrap>
      <DropoffDetails>
        <Title>
          {t('Pickup & Drop-off Details')}
        </Title>

        <Section>
          {emptyContainer ? (
            <Item>
              <ItemTitle>
                {emptyContainer.title}
              </ItemTitle>

              {emptyContainer.subtitles.map((subtitle) => (
                <ItemText key={`PDFDropoffDetailsComponent_${subtitle}`}>
                  {subtitle}
                </ItemText>
              ))}
            </Item>
          ) : null}

          {emptyRelease ? (
            <Item
              style={{
                paddingLeft: 8,
              }}
            >
              <ItemTitle>
                {emptyRelease.title}
              </ItemTitle>

              <ItemText>
                {emptyRelease.subtitles}
              </ItemText>
            </Item>
          ) : null}

          <Divider />

          {fullContainer ? (
            <Item>
              <ItemTitle>
                {fullContainer.title}
              </ItemTitle>

              {fullContainer.subtitles.map((subtitle) => (
                <ItemText key={`PDFDropoffDetailsComponent_${subtitle}`}>
                  {subtitle}
                </ItemText>
              ))}
            </Item>
          ) : null}

          {latestDate ? (
            <Item
              style={{
                paddingLeft: 8,
              }}
            >
              <ItemTitle>
                {latestDate.title}
              </ItemTitle>

              <ItemText>
                {latestDate.subtitles}
              </ItemText>
            </Item>
          ) : null}
        </Section>
      </DropoffDetails>
    </Wrap>
  ) : null;
};
