import { IsEnum, IsOptional } from 'class-validator';
import { EPermissionAttributePolicy } from 'monetary/constants';
import { BaseDTM } from 'proto/BaseDTM';

import { PermissionAttributePolicy } from 'shipment-operations/constants';

export interface IContractsPermissionsDTM {
  contractsPermission?: EPermissionAttributePolicy;
}

export class ContractsPermissionsDTM extends BaseDTM<IContractsPermissionsDTM> {
  @IsOptional()
  @IsEnum(PermissionAttributePolicy)
  contractsPermission?: PermissionAttributePolicy;
}
