import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Alert } from './NotAvailable.styled';

export const NotAvailable: FC = () => {
  const { t } = useTranslation();

  return (
    <Alert
      message={t('Information will be available once Ocean Booking is confirmed')}
      type="info"
      showIcon
    />
  );
};
