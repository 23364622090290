import React, { useCallback } from 'react';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';

import { RouteNames } from 'app-wrapper/constants';

import {
  Wrap,
  Image,
  Content,
  Title,
  Text,
  QuoteButton,
  PlusIcon,
} from './Schedule.styled';
import calendarSrc from './assets/calendar.png';

export const Schedule = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const goToRFQ = useCallback(() => {
    navigate(RouteNames.FREIGHT_QUOTE());
  }, [navigate]);

  return (
    <Wrap>
      <Image src={calendarSrc} />

      <Content>
        <Title>
          {t('Schedule Smart & Quote Instantly')}
        </Title>

        <Text>
          {t('Use our live schedules to access instant quotes')}
        </Text>
      </Content>

      <QuoteButton
        onClick={goToRFQ}
        icon={<PlusIcon />}
      >
        {t('New Quote')}
      </QuoteButton>
    </Wrap>
  );
};
