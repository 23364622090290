import React from 'react';

import { useAppSelector } from 'app-wrapper/hooks';
import { R } from 'shipment-operations/repository';

import { TotalComponent } from 'shipment-operations/view/components/ControlChargeTransportation';
import { ShipmentDrawerContent } from 'shipment-operations/constants';

const TotalContainer = () => {
  const totalAR = useAppSelector(R.selectors.shipmentActiveCharge.getActiveChargeTotalAR);
  const totalAP = useAppSelector(R.selectors.shipmentActiveCharge.getActiveChargeTotalAP);
  const arBudget = useAppSelector(R.selectors.shipmentActiveCharge.getARBudgetEdit);
  const apBudget = useAppSelector(R.selectors.shipmentActiveCharge.getAPBudgetEdit);
  const balanceAP = useAppSelector(R.selectors.shipmentActiveCharge.getAPBalanceEdit);
  const balanceAR = useAppSelector(R.selectors.shipmentActiveCharge.getARBalanceEdit);
  const profit = useAppSelector(R.selectors.shipmentActiveCharge.getActiveChargeProfit);

  const mode = useAppSelector(R.selectors.shipmentDrawer.getContentType);

  return (
    <TotalComponent
      totalAR={totalAR}
      totalAP={totalAP}
      budgetAP={mode === ShipmentDrawerContent.SHIPMENT_TRANSPORTATION_ADD_CHARGE ? totalAP : apBudget}
      budgetAR={mode === ShipmentDrawerContent.SHIPMENT_TRANSPORTATION_ADD_CHARGE ? totalAR : arBudget}
      balanceAP={mode === ShipmentDrawerContent.SHIPMENT_TRANSPORTATION_ADD_CHARGE ? 0 : balanceAP}
      balanceAR={mode === ShipmentDrawerContent.SHIPMENT_TRANSPORTATION_ADD_CHARGE ? 0 : balanceAR}
      profit={profit}
    />
  );
};

export { TotalContainer as Total };
