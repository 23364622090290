import { BaseController, controller } from 'proto/BaseController';

import { EAccountDepartmentType } from 'user-management/constants';
import { UC } from 'app-wrapper/controllers';
import { OrganizationDTM } from 'user-management/models/dtm';
import { R as userManagementR } from 'user-management/repository';

@controller
export class SalesRepresentativeController extends BaseController {
  public openSalesRepresentativeDrawer = async () => {
    UC.drawer.openSalesRepresentativeDrawer();
    this.mobxStore.customerSales.setIsSalesDrawerLoading(true);

    const currentOrg = userManagementR.selectors.userOrganizationData.getUserOrganization(this.store.getState());

    if (!currentOrg) {
      return;
    }

    const members = await userManagementR.services.organization.getOrganizationMembersList(currentOrg.id, {
      departments: [EAccountDepartmentType.SALES],
    });

    const customerOrg = userManagementR.selectors.customer.getOrganization(this.store.getState());

    this.mobxStore.customerSales.setTempSaleRepresentativeEmail(customerOrg?.salesManager?.email ? customerOrg.salesManager.email : '');

    this.mobxStore.customerSales.setSalesDepartmentMembers(members);
    this.mobxStore.customerSales.setIsSalesDrawerLoading(false);
  }

  public setSalesRepresentativeEmail = (email?: string) => {
    this.mobxStore.customerSales.setTempSaleRepresentativeEmail(email);
  }

  public submitSalesRepresentativeDrawer = async () => {
    const {
      salesDepartmentMembers,
      tempSaleRepresentativeEmail,
    } = this.mobxStore.customerSales.state;
    const customerOrg = userManagementR.selectors.customer.getOrganization(this.store.getState());
    const memberToSet = salesDepartmentMembers.find(({ email }) => email === tempSaleRepresentativeEmail);

    if (!customerOrg || !memberToSet) {
      return;
    }

    this.mobxStore.customerSales.setIsSalesDrawerLoading(true);

    await userManagementR.services.organization.putCurrentOrganization(OrganizationDTM.fromPlain({
      ...customerOrg,
      salesManager: memberToSet,
    }));

    const updatedCustomerOrg = await userManagementR.services.organization.getOrganizationById(customerOrg.id);

    this.dispatch(userManagementR.actions.customer.setOrganization(updatedCustomerOrg));

    UC.drawer.closeDrawer();
    this.mobxStore.customerSales.setIsSalesDrawerLoading(true);
  }
}
