import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';

import { DescriptionInputComponent } from './DescriptionInput.component';

interface DescriptionInputContainerProps {
  cargoId: number;
}

const DescriptionInputContainer: FC<DescriptionInputContainerProps> = ({ cargoId }) => {
  const { description = '' } = useSelector(R.selectors.manualBookingWizard.getCargoById(cargoId));

  return (
    <DescriptionInputComponent description={description} />
  );
};

export { DescriptionInputContainer as DescriptionInput };
