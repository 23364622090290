import React, { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate, useLocation } from 'react-router';
import Tabs from 'antd/es/tabs';
import PlusOutlined from '@ant-design/icons/PlusOutlined';

import { RouteNames } from 'app-wrapper/constants';

import { ShipmentChargesPermissionsDtm, TransportationPermissionsDtm } from 'shipment-operations/models/dtm';
import { PermissionAttributePolicy } from 'shipment-operations/constants';
import { hasReadAccess } from 'app-wrapper/utils';
import { ShipmentTabs } from 'shipment-operations/view/components/ShipmentTabs';
import { StyledButton } from './ShipmentTransportationTabs.styled';

interface IShipmentTransportationTabsComponentProps {
  openAddCharge?: () => void
  openAddAccessorials?: () => void
  permissions: ShipmentChargesPermissionsDtm
  transportationPermissions: TransportationPermissionsDtm
}

export const ShipmentTransportationTabsComponent: FC<IShipmentTransportationTabsComponentProps> = ({
  openAddCharge, openAddAccessorials, permissions, transportationPermissions,
}) => {
  const { t } = useTranslation();
  const { shipmentId = '0' } = useParams();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const onChangeTab = useCallback((tab) => {
    navigate(tab, { replace: true });
  }, []);

  const extraContent = useMemo(() => {
    if (pathname === RouteNames.SHIPMENT_TRANSPORTATION_CHARGES(shipmentId) && (
      permissions.shipmentChargesAvailability === PermissionAttributePolicy.WRITE
    )) {
      return (
        <StyledButton onClick={openAddCharge} icon={<PlusOutlined />}>{t('addCharge')}</StyledButton>
      );
    }

    if (pathname === RouteNames.SHIPMENT_TRANSPORTATION_ACCESSORIALS(shipmentId) && (
      permissions.shipmentChargesAvailability === PermissionAttributePolicy.WRITE
    )) {
      return (
        <StyledButton onClick={openAddAccessorials} icon={<PlusOutlined />}>{t('addCharge')}</StyledButton>
      );
    }

    return undefined;
  }, [pathname, shipmentId, permissions]);

  return (
    <ShipmentTabs
      activeKey={pathname}
      onChange={onChangeTab}
      tabBarExtraContent={extraContent}
    >
      {hasReadAccess(transportationPermissions.overviewTab) && <Tabs.TabPane tab={t('Overview')} key={RouteNames.SHIPMENT_TRANSPORTATION_OVERVIEW(shipmentId)} /> }
      {hasReadAccess(transportationPermissions.trackerTab) && <Tabs.TabPane tab={t('Tracker')} key={RouteNames.SHIPMENT_TRANSPORTATION_TRACKER(shipmentId)} />}
      {hasReadAccess(transportationPermissions.chargesTab) && permissions.shipmentChargesAvailability === PermissionAttributePolicy.WRITE && (
        <Tabs.TabPane tab={t('Charges')} key={RouteNames.SHIPMENT_TRANSPORTATION_CHARGES(shipmentId)} />
      )}
      {hasReadAccess(transportationPermissions.accessorialsTab) && <Tabs.TabPane tab={t('accessorials')} key={RouteNames.SHIPMENT_TRANSPORTATION_ACCESSORIALS(shipmentId)} />}
      {hasReadAccess(transportationPermissions.freeTimeTab) && <Tabs.TabPane tab={t('freeTime')} key={RouteNames.SHIPMENT_TRANSPORTATION_FREE_TIME(shipmentId)} />}
    </ShipmentTabs>
  );
};
