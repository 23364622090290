import styled from 'styled-components';

export const RFQResponseDiv = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  position: absolute;
  height: 100%;
  right: 0;
  margin: 0 auto;

  .ant-tooltip-inner {
    background-color: ${({ theme }) => theme.themesColors.neutralBranding9};
    font-size: 12px;
    font-weight: 400;
  }
`;
