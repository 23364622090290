import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Spin from 'antd/es/spin';

import { OrganizationMemberDTM, TeamMemberDTM } from 'user-management/models/dtm';
import { TeamView } from 'user-management/view/components';
import { TeamMemberCard, SubPageContent } from 'app-wrapper/view/guideline';
import { Content } from 'user-management/view/pages/TeamPage/components/TeamMembers/TeamMembers.styled';
import { SpinnerWrap } from 'user-management/view/components/CreateOrEditTeamDrawer/CreateOrEditTeamDrawer.styled';

import {
  Page,
  Title,
} from './CustomerTeamView.styled';

interface ICustomerTeamViewComponentProps {
  isLoading: boolean;
  init: () => void;
  teamMembers: TeamMemberDTM[];
  accountManager?: OrganizationMemberDTM;
}

export const CustomerTeamViewComponent: FC<ICustomerTeamViewComponentProps> = ({
  isLoading,
  teamMembers,
  accountManager,
  init,
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    init();
  }, []);

  return (
    <Page>
      {isLoading ? (
        <SpinnerWrap>
          <Spin size="large" />
        </SpinnerWrap>
      ) : null}

      <Title>
        {t('Skypace Team')}
      </Title>

      <SubPageContent>
        <TeamView accountManager={accountManager} />

        <Content>
          {teamMembers.map((member) => (
            <TeamMemberCard key={member.id} teamMember={member} />
          ))}
        </Content>
      </SubPageContent>
    </Page>
  );
};
