import styled from 'styled-components';

import { Wrapper, SubPageContent } from 'app-wrapper/view/guideline';

export const Content = styled(Wrapper)`
  padding: 0 0 80px 0;
  display: flex;
  flex-direction: column;
  gap: 8px;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const InnerContent = styled(SubPageContent)`
  padding: 0;
`;
