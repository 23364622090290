import styled from 'styled-components';
import Table from 'antd/es/table';
import Menu from 'antd/es/menu';
import { MoreOutlined } from '@ant-design/icons';

import { ExpandIcon } from 'app-wrapper/view/components/ExpandIcon';

import {
  StyledTable,
} from 'shipment-operations/view/components/CustomTable/CustomTable.styled';

interface ITableProps {
  isOpen?: boolean,
  active?: boolean,
}

export const CustomHeader = styled(Table)`
  .ant-table-tbody {
    display: none;
  }
  .ant-table-thead > tr > th {
    background: white;
    border-bottom: none;
  }
  .ant-table-thead > tr:last-child > th:last-child {
    padding-right: 0;
  }
  .ant-table-container table > thead > tr:first-child th:last-child,
  .ant-table-tbody .ant-table-cell:last-child {
    padding-right: 0;
  }
  .ant-table-thead .ant-table-cell {
    padding-top: 8px;
    padding-bottom: 8px;
    border-bottom: 1px solid #EAEEF8;
    border-top: 1px solid #EAEEF8;
  }
  .ant-table-thead .ant-table-cell {
    color: #73819B;
    font-size: 12px;
    font-weight: 400;
  }
  .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    display: none;
  }
  .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan]):nth-child(2)::before,
  .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan]):nth-child(5)::before,
  .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan]):nth-child(8)::before {
    background-color: #EBEEF7;
    display: block;
  }
  .ant-table-thead > tr:first-child > th {
    border-top: none !important;
  }
  .ant-table-container table > thead > tr:first-child th:first-child {
    padding-left: 0;
  }
  .ant-table-container table > thead > tr:last-child th:first-child {
    padding-left: 0;
  }
`;

export const StyledExpandIcon = styled(ExpandIcon)`
  margin-top: 5px;
  margin-right: 6px;
`;

export const StyledTypeTitle = styled.div`
  margin-left: 15px;
`;

export const StyledType = styled.div<ITableProps>`
  text-decoration: ${({ active }) => !active && 'line-through'};
  cursor: pointer;
  display: flex;
  align-items: center;
  text-transform: capitalize;
  color: ${({ isOpen, active }) => {
    if (!active) {
      return '#D1D7E2';
    }
    if (isOpen) {
      return '#6C79DE';
    }
    return null;
  }}
`;

export const StyledBodyCell = styled.div<ITableProps>`
  text-decoration: ${({ active }) => !active && 'line-through'};
  color: ${({ isOpen, active }) => {
    if (!active) {
      return '#D1D7E2';
    }
    if (isOpen) {
      return '#73819B';
    }
    return null;
  }}
`;

export const ExpandTable = styled(StyledTable)`
  .ant-table-expanded-row .ant-table-cell {
    background: white;
  }

  .ant-table-tbody > .ant-table-cell {
    padding-top: 16px;
    padding-bottom: 16px;
  }

  .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan]):nth-child(2)::before,
  .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan]):nth-child(8)::before,
  .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan]):nth-child(5)::before {
    background-color: #EBEEF7;
    display: block;
  }

  .ant-table-tbody tr .ant-table-cell:nth-child(2)::before,
  .ant-table-tbody tr .ant-table-cell:nth-child(8)::before,
  .ant-table-tbody tr .ant-table-cell:nth-child(5)::before {
    position: absolute;
    top: 5%;
    bottom: 5%;
    right: 0;
    width: 1px;
    background-color: #EBEEF7;
    transition: background-color 0.3s;
    content: '';
  }

  .customExpandRow {
    top: -1px;
    position: relative;
  }

  .customExpandRow > .ant-table-cell {
    border-top: 1px dashed #EAEEF8;
  }
`;

export const StyledMoreIcon = styled(MoreOutlined)`
  cursor: pointer;
  margin-right: -5px;
`;

export const StyledMenuItem = styled(Menu.Item)`
  min-width: 140px;
`;

export const StyledRemove = styled(StyledMenuItem)`
  color: #FF4D4F;
`;

export const DropDownWrapper = styled.div<ITableProps>`
  display: inline-block;
  visibility: ${({ active }) => (active ? 'visible' : 'hidden')};
`;

export const FirstHeaderWrapper = styled.span`
  color: #202C3C;
  font-weight: 500;
  font-size: 16px;
  text-align: left;
  line-height: 22px;
  &:before {
    position: absolute;
    top: 20%;
    bottom: 20%;
    right: 0;
    width: 1px;
    background-color: #EBEEF7;
    transition: background-color 0.3s;
    content: '';
  }
`;

export const SubHeader = styled.span`
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  color: #202C3C;
  &:before {
    position: absolute;
    top: 20%;
    bottom: 20%;
    right: 0;
    width: 1px;
    background-color: #EBEEF7;
    transition: background-color 0.3s;
    content: '';
  }
`;
