import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'antd/es/skeleton';
import Typography from 'antd/es/typography';

import { ShippingPartyOverviewDTM } from 'shipment-operations/models/dtm';
import { EShippingPartyTypes, ShippingPartyNames } from 'shipment-operations/constants';
import { ShippingPartyCard } from 'shipment-operations/view/components';

import { ShippingPartiesCardContainer } from './ShippingPartiesCard.styled';

interface IShippingPartiesCardComponentProps {
  isLoading: boolean
  shipper: ShippingPartyOverviewDTM
  consignee: ShippingPartyOverviewDTM
  notifyParty: ShippingPartyOverviewDTM
}

export const ShippingPartiesCardComponent: FC<IShippingPartiesCardComponentProps> = ({
  isLoading,
  shipper,
  consignee,
  notifyParty,
}) => {
  const { t } = useTranslation();

  if (isLoading) {
    return (
      <ShippingPartiesCardContainer>
        <Typography.Title level={5}>
          {t('Shipping Parties')}
        </Typography.Title>
        <Skeleton />
      </ShippingPartiesCardContainer>
    );
  }

  return (
    <ShippingPartiesCardContainer>
      <Typography.Title level={5}>
        {t('Shipping Parties')}
      </Typography.Title>
      <ShippingPartyCard
        defaultActive
        selectionKey={EShippingPartyTypes.SHIPPER}
        header={t(ShippingPartyNames[EShippingPartyTypes.SHIPPER])}
        references={shipper.references}
        companyName={shipper.companyName}
        addressLine={shipper.addressLine}
        contactPerson={shipper.contactPerson}
        email={shipper.email}
        phone={shipper.phone}
        additionalPhone={shipper.additionalPhone}
        usciNumber={shipper.usciNumber}
        taxId={shipper.taxId}
      />
      <ShippingPartyCard
        selectionKey={EShippingPartyTypes.CONSIGNEE}
        header={t(ShippingPartyNames[EShippingPartyTypes.CONSIGNEE])}
        references={consignee.references}
        companyName={consignee.companyName}
        addressLine={consignee.addressLine}
        contactPerson={consignee.contactPerson}
        email={consignee.email}
        phone={consignee.phone}
        additionalPhone={consignee.additionalPhone}
        usciNumber={consignee.usciNumber}
        taxId={consignee.taxId}
      />
      <ShippingPartyCard
        selectionKey={EShippingPartyTypes.NOTIFY_PARTY}
        header={t(ShippingPartyNames[EShippingPartyTypes.NOTIFY_PARTY])}
        references={notifyParty.references}
        companyName={notifyParty.companyName}
        addressLine={notifyParty.addressLine}
        contactPerson={notifyParty.contactPerson}
        email={notifyParty.email}
        phone={notifyParty.phone}
        additionalPhone={notifyParty.additionalPhone}
        usciNumber={notifyParty.usciNumber}
        taxId={notifyParty.taxId}
      />
    </ShippingPartiesCardContainer>
  );
};
