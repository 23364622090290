import i18n from 'i18next';

import { ECarrierSCAC } from './CarrierSCAC.enum';

export const CarrierSCACNames = {
  [ECarrierSCAC.APLU]: i18n.t('American President Line'),
  [ECarrierSCAC.CMDU]: i18n.t('CMA CGM'),
  [ECarrierSCAC.COSU]: i18n.t('China Ocean Shipping Group'),
  [ECarrierSCAC.EGLV]: i18n.t('Evergreen'),
  [ECarrierSCAC.HLCU]: i18n.t('Hapag Lloyd'),
  [ECarrierSCAC.SUDU]: i18n.t('Hamburg Süd'),
  [ECarrierSCAC.HDMU]: i18n.t('Hyundai Shipping'),
  [ECarrierSCAC.MAEU]: i18n.t('Maersk Line'),
  [ECarrierSCAC.MSCU]: i18n.t('Mediterranean Shipping Company'),
  [ECarrierSCAC.OOLU]: i18n.t('ORIENT OVERSEAS CONTAINER LINE'),
  [ECarrierSCAC.WHLU]: i18n.t('Wanhai Lines'),
  [ECarrierSCAC.YMLU]: i18n.t('Yang Ming Lines'),
  [ECarrierSCAC.ZIMU]: i18n.t('Zim Lines'),
  [ECarrierSCAC.MCSM]: i18n.t('Maritime Carrier Shipping'),
  [ECarrierSCAC.PCIU]: i18n.t('Pacific International Lines'),
  [ECarrierSCAC.ESPU]: i18n.t('Emirates'),
  [ECarrierSCAC.ANNU]: i18n.t('ANL Container Line Pty Ltd'),
  [ECarrierSCAC.MATS]: i18n.t('Matson'),
  [ECarrierSCAC.SMLM]: i18n.t('SM Line'),
  [ECarrierSCAC.ACLU]: i18n.t('ACL Container Line'),
  [ECarrierSCAC.ONEY]: i18n.t('Ocean Network Express'),
  [ECarrierSCAC.IILU]: i18n.t('Independent Container Line'),
  [ECarrierSCAC.SMLU]: i18n.t('Seaboard Marine'),
  [ECarrierSCAC.WWSU]: i18n.t('Westwood Shipping Lines'),
  [ECarrierSCAC['11DX']]: i18n.t('CHENG LIE Navigation Company'),
  [ECarrierSCAC.ECUW]: i18n.t('ECU Worldwide'),
};

export const CarrierSCACNamesRFQ: Record<ECarrierSCAC, string> = {
  [ECarrierSCAC.ACLU]: i18n.t('ACL'),
  [ECarrierSCAC.COSU]: i18n.t('Cosco'),
  [ECarrierSCAC.SUDU]: i18n.t('Hamburg SUD'),
  [ECarrierSCAC.EGLV]: i18n.t('Evergreen'),
  [ECarrierSCAC.HLCU]: i18n.t('Hapag Lloyd'),
  [ECarrierSCAC.HDMU]: i18n.t('HMM'),
  [ECarrierSCAC.MAEU]: i18n.t('Maersk'),
  [ECarrierSCAC.MSCU]: i18n.t('MSC'),
  [ECarrierSCAC.ONEY]: i18n.t('ONE Line'),
  [ECarrierSCAC.ZIMU]: i18n.t('ZIM'),
  [ECarrierSCAC.YMLU]: i18n.t('YML'),
  [ECarrierSCAC.CMDU]: i18n.t('CMA CGM'),
  [ECarrierSCAC.ECUW]: i18n.t('ECU Worldwide'),
  [ECarrierSCAC.OOLU]: i18n.t('OOCL'),
  [ECarrierSCAC.WHLU]: i18n.t('Wanhai Lines'),
  [ECarrierSCAC.MCSM]: i18n.t('Maritime Carrier Shipping'),
  [ECarrierSCAC.PCIU]: i18n.t('Pacific International Lines'),
  [ECarrierSCAC.ESPU]: i18n.t('Emirates'),
  [ECarrierSCAC.ANNU]: i18n.t('ANL Container Line Pty Ltd'),
  [ECarrierSCAC.MATS]: i18n.t('Matson'),
  [ECarrierSCAC.SMLM]: i18n.t('SM Line'),
  [ECarrierSCAC.IILU]: i18n.t('Independent Container Line'),
  [ECarrierSCAC.SMLU]: i18n.t('Seaboard Marine'),
  [ECarrierSCAC.WWSU]: i18n.t('Westwood Shipping Lines'),
  [ECarrierSCAC.APLU]: i18n.t('American President Line'),
  [ECarrierSCAC['11DX']]: i18n.t('CHENG LIE Navigation Company'),
};

export const getCarrierSCACNamesRFQ = (value: string) => {
  switch (value) {
    case ECarrierSCAC.ACLU:
      return CarrierSCACNamesRFQ[ECarrierSCAC.ACLU];
    case ECarrierSCAC.COSU:
      return CarrierSCACNamesRFQ[ECarrierSCAC.COSU];
    case ECarrierSCAC.SUDU:
      return CarrierSCACNamesRFQ[ECarrierSCAC.SUDU];
    case ECarrierSCAC.EGLV:
      return CarrierSCACNamesRFQ[ECarrierSCAC.EGLV];
    case ECarrierSCAC.HLCU:
      return CarrierSCACNamesRFQ[ECarrierSCAC.HLCU];
    case ECarrierSCAC.HDMU:
      return CarrierSCACNamesRFQ[ECarrierSCAC.HDMU];
    case ECarrierSCAC.MAEU:
      return CarrierSCACNamesRFQ[ECarrierSCAC.MAEU];
    case ECarrierSCAC.MSCU:
      return CarrierSCACNamesRFQ[ECarrierSCAC.MSCU];
    case ECarrierSCAC.ONEY:
      return CarrierSCACNamesRFQ[ECarrierSCAC.ONEY];
    case ECarrierSCAC.ZIMU:
      return CarrierSCACNamesRFQ[ECarrierSCAC.ZIMU];
    case ECarrierSCAC.YMLU:
      return CarrierSCACNamesRFQ[ECarrierSCAC.YMLU];
    case ECarrierSCAC.CMDU:
      return CarrierSCACNamesRFQ[ECarrierSCAC.CMDU];
    case ECarrierSCAC.ECUW:
      return CarrierSCACNamesRFQ[ECarrierSCAC.ECUW];
    case ECarrierSCAC.OOLU:
      return CarrierSCACNamesRFQ[ECarrierSCAC.OOLU];
    case ECarrierSCAC.WHLU:
      return CarrierSCACNamesRFQ[ECarrierSCAC.WHLU];
    case ECarrierSCAC.MCSM:
      return CarrierSCACNamesRFQ[ECarrierSCAC.MCSM];
    case ECarrierSCAC.PCIU:
      return CarrierSCACNamesRFQ[ECarrierSCAC.PCIU];
    case ECarrierSCAC.ESPU:
      return CarrierSCACNamesRFQ[ECarrierSCAC.ESPU];
    case ECarrierSCAC.ANNU:
      return CarrierSCACNamesRFQ[ECarrierSCAC.ANNU];
    case ECarrierSCAC.MATS:
      return CarrierSCACNamesRFQ[ECarrierSCAC.MATS];
    case ECarrierSCAC.SMLM:
      return CarrierSCACNamesRFQ[ECarrierSCAC.SMLM];
    case ECarrierSCAC.WWSU:
      return CarrierSCACNamesRFQ[ECarrierSCAC.WWSU];
    default:
      return '';
  }
};

export const MaerskCodes = ['MAEU', 'SEAU'];
