import React, { forwardRef } from 'react';
import { ChangeHandler, FieldErrors } from 'react-hook-form';
import omit from 'lodash/fp/omit';

import { Checkbox, Errors } from 'app-wrapper/view/components';

import { CheckboxFieldWrapper, CheckboxFieldText } from './CheckboxField.styled';

// TODO: fix types
interface ICheckboxFieldProps extends React.InputHTMLAttributes<HTMLInputElement> {
  text: string | React.ReactNode;
  errors: FieldErrors;
  onChange?: ChangeHandler;
  inputName?: string;
}

export const CheckboxField = forwardRef((
  props: ICheckboxFieldProps,
  ref,
) => {
  const inputProps = omit(['inputName', 'errors', 'text'], props);

  return (
    <>
      <CheckboxFieldWrapper>
        <Checkbox
          ref={ref as React.RefObject<HTMLInputElement>}
          {...inputProps}
        />
        <CheckboxFieldText>
          {props.text}
        </CheckboxFieldText>
      </CheckboxFieldWrapper>
      <Errors name={props.inputName || props.name as string} errors={props.errors} />
    </>
  );
});
