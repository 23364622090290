import React, {
  FC, useCallback, useEffect, useMemo,
} from 'react';
import Alert from 'antd/es/alert';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import Skeleton from 'antd/es/skeleton';

import { CREATE_AP_INVOICE } from 'shipment-operations/constants';

import { Container as ModalContainer, CustomModal, InnerContainer } from 'shipment-operations/view/components';
import { StyledButton } from 'shipment-operations/view/pages/ShipmentCharges/ShipmentCharges.styled';
import {
  Header as HeaderContainer, Transportation, Services, Fees, Additional, StickyTotal, Footer,
} from './components';

interface ICreateInvoiceProps {
  loadData: (shipmentId?: string) => void
  sum: number
  closeModal: () => void
  errorCompanies: boolean
  errorCharges: boolean
  isLoading: boolean
  onClose: () => void
  resetSuccess: () => void
  isEmptyData: boolean
  modalOwner: string
}

const CreateInvoiceComponent: FC<ICreateInvoiceProps> = ({
  errorCharges, errorCompanies, loadData, sum, closeModal, isLoading, onClose, resetSuccess, isEmptyData,
  modalOwner,
}) => {
  const { t } = useTranslation();
  const { shipmentId } = useParams<'shipmentId'>();

  const reloadData = useCallback(() => {
    loadData(shipmentId);
  }, [shipmentId, loadData]);

  useEffect(() => {
    loadData(shipmentId);
  }, [loadData, shipmentId]);

  useEffect(() => () => onClose(), []);

  useEffect(() => resetSuccess(), []);

  const shouldRenderError = useMemo(() => !isLoading && (errorCharges || errorCompanies), [isLoading, errorCompanies, errorCharges]);

  if (!isLoading && isEmptyData) {
    return (
      <CustomModal onClose={closeModal} title={t('createInvoice')}>
        <Alert
          message={t('createInvoiceWindow.noChargesTitle')}
          type="warning"
        />
      </CustomModal>
    );
  }

  return (
    <CustomModal onClose={closeModal} title={t(modalOwner === CREATE_AP_INVOICE ? 'createAPInvoice' : 'createInvoice')}>
      {shouldRenderError ? (
        <Alert
          message={t('createInvoiceWindow.errorTitle')}
          description={t('createInvoiceWindow.errorSubTitle')}
          type="error"
          action={(
            <StyledButton size="middle" type="primary" onClick={reloadData}>
              {t('reload')}
            </StyledButton>
          )}
        />
      ) : (
        <>
          <HeaderContainer isLoading={isLoading} />
          <ModalContainer mBottom="50px">
            {isLoading ? (
              <InnerContainer>
                <Skeleton active paragraph={{ rows: 10 }} />
              </InnerContainer>
            ) : (
              <>
                <Transportation />
                <Services />
                <Fees />
                <Additional />
                <Footer sum={sum} />
                {!!sum && (
                  <StickyTotal />
                )}
              </>
            )}
          </ModalContainer>
        </>
      )}
    </CustomModal>
  );
};

export { CreateInvoiceComponent };
