import React, { memo, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { R } from 'user-management/repository';
import { UC } from 'user-management/controllers';

import {
  EditProfileOrganizationCoverageAreaComponent,
} from 'user-management/view/pages/Account/components/AccountDrawer/components/EditProfileOrganization/components/EditProfileOrganizationCoverageArea/EditProfileOrganizationCoverageArea.component';
import { COUNTRIES_SELECT_OPTIONS } from 'app-wrapper/repository/store/CountriesList/countries';
import { hasAccess } from 'app-wrapper/utils';
import { PermissionAttributePolicy } from 'shipment-operations/constants';

interface EditProfileOrganizationCoverageAreaContainerProps {
  permission?: PermissionAttributePolicy
}

export const EditProfileOrganizationCoverageAreaContainer = memo(({ permission }: EditProfileOrganizationCoverageAreaContainerProps) => {
  const organizationUpdateCoverageAreas = useSelector(R.selectors.userOrganizationData.getUserOrganizationUpdateCoverageAreas);
  const organizationFieldsErrors = useSelector(R.selectors.userOrganizationData.organizationFieldsErrors);

  const values = useMemo(() => (organizationUpdateCoverageAreas || []).map((area) => area.countryCode), [organizationUpdateCoverageAreas]);

  const disabled = !hasAccess(permission, PermissionAttributePolicy.WRITE);

  const hasError = !!(organizationFieldsErrors?.coverageAreas?.isVisited && organizationFieldsErrors?.coverageAreas.message);

  return (
    <EditProfileOrganizationCoverageAreaComponent
      dataClass="SelectWithErrorOrganizationCoverageArea"
      value={values}
      options={COUNTRIES_SELECT_OPTIONS}
      onChange={UC.organizationProfile.onChangeUpdateCoverageArea}
      onBlur={UC.organizationProfile.onBlurUpdateCoverageArea}
      onClear={UC.organizationProfile.onClearUpdateCoverageArea}
      hasError={hasError}
      disabled={disabled}
    />
  );
});
