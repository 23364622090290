import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  margin-bottom: -15px;
`;

export const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 15px;
`;

export const Title = styled.h2`
  font-size: 16px;
  line-height: 24px;
  color: #000000D9;
  font-weight: 500;
`;

export const Description = styled.p`
  font-size: 14px;
  line-height: 22px;
  color: #202C3C;
  font-weight: 400;
`;
