import i18n from 'i18next';

import { EShippingPartyTypes } from './ShippingParty.enum';

export const ShippingPartyNames = {
  [EShippingPartyTypes.CONSIGNEE]: i18n.t('Consignee'),
  [EShippingPartyTypes.CUSTOMER]: i18n.t('Customer'),
  [EShippingPartyTypes.EXPORT_BROKER]: i18n.t('Export Broker'),
  [EShippingPartyTypes.FORWARDER_AGENT]: i18n.t('Forwarding Agent'),
  [EShippingPartyTypes.HOUSE_CONSIGNEE]: i18n.t('House Consignee'),
  [EShippingPartyTypes.HOUSE_NOTIFY_PARTY]: i18n.t('House Notify Party'),
  [EShippingPartyTypes.HOUSE_SHIPPER]: i18n.t('House Shipper'),
  [EShippingPartyTypes.IMPORT_BROKER]: i18n.t('Import Broker'),
  [EShippingPartyTypes.NOTIFY_PARTY]: i18n.t('Notify Party'),
  [EShippingPartyTypes.SECOND_NOTIFY_PARTY]: i18n.t('2nd Notify Party'),
  [EShippingPartyTypes.SHIPPER]: i18n.t('Shipper'),
  [EShippingPartyTypes.FORWARDER_AGENT]: i18n.t('Forwarder Agent'),
  [EShippingPartyTypes.DELIVERY_AGENT]: i18n.t('Delivery Agent'),
  [EShippingPartyTypes.ACCOUNT_HOLDER]: i18n.t('Account Holder'),
  [EShippingPartyTypes.ULTIMATE_CUSTOMER]: i18n.t('Ultimate Customer'),
  [EShippingPartyTypes.OCEAN_CARRIER]: i18n.t('Ocean Carrier'),
  [EShippingPartyTypes.BOOKING_AGENT]: i18n.t('Booking Agent'),
  [EShippingPartyTypes.ORIGIN_PARTNER_AGENT]: i18n.t('Origin Partner Agent'),
  [EShippingPartyTypes.DESTINATION_PARTNER_AGENT]: i18n.t('Destination Partner Agent'),
};

export const SHIPPING_PARTIES_PAGE_NAMES = {
  ...ShippingPartyNames,
  [EShippingPartyTypes.HOUSE_CONSIGNEE]: i18n.t('Consignee'),
  [EShippingPartyTypes.HOUSE_NOTIFY_PARTY]: i18n.t('Notify Party'),
  [EShippingPartyTypes.HOUSE_SHIPPER]: i18n.t('Shipper'),
};
