import styled from 'styled-components';

import { colors } from 'app-wrapper/view/themes/themesColors';

export const CarrierContactCopiableTextContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;

  > .ant-typography {
    > .ant-typography-copy {
      color: ${colors.gray61} !important;
    }
  }
`;
