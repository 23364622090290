import React, { memo } from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';

import { UC as appUC } from 'app-wrapper/controllers';
import { ChargesCostChangesComponent } from './ChargesCostChanges.component';

const ChargesCostChangesContainer = memo(() => {
  const chargesBol = useSelector(R.selectors.shipmentTrackerRoutes.getComparedChargesTableBol);
  const chargesContainer = useSelector(R.selectors.shipmentTrackerRoutes.getComparedChargesTableContainer);
  const currentTotal = useSelector(R.selectors.shipmentTrackerRoutes.getSavedChargesSum);
  const newTotal = useSelector(R.selectors.shipmentTrackerRoutes.getNewTotal);

  return (
    <ChargesCostChangesComponent
      chargesBol={chargesBol}
      chargesContainer={chargesContainer}
      onClose={appUC.drawer.closeDrawer}
      currentTotal={currentTotal}
      newTotal={newTotal}
    />
  );
});

export { ChargesCostChangesContainer as ChargesCostChanges };
