import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'app-wrapper/createSlice';

import { ShipmentDeclarationFileDTM } from 'shipment-operations/models/dtm';
import { IShipmentDocumentsHazmatState } from 'shipment-operations/models/states';

export const initialState: IShipmentDocumentsHazmatState = {
  containers: [],
  isLoading: false,
  hasHazmat: false,
  isHasHazmatPending: false,
};

export const ShipmentDocumentsHazmatStore = createSlice({
  name: 'ShipmentDocument',
  initialState,
  reducers: {
    setIsLoading: (state, { payload }) => ({
      ...state,
      isLoading: payload,
    }),
    setContainers: (state, { payload }) => ({
      ...state,
      containers: payload,
    }),
    setHasHazmat: (state, { payload }: PayloadAction<boolean>) => {
      state.hasHazmat = payload;
    },
    setIsHasHazmatPending: (state, { payload }: PayloadAction<boolean>) => {
      state.isHasHazmatPending = payload;
    },
    setContainerFile: (state, { payload }: PayloadAction<{
      containerId: number;
      file?: ShipmentDeclarationFileDTM,
    }>) => {
      const containers = [...state.containers];
      const targetContainerIndex = containers.findIndex(({ id }) => id === payload.containerId);
      let targetContainer = containers[targetContainerIndex];

      if (!targetContainer) {
        console.error('ShipmentDocumentsHazmat store error: setContainerFile');

        return state;
      }

      targetContainer = { ...targetContainer };
      targetContainer.file = payload.file;

      containers.splice(targetContainerIndex, 1, targetContainer);

      return {
        ...state,
        containers,
      };
    },
  },
});

export const shipmentDocumentsHazmatReducer = ShipmentDocumentsHazmatStore.reducer;
export const shipmentDocumentsHazmatActions = ShipmentDocumentsHazmatStore.actions;
