import React from 'react';
import { useSelector } from 'react-redux';

import { R } from 'user-management/repository';

import { EditTradeAccountingComponent } from './EditTradeAccounting.component';

const EditTradeAccountingContainer = () => {
  const isPending = useSelector(R.selectors.customer.getIsTradeReferencePending);

  return (
    <EditTradeAccountingComponent
      isPending={isPending}
    />
  );
};

export { EditTradeAccountingContainer as EditTradeAccounting };
