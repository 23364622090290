import { createSelector } from 'reselect';

import { RootState } from 'app-wrapper/store';

const localState = (state: RootState) => state.passwordResetEmailSend;

const getLoading = createSelector(
  localState,
  (state) => state.isLoading,
);

const getEmail = createSelector(
  localState,
  (state) => state.email,
);

const getEmailError = createSelector(
  localState,
  (state) => state.emailError,
);

const wasAnyFormFieldChanged = createSelector(
  localState,
  (state) => !state.email || !!state.emailError,
);

export const passwordResetEmailSendSelectors = {
  getEmail,
  getEmailError,
  getLoading,
  wasAnyFormFieldChanged,
};
