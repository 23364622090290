import styled from 'styled-components';
import Typography from 'antd/es/typography';

export const TemperatureControl = styled.div`
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  padding: 16px;
  background-color: white;
`;

export const TemperatureControlHeader = styled(Typography)`
  width: 100%;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  padding-bottom: 16px;
  margin-bottom: 16px;
  border-bottom: 1px solid #EAEEF8;
`;

export const TemperatureControlContent = styled.div`
  display: flex;
  flex-direction: row;
  
  & > div {
    height: 32px;
  }
  
  & > div > span {
    font-size: 12px;
    line-height: 20px;
  }
`;
