import styled from 'styled-components';
import Typography from 'antd/es/typography';

export const ShippingFormSectionTitle = styled(Typography.Text)`
  display: block;
  margin-bottom: 16px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
`;
