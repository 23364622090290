import { createSlice } from 'app-wrapper/createSlice';

import { IInvoicesState } from 'monetary/models/states/Invoices.state';

const initialState: IInvoicesState = {
  isLoading: false,
  data: [],
  isError: false,
  totalElements: 0,
  activePage: 1,
  firstTimeLoading: true,
};

export const invoicesSlice = createSlice({
  name: 'invoices',
  initialState,
  reducers: {
    setIsLoading: (state, { payload }) => ({
      ...state,
      isLoading: payload,
    }),
    setError: (state, { payload }) => ({
      ...state,
      isLoading: false,
      error: payload,
    }),
    setData: (state, { payload }) => ({
      ...state,
      isLoading: false,
      isError: false,
      data: payload.data,
      totalElements: payload.totalElements,
    }),
    onChangeActivePage: (state, { payload }) => ({
      ...state,
      activePage: payload,
    }),
    setFirstTimeLoading: (state, { payload }) => ({
      ...state,
      firstTimeLoading: payload,
    }),
  },
});

export const invoicesActions = invoicesSlice.actions;
export const invoicesReducer = invoicesSlice.reducer;
