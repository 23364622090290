import React from 'react';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';

import { R } from 'user-management/repository';
import { UC } from 'user-management/controllers';

import { TeamPageComponent } from './TeamPage.component';

export const TeamPage = () => {
  const { teamId = '' } = useParams();
  const isLoading = useSelector(R.selectors.teams.getIsLoading);
  const team = useSelector(R.selectors.teams.getTeam);
  const isAdmin = useSelector(R.selectors.teams.getIsCurrentUserAdmin);

  return (
    <TeamPageComponent
      teamId={teamId}
      team={team}
      init={UC.teams.initTeam}
      isLoading={isLoading}
      openEditDrawer={UC.teams.openEditTeamDrawer}
      isAdmin={isAdmin}
    />
  );
};
