import React from 'react';

import { IPropsIcon } from 'app-wrapper/interfaces/icon';

export const PriorityHighSvg = (props: IPropsIcon) => {
  const { width, height, style } = props;
  return (
    <svg
      className="PriorityHighSvg"
      width={width || '16'}
      height={height || '17'}
      viewBox="0 0 16 17"
      color={style?.color || '#FF4D4F'}
      style={style || undefined}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect y="0.5" width="16" height="16" rx="2" fill="#FFF1F0" />
      <path fillRule="evenodd" clipRule="evenodd" d="M3.81601 13.2397C4.16315 13.5868 4.72596 13.5868 5.07309 13.2397L8.00011 10.3126L10.9271 13.2397C11.2743 13.5868 11.8371 13.5868 12.1842 13.2397C12.5313 12.8925 12.5313 12.3297 12.1842 11.9826L8.62865 8.42702C8.28152 8.07988 7.7187 8.07988 7.37157 8.42702L3.81601 11.9826C3.46888 12.3297 3.46888 12.8925 3.81601 13.2397Z" fill="#FF4D4F" />
      <path fillRule="evenodd" clipRule="evenodd" d="M3.81601 8.23965C4.16315 8.58678 4.72596 8.58678 5.07309 8.23965L8.00011 5.31263L10.9271 8.23965C11.2743 8.58678 11.8371 8.58678 12.1842 8.23965C12.5313 7.89252 12.5313 7.3297 12.1842 6.98257L8.62865 3.42702C8.28152 3.07988 7.7187 3.07988 7.37157 3.42702L3.81601 6.98257C3.46888 7.3297 3.46888 7.89252 3.81601 8.23965Z" fill="#FF4D4F" />
    </svg>
  );
};
