import React from 'react';

import { IPropsIcon } from 'app-wrapper/interfaces/icon';
import themesColors from 'app-wrapper/view/themes/themesColors';

export const MinusSvg = (props: IPropsIcon) => {
  const { width, height, style } = props;
  return (
    <svg
      className="MinusSvg"
      width={width || '10'}
      height={height || '2'}
      viewBox="0 0 10 2"
      color={style?.color || themesColors.neutralBranding10}
      style={style || undefined}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >

      <path d="M9.82154 0.491699H0.178676C0.119747 0.491699 0.0715332 0.539914 0.0715332 0.598842V1.40242C0.0715332 1.46134 0.119747 1.50956 0.178676 1.50956H9.82154C9.88046 1.50956 9.92868 1.46134 9.92868 1.40242V0.598842C9.92868 0.539914 9.88046 0.491699 9.82154 0.491699Z" fill="currentColor" />
    </svg>
  );
};
