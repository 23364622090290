import styled from 'styled-components';
import { InfoCircleOutlined } from '@ant-design/icons';

import { FlexRow, SmallTextFootnoteDescription } from 'app-wrapper/view/guideline';

export const Footer = styled(FlexRow)`
  height: 24px;
  margin-bottom: 12px;
  justify-content: space-between;
`;

export const Text = styled.span`
  ${SmallTextFootnoteDescription}
  color: ${({ theme }) => theme.themesColors.characterSecondaryDot45};
`;

export const InfoIcon = styled(InfoCircleOutlined)`
  margin-left: 4px;
  width: 12px;
  font-size: 11px;
  color: ${({ theme }) => theme.themesColors.characterSecondaryDot45};
`;

export const DueDate = styled(FlexRow)`
  margin-left: auto;
`;

export const DueDateText = styled(Text)`
  color: initial;
`;
