import styled from 'styled-components';

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 16px 120px 16px;
  overflow-y: auto;
  height: 100%;
  
  &::-webkit-scrollbar {
    display: none;
  }
`;
