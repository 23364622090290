import styled from 'styled-components';

import { SkypaceDarkLogo } from 'app-wrapper/view/icons';

export const Logo = styled(SkypaceDarkLogo)`
  width: 129px;
  height: auto;
`;

export const License = styled.span`
  font-weight: 400;
  font-size: 0.7rem;
`;
