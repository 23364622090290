import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Option } from 'app-wrapper/view/components';
import { ClausesList } from 'shipment-operations/constants';
import { IBillOfLadingCommonState } from 'shipment-operations/models/states';

import { Footer } from './components';
import {
  Form,
  FormItem,
  FormItemLabel,
  Label,
  Spinner,
  SpinnerWrap,
  Select,
} from './BillOfLadingEditClauses.styled';

interface BillOfLadingEditClausesComponentProps {
  formState: IBillOfLadingCommonState['clausesFormState'];
  formErrors: IBillOfLadingCommonState['clausesFormErrors'];
  setFormState: (clauses: string[]) => void;
  isPending: boolean;
}

export const BillOfLadingEditClausesComponent: FC<BillOfLadingEditClausesComponentProps> = ({
  formErrors,
  formState,
  setFormState,
  isPending,
}) => {
  const { t } = useTranslation();

  const handleChange = useCallback((value: string[]) => {
    setFormState(value);
  }, [setFormState]);

  return (
    <Form>
      {isPending ? (
        <SpinnerWrap>
          <Spinner />
        </SpinnerWrap>
      ) : (
        <>
          <FormItem>
            <FormItemLabel>
              <Label>
                {t('Choose Clause(s)')}
              </Label>
            </FormItemLabel>

            <Select
              mode="multiple"
              allowClear
              value={formState.clauses}
              onChange={handleChange}
              error={formErrors.clauses}
              placeholder={t('Clauses')}
            >
              {ClausesList.map(({ id, name }) => (
                <Option key={id} value={id}>{name}</Option>
              ))}
            </Select>
          </FormItem>

          <Footer />
        </>
      )}
    </Form>
  );
};
