import React, { FC } from 'react';
import { AlertProps } from 'antd/es/alert';

import { Alert } from './AlertBlock.styled';

export const AlertBlock: FC<AlertProps> = (props) => (
  <Alert
    showIcon
    {...props}
  />
);
