import styled from 'styled-components';
import AntDivider from 'antd/es/divider';

import { FlexCol, FlexRow } from 'app-wrapper/view/guideline';

export const Service = styled(FlexRow)`
  padding: 16px;
  height: 76px;
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 1);
  border: 1px solid ${({ theme }) => theme.themesColors.neutralBranding4};
  cursor: pointer;
`;

export const ServiceTexts = styled(FlexCol)`
  min-width: 80%;
  margin-left: 16px;
`;

export const ServiceTitle = styled.span`
  font-size: 14px;
  line-height: 22px;
  font-weight: 500;
  color: ${({ theme }) => theme.themesColors.primaryBranding7};
`;

export const ServiceSubTitle = styled(ServiceTitle)`
  color: ${({ theme }) => theme.themesColors.characterSecondaryDot45};
`;

export const Divider = styled(AntDivider)`
  margin: 0 16px;
  width: 1px;
  height: 100%;
`;

export const StatusWrap = styled.div`
  margin-left: auto;
`;
