import styled from 'styled-components';

import Menu from 'antd/es/menu';
import Badge from 'antd/es/badge';

const { Item } = Menu;

export const ShipmentContainersTabsMenu = styled(Menu).attrs({
  'data-class': 'ShipmentContainersTabsMenu',
})`
  width: 100%;
  
  &.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover {
    color: #202C3C;
  }
  
  &.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected,
  &.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active {
    color: #202C3C;
  }
`;

export const ShipmentContainersTabsMenuItem = styled(Item).attrs({
  'data-class': 'ShipmentContainersTabsMenuItem',
})`
  color: #73819B;
`;

export const ShipmentContainersTabsBadge = styled(Badge).attrs({
  'data-class': 'ShipmentContainersTabsBadge',
})`
  margin: -3px 0 0 4px;
  background-color: transparent;
`;
