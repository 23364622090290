import styled from 'styled-components';

import { divFlex } from 'app-wrapper/view/themes/mixin';

export const SOScaffoldWrapper = styled('div').attrs({
  'data-class': 'SOScaffoldWrapper',
})`
  ${divFlex}
  flex-direction: column;
  gap: 8px;
  overflow-y: auto;
`;
