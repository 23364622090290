import React, { FC } from 'react';

import { UC } from 'shipment-operations/controllers';
import { CargoBaseDTM } from 'shipment-operations/models/dtm';

import { CardSelectCheckboxComponent } from './CardSelectCheckbox.component';

interface ICardSelectCheckboxContainerProps {
  checked: boolean
  disabled: boolean
  cargo: CargoBaseDTM
}

const CardSelectCheckboxContainer: FC<ICardSelectCheckboxContainerProps> = ({
  checked,
  disabled,
  cargo,
}) => (
  <CardSelectCheckboxComponent
    checked={checked}
    disabled={disabled}
    cargo={cargo}
    onChange={UC.shipmentContainers.toggleCargoToUsed}
  />
);

export {
  CardSelectCheckboxContainer as CardSelectCheckbox,
};
