import React, {
  FC, memo, useEffect, useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import Skeleton from 'antd/es/skeleton';
import { useParams } from 'react-router';

import { REQUEST_STATUS } from 'app-wrapper/constants';
import { EditPanSvg, PlusSvg } from 'app-wrapper/view/icons';

import { CompanyDTM } from 'user-management/models/dtm';
import { NoDataLineItem } from 'user-management/view/components';

import {
  AACContentTable,
  AACContentTableContent,
  AACContentTableContentLink,
  AACContentTableContentItem,
  AACContentTableContentItemTop,
  AACContentTableContentTitle,
  AACContentTableHeader,
  AACContentTableHeaderItem,
  AACSpanEllipsis,
  AACContentTableTitle,
  AACContentTableTitleWrap,
  AACContentTableHeaderAddBtn,
  AACContentTableContentPrimary,
} from './AACContentTable.styled';

interface IAACTableProps {
  onStart: () => void;
  nextPageTasks: () => void;
  onClickAddAddress: () => void;
  onClickEditAddress: (id: number) => () => void;
  isLoad?: boolean;
  isLoadAdd?: string;
  company?: CompanyDTM;
  isEnd?: boolean;
}

const AACTable: FC<IAACTableProps> = (props) => {
  const {
    onStart,
    nextPageTasks,
    onClickAddAddress,
    onClickEditAddress,
    isLoad,
    isLoadAdd,
    company,
    isEnd,
  } = props;

  const { t } = useTranslation();
  const { addressId } = useParams<'addressId'>();

  useEffect(() => {
    onStart();
  }, []);

  const addressLine = useMemo(() => company?.addresses?.filter((item) => `${item.id}` === addressId)?.[0]?.getAddressLine(), [company?.addresses]);

  return (
    <AACContentTable>
      <AACContentTableTitleWrap>
        <AACContentTableTitle>{addressLine}</AACContentTableTitle>

        <AACContentTableHeaderAddBtn
          onClick={onClickAddAddress}
        >
          <PlusSvg />
          <span>{t('AddContactPerson')}</span>
        </AACContentTableHeaderAddBtn>
      </AACContentTableTitleWrap>

      <AACContentTableHeader>
        <AACContentTableHeaderItem
          width="23.6%"
        >
          {t('ContactPerson')}
        </AACContentTableHeaderItem>
        <AACContentTableHeaderItem
          width="23.6%"
        >
          {t('Email')}
        </AACContentTableHeaderItem>
        <AACContentTableHeaderItem
          width="23.6%"
        >
          {t('Phone')}
        </AACContentTableHeaderItem>
        <AACContentTableHeaderItem
          width="23.6%"
        >
          {t('Additional Phone')}
        </AACContentTableHeaderItem>
      </AACContentTableHeader>

      <AACContentTableContent id="AACContentTableContent">

        <InfiniteScroll
          dataLength={Number(company?.contacts?.length || 0)}
          next={nextPageTasks}
          hasMore={!isEnd}
          loader={
            isLoad
            && (<Skeleton />)
          }
          scrollableTarget="AACContentTableContent"
        >
          {company?.contacts?.map((item, index) => (
            <AACContentTableContentItem key={`AACContentTableContentItem_${item.id}`}>
              <AACContentTableContentItemTop>
                <AACContentTableContentTitle isFirst>
                  <AACSpanEllipsis>
                    {item.fullName}
                  </AACSpanEllipsis>
                  {item.primary && (<AACContentTableContentPrimary>{t('Primary')}</AACContentTableContentPrimary>)}
                </AACContentTableContentTitle>
                <AACContentTableContentTitle>
                  <AACSpanEllipsis>
                    {item.email}
                  </AACSpanEllipsis>
                </AACContentTableContentTitle>
                <AACContentTableContentTitle>
                  <AACSpanEllipsis>
                    {item.phone}
                  </AACSpanEllipsis>
                </AACContentTableContentTitle>
                <AACContentTableContentTitle>
                  <AACSpanEllipsis>
                    {item?.phone2 || (<NoDataLineItem />)}
                  </AACSpanEllipsis>
                </AACContentTableContentTitle>
                <AACContentTableContentLink
                  width="2.8%"
                  justifyContent="end"
                  onClick={onClickEditAddress(index)}
                >
                  <EditPanSvg />
                </AACContentTableContentLink>
              </AACContentTableContentItemTop>

            </AACContentTableContentItem>
          ))}
        </InfiniteScroll>
        {isLoadAdd === REQUEST_STATUS.pending && (<Skeleton />)}
      </AACContentTableContent>

    </AACContentTable>
  );
};

const AACTableCache = memo(AACTable);

export { AACTableCache as AACTable };
