import React from 'react';

import { useAppSelector } from 'app-wrapper/hooks';
import { R } from 'shipment-operations/repository';

import { AdditionalComponent } from './Additional.component';

const AdditionalContainer = () => {
  const data = useAppSelector(R.selectors.shipmentTransportationCharges.groupedAdditionalContainers);
  const containers = useAppSelector(R.selectors.shipmentTransportationCharges.getAdditionalContainersTable);
  const tableData = useAppSelector(R.selectors.shipmentTransportationCharges.getAdditionalDataForTable);
  const totalData = useAppSelector(R.selectors.shipmentTransportationCharges.getAdditionalDataTotal);

  return (
    <AdditionalComponent
      innerData={data}
      containers={containers}
      tableData={tableData}
      totalData={totalData}
    />
  );
};

export { AdditionalContainer as Additional };
