import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  Wrap,
  Content,
  Image,
  Title,
  Text,
} from './OrganizationCreated.styled';
import illustrationSrc from './assets/Illustration.png';

export const OrganizationCreated = () => {
  const { t } = useTranslation();

  return (
    <Wrap>
      <Content>
        <Title>
          {t('You have successfully created your organization')}
        </Title>

        <Text>
          {t('Your organization’s details will be reviewed within 2 days. We will contact you via email, and if the review is successful, you will be officially registered on the platform.')}
        </Text>
      </Content>

      <Image src={illustrationSrc} />
    </Wrap>
  );
};
