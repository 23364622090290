import { R } from 'monetary/repository';
import { UC } from 'monetary/controllers';
import React, {
  FC, useMemo,
} from 'react';
import { useSelector } from 'react-redux';

import { AddressBlockComponent } from './AddressBlock.component';

export const AddressBlock: FC = (() => {
  const getOriginDoorAddress = useSelector(R.selectors.freightQuote.getOriginDoorAddress);
  const getDestinationDoorAddress = useSelector(R.selectors.freightQuote.getDestinationDoorAddress);

  const getCurrent = useSelector(R.selectors.freightQuote.getFreightQuoteCurrent);
  const getOrigin = useSelector(R.selectors.freightQuote.getFreightQuoteCurrentOrigin);
  const getDestination = useSelector(R.selectors.freightQuote.getFreightQuoteCurrentDestination);
  const getErrors = useSelector(R.selectors.freightQuote.getFreightQuoteErrors);

  const getLocationPortStatus = useSelector(R.selectors.freightQuote.getFreightQuoteCurrentLocationPortStatus);

  const originErrors = useMemo(() => ({
    isPort: getErrors?.origin?.isPort,
    dateDoor: getErrors?.origin?.dateDoor,
    datePort: getErrors?.origin?.datePort,
    location: getErrors?.origin?.location,
  }), []);
  const destinationErrors = useMemo(() => ({
    isPort: getErrors?.destination?.isPort,
    dateDoor: getErrors?.destination?.dateDoor,
    datePort: getErrors?.destination?.datePort,
    location: getErrors?.destination?.location,
  }), []);

  return (
    <AddressBlockComponent
      isAllDisabled={getCurrent.isAllFieldDisabled}
      isCollapsed={!getCurrent.isCollapse}
      // origin
      origin={getOrigin}
      onChangeOriginDoorAutocomplete={UC.FreightQuote.getRFQDoorAutocompleteOrigin}
      originDoorAutocompleteStatus={getCurrent.doorsAutocomplete?.origin?.status || ''}
      onChangeOriginTypeToPort={UC.FreightQuote.onChangeOriginTypeToPort}
      toPortOrigin={!!getOrigin?.isPort}
      onChangeOriginAddress={UC.FreightQuote.onChangeOriginAddress}
      onFocusOriginAddress={UC.FreightQuote.onFocusOriginAddress}
      onBlurOriginAddress={UC.FreightQuote.onBlurOriginAddress}
      onClearOriginAddress={UC.FreightQuote.onClearOriginAddress}
      getRFQPortAutocompleteOrigin={UC.FreightQuote.getRFQPortAutocompleteOrigin}
      locationPortStatusOrigin={getLocationPortStatus?.origin?.status || ''}
      onKeyPressOriginAddress={UC.FreightQuote.onKeyPressOriginAddress}
      onChangePortDateOrigin={UC.FreightQuote.onChangePortDateOrigin}
      onFocusPortDateOrigin={UC.FreightQuote.onFocusPortDateOrigin}
      onBlurPortDateOrigin={UC.FreightQuote.onBlurPortDateOrigin}
      // destination
      toPortDestination={!!getDestination?.isPort}
      onChangeDestinationTypeToPort={UC.FreightQuote.onChangeDestinationTypeToPort}
      destinationDoorAutocompleteStatus={getCurrent.doorsAutocomplete?.destination?.status || ''}
      originDoorAutocompleteOptions={getOriginDoorAddress}
      destinationDoorAutocompleteOptions={getDestinationDoorAddress}
      onChangeDestinationDoorAutocomplete={UC.FreightQuote.getRFQDoorAutocompleteDestination}
      getRFQPortAutocompleteDestination={UC.FreightQuote.getRFQPortAutocompleteDestination}
      onChangeDestinationAddress={UC.FreightQuote.onChangeDestinationAddress}
      onFocusDestinationAddress={UC.FreightQuote.onFocusDestinationAddress}
      onBlurDestinationAddress={UC.FreightQuote.onBlurDestinationAddress}
      onClearDestinationAddress={UC.FreightQuote.onClearDestinationAddress}
      destination={getDestination}
      locationPortStatusDestination={getLocationPortStatus?.destination?.status || ''}
      onKeyPressDestinationAddress={UC.FreightQuote.onKeyPressDestinationAddress}
      onChangePortDateDestination={UC.FreightQuote.onChangePortDateDestination}
      onFocusPortDateDestination={UC.FreightQuote.onFocusPortDateDestination}
      onBlurPortDateDestination={UC.FreightQuote.onBlurPortDateDestination}
      // errors
      originErrors={originErrors}
      destinationErrors={destinationErrors}
      isSubmitVisited={getCurrent.isSubmitVisited}
    />
  );
});
