import styled from 'styled-components';
import Typography from 'antd/es/typography';
import Spin from 'antd/es/spin';
import { UploadOutlined } from '@ant-design/icons';

export const Documents = styled.div`
  position: relative;
  width: 100%;
  padding: 16px;
  display: flex;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 1);
  border: 1px solid rgba(234, 238, 248, 1);
  border-radius: 3px;
  overflow-y: auto;
`;

export const SectionTitleWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  &:not(:first-of-type) {
    padding-top: 20px;
    border-top: 1px solid rgba(234, 238, 248, 1);
  }
`;

export const SectionTitle = styled(Typography)`
  padding-bottom: 8px;
  width: 100%;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
`;

export const InvoiceSpinnerWrap = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const InvoiceSpinner = styled(Spin)``;

export const UploadIcon = styled(UploadOutlined)`
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: rgba(49, 66, 88, 1);
  color: white;
  cursor: pointer;

  svg {
    width: 13px;
  }
`;
