import styled from 'styled-components';

import { Button } from 'app-wrapper/view/components';

export const SectionButton = styled(Button)`
  padding: 0 8px;
  height: 22px;
  font-size: 12px;
  line-height: 20px;
  border-color: ${({ theme }) => theme.themesColors.neutralBranding5};
`;
