import React, { FC } from 'react';

import { R } from 'shipment-operations/repository';
import { useAppSelector } from 'app-wrapper/hooks';

import { TotalFooterComponent } from './TotalFooter.component';

interface ITotalFooterProps {
  dataSource: {
    apTotalCost: number
    arTotalCost: number
    profit: number
  }[]
}

const TotalFooterContainer: FC<ITotalFooterProps> = ({ dataSource }) => {
  const isPercentage = useAppSelector(R.selectors.shipmentTransportationCharges.getIsPercentage);

  return (
    <TotalFooterComponent dataSource={dataSource} isPercentage={isPercentage} />
  );
};

export { TotalFooterContainer as TotalFooter };
