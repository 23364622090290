import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';
import { UC } from 'shipment-operations/controllers';

import { CargoTitleComponent } from './CargoTitle.component';

const CargoTitleContainer: FC = () => {
  const cargos = useSelector(R.selectors.cargo.getCargos);
  const permissions = useSelector(R.selectors.shipment.getCargosPermissions);

  return (
    <CargoTitleComponent
      amountOfCargos={cargos.length}
      addCargo={UC.cargo.addCargo}
      cargosAvailability={permissions.cargosAvailability}
    />
  );
};

export { CargoTitleContainer as CargoTitle };
