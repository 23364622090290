import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Wrapper,
  Title,
} from './Header.styled';

export const Header: FC = () => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <Title>{t('Express Bill of Lading')}</Title>
    </Wrapper>
  );
};
