import styled, { css } from 'styled-components';

export const textColorFirst = css`
  color: ${({ theme }) => theme.themesColors.characterBrandingTitle85};
`;

export const divFlex = css`
  display: flex;
  position: relative;
  ${textColorFirst}
`;

// Div

export const EmptyStateForPagesDiv = styled.div.attrs({
  'data-class': 'EmptyStateForPagesDiv',
})`
  ${divFlex}
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-width: 270px;
  align-items: center;
  justify-content: center;
`;

export const EmptyStateForPagesDivWrapper = styled.div.attrs({
  'data-class': 'EmptyStateForPagesDivWrapper',
})`
  ${divFlex}
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const EmptyStateForPagesIconWrap = styled.div.attrs({
  'data-class': 'EmptyStateForPagesIconWrap',
})`
  ${divFlex}
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const EmptyStateForPagesIconSecondWrap = styled.div.attrs({
  'data-class': 'EmptyStateForPagesIconSecondWrap',
})`
  ${divFlex}
  position: absolute;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  top: 28px;
  svg {
    width: 100%;
    height: 100%;
  }
`;

// Content

export const EmptyStateForPagesText = styled.div.attrs({
  'data-class': 'EmptyStateForPagesText',
})`
  ${divFlex}
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
  margin-bottom: 16px;
`;

export const EmptyStateForPagesTextFirst = styled.div.attrs({
  'data-class': 'EmptyStateForPagesTextFirst',
})`
  ${divFlex}
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.themesColors.neutralBranding10};
  ${({ theme }) => theme?.fonts?.TitleText};
`;

export const EmptyStateForPagesTextSecond = styled.div.attrs({
  'data-class': 'EmptyStateForPagesTextSecond',
})`
  ${divFlex}
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  margin-top: 4px;
  color: ${({ theme }) => theme.themesColors.secondaryDot45};
  ${({ theme }) => theme?.fonts?.SmallTextFootnoteDescription};
`;

export const EmptyStateForPagesTextButton = styled.div.attrs({
  'data-class': 'EmptyStateForPagesTextButton',
})`
  ${divFlex}
  align-items: center;
  justify-content: center;

  margin-top: 0px;
  color: ${({ theme }) => theme.themesColors.neutralBranding1};
  ${({ theme }) => theme?.fonts?.BodyTextRegular};
`;
