import styled from 'styled-components';
import Button from 'antd/es/button';
import { SizeType } from 'antd/es/config-provider/SizeContext';

export const ShippingPartiesReferencesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 324px;

  > * {
    display: flex;
    flex-direction: row;
    gap: 4px;
    width: 100%;
  }
`;

export const ShippingPartiesReference = styled.div`
`;

const getPxSize = (size: SizeType) => {
  switch (size) {
    case 'large':
      return '40px';
    case 'middle':
      return '32px';
    case 'small':
      return '30px';
    default:
      return '';
  }
};

export const ShippingPartiesReferenceButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;

  width: ${({ size }) => getPxSize(size)};
  height: ${({ size }) => getPxSize(size)};
`;
