import React from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';

import { PDFFreightAndChargesComponent } from './PDFFreightAndCharges.component';

const PDFFreightAndChargesContainer = () => {
  const totalValue = useSelector(R.selectors.bookingConfirmationPDF.getDeclaredCargoValue);
  const paymentTerms = useSelector(R.selectors.bookingConfirmationPDF.getPaymentTermsItems);

  return (
    <PDFFreightAndChargesComponent
      paymentTermsItems={paymentTerms}
      totalValue={totalValue}
    />
  );
};

export { PDFFreightAndChargesContainer as PDFFreightAndCharges };
