import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { CardTopFormStatus } from 'shipment-operations/view/components';
import {
  CargoCardTopContainer,
  CargoCardTitleContainer,
  CargoCardHsCode,
  CargoTitleTextWithDraft,
  CargoTitleText,
  UnalignedBadge,
} from './CargoCardTop.styled';

interface ICargoCardTopProps {
  name?: string
  description?: string
  code?: string
  isInDraft: boolean
  isError: boolean
}

export const CargoCardTop: FC<ICargoCardTopProps> = ({
  name,
  description,
  code,
  isInDraft,
  isError,
}) => {
  const { t } = useTranslation();

  const cardTitle = description || name;

  return (
    <CargoCardTopContainer>
      <CargoCardTitleContainer>
        <CargoTitleTextWithDraft>
          <CargoTitleText gray={+!cardTitle}>
            {cardTitle || t('Add cargo details')}
          </CargoTitleText>
          {isInDraft && <UnalignedBadge color="red" />}
        </CargoTitleTextWithDraft>
        <CardTopFormStatus isError={isError} />
      </CargoCardTitleContainer>
      {!!code && <CargoCardHsCode>{code}</CargoCardHsCode>}
    </CargoCardTopContainer>
  );
};
