import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Typography from 'antd/es/typography';
import Skeleton from 'antd/es/skeleton';
import Divider from 'antd/es/divider';
import FileTextOutlined from '@ant-design/icons/FileTextOutlined';
import NumberOutlined from '@ant-design/icons/NumberOutlined';
import UserSwitchOutlined from '@ant-design/icons/UserSwitchOutlined';

import { ShipIcon } from 'app-wrapper/view/icons';
import { PermissionAttributePolicy } from 'shipment-operations/constants';
import { IconFrame } from 'shipment-operations/view/components';

import {
  ReferencesCardContainer,
  ReferenceCardItem,
  ReferenceCardItemContent,
  ReferenceCardLabel,
  ItemsWrapper,
} from './styled';

interface IReferencesCardComponentProps {
  isLoading: boolean
  mblNumber?: string
  inttraReferenceNumber?: string
  contractNumber?: string
  agentsReference?: string
  inttraReferenceNumberAvailability?: PermissionAttributePolicy
  contractNumberAvailability?: PermissionAttributePolicy
}

export const ReferencesCardComponent: FC<IReferencesCardComponentProps> = ({
  isLoading,
  mblNumber,
  inttraReferenceNumber,
  contractNumber,
  agentsReference,
  inttraReferenceNumberAvailability,
  contractNumberAvailability,
}) => {
  const { t } = useTranslation();

  const isAbleToViewInttraReference = useMemo(() => inttraReferenceNumberAvailability === PermissionAttributePolicy.READ, [inttraReferenceNumberAvailability]);
  const isAbleToViewContractNumber = useMemo(() => contractNumberAvailability === PermissionAttributePolicy.READ, [contractNumberAvailability]);

  if (isLoading) {
    return (
      <ReferencesCardContainer>
        <Typography.Title level={5}>
          {t('References')}
        </Typography.Title>
        <Skeleton />
      </ReferencesCardContainer>
    );
  }

  return (
    <ReferencesCardContainer>
      <Typography.Title level={5}>
        {t('References')}
      </Typography.Title>
      <Divider dashed style={{ marginTop: '12px', marginBottom: '8px' }} />

      <ItemsWrapper>
        <ReferenceCardItem>
          <IconFrame><FileTextOutlined /></IconFrame>
          <ReferenceCardItemContent>
            <ReferenceCardLabel>{t('MBL Number')}</ReferenceCardLabel>
            <Typography.Text copyable={!!mblNumber}>{mblNumber || '-'}</Typography.Text>
          </ReferenceCardItemContent>
        </ReferenceCardItem>

        {isAbleToViewInttraReference ? (
          <ReferenceCardItem marginLeft="64px">
            <IconFrame><ShipIcon color="#4A55AD" /></IconFrame>
            <ReferenceCardItemContent>
              <ReferenceCardLabel>{t('Intrra Reference Number')}</ReferenceCardLabel>
              <Typography.Text copyable={!!inttraReferenceNumber}>{inttraReferenceNumber || '-'}</Typography.Text>
            </ReferenceCardItemContent>
          </ReferenceCardItem>
        ) : null}

        {isAbleToViewContractNumber ? (
          <ReferenceCardItem marginLeft="64px">
            <IconFrame><NumberOutlined /></IconFrame>
            <ReferenceCardItemContent>
              <ReferenceCardLabel>{t('Contract Number')}</ReferenceCardLabel>
              <Typography.Text copyable={!!contractNumber}>{contractNumber || '-'}</Typography.Text>
            </ReferenceCardItemContent>
          </ReferenceCardItem>
        ) : null}

        {!!agentsReference && (
        <ReferenceCardItem>
          <IconFrame><UserSwitchOutlined /></IconFrame>
          <ReferenceCardItemContent>
            <ReferenceCardLabel>{t('Agent\'s Reference')}</ReferenceCardLabel>
            <Typography.Text copyable>{agentsReference}</Typography.Text>
          </ReferenceCardItemContent>
        </ReferenceCardItem>
        )}
      </ItemsWrapper>
    </ReferencesCardContainer>
  );
};
