import styled from 'styled-components';
import Table from 'antd/es/table';
import FilePdfOutlined from '@ant-design/icons/FilePdfOutlined';
import { NavLink } from 'react-router-dom';

export const AlertWrapper = styled.div`
  padding-top: 40px;
`;

export const NameOfDocument = styled(NavLink)`
  color: #4A55AD;
  cursor: pointer;
`;

export const StyledDownloadIcon = styled(FilePdfOutlined)`
  color: #73819B;
  cursor: pointer;
`;

export const StyledStatus = styled.span`
  position: relative;
  display: flex;
  align-items: center;
  margin-left: 15px;

  &::before {
    content: '';
    position: absolute;
    width: 5px;
    height: 5px;
    background-color: ${({ color }) => color};
    border-radius: 50%;
    left: -15px;
  }
`;

export const StyledTotal = styled.span`
  font-weight: 500;
`;

export const StyledTable = styled(Table)`
  .ant-table-thead > tr > th {
    background: white;
  }
  .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    display: none;
  }
  .ant-table-thead .ant-table-cell {
    color: #73819B;
    font-size: 12px;
    font-weight: 400;
  }
`;

export const StyledType = styled.span`
  text-transform: capitalize;
`;
