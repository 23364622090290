import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Moment } from 'moment';
import { useParams } from 'react-router-dom';

import {
  Select, Input,
  Tooltip,
} from 'app-wrapper/view/components';

import { RECEIVED_AMOUNT, PAYMENT_DATE, RECEIVABLES } from 'shipment-operations/constants';
import { ShipmentBillingInvoiceDtm } from 'shipment-operations/models/dtm';

import {
  Wrapper, Total, Payment, FieldName, InputWrapper, TotalTitle, TotalSubTitle, StyledInputNumber, StyledDatePicker,
} from './PaymentHeader.styled';

interface IHeaderComponent {
  invoice?: ShipmentBillingInvoiceDtm
  onChangeReceivedAmount: (sum: number) => void
  receivedAmount?: number
  onChangePaymentDate: (date: Moment | null) => void
  onChangeReference: (value: string) => void
  errors?: string[]
  savedDate?: Moment
  savedReference?: string
  isShowAmountError?: boolean
}

const timeFormat = 'D MMM YYYY';

const HeaderComponent: FC<IHeaderComponent> = ({
  invoice, onChangeReceivedAmount, receivedAmount, onChangePaymentDate, onChangeReference, errors, savedDate,
  savedReference, isShowAmountError,
}) => {
  const { t } = useTranslation();
  const { invoiceType } = useParams<'invoiceType'>();

  const handleOnChangeAmount = useCallback((value) => onChangeReceivedAmount(value), []);
  const handleOnChangeDate = useCallback((date) => onChangePaymentDate(date), []);
  const handleOnChangeReference = useCallback((e) => onChangeReference(e.target.value), []);

  return (
    <Wrapper>
      <Payment>
        <InputWrapper>
          <FieldName>{invoiceType === RECEIVABLES ? t('payment.customer') : t('payment.vendor')} *</FieldName>
          <Select
            style={{ height: '32px', minHeight: '32px' }}
            size="middle"
            disabled
            defaultValue={invoice && invoice[invoiceType === RECEIVABLES ? 'companyName' : 'companyNameFrom']}
          />
        </InputWrapper>
        <InputWrapper>
          <FieldName>{invoiceType === RECEIVABLES ? t('payment.postedDate') : t('payment.paidDate')} *</FieldName>
          <StyledDatePicker
            style={{ borderColor: errors?.includes(PAYMENT_DATE) ? 'red' : '' }}
            onChange={handleOnChangeDate}
            format={timeFormat}
            allowClear={false}
            value={savedDate}
          />
        </InputWrapper>
        <InputWrapper>
          <FieldName>{t('payment.bankAccountNumber')} *</FieldName>
          <Select
            style={{ height: '32px', minHeight: '32px' }}
            size="middle"
            disabled
            defaultValue="23 0112 3456 7801 1234 5678"
          />
        </InputWrapper>
        <InputWrapper>
          <FieldName>{invoiceType === RECEIVABLES ? t('payment.receivedReference') : t('payment.reference')}</FieldName>
          <Input
            defaultValue={savedReference}
            size="small"
            style={{ height: '32px', minHeight: '32px' }}
            onChange={handleOnChangeReference}
            maxLength={16}
          />
        </InputWrapper>
      </Payment>
      <Total>
        <TotalTitle>{invoiceType === RECEIVABLES ? t('payment.Total Received Amount') : t('payment.Total Paid Amount')}</TotalTitle>
        <TotalSubTitle>{t('payment.Please enter')}</TotalSubTitle>
        <Tooltip
          title={t('payment.error.payment')}
          placement="bottomRight"
          mode="danger"
          visible={isShowAmountError}
        >
          <StyledInputNumber
            data-class="StyledInputNumberReceivedAmount"
            size="large"
            style={{
              fontSize: '16px',
              width: '100%',
              textAlign: 'right',
              borderColor: (errors?.includes(RECEIVED_AMOUNT) || isShowAmountError) ? 'red' : '',
            }}
            onChange={handleOnChangeAmount}
            controls={false}
            value={receivedAmount || ''}
            formatter={(value) => (value ? `$ ${value}` : '$ 0')}
            min={0}
          />
        </Tooltip>
      </Total>
    </Wrapper>
  );
};

export { HeaderComponent };
