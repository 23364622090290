import styled from 'styled-components';
import Button from 'antd/es/button';

import { FlexCol } from 'app-wrapper/view/guideline';

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  padding: 0 16px;
  background-color: #F4F5F8;
  overflow: auto;
`;

export const AlertWrapper = styled.div`
  padding-top: 40px;
`;

export const StyledButton = styled(Button)`
  background-color: #4E5D70;
  border: none;

  &:hover {
    background-color: #798aa2;
  }
`;

export const Main = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 1260px;
  margin: 0 auto;
`;

export const SkeletonWrap = styled(FlexCol)`
  margin-top: 16px;
  gap: 8px;
`;
