import React from 'react';
import { useSelector } from 'react-redux';

import { R } from 'app-wrapper/repository';
import { UC } from 'app-wrapper/controllers';

import { AgreementsDrawerComponent } from './AgreementsDrawer.component';

const AgreementsDrawerContainer = () => {
  const isOpened = useSelector(R.selectors.agreements.getIsDrawerOpened);
  const shipment = useSelector(R.selectors.agreements.getShipment);
  const isLoading = useSelector(R.selectors.agreements.getIsLoading);
  const isNRARequired = useSelector(R.selectors.agreements.getIsNRARequired);

  return (
    <AgreementsDrawerComponent
      isLoading={isLoading}
      shipment={shipment}
      onClose={UC.agreements.closeAgreementsDrawer}
      isOpen={isOpened}
      isNRAVisible={isNRARequired}
    />
  );
};

export { AgreementsDrawerContainer as AgreementsDrawer };
