import React, { FC, useMemo } from 'react';
import filter from 'lodash/fp/filter';

import { UC } from 'shipment-operations/controllers';
import { R } from 'shipment-operations/repository';
import { useAppSelector } from 'app-wrapper/hooks';

import { subjectToIncluded } from 'shipment-operations/constants';
import { ChargeDTM } from 'shipment-operations/models/dtm';
import { CustomTableSelectable } from 'shipment-operations/view/components';
import { parseCharges } from 'shipment-operations/view/pages/ShipmentCharges/utils/prepareDataForTable';

interface ICustomTableProps {
  data: ChargeDTM[]
  fullTable: boolean
  withTooltip?: boolean
}

const CustomTable: FC<ICustomTableProps> = ({ data, fullTable, withTooltip = false }) => {
  const selectedCharges = useAppSelector(R.selectors.createInvoiceCharges.getSelectedCharges);
  const includedCharges = useMemo(() => data.filter((item) => item.subjectTo === subjectToIncluded).map((item) => item.chargeCode.description), [data]);
  const notIncludedCharges = useMemo(() => data.filter((item) => item.subjectTo !== subjectToIncluded), [data]);

  const originData = useMemo(() => {
    const filtered = filter((item) => item.designation === 'ORIGIN', withTooltip ? notIncludedCharges : data);
    return parseCharges(filtered);
  }, [data]);

  const freightData = useMemo(() => {
    const filtered = filter((item) => item.designation === 'FREIGHT', notIncludedCharges);
    const filterByIncluded = filter((item) => item.designation === 'FREIGHT' && item.subjectTo !== subjectToIncluded, data);
    return parseCharges(withTooltip ? filterByIncluded : filtered, includedCharges);
  }, [data]);

  const destinationData = useMemo(() => {
    const filtered = filter((item) => item.designation === 'DESTINATION', withTooltip ? notIncludedCharges : data);
    return parseCharges(filtered);
  }, [data]);

  return (
    <CustomTableSelectable
      fullTable={fullTable}
      onItemSelect={UC.createInvoiceCharges.setSelectedCharge}
      onSubTableSelect={UC.createInvoiceCharges.setGroupSelectedCharges}
      selectedCharges={selectedCharges}
      originData={originData}
      freightData={freightData}
      destinationData={destinationData}
    />
  );
};

export { CustomTable };
