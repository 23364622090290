import { createSelector } from 'reselect';
import groupBy from 'lodash/fp/groupBy';

import { calculateRange } from 'monetary/repository/store/FreightQuote/FreightQuotePrint.selectors';
import { RootState } from 'app-wrapper/store';

const localState = (state: RootState) => state.shipmentFreeTime;

const getIsLoading = createSelector(
  localState,
  (state) => state.isLoading,
);

const getError = createSelector(
  localState,
  (state) => state.error,
);

const getData = createSelector(
  localState,
  (state) => state.data,
);

const getNotAppliedCharges = createSelector(
  getData,
  (charges) => {
    const filtered = charges && charges?.filter((item) => !item.applied && item?.chargeCode?.subType === 'FREE_TIME');
    return filtered?.map((item) => ({ ...item, customAppliance: calculateRange(item.applianceRange?.maxValue, item.applianceRange?.minValue) }));
  },
);

const getOriginCharges = createSelector(
  getNotAppliedCharges,
  (charges) => charges && charges?.filter((item) => item?.designation === 'ORIGIN'),
);

const getDestinationCharges = createSelector(
  getNotAppliedCharges,
  (charges) => charges && charges?.filter((item) => item?.designation === 'DESTINATION'),
);

const getFreightCharges = createSelector(
  getNotAppliedCharges,
  (charges) => charges && charges?.filter((item) => item?.designation === 'FREIGHT'),
);

const getGroupedOrigin = createSelector(
  getOriginCharges,
  (charges) => charges && groupBy((item) => item.container.type, charges),
);

const getGroupedDestination = createSelector(
  getDestinationCharges,
  (charges) => charges && groupBy((item) => item.container.type, charges),
);

const getGroupedFreight = createSelector(
  getFreightCharges,
  (charges) => charges && groupBy((item) => item.container.type, charges),
);

export const shipmentFreeTimeSelectors = {
  getIsLoading,
  getError,
  getData,
  getOriginCharges,
  getDestinationCharges,
  getFreightCharges,
  getGroupedOrigin,
  getGroupedDestination,
  getGroupedFreight,
};
