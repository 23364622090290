import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { REQUEST_STATUS } from 'app-wrapper/constants';

import { R } from 'shipment-operations/repository';
import { UC } from 'shipment-operations/controllers';

import { ShipmentDocumentsAdditionalFormComponent } from './ShipmentDocumentsAdditionalForm.component';

interface IShipmentDocumentsAdditionalForm {
  authToken: string
  shipmentId?: string | number
}

const ShipmentDocumentsAdditionalFormContainer: FC<IShipmentDocumentsAdditionalForm> = ({ shipmentId, authToken }) => {
  const updateState = useSelector(R.selectors.shipmentDocuments.getShipmentDocumentUpdate);
  const defaultState = useSelector(R.selectors.shipmentDocuments.getShipmentDocumentDefault);
  const permissions = useSelector(R.selectors.shipment.getDocumentsPermissions);

  return (
    <ShipmentDocumentsAdditionalFormComponent
      shipmentId={shipmentId}
      authToken={authToken}
      updateState={updateState}
      isLoad={!defaultState.isRequest || defaultState.getAdditionalStatus === REQUEST_STATUS.pending || defaultState.getManualAdditionalStatus === REQUEST_STATUS.pending}
      updateDocumentType={UC.shipmentDocumentAdditional.setUpdateDocumentTypeByIndex}
      clearDocumentType={UC.shipmentDocumentAdditional.setClearDocumentTypeByIndex}
      onUploadDoneHandler={UC.shipmentDocumentAdditional.setUpdateDocumentFileByIndex}
      onChangeNotesHandler={UC.shipmentDocumentAdditional.setUpdateNotes}
      onChangeReferenceHandler={UC.shipmentDocumentAdditional.setUpdateReference}
      onRemoveItem={UC.shipmentDocumentAdditional.onRemoveUpdateDocumentItem}
      setUpdateDocuments={UC.shipmentDocumentAdditional.setUpdateDocuments}
      additionalDocumentsPermissions={permissions.additionalDocuments}
    />
  );
};

export { ShipmentDocumentsAdditionalFormContainer as ShipmentDocumentsAdditionalForm };
