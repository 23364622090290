const POSITIVE_DECIMAL_FRACTION_REGEX = /^(\d{0,100})(\.)?(\d{0,100})$/; // test Sonar fix error, original /^(\d*)(\.)?(\d*)$/

type Validator = (
  str: string,
  maxLength: number,
  precision?: number,
  props?: {
    withoutZero?: boolean
    integerMaxLength?: number
    forcePrecision?: boolean
  }
) => string;

export const validateDecimalFraction: Validator = (
  str, maxLength, precision, props,
) => {
  let result = '';
  const { withoutZero, integerMaxLength, forcePrecision } = props || {};

  str = withoutZero ? str.replace(/^0+/, '') : str;

  const matchResult = str
    .replace(/[^0-9.]/g, '')
    .replace('.', 'x')
    .replace(/\./g, '')
    .replace('x', '.')
    .match(POSITIVE_DECIMAL_FRACTION_REGEX);

  if (matchResult) {
    const [, integerPartMatch, dot, decimalPart] = matchResult;
    const integerPart = integerMaxLength
      ? integerPartMatch.slice(0, integerMaxLength)
      : integerPartMatch;

    if (dot && precision !== undefined) {
      const integerPartLength = maxLength - 1 - Math.min(
        precision,
        maxLength - integerPart.length - 1,
      );
      const slicedIntegerPart = integerPart.slice(0, integerPartLength);
      const slicedDecimalPart = decimalPart.slice(0, precision);

      result = `${slicedIntegerPart}.${slicedDecimalPart}`;
    } else {
      result = integerPart;
    }
  }

  if (forcePrecision && !result.includes('.') && precision) {
    result = `${result}.${'0'.repeat(precision)}`;
  }

  return result.slice(0, maxLength);
};
