import React, { useCallback, FC } from 'react';
import Divider from 'antd/es/divider';
import DatePicker from 'antd/es/date-picker';
import moment, { Moment } from 'moment';
import { useTranslation } from 'react-i18next';
import Skeleton from 'antd/es/skeleton';

import { CREATE_AP_INVOICE } from 'shipment-operations/constants';
import { Input, Select } from 'app-wrapper/view/components';
import { ShippingPartyDTM } from 'shipment-operations/models/dtm';

import {
  CardWrapper, Wrapper, CardsTitle, CardsValue,
} from 'shipment-operations/view/components';

import { DatePickerStyled } from './Header.styled';

const timeFormat = 'D MMM YYYY';

interface IHeaderComponentProps {
  companies: any
  selectedCompany?: ShippingPartyDTM
  setActiveCompany: (id: number) => void
  setDueDate: (date: string) => void
  dueDate: Moment
  isLoading: boolean
  selectedCompanyError: boolean
  clearSelectedCompanyError: () => void
  modalOwner: string
  onChangeReference: (value: string) => void
  setBilledDate: (date: string) => void
  shipmentName?: string;
}

const HeaderComponent: FC<IHeaderComponentProps> = ({
  companies, selectedCompany, setActiveCompany, setDueDate, dueDate, isLoading,
  selectedCompanyError, clearSelectedCompanyError, modalOwner, onChangeReference, setBilledDate, shipmentName,
}) => {
  const { t } = useTranslation();

  const checkDate = useCallback((current) => current && current < moment().endOf('day'), []);
  const pasteDate = useCallback((current) => current && current > moment().endOf('day'), []);
  const onChangeCompany = useCallback((id) => setActiveCompany(id), [setActiveCompany]);
  const onChangeDate = useCallback((date) => setDueDate(date), []);
  const onChangeBilledDate = useCallback((date) => setBilledDate(date), []);
  const handleOnChangeReference = useCallback((e) => onChangeReference(e.target.value), []);

  return (
    <Wrapper>

      <CardWrapper>
        <CardsTitle>{t('shipment')}</CardsTitle>
        {isLoading ? (
          <Skeleton paragraph={{ rows: 1 }} />
        ) : (
          <>
            <Select
              defaultValue={shipmentName}
              style={{
                width: '100%', marginBottom: '8px', marginTop: '4px', fontSize: '12px',
              }}
              disabled
            />
            <CardsTitle>{t(modalOwner === CREATE_AP_INVOICE ? 'vendor' : 'customer')}</CardsTitle>
            <Select
              style={{ width: '100%', fontSize: '12px' }}
              options={companies}
              defaultValue={selectedCompany?.company?.name}
              onChange={onChangeCompany}
              error={selectedCompanyError}
              onFocus={clearSelectedCompanyError}
            />
          </>
        )}
      </CardWrapper>

      <CardWrapper>
        <CardsTitle>{t('billTo')}</CardsTitle>
        {isLoading ? (
          <Skeleton active paragraph={{ rows: 1 }} />
        ) : (
          <>
            <CardsValue>{selectedCompany?.address?.address1}</CardsValue>
            <CardsValue>{selectedCompany?.address?.address2}</CardsValue>
            <CardsValue>{selectedCompany?.address?.city} {selectedCompany?.address?.state}</CardsValue>
            <CardsValue>{selectedCompany?.address?.postalCode} {selectedCompany?.address?.country}</CardsValue>
          </>
        )}
      </CardWrapper>

      <CardWrapper>
        {isLoading ? (
          <Skeleton active paragraph={{ rows: 1 }} />
        ) : (
          <>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              {modalOwner === CREATE_AP_INVOICE ? (
                <div style={{ width: '48%' }}>
                  <CardsTitle>{t('billedDate')}</CardsTitle>
                  <DatePickerStyled>
                    <DatePicker
                      defaultValue={moment(new Date())}
                      format={timeFormat}
                      disabledDate={pasteDate}
                      onChange={onChangeBilledDate}
                      style={{ width: '100%', fontSize: '12px' }}
                      allowClear={false}
                    />
                  </DatePickerStyled>
                </div>
              ) : (
                <div>
                  <CardsTitle>{t('billedDate')}</CardsTitle>
                  <CardsValue style={{ marginTop: '5px' }}>{moment(new Date()).format(timeFormat)}</CardsValue>
                </div>
              )}
              <div style={{ width: '50%' }}>
                <CardsTitle>{t('dueDate')}</CardsTitle>
                <DatePickerStyled>
                  <DatePicker
                    defaultValue={moment(dueDate)}
                    format={timeFormat}
                    disabledDate={checkDate}
                    onChange={onChangeDate}
                    style={{ width: '100%', fontSize: '12px' }}
                    allowClear={false}
                  />
                </DatePickerStyled>
              </div>
            </div>
            <Divider style={{ margin: `${modalOwner === CREATE_AP_INVOICE ? '8px 0' : '10px 0'}` }} />
            <>
              <CardsTitle>{t('payment.receivedReference')}</CardsTitle>
              <Input
                size="small"
                style={{ height: '32px', minHeight: '32px' }}
                onChange={handleOnChangeReference}
                maxLength={16}
              />
            </>
          </>
        )}
      </CardWrapper>

    </Wrapper>
  );
};

export { HeaderComponent };
