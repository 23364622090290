import styled from 'styled-components';
import AntAlert from 'antd/es/alert';

import { Container } from 'shipment-operations/view/components';

export const Alert = styled(AntAlert)`
  border: ${({ theme }) => `1px solid ${theme.themesColors.primaryBranding3}`}
`;
export const NotAvailableWrapper = styled(Container).attrs({
  'data-class': 'NotAvailableWrapper',
})`
  overflow-y: auto;
  padding: 20px 10px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  
  span {
    font-weight: bold;
  }
`;
