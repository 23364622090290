import React, { FC } from 'react';
import { ButtonProps } from 'antd/es/button';

import { SectionButton } from './SectionButton.styled';

interface ISectionButtonProps extends ButtonProps{}

const SectionButtonComponent: FC<ISectionButtonProps> = ({
  ...props
}) => (
  <SectionButton
    type="default"
    {...props}
  />
);

export { SectionButtonComponent as SectionButton };
