import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'app-wrapper/createSlice';

import { IVGMTaskDrawerState } from 'app-wrapper/models/states';
import { DateDtm } from 'app-wrapper/models/dtm';
import { ShipmentPreviewDTM, ContainerDTM } from 'shipment-operations/models/dtm';

const initialState: IVGMTaskDrawerState = {
  isLoading: false,
  isDrawerOpened: false,
  shipment: undefined,
  containers: [],
  markedContainers: [],
  dueDate: null,
};

export const VGMTaskDrawerStore = createSlice({
  name: 'vgmTaskDrawer',
  initialState,
  reducers: {
    setIsLoading: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      isLoading: payload,
    }),
    setShipment: (state, { payload }: PayloadAction<ShipmentPreviewDTM | undefined>) => ({
      ...state,
      shipment: payload,
    }),
    setIsDrawerOpened: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      isDrawerOpened: payload,
    }),
    setDueDate: (state, { payload }: PayloadAction<DateDtm | null>) => ({
      ...state,
      dueDate: payload,
    }),
    setContainers: (state, { payload }: PayloadAction<ContainerDTM[]>) => ({
      ...state,
      containers: payload,
    }),
    setMarkedContainers: (state, { payload }: PayloadAction<string[]>) => ({
      ...state,
      markedContainers: [...payload],
    }),
  },
});

export const vgmTaskDrawerReducer = VGMTaskDrawerStore.reducer;
export const vgmTaskDrawerActions = VGMTaskDrawerStore.actions;
