import { plainToInstance } from 'class-transformer';
import {
  IsNumber, IsOptional, IsString, validateSync,
} from 'class-validator';

export interface IPreventPromiseExecutionError {
  message?: string
  status?: number
  details?: string[]
  code?: number
}

export class PreventPromiseExecutionError {
  @IsOptional()
  @IsString()
  message?: string;

  @IsOptional()
  @IsNumber()
  status?: number;

  @IsOptional()
  @IsString({ each: true })
  details?: string[];

  @IsOptional()
  @IsNumber()
  code?: number;

  static fromPlain(dataState: IPreventPromiseExecutionError) {
    return plainToInstance(this, dataState);
  }

  isValid() {
    return validateSync(this).length === 0;
  }

  validate() {
    return validateSync(this);
  }

  getErrorMessage() {
    return this.message || this.details?.[0] || '';
  }
}
