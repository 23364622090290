import React, { FC, memo, useMemo } from 'react';

import { useSelector } from 'react-redux';
import { R } from 'shipment-operations/repository';
import { UC } from 'shipment-operations/controllers';
import { R as userManagementR } from 'user-management/repository';
import { EOrganizationMemberRole } from 'user-management/constants';

import {
  SBLBillOfLadingInformation,
  SBLCarrierInformation,
  SBLChargesTable,
  SBLRoutingInformation,
  SBLCommentsInformation,
  SBLCompanyInformation,
  SBLPaymentTerms,
  SBLScaffold,
  SBLShippingParties,
  SBLTransportInformation,
  SBLReferencesInformation,
  SBLCargoTables,
  SBLClausesInformation,
} from '..';

export const SBLScaffoldContainer: FC = memo(() => {
  const isFreighted = useSelector(R.selectors.billOfLadingCommon.getIsFreightedView);
  const isHBLPage = useSelector(R.selectors.billOfLadingCommon.getIsHBLPage);
  const organization = useSelector(userManagementR.selectors.userOrganizationData.getUserOrganization);
  const isDestinationPartner = useSelector(R.selectors.shipment.getIsDestinationPartner);

  const isCustomerOrg = useMemo(() => !!(organization && organization.role === EOrganizationMemberRole.CUSTOMER), [organization]);
  const isChargesSectionHidden = useMemo(() => (!isFreighted && isHBLPage) || !isHBLPage, [isFreighted, isHBLPage]);

  return (
    <SBLScaffold
      showClauses={!isHBLPage && !isCustomerOrg && !isDestinationPartner}
      clearError={UC.billOfLading.clearShipmentInstructionsError}
      companyBody={<SBLCompanyInformation />}
      carrierBody={<SBLCarrierInformation />}
      transportBody={<SBLTransportInformation />}
      billInstructionBody={<SBLBillOfLadingInformation />}
      paymentsBody={<SBLPaymentTerms />}
      shippingPartiesBody={<SBLShippingParties />}
      routingBody={<SBLRoutingInformation />}
      referencesBody={<SBLReferencesInformation />}
      cargoTables={<SBLCargoTables />}
      clausesBody={<SBLClausesInformation isHBLPage={isHBLPage} />}
      commentsBody={!isHBLPage && !isCustomerOrg && !isDestinationPartner ? <SBLCommentsInformation /> : null}
      chargesBody={isChargesSectionHidden ? null : <SBLChargesTable />}
    />
  );
});
