import styled from 'styled-components';
import Typography from 'antd/es/typography';
import { InfoCircleFilled, EditOutlined } from '@ant-design/icons';

import { divFlex } from 'app-wrapper/view/themes/mixin';
import { NoDataStub } from 'shipment-operations/view/components';

export const SBLShippingParties = styled('div').attrs({
  'data-class': 'SBLShippingParties',
})`
  width: 100%;
  ${divFlex}
  flex-direction: column;
`;

export const ShippingPartiesRow = styled.div.attrs({
  'data-class': 'ShippingPartiesRow',
})`
  margin-top: 8px;
  display: flex;
  flex-direction: row;
`;

export const SBLShippingPartiesTitle = styled(Typography)`
  width: 100%;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
`;

export const SBLShippingPartiesItem = styled('div').attrs({
  'data-class': 'SBLShippingPartiesItem',
})`
  ${divFlex}
  flex-direction: column;
  padding-right: 20px;
  width: 24%;

  &:not(&:first-of-type) {
    margin-left: 10px;
  }
`;

export const SBLShippingPartiesItemTitle = styled(Typography).attrs({
  'data-class': 'SBLShippingPartiesItemTitle',
})`
  display: flex;
  width: 100%;
  font-size: 12px;
  line-height: 20px;
  color: ${({ theme }) => theme.themesColors.secondaryDot45}
`;

export const SBLShippingPartiesItemText = styled(Typography).attrs({
  'data-class': 'SBLShippingPartiesItemText',
})`
  font-size: 14px;
  line-height: 20px;
  white-space: pre-line;
`;

export const SBLWarningIcon = styled(InfoCircleFilled).attrs({
  'data-class': 'SBLWarningIcon',
})`
  color: ${({ theme }) => theme.themesColors.characterBrandingWarning};
  margin-left: 4px;
`;

export const SBLNoDataStub = styled(NoDataStub).attrs({
  'data-class': 'SBLNoDataStub',
})``;

export const EditIcon = styled(EditOutlined)`
  margin-left: auto;
  cursor: pointer;
  font-size: 14px;
  margin-right: 10px;
`;
