import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import Checkbox from 'antd/es/checkbox';

import { PrintNRAAgreementRFQ } from 'shipment-operations/view/pages/ShipmentDocumentsAll/components/DocumentsSection/components/ActionMenu/components/PrintNRAAgreementRFQ';

import {
  Wrap,
  Image,
  Content,
  Title,
  Text,
  Agree,
  AgreeText,
  Checked,
} from './NRAAgreement.styled';
import illustrationSrc from './assets/illustration.png';

interface NRAAgreementComponentProps {
  isChecked: boolean;
  onChange: (value: boolean) => void;
}

export const NRAAgreementComponent: FC<NRAAgreementComponentProps> = ({ isChecked, onChange }) => {
  const { t } = useTranslation();

  const handleChange = useCallback((e) => {
    onChange(e.target.checked);
  }, [onChange]);

  return (
    <Wrap>
      <Image src={illustrationSrc} />

      <Content>
        <Title>
          {t('Negotiated Rate Arrangement (NRA)')}
        </Title>

        <Text>
          {t('NRA is a formalized record overseen by the Federal Maritime Commission (FMC) to guarantee the pre-approval and documentation of all sea freight rates prior to cargo loading. This instrument empowers Skypace to comply with FMC stipulations while safeguarding the confidentiality of our clients\' trade data.')}
        </Text>

        <Agree>
          {isChecked ? (
            <Checked />
          ) : null}

          <Checkbox checked={isChecked} onChange={handleChange} />

          <AgreeText>
            {t('I agree with')}
          </AgreeText>

          <PrintNRAAgreementRFQ showAsLink />

          <AgreeText>
            {t('for this Shipment *')}
          </AgreeText>
        </Agree>
      </Content>
    </Wrap>
  );
};
