import { RootState } from 'app-wrapper/store';
import { CompanyAddressDTM, CompanyContactDTM, ContactBookDefaultStateCompanyListStateDTM } from 'user-management/models/dtm';

const getContactBook = (state: RootState) => (
  state.contactBook
);

const getContactBookDefault = (state: RootState) => (
  state.contactBook.defaultState
);

const getContactBookDefaultCompanyPrimaryStateAddressById = (addressId: string) => (state: RootState) => (
  state.contactBook.defaultState.companyListAddresses?.filter((item) => item.customId === addressId)
);

const getContactBookDefaultCompanyListAddressesByIds = (ids: string[]) => (state: RootState) => (
  state.contactBook.defaultState.companyListAddresses.filter((item) => ids.includes(item.customId || '')).map((item) => CompanyAddressDTM.fromPlain(item))
);

const getContactBookDefaultCompanyListContactPersonsByIds = (ids: string[]) => (state: RootState) => (
  state.contactBook.defaultState.companyListContactPersons.filter((item) => ids.includes(item?.customId || '')).map((item) => CompanyContactDTM.fromPlain(item))
);

const getContactBookDefaultCompanyListByIds = (id: string) => (state: RootState) => (
  state.contactBook.defaultState.companyList.filter((item) => id === item.customId)?.[0]
);

const getDefaultCompanyPrimaryState = (state: RootState) => (
  state.contactBook.defaultState.companyPrimaryState && ContactBookDefaultStateCompanyListStateDTM.fromPlain(state.contactBook.defaultState.companyPrimaryState)
);

const getContactBookUpdate = (state: RootState) => (
  state.contactBook.updateState
);

const getContactBookUpdateCompanyListVisible = (state: RootState) => (
  state.contactBook.updateState.companyList?.filter((item) => !item.isHide)?.map((item) => ContactBookDefaultStateCompanyListStateDTM.fromPlain(item))
);

const getContactBookUpdateCompanyListAddresses = (state: RootState) => (
  state.contactBook.updateState.companyListAddresses?.map((item) => CompanyAddressDTM.fromPlain(item))
);

const getContactBookUpdateCompanyListContactPersons = (state: RootState) => (
  state.contactBook.updateState.companyListContactPersons
);

const getContactBookUpdateCompanyListByIds = (id: string) => (state: RootState) => (
  state.contactBook.updateState.companyList.filter((item) => id === item.customId)?.[0]
);

const getContactBookUpdateCompanyListAddressesByIds = (ids: string[]) => (state: RootState) => (
  state.contactBook.updateState.companyListAddresses.filter((item) => ids.includes(item?.customId || '')).map((item) => CompanyAddressDTM.fromPlain(item))
);

const getContactBookUpdateCompanyListContactPersonsByIds = (ids: string[]) => (state: RootState) => (
  state.contactBook.updateState.companyListContactPersons.filter((item) => ids.includes(item?.customId || '')).map((item) => CompanyContactDTM.fromPlain(item))
);

const getContactBookUpdateErrorsCompanyListStateById = (id: string) => (state: RootState) => (
  state.contactBook.updateState.errors.companyListState.filter((item) => id === item.customId)?.[0]
);

const getContactBookUpdateErrorsEditCompanyDetailsDrawer = (state: RootState) => (
  state.contactBook.updateState.errorsEditCompanyDetailsDrawer
);

const getContactBookUpdateErrorsAddressesById = (id: string) => (state: RootState) => (
  state.contactBook.updateState.errors.addresses.filter((item) => id === item.customId)?.[0]
);

const getContactBookUpdateErrorsContactPersonsById = (id: string) => (state: RootState) => (
  state.contactBook.updateState.errors.contactPersons.filter((item) => id === item.customId)?.[0]
);

export const contactBookSelectors = {
  getContactBook,
  getContactBookDefault,
  getContactBookDefaultCompanyListAddressesByIds,
  getContactBookDefaultCompanyListContactPersonsByIds,
  getContactBookDefaultCompanyListByIds,
  getContactBookDefaultCompanyPrimaryStateAddressById,
  getDefaultCompanyPrimaryState,
  getContactBookUpdate,
  getContactBookUpdateCompanyListVisible,
  getContactBookUpdateCompanyListAddresses,
  getContactBookUpdateCompanyListContactPersons,
  getContactBookUpdateCompanyListByIds,
  getContactBookUpdateCompanyListAddressesByIds,
  getContactBookUpdateCompanyListContactPersonsByIds,
  getContactBookUpdateErrorsCompanyListStateById,
  getContactBookUpdateErrorsEditCompanyDetailsDrawer,
  getContactBookUpdateErrorsContactPersonsById,
  getContactBookUpdateErrorsAddressesById,
};
