import React, { FC, memo } from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';
import { UC } from 'shipment-operations/controllers';

import { ShipmentContainerNumberSelectFieldComponent } from './ShipmentContainerNumberSelectField.component';

const ShipmentContainerNumberSelectFieldContainer: FC = memo(() => {
  const list = useSelector(R.selectors.shipmentContainers.getContainerNumberListForSelectedContainer);
  const number = useSelector(R.selectors.shipmentContainers.getSelectedContainerNumber);
  const isUpdating = useSelector(R.selectors.shipmentContainers.getUpdateLoadingState);
  const permissions = useSelector(R.selectors.shipment.getContainersPermissions);
  const containerNumberManual = useSelector(R.selectors.shipmentContainers.getContainerNumberManual);
  const error = useSelector(R.selectors.shipmentContainers.getContainerNumberManualError);

  return (
    <ShipmentContainerNumberSelectFieldComponent
      containerNumber={number}
      containerNumberManual={containerNumberManual}
      containerNumberManualError={error}
      containerNumberList={list}
      disabled={isUpdating}
      setContainerNumberManualError={UC.shipmentContainers.setContainerNumberManualError}
      onChange={UC.shipmentContainers.updateContainerNumberSelectVolume}
      onChangeContainerManualNumber={UC.shipmentContainers.onChangeContainerNumberManual}
      onAddContainerNumber={UC.shipmentContainers.onAddContainerNumberManual}
      containersAvailability={permissions.containersAvailability}
    />
  );
});

export {
  ShipmentContainerNumberSelectFieldContainer as ShipmentContainerNumberSelectField,
};
