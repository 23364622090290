import React, { FC, ReactNode } from 'react';

import {
  Warning,
  WarningIcon,
  WarningText,
  Content,
  WarningTitle,
} from './WarningBlock.styled';

interface WarningBlockProps {
  title: string;
  text?: string;
  content?: ReactNode;
}

export const WarningBlock: FC<WarningBlockProps> = ({ text, title, content }) => (
  <Warning>
    <WarningIcon />

    <Content>
      <WarningTitle>
        {title}
      </WarningTitle>

      {content}

      {text ? (
        <WarningText>
          {text}
        </WarningText>
      ) : null}
    </Content>
  </Warning>
);
