import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';
import { UC } from 'shipment-operations/controllers';

import { VoyageInputComponent } from './VoyageInput.component';

interface VesselInputContainerProps {
  id: number;
}

const VoyageInputContainer: FC<VesselInputContainerProps> = ({ id }) => {
  const transportation = useSelector(R.selectors.manualBookingWizard.getTransportationById(id));

  return (
    <VoyageInputComponent
      onChange={UC.manualBookingWizard.setVoyageNumber}
      transportation={transportation}
    />
  );
};

export { VoyageInputContainer as VoyageInput };
