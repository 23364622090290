import React from 'react';
import { useSelector } from 'react-redux';

import { R } from 'user-management/repository';
import { UC } from 'user-management/controllers';

import { CustomerDocumentsComponent } from './CustomerDocuments.component';

const CustomerDocumentsContainer = () => {
  const isLoading = useSelector(R.selectors.customer.getLoading);

  return (
    <CustomerDocumentsComponent
      isLoading={isLoading}
      initData={UC.customer.initData}
    />
  );
};

export { CustomerDocumentsContainer as CustomerDocuments };
