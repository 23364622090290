import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { AccountDrawer } from 'user-management/view/pages/Account/components/AccountDrawer';

import {
  Wrap,
  Content,
  Image,
  Title,
  Text,
} from './InviteMembers.styled';
import illustrationSrc from './assets/illustration.png';
import { StepManagerButton } from '../../StepsManager.styled';

interface IInviteMembersComponentProps {
  openUserInviteDrawer: () => void;
}

export const InviteMembersComponent: FC<IInviteMembersComponentProps> = ({ openUserInviteDrawer }) => {
  const { t } = useTranslation();

  const goInviteMembers = useCallback(() => {
    openUserInviteDrawer();
  }, [openUserInviteDrawer]);

  return (
    <Wrap>
      <AccountDrawer />

      <Content>
        <Title>
          {t('Bring your Team onboard')}
        </Title>

        <Text>
          {t('Send invites to other people to join Skypace.')}
        </Text>

        <StepManagerButton onClick={goInviteMembers}>
          {t('Invite')}
        </StepManagerButton>
      </Content>

      <Image src={illustrationSrc} />
    </Wrap>
  );
};
