import React, { FC, memo } from 'react';
import { useSelector } from 'react-redux';

import { R } from 'user-management/repository';
import { UC } from 'user-management/controllers';

import { EditProfileOrganizationComponent } from './EditProfileOrganization.component';

const EditProfileOrganizationContainer: FC = () => {
  const organizationUpdate = useSelector(R.selectors.userOrganizationData.getUserOrganizationUpdate);
  const organizationFieldsErrors = useSelector(R.selectors.userOrganizationData.organizationFieldsErrors);
  const organizationCountries = useSelector(R.selectors.userOrganizationData.organizationCountriesLimited);
  const userManagementPermissions = useSelector(R.selectors.moduleFunctionalityPermissions.getUserManagementPermissions);

  const organizationEditProfileCountry = userManagementPermissions?.organizationEditProfileCountry;

  return (
    <EditProfileOrganizationComponent
      organization={organizationUpdate}
      organizationEditProfileCountry={organizationEditProfileCountry}
      organizationFieldsErrors={organizationFieldsErrors}
      countriesList={organizationCountries}
      closeEditProfileOrganizationDrawer={UC.organizationProfile.closeEditProfileOrganizationDrawer}
      saveEditProfileOrganization={UC.organizationProfile.saveEditProfileOrganization}
      onChangeUpdateAddress1={UC.organizationProfile.onChangeUpdateAddress1}
      onChangeUpdateAddress2={UC.organizationProfile.onChangeUpdateAddress2}
      onChangeUpdateAddressCity={UC.organizationProfile.onChangeUpdateAddressCity}
      onChangeUpdateAddressPostalCode={UC.organizationProfile.onChangeUpdateAddressPostalCode}
      onChangeUpdateAddressCountry={UC.organizationProfile.onChangeUpdateAddressCountry}
      onChangeUpdatePhone={UC.organizationProfile.onChangeUpdatePhone}
      onFocusUpdatePhone={UC.organizationProfile.onFocusUpdatePhone}
      onChangeUpdatePhone2={UC.organizationProfile.onChangeUpdatePhone2}
      onChangeUpdateEmail={UC.organizationProfile.onChangeUpdateEmail}
      onChangeUpdateWebUrl={UC.organizationProfile.onChangeUpdateWebUrl}
      onBlurUpdateAddress1={UC.organizationProfile.onBlurUpdateAddress1}
      onBlurUpdateAddress2={UC.organizationProfile.onBlurUpdateAddress2}
      onBlurUpdateAddressCity={UC.organizationProfile.onBlurUpdateAddressCity}
      onBlurUpdateAddressPostalCode={UC.organizationProfile.onBlurUpdateAddressPostalCode}
      onBlurUpdateAddressCountry={UC.organizationProfile.onBlurUpdateAddressCountry}
      onClearUpdateAddressCountry={UC.organizationProfile.onClearUpdateAddressCountry}
      onBlurUpdatePhone={UC.organizationProfile.onBlurUpdatePhone}
      onBlurUpdatePhone2={UC.organizationProfile.onBlurUpdatePhone2}
      onFocusUpdatePhone2={UC.organizationProfile.onFocusUpdatePhone2}
      onBlurUpdateEmail={UC.organizationProfile.onBlurUpdateEmail}
      onFocusUpdateEmail={UC.organizationProfile.onFocusUpdateEmail}
      onBlurUpdateWebUrl={UC.organizationProfile.onBlurUpdateWebUrl}
      onFocusUpdateWebUrl={UC.organizationProfile.onFocusUpdateWebUrl}
      onChangeUpdatePrimaryName={UC.organizationProfile.onChangeUpdatePrimaryName}
      onBlurUpdatePrimaryName={UC.organizationProfile.onBlurUpdatePrimaryName}
      onChangeUpdatePrimaryEmail={UC.organizationProfile.onChangeUpdatePrimaryEmail}
      onBlurUpdatePrimaryEmail={UC.organizationProfile.onBlurUpdatePrimaryEmail}
      onFocusUpdatePrimaryEmail={UC.organizationProfile.onFocusUpdatePrimaryEmail}
      onChangeUpdatePrimaryPhone={UC.organizationProfile.onChangeUpdatePrimaryPhone}
      onFocusUpdatePrimaryPhone={UC.organizationProfile.onFocusUpdatePrimaryPhone}
      onBlurUpdatePrimaryPhone={UC.organizationProfile.onBlurUpdatePrimaryPhone}
      onChangeUpdatePrimaryPhone2={UC.organizationProfile.onChangeUpdatePrimaryPhone2}
      onBlurUpdatePrimaryPhone2={UC.organizationProfile.onBlurUpdatePrimaryPhone2}
      onFocusUpdatePrimaryPhone2={UC.organizationProfile.onFocusUpdatePrimaryPhone2}
    />
  );
};

const EditProfileOrganizationContainerCache = memo(EditProfileOrganizationContainer);

export { EditProfileOrganizationContainerCache as EditProfileOrganization };
