import React from 'react';

import { useAppSelector } from 'app-wrapper/hooks';
import { R } from 'shipment-operations/repository';

import { CreditNotesComponent } from './CreditNotes.component';

const CreditNotesContainer = () => {
  const linkedCreditNotes = useAppSelector(R.selectors.shipmentBillingInvoice.preparedCreditNotesForTable);
  const isLoading = useAppSelector(R.selectors.shipmentBillingInvoice.getLinkedCreditNotesLoading);
  const isError = useAppSelector(R.selectors.shipmentBillingInvoice.getLinkedCreditNotesError);

  return (
    <CreditNotesComponent
      linkedCreditNotes={linkedCreditNotes}
      isLoading={isLoading}
      isError={isError}
    />
  );
};

export { CreditNotesContainer as CreditNotes };
