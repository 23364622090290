import React, { FC } from 'react';
import Divider from 'antd/es/divider';
import { useTranslation } from 'react-i18next';
import isEmpty from 'lodash/fp/isEmpty';

import { ContainerViewDTM, IGroupedInvoicesChargesDTM } from 'shipment-operations/models/dtm';

import {
  CustomTableEditable,
} from 'shipment-operations/view/pages/CreateCreditNote/components/SecondStep/components';

import {
  NotesInnerContainer, Title, HeaderTableWrapper,
  ExpandPanelComponent,
} from 'shipment-operations/view/components';

interface ITransportationComponent {
  containers: ContainerViewDTM[]
  data: IGroupedInvoicesChargesDTM
}

const TransportationComponent: FC<ITransportationComponent> = ({
  data, containers,
}) => {
  const { t } = useTranslation();

  if ((isEmpty(data) && isEmpty(containers))) {
    return null;
  }

  return (
    <NotesInnerContainer>
      <Title>{t('transportation')}</Title>
      <Divider style={{ margin: '0' }} />
      <HeaderTableWrapper>
        <span>{t('Containers')}</span>
      </HeaderTableWrapper>
      <Divider style={{ margin: '0' }} />
      {containers.map((item) => (
        <ExpandPanelComponent
          container={item}
          key={item.id}
        >
          <CustomTableEditable data={data[item.id]} />
        </ExpandPanelComponent>
      ))}
    </NotesInnerContainer>
  );
};

export { TransportationComponent };
