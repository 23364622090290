import { v4 as uuidv4 } from 'uuid';

import { apiWorker } from 'app-wrapper/repository/utilsServices';

import {
  AccountDepartmentDTM,
  OrganizationMemberDTM,
} from 'user-management/models/dtm';
import {
  TGetOrganizationDepartmentsRequestParamsContract,
  TGetOrganizationDepartmentsResponseContract,
} from 'user-management/models/contracts';
import { AccountDepartmentTypeArraySort, EOrganizationMemberRole } from 'user-management/constants';

export class AccountDepartmentService {
  urlBase = '/organization-service/api/v1/organizations'

  public getOrganizationDepartments = async (params: TGetOrganizationDepartmentsRequestParamsContract) => {
    const {
      organizationId,
    } = params;

    const accountDepartmentService: AccountDepartmentDTM[] = [];

    if (!organizationId) {
      return accountDepartmentService;
    }

    const rawResponse = await apiWorker.requestGet<TGetOrganizationDepartmentsResponseContract[]>(`${this.urlBase}/${organizationId}/departments`, {});

    const response = rawResponse.data;

    response?.forEach((item) => {
      const department = AccountDepartmentDTM.fromPlain({
        id: item?.id,
        customId: uuidv4(),
        type: item?.type,
        email: item?.email,
        phone: item?.phone,
        createdBy: item?.createdBy,
        createdAt: item?.createdAt,
        membersCount: item?.membersCount,
        members: item?.members?.map((itemMember) => OrganizationMemberDTM.fromPlain({
          id: itemMember?.id,
          customId: uuidv4(),
          email: itemMember.email,
          role: itemMember.role as EOrganizationMemberRole,
          status: itemMember?.status,
          sub: itemMember?.sub,
          enabled: itemMember?.enabled,
          primary: itemMember?.primary,
          firstName: itemMember?.firstName,
          lastName: itemMember?.lastName,
          phone: itemMember?.phone,
          phone2: itemMember?.phone2,
          onboarded: itemMember?.onboarded,
          invitedBy: itemMember?.invitedBy,
          createdAt: itemMember?.createdAt,
          // TODO: https://frtunes.atlassian.net/browse/FRTUN-3452
          departments: itemMember?.departments?.map((itemDepartment) => AccountDepartmentDTM.fromPlain({
            ...itemDepartment,
          })) || [],
          // Check props
          teams: [],
        })),
      });

      if (!department?.isValid()) {
        console.error(`AccountDepartmentDTM validation errors (getOrganizationDepartments): ${department.validate().join(', ')}`, department.validate());
      }

      accountDepartmentService.push(department);
    });

    return accountDepartmentService?.sort((a, b) => (AccountDepartmentTypeArraySort.indexOf(a?.type || '') - AccountDepartmentTypeArraySort.indexOf(b?.type || '')));
  }

  public getOrganizationDepartmentsById = async (params: TGetOrganizationDepartmentsRequestParamsContract) => {
    const {
      organizationId,
      departmentId,
    } = params;

    let accountDepartmentService: AccountDepartmentDTM | undefined;

    if (!organizationId) {
      return accountDepartmentService;
    }

    const rawResponse = await apiWorker.requestGet<TGetOrganizationDepartmentsResponseContract>(`${this.urlBase}/${organizationId}/departments/${departmentId}`, {});

    const response = rawResponse.data;

    accountDepartmentService = response ? AccountDepartmentDTM.fromPlain({
      id: response?.id,
      customId: uuidv4(),
      type: response?.type,
      email: response?.email,
      phone: response?.phone,
      createdBy: response?.createdBy,
      createdAt: response?.createdAt,
      membersCount: response?.membersCount,
      members: response?.members?.map((item) => OrganizationMemberDTM.fromPlain({
        id: item?.id,
        customId: uuidv4(),
        email: item.email,
        role: item.role as EOrganizationMemberRole,
        status: item?.status,
        sub: item?.sub,
        enabled: item?.enabled,
        primary: item?.primary,
        firstName: item?.firstName,
        lastName: item?.lastName,
        phone: item?.phone,
        phone2: item?.phone2,
        onboarded: item?.onboarded,
        invitedBy: item?.invitedBy,
        createdAt: item?.createdAt,
        // TODO: https://frtunes.atlassian.net/browse/FRTUN-3452
        departments: item?.departments?.map((itemDepartment) => AccountDepartmentDTM.fromPlain({
          ...itemDepartment,
        })) || [],
        // Check props
        teams: [],
      })),
    }) : undefined;
    if (accountDepartmentService && !accountDepartmentService?.isValid()) {
      console.error(`AccountDepartmentDTM validation errors (getOrganizationDepartments): ${accountDepartmentService.validate().join(', ')}`, accountDepartmentService.validate());
    }

    return accountDepartmentService;
  }

  public deleteOrganizationDepartmentsById = async (params: TGetOrganizationDepartmentsRequestParamsContract) => {
    const {
      organizationId,
      departmentId,
    } = params;

    if (!organizationId) {
      return;
    }

    await apiWorker.requestDelete<TGetOrganizationDepartmentsResponseContract>(`${this.urlBase}/${organizationId}/departments/${departmentId}`, {});
  }

  public postOrganizationDepartments = async (params: TGetOrganizationDepartmentsRequestParamsContract) => {
    const {
      organizationId,
      departments,
    } = params;
    let accountDepartmentService: AccountDepartmentDTM | undefined;

    if (!organizationId) {
      return accountDepartmentService;
    }

    const rawResponse = await apiWorker.requestPost<TGetOrganizationDepartmentsResponseContract>(`${this.urlBase}/${organizationId}/departments`, departments);

    const response = rawResponse.data;

    accountDepartmentService = response ? AccountDepartmentDTM.fromPlain({
      id: response?.id,
      customId: uuidv4(),
      type: response?.type,
      email: response?.email,
      phone: response?.phone,
      createdBy: response?.createdBy,
      createdAt: response?.createdAt,
      membersCount: response?.membersCount,
      members: response?.members?.map((item) => OrganizationMemberDTM.fromPlain({
        id: item?.id,
        customId: uuidv4(),
        email: item.email,
        role: item.role as EOrganizationMemberRole,
        status: item?.status,
        sub: item?.sub,
        enabled: item?.enabled,
        primary: item?.primary,
        firstName: item?.firstName,
        lastName: item?.lastName,
        phone: item?.phone,
        phone2: item?.phone2,
        onboarded: item?.onboarded,
        invitedBy: item?.invitedBy,
        createdAt: item?.createdAt,
        // TODO: https://frtunes.atlassian.net/browse/FRTUN-3452
        departments: item?.departments?.map((itemDepartment) => AccountDepartmentDTM.fromPlain({
          ...itemDepartment,
        })) || [],
        // Check props
        teams: [],
      })),
    }) : undefined;
    if (accountDepartmentService && !accountDepartmentService?.isValid()) {
      console.error(`AccountDepartmentDTM validation errors (getOrganizationDepartments): ${accountDepartmentService.validate().join(', ')}`, accountDepartmentService.validate());
    }

    return accountDepartmentService;
  }

  public postOrganizationDepartmentsById = async (params: TGetOrganizationDepartmentsRequestParamsContract) => {
    const {
      organizationId,
      departmentId,
      departments,
    } = params;
    let accountDepartmentService: AccountDepartmentDTM | undefined;

    if (!organizationId) {
      return accountDepartmentService;
    }

    const rawResponse = await apiWorker.requestPost<TGetOrganizationDepartmentsResponseContract>(`${this.urlBase}/${organizationId}/departments/${departmentId}`, departments);

    const response = rawResponse.data;

    accountDepartmentService = response ? AccountDepartmentDTM.fromPlain({
      id: response?.id,
      customId: uuidv4(),
      type: response?.type,
      email: response?.email,
      phone: response?.phone,
      createdBy: response?.createdBy,
      createdAt: response?.createdAt,
      membersCount: response?.membersCount,
      members: response?.members?.map((item) => OrganizationMemberDTM.fromPlain({
        id: item?.id,
        customId: uuidv4(),
        email: item.email,
        role: item.role as EOrganizationMemberRole,
        status: item?.status,
        sub: item?.sub,
        enabled: item?.enabled,
        primary: item?.primary,
        firstName: item?.firstName,
        lastName: item?.lastName,
        phone: item?.phone,
        phone2: item?.phone2,
        onboarded: item?.onboarded,
        invitedBy: item?.invitedBy,
        createdAt: item?.createdAt,
        // TODO: https://frtunes.atlassian.net/browse/FRTUN-3452
        departments: item?.departments?.map((itemDepartment) => AccountDepartmentDTM.fromPlain({
          ...itemDepartment,
        })) || [],
        // Check props
        teams: [],
      })),
    }) : undefined;
    if (accountDepartmentService && !accountDepartmentService?.isValid()) {
      console.error(`AccountDepartmentDTM validation errors (getOrganizationDepartments): ${accountDepartmentService.validate().join(', ')}`, accountDepartmentService.validate());
    }

    return accountDepartmentService;
  }

  public putOrganizationDepartmentsById = async (params: TGetOrganizationDepartmentsRequestParamsContract) => {
    const {
      organizationId,
      departmentId,
      departments,
    } = params;
    let accountDepartmentService: AccountDepartmentDTM | undefined;

    if (!organizationId) {
      return accountDepartmentService;
    }

    const rawResponse = await apiWorker.requestPut<TGetOrganizationDepartmentsResponseContract>(`${this.urlBase}/${organizationId}/departments/${departmentId}`, departments);

    const response = rawResponse.data;

    accountDepartmentService = response ? AccountDepartmentDTM.fromPlain({
      id: response?.id,
      customId: uuidv4(),
      type: response?.type,
      email: response?.email,
      phone: response?.phone,
      createdBy: response?.createdBy,
      createdAt: response?.createdAt,
      membersCount: response?.membersCount,
      members: response?.members?.map((item) => OrganizationMemberDTM.fromPlain({
        id: item?.id,
        customId: uuidv4(),
        email: item.email,
        role: item.role as EOrganizationMemberRole,
        status: item?.status,
        sub: item?.sub,
        enabled: item?.enabled,
        primary: item?.primary,
        firstName: item?.firstName,
        lastName: item?.lastName,
        phone: item?.phone,
        phone2: item?.phone2,
        onboarded: item?.onboarded,
        invitedBy: item?.invitedBy,
        createdAt: item?.createdAt,
        // TODO: https://frtunes.atlassian.net/browse/FRTUN-3452
        departments: item?.departments?.map((itemDepartment) => AccountDepartmentDTM.fromPlain({
          ...itemDepartment,
        })) || [],
        // Check props
        teams: [],
      })),
    }) : undefined;
    if (accountDepartmentService && !accountDepartmentService?.isValid()) {
      console.error(`AccountDepartmentDTM validation errors (getOrganizationDepartments): ${accountDepartmentService.validate().join(', ')}`, accountDepartmentService.validate());
    }

    return accountDepartmentService;
  }

  public deleteOrganizationDepartmentsByIdMemberByEmail = async (params: TGetOrganizationDepartmentsRequestParamsContract) => {
    const {
      organizationId,
      departmentId,
      memberEmail,
    } = params;

    if (!organizationId) {
      return;
    }

    await apiWorker.requestDelete<TGetOrganizationDepartmentsResponseContract>(`${this.urlBase}/${organizationId}/departments/${departmentId}/members/${memberEmail}`, {});
  }
}
