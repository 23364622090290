import React, {
  useEffect, FC, useCallback,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import Alert from 'antd/es/alert';

import { CreateCreditNote, MakePayment } from 'shipment-operations/view/pages';
import {
  Header, CardsWrapper, Transportation, Footer, Additional, Fees, Services, Buttons,
} from 'shipment-operations/view/pages/ShipmentBillingInvoice/components';
import { Container } from 'shipment-operations/view/components';
import { PAYABLES } from 'shipment-operations/constants';
import { AlertBlock } from 'app-wrapper/view/components';
import { Wrapper, FlexRow } from 'app-wrapper/view/guideline';
import { SkeletonSection } from 'app-wrapper/view/components/Skeleton/Skeleton.component';
import {
  AlertWrapper,
  StyledButton,
  SkeletonWrap,
} from 'shipment-operations/view/pages/ShipmentBillingInvoice/ShipmentBillingInvoice.styled';

interface IShipmentBillingInvoiceProps {
  fetchData: (shipmentId?: string, invoiceId?: string, invoiceType?: string) => void
  error: boolean
  isLoading: boolean
  createCreditNoteSuccess: boolean
  onResetCreditNote: () => void
  isOpenCreditNote: boolean
  isOpenPayment: boolean
  createPaymentSuccess: boolean
  closePaymentSuccess: () => void
  makePaymentButtonAvailability: boolean
}

const ShipmentBillingInvoiceComponent: FC<IShipmentBillingInvoiceProps> = ({
  fetchData, error, isLoading, createCreditNoteSuccess, onResetCreditNote,
  isOpenCreditNote, isOpenPayment, createPaymentSuccess, closePaymentSuccess,
  makePaymentButtonAvailability,
}) => {
  const { shipmentId } = useParams<'shipmentId'>();
  const { invoiceId } = useParams<'invoiceId'>();
  const { invoiceType } = useParams<'invoiceType'>();
  const { t } = useTranslation();

  const handleFetchData = useCallback(() => {
    fetchData(shipmentId, invoiceId, invoiceType);
  }, [shipmentId, invoiceId, fetchData]);

  useEffect(() => {
    fetchData(shipmentId, invoiceId, invoiceType);
  }, [fetchData, shipmentId, invoiceId]);

  useEffect(() => {
    if (createCreditNoteSuccess || createPaymentSuccess) {
      fetchData(shipmentId, invoiceId, invoiceType);
    }
  }, [createCreditNoteSuccess, createPaymentSuccess]);

  useEffect(() => () => onResetCreditNote(), []);
  useEffect(() => () => closePaymentSuccess(), []);

  const isPayables = invoiceType === PAYABLES;

  return (error ? (
    <Wrapper>
      <AlertWrapper>
        <Alert
          message={t('loadBillingInvoiceErrorMsg')}
          description={t('loadBillingInvoiceErrorDescription')}
          type="error"
          action={(
            <StyledButton size="middle" type="primary" onClick={handleFetchData}>
              {t('reload')}
            </StyledButton>
          )}
        />
      </AlertWrapper>
    </Wrapper>
  ) : (
    <>
      {isOpenCreditNote && (
        <CreateCreditNote />
      )}

      {isOpenPayment && (
        <MakePayment />
      )}

      <Wrapper>
        <Header isLoading={isLoading} isAPInvoiceType={isPayables} />

        {isLoading ? (
          <SkeletonWrap>
            <SkeletonSection height="28px" />

            <FlexRow
              style={{
                gap: 8,
              }}
            >
              <SkeletonSection height="136px" width="33%" />
              <SkeletonSection height="136px" width="33%" />
              <SkeletonSection height="136px" width="33%" />
            </FlexRow>

            <SkeletonSection height="627px" />
          </SkeletonWrap>
        ) : (
          <>
            {isPayables && !makePaymentButtonAvailability && <AlertBlock type="warning" message={t('Payment details')} />}
            <CardsWrapper />
            <Container>
              <Transportation />
              <Services />
              <Fees />
              <Additional />
              <Footer />
            </Container>
          </>
        )}
      </Wrapper>

      {(isPayables && !makePaymentButtonAvailability) ? null : <Buttons />}
    </>
  ));
};

export { ShipmentBillingInvoiceComponent };
