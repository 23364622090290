import styled from 'styled-components';
import Typography from 'antd/es/typography';
import Tag from 'antd/es/tag';

export const Wrap = styled.div`
  page-break-inside: avoid;
  padding: 24px;
`;

export const FreightAndCharges = styled.div`
  padding-bottom: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-bottom: 0.2px solid rgba(209, 215, 226, 1);
`;

export const Title = styled(Typography)`
  margin-bottom: 16px;
  font-size: 14.4px;
  line-height: 19.2px;
  font-weight: 700;
`;

export const Items = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Item = styled.div`
  min-width: 20%;
  display: flex;
  flex-direction: column;
`;

export const ItemTitleWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const ItemTitle = styled(Typography)`
  margin-right: 6px;
  font-size: 9.6px;
  line-height: 14.4px;
  font-weight: 700;
`;

export const ItemTitleTag = styled(Tag)`
  padding: 0 2px;
  font-size: 7.2px;
  line-height: 8px;
  height: fit-content;
  font-weight: 600;
  border: 0.2px solid rgba(234, 238, 248, 1);
`;

export const ItemText = styled(Typography)`
  margin-top: 4px;
  font-size: 9.6px;
  line-height: 12px;
`;

export const ItemLast = styled(Item)`
  width: 40%;
  padding-left: 12px;
  min-height: 100%;
  border-left: 0.2px solid rgba(209, 215, 226, 1);
`;
