import React, {
  FC,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, Outlet } from 'react-router';
import Popover from 'antd/es/popover';

import { UserMenu } from 'user-management/view/components';
import { RouteNames } from 'app-wrapper/constants';
import { useWindowSize } from 'app-wrapper/hooks';
import { LogoMenuSvg, SkypaceLogoHeaderSvg } from 'app-wrapper/view/icons';

import { MainMenuContainer } from 'app-wrapper/view/pages/Home/components/MainMenu';
import { useIntercom } from 'app-wrapper/hooks/useIntercom';
import { usePosthogAuth } from 'app-wrapper/hooks/usePosthogAuth';
import { DrawersContainer } from './components/Drawers';
import { AppBreadcrumb } from './components';
import {
  Layout, HomeContent, LayoutContent, Header, HeaderBadge, Sider, Spinner, SpinnerWrap, MainMenuLogo, UserSettingWrapper, HelpIcon, StyledUser,
  MainMenuLogoCollapsed, MainMenuLogoTitle, LayoutMenuBorder, UserWrapper, MenuTriggerSticky, MenuTriggerStickyContainer, HelpWrapper, StyledQuestion,
} from './Home.styled';

interface HomeComponentProps {
  isLoading: boolean;
  init: () => void;
}

// TODO: fix props for component
export const HomeComponent: FC<HomeComponentProps> = ({
  isLoading,
  init,
}) => {
  const { t } = useTranslation();
  const [isCollapsed, setCollapsed] = useState(false);
  const [visible, setVisible] = useState(false);
  const { isMediumWidth } = useWindowSize();
  const { pathname } = useLocation();
  useIntercom();
  usePosthogAuth();

  const onCollapse = useCallback(() => {
    setCollapsed(!isCollapsed);
  }, [isCollapsed]);

  const onClose = useCallback(() => setVisible(false), []);
  const onChange = useCallback((isVisible) => setVisible(isVisible), []);

  useEffect(() => {
    if (pathname.includes(`${RouteNames.SHIPMENTS()}/`) && !isMediumWidth) {
      setCollapsed(!isMediumWidth);
    }
  }, [isMediumWidth]);

  useEffect(() => {
    init();
  }, []);

  return (
    <Layout>
      <DrawersContainer />
      {isLoading ? (
        <SpinnerWrap>
          <Spinner />
        </SpinnerWrap>
      ) : null}

      <Sider
        collapsible
        collapsed={isCollapsed}
        trigger={null}
        width={213}
        collapsedWidth={47}
      >
        {!isCollapsed ? (
          <LayoutMenuBorder />
        ) : null}

        {isCollapsed ? (
          <MainMenuLogoCollapsed>
            <LogoMenuSvg />

            {!isCollapsed ? (
              <MainMenuLogoTitle>
                <SkypaceLogoHeaderSvg />
              </MainMenuLogoTitle>
            ) : null}
          </MainMenuLogoCollapsed>
        ) : (
          <MainMenuLogo>
            <LogoMenuSvg />

            {!isCollapsed ? (
              <MainMenuLogoTitle>
                <SkypaceLogoHeaderSvg />
              </MainMenuLogoTitle>
            ) : null}
          </MainMenuLogo>
        )}
        <MainMenuContainer
          isCollapsed={isCollapsed}
        />
        <MenuTriggerStickyContainer>
          <MenuTriggerSticky onClick={onCollapse} isCollapsed={isCollapsed} />
        </MenuTriggerStickyContainer>
      </Sider>
      <LayoutContent>
        <Header>
          <AppBreadcrumb />
          <UserSettingWrapper>
            <HelpWrapper id="intercom_id_hook">
              <HelpIcon>
                <StyledQuestion />
              </HelpIcon>
              {t('help')}
            </HelpWrapper>
            <HeaderBadge>
              <Popover
                content={<UserMenu onClosePopover={onClose} />}
                trigger="click"
                placement="bottomRight"
                visible={visible}
                onVisibleChange={onChange}
              >
                <UserWrapper>
                  <StyledUser />
                </UserWrapper>
              </Popover>
            </HeaderBadge>
          </UserSettingWrapper>
        </Header>
        <HomeContent>
          <Outlet />
        </HomeContent>
      </LayoutContent>
    </Layout>
  );
};
