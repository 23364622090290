import styled from 'styled-components';
import Typography from 'antd/es/typography';

export const MBLRowInformation = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const MBLRowTitle = styled(Typography)`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
`;

export const MBLRowItems = styled.div`
  margin-top: 12px;
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
`;

export const MBLCarrierItem = styled('div')`
  display: flex;
  flex-direction: column;
`;

export const MBLCarrierItemTitle = styled(Typography)`
  font-size: 12px;
  line-height: 20px;
  color: ${({ theme }) => theme.themesColors.secondaryDot45};
`;

export const MBLCarrierItemSubTitle = styled(Typography)`
  font-size: 14px;
  line-height: 22px;
`;
