import styled from 'styled-components';
import Typography from 'antd/es/typography';

export const Wrap = styled.div`
  margin-top: 8px;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 202px;
  padding: 16px;
  background-color: white;
  border-radius: 3px;
  border: 1px solid rgba(234, 238, 248, 1);
`;

export const Image = styled.img`
  margin-right: 24px;
  width: 320px;
  height: 100%;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled(Typography)`
  font-size: 14px;
  line-height: 22px;
  font-weight: 500;
`;

export const Text = styled(Typography)`
  margin-top: 8px;
  font-size: 14px;
  line-height: 22px;
  color: rgba(115, 129, 155, 1);
`;

export const Agree = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px;
  margin-top: 12px;
  border-radius: 3px;
  width: 100%;
  height: 62px;
  border: 1px solid rgba(234, 238, 248, 1);
`;

export const AgreeText = styled(Typography)`
  margin-left: 4px;
  font-size: 14px;
  line-height: 22px;

  &:first-of-type {
    margin-left: 12px;
  }
`;

// (AgreeText)
export const AgreeTextPdf = styled.div.attrs({
  'data-class': 'AgreeTextPdf',
})`
  color: rgba(74, 85, 173, 1);
  cursor: pointer;
`;

export const AgreeTextLink = styled.a`
  color: rgba(74, 85, 173, 1);
  cursor: pointer;
`;

export const Checked = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 4px;
  height: 100%;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  background-color: rgba(108, 121, 222, 1);
`;
