import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';
import { UC } from 'shipment-operations/controllers';

import { CargoListComponent } from './CargoList.component';

const CargoListContainer: FC = () => {
  const selectedCargo = useSelector(R.selectors.cargo.getSelectedCargo);
  const cargoDraftStatuses = useSelector(R.selectors.cargo.getDraftStatusOfEachCargo);
  const cargos = useSelector(R.selectors.cargo.getCargos);

  return (
    <CargoListComponent
      cargos={cargos}
      cargoDraftStatuses={cargoDraftStatuses}
      selectedCargo={selectedCargo}
      selectCargo={UC.cargo.selectCargo}
    />
  );
};

export {
  CargoListContainer as CargoList,
};
