import reduce from 'lodash/fp/reduce';
import getOr from 'lodash/fp/getOr';

import { InvoicesChargeDTM, CreditNoteItemDTM } from 'shipment-operations/models/dtm';

export const calculateInvoiceSum = (data: InvoicesChargeDTM[]) => {
  const sum = reduce(
    (acc: number, cur: InvoicesChargeDTM | {}) => acc + getOr(0, ['amount'], cur), 0, data,
  );
  return parseFloat(sum.toFixed(2));
};

export const calculateCreditNoteSum = (data: CreditNoteItemDTM[]) => {
  const sum = reduce(
    (acc: number, cur: InvoicesChargeDTM | {}) => acc + getOr(0, ['amount'], cur), 0, data,
  );
  return parseFloat(sum.toFixed(2));
};
