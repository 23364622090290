import React, { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { DocumentsCollapseItemDTM } from 'shipment-operations/models/dtm';
import { ExpandIcon } from 'app-wrapper/view/components';
import themesColors from 'app-wrapper/view/themes/themesColors';

import {
  DocumentCollapse,
  CollapsePanel,
  CollapseIcon,
  CollapseIconActive,
  CollapseLabel,
  CollapseLabelText,
  TableCell,
  TableDocuments,
  BoldText,
} from './DocumentsSection.styled';
import { ActionMenu } from './components';

interface DocumentsSectionProps {
  sectionName: string;
  isCollapsed?: boolean;
  documents: DocumentsCollapseItemDTM[];
  onDownload?: (shipmentId: string, documentId: number, documentName: string) => void;
  onRemove?: (shipmentId: string, document: DocumentsCollapseItemDTM) => void;
  shipmentId: string;
  showBCPrintMenuItem?: boolean;
  showQuotePrintMenuItem?: boolean;
  showInvoicePrintMenuItem?: boolean;
  showHBLPrintMenuItem?: boolean;
  showNRAPrintMenuItem?: boolean;
  showNRAPrintContractOwnerToAdminMenuItem?: boolean;
  showNRAPrintAdminToAdminMenuItem?: boolean;
  showSkypaceTermsPrintMenuItem?: boolean;
  isAPInvoiceType?: boolean;
}

export const DocumentsSection: FC<DocumentsSectionProps> = ({
  sectionName,
  documents,
  isCollapsed,
  shipmentId,
  onDownload,
  onRemove,
  showBCPrintMenuItem,
  showQuotePrintMenuItem,
  showInvoicePrintMenuItem,
  showHBLPrintMenuItem,
  showNRAPrintMenuItem,
  showNRAPrintAdminToAdminMenuItem,
  showNRAPrintContractOwnerToAdminMenuItem,
  showSkypaceTermsPrintMenuItem,
  isAPInvoiceType,
}) => {
  const { t } = useTranslation();

  const columns = useMemo(() => [
    {
      title: t('Document Name'),
      width: '25%',
      key: 'object',
      align: 'left' as 'left',
      render: (item: string, record: DocumentsCollapseItemDTM | {}) => {
        const document = record as DocumentsCollapseItemDTM;

        return (
          <BoldText>
            {document.name}
          </BoldText>
        );
      },
    },
    {
      title: t('Uploaded Date'),
      width: '25%',
      key: 'object',
      align: 'left' as 'left',
      render: (item: string, record: DocumentsCollapseItemDTM | {}) => {
        const document = record as DocumentsCollapseItemDTM;

        return (
          <TableCell>
            {document.uploadedDate ? document.uploadedDate.getDateDMMMYYYYHHmmWithOffset() : '-'}
          </TableCell>
        );
      },
    },
    {
      title: t('Uploaded By'),
      width: '25%',
      key: 'object',
      align: 'left' as 'left',
      render: (item: string, record: DocumentsCollapseItemDTM | {}) => {
        const document = record as DocumentsCollapseItemDTM;

        return (
          <TableCell>
            {document.createdBy}
          </TableCell>
        );
      },
    },
    {
      title: t('Action'),
      width: '15%',
      key: 'object',
      align: 'right' as 'right',
      render: (item: string, record: DocumentsCollapseItemDTM | {}) => {
        const document = record as DocumentsCollapseItemDTM;

        const handleDelete = onRemove ? () => onRemove(shipmentId, document) : undefined;
        const handleDownload = onDownload ? () => onDownload(shipmentId, document.id, document.name) : undefined;

        return (
          <TableCell>
            <ActionMenu
              onDownload={handleDownload}
              onDelete={handleDelete}
              showBCPrintMenuItem={showBCPrintMenuItem}
              showQuotePrintMenuItem={showQuotePrintMenuItem}
              showInvoicePrintMenuItem={showInvoicePrintMenuItem}
              showHBLPrintMenuItem={showHBLPrintMenuItem}
              showNRAPrintMenuItem={showNRAPrintMenuItem}
              showSkypaceTermsPrintMenuItem={showSkypaceTermsPrintMenuItem}
              showNRAPrintMenuAdminToAdminItem={showNRAPrintAdminToAdminMenuItem}
              showNRAPrintMenuContractOwnerToAdminItem={showNRAPrintContractOwnerToAdminMenuItem}
              invoiceId={document.id}
              isAPInvoiceType={isAPInvoiceType}
            />
          </TableCell>
        );
      },
    },
  ], [t, showBCPrintMenuItem, showQuotePrintMenuItem, showInvoicePrintMenuItem, showHBLPrintMenuItem, showNRAPrintMenuItem, showSkypaceTermsPrintMenuItem, isAPInvoiceType]);

  const onExpandIcon = useCallback(
    ({ isActive }) => ExpandIcon({
      isActive,
      color: `${isActive ? themesColors.primaryBranding6 : themesColors.neutralBranding7}`,
    }),
    [],
  );

  return (
    <DocumentCollapse expandIcon={onExpandIcon}>
      <CollapsePanel
        key={sectionName}
        header={(
          <CollapseLabel>
            {isCollapsed ? (
              <CollapseIconActive />
            ) : (
              <CollapseIcon />
            )}

            <CollapseLabelText $isCollapsed={isCollapsed}>
              {sectionName}
            </CollapseLabelText>
          </CollapseLabel>
        )}
      >
        <TableDocuments
          pagination={false}
          columns={columns}
          dataSource={documents}
        />
      </CollapsePanel>
    </DocumentCollapse>
  );
};
