import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';
import { UC } from 'shipment-operations/controllers';

import { BillOfLadingCopiesDistributionComponent } from './BillOfLadingCopiesDistribution.component';

const BillOfLadingCopiesDistributionContainer: FC = () => {
  const {
    copies,
    errors,
    touchedFields,
    wasUpdateAttempted,
    shippingParties,
  } = useSelector(R.selectors.billOfLading.getAllBillOfLadingInformation);
  const permissions = useSelector(R.selectors.shipment.getDocumentsPermissions);
  const { type } = useSelector(R.selectors.billOfLading.getAllBillOfLadingInformation);
  const shippingPartiesRecord = useSelector(R.selectors.billOfLadingCommon.getShippingPartiesAsMap);

  return (
    <BillOfLadingCopiesDistributionComponent
      shippingPartiesMap={shippingParties}
      shippingPartiesRecord={shippingPartiesRecord}
      billOfLadingType={type}
      entries={copies}
      errors={errors.copies}
      touchedFields={touchedFields}
      wasUpdateAttempted={wasUpdateAttempted}
      touchField={UC.billOfLading.touchField}
      setShippingParty={UC.billOfLading.setCopyParty}
      setAmount={UC.billOfLading.setCopyAmount}
      setFreighted={UC.billOfLading.setCopyFreighted}
      addEntry={UC.billOfLading.addCopy}
      removeEntry={UC.billOfLading.removeCopy}
      billOfLadingPermission={permissions.billOfLadingDocuments}
    />
  );
};

export {
  BillOfLadingCopiesDistributionContainer as BillOfLadingCopiesDistribution,
};
