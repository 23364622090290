import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';

import { PageHeader } from 'app-wrapper/view/components';
import { RATES_STATUS, RouteNames } from 'app-wrapper/constants';
import themesColors from 'app-wrapper/view/themes/themesColors';

import {
  IFreightQuotasStateDTM,
} from 'monetary/models/dtm';

import {
  RFRByIdPageDiv, RFRByIdPageHeaderRight, RFRByIdPageHeaderRightIcon, RFRByIdPageHeaderRightText,
} from './RFRById.styled';
import { RFRByIdSecondStep } from './RFRByIdSecondStep';

interface IRFRByIdPageComponentProps {
  allQuotesState?: IFreightQuotasStateDTM
  RFRStatus?: string
  quotaStatus?: string
  quotaName?: string
  isFavorite?: boolean
  isLoad?: boolean
  onRunRFRByIdQuotasBumpersPage: (quotaId: string) => void
  abortAllRequests: () => void
}

export const RFRByIdPageComponent: FC<IRFRByIdPageComponentProps> = (props) => {
  const {
    allQuotesState,
    RFRStatus,
    quotaStatus,
    quotaName,
    isFavorite,
    isLoad,
    onRunRFRByIdQuotasBumpersPage,
    abortAllRequests,
  } = props;

  const { t } = useTranslation();
  const navigate = useNavigate();
  const { customQuotaId } = useParams();

  useEffect(() => () => {
    abortAllRequests();
  }, []);

  useEffect(() => {
    if (customQuotaId) {
      onRunRFRByIdQuotasBumpersPage(customQuotaId);
    }
  }, [customQuotaId]);

  const stepComponent = useMemo(() => (
    <RFRByIdSecondStep />
  ), [allQuotesState?.quotasOption,
    quotaStatus, isFavorite]);

  const onBackHandler = useCallback(
    () => {
      navigate(RouteNames.RATES_FREIGHT_LIST());
    },
    [navigate],
  );

  const statusText = useCallback((status?: string) => {
    switch (status) {
      case RATES_STATUS.PENDING:
        return t('Pending');
      case RATES_STATUS.COMPLETED:
        return t('Completed');
      case RATES_STATUS.CANCELLED:
        return t('Canceled');
      default:
        return '';
    }
  }, [t]);

  const statusColor = useCallback((status?: string) => {
    switch (status) {
      case RATES_STATUS.PENDING:
        return themesColors.characterBrandingWarning;
      case RATES_STATUS.COMPLETED:
        return themesColors.characterBrandingSuccess;
      case RATES_STATUS.CANCELLED:
        return themesColors.characterBrandingDanger;
      default:
        return '';
    }
  }, []);

  const pageHeader = useMemo(() => (
    <PageHeader
      onBack={onBackHandler}
      title={customQuotaId || ''}
      contentRight={(
        <RFRByIdPageHeaderRight>
          <RFRByIdPageHeaderRightIcon iconColor={statusColor(RFRStatus)} />
          <RFRByIdPageHeaderRightText>
            {statusText(RFRStatus)}
          </RFRByIdPageHeaderRightText>
        </RFRByIdPageHeaderRight>
      )}
      ghost={false}
    />
  ), [onBackHandler, RFRStatus, quotaName, isLoad]);

  return (
    <RFRByIdPageDiv>
      {pageHeader}
      {stepComponent}
    </RFRByIdPageDiv>
  );
};
