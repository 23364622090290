import React, {
  FC,
  useCallback,
  ChangeEvent, useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import MailOutlined from '@ant-design/icons/MailOutlined';
import LockOutlined from '@ant-design/icons/LockOutlined';

import { RouteNames } from 'app-wrapper/constants';
import { SkypaceDarkLogo } from 'app-wrapper/view/icons';

import {
  BottomMessage,
  ErrorWrapper,
  ForgotPasswordLinkWrapper,
  LogoWrapper,
  SignInAlertBlock,
  SignInButton,
  SignInEmailInput,
  SignInForm,
  SignInPasswordInput,
  SignInTitle,
  SignInWrapper,
  SignUpLinkWrapper,
} from './SignIn.styled';

interface ISignInComponentProps {
  loading: boolean
  responseError: string
  emailValue: string
  emailError?: string
  passwordValue: string
  passwordError?: string
  onEmailFieldChange: (email: string) => void
  onEmailBlur: () => void
  onEmailFocus: () => void
  onPasswordFieldChange: (password: string) => void
  onPasswordBlur: () => void
  onPasswordFocus: () => void
  onSignIn: () => void
  code: string
  username: string
  onEmailConfirmation: (username: string, code: string) => void
}

export const SignInComponent: FC<ISignInComponentProps> = ({
  loading,
  responseError,
  emailValue,
  emailError,
  passwordValue,
  passwordError,
  onEmailFieldChange,
  onEmailBlur,
  onEmailFocus,
  onPasswordFieldChange,
  onPasswordBlur,
  onPasswordFocus,
  onSignIn,
  onEmailConfirmation,
  username,
  code,
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (username && code) {
      onEmailConfirmation(username, code);
    }
  }, []);

  const emailChangeHandler = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      onEmailFieldChange(event.currentTarget.value);
    },
    [],
  );

  const emailBlurHandler = useCallback(
    () => {
      onEmailBlur();
    },
    [],
  );

  const emailFocusHandler = useCallback(
    () => {
      onEmailFocus();
    },
    [],
  );

  const passwordChangeHandler = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      onPasswordFieldChange(event.currentTarget.value);
    },
    [],
  );

  const passwordBlurHandler = useCallback(
    () => {
      onPasswordBlur();
    },
    [],
  );

  const passwordFocusHandler = useCallback(
    () => {
      onPasswordFocus();
    },
    [],
  );

  const signInHandler = useCallback(
    () => {
      onSignIn();
    },
    [],
  );

  return (
    <SignInWrapper>
      <SignInForm>
        <LogoWrapper>
          <SkypaceDarkLogo />
        </LogoWrapper>
        <SignInTitle>
          {t('Empowering Global Trade Logistics')}
        </SignInTitle>
        <ErrorWrapper>
          {!!responseError && <SignInAlertBlock message={responseError} type="error" />}
        </ErrorWrapper>
        <SignInEmailInput
          placeholder={t('Email')}
          name="email"
          size="large"
          prefix={<MailOutlined />}
          value={emailValue}
          error={!!emailError}
          onChange={emailChangeHandler}
          onBlur={emailBlurHandler}
          onFocus={emailFocusHandler}
        />
        <SignInPasswordInput
          placeholder={t('Password')}
          name="password"
          type="password"
          size="large"
          prefix={<LockOutlined />}
          value={passwordValue}
          error={!!passwordError}
          onChange={passwordChangeHandler}
          onBlur={passwordBlurHandler}
          onFocus={passwordFocusHandler}
        />
        <ForgotPasswordLinkWrapper><Link to={RouteNames.FORGOT_PASSWORD_EMAIL_SEND()}>{t('Forgot password?')}</Link></ForgotPasswordLinkWrapper>
        <SignInButton size="large" onClick={signInHandler} loading={loading}>
          {t('Sign In')}
        </SignInButton>
        <SignUpLinkWrapper>
          <span>{t('Don’t have account?')} <Link to={RouteNames['SIGN-UP']()}>{t('Sign Up')}</Link></span>
        </SignUpLinkWrapper>
      </SignInForm>
      <BottomMessage />
    </SignInWrapper>
  );
};
