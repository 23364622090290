import styled from 'styled-components';
import CopyOutlined from '@ant-design/icons/CopyOutlined';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  padding: 12px 16px;
`;

export const PaymentDetailsWrapper = styled.div`
  width: 50%;
`;

export const BilledWrapper = styled.div`
  width: 50%;
`;

export const Title = styled.span`
  color: #202C3C;
  font-weight: 500;
  font-size: 14px;
`;

export const PaymentDataWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`;

export const PaymentData = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  margin-top: 12px;
`;

export const PaymentLabel = styled.span`
  color: #73819B;
  font-weight: 400;
  font-size: 12px;
`;

export const PaymentValue = styled.span`
  color: #202C3C;
  font-weight: 400;
  font-size: 14px;
`;

export const CopyIcon = styled(CopyOutlined)`
  color: #73819B;
  font-size: 12px;
  margin-left: 3px;
  cursor: pointer;
`;

export const FooterDialog = styled.p`
  color: #73819B;
  font-weight: 400;
  font-size: 14px;
`;

export const StyledInvoice = styled.span`
  color: #202C3C;
  margin-left: 3px;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const BoldValue = styled.span`
  color: #202C3C;
  font-weight: 500;
  font-size: 14px;
`;
