import { EPermissionAttributePolicy } from 'monetary/constants';
import { EOrganizationMemberRole } from 'user-management/constants';
import { EOrganizationStatus, ModuleFunctionalityPermissionsDtm } from 'user-management/models/dtm';

const organizationsRolesPermissions = (organizationRole?: EOrganizationMemberRole, userRole?: EOrganizationMemberRole) => {
  if (organizationRole === EOrganizationMemberRole.CUSTOMER) {
    return {
      accountOrganizationProfile: {
        coverageAreas: undefined,
      },
      customerOrganizationProfile: {
        coverageAreas: undefined,
        customerSalesAndMarkupPermissions: undefined,
        salesRepresentativePermissions: undefined,
      },
      partnerOrganizationProfile: {
        coverageAreas: undefined,
      },
      editOrganizationProfileDrawer: {
        coverageAreas: undefined,
      },
      editCustomerOrganizationProfileDrawer: {
        coverageAreas: undefined,
      },
      editPartnerOrganizationProfileDrawer: {
        coverageAreas: undefined,
      },
      userManagementProfile: {
        userDepartmentsAndTeamsEdit: undefined,
        userDepartmentsAndTeamsView: undefined,
      },
      contracts: {
        contractsPermission: undefined,
      },
    };
  }

  if (organizationRole === EOrganizationMemberRole.PARTNER) {
    return {
      accountOrganizationProfile: {
        coverageAreas: EPermissionAttributePolicy.READ,
      },
      customerOrganizationProfile: {
        coverageAreas: undefined,
        customerSalesAndMarkupPermissions: userRole === EOrganizationMemberRole.admin ? EPermissionAttributePolicy.READ : undefined,
        salesRepresentativePermissions: userRole === EOrganizationMemberRole.admin ? EPermissionAttributePolicy.WRITE : undefined,
      },
      partnerOrganizationProfile: {
        coverageAreas: undefined,
      },
      editOrganizationProfileDrawer: {
        coverageAreas: EPermissionAttributePolicy.READ,
      },
      editCustomerOrganizationProfileDrawer: {
        coverageAreas: undefined,
      },
      editPartnerOrganizationProfileDrawer: {
        coverageAreas: EPermissionAttributePolicy.READ,
      },
      userManagementProfile: {
        userDepartmentsAndTeamsEdit: EPermissionAttributePolicy.WRITE,
        userDepartmentsAndTeamsView: EPermissionAttributePolicy.WRITE,
      },
      contracts: {
        contractsPermission: userRole === EOrganizationMemberRole.admin ? EPermissionAttributePolicy.WRITE : EPermissionAttributePolicy.READ,
      },
    };
  }

  if (organizationRole === EOrganizationMemberRole.admin) {
    return {
      accountOrganizationProfile: {
        coverageAreas: undefined,
      },
      customerOrganizationProfile: {
        coverageAreas: undefined,
        customerSalesAndMarkupPermissions: userRole === EOrganizationMemberRole.admin ? EPermissionAttributePolicy.READ : undefined,
        salesRepresentativePermissions: undefined,
      },
      partnerOrganizationProfile: {
        coverageAreas: EPermissionAttributePolicy.WRITE,
      },
      editOrganizationProfileDrawer: {
        coverageAreas: undefined,
      },
      editCustomerOrganizationProfileDrawer: {
        coverageAreas: undefined,
      },
      editPartnerOrganizationProfileDrawer: {
        coverageAreas: EPermissionAttributePolicy.WRITE,
      },
      userManagementProfile: {
        userDepartmentsAndTeamsEdit: EPermissionAttributePolicy.WRITE,
        userDepartmentsAndTeamsView: EPermissionAttributePolicy.WRITE,
      },
      contracts: {
        contractsPermission: EPermissionAttributePolicy.READ,
      },
    };
  }

  if (organizationRole === EOrganizationMemberRole.member) {
    return {
      accountOrganizationProfile: {
        coverageAreas: undefined,
      },
      customerOrganizationProfile: {
        coverageAreas: undefined,
        customerSalesAndMarkupPermissions: undefined,
        salesRepresentativePermissions: undefined,
      },
      partnerOrganizationProfile: {
        coverageAreas: undefined,
      },
      editOrganizationProfileDrawer: {
        coverageAreas: undefined,
      },
      editCustomerOrganizationProfileDrawer: {
        coverageAreas: undefined,
      },
      editPartnerOrganizationProfileDrawer: {
        coverageAreas: undefined,
      },
      userManagementProfile: {
        userDepartmentsAndTeamsEdit: undefined,
        userDepartmentsAndTeamsView: undefined,
      },
      contracts: {
        contractsPermission: undefined,
      },
    };
  }

  return {
    accountOrganizationProfile: {
      coverageAreas: undefined,
    },
    customerOrganizationProfile: {
      coverageAreas: undefined,
      customerSalesAndMarkupPermissions: undefined,
      salesRepresentativePermissions: undefined,
    },
    partnerOrganizationProfile: {
      coverageAreas: undefined,
    },
    editOrganizationProfileDrawer: {
      coverageAreas: undefined,
    },
    editCustomerOrganizationProfileDrawer: {
      coverageAreas: undefined,
    },
    editPartnerOrganizationProfileDrawer: {
      coverageAreas: undefined,
    },
    userManagementProfile: {
      userDepartmentsAndTeamsEdit: undefined,
      userDepartmentsAndTeamsView: undefined,
    },
    contracts: {
      contractsPermission: undefined,
    },
  };
};

export class ModuleFunctionalityPermissionsService {
  public getFunctionalityPermissions = (userRole: EOrganizationMemberRole, organizationRole?: EOrganizationMemberRole, statusOrganization?: EOrganizationStatus) => {
    let organizationDeletedDocumentsDetails = false;
    let organizationEditProfileIdentifiers = false;
    let organizationEditProfileIdentifiersType = false;
    let organizationEditProfileIdentifiersTypeIndustry = false;
    let organizationEditProfileCountry = false;
    let organizationAccountShowDepartments = false;
    let documentsUserTermsOfService = false;
    let isVisibleAssigneeOrganizationTasks = true;
    let usersPage: EPermissionAttributePolicy | undefined;
    let usersDownloadUsersButton: EPermissionAttributePolicy | undefined;

    if (organizationRole === EOrganizationMemberRole.CUSTOMER) {
      organizationDeletedDocumentsDetails = !(statusOrganization === EOrganizationStatus.DRAFT || statusOrganization === EOrganizationStatus.WAITING_APPROVAL);
      organizationEditProfileIdentifiers = !(statusOrganization === EOrganizationStatus.DRAFT || statusOrganization === EOrganizationStatus.WAITING_APPROVAL);
      organizationEditProfileCountry = !(statusOrganization === EOrganizationStatus.DRAFT);
      documentsUserTermsOfService = true;
      isVisibleAssigneeOrganizationTasks = false;
    }

    if (organizationRole === EOrganizationMemberRole.PARTNER) {
      organizationEditProfileIdentifiersType = true;
      organizationEditProfileIdentifiersTypeIndustry = true;
      organizationAccountShowDepartments = true;
    }

    if (userRole === EOrganizationMemberRole.PARTNER) {
      organizationEditProfileIdentifiers = true;
      organizationEditProfileIdentifiersType = true;
      organizationEditProfileIdentifiersTypeIndustry = true;
    }

    if (organizationRole === EOrganizationMemberRole.admin) {
      organizationDeletedDocumentsDetails = false;
      organizationEditProfileIdentifiers = false;
      organizationEditProfileIdentifiersTypeIndustry = false;
      organizationEditProfileCountry = false;
      usersPage = EPermissionAttributePolicy.WRITE;
      usersDownloadUsersButton = EPermissionAttributePolicy.WRITE;
    }

    if (userRole === EOrganizationMemberRole.admin) {
      return ModuleFunctionalityPermissionsDtm.fromPlain({
        userManagement: {
          userInviteButton: true,
          isAdmin: true,
          organizationDeletedDocumentsDetails,
          organizationEditProfileIdentifiers,
          organizationEditProfileIdentifiersType,
          organizationEditProfileIdentifiersTypeIndustry,
          organizationEditProfileCountry,
          organizationAccountShowDepartments,
          documentsUserTermsOfService,
          isVisibleAssigneeOrganizationTasks,
        },
        users: {
          page: usersPage,
          downloadUsersButton: usersDownloadUsersButton,
        },
        ...organizationsRolesPermissions(organizationRole, userRole),
      });
    }

    // default return - member setting
    return ModuleFunctionalityPermissionsDtm.fromPlain({
      userManagement: {
        userInviteButton: false,
        isAdmin: false,
        organizationDeletedDocumentsDetails,
        organizationEditProfileIdentifiers,
        organizationEditProfileIdentifiersType,
        organizationEditProfileIdentifiersTypeIndustry,
        organizationEditProfileCountry,
        organizationAccountShowDepartments,
        documentsUserTermsOfService,
        isVisibleAssigneeOrganizationTasks,
      },
      users: {
        page: usersPage,
        downloadUsersButton: usersDownloadUsersButton,
      },
      ...organizationsRolesPermissions(organizationRole, userRole),
    });
  }
}
