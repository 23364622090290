import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'app-wrapper/createSlice';

import { IShipmentTrackerState } from 'shipment-operations/models/states/ShipmentTracker.state';
import { ShipmentTrackerDTM, UpdatedDateDTM } from 'shipment-operations/models/dtm';

const initialState: IShipmentTrackerState = {
  containers: [],
  originalContainers: [],
  isLoading: true,
  isPageLoading: true,
  error: false,
  updatedDates: [],
  updateDatesLoading: false,
  updateDatesError: false,
};

export const ShipmentTrackerSlice = createSlice({
  name: 'shipmentTracker',
  initialState,
  reducers: {
    setLoading: (state, { payload }) => {
      state.isLoading = payload;
    },
    setPageLoading: (state, { payload }) => {
      state.isPageLoading = payload;
    },
    setError: (state, { payload }) => {
      state.isLoading = false;
      state.containers = [];
      state.originalContainers = [];
      state.error = payload;
    },
    setContainers: (state, { payload }: PayloadAction<ShipmentTrackerDTM[]>) => {
      state.isLoading = false;
      state.containers = payload;
      state.originalContainers = payload;
      state.error = false;
    },
    setUpdatedDates: (state, { payload }: PayloadAction<UpdatedDateDTM[]>) => ({
      ...state,
      updatedDates: payload,
    }),
    resetChanges: (state) => {
      state.containers = state.originalContainers;
      state.updatedDates = [];
    },
    setUpdateDatesLoading: (state, { payload }) => {
      state.updateDatesLoading = payload;
    },
    setUpdateDatesError: (state, { payload }) => {
      state.updateDatesError = payload;
      state.updateDatesLoading = false;
    },
  },
});

export const shipmentTrackerActions = ShipmentTrackerSlice.actions;
export const shipmentTrackerReducer = ShipmentTrackerSlice.reducer;
