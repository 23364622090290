import React from 'react';
import { useTranslation } from 'react-i18next';

import { SkypaceInformationDTM } from 'app-wrapper/constants';

import {
  Wrap,
  Title,
  NORContent,
  NORContentDate,
  NORContentParagraph,
  NORContentParagraphPaddingLeft,
  NORContentBoldText,
  NORContentTitle,
  NORContentItem,
  NORContentItemNumber,
  NORContentTextUnderline,
  NORContentWrap,
  PageBreak,
  Signatures,
  Signature,
  SignatureName,
  SignatureDivider,
  SignatureLabel,
} from './TermsOfConditionsPdf.styled';
import { Header } from './Header';

interface OrganizationTermOfServiceComponentProps {
  header?: {
    company?: SkypaceInformationDTM
    customerFullName?: string;
  }
  fileNamePrint?: string;
  createdByName?: string;
}

export const TermsOfConditionsUKPdfComponent = React.forwardRef(({
  header,
  fileNamePrint,
  createdByName,
}: OrganizationTermOfServiceComponentProps, ref) => {
  const { t } = useTranslation();

  return (
    <NORContentWrap ref={ref as React.RefObject<any>}>
      {fileNamePrint ? (<title>{fileNamePrint}</title>) : null}
      <Header
        company={header?.company}
      />
      <Wrap>
        <Title>
          Skypace Ltd<br />
          Standard Terms and Conditions of Service
        </Title>
        <NORContent>
          <NORContentDate>
            Last modified on: September 9, 2024
          </NORContentDate>

          <NORContentParagraph>
            These terms and conditions of service constitute a legally binding contract between Skypace Ltd  and the “Customer.” In the event Skypace Ltd renders services and issues a document containing Terms and Conditions governing such services, the Terms and Conditions set forth in such other document(s) shall govern those services to the extent inconsistent with the terms and conditions set forth herein.
          </NORContentParagraph>
          <NORContentParagraph>
            These Standard Terms and Conditions of Service are subject to change by Skypace Ltd from time to time upon posting on Company`s website. The most current and controlling version of the Standard Terms and Conditions of Service are published at <NORContentTextUnderline>https://www.skypace.com/</NORContentTextUnderline> .
          </NORContentParagraph>
          <NORContentParagraph>
            The Customer acknowledges and agrees that all services are also subject to the terms and conditions specified in the applicable consignment note (Bill of Lading, air waybill, Rail Waybill). The terms and conditions outlined in the Bill of Lading govern the rights, responsibilities, and obligations of both parties, and they are an integral part of the service agreement. It is Customer’s responsibility to review and understand the provisions of the consignment note before utilizing services, as they will be binding upon both parties in the execution of said services.
          </NORContentParagraph>
          <NORContentParagraph>
            The Customer acknowledges and agrees that all services are also subject to BIFA standard trading conditions.
          </NORContentParagraph>
          <NORContentParagraph>
            In tendering a shipment to Skypace Ltd or otherwise engaging the services of Skypace Ltd in any other fashion, the “Customer” agrees that these Standard Terms and Conditions of Service apply to that shipment or those services and supersede any alleged or asserted oral agreement, promise, representation, or understanding between the “Parties.” Further, Customer agrees that the Terms and Conditions on Skypace`s bill of lading, if applicable, and all limitations of liability therein, available at <NORContentTextUnderline>https://www.skypace.com/</NORContentTextUnderline>, apply to the shipment and any services provided by Company and are fully binding upon Customer.
          </NORContentParagraph>

          <NORContentParagraph>
            Company will only provide services that have been agreed to via its electronic platform or via email.
          </NORContentParagraph>

          <NORContentTitle>1. Definitions</NORContentTitle>

          <NORContentItem>
            <NORContentItemNumber>a.</NORContentItemNumber>
            <NORContentParagraph isNoneMarginBottom>
              <NORContentBoldText>“Company“</NORContentBoldText> shall mean Skypace Ltd , its subsidiaries, related companies, agents, and/or representatives.
            </NORContentParagraph>
          </NORContentItem>

          <NORContentItem>
            <NORContentItemNumber>b.</NORContentItemNumber>
            <NORContentParagraph isNoneMarginBottom>
              <NORContentBoldText>“Customer“</NORContentBoldText> shall mean the person or company for which Company is rendering service, as well as its principals, agents, and/or representatives, including but not limited to, shippers, importers, exporters, carriers, warehousemen, buyers and/or sellers, shipper’s agents, insurers and underwriters, break-bulk agents, consignees, manufacturers, distributors, senders, consignors, transferors, transferees of the shipment, etc. It is the responsibility of the Customer to provide notice and copy(s) of these Terms and Conditions of Service to all such agents and/or representatives.
            </NORContentParagraph>
          </NORContentItem>

          <NORContentItem>
            <NORContentItemNumber>c.</NORContentItemNumber>
            <NORContentParagraph isNoneMarginBottom>
              <NORContentBoldText>“Parties</NORContentBoldText> shall mean Company and Customer, as defined herein.
            </NORContentParagraph>
          </NORContentItem>

          <NORContentTitle>2. Company as Agent and Property Broker</NORContentTitle>

        </NORContent>
      </Wrap>
      <PageBreak
        marginBottom="0px"
      />
      <Header
        company={header?.company}
      />
      <Wrap>
        <NORContent>

          <NORContentItem>
            <NORContentItemNumber>a.</NORContentItemNumber>
            <NORContentParagraph isNoneMarginBottom>
              Company acts as the “agent” of the Customer for the purpose of performing duties in connection with the entry and release of goods, post entry services, the filing of export and security documentation on behalf of the Customer and other dealings with government agencies, or for arranging for transportation services, or other logistics services in any capacity other than as a carrier.
            </NORContentParagraph>
          </NORContentItem>

          <NORContentItem>
            <NORContentItemNumber>b.</NORContentItemNumber>
            <NORContentParagraph isNoneMarginBottom>
              Unless otherwise stated explicitly in writing by Company, all ground transportation arranged by Company is undertaken in Company’s role as property broker. Company will arrange for the transportation of property in exchange for payment by using a motor carrier. Company does not assume responsibility for the property and will not take legal possession of it.
            </NORContentParagraph>
          </NORContentItem>

          <NORContentTitle>3. Limitation of Actions</NORContentTitle>

          <NORContentItem>
            <NORContentItemNumber>a.</NORContentItemNumber>
            <NORContentParagraph isNoneMarginBottom>
              Unless subject to a specific statute or international convention, all claims against Company for a potential or actual loss, must be made in writing and received by Company within 30 days of the event giving rise to claim; the failure to give Company timely notice shall be a complete defense to any suit or action commenced by Customer.
            </NORContentParagraph>
          </NORContentItem>

          <NORContentItem>
            <NORContentItemNumber>b.</NORContentItemNumber>
            <NORContentParagraph isNoneMarginBottom>
              All suits against Company must be filed and properly served on Company in accordance with the terms and conditions of the carrier’s bill of lading or transport document, or the following, whichever is shorter. Suits must be filed within 1 year from the date of the loss or damage. For claims arising out of the preparation and/or submission of an import entry(s), suit must be brought within 75 days from the date of liquidation of the entry(s). For any and all other claims of any other type, suit must be brought within 1 year from the date of the loss or damage.
            </NORContentParagraph>
          </NORContentItem>

          <NORContentItem>
            <NORContentItemNumber>c.</NORContentItemNumber>
            <NORContentParagraph isNoneMarginBottom>
              Any claims against Company arising from Company’s administrative services, including any billing disputes, must be filed and properly served upon Company within 90 days from the date of any billing disputed.  It is Company’s goal to promptly resolve any disputes with its customers, and prompt notification is necessary to facilitate a resolution of any such dispute.
            </NORContentParagraph>
          </NORContentItem>
        </NORContent>
      </Wrap>

      <PageBreak
        marginBottom="0px"
      />
      <Header
        company={header?.company}
      />

      <Wrap>
        <NORContent>
          <NORContentTitle>4. No Liability for the Selection or Services of Third Parties and/or Routes</NORContentTitle>

          <NORContentItem isLastMarginBottom>
            <NORContentParagraph isNoneMarginBottom>
              Company is authorized to select and engage carriers, truckmen, brokers (including via a co-brokerage agreement) lightermen, forwarders, customs brokers, agents, warehousemen and others, as required, to transport shipment, and/or handle and deliver the goods, all of whom shall be considered the agents of the Customer, and the goods may be entrusted to such agencies subject to all conditions as to limitations of liability for loss, damage, expense, or delay and to all rules, regulations, requirements, and conditions, whether printed, written, or stamped, appearing in bills of lading, receipts, or tariffs issued by such carriers, truckmen, lightermen, forwarders, customs brokers, agents, warehousemen, and others.
            </NORContentParagraph>
          </NORContentItem>

          <NORContentItem isLastMarginBottom>
            <NORContentParagraph isNoneMarginBottom>
              Unless services are performed by persons or firms engaged pursuant to express written instructions from the Customer, Company shall use reasonable care in its selection of third parties, or in selecting the means and procedure to be followed in the handling, transportation, clearance, and delivery of the shipment; advice by Company that a particular person or firm has been selected to render services with respect to the goods, shall not be construed to mean that Company warrants or represents that such person or firm will render such services nor does Company assume responsibility or liability for any action(s) and/or inaction(s) of such third parties and/or its agents, and shall not be liable for any damage, expense, delay, or loss of any kind, which occurs while a shipment is in the custody or control of a third party or the agent of a third party; all claims in connection with the act of a third party shall be brought solely against such party and/or its agents; in connection with any such claim, Company shall reasonably cooperate with the Customer, which shall be liable for any charges or costs incurred by Company.
            </NORContentParagraph>
          </NORContentItem>

          <NORContentItem isLastMarginBottom>
            <NORContentParagraph>
              The Customer agrees not to hold Company liable for the specific choice of carriers or brokers used, as long as these choices are made in good faith and within the bounds of industry standards.
            </NORContentParagraph>
          </NORContentItem>

          <NORContentTitle>5. Quotations Not Binding</NORContentTitle>

          <NORContentItem>
            <NORContentItemNumber>a.</NORContentItemNumber>
            <NORContentParagraph isNoneMarginBottom>
              Quotations as to fees, rates of duty, freight charges, insurance premiums, or other charges given by Company to the Customer are for informational purposes only and are subject to change without notice; no quotation shall be binding upon Company unless Company, in writing, specifically agrees to undertake the handling or transportation of the shipment at a specific rate and payment arrangements are agreed to between Company and the Customer. All quotations are subject to change or cancellation by Company at any time if Company discovers that the shipment violates any court order, contractual obligation, government agency regulation, or law.
            </NORContentParagraph>
          </NORContentItem>

          <NORContentItem>
            <NORContentItemNumber>b.</NORContentItemNumber>
            <NORContentParagraph isNoneMarginBottom>
              Rates and charges are quoted in U.S. Currency and have been determined with due consideration to the relationship of U.S. currency to other currencies involved. However, if there are changes in the local currency rates, the prices can be recalculated based on the actual rates in effect at the time of arrival at the port of destination. It is the Customer`s obligation to pay any resulting difference in the converted amount.
            </NORContentParagraph>
          </NORContentItem>

          <NORContentTitle>6. Duty to Furnish Information; Reliance on Information Furnished</NORContentTitle>

          <NORContentItem>
            <NORContentItemNumber>a.</NORContentItemNumber>
            <NORContentParagraph isNoneMarginBottom>
              Customer acknowledges that it has a duty to timely furnish to Company any and all necessary and/or relevant information needed by Company so that Company can accurately complete any quoted services or offer transportation guidance to Customer. Customer further acknowledges that it is required to review all documents and declarations prepared and/or filed with U.S. Customs & Border Protection, other government agencies, and/or third parties, and will immediately advise Company of any errors, discrepancies, incorrect statements, or omissions on any declaration or other submission filed on Customer’s behalf.
            </NORContentParagraph>
          </NORContentItem>
        </NORContent>
      </Wrap>

      <PageBreak
        marginBottom="0px"
      />
      <Header
        company={header?.company}
      />

      <Wrap>
        <NORContent>
          <NORContentItem>
            <NORContentItemNumber>b.</NORContentItemNumber>
            <NORContentParagraph isNoneMarginBottom>
              In preparing and submitting customs entries, export declarations, applications, security filings, negotiated rate agreements, documentation, and/or other required data, Company relies on the correctness of all documentation, whether in written or electronic format, and information furnished by Customer; Customer shall use reasonable care to ensure the correctness of all such information and shall indemnify and hold Company harmless from any and all claims asserted and/or liability or losses suffered by reason of the Customer’s failure to disclose information or any incorrect, incomplete or false statement by the Customer or its agent, representative, or contractor upon which Company reasonably relied. The Customer agrees that the Customer has an affirmative non-delegable duty to disclose any and all information required to import, export, or enter the goods.
            </NORContentParagraph>
          </NORContentItem>

          <NORContentItem isLastMarginBottom>
            <NORContentItemNumber>c.</NORContentItemNumber>
            <NORContentParagraph isNoneMarginBottom>
              Customer acknowledges that it is required to provide verified weights obtained on calibrated, certified equipment of all cargo that is to be tendered to steamship lines and represents that Company is entitled to rely on the accuracy of such weights and to counter-sign or endorse it as agent of Customer in order to provide the certified weight to the steamship lines. The Customer agrees that it shall indemnify and hold Company harmless from any and all claims, losses, penalties or other costs resulting from any incorrect or questionable statements of the weight provided by the Customer or its agent or contractor on which Company relies.
            </NORContentParagraph>
          </NORContentItem>

          <NORContentItem isSecondItem>
            <NORContentItemNumber>i.</NORContentItemNumber>
            <NORContentParagraph isNoneMarginBottom>
              The verified weight shall be checked by the carrier at the port of loading. If the carrier finds a discrepancy between the reported and actual weight, Customer must provide corrected shipping instructions or the shipment may be rejected from loading or delayed and any cost or expense due to such rejection or delay, including but not limited to demurrage or detention, are the full responsibility of Customer, who agrees to indemnify Company for any such costs. Company shall attempt to notify Customer of a discrepancy as soon as possible but is under no obligation to notify Customer within a specific timeframe.
            </NORContentParagraph>
          </NORContentItem>

          <NORContentItem isLastMarginBottom>
            <NORContentItemNumber>d.</NORContentItemNumber>
            <NORContentParagraph isNoneMarginBottom>
              Customer agrees that if it uses Company’s electronic portal for booking transportation, that Company has no duty to review the information, and any adverse consequences due to typos or mistakes made by Customer in entering information into the portal shall be borne entirely by Customer.
            </NORContentParagraph>
          </NORContentItem>

          <NORContentItem isLastMarginBottom>
            <NORContentItemNumber>e.</NORContentItemNumber>
            <NORContentParagraph isNoneMarginBottom>
              Any and all fines or penalties levied against Company due to misdeclarations on manifest information provided to carriers, U.S. Customs, or any other government authority, due to Customer’s submission of inaccurate or incomplete information to Company, will be the responsibility of Customer regardless of the original source of such misdeclaration or whether the misdeclaration was intentional or unintentional.
            </NORContentParagraph>
          </NORContentItem>

          <NORContentItem isLastMarginBottom>
            <NORContentItemNumber>f.</NORContentItemNumber>
            <NORContentParagraph isNoneMarginBottom>
              The Customer affirms that their financial status is sufficient to meet all financial and credit obligations related to the transaction at hand, and is capable of fully covering all current, recurring, unforeseen, and applicable charges and costs associated with the service provided. The Customer`s commitment to executing the payment responsibilities remains effective until all costs, invoices, credit notes, and demands for payment are fully settled within the stipulated credit terms.
            </NORContentParagraph>
          </NORContentItem>
        </NORContent>
      </Wrap>

      <PageBreak
        marginBottom="0px"
      />
      <Header
        company={header?.company}
      />
      <Wrap>
        <NORContent>

          <NORContentTitle>7. Authority to Arrange for Transportation and Agree to These Terms and Conditions</NORContentTitle>

          <NORContentItem>
            <NORContentItemNumber>a.</NORContentItemNumber>
            <NORContentParagraph isNoneMarginBottom>
              Customer warrants that its representative requesting services of Company is authorized to agree to these Terms and Conditions of Service on behalf of itself or any shipper and/or consignee that Customer represents.
            </NORContentParagraph>
          </NORContentItem>

          <NORContentItem isLastMarginBottom>
            <NORContentItemNumber>b.</NORContentItemNumber>
            <NORContentParagraph isNoneMarginBottom>
              If Customer is booking transportation on behalf of a shipper or consignee as an agent or representative of the shipper or consignee, then Customer warrants and confirms that it has been granted legal authority to arrange for the transportation on behalf of the shipper and/or consignee, including the authority to enter into any Negotiated Rate Agreements on behalf of the shipper and/or consignee, and/or to provide authority to file export documentation on behalf of the shipper and/or consignee.
            </NORContentParagraph>
          </NORContentItem>

          <NORContentItem isLastMarginBottom>
            <NORContentItemNumber>c.</NORContentItemNumber>
            <NORContentParagraph isNoneMarginBottom>
              Customer shall provide proof of this legal authority (which may be in the form of a “Power of Attorney”) to Company upon request.
            </NORContentParagraph>
          </NORContentItem>

          <NORContentTitle>8. Pre-packaged Cargo</NORContentTitle>

          <NORContentItem>
            To the extent that Customer is providing packaged, marked, and/or addressed cargo, Customer warrants that each package and/or shipment is properly marked and addressed, packaged properly and adequately to protect the contents in the normal course of transportation, and except as noted in writing to Company, is in good order and condition. Customer shall hold Company harmless from any and all claims asserted and/or liability or losses suffered by reason of the Customer’s failure to properly package, mark and/or address cargo or to notify Company of any preexisting damage or impairment of the property.
          </NORContentItem>

          <NORContentTitle>9. Declaring Higher Valuation</NORContentTitle>

          <NORContentParagraph>
            Inasmuch as truckers, carriers, warehousemen, and other third parties to whom goods are entrusted usually limit their liability for loss or damage unless a higher value is declared and a charge based on such higher value is agreed to by said third party, Company must receive specific written instructions from the Customer to pay such higher charge based on valuation and the third party must accept such higher declared value; otherwise, the valuation placed by the Customer on the goods shall be considered solely for export or customs purposes and the goods will be delivered / tendered to the third party subject to the terms of the third party’s limitations of liability and/or these Terms and Conditions of Service.
          </NORContentParagraph>

        </NORContent>
      </Wrap>
      <PageBreak
        marginBottom="0px"
      />
      <Header
        company={header?.company}
      />
      <Wrap>
        <NORContent>

          <NORContentTitle>10. Insurance</NORContentTitle>

          <NORContentParagraph>
            Company will make reasonable efforts to effect marine, theft, and other insurance upon the goods only after specific written instructions have been received by Company in sufficient time prior to the shipment from the point of origin, and at the same time states specifically the kind and amount of insurance to be placed. Company does not undertake or warrant that such insurance can or will be placed. Unless the Customer has its own open marine policy and instructs Company to effect insurance under such policy, insurance is to be effected with one or more insurance companies or other underwriters to be selected by Company. Any insurance placed shall be governed by the certificate or policy issued and will only be effective when accepted by such insurance companies or other underwriters. Should an insurer dispute its liability for any reason, the insured shall have recourse against the insurer only and Company shall not be under any responsibility of liability in relation thereto, notwithstanding that the premium upon the policy may not be at the same rates as that charged or paid to Company by the Customer, or that the shipment was insured under a policy in the name of Company. Insurance premiums and the charge of Company for arranging the same shall be at the Customer`s expense. If for any reason the goods are held in warehouse, or elsewhere, the same will not be covered under any insurance, unless Company receives written instructions from the Customer. Unless specifically agreed in writing, Company assumes no responsibility to effect insurance on any shipment which it does not handle.
          </NORContentParagraph>

          <NORContentTitle>11. Disclaimers; Limitations of Liability</NORContentTitle>

          <NORContentItem>
            <NORContentItemNumber>a.</NORContentItemNumber>
            Except as specifically set forth herein, Company makes no express or implied warranties in connection with its services.
          </NORContentItem>

          <NORContentItem>
            <NORContentItemNumber>b.</NORContentItemNumber>
            <NORContentParagraph isNoneMarginBottom>
              In connection with all services performed by Company, Customer may obtain additional liability coverage, up to the actual or declared value of the shipment or transaction, by requesting such coverage and agreeing to make payment therefor, which request must be confirmed in writing by Company prior to rendering services for the covered transaction(s).
            </NORContentParagraph>
          </NORContentItem>

          <NORContentItem isLastMarginBottom>
            <NORContentItemNumber>c.</NORContentItemNumber>
            <NORContentParagraph isNoneMarginBottom>
              In the absence of additional coverage under (b) above, Company shall only be liable for any loss, damage, expense, or delay to the goods resulting from the negligence or other fault of Company, and such liability shall be limited to an amount equal to the lesser of fifty dollars ($50.00) per entry or shipment or the fee(s) charged for the services, whichever is less.
            </NORContentParagraph>
          </NORContentItem>

          <NORContentItem isLastMarginBottom>
            <NORContentItemNumber>d.</NORContentItemNumber>
            <NORContentParagraph isNoneMarginBottom>
              In no event shall Company be liable or responsible for consequential, indirect, incidental, statutory, special, or punitive damages, even if it has been put on notice of the possibility of such damages, or for the acts of third parties.
            </NORContentParagraph>
          </NORContentItem>

          <NORContentItem isLastMarginBottom>
            <NORContentItemNumber>e.</NORContentItemNumber>
            <NORContentParagraph isNoneMarginBottom>
              Unless the service to be performed by Company on behalf of the Customer is delayed by reason of negligence of Company, Company shall not be responsible for any loss, damage, or expense incurred by the Customer because of such delay. In the event Company is found liable under this subsection, its liability is limited in accordance with the provisions herein at subsection (c). Due to the inherent nature of the transportation business, Company does not guarantee pick up, transportation, or delivery by a stipulated time. In no event shall Company be liable for any delays or related expenses caused by terminal equipment shortages or any delay in transit ports.
            </NORContentParagraph>
          </NORContentItem>

          <NORContentItem isLastMarginBottom>
            <NORContentItemNumber>f.</NORContentItemNumber>
            <NORContentParagraph isNoneMarginBottom>
              Any additional expenses charged by the carrier, including but not limited to any charges relating to Customer’s request to terminate a shipment or any storage or demurrage resulting therefrom, are the full responsibility of Customer, and Customer shall indemnify Company for such costs and expenses regardless of whether Company timely communicated such request to carrier.
            </NORContentParagraph>
          </NORContentItem>

        </NORContent>
      </Wrap>
      <PageBreak
        marginBottom="0px"
      />
      <Header
        company={header?.company}
      />
      <Wrap>
        <NORContent>

          <NORContentTitle>12. Payment terms</NORContentTitle>

          <NORContentItem>
            <NORContentItemNumber>a.</NORContentItemNumber>
            <NORContentParagraph isNoneMarginBottom>
              <NORContentBoldText>Advancing Money</NORContentBoldText>. All charges must be paid by Customer in advance within 3 days from the issuance of the invoice. Specific payment arrangements may be outlined in the Customer`s account in their personal dashboard. These arrangements, if any, will supersede the standard prepayment terms as stated here. It is the customer`s responsibility to review and adhere to these specific payment terms, where applicable. Company shall be under no obligation to advance freight charges, customs duties, or taxes on any shipment, nor shall advance by Company be construed as a waiver of this provision.
            </NORContentParagraph>
          </NORContentItem>

          <NORContentItem>
            <NORContentItemNumber>b.</NORContentItemNumber>
            <NORContentParagraph isNoneMarginBottom>
              In the event of any changes to the nature of the goods or the details of the shipment after the booking has been confirmed, Company reserves the right to modify the service price at our discretion. This includes, but is not limited to, changes in weight, volume, need for special packaging, delivery route, or any other aspects that may affect the cost of service provision. Such price changes may be implemented without prior notice to the customer and without their prior approval.
            </NORContentParagraph>
          </NORContentItem>

          <NORContentTitle>13. Indemnification / Hold Harmless</NORContentTitle>

          <NORContentItem>
            <NORContentItemNumber>a.</NORContentItemNumber>
            <NORContentParagraph isNoneMarginBottom>
              The Customer agrees to indemnify, defend, and hold Company harmless from any claims and/or liability, fines, penalties and/or attorneys’ fees arising from the transport, importation or exportation of Customer’s merchandise, and/or any conduct of the Customer, including but not limited to the inaccuracy of entry, export or security data supplied by Customer, which violates any laws, and further agrees to indemnify and hold Company harmless against any and all liability, loss, damages, costs, claims, penalties, fines and/or expenses, including but not limited to reasonable attorneys’ fees, which Company may hereafter incur, suffer or be required to pay by reason of such claims; in the event that any claim, suit or proceeding is brought against Company, it shall give notice in writing to the Customer by email or mail at its address on file with Company. The confiscation or detention of the goods by any governmental authority shall not affect or diminish the liability of the Customer to Company to pay all charges or other money due promptly on demand.
            </NORContentParagraph>
          </NORContentItem>

          <NORContentItem>
            <NORContentItemNumber>b.</NORContentItemNumber>
            <NORContentParagraph isNoneMarginBottom>
              Customer further agrees to indemnify, defend, and hold harmless Company against any and all claims, losses, damages, costs, or expenses asserted against Company, its employees, agents or any other person for any injury (including sickness, disease or death) or claim or injury to property arising out of or in connection with the performance of these terms and conditions and/or in connection with Customer transporting the container for any purpose.
            </NORContentParagraph>
          </NORContentItem>

          <NORContentTitle>14. C.O.D. or Cash Collect Shipments</NORContentTitle>

          <NORContentItem>
            <NORContentParagraph isNoneMarginBottom>
              Company shall use reasonable care regarding written instructions relating to “Cash/Collect on Delivery (C.O.D.)” shipments, bank drafts, cashier’s and/or certified checks, letter(s) of credit and other similar payment documents and/or instructions regarding collection of monies but shall not have liability if the bank, correspondent, carrier, agent, or consignee refuses to pay for the shipment or for any act, omission, default, suspension, insolvency or want of care, negligence, or fault of such bank, correspondent, carrier, agent, or consignee, nor for any delay in remittance lost in exchange, or loss during transmission, or while in the course of collection.
            </NORContentParagraph>
          </NORContentItem>

          <NORContentTitle>15. Costs of Collection</NORContentTitle>

          <NORContentItem>
            <NORContentParagraph isNoneMarginBottom>
              In any dispute involving monies owed to Company, Company shall be entitled to all costs of collection, including reasonable attorneys’ fees and interest at 1.5% per month or the highest rate allowed by law, whichever is less unless a lower amount is agreed to by Company. Customer is liable to Company for any damages and costs incurred by Company arising from checks or drafts given to Company, Company’s representatives or affiliates, for which either Company or Company’s representative or affiliate is unable to collect funds.
            </NORContentParagraph>
          </NORContentItem>

        </NORContent>
      </Wrap>
      <PageBreak
        marginBottom="0px"
      />
      <Header
        company={header?.company}
      />
      <Wrap>
        <NORContent>

          <NORContentTitle>16. Compensation of Company</NORContentTitle>

          <NORContentItem>
            <NORContentParagraph isNoneMarginBottom>
              The compensation of Company for its services shall be included with and is in addition to the rates and charges of all carriers and other agencies selected by Company to transport and deal with the goods and such compensation shall be exclusive of any brokerage, commissions, dividends, or other revenue received by Company from carriers, insurers and others in connection with the shipment. On ocean exports, upon request, Company shall provide a detailed breakout of the components of all charges assessed and a true copy of each pertinent document relating to these charges. In any referral for collection or action against the Customer for monies due to Company, the Customer shall pay the expenses of collection and/or litigation, including reasonable attorneys’ fees. Customer shall remain responsible for all payments and shall not assign the responsibility of payment to another company or party without Company’s written consent.
            </NORContentParagraph>
          </NORContentItem>

          <NORContentTitle>17. General Lien and Right to Sell Customer`s Property</NORContentTitle>

          <NORContentItem>
            <NORContentItemNumber>a.</NORContentItemNumber>
            <NORContentParagraph isNoneMarginBottom>
              Company shall have a continuing lien on any and all property and documents relating to Customer and coming into Company’s actual or constructive possession, custody or control or enroute, which lien shall survive delivery, for all charges, expenses or advances owed to Company with regard to the shipment on which the lien is claimed, a prior shipment(s) and/or both. Customs duties, transportation charges, and related payments advanced by Company shall be deemed paid in trust on behalf of the Customer and treated as pass through payments made on behalf of the Customer for which Company is acting as a mere conduit.
            </NORContentParagraph>
          </NORContentItem>

          <NORContentItem>
            <NORContentItemNumber>b.</NORContentItemNumber>
            <NORContentParagraph isNoneMarginBottom>
              Company shall provide written notice to Customer of its intent to exercise such lien, the exact amount of monies due and owing, as well as any on-going storage or other charges; Customer shall notify all parties having an interest in its shipment(s) of Company’s rights and/or the exercise of such lien.
            </NORContentParagraph>
          </NORContentItem>

          <NORContentItem>
            <NORContentItemNumber>c.</NORContentItemNumber>
            <NORContentParagraph isNoneMarginBottom>
              Unless, within thirty days of receiving notice of lien, Customer posts cash or letter of credit at sight, or, if the amount due is in dispute, an acceptable bond equal to 110% of the value of the total amount due, in favor of Company, guaranteeing payment of the monies owed, plus all storage charges accrued or to be accrued, Company shall have the right to sell such shipment(s) at public or private sale or auction and any net proceeds remaining thereafter shall be refunded to Customer.
            </NORContentParagraph>
          </NORContentItem>

          <NORContentTitle>18. No Duty to Maintain Records for Customer</NORContentTitle>

          <NORContentItem>
            <NORContentParagraph isNoneMarginBottom>
              Customer acknowledges that pursuant to Sections 118 of the Customs and Excise Management Act 1979 it has the duty and is solely liable for maintaining all records required under the Customs and/or other laws and regulations of the United Kingdom; unless otherwise agreed to in writing, Company shall only keep such records that it is required to maintain by statue(s) and/or regulation(s), but not as a “recordkeeper” or “recordkeeping agent” for Customer.
            </NORContentParagraph>
          </NORContentItem>

        </NORContent>
      </Wrap>

      <PageBreak
        marginBottom="0px"
      />
      <Header
        company={header?.company}
      />

      <Wrap>
        <NORContent>

          <NORContentTitle>19. Obtaining Binding Rulings, Filing Protests, etc.</NORContentTitle>

          <NORContentItem>
            <NORContentParagraph isNoneMarginBottom>
              Unless requested by Customer in writing and agreed to by Company in writing, Company shall be under no obligation to undertake any pre- or post Customs release action, including, but not limited to, obtaining binding rulings, advising of liquidations, filing of petition(s) and/or protests, etc.
            </NORContentParagraph>
          </NORContentItem>

          <NORContentTitle>20. No Duty to Provide Licensing Authority; No Responsibility for Governmental Requirements Pertaining to Exports </NORContentTitle>

          <NORContentItem>
            <NORContentItemNumber>a.</NORContentItemNumber>
            <NORContentParagraph isNoneMarginBottom>
              Company shall not be responsible for determining licensing authority or obtaining any license or other authority pertaining to the export from or import into the United States.
            </NORContentParagraph>
          </NORContentItem>

          <NORContentItem>
            <NORContentItemNumber>b.</NORContentItemNumber>
            <NORContentParagraph isNoneMarginBottom>
              It is the responsibility of the Customer to know and comply with the export licensing or permitting requirements of all government agencies, including adhering to any and all regulations and directives of Federal, state and/or local agencies pertaining to the merchandise, shipment or transportation, and/or parties to the shipment. Company shall not be responsible for action taken or fines or penalties assessed by any governmental agency against the shipment or parties involved in the shipment because of the failure of the Customer to comply with the law or the requirements or regulations of any governmental agency or to the extent the classification information of any goods provided by any party to the shipment are incorrect.
            </NORContentParagraph>
          </NORContentItem>

          <NORContentItem>
            <NORContentItemNumber>c.</NORContentItemNumber>
            <NORContentParagraph isNoneMarginBottom>
              Customer represents, warrants, and covenants, that it has reasonably completed due diligence on the transaction, and that the shipment in no way violates any applicable law, and that the expected end use of the products being shipped are permitted under the law by permissible end users.
            </NORContentParagraph>
          </NORContentItem>

          <NORContentTitle>21. Preparation and Issuance of Bills of Lading and Air Waybills</NORContentTitle>

          <NORContentItem>
            <NORContentParagraph isNoneMarginBottom>
              Where Company prepares and/or issues a bill of lading or air waybill, Company shall be under no obligation to specify thereon the number of pieces, packages and/or cartons, etc., unless specifically requested to do so in writing by Customer or its agent and Customer agrees to pay for same. Company shall rely upon and use the cargo weight supplied by Customer.
            </NORContentParagraph>
          </NORContentItem>

          <NORContentTitle>22. Ocean Freight Specific Terms</NORContentTitle>

          <NORContentItem>
            <NORContentItemNumber>a.</NORContentItemNumber>
            <NORContentParagraph isNoneMarginBottom>
              All terms and conditions of Company’s Bill of Lading are incorporated herein by reference.
            </NORContentParagraph>
          </NORContentItem>

          <NORContentItem>
            <NORContentItemNumber>b.</NORContentItemNumber>
            <NORContentParagraph isNoneMarginBottom>
              Company does not allow split bookings. In case of delay, cargo must remain together and be stored at Customer’s expense until the Carrier has room for the full shipment.
            </NORContentParagraph>
          </NORContentItem>

          <NORContentItem>
            <NORContentItemNumber>c.</NORContentItemNumber>
            <NORContentParagraph isNoneMarginBottom>
              If Customer wishes to divert a shipment that Company has begun to undertake to a different destination, Customer must either cancel the original shipment (if it has not been loaded onto the carrier), or must arrange for a new shipment once the cargo reaches the original destination and the container has been returned. Diversion contrary to UK law is strictly prohibited.
            </NORContentParagraph>
          </NORContentItem>

          <NORContentItem>
            <NORContentItemNumber>d.</NORContentItemNumber>
            <NORContentParagraph isNoneMarginBottom>
              Company does not allow “street turns”, in which a container is used for one shipment and then, instead of being returned to the yard, is used for an additional shipment. If a “street turn” occurs, then Customer takes full responsibility and agrees to indemnify Company for damages and losses caused to cargo or the container.
            </NORContentParagraph>
          </NORContentItem>

          <NORContentItem>
            <NORContentItemNumber>e.</NORContentItemNumber>
            <NORContentParagraph isNoneMarginBottom>
              If Customer chooses to cancel or delay a shipment such that a container is removed from the port of loading or while in transit, Company will use its best efforts to accommodate the request, but Customer is fully responsible for any additional charges incurred.
            </NORContentParagraph>
          </NORContentItem>

        </NORContent>
      </Wrap>

      <PageBreak
        marginBottom="0px"
      />
      <Header
        company={header?.company}
      />

      <Wrap>
        <NORContent>

          <NORContentItem>
            <NORContentItemNumber>f.</NORContentItemNumber>
            <NORContentParagraph isNoneMarginBottom>
              Company allows only two types of cargo release: express release (electronic) or original bill of lading surrendered at the named location (either origin or destination). If changes are made to an original bill of lading, a new bill of lading must be prepared and issued by Company or the bill of lading must be converted to express release.
            </NORContentParagraph>
          </NORContentItem>

          <NORContentItem>
            <NORContentItemNumber>g.</NORContentItemNumber>
            <NORContentParagraph isNoneMarginBottom>
              Any amendments or changes made to a bill of lading after departure shall be subject to accessorial charges as quoted at the time of the booking request. Any amendments to a bill of lading less than ten (10) business days prior to estimated time of arrival are subject to late bill of lading change charges. Company shall not be liable for any storage, demurrage, detention, or other charges caused due to a bill of lading amendment or delay related to a bill of lading amendment, and Customer agrees to fully indemnify Company for any such costs.
            </NORContentParagraph>
          </NORContentItem>

          <NORContentItem>
            <NORContentItemNumber>h.</NORContentItemNumber>
            <NORContentParagraph isNoneMarginBottom>
              Any shipment booked on a “Prepayment” basis is not refundable.
            </NORContentParagraph>
          </NORContentItem>

          <NORContentItem>
            <NORContentItemNumber>i.</NORContentItemNumber>
            <NORContentParagraph isNoneMarginBottom>
              If Customer or the consignee of a shipment fail to return an empty container, or if the container is returned but damaged, then Customer shall be fully responsible for any costs or damages associated with the missing or damaged container and shall fully indemnify Company for such costs or damages.
            </NORContentParagraph>
          </NORContentItem>

          <NORContentItem>
            <NORContentItemNumber>j.</NORContentItemNumber>
            <NORContentParagraph isNoneMarginBottom>
              If Customer or consignee fail to pick up the cargo at point of destination within 20 calendar days, then Company shall consider the cargo abandoned. Customer is fully responsible for and shall indemnify Company for any costs, damages, duties, taxes, demurrage, and/or detention charges associated with the abandoned cargo.
            </NORContentParagraph>
          </NORContentItem>

          <NORContentItem>
            <NORContentItemNumber>k.</NORContentItemNumber>
            <NORContentParagraph isNoneMarginBottom>
              Company assumes no liability for container equipment and cannot guarantee that cargo is shipped at a certain temperature.
            </NORContentParagraph>
          </NORContentItem>

          <NORContentItem>
            <NORContentItemNumber>l.</NORContentItemNumber>
            <NORContentParagraph isNoneMarginBottom>
              Company is not required to validate signatures on documents nor to confirm that documentation uploaded onto its portal or provided by Customer is correct.
            </NORContentParagraph>
          </NORContentItem>

          <NORContentItem>
            <NORContentItemNumber>m.</NORContentItemNumber>
            <NORContentParagraph isNoneMarginBottom>
              Company is not responsible for confirming that Customer or carrier has properly cleared the cargo, and is not responsible for any delay or extra charges resulting from Customer or carrier’s failure to properly clear cargo.
            </NORContentParagraph>
          </NORContentItem>

          <NORContentItem>
            <NORContentItemNumber>n.</NORContentItemNumber>
            <NORContentParagraph isNoneMarginBottom>
              Company is not responsible for any expenses or charges resulting from the carrier’s failure to provide equipment or the carrier’s cancellation or rolling of a booking, and such charges and expenses are the full responsibility of Customer. Company is not responsible for any charges or expenses not covered by its quotation and agreed to via its portal or via email.
            </NORContentParagraph>
          </NORContentItem>

          <NORContentItem>
            <NORContentItemNumber>o.</NORContentItemNumber>
            <NORContentParagraph isNoneMarginBottom>
              Company reserves the right to reject any shipment of dangerous or hazardous goods at any time, even if Company originally agreed to handle the shipment and shall, at that point, be responsible for no further charges or expenses.
            </NORContentParagraph>
          </NORContentItem>

          <NORContentItem>
            <NORContentItemNumber>p.</NORContentItemNumber>
            <NORContentParagraph isNoneMarginBottom>
              Ocean rates are only applicable during the “validity period” as defined by Company’s quote, and outside of the validity period are subject to change.
            </NORContentParagraph>
          </NORContentItem>

          <NORContentItem>
            <NORContentItemNumber>q.</NORContentItemNumber>
            <NORContentParagraph isNoneMarginBottom>
              Any origin or destination charges shown on Company’s quotation are subject to change.
            </NORContentParagraph>
          </NORContentItem>

          <NORContentTitle>23. Company`s Responsibility for Loss or Damage to Goods</NORContentTitle>

          <NORContentItem>
            <NORContentItemNumber>a.</NORContentItemNumber>
            <NORContentParagraph isNoneMarginBottom>
              If the Customer establishes that Company is responsible for loss or damage in connection with the Goods, such responsibility, subject to the provisions of the consignment note, shall be to the extent following but not further.
            </NORContentParagraph>
          </NORContentItem>

          <NORContentItem>
            <NORContentItemNumber>b.</NORContentItemNumber>
            <NORContentParagraph isNoneMarginBottom>
              With respect to loss or damage caused during the period from the time when the Goods arrived at the sea terminal at the port of loading to the time when they left the sea terminal at the port of discharge, or caused during any previous or subsequent period of carriage by sea or waterways, to the extent prescribed by the applicable Hague Rules the International Conventions for the Unification of Certain Rules Relating to Bills of Lading, dated at Brussels, August 25, 1924, as amended (including, where enacted, the Protocol dated at Brussels, February 23, 1968, known as the Visby Rules).
            </NORContentParagraph>
          </NORContentItem>

        </NORContent>

      </Wrap>

      <PageBreak
        marginBottom="0px"
      />
      <Header
        company={header?.company}
      />

      <Wrap>
        <NORContent>

          <NORContentItem>
            <NORContentItemNumber>c.</NORContentItemNumber>
            <NORContentParagraph isNoneMarginBottom>
              With respect to loss or damage caused during the handling, storage or carriage of the Goods by Company`s Subcontractor:
            </NORContentParagraph>
          </NORContentItem>

          <NORContentItem isSecondItem>
            <NORContentItemNumber>i.</NORContentItemNumber>
            <NORContentParagraph isNoneMarginBottom>
              If such handling, storage or carriage occurred in or between points in USA, To the extent to which such Subcontractor would have been liable to the Customer if he had made a direct and separate contract with the Customer in respect of such handling, storage or carriage, with the Customer agreeing to a limitation of liability not exceeding $100,000, as referenced in the Carmack Amendment.
            </NORContentParagraph>
          </NORContentItem>

          <NORContentItem isSecondItem>
            <NORContentItemNumber>ii.</NORContentItemNumber>
            <NORContentParagraph isNoneMarginBottom>
              However, if Company is not authorized under any applicable laws, rules or regulations to undertake such handling, storage, or carriage under its own responsibility, Company shall only be liable for procuring such handling, storage or carriage.
            </NORContentParagraph>
          </NORContentItem>

          <NORContentItem isSecondItem>
            <NORContentItemNumber>iii.</NORContentItemNumber>
            <NORContentParagraph isNoneMarginBottom>
              If such handling, storage or carriage occurred in or between points in Europe, or where otherwise applicable, such responsibility shall be governed by:
              - If by road, by the Convention on the Contract for the International Carriage of Goods by Road, dated 19 May, 1956 (CMR);
              - If by rail, by the International Convention Concerning the Carriage of Goods by Rail, dated 25 February, 1961 (CIM);
              - If by air, by the Convention for the Unification of Certain Rules Relating to International Carriage by Air, signed Warsaw 12 October, 1929, as amended by the Hague Protocol dated 28 September, 1955 (Warsaw Convention).
            </NORContentParagraph>
          </NORContentItem>

          <NORContentItem isSecondItem>
            <NORContentItemNumber>iv.</NORContentItemNumber>
            <NORContentParagraph isNoneMarginBottom>
              If it is established by the Customer that an Inland Carrier is responsible for loss of or damage to or in connection with the Goods, such responsibility shall be to the extent, but not further, than the Inland Carrier would have been liable to the Customer if he had made a direct and separate contract with the Customer in respect of handling, storage or carriage of the Goods, as applicable.
            </NORContentParagraph>
          </NORContentItem>

          <NORContentItem>
            <NORContentItemNumber>d.</NORContentItemNumber>
            <NORContentParagraph isNoneMarginBottom>
              If the Bill of Lading is used for Port-to-Port Transportation, the column indicating final destination on the face of this Bill of Lading is solely for the purpose of the Customer`s reference, and Company`s responsibility for the Goods shall in all cases cease at the time of discharge of the Goods at the port of discharge.
            </NORContentParagraph>
          </NORContentItem>

          <NORContentItem>
            <NORContentItemNumber>e.</NORContentItemNumber>
            <NORContentParagraph isNoneMarginBottom>
              Company does not undertake that the Goods shall arrive at the port of discharge or place of delivery at any particular time or in time to meet any particular market or use, and Company shall not be responsible for any direct or indirect loss or damage that is caused through delay.
            </NORContentParagraph>
          </NORContentItem>

          <NORContentTitle>24. Company`s Responsibility for other claims , except for loss or damage to goods:</NORContentTitle>

          <NORContentItem>
            <NORContentItemNumber>a.</NORContentItemNumber>
            <NORContentParagraph isNoneMarginBottom>
              subject to (b) below, in the case of all other claims except loss and damage to Goods:
            </NORContentParagraph>
          </NORContentItem>

          <NORContentItem isSecondItem>
            <NORContentItemNumber>i.</NORContentItemNumber>
            <NORContentParagraph isNoneMarginBottom>
              the value of the subject Goods of the relevant transaction between the Company and its Customer; or
            </NORContentParagraph>
          </NORContentItem>

          <NORContentItem isSecondItem>
            <NORContentItemNumber>ii.</NORContentItemNumber>
            <NORContentParagraph isNoneMarginBottom>
              where the weight can be defined, a sum calculated at the rate of 2 SDR per kilo of the gross weight of the subject Goods of the said transaction; or
            </NORContentParagraph>
          </NORContentItem>

          <NORContentItem isSecondItem>
            <NORContentItemNumber>iii.</NORContentItemNumber>
            <NORContentParagraph isNoneMarginBottom>
              75,000 SDR in respect of any one transaction,  whichever shall be the lesser.
            </NORContentParagraph>
          </NORContentItem>

          <NORContentItem>
            <NORContentItemNumber>b.</NORContentItemNumber>
            <NORContentParagraph isNoneMarginBottom>
              in the case of an error and/or omission, or a series of errors and/or omissions which are repetitions of or represent the continuation of an original error and/or omission:
            </NORContentParagraph>
          </NORContentItem>

          <NORContentItem isSecondItem>
            <NORContentItemNumber>i.</NORContentItemNumber>
            <NORContentParagraph isNoneMarginBottom>
              the loss incurred; or
            </NORContentParagraph>
          </NORContentItem>

          <NORContentItem isSecondItem>
            <NORContentItemNumber>ii.</NORContentItemNumber>
            <NORContentParagraph isNoneMarginBottom>
              75,000 SDR in the aggregate of any one trading year commencing from the time of the making of  the original error and/or omission,  whichever shall be the lesser.
            </NORContentParagraph>

          </NORContentItem>
        </NORContent>
      </Wrap>
      <PageBreak
        marginBottom="0px"
      />
      <Header
        company={header?.company}
      />
      <Wrap>
        <NORContent isEnd>
          <NORContentTitle>25. Compliance with Applicable Law</NORContentTitle>

          <NORContentItem>
            <NORContentItemNumber>a.</NORContentItemNumber>
            <NORContentParagraph isNoneMarginBottom>
              Customer represents, warrants, and covenants, that it is and will remain in compliance with all applicable laws, including but not limited to any anti-corruption laws such as the UK Bribery Act 2010, or any export compliance or international sanctions regulations such as the Export Control Act 2002, the Export Control Order 2008, and economic sanctions programs administered by the UK Treasury Department’s Office of Financial Sanctions Implementation (OFSI).
            </NORContentParagraph>
          </NORContentItem>

          <NORContentItem>
            <NORContentItemNumber>b.</NORContentItemNumber>
            <NORContentParagraph isNoneMarginBottom>
              If at any time Company becomes aware of a shipment being illegally sent to a controlled destination or consignee, Company may immediately cancel the shipment. If the shipment is prepaid, it is not reimbursable.
            </NORContentParagraph>
          </NORContentItem>

          <NORContentTitle>26. No Modification or Amendment Unless Written</NORContentTitle>

          <NORContentItem>
            <NORContentParagraph isNoneMarginBottom>
              These Terms and Conditions of Service may only be modified, altered or amended by Customer in writing signed by both Customer and Company. Company may modify, alter, or amend these Terms and Conditions of Service at its sole discretion, but only the version in place at the time of a shipment shall control for that shipment.
            </NORContentParagraph>
          </NORContentItem>

          <NORContentTitle>27.  Use of Company Logo / Trademark</NORContentTitle>

          <NORContentItem>
            <NORContentParagraph isNoneMarginBottom>
              Customer shall not use Company’s name, logo, trademarks or trade names in publicity releases, promotional material, customer lists, advertising, marketing or business-generating efforts, whether written or oral, without obtaining Company’s prior written consent, which consent shall be given at Company’s sole
            </NORContentParagraph>
          </NORContentItem>

          <NORContentTitle>28. Force Majeure</NORContentTitle>

          <NORContentItem>
            <NORContentParagraph isNoneMarginBottom>
              Company shall not be liable for losses, damages, delays, wrongful or missed deliveries, or partial or full nonperformance, resulting from an occurrence beyond its control, including without limitation, war or terrorist activities; epidemic; riots, rebellion, civil commotion; acts of God; acts of lawful and/or government authorities (including but not limited to embargoes or denial or cancellation of any license or shipment); criminal acts; strikes, lockouts, or other labor disputes; incidents or deteriorations to means of transportation; defects, nature or inherent vice of the goods; or acts, breaches of contract or omissions by Customer, shipper, consignee, or anyone else who may have an interest in the shipment.
            </NORContentParagraph>
          </NORContentItem>

          <NORContentTitle>29. Severability</NORContentTitle>

          <NORContentItem>
            <NORContentParagraph isNoneMarginBottom>
              In the event any paragraph(s) and/or portion(s) hereof is found to be invalid and/or unenforceable, then in such event the remainder hereof shall remain in full force and effect. Company’s decision to waive any provision herein, either by conduct or otherwise, fully or partially, shall not be deemed to be a further or continuing waiver of such provision or to otherwise waive or invalidate any other provision herein.
            </NORContentParagraph>
          </NORContentItem>

          <NORContentTitle>30. Governing Law; Consent to Jurisdiction and Venue</NORContentTitle>

          <NORContentItem>
            <NORContentItemNumber>a.</NORContentItemNumber>
            <NORContentParagraph isNoneMarginBottom>
              These conditions and any act or contract to which they apply shall be governed by English law.
            </NORContentParagraph>
          </NORContentItem>

          <NORContentItem>
            <NORContentItemNumber>b.</NORContentItemNumber>
            <NORContentParagraph isNoneMarginBottom>
              Any dispute arising out of any act or contract to which these Conditions apply shall, save as provided in (C) below, be subject to the exclusive jurisdiction of the English courts.
            </NORContentParagraph>
          </NORContentItem>

          <NORContentItem>
            <NORContentItemNumber>c.</NORContentItemNumber>
            <NORContentParagraph isNoneMarginBottom>
              Notwithstanding (B) above, the Company is entitled to require any dispute to be determined by arbitration.
            </NORContentParagraph>
          </NORContentItem>
        </NORContent>
      </Wrap>

      <PageBreak
        marginBottom="0px"
      />
      <Header
        company={header?.company}
      />
      <Wrap>
        <NORContent isEnd>
          <NORContentItem>
            <NORContentItemNumber>d.</NORContentItemNumber>
            <NORContentParagraph isNoneMarginBottom>
              The Company may exercise its rights under (C) above either by itself commencing arbitration in respect of a dispute or by giving written notice to the Customer requiring a dispute to be determined by arbitration.
            </NORContentParagraph>
          </NORContentItem>

          <NORContentItem>
            <NORContentItemNumber>e.</NORContentItemNumber>
            <NORContentParagraph isNoneMarginBottom>
              In the event that the Company exercises its rights under (C) above, the corresponding arbitration shall be conducted as follows:
            </NORContentParagraph>
          </NORContentItem>

          <NORContentItem isSecondItem>
            <NORContentItemNumber>i.</NORContentItemNumber>
            <NORContentParagraph isNoneMarginBottom>
              Where the amount claimed by the claimant is less than £400,000, excluding interest, (or such other sum as the Company and Customer may agree, and subject to (iii) below), the reference shall be to a tribunal of three arbitrators and the arbitration shall be conducted in accordance with the LMAA Intermediate Claims Procedure applicable at the date of the commencement of the arbitration proceedings;
            </NORContentParagraph>
          </NORContentItem>

          <NORContentItem isSecondItem>
            <NORContentItemNumber>ii.</NORContentItemNumber>
            <NORContentParagraph isNoneMarginBottom>
              Where the amount claimed by the claimant is less than £100,000, excluding interest, (or such other sum as the Company and Customer may agree, and subject to (iii) below), the reference shall be to a sole arbitrator and the arbitration shall be conducted in accordance with the London Maritime Arbitrators Association (LMAA) Small Claims Procedure applicable at the date of the commencement of the arbitration proceedings;
            </NORContentParagraph>
          </NORContentItem>

          <NORContentItem isSecondItem>
            <NORContentItemNumber>iii.</NORContentItemNumber>
            <NORContentParagraph isNoneMarginBottom>
              In any case where neither of the LMAA Procedures referred to in (i) and/or (ii) above applies, the reference shall be to three arbitrators in accordance with the LMAA Terms applicable at the date of the commencement of the arbitration proceedings.
            </NORContentParagraph>
          </NORContentItem>

          <NORContentTitle>31.  Contact Information</NORContentTitle>

          <NORContentParagraphPaddingLeft isNoneMarginBottom>
            Skypace Limited
          </NORContentParagraphPaddingLeft>
          <NORContentParagraphPaddingLeft isNoneMarginBottom>
            Kemp House
          </NORContentParagraphPaddingLeft>
          <NORContentParagraphPaddingLeft isNoneMarginBottom>
            160, City Road London
          </NORContentParagraphPaddingLeft>
          <NORContentParagraphPaddingLeft isNoneMarginBottom>
            EC1V 2NX
          </NORContentParagraphPaddingLeft>
          <NORContentParagraphPaddingLeft isNoneMarginBottom>
            VAT 362 6787 62
          </NORContentParagraphPaddingLeft>
          <NORContentParagraphPaddingLeft
            isNoneMarginBottom
          >
            legal@skypace.com
          </NORContentParagraphPaddingLeft>

          <br />

          <NORContentParagraphPaddingLeft isNoneMarginBottom>
            Partner of the Company for your future orders will be:
          </NORContentParagraphPaddingLeft>
          <NORContentParagraphPaddingLeft isNoneMarginBottom>
            Maxton Shipping Sp. z o.o., NIP 5213968426.
          </NORContentParagraphPaddingLeft>
          <NORContentParagraphPaddingLeft isNoneMarginBottom>
            22B, Bartycka, office 21A, Warsaw, 00-716, Poland
          </NORContentParagraphPaddingLeft>
          <NORContentParagraphPaddingLeft isNoneMarginBottom>
            They will directly handle the transportation and related services.
          </NORContentParagraphPaddingLeft>

          <Signatures>
            <Signature>
              <SignatureName>
                {createdByName || ''}
              </SignatureName>

              <SignatureDivider />

              <SignatureLabel>
                {t('Client Authorized Signature')}
              </SignatureLabel>
            </Signature>

            <Signature>
              <SignatureName>
                {t('Uladzimir Nikalayeu')}
              </SignatureName>

              <SignatureDivider />

              <SignatureLabel>
                {t('Skypace Authorized Signature')}
              </SignatureLabel>
            </Signature>
          </Signatures>
        </NORContent>
      </Wrap>

      {/* <Row style={{ marginTop: 18 }}>
        <Bold>
          {t('Date of the Agreement Acceptance & Signature')}
        </Bold>

        {createdAt ? (
          <Text>
            {createdAt}
          </Text>
        ) : null}
      </Row> */}
    </NORContentWrap>
  );
});
