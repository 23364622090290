import styled, { css } from 'styled-components';

export const textColorFirst = css`
  color: ${({ theme }) => theme.themesColors.characterBrandingTitle85};
`;

export const divFlex = css`
  display: flex;
  position: relative;
  ${textColorFirst}
`;

// Div

export const RFQNotFoundRecommendedDiv = styled.div.attrs({
  'data-class': 'RFQNotFoundRecommendedDiv',
})`
  ${divFlex}
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  min-height: 83vh;
`;

export const RFQNotFoundRecommendedDivWrapper = styled.div`
  ${divFlex}
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

// Content

export const RFQNotFoundRecommendedText = styled.div`
  ${divFlex}
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
  margin-bottom: 24px;
`;

export const RFQNotFoundRecommendedTextFirst = styled.div`
  ${divFlex}
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.themesColors.characterBrandingTitle85};
  font-weight: 400;
  font-size: 14px;
`;

export const RFQNotFoundRecommendedTextSecond = styled.div`
  ${divFlex}
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.themesColors.characterBrandingTitle85};
  font-weight: 400;
  font-size: 12px;
`;

export const RFQNotFoundRecommendedTextButton = styled.div`
  ${divFlex}
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.themesColors.neutralBranding1};
  font-weight: 400;
  font-size: 16px;
`;
