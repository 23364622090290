import React, { useMemo } from 'react';
import Typography from 'antd/es/typography';
import Spin from 'antd/es/spin';
import { useTranslation } from 'react-i18next';

import { REQUEST_STATUS } from 'app-wrapper/constants';
import i18n from 'app-wrapper/i18n/i18n';

export const useNotFoundContent = (status: string, isResult: boolean) => {
  const { t } = useTranslation();

  const notFoundContent = useMemo(() => {
    if (status === REQUEST_STATUS.pending) {
      return <Spin size="small" />;
    }

    if (status === REQUEST_STATUS.complete && !isResult) {
      return (
        <Typography.Text>
          {t('Validations.string.noResults')}
        </Typography.Text>
      );
    }

    return null;
  }, [isResult, status, t]);
  return notFoundContent;
};

export const getNotFoundContent = (status: string, isResult: boolean) => {
  if (status === REQUEST_STATUS.pending) {
    return <Spin size="small" />;
  }

  if (status === REQUEST_STATUS.complete && !isResult) {
    return (
      <Typography.Text>
        {i18n.t('Validations.string.noResults')}
      </Typography.Text>
    );
  }

  return null;
};
