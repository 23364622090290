import React from 'react';

import {
  Wrap,
  Content,
} from './ScheduleAndTrack.styled';
import { Schedule, Tracker } from './components';

export const ScheduleAndTrack = () => (
  <Wrap>
    <Content>
      <Schedule />
    </Content>

    <Content>
      <Tracker />
    </Content>
  </Wrap>
);
