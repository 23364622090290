import React, { FC, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { DocumentType } from 'shipment-operations/constants';
import { CustomerDocumentsMap } from 'user-management/models/dtm';

import {
  Wrap,
  Header,
  Title,
  TitleSecond,
} from './Documents.styled';
import { DocumentsSection } from '../DocumentsSection';

interface DocumentsComponentProps {
  organizationId: number;
  documentTypesMap: CustomerDocumentsMap;
  openDocumentUploadDrawer: (documentType: DocumentType) => void;
  onDownload: (organizationId: string, documentId: number) => void;
  onDelete: (organizationId: string, documentId: number) => void;
  isBLICRequired: boolean;
}

export const DocumentsComponent: FC<DocumentsComponentProps> = ({
  organizationId,
  documentTypesMap,
  openDocumentUploadDrawer,
  onDownload,
  isBLICRequired,
  onDelete,
}) => {
  const { t } = useTranslation();

  const documentsALO = useMemo(() => documentTypesMap[DocumentType.ALO] || [], [documentTypesMap]);
  const documentsCCI = useMemo(() => documentTypesMap[DocumentType.CCI] || [], [documentTypesMap]);
  const documentsBLIC = useMemo(() => documentTypesMap[DocumentType.BLIC] || [], [documentTypesMap]);
  const documentsTCER = useMemo(() => documentTypesMap[DocumentType.TCER] || [], [documentTypesMap]);
  const documentsMSC = useMemo(() => documentTypesMap[DocumentType.MISC] || [], [documentTypesMap]);

  const openDocumentALOUploadDrawer = useCallback(() => {
    openDocumentUploadDrawer(DocumentType.ALO);
  }, [openDocumentUploadDrawer]);

  const openDocumentCCIUploadDrawer = useCallback(() => {
    openDocumentUploadDrawer(DocumentType.CCI);
  }, [openDocumentUploadDrawer]);

  const openDocumentBLICUploadDrawer = useCallback(() => {
    openDocumentUploadDrawer(DocumentType.BLIC);
  }, [openDocumentUploadDrawer]);

  const openDocumentTCERUploadDrawer = useCallback(() => {
    openDocumentUploadDrawer(DocumentType.TCER);
  }, [openDocumentUploadDrawer]);

  const openDocumentMSCUploadDrawer = useCallback(() => {
    openDocumentUploadDrawer(DocumentType.MISC);
  }, [openDocumentUploadDrawer]);

  return (
    <Wrap>
      <Header>
        <Title>
          {t('Group')}
        </Title>

        <TitleSecond>
          {t('Action')}
        </TitleSecond>
      </Header>

      <DocumentsSection
        sectionName={t('Bill stating Name & Address of Organization *')}
        documents={documentsALO}
        openDocumentUploadDrawer={openDocumentALOUploadDrawer}
        onDownload={onDownload}
        onRemove={onDelete}
        organizationId={organizationId}
      />
      <DocumentsSection
        sectionName={t('Copy of Company Identity/Business Card *')}
        documents={documentsCCI}
        openDocumentUploadDrawer={openDocumentCCIUploadDrawer}
        onDownload={onDownload}
        onRemove={onDelete}
        organizationId={organizationId}
      />
      {isBLICRequired ? (
        <DocumentsSection
          sectionName={t('Business License *')}
          documents={documentsBLIC}
          openDocumentUploadDrawer={openDocumentBLICUploadDrawer}
          onDownload={onDownload}
          onRemove={onDelete}
          organizationId={organizationId}
        />
      ) : null}
      <DocumentsSection
        sectionName={t('Tax Registration *')}
        documents={documentsTCER}
        openDocumentUploadDrawer={openDocumentTCERUploadDrawer}
        onDownload={onDownload}
        onRemove={onDelete}
        organizationId={organizationId}
      />
      <DocumentsSection
        sectionName={t('Other')}
        documents={documentsMSC}
        openDocumentUploadDrawer={openDocumentMSCUploadDrawer}
        onDownload={onDownload}
        onRemove={onDelete}
        organizationId={organizationId}
        isOptional
      />
    </Wrap>
  );
};
