import React, {
  FC, useCallback, useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import WarningFilled from '@ant-design/icons/WarningFilled';

import { ETransportationType } from 'monetary/constants';
import { ITransportPlanChanges } from 'shipment-operations/models/dtm';
import { ShipIcon, TrainSvg, TruckSvg } from 'app-wrapper/view/icons';

import { SEA, WATER } from 'shipment-operations/constants';
import {
  Dashed, Header, Line, PlaceMain, PlaceWrapper, Point, RouteWrapper, TimeWrapper,
  Title, VesselVoyageWrapper, Wrapper, DataWrapper, Main, Value,
} from './TransportPlan.styled';

interface ITransportPlanProps {
  currentTitle?: string;
  requestedTitle?: string;
  mismatchedTitle?: string;
  currentData?: ITransportPlanChanges[];
  requestedData?: ITransportPlanChanges[];
  mismatchedData?: ITransportPlanChanges[];
  withMismatch?: boolean;
}

const TransportPlanComponent: FC<ITransportPlanProps> = ({
  currentTitle, requestedTitle, mismatchedTitle, currentData, requestedData, mismatchedData, withMismatch,
}) => {
  const { t } = useTranslation();
  const renderIcon = useCallback((type) => {
    switch (type) {
      case ETransportationType.SEA:
      case ETransportationType.WATER:
      case ETransportationType.OCEAN:
        return <ShipIcon color="#73819B" />;

      case ETransportationType.RAIL:
        return <TrainSvg style={{ color: '#73819B' }} />;

      case ETransportationType.ROAD:
        return <TruckSvg fill="#73819B" />;

      default:
        return null;
    }
  }, []);

  const blockWidth = useMemo(() => {
    const dataArr = [currentData, requestedData, mismatchedData];
    const dataLength = dataArr.filter((item) => item).length;
    if (dataLength === 1) {
      return '100%';
    }
    if (dataLength === 2) {
      return '47%';
    }
    return '32%';
  }, []);

  const renderLayout = useCallback((transportData?: ITransportPlanChanges[], title?: string, isMismatch?: boolean) => {
    if (!transportData) {
      return null;
    }
    const dataLength = transportData.length;

    return (
      <div style={{ width: blockWidth }}>
        <Header>
          <Title>{title}</Title>
        </Header>
        <Wrapper>
          <RouteWrapper style={{ width: '5%', marginTop: '15px', marginRight: '10px' }}>
            {transportData.map((item, index) => {
              let isChanged = false;
              const matchedLocation = requestedData?.find((el) => el.location === item.location);
              if (isMismatch) {
                if (matchedLocation) {
                  isChanged = item.type !== matchedLocation.type;
                  if ([SEA, WATER].includes(item.type) && [SEA, WATER].includes(matchedLocation.type)) {
                    isChanged = false;
                  }
                } else {
                  isChanged = true;
                }
              }
              if (index === dataLength - 1) {
                return isChanged ? <WarningFilled key={item.customId} style={{ color: '#DA9D00' }} /> : <Point key={item.customId} />;
              }
              return (
                <React.Fragment key={item.customId}>
                  {isChanged ? <WarningFilled style={{ color: '#DA9D00' }} /> : <Point />}
                  <Line />
                  {renderIcon(item.type)}
                  <Line />
                </React.Fragment>
              );
            })}
          </RouteWrapper>
          <RouteWrapper style={{ width: '95%' }}>
            {transportData.map((item, index) => {
              let isChangedETA = false;
              let isChangedETD = false;
              let isChangedLocation = false;
              let isChangedVessel = false;
              let isChangedVoyage = false;
              const matchedLocation = requestedData?.find((el) => el.location === item.location);
              if (!matchedLocation && isMismatch) {
                isChangedETA = true;
                isChangedETD = true;
                isChangedLocation = true;
                isChangedVessel = true;
                isChangedVoyage = true;
              }
              if (isMismatch && matchedLocation) {
                isChangedETA = item.etaTime !== matchedLocation.etaTime;
                isChangedETD = item.etdTime !== matchedLocation.etdTime;
                isChangedLocation = item.location !== matchedLocation.location;
                isChangedVessel = item.vessel !== matchedLocation.vessel;
                isChangedVoyage = item.voyage !== matchedLocation.voyage;
              }
              const renderETA = isChangedETA ? (
                <div>
                  <WarningFilled style={{ color: '#DA9D00' }} />
                  <span style={{ color: '#DA9D00', marginLeft: '5px' }}>{t('eta')} {item.etaTime}</span>
                </div>
              ) : (
                <Value>{t('eta')} {item.etaTime}</Value>
              );
              const renderETD = isChangedETD ? (
                <div>
                  <WarningFilled style={{ color: '#DA9D00' }} />
                  <span style={{ color: '#DA9D00', marginLeft: '5px' }}>{t('etd')} {item.etdTime}</span>
                </div>
              ) : (
                <Value>{t('etd')} {item.etdTime}</Value>
              );
              if (index === dataLength - 1) {
                return (
                  <React.Fragment key={item.customId}>
                    <PlaceWrapper>
                      <PlaceMain style={{ color: isChangedLocation ? '#DA9D00' : '' }}>{item.location}</PlaceMain>
                      <TimeWrapper>
                        {item.etaTime && renderETA}
                        {item.etdTime && renderETD}
                      </TimeWrapper>
                    </PlaceWrapper>
                  </React.Fragment>
                );
              }
              return (
                <React.Fragment key={item.customId}>
                  <PlaceWrapper>
                    <PlaceMain style={{ color: isChangedLocation ? '#DA9D00' : '' }}>{item.location}</PlaceMain>
                    <TimeWrapper>
                      {item.etaTime && renderETA}
                      {item.etdTime && renderETD}
                    </TimeWrapper>
                  </PlaceWrapper>
                  <Dashed />
                  <VesselVoyageWrapper>
                    {
                      isChangedVessel ? (
                        <div>
                          <WarningFilled style={{ color: '#DA9D00' }} />
                          <span style={{ color: '#DA9D00' }}>{t('vessel')} {item.vessel}</span>
                        </div>
                      ) : (
                        <Value>{t('vessel')} {item.vessel}</Value>
                      )
                    }
                    {
                      isChangedVoyage ? (
                        <div>
                          <WarningFilled style={{ color: '#DA9D00' }} />
                          <span style={{ color: '#DA9D00' }}>{t('voyage')} {item.voyage}</span>
                        </div>
                      ) : (
                        <Value>{t('voyage')} {item.voyage}</Value>
                      )
                    }
                  </VesselVoyageWrapper>
                  <Dashed />
                </React.Fragment>
              );
            })}
          </RouteWrapper>
        </Wrapper>
      </div>
    );
  }, []);

  return (
    <Main>
      <DataWrapper>
        {!!currentData && renderLayout(currentData, currentTitle)}
        {!!requestedData && renderLayout(requestedData, requestedTitle)}
        {!!mismatchedData && renderLayout(mismatchedData, mismatchedTitle, withMismatch)}
      </DataWrapper>
    </Main>
  );
};

export { TransportPlanComponent as TransportPlan };
