import styled from 'styled-components';

import { divFlex } from 'app-wrapper/view/themes/mixin';

export const ARQItemSubCardDescriptionsContainer = styled.div.attrs((props: {
  isVerticalCenter: boolean,
  marginLeft: string,
}) => ({
  isVerticalCenter: props.isVerticalCenter,
  marginLeft: props.marginLeft,
  'data-class': 'ARQItemSubCardDescriptionsContainer',
}))`
  ${divFlex}
  flex-direction: column;
  ${({ isVerticalCenter }) => isVerticalCenter && 'justify-content: center;'};
  ${({ marginLeft }) => marginLeft && `margin-left: ${marginLeft};`};

  padding: 8px 0 10px 0;
  width: 100%;
  height:  100%;
`;

export const ARQItemSubCardDescriptionsContainerSpanClip = styled.span.attrs({
  'data-class': 'ARQItemSubCardDescriptionsContainerSpanClip',
})`
  display: inline;
  max-width: 80%;

  text-overflow: ellipsis;
  overflow: clip;
  white-space: nowrap;
`;

export const ARQItemSubCardDescriptionsContainerTitle = styled.div.attrs({
  'data-class': 'ARQItemSubCardDescriptionsContainerTitle',
})`
  ${divFlex}
  height: 22px;
  align-items: center;

  color: ${({ theme }) => theme.themesColors.secondaryDot45};
  font-size: 12px;
  font-weight: 400;

  span {
    line-height: 18px;
  }
`;

export const ARQItemSubCardDescriptionsContainerContent = styled.div.attrs({
  'data-class': 'ARQItemSubCardDescriptionsContainerContent',
})`
  ${divFlex}
  height: 16px;
  align-items: center;

  color: ${({ theme }) => theme.themesColors.neutralBranding10};
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;

  span {
    line-height: 16px;
  }
`;

export const ARQItemSubCardDescriptionsContainerFooter = styled.div.attrs({
  'data-class': 'ARQItemSubCardDescriptionsContainerFooter',
})`
  ${divFlex}
  height: 22px;
  align-items: center;

  color: ${({ theme }) => theme.themesColors.secondaryDot45};
  font-size: 12px;
  font-weight: 400;

  span {
    line-height: 20px;
  }
`;
