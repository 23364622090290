import React, { FC, useCallback } from 'react';
import { CargoBaseDTM } from 'shipment-operations/models/dtm';

import { CardSelectCheckbox } from './CardSelectCheckbox.styled';

interface ICardSelectCheckboxComponentProps {
  cargo: CargoBaseDTM
  checked: boolean
  disabled: boolean
  onChange: (cargo: CargoBaseDTM) => void
}

export const CardSelectCheckboxComponent: FC<ICardSelectCheckboxComponentProps> = ({
  cargo,
  checked,
  disabled,
  onChange,
}) => {
  const onChangeHandler = useCallback(() => {
    onChange(cargo);
  }, [cargo]);

  return (
    <CardSelectCheckbox
      checked={checked}
      disabled={disabled}
      onChange={onChangeHandler}
    />
  );
};
