import styled from 'styled-components';

import themesColors from 'app-wrapper/view/themes/themesColors';

export const UserProfileContent = styled.div.attrs({
  'data-class': 'UserProfileContent',
})`
  display: grid;
  grid-template-columns: 1fr 1px 1fr;
  grid-template-rows: 80px 1px auto;
  grid-template-areas: 'UserProfileHeader UserProfileHeader UserProfileHeader'
  'UserProfileHorizontalSeparator UserProfileHorizontalSeparator UserProfileHorizontalSeparator'
  'UserProfileLeftBox UserProfileVerticalSeparator UserProfileRightBox';
  column-gap: 16px;
  row-gap: 16px;
  
  font-size: 14px;
`;

export const UserProfileContentHeader = styled.div`
  grid-area: UserProfileHeader;
`;

export const UserProfileLeftBox = styled.div`
  grid-area: UserProfileLeftBox;
`;

export const UserProfileRightBox = styled.div`
  grid-area: UserProfileRightBox;
`;

export const UserProfileHorizontalSeparator = styled.div`
  grid-area: UserProfileHorizontalSeparator;

  background-color: ${themesColors.neutralBranding1};
  border-top: 1px solid ${themesColors.neutralBranding4};

  height: 1px;
`;

export const UserProfileVerticalSeparator = styled.div`
  grid-area: UserProfileVerticalSeparator;

  background-color: ${themesColors.neutralBranding1};
  border-left: 1px dashed ${themesColors.neutralBranding4};
  
  width: 1px;
`;
