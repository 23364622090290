import { createSlice } from 'app-wrapper/createSlice';

import { IShipmentTransportationChargesState } from 'shipment-operations/models/states';

const initialState: IShipmentTransportationChargesState = {
  data: [],
  error: false,
  isLoading: false,
  isPercentage: false,
};

export const ShipmentTransportationChargesSlice = createSlice({
  name: 'shipmentTransportationCharges',
  initialState,
  reducers: {
    setIsLoading: (state, { payload }) => ({
      ...state,
      isLoading: payload,
    }),
    setError: (state, { payload }) => ({
      ...state,
      isLoading: false,
      error: payload,
    }),
    setData: (state, { payload }) => ({
      ...state,
      isLoading: false,
      error: false,
      data: payload,
    }),
    setPercentage: (state, { payload }) => ({
      ...state,
      isPercentage: payload,
    }),
    clear: () => (initialState),
  },
});

export const shipmentTransportationChargesActions = ShipmentTransportationChargesSlice.actions;
export const shipmentTransportationChargesReducer = ShipmentTransportationChargesSlice.reducer;
