import { Factory } from 'fishery';
import { DetailBreakdownDTM } from './detailBreakdown.dtm';

export const waypointDTMFactory = Factory.define<DetailBreakdownDTM>(() => DetailBreakdownDTM.fromPlain({
  transportation: {
    summary: 5192,
    containers: [
      {
        name: '20` Dry Standard',
        steps: [
          {
            name: 'Freight',
            cost: 5187,
            totalUnits: 1,
            summary: 5187,
            infoSteps: [
              'Surcharge EBS',
            ],
          },
          {
            name: 'Surcharge HAZ',
            cost: 1,
            totalUnits: 1,
            summary: 1,
          },
          {
            name: 'LOLO freight BOL(cargo ships with on-board cranes to load and unload cargo)',
            cost: 4,
            totalUnits: 1,
            summary: 4,
          },
        ],
        summary: 5192,
        cost: 5187,
        totalUnits: 1,
      },
    ],
  },
  fees: {
    summary: 8.3,
    steps: [
      {
        name: 'Surcharge 789',
        summary: 3.3,
        cost: 3.3,
        totalUnits: 1,
      },
      {
        name: 'Freifgh bol ADMFO surcharge',
        summary: 5,
        cost: 5,
        totalUnits: 1,
      },
    ],
  },
  fieldName: 'Freight',
  summary: 5200.3,
  paymentTerms: '',
}));

export const waypointDTMFactoryOrigin = Factory.define<DetailBreakdownDTM>(() => DetailBreakdownDTM.fromPlain({
  transportation: {
    summary: 0,
    containers: [
      {
        name: '20` Dry Standard',
        steps: [
          {
            name: 'Surcharge 789',
            cost: 10,
            totalUnits: 1,
            summary: 10,
          },
        ],
        summary: 10,
        cost: 5187,
        totalUnits: 1,
      },
    ],
  },
  fees: {
    summary: 6.2,
    steps: [
      {
        name: 'MDF BOL ORIGIN SURCH',
        summary: 6.2,
        cost: 6.2,
        totalUnits: 1,
      },
    ],
  },
  fieldName: 'Origin',
  summary: 16.2,
  paymentTerms: '',
}));
