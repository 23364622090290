import {
  IsBoolean,
  IsDefined, IsEnum, IsOptional, IsString,
} from 'class-validator';
import { Transform, Type } from 'class-transformer';
import moment from 'moment/moment';
import { Moment } from 'moment';
import { BaseDTM } from 'proto/BaseDTM';

import { ContainerReeferTypes, ContainerUsualTypes } from 'shipment-operations/constants';

// TODO: rework this model with Nehved
export interface IContainerNumberDTM {
  number: string
  type: ContainerReeferTypes | ContainerUsualTypes
  typeDescription: string
  registeredAt: Moment
  isManual?: boolean
}

export class ContainerNumberDTM extends BaseDTM<IContainerNumberDTM> {
  @IsDefined()
  @IsString()
  number: string

  @IsDefined()
  @IsString()
  typeDescription: string

  @IsDefined()
  @IsEnum([...Object.values(ContainerReeferTypes), ...Object.values(ContainerUsualTypes)])
  type: ContainerReeferTypes | ContainerUsualTypes;

  @IsDefined()
  @Type(() => Date)
  @Transform(({ value }) => value && moment(value), { toClassOnly: true })
  registeredAt: Moment;

  @IsOptional()
  @IsBoolean()
  isManual?: boolean;
}
