import styled from 'styled-components';
import { ClockCircleOutlined } from '@ant-design/icons';
import AntTag from 'antd/es/tag';

import { FlexCol, FlexRow } from 'app-wrapper/view/guideline';

export const Result = styled(FlexCol)`
  height: 46px;
`;

export const ResultHeader = styled(FlexRow)`
  gap: 8px;
`;

export const ResultsHeaderTitle = styled.span`
  font-size: 12px;
  line-height: 20px;
  color: ${({ theme }) => theme.themesColors.characterSecondaryDot45};
`;

export const ResultsValue = styled.span`
  margin-top: auto;
  font-size: 14px;
  line-height: 22px;
`;

export const Tag = styled(AntTag)`
  color: ${({ theme }) => theme.themesColors.characterSecondaryDot45};
  background-color: ${({ theme }) => theme.themesColors.neutralBranding3};
  border: 1px solid ${({ theme }) => theme.themesColors.neutralBranding4};
`;

export const ClockIcon = styled(ClockCircleOutlined)`
  font-size: 11px;
`;
