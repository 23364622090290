import React from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';
import { UC } from 'shipment-operations/controllers';

import { DrawerFooterComponent } from './DrawerFooter.component';

const DrawerFooterContainer = () => {
  const isAbleToSave = useSelector(R.selectors.shippingParties.getIsAbleToSaveSelectedCompany);

  return (
    <DrawerFooterComponent
      onClose={UC.shippingParties.closeSelectCompanyDrawer}
      onSave={UC.shippingParties.saveSelectedCompany}
      isAbleToSave={isAbleToSave}
    />
  );
};

export { DrawerFooterContainer as DrawerFooter };
