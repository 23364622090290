import React from 'react';

import { IPropsIcon } from 'app-wrapper/interfaces/icon';

import { Icon } from './SkypaceLoadingCheck.styled';

export const SkypaceLoadingCheck = (props: IPropsIcon) => {
  const { width, height, fill } = props;
  return (
    <Icon
      className="SkypaceLoadingCheck"
      width={width || '15'}
      height={height || '15'}
      viewBox="0 0 15 15"
      fill={fill || 'currentColor'}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M7.5 0.5C3.63438 0.5 0.5 3.63438 0.5 7.5C0.5 11.3656 3.63438 14.5 7.5 14.5C11.3656 14.5 14.5 11.3656 14.5 7.5C14.5 3.63438 11.3656 0.5 7.5 0.5ZM10.5234 5.21406L7.23281 9.77656C7.18682 9.84076 7.12619 9.89306 7.05595 9.92914C6.98571 9.96523 6.90787 9.98405 6.82891 9.98405C6.74994 9.98405 6.67211 9.96523 6.60186 9.92914C6.53162 9.89306 6.47099 9.84076 6.425 9.77656L4.47656 7.07656C4.41719 6.99375 4.47656 6.87813 4.57812 6.87813H5.31094C5.47031 6.87813 5.62187 6.95469 5.71562 7.08594L6.82812 8.62969L9.28438 5.22344C9.37813 5.09375 9.52812 5.01562 9.68906 5.01562H10.4219C10.5234 5.01562 10.5828 5.13125 10.5234 5.21406Z" fill="currentColor" />
    </Icon>
  );
};
