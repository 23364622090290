import React, { FC, ReactNode } from 'react';

import { SkeletonSection } from 'app-wrapper/view/components/Skeleton/Skeleton.component';

import {
  ShipmentDetailsFormContainer,
  ShipmentDetailsFormFooter,
  ShipmentDetailsFormScroll,
  ShipmentDetailsFormWrapper,
} from './ShipmentDetailsForm.styled';

interface IShipmentDetailsFormProps {
  footer?: ReactNode
  isLoading?: boolean
}

export const ShipmentDetailsForm: FC<IShipmentDetailsFormProps> = ({
  footer,
  isLoading = false,
  children,
}) => (!isLoading ? (
  <ShipmentDetailsFormWrapper>
    <ShipmentDetailsFormScroll>
      <ShipmentDetailsFormContainer>
        {children}
      </ShipmentDetailsFormContainer>
    </ShipmentDetailsFormScroll>
    {footer && (
      <ShipmentDetailsFormFooter>
        {footer}
      </ShipmentDetailsFormFooter>
    )}
  </ShipmentDetailsFormWrapper>
) : (
  <>
    <SkeletonSection />
  </>
));
