import i18n from 'i18next';

import { ValidationErrorDTM, ValidationErrorType } from 'app-wrapper/types';

import { CargoErrorsDTM, HazmatDTM } from 'shipment-operations/models/dtm';

const getRequiredError = () => (ValidationErrorDTM.fromPlain({
  type: ValidationErrorType.DEFAULT,
  message: i18n.t('basicErrors.REQUIRED_MESSAGE'),
}));

const getUnNumberLengthError = () => (ValidationErrorDTM.fromPlain({
  type: ValidationErrorType.ALERT,
  message: i18n.t('shipmentContainerErrors.UN_LENGTH_MESSAGE'),
}));

export const validateHazmat = (
  unNumber: HazmatDTM['unNumber'],
  imoClass: HazmatDTM['imoClass'],
  packingGroup: HazmatDTM['packingGroup'],
  shippingName: HazmatDTM['shippingName'],
  msdsDocument: HazmatDTM['msdsDocument'],
) => {
  const errors = CargoErrorsDTM.createEmpty();

  if (!unNumber) {
    errors.unNumber = getRequiredError();
  }
  if (unNumber && unNumber.length !== 4) {
    errors.unNumber = getUnNumberLengthError();
  }
  if (!imoClass) {
    errors.imoClass = getRequiredError();
  }
  if (!shippingName) {
    errors.shippingName = getRequiredError();
  }
  if (!packingGroup) {
    errors.packingGroup = getRequiredError();
  }
  if (!(
    msdsDocument
    && msdsDocument.length
    && msdsDocument[0].status === 'done'
    && msdsDocument[0].response.id
    && msdsDocument[0].response.name
    && msdsDocument[0].response.type
  )) {
    errors.msdsDocument = getRequiredError();
  }

  return errors;
};
