import styled, { css } from 'styled-components';

import { divFlex } from 'app-wrapper/view/themes/mixin';
import { Button, Input } from 'app-wrapper/view/components';

const cssInput = css`
  border: 1px solid ${({ theme }) => theme?.themesColors?.middleGrayDisable};

  &::placeholder {
    font-size: 12px;
    font-weight: 400;
    color: ${({ theme }) => theme?.themesColors?.darkGrayMiddle};
    opacity: 1;
  }
`;

const cssButton = css`
  font-size: 14px;
  font-weight: 400;
  padding: 1px 8px 1px 8px;
  border-radius: 3px;
  color: ${({ theme }) => theme?.themesColors?.neutralBranding1};
  height: 24px;
`;

export const FMMEWrapper = styled.div.attrs({
  'data-class': 'FMMEWrapper',
})`
  ${divFlex}
`;

export const FMMEHeader = styled.div.attrs({
  'data-class': 'FMMEHeader',
})`
  ${divFlex}
`;

export const FMMEHeaderNameWrapper = styled.div.attrs({
  'data-class': 'FMMEHeaderNameWrapper',
})`
  ${divFlex}
  cursor: pointer;
`;

export const FMMEHeaderUpdateDot = styled.div.attrs({
  'data-class': 'FMMEHeaderUpdateDot',
})`
  ${divFlex}
  width: 6px;
  height: 6px;
  border-radius: 50%;
  margin-right: 2px;
  background-color: ${({ theme }) => theme?.themesColors?.primaryBranding6};
`;

export const FMMEHeaderName = styled.div.attrs((props: {
  isActive: boolean,
}) => ({
  isActive: props.isActive,
  'data-class': 'FMMEHeaderName',
}))`
  ${divFlex}
  font-size: 12px;
  font-weight: 400;
  color: ${({ theme, isActive }) => (isActive ? theme?.themesColors?.darkGrayMiddle : theme?.themesColors?.neutralBranding10)};
`;

export const FMMEHeaderArrow = styled.div.attrs((props: {
  isActive: boolean,
}) => ({
  isActive: props.isActive,
  'data-class': 'FMMEHeaderArrow',
}))`
  ${divFlex}
  margin-left: 6px;

  justify-content: center;
  align-items: center;

  svg {
    color: ${({ theme, isActive }) => (isActive ? theme?.themesColors?.primaryBranding6 : theme?.themesColors?.neutralBranding7)};
  }
`;

export const FMMEModal = styled.div.attrs({
  'data-class': 'FMMEModal',
})`
  ${divFlex}
  position: absolute;
  bottom: -100%;
  z-index: 7;
  right: -22px;
  height: 100%;
`;

export const FMMEModalWrapper = styled.div.attrs({
  'data-class': 'FMMEModalWrapper',
})`
  ${divFlex}
  flex-direction: column;
  padding: 16px;
  top: 7px;

  background-color: ${({ theme }) => theme?.themesColors?.neutralBranding1};
  border-radius: 2px;
  width: 312px;
  height: 96px;
  filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.15));

  ::after {
    content: '';
    position: absolute;
    top: -10px;
    right: 16px;

    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 10px 10px 10px;
    border-color: transparent transparent ${({ theme }) => theme?.themesColors?.neutralBranding1} transparent;
  }
`;

export const FMMEModalInputs = styled.div.attrs({
  'data-class': 'FMMEModalInputs',
})`
  ${divFlex}
`;

export const FMMEModalInputMin = styled(Input).attrs({
  'data-class': 'FMMEModalInputMin',
})`
  ${divFlex}
  ${cssInput}
  border-radius: 2px 0px 0px 2px;
`;

export const FMMEModalInputMax = styled(Input).attrs({
  'data-class': 'FMMEModalInputMax',
})`
  ${divFlex}
  ${cssInput}
  border-radius: 0px 2px 2px 0px;
  border-left: none;
`;

export const FMMEModalButtons = styled.div.attrs({
  'data-class': 'FMMEModalButtons',
})`
  ${divFlex}
  padding-top: 8px;
  justify-content: end;
`;

export const FMMEModalButtonReset = styled(Button).attrs({
  'data-class': 'FMMEModalButtonReset',
})`
  ${divFlex}
  ${cssButton}
  margin-right: 8px;
  border: none;
  color: ${({ theme }) => theme?.themesColors?.darkGrayMiddle};
  background: none;
  box-shadow: none;

  :hover, :focus, :active, :not([disabled]):active {
    color: ${({ theme }) => theme?.themesColors?.primaryBranding6};
    background: none;
    box-shadow: none;
    text-shadow: none
  }
  :active, :not([disabled]):active {
    color: ${({ theme }) => theme?.themesColors?.primaryBranding5};
    background: none;
    box-shadow: none;
    text-shadow: none
  }
  :disabled {
    color: ${({ theme }) => theme?.themesColors?.neutralBranding6};
    background: none;
    box-shadow: none;
  }
  ::after {
    border: none;
    content: '';
    box-shadow: none;
    display: none;
    text-shadow: none
  }
`;

export const FMMEModalButtonApply = styled(Button).attrs({
  'data-class': 'FMMEModalButtonApply',
})`
  ${divFlex}
  ${cssButton}
`;
