import React from 'react';
import { useSelector } from 'react-redux';

import { R } from 'user-management/repository';
import { UC } from 'user-management/controllers';

import { TeamDrawerFooterComponent } from './TeamDrawerFooter.component';

const TeamDrawerFooterContainer = () => {
  const isAbleToSave = useSelector(R.selectors.teams.getIsAbleToCreateOrSaveTeam);
  const isDrawerInEditMode = useSelector(R.selectors.teams.getIsDrawerInEditMode);

  return (
    <TeamDrawerFooterComponent
      onClose={UC.teams.closeTeamDrawer}
      onSave={UC.teams.handleCreateOrSaveTeam}
      isEditMode={isDrawerInEditMode}
      isAbleToSave={isAbleToSave}
    />
  );
};

export { TeamDrawerFooterContainer as TeamDrawerFooter };
