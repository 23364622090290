import { action, makeObservable } from 'mobx';
import { BaseMobxStore } from 'proto/BaseMobxStore';
import { TUserEditProfileState } from 'user-management/models/states';

export const initialState: TUserEditProfileState = {
  isLoading: false,
};

export class UserEditProfileStore<T extends TUserEditProfileState = TUserEditProfileState> extends BaseMobxStore<TUserEditProfileState> {
  initialState = initialState

  constructor(_initialState?: T) {
    super();
    makeObservable(this);
    this.init(_initialState || this.initialState);
  }

  @action
  setPhone(phone: string) {
    this.state.phone = phone;
  }

  @action
  setPhoneError(error: Error) {
    this.state.phoneError = error;
  }

  @action
  clearPhoneError() {
    this.state.phoneError = undefined;
  }

  @action
  setFirstName(name: string) {
    this.state.firstName = name;
  }

  @action
  setFirstNameError(error: Error) {
    this.state.firstNameError = error;
  }

  @action
  clearFirstNameError() {
    this.state.firstNameError = undefined;
  }

  @action
  setLastName(lastName: string) {
    this.state.lastName = lastName;
  }

  @action
  setLastNameError(error: Error) {
    this.state.lastNameError = error;
  }

  @action
  clearLastNameError() {
    this.state.lastNameError = undefined;
  }

  @action
  setJobTitle(jobTitle: string) {
    this.state.jobTitle = jobTitle;
  }

  @action
  setLoading(loading: boolean) {
    this.state.isLoading = loading;
  }
}
