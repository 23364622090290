import React from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';

import { FirstPageComponent } from './FirstPage.component';

const FirstPageContainer = () => {
  const billOfLadingHBL = useSelector(R.selectors.documentHBLPDF.getHBLDocumentBOL);
  const department = useSelector(R.selectors.documentHBLPDF.getAccountHolderRelatedDocExportDepartment);
  const currentOrgRelatedAdmin = useSelector(R.selectors.documentHBLPDF.getCurrentOrgRelatedAdminPublicInfo);
  const isOriginOrDestinationFromUS = useSelector(R.selectors.shipment.getIsUsShipmentOriginOrDestination);

  return (
    <FirstPageComponent
      isOriginOrDestinationFromUS={isOriginOrDestinationFromUS}
      pricingDepartment={department}
      adminPublicInfo={currentOrgRelatedAdmin}
      data={billOfLadingHBL}
    />
  );
};

export { FirstPageContainer as FirstPage };
