import styled from 'styled-components';
import Typography from 'antd/es/typography';
import AntTag from 'antd/es/tag';

import { divFlex } from 'app-wrapper/view/themes/mixin';

export const MBLCompanyInformation = styled('div').attrs({
  'data-class': 'MBLCompanyInformation',
})`
  ${divFlex}
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
`;

export const MBLCompanyLogoWrapper = styled('div')`
  width: 27%;
  height: 100%;
`;

export const MBLCompanyInformationItem = styled('div')`
  ${divFlex}
  width: 31%;
  flex-direction: column;
  align-items: flex-start;
`;

export const MBLCompanyText = styled(Typography)`
  font-size: 14px;
  line-height: 22px;
`;

export const MBLCompanyTitle = styled(Typography)`
  font-size: 12px;
  line-height: 20px;
  color: #73819B;
`;

export const Tag = styled(AntTag)`
  background-color: #F4F5F8;
  border: 1px solid #EAEEF8;
  color: #73819B;
  font-weight: 400;
  font-size: 11px;
  line-height: 18px;
`;
