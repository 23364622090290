import { useState, useEffect } from 'react';

interface IColumns {
  title: string
  dataIndex: string
  key: string
  width: string
  editable?: boolean
  label?: string
}

const useTableEditable = (data: any, defaultColumns: IColumns[], onChange: (row: IColumns) => void) => {
  const [dataSource, setDataSource] = useState(data);

  useEffect(() => {
    const dataWithErrors = dataSource.map((item: any) => {
      const matchedElem = data.find((elem: any) => elem.key === item.key);
      if (matchedElem) {
        return {
          ...item,
          isError: matchedElem.isError,
          datesError: matchedElem.datesError,
        };
      }
      return item;
    });
    setDataSource(dataWithErrors);
  }, [data]);

  const handleSave = (row: any) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    setDataSource(newData);
    onChange(row);
  };

  const columns = defaultColumns.map((col: IColumns) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: any) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        label: record.label || '',
        isMismatch: record.isMismatch || false,
        isError: record.isError || false,
        datesError: record.datesError || false,
        emptyRender: record.emptyRender || false,
        handleSave,
      }),
    };
  });

  return {
    dataSource,
    columns,
  };
};

export { useTableEditable };
