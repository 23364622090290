import { useCallback } from 'react';

import { isInViewport } from 'app-wrapper/utils';

export const useCollapseOnChangeHandler = (
  endVisible: HTMLElement | null, callback?: () => void,
) => useCallback(
  (value) => {
    if (value.length && endVisible) {
      setTimeout(() => {
        if (!isInViewport(endVisible, { bottom: 64 })) {
          endVisible.scrollIntoView();
        }
      }, 150);
    }
    if (callback) {
      callback();
    }
  },
  [callback, endVisible],
);

export const CollapseOnChangeHandler = (
  endVisible: HTMLElement | null, callback?: () => void,
) => (value: string | string[]) => {
  if (value.length && endVisible) {
    setTimeout(() => {
      if (!isInViewport(endVisible, { bottom: 64 })) {
        endVisible.scrollIntoView();
      }
    }, 150);
  }
  if (callback) {
    callback();
  }
};
