import React, { FC } from 'react';

import { useAppSelector } from 'app-wrapper/hooks/hooks';

import { R } from 'authentication/repository';
import { UC } from 'authentication/controllers';

import { useSearchParams } from 'react-router-dom';
import { SignInComponent } from './SignIn.component';

const SignInContainer: FC = () => {
  const isLoading = useAppSelector(R.selectors.auth.getIsLoading);
  const emailValue = useAppSelector(R.selectors.auth.getEmail);
  const emailError = useAppSelector(R.selectors.auth.getEmailError);
  const passwordValue = useAppSelector(R.selectors.auth.getPassword);
  const passwordError = useAppSelector(R.selectors.auth.getPasswordError);
  const responseError = useAppSelector(R.selectors.auth.getResponseError);

  const [searchParams] = useSearchParams();

  return (
    <SignInComponent
      loading={isLoading}
      responseError={responseError}
      emailValue={emailValue}
      emailError={emailError}
      passwordValue={passwordValue}
      passwordError={passwordError}
      code={searchParams.get('code') || ''}
      username={searchParams.get('username') || ''}
      onEmailConfirmation={UC.auth.confirmEmail}
      onEmailFieldChange={UC.auth.setEmail}
      onEmailBlur={UC.auth.setEmailBlur}
      onEmailFocus={UC.auth.setEmailFocus}
      onPasswordFieldChange={UC.auth.setPassword}
      onPasswordBlur={UC.auth.setPasswordBlur}
      onPasswordFocus={UC.auth.setPasswordFocus}
      onSignIn={UC.auth.signIn}
    />
  );
};

export { SignInContainer as SignIn };
