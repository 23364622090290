import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Button,
  ExpandIcon,
} from './HazmatToggle.styled';

const EXPANDED_ICON_STYLES = {
  transform: 'rotate(90deg)',
};

interface HazmatToggleComponentProps {
  isHazmatToggled?: boolean;
  openHazmatSection: (cargoId: number) => void;
  closeHazmatSection: (cargoId: number) => void;
  cargoId: number;
}

export const HazmatToggleComponent: FC<HazmatToggleComponentProps> = ({
  isHazmatToggled,
  openHazmatSection,
  closeHazmatSection,
  cargoId,
}) => {
  const { t } = useTranslation();

  const toggleHazmatSection = useCallback(() => (isHazmatToggled ? closeHazmatSection(cargoId) : openHazmatSection(cargoId)), [cargoId, isHazmatToggled, openHazmatSection, closeHazmatSection]);

  return (
    <Button
      type="dashed"
      onClick={toggleHazmatSection}
      icon={(
        <ExpandIcon
          styles={{
            ...(isHazmatToggled ? EXPANDED_ICON_STYLES : {}),
          }}
        />
      )}
    >
      {t('Hazmat')}
    </Button>
  );
};
