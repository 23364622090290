import React from 'react';
import { observer } from 'mobx-react-lite';

import { useMobxStore } from 'app-wrapper/mobxStores';
import { UC as appUC } from 'app-wrapper/controllers';
import { UC } from 'user-management/controllers';

import { FooterComponent } from './Footer.component';

export const Footer = observer(() => {
  const { customerSales } = useMobxStore();

  return (
    <FooterComponent
      onCancel={appUC.drawer.closeDrawer}
      onSubmit={UC.salesRepresentative.submitSalesRepresentativeDrawer}
      disabled={!customerSales.state.tempSaleRepresentativeEmail}
    />
  );
});
