import * as yup from 'yup';
import i18n from 'i18next';

yup.addMethod(yup.string, 'phoneFormat', function isValidPhone(translation?: string) {
  return this.matches(/^\+?[0-9. ()-]{7,20}$/, {
    message: translation || i18n.t('Validations.string.phoneFormat'),
    excludeEmptyString: true,
  });
});

declare module 'yup' {
  interface StringSchema {
    phoneFormat(translation?: string): StringSchema;
  }
}
