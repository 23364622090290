import styled from 'styled-components';

export const ShipmentTransportationHeaderStatus = styled.div.attrs({
  'data-class': 'ShipmentTransportationHeaderStatus',
})`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

export const ShipmentTransportationTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;

  > .ant-typography {
    margin-bottom: 0;
  }
`;
