import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import { RouteNames } from 'app-wrapper/constants';

import {
  Wrap,
  Content,
  Image,
  TextContent,
  Title,
  Text,
  BookButton,
  Popup,
  PopupText,
  PopupContent,
  WarningIcon,
  Row,
} from './BookFirstShipment.styled';
import requestIllustrationSrc from './assets/request-illustration.png';
import bookIllustrationSrc from './assets/book-illustration.png';

interface BookFirstShipmentComponentProps {
  isOrganizationActive?: boolean;
}

export const BookFirstShipmentComponent: FC<BookFirstShipmentComponentProps> = ({ isOrganizationActive }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const goToRFQ = useCallback(() => navigate(RouteNames.FREIGHT_QUOTE()), [navigate]);

  return (
    <Wrap>
      <Content>
        <Image src={requestIllustrationSrc} />

        <TextContent>
          <Title>
            {t('Request Quote')}
          </Title>

          <Text>
            {t(`Use our smart calendar
            and live schedules.`)}
          </Text>
        </TextContent>
      </Content>

      <Content>
        <Image src={bookIllustrationSrc} />

        <TextContent>
          <Title>
            {t('Book Your First Shipment')}
          </Title>

          <Text>
            {t('We save your time for a call.')}
          </Text>

          {isOrganizationActive ? (
            <BookButton onClick={goToRFQ}>
              {t('Start Process')}
            </BookButton>
          ) : (
            <Popup
              content={(
                <PopupContent>
                  <Row>
                    <WarningIcon />

                    <PopupText>
                      {t('You will be able to make a booking once we verified your Organization.')}
                    </PopupText>
                  </Row>
                </PopupContent>
              )}
            >
              <BookButton onClick={goToRFQ}>
                {t('Start Process')}
              </BookButton>
            </Popup>
          )}
        </TextContent>
      </Content>
    </Wrap>
  );
};
