import styled from 'styled-components';

import { divFlex } from 'app-wrapper/view/themes/mixin';

export const CarrierCardBottom = styled.div.attrs({
  'data-class': 'CarrierCardBottom',
})`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const CarrierCardBottomWrap = styled.div.attrs({
  'data-class': 'CarrierCardBottomWrap',
})`
  ${divFlex}
  align-items: center;
  margin-bottom: 12px;
`;

export const CarrierCardBottomExpandButton = styled.div.attrs({
  'data-class': 'CarrierCardBottomExpandButton',
})`
  ${divFlex}
  align-items: center;
  height: 26px;

  color: ${({ theme }) => theme.themesColors.neutralBranding10};
  font-size: 14px;
  font-weight: 400;
  border: 1px solid ${({ theme }) => theme.themesColors.neutralBranding5};
  border-radius: 3px;
  padding: 5px 8px;
  cursor: pointer;

  svg {
    margin-right: 12px;
  }
`;
