import React, { FC } from 'react';
import Divider from 'antd/es/divider';
import { useTranslation } from 'react-i18next';
import isEmpty from 'lodash/fp/isEmpty';

import { calculateInvoiceSum } from 'shipment-operations/view/pages/ShipmentBillingInvoice/utils/calculateSum';
import { ContainerViewDTM, IGroupedInvoicesChargesDTM } from 'shipment-operations/models/dtm';

import {
  InnerContainer, Title, StyledTotal, HeaderTableWrapper,
} from 'shipment-operations/view/components';
import { CustomTable, ExpandPanel } from 'shipment-operations/view/pages/CreateCreditNote/components/FirstStep/components';

interface ITransportationComponent {
  containers: ContainerViewDTM[]
  data: IGroupedInvoicesChargesDTM
  sum: number
}

const TransportationComponent: FC<ITransportationComponent> = ({
  sum, data, containers,
}) => {
  const { t } = useTranslation();

  return (!isEmpty(data) || !isEmpty(containers)) ? (
    <>
      <InnerContainer>
        <Title>{t('transportation')}</Title>
        <Divider style={{ margin: '0' }} />
        <HeaderTableWrapper>
          <span>{t('Containers')}</span>
          <span>{t('total')}</span>
        </HeaderTableWrapper>
        <Divider style={{ margin: '0' }} />
        {containers.map((item) => (
          <ExpandPanel
            container={item}
            sum={calculateInvoiceSum(data[item.id])}
            key={item.id}
            keys={data[item.id].map((elem) => elem.id)}
          >
            <CustomTable data={data[item.id]} fullTable />
          </ExpandPanel>
        ))}
      </InnerContainer>
      <StyledTotal>$ {sum.toFixed(2)}</StyledTotal>
    </>
  ) : null;
};

export { TransportationComponent };
