import styled from 'styled-components';

import { EditProfileOrganizationContent, EditProfileOrganizationWrap } from 'user-management/view/pages/NewOrganizationWizard/NewOrganizationData/NewOrganizationData.styled';

export const Wrap = styled(EditProfileOrganizationWrap)`
  gap: 8px;
`;

export const Content = styled(EditProfileOrganizationContent)`
  background-color: transparent;
  padding: 24px 0;
  border: 0;
`;
