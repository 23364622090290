import React, { FC, useCallback } from 'react';
import { useParams } from 'react-router';
import Spinner from 'antd/es/spin';
import { useTranslation } from 'react-i18next';

import { Button } from 'app-wrapper/view/components';
import { ShipmentDrawerWrapper } from 'shipment-operations/view/pages/Shipment/components/ShipmentDrawer/ShipmentDrawer.styled';
import { MBLDraftContent } from 'shipment-operations/view/pages/ShipmentBillOfLading/components/MBLContentPages/components/MBLDraftContent';
import {
  SpinnerWrapper,
  Wrapper,
  FooterWrapper,
} from 'shipment-operations/view/drawers/ShipmentChanges/ShipmentChanges.styled';

interface ApproveEMBLDrawerComponentProps {
  loading: boolean;
  onClose: () => void;
  approveDraftMBL: (shipmentId: string) => void;
}

const ApproveEMBLDrawerComponent: FC<ApproveEMBLDrawerComponentProps> = ({
  loading,
  onClose,
  approveDraftMBL,
}) => {
  const { t } = useTranslation();
  const { shipmentId } = useParams();

  const handleApprove = useCallback(async () => {
    if (shipmentId) {
      await approveDraftMBL(shipmentId);
      onClose();
    }
  }, [approveDraftMBL, shipmentId, onClose]);

  return (
    <ShipmentDrawerWrapper
      title={t('Review and Approve eMBL')}
      visible
      width="1137px"
      closable
      maskClosable={false}
      onClose={onClose}
    >
      <Wrapper>
        {loading ? (
          <SpinnerWrapper>
            <Spinner />
          </SpinnerWrapper>
        ) : (
          <MBLDraftContent />
        )}
      </Wrapper>

      <FooterWrapper>
        <Button
          loading={loading}
          onClick={handleApprove}
        >
          {t('Approve')}
        </Button>
      </FooterWrapper>
    </ShipmentDrawerWrapper>
  );
};

export { ApproveEMBLDrawerComponent };
