import styled from 'styled-components';
import Button from 'antd/es/button';

export const Main = styled.div`
  overflow-y: auto;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const DescriptionContainer = styled.div`
  padding: 12px 16px;
`;

export const DescriptionTitle = styled.span`
  font-weight: 400;
  font-size: 12px;
  color: #73819B;
`;

export const DescriptionStyled = styled.p`
  font-weight: 400;
  font-size: 14px;
  color: #202C3C;
  margin-top: 4px;
`;

export const StyledTotal = styled.div`
  width: 100%;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border: 1px solid #EAEEF8;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  background-color: white;
  border-top: none;
`;

export const TotalInner = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 16px;
`;

export const TotalValue = styled.span`
  font-weight: 500;
  font-size: 14px;
  color: #202C3C;
`;

export const AlertWrapper = styled.div`
  padding-top: 40px;
`;

export const StyledButton = styled(Button)`
  background-color: #4E5D70;
  border: none;

  &:hover {
    background-color: #798aa2;
  }
`;
