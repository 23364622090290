import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Progress,
  ProgressText,
  ProgressWrap,
} from './Progress.styled';

interface ProgressComponentProps {
  percentage: number;
}

const ProgressComponent: FC<ProgressComponentProps> = ({ percentage }) => {
  const { t } = useTranslation();

  return (
    <ProgressWrap>
      <Progress percent={percentage} />

      <ProgressText>
        {t('Completed')}
      </ProgressText>
    </ProgressWrap>
  );
};

export { ProgressComponent as Progress };
