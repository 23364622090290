const sleep = (time: number) => new Promise((resolve) => setTimeout(resolve, time));

export const loadUntil = async <T extends(...args: any) => any, C extends (...args: any) => any>(
  func: T, condition: C, options?: {delay?: number, callTimes?: number},
): Promise<ReturnType<T>> => {
  const delay = options?.delay || 500;
  const callTimes = options && typeof options.callTimes === 'number' ? options?.callTimes : 10;

  const response = await func();

  if (callTimes > 0 && !condition(response)) {
    await sleep(delay);
    const nextResponse = await loadUntil(func, condition, { delay, callTimes: callTimes - 1 });

    return nextResponse;
  }

  return response;
};
