import styled from 'styled-components';

import { VerticalFormItemSvg } from 'app-wrapper/view/icons';
import { colors } from 'app-wrapper/view/themes/themesColors';

export const ShipmentWaypointsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 19px;

  width: auto;
  height: 100%;
`;

export const ShipmentWaypointDivider = styled(VerticalFormItemSvg)`
  color: ${colors.gray43};
`;
