import { apiWorker } from 'app-wrapper/repository/utilsServices';

import { AdditionalServiceCheckedDTM } from 'monetary/models/dtm/Quotas';

export class ShipmentAdditionalServicesService {
  private base = '/shipment-service/api/v1/shipments';

  public postShipmentByIdAdditionalServices = async (shipmentId: string, services?: AdditionalServiceCheckedDTM[]) => {
    const rawResponse = await apiWorker.requestPost(`${this.base}/${shipmentId}/additional-services`, services);

    if (rawResponse.data) {
      return true;
    }

    return false;
  };
}
