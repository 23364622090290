import styled from 'styled-components';

export const Row = styled.div<{ fontSize?: number }>`
  display: flex;
  flex-direction: row;
  width: 100%;
  flex: 1;
  padding-bottom: 8px;
  margin-bottom: 8px;
  border-bottom: 0.5px solid #D1D7E2;
  font-size: ${({ fontSize }) => fontSize}rem;

  &:last-child {
    border-bottom: none;
    padding-bottom: 0px;
    margin-bottom: 0px;
  }
`;

export const Column = styled.div<{flex?: number}>`
  display: flex;
  flex-direction: column;
  flex: ${({ flex = '1' }) => flex};
  padding: 0;
  padding-right: 8px;
  margin-right: 8px;
  border-right: 0.5px solid #D1D7E2;

  &:last-child {
    border-right: none;
    padding-right: 0;
    margin-right: 0;
  }
`;
