import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Option } from 'app-wrapper/view/components';
import { CargoDTM } from 'shipment-operations/models/dtm';
import { PackingGroupType, PackingGroupTypeNames } from 'shipment-operations/constants';

import { SelectStyled } from './PackagingGroupSelect.styled';

interface PackagingGroupSelectComponentProps {
  packingGroup: CargoDTM['packingGroup']
  touchCargoField: (field: string, cargoId: number) => void
  setCargoPackingGroup: (value: PackingGroupType, cargoId: number) => void
  error?: boolean
  cargoId: number;
}

export const PackagingGroupSelectComponent: FC<PackagingGroupSelectComponentProps> = ({
  packingGroup,
  touchCargoField,
  setCargoPackingGroup,
  cargoId,
  error,
}) => {
  const { t } = useTranslation();

  const setPackingGroup = useCallback((value: PackingGroupType) => {
    setCargoPackingGroup(value, cargoId);
  }, [setCargoPackingGroup, cargoId]);

  const touchField = useCallback((field: string) => {
    touchCargoField(field, cargoId);
  }, [touchCargoField, cargoId]);

  const handleBlur = useCallback(() => {
    touchField('packingGroup');
  }, [touchField]);

  return (
    <SelectStyled
      value={packingGroup}
      error={error}
      allowClear={!!packingGroup}
      onBlur={handleBlur}
      onChange={setPackingGroup}
      size="middle"
    >
      {Object.keys(PackingGroupTypeNames).map((type) => (
        <Option key={type} value={type}>
          {t(PackingGroupTypeNames[type as PackingGroupType])}
        </Option>
      ))}
    </SelectStyled>
  );
};
