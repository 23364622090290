import React from 'react';
import { useSelector } from 'react-redux';

import { UC } from 'monetary/controllers';
import { R } from 'monetary/repository';

import { FAKExceptionComponent } from './FAKException.component';

const FAKExceptionContainer = ({ idIndex }: { idIndex?: number }) => {
  const data = useSelector(R.selectors.fakException.getData);

  return (
    <FAKExceptionComponent
      data={data[idIndex || 0]}
      loadData={() => UC.fakException.loadData(idIndex)}
    />
  );
};

export { FAKExceptionContainer as FAKException };
