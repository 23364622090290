import styled from 'styled-components';

export const ShipmentOverviewCargosWrap = styled.div.attrs({
  'data-class': 'ShipmentOverviewCargos',
})`
  background-color: ${({ theme }) => theme.themesColors.neutralBranding1};
  border: 1px solid ${({ theme }) => theme.themesColors.neutralBranding4};
`;

export const ShipmentOverviewCargosLoadingWrap = styled.div`
  padding: 16px;
`;
