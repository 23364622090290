import React, {
  FC, memo,
} from 'react';
import { useTranslation } from 'react-i18next';

import { useWindowSize } from 'app-wrapper/hooks';

import {
  ICompanyContactDTM,
} from 'user-management/models/dtm';

import {
  SPAddContactContentBodyForm,
  SPAddContactContentBodyFormFooter,
  SPAddContactContentBodyFormWrap,
  SPAddContactContentBodyFormWrapper,
} from './ShipmentPartyAddContact.styled';
import { ContactPersonItem } from './components';

interface IShipmentPartyAddContactComponentProps {
  companyListContactPersons?: ICompanyContactDTM[]
  companyListStateIndex: string
  isUpdating: boolean
  updateForm: () => void
  onDiscard: () => void
}

const ShipmentPartyAddContactComponent: FC<IShipmentPartyAddContactComponentProps> = (props) => {
  const {
    companyListContactPersons,
    companyListStateIndex,
    isUpdating,
    updateForm,
    onDiscard,
  } = props;
  const { t } = useTranslation();
  const largeInput = useWindowSize().isFullMediaWidth;

  return (
    <SPAddContactContentBodyFormWrap>
      <SPAddContactContentBodyForm>
        <SPAddContactContentBodyFormWrapper>

          {companyListContactPersons?.map((personItem, personIndex) => (
            <ContactPersonItem
              key={`ContactBookContactPersonItem-${personItem.customId}`}
              largeInput={largeInput}
              isFirst
              isLast={false}
              contactIndex={personIndex}
              personItem={personItem}
              companyListStateIndex={companyListStateIndex}
            />
          ))}

        </SPAddContactContentBodyFormWrapper>

      </SPAddContactContentBodyForm>

      <SPAddContactContentBodyFormFooter
        saveName={t('Add')}
        discardName={t('cancel')}
        discardChanges={onDiscard}
        updateForm={updateForm}
        disabledUpdate={isUpdating}
      />
    </SPAddContactContentBodyFormWrap>
  );
};

const ShipmentPartyAddContactComponentCache = memo(ShipmentPartyAddContactComponent);

export { ShipmentPartyAddContactComponentCache as ShipmentPartyAddContactComponent };
