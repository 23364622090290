import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'app-wrapper/createSlice';

import { TPartnersState } from 'user-management/models/states';
import { OrganizationDTM } from 'user-management/models/dtm';

export const initialState: TPartnersState = {
  isLoading: false,
  isPaginationEnded: false,
  page: 0,
  partners: [],
};

export const partnersStore = createSlice({
  name: 'partners',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setPartners: (state, { payload }: PayloadAction<OrganizationDTM[]>) => {
      state.partners = payload;
    },
    setPage: (state, { payload }: PayloadAction<number>) => {
      state.page = payload;
    },
    setIsPaginationEnded: (state, { payload }: PayloadAction<boolean>) => {
      state.isPaginationEnded = payload;
    },
    clear: () => (initialState),
  },
});

export const partnersActions = partnersStore.actions;
export const partnersReducer = partnersStore.reducer;
