import React, {
  FC,
  Fragment,
  useCallback,
  useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { SelectProps } from 'antd/es/select';

import { CargoDTM, CommodityDTM } from 'shipment-operations/models/dtm';
import { PermissionAttributePolicy } from 'shipment-operations/constants';
import { hasAccess } from 'app-wrapper/utils';

import { getNotFoundContent } from 'monetary/hooks';

import {
  Option,
  OptionDescription,
  OptionHeader,
  OptionTitle,
  Select,
} from './HsCodeInput.styled';

const GET_PARENT_NODE = (triggerNode: any) => triggerNode.parentElement || document.body;

export interface IHsCodeInputComponentProps {
  commodities: CommodityDTM[]
  isQueryEmpty: boolean
  isLoadingCommodities: boolean
  statusCommoditiesRequest?: string
  isTemperatureControlled: boolean
  isError: boolean
  hsCode: CargoDTM['code']
  searchCommodities: (query: string) => void
  setHsCode: (shipmentId: number, code: CargoDTM['code']) => void
  touchField: (field: string) => void
  size?: SelectProps<any>['size'];
  placeholder?: string;
  cargosAvailability?: PermissionAttributePolicy;
  dropdownMatchSelectWidth?: number;
}

export const HsCodeInputComponent: FC<IHsCodeInputComponentProps> = ({
  statusCommoditiesRequest,
  isTemperatureControlled,
  isQueryEmpty,
  commodities,
  isError,
  hsCode,
  setHsCode,
  touchField,
  searchCommodities,
  size = 'large',
  placeholder,
  cargosAvailability,
  dropdownMatchSelectWidth,
}) => {
  const { t } = useTranslation();
  const { shipmentId = '0' } = useParams();

  const handleSelect = useCallback((code: string) => {
    setHsCode(+shipmentId, code);
  }, [shipmentId]);

  const handleClear = useCallback(() => {
    setHsCode(+shipmentId, undefined);
  }, [shipmentId]);

  const handleBlur = useCallback(() => {
    touchField('code');
  }, []);

  const sortCommoditiesByHSCode = useCallback((commoditiesToSort: CommodityDTM[]) => [...commoditiesToSort].sort((a, b) => (a.code ? +a.code : 0) - (b.code ? +b.code : 0)), []);
  const forbiddenCommodities = useMemo(() => sortCommoditiesByHSCode(commodities.filter((commodity) => commodity.forbidden)), [commodities, sortCommoditiesByHSCode]);
  const allowedCommodities = useMemo(() => sortCommoditiesByHSCode(commodities.filter((commodity) => !commodity.forbidden)), [commodities, sortCommoditiesByHSCode]);

  const isDisabled = useMemo(() => isTemperatureControlled || !hasAccess(cargosAvailability, PermissionAttributePolicy.WRITE), [cargosAvailability, isTemperatureControlled]);

  return (
    <Select
      showSearch
      dropdownMatchSelectWidth={dropdownMatchSelectWidth}
      placeholder={placeholder || t('Start entering H.S or Name')}
      showArrow={false}
      filterOption={false}
      autoClearSearchValue={false}
      getPopupContainer={GET_PARENT_NODE}
      disabled={isDisabled}
      error={isError}
      notFoundContent={getNotFoundContent(statusCommoditiesRequest || '', !!commodities.length)}
      allowClear={!!hsCode || !isQueryEmpty}
      value={hsCode || undefined}
      onSearch={searchCommodities}
      onSelect={handleSelect}
      onBlur={handleBlur}
      onClear={handleClear}
      size={size}
    >{
        allowedCommodities.map(({ code, name, forbidden }) => (
          <Fragment key={code}>
            <Option
              key={`origin_locationValues_${code}_success`}
              value={code}
              disabled={forbidden}
            >
              <OptionTitle isDisabled={forbidden}>{code}</OptionTitle>
              <OptionDescription isDisabled={forbidden}>{name}</OptionDescription>
            </Option>
          </Fragment>
        ))
      }

      {forbiddenCommodities.length ? (
        <>
          <Option
            description={t('Excluded Commodities')}
            value=""
            disabled
          >
            <OptionHeader isDisabled>
              {t('Excluded Commodities')}
            </OptionHeader>
          </Option>

          {forbiddenCommodities.map(({ code, name }) => (
            <Option key={`origin_locationValues_${code}_success`} value={code} disabled>
              <OptionTitle isDisabled>{code}</OptionTitle>
              <OptionDescription isDisabled>{name}</OptionDescription>
            </Option>
          ))}
        </>
      ) : null}
    </Select>
  );
};
