import React, {
  FC,
  useRef,
  useCallback,
} from 'react';
import { useReactToPrint } from 'react-to-print';

import { InvoicePDFDocument } from './components';
import { PDFIcon } from './ShareInvoice.styled';

interface ShareInvoiceComponentProps {
  shipmentId: string;
  invoiceId?: number;
  isLoaded: boolean;
  isLoading: boolean;
  fetchData: (shipmentId: string, invoiceId?: number) => void;
  isAPInvoiceType: boolean;
  fileNamePrint?: string;
}

const oldTitle = document.title;

export const ShareInvoiceComponent: FC<ShareInvoiceComponentProps> = ({
  shipmentId,
  invoiceId,
  isLoaded,
  isLoading,
  fetchData,
  isAPInvoiceType,
  fileNamePrint,
}) => {
  const componentRef = useRef<React.ReactInstance>(null);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onAfterPrint: () => {
      // support chrome
      document.title = oldTitle;
    },
  });

  const handleClick = useCallback(async () => {
    if (isLoading) {
      return;
    }

    await fetchData(shipmentId, invoiceId);

    // @ts-ignore only for e2e test
    if (!window.Cypress) {
      // support chrome
      document.title = fileNamePrint || oldTitle;
      handlePrint();
    }
  }, [shipmentId, invoiceId, fetchData, isLoading, handlePrint]);

  return (
    <>
      <div data-class="pdfLayout" style={{ display: 'none' }}>
        {isLoaded && (
          <InvoicePDFDocument
            fileNamePrint={fileNamePrint}
            ref={componentRef}
            isAPInvoiceType={isAPInvoiceType}
          />
        )}
      </div>

      <PDFIcon onClick={handleClick} disabled={isLoading} />
    </>
  );
};
