import styled from 'styled-components';

import { Button } from 'app-wrapper/view/components';
import themesColors from 'app-wrapper/view/themes/themesColors';

export const UserDataWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const UserDataIconDefaultBG = styled.div.attrs({
  'data-class': 'UserDataIconDefaultBG',
})`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;
  background-color: ${themesColors.primaryBranding1};
  border-radius: 50%;
  color: ${themesColors.primaryBranding5};
  font-size: 18px;
`;

export const UserDataNameBox = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: flex-start;
  height: 100%;
  margin-left: 16px;
  margin-right: auto;
`;

export const UserDataName = styled.div`
  font-size: 20px;
  line-height: 28px;
  font-weight: 500;
`;

export const UserDataType = styled.div`
  font-size: 14px;
  color: ${themesColors.darkGrayMiddle};
  line-height: 22px;
  font-weight: 400;
`;

export const UserDataButton = styled(Button)`
  margin-right: 8px;
`;

export const UserDataButtonLast = styled(Button)`
  margin-right: 0;
`;
