import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import Tabs from 'antd/es/tabs';
import { useParams, useNavigate, useLocation } from 'react-router';

import { RouteNames } from 'app-wrapper/constants';

import {
  ShipmentDocumentsTabsStyled,
} from './ShipmentDocumentsTabs.styled';

interface IShipmentDocumentsTabsProps {
  hasHazmat: boolean;
  hasSOC: boolean;
}

export const ShipmentDocumentsTabs: FC<IShipmentDocumentsTabsProps> = ({ hasHazmat, hasSOC }) => {
  const { t } = useTranslation();
  const { shipmentId = '' } = useParams();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const onChangeTab = useCallback((tab) => {
    navigate(tab, { replace: true });
  }, []);

  return (
    <ShipmentDocumentsTabsStyled
      activeKey={pathname}
      onChange={onChangeTab}
    >
      <Tabs.TabPane tab={t('Documents')} key={RouteNames.SHIPMENT_DOCUMENTS_ALL(shipmentId)} />
      {hasHazmat ? <Tabs.TabPane tab={t('Hazmat')} key={RouteNames.SHIPMENT_DOCUMENTS_HAZMAT(shipmentId)} /> : null}
      {hasSOC ? (
        <Tabs.TabPane
          tab={t('Seaworthy Certificates')}
          key={RouteNames.SHIPMENT_DOCUMENTS_SEAWORTHY_CERTIFICATE(shipmentId)}
          data-testid="document-seaworthy-nav-tab"
        />
      ) : null}
      {/* temporarly disabled <Tabs.TabPane tab={t('Additional')} key={RouteNames.SHIPMENT_DOCUMENTS_ADDITIONAL(shipmentId)} /> */}
    </ShipmentDocumentsTabsStyled>
  );
};
