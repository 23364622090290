import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Spin from 'antd/es/spin';

import { SpinnerWrap } from 'app-wrapper/view/guideline';
import { Button } from 'app-wrapper/view/components';
import { Footer, NextButton, CancelButton } from 'user-management/view/pages/NewOrganizationWizard/NewOrganizationDocuments/NewOrganizationDocuments.styled';

import {
  SkypaceTermsOfService,
  PartnersInformation,
} from './components';
import { Content, Wrap } from './NewOrganizationReviewAndConfirm.styled';

interface NewOrganizationReviewAndConfirmComponentProps {
  initData: () => void;
  isLoading: boolean;
  onConfirm: () => void;
  onGoBack: () => void;
  cancelWizard: () => void;
  isAbleToConfirm?: boolean;
}

export const NewOrganizationReviewAndConfirmComponent: FC<NewOrganizationReviewAndConfirmComponentProps> = ({
  initData,
  isLoading,
  onConfirm,
  onGoBack,
  cancelWizard,
  isAbleToConfirm,
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    initData();
  }, []);

  return isLoading ? (
    <SpinnerWrap>
      <Spin size="large" />
    </SpinnerWrap>
  ) : (
    <Wrap>
      <Content>
        <SkypaceTermsOfService />

        <PartnersInformation />
      </Content>

      <Footer>
        <CancelButton
          disabled={isLoading}
          type="default"
          onClick={cancelWizard}
        >
          {t('Cancel')}
        </CancelButton>

        <Button
          disabled={isLoading}
          type="default"
          onClick={onGoBack}
        >
          {t('Back')}
        </Button>

        <NextButton
          data-class="NewOrganizationReviewAndConfirmComponentNextButton"
          onClick={onConfirm}
          disabled={!isAbleToConfirm || isLoading}
        >
          {t('Confirm')}
        </NextButton>
      </Footer>
    </Wrap>
  );
};
