import styled from 'styled-components';
import Typography from 'antd/es/typography';

import { divFlex } from 'app-wrapper/view/themes/mixin';

export const ContainerColumn = styled.div.attrs({
  'data-class': 'ContainerColumn',
})`
  ${divFlex}
  flex-direction: row;
  align-items: center;
`;

export const ContainerColumnName = styled(Typography).attrs({
  'data-class': 'ContainerColumnName',
})`
  color: ${({ theme }) => theme.themesColors.characterBrandingTitle85}
  font-size: 14px;
  line-height: 22px;
`;
