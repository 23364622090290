import { action, makeObservable, computed } from 'mobx';
import { BaseMobxStore } from 'proto/BaseMobxStore';
import { ShipmentPreviewDTM } from 'shipment-operations/models/dtm';

interface OverviewShipmentState {
  shipments: ShipmentPreviewDTM[]
  isLoading: boolean
}

export const initialState: OverviewShipmentState = {
  shipments: [],
  isLoading: true,
};

export class OverviewShipmentStore<T extends OverviewShipmentState = OverviewShipmentState> extends BaseMobxStore<OverviewShipmentState> {
  initialState = { ...initialState }

  constructor(_initialState?: T) {
    super();
    makeObservable(this);
    this.init(_initialState || this.initialState);
  }

  @action
  setShipments(shipments: ShipmentPreviewDTM[]) {
    this.initialState.shipments = shipments;
  }

  @action
  setLoading(loading: boolean) {
    this.initialState.isLoading = loading;
  }

  @computed
  get shipmentsWithKey() {
    return this.initialState.shipments.map((item) => (ShipmentPreviewDTM.fromPlain({
      ...item,
      key: item.id,
    })));
  }
}
