import styled from 'styled-components';
import Typography from 'antd/es/typography';

export const Wrap = styled.div`
  padding: 8px 24px;
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

export const Card = styled.div`
  width: 25%;
  border-radius: 3px;
  padding: 16px 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(255, 255, 255, 1);
  border: 1px solid rgba(234, 238, 248, 1);
`;

export const Image = styled.img`
  width: 70px;
  height: 70px;
`;

export const Title = styled(Typography)`
  text-align: center;
  margin-top: 4px;
  font-size: 14px;
  line-height: 22px;
  font-weight: 500;
`;

export const Text = styled(Typography)`
  text-align: center;
  font-size: 14px;
  line-height: 22px;
  color: rgba(115, 129, 155, 1);
`;
