import React from 'react';
import { useSelector } from 'react-redux';

import { UC } from 'user-management/controllers';
import { R } from 'user-management/repository';
import { R as AuthR } from 'authentication/repository';
import { UserDataComponent } from 'user-management/view/pages/UserProfile/components/UserData';

export const UserData = () => {
  const user = useSelector(R.selectors.userManagement.getUserProfile);
  const isAdmin = useSelector(R.selectors.userOrganizationData.isAdmin);
  const userEmail = useSelector(AuthR.selectors.auth.getEmail);

  return user ? (
    <UserDataComponent
      isAdmin={isAdmin}
      userEmail={userEmail}
      user={user}
      userName={user.getFullName()}
      onEditProfile={UC.userManagement.openEditMemberDrawer}
    />
  ) : null;
};
