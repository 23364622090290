import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';

import { UnNumberInputComponent } from './UnNumberInput.component';

interface UnNumberInputContainerProps {
  cargoId: number;
}

const UnNumberInputContainer: FC<UnNumberInputContainerProps> = ({ cargoId }) => {
  const { unNumber = '', errors } = useSelector(R.selectors.manualBookingWizard.getCargoById(cargoId));
  const { unNumber: error } = errors;

  return (
    <UnNumberInputComponent
      unNumber={unNumber}
      error={!!error}
    />
  );
};

export { UnNumberInputContainer as UnNumberInput };
