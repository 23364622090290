import { plainToInstance, Type } from 'class-transformer';
import {
  IsOptional,
  IsString,
  ValidateNested,
  IsDefined,
} from 'class-validator';

import { IValidationErrorDTM, ValidationErrorDTM } from 'app-wrapper/types';
import { BaseDTM } from 'proto/BaseDTM';

interface IBookingWizardContainersErrorsDTM {
  containerId: string;
  number?: IValidationErrorDTM;
  sealNumber?: IValidationErrorDTM;
  certificate?: IValidationErrorDTM;
}

export class BookingWizardContainersErrorsDTM extends BaseDTM<IBookingWizardContainersErrorsDTM> {
  @IsDefined()
  @IsString()
  containerId: string;

  @IsOptional()
  @ValidateNested()
  @Type(() => ValidationErrorDTM)
  number?: ValidationErrorDTM;

  @IsOptional()
  @ValidateNested()
  @Type(() => ValidationErrorDTM)
  sealNumber?: ValidationErrorDTM;

  @IsOptional()
  @ValidateNested()
  @Type(() => ValidationErrorDTM)
  certificate?: ValidationErrorDTM;

  static createEmpty = (containerId: string) => plainToInstance(BookingWizardContainersErrorsDTM, { containerId });

  public hasErrors = () => !!(
    this.number
    || this.sealNumber
    || this.certificate
  )
}
