import styled, { css } from 'styled-components';

import { UserManagementFormFooter } from 'user-management/view/components';

import { divFlex } from 'app-wrapper/view/themes/mixin';
import { Input } from 'app-wrapper/view/components';
import { emptyScrollbar } from 'app-wrapper/view/themes/components';

const titleCss = css`
  font-size: 12px;
  font-weight: 400;
  color: ${({ theme }) => theme?.themesColors?.neutralBranding10};
`;

const inputCss = css`
  font-size: 12px;
  font-weight: 400;
  color: ${({ theme }) => theme?.themesColors?.neutralBranding10};
  height: 32px;
`;

const inputLargeCss = css`
  height: 40px;
`;

const inputTopMarginCss = css`
  margin-top: 6px;
`;

export const CBDividerMargins = {
  margin: '0',
  marginTop: '16px',
};

export const CBContentBodyFormWrap = styled.div.attrs({
  'data-class': 'CBContentBodyFormWrap',
})`
  ${divFlex}
  ${emptyScrollbar}
  flex-direction: column;

  background-color: ${({ theme }) => theme?.themesColors?.neutralBranding3};
  height: 100%;
  width: 100%;
  padding: 16px 24px 0 24px;
`;

export const CBContentBodyForm = styled.div.attrs({
  'data-class': 'CBContentBodyForm',
})`
  ${divFlex}
  ${emptyScrollbar}
  flex-direction: column;

  height: 100%;
  width: 100%;

  background-color: ${({ theme }) => theme?.themesColors?.neutralBranding1};
  border: 1px solid ${({ theme }) => theme?.themesColors?.neutralBranding4};
  border-radius: 3px;
`;

export const CBContentBodyFormWrapper = styled.div.attrs({
  'data-class': 'CBContentBodyFormWrapper',
})`
  ${divFlex}
  ${emptyScrollbar}
  flex-direction: column;
  overflow-y: auto;

  height: calc(100% - 64px);
  width: 100%;

  background-color: ${({ theme }) => theme?.themesColors?.neutralBranding1};
  padding: 16px;
`;

export const CBContentBodyFormFooter = styled(UserManagementFormFooter).attrs({
  'data-class': 'CBContentBodyFormFooter',
})`
  width: 100%;
`;

export const CBContentBodyFormCompanyFirstBlock = styled.div.attrs({
  'data-class': 'CBContentBodyFormCompanyFirstBlock',
})`
  ${divFlex}
  width: 100%;
`;

export const CBContentBodyFormCompanyName = styled.div.attrs({
  'data-class': 'CBContentBodyFormCompanyName',
})`
  ${divFlex}
  flex-direction: column;
  width: calc(50% - 2px);
`;

export const CBContentBodyFormCompanyNameTitleWrapper = styled.div.attrs({
  'data-class': 'CBContentBodyFormCompanyNameTitleWrapper',
})`
  ${divFlex}
  width: 100%;
  height: 22px;
`;

export const CBContentBodyFormCompanyNameTitle = styled.div.attrs({
  'data-class': 'CBContentBodyFormCompanyNameTitle',
})`
  ${divFlex}
  ${titleCss}
`;

export const CBContentBodyFormCompanyNameInput = styled(Input).attrs((props: {
  hasError: boolean,
  largeInput: boolean,
}) => ({
  hasError: props.hasError,
  largeInput: props.largeInput,
  'data-class': 'CBContentBodyFormCompanyNameInput',
}))`
  ${divFlex}
  ${inputCss}
  ${inputTopMarginCss}

  ${({ largeInput }) => largeInput && inputLargeCss};
  margin-top: 4px;
  width: 100%;

  ${({ hasError, theme }) => hasError && `border-color: ${theme.themesColors.characterBrandingDanger} !important; border-style: solid;`};
`;

export const CBContentBodyFormCompanyTaxId = styled.div.attrs({
  'data-class': 'CBContentBodyFormCompanyTaxId',
})`
  ${divFlex}
  flex-direction: column;
  width: calc(50% - 2px);
  margin-left: 4px;
`;

export const CBContentBodyFormCompanyTaxIdTitleWrapper = styled.div.attrs({
  'data-class': 'CBContentBodyFormCompanyTaxIdTitleWrapper',
})`
  ${divFlex}
  width: 100%;
  height: 22px;
`;

export const CBContentBodyFormCompanyTaxIdTitle = styled.div.attrs({
  'data-class': 'CBContentBodyFormCompanyTaxIdTitle',
})`
  ${divFlex}
  ${titleCss}
`;

export const CBContentBodyFormCompanyTaxIdInput = styled(Input).attrs((props: {
  hasError: boolean,
  largeInput: boolean,
}) => ({
  hasError: props.hasError,
  largeInput: props.largeInput,
  'data-class': 'CBContentBodyFormCompanyTaxIdInput',
}))`
  ${divFlex}
  ${inputCss}
  ${inputTopMarginCss}

  ${({ largeInput }) => largeInput && inputLargeCss};
  margin-top: 4px;
  width: 100%;

  ${({ hasError, theme }) => hasError && `border-color: ${theme.themesColors.characterBrandingDanger} !important; border-style: solid;`};
`;
