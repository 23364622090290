export const QUOTAS_STATUS = {
  pending: 'PENDING',
  executing: 'EXECUTING',
  complete: 'COMPLETE',
  failed: 'FAILED',
};

export const CUSTOM_REQUEST_QUOTAS_STATUS = {
  PENDING: 'PENDING',
  CANCELED: 'CANCELED',
  COMPLETED: 'COMPLETED',
};

type KeyQuotasStatus = keyof typeof QUOTAS_STATUS;
export type KeysQuotasStatus = typeof QUOTAS_STATUS[KeyQuotasStatus];
