import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'app-wrapper/createSlice';
import { ContainerDocumentDTM } from 'shipment-operations/models/dtm';

import { IMakePaymentState } from 'shipment-operations/models/states';

const initialState: IMakePaymentState = {
  paymentDate: '',
  reference: '',
  receivedAmount: 0,
  isLoadingRecord: false,
  inputsErrors: [],
  createPaymentError: false,
  createPaymentSuccess: false,
  loadPaymentViewError: false,
  paymentViewLoading: false,
  linkedInvoices: [],
  isOpenEdit: false,
  editPayment: {
    date: '',
    reference: '',
    totalAmount: 0,
    usedAmount: 0,
    transactions: [],
  },
  isLoadingEditPayment: false,
  isLoadingEditTransaction: false,
  isErrorEditPayment: false,
  isErrorEditTransaction: false,
  shouldRefresh: false,
  editErrorType: '',
  tempDocuments: [],
  documentIdsToDelete: [],
  paymentType: '',
};

export const MakePaymentSlice = createSlice({
  name: 'makePayment',
  initialState,
  reducers: {
    onChangePaymentDate: (state, { payload }) => ({
      ...state,
      paymentDate: payload,
    }),
    onChangeReference: (state, { payload }) => ({
      ...state,
      reference: payload,
    }),
    onChangeReceivedAmount: (state, { payload }) => ({
      ...state,
      receivedAmount: payload,
    }),
    onSetLoadingRecord: (state, { payload }) => ({
      ...state,
      isLoadingRecord: payload,
    }),
    onSetInputsErrors: (state, { payload }) => ({
      ...state,
      inputsErrors: payload,
      isLoadingRecord: false,
    }),
    onSetCreatePaymentError: (state, { payload }) => ({
      ...state,
      createPaymentError: payload,
      isLoadingRecord: false,
    }),
    onSetCreatePaymentSuccess: (state, { payload }) => ({
      ...state,
      createPaymentSuccess: payload,
      isLoadingRecord: false,
    }),
    clearPaymentViewError: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      loadPaymentViewError: payload,
    }),
    onSetPaymentView: (state, { payload }) => ({
      ...state,
      paymentView: payload,
      loadPaymentViewError: false,
      shouldRefresh: false,
    }),
    onSetLoadPaymentViewError: (state) => ({
      ...state,
      paymentView: undefined,
      loadPaymentViewError: true,
      paymentViewLoading: false,
    }),
    onSetLinkedInvoices: (state, { payload }) => ({
      ...state,
      linkedInvoices: payload,
      paymentViewLoading: false,
    }),
    onSetPaymentViewLoading: (state, { payload }) => ({
      ...state,
      paymentViewLoading: payload,
    }),
    onSetCreatedPayment: (state, { payload }) => ({
      ...state,
      createdPayment: payload,
    }),
    onOpenEdit: (state, { payload }) => ({
      ...state,
      isOpenEdit: true,
      editPayment: {
        date: state.paymentView?.createdAt,
        reference: state.paymentView?.reference,
        totalAmount: state.paymentView?.amount,
        usedAmount: 0,
        transactions: payload,
      },
    }),
    onCloseEdit: (state) => ({
      ...state,
      isOpenEdit: false,
      editPayment: initialState.editPayment,
    }),
    onChangeEditTotalAmount: (state, { payload }) => ({
      ...state,
      editPayment: {
        ...state.editPayment,
        totalAmount: payload,
      },
    }),
    onChangeEditDate: (state, { payload }) => ({
      ...state,
      editPayment: {
        ...state.editPayment,
        date: payload,
      },
    }),
    setTempDocuments: (state, { payload }: PayloadAction<ContainerDocumentDTM[]>) => ({
      ...state,
      tempDocuments: payload,
    }),
    addTempDocument: (state, { payload }: PayloadAction<ContainerDocumentDTM>) => ({
      ...state,
      tempDocuments: [...state.tempDocuments, payload],
    }),
    setPaymentType: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      paymentType: payload,
    }),
    setDocumentIdsToDelete: (state, { payload }: PayloadAction<number[]>) => ({
      ...state,
      documentIdsToDelete: payload,
    }),
    onChangeEditReference: (state, { payload }) => ({
      ...state,
      editPayment: {
        ...state.editPayment,
        reference: payload,
      },
    }),
    onSetEditTransactions: (state, { payload }) => ({
      ...state,
      editPayment: {
        ...state.editPayment,
        transactions: payload,
      },
    }),
    setIsLoadingEditPayment: (state, { payload }) => ({
      ...state,
      isLoadingEditPayment: payload,
    }),
    setIsLoadingEditTransaction: (state, { payload }) => ({
      ...state,
      isLoadingEditTransaction: payload,
    }),
    setIsErrorEditPayment: (state, { payload }) => ({
      ...state,
      isErrorEditPayment: payload,
      isLoadingEditPayment: false,
    }),
    setIsErrorEditTransaction: (state, { payload }) => ({
      ...state,
      isErrorEditTransaction: payload,
      isLoadingEditTransaction: false,
    }),
    setShouldRefresh: (state, { payload }) => ({
      ...state,
      shouldRefresh: payload,
    }),
    setEditErrorType: (state, { payload }) => ({
      ...state,
      editErrorType: payload,
    }),
  },
});

export const makePaymentActions = MakePaymentSlice.actions;
export const makePaymentReducers = MakePaymentSlice.reducer;
