import React from 'react';
import { observer } from 'mobx-react-lite';

import { useMobxStore } from 'app-wrapper/mobxStores';

import { ServicesListComponent } from './ServicesList.component';

export const ServicesList = observer(() => {
  const { shipmentServices } = useMobxStore();

  return (
    <ServicesListComponent
      services={shipmentServices.state.services}
    />
  );
});
