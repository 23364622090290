import React, { FC, ReactNode } from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';

import { BookingConfirmationPDFComponent } from './BookingConfirmationPDF.component';

interface BookingConfirmationPDFContainerProps {
  header?: ReactNode;
}

const BookingConfirmationPDFContainer: FC<BookingConfirmationPDFContainerProps> = ({ header }) => {
  const isLoading = useSelector(R.selectors.bookingConfirmationPDF.getIsLoading);
  const isLoadingFinished = useSelector(R.selectors.bookingConfirmationPDF.getIsLoadingFinished);
  const getShipmentConfirmationFileNamePrint = useSelector(R.selectors.shipment.getShipmentConfirmationFileNamePrint);

  return (
    <BookingConfirmationPDFComponent
      isLoading={isLoading}
      isLoaded={isLoadingFinished}
      fileNamePrint={getShipmentConfirmationFileNamePrint}
      header={header}
    />
  );
};

export { BookingConfirmationPDFContainer as BookingConfirmationPDF };
