import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Divider from 'antd/es/divider';
import { useParams } from 'react-router-dom';

import { FullCreditNoteDTM } from 'shipment-operations/models/dtm';

import { RouteNames } from 'app-wrapper/constants';
import {
  CardWrapper, Wrapper, CardsTitle, CardsValue, LargeTitle, MainSum, CardsLink,
} from 'shipment-operations/view/components';

interface ICardsWrapperComponentProps {
  creditNote: FullCreditNoteDTM | null
}

const CardsWrapperComponent: FC<ICardsWrapperComponentProps> = ({ creditNote }) => {
  const { t } = useTranslation();
  const { shipmentId } = useParams<'shipmentId'>();
  const { invoiceType } = useParams<'invoiceType'>();

  return (
    <Wrapper>
      <CardWrapper>
        <CardsTitle>{t('creditNote.created')}</CardsTitle>
        <CardsValue>{creditNote?.createdAt}</CardsValue>
        <Divider style={{ margin: '5px 0' }} />
        <CardsTitle>{t('creditNote.reason')}</CardsTitle>
        <CardsValue>{creditNote?.reason}</CardsValue>
      </CardWrapper>
      <CardWrapper>
        <CardsTitle>{t('creditNote.type')}</CardsTitle>
        <CardsValue>{t('creditNote.adjustment')}</CardsValue>
        <Divider style={{ margin: '5px 0' }} />
        <CardsTitle>{t('creditNote.invoice')}</CardsTitle>
        <CardsLink to={RouteNames.SHIPMENT_BILLING_INVOICE(shipmentId, creditNote?.invoiceId, invoiceType)}>{creditNote?.invoiceNumber}</CardsLink>
      </CardWrapper>
      <CardWrapper>
        <LargeTitle>{t('creditNote.totalAmount')}</LargeTitle>
        <MainSum>$ {creditNote?.amount.toFixed(2)}</MainSum>
      </CardWrapper>
    </Wrapper>
  );
};

export { CardsWrapperComponent };
