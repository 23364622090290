import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { R } from 'user-management/repository';

import { CIAddressComponent } from './CIAddress.component';

const CIAddressContainer: FC = () => {
  const organization = useSelector(R.selectors.userOrganizationData.getInternalOrganization);

  return (
    <CIAddressComponent
      organization={organization}
    />
  );
};

export { CIAddressContainer as CIAddress };
