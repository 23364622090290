import styled from 'styled-components';
import { ArrowLeftOutlined } from '@ant-design/icons';

import { divFlex } from 'app-wrapper/view/themes/mixin';

export const CBByIdContentBody = styled.div.attrs((props: {
  $height: string
  'data-class'?: string
}) => ({
  $height: props.$height,
  'data-class': props?.['data-class'] || 'CBByIdContentBody',
}))`
  ${divFlex}

  width: 100%;
  ${({ $height }) => $height && `height: ${$height}`};
`;

export const CBByIdContentTitleWrap = styled.div.attrs({
  'data-class': 'CBByIdContentTitleWrap',
})`
  ${divFlex}
  justify-content: center;
`;

export const CBByIdContentTitle = styled.div.attrs({
  'data-class': 'CBByIdContentTitle',
})`
  ${divFlex}

  font-size: 20px;
  font-weight: 500;
  color: ${({ theme }) => theme.themesColors.neutralBranding10};
  margin-left: 16px;
`;

export const CBByIdContentTitleArrowIcon = styled(ArrowLeftOutlined).attrs({
  'data-class': 'CBByIdContentTitleArrowIcon',
})`
  ${divFlex}
  align-items: center;
  cursor: pointer;
`;

export const HeaderWrapper = styled.div`
  width: 100%;
  background-color: white;
`;
