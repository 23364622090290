import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import uniqBy from 'lodash/fp/uniqBy';
import minBy from 'lodash/fp/minBy';

import {
  ChargeName,
  ChargeWrapper,
  ColumnWithBorder,
  DaysWrapper,
  FirstColumn,
  SecondColumn,
  StyledCell, StyledCellRight, StyledCellWithBorder,
  StyledDays,
  StyledHeaderIcon,
  StyledPerUnit,
  StyledRow,
  TableHeader,
} from 'shipment-operations/view/pages/ShipmentFreeTime/ShipmentFreeTime.styled';
import { normalizationCost } from 'app-wrapper/utils';
import { ChargeDTM, ShipmentChargesPermissionsDtm } from 'shipment-operations/models/dtm';
import { PermissionAttributePolicy } from 'shipment-operations/constants';

interface ICustomTableProps {
  data: ChargeDTM[]
  permissions: ShipmentChargesPermissionsDtm
}

const CustomTable: FC<ICustomTableProps> = ({ data, permissions }) => {
  const { t } = useTranslation();
  const filtered = uniqBy((item) => item.customAppliance, data);
  const min = minBy((item) => item?.applianceRange?.minValue, data)?.applianceRange?.minValue;
  return (
    <>
      <ChargeWrapper>
        <ChargeName>{filtered[0].chargeCode.description}</ChargeName>
        <DaysWrapper>
          <StyledDays>{(min && min > 0) ? min - 1 : '-'} </StyledDays>
          {`${t('free day(s)')}`}
        </DaysWrapper>
      </ChargeWrapper>
      <TableHeader>
        <FirstColumn>{t('daysRange')}</FirstColumn>
        <ColumnWithBorder>
          AP
          <StyledHeaderIcon />
          <StyledPerUnit>{t('perUnit')}</StyledPerUnit>
        </ColumnWithBorder>
        {permissions.shipmentChargesAvailability === PermissionAttributePolicy.WRITE && (
          <SecondColumn>
            AR
            <StyledHeaderIcon />
            <StyledPerUnit>{t('perUnit')}</StyledPerUnit>
          </SecondColumn>
        )}
      </TableHeader>
      {filtered.sort((a, b) => (a?.applianceRange?.minValue || 0) - (b?.applianceRange?.minValue || 0)).map((item) => (
        <StyledRow key={item.customAppliance}>
          <StyledCell>{item.customAppliance?.replace(/[^\d+-]/g, '')}</StyledCell>
          <StyledCellWithBorder fullColumns={permissions.shipmentChargesAvailability === PermissionAttributePolicy.WRITE}>$ {normalizationCost(item.buyCostPerUnit, { thousandthSeparatorComma: true, toFixed: 2 })}</StyledCellWithBorder>
          {permissions.shipmentChargesAvailability === PermissionAttributePolicy.WRITE && (
            <StyledCellRight>$ {normalizationCost(item.totalCost, { thousandthSeparatorComma: true, toFixed: 2 })}</StyledCellRight>
          )}
        </StyledRow>
      ))}
    </>
  );
};

export { CustomTable };
