import React from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';

import { PDFMarksAndNumbersComponent } from './PDFMarksAndNumbers.component';

const PDFMarksAndNumbersContainer = () => {
  const marks = useSelector(R.selectors.bookingConfirmationPDF.getMarks);
  const notes = useSelector(R.selectors.bookingConfirmationPDF.getNotes);

  return (
    <PDFMarksAndNumbersComponent marks={marks} notes={notes} />
  );
};

export { PDFMarksAndNumbersContainer as PDFMarksAndNumbers };
