import React, { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Spin from 'antd/es/spin';
import Checkbox from 'antd/es/checkbox';

import { Drawer } from 'app-wrapper/view/components';
import {
  Content,
  SpinnerWrap,
} from 'user-management/view/components/CreateOrEditTeamDrawer/CreateOrEditTeamDrawer.styled';
import {
  ShippingPartiesCompanyName,
  ShippingPartiesAddressLine,
  ShippingPartiesContactPerson,
} from 'shipment-operations/view/pages/ShipmentShippingParties/components/ShippingPartiesForm/components';
import { PrintNRAAgreementRFQ } from 'shipment-operations/view/pages/ShipmentDocumentsAll/components';

import { DrawerFooter } from './components';
import {
  Fields,
  FieldLabel,
  AgreeText,
  AgreeWrap,
} from './ChangeCompanyDrawer.styled';

const DRAWER_WIDTH = 400;

interface IChangeCompanyDrawerComponentProps {
  isVisible: boolean;
  onClose: () => void;
  isLoading: boolean;
  isInContactMode: boolean;
  isInAddressMode: boolean;
  isAgreeNra?: boolean
  isAgreeNraError?: boolean
  isAgreeNraShow?: boolean
  onChangeAgreeNra: (value: boolean) => void;
}

export const ChangeCompanyDrawerComponent: FC<IChangeCompanyDrawerComponentProps> = ({
  isVisible,
  onClose,
  isLoading,
  isInContactMode,
  isInAddressMode,
  isAgreeNra,
  isAgreeNraError,
  isAgreeNraShow,
  onChangeAgreeNra,
}) => {
  const { t } = useTranslation();

  const handleChangeAgreeNra = useCallback((e) => {
    onChangeAgreeNra(e.target.checked);
  }, [onChangeAgreeNra]);

  const title = useMemo(() => {
    if (isInContactMode) {
      return t('Select Contact');
    }

    if (isInAddressMode) {
      return t('Select Address');
    }

    return t('Select Company');
  }, [t, isInContactMode, isInAddressMode]);

  return (
    <Drawer
      visible={isVisible}
      onClose={onClose}
      maskClosable={false}
      width={DRAWER_WIDTH}
      push={false}
      title={title}
    >
      <Content>
        {isLoading ? (
          <SpinnerWrap>
            <Spin size="large" />
          </SpinnerWrap>
        ) : (
          <>
            <Fields>
              {!isInAddressMode && !isInContactMode ? (
                <>
                  <FieldLabel>
                    {t('Company Name*')}
                  </FieldLabel>
                  <ShippingPartiesCompanyName />
                </>
              ) : null}

              {!isInContactMode ? (
                <>
                  <FieldLabel>
                    {t('Address*')}
                  </FieldLabel>
                  <ShippingPartiesAddressLine />
                </>
              ) : null}

              <FieldLabel>
                {t('Contact Person')}
                <ShippingPartiesContactPerson />
              </FieldLabel>

              {isAgreeNraShow && (
                <>
                  <AgreeWrap isError={isAgreeNraError}>
                    <Checkbox checked={isAgreeNra} onChange={handleChangeAgreeNra} />

                    <AgreeText isError={isAgreeNraError}>
                      {t('I agree with')}
                    </AgreeText>

                    <PrintNRAAgreementRFQ showAsLink />
                  </AgreeWrap>
                </>
              )}
            </Fields>

            <DrawerFooter />
          </>
        )}
      </Content>
    </Drawer>
  );
};
