import React, { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from 'app-wrapper/view/components';
import { PermissionAttributePolicy } from 'shipment-operations/constants';

import { Wrapper } from './Footer.styled';

interface FooterComponentProps {
  onSave: (shipmentId: string) => void;
  shipmentId: string;
  seaworthyCertificatePermission?: PermissionAttributePolicy;
}

export const FooterComponent: FC<FooterComponentProps> = ({
  onSave,
  shipmentId,
  seaworthyCertificatePermission,
}) => {
  const { t } = useTranslation();

  const handleSave = useCallback(() => {
    onSave(shipmentId);
  }, [onSave, shipmentId]);

  const isDisabled = useMemo(() => seaworthyCertificatePermission !== PermissionAttributePolicy.WRITE, [seaworthyCertificatePermission]);

  return (
    <Wrapper>
      <Button
        data-testid="seaworthy-documents-save-button"
        size="large"
        onClick={handleSave}
        disabled={isDisabled}
      >
        {t('Update Documents')}
      </Button>
    </Wrapper>
  );
};
