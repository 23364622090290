import React from 'react';

import { IPropsIcon } from 'app-wrapper/interfaces/icon';

import { Icon } from './RCQLoadingFailed.styled';

export const RCQLoadingFailed = (props: IPropsIcon) => {
  const { width, height, fill } = props;
  return (
    <Icon
      className="RCQLoadingFailed"
      width={width || '14'}
      height={height || '15'}
      viewBox="0 0 14 15"
      fill={fill || 'currentColor'}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M7 0.5C3.13438 0.5 0 3.63438 0 7.5C0 11.3656 3.13438 14.5 7 14.5C10.8656 14.5 14 11.3656 14 7.5C14 3.63438 10.8656 0.5 7 0.5ZM9.58438 10.1594L8.55313 10.1547L7 8.30313L5.44844 10.1531L4.41563 10.1578C4.34688 10.1578 4.29063 10.1031 4.29063 10.0328C4.29063 10.0031 4.30156 9.975 4.32031 9.95156L6.35313 7.52969L4.32031 5.10938C4.30143 5.08647 4.29096 5.0578 4.29063 5.02812C4.29063 4.95937 4.34688 4.90312 4.41563 4.90312L5.44844 4.90781L7 6.75938L8.55156 4.90938L9.58281 4.90469C9.65156 4.90469 9.70781 4.95937 9.70781 5.02969C9.70781 5.05937 9.69688 5.0875 9.67813 5.11094L7.64844 7.53125L9.67969 9.95312C9.69844 9.97656 9.70938 10.0047 9.70938 10.0344C9.70938 10.1031 9.65313 10.1594 9.58438 10.1594Z" fill="currentColor" />
    </Icon>
  );
};
