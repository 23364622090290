import styled from 'styled-components';
import LargeAndUncustomizableDivider from 'antd/es/divider';

export const CargoFormDivider = styled(LargeAndUncustomizableDivider)`
  margin: 0;
`;

interface ICargoFormSectionProps {
  columns: string
}

export const CargoFormSection = styled.div<ICargoFormSectionProps>`
  display: grid;
  grid-template-columns: ${({ columns }) => columns};
  grid-gap: 4px;
  & > * {
    max-width: 100%;
    font-size: 12px;
  }
`;
