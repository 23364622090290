import React, { FC, useCallback, useMemo } from 'react';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';

import { Button } from 'app-wrapper/view/components';
import { PermissionAttributePolicy } from 'shipment-operations/constants';

interface ICargoFooterComponentProps {
  isInDraft: boolean
  discardChanges: () => void
  updateCargo: (shipmentId: number) => void
  cargosAvailability?: PermissionAttributePolicy
}

export const CargoFooterComponent: FC<ICargoFooterComponentProps> = ({
  isInDraft,
  discardChanges,
  updateCargo,
  cargosAvailability,
}) => {
  const { t } = useTranslation();
  const { shipmentId = '0' } = useParams();

  const handleUpdateCargo = useCallback(() => {
    updateCargo(+shipmentId);
  }, []);

  const isDisabled = useMemo(() => cargosAvailability !== PermissionAttributePolicy.WRITE || !isInDraft, [cargosAvailability, isInDraft]);

  return (
    <>
      <Button
        disabled={isDisabled}
        size="large"
        type="default"
        onClick={discardChanges}
      >
        {t('Discard Changes')}
      </Button>
      <Button
        disabled={isDisabled}
        size="large"
        onClick={handleUpdateCargo}
      >
        {t('Update Cargo')}
      </Button>
    </>
  );
};
