import {
  ValidateNested,
  IsDefined,
  IsNumber,
  IsString,
  IsOptional,
  IsBoolean,
} from 'class-validator';
import { Type } from 'class-transformer';

import {
  FreightLoadTypeDTM,
  FreightModeDTM,
  IRFQServiceByIdContentRoutesLocationOriginDTM,
  RFQServiceByIdContentRoutesLocationOriginDTM,
  IRFQServiceByIdContentRoutesTransportation,
  RFQServiceByIdContentRoutesTransportation,
} from 'monetary/models/dtm/Quotas';

import {
  QuotaServiceByIdContentRoutesLegsLocationDTM,
  IQuotaServiceByIdContentRoutesLegsLocationDTM,
  IFreightQuotaContentContainerDTM,
  FreightQuotaContentContainerDTM,
  IFreightQuotaContentContainerRequestsLocationDTM,
  FreightQuotaContentContainerRequestsLocationDTM,
} from 'monetary/models/dtm';
import { BaseDTM } from 'proto/BaseDTM';

export type TRouteLegPhase = 'FREIGHT' | 'ORIGIN' | 'DESTINATION';

export interface IRouteLegDTM { // TODO: migration to class when monetary models will be migrated
  arrivalLocation: IQuotaServiceByIdContentRoutesLegsLocationDTM;
  departureLocation: IQuotaServiceByIdContentRoutesLegsLocationDTM;
  id: number;
  phase: TRouteLegPhase;
  sequence: number;
}

export class RouteLegDTM extends BaseDTM<IRouteLegDTM> {
  @IsDefined()
  @ValidateNested()
  @Type(() => QuotaServiceByIdContentRoutesLegsLocationDTM)
  arrivalLocation: QuotaServiceByIdContentRoutesLegsLocationDTM;

  @IsDefined()
  @ValidateNested()
  @Type(() => QuotaServiceByIdContentRoutesLegsLocationDTM)
  departureLocation: QuotaServiceByIdContentRoutesLegsLocationDTM;

  @IsDefined()
  @IsNumber()
  id: number;

  @IsDefined()
  @IsString()
  phase: TRouteLegPhase;

  @IsDefined()
  @IsNumber()
  sequence: number;
}

export interface ITransportPlanRouteDTM {
  destination: IRFQServiceByIdContentRoutesLocationOriginDTM;
  origin: IRFQServiceByIdContentRoutesLocationOriginDTM;
  legs: IRouteLegDTM[];
}

export class TransportPlanRouteDTM extends BaseDTM<ITransportPlanRouteDTM> {
  @IsDefined()
  @ValidateNested()
  @Type(() => RFQServiceByIdContentRoutesLocationOriginDTM)
  destination: RFQServiceByIdContentRoutesLocationOriginDTM;

  @IsDefined()
  @ValidateNested()
  @Type(() => RFQServiceByIdContentRoutesLocationOriginDTM)
  origin: RFQServiceByIdContentRoutesLocationOriginDTM;

  @IsDefined()
  @ValidateNested({ each: true })
  @Type(() => RouteLegDTM)
  legs: RouteLegDTM[];
}

export interface IRequestDTM {
  id: number;
  freightMode: string;
  loadType: string;
  status: string;
  organizationId: number;
  createdBy: string;
  tradeType: string;
  isSelfServiceRequest: boolean;
  includeRelatedPorts: boolean;
  scheduleId: number;
  origin: IFreightQuotaContentContainerRequestsLocationDTM;
  destination: IFreightQuotaContentContainerRequestsLocationDTM;
  container: IFreightQuotaContentContainerDTM;
  additionalServices: string[];
  referencePlanId: number;
  filterEvensByType: string;
}

export class RequestDTM extends BaseDTM<IRequestDTM> {
  @IsDefined()
  @IsNumber()
  id: number;

  @IsDefined()
  @IsString()
  freightMode: string;

  @IsDefined()
  @IsString()
  loadType: string;

  @IsDefined()
  @IsString()
  status: string;

  @IsDefined()
  @IsNumber()
  organizationId: number;

  @IsDefined()
  @IsString()
  createdBy: string;

  @IsDefined()
  @IsString()
  tradeType: string;

  @IsDefined()
  @IsBoolean()
  isSelfServiceRequest: boolean;

  @IsDefined()
  @IsBoolean()
  includeRelatedPorts: boolean;

  @IsDefined()
  @IsNumber()
  scheduleId: number;

  @IsDefined()
  @ValidateNested()
  @Type(() => FreightQuotaContentContainerRequestsLocationDTM)
  origin: FreightQuotaContentContainerRequestsLocationDTM;

  @IsDefined()
  @ValidateNested()
  @Type(() => FreightQuotaContentContainerRequestsLocationDTM)
  destination: FreightQuotaContentContainerRequestsLocationDTM;

  @IsDefined()
  @ValidateNested()
  @Type(() => FreightQuotaContentContainerDTM)
  container: FreightQuotaContentContainerDTM;

  @IsDefined()
  @IsString({ each: true })
  additionalServices: string[];

  @IsDefined()
  @IsNumber()
  referencePlanId: number;

  @IsDefined()
  @IsString()
  filterEvensByType: string;
}

export interface ITransportPlanDTM {
  freightMode: FreightModeDTM;
  id: number;
  loadType: FreightLoadTypeDTM;
  route: ITransportPlanRouteDTM;
  transportations: IRFQServiceByIdContentRoutesTransportation[];
  container?: IFreightQuotaContentContainerDTM;
  request?: IRequestDTM;
}

export class TransportPlanDTM extends BaseDTM<ITransportPlanDTM> {
  @IsDefined()
  @IsString()
  freightMode: FreightModeDTM;

  @IsDefined()
  @IsNumber()
  id: number;

  @IsDefined()
  @IsString()
  loadType: FreightLoadTypeDTM;

  @IsDefined()
  @ValidateNested()
  @Type(() => TransportPlanRouteDTM)
  route: TransportPlanRouteDTM;

  @IsDefined()
  @ValidateNested({ each: true })
  @Type(() => RFQServiceByIdContentRoutesTransportation)
  transportations: RFQServiceByIdContentRoutesTransportation[];

  @IsOptional()
  @ValidateNested()
  @Type(() => FreightQuotaContentContainerDTM)
  container?: FreightQuotaContentContainerDTM

  @IsOptional()
  @ValidateNested()
  @Type(() => RequestDTM)
  request?: RequestDTM;
}
