import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Typography from 'antd/es/typography';

export const Documents: FC = () => {
  const { t } = useTranslation();

  return (
    <Typography.Title level={4}>
      {t('Documents')}
    </Typography.Title>
  );
};
