import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'app-wrapper/createSlice';

import { IAgreementsState } from 'app-wrapper/models/states';
import { ShipmentPreviewDTM } from 'shipment-operations/models/dtm';
import { AccountDepartmentDTM, AdminPublicInfoDTM } from 'user-management/models/dtm';

const initialState: IAgreementsState = {
  isLoading: false,
  isDrawerOpened: false,
  shouldAgreeWithNRA: false,
  shipmentId: undefined,
  isNRAChecked: false,
  isTermsChecked: false,
  isPlacedAtShipmentTasks: false,
  isPlacedAtTasks: false,
  shipment: undefined,
  currentOrgRelatedAdmin: null,
  accountHolderBookingOpsDepartment: null,
  accountHolderCustomerOnboardingDepartment: null,
};

export const agreementsStore = createSlice({
  name: 'agreementsStore',
  initialState,
  reducers: {
    setIsLoading: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      isLoading: payload,
    }),
    setShipmentId: (state, { payload }: PayloadAction<string | undefined>) => ({
      ...state,
      shipmentId: payload,
    }),
    setShipment: (state, { payload }: PayloadAction<ShipmentPreviewDTM | undefined>) => ({
      ...state,
      shipment: payload,
    }),
    setIsDrawerOpened: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      isDrawerOpened: payload,
    }),
    setShouldAgreeWithNRA: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      shouldAgreeWithNRA: payload,
    }),
    setIsNRAChecked: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      isNRAChecked: payload,
    }),
    setIsTermsChecked: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      isTermsChecked: payload,
    }),
    setIsPlacedAtShipmentTasks: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      isPlacedAtShipmentTasks: payload,
    }),
    setIsPlacedAtTasks: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      isPlacedAtTasks: payload,
    }),
    setCurrentOrgRelatedAdmin: (state, { payload }: PayloadAction<AdminPublicInfoDTM | null>) => ({
      ...state,
      currentOrgRelatedAdmin: payload,
    }),
    setAccountHolderBookingOpsDepartment: (state, { payload }: PayloadAction<AccountDepartmentDTM | null>) => ({
      ...state,
      accountHolderBookingOpsDepartment: payload,
    }),
    setAccountHolderCustomerOnboardingDepartment: (state, { payload }: PayloadAction<AccountDepartmentDTM | null>) => ({
      ...state,
      accountHolderCustomerOnboardingDepartment: payload,
    }),
    clear: (state) => ({
      ...state,
      shipment: undefined,
      shouldAgreeWithNRA: false,
      isLoading: false,
      shipmentId: undefined,
      isNRAChecked: false,
      isTermsChecked: false,
      isPlacedAtShipmentTasks: false,
      isPlacedAtTasks: false,
    }),
  },
});

export const agreementsReducer = agreementsStore.reducer;
export const agreementsActions = agreementsStore.actions;
