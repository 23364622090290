import React from 'react';
import { useSelector } from 'react-redux';

import { UC } from 'user-management/controllers';
import { R } from 'user-management/repository';

import { NewOrganizationReviewAndConfirmComponent } from './NewOrganizationReviewAndConfirm.component';

const NewOrganizationReviewAndConfirmContainer = () => {
  const isLoading = useSelector(R.selectors.newOrganizationWizardReviewAndConfirm.getLoading);
  const areTermsChecked = useSelector(R.selectors.newOrganizationWizardReviewAndConfirm.getAreTermsChecked);

  return (
    <NewOrganizationReviewAndConfirmComponent
      initData={UC.newOrganizationWizardReviewAndConfirm.initData}
      onConfirm={UC.newOrganizationWizardReviewAndConfirm.confirmAndReview}
      onGoBack={UC.newOrganizationWizardReviewAndConfirm.goPrevStep}
      cancelWizard={UC.newOrganizationWizardReviewAndConfirm.cancelWizard}
      isAbleToConfirm={areTermsChecked}
      isLoading={isLoading}
    />
  );
};

export { NewOrganizationReviewAndConfirmContainer as NewOrganizationReviewAndConfirm };
