import styled from 'styled-components';

import CloseSquareOutlined from '@ant-design/icons/CloseSquareOutlined';

export const CloseIcon = styled(CloseSquareOutlined)`
  svg {
    width: 30px;
    height: 30px;
    fill: ${({ theme }) => theme.bookingPage.bookingModal.header.closeIcon.innerBorder};

    path:first-child  {
      fill: ${({ theme }) => theme.bookingPage.bookingModal.header.closeIcon.innerIcon};
    }
  }
`;
