import React, { FC, memo } from 'react';
import { useSelector } from 'react-redux';

import { UC } from 'shipment-operations/controllers';
import { R } from 'shipment-operations/repository';

import { TransportationOceanBookingComponent } from './TransportationOceanBooking.component';

const TransportationOceanBookingContainer: FC = memo(() => {
  const shipment = useSelector(R.selectors.shipment.getShipment);

  return (
    <TransportationOceanBookingComponent
      bookingStatus={shipment?.bookingStatus}
      loadData={UC.transportationOverview.fetchTransportationOverviewData}
      reset={UC.transportationOverview.reset}
    />
  );
});

export {
  TransportationOceanBookingContainer as TransportationOceanBooking,
};
