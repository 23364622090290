import React, { FC } from 'react';

import { FormStatus } from 'app-wrapper/view/components';

interface ICargoFormStatusProps {
  isError?: boolean
}

export const CardTopFormStatus: FC<ICargoFormStatusProps> = ({ isError }) => {
  if (isError) {
    return <FormStatus type="incomplete" />;
  }

  return <FormStatus type="complete" />;
};
