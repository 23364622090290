import React, { FC, useMemo, CSSProperties } from 'react';

import {
  Step,
  StepName,
  ChosenStub,
  WaitingIcon,
  PassedIcon,
  CompletedIcon,
} from './Step.styled';

export enum EStepStatus {
  WAITING = 'WAITING',
  PASSED = 'PASSED',
  COMPLETED = 'COMPLETED',
}

interface StepProps {
  name: string;
  status: EStepStatus;
  isChosen?: boolean;
  onClick: () => void;
}

const StepComponent: FC<StepProps> = ({
  name,
  isChosen,
  status,
  onClick,
}) => {
  const Icon = useMemo(() => {
    if (status === EStepStatus.PASSED) {
      return <PassedIcon />;
    }

    if (status === EStepStatus.COMPLETED) {
      return <CompletedIcon />;
    }

    if (status === EStepStatus.WAITING) {
      return <WaitingIcon />;
    }

    return null;
  }, [status]);

  const stepStyles = useMemo<CSSProperties>(() => (isChosen ? {
    boxShadow: '0px 13px 9px -3px rgba(0,0,0,0.12)',
  } : {}), [isChosen]);

  const nameStyles = useMemo<CSSProperties>(() => (status === EStepStatus.PASSED || status === EStepStatus.COMPLETED ? ({
    color: 'rgba(115, 129, 155, 1)',
  }) : {}), [status]);

  return (
    <Step
      onClick={onClick}
      style={stepStyles}
    >
      {isChosen ? <ChosenStub /> : null}

      {Icon}

      <StepName style={nameStyles}>
        {name}
      </StepName>
    </Step>
  );
};

export { StepComponent as Step };
