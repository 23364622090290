import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { REQUEST_STATUS } from 'app-wrapper/constants';

import { UC } from 'user-management/controllers';
import { R } from 'user-management/repository';

import { ContactBookByIdComponent } from './ContactBookById.component';

const ContactBookByIdContainer: FC = () => {
  const ContactBookByIdDefault = useSelector(R.selectors.contactBook.getContactBookDefault);
  const ContactBook = useSelector(R.selectors.contactBook.getContactBookUpdate);

  const listIndex = ContactBook.activeListTabIndex;

  const companyListState = useSelector(R.selectors.contactBook.getContactBookUpdateCompanyListByIds(listIndex));

  const addressesIds = companyListState?.addressesIds || [];
  const contactPersonsIds = companyListState?.contactPersonsIds || [];

  const companyListAddresses = useSelector(R.selectors.contactBook.getContactBookDefaultCompanyListAddressesByIds(addressesIds));
  const companyListContactPersons = useSelector(R.selectors.contactBook.getContactBookDefaultCompanyListContactPersonsByIds(contactPersonsIds));

  return (
    <ContactBookByIdComponent
      companyListState={ContactBook.companyList}
      companyListAddresses={companyListAddresses}
      companyListContactPersons={companyListContactPersons}
      isLoading={ContactBookByIdDefault.getRequestStatus === REQUEST_STATUS.pending}
      runComponent={UC.contactBook.onRunContactBookById}
      onClickAddAddress={UC.contactBookDrawers.onOpenContactBookByIDAddAddress}
      onClickEditAddress={UC.contactBookDrawers.onOpenContactBookByIDEditAddress}
      onClickEditCompany={UC.contactBookDrawers.onOpenContactBookByIDEditCompany}
      clickListItem={UC.contactBook.onClickContactBookAddressByIdContact}
      onClickArrow={UC.contactBook.onBackContactBookById}
    />
  );
};

export { ContactBookByIdContainer as ContactBookByIdPage };
