import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';
import { UC } from 'shipment-operations/controllers';

import { VesselInputComponent } from './VesselInput.component';

interface VesselInputContainerProps {
  id: number;
}

const VesselInputContainer: FC<VesselInputContainerProps> = ({ id }) => {
  const transportation = useSelector(R.selectors.manualBookingWizard.getTransportationById(id));

  return (
    <VesselInputComponent
      onChange={UC.manualBookingWizard.setVesselName}
      transportation={transportation}
    />
  );
};

export { VesselInputContainer as VesselInput };
