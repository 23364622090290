import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { BookingConfirmationPDFGeneralInfoDTM } from 'shipment-operations/models/dtm';

import {
  GeneralInfo,
  Column,
  ColumnItems,
  ColumnInfo,
  TotalColumn,
  TotalAmount,
  TotalTitle,
  CompanyName,
  Title,
  Text,
  TotalRow,
} from './PDFGeneralInfo.styled';

interface InvoicePDFGeneralInfoComponentProps {
  generalInfo: BookingConfirmationPDFGeneralInfoDTM | null;
  total: number;
  shipmentName: string;
}

export const PDFGeneralInfoComponent: FC<InvoicePDFGeneralInfoComponentProps> = ({
  total,
  generalInfo,
  shipmentName,
}: InvoicePDFGeneralInfoComponentProps) => {
  const { t } = useTranslation();

  return (
    <GeneralInfo>
      {generalInfo ? (
        <>
          <Column>
            <Title>
              {`${t('Booking Confirmation')} ${shipmentName}`}
            </Title>

            <ColumnItems>
              <ColumnInfo>
                <CompanyName>
                  {generalInfo.companyName}
                </CompanyName>

                <Text>
                  {generalInfo.address}
                </Text>

                <Text>
                  {generalInfo.addressLine}
                </Text>
              </ColumnInfo>

              <ColumnInfo>
                <CompanyName>
                  {generalInfo.fullName}
                </CompanyName>

                <Text>
                  {generalInfo.phone}
                </Text>

                <Text>
                  {generalInfo.email}
                </Text>
              </ColumnInfo>
            </ColumnItems>
          </Column>
        </>
      ) : (
        <Column>
          <Title>
            {`${t('Booking Confirmation')} ${shipmentName}`}
          </Title>

          <CompanyName>
            {t('Customer is not specified')}
          </CompanyName>
        </Column>
      )}

      <TotalColumn>
        <TotalRow>
          <TotalTitle>
            {t('Total')}
          </TotalTitle>

          <TotalAmount>
            {'$ '}
            {total.toFixed(2)}
          </TotalAmount>
        </TotalRow>
      </TotalColumn>
    </GeneralInfo>
  );
};
