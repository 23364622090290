import moment from 'moment/moment';

import { BaseUseCase } from 'app-wrapper/usecases/Base.useCase';
import { R } from 'shipment-operations/repository';
import {
  DOCUMENTATION, PORT, UPDATE_TRANSPORT_PLAN, VGM,
} from 'shipment-operations/constants';

interface ICutOff {
  terminalCutOff: string
  vgmCutOff: string
  documentCutOff: string
}

export class GetEstimatedChargesUseCase extends BaseUseCase {
  findDiff = (changed: ICutOff, original: ICutOff) => {
    const diff: Partial<ICutOff> = {};
    (Object.keys(changed) as (keyof ICutOff)[]).forEach((key) => {
      if (changed[key] !== original[key]) {
        diff[key] = changed[key];
      }
    });

    return diff;
  }

  parseCutoffs = (obj: { [key: string]: string }) => {
    const mappedNames = {
      terminalCutOff: PORT,
      vgmCutOff: VGM,
      documentCutOff: DOCUMENTATION,
    };
    return Object.entries(obj).map(([key, value]) => ({
      type: mappedNames[key as keyof ICutOff],
      value: moment.parseZone(value),
    }));
  }

  parseTransportPlan = () => {
    const plan = R.selectors.shipmentTrackerRoutes.getPlan(this.controller.store.getState());
    const savedLegs = R.selectors.shipmentTrackerRoutes.getLegs(this.controller.store.getState());
    const newTransportation = [...plan[0].transportations];
    const mergedTransportPlan = newTransportation.map((item) => {
      const matchedLeg = savedLegs.find((leg) => leg.id === item.transportLeg);
      const fullArrivalLocation = matchedLeg?.nextLocationList.find((elem) => elem.code === matchedLeg.nextLocation);
      const fullDepartureLocation = matchedLeg?.locationList.find((elem) => elem.code === matchedLeg.location);
      const newArrivalLocation = matchedLeg?.isUpdatedNextLocation ? {
        country: {
          code: fullArrivalLocation?.countryCode,
          name: fullArrivalLocation?.countryName,
        },
        state: (fullArrivalLocation?.subdivisionCode && fullArrivalLocation?.subdivisionName) ? {
          code: fullArrivalLocation?.subdivisionCode,
          name: fullArrivalLocation?.subdivisionName,
        } : null,
        coordinates: fullArrivalLocation?.coordinates,
        timeZoneId: fullArrivalLocation?.timezoneId,
        code: fullArrivalLocation?.code,
        name: fullArrivalLocation?.locationName,
        type: matchedLeg.nextLocationType,
      } : item.leg?.arrivalLocation;
      const newDepartureLocation = matchedLeg?.isUpdatedLocation ? {
        country: {
          code: fullDepartureLocation?.countryCode,
          name: fullDepartureLocation?.countryName,
        },
        state: (fullDepartureLocation?.subdivisionCode && fullDepartureLocation?.subdivisionName) ? {
          code: fullDepartureLocation?.subdivisionCode,
          name: fullDepartureLocation?.subdivisionName,
        } : null,
        coordinates: fullDepartureLocation?.coordinates,
        timeZoneId: fullDepartureLocation?.timezoneId,
        code: fullDepartureLocation?.code,
        name: fullDepartureLocation?.locationName,
        type: matchedLeg.locationType,
      } : item.leg?.departureLocation;
      return {
        ...item,
        schedule: {
          ...item.schedule,
          departureTime: matchedLeg?.etdTime.format(),
          arrivalTime: matchedLeg?.etaTime.format(),
        },
        voyageCode: matchedLeg?.voyage || '',
        transport: {
          ...item.transport,
          type: matchedLeg?.type,
          name: matchedLeg?.vessel || '',
          number: matchedLeg?.number || matchedLeg?.voyage || '',
        },
        leg: {
          ...item.leg,
          arrivalLocation: newArrivalLocation,
          departureLocation: newDepartureLocation,
        },
      };
    });

    return {
      ...plan[0],
      transportations: mergedTransportPlan,
    };
  }

  public async getEstimatedCharges(shipmentId: string, type: string) {
    let preparedCutoffs = [];
    if (type === UPDATE_TRANSPORT_PLAN) {
      const schedule = R.selectors.shipmentTrackerRoutes.getEditableSchedules(this.controller.store.getState());
      const originalSchedule = R.selectors.shipmentTrackerRoutes.getOriginalSchedules(this.controller.store.getState());
      const diff = this.findDiff(schedule, originalSchedule);
      preparedCutoffs = this.parseCutoffs(diff);
    } else {
      const getChangedCutoffs = R.selectors.shipmentChanges.getChangedCutOffs(this.controller.store.getState());
      preparedCutoffs = getChangedCutoffs.filter((item) => item.value);
    }

    const savedOffset = R.selectors.shipment.getOriginTimeZone(this.store.getState());
    const savedOffsetChanges = R.selectors.shipmentChanges.getOriginTimeZone(this.store.getState());
    const cutoffsTimezone = preparedCutoffs.map((item) => ({
      ...item,
      value: moment.parseZone(item.value).format().substring(0, 19) + (savedOffset || savedOffsetChanges),
    }));

    let transportPlan: {} | null = {};
    if (type === UPDATE_TRANSPORT_PLAN) {
      transportPlan = this.parseTransportPlan();
    } else {
      const savedTransportPlan = R.selectors.shipmentChanges.getMismatchedTransportPlan(this.controller.store.getState());
      transportPlan = savedTransportPlan?.mismatchesCurrent || null;
    }
    const updatedPlan = {
      cutOffs: cutoffsTimezone,
      transportationPlan: transportPlan,
    };
    const chargesResponse = await R.services.shipmentCharges.getEstimatedCharges(shipmentId, updatedPlan);
    this.controller.dispatch(R.actions.shipmentTrackerRoutes.setEstimatedCharges(chargesResponse));

    const paymentTerms = await R.services.paymentTerms.getShipmentPaymentTerms(shipmentId);
    if (paymentTerms) {
      const { incoterm } = paymentTerms;
      this.controller.dispatch(R.actions.shipmentTrackerRoutes.setIncoterm(incoterm));
    }

    this.controller.dispatch(R.actions.shipmentTrackerRoutes.setValidationLoading(false));
    this.controller.dispatch(R.actions.shipmentTrackerRoutes.setValidationLoaded(true));
    this.controller.dispatch(R.actions.shipmentTrackerRoutes.setShowAddChargeButton(false));
  }
}
