import i18n from 'app-wrapper/i18n/i18n';

export const parseDurationFromHoursToDays = (duration: number, hours?: boolean): string => {
  let daysText = '';
  let hoursText = '';
  if (hours) {
    const days = Math.floor(duration / 60 / 24);
    const hoursDiff = Math.ceil((duration - (days * 60 * 24)) / 60);
    daysText = `${days} ${i18n.t('Day(s)')}`;
    hoursText = ` ${hoursDiff} ${i18n.t('Hour(s)')}`;
  } else {
    daysText = `${Math.ceil(duration / 60 / 24)} ${i18n.t('Day(s)')}`;
  }
  return `${daysText}${hoursText}`;
};
