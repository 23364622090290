import styled, { css } from 'styled-components';

import { UserManagementFormFooter } from 'user-management/view/components';

import { divFlex } from 'app-wrapper/view/themes/mixin';
import { PageHeader, Input, Button } from 'app-wrapper/view/components';
import { emptyScrollbar } from 'app-wrapper/view/themes/components';

const titleCss = css`
  font-size: 12px;
  font-weight: 400;
  color: ${({ theme }) => theme?.themesColors?.neutralBranding10};
`;

const inputCss = css`
  font-size: 12px;
  font-weight: 400;
  color: ${({ theme }) => theme?.themesColors?.neutralBranding10};
  height: 32px;
`;

const inputLargeCss = css`
  height: 40px;
`;

const inputTopMarginCss = css`
  margin-top: 6px;
`;

export const CBDividerMargins = {
  margin: '0',
  marginTop: '16px',
};

export const CBWrapper = styled.div.attrs({
  'data-class': 'CBWrapper',
})`
  ${divFlex}
  flex-direction: column;

  background-color: ${({ theme }) => theme?.themesColors?.neutralBranding3};
  height: 100%;
`;

export const CBByIdContent = styled.div.attrs({
  'data-class': 'CBByIdContent',
})`
  ${divFlex}
  flex-direction: column;

  height: 100%;

  padding: 0 24px;
`;

export const CBByIdContentBodyFormWrap = styled.div.attrs({
  'data-class': 'CBByIdContentBodyFormWrap',
})`
  ${divFlex}
  ${emptyScrollbar}
  flex-direction: column;

  background-color: ${({ theme }) => theme?.themesColors?.neutralBranding3};
  height: 100%;
  width: 100%;
`;

export const CBByIdContentBodyForm = styled.div.attrs({
  'data-class': 'CBByIdContentBodyForm',
})`
  ${divFlex}
  ${emptyScrollbar}
  flex-direction: column;

  height: 100%;
  width: 100%;

  background-color: ${({ theme }) => theme?.themesColors?.neutralBranding1};
  border: 1px solid ${({ theme }) => theme?.themesColors?.neutralBranding4};
  border-radius: 3px;
`;

export const CBByIdContentBodyFormWrapper = styled.div.attrs({
  'data-class': 'CBByIdContentBodyFormWrapper',
})`
  ${divFlex}
  ${emptyScrollbar}
  flex-direction: column;
  overflow-y: auto;

  height: calc(100% - 64px);
  width: 100%;

  background-color: ${({ theme }) => theme?.themesColors?.neutralBranding1};
  padding: 16px;
`;

export const CBByIdContentBodyFormFooter = styled(UserManagementFormFooter).attrs({
  'data-class': 'CBByIdContentBodyFormFooter',
})`
  width: 100%;

  span {
    font-size: 14px;
  }
`;

export const CBByIdContentBodyFormCompanyFirstBlock = styled.div.attrs({
  'data-class': 'CBByIdContentBodyFormCompanyFirstBlock',
})`
  ${divFlex}
  flex-direction: column;
  width: 100%;
`;

export const ContactBookContactPersonItemDeleteButton = styled(Button).attrs({
  'data-class': 'ContactBookContactPersonItemDeleteButton',
})`
  ${divFlex}
  ${titleCss}

  height: 22px;
  align-items: center;
  justify-content: center;
  width: 82px;
  right: 0;
  margin-left: auto;
`;

export const CBByIdContentBodyFormCompanyName = styled.div.attrs({
  'data-class': 'CBByIdContentBodyFormCompanyName',
})`
  ${divFlex}
  flex-direction: column;
`;

export const CBByIdContentBodyFormCompanyNameTitleWrapper = styled.div.attrs({
  'data-class': 'CBByIdContentBodyFormCompanyNameTitleWrapper',
})`
  ${divFlex}
  width: 100%;
  height: 22px;
`;

export const CBByIdContentBodyFormCompanyNameTitle = styled.div.attrs({
  'data-class': 'CBByIdContentBodyFormCompanyNameTitle',
})`
  ${divFlex}
  ${titleCss}
`;

export const CBByIdContentBodyFormCompanyNameInput = styled(Input).attrs((props: {
  hasError: boolean,
  largeInput: boolean,
}) => ({
  hasError: props.hasError,
  largeInput: props.largeInput,
  'data-class': 'CBByIdContentBodyFormCompanyNameInput',
}))`
  ${divFlex}
  ${inputCss}
  ${inputTopMarginCss}

  ${({ largeInput }) => largeInput && inputLargeCss};
  margin-top: 4px;
  width: 100%;

  ${({ hasError, theme }) => hasError && `border-color: ${theme.themesColors.characterBrandingDanger} !important; border-style: solid;`};
`;

export const CBByIdContentBodyFormCompanyTaxId = styled.div.attrs({
  'data-class': 'CBByIdContentBodyFormCompanyTaxId',
})`
  ${divFlex}
  flex-direction: column;
  margin-top: 10px;
`;

export const CBByIdContentBodyFormCompanyTaxIdTitleWrapper = styled.div.attrs({
  'data-class': 'CBByIdContentBodyFormCompanyTaxIdTitleWrapper',
})`
  ${divFlex}
  width: 100%;
  height: 22px;
`;

export const CBByIdContentBodyFormCompanyTaxIdTitle = styled.div.attrs({
  'data-class': 'CBByIdContentBodyFormCompanyTaxIdTitle',
})`
  ${divFlex}
  ${titleCss}
`;

export const CBByIdContentBodyFormCompanyTaxIdInput = styled(Input).attrs((props: {
  hasError: boolean,
  largeInput: boolean,
}) => ({
  hasError: props.hasError,
  largeInput: props.largeInput,
  'data-class': 'CBByIdContentBodyFormCompanyTaxIdInput',
}))`
  ${divFlex}
  ${inputCss}
  ${inputTopMarginCss}

  ${({ largeInput }) => largeInput && inputLargeCss};
  margin-top: 4px;
  width: 100%;

  ${({ hasError, theme }) => hasError && `border-color: ${theme.themesColors.characterBrandingDanger} !important; border-style: solid;`};
`;

export const CBLayoutHeader = styled(PageHeader).attrs({
  'data-class': 'CBLayoutHeader',
})`
  width: 100%;
  height: 56px;
  padding-left: 24px;

  .ant-page-header-heading {
    ${divFlex}
    align-items: center;
    height: 100%;
  }

  .ant-page-header-heading-title {
    font-size: 20px;
    font-weight: 500;
  }
`;
