import { PackingGroupType } from './PackingGroupType.enum';

export const PackingGroupTypeNames = {
  [PackingGroupType.GROUP_I_GREAT_DANGER]: 'Group I: Great Danger',
  [PackingGroupType.GROUP_II_MEDIUM_DANGER]: 'Group II: Medium Danger',
  [PackingGroupType.GROUP_III_MINOR_DANGER]: 'Group III: Minor Danger',
};

export const PackingGroupTypeShortNames = {
  [PackingGroupType.GROUP_I_GREAT_DANGER]: 'I',
  [PackingGroupType.GROUP_II_MEDIUM_DANGER]: 'II',
  [PackingGroupType.GROUP_III_MINOR_DANGER]: 'III',
};
