import styled from 'styled-components';

export const ShippingFormAddNewItem = styled.div`
  color: ${({ theme }) => theme.themesColors.primaryBranding6};
  padding: 5px 10px;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  &:hover {
    opacity: 0.6;
  }
`;
