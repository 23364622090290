import { CognitoUser, CognitoUserSession } from 'amazon-cognito-identity-js';
import { Auth } from '@aws-amplify/auth';

class CurrentSession {
  private session: CognitoUserSession | undefined

  private user: CognitoUser | undefined

  private userId: string | undefined

  private loadSession = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();

      if (!this.user) {
        this.user = user;
        this.userId = this.user?.getUsername();
      }

      if (!this.session) {
        this.session = await Auth.currentSession();
      }

      if (this.userId && this.userId !== user.getUsername()) {
        // eslint-disable-next-line no-restricted-globals
        location.reload();
      }
    } catch (e) {
      console.error(e);
    }
  }

  getIdToken = async () => {
    try {
      await this.loadSession();

      if (this.session) {
        return this.session.getIdToken().getJwtToken();
      }
    } catch (e) {
      console.error(e);
    }

    return '';
  }

  async refreshSession() {
    const result = await new Promise<CognitoUserSession>((resolve, reject) => {
      if (this.session && this.user) {
        this.user.refreshSession(this.session.getRefreshToken(), (error, newSession) => {
          if (error) {
            reject(error);
          }
          resolve(newSession);
        });
      }
    });
    this.session = result;
    return result;
  }

  signOut() {
    this.user?.signOut();
    this.session = undefined;
    this.user = undefined;
  }
}

export const currentSession = new CurrentSession();
