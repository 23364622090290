import React, {
  FC, memo, useCallback, useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';
import { RouteNames } from 'app-wrapper/constants';
import { useNavigate } from 'react-router';

import {
  PageHeader,
} from 'app-wrapper/view/components';

import {
  CCContent,
  CCContentHeader,
  CCContentHeaderItem,
  CCWrap,
  CCWrapHeader,
} from './CommandCenter.styled';

interface ICommandCenterComponentProps {
  onResetTaskPage: () => void;
}

const CommandCenterComponent: FC<ICommandCenterComponentProps> = (props) => {
  const {
    onResetTaskPage,
  } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();

  const title = t('Action Center');

  useEffect(() => {
    const foo = async () => {
      onResetTaskPage();
    };

    foo();
  }, []);

  const clickTab = useCallback(
    (url: string) => () => {
      navigate(url);
      navigate(RouteNames.COMMAND_CENTER_TASKS());
    },
    [],
  );

  return (
    <>
      <CCWrapHeader>
        <PageHeader title={title} />
      </CCWrapHeader>
      <CCWrap>
        <CCContent>
          <CCContentHeader>
            <CCContentHeaderItem onClick={clickTab(RouteNames.COMMAND_CENTER_TASKS())}>
              {t('Tasks')}
            </CCContentHeaderItem>
            <CCContentHeaderItem onClick={clickTab(RouteNames.COMMAND_CENTER_NOTIFICATION())}>
              {t('Notifications')}
            </CCContentHeaderItem>
          </CCContentHeader>
        </CCContent>
      </CCWrap>
    </>
  );
};

const CommandCenterComponentCache = memo(CommandCenterComponent);

export { CommandCenterComponentCache as CommandCenterComponent };
