import { apiWorker } from 'app-wrapper/repository/utilsServices';
import {
  IPutShipmentDocumentsAdditionalDetailsRequest,
  getShipmentDocumentsSiDetailsResponse,
  putShipmentDocumentsAdditionalDetailsResponse,
} from 'shipment-operations/models/contracts';
import { ShipmentReferenceDTM } from 'shipment-operations/models/dtm';

export class ShipmentDocumentService {
  private formatUrl = (shipmentId: string | number, fileId?: string | number) => (
    `/shipment-service/api/v1/shipments/${shipmentId}/documents${fileId ? `/${fileId}` : ''}`
  )

  public getShipmentDocument = async (
    shipmentId: number,
    documentId: number,
    fileName = 'document',
  ) => {
    const response = await apiWorker.requestGet<Blob>(`/shipment-service/api/v1/shipments/${shipmentId}/documents/${documentId}`, {
      responseType: 'blob',
    });
    const blob = response.data;

    if (!blob) {
      return;
    }

    const url = window.URL.createObjectURL(blob);
    const anchor = document.createElement('a');

    anchor.href = url;
    anchor.download = fileName;
    anchor.click();

    window.URL.revokeObjectURL(url);
    anchor.remove();
  };

  public onRemoveFile = async (shipmentId: string | number, fileId: string) => {
    await apiWorker.requestDelete(this.formatUrl(shipmentId, fileId));
  };

  public getAdditionalDetails = async (shipmentId: number): Promise<putShipmentDocumentsAdditionalDetailsResponse | null> => {
    const response = await apiWorker.requestGet<putShipmentDocumentsAdditionalDetailsResponse>(`/shipment-service/api/v1/shipments/${shipmentId}/additional-details`);

    if (response.status === 204) {
      return null;
    }

    return response.data;
  }

  public putAdditionalDetails = async (shipmentId: number, body: IPutShipmentDocumentsAdditionalDetailsRequest) => {
    const res = await apiWorker.requestPut<ShipmentReferenceDTM>(`/shipment-service/api/v1/shipments/${shipmentId}/additional-details`, body);

    return res.data;
  };

  public putUpdateFileTypeAdditionalDetails = async (
    data: {
      shipmentId: number | string
      documentId: number | string
      newType: string
    },
    body?: string[],
  ) => {
    try {
      const res = await apiWorker.requestPut(`/shipment-service/api/v1/shipments/${data.shipmentId}/documents/${data.documentId}?type=${data.newType}`, body);

      return res;
    } catch (e) {
      throw new Error('Something wrong, please try again: putUpdateFileTypeAdditionalDetails');
    }
  };

  public postAdditionalDetails = async (shipmentId: number, body: IPutShipmentDocumentsAdditionalDetailsRequest) => {
    const res = await apiWorker.requestPost<ShipmentReferenceDTM>(`/shipment-service/api/v1/shipments/${shipmentId}/additional-details`, body);

    return res.data;
  }

  public getSiDetails = async (shipmentId: number): Promise<getShipmentDocumentsSiDetailsResponse | null> => {
    const response = await apiWorker.requestGet<getShipmentDocumentsSiDetailsResponse>(`/shipment-service/api/v1/shipments/${shipmentId}/si-details`);

    return response.data;
  }
}
