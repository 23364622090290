import React, { FC } from 'react';

import {
  SeaworthyDocumentUpload,
  IMODeclarationUpload,
} from 'shipment-operations/view/pages/ShipmentContainerData/components';

import { Wrap, DocumentUploadSection } from './DocumentsForm.styled';

interface DocumentsFormComponentProps {
  isOwnContainer: boolean;
  hasHazmat: boolean;
}

export const DocumentsFormComponent: FC<DocumentsFormComponentProps> = ({ isOwnContainer, hasHazmat }) => {
  if (!isOwnContainer && !hasHazmat) {
    return null;
  }

  return (
    <Wrap>
      {isOwnContainer ? (
        <DocumentUploadSection>
          <SeaworthyDocumentUpload />
        </DocumentUploadSection>
      ) : null}

      {hasHazmat ? (
        <DocumentUploadSection>
          <IMODeclarationUpload />
        </DocumentUploadSection>
      ) : null}
    </Wrap>
  );
};
