import { BaseDTM } from 'proto/BaseDTM';
import { GetPaymentResponseDTM, IGetPaymentResponseDTM } from 'shipment-operations/models/dtm';
import {
  IsDefined, IsNumber, ValidateNested, IsOptional, IsString,
} from 'class-validator';
import { Type } from 'class-transformer';

interface IPaymentsResponseDTM {
  totalElements: number
  data: IGetPaymentResponseDTM[]
}

export class PaymentsResponseDTM extends BaseDTM<IPaymentsResponseDTM> {
  @IsNumber()
  @IsDefined()
  totalElements: number;

  @ValidateNested()
  @Type(() => GetPaymentResponseDTM)
  data: GetPaymentResponseDTM[]
}

export interface IPaymentTableDTM {
  id?: number
  key?: number
  number: string
  postedDate: string
  debtorName: string
  vendorName: string
  status: string
  total: number
  totalLeft: number
}

export class PaymentTableDTM extends BaseDTM<IPaymentTableDTM> {
  @IsNumber()
  @IsOptional()
  id?: number;

  @IsNumber()
  @IsOptional()
  key?: number;

  @IsString()
  @IsDefined()
  number: string;

  @IsString()
  @IsDefined()
  postedDate: string;

  @IsString()
  @IsDefined()
  debtorName: string;

  @IsString()
  @IsDefined()
  vendorName: string;

  @IsString()
  @IsDefined()
  status: string;

  @IsNumber()
  @IsDefined()
  total: number;

  @IsNumber()
  @IsDefined()
  totalLeft: number;
}
