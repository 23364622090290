import React from 'react';
import { useSelector } from 'react-redux';

import { R } from 'user-management/repository';
import { UC } from 'user-management/controllers';

import { CustomerInternalTeamComponent } from './CustomerInternalTeam.component';

export const CustomerInternalTeam = () => {
  const isLoading = useSelector(R.selectors.teams.getIsLoading);
  const team = useSelector(R.selectors.teams.getAccountTeam);
  const teamMembers = useSelector(R.selectors.teams.getTeamMembers);
  const accountManager = useSelector(R.selectors.teams.getAccountManager);
  const isUserAdmin = useSelector(R.selectors.teams.getIsCurrentUserAdmin);

  return (
    <CustomerInternalTeamComponent
      isLoading={isLoading}
      teamMembers={teamMembers}
      openAddTeamDrawer={UC.teams.openChangeTeamDrawer}
      init={UC.teams.initCustomerInternalTeam}
      accountManager={accountManager}
      team={team}
      isAdmin={isUserAdmin}
    />
  );
};
