import React from 'react';
import { IPropsIcon } from 'app-wrapper/interfaces/icon';

export const CompassSvg = (props: IPropsIcon) => {
  const { width, height, style } = props;
  return (
    <svg
      className="CompassSvg"
      width={width || '15'}
      height={height || '14'}
      viewBox="0 0 15 14"
      color={style?.color || '#6C79DE'}
      style={style || undefined}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M7.5 0C3.63438 0 0.5 3.13438 0.5 7C0.5 10.8656 3.63438 14 7.5 14C11.3656 14 14.5 10.8656 14.5 7C14.5 3.13438 11.3656 0 7.5 0ZM4.61406 9.975C4.58281 9.98906 4.54531 9.975 4.53125 9.94219C4.525 9.92656 4.525 9.90781 4.53125 9.89219L6.07344 6.36875L8.1375 8.43281L4.61406 9.975ZM10.475 4.11406L8.93281 7.6375L6.86875 5.57344L10.3922 4.03125C10.4234 4.01719 10.4609 4.03125 10.475 4.06406C10.4813 4.07969 10.4813 4.09687 10.475 4.11406Z" fill="currentColor" />
    </svg>
  );
};
