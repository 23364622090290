import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';
import { UC } from 'shipment-operations/controllers';
import { ShipmentTrackerHeaderComponent } from './ShipmentTrackerHeader.component';

interface ITrackerHeaderContainerProps {
  isPageLoading: boolean
  skeletonHeight: string
  withEdit: boolean
}

const TrackerHeaderContainer: FC<ITrackerHeaderContainerProps> = ({ skeletonHeight, isPageLoading, withEdit }) => {
  const routes = useSelector(R.selectors.shipmentTrackerRoutes.getRoutes);
  const isLoadingSchedules = useSelector(R.selectors.shipmentTrackerRoutes.getIsLoadingSchedules);
  const isErrorSchedules = useSelector(R.selectors.shipmentTrackerRoutes.getIsErrorSchedules);
  const schedule = useSelector(R.selectors.shipmentTrackerRoutes.getSchedule);

  return (
    <ShipmentTrackerHeaderComponent
      setDefaultLegs={UC.shipmentTracker.setDefaultLegs}
      onClear={UC.shipmentTracker.clearEditPlan}
      routes={routes}
      skeletonHeight={skeletonHeight}
      isPageLoading={isPageLoading}
      isLoadingSchedules={isLoadingSchedules}
      isErrorSchedules={isErrorSchedules}
      schedule={schedule}
      withEdit={withEdit}
    />
  );
};

export {
  TrackerHeaderContainer as TrackerHeader,
};
