import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'app-wrapper/createSlice';
import { EAccountDepartmentType } from 'user-management/constants';

import { OrganizationDTM, OrganizationMemberDTM } from 'user-management/models/dtm';
import {
  WorkgroupDTM,
  ShipmentPreviewDTM,
  WorkgroupMemberDTM,
  ShippingPartyDTM,
} from 'shipment-operations/models/dtm';
import { IShipmentPeopleState } from 'shipment-operations/models/states';

const INITIAL_ROLES_TO_EMAILS_STATE = {
  [EAccountDepartmentType.BOOKING_OPS]: undefined,
  [EAccountDepartmentType.DOCUMENTATION_OPS_EXPORT]: undefined,
  [EAccountDepartmentType.DOCUMENTATION_OPS_IMPORT]: undefined,
  [EAccountDepartmentType.DRAYAGE_IMPORT]: undefined,
  [EAccountDepartmentType.DRAYAGE_EXPORT]: undefined,
  [EAccountDepartmentType.ACCOUNTING_PAYABLES]: undefined,
  [EAccountDepartmentType.ACCOUNTING_RECEIVABLES]: undefined,
  [EAccountDepartmentType.CUSTOMER_ONBOARDING]: undefined,
  [EAccountDepartmentType.ACCOUNT_MANAGEMENT]: undefined,
  [EAccountDepartmentType.PRICING]: undefined,
  [EAccountDepartmentType.RATE_MANAGEMENT]: undefined,
  [EAccountDepartmentType.SALES]: undefined,
};

const initialState: IShipmentPeopleState = {
  isLoading: false,
  workgroupId: 0,
  workgroups: [],
  workgroupMembersEmails: [],
  isDrawerOpened: false,
  isDrawerLoading: false,
  tempMember: undefined,
  drawerMembers: [],
  currentOrganization: null,
  shipment: null,
  rolesToEdit: [],
  workgroupMembers: [],
  shippingParties: [],
  rolesToEmailsState: {
    ...INITIAL_ROLES_TO_EMAILS_STATE,
  },
  rolesToEmailsInitialState: {
    ...INITIAL_ROLES_TO_EMAILS_STATE,
  },
};

export const shipmentPeopleStore = createSlice({
  name: 'shipmentPeople',
  initialState,
  reducers: {
    setLoading: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      isLoading: payload,
    }),
    setWorkgroups: (state, { payload }: PayloadAction<WorkgroupDTM[]>) => ({
      ...state,
      workgroups: payload,
    }),
    setIsDrawerOpened: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      isDrawerOpened: payload,
    }),
    setIsDrawerLoading: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      isDrawerLoading: payload,
    }),
    setWorkgroupId: (state, { payload }: PayloadAction<number>) => ({
      ...state,
      workgroupId: payload,
    }),
    setWorkgroupMembersEmails: (state, { payload }: PayloadAction<string[]>) => ({
      ...state,
      workgroupMembersEmails: payload,
    }),
    setDrawerMembers: (state, { payload }: PayloadAction<OrganizationMemberDTM[]>) => ({
      ...state,
      drawerMembers: payload,
    }),
    setTempMember: (state, { payload }: PayloadAction<string | undefined>) => ({
      ...state,
      tempMember: payload,
    }),
    setShipment: (state, { payload }: PayloadAction<ShipmentPreviewDTM | null>) => ({
      ...state,
      shipment: payload,
    }),
    setShippingParties: (state, { payload }: PayloadAction<ShippingPartyDTM[]>) => ({
      ...state,
      shippingParties: payload,
    }),
    setRolesToEdit: (state, { payload }: PayloadAction<EAccountDepartmentType[]>) => ({
      ...state,
      rolesToEdit: payload,
    }),
    setWorkgroupMembers: (state, { payload }: PayloadAction<WorkgroupMemberDTM[]>) => ({
      ...state,
      workgroupMembers: payload,
    }),
    clearRolesToEmailsState: (state) => ({
      ...state,
      rolesToEmailsState: {
        ...INITIAL_ROLES_TO_EMAILS_STATE,
      },
      rolesToEmailsInitialState: {
        ...INITIAL_ROLES_TO_EMAILS_STATE,
      },
    }),
    setRolesToEmailsStateItem: (state, { payload }: PayloadAction<{ email: string; role: EAccountDepartmentType }>) => {
      const { role, email } = payload;

      state.rolesToEmailsState[role] = email;
    },
    setRolesToEmailsInitialStateItem: (state, { payload }: PayloadAction<{ email: string; role: EAccountDepartmentType }>) => {
      const { role, email } = payload;

      state.rolesToEmailsInitialState[role] = email;
    },
    setCurrentOrganization: (state, { payload }: PayloadAction<OrganizationDTM | null>) => ({
      ...state,
      currentOrganization: payload,
    }),
  },
});

export const shipmentPeopleActions = shipmentPeopleStore.actions;

export const shipmentPeopleReducer = shipmentPeopleStore.reducer;
