import Layout from 'antd/es/layout';
import Skeleton from 'antd/es/skeleton';
import styled from 'styled-components';

import { CustomScrollbar } from 'app-wrapper/view/styles/mixins';

export const ShipmentDetailsFormsWrapper = styled.div.attrs({
  'data-class': 'ShipmentDetailsFormsWrapper',
})`
  position: relative;
  box-sizing: border-box;
  height: 100%;
  padding: 0;

  color: black;
  overflow-y: auto;
`;

export const ShipmentDetailsFormsSkeleton = styled(Skeleton).attrs({
  'data-class': 'ShipmentDetailsFormsSkeleton',
})`
  width: 90%;
  margin: 0 auto;
`;

export const ShipmentDetailsFormsScroll = styled.div.attrs({
  'data-class': 'ShipmentDetailsFormsScroll',
})`
  display: flex;
  gap: 8px;
  flex-direction: column;
  height: calc(100% - 64px);
  overflow-y: auto;
  ${CustomScrollbar}
`;

export const ShipmentDetailsFormsContainer = styled.form.attrs({
  'data-class': 'ShipmentDetailsFormsContainer',
})`
  display: flex;
  flex-direction: column;
  padding: 16px;
  box-sizing: border-box;
  border: 1px solid #EAEEF8;
  border-bottom: none;
  border-radius: 3px;
  background: white;
`;

export const ShipmentDetailsFormsFooter = styled(Layout.Footer).attrs({
  'data-class': 'ShipmentDetailsFormsFooter',
})`
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
  height: 64px;
  width: 100%;
  padding: 0px 24px;

  z-index: 2;
  color: inherit;
  background-color: white;
  box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px rgba(0, 0, 0, 0.08), 0px 9px 28px 8px rgba(0, 0, 0, 0.05);
`;
