import React, { memo, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';
import { UC } from 'shipment-operations/controllers';
import { TradeType } from 'shipment-operations/constants';
import { PaymentTermsDTM, PaymentTermsPayeeCompaniesDTM } from 'shipment-operations/models/dtm';

import { BillOfLadingEditPaymentsComponent } from './BillOfLadingEditPayments.component';

const BillOfLadingEditPaymentsContainer = memo(() => {
  const paymentTerms = useSelector(R.selectors.billOfLadingCommon.getPaymentTerms);
  const shippingPartiesMap = useSelector(R.selectors.billOfLadingCommon.getShippingPartiesAsMap);
  const calculatedPaymentsAmount = useSelector(R.selectors.billOfLadingCommon.getCalculatedAmountsForHBLPaymentTerms);
  const formState = useSelector(R.selectors.billOfLadingCommon.getPaymentsFormState);
  const isStateUpdatePending = useSelector(R.selectors.billOfLadingCommon.getIsStatePending);
  const shortShipment = useSelector(R.selectors.shipment.getShipment);
  const contractOwner = useSelector(R.selectors.billOfLadingCommon.getContractOwner);
  const isHBLPage = useSelector(R.selectors.billOfLadingCommon.getIsHBLPage);

  const availableIncoterms = useMemo(() => {
    if (!paymentTerms) {
      return [];
    }

    return PaymentTermsDTM.getAvailableIncoterms(paymentTerms.tradeType);
  }, [paymentTerms]);

  const payeeCompanies = useMemo(() => {
    if (!shortShipment || !paymentTerms) {
      return PaymentTermsPayeeCompaniesDTM.fromPlain({
        origin: '',
        freight: '',
        destination: '',
      });
    }

    const { isSelfService } = shortShipment;
    const { tradeType } = paymentTerms;

    return PaymentTermsDTM.getPayeeCompanyNames(formState.incoterm, shippingPartiesMap, tradeType, contractOwner, !!isSelfService, isHBLPage);
  }, [formState.incoterm, shippingPartiesMap, paymentTerms, shortShipment, contractOwner, isHBLPage]);

  return (
    <BillOfLadingEditPaymentsComponent
      incoterms={availableIncoterms}
      originAmount={calculatedPaymentsAmount.origin}
      freightAmount={calculatedPaymentsAmount.freight}
      destinationAmount={calculatedPaymentsAmount.destination}
      originPayeeCompany={payeeCompanies.origin}
      freightPayeeCompany={payeeCompanies.freight}
      destinationPayeeCompany={payeeCompanies.destination}
      formState={formState}
      setIncotermFormState={UC.billOfLading.setPaymentsIncoterm}
      isPending={isStateUpdatePending}
      tradeType={paymentTerms?.tradeType as TradeType}
    />
  );
});

export { BillOfLadingEditPaymentsContainer as BillOfLadingEditPayments };
