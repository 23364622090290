import { useAppSelector } from 'app-wrapper/hooks';
import React, { FC } from 'react';

import { IShipmentDocumentDefaultStateDocumentsDTM } from 'shipment-operations/models/dtm';
import { R } from 'shipment-operations/repository';
import { UC } from 'shipment-operations/controllers';
import { SDCFMainContentFormDocumentComponentComponent } from './SDCFMainContentFormDocumentComponent.component';

interface ISDCFMainContentFormDocumentComponent {
  documentFile: IShipmentDocumentDefaultStateDocumentsDTM
  index: number
  authToken: string
  shipmentId?: string | number
  clearDocumentType: (index: number) => void
  onRemoveItem: (index: number) => void
  updateDocumentType: (value: string, index: number) => void
  onUploadDoneHandler: (props: { value: string, name: string }, index: number) => void
  disabled: boolean
}

const SDCFMainContentFormDocumentComponentContainer: FC<ISDCFMainContentFormDocumentComponent> = ({
  documentFile,
  index,
  authToken,
  shipmentId,
  clearDocumentType,
  onRemoveItem,
  updateDocumentType,
  onUploadDoneHandler,
  disabled,
}) => {
  const shipmentDocumentUpdate = useAppSelector(R.selectors.shipmentDocuments.getShipmentDocumentUpdate);

  return (
    <SDCFMainContentFormDocumentComponentComponent
      documentFile={documentFile}
      index={index}
      authToken={authToken}
      shipmentId={shipmentId}
      errorsDocuments={shipmentDocumentUpdate.errors?.documents}
      clearDocumentType={clearDocumentType}
      isBlurDocumentType={UC.shipmentDocumentAdditional.setIsBlurDocumentTypeByIndex}
      isFocusDocumentType={UC.shipmentDocumentAdditional.setIsFocusDocumentTypeByIndex}
      isBlurDocumentFile={UC.shipmentDocumentAdditional.setIsBlurDocumentFileByIndex}
      isFocusDocumentFile={UC.shipmentDocumentAdditional.setIsFocusDocumentFileByIndex}
      onRemoveItem={onRemoveItem}
      updateDocumentType={updateDocumentType}
      onUploadDoneHandler={onUploadDoneHandler}
      onAddItem={UC.shipmentDocumentAdditional.setUpdateAddDocumentsItem}
      disabled={disabled}
    />
  );
};

export { SDCFMainContentFormDocumentComponentContainer as SDCFMainContentFormDocumentComponent };
