import React from 'react';

import { IPropsIcon } from 'app-wrapper/interfaces/icon';

import { Icon } from './ShipFloodedSvg.styled';

export const ShipFloodedSvg = (props: IPropsIcon) => {
  const {
    width, height, fill, color,
  } = props;
  return (
    <Icon
      className="ShipFloodedSvg"
      width={width || '14'}
      height={height || '14'}
      viewBox="0 0 15 12"
      color={color}
      fill={fill || 'currentColor'}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M11.2387 8.38173L12.7699 6.85048C13.1418 6.4786 12.9887 5.84423 12.4855 5.6911L11.5887 5.40673V2.32235C11.5887 1.95048 11.2605 1.62235 10.8887 1.62235H9.48867V0.747351C9.48867 0.462976 9.24805 0.222351 8.96367 0.222351H5.81367C5.50742 0.222351 5.28867 0.462976 5.28867 0.747351V1.62235H3.88867C3.49492 1.62235 3.18867 1.95048 3.18867 2.32235V5.40673L2.26992 5.6911C1.7668 5.84423 1.61367 6.4786 1.98555 6.85048L3.5168 8.38173C3.12305 9.34423 2.24805 10.0224 0.913672 10.0224C0.607422 10.0224 0.388672 10.263 0.388672 10.5474V10.8974C0.388672 11.2036 0.607422 11.4224 0.913672 11.4224C2.24805 11.4224 3.2543 10.9849 4.0418 10.1317C4.34805 10.8974 5.11367 11.4224 5.98867 11.4224H8.78867C9.6418 11.4224 10.4074 10.8974 10.7137 10.1317C11.5012 10.9849 12.5074 11.4224 13.8637 11.4224C14.148 11.4224 14.3887 11.2036 14.3887 10.8974V10.5474C14.3887 10.263 14.148 10.0224 13.8637 10.0224C12.5293 10.0224 11.6324 9.34423 11.2387 8.38173ZM4.58867 3.02235H10.1887V4.94735L7.58555 4.1161C7.5418 4.09423 7.43242 4.09423 7.38867 4.09423C7.32305 4.09423 7.21367 4.09423 7.16992 4.1161L4.58867 4.94735V3.02235Z" fill="currentColor" />
    </Icon>
  );
};
