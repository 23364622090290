import styled from 'styled-components';
import Typography from 'antd/es/typography';
import { CheckCircleFilled, ClockCircleOutlined } from '@ant-design/icons';

import { Button } from 'app-wrapper/view/components';

export const Wrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`;

export const Content = styled.div`
  max-width: 490px;
  display: flex;
  flex-direction: column;
`;

export const Image = styled.img`
  margin-left: auto;
  width: 268px;
  height: auto;
`;

export const Title = styled(Typography)`
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
`;

export const Text = styled(Typography)`
  font-size: 14px;
  line-height: 22px;
  color: rgba(115, 129, 155, 1);
`;

export const Step = styled.div`
  margin-top: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
`;

export const WaitingIcon = styled(ClockCircleOutlined)`
  font-size: 14px;
  color: rgba(115, 129, 155, 1);
`;

export const CompletedIcon = styled(CheckCircleFilled)`
  font-size: 14px;
  color: rgba(117, 203, 63, 1);
`;

export const StepName = styled.div`
  margin-left: 8px;
  font-size: 14px;
  line-height: 22px;
`;

export const ArrowRight = styled.img`
  margin-top: 2px;
  margin-left: 4px;
  width: 14px;
`;

export const RegisterButton = styled(Button)`
  width: fit-content;
  min-width: 166px;
  margin-top: 20px;
  height: 32px;
`;
