import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { R as RApp } from 'app-wrapper/repository';

import { UC } from 'user-management/controllers';
import { R } from 'user-management/repository';
import { ContactsAddressBlockComponent } from 'user-management/view/components';
import { ICompanyAddressDTM } from 'user-management/models/dtm';

interface IContactBookAddressItemContainerProps {
  largeInput: boolean
  addressIndex: number
  companyListStateIndex: string
  isFirst: boolean
  isLast: boolean
  address?: ICompanyAddressDTM
  primaryComponent?: React.ReactNode
}

export const ContactBookAddressItemContainer: FC<IContactBookAddressItemContainerProps> = (props) => {
  const {
    largeInput,
    addressIndex,
    companyListStateIndex,
    isFirst,
    isLast,
    address,
    primaryComponent,
  } = props;

  const countriesList = useSelector(RApp.selectors.countriesList.getCountriesList);

  const errors = useSelector(R.selectors.contactBook.getContactBookUpdateErrorsAddressesById(address?.customId || ''));

  return (
    <ContactsAddressBlockComponent
      largeInput={largeInput}
      countriesList={countriesList}
      isFirst={isFirst}
      isLast={isLast}
      address={address}
      addressIndex={addressIndex}
      companyListStateIndex={companyListStateIndex}
      errors={errors}
      primaryComponent={primaryComponent}
      onChangeAddressLineFirst={UC.contactBookDrawers.onChangeUpdateAddressesAddressLineFirstByIndex(UC.contactBookDrawers.onChangeContactBookByIdAddAddressDrawer)}
      onBlurAddressLineFirst={UC.contactBookDrawers.onBlurUpdateAddressesAddressLineFirstByIndex(UC.contactBookDrawers.onChangeValidateContactBookByIdAddAddressDrawer)}
      onFocusAddressLineFirst={UC.contactBookDrawers.onFocusUpdateAddressesAddressLineFirstByIndex(UC.contactBookDrawers.onChangeValidateContactBookByIdAddAddressDrawer)}
      onChangeAddressLineSecond={UC.contactBookDrawers.onChangeUpdateAddressesAddressLineSecondByIndex(UC.contactBookDrawers.onChangeContactBookByIdAddAddressDrawer)}
      onBlurAddressLineSecond={UC.contactBookDrawers.onBlurUpdateAddressesAddressLineSecondByIndex(UC.contactBookDrawers.onChangeValidateContactBookByIdAddAddressDrawer)}
      onFocusAddressLineSecond={UC.contactBookDrawers.onFocusUpdateAddressesAddressLineSecondByIndex(UC.contactBookDrawers.onChangeValidateContactBookByIdAddAddressDrawer)}
      onChangeCity={UC.contactBookDrawers.onChangeUpdateAddressesCityByIndex(UC.contactBookDrawers.onChangeContactBookByIdAddAddressDrawer)}
      onBlurCity={UC.contactBookDrawers.onBlurUpdateAddressesCityByIndex(UC.contactBookDrawers.onChangeValidateContactBookByIdAddAddressDrawer)}
      onFocusCity={UC.contactBookDrawers.onFocusUpdateAddressesCityByIndex(UC.contactBookDrawers.onChangeValidateContactBookByIdAddAddressDrawer)}
      onChangeCountry={UC.contactBookDrawers.onChangeUpdateAddressesCountryByIndex(UC.contactBookDrawers.onChangeContactBookByIdAddAddressDrawer)}
      onBlurCountry={UC.contactBookDrawers.onBlurUpdateAddressesCountryByIndex(UC.contactBookDrawers.onChangeValidateContactBookByIdAddAddressDrawer)}
      onFocusCountry={UC.contactBookDrawers.onFocusUpdateAddressesCountryByIndex(UC.contactBookDrawers.onChangeValidateContactBookByIdAddAddressDrawer)}
      onClearCountry={UC.contactBookDrawers.onClearUpdateAddressesCountryByIndex(UC.contactBookDrawers.onValidateContactBookByIdAddAddressDrawer)}
      onChangeState={UC.contactBookDrawers.onChangeUpdateAddressesStateByIndex(UC.contactBookDrawers.onChangeContactBookByIdAddAddressDrawer)}
      onBlurState={UC.contactBookDrawers.onBlurUpdateAddressesStateByIndex(UC.contactBookDrawers.onChangeValidateContactBookByIdAddAddressDrawer)}
      onFocusState={UC.contactBookDrawers.onFocusUpdateAddressesStateByIndex(UC.contactBookDrawers.onChangeValidateContactBookByIdAddAddressDrawer)}
      onClearState={UC.contactBookDrawers.onClearUpdateAddressesStateByIndex(UC.contactBookDrawers.onValidateContactBookByIdAddAddressDrawer)}
      onChangePostalCode={UC.contactBookDrawers.onChangeUpdateAddressesPostalCodeByIndex(UC.contactBookDrawers.onChangeContactBookByIdAddAddressDrawer)}
      onBlurPostalCode={UC.contactBookDrawers.onBlurUpdateAddressesPostalCodeByIndex(UC.contactBookDrawers.onChangeValidateContactBookByIdAddAddressDrawer)}
      onFocusPostalCode={UC.contactBookDrawers.onFocusUpdateAddressesPostalCodeByIndex(UC.contactBookDrawers.onChangeValidateContactBookByIdAddAddressDrawer)}
      addAddressItem={UC.contactBookDrawers.setUpdateAddCompanyListStateAddressItem(UC.contactBookDrawers.onValidateContactBookByIdAddAddressDrawer)}
      onRemoveAddress={UC.contactBookDrawers.setUpdateRemoveCompanyListStateAddressItem(UC.contactBookDrawers.onValidateContactBookByIdAddAddressDrawer)}
    />
  );
};

export { ContactBookAddressItemContainer as ContactBookAddressItem };
