import { useSelector } from 'react-redux';
import React, {
  FC,
} from 'react';

import { R } from 'monetary/repository';
import { UC } from 'monetary/controllers';
import { RFRFormRequestComponent } from 'monetary/view/components';

interface IRFRFormRequestComponentProps {
  quotaSearchId?: string | null
  forceCollapse?: boolean
  hideButton?: boolean
  onSubmitReuseRequest?: () => void
}

export const RFRFormRequest: FC<IRFRFormRequestComponentProps> = ({
  forceCollapse,
  quotaSearchId,
  hideButton,
  onSubmitReuseRequest,
}) => {
  const {
    isCollapse, status, quotaStatusMessage, isAllRequestedPage,
  } = useSelector(R.selectors.freightQuote.getFreightQuoteCurrent);

  return (
    <RFRFormRequestComponent
      forceCollapse={forceCollapse}
      quotaSearchId={quotaSearchId}
      hideButton={hideButton}
      isAllRequestedPage={isAllRequestedPage}
      onStartFirstRun={UC.FreightQuote.onStartFirstRun}
      onSubmit={UC.FreightQuote.onSubmit}
      onSubmitReuseRequest={onSubmitReuseRequest}
      onCollapseChange={UC.FreightQuote.onCollapseChanged}
      isCollapsed={isCollapse}
      requestQuotaStatus={status}
      quotaStatusMessage={quotaStatusMessage}
    />
  );
};
