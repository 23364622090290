import React, { FC, memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Input } from 'app-wrapper/view/components';

import {
  FreightQuoteContentModalSave,
  FreightQuoteContentModalSaveWrap,
  FreightQuoteContentModalSaveContent,
  FreightQuoteContentModalSaveContentHeader,
  FreightQuoteContentModalSaveContentTitle,
  FreightQuoteContentModalSaveContentFooter,
  FreightQuoteContentModalSaveContentName,
  FreightQuoteContentModalSaveContentFooterBtnWrap,
} from './FreightQuoteContentModalSave.styles';

interface IFreightQuoteContentModalSaveComponentProps {
  savedRequestName?: string;
  isLoadingRequestName?: boolean;
  onChangeSavedName: (name: string) => void;
  onSaveRequestSavedName: () => void;
  onCloseRequestSavedName: () => void;
}

const FreightQuoteContentModalSaveComponent: FC<IFreightQuoteContentModalSaveComponentProps> = (props) => {
  const {
    savedRequestName,
    isLoadingRequestName,
    onChangeSavedName,
    onSaveRequestSavedName,
    onCloseRequestSavedName,
  } = props;
  const { t } = useTranslation();

  const onChangeSavedNameHandler = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onChangeSavedName(event.target.value);
    },
    [onChangeSavedName],
  );

  return (
    <FreightQuoteContentModalSave>
      <FreightQuoteContentModalSaveWrap>
        <FreightQuoteContentModalSaveContent>
          <FreightQuoteContentModalSaveContentHeader>
            {t('SaveRequest')}
          </FreightQuoteContentModalSaveContentHeader>
          <FreightQuoteContentModalSaveContentTitle>
            {t('NameOfSavedRequest')}
          </FreightQuoteContentModalSaveContentTitle>

          <FreightQuoteContentModalSaveContentName>
            <Input
              value={savedRequestName || ''}
              onChange={onChangeSavedNameHandler}
            />
          </FreightQuoteContentModalSaveContentName>

          <FreightQuoteContentModalSaveContentFooter>
            <Button
              type="default"
              disabled={isLoadingRequestName}
              onClick={onCloseRequestSavedName}
            >
              {t('addChargeMenu.cancel')}
            </Button>
            <FreightQuoteContentModalSaveContentFooterBtnWrap>
              <Button
                disabled={isLoadingRequestName}
                onClick={onSaveRequestSavedName}
                loading={isLoadingRequestName}
              >
                {t('addChargeMenu.save')}
              </Button>
            </FreightQuoteContentModalSaveContentFooterBtnWrap>

          </FreightQuoteContentModalSaveContentFooter>
        </FreightQuoteContentModalSaveContent>
      </FreightQuoteContentModalSaveWrap>
    </FreightQuoteContentModalSave>
  );
};

const FreightQuoteContentModalSaveComponentCached = memo(FreightQuoteContentModalSaveComponent);

export { FreightQuoteContentModalSaveComponentCached as FreightQuoteContentModalSaveComponent };
