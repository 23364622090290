import React, { FC } from 'react';

import { AdditionalServiceInputActivityDTM } from 'shipment-operations/models/dtm';
import { ExportClearanceType } from 'shipment-operations/constants';

import { InputResult } from './components';
import { Results } from './InputResults.styled';

interface IInputResultsComponentProps {
  relatedActivities: AdditionalServiceInputActivityDTM[];
  othersActivities: AdditionalServiceInputActivityDTM[];
  startEditingFlowFromInputActivity: (type: ExportClearanceType) => void;
}

export const InputResultsComponent: FC<IInputResultsComponentProps> = ({
  relatedActivities,
  othersActivities,
  startEditingFlowFromInputActivity,
}) => (
  <Results>
    {relatedActivities.map((activity) => (
      <InputResult
        key={activity.type}
        activity={activity}
        startEditingFlowFromInputActivity={startEditingFlowFromInputActivity}
        isOwnActivity
      />
    ))}
    {othersActivities.map((activity) => (
      <InputResult
        key={activity.type}
        activity={activity}
        isOwnActivity={false}
        startEditingFlowFromInputActivity={startEditingFlowFromInputActivity}
      />
    ))}
  </Results>
);
