import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  height: fit-content;
  padding-top: 20px;
`;

export const TableTitle = styled.div`
  width: 100%;
  padding-bottom: 12px;
  border-bottom: 1px solid #EAEEF8;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #202C3C;
`;

export const TableContainerTitle = styled(TableTitle)`
  border-top: 1px solid #EAEEF8;
  padding-top: 20px;
`;
