import React, { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';
import { UC } from 'shipment-operations/controllers';
import { PermissionAttributePolicy } from 'shipment-operations/constants';

import { TemperatureFieldComponent } from './TemperatureField.component';

interface TemperatureFieldContainerProps {
  containersAvailability?: PermissionAttributePolicy;
}

const TemperatureFieldContainer: FC<TemperatureFieldContainerProps> = ({ containersAvailability }) => {
  const { temperature } = useSelector(R.selectors.temperatureControl.getTemperatureControl);
  const { temperature: temperatureError } = useSelector(R.selectors.temperatureControl.getTemperatureControlErrors);
  const isTemperatureErrorFieldIsActive = useSelector(R.selectors.temperatureControl.isTemperatureErrorFieldIsActive);
  const permissions = useSelector(R.selectors.shipment.getContainersPermissions);

  const availability = useMemo(() => containersAvailability || permissions.containersAvailability, [permissions.containersAvailability, containersAvailability]);

  return (
    <TemperatureFieldComponent
      temperature={temperature}
      isErrorShowed={isTemperatureErrorFieldIsActive}
      error={temperatureError}
      setTemperature={UC.temperatureControl.setTemperature}
      touchField={UC.temperatureControl.touchField}
      containersAvailability={availability}
    />
  );
};

export {
  TemperatureFieldContainer as TemperatureField,
};
