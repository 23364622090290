import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { FlexCol } from 'app-wrapper/view/guideline';
import { Title, Label } from 'shipment-operations/view/pages/ShipmentAdditionalService/components/ServiceWorkflow/сomponents/RequiredActionsFlow/components/StepsForm/components/InputActivity/InputActivity.styled';
import { Button, ButtonWrap } from 'shipment-operations/view/pages/ShipmentAdditionalService/components/ServiceWorkflow/сomponents/RequiredActionsFlow/components/StepsForm/components/SubmitInformationToSkypace/SubmitInformationToSkypace.styled';

interface ISubmitInformationToSkypaceComponentProps {
  onSubmit: () => void;
  disabled?: boolean;
}

export const CompleteServiceComponent: FC<ISubmitInformationToSkypaceComponentProps> = ({ onSubmit, disabled }) => {
  const { t } = useTranslation();

  return (
    <FlexCol>
      <Title>
        {t('Complete Service')}
      </Title>

      <Label>
        {t('Once submitted, the information you provided will be available for the customer to view and download.')}
      </Label>

      <ButtonWrap>
        <Button onClick={onSubmit} disabled={disabled}>
          {t('Submit')}
        </Button>
      </ButtonWrap>
    </FlexCol>
  );
};
