import { plainToInstance } from 'class-transformer';
import moment from 'moment';
import {
  IsDate, IsDefined, IsNumber,
} from 'class-validator';

export interface IDateDTM {
  date: Date
  offset: number
}

export type TDateDTMInput = {
  date: string
  offset: number
}

// this model separates date and offset, date have local TZ offset, but time value should be shifted for target location
export class DateDtm implements IDateDTM {
  @IsDefined()
  @IsDate()
  date: Date

  @IsDefined()
  @IsNumber()
  offset: number

  static fromPlain = (input: TDateDTMInput) => plainToInstance(DateDtm, {
    date: moment.parseZone(input.date).toDate(),
    offset: input.offset,
  });

  static fromPlainWithDateShift = (input: TDateDTMInput) => plainToInstance(DateDtm, {
    date: moment.parseZone(input.date).utcOffset(input.offset, true).toDate(),
    offset: input.offset,
  })

  static getDifBetweenDates = (startDate: DateDtm, endDate: DateDtm) => moment.duration(moment(endDate.date).diff(moment(startDate.date)));

  getValueOf = () => moment(this.date).valueOf()

  // beware: this.date TZ will be ignored, only local time will be used
  getDateAsMomentWithOffset = () => {
    const date = moment(this.date);
    date.utcOffset(this.offset);

    return date;
  }

  getDateAsMomentLocalOffset = () => moment(this.date)

  getFormatDMMM = () => moment.parseZone(this.date).format('D MMM')

  getFormatDMMMHHmm = () => moment.parseZone(this.date).format('D MMM, HH:mm')

  getFormatDMMMHHmmWithOffset = () => moment.parseZone(this.getDateAsMomentWithOffset()).format('D MMM, HH:mm')

  getDateDMMMYYYYHHmm = () => moment.parseZone(this.date).format('D MMM YYYY, HH:mm')

  getDateYYYYMMDD = () => moment.parseZone(this.date).format('YYYY-MM-DD')

  getDateMMMDYYYY = () => moment.parseZone(this.date).format('MMM D, YYYY')

  getDateDMMMYYYYHHmmWithOffset = () => moment.parseZone(this.getDateAsMomentWithOffset()).format('D MMM YYYY, HH:mm')

  getDateMMMDYYYYWithOffset = () => this.getDateAsMomentWithOffset().format('MMMM D, YYYY');

  getDateDMMMYYYY = () => moment.parseZone(this.date).format('D MMM YYYY')

  getDateDMMMYYYYWithOffset = () => moment.parseZone(this.getDateAsMomentWithOffset()).format('D MMM YYYY')

  getDateISO = () => moment(this.date).toISOString()
}
