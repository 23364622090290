import React, { FC, memo } from 'react';
import { useSelector } from 'react-redux';

import { R } from 'user-management/repository';
import { UC } from 'user-management/controllers';
import { EOrganizationType, OrganizationIndustryArray } from 'user-management/constants';

import { EditProfileOrganizationGeneralInformationComponent } from './EditProfileOrganizationGeneralInformation.component';

const EditProfileOrganizationGeneralInformationContainer: FC = () => {
  const organizationUpdate = useSelector(R.selectors.newOrganizationData.getUserOrganizationUpdate);
  const organizationFieldsErrors = useSelector(R.selectors.newOrganizationData.organizationFieldsErrors);
  const isIndustryDisabled = organizationUpdate?.type === EOrganizationType.FF
  || organizationUpdate?.type !== EOrganizationType.BCO;

  return (
    <EditProfileOrganizationGeneralInformationComponent
      organization={organizationUpdate}
      organizationIndustry={OrganizationIndustryArray}
      organizationFieldsErrors={organizationFieldsErrors}
      isIndustryDisabled={isIndustryDisabled}
      onChangeUpdateName={UC.newOrganizationData.onChangeUpdateName}
      onBlurUpdateName={UC.newOrganizationData.onBlurUpdateName}
      onChangeUpdateType={UC.newOrganizationData.onChangeUpdateType}
      onBlurUpdateType={UC.newOrganizationData.onBlurUpdateType}
      onChangeUpdateIndustry={UC.newOrganizationData.onChangeUpdateIndustry}
    />
  );
};

export const EditProfileOrganizationGeneralInformation = memo(EditProfileOrganizationGeneralInformationContainer);
