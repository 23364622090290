import { AnyAction } from '@reduxjs/toolkit';
import { BaseController } from 'proto/BaseController';

export class BaseUseCase {
  controller: BaseController

  constructor(controller: BaseController) {
    this.controller = controller;
  }

  get mobxStores() {
    return this.controller.mobxStore;
  }

  get store() {
    return this.controller.store;
  }

  get repositories() {
    return this.controller.repositories;
  }

  dispatch(action: AnyAction) {
    return this.store.dispatch(action);
  }
}
