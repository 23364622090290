import styled from 'styled-components';

import { FlexCol } from 'app-wrapper/view/guideline';

export const DrawerFields = styled(FlexCol)`
  gap: 16px;
`;

export const Field = styled(FlexCol)`
  gap: 4px;
`;

export const FieldLabel = styled.span`
  font-size: 12px;
  line-height: 20px;
`;
