import styled from 'styled-components';
import {
  divFlex, EmptyScrollbar,
} from 'app-wrapper/view/themes/mixin';
import { CustomScrollbar } from 'app-wrapper/view/styles/mixins';

// Content

export const ARFRQItemInfiniteScrollSpin = styled.div`
${divFlex}
align-items: center;
justify-content: center;
`;

export const AllRFRQuotesComponentDiv2 = styled.div.attrs({
  'data-class': 'AllRFRQuotesComponentDiv',
})`
  ${divFlex}
  height: 89vh;
  width: 100%;
`;

export const AllRFRQuotesEndComponent = styled.div.attrs({
  'data-class': 'AllRFRQuotesEndComponent',
})`
  ${divFlex}
  align-items: center;
  justify-content: center;
  bottom: 5px;
`;

export const AllRFRQuotesComponentDiv = styled(AllRFRQuotesComponentDiv2)`
.ant-tabs-nav-operations {
  display: none;
}
`;

export const AllRFRQuotesComponentQuotesDivWrapper = styled.div.attrs({
  'data-class': 'AllRFRQuotesComponentQuotesDivWrapper',
})`
  ${divFlex}
  width: 100%;
  padding-bottom: 10px;

  background-color: ${({ theme }) => theme.themesColors.lightGrayBorder};
`;

export const AllRFRQuotesComponentQuotesDiv = styled.div.attrs({
  'data-class': 'AllRFRQuotesComponentQuotesDiv',
})`
  ${divFlex}
  width: 100%;
  max-width: 1660px;
  margin: 0 auto;
  height: auto;
  height: 100%;

  flex-direction: column;
  background-color: ${({ theme }) => theme.themesColors.lightGrayBorder};
  padding-left: 24px;
  padding-right: 24px;
`;

export const AllRFRQuotesComponentHeader = styled.div`
  ${divFlex}
  width: 100%;
  height: ${({ theme }) => theme.freightQuotePage.height.filterHeader};
  border-bottom: 1px solid ${({ theme }) => theme.themesColors.neutralBranding4};
`;

export const AllRFRQuotesComponentFilterShowOnly = styled.div.attrs({
  'data-class': 'AllRFRQuotesComponentFilterShowOnly',
})`
  ${divFlex}
  justify-content: center;
  align-items: center;
  height: ${({ theme }) => theme.freightQuotePage.height.filterHeader};
`;

export const AllRFRQuotesComponentTableHeader = styled.div.attrs({
  'data-class': 'AllRFRQuotesComponentTableHeader',
})`
  ${divFlex}

  height: 28px;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.themesColors.neutralBranding3};
  padding-bottom: 10px;
  margin-top: 8px;
`;

export const AllRFRQuotesComponentTableHeaderItem = styled.div.attrs((props: {
  isCenter?: boolean,
  isRight?: boolean,
  width?: string,
  paddingLeft?: string,
}) => ({
  isCenter: props.isCenter,
  isRight: props.isRight,
  width: props.width,
  paddingLeft: props.paddingLeft,
  'data-class': 'AllRFRQuotesComponentTableHeaderItem',
}))`
  ${divFlex}
  align-items: center;
  justify-content: ${({ isCenter }) => (isCenter ? 'center' : 'left')};
  justify-content: ${({ isRight }) => (isRight ? 'right' : 'left')};

  width: ${({ width }) => (width || 'auto')};
  padding-left: ${({ paddingLeft }) => (paddingLeft || '0')};

  color: ${({ theme }) => theme.themesColors.secondaryDot45};
  font-size: 12px;
  font-weight: 400;
`;

export const AllRFRQuotesComponentHeaderFound = styled.div`
  ${divFlex}
  color: ${({ theme }) => theme.themesColors.middleGraySecondary};
  align-items: center;
  font-size: 12px;
  padding-top: 5px;
  margin-left: auto;
`;

export const AllRFRQuotesComponentQuotesItemsDiv = styled.div.attrs({
  'data-class': 'AllRFRQuotesComponentQuotesItemsDiv',
})`
  ${divFlex}
  width: 100%;
  height: auto;
  flex-direction: column;

  overflow: auto;
  ${CustomScrollbar}
  .infinite-scroll-component {
    ${EmptyScrollbar}
  }
`;
