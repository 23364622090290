import styled from 'styled-components';
import Typography from 'antd/es/typography';

import { Button } from 'app-wrapper/view/components';
import { divFlex } from 'app-wrapper/view/themes/mixin';

export const NORContentWrap = styled.div.attrs({
  'data-class': 'NewOrganizationReviewAndConfirmComponent_NORContentWrap',
})`
  ${divFlex}
  flex-direction: column;
  width: 100%;
`;

export const Wrap = styled.div`
  position: relative;
  width: 688px;
  height: fit-content;
  min-height: 85vh;
  display: flex;
  flex-direction: column;
  margin: 24px auto 100px auto;
  padding: 16px;
  border: 1px solid rgba(234, 238, 248, 1);
  background-color: rgba(255, 255, 255, 1);
  border-radius: 3px;
  margin-bottom: 8px;
`;

export const Title = styled(Typography)`
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  padding-bottom: 16px;
  margin-bottom: 16px;
  border-bottom: 1px dashed rgba(234, 238, 248, 1);
`;

export const NORContentHeader = styled.div.attrs({
  'data-class': 'NewOrganizationReviewAndConfirmComponent_NORContentHeader',
})`
  ${divFlex}
  flex-direction: column;
  width: 100%;

  font-size: 14px;
  line-height: 22px;
  font-weight: 500;
  margin-bottom: 8px;
  color: ${({ theme }) => theme?.themesColors?.neutralBranding10};
`;

export const NORContent = styled.div.attrs((props: {
  isEnd: boolean
  'data-class'?: string
}) => ({
  isEnd: props.isEnd,
  'data-class': props?.['data-class'] || 'NewOrganizationReviewAndConfirmComponent_NORContent',
}))`
  ${divFlex}
  flex-direction: column;
  width: 100%;

  ${({ isEnd }) => (isEnd && 'margin-bottom: 64px;')};
`;

export const NORContentDate = styled.div.attrs({
  'data-class': 'NewOrganizationReviewAndConfirmComponent_NORContentDate',
})`
  ${divFlex}
  width: 100%;
  margin-bottom: 16px;

  font-size: 12px;
  font-weight: 400;
  color: ${({ theme }) => theme?.themesColors?.secondaryDot45};
`;

export const NORContentParagraph = styled.div.attrs((props: {
  isNoneMarginBottom: boolean
  'data-class'?: string
}) => ({
  isNoneMarginBottom: props.isNoneMarginBottom,
  'data-class': props?.['data-class'] || 'NewOrganizationReviewAndConfirmComponent_NORContentParagraph',
}))`
  ${divFlex}
  width: 100%;
  margin-bottom: ${({ isNoneMarginBottom }) => (isNoneMarginBottom ? '' : '16px')};
  display: block;

  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  color: ${({ theme }) => theme?.themesColors?.neutralBranding10};
`;

export const NORContentBoldText = styled.span.attrs({
  'data-class': 'NewOrganizationReviewAndConfirmComponent_NORContentBoldText',
})`
  ${divFlex}
  display: inline-flex;

  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  color: ${({ theme }) => theme?.themesColors?.neutralBranding10};
`;

export const NORContentTitle = styled.div.attrs({
  'data-class': 'NewOrganizationReviewAndConfirmComponent_NORContentTitle',
})`
  ${divFlex}
  width: 100%;
  margin-bottom: 16px;
  padding-left: 22px;

  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  color: ${({ theme }) => theme?.themesColors?.neutralBranding10};
  background-color: ${({ theme }) => theme?.themesColors?.neutralBranding2};
`;

export const NORContentItem = styled.div.attrs((props: {
  isColumn: boolean
  isSecondItem: boolean
  isThirdItem: boolean
  isLastMarginBottom: boolean
  'data-class'?: string
}) => ({
  isColumn: props.isColumn,
  isSecondItem: props.isSecondItem,
  isThirdItem: props.isThirdItem,
  isLastMarginBottom: props.isLastMarginBottom,
  'data-class': props?.['data-class'] || 'NewOrganizationReviewAndConfirmComponent_NORContentItem',
}))`
  ${divFlex}
  width: calc(100% - 24px);
  ${({ isColumn }) => (isColumn && 'flex-direction: column')};
  ${({ isSecondItem }) => (isSecondItem && 'margin-left: 24px;')};
  ${({ isThirdItem }) => (isThirdItem && 'margin-left: 46px;')};

  margin-bottom: ${({ isLastMarginBottom }) => (isLastMarginBottom ? '16px' : '8px')};
`;

export const NORContentTextUnderline = styled.div.attrs({
  'data-class': 'NewOrganizationReviewAndConfirmComponent_NORContentItemNumber',
})`
  display: inline;
  text-decoration: underline;
`;

export const NORContentItemNumber = styled.div.attrs({
  'data-class': 'NewOrganizationReviewAndConfirmComponent_NORContentItemNumber',
})`
  ${divFlex}
  width: 18px;
  margin-right: 4px;

  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  color: ${({ theme }) => theme?.themesColors?.neutralBranding10};
`;

export const NORContentTitleEnd = styled.div.attrs({
  'data-class': 'NewOrganizationReviewAndConfirmComponent_NORContentTitleEnd',
})`
  ${divFlex}
  color: ${({ theme }) => theme?.themesColors?.secondaryDot45};

  font-size: 12px;
  line-height: 24px;
  font-weight: 400;
  padding-top: 8px;
  margin-bottom: 16px;
  border-top: 1px dashed rgba(234, 238, 248, 1);
`;

export const Footer = styled.div`
  position: fixed;
  padding: 14px 24px;
  bottom: 0;
  left: 0;
  width: calc(100% - 430px);
  height: 64px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  border: 1px solid rgba(244, 245, 248, 1);
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 0px -12px 24px -10px rgba(0,0,0,0.21);
`;

export const NextButton = styled(Button).attrs({
  'data-class': 'NewOrganizationReviewAndConfirmComponentNextButton',
})`
  margin-left: 8px;
`;

export const CancelButton = styled(Button)`
  margin-right: 24px;
`;
