import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { R } from 'shipment-operations/repository';
import { UC } from 'shipment-operations/controllers';
import { useAuthToken } from 'app-wrapper/hooks';

import { DocumentUploadComponent } from './DocumentUpload.component';

const DocumentUploadContainer = () => {
  const { shipmentId = '0' } = useParams();
  const authToken = useAuthToken();
  const document = useSelector(R.selectors.shipmentDocumentsAll.getAdditionalDocument);
  const type = useSelector(R.selectors.shipmentDocumentsAll.getAdditionalType);

  const isDisabled = useMemo(() => !type, [type]);

  return (
    <DocumentUploadComponent
      authToken={authToken}
      shipmentId={shipmentId}
      containerDocument={document}
      setContainerDocument={UC.shipmentDocumentsAll.setAdditionalDocument}
      downloadDocument={UC.shipmentDocumentsAll.downloadAdditionalDocument}
      documentType={type}
      disabled={isDisabled}
    />
  );
};

export { DocumentUploadContainer as DocumentUpload };
