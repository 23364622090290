import React from 'react';
import { useSelector } from 'react-redux';

import { R as userManagementR } from 'user-management/repository';
import { R } from 'app-wrapper/repository';
import { UC } from 'app-wrapper/controllers';

import { StepsManagerComponent } from './StepsManager.component';

const StepsManagerContainer = () => {
  const organization = useSelector(userManagementR.selectors.userOrganizationData.getUserOrganization);
  const hasOrganizationDocuments = useSelector(userManagementR.selectors.userOrganizationData.getHasRequiredDocuments);
  const hasOwnRequestedQuotas = useSelector(R.selectors.overview.getHasOwnRequestedQuotas);
  const hasOwnBookedShipments = useSelector(R.selectors.overview.getHasOwnBookedShipments);
  const isUserInvited = useSelector(R.selectors.overview.getIsUserInvited);
  const isUserPrimary = useSelector(R.selectors.overview.getIsUserPrimary);
  const isUserTheOnlyMember = useSelector(R.selectors.overview.getIsUserTheOnlyMember);
  const step = useSelector(R.selectors.overview.getStep);

  return (
    <StepsManagerComponent
      step={step}
      isUserInvited={isUserInvited}
      status={organization?.status}
      isTermsAndConditionAccepted={Boolean(organization?.isTermsAndConditionAccepted())}
      isUserPrimary={isUserPrimary}
      hasRequiredDocuments={hasOrganizationDocuments}
      goToBookShipmentStep={UC.overview.goToBookShipmentStep}
      goToQuotasStep={UC.overview.goToQuotasStep}
      goToOrganizationRegistrationStep={UC.overview.goToOrganizationRegistrationStep}
      goToAgreeTermsAndConditions={UC.overview.goToAgreeTermsAndConditions}
      goToInviteMembers={UC.overview.goToInviteMembers}
      isTheOnlyMemberInOrganization={isUserTheOnlyMember}
      hasOwnBookedShipments={hasOwnBookedShipments}
      hasOwnRequestedQuotas={hasOwnRequestedQuotas}
    />
  );
};

export { StepsManagerContainer as StepsManager };
