import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import ClearOutlined from '@ant-design/icons/ClearOutlined';

import { Button } from 'app-wrapper/view/components';

import {
  CategoryFilter,
  CarriersFilter,
  SearchInput,
} from './components';
import {
  Filters,
  Wrap,
  Title,
} from './ContractsFilters.styled';

interface IContractsFiltersComponentProps {
  clearAllFilters: () => void;
  hasAnyActiveFilters: boolean;
}

export const ContractsFiltersComponent: FC<IContractsFiltersComponentProps> = ({ clearAllFilters, hasAnyActiveFilters }) => {
  const { t } = useTranslation();

  return (
    <Filters>
      <Wrap>
        <Title>
          {t('Filters')}
        </Title>
        <CategoryFilter />
      </Wrap>

      <Wrap>
        <CarriersFilter />

        {hasAnyActiveFilters ? (
          <Button
            onClick={clearAllFilters}
            size="small"
            type="default"
            icon={<ClearOutlined />}
          >
            {t('Clear All')}
          </Button>
        ) : null}
      </Wrap>

      <SearchInput />
    </Filters>
  );
};
