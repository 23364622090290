import { services } from './services';
import { actions, reducers, selectors } from './store';
import { mobxStores } from './mobxStores';

export const R = {
  services,
  actions,
  reducers,
  selectors,
  mobxStores,
};
