import styled from 'styled-components';

import { divFlex } from 'app-wrapper/view/themes/mixin';

export const FooterSumSub = styled.div.attrs({
  'data-class': 'FooterSumSub',
})`
  ${divFlex}

  margin-left: 2px;
  margin-right: 2px;
  font-size: 14px;
  font-weight: 500;
  color: ${({ theme }) => theme?.themesColors?.neutralBranding6};
`;
