import { BaseController, controller } from 'proto/BaseController';

import { ContainerDocumentDTM, ContainerDTM } from 'shipment-operations/models/dtm';
import { R } from 'shipment-operations/repository';
import { RouteNames } from 'app-wrapper/constants';

@controller
export class ShipmentDocumentsSeaworthyCertificatesController extends BaseController {
  public fetchDocumentsSeaworthyCertificatesData = async (shipmentId: string) => {
    let containers: ContainerDTM[] = [];

    this.dispatch(R.actions.shipmentDocumentsSeaworthyCertificates.setIsLoading(true));

    try {
      containers = await R.services.shipmentContainers.getContainersList(shipmentId);
    } catch (e) {
      console.error('ShipmentDocumentsController.fetchDocumentsSeaworthyCertificatesData error');
    }

    const hasSOC = containers.some(({ ownContainer }) => ownContainer);

    this.dispatch(R.actions.shipmentDocumentsSeaworthyCertificates.setHasSOC(hasSOC));
    this.dispatch(R.actions.shipmentDocumentsSeaworthyCertificates.setContainers(containers));
    this.dispatch(R.actions.shipmentDocumentsSeaworthyCertificates.setInitialContainers(containers));
    this.dispatch(R.actions.shipmentDocumentsSeaworthyCertificates.setIsLoading(false));
    this.dispatch(R.actions.shipmentDocumentsSeaworthyCertificates.setHasLoadingHappened(true));
  };

  public setContainerDocument = (containerId: string, document: ContainerDocumentDTM | null) => {
    this.dispatch(R.actions.shipmentDocumentsSeaworthyCertificates.setContainerSeaworthyCertificate({
      containerId,
      document,
    }));
    this.dispatch(R.actions.shipmentDocumentsSeaworthyCertificates.clearContainersIdsWithErrors());
  }

  public downloadDocument = (shipmentId: string, containerId: string) => {
    const container = R.selectors.shipmentDocumentsSeaworthyCertificates.getContainerById(containerId)(this.store.getState());

    if (container && container.seaworthyCertificate) {
      const { seaworthyCertificate } = container;

      try {
        R.services.shipmentDocument.getShipmentDocument(+shipmentId, seaworthyCertificate.response.id, seaworthyCertificate.response.name);
      } catch (e) {
        console.error('DocumentsSeaworthyCertificates CONTROLLER: downloadDocument');
      }
    }
  }

  public validateContainersDocuments = () => {
    const containers = R.selectors.shipmentDocumentsSeaworthyCertificates.getContainers(this.store.getState());

    containers.forEach((container) => {
      if (!container.seaworthyCertificate) {
        this.dispatch(R.actions.shipmentDocumentsSeaworthyCertificates.addErrorContainerId(container.id));
      }
    });
  }

  public saveContainersDocuments = async (shipmentId: string) => {
    this.validateContainersDocuments();

    const hasErrors = R.selectors.shipmentDocumentsSeaworthyCertificates.getHasErrors(this.store.getState());

    if (hasErrors) {
      return;
    }

    const containers = R.selectors.shipmentDocumentsSeaworthyCertificates.getContainers(this.store.getState());

    this.dispatch(R.actions.shipmentDocumentsSeaworthyCertificates.setIsUpdateLoading(true));

    try {
      await Promise.all(containers.map((container) => R.services.shipmentContainers.putContainer(shipmentId, container)));
    } catch (e) {
      console.error('DocumentsSeaworthyCertificates CONTROLLER: saveContainersDocuments');
      this.dispatch(R.actions.shipmentDocumentsSeaworthyCertificates.setIsUpdateLoading(false));
      return;
    }

    this.dispatch(R.actions.shipmentDocumentsSeaworthyCertificates.setInitialContainers(containers));
    this.dispatch(R.actions.shipmentDocumentsSeaworthyCertificates.setIsUpdateLoading(false));
  }

  public setInitialState = () => {
    const containers = R.selectors.shipmentDocumentsSeaworthyCertificates.getInitialContainers(this.store.getState());

    this.dispatch(R.actions.shipmentDocumentsSeaworthyCertificates.clearContainersIdsWithErrors());
    this.dispatch(R.actions.shipmentDocumentsSeaworthyCertificates.setContainers(containers));
    this.dispatch(R.actions.shipmentDocumentsSeaworthyCertificates.setHasLoadingHappened(false));
  }

  public goBackToDocuments = (shipmentId: string) => {
    this.navigate(RouteNames.SHIPMENT_DOCUMENTS(shipmentId));
  }
}
