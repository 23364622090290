import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Table from 'antd/es/table';
import get from 'lodash/fp/get';

import {
  CUSTOMER_VIEW,
  PermissionAttributePolicy,
  ShortChargeStatusEnum as statuses,
} from 'shipment-operations/constants';
import { ChargeTableDTM, ShipmentChargesPermissionsDtm } from 'shipment-operations/models/dtm';
import {
  StyledExpandIcon,
} from 'shipment-operations/view/pages/ShipmentCharges/components/CustomTable/CustomTable.styled';
import { ContainerTitleWrapper, ContainerType } from 'shipment-operations/view/components/ExpandPanel/ExpandPanel.styled';
import {
  matchedIcon,
} from 'shipment-operations/view/pages/ShipmentCharges/components/ContainersTable/ContainersTable.component';

import { normalizationCost } from 'app-wrapper/utils';

import {
  StyledBodyCell, StyledType, FirstHeaderWrapper, SubHeader,
} from './ContainersTable.styled';

const { Column, ColumnGroup } = Table;

const useTableColumns = (openedKeys: number[], onToggleCollapse: (key: number) => void, permissions: ShipmentChargesPermissionsDtm, mode: string, isPercentage: boolean, subTitle?: string) => {
  const { t } = useTranslation();

  const renderOpenedCells = useCallback((value, record) => (
    <StyledBodyCell active isOpen={openedKeys.includes(record.key)}>$ {normalizationCost(value, { thousandthSeparatorComma: true, toFixed: 2 })}</StyledBodyCell>
  ), [openedKeys]);

  const renderProfit = useCallback((value, record) => (
    <StyledBodyCell active isOpen={openedKeys.includes(record.key)}>
      {isPercentage ? `% ${record.profitPercentage.toFixed(2)}` : `$ ${value.toFixed(2)}`}
    </StyledBodyCell>
  ), [openedKeys, isPercentage, mode]);

  const fullColumns = useMemo(() => (
    <>
      <ColumnGroup align="left" title={<FirstHeaderWrapper>{subTitle}</FirstHeaderWrapper>}>
        <Column
          title={t('shipmentOperation.transportation.container')}
          dataIndex="description"
          key="description"
          width="19%"
          render={(number: string, record: ChargeTableDTM | {}) => {
            const key = get(['key'], record);
            const type = get(['type'], record);

            return (
              <StyledType active onClick={() => onToggleCollapse(key)} isOpen={openedKeys.includes(key)}>
                <StyledExpandIcon isActive={openedKeys.includes(key)} />
                <ContainerTitleWrapper>
                  <ContainerType active={openedKeys.includes(key)}>
                    {type} &apos;
                  </ContainerType>
                </ContainerTitleWrapper>
                {number || t('Number pending entry')}
              </StyledType>
            );
          }}
        />
        <Column
          title={t('Unit')}
          dataIndex="unitType"
          key="unitType"
          width="12%"
          render={() => null}
        />
      </ColumnGroup>
      <ColumnGroup title={<SubHeader>{t('payables')}</SubHeader>}>
        <Column
          title={t('costPerUnit')}
          dataIndex="buyCostPerUnit"
          key="buyCostPerUnit"
          width="10%"
          align={'right' as 'right'}
          render={() => null}
        />
        <Column
          title={t('QTY')}
          dataIndex="buyQuantity"
          key="buyQuantity"
          width="4%"
          align={'right' as 'right'}
          render={() => null}
        />
        <Column
          title={t('Buy')}
          dataIndex="buyTotalCost"
          key="buyTotalCost"
          width="9%"
          align={'right' as 'right'}
          render={renderOpenedCells}
        />
        <Column
          title={t('Posted')}
          dataIndex="posted"
          key="posted"
          width="8%"
          align="center"
          render={(value: statuses) => matchedIcon[value]}
        />
      </ColumnGroup>
      <ColumnGroup title={<SubHeader>{t('receivables')}</SubHeader>}>
        <Column
          title={t('costPerUnit')}
          dataIndex="costPerUnit"
          key="costPerUnit"
          width="10%"
          align={'right' as 'right'}
          render={() => null}
        />
        <Column
          title={t('QTY')}
          dataIndex="quantity"
          key="quantity"
          width="4%"
          align={'right' as 'right'}
          render={() => null}
        />
        <Column
          title={t('sell')}
          dataIndex="totalCost"
          key="totalCost"
          width="9%"
          align={'right' as 'right'}
          render={renderOpenedCells}
        />
        <Column
          title={t('Invoiced')}
          dataIndex="invoiced"
          key="invoiced"
          width="5%"
          align="center"
          render={(value: statuses) => matchedIcon[value]}
        />
      </ColumnGroup>
      <ColumnGroup align="right">
        <Column
          title={t('shipmentOperation.transportation.profit')}
          dataIndex="profit"
          key="profit"
          width="10%"
          align={'right' as 'right'}
          render={renderProfit}
        />
      </ColumnGroup>
    </>
  ), [openedKeys, isPercentage, mode]);

  const columns = useMemo(() => (
    <>
      <Column
        title={t('shipmentOperation.transportation.container')}
        dataIndex="description"
        key="description"
        width="24%"
        render={(number: string, record: ChargeTableDTM | {}) => {
          const key = get(['key'], record);
          const type = get(['type'], record);
          return (
            <StyledType active onClick={() => onToggleCollapse(key)} isOpen={openedKeys.includes(key)}>
              <StyledExpandIcon isActive={openedKeys.includes(key)} />
              <ContainerTitleWrapper>
                <ContainerType active={openedKeys.includes(key)}>
                  {type} &apos;
                </ContainerType>
              </ContainerTitleWrapper>
              {number || t('Number pending entry')}
            </StyledType>
          );
        }}
      />
      <Column
        title={t('Unit')}
        dataIndex="unitType"
        key="unitType"
        width="19%"
        align={'right' as 'right'}
      />
      <Column
        title={t('costPerUnit')}
        dataIndex="costPerUnit"
        key="costPerUnit"
        width="19%"
        align={'right' as 'right'}
      />
      <Column
        title={t('QTY')}
        dataIndex="quantity"
        key="quantity"
        width="19%"
        align={'right' as 'right'}
      />
      <Column
        title={t('Buy')}
        dataIndex="buyTotalCost"
        key="buyTotalCost"
        width="19%"
        align={'right' as 'right'}
        render={renderOpenedCells}
      />
    </>
  ), [openedKeys, isPercentage, mode]);

  if (mode === CUSTOMER_VIEW) {
    return { columns };
  }

  return { columns: permissions.shipmentChargesAvailability === PermissionAttributePolicy.WRITE ? fullColumns : columns };
};

export { useTableColumns };
