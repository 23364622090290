import React, { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';
import { UC } from 'shipment-operations/controllers';

import { PermissionAttributePolicy, ShipmentBookingStatusEnum } from 'shipment-operations/constants';
import { hasAccess } from 'app-wrapper/utils';
import { UC as appUC } from 'app-wrapper/controllers';
import {
  ShipmentContainersComponent,
} from './ShipmentContainers.component';

const ShipmentContainersContainer: FC = () => {
  const isLoading = useSelector(R.selectors.shipmentContainers.getLoadingState);
  const isShowWarning = useSelector(R.selectors.shipment.getIsBookingStatusWarning);
  const shipment = useSelector(R.selectors.shipment.getShipment);
  const isMaersk = useSelector(R.selectors.shipment.getIsMaersk);
  const withMisMatches = useSelector(R.selectors.shipmentChanges.getWithMismatches);
  const permissions = useSelector(R.selectors.shipment.getDashboardsPermissions);
  const shouldRefresh = useSelector(R.selectors.shipmentChanges.getShouldRefresh);
  const isRequestTheSame = useSelector(R.selectors.shipment.getIsRequestTheSameWithShipment);

  const partnerWarning = useMemo(() => withMisMatches && hasAccess(permissions, PermissionAttributePolicy.WRITE) && (shipment?.bookingStatus === ShipmentBookingStatusEnum.BOOKING_AMENDMENT_SUBMITTED || shipment?.bookingStatus === ShipmentBookingStatusEnum.BOOKING_SUBMITTED), [withMisMatches, shipment?.bookingStatus, shouldRefresh]);

  return (
    <ShipmentContainersComponent
      isLoading={isLoading}
      cleanup={UC.shipmentContainers.clearPageState}
      isShowWarning={isShowWarning || partnerWarning}
      toggleChangesDrawer={appUC.drawer.openShipmentChanges}
      toggleMaerskDrawer={appUC.drawer.openMaerskChanges}
      shipment={shipment}
      isMaersk={isMaersk}
      partnerWarning={partnerWarning}
      permissions={permissions}
      isRequestTheSame={isRequestTheSame}
    />
  );
};

export { ShipmentContainersContainer as ShipmentContainers };
