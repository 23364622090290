import styled from 'styled-components';

import { divFlex } from 'app-wrapper/view/themes/mixin';
import { emptyScrollbar } from 'app-wrapper/view/themes/components';

export const RFQAddAdditionalServices = styled.div.attrs({
  'data-class': 'RFQAddAdditionalServices',
})`
  ${divFlex}
  height: 100%;
  flex-direction: column;
`;

export const RFQAddAdditionalServicesWrap = styled.div.attrs({
  'data-class': 'RFQAddAdditionalServicesWrap',
})`
  ${divFlex}
  ${emptyScrollbar}
  flex-direction: column;
  align-items: center;

  height: calc(100% - 64px);
  width: 100%;
  background-color: ${({ theme }) => theme.themesColors.neutralBranding3};
`;

export const RFQAddAdditionalServicesContent = styled.div.attrs({
  'data-class': 'RFQAddAdditionalServicesContent',
})`
  ${divFlex}
  ${emptyScrollbar}
  flex-direction: column;
  overflow: auto;

  font-weight: 400;
  font-size: 12px;
  color: ${({ theme }) => theme.themesColors.neutralBranding10};

  padding: 16px;
  height: 100%;
  width: 100%;
  background-color: ${({ theme }) => theme.themesColors.neutralBranding1};
`;

export const RFQAddAdditionalServicesContentItem = styled.div.attrs({
  'data-class': 'RFQAddAdditionalServicesContentItem',
})`
  ${divFlex}
  ${emptyScrollbar}
  flex-direction: column;
  margin-bottom: 16px;
`;

export const RFQAddAdditionalServicesTitle = styled.div.attrs({
  'data-class': 'RFQAddAdditionalServicesTitle',
})`
  ${divFlex}
  justify-content: start;

  font-weight: 500;
  font-size: 16px;
  color: ${({ theme }) => theme.themesColors.neutralBranding10};
`;

export const RFQAddAdditionalServicesSectionTitle = styled.div.attrs({
  'data-class': 'RFQAddAdditionalServicesSectionTitle',
})`
  ${divFlex}
  justify-content: start;

  margin-top: 12px;
  font-weight: 400;
  font-size: 12px;
  color: ${({ theme }) => theme.themesColors.secondaryDot45};
`;

export const RFQAddAdditionalServicesFooter = styled.div.attrs({
  'data-class': 'RFQAddAdditionalServicesFooter',
})`
  ${divFlex}
  margin-right: auto;
`;

export const RFQAddAdditionalServicesFooterSum = styled.div.attrs({
  'data-class': 'RFQAddAdditionalServicesFooterSum',
})`
  ${divFlex}

  font-size: 14px;
  font-weight: 500;
  color: ${({ theme }) => theme?.themesColors?.neutralBranding10};
`;
