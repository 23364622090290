import { IsString, IsDefined } from 'class-validator';
import { BaseDTM } from 'proto/BaseDTM';

export interface ICountryDTM {
  code: string // just ISO-3166-1 alpha-2
  name: string // full name
}

export class CountryDTM extends BaseDTM<ICountryDTM> {
  @IsDefined()
  @IsString()
  code: string;

  @IsDefined()
  @IsString()
  name: string;
}
