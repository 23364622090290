import React, { ChangeEvent, FC, useCallback } from 'react';

import {
  CardVolumeField,
} from './CardVolumeField.styled';

interface ICardVolumeFieldComponentProps {
  cargoId: string
  value: string
  isErrorShowed: boolean
  disabled: boolean
  onChange: (value: string, cargoId: string) => void
  touchField: (cargoId: string) => void
}

export const CardVolumeFieldComponent: FC<ICardVolumeFieldComponentProps> = ({
  cargoId,
  value,
  isErrorShowed,
  disabled,
  onChange,
  touchField,
}) => {
  const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value, cargoId);
  }, []);

  const handleBlur = useCallback(() => {
    touchField(cargoId);
  }, []);

  return (
    <CardVolumeField
      type="number"
      value={value}
      disabled={disabled}
      error={isErrorShowed}
      onChange={handleChange}
      onBlur={handleBlur}
    />
  );
};
