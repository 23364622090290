import i18n from 'app-wrapper/i18n/i18n';

export const validationEmail = (email: string, option?: {
  maxValue?: number
}) => {
  const maxValue = option?.maxValue || 254;
  const value = email.slice(0, maxValue);
  const emailDomain = email.split('@')[1];
  let errorMessage = '';

  if (!/@/.test(value)) {
    errorMessage = i18n.t('basicErrors.INCLUDE_AT_EMAIL');
    return { errorMessage, email: value };
  }

  if (/[^A-Za-z0-9.@-]/.test(value)) {
    errorMessage = i18n.t('basicErrors.ONLY_TRUE_LETTERS');
    return { errorMessage, email: value };
  }

  if (/^([A-Za-z0-9.-]){63}@*/.test(value)) {
    errorMessage = i18n.t('basicErrors.EMAIL_TOO_LONG');
    return { errorMessage, email: value };
  }

  if (!/^([A-Za-z0-9.-]){0,63}@{1}\w+([.-]?\w)*(.[a-zA-Z]{2,3})+$/.test(value)) {
    errorMessage = i18n.t('basicErrors.EMAIL_DOMAIN_IS_MISSING');
    return { errorMessage, email: value };
  }

  if (!emailDomain.includes('.')) {
    errorMessage = i18n.t('basicErrors.EMAIL_DOMAIN_IS_MISSING');
    return { errorMessage, email: value };
  }

  if (email.length > maxValue) {
    errorMessage = i18n.t('basicErrors.MAX_LENGTH', { count: maxValue });
    return { errorMessage, email: value };
  }

  return { errorMessage, email: value };
};
