import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { UC } from 'shipment-operations/controllers';
import { R } from 'shipment-operations/repository';

import { CargoFormHeaderComponent } from './CargoFormHeader.component';

const CargoFormHeaderContainer: FC = () => {
  const {
    code,
    hsCodeValidationStatus,
    errors,
  } = useSelector(R.selectors.cargo.getCargo);
  const permissions = useSelector(R.selectors.shipment.getCargosPermissions);

  const isValidationPassed = !errors.hasErrors();
  const isHsCodeValidated = hsCodeValidationStatus === 'REQUEST_SENT_AND_VALID';

  return (
    <CargoFormHeaderComponent
      code={code}
      isError={!(isValidationPassed && isHsCodeValidated)}
      removeCargo={UC.cargo.removeCargo}
      cargosAvailability={permissions.cargosAvailability}
    />
  );
};

export {
  CargoFormHeaderContainer as CargoFormHeader,
};
