import styled from 'styled-components';

import { Button } from 'app-wrapper/view/components';

export const Section = styled.div`
  border-radius: 3px;
  width: 100%;
  min-height: 403px;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.themesColors.neutralBranding1};;
  border: 1px solid ${({ theme }) => theme.themesColors.neutralBranding4};;
`;

export const Header = styled.div`
  padding: 0 16px;
  margin-bottom: 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const HeaderTitle = styled.span`
  font-size: 12px;
  line-height: 20px;
  color: ${({ theme }) => theme.themesColors.secondaryDot45};
`;

export const MarkAllButton = styled(Button)`
  width: 68px;
  height: 24px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  min-height: 55px;
  margin: 0 16px;
  padding: 6.5px 0;
  border-top: 1px solid rgba(244, 245, 248, 1);
`;

export const ContainerType = styled.div`
  height: 16px;
  min-width: 26px;
  background: #73819B;
  color: white;
  box-sizing: border-box;
  border-right: 3px solid white;
  border-left: 3px solid white;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 3px solid #73819B;
  border-bottom: 3px solid #73819B;
  width: fit-content;
  padding: 0 3px;
  font-size: 10px;
  margin-right: 4px;
  font-weight: 700;
`;

export const ContainerName = styled.span`
  margin-left: 4px;
  font-size: 14px;
  line-height: 18px;
`;

export const MarkButton = styled(Button)`
  margin: auto 0 auto auto;
  width: fit-content;
  min-width: 141px;
  height: 32px;
`;

export const MarkedButton = styled(MarkButton)`
  border-color: rgba(217, 247, 190, 1) !important;
  color: rgba(56, 158, 13, 1) !important;

  svg {
    color: rgba(56, 158, 13, 1) !important;
  }
`;
