import {
  IsEnum, IsOptional,
} from 'class-validator';
import { EPermissionAttributePolicy } from 'monetary/constants';
import { BaseDTM } from 'proto/BaseDTM';
import { PermissionAttributePolicy } from 'shipment-operations/constants';

export type TCustomerOrganizationProfileDtm = {
  coverageAreas?: EPermissionAttributePolicy
  customerSalesAndMarkupPermissions?: EPermissionAttributePolicy
  salesRepresentativePermissions?: EPermissionAttributePolicy
}

export class CustomerOrganizationProfileDtm extends BaseDTM<TCustomerOrganizationProfileDtm> {
  @IsOptional()
  @IsEnum(PermissionAttributePolicy)
  coverageAreas?: PermissionAttributePolicy

  @IsOptional()
  @IsEnum(PermissionAttributePolicy)
  customerSalesAndMarkupPermissions?: PermissionAttributePolicy

  @IsOptional()
  @IsEnum(PermissionAttributePolicy)
  salesRepresentativePermissions?: PermissionAttributePolicy
}
