import { US_COUNTRY_CODE } from 'app-wrapper/constants';
import { createSelector } from 'reselect';

import { RootState } from 'app-wrapper/store';

import { EShippingPartyTypes, ShippingPartyRoutes } from 'shipment-operations/constants';
import { ShippingPartyDTM } from 'shipment-operations/models/dtm';
import {
  SHIPPING_PARTIES_HBL_TAB,
  SHIPPING_PARTIES_MBL_TAB,
  SHIPPING_PARTIES_OTHERS_TAB,
} from 'shipment-operations/constants/ShippingPartiesTab.const';

const localState = (state: RootState) => state.shippingParties;

const isShippingPartyFormChanged = (changedState: ShippingPartyDTM, currentState: ShippingPartyDTM) => (
  changedState.company?.id !== currentState.company?.id
  || (changedState.address?.id !== currentState.address?.id)
  || (changedState.contact?.id !== currentState.contact?.id)
  || JSON.stringify(changedState.references) !== JSON.stringify(currentState.references)
);

const getSelectedFormType = createSelector(
  localState,
  (state) => state.selectedForm,
);

const getSelectedShippingParty = createSelector(
  localState,
  (state) => state.changedFormState[state.selectedForm],
);

const getSelectedShippingPartyConsigneeAddress = createSelector(
  getSelectedShippingParty,
  (customer) => (customer && customer.address ? `${customer.company?.name ? `${customer.company?.name.trim()}, ` : ''}${customer.address.address1}, ${customer.address.city}, ${customer.address.state || ''} ${customer.address.postalCode}, ${customer.address.country || ''}` : ''),
);

const isSelectedShippingPartyHasSystemUpdatePolicy = createSelector(
  getSelectedShippingParty,
  (selectedShippingParty) => selectedShippingParty.hasSystemUpdatePolicy,
);

const getSelectedCurrentShippingParty = createSelector(
  localState,
  (state) => state.currentFormState[state.selectedForm],
);

const getShippingPartiesListLoadingState = createSelector(
  localState,
  (state) => state.isShippingPartyListLoading,
);

const getFormDataLoadingState = createSelector(
  localState,
  (state) => state.isFormDataLoading,
);

const getCompanyList = createSelector(
  localState,
  (state) => state.companyList,
);

const getAddressList = createSelector(
  localState,
  (state) => state.currentFormState[state.selectedForm].addressList,
);

const getContactList = createSelector(
  localState,
  (state) => state.currentFormState[state.selectedForm].contactList,
);

const getSelectedCompany = createSelector(
  getSelectedShippingParty,
  (selectedShippingParty) => selectedShippingParty.company,
);

const getSelectedCurrentCompany = createSelector(
  getSelectedCurrentShippingParty,
  (selectedCurrentShippingParty) => selectedCurrentShippingParty?.company,
);

const getSelectedAddress = createSelector(
  getSelectedShippingParty,
  (selectedShippingParty) => selectedShippingParty.address,
);

const getSelectedCurrentAddress = createSelector(
  getSelectedCurrentShippingParty,
  (selectedCurrentShippingParty) => selectedCurrentShippingParty.address,
);

const getSelectedContact = createSelector(
  getSelectedShippingParty,
  (selectedShippingParty) => selectedShippingParty.contact,
);

const getSelectedCurrentContact = createSelector(
  getSelectedCurrentShippingParty,
  (selectedCurrentShippingParty) => selectedCurrentShippingParty.contact,
);

const getIsForceCustomer = createSelector(
  localState,
  (state) => state.isForceCustomer,
);

const getShippingParties = createSelector(
  localState,
  (state) => state.changedFormState,
);

const getCurrentShippingParties = createSelector(
  localState,
  (state) => state.currentFormState,
);

const isSelectedFormChanged = createSelector(
  localState,
  (state) => {
    const eq = isShippingPartyFormChanged(state.changedFormState[state.selectedForm], state.currentFormState[state.selectedForm]);

    return eq;
  },
);

const isSelectedFormHasInitialState = createSelector(
  localState,
  (state) => (
    !state.changedFormState[state.selectedForm].address
    && !state.changedFormState[state.selectedForm].company
    && !state.changedFormState[state.selectedForm].contact
    && state.changedFormState[state.selectedForm].references.length === 1 // because we have virtual state for 1 ref any way
    && state.changedFormState[state.selectedForm].references[0].value === '' // but this ref will be empty by default, any changes makes it changed :)
  ),
);

const isFormsHaveChanges = createSelector(
  localState,
  (state) => (
    isShippingPartyFormChanged(state.changedFormState[EShippingPartyTypes.SHIPPER], state.currentFormState[EShippingPartyTypes.SHIPPER])
    || isShippingPartyFormChanged(state.changedFormState[EShippingPartyTypes.CONSIGNEE], state.currentFormState[EShippingPartyTypes.CONSIGNEE])
    || isShippingPartyFormChanged(state.changedFormState[EShippingPartyTypes.NOTIFY_PARTY], state.currentFormState[EShippingPartyTypes.NOTIFY_PARTY])
    || isShippingPartyFormChanged(state.changedFormState[EShippingPartyTypes.CUSTOMER], state.currentFormState[EShippingPartyTypes.CUSTOMER])
    || isShippingPartyFormChanged(state.changedFormState[EShippingPartyTypes.HOUSE_SHIPPER], state.currentFormState[EShippingPartyTypes.HOUSE_SHIPPER])
    || isShippingPartyFormChanged(state.changedFormState[EShippingPartyTypes.HOUSE_CONSIGNEE], state.currentFormState[EShippingPartyTypes.HOUSE_CONSIGNEE])
    || isShippingPartyFormChanged(state.changedFormState[EShippingPartyTypes.HOUSE_NOTIFY_PARTY], state.currentFormState[EShippingPartyTypes.HOUSE_NOTIFY_PARTY])
    || isShippingPartyFormChanged(state.changedFormState[EShippingPartyTypes.EXPORT_BROKER], state.currentFormState[EShippingPartyTypes.EXPORT_BROKER])
    || isShippingPartyFormChanged(state.changedFormState[EShippingPartyTypes.IMPORT_BROKER], state.currentFormState[EShippingPartyTypes.IMPORT_BROKER])
    || isShippingPartyFormChanged(state.changedFormState[EShippingPartyTypes.SECOND_NOTIFY_PARTY], state.currentFormState[EShippingPartyTypes.SECOND_NOTIFY_PARTY])
    || isShippingPartyFormChanged(state.changedFormState[EShippingPartyTypes.FORWARDER_AGENT], state.currentFormState[EShippingPartyTypes.FORWARDER_AGENT])
    || isShippingPartyFormChanged(state.changedFormState[EShippingPartyTypes.DELIVERY_AGENT], state.currentFormState[EShippingPartyTypes.DELIVERY_AGENT])
    || isShippingPartyFormChanged(state.changedFormState[EShippingPartyTypes.ACCOUNT_HOLDER], state.currentFormState[EShippingPartyTypes.ACCOUNT_HOLDER])
    || isShippingPartyFormChanged(state.changedFormState[EShippingPartyTypes.ULTIMATE_CUSTOMER], state.currentFormState[EShippingPartyTypes.ULTIMATE_CUSTOMER])
    || isShippingPartyFormChanged(state.changedFormState[EShippingPartyTypes.OCEAN_CARRIER], state.currentFormState[EShippingPartyTypes.OCEAN_CARRIER])
    || isShippingPartyFormChanged(state.changedFormState[EShippingPartyTypes.BOOKING_AGENT], state.currentFormState[EShippingPartyTypes.BOOKING_AGENT])
  ),
);

const getFormDraftState = createSelector(
  localState,
  (state) => ({
    [EShippingPartyTypes.CONSIGNEE]: isShippingPartyFormChanged(state.changedFormState[EShippingPartyTypes.CONSIGNEE], state.currentFormState[EShippingPartyTypes.CONSIGNEE]),
    [EShippingPartyTypes.CUSTOMER]: isShippingPartyFormChanged(state.changedFormState[EShippingPartyTypes.CUSTOMER], state.currentFormState[EShippingPartyTypes.CUSTOMER]),
    [EShippingPartyTypes.EXPORT_BROKER]: isShippingPartyFormChanged(state.changedFormState[EShippingPartyTypes.EXPORT_BROKER], state.currentFormState[EShippingPartyTypes.EXPORT_BROKER]),
    [EShippingPartyTypes.FORWARDER_AGENT]: isShippingPartyFormChanged(state.changedFormState[EShippingPartyTypes.FORWARDER_AGENT], state.currentFormState[EShippingPartyTypes.FORWARDER_AGENT]),
    [EShippingPartyTypes.HOUSE_CONSIGNEE]: isShippingPartyFormChanged(state.changedFormState[EShippingPartyTypes.HOUSE_CONSIGNEE], state.currentFormState[EShippingPartyTypes.HOUSE_CONSIGNEE]),
    [EShippingPartyTypes.HOUSE_NOTIFY_PARTY]: isShippingPartyFormChanged(state.changedFormState[EShippingPartyTypes.HOUSE_NOTIFY_PARTY], state.currentFormState[EShippingPartyTypes.HOUSE_NOTIFY_PARTY]),
    [EShippingPartyTypes.HOUSE_SHIPPER]: isShippingPartyFormChanged(state.changedFormState[EShippingPartyTypes.HOUSE_SHIPPER], state.currentFormState[EShippingPartyTypes.HOUSE_SHIPPER]),
    [EShippingPartyTypes.IMPORT_BROKER]: isShippingPartyFormChanged(state.changedFormState[EShippingPartyTypes.IMPORT_BROKER], state.currentFormState[EShippingPartyTypes.IMPORT_BROKER]),
    [EShippingPartyTypes.NOTIFY_PARTY]: isShippingPartyFormChanged(state.changedFormState[EShippingPartyTypes.NOTIFY_PARTY], state.currentFormState[EShippingPartyTypes.NOTIFY_PARTY]),
    [EShippingPartyTypes.SECOND_NOTIFY_PARTY]: isShippingPartyFormChanged(state.changedFormState[EShippingPartyTypes.SECOND_NOTIFY_PARTY], state.currentFormState[EShippingPartyTypes.SECOND_NOTIFY_PARTY]),
    [EShippingPartyTypes.SHIPPER]: isShippingPartyFormChanged(state.changedFormState[EShippingPartyTypes.SHIPPER], state.currentFormState[EShippingPartyTypes.SHIPPER]),
    [EShippingPartyTypes.FORWARDER_AGENT]: isShippingPartyFormChanged(state.changedFormState[EShippingPartyTypes.FORWARDER_AGENT], state.currentFormState[EShippingPartyTypes.FORWARDER_AGENT]),
    [EShippingPartyTypes.DELIVERY_AGENT]: isShippingPartyFormChanged(state.changedFormState[EShippingPartyTypes.DELIVERY_AGENT], state.currentFormState[EShippingPartyTypes.DELIVERY_AGENT]),
    [EShippingPartyTypes.ACCOUNT_HOLDER]: isShippingPartyFormChanged(state.changedFormState[EShippingPartyTypes.ACCOUNT_HOLDER], state.currentFormState[EShippingPartyTypes.ACCOUNT_HOLDER]),
    [EShippingPartyTypes.ULTIMATE_CUSTOMER]: isShippingPartyFormChanged(state.changedFormState[EShippingPartyTypes.ULTIMATE_CUSTOMER], state.currentFormState[EShippingPartyTypes.ULTIMATE_CUSTOMER]),
    [EShippingPartyTypes.OCEAN_CARRIER]: isShippingPartyFormChanged(state.changedFormState[EShippingPartyTypes.OCEAN_CARRIER], state.currentFormState[EShippingPartyTypes.OCEAN_CARRIER]),
    [EShippingPartyTypes.BOOKING_AGENT]: isShippingPartyFormChanged(state.changedFormState[EShippingPartyTypes.BOOKING_AGENT], state.currentFormState[EShippingPartyTypes.BOOKING_AGENT]),
    [EShippingPartyTypes.DESTINATION_PARTNER_AGENT]: isShippingPartyFormChanged(state.changedFormState[EShippingPartyTypes.DESTINATION_PARTNER_AGENT], state.currentFormState[EShippingPartyTypes.DESTINATION_PARTNER_AGENT]),
    [EShippingPartyTypes.ORIGIN_PARTNER_AGENT]: isShippingPartyFormChanged(state.changedFormState[EShippingPartyTypes.ORIGIN_PARTNER_AGENT], state.currentFormState[EShippingPartyTypes.ORIGIN_PARTNER_AGENT]),
  }),
);

const getErrorsFromForms = createSelector(
  localState,
  (state) => state.errorsFormState,
);

const getErrorsForCurrentForm = createSelector(
  localState,
  (state) => state.errorsFormState[state.selectedForm],
);

const getCompanyErrorFromSelectedForm = createSelector(
  localState,
  (state) => state.errorsFormState[state.selectedForm].company,
);

const getAddressErrorFromSelectedForm = createSelector(
  localState,
  (state) => state.errorsFormState[state.selectedForm].address,
);

const getContactErrorFromSelectedForm = createSelector(
  localState,
  (state) => state.errorsFormState[state.selectedForm].contact,
);

const getActiveTab = createSelector(
  localState,
  (state) => state.activeTab,
);

const getIsSelectCompanyDrawerOpened = createSelector(
  localState,
  (state) => state.isSelectCompanyDrawerOpened,
);

const getIsSelectCompanyDrawerLoading = createSelector(
  localState,
  (state) => state.isSelectCompanyDrawerLoading,
);

const getIsAbleToSaveSelectedCompany = createSelector(
  getSelectedAddress,
  getSelectedContact,
  isSelectedFormChanged,
  (selectedAddress, selectedContact, isFormChanged) => Boolean(isFormChanged && selectedAddress && selectedContact),
);

const getIsSelectCompanyDrawerInContactMode = createSelector(
  localState,
  (state) => state.isSelectCompanyDrawerInContactMode,
);

const getIsSelectCompanyDrawerInAddressMode = createSelector(
  localState,
  (state) => state.isSelectCompanyDrawerInAddressMode,
);

const getAddressLine1 = createSelector(
  localState,
  (state) => state.addressLine1,
);

const getAddressLine2 = createSelector(
  localState,
  (state) => state.addressLine2,
);

const getTaxId = createSelector(
  localState,
  (state) => state.taxId,
);

const getCity = createSelector(
  localState,
  (state) => state.city,
);

const getCountry = createSelector(
  localState,
  (state) => state.country,
);

const getCountryState = createSelector(
  localState,
  (state) => state.state,
);

const getPostalCode = createSelector(
  localState,
  (state) => state.postalCode,
);

const getFullName = createSelector(
  localState,
  (state) => state.fullName,
);

const getEmail = createSelector(
  localState,
  (state) => state.email,
);

const getPhone = createSelector(
  localState,
  (state) => state.phone,
);

const getAdditionalPhone = createSelector(
  localState,
  (state) => state.additionalPhone,
);

const getIsRequiredErrorVisible = createSelector(
  localState,
  (state) => state.isRequiredErrorVisible,
);

const getEmailError = createSelector(
  localState,
  (state) => state.emailError,
);

const getIsSelectedAddressInUS = createSelector(
  getSelectedAddress,
  (address) => Boolean(address && address.country === US_COUNTRY_CODE),
);

const getHasShippingPartyFieldsChanged = createSelector(
  localState,
  getSelectedCurrentCompany,
  getSelectedCurrentAddress,
  getSelectedCurrentContact,
  getSelectedShippingParty,
  getSelectedCurrentShippingParty,
  (
    state,
    company,
    address,
    contact,
    shippingParty,
    currentShippingParty,
  ) => {
    const {
      taxId,
      addressLine1,
      addressLine2,
      city,
      country,
      state: countryState,
      postalCode,
      fullName,
      email,
      phone,
      additionalPhone,
    } = state;
    let hasChanged = false;

    if (company && ((company.taxId || '') !== taxId)) {
      hasChanged = true;
    }

    if (address && (address.address1 !== addressLine1 || (address.address2 || '') !== addressLine2 || address.city !== city || address.country !== country || (address.state || '') !== countryState || address.postalCode !== postalCode)) {
      hasChanged = true;
    }

    if (contact && (contact.fullName !== fullName || contact.email !== email || contact.phone !== phone || (contact.phone2 || '') !== additionalPhone)) {
      hasChanged = true;
    }

    const currentReferencesJoined = currentShippingParty.references.map(({ value }) => value).join('');
    const referencesJoined = shippingParty.references.map(({ value }) => value).join('');

    if (currentReferencesJoined !== referencesJoined) {
      hasChanged = true;
    }

    return hasChanged;
  },
);

const getOwnCompaniesList = createSelector(
  localState,
  (state) => state.ownCompaniesList,
);

const getIsSelectedCompanyRelatesToUser = createSelector(
  getSelectedCompany,
  getOwnCompaniesList,
  (company, companyList) => Boolean(company && companyList.find(({ id }) => id === company?.id)),
);

const getShippingPartiesPermissions = createSelector(
  localState,
  (state) => state.shippingPartiesPermissions,
);

const getShippingPartyPermissionsByRole = (role: EShippingPartyTypes) => createSelector(
  getShippingPartiesPermissions,
  (permissions) => permissions[role],
);

const getTabParties = createSelector(
  getActiveTab,
  getShippingPartiesPermissions,
  (activeTab, permissions) => {
    const partiesForActiveTabWithPermissions: EShippingPartyTypes[] = [];
    let partiesForActiveTab: EShippingPartyTypes[] = [];

    if (activeTab === ShippingPartyRoutes.HOUSE_BILL_OF_LADING) {
      partiesForActiveTab = SHIPPING_PARTIES_HBL_TAB;
    } else if (activeTab === ShippingPartyRoutes.MASTER_BILL_OF_LADING) {
      partiesForActiveTab = SHIPPING_PARTIES_MBL_TAB;
    } else {
      partiesForActiveTab = SHIPPING_PARTIES_OTHERS_TAB;
    }

    partiesForActiveTab.forEach((role) => {
      const { isAbleToView } = permissions[role];

      if (isAbleToView) {
        partiesForActiveTabWithPermissions.push(role);
      }
    });

    return partiesForActiveTabWithPermissions;
  },
);

const getTradeType = createSelector(
  localState,
  (state) => state.tradeType,
);

const getIsLoading = createSelector(
  localState,
  (state) => state.isLoading,
);

export const shippingPartiesSelectors = {
  getAddressErrorFromSelectedForm,
  getAddressList,
  getCompanyErrorFromSelectedForm,
  getCompanyList,
  getContactErrorFromSelectedForm,
  getContactList,
  getCurrentShippingParties,
  getErrorsForCurrentForm,
  getErrorsFromForms,
  getFormDataLoadingState,
  getFormDraftState,
  getIsForceCustomer,
  getSelectedAddress,
  getSelectedCompany,
  getSelectedContact,
  getSelectedCurrentAddress,
  getSelectedCurrentCompany,
  getSelectedCurrentContact,
  getSelectedCurrentShippingParty,
  getSelectedFormType,
  getSelectedShippingParty,
  getSelectedShippingPartyConsigneeAddress,
  getShippingParties,
  getShippingPartiesListLoadingState,
  isFormsHaveChanges,
  isSelectedFormChanged,
  isSelectedFormHasInitialState,
  isSelectedShippingPartyHasSystemUpdatePolicy,
  getActiveTab,
  getTabParties,
  getIsSelectCompanyDrawerOpened,
  getIsSelectCompanyDrawerLoading,
  getIsAbleToSaveSelectedCompany,
  getIsSelectCompanyDrawerInContactMode,
  getIsSelectCompanyDrawerInAddressMode,
  getAddressLine1,
  getAddressLine2,
  getTaxId,
  getCity,
  getCountry,
  getCountryState,
  getPostalCode,
  getFullName,
  getPhone,
  getEmail,
  getAdditionalPhone,
  getIsRequiredErrorVisible,
  getEmailError,
  getIsSelectedAddressInUS,
  getHasShippingPartyFieldsChanged,
  getIsSelectedCompanyRelatesToUser,
  getOwnCompaniesList,
  getShippingPartyPermissionsByRole,
  getTradeType,
  getIsLoading,
};
