import React from 'react';
import { useSelector } from 'react-redux';

import { R } from 'monetary/repository';
import { UC } from 'monetary/controllers';

import { ServicesComponent } from './Services.component';

export type CheckedKeysValues = Key[] | {
  checked: Key[];
  halfChecked: Key[];
}

type Key = string | number;

const ServicesContainer = (() => {
  const getCurrent = useSelector(R.selectors.freightQuote.getFreightQuoteCurrent);
  const { form, status } = useSelector(R.selectors.freightQuote.getFreightQuoteCurrent);
  const getTreeDataAdditionalServices = useSelector(R.selectors.freightQuote.getTreeDataAdditionalServices);
  const getTreeDataAdditionalServicesDefaultExpandedKeys = useSelector(R.selectors.freightQuote.getTreeDataAdditionalServicesDefaultExpandedKeys);
  const getTreeDataAdditionalServicesDefaultKeys = useSelector(R.selectors.freightQuote.getTreeDataAdditionalServicesDefaultKeys);

  return (
    <ServicesComponent
      reloadData={UC.FreightQuote.getRateService}
      defaultCheckedKeys={form?.services?.valuesChecked}
      isReuseRequest={!!form?.services?.isReuseRequest}
      isAllDisabled={!!getCurrent.isAllFieldDisabled}
      onCheck={UC.FreightQuote.onCheckedAdditionalService}
      requestStatus={status}
      serviceStatus={form?.services?.status}
      treeData={getTreeDataAdditionalServices}
      defaultExpandedKeys={getTreeDataAdditionalServicesDefaultExpandedKeys}
      defaultKeys={getTreeDataAdditionalServicesDefaultKeys}
    />
  );
});

export { ServicesContainer as Services };
