import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ContainerDocumentDTM, AdditionalServiceDocumentActivityDTM } from 'shipment-operations/models/dtm';
import { DocumentType } from 'shipment-operations/constants';
import { DocumentsSection } from 'shipment-operations/view/pages/ShipmentAdditionalService/components/ServiceWorkflow/сomponents/RequiredActionsFlow/components/StepsForm/components/UploadDocuments/components';

import { Title, Content } from './ValidateCustomerDocuments.styled';

interface IValidateCustomerDocumentsComponentProps {
  documentActivities: AdditionalServiceDocumentActivityDTM[];
  openDocumentUploadDrawer: (type: DocumentType, document: ContainerDocumentDTM | null) => void;
  removeServiceDocument: (shipmentId: string, document: ContainerDocumentDTM) => void;
  downloadDocument: (shipmentId: string, documentId: number, documentName: string) => void;
}

export const ValidateCustomerDocumentsComponent: FC<IValidateCustomerDocumentsComponentProps> = ({
  documentActivities,
  openDocumentUploadDrawer,
  removeServiceDocument,
  downloadDocument,
}) => {
  const { t } = useTranslation();

  return (
    <Content>
      <Title>
        {t('Review documents provided by other parties')}
      </Title>

      {documentActivities.map((activity) => (
        <DocumentsSection
          templateURL={activity.templateUrl}
          key={activity.type}
          type={activity.code}
          documents={activity.providedDocuments}
          onDownload={downloadDocument}
          onRemove={removeServiceDocument}
          isOptional={!activity.isRequired}
          openDocumentUploadDrawer={() => openDocumentUploadDrawer(activity.code, activity.getProvidedDocument())}
        />
      ))}
    </Content>
  );
};
