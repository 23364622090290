import React from 'react';
import { observer } from 'mobx-react-lite';
import { useSelector } from 'react-redux';

import { UC } from 'app-wrapper/controllers';
import { useMobxStore } from 'app-wrapper/mobxStores';
import { R as userManagementR } from 'user-management/repository';

import { ContractsComponent } from './Contracts.component';

export const Contracts = observer(() => {
  const { contracts } = useMobxStore();
  const contractsPermissions = useSelector(userManagementR.selectors.moduleFunctionalityPermissions.getContractsPermissions);

  return (
    <ContractsComponent
      init={UC.contracts.init}
      isLoading={contracts.state.isLoading}
      contractsPermissions={contractsPermissions?.contractsPermission}
      openCreateContractDrawer={UC.contracts.openCreateContractDrawer}
    />
  );
});
