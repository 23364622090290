import React from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';

import { PDFHeaderComponent } from './PDFHeader.component';

const PDFHeaderContainer = () => {
  const companyName = useSelector(R.selectors.bookingConfirmationPDF.getHeaderCompanyName);
  const getHeaderSkypaceInformation = useSelector(R.selectors.bookingConfirmationPDF.getHeaderSkypaceInformation);

  return (
    <PDFHeaderComponent
      companyName={companyName}
      company={getHeaderSkypaceInformation}
    />
  );
};

export { PDFHeaderContainer as PDFHeader };
