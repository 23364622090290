import {
  IsBoolean,
  IsDefined, IsEnum, IsNumber, IsOptional, IsString, ValidateNested,
} from 'class-validator';
import { Type } from 'class-transformer';
import { BaseDTM } from 'proto/BaseDTM';

import { ShortChargeDTM, IShortChargeDTM } from 'shipment-operations/models/dtm';
import { InvoiceStatusesEnum } from 'shipment-operations/constants';

export interface IInvoicesChargeDTM {
  id: number
  charge: IShortChargeDTM
  costPerUnit: number
  numberOfUnits: number
  amount: number
  invoiced: number
}

export class InvoicesChargeDTM extends BaseDTM<IInvoicesChargeDTM> {
  @IsNumber()
  @IsDefined()
  id: number;

  @ValidateNested()
  @Type(() => ShortChargeDTM)
  charge: ShortChargeDTM

  @IsNumber()
  @IsDefined()
  costPerUnit: number;

  @IsNumber()
  @IsDefined()
  numberOfUnits: number;

  @IsNumber()
  @IsDefined()
  amount: number;

  @IsNumber()
  @IsDefined()
  invoiced: number;
}

export interface IInvoiceDataTypeDTM {
  key: number
  service: string
  measure: string
  cost: string
  quantity: number
  total: string
  adjustment: string
  invoiced: string
}

export class InvoiceDataTypeDTM extends BaseDTM<IInvoiceDataTypeDTM> {
  @IsNumber()
  @IsDefined()
  key: number;

  @IsString()
  @IsDefined()
  service: string;

  @IsString()
  @IsDefined()
  measure: string;

  @IsString()
  @IsDefined()
  cost: string;

  @IsNumber()
  @IsDefined()
  quantity: number;

  @IsString()
  @IsDefined()
  total: string;

  @IsString()
  @IsDefined()
  adjustment: string;

  @IsString()
  @IsOptional()
  invoiced: string;
}

interface IInvoiceItemDTM {
  id: number
}

export class InvoiceItem implements IInvoiceItemDTM {
  @IsNumber()
  @IsDefined()
  id: number;
}

export interface IUpdatedCreditNoteItemDTM {
  invoiceItem: IInvoiceItemDTM,
  costPerUnit: string
  numberOfUnits: number
  amount: string
}

export class UpdatedCreditNoteItemDTM extends BaseDTM<IUpdatedCreditNoteItemDTM> {
  @IsDefined()
  @ValidateNested()
  @Type(() => InvoiceItem)
  invoiceItem: InvoiceItem;

  @IsString()
  @IsDefined()
  costPerUnit: string;

  @IsNumber()
  @IsDefined()
  numberOfUnits: number;

  @IsString()
  @IsDefined()
  amount: string;
}

interface ISourceDTM {
  id?: number
  number?: string
  status?: InvoiceStatusesEnum
  createdBy?: string
  paid?: number
  balance?: number
  type: string
}

class SourceDTM extends BaseDTM<ISourceDTM> {
  @IsNumber()
  @IsOptional()
  id?: number;

  @IsString()
  @IsOptional()
  number?: string;

  @IsEnum(InvoiceStatusesEnum)
  @IsOptional()
  status?: InvoiceStatusesEnum;

  @IsString()
  @IsOptional()
  createdBy?: string;

  @IsNumber()
  @IsOptional()
  paid: number;

  @IsNumber()
  @IsOptional()
  balance: number;

  @IsString()
  @IsDefined()
  type: string;
}

interface IInvoiceTransactionsDTM {
  id?: number
  createdAt?: string
  createdBy?: string
  amount: number
  source?: ISourceDTM
}

class InvoiceTransactionsDTM extends BaseDTM<IInvoiceTransactionsDTM> {
  @IsNumber()
  @IsOptional()
  id?: number;

  @IsString()
  @IsOptional()
  createdAt?: string;

  @IsString()
  @IsOptional()
  createdBy?: string;

  @IsNumber()
  @IsDefined()
  amount: number;

  @ValidateNested()
  @Type(() => SourceDTM)
  source: SourceDTM
}

interface IInvoicesShipmentDTM {
  id: number
  organizationId: number
  shipmentName: string;
}

class InvoicesShipmentDTM extends BaseDTM<IInvoicesShipmentDTM> {
  @IsNumber()
  @IsDefined()
  id: number;

  @IsNumber()
  @IsDefined()
  organizationId: number;

  @IsDefined()
  @IsString()
  shipmentName: string;
}

interface IInvoiceCompanyDTM {
  id: number
  createdAt: string
  organizationId?: number
  name?: string
  phone?: string
  phone2?: string
  email?: string
  usci?: string
  taxId?: number
}

class InvoiceCompanyDTM extends BaseDTM<IInvoiceCompanyDTM> {
  @IsNumber()
  id: number;

  @IsString()
  createdAt: string;

  @IsNumber()
  @IsOptional()
  organizationId?: number;

  @IsString()
  @IsOptional()
  name?: string;

  @IsString()
  @IsOptional()
  phone?: string;

  @IsString()
  @IsOptional()
  phone2?: string;

  @IsString()
  @IsOptional()
  email?: string;

  @IsString()
  @IsOptional()
  usci?: string;

  @IsNumber()
  @IsOptional()
  taxId?: number;
}

export interface IShipmentBillingInvoiceDtm {
  id?: number
  additional?: boolean
  createdAt?: string
  createdBy?: string
  status: InvoiceStatusesEnum
  companyName?: string
  companyNameFrom?: string
  phoneFrom?: string
  emailFrom?: string
  reference?: string
  billedDate: string
  dueDate: string
  billed: number
  adjusted: number
  paid: number
  balance: number
  number: string
  charges: IInvoicesChargeDTM[]
  accountId: number
  accountIdFrom: number
  transactions?: IInvoiceTransactionsDTM[]
  shipment?: IInvoicesShipmentDTM
  billTo?: IInvoiceCompanyDTM
  billFrom?: IInvoiceCompanyDTM
}

export class ShipmentBillingInvoiceDtm extends BaseDTM<IShipmentBillingInvoiceDtm> {
  @IsOptional()
  @IsNumber()
  id?: number;

  @IsOptional()
  @IsBoolean()
  additional?: boolean

  @IsString()
  @IsOptional()
  createdAt?: string

  @IsString()
  @IsOptional()
  createdBy?: string

  @IsEnum(InvoiceStatusesEnum)
  @IsDefined()
  status: InvoiceStatusesEnum;

  @IsString()
  @IsOptional()
  companyName?: string;

  @IsOptional()
  @IsString()
  phoneFrom?: string;

  @IsOptional()
  @IsString()
  emailFrom?: string;

  @IsString()
  @IsOptional()
  companyNameFrom: string;

  @IsString()
  @IsOptional()
  reference?: string;

  @IsString()
  @IsDefined()
  billedDate: string;

  @IsString()
  @IsDefined()
  dueDate: string;

  @IsNumber()
  @IsDefined()
  billed: number;

  @IsNumber()
  @IsDefined()
  adjusted: number;

  @IsNumber()
  @IsDefined()
  paid: number;

  @IsNumber()
  @IsDefined()
  balance: number;

  @IsString()
  @IsDefined()
  number: string;

  @IsNumber()
  @IsDefined()
  accountId: number

  @IsNumber()
  @IsDefined()
  accountIdFrom: number

  @ValidateNested()
  @Type(() => InvoicesShipmentDTM)
  shipment?: InvoicesShipmentDTM

  @ValidateNested({ each: true })
  @Type(() => InvoicesChargeDTM)
  charges: InvoicesChargeDTM[]

  @ValidateNested({ each: true })
  @Type(() => InvoiceTransactionsDTM)
  transactions?: InvoiceTransactionsDTM[]

  @ValidateNested()
  @Type(() => InvoiceCompanyDTM)
  billTo?: InvoiceCompanyDTM

  @ValidateNested()
  @Type(() => InvoiceCompanyDTM)
  billFrom?: InvoiceCompanyDTM
}

export interface IGroupedInvoicesChargesDTM {
  [key: string]: InvoicesChargeDTM[]
}
