import React, { FC, ReactNode } from 'react';
import { CSSProperties } from 'styled-components';

import { Header } from '../index';

const Table = {
  width: '100%',
  borderSpacing: 0,
  breakBefore: 'page' as CSSProperties['breakBefore'],
};
const SideBorder = {
  marginLeft: '24px !important',
  marginRight: '24px !important',
};

interface PageProps {
  children: ReactNode
  header?: ReactNode
}

export const Page: FC<PageProps> = ({
  children,
  header,
}) => (
  <table style={Table}>
    <thead>
      <tr>
        <td>
          {header || <Header />}
        </td>
      </tr>
    </thead>
    <tbody>
      <tr style={{ margin: 'auto' }}>
        <td
          style={SideBorder}
        >
          {children}
        </td>
      </tr>
    </tbody>
    <tfoot>
      <tr>
        <td />
      </tr>
    </tfoot>
  </table>
);
