import React, { FC } from 'react';
import { ButtonProps } from 'antd/es/button';

import { Button, Icon } from './DownloadButton.styled';

interface IDownloadButtonProps extends Omit<ButtonProps, 'type'> {
  iconOnly?: boolean;
  link: string;
}

export const DownloadButton: FC<IDownloadButtonProps> = ({
  iconOnly,
  link,
  ...props
}) => (
  <Button
    href={link}
    target="_blank"
    icon={<Icon />}
    type="default"
    {...props}
    style={{
      ...(iconOnly ? {
        width: 24,
        height: 24,
      } : {}),
    }}
  >
    {!iconOnly ? props.children : null}
  </Button>
);
