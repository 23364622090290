import styled from 'styled-components';

import { SubPageContent } from 'app-wrapper/view/guideline';
import { ContentWrap } from 'user-management/view/pages/UserManagementProfile/UserManagementProfile.styled';

export const Content = styled(SubPageContent)`
  margin-top: 8px;
`;

export const DepartmentsWrap = styled(ContentWrap)`
  border-left: 0 !important;
  padding-left: 0 !important;
`;
