import moment from 'moment';

import { BaseController, controller } from 'proto/BaseController';
import { R } from 'shipment-operations/repository';
import { IGetShipmentPlansResponse } from 'shipment-operations/models/contracts';
import { ValidationErrorDTM, ValidationErrorType } from 'app-wrapper/types';
import {
  CargoBaseDTM,
  CargoDTM,
  CommodityDTM,
  ContainerDocumentDTM,
  ContainerDTM,
  ManualBookingTransportationErrorsDTM,
  RouteLegDTM,
  ShipmentPreviewDTM,
  ShipmentTrackerDTM,
  TemperatureControlDTM,
  TransportPlanDTM,
} from 'shipment-operations/models/dtm';
import {
  RFQServiceByIdContentRoutesTransportation,
} from 'monetary/models/dtm/Quotas';

@controller
export class ManualBookingWizardController extends BaseController {
  public getCargos = async (shipmentId: string) => {
    const hasTemperatureControl = R.selectors.manualBookingWizard.getHasTemperatureControl(this.store.getState());
    let cargos: CargoDTM[] = [];

    try {
      cargos = await R.services.cargo.getCargos(+shipmentId);
    } catch (e) {
      console.error('BookingWizardController ERROR: init - getCargos');
    }

    if (hasTemperatureControl) {
      cargos = cargos.map((cargo) => ({
        ...cargo,
        hsValidationStatus: 'REQUEST_SENT_AND_VALID',
      }));

      const defaultCargo = R.selectors.manualBookingWizard.getDefaultCargo(this.store.getState());
      const { code, name } = cargos[0];

      this.dispatch(R.actions.manualBookingWizard.setDefaultCargo(CargoBaseDTM.fromPlain({
        ...defaultCargo,
        code,
        name,
      })));
    }

    if (cargos.length) {
      this.dispatch(R.actions.manualBookingWizard.setCargos(cargos));
    }

    const cargosInStore = R.selectors.manualBookingWizard.getCargos(this.store.getState());
    const commodityPromises: Promise<CommodityDTM[]>[] = [];

    cargosInStore.forEach(({ code }) => {
      if (!code) {
        return;
      }

      try {
        commodityPromises.push(R.services.commodity.getCommodities(code));
      } catch (e) {
        console.error('GET COMMODITIES: CONTROLLER ERROR');
      }
    });

    const commoditySearchResults = await Promise.all(commodityPromises);

    const commodities = commoditySearchResults.map((commodityList, i) => (
      commodityList.find((commodity) => commodity.code === cargosInStore[i].code)!
    )).filter((v) => !!v);

    this.dispatch(R.actions.commodity.setCommodities(commodities));
  }

  public init = async (shipmentId: string) => {
    this.dispatch(R.actions.manualBookingWizard.setIsLoading(true));

    const shipment = R.selectors.shipment.getShipment(this.store.getState());

    if (shipment && shipment.carrierReferenceNumber) {
      this.dispatch(R.actions.manualBookingWizard.setCarrierReferenceNumber(shipment.carrierReferenceNumber));
      this.dispatch(R.actions.manualBookingWizard.setIsCarrierReferenceDisabled(true));
    }

    let containers: ContainerDTM[] = [];

    try {
      containers = await R.services.shipmentContainers.getContainersList(shipmentId);
    } catch (e) {
      console.error('CONTROLLER ERROR: init method');
    }

    const [container] = containers;

    if (!container) {
      this.dispatch(R.actions.manualBookingWizard.setIsDrawerOpened(false));

      return;
    }

    this.dispatch(R.actions.manualBookingWizard.setContainers(containers));

    let transportPlans: IGetShipmentPlansResponse[] = [];

    try {
      transportPlans = await R.services.shipmentPlans.getShipmentPlans(container.planId);
    } catch (e) {
      console.error('CONTROLLER ERROR: getShipmentPlans method');
    }

    const [transportPlan] = transportPlans;

    if (!transportPlan) {
      this.dispatch(R.actions.manualBookingWizard.setIsDrawerOpened(false));

      return;
    }

    let hasTemperatureControl = false;

    try {
      hasTemperatureControl = await R.services.temperatureControl.getTemperatureControl(+shipmentId);
    } catch (e) {
      console.error('ManualBookingWizard ERROR: init - getTemperatureControl');
    }

    this.dispatch(R.actions.manualBookingWizard.setHasTemperatureControl(hasTemperatureControl));

    let temperatureControl: TemperatureControlDTM | null = null;

    if (hasTemperatureControl) {
      try {
        temperatureControl = await R.services.temperatureControl.getTemperatureControlData(shipmentId);
      } catch (e) {
        console.error('ManualBookingWizard ERROR: init - getTemperatureControl');
      }
    }

    this.dispatch(R.actions.manualBookingWizard.setTemperatureControl(temperatureControl));

    await this.getCargos(shipmentId);

    const cargosInStore = R.selectors.manualBookingWizard.getCargos(this.store.getState());

    const hasAnyHazmats = cargosInStore.some(({ isHazmat }) => isHazmat);

    this.dispatch(R.actions.manualBookingWizard.setHasHazmats(hasAnyHazmats));

    this.dispatch(R.actions.manualBookingWizard.setRouteLegs(transportPlan.route.legs.map((leg) => RouteLegDTM.fromPlain(leg))));
    this.dispatch(R.actions.manualBookingWizard.setTransportationsErrors(transportPlan.transportations.map((transportation) => ManualBookingTransportationErrorsDTM.createEmpty(transportation.id))));
    this.dispatch(R.actions.manualBookingWizard.setTransportations(transportPlan.transportations.map((transportation) => RFQServiceByIdContentRoutesTransportation.fromPlain(transportation))));
    this.dispatch(R.actions.manualBookingWizard.setTransportPlan(TransportPlanDTM.fromPlain(transportPlan)));

    this.dispatch(R.actions.manualBookingWizard.setIsLoading(false));
  }

  public setVesselName = (vesselName: string, transportationId: number) => {
    const transportationToCopy = R.selectors.manualBookingWizard.getTransportationById(transportationId)(this.store.getState());
    const transportation = RFQServiceByIdContentRoutesTransportation.fromPlain({
      ...transportationToCopy,
      transport: {
        ...transportationToCopy.transport,
        name: vesselName,
      },
    });

    this.dispatch(R.actions.manualBookingWizard.setTransportationById(transportation));
  }

  public setVoyageNumber = (voyageNumber: string, transportationId: number) => {
    const transportationToCopy = R.selectors.manualBookingWizard.getTransportationById(transportationId)(this.store.getState());
    const transportation = RFQServiceByIdContentRoutesTransportation.fromPlain({
      ...transportationToCopy,
      transport: {
        ...transportationToCopy.transport,
        number: voyageNumber,
      },
    });

    this.dispatch(R.actions.manualBookingWizard.setTransportationById(transportation));
  }

  public setETDDate = (utcDate: string, transportationId: number) => {
    const transportationToCopy = R.selectors.manualBookingWizard.getTransportationById(transportationId)(this.store.getState());
    const transportation = RFQServiceByIdContentRoutesTransportation.fromPlain({
      ...transportationToCopy,
      schedule: {
        ...transportationToCopy.schedule,
        carrierDepartureTime: utcDate,
      },
    });

    this.dispatch(R.actions.manualBookingWizard.setTransportationById(transportation));

    this.validateTransportPlanDates();
  }

  public setETADate = (utcDate: string, transportationId: number) => {
    const transportationToCopy = R.selectors.manualBookingWizard.getTransportationById(transportationId)(this.store.getState());
    const transportation = RFQServiceByIdContentRoutesTransportation.fromPlain({
      ...transportationToCopy,
      schedule: {
        ...transportationToCopy.schedule,
        carrierArrivalTime: utcDate,
      },
    });

    this.dispatch(R.actions.manualBookingWizard.setTransportationById(transportation));

    this.validateTransportPlanDates();
  }

  public validateTransportPlanDates = () => {
    const transportations = R.selectors.manualBookingWizard.getTransportations(this.store.getState());

    this.dispatch(R.actions.manualBookingWizard.clearTransportationsErrors());

    transportations.forEach((transportation, index) => {
      const { carrierArrivalTime, carrierDepartureTime } = transportation.schedule;

      if (index === 0) {
        if (moment(carrierDepartureTime).isAfter(carrierArrivalTime)) {
          this.dispatch(R.actions.manualBookingWizard.setTransportationETDErrorById({
            transportationId: transportation.id,
            error: this.createDateError(),
          }));
        }
      } else if (index === transportations.length - 1) {
        const prevTSSchedule = transportations[index - 1].schedule;

        if (moment(prevTSSchedule.carrierArrivalTime).isAfter(carrierDepartureTime)) {
          this.dispatch(R.actions.manualBookingWizard.setTransportationETAErrorById({
            transportationId: transportations[index - 1].id,
            error: this.createDateError(),
          }));
        }

        if (moment(carrierDepartureTime).isAfter(carrierArrivalTime)) {
          this.dispatch(R.actions.manualBookingWizard.setTransportationETDErrorById({
            transportationId: transportation.id,
            error: this.createDateError(),
          }));
        }
      } else {
        const prevTSSchedule = transportations[index - 1].schedule;

        if (moment(prevTSSchedule.carrierArrivalTime).isAfter(carrierDepartureTime)) {
          this.dispatch(R.actions.manualBookingWizard.setTransportationETAErrorById({
            transportationId: transportations[index - 1].id,
            error: this.createDateError(),
          }));
        }

        if (moment(carrierDepartureTime).isAfter(carrierArrivalTime)) {
          this.dispatch(R.actions.manualBookingWizard.setTransportationETDErrorById({
            transportationId: transportation.id,
            error: this.createDateError(),
          }));
        }
      }
    });
  }

  public downloadDocument = (shipmentId: string) => {
    const confirmationDocument = R.selectors.manualBookingWizard.getConfirmationDocument(this.store.getState());

    if (!confirmationDocument) {
      return;
    }

    try {
      R.services.shipmentDocument.getShipmentDocument(+shipmentId, confirmationDocument.response.id, confirmationDocument.response.name);
    } catch (e) {
      console.error('PREVIEW SHIPMENT DOCUMENT: CONTROLLER ERROR');
    }
  }

  public setConfirmationDocument = (document: ContainerDocumentDTM | null) => {
    this.dispatch(R.actions.manualBookingWizard.setBookingConfirmationDocument(document));
    this.dispatch(R.actions.manualBookingWizard.setBookingConfirmationError(document ? undefined : this.createRequiredError()));
  }

  public setCarrierReferenceNumber = (referenceNumber: string) => {
    this.dispatch(R.actions.manualBookingWizard.setCarrierReferenceNumber(referenceNumber));

    this.dispatch(R.actions.manualBookingWizard.setCarrierReferenceNumberError(!referenceNumber ? this.createRequiredError() : undefined));
  };

  public createDateError = () => ValidationErrorDTM.fromPlain({
    type: ValidationErrorType.DEFAULT,
    message: '',
  });

  public createRequiredError = () => ValidationErrorDTM.fromPlain({
    type: ValidationErrorType.DEFAULT,
    message: '',
  });

  public openWizard = () => {
    this.dispatch(R.actions.manualBookingWizard.setIsDrawerOpened(true));
  }

  public closeWizard = () => {
    this.dispatch(R.actions.manualBookingWizard.setIsDrawerOpened(false));
    this.dispatch(R.actions.manualBookingWizard.reset());
  }

  public openHazmatSectionByCargoId = (cargoId: number) => {
    this.dispatch(R.actions.manualBookingWizard.addToggledHazmatCargoId(cargoId));
  }

  public closeHazmatSectionByCargoId = (cargoId: number) => {
    this.dispatch(R.actions.manualBookingWizard.removeToggledHazmatCargoId(cargoId));
  }

  public validateRequiredData = () => {
    const confirmationDocument = R.selectors.manualBookingWizard.getConfirmationDocument(this.store.getState());
    const carrierReferenceNumber = R.selectors.manualBookingWizard.getCarrierReferenceNumber(this.store.getState());

    this.validateTransportPlanDates();

    this.dispatch(R.actions.manualBookingWizard.setBookingConfirmationError(confirmationDocument ? undefined : this.createRequiredError()));
    this.dispatch(R.actions.manualBookingWizard.setCarrierReferenceNumberError(!carrierReferenceNumber ? this.createRequiredError() : undefined));
  }

  public submitBookingConfirmation = async (shipmentId: string) => {
    this.validateRequiredData();

    const isValid = R.selectors.manualBookingWizard.getIsManualBookingWizardValid(this.store.getState());

    if (!isValid) {
      return;
    }

    this.dispatch(R.actions.manualBookingWizard.setResponseErrorMessage(''));
    this.dispatch(R.actions.manualBookingWizard.setIsLoading(true));

    const transportPlan = R.selectors.manualBookingWizard.getTransportPlanWithEditedTransportations(this.store.getState());
    const carrierReferenceNumber = R.selectors.manualBookingWizard.getCarrierReferenceNumber(this.store.getState());
    const confirmationDocument = R.selectors.manualBookingWizard.getConfirmationDocument(this.store.getState()) as ContainerDocumentDTM;

    try {
      await R.services.inttra.manualConfirmBooking(shipmentId, transportPlan, carrierReferenceNumber, confirmationDocument?.response);
    } catch (e) {
      const error = e as Error;

      this.dispatch(R.actions.manualBookingWizard.setIsLoading(false));
      this.dispatch(R.actions.manualBookingWizard.setResponseErrorMessage(error.message));
      return;
    }

    await this.updateOverviewInformation(shipmentId);

    this.dispatch(R.actions.manualBookingWizard.reset());
    this.dispatch(R.actions.overview.setWasManualBookingConfirmationSuccessful(true));
    this.dispatch(R.actions.manualBookingWizard.setIsDrawerOpened(false));
    this.dispatch(R.actions.manualBookingWizard.setIsLoading(false));
  }

  public updateOverviewInformation = async (shipmentId: string) => {
    let shipment: ShipmentPreviewDTM | null = null;

    try {
      shipment = await R.services.shipment.getShipmentShortById(+shipmentId);
    } catch (e) {
      console.error('OverviewController.loadData: error');
    }

    if (shipment) {
      this.dispatch(R.actions.rollShipmentWizard.setShipment(shipment));
      this.dispatch(R.actions.shipment.setShipment(shipment));
    }

    let containers: ShipmentTrackerDTM[];

    try {
      containers = await R.services.shipmentTracker.getContainers(shipmentId);
    } catch {
      console.error('RollShipmentWizardController: updateOverviewInformation');

      return;
    }

    this.dispatch(R.actions.shipmentTracker.setContainers(containers));
    this.dispatch(R.actions.shipmentTrackerRoutes.setContainers(containers));

    if (!containers.length) {
      return;
    }

    const [container] = containers;
    const { planId } = container;

    let plans;

    try {
      plans = await R.services.shipmentTracker.getSchedules(planId);
    } catch {
      console.error('RollShipmentWizardController: updateOverviewInformation');
    }

    this.dispatch(R.actions.shipmentTrackerRoutes.setSchedules(plans));
  };
}
