import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'app-wrapper/createSlice';

import { TNewOrganizationDocumentsState } from 'user-management/models/states';
import { DocumentDTM, ContainerDocumentDTM } from 'shipment-operations/models/dtm';
import { DocumentType } from 'shipment-operations/constants';
import { CustomerDocumentsMap } from 'user-management/models/dtm';
import { EOrganizationType } from 'user-management/constants';

export const initialState: TNewOrganizationDocumentsState = {
  isLoading: false,
  isUploadLoading: false,
  organizationId: 0,
  isOrganizationAdmin: false,
  isRequiredWarningShown: false,
  documents: [],
  documentsTypeMap: {
    [DocumentType.ALO]: [],
    [DocumentType.CCI]: [],
    [DocumentType.BLIC]: [],
    [DocumentType.TCER]: [],
    [DocumentType.MISC]: [],
  },
  documentToUpload: null,
  documentsToRemoveIds: [],
};

export const newOrganizationDocumentsStore = createSlice({
  name: 'newOrganizationDocuments',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setIsUploadLoading: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      isUploadLoading: payload,
    }),
    setIsOrganizationAdmin: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      isOrganizationAdmin: payload,
    }),
    setOrganizationType: (state, { payload }: PayloadAction<EOrganizationType | undefined>) => ({
      ...state,
      organizationType: payload,
    }),
    setOrganizationId: (state, { payload }) => ({
      ...state,
      organizationId: payload,
    }),
    setIsRequiredWarningShown: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      isRequiredWarningShown: payload,
    }),
    setDocuments: (state, { payload }: PayloadAction<DocumentDTM[]>) => ({
      ...state,
      documents: payload,
    }),
    setDocumentsTypeMap: (state, { payload }: PayloadAction<CustomerDocumentsMap>) => ({
      ...state,
      documentsTypeMap: payload,
    }),
    setDocumentTypeMapDocuments: (state, { payload }: PayloadAction<{ type: DocumentType, documents: DocumentDTM[] }>) => {
      const { documentsTypeMap } = state;
      documentsTypeMap[payload.type] = [...payload.documents];

      return {
        ...state,
        documentsTypeMap,
      };
    },
    setDocumentToUpload: (state, { payload }: PayloadAction<ContainerDocumentDTM | null>) => ({
      ...state,
      documentToUpload: payload,
    }),
    setDocumentToUploadType: (state, { payload }: PayloadAction<DocumentType>) => ({
      ...state,
      documentToUploadType: payload,
    }),
    clearDocumentUploadFormData: (state) => ({
      ...state,
      documentToUpload: null,
      documentToUploadType: undefined,
    }),
    addDocumentToRemoveId: (state, { payload }: PayloadAction<number>) => ({
      ...state,
      documentsToRemoveIds: [
        ...state.documentsToRemoveIds,
        payload,
      ],
    }),
    setDocumentsToRemoveIds: (state, { payload }: PayloadAction<number[]>) => ({
      ...state,
      documentsToRemoveIds: payload,
    }),
    clearDocumentsToRemoveIds: (state) => ({
      ...state,
      documentsToRemoveIds: [],
    }),
    clear: () => (initialState),
  },
});

export const newOrganizationDocumentsActions = newOrganizationDocumentsStore.actions;
export const newOrganizationDocumentsReducer = newOrganizationDocumentsStore.reducer;
