import styled from 'styled-components';
import AntLayout from 'antd/es/layout';

export const ShipmentListContent = styled(AntLayout.Content).attrs({
  'data-class': 'ShipmentListContent',
})`
  display: flex;
  flex-direction: row;
  height: calc(100% - 56px - 46px - 20px);

  max-width: 1660px;

  margin: 0 auto;
  padding: 0;

  background-color: inherit;
`;
