import styled from 'styled-components';

import { divFlex } from 'app-wrapper/view/themes/mixin';
import { Button, Input } from 'app-wrapper/view/components';

export const Wrapper = styled.div.attrs({
  'data-class': 'ModalShipmentReferenceUpdateComponent_Wrapper',
})`
  ${divFlex}
  position: absolute;

  z-index: 11;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  background-color: white;
  box-shadow: 0 9px 28px 8px rgba(0, 0, 0, 0.05), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12);

  div[data-testid="modalHeader"],
  div[data-testid="modalFooter"] {
    display: none;
  }

  div[data-class="Modal_Dialog"] {
    width: 416px;
    height: 200px;
  }
`;

export const ModalRateContent = styled.div.attrs({
  'data-class': 'ModalShipmentReferenceUpdateComponent_ModalRateContent',
})`
  ${divFlex}
  flex-direction: column;
  width: 100%;

  padding: 32px 32px 0 32px;
`;

export const ModalRateContentInput = styled(Input).attrs((props: {
  isError: boolean
  'data-class'?: string
}) => ({
  isError: props.isError,
  'data-class': props?.['data-class'] || 'ModalShipmentReferenceUpdateComponent_ModalRateContent',
}))`
  ${({ isError, theme }) => isError && `border-color: ${theme.themesColors.characterBrandingDanger} !important;`};
  ${({ isError }) => isError && 'z-index: 2;'};
`;

export const ModalRateHeader = styled.div.attrs({
  'data-class': 'ModalShipmentReferenceUpdateComponent_ModalRateHeader',
})`
  ${divFlex}

  color: ${({ theme }) => theme?.themesColors?.neutralBranding10};
  font-size: 16px;
  font-weight: 500;
`;

export const ModalRateTextInfoWrap = styled.div.attrs({
  'data-class': 'ModalShipmentReferenceUpdateComponent_ModalRateTextInfoWrap',
})`
  ${divFlex}

  margin-top: 8px;
  border: 1px solid ${({ theme }) => theme?.themesColors?.primaryBranding3};
  background-color: ${({ theme }) => theme?.themesColors?.primaryBranding1};
  border-radius: 3px;
  padding: 9px 16px;
`;

export const ModalRateTextInfoIcon = styled.div.attrs({
  'data-class': 'ModalShipmentReferenceUpdateComponent_ModalRateTextInfoIcon',
})`
  ${divFlex}
  justify-content: center;
  align-items: center;
`;

export const ModalRateTextInfo = styled.div.attrs({
  'data-class': 'ModalShipmentReferenceUpdateComponent_ModalRateTextInfo',
})`
  ${divFlex}

  color: ${({ theme }) => theme?.themesColors?.neutralBranding10};
  font-size: 14px;
  font-weight: 400;
  margin-left: 10px;
`;

export const ModalShipmentReferenceUpdate = styled.div.attrs({
  'data-class': 'ModalShipmentReferenceUpdateComponent_ModalShipmentReferenceUpdate',
})`
  ${divFlex}
  flex-direction: column;
  margin-top: 8px;
`;

export const ModalShipmentReferenceUpdateTitle = styled.div.attrs({
  'data-class': 'ModalShipmentReferenceUpdateComponent_ModalShipmentReferenceUpdateTitle',
})`
  ${divFlex}

  color: ${({ theme }) => theme?.themesColors?.neutralBranding10};
  font-size: 12px;
  font-weight: 400;
`;

export const ModalShipmentReferenceUpdateSelect = styled.div.attrs({
  'data-class': 'ModalShipmentReferenceUpdateComponent_ModalShipmentReferenceUpdateSelect',
})`
  ${divFlex}

  color: ${({ theme }) => theme?.themesColors?.neutralBranding10};
  font-size: 12px;
  font-weight: 400;
  margin-top: 4px;
`;

export const ModalRateFooter = styled.div.attrs({
  'data-class': 'ModalShipmentReferenceUpdateComponent_ModalRateFooter',
})`
  ${divFlex}

  color: ${({ theme }) => theme?.themesColors?.neutralBranding10};
  font-size: 12px;
  font-weight: 400;
  margin-top: 24px;
  justify-content: end;
`;

export const ModalRateCancelBtn = styled(Button).attrs({
  'data-class': 'ModalShipmentReferenceUpdateComponent_ModalRateCancelBtn',
})`
  ${divFlex}
  align-items: center;
  justify-content: center;

  background-color: ${({ theme }) => theme.themesColors.neutralBranding1};
  height: 32px;
  border: 1px solid ${({ theme }) => theme.themesColors.neutralBranding5};
  border-radius: 3px;
  text-transform: capitalize;

  span {
    color: ${({ theme }) => theme.themesColors.characterBrandingTitle85};
    font-size: 14px;
    font-weight: 400;
    text-shadow: none;
  }
`;

export const ModalRateSaveBtn = styled(Button).attrs({
  'data-class': 'ModalShipmentReferenceUpdateComponent_ModalRateSaveBtn',
})`
  ${divFlex}
  align-items: center;
  justify-content: center;

  background-color: ${({ theme }) => theme.themesColors.neutralBranding8};
  height: 32px;
  border: 1px solid ${({ theme }) => theme.themesColors.neutral5};
  border-radius: 3px;
  text-transform: capitalize;
  margin-left: 8px;

  span {
    color: ${({ theme }) => theme.themesColors.neutralBranding1};
    font-size: 14px;
    font-weight: 400;
    text-shadow: none;
  }
`;
