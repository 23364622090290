import styled from 'styled-components';

import { Input } from 'app-wrapper/view/components';

export const TemperatureFieldWrapper = styled.div.attrs({
  'data-class': 'TemperatureFieldWrapper',
})`
  box-sizing: border-box;
  margin-left: 0;
  height: 50px;
  
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
`;

export const TemperatureLabel = styled.span.attrs({
  'data-class': 'TemperatureLabel',
})`  
  margin-right: 8px;
`;

export const TemperatureInput = styled(Input).attrs({
  'data-class': 'TemperatureInput',
})`  
  width: 70px;
  height: 32px;
  min-height: 32px;
  
  &.ant-input {
    font-size: 12px;
  }
`;
