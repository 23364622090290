import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';
import { UC } from 'shipment-operations/controllers';

import { PrintInvoiceComponent } from './PrintInvoice.component';

interface ShareInvoiceContainerProps {
  invoiceId?: number;
  shipmentId: string;
  isAPInvoiceType: boolean;
}

const PrintInvoiceContainer: FC<ShareInvoiceContainerProps> = ({ invoiceId, shipmentId, isAPInvoiceType }) => {
  const isLoading = useSelector(R.selectors.shareInvoice.getIsLoading);
  const isLoadingFinished = useSelector(R.selectors.shareInvoice.getIsLoadingFinished);
  const shipmentInvoiceFileNamePrint = useSelector(R.selectors.shareInvoice.getShipmentInvoiceFileNamePrint);
  const fileName = `${shipmentInvoiceFileNamePrint}${invoiceId || ''}`;

  return (
    <PrintInvoiceComponent
      shipmentId={shipmentId}
      fileNamePrint={fileName}
      invoiceId={invoiceId}
      isLoading={isLoading}
      isLoaded={isLoadingFinished}
      fetchData={UC.shareInvoice.fetchData}
      isAPInvoiceType={isAPInvoiceType}
    />
  );
};

export { PrintInvoiceContainer as PrintInvoice };
