import {
  IsEnum,
  IsOptional,
} from 'class-validator';
import { EPermissionAttributePolicy } from 'monetary/constants';

import { PermissionAttributePolicy } from 'shipment-operations/constants';
import { BaseDTM } from 'proto/BaseDTM';

export type TShipmentChargesAvailabilityDTM = {
  shipmentChargesAvailability?: EPermissionAttributePolicy
  editPayablesPayment?: PermissionAttributePolicy
}

export class ShipmentChargesAvailabilityDTM extends BaseDTM<TShipmentChargesAvailabilityDTM> {
  @IsOptional()
  @IsEnum(PermissionAttributePolicy)
  shipmentChargesAvailability?: PermissionAttributePolicy

  @IsOptional()
  @IsEnum(PermissionAttributePolicy)
  editPayablesPayment?: PermissionAttributePolicy
}
