import { useController, UseControllerProps } from 'react-hook-form';
import { useMemo } from 'react';
import omit from 'lodash/fp/omit';
import { UseControllerReturn } from 'react-hook-form/dist/types';

export type ControllerPropsI<ComponentProps, ControllerProps> = ComponentProps &
  {controllerProps: UseControllerProps<ControllerProps>};

export const useControllerProps = <ComponentProps, ControllerProps>(
  props: ControllerPropsI<ComponentProps, ControllerProps>): [
  Pick<ControllerPropsI<ComponentProps, ControllerProps>, Exclude<keyof ComponentProps, 'controllerProps'>>,
  UseControllerReturn<ControllerProps>, UseControllerProps<ControllerProps>] => {
  const componentProps = useMemo(() => omit('controllerProps', props), [props]);
  const controllerProps = useMemo(() => props.controllerProps, [props]);
  const controller = useController(controllerProps);

  return [componentProps, controller, controllerProps];
};
