import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { MBLViewChargeDTM } from 'shipment-operations/models/dtm';

import {
  Table,
  TableCell,
  Title,
  TableNameCell,
  Wrapper,
} from './MBLChargesTable.styled';

interface MBLChargesTableComponentProps {
  chargesItems: MBLViewChargeDTM[];
}

export const MBLChargesTableComponent: FC<MBLChargesTableComponentProps> = ({ chargesItems }) => {
  const { t } = useTranslation();

  const columns = useMemo(() => [
    {
      title: t('Charge'),
      width: '20%',
      key: 'charge',
      align: 'left' as 'left',
      render: (item: string, record: {} | MBLViewChargeDTM) => {
        const charge = record as MBLViewChargeDTM;

        return (
          <TableNameCell>
            {charge.name}
          </TableNameCell>
        );
      },
    },
    {
      title: t('Payment Term'),
      width: '20%',
      key: 'type',
      align: 'left' as 'left',
      render: (item: string, record: {} | MBLViewChargeDTM) => {
        const charge = record as MBLViewChargeDTM;

        return (
          <TableCell>
            {charge.type}
          </TableCell>
        );
      },
    },
    {
      title: t('Currency'),
      width: '20%',
      key: 'currency',
      align: 'left' as 'left',
      render: (item: string, record: {} | MBLViewChargeDTM) => {
        const charge = record as MBLViewChargeDTM;

        return (
          <TableCell>
            {charge.currency}
          </TableCell>
        );
      },
    },
    {
      title: t('Total'),
      width: '20%',
      key: 'total',
      align: 'right' as 'right',
      render: (item: string, record: {} | MBLViewChargeDTM) => {
        const charge = record as MBLViewChargeDTM;

        return (
          <TableCell>
            {charge.price}
          </TableCell>
        );
      },
    },
  ], [t]);

  return (
    <Wrapper>
      <Title>
        {t('Charges')}
      </Title>

      <Table
        pagination={false}
        dataSource={chargesItems}
        columns={columns}
      />
    </Wrapper>
  );
};
