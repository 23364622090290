import styled from 'styled-components';
import Typography from 'antd/es/typography';

export const Section = styled.div`
  margin-top: 8px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  padding: 16px;
  border-radius: 3px;
  background-color: white;
`;

export const SectionCenteredTitle = styled(Section)`
  align-items: center;
`;

export const Title = styled(Typography)`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
`;
