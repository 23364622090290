import { CustomScrollbar } from 'app-wrapper/view/styles/mixins';
import styled from 'styled-components';
import Menu from 'antd/es/menu';
import BankFilled from '@ant-design/icons/BankFilled';
import { LockOutlined } from '@ant-design/icons';

import { ContactBookSvg, ContactCustomersSvg } from 'app-wrapper/view/icons';
import { divFlex } from 'app-wrapper/view/themes/mixin';

export const MainMenuContactBookSvg = styled(ContactBookSvg).attrs({
  'data-class': 'MainMenuContactBookSvg',
})``;

export const MainMenuCustomersSvg = styled(ContactCustomersSvg).attrs({
  'data-class': 'MainMenuCustomersSvg',
})`
  margin-left: -3px;
`;

export const MainMenuWrapper = styled.div.attrs({
  'data-class': 'MainMenuWrapper',
})`
  display: flex;
  height: calc(100% - 48px - 40px);
  ${CustomScrollbar}

  .ant-menu {
    border: none;
    background-color: transparent;
    padding-right: 8px;

    svg {
      color: #73819b;
    }
  }

  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: unset;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;

    .ant-menu-title-content {
      background-color: #f9f9fb;
      box-shadow: inset 3px 0 0 #7b61ff;
      color: #73819b;
    }
  }
`;

export const MainItemSubMenu = styled(Menu.SubMenu).attrs({
  'data-class': 'MainItemSubMenu',
})`
  padding-right: 0px;

  .ant-menu {
    background-color: transparent;
  }

  &.ant-menu-item-disabled a, .ant-menu-submenu-disabled a  {
    ${(props) => (props.disabled ? 'pointer-events: none' : '')};
    color: ${({ theme }) => theme.themesColors.neutralBranding10} !important;
  }

  &.ant-menu-item {
    padding-left: 16px !important;
    padding-right: 0;
    margin-top: 0;
    margin-bottom: 8px;
  }

  .ant-menu-item {
    padding-right: 0;
    margin-top: 0;
    margin-bottom: 8px;
  }

  &.ant-menu-submenu {
    .ant-menu-submenu-title {
      padding-left: 16px !important;
      margin-top: 0px !important;
      margin-bottom: 0px !important;
    }
  }

  &.main-item-sub-menu > .ant-menu {
    padding: 0 0 0 30px;
  }

  &.ant-menu-submenu-selected,
  &.ant-menu-item-selected a {
    color: #73819b;
  }

  &.ant-menu-item-selected {
    .ant-menu-title-content {
      padding-left: 14px;
    }
  }

  &.ant-menu-submenu-selected,
  &.ant-menu-item-selected {
    svg {
      color: #6c79de;
    }
  }

  .ant-menu-title-content {
    margin-left: 18px;
    padding-left: 14px;
    z-index: 10;
  }

  &.ant-menu-submenu {
    .ant-menu-title-content {
      padding-left: 16px;
      margin-left: 16px;
      z-index: 10;
    }
    .ant-menu-item {
      .ant-menu-title-content {
        margin-left: 0;
        border-top-right-radius: 10%;
        border-bottom-right-radius: 10%;
      }
    }
    .ant-menu-item-selected::after {
      content: none;
    }
  }

  &::after {
    content: none;
  }

  .ant-menu-submenu-arrow {
    color: ${({ theme }) => theme.themesColors.neutralBranding7};
    &::after,
    &::before {
      height: 2px;
      width: 6px;
    }
    &::after {
      transform: rotate(45deg) translateX(-2px);
    }
    &::before {
      transform: rotate(-45deg) translateX(2px);
    }
  }
`;

export const SubSubMenu = styled(MainItemSubMenu).attrs({
  'data-class': 'SubSubMenu',
})`
  &.sub-menu-collapsed {
    padding-left: 0px;

    .ant-menu-title-content {
      padding-left: 0;
      margin-left: 0;
    }
    &.ant-menu-submenu-active {
      .ant-menu-submenu-arrow {
        ::before {
          transform: rotate(-135deg) translateX(2px);
        }
        ::after {
          transform: rotate(-45deg) translateX(-2px);
        }
      }
    }
  }

  &.sub-menu-not-collapsed {
    padding-left: 13px;
    .ant-menu-title-content {
      padding-left: 3px;
      margin-left: 0;
    }
  }
  
  .ant-menu-inline.ant-menu-root .ant-menu-item > .ant-menu-title-content, .ant-menu-inline.ant-menu-root .ant-menu-submenu-title > .ant-menu-title-content {
    padding-left: 45px;
  }
`;

export const SubSubMenuItem = styled(Menu.Item).attrs({
  'data-class': 'SubSubMenuItem',
})`
  &.sub-menu-not-collapsed {
    padding-left: 31px !important;
    .ant-menu-title-content {
      padding-left: 14px;
    }
  }
`;

export const MainMenu = styled(Menu).attrs({
  'data-class': 'Menu',
})`
  &.ant-menu.ant-menu-inline-collapsed .anticon {
    font-size: 14px !important;
    line-height: 14px !important;
    align-items: center;
  }
`;

export const MainMenuItem = styled(Menu.Item).attrs({
  'data-class': 'MainMenuItem',
})`
  &.ant-menu-item {
    padding-left: 16px !important;
    padding-right: 0;
    margin-top: 0;
    margin-bottom: 8px;
  }

  &.ant-menu-item-disabled a, .ant-menu-submenu-disabled a  {
    ${(props) => (props.disabled ? 'pointer-events: none' : '')};
    color: ${({ theme }) => theme.themesColors.neutralBranding10} !important;
  }

  .ant-menu-item {
    padding-right: 8px;
    margin-top: 0;
    margin-bottom: 8px;
  }
  &.ant-menu-item {
    margin-bottom: 0px !important;
  }

  &.ant-menu-submenu {
    .ant-menu-submenu-title {
      padding-left: 16px !important;
    }
  }

  &.ant-menu-submenu-selected,
  &.ant-menu-item-selected a {
    color: ${({ theme }) => theme.themesColors.secondaryDot45};
  }

  &.ant-menu-item-selected {
    .ant-menu-title-content {
      padding-left: 14px;
    }
  }

  &.ant-menu-submenu-selected,
  &.ant-menu-item-selected {
    svg {
      color: ${({ theme }) => theme.themesColors.primaryBranding6};
    }
  }

  span.ant-menu-title-content {
    margin-left: 18px;
    padding-left: 14px;
    z-index: 10;
  }

  &.ant-menu-submenu {
    .ant-menu-title-content {
      padding-left: 16px;
      margin-left: 16px;
      z-index: 10;
    }
    .ant-menu-item {
      .ant-menu-title-content {
        margin-left: 0;
      }
    }
    .ant-menu-item-selected::after {
      content: none;
    }
  }

  &::after {
    border-right: unset !important;
    content: '';
  }

  .ant-menu-submenu-arrow {
    color: ${({ theme }) => theme.themesColors.neutralBranding7};
    &::after,
    &::before {
      height: 2px;
      width: 6px;
    }
    &::after {
      transform: rotate(45deg) translateX(-2px);
    }
    &::before {
      transform: rotate(-45deg) translateX(2px);
    }
  }
`;

export const MainMenuContactBook = styled(MainMenuItem).attrs({
  'data-class': 'MainMenuContactBook',
})`
  .ant-menu-item-selected a {
    // padding-left: 3px;
  }
`;

export const MainMenuCustomers = styled(MainMenuItem).attrs({
  'data-class': 'MainMenuCustomers',
})`
  .ant-menu-item-selected a {
    padding-left: 3px;
  }
`;

export const HiddenIcon = styled(BankFilled)`
  visibility: hidden;
  margin-right: 15px;
`;

export const LinkLock = styled(LockOutlined)`
  display: inline;
  margin: auto 9px auto;
  font-size: 12px !important;
  color: rgba(115, 129, 155, 1);
`;

export const MainMenuWarningDot = styled.div.attrs((props: {
  isSub: boolean,
  isCollapsed: boolean,
  isVisible: boolean,
}) => ({
  isSub: props.isSub,
  isCollapsed: props.isCollapsed,
  isVisible: props.isVisible,
  'data-class': 'MainMenuWarningDot',
}))`
  ${divFlex}

  ${({ isSub }) => (isSub ? 'display: inline-flex;' : '')};
  ${({ isSub }) => (isSub ? 'position: relative;' : 'position: absolute;')};
  ${({ isSub }) => (isSub ? '' : 'right: 47px')};
  ${({ isSub }) => (isSub ? 'margin-left: 2px;' : '')};
  ${({ isSub }) => (isSub ? 'top: 0;' : 'top: 13px;')};
  ${({ isSub }) => (isSub ? 'margin-bottom: 6px;' : 'margin-bottom: auto;')};
  ${({ isCollapsed }) => (isCollapsed ? 'top: -4px;' : '')};
  ${({ isCollapsed }) => (isCollapsed ? 'right: 2px;' : '')};

  visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')};
  width: 6px;
  height: 6px;
  border-radius: 50%;
  margin-right: 2px;
  background-color: ${({ theme }) => theme?.themesColors?.characterBrandingDanger};
`;
