import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'app-wrapper/createSlice';
import { v4 as uuidv4 } from 'uuid';

import {
  BillOfLadingDTM, DocumentsDistributionDTM,
  DocumentsDistributionErrorsDTM,
  IShippingPartiesMapDTM,
} from 'shipment-operations/models/dtm';
import { BillOfLadingType, EShippingPartyTypes } from 'shipment-operations/constants';
import { IBillOfLadingState } from 'shipment-operations/models/states';

const initialState: IBillOfLadingState = {
  type: undefined,
  originals: [],
  copies: [],

  isLoading: true,
  touchedFields: {},
  errors: {
    originals: [],
    copies: [],
  },
  shippingParties: {},
  initialState: {
    type: undefined,
    originals: [],
    copies: [],
  },
  isVesselDeparted: false,
  isOnlyHouseConsigneeAvailable: false,
  isEnoughTimeRemainingBeforeVesselArrival: false,
};

export const billOfLadingStore = createSlice({
  name: 'billOfLading',
  initialState,
  reducers: {
    reset: (state) => ({
      ...initialState,
      isVesselDeparted: state.isVesselDeparted,
      isOnlyHouseConsigneeAvailable: state.isOnlyHouseConsigneeAvailable,
      isEnoughTimeRemainingBeforeVesselArrival: state.isEnoughTimeRemainingBeforeVesselArrival,
    }),
    resetBillOfLading: (state) => {
      state.id = state.initialState.id;
      state.type = state.initialState.type;
      state.originals = state.initialState.originals;
      state.copies = state.initialState.copies;
      state.touchedFields = {};
      state.wasUpdateAttempted = false;
      state.errors = {
        originals: [],
        copies: [],
      };
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setUpdateAttemptStatus: (state, action: PayloadAction<boolean>) => {
      state.wasUpdateAttempted = action.payload;
    },
    touchField: (state, action: PayloadAction<string>) => {
      state.touchedFields[action.payload] = true;
    },
    clearTouchedFields: (state) => {
      state.touchedFields = {};
    },
    setShippingPartiesMap: (state, action: PayloadAction<IShippingPartiesMapDTM>) => {
      state.shippingParties = action.payload;
    },
    setType: (state, action: PayloadAction<BillOfLadingType | undefined>) => {
      state.type = action.payload;
      state.touchedFields.type = true;
    },
    setEntryParty: (state, action: PayloadAction<{
      distribution: 'originals' | 'copies',
      index: number,
      party?: EShippingPartyTypes,
    }>) => {
      const {
        distribution, index, party,
      } = action.payload;

      state[distribution][index].party = party;
      state.touchedFields[`${distribution}.${index}.party`] = true;
    },
    setIsOnlyHouseConsigneeAvailable: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      isOnlyHouseConsigneeAvailable: payload,
    }),
    setIsEnoughTimeRemainingBeforeVesselArrival: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      isEnoughTimeRemainingBeforeVesselArrival: payload,
    }),
    setEntryAmount: (state, action: PayloadAction<{
      distribution: 'originals' | 'copies',
      index: number,
      amount?: string,
    }>) => {
      const {
        distribution, index, amount,
      } = action.payload;

      state[distribution][index].amount = amount;
      state.touchedFields[`${distribution}.${index}.amount`] = true;
    },
    setFreighted: (state, action: PayloadAction<{
      distribution: 'originals' | 'copies',
      index: number,
      freighted: boolean,
    }>) => {
      const { distribution, index, freighted } = action.payload;

      state[distribution][index].freighted = freighted;
    },
    setErrorForEntries: (state, action: PayloadAction<{
      originals: DocumentsDistributionErrorsDTM[]
      copies: DocumentsDistributionErrorsDTM[]
    }>) => {
      const { originals, copies } = action.payload;
      state.errors.originals = originals;
      state.errors.copies = copies;
    },
    addEntry: (state, action: PayloadAction<'originals' | 'copies'>) => {
      // state[action.payload] = [...state[action.payload], { id: uuidv4() }];
      state[action.payload].push(DocumentsDistributionDTM.fromPlain({ id: uuidv4(), freighted: false }));
    },
    removeEntry: (state, action: PayloadAction<{ distribution: 'originals' | 'copies', index: number }>) => {
      const { distribution, index } = action.payload;
      state[distribution] = state[distribution].filter((_, i) => i !== index);
      state.touchedFields[`${distribution}.${index}.party`] = false;
      state.touchedFields[`${distribution}.${index}.amount`] = false;
    },
    setBillOfLading: (state, action: PayloadAction<BillOfLadingDTM>) => {
      state.id = action.payload.id;
      state.type = action.payload.type;
      state.originals = [...action.payload.originals];
      state.copies = [...action.payload.copies];
    },
    setIsVesselDeparted: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      isVesselDeparted: payload,
    }),
    setInitialState: (state, action: PayloadAction<BillOfLadingDTM>) => {
      state.initialState = {
        id: action.payload.id,
        type: action.payload.type,
        originals: [...action.payload.originals],
        copies: [...action.payload.copies],
      };
    },
  },
});

export const billOfLadingActions = billOfLadingStore.actions;
export const billOfLadingReducer = billOfLadingStore.reducer;
