import React, { FC } from 'react';

import { Bold } from 'shipment-operations/view/components/HBLDocumentPDF/components';

import { CargoItemWrapper } from './CargoItem.styled';

interface CargoItemProps {
  label: string;
  value: string | number;
  alignItems?: string
}

export const CargoItem: FC<CargoItemProps> = ({ label, value, alignItems }) => (
  <CargoItemWrapper alignItems={alignItems}>
    <Bold bottomMargin>{label}</Bold>
    <span>{value}</span>
  </CargoItemWrapper>
);
