import React from 'react';

import { IPropsIcon } from 'app-wrapper/interfaces/icon';

import { Icon } from './EmptySectionForIconsSvg.styled';

export const EmptySectionForIconsSvg = (props: IPropsIcon) => {
  const { width, height, fill } = props;
  return (
    <Icon
      className="EmptySectionForIconsSvg"
      width={width || '52'}
      height={height || '42'}
      viewBox="0 0 52 42"
      fill={fill || 'currentColor'}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path fillRule="evenodd" clipRule="evenodd" d="M23.1536 35.8227H2.73354C1.67258 35.8227 0.8125 34.9625 0.8125 33.9012V2.35652C0.8125 1.2953 1.67258 0.434998 2.73354 0.434998H23.1536C24.2145 0.434998 25.0746 1.2953 25.0746 2.35652V33.9012C25.0746 34.9625 24.2145 35.8227 23.1536 35.8227Z" fill="#4E5C93" />
      <path fillRule="evenodd" clipRule="evenodd" d="M49.1852 41.5034H2.73549C1.67448 41.5034 0.814453 40.6431 0.814453 39.5819V6.15913C0.814453 5.09791 1.67448 4.23761 2.73549 4.23761H49.1852C50.2462 4.23761 51.1063 5.09791 51.1063 6.15913V39.5819C51.1063 40.6431 50.2462 41.5034 49.1852 41.5034Z" fill="#E4EBF7" />
    </Icon>
  );
};
