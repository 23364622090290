import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Warning, WarningWrap } from './RequiredWarning.styled';

interface RequiredWarningComponentProps {
  isBLICDocumentRequired: boolean;
  isWarningShown: boolean;
}

export const RequiredWarningComponent: FC<RequiredWarningComponentProps> = ({ isBLICDocumentRequired, isWarningShown }) => {
  const { t } = useTranslation();

  const warningMessage = useMemo(() => (isBLICDocumentRequired
    ? t('Following documents types are required: Tax Registration, Copy of Company Identity/Business Card, Bill stating Name & Address of Organization, Business License')
    : t('Following documents types are required: Tax Registration, Copy of Company Identity/Business Card, Bill stating Name & Address of Organization')), [t, isBLICDocumentRequired]);

  if (!isWarningShown) {
    return null;
  }

  return (
    <WarningWrap>
      <Warning
        title={t('Required Documents Missing')}
        text={warningMessage}
      />
    </WarningWrap>
  );
};
