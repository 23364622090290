import React, { forwardRef } from 'react';
import { DatePickerProps } from 'antd/es/date-picker';

import { DatePicker } from './DatePicker.styled';

// TODO: fix types for ref
const DatePickerComponent = forwardRef<any, DatePickerProps>((props, ref) => (
  <DatePicker
    className="DatePicker"
    ref={ref as React.RefObject<any>}
    {...props}
  />
));

export { DatePickerComponent as DatePicker };
