import { instanceToPlain, plainToInstance } from 'class-transformer';
import { validateSync } from 'class-validator';

type DTMType<T> = T extends { _dataType: infer U } ? U : never;

export class BaseDTM<L> {
  _dataType: L;

  static fromPlain<K>(this: new () => K, dataState: DTMType<K>): K {
    const instance = plainToInstance(this, dataState);

    // if (!instance.isValid()) {
    //   console.error(`${this.name} validation errors (getOrganizationById): ${instance.validate().join(', ')}`, instance.validate());
    // }

    return instance;
  }

  toPlain = () => instanceToPlain(this);

  isValid = () => validateSync(this).length === 0;

  validate = () => validateSync(this);
}
