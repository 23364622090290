import React, { FC, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import Divider from 'antd/es/divider';

import { ShippingPartyOverviewDTM } from 'shipment-operations/models/dtm';
import { NoDataStub } from 'shipment-operations/view/components';
import { RouteNames } from 'app-wrapper/constants';

import { SkeletonSection } from 'app-wrapper/view/components/Skeleton/Skeleton.component';
import {
  ShippingPartyCardsLayout,
  Title,
  Content,
  Card,
  CardTitle,
  Gap,
} from './ShipmentOverviewShippingParties.styled';
import { ShipmentOverviewMoreDetails } from '../ShipmentOverviewMoreDetails';
import { ShippingPartyCard } from './components';

interface IShipmentOverviewShippingPartiesProps {
  isLoading: boolean
  isLoadingPage: boolean
  houseShipper: ShippingPartyOverviewDTM
  houseConsignee: ShippingPartyOverviewDTM
  houseNotifyParty: ShippingPartyOverviewDTM
}

export const ShipmentOverviewShippingPartiesComponent: FC<IShipmentOverviewShippingPartiesProps> = ({
  isLoading,
  isLoadingPage,
  houseShipper,
  houseConsignee,
  houseNotifyParty,
}) => {
  const { shipmentId = '' } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleGoToShippingParties = useCallback(() => {
    navigate(RouteNames.SHIPMENT_SHIPPING_PARTIES(shipmentId));
  }, [navigate]);

  if (isLoading || isLoadingPage) {
    return (
      <SkeletonSection height="297px" />
    );
  }

  return (
    <ShippingPartyCardsLayout>
      <Title>
        {t('Shipping Parties')}
      </Title>

      <Content>
        <Card>
          <CardTitle>
            {t('Shipper')}
          </CardTitle>

          <Gap />

          {houseShipper.companyName ? <ShippingPartyCard shippingParty={houseShipper} /> : <NoDataStub />}
        </Card>

        <Card>
          <CardTitle>
            {t('Consignee')}
          </CardTitle>

          <Gap />

          {houseConsignee.companyName ? <ShippingPartyCard shippingParty={houseConsignee} /> : <NoDataStub />}
        </Card>

        <Card>
          <CardTitle>
            {t('Notify Party')}
          </CardTitle>

          <Gap />

          {houseNotifyParty.companyName ? <ShippingPartyCard shippingParty={houseNotifyParty} /> : <NoDataStub />}
        </Card>
      </Content>

      <Divider dashed style={{ marginTop: '16px', marginBottom: '0px' }} />

      <ShipmentOverviewMoreDetails
        onClick={handleGoToShippingParties}
      />
    </ShippingPartyCardsLayout>
  );
};
