import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Typography from 'antd/es/typography';

import { ShipmentDetailsForm } from 'shipment-operations/view/components';

import { CargoFormDivider, CargoFormSection } from './CargoForm.styled';
import {
  CargoFormHeader,
  CargoFormDescriptionInput,
  CargoFormHsCodeInput,
  CargoFormValueInput,
  CargoFormHsErrorAlert,
  CargoFormPackageTypeSelect,
  CargoFormPackagesNumberInput,
  CargoFormWeightInput,
  CargoFormVolumeInput,
  CargoFormTitle,
  CargoReferencesForm,
  CargoFormMarksInput,
  CargoFooter,
} from './components';

export const CargoForm: FC = () => {
  const { t } = useTranslation();

  return (
    <ShipmentDetailsForm footer={<CargoFooter />}>
      <CargoFormHeader />
      <CargoFormTitle />
      <CargoFormSection columns="repeat(3, minmax(0, 1fr))">
        <Typography.Text>{t('H.S. or Commodity Name')} *</Typography.Text>
        <Typography.Text>{t('Description')}</Typography.Text>
        <Typography.Text>{t('Value')}</Typography.Text>
        <CargoFormHsCodeInput />
        <CargoFormDescriptionInput />
        <CargoFormValueInput />
      </CargoFormSection>
      <CargoFormHsErrorAlert />
      <CargoFormSection columns="2fr repeat(3, 1fr)">
        <Typography.Text>{t('Pack')} *</Typography.Text>
        <Typography.Text>{t('QTY')} *</Typography.Text>
        <Typography.Text>{t('Weight')} *</Typography.Text>
        <Typography.Text>{t('Volume')}</Typography.Text>
        <CargoFormPackageTypeSelect />
        <CargoFormPackagesNumberInput />
        <CargoFormWeightInput />
        <CargoFormVolumeInput />
      </CargoFormSection>
      <CargoReferencesForm />
      <CargoFormSection columns="1fr 1fr 32px">
        <Typography.Text>{t('Marks and Numbers')}</Typography.Text>
        <Typography.Text />
        <Typography.Text />
        <CargoFormMarksInput />
      </CargoFormSection>
      <CargoFormDivider dashed />
      {/* temporarily hidden */}
      {/* <CargoFormHazmat> */}
      {/*  <CargoFormSection columns="repeat(3, 1fr)"> */}
      {/*    <Typography.Text>{t('UN Number')} *</Typography.Text> */}
      {/*    <Typography.Text>{t('IMO Class')} *</Typography.Text> */}
      {/*    <Typography.Text>{t('Packing Group')} *</Typography.Text> */}
      {/*    <CargoFormUnNumberInput /> */}
      {/*    <CargoFormImoClassSelect /> */}
      {/*    <CargoFormPackingGroupSelect /> */}
      {/*  </CargoFormSection> */}
      {/*  <CargoFormSection columns="repeat(1, 1fr)"> */}
      {/*    <Typography.Text>{t('Proper Shipping Name')} *</Typography.Text> */}
      {/*    <CargoFormShippingNameInput /> */}
      {/*  </CargoFormSection> */}
      {/*  <CargoFormSection columns="repeat(1, 1fr)"> */}
      {/*    <Typography.Text>{t('MSDS')} *</Typography.Text> */}
      {/*    <CargoDocumentUpload /> */}
      {/*  </CargoFormSection> */}
      {/*  <CargoFormSection columns="repeat(2, 1fr)"> */}
      {/*    <Typography.Text>{t('Emergency Contact Name')}</Typography.Text> */}
      {/*    <Typography.Text>{t('Emergency Contact Number')}</Typography.Text> */}
      {/*    <CargoFormContactNameInput /> */}
      {/*    <CargoFormContactNumberInput /> */}
      {/*  </CargoFormSection> */}
      {/* </CargoFormHazmat> */}
      <CargoFormDivider dashed />
    </ShipmentDetailsForm>
  );
};
