import React, { FC } from 'react';

import { R } from 'shipment-operations/repository';
import { useAppSelector } from 'app-wrapper/hooks';
import { UC } from 'shipment-operations/controllers';
import { ChargeViewDtm } from 'shipment-operations/models/dtm';

import { CustomTable } from './CustomTable.component';

interface ICustomTableProps {
  data: ChargeViewDtm[]
  subTitle?: string
}

const CustomTableContainer: FC<ICustomTableProps> = ({ data, subTitle }) => {
  const isPercentage = useAppSelector(R.selectors.shipmentTransportationCharges.getIsPercentage);
  const isLoadingDelete = useAppSelector(R.selectors.shipmentActiveCharge.getIsLoadingDelete);

  return (
    <CustomTable
      data={data}
      isPercentage={isPercentage}
      openEditPanel={UC.shipmentActiveCharge.openEditCharge}
      setDeletedCharge={UC.shipmentActiveCharge.setDeletedCharge}
      onDelete={UC.shipmentActiveCharge.deleteCharge}
      isLoadingDelete={isLoadingDelete}
      onDownloadDocument={UC.shipmentActiveCharge.downloadDocument}
      subTitle={subTitle}
    />
  );
};

export { CustomTableContainer as CustomTable };
