import React, {
  FC,
  useMemo,
  useCallback,
  ReactNode,
  CSSProperties,
} from 'react';
import { useTranslation, TFunction } from 'react-i18next';
import { useNavigate } from 'react-router';

import {
  CargoBaseDTM, ContainerCargoShortItemDTM, CargoDTM,
} from 'shipment-operations/models/dtm';
import { CargoReferenceType, CargoReferenceTypeNamesShort } from 'shipment-operations/constants';
import { PackageTypeNames, ReversePackageTypesCodes } from 'app-wrapper/types/PackageType';
import { NoDataStub } from 'shipment-operations/view/components';

import {
  SDLTableLayout,
  SDLTable,
  SDLTableCell,
  SDLTableHeader,
  SDLTableFooter,
  SDLTableFooterCell,
  SDLWarningIcon,
  SDLTableNameCell,
  SDLTooltip,
  SDLFooterWrap,
  SDLFooterDivider,
  SDLHazmatTag,
  SDLTableCellEllipsis,
  SDLTableHeaderCustom,
  SDLTableFooterTotal,
} from './SBLGoodsTable.styled';

const getCargoWarningMessage = (cargo: CargoDTM, allCargoItems: ContainerCargoShortItemDTM[], t: TFunction<'translation'>): string => {
  const cargoItems = allCargoItems.filter(({ cargoId }) => +cargoId === cargo.id);
  let warningMessage = '';

  const cargoItemSum = cargoItems.reduce((prev, next) => ({
    volume: +prev.volume + +next.volume,
    weight: +prev.weight + +next.weight,
    packagesNumber: +prev.packagesNumber + +next.packagesNumber,
  }), {
    volume: 0,
    weight: 0,
    packagesNumber: 0,
  });

  if (cargo.weight && (cargo.weight !== String(cargoItemSum.weight))) {
    warningMessage = `${warningMessage}${t('warningMessageCargoWeight', { itemWeight: cargoItemSum.weight, cargoWeight: cargo.weight })}
    `;
  }

  if (cargo.volume && (cargo.volume !== String(cargoItemSum.volume))) {
    warningMessage = `${warningMessage}${t('warningMessageCargoVolume', { itemVolume: cargoItemSum.volume, cargoVolume: cargo.volume })}
    `;
  }

  if (cargo.packagesNumber && (cargo.packagesNumber !== String(cargoItemSum.packagesNumber))) {
    warningMessage = `${warningMessage}${t('warningMessageCargoPackagesNumber', { itemPackages: cargoItemSum.packagesNumber, cargoPackages: cargo.packagesNumber })}
    `;
  }

  return warningMessage.trim();
};

interface ISBLGoodsTableComponentProps {
  cargos: CargoDTM[];
  cargoItems?: ContainerCargoShortItemDTM[];
  title?: string;
  redirectURL?: string;
  isNested?: boolean;
  footer?: ReactNode;
  showHazmatTag?: boolean;
  hideNoDataAddButton?: boolean;
  titleStyles?: {
    fontSize: CSSProperties['fontSize'];
  };
}

const EMPTY_CELL = '-';

export const SBLGoodsTableComponent: FC<ISBLGoodsTableComponentProps> = ({
  cargos,
  cargoItems = [],
  isNested,
  redirectURL = '',
  title: sectionTitle,
  footer,
  showHazmatTag = false,
  hideNoDataAddButton = false,
  titleStyles = {},
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const columns = useMemo(() => [
    {
      title: t('Description'),
      dataIndex: 'description',
      key: 'description',
      width: '28%',
      render: (item: string, record: CargoDTM | {}) => {
        const cargo = record as CargoDTM;
        const title = cargo.description ? String(cargo.description) : String(cargo.name);
        const warningMessage = getCargoWarningMessage(cargo, cargoItems, t);

        if (showHazmatTag && cargo.isHazmat) {
          return (
            <SDLTableNameCell>
              <SDLTooltip title={title} placement="top">
                <SDLTableCellEllipsis>
                  {title}
                </SDLTableCellEllipsis>
              </SDLTooltip>

              <SDLHazmatTag color="gold">
                {t('Hazmat')}
              </SDLHazmatTag>
            </SDLTableNameCell>
          );
        }

        return !(warningMessage && !isNested) ? (
          <SDLTooltip title={title} placement="top">
            <SDLTableCell>
              {title}
            </SDLTableCell>
          </SDLTooltip>
        ) : (
          <SDLTableNameCell>
            <SDLTooltip title={title} placement="top">
              <SDLTableCellEllipsis>
                {title}
              </SDLTableCellEllipsis>
            </SDLTooltip>

            {warningMessage ? (
              <SDLTooltip title={warningMessage} placement="top">
                <SDLWarningIcon />
              </SDLTooltip>
            ) : null}
          </SDLTableNameCell>
        );
      },
    },
    {
      title: t('HS'),
      dataIndex: 'hs',
      key: 'hs',
      width: '10%',
      render: (item: string, record: CargoBaseDTM | {}) => {
        const cargo = record as CargoBaseDTM;

        return (
          <SDLTableCell>
            {cargo.code}
          </SDLTableCell>
        );
      },
    },
    {
      title: t('Reference'),
      dataIndex: 'reference',
      key: 'reference',
      width: '10%',
      render: (item: string, record: CargoBaseDTM | {}) => {
        const cargo = record as CargoBaseDTM;
        const reference = cargo.references.find(({ type }) => type === CargoReferenceType.PO_NUMBER);
        const referenceType = reference?.type ? CargoReferenceTypeNamesShort[reference?.type] : '';

        return (
          <SDLTableCell>
            {reference ? `${referenceType}${reference.value}` : EMPTY_CELL}
          </SDLTableCell>
        );
      },
    },
    {
      title: t('Pack'),
      dataIndex: 'pack',
      key: 'pack',
      width: '10%',
      render: (item: string, record: CargoBaseDTM | {}) => {
        const cargo = record as CargoBaseDTM;
        const packageType = cargo.packageType ? ReversePackageTypesCodes[cargo.packageType as unknown as keyof typeof ReversePackageTypesCodes] : '';

        return (
          <SDLTableCell>
            {packageType ? PackageTypeNames[packageType] : EMPTY_CELL}
          </SDLTableCell>
        );
      },
    },
    {
      title: t('Pack QTY'),
      dataIndex: 'pack-quantity',
      key: 'pack-quantity',
      width: '7%',
      align: 'right' as 'right',
      render: (item: string, record: CargoBaseDTM | {}) => {
        const cargo = record as CargoBaseDTM;

        return (
          <SDLTableCell>
            {cargo.packagesNumber}
          </SDLTableCell>
        );
      },
    },
    {
      title: t('Weight, kg'),
      dataIndex: 'weight',
      key: 'weight',
      width: '12%',
      align: 'right' as 'right',
      render: (item: string, record: CargoBaseDTM | {}) => {
        const cargo = record as CargoBaseDTM;

        return (
          <SDLTableCell>
            {cargo.weight ? Number(cargo.weight).toFixed(2) : 0}
          </SDLTableCell>
        );
      },
    },
    {
      title: t('Volume, cbm'),
      dataIndex: 'volume',
      key: 'volume',
      width: '12%',
      align: 'right' as 'right',
      render: (item: string, record: CargoBaseDTM | {}) => {
        const cargo = record as CargoBaseDTM;

        return (
          <SDLTableCell>
            {cargo.volume ? Number(cargo.volume).toFixed(2) : 0}
          </SDLTableCell>
        );
      },
    },
    {
      title: t('Goods Value'),
      dataIndex: 'usd',
      key: 'usd',
      width: '12%',
      align: 'right' as 'right',
      render: (item: string, record: CargoBaseDTM | {}) => {
        const cargo = record as CargoBaseDTM;

        return (
          <SDLTableCell>
            $ {cargo.value ? Number(cargo.value).toFixed(2) : 0}
          </SDLTableCell>
        );
      },
    },
  ], [t]);

  const footerColumns = useMemo(() => [
    {
      key: 1,
      width: '28%',
      render: () => (
        <SDLTableFooterTotal>
          {t('total')}
        </SDLTableFooterTotal>
      ),
    },
    {
      key: 2,
      width: '10%',
    },
    {
      key: 3,
      width: '10%',
    },
    {
      key: 4,
      width: '10%',
    },
    {
      dataIndex: 'pack-quantity',
      key: 'pack-quantity',
      width: '7%',
      align: 'right' as 'right',
      render: (item: string, record: CargoBaseDTM | {}) => {
        const cargo = record as CargoBaseDTM;

        return (
          <SDLTableFooterCell>
            {cargo.packagesNumber || 0}
          </SDLTableFooterCell>
        );
      },
    },
    {
      dataIndex: 'weight',
      key: 'weight',
      width: '12%',
      align: 'right' as 'right',
      render: (item: string, record: CargoBaseDTM | {}) => {
        const cargo = record as CargoBaseDTM;

        return (
          <SDLTableFooterCell>
            {cargo.weight || 0}
          </SDLTableFooterCell>
        );
      },
    },
    {
      dataIndex: 'volume',
      key: 'volume',
      width: '12%',
      align: 'right' as 'right',
      render: (item: string, record: CargoBaseDTM | {}) => {
        const cargo = record as CargoBaseDTM;

        return (
          <SDLTableFooterCell>
            {cargo.volume || 0}
          </SDLTableFooterCell>
        );
      },
    },
    {
      dataIndex: 'usd',
      key: 'usd',
      width: '12%',
      align: 'right' as 'right',
      render: (item: string, record: CargoBaseDTM | {}) => {
        const cargo = record as CargoBaseDTM;

        return (
          <SDLTableFooterCell>
            $ {cargo.value || 0}
          </SDLTableFooterCell>
        );
      },
    },
  ], []);

  const footerDataSource = useMemo(() => cargos.reduce((prev, next) => {
    if (!next) {
      return prev;
    }

    return {
      packagesNumber: prev.packagesNumber + Number(next.packagesNumber || 0),
      volume: prev.volume + Number(next.volume || 0),
      weight: prev.weight + Number(next.weight || 0),
      value: +(prev.value + Number(next.value || 0)).toFixed(2),
    };
  }, {
    packagesNumber: 0,
    volume: 0,
    weight: 0,
    value: 0,
  }), [cargos]);

  const handleDataStubClick = useCallback(() => {
    navigate(redirectURL);
  }, [navigate]);

  return (
    <SDLTableLayout>
      {sectionTitle ? (
        <SDLTableHeaderCustom style={{ ...titleStyles }}>
          {sectionTitle}
        </SDLTableHeaderCustom>
      ) : (
        <SDLTableHeader
          isNested={isNested}
          style={{ ...titleStyles }}
        >
          {t('Cargo')}
        </SDLTableHeader>
      )}

      <SDLTable
        locale={{
          emptyText: <NoDataStub onAddData={hideNoDataAddButton ? undefined : handleDataStubClick} />,
        }}
        dataSource={cargos}
        pagination={false}
        columns={columns}
      />
      {!isNested ? (
        <SDLTableFooter
          columns={footerColumns}
          dataSource={[footerDataSource]}
          pagination={false}
          showHeader={false}
        />
      ) : null}

      {cargos.length && footer ? (
        <SDLFooterWrap>
          <SDLFooterDivider />
          {footer}
        </SDLFooterWrap>
      ) : null}
    </SDLTableLayout>
  );
};
