import {
  ValidateNested,
  IsOptional,
  IsBoolean,
} from 'class-validator';
import { Type } from 'class-transformer';

import {
  AppFiltersCheckboxDTM, AppFiltersMinMaxDTM, IAppFiltersCheckboxDTM, IAppFiltersMinMaxDTM,
} from 'app-wrapper/models/dtm';
import { BaseDTM } from 'proto/BaseDTM';

export interface IFreightFiltersDTM {
  filterPrice?: IAppFiltersMinMaxDTM
  filterTransitTime?: IAppFiltersMinMaxDTM
  filterCarrier?: IAppFiltersCheckboxDTM
  filterIsFavorite?: boolean
}

export class FreightFiltersDTM extends BaseDTM<IFreightFiltersDTM> {
  @IsOptional()
  @ValidateNested()
  @Type(() => AppFiltersMinMaxDTM)
  filterPrice?: AppFiltersMinMaxDTM;

  @IsOptional()
  @ValidateNested()
  @Type(() => AppFiltersMinMaxDTM)
  filterTransitTime?: AppFiltersMinMaxDTM;

  @IsOptional()
  @ValidateNested()
  @Type(() => AppFiltersCheckboxDTM)
  filterCarrier?: AppFiltersCheckboxDTM;

  @IsOptional()
  @IsBoolean()
  filterIsFavorite?: boolean;
}
