import { css } from 'styled-components';

const responsive = {
  forFullMedia: (arg: any, ...args: any) => css`
      @media (min-width: 1600px) {
        ${css(arg, ...args)};
      }
    `,
  forFullMediaMax: (arg: any, ...args: any) => css`
      @media (max-width: 1599px) {
        ${css(arg, ...args)};
      }
    `,
  for1768Min: (arg: any, ...args: any) => css`
      @media (min-width: 1768px) {
        ${css(arg, ...args)};
      }
    `,
  forFullDesktop: (arg: any, ...args: any) => css`
      @media (min-width: 1920px) {
        ${css(arg, ...args)};
      }
    `,
  forFullDesktopMax: (arg: any, ...args: any) => css`
      @media (max-width: 1919px) {
        ${css(arg, ...args)};
      }
    `,
  forLargeDesktop: (arg: any, ...args: any) => css`
      @media (min-width: 1440px) {
        ${css(arg, ...args)};
      }
    `,
  forMediumDesktop: (arg: any, ...args: any) => css`
      @media (min-width: 1366px) {
        ${css(arg, ...args)};
      }
    `,
  forMediumDesktopMax: (arg: any, ...args: any) => css`
      @media (max-width: 1365px) {
        ${css(arg, ...args)};
      }
    `,
  forMediumMediaDesktop: (arg: any, ...args: any) => css`
      @media (min-width: 1200px) {
        ${css(arg, ...args)};
      }
    `,
  forMediumMediaDesktopMax: (arg: any, ...args: any) => css`
      @media (max-width: 1200px) {
        ${css(arg, ...args)};
      }
    `,
};

export default responsive;
