import React from 'react';

import { IPropsIcon } from 'app-wrapper/interfaces/icon';

export const PackagesNumber = (props: IPropsIcon) => {
  const { width, height, fill } = props;
  return (
    <svg
      width={width || '14'}
      height={height || '13'}
      fill={fill || 'currentColor'}
      viewBox="0 0 14 13"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M5.25 0.750977H0.5C0.3625 0.750977 0.25 0.863477 0.25 1.00098V5.75098C0.25 5.88848 0.3625 6.00098 0.5 6.00098H5.25C5.3875 6.00098 5.5 5.88848 5.5 5.75098V1.00098C5.5 0.863477 5.3875 0.750977 5.25 0.750977ZM4.4375 4.93848H1.3125V1.81348H4.4375V4.93848ZM11.5 0.750977H6.75C6.6125 0.750977 6.5 0.863477 6.5 1.00098V5.75098C6.5 5.88848 6.6125 6.00098 6.75 6.00098H11.5C11.6375 6.00098 11.75 5.88848 11.75 5.75098V1.00098C11.75 0.863477 11.6375 0.750977 11.5 0.750977ZM10.6875 4.93848H7.5625V1.81348H10.6875V4.93848ZM5.25 7.00098H0.5C0.3625 7.00098 0.25 7.11348 0.25 7.25098V12.001C0.25 12.1385 0.3625 12.251 0.5 12.251H5.25C5.3875 12.251 5.5 12.1385 5.5 12.001V7.25098C5.5 7.11348 5.3875 7.00098 5.25 7.00098ZM4.4375 11.1885H1.3125V8.06348H4.4375V11.1885ZM11.5 7.00098H6.75C6.6125 7.00098 6.5 7.11348 6.5 7.25098V12.001C6.5 12.1385 6.6125 12.251 6.75 12.251H11.5C11.6375 12.251 11.75 12.1385 11.75 12.001V7.25098C11.75 7.11348 11.6375 7.00098 11.5 7.00098ZM10.6875 11.1885H7.5625V8.06348H10.6875V11.1885Z" fill="#D1D7E2" />
    </svg>
  );
};
