import { RoutesController } from 'authentication/controllers/RoutersController';
import { ControllerParams } from 'proto/BaseController';
import { AuthController } from './AuthController';
import { SignUpController } from './SignUpController';
import { PasswordResetEmailSendController } from './PasswordResetEmailSendController';
import { PasswordResetNewPasswordController } from './PasswordResetNewPasswordController';
import { SignUpSuccessController } from './SignUpSuccessController';

type UseCases = {
  auth: AuthController
  signUp: SignUpController
  signUpSuccess: SignUpSuccessController
  passwordResetEmailSend: PasswordResetEmailSendController
  passwordResetNewPassword: PasswordResetNewPasswordController
  routesController: RoutesController
}

// @ts-ignore
export const UC: UseCases = {};

export const initUC = (controllerParams: ControllerParams) => {
  UC.auth = new AuthController(controllerParams);
  UC.signUp = new SignUpController(controllerParams);
  UC.signUpSuccess = new SignUpSuccessController(controllerParams);
  UC.passwordResetEmailSend = new PasswordResetEmailSendController(controllerParams);
  UC.passwordResetNewPassword = new PasswordResetNewPasswordController(controllerParams);
  UC.routesController = new RoutesController(controllerParams);
};
