import React, { FC, useCallback } from 'react';
import { useNavigate } from 'react-router';

import { RouteNames } from 'app-wrapper/constants';

import { NameLink } from './TeamName.styled';

interface ITeamNameProps {
  id: number;
  name: string;
}

export const TeamName: FC<ITeamNameProps> = ({ id, name }) => {
  const navigate = useNavigate();

  const navigateToTeam = useCallback(() => navigate(RouteNames.ACCOUNT_TEAM(id)), [id, navigate]);

  return (
    <NameLink onClick={navigateToTeam}>
      {name}
    </NameLink>
  );
};
