import {
  IsEnum, IsOptional,
} from 'class-validator';
import { EPermissionAttributePolicy } from 'monetary/constants';
import { BaseDTM } from 'proto/BaseDTM';
import { PermissionAttributePolicy } from 'shipment-operations/constants';

export type TEditPartnerOrganizationProfileDrawerDtm = {
  coverageAreas?: EPermissionAttributePolicy
}

export class EditPartnerOrganizationProfileDrawerDtm extends BaseDTM<TEditPartnerOrganizationProfileDrawerDtm> {
  @IsOptional()
  @IsEnum(PermissionAttributePolicy)
  coverageAreas?: PermissionAttributePolicy
}
