import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { CustomModal } from 'shipment-operations/view/components';

import {
  TermsAndConditions,
  Footer,
} from './components';

interface BookingDrawerComponentProps {
  isOpen: boolean;
  onClose: () => void;
}

export const TermsAndConditionsDrawerComponent: FC<BookingDrawerComponentProps> = ({
  isOpen,
  onClose,
}) => {
  const { t } = useTranslation();

  return isOpen ? (
    <CustomModal
      title={t('Terms & Conditions')}
      onClose={onClose}
      contentStyles={{
        left: 'unset',
        width: '45%',
        minWidth: '736px',
      }}
      childrenWrapperStyles={{
        position: 'relative',
        padding: '0px 0px',
      }}
    >
      <TermsAndConditions />

      <Footer />
    </CustomModal>
  ) : null;
};
