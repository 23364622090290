import styled from 'styled-components';
import { ArrowLeftOutlined } from '@ant-design/icons';

import { emptyScrollbar } from 'app-wrapper/view/themes/components/emptyScrollbar';
import { divFlex } from 'app-wrapper/view/themes/mixin';

export const AACDiv = styled.div.attrs({
  'data-class': 'AACDiv',
})`
  ${divFlex}
  ${emptyScrollbar}
  flex-direction: column;
  width: 100%;
  padding: 16px;
  max-width: 1660px;
  margin: 0 auto;
`;

export const AACDivHeader = styled.div.attrs({
  'data-class': 'AACDivHeader',
})`
  ${divFlex}
  width: 100%;
  align-items: center;
  height: 28px;

  color: ${({ theme }) => theme.themesColors.neutralBranding10};
  font-size: 20px;
  font-weight: 500;
`;

export const AACDivHeaderHeaderArrowIcon = styled(ArrowLeftOutlined).attrs({
  'data-class': 'AACDivHeaderHeaderArrowIcon',
})`
  margin-right: 16px;
  width: 13px;
  height: 13px;

  svg {
    width: 13px;
    height: 13px;
  }
`;
