import styled from 'styled-components';
import Typography from 'antd/es/typography';

import { ContainerIcon } from 'app-wrapper/view/icons';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 32px;
`;

export const Text = styled(Typography)`
  margin-left: 8px;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
`;

export const ContainerIconStyled = styled(ContainerIcon)``;
