import styled from 'styled-components';

import { divFlex } from 'app-wrapper/view/themes/mixin';

export const SupportDialogBlockWrap = styled.div.attrs((props: {
  marginBottom: string
  'data-class'?: string
}) => ({
  marginBottom: props.marginBottom,
  'data-class': props?.['data-class'] || 'SupportDialogBlockWrap',
}))`
  ${divFlex}
  width: 363px;
  height: 94px;

  align-items: center;
  justify-content: center;
  ${({ marginBottom }) => marginBottom && `margin-bottom: ${marginBottom}`};

  opacity: 0.8;
  background-color: ${({ theme }) => theme?.themesColors?.neutralBranding1};
  border: 1px solid ${({ theme }) => theme?.themesColors?.neutralBranding1};
  border-radius: 3px;
`;

export const SupportDialogBlockContent = styled.div.attrs({
  'data-class': 'SupportDialogBlockContent',
})`
  ${divFlex}

  width: 100%;
  height: 100%;
  padding: 16px;
`;

export const SupportDialogBlockIonWrap = styled.div.attrs((props: {
  iconColor: string
  'data-class'?: string
}) => ({
  iconColor: props.iconColor,
  'data-class': props?.['data-class'] || 'SupportDialogBlockIonWrap',
}))`
  ${divFlex}
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;

  padding: 16px;
  border: 1px solid ${({ theme }) => theme?.themesColors?.primaryBranding1};
  border-radius: 4px;

  svg {
    ${({ iconColor }) => iconColor && `color: ${iconColor}`};
  }
`;

export const SupportDialogBlockTextWrap = styled.div.attrs({
  'data-class': 'SupportDialogBlockTextWrap',
})`
  ${divFlex}
  flex-direction: column;
  align-items: start;
  justify-content: center;

  width: 100%;
  margin-left: 16px;
`;

export const SupportDialogBlockHeader = styled.div.attrs({
  'data-class': 'SupportDialogBlockHeader',
})`
  ${divFlex}
  align-items: center;
  justify-content: center;

  ${({ theme }) => theme?.fonts?.BodyTextMedium};
  color: ${({ theme }) => theme?.themesColors?.neutralBranding10};
`;

export const SupportDialogBlockFooter = styled.div.attrs({
  'data-class': 'SupportDialogBlockFooter',
})`
  ${divFlex}
  align-items: center;
  justify-content: center;

  ${({ theme }) => theme?.fonts?.SmallTextFootnoteDescription};
  color: ${({ theme }) => theme?.themesColors?.secondaryDot45};
`;
