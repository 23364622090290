import React, {
  useCallback, useMemo, useRef, useState, useEffect, FC, memo,
} from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useReactToPrint } from 'react-to-print';

import themesColors from 'app-wrapper/view/themes/themesColors';
import { Button } from 'app-wrapper/view/components';
import { FavoritesCheckQuotesSvg, FavoritesQuotesSvg, VerticalFormItemSvg } from 'app-wrapper/view/icons';
import { normalizationCost } from 'app-wrapper/utils';
import { REQUEST_STATUS } from 'app-wrapper/constants';

import { BookingComponent } from 'shipment-operations/view/pages';

import {
  FreightQuotaContentDTM,
  DetailAccessorialsDTM,
  DetailBreakdownDTM, DetailRoutingDTM,
} from 'monetary/models/dtm';
import { getCarrierSCACNamesRFQ } from 'monetary/constants';

import {
  RFQItemContent,
  RFQItemDiv,
  RFQItemFooter,
  RFQItemHeader,
  RFQItemHeaderFirst,
  RFQItemHeaderFirstSummary,
  RFQItemHeaderFirstTitle,
  RFQItemFooterDetail,
  RFQItemFooterBook,
} from './styled';
import { RFQCarousel } from '../RFQCarousel';
import { RFQItemDetail } from '../RFQItemDetail';
import { RFQItemHeaderComponent } from './RFQItemHeaderComponent';
import { DraftOrganizationPopover } from './DraftOrganizationPopover';
import { RFQItemCutoffComponent } from './RFQItemCutoffComponent';
import { PrintRFQ } from './PrintRFQ';
import { RFQItemHeaderIncotermTitle, RFQItemHeaderSecondTitle } from './styled/header';
import {
  RFQItemFooterBookButton, RFQItemFooterDetailExpired, RFQItemFooterDetailExpiredDate, RFQItemFooterFavoriteButton, RFQItemFooterFavoritePrint,
} from './styled/footer';

export interface IRFQItemComponentProps {
  data?: FreightQuotaContentDTM
  dataNotApplied?: FreightQuotaContentDTM
  idIndex?: number
  onScroll: (top: number) => void
  onPrint?: (id: number) => void
  isLoadingPrint?: boolean
  printedId?: number
  shipmentId?: number
  indexSchedule: number
  onClearCustomerInfo?: () => void
  onBook: (quotaId: number, quotaScheduleId: number) => void
  freightData?: DetailRoutingDTM[]
  originData?: DetailRoutingDTM[]
  destinationData?: DetailRoutingDTM[]
  breakdownOrigin?: DetailBreakdownDTM
  breakdownFreight?: DetailBreakdownDTM
  breakdownDestination?: DetailBreakdownDTM
  accessorialsOrigin?: DetailAccessorialsDTM
  accessorialsFreight?: DetailAccessorialsDTM
  accessorialsDestination?: DetailAccessorialsDTM
  bookingStatus?: string
  fileNamePrint?: string
  totalCost?: string
  isAllRequestPage?: boolean
  paymentTermsOrigin: boolean
  paymentTermsDestination: boolean
  isShowContractName?: boolean
  isFavorite?: boolean
  isFavoriteLoading?: boolean
  isOrganizationInDraft?: boolean
  isOrganizationInWaitingForApproval?: boolean
  hideBook?: boolean
  hideFavorite?: boolean
  onClickToggleIsFavorite: (isChecked: boolean) => void
  mustOrganizationAcceptTermsAndCondition?: boolean
}

const oldTitle = document.title;

const RFQItemComponent: FC<IRFQItemComponentProps> = (props) => {
  const {
    data,
    idIndex,
    dataNotApplied,
    onScroll,
    onPrint,
    isLoadingPrint,
    printedId,
    shipmentId,
    onClearCustomerInfo,
    onBook,
    indexSchedule,
    freightData,
    originData,
    destinationData,
    breakdownOrigin,
    breakdownFreight,
    breakdownDestination,
    accessorialsOrigin,
    accessorialsFreight,
    accessorialsDestination,
    bookingStatus,
    fileNamePrint,
    totalCost,
    paymentTermsOrigin,
    paymentTermsDestination,
    isShowContractName,
    isFavorite,
    hideBook,
    hideFavorite,
    onClickToggleIsFavorite,
    isOrganizationInDraft,
    isOrganizationInWaitingForApproval,
    mustOrganizationAcceptTermsAndCondition,
  } = props;

  const { t } = useTranslation();
  const itemRef = useRef<{ clientWidth: number } & HTMLDivElement>(null);
  const [isDetail, setIsDetail] = useState(false);

  const itemWidth = useMemo<number>(
    () => (itemRef?.current?.clientWidth) || 0,
    [itemRef?.current?.clientWidth],
  );
  const isMiniVisible = useMemo(() => (itemWidth < 640), [itemRef?.current?.clientWidth]);

  const detailOnHandler = useCallback(
    () => {
      setIsDetail((prev) => !prev);
    },
    [],
  );

  const bookOnHandler = useCallback(
    () => {
      onBook(data?.id || 0, Number(data?.schedules?.[indexSchedule]?.id) || 0);
    },
    [data, shipmentId, indexSchedule, onBook],
  );

  useEffect(() => {
    if (isDetail) {
      const height = itemRef?.current?.getBoundingClientRect().height || 0;
      const y = itemRef?.current?.getBoundingClientRect().y || 0;
      const top = itemRef?.current?.offsetTop || 0;
      if (height + y > window.innerHeight) {
        onScroll(top);
      }
    }
  }, [isDetail, onScroll]);

  const isCutoffGold = useMemo<boolean>(() => {
    const cutoffDoc = moment(data?.schedules?.[indexSchedule]?.documentCutOff);
    const dateNow = moment(Date());

    return cutoffDoc.diff(dateNow, 'day') <= 5;
  }, [indexSchedule, data]);

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    // @ts-ignore
    content: () => componentRef.current,
    documentTitle: fileNamePrint,
    onAfterPrint: () => {
      if (onClearCustomerInfo) {
        onClearCustomerInfo();
      }

      // support chrome
      document.title = oldTitle;
    },
  });

  const onClickPrint = useCallback(async () => {
    if (onPrint) {
      await onPrint(data?.id || 0);
      // support chrome
      document.title = fileNamePrint || oldTitle;
      handlePrint();
    }
  }, [fileNamePrint, data?.id]);

  const onClickToggleIsFavoriteHandel = useCallback(
    () => {
      onClickToggleIsFavorite(!!isFavorite);
    },
    [onClickToggleIsFavorite, isFavorite],
  );

  const disableBook = bookingStatus === REQUEST_STATUS.pending || data?.validPeriod?.getIsExpired();

  const nameSCAC: string = data?.contracts?.[0]?.scac || '';
  const nameTitle: string = getCarrierSCACNamesRFQ(nameSCAC);

  return (
    <RFQItemDiv ref={itemRef} className="RFQItemDiv">
      <BookingComponent />
      <RFQItemHeader>
        <RFQItemHeaderFirst>
          <RFQItemHeaderFirstTitle>{nameTitle}</RFQItemHeaderFirstTitle>
          <RFQItemHeaderSecondTitle>
            {isShowContractName ? data?.contracts?.[0].number || '' : ''}
          </RFQItemHeaderSecondTitle>
          <RFQItemHeaderIncotermTitle>
            {data?.incoterm || ''}
          </RFQItemHeaderIncotermTitle>
          <RFQItemHeaderFirstSummary>$ {normalizationCost(totalCost || 0, { toFixed: 2, thousandthSeparatorComma: true })}</RFQItemHeaderFirstSummary>
        </RFQItemHeaderFirst>

        <RFQItemHeaderComponent
          data={data}
          indexSchedule={indexSchedule}
        />
      </RFQItemHeader>

      <RFQItemContent>
        <RFQCarousel
          schedules={data?.schedules}
          contentItemWidth={itemWidth}
          indexRFQItem={idIndex}
        />
      </RFQItemContent>

      <RFQItemCutoffComponent
        data={data}
        indexSchedule={indexSchedule}
        isMiniVisible={isMiniVisible}
        isCutoffGold={isCutoffGold}
      />

      <RFQItemFooter>
        <RFQItemFooterDetail isDetail={isDetail}>
          <Button className="RFQItemDiv__btn_Details" onClick={detailOnHandler}>
            <VerticalFormItemSvg
              rotate={isDetail ? 0 : -90}
              style={{
                color: isDetail ? themesColors.primaryBranding5 : themesColors.neutralBranding7,
              }}
            /><span>{t('Details')}</span>
          </Button>
          <RFQItemFooterDetailExpired isExpired={data?.validPeriod?.getIsExpired()}>
            {`${data?.validPeriod?.getIsExpired() ? t('Expired') : t('Expires')}`}
            <RFQItemFooterDetailExpiredDate>
              {`${data?.validPeriod?.getFormatDateDMMMTo() || ''}`}
            </RFQItemFooterDetailExpiredDate>
          </RFQItemFooterDetailExpired>
        </RFQItemFooterDetail>
        <RFQItemFooterBook>
          <div style={{ display: 'none' }}>
            <PrintRFQ
              ref={componentRef}
            />
          </div>
          {/* temporarily hidden */}
          {!hideFavorite && false && (
            <RFQItemFooterFavoriteButton
              disabled={data?.isFavoriteLoading}
              onClick={onClickToggleIsFavoriteHandel}
              loading={data?.isFavoriteLoading}
            >
              {isFavorite
                ? (<FavoritesCheckQuotesSvg fill={themesColors.secondaryDot45} />)
                : (<FavoritesQuotesSvg fill={themesColors.secondaryDot45} />)}
            </RFQItemFooterFavoriteButton>
          )}
          <RFQItemFooterFavoritePrint
            disabled={bookingStatus === REQUEST_STATUS.pending}
            onClick={onClickPrint}
            loading={isLoadingPrint && printedId === dataNotApplied?.id}
          >
            {t('Print')}
          </RFQItemFooterFavoritePrint>
          {!hideBook && (
            <>
              {isOrganizationInDraft || isOrganizationInWaitingForApproval || mustOrganizationAcceptTermsAndCondition ? (
                <DraftOrganizationPopover>
                  <RFQItemFooterBookButton
                    style={{ marginLeft: '10px', opacity: 0.2, cursor: 'initial' }}
                    className="RFQItemDiv__btn_Book"
                  >
                    {t('Book')}
                  </RFQItemFooterBookButton>
                </DraftOrganizationPopover>
              ) : (
                <RFQItemFooterBookButton
                  style={{ marginLeft: '10px' }}
                  disabled={disableBook}
                  onClick={bookOnHandler}
                  className="RFQItemDiv__btn_Book"
                >
                  {t('Book')}
                </RFQItemFooterBookButton>
              )}
            </>
          )}
        </RFQItemFooterBook>
      </RFQItemFooter>
      {isDetail && (
        <RFQItemDetail
          routing={{
            freight: freightData,
            origin: originData,
            destination: destinationData,
          }}
          breakdown={{
            freight: breakdownFreight,
            origin: breakdownOrigin,
            destination: breakdownDestination,
          }}
          accessorials={{
            freight: accessorialsFreight,
            origin: accessorialsOrigin,
            destination: accessorialsDestination,
          }}
          paymentTermsOrigin={paymentTermsOrigin}
          paymentTermsDestination={paymentTermsDestination}
          idIndex={idIndex}
        />
      )}
    </RFQItemDiv>
  );
};

const RFQItemComponentCached = memo(RFQItemComponent);

export { RFQItemComponentCached as RFQItemComponent };
