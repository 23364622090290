interface IGetLocationToOneStringProps {
  country?: string | null
  state?: string | null
  code?: string | null
  city?: string | null
  subdivisionName?: string
  firstCityName?: boolean
}

export const getLocationToOneString = ({
  country,
  state,
  code,
  city,
  subdivisionName,
  firstCityName,
}: IGetLocationToOneStringProps): string => {
  if (firstCityName) {
    const cityName = `${city ? `${city}` : ''}`;
    const stateName = `${state ? `${cityName && ', '}${state}` : ''}`;
    const countryName = `${country ? `${(cityName || stateName) && ', '}${country}` : ''}`;

    return `${cityName}${stateName}${countryName}`;
  }

  if (code) {
    let stateName = `${state ? `${state}` : ''}`;
    if (subdivisionName) {
      stateName = `${stateName} (${subdivisionName})`;
    }

    const countryName = `${country ? `${stateName && ', '}${country}` : ''}`;
    const cityName = `${code ? ` (${code})` : ''}`;

    return `${stateName}${countryName}${cityName}`;
  }

  const countryName = `${country ? `${country}` : ''}`;
  const stateName = `${state ? `${countryName && ', '}${state}` : ''}`;
  const cityName = `${city ? `${(countryName || stateName) && ', '}${city}` : ''}`;

  return `${countryName}${stateName}${cityName}`;
};
