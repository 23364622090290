export enum EPageSortGeneral {
  DEPARTURE = 'DEPARTURE',
  ARRIVAL = 'ARRIVAL',
  CREATED_AT = 'CREATED_AT',
}

export enum EPageDirectionGeneral {
  ASC = 'ASC',
  DESC = 'DESC'
}

export interface IPageRequestGeneral {
  page?: number
  size?: number
  sort?: EPageSortGeneral | string
  direction?: EPageDirectionGeneral
}
