import styled from 'styled-components';
import Typography from 'antd/es/typography';
import { InfoCircleOutlined } from '@ant-design/icons';

import { WarningBlock } from 'app-wrapper/view/components';

export const WarningWrap = styled.div.attrs({
  'data-class': 'RollWarningBlockComponent',
})`
  min-height: fit-content;

  & > div {
    padding-bottom: 12px;
  }
`;

export const Warning = styled(WarningBlock)`
  min-height: fit-content;
`;

export const Wrap = styled.div`
  margin-top: 4px;
  display: flex;
  flex-direction: row;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;

  &:last-of-type {
    margin-left: 24px;
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Text = styled(Typography)`
  font-size: 14px;
  line-height: 22px;
`;

export const TextGray = styled(Text)`
  margin-left: 8px;
  color: rgba(115, 129, 155, 1);
`;

export const Icon = styled.img`
  margin: 0 8px;
  width: 5px;
`;

export const NewStub = styled.div`
  margin-left: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 22px;
  border-radius: 2px;
  min-width: 40px;
  color: rgba(255, 255, 255, 1);
  background-color: rgba(241, 174, 0, 1);
  font-size: 12px;
  line-height: 20px;
`;

export const Info = styled.div`
  border-top: 1px dashed rgba(222, 225, 233, 1);
  margin-top: 8px;
  padding-top: 8px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const InfoIcon = styled(InfoCircleOutlined)`
  margin-right: 8px;
  color: rgba(115, 129, 155, 1);
  font-size: 14px;
`;

export const InfoText = styled(Typography)`
  font-size: 14px;
  line-height: 22px;
  color: rgba(115, 129, 155, 1);
`;
