import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { UC } from 'app-wrapper/controllers';
import { R } from 'app-wrapper/repository';

import { CommandCenterNotificationComponent } from './CommandCenterNotification.component';

const CommandCenterNotificationContainer: FC = () => {
  const getCommandCenterCurrentAllNotifications = useSelector(R.selectors.commandCenter.getCommandCenterCurrentAllNotifications);

  const getCommandCenterNotificationCurrentStatus = useSelector(R.selectors.commandCenter.getCommandCenterNotificationCurrentStatus);
  const getCommandCenterNotificationCurrentStatusAdd = useSelector(R.selectors.commandCenter.getCommandCenterNotificationCurrentStatusAdd);
  const getCommandCenterNotificationCurrent = useSelector(R.selectors.commandCenter.getCommandCenterNotificationCurrent);
  const getCommandCenterNotificationCurrentIsClearAllFilters = useSelector(R.selectors.commandCenter.getCommandCenterNotificationCurrentIsClearAllFilters);

  return (
    <CommandCenterNotificationComponent
      onResetTaskPage={UC.CommandCenter.onResetTaskPage}
      onStartNotifications={UC.CommandCenter.onStartNotificationsPage()}
      nextPageNotifications={UC.CommandCenter.nextPageNotifications()}
      onHoverNotificationItem={UC.CommandCenter.onHoverNotificationItem}
      onUnviewedNotificationItem={UC.CommandCenter.onUnviewedNotificationItem}
      onAllNotificationMakeViews={UC.CommandCenter.onAllNotificationMakeViews()}
      onChangeFilterShowOnlyUnread={UC.CommandCenter.onChangeFilterShowOnlyUnread()}
      onApplyPriority={UC.CommandCenter.onApplyPriority()}
      onResetPriority={UC.CommandCenter.onResetPriority()}
      onChangeGroupPriority={UC.CommandCenter.onChangeGroupPriority()}
      onApplyReceivedDate={UC.CommandCenter.onApplyFilterReceivedDates()}
      onResetReceivedDate={UC.CommandCenter.onResetFilterReceivedDates()}
      dateOnReceivedDateChange={UC.CommandCenter.onChangeFilterReceivedDates()}
      onApplyModule={UC.CommandCenter.onApplyModule()}
      onResetModule={UC.CommandCenter.onResetModule()}
      onChangeGroupModule={UC.CommandCenter.onChangeGroupModule()}
      onClearAllFilters={UC.CommandCenter.onClearAllFilters()}
      isLoadNotification={getCommandCenterNotificationCurrentStatus}
      isLoadNotificationAdd={getCommandCenterNotificationCurrentStatusAdd}
      allNotifications={getCommandCenterCurrentAllNotifications}
      infoCountUnread={getCommandCenterNotificationCurrent?.countUnreadNotifications}
      infoCountImportant={getCommandCenterNotificationCurrent?.countImportantNotifications}
      infoCountRegular={getCommandCenterNotificationCurrent?.countRegularNotifications}
      filters={getCommandCenterNotificationCurrent?.filters}
      isVisibleClearAll={getCommandCenterNotificationCurrentIsClearAllFilters}
    />
  );
};

export { CommandCenterNotificationContainer as CommandCenterNotification };
