import React from 'react';
import { useSelector } from 'react-redux';

import { UC } from 'shipment-operations/controllers';
import { R } from 'shipment-operations/repository';

import { HeaderComponent } from './Header.component';

const HeaderContainer = () => {
  const errors = useSelector(R.selectors.addRates.getHeaderErrors);

  return (
    <HeaderComponent
      onChangeHeader={UC.addRates.onChangeHeader}
      errors={errors}
    />
  );
};

export { HeaderContainer as Header };
