import React, {
  FC,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'antd/es/modal';
import notification from 'antd/es/notification';

import {
  Button,
  Option,
  Select,
  Input,
} from 'app-wrapper/view/components';
import { hasAccess } from 'app-wrapper/utils';
import { AccountDepartmentDTM } from 'user-management/models/dtm';
import { DEPARTMENT_TYPE_NAMES, ROLES_OPTIONS } from 'user-management/constants';
import { PermissionAttributePolicy } from 'shipment-operations/constants';

import {
  BtnWrapper,
  Field,
  Footer,
  Name,
  Wrapper,
} from './UserManagementEdit.styled';

interface IUserManagementEditComponentProps {
  selectedRole: string
  onChangeRole: (role: string) => void
  onClose: () => void
  onEdit: () => void
  updateLoading: boolean
  updateError: boolean
  onCloseError: () => void
  deleteLoading: boolean
  deleteError: boolean
  onCloseDeleteError: () => void
  onDelete: () => void
  departments: string[]
  setDepartments: (departments: string[]) => void
  departmentsError: boolean
  jobTitle: string
  setJobTitle: (jobTitle: string) => void
  availableDepartments: AccountDepartmentDTM[]
  userDepartmentsAndTeamsEditPermissions?: PermissionAttributePolicy
}

const UserManagementEditComponent: FC<IUserManagementEditComponentProps> = ({
  selectedRole,
  onChangeRole,
  onClose,
  onEdit,
  updateLoading,
  updateError,
  onCloseError,
  deleteLoading,
  deleteError,
  onCloseDeleteError,
  onDelete,
  departmentsError,
  departments,
  setDepartments,
  availableDepartments,
  jobTitle,
  setJobTitle,
  userDepartmentsAndTeamsEditPermissions,
}) => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (updateError) {
      notification.error({
        message: t('basicErrors.REQUEST_COMMON.title'),
        description: t('basicErrors.REQUEST_COMMON.subTitle'),
        placement: 'bottomRight',
        bottom: 60,
        onClose: onCloseError,
      });
    }
  }, [updateError]);

  useEffect(() => {
    if (deleteError) {
      notification.error({
        message: t('basicErrors.REQUEST_COMMON.title'),
        description: t('basicErrors.REQUEST_COMMON.subTitle'),
        placement: 'bottomRight',
        bottom: 60,
        onClose: onCloseDeleteError,
      });
      setIsModalOpen(false);
    }
  }, [deleteError]);

  const handleJobTitleChange = useCallback((e) => {
    setJobTitle(e.target.value);
  }, [setJobTitle]);

  const isAbleToEditDepartmentsAndTeams = hasAccess(userDepartmentsAndTeamsEditPermissions, PermissionAttributePolicy.WRITE);

  return (
    <Wrapper>
      <Name>{t('role')}</Name>
      <Select
        options={ROLES_OPTIONS}
        value={selectedRole}
        onChange={onChangeRole}
      />

      <Field>
        <Name>
          {t('Job Title')}
        </Name>

        <Input
          value={jobTitle}
          onChange={handleJobTitleChange}
        />
      </Field>

      {isAbleToEditDepartmentsAndTeams ? (
        <>
          <Field>
            <Name>
              {t('Departments')}
            </Name>

            <Select
              mode="multiple"
              value={departments}
              onChange={setDepartments}
              error={departmentsError}
              allowClear
            >
              {availableDepartments.map((department) => (
                <Option key={String(department?.id)} value={String(department?.id)}>
                  {DEPARTMENT_TYPE_NAMES[department.type]}
                </Option>
              ))}
            </Select>
          </Field>
        </>
      ) : null}

      <Footer>
        <Button type="ghost" danger onClick={() => setIsModalOpen(true)}>
          Remove
        </Button>
        <BtnWrapper>
          <Button style={{ marginRight: '10px' }} type="default" onClick={onClose}>
            Cancel
          </Button>
          <Button onClick={onEdit} loading={updateLoading}>
            Save
          </Button>
        </BtnWrapper>
      </Footer>

      <Modal
        onCancel={() => setIsModalOpen(false)}
        visible={isModalOpen}
        okText={t('yes')}
        cancelText={t('no')}
        onOk={onDelete}
        okButtonProps={{ loading: deleteLoading }}
      >
        <p>{t('Are you sure you want to delete this user')}</p>
      </Modal>
    </Wrapper>
  );
};

export { UserManagementEditComponent };
