import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'app-wrapper/createSlice';

import {
  OrganizationMemberDTM,
  AccountDepartmentDTM,
  TeamDTM,
} from 'user-management/models/dtm';
import { TUserManagementState } from 'user-management/models/states';

export const initialState: TUserManagementState = {
  usersList: [],
  isLoading: false,
  selectedMemberId: '',
  selectedMemberRole: '',
  updateLoading: false,
  updateSuccess: false,
  updateError: false,
  deleteLoading: false,
  deleteSuccess: false,
  deleteError: false,
  userProfile: undefined,
  userOrgId: undefined,
  teams: [],
  departments: [],
  availableDepartments: [],
  availableTeams: [],
  departmentsError: false,
  jobTitle: '',
};

export const UserManagementStore = createSlice({
  name: 'UserManagement',
  initialState,
  reducers: {
    setUserList: (state, { payload }: PayloadAction<OrganizationMemberDTM[]>) => ({
      ...state,
      usersList: payload,
    }),
    setIsLoading: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      isLoading: payload,
    }),
    setSelectedMemberId: (state, { payload }) => ({
      ...state,
      selectedMemberId: payload,
    }),
    setSelectedMemberRole: (state, { payload }) => ({
      ...state,
      selectedMemberRole: payload,
    }),
    setActionValue: (state, { payload }) => ({
      ...state,
      [payload.state]: payload.value,
    }),
    setUserProfile: (state, { payload }: PayloadAction<OrganizationMemberDTM | undefined>) => ({
      ...state,
      userProfile: payload,
    }),
    setUserOrgId: (state, { payload }: PayloadAction<number| undefined>) => ({
      ...state,
      userOrgId: payload,
    }),
    setDepartments: (state, { payload }: PayloadAction<string[]>) => ({
      ...state,
      departments: payload,
    }),
    setTeams: (state, { payload }: PayloadAction<string[]>) => ({
      ...state,
      teams: payload,
    }),
    setDepartmentsError: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      departmentsError: payload,
    }),
    setAvailableTeams: (state, { payload }: PayloadAction<TeamDTM[]>) => ({
      ...state,
      availableTeams: payload,
    }),
    setAvailableDepartments: (state, { payload }: PayloadAction<AccountDepartmentDTM[]>) => ({
      ...state,
      availableDepartments: payload,
    }),
    setJobTitle: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      jobTitle: payload,
    }),
  },
});

export const userManagementReducer = UserManagementStore.reducer;
export const userManagementActions = UserManagementStore.actions;
