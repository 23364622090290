import React from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';
import { UC } from 'shipment-operations/controllers';

import { NoCompanyAttachedComponent } from './NoCompanyAttached.component';

export const NoCompanyAttached = () => {
  const selectedShippingParty = useSelector(R.selectors.shippingParties.getSelectedFormType);
  const permissionsForRole = useSelector(R.selectors.shippingParties.getShippingPartyPermissionsByRole(selectedShippingParty));

  return (
    <NoCompanyAttachedComponent
      openSelectCompanyDrawer={UC.shippingParties.openSelectCompanyDrawer}
      isAbleToEditCompany={permissionsForRole.isAbleToEditCompany}
    />
  );
};
