import React, {
  FC,
  useMemo,
  useCallback,
  CSSProperties,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import { EOrganizationStatus } from 'user-management/models/dtm';
import { RouteNames } from 'app-wrapper/constants';

import {
  Wrap,
  Content,
  Image,
  Title,
  Text,
  Step,
  StepName,
  ArrowRight,
  CompletedIcon,
  WaitingIcon,
  RegisterButton,
} from './RegisterAccountStep.styled';
import arrowRightSrc from './assets/arrow-right.png';
import illustrationSrc from './assets/Illustration.png';

interface RegisterAccountStepComponentProps {
  status?: EOrganizationStatus;
  hasRequiredDocuments?: boolean;
}

export const RegisterAccountStepComponent: FC<RegisterAccountStepComponentProps> = ({ status, hasRequiredDocuments }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const isGoToOrgDisabled = useMemo(() => status && status !== EOrganizationStatus.DRAFT, [status]);

  const goToWizardFirstStep = useCallback(() => {
    navigate(RouteNames.NEW_ORGANIZATION_WIZARD());
  }, [navigate]);

  const goToWizardDocumentsStep = useCallback(() => {
    navigate(RouteNames.SUBMIT_PROOF_DOCUMENTS_WIZARD_ITEM());
  }, [navigate]);

  const goToWizardConfirmStep = useCallback(() => {
    navigate(RouteNames.NEW_ORGANIZATION_REVIEW_AND_CONFIRM_WIZARD_ITEM());
  }, [navigate]);

  const isFirstStepCompleted = useMemo(() => status, [status]);
  const isSecondStepCompleted = useMemo(() => status === EOrganizationStatus.ACTIVE || status === EOrganizationStatus.WAITING_APPROVAL || (status === EOrganizationStatus.DRAFT && hasRequiredDocuments), [status, hasRequiredDocuments]);
  const isThirdStepCompleted = useMemo(() => status === EOrganizationStatus.ACTIVE || status === EOrganizationStatus.WAITING_APPROVAL, [status]);

  const stepStyles = useMemo<CSSProperties>(() => (isGoToOrgDisabled || isFirstStepCompleted ? {
    cursor: 'initial',
    pointerEvents: 'none',
  } : {}), [isGoToOrgDisabled, isFirstStepCompleted]);

  const secondStepStyles = useMemo<CSSProperties>(() => (!isFirstStepCompleted || isSecondStepCompleted ? {
    cursor: 'initial',
    pointerEvents: 'none',
  } : {}), [isFirstStepCompleted, isSecondStepCompleted]);
  const thirdStepStyles = useMemo<CSSProperties>(() => (!isSecondStepCompleted || isThirdStepCompleted ? {
    cursor: 'initial',
    pointerEvents: 'none',
  } : {}), [isSecondStepCompleted, isThirdStepCompleted]);

  const firstStepNameStyles = useMemo(() => (isFirstStepCompleted ? {
    color: 'rgba(115, 129, 155, 1)',
  } : {}), [isFirstStepCompleted]);
  const secondStepNameStyles = useMemo(() => (isSecondStepCompleted ? {
    color: 'rgba(115, 129, 155, 1)',
  } : {}), [isSecondStepCompleted]);
  const thirdStepNameStyles = useMemo(() => (isThirdStepCompleted ? {
    color: 'rgba(115, 129, 155, 1)',
  } : {}), [isThirdStepCompleted]);

  const handleGoToRegistration = useCallback(() => {
    if (isSecondStepCompleted) {
      goToWizardConfirmStep();

      return;
    }

    if (isFirstStepCompleted) {
      goToWizardDocumentsStep();

      return;
    }

    goToWizardFirstStep();
  }, [isFirstStepCompleted, isSecondStepCompleted, goToWizardFirstStep, goToWizardDocumentsStep, goToWizardConfirmStep]);

  return (
    <Wrap>
      <Content>
        <Title>
          {t('Register Organization Account')}
        </Title>

        <Text>
          {t('Add your organization\'s details to Skypace so we can verify your company\'s identity and enable you to take full advantage of the platform\'s capabilities.')}
        </Text>

        <Step onClick={goToWizardFirstStep} style={stepStyles}>
          {isFirstStepCompleted ? (
            <CompletedIcon />
          ) : (
            <WaitingIcon />
          )}

          <StepName style={firstStepNameStyles}>
            {t('Provide General Information')}
          </StepName>

          {!isFirstStepCompleted ? (
            <ArrowRight src={arrowRightSrc} />
          ) : null}
        </Step>

        <Step onClick={goToWizardDocumentsStep} style={secondStepStyles}>
          {isSecondStepCompleted ? (
            <CompletedIcon />
          ) : (
            <WaitingIcon />
          )}

          <StepName style={secondStepNameStyles}>
            {t('Upload Documents')}
          </StepName>

          {isFirstStepCompleted && !isSecondStepCompleted ? (
            <ArrowRight src={arrowRightSrc} />
          ) : null}
        </Step>

        <Step onClick={goToWizardConfirmStep} style={thirdStepStyles}>
          {isThirdStepCompleted ? (
            <CompletedIcon />
          ) : (
            <WaitingIcon />
          )}

          <StepName style={thirdStepNameStyles}>
            {t('Agree with Terms & Conditions')}
          </StepName>

          {isSecondStepCompleted && !isThirdStepCompleted ? (
            <ArrowRight src={arrowRightSrc} />
          ) : null}
        </Step>

        <RegisterButton onClick={handleGoToRegistration} disabled={isGoToOrgDisabled}>
          {isFirstStepCompleted ? t('Complete Your Registration') : t('Register Organization')}
        </RegisterButton>
      </Content>

      <Image src={illustrationSrc} />
    </Wrap>
  );
};
