import styled from 'styled-components';

export const Wrap = styled.div`
  width: 100%;
`;

export const ContentWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #FFFFFF;
`;

export const SpinnerWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 600px
`;
