import React, { FC, useEffect } from 'react';
import { useParams } from 'react-router';
import Spin from 'antd/es/spin';

import { SalesRepresentative, MarkupPolicy } from './components';
import {
  Page,
  SpinnerWrap,
  Content,
} from './CustomerSales.styled';

interface ICustomerSalesComponentProps {
  isLoading: boolean;
  init: (customerId: string) => void;
}

export const CustomerSalesComponent: FC<ICustomerSalesComponentProps> = ({ isLoading, init }) => {
  const { customerId = '' } = useParams();

  useEffect(() => {
    init(customerId);
  }, []);

  return (
    <Page>
      {isLoading ? (
        <SpinnerWrap>
          <Spin size="large" />
        </SpinnerWrap>
      ) : (
        <Content>
          <SalesRepresentative />
          <MarkupPolicy />
        </Content>
      )}
    </Page>
  );
};
