import React, { FC } from 'react';
import isEmpty from 'lodash/fp/isEmpty';
import { useTranslation } from 'react-i18next';

import { ChargeDTM } from 'shipment-operations/models/dtm';
import {
  InnerContainer,
  StyledTotal,
  Title,
} from 'shipment-operations/view/components';
import { CustomTable } from 'shipment-operations/view/pages/CreateInvoice/components/index';
import { TotalFooter } from 'app-wrapper/view/components';

interface IFeesComponentProps {
  sum: number
  data: ChargeDTM[]
}

const FeesComponent: FC<IFeesComponentProps> = ({
  sum, data,
}) => {
  const { t } = useTranslation();

  return (!isEmpty(data) ? (
    <>
      <InnerContainer>
        <Title>{t('fees')}</Title>
        <CustomTable data={data} fullTable />
      </InnerContainer>

      <TotalFooter>
        <StyledTotal>{t('total')}</StyledTotal>

        <StyledTotal>$ {sum.toFixed(2)}</StyledTotal>
      </TotalFooter>
    </>
  ) : null);
};

export { FeesComponent };
