import styled from 'styled-components';

import { FlexRow, FlexCol } from 'app-wrapper/view/guideline';

export const Form = styled(FlexRow)`
  align-items: flex-start;
`;

export const Steps = styled(FlexCol)`
  width: 30%;
  border-right: 1px solid ${({ theme }) => theme.themesColors.neutralBranding4};
`;

export const StepForm = styled(FlexCol)`
  width: 70%;
  margin-left: -1px;
  padding: 22px 32px 32px 32px;
  border-left: 1px solid ${({ theme }) => theme.themesColors.neutralBranding4};
`;
