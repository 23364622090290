import React, { forwardRef } from 'react';
import { RangePickerProps } from 'antd/es/date-picker';

import { RangePicker } from './RangePicker.styled';

// TODO: fix ref types
const RangePickerComponent = forwardRef<any, RangePickerProps>((
  props, ref,
) => (
  <RangePicker
    className="RangePicker"
    ref={ref}
    {...props}
  />
));

export { RangePickerComponent as RangePicker };
