import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { ShipmentDetailsLayout } from 'shipment-operations/view/components';
import { SubPageHeaderTitle } from 'app-wrapper/view/guideline';
import { AccountDrawer } from 'user-management/view/pages/Account/components';
import { useBlocker } from 'app-wrapper/hooks';

import {
  ShippingPartiesList,
  ShippingPartiesForm,
  NavigationBar,
  ChangeCompanyDrawer,
} from './components';

type IRouteParams = {
  shipmentId: string
  shipmentPartyId: string
}

interface IShipmentShippingPartiesComponentProps {
  isFormsHaveChanges: boolean
  uploadData: (shipmentId: string) => void
  discardChanges: () => void
  clearPageState: () => void
}

export const ShipmentShippingPartiesComponent: FC<IShipmentShippingPartiesComponentProps> = ({
  isFormsHaveChanges,
  uploadData,
  discardChanges,
  clearPageState,
}) => {
  const { t } = useTranslation();
  const { shipmentId } = useParams<IRouteParams>();

  useEffect(() => {
    if (!shipmentId) {
      return clearPageState;
    }

    uploadData(shipmentId);

    return clearPageState;
  }, []);

  useBlocker(
    discardChanges,
    () => {},
    isFormsHaveChanges,
  );

  return (
    <ShipmentDetailsLayout
      title={<SubPageHeaderTitle>{t('Shipping Parties')}</SubPageHeaderTitle>}
      navigationBar={<NavigationBar />}
    >
      <AccountDrawer />
      <ChangeCompanyDrawer />

      <ShippingPartiesList />
      <ShippingPartiesForm />
    </ShipmentDetailsLayout>
  );
};
