import React, { useCallback, FC } from 'react';
import { useTranslation } from 'react-i18next';
import Divider from 'antd/es/divider';

import {
  CREATE_AP_INVOICE,
  US_BANK_INFORMATION,
  UK_BANK_INFORMATION,
} from 'shipment-operations/constants';
import {
  Wrapper, BilledWrapper, PaymentDetailsWrapper, Title, PaymentDataWrapper, PaymentLabel,
  PaymentData, PaymentValue, CopyIcon, Row, BoldValue,
} from 'shipment-operations/view/pages/CreateInvoice/components/Footer/Footer.styled';

interface IFooterProps {
  sum: number;
  modalOwner: string;
  isAdminFromUS: boolean;
}

const FooterComponent: FC<IFooterProps> = ({
  sum,
  modalOwner,
  isAdminFromUS,
}) => {
  const { t } = useTranslation();

  const onCopyAccountNumber = useCallback(() => navigator.clipboard.writeText(isAdminFromUS ? US_BANK_INFORMATION.accountNumber : UK_BANK_INFORMATION.accountNumber), [isAdminFromUS]);
  const onCopyAccountRouting = useCallback(() => navigator.clipboard.writeText(isAdminFromUS ? US_BANK_INFORMATION.routingNumber : UK_BANK_INFORMATION.routingNumber), [isAdminFromUS]);
  const onCopyBeneficiaryBank = useCallback(() => navigator.clipboard.writeText(isAdminFromUS ? US_BANK_INFORMATION.beneficiaryBank : UK_BANK_INFORMATION.beneficiaryBank), [isAdminFromUS]);

  return (
    <Wrapper>
      <PaymentDetailsWrapper>
        {modalOwner !== CREATE_AP_INVOICE && (
        <>
          <Title>{t('paymentDetails')}</Title>
          <PaymentDataWrapper>
            <PaymentData>
              <PaymentLabel>{t('accountNumber')}</PaymentLabel>
              <div>
                <PaymentValue>{isAdminFromUS ? US_BANK_INFORMATION.accountNumber : UK_BANK_INFORMATION.accountNumber}</PaymentValue>
                <CopyIcon onClick={onCopyAccountNumber} />
              </div>
            </PaymentData>

            <PaymentData>
              <PaymentLabel>{t('Beneficiary Bank')}</PaymentLabel>
              <div>
                <PaymentValue>{isAdminFromUS ? US_BANK_INFORMATION.beneficiaryBank : UK_BANK_INFORMATION.beneficiaryBank}</PaymentValue>
                <CopyIcon onClick={onCopyBeneficiaryBank} />
              </div>
            </PaymentData>

            <PaymentData>
              <PaymentLabel>{t('routingNumber')}</PaymentLabel>
              <div>
                <PaymentValue>{isAdminFromUS ? US_BANK_INFORMATION.routingNumber : UK_BANK_INFORMATION.routingNumber}</PaymentValue>
                <CopyIcon onClick={onCopyAccountRouting} />
              </div>
            </PaymentData>

            <PaymentData>
              <PaymentLabel>{t('SWIFT/BIC')}</PaymentLabel>
              <PaymentValue>{isAdminFromUS ? US_BANK_INFORMATION.swiftOrBIC : UK_BANK_INFORMATION.swiftOrBIC}</PaymentValue>
            </PaymentData>
          </PaymentDataWrapper>
          <Divider dashed style={{ margin: '12px 0' }} />
        </>
        )}
      </PaymentDetailsWrapper>
      <BilledWrapper>
        <Row>
          <Title>{t('totalFooter')}</Title>
          <BoldValue>$ {sum.toFixed(2)}</BoldValue>
        </Row>
        <Divider style={{ margin: '10px 0' }} />

        <Row>
          <Title>{t('footerBalance')}</Title>
          <BoldValue>$ {sum.toFixed(2)}</BoldValue>
        </Row>
      </BilledWrapper>
    </Wrapper>
  );
};

export { FooterComponent };
