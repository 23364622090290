import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';

import { InvoicePDFBankDetailsComponent } from './InvoicePDFBankDetails.component';

const InvoicePDFBankDetailsContainer: FC = () => {
  const invoiceNumber = useSelector(R.selectors.shareInvoice.getInvoiceNumber);
  const currentOrgRelatedAdmin = useSelector(R.selectors.shareInvoice.getCurrentOrgRelatedAdmin);

  return (
    <InvoicePDFBankDetailsComponent
      relatedAdmin={currentOrgRelatedAdmin}
      invoiceNumber={invoiceNumber}
    />
  );
};

export { InvoicePDFBankDetailsContainer as InvoicePDFBankDetails };
