import React, {
  ChangeEvent,
  FC,
  useCallback,
} from 'react';
import { useTranslation } from 'react-i18next';

import { Tooltip } from 'app-wrapper/view/components';

import { InputStyled } from './UnNumberInput.styled';

interface UnNumberInputComponentProps {
  cargoId: number;
  unNumber: string;
  touchCargoField: (field: string, cargoId: number) => void
  setUnNumber: (unNumber: string, cargoId: number) => void
  error?: boolean;
  showTooltip: boolean;
}

export const UnNumberInputComponent: FC<UnNumberInputComponentProps> = ({
  cargoId,
  unNumber,
  touchCargoField,
  setUnNumber,
  error,
  showTooltip,
}) => {
  const { t } = useTranslation();

  const handleChangeUnNumber = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setUnNumber(event.target.value, cargoId);
  }, [unNumber, cargoId]);

  const handleBlur = useCallback(() => {
    touchCargoField('unNumber', cargoId);
  }, [touchCargoField, cargoId]);

  return (
    <Tooltip mode="danger" visible={showTooltip} title={t('UN Number length should be equal to 4')}>
      <InputStyled
        placeholder={t('UN Number')}
        value={unNumber}
        onChange={handleChangeUnNumber}
        onBlur={handleBlur}
        error={error}
      />
    </Tooltip>
  );
};
