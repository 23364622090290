import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';

import { CommodityRowComponent } from './CommodityRow.component';

interface CommodityRowContainerProps {
  cargoId: number;
}

const CommodityRowContainer: FC<CommodityRowContainerProps> = ({ cargoId }) => {
  const { msdsDocument } = useSelector(R.selectors.bookingWizard.getCargoById(cargoId));
  const shouldHaveHazmats = useSelector(R.selectors.bookingWizard.getShouldHaveAnyHazmats);
  const isHazmatToggled = useSelector(R.selectors.bookingWizard.getIsHazmatToggledByCargoId(cargoId));

  return (
    <CommodityRowComponent
      hasHazmat={shouldHaveHazmats}
      cargoId={cargoId}
      hazmatToggled={isHazmatToggled}
      hasMSDSDocument={!!msdsDocument.length}
    />
  );
};

export { CommodityRowContainer as CommodityRow };
