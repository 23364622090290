import { createSlice } from 'app-wrapper/createSlice';

import { IShipmentAccessorialState } from 'shipment-operations/models/states';

const initialState: IShipmentAccessorialState = {
  isLoading: false,
  error: false,
  data: [],
};

export const ShipmentAccessorialSlice = createSlice({
  name: 'shipmentAccessorial',
  initialState,
  reducers: {
    setIsLoading: (state, { payload }) => ({
      ...state,
      isLoading: payload,
    }),
    setError: (state, { payload }) => ({
      ...state,
      isLoading: false,
      error: payload,
    }),
    setData: (state, { payload }) => ({
      ...state,
      isLoading: false,
      error: false,
      data: payload,
    }),
  },
});

export const shipmentAccessorialReducer = ShipmentAccessorialSlice.reducer;
export const shipmentAccessorialActions = ShipmentAccessorialSlice.actions;
