import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { UC } from 'shipment-operations/controllers';
import { R } from 'shipment-operations/repository';

import { FooterComponent } from 'shipment-operations/view/components/ControlChargeTransportation';

const FooterContainer = () => {
  const { t } = useTranslation();
  const isPending = useSelector(R.selectors.billOfLadingCommon.getIsStatePending);

  return (
    <FooterComponent
      onCancel={UC.shipmentDrawer.closeDrawer}
      onAction={UC.billOfLading.sendBillOflading}
      isLoading={isPending}
      actionName={t('Add')}
    />
  );
};

export { FooterContainer as Footer };
