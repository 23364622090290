import {
  IsDefined, IsEnum, IsNumber, IsOptional, IsString, ValidateNested,
} from 'class-validator';
import { Type } from 'class-transformer';
import { BaseDTM } from 'proto/BaseDTM';

import { PaymentsStatusesEnum } from 'shipment-operations/constants';
import { AccountDTM, IAccountDTM } from 'monetary/models/dtm/Account.dtm';

export interface IPaymentDocumentDTM {
  id: number;
  name: string;
}

export class PaymentDocumentDTM extends BaseDTM<IPaymentDocumentDTM> {
  @IsDefined()
  @IsNumber()
  id: number

  @IsDefined()
  @IsString()
  name: string
}

export interface ICreatePaymentResponseDTM {
  type: string
  id: number
  number: string
  status: string
  createdBy: string
  paid: number
  balance: number
  createdAt: string
  amount: number
}

export class CreatePaymentResponseDTM extends BaseDTM<ICreatePaymentResponseDTM> {
  @IsDefined()
  @IsString()
  type: string

  @IsDefined()
  @IsNumber()
  id: number

  @IsDefined()
  @IsString()
  number: string

  @IsDefined()
  @IsString()
  status: string

  @IsDefined()
  @IsString()
  createdBy: string

  @IsDefined()
  @IsNumber()
  paid: number

  @IsDefined()
  @IsNumber()
  balance: number

  @IsDefined()
  @IsString()
  createdAt: string

  @IsDefined()
  @IsNumber()
  amount: number
}

export interface ICreatePaymentBodyDTM {
  createdAt?: string
  reference: string | null
  type: string
  amount: number
  documents: IPaymentDocumentDTM[];
}

export class CreatePaymentBodyDTM extends BaseDTM<ICreatePaymentBodyDTM> {
  @IsOptional()
  @IsString()
  createdAt?: string

  @IsOptional()
  @IsString()
  reference: string | null

  @IsDefined()
  @IsString()
  type: string

  @IsDefined()
  @IsNumber()
  amount: number

  @IsDefined()
  @Type(() => PaymentDocumentDTM)
  documents: PaymentDocumentDTM[]
}

export interface ISourceTransactionDTM {
  type: string
  id: number
}

export class SourceTransactionDTM extends BaseDTM<ISourceTransactionDTM> {
  @IsDefined()
  @IsString()
  type: string

  @IsDefined()
  @IsNumber()
  id: number
}

export interface ICreateTransactionBodyDTM {
  amount: number
  createdAt: string
  source: ISourceTransactionDTM
}

export class CreateTransactionBodyDTM extends BaseDTM<ICreateTransactionBodyDTM> {
  @IsDefined()
  @IsNumber()
  amount: number

  @IsDefined()
  @IsString()
  createdAt: string

  @IsDefined()
  @ValidateNested()
  @Type(() => SourceTransactionDTM)
  source: SourceTransactionDTM
}

interface IShipmentDTM {
  id: number
  organizationId: number
}

class ShipmentDTM extends BaseDTM<IShipmentDTM> {
  @IsDefined()
  @IsNumber()
  id: number

  @IsDefined()
  @IsNumber()
  organizationId: number
}

interface IDestinationDTM {
  id: number
  type: string
  shipment: IShipmentDTM
}

class DestinationDTM extends BaseDTM<IDestinationDTM> {
  @IsDefined()
  @IsNumber()
  id: number

  @IsDefined()
  @IsString()
  type: string

  @IsDefined()
  @ValidateNested()
  @Type(() => ShipmentDTM)
  shipment: ShipmentDTM
}

export interface ITransactionsDTM {
  id: number
  createdAt: string
  createdBy: string
  amount: number
  destination: IDestinationDTM
}

class TransactionsDTM extends BaseDTM<ITransactionsDTM> {
  @IsDefined()
  @IsNumber()
  id: number

  @IsDefined()
  @IsString()
  createdAt: string

  @IsDefined()
  @IsString()
  createdBy: string

  @IsDefined()
  @IsNumber()
  amount: number

  @IsDefined()
  @ValidateNested()
  @Type(() => DestinationDTM)
  destination: DestinationDTM
}

export interface IGetPaymentResponseDTM {
  type?: string
  id: number
  number: string
  status: PaymentsStatusesEnum
  createdBy: string
  paid: number
  balance: number
  createdAt: string
  reference?: string
  amount: number
  transactions: ITransactionsDTM[]
  debtor?: IAccountDTM
  creditor?: IAccountDTM
  documents: IPaymentDocumentDTM[]
}

export class GetPaymentResponseDTM extends BaseDTM<IGetPaymentResponseDTM> {
  @IsOptional()
  @IsString()
  type?: string

  @IsDefined()
  @IsNumber()
  id: number

  @IsDefined()
  @IsString()
  number: string

  @IsEnum(PaymentsStatusesEnum)
  status: PaymentsStatusesEnum

  @IsDefined()
  @IsString()
  createdBy: string

  @IsDefined()
  @IsNumber()
  paid: number

  @IsDefined()
  @IsNumber()
  balance: number

  @IsDefined()
  @IsString()
  createdAt: string

  @IsOptional()
  @IsString()
  reference?: string

  @IsDefined()
  @IsNumber()
  amount: number

  @IsDefined()
  @ValidateNested({ each: true })
  @Type(() => TransactionsDTM)
  transactions: TransactionsDTM[]

  @IsOptional()
  @ValidateNested()
  @Type(() => AccountDTM)
  debtor?: AccountDTM

  @IsOptional()
  @ValidateNested()
  @Type(() => AccountDTM)
  creditor?: AccountDTM

  @IsDefined()
  @ValidateNested()
  @Type(() => PaymentDocumentDTM)
  documents: PaymentDocumentDTM[]
}
