import React, {
  FC,
  memo,
  useCallback,
  useState,
  useMemo,
  ChangeEvent,
  useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';

import { VerticalFormItemSvg } from 'app-wrapper/view/icons';
import themesColors from 'app-wrapper/view/themes/themesColors';
import { useOnclickOutsideHook } from 'app-wrapper/hooks';
import { IAppFiltersCheckboxGroupDTM, AppFiltersCheckboxGroupDTM } from 'app-wrapper/models/dtm';

import {
  FCEWrapper,
  FCEHeader,
  FCEHeaderUpdateDot,
  FCEHeaderName,
  FCEModal,
  FCEModalWrapper,
  FCEModalCheckbox,
  FCEModalCheckboxGroup,
  FCEModalCheckboxGroupItem,
  FCEModalButtons,
  FCEModalButtonReset,
  FCEModalButtonApply,
  FCEHeaderArrow,
  FCEHeaderNameWrapper, FCEHeaderAmount, FCEHeaderSubName,
  FCEModalSearch,
} from './FilterCheckboxElement.styled';
import { InputSearch } from '../InputSearch/InputSearch.styled';

interface IFilterCheckboxElementProps {
  name: string
  subName?: string
  group: IAppFiltersCheckboxGroupDTM[]
  disabledModal?: boolean
  disabledReset: boolean
  isRight?: boolean
  isVisibleSearch?: boolean
  isBigHeight?: boolean
  onApply: () => void
  onReset: () => void
  onChangeGroup: (id: number, value: AppFiltersCheckboxGroupDTM) => void
}

const FilterCheckboxElement: FC<IFilterCheckboxElementProps> = (props) => {
  const {
    name,
    subName,
    group,
    disabledModal,
    disabledReset,
    isRight,
    isVisibleSearch,
    isBigHeight,
    onApply,
    onReset,
    onChangeGroup,
  } = props;
  const { t } = useTranslation();
  const [isActive, setIsActive] = useState(false);
  const [valueSearch, setValueSearch] = useState('');
  const [groupSearch, setGroupSearch] = useState<IAppFiltersCheckboxGroupDTM[]>([]);

  const chosenFiltersAmount = useMemo(() => group.filter(({ checked }) => checked).length, [group]);

  const handleClickOutside = useCallback(
    () => {
      setIsActive((prev) => {
        if (prev) {
          onApply();
        }

        return false;
      });
    },
    [setIsActive, onApply],
  );
  const refWrapper = useOnclickOutsideHook(handleClickOutside);

  const onClickWrapper = useCallback(
    () => {
      if (!disabledModal) {
        setIsActive((prev) => !prev);
      }
    }, [disabledModal, setIsActive],
  );

  const onChangeGroupHandler = (id: number) => (checkedValues: CheckboxChangeEvent) => {
    onChangeGroup(id, AppFiltersCheckboxGroupDTM.fromPlain({
      name: (checkedValues.target as { 'data-name'?: string })?.['data-name'] || '',
      customId: (checkedValues.target as { 'data-customId'?: string })?.['data-customId'] || '',
      value: checkedValues.target?.value || '',
      checked: checkedValues.target?.checked || false,
    }));
  };

  const onApplyHandler = useCallback(
    () => {
      setIsActive(false);

      onApply();
    },
    [onApply, disabledReset],
  );

  const onResetHandler = useCallback(() => {
    setIsActive(false);

    onReset();
  }, [onReset]);

  const valueSearchHandle = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setValueSearch(event.target.value);
  }, []);

  useEffect(() => {
    if (valueSearch?.trim()) {
      const newGroup = group.filter((item) => item.name.toLowerCase().indexOf(valueSearch?.trim().toLowerCase()) !== -1).map((item) => ({ ...item }));

      setGroupSearch(newGroup);
    } else {
      setGroupSearch(group);
    }
  }, [valueSearch, group]);
  return (
    <FCEWrapper ref={refWrapper}>
      <FCEHeader>
        <FCEHeaderNameWrapper
          disabledModal={disabledModal}
          onClick={onClickWrapper}
        >
          <FCEHeaderUpdateDot isVisible={!disabledReset} />

          <FCEHeaderName
            disabledModal={disabledModal}
            isActive={isActive}
          >
            {name}
            {subName
              ? (<FCEHeaderSubName>{`(${subName})`}</FCEHeaderSubName>)
              : null}

            <FCEHeaderAmount>
              {(chosenFiltersAmount ? `(${chosenFiltersAmount})` : null)}
            </FCEHeaderAmount>
          </FCEHeaderName>
          <FCEHeaderArrow
            isActive={isActive}
          >
            <VerticalFormItemSvg
              style={{
                color: isActive ? themesColors?.primaryBranding6 : themesColors?.neutralBranding7,
              }}
              rotate={isActive ? 180 : 0}
            />
          </FCEHeaderArrow>
        </FCEHeaderNameWrapper>
        {isActive && (
          <FCEModal isRight={isRight}>
            <FCEModalWrapper isRight={isRight} isBigHeight={isBigHeight}>

              {isVisibleSearch && (
                <FCEModalSearch>
                  <InputSearch
                    value={valueSearch}
                    onChange={valueSearchHandle}
                  />
                </FCEModalSearch>
              )}

              <FCEModalCheckbox isBigHeight={isBigHeight}>
                <FCEModalCheckboxGroup value={groupSearch.filter((checkItem) => checkItem.checked).map((checkItem) => checkItem.value)}>
                  {groupSearch.map((checkItem, checkIndex) => (
                    <FCEModalCheckboxGroupItem
                      key={`FCEModalCheckboxGroupItem_${checkItem.customId || checkIndex}`}
                      value={checkItem.value}
                      name={checkItem.name}
                      data-name={checkItem.name}
                      data-customId={checkItem.customId}
                      checked={checkItem.checked}
                      disabled={checkItem.disabled}
                      onChange={onChangeGroupHandler(checkIndex)}
                    >
                      {checkItem.name}
                    </FCEModalCheckboxGroupItem>
                  ))}
                </FCEModalCheckboxGroup>
              </FCEModalCheckbox>
              <FCEModalButtons>
                <FCEModalButtonReset
                  onClick={onResetHandler}
                  disabled={disabledReset}
                >
                  {t('reset')}
                </FCEModalButtonReset>
                <FCEModalButtonApply
                  onClick={onApplyHandler}
                >
                  {t('apply')}
                </FCEModalButtonApply>
              </FCEModalButtons>
            </FCEModalWrapper>
          </FCEModal>
        )}
      </FCEHeader>
    </FCEWrapper>
  );
};

const FilterCheckboxElementCached = memo(FilterCheckboxElement);

export { FilterCheckboxElementCached as FilterCheckboxElement };
