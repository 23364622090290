import React, { FC, memo } from 'react';

import { useSelector } from 'react-redux';
import { R } from 'shipment-operations/repository';
import {
  ShipmentContainersFormLoadPlanCounterComponent,
} from './ShipmentContainersFormLoadPlanCounter.component';

const ShipmentContainersFormLoadPlanCounterContainer: FC = memo(() => {
  const commonPackagesNumber = useSelector(R.selectors.shipmentContainers.getCommonChangedPackagesNumber);
  const commonWeight = useSelector(R.selectors.shipmentContainers.getCommonChangedWeight);
  const commonVolume = useSelector(R.selectors.shipmentContainers.getCommonChangedVolume);
  return (
    <ShipmentContainersFormLoadPlanCounterComponent
      commonPackagesNumber={commonPackagesNumber}
      commonWeight={commonWeight}
      commonVolume={commonVolume}
    />
  );
});

export {
  ShipmentContainersFormLoadPlanCounterContainer,
};
