import styled from 'styled-components';
import get from 'lodash/fp/get';
import Steps from 'antd/es/steps';

const { Step: AntdStep } = Steps;

const getStepColor = (props: Array<string>) => get(['theme', 'step', ...props]);

const getDependsColor = (props: any) => {
  if (props.status === 'wait') {
    return getStepColor(['status', 'wait'])(props);
  }
  return getStepColor(['status', 'default'])(props);
};

export const Step = styled(AntdStep)`
  .ant-steps-item-icon {
    width: 24px;
    height: 24px;
    line-height: 24px;
    font-size: 16px;
    color: ${getDependsColor};
    border: 1px solid ${getDependsColor};
    background-color: transparent;

    span {
      top: -1.5px;
    }
  }

  .ant-steps-item-wait {
    .ant-steps-item-icon {
      & > .ant-steps-icon {
      color:red;
    }
  }
  }

  .ant-steps-item-title {
    line-height: 20px;
    font-size: 12px;
    ${(props: any) => {
    if (props.status === 'wait') {
      return `color: ${getStepColor(['status', 'wait'])(props)} !important;`;
    }
    return '';
  }}
    &:after {
      top: 12px;
    }
  }

  .ant-steps-item-icon,
  .ant-steps-item-content {
    cursor: pointer;
  }
`;
