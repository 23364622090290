import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'antd/es/skeleton';

import {
  Section,
  Title,
  SectionCenteredTitle,
} from './Containers.styled';
import { ContainersTable } from './components';

interface ContainersComponentProps {
  isPending: boolean;
  hasContainers: boolean;
  containersQTY: number;
}

export const ContainersComponent: FC<ContainersComponentProps> = ({ isPending, hasContainers, containersQTY }) => {
  const { t } = useTranslation();

  const content = useMemo(() => (
    <>
      <Title>
        {t('Containers')}
      </Title>

      <ContainersTable />
    </>
  ), []);

  return isPending ? <Skeleton /> : (
    <>
      {hasContainers ? (
        <>
          {containersQTY > 1 ? (
            <Section>
              {content}
            </Section>
          ) : (
            <SectionCenteredTitle>
              {content}
            </SectionCenteredTitle>
          )}
        </>
      ) : null}
    </>
  );
};
