import React, { FC, ReactNode } from 'react';

import {
  StepNumber,
  StepWrap,
} from './NumberedStep.styled';

interface INumberedStepProps {
  stepNumber: number;
  content: ReactNode;
}

export const NumberedStep: FC<INumberedStepProps> = ({ stepNumber, content }) => (
  <StepWrap>
    <StepNumber>
      {stepNumber}
    </StepNumber>

    {content}
  </StepWrap>
);
