import React from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';
import { UC } from 'shipment-operations/controllers';

import { ModalShipmentReferenceUpdateComponent } from './ModalShipmentReferenceUpdate.component';

const ModalShipmentReferenceUpdate = () => {
  const isShowModal = useSelector(R.selectors.overview.getModalShipmentReference);
  const getShipmentReference = useSelector(R.selectors.overview.getShipmentReference);
  const getModalShipmentReferenceIsError = useSelector(R.selectors.overview.getModalShipmentReferenceIsError);

  return (
    <ModalShipmentReferenceUpdateComponent
      shipmentReference={getShipmentReference}
      isModalOpen={isShowModal}
      isErrorReference={getModalShipmentReferenceIsError}
      onClickCloseModal={UC.overview.onChangeIsOpenModalShipmentReference}
      saveShipmentReference={UC.overview.saveShipmentReference}
      onChangeShipmentReference={UC.overview.onChangeShipmentReference}
    />
  );
};

export { ModalShipmentReferenceUpdate };
