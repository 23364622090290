import React from 'react';

import { R } from 'shipment-operations/repository';
import { R as MonetaryR } from 'monetary/repository';

import { useAppSelector } from 'app-wrapper/hooks';

import { useSelector } from 'react-redux';
import { HeaderComponents } from './Header.components';

const HeaderContainer = () => {
  const postedDate = useAppSelector(R.selectors.makePayment.getPaymentViewPostedDate);
  const reference = useAppSelector(R.selectors.makePayment.getPaymentViewReference);
  const amount = useAppSelector(R.selectors.makePayment.getPaymentViewAmount);
  const isLoading = useAppSelector(R.selectors.makePayment.getIsLoadingPaymentView);
  const debtorName = useAppSelector(R.selectors.makePayment.getPaymentDebtorName);
  const creditorName = useAppSelector(R.selectors.makePayment.getPaymentCreditorName);
  const permissions = useSelector(MonetaryR.selectors.moduleFunctionalityPermissions.getShipmentChargesPermissions);

  return (
    <HeaderComponents
      postedDate={postedDate}
      reference={reference}
      amount={amount}
      isLoading={isLoading}
      creditorName={creditorName}
      debtorName={debtorName}
      permissions={permissions}
    />
  );
};

export { HeaderContainer as Header };
