import { ButtonMinus, ButtonPlus } from 'app-wrapper/view/components';
import React, { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { CargoWiseDocumentTypes, SDCFMainContentFormDocumentConst } from 'shipment-operations/constants';

import { IShipmentDocumentDefaultStateDocumentsDTM, IShipmentDocumentUpdateStateErrorsDTM } from 'shipment-operations/models/dtm';
import { ShipmentDocumentsAdditionalUpload } from '../ShipmentDocumentsAdditionalUpload';

import {
  SDCFMainContentFormDocument,
  SDCFMainContentFormDocumentFile,
  SDCFMainContentFormDocumentFileContent,
  SDCFMainContentFormDocumentFileUpload,
  SDCFMainContentFormDocumentType,
  SDCFMainContentFormDocumentTypeContent,
  SDCFMainContentFormSelect,
} from './SDCFMainContentFormDocumentComponent.styled';

interface ISDCFMainContentFormDocumentComponent {
  documentFile: IShipmentDocumentDefaultStateDocumentsDTM
  index: number
  authToken: string
  shipmentId?: string | number
  errorsDocuments: IShipmentDocumentUpdateStateErrorsDTM['documents']
  clearDocumentType: (index: number) => void
  isBlurDocumentType: (index: number) => void
  isFocusDocumentType: (index: number) => void
  isBlurDocumentFile: (index: number) => void
  isFocusDocumentFile: (index: number) => void
  onRemoveItem: (index: number, fileId?: string) => void
  updateDocumentType: (value: string, index: number) => void
  onUploadDoneHandler: (props: { value: string, name: string }, index: number) => void
  onAddItem: () => void
  disabled: boolean
}

export const SDCFMainContentFormDocumentComponentComponent: FC<ISDCFMainContentFormDocumentComponent> = ({
  documentFile,
  index,
  authToken,
  shipmentId,
  errorsDocuments,
  clearDocumentType,
  isBlurDocumentType,
  isFocusDocumentType,
  isBlurDocumentFile,
  isFocusDocumentFile,
  onRemoveItem,
  updateDocumentType,
  onUploadDoneHandler,
  onAddItem,
  disabled,
}) => {
  const { t } = useTranslation();

  const onChangeReference = useCallback(
    (value: string, name: string) => {
      onUploadDoneHandler({ value, name }, index);
    },
    [index, onUploadDoneHandler],
  );

  const hasErrorType = useMemo(() => {
    const errDocument = errorsDocuments?.[index];

    return !!(errDocument
      && errDocument?.documentType?.message
      && (errDocument?.documentType?.isBlur || errDocument?.documentType?.isVisited));
  }, [index, errorsDocuments]);

  const hasErrorFile = useMemo(() => {
    const errDocument = errorsDocuments?.[index];

    return !!(errDocument
      && errDocument?.uploadFiles?.message
      && (errDocument?.uploadFiles?.isBlur || errDocument?.uploadFiles?.isVisited)
    );
  }, [index, errorsDocuments]);

  const onClear = useCallback(
    () => {
      clearDocumentType(index);
    },
    [clearDocumentType, index],
  );

  const onBlur = useCallback(
    () => {
      isBlurDocumentType(index);
    },
    [isBlurDocumentType, index],
  );

  const onFocus = useCallback(
    () => {
      isFocusDocumentType(index);
    },
    [isFocusDocumentType, index],
  );

  const onClickButton = useCallback(
    () => {
      onRemoveItem(index);
    },
    [onRemoveItem, index],
  );

  return (
    <SDCFMainContentFormDocument
      isNext={index > 0}
      key={SDCFMainContentFormDocumentConst(index)}
    >
      <SDCFMainContentFormDocumentType>
        <SDCFMainContentFormDocumentTypeContent>
          <SDCFMainContentFormSelect
            value={documentFile.documentType}
            onChange={(value) => updateDocumentType(value, index)}
            placeholder={t('SelectDocumentType')}
            showSearch
            allowClear
            hasError={hasErrorType}
            onClear={onClear}
            optionsData={CargoWiseDocumentTypes}
            onBlur={onBlur}
            onFocus={onFocus}
            disabled={disabled}
          />
        </SDCFMainContentFormDocumentTypeContent>
      </SDCFMainContentFormDocumentType>
      <SDCFMainContentFormDocumentFile>
        <SDCFMainContentFormDocumentFileContent>
          <SDCFMainContentFormDocumentFileUpload>
            <ShipmentDocumentsAdditionalUpload
              idIndex={index}
              keyIndex={SDCFMainContentFormDocumentConst(index)}
              documentType={documentFile.documentType}
              authToken={authToken}
              shipmentId={shipmentId}
              onUploadDone={onChangeReference}
              disabledBtn={!documentFile.documentType}
              isBlurDocumentFile={isBlurDocumentFile}
              isFocusDocumentFile={isFocusDocumentFile}
              hasError={hasErrorFile}
            />
          </SDCFMainContentFormDocumentFileUpload>
          {
            index === 0
              ? (
                <ButtonPlus isDisabled={disabled} onClick={onAddItem} />
              )
              : (
                <ButtonMinus isDisabled={disabled} onClick={onClickButton} />
              )
          }
        </SDCFMainContentFormDocumentFileContent>
      </SDCFMainContentFormDocumentFile>
    </SDCFMainContentFormDocument>
  );
};
