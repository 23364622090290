import React from 'react';

import { IPropsIcon } from 'app-wrapper/interfaces/icon';

import { Icon } from './RCQNoQuoteNoSchedulesErrorSvg.styled';

export const RCQNoQuoteNoSchedulesErrorSvg = (props: IPropsIcon) => {
  const { width, height, fill } = props;
  return (
    <Icon
      className="RCQNoQuoteNoSchedulesErrorSvg"
      width={width || '144'}
      height={height || '144'}
      viewBox="0 0 144 144"
      fill={fill || 'currentColor'}
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="144" height="144" rx="72" fill="#F9F9FB" />
      <path d="M118 33H102C99.7909 33 98 34.7909 98 37L98 53C98 55.2091 99.7909 57 102 57H118C120.209 57 122 55.2091 122 53V37C122 34.7909 120.209 33 118 33Z" fill="#E9F0FD" />
      <path fillRule="evenodd" clipRule="evenodd" d="M103.974 48.4312H40.0469V42.7031C40.0469 41.4606 41.0543 40.4531 42.2969 40.4531H101.724C102.967 40.4531 103.974 41.4606 103.974 42.7031V48.4312Z" fill="#253368" />
      <path fillRule="evenodd" clipRule="evenodd" d="M101.724 103.586H42.2969C41.0543 103.586 40.0469 102.578 40.0469 101.336V47.7617H103.974V101.336C103.974 102.578 102.967 103.586 101.724 103.586Z" fill="#E4EBF7" />
      <path fillRule="evenodd" clipRule="evenodd" d="M45.2421 44.166C45.2421 44.9458 44.6098 45.578 43.8299 45.578C43.0501 45.578 42.418 44.9458 42.418 44.166C42.418 43.3862 43.0501 42.7539 43.8299 42.7539C44.6098 42.7539 45.2421 43.3862 45.2421 44.166Z" fill="#FF7946" />
      <path fillRule="evenodd" clipRule="evenodd" d="M50.5154 44.166C50.5154 44.9458 49.8833 45.578 49.1034 45.578C48.3236 45.578 47.6914 44.9458 47.6914 44.166C47.6914 43.3862 48.3236 42.7539 49.1034 42.7539C49.8833 42.7539 50.5154 43.3862 50.5154 44.166Z" fill="#FAAD14" />
      <path fillRule="evenodd" clipRule="evenodd" d="M55.7889 44.166C55.7889 44.9458 55.1567 45.578 54.3768 45.578C53.597 45.578 52.9648 44.9458 52.9648 44.166C52.9648 43.3862 53.597 42.7539 54.3768 42.7539C55.1567 42.7539 55.7889 43.3862 55.7889 44.166Z" fill="#7CB305" />
      <path fillRule="evenodd" clipRule="evenodd" d="M46.956 95.9903C46.5682 95.9903 46.2539 95.6762 46.2539 95.2888V54.6195C46.2539 54.2321 46.5682 53.918 46.956 53.918H97.7631C98.1508 53.918 98.4652 54.2321 98.4652 54.6195V95.2888C98.4652 95.6762 98.1508 95.9903 97.7631 95.9903H46.956Z" fill="white" />
      <path d="M33 101H27C25.8954 101 25 101.895 25 103L25 109C25 110.105 25.8954 111 27 111H33C34.1046 111 35 110.105 35 109V103C35 101.895 34.1046 101 33 101Z" fill="#E4EBF7" />
      <path fillRule="evenodd" clipRule="evenodd" d="M58.0269 89.107L56.8867 87.9668L66.0097 78.8438L67.1499 79.984L58.0269 89.107Z" fill="#FFD0BE" />
      <path fillRule="evenodd" clipRule="evenodd" d="M75.423 80.8492C69.7457 80.8492 65.1434 76.2468 65.1434 70.5695C65.1434 64.8922 69.7457 60.2898 75.423 60.2898C81.1003 60.2898 85.7027 64.8922 85.7027 70.5695C85.7027 76.2468 81.1003 80.8492 75.423 80.8492ZM75.423 56.8633C67.8533 56.8633 61.7168 62.9998 61.7168 70.5695C61.7168 78.1393 67.8533 84.2758 75.423 84.2758C82.9928 84.2758 89.1293 78.1393 89.1293 70.5695C89.1293 62.9998 82.9928 56.8633 75.423 56.8633Z" fill="#FFB598" />
      <path fillRule="evenodd" clipRule="evenodd" d="M68.1562 70.5716C68.1562 66.5582 71.4098 63.3047 75.4232 63.3047C79.4365 63.3047 82.6901 66.5582 82.6901 70.5716C82.6901 74.585 79.4365 77.8385 75.4232 77.8385C71.4098 77.8385 68.1562 74.585 68.1562 70.5716Z" fill="#A3B5D6" />
      <path fillRule="evenodd" clipRule="evenodd" d="M75.4238 74.6016C73.1974 74.6016 71.3926 72.7967 71.3926 70.5703C71.3926 68.3439 73.1974 66.5391 75.4238 66.5391C77.6502 66.5391 79.4551 68.3439 79.4551 70.5703C79.4551 72.7967 77.6502 74.6016 75.4238 74.6016Z" fill="white" />
      <path fillRule="evenodd" clipRule="evenodd" d="M53.6087 93.5271L52.4686 92.3869C52.1537 92.072 52.1537 91.5615 52.4686 91.2467L60.479 83.2361C60.7939 82.9213 61.3044 82.9213 61.6192 83.2361C61.6192 83.2361 61.6192 83.2361 61.6192 83.2361L62.7595 84.3764C63.0743 84.6912 63.0743 85.2017 62.7595 85.5166L54.7489 93.5271C54.4341 93.842 53.9236 93.842 53.6087 93.5271C53.6087 93.5271 53.6087 93.5271 53.6087 93.5271Z" fill="#FFB598" />
      <path fillRule="evenodd" clipRule="evenodd" d="M75.5 61.6406C70.6071 61.6406 66.6406 65.6071 66.6406 70.5C66.6406 75.3929 70.6071 79.3594 75.5 79.3594C80.3929 79.3594 84.3594 75.3929 84.3594 70.5C84.3594 65.6071 80.3929 61.6406 75.5 61.6406Z" fill="#FF7946" />
      <path fillRule="evenodd" clipRule="evenodd" d="M78.4092 73.1855L78.1855 73.4092C78.0001 73.5945 77.6996 73.5945 77.5143 73.4092L72.5921 68.4871C72.4068 68.3017 72.4068 68.0012 72.5921 67.8159L72.8159 67.5921C73.0012 67.4068 73.3017 67.4068 73.4871 67.5921L78.4092 72.5143C78.5945 72.6996 78.5945 73.0001 78.4092 73.1855Z" fill="white" />
      <path fillRule="evenodd" clipRule="evenodd" d="M72.5921 73.1855L72.8159 73.4092C73.0012 73.5945 73.3017 73.5945 73.4871 73.4092L78.4092 68.4871C78.5945 68.3017 78.5945 68.0012 78.4092 67.8159L78.1855 67.5921C78.0001 67.4068 77.6996 67.4068 77.5143 67.5921L72.5921 72.5143C72.4068 72.6996 72.4068 73.0001 72.5921 73.1855Z" fill="white" />
    </Icon>
  );
};
