import React from 'react';
import { useSelector } from 'react-redux';

import { UC } from 'shipment-operations/controllers';
import { R } from 'shipment-operations/repository';

import { EditShippingPartyDrawerComponent } from './EditShippingPartyDrawer.component';

const EditShippingPartyDrawer = () => {
  const isVisible = useSelector(R.selectors.billOfLadingCommon.getIsEditShippingPartyDrawerVisible);
  const isLoading = useSelector(R.selectors.billOfLadingCommon.getIsEditShippingPartyDrawerLoading);

  return (
    <EditShippingPartyDrawerComponent
      isVisible={isVisible}
      onClose={UC.billOfLading.closeEditShippingPartyDrawer}
      isLoading={isLoading}
    />
  );
};

const EditShippingPartyDrawerCached = React.memo(EditShippingPartyDrawer);

export { EditShippingPartyDrawerCached as EditShippingPartyDrawer };
