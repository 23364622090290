import defaultTheme from './default';

export default (themeName?: string) => {
  switch (themeName) {
    case 'dark':
      return {};
    default:
      return defaultTheme;
  }
};
