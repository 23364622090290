import React from 'react';
import { useSelector } from 'react-redux';

import { R } from 'user-management/repository';
import { UC } from 'user-management/controllers';

import { OrganizationDocumentsComponent } from './OrganizationDocuments.component';

const OrganizationDocumentsContainer = () => {
  const isLoading = useSelector(R.selectors.customer.getLoading);

  return (
    <OrganizationDocumentsComponent
      isLoading={isLoading}
      initData={UC.customer.initDataForCurrent}
    />
  );
};

export { OrganizationDocumentsContainer as OrganizationDocuments };
