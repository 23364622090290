import * as yup from 'yup';
import i18n from 'i18next';

yup.addMethod(yup.string, 'onlyInteger', function onlyInteger(translation) {
  return this.matches(/^\d+$/, {
    message: translation || i18n.t('The field should have digits only'),
    excludeEmptyString: true,
  });
});

declare module 'yup' {
  interface StringSchema {
    onlyInteger(translation?: string): StringSchema;
  }
}
