import { createSelector } from 'reselect';
import { RootState } from 'app-wrapper/store';

import { InvoicesTableDTM } from 'monetary/models/dtm/Invoices.dtm';

const localState = (state: RootState) => state.invoices;

const getIsLoading = createSelector(
  localState,
  (state) => state.isLoading,
);

const getIsError = createSelector(
  localState,
  (state) => state.isError,
);

const getData = createSelector(
  localState,
  (state) => state.data,
);

const getDataForTable = createSelector(
  getData,
  (data) => data.map((item) => (InvoicesTableDTM.fromPlain({
    id: item.id,
    key: item.id,
    debtorName: item.companyName,
    vendor: item.companyNameFrom,
    number: item.number,
    created: item.billedDate,
    dueDate: item.dueDate,
    status: item.status,
    total: item.billed,
    balance: item.balance,
    shipment: item.shipment?.id,
    shipmentName: item.shipment?.shipmentName,
  }))),
);

const getTotalElements = createSelector(
  localState,
  (state) => state.totalElements,
);

const getActivePage = createSelector(
  localState,
  (state) => state.activePage,
);

const getFirstTimeLoading = createSelector(
  localState,
  (state) => state.firstTimeLoading,
);

export const invoicesSelectors = {
  getIsLoading,
  getIsError,
  getDataForTable,
  getTotalElements,
  getActivePage,
  getFirstTimeLoading,
};
