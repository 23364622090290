import React from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';

import { BillOfLadingEditExportClearanceComponent } from './BillOfLadingEditExportClearance.component';

const BillOfLadingEditExportClearanceContainer = () => {
  const isPending = useSelector(R.selectors.exportClearance.getLoading);

  return (
    <BillOfLadingEditExportClearanceComponent
      isPending={isPending}
    />
  );
};

export { BillOfLadingEditExportClearanceContainer as BillOfLadingEditExportClearance };
