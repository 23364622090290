import React, { FC } from 'react';

import { IChangesTable } from 'shipment-operations/models/dtm';

import { useTable } from './useTable';
import { CustomHeader } from './CustomTable.styled';

interface ICustomTableProps {
  data: IChangesTable[]
  type: string
  withThreeColumns: boolean
  withMismatches: boolean
  isRequestTheSameWithShipment?: boolean
  isCustomer: boolean
  mismatchesTable?: boolean
  maerskMode?: boolean
  partnerAmendmentSubmitted?: boolean
}

const CustomTable: FC<ICustomTableProps> = ({
  data, type, withThreeColumns, withMismatches, isRequestTheSameWithShipment, isCustomer,
  mismatchesTable = false, maerskMode = false, partnerAmendmentSubmitted = false,
}) => {
  const { header } = useTable(withThreeColumns, type, withMismatches, isRequestTheSameWithShipment, isCustomer, mismatchesTable, maerskMode, partnerAmendmentSubmitted);
  return (
    <CustomHeader
      columns={header}
      dataSource={data}
      pagination={false}
    />
  );
};

export { CustomTable };
