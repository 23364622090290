import styled, { AnyStyledComponent } from 'styled-components';
import InfiniteScroll from 'react-infinite-scroll-component';

export const ShipmentListInfiniteScroll = styled(InfiniteScroll as unknown as AnyStyledComponent).attrs({
  'data-class': 'ShipmentListInfiniteScroll',
})`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
`;
