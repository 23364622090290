import { IShipmentDocumentStateDTM } from 'shipment-operations/models/dtm';

export const ShipmentDocumentInitialState: IShipmentDocumentStateDTM = {
  defaultState: {
    notes: '',
    reference: '',
    additionalId: null,
    referenceId: null,
    isRequest: false,
    isEmptyRequest: false,
    getAdditionalStatus: null,
    getManualAdditionalStatus: null,
    documents: [{
      documentType: null,
      uploadFiles: '',
      fileName: '',
    }],
  },
  updateState: {
    notes: '',
    reference: '',
    additionalId: null,
    referenceId: null,
    isUpdate: false,
    documents: [],
    errors: {
      documents: [],
    },
  },
  fileList: {},
};
