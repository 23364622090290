import React, {
  useCallback, useMemo, FC, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import get from 'lodash/fp/get';
import getOr from 'lodash/fp/getOr';
import isEmpty from 'lodash/fp/isEmpty';
import noop from 'lodash/fp/noop';
import Dropdown from 'antd/es/dropdown';
import Menu from 'antd/es/menu';
import Tooltip from 'antd/es/tooltip';
import Modal from 'antd/es/modal';
import InfoCircleOutlined from '@ant-design/icons/InfoCircleOutlined';
import { useParams } from 'react-router';
import Table from 'antd/es/table';

import { checkSign } from 'app-wrapper/utils';
import { ChargeTableDTM, ChargeViewDtm } from 'shipment-operations/models/dtm';
import { useToggleCollapse } from 'shipment-operations/view/hooks';
import { measureByConst } from 'shipment-operations/constants';

import { HistoryTable } from 'shipment-operations/view/components';
import { CustomUnit } from 'shipment-operations/view/pages/ShipmentCharges/components/CustomTable/CustomTable.styled';
import {
  CustomHeader, ExpandTable, StyledType, StyledTypeTitle, FirstHeaderWrapper, SubHeader,
  StyledBodyCell, StyledExpandIcon, StyledMoreIcon, StyledMenuItem, StyledRemove, DropDownWrapper,
} from './CustomTable.styled';

interface ICustomTableProps {
  data: ChargeViewDtm[]
  isPercentage: boolean
  openEditPanel: (id: number, host: string) => void
  setDeletedCharge: (id: number | null) => void
  onDelete: (id?: string) => void
  isLoadingDelete: boolean
  onDownloadDocument: (shipmentId: string, id: number, name: string) => void
  subTitle?: string
}

const { Column, ColumnGroup } = Table;

const CustomTable: FC<ICustomTableProps> = ({
  data, isPercentage, openEditPanel, setDeletedCharge, onDelete, isLoadingDelete,
  onDownloadDocument, subTitle,
}) => {
  const { t } = useTranslation();
  const { shipmentId } = useParams<'shipmentId'>();
  const { openedKeys, onToggleCollapse } = useToggleCollapse();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const renderOpenedCells = useCallback((value, record) => {
    const active = get(['active'], record);
    return (
      <StyledBodyCell active={active}>$ {value.toFixed(2)}</StyledBodyCell>
    );
  }, [openedKeys]);

  const renderOpenedCellsWithoutSum = useCallback((value, record) => {
    const active = get(['active'], record);
    return (
      <StyledBodyCell active={active}>{value}</StyledBodyCell>
    );
  }, [openedKeys]);

  const openEditMenu = useCallback((id) => {
    openEditPanel(id, 'transportation');
  }, []);

  const openOnRemove = useCallback((id) => {
    setDeletedCharge(id);
    setIsModalOpen(true);
  }, []);

  const closeOnRemove = useCallback(() => {
    setDeletedCharge(null);
    setIsModalOpen(false);
  }, []);

  const handleDelete = useCallback(() => onDelete(shipmentId), []);

  const expandMenuItems = useCallback((item) => (
    (
      <Menu>
        <StyledMenuItem
          onClick={() => openEditMenu(item.key)}
          key={1}
        >
          {t('Edit')}
        </StyledMenuItem>
        <StyledRemove
          key={2}
          onClick={() => openOnRemove(item.key)}
        >
          {t('remove')}
        </StyledRemove>
      </Menu>
    )
  ), []);

  const renderProfit = useCallback((value, record) => {
    const active = get(['active'], record);
    if (isPercentage) {
      const percent = record.profitPercentage;
      return (
        <StyledBodyCell active={active} isOpen={openedKeys.includes(record.key)}>
          {checkSign(percent, '%')}
          <DropDownWrapper active={active}>
            <Dropdown arrow overlay={expandMenuItems(record)}>
              <StyledMoreIcon />
            </Dropdown>
          </DropDownWrapper>
        </StyledBodyCell>
      );
    }
    return (
      <StyledBodyCell active={active} isOpen={openedKeys.includes(record.key)}>
        {checkSign(value, '$')}
        <DropDownWrapper active={active}>
          <Dropdown arrow overlay={expandMenuItems(record)}>
            <StyledMoreIcon />
          </Dropdown>
        </DropDownWrapper>
      </StyledBodyCell>
    );
  }, [isPercentage]);

  const columns = useMemo(() => (
    <>
      <ColumnGroup align="left" title={<FirstHeaderWrapper>{subTitle}</FirstHeaderWrapper>}>
        <Column
          title={t('shipmentOperation.transportation.service')}
          dataIndex="description"
          key="description"
          width="22%"
          render={(type: string, record: ChargeTableDTM | {}) => {
            const key = get(['key'], record);
            const active = get(['active'], record);
            const history = getOr([], ['history'], record);
            const includedCharges = getOr([], ['includedCharges'], record);
            const hasHistory = history.length > 0;
            return (
              <StyledType active={active} onClick={hasHistory ? () => onToggleCollapse(key) : noop} isOpen={openedKeys.includes(key)}>
                {hasHistory && <StyledExpandIcon isActive={openedKeys.includes(key)} />}
                {hasHistory ? type : <StyledTypeTitle>{type}</StyledTypeTitle>}
                {!isEmpty(includedCharges) && (
                  <Tooltip
                    title={includedCharges.map((item: string) => <p key={`shipmentOperation_transportation_${item}`} style={{ margin: '0 3px 0 0', textTransform: 'capitalize' }}>{item}</p>)}
                    placement="top"
                  >
                    <InfoCircleOutlined style={{ marginLeft: '7px', cursor: 'pointer' }} />
                  </Tooltip>
                )}
              </StyledType>
            );
          }}
        />
        <Column
          title={t('Unit')}
          dataIndex="unitType"
          key="unitType"
          width="13%"
          render={(title: string, record: ChargeViewDtm | {}) => {
            const active = get(['active'], record);
            return <CustomUnit active={active}>{t(`createInvoiceTable.${measureByConst[title as keyof typeof measureByConst]}`)}</CustomUnit>;
          }}
        />
      </ColumnGroup>
      <ColumnGroup title={<SubHeader>{t('payables')}</SubHeader>}>
        <Column
          title={t('costPerUnit')}
          dataIndex="buyCostPerUnit"
          key="buyCostPerUnit"
          width="10%"
          align={'right' as 'right'}
          render={renderOpenedCells}
        />
        <Column
          title={t('QTY')}
          dataIndex="buyQuantity"
          key="buyQuantity"
          width="5%"
          align={'right' as 'right'}
          render={renderOpenedCellsWithoutSum}
        />
        <Column
          title={t('Buy')}
          dataIndex="buyTotalCost"
          key="buyTotalCost"
          width="10%"
          align={'right' as 'right'}
          render={renderOpenedCells}
        />
      </ColumnGroup>
      <ColumnGroup title={<SubHeader>{t('receivables')}</SubHeader>}>
        <Column
          title={t('costPerUnit')}
          dataIndex="costPerUnit"
          key="costPerUnit"
          width="10%"
          align={'right' as 'right'}
          render={renderOpenedCells}
        />
        <Column
          title={t('QTY')}
          dataIndex="quantity"
          key="quantity"
          width="5%"
          align={'right' as 'right'}
          render={renderOpenedCellsWithoutSum}
        />
        <Column
          title={t('sell')}
          dataIndex="totalCost"
          key="totalCost"
          width="10%"
          align={'right' as 'right'}
          render={renderOpenedCells}
        />
      </ColumnGroup>
      <ColumnGroup align="right">
        <Column
          title={t('shipmentOperation.transportation.profit')}
          dataIndex="profit"
          key="profit"
          width="15%"
          align={'right' as 'right'}
          render={renderProfit}
        />
      </ColumnGroup>
    </>
  ), [openedKeys, isPercentage]);

  const expandRender = (record: ChargeViewDtm | {}) => (
    <HistoryTable data={record} onDownloadDocument={onDownloadDocument} />
  );

  return (
    <>
      <CustomHeader
        expandable={{
          expandIconColumnIndex: -1,
        }}
      >
        {columns}
      </CustomHeader>
      <ExpandTable
        pagination={false}
        showHeader={false}
        dataSource={data}
        rowClassName="customTableRow"
        expandedRowClassName={() => 'customExpandRow'}
        expandable={{
          expandedRowRender: expandRender,
          expandedRowKeys: openedKeys,
          expandIconColumnIndex: -1,
        }}
      >
        {columns}
      </ExpandTable>
      <Modal
        onCancel={closeOnRemove}
        visible={isModalOpen}
        okText={t('yes')}
        cancelText={t('no')}
        onOk={handleDelete}
        okButtonProps={{ loading: isLoadingDelete }}
      >
        <p>{t('Are you sure you want to delete this item')}</p>
      </Modal>
    </>
  );
};

export { CustomTable };
