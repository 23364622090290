import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import {
  SBLTransportInformation,
  SBLTransportTitle,
  SBLTransportRow,
  SBLTransportItem,
  SBLTransportItemTitle,
  SBLTransportItemSubTitle, Tag,
} from './SBLTransportInformation.styled';

interface SBLTransportInformationComponentProps {
  items: Array<{
    title: string;
    subtitle: string;
  }>;
}

export const SBLTransportInformationComponent: FC<SBLTransportInformationComponentProps> = ({ items }) => {
  const { t } = useTranslation();

  return (
    <SBLTransportInformation>
      <SBLTransportTitle>
        {t('Transport')}
      </SBLTransportTitle>

      <SBLTransportRow>
        {items.map(({ title, subtitle }) => (
          <SBLTransportItem key={title}>
            <SBLTransportItemTitle>
              {title}
            </SBLTransportItemTitle>

            <SBLTransportItemSubTitle>
              {subtitle || <Tag>{t('Not Provided')}</Tag>}
            </SBLTransportItemSubTitle>
          </SBLTransportItem>
        ))}
      </SBLTransportRow>
    </SBLTransportInformation>
  );
};
