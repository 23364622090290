export enum GeocoderAddressTypes {
  POSTAL_CODE = 'postal_code',
  COUNTRY = 'country',
  ROUTE = 'route',
  LOCALITY = 'locality',
  STREET_NUMBER = 'street_number',
  STATE = 'administrative_area_level_1'
}

export type GeocoderAddressComponent = {
  long_name: string // eslint-disable-line
  short_name: string // eslint-disable-line
  types: GeocoderAddressTypes[]
}
