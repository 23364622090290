import styled from 'styled-components';
import { StyledTable } from 'shipment-operations/view/components/CustomTable/CustomTable.styled';

export const Wrapper = styled.div`
  width: 100%;
  padding: 14px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1660px;
  margin: 0 auto;
`;

export const Title = styled.span`
  font-weight: 500;
  font-size: 20px;
  color: #202C3C;
`;

export const CustomTable = styled(StyledTable)`
  max-width: 1614px;

  .ant-table table {
    padding: 15px;
  }
  .ant-table-thead .ant-table-cell {
    padding-top: 8px;
    padding-bottom: 8px;
    border-bottom: 1px solid #EAEEF8;
    border-top: 1px solid #EAEEF8;
  }
  .ant-table-thead .ant-table-cell {
    color: #73819B;
    font-size: 12px;
    font-weight: 400;
  }
  .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    display: none;
  }
  .ant-table-thead > tr > th {
    background: white;
    border-bottom: none;
  }
  .ant-table-container table > thead > tr:first-child th:last-child,
  .ant-table-tbody .ant-table-cell:last-child {
    padding-right: 0;
  }
  .ant-table-container table > thead > tr:first-child th:first-child {
    padding-left: 0;
  }
`;
