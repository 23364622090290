import React, { FC, useCallback } from 'react';

import { Button } from 'app-wrapper/view/components';
import { EmptySectionForIconsSvg, PlusSvg } from 'app-wrapper/view/icons';

import {
  EmptyStateForSectionDiv,
  EmptyStateForSectionDivWrapper,
  EmptyStateForSectionIconSecondWrap,
  EmptyStateForSectionIconWrap,
  EmptyStateForSectionText,
  EmptyStateForSectionTextButton,
  EmptyStateForSectionTextFirst,
  EmptyStateForSectionTextSecond,
} from './EmptyStateForSection.styled';

interface IEmptyStateForSectionProps {
  icon?: React.ReactNode
  headerText?: string
  contentText?: string
  buttonText?: string
  isPlus?: boolean
  onClickButton?: () => void
}

export const EmptyStateForSection: FC<IEmptyStateForSectionProps> = (props) => {
  const {
    icon,
    headerText,
    contentText,
    buttonText,
    isPlus,
    onClickButton,
  } = props;

  const onClickButtonHandle = useCallback(
    () => {
      if (onClickButton) {
        onClickButton();
      }
    },
    [onClickButton],
  );

  return (
    <EmptyStateForSectionDiv>
      <EmptyStateForSectionDivWrapper>
        <EmptyStateForSectionIconWrap>
          <EmptySectionForIconsSvg />
          {icon ? (
            <EmptyStateForSectionIconSecondWrap>
              {icon}
            </EmptyStateForSectionIconSecondWrap>
          ) : null}
        </EmptyStateForSectionIconWrap>

        <EmptyStateForSectionText>
          {headerText ? (
            <EmptyStateForSectionTextFirst>
              {headerText || ''}
            </EmptyStateForSectionTextFirst>
          ) : null}
          {contentText ? (
            <EmptyStateForSectionTextSecond>
              {contentText || ''}
            </EmptyStateForSectionTextSecond>
          ) : null}
        </EmptyStateForSectionText>
        {onClickButton ? (
          <EmptyStateForSectionTextButton>
            <Button
              type="dashed"
              size="middle"
              onClick={onClickButtonHandle}
            >
              {isPlus ? (
                <PlusSvg
                  height={9}
                  width={9}
                />
              ) : null}
              {buttonText || ''}
            </Button>
          </EmptyStateForSectionTextButton>
        ) : null}
      </EmptyStateForSectionDivWrapper>
    </EmptyStateForSectionDiv>
  );
};
