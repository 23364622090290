import styled from 'styled-components';
import Typography from 'antd/es/typography';

export const Wrap = styled.div`
  page-break-inside: avoid;
  padding: 0 24px 24px 24px;
`;

export const DropoffDetails = styled.div`
  min-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const Title = styled(Typography)`
  margin-bottom: 6px;
  font-size: 12px;
  line-height: 19.2px;
  font-weight: 700;
`;

export const Section = styled.div`
  min-width: 100%;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  padding: 8px;
  border: 0.2px solid rgba(209, 215, 226, 1);
`;

export const Item = styled.div`
  padding-right: 8px;
  min-width: 24%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const ItemTitle = styled(Typography)`
  min-width: 100%;
  font-size: 9.6px;
  line-height: 14.4px;
  font-weight: 700;
`;

export const ItemText = styled(Typography)`
  min-width: 100%;
  font-size: 9.6px;
  line-height: 12px;
`;

export const Divider = styled.div`
  margin: 0px 20px;
  width: 1px;
  border: 0.2px dashed rgba(209, 215, 226, 1);
  min-height: 100%;
`;
