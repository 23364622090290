import isEmpty from 'lodash/fp/isEmpty';
import isNumber from 'lodash/fp/isNumber';
import uniqBy from 'lodash/fp/uniqBy';
import get from 'lodash/fp/get';
import getOr from 'lodash/fp/getOr';
import { BaseController, controller } from 'proto/BaseController';
import type { DefaultValueType } from 'rc-tree-select/lib/interface';

import { R } from 'shipment-operations/repository';
import {
  ChargeCodeDTM, ChargeCompanyCreateDTM,
  ChargeDocumentDTM,
  ChargeDTM,
  CreateChargeDTM,
  ShippingPartyDTM,
  ShortContainerDTM,
} from 'shipment-operations/models/dtm';
import {
  ACCESSORIAL,
  APPLY_TO,
  applyToModel,
  applyToOptions,
  ChargeCodePriceBy, ChargeCodeType,
  CONTAINER,
  CONTAINER_TYPE,
  ContainerTypesConst,
  DESTINATION,
  EShippingPartyTypes,
  FLAT,
  FREIGHT,
  MEASURE_BY,
  measureByConst,
  ORIGIN,
  PermissionAttributePolicy,
  phasesOptions,
  SHIPMENT,
  ShipmentDrawerContent,
} from 'shipment-operations/constants';
import { R as userManagementR } from 'user-management/repository';
import { chargeCodeOccurrence, chargeCodeType } from 'monetary/constants';
import { EOrganizationMemberRole } from 'user-management/constants';

@controller
export class ShipmentActiveChargeController extends BaseController {
  loadFullListCharges = async () => {
    let response: ChargeCodeDTM[] | null;

    this.dispatch(R.actions.shipmentActiveCharge.setGlobalLoadingData(true));
    this.dispatch(R.actions.shipmentActiveCharge.setIsLoadingFullList(true));
    try {
      response = await R.services.shipmentActiveCharge.getFullListCharges();
    } catch (e) {
      this.dispatch(R.actions.shipmentActiveCharge.setErrorFullList(true));
      return;
    }
    const isOriginPartnerAgent = R.selectors.shipment.getIsOriginPartnerAgent(this.store.getState());
    const isDestinationPartnerAgent = R.selectors.shipment.getIsDestinationPartnerAgent(this.store.getState());
    const isBookingAgent = R.selectors.shipment.getIsBookingAgent(this.store.getState());
    if (isOriginPartnerAgent || isDestinationPartnerAgent || isBookingAgent) {
      response = response.filter((item) => item.type === ChargeCodeType.FEE);
    }
    this.dispatch(R.actions.shipmentActiveCharge.setFullLustCharges(response));
  }

  loadContainers = async (shipmentId?: string) => {
    if (!shipmentId) {
      return;
    }
    let response: ShortContainerDTM[] | null;
    try {
      response = await R.services.shipmentActiveCharge.getContainers(shipmentId);
    } catch (e) {
      this.dispatch(R.actions.shipmentActiveCharge.setActiveChargeContainersError(true));
      return;
    }
    this.dispatch(R.actions.shipmentActiveCharge.setActiveChargeContainers(response));
  }

  public loadShipmentParties = async (shipmentId: string) => {
    let shippingPartyList: ShippingPartyDTM[] | null;

    this.dispatch(R.actions.shipmentActiveCharge.setIsLoadingCompanies(true));
    try {
      shippingPartyList = await R.services.shippingParties.getList(shipmentId);
    } catch (e) {
      this.dispatch(R.actions.shipmentActiveCharge.setLoadCompaniesError(true));
      this.dispatch(R.actions.shipmentActiveCharge.setGlobalLoadingData(false));
      return;
    }

    this.dispatch(R.actions.shipmentActiveCharge.setGlobalLoadingData(false));

    const uniqCompanies = uniqBy((item) => item.id, shippingPartyList);
    if (!uniqCompanies) {
      return;
    }

    this.dispatch(R.actions.shipmentActiveCharge.onLoadCompaniesSuccess(uniqCompanies));
  }

  public loadPaymentTerms = async (shipmentId: string) => {
    this.dispatch(R.actions.shipmentActiveCharge.setIsLoadingPaymentTerms(true));
    const paymentTerms = await R.services.paymentTerms.getShipmentPaymentTerms(shipmentId);
    if (paymentTerms) {
      const { tradeType } = paymentTerms;
      this.dispatch(R.actions.shipmentActiveCharge.setTradeType(tradeType));
    }
    this.dispatch(R.actions.shipmentActiveCharge.setIsLoadingPaymentTerms(false));
  }

  loadDataForCharges = async (shipmentId?: string) => {
    if (!shipmentId) {
      return;
    }
    await this.loadFullListCharges();
    await this.loadContainers(shipmentId);
    await this.loadShipmentParties(shipmentId);
    await this.loadPaymentTerms(shipmentId);
  }

  openTransportationActiveCharge = () => {
    this.dispatch(R.actions.shipmentDrawer.openDrawer(
      ShipmentDrawerContent.SHIPMENT_TRANSPORTATION_ADD_CHARGE,
    ));
  }

  openAccessorialActiveCharge = () => {
    this.dispatch(R.actions.shipmentDrawer.openDrawer(
      ShipmentDrawerContent.SHIPMENT_ACCESSORIAL_ADD_CHARGE,
    ));
  }

  closeDrawer = () => {
    this.dispatch(R.actions.shipmentDrawer.closeDrawer());
    this.dispatch(R.actions.shipmentActiveCharge.clear());
  }

  applyTo = (charge?: ChargeDTM) => {
    if (charge?.priceBy === ChargeCodePriceBy.BOL) {
      return applyToOptions.find((item) => item.value === 'SHIPMENT')?.value;
    }
    if (charge?.priceBy === ChargeCodePriceBy.CONTAINER) {
      return applyToOptions.find((item) => item.value === 'CONTAINER')?.value;
    }
    return '';
  };

  openEditCharge = (id: number, host: string) => {
    let charges: ChargeDTM[];
    if (host === ACCESSORIAL) {
      charges = R.selectors.shipmentAccessorial.getData(this.store.getState());
    } else {
      charges = R.selectors.shipmentTransportationCharges.getNotFilteredCharges(this.store.getState());
    }
    const matched = charges.find((item) => item.id === id);

    this.dispatch(R.actions.shipmentActiveCharge.setActiveChargeSavedValues({
      APCostPerUnit: matched?.buyCostPerUnit,
      APNumberOfUnits: matched?.buyNumberOfUnits,
      ARCostPerUnit: matched?.costPerUnit,
      ARNumberOfUnits: matched?.numberOfUnits,
      designation: matched?.designation,
      measureBy: matched?.measureBy ? measureByConst[matched?.measureBy as keyof typeof measureByConst] : '',
      description: matched?.description,
      chargeCode: matched?.chargeCode,
      applyTo: this.applyTo(matched),
      id: matched?.id,
      savedContainer: matched?.container?.number || matched?.container?.id,
      arBudget: matched?.arBudget,
      apBudget: matched?.apBudget,
      documents: matched?.documents,
      rateId: matched?.rateId,
    }));

    this.dispatch(R.actions.shipmentActiveCharge.setSelectedCompany({ key: 'selectedCustomerCompany', value: matched?.debtor?.id }));
    this.dispatch(R.actions.shipmentActiveCharge.setSelectedCompany({ key: 'selectedVendorCompany', value: matched?.creditor?.id }));

    this.dispatch(R.actions.shipmentActiveCharge.setActiveChargeSelectedContainers([matched?.container?.id]));

    if (host === ACCESSORIAL) {
      this.dispatch(R.actions.shipmentDrawer.openDrawer(
        ShipmentDrawerContent.SHIPMENT_ACCESSORIAL_EDIT_CHARGE,
      ));
    } else {
      this.dispatch(R.actions.shipmentDrawer.openDrawer(
        ShipmentDrawerContent.SHIPMENT_TRANSPORTATION_EDIT_CHARGE,
      ));
    }
  }

  setDeletedCharge = (id: number | null) => {
    this.dispatch(R.actions.shipmentActiveCharge.setDeletedChargeId(id));
  }

  setActiveChargeSelectedCharge = (value: string, mode?: ShipmentDrawerContent) => {
    const charges = R.selectors.shipmentActiveCharge.getFullListCharges(this.store.getState());
    const isError = R.selectors.shipmentActiveCharge.getActiveChargeErrorCharge(this.store.getState());

    const selectedCharge = charges.find((item: ChargeCodeDTM) => item.code === value);
    this.dispatch(R.actions.shipmentActiveCharge.setActiveChargeSelectedCharge(selectedCharge));
    this.dispatch(R.actions.shipmentActiveCharge.clearActiveChargeInputs());

    if (mode === ShipmentDrawerContent.SHIPMENT_ACCESSORIAL_EDIT_CHARGE || mode === ShipmentDrawerContent.SHIPMENT_ACCESSORIAL_ADD_CHARGE) {
      this.setActiveChargeValue(1, 'ARNumberOfUnits');
      this.setActiveChargeValue(1, 'APNumberOfUnits');
    }

    const originPermissions = R.selectors.shipment.getOriginActiveChargePermissions(this.store.getState());
    const destinationPermissions = R.selectors.shipment.getDestinationActiveChargePermissions(this.store.getState());
    const isOriginPartner = R.selectors.shipment.getIsOriginPartner(this.store.getState());
    const isDestinationPartner = R.selectors.shipment.getIsDestinationPartner(this.store.getState());
    const isAccountHolder = R.selectors.shipment.getIsAccountHolder(this.store.getState());
    const isOriginPartnerAgent = R.selectors.shipment.getIsOriginPartnerAgent(this.store.getState());
    const isDestinationPartnerAgent = R.selectors.shipment.getIsDestinationPartnerAgent(this.store.getState());
    const isBookingAgent = R.selectors.shipment.getIsBookingAgent(this.store.getState());
    const role = userManagementR.selectors.userOrganizationData.getUserOrganisationRole(this.store.getState());
    const companies = R.selectors.shipmentActiveCharge.getCompanies(this.store.getState());
    const oceanCarrier = companies.find((item) => item.role === EShippingPartyTypes.OCEAN_CARRIER);
    const bookingAgent = companies.find((item) => item.role === EShippingPartyTypes.BOOKING_AGENT);
    const customer = companies.find((item) => item.role === EShippingPartyTypes.CUSTOMER);
    const originPartnerAgent = companies.find((item) => item.role === EShippingPartyTypes.ORIGIN_PARTNER_AGENT);
    const destinationPartnerAgent = companies.find((item) => item.role === EShippingPartyTypes.DESTINATION_PARTNER_AGENT);

    const type = get(['type'], selectedCharge);
    const occurrence = get(['occurrence'], selectedCharge);
    const phase = getOr([], ['phases'], selectedCharge) as string[];
    const isOrigin = phase?.includes(ORIGIN);
    const isDestination = phase?.includes(DESTINATION);
    this.dispatch(R.actions.shipmentActiveCharge.setIsDisableDesignation(false));
    if (type === chargeCodeType.FEE) {
      this.dispatch(R.actions.shipmentActiveCharge.setActiveChargeInputsByFee());
    }

    if ((originPermissions === PermissionAttributePolicy.WRITE) && !isOriginPartner) {
      this.dispatch(R.actions.shipmentActiveCharge.setIsDisableDesignation(true));
      this.setActiveChargeValue(ORIGIN, 'designation');
    }

    if ((destinationPermissions === PermissionAttributePolicy.WRITE) && isDestinationPartner) {
      if (occurrence === chargeCodeOccurrence.ADDITIONAL || type === chargeCodeType.FEE) {
        this.dispatch(R.actions.shipmentActiveCharge.setIsDisableDesignation(true));
        this.setActiveChargeValue(DESTINATION, 'designation');
      }
    }

    if ((destinationPermissions === PermissionAttributePolicy.WRITE) && !isDestinationPartner) {
      this.dispatch(R.actions.shipmentActiveCharge.setIsDisableDesignation(true));
      this.setActiveChargeValue(DESTINATION, 'designation');
    }

    if (role === EOrganizationMemberRole.admin) {
      this.dispatch(R.actions.shipmentActiveCharge.setIsDisableDesignation(true));
      this.setActiveChargeValue(FREIGHT, 'designation');
    }

    if (((occurrence === chargeCodeOccurrence.ADDITIONAL && isOrigin) || (occurrence === chargeCodeOccurrence.ADDITIONAL && isDestination)) || type === chargeCodeType.FEE) {
      this.setCompany('selectedVendorCompany');
    } else {
      this.setCompany('selectedVendorCompany', oceanCarrier?.company?.id);
    }

    if (isOriginPartnerAgent || isDestinationPartnerAgent || isBookingAgent) {
      this.setCompany('selectedVendorCompany');
    }

    if (isOriginPartner) {
      this.setCompany('selectedCustomerCompany', originPartnerAgent?.company?.id);
    }

    if (isDestinationPartner) {
      this.setCompany('selectedCustomerCompany', destinationPartnerAgent?.company?.id);
    }

    if (isAccountHolder) {
      this.setCompany('selectedCustomerCompany', bookingAgent?.company?.id);
    }

    if (isOriginPartnerAgent) {
      this.setCompany('selectedCustomerCompany', bookingAgent?.company?.id);
    }

    if (isDestinationPartnerAgent) {
      this.setCompany('selectedCustomerCompany', bookingAgent?.company?.id);
    }

    if (isBookingAgent) {
      this.setCompany('selectedCustomerCompany', customer?.company?.id);
    }

    if (isError) {
      this.dispatch(R.actions.shipmentActiveCharge.setActiveChargeErrorCharge(false));
    }
  }

  setCompany = (key: string, id?: number) => {
    this.dispatch(R.actions.shipmentActiveCharge.setSelectedCompany({ key, value: id || '' }));
  }

  syncSavedCharge = (mode: string, value: string, host: string, containerType?: string) => {
    let savedCharge: ChargeDTM | undefined;
    let charges: ChargeDTM[];
    if (host === ACCESSORIAL) {
      charges = R.selectors.shipmentAccessorial.getData(this.store.getState());
    } else {
      charges = R.selectors.shipmentTransportationCharges.getNotFilteredCharges(this.store.getState());
    }
    if (mode === SHIPMENT) {
      savedCharge = charges.find((item: ChargeDTM) => item.chargeCode.code === value);
    } else {
      savedCharge = charges.find((item: ChargeDTM) => (item.chargeCode.code === value) && item.container.type === ContainerTypesConst[containerType as keyof typeof ContainerTypesConst]);
    }

    if (savedCharge) {
      this.dispatch(R.actions.shipmentActiveCharge.setActiveChargeSavedValues({
        APCostPerUnit: savedCharge.buyCostPerUnit,
        APNumberOfUnits: savedCharge.buyNumberOfUnits,
        ARCostPerUnit: savedCharge.costPerUnit,
        ARNumberOfUnits: savedCharge.numberOfUnits,
        designation: savedCharge.designation ? phasesOptions.find((item) => item.value === savedCharge?.designation)?.value : '',
        measureBy: savedCharge.measureBy ? measureByConst[savedCharge.measureBy as keyof typeof measureByConst] : '',
        applyTo: this.applyTo(savedCharge),
        chargeCode: savedCharge.chargeCode,
      }));
    }
  }

  setActiveChargeValue = (value: number | string, field: string) => {
    const errors = R.selectors.shipmentActiveCharge.getActiveChargeErrors(this.store.getState());
    if (!isEmpty(errors)) {
      const updatedErrors = errors.filter((item) => item !== field);
      this.dispatch(R.actions.shipmentActiveCharge.setActiveChargeErrors(updatedErrors));
    }

    if (field === MEASURE_BY && (value === FLAT || value === CONTAINER_TYPE)) {
      this.dispatch(R.actions.shipmentActiveCharge.setActiveChargeInputsByFlat());
    }

    this.dispatch(R.actions.shipmentActiveCharge.setActiveChargeValue({ value, field }));

    const selectedChargeCode = R.selectors.shipmentActiveCharge.getActiveChargeSelectedChargeCode(this.store.getState());
    if (field === APPLY_TO && value === SHIPMENT) {
      this.syncSavedCharge(SHIPMENT, selectedChargeCode.code, ACCESSORIAL);
    }
  }

  setComments = (value: string) => {
    this.dispatch(R.actions.shipmentActiveCharge.setComments(value));
  }

  setActiveChargeContainer = (containers: DefaultValueType) => {
    const isError = R.selectors.shipmentActiveCharge.getActiveChargeSelectedContainersError(this.store.getState());
    if (isError) {
      this.dispatch(R.actions.shipmentActiveCharge.setActiveChargeSelectedContainersError(false));
    }

    const availableContainers = R.selectors.shipmentActiveCharge.getActiveChargeContainers(this.store.getState());
    const selectedChargeCode = R.selectors.shipmentActiveCharge.getActiveChargeSelectedChargeCode(this.store.getState());
    const containerId = get([0], containers);
    if (!containerId) {
      this.dispatch(R.actions.shipmentActiveCharge.clearSyncedInfo());
    }
    const matchedContainerType = availableContainers.find((item) => (item.id === containerId))?.type;

    this.dispatch(R.actions.shipmentActiveCharge.setActiveChargeSelectedContainers(containers));
    this.dispatch(R.actions.shipmentActiveCharge.setActiveChargeSelectedContainerType(matchedContainerType));

    this.syncSavedCharge('container', selectedChargeCode.code, ACCESSORIAL, matchedContainerType);
  }

  validateActiveCharge = () => {
    const selectedCharge = R.selectors.shipmentActiveCharge.getActiveChargeSelectedChargeTitle(this.store.getState());
    if (!selectedCharge) {
      this.dispatch(R.actions.shipmentActiveCharge.setActiveChargeErrorCharge(true));
      return false;
    }

    const selectedContainers = R.selectors.shipmentActiveCharge.getActiveChargeSelectedContainers(this.store.getState());
    const applyTo = R.selectors.shipmentActiveCharge.getActiveChargeApplyTo(this.store.getState());
    if ((applyTo === CONTAINER) && isEmpty(selectedContainers)) {
      this.dispatch(R.actions.shipmentActiveCharge.setActiveChargeSelectedContainersError(true));
      return false;
    }

    const measureBy = R.selectors.shipmentActiveCharge.getActiveChargeMeasureBy(this.store.getState());
    const designation = R.selectors.shipmentActiveCharge.getActiveChargeDesignation(this.store.getState());
    const APCostPerUnit = R.selectors.shipmentActiveCharge.getActiveChargeAPCostPerUnit(this.store.getState());
    const APNumberOfUnits = R.selectors.shipmentActiveCharge.getActiveChargeAPNumberOfUnits(this.store.getState());
    const ARCostPerUnit = R.selectors.shipmentActiveCharge.getActiveChargeARCostPerUnit(this.store.getState());
    const ARNumberOfUnits = R.selectors.shipmentActiveCharge.getActiveChargeARNumberOfUnits(this.store.getState());

    const fieldsGroup = [
      {
        key: 'APCostPerUnit',
        value: APCostPerUnit,
      },
      {
        key: 'APNumberOfUnits',
        value: APNumberOfUnits,
      },
      {
        key: 'ARCostPerUnit',
        value: ARCostPerUnit,
      },
      {
        key: 'ARNumberOfUnits',
        value: ARNumberOfUnits,
      },
    ];

    const dropDownGroups : { key: string, value?: string }[] = [
      {
        key: 'applyTo',
        value: applyTo,
      },
      {
        key: 'measureBy',
        value: measureBy,
      },
      {
        key: 'designation',
        value: designation,
      },
    ];

    const errors: string[] = [];
    fieldsGroup.forEach((item) => !isNumber(item.value) && errors.push(item.key));
    dropDownGroups.forEach((item) => !item.value && errors.push(item.key));

    if (!isEmpty(errors)) {
      this.dispatch(R.actions.shipmentActiveCharge.setActiveChargeErrors(errors));
      return false;
    }

    return true;
  }

  prepareBody = () => {
    const applyTo = R.selectors.shipmentActiveCharge.getActiveChargeApplyTo(this.store.getState());
    const measureBy = R.selectors.shipmentActiveCharge.getActiveChargeMeasureBy(this.store.getState());
    const designation = R.selectors.shipmentActiveCharge.getActiveChargeDesignation(this.store.getState());
    const selectedChargeCode = R.selectors.shipmentActiveCharge.getActiveChargeSelectedChargeCode(this.store.getState());
    const ARTotal = R.selectors.shipmentActiveCharge.getActiveChargeTotalAR(this.store.getState());
    const APTotal = R.selectors.shipmentActiveCharge.getActiveChargeTotalAP(this.store.getState());
    const description = R.selectors.shipmentActiveCharge.getActiveChargeDescription(this.store.getState());
    const APCostPerUnit = R.selectors.shipmentActiveCharge.getActiveChargeAPCostPerUnit(this.store.getState());
    const ARCostPerUnit = R.selectors.shipmentActiveCharge.getActiveChargeARCostPerUnit(this.store.getState());
    const ARNumberOfUnits = R.selectors.shipmentActiveCharge.getActiveChargeARNumberOfUnits(this.store.getState());
    const APNumberOfUnits = R.selectors.shipmentActiveCharge.getActiveChargeAPNumberOfUnits(this.store.getState());
    const documents = R.selectors.shipmentActiveCharge.getActiveChargeDocuments(this.store.getState());
    const rateId = R.selectors.shipmentActiveCharge.getActiveChargeRateId(this.store.getState());

    const mode = R.selectors.shipmentDrawer.getContentType(this.store.getState());

    const body = CreateChargeDTM.fromPlain({
      chargeCode: selectedChargeCode,
      applianceRange: null,
      subjectTo: null,
      applied: (mode === ShipmentDrawerContent.SHIPMENT_TRANSPORTATION_ADD_CHARGE || mode === ShipmentDrawerContent.SHIPMENT_TRANSPORTATION_EDIT_CHARGE),
      currency: 'USD',
      designation,
      measureBy,
      priceBy: applyToModel[applyTo as keyof typeof applyToModel],
      costPerUnit: ARCostPerUnit,
      buyCostPerUnit: APCostPerUnit,
      numberOfUnits: ARNumberOfUnits,
      buyNumberOfUnits: APNumberOfUnits,
      buyTotalCost: APTotal,
      totalCost: ARTotal,
      description,
      container: null,
      documents,
    });
    if (rateId) {
      body.rateId = rateId;
    }
    return body;
  }

  addCharge = async (shipmentId?: string) => {
    if (!shipmentId) {
      return;
    }

    this.dispatch(R.actions.shipmentActiveCharge.setActiveChargeCreateIsLoading(true));

    if (!this.validateActiveCharge()) {
      return;
    }

    const containers = R.selectors.shipmentActiveCharge.getActiveChargeContainers(this.store.getState());
    const selectedContainers = R.selectors.shipmentActiveCharge.getActiveChargeSelectedContainers(this.store.getState());
    const applyTo = R.selectors.shipmentActiveCharge.getActiveChargeApplyTo(this.store.getState());
    const selectedVendorCompany = R.selectors.shipmentActiveCharge.getSelectedVendorCompany(this.store.getState());
    const selectedCustomerCompany = R.selectors.shipmentActiveCharge.getSelectedCustomerCompany(this.store.getState());

    let body = this.prepareBody();

    if (selectedVendorCompany) {
      body = {
        ...body,
        creditor: ChargeCompanyCreateDTM.fromPlain({
          id: selectedVendorCompany.company?.id,
        }),
      };
    }

    if (selectedCustomerCompany) {
      body = {
        ...body,
        debtor: ChargeCompanyCreateDTM.fromPlain({
          id: selectedCustomerCompany.company?.id,
        }),
      };
    }

    if (applyTo === CONTAINER) {
      const containersWithCharge = selectedContainers.map((item) => {
        const matched = containers.find((elem) => elem.id === item);

        return (CreateChargeDTM.fromPlain({
          ...body,
          container: {
            id: item,
            type: matched?.type,
          },
        }));
      });
      await R.services.shipmentActiveCharge.onAddChargeBulk(shipmentId, containersWithCharge);
    } else {
      await R.services.shipmentActiveCharge.onAddCharge(shipmentId, body);
    }

    this.dispatch(R.actions.shipmentActiveCharge.setActiveChargeCreateSuccess(true));
  }

  editCharge = async (shipmentId?: string) => {
    if (!shipmentId) {
      return;
    }
    this.dispatch(R.actions.shipmentActiveCharge.setActiveChargeCreateIsLoading(true));

    if (!this.validateActiveCharge()) {
      return;
    }

    const id = R.selectors.shipmentActiveCharge.getActiveChargeId(this.store.getState());
    const mode = R.selectors.shipmentDrawer.getContentType(this.store.getState());
    let charges: ChargeDTM[];
    if (mode === ShipmentDrawerContent.SHIPMENT_ACCESSORIAL_EDIT_CHARGE || mode === ShipmentDrawerContent.SHIPMENT_ACCESSORIAL_ADD_CHARGE) {
      charges = R.selectors.shipmentAccessorial.getData(this.store.getState());
    } else {
      charges = R.selectors.shipmentTransportationCharges.getNotFilteredCharges(this.store.getState());
    }
    let body = this.prepareBody();

    const matched = charges.find((item) => item.id === id);
    if (matched?.debtor?.id) {
      body = {
        ...body,
        debtor: ChargeCompanyCreateDTM.fromPlain({
          id: matched?.debtor?.id,
        }),
      };
    }

    if (matched?.creditor?.id) {
      body = {
        ...body,
        creditor: ChargeCompanyCreateDTM.fromPlain({
          id: matched?.creditor?.id,
        }),
      };
    }
    await R.services.shipmentActiveCharge.onEditCharge(shipmentId, body, id);
    this.dispatch(R.actions.shipmentActiveCharge.setActiveChargeCreateSuccess(true));
  }

  deleteCharge = async (shipmentId?: string) => {
    if (!shipmentId) {
      return;
    }
    this.dispatch(R.actions.shipmentActiveCharge.setDeletedChargeLoading(true));
    const id = R.selectors.shipmentActiveCharge.getDeletedCharge(this.store.getState());
    try {
      await R.services.shipmentActiveCharge.onDeleteCharge(shipmentId, id);
    } catch {
      this.dispatch(R.actions.shipmentActiveCharge.deleteChargeFailed());
    }

    this.dispatch(R.actions.shipmentActiveCharge.deleteChargeSuccess());
  }

  onCloseActiveCharge = () => {
    this.dispatch(R.actions.shipmentActiveCharge.clearActiveCharge());
  }

  onClearSuccess = () => {
    this.dispatch(R.actions.shipmentActiveCharge.setActiveChargeCreateSuccess(false));
  }

  onClearDelete = () => {
    this.dispatch(R.actions.shipmentActiveCharge.onSetDeleteCharge(false));
  }

  public setIsDocumentUploading = (isUploading: boolean) => {
    this.dispatch(R.actions.shipmentActiveCharge.setIsDocumentUploading(isUploading));
  };

  downloadDocument = (shipmentId: string, id: number, name: string) => {
    try {
      R.services.shipmentBillingDocuments.getBillingDocument(+shipmentId, id, name);
    } catch (e) {
      console.error('DocumentsSeaworthyCertificates CONTROLLER: downloadDocument');
    }
  }

  addDocumentToCharge = (document: ChargeDocumentDTM) => {
    this.dispatch(R.actions.shipmentActiveCharge.addDocumentToActiveCharge(document));
  }

  removeDocumentFromCharge = (id: number) => {
    this.dispatch(R.actions.shipmentActiveCharge.removeDocumentFromActiveCharge(id));
  }
}
