import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Button } from 'app-wrapper/view/components';
import { FooterWrapper } from 'shipment-operations/view/components/ActiveCharge';

interface IFooterComponentProps {
  onCancel: () => void
  onAction: (shipmentId: string) => void
  isLoading: boolean
  actionName: string
  isDisabled?: boolean
}

const FooterComponent: FC<IFooterComponentProps> = ({
  onCancel,
  onAction,
  isLoading,
  actionName,
  isDisabled,
}) => {
  const { t } = useTranslation();
  const { shipmentId = '0' } = useParams<'shipmentId'>();

  const handleOnAdd = useCallback(() => onAction(shipmentId), []);

  return (
    <FooterWrapper>
      <Button
        disabled={isDisabled}
        type="default"
        style={{ marginRight: '8px' }}
        onClick={onCancel}
      >
        {t('addChargeMenu.cancel')}
      </Button>
      <Button
        disabled={isDisabled}
        htmlType="submit"
        onClick={handleOnAdd}
        loading={isLoading}
      >
        {actionName}
      </Button>
    </FooterWrapper>
  );
};

export { FooterComponent };
