import i18n from 'app-wrapper/i18n/i18n';
import {
  IsDefined, IsNumber, IsOptional, IsString,
} from 'class-validator';
import { BaseDTM } from 'proto/BaseDTM';

export type TWebsiteUrl = {
  webUrl: string
  maxValue?: number
}

export class WebsiteUrl extends BaseDTM<TWebsiteUrl> {
  @IsDefined()
  @IsString()
  webUrl: string;

  @IsOptional()
  @IsNumber()
  maxValue?: number;

  hasError = () => !/^https?:\/\/(www.)?[a-zA-Z0-9@:%.+~#?&/=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%.+~#?&/=]*)/.test(this.webUrl)

  getErrorMessage = () => {
    if (!/^https?:\/\/(www.)?[a-zA-Z0-9@:%.+~#?&/=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%.+~#?&/=]*)/.test(this.webUrl)) {
      return i18n.t('basicErrors.NOT_VALID_PATTERN_WEBSITE_URL');
    }
    return '';
  }

  getValidValue = () => {
    const maxValue = this?.maxValue || 300;
    return this.webUrl.slice(0, maxValue);
  }
}
