import React, {
  FC, useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import FilterFilled from '@ant-design/icons/FilterFilled';

import {

  CompanyAddressDTM,
  CompanyContactDTM,
  IContactBookDefaultStateCompanyListStateDTM,
} from 'user-management/models/dtm';

import { EmptyStateForPages, TableSkeleton } from 'app-wrapper/view/components';
import themesColors from 'app-wrapper/view/themes/themesColors';
import { Layout } from 'app-wrapper/view/guideline';
import { CBLayoutHeader, CBWrapper } from 'user-management/view/pages/ContactBook/styled';
import {
  CBByIdTable,
} from './components';

import {
  CBByIdContentBody,
  CBByIdContentTitle,
  CBByIdContentTitleArrowIcon,
  CBByIdContentTitleWrap,
  HeaderWrapper,
} from './ContactBookById.styled';
import { AccountDrawer } from '../Account/components';

interface IContactBookComponentProps {
  companyListState: IContactBookDefaultStateCompanyListStateDTM[]
  companyListAddresses?: CompanyAddressDTM[]
  companyListContactPersons?: CompanyContactDTM[]
  isLoading: boolean
  runComponent: (id: string) => void
  onClickEditAddress: (id: string) => () => void
  onClickAddAddress: () => void
  onClickEditCompany: () => void
  clickListItem: (companyId: string, addressId: string) => () => void
  onClickArrow: () => void
}

export const ContactBookByIdComponent: FC<IContactBookComponentProps> = (props) => {
  const {
    companyListState,
    companyListAddresses,
    companyListContactPersons,
    isLoading,
    runComponent,
    onClickEditAddress,
    onClickAddAddress,
    onClickEditCompany,
    clickListItem,
    onClickArrow,
  } = props;
  const { t } = useTranslation();
  const { contactId = '0' } = useParams();

  useEffect(() => {
    runComponent(contactId);
  }, []);

  return (
    <CBWrapper>
      <AccountDrawer />
      <HeaderWrapper>
        <CBLayoutHeader
          title={(
            <CBByIdContentTitleWrap>
              <CBByIdContentTitleArrowIcon onClick={onClickArrow} />
              <CBByIdContentTitle>{t('Contact Book')}</CBByIdContentTitle>
            </CBByIdContentTitleWrap>
          )}
          ghost={false}
        />
      </HeaderWrapper>
      <Layout>
        {isLoading
          ? (<TableSkeleton />)
          : null}
        {!isLoading
          ? (
            <CBByIdContentBody $height={!companyListAddresses?.length && !isLoading ? '100%' : ''}>
              <CBByIdTable
                companyListState={companyListState}
                companyListAddresses={companyListAddresses}
                companyListContactPersons={companyListContactPersons}
                emptyState={!companyListAddresses?.length && !isLoading ? (
                  <EmptyStateForPages
                    icon={(
                      <FilterFilled
                        width={28}
                        height={22}
                        style={{
                          color: themesColors.neutralBranding65,
                          width: '28px',
                          height: '22px',
                        }}
                      />
                    )}
                    headerText={t('No addresses yet')}
                    contentText={t('It seems like your contact book is empty. Start adding your contacts to easily manage and access them.')}
                    buttonText={t('Create address')}
                    onClickButton={onClickAddAddress}
                  />
                ) : null}
                nextPageTasks={() => ''}
                onClickEditCompany={onClickEditCompany}
                onClickEditAddress={onClickEditAddress}
                onClickAddAddress={onClickAddAddress}
                onClickAddressItem={clickListItem}
                onStart={() => ''}
              />
            </CBByIdContentBody>
          ) : null}

      </Layout>
    </CBWrapper>
  );
};
