import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';

import { CargoFormHsErrorAlertComponent } from './CargoFormHsErrorAlert.component';

const CargoFormHsErrorAlertContainer: FC = () => {
  const { errors } = useSelector(R.selectors.cargo.getCargo);

  return (
    <CargoFormHsErrorAlertComponent
      hsError={errors?.code}
    />
  );
};

export {
  CargoFormHsErrorAlertContainer as CargoFormHsErrorAlert,
};
