import styled from 'styled-components';

import { Input, Button } from 'app-wrapper/view/components';

export const Title = styled.span`
  margin-bottom: 8px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
`;

export const Text = styled.span`
  max-width: 380px;
  margin-bottom: 8px;
  font-size: 14px;
  line-height: 22px;
  color: ${({ theme }) => theme.themesColors.characterSecondaryDot45}; 
`;

export const Label = styled.span`
  margin-bottom: 4px;
  font-size: 12px;
  line-height: 20px;
`;

export const InputITN = styled(Input)`
  margin-bottom: 20px;
  width: 400px;
`;

export const SubmitButton = styled(Button)`
  width: 77px;
`;
