import { Factory } from 'fishery';

import { ModuleFunctionalityPermissionsDtm } from 'shipment-operations/models/dtm';

export const moduleFunctionalityPermissionsDtmFactory = Factory.define<ModuleFunctionalityPermissionsDtm>(
  () => ModuleFunctionalityPermissionsDtm.fromPlain({
    shippingInstructions: {},
    documents: {},
    exportClearance: {},
    containers: {},
    cargos: {},
    shipmentCharges: {},
    shipmentsBillingInvoicePayables: {},
    overview: {},
    transportation: {},
    dashboards: {},
    workgroup: {},
  }),
);
