import { useState, useCallback } from 'react';

const useToggleCollapse = () => {
  const [openedKeys, setOpenedKeys] = useState<number[]>([]);
  const onToggleCollapse = useCallback((key) => {
    if (openedKeys.includes(key)) {
      const newKeys = openedKeys.filter((item) => item !== key);
      setOpenedKeys(newKeys);
    } else {
      setOpenedKeys((keys) => [...keys, key]);
    }
  }, [openedKeys]);

  return {
    openedKeys,
    onToggleCollapse,
    setOpenedKeys,
  };
};

export { useToggleCollapse };
