import styled from 'styled-components';

export const BoxWrapper = styled.div<{ selected?: boolean, hasError?: boolean }>`
  position: relative;
  padding: 13px 16px 14px 16px;
  background: ${({ theme }) => theme.boxComponent.bg};
  border: 1px solid ${({ theme, hasError, selected }) => (hasError && !selected ? '#FFCCC7' : theme.boxComponent.border)};
  border-radius: 3px;
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'default')};

  ${({ selected, theme }) => (selected ? `
    box-shadow: 0px 4px 4px ${theme.boxComponent.dropShadow}, inset 5px 0px 0px ${theme.boxComponent.innerShadow};` : '')}
`;

export const Text = styled.div``;
