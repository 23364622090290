import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { NoDataStub } from 'shipment-operations/view/components';

import {
  SBLCommentsInformation,
  SBLCommentsInformationList,
  SBLCommentsInformationTitle,
  SBLCommentsInformationTitleWrap,
} from './SBLCommentsInformation.styled';
import { SBLEditButton } from '../SBLEditButton';

interface ISBLBillingInstructionsComponentProps {
  onOpenEdit?: () => void;
  comment: string;
  isIncomplete?: boolean;
}

export const SBLCommentsInformationComponent: FC<ISBLBillingInstructionsComponentProps> = ({
  isIncomplete,
  onOpenEdit,
  comment,
}) => {
  const { t } = useTranslation();

  return (
    <SBLCommentsInformation>
      <SBLCommentsInformationTitleWrap>
        <SBLCommentsInformationTitle>
          {t('BL Comments')}
        </SBLCommentsInformationTitle>

        {!isIncomplete && onOpenEdit ? (
          <SBLEditButton onClick={onOpenEdit}>
            {t('Edit')}
          </SBLEditButton>
        ) : null}
      </SBLCommentsInformationTitleWrap>

      {isIncomplete ? (
        <NoDataStub onAddData={onOpenEdit} />
      ) : (
        <SBLCommentsInformationList>
          {comment}
        </SBLCommentsInformationList>
      )}
    </SBLCommentsInformation>
  );
};
