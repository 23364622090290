import { plainToInstance } from 'class-transformer';
import {
  IsBoolean, IsNumber, IsOptional, IsString, IsDefined,
} from 'class-validator';
import { BaseDTM } from 'proto/BaseDTM';

import { AirVentDTM, IAirVentDTM } from 'shipment-operations/models/dtm/AirVent.dtm';

export interface ITemperatureControlDTM {
  id?: number,
  temperature?: string
  airVent?: IAirVentDTM
  genset: boolean
}

export class TemperatureControlDTM extends BaseDTM<ITemperatureControlDTM> {
  @IsOptional()
  @IsNumber()
  id?: number;

  @IsOptional()
  @IsString()
  temperature?: string;

  @IsOptional()
  @IsString()
  airVent: AirVentDTM

  @IsBoolean()
  @IsDefined()
  genset: boolean;

  static createEmpty = () => plainToInstance(TemperatureControlDTM, { references: [] });
}
