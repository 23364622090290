import React, { FC, memo } from 'react';
import { useSelector } from 'react-redux';

import { R } from 'user-management/repository';
import { UC } from 'user-management/controllers';

import { NewOrganizationDataComponent } from './NewOrganizationData.component';

const NewOrganizationDataContainer: FC = () => {
  const isLoading = useSelector(R.selectors.newOrganizationData.getIsLoading);
  const organizationUpdate = useSelector(R.selectors.newOrganizationData.getUserOrganizationUpdate);
  const organizationFieldsErrors = useSelector(R.selectors.newOrganizationData.organizationFieldsErrors);
  const organizationCountries = useSelector(R.selectors.newOrganizationData.organizationCountriesLimited);

  return (
    <NewOrganizationDataComponent
      isLoading={isLoading}
      organization={organizationUpdate}
      organizationFieldsErrors={organizationFieldsErrors}
      countriesList={organizationCountries}
      onOpen={UC.newOrganizationData.uploadData}
      onClose={UC.newOrganizationData.clear}
      onNext={UC.newOrganizationData.nextOrganizationDataStep}
      onSave={UC.newOrganizationData.saveOrganizationDataStep}
      onCancel={UC.newOrganizationData.cancelWizard}
      onChangeUpdateAddress1={UC.newOrganizationData.onChangeUpdateAddress1}
      onChangeUpdateAddress2={UC.newOrganizationData.onChangeUpdateAddress2}
      onChangeUpdateAddressCity={UC.newOrganizationData.onChangeUpdateAddressCity}
      onChangeUpdateAddressPostalCode={UC.newOrganizationData.onChangeUpdateAddressPostalCode}
      onChangeUpdateAddressCountry={UC.newOrganizationData.onChangeUpdateAddressCountry}
      onChangeUpdatePhone={UC.newOrganizationData.onChangeUpdatePhone}
      onFocusUpdatePhone={UC.newOrganizationData.onFocusUpdatePhone}
      onChangeUpdatePhone2={UC.newOrganizationData.onChangeUpdatePhone2}
      onChangeUpdateEmail={UC.newOrganizationData.onChangeUpdateEmail}
      onChangeUpdateWebUrl={UC.newOrganizationData.onChangeUpdateWebUrl}
      onBlurUpdateAddress1={UC.newOrganizationData.onBlurUpdateAddress1}
      onBlurUpdateAddress2={UC.newOrganizationData.onBlurUpdateAddress2}
      onBlurUpdateAddressCity={UC.newOrganizationData.onBlurUpdateAddressCity}
      onBlurUpdateAddressPostalCode={UC.newOrganizationData.onBlurUpdateAddressPostalCode}
      onBlurUpdateAddressCountry={UC.newOrganizationData.onBlurUpdateAddressCountry}
      onClearUpdateAddressCountry={UC.newOrganizationData.onClearUpdateAddressCountry}
      onBlurUpdatePhone={UC.newOrganizationData.onBlurUpdatePhone}
      onBlurUpdatePhone2={UC.newOrganizationData.onBlurUpdatePhone2}
      onFocusUpdatePhone2={UC.newOrganizationData.onFocusUpdatePhone2}
      onBlurUpdateEmail={UC.newOrganizationData.onBlurUpdateEmail}
      onFocusUpdateEmail={UC.newOrganizationData.onFocusUpdateEmail}
      onBlurUpdateWebUrl={UC.newOrganizationData.onBlurUpdateWebUrl}
      onFocusUpdateWebUrl={UC.newOrganizationData.onFocusUpdateWebUrl}
    />
  );
};

export const NewOrganizationData = memo(NewOrganizationDataContainer);
