import Divider from 'antd/es/divider';
import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';

export const textColorFirst = css`
  color: ${({ theme }) => theme.themesColors.characterBrandingTitle85};
`;

export const divFlex = css`
  display: flex;
  position: relative;
  ${textColorFirst}
`;

export const ARQSpanEllipsis = styled.span.attrs({
  'data-class': 'ARQSpanEllipsis',
})`
  max-width: 100%;
  position: relative;
  display: inline;

  text-overflow: ellipsis;
  overflow: clip;
  white-space: nowrap;
`;

// Div

export const ContainerComponentItemDiv = styled.div.attrs({
  'data-class': 'ContainerComponentItemDiv',
})`
  ${divFlex}
  flex-direction: column;
  background-color: ${({ theme }) => theme.themesColors.neutralBranding1};
  padding: 14px 0px;
  padding-bottom: 8px;
  border: 1px solid ${({ theme }) => theme.themesColors.neutralBranding3};
  border-radius: 4px;
  margin-bottom: 16px;
`;

export const RCQQuotesItemDiv = styled(ContainerComponentItemDiv).attrs({
  'data-class-second': 'RCQQuotesItemDiv',
})`
  padding: 0px;
  padding-bottom: 0;
`;

export const RCQQuotesItemInfiniteScrollSpin = styled.div`
  ${divFlex}
  align-items: center;
  justify-content: center;
`;

// Content Header

export const RCQQuotesItemHeader = styled.div.attrs({
  'data-class': 'RCQQuotesItemHeader',
})`
${divFlex}
width: 100%;
border-bottom: 1px solid ${({ theme }) => theme.themesColors.neutralBranding3};
padding-left: 16px;
padding-right: 16px;
padding-bottom: 6px;
`;

export const RCQQuotesItemHeaderLeft = styled.div`
${divFlex}
`;

export const RCQQuotesItemHeaderLeftIcon = styled.div`
${divFlex}
align-items: center;
svg {
  color: ${({ theme }) => theme.themesColors.neutralBranding6};
}
`;

const RCQQuotesItemHeaderLeftTextCss = css`
color: ${({ theme }) => theme.themesColors.neutralBranding10};
font-weight: 500;
font-size: 16px;
`;

export const RCQQuotesItemHeaderLeftText = styled.div`
${divFlex}
align-items: center;
${RCQQuotesItemHeaderLeftTextCss}
padding-left: 8px;
padding-right: 6px;
`;

export const RCQQuotesItemHeaderLeftTextLink = styled.div`
${divFlex}
cursor: pointer;
a {
  ${RCQQuotesItemHeaderLeftTextCss}
}
`;

export const RCQQuotesItemHeaderLeftTextIcon = styled.div`
${divFlex}
align-items: center;
`;

export const RCQQuotesItemHeaderRight = styled.div`
${divFlex}
margin-left: auto;
align-items: center;
`;

export const RCQQuotesItemHeaderRightText = styled.div`
${divFlex}
font-weight: 400;
font-size: 14px;
padding-right: 8px;
`;

export const RCQQuotesItemHeaderRightIcon = styled.div.attrs((props: {
  colorStatus: string,
}) => ({
  colorStatus: props.colorStatus,
}))`
${divFlex}
width: 6px;
height: 6px;
border-radius: 50%;
background-color: ${(props) => (props.colorStatus)};
align-items: center;
`;

// Content

export const RCQQuotesItemContent = styled.div.attrs({
  'data-class': 'RCQQuotesItemContent',
})`
${divFlex}
width: 100%;
padding-top: 8px;
padding-bottom: 7px;
padding-left: 16px;
padding-right: 16px;
`;

export const ARQCardContainer = styled(NavLink).attrs((props: {
  isClickable?: boolean,
}) => ({
  isClickable: props.isClickable,
  'data-class': 'ARQCardContainer',
}))`
  ${divFlex}
  padding: 0;

  width: 100%;
  height:  76px;

  border: 1px solid ${({ theme }) => theme.themesColors.neutralBranding4};
  border-radius: 4px;

  background-color: ${({ theme }) => theme.themesColors.neutralBranding1};
  line-height: inherit !important;
  color: ${({ theme }) => theme.themesColors.neutralBranding13} !important;

  ${({ isClickable }) => !isClickable && 'cursor: auto;'};
  ${({ isClickable }) => !isClickable && 'border: none;'};
  ${({ isClickable }) => !isClickable && 'box-shadow: none;'};

  &:hover {
    ${({ isClickable }) => !isClickable && 'border: none;'};
    ${({ isClickable }) => !isClickable && 'box-shadow: none;'};
  }
`;

export const ARQCardSectionIcon = styled.div.attrs({
  'data-class': 'ARQCardSectionIcon',
})`
  ${divFlex}

  justify-content: center;
  align-items: center;
  margin-left: 16px;
  margin-right: 8px;
`;

export const ARQCardSectionContentDescriptions = styled.div.attrs({
  'data-class': 'ARQCardSectionContentDescriptions',
})`
  ${divFlex}

  justify-content: center;
  align-items: center;
  margin-left: 16px;
  margin-right: 8px;
`;

export const ARQCardSectionDivider = styled(Divider).attrs({
  'data-class': 'ARQCardSectionDivider',
})`
  ${divFlex}
  width: 100%;
  margin: 0;
`;
