import React, { useCallback } from 'react';
import { useLocation } from 'react-router';
import { NavLink } from 'react-router-dom';

import { AppBreadcrumbStyled } from './AppBreadcrumb.styled';
import { getNameAppBreadcrumb } from './GetNameAppBreadcrumb';

const { Item } = AppBreadcrumbStyled;

const minRouteLevelToShowBreadcrumb = 1;

const notBreadcrumbRoutes = ['active', 'completed', 'cancelled'];

export const AppBreadcrumb = () => {
  const { pathname } = useLocation();
  const activeRouteItems = pathname.split('/');
  // we need to exclude first empty element
  activeRouteItems.shift();

  const getUpperWord = useCallback(
    (value: string) => value.charAt(0).toUpperCase() + value.slice(1),
    [],
  );

  const getUpperDash = useCallback(
    (value: string) => {
      const indexNum = value.indexOf('-');

      return `${getUpperWord(value.slice(0, indexNum))} ${getUpperWord(value.slice(indexNum + 1))}`;
    },
    [],
  );

  const getUpperUnderscore = useCallback(
    (value: string) => {
      const indexNum = value.indexOf('_');

      return `${getUpperWord(value.slice(0, indexNum))} ${getUpperWord(value.slice(indexNum + 1))}`;
    },
    [],
  );

  if (activeRouteItems.length <= minRouteLevelToShowBreadcrumb) {
    return null;
  }

  if (notBreadcrumbRoutes.includes(activeRouteItems[activeRouteItems.length - 1])) {
    return null;
  }

  return (
    <AppBreadcrumbStyled className="AppBreadcrumb">
      {
        activeRouteItems.map((routeItemName, index) => {
          const link = `/${activeRouteItems.slice(0, index + 1).join('/')}`;
          const isLast = activeRouteItems.length === index + 1;
          let breadcrumbName = getUpperWord(routeItemName);

          if (breadcrumbName.includes('-')) {
            breadcrumbName = getUpperDash(breadcrumbName);
          }
          if (breadcrumbName.includes('-')) {
            breadcrumbName = getUpperDash(breadcrumbName);
          }

          if (breadcrumbName.includes('_')) {
            breadcrumbName = getUpperUnderscore(breadcrumbName);
          }
          if (breadcrumbName.includes('_')) {
            breadcrumbName = getUpperUnderscore(breadcrumbName);
          }

          if (getNameAppBreadcrumb({ url: pathname, index })) {
            breadcrumbName = getNameAppBreadcrumb({ url: pathname, index });
          }

          return (
            <Item key={routeItemName}>
              {
                isLast
                  ? breadcrumbName
                  : (<NavLink to={link}>{breadcrumbName}</NavLink>)
              }
            </Item>
          );
        })
      }
    </AppBreadcrumbStyled>
  );
};
