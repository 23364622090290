import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  getOr,
  includes,
  head,
} from 'lodash/fp';

import {
  BillOfLadingDTM,
  CustomsClearanceDTM,
  HBLDocumentBOLDTM,
} from 'shipment-operations/models/dtm';
import { BillOfLadingTypeNames, BillOfLadingType } from 'shipment-operations/constants';

import { Cell } from '../Cell';
import { Column, Row, ShipmentTransportWrapper } from '../Common.styled';

const freights = ['Collect', 'Prepaid'];

const DEFAULT_VALUE = 'N/A';

interface PaymentsAndCustomsInformationProps {
  data: HBLDocumentBOLDTM
}

export const PaymentsAndCustomsInformation: FC<PaymentsAndCustomsInformationProps> = ({ data }) => {
  const { t } = useTranslation();
  const leg = head(data.legs);

  const departureLocationName: string = getOr('', ['departureLocation', 'name'], leg);
  const freight = getOr('', ['paymentTerms', 'freight'], data);
  const type: BillOfLadingType = getOr('EBL', ['billOfLading', 'type'], data);
  const originals: BillOfLadingDTM['originals'] = getOr([], ['billOfLading', 'originals'], data);
  const customsClearance = getOr(data, ['exportCustoms'], data) as CustomsClearanceDTM;

  const freightPayableAt = includes(freight, freights) ? departureLocationName : '-';
  const number = originals.reduce((acc, item) => {
    acc += Number(item.amount);

    return acc;
  }, 0);

  const { departureTime } = data;

  return (
    <ShipmentTransportWrapper>
      <Row>
        <Column><Cell label={t('Shipped On Board')} value={departureTime?.getDateDMMMYYYYWithOffset() || DEFAULT_VALUE} /></Column>
        <Column><Cell label={t('Freight Payable At')} value={freightPayableAt} /></Column>
        <Column><Cell label={t('Release Type')} value={type ? BillOfLadingTypeNames[type] : DEFAULT_VALUE} /></Column>
        <Column><Cell label={t('No. of Original B/L')} value={String(number)} /></Column>
      </Row>
      <Row>
        <Column><Cell label={t('Export References')} value={customsClearance?.number || DEFAULT_VALUE} /></Column>
        <Column>
          <Cell label={t('Import References')} value={DEFAULT_VALUE} />
        </Column>
      </Row>
    </ShipmentTransportWrapper>
  );
};
