import React, {
  FC, SyntheticEvent,
  useCallback,
  useRef,
  useState,
} from 'react';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import { ContentTableTasksAssigneeUsers } from 'app-wrapper/view/pages/CommandCenterPage/CommandCenter/components/ContentTableTasks/Components';
import { ENotificationCriticality, RouteNames } from 'app-wrapper/constants';
import {
  PriorityHighSvg, PriorityLowSvg, PriorityMediumSvg, VerticalFormItemSvg,
} from 'app-wrapper/view/icons';
import { CollapseOnChangeHandler } from 'app-wrapper/hooks';
import {
  Collapse, Panel, Tooltip,
} from 'app-wrapper/view/components';
import themesColors from 'app-wrapper/view/themes/themesColors';
import { CommandCenterComponentTaskDTM } from 'app-wrapper/models/dtm';
import { ETasksType } from 'shipment-operations/constants';

import { GapVerticalContainerStyled } from 'app-wrapper/view/guideline';
import { sanitizeHtml } from 'app-wrapper/utils/sanitizeHtml';
import { SkeletonSection } from 'app-wrapper/view/components/Skeleton/Skeleton.component';
import {
  CCContentTable,
  CCContentTableContent,
  CCContentTableContentDueDate,
  CCContentTableContentLink,
  CCContentTableContentExpand,
  CCContentTableContentItem,
  CCContentTableContentItemBottom,
  CCContentTableContentItemTop,
  CCContentTableContentTitle,
  CCContentTableHeader,
  CCContentTableHeaderItem,
  CCContentTableContentModule,
  CCContentTableContentAssignee,
  CCContentTableContentAction,
  CCContentTableContentItemBottomDescription,
  CCSpanEllipsis,
  CCContentTableContentHeaderBlock,
  TasksButton,
  CCContentTableContentHeaderAlert,
  CCContentTableSubText,
  TextLink,
} from './AlertsTable.styled';
import { DatePriority } from './components';
import { AllTasksDone } from '../AllTasksDone';

interface IContentTableTasksProps {
  allTasks?: CommandCenterComponentTaskDTM[];
  isLoading?: boolean
  countAllTasks?: number;
  goToLink?: string;
  openAgreementsDrawer: (shipmentId: string) => void;
  openVGMDrawer: (shipmentId: string) => void;
  openRailBillingDrawer: (shipmentId: string) => void;
  openShipmentCostChangesDrawer: (taskId: string) => void;
  openMismatchDrawer: () => void;
  openMaerskDrawer: () => void;
  openChangesDrawer: (withValidate?: boolean) => void;
  openBookingConfirmationDrawer?: () => void;
  setShipmentId?: (shipmentId: string) => void;
}

export const AlertsTableComponent: FC<IContentTableTasksProps> = ({
  allTasks,
  isLoading,
  countAllTasks,
  goToLink,
  openAgreementsDrawer,
  openVGMDrawer,
  openRailBillingDrawer,
  openMismatchDrawer,
  openShipmentCostChangesDrawer,
  openMaerskDrawer,
  openChangesDrawer,
  openBookingConfirmationDrawer,
  setShipmentId,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const getPriority = useCallback(
    (priority?: ENotificationCriticality | string) => {
      switch (priority) {
        case ENotificationCriticality.HIGH:
          return <PriorityHighSvg />;
        case ENotificationCriticality.MEDIUM:
          return <PriorityMediumSvg />;
        case ENotificationCriticality.LOW:
          return <PriorityLowSvg />;
        default:
          return null;
      }
    },
    [],
  );

  const goToCommandCenterTasks = useCallback(() => {
    navigate(goToLink || RouteNames.COMMAND_CENTER_TASKS_ALERTS());
  }, [navigate, getPriority, goToLink]);

  const endVisible = useRef<HTMLElement>(null);

  const [
    changeIsCollapseState, setChangeIsCollapseState,
  ] = useState<Array<{
    id: string;
    activeKey: string[];
  }>>([]);

  const changeErrorIsCollapseCallback = useCallback(
    (id: string) => () => {
      setChangeIsCollapseState((prev) => {
        let isFindId = false;

        const newState = prev.map((item) => {
          if (item.id === id) {
            isFindId = true;

            return {
              id,
              activeKey: item.activeKey?.length ? [] : ['1'],
            };
          }

          return item;
        });

        if (!isFindId) {
          newState.push({
            id,
            activeKey: ['1'],
          });
        }

        return newState;
      });
    },
    [],
  );
  const collapseOnChangeHandler = useCallback(
    (id: string) => CollapseOnChangeHandler(
      endVisible.current, changeErrorIsCollapseCallback(id),
    ),
    [],
  );

  const getObjectName = useCallback((item: CommandCenterComponentTaskDTM) => {
    if (item.rateOrganizationIdName) {
      return item.rateOrganizationIdName ? `${item.rateOrganizationType?.toLocaleLowerCase() || ''} #${item.rateOrganizationIdName.toLowerCase() || ''}` : '';
    }

    if (item.rateRequestName) {
      return item.rateRequestName ? `${item.rateRequestType?.toLocaleLowerCase() || ''} #${item.rateRequestName.toLowerCase() || ''}` : '';
    }

    if (item.shipmentName) {
      return item.shipmentName || '';
    }

    return '';
  }, []);

  const handleGoToShipment = useCallback((item: CommandCenterComponentTaskDTM) => {
    if (item.rateOrganizationIdName && item?.organizationId) {
      navigate(RouteNames.ACCOUNT_CUSTOMER_GENERAL_INFO(item.organizationId));
    }

    if (item.shipmentName && item?.shipmentId) {
      navigate(RouteNames.SHIPMENT_OVERVIEW(item?.shipmentId));
    }

    if (item.rateRequestName && item?.requestId) {
      navigate(RouteNames.RATES_FREIGHT_BY_ID(item.requestId));
    }
  }, [navigate]);

  const handleTaskActionClick = useCallback((item: CommandCenterComponentTaskDTM, e: SyntheticEvent) => {
    if (setShipmentId) {
      setShipmentId(String(item.shipmentId) || '');
    }
    if (item.taskType === ETasksType.NRA_AGREEMENT_TASK && item.shipmentId && openAgreementsDrawer) {
      e.preventDefault();
      openAgreementsDrawer(String(item.shipmentId));
    } else if (item.taskType === ETasksType.VGM_TASK && item.shipmentId) {
      e.preventDefault();
      openVGMDrawer(String(item.shipmentId));
    } else if (item.taskType === ETasksType.SUBMIT_RAIL_BILLING && item.shipmentId) {
      e.preventDefault();
      openRailBillingDrawer(String(item.shipmentId));
    } else if (item.taskType === ETasksType.CREATE_BOOKING_REQUEST_TASK && openMaerskDrawer) {
      e.preventDefault();
      openMaerskDrawer();
    } else if (item.taskType === ETasksType.VALIDATE_BOOKING_INFORMATION && openMismatchDrawer) {
      e.preventDefault();
      openMismatchDrawer();
    } else if (item.taskType === ETasksType.VALIDATE_SCHEDULE_UPDATES && openMismatchDrawer) {
      e.preventDefault();
      openMismatchDrawer();
    } else if (item.taskType === ETasksType.VALIDATE_SHIPMENT_CHANGES && openChangesDrawer) {
      e.preventDefault();
      openChangesDrawer(true);
    } else if (item.taskType === ETasksType.SUBMIT_BOOKING_AMEND_REQUEST && openChangesDrawer) {
      e.preventDefault();
      openChangesDrawer();
    } else if (item.taskType === ETasksType.CONFIRM_SHIPMENT_REQUEST && openBookingConfirmationDrawer) {
      e.preventDefault();
      openBookingConfirmationDrawer();
    } else if (item.taskType === ETasksType.CONFIRM_SHIPMENT_COST_CHANGE_TASK && openShipmentCostChangesDrawer) {
      e.preventDefault();
      openShipmentCostChangesDrawer(String(item.id));
    }
  }, [openAgreementsDrawer, openVGMDrawer, openRailBillingDrawer, openShipmentCostChangesDrawer]);

  const countAllTasksSum = (countAllTasks || 0) - (allTasks?.length || 0);

  if (isLoading) {
    return (
      <GapVerticalContainerStyled>
        <SkeletonSection height="327px" />
      </GapVerticalContainerStyled>
    );
  }

  if (!allTasks?.length) {
    return null;
  }

  return (
    <CCContentTable>
      <CCContentTableContentHeaderBlock>
        <CCContentTableContentHeaderAlert>
          {countAllTasks || 0}
        </CCContentTableContentHeaderAlert>
        {t('Alerts')}
      </CCContentTableContentHeaderBlock>

      <CCContentTableHeader>
        <CCContentTableHeaderItem
          width="41%"
        >
          {t('Alert')}
        </CCContentTableHeaderItem>
        <CCContentTableHeaderItem
          width="14.5%"
        >
          {t('dueDate')}
        </CCContentTableHeaderItem>
        <CCContentTableHeaderItem
          width="14.5%"
        >
          {t('object')}
        </CCContentTableHeaderItem>
        <CCContentTableHeaderItem
          width="16%"
        >
          {t('module')}
        </CCContentTableHeaderItem>
        <CCContentTableHeaderItem
          width="7%"
          isCenter
        >
          {t('assignee')}
        </CCContentTableHeaderItem>
        <CCContentTableHeaderItem
          width="7%"
          isCenter
        >
          {t('action')}
        </CCContentTableHeaderItem>
      </CCContentTableHeader>

      <CCContentTableContent id="CCContentTableContent">
        {allTasks?.map((item) => (
          <CCContentTableContentItem key={item.customId}>
            <CCContentTableContentItemTop>
              <CCContentTableContentExpand
                onClick={item.description ? changeErrorIsCollapseCallback(item.customId || item.id) : undefined}
                isEmpty={!!item.description}
              >
                {!!item.description && (
                  <VerticalFormItemSvg
                    rotate={changeIsCollapseState.filter((itemCollapse) => itemCollapse.id === item.customId)?.[0]?.activeKey.length ? 0 : -90}
                    style={{
                      color: changeIsCollapseState.filter((itemCollapse) => itemCollapse.id === item.customId)?.[0]?.activeKey.length ? themesColors.primaryBranding6 : themesColors.secondaryDot45,
                    }}
                  />
                )}
              </CCContentTableContentExpand>
              <CCContentTableContentTitle isActive={!!changeIsCollapseState.filter((itemCollapse) => itemCollapse.id === item.customId)?.[0]?.activeKey.length}>
                <CCSpanEllipsis
                  dangerouslySetInnerHTML={{ __html: sanitizeHtml(item.title || '') }}
                />
              </CCContentTableContentTitle>
              <CCContentTableContentDueDate>
                <DatePriority date={item.dueDate?.getFormatDMMMHHmm() || ''} mode={item.datePriority} isWarning={item.dateWarning} />
              </CCContentTableContentDueDate>
              <CCContentTableContentLink>
                <TextLink onClick={() => handleGoToShipment(item)}>
                  {getObjectName(item)}
                </TextLink>
              </CCContentTableContentLink>
              <CCContentTableContentModule>
                <CCSpanEllipsis>
                  {item.module}
                </CCSpanEllipsis>
              </CCContentTableContentModule>
              <CCContentTableContentAssignee isBgNone={!!item?.assigneeUsers?.length}>
                {item.assignee?.firstName && (
                  <Tooltip
                    title={(
                      <>
                        {item.assignee.getFullName() || ''}<br />
                        {item.assignee.email || ''}<br />
                        {item.assignee.phone || ''}
                      </>
                    )}
                    placement="top"
                  >
                    {!!item.assignee && item.assignee.getFirstLetterOfName().toLocaleUpperCase()}
                  </Tooltip>
                )}
                {!item.assignee?.firstName && item?.assigneeUsers?.length && (
                  <ContentTableTasksAssigneeUsers users={item.assigneeUsers} />
                )}
              </CCContentTableContentAssignee>
              <CCContentTableContentAction>
                <NavLink to={item.link()} onClick={(e) => handleTaskActionClick(item, e)}>
                  <VerticalFormItemSvg
                    rotate={-90}
                    style={{
                      color: themesColors.neutralBranding1,
                    }}
                  />
                </NavLink>
              </CCContentTableContentAction>
            </CCContentTableContentItemTop>

            <CCContentTableContentItemBottom>
              <Collapse
                onChange={collapseOnChangeHandler(item.customId || item.id)}
                activeKey={changeIsCollapseState.filter((itemCollapse) => itemCollapse.id === item.customId)?.[0]?.activeKey || []}
              >
                <Panel header="" key="1" extra={null}>
                  <CCContentTableContentItemBottomDescription
                    dangerouslySetInnerHTML={{ __html: sanitizeHtml(item.description || '') }}
                  />
                </Panel>
              </Collapse>
            </CCContentTableContentItemBottom>
          </CCContentTableContentItem>
        ))}

        {!allTasks?.length ? (
          <AllTasksDone />
        ) : null}
      </CCContentTableContent>

      {allTasks && allTasks.length && countAllTasksSum > 0 ? (
        <TasksButton
          onClick={goToCommandCenterTasks}
          type="default"
        >
          {t('View all alerts')}
          {countAllTasksSum > 0 ? (
            <CCContentTableSubText>
              ({countAllTasksSum})
            </CCContentTableSubText>
          ) : null}
        </TasksButton>
      ) : null}
    </CCContentTable>
  );
};
