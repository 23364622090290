import React, { FC } from 'react';

import i18n from 'app-wrapper/i18n/i18n';
import { dateFormatETA } from 'app-wrapper/utils';

import { IFreightQuotaContentDTM } from 'monetary/models/dtm';

import {
  RFQItemCutoff,
  RFQItemCutoffGoldTitleIcon,
  RFQItemCutoffTitle,
  RFQItemCutoffTitleIcon,
  RFQItemCutoffTitleText,
  RFQItemCutoffToDoc,
  RFQItemCutoffToDocContent,
  RFQItemCutoffToDocName,
  RFQItemCutoffToHazmat,
  RFQItemCutoffToHazmatContent,
  RFQItemCutoffToHazmatName,
  RFQItemCutoffToPort,
  RFQItemCutoffToPortContent,
  RFQItemCutoffToPortName,
} from '../styled';

export interface IRFQItemCutoffComponentProps {
  data?: IFreightQuotaContentDTM
  isMiniVisible: boolean
  indexSchedule: number
  isCutoffGold: boolean
}

export const RFQItemCutoffComponent: FC<IRFQItemCutoffComponentProps> = ({
  data, isMiniVisible, indexSchedule, isCutoffGold,
}) => (
  <RFQItemCutoff isMiniVisible={isMiniVisible}>
    <RFQItemCutoffTitle isMiniVisible={isMiniVisible}>
      {isCutoffGold
        ? <RFQItemCutoffGoldTitleIcon width={18} height={18} />
        : <RFQItemCutoffTitleIcon />}
      <RFQItemCutoffTitleText isMiniVisible={isMiniVisible}>{i18n.t('Cutoff')}</RFQItemCutoffTitleText>
    </RFQItemCutoffTitle>
    <RFQItemCutoffToPort isMiniVisible={isMiniVisible}>
      <RFQItemCutoffToPortName>
        {i18n.t('Port')}
      </RFQItemCutoffToPortName>
      <RFQItemCutoffToPortContent>
        {dateFormatETA(data?.schedules?.[indexSchedule]?.terminalCutOff || '')}
      </RFQItemCutoffToPortContent>
    </RFQItemCutoffToPort>
    <RFQItemCutoffToDoc isMiniVisible={isMiniVisible}>
      <RFQItemCutoffToDocName>
        {i18n.t('Documentation')}
      </RFQItemCutoffToDocName>
      <RFQItemCutoffToDocContent>
        {dateFormatETA(data?.schedules?.[indexSchedule]?.documentCutOff || '')}
      </RFQItemCutoffToDocContent>
    </RFQItemCutoffToDoc>
    {data?.schedules?.[indexSchedule]?.hazmatCutOff && (
      <RFQItemCutoffToHazmat isMiniVisible={isMiniVisible}>
        <RFQItemCutoffToHazmatName>
          {i18n.t('Hazmat')}
        </RFQItemCutoffToHazmatName>
        <RFQItemCutoffToHazmatContent>
          {dateFormatETA(data.schedules[indexSchedule].hazmatCutOff as string)}
        </RFQItemCutoffToHazmatContent>
      </RFQItemCutoffToHazmat>
    )}
  </RFQItemCutoff>
);
