import React from 'react';
import { useSelector } from 'react-redux';

import { R } from 'user-management/repository';

import { RegisterAccountStepComponent } from './RegisterAccountStep.component';

const RegisterAccountStepContainer = () => {
  const organization = useSelector(R.selectors.userOrganizationData.getUserOrganization);
  const hasRequiredDocuments = useSelector(R.selectors.userOrganizationData.getHasRequiredDocuments);

  return (
    <RegisterAccountStepComponent
      status={organization?.status}
      hasRequiredDocuments={hasRequiredDocuments}
    />
  );
};

export { RegisterAccountStepContainer as RegisterAccountStep };
