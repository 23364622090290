import React from 'react';

import { Wrap, Text } from './MissingTextValue.styled';

export const MissingTextValue = () => (
  <Wrap>
    <Text>
      —
    </Text>
  </Wrap>
);
