import React from 'react';

import { IPropsIcon } from 'app-wrapper/interfaces/icon';

import { Icon } from './RFRCompletedRequestSvg.styled';

export const RFRCompletedRequestSvg = (props: IPropsIcon) => {
  const { width, height, fill } = props;
  return (
    <Icon
      className="RFRCompletedRequestSvg"
      width={width || '144'}
      height={height || '144'}
      viewBox="0 0 144 144"
      fill={fill || 'currentColor'}
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="144" height="144" rx="72" fill="#F9F9FB" />
      <path d="M118 33H102C99.7909 33 98 34.7909 98 37L98 53C98 55.2091 99.7909 57 102 57H118C120.209 57 122 55.2091 122 53V37C122 34.7909 120.209 33 118 33Z" fill="#E9F0FD" />
      <path fillRule="evenodd" clipRule="evenodd" d="M107.601 52.2221H36.4465V47.1889C36.4465 46.0971 37.313 45.2119 38.3818 45.2119H105.666C106.735 45.2119 107.601 46.0971 107.601 47.1889V52.2221Z" fill="#253368" />
      <path fillRule="evenodd" clipRule="evenodd" d="M41.4359 48.5032C41.4359 49.1884 40.8955 49.7439 40.2288 49.7439C39.5622 49.7439 39.0219 49.1884 39.0219 48.5032C39.0219 47.818 39.5622 47.2625 40.2288 47.2625C40.8955 47.2625 41.4359 47.818 41.4359 48.5032Z" fill="#FF7946" />
      <path fillRule="evenodd" clipRule="evenodd" d="M45.9426 48.5032C45.9426 49.1884 45.4022 49.7439 44.7356 49.7439C44.069 49.7439 43.5286 49.1884 43.5286 48.5032C43.5286 47.818 44.069 47.2625 44.7356 47.2625C45.4022 47.2625 45.9426 47.818 45.9426 48.5032Z" fill="#FAAD14" />
      <path fillRule="evenodd" clipRule="evenodd" d="M50.4494 48.5032C50.4494 49.1884 49.909 49.7439 49.2423 49.7439C48.5758 49.7439 48.0354 49.1884 48.0354 48.5032C48.0354 47.818 48.5758 47.2625 49.2423 47.2625C49.909 47.2625 50.4494 47.818 50.4494 48.5032Z" fill="#7CB305" />
      <path fillRule="evenodd" clipRule="evenodd" d="M38.533 98.801C37.3413 98.801 36.375 97.9439 36.375 96.8865V51.301H107.625V96.8865C107.625 97.9439 106.659 98.801 105.467 98.801H38.533Z" fill="#E4EBF7" />
      <path d="M101.948 55.7944H57.4573C56.9808 55.7944 56.5946 56.1807 56.5946 56.6571V65.202C56.5946 65.6785 56.9808 66.0647 57.4573 66.0647H101.948C102.425 66.0647 102.811 65.6785 102.811 65.202V56.6571C102.811 56.1807 102.425 55.7944 101.948 55.7944Z" fill="#8BBC06" />
      <path d="M86.2821 62.2134H61.5692C61.3034 62.2134 61.0878 62.4289 61.0878 62.6948C61.0878 62.9607 61.3034 63.1762 61.5692 63.1762H86.2821C86.548 63.1762 86.7635 62.9607 86.7635 62.6948C86.7635 62.4289 86.548 62.2134 86.2821 62.2134Z" fill="white" />
      <path d="M95.2686 59.0039H61.5692C61.3034 59.0039 61.0878 59.2194 61.0878 59.4853C61.0878 59.7512 61.3034 59.9667 61.5692 59.9667H95.2686C95.5345 59.9667 95.75 59.7512 95.75 59.4853C95.75 59.2194 95.5345 59.0039 95.2686 59.0039Z" fill="white" />
      <path d="M101.948 69.916H57.4573C56.9808 69.916 56.5946 70.3023 56.5946 70.7787V79.3236C56.5946 79.8 56.9808 80.1863 57.4573 80.1863H101.948C102.425 80.1863 102.811 79.8 102.811 79.3236V70.7787C102.811 70.3023 102.425 69.916 101.948 69.916Z" fill="white" />
      <path d="M86.2821 76.335H61.5692C61.3034 76.335 61.0878 76.5505 61.0878 76.8164C61.0878 77.0823 61.3034 77.2978 61.5692 77.2978H86.2821C86.548 77.2978 86.7635 77.0823 86.7635 76.8164C86.7635 76.5505 86.548 76.335 86.2821 76.335Z" fill="#E4EBF7" />
      <path d="M95.2686 73.1255H61.5692C61.3034 73.1255 61.0878 73.341 61.0878 73.6069C61.0878 73.8728 61.3034 74.0883 61.5692 74.0883H95.2686C95.5345 74.0883 95.75 73.8728 95.75 73.6069C95.75 73.341 95.5345 73.1255 95.2686 73.1255Z" fill="#E4EBF7" />
      <path d="M101.948 84.0376H57.4573C56.9808 84.0376 56.5946 84.4238 56.5946 84.9003V93.4452C56.5946 93.9216 56.9808 94.3079 57.4573 94.3079H101.948C102.425 94.3079 102.811 93.9216 102.811 93.4452V84.9003C102.811 84.4238 102.425 84.0376 101.948 84.0376Z" fill="white" />
      <path d="M86.2821 90.4565H61.5692C61.3034 90.4565 61.0878 90.6721 61.0878 90.938C61.0878 91.2038 61.3034 91.4194 61.5692 91.4194H86.2821C86.548 91.4194 86.7635 91.2038 86.7635 90.938C86.7635 90.6721 86.548 90.4565 86.2821 90.4565Z" fill="#E4EBF7" />
      <path d="M95.2686 87.2471H61.5692C61.3034 87.2471 61.0878 87.4626 61.0878 87.7285C61.0878 87.9944 61.3034 88.2099 61.5692 88.2099H95.2686C95.5345 88.2099 95.75 87.9944 95.75 87.7285C95.75 87.4626 95.5345 87.2471 95.2686 87.2471Z" fill="#E4EBF7" />
      <path d="M52.5224 55.7944H42.6938C42.2173 55.7944 41.8311 56.1807 41.8311 56.6571V65.202C41.8311 65.6785 42.2173 66.0647 42.6938 66.0647H52.5224C52.9989 66.0647 53.3851 65.6785 53.3851 65.202V56.6571C53.3851 56.1807 52.9989 55.7944 52.5224 55.7944Z" fill="#8BBC06" />
      <path d="M52.5224 69.916H42.6938C42.2173 69.916 41.8311 70.3023 41.8311 70.7787V79.3236C41.8311 79.8 42.2173 80.1863 42.6938 80.1863H52.5224C52.9989 80.1863 53.3851 79.8 53.3851 79.3236V70.7787C53.3851 70.3023 52.9989 69.916 52.5224 69.916Z" fill="white" />
      <path d="M52.5224 84.0376H42.6938C42.2173 84.0376 41.8311 84.4238 41.8311 84.9003V93.4452C41.8311 93.9216 42.2173 94.3079 42.6938 94.3079H52.5224C52.9989 94.3079 53.3851 93.9216 53.3851 93.4452V84.9003C53.3851 84.4238 52.9989 84.0376 52.5224 84.0376Z" fill="white" />
      <path fillRule="evenodd" clipRule="evenodd" d="M104 83.5C96.5441 83.5 90.5 89.5441 90.5 97C90.5 104.456 96.5441 110.5 104 110.5C111.456 110.5 117.5 104.456 117.5 97C117.5 89.5441 111.456 83.5 104 83.5Z" fill="#8BBC06" />
      <path fillRule="evenodd" clipRule="evenodd" d="M101.825 101.586L100.972 100.734L109.232 92.4443C109.514 92.1618 109.972 92.1618 110.254 92.4443L110.595 92.7852C110.878 93.0676 110.878 93.5255 110.595 93.808L102.847 101.586C102.565 101.868 102.107 101.868 101.825 101.586Z" fill="white" />
      <path fillRule="evenodd" clipRule="evenodd" d="M103.698 100.734L102.846 101.586C102.563 101.868 102.106 101.868 101.823 101.586L98.4293 98.192C98.1469 97.9096 98.1469 97.4516 98.4293 97.1692L98.7702 96.8283C99.0527 96.5458 99.5106 96.5458 99.793 96.8283L103.698 100.734Z" fill="white" />
      <path d="M33 101H27C25.8954 101 25 101.895 25 103L25 109C25 110.105 25.8954 111 27 111H33C34.1046 111 35 110.105 35 109V103C35 101.895 34.1046 101 33 101Z" fill="#E4EBF7" />
    </Icon>
  );
};
