import AntdDivider from 'antd/es/divider';
import CheckCircleOutlined from '@ant-design/icons/CheckCircleOutlined';
import InfoCircleOutlined from '@ant-design/icons/InfoCircleOutlined';
import styled from 'styled-components';

import { colors } from 'app-wrapper/view/themes/themesColors';

export const ShipmentContainersFormCardListContainer = styled.div.attrs({
  'data-class': 'ShipmentContainersFormCardListContainer',
})`
  display: flex;
  flex-flow: column nowrap;
  justify-content: stretch;
  align-items: stretch;
  gap: 8px
`;

export const ShipmentContainersFormCardContainer = styled.div.attrs({
  'data-class': 'ShipmentContainersFormCardContainer',
})`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: stretch;
  height: 105px;
`;

export const Divider = styled(AntdDivider)`
  margin: 0;
`;

export const ShipmentContainersFormCardTopWrapper = styled.div.attrs({
  'data-class': 'ShipmentContainersFormCardTopWrapper',
})`
  display: grid;
  grid-template-columns: 16px auto 92px 92px 92px;
  grid-template-rows: 32px 20px;
  column-gap: 4px;
  align-items: center;
  justify-items: left;
  width: 100%;
  
  .ant-checkbox-checked .ant-checkbox-inner {
    border-color: ${colors.buttonPrimaryColor};
    background-color: ${colors.buttonPrimaryColor};
  }
`;

export const ContainerCardTopName = styled.span.attrs({
  'data-class': 'ContainerCardTopName',
})`
  padding-left: 4px;
  font-weight: 400;
  font-size: 12px;
  
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 95%;
  overflow: hidden;
`;

export const ContainerCardTopContainer = styled.div.attrs({
  'data-class': 'ContainerCardTopContainer',
})`
  display: grid;
  grid-template-columns: 16px auto 92px 92px 92px;
  grid-template-rows: 32px 20px;
  column-gap: 4px;
  row-gap: 0;
  align-items: center;
  justify-items: left;
  width: 100%;
`;

export const ShipmentContainersFormCardFullDataLongItem = styled.div.attrs({
  'data-class': 'ShipmentContainersFormCardFullDataLongItem',
})`
  grid-column-start: span 3;
  font-size: 12px;
  color: ${colors.gray61};;
`;

export const ShipmentContainersFormCardFullDataItem = styled.div.attrs({
  'data-class': 'ShipmentContainersFormCardFullDataItem',
})`
  color: ${colors.gray61};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 12px;
  width: 92px;
`;

export const InfoIcon = styled(InfoCircleOutlined).attrs({
  'data-class': 'InfoIcon',
})`
  color: ${colors.gray61};
`;

export const SuccessIcon = styled(CheckCircleOutlined).attrs({
  'data-class': 'SuccessIcon',
})`
  color: ${colors.green60};
`;
