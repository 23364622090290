import React, { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Wrap,
  Image,
  Content,
  Title,
  Items,
} from './PathToReapply.styled';
import replySrc from './assets/reply.png';

interface PathToReapplyProps {
  content: ReactNode;
}

export const PathToReapply: FC<PathToReapplyProps> = ({ content }) => {
  const { t } = useTranslation();

  return (
    <Wrap>
      <Image src={replySrc} />

      <Content>
        <Title>
          {t('Path To Path to Reapply')}
        </Title>

        <Items>
          {content}
        </Items>
      </Content>
    </Wrap>
  );
};
