import React, { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';

import { normalizationCost, validateDecimalFraction } from 'app-wrapper/utils';

import { UC } from 'monetary/controllers';
import { AdditionalRatesServiceDTM } from 'monetary/models/dtm/Quotas';
import { chargeCodePriceBy } from 'monetary/constants';

import { AddAdditionalServicesSelectItemComponent } from './AddAdditionalServicesSelectItem.component';

interface IAddAdditionalServicesSelectItemContainerProps {
  services?: AdditionalRatesServiceDTM;
  isHideCheckbox?: boolean;
  onDeletedService?: (id?: string) => () => void;
}

const AddAdditionalServicesSelectItemContainer: FC<IAddAdditionalServicesSelectItemContainerProps> = (props) => {
  const {
    services,
    isHideCheckbox,
    onDeletedService,
  } = props;
  const { t } = useTranslation();

  const totalCost = normalizationCost(validateDecimalFraction(`${Number(services?.countDocument || 0) * Number(services?.buyPrice || 0)}`, 12, 2, { forcePrecision: true }), {
    toFixed: 2,
    thousandthSeparatorComma: true,
  });

  const documentName = services?.priceBy === chargeCodePriceBy.CONTAINER ? t('Containers') : t('Documents');

  return (
    <AddAdditionalServicesSelectItemComponent
      isChecked={services?.isChecked}
      isHideCheckbox={isHideCheckbox}
      documentName={documentName}
      serviceName={services?.chargeCode?.description}
      information={services?.chargeCode?.additionalInformation}
      serviceCount={services?.countDocument}
      totalCost={`${totalCost}`}
      onChangeChecked={UC.AdditionalServices.onChangeCheckedAdditionalServiceDrawerItemById(services?.customId)}
      onChangeCount={UC.AdditionalServices.onChangeCountAdditionalServiceDrawerItemById(services?.customId)}
      onDeletedService={onDeletedService && onDeletedService(services?.customId)}
    />
  );
};

const AddAdditionalServicesSelectItemContainerCache = memo(AddAdditionalServicesSelectItemContainer);

export { AddAdditionalServicesSelectItemContainerCache as AddAdditionalServicesSelectItem };
