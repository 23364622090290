import React from 'react';

import { IPropsIcon } from 'app-wrapper/interfaces/icon';

import { Icon } from './VerticalDotsSvg.styled';

export const VerticalDotsSvg = (props: IPropsIcon) => {
  const { width, height, fill } = props;
  return (
    <Icon
      className="VerticalDotsSvg"
      width={width || '3'}
      height={height || '12'}
      viewBox="0 0 3 12"
      fill={fill || 'currentColor'}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M1.6875 4.75854C0.949219 4.75854 0.375 5.36011 0.375 6.07104C0.375 6.80933 0.949219 7.38354 1.6875 7.38354C2.39844 7.38354 3 6.80933 3 6.07104C3 5.36011 2.39844 4.75854 1.6875 4.75854ZM0.375 1.91479C0.375 2.65308 0.949219 3.22729 1.6875 3.22729C2.39844 3.22729 3 2.65308 3 1.91479C3 1.20386 2.39844 0.602295 1.6875 0.602295C0.949219 0.602295 0.375 1.20386 0.375 1.91479ZM0.375 10.2273C0.375 10.9656 0.949219 11.5398 1.6875 11.5398C2.39844 11.5398 3 10.9656 3 10.2273C3 9.51636 2.39844 8.91479 1.6875 8.91479C0.949219 8.91479 0.375 9.51636 0.375 10.2273Z" fill="currentColor" />
    </Icon>
  );
};
