import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import {
  MarksAndNumber,
  Wrap,
  MarksWrap,
  Title,
  Mark,
  Marks,
  Notes,
  Note,
} from './PDFMarksAndNumbers.styled';

interface PDFMarksAndNumbersComponentProps {
  marks: string[];
  notes: string[];
}

export const PDFMarksAndNumbersComponent: FC<PDFMarksAndNumbersComponentProps> = ({ marks, notes }: PDFMarksAndNumbersComponentProps) => {
  const { t } = useTranslation();

  return marks.length ? (
    <Wrap>
      <MarksAndNumber>
        <MarksWrap>
          <Title>
            {t('Marks & Numbers')}
          </Title>

          <Marks>
            {marks.map((mark) => (
              <Mark key={`PDFMarksAndNumbersComponent_${mark}`}>
                {mark}
              </Mark>
            ))}
          </Marks>
        </MarksWrap>

        <Notes>
          {notes.map((note) => (
            <Note key={`PDFMarksAndNumbersComponent_${note}`}>
              {note}
            </Note>
          ))}
        </Notes>
      </MarksAndNumber>
    </Wrap>
  ) : null;
};
