import React, { FC, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Skeleton from 'antd/es/skeleton';
import Alert from 'antd/es/alert';

import { Wrapper } from 'app-wrapper/view/guideline';
import { Container } from 'shipment-operations/view/components';
import { FullCreditNoteDTM } from 'shipment-operations/models/dtm';
import { PAYABLES } from 'shipment-operations/constants';

import {
  Header, CardsWrapper, Fees, Transportation, Services, Additional,
} from './components';
import {
  DescriptionContainer, DescriptionTitle, DescriptionStyled, Main,
  StyledTotal, TotalInner, TotalValue, AlertWrapper, StyledButton,
} from './CreditNote.styled';

interface ICreditNoteComponentProps {
  fetchData: (shipmentId?: string, creditNoteId?: string, category?: string) => void
  description?: string
  creditNote: FullCreditNoteDTM | null
  isLoading: boolean
  isError: boolean
  cleanup: () => void;
}

const CreditNoteComponent: FC<ICreditNoteComponentProps> = ({
  fetchData,
  description,
  creditNote,
  isLoading,
  isError,
  cleanup,
}) => {
  const { shipmentId } = useParams<'shipmentId'>();
  const { creditNoteId } = useParams<'creditNoteId'>();
  const { invoiceType } = useParams<'invoiceType'>();
  const { t } = useTranslation();

  const handleFetchData = useCallback(() => {
    fetchData(shipmentId, creditNoteId);
  }, []);

  useEffect(() => {
    fetchData(shipmentId, creditNoteId, invoiceType);

    return () => {
      cleanup();
    };
  }, []);

  if (isLoading) {
    return (
      <Wrapper>
        <Skeleton />
      </Wrapper>
    );
  }

  return isError ? (
    <Wrapper>
      <AlertWrapper>
        <Alert
          message={t('creditNote.errorMsg')}
          description={t('creditNote.errorDescription')}
          type="error"
          action={(
            <StyledButton size="middle" type="primary" onClick={handleFetchData}>
              {t('reload')}
            </StyledButton>
          )}
        />
      </AlertWrapper>
    </Wrapper>
  ) : (
    <Main>
      <Wrapper>
        <Header isAPInvoiceType={invoiceType === PAYABLES} />
        <CardsWrapper />
        <Container mBottom="0px">
          <Transportation />
          <Services />
          <Fees />
          <Additional />
        </Container>
        <StyledTotal>
          <TotalInner>
            <TotalValue>{t('creditNote.total')}</TotalValue>
            <TotalValue>$ {creditNote?.amount.toFixed(2)}</TotalValue>
          </TotalInner>
        </StyledTotal>
        {description && (
        <Container mBottom="80px">
          <DescriptionContainer>
            <DescriptionTitle>{t('creditNote.additionalNotes')}</DescriptionTitle>
            <DescriptionStyled>{description}</DescriptionStyled>
          </DescriptionContainer>
        </Container>
        )}
      </Wrapper>
    </Main>
  );
};

export { CreditNoteComponent };
