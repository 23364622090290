import React, { FC } from 'react';

import { Header, StepsForm } from './components';
import { Content } from './RequiredActionsFlow.styled';

export const RequiredActionsFlowComponent: FC = () => (
  <Content>
    <Header />
    <StepsForm />
  </Content>
);
