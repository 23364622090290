import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';
import { UC } from 'shipment-operations/controllers';

import { PackTypeSelectComponent } from './PackTypeSelect.component';

interface PackTypeSelectContainerProps {
  cargoId: number;
}

const PackTypeSelectContainer: FC<PackTypeSelectContainerProps> = ({ cargoId }) => {
  const { packageType, errors } = useSelector(R.selectors.bookingWizard.getCargoById(cargoId));
  const { packageType: error } = errors;

  return (
    <PackTypeSelectComponent
      packageType={packageType}
      touchCargoField={UC.bookingWizard.touchCargoField}
      setCargoPackageType={UC.bookingWizard.setPackageType}
      cargoId={cargoId}
      error={!!error}
    />
  );
};

export { PackTypeSelectContainer as PackTypeSelect };
