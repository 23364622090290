import React from 'react';

import { IPropsIcon } from 'app-wrapper/interfaces/icon';

import { Icon } from './MakeReadSvg.styled';

export const MakeReadSvg = (props: IPropsIcon) => {
  const { width, height, fill } = props;
  return (
    <Icon
      className="MakeReadSvg"
      width={width || '13'}
      height={height || '12'}
      viewBox="0 0 13 12"
      fill={fill || 'currentColor'}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M12.8128 0.968262H11.7206C11.5674 0.968262 11.4221 1.03857 11.3284 1.15889L4.88619 9.31983L1.79713 5.40576C1.7504 5.34643 1.69083 5.29846 1.62291 5.26544C1.55499 5.23243 1.48046 5.21523 1.40494 5.21514H0.312753C0.208065 5.21514 0.150253 5.33545 0.214315 5.4167L4.494 10.8386C4.694 11.0917 5.07838 11.0917 5.27994 10.8386L12.9112 1.16826C12.9753 1.08857 12.9174 0.968262 12.8128 0.968262Z" fill="currentColor" />
    </Icon>
  );
};
