import styled from 'styled-components';

import { divFlex } from 'app-wrapper/view/themes/mixin';

export const RFQLoaderWrap = styled.div.attrs({
  'data-class': 'RFQLoaderWrap',
})`
  ${divFlex}
  width: 320px;
  height: 202px;

  border-radius: 3px;
  border: 1px solid ${({ theme }) => theme.themesColors.neutralBranding4};
  background: ${({ theme }) => theme.themesColors.neutralBranding1};
`;

export const RFQLoaderContentWrap = styled.div.attrs({
  'data-class': 'RFQLoaderContentWrap',
})`
  ${divFlex}
  flex-direction: column;
  width: 100%;

  padding: 24px;
`;

export const RFQLoaderContentWrapImage = styled.div.attrs((props: {
  image: string
}) => ({
  image: props.image,
  'data-class': 'RFQLoaderContentWrapImage',
}))`
  ${divFlex}
  position: absolute;
  width: 100%;
  height: 100%;
  justify-content: end;
`;

export const RFQLoaderHeader = styled.div.attrs({
  'data-class': 'RFQLoaderHeader',
})`
  ${divFlex}
  align-items: center;

  height: 70px;
  margin-top: -18px;
`;

export const RFQLoaderHeaderLogo = styled.div.attrs({
  'data-class': 'RFQLoaderHeaderLogo',
})`
  ${divFlex}
`;

export const RFQLoaderHeaderTitle = styled.div.attrs({
  'data-class': 'RFQLoaderHeaderTitle',
})`
  ${divFlex}
  align-items: center;

  font-weight: 500;
  font-size: 20px;
  margin-left: 8px;
  color: ${({ theme }) => theme.themesColors.neutralBranding10};
`;

export const RFQLoaderContent = styled.div.attrs({
  'data-class': 'RFQLoaderContent',
})`
  ${divFlex}
  flex-direction: column;
  justify-content: center;
  width: 100%;

  height: 82px;
  margin-top: 20px;
`;

export const RFQLoaderStep = styled.div.attrs((props: {
  marginTop: string
}) => ({
  marginTop: props.marginTop,
  'data-class': 'RFQLoaderStep',
}))`
  ${divFlex}
  align-items: center;
  width: 100%;

  margin-top: ${({ marginTop }) => marginTop || ''};
  height: 22px;
`;

export const RFQLoaderStepLoading = styled.div.attrs((props: {
  isLoading: boolean
}) => ({
  isLoading: props.isLoading,
  'data-class': 'RFQLoaderStepLoading',
}))`
  ${divFlex}
  align-items: center;
  height: 2px;

  position: absolute;
  background-color: ${({ theme }) => theme.themesColors.neutralBranding10};
  width: 100%;
  border-radius: 2px;
  bottom: 0;
  left: 0;
  animation: ${({ isLoading }) => isLoading && 'progress 160s infinite linear'};
  transition: background-color 0.1s;

  @keyframes progress {
      0%{
        width: 0%;
      }
      2%{
        width: 50%;
      }
      20%{
        width: 80%;
      }
      100%{
          width: 100%;
      }
  };
`;

export const RFQLoaderStepLogo = styled.div.attrs({
  'data-class': 'RFQLoaderStepLogo',
})`
  ${divFlex}
  align-items: center;

  width: 32px;
  height: 16px;
`;

export const RFQLoaderStepDescription = styled.div.attrs((props: {
  isLoading: boolean
}) => ({
  isLoading: props.isLoading,
  'data-class': 'RFQLoaderStepDescription',
}))`
  ${divFlex}
  align-items: center;

  font-weight: 400;
  font-size: 14px;
  margin-left: 8px;
  color: ${({ theme, isLoading }) => (isLoading ? theme.themesColors.secondaryDot45 : theme.themesColors.neutralBranding10)};
  transition: color 0.4s;
`;
