import React from 'react';

import { IPropsIcon } from 'app-wrapper/interfaces/icon';

import { Icon } from './UserPersonSvg.styled';

export const UserPersonSvg = (props: IPropsIcon) => {
  const { width, height, fill } = props;
  return (
    <Icon
      className="UserPersonSvg"
      width={width || '14'}
      height={height || '16'}
      viewBox="0 0 14 16"
      fill={fill || 'currentColor'}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M13.187 12.4907C12.8501 11.6928 12.3613 10.968 11.7477 10.3568C11.136 9.74374 10.4113 9.25498 9.61377 8.91747C9.60663 8.9139 9.59949 8.91212 9.59234 8.90855C10.7048 8.10497 11.4281 6.79604 11.4281 5.31926C11.4281 2.87283 9.44591 0.890686 6.99949 0.890686C4.55306 0.890686 2.57091 2.87283 2.57091 5.31926C2.57091 6.79604 3.29413 8.10497 4.40663 8.91033C4.39949 8.9139 4.39234 8.91569 4.3852 8.91926C3.5852 9.25676 2.86734 9.74069 2.25127 10.3585C1.63825 10.9703 1.14949 11.6949 0.811987 12.4925C0.480419 13.2733 0.301597 14.1104 0.285201 14.9585C0.284724 14.9776 0.288067 14.9966 0.295033 15.0143C0.301998 15.0321 0.312445 15.0482 0.325759 15.0619C0.339072 15.0755 0.354982 15.0864 0.372551 15.0938C0.39012 15.1012 0.408993 15.105 0.428058 15.105H1.49949C1.57806 15.105 1.64056 15.0425 1.64234 14.9657C1.67806 13.5871 2.23163 12.296 3.2102 11.3175C4.2227 10.305 5.56734 9.74783 6.99949 9.74783C8.43163 9.74783 9.77627 10.305 10.7888 11.3175C11.7673 12.296 12.3209 13.5871 12.3566 14.9657C12.3584 15.0443 12.4209 15.105 12.4995 15.105H13.5709C13.59 15.105 13.6089 15.1012 13.6264 15.0938C13.644 15.0864 13.6599 15.0755 13.6732 15.0619C13.6865 15.0482 13.697 15.0321 13.7039 15.0143C13.7109 14.9966 13.7142 14.9776 13.7138 14.9585C13.6959 14.105 13.5191 13.2746 13.187 12.4907ZM6.99949 8.39069C6.17984 8.39069 5.40841 8.07104 4.82806 7.49069C4.2477 6.91033 3.92806 6.1389 3.92806 5.31926C3.92806 4.49962 4.2477 3.72819 4.82806 3.14783C5.40841 2.56747 6.17984 2.24783 6.99949 2.24783C7.81913 2.24783 8.59056 2.56747 9.17091 3.14783C9.75127 3.72819 10.0709 4.49962 10.0709 5.31926C10.0709 6.1389 9.75127 6.91033 9.17091 7.49069C8.59056 8.07104 7.81913 8.39069 6.99949 8.39069Z" fill="currentColor" />
    </Icon>
  );
};
