import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';

import { ShipmentContainersTitleComponent } from './ShipmentContainersTitle.component';

const ShipmentContainersTitleContainer: FC = () => {
  const containersCount = useSelector(R.selectors.shipmentContainers.getContainersCommonCount);

  return (
    <ShipmentContainersTitleComponent
      amountOfCargos={containersCount}
    />
  );
};

export {
  ShipmentContainersTitleContainer as ShipmentContainersTitle,
};
