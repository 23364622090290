import React from 'react';
import { observer } from 'mobx-react-lite';

import { useMobxStore } from 'app-wrapper/mobxStores';
import { UC } from 'user-management/controllers';
import { UC as appUC } from 'app-wrapper/controllers';

import { SalesRepresentativeDrawerComponent } from './SalesRepresentativeDrawer.component';

const SalesRepresentativeDrawerContainer = observer(() => {
  const { customerSales } = useMobxStore();

  return (
    <SalesRepresentativeDrawerComponent
      onClose={appUC.drawer.closeDrawer}
      setSalesRepresentativeEmail={UC.salesRepresentative.setSalesRepresentativeEmail}
      isLoading={customerSales.state.isSalesDrawerLoading}
      salesDepartmentMembers={customerSales.state.salesDepartmentMembers}
      saleRepresentativeEmail={customerSales.state.tempSaleRepresentativeEmail}
    />
  );
});

export { SalesRepresentativeDrawerContainer as SalesRepresentativeDrawer };
