import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { UC } from 'shipment-operations/controllers';
import { R } from 'shipment-operations/repository';
import {
  ShippingPartyReferenceInputComponent,
} from '../../../ShipperInformation/components/ShippingPartyReferenceInput/ShippingPartyReferenceInput.component';

const ConsigneeReferenceInputContainer: FC = () => {
  const shipmentConsigneeReference = useSelector(R.selectors.bookingWizard.getShipmentConsigneeReference);
  const shipmentConsigneeReferenceError = useSelector(R.selectors.bookingWizard.getShipmentConsigneeReferenceError);

  return (
    <ShippingPartyReferenceInputComponent
      value={shipmentConsigneeReference}
      isError={shipmentConsigneeReferenceError}
      onChange={UC.bookingWizard.onChangeConsigneeReference}
    />
  );
};

export { ConsigneeReferenceInputContainer as ReferenceInput };
