import { createSelector } from 'reselect';

import { RootState } from 'app-wrapper/store';

const localState = (appState: RootState) => appState.shipmentDocumentsHazmat;

const getIsLoading = createSelector(
  localState,
  (state) => state.isLoading,
);

const getContainers = createSelector(
  localState,
  (state) => state.containers,
);

const getHasHazmat = createSelector(
  localState,
  (state) => state.hasHazmat,
);

const getIsHazHazmatIsLoading = createSelector(
  localState,
  (state) => state.isHasHazmatPending,
);

export const shipmentDocumentsHazmatSelectors = {
  getIsLoading,
  getContainers,
  getHasHazmat,
  getIsHazHazmatIsLoading,
};
