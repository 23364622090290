import React, {
  KeyboardEvent, useState, useMemo, useCallback, memo, FC,
} from 'react';
import Spin from 'antd/es/spin';
import { useTranslation } from 'react-i18next';
import { AutoCompleteProps } from 'antd/es/auto-complete';
import usePlacesAutocomplete, { getDetails } from 'use-places-autocomplete';

import { useOnclickOutsideHook } from 'app-wrapper/hooks';
import parseAddressComponent from 'app-wrapper/utils/geocoderAddressParser/geocoderAddressParser';
import { GeoParsedAddress } from 'app-wrapper/types/Address';

import { AutoComplete } from '../AutoComplete';

import Wrapper from './PlacesAutocomplete.styled';

const acceptedKeys = ['ArrowUp', 'ArrowDown', 'Escape', 'Enter'];

type Suggestion = google.maps.places.AutocompletePrediction;

interface IPlaceAutocompleteProps extends AutoCompleteProps {
  defaultValue: string;
  onSelectPlace?: (val: GeoParsedAddress) => void;
}

const PlacesAutocomplete: FC<IPlaceAutocompleteProps> = (props) => {
  const {
    defaultValue,
    onSelectPlace,
  } = props;
  const { t } = useTranslation();
  const [currIndex, setCurrIndex] = useState<number | null>(null);
  const {
    value,
    suggestions: { status, data, loading },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    debounce: 500,
    defaultValue,
    requestOptions: {
      types: ['address'],
    },
  });
  const hasSuggestions = status === 'OK';

  const dismissSuggestions = useCallback(() => {
    setCurrIndex(null);
    clearSuggestions();
  }, [clearSuggestions]);

  const ref = useOnclickOutsideHook(dismissSuggestions);

  const handleInput = (enteredValue: string) => {
    setValue(enteredValue);
  };

  const handleSelect = (selectedValue: string, option: any) => {
    setValue(option.value, false);
    const parameter = {
      placeId: option.key,
      fields: ['address_component'],
    };

    getDetails(parameter)
      .then((details: any) => {
        const parsedAddress = parseAddressComponent(details.address_components);

        if (onSelectPlace) {
          onSelectPlace(parsedAddress);
        }
      })
      .catch((error) => {
        console.error('Error: ', error);
      });
    dismissSuggestions();
  };

  const handleLeave = () => {
    setCurrIndex(null);
  };

  const handleKeyDown = useCallback((e: KeyboardEvent<HTMLInputElement>) => {
    if (!hasSuggestions || !acceptedKeys.includes(e.key)) return;

    if (e.key === 'Enter' || e.key === 'Escape') {
      dismissSuggestions();
      return;
    }

    let nextIndex: number | null;

    if (e.key === 'ArrowUp') {
      e.preventDefault();
      nextIndex = currIndex ?? data.length;
      nextIndex = nextIndex > 0 ? nextIndex - 1 : null;
    } else {
      nextIndex = currIndex ?? -1;
      nextIndex = nextIndex < data.length - 1 ? nextIndex + 1 : null;
    }

    setCurrIndex(nextIndex);
  }, [currIndex, data.length, dismissSuggestions, hasSuggestions]);

  const suggestions = useMemo(() => data.map((suggestion: Suggestion) => {
    const {
      place_id: placeId,
      description,
      structured_formatting: { main_text: mainText, secondary_text: secondaryText },
    } = suggestion;

    return {
      label: `${mainText}, ${secondaryText}`,
      value: description,
      key: placeId,
    };
  }), [data]);

  return (
    <Wrapper>
      <AutoComplete
        ref={ref}
        onChange={handleInput}
        onSearch={handleInput}
        onBlur={handleLeave}
        options={suggestions}
        onSelect={handleSelect}
        notFoundContent={loading ? (<Spin size="small" />) : t('Validations.string.noResults')}
        onKeyDown={handleKeyDown}
        value={value}
        {...props}
      />
    </Wrapper>
  );
};

const CashedPlacesAutocomplete = memo(PlacesAutocomplete);

export {
  CashedPlacesAutocomplete as PlacesAutocomplete,
};
