import React from 'react';

import { IPropsIcon } from 'app-wrapper/interfaces/icon';

export const TrainSvg = (props: IPropsIcon) => {
  const {
    width, height, style, ...other
  } = props;
  return (
    <svg
      className="TrainSvg"
      width={width || '13'}
      height={height || '16'}
      viewBox="0 0 13 16"
      color={style?.color || '#4E5D70'}
      style={style || undefined}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...other}
    >
      <path d="M7.52669 10.2269C7.52669 9.61578 7.02669 9.11578 6.41558 9.11578C5.77669 9.11578 5.30447 9.61578 5.30447 10.2269C5.30447 10.8658 5.77669 11.338 6.41558 11.338C7.02669 11.338 7.52669 10.8658 7.52669 10.2269ZM12.6378 3.94911C12.6378 2.17133 10.6656 0.893555 8.77669 0.893555H4.02669C2.16558 0.893555 0.193359 2.17133 0.193359 3.94911V10.3102C0.193359 11.6713 1.3878 12.8102 2.94336 13.1991L1.30447 14.838C1.19336 14.9491 1.27669 15.1158 1.41558 15.1158H2.74891C2.83225 15.1158 2.94336 15.088 2.99891 15.0324L4.66558 13.338H8.1378L9.80447 15.0324C9.86003 15.088 9.97114 15.1158 10.0545 15.1158H11.3878C11.5267 15.1158 11.61 14.9491 11.4989 14.838L9.86003 13.1991C11.4156 12.8102 12.6378 11.6713 12.6378 10.3102V3.94911ZM1.52669 7.11578V4.89355H11.3045V7.11578H1.52669ZM1.58225 3.56022C1.8878 2.838 2.94336 2.22689 4.02669 2.22689H8.77669C9.8878 2.22689 10.9434 2.838 11.2211 3.56022H1.58225ZM11.3045 10.3102C11.3045 11.3102 9.83225 12.0047 8.77669 12.0047H4.02669C2.94336 12.0047 1.52669 11.3102 1.52669 10.3102V8.44911H11.3045V10.3102Z" fill="currentColor" />
    </svg>
  );
};
