import React, {
  ChangeEvent,
  FC,
  useCallback,
} from 'react';
import { useTranslation } from 'react-i18next';

import { ValidationErrorDTM } from 'app-wrapper/types';

import { InputStyled } from './PackQTYInput.styled';

interface PackQTYInputComponentProps {
  cargoId: number;
  packagesQTY: string;
  touchCargoField: (field: string, cargoId: number) => void
  setPackagesQTY: (description: string, cargoId: number) => void
  error?: ValidationErrorDTM
}

export const PackQTYInputComponent: FC<PackQTYInputComponentProps> = ({
  cargoId,
  packagesQTY,
  touchCargoField,
  setPackagesQTY,
  error,
}) => {
  const { t } = useTranslation();

  const handleChangeQTY = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setPackagesQTY(event.target.value, cargoId);
  }, [setPackagesQTY, cargoId]);

  const handleBlur = useCallback(() => {
    touchCargoField('packagesNumber', cargoId);
  }, [touchCargoField, cargoId]);

  return (
    <InputStyled
      placeholder={t('Quantity')}
      value={packagesQTY}
      onChange={handleChangeQTY}
      onBlur={handleBlur}
      error={!!error}
    />
  );
};
