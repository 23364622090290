import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'app-wrapper/createSlice';

import { TModuleFunctionalityPermissionsState } from 'monetary/models/states';
import { ModuleFunctionalityPermissionsDtm } from 'monetary/models/dtm';

export const initialState: TModuleFunctionalityPermissionsState = {
  permissions: undefined,
};

export const moduleFunctionalityPermissionsStore = createSlice({
  name: 'MonetaryModuleFunctionalityPermissions',
  initialState,
  reducers: {
    setPermissions: (state, action: PayloadAction<ModuleFunctionalityPermissionsDtm>) => {
      state.permissions = action.payload;
    },
  },
});

export const moduleFunctionalityPermissionsActions = moduleFunctionalityPermissionsStore.actions;
export const moduleFunctionalityPermissionsReducer = moduleFunctionalityPermissionsStore.reducer;
