import React, { FC, memo } from 'react';

import { UC } from 'user-management/controllers';

import { AccountDepartmentEditComponent } from './AccountDepartmentEdit.component';

const AccountDepartmentEditContainer: FC = () => (
  <AccountDepartmentEditComponent
    isDisabledCancelBtn={false}
    isDisabledSaveBtn={false}
    onCancelClick={UC.accountDepartments.onCloseAccountDepartmentEditDrawer}
    onSaveClick={UC.accountDepartments.onSaveAccountDepartmentEditDrawer}
  />
);

const AccountDepartmentEditContainerCached = memo(AccountDepartmentEditContainer);

export { AccountDepartmentEditContainerCached as AccountDepartmentEditPage };
