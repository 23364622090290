import React from 'react';
import { useSelector } from 'react-redux';

import { R as userManagementR } from 'user-management/repository';
import { R } from 'shipment-operations/repository';
import { UC } from 'shipment-operations/controllers';

import { NavigationBarComponent } from './NavigationBar.component';

const NavigationBarContainer = () => {
  const activeTab = useSelector(R.selectors.shippingParties.getActiveTab);
  const orgRole = useSelector(userManagementR.selectors.userOrganizationData.getUserOrganisationRole);
  const isLoading = useSelector(R.selectors.shippingParties.getIsLoading);

  return (
    <NavigationBarComponent
      isLoading={isLoading}
      activeTab={activeTab}
      openTab={UC.shippingParties.openTab}
      orgRole={orgRole}
    />
  );
};

export { NavigationBarContainer as NavigationBar };
