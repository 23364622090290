import {
  IsBoolean, IsEnum, IsNumber, IsOptional, IsString,
} from 'class-validator';
import { BaseDTM } from 'proto/BaseDTM';

import { ExportClearanceType } from 'shipment-operations/constants';

export interface ICustomsClearanceDTM {
  id?: number;
  isCreated?: boolean;
  type?: ExportClearanceType;
  number?: string;
  selfCustomsClearance?: boolean;
}

export class CustomsClearanceDTM extends BaseDTM<ICustomsClearanceDTM> {
  @IsOptional()
  @IsNumber()
  id: number;

  @IsOptional()
  @IsBoolean()
  isCreated?: boolean;

  @IsOptional()
  @IsEnum(ExportClearanceType)
  type?: ExportClearanceType;

  @IsOptional()
  @IsString()
  number?: string;

  @IsOptional()
  @IsBoolean()
  selfCustomsClearance?: boolean;

  static areInstancesEqual = (instanceOne: ICustomsClearanceDTM, instanceTwo: ICustomsClearanceDTM) => instanceOne.isCreated === instanceTwo.isCreated
    && instanceOne.type === instanceTwo.type
    && instanceOne.number === instanceTwo.number;
}
