import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';
import { UC } from 'shipment-operations/controllers';

import { HSCodeInputComponent } from './HSCodeInput.component';

interface HSCodeInputContainerProps {
  cargoId: number;
}

const HSCodeInputContainer: FC<HSCodeInputContainerProps> = ({ cargoId }) => {
  const { code } = useSelector(R.selectors.manualBookingWizard.getCargoById(cargoId));
  const { commodities, isLoading, query } = useSelector(R.selectors.commodity.getCommodity);

  return (
    <HSCodeInputComponent
      commodities={commodities}
      isQueryEmpty={!!query}
      isLoadingCommodities={isLoading}
      hsCode={code}
      searchCommodities={UC.cargo.searchCommodities}
      isError={false}
      isTemperatureControlled
    />
  );
};

export { HSCodeInputContainer as HSCodeInput };
