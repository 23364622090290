import styled from 'styled-components';
import Spin from 'antd/es/spin';

export const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #FFF;
`;

export const Spinner = styled(Spin)``;
