import React, {
  useEffect,
  useState,
  useCallback,
  useRef,
} from 'react';
import { useForm, useFieldArray, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Col from 'antd/es/col';
import Spin from 'antd/es/spin';
import map from 'lodash/fp/map';
import PlusOutlined from '@ant-design/icons/PlusOutlined';
import { yupResolver } from '@hookform/resolvers/yup';

import { getShipmentId } from 'app-wrapper/selectors/shipments';
import { useAppSelector, useAppDispatch } from 'app-wrapper/hooks';
import { fetchShipmentRoles, searchCompanies, setCurrentRole } from 'app-wrapper/data';

import { TitleWrapper, StepTitle } from 'shipment-operations/view/pages/Booking/styled';

import { ShippingPartyList } from './ShippingPartyList';
import { ShippingPartyForm } from './ShippingPartyForm';
import { ShipmentPartiesSchema } from './ShipmentPartiesSchema';
import { CreateNewItem } from './CreateNewItem';
import { ShipmentPartyService } from './ShipmentPartyService';
import { IShippingPartyForm, IShippingPartyForms } from './types';
import {
  FullHeightRow,
  ShippingPartyCreateButton,
  ShippingPartyHeadingContainer,
  ShippingPartyTitleIcon,
} from './styles';

const HEADING_STYLE = {
  paddingBottom: 11,
  margin: 0,
};

const service = new ShipmentPartyService();

// TODO: divide logic and view

export const ShippingParties = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [isLoadingData, setIsLoadingData] = useState(true);
  const [
    selectedShipmentParty,
    setSelectedShipmentParty,
  ] = useState<number>(0);
  const shipmentId = useAppSelector(getShipmentId);
  const yupSchemaRef = useRef(ShipmentPartiesSchema);
  useEffect(() => {
    dispatch(fetchShipmentRoles({}));
    dispatch(searchCompanies({
      urlGetParams: [
        'page=1',
        'size=20',
      ],
    }));
  }, []);

  const formMethods = useForm<IShippingPartyForms>({
    defaultValues: { shipmentParties: service.getDefaultRoles() },
    resolver: yupResolver(yupSchemaRef.current),
    mode: 'all',
    reValidateMode: 'onChange',
  });
  const arrayControl = useFieldArray({
    control: formMethods.control,
    name: 'shipmentParties',
    keyName: 'key',
  });

  useEffect(() => {
    setIsLoadingData(true);

    const fetchShipmentParties = async () => {
      const data = await service.getShipmentParties(shipmentId);

      if (data?.length > 0) {
        formMethods.setValue('shipmentParties', data);
        formMethods.trigger('shipmentParties');
      }

      setIsLoadingData(false);
    };

    fetchShipmentParties()
      .catch(() => {
        setIsLoadingData(false);
        formMethods.trigger('shipmentParties');
      });
  }, []);

  const handleCreateShipmentParty = useCallback(() => {}, []);

  const handleRemoveRole = useCallback(() => {
    setSelectedShipmentParty((i) => (
      i > 1 ? i - 1 : 0
    ));
    arrayControl.remove(selectedShipmentParty);
  }, [arrayControl, selectedShipmentParty]);

  const handleSelectSP = useCallback((i: number) => {
    if (i === selectedShipmentParty) {
      return;
    }
    const selectedParty = formMethods.getValues(`shipmentParties.${i}`);

    dispatch(setCurrentRole(selectedParty.role));
    formMethods.trigger(`shipmentParties.${i}`);
    setSelectedShipmentParty(i);
  }, [formMethods, selectedShipmentParty]);

  const allAddedRoles = formMethods.getValues('shipmentParties') || [];

  const renderFormHeader = () => (
    <ShippingPartyHeadingContainer>
      <TitleWrapper>
        <ShippingPartyTitleIcon width={15} height={17} />
        <StepTitle level={4} style={HEADING_STYLE}>{t('Shipping Parties')}</StepTitle>
      </TitleWrapper>
      <CreateNewItem
        addedRoles={map((item: IShippingPartyForm) => item.role, allAddedRoles)}
        onClick={
          (value: any) => {
            arrayControl.append(
              service.createDefaultShipmentParty(value, -arrayControl.fields.length),
            );
            formMethods.trigger(`shipmentParties.${arrayControl.fields.length}`);
          }
        }
      />
    </ShippingPartyHeadingContainer>
  );

  return (
    <FormProvider {...formMethods}>
      {renderFormHeader()}
      <FullHeightRow gutter={8}>
        {
          isLoadingData
            ? <Spin />
            : (
              <>
                <Col span={8}>
                  <ShippingPartyList
                    selectedShipmentParty={selectedShipmentParty}
                    setSelectedShipmentParty={handleSelectSP}
                  />
                  <ShippingPartyCreateButton
                    type="dashed"
                    icon={<PlusOutlined />}
                    onClick={handleCreateShipmentParty}
                  >
                    {t('Add role')}
                  </ShippingPartyCreateButton>
                </Col>
                <Col span={16}>
                  <ShippingPartyForm
                    key={`form__${arrayControl.fields[selectedShipmentParty]?.key}`}
                    onRemoveRole={handleRemoveRole}
                    selectedShipmentParty={selectedShipmentParty}
                  />
                </Col>
              </>
            )
        }
      </FullHeightRow>
    </FormProvider>
  );
};
