import React, { FC, HTMLAttributes } from 'react';

export const ActionMenuIcon: FC<HTMLAttributes<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="#73819B"
      d="M12 10.688c-.738 0-1.313.601-1.313 1.312 0 .738.575 1.313 1.313 1.313.71 0 1.313-.575 1.313-1.313A1.33 1.33 0 0012 10.687zm-1.313-2.844c0 .738.575 1.312 1.313 1.312.71 0 1.313-.574 1.313-1.312A1.33 1.33 0 0012 6.53c-.738 0-1.313.602-1.313 1.313zm0 8.312c0 .739.575 1.313 1.313 1.313.71 0 1.313-.575 1.313-1.313A1.33 1.33 0 0012 14.844c-.738 0-1.313.601-1.313 1.312z"
    />
  </svg>
);
