import React, { useMemo, FC } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { SubPageHeaderTitle, SubPageHeaderContainer } from 'app-wrapper/view/guideline';
import { RouteNames } from 'app-wrapper/constants';
import { SkeletonSection } from 'app-wrapper/view/components/Skeleton/Skeleton.component';
import { InvoiceStatusesEnum as statuses } from 'shipment-operations/constants';
import { ShareInvoice } from 'shipment-operations/view/components';

import {
  SubPageHeaderStyled,
  StyledBackIcon,
  ShareInvoiceWrap,
  LeftContainer,
  StyledStatus,
  SkeletonWrap,
} from './Header.styled';

interface IHeaderProps {
  status?: statuses
  number?: string
  title: string
  invoiceId?: number
  isAPInvoiceType: boolean;
  hidePDFIcon?: boolean;
  isLoading?: boolean;
}

const HeaderComponent: FC<IHeaderProps> = ({
  status,
  number,
  title,
  invoiceId,
  isAPInvoiceType,
  hidePDFIcon,
  isLoading,
}) => {
  const { t } = useTranslation();
  const { shipmentId } = useParams<'shipmentId'>();

  const matchedStatus = useMemo(() => ({
    [statuses.PAID]: <StyledStatus color="#75CB3F">{t('billingStatuses.paid')}</StyledStatus>,
    [statuses.UNPAID]: <StyledStatus color="#6C79DE">{t('billingStatuses.unpaid')}</StyledStatus>,
    [statuses.PARTIALLY_PAID]: <StyledStatus color="#F1AE00">{t('billingStatuses.partiallyPaid')}</StyledStatus>,
    [statuses.APPLIED]: <StyledStatus color="#75CB3F">{t('billingStatuses.applied')}</StyledStatus>,
    [statuses.VOID]: <StyledStatus color="#82909D">{t('billingStatuses.void')}</StyledStatus>,
  }), [t]);

  return (
    <SubPageHeaderStyled>
      {isLoading ? (
        <SkeletonWrap>
          <SkeletonSection />
        </SkeletonWrap>
      ) : null}

      <SubPageHeaderContainer
        style={{
          visibility: isLoading ? 'hidden' : 'visible',
        }}
      >
        <LeftContainer>
          <NavLink to={RouteNames.SHIPMENT_BILLING(shipmentId)}>
            <StyledBackIcon />
          </NavLink>
          <SubPageHeaderTitle>{t(title)} {number}</SubPageHeaderTitle>

          {!hidePDFIcon ? (
            <ShareInvoiceWrap>
              <ShareInvoice shipmentId={String(shipmentId)} invoiceId={invoiceId} isAPInvoiceType={isAPInvoiceType} />
            </ShareInvoiceWrap>
          ) : null}
        </LeftContainer>
        {status && matchedStatus[status]}
      </SubPageHeaderContainer>
    </SubPageHeaderStyled>
  );
};

export { HeaderComponent };
