import React from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';
import { UC } from 'shipment-operations/controllers';

import { ShipmentDocumentsComponent } from './ShipmentDocuments.component';

const ShipmentDocumentsContainer = () => {
  const hasHazmat = useSelector(R.selectors.shipmentDocumentsHazmat.getHasHazmat);
  const hasSOC = useSelector(R.selectors.shipmentDocumentsSeaworthyCertificates.getHasSOC);

  return (
    <ShipmentDocumentsComponent
      hasHazmat={hasHazmat}
      hasSOC={hasSOC}
      fetchHasHazmat={UC.shipmentDocuments.fetchHasHazmatInfo}
      fetchSeaworthyCertificates={UC.shipmentDocumentsSeaworthyCertificates.fetchDocumentsSeaworthyCertificatesData}
      cleanup={UC.shipmentDocuments.cleanup}
    />
  );
};

export { ShipmentDocumentsContainer as ShipmentDocuments };
