import {
  IsString, IsDefined, ValidateNested,
} from 'class-validator';
import { Type } from 'class-transformer';

import { IReferenceDTM, ReferenceDTM } from 'app-wrapper/types/Reference';
import { BaseDTM } from 'proto/BaseDTM';
import { ICustomerDTM, CustomerDTM } from './Customer.dtm';
import { IWaypointDTM, WaypointDTM } from './Waypoint.dtm';

export interface IShipmentDTM {
  status: string
  shipmentName: string
  references: IReferenceDTM[]
  origin: IWaypointDTM
  destination: IWaypointDTM
  customer: ICustomerDTM
}

export class ShipmentDTM extends BaseDTM<IShipmentDTM> {
  @IsString()
  @IsDefined()
  status : string

  @IsString()
  @IsDefined()
  shipmentName: string

  @IsDefined()
  @ValidateNested()
  @Type(() => ReferenceDTM)
  references: ReferenceDTM[]

  @IsDefined()
  @ValidateNested()
  @Type(() => WaypointDTM)
  origin: WaypointDTM

  @IsDefined()
  @ValidateNested()
  @Type(() => WaypointDTM)
  destination: WaypointDTM

  @IsDefined()
  @ValidateNested()
  @Type(() => CustomerDTM)
  customer: CustomerDTM
}

interface IEntryNumberDTM {
  number: string
}

class EntryNumberDTM extends BaseDTM<IEntryNumberDTM> {
  @IsString()
  @IsDefined()
  number: string
}

export interface IExportCustomsDTM {
  entryNumber: IEntryNumberDTM
}

export class ExportCustomsDTM extends BaseDTM<IExportCustomsDTM> {
  @IsDefined()
  @ValidateNested()
  @Type(() => EntryNumberDTM)
  entryNumber: EntryNumberDTM
}
