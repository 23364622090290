import React, { FC } from 'react';
import { SkeletonNode, SkeletonNodeBackground } from 'app-wrapper/view/components/Skeleton/Skeleton.styled';

interface SkeletonRowProps {
  active?: boolean
  height?: string
}

export const SkeletonRow: FC<SkeletonRowProps> = ({ active, height }) => (
  <SkeletonNode $active={active} $height={height}>
    <SkeletonNodeBackground $height={height} />
  </SkeletonNode>
);

interface SkeletonSectionProps {
  height?: string
  width?: string
}

export const SkeletonSection: FC<SkeletonSectionProps> = ({ height, width }) => (
  <SkeletonNode $active $height={height || '100%'} $width={width || '100%'}>
    <SkeletonNodeBackground $height={height || '100%'} />
  </SkeletonNode>
);

interface SkeletonShipmentProps {
  rowCount?: number
  isLoading?: boolean
  children?: React.ReactNode | React.ReactNode[]
}

export const SkeletonShipment: FC<SkeletonShipmentProps> = ({
  isLoading = true,
  rowCount,
  children,
}) => (
  <>
    {
      isLoading
        ? Array(rowCount || 8)
          .fill(undefined)
          // eslint-disable-next-line react/no-array-index-key
          .map((_, index) => <SkeletonRow key={`skeleton_node_${index}`} active height="76px" />)
        : children
    }
  </>
);

export const SkeletonRFQ: FC<SkeletonShipmentProps> = ({
  isLoading = true,
  rowCount,
  children,
}) => (
  <>
    {
      isLoading
        ? Array(rowCount || 8)
          .fill(undefined)
          // eslint-disable-next-line react/no-array-index-key
          .map((_, index) => <SkeletonRow key={`skeleton_node_${index}`} active height="185px" />)
        : children
    }
  </>
);
