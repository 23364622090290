import React, { FC, useEffect, Fragment } from 'react';
import { useParams } from 'react-router-dom';
import isEmpty from 'lodash/fp/isEmpty';
import { useTranslation } from 'react-i18next';

import { EmptyStateForPages, TableSkeleton } from 'app-wrapper/view/components';

import { ChargeDTM, ShipmentChargesPermissionsDtm } from 'shipment-operations/models/dtm';

import Alert from 'antd/es/alert';
import { CustomTable } from './CustomTable';
import {
  MainContainer, Title, ContainerTitle,
} from './ShipmentFreeTime.styled';

interface IShipmentFreeTimeProps {
  isLoading: boolean
  fetchData: (shipmentId?: string) => void
  originData: { [key: string]: ChargeDTM[] }
  freightData: { [key: string]: ChargeDTM[] }
  destinationData: { [key: string]: ChargeDTM[] }
  permissions: ShipmentChargesPermissionsDtm
  isError: boolean
}

const ShipmentFreeTime: FC<IShipmentFreeTimeProps> = ({
  isLoading, fetchData, originData, freightData, destinationData, permissions, isError,
}) => {
  const { shipmentId } = useParams<'shipmentId'>();
  const { t } = useTranslation();

  useEffect(() => {
    fetchData(shipmentId);
  }, [fetchData]);

  if (isError) {
    return (
      <MainContainer>
        <Alert
          message={t('basicErrors.REQUEST_COMMON.title')}
          description={t('basicErrors.REQUEST_COMMON.subTitle')}
          type="error"
        />
      </MainContainer>
    );
  }

  if (isLoading) {
    return <TableSkeleton />;
  }

  if (isEmpty(originData) && isEmpty(freightData) && isEmpty(destinationData)) {
    return (
      <EmptyStateForPages
        headerText={t('No charges are known at this time.')}
        contentText={t('All information regarding free time, detention and demurrage surcharges will be displayed here.')}
      />
    );
  }

  const tables = (data: ChargeDTM[], chargesPermissions: ShipmentChargesPermissionsDtm) => [...new Set(data.map((item) => item.chargeCode?.description))].map((name) => {
    const filteredData = data.filter((item) => item.chargeCode?.description === name);

    return (
      <CustomTable key={`ShipmentFreeTime_${name}`} data={filteredData} permissions={chargesPermissions} />
    );
  });

  return (
    <MainContainer>
      {!isEmpty(originData) && (
        <>
          <Title>{t('Origin')}</Title>
          {Object.keys(originData).map((key) => (
            <Fragment key={key}>
              <ContainerTitle>{key}</ContainerTitle>
              {tables(originData[key], permissions)}
            </Fragment>
          ))}
        </>
      )}
      {!isEmpty(freightData) && (
        <>
          <Title>{t('Freight')}</Title>
          {Object.keys(freightData).map((key) => (
            <Fragment key={key}>
              <ContainerTitle>{key}</ContainerTitle>
              {tables(freightData[key], permissions)}
            </Fragment>
          ))}
        </>
      )}
      {!isEmpty(destinationData) && (
        <>
          <Title>{t('Destination')}</Title>
          {Object.keys(destinationData).map((key) => (
            <Fragment key={key}>
              <ContainerTitle>{key}</ContainerTitle>
              {tables(destinationData[key], permissions)}
            </Fragment>
          ))}
        </>
      )}
    </MainContainer>
  );
};

export { ShipmentFreeTime };
