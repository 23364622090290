import InputNumber from 'antd/es/input-number';
import styled, { css } from 'styled-components';
import Button from 'antd/es/button';

import { divFlex, responsive } from 'app-wrapper/view/themes/mixin';
import { emptyScrollbar } from 'app-wrapper/view/themes/components';
import { Input } from 'app-wrapper/view/components';

const inputCss = css`
  font-size: 12px;
  font-weight: 400;
  color: ${({ theme }) => theme?.themesColors?.neutralBranding10};
  min-height: 32px;
`;

const inputTopMarginCss = css`
  margin-top: 4px;
`;

const inputLargeCss = css`
  height: 40px;
`;

export const CAIWrap = styled.div.attrs({
  'data-class': 'CAIWrap',
})`
  ${divFlex}
  ${emptyScrollbar}
  flex-direction: column;
  align-items: center;

  padding: 16px 24px 0 24px;
  height: 100%;
  width: 100%;
  background-color: ${({ theme }) => theme.themesColors.neutralBranding3};

  .ant-input, &#CAIWrap .ant-select-selector {
    border-color: ${({ theme }) => theme.themesColors.neutralBranding6};
  }
`;

export const CAIContent = styled.div.attrs({
  'data-class': 'CAIContent',
})`
  ${divFlex}
  ${emptyScrollbar}
  flex-direction: column;
  overflow: auto;

  font-weight: 400;
  font-size: 12px;
  color: ${({ theme }) => theme.themesColors.neutralBranding10};

  padding: 16px;
  height: 100%;
  width: 100%;
  border: 1px solid ${({ theme }) => theme.themesColors.neutralBranding4};
  background-color: ${({ theme }) => theme.themesColors.neutralBranding1};
`;

export const CAIOrganization = styled.div.attrs({
  'data-class': 'CAIOrganization',
})`
  ${divFlex}
  flex-direction: column;
  justify-content: center;
`;

export const CAITitle = styled.div.attrs({
  'data-class': 'CAITitle',
})`
  ${divFlex}
  justify-content: start;

  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  color: ${({ theme }) => theme.themesColors.neutralBranding10};
`;

export const CAISubTitle = styled.div.attrs({
  'data-class': 'CAISubTitle',
})`
  ${divFlex}

  margin-left: 4px;
  font-weight: 400;
  font-size: 12px;
  color: ${({ theme }) => theme.themesColors.secondaryDot45};
`;

export const CAIHeader = styled.div.attrs({
  'data-class': 'CAIHeader',
})`
  ${divFlex}
  justify-content: start;

  margin-bottom: 16px;
  font-weight: 500;
  font-size: 14px;
  color: ${({ theme }) => theme.themesColors.neutralBranding10};
`;

export const CAIInput = styled(Input).attrs((props: {
  hasError: boolean,
  largeInput: boolean,
}) => ({
  hasError: props.hasError,
  largeInput: props.largeInput,
  'data-class': 'CAIInput',
}))`
  ${divFlex}
  ${inputCss}
  ${inputTopMarginCss}
  ${({ largeInput }) => largeInput && inputLargeCss};
  width: 100%;

  ${responsive.forFullMedia`
    min-height: ${({ theme }: { theme: { inputConst: { hdHeight: string } } }) => theme?.inputConst?.hdHeight};
  `}
  ${({ hasError, theme }) => hasError && `border-color: ${theme.themesColors.characterBrandingDanger} !important; border-style: solid;`};
`;

export const StyledInputNumber = styled(InputNumber).attrs((props: {
  isError: boolean
  isValue: boolean
  'data-class'?: string
}) => ({
  isError: props.isError,
  isValue: props.isValue,
  'data-class': props?.['data-class'] || 'CAIInputNumber',
}))`
  width: 100%;
  font-size: 12px;

  ${inputTopMarginCss}
  ${({ isError, theme }) => isError && `border-color: ${theme.themesColors.characterBrandingDanger} !important;`};
  ${({ isError }) => isError && 'z-index: 2;'};
  .ant-input-prefix {
    ${({ isValue, theme }) => isValue && `color: ${theme.themesColors.secondaryDot45};`};
  }
`;

export const CAIDivider = styled.div.attrs({
  'data-class': 'CAIDivider',
})`
  ${divFlex}
  width: 100%;

  margin-top: 16px;
  margin-bottom: 16px;
  border: 1px dashed ${({ theme }) => theme.themesColors.neutralBranding4};
`;

export const CAISpaceInput = styled.div.attrs({
  'data-class': 'CAISpaceInput',
})`
  ${divFlex}
  width: 100%;

  margin-bottom: 16px;
`;

export const CAISpaceRow = styled.div.attrs({
  'data-class': 'CAISpaceRow',
})`
  ${divFlex}
  width: 100%;

  > div:not(:first-child) {
    margin-left: 8px;
  }
`;

export const CAISpaceColumn = styled.div.attrs((props: {
  widthProp: string,
}) => ({
  widthProp: props.widthProp,
  'data-class': 'CAISpaceColumn',
}))`
  ${divFlex}
  flex-direction: column;

  ${({ widthProp }) => widthProp && `width: ${widthProp}`};
`;

export const CAIContact = styled.div.attrs({
  'data-class': 'CAIContact',
})`
  ${divFlex}
  flex-direction: column;
  justify-content: center;
`;

export const CAICreditInformation = styled.div.attrs({
  'data-class': 'CAICreditInformation',
})`
  ${divFlex}
  flex-direction: column;
  justify-content: center;
`;

export const CAIContactInformation = styled.div.attrs({
  'data-class': 'CAIContactInformation',
})`
  ${divFlex}
  flex-direction: column;
  justify-content: center;
`;

export const CAIFooter = styled.div.attrs({
  'data-class': 'CAIFooter',
})`
  ${divFlex}
  justify-content: end;
  z-index: 4;
  width: calc(100% + 48px);
  height: 64px;
  bottom: 0px;
  align-items: center;
  padding: 14px 24px;

  background-color: ${({ theme }) => theme.themesColors.neutralBranding1};
  box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px rgba(0, 0, 0, 0.08), 0px 9px 28px 8px rgba(0, 0, 0, 0.05);
`;

export const CAIButtonCancel = styled(Button).attrs({
  'data-class': 'CAIButtonCancel',
})`
  ${divFlex}

  font-size: 16px;
  font-weight: 400;
  color: ${({ theme }) => theme?.themesColors?.neutralBranding10};
  background: ${({ theme }) => theme?.themesColors?.neutralBranding1};
  border: 1px solid ${({ theme }) => theme?.themesColors?.neutralBranding5};
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
  border-radius: 3px;

  width: 67px;
  height: 40px;
  align-items: center;
  justify-content: center;
  right: 0;
`;

export const CAIButtonSave = styled(Button).attrs({
  'data-class': 'CAIButtonSave',
})`
  ${divFlex}

  font-size: 16px;
  font-weight: 400;
  color: ${({ theme }) => theme?.themesColors?.neutralBranding1};
  background-color: ${({ theme }) => theme?.themesColors?.neutralBranding8};
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
  border-radius: 3px;

  width: 67px;
  height: 40px;
  align-items: center;
  justify-content: center;
  right: 0;
  margin-left: 8px;
`;
