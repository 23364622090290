import React, { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ContainerWithChangeChargesDTM, ShipmentChargeChangeDTM } from 'app-wrapper/models/dtm';
import { useToggleCollapse } from 'shipment-operations/view/hooks';
import { MissingTextValue, FlexCol } from 'app-wrapper/view/guideline';
import { ContainerTypesConst } from 'shipment-operations/constants';
import { StyledExpandIcon } from 'shipment-operations/view/pages/ShipmentCharges/components/CustomTable/CustomTable.styled';
import { ContainerTitleWrapper, ContainerType } from 'shipment-operations/view/components/ExpandPanel/ExpandPanel.styled';
import { StyledType } from 'shipment-operations/view/pages/ShipmentCharges/components/ContainersTable/ContainersTable.styled';
import { FooterTotalTable, CostChangesTable } from 'app-wrapper/view/drawers/ShipmentCostChangesDrawer/components';
import { CostChangeDifference } from 'app-wrapper/view/drawers/ShipmentCostChangesDrawer/components/CostChangesTable/components';
import {
  MissingValueWrap,
  TableCell,
  TableCellDifference,
} from 'app-wrapper/view/drawers/ShipmentCostChangesDrawer/components/CostChangesTable/CostChangesTable.styled';

import {
  StyledTable,
  Title,
  SubTitle,
  TableWrap,
  Wrap,
} from './ContainersChargesChangesTable.styled';

interface IContainersTableProps {
  dataSource: ContainerWithChangeChargesDTM[]
}

const ContainersChargesChangesTableComponent: FC<IContainersTableProps> = ({
  dataSource,
}) => {
  const { t } = useTranslation();
  const { openedKeys, onToggleCollapse } = useToggleCollapse();

  const expandRender = useCallback((record) => {
    const { charges } = record as ContainerWithChangeChargesDTM;

    return (
      <CostChangesTable costChanges={charges} />
    );
  }, [openedKeys]);

  const handleClass = useCallback(() => 'expandContainersRow', []);

  const options = useMemo(() => ({
    expandedRowRender: expandRender,
    expandedRowKeys: openedKeys,
    expandIconColumnIndex: -1,
  }), [openedKeys, expandRender]);

  const columns = useMemo(() => [
    {
      title: t('Charge'),
      width: '31%',
      key: 'object',
      align: 'left' as 'left',
      render: (item: string, record: ContainerWithChangeChargesDTM | {}) => {
        const costChange = record as ContainerWithChangeChargesDTM;
        const { type, number, key } = costChange;

        return (
          <StyledType active onClick={() => onToggleCollapse(key)} isOpen={openedKeys.includes(key)}>
            <StyledExpandIcon isActive={openedKeys.includes(key)} />
            <ContainerTitleWrapper>
              <ContainerType active={openedKeys.includes(key)}>
                {ContainerTypesConst[type as keyof typeof ContainerTypesConst]} &apos;
              </ContainerType>
            </ContainerTitleWrapper>

            {number || t('Number pending entry')}
          </StyledType>
        );
      },
    },
    {
      title: t('Old Value'),
      width: '23%',
      key: 'object',
      align: 'right' as 'right',
      render: (item: string, record: ContainerWithChangeChargesDTM | {}) => {
        const costChange = record as ContainerWithChangeChargesDTM;
        const { charges } = costChange;
        const oldValue = charges.reduce((prev, current) => (Number(current.prevVersion?.totalCost) || 0) + prev, 0);

        return (
          <TableCell>
            {oldValue > 0 ? `$ ${oldValue.toFixed(2)}` : (
              <MissingValueWrap>
                <MissingTextValue />
              </MissingValueWrap>
            )}
          </TableCell>
        );
      },
    },
    {
      title: t('Difference'),
      width: '23%',
      key: 'object',
      align: 'right' as 'right',
      render: (item: string, record: ContainerWithChangeChargesDTM | {}) => {
        const costChange = record as ContainerWithChangeChargesDTM;
        const { charges } = costChange;
        const oldValue = charges.reduce((prev, current) => (Number(current.prevVersion?.totalCost) || 0) + prev, 0);
        const newValue = charges.reduce((prev, current) => (Number(current.newVersion?.totalCost) || 0) + prev, 0);

        return (
          <TableCellDifference>
            <CostChangeDifference
              prevCost={oldValue}
              newCost={newValue}
            />
          </TableCellDifference>
        );
      },
    },
    {
      title: t('New Value'),
      width: '23%',
      key: 'object',
      align: 'right' as 'right',
      render: (item: string, record: ContainerWithChangeChargesDTM | {}) => {
        const costChange = record as ContainerWithChangeChargesDTM;
        const { charges } = costChange;
        const newValue = charges.reduce((prev, current) => (Number(current.newVersion?.totalCost) || 0) + prev, 0);

        return (
          <TableCell>
            {newValue > 0 ? `$ ${newValue.toFixed(2)}` : (
              <MissingValueWrap>
                <MissingTextValue />
              </MissingValueWrap>
            )}
          </TableCell>
        );
      },
    },
  ], [openedKeys, onToggleCollapse]);

  const allCharges: ShipmentChargeChangeDTM[] = dataSource.reduce((prev, current) => ([...current.charges, ...prev]), [] as ShipmentChargeChangeDTM[]);

  if (!dataSource.length) {
    return null;
  }

  return (
    <Wrap>
      <TableWrap>
        <FlexCol>
          <Title>
            {t('Charges')}
          </Title>

          <SubTitle>
            {t('Transportation')}
          </SubTitle>
        </FlexCol>

        <StyledTable
          pagination={false}
          dataSource={dataSource}
          expandedRowClassName={handleClass}
          expandable={options}
          columns={columns}
        />
      </TableWrap>

      <FooterTotalTable costChanges={allCharges} />
    </Wrap>
  );
};

export { ContainersChargesChangesTableComponent };
