import { createSelector } from 'reselect';

import { RootState } from 'app-wrapper/store';

const localState = (state: RootState) => state.newOrganizationWizardSteps;

const getActiveStepNumber = createSelector(
  localState,
  (state) => state.activeStepNumber,
);

const getPercent = createSelector(
  localState,
  (state) => state.percent,
);

const getStatus = createSelector(
  localState,
  (state) => state.status,
);

const isFirstStepDisabled = createSelector(
  localState,
  (state) => state.firstStepIsDisabled,
);

const isSecondStepDisabled = createSelector(
  localState,
  (state) => state.secondStepIsDisabled,
);

const isThirdStepDisabled = createSelector(
  localState,
  (state) => state.thirdStepIsDisabled,
);

export const newOrganizationWizardStepsSelectors = {
  getActiveStepNumber,
  getPercent,
  getStatus,
  isFirstStepDisabled,
  isSecondStepDisabled,
  isThirdStepDisabled,
};
