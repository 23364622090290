import React, { useMemo, FC } from 'react';
import { useTranslation } from 'react-i18next';
import get from 'lodash/fp/get';

import { normalizationCost } from 'app-wrapper/utils';

import { StyledStatus } from 'shipment-operations/view/pages/ShipmentBilling/ShipmentBilling.styled';
import { PaymentsStatusesEnum as statuses, RECEIVABLES } from 'shipment-operations/constants';

import { PaymentTableDTM } from 'monetary/models/dtm';
import { CustomTable } from 'monetary/view/components';

import { RouteNames } from 'app-wrapper/constants';
import { NameOfDocument } from './Table.styled';

interface ITableComponentProps {
  data: PaymentTableDTM[]
  totalElements: number
  onChangePage: (page: number) => void
  type: string
  activePage: number
}

const TableComponent: FC<ITableComponentProps> = ({
  data, totalElements, onChangePage, type, activePage,
}) => {
  const { t } = useTranslation();

  const status = useMemo(() => ({
    [statuses.APPLIED]: <StyledStatus color="#6C79DE">{t('payment.applied')}</StyledStatus>,
    [statuses.OPEN]: <StyledStatus color="#75CB3F">{t('payment.open')}</StyledStatus>,
    [statuses.PARTIAL]: <StyledStatus color="#F1AE00">{t('payment.partial')}</StyledStatus>,
  }), [t]);

  const columns = useMemo(() => [
    {
      title: t('paymentsId'),
      dataIndex: 'id',
      key: 'id',
      align: 'left' as 'left',
      render: (item: string, record: PaymentTableDTM | {}) => {
        const number = get(['number'], record);
        return (
          <NameOfDocument to={RouteNames.FINANCES_PAYMENT_VIEW(item, type)}>{number}</NameOfDocument>
        );
      },
    },
    {
      title: type === RECEIVABLES ? t('postedDate') : t('paidDate'),
      dataIndex: 'postedDate',
      key: 'postedDate',
      align: 'left' as 'left',
    },
    {
      title: type === RECEIVABLES ? t('debtorName') : t('vendor'),
      dataIndex: 'debtorName',
      key: 'debtorName',
      align: 'left' as 'left',
      render: (item: string, record: PaymentTableDTM | {}) => {
        const debtorName = get(['debtorName'], record);
        const vendorName = get(['vendorName'], record);

        return type === RECEIVABLES ? debtorName : vendorName;
      },
    },
    {
      title: t('status'),
      dataIndex: 'status',
      key: 'status',
      align: 'left' as 'left',
      render: (item: statuses) => status[item],
    },
    {
      title: type === RECEIVABLES ? t('totalReceived') : t('totalPaid'),
      dataIndex: 'total',
      key: 'total',
      align: 'right' as 'right',
      render: (sum: number) => <>$ {normalizationCost(sum, { toFixed: 2, thousandthSeparatorComma: true })}</>,
    },
    {
      title: t('totalLeft'),
      dataIndex: 'totalLeft',
      key: 'totalLeft',
      align: 'right' as 'right',
      render: (sum: number) => <>$ {normalizationCost(sum, { toFixed: 2, thousandthSeparatorComma: true })}</>,
    },
  ], [data]);

  return (
    <CustomTable
      columns={columns}
      dataSource={data}
      pagination={{
        onChange: (page) => onChangePage(page),
        pageSize: 15,
        total: totalElements,
        showSizeChanger: false,
        current: activePage,
      }}
    />
  );
};

export { TableComponent };
