import styled from 'styled-components';
import AntdDivider from 'antd/es/divider';
import Typography from 'antd/es/typography';

import { ShipmentDetailsForm } from 'shipment-operations/view/components';

export const Divider = styled(AntdDivider)`
  margin: 16px 0 0 0;
`;

export const Label = styled(Typography.Text).attrs({
  'data-class': 'ShippingPartiesFormComponent_Label',
})`
  display: block;
  margin-bottom: 4px;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
`;

export const FormContainer = styled(ShipmentDetailsForm).attrs({
  'data-class': 'ShippingPartiesFormComponentFormContainer',
})`
  position: relative;
`;
