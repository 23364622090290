import React, { FC, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import SettingOutlined from '@ant-design/icons/SettingOutlined';
import TeamOutlined from '@ant-design/icons/TeamOutlined';
import Spin from 'antd/es/spin';
import { NavLink } from 'react-router-dom';

import { Button } from 'app-wrapper/view/components';
import { RouteNames } from 'app-wrapper/constants';
import { OrganizationDTM, EOrganizationStatus } from 'user-management/models/dtm';

import {
  OrganizationDataContainer,
  OrganizationIconContainer,
  OrganizationIconDefaultBG,
  OrganizationName,
  OrganizationNumber,
  OrganizationAccountManage,
  CreateNewOrganizationContainer,
  OrganizationDataLoadingContainer,
} from './OrganizationMenuItem.styled';

interface IOrganizationMenuItemComponentProps {
  isLoading: boolean
  organization?: OrganizationDTM
  initDataUpload: () => void
  clear: () => void
  onClosePopover: () => void
}

export const OrganizationMenuItemComponent: FC<IOrganizationMenuItemComponentProps> = ({
  isLoading,
  organization,
  initDataUpload,
  clear,
  onClosePopover,
}) => {
  const { t } = useTranslation();

  const isOrganizationInDraft = useMemo(() => organization?.status === EOrganizationStatus.DRAFT, [organization]);

  useEffect(() => {
    initDataUpload();

    return clear;
  }, []);

  return (
    <>
      {
        isLoading && (
          <OrganizationDataLoadingContainer><Spin /></OrganizationDataLoadingContainer>
        )
      }
      {
        !isLoading && organization && !isOrganizationInDraft && (
          <OrganizationDataContainer>
            <OrganizationIconContainer>
              <OrganizationIconDefaultBG>
                <TeamOutlined />
              </OrganizationIconDefaultBG>
            </OrganizationIconContainer>
            <OrganizationName>
              {organization?.name}
            </OrganizationName>
            <OrganizationNumber>
              {`${t('Organization ID')}: ${organization?.id}`}
            </OrganizationNumber>
            <OrganizationAccountManage>
              <NavLink to={RouteNames.ORGANIZATION_PROFILE()}>
                <Button type="ghost" size="small" icon={<SettingOutlined />} onClick={onClosePopover}>
                  {t('Manage')}
                </Button>
              </NavLink>
            </OrganizationAccountManage>
          </OrganizationDataContainer>
        )
      }
      {
        !isLoading && (!organization || isOrganizationInDraft) && (
          <CreateNewOrganizationContainer>
            <NavLink to={RouteNames.NEW_ORGANIZATION_WIZARD()}>
              <Button size="small" onClick={onClosePopover}>
                {t('Create Organization')}
              </Button>
            </NavLink>
          </CreateNewOrganizationContainer>
        )
      }
    </>
  );
};
