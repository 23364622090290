import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import Skeleton from 'antd/es/skeleton';

import {
  Container,
  ContainerWrap,
  Actions,
  Button,
} from './Footer.styled';

interface FooterComponentProps {
  acceptSecondStep: (shipmentId: string) => void;
  closeDrawer: () => void;
  isLoading: boolean;
  isAbleToAccept: boolean;
}

export const FooterComponent: FC<FooterComponentProps> = ({
  acceptSecondStep,
  closeDrawer,
  isLoading,
  isAbleToAccept,
}) => {
  const { shipmentId = '' } = useParams();
  const { t } = useTranslation();

  const handleAcceptSecond = useCallback(() => {
    acceptSecondStep(shipmentId);
  }, [acceptSecondStep, shipmentId]);

  return (
    <Container>
      {isLoading ? <Skeleton /> : (
        <ContainerWrap>
          <Actions>
            <Button
              onClick={closeDrawer}
              type="default"
            >
              {t('Cancel')}
            </Button>

            <Button onClick={handleAcceptSecond} disabled={!isAbleToAccept}>
              {t('Accept')}
            </Button>
          </Actions>
        </ContainerWrap>
      )}
    </Container>
  );
};
