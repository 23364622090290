import React, {
  FC,
} from 'react';
import { useSelector } from 'react-redux';

import { R } from 'monetary/repository';
import { UC } from 'monetary/controllers';

import { CommodityComponent } from './Commodity.component';

export const Commodity: FC = (() => {
  const {
    form,
    isSubmitVisited,
    isSubmitClicked,
    hsCodeState,
    isCollapse,
    status,
    isAllFieldDisabled,
  } = useSelector(R.selectors.freightQuote.getFreightQuoteCurrent);
  const getErrors = useSelector(R.selectors.freightQuote.getFreightQuoteErrors);

  return (
    <CommodityComponent
      isAllDisabled={isAllFieldDisabled}
      forceCollapse={!isCollapse}
      isCollapsed={isCollapse}
      temperatureControl={form?.commodity?.temperatureControl}
      onChangeCommodityTemperature={UC.FreightQuote.onChangeCommodityTemperature}
      onAddCommodityValuesItem={UC.FreightQuote.onAddCommodityValuesItem}
      onRemoveCommodityValuesItem={UC.FreightQuote.onRemoveCommodityValuesItem}
      commodityValues={form?.commodity?.values}
      isCommodityError={getErrors?.commodity?.isCommodityError}
      quotaStatus={status}
      isSubmitVisited={isSubmitVisited}
      isSubmitClicked={isSubmitClicked}
      // HsCode
      getRFQCommodityHsCodeDyIndex={UC.FreightQuote.getRFQCommodityHsCodeDyIndex}
      hsCodeState={hsCodeState}
      onChangeCommodityHsCode={UC.FreightQuote.onChangeCommodityHsCode}
      onClearCommodityHsCode={UC.FreightQuote.onClearCommodityHsCode}
      onFocusCommodityHsCode={UC.FreightQuote.onFocusCommodityHsCode}
      onBlurCommodityHsCode={UC.FreightQuote.onBlurCommodityHsCode}
      onKeyPressHsCodeByIndex={UC.FreightQuote.onKeyPressHsCodeByIndex}
      // IMO Class
      onChangeCommodityIMOClass={UC.FreightQuote.onChangeCommodityIMOClass}
      onClearCommodityIMOClass={UC.FreightQuote.onClearCommodityIMOClass}
      onFocusCommodityIMOClass={UC.FreightQuote.onFocusCommodityIMOClass}
      onBlurCommodityIMOClass={UC.FreightQuote.onBlurCommodityIMOClass}
    />
  );
});
