import { currentSession } from 'app-wrapper/utils';

import { addPrefix } from './addPrefix';

const prepareParams = async (url: string, requestOptions: RequestInit): Promise<Response> => {
  const options: RequestInit = {
    ...requestOptions,
    headers: {
      Authorization: `Bearer ${await currentSession.getIdToken()}`,
      ...requestOptions.headers,
    },
  };

  if ((process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test')
    && options.headers) {
    options.headers = {
      ...options.headers,
    };
  }
  return fetch(addPrefix(url), options);
};

export const get = (url: string) => {
  const requestOptions = {
    method: 'GET',
  };
  return prepareParams(url, requestOptions);
};

export const post = (url: string, body?: unknown, headers?: object) => {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', ...headers },
    body: JSON.stringify(body),
  };
  return prepareParams(url, requestOptions);
};

export const put = (url: string, body?: unknown) => {
  const requestOptions = {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(body),
  };
  return prepareParams(url, requestOptions);
};

export const Delete = (url: string) => {
  const requestOptions = {
    method: 'DELETE',
  };
  return prepareParams(url, requestOptions);
};

export const fetchApi = {
  get,
  post,
  put,
  delete: Delete,
};
