import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';

import { ShippingPartiesCardComponent } from './ShippingPartiesCard.component';

const ShippingPartiesCardContainer: FC = () => {
  const isLoading = useSelector(R.selectors.transportationOverview.getLoading);
  const transportationOverview = useSelector(R.selectors.transportationOverview.getTransportationOverview);

  return (
    <ShippingPartiesCardComponent
      isLoading={isLoading}
      shipper={transportationOverview.shipper}
      consignee={transportationOverview.consignee}
      notifyParty={transportationOverview.notifyParty}
    />
  );
};

export {
  ShippingPartiesCardContainer as ShippingPartiesCard,
};
