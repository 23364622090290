import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { InputSearch } from 'app-wrapper/view/components';
import { InputWrap } from './SearchInput.styled';

interface ISearchInputComponentProps {
  searchQuery: string;
  setSearchQuery: (query: string) => void;
}

export const SearchInputComponent: FC<ISearchInputComponentProps> = ({ setSearchQuery, searchQuery }) => {
  const { t } = useTranslation();

  const handleChange = useCallback((e) => {
    setSearchQuery(e.target.value);
  }, [searchQuery]);

  return (
    <InputWrap>
      <InputSearch
        size="small"
        placeholder={t('Search by contract name or number')}
        onChange={handleChange}
        value={searchQuery}
      />
    </InputWrap>
  );
};
