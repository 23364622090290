import React from 'react';
import { useSelector } from 'react-redux';

import { R } from 'user-management/repository';
import { UC } from 'user-management/controllers';

import { TeamDrawerFooterComponent } from './TeamDrawerFooter.component';

const TeamDrawerFooterContainer = () => {
  const isAbleToSave = useSelector(R.selectors.teams.getIsAbleToSaveAddOrChangeTeam);
  const isEditMode = useSelector(R.selectors.teams.getIsAddOrChangeDrawerInEditMode);

  return (
    <TeamDrawerFooterComponent
      onClose={UC.teams.closeChangeTeamDrawer}
      onSave={UC.teams.addOrChangeTeam}
      isEditMode={isEditMode}
      isAbleToSave={isAbleToSave}
    />
  );
};

export { TeamDrawerFooterContainer as TeamDrawerFooter };
