export enum EOceanRateLoadChargeCodeType {
  DRAYAGE = 'DRAYAGE',
  FCL = 'FCL',
  LCL = 'LCL',
  LTL = 'LTL',
  FTL = 'FTL',
  OOG = 'OOG',
  ALL = 'ALL',
}

export enum EOceanRateLoadType {
  FCL = 'FCL',
  LCL = 'LCL',
}
