import React, { FC } from 'react';

import themesColors from 'app-wrapper/view/themes/themesColors';

import {
  StatusWrap,
  StatusText,
  StatusIndicator,
} from './Status.styled';

export enum EStatusType {
  NOT_ACTIVE = 'NOT_ACTIVE',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
}

const STATUS_TYPE_TO_COLOR_MAP: Record<EStatusType, string> = {
  [EStatusType.NOT_ACTIVE]: themesColors.characterBrandingDisabled025,
  [EStatusType.IN_PROGRESS]: themesColors.primaryBranding6,
  [EStatusType.COMPLETED]: themesColors.characterBrandingSuccess,
  [EStatusType.FAILED]: themesColors.characterBrandingDanger,
};

interface StatusComponentProps {
  text: string;
  type: EStatusType;
}

const StatusComponent: FC<StatusComponentProps> = ({ text, type }) => (
  <StatusWrap>
    <StatusIndicator
      style={{
        backgroundColor: STATUS_TYPE_TO_COLOR_MAP[type],
      }}
    />

    <StatusText>
      {text}
    </StatusText>
  </StatusWrap>
);

export { StatusComponent as Status };
