import React, { FC } from 'react';

interface IBadgeProps {
  color?: 'pink'
  | 'red'
  | 'yellow'
  | 'orange'
  | 'cyan'
  | 'green'
  | 'blue'
  | 'purple'
  | 'geekblue'
  | 'magenta'
  | 'volcano'
  | 'gold'
  | 'lime'
  inlineColor?: string
}

export const Badge: FC<IBadgeProps> = ({ color, inlineColor }) => (
  <span className="ant-badge ant-badge-status ant-badge-not-a-wrapper">
    <span
      className={color ? `ant-badge-status-dot ant-badge-status-${color}` : 'ant-badge-status-dot'}
      style={{
        ...(inlineColor ? {
          backgroundColor: inlineColor,
        } : {}),
      }}
    />
  </span>
);
