import { BaseController, controller } from 'proto/BaseController';

import { R } from 'user-management/repository';
import { RouteNames } from 'app-wrapper/constants';
import { EOrganizationStatus, OrganizationDTM } from 'user-management/models/dtm';

@controller
export class NewOrganizationSuccessController extends BaseController {
  public initData = async () => {
    let organization: OrganizationDTM | null = null;

    this.dispatch(R.actions.newOrganizationWizardReviewAndConfirm.setIsLoading(true));

    try {
      organization = await R.services.organization.getCurrentOrganization();
    } catch (e) {
      console.error('NewOrganizationWizardReviewAndConfirmController ERROR: initData');
    }

    if (organization) {
      const { status } = organization;

      if (status !== EOrganizationStatus.WAITING_APPROVAL) {
        this.navigate(RouteNames.OVERVIEW());
        return;
      }
    }

    if (!organization) {
      this.navigate(RouteNames.OVERVIEW());
      return;
    }

    this.dispatch(R.actions.newOrganizationWizardReviewAndConfirm.setIsLoading(false));
  }

  public handleClose = () => {
    this.navigate(RouteNames.OVERVIEW());
  }

  public handleViewMyOrganization = () => {
    this.navigate(RouteNames.ORGANIZATION_PROFILE());
  }
}
