import { apiWorker } from 'app-wrapper/repository/utilsServices';
import { WorkgroupMemberDTM } from 'shipment-operations/models/dtm';
import { IAddShipmentWorkgroupMemberContract } from 'shipment-operations/models/contracts';
import { EAccountDepartmentType, EOrganizationMemberRole, EShipmentOrganizationRole } from 'user-management/constants';

export class ShipmentWorkgroupService {
  private base = '/shipment-service/api/v1/shipments';

  public getShipmentWorkgroup = async (shipmentId: string): Promise<WorkgroupMemberDTM[]> => {
    let people: WorkgroupMemberDTM[] = [];

    const response = await apiWorker.requestGetBySchema(`${this.base}/${shipmentId}/workgroup` as '/api/v1/shipments/{shipmentId}/workgroup');

    people = response.data.members ? response.data.members.map((member) => WorkgroupMemberDTM.fromPlain({
      id: member.id as number,
      workgroupId: response.data.id as number,
      firstName: member.firstName as string,
      lastName: member.lastName as string,
      email: member.email,
      phone: member.phone || '',
      jobTitle: member.jobTitle || '',
      role: member.role as EAccountDepartmentType,
      organization: {
        id: member.organizationId,
        shipmentRoles: member.shipmentRoles as EShipmentOrganizationRole[],
        organizationRole: member.organizationRole as EOrganizationMemberRole,
      },
    })) : [];

    return people;
  };

  public addShipmentWorkgroupMember = async (shipmentId: number, workgroupId: number, body: IAddShipmentWorkgroupMemberContract) => {
    await apiWorker.requestPost(`${this.base}/${shipmentId}/workgroups/${workgroupId}/members`, body);
  };

  public removeShipmentWorkgroupMember = async (shipmentId: number, workgroupId: number, memberId: number) => {
    await apiWorker.requestDelete(`${this.base}/${shipmentId}/workgroups/${workgroupId}/members/${memberId}`);
  };
}
