import React, { FC, memo } from 'react';
import { useSelector } from 'react-redux';

import { REQUEST_STATUS } from 'app-wrapper/constants';

import { UC } from 'shipment-operations/controllers';
import { R } from 'shipment-operations/repository';

import { ShipmentPartyAddContactComponent } from './ShipmentPartyAddContact.component';

const ShipmentPartyAddContactContainer: FC = () => {
  const contactBookDefault = useSelector(R.selectors.bookingWizard.getContactBookDefault);
  const contactBook = useSelector(R.selectors.bookingWizard.getContactBookUpdate);

  const listIndex = contactBook.activeListTabIndex;

  const companyListState = useSelector(R.selectors.bookingWizard.getContactBookUpdateCompanyListByIds(listIndex));

  const contactPersonsIds = companyListState?.contactPersonsIds || [];

  const companyListContactPersons = useSelector(R.selectors.bookingWizard.getContactBookUpdateCompanyListContactPersonsByIds(contactPersonsIds));

  return (
    <ShipmentPartyAddContactComponent
      companyListContactPersons={companyListContactPersons}
      companyListStateIndex={listIndex}
      isUpdating={contactBookDefault.getManualRequestStatus === REQUEST_STATUS.pending || !companyListState?.isUpdate}
      updateForm={UC.bookingWizardDrawer.onSPAddContact}
      onDiscard={UC.bookingWizardDrawer.onCancelShipmentPartyAddContact}
    />
  );
};

const ShipmentPartyAddContactContainerCache = memo(ShipmentPartyAddContactContainer);

export { ShipmentPartyAddContactContainerCache as ShipmentPartyAddContactPage };
