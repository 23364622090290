import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { R } from 'app-wrapper/repository';
import { R as shipmentOperationsR } from 'shipment-operations/repository';
import { UC as appUC, UC } from 'app-wrapper/controllers';
import { RouteNames } from 'app-wrapper/constants';

import { AlertsTableComponent } from './index';

const AlertsTableContainer = () => {
  const tasks = useSelector(R.selectors.overview.getTasksToRender);
  const countAllTasks = useSelector(R.selectors.overview.getCountAllTasks);
  const isLoading = useSelector(shipmentOperationsR.selectors.overview.getLoadingStatus);
  const { shipmentId = '' } = useParams();

  return (
    <AlertsTableComponent
      openAgreementsDrawer={UC.agreements.openDrawerFromTasks}
      openVGMDrawer={UC.drawer.openVGMTaskDrawer}
      openRailBillingDrawer={UC.drawer.openRailBillingDrawer}
      openMismatchDrawer={appUC.drawer.openShipmentMismatches}
      openMaerskDrawer={UC.drawer.openMaerskChanges}
      openChangesDrawer={UC.drawer.openShipmentChanges}
      openShipmentCostChangesDrawer={UC.drawer.openShipmentCostChangesDrawer}
      openBookingConfirmationDrawer={UC.drawer.openBookingConfirmation}
      allTasks={tasks}
      isLoading={isLoading}
      countAllTasks={countAllTasks}
      goToLink={RouteNames.SHIPMENT_CENTER_ALERTS(shipmentId)}
    />
  );
};

const AlertsTableCached = memo(AlertsTableContainer);

export { AlertsTableCached as AlertsTable };
