import i18n from 'i18next';

import { ValidationErrorDTM, ValidationErrorType } from 'app-wrapper/types';

export const validateRequiredField = (field: unknown) => (
  field ? undefined : ValidationErrorDTM.fromPlain({
    type: ValidationErrorType.DEFAULT,
    message: i18n.t('basicErrors.REQUIRED_MESSAGE'),
  })
);
