import React from 'react';

import { R } from 'shipment-operations/repository';
import { useAppSelector } from 'app-wrapper/hooks';

import { ServicesComponent } from 'shipment-operations/view/pages/CreateCreditNote/components/FirstStep/components/Services/Services.component';

const Services = () => {
  const tableData = useAppSelector(R.selectors.createCreditNote.getServicesDataRest);
  const sum = useAppSelector(R.selectors.createCreditNote.getServicesDataSum);
  const containers = useAppSelector(R.selectors.createCreditNote.uniqServicesContainers);
  const data = useAppSelector(R.selectors.createCreditNote.groupedServicesContainers);

  return (
    <ServicesComponent
      data={data}
      sum={sum}
      containers={containers}
      tableData={tableData}
    />
  );
};

export { Services };
