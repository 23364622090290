import { divFlex } from 'app-wrapper/view/themes/mixin';
import styled from 'styled-components';

import { FlexCol } from 'app-wrapper/view/guideline';

export const TypeSwitchWrap = styled.div`
  position: absolute;
  right: 78px;
`;

export const Content = styled.div`
  padding-bottom: 120px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  height: 100%;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const ContentSkeletons = styled(FlexCol)`
  gap: 8px;
`;

export const BookingDrawerDivider = styled.div.attrs({
  'data-class': 'BookingDrawerDivider',
})`
  ${divFlex}
  margin-top: 8px;
  height: 1px;
  width: 100%;
  border-top: 1px dashed ${({ theme }) => theme.themesColors.neutralBranding4};
`;
