import React, { FC } from 'react';

import { UC } from 'app-wrapper/controllers';

import { CommandCenterComponent } from './CommandCenter.component';

const CommandCenterContainer: FC = () => (
  <CommandCenterComponent
    onResetTaskPage={UC.CommandCenter.onResetTaskPage}
  />
);

export { CommandCenterContainer as CommandCenter };
