import { createSelector } from 'reselect';
import isEmpty from 'lodash/fp/isEmpty';

import { RootState } from 'app-wrapper/store';
import { PAYABLES, RECEIVABLES } from 'shipment-operations/constants';

const localState = (state: RootState) => state.makePayment;

const getPaymentDate = createSelector(
  localState,
  (state) => state.paymentDate,
);

const getReceivedAmount = createSelector(
  localState,
  (state) => state.receivedAmount,
);

const getIsRecordDisabled = createSelector(
  getPaymentDate,
  getReceivedAmount,
  (date, amount) => !date && !amount,
);

const getIsLoadingRecord = createSelector(
  localState,
  (state) => state.isLoadingRecord,
);

const getInputsErrors = createSelector(
  localState,
  (state) => state.inputsErrors,
);

const getReference = createSelector(
  localState,
  (state) => state.reference,
);

const getIsErrorCreation = createSelector(
  localState,
  (state) => state.createPaymentError,
);

const getIsSuccessCreation = createSelector(
  localState,
  (state) => state.createPaymentSuccess,
);

const getPaymentView = createSelector(
  localState,
  (state) => state.paymentView,
);

const getPaymentViewNumber = createSelector(
  getPaymentView,
  (payment) => payment?.number,
);

const getPaymentViewPostedDate = createSelector(
  getPaymentView,
  (payment) => payment?.createdAt,
);

const getPaymentViewReference = createSelector(
  getPaymentView,
  (payment) => payment?.reference,
);

const getPaymentViewAmount = createSelector(
  getPaymentView,
  (payment) => payment?.amount || 0,
);

const getPaymentViewAmountPaid = createSelector(
  getPaymentView,
  (payment) => payment?.paid || 0,
);

const getPaymentViewLeftAmount = createSelector(
  getPaymentViewAmount,
  getPaymentViewAmountPaid,
  (amount, paid) => amount - paid,
);

const getLinkedInvoices = createSelector(
  localState,
  (state) => state.linkedInvoices,
);

const getDataForPaymentViewTable = createSelector(
  getPaymentView,
  getLinkedInvoices,
  (payment, invoices) => invoices.map((item) => {
    const invoicePayment = payment?.transactions.find((elem) => elem.destination.id === item.id);
    return ({
      id: item.number,
      dueDate: item.dueDate,
      total: item.billed,
      payment: invoicePayment?.amount,
      shipmentId: item.shipment?.id,
      linkId: item.id,
    });
  }),
);

const getDataForPaymentEditTable = createSelector(
  getPaymentView,
  getLinkedInvoices,
  (payment, invoices) => invoices.map((item) => {
    const invoicePayment = payment?.transactions.find((elem) => elem.destination.id === item.id);
    return ({
      id: item.number,
      dueDate: item.dueDate,
      total: item.billed,
      balance: item.balance + (invoicePayment?.amount || 0),
      payment: invoicePayment?.amount,
      innerId: invoicePayment?.id,
    });
  }),
);

const getPaymentViewError = createSelector(
  localState,
  (state) => state.loadPaymentViewError,
);

const getIsLoadingPaymentView = createSelector(
  localState,
  (state) => state.paymentViewLoading,
);

const getPaymentViewStatus = createSelector(
  getPaymentView,
  (payment) => payment?.status,
);

const getPaymentCreditorName = createSelector(
  getPaymentView,
  (payment) => payment?.creditor?.name,
);

const getPaymentDebtorName = createSelector(
  getPaymentView,
  (payment) => payment?.debtor?.name,
);

const getCreatedPayment = createSelector(
  localState,
  (state) => state.createdPayment,
);

const getIsOpenEdit = createSelector(
  localState,
  (state) => state.isOpenEdit,
);

const getEditPayment = createSelector(
  localState,
  (state) => state.editPayment,
);

const getEditedDate = createSelector(
  getEditPayment,
  (payment) => payment.date,
);

const getEditedReference = createSelector(
  getEditPayment,
  (payment) => payment.reference,
);

const getEditedTotalAmount = createSelector(
  getEditPayment,
  (payment) => payment.totalAmount || 0,
);

const getEditedTransactions = createSelector(
  getEditPayment,
  (payment) => payment.transactions,
);

const getEditSumTransactions = createSelector(
  getEditedTransactions,
  (transactions) => (transactions ? transactions.reduce((acc, cur) => acc + cur.value, 0) : 0),
);

const getEditAmountLeft = createSelector(
  getEditedTotalAmount,
  getEditSumTransactions,
  (total, used) => total - used,
);

const getIsLoadingEditTransaction = createSelector(
  localState,
  (state) => state.isLoadingEditTransaction,
);

const getIsLoadingEditPayment = createSelector(
  localState,
  (state) => state.isLoadingEditPayment,
);

const getIsLoadingEdit = createSelector(
  getIsLoadingEditTransaction,
  getIsLoadingEditPayment,
  (transaction, payment) => (transaction || payment),
);

const getShouldRefresh = createSelector(
  localState,
  (state) => state.shouldRefresh,
);

const getIsErrorEditTransaction = createSelector(
  localState,
  (state) => state.isErrorEditTransaction,
);

const getIsErrorEditPayment = createSelector(
  localState,
  (state) => state.isErrorEditPayment,
);

const getIsErrorEdit = createSelector(
  getIsErrorEditTransaction,
  getIsErrorEditPayment,
  (transaction, payment) => (transaction || payment),
);

const getErrorType = createSelector(
  localState,
  (state) => state.editErrorType,
);

const getIsShowAmountError = createSelector(
  getEditSumTransactions,
  getEditedTotalAmount,
  (transactions, amount) => amount < transactions,
);

const getIsDisableEdit = createSelector(
  getIsShowAmountError,
  getEditedTransactions,
  (amountError, edited) => {
    const isErrorInTable = edited?.find((item) => (item.value || 0) > item.balance);
    return !isEmpty(isErrorInTable) || amountError;
  },
);

const getPaymentDocuments = createSelector(
  getPaymentView,
  (paymentView) => paymentView?.documents || [],
);

const getPaymentType = createSelector(
  localState,
  (state) => state.paymentType,
);

const getAccountIdToAttachDocuments = createSelector(
  getPaymentView,
  getPaymentType,
  (payment, paymentType) => {
    if (!payment) {
      return 0;
    }

    const { debtor, creditor } = payment;

    if (paymentType === PAYABLES && creditor) {
      return creditor.id;
    }

    if (paymentType === RECEIVABLES && debtor) {
      return debtor.id;
    }

    return 0;
  },
);

const getTempDocuments = createSelector(
  localState,
  (state) => state.tempDocuments,
);

const getDocumentsToDeleteIds = createSelector(
  localState,
  (state) => state.documentIdsToDelete,
);

export const makePaymentSelectors = {
  getPaymentDate,
  getReceivedAmount,
  getIsRecordDisabled,
  getIsLoadingRecord,
  getInputsErrors,
  getReference,
  getIsErrorCreation,
  getIsSuccessCreation,
  getPaymentViewNumber,
  getPaymentViewPostedDate,
  getPaymentViewReference,
  getPaymentViewAmount,
  getPaymentViewAmountPaid,
  getPaymentViewLeftAmount,
  getDataForPaymentViewTable,
  getPaymentViewError,
  getIsLoadingPaymentView,
  getPaymentViewStatus,
  getCreatedPayment,
  getIsOpenEdit,
  getEditedDate,
  getEditedReference,
  getEditedTotalAmount,
  getLinkedInvoices,
  getPaymentView,
  getEditSumTransactions,
  getEditAmountLeft,
  getEditedTransactions,
  getDataForPaymentEditTable,
  getIsLoadingEdit,
  getShouldRefresh,
  getIsErrorEdit,
  getErrorType,
  getIsShowAmountError,
  getIsDisableEdit,
  getPaymentCreditorName,
  getPaymentDebtorName,
  getPaymentDocuments,
  getAccountIdToAttachDocuments,
  getTempDocuments,
  getDocumentsToDeleteIds,
  getPaymentType,
};
