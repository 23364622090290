import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';
import { PermissionAttributePolicy } from 'shipment-operations/constants';

import { MBLContentComponent } from '../MBLContent';

const MBLDraftContentContainer = () => {
  const permissions = useSelector(R.selectors.shipment.getSIPermissions);
  const draftMBL = useSelector(R.selectors.billOfLadingCommon.getDraftMBL);
  const {
    consignee,
    shipper,
    notifyParty,
  } = draftMBL ? draftMBL.getShippingPartiesInformation() : { consignee: undefined, shipper: undefined, notifyParty: undefined };
  const containers = draftMBL ? draftMBL.containers : [];
  const cargos = draftMBL ? draftMBL.cargos : [];
  const hazmatCargos = cargos.filter(({ isHazmat }) => isHazmat);

  const isChargesSectionVisible = useMemo(() => permissions.chargesMBLVisible === PermissionAttributePolicy.READ, [permissions]);

  return draftMBL ? (
    <MBLContentComponent
      freightPaymentLocation={draftMBL.freightPaymentLocation}
      contactDetails={draftMBL.contactDetails}
      carrierSCAC={draftMBL.carrierScac}
      carrierInfoItems={draftMBL.getCarrierInformationItems()}
      transportInfoItems={draftMBL.getTransportInformationItems()}
      consignee={consignee}
      shipper={shipper}
      notifyParty={notifyParty}
      billOfLadingItems={draftMBL.getBillOfLadingInformationItems()}
      routingItems={draftMBL.getRoutingInformationItems()}
      routingInstructions={draftMBL.routingInstructions}
      referencesItems={draftMBL.getReferencesInformationItems()}
      cargos={cargos}
      containers={containers}
      hazmatCargos={hazmatCargos}
      chargesItems={draftMBL.getChargesInformationItems()}
      blReleaseItems={draftMBL.getBillOfLadingReleaseItems()}
      shippedOnBoardItems={draftMBL.getShippedDateItems()}
      additionalNotes={draftMBL.additionalNotes}
      clauses={draftMBL.getClausesItems()}
      termsAndConditions={draftMBL.termsAndConditions}
      temperatureControl={draftMBL.temperatureControl}
      isChargesSectionVisible={isChargesSectionVisible}
    />
  ) : null;
};

export { MBLDraftContentContainer as MBLDraftContent };
