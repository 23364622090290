import {
  IsString, IsOptional, IsBoolean,
} from 'class-validator';
import { BaseDTM } from 'proto/BaseDTM';

export type TFieldErrorDTM = {
  id?: string
  isBlur?: boolean
  isFocus?: boolean
  message?: string
  isVisited?: boolean
  isTooltipVisible?: boolean
  hasError?: boolean
}

export class FieldErrorDTM extends BaseDTM<TFieldErrorDTM> {
  @IsString()
  id?: string

  @IsOptional()
  @IsBoolean()
  isBlur?: boolean

  @IsOptional()
  @IsBoolean()
  isFocus?: boolean

  @IsOptional()
  @IsString()
  message?: string

  @IsOptional()
  @IsBoolean()
  isVisited?: boolean

  @IsOptional()
  @IsBoolean()
  isTooltipVisible?: boolean

  @IsOptional()
  @IsBoolean()
  hasError?: boolean

  isTooltipMessageVisible() {
    return Boolean(this.message && this.isFocus);
  }
}
