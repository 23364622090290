import React, {
  FC,
} from 'react';

import { IFreightQuotaContentSchedulesDTM } from 'monetary/models/dtm';
import { UC } from 'monetary/controllers';

import { RFQCarouselComponent } from './RFQCarousel.component';

export interface IRFQCarouselProps {
  contentItemWidth: number
  schedules?: IFreightQuotaContentSchedulesDTM[]
  indexRFQItem?: number
}

export const RFQCarousel: FC<IRFQCarouselProps> = ({
  contentItemWidth,
  schedules,
  indexRFQItem,
}) => (
  <RFQCarouselComponent
    contentItemWidth={contentItemWidth}
    schedules={schedules}
    setIndexSchedule={UC.FreightQuote.onClickQuotaSchedule(indexRFQItem || 0)}
  />
);
