import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'antd/es/skeleton';

import {
  RFQServiceByIdContentRoutesTransportation,
} from 'monetary/models/dtm/Quotas';

import {
  Section,
  SectionTitle,
  Content,
} from './TransportPlan.styled';
import { TransportLegForm } from './components';

interface TransportPlanComponentProps {
  transportations: RFQServiceByIdContentRoutesTransportation[];
  isPending: boolean;
}

export const TransportPlanComponent: FC<TransportPlanComponentProps> = ({ transportations, isPending }) => {
  const { t } = useTranslation();

  return isPending ? <Skeleton /> : (
    <Section>
      <SectionTitle>
        {t('Transport Plan')}
      </SectionTitle>

      <Content>
        {transportations.map(({ id }, index) => (
          <TransportLegForm key={`TransportPlanComponent_${id}_${index + 0}`} transportationId={id} index={index} />
        ))}
      </Content>
    </Section>
  );
};
