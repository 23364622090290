import React, { FC, useMemo } from 'react';
import Typography from 'antd/es/typography';
import { useTranslation } from 'react-i18next';

import { Button, Col, Row } from 'app-wrapper/view/components';
import { QUOTAS_STATUS } from 'app-wrapper/constants';

import { useWindowSize } from 'app-wrapper/hooks';
import {
  GorizontalMenuSvg, NavigatorIconSvg, ServicesIconSvg, SkypaceSpin, WeightIconSvg,
} from 'app-wrapper/view/icons';

import {
  FQFRFooterTotalsItemTitle,
  FQFRFooterTotalsItemTitleBlock,
  FQFRFooterTotalsItemTitleBlockWrapper,
  FQFRFormFooter,
  FQFRFormFooterSubmit,
  FQFRFormFooterTotals,
} from './FreightQuoteFooter.styled';

export interface IFreightQuoteFooterComponentProps {
  disabledSubmit?: boolean
  isLoadSubmit?: boolean
  quotasCreateFailedMessage?: string
  isFormCollapsed?: boolean
  isReuseRequest?: boolean
  hideButton?: boolean
  countsContainersTotal?: string
  weightTotal?: string
  volumeTotal?: string
  requestQuotaStatus?: string
}

export const FreightQuoteFooterComponent: FC<IFreightQuoteFooterComponentProps> = (props) => {
  const { t } = useTranslation();
  const { isFullWidth } = useWindowSize();
  const {
    disabledSubmit,
    isLoadSubmit,
    quotasCreateFailedMessage,
    isFormCollapsed,
    isReuseRequest,
    hideButton,
    countsContainersTotal,
    weightTotal,
    volumeTotal,
    requestQuotaStatus,
  } = props;

  const isQuotasCreateFailedMessage = quotasCreateFailedMessage ? !quotasCreateFailedMessage : (!isReuseRequest && disabledSubmit);
  const isDisableSubmit = requestQuotaStatus === QUOTAS_STATUS.pending ? true : isQuotasCreateFailedMessage;

  const widthFooter = useMemo(() => {
    if (hideButton) {
      return 24;
    }

    if (isFullWidth) {
      return 18;
    }

    return 16;
  }, [hideButton, isFullWidth]);

  const widthFooterItem = useMemo(() => {
    if (hideButton) {
      return 8;
    }

    if (isFullWidth) {
      return 5;
    }

    return 8;
  }, [hideButton, isFullWidth]);

  const getQuoteButton = isLoadSubmit ? (<SkypaceSpin />) : t('getQuotes');

  return (
    <FQFRFormFooter className="FreightQuoteFormRequest__footer">
      <FQFRFormFooterTotals span={widthFooter} className="FreightQuoteFormRequest__footer__totals">
        {isFormCollapsed && (
          <Row>
            <div>
              <Typography.Text>{t('Shipment Total')}</Typography.Text>
            </div>
          </Row>
        )}
        <Row className="FreightQuoteFormRequest__footer__totals__items">
          {!!countsContainersTotal
            && (
              <Col span={widthFooterItem} className="FreightQuoteFormRequest__footer__totals__items_item">
                <FQFRFooterTotalsItemTitle isFormCollapsed={isFormCollapsed}>
                  <FQFRFooterTotalsItemTitleBlock isSvg><GorizontalMenuSvg /></FQFRFooterTotalsItemTitleBlock>
                  {(!isFullWidth && isFormCollapsed) && (
                    <FQFRFooterTotalsItemTitleBlock isSeparate isSvg>{!isFullWidth && '×'}</FQFRFooterTotalsItemTitleBlock>
                  )}
                  <FQFRFooterTotalsItemTitleBlockWrapper isFormCollapsed={isFormCollapsed}>
                    <FQFRFooterTotalsItemTitleBlock isText>{countsContainersTotal} {!isFullWidth && 'cont'}</FQFRFooterTotalsItemTitleBlock>
                  </FQFRFooterTotalsItemTitleBlockWrapper>
                </FQFRFooterTotalsItemTitle>
              </Col>
            )}
          {!!weightTotal
            && (
              <Col span={widthFooterItem} className="FreightQuoteFormRequest__footer__totals__items_item">
                <FQFRFooterTotalsItemTitle isFormCollapsed={isFormCollapsed}>
                  <FQFRFooterTotalsItemTitleBlock isSvg><WeightIconSvg /></FQFRFooterTotalsItemTitleBlock>
                  <FQFRFooterTotalsItemTitleBlockWrapper isFormCollapsed={isFormCollapsed}>
                    <FQFRFooterTotalsItemTitleBlock isText>{weightTotal} {!isFullWidth && 'kg'}</FQFRFooterTotalsItemTitleBlock>
                  </FQFRFooterTotalsItemTitleBlockWrapper>
                </FQFRFooterTotalsItemTitle>
              </Col>
            )}
          {!!volumeTotal
            && (
              <Col span={widthFooterItem} className="FreightQuoteFormRequest__footer__totals__items_item">
                <FQFRFooterTotalsItemTitle isFormCollapsed={isFormCollapsed}>
                  <FQFRFooterTotalsItemTitleBlock isSvg><NavigatorIconSvg /></FQFRFooterTotalsItemTitleBlock>
                  <FQFRFooterTotalsItemTitleBlockWrapper isFormCollapsed={isFormCollapsed}>
                    <FQFRFooterTotalsItemTitleBlock isText>{volumeTotal} {!isFullWidth && 'cbm'}</FQFRFooterTotalsItemTitleBlock>
                  </FQFRFooterTotalsItemTitleBlockWrapper>
                </FQFRFooterTotalsItemTitle>
              </Col>
            )}
          {false && (
            <Col span={isFullWidth ? 5 : 6} className="FreightQuoteFormRequest__footer__totals__items_item">
              <Typography.Text><ServicesIconSvg />{isFullWidth && t('Services')}</Typography.Text>
            </Col>
          )}
        </Row>
      </FQFRFormFooterTotals>
      {!hideButton && (
        <FQFRFormFooterSubmit $isDisabled={isDisableSubmit} span={widthFooterItem} className="FreightQuoteFormRequest__footer__submit">
          <Button
            htmlType="submit"
            size="large"
            disabled={isDisableSubmit}
          >{isReuseRequest ? t('Reuse') : getQuoteButton}
          </Button>
        </FQFRFormFooterSubmit>
      )}
    </FQFRFormFooter>
  );
};
