import React, { FC, useEffect } from 'react';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import Tabs from 'antd/es/tabs';

import {
  OriginContainerYard,
  OriginContainerTerminalOperator,
  DestinationContainerYard,
  DestinationContainerTerminalOperator,
} from './components';
import {
  Page,
  Title,
  Section,
  LocationWrap,
} from './ShipmentLocations.styled';

interface IShipmentLocationsComponentProps {
  init: (shipmentId?: string) => void;
  cleanup: () => void;
}

export const ShipmentLocationsComponent: FC<IShipmentLocationsComponentProps> = ({
  init,
  cleanup,
}) => {
  const { shipmentId = '' } = useParams();
  const { t } = useTranslation();

  useEffect(() => {
    init(shipmentId);

    return cleanup;
  }, []);

  return (
    <Page>
      <Title>
        {t('Locations')}
      </Title>

      <Tabs defaultActiveKey="1">
        <Tabs.TabPane
          tab={t('Export Haulage')}
          key="EXPORT_HAULAGE"
        >
          <Section>
            <LocationWrap>
              <OriginContainerYard />
            </LocationWrap>

            <LocationWrap>
              <OriginContainerTerminalOperator />
            </LocationWrap>
          </Section>
        </Tabs.TabPane>

        <Tabs.TabPane
          tab={t('Import Haulage')}
          key="IMPORT_HAULAGE"
        >
          <Section>
            <LocationWrap>
              <DestinationContainerYard />
            </LocationWrap>

            <LocationWrap>
              <DestinationContainerTerminalOperator />
            </LocationWrap>
          </Section>
        </Tabs.TabPane>
      </Tabs>
    </Page>
  );
};
