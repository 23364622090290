import React from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';
import { UC } from 'shipment-operations/controllers';

import { BookingDrawerComponent } from './BookingDrawer.component';

const BookingDrawerContainer = () => {
  const isWizardOpened = useSelector(R.selectors.bookingWizard.getIsWizardOpened);
  const shipmentId = useSelector(R.selectors.bookingWizard.getShipmentId);
  const isLoading = useSelector(R.selectors.bookingWizard.getIsLoading);
  const currentStep = useSelector(R.selectors.bookingWizard.getCurrentStep);
  const hasTemperatureControl = useSelector(R.selectors.bookingWizard.getHasTemperatureControl);
  const shouldShowShipperInformation = useSelector(R.selectors.bookingWizard.getShouldShowShipperSection);
  const shouldShowConsigneeInformation = useSelector(R.selectors.bookingWizard.getShouldShowConsigneeSection);

  return (
    <BookingDrawerComponent
      isLoading={isLoading}
      onClose={UC.bookingWizard.closeWizard}
      isOpen={isWizardOpened}
      shipmentId={shipmentId}
      init={UC.bookingWizard.init}
      currentStep={currentStep}
      hasTemperatureControl={hasTemperatureControl}
      shouldShowShipperInformation={shouldShowShipperInformation}
      shouldShowConsigneeInformation={shouldShowConsigneeInformation}
    />
  );
};

export { BookingDrawerContainer as BookingDrawer };
