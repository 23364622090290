import React, { FC } from 'react';
import Skeleton from 'antd/es/skeleton';
import { useTranslation } from 'react-i18next';

import {
  AirVentField,
  GensetField,
  TemperatureField,
} from './components';
import {
  TemperatureControl,
  TemperatureControlContent,
  TemperatureControlHeader,
} from './TemperatureControl.styled';

interface TemperatureControlComponentProps {
  isUpdatePending: boolean;
  hasTemperatureControl: boolean;
}

export const TemperatureControlComponent: FC<TemperatureControlComponentProps> = ({ isUpdatePending, hasTemperatureControl }) => {
  const { t } = useTranslation();

  return isUpdatePending ? <Skeleton /> : (
    <>
      {hasTemperatureControl ? (
        <TemperatureControl>
          <TemperatureControlHeader>
            {t('Temperature Control')}
          </TemperatureControlHeader>

          <TemperatureControlContent>
            <TemperatureField />
            <AirVentField />
            <GensetField />
          </TemperatureControlContent>
        </TemperatureControl>
      ) : null}
    </>
  );
};
