import styled from 'styled-components';
import Typography from 'antd/es/typography';

import { divFlex } from 'app-wrapper/view/themes/mixin';

export const SBLTableRowCollapse = styled('div').attrs({
  'data-class': 'SBLTableRowCollapse',
})`
  ${divFlex}
  padding: 8px;
  flex-direction: row;
  border-top: ${({ theme }) => `1px dashed ${theme.themesColors.neutralBranding4}`}
`;

export const SBLCollapseContainerInfo = styled('div')`
  ${divFlex}
  max-width: 300px;
  width: 100%;
  flex-direction: column;
  padding-right: 16px;
  border-right: ${({ theme }) => `1px dashed ${theme.themesColors.neutralBranding4}`};
`;

export const SBLContainerInfoSectionTitle = styled(Typography)`
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: ${({ theme }) => theme.themesColors.secondaryDot45};
  
  &:not(:first-of-type) {
    margin-top: 16px;
  }
`;

export const SBLContainerInfoSectionText = styled(Typography)`
  ${divFlex}
  flex-direction: row;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
`;

export const SBLCollapseCargosInfo = styled.div`
  ${divFlex}
  width: 100%;
  padding: 0px 16px 18px 16px;
  flex-direction: column;
`;

export const SBLCargosInfoDivider = styled.div`
  margin-top: 8px;
  width: 100%;
  border-bottom: ${({ theme }) => `1px dashed ${theme.themesColors.neutralBranding4}`};
`;

export const SBLInfoSectionDivider = styled.div`
  margin: 0 2px;
`;
