import { action, makeObservable } from 'mobx';
import { IARQQuotesItem } from 'monetary/view/components';
import { BaseMobxStore } from 'proto/BaseMobxStore';

import { IAllRequestsState } from 'monetary/models/states';

export const initialState: IAllRequestsState<IARQQuotesItem> = {
  isLoading: false,
  items: [],
};

export class AllRequestsStore<T extends IAllRequestsState<IARQQuotesItem> = IAllRequestsState<IARQQuotesItem>> extends BaseMobxStore<IAllRequestsState<IARQQuotesItem>> {
  initialState = initialState

  constructor(_initialState?: T) {
    super();
    makeObservable(this);
    this.init(_initialState || this.initialState);
  }

  @action
  setLoading(isLoading: boolean) {
    this.state.isLoading = isLoading;
  }

  @action
  addItems(items: IARQQuotesItem[]) {
    const requestsIds = items?.reduce((prev: string[], item) => {
      prev.push(String(item.id));
      return prev;
    }, []) || [];

    this.state.items = [
      ...this.state.items
        ?.filter((itemContent) => !requestsIds
          .includes(`${itemContent.id}`)) || [],
      ...items,
    ];
  }

  @action
  setItems(items: IARQQuotesItem[]) {
    this.state.items = items;
  }
}
