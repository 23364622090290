import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { R } from 'user-management/repository';
import { UC } from 'user-management/controllers';

import { ChangePasswordComponent } from './ChangePassword.component';

const ChangePasswordContainer: FC = () => {
  const isLoading = useSelector(R.selectors.changePassword.getLoading);
  const wasAnyFormFieldChanged = useSelector(R.selectors.changePassword.wasAnyFormFieldChanged);
  const currentPassword = useSelector(R.selectors.changePassword.getCurrentPassword);
  const currentPasswordError = useSelector(R.selectors.changePassword.getCurrentPasswordError);
  const newPassword = useSelector(R.selectors.changePassword.getNewPassword);
  const newPasswordError = useSelector(R.selectors.changePassword.getNewPasswordError);
  const confirmNewPassword = useSelector(R.selectors.changePassword.getConfirmNewPassword);
  const confirmNewPasswordError = useSelector(R.selectors.changePassword.getConfirmNewPasswordError);

  return (
    <ChangePasswordComponent
      isLoading={isLoading}
      wasAnyFormFieldChanged={wasAnyFormFieldChanged}
      currentPasswordValue={currentPassword}
      currentPasswordError={currentPasswordError}
      currentPasswordChange={UC.changePassword.setCurrentPassword}
      currentPasswordBlur={UC.changePassword.onBlurCurrentPassword}
      newPasswordValue={newPassword}
      newPasswordError={newPasswordError}
      newPasswordChange={UC.changePassword.setNewPassword}
      newPasswordBlur={UC.changePassword.onBlurNewPassword}
      confirmNewPasswordValue={confirmNewPassword}
      confirmNewPasswordError={confirmNewPasswordError}
      confirmNewPasswordChange={UC.changePassword.setConfirmNewPassword}
      confirmNewPasswordBlur={UC.changePassword.onBlurConfirmNewPassword}
      onOk={UC.changePassword.changeUserPassword}
      onCancel={UC.changePassword.closeChangePasswordDrawer}
    />
  );
};

export { ChangePasswordContainer as ChangePassword };
