import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import i18n from 'app-wrapper/i18n/i18n';

import {
  PermissionAttributePolicy,
  ShipmentBookingStatusEnum,
  ShipmentDrawerContent,
} from 'shipment-operations/constants';

import {
  AccessorialActiveCharge,
  AdditionalDocumentUpload,
  BillOfLadingEditClauses,
  BillOfLadingEditComments,
  BillOfLadingEditExportClearance,
  BillOfLadingEditPayments,
  EditBillOfLading,
  EditTradeAccounting,
  EditTradeReference,
  NewOrganizationDocumentUpload,
  OrganizationDocumentUpload,
  SIDocumentUpload,
  TransportationActiveCharges,
  TransportationLocationData,
} from './components';

import { ShipmentDrawerWrapper } from './ShipmentDrawer.styled';

interface IShipmentDrawerComponentProps {
  isVisible: boolean
  activeContent?: ShipmentDrawerContent
  onClose: () => void
  bookingStatus?: ShipmentBookingStatusEnum;
  permissions?: PermissionAttributePolicy;
  isRequestTheSame?: boolean;
  isMaersk?: boolean;
  partnerWarning?: boolean;
}

type ITitleList = {
  [key in ShipmentDrawerContent]: string
}

const TITLES_LIST: ITitleList = {
  [ShipmentDrawerContent.SHIPMENT_TRANSPORTATION_ADD_CHARGE]: i18n.t('addCharge'),
  [ShipmentDrawerContent.SHIPMENT_TRANSPORTATION_EDIT_CHARGE]: i18n.t('editCharge'),
  [ShipmentDrawerContent.SHIPMENT_TRANSPORTATION_LOCATION_DESTINATION_CONTAINER_TERMINAL_OPERATOR]: i18n.t('Empty Container Drop-off'),
  [ShipmentDrawerContent.SHIPMENT_TRANSPORTATION_LOCATION_DESTINATION_CONTAINER_YARD]: i18n.t('Full Container Pick-up'),
  [ShipmentDrawerContent.SHIPMENT_TRANSPORTATION_LOCATION_ORIGIN_CONTAINER_TERMINAL_OPERATOR]: i18n.t('Full Container Drop-off'),
  [ShipmentDrawerContent.SHIPMENT_TRANSPORTATION_LOCATION_ORIGIN_CONTAINER_YARD]: i18n.t('Empty Container Pick-up'),
  [ShipmentDrawerContent.SHIPMENT_BILL_OF_LADING_EDIT_PAYMENTS]: i18n.t('Payments'),
  [ShipmentDrawerContent.SHIPMENT_BILL_OF_LADING_EDIT_CLAUSES]: i18n.t('Clauses'),
  [ShipmentDrawerContent.SHIPMENT_BILL_OF_LADING_EDIT_COMMENTS]: i18n.t('BL Comments'),
  [ShipmentDrawerContent.SHIPMENT_ACCESSORIAL_ADD_CHARGE]: i18n.t('addCharge'),
  [ShipmentDrawerContent.SHIPMENT_ACCESSORIAL_EDIT_CHARGE]: i18n.t('editCharge'),
  [ShipmentDrawerContent.SHIPMENT_SI_DRAFT_DOCUMENT_UPLOAD]: i18n.t('Submit Draft Bill of Lading'),
  [ShipmentDrawerContent.SHIPMENT_SI_FINAL_DOCUMENT_UPLOAD]: i18n.t('Submit Final Bill of Lading'),
  [ShipmentDrawerContent.SHIPMENT_ADDITIONAL_DOCUMENT_UPLOAD]: i18n.t('Upload Document'),
  [ShipmentDrawerContent.ORGANIZATION_DOCUMENT_UPLOAD]: i18n.t('Upload Document'),
  [ShipmentDrawerContent.NEW_ORGANIZATION_DOCUMENT_UPLOAD]: i18n.t('Upload Document'),
  [ShipmentDrawerContent.EDIT_BILL_OF_LADING]: i18n.t('BL Release Type'),
  [ShipmentDrawerContent.SHIPMENT_BILL_OF_LADING_EDIT_EXPORT_CLEARANCE]: i18n.t('Export Entry Number'),
  [ShipmentDrawerContent.EDIT_TRADE_REFERENCE]: i18n.t('Trade Reference'),
  [ShipmentDrawerContent.EDIT_TRADE_ACCOUNTING]: i18n.t('Edit Credit Information'),
  [ShipmentDrawerContent.SHIPMENT_CHANGES]: '',
  [ShipmentDrawerContent.SHIPMENT_MISMATCHES]: i18n.t('Review Shipment Amendments'),
};

const width: ITitleList = {
  [ShipmentDrawerContent.SHIPMENT_TRANSPORTATION_ADD_CHARGE]: '378px',
  [ShipmentDrawerContent.SHIPMENT_TRANSPORTATION_EDIT_CHARGE]: '378px',
  [ShipmentDrawerContent.SHIPMENT_TRANSPORTATION_LOCATION_DESTINATION_CONTAINER_TERMINAL_OPERATOR]: '378px',
  [ShipmentDrawerContent.SHIPMENT_TRANSPORTATION_LOCATION_DESTINATION_CONTAINER_YARD]: '378px',
  [ShipmentDrawerContent.SHIPMENT_TRANSPORTATION_LOCATION_ORIGIN_CONTAINER_TERMINAL_OPERATOR]: '378px',
  [ShipmentDrawerContent.SHIPMENT_TRANSPORTATION_LOCATION_ORIGIN_CONTAINER_YARD]: '378px',
  [ShipmentDrawerContent.SHIPMENT_BILL_OF_LADING_EDIT_PAYMENTS]: '378px',
  [ShipmentDrawerContent.SHIPMENT_BILL_OF_LADING_EDIT_CLAUSES]: '378px',
  [ShipmentDrawerContent.SHIPMENT_BILL_OF_LADING_EDIT_COMMENTS]: '378px',
  [ShipmentDrawerContent.SHIPMENT_ACCESSORIAL_ADD_CHARGE]: '378px',
  [ShipmentDrawerContent.SHIPMENT_ACCESSORIAL_EDIT_CHARGE]: '378px',
  [ShipmentDrawerContent.SHIPMENT_SI_DRAFT_DOCUMENT_UPLOAD]: '378px',
  [ShipmentDrawerContent.SHIPMENT_SI_FINAL_DOCUMENT_UPLOAD]: '378px',
  [ShipmentDrawerContent.SHIPMENT_ADDITIONAL_DOCUMENT_UPLOAD]: '378px',
  [ShipmentDrawerContent.ORGANIZATION_DOCUMENT_UPLOAD]: '378px',
  [ShipmentDrawerContent.NEW_ORGANIZATION_DOCUMENT_UPLOAD]: '378px',
  [ShipmentDrawerContent.EDIT_BILL_OF_LADING]: '600px',
  [ShipmentDrawerContent.SHIPMENT_BILL_OF_LADING_EDIT_EXPORT_CLEARANCE]: '378px',
  [ShipmentDrawerContent.EDIT_TRADE_REFERENCE]: '378px',
  [ShipmentDrawerContent.EDIT_TRADE_ACCOUNTING]: '378px',
  [ShipmentDrawerContent.SHIPMENT_CHANGES]: '1137px',
  [ShipmentDrawerContent.SHIPMENT_MISMATCHES]: '1137px',
};

const withClosable: ShipmentDrawerContent[] = [
  ShipmentDrawerContent.SHIPMENT_CHANGES,
  ShipmentDrawerContent.SHIPMENT_MISMATCHES,
];

const CONFIRMED_STATUSES = [
  ShipmentBookingStatusEnum.BOOKING_CONFIRMED,
  ShipmentBookingStatusEnum.BOOKING_AMENDMENT_CONFIRMED,
];

export const ShipmentDrawerComponent: FC<IShipmentDrawerComponentProps> = ({
  isVisible,
  activeContent,
  onClose,
  bookingStatus,
  permissions,
  isRequestTheSame,
  isMaersk,
  partnerWarning,
}) => {
  const { t } = useTranslation();
  const selectedWidth = activeContent ? width[activeContent] : undefined;
  const isClosable = activeContent ? withClosable.includes(activeContent) : false;

  const title = useMemo(() => {
    if (activeContent === ShipmentDrawerContent.SHIPMENT_CHANGES) {
      if (permissions === PermissionAttributePolicy.READ) {
        if (bookingStatus && CONFIRMED_STATUSES.includes(bookingStatus) && !isRequestTheSame) {
          return t('Submit Shipment Amendments');
        }
        if (bookingStatus && bookingStatus === ShipmentBookingStatusEnum.BOOKING_AMENDMENT_SUBMITTED) {
          return t('Amendments Waiting Confirmation');
        }
      }
      if (isMaersk && bookingStatus && (bookingStatus === ShipmentBookingStatusEnum.BOOKING_SUBMITTED || bookingStatus === ShipmentBookingStatusEnum.BOOKING_AMENDMENT_SUBMITTED)) {
        return t('Manage Request Manually');
      }
      if (partnerWarning) {
        return t('Review Carrier Response and Confirm');
      }
    }
    return activeContent ? TITLES_LIST[activeContent] : undefined;
  }, [activeContent]);

  return (
    <ShipmentDrawerWrapper
      title={title}
      visible={isVisible}
      width={selectedWidth}
      closable={isClosable}
      maskClosable={false}
      onClose={onClose}
    >
      {
        (
          activeContent === ShipmentDrawerContent.SHIPMENT_TRANSPORTATION_LOCATION_ORIGIN_CONTAINER_YARD
          || activeContent === ShipmentDrawerContent.SHIPMENT_TRANSPORTATION_LOCATION_ORIGIN_CONTAINER_TERMINAL_OPERATOR
          || activeContent === ShipmentDrawerContent.SHIPMENT_TRANSPORTATION_LOCATION_DESTINATION_CONTAINER_YARD
          || activeContent === ShipmentDrawerContent.SHIPMENT_TRANSPORTATION_LOCATION_DESTINATION_CONTAINER_TERMINAL_OPERATOR
        ) && (
          <TransportationLocationData />
        )
      }
      {
        activeContent === ShipmentDrawerContent.SHIPMENT_TRANSPORTATION_ADD_CHARGE && (
          <TransportationActiveCharges />
        )
      }
      {
        activeContent === ShipmentDrawerContent.SHIPMENT_TRANSPORTATION_EDIT_CHARGE && (
          <TransportationActiveCharges />
        )
      }
      {activeContent === ShipmentDrawerContent.SHIPMENT_BILL_OF_LADING_EDIT_PAYMENTS && (
        <BillOfLadingEditPayments />
      )}
      {activeContent === ShipmentDrawerContent.SHIPMENT_BILL_OF_LADING_EDIT_CLAUSES && (
        <BillOfLadingEditClauses />
      )}
      {activeContent === ShipmentDrawerContent.SHIPMENT_BILL_OF_LADING_EDIT_COMMENTS && (
        <BillOfLadingEditComments />
      )}
      {activeContent === ShipmentDrawerContent.SHIPMENT_ACCESSORIAL_ADD_CHARGE && (
        <AccessorialActiveCharge />
      )}
      {activeContent === ShipmentDrawerContent.SHIPMENT_SI_DRAFT_DOCUMENT_UPLOAD && (
        <SIDocumentUpload />
      )}
      {activeContent === ShipmentDrawerContent.SHIPMENT_SI_FINAL_DOCUMENT_UPLOAD && (
        <SIDocumentUpload />
      )}
      {activeContent === ShipmentDrawerContent.SHIPMENT_ACCESSORIAL_EDIT_CHARGE && (
        <AccessorialActiveCharge />
      )}
      {activeContent === ShipmentDrawerContent.SHIPMENT_ADDITIONAL_DOCUMENT_UPLOAD && (
        <AdditionalDocumentUpload />
      )}
      {activeContent === ShipmentDrawerContent.ORGANIZATION_DOCUMENT_UPLOAD && (
        <OrganizationDocumentUpload />
      )}
      {activeContent === ShipmentDrawerContent.NEW_ORGANIZATION_DOCUMENT_UPLOAD && (
        <NewOrganizationDocumentUpload />
      )}
      {activeContent === ShipmentDrawerContent.EDIT_BILL_OF_LADING && (
        <EditBillOfLading />
      )}
      {activeContent === ShipmentDrawerContent.SHIPMENT_BILL_OF_LADING_EDIT_EXPORT_CLEARANCE && (
        <BillOfLadingEditExportClearance />
      )}
      {activeContent === ShipmentDrawerContent.EDIT_TRADE_REFERENCE && (
        <EditTradeReference />
      )}
      {activeContent === ShipmentDrawerContent.EDIT_TRADE_ACCOUNTING && (
        <EditTradeAccounting />
      )}
    </ShipmentDrawerWrapper>
  );
};
