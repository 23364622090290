import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { OrganizationCoverageAreasDTM } from 'user-management/models/dtm';

import countries from 'app-wrapper/repository/store/CountriesList/countries.json';
import {
  CIFinancialAndLegalHeader,
  CIFinancialAndLegalRow, CIFinancialAndLegalWrapper,
} from 'user-management/view/pages/CustomersInternal/components/CIFinancialAndLegal/CIFinancialAndLegal.styled';
import { Label, LabelsRow } from 'app-wrapper/view/guideline';

interface IUserDataComponentProps {
  coverageAreas?: OrganizationCoverageAreasDTM[]
}

export const OrganizationCoverageAreasComponent: FC<IUserDataComponentProps> = ({
  coverageAreas,
}) => {
  const { t } = useTranslation();
  return (
    <CIFinancialAndLegalWrapper>
      <CIFinancialAndLegalHeader>{t('Coverage Area')}</CIFinancialAndLegalHeader>
      <CIFinancialAndLegalRow>
        <LabelsRow>
          {(coverageAreas || []).map((coverageArea) => (
            <Label key={coverageArea.countryCode}>
              {coverageArea?.countryCode ? countries[coverageArea.countryCode as keyof typeof countries] : null}
            </Label>
          ))}
        </LabelsRow>
      </CIFinancialAndLegalRow>
    </CIFinancialAndLegalWrapper>
  );
};
