export enum ShipmentDrawerContent {
  SHIPMENT_TRANSPORTATION_LOCATION_ORIGIN_CONTAINER_YARD = 'SHIPMENT_TRANSPORTATION_LOCATION_ORIGIN_CONTAINER_YARD',
  SHIPMENT_TRANSPORTATION_LOCATION_ORIGIN_CONTAINER_TERMINAL_OPERATOR = 'SHIPMENT_TRANSPORTATION_LOCATION_ORIGIN_CONTAINER_TERMINAL_OPERATOR',
  SHIPMENT_TRANSPORTATION_LOCATION_DESTINATION_CONTAINER_YARD = 'SHIPMENT_TRANSPORTATION_LOCATION_DESTINATION_CONTAINER_YARD',
  SHIPMENT_TRANSPORTATION_LOCATION_DESTINATION_CONTAINER_TERMINAL_OPERATOR = 'SHIPMENT_TRANSPORTATION_LOCATION_DESTINATION_CONTAINER_TERMINAL_OPERATOR',
  SHIPMENT_TRANSPORTATION_ADD_CHARGE = 'SHIPMENT_TRANSPORTATION_ADD_CHARGE',
  SHIPMENT_TRANSPORTATION_EDIT_CHARGE = 'SHIPMENT_TRANSPORTATION_EDIT_CHARGE',
  SHIPMENT_BILL_OF_LADING_EDIT_PAYMENTS = 'SHIPMENT_BILL_OF_LADING_EDIT_PAYMENTS',
  SHIPMENT_BILL_OF_LADING_EDIT_CLAUSES = 'SHIPMENT_BILL_OF_LADING_EDIT_CLAUSES',
  SHIPMENT_BILL_OF_LADING_EDIT_COMMENTS = 'SHIPMENT_BILL_OF_LADING_EDIT_COMMENTS',
  SHIPMENT_ACCESSORIAL_ADD_CHARGE = 'SHIPMENT_ACCESSORIAL_ADD_CHARGE',
  SHIPMENT_ACCESSORIAL_EDIT_CHARGE = 'SHIPMENT_ACCESSORIAL_EDIT_CHARGE',
  SHIPMENT_SI_DRAFT_DOCUMENT_UPLOAD = 'SHIPMENT_SI_DRAFT_DOCUMENT_UPLOAD',
  SHIPMENT_SI_FINAL_DOCUMENT_UPLOAD = 'SHIPMENT_SI_FINAL_DOCUMENT_UPLOAD',
  SHIPMENT_ADDITIONAL_DOCUMENT_UPLOAD = 'SHIPMENT_ADDITIONAL_DOCUMENT_UPLOAD',
  ORGANIZATION_DOCUMENT_UPLOAD = 'ORGANIZATION_DOCUMENT_UPLOAD',
  NEW_ORGANIZATION_DOCUMENT_UPLOAD = 'NEW_ORGANIZATION_DOCUMENT_UPLOAD',
  EDIT_BILL_OF_LADING = 'EDIT_BILL_OF_LADING',
  SHIPMENT_BILL_OF_LADING_EDIT_EXPORT_CLEARANCE = 'SHIPMENT_BILL_OF_LADING_EDIT_EXPORT_CLEARANCE',
  EDIT_TRADE_REFERENCE = 'EDIT_TRADE_REFERENCE',
  EDIT_TRADE_ACCOUNTING = 'EDIT_TRADE_ACCOUNTING',
  SHIPMENT_CHANGES = 'SHIPMENT_CHANGES',
  SHIPMENT_MISMATCHES = 'SHIPMENT_MISMATCHES',
}
