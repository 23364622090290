import React, {
  FC, memo, useEffect, useRef,
} from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';

import { TableSkeleton } from 'app-wrapper/view/components';
import { REQUEST_STATUS } from 'app-wrapper/constants';
import { CommandCenterComponentTaskDTM } from 'app-wrapper/models/dtm';

import {
  CCContentTable,
  CCContentTableContent,
  CCContentTableHeader,
  CCContentTableHeaderItem,
} from './ContentTableTasks.styled';
import { ContentTableTasksSectionComponent } from './Components';

interface IContentTableTasksProps {
  onStart: () => void;
  nextPageTasks?: () => void;
  checkAnotherDownloadNextPageTask: (parentHeight: number, elementHeight: number) => void;
  openAgreementsDrawer?: (shipmentId: string) => void;
  openVGMDrawer?: (shipmentId: string) => void;
  openRailBillingDrawer?: (shipmentId: string) => void;
  openShipmentCostChangesDrawer?: (taskId: string) => void;
  isCompleted?: boolean;
  isLoad?: string;
  isLoadAdd?: string;
  overdueTasks?: CommandCenterComponentTaskDTM[];
  todayTasks?: CommandCenterComponentTaskDTM[];
  thisWeekTasks?: CommandCenterComponentTaskDTM[];
  allTasks?: CommandCenterComponentTaskDTM[];
  laterTasks?: CommandCenterComponentTaskDTM[];
  countTasksOverdue?: number;
  countTasksToday?: number;
  countTasksThisWeek?: number;
  countTasksLater?: number;
  countTasks?: number;
  infiniteScrollId: string
  titleNameFirstOverdue?: string;
  titleNameFirstColumn?: string;
  openMaerskDrawer?: () => void;
  setShipmentId?: (shipmentId: string) => void;
  openMismatchDrawer?: () => void;
  openChangesDrawer?: (withValidate?: boolean) => void;
  openBookingConfirmationDrawer?: () => void;
}

interface IRefParent extends HTMLDivElement {
  el?: {
    clientHeight?: number;
  }
}
interface IRefScroll {
  el?: {
    clientHeight?: number;
  }
}

const ContentTableTasks: FC<IContentTableTasksProps> = (props) => {
  const {
    onStart,
    nextPageTasks,
    checkAnotherDownloadNextPageTask,
    openAgreementsDrawer,
    openVGMDrawer,
    openRailBillingDrawer,
    isCompleted,
    isLoad,
    isLoadAdd,
    overdueTasks,
    todayTasks,
    thisWeekTasks,
    allTasks,
    laterTasks,
    countTasksOverdue,
    countTasksToday,
    countTasksThisWeek,
    countTasksLater,
    countTasks,
    infiniteScrollId,
    titleNameFirstOverdue,
    titleNameFirstColumn,
    openMaerskDrawer,
    setShipmentId,
    openMismatchDrawer,
    openChangesDrawer,
    openBookingConfirmationDrawer,
    openShipmentCostChangesDrawer,
  } = props;

  const { t } = useTranslation();

  useEffect(() => {
    onStart();
  }, []);

  // Download next page on large table
  const refParent = useRef<IRefParent | null>(null);
  const refScroll = useRef<InfiniteScroll | null>(null);

  useEffect(() => {
    const scroll = refScroll?.current ? refScroll.current as unknown as IRefScroll : undefined;
    const currentHeight = refParent?.current?.clientHeight || 0;

    checkAnotherDownloadNextPageTask(currentHeight, scroll?.el?.clientHeight || 0);
  }, [allTasks?.length, refParent.current, refScroll?.current]);

  return (
    <CCContentTable ref={refParent} isCompleted={isCompleted}>
      <CCContentTableHeader>
        <CCContentTableHeaderItem
          width="41%"
        >
          {titleNameFirstColumn || t('Task')}
        </CCContentTableHeaderItem>
        <CCContentTableHeaderItem
          width="14.5%"
        >
          {t('dueDate')}
        </CCContentTableHeaderItem>
        {isCompleted ? (
          <CCContentTableHeaderItem
            width="14.5%"
          >
            {t('Completion Date')}
          </CCContentTableHeaderItem>
        ) : (
          <CCContentTableHeaderItem
            width="14.5%"
          >
            {t('object')}
          </CCContentTableHeaderItem>
        )}
        <CCContentTableHeaderItem
          width="16%"
        >
          {t('module')}
        </CCContentTableHeaderItem>
        <CCContentTableHeaderItem
          width="7%"
          isCenter
        >
          {t('assignee')}
        </CCContentTableHeaderItem>
        <CCContentTableHeaderItem
          width="7%"
          isCenter
        >
          {t('action')}
        </CCContentTableHeaderItem>
      </CCContentTableHeader>

      <CCContentTableContent>

        <ContentTableTasksSectionComponent
          nameSection={titleNameFirstOverdue || t('Overdue')}
          isLoad={isLoad === REQUEST_STATUS.pending}
          dataTasks={overdueTasks}
          countTasks={countTasksOverdue}
          openAgreementsDrawer={openAgreementsDrawer}
          openVGMDrawer={openVGMDrawer}
          openRailBillingDrawer={openRailBillingDrawer}
          openShipmentCostChangesDrawer={openShipmentCostChangesDrawer}
          openMaerskDrawer={openMaerskDrawer}
          setShipmentId={setShipmentId}
          openMismatchDrawer={openMismatchDrawer}
          openChangesDrawer={openChangesDrawer}
          openBookingConfirmationDrawer={openBookingConfirmationDrawer}
        />

        <ContentTableTasksSectionComponent
          nameSection={t('Today')}
          isLoad={isLoad === REQUEST_STATUS.pending}
          dataTasks={todayTasks}
          countTasks={countTasksToday}
          openAgreementsDrawer={openAgreementsDrawer}
          openVGMDrawer={openVGMDrawer}
          openRailBillingDrawer={openRailBillingDrawer}
          openShipmentCostChangesDrawer={openShipmentCostChangesDrawer}
          openMaerskDrawer={openMaerskDrawer}
          setShipmentId={setShipmentId}
          openMismatchDrawer={openMismatchDrawer}
          openChangesDrawer={openChangesDrawer}
          openBookingConfirmationDrawer={openBookingConfirmationDrawer}
        />

        <ContentTableTasksSectionComponent
          nameSection={t('Next 7 Days')}
          isLoad={isLoad === REQUEST_STATUS.pending}
          dataTasks={thisWeekTasks}
          countTasks={countTasksThisWeek}
          openAgreementsDrawer={openAgreementsDrawer}
          openShipmentCostChangesDrawer={openShipmentCostChangesDrawer}
          openVGMDrawer={openVGMDrawer}
          openRailBillingDrawer={openRailBillingDrawer}
          openMaerskDrawer={openMaerskDrawer}
          setShipmentId={setShipmentId}
          openMismatchDrawer={openMismatchDrawer}
          openChangesDrawer={openChangesDrawer}
          openBookingConfirmationDrawer={openBookingConfirmationDrawer}
        />

        <ContentTableTasksSectionComponent
          nameSection={t('Later')}
          isLoad={isLoad === REQUEST_STATUS.pending}
          dataTasks={laterTasks}
          countTasks={countTasksLater}
          openAgreementsDrawer={openAgreementsDrawer}
          openShipmentCostChangesDrawer={openShipmentCostChangesDrawer}
          openVGMDrawer={openVGMDrawer}
          openRailBillingDrawer={openRailBillingDrawer}
          openMaerskDrawer={openMaerskDrawer}
          setShipmentId={setShipmentId}
          openMismatchDrawer={openMismatchDrawer}
          openChangesDrawer={openChangesDrawer}
          openBookingConfirmationDrawer={openBookingConfirmationDrawer}
        />

        {nextPageTasks && (
          <InfiniteScroll
            ref={refScroll}
            dataLength={Number(allTasks?.length)}
            next={nextPageTasks}
            hasMore
            loader={
              isLoad === REQUEST_STATUS.pending
              && (<TableSkeleton />)
            }
            scrollableTarget={infiniteScrollId}
          >

            <ContentTableTasksSectionComponent
              isCompleted
              hideExtend
              isLoad={isLoad === REQUEST_STATUS.pending}
              dataTasks={allTasks}
              countTasks={countTasks}
              openAgreementsDrawer={openAgreementsDrawer}
              openShipmentCostChangesDrawer={openShipmentCostChangesDrawer}
              openVGMDrawer={openVGMDrawer}
            />
          </InfiniteScroll>
        )}

        {isLoadAdd === REQUEST_STATUS.pending && (<TableSkeleton />)}
      </CCContentTableContent>

    </CCContentTable>
  );
};

const ContentTableTasksCache = memo(ContentTableTasks);

export { ContentTableTasksCache as ContentTableTasks };
