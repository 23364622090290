import styled from 'styled-components';

export const StyledSign = styled.span<{fontSize?: number}>`
  position: relative;
  top: 0;
  right: 0;
  background: #F4F5F8;
  border: 1px solid #EAEEF8;
  padding: 2px;
  border-radius: 4px;
  font-size: ${({ fontSize }) => fontSize || '0.45'}rem;
  font-weight: 600;
`;

export const TableRow = styled.div`
  display: flex;
  border-bottom: 0.5px dashed #D1D7E2;
  font-size: 0.7rem;
  padding: 5px 0 5px 0;
`;

export const Cell = styled.div<{justifyContent?: string; flex?: number}>`
  display: flex;
  align-items: center;
  justify-content: ${({ justifyContent }) => justifyContent};
  align-items: flex-start;
  flex: ${({ flex = 1 }) => flex};
  flex-wrap: wrap;
  gap: 4px 4px;
  text-transform: capitalize;
`;
