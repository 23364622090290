import React from 'react';
import { useSelector } from 'react-redux';

import { UC } from 'shipment-operations/controllers';
import { R } from 'shipment-operations/repository';

import { FooterComponent } from './Footer.component';

const FooterContainer = () => {
  const IsNRAConfirmed = useSelector(R.selectors.overview.getIsNRAConfirmed);
  const IsTermsAndConditionsConfirmed = useSelector(R.selectors.overview.getIsTermsAndConditionsConfirmed);
  const isUsShipmentOriginOrDestination = Boolean(useSelector(R.selectors.bookingWizard.getShipment)?.isUsShipmentOriginOrDestination());

  const isAbleToAccept = IsTermsAndConditionsConfirmed && ((isUsShipmentOriginOrDestination && IsNRAConfirmed) || !isUsShipmentOriginOrDestination);

  return (
    <FooterComponent
      isLoading={false}
      isAbleToAccept={isAbleToAccept}
      acceptSecondStep={UC.overview.acceptCustomerBookingAgreements}
      closeDrawer={UC.overview.closeTermsAgreementDrawer}
    />
  );
};

export { FooterContainer as Footer };
