import React, { useMemo } from 'react';
import { FieldValues } from 'react-hook-form/dist/types/index.d';

import { Switch } from 'app-wrapper/view/components';

import omit from 'lodash/fp/omit';
import {
  TypeSwitchControllerText,
  TypeSwitchControllerWithText,
  TypeSwitchController,
  TypeSwitchControllerTextInactive,
} from './TypeSwitch.styled';

interface ITypeSwitch extends FieldValues {
  leftText?: string
  rightText?: string
  disabledbg?: string
  value: boolean
  invertActive?: boolean
  'data-class'?: string
  checkedChildren?: string
  disabled?: boolean
  onChange?: (isPrimary: boolean) => void
}

export const TypeSwitch = (
  props: ITypeSwitch,
) => {
  const {
    className,
    leftText,
    rightText,
    disabledbg,
    checkedChildren,
    unCheckedChildren,
    value,
    invertActive,
    'data-class': dataClasses,
  } = props;

  const leftContent = useMemo(() => {
    if (leftText) {
      if (rightText) {
        return value ? (
          <TypeSwitchControllerTextInactive isInvert={invertActive} className="controller-text-left">
            {leftText}
          </TypeSwitchControllerTextInactive>
        ) : (
          <TypeSwitchControllerText isInvert={invertActive} className="controller-text-left">
            {leftText}
          </TypeSwitchControllerText>
        );
      }

      return value ? (
        <TypeSwitchControllerText isInvert={invertActive} className="controller-text-left">
          {leftText}
        </TypeSwitchControllerText>
      ) : (
        <TypeSwitchControllerTextInactive isInvert={invertActive} className="controller-text-left">
          {leftText}
        </TypeSwitchControllerTextInactive>
      );
    }

    return null;
  }, [leftText, value, rightText, invertActive]);

  const rightContent = useMemo(() => {
    if (rightText) {
      return value ? (
        <TypeSwitchControllerText isInvert={invertActive} className="controller-text-right">
          {rightText}
        </TypeSwitchControllerText>
      ) : (
        <TypeSwitchControllerTextInactive isInvert={invertActive} className="controller-text-right">
          {rightText}
        </TypeSwitchControllerTextInactive>
      );
    }

    return null;
  }, [rightText, value, invertActive]);

  const switchComponent = useMemo(() => (
    <Switch
      className={className || 'Switch'}
      checked={value}
      disabled={!disabledbg}
      {...omit(['leftText', 'rightText', 'disabledbg'], props)}
    />
  ), [className, value, disabledbg, props]);

  return checkedChildren || unCheckedChildren ? (
    <TypeSwitchControllerWithText data-class={dataClasses}>
      {leftContent}
      {switchComponent}
      {rightContent}
    </TypeSwitchControllerWithText>
  ) : (
    <TypeSwitchController data-class={dataClasses}>
      {leftContent}
      {switchComponent}
      {rightContent}
    </TypeSwitchController>
  );
};
