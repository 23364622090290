import i18n from 'app-wrapper/i18n/i18n';

export enum EWorkgroupTypesEnum {
  CUSTOMER_REPRESENTATIVES = 'CUSTOMER_REPRESENTATIVES',
  EXPORT = 'EXPORT',
  IMPORT = 'IMPORT',
  SKYPACE_TEAM = 'SKYPACE_TEAM',
  SKYPACE_REPRESENTATIVES = 'SKYPACE_REPRESENTATIVES',
  ACCOUNT_HOLDER_ORGANIZATION = 'ACCOUNT_HOLDER_ORGANIZATION',
}

export const WORKGROUP_ROLES: Record<EWorkgroupTypesEnum, string> = {
  [EWorkgroupTypesEnum.CUSTOMER_REPRESENTATIVES]: i18n.t('Customer'),
  [EWorkgroupTypesEnum.EXPORT]: i18n.t('Export Agent'),
  [EWorkgroupTypesEnum.IMPORT]: i18n.t('Import Agent'),
  [EWorkgroupTypesEnum.SKYPACE_TEAM]: i18n.t('Regional Office'),
  [EWorkgroupTypesEnum.SKYPACE_REPRESENTATIVES]: i18n.t('Regional Office'),
  [EWorkgroupTypesEnum.ACCOUNT_HOLDER_ORGANIZATION]: i18n.t(''),
};
