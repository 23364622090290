import React, { memo, useMemo } from 'react';
import { SkeletonRow } from 'app-wrapper/view/components/Skeleton/Skeleton.component';
import { Table, TableCell } from 'app-wrapper/view/guideline/Table';
import { ColumnType } from 'antd/es/table';

export const TableCellSkeleton = () => <SkeletonRow active height="16px" />;

interface ITableSkeletonProps {
  columnCount?: number;
}
export const TableSkeleton = memo(({ columnCount = 5 }: ITableSkeletonProps) => {
  const elements = useMemo(() => Array(columnCount).fill(undefined).map((_) => ({
    column1: '',
    column2: '',
    column3: '',
    column4: '',
    column5: '',
    column6: '',
  })), []);

  const columns: ColumnType<object>[] = useMemo(() => Array(columnCount).fill(undefined).map((_, index) => ({
    title: <SkeletonRow active height="16px" />,
    key: `column${index}`,
    align: 'left' as 'left',
    render: () => (
      <TableCell>
        <SkeletonRow active height="16px" />
      </TableCell>
    ),
  })), []);

  return (
    <Table
      pagination={false}
      dataSource={elements}
      columns={columns}
    />
  );
});
