import styled from 'styled-components';

export const Field = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
`;

export const Label = styled.span`
  font-size: 12px;
  line-height: 20px;
  margin-bottom: 4px;
`;
