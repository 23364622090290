import styled from 'styled-components';

export const ReferenceCardItem = styled.div.attrs((props: {
  marginLeft: string
  'data-class'?: string
}) => ({
  marginLeft: props.marginLeft,
  'data-class': props?.['data-class'] || 'ReferenceCardItem',
}))`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;

  ${({ marginLeft }) => marginLeft && `margin-left: ${marginLeft}`};
`;
