import React from 'react';
import { useSelector } from 'react-redux';

import { UC } from 'shipment-operations/controllers';
import { R } from 'shipment-operations/repository';

import { ThirdStepComponent } from 'shipment-operations/view/drawers/MaerskChanges/components/ThirdStep/ThirdStep.component';

const ThirdStepContainer = () => {
  const cargoTable = useSelector(R.selectors.shipmentChanges.getCargoTable);
  const containersTable = useSelector(R.selectors.shipmentChanges.getContainersTable);
  const transportPlanMismatchCurrent = useSelector(R.selectors.shipmentChanges.getTransportPlanMismatchCurrent);
  const transportPlanMismatchPrevious = useSelector(R.selectors.shipmentChanges.getTransportPlanMismatchPrevious);
  const loading = useSelector(R.selectors.shipmentChanges.getLoading);
  const cutOffTable = useSelector(R.selectors.shipmentChanges.getCutOffTable);
  const locationsTable = useSelector(R.selectors.shipmentChanges.getLocationsTable);
  const inttraNumber = useSelector(R.selectors.shipmentChanges.getInttraNumber);
  const carrierNumber = useSelector(R.selectors.shipmentChanges.getCarrierNumber);
  const shipmentId = useSelector(R.selectors.overview.getShipmentId);
  const savedIntraNumber = useSelector(R.selectors.shipment.getOceanBookingId);
  const bookingStatus = useSelector(R.selectors.shipment.getBookingStatus);
  const updatedIntraNumber = useSelector(R.selectors.shipmentChanges.getShortShipmentOceanBookingId);
  const updatedBookingStatus = useSelector(R.selectors.shipmentChanges.getBookingStatusRequestedShipment);
  const showValidate = useSelector(R.selectors.shipmentChanges.getIsShowValidate);

  return (
    <ThirdStepComponent
      loadData={UC.shipmentChanges.loadData}
      onChangeLocation={UC.shipmentChanges.onChangeLocation}
      onChangeCutOff={UC.shipmentChanges.onChangeCutOff}
      cargoTable={cargoTable}
      containersTable={containersTable}
      transportPlanCurrent={transportPlanMismatchCurrent}
      loading={loading}
      transportPlanPrevious={transportPlanMismatchPrevious}
      cutOffTable={cutOffTable}
      locationsTable={locationsTable}
      inttraNumber={inttraNumber}
      carrierNumber={carrierNumber}
      savedShipmentId={shipmentId}
      syncIntraNumber={UC.shipmentChanges.syncIntraNumber}
      savedIntraNumber={savedIntraNumber}
      bookingStatus={bookingStatus}
      updatedIntraNumber={updatedIntraNumber}
      updatedBookingStatus={updatedBookingStatus}
      showValidate={showValidate}
    />
  );
};

export { ThirdStepContainer as ThirdStep };
