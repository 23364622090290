import React from 'react';
import { useSelector } from 'react-redux';

import { UC } from 'shipment-operations/controllers';
import { R } from 'shipment-operations/repository';

import { AddWorkgroupMemberComponent } from './AddWorkgroupMember.component';

export const AddWorkgroupMember = () => {
  const isOpened = useSelector(R.selectors.shipmentPeople.getIsDrawerOpened);
  const isLoading = useSelector(R.selectors.shipmentPeople.getIsDrawerLoading);
  const drawerMembers = useSelector(R.selectors.shipmentPeople.getDrawerMembers);
  const tempMember = useSelector(R.selectors.shipmentPeople.getTempMember);
  const isCustomerView = useSelector(R.selectors.shipmentPeople.getIsCustomerView);
  const rolesToEdit = useSelector(R.selectors.shipmentPeople.getRolesToEdit);
  const rolesToEmailsState = useSelector(R.selectors.shipmentPeople.getRolesToEmailsState);

  return (
    <AddWorkgroupMemberComponent
      isVisible={isOpened}
      onClose={UC.shipmentPeople.closeAddMemberDrawer}
      isLoading={isLoading}
      members={drawerMembers}
      tempMember={tempMember}
      isCustomerView={isCustomerView}
      setTempMember={UC.shipmentPeople.setTempMember}
      rolesToEdit={rolesToEdit}
      rolesToEmailsState={rolesToEmailsState}
      setEmailByRole={UC.shipmentPeople.setRoleMember}
    />
  );
};
