import { createSelector } from 'reselect';
import { RootState } from 'app-wrapper/store';
import uniqBy from 'lodash/fp/uniqBy';
import groupBy from 'lodash/fp/groupBy';
import filter from 'lodash/fp/filter';
import includes from 'lodash/fp/includes';
import isEmpty from 'lodash/fp/isEmpty';
import partition from 'lodash/fp/partition';

import {
  priceByBol, occurrenceAdditional, priceByContainer, typeFee, subjectToIncluded, CREATE_AP_INVOICE,
} from 'shipment-operations/constants';

import { calculateChargesSum } from 'shipment-operations/view/pages/ShipmentCharges/utils/calculateSum';
import { ChargeDTM, ContainerViewDTM } from 'shipment-operations/models/dtm';

const parseContainers = (data: ChargeDTM[]) => {
  const filtered = data.filter((item) => item.container);
  if (filtered.length) {
    const prepared = filtered.map((item) => (item.container ? ({
      number: item.container.number,
      type: item.container.type,
      id: item.container.id,
    }) : null));
    return prepared.length ? prepared.filter((el) => el) as ContainerViewDTM[] : [];
  }
  return [];
};

const localState = (state: RootState) => state.createInvoiceCharges;

const getIsLoading = createSelector(
  localState,
  (state) => state.isLoading,
);

const getError = createSelector(
  localState,
  (state) => state.error,
);

const getCharges = createSelector(
  localState,
  (state) => state.charges,
);

const getIsOpenCreateInvoiceModal = createSelector(
  localState,
  (state) => state.isOpenCreateInvoiceModal,
);

const getSelectedCompanyId = createSelector(
  localState,
  (state) => state.selectedCompanyId,
);

const getPreparedCharges = createSelector(
  getCharges,
  (charges) => charges.filter((item) => item.applied && item.status !== 'INVOICED' && item.status !== 'OVER_INVOICED' && item.active),
);

const getMatchedCharges = createSelector(
  getPreparedCharges,
  getSelectedCompanyId,
  getIsOpenCreateInvoiceModal,
  (charges, companyId, owner) => {
    if (owner === CREATE_AP_INVOICE) {
      return charges.filter((item) => item.creditor?.id === companyId);
    }
    return charges.filter((item) => item.debtor?.id === companyId);
  },
);

const getCompanies = createSelector(
  localState,
  (state) => state.companies,
);

const getIsLoadingCompanies = createSelector(
  localState,
  (state) => state.isLoadingCompanies,
);

const getCreateInvoiceLoading = createSelector(
  localState,
  (state) => state.createInvoiceLoading,
);

const getCreateInvoiceSuccess = createSelector(
  localState,
  (state) => state.createInvoiceSuccess,
);

const getTypeOfCreatedInvoice = createSelector(
  localState,
  (state) => state.typeOfInvoice,
);

const getLoadCompaniesError = createSelector(
  localState,
  (state) => state.loadCompaniesError,
);

const getCreateInvoiceDueDate = createSelector(
  localState,
  (state) => state.dueDate,
);

const getCreatedInvoice = createSelector(
  localState,
  (state) => state.createdInvoice,
);

const getCompaniesForSelect = createSelector(
  getCompanies,
  (companies) => companies.map((item) => ({
    value: item.company?.id,
    label: item.company?.name,
  })),
);

const getSelectedCompany = createSelector(
  getCompanies,
  getSelectedCompanyId,
  (companies, id) => companies.find((item) => item.company?.id === id),
);

const getSelectedCompanyError = createSelector(
  localState,
  (state) => state.selectedCompanyError,
);

const getAdditionalData = createSelector(
  getMatchedCharges,
  (charges) => partition((item) => item.additional, charges),
);

const getServicesData = createSelector(
  getAdditionalData,
  ([, data]) => partition((item) => item.chargeCode.occurrence === occurrenceAdditional, data),
);

const getFeesData = createSelector(
  getServicesData,
  ([, data]) => partition((item) => (item.chargeCode.type === typeFee || item.priceBy === priceByBol), data),
);

const getTransportationData = createSelector(
  getFeesData,
  ([, data]) => data.filter((item) => item.subjectTo !== subjectToIncluded),
);

const getServicesContainersData = createSelector(
  getServicesData,
  ([charges]) => charges.filter((item) => item.priceBy === priceByContainer),
);

const getServicesDataRest = createSelector(
  getServicesData,
  ([charges]) => charges.filter((item) => item.priceBy !== priceByContainer),
);

const getIsEmptyData = createSelector(
  getTransportationData,
  getServicesData,
  getFeesData,
  getAdditionalData,
  (transportation, services, fees, additional) => isEmpty(transportation) && isEmpty(services) && isEmpty(fees) && isEmpty(additional),
);

const getAdditionalContainersData = createSelector(
  getAdditionalData,
  ([data]) => data.filter((item) => item.priceBy === priceByContainer),
);

const getAdditionalRestData = createSelector(
  getAdditionalData,
  ([data]) => data.filter((item) => item.priceBy !== priceByContainer),
);

const getTransportationDataSum = createSelector(
  getTransportationData,
  (data) => calculateChargesSum(data),
);

const getServicesDataSum = createSelector(
  getServicesData,
  ([data]) => calculateChargesSum(data),
);

const getFeesDataSum = createSelector(
  getFeesData,
  ([data]) => calculateChargesSum(data),
);

const getAdditionalDataSum = createSelector(
  getAdditionalData,
  ([data]) => calculateChargesSum(data),
);

const getPreparedServicesContainersData = createSelector(
  getServicesContainersData,
  (data) => parseContainers(data),
);

const getTransportationContainers = createSelector(
  getTransportationData,
  (data) => parseContainers(data),
);

const getAdditionalContainers = createSelector(
  getAdditionalContainersData,
  (data) => parseContainers(data),
);

const uniqTransportationContainers = createSelector(
  getTransportationContainers,
  (containers) => uniqBy('id', containers),
);

const uniqAdditionalContainers = createSelector(
  getAdditionalContainers,
  (containers) => uniqBy('id', containers),
);

const uniqServicesContainers = createSelector(
  getPreparedServicesContainersData,
  (containers) => uniqBy('id', containers),
);

const groupedTransportationContainers = createSelector(
  getTransportationData,
  (data) => groupBy((item) => item.container?.id, data),
);

const groupedAdditionalContainers = createSelector(
  getAdditionalContainersData,
  (data) => groupBy((item) => item.container?.id, data),
);

const groupedServicesContainers = createSelector(
  getServicesContainersData,
  (data) => groupBy((item) => item.container?.id, data),
);

const getSelectedCharges = createSelector(
  localState,
  (state) => state.selectedCharges,
);

const getFullListSelectedCharges = createSelector(
  getCharges,
  getSelectedCharges,
  (charges, selected) => filter((item) => includes(item.id, selected), charges),
);

const getSumSelectedCharges = createSelector(
  getFullListSelectedCharges,
  (charges) => charges.reduce((acc, cur) => acc + (cur.totalCost || 0), 0),
);

const getReference = createSelector(
  localState,
  (state) => state.reference,
);

const getBilledDate = createSelector(
  localState,
  (state) => state.billedDate,
);

export const createInvoiceChargesSelectors = {
  getIsLoading,
  getTransportationData,
  getTransportationContainers,
  uniqTransportationContainers,
  groupedTransportationContainers,
  getTransportationDataSum,
  getServicesData,
  getServicesDataRest,
  getServicesContainersData,
  getServicesDataSum,
  getFeesData,
  getFeesDataSum,
  getAdditionalData,
  uniqAdditionalContainers,
  groupedAdditionalContainers,
  uniqServicesContainers,
  groupedServicesContainers,
  getAdditionalDataSum,
  getAdditionalRestData,
  getError,
  getSelectedCharges,
  getSumSelectedCharges,
  getCompaniesForSelect,
  getSelectedCompanyId,
  getSelectedCompany,
  getCreateInvoiceLoading,
  getCreateInvoiceSuccess,
  getCreateInvoiceDueDate,
  getLoadCompaniesError,
  getIsLoadingCompanies,
  getCreatedInvoice,
  getCompanies,
  getSelectedCompanyError,
  getIsEmptyData,
  getIsOpenCreateInvoiceModal,
  getTypeOfCreatedInvoice,
  getReference,
  getBilledDate,
  getMatchedCharges,
  getPreparedCharges,
};
