import React from 'react';
import { InfoCircleOutlined } from '@ant-design/icons';

import themesColors from 'app-wrapper/view/themes/themesColors';

export const InfoCircleOutlinedComponent = (
  <InfoCircleOutlined
    width={11}
    height={11}
    color={themesColors.secondaryDot45}
  />
);
