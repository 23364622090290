import styled from 'styled-components';

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 2px 16px;
  background: #F4F5F8;
  border-radius: 4px;
  margin-bottom: 10px;
`;

export const Cell = styled.div<{justifyContent?: string; flex?: number}>`
  display: flex;
  align-items: center;
  justify-content: ${({ justifyContent }) => justifyContent};
  flex: ${({ flex = 1 }) => flex};
  flex-wrap: wrap;
  gap: 4px 4px;
  text-transform: capitalize;
`;

export const Bold = styled.span<{bottomMargin?: boolean}>`
  font-size: 10px;
  font-weight: 700;
  text-transform: capitalize;
  ${({ bottomMargin }) => bottomMargin && 'margin-bottom: 4px;'}
`;

export const Span = styled.span<{fontSize?: number}>`
  font-size: 9px;
  text-transform: capitalize;
`;
