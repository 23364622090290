import { createSelector } from 'reselect';
import { RootState } from 'app-wrapper/store';

const localState = (state: RootState) => state.commodity;

const getCommodity = createSelector(
  localState,
  (state) => state,
);

const getCommodities = createSelector(localState, (state) => state?.commodities);

export const commoditySelectors = {
  getCommodity,
  getCommodities,
};
