import React from 'react';
import { useSelector } from 'react-redux';

import { R } from 'user-management/repository';
import { UC } from 'user-management/controllers';

import { RejectModalComponent } from './RejectModal.component';

const RejectModalContainer = () => {
  const isRejectModalOpened = useSelector(R.selectors.customer.getIsRejectModalOpened);
  const rejectionNote = useSelector(R.selectors.customer.getRejectionNote);
  const rejectReason = useSelector(R.selectors.customer.getRejectReason);

  return (
    <RejectModalComponent
      rejectReason={rejectReason}
      rejectionNote={rejectionNote}
      setRejectionNote={UC.customer.setRejectionNote}
      setRejectReason={UC.customer.setRejectReason}
      isOpen={isRejectModalOpened}
      onClose={UC.customer.closeRejectModal}
      onOk={UC.customer.handleReject}
    />
  );
};

export { RejectModalContainer as RejectModal };
