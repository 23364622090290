import React, { FC } from 'react';
import isEmpty from 'lodash/fp/isEmpty';
import { useTranslation } from 'react-i18next';
import Divider from 'antd/es/divider';

import { InvoicesChargeDTM, ContainerViewDTM, IGroupedInvoicesChargesDTM } from 'shipment-operations/models/dtm';

import {
  HeaderTableWrapper, NotesInnerContainer, Title, ExpandPanelComponent,
} from 'shipment-operations/view/components';
import {
  CustomTableEditable,
} from 'shipment-operations/view/pages/CreateCreditNote/components/SecondStep/components';

interface IAdditionalComponentProps {
  data: IGroupedInvoicesChargesDTM
  containers: ContainerViewDTM[]
  tableData: InvoicesChargeDTM[]
}

const AdditionalComponent: FC<IAdditionalComponentProps> = ({
  containers, data, tableData,
}) => {
  const { t } = useTranslation();

  if ((isEmpty(data) && isEmpty(tableData))) {
    return null;
  }

  return (
    <NotesInnerContainer>
      <Title>{t('additionalChargesTitle')}</Title>
      {!isEmpty(containers) && (
        <>
          <Divider style={{ margin: '0' }} />
          <HeaderTableWrapper>
            <span>{t('container')}</span>
          </HeaderTableWrapper>
          <Divider style={{ margin: '0' }} />
        </>
      )}
      {!isEmpty(data) && containers.map((item) => (
        <ExpandPanelComponent
          key={`AdditionalComponent_${item.id}`}
          container={item}
        >
          <CustomTableEditable data={data[item.id]} />
        </ExpandPanelComponent>
      ))}
      {!isEmpty(tableData) && (<CustomTableEditable data={tableData} />)}
    </NotesInnerContainer>
  );
};

export { AdditionalComponent };
