import React from 'react';
import { useSelector } from 'react-redux';

import { R } from 'user-management/repository';

import { BookFirstShipmentComponent } from './BookFirstShipment.component';

const BookFirstShipmentContainer = () => {
  const isActive = useSelector(R.selectors.userOrganizationData.getIsOrganizationActive);

  return (
    <BookFirstShipmentComponent isOrganizationActive={isActive} />
  );
};

export { BookFirstShipmentContainer as BookFirstShipment };
