import styled, { css } from 'styled-components';

import { divFlex } from 'app-wrapper/view/themes/mixin';
import { SelectSearch } from 'app-wrapper/view/components';

import {
  SDCFMainContentLeftRowWidth,
  SDCFMainContentRightRowWidth,
  SDCFMainContentRirhtRowMinWidth,
  SDCFMainContentTextTitle,
} from '../ShipmentDocumentsAdditionalForm/ShipmentDocumentsForm.styled';

const borderInput = css`
  border-color: ${({ theme }) => theme?.themesColors?.neutralBranding5};
`;

export const SDCFMainContentFormDocument = styled.div.attrs((props: {
  isNext: boolean,
}) => ({
  isNext: props.isNext,
  'data-class': 'SDCFMainContentFormDocument',
}))`
  ${divFlex}
  ${({ isNext }) => isNext && 'margin-top: 8px;'};
`;

export const SDCFMainContentFormDocumentType = styled.div.attrs({
  'data-class': 'SDCFMainContentFormDocumentType',
})`
  ${divFlex}
  flex-direction: column;
  min-width: ${SDCFMainContentLeftRowWidth};
  width: ${SDCFMainContentLeftRowWidth};
`;

export const SDCFMainContentFormDocumentTypeTitle = styled.div.attrs({
  'data-class': 'SDCFMainContentFormDocumentTypeTitle',
})`
  ${divFlex}
  ${SDCFMainContentTextTitle}
`;

export const SDCFMainContentFormDocumentTypeContent = styled.div.attrs({
  'data-class': 'SDCFMainContentFormDocumentTypeContent',
})`
  ${divFlex}
  width: 100%;

  .ant-select div.ant-select-selector {
    ${borderInput}
  }
`;

export const SDCFMainContentForm = styled.div.attrs({
  'data-class': 'SDCFMainContentForm',
})`
  ${divFlex}
  flex-direction: column;
  width: 100%;
  margin-top: 16px;
`;

export const SDCFMainContentFormDocumentFile = styled.div.attrs({
  'data-class': 'SDCFMainContentFormDocumentFile',
})`
  ${divFlex}
  flex-direction: column;
  min-width: ${SDCFMainContentRirhtRowMinWidth};
  width: ${SDCFMainContentRightRowWidth};
`;

export const SDCFMainContentFormDocumentFileTitle = styled.div.attrs({
  'data-class': 'SDCFMainContentFormDocumentFileTitle',
})`
  ${divFlex}
  ${SDCFMainContentTextTitle}
  margin-left: 4px;
`;

export const SDCFMainContentFormDocumentFileContent = styled.div.attrs({
  'data-class': 'SDCFMainContentFormDocumentFileContent',
})`
  ${divFlex}
  width: 100%;
`;

export const SDCFMainContentFormDocumentFileUpload = styled.div.attrs({
  'data-class': 'SDCFMainContentFormDocumentFileUpload',
})`
  ${divFlex}
  width: 100%;
  margin: 0 4px;

  button {
    ${borderInput}
    span {
      font-size: 12px;
      font-weight: 400;
      color: ${({ theme }) => theme?.themesColors?.secondaryDot45};
    }
  }

  .ant-upload-list {
    span.ant-upload-span {
      color: ${({ theme }) => theme?.themesColors?.primaryBranding6};
    }
  }
`;

export const SDCFMainContentFormSelect = styled(SelectSearch).attrs({
  'data-class': 'SDCFMainContentFormSelect',
})`
  ${divFlex}
  min-width: ${SDCFMainContentLeftRowWidth};
  width: 100%;

  .ant-select-selection-placeholder {
    font-size: 12px;
    font-weight: 400;
    color: ${({ theme }) => theme?.themesColors?.secondaryDot45};
  }
`;
