import styled from 'styled-components';
import { ExclamationCircleFilled } from '@ant-design/icons';
import Typography from 'antd/es/typography';

import themesColors from 'app-wrapper/view/themes/themesColors';
import { Button } from 'app-wrapper/view/components';

export const UserOrganizationWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const UserOrganizationIconBG = styled.div.attrs({
  'data-class': 'UserOrganizationIconBG',
})`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  background-color: transparent;
  border-radius: 4px;
  color: ${themesColors.primaryBranding5};
  border: 1px solid ${themesColors.neutralBranding4};
  font-size: 14px;
`;

export const UserOrganizationRow = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  height: 44px;
  width: 100%;
  margin-top: 12px;
`;

export const UserOrganizationItem = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  width: 50%;
`;

export const UserOrganizationHeader = styled.div`
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
`;

export const UserOrganizationData = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: flex-start;
  
  margin-left: 16px;
`;

export const UserOrganizationTitle = styled.div`
  font-size: 12px;
  color: ${themesColors.darkGrayMiddle};
  line-height: 20px;
  font-weight: 400;
`;

export const UserOrganizationValue = styled.div`
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
`;

export const Warning = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 16px;
  height: 42px;
  background-color: rgba(255, 251, 230, 1);
  border: 1px solid rgba(255, 229, 143, 1);
`;

export const WarningIcon = styled(ExclamationCircleFilled)`
  color: rgba(250, 173, 20, 1);
`;

export const WarningText = styled(Typography)`
  margin-left: 8px;
  font-size: 14px;
  line-height: 22px;
`;

export const WarningButton = styled(Button)`
  margin-left: auto;
  height: 24px;
`;
