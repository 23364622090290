import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { useAuthToken } from 'app-wrapper/hooks';

import { R } from 'shipment-operations/repository';
import { UC } from 'shipment-operations/controllers';

import { ShipmentDocumentsAdditionalComponent } from './ShipmentDocumentsAdditional.component';

const ShipmentDocumentsAdditionalContainer: FC = () => {
  const shipment = useSelector(R.selectors.shipment.getShipment);
  const permissions = useSelector(R.selectors.shipment.getDocumentsPermissions);
  const shipmentDocumentDefault = useSelector(R.selectors.shipmentDocuments.getShipmentDocumentDefault);
  const isUpdated = useSelector(R.selectors.shipmentDocuments.getShipmentDocumentUpdateIsUpdate);
  const isLoading = useSelector(R.selectors.shipment.getLoadingState);
  const authToken = useAuthToken();

  return (
    <ShipmentDocumentsAdditionalComponent
      shipment={shipment}
      shipmentDocumentDefault={shipmentDocumentDefault}
      isLoading={isLoading}
      authToken={authToken}
      onLeavePage={UC.shipmentDocumentAdditional.onLeavePage}
      onEnterPage={UC.shipmentDocumentAdditional.onEnterPage}
      disabledUpdate={!isUpdated}
      onDiscardHandler={UC.shipmentDocumentAdditional.onDiscardHandler}
      onUpdateHandler={UC.shipmentDocumentAdditional.onUpdateAdditional}
      getRunAdditionalDetails={UC.shipmentDocumentAdditional.getRunAdditionalDetailsWithCheck}
      additionalDocumentsPermission={permissions.additionalDocuments}
    />
  );
};

export { ShipmentDocumentsAdditionalContainer as ShipmentDocumentsAdditional };
