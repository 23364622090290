import React, { FC, memo } from 'react';
import { useSelector } from 'react-redux';

import { R } from 'user-management/repository';
import { UC } from 'user-management/controllers';

import { AccountDepartmentsByIdComponent } from './AccountDepartmentsById.component';

const AccountDepartmentsByIdContainer: FC = () => {
  const department = useSelector(R.selectors.accountDepartments.getAccountDepartmentsDefaultDepartmentById);
  const isAdmin = useSelector(R.selectors.moduleFunctionalityPermissions.getIsAdmin);

  return (
    <AccountDepartmentsByIdComponent
      department={department}
      isLoading={false}
      isAdmin={isAdmin}
      runComponent={UC.accountDepartments.onRunAccountDepartmentsByIdPage}
      onClickRemoveDepartment={UC.accountDepartments.onClickPageAccountDepartmentsByIdRemove}
      onClickEditAddress={UC.accountDepartments.onClickPageAccountDepartmentsByIdEditDepartment}
      onClickArrow={UC.accountDepartments.onClickPageAccountDepartmentsArrow}
    />
  );
};

const AccountDepartmentsByIdContainerCached = memo(AccountDepartmentsByIdContainer);

export { AccountDepartmentsByIdContainerCached as AccountDepartmentsByIdPage };
