import React, { FC } from 'react';
import { UserOutlined } from '@ant-design/icons';

import {
  Members,
  Text,
} from './TeamMembers.styled';

interface ITeamMembersProps {
  membersCount: number;
}

export const TeamMembers: FC<ITeamMembersProps> = ({ membersCount }) => (
  <Members>
    <UserOutlined />

    <Text>
      {membersCount}
    </Text>
  </Members>
);
