import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import {
  PageWrapper,
  Main,
  Bold,
  TitleWrapper,
  FreightAndChargesBreakdown,
  Clauses,
} from 'shipment-operations/view/components/HBLDocumentPDF/components';
import { HBLDocumentBOLDTM } from 'shipment-operations/models/dtm';

interface ThirdPageProps {
  data: HBLDocumentBOLDTM;
}

export const ThirdPage: FC<ThirdPageProps> = ({ data }) => {
  const { t } = useTranslation();

  return (
    <PageWrapper>
      <Main>
        <TitleWrapper>
          <Bold>{t('Freight and Charges Breakdown')}</Bold>
        </TitleWrapper>
        <FreightAndChargesBreakdown charges={data.charges} />

        {data.clauses && data.clauses.length ? (
          <>
            <TitleWrapper>
              <Bold>{t('Clauses')}</Bold>
            </TitleWrapper>
            <Clauses clauses={data.clauses} />
          </>
        ) : null}
      </Main>
    </PageWrapper>
  );
};
