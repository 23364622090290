import styled from 'styled-components';

import themesColors from 'app-wrapper/view/themes/themesColors';

export const SignUpSuccessWrapper = styled.div.attrs({
  'data-class': 'SignUpSuccessWrapper',
})`
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-around;
  align-items: center;

  width: 100%;
  height: 100%;
`;

export const SignUpSuccessForm = styled.div.attrs({
  'data-class': 'SignUpSuccessForm',
})`
  margin-top: auto;
  width: 360px;
  height: fit-content;
`;

export const LogoWrapper = styled.div.attrs({
  'data-class': 'SignUpSuccessButton',
})`
  margin: 0 auto 12px auto;
  width: fit-content;
`;

export const SignUpSuccessTitle = styled.p.attrs({
  'data-class': 'SignUpSuccessTitle',
})`
  margin: auto auto 32px auto;

  color: ${themesColors.secondaryDot45};
  font-size: 14px;
  line-height: 22px;
  text-align: center;
`;

export const SignUpSuccessCard = styled.div.attrs({
  'data-class': 'SignUpSuccessCard',
})`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  
  border: 1px solid ${themesColors.neutralBranding4};
  border-radius: 3px;

  padding: 16px 8px 24px 8px;
  width: 360px;
  height: 208px;
`;

export const SignUpSuccessCardEmailImgWrapper = styled.div.attrs({
  'data-class': 'SignUpSuccessCardEmailImgWrapper',
})`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  
  border-radius: 50%;
  
  margin-bottom: 8px;
  width: 80px;
  min-width: 80px;
  height: 80px;
  min-height: 80px;
  
  background-color: ${themesColors.neutralBranding2};
`;

export const SignUpSuccessMessageHeader = styled.p.attrs({
  'data-class': 'SignUpSuccessMessageHeader',
})`
  margin: auto auto 8px auto;

  color: ${themesColors.neutralBranding10};
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  text-align: left;
`;

export const SignUpSuccessMessage = styled.p.attrs({
  'data-class': 'SignUpSuccessMessage',
})`
  margin: auto auto 12px auto;

  color: ${themesColors.secondaryDot45};
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  
  & > span {
    font-weight: bold;
  }
`;

export const BottomMessage = styled.p.attrs({
  'data-class': 'BottomMessage',
})`
  margin: auto auto 20px auto;
  
  text-align: center;
  font-size: 14px;
  color: ${themesColors.secondaryDot45};
`;
