import styled, { css } from 'styled-components';
import Button from 'antd/es/button';

import { divFlex } from 'app-wrapper/view/themes/mixin';
import { Input } from 'app-wrapper/view/components';

const titleCss = css`
  font-size: 12px;
  font-weight: 400;
  color: ${({ theme }) => theme?.themesColors?.neutralBranding10};
`;

const secondTitleCss = css`
  font-size: 12px;
  font-weight: 400;
  color: ${({ theme }) => theme?.themesColors?.secondaryDot45};
`;

const inputCss = css`
  font-size: 12px;
  font-weight: 400;
  color: ${({ theme }) => theme?.themesColors?.neutralBranding10};
  height: 32px;
`;

const inputLargeCss = css`
  height: 40px;
`;

const blockNameCss = css`
  font-size: 14px;
  font-weight: 500;
  color: ${({ theme }) => theme?.themesColors?.neutralBranding10};
`;

const itemRowCss = css`
  margin-top: 10px;
`;

const inputTopMarginCss = css`
  margin-top: 5px;
`;

export const CBDividerMargins = {
  margin: '0',
  marginTop: '16px',
};

export const ContactPersonBlock = styled.div.attrs({
  'data-class': 'ContactPersonBlock',
})`
  ${divFlex}
  ${itemRowCss}
  flex-direction: column;
  width: 100%;
`;

export const CBContentBodyFormPrimary = styled.div.attrs({
  'data-class': 'CBContentBodyFormPrimary',
})`
  ${divFlex}
  width: 58px;
  height: 22px;
  align-items: center;
  justify-content: center;

  background-color: ${({ theme }) => theme.themesColors.polarGreen1};
  color: ${({ theme }) => theme.themesColors.polarGreen7};
  font-size: 12px;
  font-weight: 400;
  border: 1px solid ${({ theme }) => theme.themesColors.polarGreen3};
  margin-left: 8px;
  border-radius: 3px;
`;

export const ContactPersonBlockNameWrapper = styled.div.attrs({
  'data-class': 'ContactPersonBlockNameWrapper',
})`
  ${divFlex}
  ${blockNameCss}
  width: 100%;
`;

export const ContactPersonBlockName = styled.div.attrs({
  'data-class': 'ContactPersonBlockName',
})`
  ${divFlex}
  ${blockNameCss}
  width: 100%;
`;

export const ContactPersonBlockDeleteButton = styled(Button).attrs({
  'data-class': 'ContactPersonBlockDeleteButton',
})`
  ${divFlex}
  ${titleCss}

  height: 22px;
  align-items: center;
  justify-content: center;
  width: 82px;
  right: 0;
  margin-left: auto;
`;

// First

export const ContactPersonBlockFirst = styled.div.attrs({
  'data-class': 'ContactPersonBlockFirst',
})`
  ${divFlex}
  ${itemRowCss}
  flex-direction: column;
  width: 100%;
`;

export const ContactPersonBlockFirstInputDiv = styled.div.attrs({
  'data-class': 'ContactPersonBlockFirstInputDiv',
})`
  ${divFlex}
  flex-direction: column;
  width: 100%;
`;

export const ContactPersonBlockFirstInputWrapper = styled.div.attrs({
  'data-class': 'ContactPersonBlockFirstInputWrapper',
})`
  ${divFlex}
  flex-direction: column;
`;

export const ContactPersonBlockFirstInputWrapperRight = styled.div.attrs({
  'data-class': 'ContactPersonBlockFirstInputWrapperRight',
})`
  ${divFlex}

  margin-top: 10px;
  flex-direction: column;
`;

export const ContactPersonBlockFirstTitle = styled.div.attrs({
  'data-class': 'ContactPersonBlockFirstTitle',
})`
  ${divFlex}
  ${titleCss}
  width: 100%;
`;

export const ContactPersonBlockFirstInput = styled(Input).attrs((props: {
  hasError: boolean,
  largeInput: boolean,
}) => ({
  hasError: props.hasError,
  largeInput: props.largeInput,
  'data-class': 'ContactPersonBlockFirstInput',
}))`
  ${divFlex}
  ${inputCss}
  ${inputTopMarginCss}

  ${({ largeInput }) => largeInput && inputLargeCss};
  width: 100%;

  ${({ hasError, theme }) => hasError && `border-color: ${theme.themesColors.characterBrandingDanger} !important; border-style: solid;`};
`;

// Second

export const ContactPersonBlockSecond = styled.div.attrs({
  'data-class': 'ContactPersonBlockSecond',
})`
  ${divFlex}
  ${itemRowCss}
  flex-direction: column;
  width: 100%;
  margin-top: 10px;
`;

export const ContactPersonBlockSecondTitleDiv = styled.div.attrs({
  'data-class': 'ContactPersonBlockSecondTitleDiv',
})`
  ${divFlex}
  ${titleCss}
  width: 100%;
`;

export const ContactPersonBlockSecondTitle = styled.div.attrs({
  'data-class': 'ContactPersonBlockSecondTitle',
})`
  ${divFlex}
  ${titleCss}
`;

export const ContactPersonBlockSecondTitleSecond = styled.div.attrs({
  'data-class': 'ContactPersonBlockSecondTitleSecond',
})`
  ${divFlex}
  ${titleCss}
  ${secondTitleCss}
  margin-left: 3px;
`;

export const ContactPersonBlockSecondInputDiv = styled.div.attrs({
  'data-class': 'ContactPersonBlockSecondInputDiv',
})`
  ${divFlex}
  flex-direction: column;
  width: 100%;
  margin-top: 4px;
`;

export const ContactPersonBlockSecondPhoneWrapper = styled.div.attrs({
  'data-class': 'ContactPersonBlockSecondPhoneWrapper',
})`
  ${divFlex}
  flex-direction: column;
`;

export const ContactPersonBlockSecondPhone = styled(Input).attrs((props: {
  hasError: boolean,
  largeInput: boolean,
}) => ({
  hasError: props.hasError,
  largeInput: props.largeInput,
  'data-class': 'ContactPersonBlockSecondPhone',
}))`
  ${divFlex}
  ${inputCss}
  ${inputTopMarginCss}

  ${({ largeInput }) => largeInput && inputLargeCss};
  width: 100%;

  ${({ hasError, theme }) => hasError && `border-color: ${theme.themesColors.characterBrandingDanger} !important; border-style: solid;`};
`;

export const ContactPersonBlockSecondAPhoneWrapper = styled.div.attrs({
  'data-class': 'ContactPersonBlockSecondAPhoneWrapper',
})`
  ${divFlex}

  margin-top: 10px;
  flex-direction: column;
`;

export const ContactPersonBlockSecondAPhone = styled(Input).attrs((props: {
  hasError: boolean,
  largeInput: boolean,
}) => ({
  hasError: props.hasError,
  largeInput: props.largeInput,
  'data-class': 'ContactPersonBlockSecondAPhone',
}))`
  ${divFlex}
  ${inputCss}
  ${({ largeInput }) => largeInput && inputLargeCss};
  width: 100%;
  margin-top: 4px;

  ${({ hasError, theme }) => hasError && `border-color: ${theme.themesColors.characterBrandingDanger} !important; border-style: solid;`};
`;

// Third

export const ContactPersonBlockFourth = styled.div.attrs({
  'data-class': 'ContactPersonBlockFourth',
})`
  ${divFlex}
  ${itemRowCss}
  flex-direction: column;
  width: 100%;

  button {
    width: 179px;
    ${titleCss}
    font-size: 14px;
  }
  svg {
    color: ${({ theme }) => theme?.themesColors?.neutralBranding7};
  }
`;
