import { ChargeCodeDTM } from 'shipment-operations/models/dtm';

export interface DataType {
  key: string | number;
  charge: string;
  chargeCode?: ChargeCodeDTM;
  applicability?: string;
  measureBy: string;
  currency: string;
  title: boolean | string;
  emptyRender: boolean;
  price?: number;
  [key: string]: any;
}

export const CONTAINER_COLUMN = 'container';

export const UPDATE_TRANSPORT_PLAN = 'UPDATE_TRANSPORT_PLAN';
export const CHANGES = 'CHANGES';

export const SUBJECT_TO = 'subjectTo';

export const ORIGIN_INCOTERM = ['CIF', 'CFR'];
export const DESTINATION_INCOTERM = ['FOB', 'FAS', 'FCA'];
