import React, {
  FC,
  ReactNode,
  useCallback,
  useRef,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useReactToPrint } from 'react-to-print';

import { MenuItem } from './BookingConfirmationPDF.styled';
import { PDFDocument } from './components';

interface ShareInvoiceComponentProps {
  header?: ReactNode;
  isLoaded: boolean;
  isLoading: boolean;
  fileNamePrint?: string;
}

const oldTitle = document.title;

export const BookingConfirmationPDFComponent: FC<ShareInvoiceComponentProps> = ({
  isLoaded,
  header,
  isLoading,
  fileNamePrint,
}) => {
  const { t } = useTranslation();
  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    // @ts-ignore
    content: () => componentRef.current,
    onAfterPrint: () => {
      // support chrome
      document.title = oldTitle;
    },
  });

  const handleClick = useCallback(async () => {
    // @ts-ignore only for e2e test
    if (!window.Cypress) {
      // support chrome
      document.title = fileNamePrint || oldTitle;
      await handlePrint();
    }
  }, [handlePrint, isLoading, fileNamePrint]);

  return (
    <>
      <div data-class="pdfLayout" style={{ display: 'none' }}>
        {isLoaded && <PDFDocument header={header} fileNamePrint={fileNamePrint} ref={componentRef} />}
      </div>

      <MenuItem onClick={handleClick} disabled={isLoading}>
        {t('Print')}
      </MenuItem>
    </>
  );
};
