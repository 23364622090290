import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { UC } from 'user-management/controllers';

import { R } from 'user-management/repository';
import { UserManagementComponent } from './UserManagement.component';

const UserManagementContainer: FC = () => {
  const updateSuccess = useSelector(R.selectors.userManagement.getUpdateSuccess);
  const deleteSuccess = useSelector(R.selectors.userManagement.getDeleteSuccess);

  return (
    <UserManagementComponent
      onUpload={UC.userManagement.uploadData}
      onClear={UC.userManagement.clear}
      updateSuccess={updateSuccess}
      deleteSuccess={deleteSuccess}
    />
  );
};

export {
  UserManagementContainer as UserManagement,
};
