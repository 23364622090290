import styled from 'styled-components';

export const Card = styled.div`
  width: 100%;
  height: fit-content;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
`;

export const ParticipantCardWrapper = styled(Card)`
  justify-content: flex-start;
  font-size: 10px;
`;

export const Phone = styled.span`
  position: absolute;
  right: 0;
  top: 0;
`;
