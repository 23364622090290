import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { R as RApp } from 'app-wrapper/repository';

import { R } from 'shipment-operations/repository';
import { UC } from 'shipment-operations/controllers';

import { ContactsAddressBlockComponent } from 'user-management/view/components';
import { ICompanyAddressDTM } from 'user-management/models/dtm';

interface IAddressItemContainerProps {
  largeInput: boolean
  addressIndex: number
  companyListStateIndex: string
  isFirst: boolean
  isLast: boolean
  address?: ICompanyAddressDTM
}

export const AddressItemContainer: FC<IAddressItemContainerProps> = (props) => {
  const {
    largeInput,
    addressIndex,
    companyListStateIndex,
    isFirst,
    isLast,
    address,
  } = props;

  const countriesList = useSelector(RApp.selectors.countriesList.getCountriesList);

  const errors = useSelector(R.selectors.bookingWizard.getContactBookUpdateErrorsAddressesById(address?.customId || ''));

  const validateAll = UC.bookingWizardDrawer.validateFullShipmentPartyCompany;
  const validateOnly = UC.bookingWizardDrawer.validateOnlyShipmentPartyCompany;
  const validateCompare = UC.bookingWizardDrawer.validateCompareShipmentPartyCompany;

  return (
    <ContactsAddressBlockComponent
      largeInput={largeInput}
      countriesList={countriesList}
      isFirst={isFirst}
      isLast={isLast}
      address={address}
      addressIndex={addressIndex}
      companyListStateIndex={companyListStateIndex}
      errors={errors}
      onChangeAddressLineFirst={UC.bookingWizardDrawer.onChangeUpdateAddressesAddressLineFirstByIndex(validateCompare)}
      onBlurAddressLineFirst={UC.bookingWizardDrawer.onBlurUpdateAddressesAddressLineFirstByIndex(validateOnly)}
      onFocusAddressLineFirst={UC.bookingWizardDrawer.onFocusUpdateAddressesAddressLineFirstByIndex(validateOnly)}
      onChangeAddressLineSecond={UC.bookingWizardDrawer.onChangeUpdateAddressesAddressLineSecondByIndex(validateCompare)}
      onBlurAddressLineSecond={UC.bookingWizardDrawer.onBlurUpdateAddressesAddressLineSecondByIndex(validateOnly)}
      onFocusAddressLineSecond={UC.bookingWizardDrawer.onFocusUpdateAddressesAddressLineSecondByIndex(validateOnly)}
      onChangeCity={UC.bookingWizardDrawer.onChangeUpdateAddressesCityByIndex(validateCompare)}
      onBlurCity={UC.bookingWizardDrawer.onBlurUpdateAddressesCityByIndex(validateOnly)}
      onFocusCity={UC.bookingWizardDrawer.onFocusUpdateAddressesCityByIndex(validateOnly)}
      onChangeCountry={UC.bookingWizardDrawer.onChangeUpdateAddressesCountryByIndex(validateCompare)}
      onBlurCountry={UC.bookingWizardDrawer.onBlurUpdateAddressesCountryByIndex(validateOnly)}
      onFocusCountry={UC.bookingWizardDrawer.onFocusUpdateAddressesCountryByIndex(validateOnly)}
      onClearCountry={UC.bookingWizardDrawer.onClearUpdateAddressesCountryByIndex(validateAll)}
      onChangeState={UC.bookingWizardDrawer.onChangeUpdateAddressesStateByIndex(validateCompare)}
      onBlurState={UC.bookingWizardDrawer.onBlurUpdateAddressesStateByIndex(validateOnly)}
      onFocusState={UC.bookingWizardDrawer.onFocusUpdateAddressesStateByIndex(validateOnly)}
      onClearState={UC.bookingWizardDrawer.onClearUpdateAddressesStateByIndex(validateAll)}
      onChangePostalCode={UC.bookingWizardDrawer.onChangeUpdateAddressesPostalCodeByIndex(validateCompare)}
      onBlurPostalCode={UC.bookingWizardDrawer.onBlurUpdateAddressesPostalCodeByIndex(validateOnly)}
      onFocusPostalCode={UC.bookingWizardDrawer.onFocusUpdateAddressesPostalCodeByIndex(validateOnly)}
      addAddressItem={UC.bookingWizardDrawer.setUpdateAddCompanyListStateAddressItem(validateAll)}
      onRemoveAddress={UC.bookingWizardDrawer.setUpdateRemoveCompanyListStateAddressItem(validateAll)}
    />
  );
};

export { AddressItemContainer as AddressItem };
