import React, { FC } from 'react';

import { IReferenceTagsProps, ReferenceTags } from 'app-wrapper/view/components';
import { ReferenceDTM } from 'app-wrapper/types/Reference';

import { CargoReferenceTypeNamesShort } from 'shipment-operations/constants';

import { Divider } from './ContainerCardMiddle.styled';

interface ICargoCardMiddleProps {
  references: ReferenceDTM[]
}

export const ContainerCardMiddle: FC<ICargoCardMiddleProps> = ({ references }) => {
  const filteredRefs = references.filter((reference) => !!(reference.type && reference.value));

  if (!filteredRefs.length) {
    return <Divider dashed />;
  }

  return (
    <>
      <Divider dashed />
      <ReferenceTags
        referenceTypeNamesMap={CargoReferenceTypeNamesShort}
        references={references as IReferenceTagsProps['references']}
      />
      <Divider dashed />
    </>
  );
};
