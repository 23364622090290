import styled, { css } from 'styled-components';

import { divFlex } from 'app-wrapper/view/themes/mixin';

export const scaffoldSection = css`
  background-color: ${({ theme }) => theme?.themesColors?.neutralBranding1};
  border-radius: 3px;
  padding: 16px;
`;

export const SBLScaffoldCompany = styled('div')`
  ${scaffoldSection}
  width: 100%;
`;

export const SBLScaffoldRow = styled('div')`
  ${divFlex}
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
`;

export const SBLScaffoldColumn = styled('div').attrs({
  'data-class': 'SBLScaffoldColumn',
})`
  ${divFlex}
  flex-direction: column;
  justify-content: space-between;
`;

export const SBLScaffoldCarrier = styled('div').attrs({
  'data-class': 'SBLScaffoldCarrier',
})`
  ${scaffoldSection}
  width: calc(50% - 4px);
  min-height: 106px;
`;

export const SBLScaffoldTransport = styled('div').attrs({
  'data-class': 'SBLScaffoldTransport',
})`
  ${scaffoldSection}
  width: calc(50% - 4px);
  min-height: 106px;
`;

export const SBLScaffoldShippingParties = styled('div').attrs({
  'data-class': 'SBLScaffoldShippingParties',
})`
  ${scaffoldSection}
  width: 100%;
`;

export const SBLScaffoldBillingInstructions = styled('div').attrs({
  'data-class': 'SBLScaffoldBillingInstructions',
})`
  ${scaffoldSection}
  width: 100%;
  height: 100%;
`;

export const SBLScaffoldPayments = styled('div').attrs({
  'data-class': 'SBLScaffoldPayments',
})`
  ${scaffoldSection}
  width: 100%;
  height: 100%;
`;

export const SBLRoutingInformation = styled('div').attrs({
  'data-class': 'SBLRoutingInformation',
})`
  ${scaffoldSection}
  width: 100%;
  height: 100%;
  max-height: 106px;
`;

export const SBLCargoTables = styled('div').attrs({
  'data-class': 'SBLCargoTables',
})`
  ${divFlex}
  flex-direction: column;
`;

export const SBLCharges = styled('div').attrs({
  'data-class': 'SBLCharges',
})`
  ${scaffoldSection}
  width: 100%;
`;

export const SBLClauses = styled('div').attrs({
  'data-class': 'SBLClauses',
})`
  ${scaffoldSection}
  width: 33%;
`;

export const SBLComments = styled('div')
  .attrs((props: {fullWidth?: boolean}) => ({
    fullWidth: props.fullWidth,
  }))`
  ${scaffoldSection}
  width: ${({ fullWidth }) => (fullWidth ? '100' : '66')}%;
  height: 100%;
`;
