import moment from 'moment';

import { BaseController, controller } from 'proto/BaseController';

import { R } from 'user-management/repository';

@controller
export class CustomerSalesController extends BaseController {
  initCustomerSales = async (customerId: string) => {
    this.mobxStore.customerSales.setLoading(true);

    const markupPolicies = await R.services.markupPolicy.getMarkupPolicies(customerId);
    const policiesCreatedAtDates = markupPolicies.map(({ createdAt }) => createdAt?.getDateAsMomentWithOffset());
    const latestCreatedAtDate = moment.max(policiesCreatedAtDates as moment.Moment[]);

    const latestUploadedPolicy = markupPolicies.find((policy) => String(latestCreatedAtDate) === String(policy.createdAt?.getDateAsMomentWithOffset()));

    this.mobxStore.customerSales.setMarkupPolicy(latestUploadedPolicy);
    this.mobxStore.customerSales.setLoading(false);
  }

  uploadMarkupPolicy = async (file: File, customerId: string) => {
    this.mobxStore.customerSales.setLoading(true);

    await R.services.markupPolicy.uploadMarkupPolicyFile(file, customerId);
    this.initCustomerSales(customerId);

    this.mobxStore.customerSales.setLoading(false);
  };

  downloadMarkupPolicy = async () => {
    const customer = R.selectors.customer.getOrganization(this.store.getState());

    if (!customer) {
      return;
    }

    await R.services.markupPolicy.downloadMarkupPolicyFile(customer.id);
  }
}
