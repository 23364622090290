import styled from 'styled-components';

import { divFlex } from 'app-wrapper/view/themes/mixin';
import { ShipmentDetailsActionPanel, ShipmentDetailsLayout } from 'shipment-operations/view/components';

export const SDCShipmentDetailsLayout = styled(ShipmentDetailsLayout).attrs({
  'data-class': 'SDCShipmentDetailsLayout',
})`
  width: 100%;
`;

export const SDCShipmentDetailsFooter = styled(ShipmentDetailsActionPanel).attrs({
  'data-class': 'SDCShipmentDetailsLayout',
})`
  width: 100%;
  margin-left: 0;
  margin-right: 0;
`;

export const SDCLoaderWrapper = styled.div.attrs({
  'data-class': 'SDCLoaderWrapper',
})`
  ${divFlex}
`;

export const SDCMainContent = styled.div.attrs({
  'data-class': 'SDCMainContent',
})`
  ${divFlex}
  overflow-y: auto;
  flex-direction: column;
  background-color: ${({ theme }) => theme?.themesColors?.neutralBranding1};
  height: 100%;
`;

export const SDCMainContentWrapper = styled.div.attrs({
  'data-class': 'SDCMainContentWrapper',
})`
  ${divFlex}
  overflow-y: auto;
  flex-direction: column;
  width: 100%;
  height: calc(100% - 64px);
  padding: 16px;
`;

export const SDCMainContentTitle = styled.div.attrs({
  'data-class': 'SDCMainContentTitle',
})`
  ${divFlex}
  font-size: 16px;
  font-weight: 500;
`;

export const SDCDividerMargins = {
  margin: '0',
  marginTop: '13px',
};
