import { apiWorker } from 'app-wrapper/repository/utilsServices';

export class HsValidationService {
  private base = '/shipment-service/api/v1/shipments';

  public getHsGroup = async (shipmentId: number, hsCode: string) => {
    await apiWorker.requestPostBySchema(`${this.base}/${shipmentId}/check-hs-code?hsCode=${hsCode}` as '/api/v1/shipments/{shipmentId}/check-hs-code');

    return true;
  };
}
