import React, { FC, memo } from 'react';
import { GapHorizontalContainerStyled } from 'app-wrapper/view/guideline';
import { SkeletonSection } from 'app-wrapper/view/components/Skeleton/Skeleton.component';
import { StatisticsTasksTripleBlockComponent } from '../../../StatisticsTasksTripleBlock';

interface IStatisticsTasksComponentProps {
  firstHeaderNameTasks?: string;
  secondHeaderNameTasks?: string;
  secondHeaderSubNameTasks?: string;
  thirdHeaderNameTasks?: string;
  firstHeaderLinkTasks?: string;
  secondHeaderLinkTasks?: string;
  thirdHeaderLinkTasks?: string;
  firstHeaderIconTasks?: React.ReactNode;
  secondHeaderIconTasks?: React.ReactNode;
  thirdHeaderIconTasks?: React.ReactNode;
  firstHeaderCountTasks?: string;
  secondHeaderCountTasks?: string;
  thirdHeaderCountTasks?: string;

  firstHeaderNameNotifications?: string;
  secondHeaderNameNotifications?: string;
  thirdHeaderNameNotifications?: string;
  firstHeaderLinkNotifications?: string;
  secondHeaderLinkNotifications?: string;
  thirdHeaderLinkNotifications?: string;
  firstHeaderIconNotifications?: React.ReactNode;
  secondHeaderIconNotifications?: React.ReactNode;
  thirdHeaderIconNotifications?: React.ReactNode;
  firstHeaderCountNotifications?: string;
  secondHeaderCountNotifications?: string;
  thirdHeaderCountNotifications?: string;
  isLoading?: boolean
}

const StatisticsTasksComponent: FC<IStatisticsTasksComponentProps> = (props) => {
  const {
    firstHeaderNameTasks,
    secondHeaderNameTasks,
    secondHeaderSubNameTasks,
    thirdHeaderNameTasks,
    firstHeaderLinkTasks,
    secondHeaderLinkTasks,
    thirdHeaderLinkTasks,
    firstHeaderIconTasks,
    secondHeaderIconTasks,
    thirdHeaderIconTasks,
    firstHeaderCountTasks,
    secondHeaderCountTasks,
    thirdHeaderCountTasks,
    firstHeaderNameNotifications,
    secondHeaderNameNotifications,
    thirdHeaderNameNotifications,
    firstHeaderLinkNotifications,
    secondHeaderLinkNotifications,
    thirdHeaderLinkNotifications,
    firstHeaderIconNotifications,
    secondHeaderIconNotifications,
    thirdHeaderIconNotifications,
    firstHeaderCountNotifications,
    secondHeaderCountNotifications,
    thirdHeaderCountNotifications,
    isLoading,
  } = props;

  return (
    <GapHorizontalContainerStyled>
      {isLoading
        ? (
          <>
            <SkeletonSection height="81px" />
            <SkeletonSection height="81px" />
          </>
        )
        : (
          <>
            <StatisticsTasksTripleBlockComponent
              firstHeaderName={firstHeaderNameTasks}
              secondHeaderName={secondHeaderNameTasks}
              secondHeaderSubName={secondHeaderSubNameTasks}
              thirdHeaderName={thirdHeaderNameTasks}
              firstHeaderLink={firstHeaderLinkTasks}
              secondHeaderLink={secondHeaderLinkTasks}
              thirdHeaderLink={thirdHeaderLinkTasks}
              firstHeaderIcon={firstHeaderIconTasks}
              secondHeaderIcon={secondHeaderIconTasks}
              thirdHeaderIcon={thirdHeaderIconTasks}
              firstHeaderCount={firstHeaderCountTasks}
              secondHeaderCount={secondHeaderCountTasks}
              thirdHeaderCount={thirdHeaderCountTasks}
            />
            <StatisticsTasksTripleBlockComponent
              firstHeaderName={firstHeaderNameNotifications}
              secondHeaderName={secondHeaderNameNotifications}
              thirdHeaderName={thirdHeaderNameNotifications}
              firstHeaderLink={firstHeaderLinkNotifications}
              secondHeaderLink={secondHeaderLinkNotifications}
              thirdHeaderLink={thirdHeaderLinkNotifications}
              firstHeaderIcon={firstHeaderIconNotifications}
              secondHeaderIcon={secondHeaderIconNotifications}
              thirdHeaderIcon={thirdHeaderIconNotifications}
              firstHeaderCount={firstHeaderCountNotifications}
              secondHeaderCount={secondHeaderCountNotifications}
              thirdHeaderCount={thirdHeaderCountNotifications}
            />
          </>
        )}
    </GapHorizontalContainerStyled>
  );
};

const StatisticsTasksComponentCache = memo(StatisticsTasksComponent);

export { StatisticsTasksComponentCache as StatisticsTasksOverviewComponent };
