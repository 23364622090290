import React, { ReactNode, useEffect } from 'react';
import { BaseController } from 'proto/BaseController';

interface PageComponentProps {
  controller: BaseController
  children: ReactNode | ReactNode[]
}

export const PageComponent = ({ controller, children }: PageComponentProps) => {
  useEffect(() => () => {
    controller.onClosePage();
  }, [controller]);

  return <>{children}</>;
};

export const Page = (controller: BaseController, component: React.ReactNode) => (
  <PageComponent controller={controller}>
    {component}
  </PageComponent>
);
