import { createSlice } from 'app-wrapper/createSlice';

const initialState: { isCollapsed: boolean } = {
  isCollapsed: false,
};

export const isMenuCollapsedSlice = createSlice({
  name: 'requestHsCode',
  initialState,
  reducers: {
    setIsMenuCollapsed: (state, { payload }) => ({ ...state, isCollapsed: payload }),
  },
});

export const { setIsMenuCollapsed } = isMenuCollapsedSlice.actions;

export const isMenuCollapsedSliceReducer = isMenuCollapsedSlice.reducer;
