import React, { FC, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
  TrackerHeader,
} from 'shipment-operations/view/components/ShipmentTrackerHeader';
import notification from 'antd/es/notification';
import { GapVerticalContainerStyled } from 'app-wrapper/view/guideline';
import { ContainersTable } from './components';

interface IShipmentTransportationTrackerComponentProps {
  loadData: (shipmentId?: string) => void
  onClosePage: () => void
  errorUpdate: boolean
  resetError: () => void
  withEdit: boolean
  isLoading: boolean
}

export const ShipmentTransportationTrackerComponent: FC<IShipmentTransportationTrackerComponentProps> = ({
  loadData,
  isLoading,
  onClosePage,
  errorUpdate,
  resetError,
  withEdit,
}) => {
  const { shipmentId } = useParams<'shipmentId'>();
  const { t } = useTranslation();

  useEffect(() => {
    loadData(shipmentId);
    return onClosePage;
  }, []);

  useEffect(() => {
    if (errorUpdate) {
      notification.error({
        message: t('basicErrors.REQUEST_COMMON.title'),
        description: t('basicErrors.REQUEST_COMMON.subTitle'),
        placement: 'bottomRight',
        bottom: 60,
        onClose: resetError,
      });
    }
  }, [errorUpdate, t]);

  return (
    <GapVerticalContainerStyled>
      <TrackerHeader isPageLoading={isLoading} skeletonHeight="210px" withEdit={withEdit} />

      <ContainersTable />
    </GapVerticalContainerStyled>
  );
};
