import React, {
  Fragment, FC, useCallback, useEffect,
} from 'react';
import EnvironmentOutlined from '@ant-design/icons/EnvironmentOutlined';
import Input from 'antd/es/input';
import { useTranslation } from 'react-i18next';
import Spin from 'antd/es/spin';
import Typography from 'antd/es/typography';
import { Moment } from 'moment';
import { useParams } from 'react-router';
import moment from 'moment/moment';

import { ITransportPlanChanges } from 'shipment-operations/models/dtm';
import {
  DOCUMENT_CUTOFF, TERMINAL_CUTOFF, VGM_CUTOFF, RoadTypes, UPDATE_TRANSPORT_PLAN,
} from 'shipment-operations/constants';

import { Button } from 'app-wrapper/view/components';
import { ETransportationType } from 'monetary/constants';
import { Validate } from 'shipment-operations/view/components';
import {
  ElemWrapper, Wrapper, Main, Dot, Point, RouteWrapper, LocationWrapper,
  InputWrapper, Label, DashedBorder, ModeWrapper, VoyageWrapper, Divider,
  StyledDatePicker, StyledSelect, FooterWrapper, CutoffWrapper, CutoffTitle,
  CutoffTime, CutoffElem,
} from './EditTransportPlan.styled';

const timeFormat = 'D MMM YYYY, HH:mm';

const DotsRender = (isStart: boolean, isEnd: boolean) => (
  <>
    {isStart ? <EnvironmentOutlined /> : <Point />}
    <Dot />
    <Dot />
    <Dot style={{ marginBottom: '10px' }} />
    {isEnd ? <EnvironmentOutlined /> : <Point />}
  </>
);

interface IEditTransportPlanProps {
  originalData: ITransportPlanChanges[];
  onSearchLocation: (searchValue: string, id: number, type: string) => void
  onSetLocation: (code: string, id: number, type: string) => void
  onSetDate: (date: Moment, id: number, type: string) => void
  onSetMode: (mode: string, id: number) => void
  onSetVoyage: (voyage: string, id: number, index: number) => void
  onSetVessel: (vessel: string, id: number, index: number) => void
  onClose: () => void
  onUpdate: (id: string) => void
  isLoadingUpdate: boolean
  isSuccess: boolean
  schedules: {
    terminalCutOff: string
    vgmCutOff: string
    documentCutOff: string
  }
  onChangeSchedule: (key: string, value: Moment) => void
  errorTerminal: boolean
  voyageErrorIndexes: number[]
  vesselErrorIndexes: number[]
  isDisabledUpdate: boolean
  isChangedPortCutoff: boolean
}

const disabledTypes: string[] = [ETransportationType.RAIL, ETransportationType.ROAD];
// add enum ETransportationType above

const EditTransportPlanComponent: FC<IEditTransportPlanProps> = ({
  originalData, onSearchLocation, onSetLocation, onSetDate, schedules,
  onSetMode, onSetVessel, onSetVoyage, onClose, onUpdate, isLoadingUpdate, isSuccess, onChangeSchedule, errorTerminal,
  voyageErrorIndexes, vesselErrorIndexes, isDisabledUpdate, isChangedPortCutoff,
}) => {
  const { shipmentId } = useParams<'shipmentId'>();

  useEffect(() => {
    if (isSuccess) {
      onClose();
    }
  }, [isSuccess]);

  const { t } = useTranslation();

  const handleUpdate = useCallback(() => {
    if (shipmentId) {
      onUpdate(shipmentId);
    }
  }, []);

  const handleSetLocation = useCallback((code, id: number, type: string) => {
    onSetLocation(code, id, type);
  }, []);

  const handleSearchLocation = useCallback((searchValue: string, id: number, type: string) => {
    onSearchLocation(searchValue, id, type);
  }, []);

  const handleSetDate = useCallback((date, id: number, type: string) => {
    onSetDate(date, id, type);
  }, []);

  const handleSetVessel = useCallback((vessel, id: number, index: number) => {
    onSetVessel(vessel, id, index);
  }, []);

  const handleSetVoyage = useCallback((voyage, id: number, index: number) => {
    onSetVoyage(voyage, id, index);
  }, []);

  const handleSetMode = useCallback((mode, id: number, index: number) => {
    if (disabledTypes.includes(mode)) {
      handleSetVessel('', id, index);
      handleSetVoyage('', id, index);
    }
    onSetMode(mode, id);
  }, []);

  const handleUpdateScheduleTerminal = useCallback((value) => {
    onChangeSchedule(TERMINAL_CUTOFF, value);
  }, []);

  const handleUpdateScheduleDocument = useCallback((value) => {
    onChangeSchedule(DOCUMENT_CUTOFF, value);
  }, []);

  const handleUpdateScheduleVGM = useCallback((value) => {
    onChangeSchedule(VGM_CUTOFF, value);
  }, []);

  return (
    <div style={{ position: 'relative', height: '100%' }}>
      <Wrapper>
        <CutoffWrapper>
          <CutoffTitle>{t('Cutoffs')}</CutoffTitle>
          <CutoffTime>
            <CutoffElem>
              <Label>{t('portCutOff')}</Label>
              <StyledDatePicker
                style={{ borderColor: errorTerminal ? 'red' : '' }}
                showTime
                value={moment(schedules.terminalCutOff)}
                format={timeFormat}
                onChange={handleUpdateScheduleTerminal}
                allowClear={false}
              />
            </CutoffElem>
            <CutoffElem>
              <Label>{t('documentation')}</Label>
              <StyledDatePicker
                showTime
                value={moment(schedules.documentCutOff)}
                format={timeFormat}
                onChange={handleUpdateScheduleDocument}
                allowClear={false}
              />
            </CutoffElem>
            <CutoffElem>
              <Label>{t('VGM')}</Label>
              <StyledDatePicker
                showTime
                value={moment(schedules.vgmCutOff)}
                format={timeFormat}
                onChange={handleUpdateScheduleVGM}
                allowClear={false}
              />
            </CutoffElem>
          </CutoffTime>
        </CutoffWrapper>
        {isChangedPortCutoff && <Validate typeDrawer={UPDATE_TRANSPORT_PLAN} />}
        {originalData.map((item, index) => {
          const isLastElem = index === originalData.length - 1;
          return (
            <Fragment key={item.id}>
              <ElemWrapper>
                <Main>
                  <RouteWrapper>
                    {DotsRender(!index, isLastElem)}
                  </RouteWrapper>
                  <LocationWrapper>
                    <InputWrapper>
                      <Label>{t('departure')}</Label>
                      <StyledSelect
                        showSearch
                        disabled={false}
                        showArrow={false}
                        filterOption={false}
                        options={
                          item.locationList.map((elem) => ({
                            label: elem.description || '',
                            value: elem.code || '',
                          }))
                        }
                        value={item.location}
                        onSearch={(val) => handleSearchLocation(val, item.id, 'origin')}
                        onChange={(code) => handleSetLocation(code, item.id, 'origin')}
                        notFoundContent={(
                          <div>
                            {item.locationLoading && (<Spin size="small" />)}
                            {!item.locationList.length && !item.locationLoading && (
                              <Typography.Text>
                                {t('Validations.string.noResults')}
                              </Typography.Text>
                            )}
                          </div>
                        )}
                      />
                    </InputWrapper>
                    <InputWrapper>
                      <Label>{t('arrival')}</Label>
                      <StyledSelect
                        showSearch
                        disabled={false}
                        showArrow={false}
                        filterOption={false}
                        options={
                          item.nextLocationList.map((elem) => ({
                            label: elem.description || '',
                            value: elem.code || '',
                          }))
                        }
                        value={item.nextLocation}
                        onSearch={(val) => handleSearchLocation(val, item.id, 'destination')}
                        onChange={(code) => handleSetLocation(code, item.id, 'destination')}
                        notFoundContent={(
                          <div>
                            {item.nextLocationLoading && (<Spin size="small" />)}
                            {!item.nextLocationList.length && !item.nextLocationLoading && (
                              <Typography.Text>
                                {t('Validations.string.noResults')}
                              </Typography.Text>
                            )}
                          </div>
                        )}
                      />
                    </InputWrapper>
                    <InputWrapper>
                      <Label>{t('etd')}</Label>
                      <StyledDatePicker
                        showTime
                        value={item.etdTime}
                        format={timeFormat}
                        onChange={(date) => handleSetDate(date, item.id, 'etd')}
                      />
                    </InputWrapper>
                    <InputWrapper>
                      <Label>{t('eta')}</Label>
                      <StyledDatePicker
                        showTime
                        value={item.etaTime}
                        format={timeFormat}
                        onChange={(date) => handleSetDate(date, item.id, 'eta')}
                      />
                    </InputWrapper>
                  </LocationWrapper>
                  <DashedBorder />
                  <ModeWrapper>
                    <InputWrapper style={{ width: '100%' }}>
                      <Label>{t('mode')}</Label>
                      <StyledSelect
                        key={item.id}
                        value={item.type}
                        onChange={(mode) => handleSetMode(mode, item.id, index)}
                        options={RoadTypes}
                      />
                    </InputWrapper>
                    <VoyageWrapper>
                      <InputWrapper>
                        <Label>{t('vessel')}</Label>
                        <Input
                          style={{ borderColor: vesselErrorIndexes.includes(index) ? 'red' : '' }}
                          value={item.vessel}
                          disabled={disabledTypes.includes(item.type)}
                          onChange={(e) => handleSetVessel(e.target.value, item.id, index)}
                        />
                      </InputWrapper>
                      <InputWrapper>
                        <Label>{t('voyage')}</Label>
                        <Input
                          style={{ borderColor: voyageErrorIndexes.includes(index) ? 'red' : '' }}
                          value={item.voyage}
                          disabled={disabledTypes.includes(item.type)}
                          onChange={(e) => handleSetVoyage(e.target.value, item.id, index)}
                        />
                      </InputWrapper>
                    </VoyageWrapper>
                  </ModeWrapper>
                </Main>
              </ElemWrapper>
              {((index !== originalData.length - 1) || !index) && <Divider />}
            </Fragment>
          );
        })}
      </Wrapper>
      <FooterWrapper>
        <Button
          type="default"
          onClick={onClose}
          size="large"
        >
          {t('Cancel')}
        </Button>
        <Button
          htmlType="submit"
          size="large"
          style={{ marginLeft: '12px' }}
          onClick={handleUpdate}
          loading={isLoadingUpdate}
          disabled={isDisabledUpdate}
        >
          {t('Update')}
        </Button>
      </FooterWrapper>
    </div>
  );
};

export { EditTransportPlanComponent };
