import React from 'react';
import { useSelector } from 'react-redux';

import { UC } from 'user-management/controllers';
import { R } from 'user-management/repository';

import { CustomerComponent } from './Customer.component';

const CustomerContainer = () => {
  const isLoading = useSelector(R.selectors.customer.getLoading);
  const organization = useSelector(R.selectors.customer.getOrganization);
  const isAdmin = useSelector(R.selectors.customer.getIsOrganizationAdmin);
  const hasRequestedAdditionalInformation = useSelector(R.selectors.customer.getHasRequestedAdditionalInfo);
  const reviewNote = useSelector(R.selectors.customer.getReviewNote);
  const reviewAction = useSelector(R.selectors.customer.getReviewAction);
  const permisssions = useSelector(R.selectors.moduleFunctionalityPermissions.getCustomerOrganizationProfilePermissions);

  return (
    <CustomerComponent
      isLoading={isLoading}
      isAdmin={isAdmin}
      organizationStatus={organization?.status}
      initData={UC.customer.initData}
      onApprove={UC.customer.approveOrganization}
      onReject={UC.customer.openRejectModal}
      onRequestAdditionalInfo={UC.customer.openAdditionalInformationModal}
      customerSalesAndMarkupPermissions={permisssions?.customerSalesAndMarkupPermissions}
      hasRequestedAdditionalInfo={hasRequestedAdditionalInformation}
      reviewNote={reviewNote}
      reviewAction={reviewAction}
    />
  );
};

export { CustomerContainer as Customer };
