import React, { FC, useEffect } from 'react';
import { Outlet, useParams } from 'react-router';
import { useTranslation } from 'react-i18next';

import {
  Wrapper,
  SubPageHeader,
  SubPageHeaderContainer,
  SubPageHeaderTitle,
} from 'app-wrapper/view/guideline';

import { ShipmentDocumentsTabs } from './components';

interface IShipmentTransportationComponentProps {
  fetchHasHazmat: (shipmentId: string) => void;
  fetchSeaworthyCertificates: (shipmentId: string) => void;
  hasHazmat: boolean;
  hasSOC: boolean;
  cleanup: () => void;
}

export const ShipmentDocumentsComponent: FC<IShipmentTransportationComponentProps> = ({
  fetchHasHazmat,
  fetchSeaworthyCertificates,
  hasHazmat,
  cleanup,
  hasSOC,
}) => {
  const { t } = useTranslation();
  const { shipmentId = '' } = useParams();

  useEffect(() => {
    fetchHasHazmat(shipmentId);
    fetchSeaworthyCertificates(shipmentId);

    return () => {
      cleanup();
    };
  }, []);

  return (
    <Wrapper>
      <SubPageHeader>
        <SubPageHeaderContainer>
          <SubPageHeaderTitle>
            {t('Documents')}
          </SubPageHeaderTitle>
        </SubPageHeaderContainer>
      </SubPageHeader>

      <ShipmentDocumentsTabs
        hasHazmat={hasHazmat}
        hasSOC={hasSOC}
      />
      <Outlet />
    </Wrapper>
  );
};
