import * as yup from 'yup';
import i18n from 'i18next';

yup.addMethod(yup.array, 'dateRange', function dateRange(property) {
  return this.test(
    'dateRange',
    i18n.t('Validations.OverviewPage.selectOrigin'),
    function dateRangeTest(value, context) {
      const form = context?.options.context?.form.current;
      const { isSubmitted, isSubmitting } = form.formState;
      const firstDateState = form.getFieldState(context.path);
      const secondDateState = form.getFieldState(property);
      return !(((firstDateState.isTouched
          && secondDateState.isTouched)
        || isSubmitted || isSubmitting)
      && value?.length === 0
      && this.parent[property].length === 0);
    },
  );
});

declare module 'yup' {
  interface ArraySchema<T> {
    dateRange(
      property?: string, mapper?: T
    ): ArraySchema<T>;
  }
}
