import { Factory } from 'fishery';

import { RFRQuotasDTM } from './RFQServiceById';
import { FreightQuotaPageableDTM } from '../../Freight';

export const RFRQuotasDTMFactoryEmpty = Factory.define<RFRQuotasDTM>(() => RFRQuotasDTM.fromPlain({
  content: [],
  pageable: FreightQuotaPageableDTM.fromPlain({}),
  last: false,
  totalPages: 0,
  totalElements: 0,
  size: 0,
  number: 0,
  sort: {
    empty: false,
    sorted: false,
    unsorted: false,
  },
  first: false,
  numberOfElements: 0,
  empty: false,
}));
