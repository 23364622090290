import { createSelector } from 'reselect';

import { RootState } from 'app-wrapper/store';
import { TransportPlanMissMatchDTM } from 'shipment-operations/models/dtm';

const localState = (state: RootState) => state.shipmentMissMatches;

export const getTransportPlanMissMatches = createSelector(
  localState,
  (state) => state.transportPlanMissMatches,
);

export const getTransportPlanId = createSelector(
  localState,
  (state) => state.transportPlanBookingId,
);

export const getTransportPlanMissMatchesToRender = createSelector(
  localState,
  (state) => TransportPlanMissMatchDTM.convertToRenderTypeMissMatches(state.transportPlanMissMatches),
);

export const getCargoMissMatchesGroups = createSelector(
  localState,
  (state) => state.cargoMissMatchesGroups,
);

export const getContainerMissMatchesGroups = createSelector(
  localState,
  (state) => state.containerMissMatchesGroups,
);

export const getBookingId = createSelector(
  localState,
  (state) => state.bookingId,
);

export const getAreThereAnyCargoMissMatches = createSelector(
  localState,
  (state) => Boolean(state.cargoMissMatchesGroups.length),
);

export const getAreThereAnyContainersMissMatches = createSelector(
  localState,
  (state) => Boolean(state.containerMissMatchesGroups.length),
);

export const getCorrectionsText = createSelector(
  localState,
  (state) => state.correctionsText,
);

export const getIsThereAnyMissMatches = createSelector(
  localState,
  (state) => !!state.transportPlanMissMatches.length || !!state.cargoMissMatchesGroups.length || !!state.containerMissMatchesGroups.length,
);

export const shipmentMissMatchesSelectors = {
  getTransportPlanMissMatches,
  getTransportPlanMissMatchesToRender,
  getTransportPlanId,
  getCargoMissMatchesGroups,
  getContainerMissMatchesGroups,
  getBookingId,
  getAreThereAnyCargoMissMatches,
  getAreThereAnyContainersMissMatches,
  getCorrectionsText,
  getIsThereAnyMissMatches,
};
