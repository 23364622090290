import React, {
  FC, memo, useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';

import { REQUEST_STATUS } from 'app-wrapper/constants';
import { EditPanSvg, PlusSvg } from 'app-wrapper/view/icons';
import { Tooltip, TableSkeleton } from 'app-wrapper/view/components';

import { CompanyAddressDTM } from 'user-management/models/dtm';

import {
  AACContentTableContentLink,
  AACContentTableContentItem,
  AACContentTableContentItemTop,
  AACContentTableContentTitle,
  AACContentTableHeader,
  AACContentTableHeaderItem,
  AACSpanEllipsis,
  AACContentTableTitle,
  AACContentTableTitleWrap,
  AACContentTableHeaderAddBtn,
  AACContentTableContact,
  AACContentTableContactWrap,
  AACContentTableContentPrimary,
} from './AACContentTable.styled';

interface IAACTableProps {
  onStart: () => void;
  nextPageTasks: () => void;
  onClickAddressItem: (id: string) => () => void;
  onClickAddAddress: () => void;
  onClickEditAddress: (id: string) => () => void;
  isLoad?: boolean;
  isLoadAdd?: string;
  allData?: CompanyAddressDTM[];
  isEnd?: boolean;
}

const AACTable: FC<IAACTableProps> = (props) => {
  const {
    onStart,
    nextPageTasks,
    onClickAddressItem,
    onClickAddAddress,
    onClickEditAddress,
    isLoad,
    isLoadAdd,
    allData,
    isEnd,
  } = props;

  const { t } = useTranslation();

  useEffect(() => {
    onStart();
  }, []);

  return (
    <>
      <AACContentTableTitleWrap>
        <AACContentTableTitle>{t('Addresses')}</AACContentTableTitle>

        <AACContentTableHeaderAddBtn
          onClick={onClickAddAddress}
        >
          <PlusSvg />
          <span>{t('AddAddress')}</span>
        </AACContentTableHeaderAddBtn>
      </AACContentTableTitleWrap>

      <AACContentTableHeader>
        <AACContentTableHeaderItem
          width="42.7%"
        >
          {t('Address')}
        </AACContentTableHeaderItem>
        <AACContentTableHeaderItem
          width="17.8%"
        >
          {t('Country')}
        </AACContentTableHeaderItem>
        <AACContentTableHeaderItem
          width="17.8%"
        >
          {t('City')}
        </AACContentTableHeaderItem>
        <AACContentTableHeaderItem
          width="17.8%"
          isCenter
        >
          {t('Contact')}
        </AACContentTableHeaderItem>
      </AACContentTableHeader>

      <>
        <InfiniteScroll
          dataLength={Number(allData?.length || 0)}
          next={nextPageTasks}
          hasMore={!isEnd}
          loader={
            isLoad
            && (<TableSkeleton />)
          }
          scrollableTarget="Wrapper"
        >
          {allData?.map((item) => (
            <AACContentTableContentItem key={`AACContentTableContentItem_${item.id}`}>
              <AACContentTableContentItemTop>
                <AACContentTableContentLink
                  onClick={onClickAddressItem(`${item.id || ''}`)}
                >
                  <AACSpanEllipsis>
                    {item.getBothAddressLine()}
                  </AACSpanEllipsis>
                  {item.isPrimary && (<AACContentTableContentPrimary>{t('Primary')}</AACContentTableContentPrimary>)}
                </AACContentTableContentLink>
                <AACContentTableContentTitle isLeft>
                  <AACSpanEllipsis>
                    {item.country}
                  </AACSpanEllipsis>
                </AACContentTableContentTitle>
                <AACContentTableContentTitle isLeft>
                  <AACSpanEllipsis>
                    {item?.city || ''}
                  </AACSpanEllipsis>
                </AACContentTableContentTitle>
                <AACContentTableContactWrap>
                  <AACContentTableContact>
                    <Tooltip
                      isGetPopupContainerDefault
                      title={(
                        <>
                          {item?.primaryContact?.fullName || ''}<br />
                          {item?.primaryContact?.email || ''}<br />
                          {item?.primaryContact?.phone || ''}<br />
                        </>
                      )}
                      placement="top"
                    >
                      {!!item?.primaryContact?.fullName && item?.primaryContact?.fullName.slice(0, 1).toLocaleUpperCase()}
                    </Tooltip>
                  </AACContentTableContact>
                </AACContentTableContactWrap>
                <AACContentTableContentLink
                  width="3.9%"
                  onClick={onClickEditAddress(item.customId || '')}
                >
                  <EditPanSvg />
                </AACContentTableContentLink>
              </AACContentTableContentItemTop>

            </AACContentTableContentItem>
          ))}
        </InfiniteScroll>
        {isLoadAdd === REQUEST_STATUS.pending && (<TableSkeleton />)}
      </>
    </>
  );
};

const AACTableCache = memo(AACTable);

export { AACTableCache as AACTable };
