import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { CargoDTM, ShareInvoiceShipmentDetailsDTM } from 'shipment-operations/models/dtm';

import {
  Wrap,
  Table,
  Title,
  TableCell,
  TableCellBold,
  Containers,
  ContainerItem,
  ContainerItemTitle,
  ContainerItemSubtitle,
} from './PDFCargoAndContainers.styled';

interface InvoicePDFChargesComponentProps {
  cargos: CargoDTM[];
  containersItems: ShareInvoiceShipmentDetailsDTM[];
}

export const PDFCargoAndContainersComponent: FC<InvoicePDFChargesComponentProps> = ({
  cargos,
  containersItems,
}) => {
  const { t } = useTranslation();
  const [containersTypes, containersAmount] = containersItems;

  const columns = useMemo(() => [
    {
      title: t('Goods Description'),
      width: '40%',
      key: 'object',
      align: 'left' as 'left',
      render: (item: string, record: CargoDTM | {}) => {
        const cargo = record as CargoDTM;

        return (
          <TableCellBold>
            {cargo.description || cargo.name}
          </TableCellBold>
        );
      },
    },
    {
      title: t('PCS '),
      width: '15%',
      key: 'object',
      align: 'right' as 'right',
      render: (item: string, record: CargoDTM | {}) => {
        const cargo = record as CargoDTM;

        return (
          <TableCell>
            {cargo.packagesNumber}
          </TableCell>
        );
      },
    },
    {
      title: t('Net Weight, kg'),
      width: '15%',
      key: 'object',
      align: 'right' as 'right',
      render: (item: string, record: CargoDTM | {}) => {
        const cargo = record as CargoDTM;

        return (
          <TableCell>
            {cargo.weight}
          </TableCell>
        );
      },
    },
    {
      title: t('Gross Weight, kg '),
      width: '15%',
      key: 'object',
      align: 'right' as 'right',
      render: (item: string, record: CargoDTM | {}) => {
        const cargo = record as CargoDTM;

        return (
          <TableCell>
            {cargo.weight}
          </TableCell>
        );
      },
    },
    {
      title: t('Volume, cbm'),
      width: '15%',
      key: 'object',
      align: 'right' as 'right',
      render: (item: string, record: CargoDTM | {}) => {
        const cargo = record as CargoDTM;

        return (
          <TableCell>
            {cargo.volume || 0}
          </TableCell>
        );
      },
    },
  ], [t]);

  return (
    <Wrap>
      <Title>
        {t('Cargo and Containers')}
      </Title>

      <Table
        dataSource={cargos}
        columns={columns}
        pagination={false}
      />

      <Containers>
        <ContainerItem>
          <ContainerItemTitle>
            {containersTypes ? containersTypes.title : ''}
          </ContainerItemTitle>

          <ContainerItemSubtitle>
            {containersTypes ? containersTypes.subtitles : ''}
          </ContainerItemSubtitle>
        </ContainerItem>

        <ContainerItem>
          <ContainerItemTitle>
            {containersAmount ? containersAmount.title : ''}
          </ContainerItemTitle>

          <ContainerItemSubtitle>
            {containersAmount ? containersAmount.subtitles : ''}
          </ContainerItemSubtitle>
        </ContainerItem>
      </Containers>
    </Wrap>
  );
};
