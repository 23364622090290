import styled from 'styled-components';
import Tag from 'antd/es/tag';

import { colors } from 'app-wrapper/view/themes/themesColors';

export const CarrierCardReferenceTag = styled(Tag)`
  height: 22px !important;

  background: ${colors.gray35};
  border-color: ${colors.purple15};

  overflow: hidden;
  text-overflow: ellipsis;
`;
