import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { UC } from 'shipment-operations/controllers';
import { R } from 'shipment-operations/repository';

import { ShipmentListComponent } from './ShipmentList.component';

export const ShipmentListContainer: FC = () => {
  const list = useSelector(R.selectors.shipmentList.getList);
  const isLoading = useSelector(R.selectors.shipmentList.getLoadingState);
  const areFiltersLoading = useSelector(R.selectors.shipmentList.getLoadingFiltersState);
  const wasLastResponseEmpty = useSelector(R.selectors.shipmentList.getWasLastResponseEmpty);
  const searchValue = useSelector(R.selectors.shipmentList.getSearchValue);

  const isClearAllVisible = useSelector(R.selectors.shipmentList.getIsClearAllVisible);

  return (
    <ShipmentListComponent
      list={list}
      isLoading={isLoading || areFiltersLoading}
      wasLastResponseEmpty={wasLastResponseEmpty}
      isClearAllVisible={isClearAllVisible}
      searchValue={searchValue}
      isClearSearch={!!searchValue && !list.length}
      onStartLoad={UC.shipmentList.onStartLoadShipmentListPage}
      loadMoreDataHandler={UC.shipmentList.loadNextShipmentListPage}
      clearHandler={UC.shipmentList.clearList}
      onClickGetNewQuote={UC.shipmentList.onClickGetNewQuote}
      clearAllFilters={UC.shipmentList.clearAllFilters}
      onClickToCustomer={UC.shipmentList.onClickToCustomer}
    />
  );
};

export { ShipmentListContainer as ShipmentList };
