import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'app-wrapper/createSlice';

import { ValidationErrorDTM } from 'app-wrapper/types';

import { AirVentUnitsType } from 'shipment-operations/constants';
import { TemperatureControlDTM } from 'shipment-operations/models/dtm';
import { AirVentDTM } from 'shipment-operations/models/dtm/AirVent.dtm';
import { ITemperatureControlState } from 'shipment-operations/models/states';

const initialState: ITemperatureControlState = {
  isLoading: false,
  temperatureControl: TemperatureControlDTM.fromPlain({
    genset: false,
    airVent: AirVentDTM.fromPlain({
      flowRate: undefined,
      flowRateUnit: undefined,
    }),
    temperature: undefined,
  }),
  initialState: TemperatureControlDTM.fromPlain({
    genset: false,
    airVent: AirVentDTM.fromPlain({
      flowRate: undefined,
      flowRateUnit: undefined,
    }),
    temperature: undefined,
  }),
  errors: {},
  touchedFields: {},
  wasUpdateAttempted: false,
  charges: [],
};

export const temperatureControlStore = createSlice({
  name: 'temperatureControl',
  initialState,
  reducers: {
    setTemperatureControlData: (state, action: PayloadAction<TemperatureControlDTM>) => {
      state.temperatureControl = action.payload;
    },
    setGenset: (state, action: PayloadAction<boolean>) => {
      state.temperatureControl = TemperatureControlDTM.fromPlain({
        ...state.temperatureControl,
        genset: action.payload,
      });
    },
    setTemperature: (state, action: PayloadAction<{ error?: ValidationErrorDTM, temperature?: string }>) => {
      const { error, temperature } = action.payload;

      state.temperatureControl = TemperatureControlDTM.fromPlain({
        ...state.temperatureControl,
        temperature,
      });

      state.errors = {
        ...state.errors,
        temperature: error,
      };
    },
    setFlowRate: (state, action: PayloadAction<string | undefined>) => {
      state.temperatureControl.airVent.flowRate = action.payload;

      state.temperatureControl = TemperatureControlDTM.fromPlain({
        ...state.temperatureControl,
        airVent: AirVentDTM.fromPlain({
          ...state.temperatureControl.airVent,
          flowRate: action.payload,
        }),
      });
    },
    setFlowRateUnit: (state, action: PayloadAction<AirVentUnitsType | undefined>) => {
      state.temperatureControl = TemperatureControlDTM.fromPlain({
        ...state.temperatureControl,
        airVent: AirVentDTM.fromPlain({
          ...state.temperatureControl.airVent,
          flowRateUnit: action.payload,
        }),
      });
    },
    setAirVentErrors: (state, action: PayloadAction<{ flowRate?: ValidationErrorDTM, flowRateUnit?: ValidationErrorDTM }>) => {
      const { flowRate, flowRateUnit } = action.payload;

      state.errors = {
        ...state.errors,
        flowRate,
        flowRateUnit,
      };
    },
    setTemperatureErrors: (state, action: PayloadAction<{ temperature?: ValidationErrorDTM }>) => {
      const { temperature } = action.payload;

      state.errors = {
        ...state.errors,
        temperature,
      };
    },
    touchField: (state, action: PayloadAction<string>) => {
      state.touchedFields = {
        ...state.touchedFields,
        [action.payload]: true,
      };
    },
    resetTouchedFields: (state) => {
      state.touchedFields = {};
    },
    resetErrors: (state) => {
      state.errors = {};
    },
    setInitialState: (state, action: PayloadAction<TemperatureControlDTM>) => {
      state.initialState = {
        ...action.payload,
      };
    },
    setUpdateAttemptStatus: (state, action: PayloadAction<boolean>) => {
      state.wasUpdateAttempted = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    clearWithNewFieldsState: (state, action: PayloadAction<TemperatureControlDTM>) => {
      state.isLoading = initialState.isLoading;
      state.temperatureControl = {
        ...action.payload,
      };
      state.initialState = {
        ...action.payload,
      };
      state.errors = {
        ...initialState.errors,
      };
      state.touchedFields = {
        ...initialState.touchedFields,
      };
      state.wasUpdateAttempted = initialState.wasUpdateAttempted;
    },
    setCharges: (state, action) => {
      state.charges = action.payload;
    },
    clear: () => (initialState),
  },
});

export const temperatureControlActions = temperatureControlStore.actions;
export const temperatureControlReducer = temperatureControlStore.reducer;
