import React from 'react';
import { useSelector } from 'react-redux';

import { R } from 'user-management/repository';

import { UserContactsComponent } from 'user-management/view/pages/UserProfile/components/UserContacts/UserContacts.component';

export const Contacts = () => {
  const user = useSelector(R.selectors.userManagement.getUserProfile);

  return user ? (
    <UserContactsComponent email={user.email} phone={user.phone} />
  ) : null;
};
