export const elementInViewport = (element: HTMLDivElement | null) => {
  if (!element) return '';
  const newCurrent = element;
  const top = newCurrent.offsetTop;
  const left = newCurrent.offsetLeft;
  const width = newCurrent.offsetWidth;
  const height = newCurrent.offsetHeight;

  return (
    top >= window.pageYOffset
    && left >= window.pageXOffset
    && (top + height) <= (window.pageYOffset + window.innerHeight)
    && (left + width) <= (window.pageXOffset + window.innerWidth)
  );
};
