import styled from 'styled-components';

import { colors } from 'app-wrapper/view/themes/themesColors';
import { Collapse, Panel } from 'app-wrapper/view/components/Collapse';

export const CargoFormHazmatContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-items: left;
  gap: 16px;
`;

export const CargoFormHazmatCollapse = styled(Collapse)`
  & .ant-collapse-item-active {
    > .ant-collapse-header {
      color: ${colors.purple60} !important;
    }
  }
  > * {
    > .ant-collapse-header {
      padding: 0 !important;
    }
    > .ant-collapse-content {
      > .ant-collapse-content-box {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
`;

export const CargoFormHazmatPanel = styled(Panel)`
  font-size: 14px;
`;
