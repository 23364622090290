import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { RcFile, UploadProps } from 'antd/es/upload/interface';
import AntdUploadExports from 'antd/es/upload';
import DeleteOutlined from '@ant-design/icons/DeleteOutlined';
import UploadOutlined from '@ant-design/icons/UploadOutlined';

import { useResponsiveSize } from 'app-wrapper/hooks';
import { showWarning } from 'app-wrapper/utils/showWarning';
import { addPrefix } from 'app-wrapper/utils';
import { ContainerDocumentDTM } from 'shipment-operations/models/dtm';

import { Upload, Button } from './UploadPaymentDocument.styled';

const MAX_FILE_SIZE = 1024 * 1024 * 10; // 10MB
const { LIST_IGNORE } = AntdUploadExports;

interface UploadPaymentDocumentComponentProps {
  authToken: string
  accountId: number;
  setContainerDocument: (document: ContainerDocumentDTM | null) => void
  downloadDocument: (accountId: number, documentId: number, documentName: string) => void;
  error: boolean;
  disabled?: boolean;
  documents: ContainerDocumentDTM[];
  addDocument: (document: ContainerDocumentDTM) => void;
  deleteDocument: (documentId: string) => void;
}

export const UploadPaymentDocumentComponent: FC<UploadPaymentDocumentComponentProps> = ({
  authToken,
  setContainerDocument,
  accountId,
  error,
  disabled = false,
  documents,
  downloadDocument,
  addDocument,
  deleteDocument,
}) => {
  const { t } = useTranslation();
  const size = useResponsiveSize();

  const setDocument = useCallback((value: ContainerDocumentDTM[]) => {
    setContainerDocument(value[0] || null);
  }, [setContainerDocument]);

  const handleBeforeUpload = useCallback((file: RcFile) => {
    const isAcceptableSize = file.size < MAX_FILE_SIZE;
    if (!isAcceptableSize) {
      showWarning({ message: t('File is too large'), duration: 4 });
    }
    return isAcceptableSize || LIST_IGNORE;
  }, []);

  const handleChange: UploadProps['onChange'] = useCallback(({ file }) => {
    if (file.status === 'removed') {
      setDocument([]);
      return;
    }

    if (file.response) {
      file.url = '/';
    }

    if (file.response && file.status === 'error') {
      file.error = new Error(file.response?.message);
      file.response = file.response.message ? file.response.message : {};
    }

    addDocument(ContainerDocumentDTM.fromPlain({
      name: file.name,
      url: file.url,
      uid: file.uid,
      response: file.response,
      status: file.status,
    }));
  }, [addDocument]);

  const handleRemove: UploadProps['onRemove'] = useCallback((file) => {
    deleteDocument(file.uid);
  }, [deleteDocument]);

  const handlePreview: UploadProps['onPreview'] = useCallback((file) => {
    if (!file.status) {
      return;
    }

    downloadDocument(accountId, file.response.id, file.name);
  }, [downloadDocument, accountId]);

  const postUrl = addPrefix(`/billing-service/api/v1/accounts/${accountId}/documents`);

  return (
    <Upload
      multiple={false}
      showUploadList={{ showRemoveIcon: true, removeIcon: <DeleteOutlined /> }}
      beforeUpload={handleBeforeUpload}
      action={postUrl}
      headers={{ Authorization: authToken }}
      fileList={documents}
      onChange={handleChange}
      onRemove={handleRemove}
      onPreview={handlePreview}
      disabled={disabled}
    >
      <Button
        disabled={disabled}
        icon={<UploadOutlined />}
        type="dashed"
        size={size}
        hasError={error}
      >
        {t('Upload document')}
      </Button>
    </Upload>
  );
};
