import React from 'react';
import { useSelector } from 'react-redux';
import { observer } from 'mobx-react-lite';

import { R } from 'app-wrapper/repository';
import { UC } from 'app-wrapper/controllers';

import { VGMTaskDrawerComponent } from './VGMTaskDrawer.component';

const VGMTaskDrawerContainer = observer(({ shipmentId }: { shipmentId: string }) => {
  const isLoading = useSelector(R.selectors.vgmTaskDrawer.getIsLoading);
  const shipment = useSelector(R.selectors.vgmTaskDrawer.getShipment);
  const containers = useSelector(R.selectors.vgmTaskDrawer.getContainers);
  const markedContainers = useSelector(R.selectors.vgmTaskDrawer.getMarkedContainers);
  const isAbleToAccept = useSelector(R.selectors.vgmTaskDrawer.getIsAbleToAccept);
  const dueDate = useSelector(R.selectors.vgmTaskDrawer.getDueDate);

  return (
    <VGMTaskDrawerComponent
      isLoading={isLoading}
      onClose={UC.vgmTaskDrawer.closeVGMDrawer}
      onAccept={UC.vgmTaskDrawer.acceptVGM}
      shipmentId={shipmentId}
      init={UC.vgmTaskDrawer.init}
      shipment={shipment}
      containers={containers}
      markedContainers={markedContainers}
      markAllContainers={UC.vgmTaskDrawer.markAllContainers}
      markContainer={UC.vgmTaskDrawer.markContainer}
      isAbleToAccept={isAbleToAccept}
      dueDate={dueDate}
    />
  );
});

export { VGMTaskDrawerContainer as VGMTaskDrawer };
