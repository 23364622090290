import React, { FC, memo } from 'react';
import { useSelector } from 'react-redux';

import { UC } from 'user-management/controllers';
import { R } from 'user-management/repository';

import { PartnersComponent } from './PartnersList.component';

const PartnersListContainer: FC = () => {
  const isLoading = useSelector(R.selectors.partners.getLoading);
  const partners = useSelector(R.selectors.partners.getPartners);
  const isPaginationEnded = useSelector(R.selectors.partners.getIsPaginationEnded);

  return (
    <PartnersComponent
      partners={partners}
      getPartners={UC.organizationProfile.getPartnersPageInfo}
      goToOrganization={UC.organizationProfile.goToPartnerOrganization}
      paginatePartners={UC.organizationProfile.paginatePartners}
      clearPartners={UC.organizationProfile.clearPartnersPage}
      isPaginationEnded={isPaginationEnded}
      isLoading={isLoading}
    />
  );
};

const PartnersContainerCache = memo(PartnersListContainer);

export { PartnersContainerCache as PartnersListPage };
