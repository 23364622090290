import { IsNumber, IsString, IsDefined } from 'class-validator';
import { BaseDTM } from 'proto/BaseDTM';

export interface IContainerViewDTM {
  number: string
  type: string
  id: number
}

export class ContainerViewDTM extends BaseDTM<IContainerViewDTM> {
  @IsString()
  @IsDefined()
  number: string;

  @IsString()
  @IsDefined()
  type: string;

  @IsNumber()
  @IsDefined()
  id: number;
}
