import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { R } from 'user-management/repository';
import { UC } from 'user-management/controllers';

import { UserInviteComponent } from './UserInvite.component';

const UserInviteContainer: FC = () => {
  const isLoading = useSelector(R.selectors.userInvite.getLoading);
  const wasAnyFormFieldChanged = useSelector(R.selectors.userInvite.wasAnyFormFieldChanged);
  const email = useSelector(R.selectors.userInvite.getEmail);
  const emailError = useSelector(R.selectors.userInvite.getEmailError);
  const role = useSelector(R.selectors.userInvite.getRole);
  const roleError = useSelector(R.selectors.userInvite.getRoleError);
  const departments = useSelector(R.selectors.userInvite.getDepartments);
  const departmentsError = useSelector(R.selectors.userInvite.getDepartmentsError);
  const availableDepartments = useSelector(R.selectors.userInvite.getAvailableDepartments);
  const jobTitle = useSelector(R.selectors.userInvite.getJobTitle);
  const permissions = useSelector(R.selectors.moduleFunctionalityPermissions.getUserManagementProfilePermissions);

  return (
    <UserInviteComponent
      isLoading={isLoading}
      wasAnyFormFieldChanged={wasAnyFormFieldChanged}
      emailValue={email}
      emailError={emailError}
      emailChange={UC.userInvite.setEmail}
      emailBlur={UC.userInvite.onBlurEmail}
      departments={departments}
      departmentsError={departmentsError}
      availableDepartments={availableDepartments}
      setDepartments={UC.userInvite.setDepartments}
      roleValue={role}
      roleError={roleError}
      jobTitle={jobTitle}
      setJobTitle={UC.userInvite.setJobTitle}
      roleChange={UC.userInvite.setRole}
      roleBlur={UC.userInvite.onBlurRole}
      onOk={UC.userInvite.sendInvite}
      onCancel={UC.userInvite.closeUserInviteDrawer}
      userDepartmentsAndTeamsEditPermissions={permissions?.userDepartmentsAndTeamsEdit}
    />
  );
};

export { UserInviteContainer as UserInvite };
