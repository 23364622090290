import { GeoParsedAddress } from 'app-wrapper/types/Address';
import { GeocoderAddressComponent, GeocoderAddressTypes } from 'app-wrapper/types/GeocoderAddressComponent';
import forEach from 'lodash/fp/forEach';

const parseAddressComponent = (addressComponents?: GeocoderAddressComponent[]) => {
  const resultAddress: GeoParsedAddress = {
    postalCode: '',
    country: '',
    city: '',
    streetNumber: '',
    route: '',
    state: '',
  };

  forEach((component: GeocoderAddressComponent) => {
    const componentType = component.types[0];

    switch (componentType) {
      case GeocoderAddressTypes.STREET_NUMBER: {
        resultAddress.streetNumber = component.long_name;
        break;
      }
      case GeocoderAddressTypes.ROUTE: {
        resultAddress.route = component.long_name;
        break;
      }
      case GeocoderAddressTypes.POSTAL_CODE: {
        resultAddress.postalCode = component.long_name;
        break;
      }
      case GeocoderAddressTypes.LOCALITY:
        resultAddress.city = component.long_name;
        break;
      case GeocoderAddressTypes.STATE: {
        resultAddress.state = component.short_name;
        break;
      }
      case GeocoderAddressTypes.COUNTRY:
        resultAddress.country = component.short_name;
        break;
      default:
        break;
    }
  }, addressComponents);

  return resultAddress;
};

export default parseAddressComponent;
