import React from 'react';
import { useSelector } from 'react-redux';

import { R } from 'user-management/repository';
import { UC } from 'user-management/controllers';

import { CustomerAddInternalTeamComponent } from './CustomerAddInternalTeam.component';

export const CustomerAddInternalTeam = () => {
  const teams = useSelector(R.selectors.userOrganizationData.getTeams);
  const accountManagers = useSelector(R.selectors.userOrganizationData.getAccountManagers);
  const tempTeam = useSelector(R.selectors.userOrganizationData.getTempTeam);
  const tempAccountManager = useSelector(R.selectors.userOrganizationData.getTempAccountManager);
  const tempTeamError = useSelector(R.selectors.userOrganizationData.getTempTeamError);
  const tempAccountManagerError = useSelector(R.selectors.userOrganizationData.getTempAccountManagerError);

  return (
    <CustomerAddInternalTeamComponent
      teams={teams}
      accountManagers={accountManagers}
      tempTeam={tempTeam}
      tempAccountManager={tempAccountManager}
      setTempTeam={UC.organizationProfile.setTempTeam}
      setTempAccountManager={UC.organizationProfile.setTempAccountManager}
      teamError={tempTeamError}
      accountManagerError={tempAccountManagerError}
    />
  );
};
