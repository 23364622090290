import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'app-wrapper/createSlice';

import { IShipmentNewContainerState } from 'shipment-operations/models/states';
import { ContainerReeferTypes, ContainerUsualTypes } from 'shipment-operations/constants';

const initialState: IShipmentNewContainerState = {
  isLoading: false,
  isAddContainerModalOpen: false,
  newContainerQTY: '1',
};

export const shipmentNewContainerStore = createSlice({
  name: 'shipmentNewContainer',
  initialState,
  reducers: {
    setContainerType: (state, { payload }: PayloadAction<ContainerReeferTypes | ContainerUsualTypes>) => {
      state.newContainerType = payload;
    },
    setContainerTypeError: (state, { payload }: PayloadAction<Error | undefined>) => {
      state.newContainerTypeError = payload;
    },
    setContainerQTY: (state, { payload }: PayloadAction<string>) => {
      state.newContainerQTY = payload;
    },
    setContainerQTYError: (state, { payload }: PayloadAction<Error | undefined>) => {
      state.newContainerQTYError = payload;
    },
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload;
    },
    setContainerAddModalState: (state, { payload }: PayloadAction<boolean>) => {
      state.isAddContainerModalOpen = payload;
    },
    clear: () => (initialState),
  },
});

export const shipmentNewContainerActions = shipmentNewContainerStore.actions;

export const shipmentNewContainerReducer = shipmentNewContainerStore.reducer;
