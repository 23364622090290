import styled from 'styled-components';
import Typography from 'antd/es/typography';

export const Wrap = styled.div`
  margin-top: 8px;
  width: 100%;
  display: flex;
  padding: 12px 56px;
  flex-direction: row;
  align-items: center;
  border-radius: 3px;
  background-color: rgba(255, 255, 255, 1);
  border: 1px solid rgba(234, 238, 248, 1);
`;

export const Image = styled.img`
  width: 180px;
  height: 130px;
  margin-right: 44px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 859px;
`;

export const Title = styled(Typography)`
  margin-bottom: 10px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
`;

export const Items = styled.div`
  margin-left: 8px;
`;
