import React, { FC, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { ReferenceDTM } from 'app-wrapper/types/Reference';
import { SHIPMENT_CONTAINER } from 'app-wrapper/models/routes';
import { SkeletonSection } from 'app-wrapper/view/components/Skeleton/Skeleton.component';
import { CargoBaseDTM, ContainerDTM, ShipmentDeclarationDTM } from 'shipment-operations/models/dtm';
import { CommonContainerTypes, ContainerUsualTypes, ContainerReeferTypes } from 'shipment-operations/constants';
import { ShipmentDetailsList, ShipmentDetailsCardWithThreeSections } from 'shipment-operations/view/components';

import { ContainerCardBottom, ContainerCardMiddle, ContainerCardTop } from './components';

interface IShipmentContainersListComponentProps {
  isLoading: boolean
  containers?: {
    [k in CommonContainerTypes]: ContainerDTM[]
  }
  cargos: CargoBaseDTM[]
  selectedContainerCommonReferences: ReferenceDTM[]
  selectedContainerCommonPackagesNumber: number
  selectedContainerCommonWeight: number
  selectedContainerCommonVolume: number
  selectedContainerType?: ContainerUsualTypes | ContainerReeferTypes
  selectedContainerId?: string
  selectContainer: (shipmentId?: string, containerId?: string) => void
  listUploadHandler: (shipmentId?: string, containerId?: string) => void
  iMODeclarationsList: (ShipmentDeclarationDTM | undefined)[]
}

export const ShipmentContainersListComponent: FC<IShipmentContainersListComponentProps> = ({
  isLoading,
  containers,
  cargos,
  selectedContainerCommonReferences,
  selectedContainerCommonPackagesNumber,
  selectedContainerCommonWeight,
  selectedContainerCommonVolume,
  selectedContainerType,
  selectedContainerId,
  selectContainer,
  listUploadHandler,
  iMODeclarationsList,
}) => {
  const { shipmentId, containerId } = useParams<SHIPMENT_CONTAINER>();
  const { t } = useTranslation();
  const createSelectCargoHandler = useCallback((clickedContainerId?: string) => () => {
    selectContainer(shipmentId, clickedContainerId);
  }, [containers]);

  useEffect(() => {
    listUploadHandler(shipmentId, containerId);
  }, []);

  if (isLoading) {
    return (
      <ShipmentDetailsList>
        <SkeletonSection height="81px" />
      </ShipmentDetailsList>
    );
  }

  return (
    <ShipmentDetailsList>
      {
        containers && selectedContainerType && containers[selectedContainerType].map((container) => {
          const isSelected = container.id === selectedContainerId;
          const handleClick = createSelectCargoHandler(container.id);
          const containerName = container.name
            ? container.name : t('Number pending entry');
          const cardName = container.isVirtual ? t('New Container') : containerName;

          let commonPackagesNumber = 0;
          let commonVolume = 0;
          let commonWeight = 0;
          let references: ReferenceDTM[] = [];

          if (isSelected) {
            commonPackagesNumber = selectedContainerCommonPackagesNumber;
            commonWeight = selectedContainerCommonWeight;
            commonVolume = selectedContainerCommonVolume;
            references = references.concat(selectedContainerCommonReferences);
          } else {
            container.cargoItems.forEach((cargoItem) => {
              commonPackagesNumber += +cargoItem.packagesNumber;
              commonVolume += +cargoItem.volume;
              commonWeight += +cargoItem.weight;

              const cargo = cargos.find((item) => item.baseId === cargoItem.cargoId);

              if (!cargo) {
                return;
              }

              references = references.concat(cargo.references as ReferenceDTM[]); // TODO: fix it with Cargo refactoring
            });
          }

          const isHazmat = container.cargoItems.some((cargoItem) => {
            const cargo = cargos.find((item) => item.baseId === cargoItem.cargoId);
            return cargo?.imoClass;
          });

          const hasIMODeclaration = (isHazmat
              && !!iMODeclarationsList.find((declaration) => declaration?.containerId === +container.id))
            || !isHazmat;

          const hasSeaworthyCertificate = ((container.ownContainer && container.seaworthyCertificate) || !container.ownContainer);

          const containerInfoFilledIn = Boolean(
            container.number && container.sealNumber && container.cargoItems.length > 0
            && hasSeaworthyCertificate && hasIMODeclaration,
          );

          return (
            <ShipmentDetailsCardWithThreeSections
              key={`card__${container.id}`}
              isSelected={isSelected}
              onClick={handleClick}
            >
              <ContainerCardTop
                name={cardName}
                hasNumber={Boolean(container.name)}
                isInDraft={container.isInDraft}
                type={container.type}
                isSelected={isSelected}
                infoFilledIn={containerInfoFilledIn}
              />
              <ContainerCardMiddle references={references} />
              <ContainerCardBottom
                packagesNumber={commonPackagesNumber}
                volume={commonVolume}
                weight={commonWeight}
              />
            </ShipmentDetailsCardWithThreeSections>
          );
        })
      }
    </ShipmentDetailsList>
  );
};
