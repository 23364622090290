import styled from 'styled-components';

import { divFlex } from 'app-wrapper/view/themes/mixin';

export const RCQNoQuoteErrorWrap = styled.div.attrs({
  'data-class': 'RCQNoQuoteErrorWrap',
})`
  ${divFlex}
  width: 580px;
  height: 80vh;
  align-items: center;
`;

export const RCQNoQuoteErrorContentWrap = styled.div.attrs({
  'data-class': 'RCQNoQuoteErrorContentWrap',
})`
  ${divFlex}
  flex-direction: column;
  align-items: center;
  justify-content: center;

  height: 258px;
  width: 100%;
`;

export const RCQNoQuoteErrorContentWrapImage = styled.div.attrs((props: {
  image: string
}) => ({
  image: props.image,
  'data-class': 'RCQNoQuoteErrorContentWrapImage',
}))`
  ${divFlex}
`;

export const RCQNoQuoteErrorHeader = styled.div.attrs({
  'data-class': 'RCQNoQuoteErrorHeader',
})`
  ${divFlex}
  align-items: center;

  font-weight: 500;
  font-size: 20px;
  margin-top: 8px;
`;

export const RCQNoQuoteErrorHeaderTitle = styled.div.attrs({
  'data-class': 'RCQNoQuoteErrorHeaderTitle',
})`
  ${divFlex}
  align-items: center;
  text-align: center;

  font-weight: 400;
  font-size: 14px;
  color: ${({ theme }) => theme.themesColors.secondaryDot45};
`;

export const RCQNoQuoteErrorContent = styled.div.attrs({
  'data-class': 'RCQNoQuoteErrorContent',
})`
  ${divFlex}
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;

  height: 82px;
  margin-top: 20px;
`;

export const RCQNoQuoteErrorTwoBtn = styled.div.attrs({
  'data-class': 'RCQNoQuoteErrorTwoBtn',
})`
  ${divFlex}
  gap: 8px;
`;

export const RCQNoQuoteErrorContentLink = styled.div.attrs({
  'data-class': 'RCQNoQuoteErrorContentLink',
})`
  ${divFlex}

  font-weight: 400;
  font-size: 16px;
  margin-top: 0px;
`;
