import { Select } from 'app-wrapper/view/components/Select';
import styled, { css } from 'styled-components';
import { divFlex, responsive } from 'app-wrapper/view/themes/mixin';

const inputCss = css`
  font-size: 12px;
  font-weight: 400;
  color: ${({ theme }) => theme?.themesColors?.neutralBranding10};
  min-height: 32px;
`;

const inputTopMarginCss = css`
  margin-top: 4px;
`;

const inputLargeCss = css`
  height: 40px;
`;

export const SelectWithError = styled(Select).attrs((props: {
  hasError: boolean,
  largeInput: boolean,
  'data-class'?: string,
}) => ({
  hasError: props.hasError,
  largeInput: props.largeInput,
  'data-class': props['data-class'] || 'SelectWithError',
}))`
  ${divFlex}
  ${inputCss}
  ${inputTopMarginCss}
  ${({ largeInput }) => largeInput && inputLargeCss};
  width: 100%;

  .ant-select-item-option-content {
    font-size: 12px;
    color: ${({ theme }) => theme.themesColors.neutralBranding10};
  }

  border-width: 0px;
  .ant-select-item-option-content {
    font-family: "Roboto";
  }

  .ant-select-selector {
    width: 100%;
    min-width: ${({ theme }) => theme?.inputConst?.defaultHeight};
    ${({ hasError, theme }) => hasError && `border-color: ${theme.themesColors.characterBrandingDanger} !important; border-style: solid;`};
  }
  ${responsive.forFullMedia`
    .ant-select-selector {
      min-width: ${({ theme }: { theme: { inputConst: { hdHeight: string } } }) => theme?.inputConst?.hdHeight};
    }
  `}
  ${({ hasError, theme }) => hasError && `border-color: ${theme.themesColors.characterBrandingDanger} !important; border-style: solid;`};
`;
