import {
  IsNumber, IsString, IsOptional,
} from 'class-validator';
import { BaseDTM } from 'proto/BaseDTM';

export interface IAccountDTM {
  id: number
  organizationId: number | string
  name?: string
  phone?: string
  phone2?: string
  email?: string
  usci?: string
  taxId?: string
}

export class AccountDTM extends BaseDTM<IAccountDTM> {
  @IsNumber()
  id: number;

  @IsOptional()
  organizationId: number | string;

  @IsString()
  @IsOptional()
  name?: string;

  @IsString()
  @IsOptional()
  phone?: string;

  @IsString()
  @IsOptional()
  phone2?: string;

  @IsString()
  @IsOptional()
  email?: string;

  @IsString()
  @IsOptional()
  usci?: string;

  @IsString()
  @IsOptional()
  taxId?: string;
}
