import * as yup from 'yup';
import i18n from 'i18next';

yup.addMethod(yup.string, 'wcaIdFormat', function isValidWcaId(translation?: string) {
  return this.matches(/^[0-9]{6}$/, {
    message: translation || i18n.t('Validations.string.wcaIdFormat'),
    excludeEmptyString: true,
  });
});

declare module 'yup' {
  interface StringSchema {
    wcaIdFormat(translation?: string): StringSchema;
  }
}
