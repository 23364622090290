import { apiWorker } from 'app-wrapper/repository/utilsServices';

export class UsersService {
  public url = '/organization-service/api/v1/users';

  public async downloadUsersXml() {
    const response = await apiWorker.requestGet<Blob>(`${this.url}/download`, {
      responseType: 'blob',
    });

    return response.data;
  }
}
