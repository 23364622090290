import React, { FC, useCallback } from 'react';
import Divider from 'antd/es/divider';

import { useTranslation } from 'react-i18next';
import {
  HorizontalFieldName,
  InputItem, InputItemRight, InputItemsTitle,
  InputsRow, InputsWrapper, StyledInput,
} from './styled';

interface ICostComponentProps {
  APCostPerUnit?: number
  ARCostPerUnit?: number
  APNumberOfUnits?: number
  ARNumberOfUnits?: number
  onChangeInput: (value: number, field: string) => void
  errors: string[]
  selectedChargeMeasure?: string
  selectedChargeType?: string
  isFilledInfo: boolean
  withoutTotal?: boolean
}

const CostComponent: FC<ICostComponentProps> = ({
  APCostPerUnit, APNumberOfUnits, onChangeInput, ARCostPerUnit, ARNumberOfUnits,
  errors, selectedChargeMeasure, selectedChargeType, isFilledInfo, withoutTotal,
}) => {
  const { t } = useTranslation();

  const onChangeARCostPerUnit = useCallback((value) => onChangeInput(value, 'ARCostPerUnit'), []);
  const onChangeAPCostPerUnit = useCallback((value) => onChangeInput(value, 'APCostPerUnit'), []);
  const onChangeARNumberOfUnits = useCallback((value) => onChangeInput(value, 'ARNumberOfUnits'), []);
  const onChangeAPNumberOfUnits = useCallback((value) => onChangeInput(value, 'APNumberOfUnits'), []);

  const onFormatValue = useCallback((value) => (value ? `$ ${value}` : ''), []);

  const isRenderFlat = selectedChargeMeasure !== 'FLAT' && selectedChargeMeasure !== 'CONTAINER_TYPE' && !withoutTotal;

  return (
    <InputsWrapper>
      <InputsRow>
        <InputItem />
        <InputItemsTitle>{t('addChargeMenu.AP')}</InputItemsTitle>
        <InputItemsTitle>{t('addChargeMenu.AR')}</InputItemsTitle>
      </InputsRow>
      <InputsRow>
        <InputItem>
          <HorizontalFieldName>
            {t('addChargeMenu.Cost Per Unit')}
          </HorizontalFieldName>
        </InputItem>
        <InputItemRight>
          <StyledInput
            value={APCostPerUnit}
            onChange={onChangeAPCostPerUnit}
            controls={false}
            error={errors.includes('APCostPerUnit') ? 1 : 0}
            min={0}
            formatter={onFormatValue}
            disabled={(selectedChargeType === 'FEE') || isFilledInfo}
          />
        </InputItemRight>
        <InputItemRight>
          <StyledInput
            value={ARCostPerUnit}
            onChange={onChangeARCostPerUnit}
            controls={false}
            error={errors.includes('ARCostPerUnit') ? 1 : 0}
            min={0}
            formatter={onFormatValue}
            disabled={isFilledInfo}
          />
        </InputItemRight>
      </InputsRow>
      {isRenderFlat && (
        <>
          <Divider style={{ margin: '8px 0' }} dashed />
          <InputsRow>
            <InputItem>
              <HorizontalFieldName>
                {t('addChargeMenu.Number Of Units')}
              </HorizontalFieldName>
            </InputItem>
            <InputItemRight>
              <StyledInput
                value={APNumberOfUnits}
                onChange={onChangeAPNumberOfUnits}
                controls={false}
                error={errors.includes('APNumberOfUnits') ? 1 : 0}
                min={0}
                disabled={isFilledInfo}
              />
            </InputItemRight>
            <InputItemRight>
              <StyledInput
                value={ARNumberOfUnits}
                onChange={onChangeARNumberOfUnits}
                controls={false}
                error={errors.includes('ARNumberOfUnits') ? 1 : 0}
                min={0}
                disabled={isFilledInfo}
              />
            </InputItemRight>
          </InputsRow>
        </>
      )}
    </InputsWrapper>
  );
};

export { CostComponent };
