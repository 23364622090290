import React, {
  FC, memo, useCallback,
} from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';

import { TableSkeleton } from 'app-wrapper/view/components';
import { REQUEST_STATUS } from 'app-wrapper/constants';
import { OrganizationStatus, RejectionTooltip } from 'user-management/view/components';
import { EOrganizationStatus, OrganizationDTM } from 'user-management/models/dtm';
import { EReviewAction } from 'user-management/constants';

import {
  UMCContentTable,
  UMCContentTableContentLink,
  UMCContentTableContentItem,
  UMCContentTableContentItemTop,
  UMCContentTableContentTitle,
  UMCContentTableHeader,
  UMCContentTableHeaderItem,
  UMCSpanEllipsis,
  UMCStatusRow,
} from './CustomerContentTable.styled';

interface ICustomerContentTableProps {
  nextPageTasks: () => void;
  onClickCustomerInternal: (id: string) => void;
  isLoad?: string;
  isLoadAdd?: string;
  allData?: OrganizationDTM[];
  isEnd?: boolean;
}

const CustomerContentTable: FC<ICustomerContentTableProps> = (props) => {
  const {
    nextPageTasks,
    onClickCustomerInternal,
    isLoad,
    isLoadAdd,
    allData,
    isEnd,
  } = props;

  const { t } = useTranslation();

  const onClickCustomerInternalHandler = useCallback(
    (id: string) => () => {
      onClickCustomerInternal(id);
    },
    [],
  );

  return (
    <UMCContentTable>
      <UMCContentTableHeader>
        <UMCContentTableHeaderItem
          width="20%"
        >
          {t('Company')}
        </UMCContentTableHeaderItem>
        <UMCContentTableHeaderItem
          width="20%"
        >
          {t('Location')}
        </UMCContentTableHeaderItem>
        <UMCContentTableHeaderItem
          width="20%"
        >
          {t('Phone')}
        </UMCContentTableHeaderItem>
        <UMCContentTableHeaderItem
          width="20%"
        >
          {t('Email')}
        </UMCContentTableHeaderItem>
        <UMCContentTableHeaderItem
          width="20%"
          marginLeft="0 !important"
          marginRight="8px"
        >
          {t('Status')}
        </UMCContentTableHeaderItem>
      </UMCContentTableHeader>

      <>

        <InfiniteScroll
          dataLength={Number(allData?.length || 0)}
          next={nextPageTasks}
          hasMore={!isEnd}
          loader={
            isLoad === REQUEST_STATUS.pending
            && (<TableSkeleton />)
          }
          scrollableTarget="Wrapper"
        >
          {allData?.map((item) => (
            <UMCContentTableContentItem key={item.customId}>
              <UMCContentTableContentItemTop>
                <UMCContentTableContentLink
                  onClick={onClickCustomerInternalHandler(`${item.id || ''}`)}
                >
                  <UMCSpanEllipsis>
                    {item.name}
                  </UMCSpanEllipsis>
                </UMCContentTableContentLink>
                <UMCContentTableContentTitle>
                  <UMCSpanEllipsis>
                    {item.getAddressCityCountry()}
                  </UMCSpanEllipsis>
                </UMCContentTableContentTitle>
                <UMCContentTableContentTitle>
                  <UMCSpanEllipsis>
                    {item?.phone || ''}
                  </UMCSpanEllipsis>
                </UMCContentTableContentTitle>
                <UMCContentTableContentTitle>
                  <UMCSpanEllipsis>
                    {item?.email || ''}
                  </UMCSpanEllipsis>
                </UMCContentTableContentTitle>
                <UMCContentTableContentTitle isCapitalize>
                  {item.status ? (
                    <UMCStatusRow>
                      <OrganizationStatus
                        status={item.status}
                        isAdditionalInfoTooltip={item.review?.action === EReviewAction.REQUEST_ADDITIONAL_INFO}
                        tooltipName={item.review?.note}
                      />

                      {item?.review?.note && item.status === EOrganizationStatus.REJECTED ? (
                        <RejectionTooltip note={item?.review?.note} />
                      ) : null}
                    </UMCStatusRow>
                  ) : null}
                </UMCContentTableContentTitle>
              </UMCContentTableContentItemTop>
            </UMCContentTableContentItem>
          ))}
        </InfiniteScroll>
        {isLoadAdd === REQUEST_STATUS.pending && (<TableSkeleton />)}
      </>

    </UMCContentTable>
  );
};

const CustomerContentTableCache = memo(CustomerContentTable);

export { CustomerContentTableCache as CustomerContentTable };
