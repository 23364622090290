import styled from 'styled-components';
import {
  MainContent, responsive,
} from 'app-wrapper/view/themes/mixin';
import {
  MainContentCollapse, MainContentRightWrapperFullCollapse, MainContentSidebarFullCollapse, divFlex,
} from 'app-wrapper/view/themes/mixin/main';
import { Button } from 'app-wrapper/view/components';

// Content

export const RFRByIdSecondStepSpinWrap = styled.div.attrs({
  'data-class-second': 'RFRByIdSecondStepSpinWrap',
})`
  ${divFlex}
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;
`;

export const RFRByIdSecondStepDiv = styled(MainContent).attrs({
  'data-class-second': 'RFRByIdSecondStepDiv',
})`
  width: calc(100% - 24px);
  padding-left: 24px;
`;

export const RFRByIdSecondStepWrap = styled.div.attrs({
  'data-class-second': 'RFRByIdSecondStepWrap',
})`

  padding-left: 24px;
`;

export const RFRByIdSecondStepFirst = styled(MainContentCollapse).attrs({
  'data-class-second': 'RFRByIdSecondStepFirst',
})`


.RFRFormRequest__isCollapsed {
    min-width: calc(762px - 24px);

    .RFRFormRequest {
      &__Row {
        &__col {
          &_both_col {
            height: 95px;
          }
        }

        &__Commodity, &__Containers {
          &__content {
            &_title {
              margin-top: 8px;
            }
          }
        }
      }
    }
  }
`;

export const RFRByIdSecondStepSecond = styled(MainContent).attrs({
  'data-class-second': 'RFRByIdSecondStepSecond',
})`

`;

export const RFRByIdSecondStepSecondHeaderFirst = styled.div.attrs((props: {
  iconColor: string,
}) => ({
  iconColor: props.iconColor,
  'data-class': 'RFRByIdSecondStepSecondHeaderFirst',
}))`
  margin-left: 0px;
`;

export const RFRByIdSecondStepSecondHeaderSecond = styled.div.attrs((props: {
  iconColor: string,
}) => ({
  iconColor: props.iconColor,
  'data-class': 'RFRByIdSecondStepSecondHeaderSecond',
}))`
  margin-left: auto;
`;

export const RFRByIdSecondStepSecondHeaderThird = styled.div.attrs((props: {
  iconColor: string,
}) => ({
  iconColor: props.iconColor,
  'data-class': 'RFRByIdSecondStepSecondHeaderThird',
}))`
  margin-left: 8px;
`;

export const RFRByIdSecondStepSecondHeaderButton = styled(Button).attrs({
  'data-class': 'RFRByIdSecondStepSecondHeaderButton',
})`
`;

export const RFRByIdSecondStepSecondFooter = styled.div.attrs({
  'data-class': 'RFRByIdSecondStepSecondFooter',
})`
  ${divFlex}
  width: 75%;
  height: 64px;
  align-items: center;

  padding: 8px 24px;
  z-index: 1;
  box-shadow: 0px -3px 10px 1px rgba(0,0,0,0.04);
  background-color: ${({ theme }) => theme.themesColors.neutralBranding1};
`;

export const RFRByIdSecondStepSecondFooterButton = styled(Button).attrs({
  'data-class': 'RFRByIdSecondStepSecondFooterButton',
})`

`;

export const RFRByIdSecondStepSecondFooterWrap = styled.div.attrs({
  'data-class-second': 'RFRByIdSecondStepSecondFooterWrap',
})`
  ${divFlex}
  position: absolute;
  align-items: end;

  max-width: 1660px;
  width: calc(100% + 24px);
  height: 100%;
`;

export const RFRByIdSecondStepSecondFooterSidebar = styled.div.attrs({
  'data-class-second': 'RFRByIdSecondStepSecondFooterSidebar',
})`
  ${divFlex}

  width: 30%;
  min-width: 390px;
  max-width: calc(480px - 24px);
`;

export const RFRByIdSecondStepMainContentSidebarFullCollapse = styled(
  MainContentSidebarFullCollapse,
).attrs((props: {
  isCollapse: boolean,
}) => ({
  ...props,
  isCollapse: props.isCollapse,
  'data-class-second': 'RFRByIdSecondStepMainContentSidebarFullCollapse',
}))`
  .RFRFormRequest {
    &__Row {
      &__col {
        &_both_col {
          ${responsive.forFullMedia`
            ${({ isCollapse }: { isCollapse: boolean }) => !isCollapse && 'height: 235px;'}
          `}
        }
      }
    }
  }
  .RFRFormRequest {
    ${responsive.forFullMedia`
      width: 100%;
      max-width: calc(762px - 24px);
      min-width: 670px;
      ${({ isCollapse }: { isCollapse: boolean }) => !isCollapse && 'min-width: calc(390px - 24px);'}
  `}
    ${responsive.forFullMediaMax`
      width: 100%;
      max-width: calc(480px - 24px);
    `}
    input:disabled,
    .ant-select-disabled span {
      color: ${({ theme }) => theme.themesColors.secondaryDot45};
    }
    .ant-radio-button-wrapper-disabled span {
      color: ${({ theme }) => theme.themesColors.characterBrandingDisabled025};
    }
    .ant-picker-suffix {
      svg {
        color: ${({ theme }) => theme.themesColors.characterBrandingDisabled025};
      }
    }
    .TypeSwitchController.disabled-bg {
      .TypeSwitchController__text {
        color: ${({ theme }) => theme.themesColors.secondaryDot45};
      }
      .TypeSwitchController__text-inactive {
        color: ${({ theme }) => theme.themesColors.characterBrandingDisabled025};
      }
      .ant-switch {
        background-color: ${({ theme }) => theme.themesColors.characterBrandingDisabled025};
      }
    }
  }
  .RFRFormRequest.RFRFormRequest__isCollapsed {
      ${responsive.forFullMedia`
        min-width: 670px;
      `}
    }
`;

export const RFRByIdSecondStepMainContentRightWrapperFullCollapse = styled(
  MainContentRightWrapperFullCollapse,
).attrs({
  'data-class-second': 'RFRByIdSecondStepMainContentRightWrapperFullCollapse',
})`

`;

export const RFRByIdSecondStepMainContentRightRequestWrap = styled.div.attrs({
  'data-class': 'RFRByIdSecondStepMainContentRightRequestWrap',
})`
  ${divFlex}
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding-bottom: 64px;
`;

export const RFRByIdSecondStepMainContentRightCompleteWrap = styled.div.attrs({
  'data-class': 'RFRByIdSecondStepMainContentRightCompleteWrap',
})`
  ${divFlex}
  flex-direction: column;
  padding-top: 8px;
  height: 100%;
  padding-bottom: 64px;
`;

export const RFRByIdSecondStepMainContentRightCompleteWrapContent = styled.div.attrs({
  'data-class': 'RFRByIdSecondStepMainContentRightCompleteWrap',
})`
  ${divFlex}

  align-items: center;
  justify-content: center;
  height: 100%;
`;

export const RFRByIdSecondStepWrapper = styled.div.attrs({
  'data-class': 'RFRByIdSecondStepWrapper',
})`
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 0;
  background-color: ${({ theme }) => theme.themesColors.lightGrayBorder};
`;
