import {
  IsDefined, IsNumber, IsOptional, IsString,
} from 'class-validator';
import { BaseDTM } from 'proto/BaseDTM';

export interface ICompanyDTM {
  id: number
  name: string
  phone: string
  phone2: string
  email: string
  usci?: string
  taxId?: string
}

export class CompanyDTM extends BaseDTM<ICompanyDTM> {
  @IsDefined()
  @IsNumber()
  id: number;

  @IsDefined()
  @IsString()
  name: string;

  @IsDefined()
  @IsString()
  name2: string;

  @IsDefined()
  @IsString()
  phone: string;

  @IsDefined()
  @IsString()
  phone2: string;

  @IsDefined()
  @IsString()
  email: string;

  @IsOptional()
  @IsString()
  usci?: string;

  @IsOptional()
  @IsString()
  taxId?: string;
}

export interface ICompanyForViewDTM {
  name: string
  id: number
}

export class CompanyForViewDTM extends BaseDTM<ICompanyForViewDTM> implements ICompanyForViewDTM {
  @IsString()
  name: string

  @IsNumber()
  id: number
}
