import React from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';

import { CommodityComponent } from './Commodity.component';

const CommodityContainer = () => {
  const cargos = useSelector(R.selectors.manualBookingWizard.getCargos);
  const shouldHaveAnyHazmats = useSelector(R.selectors.manualBookingWizard.getHasHazmats);
  const isPending = useSelector(R.selectors.manualBookingWizard.getIsLoading);

  return (
    <CommodityComponent
      isPending={isPending}
      cargos={cargos}
      hasAnyHazmats={shouldHaveAnyHazmats}
    />
  );
};

export { CommodityContainer as Commodity };
