import styled from 'styled-components';
import Button from 'antd/es/button';
import TextArea from 'antd/es/input/TextArea';
import TreeSelect from 'antd/es/tree-select';

interface IInput {
  error?: number
}

export const Container = styled.div`
  padding: 16px 24px 50px 24px;
`;

export const StyledButton = styled(Button)`
  background-color: #4E5D70;
  border: none;

  &:hover {
    background-color: #798aa2;
  }
`;

export const FieldName = styled.span`
  font-weight: 400;
  font-size: 12px;
  color: #202C3C;
  margin-bottom: 3px;
  margin-top: 10px;
  display: block;
`;

export const FirstFieldName = styled(FieldName)`
  margin-top: 10px;
`;

export const HorizontalFieldName = styled(FieldName)`
  margin-top: 0;
  margin-bottom: 0;
`;

export const GrayFieldName = styled(HorizontalFieldName)`
  color: #73819B;
`;

export const InputsHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const StyledDesignation = styled(FieldName)`
  color: #73819B;
  text-transform: lowercase;
  :first-letter {
    text-transform: uppercase;
  }
`;

export const InputItem = styled.div`
  width: 31%;
  display: flex;
`;

export const InputItemRight = styled(InputItem)`
  justify-content: end;
`;

export const InputsTotalValue = styled(InputItemRight)`
  font-weight: 400;
  font-size: 14px;
  color: #202C3C;
`;

export const StyledProfit = styled(InputsTotalValue)`
  font-weight: 500;
`;

export const StyledTextArea = styled(TextArea)`
  margin-bottom: 15px;
  font-size: 12px;
`;

export const FooterWrapper = styled.div`
  width: 100%;
  background-color: white;
  position: absolute;
  bottom: 0;
  left: 0;
  box-shadow: inset 0 1px 0 #F0F0F0;
  height: 52px;
  padding: 10px 16px;
  display: flex;
  align-items: end;
  justify-content: end;
`;

export const StyledTreeSelect = styled(TreeSelect)<IInput>`
  width: 100%;
  
  .ant-select-selector {
    border-color: ${({ error }) => error && 'red'} !important;
  }
`;

export const InputsRow = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
`;

export const EditContainerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 8px;
`;
