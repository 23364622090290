import React, { FC, useCallback, useMemo } from 'react';
import get from 'lodash/fp/get';
import CheckCircleOutlined from '@ant-design/icons/CheckCircleOutlined';
import ClockCircleOutlined from '@ant-design/icons/ClockCircleOutlined';
import WarningOutlined from '@ant-design/icons/WarningOutlined';
import uniq from 'lodash/fp/uniq';

import { prepareAPChargeHistory } from 'shipment-operations/repository/store/ShipmentCharges';
import {
  ChargeViewDtm,
  FullChargeTableDTM,
  IGroupedChargesDTM,
  ShipmentChargesPermissionsDtm,
} from 'shipment-operations/models/dtm';
import {
  CUSTOMER_VIEW,
  ShortChargeStatusEnum as statuses,
  subjectToIncluded,
} from 'shipment-operations/constants';
import { useToggleCollapse } from 'shipment-operations/view/hooks';
import { CustomTable } from 'shipment-operations/view/pages/ShipmentCharges/components';
import { chargeCodeCode } from 'monetary/constants';
import { Title } from 'shipment-operations/view/pages/ShipmentCharges/ShipmentCharges.styled';

import { useTableColumns } from './useTable';
import { StyledTable } from './ContainersTable.styled';

interface IContainersTableProps {
  dataSource: ChargeViewDtm[]
  innerTableData: IGroupedChargesDTM
  permissions: ShipmentChargesPermissionsDtm
  mode: string
  isPercentage: boolean
  subTitle?: string
}

export const matchedIcon = {
  [statuses.INVOICED]: <CheckCircleOutlined style={{ color: '#75CB3F' }} />,
  [statuses.UN_INVOICED]: <ClockCircleOutlined style={{ color: '#D1D7E2' }} />,
  [statuses.PARTIALLY_INVOICED]: <WarningOutlined style={{ color: '#F1AE00' }} />,
  [statuses.OVER_INVOICED]: <WarningOutlined style={{ color: '#F1AE00' }} />,
};

const ContainersTableComponent: FC<IContainersTableProps> = ({
  dataSource, innerTableData, permissions, mode, subTitle, isPercentage,
}) => {
  const { openedKeys, onToggleCollapse } = useToggleCollapse();

  const { columns } = useTableColumns(openedKeys, onToggleCollapse, permissions, mode, isPercentage, subTitle);

  const expandRender = useCallback((record: FullChargeTableDTM | {}) => {
    const key = get(['key'], record);
    const matchedData = innerTableData[key];
    const filteredData = matchedData.filter((item) => item.subjectTo !== subjectToIncluded);
    const includedCharges = matchedData.filter((item) => item.subjectTo === subjectToIncluded).map((item) => item.chargeCode.description);
    const tableDataFull = filteredData.map((item) => (ChargeViewDtm.fromPlain({
      key: item.id,
      active: item.active,
      designation: item.designation,
      description: item.chargeCode.description,
      apBudget: (item?.apBudget?.totalCost || 0),
      apTotalCost: item.buyTotalCost,
      arBudget: (item?.arBudget?.totalCost || 0),
      arTotalCost: item.totalCost,
      profit: item.profitAmount,
      profitPercentage: item.profitPercent,
      posted: item.apStatus,
      invoiced: item.arStatus,
      unitType: item.measureBy,
      buyCostPerUnit: item.buyCostPerUnit,
      buyQuantity: item.buyNumberOfUnits,
      buyTotalCost: item.buyTotalCost,
      costPerUnit: item.costPerUnit,
      quantity: item.numberOfUnits,
      totalCost: item.totalCost,
      history: prepareAPChargeHistory(item.apVersions, item.additional),
      includedCharges: item.chargeCode.code === chargeCodeCode.FRT ? uniq(includedCharges) : [],
    })));
    return (
      <CustomTable
        data={tableDataFull}
        innerTable
      />
    );
  }, [openedKeys]);

  const handleClass = useCallback(() => 'expandContainersRow', []);

  const options = useMemo(() => ({
    expandedRowRender: expandRender,
    expandedRowKeys: openedKeys,
    expandIconColumnIndex: -1,
  }), [openedKeys, expandRender]);

  return (
    <>
      {mode === CUSTOMER_VIEW && (
        <Title>{subTitle}</Title>
      )}
      <StyledTable
        pagination={false}
        dataSource={dataSource}
        expandedRowClassName={handleClass}
        expandable={options}
        shortVersion={mode !== CUSTOMER_VIEW}
      >
        {columns}
      </StyledTable>
    </>
  );
};

export { ContainersTableComponent };
