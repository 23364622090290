import React from 'react';
import { useParams, Navigate } from 'react-router';

import { RouteNames } from 'app-wrapper/constants';

export const ShipmentImportDefaultNavigate = () => {
  const { shipmentId } = useParams();

  return (
    <Navigate to={RouteNames.SHIPMENT_TRANSPORTATION_IMPORT_OVERVIEW(shipmentId)} />
  );
};
