import styled, { css } from 'styled-components';

const textColorFirst = css`
  color: ${({ theme }) => theme.themesColors.characterBrandingTitle85};
`;

export const divFlex = css`
  display: flex;
  position: relative;
  ${textColorFirst}
`;

export const RFQResponseTextStatus = styled.div`
  ${divFlex}
  align-items: center;
  justify-content: center;
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;

export const Icon = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  position: relative;
`;

export const IconDialog = styled.div`
  position: absolute;
  right: -21px;
  top: -21px;
  display: flex;
  justify-content: end;
  width: 100%;
`;

export const IconDialogDots = styled.div`
  position: absolute;
  right: -7px;
  top: 9px;
`;

export const Message = styled.div`
  position: relative;
`;

export const Footer = styled.div`
  margin-top: 15px;
`;

export const FooterText = styled.div`
  color: #99a3b5;
  font-weight: 400;
  text-align: center;
`;

export const FooterTitle = styled(FooterText)`
  font-size: 14px;
`;

export const FooterDescription = styled(FooterText)`
  font-size: 12px;
`;
