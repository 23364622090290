import React, { FC, memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import ClearOutlined from '@ant-design/icons/ClearOutlined';
import { DataNode } from 'antd/es/tree';

import {
  Button, FilterCheckboxElement, FilterCheckboxesTreeElement, FilterDatesElement, TypeSwitch,
} from 'app-wrapper/view/components';
import {
  AppFiltersCheckboxGroupDTM, IAppFiltersCheckboxGroupDTM,
  INotificationTaskFilterReceivedDatesDTM,
  NotificationTaskFilterDatesDTM,
} from 'app-wrapper/models/dtm';

import { TasksFilterHeaderComponentSeparate, TasksFilterHeaderComponentWrap } from './TasksFilterHeader.styled';

interface ITasksFilterHeaderComponentProps {
  valueShowOnlyUnread?: boolean
  isVisibleShowOnlyUnread?: boolean
  isDisabledShowOnlyUnread?: boolean
  isVisibleShowOnlyPriority?: boolean
  isVisibleAssignee?: boolean
  isVisibleAssigneeOrganization?: boolean
  isVisibleObject?: boolean
  isVisibleDates?: boolean
  isVisibleModule?: boolean
  isVisibleClearAll?: boolean
  isDisabledResetPriority?: boolean
  groupPriority?: IAppFiltersCheckboxGroupDTM[]
  groupAssignee?: IAppFiltersCheckboxGroupDTM[]
  isDisabledResetAssignee?: boolean
  groupAssigneeOrganization?: IAppFiltersCheckboxGroupDTM[]
  isDisabledResetAssigneeOrganization?: boolean
  treeDataObject?: DataNode[]
  groupObject?: IAppFiltersCheckboxGroupDTM[]
  isDisabledResetObject?: boolean
  receivedDates?: INotificationTaskFilterReceivedDatesDTM
  groupModule?: IAppFiltersCheckboxGroupDTM[]
  isDisabledResetModule?: boolean
  receivedName?: string
  shownOnlyName?: string
  isVisibleSearch?: boolean
  disabledModalAssignee?: boolean
  disabledModalAssigneeOrganization?: boolean
  isAllFiltersDisabled?: boolean
  onChangeFilterShowOnlyUnread?: (value: boolean) => void
  onApplyPriority?: () => void
  onResetPriority?: () => void
  onChangeGroupPriority?: (id: number, value: AppFiltersCheckboxGroupDTM) => void
  onApplyAssignee?: () => void
  onResetAssignee?: () => void
  onChangeGroupAssignee?: (id: number, value: AppFiltersCheckboxGroupDTM) => void
  onApplyAssigneeOrganization?: () => void
  onResetAssigneeOrganization?: () => void
  onChangeGroupAssigneeOrganization?: (id: number, value: AppFiltersCheckboxGroupDTM) => void
  onApplyObject?: () => void
  onResetObject?: () => void
  onChangeGroupObject?: (checkedKeysValue: string[]) => void
  onApplyReceivedDate?: () => void
  onResetReceivedDate?: () => void
  dateOnReceivedDateChange?: (dates: NotificationTaskFilterDatesDTM) => void
  onChangeGroupModule?: (id: number, value: AppFiltersCheckboxGroupDTM) => void
  onApplyModule?: () => void
  onResetModule?: () => void
  onClearAllFilters?: () => void
}

const TasksFilterHeaderComponent: FC<ITasksFilterHeaderComponentProps> = (props) => {
  const {
    valueShowOnlyUnread,
    isVisibleShowOnlyUnread,
    isDisabledShowOnlyUnread,
    isVisibleShowOnlyPriority,
    isVisibleAssignee,
    isVisibleAssigneeOrganization,
    isVisibleObject,
    isVisibleDates,
    isVisibleModule,
    isVisibleClearAll,
    groupPriority,
    isDisabledResetPriority,
    groupAssignee,
    isDisabledResetAssignee,
    groupAssigneeOrganization,
    isDisabledResetAssigneeOrganization,
    treeDataObject,
    groupObject,
    isDisabledResetObject,
    receivedDates,
    groupModule,
    isDisabledResetModule,
    receivedName,
    shownOnlyName,
    isVisibleSearch,
    disabledModalAssignee,
    disabledModalAssigneeOrganization,
    isAllFiltersDisabled,
    onChangeFilterShowOnlyUnread,
    onApplyPriority,
    onResetPriority,
    onChangeGroupPriority,
    onApplyAssignee,
    onResetAssignee,
    onChangeGroupAssignee,
    onApplyAssigneeOrganization,
    onResetAssigneeOrganization,
    onChangeGroupAssigneeOrganization,
    onApplyObject,
    onResetObject,
    onChangeGroupObject,
    onApplyReceivedDate,
    onResetReceivedDate,
    dateOnReceivedDateChange,
    onChangeGroupModule,
    onApplyModule,
    onResetModule,
    onClearAllFilters,
  } = props;
  const { t } = useTranslation();

  const onChangeFilterIsIncludeRelatedPortRequestHandler = useCallback(
    (value) => {
      if (onChangeFilterShowOnlyUnread) {
        onChangeFilterShowOnlyUnread(!!value);
      }
    },
    [onChangeFilterShowOnlyUnread],
  );

  const onApplyPriorityHandler = useCallback(
    () => {
      if (onApplyPriority) {
        onApplyPriority();
      }
    },
    [onApplyPriority],
  );

  const onResetPriorityHandler = useCallback(
    () => {
      if (onResetPriority) {
        onResetPriority();
      }
    },
    [onResetPriority],
  );

  const onChangeGroupPriorityHandler = useCallback(
    (id: number, value: AppFiltersCheckboxGroupDTM) => {
      if (onChangeGroupPriority) {
        onChangeGroupPriority(id, value);
      }
    },
    [onChangeGroupPriority],
  );

  const onApplyAssigneeHandler = useCallback(
    () => {
      if (onApplyAssignee) {
        onApplyAssignee();
      }
    },
    [onApplyAssignee],
  );

  const onResetAssigneeHandler = useCallback(
    () => {
      if (onResetAssignee) {
        onResetAssignee();
      }
    },
    [onResetAssignee],
  );

  const onChangeGroupAssigneeHandler = useCallback(
    (id: number, value: AppFiltersCheckboxGroupDTM) => {
      if (onChangeGroupAssignee) {
        onChangeGroupAssignee(id, value);
      }
    },
    [onChangeGroupAssignee],
  );

  const onApplyAssigneeOrganizationHandler = useCallback(
    () => {
      if (onApplyAssigneeOrganization) {
        onApplyAssigneeOrganization();
      }
    },
    [onApplyAssigneeOrganization],
  );

  const onResetAssigneeOrganizationHandler = useCallback(
    () => {
      if (onResetAssigneeOrganization) {
        onResetAssigneeOrganization();
      }
    },
    [onResetAssigneeOrganization],
  );

  const onChangeGroupAssigneeOrganizationHandler = useCallback(
    (id: number, value: AppFiltersCheckboxGroupDTM) => {
      if (onChangeGroupAssigneeOrganization) {
        onChangeGroupAssigneeOrganization(id, value);
      }
    },
    [onChangeGroupAssigneeOrganization],
  );

  const onApplyObjectHandler = useCallback(
    () => {
      if (onApplyObject) {
        onApplyObject();
      }
    },
    [onApplyObject],
  );

  const onResetObjectHandler = useCallback(
    () => {
      if (onResetObject) {
        onResetObject();
      }
    },
    [onResetObject],
  );

  const onChangeGroupObjectHandler = useCallback(
    (checkedKeysValue: string[]) => {
      if (onChangeGroupObject) {
        onChangeGroupObject(checkedKeysValue);
      }
    },
    [onChangeGroupObject],
  );

  const dateOnReceivedDateChangeHandler = useCallback(
    (dates: NotificationTaskFilterDatesDTM) => {
      if (dateOnReceivedDateChange) {
        dateOnReceivedDateChange(dates);
      }
    },
    [dateOnReceivedDateChange],
  );

  const onApplyReceivedDateHandler = useCallback(
    () => {
      if (onApplyReceivedDate) {
        onApplyReceivedDate();
      }
    },
    [onApplyReceivedDate],
  );

  const onResetReceivedDateHandler = useCallback(
    () => {
      if (onResetReceivedDate) {
        onResetReceivedDate();
      }
    },
    [onResetReceivedDate],
  );

  const onChangeGroupModuleHandler = useCallback(
    (id: number, value: AppFiltersCheckboxGroupDTM) => {
      if (onChangeGroupModule) {
        onChangeGroupModule(id, value);
      }
    },
    [onChangeGroupModule],
  );

  const onApplyModuleHandler = useCallback(
    () => {
      if (onApplyModule) {
        onApplyModule();
      }
    },
    [onApplyModule],
  );

  const onResetModuleHandler = useCallback(
    () => {
      if (onResetModule) {
        onResetModule();
      }
    },
    [onResetModule],
  );

  const onClearAllFiltersHandler = useCallback(
    () => {
      if (onClearAllFilters) {
        onClearAllFilters();
      }
    },
    [onClearAllFilters],
  );

  return (
    <TasksFilterHeaderComponentWrap>
      {isVisibleShowOnlyUnread && (
        <TypeSwitch
          onChange={onChangeFilterIsIncludeRelatedPortRequestHandler}
          disabled={isAllFiltersDisabled || isDisabledShowOnlyUnread}
          data-class="TasksFilterHeaderComponentShowOnlyUnread"
          rightText={shownOnlyName || t('Show Only Unread')}
          checkedChildren="On"
          unCheckedChildren="Off"
          value={!!valueShowOnlyUnread}
          disabledbg="true"
        />
      )}

      {isVisibleShowOnlyPriority && (
        <>
          {isVisibleShowOnlyUnread && (<TasksFilterHeaderComponentSeparate />)}
          <FilterCheckboxElement
            name={t('Priority')}
            isRight
            onApply={onApplyPriorityHandler}
            onReset={onResetPriorityHandler}
            group={groupPriority || []}
            disabledModal={isAllFiltersDisabled}
            disabledReset={!!isDisabledResetPriority}
            onChangeGroup={onChangeGroupPriorityHandler}
          />
        </>
      )}

      {isVisibleAssignee && (
        <>
          {(isVisibleShowOnlyUnread || isVisibleShowOnlyPriority) && (<TasksFilterHeaderComponentSeparate />)}
          <FilterCheckboxElement
            name={t('Assignee')}
            isRight
            isBigHeight
            disabledModal={isAllFiltersDisabled || disabledModalAssignee}
            isVisibleSearch={isVisibleSearch}
            onApply={onApplyAssigneeHandler}
            onReset={onResetAssigneeHandler}
            group={groupAssignee || []}
            disabledReset={!!isDisabledResetAssignee}
            onChangeGroup={onChangeGroupAssigneeHandler}
          />
        </>
      )}

      {isVisibleAssigneeOrganization && (
        <>
          {(isVisibleAssignee) && (<TasksFilterHeaderComponentSeparate />)}
          <FilterCheckboxElement
            name={t('Organization')}
            isRight
            disabledModal={isAllFiltersDisabled || disabledModalAssigneeOrganization}
            isVisibleSearch={isVisibleSearch}
            onApply={onApplyAssigneeOrganizationHandler}
            onReset={onResetAssigneeOrganizationHandler}
            group={groupAssigneeOrganization || []}
            disabledReset={!!isDisabledResetAssigneeOrganization}
            onChangeGroup={onChangeGroupAssigneeOrganizationHandler}
          />
        </>
      )}

      {isVisibleDates && (
        <>
          <TasksFilterHeaderComponentSeparate />
          <FilterDatesElement
            name={receivedName || t('ReceivedDate')}
            isRight
            disabledModal={isAllFiltersDisabled}
            onApply={onApplyReceivedDateHandler}
            onReset={onResetReceivedDateHandler}
            earliestDate={receivedDates?.dates?.earliestDate}
            latestDate={receivedDates?.dates?.latestDate}
            disabledReset={!!receivedDates?.disableReset}
            dateOnCalendarChange={dateOnReceivedDateChangeHandler}
          />
        </>
      )}

      {isVisibleObject && (
        <>
          <TasksFilterHeaderComponentSeparate />
          <FilterCheckboxesTreeElement
            name={t('Object')}
            treeData={treeDataObject}
            isRight
            disabledModal={isAllFiltersDisabled}
            onApply={onApplyObjectHandler}
            onReset={onResetObjectHandler}
            group={groupObject || []}
            disabledReset={!!isDisabledResetObject}
            onChangeCheck={onChangeGroupObjectHandler}
          />
        </>
      )}

      {isVisibleModule && (
        <>
          <TasksFilterHeaderComponentSeparate />
          <FilterCheckboxElement
            name={t('Module')}
            isRight
            disabledModal={isAllFiltersDisabled}
            isVisibleSearch={isVisibleSearch}
            onApply={onApplyModuleHandler}
            onReset={onResetModuleHandler}
            group={groupModule || []}
            disabledReset={!!isDisabledResetModule}
            onChangeGroup={onChangeGroupModuleHandler}
          />
        </>
      )}

      {isVisibleClearAll && (
        <>
          <TasksFilterHeaderComponentSeparate />
          <Button
            onClick={onClearAllFiltersHandler}
            disabled={isAllFiltersDisabled}
            size="small"
            type="default"
            icon={<ClearOutlined />}
          >
            {t('Clear All')}
          </Button>
        </>
      )}
    </TasksFilterHeaderComponentWrap>
  );
};

const TasksFilterHeaderComponentCache = memo(TasksFilterHeaderComponent);

export { TasksFilterHeaderComponentCache as TasksFilterHeaderComponent };
