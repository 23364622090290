export enum EReviewAction {
  APPROVE = 'APPROVE',
  REJECT = 'REJECT',
  REQUEST_ADDITIONAL_INFO = 'REQUEST_ADDITIONAL_INFO',
}

export enum EReviewReason {
  OUTSIDE_SERVICEABLE_REGION = 'OUTSIDE_SERVICEABLE_REGION',
  SECURITY_CONCERNS = 'SECURITY_CONCERNS',
  ALREADY_EXISTS = 'ALREADY_EXISTS',
  OTHER = 'OTHER',
}
