import React, {
  ChangeEvent,
  FC,
  useCallback,
  useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';

import { Tooltip } from 'app-wrapper/view/components';
import { PermissionAttributePolicy } from 'shipment-operations/constants';
import { ValidationErrorDTM, ValidationErrorType } from 'app-wrapper/types';

import {
  TemperatureFieldWrapper,
  TemperatureInput,
  TemperatureLabel,
} from './TemperatureField.styled';

interface ITemperatureFieldComponentProps {
  temperature?: string
  isErrorShowed: boolean
  error?: ValidationErrorDTM
  setTemperature: (temperature?: string) => void
  touchField: (field: string) => void
  containersAvailability?: PermissionAttributePolicy
}

export const TemperatureFieldComponent: FC<ITemperatureFieldComponentProps> = ({
  temperature,
  isErrorShowed,
  error,
  setTemperature,
  touchField,
  containersAvailability,
}) => {
  const { t } = useTranslation();

  const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setTemperature(event.target.value);
  }, []);

  const handleBlur = useCallback(() => {
    touchField('temperature');
  }, []);

  const isDisabled = useMemo(() => containersAvailability !== PermissionAttributePolicy.WRITE, [containersAvailability]);

  return (
    <TemperatureFieldWrapper>
      <TemperatureLabel>
        {`${t('Temperature')} `}{'\u2103'}
      </TemperatureLabel>
      <Tooltip
        mode="danger"
        title={t(error?.message || '')}
        visible={isErrorShowed && error?.type === ValidationErrorType.ALERT}
      >
        <TemperatureInput
          value={temperature}
          error={isErrorShowed}
          onChange={handleChange}
          onBlur={handleBlur}
          disabled={isDisabled}
        />
      </Tooltip>
    </TemperatureFieldWrapper>
  );
};
