import React, { FC } from 'react';
import { IRFQItemDetailRoutingComponentProps, RFQItemDetailRoutingComponent } from './RFQItemDetailRouting.component';

export const RFQItemDetailRouting: FC<IRFQItemDetailRoutingComponentProps> = ({
  origin,
  freight,
  destination,
}) => (
  <RFQItemDetailRoutingComponent
    origin={origin}
    freight={freight}
    destination={destination}
  />
);
