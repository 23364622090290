import styled from 'styled-components';

import { FlexRow } from 'app-wrapper/view/guideline';

import { BaseContent } from 'shipment-operations/view/pages/ShipmentBillOfLading/components/MBLFlowManagement/MBLFlowManagement.styled';

export const Content = styled(BaseContent)``;

export const Row = styled(FlexRow)`
  width: 80%;
  justify-content: space-between;
`;
