import {
  IsEnum, IsNumber, IsOptional, IsString,
} from 'class-validator';

import i18n from 'app-wrapper/i18n/i18n';
import { BaseDTM } from 'proto/BaseDTM';

import { EBusinessRegistrationNumberType } from 'user-management/constants';

export type TBusinessRegistrationNumberDtm = {
  type: `${EBusinessRegistrationNumberType}`
  value: string
  valueTemp?: string
  maxValue?: number
}

const EIN_PATTERN = /^[0-9]{2}-[0-9]{7}([a-zA-Z0-9]{2})?$/;

export class BusinessRegistrationNumberDtm extends BaseDTM<TBusinessRegistrationNumberDtm> {
  @IsEnum(EBusinessRegistrationNumberType)
  type: EBusinessRegistrationNumberType;

  // Currently off
  // @Matches(EIN_PATTERN)
  // value: string;

  @IsString()
  value: string;

  @IsOptional()
  @IsString()
  valueTemp?: string;

  @IsOptional()
  @IsNumber()
  maxValue?: number;

  hasError = () => !EIN_PATTERN.test(this.value)

  getErrorMessage = () => {
    if (!EIN_PATTERN.test(this.value)) {
      return i18n.t('basicErrors.NOT_VALID_PATTERN_EIN');
    }
    return '';
  }

  getValidValue = () => this.value
}
