import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  Popover,
  InfoIcon,
  Content,
} from './OptionalIcon.styled';

export const OptionalIcon = () => {
  const { t } = useTranslation();

  return (
    <Popover
      overlayInnerStyle={{
        padding: 0,
        backgroundColor: 'rgba(32, 44, 60, 1)',
      }}
      placement="bottom"
      content={(
        <Content>
          {t('The document can be omitted if the commercial invoice includes packing information.')}
        </Content>
      )}
    >
      <InfoIcon />
    </Popover>
  );
};
