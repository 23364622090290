import styled from 'styled-components';

import { Option, Select } from 'app-wrapper/view/components';
import { divFlex } from 'app-wrapper/view/themes/mixin';

export const SelectStyled = styled(Select).attrs({
  'data-class': 'BookingDrawerCompanyNameSelectStyled',
})`
  width: 255px;
  min-width: 255px;
  min-height: 32px;
  height: 32px;

  .ant-select {
    border: 1px solid #d9d9d9;
    height: 32px !important;
    min-height: 32px !important;
  }

  .ant-select-selector {
    height: 32px !important;
  }

  .ant-select-selection-placeholder, .ant-select-selection-item {
    line-height: 32px !important;
  }

  input {
    height: 32px !important;
  }
`;

export const SelectOptionHidden = styled(Option).attrs({
  'data-class': 'SelectOptionHidden',
})`
  ${divFlex}
  display: none;
`;

export const SelectStyledItem = styled.div.attrs({
  'data-class': 'SelectStyledItem',
})`
  ${divFlex}
  align-items: center;

  font-size: 12px;
  font-weight: 400;
  color: ${({ theme }) => theme?.themesColors?.secondaryDot45};
`;

export const SelectStyledFooter = styled.div.attrs({
  'data-class': 'SelectStyledFooter',
})`
  ${divFlex}
  align-items: center;
  z-index: 10;
  height: 32px;

  padding: 5px 12px;
  border-top: 1px solid ${({ theme }) => theme?.themesColors?.neutralBranding4};
  color: ${({ theme }) => theme?.themesColors?.primaryBranding6};
`;

export const SelectStyledFooterText = styled.div.attrs({
  'data-class': 'SelectStyledFooterText',
})`
  ${divFlex}

  margin-left: 8px;
  margin-top: 2px;
  font-size: 12px;
  font-weight: 400;
  color: ${({ theme }) => theme?.themesColors?.primaryBranding6};
`;
