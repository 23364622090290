import React, { FC } from 'react';
import isEmpty from 'lodash/fp/isEmpty';
import Divider from 'antd/es/divider';
import { useTranslation } from 'react-i18next';

import {
  HeaderTableWrapper,
  InnerContainer,
  StyledTotal,
  Title,
} from 'shipment-operations/view/components';
import { CustomTable } from 'shipment-operations/view/pages/CreditNote/components/CustomTable';
import { ExpandPanel } from 'shipment-operations/view/pages/ShipmentBillingInvoice/components';
import { calculateCreditNoteSum } from 'shipment-operations/view/pages/ShipmentBillingInvoice/utils/calculateSum';
import { CreditNoteItemDTM, ContainerViewDTM, IGroupedCreditNotesDTM } from 'shipment-operations/models/dtm';

interface IServicesComponentProps {
  data: IGroupedCreditNotesDTM
  sum: number
  containers: ContainerViewDTM[]
  tableData: CreditNoteItemDTM[]
}

const ServicesComponent: FC<IServicesComponentProps> = ({
  containers, data, tableData, sum,
}) => {
  const { t } = useTranslation();

  return ((!isEmpty(data) || !isEmpty(tableData)) ? (
    <>
      <InnerContainer>
        <Title>{t('services')}</Title>
        {!isEmpty(containers) && (
          <>
            <Divider style={{ margin: '0' }} />
            <HeaderTableWrapper>
              <span>{t('Containers')}</span>
              <span>{t('total')}</span>
            </HeaderTableWrapper>
            <Divider style={{ margin: '0' }} />
          </>
        )}
        {!isEmpty(data) && containers.map((item) => (
          <ExpandPanel container={item} sum={calculateCreditNoteSum(data[item.id])} key={item.id}>
            <CustomTable data={data[item.id]} />
          </ExpandPanel>
        ))}
        {!isEmpty(tableData) && (<CustomTable data={tableData} />)}
      </InnerContainer>
      <StyledTotal>$ {sum.toFixed(2)}</StyledTotal>
    </>
  ) : null);
};

export { ServicesComponent };
