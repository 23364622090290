import React, { FC, useCallback, useMemo } from 'react';

import { EAccountDepartmentType } from 'user-management/constants';
import { OrganizationMemberDTM } from 'user-management/models/dtm';
import { Select, Option } from 'app-wrapper/view/components';
import { FieldWrap, FieldLabel } from 'user-management/view/components/CreateOrEditTeamDrawer/CreateOrEditTeamDrawer.styled';

interface IRoleSelectProps {
  role: EAccountDepartmentType;
  email?: string;
  label: string;
  setRoleEmail: (role: EAccountDepartmentType, email: string) => void;
  members: OrganizationMemberDTM[];
  disableClear?: boolean;
}

export const RoleSelect: FC<IRoleSelectProps> = ({
  role,
  email,
  label,
  setRoleEmail,
  members,
  disableClear,
}) => {
  const membersWithRole = useMemo(() => members.filter((member) => {
    const departmentsTypes = member.departments.map((department) => department.type);

    return departmentsTypes.includes(role);
  }), [role, members]);

  const handleRoleEmailChange = useCallback((newEmail: string) => {
    setRoleEmail(role, newEmail);
  }, [role, setRoleEmail]);

  return (
    <FieldWrap>
      <FieldLabel>
        {label}
      </FieldLabel>

      <Select
        data-class={`role-select-${role}`}
        onChange={handleRoleEmailChange}
        value={email}
        placeholder={null}
        allowClear={!disableClear}
      >
        {membersWithRole.map((member) => (
          <Option
            data-class="role-select-option"
            key={member.id}
            value={member.email}
          >
            {member.getFullName()}
          </Option>
        ))}
      </Select>
    </FieldWrap>
  );
};
