import { ControllerParams } from 'proto/BaseController';
import { UsersController } from 'user-management/controllers/Users';
import { ChangePasswordController } from './ChangePassword';
import { ContactBookController, ContactBookDrawersController } from './ContactBook';
import { CustomerController } from './Customer';
import { NewOrganizationDataController } from './NewOrganizationData';
import { NewOrganizationDocumentsController } from './NewOrganizationDocuments';
import { NewOrganizationSuccessController } from './NewOrganizationSuccess';
import { NewOrganizationWizardController } from './NewOrganizationWizard';
import { NewOrganizationWizardReviewAndConfirmController } from './NewOrganizationWizardReviewAndConfirm';
import { OrganizationProfileController } from './OrganizationProfile';
import { UserEditProfileController } from './UserEditProfile';
import { UserInviteController } from './UserInvite';
import { UserManagementController } from './UserManagement';
import { UserMenuController } from './UserMenu';
import { UserProfileController } from './UserProfile';
import { AccountDepartmentsController } from './AccountDepartments';
import { TeamsController } from './Teams';
import { CustomerSalesController } from './CustomerSales';
import { SalesRepresentativeController } from './SalesRepresentative';

type UseCases = {
  changePassword: ChangePasswordController
  contactBook: ContactBookController
  accountDepartments: AccountDepartmentsController
  contactBookDrawers: ContactBookDrawersController
  newOrganizationWizard: NewOrganizationWizardController
  organizationProfile: OrganizationProfileController
  newOrganizationData: NewOrganizationDataController
  userEditProfile: UserEditProfileController
  userInvite: UserInviteController
  userManagement: UserManagementController
  userMenu: UserMenuController
  userProfile: UserProfileController
  customer: CustomerController
  newOrganizationDocuments: NewOrganizationDocumentsController
  newOrganizationWizardReviewAndConfirm: NewOrganizationWizardReviewAndConfirmController
  newOrganizationSuccess: NewOrganizationSuccessController
  users: UsersController,
  teams: TeamsController,
  customerSales: CustomerSalesController,
  salesRepresentative: SalesRepresentativeController,
}

// @ts-ignore
export const UC: UseCases = {};

export const initUC = (controllerParams: ControllerParams) => {
  UC.changePassword = new ChangePasswordController(controllerParams);
  UC.contactBook = new ContactBookController(controllerParams);
  UC.accountDepartments = new AccountDepartmentsController(controllerParams);
  UC.contactBookDrawers = new ContactBookDrawersController(controllerParams);
  UC.newOrganizationWizard = new NewOrganizationWizardController(controllerParams);
  UC.organizationProfile = new OrganizationProfileController(controllerParams);
  UC.newOrganizationData = new NewOrganizationDataController(controllerParams);
  UC.userEditProfile = new UserEditProfileController(controllerParams);
  UC.userInvite = new UserInviteController(controllerParams);
  UC.userManagement = new UserManagementController(controllerParams);
  UC.userMenu = new UserMenuController(controllerParams);
  UC.userProfile = new UserProfileController(controllerParams);
  UC.customer = new CustomerController(controllerParams);
  UC.newOrganizationDocuments = new NewOrganizationDocumentsController(controllerParams);
  UC.newOrganizationWizardReviewAndConfirm = new NewOrganizationWizardReviewAndConfirmController(controllerParams);
  UC.newOrganizationSuccess = new NewOrganizationSuccessController(controllerParams);
  UC.users = new UsersController(controllerParams);
  UC.teams = new TeamsController(controllerParams);
  UC.customerSales = new CustomerSalesController(controllerParams);
  UC.salesRepresentative = new SalesRepresentativeController(controllerParams);
};
