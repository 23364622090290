import React, {
  useCallback, useMemo, FC,
} from 'react';
import Panel from 'antd/es/collapse/CollapsePanel';
import Skeleton from 'antd/es/skeleton';
import Alert from 'antd/es/alert';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { StyledExpandIcon } from 'shipment-operations/view/components';
import { NameOfDocument } from 'shipment-operations/view/pages/ShipmentBilling/ShipmentBilling.styled';
import {
  CollapseTitle, StyledCollapse, CustomTableHeader, CustomTable,
} from 'shipment-operations/view/pages/ShipmentBillingInvoice/components/Footer/Footer.styled';
import { useToggleCollapse } from 'shipment-operations/view/hooks';
import { LinkedCreditNoteDTM } from 'shipment-operations/models/dtm';

import { RouteNames } from 'app-wrapper/constants';
import get from 'lodash/fp/get';

interface ICreditNotesComponentsProps {
  linkedCreditNotes: LinkedCreditNoteDTM[]
  isLoading: boolean
  isError: boolean
}

const CreditNotesComponent: FC<ICreditNotesComponentsProps> = ({
  linkedCreditNotes, isLoading, isError,
}) => {
  const { t } = useTranslation();
  const { shipmentId } = useParams<'shipmentId'>();
  const { invoiceType } = useParams<'invoiceType'>();

  const { openedKeys, onToggleCollapse } = useToggleCollapse();
  const onToggle = useCallback((key) => () => onToggleCollapse(key), [onToggleCollapse]);

  const columns = useMemo(() => [
    {
      title: 'Date',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: '33%',
    },
    {
      title: 'Reference',
      dataIndex: 'number',
      key: 'number',
      width: '40%',
      render: (item: string, record: LinkedCreditNoteDTM | {}) => {
        const id = get(['id'], record);

        return (
          <NameOfDocument to={RouteNames.SHIPMENT_CREDIT_NOTE(shipmentId, id, invoiceType)}>{item}</NameOfDocument>
        );
      },
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      width: '26%',
      align: 'right' as 'right',
      render: (sum: number) => `($ ${sum.toFixed(2)})`,
    },
  ], []);

  if (!linkedCreditNotes.length) {
    return null;
  }

  return (
    <StyledCollapse ghost expandIcon={StyledExpandIcon} onChange={onToggle(1)}>
      <Panel
        key={1}
        header={
          <CollapseTitle active={openedKeys.includes(1)}>Credit Notes</CollapseTitle>
        }
      >
        {isLoading && (
          <Skeleton />
        )}
        {isError && (
          <Alert
            message={t('creditNote.errorTitle')}
            description={t('creditNote.errorSubTitle')}
            type="error"
          />
        )}
        {(!isLoading && !isError) && (
          <>
            <CustomTableHeader
              columns={columns}
              pagination={false}
            />
            <CustomTable
              showHeader={false}
              pagination={false}
              columns={columns}
              dataSource={linkedCreditNotes}
            />
          </>
        )}
      </Panel>
    </StyledCollapse>
  );
};

export { CreditNotesComponent };
