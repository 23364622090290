import React, { FC, useEffect } from 'react';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import Row from 'antd/es/row';
import Spin from 'antd/es/spin';

import { hasReadAccess } from 'app-wrapper/utils';
import { SpinnerWrap } from 'app-wrapper/view/guideline';
import { TabPane } from 'app-wrapper/view/components';
import { SubPageTabs } from 'app-wrapper/view/guideline/SubPageTabs';
import { PermissionAttributePolicy } from 'shipment-operations/constants';

import {
  GoBack,
  UserData,
  Contacts,
  TeamsAndDepartments,
  MemberTeamsTable,
} from './components';
import {
  Page,
  Content,
  Divider,
  ContentWrap,
  ContentWrapTitle,
} from './UserManagementProfile.styled';

interface IUserProfileComponentProps {
  init: (email: string) => void;
  cleanup: () => void;
  isLoading: boolean;
  userDepartmentsAndTeamsViewPermissions?: PermissionAttributePolicy;
}

export const UserManagementProfileComponent: FC<IUserProfileComponentProps> = ({
  init,
  cleanup,
  isLoading,
  userDepartmentsAndTeamsViewPermissions,
}) => {
  const { t } = useTranslation();
  const { email = '' } = useParams();

  useEffect(() => {
    init(email);

    return () => {
      cleanup();
    };
  }, []);

  const isAbleToViewDepartmentsAndTeams = hasReadAccess(userDepartmentsAndTeamsViewPermissions);

  return (
    <Page>
      <GoBack />

      {isLoading ? (
        <SpinnerWrap>
          <Spin size="large" />
        </SpinnerWrap>
      ) : (
        <SubPageTabs defaultActiveKey="1">
          <TabPane
            tab={t('Overview')}
            key="Overview"
          >
            <Content>
              <UserData />

              <Divider />

              <Row>
                <ContentWrap>
                  <Contacts />
                </ContentWrap>

                {isAbleToViewDepartmentsAndTeams ? (
                  <ContentWrap>
                    <ContentWrapTitle>
                      {t('Departments')}
                    </ContentWrapTitle>

                    <TeamsAndDepartments />
                  </ContentWrap>
                ) : null}
              </Row>
            </Content>
          </TabPane>

          <TabPane
            tab={t('Teams')}
            key="TEAMS"
          >
            <MemberTeamsTable />
          </TabPane>
        </SubPageTabs>
      )}
    </Page>
  );
};
