import { Type } from 'class-transformer';
import {
  IsDefined,
  IsString,
  IsBoolean,
  IsNumber,
  ValidateNested, IsEnum,
} from 'class-validator';
import i18n from 'i18next';
import { BaseDTM } from 'proto/BaseDTM';

import { commonContainersTypesLong, CommonContainerTypes } from 'shipment-operations/constants';

export type TBookingMissMatchField = 'DESCRIPTION' | 'HS_CODE' | 'UN_NUMBER' | 'TYPE' | 'SOC' | 'TEMPERATURE' | 'GENSET' | 'IMO_CLASS';
export type TBookingMissMatchTypeField = 'STRING' | 'NUMBER' | 'BOOLEAN' | 'DATE';
export type TBookingMissMatchRelatesToType = 'CONTAINER' | 'CARGO';

export enum EBookingMissMatchField {
  'DESCRIPTION' = 'DESCRIPTION',
  'HS_CODE' = 'HS_CODE',
  'UN_NUMBER' = 'UN_NUMBER',
  'TYPE' = 'TYPE',
  'SOC' = 'SOC',
  'TEMPERATURE' = 'TEMPERATURE',
  'GENSET' = 'GENSET',
  'IMO_CLASS' = 'IMO_CLASS'
}
enum EBookingMissMatchTypeField {
  'STRING' = 'STRING',
  'NUMBER' = 'NUMBER',
  'BOOLEAN' = 'BOOLEAN',
  'DATE' = 'DATE'
}
enum EBookingMissMatchRelatesToType {
  'CONTAINER' = 'CONTAINER',
  'CARGO' = 'CARGO',
}

export interface IBookingMissMatchDTM {
  id: number;
  requested: string | boolean | number;
  confirmed: string | boolean | number;
  valueType: TBookingMissMatchTypeField;
  field: TBookingMissMatchField;
  relatesToType: TBookingMissMatchRelatesToType;
}

export class BookingMissMatchDTM extends BaseDTM<IBookingMissMatchDTM> {
  @IsDefined()
  @IsNumber()
  id: number;

  @IsDefined()
  @IsString()
  @IsBoolean()
  @IsNumber()
  requested: string | boolean | number;

  @IsDefined()
  @IsString()
  @IsBoolean()
  @IsNumber()
  confirmed: string | boolean | number;

  @IsDefined()
  @IsEnum(EBookingMissMatchTypeField)
  valueType: TBookingMissMatchTypeField;

  @IsDefined()
  @IsEnum(EBookingMissMatchField)
  field: TBookingMissMatchField;

  @IsDefined()
  @IsEnum(EBookingMissMatchRelatesToType)
  relatesToType: TBookingMissMatchRelatesToType;

  public getValueToRender = (key: 'requested' | 'confirmed') => {
    const value = this[key];

    if (value === null) {
      return null;
    }

    if (this.field === 'TYPE') {
      return commonContainersTypesLong[value as CommonContainerTypes];
    }

    if (this.field === 'SOC' || this.field === 'GENSET') {
      return value ? i18n.t('Yes') : i18n.t('No');
    }

    return value;
  }
}

export interface IBookingMissMatchesGroupDTM {
  type: TBookingMissMatchRelatesToType;
  id: number;
  missMatches: IBookingMissMatchDTM[];
}

export class BookingMissMatchesGroupDTM extends BaseDTM<IBookingMissMatchesGroupDTM> {
  @IsDefined()
  type: TBookingMissMatchRelatesToType;

  @IsDefined()
  @IsNumber()
  id: number;

  @IsDefined()
  @ValidateNested({ each: true })
  @Type(() => BookingMissMatchDTM)
  missMatches: BookingMissMatchDTM[];
}

export interface IBookingMissMatchesDTM {
  id: number;
  containerMissMatches: IBookingMissMatchesGroupDTM[];
  cargoMissMatches: IBookingMissMatchesGroupDTM[];
}

export class BookingMissMatchesDTM extends BaseDTM<IBookingMissMatchesDTM> {
  @IsDefined()
  @IsNumber()
  id: number;

  @IsDefined()
  @ValidateNested({ each: true })
  @Type(() => BookingMissMatchesGroupDTM)
  cargoMissMatches: BookingMissMatchesGroupDTM[];

  @IsDefined()
  @ValidateNested({ each: true })
  @Type(() => BookingMissMatchesGroupDTM)
  containerMissMatches: BookingMissMatchesGroupDTM[];

  static groupCargoMissMatches = (items: BookingMissMatchDTM[]): BookingMissMatchesGroupDTM[] => {
    const missMatchesGroupMap: Record<string, BookingMissMatchesGroupDTM> = {};

    items.forEach((missMatch) => {
      if (missMatchesGroupMap[missMatch.id]) {
        missMatchesGroupMap[missMatch.id].missMatches.push(missMatch);
      } else {
        missMatchesGroupMap[missMatch.id] = BookingMissMatchesGroupDTM.fromPlain({
          id: missMatch.id,
          missMatches: [missMatch],
          type: 'CARGO',
        });
      }
    });

    const missMatchesGroups = Object.values(missMatchesGroupMap).map((group) => ({
      ...group,
      missMatches: [
        ...(group.missMatches.filter(({ field }) => field === 'HS_CODE')),
        ...(group.missMatches.filter(({ field }) => field === 'DESCRIPTION')),
        ...(group.missMatches.filter(({ field }) => field === 'UN_NUMBER')),
        ...(group.missMatches.filter(({ field }) => field === 'IMO_CLASS')),
      ],
    }));

    return missMatchesGroups;
  }

  static groupContainerMissMatches = (items: BookingMissMatchDTM[]): BookingMissMatchesGroupDTM[] => {
    const missMatchesGroupMap: Record<string, BookingMissMatchesGroupDTM> = {};

    items.forEach((missMatch) => {
      if (missMatchesGroupMap[missMatch.id]) {
        missMatchesGroupMap[missMatch.id].missMatches.push(missMatch);
      } else {
        missMatchesGroupMap[missMatch.id] = BookingMissMatchesGroupDTM.fromPlain({
          id: missMatch.id,
          missMatches: [missMatch],
          type: 'CONTAINER',
        });
      }
    });

    const missMatchesGroups = Object.values(missMatchesGroupMap).map((group) => ({
      ...group,
      missMatches: [
        ...(group.missMatches.filter(({ field }) => field === 'TYPE')),
        ...(group.missMatches.filter(({ field }) => field === 'TEMPERATURE')),
        ...(group.missMatches.filter(({ field }) => field === 'GENSET')),
        ...(group.missMatches.filter(({ field }) => field === 'SOC')),
      ],
    }));

    return missMatchesGroups;
  }

  static groupMissMatches = (missMatches: BookingMissMatchDTM[]) => {
    const cargoMissMatches = missMatches.filter(({ relatesToType }) => relatesToType === 'CARGO');
    const containerMissMatches = missMatches.filter(({ relatesToType }) => relatesToType === 'CONTAINER');

    return {
      containerMissMatches: this.groupContainerMissMatches(containerMissMatches),
      cargoMissMatches: this.groupCargoMissMatches(cargoMissMatches),
    };
  };
}
