import React, { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import DownloadOutlined from '@ant-design/icons/DownloadOutlined';
import FileExcelOutlined from '@ant-design/icons/FileExcelOutlined';
import CheckCircleFilled from '@ant-design/icons/CheckCircleFilled';
import CloseCircleFilled from '@ant-design/icons/CloseCircleFilled';
import CloseOutlined from '@ant-design/icons/CloseOutlined';
import UploadOutlined from '@ant-design/icons/UploadOutlined';
import Upload from 'antd/es/upload';
import { RcFile } from 'antd/es/upload/interface';
import Spin from 'antd/es/spin';
import { LoadingOutlined } from '@ant-design/icons';

import { Tooltip } from 'app-wrapper/view/components';

import { ERateTypes, RateNames } from 'monetary/constants';
import { RateDtm } from 'monetary/models/dtm';

import {
  RateItemWrapper, RateItemTemplateButton, RateItemName, RateItemDownloadButton, RateItemUploadButton, RateItemWarningMessage, RateItemWarningMessageIcon, RateItemWarningCloseIcon,
} from './RateItem.styled';

interface IRateItemComponentProps {
  rateItem: RateDtm
  isLoading?: boolean
  fileSend?: string
  openRatesDownload: (rateType: ERateTypes) => void
  onRateUpload: (rateType: ERateTypes, file?: File) => void
  onCloseMessageWarning: (rateType: ERateTypes) => void
}

const antIcon = <LoadingOutlined style={{ fontSize: 18, color: '#FFF' }} spin />;

export const RateItemComponent: FC<IRateItemComponentProps> = ({
  rateItem,
  isLoading,
  fileSend,
  openRatesDownload,
  onRateUpload,
  onCloseMessageWarning,
}) => {
  const { t } = useTranslation();

  const onDownloadHandler = useCallback(() => {
    openRatesDownload(rateItem.type);

    return false; // to prevent native ant upload
  }, []);

  const onUploadFileHandler = useCallback((file: RcFile) => {
    onRateUpload(rateItem.type, file);
  }, [rateItem.type]);

  const onCloseMessageWarningHandler = useCallback(() => {
    onCloseMessageWarning(rateItem.type);
  }, [rateItem.type]);

  const loading = isLoading && rateItem.type === fileSend;

  const TemplateLinkComponent = useMemo(() => (
    <RateItemTemplateButton
      type="ghost"
      icon={<FileExcelOutlined />}
      href={rateItem.templateLink}
      target="_blank"
    />
  ), [rateItem]);

  return (
    <RateItemWrapper>
      {rateItem.templateLinkTooltip ? (
        <Tooltip
          title={rateItem.templateLinkTooltip}
          placement="top"
        >
          {TemplateLinkComponent}
        </Tooltip>
      )
        : TemplateLinkComponent}

      <RateItemName>{RateNames[rateItem.type]}</RateItemName>

      {rateItem.warningMessage ? (
        <RateItemWarningMessage isError={rateItem.isErrorMessage}>
          <RateItemWarningMessageIcon isError={rateItem.isErrorMessage}>
            {rateItem.isErrorMessage ? (
              <CloseCircleFilled />
            )
              : (
                <CheckCircleFilled />
              )}
          </RateItemWarningMessageIcon>
          {rateItem.warningMessage}
          <RateItemWarningCloseIcon onClick={onCloseMessageWarningHandler}>
            <CloseOutlined />
          </RateItemWarningCloseIcon>
        </RateItemWarningMessage>
      ) : null}
      <RateItemDownloadButton
        type="ghost"
        icon={<DownloadOutlined />}
        onClick={onDownloadHandler}
        isHasLeftBlock={!!rateItem.warningMessage}
      >
        {t('Download Rates')}
      </RateItemDownloadButton>
      <Upload
        showUploadList={false}
        multiple={false}
        customRequest={() => ''}
        beforeUpload={onUploadFileHandler}
        disabled={loading}
      >
        <RateItemUploadButton icon={<UploadOutlined />}>
          {t('Upload Rates')}
          {loading && <Spin indicator={antIcon} />}
        </RateItemUploadButton>
      </Upload>
    </RateItemWrapper>
  );
};
