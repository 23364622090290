import { emptyScrollbar } from 'app-wrapper/view/themes/components/emptyScrollbar';
import styled from 'styled-components';

import { divFlex } from 'app-wrapper/view/themes/mixin';
import { Button } from 'app-wrapper/view/components';

export const AACSpanEllipsis = styled.span.attrs({
  'data-class': 'AACSpanEllipsis',
})`
  max-width: 100%;
  position: relative;
  display: inline;

  text-overflow: ellipsis;
  overflow: clip;
  white-space: nowrap;
`;

export const ContactBookListTableDiv = styled.div.attrs({
  'data-class': 'ContactBookListTableDiv',
})`
  ${divFlex}
  flex-direction: column;
  width: 100%;

  margin: 13px 24px 13px 0;
  padding: 16px;
  padding-bottom: 0;
  background-color: ${({ theme }) => theme.themesColors.neutralBranding1};
`;

export const ContactBookListTableTitleWrap = styled.div.attrs({
  'data-class': 'ContactBookListTableTitleWrap',
})`
  ${divFlex}

  height: 48px;
  width: 100%;
  align-items: center;
`;

export const ContactBookListTableTitle = styled.div.attrs({
  'data-class': 'ContactBookListTableTitle',
})`
  ${divFlex}
  width: 100%;
  align-items: center;

  color: ${({ theme }) => theme.themesColors.neutralBranding10};
  font-size: 16px;
  font-weight: 500;
`;

export const ContactBookListTableHeader = styled.div.attrs({
  'data-class': 'ContactBookListTableHeader',
})`
  ${divFlex}
  align-items: center;

  height: 36px;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.themesColors.neutralBranding3};
  padding-bottom: 8px;
`;

export const ContactBookListTableHeaderAddBtn = styled(Button).attrs({
  'data-class': 'ContactBookListTableHeaderAddBtn',
})`
  ${divFlex}
  width: 140px;
  height: 32px;

  svg {
    color: ${({ theme }) => theme.themesColors.neutralBranding1};
    margin-right: 9px;
  }
  span {
    color: ${({ theme }) => theme.themesColors.neutralBranding1};
    font-weight: 400;
  }
`;

export const ContactBookListTableHeaderItem = styled.div.attrs((props: {
  isCenter?: boolean,
  width?: string,
  paddingLeft?: string,
  marginLeft?: string,
}) => ({
  isCenter: props.isCenter,
  width: props.width,
  paddingLeft: props.paddingLeft,
  marginLeft: props.marginLeft,
  'data-class': 'ContactBookListTableHeaderItem',
}))`
  ${divFlex}
  align-items: center;
  justify-content: ${({ isCenter }) => (isCenter ? 'center' : 'left')};

  width: ${({ width }) => (width || 'auto')};
  padding-left: ${({ paddingLeft }) => (paddingLeft || '0')};
  margin-left: ${({ marginLeft }) => (marginLeft || '0')};

  color: ${({ theme }) => theme.themesColors.secondaryDot45};
  font-size: 12px;
  font-weight: 400;
`;

export const ContactBookListTableContent = styled.div.attrs({
  'data-class': 'ContactBookListTableContent',
})`
  ${divFlex}
  flex-direction: column;

  padding-bottom: 6px;
  .infinite-scroll-component {
    min-height: 50vh;
  }
`;

export const ContactBookListTableContentItem = styled.div.attrs({
  'data-class': 'ContactBookListTableContentItem',
})`
  ${divFlex}
  flex-direction: column;
  width: 100%;

  border-bottom: 1px solid ${({ theme }) => theme.themesColors.neutralBranding3}
`;

export const ContactBookListTableContentItemTop = styled.div.attrs({
  'data-class': 'ContactBookListTableContentItemTop',
})`
  ${divFlex}
  ${emptyScrollbar}

  height: 55px;
`;

export const ContactBookListTableContentTitle = styled.div.attrs((props: {
  isActive?: boolean,
  isCapitalize?: boolean,
}) => ({
  isActive: props.isActive,
  isCapitalize: props.isCapitalize,
  'data-class': 'ContactBookListTableContentTitle',
}))`
  ${divFlex}
  width: calc(17.8%);
  align-items: center;
  justify-content: left;

  margin-left: 10px;
  color: ${({ theme }) => theme.themesColors.characterBrandingTitle85};
  color: ${({ theme, isActive }) => (isActive ? theme.themesColors.primaryBranding6 : theme.themesColors.characterBrandingTitle85)};
  font-size: 14px;
  font-weight: 400;

  ${({ isCapitalize }) => isCapitalize && 'text-transform: capitalize'};
`;

export const ContactBookListTableContentLink = styled.div.attrs((props: {
  width?: string,
}) => ({
  width: props.width,
  'data-class': 'ContactBookListTableContentLink',
}))`
  ${divFlex}
  width: ${({ width }) => (width || '42.7%')};
  align-items: center;
  justify-content: left;

  color: ${({ theme }) => theme.themesColors.primaryBranding7};
  font-size: 14px;
  font-weight: 400;

  text-overflow: ellipsis;
  overflow: clip;
  white-space: nowrap;
  cursor: pointer;

  > span:first-letter {
    text-transform: uppercase;
  }
`;

export const ContactBookListTableContentPrimary = styled.div.attrs({
  'data-class': 'ContactBookListTableContentPrimary',
})`
  ${divFlex}
  width: 58px;
  height: 22px;
  align-items: center;
  justify-content: center;

  background-color: ${({ theme }) => theme.themesColors.polarGreen1};
  color: ${({ theme }) => theme.themesColors.polarGreen7};
  font-size: 12px;
  font-weight: 400;
  border: 1px solid ${({ theme }) => theme.themesColors.polarGreen3};
  margin-left: 8px;
  border-radius: 3px;
`;

export const ContactBookListTableContactWrap = styled.div.attrs({
  'data-class': 'ContactBookListTableContactWrap',
})`
  ${divFlex}
  width: 17.8%;
  align-items: center;
  justify-content: center;
`;

export const ContactBookListTableContact = styled.div.attrs({
  'data-class': 'ContactBookListTableContact',
})`
  ${divFlex}
  align-items: center;
  justify-content: center;
  min-width: 24px;
  width: 24px;
  height: 24px;

  margin: auto;
  color: ${({ theme }) => theme.themesColors.primaryBranding5};
  background-color: ${({ theme }) => theme.themesColors.primaryBranding1};
  font-size: 12px;
  font-weight: 400;
  border-radius: 100px;
`;
