import { BaseController, controller } from 'proto/BaseController';
import { R } from 'shipment-operations/repository';
import { apiWorker } from 'app-wrapper/repository/utilsServices';
import { ParsedInvoiceDTM } from 'shipment-operations/models/dtm';

@controller
export class ShipmentBillingController extends BaseController {
  loadData = async (shipmentId?: string, type?: string) => {
    if (!shipmentId || !type) {
      return;
    }
    let response: ParsedInvoiceDTM[] | null;

    this.dispatch(R.actions.shipmentBilling.setIsLoading(true));
    try {
      response = await R.services.shipmentBilling.getShipmentEntities(shipmentId, type);
    } catch (e) {
      this.dispatch(R.actions.shipmentBilling.setError(true));
      return;
    }
    this.dispatch(R.actions.shipmentBilling.setShipmentBilling(response));
  }

  resetData = () => {
    apiWorker.abortAllRequests();
    this.dispatch(R.actions.shipmentBilling.clear());
  }
}
