import React, {
  useContext, useRef, useState, useEffect, useMemo,
} from 'react';
import Form from 'antd/es/form';
import InputNumber from 'antd/es/input-number';
import { useTranslation } from 'react-i18next';

import {
  Tooltip,
} from 'app-wrapper/view/components';

import { EditableContext } from 'shipment-operations/view/components/EditableRow';
import { EditableCellWrapper, ChildrenWrapper } from 'shipment-operations/view/pages/CreateCreditNote/components/SecondStep/components/CustomTableEditable/components/Cell/Cell.styled';

interface IRecordProps {
  id?: string
  dueDate?: string
  total?: number
  balance?: number
  payment?: number
  innerId?: number
}

interface EditableCellProps {
  title: React.ReactNode;
  editable: boolean;
  children: React.ReactNode;
  dataIndex: string
  record: IRecordProps
  handleSave: (record: IRecordProps, type: string) => void;
}

const EditableCell: React.FC<EditableCellProps> = ({
  title,
  dataIndex,
  record,
  editable,
  children,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const form = useContext(EditableContext)!;
  const { t } = useTranslation();

  useEffect(() => {
    if (editing) {
      inputRef.current!.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({ [dataIndex]: record[dataIndex as keyof typeof record] });
  };

  const save = async () => {
    try {
      const value = await form.validateFields();
      toggleEdit();
      handleSave({ ...record, ...value }, dataIndex);
    } catch (errInfo) {
      const value = {
        [dataIndex]: 0,
      };
      handleSave({ ...record, ...value }, dataIndex);
      toggleEdit();
    }
  };

  const isError = useMemo(() => (record?.payment || 0) > (record?.balance || 0), [record]);

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{ margin: 0 }}
        name={dataIndex}
        rules={[
          {
            required: true,
            message: `${title} is required`,
          },
        ]}
      >
        <InputNumber
          ref={inputRef}
          onPressEnter={save}
          onBlur={save}
          style={{
            textAlign: 'right', width: '100%', fontSize: '12px', borderColor: isError ? 'red' : '',
          }}
          min={0}
        />
      </Form.Item>
    ) : (
      <EditableCellWrapper onClick={toggleEdit} id="editableCell" style={{ borderColor: isError ? 'red' : '' }}>
        <Tooltip
          title={t('payment.error.transaction')}
          placement="bottomRight"
          mode="danger"
          visible={isError}
        >
          $ {children}
        </Tooltip>
      </EditableCellWrapper>
    );
  }

  return (
    <td {...restProps}>
      <ChildrenWrapper>
        {childNode}
      </ChildrenWrapper>
    </td>
  );
};

export { EditableCell };
