import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';
import { UC } from 'shipment-operations/controllers';

import { ShipmentContainersTabsComponent } from './ShipmentContainersTabs.component';

export const ShipmentContainersTabsContainer: FC = () => {
  const containersBasicType = useSelector(R.selectors.shipment.getContainersBasicType);
  const selectedContainersType = useSelector(R.selectors.shipmentContainers.getSelectedContainersType);
  const containers = useSelector(R.selectors.shipmentContainers.getLists);

  return (
    <ShipmentContainersTabsComponent
      containers={containers}
      containersBasicType={containersBasicType}
      selectedItem={selectedContainersType}
      setSelectedItem={UC.shipmentContainers.selectActiveTabItem}
    />
  );
};

export { ShipmentContainersTabsContainer as ShipmentContainersTabs };
