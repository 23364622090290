import message from 'antd/es/message';
import i18n from 'i18next';

import { DateDtm } from 'app-wrapper/models/dtm';
import { getLocationToOneString } from 'app-wrapper/utils';

import { R } from 'monetary/repository';
import { ECarrierSCAC } from 'monetary/constants';
import { FreightSelectFieldDTM } from 'monetary/models/dtm';
import { ILocationsServiceDTM } from 'monetary/models/dtm/Quotas';
import { BaseController, controller } from 'proto/BaseController';

@controller
export class SurchargesRatesModalController extends BaseController {
  searchOriginLocationTimeoutId: number | undefined = undefined

  searchDestinationLocationTimeoutId: number | undefined = undefined

  downloadRates = async () => {
    this.validateDate();
    this.validateOriginLocation();
    this.validateDestinationLocation();

    this.dispatch(R.actions.surchargesRatesModal.setLoading(true));

    const date = R.selectors.surchargesRatesModal.getDate(this.store.getState());
    const originLocation = R.selectors.surchargesRatesModal.getOriginLocation(this.store.getState());
    const destinationLocation = R.selectors.surchargesRatesModal.getDestinationLocation(this.store.getState());
    const carrierScacList = R.selectors.surchargesRatesModal.getCarrierScacList(this.store.getState());

    const isFormDataValid = R.selectors.surchargesRatesModal.isFormDataValid(this.store.getState());

    if (
      !isFormDataValid || (
        !date
        || !originLocation
        || !destinationLocation
        || !carrierScacList
      )
    ) {
      return;
    }

    try {
      await R.services.rate.downloadOceanSurchargesRate(date, originLocation, destinationLocation, carrierScacList);
    } catch (e) {
      message.error(i18n.t('Something went wrong'));

      this.dispatch(R.actions.surchargesRatesModal.setLoading(false));

      return;
    }

    this.dispatch(R.actions.surchargesRatesModal.setOpen(false));
    this.dispatch(R.actions.surchargesRatesModal.clear());

    message.success(i18n.t('File downloaded'));
  }

  openModal = () => {
    this.dispatch(R.actions.surchargesRatesModal.setOpen(true));
  }

  closeModal = () => {
    this.dispatch(R.actions.surchargesRatesModal.setOpen(false));
    this.dispatch(R.actions.surchargesRatesModal.clear());
  }

  setDate = (date: DateDtm | null) => {
    if (!date) {
      this.dispatch(R.actions.surchargesRatesModal.clearDate());

      return;
    }

    this.dispatch(R.actions.surchargesRatesModal.setDate(date));
  }

  blurDateField = () => {
    this.validateDate();
  }

  setOriginLocation = (code: string) => {
    if (!code) {
      this.dispatch(R.actions.surchargesRatesModal.clearOriginLocation());

      return;
    }

    this.dispatch(R.actions.surchargesRatesModal.setOriginLocation(code));
  }

  blurOriginLocationField = () => {
    this.validateOriginLocation();
  }

  setDestinationLocation = (code: string) => {
    if (!code) {
      this.dispatch(R.actions.surchargesRatesModal.clearDestinationLocation());

      return;
    }

    this.dispatch(R.actions.surchargesRatesModal.setDestinationLocation(code));
  }

  blurDestinationLocationField = () => {
    this.validateDestinationLocation();
  }

  searchOriginLocation = async (searchValue: string) => {
    let result: FreightSelectFieldDTM[] | null = null;
    let response: ILocationsServiceDTM[] | null = null;

    clearInterval(this.searchOriginLocationTimeoutId);

    await new Promise((resolve) => {
      this.searchOriginLocationTimeoutId = window.setTimeout(resolve, 400);
    });

    if (!searchValue) {
      this.dispatch(R.actions.surchargesRatesModal.setOriginLocationSearchList([]));

      return;
    }

    this.dispatch(R.actions.surchargesRatesModal.setOriginLocationSearchListLoading(true));

    try {
      response = await R.services.RFQServiceById.getLocationService({
        query: searchValue,
      });

      if (!response) {
        return;
      }

      result = response.map((itemLocation) => FreightSelectFieldDTM.fromPlain({
        description: getLocationToOneString({
          country: itemLocation?.country?.name,
          state: itemLocation?.locationName,
          code: itemLocation?.code,
        }),
        code: itemLocation.code,
        timezoneId: itemLocation.timeZoneId,
      }));
    } catch (e) {
      console.error('getRFQPortAutocompleteOrigin: error', e);

      this.dispatch(R.actions.surchargesRatesModal.setOriginLocationSearchListLoading(false));

      return;
    }

    if (!result) return;

    this.dispatch(R.actions.surchargesRatesModal.setOriginLocationSearchList(result));
    this.dispatch(R.actions.surchargesRatesModal.setOriginLocationSearchListLoading(false));
  }

  searchDestinationLocation = async (searchValue: string) => {
    let result: FreightSelectFieldDTM[] | null = null;
    let response: ILocationsServiceDTM[] | null = null;

    clearInterval(this.searchDestinationLocationTimeoutId);

    await new Promise((resolve) => {
      this.searchDestinationLocationTimeoutId = window.setTimeout(resolve, 400);
    });

    if (!searchValue) {
      this.dispatch(R.actions.surchargesRatesModal.setDestinationLocationSearchList([]));

      return;
    }

    this.dispatch(R.actions.surchargesRatesModal.setDestinationLocationSearchListLoading(true));

    try {
      response = await R.services.RFQServiceById.getLocationService({
        query: searchValue,
      });

      if (!response) {
        return;
      }

      result = response.map((itemLocation) => FreightSelectFieldDTM.fromPlain({
        description: getLocationToOneString({
          country: itemLocation?.country?.name,
          state: itemLocation?.locationName,
          code: itemLocation?.code,
        }),
        code: itemLocation.code,
        timezoneId: itemLocation.timeZoneId,
      }));
    } catch (e) {
      console.error('getRFQPortAutocompleteOrigin: error', e);

      this.dispatch(R.actions.surchargesRatesModal.setDestinationLocationSearchListLoading(false));

      return;
    }

    if (!result) return;

    this.dispatch(R.actions.surchargesRatesModal.setDestinationLocationSearchList(result));
    this.dispatch(R.actions.surchargesRatesModal.setDestinationLocationSearchListLoading(false));
  }

  setCarrierSCACList = (carrierScacList: ECarrierSCAC[]) => {
    this.dispatch(R.actions.surchargesRatesModal.setCarrierScacList(carrierScacList));
  }

  private validateDate = () => {
    const effectiveDate = R.selectors.surchargesRatesModal.getDate(this.store.getState());

    this.dispatch(R.actions.surchargesRatesModal.clearDateError());

    if (!effectiveDate) {
      this.dispatch(R.actions.surchargesRatesModal.setDateError(new Error(i18n.t('basicErrors.REQUIRED_MESSAGE'))));
    }
  }

  private validateOriginLocation = () => {
    const originLocation = R.selectors.surchargesRatesModal.getOriginLocation(this.store.getState());

    this.dispatch(R.actions.surchargesRatesModal.clearOriginLocationError());

    if (!originLocation) {
      this.dispatch(R.actions.surchargesRatesModal.setOriginLocationError(new Error(i18n.t('basicErrors.REQUIRED_MESSAGE'))));
    }
  }

  private validateDestinationLocation = () => {
    const destinationLocation = R.selectors.surchargesRatesModal.getDestinationLocation(this.store.getState());

    this.dispatch(R.actions.surchargesRatesModal.clearDestinationLocationError());

    if (!destinationLocation) {
      this.dispatch(R.actions.surchargesRatesModal.setDestinationLocationError(new Error(i18n.t('basicErrors.REQUIRED_MESSAGE'))));
    }
  }
}
