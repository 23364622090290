import React, {
  FC, memo, useCallback, useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'antd/es/modal';

import { useWindowSize } from 'app-wrapper/hooks';

import {
  IContactBookDefaultStateCompanyListStateDTM,
  IContactBookUpdateStateCompanyListStateErrorsDTM,
} from 'user-management/models/dtm';
import { TooltipError } from 'user-management/view/components';

import {
  CBByIdContentBodyForm,
  CBByIdContentBodyFormCompanyFirstBlock,
  CBByIdContentBodyFormCompanyName,
  CBByIdContentBodyFormCompanyNameInput,
  CBByIdContentBodyFormCompanyNameTitle,
  CBByIdContentBodyFormCompanyNameTitleWrapper,
  CBByIdContentBodyFormCompanyTaxId,
  CBByIdContentBodyFormCompanyTaxIdInput,
  CBByIdContentBodyFormCompanyTaxIdTitle,
  CBByIdContentBodyFormCompanyTaxIdTitleWrapper,
  CBByIdContentBodyFormFooter,
  CBByIdContentBodyFormWrap,
  CBByIdContentBodyFormWrapper,
} from './ContactBookByIdEditCompany.styled';

interface IContactBookByIdEditCompanyComponentProps {
  companyListState?: IContactBookDefaultStateCompanyListStateDTM
  companyListStateIndex: string
  isUpdating?: boolean
  errors?: IContactBookUpdateStateCompanyListStateErrorsDTM
  onRemoveCompany: () => void
  updateForm: () => void
  onDiscard: () => void
  onChangeListName: (name: string) => void
  onBlurListName: () => void
  onFocusListName: () => void
  onChangeTaxId: (taxId: string) => void
  onBlurTaxId: () => void
  onFocusTaxId: () => void
}

const ContactBookByIdEditCompanyComponent: FC<IContactBookByIdEditCompanyComponentProps> = (props) => {
  const {
    companyListState,
    companyListStateIndex,
    isUpdating,
    errors,
    onRemoveCompany,
    updateForm,
    onDiscard,
    onChangeListName,
    onBlurListName,
    onFocusListName,
    onChangeTaxId,
    onBlurTaxId,
    onFocusTaxId,
  } = props;
  const { t } = useTranslation();
  const largeInput = useWindowSize().isFullMediaWidth;

  const onChangeListNameHandler = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onChangeListName(event.target.value);
    },
    [],
  );

  const onChangeTaxIdHandler = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onChangeTaxId(event.target.value);
    },
    [],
  );

  const hasErrorName = useMemo(() => {
    const errDocument = errors?.name;

    return !!(errDocument
      && errDocument?.message
      && (errDocument?.isBlur || errDocument?.isVisited));
  }, [companyListStateIndex, errors]);

  const hasErrorTaxId = useMemo(() => {
    const errDocument = errors?.taxId;

    return !!(errDocument
      && errDocument?.message
      && (errDocument?.isBlur || errDocument?.isVisited));
  }, [companyListStateIndex, errors]);

  const tooltipMessage = errors?.name?.message || '';
  const tooltipVisible = errors?.name?.isTooltipVisible || false;

  const handleClickRemove = useCallback(() => {
    Modal.confirm({
      title: t('Are you sure you want to remove this Company?'),
      okText: t('Yes'),
      cancelText: t('No'),
      onOk: onRemoveCompany,
    });
  }, [onRemoveCompany, t]);

  return (
    <CBByIdContentBodyFormWrap>
      <CBByIdContentBodyForm>
        <CBByIdContentBodyFormWrapper>
          <CBByIdContentBodyFormCompanyFirstBlock>
            <CBByIdContentBodyFormCompanyName>
              <CBByIdContentBodyFormCompanyNameTitleWrapper>
                <CBByIdContentBodyFormCompanyNameTitle>
                  {`${t('CompanyName')} ${t('requiredSymbol')}`}
                </CBByIdContentBodyFormCompanyNameTitle>
              </CBByIdContentBodyFormCompanyNameTitleWrapper>
              <TooltipError
                message={tooltipMessage}
                visible={tooltipVisible}
              >
                <CBByIdContentBodyFormCompanyNameInput
                  largeInput={largeInput}
                  value={companyListState?.name || ''}
                  hasError={hasErrorName}
                  onChange={onChangeListNameHandler}
                  onBlur={onBlurListName}
                  onFocus={onFocusListName}
                />
              </TooltipError>
            </CBByIdContentBodyFormCompanyName>

            <CBByIdContentBodyFormCompanyTaxId>
              <CBByIdContentBodyFormCompanyTaxIdTitleWrapper>
                <CBByIdContentBodyFormCompanyTaxIdTitle>
                  {t('TaxID')}
                </CBByIdContentBodyFormCompanyTaxIdTitle>
              </CBByIdContentBodyFormCompanyTaxIdTitleWrapper>
              <CBByIdContentBodyFormCompanyTaxIdInput
                largeInput={largeInput}
                value={companyListState?.taxId || ''}
                hasError={hasErrorTaxId}
                onChange={onChangeTaxIdHandler}
                onBlur={onBlurTaxId}
                onFocus={onFocusTaxId}
              />
            </CBByIdContentBodyFormCompanyTaxId>
          </CBByIdContentBodyFormCompanyFirstBlock>

        </CBByIdContentBodyFormWrapper>

      </CBByIdContentBodyForm>

      <CBByIdContentBodyFormFooter
        saveName={t('Save')}
        discardName={t('cancel')}
        hasRemove
        removeChanges={handleClickRemove}
        discardChanges={onDiscard}
        updateForm={updateForm}
        disabledUpdate={isUpdating}
        sizeType="middle"
      />
    </CBByIdContentBodyFormWrap>
  );
};

const ContactBookByIdEditCompanyComponentCache = memo(ContactBookByIdEditCompanyComponent);

export { ContactBookByIdEditCompanyComponentCache as ContactBookByIdEditCompanyComponent };
