import { BaseController, controller } from 'proto/BaseController';
import { R } from 'shipment-operations/repository';
import { ChargeDTM } from 'shipment-operations/models/dtm';

@controller
export class ShipmentTransportationChargesController extends BaseController {
  loadData = async (shipmentId?: string) => {
    if (!shipmentId) {
      return;
    }
    let response: ChargeDTM[] | null;

    this.dispatch(R.actions.shipmentTransportationCharges.setIsLoading(true));
    try {
      response = await R.services.shipmentTransportationCharges.getCharges(shipmentId);
    } catch (e) {
      this.dispatch(R.actions.shipmentTransportationCharges.setError(true));
      return;
    }
    this.dispatch(R.actions.shipmentTransportationCharges.setData(response));
  }

  togglePercentage = (status: boolean) => {
    this.dispatch(R.actions.shipmentTransportationCharges.setPercentage(status));
  }

  onLeave = () => {
    this.dispatch(R.actions.shipmentTransportationCharges.clear());
  }
}
