import React, { FC, useMemo } from 'react';

import { ContainerDTM } from 'shipment-operations/models/dtm';
import { ContainerTypesConst } from 'shipment-operations/constants';
import { ContainerType } from 'shipment-operations/view/components/ExpandPanel/ExpandPanel.styled';

import { ContainerColumn, ContainerColumnName } from './ContainerColumn.styled';

export interface ISDHContainerColumnComponent {
  container: ContainerDTM;
}

const ContainerColumnComponent: FC<ISDHContainerColumnComponent> = ({ container }) => {
  const { type } = container;

  const containerType = useMemo(() => ContainerTypesConst[type], [type]);

  return (
    <ContainerColumn>
      {containerType ? (
        <ContainerType active={false}>
          {ContainerTypesConst[type]} &apos;
        </ContainerType>
      ) : null}

      <ContainerColumnName>
        {container.getContainerName()}
      </ContainerColumnName>
    </ContainerColumn>
  );
};

export { ContainerColumnComponent as ContainerColumn };
