import React, {
  FC, memo, useEffect, useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';

import { useWindowSize } from 'app-wrapper/hooks';
import { AlertBlock, PageHeader } from 'app-wrapper/view/components';

import { AccountDrawer } from 'user-management/view/pages/Account/components';

import {
  FreightQuoteContentModalSave, FreightQuoteFormRequest, RCQNoQuoteErrorComponent, RFQResponse,
} from 'monetary/view/components';
import { RFQNewQuotaAvailabilityDTM } from 'monetary/models/dtm';
import { QuotaRFRRequestOptionsDTM } from 'monetary/models/dtm/Quotas';

import { BookingDrawer } from 'shipment-operations/view/components';

import { SelfServiceSwitch } from './components';

import {
  FreightQuoteContentPage,
  FreightQuoteContentWrapper,
  FreightQuoteContentComponentDiv,
  FreightQuoteContentContainer,
  FreightQuoteContentContainerBGWrapper,
  BackgroundWrapper,
  FreightQuoteContentHeaderSaveBtn,
  FreightQuoteContentHeaderWrap,
} from './FreightQuoteContent.styled';

interface IFreightQuoteContentComponentProps {
  isCollapse?: boolean;
  isCollapseAndEmptyQuota?: boolean;
  isEmptyBg?: boolean;
  RFQNewQuotaAvailability?: RFQNewQuotaAvailabilityDTM
  quotasCreateFailedMessage?: string;
  isDisableModalRequestName?: boolean;
  quotaId?: string;
  isQuotaEmptyError?: boolean;
  isShowModalRequestName?: boolean;
  isLoadingCompleteStep1?: boolean;
  isLoadingCompleteStep2?: boolean;
  isLoadingCompleteStep3?: boolean;
  isLoadingCompleteStep4?: boolean;
  quotaRFRRequestOptions?: QuotaRFRRequestOptionsDTM;
  onClickOpenModal: () => void;
  onOpenRequestSavedName: () => void;
  onStartFirstRunSupportAllRequestPage: () => void;
  onStartFirstRun: () => void;
  abortAllRequests: () => void;
  onClickEmptyQuota: () => void;
  onClickModifyQuota: () => void;
}

const FreightQuoteContentComponent: FC<IFreightQuoteContentComponentProps> = (props) => {
  const {
    isCollapse,
    isCollapseAndEmptyQuota,
    isEmptyBg,
    RFQNewQuotaAvailability,
    quotasCreateFailedMessage,
    isDisableModalRequestName,
    quotaId,
    isQuotaEmptyError,
    isShowModalRequestName,
    isLoadingCompleteStep1,
    isLoadingCompleteStep2,
    isLoadingCompleteStep3,
    isLoadingCompleteStep4,
    quotaRFRRequestOptions,
    onClickOpenModal,
    onOpenRequestSavedName,
    onStartFirstRunSupportAllRequestPage,
    onStartFirstRun,
    abortAllRequests,
    onClickEmptyQuota,
    onClickModifyQuota,
  } = props;
  const { t } = useTranslation();
  const { isFullWidth } = useWindowSize();
  const title = t('FreightQuoteNewTitle');

  // need for support AllRequestPage
  useEffect(() => {
    onStartFirstRunSupportAllRequestPage();
    onStartFirstRun();

    return () => {
      abortAllRequests();
    };
  }, []);

  const isSelfServiceSwitchAvailable = useMemo(
    () => RFQNewQuotaAvailability?.selfService !== undefined,
    [RFQNewQuotaAvailability],
  );

  const quotasCreateFailedMessageComponent = useMemo(() => {
    if (isQuotaEmptyError) {
      return (
        <RCQNoQuoteErrorComponent
          onClickOpenModal={onClickOpenModal}
          onClickEmptyQuotaAndSchedule={onClickEmptyQuota}
          onClickModifyQuotaAndSchedule={onClickModifyQuota}
          onClickPullSchedule={onClickEmptyQuota}
          isOpenModalRate={!!quotaRFRRequestOptions?.isOpenModalRate}
          step1={!!quotaRFRRequestOptions?.step1}
          step2={quotaRFRRequestOptions?.step2}
          step3={quotaRFRRequestOptions?.step3}
          isLoading={quotaRFRRequestOptions?.isLoading}
        />
      );
    }

    return (quotasCreateFailedMessage
      ? (<AlertBlock type="warning" message={quotasCreateFailedMessage} />)
      : null);
  }, [quotasCreateFailedMessage, isQuotaEmptyError, quotaId, quotaRFRRequestOptions]);

  return (
    <FreightQuoteContentWrapper>
      <BookingDrawer />
      <AccountDrawer />

      {isShowModalRequestName && (
        <FreightQuoteContentModalSave />
      )}

      <FreightQuoteContentHeaderWrap>
        <PageHeader
          title={title}
        >
          {
            // Currently hidden
            false && isSelfServiceSwitchAvailable && (<SelfServiceSwitch />)
          }
          <FreightQuoteContentHeaderSaveBtn
            disabled={isDisableModalRequestName}
            onClick={onOpenRequestSavedName}
          >
            {t('SaveRequest')}
          </FreightQuoteContentHeaderSaveBtn>
        </PageHeader>
      </FreightQuoteContentHeaderWrap>

      <FreightQuoteContentPage>
        <FreightQuoteContentComponentDiv
          isCollapseAndEmptyQuota={isCollapseAndEmptyQuota}
          className={`${isCollapse ? 'FreightQuoteContent__isCollapsed' : ''} FreightQuoteContent FreightQuoteContent__isNotCollapsed`}
        >
          {isFullWidth}

          <FreightQuoteFormRequest />

          <FreightQuoteContentContainer
            isCollapseAndEmptyQuota={isCollapseAndEmptyQuota}
            isEmptyBg={isEmptyBg}
            className="FreightQuoteContent__rfq_container"
          >
            <FreightQuoteContentContainerBGWrapper className="FreightQuoteContent__bg_wrapper" />
            <RFQResponse
              NoneContentRecommended={quotasCreateFailedMessageComponent}
              isLoadingCompleteStep1={isLoadingCompleteStep1}
              isLoadingCompleteStep2={isLoadingCompleteStep2}
              isLoadingCompleteStep3={isLoadingCompleteStep3}
              isLoadingCompleteStep4={isLoadingCompleteStep4}
            />
          </FreightQuoteContentContainer>

          {isCollapse && !isCollapseAndEmptyQuota ? <BackgroundWrapper /> : null}
        </FreightQuoteContentComponentDiv>
      </FreightQuoteContentPage>

    </FreightQuoteContentWrapper>
  );
};

const FreightQuoteContentComponentCached = memo(FreightQuoteContentComponent);

export { FreightQuoteContentComponentCached as FreightQuoteContentComponent };
