import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';
import { UC } from 'shipment-operations/controllers';

import { CargoFooterComponent } from './CargoFooter.component';

const CargoFooterContainer: FC = () => {
  const isInDraft = useSelector(R.selectors.cargo.getCargoDraftStatus);
  const permissions = useSelector(R.selectors.shipment.getCargosPermissions);

  return (
    <CargoFooterComponent
      isInDraft={isInDraft}
      discardChanges={UC.cargo.resetCargo}
      updateCargo={UC.cargo.updateCargo}
      cargosAvailability={permissions.cargosAvailability}
    />
  );
};

export { CargoFooterContainer as CargoFooter };
