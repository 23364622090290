import {
  IsString, IsDefined, IsOptional,
} from 'class-validator';
import { BaseDTM } from 'proto/BaseDTM';

export interface IChargeCodeDTM {
  code: string
  description: string
  mode: string
  type: string
  subType: string
  occurrence: string
  loadType?: string
  status?: string
  phases?: string[]
}

export class ChargeCodeDTM extends BaseDTM<IChargeCodeDTM> {
  @IsString()
  @IsDefined()
  code: string;

  @IsString()
  @IsDefined()
  description: string;

  @IsString()
  @IsDefined()
  mode: string;

  @IsString()
  @IsDefined()
  type: string;

  @IsString()
  @IsOptional()
  subType: string;

  @IsString()
  @IsDefined()
  occurrence: string;

  @IsString()
  @IsOptional()
  loadType: string;

  @IsString()
  @IsOptional()
  status: string;

  @IsOptional()
  phases: string[];
}
