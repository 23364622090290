import React, { FC, useCallback } from 'react';
import EditOutlined from '@ant-design/icons/EditOutlined';
import PlusOutlined from '@ant-design/icons/PlusOutlined';
import Upload from 'antd/es/upload';
import { useTranslation } from 'react-i18next';
import TagsOutlined from '@ant-design/icons/TagsOutlined';
import { Tooltip } from 'app-wrapper/view/components';

import { OrganizationDTM } from 'user-management/models/dtm';
import { OrganizationStatus } from 'user-management/view/components';
import { EOrganizationIndustry, OrganizationIndustryNames } from 'user-management/constants';

import {
  OrganizationDataButton,
  OrganizationDataIcon,
  OrganizationDataName,
  OrganizationDataNameBox,
  OrganizationDataPrimaryContactIconBG,
  OrganizationDataType,
  OrganizationDataTypeBox,
  OrganizationDataTypeBoxName,
  OrganizationDataTypeBoxSecond,
  OrganizationDataTypeBoxTitle,
  OrganizationDataWrapper,
} from './OrganizationData.styled';

const { Dragger } = Upload;

interface IUserDataComponentProps {
  organization?: OrganizationDTM
  isAdmin?: boolean
  onEditProfileOrganization: () => void
}

export const OrganizationDataComponent: FC<IUserDataComponentProps> = ({
  organization,
  isAdmin,
  onEditProfileOrganization,
}) => {
  const { t } = useTranslation();

  const onEditProfileClick = useCallback(() => {
    onEditProfileOrganization();
  }, []);

  return (
    <OrganizationDataWrapper>
      <Tooltip
        title={t('Feature coming soon')}
        placement="top"
      >
        <OrganizationDataIcon>
          <Dragger
            name="file"
            showUploadList={false}
            multiple={false}
            onChange={() => {}}
            onDrop={() => {}}
            disabled
          >
            <PlusOutlined />
            <p>{t('Upload Logo')}</p>
          </Dragger>
        </OrganizationDataIcon>
      </Tooltip>
      <OrganizationDataNameBox>
        {organization?.status ? (
          <OrganizationStatus status={organization.status} />
        ) : null}

        <OrganizationDataName>{organization?.name}</OrganizationDataName>
        <OrganizationDataType>{t('ID')}: {organization?.id}</OrganizationDataType>
      </OrganizationDataNameBox>

      <OrganizationDataTypeBox>
        <OrganizationDataPrimaryContactIconBG>
          <TagsOutlined />
        </OrganizationDataPrimaryContactIconBG>
        <OrganizationDataTypeBoxSecond>
          <OrganizationDataTypeBoxTitle>{t('Type')} / {t('Industry')}</OrganizationDataTypeBoxTitle>
          <OrganizationDataTypeBoxName>
            {organization?.type ? organization?.type : ''}
            <OrganizationDataTypeBoxTitle>&nbsp;/&nbsp;</OrganizationDataTypeBoxTitle>
            {organization?.industry ? OrganizationIndustryNames[organization.industry as EOrganizationIndustry] : 'N/A'}
          </OrganizationDataTypeBoxName>
        </OrganizationDataTypeBoxSecond>
      </OrganizationDataTypeBox>

      <OrganizationDataButton
        icon={<EditOutlined />}
        onClick={onEditProfileClick}
        disabled={!isAdmin}
      >
        {t('Edit Profile')}
      </OrganizationDataButton>
    </OrganizationDataWrapper>
  );
};
