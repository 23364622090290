import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { UC } from 'shipment-operations/controllers';
import { R } from 'shipment-operations/repository';

import { ShippingPartiesListComponent } from './ShippingPartiesList.component';

export const ShippingPartiesList: FC = () => {
  const isShippingPartiesListLoading = useSelector(R.selectors.shippingParties.getShippingPartiesListLoadingState);
  const selectedFormType = useSelector(R.selectors.shippingParties.getSelectedFormType);
  const currentShippingParties = useSelector(R.selectors.shippingParties.getCurrentShippingParties);
  const draftState = useSelector(R.selectors.shippingParties.getFormDraftState);
  const tabPartiesWithPermission = useSelector(R.selectors.shippingParties.getTabParties);

  return (
    <ShippingPartiesListComponent
      isLoading={isShippingPartiesListLoading}
      selectedShippingParty={selectedFormType}
      shippingPartiesCurrentData={currentShippingParties}
      shippingPartiesDraftState={draftState}
      selectShippingParty={UC.shippingParties.selectShippingParty}
      tabParties={tabPartiesWithPermission}
    />
  );
};
