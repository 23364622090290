import React, { FC, useEffect, useMemo } from 'react';
import { useParams } from 'react-router';
import Popover from 'antd/es/popover';
import { useTranslation } from 'react-i18next';

import { ContainerBasicTypes } from 'shipment-operations/constants';
import { SkeletonSection } from 'app-wrapper/view/components/Skeleton/Skeleton.component';
import {
  PopoverContainer,
} from 'shipment-operations/view/pages/ShipmentOverview/components/ShipmentOverviewTitle/ShipmentOverviewTitle.styled';

import {
  AirVentField,
  ControlButtons,
  GensetField,
  ShipmentContainersTabs,
  TemperatureField,
} from './components';
import {
  ShipmentContainersHeaderBottomRow,
  ShipmentContainersHeaderDivider,
  ShipmentContainersHeaderLayout,
  ShipmentContainersHeaderTopRow,
  DisableBlock,
  DisableEvents,
} from './ShipmentContainersHeader.styled';

interface IShipmentContainersHeaderComponentProps {
  isLoading: boolean
  containerBasicType?: ContainerBasicTypes
  fetchTemperatureControlData: (shipmentId: string) => void
  clearTemperatureControlData: () => void
  disable: boolean
}

export const ShipmentContainersHeaderComponent: FC<IShipmentContainersHeaderComponentProps> = ({
  isLoading,
  containerBasicType,
  fetchTemperatureControlData,
  clearTemperatureControlData,
  disable,
}) => {
  const { t } = useTranslation();
  const { shipmentId = '0' } = useParams();

  const isTemperatureControlActive = useMemo(
    () => containerBasicType === ContainerBasicTypes.REEFER,
    [containerBasicType],
  );

  useEffect(() => {
    fetchTemperatureControlData(shipmentId);

    return clearTemperatureControlData;
  }, [containerBasicType]);

  if (isLoading) {
    return (
      <SkeletonSection height="46px" />
    );
  }

  return (
    <ShipmentContainersHeaderLayout isTemperatureControlActive={isTemperatureControlActive}>
      {
        isTemperatureControlActive && (disable ? (
          <Popover
            trigger="hover"
            content={<PopoverContainer>{t('changesForbidden')}</PopoverContainer>}
            placement="bottom"
            overlayInnerStyle={{
              backgroundColor: 'rgba(32, 44, 60, 1)',
            }}
          >
            <DisableBlock>
              <DisableEvents>
                <ShipmentContainersHeaderTopRow>
                  <TemperatureField />
                  <AirVentField />
                  <GensetField />
                  <ControlButtons />
                </ShipmentContainersHeaderTopRow>
                <ShipmentContainersHeaderDivider />
              </DisableEvents>
            </DisableBlock>
          </Popover>
        ) : (
          <>
            <ShipmentContainersHeaderTopRow>
              <TemperatureField />
              <AirVentField />
              <GensetField />
              <ControlButtons />
            </ShipmentContainersHeaderTopRow>
            <ShipmentContainersHeaderDivider />
          </>
        ))
      }
      <ShipmentContainersHeaderBottomRow>
        <ShipmentContainersTabs />
      </ShipmentContainersHeaderBottomRow>
    </ShipmentContainersHeaderLayout>
  );
};
