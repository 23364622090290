import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { CargoDTM } from 'shipment-operations/models/dtm';
import { IMOClassShortTypeNames } from 'app-wrapper/types/IMOClassType';
import { CargoReferenceType, PackingGroupTypeShortNames } from 'shipment-operations/constants';
import {
  Bold,
  Column,
  Row,
  Span,
  TableRow,
} from 'shipment-operations/view/components/HBLDocumentPDF/components';
import { PackageTypeNames, ReversePackageTypesCodes } from 'app-wrapper/types/PackageType';
import { Cell } from 'shipment-operations/view/components/HBLDocumentPDF/components/CargoAndContainers/CargoAndContainers.styled';

import {
  GoodsDescriptionItemWrapper,
  MarksAndNumbersWrapper,
  Nested,
  NestedTableRow,
  StyledSign,
} from '../../CargoTables.styled';

const EMPTY_CELL = '-';

interface GoodsDescriptionItemProps {
  item: CargoDTM;
  forContainers?: boolean
}

export const GoodsDescriptionItem: FC<GoodsDescriptionItemProps> = ({ item, forContainers = false }) => {
  const { t } = useTranslation();
  const {
    imoClass,
    unNumber,
    shippingName,
    packingGroup,
    contactNumber: name,
    contactName: phone,
    code: hsCode,
    weight,
    packagesNumber,
    volume,
    isHazmat,
  } = item;

  const mappedPackingGroup = useMemo(() => (packingGroup ? PackingGroupTypeShortNames[packingGroup] : t('N/A')), [t, packingGroup]);
  const mappedIMOClass = useMemo(() => (imoClass ? IMOClassShortTypeNames[imoClass] : t('N/A')), [t, imoClass]);

  if (!item) {
    return null;
  }

  const description = item.description || item.name;
  const packageType = item.packageType ? ReversePackageTypesCodes[item.packageType as unknown as keyof typeof ReversePackageTypesCodes] : '';

  return (
    <GoodsDescriptionItemWrapper>
      <TableRow>
        {
          forContainers ? (
            <Cell flex={2} justifyContent="flex-start">{description}</Cell>
          ) : (
            <Cell flex={2} justifyContent="flex-start"><Bold>{description}</Bold></Cell>
          )
        }
        <Cell justifyContent="flex-end">{!forContainers && hsCode}</Cell>
        <Cell justifyContent="flex-end">{packageType ? PackageTypeNames[packageType] : EMPTY_CELL}</Cell>
        <Cell justifyContent="center">{packagesNumber}</Cell>
        <Cell justifyContent="flex-end">{weight ? Number(weight).toFixed(2) : ''}</Cell>
        <Cell justifyContent="flex-end">{volume ? Number(volume).toFixed(2) : ''}</Cell>
      </TableRow>
      <Nested>
        {isHazmat && (
          <>
            <NestedTableRow>
              <Cell>
                <Bold>Proper Shipping Name </Bold>
                <Span>{shippingName}</Span>
              </Cell>
            </NestedTableRow>
            <NestedTableRow>
              <Cell>
                <Bold>UN Number</Bold>
                <Span>{unNumber}</Span>
              </Cell>
              <Cell>
                <Bold>Class </Bold>
                <Span>{mappedIMOClass}</Span>
              </Cell>
              <Cell>
                <Bold>Packing Group </Bold>
                <Span>{mappedPackingGroup}</Span>
              </Cell>
              <Cell />
            </NestedTableRow>
            <NestedTableRow>
              <Cell>
                <Bold>Emergency Contact Name </Bold>
                <Span>{name && name}</Span>
              </Cell>
              <Cell>
                <Bold>Emergency Contact Number </Bold>
                <Span>{phone && phone}</Span>
              </Cell>
            </NestedTableRow>
          </>
        )}
        <NestedTableRow>
          <Column>
            <Bold>Marks & Numbers</Bold>
            <MarksAndNumbersWrapper>
              <Row>
                <Cell>
                  {item.references.map(({ type, value }) => (
                    <StyledSign key={value}>{type ? CargoReferenceType[type] : ''}-{value}</StyledSign>
                  ))}
                </Cell>
                <Cell flex={2}>
                  <Column>
                    <span>{item.marks}</span>
                  </Column>
                </Cell>
              </Row>
            </MarksAndNumbersWrapper>
          </Column>
        </NestedTableRow>
      </Nested>
    </GoodsDescriptionItemWrapper>
  );
};
