import styled from 'styled-components';

import { colors } from 'app-wrapper/view/themes/themesColors';

interface ICShipmentDetailsCardWithThreeSectionsProps {
  isSelected?: boolean;
  isError?: boolean;
}

export const ShipmentDetailsCardWithThreeSections = styled.div<ICShipmentDetailsCardWithThreeSectionsProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  width: 100%;
  padding: 16px 16px 8px;
  border: 1px solid ${colors.purple15};

  background: white;
  border-radius: 3px;
  box-shadow: ${({ isSelected }) => (
    isSelected ? `0px 4px 4px rgba(32, 44, 60, 0.12), inset 5px 0px 0px ${colors.purple60}` : 'none'
  )};
  
  &:hover {
    cursor: pointer;
  }
`;
