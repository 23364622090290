import React, { FC, useCallback, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router';

import { RouteNames } from 'app-wrapper/constants';
import { Status } from 'app-wrapper/view/guideline';
import { AdditionalServiceDTM } from 'shipment-operations/models/dtm';
import {
  ISF_SERVICE_CODE,
  AES_PPI_CODE,
  AES_USPPI_CODE,
} from 'shipment-operations/constants';

import { IconEX1, IconExportDeclaration, IconImportFilling } from './assets';

import {
  Divider,
  Service,
  ServiceSubTitle,
  ServiceTexts,
  ServiceTitle,
  StatusWrap,
} from './Service.styled';

interface IServiceComponentProps {
  service: AdditionalServiceDTM;
}

const ServiceComponent: FC<IServiceComponentProps> = ({
  service,
}) => {
  const { shipmentId = '' } = useParams();
  const navigate = useNavigate();
  const { name, info } = service;

  const goToService = useCallback(() => {
    navigate(RouteNames.SHIPMENT_ADDITIONAL_SERVICE(shipmentId, service.id));
  }, [navigate, service]);

  const Icon = useMemo(() => {
    if (service.code === AES_PPI_CODE || service.code === AES_USPPI_CODE) {
      return IconExportDeclaration;
    }

    if (service.code === ISF_SERVICE_CODE) {
      return IconImportFilling;
    }

    return IconEX1;
  }, [service]);

  const { status, statusText } = service.getServiceStatusInfo();

  return (
    <Service onClick={goToService}>
      <Icon />

      <ServiceTexts>
        <ServiceTitle>
          {name}
        </ServiceTitle>

        <ServiceSubTitle>
          {info}
        </ServiceSubTitle>
      </ServiceTexts>

      <Divider type="vertical" />

      <StatusWrap>
        <Status text={statusText} type={status} />
      </StatusWrap>
    </Service>
  );
};

export { ServiceComponent as Service };
