import React from 'react';
import { observer } from 'mobx-react-lite';

import { useMobxStore } from 'app-wrapper/mobxStores';
import { UC } from 'shipment-operations/controllers';

import { ServiceDetailsComponent } from './ServiceDetails.component';

export const ServiceDetails = observer(() => {
  const { shipmentServices } = useMobxStore();

  return (
    <ServiceDetailsComponent
      serviceInfo={shipmentServices.state.service?.info}
      startEditingFlow={UC.shipmentServices.startEditingFlowFromBeginning}
      isThereAreAnyRelatedActivities={shipmentServices.getIsThereAnyRelatedActivities}
    />
  );
});
