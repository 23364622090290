import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';

import { UC } from 'shipment-operations/controllers';
import { ShipmentTransportationOverviewComponent } from './ShipmentTransportationOverview.component';

const ShipmentTransportationOverviewContainer: FC = () => {
  const permissions = useSelector(R.selectors.shipment.getTransportationPermissions);
  const isTransportationOverviewLoading = useSelector(R.selectors.transportationOverview.getLoading);
  const isShipmentLoading = useSelector(R.selectors.shipment.getLoadingState);

  const isLoading = isShipmentLoading || isTransportationOverviewLoading;

  return (
    <ShipmentTransportationOverviewComponent
      tabAvailability={permissions.overviewTab}
      onNoAccess={UC.shipmentTransportationOverview.onNoAccess}
      isLoading={isLoading}
    />
  );
};

export {
  ShipmentTransportationOverviewContainer as ShipmentTransportationOverview,
};
