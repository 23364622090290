import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  RFRRejectRequestSvg, SkypaceSpin,
} from 'app-wrapper/view/icons';

import {
  RFRRejectRequestContentWrap, RFRRejectRequestHeader, RFRRejectRequestHeaderTitle, RFRRejectRequestContentWrapImage, RFRRejectRequestWrap,
} from './RFRRejectRequest.styled';

interface IRFRRejectRequestComponentProps {
  nameLogo?: string;
  step1?: boolean;
  step2?: boolean;
  step3?: boolean;
  isLoading?: boolean;
}

const RFRRejectRequestComponent = (props: IRFRRejectRequestComponentProps) => {
  const { t } = useTranslation();
  const {
    nameLogo,
    step1 = true,
    step2,
    step3,
    isLoading,
  } = props;

  return (
    <RFRRejectRequestWrap>
      <RFRRejectRequestContentWrap>

        {isLoading && (
          <SkypaceSpin />
        )}

        {step1 && !isLoading && (
          <>
            <RFRRejectRequestContentWrapImage>
              <RFRRejectRequestSvg />
            </RFRRejectRequestContentWrapImage>

            <RFRRejectRequestHeader>
              {nameLogo || t('The request has been rejected') || ''}
            </RFRRejectRequestHeader>
            <RFRRejectRequestHeaderTitle>
              {t('We are unable to cover this request. Customer who created') || ''}
            </RFRRejectRequestHeaderTitle>
            <RFRRejectRequestHeaderTitle>
              {t('the request was notified about this.') || ''}
            </RFRRejectRequestHeaderTitle>
          </>
        )}

        {step2 && !isLoading && (
          <>
            <RFRRejectRequestHeader>
              {}
            </RFRRejectRequestHeader>
            <RFRRejectRequestHeaderTitle>
              {}
            </RFRRejectRequestHeaderTitle>
          </>
        )}

        {step3 && !isLoading && (
          <>
            <RFRRejectRequestContentWrapImage>
              <RFRRejectRequestSvg />
            </RFRRejectRequestContentWrapImage>

            <RFRRejectRequestHeader>
              {nameLogo || t('The request has been rejected') || ''}
            </RFRRejectRequestHeader>
            <RFRRejectRequestHeaderTitle>
              {t('We are unable to cover this request. Customer who created') || ''}
            </RFRRejectRequestHeaderTitle>
            <RFRRejectRequestHeaderTitle>
              {t('the request was notified about this.') || ''}
            </RFRRejectRequestHeaderTitle>
          </>
        )}

      </RFRRejectRequestContentWrap>
    </RFRRejectRequestWrap>
  );
};

const RFRRejectRequestComponentCache = memo(RFRRejectRequestComponent);

export { RFRRejectRequestComponentCache as RFRRejectRequestComponent };
