import styled, { css } from 'styled-components';

export const textColorFirst = css`
  color: ${({ theme }) => theme.themesColors.characterBrandingTitle85};
`;

export const divFlex = css`
  display: flex;
  position: relative;
  ${textColorFirst}
`;

export const textColorSecond = css`
  color: ${({ theme }) => theme.themesColors.secondaryDot45};
`;

export const RFQItemblockBorder = css`
  border: 1px solid ${({ theme }) => theme.themesColors.lightGrayBackground};
`;

export const RFQItemDiv = styled.div.attrs({
  'data-class': 'RFQItemDiv',
})`
  ${divFlex}
  ${RFQItemblockBorder}
  border-radius: 4px;
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: max-content;
  background-color: ${({ theme }) => theme.colors.white};
  padding: 8px 0px;
`;
