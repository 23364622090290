import React, { forwardRef, InputHTMLAttributes } from 'react';

import { PageContainer } from './PageContainer.styled';

// TODO: fix types
const PageContainerComponent = forwardRef<any, InputHTMLAttributes<HTMLDivElement>>((
  props,
  ref,
) => {
  /* eslint-disable-next-line react/prop-types */
  const { children } = props;

  return (
    <PageContainer
      ref={ref as React.RefObject<HTMLDivElement>}
      {...props}
    >
      {children}
    </PageContainer>
  );
});

export { PageContainerComponent as PageContainer };
