import styled from 'styled-components';

import { FlexRow } from 'app-wrapper/view/guideline';

export const StatusWrap = styled(FlexRow)`
  gap: 8px;
`;

export const StatusIndicator = styled.div`
  width: 7px;
  height: 7px;
  border-radius: 50%;
`;

export const StatusText = styled.span`
  font-size: 14px;
  line-height: 22px;
`;
