import React, { FC } from 'react';
import { InputProps } from 'antd/es/input';
import omit from 'lodash/fp/omit';
import { SizeType } from 'antd/es/config-provider/SizeContext';

import { useResponsiveSize } from 'app-wrapper/hooks';
import { InputPasswordStyled } from './InputPassword.styled';

const RED_BORDER_HIGHLIGHTING = {
  border: '1px solid red',
  boxShadow: 'none',
};

interface IInputPasswordProps extends InputProps {
  error?: boolean
  ref?: any
  size?: SizeType
  dataClass?: string
}

export const InputPassword: FC<IInputPasswordProps> = (props: IInputPasswordProps) => {
  const {
    style,
    ref,
    error,
    size,
    dataClass,
  } = props;

  const defaultSize = useResponsiveSize();

  let sizeInput: SizeType = 'middle';
  sizeInput = size || defaultSize;

  const inputProps = omit(['style', 'ref', 'error', 'dataClass'], props);

  return (
    <InputPasswordStyled
      data-class={dataClass || 'InputPasswordStyled'}
      size={sizeInput}
      ref={ref}
      style={{ ...style, ...(error ? RED_BORDER_HIGHLIGHTING : {}) }}
      {...inputProps}
    />
  );
};
