import { v4 as uuidv4 } from 'uuid';
import { AxiosError } from 'axios';

import {
  ICompanyAddressDTM,
  ICompanyContactDTM,
  CompanyDTM,
  CompanyAddressDTM,
  CompanyContactDTM,
  CompanyCollectedDTM,
  IRequestParams,
} from 'user-management/models/dtm';
import {
  IGetCompanyListContactResponse,
  IPostAddressContactPersonRequest,
  IPostCompanyListAddressRequest,
  TGetCompanyAddressByIdResponse,
  TGetCompanyListContentResponse,
  TGetCompanyListResponse,
  TPostCompanyListRequest,
  TPostCompanyListResponse,
  TPutCompanyListRequest,
  TPutCompanyListResponse,
} from 'user-management/models/contracts';

import { apiWorker } from 'app-wrapper/repository/utilsServices';
import { ServerError } from 'app-wrapper/types/ServerError';
import { NetworkErrorProto } from 'app-wrapper/models/errors';

export class ContactsService {
  urlBase = '/contact-service/api/v1/companies'

  public deleteCompanyById = async (companyId: number) => {
    const rawResponse = await apiWorker.requestDelete(`${this.urlBase}/${companyId}`);

    return rawResponse;
  }

  public getCompanyList = async (onlyPrimary?: boolean, params?: IRequestParams) => {
    let companyList: CompanyDTM[] = [];
    const addresses: CompanyAddressDTM[] = [];
    const contacts: CompanyContactDTM[] = [];
    let companyCollected: CompanyCollectedDTM = CompanyCollectedDTM.fromPlain({
      companyList,
      addresses,
      contacts,
    });

    const rawResponse = await apiWorker.requestGet<TGetCompanyListResponse>(`${this.urlBase}`,
      {
        params: {
          page: params?.page,
          size: params?.size || '1000',
          onlyPrimary,
        },
      });
    const response = rawResponse.data;

    if (!response?.content.length) {
      return companyCollected;
    }

    companyList = response?.content?.map((item) => {
      const addressIds: string[] = [];
      const contactsIds: string[] = [];

      item?.contacts?.forEach((itemContact) => {
        const contactId = uuidv4();

        const companyContactDTM: CompanyContactDTM = CompanyContactDTM.fromPlain({
          id: itemContact.id,
          customId: contactId,
          fullName: itemContact.fullName,
          email: itemContact.email,
          phone: itemContact.phone,
          phone2: itemContact.phone2,
          primary: itemContact.primary,
        });

        contactsIds.push(contactId);
        contacts.push(companyContactDTM);
      });

      let primaryContactCompany: CompanyContactDTM | undefined;

      item?.addresses?.forEach((itemAddress) => {
        const addressId = uuidv4();
        const contactId = uuidv4();

        const primaryContacts: CompanyContactDTM[] | null = itemAddress.contacts
          ?.filter((itemAddressContact) => itemAddressContact.primary)?.map((itemAddressContact) => {
            const companyContactDTM: CompanyContactDTM = CompanyContactDTM.fromPlain({
              id: itemAddressContact.id,
              customId: contactId,
              fullName: itemAddressContact.fullName,
              email: itemAddressContact.email,
              phone: itemAddressContact.phone,
              phone2: itemAddressContact.phone2,
            });

            contactsIds.push(contactId);
            return companyContactDTM;
          }) || null;

        const primaryContact: CompanyContactDTM | undefined = primaryContacts?.[0] && CompanyContactDTM.fromPlain({
          id: (primaryContacts?.[0]?.id),
          email: primaryContacts?.[0]?.email,
          fullName: primaryContacts?.[0]?.fullName,
          phone: primaryContacts?.[0]?.phone,
          phone2: primaryContacts?.[0]?.phone2,
        });

        if (itemAddress.primary) {
          primaryContactCompany = CompanyContactDTM.fromPlain({
            id: Number(primaryContact?.id),
            email: `${primaryContact?.email}`,
            fullName: `${primaryContact?.fullName}`,
            phone: `${primaryContact?.phone}`,
            phone2: `${primaryContact?.phone2}`,
          });
        }

        const companyAddressDTM: CompanyAddressDTM = CompanyAddressDTM.fromPlain({
          id: itemAddress.id,
          customId: addressId,
          country: itemAddress.country,
          state: itemAddress.state,
          city: itemAddress.city,
          address1: itemAddress.address1,
          address2: itemAddress.address2,
          postalCode: itemAddress.postalCode,
          closestPort: itemAddress.closestPort,
          company: itemAddress.company,
          isPrimary: itemAddress.primary,
          primaryDefault: itemAddress.primary,
          primaryContact,
        });

        addressIds.push(addressId);
        addresses.push(companyAddressDTM);
      });

      const companyDTM: CompanyDTM = CompanyDTM.fromPlain({
        id: item.id,
        customId: uuidv4(),
        name: item.name,
        phone: item.phone,
        phone2: item.phone,
        email: item.email,
        usci: item.usci || undefined,
        taxId: item.taxId || undefined,
        addressesIds: addressIds,
        contactPersonsIds: contactsIds,
        isPrimary: item.isPrimary,
        primaryContact: primaryContactCompany,
      });

      return companyDTM;
    });

    companyCollected = CompanyCollectedDTM.fromPlain({
      companyList,
      addresses,
      contacts,
    });

    return companyCollected;
  }

  public getCompanyById = async (id: string) => {
    let companyList: CompanyDTM[] = [];
    const addresses: CompanyAddressDTM[] = [];
    const contacts: CompanyContactDTM[] = [];
    let companyCollected: CompanyCollectedDTM = CompanyCollectedDTM.fromPlain({
      companyList,
      addresses,
      contacts,
    });

    const rawResponse = await apiWorker.requestGet<TGetCompanyListContentResponse>(`${this.urlBase}/${id}`);
    const response = rawResponse.data;

    if (!response) {
      return companyCollected;
    }

    const addressIds: string[] = [];
    const contactsIds: string[] = [];

    response?.contacts?.forEach((itemContact) => {
      const contactId = uuidv4();

      const companyContactDTM: CompanyContactDTM = CompanyContactDTM.fromPlain({
        id: itemContact.id,
        customId: contactId,
        fullName: itemContact.fullName,
        email: itemContact.email,
        phone: itemContact.phone,
        phone2: itemContact.phone2,
        primary: itemContact.primary,
      });

      contactsIds.push(contactId);
      contacts.push(companyContactDTM);
    });

    response?.addresses?.forEach((itemAddress) => {
      const addressId = uuidv4();
      const contactId = uuidv4();

      const primaryContact: CompanyContactDTM[] | null = itemAddress.contacts
        ?.filter((itemAddressContact) => itemAddressContact.primary)?.map((itemAddressContact) => {
          const companyContactDTM: CompanyContactDTM = CompanyContactDTM.fromPlain({
            id: itemAddressContact.id,
            customId: contactId,
            fullName: itemAddressContact.fullName,
            email: itemAddressContact.email,
            phone: itemAddressContact.phone,
            phone2: itemAddressContact.phone2,
          });

          contactsIds.push(contactId);
          return companyContactDTM;
        }) || null;

      const addressesContactsIds: string[] = [];
      const addressesContacts: CompanyContactDTM[] = itemAddress.contacts
        ?.sort((a) => (a.primary ? -1 : 1))
        ?.map((itemAddressContact) => {
          const addressContactId = uuidv4();

          const companyContactDTM: CompanyContactDTM = CompanyContactDTM.fromPlain({
            id: itemAddressContact.id,
            customId: addressContactId,
            fullName: itemAddressContact.fullName,
            email: itemAddressContact.email,
            phone: itemAddressContact.phone,
            phone2: itemAddressContact.phone2,
          });

          addressesContactsIds.push(addressContactId);
          contactsIds.push(addressContactId);
          contacts.push(companyContactDTM);

          return companyContactDTM;
        }) || [];

      const companyAddressDTM: CompanyAddressDTM = CompanyAddressDTM.fromPlain({
        id: itemAddress.id,
        customId: addressId,
        country: itemAddress.country,
        state: itemAddress.state,
        city: itemAddress.city,
        address1: itemAddress.address1,
        address2: itemAddress.address2,
        postalCode: itemAddress.postalCode,
        closestPort: itemAddress.closestPort,
        company: itemAddress.company,
        isPrimary: itemAddress.primary,
        primaryDefault: itemAddress.primary,
        primaryContact: primaryContact?.[0] && {
          id: primaryContact?.[0]?.id,
          email: primaryContact?.[0]?.email,
          fullName: primaryContact?.[0]?.fullName,
          phone: primaryContact?.[0]?.phone,
          phone2: primaryContact?.[0]?.phone2,
        },
        contacts: addressesContacts,
        contactsIds: addressesContactsIds,
      });

      addressIds.push(addressId);
      addresses.push(companyAddressDTM);
    });

    const companyDTM: CompanyDTM = CompanyDTM.fromPlain({
      id: response.id,
      customId: uuidv4(),
      name: response.name,
      phone: response.phone,
      phone2: response.phone,
      email: response.email,
      usci: response.usci || undefined,
      taxId: response.taxId || undefined,
      addressesIds: addressIds,
      contactPersonsIds: contactsIds,
      isPrimary: response.isPrimary,
    });

    companyList = [companyDTM];

    companyCollected = CompanyCollectedDTM.fromPlain({
      companyList,
      addresses,
      contacts,
    });

    return companyCollected;
  }

  public postCompanyList = async (companyData: TPostCompanyListRequest) => {
    const addresses: CompanyAddressDTM[] = [];
    const contacts: CompanyContactDTM[] = [];
    let companyCollected: CompanyCollectedDTM = CompanyCollectedDTM.fromPlain({
      companyList: [],
      addresses,
      contacts,
    });

    const rawResponse = await apiWorker.requestPost<TPostCompanyListResponse>(`${this.urlBase}`, companyData);

    const response = rawResponse.data;

    const addressIds: string[] = [];
    const contactsIds: string[] = [];

    response?.contacts?.forEach((itemContact) => {
      const contactId = uuidv4();
      const newShipmentPreviewDtm: CompanyContactDTM = CompanyContactDTM.fromPlain({
        id: itemContact.id,
        fullName: itemContact.fullName,
        email: itemContact.email,
        phone: itemContact.phone,
        phone2: itemContact.phone2,
        primary: itemContact.primary,
        customId: uuidv4(),
      });

      contactsIds.push(contactId);
      contacts.push(newShipmentPreviewDtm);
    });

    response?.addresses?.forEach((itemAddress) => {
      const addressId = uuidv4();
      const contactId = uuidv4();

      const primaryContact: CompanyContactDTM[] | null = itemAddress.contacts
        ?.filter((itemAddressContact) => itemAddressContact.primary)?.map((itemAddressContact) => {
          const companyContactDTM: CompanyContactDTM = CompanyContactDTM.fromPlain({
            id: itemAddressContact.id,
            customId: contactId,
            fullName: itemAddressContact.fullName,
            email: itemAddressContact.email,
            phone: itemAddressContact.phone,
            phone2: itemAddressContact.phone2,
          });

          contactsIds.push(contactId);
          return companyContactDTM;
        }) || null;

      const companyAddressDTM: CompanyAddressDTM = CompanyAddressDTM.fromPlain({
        id: itemAddress.id,
        customId: addressId,
        country: itemAddress.country,
        state: itemAddress.state,
        city: itemAddress.city,
        address1: itemAddress.address1,
        address2: itemAddress.address2,
        postalCode: itemAddress.postalCode,
        closestPort: itemAddress.closestPort,
        company: itemAddress.company,
        isPrimary: itemAddress.primary,
        primaryContact: primaryContact?.[0] && {
          id: primaryContact?.[0]?.id,
          email: primaryContact?.[0]?.email,
          fullName: primaryContact?.[0]?.fullName,
          phone: primaryContact?.[0]?.phone,
          phone2: primaryContact?.[0]?.phone2,
        },
      });

      addressIds.push(addressId);
      addresses.push(companyAddressDTM);
    });

    const companyDTM = CompanyDTM.fromPlain({
      id: response.id,
      customId: uuidv4(),
      name: response.name,
      phone: response.phone,
      phone2: response.phone,
      email: response.email,
      usci: response.usci || undefined,
      taxId: response.taxId || undefined,
      addressesIds: addressIds,
      contactPersonsIds: contactsIds,
      isPrimary: response.isPrimary,
    });

    companyCollected = CompanyCollectedDTM.fromPlain({
      companyList: [companyDTM],
      addresses,
      contacts,
    });

    return companyCollected;
  }

  public putCompanyList = async (companyId: number | string, companyData: TPutCompanyListRequest) => {
    const addresses: CompanyAddressDTM[] = [];
    const contacts: CompanyContactDTM[] = [];
    let companyCollected: CompanyCollectedDTM = CompanyCollectedDTM.fromPlain({
      companyList: [],
      addresses,
      contacts,
    });

    const rawResponse = await apiWorker.requestPut<TPutCompanyListResponse>(`${this.urlBase}/${companyId}`, companyData);

    const response = rawResponse.data;

    const addressIds: string[] = [];
    const contactsIds: string[] = [];

    response?.contacts?.forEach((itemContact) => {
      const contactId = uuidv4();
      const newShipmentPreviewDtm: CompanyContactDTM = CompanyContactDTM.fromPlain({
        id: itemContact.id,
        fullName: itemContact.fullName,
        email: itemContact.email,
        phone: itemContact.phone,
        phone2: itemContact.phone2,
        primary: itemContact.primary,
        customId: uuidv4(),
      });

      contactsIds.push(contactId);
      contacts.push(newShipmentPreviewDtm);
    });

    response?.addresses?.forEach((itemAddress) => {
      const addressId = uuidv4();
      const contactId = uuidv4();

      const primaryContact: CompanyContactDTM[] | null = itemAddress.contacts
        ?.filter((itemAddressContact) => itemAddressContact.primary)?.map((itemAddressContact) => {
          const companyContactDTM: CompanyContactDTM = CompanyContactDTM.fromPlain({
            id: itemAddressContact.id,
            customId: contactId,
            fullName: itemAddressContact.fullName,
            email: itemAddressContact.email,
            phone: itemAddressContact.phone,
            phone2: itemAddressContact.phone2,
          });

          contactsIds.push(contactId);
          return companyContactDTM;
        }) || null;

      const companyAddressDTM: CompanyAddressDTM = CompanyAddressDTM.fromPlain({
        id: itemAddress.id,
        customId: addressId,
        country: itemAddress.country,
        state: itemAddress.state,
        city: itemAddress.city,
        address1: itemAddress.address1,
        address2: itemAddress.address2,
        postalCode: itemAddress.postalCode,
        closestPort: itemAddress.closestPort,
        company: itemAddress.company,
        isPrimary: itemAddress.primary,
        primaryContact: primaryContact?.[0] && {
          id: primaryContact?.[0]?.id,
          email: primaryContact?.[0]?.email,
          fullName: primaryContact?.[0]?.fullName,
          phone: primaryContact?.[0]?.phone,
          phone2: primaryContact?.[0]?.phone2,
        },
      });

      addressIds.push(addressId);
      addresses.push(companyAddressDTM);
    });

    const companyDTM = CompanyDTM.fromPlain({
      id: response.id,
      customId: uuidv4(),
      name: response.name,
      phone: response.phone,
      phone2: response.phone,
      email: response.email,
      usci: response.usci || undefined,
      taxId: response.taxId || undefined,
      addressesIds: addressIds,
      contactPersonsIds: contactsIds,
      isPrimary: response.isPrimary,
    });

    companyCollected = CompanyCollectedDTM.fromPlain({
      companyList: [companyDTM],
      addresses,
      contacts,
    });

    return companyCollected;
  }

  public getContactList = async (companyId: number) => {
    let list: ICompanyContactDTM[] = [];

    try {
      const rawResponse = await apiWorker.requestGet<IGetCompanyListContactResponse[]>(`${this.urlBase}/${companyId}/contacts`);
      const response = rawResponse.data;

      list = response.map((item) => {
        const newShipmentPreviewDtm: ICompanyContactDTM = {
          id: item.id,
          fullName: item.fullName,
          email: item.email,
          phone: item.phone,
          phone2: item.phone2,
        };

        return newShipmentPreviewDtm;
      });
    } catch (e) {
      const error = e as AxiosError<ServerError>;

      if (error.status === 500) {
        throw NetworkErrorProto.fromPlain({
          message: 'Contact list getting error',
          code: error?.response?.data?.code,
          status: error.status,
        });
      }

      if (error?.response?.data?.message || error?.response?.data?.details) {
        throw NetworkErrorProto.fromPlain({
          message: error?.response?.data?.message,
          details: error?.response?.data?.details,
          code: error?.response?.data?.code,
          status: error.status,
        });
      }

      throw NetworkErrorProto.fromPlain({
        message: 'Contact list getting error',
        code: error?.response?.data?.code,
        status: error.status,
      });
    }

    return list;
  }

  public getCompany = async (companyId: number) => {
    let company: CompanyDTM | null = null;

    try {
      const rawResponse = await apiWorker.requestGet<TGetCompanyListContentResponse>(`${this.urlBase}/${companyId}`);

      const response = rawResponse.data;

      const contacts: CompanyContactDTM[] = response.contacts.map((itemContact) => {
        const newShipmentPreviewDtm: CompanyContactDTM = CompanyContactDTM.fromPlain({
          id: itemContact.id,
          fullName: itemContact.fullName,
          email: itemContact.email,
          phone: itemContact.phone,
          phone2: itemContact.phone2,
        });

        return newShipmentPreviewDtm;
      });

      const addresses: CompanyAddressDTM[] = response.addresses.map((itemAddress) => {
        const newShipmentPreviewDtm: CompanyAddressDTM = CompanyAddressDTM.fromPlain({
          id: itemAddress.id,
          country: itemAddress.country,
          state: itemAddress.state,
          city: itemAddress.city,
          address1: itemAddress.address1,
          address2: itemAddress.address2,
          postalCode: itemAddress.postalCode,
          closestPort: itemAddress.closestPort,
          company: itemAddress.company,
        });

        return newShipmentPreviewDtm;
      });

      company = CompanyDTM.fromPlain({
        id: response.id,
        name: response.name,
        phone: response.phone,
        phone2: response.phone,
        email: response.email,
        usci: response.usci || undefined,
        taxId: response.taxId || undefined,
        contacts,
        addresses,
      });
    } catch (e) {
      const error = e as AxiosError<ServerError>;

      if (error.status === 500) {
        throw NetworkErrorProto.fromPlain({
          message: 'Company getting error',
          code: error?.response?.data?.code,
          status: error.status,
        });
      }

      if (error?.response?.data?.message || error?.response?.data?.details) {
        throw NetworkErrorProto.fromPlain({
          message: error?.response?.data?.message,
          details: error?.response?.data?.details,
          code: error?.response?.data?.code,
          status: error.status,
        });
      }

      throw NetworkErrorProto.fromPlain({
        message: 'Company getting error',
        code: error?.response?.data?.code,
        status: error.status,
      });
    }

    return company;
  }

  public getContact = async (companyId: number, contactId: number) => {
    let contact: ICompanyContactDTM | null = null;

    try {
      const rawResponse = await apiWorker.requestGet<IGetCompanyListContactResponse>(`${this.urlBase}/${companyId}/contacts/${contactId}`);

      const response = rawResponse.data;

      contact = {
        id: response.id,
        fullName: response.fullName,
        email: response.email,
        phone: response.phone,
        phone2: response.phone2,
      };
    } catch (e) {
      const error = e as AxiosError<ServerError>;

      if (error.status === 500) {
        throw NetworkErrorProto.fromPlain({
          message: 'Contact getting error',
          code: error?.response?.data?.code,
          status: error.status,
        });
      }

      if (error?.response?.data?.message || error?.response?.data?.details) {
        throw NetworkErrorProto.fromPlain({
          message: error?.response?.data?.message,
          details: error?.response?.data?.details,
          code: error?.response?.data?.code,
          status: error.status,
        });
      }

      throw NetworkErrorProto.fromPlain({
        message: 'Contact getting error',
        code: error?.response?.data?.code,
        status: error.status,
      });
    }

    return contact;
  }

  public getAddress = async (companyId: number, addressId: number) => {
    let address: ICompanyAddressDTM | null = null;

    const rawResponse = await apiWorker.requestGet<ICompanyAddressDTM>(`${this.urlBase}/${companyId}/addresses/${addressId}`);

    const response = rawResponse.data;
    const addressCustomId = uuidv4();

    let primaryContact: CompanyContactDTM | undefined;

    const contacts: CompanyContactDTM[] = response?.contacts?.map((itemAddressContact) => {
      const contactId = uuidv4();

      const companyContactDTM: CompanyContactDTM = CompanyContactDTM.fromPlain({
        id: itemAddressContact.id,
        customId: contactId,
        fullName: itemAddressContact.fullName,
        email: itemAddressContact.email,
        phone: itemAddressContact.phone,
        phone2: itemAddressContact.phone2,
        primary: itemAddressContact.primary,
        primaryDefault: itemAddressContact.primary,
      });

      if (itemAddressContact.primary) {
        primaryContact = CompanyContactDTM.fromPlain({
          ...companyContactDTM,
        });
      }

      return companyContactDTM;
    }) || [];

    address = {
      id: response.id,
      customId: addressCustomId,
      country: response.country,
      state: response.state,
      city: response.city,
      address1: response.address1,
      address2: response.address2,
      postalCode: response.postalCode,
      closestPort: response.closestPort,
      company: response.company,
      contacts,
      primaryContact,
    };

    return address;
  }

  public postAddress = async (companyId: number, postData: IPostCompanyListAddressRequest) => {
    let address: ICompanyAddressDTM | null = null;

    const rawResponse = await apiWorker.requestPost<ICompanyAddressDTM>(`${this.urlBase}/${companyId}/addresses/`, postData);

    const response = rawResponse.data;

    address = {
      id: response.id,
      country: response.country,
      state: response.state,
      city: response.city,
      address1: response.address1,
      address2: response.address2,
      postalCode: response.postalCode,
      closestPort: response.closestPort,
      company: response.company,
    };

    return address;
  }

  public putAddress = async (companyId: number, addressId: number, postData: IPostCompanyListAddressRequest) => {
    let address: ICompanyAddressDTM | null = null;

    if (!companyId || !addressId) {
      return address;
    }

    const rawResponse = await apiWorker.requestPut<ICompanyAddressDTM>(`${this.urlBase}/${companyId}/addresses/${addressId}`, postData);

    const response = rawResponse.data;

    address = {
      id: response.id,
      country: response.country,
      state: response.state,
      city: response.city,
      address1: response.address1,
      address2: response.address2,
      postalCode: response.postalCode,
      closestPort: response.closestPort,
      company: response.company,
    };

    return address;
  }

  public deleteAddress = async (companyId: number, addressId: number) => {
    if (!companyId || !addressId) {
      return;
    }

    await apiWorker.requestDelete<ICompanyAddressDTM>(`${this.urlBase}/${companyId}/addresses/${addressId}`);
  }

  public getCompanyContactById = async (companyId: number, addressId: string) => {
    let contacts: CompanyContactDTM[] | null = null;

    const rawResponse = await apiWorker.requestGet<TGetCompanyAddressByIdResponse[]>(`${this.urlBase}/${companyId}/contacts`, {
      params: {
        addressId,
      },
    });

    const response = rawResponse.data;

    contacts = response.map((itemContact) => {
      const newShipmentPreviewDtm: CompanyContactDTM = CompanyContactDTM.fromPlain({
        id: itemContact.id || 0,
        fullName: itemContact.fullName || '',
        email: itemContact.email || '',
        phone: itemContact.phone || '',
        phone2: itemContact.phone2 || '',
        primary: itemContact.primary,
      });

      return newShipmentPreviewDtm;
    });

    return contacts;
  }

  public postAddressContactByID = async (companyId: string, addressId: string, postData: IPostAddressContactPersonRequest) => {
    const address: ICompanyAddressDTM | null = null;

    const data = {
      id: postData?.id || null,
      email: postData?.email || null,
      fullName: postData?.fullName || null,
      phone: postData?.phone || null,
      phone2: postData?.phone2 || null,
      primary: postData?.primary,
    };

    await apiWorker.requestPost<ICompanyAddressDTM>(`${this.urlBase}/${companyId}/addresses/${addressId}/contacts`, data);

    return address;
  }

  public putAddressContactByID = async (companyId: string, addressId: string, contactId: string, postData: IPostAddressContactPersonRequest) => {
    const address: ICompanyAddressDTM | null = null;

    const data = {
      id: postData?.id || null,
      email: postData?.email || null,
      fullName: postData?.fullName || null,
      phone: postData?.phone || null,
      phone2: postData?.phone2 || null,
      primary: postData?.primary,
    };

    await apiWorker.requestPut<ICompanyAddressDTM>(`${this.urlBase}/${companyId}/addresses/${addressId}/contacts/${contactId}`, data);

    return address;
  }

  public deleteAddressContactByID = async (companyId: string, addressId: string, contactId: string) => {
    await apiWorker.requestDelete<ICompanyAddressDTM>(`${this.urlBase}/${companyId}/contacts/${contactId}`);
  }
}
