import { plainToInstance } from 'class-transformer';
import {
  IsOptional,
  IsBoolean,
  IsString,
} from 'class-validator';

export interface IDefaultFieldErrors {
  isBlur?: boolean
  isFocus?: boolean
  message?: string
  isVisited?: boolean
  isTooltipVisible?: boolean
}

export class DefaultFieldErrors implements IDefaultFieldErrors {
  @IsOptional()
  @IsBoolean()
  isBlur?: boolean;

  @IsOptional()
  @IsBoolean()
  isFocus?: boolean;

  @IsOptional()
  @IsString()
  message?: string;

  @IsOptional()
  @IsBoolean()
  isVisited?: boolean;

  @IsOptional()
  @IsBoolean()
  isTooltipVisible?: boolean;

  static fromPlain = (fieldErrors: IDefaultFieldErrors) => plainToInstance(DefaultFieldErrors, fieldErrors);
}
