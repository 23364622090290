import { accountDrawerActions, accountDrawerReducer, accountDrawerSelectors } from './AccountDrawer';
import { changePasswordActions, changePasswordReducer, changePasswordSelectors } from './ChangePassword';
import { contactBookActions, contactBookReducer, contactBookSelectors } from './ContactBook';
import { customerActions, customerReducer, customerSelectors } from './Customer';
import {
  moduleFunctionalityPermissionsActions, moduleFunctionalityPermissionsReducer, moduleFunctionalityPermissionsSelectors,
} from './ModuleFunctionalityPermissions';
import { newOrganizationDataActions, newOrganizationDataReducer, newOrganizationDataSelectors } from './NewOrganizationData';
import { newOrganizationDocumentsActions, newOrganizationDocumentsReducer, newOrganizationDocumentsSelectors } from './NewOrganizationDocuments';
import {
  newOrganizationWizardReviewAndConfirmActions, newOrganizationWizardReviewAndConfirmReducer, newOrganizationWizardReviewAndConfirmSelectors,
} from './NewOrganizationWizardReviewAndConfirm';
import { newOrganizationWizardStepsActions, newOrganizationWizardStepsReducer, newOrganizationWizardStepsSelectors } from './NewOrganizationWizardSteps';
import { userEditProfileActions, userEditProfileReducer, userEditProfileSelectors } from './UserEditProfile';
import { userInviteActions, userInviteReducer, userInviteSelectors } from './UserInvite';
import { userManagementActions, userManagementReducer, userManagementSelectors } from './UserManagement';
import { usersActions, usersReducer, usersSelectors } from './Users';
import { partnersActions, partnersReducer, partnersSelectors } from './Partners';
import { userOrganizationDataActions, userOrganizationDataReducer, userOrganizationDataSelectors } from './UserOrganizationData';
import { accountDepartmentsActions, accountDepartmentsReducer, accountDepartmentsSelectors } from './AccountDepartments';
import { teamsReducer, teamsSelectors, teamsActions } from './Teams';

export const actions = {
  accountDrawer: accountDrawerActions,
  accountDepartments: accountDepartmentsActions,
  changePassword: changePasswordActions,
  contactBook: contactBookActions,
  customer: customerActions,
  moduleFunctionalityPermissions: moduleFunctionalityPermissionsActions,
  newOrganizationData: newOrganizationDataActions,
  newOrganizationDocuments: newOrganizationDocumentsActions,
  newOrganizationWizardReviewAndConfirm: newOrganizationWizardReviewAndConfirmActions,
  newOrganizationWizardSteps: newOrganizationWizardStepsActions,
  userEditProfile: userEditProfileActions,
  userInvite: userInviteActions,
  userManagement: userManagementActions,
  userOrganizationData: userOrganizationDataActions,
  users: usersActions,
  partners: partnersActions,
  teams: teamsActions,
};

export const reducers = {
  accountDrawer: accountDrawerReducer,
  accountDepartments: accountDepartmentsReducer,
  changePassword: changePasswordReducer,
  contactBook: contactBookReducer,
  customer: customerReducer,
  moduleFunctionalityPermissions: moduleFunctionalityPermissionsReducer,
  newOrganizationData: newOrganizationDataReducer,
  newOrganizationDocuments: newOrganizationDocumentsReducer,
  newOrganizationWizardReviewAndConfirm: newOrganizationWizardReviewAndConfirmReducer,
  newOrganizationWizardSteps: newOrganizationWizardStepsReducer,
  userEditProfile: userEditProfileReducer,
  userInvite: userInviteReducer,
  userManagement: userManagementReducer,
  userOrganizationData: userOrganizationDataReducer,
  users: usersReducer,
  partners: partnersReducer,
  teams: teamsReducer,
};

export const selectors = {
  accountDrawer: accountDrawerSelectors,
  accountDepartments: accountDepartmentsSelectors,
  changePassword: changePasswordSelectors,
  contactBook: contactBookSelectors,
  customer: customerSelectors,
  moduleFunctionalityPermissions: moduleFunctionalityPermissionsSelectors,
  newOrganizationData: newOrganizationDataSelectors,
  newOrganizationDocuments: newOrganizationDocumentsSelectors,
  newOrganizationWizardReviewAndConfirm: newOrganizationWizardReviewAndConfirmSelectors,
  newOrganizationWizardSteps: newOrganizationWizardStepsSelectors,
  userEditProfile: userEditProfileSelectors,
  userInvite: userInviteSelectors,
  userManagement: userManagementSelectors,
  userOrganizationData: userOrganizationDataSelectors,
  users: usersSelectors,
  partners: partnersSelectors,
  teams: teamsSelectors,
};
