import React from 'react';
import { useSelector } from 'react-redux';

import { R } from 'app-wrapper/repository';
import { UC } from 'app-wrapper/controllers';

import { RailBillingDrawerComponent } from './RailBillingDrawer.component';

const RailBillingDrawerContainer = ({ shipmentId }: { shipmentId: string }) => {
  const isLoading = useSelector(R.selectors.railBillingDrawer.getIsLoading);
  const shipment = useSelector(R.selectors.railBillingDrawer.getShipment);
  const containers = useSelector(R.selectors.railBillingDrawer.getContainers);
  const markedContainers = useSelector(R.selectors.railBillingDrawer.getMarkedContainers);
  const isAbleToAccept = useSelector(R.selectors.railBillingDrawer.getIsAbleToAccept);
  const dueDate = useSelector(R.selectors.railBillingDrawer.getDueDate);

  return (
    <RailBillingDrawerComponent
      isLoading={isLoading}
      dueDate={dueDate}
      onClose={UC.railBilling.closeRailBillingDrawer}
      onAccept={UC.railBilling.acceptRailBilling}
      shipmentId={shipmentId}
      shipment={shipment}
      init={UC.railBilling.init}
      containers={containers}
      markedContainers={markedContainers}
      markAllContainers={UC.railBilling.markAllContainers}
      markContainer={UC.railBilling.markContainer}
      isAbleToAccept={isAbleToAccept}
    />
  );
};

export { RailBillingDrawerContainer as RailBillingDrawer };
