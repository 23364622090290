import React, {
  FC,
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import Typography from 'antd/es/typography';
import { SizeType } from 'antd/es/config-provider/SizeContext';

import {
  Row,
  Col,
  ExpandIcon,
  Panel,
  Collapse,
  TypeSwitch,
  Option,
} from 'app-wrapper/view/components';
import {
  useWindowSize, useCollapseOnChangeHandler,
} from 'app-wrapper/hooks';
import {
  VerticalFormItemSvg,
} from 'app-wrapper/view/icons';
import themesColors from 'app-wrapper/view/themes/themesColors';

import {
  ContainerAllTypesNamesShortConst,
  ContainerReeferTypesArray, ContainerReeferTypesNamesLongConst, ContainerUsualTypesArray, ContainerUsualTypesNamesLongConst, isContainerAllTypes,
} from 'shipment-operations/constants';

import { IFreightSelectFieldDTM } from 'monetary/models/dtm';

import {
  ContainersSelect,
  ContainersSwitchGensetWrap,
  ContainersSwitchSOCWrap,
  ContainersWrapper,
} from './Containers.styled';

const collapseIcon = (<VerticalFormItemSvg />);

export interface IContainersComponentProps {
  isAllDisabled?: boolean
  formLocalState: { isCollapsed?: boolean }
  setFormLocalState: React.Dispatch<{ isCollapsed?: boolean }>
  forceCollapse?: boolean
  isCollapsed?: boolean
  isMetric?: boolean
  socControl?: boolean
  gensetControl?: boolean
  containersValues?: string[]
  isContainersError?: boolean
  isSubmitVisited?: boolean
  isSubmitClicked?: boolean
  temperatureControl?: boolean
  onChangeContainersSocControl: (socControl: boolean) => void
  // Type
  onChangeContainersType: (indexItem: number) => (value: IFreightSelectFieldDTM) => void
  onClearContainersType: (indexItem: number) => () => void
  onFocusContainersType: (indexItem: number) => () => void
  onBlurContainersType: (indexItem: number) => () => void
}

export const ContainersComponent: FC<IContainersComponentProps> = ((props) => {
  const {
    formLocalState,
    isAllDisabled,
    forceCollapse,
    isCollapsed,
    socControl,
    gensetControl,
    containersValues,
    isSubmitVisited,
    isSubmitClicked,
    isContainersError,
    temperatureControl,
    onChangeContainersSocControl,
    // Type
    onChangeContainersType,
    onClearContainersType,
    onFocusContainersType,
    onBlurContainersType,
  } = props;

  const { isFullMediaWidth } = useWindowSize();
  const [isResponsive, setIsResponsive] = useState(isFullMediaWidth);
  useEffect(() => {
    if (forceCollapse) {
      setIsResponsive(false);
    } else {
      setIsResponsive(!!formLocalState?.isCollapsed);
    }
  }, [forceCollapse, formLocalState?.isCollapsed]);
  const sizeSelect: SizeType = useMemo(() => (isFullMediaWidth ? 'large' : 'middle'), [isFullMediaWidth]);
  const { t } = useTranslation();

  const typeOptions = useMemo(() => {
    if (temperatureControl) {
      return (
        <>
          {ContainerReeferTypesArray.map((item) => (
            <Option key={item} value={item} description={ContainerReeferTypesNamesLongConst[item]}>{ContainerReeferTypesNamesLongConst[item]}</Option>
          ))}
        </>
      );
    }

    return (
      <>
        {ContainerUsualTypesArray.map((item) => (
          <Option key={item} value={item} description={ContainerUsualTypesNamesLongConst[item]}>{ContainerUsualTypesNamesLongConst[item]}</Option>
        ))}
      </>
    );
  }, [temperatureControl, t]);

  const switchSoc = useCallback((invertActive?: boolean) => (
    socControl
      ? (
        <ContainersSwitchSOCWrap>
          <TypeSwitch
            value={!!socControl}
            data-class="switchSoc"
            invertActive={!!invertActive}
            rightText={t('SOC')}
            checkedChildren="On"
            unCheckedChildren="Off"
            disabledbg="true"
            onChange={onChangeContainersSocControl}
            disabled={isAllDisabled}
            id="switchSoc"
          />
        </ContainersSwitchSOCWrap>
      )
      : null
  ), [socControl, isAllDisabled, isResponsive, onChangeContainersSocControl]);

  const switchGenset = useCallback((invertActive?: boolean) => (
    gensetControl
      ? (
        <ContainersSwitchGensetWrap isFirst={!socControl}>
          <TypeSwitch
            value={!!gensetControl}
            data-class="switchGenset"
            invertActive={!!invertActive}
            rightText={t('Genset')}
            checkedChildren="On"
            unCheckedChildren="Off"
            disabledbg="true"
            disabled={isAllDisabled}
            id="switchGenset"
          />
        </ContainersSwitchGensetWrap>
      )
      : null
  ), [gensetControl, socControl, isAllDisabled, temperatureControl, isResponsive]);

  const headerIsFullComponent = useMemo(() => isResponsive && (
    <>
      <Row className="RFRFormRequest__Row__Containers__content">
        <Col span={10}>
          <div className="RFRFormRequest__Row__Containers__content_title">
            <Typography.Text>{t('Type')}</Typography.Text>
          </div>
        </Col>
        <Col span={1} style={{ minWidth: isFullMediaWidth ? '40px' : '32px' }} />
      </Row>
    </>
  ), [isFullMediaWidth, isResponsive, t]);

  const onChangeContainersTypeHandler = useCallback(
    (index: number) => (value: string, option: unknown) => {
      const { description } = option as { description: string } || {};

      if (value) {
        onChangeContainersType(index)({
          code: value,
          description: description || '',
        });
      }
    },
    [onChangeContainersType],
  );

  const containersType = useMemo(() => {
    const value = containersValues?.map((item) => (isContainerAllTypes(item) ? ContainerAllTypesNamesShortConst[item] : null))?.filter((item) => item);
    return value;
  }, [containersValues]);

  const containerTypeComponent = useCallback(() => (
    <ContainersSelect
      mode="multiple"
      value={containersType}
      data-class="containerTypeComponent"
      size={sizeSelect}
      placeholder={t('Container Type')}
      disabled={isAllDisabled}
      suffixIcon={collapseIcon}
    />
  ), [containersValues,
    isAllDisabled,
    isSubmitVisited,
    onClearContainersType,
    onChangeContainersTypeHandler,
    onFocusContainersType,
    onBlurContainersType,
    sizeSelect, t, typeOptions,
  ]);

  const itemContainerIsFull = useCallback((index) => (
    <Row
      className={`${index === 0 ? 'RFRFormRequest__Row__Containers__parent_content_item_first' : ''} RFRFormRequest__Row__Containers__content RFRFormRequest__Row__Containers__parent_content_item`}
      key={`RFRFormRequest__Row__Containers__parent_content_item_${index}`}
    >
      <Col span={14}>
        {containerTypeComponent()}
      </Col>
    </Row>
  ), [
    containerTypeComponent,
    isAllDisabled,
  ]);

  const itemContainerIsMedium = useCallback((index) => (
    <Row
      className={`${index === 0 ? 'RFRFormRequest__Row__Containers__parent_content_item_first' : ''} RFRFormRequest__Row__Containers__content RFRFormRequest__Row__Containers__parent_content_item
        RFRFormRequest__Row__Containers__parent_content_item__medium`}
      key={`RFRFormRequest__Row__Containers__parent_content_item_${index}`}
    >
      <Col span={24}>
        <Row>
          <Col span={18}>
            <div className="RFRFormRequest__Row__Containers__content_title">
              <Typography.Text>{t('Type')}</Typography.Text>
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            {containerTypeComponent()}
          </Col>
        </Row>
      </Col>
    </Row>
  ), [
    containerTypeComponent,
    isAllDisabled, t,
  ]);

  const containersComponent = useMemo(() => (
    <>
      {isResponsive && (
        <Row justify="space-between" className="RFRFormRequest__Row__col_both__title RFRFormRequest__Row__Containers__temperature">
          <div>
            <Typography.Text>{t('Containers')}</Typography.Text>
          </div>
        </Row>
      )}

      {headerIsFullComponent}
      {isResponsive ? itemContainerIsFull(0) : itemContainerIsMedium(0)}

      <Row justify="start" className="RFRFormRequest__Row__col_both_col__title_owned switchSoc">
        {isResponsive ? (
          <>
            {switchSoc()}
            {switchGenset()}
          </>
        ) : (
          <>
            <Col span={6} className="isMedium switchSoc">
              {switchSoc(true)}
            </Col>
            <Col span={7} className="isMedium switchGenset">
              {switchGenset(true)}
            </Col>
          </>
        )}
      </Row>
    </>
  ), [
    containersValues,
    switchSoc,
    t,
    headerIsFullComponent,
    isResponsive,
    itemContainerIsFull,
    itemContainerIsMedium,
  ]);
  const endVisible = useRef<HTMLElement>(null);

  const [
    changeErrorIsCollapseState, setChangeErrorIsCollapseState,
  ] = useState<Array<string>>([]);

  useEffect(() => {
    if (isSubmitVisited && isContainersError) {
      setChangeErrorIsCollapseState(['1']);
    }
  }, [isSubmitVisited, isContainersError, isSubmitClicked]);

  const changeErrorIsCollapseCallback = useCallback(
    () => {
      setChangeErrorIsCollapseState((prev) => (prev.length ? [] : ['1']));
    },
    [],
  );

  const collapseOnChangeHandler = useCollapseOnChangeHandler(
    endVisible.current, changeErrorIsCollapseCallback,
  );

  const collapseComponent = useMemo(() => (
    <Collapse
      expandIcon={({ isActive }) => ExpandIcon({
        isActive,
        color: `${changeErrorIsCollapseState.length ? themesColors.primaryBranding6 : themesColors.neutralBranding7}`,
      })}
      onChange={collapseOnChangeHandler}
      activeKey={changeErrorIsCollapseState}
    >
      <Panel
        header={t('Containers')}
        key="1"
      >
        {containersComponent}
      </Panel>
    </Collapse>
  ), [changeErrorIsCollapseState, collapseOnChangeHandler, containersComponent, t]);

  return (
    <ContainersWrapper isNotCollapsed={!isCollapsed}>
      <Row className="RFRFormRequest__Row__col_both RFRFormRequest__Row__Containers RFRFormRequest__Row__col__containersPanel">
        <Col span={24}>
          {!isResponsive ? (
            collapseComponent
          ) : (
            containersComponent
          )}
          <span
            id="RFRFormRequest__Row__col__containerssPanel__ref_endVisible"
            ref={endVisible}
          />
        </Col>
      </Row>
    </ContainersWrapper>
  );
});
