import React from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';
import { R as monetaryR } from 'monetary/repository';

import { NRADocumentPageComponent } from 'shipment-operations/view/pages/ShipmentDocumentsAll/components/DocumentsSection/components/ActionMenu/components/PrintNRAAgreementRFQ/components/NRADocumentPage/NRADocumentPage.component';

const NRAPageBetweenAdminAndContractOwnerContainer = () => {
  const quotaId = useSelector(monetaryR.selectors.freightQuote.FreightQuotePrintSelectors.printedQuotaId);
  const validity = useSelector(monetaryR.selectors.freightQuote.FreightQuotePrintSelectors.getValidity);
  const shipment = useSelector(R.selectors.shipment.getShipment);
  const contractOwner = useSelector(R.selectors.shipmentDocumentsAll.getContractOwnerOrganization);
  const adminPublicInfo = useSelector(R.selectors.shipmentDocumentsAll.getContractOwnerRelatedAdminPublicInfo);

  return (
    <NRADocumentPageComponent
      quotaId={quotaId}
      validityFrom={validity.from}
      validityTo={validity.to}
      customerAddress={adminPublicInfo ? `${adminPublicInfo.name}${adminPublicInfo?.address ? `, ${adminPublicInfo?.address.getAddressLine()}` : ''}` : ''}
      isNRABetweenContractOwnerAndAdmin
      createdByName={contractOwner?.primaryContact ? contractOwner?.primaryContact.getFullName() : ''}
      rightSignaturePerson={adminPublicInfo?.primaryContact ? adminPublicInfo?.primaryContact.getFullName() : ''}
      createdAt={shipment?.createdAt ? shipment?.createdAt.getDateDMMMYYYYHHmm() : ''}
    />
  );
};

export { NRAPageBetweenAdminAndContractOwnerContainer as NRAPageBetweenAdminAndContractOwner };
