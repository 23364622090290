import React, { forwardRef } from 'react';
import { ButtonProps } from 'antd/lib/button/button';
import { Button as AntButton } from './Button.styled';

// TODO: fix ref types
export const Button = forwardRef<any, ButtonProps>((
  props,
  ref,
) => (
  <AntButton
    ref={ref as React.RefObject<HTMLElement>}
    {...props}
  />
));

Button.defaultProps = {
  type: 'primary',
  size: 'middle',
};
