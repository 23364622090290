import {
  IsDefined, IsString, IsOptional, IsNumber, ValidateNested, IsBoolean, IsEnum, IsEmail,
} from 'class-validator';
import { Type } from 'class-transformer';
import { BaseDTM } from 'proto/BaseDTM';

import { EShippingPartyTypes } from 'shipment-operations/constants';

import { IAddressDTM, AddressDTM } from './Address.dtm';
import { ICompanyDTM, CompanyDTM } from './Company.dtm';
import { IContactDTM, ContactDTM } from './Contact.dtm';

export interface IShippingPartyReference {
  id: string
  value: string
}

export class ShippingPartyReference extends BaseDTM<IShippingPartyReference> {
  @IsDefined()
  @IsString()
  id: string;

  @IsDefined()
  @IsString()
  value: string;
}

export interface IShippingPartyContactDTM {
  id: number | null
  fullName: string
  email: string
  phone: string
  phone2?: string
  primary?: boolean
}

export class ShippingPartyContactDTM extends BaseDTM<IShippingPartyContactDTM> {
  @IsNumber()
  @IsOptional()
  id: number | null;

  @IsDefined()
  @IsString()
  fullName: string;

  @IsDefined()
  @IsEmail()
  email: string;

  @IsDefined()
  @IsString()
  phone: string;

  @IsOptional()
  @IsString()
  phone2?: string;

  @IsOptional()
  @IsBoolean()
  primary?: boolean;
}

export interface IShippingPartyAddressDTM {
  id: number | null
  country: string
  state?: string
  city: string
  address1: string
  address2?: string
  postalCode: string
  closestPort: string
  contacts?: ContactDTM[]
  primary?: boolean
}

export class ShippingPartyAddressDTM extends BaseDTM<IShippingPartyAddressDTM> {
  @IsOptional()
  @IsNumber()
  id: number | null;

  @IsDefined()
  @IsString()
  country: string;

  @IsOptional()
  @IsString()
  state?: string;

  @IsDefined()
  @IsString()
  city: string;

  @IsDefined()
  @IsString()
  address1: string;

  @IsOptional()
  @IsString()
  address2?: string;

  @IsDefined()
  @IsString()
  postalCode: string;

  @IsDefined()
  @IsString()
  closestPort: string;

  @IsOptional()
  @ValidateNested({ each: true })
  @Type(() => ContactDTM)
  contacts?: ContactDTM[];

  @IsOptional()
  @IsBoolean()
  primary?: boolean;

  getAddressLine = () => {
    let addressLine = this.city;
    if (this.state) {
      addressLine += `, ${this.state}`;
    }
    addressLine += `, ${this.country}, ${this.address1}, ${this.postalCode}`;

    return addressLine;
  }
}

export interface IShippingPartyDTM {
  id?: number

  role: EShippingPartyTypes

  company?: ICompanyDTM;
  address?: IShippingPartyAddressDTM;
  contact?: IShippingPartyContactDTM;

  addressList: IAddressDTM[]
  contactList: IContactDTM[]

  references: IShippingPartyReference[]

  description?: string

  isHasCompanyBook?: boolean
  isSameAsMyOrganisation?: boolean
  hasSystemUpdatePolicy?: boolean
  isFreightForwarderOrgType?: boolean
  isAgreeNra?: boolean
  isAgreeNraError?: boolean
  isAgreeNraShow?: boolean
}

export class ShippingPartyDTM extends BaseDTM<IShippingPartyDTM> {
  @IsOptional()
  @IsNumber()
  id?: number;

  @IsDefined()
  @IsEnum(EShippingPartyTypes)
  role: EShippingPartyTypes;

  @IsOptional()
  @ValidateNested()
  @Type(() => CompanyDTM)
  company?: CompanyDTM;

  @IsOptional()
  @ValidateNested()
  @Type(() => ShippingPartyAddressDTM)
  address?: ShippingPartyAddressDTM;

  @IsOptional()
  @ValidateNested()
  @Type(() => ShippingPartyContactDTM)
  contact?: ShippingPartyContactDTM;

  @IsDefined()
  @ValidateNested({ each: true })
  @Type(() => AddressDTM)
  addressList: AddressDTM[]

  @IsDefined()
  @ValidateNested({ each: true })
  @Type(() => ContactDTM)
  contactList: ContactDTM[]

  @IsDefined()
  @ValidateNested()
  @Type(() => ShippingPartyReference)
  references: ShippingPartyReference[]

  @IsOptional()
  @IsString()
  description?: string;

  @IsOptional()
  @IsBoolean()
  isHasCompanyBook?: boolean;

  @IsOptional()
  @IsBoolean()
  isSameAsMyOrganisation?: boolean;

  @IsOptional()
  @IsBoolean()
  hasSystemUpdatePolicy?: boolean;

  @IsOptional()
  @IsBoolean()
  isFreightForwarderOrgType?: boolean;

  @IsOptional()
  @IsBoolean()
  isAgreeNra?: boolean;

  @IsOptional()
  @IsBoolean()
  isAgreeNraError?: boolean;

  @IsOptional()
  @IsBoolean()
  isAgreeNraShow?: boolean;
}
