import Card from 'antd/es/card';
import styled from 'styled-components';
import Divider from 'antd/es/divider';
import { NavLink } from 'react-router-dom';

import { divFlex } from 'app-wrapper/view/themes/mixin';

export const RCQItemSubCardContainer = styled(NavLink).attrs((props: {
  isClickable?: boolean,
}) => ({
  isClickable: props.isClickable,
  'data-class': 'RCQItemSubCardContainer',
}))`
  ${divFlex}
  padding: 0;

  width: 100%;
  height:  76px;

  border: 1px solid ${({ theme }) => theme.themesColors.neutralBranding4};
  border-radius: 4px;

  background-color: ${({ theme }) => theme.themesColors.neutralBranding1};
  line-height: inherit !important;
  color: ${({ theme }) => theme.themesColors.neutralBranding13} !important;

  ${({ isClickable }) => !isClickable && 'cursor: auto;'};
  ${({ isClickable }) => !isClickable && 'border: none;'};
  ${({ isClickable }) => !isClickable && 'box-shadow: none;'};

  &:hover {
    ${({ isClickable }) => !isClickable && 'border: none;'};
    ${({ isClickable }) => !isClickable && 'box-shadow: none;'};
  }
`;

export const RCQItemSubCardSectionRequest = styled.div.attrs((props: {
  isClickable?: boolean,
  width?: string,
}) => ({
  isClickable: props.isClickable,
  width: props.width,
  'data-class': 'RCQItemSubCardSectionRequest',
}))`
  ${divFlex}
  width: ${({ width }) => (width || '20%')};
  padding-left: 16px;

  div[data-class="RCQItemSubCardDescriptionsContainerContent"] span[data-class="RCQItemSubCardDescriptionsContainerSpanClip"] {
    color: ${({ theme, isClickable }) => (isClickable ? theme.themesColors.primaryBranding7 : theme.themesColors.neutralBranding10)};

    ${({ isClickable }) => !isClickable && 'font-size: 20px;'};
  }
  ${({ isClickable }) => !isClickable && 'padding-left: 0;'};
`;

export const RCQItemSubCardSectionContainerAndCommodity = styled.div.attrs({
  'data-class': 'RCQItemSubCardSectionContainerAndCommodity',
})`
  ${divFlex}
  width: 17.2%;
`;

export const RCQItemSubCardSectionCustomerTitle = styled.div.attrs({
  'data-class': 'RCQItemSubCardSectionCustomerTitle',
})`
  ${divFlex}

  font-size: 12px;
  font-weight: 400;
  color: ${({ theme }) => theme.themesColors.secondaryDot45};
`;

export const RCQItemSubCardSectionVerticalDivider = styled.div.attrs({
  'data-class': 'RCQItemSubCardSectionVerticalDivider',
})`
  ${divFlex}
  width: 0;
  height: 52px;

  margin: auto 0;
  border-left: 1px dashed ${({ theme }) => theme.themesColors.neutralBranding5};
`;

export const RCQItemSubCardSectionVerticalDividerIcon = styled.div.attrs({
  'data-class': 'RCQItemSubCardSectionVerticalDividerIcon',
})`
  ${divFlex}
  justify-content: center;
  align-items: center;
  height: 100%;

  margin: 0;
`;

export const RCQItemSubCardSectionOrigin = styled.div.attrs({
  'data-class': 'RCQItemSubCardSectionOrigin',
})`
  ${divFlex}
  width: 17.2%;

  div[data-class="SCDescriptionsContainer"] {
    width: calc(100% - 16px - 16px - 8px);
  }
`;

export const RCQItemSubCardSectionDestination = styled.div.attrs({
  'data-class': 'RCQItemSubCardSectionDestination',
})`
  ${divFlex}
  width: 17.2%;

  div[data-class="SCDescriptionsContainer"] {
    width: calc(100% - 16px - 16px - 8px);
  }
`;

export const RCQItemSubCardSectionCustomer = styled.div.attrs({
  'data-class': 'RCQItemSubCardSectionCustomer',
})`
  ${divFlex}
  width: 17.2%;
`;

export const RCQItemSubCardSectionTasks = styled.div.attrs({
  'data-class': 'RCQItemSubCardSectionTasks',
})`
  ${divFlex}
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 6.2%;
`;

export const RCQItemSubCardSectionTasksIcon = styled.div.attrs((props: {
  colorSvg?: string,
  marginBottomSvg?: string,
}) => ({
  colorSvg: props.colorSvg,
  marginBottomSvg: props.marginBottomSvg,
  'data-class': 'RCQItemSubCardSectionTasksIcon',
}))`
  ${divFlex}
  width: 7px;
  height: 7px;

  border-radius: 50%;
  ${({ colorSvg }) => colorSvg && `color: ${colorSvg}`};
`;

export const RCQItemSubCardSectionTasksText = styled.div.attrs((props: {
  colorBg?: string,
}) => ({
  colorBg: props.colorBg,
  'data-class': 'RCQItemSubCardSectionTasksText',
}))`
  ${divFlex}
  min-width: 23px;
  justify-content: center;

  color: ${({ theme }) => theme.themesColors.secondaryDot45};
  font-size: 12px;
  font-weight: 400;

  border-radius: 100px;
  ${({ colorBg }) => colorBg && `background-color: ${colorBg}`};
`;

export const RCQItemSubCardSectionIcon = styled.div.attrs((props: {
  marginLeft?: string,
  marginRight?: string,
}) => ({
  marginLeft: props.marginLeft,
  marginRight: props.marginRight,
  'data-class': 'RCQItemSubCardSectionIcon',
}))`
  ${divFlex}

  justify-content: center;
  align-items: center;
  margin-left: ${({ marginLeft }) => (marginLeft || '16px')};
  margin-right: ${({ marginRight }) => (marginRight || '8px')};
`;

export const RCQItemSubCardSectionContentDescriptions = styled.div.attrs({
  'data-class': 'RCQItemSubCardSectionContentDescriptions',
})`
  ${divFlex}

  justify-content: center;
  align-items: center;
  margin-left: 16px;
  margin-right: 8px;
`;

export const RCQItemSubCardSectionDivider = styled(Divider).attrs({
  'data-class': 'RCQItemSubCardSectionDivider',
})`
  ${divFlex}
  width: 100%;
  margin: 0;
`;

export const ARQListLoadingCardStyled = styled(Card).attrs({
  'data-class': 'ARQListLoadingCardStyled',
})`
  ${divFlex}
  width: 100%;
`;
