import styled from 'styled-components';
import Steps from 'antd/es/steps';

import themesColors from 'app-wrapper/view/themes/themesColors';

const { Step } = Steps;

export const NewOrganizationWizardWrapperAsideContainer = styled.div.attrs({
  'data-class': 'NewOrganizationWizardWrapperAsideContainer',
})`
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  
  gap: 16px;
  width: 100%;
  height: 100%;
  padding: 24px;

  font-size: 20px;
  
  background-color: ${themesColors.neutralBranding1};
  box-shadow: 0px -1px 0px 0px ${themesColors.neutralBranding3} inset;
`;

export const NewOrganizationWizardWrapperAsideTitle = styled.div.attrs({
  'data-class': 'NewOrganizationWizardWrapperAsideTitle',
})`
  font-size: 16px;
  font-weight: 500;
`;

export const NewOrganizationWizardWrapperAsideSteps = styled(Steps).attrs({
  'data-class': 'NewOrganizationWizardWrapperAsideSteps',
})`
`;

export const NewOrganizationWizardWrapperAsideStep = styled(Step).attrs({
  'data-class': 'NewOrganizationWizardWrapperAsideStep',
})`
  svg.ant-progress-circle {
    display: none;
  }
`;
