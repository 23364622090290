import styled from 'styled-components';
import AntdUpload from 'antd/es/upload';
import AntdButton from 'antd/es/button';
import { colors } from 'app-wrapper/view/themes/themesColors';

export const Upload = styled(AntdUpload)`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 32px;
  margin-bottom: 20px;

  .ant-upload-list, .ant-upload-list-item {
    font-size: 12px;
  }
  .ant-upload-list-item-name {
    color: #4A55AD;
    cursor: pointer;
  }
  .ant-upload-list-item-error .ant-upload-list-item-name {
    color: #FF4D4F;
  }
`;

export const Button = styled(AntdButton)`
  width: 100%;
  height: 32px;
  min-height: 32px;
  font-size: 12px;
  box-shadow: none;
  color: ${colors.gray61}
`;
