import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { R } from 'shipment-operations/repository';
import { UC } from 'shipment-operations/controllers';
import { useAuthToken } from 'app-wrapper/hooks';

import { ConfirmationDocumentComponent } from './ConfirmationDocument.component';

const ConfirmationDocumentContainer = () => {
  const { shipmentId = '0' } = useParams();
  const authToken = useAuthToken();
  const { confirmationDocument: error } = useSelector(R.selectors.manualBookingWizard.getErrors);
  const document = useSelector(R.selectors.manualBookingWizard.getConfirmationDocument);

  return (
    <ConfirmationDocumentComponent
      error={!!error}
      authToken={authToken}
      shipmentId={shipmentId}
      containerDocument={document ? [document] : []}
      setContainerDocument={UC.manualBookingWizard.setConfirmationDocument}
      downloadDocument={UC.manualBookingWizard.downloadDocument}
    />
  );
};

export { ConfirmationDocumentContainer as ConfirmationDocument };
