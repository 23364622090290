import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';

export const textColorFirst = css`
  color: ${({ theme }) => theme.themesColors.characterBrandingTitle85};
`;

export const divFlex = css`
  display: flex;
  position: relative;
  ${textColorFirst}
`;

// Div

export const ContainerComponentItemDiv = styled.div.attrs({
  'data-class': 'ContainerComponentItemDiv',
})`
  ${divFlex}
  flex-direction: column;
  background-color: ${({ theme }) => theme.themesColors.neutralBranding1};
  padding: 14px 0px;
  padding-bottom: 8px;
  border: 1px solid ${({ theme }) => theme.themesColors.neutralBranding3};
  border-radius: 4px;
`;

export const ARQQuotesItemDiv = styled(ContainerComponentItemDiv).attrs({
  'data-class-second': 'ARQQuotesItemDiv',
})`
  padding: 0px;
  padding-bottom: 0;
`;

export const ARQCardContainer = styled(NavLink).attrs((props: {
  isClickable?: boolean,
}) => ({
  isClickable: props.isClickable,
  'data-class': 'ARQCardContainer',
}))`
  ${divFlex}
  padding: 0;

  width: 100%;
  height:  76px;

  border: 1px solid ${({ theme }) => theme.themesColors.neutralBranding4};
  border-radius: 4px;

  background-color: ${({ theme }) => theme.themesColors.neutralBranding1};
  line-height: inherit !important;
  color: ${({ theme }) => theme.themesColors.neutralBranding13} !important;

  ${({ isClickable }) => !isClickable && 'cursor: auto;'};
  ${({ isClickable }) => !isClickable && 'border: none;'};
  ${({ isClickable }) => !isClickable && 'box-shadow: none;'};

  &:hover {
    ${({ isClickable }) => !isClickable && 'border: none;'};
    ${({ isClickable }) => !isClickable && 'box-shadow: none;'};
  }
`;

export const ARQCardSectionIcon = styled.div.attrs({
  'data-class': 'ARQCardSectionIcon',
})`
  ${divFlex}

  justify-content: center;
  align-items: center;
  margin-left: 16px;
  margin-right: 8px;
`;

// export const ARQQuotesItemContentLeft = styled.div`
// ${divFlex}
// `;

// const ItemContentLeftDiv = css`
// flex-direction: column;
// padding-left: 8px;
// padding-right: 15px;
// `;

// const ItemContentLeftDivIcon = css`
// align-items: center;
// `;

// const ItemContentLeftOriginTitle = css`
// color: ${({ theme }) => theme.themesColors.secondaryDot45};
// font-weight: 400;
// font-size: 12px;
// line-height: 19px;
// `;

// const ItemContentLeftOriginFirstText = css`
// color: ${({ theme }) => theme.themesColors.neutralBranding10};
// font-weight: 400;
// font-size: 14px;
// line-height: 19px;
// `;

// const ItemContentLeftOriginSecondText = css`
// color: ${({ theme }) => theme.themesColors.neutralBranding10};
// font-weight: 400;
// font-size: 14px;
// line-height: 19px;
// `;

// const ItemContentLeftOriginSecondTextPre = css`
// color: ${({ theme }) => theme.themesColors.secondaryDot45};
// font-weight: 400;
// font-size: 14px;
// line-height: 19px;
// padding-right: 6px;
// `;

// export const ARQQuotesItemContentLeftOrigin = styled.div.attrs({
//   'data-class': 'ARQQuotesItemContentLeftOrigin',
// })`
// ${divFlex}
// `;

// export const ARQQuotesItemContentLeftOriginContent = styled.div`
// ${divFlex}
// ${ItemContentLeftDiv}
// `;

// export const ARQQuotesItemContentLeftOriginIcon = styled.div`
// ${divFlex}
// ${ItemContentLeftDivIcon}
// `;

// export const ARQQuotesItemContentLeftOriginTitle = styled.div`
// ${divFlex}
// ${ItemContentLeftOriginTitle}
// `;

// export const ARQQuotesItemContentLeftOriginFirstText = styled.div`
// ${divFlex}
// ${ItemContentLeftOriginFirstText}
// `;

// export const ARQQuotesItemContentLeftOriginSecondTextPre = styled.div`
// ${divFlex}
// ${ItemContentLeftOriginSecondTextPre}
// `;

// export const ARQQuotesItemContentLeftOriginSecondText = styled.div`
// ${divFlex}
// ${ItemContentLeftOriginSecondText}
// `;

// export const ARQQuotesItemContentLeftSeparate = styled.div`
// ${divFlex}
// align-items: center;
// `;

// export const ARQQuotesItemContentLeftDestination = styled.div.attrs({
//   'data-class': 'ARQQuotesItemContentLeftDestination',
// })`
// ${divFlex}
// padding-left: 15px;
// `;

// export const ARQQuotesItemContentLeftDestinationContent = styled.div`
// ${divFlex}
// ${ItemContentLeftDiv}
// `;

// export const ARQQuotesItemContentLeftDestinationIcon = styled.div`
// ${divFlex}
// ${ItemContentLeftDivIcon}
// `;

// export const ARQQuotesItemContentLeftDestinationTitle = styled.div`
// ${divFlex}
// ${ItemContentLeftOriginTitle}
// `;

// export const ARQQuotesItemContentLeftDestinationFirstText = styled.div`
// ${divFlex}
// ${ItemContentLeftOriginFirstText}
// `;

// export const ARQQuotesItemContentLeftDestinationSecondTextPre = styled.div`
// ${divFlex}
// ${ItemContentLeftOriginSecondTextPre}
// `;

// export const ARQQuotesItemContentLeftDestinationSecondText = styled.div`
// ${divFlex}
// ${ItemContentLeftOriginSecondText}
// `;

// export const ARQQuotesItemContentRight = styled.div`
// ${divFlex}
// margin-left: auto;
// flex-direction: column;
// justify-content: space-between;
// `;

// export const ARQQuotesItemContentRightFirst = styled.div`
// ${divFlex}
// margin-left: auto;
// `;

// export const ARQQuotesItemContentRightFirstIcon = styled.div`
// ${divFlex}
// svg {
//   color: ${({ theme }) => theme.themesColors.characterBrandingDisabled025};
// }
// align-items: center;
// margin-right: 8px;
// `;

// export const ARQQuotesItemContentRightFirstText = styled.div`
// ${divFlex}
// color: ${({ theme }) => theme.themesColors.neutralBranding10};
// font-weight: 400;
// font-size: 14px;
// line-height: 19px;
// `;

// export const ARQQuotesItemContentRightSecond = styled.div`
// ${divFlex}
// margin-left: auto;
// `;

// export const ARQQuotesItemContentRightSecondItem = styled.div`
// ${divFlex}
// color: ${({ theme }) => theme.themesColors.neutralBranding10};
// background-color: ${({ theme }) => theme.themesColors.neutralBranding3};
// font-weight: 400;
// font-size: 12px;
// border: 1px solid ${({ theme }) => theme.themesColors.neutralBranding4};
// border-radius: 2px;
// margin-right: 4px;
// padding: 2px 8px;
// align-items: center;
// `;

// export const ARQQuotesItemContentRightSecondItems = styled.div`
// ${divFlex}
// color: ${({ theme }) => theme.themesColors.secondaryDot45};
// font-weight: 400;
// font-size: 12px;
// background-color: ${({ theme }) => theme.themesColors.neutralBranding1};
// border: 1px solid ${({ theme }) => theme.themesColors.neutralBranding4};
// border-radius: 2px;
// padding: 2px 8px;
// align-items: center;
// `;

// // Content Footer

// export const ARQQuotesItemFooter = styled.div.attrs({
//   'data-class': 'ARQQuotesItemFooter',
// })`
// ${divFlex}
// width: 100%;
// border-top: 1px solid ${({ theme }) => theme.themesColors.neutralBranding3};
// padding-left: 16px;
// padding-right: 16px;
// padding-top: 8px;
// align-items: center;
// `;

// export const ARQQuotesItemFooterIcon = styled.div`
// ${divFlex}
// svg {
//   color: ${({ theme }) => theme.themesColors.characterBrandingDisabled025};
// }
// `;

// export const ARQQuotesItemFooterFirstText = styled.div`
// ${divFlex}
// color: ${({ theme }) => theme.themesColors.secondaryDot45};
// font-weight: 400;
// font-size: 12px;
// padding-left: 11px;
// padding-right: 8px;
// `;

// export const ARQQuotesItemFooterSecondText = styled.div`
// ${divFlex}
// color: ${({ theme }) => theme.themesColors.neutralBranding10};
// font-weight: 400;
// font-size: 12px;
// padding-right: 8px;
// `;

// export const ARQQuotesItemFooterInfoIcon = styled.div`
// ${divFlex}
// svg {
//   color: ${({ theme }) => theme.themesColors.secondaryDot45};
// }
// `;

// export const ARQTableContentTitle = styled.div.attrs((props: {
//   isActive?: boolean,
//   isCapitalize?: boolean,
// }) => ({
//   isActive: props.isActive,
//   isCapitalize: props.isCapitalize,
//   'data-class': 'ARQTableContentTitle',
// }))`
//   ${divFlex}
//   width: calc(41% - 12px - 31px - 10px);
//   align-items: center;
//   justify-content: left;

//   margin-left: 10px;
//   color: ${({ theme }) => theme.themesColors.characterBrandingTitle85};
//   color: ${({ theme, isActive }) => (isActive ? theme.themesColors.primaryBranding6 : theme.themesColors.characterBrandingTitle85)};
//   font-size: 14px;
//   font-weight: 400;

//   ${({ isCapitalize }) => isCapitalize && 'text-transform: capitalize'};
// `;

// export const ARQTableContentLink = styled.div.attrs({
//   'data-class': 'ARQTableContentLink',
// })`
//   ${divFlex}
//   width: 14.5%;
//   align-items: center;
//   justify-content: left;

//   color: ${({ theme }) => theme.themesColors.primaryBranding7};
//   font-size: 14px;
//   font-weight: 400;

//   text-overflow: ellipsis;
//   overflow: clip;
//   white-space: nowrap;
//   cursor: pointer;

//   > span:first-letter {
//     text-transform: uppercase;
//   }
// `;
