import { createSelector } from 'reselect';

import { RootState } from 'app-wrapper/store';
import { CustomsClearanceDTM } from 'shipment-operations/models/dtm';

const localState = (state: RootState) => state.exportClearance;

const getWholeState = createSelector(
  localState,
  (state) => state,
);

const getLoading = createSelector(
  localState,
  (state) => state.isLoading,
);

const getUpdateAttemptStatus = createSelector(
  localState,
  (state) => state.wasUpdateAttempted,
);

const getDraftStatus = createSelector(
  localState,
  (state) => !CustomsClearanceDTM.areInstancesEqual(state.exportClearance, state.initialState),
);

const getInitialState = createSelector(
  localState,
  (state) => state.initialState,
);

const getTouchedFields = createSelector(
  localState,
  (state) => state.touchedFields,
);

const getErrors = createSelector(
  localState,
  (state) => state.errors,
);

const getExportClearance = createSelector(
  localState,
  (state) => state.exportClearance,
);

export const exportClearanceSelectors = {
  getWholeState,
  getLoading,
  getUpdateAttemptStatus,
  getDraftStatus,
  getInitialState,
  getTouchedFields,
  getErrors,
  getExportClearance,
};
