import {
  IsBoolean,
  IsEmail, IsOptional, IsString,
} from 'class-validator';
import { BaseDTM } from 'proto/BaseDTM';

export type TUserAuthData = {
  firstName?: string
  lastName?: string
  email?: string
  userId?: string
  isAdmin?: boolean
}

export class UserAuthDataDTM extends BaseDTM<TUserAuthData> {
  @IsOptional()
  @IsString()
  firstName: string

  @IsOptional()
  @IsString()
  lastName: string

  @IsOptional()
  @IsEmail()
  email: string

  @IsOptional()
  @IsString()
  userId: string

  @IsOptional()
  @IsBoolean()
  isAdmin: boolean
}
