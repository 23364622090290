import styled from 'styled-components';
import Typography from 'antd/es/typography';

import { divFlex } from 'app-wrapper/view/themes/mixin';

export const SBLClausesInformation = styled('div').attrs({
  'data-class': 'SBLClausesInformation',
})`
  ${divFlex}
  flex-direction: column;
  min-height: 200px;
`;

export const SBLClausesInformationTitle = styled(Typography)`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 4px;
`;

export const SBLClausesInformationList = styled('div')`
  ${divFlex}
  flex-direction: column;
`;

export const SBLClausesInformationListItem = styled(Typography)`
  font-size: 14px;
  line-height: 20px;
  margin-top: 8px;
`;

export const SBLClausesInformationTitleWrap = styled.div`
  ${divFlex}
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;
