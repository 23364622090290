import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { R } from 'user-management/repository';
import { UC } from 'user-management/controllers';

import { UC as UCShipment } from 'shipment-operations/controllers';

import { AccountDrawerComponent } from './AccountDrawer.component';

const AccountDrawerContainer: FC = () => {
  const isVisible = useSelector(R.selectors.accountDrawer.getVisibleState);
  const contentType = useSelector(R.selectors.accountDrawer.getContentType);
  const dynamicDrawerTitle = useSelector(R.selectors.accountDrawer.getDynamicDrawerTitle);

  return (
    <AccountDrawerComponent
      isVisible={isVisible}
      activeContent={contentType}
      dynamicDrawerTitle={dynamicDrawerTitle}
      onCloseOrganization={UC.organizationProfile.closeEditProfileOrganizationDrawer}
      onCloseAccountDepartmentEdit={UC.accountDepartments.onCloseAccountDepartmentEditDrawer}
      onCloseOrganizationAddInternal={UC.organizationProfile.closeCustomerAddInternalDrawer}
      closeAddressesAndContactsAddAddressDrawer={UC.organizationProfile.closeAddressesAndContactsAddAddressDrawer}
      closeAddressesAndContactsEditAddressDrawer={UC.organizationProfile.closeAddressesAndContactsEditAddressDrawer}
      closeAACContactEditContactPersonDrawer={UC.contactBook.closeAACContactEditContactPersonDrawer}
      closeAACContactAddContactPersonDrawer={UC.contactBook.closeAACContactAddContactPersonDrawer}
      closeCBAddContactDrawer={UC.contactBook.onCancelContactBookAddContact}
      closeCBByIdAddAddressDrawer={UC.contactBookDrawers.onCancelContactBookByIdAddAddress}
      closeCBByIdEditAddressDrawer={UC.contactBookDrawers.onCancelContactBookByIdEditAddress}
      closeCBByIdEditCompanyDrawer={UC.contactBook.closeCBByIdEditCompanyDrawer}
      closeCBByIdAddContactDrawer={UC.contactBookDrawers.closeCBByIdAddContactDrawer}
      closeCBByIdEditContactDrawer={UC.contactBookDrawers.closeCBByIdEditContactDrawer}
      closeUserManagementEdit={UC.userManagement.closeEditPanel}
      closeBookingAddCompany={UCShipment.bookingWizardDrawer.onCancelBookingAddCompany}
      closeShipmentPartyAddCompany={UCShipment.bookingWizardDrawer.onCancelShipmentPartyAddCompany}
      closeShipmentPartyAddAddress={UCShipment.bookingWizardDrawer.onCancelShipmentPartyAddAddress}
      closeShipmentPartyAddContact={UCShipment.bookingWizardDrawer.onCancelShipmentPartyAddContact}
    />
  );
};

export { AccountDrawerContainer as AccountDrawer };
