import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { InputStyled } from './VolumeInput.styled';

interface VolumeInputComponentProps {
  volume: string;
}

export const VolumeInputComponent: FC<VolumeInputComponentProps> = ({
  volume,
}) => {
  const { t } = useTranslation();

  return (
    <InputStyled
      placeholder={t('Enter Volume')}
      value={volume}
      disabled
    />
  );
};
