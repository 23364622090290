import { Type } from 'class-transformer';
import {
  IsDefined,
  IsString,
  IsBoolean,
  IsNumber,
  ValidateNested,
  IsOptional,
} from 'class-validator';
import moment from 'moment';
import { BaseDTM } from 'proto/BaseDTM';

import { TransportPlanMissMatchFieldNames, TransportPlanMissMatchRelatesToTypeNames } from 'shipment-operations/constants';
import { CountryDTM, StateDTM } from 'shipment-operations/models/dtm';
import { DateDtm } from 'app-wrapper/models/dtm';

export type TTransportPlanMissMatchField = 'ETA' | 'ETD' | 'VESSEL' | 'VOYAGE';
export type TTransportPlanMissMatchTypeField = 'STRING' | 'NUMBER' | 'BOOLEAN' | 'DATE';
export type TTransportPlanMissMatchRelatesToType = 'PLACE_OF_RECEIPT' | 'PLACE_OF_DELIVERY' | 'PORT_LOADING' | 'PORT_DISCHARGE' | 'TRANSIT_PORT' | 'TRANSPORT_DETAILS';

const TIME_FORMAT = 'DD MMM, HH:mm';

export interface IMissMatchLocationDTM {
  country?: {
    code: string;
    name: string;
  };
  state?: {
    code?: string;
    name?: string;
  };
  code: string;
  name: string;
}

export class MissMatchLocationDTM extends BaseDTM<IMissMatchLocationDTM> {
  @IsOptional()
  @Type(() => CountryDTM)
  country?: CountryDTM;

  @IsOptional()
  @Type(() => StateDTM)
  state?: StateDTM;

  @IsDefined()
  @IsString()
  code: string;

  @IsDefined()
  @IsString()
  name: string;

  public locationToString = () => {
    const country = this.country ? this.country.code : '';
    const state = this.state ? this.state.code : '';

    if (country === 'US') {
      return `${this.name}${state ? `, ${state}` : ''}${country ? `, ${country}` : ''}`;
    }

    return `${this.name}${country ? `, ${country}` : ''}`;
  };
}

export interface ITransportPlanMissMatchDTM {
  requested: string | boolean | number | Date;
  confirmed: string | boolean | number | Date;
  valueType: TTransportPlanMissMatchTypeField;
  field: TTransportPlanMissMatchField;
  relatesToType: TTransportPlanMissMatchRelatesToType;
  location?: IMissMatchLocationDTM;
}

export interface ITransportPlanMissMatchToRenderDTM {
  items: Array<{
    requestedValue: string | boolean | number;
    confirmedValue: string | boolean | number | null;
    valueKeyName: string;
  }>;
  groupName: string;
  location?: string;
}

export class TransportPlanMissMatchDTM extends BaseDTM<ITransportPlanMissMatchDTM> {
  @IsDefined()
  @IsString()
  @IsBoolean()
  @IsNumber()
  requested: string | boolean | number;

  @IsDefined()
  @IsString()
  @IsBoolean()
  @IsNumber()
  confirmed: string | boolean | number;

  @IsDefined()
  valueType: TTransportPlanMissMatchTypeField;

  @IsDefined()
  field: TTransportPlanMissMatchField;

  @IsDefined()
  relatesToType: TTransportPlanMissMatchRelatesToType;

  @IsOptional()
  @ValidateNested()
  @Type(() => MissMatchLocationDTM)
  location?: MissMatchLocationDTM;

  static formatValue = (value: string | boolean | number | Date, valueType: TTransportPlanMissMatchTypeField): string => {
    if (valueType === 'DATE' && typeof value === 'string') {
      const dateDTM = DateDtm.fromPlain({
        date: value,
        offset: moment.parseZone(value).utcOffset(),
      });

      return dateDTM.getDateAsMomentWithOffset().format(TIME_FORMAT);
    }

    return String(value);
  }

  static sortMissMatches = (missMatches: ITransportPlanMissMatchToRenderDTM[]): ITransportPlanMissMatchToRenderDTM[] => [
    ...missMatches.filter(({ groupName }) => groupName === TransportPlanMissMatchRelatesToTypeNames.PLACE_OF_RECEIPT),
    ...missMatches.filter(({ groupName }) => groupName === TransportPlanMissMatchRelatesToTypeNames.PORT_LOADING),
    ...missMatches.filter(({ groupName }) => groupName === TransportPlanMissMatchRelatesToTypeNames.TRANSIT_PORT),
    ...missMatches.filter(({ groupName }) => groupName === TransportPlanMissMatchRelatesToTypeNames.PORT_DISCHARGE),
    ...missMatches.filter(({ groupName }) => groupName === TransportPlanMissMatchRelatesToTypeNames.PLACE_OF_DELIVERY),
    ...missMatches.filter(({ groupName }) => groupName === TransportPlanMissMatchRelatesToTypeNames.TRANSPORT_DETAILS),
  ];

  static convertToRenderTypeMissMatches = (missMatches: TransportPlanMissMatchDTM[]): ITransportPlanMissMatchToRenderDTM[] => {
    let convertedMissMatches: ITransportPlanMissMatchToRenderDTM[] = missMatches.map(((missMatch) => ({
      groupName: TransportPlanMissMatchRelatesToTypeNames[missMatch.relatesToType],
      items: [
        {
          requestedValue: this.formatValue(missMatch.requested, missMatch.valueType),
          confirmedValue: missMatch.confirmed ? this.formatValue(missMatch.confirmed, missMatch.valueType) : '',
          valueKeyName: TransportPlanMissMatchFieldNames[missMatch.field],
        },
      ],
      location: missMatch.location ? missMatch.location.locationToString() : '',
    })));

    const transportDetailsMissMatches = convertedMissMatches.filter(({ groupName }) => groupName === TransportPlanMissMatchRelatesToTypeNames.TRANSPORT_DETAILS);
    const portOfLoadingMissMatches = convertedMissMatches.filter(({ groupName }) => groupName === TransportPlanMissMatchRelatesToTypeNames.PORT_LOADING);
    const portOfDischargeMissMatches = convertedMissMatches.filter(({ groupName }) => groupName === TransportPlanMissMatchRelatesToTypeNames.PORT_DISCHARGE);
    const transitPortMissMatches = convertedMissMatches.filter(({ groupName }) => groupName === TransportPlanMissMatchRelatesToTypeNames.TRANSIT_PORT);

    if (transportDetailsMissMatches.length > 1) {
      // if there is 2 TRANSPORT_DETAILS miss matches we need to manually combine their items
      convertedMissMatches = convertedMissMatches.filter(({ groupName }) => groupName !== TransportPlanMissMatchRelatesToTypeNames.TRANSPORT_DETAILS);

      const transportMissMatch1 = transportDetailsMissMatches[0];
      const transportMissMatch2 = transportDetailsMissMatches[1];

      convertedMissMatches.push({
        groupName: transportMissMatch1.groupName,
        items: [
          ...transportMissMatch1.items,
          ...transportMissMatch2.items,
        ],
        location: '',
      });
    }

    if (portOfLoadingMissMatches.length > 1) {
      // if there is 2 PORT_LOADING miss matches we need to manually combine their items
      convertedMissMatches = convertedMissMatches.filter(({ groupName }) => groupName !== TransportPlanMissMatchRelatesToTypeNames.PORT_LOADING);

      const missMatch1 = portOfLoadingMissMatches.find(({ items }) => items.find(({ valueKeyName }) => valueKeyName === TransportPlanMissMatchFieldNames.ETA));
      const missMatch2 = portOfLoadingMissMatches.find(({ items }) => items.find(({ valueKeyName }) => valueKeyName === TransportPlanMissMatchFieldNames.ETD));

      if (missMatch1 && missMatch2) {
        convertedMissMatches.push({
          groupName: missMatch1.groupName,
          items: [
            ...missMatch1.items,
            ...missMatch2.items,
          ],
          location: missMatch1.location,
        });
      }
    }

    if (portOfDischargeMissMatches.length > 1) {
      // if there is 2 PORT_DISCHARGE miss matches we need to manually combine their items
      convertedMissMatches = convertedMissMatches.filter(({ groupName }) => groupName !== TransportPlanMissMatchRelatesToTypeNames.PORT_DISCHARGE);

      const missMatch1 = portOfDischargeMissMatches.find(({ items }) => items.find(({ valueKeyName }) => valueKeyName === TransportPlanMissMatchFieldNames.ETA));
      const missMatch2 = portOfDischargeMissMatches.find(({ items }) => items.find(({ valueKeyName }) => valueKeyName === TransportPlanMissMatchFieldNames.ETD));

      if (missMatch1 && missMatch2) {
        convertedMissMatches.push({
          groupName: missMatch1.groupName,
          items: [
            ...missMatch1.items,
            ...missMatch2.items,
          ],
          location: missMatch1.location,
        });
      }
    }

    if (transitPortMissMatches.length > 1) {
      // if there are more than 1 transit ports miss matches we need to manually combine them
      convertedMissMatches = convertedMissMatches.filter(({ groupName }) => groupName !== TransportPlanMissMatchRelatesToTypeNames.TRANSIT_PORT);

      convertedMissMatches = [
        ...convertedMissMatches,
        ...this.groupTransitPortMissMatches(missMatches.filter(({ relatesToType }) => relatesToType === 'TRANSIT_PORT')),
      ];
    }

    return this.sortMissMatches(convertedMissMatches);
  }

  static groupTransitPortMissMatches = (missMatches: TransportPlanMissMatchDTM[]): ITransportPlanMissMatchToRenderDTM[] => {
    const missMatchesGroupedByLocationMap: Record<string, TransportPlanMissMatchDTM[]> = {};

    missMatches.filter(({ location }) => location).forEach((missMatch) => {
      const unCode = missMatch.location ? missMatch.location.code : '';

      if (!missMatchesGroupedByLocationMap[unCode]) {
        missMatchesGroupedByLocationMap[unCode] = [missMatch];
        return;
      }

      missMatchesGroupedByLocationMap[unCode].push(missMatch);
    });

    const combinedMissMatches: ITransportPlanMissMatchToRenderDTM[] = Object.values(missMatchesGroupedByLocationMap).map((items) => {
      if (items.length === 1) {
        const missMatch = items[0];

        return {
          groupName: TransportPlanMissMatchRelatesToTypeNames[missMatch.relatesToType],
          items: [
            {
              requestedValue: this.formatValue(missMatch.requested, missMatch.valueType),
              confirmedValue: missMatch.confirmed ? this.formatValue(missMatch.confirmed, missMatch.valueType) : '',
              valueKeyName: TransportPlanMissMatchFieldNames[missMatch.field],
            },
          ],
          location: missMatch.location ? missMatch.location.locationToString() : '',
        };
      }

      const missMatch1 = items.find(({ field }) => field === 'ETA');
      const missMatch2 = items.find(({ field }) => field === 'ETD');

      const missMatch: ITransportPlanMissMatchToRenderDTM = {
        groupName: missMatch1 ? TransportPlanMissMatchRelatesToTypeNames[missMatch1.relatesToType] : '',
        items: [],
        location: missMatch1 && missMatch1.location ? missMatch1.location.locationToString() : '',
      };

      if (missMatch1) {
        missMatch.items.push({
          requestedValue: this.formatValue(missMatch1.requested, missMatch1.valueType),
          confirmedValue: missMatch1.confirmed ? this.formatValue(missMatch1.confirmed, missMatch1.valueType) : '',
          valueKeyName: TransportPlanMissMatchFieldNames[missMatch1.field],
        });
      }

      if (missMatch2) {
        missMatch.items.push({
          requestedValue: this.formatValue(missMatch2.requested, missMatch2.valueType),
          confirmedValue: missMatch2.confirmed ? this.formatValue(missMatch2.confirmed, missMatch2.valueType) : '',
          valueKeyName: TransportPlanMissMatchFieldNames[missMatch2.field],
        });
      }

      return missMatch;
    });

    return combinedMissMatches;
  }
}

interface ITransportPlanMissMatchesDTM {
  missMatches: ITransportPlanMissMatchDTM[];
  id: number;
}

export class TransportPlanMissMatchesDTM extends BaseDTM<ITransportPlanMissMatchesDTM> {
  @IsDefined()
  @ValidateNested()
  @Type(() => TransportPlanMissMatchDTM)
  missMatches: TransportPlanMissMatchDTM[];

  @IsDefined()
  @IsNumber()
  id: number;
}
