import React from 'react';
import { IPropsIcon } from 'app-wrapper/interfaces/icon';

export const BellSvg = (props: IPropsIcon) => {
  const { width, height, style } = props;
  return (
    <svg
      className="BellSvg"
      width={width || '12'}
      height={height || '16'}
      viewBox="0 0 12 16"
      color={style?.color || '#99A3B5'}
      style={style || undefined}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M11.4286 12.5692H11V6.49777C11 3.97813 9.1375 1.89598 6.71429 1.54955V0.854911C6.71429 0.460268 6.39464 0.140625 6 0.140625C5.60536 0.140625 5.28571 0.460268 5.28571 0.854911V1.54955C2.8625 1.89598 1 3.97813 1 6.49777V12.5692H0.571429C0.255357 12.5692 0 12.8246 0 13.1406V13.7121C0 13.7906 0.0642857 13.8549 0.142857 13.8549H4C4 14.9585 4.89643 15.8549 6 15.8549C7.10357 15.8549 8 14.9585 8 13.8549H11.8571C11.9357 13.8549 12 13.7906 12 13.7121V13.1406C12 12.8246 11.7446 12.5692 11.4286 12.5692ZM6 14.7121C5.52679 14.7121 5.14286 14.3281 5.14286 13.8549H6.85714C6.85714 14.3281 6.47321 14.7121 6 14.7121ZM2.28571 12.5692V6.49777C2.28571 5.50491 2.67143 4.57277 3.37321 3.87098C4.075 3.1692 5.00714 2.78348 6 2.78348C6.99286 2.78348 7.925 3.1692 8.62679 3.87098C9.32857 4.57277 9.71429 5.50491 9.71429 6.49777V12.5692H2.28571Z" fill="currentColor" />
    </svg>
  );
};
