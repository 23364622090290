import styled from 'styled-components';

export const EmailConfirmation = styled.div`
  width: 518px;
  height: 180px;
  background: rgba(255, 255, 255, 1);
  position: absolute;
`;

export const EmailConfirmationTitle = styled.div`
  width: 343px;
  color: #202c3c;
  position: absolute;
  top: 40px;
  left: 87px;
  line-height: 24px;
  text-align: center;
`;

export const EmailConfirmationErrorText = styled.p`
  color: #ff0000;
`;
