import React, { FC } from 'react';
import { Outlet } from 'react-router-dom';

import {
  NewOrganizationWizardWrapperScaffold,
  NewOrganizationWizardWrapperHeader,
  NewOrganizationWizardWrapperAside,
} from './components';

export const NewOrganizationWizardWrapper: FC = () => (
  <NewOrganizationWizardWrapperScaffold
    aside={<NewOrganizationWizardWrapperAside />}
    content={<Outlet />}
    header={<NewOrganizationWizardWrapperHeader />}
  />
);
