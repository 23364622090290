import { action, makeObservable } from 'mobx';
import { BaseMobxStore } from 'proto/BaseMobxStore';
import { ReactElement } from 'react';
import { v4 as uuidv4 } from 'uuid';

interface DrawerStoreState {
  currentDrawers: {
    id: string
    reactElement: ReactElement
  }[]
}

export const initialState: DrawerStoreState = {
  currentDrawers: [],
};

export class DrawerStore<T extends DrawerStoreState = DrawerStoreState> extends BaseMobxStore<DrawerStoreState> {
  initialState = { ...initialState }

  constructor(_initialState?: T) {
    super();
    makeObservable(this);
    this.init(_initialState || this.initialState);
  }

  @action
  addDrawer(reactElement: ReactElement) {
    const id = uuidv4();
    this.state.currentDrawers.push({ id, reactElement });
    return id;
  }

  @action
  closeDrawer() {
    this.state.currentDrawers.pop();
  }

  @action
  closeDrawerById(id: string) {
    const index = this.state.currentDrawers.findIndex((drawer) => drawer.id === id);
    if (index !== -1) {
      this.state.currentDrawers.splice(index, 1);
    }
  }
}
