import styled from 'styled-components';

import {
  SubPageContent,
  TitleText,
  BodyTextRegular,
  BodyTextMedium,
} from 'app-wrapper/view/guideline';

export const Content = styled(SubPageContent)``;

export const Title = styled.span`
  margin-bottom: 4px;
  ${TitleText}
`;

export const Text = styled.span`
  ${BodyTextRegular}
`;

export const TextBold = styled.span`
  ${BodyTextMedium}
`;
