import React from 'react';
import { useSelector } from 'react-redux';

import { UC } from 'shipment-operations/controllers';
import { R } from 'shipment-operations/repository';

import { ChangeCompanyDrawerComponent } from './ChangeCompanyDrawer.component';

const ChangeCompanyDrawer = () => {
  const isOpened = useSelector(R.selectors.shippingParties.getIsSelectCompanyDrawerOpened);
  const isLoading = useSelector(R.selectors.shippingParties.getIsSelectCompanyDrawerLoading);
  const isInContactMode = useSelector(R.selectors.shippingParties.getIsSelectCompanyDrawerInContactMode);
  const isInAddressMode = useSelector(R.selectors.shippingParties.getIsSelectCompanyDrawerInAddressMode);
  const isAgreeNra = useSelector(R.selectors.shippingParties.getSelectedShippingParty)?.isAgreeNra;
  const isAgreeNraShow = useSelector(R.selectors.shippingParties.getSelectedShippingParty)?.isAgreeNraShow;
  const isAgreeNraError = useSelector(R.selectors.shippingParties.getSelectedShippingParty)?.isAgreeNraError;

  return (
    <ChangeCompanyDrawerComponent
      isVisible={isOpened}
      onClose={UC.shippingParties.closeSelectCompanyDrawer}
      isLoading={isLoading}
      isAgreeNra={isAgreeNra}
      isAgreeNraError={isAgreeNraError}
      isAgreeNraShow={isAgreeNraShow}
      onChangeAgreeNra={UC.shippingParties.setIsTermsAndConditionsChecked}
      isInContactMode={isInContactMode}
      isInAddressMode={isInAddressMode}
    />
  );
};

const ChangeCompanyDrawerCached = React.memo(ChangeCompanyDrawer);

export { ChangeCompanyDrawerCached as ChangeCompanyDrawer };
