import { BaseUseCase } from 'app-wrapper/usecases/Base.useCase';
import {
  ContainerCargoDTM, ContainerDTM, ShipmentPreviewDTM,
} from 'shipment-operations/models/dtm';
import { R } from 'shipment-operations/repository';

export class ShipmentContainersUseCase extends BaseUseCase {
  updateChangedCargoList = () => {
    const selectedContainer = R.selectors.shipmentContainers.getSelectedContainer(this.store.getState());
    const cargoList = R.selectors.shipmentContainers.getCargoList(this.store.getState());

    if (!selectedContainer) {
      console.error('ShipmentContainersController.selectContainer has got no selected container');

      return;
    }

    if (!cargoList.length) {
      console.error('ShipmentContainersController.selectContainer has got no cargo list');

      return;
    }

    selectedContainer.cargoItems.forEach((containerCargoItem) => {
      const relevantCargo = cargoList.find((item) => item.baseId === containerCargoItem.cargoId);

      if (relevantCargo) {
        this.dispatch(R.actions.shipmentContainers.removeCargoFromChangedList(relevantCargo));
      } else {
        // no need to do something in this case
        return;
      }

      const newContainerCargo = ContainerCargoDTM.fromPlain({
        ...relevantCargo,
        id: containerCargoItem.id,
        packagesNumberValue: containerCargoItem.packagesNumber,
        weightValue: containerCargoItem.weight,
        volumeValue: containerCargoItem.volume,
      });

      this.dispatch(R.actions.shipmentContainers.addCargoToChangedList(newContainerCargo));
    });
  }

  updateContainersList = async (shipmentId: string) => {
    let containersList: ContainerDTM[] = [];

    try {
      containersList = await R.services.shipmentContainers.getContainersList(shipmentId);
    } catch (e) {
      this.dispatch(R.actions.shipmentContainers.setLoading(false));

      throw e;
    }

    this.dispatch(R.actions.shipmentContainers.setList(containersList));
  }

  updateShipmentShort = async (shipmentId: string) => {
    let shipment: ShipmentPreviewDTM | null = null;
    shipment = await R.services.shipment.getShipmentShortById(+shipmentId);
    if (shipment) {
      this.dispatch(R.actions.shipment.setShipment(shipment));
    }
  }

  updateCargoList = async (shipmentId: string) => {
    const cargoList = await R.services.cargo.getCargoBases(+shipmentId);

    this.dispatch(R.actions.shipmentContainers.setCargoListContainer(cargoList));
  }

  updateContainerNumbers = async (shipmentId: string) => {
    const containerNumbers = await R.services.shipmentTracker.getContainersNew(shipmentId);

    this.dispatch(R.actions.shipmentContainers.setContainerNumberList(containerNumbers));
  }

  updateDeclarations = async (shipmentId: string) => {
    const containers = R.selectors.shipmentContainers.getContainerList(this.store.getState());

    const declarations = await R.services.shipmentDeclaration.getDeclarationsForContainers(Number(shipmentId), containers.map(({ id }) => +id));

    this.dispatch(R.actions.shipmentContainers.setIMODeclarations(declarations));
  }
}
