import { createSelector } from 'reselect';

import { RootState } from 'app-wrapper/store';

const localState = (state: RootState) => state.shipmentDocumentsSeaworthyCertificates;

const getIsLoading = createSelector(
  localState,
  (state) => state.isLoading,
);

const getIsUpdateLoading = createSelector(
  localState,
  (state) => state.isUpdateLoading,
);

const getHasSOC = createSelector(
  localState,
  (state) => state.hasSOC,
);

const getContainerById = (containerId: string) => createSelector(
  localState,
  (state) => state.containers.find(({ id }) => id === containerId),
);

const getContainers = createSelector(
  localState,
  (state) => state.containers,
);

const getHasErrors = createSelector(
  localState,
  (state) => !!state.containersIdsWithErrors.length,
);

const getHasErrorById = (containerId: string) => createSelector(
  localState,
  (state) => !!state.containersIdsWithErrors.find((id) => id === containerId),
);

const getInitialContainers = createSelector(
  localState,
  (state) => state.initialContainers,
);

const getHasLoadingHappened = createSelector(
  localState,
  (state) => state.loadingHappened,
);

export const shipmentDocumentsSeaworthyCertificatesSelectors = {
  getIsLoading,
  getIsUpdateLoading,
  getHasSOC,
  getContainerById,
  getContainers,
  getHasErrors,
  getHasErrorById,
  getInitialContainers,
  getHasLoadingHappened,
};
