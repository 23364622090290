import styled, { css } from 'styled-components';

export const textColorFirst = css`
  color: ${({ theme }) => theme.colors.characterBrandingTitle85};
`;

export const divFlex = css`
  display: flex;
  position: relative;
  ${textColorFirst}
`;

export const borderAfter = css`
  position: absolute;
  content: '';
  border: 1px solid ${({ theme }) => theme.themesColors.neutralBranding5};
  left: calc(50% + 0.5px);
  transform: translateX(-50%);
  z-index: 2;
  height: 101%;
`;

// Content

export const RFQItemDetailBreakdownWrapper = styled.div`
  ${divFlex}
  width: 100%;
  flex-direction: column;
`;

export const RFQItemDetailBreakdownContent = styled.div`
  ${divFlex}
  width: 100%;
  margin-top: 12px;
`;

///
export const RFQItemDetailDivRoutingContentOriginItemIcon = styled
  .div
  .attrs(
    (props: {
      isLast: boolean,
    }) => ({
      isLast: props.isLast,
    }),
  )`
  ${divFlex}
  margin-right: 10px;
  margin-left: 1px;
  svg {
    z-index: 3;
    margin-top: 2px;
  }
  &:after {
    ${borderAfter}
    top: 13px;
    display: ${(props) => (props.isLast ? 'none' : 'block')};
  }
`;

// First
