import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'antd/es/skeleton';

import { DateDtm } from 'app-wrapper/models/dtm';
import { CustomModal } from 'shipment-operations/view/components';
import { ShipmentPreviewDTM, ContainerDTM } from 'shipment-operations/models/dtm';
import { Footer, MarkingContainers } from 'app-wrapper/view/drawers/components';

import { VGMInformation } from './components';
import { Content } from './VGMTaskDrawer.styled';

interface BookingDrawerComponentProps {
  init: (shipmentId: string) => Promise<void>
  shipmentId: string
  onClose: () => void
  isLoading: boolean
  isAbleToAccept: boolean
  onAccept: () => void
  shipment?: ShipmentPreviewDTM
  containers: ContainerDTM[]
  markedContainers: string[]
  markAllContainers: () => void
  markContainer: (id: string) => void
  dueDate: DateDtm | null
}

export const VGMTaskDrawerComponent: FC<BookingDrawerComponentProps> = ({
  init,
  shipmentId,
  onClose,
  onAccept,
  isLoading,
  isAbleToAccept,
  shipment,
  containers,
  markedContainers,
  markAllContainers,
  markContainer,
  dueDate,
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    init(shipmentId);
  }, []);

  return (
    <CustomModal
      title={t('Verify VGM Submission')}
      onClose={onClose}
      contentStyles={{
        left: 'unset',
        width: '1137px',
      }}
      childrenWrapperStyles={{
        position: 'relative',
        height: 'calc(100vh - 56px)',
        overflow: 'hidden',
      }}
    >
      {isLoading ? (
        <Skeleton />
      ) : (
        <Content>
          <VGMInformation dueDate={dueDate} />

          <MarkingContainers
            containers={containers}
            shipment={shipment}
            markAll={markAllContainers}
            markContainer={markContainer}
            markedContainers={markedContainers}
            markedLabel={t('Mark as Provided')}
          />
        </Content>
      )}

      <Footer
        onAccept={onAccept}
        closeDrawer={onClose}
        isLoading={isLoading}
        isAbleToAccept={isAbleToAccept}
        hideCancel
        okText={t('Complete Task')}
      />
    </CustomModal>
  );
};
