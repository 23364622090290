import React from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';
import { UC } from 'shipment-operations/controllers';
import { SecondStepComponent } from 'shipment-operations/view/drawers/MaerskChanges/components/SecondStep/SecondStep.component';

const SecondStepContainer = () => {
  const inttraNumber = useSelector(R.selectors.shipmentChanges.getInttraNumber);
  const isSearchLoading = useSelector(R.selectors.shipmentChanges.getIsSearchLoading);
  const searchError = useSelector(R.selectors.shipmentChanges.getSearchError);
  const searchErrorMsg = useSelector(R.selectors.shipmentChanges.getSearchErrorMessage);
  const shipmentId = useSelector(R.selectors.overview.getShipmentId);

  return (
    <SecondStepComponent
      inttraNumber={inttraNumber}
      onChangeInttraNumber={UC.shipmentChanges.onChangeInttraNumber}
      onSearchRef={UC.shipmentChanges.onSearchRef}
      isSearchLoading={isSearchLoading}
      searchError={searchError}
      searchErrorMsg={searchErrorMsg}
      savedShipmentId={shipmentId}
    />
  );
};

export { SecondStepContainer as SecondStep };
