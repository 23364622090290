import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'antd/es/skeleton';

import {
  Section,
  Row,
  SectionTitle,
  SelectLabel,
  SelectWrapper,
} from './ShipperReference.styled';
import { ShipperReferenceInput } from './components';

interface ContainersDetailsComponentProps {
  isPending: boolean;
}

export const ShipperReferenceComponent: FC<ContainersDetailsComponentProps> = ({
  isPending,
}) => {
  const { t } = useTranslation();

  return isPending ? (
    <Skeleton />
  ) : (
    <Section>
      <Row>
        <SectionTitle>
          {t('References')}
        </SectionTitle>

        <SelectWrapper>
          <SelectLabel>
            {t('Shipment Reference')}
          </SelectLabel>

          <ShipperReferenceInput />
        </SelectWrapper>
      </Row>
    </Section>
  );
};
