import Badge from 'antd/es/badge';
import React, { FC } from 'react';

import { EUserStatus, UserStatusNames, getUserAntdStatus } from 'user-management/constants';

import {
  UserStatusWrapper,
} from './UserStatus.styled';

interface IUserStatusProps {
  enabled?: boolean
}

export const UserStatus: FC<IUserStatusProps> = ({ enabled }) => {
  let status = EUserStatus.active;
  if (enabled === null) {
    status = EUserStatus.invited;
  }

  if (enabled === false) {
    status = EUserStatus.inactive;
  }

  return (
    <UserStatusWrapper isActive={status === EUserStatus.active}>
      <Badge status={getUserAntdStatus(status)} />
      {UserStatusNames[status]}
    </UserStatusWrapper>
  );
};
