import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { R } from 'shipment-operations/repository';
import { UC } from 'shipment-operations/controllers';
import { useAuthToken } from 'app-wrapper/hooks';
import { ShipmentDrawerContent, DocumentType } from 'shipment-operations/constants';

import { DocumentUploadComponent } from './DocumentUpload.component';

const DocumentUploadContainer = () => {
  const { shipmentId = '0' } = useParams();
  const authToken = useAuthToken();
  const document = useSelector(R.selectors.billOfLadingCommon.getMBLFileFormState);
  const drawerContentType = useSelector(R.selectors.shipmentDrawer.getContentType);

  const documentType = useMemo(() => (drawerContentType === ShipmentDrawerContent.SHIPMENT_SI_DRAFT_DOCUMENT_UPLOAD ? DocumentType.DMBL : DocumentType.FMBL), [drawerContentType]);

  return (
    <DocumentUploadComponent
      authToken={authToken}
      shipmentId={shipmentId}
      containerDocument={document}
      setContainerDocument={UC.billOfLading.setMBLFormStateDocument}
      downloadDocument={UC.billOfLading.downloadMBLFormStateDocument}
      documentType={documentType}
    />
  );
};

export { DocumentUploadContainer as DocumentUpload };
