import React, {
  useMemo, FC,
} from 'react';
import { useTranslation } from 'react-i18next';
import filter from 'lodash/fp/filter';
import isEmpty from 'lodash/fp/isEmpty';

import { HistoryTable } from 'shipment-operations/view/components';
import { ShipmentChargesPermissionsDtm, ChargeViewDtm } from 'shipment-operations/models/dtm';
import { TableHeader, TableWithoutHeader } from 'shipment-operations/view/pages/ShipmentCharges/components/ContainersTable/ContainersTable.styled';
import { Title } from 'shipment-operations/view/pages/ShipmentCharges/ShipmentCharges.styled';
import { CUSTOMER_VIEW } from 'shipment-operations/constants';

import { useToggleCollapse } from 'shipment-operations/view/hooks';
import { StyledSection } from './CustomTable.styled';
import { useTable } from './useTable';

interface ICustomTableProps {
  data: ChargeViewDtm[]
  innerTable?: boolean
  permissions: ShipmentChargesPermissionsDtm
  onDownloadDocument: (shipmentId: string, id: number, name: string) => void
  mode: string
  isPercentage: boolean
  subTitle?: string
}

const CustomTable: FC<ICustomTableProps> = ({
  data, innerTable, permissions, onDownloadDocument, mode, subTitle, isPercentage,
}) => {
  const { t } = useTranslation();
  const { openedKeys, onToggleCollapse } = useToggleCollapse();

  const originData = useMemo(() => filter((item) => item.designation === 'ORIGIN', data), [data]);
  const freightData = useMemo(() => filter((item) => item.designation === 'FREIGHT', data), [data]);
  const destinationData = useMemo(() => filter((item) => item.designation === 'DESTINATION', data), [data]);

  const { columns } = useTable(permissions, onToggleCollapse, openedKeys, mode, isPercentage, subTitle);

  const expandRender = (record: ChargeViewDtm | {}) => (
    <HistoryTable data={record} onDownloadDocument={onDownloadDocument} shortVersion />
  );

  return (
    <>
      {mode === CUSTOMER_VIEW && subTitle && (
        <Title>{subTitle}</Title>
      )}
      <TableHeader
        className={innerTable ? 'inner-table' : ''}
        expandable={{
          expandIconColumnIndex: -1,
        }}
        shortVersion={mode !== CUSTOMER_VIEW}
      >
        {columns}
      </TableHeader>
      {!isEmpty(originData) && (
        <>
          <StyledSection inner={innerTable}>{t('Origin')}</StyledSection>
          <TableWithoutHeader
            dataSource={originData}
            pagination={false}
            className={innerTable ? 'inner-table' : ''}
            rowClassName="customTableRow"
            expandedRowClassName={() => 'customExpandRow'}
            expandable={{
              expandedRowRender: expandRender,
              expandedRowKeys: openedKeys,
              expandIconColumnIndex: -1,
            }}
          >
            {columns}
          </TableWithoutHeader>
        </>
      )}
      {!isEmpty(freightData) && (
        <>
          <StyledSection inner={innerTable}>{t('Freight')}</StyledSection>
          <TableWithoutHeader
            dataSource={freightData}
            pagination={false}
            className={innerTable ? 'inner-table' : ''}
            expandedRowClassName={() => 'customExpandRow'}
            expandable={{
              expandedRowRender: expandRender,
              expandedRowKeys: openedKeys,
              expandIconColumnIndex: -1,
            }}
          >
            {columns}
          </TableWithoutHeader>
        </>
      )}
      {!isEmpty(destinationData) && (
        <>
          <StyledSection inner={innerTable}>{t('Destination')}</StyledSection>
          <TableWithoutHeader
            dataSource={destinationData}
            pagination={false}
            className={innerTable ? 'inner-table' : ''}
            expandedRowClassName={() => 'customExpandRow'}
            expandable={{
              expandedRowRender: expandRender,
              expandedRowKeys: openedKeys,
              expandIconColumnIndex: -1,
            }}
          >
            {columns}
          </TableWithoutHeader>
        </>
      )}
    </>
  );
};

export { CustomTable };
