import React from 'react';

export const IconExportDeclaration = () => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="2" y="1.66602" width="36" height="36" rx="4" fill="#E4EBF7" />
    <path d="M5 22.5832C5 20.7146 6.51482 19.1998 8.38345 19.1998V19.1998V30.8002V30.8002C6.51482 30.8002 5 29.2853 5 27.4167V22.5832Z" fill="#FAAD14" />
    <path d="M11.7653 34.1841C9.89666 34.1841 8.38184 32.6693 8.38184 30.8007V30.8007L19.9822 30.8007V30.8007C19.9822 32.6693 18.4674 34.1841 16.5988 34.1841L11.7653 34.1841Z" fill="#FAAD14" />
    <path fillRule="evenodd" clipRule="evenodd" d="M30.0914 16.783H22.3996V16.8961C22.2453 16.9624 22.1024 17.0622 21.9822 17.1949L13.4935 26.5651C13.0455 27.0596 13.0832 27.8238 13.5778 28.2719C14.0723 28.7199 14.8365 28.6822 15.2846 28.1876L22.3996 20.3338V25.2734L30.0914 16.783ZM22.3996 33.7002V30.8005L30.7324 30.8005C31.3998 30.8005 31.9408 30.2595 31.9408 29.5921C31.9408 28.9247 31.3998 28.3837 30.7324 28.3837H22.8429L33.071 17.0935C33.6294 17.4483 34 18.0724 34 18.783V33.7002H22.3996Z" fill="#597EF7" />
    <path fillRule="evenodd" clipRule="evenodd" d="M21.4336 5.66602H32.5506V10.9829C32.5506 13.3854 30.603 15.333 28.2005 15.333C25.798 15.333 23.8503 13.3854 23.8503 10.9829V10.0159H32.5502V8.08254H23.8501L21.4336 5.66602ZM23.8503 10.0163L22.3254 10.983H23.8503V10.0163Z" fill="#FAAD14" />
  </svg>
);

