import * as yup from 'yup';
import i18n from 'i18next';

yup.addMethod(yup.string, 'equalLength', function equalLength(min, isEmpty) {
  return this.test(
    'equalLength',
    `${i18n.t('Validations.string.equalLength')} ${min}`,
    (value, context) => {
      const form = context?.options.context?.form.current;
      const firstDateState = form.getFieldState(context.path);
      if (isEmpty && (value === '' || value === undefined)) {
        return true;
      }
      return !firstDateState.isTouched || (!!value?.length && value.length === min);
    },
  );
});

declare module 'yup' {
  interface StringSchema {
    equalLength(min?: number, isEmpty?: boolean): StringSchema;
  }
}
