import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { DataNode } from 'antd/es/tree';

import { R as UserManagementR } from 'user-management/repository';

import { R } from 'app-wrapper/repository';
import {
  AppFiltersCheckboxGroupDTM,
  NotificationTaskContentDTM,
  NotificationTaskContentViewedDTM,
  NotificationTaskDTM,
  CommandCenterGetCommandCenterGetNotificationsDTM,
  CommandCenterGetTasksDTM,
  IAppFiltersCheckboxGroupDTM,
  IAppFiltersCheckboxTreeDTM,
  ICommandCenterGetTasksDTM,
  NotificationTaskFilterDatesDTM,
  NotificationTaskFilterReceivedDatesDTM,
  NotificationViewedFiltersDTM,
} from 'app-wrapper/models/dtm';
import { ENotificationCriticality, ENotificationDomain, REQUEST_STATUS } from 'app-wrapper/constants';
import { apiWorker } from 'app-wrapper/repository/utilsServices';

import { BaseController, controller } from 'proto/BaseController';
import i18n from 'app-wrapper/i18n/i18n';
import { ETasksType } from 'shipment-operations/constants';
import { OrganizationDTM } from 'user-management/models/dtm';
import { ShipmentAllStatsDTM } from 'shipment-operations/models/dtm';

interface IOnLoadTasksFiltersParams {
  params?: CommandCenterGetTasksDTM
}

const notificationSize = '20';

const TasksBlockSize = '100';
const TasksScrollBlockSize = '20';

const defaultParams = CommandCenterGetTasksDTM.fromPlain({
  page: `${0}`,
  size: TasksBlockSize,
  sort: 'dueDate,asc',
  query: 'TODO',
});

const getTasks = async (paramsTasks?: CommandCenterGetTasksDTM) => new Promise<NotificationTaskDTM | null>((resolve, reject) => {
  try {
    const res = R.services.CommandCenter.getTasks({
      ...defaultParams,
      ...paramsTasks,
    });

    resolve(res);
  } catch (e) {
    reject(e);
  }
});

const getTasksStats = async (paramsTasks?: CommandCenterGetTasksDTM) => new Promise<ShipmentAllStatsDTM | undefined>((resolve, reject) => {
  try {
    const res = R.services.CommandCenter.getOrganizationStats({
      ...defaultParams,
      ...paramsTasks,
    });

    resolve(res);
  } catch (e) {
    reject(e);
  }
});

const newGroupFilterPriority = [
  AppFiltersCheckboxGroupDTM.fromPlain({
    value: ENotificationCriticality.HIGH,
    name: i18n.t('Important'),
    checked: false,
  }),
  AppFiltersCheckboxGroupDTM.fromPlain({
    value: `${ENotificationCriticality.MEDIUM},${ENotificationCriticality.LOW}`,
    name: i18n.t('Regular'),
    checked: false,
  }),
];

const newGroupFilterModule = [
  AppFiltersCheckboxGroupDTM.fromPlain({
    value: ENotificationDomain.BILLING,
    name: i18n.t('Billing'),
    customId: uuidv4(),
    checked: false,
  }),
  AppFiltersCheckboxGroupDTM.fromPlain({
    value: ENotificationDomain.BOOKING,
    name: i18n.t('Booking'),
    customId: uuidv4(),
    checked: false,
  }),
  AppFiltersCheckboxGroupDTM.fromPlain({
    value: ENotificationDomain.DOCUMENTATION,
    name: i18n.t('Documentation'),
    customId: uuidv4(),
    checked: false,
  }),
  AppFiltersCheckboxGroupDTM.fromPlain({
    value: ENotificationDomain.PLAN_TRACK,
    name: i18n.t('Plan Track'),
    customId: uuidv4(),
    checked: false,
  }),
  AppFiltersCheckboxGroupDTM.fromPlain({
    value: ENotificationDomain.USER_MANAGEMENT,
    name: i18n.t('User Management'),
    customId: uuidv4(),
    checked: false,
  }),
  AppFiltersCheckboxGroupDTM.fromPlain({
    value: ENotificationDomain.QUOTES_AND_RATES,
    name: i18n.t('Quotes and Rates'),
    customId: uuidv4(),
    checked: false,
  }),
];

@controller
export class CommandCenterController extends BaseController {
  public onResetTaskPage = async () => {
    this.dispatch(R.actions.commandCenter.setIsTaskFirstRequest(false));
    this.dispatch(R.actions.commandCenter.setIsNotificationFirstRequest(false));
  }

  public resetTasksState = async () => {
    this.dispatch(R.actions.commandCenter.setTotalElements(0));
    this.dispatch(R.actions.commandCenter.setTotalElementsOverdue(0));
    this.dispatch(R.actions.commandCenter.setNotificationTotalElements(0));

    this.dispatch(R.actions.commandCenter.setTotalElements(0));
    this.dispatch(R.actions.commandCenter.setContent([]));

    this.dispatch(R.actions.commandCenter.setContentOverdue([]));
    this.dispatch(R.actions.commandCenter.setContentToday([]));
    this.dispatch(R.actions.commandCenter.setContentThisWeek([]));
    this.dispatch(R.actions.commandCenter.setContentLater([]));
    this.dispatch(R.actions.commandCenter.setTotalElementsOverdue(0));
    this.dispatch(R.actions.commandCenter.setTotalElementsToday(0));
    this.dispatch(R.actions.commandCenter.setTotalElementsTisWeek(0));
    this.dispatch(R.actions.commandCenter.setTotalElementsLater(0));
    this.dispatch(R.actions.commandCenter.setInfoCountAllTasks(0));
    this.dispatch(R.actions.commandCenter.setInfoCountOverdueTasks(0));
    this.dispatch(R.actions.commandCenter.setInfoCountDueTasks(0));
    await this.dispatch(R.actions.commandCenter.setInfoCountPlannedTasks(0));
  }

  public onEndTaskPage = async () => {
    apiWorker.abortAllRequests();
  }

  public onLoadStatisticsTasks = async (shipmentId?: string) => {
    const { currentState: { criticality, filters } } = this.store.getState().commandCenter;

    const { organization } = this.store.getState().userOrganizationData;
    let organizationReq: OrganizationDTM | undefined;

    if (!organization) {
      organizationReq = await UserManagementR.services.organization.getCurrentOrganization();
    }

    const organizationId = organization?.id || organizationReq?.id;
    const assigneeOrganizationIds = organizationId ? [organizationId] : undefined;
    const { email } = this.store.getState().auth;
    const assigneeOrganizationUsers = email ? [email] : undefined;

    const params = CommandCenterGetTasksDTM.fromPlain({
      page: `${0}`,
      size: '1',
      query: 'TODO',
      criticality,
      shipmentId,
      assigneeUserEmails: !filters?.isOnlyUnread ? assigneeOrganizationUsers : undefined,
      assigneeOrganizationIds: filters?.isOnlyUnread ? assigneeOrganizationIds : undefined,
    });

    const [resTasks] = await Promise.all<ShipmentAllStatsDTM | undefined>([
      getTasksStats(CommandCenterGetTasksDTM.fromPlain({
        ...params,
      })),
    ]);

    const tasksOverdue = resTasks?.taskStats.overdue;
    const tasksDue = resTasks?.taskStats.expiring;
    const tasksPlanned = resTasks?.taskStats.todo;
    const tasksAllCounts = (resTasks?.taskStats.overdue || 0) + (resTasks?.taskStats.expiring || 0) + (resTasks?.taskStats.todo || 0);

    this.dispatch(R.actions.commandCenter.setInfoCountAllTasks(tasksAllCounts || 0));
    this.dispatch(R.actions.commandCenter.setInfoCountOverdueTasks(tasksOverdue || 0));
    this.dispatch(R.actions.commandCenter.setInfoCountDueTasks(tasksDue || 0));
    this.dispatch(R.actions.commandCenter.setInfoCountPlannedTasks(tasksPlanned || 0));

    if (criticality?.length === 1 && criticality.includes(ENotificationCriticality.HIGH)) {
      this.dispatch(R.actions.commandCenter.setInfoCountAllTasks(resTasks?.taskStats.alerts || 0));
      this.dispatch(R.actions.commandCenter.setInfoCountOverdueTasks(resTasks?.taskStats.highOverdue || 0));
      this.dispatch(R.actions.commandCenter.setInfoCountDueTasks(resTasks?.taskStats.highExpiring || 0));
      this.dispatch(R.actions.commandCenter.setInfoCountPlannedTasks(resTasks?.taskStats.highTodo || 0));
    }
  }

  public onLoadTasksFilters = async (propsData?: IOnLoadTasksFiltersParams) => {
    const {
      params,
    } = propsData || {};
    const { currentState } = this.store.getState().commandCenter;

    this.dispatch(R.actions.commandCenter.setTasksFilters(NotificationViewedFiltersDTM.fromPlain({
      ...currentState?.filters,
      isRequest: true,
    })));

    const newAssignee: IAppFiltersCheckboxGroupDTM[] = [];
    const newAssigneeOrganization: IAppFiltersCheckboxGroupDTM[] = [];
    const newAssigneeOrganizationDefault: IAppFiltersCheckboxGroupDTM[] = [];
    const newObject: IAppFiltersCheckboxTreeDTM = {};
    const newObjectTreeData: DataNode[] = [];
    const newObjectTreeDataOrganization: DataNode[] = [];
    const newObjectTreeDataRates: DataNode[] = [];
    const newObjectTreeDataShipments: DataNode[] = [];
    const objectIds: string[] = [];

    const groupObject: IAppFiltersCheckboxGroupDTM[] = [];

    const response = await R.services.CommandCenter.getTasksFilters(params);

    if (response) {
      newAssignee.push({
        checked: false,
        customId: uuidv4(),
        name: 'Unassigned',
        value: 'false',
        value2: 'false',
      });
      response?.assignee?.users?.forEach((item) => {
        newAssignee.push({
          checked: false,
          value: item?.email || '',
          customId: uuidv4(),
          name: item?.getFullName(),
          value2: item?.sub,
        });
      });
      response?.assignee?.organizations?.forEach((item) => {
        if (params?.currentOrganizationId && params?.currentOrganizationId === item.id) {
          return;
        }

        newAssigneeOrganization.push({
          checked: false,
          value: `${item?.id || ''}`,
          customId: uuidv4(),
          name: item?.name,
          value2: `${item?.id || ''}`,
        });
        newAssigneeOrganizationDefault.push({
          checked: false,
          value: `${item?.id || ''}`,
          customId: uuidv4(),
          name: item?.name,
          value2: `${item?.id || ''}`,
        });
      });

      response?.objectReference?.organizations?.forEach((item) => {
        const id = uuidv4();
        objectIds.push(id);

        groupObject.push({
          checked: false,
          name: `${item.name}`,
          id: `${item.id}`,
          customId: uuidv4(),
          value: `${id}`,
          value2: 'Organization',
        });

        newObjectTreeDataOrganization.push({
          title: `${item.name}`,
          key: id,
          disabled: false,
        });
      });

      response?.objectReference?.rateRequestIds?.forEach((item) => {
        const id = uuidv4();
        objectIds.push(id);

        groupObject.push({
          checked: false,
          name: `${item}`,
          id: `${item}`,
          customId: uuidv4(),
          value: `${id}`,
          value2: 'Request',
        });

        newObjectTreeDataRates.push({
          title: `${i18n.t('Request')} #${item}`,
          key: id,
          disabled: false,
        });
      });

      response?.objectReference?.shipmentIds?.forEach((item) => {
        const id = uuidv4();
        objectIds.push(id);

        groupObject.push({
          checked: false,
          name: `${item}`,
          id: `${item}`,
          customId: uuidv4(),
          value: `${id}`,
          value2: 'Shipment',
        });

        newObjectTreeDataShipments.push({
          title: `${i18n.t('Shipment')} #${item}`,
          key: id,
          disabled: false,
        });
      });

      if (newObjectTreeDataOrganization.length) {
        newObjectTreeData.push({
          title: i18n.t('Organizations'),
          key: uuidv4(),
          children: newObjectTreeDataOrganization,
        });
      }

      if (newObjectTreeDataRates.length) {
        newObjectTreeData.push({
          title: i18n.t('Requests'),
          key: uuidv4(),
          children: newObjectTreeDataRates,
        });
      }

      if (newObjectTreeDataShipments.length) {
        newObjectTreeData.push({
          title: i18n.t('Shipments'),
          key: uuidv4(),
          children: newObjectTreeDataShipments,
        });
      }
    }

    newObject.treeData = newObjectTreeData;
    newObject.treeDataIds = objectIds;
    newObject.group = groupObject;
    newObject.groupDefault = groupObject;
    newObject.isUpdate = false;
    newObject.disableReset = true;

    const { currentState: { filters } } = this.store.getState().commandCenter;
    this.dispatch(R.actions.commandCenter.setTasksFilters(NotificationViewedFiltersDTM.fromPlain({
      ...filters,
      isRequest: false,
      filterAssignee: {
        ...filters?.filterAssignee,
        groupDefault: newAssignee,
        group: newAssignee,
      },
      filterAssigneeOrganization: {
        ...filters?.filterAssigneeOrganization,
        groupDefault: newAssigneeOrganizationDefault,
        group: newAssigneeOrganization,
      },
      filterTreeDataObject: newObject,
    })));

    this.compareFilterTasksAssigneeFilter();
    this.compareFilterTasksAssigneeOrganizationFilter();
  }

  public removeTaskFromTheStore = (shipmentId: string, taskType: ETasksType, taskId?: string) => {
    const {
      content,
      contentOverdue = [],
      contentLater = [],
      contentThisWeek = [],
      contentToday = [],
    } = R.selectors.commandCenter.getCommandCenter(this.store.getState()).currentState;
    const tempContent = [...content];
    const tempContentOverdue = [...contentOverdue];
    const tempContentLater = [...contentLater];
    const tempContentThisWeek = [...contentThisWeek];
    const tempContentToday = [...contentToday];
    const task = content.find(({ metadata, id }) => metadata && metadata?.shipmentId === shipmentId && (taskId ? taskId === id : metadata?.taskType === taskType));

    const contentIndex = content.findIndex(({ id }) => id === task?.id);
    const contentOverdueIndex = contentOverdue.findIndex(({ id }) => id === task?.id);
    const contentLaterIndex = contentLater.findIndex(({ id }) => id === task?.id);
    const contentThisWeekIndex = contentThisWeek.findIndex(({ id }) => id === task?.id);
    const contentTodayIndex = contentToday.findIndex(({ id }) => id === task?.id);

    if (contentIndex !== -1) {
      tempContent.splice(contentIndex, 1);
    }
    if (contentOverdueIndex !== -1) {
      tempContentOverdue.splice(contentOverdueIndex, 1);
    }
    if (contentLaterIndex !== -1) {
      tempContentLater.splice(contentLaterIndex, 1);
    }
    if (contentThisWeekIndex !== -1) {
      tempContentThisWeek.splice(contentThisWeekIndex, 1);
    }
    if (contentTodayIndex !== -1) {
      tempContentToday.splice(contentTodayIndex, 1);
    }

    this.dispatch(R.actions.commandCenter.setContent(tempContent));
    this.dispatch(R.actions.commandCenter.setContentOverdue(tempContentOverdue));
    this.dispatch(R.actions.commandCenter.setContentLater(tempContentLater));
    this.dispatch(R.actions.commandCenter.setContentThisWeek(tempContentThisWeek));
    this.dispatch(R.actions.commandCenter.setContentToday(tempContentToday));
  }

  public onStartTaskPage = async (shipmentId?: string) => {
    const { isTaskFirstRequest } = this.store.getState().commandCenter;
    if (isTaskFirstRequest) return;

    await this.resetTasksState();

    let tasksAll: NotificationTaskDTM | null = null;
    let tasksOverdue: NotificationTaskDTM | null = null;
    let tasksToday: NotificationTaskDTM | null = null;
    let tasksThisWeek: NotificationTaskDTM | null = null;
    let tasksLater: NotificationTaskDTM | null = null;

    const { currentState: { status } } = this.store.getState().commandCenter;
    if (status === REQUEST_STATUS.pending) return;

    this.dispatch(R.actions.commandCenter.setDefaultCurrentState());
    const { currentState } = this.store.getState().commandCenter;
    const criticality = [ENotificationCriticality.MEDIUM, ENotificationCriticality.LOW];

    this.dispatch(R.actions.commandCenter.setCurrentStatus(REQUEST_STATUS.pending));
    this.dispatch(R.actions.commandCenter.setCurrentCriticality(criticality));

    const { organization } = this.store.getState().userOrganizationData;
    let organizationReq: OrganizationDTM | undefined;

    if (!organization) {
      organizationReq = await UserManagementR.services.organization.getCurrentOrganization();
    }
    const { email } = this.store.getState().auth;
    const assigneeOrganizationUsers = email ? [email] : undefined;

    const organizationId = organization?.id || organizationReq?.id;
    const assigneeOrganizationIds = organizationId ? [organizationId] : undefined;

    const requestPage = (currentState.currentPage || currentState.currentPage === 0) ? currentState.currentPage + 1 : 0;

    const params = CommandCenterGetTasksDTM.fromPlain({
      page: `${requestPage}`,
      size: TasksBlockSize,
      sort: 'dueDate,asc',
      query: 'TODO',
      criticality,
      shipmentId,
      assigneeUserEmails: assigneeOrganizationUsers,
    });

    this.onSetupDefaultTasksFilters();
    await this.onLoadTasksFilters({
      params: CommandCenterGetTasksDTM.fromPlain({
        assigneeOrganizationIds: !shipmentId ? assigneeOrganizationIds : undefined,
        currentOrganizationId: organizationId,
        criticality,
        shipmentId,
      }),
    });
    this.onLoadStatisticsTasks(shipmentId);

    const [res1, res2, res3, res4, res5] = await Promise.all<NotificationTaskDTM | null>([
      getTasks(CommandCenterGetTasksDTM.fromPlain({
        ...params,
        sort: 'dueDate,desc',
        dueDateTo: moment().format(),
      })),
      getTasks(CommandCenterGetTasksDTM.fromPlain({
        ...params,
        dueDateFrom: moment().format(),
        dueDateTo: moment().endOf('day').format(),
      })),
      getTasks(CommandCenterGetTasksDTM.fromPlain({
        ...params,
        dueDateFrom: moment().add('days', 1).startOf('day').format(),
        dueDateTo: moment().add('days', 7).endOf('day').format(),
      })),
      getTasks(CommandCenterGetTasksDTM.fromPlain({
        ...params,
        includeEmptyDueDate: 'true',
        dueDateFrom: moment().add('days', 8).startOf('day').format(),
      })),
      getTasks(CommandCenterGetTasksDTM.fromPlain({
        ...params,
        dueDateFrom: moment().format(),
      })),
    ]);

    tasksOverdue = res1;
    tasksToday = res2;
    tasksThisWeek = res3;
    tasksLater = res4;
    tasksAll = res5;

    this.dispatch(R.actions.commandCenter.setCurrentStatus(REQUEST_STATUS.complete));
    this.dispatch(R.actions.commandCenter.setCurrentPage(requestPage));
    this.dispatch(R.actions.commandCenter.setIsTaskFirstRequest(true));

    this.dispatch(R.actions.commandCenter.setTotalElements(tasksAll?.totalElements || 0));
    this.dispatch(R.actions.commandCenter.setContent(tasksAll?.content || []));

    this.dispatch(R.actions.commandCenter.setContentOverdue(tasksOverdue?.content || []));
    this.dispatch(R.actions.commandCenter.setContentToday(tasksToday?.content || []));
    this.dispatch(R.actions.commandCenter.setContentThisWeek(tasksThisWeek?.content || []));
    this.dispatch(R.actions.commandCenter.setContentLater(tasksLater?.content || []));
    this.dispatch(R.actions.commandCenter.setTotalElementsOverdue(tasksOverdue?.totalElements || 0));
    this.dispatch(R.actions.commandCenter.setTotalElementsToday(tasksToday?.totalElements || 0));
    this.dispatch(R.actions.commandCenter.setTotalElementsTisWeek(tasksThisWeek?.totalElements || 0));
    this.dispatch(R.actions.commandCenter.setTotalElementsLater(tasksLater?.totalElements || 0));
  }

  public onStartTaskAlertsPage = (shipmentId?: string) => async () => {
    const { isTaskFirstRequest } = this.store.getState().commandCenter;
    if (isTaskFirstRequest) return;

    await this.resetTasksState();

    let tasksAll: NotificationTaskDTM | null = null;
    let tasksOverdue: NotificationTaskDTM | null = null;
    let tasksToday: NotificationTaskDTM | null = null;
    let tasksThisWeek: NotificationTaskDTM | null = null;
    let tasksLater: NotificationTaskDTM | null = null;

    const { currentState: { status } } = this.store.getState().commandCenter;
    if (status === REQUEST_STATUS.pending) return;

    this.dispatch(R.actions.commandCenter.setDefaultCurrentState());
    const { currentState } = this.store.getState().commandCenter;
    const criticality = [ENotificationCriticality.HIGH];

    this.dispatch(R.actions.commandCenter.setCurrentStatus(REQUEST_STATUS.pending));
    this.dispatch(R.actions.commandCenter.setCurrentCriticality(criticality));

    const { organization } = this.store.getState().userOrganizationData;
    let organizationReq: OrganizationDTM | undefined;

    if (!organization) {
      organizationReq = await UserManagementR.services.organization.getCurrentOrganization();
    }

    const requestPage = (currentState.currentPage || currentState.currentPage === 0) ? currentState.currentPage + 1 : 0;
    const { email } = this.store.getState().auth;
    const assigneeOrganizationUsers = email ? [email] : undefined;

    const organizationId = organization?.id || organizationReq?.id;
    const assigneeOrganizationIds = organizationId ? [organizationId] : undefined;

    const params = CommandCenterGetTasksDTM.fromPlain({
      page: `${requestPage}`,
      size: TasksBlockSize,
      sort: 'dueDate,asc',
      query: 'TODO',
      criticality,
      shipmentId,
      assigneeUserEmails: assigneeOrganizationUsers,
    });

    this.onSetupDefaultTasksFilters();
    await this.onLoadTasksFilters({
      params: CommandCenterGetTasksDTM.fromPlain({
        assigneeOrganizationIds: !shipmentId ? assigneeOrganizationIds : undefined,
        currentOrganizationId: organizationId,
        criticality,
        shipmentId,
      }),
    });
    this.onLoadStatisticsTasks(shipmentId);

    const [res1, res2, res3, res4, res5] = await Promise.all<NotificationTaskDTM | null>([
      getTasks(CommandCenterGetTasksDTM.fromPlain({
        ...params,
        sort: 'dueDate,desc',
        dueDateTo: moment().format(),
      })),
      getTasks(CommandCenterGetTasksDTM.fromPlain({
        ...params,
        dueDateFrom: moment().format(),
        dueDateTo: moment().endOf('day').format(),
      })),
      getTasks(CommandCenterGetTasksDTM.fromPlain({
        ...params,
        dueDateFrom: moment().add('days', 1).startOf('day').format(),
        dueDateTo: moment().add('days', 7).endOf('day').format(),
      })),
      getTasks(CommandCenterGetTasksDTM.fromPlain({
        ...params,
        includeEmptyDueDate: 'true',
        dueDateFrom: moment().add('days', 8).startOf('day').format(),
      })),
      getTasks(CommandCenterGetTasksDTM.fromPlain({
        ...params,
        dueDateFrom: moment().format(),
      })),
    ]);

    tasksOverdue = res1;
    tasksToday = res2;
    tasksThisWeek = res3;
    tasksLater = res4;
    tasksAll = res5;

    this.dispatch(R.actions.commandCenter.setCurrentStatus(REQUEST_STATUS.complete));
    this.dispatch(R.actions.commandCenter.setCurrentPage(requestPage));
    this.dispatch(R.actions.commandCenter.setIsTaskFirstRequest(true));

    this.dispatch(R.actions.commandCenter.setTotalElements(tasksAll?.totalElements || 0));
    this.dispatch(R.actions.commandCenter.setContent(tasksAll?.content || []));

    this.dispatch(R.actions.commandCenter.setContentOverdue(tasksOverdue?.content || []));
    this.dispatch(R.actions.commandCenter.setContentToday(tasksToday?.content || []));
    this.dispatch(R.actions.commandCenter.setContentThisWeek(tasksThisWeek?.content || []));
    this.dispatch(R.actions.commandCenter.setContentLater(tasksLater?.content || []));
    this.dispatch(R.actions.commandCenter.setTotalElementsOverdue(tasksOverdue?.totalElements || 0));
    this.dispatch(R.actions.commandCenter.setTotalElementsToday(tasksToday?.totalElements || 0));
    this.dispatch(R.actions.commandCenter.setTotalElementsTisWeek(tasksThisWeek?.totalElements || 0));
    this.dispatch(R.actions.commandCenter.setTotalElementsLater(tasksLater?.totalElements || 0));
  }

  public onStartTaskCompletedPage = (shipmentId?: string) => async () => {
    await this.resetTasksState();

    let tasksAll: NotificationTaskDTM | null = null;

    const { currentState: { status } } = this.store.getState().commandCenter;
    if (status === REQUEST_STATUS.pending) return;

    this.dispatch(R.actions.commandCenter.setDefaultCurrentState());
    const { currentState } = this.store.getState().commandCenter;
    const criticality = [ENotificationCriticality.MEDIUM, ENotificationCriticality.LOW];

    this.dispatch(R.actions.commandCenter.setCurrentStatus(REQUEST_STATUS.pending));
    this.dispatch(R.actions.commandCenter.setCurrentStatusPage('DONE'));
    this.dispatch(R.actions.commandCenter.setCurrentCriticality(criticality));

    const { organization } = this.store.getState().userOrganizationData;
    let organizationReq: OrganizationDTM | undefined;

    if (!organization) {
      organizationReq = await UserManagementR.services.organization.getCurrentOrganization();
    }

    const requestPage = (currentState.currentPage || currentState.currentPage === 0) ? currentState.currentPage + 1 : 0;

    const { email } = this.store.getState().auth;
    const assigneeOrganizationUsers = email ? [email] : undefined;

    const organizationId = organization?.id || organizationReq?.id;
    const assigneeOrganizationIds = organizationId ? [organizationId] : undefined;

    const params = CommandCenterGetTasksDTM.fromPlain({
      page: `${requestPage}`,
      size: TasksScrollBlockSize,
      sort: 'completedAt,desc',
      query: 'DONE',
      shipmentId,
      criticality,
      assigneeUserEmails: assigneeOrganizationUsers,
    });

    this.onSetupDefaultTasksFilters();
    await this.onLoadTasksFilters({
      params: CommandCenterGetTasksDTM.fromPlain({
        assigneeOrganizationIds: !shipmentId ? assigneeOrganizationIds : undefined,
        currentOrganizationId: organizationId,
        criticality,
        shipmentId,
      }),
    });

    const [res1] = await Promise.all<NotificationTaskDTM | null>([
      getTasks(CommandCenterGetTasksDTM.fromPlain({
        ...params,
      })),
    ]);

    tasksAll = res1;

    this.dispatch(R.actions.commandCenter.setCurrentStatus(REQUEST_STATUS.complete));
    this.dispatch(R.actions.commandCenter.setCurrentPage(requestPage));
    this.dispatch(R.actions.commandCenter.setIsTaskFirstRequest(true));

    this.dispatch(R.actions.commandCenter.setTotalElements(tasksAll?.totalElements || 0));
    this.dispatch(R.actions.commandCenter.setContent(tasksAll?.content || []));
  }

  public onStartTaskAlertsCompletedPage = (shipmentId?: string) => async () => {
    await this.resetTasksState();

    let tasksAll: NotificationTaskDTM | null = null;
    const { currentState: { status } } = this.store.getState().commandCenter;
    if (status === REQUEST_STATUS.pending) return;

    this.dispatch(R.actions.commandCenter.setDefaultCurrentState());
    const { currentState } = this.store.getState().commandCenter;
    const criticality = [ENotificationCriticality.HIGH];

    this.dispatch(R.actions.commandCenter.setCurrentStatus(REQUEST_STATUS.pending));
    this.dispatch(R.actions.commandCenter.setCurrentStatusPage('DONE'));
    this.dispatch(R.actions.commandCenter.setCurrentCriticality(criticality));

    const { organization } = this.store.getState().userOrganizationData;
    let organizationReq: OrganizationDTM | undefined;

    if (!organization) {
      organizationReq = await UserManagementR.services.organization.getCurrentOrganization();
    }

    const requestPage = (currentState.currentPage || currentState.currentPage === 0) ? currentState.currentPage + 1 : 0;

    const { email } = this.store.getState().auth;
    const assigneeOrganizationUsers = email ? [email] : undefined;

    const organizationId = organization?.id || organizationReq?.id;
    const assigneeOrganizationIds = organizationId ? [organizationId] : undefined;

    const params = CommandCenterGetTasksDTM.fromPlain({
      page: `${requestPage}`,
      size: TasksScrollBlockSize,
      sort: 'completedAt,desc',
      query: 'DONE',
      criticality,
      shipmentId,
      assigneeUserEmails: assigneeOrganizationUsers,
    });

    this.onSetupDefaultTasksFilters();
    await this.onLoadTasksFilters({
      params: CommandCenterGetTasksDTM.fromPlain({
        assigneeOrganizationIds: !shipmentId ? assigneeOrganizationIds : undefined,
        currentOrganizationId: organizationId,
        criticality,
        shipmentId,
      }),
    });
    const [res1] = await Promise.all<NotificationTaskDTM | null>([
      getTasks(CommandCenterGetTasksDTM.fromPlain({
        ...params,
      })),
    ]);

    tasksAll = res1;

    if (shipmentId) {
      await this.onStartNotificationsPage(shipmentId)();
    }

    this.dispatch(R.actions.commandCenter.setCurrentStatus(REQUEST_STATUS.complete));
    this.dispatch(R.actions.commandCenter.setCurrentPage(requestPage));
    this.dispatch(R.actions.commandCenter.setIsTaskFirstRequest(true));

    this.dispatch(R.actions.commandCenter.setTotalElements(tasksAll?.totalElements || 0));
    this.dispatch(R.actions.commandCenter.setContent(tasksAll?.content || []));
  }

  public checkAnotherDownloadNextPageTask = async (parentHeight: number, elementHeight: number) => {
    const { currentState } = this.store.getState().commandCenter;

    if (!currentState.isNextFirstRunDownload && (parentHeight - 80) > elementHeight) {
      await this.nextPageTasks();

      if (parentHeight <= elementHeight) {
        this.dispatch(R.actions.commandCenter.setCurrentIsNextFirstRunDownload(true));
      }
    }
  }

  public nextPageTasks = (shipmentId?: string) => async () => {
    let response: NotificationTaskDTM | null = null;
    const { currentState } = this.store.getState().commandCenter;

    this.dispatch(R.actions.commandCenter.setCurrentStatusAddTasks(REQUEST_STATUS.pending));

    const requestPage = (currentState.currentPage || currentState.currentPage === 0) ? currentState.currentPage + 1 : 0;

    try {
      const params = CommandCenterGetTasksDTM.fromPlain({
        page: `${requestPage}`,
        sort: 'dueDate,asc',
        size: TasksScrollBlockSize,
        query: 'TODO',
        dueDateFrom: moment().format(),
        shipmentId,
      });

      const errors = params?.validate();

      if (errors?.length) {
        console.error('DTM valid RFQ: error (CommandCenterController)', errors, errors);
      }

      response = await R.services.CommandCenter.getTasks(params);
    } catch (e) {
      console.error('AW: CommandCenterController -> nextPageTasks');
      this.dispatch(R.actions.commandCenter.setCurrentStatusAddTasks(REQUEST_STATUS.rejected));

      throw e;
    }

    const newContent = [
      ...currentState.content,
      ...response?.content || [],
    ];

    this.dispatch(R.actions.commandCenter.setCurrentStatusAddTasks(REQUEST_STATUS.complete));
    this.dispatch(R.actions.commandCenter.setCurrentPage(requestPage));
    this.dispatch(R.actions.commandCenter.setTotalPages(response?.totalPages || 0));

    this.dispatch(R.actions.commandCenter.setTotalElements(response?.totalElements || 0));
    this.dispatch(R.actions.commandCenter.addContent(response?.content || []));

    const { currentState: newCurrentTask } = this.store.getState().commandCenter;

    if (response?.content.length && newCurrentTask.content.length !== newContent.length) {
      await this.nextPageTasks();
    }
  }

  public nextPageTasksCompleted = (shipmentId?: string) => async () => {
    let response: NotificationTaskDTM | null = null;
    const { currentState } = this.store.getState().commandCenter;

    this.dispatch(R.actions.commandCenter.setCurrentStatusAddTasks(REQUEST_STATUS.pending));

    const requestPage = (currentState.currentPage || currentState.currentPage === 0) ? currentState.currentPage + 1 : 0;

    try {
      const params = CommandCenterGetTasksDTM.fromPlain({
        page: `${requestPage}`,
        sort: 'completedAt,desc',
        size: TasksScrollBlockSize,
        query: 'DONE',
        criticality: currentState?.criticality,
        shipmentId,
        ...this.getTasksFiltersRequestParams({
          dateFrom: currentState?.filters?.filterReceivedDates?.dates?.earliestDate || undefined,
          dateTo: currentState?.filters?.filterReceivedDates?.dates?.latestDate || undefined,
        }),
      });

      const errors = params?.validate();

      if (errors?.length) {
        console.error('DTM valid RFQ: error (CommandCenterController)', errors, errors);
      }

      response = await R.services.CommandCenter.getTasks(params);
    } catch (e) {
      console.error('AW: CommandCenterController -> nextPageTasksCompleted');
      this.dispatch(R.actions.commandCenter.setCurrentStatusAddTasks(REQUEST_STATUS.rejected));

      throw e;
    }

    const newContent = [
      ...currentState.content,
      ...response?.content || [],
    ];

    this.dispatch(R.actions.commandCenter.setCurrentStatusAddTasks(REQUEST_STATUS.complete));
    this.dispatch(R.actions.commandCenter.setCurrentPage(requestPage));
    this.dispatch(R.actions.commandCenter.setTotalPages(response?.totalPages || 0));

    this.dispatch(R.actions.commandCenter.setTotalElements(response?.totalElements || 0));
    this.dispatch(R.actions.commandCenter.addContent(response?.content || []));

    const { currentState: newCurrentTask } = this.store.getState().commandCenter;

    if (response?.content.length && newCurrentTask.content.length !== newContent.length) {
      await this.nextPageTasksCompleted();
    }
  }

  public onChangeFilterTasksDueDates = () => async (dates: NotificationTaskFilterDatesDTM) => {
    const { currentState: { filters } } = this.store.getState().commandCenter;

    this.dispatch(R.actions.commandCenter.setTasksFilters(NotificationViewedFiltersDTM.fromPlain({
      ...filters,
      filterReceivedDates: NotificationTaskFilterReceivedDatesDTM.fromPlain({
        ...filters?.filterReceivedDates,
        dates,
      }),
    })));

    this.compareFilterTasksDueDates();
  }

  public onChangeFilterTasksShowOnlyUnread = (shipmentId?: string) => async (value: boolean) => {
    const { currentState: { filters, status } } = this.store.getState().commandCenter;

    if (status === REQUEST_STATUS.pending || filters?.isRequest) {
      return;
    }

    const filterAssignee = !value ? {
      group: [...filters?.filterAssignee?.groupDefault || []],
      lastGroup: [],
      isUpdate: false,
      disableReset: true,
      disabledModal: true,
      groupDefault: [...filters?.filterAssignee?.groupDefault || []],
    } : {
      ...filters?.filterAssignee,
      disabledModal: false,
    };
    const filterAssigneeOrganization = !value ? {
      group: [...filters?.filterAssigneeOrganization?.groupDefault || []],
      lastGroup: [],
      isUpdate: false,
      disableReset: true,
      disabledModal: true,
      groupDefault: [...filters?.filterAssigneeOrganization?.groupDefault || []],
    } : {
      ...filters?.filterAssigneeOrganization,
      disabledModal: false,
    };

    this.dispatch(R.actions.commandCenter.setTasksFilters(NotificationViewedFiltersDTM.fromPlain({
      ...filters,
      isOnlyUnread: value,
      filterAssignee,
      filterAssigneeOrganization,
    })));

    this.compareFilterTasksAssigneeFilter();
    await this.onRunTasksUpdateFilters(shipmentId);
  }

  public onApplyFilterDueDates = (shipmentId?: string) => async () => {
    const { currentState: { filters } } = this.store.getState().commandCenter;

    if (filters?.isRequest || !filters?.filterReceivedDates?.isUpdate) {
      return;
    }

    await this.onRunTasksUpdateFilters(shipmentId);
  }

  public onResetFilterDueDates = (shipmentId?: string) => async () => {
    const { currentState: { filters } } = this.store.getState().commandCenter;

    this.dispatch(R.actions.commandCenter.setTasksFilters(NotificationViewedFiltersDTM.fromPlain({
      ...filters,
      filterReceivedDates: undefined,
    })));

    await this.onRunTasksUpdateFilters(shipmentId, true);

    this.compareFilterTasksDueDates();
  }

  public onChangeFilterTasksCompletionDates = () => async (dates: NotificationTaskFilterDatesDTM) => {
    const { currentState: { filters } } = this.store.getState().commandCenter;

    this.dispatch(R.actions.commandCenter.setTasksFilters(NotificationViewedFiltersDTM.fromPlain({
      ...filters,
      filterCompletionDates: NotificationTaskFilterReceivedDatesDTM.fromPlain({
        ...filters?.filterCompletionDates,
        dates,
      }),
    })));

    this.compareFilterTasksCompletionDates();
  }

  public onApplyFilterCompletionDates = (shipmentId?: string) => async () => {
    const { currentState: { filters } } = this.store.getState().commandCenter;

    if (filters?.isRequest || !filters?.filterCompletionDates?.isUpdate) {
      return;
    }

    await this.onRunTasksUpdateFilters(shipmentId);
  }

  public onResetFilterCompletionDates = (shipmentId?: string) => async () => {
    const { currentState: { filters } } = this.store.getState().commandCenter;

    this.dispatch(R.actions.commandCenter.setTasksFilters(NotificationViewedFiltersDTM.fromPlain({
      ...filters,
      filterCompletionDates: undefined,
    })));

    await this.onRunTasksUpdateFilters(shipmentId, true);

    this.compareFilterTasksCompletionDates();
  }

  public onChangeTasksGroupModule = () => async (id: number, value: AppFiltersCheckboxGroupDTM) => {
    const { currentState: { filters } } = this.store.getState().commandCenter;

    const newGroup = [...filters?.filterModule?.group || []];
    const groupId = filters?.filterModule?.group?.findIndex((item) => item?.customId === value?.customId);

    newGroup[groupId && groupId !== -1 ? groupId : id] = value;

    this.dispatch(R.actions.commandCenter.setTasksFilters(NotificationViewedFiltersDTM.fromPlain({
      ...filters,
      filterModule: {
        ...filters?.filterModule,
        group: newGroup,
      },
    })));

    this.compareFilterTasksModuleFilter();
  }

  public onApplyTasksModule = (shipmentId?: string) => async () => {
    const { currentState: { filters } } = this.store.getState().commandCenter;

    if (filters?.isRequest || !filters?.filterModule?.isUpdate) {
      return;
    }

    await this.onRunTasksUpdateFilters(shipmentId);
  }

  public onResetTasksModule = (shipmentId?: string) => async () => {
    const { currentState: { filters } } = this.store.getState().commandCenter;

    this.dispatch(R.actions.commandCenter.setTasksFilters(NotificationViewedFiltersDTM.fromPlain({
      ...filters,
      filterModule: {
        group: newGroupFilterModule,
        groupDefault: newGroupFilterModule,
      },
    })));

    await this.onRunTasksUpdateFilters(shipmentId, true);

    this.compareFilterTasksModuleFilter();
  }

  public onChangeTasksGroupAssignee = () => async (id: number, value: AppFiltersCheckboxGroupDTM) => {
    const { currentState: { filters } } = this.store.getState().commandCenter;

    const newGroup = [...filters?.filterAssignee?.group || []];
    const groupId = filters?.filterAssignee?.group?.findIndex((item) => item?.customId === value?.customId);

    newGroup[groupId && groupId !== -1 ? groupId : id] = value;

    this.dispatch(R.actions.commandCenter.setTasksFilters(NotificationViewedFiltersDTM.fromPlain({
      ...filters,
      filterAssignee: {
        ...filters?.filterAssignee,
        group: newGroup,
      },
    })));

    this.compareFilterTasksAssigneeFilter();
  }

  public onApplyTasksAssignee = (shipmentId?: string) => async () => {
    const { currentState: { filters } } = this.store.getState().commandCenter;

    if (filters?.isRequest || !filters?.filterAssignee?.isUpdate) {
      return;
    }

    await this.onRunTasksUpdateFilters(shipmentId);

    this.compareFilterTasksAssigneeFilter();
  }

  public onResetTasksAssignee = (shipmentId?: string) => async () => {
    const { currentState: { filters } } = this.store.getState().commandCenter;

    this.dispatch(R.actions.commandCenter.setTasksFilters(NotificationViewedFiltersDTM.fromPlain({
      ...filters,
      filterAssignee: {
        group: [...filters?.filterAssignee?.groupDefault || []],
        lastGroup: [],
        isUpdate: false,
        disableReset: true,
        groupDefault: [...filters?.filterAssignee?.groupDefault || []],
      },
    })));

    await this.onRunTasksUpdateFilters(shipmentId, true);

    this.compareFilterTasksAssigneeFilter();
  }

  public onChangeTasksGroupAssigneeOrganization = () => async (id: number, value: AppFiltersCheckboxGroupDTM) => {
    const { currentState: { filters } } = this.store.getState().commandCenter;

    const newGroup = [...filters?.filterAssigneeOrganization?.group || []];
    const groupId = filters?.filterAssigneeOrganization?.group?.findIndex((item) => item?.customId === value?.customId);

    newGroup[groupId && groupId !== -1 ? groupId : id] = value;

    this.dispatch(R.actions.commandCenter.setTasksFilters(NotificationViewedFiltersDTM.fromPlain({
      ...filters,
      filterAssigneeOrganization: {
        ...filters?.filterAssigneeOrganization,
        group: newGroup,
      },
    })));

    this.compareFilterTasksAssigneeOrganizationFilter();
  }

  public onApplyTasksAssigneeOrganization = (shipmentId?: string) => async () => {
    const { currentState: { filters } } = this.store.getState().commandCenter;

    if (filters?.isRequest || !filters?.filterAssigneeOrganization?.isUpdate) {
      return;
    }

    await this.onRunTasksUpdateFilters(shipmentId);

    this.compareFilterTasksAssigneeOrganizationFilter();
  }

  public onResetTasksAssigneeOrganization = (shipmentId?: string) => async () => {
    const { currentState: { filters } } = this.store.getState().commandCenter;

    this.dispatch(R.actions.commandCenter.setTasksFilters(NotificationViewedFiltersDTM.fromPlain({
      ...filters,
      filterAssigneeOrganization: {
        group: [...filters?.filterAssigneeOrganization?.groupDefault || []],
        lastGroup: [],
        isUpdate: false,
        disableReset: true,
        groupDefault: [...filters?.filterAssigneeOrganization?.groupDefault || []],
      },
    })));

    await this.onRunTasksUpdateFilters(shipmentId, true);

    this.compareFilterTasksAssigneeOrganizationFilter();
  }

  public onChangeTasksGroupObject = () => async (checkedKeysValue: string[]) => {
    const { currentState: { filters } } = this.store.getState().commandCenter;

    const newGroup = filters?.filterTreeDataObject?.group?.map((item) => {
      if (checkedKeysValue.includes(item.value)) {
        return {
          ...item,
          checked: true,
        };
      }

      return {
        ...item,
        checked: false,
      };
    });

    this.dispatch(R.actions.commandCenter.setTasksFilters(NotificationViewedFiltersDTM.fromPlain({
      ...filters,
      filterTreeDataObject: {
        ...filters?.filterTreeDataObject,
        group: newGroup,
      },
    })));

    this.compareFilterTasksObjectTreeFilter();
  }

  public onApplyTasksObject = (shipmentId?: string) => async () => {
    const { currentState: { filters } } = this.store.getState().commandCenter;

    if (filters?.isRequest || !filters?.filterTreeDataObject?.isUpdate) {
      return;
    }

    await this.onRunTasksUpdateFilters(shipmentId);

    this.compareFilterTasksObjectTreeFilter();
  }

  public onResetTasksObject = (shipmentId?: string) => async () => {
    const { currentState: { filters } } = this.store.getState().commandCenter;

    this.dispatch(R.actions.commandCenter.setTasksFilters(NotificationViewedFiltersDTM.fromPlain({
      ...filters,
      filterTreeDataObject: {
        ...filters?.filterTreeDataObject,
        group: [...filters?.filterTreeDataObject?.groupDefault || []],
        lastGroup: [],
        isUpdate: false,
        disableReset: true,
        groupDefault: [...filters?.filterTreeDataObject?.groupDefault || []],
        treeData: filters?.filterTreeDataObject?.treeData?.map((item) => ({
          ...item,
          checked: false,
        })),
      },
    })));

    await this.onRunTasksUpdateFilters(shipmentId, true);

    this.compareFilterTasksObjectTreeFilter();
  }

  public onRunTasksUpdateFilters = async (shipmentId?: string, isReset?: boolean) => {
    const { currentState } = this.store.getState().commandCenter;
    const {
      filters, status, statusPage, criticality,
    } = currentState;

    let tasksAll: NotificationTaskDTM | null = null;
    let tasksOverdue: NotificationTaskDTM | null = null;
    let tasksToday: NotificationTaskDTM | null = null;
    let tasksThisWeek: NotificationTaskDTM | null = null;
    let tasksLater: NotificationTaskDTM | null = null;

    if (status === REQUEST_STATUS.pending) return;
    this.dispatch(R.actions.commandCenter.setDefaultCurrentState());

    this.dispatch(R.actions.commandCenter.setCurrentStatus(REQUEST_STATUS.pending));
    this.dispatch(R.actions.commandCenter.setCurrentCriticality(criticality));
    this.dispatch(R.actions.commandCenter.setContent([]));

    const requestPage = 0;

    const params = CommandCenterGetTasksDTM.fromPlain({
      page: `${requestPage}`,
      size: TasksBlockSize,
      sort: 'dueDate,asc',
      query: statusPage || 'TODO',
      criticality,
      shipmentId,
    });

    this.dispatch(R.actions.commandCenter.setTasksFilters(NotificationViewedFiltersDTM.fromPlain({
      ...filters,
      isRequest: true,
    })));

    this.onLoadStatisticsTasks(shipmentId);
    const [res1, res2, res3, res4, res5] = await Promise.all<NotificationTaskDTM | null>([
      !statusPage ? getTasks(CommandCenterGetTasksDTM.fromPlain({
        ...params,
        sort: 'dueDate,desc',
        dueDateTo: moment().format(),
        ...this.getTasksFiltersRequestParams({
          dateTo: moment().format(),
          isOverdue: true,
          isReset,
        }),
      })) : new Promise((resolve) => resolve(null)),
      !statusPage ? getTasks(CommandCenterGetTasksDTM.fromPlain({
        ...params,
        dueDateFrom: moment().format(),
        dueDateTo: moment().endOf('day').format(),
        ...this.getTasksFiltersRequestParams({
          dateFrom: moment().format(),
          dateTo: moment().endOf('day').format(),
          isToday: true,
          isReset,
        }),
      })) : new Promise((resolve) => resolve(null)),
      !statusPage ? getTasks(CommandCenterGetTasksDTM.fromPlain({
        ...params,
        dueDateFrom: moment().add('days', 1).startOf('day').format(),
        dueDateTo: moment().add('days', 7).endOf('day').format(),
        ...this.getTasksFiltersRequestParams({
          dateFrom: moment().add('days', 1).startOf('day').format(),
          dateTo: moment().add('days', 7).endOf('day').format(),
          isWeek: true,
          isReset,
        }),
      })) : new Promise((resolve) => resolve(null)),
      !statusPage ? getTasks(CommandCenterGetTasksDTM.fromPlain({
        ...params,
        includeEmptyDueDate: 'true',
        dueDateFrom: moment().add('days', 8).startOf('day').format(),
        ...this.getTasksFiltersRequestParams({
          dateFrom: moment().add('days', 8).startOf('day').format(),
          isLater: true,
          isReset,
        }),
      })) : new Promise((resolve) => resolve(null)),
      getTasks(CommandCenterGetTasksDTM.fromPlain({
        ...params,
        size: TasksScrollBlockSize,
        sort: 'completedAt,desc',
        ...this.getTasksFiltersRequestParams({
        }),
      })),
    ]).catch();

    tasksOverdue = res1;
    tasksToday = res2;
    tasksThisWeek = res3;
    tasksLater = res4;
    tasksAll = res5;

    this.dispatch(R.actions.commandCenter.setCurrentStatus(REQUEST_STATUS.complete));
    this.dispatch(R.actions.commandCenter.setCurrentPage(requestPage));
    this.dispatch(R.actions.commandCenter.setTotalPages(tasksAll?.totalPages || 0));
    this.dispatch(R.actions.commandCenter.setIsTaskFirstRequest(true));

    this.dispatch(R.actions.commandCenter.setTotalElements(tasksAll?.totalElements || 0));
    this.dispatch(R.actions.commandCenter.setContent(tasksAll?.content || []));
    this.dispatch(R.actions.commandCenter.setCurrentStatusPage(statusPage));

    this.dispatch(R.actions.commandCenter.setContentOverdue(tasksOverdue?.content || []));
    this.dispatch(R.actions.commandCenter.setContentToday(tasksToday?.content || []));
    this.dispatch(R.actions.commandCenter.setContentThisWeek(tasksThisWeek?.content || []));
    this.dispatch(R.actions.commandCenter.setContentLater(tasksLater?.content || []));
    this.dispatch(R.actions.commandCenter.setTotalElementsOverdue(tasksOverdue?.totalElements || 0));
    this.dispatch(R.actions.commandCenter.setTotalElementsToday(tasksToday?.totalElements || 0));
    this.dispatch(R.actions.commandCenter.setTotalElementsTisWeek(tasksThisWeek?.totalElements || 0));
    this.dispatch(R.actions.commandCenter.setTotalElementsLater(tasksLater?.totalElements || 0));

    this.dispatch(R.actions.commandCenter.setTasksFilters(NotificationViewedFiltersDTM.fromPlain({
      ...filters,
      isRequest: false,
    })));
  }

  public onSetupDefaultTasksFilters = () => {
    const { currentState: { filters } } = this.store.getState().commandCenter;

    this.dispatch(R.actions.commandCenter.setTasksFilters(NotificationViewedFiltersDTM.fromPlain({
      isOnlyUnread: false,
      filterModule: {
        group: newGroupFilterModule,
        groupDefault: newGroupFilterModule,
      },
      filterTreeDataObject: {
        disableReset: true,
      },
      filterAssigneeOrganization: {
        disableReset: true,
        disabledModal: true,
      },
      filterAssignee: {
        ...filters?.filterAssignee,
        disabledModal: true,
      },
    })));

    this.compareFilterNotificationsAssigneeFilter();
    this.compareFilterTasksDueDates();
    this.compareFilterTasksCompletionDates();
    this.compareFilterTasksModuleFilter();
  }

  public onSetupResetTasksFilters = () => {
    const { currentState: { filters } } = this.store.getState().commandCenter;

    this.dispatch(R.actions.commandCenter.setTasksFilters(NotificationViewedFiltersDTM.fromPlain({
      isOnlyUnread: false,
      isRequest: false,
      filterModule: {
        disableReset: true,
        isUpdate: false,
        group: newGroupFilterModule,
        groupDefault: newGroupFilterModule,
      },
      filterAssignee: {
        disableReset: true,
        disabledModal: true,
        isUpdate: false,
        group: filters?.filterAssignee?.groupDefault?.map((item) => item) || [],
        lastGroup: filters?.filterAssignee?.groupDefault?.map((item) => item) || [],
        groupDefault: filters?.filterAssignee?.groupDefault?.map((item) => item) || [],
      },
      filterAssigneeOrganization: {
        disableReset: true,
        disabledModal: true,
        isUpdate: false,
        group: filters?.filterAssigneeOrganization?.groupDefault?.map((item) => item) || [],
        lastGroup: filters?.filterAssigneeOrganization?.groupDefault?.map((item) => item) || [],
        groupDefault: filters?.filterAssigneeOrganization?.groupDefault?.map((item) => item) || [],
      },
      filterCompletionDates: {
        disableReset: true,
        isUpdate: false,
        dates: {
          earliestDate: '',
          latestDate: '',
        },
      },
      filterReceivedDates: {
        disableReset: true,
        isUpdate: false,
        dates: {
          earliestDate: '',
          latestDate: '',
        },
      },
      filterPriority: {
        disableReset: true,
        isUpdate: false,
        group: filters?.filterPriority?.groupDefault?.map((item) => item) || [],
        lastGroup: filters?.filterPriority?.groupDefault?.map((item) => item) || [],
        groupDefault: filters?.filterPriority?.groupDefault?.map((item) => item) || [],
      },
      filterObject: {
        disableReset: true,
        isUpdate: false,
        group: filters?.filterObject?.groupDefault?.map((item) => item) || [],
        lastGroup: filters?.filterObject?.groupDefault?.map((item) => item) || [],
        groupDefault: filters?.filterObject?.groupDefault?.map((item) => item) || [],
      },
      filterTreeDataObject: {
        ...filters?.filterTreeDataObject,
        disableReset: true,
        isUpdate: false,
        group: filters?.filterTreeDataObject?.groupDefault?.map((item) => ({
          ...item,
          checked: false,
        })) || [],
        lastGroup: [],
        groupDefault: filters?.filterTreeDataObject?.groupDefault?.map((item) => ({
          ...item,
          checked: false,
        })) || [],
      },
    })));

    this.compareFilterNotificationsAssigneeFilter();
    this.compareFilterTasksDueDates();
    this.compareFilterTasksCompletionDates();
    this.compareFilterTasksModuleFilter();
  }

  public getTasksFiltersRequestParams = (params?: {
    dateFrom?: string,
    dateTo?: string,
    isOverdue?: boolean,
    isToday?: boolean,
    isWeek?: boolean,
    isLater?: boolean,
    isReset?: boolean,
  }) => {
    const {
      dateFrom,
      dateTo,
      isOverdue,
      isToday,
      isWeek,
      isLater,
    } = params || {};
    const { currentState: { filters } } = this.store.getState().commandCenter;
    const { email } = this.store.getState().auth;
    let dueDateFrom: string | undefined = dateFrom;
    let dueDateTo: string | undefined = dateTo;

    const getDates = () => {
      const earliestDate = filters?.filterReceivedDates?.dates?.earliestDate ? moment(filters?.filterReceivedDates?.dates?.earliestDate) : undefined;
      const latestDate = filters?.filterReceivedDates?.dates?.latestDate ? moment(filters?.filterReceivedDates?.dates?.latestDate).endOf('day') : undefined;

      const diffFrom = moment(dateFrom).startOf('day').diff(filters?.filterReceivedDates?.dates?.earliestDate);
      const diffTo = moment(dateTo).diff(moment(filters?.filterReceivedDates?.dates?.latestDate).endOf('day').format());
      const diffFromTo = moment(dateFrom).startOf('day').diff(moment(dateTo).endOf('day').format());
      const diffLatestToday = moment(filters?.filterReceivedDates?.dates?.latestDate).startOf('day').diff(moment().endOf('day'));

      if (isOverdue && earliestDate && diffFrom >= 0) {
        dueDateFrom = earliestDate?.format();
      } else if (isOverdue) {
        dueDateFrom = 'undefined';
      }

      if (isOverdue && latestDate && diffLatestToday <= 0) {
        dueDateTo = latestDate?.format();
      } else if (isOverdue && diffLatestToday >= 0) {
        dueDateTo = moment().endOf('day').format();
      } else if (isOverdue) {
        dueDateTo = 'undefined';
      }

      if (isToday && diffFrom >= 0) {
        dueDateFrom = dateFrom;
      } else if (isToday) {
        dueDateFrom = 'undefined';
      }
      if (isToday && diffTo <= 0) {
        dueDateTo = dateTo;
      } else if (isToday) {
        dueDateTo = 'undefined';
      }

      if (isWeek && diffFrom <= 0 && diffFrom >= diffFromTo) {
        dueDateFrom = earliestDate?.format();
      } else if (isWeek
        && (earliestDate && moment(dateFrom).valueOf() >= earliestDate?.valueOf())) {
        dueDateFrom = dateFrom;
      } else if (isWeek) {
        dueDateFrom = 'undefined';
      }
      if (isWeek
        && (latestDate && moment(dateTo).valueOf() >= latestDate?.valueOf())) {
        dueDateTo = latestDate?.format();
      } else if (isWeek
        && (latestDate && moment(dateTo).valueOf() <= latestDate?.valueOf())) {
        dueDateTo = dateTo;
      } else if (isWeek) {
        dueDateTo = 'undefined';
      }

      if (isLater
        && (earliestDate && moment(dateFrom).valueOf() <= earliestDate?.valueOf())) {
        dueDateFrom = earliestDate?.format();
      } else if (isLater
        && (earliestDate && moment(dateFrom).valueOf() >= earliestDate?.valueOf())) {
        dueDateFrom = dateFrom;
      } else if (isLater) {
        dueDateFrom = 'undefined';
      }

      if (isLater
        && (latestDate && moment(dateFrom).valueOf() <= latestDate?.valueOf())) {
        dueDateTo = latestDate?.format();
      } else if (isLater) {
        dueDateTo = 'undefined';
      }
    };

    if (filters?.filterReceivedDates?.disableReset === false) {
      getDates();
    }

    const domainGroup = filters?.filterModule?.group
      ?.filter((itemFilter) => itemFilter.checked)
      ?.map((itemFilter) => itemFilter.value);

    const assigneeEmailGroup = filters?.filterAssignee?.group
      ?.filter((itemFilter) => itemFilter.checked)
      ?.map((itemFilter) => itemFilter.value);

    const assigneeOrganizationEmailGroup = filters?.filterAssigneeOrganization?.group
      ?.filter((itemFilter) => itemFilter.checked)
      ?.map((itemFilter) => itemFilter.value);

    const referenceOrganization = filters?.filterTreeDataObject?.group
      ?.filter((itemFilter) => (itemFilter.checked && itemFilter.value2 === 'Organization'))
      ?.map((itemFilter) => itemFilter.id);
    const referenceRate = filters?.filterTreeDataObject?.group
      ?.filter((itemFilter) => (itemFilter.checked && itemFilter.value2 === 'Request'))
      ?.map((itemFilter) => itemFilter.id);
    const referenceShipment = filters?.filterTreeDataObject?.group
      ?.filter((itemFilter) => (itemFilter.checked && itemFilter.value2 === 'Shipment'))
      ?.map((itemFilter) => itemFilter.id);

    const { organization } = this.store.getState().userOrganizationData;
    const assigneeOrganizationIdsGroup = assigneeOrganizationEmailGroup?.length ? assigneeOrganizationEmailGroup : undefined;
    const assigneeOrganizationIdsGroupCurrent = filters?.isOnlyUnread === true ? [organization?.id] : undefined;

    const assigneeUserEmails = assigneeEmailGroup || undefined;

    const assigneeUserEmailsParams = filters?.isOnlyUnread !== true && !assigneeUserEmails?.length ? [email] : assigneeUserEmails;

    const isUserUnassigned = Boolean(assigneeUserEmails?.find((_email) => _email === 'false'));

    const userAssigned = isUserUnassigned ? 'false' : undefined;

    return {
      dueDateFrom,
      dueDateTo,
      domain: domainGroup?.length ? domainGroup?.join(',') : undefined,
      assigneeUserEmails: isUserUnassigned ? undefined : assigneeUserEmailsParams,
      assigneeOrganizationIds: assigneeOrganizationIdsGroup || assigneeOrganizationIdsGroupCurrent,
      assigneeUserAssigned: userAssigned,
      objectReferenceOrganizationIds: referenceOrganization,
      objectReferenceRateRequestIds: referenceRate,
      objectReferenceShipmentIds: referenceShipment,
      createdAtFrom: filters?.filterReceivedDates?.dates?.earliestDate ? moment(filters?.filterReceivedDates?.dates?.earliestDate).format() : undefined,
      createdAtTo: filters?.filterReceivedDates?.dates?.latestDate ? moment(filters?.filterReceivedDates?.dates?.latestDate).endOf('day').format() : undefined,
      completedAtFrom: filters?.filterCompletionDates?.dates?.earliestDate ? moment(filters?.filterCompletionDates?.dates?.earliestDate).format() : undefined,
      completedAtTo: filters?.filterCompletionDates?.dates?.latestDate ? moment(filters?.filterCompletionDates?.dates?.latestDate).endOf('day').format() : undefined,
    } as ICommandCenterGetTasksDTM;
  }

  public onLoadStatisticsNotifications = async (shipmentId?: string) => {
    const organization = await this.repositories.organizationRepository.get();
    const assigneeAudienceOrganizationIds = organization?.id ? [organization?.id] : undefined;

    const params = CommandCenterGetCommandCenterGetNotificationsDTM.fromPlain({
      page: `${0}`,
      size: '1',
      sort: 'createdAt,desc',
      shipmentId,
      assigneeAudienceOrganizationIds,
    });

    const [res11] = await Promise.all<ShipmentAllStatsDTM | undefined>([
      getTasksStats(CommandCenterGetTasksDTM.fromPlain({
        ...params,
        targetAudienceOrganizationIds: assigneeAudienceOrganizationIds,
      })),
    ]);

    const notificationUnread1 = res11?.notificationStats.unread;
    const notificationImportant1 = res11?.notificationStats.unreadCritical;
    const notificationRegular = res11?.notificationStats.unreadRegular;

    this.dispatch(R.actions.commandCenter.setCountUnreadNotifications(notificationUnread1 || 0));
    this.dispatch(R.actions.commandCenter.setCountImportantNotifications(notificationImportant1 || 0));
    this.dispatch(R.actions.commandCenter.setCountRegularNotifications((notificationRegular || 0)));
  }

  public getNotificationsFiltersRequestParams = () => {
    const { notificationCurrent: { filters } } = this.store.getState().commandCenter;

    const criticalityGroup = filters?.filterPriority?.group
      ?.filter((itemFilter) => itemFilter.checked)
      ?.map((itemFilter) => itemFilter.value);

    const domainGroup = filters?.filterModule?.group
      ?.filter((itemFilter) => itemFilter.checked)
      ?.map((itemFilter) => itemFilter.value);

    const { organization } = this.store.getState().userOrganizationData;
    const assigneeAudienceOrganizationIds = organization?.id ? [organization?.id] : undefined;

    return {
      viewed: filters?.isOnlyUnread === true ? 'false' : undefined,
      criticality: criticalityGroup,
      assigneeAudienceOrganizationIds,
      domain: domainGroup?.length ? domainGroup?.join(',') : undefined,
      createdAtFrom: filters?.filterReceivedDates?.dates?.earliestDate ? moment(filters?.filterReceivedDates?.dates?.earliestDate).format() : undefined,
      createdAtTo: filters?.filterReceivedDates?.dates?.latestDate ? moment(filters?.filterReceivedDates?.dates?.latestDate).endOf('day').format() : undefined,
    };
  }

  public onSetupDefaultNotificationsFilters = () => {
    this.dispatch(R.actions.commandCenter.setNotificationFilters(NotificationViewedFiltersDTM.fromPlain({
      isOnlyUnread: false,
      filterPriority: {
        group: newGroupFilterPriority,
        groupDefault: newGroupFilterPriority,
      },
      filterModule: {
        group: newGroupFilterModule,
        groupDefault: newGroupFilterModule,
      },
    })));

    this.compareFilterNotificationsPriorityFilter();
    this.compareFilterNotificationsReceivedDates();
    this.compareFilterNotificationsModuleFilter();
  }

  public onStartNotificationsPage = (shipmentId?: string) => async () => {
    let response: NotificationTaskDTM | null = null;
    const { notificationCurrent: { status } } = this.store.getState().commandCenter;
    if (status === REQUEST_STATUS.pending) return;

    this.dispatch(R.actions.commandCenter.setDefaultNotificationCurrentState());

    this.onSetupDefaultNotificationsFilters();

    const { notificationCurrent } = this.store.getState().commandCenter;

    this.dispatch(R.actions.commandCenter.setNotificationCurrentStatus(REQUEST_STATUS.pending));

    const requestPage = (notificationCurrent.currentPage || notificationCurrent.currentPage === 0) ? notificationCurrent.currentPage + 1 : 0;

    const { organization } = this.store.getState().userOrganizationData;
    const assigneeAudienceOrganizationIds = organization?.id ? [organization?.id] : undefined;

    this.onLoadStatisticsNotifications(shipmentId);

    try {
      response = await R.services.CommandCenter.getNotifications(CommandCenterGetCommandCenterGetNotificationsDTM.fromPlain({
        page: `${requestPage}`,
        size: notificationSize,
        sort: 'createdAt,desc',
        ...this.getNotificationsFiltersRequestParams(),
        shipmentId,
        assigneeAudienceOrganizationIds,
      }));
    } catch (e) {
      console.error('AW: CommandCenterController -> onStartNotificationsPage');
      this.dispatch(R.actions.commandCenter.setNotificationCurrentStatus(REQUEST_STATUS.rejected));

      throw e;
    }

    this.dispatch(R.actions.commandCenter.setNotificationCurrentStatus(REQUEST_STATUS.complete));
    this.dispatch(R.actions.commandCenter.setNotificationCurrentPage(requestPage));
    this.dispatch(R.actions.commandCenter.setNotificationTotalPages(response?.totalPages || 0));
    this.dispatch(R.actions.commandCenter.setIsNotificationFirstRequest(true));

    this.dispatch(R.actions.commandCenter.setNotificationTotalElements(response?.totalElements || 0));
    this.dispatch(R.actions.commandCenter.setNotificationContent(response?.content || []));

    await this.nextPageNotifications(shipmentId)();
  }

  public nextPageNotifications = (shipmentId?: string) => async () => {
    let response: NotificationTaskDTM | null = null;
    const { notificationCurrent } = this.store.getState().commandCenter;
    this.dispatch(R.actions.commandCenter.setNotificationCurrentStatusAddTasks(REQUEST_STATUS.pending));

    const requestPage = (notificationCurrent.currentPage || notificationCurrent.currentPage === 0) ? notificationCurrent.currentPage + 1 : 0;

    const { organization } = this.store.getState().userOrganizationData;
    const assigneeAudienceOrganizationIds = organization?.id ? [organization?.id] : undefined;

    try {
      response = await R.services.CommandCenter.getNotifications(CommandCenterGetCommandCenterGetNotificationsDTM.fromPlain({
        page: `${requestPage}`,
        size: notificationSize,
        sort: 'createdAt,desc',
        ...this.getNotificationsFiltersRequestParams(),
        shipmentId,
        assigneeAudienceOrganizationIds,
      }));
    } catch (e) {
      console.error('AW: CommandCenterController -> nextPageNotifications');
      this.dispatch(R.actions.commandCenter.setNotificationCurrentStatusAddTasks(REQUEST_STATUS.rejected));

      return;
    }

    this.dispatch(R.actions.commandCenter.setNotificationCurrentStatusAddTasks(REQUEST_STATUS.complete));
    this.dispatch(R.actions.commandCenter.setNotificationCurrentPage(requestPage));
    this.dispatch(R.actions.commandCenter.setNotificationTotalPages(response?.totalPages || 0));

    this.dispatch(R.actions.commandCenter.setNotificationTotalElements(response?.totalElements || 0));
    this.dispatch(R.actions.commandCenter.addNotificationContent(response?.content || []));
  }

  public onRunNotificationsUpdateFilters = async (shipmentId?: string) => {
    const { notificationCurrent: { filters } } = this.store.getState().commandCenter;

    let response: NotificationTaskDTM | null = null;
    this.dispatch(R.actions.commandCenter.setNotificationCurrentStatusAddTasks(REQUEST_STATUS.pending));
    this.dispatch(R.actions.commandCenter.setNotificationContent([]));

    const requestPage = 0;

    this.dispatch(R.actions.commandCenter.setNotificationFilters(NotificationViewedFiltersDTM.fromPlain({
      ...filters,
      isRequest: true,
    })));

    try {
      response = await R.services.CommandCenter.getNotifications(CommandCenterGetCommandCenterGetNotificationsDTM.fromPlain({
        page: `${requestPage}`,
        size: notificationSize,
        sort: 'createdAt,desc',
        ...this.getNotificationsFiltersRequestParams(),
        shipmentId,
      }));
    } catch (e) {
      console.error('AW: CommandCenterController -> onRunNotificationsUpdateFilters');
      this.dispatch(R.actions.commandCenter.setNotificationCurrentStatusAddTasks(REQUEST_STATUS.rejected));
      this.dispatch(R.actions.commandCenter.setNotificationFilters(NotificationViewedFiltersDTM.fromPlain({
        ...filters,
        isRequest: false,
      })));

      throw e;
    }

    this.dispatch(R.actions.commandCenter.setNotificationCurrentStatusAddTasks(REQUEST_STATUS.complete));
    this.dispatch(R.actions.commandCenter.setNotificationCurrentPage(requestPage));
    this.dispatch(R.actions.commandCenter.setNotificationTotalPages(response?.totalPages || 0));

    this.dispatch(R.actions.commandCenter.setNotificationTotalElements(response?.totalElements || 0));
    this.dispatch(R.actions.commandCenter.setNotificationContent(response?.content || []));

    this.dispatch(R.actions.commandCenter.setNotificationFilters(NotificationViewedFiltersDTM.fromPlain({
      ...filters,
      isRequest: false,
    })));
  }

  public onChangeFilterShowOnlyUnread = (shipmentId?: string) => async (value: boolean) => {
    const { notificationCurrent: { filters } } = this.store.getState().commandCenter;

    this.dispatch(R.actions.commandCenter.setNotificationFilters(NotificationViewedFiltersDTM.fromPlain({
      ...filters,
      isOnlyUnread: value,
    })));

    await this.onRunNotificationsUpdateFilters(shipmentId);
  }

  public onApplyPriority = (shipmentId?: string) => async () => {
    const { notificationCurrent: { filters } } = this.store.getState().commandCenter;

    if (filters?.isRequest || !filters?.filterPriority?.isUpdate) {
      return;
    }

    await this.onRunNotificationsUpdateFilters(shipmentId);
  }

  public onResetPriority = (shipmentId?: string) => async () => {
    const { notificationCurrent: { filters } } = this.store.getState().commandCenter;

    this.dispatch(R.actions.commandCenter.setNotificationFilters(NotificationViewedFiltersDTM.fromPlain({
      ...filters,
      filterPriority: {
        group: newGroupFilterPriority,
        groupDefault: newGroupFilterPriority,
      },
    })));

    await this.onRunNotificationsUpdateFilters(shipmentId);

    this.compareFilterNotificationsPriorityFilter();
  }

  public onChangeGroupPriority = () => async (id: number, value: AppFiltersCheckboxGroupDTM) => {
    const { notificationCurrent: { filters } } = this.store.getState().commandCenter;

    const newGroup = [...filters?.filterPriority?.group || []];
    const groupId = filters?.filterPriority?.group?.findIndex((item) => item?.customId === value?.customId);

    newGroup[groupId && groupId !== -1 ? groupId : id] = value;

    this.dispatch(R.actions.commandCenter.setNotificationFilters(NotificationViewedFiltersDTM.fromPlain({
      ...filters,
      filterPriority: {
        ...filters?.filterPriority,
        group: newGroup,
      },
    })));

    this.compareFilterNotificationsPriorityFilter();
  }

  public onChangeFilterReceivedDates = () => async (dates: NotificationTaskFilterDatesDTM) => {
    const { notificationCurrent: { filters } } = this.store.getState().commandCenter;

    this.dispatch(R.actions.commandCenter.setNotificationFilters(NotificationViewedFiltersDTM.fromPlain({
      ...filters,
      filterReceivedDates: NotificationTaskFilterReceivedDatesDTM.fromPlain({
        ...filters?.filterReceivedDates,
        dates,
      }),
    })));

    this.compareFilterNotificationsReceivedDates();
  }

  public onApplyFilterReceivedDates = (shipmentId?: string) => async () => {
    const { notificationCurrent: { filters } } = this.store.getState().commandCenter;

    if (filters?.isRequest || !filters?.filterReceivedDates?.isUpdate) {
      return;
    }

    await this.onRunNotificationsUpdateFilters(shipmentId);
  }

  public onResetFilterReceivedDates = (shipmentId?: string) => async () => {
    const { notificationCurrent: { filters } } = this.store.getState().commandCenter;

    this.dispatch(R.actions.commandCenter.setNotificationFilters(NotificationViewedFiltersDTM.fromPlain({
      ...filters,
      filterReceivedDates: undefined,
    })));

    await this.onRunNotificationsUpdateFilters(shipmentId);

    this.compareFilterNotificationsReceivedDates();
  }

  public onChangeGroupModule = () => async (id: number, value: AppFiltersCheckboxGroupDTM) => {
    const { notificationCurrent: { filters } } = this.store.getState().commandCenter;

    const newGroup = [...filters?.filterModule?.group || []];
    const groupId = filters?.filterModule?.group?.findIndex((item) => item?.customId === value?.customId);

    newGroup[groupId && groupId !== -1 ? groupId : id] = value;

    this.dispatch(R.actions.commandCenter.setNotificationFilters(NotificationViewedFiltersDTM.fromPlain({
      ...filters,
      filterModule: {
        ...filters?.filterModule,
        group: newGroup,
      },
    })));

    this.compareFilterNotificationsModuleFilter();
  }

  public onApplyModule = (shipmentId?: string) => async () => {
    const { notificationCurrent: { filters } } = this.store.getState().commandCenter;

    if (filters?.isRequest || !filters?.filterModule?.isUpdate) {
      return;
    }

    await this.onRunNotificationsUpdateFilters(shipmentId);
  }

  public onResetModule = (shipmentId?: string) => async () => {
    const { notificationCurrent: { filters } } = this.store.getState().commandCenter;

    this.dispatch(R.actions.commandCenter.setNotificationFilters(NotificationViewedFiltersDTM.fromPlain({
      ...filters,
      filterModule: {
        group: newGroupFilterModule,
        groupDefault: newGroupFilterModule,
      },
    })));

    await this.onRunNotificationsUpdateFilters(shipmentId);

    this.compareFilterNotificationsModuleFilter();
  }

  public onClearAllFilters = (shipmentId?: string) => async () => {
    this.onSetupDefaultNotificationsFilters();

    await this.onRunNotificationsUpdateFilters(shipmentId);
  }

  public onClearAllTasksFilters = (shipmentId?: string) => async () => {
    await this.onSetupResetTasksFilters();

    await this.onRunTasksUpdateFilters(shipmentId);
  }

  public onHoverNotificationItem = async (id: string, shipmentId?: string) => {
    if (!id) return;
    let result: NotificationTaskContentViewedDTM | undefined;

    try {
      result = await R.services.CommandCenter.setNotificationsViewed(id);
    } catch (e) {
      console.error('AW: CommandCenterController -> onHoverNotificationItem');

      return;
    }

    this.onLoadStatisticsNotifications(shipmentId);

    if (result) {
      this.dispatch(R.actions.commandCenter.onCheckNotificationViewedById({
        id,
        viewedId: `${result.id}`,
      }));
    }
  }

  public onUnviewedNotificationItem = async (notificationId: string, viewId: string, shipmentId?: string) => {
    if (!notificationId || !viewId) return;

    try {
      await R.services.CommandCenter.setNotificationsUnviewed(notificationId, viewId);
    } catch (e) {
      console.error('AW: CommandCenterController -> onUnviewedNotificationItem');

      return;
    }

    this.onLoadStatisticsNotifications(shipmentId);

    this.dispatch(R.actions.commandCenter.onCheckUnviewedNotificationViewedById(notificationId));
  }

  public onAllNotificationMakeViews = (shipmentId?: string) => async () => {
    const { notificationCurrent } = this.store.getState().commandCenter;

    try {
      await R.services.CommandCenter.setAllNotificationsViewed(shipmentId);
    } catch (e) {
      console.error('AW: CommandCenterController -> onAllNotificationMakeViews');

      return;
    }

    this.onLoadStatisticsNotifications(shipmentId);

    const newContent = notificationCurrent?.content?.map((item) => NotificationTaskContentDTM.fromPlain({
      ...item,
      view: true,
    })) || [];

    this.dispatch(R.actions.commandCenter.setNotificationContent(newContent));
  }

  private compareFilterNotificationsPriorityFilter = () => {
    const { notificationCurrent: { filters } } = this.store.getState().commandCenter;
    let isDisabled = true;
    let isUpdate = false;

    filters?.filterPriority?.group?.forEach((itemPriority, indexPriority) => {
      if (itemPriority.checked) {
        isDisabled = false;
      }
      if (filters?.filterPriority?.lastGroup?.[indexPriority].name !== itemPriority.name
        || filters?.filterPriority.lastGroup[indexPriority].value !== itemPriority.value
        || filters?.filterPriority.lastGroup[indexPriority].checked !== itemPriority.checked) {
        isUpdate = true;
      }
    });

    this.dispatch(R.actions.commandCenter.setNotificationFilters(NotificationViewedFiltersDTM.fromPlain({
      ...filters,
      filterPriority: {
        ...filters?.filterPriority,
        disableReset: isDisabled,
        isUpdate,
        lastGroup: filters?.filterPriority?.group?.map((item) => ({ ...item })),
      },
    })));

    return isDisabled;
  }

  private compareFilterNotificationsReceivedDates = () => {
    const { notificationCurrent: { filters } } = this.store.getState().commandCenter;
    let isDisabled = true;
    let isUpdate = false;

    if (filters?.filterReceivedDates?.dates?.earliestDate && filters?.filterReceivedDates?.dates?.latestDate) {
      isDisabled = false;
      isUpdate = true;
    }

    this.dispatch(R.actions.commandCenter.setNotificationFilters(NotificationViewedFiltersDTM.fromPlain({
      ...filters,
      filterReceivedDates: NotificationTaskFilterReceivedDatesDTM.fromPlain({
        ...filters?.filterReceivedDates,
        disableReset: isDisabled,
        isUpdate,
      }),
    })));

    return isDisabled;
  }

  private compareFilterNotificationsModuleFilter = () => {
    const { notificationCurrent: { filters } } = this.store.getState().commandCenter;
    let isDisabled = true;
    let isUpdate = false;

    filters?.filterModule?.group?.forEach((itemModule, indexModule) => {
      if (itemModule.checked) {
        isDisabled = false;
      }
      if (filters?.filterModule?.lastGroup?.[indexModule].name !== itemModule.name
        || filters?.filterModule.lastGroup[indexModule].value !== itemModule.value
        || filters?.filterModule.lastGroup[indexModule].checked !== itemModule.checked) {
        isUpdate = true;
      }
    });

    this.dispatch(R.actions.commandCenter.setNotificationFilters(NotificationViewedFiltersDTM.fromPlain({
      ...filters,
      filterModule: {
        ...filters?.filterModule,
        disableReset: isDisabled,
        isUpdate,
        lastGroup: filters?.filterModule?.group?.map((item) => ({ ...item })),
      },
    })));

    return isDisabled;
  }

  private compareFilterNotificationsAssigneeFilter = () => {
    const { currentState: { filters } } = this.store.getState().commandCenter;
    let isDisabled = true;
    let isUpdate = false;

    filters?.filterAssignee?.group?.forEach((itemAssignee, indexPriority) => {
      if (itemAssignee.checked) {
        isDisabled = false;
      }
      if (filters?.filterAssignee?.lastGroup?.[indexPriority].name !== itemAssignee.name
        || filters?.filterAssignee.lastGroup[indexPriority].value !== itemAssignee.value
        || filters?.filterAssignee.lastGroup[indexPriority].checked !== itemAssignee.checked) {
        isUpdate = true;
      }
    });

    this.dispatch(R.actions.commandCenter.setTasksFilters(NotificationViewedFiltersDTM.fromPlain({
      ...filters,
      filterAssignee: {
        ...filters?.filterAssignee,
        disableReset: isDisabled,
        isUpdate,
        lastGroup: filters?.filterAssignee?.group?.map((item) => ({ ...item })),
      },
    })));

    return isDisabled;
  }

  private compareFilterTasksDueDates = () => {
    const { currentState: { filters } } = this.store.getState().commandCenter;
    let isDisabled = true;
    let isUpdate = false;

    if (filters?.filterReceivedDates?.dates?.earliestDate && filters?.filterReceivedDates?.dates?.latestDate) {
      isDisabled = false;
      isUpdate = true;
    }

    this.dispatch(R.actions.commandCenter.setTasksFilters(NotificationViewedFiltersDTM.fromPlain({
      ...filters,
      filterReceivedDates: NotificationTaskFilterReceivedDatesDTM.fromPlain({
        ...filters?.filterReceivedDates,
        disableReset: isDisabled,
        isUpdate,
      }),
    })));

    return isDisabled;
  }

  private compareFilterTasksCompletionDates = () => {
    const { currentState: { filters } } = this.store.getState().commandCenter;
    let isDisabled = true;
    let isUpdate = false;

    if (filters?.filterCompletionDates?.dates?.earliestDate && filters?.filterCompletionDates?.dates?.latestDate) {
      isDisabled = false;
      isUpdate = true;
    }

    this.dispatch(R.actions.commandCenter.setTasksFilters(NotificationViewedFiltersDTM.fromPlain({
      ...filters,
      filterCompletionDates: NotificationTaskFilterReceivedDatesDTM.fromPlain({
        ...filters?.filterCompletionDates,
        disableReset: isDisabled,
        isUpdate,
      }),
    })));

    return isDisabled;
  }

  private compareFilterTasksModuleFilter = () => {
    const { currentState: { filters } } = this.store.getState().commandCenter;
    let isDisabled = true;
    let isUpdate = false;

    filters?.filterModule?.group?.forEach((itemModule, indexModule) => {
      if (itemModule.checked) {
        isDisabled = false;
      }
      if (filters?.filterModule?.lastGroup?.[indexModule]?.name !== itemModule.name
        || filters?.filterModule.lastGroup[indexModule]?.value !== itemModule.value
        || filters?.filterModule.lastGroup[indexModule]?.checked !== itemModule.checked) {
        isUpdate = true;
      }
    });

    this.dispatch(R.actions.commandCenter.setTasksFilters(NotificationViewedFiltersDTM.fromPlain({
      ...filters,
      filterModule: {
        ...filters?.filterModule,
        disableReset: isDisabled,
        isUpdate,
        lastGroup: filters?.filterModule?.group?.map((item) => ({ ...item })),
      },
    })));

    return isDisabled;
  }

  private compareFilterTasksAssigneeFilter = () => {
    const { currentState: { filters } } = this.store.getState().commandCenter;
    let isDisabled = true;
    let isUpdate = false;

    filters?.filterAssignee?.group?.forEach((itemFilter, indexFilter) => {
      if (itemFilter.checked) {
        isDisabled = false;
      }
      if (filters?.filterAssignee?.lastGroup?.[indexFilter]?.name !== itemFilter.name
        || filters?.filterAssignee.lastGroup[indexFilter]?.value !== itemFilter.value
        || filters?.filterAssignee.lastGroup[indexFilter]?.checked !== itemFilter.checked) {
        isUpdate = true;
      }
    });

    this.dispatch(R.actions.commandCenter.setTasksFilters(NotificationViewedFiltersDTM.fromPlain({
      ...filters,
      filterAssignee: {
        ...filters?.filterAssignee,
        disableReset: isDisabled,
        isUpdate,
        lastGroup: filters?.filterAssignee?.group?.map((item) => ({ ...item })),
      },
    })));

    return isDisabled;
  }

  private compareFilterTasksAssigneeOrganizationFilter = () => {
    const { currentState: { filters } } = this.store.getState().commandCenter;
    let isDisabled = true;
    let isUpdate = false;

    filters?.filterAssigneeOrganization?.group?.forEach((itemFilter, indexFilter) => {
      if (itemFilter.checked) {
        isDisabled = false;
      }
      if (filters?.filterAssigneeOrganization?.lastGroup?.[indexFilter]?.name !== itemFilter.name
        || filters?.filterAssigneeOrganization.lastGroup[indexFilter]?.value !== itemFilter.value
        || filters?.filterAssigneeOrganization.lastGroup[indexFilter]?.checked !== itemFilter.checked) {
        isUpdate = true;
      }
    });

    this.dispatch(R.actions.commandCenter.setTasksFilters(NotificationViewedFiltersDTM.fromPlain({
      ...filters,
      filterAssigneeOrganization: {
        ...filters?.filterAssigneeOrganization,
        disableReset: isDisabled,
        isUpdate,
        lastGroup: filters?.filterAssigneeOrganization?.group?.map((item) => ({ ...item })),
      },
    })));

    return isDisabled;
  }

  private compareFilterTasksObjectTreeFilter = () => {
    const { currentState: { filters } } = this.store.getState().commandCenter;
    let isDisabled = true;
    let isUpdate = false;

    filters?.filterTreeDataObject?.group?.forEach((itemFilter, indexFilter) => {
      if (itemFilter.checked) {
        isDisabled = false;
      }
      if (filters?.filterTreeDataObject?.lastGroup?.[indexFilter]?.name !== itemFilter.name
        || filters?.filterTreeDataObject.lastGroup[indexFilter]?.value !== itemFilter.value
        || filters?.filterTreeDataObject.lastGroup[indexFilter]?.checked !== itemFilter.checked) {
        isUpdate = true;
      }
    });

    this.dispatch(R.actions.commandCenter.setTasksFilters(NotificationViewedFiltersDTM.fromPlain({
      ...filters,
      filterTreeDataObject: {
        ...filters?.filterTreeDataObject,
        disableReset: isDisabled,
        isUpdate,
        lastGroup: filters?.filterTreeDataObject?.group?.map((item) => ({ ...item })),
      },
    })));

    return isDisabled;
  }
}
