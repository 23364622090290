import styled from 'styled-components';
import AntLayout from 'antd/es/layout';

import themesColors from 'app-wrapper/view/themes/themesColors';
import { Button } from 'app-wrapper/view/components';
import { divFlex } from 'app-wrapper/view/themes/mixin';

export const RatesUploadLayout = styled(AntLayout)`
  display: flex;
  flex-direction: column;

  margin: 0;
  height: calc(100vh - 48px);
  overflow: hidden;
  background: ${({ theme }) => theme?.themesColors?.lightGrayBorder};
`;

export const RatesUploadHeader = styled(AntLayout.Header)`
  ${divFlex}

  margin: 0;
  padding: 14px 24px;
  height: 56px;
  box-shadow: inset 0px -1px 0px #EAEEF8;
  background-color: ${themesColors.neutralBranding1};
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
`;

export const InnerHeader = styled.div`
  max-width: 1660px;
  width: 100%;
  margin: 0 auto;
`;

export const RatesUploadClearWrap = styled.div.attrs({
  'data-class': 'RatesUploadClearWrap',
})`
  ${divFlex}

  margin-right: 8px;
  margin-left: auto;

  button {
    color: ${({ theme }) => theme?.themesColors?.neutralBranding7};
  }
  button > span:nth-child(2) {
    color: ${({ theme }) => theme?.themesColors?.neutralBranding10};
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }

  &:hover button > span:nth-child(2) {
    color: inherit;
  }

  .ant-tooltip-arrow {
    right: -13px;
  }
`;

export const RatesUploadButton = styled(Button).attrs({
  'data-class': 'RatesUploadButton',
})`
  margin-right: 8px;
  margin-left: auto;
`;

export const RatesUploadContent = styled(AntLayout)`
  display: flex;
  flex-flow: column nowrap;
  gap: 8px;
  margin: 24px auto;
  max-width: 1660px;
  height: 100%;
  width: calc(100% - 48px);
  background: ${({ theme }) => theme?.themesColors?.lightGrayBorder};
`;
