import React from 'react';
import { observer } from 'mobx-react-lite';

import { UC as monetaryUC } from 'monetary/controllers';

import { UC } from 'shipment-operations/controllers';
import { useMobxStore } from 'app-wrapper/mobxStores';

import { ShipmentAdditionalServices } from './ShipmentAdditionalServices.component';

const ShipmentAdditionalServicesContainer = observer(() => {
  const { shipmentServices } = useMobxStore();
  const { isLoading, services } = shipmentServices.state;

  return (
    <ShipmentAdditionalServices
      init={UC.shipmentServices.initServices}
      addServices={monetaryUC.AdditionalServices.openShipmentAdditionalServicesDrawer}
      isLoading={isLoading}
      services={services}
    />
  );
});

export { ShipmentAdditionalServicesContainer as ShipmentAdditionalServices };
