import styled from 'styled-components';

import Typography from 'antd/es/typography';

export const CommodityRow = styled.div`
  display: grid;
  grid-template-columns: 255px 255px 95.5px 95.5px 95.5px 95.5px 105px 32px;
  gap: 4px;
  margin-bottom: 8px;

  .ant-select {
    height: 32px;
    min-height: 32px;
  }

  .ant-select-selector {
    height: 32px;
  }
`;

export const CommodityRowNoHazmat = styled(CommodityRow)`
  grid-template-columns: 210px 210px 210px 122.75px 122.75px 122.75px 32px;
`;

export const HazmatSection = styled.div`
  display: flex;
  flex-direction: column;
  margin: 8px 0 16px 0;
  width: 100%;
  padding: 16px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
`;

export const HazmatSectionRow = styled.div`
  display: grid;
  grid-template-columns: 100px 100px 180px 314.5px 314.5px;
  gap: 4px;
`;

export const HazmatSectionText = styled(Typography)`
  font-size: 12px;
  line-height: 20px;
`;
