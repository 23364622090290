import styled from 'styled-components';

import { FlexCol, SpinnerWrap as BaseSpinnerWrap } from 'app-wrapper/view/guideline';

export const Page = styled(FlexCol)`
  width: 100%;
`;

export const SpinnerWrap = styled(BaseSpinnerWrap)`
  height: 800px;
  min-width: 100%;
`;

export const Content = styled(FlexCol)`
  gap: 8px;
`;
