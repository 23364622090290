import styled from 'styled-components';
import Tabs from 'antd/es/tabs';

export const SubPageTabs = styled(Tabs).attrs({
  'data-class': 'SubPageTabs',
})`
  overflow: initial;
  /* height: 100%; */
  margin-bottom: 8px;
  
  .ant-tabs-content-holder {
    background: none;
  }

  & > div {
    margin-bottom: 0px !important;
  }

  .ant-tabs-tab:hover {
    color: unset; 
  }
`;
