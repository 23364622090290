import React, { FC, useMemo } from 'react';

import {
  ARQItemSubCardDescriptionsContainer,
  ARQItemSubCardDescriptionsContainerTitle,
  ARQItemSubCardDescriptionsContainerContent,
  ARQItemSubCardDescriptionsContainerFooter,
  ARQItemSubCardDescriptionsContainerSpanClip,
} from './ARQItemSubCardDescriptions.styled';

interface IARQItemSubCardDescriptionsProps {
  title?: string | null;
  content?: string | React.ReactNode;
  footer?: string[];
  isVerticalCenter?: boolean;
  marginLeft?: string;
}

export const ARQItemSubCardDescriptions: FC<IARQItemSubCardDescriptionsProps> = ({
  title,
  content,
  footer,
  isVerticalCenter,
  marginLeft,
}) => {
  const footerComponent = useMemo(() => {
    if (Number(footer?.length) > 0) {
      return `${footer?.join(', ')}`;
    }

    return null;
  }, [footer]);

  return (
    <ARQItemSubCardDescriptionsContainer marginLeft={marginLeft} isVerticalCenter={isVerticalCenter}>
      {title === null
        ? ''
        : (
          <ARQItemSubCardDescriptionsContainerTitle>
            <ARQItemSubCardDescriptionsContainerSpanClip>
              {title || ''}
            </ARQItemSubCardDescriptionsContainerSpanClip>
          </ARQItemSubCardDescriptionsContainerTitle>
        )}
      <ARQItemSubCardDescriptionsContainerContent>
        <ARQItemSubCardDescriptionsContainerSpanClip>
          {content || ''}
        </ARQItemSubCardDescriptionsContainerSpanClip>
      </ARQItemSubCardDescriptionsContainerContent>
      <ARQItemSubCardDescriptionsContainerFooter>
        <ARQItemSubCardDescriptionsContainerSpanClip>
          {footerComponent || ''}
        </ARQItemSubCardDescriptionsContainerSpanClip>
      </ARQItemSubCardDescriptionsContainerFooter>
    </ARQItemSubCardDescriptionsContainer>
  );
};
