import styled from 'styled-components';

import { divFlex } from 'app-wrapper/view/themes/mixin';

export const ModalBackdrop = styled.div.attrs({
  'data-class': 'ModalBackdrop',
})`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0,0,0,0.7);
  z-index: 990;
`;

export const Overlay = styled.div.attrs({
  'data-class': 'Modal_Overlay',
})`
  ${divFlex}
  justify-content: center;
  align-items: center;

  background: rgba(0, 0, 0, 0.6);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  padding: 20px;
`;

export const ModalDialog = styled.div.attrs({
  'data-class': 'Modal_Dialog',
})`
  ${divFlex}
  width: 416px;
  height: 294px;
  justify-content: center;
  align-items: center;

  background: #fefefe;
  border: #333333 solid 0px;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  z-index: 11;
  box-shadow: 0 5px 10px rgb(0 0 0 / 30%);
  transform: translate(0, -500%);
  transition: transform 0.3s ease-out;
  transform: translate(0, 0);
`;

export const ModalHeader = styled.div.attrs({
  'data-class': 'Modal_Header',
})`
  ${divFlex}

  width: 100%;
  height: 56px;
  padding: 8px 24px;
  background: #FFFFFF;
  box-shadow: inset 0px -1px 0px #F4F5F8;
`;

export const ModalContent = styled.div.attrs({
  'data-class': 'Modal_Content',
})`
  ${divFlex}

  width: 100%;
  height: 100%;
  overflow: hidden;
`;

export const ModalFooter = styled.div.attrs({
  'data-class': 'Modal_Footer',
})`
  ${divFlex}

  width: 100%;
  align-items: center;
  justify-content: end;
  height: 64px;
  padding: 4px 24px 0px;
  background: #FFFFFF;
  box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px rgba(0, 0, 0, 0.08), 0px 9px 28px 8px rgba(0, 0, 0, 0.05);
  z-index: 1;
`;
