import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { R } from 'user-management/repository';
import { UC } from 'user-management/controllers';

import { OrganizationDataComponent } from './OrganizationData.component';

const OrganizationDataContainer: FC = () => {
  const organization = useSelector(R.selectors.userOrganizationData.getUserOrganization);
  const isAdmin = useSelector(R.selectors.userOrganizationData.isAdmin);

  return (
    <OrganizationDataComponent
      organization={organization}
      isAdmin={isAdmin}
      onEditProfileOrganization={UC.organizationProfile.openEditProfileOrganization}
    />
  );
};

export { OrganizationDataContainer as OrganizationData };
