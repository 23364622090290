import React, { ReactElement } from 'react';

import { BaseUseCase } from 'app-wrapper/usecases/Base.useCase';
import {
  RailBillingDrawer,
  VGMTaskDrawer,
  CreateContractDrawer,
  EditContractDrawer,
  ShipmentCostChangesDrawer,
} from 'app-wrapper/view/drawers';
import {
  MaerskChanges,
  BookingConfirmation,
  ShipmentMismatches,
  ShipmentChanges,
  BLMismatches,
  ServiceDocumentUpload,
  AddRates,
  ApproveEMBLDrawer,
  ChargesCostChanges,
} from 'shipment-operations/view/drawers';
import { SalesRepresentativeDrawer } from 'user-management/view/drawers';

import { R } from 'shipment-operations/repository';
import { BookingAddAdditionalServicesPage, RFQAddAdditionalServicesPage, ShipmentAddAdditionalServicesPage } from 'user-management/view/pages/Account/components/AccountDrawer/components';

export class DrawersUseCase extends BaseUseCase {
  private openDrawer(drawer: ReactElement) {
    this.mobxStores.drawerStore.addDrawer(drawer);
  }

  public closeDrawer() {
    this.mobxStores.drawerStore.closeDrawer();
  }

  public closeDrawerById(id: string) {
    this.mobxStores.drawerStore.closeDrawerById(id);
  }

  public openRailBillingDrawer(shipmentId: string) {
    this.openDrawer(<RailBillingDrawer shipmentId={shipmentId} />);
  }

  public openShipmentCostChangesDrawer(taskId: string) {
    this.openDrawer(<ShipmentCostChangesDrawer taskId={taskId} />);
  }

  public openVGMTaskDrawer(shipmentId: string) {
    this.openDrawer(<VGMTaskDrawer shipmentId={shipmentId} />);
  }

  public openMaerskChanges() {
    this.openDrawer(<MaerskChanges />);
  }

  public openCreateContractDrawer() {
    this.openDrawer(<CreateContractDrawer />);
  }

  public openApproveEMBLDrawer() {
    this.openDrawer(<ApproveEMBLDrawer />);
  }

  public openEditContractDrawer() {
    this.openDrawer(<EditContractDrawer />);
  }

  public openSalesRepresentativeDrawer() {
    this.openDrawer(<SalesRepresentativeDrawer />);
  }

  public openBookingConfirmation() {
    this.openDrawer(<BookingConfirmation />);
  }

  public openShipmentMismatches() {
    this.openDrawer(<ShipmentMismatches />);
  }

  public openShipmentServiceDocumentUpload() {
    this.openDrawer(<ServiceDocumentUpload />);
  }

  public openShipmentChanges(withValidate = false) {
    this.openDrawer(<ShipmentChanges />);
    if (withValidate) {
      this.controller.dispatch(R.actions.shipmentChanges.setValidationDrawer(true));
    } else {
      this.controller.dispatch(R.actions.shipmentChanges.setValidationDrawer(false));
    }
  }

  public openBLMismatches() {
    this.openDrawer(<BLMismatches />);
  }

  public openAddAdditionalServicesDrawer() {
    this.openDrawer(<RFQAddAdditionalServicesPage />);
  }

  public openAddAdditionalServicesBookingDrawer() {
    this.openDrawer(<BookingAddAdditionalServicesPage />);
  }

  public openAddAdditionalServicesShipmentDrawer() {
    this.openDrawer(<ShipmentAddAdditionalServicesPage />);
  }

  public openAddRates(typeDrawer: string) {
    this.openDrawer(<AddRates typeDrawer={typeDrawer} />);
  }

  public openChargesCostChangesDrawer() {
    this.openDrawer(<ChargesCostChanges />);
  }
}
