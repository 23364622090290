import React, { FC, memo } from 'react';
import { useSelector } from 'react-redux';

import { R } from 'user-management/repository';
import { UC } from 'user-management/controllers';

import { AACContactEditContactPersonComponent } from './AACContactEditContactPerson.component';

const AACContactEditContactPersonContainer: FC = () => {
  const contactBookUpdate = useSelector(R.selectors.contactBook.getContactBookUpdate);

  return (
    <AACContactEditContactPersonComponent
      contactPersonForm={contactBookUpdate.contactPersonForm}
      isDisabledCancelBtn={false}
      isDisabledSaveBtn={false}
      isDisabledRemoveBtn={false}
      onCancelClick={UC.organizationProfile.closeAddressesAndContactsAddAddressDrawer}
      onRemoveClick={UC.contactBook.onRemoveAACContactEditContactPersonDrawer}
      onSaveClick={UC.contactBook.onSaveAACContactEditContactPersonDrawer}
      onChangeAddressIsPrimary={UC.contactBook.onChangePrimaryAACContactPerson}
      onChangeContactPerson={UC.contactBook.onChangeFullNameAACContactPerson}
      onBlurContactPerson={UC.contactBook.onBlurFullNameAACContactPerson}
      onFocusContactPerson={UC.contactBook.onFocusFullNameAACContactPerson}
      onChangeAdditionalPhone={UC.contactBook.onChangePhone2AACContactPerson}
      onBlurAdditionalPhone={UC.contactBook.onBlurPhone2AACContactPerson}
      onFocusAdditionalPhone={UC.contactBook.onFocusPhone2AACContactPerson}
      onChangeEmail={UC.contactBook.onChangeEmailAACContactPerson}
      onBlurEmail={UC.contactBook.onBlurEmailAACContactPerson}
      onFocusEmail={UC.contactBook.onFocusEmailAACContactPerson}
      onChangePhone={UC.contactBook.onChangePhoneAACContactPerson}
      onBlurPhone={UC.contactBook.onBlurPhoneAACContactPerson}
      onFocusPhone={UC.contactBook.onFocusPhoneAACContactPerson}
    />
  );
};

const AACContactEditContactPersonContainerCached = memo(AACContactEditContactPersonContainer);

export { AACContactEditContactPersonContainerCached as AACContactEditContactPersonPage };
