import React, { FC, memo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { R } from 'user-management/repository';
import { UC } from 'user-management/controllers';

import { AACContactAddContactPersonComponent } from './AACContactAddContactPerson.component';

const AACContactAddContactPersonContainer: FC = () => {
  const contactBookUpdate = useSelector(R.selectors.contactBook.getContactBookUpdate);
  const { t } = useTranslation();

  return (
    <AACContactAddContactPersonComponent
      contactPersonForm={contactBookUpdate.contactPersonForm}
      firstBlockName={`${t('Full Name')} *`}
      isDisabledCancelBtn={false}
      isDisabledSaveBtn={false}
      onCancelClick={UC.contactBook.closeAACContactAddContactPersonDrawer}
      onSaveClick={UC.contactBook.onSaveAACContactAddContactPersonDrawer}
      onChangeAddressIsPrimary={UC.contactBook.onChangePrimaryAACContactPerson}
      onChangeContactPerson={UC.contactBook.onChangeFullNameAACContactPerson}
      onBlurContactPerson={UC.contactBook.onBlurFullNameAACContactPerson}
      onFocusContactPerson={UC.contactBook.onFocusFullNameAACContactPerson}
      onChangeAdditionalPhone={UC.contactBook.onChangePhone2AACContactPerson}
      onBlurAdditionalPhone={UC.contactBook.onBlurPhone2AACContactPerson}
      onFocusAdditionalPhone={UC.contactBook.onFocusPhone2AACContactPerson}
      onChangeEmail={UC.contactBook.onChangeEmailAACContactPerson}
      onBlurEmail={UC.contactBook.onBlurEmailAACContactPerson}
      onFocusEmail={UC.contactBook.onFocusEmailAACContactPerson}
      onChangePhone={UC.contactBook.onChangePhoneAACContactPerson}
      onBlurPhone={UC.contactBook.onBlurPhoneAACContactPerson}
      onFocusPhone={UC.contactBook.onFocusPhoneAACContactPerson}
    />
  );
};

const AACContactAddContactPersonContainerCached = memo(AACContactAddContactPersonContainer);

export { AACContactAddContactPersonContainerCached as AACContactAddContactPersonPage };
