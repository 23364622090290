export const compareMinMax = (a: string, b: string) => {
  const matchA = a.match(/\d+/);
  const matchB = b.match(/\d+/);

  if (matchA && matchB) {
    const numA = parseInt(matchA[0], 10);
    const numB = parseInt(matchB[0], 10);
    return numA - numB;
  }
  return 0;
};
