import React, { FC, ReactNode } from 'react';

import { FlexCol } from 'app-wrapper/view/guideline';

import {
  Wrap,
  Icon,
  Label,
  Value,
} from './ValueBlockWithIcon.styled';

interface IValueBlockWithIconProps {
  icon: ReactNode;
  label: string;
  value: ReactNode;
}

export const ValueBlockWithIcon: FC<IValueBlockWithIconProps> = ({
  icon,
  label,
  value,
}) => (
  <Wrap>
    <Icon>
      {icon}
    </Icon>

    <FlexCol>
      <Label>
        {label}
      </Label>

      <Value>
        {value}
      </Value>
    </FlexCol>
  </Wrap>
);
