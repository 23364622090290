import styled from 'styled-components';

import {
  StyledTable,
} from 'shipment-operations/view/components/CustomTable/CustomTable.styled';

export const FooterStyled = styled(StyledTable)`
  .ant-table-cell {
    background: #F9F9FB;
    padding-top: 18px !important;
    padding-bottom: 18px !important;
  }
  .ant-table-tbody > tr.ant-table-row:hover > td {
    background: #F9F9FB !important;
  }
`;

export const StyledCell = styled.span`
  font-weight: 500;
  color: #202C3C;
`;
