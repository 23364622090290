import React, { FC, useCallback } from 'react';
import Collapse from 'antd/es/collapse';
import { useTranslation } from 'react-i18next';

import { StyledExpandIcon } from 'shipment-operations/view/components';

import { useToggleCollapse } from 'shipment-operations/view/hooks/useToggleCollapse';

import { ContainerViewDTM } from 'shipment-operations/models/dtm';

import {
  ContainerTitleWrapper, ContainerType, MainPanelSum,
  PanelTitleWrapper, StyledPanel,
} from './ExpandPanel.styled';

interface IExpandPanelProps {
  container: ContainerViewDTM
  sum?: number
}

const ExpandPanelComponent: FC<IExpandPanelProps> = ({
  container, sum, children,
}) => {
  const { t } = useTranslation();
  const { openedKeys, onToggleCollapse } = useToggleCollapse();
  const onToggle = useCallback((key) => () => onToggleCollapse(key), [onToggleCollapse]);

  return (
    <Collapse ghost expandIcon={StyledExpandIcon} onChange={onToggle(container.id)} key={container.id}>
      <StyledPanel
        key={container.id}
        header={(
          <PanelTitleWrapper active={openedKeys.includes(container.id)}>
            <ContainerTitleWrapper>
              <ContainerType
                active={openedKeys.includes(container.id)}
              >
                {container.type} &apos;
              </ContainerType>
              <span>{container.number ? container.number : t('Number pending entry')}</span>
            </ContainerTitleWrapper>
            {!!sum && (
              <MainPanelSum active={openedKeys.includes(container.id)}>
                $ {sum.toFixed(2)}
              </MainPanelSum>
            )}
          </PanelTitleWrapper>
        )}
      >
        {children}
      </StyledPanel>
    </Collapse>
  );
};

export { ExpandPanelComponent };
