import styled from 'styled-components';

import { ShipmentDetailsActionPanel } from 'shipment-operations/view/components';

export const Wrapper = styled(ShipmentDetailsActionPanel).attrs({
  'data-class': 'SDCShipmentDetailsLayout',
})`
  position: sticky;
  width: 100%;
  margin-left: 0;
  margin-right: 0;
`;
