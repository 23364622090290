import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { ShipmentDrawerContent } from 'shipment-operations/constants';

import { UC } from 'shipment-operations/controllers';
import { R } from 'shipment-operations/repository';

import { FooterComponent } from 'shipment-operations/view/components/ControlChargeTransportation';

const FooterContainer = () => {
  const { t } = useTranslation();
  const isLoading = useSelector(R.selectors.shipmentActiveCharge.getActiveChargeIsLoadingCreate);
  const mode = useSelector(R.selectors.shipmentDrawer.getContentType);
  const isDocumentUploading = useSelector(R.selectors.shipmentActiveCharge.getIsDocumentUploading);

  return (
    <FooterComponent
      onCancel={UC.shipmentDrawer.closeDrawer}
      onAction={mode === ShipmentDrawerContent.SHIPMENT_TRANSPORTATION_ADD_CHARGE ? UC.shipmentActiveCharge.addCharge : UC.shipmentActiveCharge.editCharge}
      isLoading={isLoading}
      actionName={mode === ShipmentDrawerContent.SHIPMENT_TRANSPORTATION_ADD_CHARGE ? t('addChargeMenu.add') : t('addChargeMenu.save')}
      isDisabled={isDocumentUploading}
    />
  );
};

export { FooterContainer as Footer };
