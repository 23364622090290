import { RootState } from 'app-wrapper/store';
import { AccountDepartmentDTM, OrganizationMemberDTM } from 'user-management/models/dtm';

const getAccountDepartments = (state: RootState) => (
  state.accountDepartments
);

const getAccountDepartmentsDefault = (state: RootState) => (
  state.accountDepartments.defaultState
);

const getAccountDepartmentsDefaultDepartmentById = (state: RootState) => (
  state.accountDepartments.defaultState?.departmentById && AccountDepartmentDTM.fromPlain({
    ...state.accountDepartments.defaultState?.departmentById,
  })
);

const getAccountDepartmentsDefaultMembersList = (state: RootState) => (
  state.accountDepartments.defaultState?.membersList?.map((item) => OrganizationMemberDTM.fromPlain(item))
);

const getAccountDepartmentsUpdateDepartmentById = (state: RootState) => (
  state.accountDepartments.updateState?.departmentById && AccountDepartmentDTM.fromPlain({
    ...state.accountDepartments.updateState?.departmentById,
  })
);

const getAccountDepartmentsDefaultDepartmentList = (state: RootState) => (
  state.accountDepartments.defaultState?.departmentList?.map((item) => AccountDepartmentDTM.fromPlain({
    ...item,
  }))
);

const getAccountDepartmentsUpdate = (state: RootState) => (
  state.accountDepartments.updateState
);

export const accountDepartmentsSelectors = {
  getAccountDepartments,
  getAccountDepartmentsDefault,
  getAccountDepartmentsDefaultDepartmentById,
  getAccountDepartmentsDefaultMembersList,
  getAccountDepartmentsUpdateDepartmentById,
  getAccountDepartmentsDefaultDepartmentList,
  getAccountDepartmentsUpdate,
};
