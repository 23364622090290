import { useSelector } from 'react-redux';
import { R } from 'authentication/repository';
import { usePostHog } from 'posthog-js/react';
import { useEffect } from 'react';

export const usePosthogAuth = () => {
  const {
    email, firstName, lastName, userId,
  } = useSelector(R.selectors.auth.getUser);

  const posthog = usePostHog();

  useEffect(() => {
    // @ts-ignore only for e2e test
    if (!window.Cypress) {
      posthog?.identify(userId, {
        email,
        name: `${firstName} ${lastName}`,
      });
    }
  }, [userId]);
};
