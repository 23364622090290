import styled from 'styled-components';
import DatePicker from 'antd/es/date-picker';
import Select from 'antd/es/select';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #F4F5F8;
  height: 100%;
`;

export const ElemWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 144px;
  width: 100%;
  position: relative;
`;

export const Main = styled.div`
  width: 100%;
  height: 100%;
  background-color: white;
  border-radius: 4px;
  border: 1px solid #EAEEF8;
  display: flex;
`;

export const Dot = styled.div`
  width: 3px;
  height: 3px;
  border-radius: 50%;
  background-color: #73819B;
  margin-top: 10px;
`;

export const Point = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  border: 1.5px solid #202C3C;
`;

export const RouteWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 5%;
  height: 100%;
`;

export const LocationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  width: 60%;
  height: 100%;
  margin-right: 16px;
`;

export const InputWrapper = styled.div`
  width: 48%;
`;

export const Label = styled.span`
  font-size: 12px;
  color: #202C3C;
  margin-right: 8px;
`;

export const DashedBorder = styled.div`
  border: 1px dashed #EAEEF8;
  margin: 34px 0 12px 0;
`;

export const ModeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  width: 30%;
  height: 100%;
  margin-left: 16px;
`;

export const VoyageWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const Divider = styled.div`
  width: 98%;
  border-bottom: 1px dashed #FFFFFF;
  margin-top: 5px;
  margin-left: 2%;
  margin-bottom: 5px;
`;

export const StyledDatePicker = styled(DatePicker)`
  display: block;
`;

export const StyledSelect = styled(Select)`
  display: block;
`;

export const FooterWrapper = styled.div`
  position: absolute;
  left: -24px;
  bottom: -24px;
  right: -24px;
  height: 64px;
  background-color: white;
  box-shadow: 0 9px 28px 8px #0000000D;
  display: flex;
  padding: 12px;
  align-items: center;
  justify-content: end;
`;

export const CutoffWrapper = styled.div`
  display: flex;
  width: 100%;
  background-color: white;
  border-radius: 4px;
  border: 1px solid #EAEEF8;
  padding: 20px 16px;
  margin-bottom: 10px;
  align-items: center;
  justify-content: space-between;
`;

export const CutoffTitle = styled.span`
  display: block;
  font-weight: 500;
  font-size: 16px;
  color: #202C3C;
`;

export const CutoffTime = styled.div`
  display: flex;
`;

export const CutoffElem = styled.div`
  display: flex;
  align-items: center;
  margin-left: 20px;
`;
