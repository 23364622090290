import { responsive } from 'app-wrapper/view/themes/mixin';
import { css } from 'styled-components';

export const inputDefaultStyle = css`
  font-size: 12px;
  font-weight: 400;
  color: ${({ theme }) => theme?.themesColors?.neutralBranding10};
  min-height: 32px;

  ${responsive.forFullMedia`
    min-height: 40px;
  `}

  .ant-input::placeholder {
    color: ${({ theme }) => theme?.themesColors?.neutralBranding10};
    opacity: 1;
  }
`;
