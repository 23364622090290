import { ControllerParams } from 'proto/BaseController';
import { ShipmentTransportationOverviewController } from './ShipmentTransportationOverview';
import { CargoController } from './CargoController';
import { CreateInvoiceChargesController } from './CreateInvoiceCharges';
import { CreditNoteController } from './CreditNote';
import { BillOfLadingController } from './BillOfLadingController';
import { ShipmentBillingController } from './ShipmentBilling';
import { ShipmentBillingInvoiceController } from './ShipmentBillingInvoice';
import { ShipmentContainersController } from './ShipmentContainersController';
import { ShipmentController } from './Shipment';
import { ShipmentDocumentsController } from './ShipmentDocuments';
import { ShipmentDocumentAdditionalController } from './ShipmentDocumentAdditional';
import { ShipmentDocumentsSeaworthyCertificatesController } from './ShipmentDocumentsSeaworthyCertificates';
import { ShipmentDrawerController } from './ShipmentDrawer';
import { ShipmentListController } from './ShipmentListController';
import { ShipmentTransportationChargesController } from './ShipmentTransportationCharges';
import { ShipmentTransportationLocationController } from './ShipmentTransportationLocation';
import { ShippingPartiesController } from './ShippingPartiesController';
import { ShareInvoiceController } from './ShareInvoice';
import { TemperatureControlController } from './TemperatureControlController';
import { TransportationOverviewController } from './TransportationOverviewController';
import { ShipmentTrackerController } from './ShipmentTracker';
import { ExportClearanceController } from './ExportClearance';
import { OverviewController } from './OverviewController';
import { MakePaymentController } from './MakePayment';
import { ShipmentChargesController } from './ShipmentCharges';
import { BookingWizardController, BookingWizardDrawerController } from './BookingWizardController';
import { ShipmentAccessorialController } from './ShipmentAccessorialController';
import { ShipmentFreeTimeController } from './ShipmentFreeTime';
import { ManualBookingWizardController } from './ManualBookingWizard';
import { RollShipmentWizardController } from './RollShipmentWizard';
import { ShipmentDocumentsAllController } from './ShipmentDocumentsAll';
import { ShipmentActiveChargeController } from './ShipmentActiveCharge';
import { NRAAgreementController } from './NRAAgreement';
import { ShipmentChangesController } from './ShipmentChanges';
import { ShipmentPeopleController } from './ShipmentPeople';
import { AdditionalServicesController } from './AdditionalServices';
import { AddRatesController } from './AddRatesController';

type UseCases = {
  cargo: CargoController
  overview: OverviewController
  exportClearance: ExportClearanceController
  createInvoiceCharges: CreateInvoiceChargesController
  creditNote: CreditNoteController
  billOfLading: BillOfLadingController
  shipment: ShipmentController
  shipmentBillingController: ShipmentBillingController
  shipmentBillingInvoice: ShipmentBillingInvoiceController
  shipmentContainers: ShipmentContainersController
  shipmentDocuments: ShipmentDocumentsController
  shipmentDocumentsAll: ShipmentDocumentsAllController
  shipmentDocumentAdditional: ShipmentDocumentAdditionalController
  shipmentDocumentsSeaworthyCertificates: ShipmentDocumentsSeaworthyCertificatesController
  shipmentDrawer: ShipmentDrawerController
  shipmentList: ShipmentListController
  shipmentTransportationCharges: ShipmentTransportationChargesController
  shipmentTransportationLocation: ShipmentTransportationLocationController
  shipmentTransportationOverview: ShipmentTransportationOverviewController
  shippingParties: ShippingPartiesController
  shareInvoice: ShareInvoiceController
  temperatureControl: TemperatureControlController
  transportationOverview: TransportationOverviewController
  shipmentTracker: ShipmentTrackerController
  makePayment: MakePaymentController
  shipmentCharges: ShipmentChargesController
  bookingWizard: BookingWizardController
  bookingWizardDrawer: BookingWizardDrawerController
  shipmentAccessorial: ShipmentAccessorialController
  shipmentFreeTime: ShipmentFreeTimeController
  manualBookingWizard: ManualBookingWizardController
  rollShipment: RollShipmentWizardController
  shipmentActiveCharge: ShipmentActiveChargeController
  nraAgreement: NRAAgreementController
  shipmentChanges: ShipmentChangesController
  shipmentPeople: ShipmentPeopleController,
  shipmentServices: AdditionalServicesController,
  addRates: AddRatesController,
}

// @ts-ignore
export const UC: UseCases = {};

export const initUC = (controllerParams: ControllerParams) => {
  UC.cargo = new CargoController(controllerParams);
  UC.overview = new OverviewController(controllerParams);
  UC.exportClearance = new ExportClearanceController(controllerParams);
  UC.createInvoiceCharges = new CreateInvoiceChargesController(controllerParams);
  UC.creditNote = new CreditNoteController(controllerParams);
  UC.billOfLading = new BillOfLadingController(controllerParams);
  UC.shipment = new ShipmentController(controllerParams);
  UC.shipmentBillingController = new ShipmentBillingController(controllerParams);
  UC.shipmentBillingInvoice = new ShipmentBillingInvoiceController(controllerParams);
  UC.shipmentContainers = new ShipmentContainersController(controllerParams);
  UC.shipmentDocuments = new ShipmentDocumentsController(controllerParams);
  UC.shipmentDocumentsAll = new ShipmentDocumentsAllController(controllerParams);
  UC.shipmentDocumentAdditional = new ShipmentDocumentAdditionalController(controllerParams);
  UC.shipmentDocumentsSeaworthyCertificates = new ShipmentDocumentsSeaworthyCertificatesController(controllerParams);
  UC.shipmentDrawer = new ShipmentDrawerController(controllerParams);
  UC.shipmentList = new ShipmentListController(controllerParams);
  UC.shipmentTransportationCharges = new ShipmentTransportationChargesController(controllerParams);
  UC.shipmentTransportationLocation = new ShipmentTransportationLocationController(controllerParams);
  UC.shipmentTransportationOverview = new ShipmentTransportationOverviewController(controllerParams);
  UC.shippingParties = new ShippingPartiesController(controllerParams);
  UC.shareInvoice = new ShareInvoiceController(controllerParams);
  UC.temperatureControl = new TemperatureControlController(controllerParams);
  UC.transportationOverview = new TransportationOverviewController(controllerParams);
  UC.shipmentTracker = new ShipmentTrackerController(controllerParams);
  UC.makePayment = new MakePaymentController(controllerParams);
  UC.shipmentCharges = new ShipmentChargesController(controllerParams);
  UC.bookingWizard = new BookingWizardController(controllerParams);
  UC.bookingWizardDrawer = new BookingWizardDrawerController(controllerParams);
  UC.shipmentAccessorial = new ShipmentAccessorialController(controllerParams);
  UC.shipmentFreeTime = new ShipmentFreeTimeController(controllerParams);
  UC.manualBookingWizard = new ManualBookingWizardController(controllerParams);
  UC.rollShipment = new RollShipmentWizardController(controllerParams);
  UC.shipmentActiveCharge = new ShipmentActiveChargeController(controllerParams);
  UC.nraAgreement = new NRAAgreementController(controllerParams);
  UC.shipmentChanges = new ShipmentChangesController(controllerParams);
  UC.shipmentPeople = new ShipmentPeopleController(controllerParams);
  UC.shipmentServices = new AdditionalServicesController(controllerParams);
  UC.addRates = new AddRatesController(controllerParams);
};
