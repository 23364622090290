import styled from 'styled-components';
import AntLayout from 'antd/es/layout';

export const ShipmentContentLayout = styled(AntLayout)`
  margin: 0;
  height: 100%;
`;

export const ShipmentContentExpandedWidthLayout = styled(AntLayout)`
  margin: 0;
  height: 100%;
`;
