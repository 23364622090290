import React from 'react';
import { useSelector } from 'react-redux';

import { R } from 'user-management/repository';

import { RejectedScreensComponent } from './RejectedScreens.component';

const RejectedScreensContainer = () => {
  const review = useSelector(R.selectors.userOrganizationData.getOrganizationReview);

  return (
    <RejectedScreensComponent review={review} />
  );
};

export { RejectedScreensContainer as RejectedScreens };
