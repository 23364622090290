import React, { FC, HTMLAttributes } from 'react';

import { colors } from 'app-wrapper/view/themes/themesColors';

interface IIconProps extends HTMLAttributes<SVGElement> {
  color?: string
  height?: number | string
  width?: number | string
}

export const WaypointTypePortIcon: FC<IIconProps> = ({
  color = colors.gray43,
  width = 16,
  height = 16,
  ...other
}) => (
  <svg width={width} height={height} viewBox="0 0 16 16" {...other}>
    <path fillRule="evenodd" clipRule="evenodd" d="M6.99989 2.66668C7.30672 2.66668 7.55545 2.41795 7.55545 2.11112V1.55557L13.3332 1.55557V10.6667L12.8568 10.6667C12.9947 10.1098 12.7096 9.47639 12.0999 9.26737L10.7631 8.80557V5.69446C10.7631 5.37849 10.4958 5.11113 10.1798 5.11113H9.01313V4.1389C9.01313 3.82293 8.74577 3.55557 8.4298 3.55557H4.92979C4.58952 3.55557 4.34646 3.82293 4.34646 4.1389V5.11113H3.17979C2.83952 5.11113 2.59646 5.37849 2.59646 5.69446V8.80557L1.23535 9.26737C0.384656 9.55904 0.165906 10.6771 0.846461 11.2604L2.66938 12.7917C2.66936 15.7219 3.22299 16 4.92979 16H7.99989H8.4298H15.111C15.6019 16 15.9999 15.602 15.9999 15.1111V11.5556V1.55557V1.44446C15.9999 0.892173 15.5522 0.444458 14.9999 0.444458H14.8888L14.4443 0.444459H13.3332L7.55545 0.444458H7.44434C7.44412 0.444458 7.44391 0.444458 7.4437 0.444458H7.111V0.50136C6.72261 0.63864 6.44434 1.00905 6.44434 1.44446V2.11112C6.44434 2.41795 6.69307 2.66668 6.99989 2.66668ZM14.4443 1.55557H14.8888V10.6667L14.4443 10.6667V1.55557ZM14.8888 14.8889V11.7778H11.8729L10.6659 12.7917C10.6016 13.463 10.4745 14.2582 10.1523 14.8889H14.8888ZM8.04091 4.52779H5.31868V5.11113H8.04091V4.52779ZM9.59646 6.27779H3.76313V8.39238L6.2909 7.51738L6.34073 7.50462L6.34073 7.50462L6.34074 7.50462C6.43904 7.47919 6.57331 7.44446 6.65549 7.44446C6.76088 7.44446 6.90283 7.48102 7.00207 7.50658L7.04438 7.51738L9.59646 8.39238V6.27779ZM8.4298 14.8333C8.84299 14.8333 9.20757 14.4931 9.20757 14.0556V12.5L11.7354 10.3611L6.6798 8.61112L1.62424 10.3611L4.15202 12.5V14.0556C4.15202 14.4931 4.49229 14.8333 4.92979 14.8333H8.4298Z" fill={color} />
  </svg>
);
