import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { FlexCol } from 'app-wrapper/view/guideline';
import { Title, Label } from 'shipment-operations/view/pages/ShipmentAdditionalService/components/ServiceWorkflow/сomponents/RequiredActionsFlow/components/StepsForm/components/InputActivity/InputActivity.styled';

import { Button, ButtonWrap } from './SubmitInformationToSkypace.styled';

interface ISubmitInformationToSkypaceComponentProps {
  onSubmit: () => void;
  disabled?: boolean;
}

export const SubmitInformationToSkypaceComponent: FC<ISubmitInformationToSkypaceComponentProps> = ({ onSubmit, disabled }) => {
  const { t } = useTranslation();

  return (
    <FlexCol>
      <Title>
        {t('Submit information to Skypace')}
      </Title>

      <Label>
        {t('After you have submitted the necessary information, our team will review and verify the details provided. \n'
          + 'This step is crucial to ensure that all information is accurate and complete. You will be contacted if further information is required.\n'
          + '\n')}
      </Label>

      <ButtonWrap>
        <Button onClick={onSubmit} disabled={disabled}>
          {t('Submit')}
        </Button>
      </ButtonWrap>
    </FlexCol>
  );
};
