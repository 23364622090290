import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'app-wrapper/createSlice';

import { TPasswordResetNewPasswordState } from 'authentication/models/states';

export const initialState: TPasswordResetNewPasswordState = {
  isLoading: false,
};

export const passwordResetNewPasswordStore = createSlice({
  name: 'passwordResetNewPasswordStore',
  initialState,
  reducers: {
    setEmail: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      email: payload,
    }),
    setCode: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      code: payload,
    }),
    setCodeError: (state, { payload }: PayloadAction<Error>) => ({
      ...state,
      codeError: payload,
    }),
    clearCodeError: (state) => ({
      ...state,
      codeError: undefined,
    }),
    setNewPassword: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      newPassword: payload,
    }),
    setNewPasswordError: (state, { payload }: PayloadAction<Error>) => ({
      ...state,
      newPasswordError: payload,
    }),
    clearNewPasswordError: (state) => ({
      ...state,
      newPasswordError: undefined,
    }),
    setConfirmNewPassword: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      confirmNewPassword: payload,
    }),
    setConfirmNewPasswordError: (state, { payload }: PayloadAction<Error>) => ({
      ...state,
      confirmNewPasswordError: payload,
    }),
    clearConfirmNewPasswordError: (state) => ({
      ...state,
      confirmNewPasswordError: undefined,
    }),
    setLoading: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      isLoading: payload,
    }),
  },
});

export const passwordResetNewPasswordActions = passwordResetNewPasswordStore.actions;
export const passwordResetNewPasswordReducer = passwordResetNewPasswordStore.reducer;
