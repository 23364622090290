import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { R as AuthR } from 'authentication/repository';

import { R } from 'user-management/repository';
import { UC } from 'user-management/controllers';

import { UserDataComponent } from './UserData.component';

const UserDataContainer: FC = () => {
  const userName = useSelector(AuthR.selectors.auth.getUserFullName);
  const isAdmin = useSelector(R.selectors.userOrganizationData.isAdmin);
  const isMember = useSelector(R.selectors.userOrganizationData.isMember);
  const user = useSelector(R.selectors.userOrganizationData.getOrganizationUserMemberData);

  return (
    <UserDataComponent
      isAdmin={isAdmin || isMember}
      userName={userName}
      user={user}
      onChangePassword={UC.userProfile.openChangePassword}
      onEditProfile={UC.userProfile.openEditProfile}
    />
  );
};

export { UserDataContainer as UserData };
