import React from 'react';
import { observer } from 'mobx-react-lite';

import { useMobxStore } from 'app-wrapper/mobxStores';
import { UC } from 'shipment-operations/controllers';

import { CompleteServiceComponent } from './CompleteService.component';

export const CompleteService = observer(() => {
  const { shipmentServices } = useMobxStore();

  return (
    <CompleteServiceComponent
      onSubmit={UC.shipmentServices.endEditingFlow}
      disabled={!shipmentServices.getAreRelatedActivitiesCompleted}
    />
  );
});
