import React from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';
import { UC } from 'shipment-operations/controllers';
import { ShipmentChargesComponent } from './ShipmentCharges.component';

const ShipmentCharges = () => {
  const permissions = useSelector(R.selectors.shipment.getShipmentChargesPermissions);
  const mode = useSelector(R.selectors.shipmentCharges.getTabOwner);
  const isLoading = useSelector(R.selectors.shipmentCharges.getIsLoading);

  return (
    <ShipmentChargesComponent
      isLoading={isLoading}
      permissions={permissions}
      switchPage={UC.shipmentCharges.switchPage}
      setTabOwner={UC.shipmentCharges.setTabOwner}
      toggleOpenModal={UC.createInvoiceCharges.toggleCreateInvoiceModal}
      mode={mode}
    />
  );
};

export { ShipmentCharges };
