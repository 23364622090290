import styled from 'styled-components';
import AntLayout from 'antd/es/layout';

export const ShipmentListHeader = styled(AntLayout.Header).attrs({
  'data-class': 'ShipmentListHeader',
})`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 56px;
  width: 100%;
  padding: 0;

  background-color: white;
  box-shadow: inset 0px -1px 0px #F4F5F8;

  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
`;

export const InnerHeader = styled.div`
  max-width: 1660px;
  margin: 0 auto;
  width: 100%;
  padding: 8px 8px 8px 24px;
`;
