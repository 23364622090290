import styled from 'styled-components';
import Badge from 'antd/es/badge';

import { divFlex } from 'app-wrapper/view/themes/mixin';

interface ICCIWrapperProps {
  selected?: boolean
  disabled?: boolean
}

export const CCIWrapper = styled.div.attrs((props: ICCIWrapperProps) => ({
  selected: props.selected,
  disabled: props.disabled,
  'data-class': 'CCIWrapper',
}))`
  ${divFlex}
  height: 72px;
  width: 100%;
  align-items: center;
  justify-content: left;
  cursor: pointer;

  border: 1px solid ${({ theme }) => theme?.themesColors?.neutralBranding4};
  border-radius: 3px;
  background-color: ${({ theme }) => theme?.themesColors?.neutralBranding1};

  ${({ theme, selected, disabled }) => {
    if (selected && !disabled) {
      return `box-shadow: 0px 4px 4px rgba(32, 44, 60, 0.12), inset 5px 0px 0px ${theme?.themesColors?.boxShadow}`;
    }

    if (selected && disabled) {
      return 'box-shadow: 0px 4px 4px rgba(32, 44, 60, 0.12), inset 5px 0px 0px rgba(32, 44, 60, .4)';
    }

    return '';
  }};
`;

export const CCITitle = styled.div.attrs((props: {
  isEmpty: boolean,
}) => ({
  isEmpty: props.isEmpty,
  'data-class': 'CCITitle',
}))`
  ${divFlex}
  align-items: center;
  justify-content: center;
  padding: 16px;
  height: 100%;
  font-size: 14px;
  font-weight: 500;

  color: ${({ theme, isEmpty }) => (isEmpty ? theme?.themesColors?.secondaryDot45 : theme?.themesColors?.neutralBranding10)};
`;

export const CCITitleBadgeWrapper = styled(Badge).attrs({
  'data-class': 'CCITitleBadgeWrapper',
})`
  ${divFlex}
  margin-left: 4px;
`;

export const CCITitleBadge = styled(Badge).attrs({
  'data-class': 'CCITitleBadge',
})`
  > * { top: -4px !important; }
`;

export const CCITitleUpdate = styled.div.attrs((props: {
  isEmpty: boolean,
}) => ({
  isEmpty: props.isEmpty,
  'data-class': 'CCITitleUpdate',
}))`
  ${divFlex}
  align-items: center;
  justify-content: center;
  padding: 16px;
  height: 100%;
  font-size: 14px;
  font-weight: 500;

  color: ${({ theme, isEmpty }) => (isEmpty ? theme?.themesColors?.secondaryDot45 : theme?.themesColors?.neutralBranding10)};
`;
