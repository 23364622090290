import notification from 'antd/es/notification';
import { AxiosError } from 'axios';

import { BaseUseCase } from 'app-wrapper/usecases/Base.useCase';
import { R } from 'user-management/repository';
import { OrganizationDTM } from 'user-management/models/dtm';
import { ServerError } from 'app-wrapper/types/ServerError';

export class NewOrganizationCase extends BaseUseCase {
  public saveOrganizationData = async () => {
    let isError = false;
    let newOrganization: OrganizationDTM | null = null;
    let organization = R.selectors.newOrganizationData.getUserOrganizationUpdate(this.store.getState());
    const tradeReference = R.selectors.newOrganizationData.getTradeReference(this.store.getState());
    const isTradeReferenceEmpty = R.selectors.newOrganizationData.getIsTradeReferenceEmpty(this.store.getState());

    organization = {
      ...organization,
      tradeReference: isTradeReferenceEmpty ? undefined : tradeReference,
    };

    try {
      if (organization?.id) {
        await R.services.organization.putCurrentOrganization(organization);
      } else {
        newOrganization = await R.services.organization.postCurrentOrganization(organization);
      }
    } catch (e) {
      const error = e as AxiosError<ServerError>;
      isError = true;
      if (error.message) {
        notification.error({
          message: error.message,
          placement: 'bottomRight',
          bottom: 60,
          duration: null,
        });
      }

      return isError;
    }

    if (newOrganization) {
      this.dispatch(R.actions.newOrganizationData.setUserOrganization(newOrganization));
      this.dispatch(R.actions.userOrganizationData.setUserOrganization(newOrganization));
    }

    return isError;
  };
}
