import React from 'react';

import {
  Wrapper,
} from './SIDocumentUpload.styled';
import { Footer, DocumentUpload } from './components';

export const SIDocumentUploadComponent = () => (
  <Wrapper>
    <DocumentUpload />
    <Footer />
  </Wrapper>
);
