import React, { FC, memo, useCallback } from 'react';
import Checkbox from 'antd/es/checkbox';
import { useTranslation } from 'react-i18next';
import WarningFilled from '@ant-design/icons/WarningFilled';
import RightOutlined from '@ant-design/icons/RightOutlined';

import { SelectItemInput } from 'app-wrapper/view/components';
import { VerticalDotsSvg } from 'app-wrapper/view/icons';

import { DraftOrganizationPopoverComponent } from 'monetary/view/components';

import {
  AddAdditionalServicesDivider,
  AddAdditionalServicesDropdown,
  AddAdditionalServicesDropdownOverlay,
  AddAdditionalServicesRemoveOption,
  AddAdditionalServicesSelectItemCheckedWrap, AddAdditionalServicesSelectItemCheckedWrapText, AddAdditionalServicesSelectItemInputBlock, AddAdditionalServicesSelectItemInputCount, AddAdditionalServicesSelectItemInputName, AddAdditionalServicesSelectItemInputSubCount, AddAdditionalServicesSelectItemInputWrap, AddAdditionalServicesSelectItemPopover, AddAdditionalServicesSelectItemPopoverText, AddAdditionalServicesSelectItemPopoverTextWrap, AddAdditionalServicesSelectItemWrap,
  AddAdditionalServicesSelectItemWrapStyle,
  DropdownIcon,
} from './AddAdditionalServicesSelectItem.styled';

interface IAddAdditionalServicesSelectItemComponentProps {
  isChecked?: boolean;
  isHideCheckbox?: boolean;
  serviceName?: string;
  information?: string;
  documentName?: string;
  serviceCount?: string;
  totalCost?: string;
  onChangeChecked: (value: boolean) => void;
  onChangeCount: (value: string) => void;
  onDeletedService?: () => void;
}

const AddAdditionalServicesSelectItemComponent: FC<IAddAdditionalServicesSelectItemComponentProps> = (props) => {
  const {
    isChecked,
    isHideCheckbox,
    serviceName,
    information,
    documentName,
    serviceCount,
    totalCost,
    onChangeChecked,
    onChangeCount,
    onDeletedService,
  } = props;
  const { t } = useTranslation();

  const onChangeCheckedHandle = useCallback((e) => {
    onChangeChecked(e.target.checked);
  }, [onChangeChecked]);

  const onChangeCountHandle = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    onChangeCount(e.target.value);
  }, [onChangeCount]);

  const onDeletedServiceHandle = useCallback(
    () => {
      if (onDeletedService) {
        onDeletedService();
      }
    },
    [onDeletedService],
  );

  return (
    <AddAdditionalServicesSelectItemWrap>
      <AddAdditionalServicesSelectItemWrapStyle />

      <AddAdditionalServicesSelectItemCheckedWrap>
        {!isHideCheckbox && (
          <Checkbox
            checked={isChecked}
            onChange={onChangeCheckedHandle}
          />
        )}
        <AddAdditionalServicesSelectItemCheckedWrapText isFirst={isHideCheckbox}>
          {serviceName}
        </AddAdditionalServicesSelectItemCheckedWrapText>
        <AddAdditionalServicesSelectItemPopover>

          <DraftOrganizationPopoverComponent
            isEmpty={!information}
            trigger="click"
            popoverText={information}
          >
            <AddAdditionalServicesSelectItemPopoverTextWrap>
              <WarningFilled />
              <AddAdditionalServicesSelectItemPopoverText>
                {t('More Info')}
                <RightOutlined />
              </AddAdditionalServicesSelectItemPopoverText>
            </AddAdditionalServicesSelectItemPopoverTextWrap>
          </DraftOrganizationPopoverComponent>
        </AddAdditionalServicesSelectItemPopover>
      </AddAdditionalServicesSelectItemCheckedWrap>

      <AddAdditionalServicesSelectItemInputWrap>
        <AddAdditionalServicesSelectItemInputName>
          {documentName}
        </AddAdditionalServicesSelectItemInputName>
        <AddAdditionalServicesSelectItemInputBlock>
          <SelectItemInput
            value={serviceCount}
            disabled={!isChecked}
            onChange={onChangeCountHandle}
          />
        </AddAdditionalServicesSelectItemInputBlock>
        <AddAdditionalServicesSelectItemInputSubCount>
          /
        </AddAdditionalServicesSelectItemInputSubCount>
        <AddAdditionalServicesSelectItemInputCount>
          $ {totalCost}
        </AddAdditionalServicesSelectItemInputCount>
      </AddAdditionalServicesSelectItemInputWrap>

      {onDeletedService ? (
        <AddAdditionalServicesDivider />
      ) : null}

      {onDeletedService ? (
        <AddAdditionalServicesDropdown
          overlay={(
            <AddAdditionalServicesDropdownOverlay>
              <AddAdditionalServicesRemoveOption onClick={onDeletedServiceHandle}>
                {t('Remove')}
              </AddAdditionalServicesRemoveOption>
            </AddAdditionalServicesDropdownOverlay>
          )}
        >
          <DropdownIcon>
            <VerticalDotsSvg />
          </DropdownIcon>
        </AddAdditionalServicesDropdown>
      ) : null}
    </AddAdditionalServicesSelectItemWrap>
  );
};

const AddAdditionalServicesSelectItemComponentCache = memo(AddAdditionalServicesSelectItemComponent);

export { AddAdditionalServicesSelectItemComponentCache as AddAdditionalServicesSelectItemComponent };
