import styled from 'styled-components';
import TextArea from 'antd/es/input/TextArea';
import { NavLink } from 'react-router-dom';

interface IContainer {
  mBottom?: string
}

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const CardWrapper = styled.div`
  width: 33%;
  min-height: 136px;
  border: 1px solid #EAEEF8;
  border-radius: 3px;
  padding: 12px 16px;
  background-color: white;
`;

export const CardsTitle = styled.span`
  color: #73819B;
  font-weight: 400;
  font-size: 12px;
`;

export const CardsValue = styled.p`
  font-weight: 400;
  font-size: 14px;
  color: #202C3C;
  margin-bottom: 0;
  text-transform: capitalize;
`;

export const CardsLink = styled(NavLink)`
  color: #4A55AD;
  font-weight: 400;
  font-size: 14px;
  display: block;
`;

export const BoldCardsValue = styled(CardsValue)`
  font-weight: 500;
`;

export const InnerContainer = styled.div`
  width: 100%;
  padding: 16px 16px 0 16px;
`;

export const NotesInnerContainer = styled(InnerContainer)`
  margin-bottom: 20px;
`;

export const Title = styled.p`
  font-weight: 500;
  font-size: 16px;
  color: #202C3C;
  margin-bottom: 14px;
`;

export const StyledTotal = styled.div`
  height: 52px;
  line-height: 52px;
  background: #F9F9FB;
  font-weight: 500;
  font-size: 14px;
  color: #202C3C;
  display: flex;
  justify-content: flex-end;
  padding: 0 16px;
  border-top: 1px solid #EAEEF8;
  border-bottom: 1px solid #EAEEF8;
`;

export const HeaderTableWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 22px;
  color: #73819B;
  font-weight: 400;
  font-size: 12px;
  height: 36px;
`;

export const Container = styled.div<IContainer>`
  border: 1px solid #EAEEF8;
  border-radius: 3px;
  background-color: white;
  margin-top: 8px;
  margin-bottom: ${({ mBottom }) => mBottom};
`;

export const StyledTextArea = styled(TextArea)`
  margin-bottom: 15px;
  font-size: 12px;
`;

export const LargeTitle = styled.p`
  font-weight: 400;
  font-size: 16px;
  color: #202C3C;
  width: 100%;
  text-align: right;
  margin: 0 0 8px 0;
`;

export const MainSum = styled.p`
  font-weight: 500;
  font-size: 24px;
  color: #202C3C;
  width: 100%;
  text-align: right;
  margin: 0;
`;
