import styled from 'styled-components';
import CopyOutlined from '@ant-design/icons/CopyOutlined';
import Collapse from 'antd/es/collapse';

import { SmallTextFootnoteDescription } from 'app-wrapper/view/guideline';
import { TableHeader, StyledTable } from 'shipment-operations/view/components/CustomTable/CustomTable.styled';

interface ITitleProps {
  active: boolean
}

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  padding: 12px 16px;
  justify-content: space-between;
`;

export const PaymentDetailsWrapper = styled.div`
  width: 49%;
`;

export const BilledWrapper = styled.div`
  width: 50%;
`;

export const Title = styled.span`
  color: #202C3C;
  font-weight: 500;
  font-size: 14px;
`;

export const PaymentDataWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`;

export const PaymentData = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  margin-top: 12px;
`;

export const PaymentLabel = styled.span`
  color: #73819B;
  font-weight: 400;
  font-size: 12px;
`;

export const PaymentValue = styled.span`
  color: #202C3C;
  font-weight: 400;
  font-size: 14px;
`;

export const CopyIcon = styled(CopyOutlined)`
  color: #73819B;
  font-size: 12px;
  margin-left: 3px;
  cursor: pointer;
`;

export const FooterDialog = styled.p`
  color: #73819B;
  font-weight: 400;
  font-size: 14px;
`;

export const StyledInvoice = styled.span`
  color: #202C3C;
  margin-left: 3px;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const BoldValue = styled.span`
  color: #202C3C;
  font-weight: 500;
  font-size: 14px;
`;

export const StyledCollapse = styled(Collapse)`
  .ant-collapse-header {
    padding: 8px 0 0 0 !important;
    align-items: center !important;
  }

  .ant-collapse-arrow {
    margin-right: 6px !important;
  }
`;

export const CollapseTitle = styled.span.attrs({
  'data-class': 'CreditNoteWrapper',
})<ITitleProps>`
  font-weight: 400;
  font-size: 12px;
  color: ${({ active }) => (active ? '#6C79DE' : '#202C3C')};
`;

export const CollapseTitlePayments = styled.span<ITitleProps>`
  font-weight: 400;
  font-size: 12px;
  color: ${({ active }) => (active ? '#6C79DE' : '#202C3C')};
`;

export const CustomTableHeader = styled(TableHeader)`
  .ant-table-container table > thead > tr:first-child th:first-child {
    padding-left: 0;
  }
  .ant-table-thead .ant-table-cell {
    border: none;
    padding-top: 4px;
    padding-bottom: 0;
  }
`;

export const CustomTable = styled(StyledTable).attrs({
  'data-class': 'CreditNoteExpandTable',
})`
  .ant-table-tbody > tr >  .ant-table-cell:first-child {
    padding-left: 0;
  }

  .ant-table-tbody .ant-table-cell {
    font-size: 12px;
    color: #202C3C;
    padding-top: 4px;
    padding-bottom: 4px;
  }

  .ant-table-tbody > tr > td {
    border-bottom: 1px dashed #EAEEF8;
  }
`;

export const SubTitleValue = styled.span`
  ${SmallTextFootnoteDescription}
`;

export const SubTitle = styled.span`
  ${SmallTextFootnoteDescription}
  color: ${({ theme }) => theme.themesColors.characterSecondaryDot45};
`;
