import React from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';
import { DashboardComponent } from 'shipment-operations/view/components';

const DashboardContainer = () => {
  const isLoading = useSelector(R.selectors.shipmentCharges.getIsLoading);
  const totalProfit = useSelector(R.selectors.shipmentCharges.getTotalProfit);
  const totalProfitPercent = useSelector(R.selectors.shipmentCharges.getTotalProfitPercent);
  const totalCost = useSelector(R.selectors.shipmentCharges.getTotalCost);
  const buyTotalCost = useSelector(R.selectors.shipmentCharges.getBuyTotalCost);
  const arBudgetTotalCost = useSelector(R.selectors.shipmentCharges.getArBudgetTotalCost);
  const apBudgetTotalCost = useSelector(R.selectors.shipmentCharges.getApBudgetTotalCost);
  const receivablesDifferent = useSelector(R.selectors.shipmentCharges.getReceivablesDifferent);
  const payablesDifferent = useSelector(R.selectors.shipmentCharges.getPayablesDifferent);
  const receivablesDifferentPercent = useSelector(R.selectors.shipmentCharges.getReceivablesDifferentPercent);
  const payablesDifferentPercent = useSelector(R.selectors.shipmentCharges.getPayablesDifferentPercent);
  const arStats = useSelector(R.selectors.shipmentCharges.getArStats);
  const apStats = useSelector(R.selectors.shipmentCharges.getApStats);

  return (
    <DashboardComponent
      loading={isLoading}
      totalProfit={totalProfit}
      totalProfitPercent={totalProfitPercent}
      totalCost={totalCost}
      buyTotalCost={buyTotalCost}
      arBudgetTotalCost={arBudgetTotalCost}
      apBudgetTotalCost={apBudgetTotalCost}
      receivablesDifferent={receivablesDifferent}
      receivablesDifferentPercent={receivablesDifferentPercent}
      payablesDifferent={payablesDifferent}
      payablesDifferentPercent={payablesDifferentPercent}
      arStats={arStats}
      apStats={apStats}
      withExtra
    />
  );
};

export { DashboardContainer as Dashboard };
