import styled from 'styled-components';
import CopyOutlined from '@ant-design/icons/CopyOutlined';

import { divFlex } from 'app-wrapper/view/themes/mixin';

export const RFRItemSubCardDescriptionsContainer = styled.div.attrs((props: {
  isVerticalCenter: boolean,
}) => ({
  isVerticalCenter: props.isVerticalCenter,
  'data-class': 'RFRItemSubCardDescriptionsContainer',
}))`
  ${divFlex}
  flex-direction: column;
  ${({ isVerticalCenter }) => isVerticalCenter && 'justify-content: center;'};

  padding: 8px 0 10px 0;
  width: 100%;
  height:  100%;
`;

export const RFRItemSubCardDescriptionsContainerSpanClip = styled.span.attrs((props: {
  isFooterClipboard: boolean,
}) => ({
  isFooterClipboard: props.isFooterClipboard,
  'data-class': 'RFRItemSubCardDescriptionsContainerSpanClip',
}))`
  display: inline;

  ${({ isFooterClipboard }) => (isFooterClipboard ? 'max-width: 70%;' : 'max-width: 80%;')};

  text-overflow: ellipsis;
  overflow: clip;
  white-space: nowrap;
`;

export const CopyIcon = styled(CopyOutlined)`
  color: #73819B;
  font-size: 12px;
  margin-left: 3px;
  cursor: copy !important;
`;

export const RFRItemSubCardDescriptionsContainerTitle = styled.div.attrs({
  'data-class': 'RFRItemSubCardDescriptionsContainerTitle',
})`
  ${divFlex}
  height: 22px;
  align-items: center;

  color: ${({ theme }) => theme.themesColors.secondaryDot45};
  font-size: 12px;
  font-weight: 400;

  span {
    line-height: 18px;
  }
`;

export const RFRItemSubCardDescriptionsContainerContent = styled.div.attrs({
  'data-class': 'RFRItemSubCardDescriptionsContainerContent',
})`
  ${divFlex}
  height: 16px;
  align-items: center;

  color: ${({ theme }) => theme.themesColors.neutralBranding10};
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;

  span {
    line-height: 16px;
  }
`;

export const RFRItemSubCardDescriptionsContainerFooter = styled.div.attrs({
  'data-class': 'RFRItemSubCardDescriptionsContainerFooter',
})`
  ${divFlex}
  height: 22px;
  align-items: center;

  color: ${({ theme }) => theme.themesColors.secondaryDot45};
  font-size: 12px;
  font-weight: 400;

  span {
    line-height: 20px;
  }
`;
