import PhoneOutlined from '@ant-design/icons/PhoneOutlined';
import MailOutlined from '@ant-design/icons/MailOutlined';
import GlobalOutlined from '@ant-design/icons/GlobalOutlined';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { OrganizationDTM } from 'user-management/models/dtm';
import { NoDataLineItem } from 'user-management/view/components';

import {
  CIContactInformationData,
  CIContactInformationHeader,
  CIContactInformationIconBG,
  CIContactInformationItem,
  CIContactInformationLink,
  CIContactInformationRow,
  CIContactInformationTitle,
  CIContactInformationValue,
  CIContactInformationWrapper,
} from './CIContactInformation.styled';

interface ICIContactInformationComponentProps {
  organization?: OrganizationDTM
}

export const CIContactInformationComponent: FC<ICIContactInformationComponentProps> = ({
  organization,
}) => {
  const { t } = useTranslation();

  return (
    <CIContactInformationWrapper>
      <CIContactInformationHeader>{t('CompanyContact')}</CIContactInformationHeader>
      <CIContactInformationRow>
        <CIContactInformationItem>
          <CIContactInformationIconBG>
            <PhoneOutlined />
          </CIContactInformationIconBG>
          <CIContactInformationData>
            <CIContactInformationTitle>{t('Phone')}</CIContactInformationTitle>
            <CIContactInformationValue>{organization?.phone}</CIContactInformationValue>
          </CIContactInformationData>
        </CIContactInformationItem>
        <CIContactInformationItem>
          <CIContactInformationIconBG>
            <PhoneOutlined />
          </CIContactInformationIconBG>
          <CIContactInformationData>
            <CIContactInformationTitle>{t('Additional Phone')}</CIContactInformationTitle>
            <CIContactInformationValue>{organization?.phone2 ? organization?.phone2 : <NoDataLineItem />}</CIContactInformationValue>
          </CIContactInformationData>
        </CIContactInformationItem>
      </CIContactInformationRow>
      <CIContactInformationRow>
        <CIContactInformationItem>
          <CIContactInformationIconBG>
            <MailOutlined />
          </CIContactInformationIconBG>
          <CIContactInformationData>
            <CIContactInformationTitle>{t('Email')}</CIContactInformationTitle>
            <CIContactInformationValue>{organization?.email || <NoDataLineItem />}</CIContactInformationValue>
          </CIContactInformationData>
        </CIContactInformationItem>
        <CIContactInformationItem>
          <CIContactInformationIconBG>
            <MailOutlined />
          </CIContactInformationIconBG>
          <CIContactInformationData>
            <CIContactInformationTitle>{t('Additional Email')}</CIContactInformationTitle>
            <CIContactInformationValue><NoDataLineItem /></CIContactInformationValue>
          </CIContactInformationData>
        </CIContactInformationItem>
      </CIContactInformationRow>
      <CIContactInformationRow>
        <CIContactInformationItem>
          <CIContactInformationIconBG>
            <GlobalOutlined />
          </CIContactInformationIconBG>
          <CIContactInformationData>
            <CIContactInformationTitle>{t('Website')}</CIContactInformationTitle>
            <CIContactInformationLink>{organization?.webUrl?.getValidValue() || <NoDataLineItem />}</CIContactInformationLink>
          </CIContactInformationData>
        </CIContactInformationItem>
      </CIContactInformationRow>
    </CIContactInformationWrapper>
  );
};
