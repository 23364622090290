import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import get from 'lodash/get';

import { ContentKey, CustomHeader } from 'shipment-operations/view/drawers/ShipmentChanges/components/CustomTable/CustomTable.styled';
import { IChangesTable } from 'shipment-operations/models/dtm';
import { BillOfLadingType, BillOfLadingTypeNames } from 'shipment-operations/constants';

interface ICell {
  name: string
  value: string
}

interface ICustomCell {
  [key: string]: string
}

interface ICustomTableProps {
  data: IChangesTable[]
  withKeyRender: boolean
  isBlOptions?: boolean
}

const CustomTable: FC<ICustomTableProps> = ({ data, withKeyRender, isBlOptions }) => {
  const { t } = useTranslation();

  const width = '33%';

  const customRender = (obj: ICell) => (
    <>
      <ContentKey>{obj.name} </ContentKey>
      <span>{obj.value}</span>
    </>
  );

  const header = [
    {
      title: '',
      dataIndex: 'title',
      key: 'title',
      width,
    },
    {
      title: t('previouslySubmitted'),
      dataIndex: 'confirmed',
      key: 'confirmed',
      width,
      render: (value: ICustomCell[] | string | ICell) => {
        if (isBlOptions) {
          const type = get(value, 'value', '') as BillOfLadingType;
          return BillOfLadingTypeNames[type];
        }
        if (withKeyRender) {
          return customRender(value as ICell);
        }
        return value;
      },
    },
    {
      title: t('changesRequested'),
      dataIndex: 'requested',
      key: 'requested',
      width,
      render: (value: ICustomCell[] | string | ICell) => {
        if (isBlOptions) {
          const type = get(value, 'value', '') as BillOfLadingType;
          return BillOfLadingTypeNames[type];
        }
        if (withKeyRender) {
          return customRender(value as ICell);
        }
        return value;
      },
    },
  ];

  return (
    <CustomHeader
      columns={header}
      dataSource={data}
      pagination={false}
    />
  );
};

export { CustomTable };
