import React from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';

import { ContainersTableComponent } from './ContainersTable.component';

const ContainersTableContainer = () => {
  const containers = useSelector(R.selectors.manualBookingWizard.getContainersWithQuantity);

  return (
    <ContainersTableComponent containers={containers} />
  );
};

export { ContainersTableContainer as ContainersTable };
