import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';
import { UC } from 'shipment-operations/controllers';

import { ContainersTableComponent } from './ContainersTable.component';

const ContainersTableContainer: FC = () => {
  const isLoading = useSelector(R.selectors.shipmentTracker.getIsLoadingPage);
  const error = useSelector(R.selectors.shipmentTracker.getIsError);
  const containers = useSelector(R.selectors.shipmentTracker.getContainersForTable);
  const isUpdatedDates = useSelector(R.selectors.shipmentTracker.getIsUpdatedDates);
  const isLoadingUpdate = useSelector(R.selectors.shipmentTracker.getIsLoadingUpdate);
  const permissions = useSelector(R.selectors.shipment.getTransportationPermissions);

  return (
    <ContainersTableComponent
      isLoading={isLoading}
      error={error}
      containers={containers}
      fetchData={UC.shipmentTracker.loadContainers}
      onChangeDate={UC.shipmentTracker.onChangeDate}
      onResetChanges={UC.shipmentTracker.onResetChanges}
      onPostDates={UC.shipmentTracker.onPostDates}
      isUpdatedDates={isUpdatedDates}
      isLoadingUpdate={isLoadingUpdate}
      trackerAvailability={permissions.trackerAvailability}
      trackerWarningsAvailability={permissions.trackerWarningsAvailability}
    />
  );
};

export {
  ContainersTableContainer as ContainersTable,
};
