import React, {
  useMemo, useState, useEffect, useCallback,
} from 'react';
import get from 'lodash/fp/get';
import noop from 'lodash/fp/noop';
import Button from 'antd/es/button';
import { PlusOutlined } from '@ant-design/icons';
import DeleteOutlined from '@ant-design/icons/DeleteOutlined';
import { useTranslation } from 'react-i18next';

import {
  CONTAINER_TYPES_TO_NAME,
  ContainerReeferTypes,
  ContainerUsualTypes,
  DataType,
} from 'shipment-operations/constants';

import { FRT } from 'monetary/constants';
import { FixedType } from './AddRates.component';
import { ChargeColumn } from './AddRates.styled';

const useTableEditable = (
  data: DataType[],
  onChangeCharge: (row: DataType, type: string) => void,
  addCharge: (title: string, type: string, designation: string) => void,
  deleteCharge: (id: number, type: string) => void,
  additionalColumns?: string[],
) => {
  const { t } = useTranslation();
  const [dataSource, setDataSource] = useState(data);

  useEffect(() => {
    setDataSource(data);
  }, [data]);

  const parsedAdditionalColumns = useMemo(() => (additionalColumns ? additionalColumns.map((column) => ({
    title: CONTAINER_TYPES_TO_NAME[column as ContainerUsualTypes | ContainerReeferTypes],
    dataIndex: column,
    key: column,
    width: 20,
    editable: true,
    align: 'center' as 'center',
    render: (item: string, record: DataType | {}) => {
      const title = get('title', record);
      if (title) {
        return '';
      }
      return item;
    },
  })) : [
    {
      title: t('price'),
      dataIndex: 'price',
      key: 'price',
      width: 20,
      editable: true,
      align: 'center' as 'center',
      render: (item: string, record: DataType | {}) => {
        const title = get('title', record);
        if (title) {
          return '';
        }
        return item;
      },
    },
  ]), [additionalColumns, data]);

  const defaultColumns = useMemo(() => [
    {
      title: t('charge'),
      width: 60,
      dataIndex: 'charge',
      key: 'charge',
      fixed: 'left' as FixedType,
      className: 'titleColumn',
      editable: true,
      render: (item: string, record: DataType | {}) => {
        const title = get('title', record);
        if (title) {
          return (
            <ChargeColumn>
              <span style={{ fontWeight: 'bold' }}>{title}</span>
              <Button
                size="small"
                icon={<PlusOutlined />}
                onClick={() => addCharge(title, additionalColumns ? 'container' : 'shipment', title)}
              >
                {t('addCharge')}
              </Button>
            </ChargeColumn>
          );
        }
        return item;
      },
    },
    {
      title: t('Applicability'),
      width: 50,
      dataIndex: 'applicability',
      key: 'applicability',
      align: 'center' as 'center',
      editable: true,
      render: (item: string, record: DataType | {}) => {
        const title = get('title', record);
        if (title) {
          return '';
        }
        return item;
      },
    },
    {
      title: t('Measure By'),
      dataIndex: 'measureBy',
      key: 'measureBy',
      width: 50,
      align: 'center' as 'center',
      editable: true,
      render: (item: string, record: DataType | {}) => {
        const title = get('title', record);
        if (title) {
          return '';
        }
        return item;
      },
    },
    {
      title: t('Currency'),
      dataIndex: 'currency',
      key: 'currency',
      width: 30,
      align: 'center' as 'center',
      editable: true,
      render: (item: string, record: DataType | {}) => {
        const title = get('title', record);
        if (title) {
          return '';
        }
        return item;
      },
    },
    {
      title: t('action'),
      key: 'operation',
      fixed: 'right' as FixedType,
      align: 'center' as 'center',
      width: 15,
      className: 'titleColumn',
      render: (item: string, record: DataType | {}) => {
        const title = get('title', record);
        const id = get('id', record);
        const charge = get('charge', record);
        const isFRT = charge === FRT;
        if (title) {
          return '';
        }
        return (
          <DeleteOutlined
            onClick={isFRT ? noop : () => deleteCharge(id, additionalColumns ? 'container' : 'shipment')}
            style={{ opacity: isFRT ? 0.4 : 1, cursor: charge === FRT ? 'not-allowed' : 'pointer' }}
          />
        );
      },
    },
  ], [data, additionalColumns]);

  const columnsWithPrice = useMemo(() => [
    ...defaultColumns.slice(0, 2),
    ...parsedAdditionalColumns,
    ...defaultColumns.slice(2),
  ], [data, parsedAdditionalColumns, defaultColumns]);

  const handleSave = useCallback((row: DataType) => {
    onChangeCharge(row, additionalColumns ? 'container' : 'shipment');
  }, [additionalColumns]);

  const columns = useMemo(() => columnsWithPrice.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: any) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        emptyRender: record.emptyRender,
        handleSave,
      }),
    };
  }), [defaultColumns]);

  return {
    dataSource,
    columns,
  };
};

export { useTableEditable };
