import React, { FC, memo } from 'react';
import { useSelector } from 'react-redux';

import { REQUEST_STATUS } from 'app-wrapper/constants';

import { R } from 'user-management/repository';
import { UC } from 'user-management/controllers';

import { AddressesAndContactsSecondComponent } from './AddressesAndContactsSecond.component';

const AddressesAndContactsSecondContainer: FC = () => {
  const contactBook = useSelector(R.selectors.contactBook.getContactBookDefault);

  return (
    <AddressesAndContactsSecondComponent
      company={contactBook.companyPrimaryByIAddress}
      isLoading={contactBook.getRequestStatus === REQUEST_STATUS.pending}
      runComponent={UC.contactBook.onRunAddressAndContactsSecondPage}
      onClickAddAddress={UC.contactBook.openAACContactAddContactPersonDrawer}
      onClickEditAddress={UC.contactBook.openAACContactEditContactPersonDrawer}
      onClickArrow={UC.contactBook.onBackAddressesAndContactsAddressById}
    />
  );
};

const AddressesAndContactsSecondContainerCached = memo(AddressesAndContactsSecondContainer);

export { AddressesAndContactsSecondContainerCached as AddressesAndContactsSecondPage };
