import React, { FC, useCallback } from 'react';

import {
  InnerContainer, Title, StyledTextArea,
} from 'shipment-operations/view/components';
import { useTranslation } from 'react-i18next';

interface INotesComponentProps {
  onInputChange: (values: string) => void
}

const NotesComponent: FC<INotesComponentProps> = ({ onInputChange }) => {
  const { t } = useTranslation();

  const handleInputChange = useCallback((e) => onInputChange(e.target.value), [onInputChange]);

  return (
    <InnerContainer>
      <Title>{t('creditNote.additionalNotes')}</Title>
      <StyledTextArea rows={4} placeholder={t('creditNote.notesPlaceholder')} onChange={handleInputChange} />
    </InnerContainer>
  );
};

export { NotesComponent };
