import { CustomScrollbar } from 'app-wrapper/view/styles/mixins';
import styled from 'styled-components';

export const Wrapper = styled.div.attrs((props: {
  noPaddingBottom: boolean,
  'data-class'?: string
}) => ({
  'data-class': props?.['data-class'] || 'Wrapper',
  id: 'Wrapper',
  noPaddingBottom: props.noPaddingBottom || false,
}))`
  position: relative;
  width: 100%;
  height: 100%;
  padding: 16px 24px ${({ noPaddingBottom }) => (noPaddingBottom ? '0' : '16px')} 24px;
  display: flex;
  flex-direction: column;
  max-width: 1660px;
  margin: 0 auto;
  ${CustomScrollbar}
`;
