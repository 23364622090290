import React, { FC } from 'react';

import { ContainerViewDTM } from 'shipment-operations/models/dtm';
import { ExpandPanelComponent } from 'shipment-operations/view/components';

interface IExpandPanelProps {
  container: ContainerViewDTM
  sum: number
}

const ExpandPanel: FC<IExpandPanelProps> = ({
  container, sum, children,
}) => (
  <ExpandPanelComponent
    container={container}
    sum={sum}
  >
    {children}
  </ExpandPanelComponent>
);

export { ExpandPanel };
