import React from 'react';
import { useTranslation } from 'react-i18next';

import { UserMenuItem, OrganizationMenuItem } from './components';
import {
  UserMenuContainer,
  OrganizationHeader,
  OrganizationHeaderLine,
} from './UserMenu.styled';

export const UserMenu = ({ onClosePopover }: { onClosePopover: () => void }) => {
  const { t } = useTranslation();

  return (
    <UserMenuContainer>
      <UserMenuItem onClosePopover={onClosePopover} />
      <OrganizationHeader>
        <span>{t('My Organization')}</span>
        <OrganizationHeaderLine />
      </OrganizationHeader>
      <OrganizationMenuItem onClosePopover={onClosePopover} />
    </UserMenuContainer>
  );
};
