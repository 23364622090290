import { BaseRepository } from 'proto/BaseRepository';
import { ModuleFunctionalityPermissionsDtm, PaymentTermsDTM } from 'shipment-operations/models/dtm';
import { R } from 'shipment-operations/repository';

export class ShipmentPermissionsRepository extends BaseRepository<ModuleFunctionalityPermissionsDtm | null> {
  async getRequest(shipmentId: string,
    paymentTerms: PaymentTermsDTM | null,
    isAdmin?: boolean,
    removeSignal?: boolean) {
    const result = await R.services.moduleFunctionalityPermissions.getFunctionalityPermissions(shipmentId, paymentTerms, isAdmin, removeSignal);
    return result;
  }

  getStore() {
    return this.store.getState().shipment.permissions;
  }
}
