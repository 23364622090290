import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import {
  ContainerTitleWrapper,
  ContainerType,
} from 'shipment-operations/view/components/ExpandPanel/ExpandPanel.styled';
import { ContainerReeferTypes, ContainerTypesConst, ContainerUsualTypes } from 'shipment-operations/constants';
import { CardTopFormStatus } from 'shipment-operations/view/components';
import {
  ContainerCardTopContainer,
  ContainerCardTitleContainer,
  ContainerTitleTextWithDraft,
  ContainerTitleText,
  UnalignedBadge,
} from './ContainerCardTop.styled';

interface IContainerCardTopProps {
  name?: string
  isInDraft: boolean
  type: ContainerUsualTypes | ContainerReeferTypes
  isSelected: boolean
  infoFilledIn: boolean
  hasNumber: boolean
}

export const ContainerCardTop: FC<IContainerCardTopProps> = ({
  name,
  isInDraft,
  type,
  isSelected,
  infoFilledIn,
  hasNumber,
}) => {
  const { t } = useTranslation();

  const cardTitle = name || t('Add cargo details');

  return (
    <ContainerCardTopContainer>
      <ContainerCardTitleContainer>
        <ContainerTitleTextWithDraft>
          <ContainerTitleWrapper>
            <ContainerType active={isSelected}>
              {ContainerTypesConst[type]} &apos;
            </ContainerType>
          </ContainerTitleWrapper>
          <ContainerTitleText $gray={!hasNumber || !cardTitle}>
            {cardTitle}
          </ContainerTitleText>
          {isInDraft && <UnalignedBadge color="red" />}
        </ContainerTitleTextWithDraft>
        <CardTopFormStatus isError={!infoFilledIn} />
      </ContainerCardTitleContainer>
    </ContainerCardTopContainer>
  );
};
