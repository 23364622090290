import message from 'antd/es/message';
import { US_COUNTRY_CODE } from 'app-wrapper/constants';
import { EShipmentOrganizationRole } from 'user-management/constants';
import { v4 as uuidv4 } from 'uuid';

import { apiWorker } from 'app-wrapper/repository/utilsServices';
import { validationEmail } from 'app-wrapper/utils';

import { BaseController, controller } from 'proto/BaseController';

import { R } from 'shipment-operations/repository';
import {
  EShipmentConfirmationTypes,
  EShippingPartyTypes,
  ShippingPartyRoutes,
  TradeType,
} from 'shipment-operations/constants';
import {
  AddressDTM,
  ContactDTM,
  ShipmentConfirmationDTM,
  ShippingPartyAddressDTM,
  ShippingPartyContactDTM,
  ShippingPartyDTM,
  ShippingPartyPermissionsDTM,
  ShippingPartyReference,
} from 'shipment-operations/models/dtm';

import {
  shippingPartiesAddressValidate,
  shippingPartiesCompanyValidate,
  shippingPartiesContactValidate,
} from './ShippingParties.validate';

@controller
export class ShippingPartiesController extends BaseController {
  // common scenarios
  public firstDataUpload = async (shipmentId: string) => {
    this.dispatch(R.actions.shipmentDocumentsAll.setCreatedByNameConfirmationNRA(''));
    this.dispatch(R.actions.shipmentDocumentsAll.setCreatedAtNRA(''));

    this.dispatch(R.actions.shippingParties.setIsLoading(true));
    this.dispatch(R.actions.shippingParties.setShippingPartyListLoading(true));

    const paymentTerms = await R.services.paymentTerms.getShipmentPaymentTerms(shipmentId);

    if (paymentTerms) {
      this.dispatch(R.actions.shippingParties.setTradeType(paymentTerms.tradeType));
    }

    const shippingPartyList = await this.getShippingParties(shipmentId) || [] as ShippingPartyDTM[];

    const shippingPartyToIsSetBySystemMap: Record<EShippingPartyTypes, boolean> = {
      [EShippingPartyTypes.SHIPPER]: false,
      [EShippingPartyTypes.CONSIGNEE]: false,
      [EShippingPartyTypes.NOTIFY_PARTY]: false,
      [EShippingPartyTypes.CUSTOMER]: false,
      [EShippingPartyTypes.HOUSE_SHIPPER]: false,
      [EShippingPartyTypes.HOUSE_CONSIGNEE]: false,
      [EShippingPartyTypes.HOUSE_NOTIFY_PARTY]: false,
      [EShippingPartyTypes.EXPORT_BROKER]: false,
      [EShippingPartyTypes.IMPORT_BROKER]: false,
      [EShippingPartyTypes.SECOND_NOTIFY_PARTY]: false,
      [EShippingPartyTypes.FORWARDER_AGENT]: false,
      [EShippingPartyTypes.DELIVERY_AGENT]: false,
      [EShippingPartyTypes.ACCOUNT_HOLDER]: false,
      [EShippingPartyTypes.ULTIMATE_CUSTOMER]: false,
      [EShippingPartyTypes.OCEAN_CARRIER]: false,
      [EShippingPartyTypes.BOOKING_AGENT]: false,
      [EShippingPartyTypes.ORIGIN_PARTNER_AGENT]: false,
      [EShippingPartyTypes.DESTINATION_PARTNER_AGENT]: false,
    };

    shippingPartyList.forEach(({ role, hasSystemUpdatePolicy }) => {
      if (hasSystemUpdatePolicy) {
        shippingPartyToIsSetBySystemMap[role] = true;
      }
    });

    const roles = await R.services.moduleFunctionalityPermissions.getCurrentShipmentRoles(shipmentId) || [] as EShipmentOrganizationRole[];
    const shippingPartiesPermissions = ShippingPartyPermissionsDTM.getShippingPartyPermissions(roles, shippingPartyToIsSetBySystemMap, paymentTerms?.tradeType);

    this.dispatch(R.actions.shippingParties.setShippingPartiesPermissions(shippingPartiesPermissions));

    const companiesList = await R.services.contacts.getCompanyList();

    this.dispatch(R.actions.shippingParties.setOwnCompaniesList(companiesList));
    this.dispatch(R.actions.shippingParties.setCompanyList(companiesList));

    shippingPartyList.forEach((shippingParty) => {
      this.setShippingPartyToState(shippingParty.role, shippingParty);
    });

    const isForceCustomer = R.selectors.shippingParties.getIsForceCustomer(this.store.getState());

    if (isForceCustomer) {
      this.dispatch(R.actions.shippingParties.setIsForceCustomerBlock(false));

      await this.selectShippingParty(EShippingPartyTypes.CUSTOMER);
      this.dispatch(R.actions.shippingParties.setIsLoading(false));

      return;
    }

    await this.selectShippingParty(EShippingPartyTypes.HOUSE_SHIPPER);
    this.dispatch(R.actions.shippingParties.setIsLoading(false));
  }

  public getShippingParties = async (shipmentId: string) => {
    let shippingPartyList: ShippingPartyDTM[] = [];

    shippingPartyList = await R.services.shippingParties.getList(shipmentId);

    return shippingPartyList;
  }

  public openTab = async (route: ShippingPartyRoutes) => {
    this.dispatch(R.actions.shippingParties.setActiveTab(route));

    await this.selectShippingParty(R.selectors.shippingParties.getTabParties(this.store.getState())[0]);
  }

  public setFormToUnchangedState = async () => {
    this.dispatch(R.actions.shippingParties.revertSelectedFormData());
    this.dispatch(R.actions.shippingParties.clearErrorsForSelectedForm());

    await this.updateDataForSelectedShippingParty();
  }

  public setIsTermsAndConditionsChecked = (value: boolean) => {
    this.dispatch(R.actions.shippingParties.setChangeFormIsAgreeNra(value));

    this.dispatch(R.actions.shippingParties.setChangeFormIsAgreeNraError(Boolean(!value)));
  };

  public clearData = () => {
    apiWorker.abortAllRequests();
    this.dispatch(R.actions.shippingParties.clear());
  }

  public sendData = async (shipmentId?: string) => {
    let updatedShippingParty: ShippingPartyDTM | null = null;
    const contactList: ContactDTM[] = [];
    let addressList: AddressDTM[] = [];

    if (!shipmentId) {
      console.error('ShippingPartiesController.sendData error: no shipmentId');

      return;
    }

    this.dispatch(R.actions.shippingParties.setFormDataLoading(true));

    const selectedShippingParty = R.selectors.shippingParties.getSelectedShippingParty(this.store.getState());
    const selectedCurrentShippingParty = R.selectors.shippingParties.getSelectedCurrentShippingParty(this.store.getState());
    const selectedCurrentShippingPartyIsEmpty = !selectedCurrentShippingParty.company
      && !selectedCurrentShippingParty.address
      && !selectedCurrentShippingParty.contact;

    this.validateFormData();

    if (!(selectedShippingParty.company && selectedShippingParty.address && selectedShippingParty.contact)) {
      console.error('ShippingPartiesController.sendData error: no companyId || no addressId || no contactId');

      this.dispatch(R.actions.shippingParties.setFormDataLoading(false));

      return;
    }

    if (selectedShippingParty.isAgreeNraShow && selectedShippingParty.isAgreeNra) {
      await R.services.shipment.postConfirmations(shipmentId, ShipmentConfirmationDTM.fromPlain({
        type: EShipmentConfirmationTypes.NEGOTIATED_RATE_ARRANGEMENT,
      }));
    }

    try {
      updatedShippingParty = selectedCurrentShippingPartyIsEmpty || !selectedShippingParty.id
        ? await R.services.shippingParties.postShippingParty(+shipmentId, selectedShippingParty)
        : await R.services.shippingParties.putShippingParty(+shipmentId, selectedShippingParty);
    } catch (e) {
      console.error(e);

      message.error('Shipment parties data update error');

      this.dispatch(R.actions.shippingParties.setFormDataLoading(false));

      return;
    }

    if (!updatedShippingParty) return;

    try {
      if (updatedShippingParty?.company?.id) {
        addressList = await R.services.contacts.getAddressList(updatedShippingParty.company.id);
      }
    } catch (e) {
      console.error('Address list data request error');
    }

    this.setShippingPartyToState(updatedShippingParty.role, ShippingPartyDTM.fromPlain({
      ...updatedShippingParty,
      address: updatedShippingParty.address && ShippingPartyAddressDTM.fromPlain({
        ...updatedShippingParty.address,
        contacts: [
          ...addressList.filter((item) => item.id === updatedShippingParty?.address?.id)?.[0]?.contacts || []],
      }),
    }));

    if (updatedShippingParty.role === EShippingPartyTypes.HOUSE_CONSIGNEE) {
      this.setUpdatedShippingPartyByRole(EShippingPartyTypes.CONSIGNEE, updatedShippingParty, addressList);
    }

    if (updatedShippingParty.role === EShippingPartyTypes.CONSIGNEE) {
      this.setUpdatedShippingPartyByRole(EShippingPartyTypes.HOUSE_CONSIGNEE, updatedShippingParty, addressList);
    }

    if (updatedShippingParty.role === EShippingPartyTypes.HOUSE_NOTIFY_PARTY) {
      this.setUpdatedShippingPartyByRole(EShippingPartyTypes.NOTIFY_PARTY, updatedShippingParty, addressList);
    }

    if (updatedShippingParty.role === EShippingPartyTypes.NOTIFY_PARTY) {
      this.setUpdatedShippingPartyByRole(EShippingPartyTypes.HOUSE_NOTIFY_PARTY, updatedShippingParty, addressList);
    }

    this.dispatch(R.actions.shippingParties.setAddressList(addressList));
    this.dispatch(R.actions.shippingParties.setContactsList(contactList));

    await this.updateDataForSelectedShippingParty();

    message.success('Shipping party updated');

    this.dispatch(R.actions.shippingParties.setFormDataLoading(false));
  }

  public setUpdatedShippingPartyByRole = (role: EShippingPartyTypes, updatedShippingParty: ShippingPartyDTM, addressList: AddressDTM[]) => {
    this.setShippingPartyToState(role, ShippingPartyDTM.fromPlain({
      ...updatedShippingParty,
      address: updatedShippingParty.address && ShippingPartyAddressDTM.fromPlain({
        ...updatedShippingParty.address,
        contacts: [
          ...addressList.filter((item) => item.id === updatedShippingParty?.address?.id)?.[0]?.contacts || []],
      }),
    }));
  }

  public removeShippingParty = async (shipmentId?: string) => {
    if (!shipmentId) {
      console.error('ShippingPartiesController.removeShippingParty error: no shipmentId');

      return;
    }

    this.dispatch(R.actions.shippingParties.setFormDataLoading(true));

    const currentShippingParty = R.selectors.shippingParties.getSelectedCurrentShippingParty(this.store.getState());

    if (!currentShippingParty?.id) {
      this.dispatch(R.actions.shippingParties.revertSelectedFormData());
      this.dispatch(R.actions.shippingParties.clearErrorsForSelectedForm());

      this.dispatch(R.actions.shippingParties.setFormDataLoading(false));

      return;
    }

    try {
      await R.services.shippingParties.deleteShippingParty(shipmentId, `${currentShippingParty.id}`);
    } catch (e) {
      console.error(e);

      this.dispatch(R.actions.shippingParties.setFormDataLoading(false));

      return;
    }

    if (currentShippingParty.role === EShippingPartyTypes.HOUSE_CONSIGNEE) {
      this.dispatch(R.actions.shippingParties.removeShippingPartyByRole(EShippingPartyTypes.CONSIGNEE));
    }

    if (currentShippingParty.role === EShippingPartyTypes.CONSIGNEE) {
      this.dispatch(R.actions.shippingParties.removeShippingPartyByRole(EShippingPartyTypes.HOUSE_CONSIGNEE));
    }

    if (currentShippingParty.role === EShippingPartyTypes.HOUSE_NOTIFY_PARTY) {
      this.dispatch(R.actions.shippingParties.removeShippingPartyByRole(EShippingPartyTypes.NOTIFY_PARTY));
    }

    if (currentShippingParty.role === EShippingPartyTypes.NOTIFY_PARTY) {
      this.dispatch(R.actions.shippingParties.removeShippingPartyByRole(EShippingPartyTypes.HOUSE_NOTIFY_PARTY));
    }

    this.dispatch(R.actions.shippingParties.clearSelectedFormData());
    this.dispatch(R.actions.shippingParties.clearErrorsForSelectedForm());

    this.dispatch(R.actions.shippingParties.setFormDataLoading(false));
  }

  // list functionality
  public selectShippingParty = async (party: EShippingPartyTypes) => {
    this.dispatch(R.actions.shippingParties.setShippingPartyListLoading(true));

    this.dispatch(R.actions.shippingParties.setSelectedForm(party));

    await this.updateDataForSelectedShippingParty();

    this.dispatch(R.actions.shippingParties.setShippingPartyListLoading(false));
  };

  public setCompanyName = async (companyId: number) => {
    if (!companyId) {
      return;
    }

    const companyList = R.selectors.shippingParties.getCompanyList(this.store.getState());

    const company = companyList.find((item) => item.id === companyId);

    if (!company) {
      console.error('ShippingPartiesController.setCompanyName error: no company with selected ID in companies list');

      return;
    }

    this.dispatch(R.actions.shippingParties.setCompany(company));

    this.dispatch(R.actions.shippingParties.setAddressDataToDefault());
    this.dispatch(R.actions.shippingParties.clearAddressErrorForSelectedForm());
    this.dispatch(R.actions.shippingParties.setContactsDataToDefault());
    this.dispatch(R.actions.shippingParties.clearContactErrorForSelectedForm());

    await this.updateDataForSelectedShippingParty();
    this.dispatch(R.actions.shippingParties.setCompany(company));
  };

  public validateCompanyName = () => {
    const selectedCompany = R.selectors.shippingParties.getSelectedCompany(this.store.getState());

    this.dispatch(R.actions.shippingParties.clearCompanyErrorForSelectedForm());
    const companyError = shippingPartiesCompanyValidate(selectedCompany);
    if (companyError) {
      this.dispatch(R.actions.shippingParties.setCompanyErrorForSelectedForm(companyError));
    }
  };

  public setAddress = (addressId: number) => {
    const addressList = R.selectors.shippingParties.getAddressList(this.store.getState());

    const address = addressList.find((item) => item.id === addressId);
    if (!address) {
      console.error('ShippingPartiesController.setAddress error: no address model with selected ID in addresses list');

      return;
    }
    this.dispatch(R.actions.shippingParties.setAddress(address));
    this.dispatch(R.actions.shippingParties.setContact(undefined));

    if (address?.contacts?.length === 1) {
      this.dispatch(R.actions.shippingParties.setContact(address.contacts[0]));
    }
  };

  public validateAddress = () => {
    const address = R.selectors.shippingParties.getSelectedAddress(this.store.getState());

    this.dispatch(R.actions.shippingParties.clearAddressErrorForSelectedForm());

    const addressError = shippingPartiesAddressValidate(address);
    if (addressError) {
      this.dispatch(R.actions.shippingParties.setAddressErrorForSelectedForm(addressError));
    }
  };

  public setContactPerson = (contactId: number) => {
    const contactList = R.selectors.shippingParties.getContactList(this.store.getState());

    const contact = contactList.find((item) => item.id === contactId);
    if (!contact) {
      console.error('ShippingPartiesController.setContactPerson error: no contact with selected ID in contact list');

      return;
    }
    this.dispatch(R.actions.shippingParties.setContact(contact));
  };

  public setCity = (city: string) => {
    this.dispatch(R.actions.shippingParties.setCity(city));
  }

  public setCountry = (country: string) => {
    this.dispatch(R.actions.shippingParties.setCountry(country));
    this.dispatch(R.actions.shippingParties.setState(''));
  }

  public setCountryState = (state: string) => {
    this.dispatch(R.actions.shippingParties.setState(state));
  }

  public setPostalCode = (postalCode: string) => {
    this.dispatch(R.actions.shippingParties.setPostalCode(postalCode));
  }

  public setFullName = (name: string) => {
    this.dispatch(R.actions.shippingParties.setFullName(name));
  };

  public setEmail = (email: string) => {
    this.dispatch(R.actions.shippingParties.setEmail(email));
    this.dispatch(R.actions.shippingParties.setEmailError(undefined));
  }

  public setPhone = (phone: string) => {
    this.dispatch(R.actions.shippingParties.setPhone(phone));
  }

  public setAdditionalPhone = (additionalPhone: string) => {
    this.dispatch(R.actions.shippingParties.setAdditionalPhone(additionalPhone));
  }

  public initFormFieldsFromShippingParty = (shippingParty: ShippingPartyDTM) => {
    if (shippingParty.company) {
      this.dispatch(R.actions.shippingParties.setTaxId(shippingParty.company.taxId || ''));
    }

    if (shippingParty.address) {
      this.dispatch(R.actions.shippingParties.setAddressLine1(shippingParty.address.address1));
      this.dispatch(R.actions.shippingParties.setAddressLine2(shippingParty.address.address2 || ''));
      this.dispatch(R.actions.shippingParties.setCountry(shippingParty.address.country || ''));
      this.dispatch(R.actions.shippingParties.setCity(shippingParty.address.city || ''));
      this.dispatch(R.actions.shippingParties.setState(shippingParty.address.state || ''));
      this.dispatch(R.actions.shippingParties.setPostalCode(shippingParty.address.postalCode || ''));
    }

    if (shippingParty.contact) {
      this.dispatch(R.actions.shippingParties.setFullName(shippingParty.contact.fullName || ''));
      this.dispatch(R.actions.shippingParties.setEmail(shippingParty.contact.email || ''));
      this.dispatch(R.actions.shippingParties.setPhone(shippingParty.contact.phone || ''));
      this.dispatch(R.actions.shippingParties.setAdditionalPhone(shippingParty.contact.phone2 || ''));
    }
  }

  public updateShippingPartyContactData = async (shipmentId: string, isCustomerOrg?: boolean, tradeType?: TradeType) => {
    this.validateSelectedCompanyFields();

    const isRequiredErrorVisible = R.selectors.shippingParties.getIsRequiredErrorVisible(this.store.getState());
    const emailError = R.selectors.shippingParties.getEmailError(this.store.getState());

    if (isRequiredErrorVisible || emailError) {
      return;
    }

    this.dispatch(R.actions.shippingParties.setShippingPartyListLoading(true));

    const taxId = R.selectors.shippingParties.getTaxId(this.store.getState());
    const address1 = R.selectors.shippingParties.getAddressLine1(this.store.getState());
    const address2 = R.selectors.shippingParties.getAddressLine2(this.store.getState());
    const country = R.selectors.shippingParties.getCountry(this.store.getState());
    const city = R.selectors.shippingParties.getCity(this.store.getState());
    const countryState = R.selectors.shippingParties.getCountryState(this.store.getState());
    const postalCode = R.selectors.shippingParties.getPostalCode(this.store.getState());
    const fullName = R.selectors.shippingParties.getFullName(this.store.getState());
    const email = R.selectors.shippingParties.getEmail(this.store.getState());
    const phone = R.selectors.shippingParties.getPhone(this.store.getState());
    const phone2 = R.selectors.shippingParties.getAdditionalPhone(this.store.getState());
    const selectedShippingParty = R.selectors.shippingParties.getSelectedShippingParty(this.store.getState());
    const { houseConsignee, houseNotifyParty } = R.selectors.billOfLadingCommon.getShippingParties(this.store.getState());

    let shippingPartyToSave = ShippingPartyDTM.fromPlain({
      ...selectedShippingParty,
    });

    if (tradeType === TradeType.EXPORT && isCustomerOrg && (shippingPartyToSave.role === EShippingPartyTypes.CONSIGNEE || shippingPartyToSave.role === EShippingPartyTypes.NOTIFY_PARTY)) {
      shippingPartyToSave = ShippingPartyDTM.fromPlain({
        ...shippingPartyToSave,
        id: shippingPartyToSave.role === EShippingPartyTypes.CONSIGNEE ? houseConsignee.id : houseNotifyParty.id,
        role: shippingPartyToSave.role === EShippingPartyTypes.CONSIGNEE ? EShippingPartyTypes.HOUSE_CONSIGNEE : EShippingPartyTypes.HOUSE_NOTIFY_PARTY,
      });
    }

    if (shippingPartyToSave.company) {
      shippingPartyToSave.company.taxId = taxId;
    }

    shippingPartyToSave.address = ShippingPartyAddressDTM.fromPlain({
      id: null,
      address1: address1 || '',
      address2,
      country: country || '',
      state: countryState || '',
      city: city || '',
      postalCode: postalCode || '',
      closestPort: shippingPartyToSave?.address?.closestPort || '',
    });

    shippingPartyToSave.contact = ShippingPartyContactDTM.fromPlain({
      id: null,
      fullName: fullName || '',
      email: email || '',
      phone: phone || '',
      phone2: phone2 || '',
    });

    const updatedShippingParty = await R.services.shippingParties.putShippingPartyWithContactData(+shipmentId, shippingPartyToSave);

    if (updatedShippingParty) {
      this.setShippingPartyToState(updatedShippingParty.role, ShippingPartyDTM.fromPlain({
        ...updatedShippingParty,
      }));
    }

    await this.updateDataForSelectedShippingParty();

    this.dispatch(R.actions.shippingParties.setShippingPartyListLoading(false));
  }

  public validateContactPerson = () => {
    const contact = R.selectors.shippingParties.getSelectedContact(this.store.getState());

    this.dispatch(R.actions.shippingParties.clearContactErrorForSelectedForm());

    const contactError = shippingPartiesContactValidate(contact);
    if (contactError) {
      this.dispatch(R.actions.shippingParties.setContactErrorForSelectedForm(contactError));
    }
  };

  public setReference = (ref: ShippingPartyReference) => {
    this.dispatch(R.actions.shippingParties.updateReferences(ref));
  };

  public addReference = () => {
    const newRef = ShippingPartyReference.fromPlain({
      id: uuidv4(),
      value: '',
    });

    this.dispatch(R.actions.shippingParties.addNewReferences(newRef));
  };

  public setAddressLine1 = (addressLine: string) => {
    this.dispatch(R.actions.shippingParties.setAddressLine1(addressLine));
  }

  public setAddressLine2 = (addressLine: string) => {
    this.dispatch(R.actions.shippingParties.setAddressLine2(addressLine));
  }

  public setTaxId = (taxId: string) => {
    this.dispatch(R.actions.shippingParties.setTaxId(taxId));
  }

  public removeReference = (ref: ShippingPartyReference) => {
    this.dispatch(R.actions.shippingParties.removeReferences(ref));
  };

  public openSelectCompanyDrawer = () => {
    this.dispatch(R.actions.shippingParties.setIsSelectCompanyDrawerOpened(true));
    this.dispatch(R.actions.shippingParties.setIsSelectCompanyDrawerInAddressMode(false));
    this.dispatch(R.actions.shippingParties.setIsSelectCompanyDrawerInContactMode(false));
  };

  public openSelectCompanyDrawerInAddressMode = () => {
    this.dispatch(R.actions.shippingParties.setIsSelectCompanyDrawerOpened(true));
    this.dispatch(R.actions.shippingParties.setIsSelectCompanyDrawerInAddressMode(true));
    this.dispatch(R.actions.shippingParties.setIsSelectCompanyDrawerInContactMode(false));
  };

  public openSelectCompanyDrawerInContactMode = () => {
    this.dispatch(R.actions.shippingParties.setIsSelectCompanyDrawerOpened(true));
    this.dispatch(R.actions.shippingParties.setIsSelectCompanyDrawerInContactMode(true));
    this.dispatch(R.actions.shippingParties.setIsSelectCompanyDrawerInAddressMode(false));
  };

  public validateSelectedCompanyFields = () => {
    this.dispatch(R.actions.shippingParties.setIsRequiredErrorVisible(false));
    this.dispatch(R.actions.shippingParties.setEmailError(undefined));

    const addressLine1 = R.selectors.shippingParties.getAddressLine1(this.store.getState());
    const country = R.selectors.shippingParties.getCountry(this.store.getState());
    const city = R.selectors.shippingParties.getCity(this.store.getState());
    const countryState = R.selectors.shippingParties.getCountryState(this.store.getState());
    const postalCode = R.selectors.shippingParties.getPostalCode(this.store.getState());
    const fullName = R.selectors.shippingParties.getFullName(this.store.getState());
    const email = R.selectors.shippingParties.getEmail(this.store.getState());
    const phone = R.selectors.shippingParties.getPhone(this.store.getState());

    if (!addressLine1 || !country || (country === US_COUNTRY_CODE && !countryState) || !city || !postalCode || !fullName || !email || !phone) {
      this.dispatch(R.actions.shippingParties.setIsRequiredErrorVisible(true));
    }

    const emailValidation = validationEmail(email);

    if (emailValidation.errorMessage) {
      this.dispatch(R.actions.shippingParties.setEmailError(emailValidation.errorMessage));
    }
  };

  public saveSelectedCompany = async (shipmentId: string) => {
    this.dispatch(R.actions.shippingParties.setIsSelectCompanyDrawerLoading(true));

    const selectedShippingParty = R.selectors.shippingParties.getSelectedShippingParty(this.store.getState());
    const selectedCurrentShippingParty = R.selectors.shippingParties.getSelectedCurrentShippingParty(this.store.getState());
    const role = R.selectors.shippingParties.getSelectedFormType(this.store.getState());
    const { isAbleToWriteNRA } = R.selectors.shippingParties.getShippingPartyPermissionsByRole(role)(this.store.getState());
    const isDifferentCompanyListSelectedCompany = selectedShippingParty.company && selectedShippingParty?.company?.id !== selectedCurrentShippingParty?.company?.id;

    if (isDifferentCompanyListSelectedCompany && isAbleToWriteNRA) {
      this.dispatch(R.actions.shippingParties.setChangeFormIsAgreeNraShow(true));
    } else {
      this.dispatch(R.actions.shippingParties.setChangeFormIsAgreeNraShow(false));
      this.dispatch(R.actions.shippingParties.setChangeFormIsAgreeNraError(false));
    }

    if (!selectedShippingParty?.isAgreeNra) {
      this.dispatch(R.actions.shippingParties.setChangeFormIsAgreeNraError(true));
    } else {
      this.dispatch(R.actions.shippingParties.setChangeFormIsAgreeNraError(false));
    }

    const hasNRAError = R.selectors.shippingParties.getSelectedShippingParty(this.store.getState()).isAgreeNraError;
    const hasAgreeNRAVisible = R.selectors.shippingParties.getSelectedShippingParty(this.store.getState()).isAgreeNraShow;

    if (hasNRAError && hasAgreeNRAVisible && isAbleToWriteNRA) {
      this.dispatch(R.actions.shippingParties.setIsSelectCompanyDrawerLoading(false));

      return;
    }

    await this.sendData(shipmentId);

    this.dispatch(R.actions.shippingParties.setIsSelectCompanyDrawerLoading(false));
    this.dispatch(R.actions.shippingParties.setIsSelectCompanyDrawerOpened(false));
  }

  public closeSelectCompanyDrawer = async () => {
    this.dispatch(R.actions.shippingParties.setIsSelectCompanyDrawerLoading(true));
    await this.setFormToUnchangedState();
    this.dispatch(R.actions.shippingParties.setIsSelectCompanyDrawerLoading(false));
    this.dispatch(R.actions.shippingParties.setIsSelectCompanyDrawerOpened(false));
    this.dispatch(R.actions.shippingParties.setIsSelectCompanyDrawerInContactMode(false));
    this.dispatch(R.actions.shippingParties.setIsSelectCompanyDrawerInAddressMode(false));
  }

  public setShippingPartyToState = (shippingPartyRole: string, shippingPartyData: ShippingPartyDTM) => {
    switch (shippingPartyRole) {
      case EShippingPartyTypes.SHIPPER:
        this.dispatch(R.actions.shippingParties.setCurrentShipperFormData(shippingPartyData));
        break;

      case EShippingPartyTypes.CONSIGNEE:
        this.dispatch(R.actions.shippingParties.setCurrentConsigneeFormData(shippingPartyData));
        break;

      case EShippingPartyTypes.NOTIFY_PARTY:
        this.dispatch(R.actions.shippingParties.setCurrentNotifyPartyFormData(shippingPartyData));
        break;

      case EShippingPartyTypes.CUSTOMER:
        this.dispatch(R.actions.shippingParties.setCurrentCustomerData(shippingPartyData));
        break;

      case EShippingPartyTypes.HOUSE_SHIPPER:
        this.dispatch(R.actions.shippingParties.setCurrentHouseShipperFormData(shippingPartyData));
        break;

      case EShippingPartyTypes.HOUSE_CONSIGNEE:
        this.dispatch(R.actions.shippingParties.setCurrentHouseConsigneeFormData(shippingPartyData));
        break;

      case EShippingPartyTypes.HOUSE_NOTIFY_PARTY:
        this.dispatch(R.actions.shippingParties.setCurrentHouseNotifyPartyFormData(shippingPartyData));
        break;

      case EShippingPartyTypes.EXPORT_BROKER:
        this.dispatch(R.actions.shippingParties.setCurrentExportBrokerFormData(shippingPartyData));
        break;

      case EShippingPartyTypes.IMPORT_BROKER:
        this.dispatch(R.actions.shippingParties.setCurrentImportBrokerFormData(shippingPartyData));
        break;

      case EShippingPartyTypes.SECOND_NOTIFY_PARTY:
        this.dispatch(R.actions.shippingParties.setCurrentSecondNotifyPartyFormData(shippingPartyData));
        break;

      case EShippingPartyTypes.FORWARDER_AGENT:
        this.dispatch(R.actions.shippingParties.setCurrentForwarderAgentFormData(shippingPartyData));
        break;

      case EShippingPartyTypes.DELIVERY_AGENT:
        this.dispatch(R.actions.shippingParties.setCurrentDeliveryAgentFormData(shippingPartyData));
        break;

      case EShippingPartyTypes.ACCOUNT_HOLDER:
        this.dispatch(R.actions.shippingParties.setCurrentAccountHolderFormData(shippingPartyData));
        break;

      case EShippingPartyTypes.ULTIMATE_CUSTOMER:
        this.dispatch(R.actions.shippingParties.setCurrentUltimateCustomerFormData(shippingPartyData));
        break;

      case EShippingPartyTypes.OCEAN_CARRIER:
        this.dispatch(R.actions.shippingParties.setCurrentOceanCarrierFormData(shippingPartyData));
        break;

      case EShippingPartyTypes.BOOKING_AGENT:
        this.dispatch(R.actions.shippingParties.setCurrentBookingAgentFormData(shippingPartyData));
        break;

      default:
        break;
    }
  }

  private validateFormData = () => {
    const selectedCompany = R.selectors.shippingParties.getSelectedCompany(this.store.getState());

    const companyError = shippingPartiesCompanyValidate(selectedCompany);
    if (companyError) {
      this.dispatch(R.actions.shippingParties.setCompanyErrorForSelectedForm(companyError));
    }

    const selectedAddress = R.selectors.shippingParties.getSelectedAddress(this.store.getState());
    const addressError = shippingPartiesAddressValidate(selectedAddress);
    if (addressError) {
      this.dispatch(R.actions.shippingParties.setAddressErrorForSelectedForm(addressError));
    }

    const selectedContact = R.selectors.shippingParties.getSelectedContact(this.store.getState());
    const contactError = shippingPartiesContactValidate(selectedContact);
    if (contactError) {
      this.dispatch(R.actions.shippingParties.setContactErrorForSelectedForm(contactError));
    }
  }

  private updateDataForSelectedShippingParty = async () => {
    const companyList = R.selectors.shippingParties.getOwnCompaniesList(this.store.getState());
    const selectedShippingParty = R.selectors.shippingParties.getSelectedShippingParty(this.store.getState());
    const selectedCurrentShippingParty = R.selectors.shippingParties.getSelectedCurrentShippingParty(this.store.getState());
    const role = R.selectors.shippingParties.getSelectedFormType(this.store.getState());
    const { isAbleToWriteNRA } = R.selectors.shippingParties.getShippingPartyPermissionsByRole(role)(this.store.getState());

    if (!selectedShippingParty?.company) {
      return;
    }

    const isCompanyListHaveSelectedCompany = !!companyList.find(
      (item) => !!selectedShippingParty.company && item.id === selectedShippingParty.company.id,
    );
    const isDifferentCompanyListSelectedCompany = selectedShippingParty.company && selectedShippingParty?.company?.id !== selectedCurrentShippingParty?.company?.id;

    if (isDifferentCompanyListSelectedCompany && isAbleToWriteNRA) {
      this.dispatch(R.actions.shippingParties.setChangeFormIsAgreeNraShow(true));
    } else {
      this.dispatch(R.actions.shippingParties.setChangeFormIsAgreeNraShow(false));
    }

    if (selectedShippingParty.company?.id === selectedCurrentShippingParty.company?.id) {
      this.initFormFieldsFromShippingParty(selectedShippingParty);
    }

    if (!isCompanyListHaveSelectedCompany && selectedShippingParty.company) {
      const companyListNew = [...companyList];
      companyListNew.push(selectedShippingParty.company);

      this.dispatch(R.actions.shippingParties.setCompanyList(companyListNew));

      return;
    }

    if (companyList.find((item) => item.id === selectedCurrentShippingParty?.company?.id)) {
      this.dispatch(R.actions.shippingParties.setCurrentFormStateContactsList(true));
    }

    const targetCompany = companyList.find(({ id }) => id === selectedShippingParty?.company?.id);

    if (targetCompany?.addresses) {
      this.dispatch(R.actions.shippingParties.setAddressList(targetCompany.addresses.map((address) => AddressDTM.fromPlain({
        id: address.id,
        country: address.country,
        city: address.city,
        state: address.state,
        address1: address.address1,
        address2: address.address2,
        postalCode: address.postalCode,
        closestPort: address.closestPort,
        primary: address.isPrimary,
        contacts: [],
      }))));
    }

    if (targetCompany?.contacts) {
      this.dispatch(R.actions.shippingParties.setContactsList(targetCompany.contacts));
    }
  }
}
