import React, { FC, useCallback } from 'react';

import {
  Button,
  MinusIon,
  PlusIcon,
} from './AddOrRemoveCargo.styled';

interface AddOrRemoveCargoComponentProps {
  cargoId: number;
  isFirstCargo: boolean;
  addCargo: () => void;
  removeCargo: (cargoId: number) => void;
}

export const AddOrRemoveCargoComponent: FC<AddOrRemoveCargoComponentProps> = ({
  cargoId,
  isFirstCargo,
  addCargo,
  removeCargo,
}) => {
  const onRemoveCargo = useCallback(() => {
    removeCargo(cargoId);
  }, [cargoId, removeCargo]);

  return (
    <Button
      type="dashed"
      onClick={isFirstCargo ? addCargo : onRemoveCargo}
    >
      {isFirstCargo ? <PlusIcon /> : <MinusIon />}
    </Button>
  );
};
