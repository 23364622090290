import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { R } from 'shipment-operations/repository';
import { UC } from 'shipment-operations/controllers';
import { ShipmentDrawerContent } from 'shipment-operations/constants';

import { FooterComponent } from './Footer.component';

export const Footer = () => {
  const { shipmentId = '0' } = useParams();
  const drawerContentType = useSelector(R.selectors.shipmentDrawer.getContentType);

  const onSubmit = useMemo(() => {
    const isDraft = drawerContentType === ShipmentDrawerContent.SHIPMENT_SI_DRAFT_DOCUMENT_UPLOAD;

    if (isDraft) {
      return UC.billOfLading.submitDraftMBLDocument;
    }

    return UC.billOfLading.submitFinalMBLDocument;
  }, [drawerContentType]);

  return (
    <FooterComponent
      onCancel={UC.shipmentDrawer.closeDrawer}
      onSubmit={onSubmit}
      shipmentId={shipmentId}
    />
  );
};
