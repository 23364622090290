import { createSlice } from 'app-wrapper/createSlice';

import { RootState } from 'app-wrapper/store';

export interface ModalState {
  bookingModalVisible: boolean;
}

const initialState: ModalState = {
  bookingModalVisible: false,
};

export const modalsSlice = createSlice({
  name: 'modals',
  initialState,
  reducers: {
    openBookingModal: (state) => ({ ...state, bookingModalVisible: true }),
    hideBookingModal: (state) => ({ ...state, bookingModalVisible: false }),
  },
});

export const { openBookingModal, hideBookingModal } = modalsSlice.actions;

export const isOpenModal = (state: RootState) => state.modals.bookingModalVisible;

export const modalsSliceReducer = modalsSlice.reducer;
