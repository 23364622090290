import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';
import { UC } from 'shipment-operations/controllers';

import { AddressSectionComponent } from './AddressSection.component';

interface AddressSectionProps {
  hideDrawerButtons?: boolean;
}

const AddressSectionContainer: FC<AddressSectionProps> = ({ hideDrawerButtons }) => {
  const isSelectedCompanyRelatesToUser = useSelector(R.selectors.shippingParties.getIsSelectedCompanyRelatesToUser);
  const selectedShippingParty = useSelector(R.selectors.shippingParties.getSelectedFormType);
  const permissionsForRole = useSelector(R.selectors.shippingParties.getShippingPartyPermissionsByRole(selectedShippingParty));

  return (
    <AddressSectionComponent
      openContactDrawer={UC.shippingParties.openSelectCompanyDrawerInContactMode}
      openAddressDrawer={UC.shippingParties.openSelectCompanyDrawerInAddressMode}
      hideDrawerButtons={hideDrawerButtons}
      isSelectedCompanyRelatesToUser={isSelectedCompanyRelatesToUser}
      isAbleToEditAddressAndContact={permissionsForRole.isAbleToEditAddressAndContact}
    />
  );
};

const AddressSectionContainerCached = React.memo(AddressSectionContainer);

export { AddressSectionContainerCached as AddressSection };
