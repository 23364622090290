import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';

import { R } from 'shipment-operations/repository';
import { R as userManagementR } from 'user-management/repository';
import { UC } from 'shipment-operations/controllers';
import { PermissionAttributePolicy } from 'shipment-operations/constants';
import { RouteNames } from 'app-wrapper/constants';
import { EOrganizationMemberRole } from 'user-management/constants';

import { SBLReferencesInformationComponent } from './SBLReferencesInformation.component';

const SBLReferencesInformationContainer = () => {
  const { t } = useTranslation();
  const { shipmentId = '0' } = useParams();
  const navigate = useNavigate();

  const additionalInformationReference = useSelector(R.selectors.billOfLadingCommon.getAdditionalInformationReference);
  const exportCustoms = useSelector(R.selectors.billOfLadingCommon.getExportCustoms);
  const permissions = useSelector(R.selectors.shipment.getSIPermissions);
  const isOriginUS = useSelector(R.selectors.billOfLadingCommon.getIsOriginUS);
  const isHBLPage = useSelector(R.selectors.billOfLadingCommon.getIsHBLPage);
  const hasAdditionalServicesWithITN = useSelector(R.selectors.billOfLadingCommon.getHasAdditionalServicesWithITNActivity);
  const currentOrgRole = useSelector(userManagementR.selectors.userOrganizationData.getUserOrganisationRole);
  const isCustomer = currentOrgRole === EOrganizationMemberRole.CUSTOMER;

  const shipmentAdditionalDocumentsURL = useMemo(() => RouteNames.SHIPMENT_DOCUMENTS_ADDITIONAL(shipmentId), [shipmentId]);

  const isAbleToEdit = useMemo(() => !isHBLPage && permissions.editSISections === PermissionAttributePolicy.WRITE, [permissions, isHBLPage]);

  const handleEditExportEntry = useCallback(() => {
    if (hasAdditionalServicesWithITN) {
      navigate(RouteNames.SHIPMENT_ADDITIONAL_SERVICES(shipmentId));

      return;
    }

    UC.shipmentDrawer.openEditExportClearanceDrawer();
  }, [hasAdditionalServicesWithITN, navigate, shipmentId]);

  const items = useMemo(() => {
    const references = isOriginUS ? [
      {
        title: t('Export Entry Number'),
        subtitle: exportCustoms?.number,
        onAction: isAbleToEdit ? handleEditExportEntry : undefined,
        redirectURL: '',
        isWaitingFromSkypace: isCustomer && hasAdditionalServicesWithITN,
      },
    ] : [];

    if (additionalInformationReference) {
      references.push({
        title: t('Shipment Reference'),
        subtitle: additionalInformationReference,
        onAction: undefined,
        redirectURL: '',
        isWaitingFromSkypace: false,
      });
    }

    return references;
  }, [additionalInformationReference, exportCustoms, shipmentAdditionalDocumentsURL, isAbleToEdit, handleEditExportEntry, isOriginUS, isCustomer, hasAdditionalServicesWithITN]);

  return (
    <SBLReferencesInformationComponent items={items} mblPagePlacement={!isAbleToEdit} />
  );
};

export { SBLReferencesInformationContainer as SBLReferencesInformation };
