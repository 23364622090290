import React from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';

import { ConsigneeInformationComponent } from './ConsigneeInformation.component';

const ConsigneeInformationContainer = () => {
  const isContentUpdating = useSelector(R.selectors.bookingWizard.getIsContentUpdating);
  const isShowConsigneeSection = useSelector(R.selectors.bookingWizard.getShouldShowConsigneeSection);

  return (
    <ConsigneeInformationComponent
      isPending={isContentUpdating}
      isShowSection={isShowConsigneeSection}
    />
  );
};

export { ConsigneeInformationContainer };
