import { v4 as uuidv4 } from 'uuid';
import toNumber from 'lodash/fp/toNumber';

import { apiWorker } from 'app-wrapper/repository/utilsServices';
import {
  IGetBillOfLadingReponse,
  IPostBillOfLadingRequest,
  IPostBillOfLadingResponse,
  IPutBillOfLadingRequest,
  IPutBillOfLadingResponse,
} from 'shipment-operations/models/contracts';
import { BillOfLadingDTM, IShippingPartiesMapDTM, IBillOfLadingDTM } from 'shipment-operations/models/dtm';
import { BillOfLadingType } from 'shipment-operations/constants';

export class BillOfLadingService {
  private formatUrl = (shipmentId?: string) => (
    `/shipment-service/api/v1/shipments/${shipmentId}/bill-of-lading`
  );

  public getBillOfLading = async (shipmentId: string, shippingPartiesMap: IShippingPartiesMapDTM = {}) => {
    let billOfLading: BillOfLadingDTM | null = null;

    // TODO: Ask back-end not to send empty body
    const response = await apiWorker.requestGet<IGetBillOfLadingReponse>(this.formatUrl(shipmentId));

    if (response.status === 204) {
      return undefined;
    }

    const body = response.data;

    billOfLading = BillOfLadingDTM.fromPlain({
      id: body.id,
      type: body.type,
      copies: body.copies.map(({ sendToId, number, freighted }) => ({
        id: uuidv4(),
        party: shippingPartiesMap[sendToId],
        amount: `${number}`,
        freighted,
      })),
      originals: body.originals.map(({ sendToId, number, freighted }) => ({
        id: uuidv4(),
        party: shippingPartiesMap[sendToId],
        amount: `${number}`,
        freighted,
      })),
    });

    return billOfLading;
  };

  public postBillOfLading = async (shipmentId: string, data: IBillOfLadingDTM, shippingPartiesMap: IShippingPartiesMapDTM = {}) => {
    if (!data.type) {
      return null;
    }

    const billOfLadingRequestData: IPostBillOfLadingRequest = {
      type: data.type,
      copies: data.copies.map(({ party, amount, freighted }) => {
        const sendToIndex: number = +(Object.keys(shippingPartiesMap).find((id) => (
          shippingPartiesMap[+id] === party
        )) || '');

        return ({
          sendToId: sendToIndex,
          number: toNumber(amount),
          freighted,
        });
      }),
      originals: data.originals.map(({ party, amount, freighted }) => {
        const sendToIndex: number = +(Object.keys(shippingPartiesMap).find((id) => (
          shippingPartiesMap[+id] === party
        )) || '');

        return ({
          sendToId: sendToIndex,
          number: toNumber(amount),
          freighted,
        });
      }),
    };

    if (billOfLadingRequestData.type === BillOfLadingType.EBL) {
      billOfLadingRequestData.originals = [];
    }

    let billOfLading: BillOfLadingDTM | null = null;

    const response = await apiWorker.requestPost<IPostBillOfLadingResponse>(this.formatUrl(shipmentId), billOfLadingRequestData);
    const body = response.data;

    billOfLading = BillOfLadingDTM.fromPlain({
      id: body.id,
      type: body.type,
      copies: body.copies ? body.copies.map(({ sendToId, number, freighted }) => ({
        id: uuidv4(),
        party: shippingPartiesMap[sendToId],
        amount: `${number}`,
        freighted,
      })) : [{ id: uuidv4(), amount: '3', freighted: false }],
      originals: body.originals ? body.originals.map(({ sendToId, number, freighted }) => ({
        id: uuidv4(),
        party: shippingPartiesMap[sendToId],
        amount: `${number}`,
        freighted,
      })) : [{ id: uuidv4(), amount: '3', freighted: false }],
    });

    return billOfLading;
  };

  public putBillOfLading = async (shipmentId: string, data: IBillOfLadingDTM, shippingPartiesMap: IShippingPartiesMapDTM = {}) => {
    if (!data.type) {
      return null;
    }

    const billOfLadingRequestData: IPutBillOfLadingRequest = {
      id: data.id,
      type: data.type,
      copies: data.copies.map(({ party, amount, freighted }) => {
        const sendToIndex: number = +(Object.keys(shippingPartiesMap).find((id) => (
          shippingPartiesMap[+id] === party
        )) || '');

        return ({
          sendToId: sendToIndex,
          number: toNumber(amount),
          freighted,
        });
      }),
      originals: data.originals.map(({ party, amount, freighted }) => {
        const sendToIndex: number = +(Object.keys(shippingPartiesMap).find((id) => (
          shippingPartiesMap[+id] === party
        )) || '');

        return ({
          sendToId: sendToIndex,
          number: toNumber(amount),
          freighted,
        });
      }),
    };

    if (billOfLadingRequestData.type === BillOfLadingType.EBL) {
      billOfLadingRequestData.originals = [];
    }

    let billOfLading: BillOfLadingDTM | null = null;

    const response = await apiWorker.requestPut<IPutBillOfLadingResponse>(this.formatUrl(shipmentId), billOfLadingRequestData);
    const body = response.data;

    billOfLading = BillOfLadingDTM.fromPlain({
      id: body.id,
      type: body.type,
      copies: body.copies ? body.copies.map(({ sendToId, number, freighted }) => ({
        id: uuidv4(),
        party: shippingPartiesMap[sendToId],
        amount: `${number}`,
        freighted,
      })) : [{ id: uuidv4(), amount: '3', freighted: false }],
      originals: body.originals ? body.originals.map(({ sendToId, number, freighted }) => ({
        id: uuidv4(),
        party: shippingPartiesMap[sendToId],
        amount: `${number}`,
        freighted,
      })) : [{ id: uuidv4(), amount: '3', freighted: false }],
    });

    return billOfLading;
  };
}
