import { BaseController, controller } from 'proto/BaseController';
import { ChargeDTM } from 'shipment-operations/models/dtm';
import { R } from 'shipment-operations/repository';

@controller
export class ShipmentAccessorialController extends BaseController {
  loadData = async (shipmentId?: string) => {
    if (!shipmentId) {
      return;
    }
    let response: ChargeDTM[] | null;

    this.dispatch(R.actions.shipmentAccessorial.setIsLoading(true));
    try {
      response = await R.services.shipmentAccessorial.getCharges(shipmentId);
    } catch (e) {
      this.dispatch(R.actions.shipmentAccessorial.setError(true));
      return;
    }
    this.dispatch(R.actions.shipmentAccessorial.setData(response));
  }
}
