import styled from 'styled-components';

import themesColors from 'app-wrapper/view/themes/themesColors';
import { Button } from 'app-wrapper/view/components';
import { divFlex } from 'app-wrapper/view/themes/mixin';

export const RateItemWrapper = styled.div.attrs({
  'data-class': 'RateItemWrapper',
})`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
  align-items: center;
  height: 64px;
  width: 100%;
  padding: 16px;

  background-color: ${themesColors.neutralBranding1};

  .ant-upload-disabled {
    button {
      cursor: not-allowed;
    }
  }
`;

export const RateItemTemplateButton = styled(Button).attrs({
  'data-class': 'RateItemTemplateButton',
})`
  margin-right: 12px;
  display: block;
  padding: 0;
`;

export const RateItemName = styled.span.attrs({
  'data-class': 'RateItemName',
})`
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
`;

export const RateItemWarningMessage = styled.div.attrs((props: {
  isError: boolean
  'data-class'?: string
}) => ({
  isError: props.isError,
  'data-class': props?.['data-class'] || 'RateItemWarningMessage',
}))`
  ${divFlex}
  margin-left: auto;

  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: ${({ theme }) => theme?.themesColors?.neutralBranding10};

  height: 100%;
  justify-content: center;
  align-items: center;
  padding: 5px 16px;

  border: 1px solid;
  border-color: ${({ theme, isError }) => (isError ? theme?.themesColors?.dustRed : theme?.themesColors?.polarGreen3)};
  background-color: ${({ theme, isError }) => (isError ? theme?.themesColors?.redBranding1 : theme?.themesColors?.polarGreen1)};
`;

export const RateItemWarningMessageIcon = styled.div.attrs((props: {
  isError: boolean
  'data-class'?: string
}) => ({
  isError: props.isError,
  'data-class': props?.['data-class'] || 'RateItemWarningMessageIcon',
}))`
  ${divFlex}
  margin-right: 10px;

  color: ${({ theme, isError }) => (isError ? theme?.themesColors?.characterBrandingDanger : theme?.themesColors?.characterBrandingSuccess65)};
`;

export const RateItemWarningCloseIcon = styled.div.attrs((props: {
  isError: boolean
  'data-class'?: string
}) => ({
  isError: props.isError,
  'data-class': props?.['data-class'] || 'RateItemWarningCloseIcon',
}))`
  ${divFlex}
  margin-left: 10px;
  cursor: pointer;

  color: ${({ theme }) => theme?.themesColors?.secondaryDot45};
`;

export const RateItemDownloadButton = styled(Button).attrs((props: {
  isHasLeftBlock: boolean
  'data-class'?: string
}) => ({
  isHasLeftBlock: props.isHasLeftBlock,
  'data-class': props?.['data-class'] || 'RateItemDownloadButton',
}))`
  margin-right: 8px;
  margin-left: ${({ isHasLeftBlock }) => (isHasLeftBlock ? '8px' : 'auto')};

  color: ${({ theme }) => theme?.themesColors?.neutralBranding7};

  > span:nth-child(2) {
    color: ${({ theme }) => theme?.themesColors?.neutralBranding10};
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }

  &:hover > span:nth-child(2) {
    color: inherit;
  }
`;

export const RateItemUploadButton = styled(Button).attrs({
  'data-class': 'RateItemUploadButton',
})`
  margin-right: 0;
  margin-left: 8px;

  .anticon-loading {
    margin-left: 8px;
  }
`;
