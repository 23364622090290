import React, { FC, ReactNode } from 'react';

import { ExpandIcon } from 'app-wrapper/view/components/ExpandIcon';

import {
  StyledCollapse,
  StyledPanel,
} from './Collapse.styled';

interface ICollapseProps {
  header: ReactNode | string
  selectionKey: string | number
  defaultActive?: boolean
}

export const Collapse: FC<ICollapseProps> = ({
  defaultActive,
  selectionKey,
  header,
  children,
}) => {
  const defaultActiveKey = defaultActive ? [selectionKey] : undefined;

  return (
    <StyledCollapse defaultActiveKey={defaultActiveKey} expandIcon={ExpandIcon} ghost>
      <StyledPanel key={selectionKey} header={header}>
        {children}
      </StyledPanel>
    </StyledCollapse>
  );
};
