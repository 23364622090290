import React, { FC, useCallback, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import Alert from 'antd/es/alert';

import { hasAccess } from 'app-wrapper/utils';
import {
  ContainersChangesWarning,
  ManualBookingDrawer,
  RollShipmentDrawer,
  ShipmentCanceled,
  SubmitAmendments,
} from 'shipment-operations/view/components';
import { TrackerHeader } from 'shipment-operations/view/components/ShipmentTrackerHeader';
import { ShipmentPreviewDTM } from 'shipment-operations/models/dtm';
import { PermissionAttributePolicy, ShipmentBookingStatusEnum, ShipmentStatusEnum } from 'shipment-operations/constants';
import {
  WarningButton,
} from 'shipment-operations/view/components/ContainersChangesWarning/ContainersChangesWarning.styled';

import { Wrapper } from 'app-wrapper/view/guideline/Wrapper';
import { RouteNames } from 'app-wrapper/constants';
import {
  AlertsTable,
  CancelShipmentModal,
  CustomerBookingConfirmation,
  Dashboard,
  DashboardsCustomer,
  // RollWarningBlock,
  ShipmentOverviewAlertBar,
  ShipmentOverviewCargos,
  ShipmentOverviewContainers,
  ShipmentOverviewReferences,
  ShipmentOverviewShippingParties,
  ShipmentOverviewTitle,
  SOScaffold,
  StatisticsTasksOverview,
  TermsAgreementDrawer,
} from './components';
import { ShipmentOverviewLayout } from './ShipmentOverview.styled';

interface IShipmentOverviewComponentProps {
  loadData: (shipmentId: string) => void;
  clear: () => void;
  toggleMismatchDrawer: () => void;
  toggleChangesDrawer: () => void;
  toggleMaerskDrawer: () => void;
  permissions?: PermissionAttributePolicy
  shipment?: ShipmentPreviewDTM;
  isShowWarning?: boolean
  isShowMismatchWarning?: boolean
  isMaersk: boolean
  partnerWarning?: boolean
  shouldRefresh?: boolean
  isRequestTheSame?: boolean
  isLoading?: boolean
  isDestinationPartner?: boolean
  isSIRequestTheSame?: boolean
}

export const ShipmentOverviewComponent: FC<IShipmentOverviewComponentProps> = ({
  loadData,
  clear,
  shipment,
  permissions,
  toggleChangesDrawer,
  toggleMismatchDrawer,
  toggleMaerskDrawer,
  isShowWarning,
  isShowMismatchWarning,
  isMaersk,
  partnerWarning,
  shouldRefresh,
  isRequestTheSame,
  isLoading,
  isDestinationPartner,
  isSIRequestTheSame,
}) => {
  const { t } = useTranslation();
  const { shipmentId = '' } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    loadData(shipmentId);

    return clear;
  }, []);

  useEffect(() => {
    if (shouldRefresh) {
      loadData(shipmentId);
    }
  }, [shouldRefresh]);

  const isCustomer = hasAccess(permissions, PermissionAttributePolicy.READ);

  const handleOpen = () => {
    if (isMaersk && !isCustomer && (shipment?.bookingStatus === ShipmentBookingStatusEnum.BOOKING_SUBMITTED)) {
      toggleMaerskDrawer();
    } else {
      toggleChangesDrawer();
    }
  };

  const isCancelled = shipment?.status === ShipmentStatusEnum.CANCELLED;

  const routeMBL = useCallback(
    () => {
      navigate(RouteNames.SHIPMENT_BILL_OF_LADING(shipmentId));
    },
    [],
  );

  return (
    <Wrapper>
      {(isRequestTheSame === false) && !isCustomer && !isLoading && !isCancelled && (
        <SubmitAmendments />
      )}
      {isShowWarning && !isLoading && !isCancelled && (
        <ContainersChangesWarning
          status={shipment?.bookingStatus}
          handleDrawerOpen={handleOpen}
          isRequestTheSame={shipment?.isRequestTheSameWithShipment}
          isCustomer={isCustomer}
          isMaersk={isMaersk}
          partnerWarning={partnerWarning}
        />
      )}
      <ShipmentOverviewLayout>
        <ShipmentOverviewTitle />
        {(isSIRequestTheSame === false) && !isLoading && !isDestinationPartner && !isCancelled && (
          <Alert
            showIcon
            type="warning"
            message={t('You have made amendments to the Bill of Lading that require confirmation from the carrier. Please review and submit.')}
            action={(
              <WarningButton size="middle" type="primary" onClick={routeMBL}>
                {t('view')}
              </WarningButton>
            )}
          />
        )}
        {isShowMismatchWarning && !isDestinationPartner && !isLoading && !isCancelled && (
          <Alert
            showIcon
            type="warning"
            message={t('Carrier has made amendments to the shipment')}
            action={(
              <WarningButton size="middle" type="primary" onClick={toggleMismatchDrawer}>
                {t('seeDetails')}
              </WarningButton>
            )}
          />
        )}

        {/* temporarily hidden */}
        {/* {<RollWarningBlock />} */}
        {!isLoading ? <CustomerBookingConfirmation /> : null}
        {!isLoading && shipment?.cancelReasonDto?.message && (
          // <WarningWrapper>
          <ShipmentCanceled
            message={shipment?.cancelReasonDto?.message}
          />
          // </WarningWrapper>
        )}

        <ManualBookingDrawer />
        <RollShipmentDrawer />
        <TermsAgreementDrawer />
        <CancelShipmentModal />
        <ShipmentOverviewAlertBar />

        <SOScaffold
          taskTable={<AlertsTable />}
          statisticsTable={<StatisticsTasksOverview />}
          trackerInfo={<TrackerHeader isPageLoading={Boolean(isLoading)} skeletonHeight="169px" withEdit={false} />}
          shippingPartiesInfo={<ShipmentOverviewShippingParties />}
          referencesInfo={<ShipmentOverviewReferences />}
          cargoInfo={<ShipmentOverviewCargos />}
          containersInfo={<ShipmentOverviewContainers />}
          dashboard={permissions === PermissionAttributePolicy.WRITE ? <Dashboard /> : <DashboardsCustomer />}
        />
      </ShipmentOverviewLayout>
    </Wrapper>
  );
};
