import React from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';
import { UC } from 'shipment-operations/controllers';

import { TermsAgreementDrawerComponent } from './TermsAgreementDrawer.component';

const TermsAgreementDrawerContainer = () => {
  const isWizardOpened = useSelector(R.selectors.overview.getIsCustomerBookingDrawerOpened);
  const isUsShipmentOriginOrDestination = Boolean(useSelector(R.selectors.shipment.getShipment)?.isUsShipmentOriginOrDestination());

  return (
    <TermsAgreementDrawerComponent
      isLoading={false}
      onClose={UC.overview.closeTermsAgreementDrawer}
      isOpen={isWizardOpened}
      showNRA={isUsShipmentOriginOrDestination}
    />
  );
};

export { TermsAgreementDrawerContainer as TermsAgreementDrawer };
