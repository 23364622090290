import React, { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import {
  RCQNoQuoteNoSchedulesErrorSvg, RFRRatesRequestSvg, SkypaceSpin,
} from 'app-wrapper/view/icons';
import { Button } from 'app-wrapper/view/components';

import {
  RCQNoQuoteErrorContent,
  RCQNoQuoteErrorContentWrap, RCQNoQuoteErrorHeader, RCQNoQuoteErrorHeaderTitle, RCQNoQuoteErrorContentWrapImage, RCQNoQuoteErrorWrap, RCQNoQuoteErrorContentLink, RCQNoQuoteErrorTwoBtn,
} from './RCQNoQuoteError.styled';
import { ModalRateDate } from './components/ModalRateDate';

interface IRCQNoQuoteErrorComponentProps {
  nameLogo?: string;
  step1?: boolean;
  step2?: boolean;
  step3?: boolean;
  isLoading?: boolean;
  isOpenModalRate?: boolean;
  onClickOpenModal: () => void
  onClickEmptyQuotaAndSchedule: () => void
  onClickModifyQuotaAndSchedule: () => void
  onClickPullSchedule: () => void
}

const RCQNoQuoteErrorComponent = (props: IRCQNoQuoteErrorComponentProps) => {
  const { t } = useTranslation();
  const {
    nameLogo,
    step1 = true,
    step2,
    step3,
    isLoading,
    isOpenModalRate,
    onClickOpenModal,
    onClickEmptyQuotaAndSchedule,
    onClickModifyQuotaAndSchedule,
    onClickPullSchedule,
  } = props;

  const onClickEmptyQuotaAndScheduleHandler = useCallback(
    () => onClickEmptyQuotaAndSchedule(),
    [onClickEmptyQuotaAndSchedule],
  );

  const onClickModifyQuotaAndScheduleHandler = useCallback(
    () => onClickModifyQuotaAndSchedule(),
    [onClickModifyQuotaAndSchedule],
  );

  const onClickPullScheduleHandler = useCallback(
    () => onClickPullSchedule(),
    [onClickPullSchedule],
  );

  return (
    <RCQNoQuoteErrorWrap>
      <RCQNoQuoteErrorContentWrap>

        {isOpenModalRate && (<ModalRateDate />)}

        {isLoading && (
          <SkypaceSpin />
        )}

        {step1 && !isLoading && (
          <>
            <RCQNoQuoteErrorContentWrapImage>
              <RCQNoQuoteNoSchedulesErrorSvg />
            </RCQNoQuoteErrorContentWrapImage>

            <RCQNoQuoteErrorHeader>
              {nameLogo || t('No quotes found') || ''}
            </RCQNoQuoteErrorHeader>
            <RCQNoQuoteErrorHeaderTitle>
              {t('We checked but couldn’t find any available sailing schedules.') || ''}
            </RCQNoQuoteErrorHeaderTitle>
            <RCQNoQuoteErrorHeaderTitle>
              {t('Please modify your request or check Origin/Destination points.') || ''}
            </RCQNoQuoteErrorHeaderTitle>
          </>
        )}

        {step2 && !isLoading && (
          <>
            <RCQNoQuoteErrorContentWrapImage>
              <RCQNoQuoteNoSchedulesErrorSvg />
            </RCQNoQuoteErrorContentWrapImage>

            <RCQNoQuoteErrorHeader>
              {t('No quotes found')}
            </RCQNoQuoteErrorHeader>
            <RCQNoQuoteErrorHeaderTitle>
              {t('We couldn\'t find any quotes matching your request.') || ''}
            </RCQNoQuoteErrorHeaderTitle>
            <RCQNoQuoteErrorHeaderTitle>
              {t('You can consider modifying your search or you can request us to add rates (up to 48 hrs) and will be notified once they`re added.') || ''}
            </RCQNoQuoteErrorHeaderTitle>
          </>
        )}

        {step3 && !isLoading && (
          <>
            <RCQNoQuoteErrorContentWrapImage>
              <RFRRatesRequestSvg />
            </RCQNoQuoteErrorContentWrapImage>

            <RCQNoQuoteErrorHeaderTitle>
              {nameLogo || t('Thank you! Our team is working on it.') || ''}
            </RCQNoQuoteErrorHeaderTitle>
            <RCQNoQuoteErrorHeaderTitle>
              {t('You will be notified by email once we have quotes for you, within the next 72 hours.') || ''}
            </RCQNoQuoteErrorHeaderTitle>
          </>
        )}

        {!isLoading && (
          <RCQNoQuoteErrorContent>
            <RCQNoQuoteErrorContentLink>
              {step1 && (
                <RCQNoQuoteErrorTwoBtn>
                  <Button
                    htmlType="submit"
                    size="large"
                    onClick={onClickModifyQuotaAndScheduleHandler}
                  >
                    {t('Modify Request') || ''}
                  </Button>
                  <Button
                    type="default"
                    htmlType="submit"
                    size="large"
                    onClick={onClickEmptyQuotaAndScheduleHandler}
                  >
                    {t('New Request') || ''}
                  </Button>
                </RCQNoQuoteErrorTwoBtn>
              )}

              {step2 && (
                <RCQNoQuoteErrorTwoBtn>
                  <Button
                    htmlType="submit"
                    size="middle"
                    onClick={onClickModifyQuotaAndScheduleHandler}
                  >
                    {t('Modify Your Search') || ''}
                  </Button>
                  <Button
                    type="default"
                    htmlType="submit"
                    size="middle"
                    onClick={onClickOpenModal}
                  >
                    {t('Request Rates') || ''}
                  </Button>
                </RCQNoQuoteErrorTwoBtn>
              )}

              {step3 && (
                <Button
                  htmlType="submit"
                  size="large"
                  onClick={onClickPullScheduleHandler}
                >
                  {t('New Request') || ''}
                </Button>
              )}
            </RCQNoQuoteErrorContentLink>
          </RCQNoQuoteErrorContent>
        )}

      </RCQNoQuoteErrorContentWrap>
    </RCQNoQuoteErrorWrap>
  );
};

const RCQNoQuoteErrorComponentCache = memo(RCQNoQuoteErrorComponent);

export { RCQNoQuoteErrorComponentCache as RCQNoQuoteErrorComponent };
