import { RootState } from 'app-wrapper/store';
import { createSelector } from 'reselect';

const localState = (state: RootState) => state.auth;

const getResponseError = createSelector(
  localState,
  (state) => state.error,
);

const getIsLoading = createSelector(
  localState,
  (state) => state.isLoading,
);

const getIsLoggedIn = createSelector(
  localState,
  (state) => Boolean(state.email && state.user.firstName && state.user.lastName && state.name),
);

const getWasSignUpSuccessful = createSelector(
  localState,
  (state) => state.wasSignUpSuccessful,
);

const getUser = createSelector(
  localState,
  (state) => state.user,
);

const getUserFullName = createSelector(
  localState,
  (state) => `${state.user.firstName} ${state.user.lastName}`,
);

const getEmail = createSelector(
  localState,
  (state) => state.email,
);

const getPassword = createSelector(
  localState,
  (state) => state.password,
);

const getEmailError = createSelector(
  localState,
  (state) => state.errors.email.message,
);

const getPasswordError = createSelector(
  localState,
  (state) => state.errors.password.message,
);

const getAuthState = createSelector(
  localState,
  (state) => state,
);

export const AuthSelectors = {
  getAuthState,
  getEmail,
  getEmailError,
  getIsLoading,
  getIsLoggedIn,
  getPassword,
  getPasswordError,
  getResponseError,
  getUser,
  getUserFullName,
  getWasSignUpSuccessful,
};
