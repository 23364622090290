import styled from 'styled-components';
import Tag from 'antd/es/tag';
import { colors } from 'app-wrapper/view/themes/themesColors';

export const HeaderLayout = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
  justify-content: space-between;

  width: 100%;
`;

export const HeaderSection = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;
`;

export const HeaderShippingParty = styled.p`
  margin-bottom: 0;

  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
`;

export const HeaderCompanyName = styled.p`
  margin-bottom: 0;

  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  opacity: 0.8;
`;

export const ReferenceTag = styled(Tag)`
  margin: 0;
  height: 22px;
  border: 1px solid ${colors.purple15};

  & .ant-tag {
    font-size: 12px;
  }
`;

export const WhiteReferenceTag = styled(ReferenceTag)`
  background-color: white;
`;
