import styled from 'styled-components';

import { divFlex } from 'app-wrapper/view/themes/mixin';

export const RCQLoaderCompleteFollowWrap = styled.div.attrs({
  'data-class': 'RCQLoaderCompleteFollowWrap',
})`
  ${divFlex}
  width: 320px;
  height: 256px;

  border-radius: 3px;
  border: 1px solid ${({ theme }) => theme.themesColors.neutralBranding4};
  background: ${({ theme }) => theme.themesColors.neutralBranding1};
`;

export const RCQLoaderCompleteFollowContentWrap = styled.div.attrs({
  'data-class': 'RCQLoaderCompleteFollowContentWrap',
})`
  ${divFlex}
  flex-direction: column;
  width: 100%;

  padding: 24px 8px 24px 24px;
`;

export const RCQLoaderCompleteFollowContentWrapImage = styled.div.attrs((props: {
  image: string
}) => ({
  image: props.image,
  'data-class': 'RCQLoaderCompleteFollowContentWrapImage',
}))`
  ${divFlex}
  position: absolute;
  width: 100%;
  height: 100%;
  justify-content: end;
  top: 16px;
`;

export const RCQLoaderCompleteFollowHeader = styled.div.attrs({
  'data-class': 'RCQLoaderCompleteFollowHeader',
})`
  ${divFlex}
  align-items: center;

  height: 74px;
  padding-bottom: 18px;
  border-bottom: dashed 1px ${({ theme }) => theme.themesColors.neutralBranding4};
`;

export const RCQLoaderCompleteFollowHeaderLogo = styled.div.attrs({
  'data-class': 'RCQLoaderCompleteFollowHeaderLogo',
})`
  ${divFlex}
`;

export const RCQLoaderCompleteFollowHeaderTitle = styled.div.attrs({
  'data-class': 'RCQLoaderCompleteFollowHeaderTitle',
})`
  ${divFlex}
  align-items: center;

  font-weight: 500;
  font-size: 20px;
  padding-right: 16px;
  color: ${({ theme }) => theme.themesColors.neutralBranding10};
`;

export const RCQLoaderCompleteFollowContent = styled.div.attrs({
  'data-class': 'RCQLoaderCompleteFollowContent',
})`
  ${divFlex}
  flex-direction: column;
  justify-content: center;
  width: 100%;

  height: 112px;
  margin-top: 20px;
`;

export const RCQLoaderCompleteFollowStep = styled.div.attrs((props: {
  marginTop: string
}) => ({
  marginTop: props.marginTop,
  'data-class': 'RCQLoaderCompleteFollowStep',
}))`
  ${divFlex}
  align-items: center;
  width: 100%;

  margin-top: ${({ marginTop }) => marginTop || ''};
  height: 22px;
`;

export const RCQLoaderCompleteFollowStepLoading = styled.div.attrs((props: {
  isLoading: boolean
}) => ({
  isLoading: props.isLoading,
  'data-class': 'RCQLoaderCompleteFollowStepLoading',
}))`
  ${divFlex}
  align-items: center;
  height: 2px;

  position: absolute;
  background-color: ${({ theme }) => theme.themesColors.primaryBranding6};
  width: 100%;
  border-radius: 2px;
  bottom: 0;
  left: 0;
  animation: ${({ isLoading }) => isLoading && 'progress 160s infinite linear'};
  transition: background-color 0.1s;

  @keyframes progress {
      0%{
        width: 0%;
      }
      2%{
        width: 50%;
      }
      20%{
        width: 80%;
      }
      100%{
          width: 100%;
      }
  };
`;

export const RCQLoaderCompleteFollowStepLogo = styled.div.attrs({
  'data-class': 'RCQLoaderCompleteFollowStepLogo',
})`
  ${divFlex}
  align-items: center;
  justify-content: center;

  font-size: 14px;
  font-weight: 400;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: ${({ theme }) => (theme.themesColors.neutralBranding3)};
  color: ${({ theme }) => (theme.themesColors.secondaryDot45)};
`;

export const RCQLoaderCompleteFollowStepLogoIcon = styled.div.attrs({
  'data-class': 'RCQLoaderCompleteFollowStepLogoIcon',
})`
  ${divFlex}
  position: absolute;
  align-items: center;
  justify-content: center;

  width: 20px;
  height: 20px;
`;

export const RCQLoaderCompleteFollowStepDescription = styled.div.attrs((props: {
  isLoading: boolean
}) => ({
  isLoading: props.isLoading,
  'data-class': 'RCQLoaderCompleteFollowStepDescription',
}))`
  ${divFlex}
  align-items: center;

  font-weight: 400;
  font-size: 14px;
  margin-left: 8px;
  color: ${({ theme, isLoading }) => (isLoading ? theme.themesColors.secondaryDot45 : theme.themesColors.neutralBranding10)};
  transition: color 0.4s;
`;

export const RCQLoaderCompleteFollowStepDescriptionIcon = styled.div.attrs({
  'data-class': 'RCQLoaderCompleteFollowStepDescriptionIcon',
})`
  ${divFlex}
  align-items: center;
  margin-top: 6px;
  margin-left: 9px;
`;
