import React, {
  FC, memo,
} from 'react';
import { useTranslation } from 'react-i18next';

import { useWindowSize } from 'app-wrapper/hooks';
import { TypeSwitch } from 'app-wrapper/view/components';

import {

  ICompanyAddressDTM, ICompanyContactDTM,
} from 'user-management/models/dtm';
import { ContactBookAddressItem, ContactBookContactPersonItem } from './components';

import {
  CBByIdContentBodyForm,
  CBByIdContentBodyFormFooter,
  CBByIdContentBodyFormWrap,
  CBByIdContentBodyFormWrapper,
} from './ContactBookByIdAddAddress.styled';

interface IContactBookByIdAddAddressComponentProps {
  companyListAddresses?: ICompanyAddressDTM[]
  companyListContactPersons?: ICompanyContactDTM[]
  companyListStateIndex: string
  isPrimaryValue?: boolean
  isUpdating?: boolean
  updateForm: () => void
  onDiscard: () => void
  onChangeAddressIsPrimary: (id: string) => (value: boolean) => void
}

const ContactBookByIdAddAddressComponent: FC<IContactBookByIdAddAddressComponentProps> = (props) => {
  const {
    companyListAddresses,
    companyListContactPersons,
    companyListStateIndex,
    isPrimaryValue,
    isUpdating,
    updateForm,
    onDiscard,
    onChangeAddressIsPrimary,
  } = props;
  const { t } = useTranslation();
  const largeInput = useWindowSize().isFullMediaWidth;

  return (
    <CBByIdContentBodyFormWrap>
      <CBByIdContentBodyForm>
        <CBByIdContentBodyFormWrapper>

          {companyListAddresses?.map((addressItem, addressIndex) => (
            <ContactBookAddressItem
              key={`ContactBookAddressItem-${addressItem.customId}`}
              largeInput={largeInput}
              isFirst={addressIndex === 0}
              isLast={false}
              addressIndex={addressIndex}
              companyListStateIndex={companyListStateIndex}
              address={addressItem}
              primaryComponent={(
                <TypeSwitch
                  onChange={onChangeAddressIsPrimary(addressItem?.customId || '')}
                  data-class="ContactBookByIdAddContactPersonAddressItem"
                  rightText={t('Primary')}
                  checkedChildren="On"
                  unCheckedChildren="Off"
                  value={!!isPrimaryValue}
                  disabledbg="true"
                />
              )}
            />
          ))}

          {companyListContactPersons?.map((personItem, personIndex) => (
            <ContactBookContactPersonItem
              key={`ContactBookContactPersonItem-${personItem.customId}`}
              largeInput={largeInput}
              isFirst={personIndex === 0}
              isLast={personIndex === companyListContactPersons.length - 1}
              contactIndex={personIndex}
              personItem={personItem}
              companyListStateIndex={companyListStateIndex}
            />
          ))}

        </CBByIdContentBodyFormWrapper>

      </CBByIdContentBodyForm>

      <CBByIdContentBodyFormFooter
        saveName={t('Add')}
        discardName={t('cancel')}
        discardChanges={onDiscard}
        updateForm={updateForm}
        disabledUpdate={isUpdating}
      />
    </CBByIdContentBodyFormWrap>
  );
};

const ContactBookByIdAddAddressComponentCache = memo(ContactBookByIdAddAddressComponent);

export { ContactBookByIdAddAddressComponentCache as ContactBookByIdAddAddressComponent };
