import moment from 'moment/moment';

import { InvoicesResponseDTM } from 'monetary/models/dtm/Invoices.dtm';
import { apiWorker } from 'app-wrapper/repository/utilsServices';
import { GetInvoicesContract } from 'monetary/models/contracts';
import { ShipmentBillingInvoiceDtm } from 'shipment-operations/models/dtm';
import { ContainerTypesConst, InvoiceStatusesEnum } from 'shipment-operations/constants';

const timeFormat = 'D MMM YYYY';

export class InvoicesService {
  public getData = async (category: string, page: number) => {
    let result : InvoicesResponseDTM;

    try {
      const response = await apiWorker.requestGet<GetInvoicesContract>(`billing-service/api/v1/${category}/invoices?page=${page}&size=15&sort=id,desc`);
      const parsedResponse = response.data.content.map((parsedRes) => {
        const parsedItem = ShipmentBillingInvoiceDtm.fromPlain({
          id: parsedRes.id,
          status: parsedRes.status as InvoiceStatusesEnum,
          companyName: parsedRes.billTo.name,
          companyNameFrom: parsedRes.billFrom.name,
          accountId: parsedRes.billTo.id,
          accountIdFrom: parsedRes.billFrom.id,
          billedDate: moment(parsedRes.createdAt).format(timeFormat),
          dueDate: moment(parsedRes.dueDate).format(timeFormat),
          billed: parsedRes.billed,
          adjusted: parsedRes.adjusted,
          paid: parsedRes.paid,
          balance: parsedRes.balance,
          number: parsedRes.number,
          charges: parsedRes.items.map((item) => ({
            id: item.id,
            charge: {
              id: item.charge.id,
              additional: item.charge.additional,
              status: item.charge.status,
              createdAt: item.charge.createdAt,
              createdBy: item.charge.createdBy,
              designation: item.charge.designation,
              priceBy: item.charge.priceBy,
              measureBy: item.charge.measureBy,
              chargeCode: {
                code: item.charge.chargeCode.code,
                description: item.charge.chargeCode.description,
                mode: item.charge.chargeCode.mode,
                type: item.charge.chargeCode.type,
                subType: item.charge.chargeCode.subType,
                occurrence: item.charge.chargeCode.occurrence,
                loadType: item.charge.chargeCode.loadType,
                status: '',
              },
              applianceRange: {
                minValue: item.charge.applianceRange?.minValue,
                maxValue: item.charge.applianceRange?.maxValue,
              },
              subjectTo: item.charge.subjectTo,
              applied: item.charge.applied,
              currency: item.charge.currency,
              costPerUnit: item.charge.costPerUnit,
              numberOfUnits: item.charge.numberOfUnits,
              totalCost: item.charge.totalCost,
              container: item.charge.container ? {
                id: item.charge.container.id,
                type: ContainerTypesConst[item.charge.container.type as keyof typeof ContainerTypesConst],
                number: item.charge.container.number,
              } : {
                id: 0,
                type: '',
                number: '',
              },
              contract: item.charge.contract && {
                id: item.charge.contract.id,
                name: item.charge.contract.name,
                number: item.charge.contract.number,
                scac: item.charge.contract.scac,
              },
              transportationIds: item.charge.transportationIds,
              rateId: item.charge.rateId,
              active: item.charge.active,
              invoiced: item.charge.invoiced,
              balance: item.charge.balance,
              budget: item.charge.budget,
            },
            costPerUnit: item.costPerUnit,
            numberOfUnits: item.numberOfUnits,
            amount: item.amount,
            invoiced: item.invoiced,
            // adjustment: item.invoiced,
          })),
          shipment: {
            id: parsedRes.shipment.id,
            organizationId: parsedRes.shipment.organizationId,
            shipmentName: parsedRes.shipment.shipmentName,
          },
        });

        if (!parsedItem.isValid()) {
          console.error('Data from API does not match with contract');
        }

        return parsedItem;
      });

      const { totalElements } = response.data;

      result = InvoicesResponseDTM.fromPlain({
        data: parsedResponse.filter((el) => el !== null) as ShipmentBillingInvoiceDtm[],
        totalElements,
      });
    } catch {
      throw new Error('Something wrong, please try again');
    }

    return result;
  }
}
