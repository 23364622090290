import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import {
  CommodityRow,
  CommodityRowNoHazmat,
  HazmatSection,
  HazmatSectionRow,
  HazmatSectionText,
} from './CommodityRow.styled';
import {
  HSCodeInput,
  DescriptionInput,
  PackTypeSelect,
  PackQTYInput,
  WeightInput,
  VolumeInput,
  HazmatToggle,
  AddOrRemoveCargo,
  IMOClassSelect,
  UnNumberInput,
  PackagingGroupSelect,
  ShippingNameInput,
  CargoDocumentUpload,
} from './components';

interface CommodityRowComponentProps {
  hasHazmat: boolean;
  hazmatToggled: boolean;
  cargoId: number;
  hasMSDSDocument: boolean;
}

export const CommodityRowComponent: FC<CommodityRowComponentProps> = ({
  hasHazmat,
  cargoId,
  hazmatToggled,
  hasMSDSDocument,
}) => {
  const { t } = useTranslation();

  return hasHazmat ? (
    <>
      <CommodityRow>
        <HSCodeInput cargoId={cargoId} />
        <DescriptionInput cargoId={cargoId} />
        <PackTypeSelect cargoId={cargoId} />
        <PackQTYInput cargoId={cargoId} />
        <WeightInput cargoId={cargoId} />
        <VolumeInput cargoId={cargoId} />
        <HazmatToggle cargoId={cargoId} />
        <AddOrRemoveCargo cargoId={cargoId} />
      </CommodityRow>

      {hazmatToggled ? (
        <HazmatSection
          style={{
            height: hasMSDSDocument ? '100px' : '88px',
          }}
        >
          <HazmatSectionRow>
            <HazmatSectionText>
              {t('IMO Class *')}
            </HazmatSectionText>

            <HazmatSectionText>
              {t('UN Number *')}
            </HazmatSectionText>

            <HazmatSectionText>
              {t('Packing Group *')}
            </HazmatSectionText>

            <HazmatSectionText>
              {t('Proper Shipping Name *')}
            </HazmatSectionText>

            <HazmatSectionText>
              {t('MSDS *')}
            </HazmatSectionText>
          </HazmatSectionRow>

          <HazmatSectionRow>
            <IMOClassSelect cargoId={cargoId} />
            <UnNumberInput cargoId={cargoId} />
            <PackagingGroupSelect cargoId={cargoId} />
            <ShippingNameInput cargoId={cargoId} />
            <CargoDocumentUpload cargoId={cargoId} />
          </HazmatSectionRow>
        </HazmatSection>
      ) : null}
    </>
  ) : (
    <CommodityRowNoHazmat>
      <HSCodeInput cargoId={cargoId} dropdownMatchSelectWidth={400} />
      <DescriptionInput cargoId={cargoId} />
      <PackTypeSelect cargoId={cargoId} />
      <PackQTYInput cargoId={cargoId} />
      <WeightInput cargoId={cargoId} />
      <VolumeInput cargoId={cargoId} />
      <AddOrRemoveCargo cargoId={cargoId} />
    </CommodityRowNoHazmat>
  );
};
