import React from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';

import { PDFDropoffDetailsComponent } from './PDFDropoffDetails.component';

const PDFDropoffDetailsContainer = () => {
  const details = useSelector(R.selectors.bookingConfirmationPDF.getPickupAndDropoffItems);

  return (
    <PDFDropoffDetailsComponent
      details={details}
    />
  );
};

export { PDFDropoffDetailsContainer as PDFDropoffDetails };
