import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ValidationErrorDTM } from 'app-wrapper/types';

import { InputStyled } from './WeightInput.styled';

interface WeightInputComponentProps {
  weight: string;
  error?: ValidationErrorDTM;
}

export const WeightInputComponent: FC<WeightInputComponentProps> = ({
  weight,
  error,
}) => {
  const { t } = useTranslation();

  return (
    <InputStyled
      placeholder={t('Enter Weight')}
      value={weight}
      error={!!error}
      disabled
    />
  );
};
