import styled from 'styled-components';

import { divFlex, responsive } from 'app-wrapper/view/themes/mixin';
import { Select, Option } from 'app-wrapper/view/components';

export const SelectSearchWrapper = styled(Select).attrs((props: {
  hasError: boolean,
}) => ({
  hasError: props.hasError,
  'data-class': 'SelectSearchWrapper',
}))`
  ${divFlex}
  width: 100%;

  .ant-select-selector {
    min-width: ${({ theme }) => theme?.inputConst?.defaultHeight};
    ${({ hasError, theme }) => hasError && `border-color: ${theme.themesColors.characterBrandingDanger} !important; border-style: solid;`};
  }
  ${responsive.forFullMedia`
    .ant-select-selector {
      // TODO:
      min-width: ${({ theme }: { theme: { inputConst: { hdHeight: string } } }) => theme?.inputConst?.hdHeight};
    }
  `}
`;

export const SelectSearchOption = styled(Option).attrs({
  'data-class': 'SelectSearchOption',
})`
  .ant-select-item-option-content {
    ${divFlex}
    flex-direction: column;
  }
`;

export const SelectSearchOptionTitle = styled.div.attrs({
  'data-class': 'SelectSearchOptionTitle',
})`
  ${divFlex}
  font-weight: 400;
  font-size: 12px;
  color: ${({ theme }) => theme?.themesColors?.neutralBranding10};
`;

export const SelectSearchOptionDescription = styled.div.attrs({
  'data-class': 'SelectSearchOptionDescription',
})`
  ${divFlex}
  margin-left: 8px;
  font-size: 12px;
  color: ${({ theme }) => theme?.themesColors?.neutralBranding10};
  font-size: 12px;
  color: ${({ theme }) => theme?.themesColors?.neutralBranding10};
  font-weight: 400;
  white-space: normal;
`;
