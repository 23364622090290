import styled from 'styled-components';
import AntTabs from 'antd/es/tabs';

import { TabsCss } from 'app-wrapper/view/styles/mixins';

export const Tabs = styled(AntTabs).attrs({
  'data-class': 'tabs',
})`
  ${TabsCss}
`;
