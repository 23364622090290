import { RouteNames } from 'app-wrapper/constants';

interface IGetNameAppBreadcrumbProps {
  url: string
  index?: number
  id?: string
}

export const getNameAppBreadcrumb = (props?: IGetNameAppBreadcrumbProps) => {
  const {
    url,
    index,
  } = props || {};

  switch (url) {
    case RouteNames.FINANCES_AP_INVOICES():
      if (index === 1) {
        return 'Invoices';
      }

      return '';

    default:
      return '';
  }
};
