import styled from 'styled-components';
import CalendarOutlined from '@ant-design/icons/CalendarOutlined';
import Input from 'antd/es/input';
import DatePicker from 'antd/es/date-picker';

interface MismatchesProps {
  isMismatches?: boolean;
}

export const EditableCellWrapper = styled.div`
  padding: 4px 11px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  width: 182px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 32px;
  position: relative;
`;

export const StyledCalendar = styled(CalendarOutlined)`
  position: absolute;
  right: 12px;
  color: #d9d9d9;
`;

export const StyledInput = styled(Input)<MismatchesProps>`
  width: 182px;
  &:focus {
    outline: none;
    box-shadow: none;
  }
`;

export const StyledDatePicker = styled(DatePicker)<MismatchesProps>`
  &:focus {
    outline: none;
    box-shadow: none;
  }
`;

export const StyledLabel = styled.span`
  font-size: 12px;
  font-weight: 400;
  color: #202C3C;
  margin-right: 5px;
`;
