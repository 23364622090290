import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'antd/es/skeleton';

import { Footer } from 'app-wrapper/view/drawers/components';
import { DrawerFields } from 'app-wrapper/view/guideline';

import {
  ContractNumber,
  ContractCarrier,
  ContractCategory,
} from './components';
import { Drawer } from './CreateContractDrawer.styled';

const DRAWER_WIDTH = 400;

interface BookingDrawerComponentProps {
  onClose: () => void
  isLoading: boolean
  isAbleToAccept: boolean
  onAccept: () => void
}

export const CreateContractDrawerComponent: FC<BookingDrawerComponentProps> = ({
  onClose,
  onAccept,
  isLoading,
  isAbleToAccept,
}) => {
  const { t } = useTranslation();

  return (
    <Drawer
      visible
      title={t('Create new contract')}
      onClose={onClose}
      maskClosable={false}
      width={DRAWER_WIDTH}
    >
      {isLoading ? (
        <Skeleton />
      ) : (
        <DrawerFields>
          <ContractNumber />
          <ContractCarrier />
          <ContractCategory />
        </DrawerFields>
      )}

      <Footer
        onAccept={onAccept}
        closeDrawer={onClose}
        isLoading={isLoading}
        isAbleToAccept={isAbleToAccept}
        okText={t('Create')}
      />
    </Drawer>
  );
};
