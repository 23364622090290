import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Option, Select } from 'app-wrapper/view/components';
import { OrganizationMemberDTM } from 'user-management/models/dtm';
import { SpinnerWrap } from 'app-wrapper/view/guideline';
import { ShipmentDrawerWrapper } from 'shipment-operations/view/pages/Shipment/components/ShipmentDrawer/ShipmentDrawer.styled';
import {
  Wrapper,
  Subtitle,
  Spinner,
} from 'shipment-operations/view/drawers/ServiceDocumentUpload/ServiceDocumentUpload.styled';

import { Footer } from './components';

interface ISalesRepresentativeDrawerComponentProps {
  isLoading?: boolean;
  onClose: () => void;
  saleRepresentativeEmail?: string;
  setSalesRepresentativeEmail: (email?: string) => void;
  salesDepartmentMembers: OrganizationMemberDTM[];
}

export const SalesRepresentativeDrawerComponent: FC<ISalesRepresentativeDrawerComponentProps> = ({
  isLoading,
  onClose,
  saleRepresentativeEmail,
  setSalesRepresentativeEmail,
  salesDepartmentMembers,
}) => {
  const { t } = useTranslation();

  return (
    <ShipmentDrawerWrapper
      title={t('Select Sales Representative')}
      visible
      closable
      maskClosable={false}
      onClose={onClose}
    >
      <Wrapper>
        {isLoading ? (
          <SpinnerWrap>
            <Spinner size="large" />
          </SpinnerWrap>
        ) : (
          <>
            <Subtitle>
              {t('Sales Representative')}
            </Subtitle>
            <Select
              allowClear
              value={saleRepresentativeEmail}
              onChange={setSalesRepresentativeEmail}
              placeholder={t('Please select sales representative')}
            >
              {salesDepartmentMembers.map((member) => (
                <Option key={member.email} value={member.email}>
                  {member.getFullName()}
                </Option>
              ))}
            </Select>
            <Footer />
          </>
        )}
      </Wrapper>
    </ShipmentDrawerWrapper>
  );
};
