import styled from 'styled-components';

import themesColors from 'app-wrapper/view/themes/themesColors';

export const NoDataLineItemWrapper = styled.div.attrs({
  'data-class': 'NoDataLineItemWrapper',
})`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  gap: 6px;
  
  
  border: 1px solid ${themesColors.neutralBranding4};
  border-radius: 2px;
  padding: 1px 6px;
  width: 77px;
  height: 22px;
  background-color: ${themesColors.neutralBranding3};
  
  color: ${themesColors.neutralBranding6};
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
`;
