import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'app-wrapper/createSlice';

import {
  BillOfLadingDTM,
  CargoDTM,
  ChargeDTM,
  ContainerDocumentDTM,
  ContainerWithCargoDTM,
  HBLViewChargeDTM,
  IRouteLegDTM,
  MasterBillOfLadingViewDTM,
  PaymentTermsDTM,
  ShipmentInstructionsDetailsDTM,
  ShippingPartyOverviewDTM,
  TemperatureControlDTM,
  CustomsClearanceDTM,
  ShipmentChangesDtm,
} from 'shipment-operations/models/dtm';
import {
  IncotermsType,
  PaymentsType,
  ContractOwner,
  ShippingInstructionsPages,
  ShippingInstructionsRoutes,
} from 'shipment-operations/constants';
import { IBillOfLadingCommonState } from 'shipment-operations/models/states';
import { BadInttraRequestError } from 'shipment-operations/models/errors';
import { DocumentDTM } from 'app-wrapper/models/dtm';
import { AccountDepartmentDTM, AdminPublicInfoDTM } from 'user-management/models/dtm';

export const initialState: IBillOfLadingCommonState = {
  isLoading: false,
  additionalInformationReference: '',
  mblNumber: '',
  contractNumber: '',
  carrierName: '',
  carrierSCAC: '',
  contactName: '',
  contactPhones: [],
  contactEmails: [],
  shipper: ShippingPartyOverviewDTM.createEmpty(),
  houseShipper: ShippingPartyOverviewDTM.createEmpty(),
  notifyParty: ShippingPartyOverviewDTM.createEmpty(),
  houseNotifyParty: ShippingPartyOverviewDTM.createEmpty(),
  consignee: ShippingPartyOverviewDTM.createEmpty(),
  houseConsignee: ShippingPartyOverviewDTM.createEmpty(),
  forwardingAgent: ShippingPartyOverviewDTM.createEmpty(),
  bookingAgent: ShippingPartyOverviewDTM.createEmpty(),
  oceanCarrier: ShippingPartyOverviewDTM.createEmpty(),
  ultimateCustomer: ShippingPartyOverviewDTM.createEmpty(),
  customer: ShippingPartyOverviewDTM.createEmpty(),
  deliveryAgent: ShippingPartyOverviewDTM.createEmpty(),
  temperatureControl: TemperatureControlDTM.createEmpty(),
  secondNotifyParty: ShippingPartyOverviewDTM.createEmpty(),
  cargos: [],
  containers: [],
  billOfLading: {
    copies: [],
    originals: [],
  },
  submitSI: {
    isSuccessful: false,
  },
  charges: [],
  houseConsigneeCharges: [],
  houseShipperCharges: [],
  deliveryAgentCharges: [],
  chargesMBL: [],
  paymentTerms: null,
  paymentsFormState: {
    origin: '',
    freight: '',
    destination: '',
    incoterm: '',
  },
  clausesFormState: {},
  clausesFormErrors: {},
  commentsFormErrors: {},
  commentsFormState: {},
  chargesHBL: [],
  isStateUpdatePending: false,
  mblFileFormState: null,
  mblFileFormStateError: false,
  mblFileFormPending: false,
  draftMBL: null,
  finalMBL: null,
  details: ShipmentInstructionsDetailsDTM.createEmpty(),
  isFreightedView: true,
  contractOwner: ContractOwner.ORIGIN,
  isDraftHBLApproved: false,
  isDraftMBLApproved: false,
  activeRoute: ShippingInstructionsRoutes.MBL_SI,
  isEditShippingPartyDrawerVisible: false,
  isEditShippingPartyDrawerLoading: false,
  hasAdditionalServicesWithITNActivity: false,
  isOriginUS: false,
  isUserFromDestinationPartner: false,
  accountHolderDocOpsExportDepartment: null,
  currentOrgRelatedAdminPublicInfo: null,
  siMissMatches: [],
  isFlowStepLoading: false,
};

export const billOfLadingCommonStore = createSlice({
  name: 'billOfLadingCommon',
  initialState,
  reducers: {
    setSubmitSI: (state, { payload }: PayloadAction<{ error?: BadInttraRequestError; isSuccessful: boolean; }>) => {
      state.submitSI = {
        isSuccessful: payload.isSuccessful,
      };
    },
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setContractOwner: (state, { payload }: PayloadAction<ContractOwner>) => {
      state.contractOwner = payload;
    },
    setExportCustoms: (state, action: PayloadAction<CustomsClearanceDTM | undefined>) => {
      state.exportCustoms = action.payload;
    },
    setLowestSequenceLeg: (state, action: PayloadAction<IRouteLegDTM>) => ({
      ...state,
      lowestSequenceLeg: action.payload,
    }),
    setIsDraftHBLApproved: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      isDraftHBLApproved: payload,
    }),
    setIsDraftMBLApproved: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      isDraftMBLApproved: payload,
    }),
    setActiveRoute: (state, { payload }: PayloadAction<ShippingInstructionsRoutes>) => ({
      ...state,
      activeRoute: payload,
    }),
    setLowestSequenceSeaLeg: (state, action: PayloadAction<IRouteLegDTM>) => ({
      ...state,
      lowestSequenceSeaLeg: action.payload,
    }),
    setAccountHolderDocOpsExportDepartment: (state, { payload }: PayloadAction<AccountDepartmentDTM | null>) => ({
      ...state,
      accountHolderDocOpsExportDepartment: payload,
    }),
    setIsFlowStepLoading: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      isFlowStepLoading: payload,
    }),
    setCurrentOrgRelatedAdminPublicInfo: (state, { payload }: PayloadAction<AdminPublicInfoDTM | null>) => ({
      ...state,
      currentOrgRelatedAdminPublicInfo: payload,
    }),
    setHighestSequenceSeaLeg: (state, action: PayloadAction<IRouteLegDTM>) => ({
      ...state,
      highestSequenceSeaLeg: action.payload,
    }),
    setHighestSequenceLeg: (state, action: PayloadAction<IRouteLegDTM>) => ({
      ...state,
      highestSequenceLeg: action.payload,
    }),
    setSIMissMatches: (state, { payload }: PayloadAction<ShipmentChangesDtm[]>) => ({
      ...state,
      siMissMatches: payload,
    }),
    setUltimateCustomer: (state, { payload }: PayloadAction<ShippingPartyOverviewDTM>) => ({
      ...state,
      ultimateCustomer: payload,
    }),
    setHasAdditionalServicesWithITNActivity: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      hasAdditionalServicesWithITNActivity: payload,
    }),
    setLowestSequenceTransportation: (state, action: PayloadAction<IBillOfLadingCommonState['lowestSequenceTransportation']>) => ({
      ...state,
      lowestSequenceTransportation: action.payload,
    }),
    setTemperatureControl: (state, action: PayloadAction<TemperatureControlDTM>) => ({
      ...state,
      temperatureControl: action.payload,
    }),
    setHBLCharges: (state, { payload }: PayloadAction<HBLViewChargeDTM[]>) => ({
      ...state,
      chargesHBL: [...payload],
    }),
    setCarrierSCAC: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      carrierSCAC: payload,
    }),
    setActiveSIPage: (state, { payload }: PayloadAction<ShippingInstructionsPages>) => ({
      ...state,
      activeSIPage: payload,
    }),
    setAdditionalInformationReference: (state, action: PayloadAction<string>) => ({
      ...state,
      additionalInformationReference: action.payload,
    }),
    setMblNumber: (state, action: PayloadAction<string>) => ({
      ...state,
      mblNumber: action.payload,
    }),
    setCharges: (state, { payload }: PayloadAction<ChargeDTM[]>) => ({
      ...state,
      charges: [...payload],
    }),
    setChargesMBL: (state, { payload }: PayloadAction<ChargeDTM[]>) => ({
      ...state,
      chargesMBL: [...payload],
    }),
    setDeliveryAgentCharges: (state, { payload }: PayloadAction<ChargeDTM[]>) => ({
      ...state,
      deliveryAgentCharges: payload,
    }),
    setIsUserFromDestinationPartner: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      isUserFromDestinationPartner: payload,
    }),
    setPaymentTerms: (state, { payload }: PayloadAction<PaymentTermsDTM>) => ({
      ...state,
      paymentTerms: payload,
    }),
    setContractNumber: (state, action: PayloadAction<string>) => ({
      ...state,
      contractNumber: action.payload,
    }),
    setIsEditShippingPartyDrawerVisible: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      isEditShippingPartyDrawerVisible: payload,
    }),
    setIsEditShippingPartyDrawerLoading: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      isEditShippingPartyDrawerLoading: payload,
    }),
    setShippingParties: (state, { payload }: PayloadAction<{
      shipper: ShippingPartyOverviewDTM,
      consignee: ShippingPartyOverviewDTM,
      notifyParty: ShippingPartyOverviewDTM,
      houseShipper: ShippingPartyOverviewDTM,
      houseConsignee: ShippingPartyOverviewDTM,
      houseNotifyParty: ShippingPartyOverviewDTM,
      forwardingAgent: ShippingPartyOverviewDTM,
      bookingAgent: ShippingPartyOverviewDTM,
      oceanCarrier: ShippingPartyOverviewDTM,
      ultimateCustomer: ShippingPartyOverviewDTM,
      customer: ShippingPartyOverviewDTM,
      deliveryAgent: ShippingPartyOverviewDTM,
      secondNotify: ShippingPartyOverviewDTM
    }>) => {
      state.shipper = payload.shipper;
      state.consignee = payload.consignee;
      state.notifyParty = payload.notifyParty;
      state.houseShipper = payload.houseShipper;
      state.houseConsignee = payload.houseConsignee;
      state.houseNotifyParty = payload.houseNotifyParty;
      state.forwardingAgent = payload.forwardingAgent;
      state.bookingAgent = payload.bookingAgent;
      state.oceanCarrier = payload.oceanCarrier;
      state.ultimateCustomer = payload.ultimateCustomer;
      state.customer = payload.customer;
      state.deliveryAgent = payload.deliveryAgent;
      state.secondNotifyParty = payload.secondNotify;
    },
    setCargos: (state, action : PayloadAction<CargoDTM[]>) => ({
      ...state,
      cargos: action.payload,
    }),
    setContainers: (state, action: PayloadAction<ContainerWithCargoDTM[]>) => ({
      ...state,
      containers: action.payload,
    }),
    setMBLFileUploadError: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      mblFileFormStateError: payload,
    }),
    setBillOfLading: (state, { payload }: PayloadAction<{ type: BillOfLadingDTM['type']; originals: BillOfLadingDTM['originals']; copies: BillOfLadingDTM['copies'] }>) => {
      state.billOfLading = {
        type: payload.type,
        originals: payload.originals,
        copies: payload.copies,
      };
    },
    setMBLFileFormState: (state, { payload }: PayloadAction<ContainerDocumentDTM | null>) => ({
      ...state,
      mblFileFormState: payload,
    }),
    setMBLFileFormPending: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      mblFileFormPending: payload,
    }),
    setPaymentsFormState: (state, { payload }: PayloadAction<{ key: keyof Omit<IBillOfLadingCommonState['paymentsFormState'], 'incoterm'>; value: PaymentsType }>) => {
      state.paymentsFormState[payload.key] = payload.value;
    },
    setIncotermFormState: (state, { payload }: PayloadAction<IncotermsType>) => {
      state.paymentsFormState.incoterm = payload;
    },
    setClausesFormState: (state, { payload }: PayloadAction<string[]>) => {
      state.clausesFormState = {
        clauses: payload,
      };
    },
    setClausesFormError: (state, { payload }: PayloadAction<boolean>) => {
      state.clausesFormErrors = {
        clauses: payload,
      };
    },
    setHouseConsigneeCharges: (state, { payload }: PayloadAction<ChargeDTM[]>) => ({
      ...state,
      houseConsigneeCharges: payload,
    }),
    setHouseShipperCharges: (state, { payload }: PayloadAction<ChargeDTM[]>) => ({
      ...state,
      houseShipperCharges: payload,
    }),
    setCommentsFormState: (state, { payload }: PayloadAction<string>) => {
      state.commentsFormState = {
        comment: payload,
      };
    },
    setCommentsFormError: (state, { payload }: PayloadAction<boolean>) => {
      state.commentsFormErrors = {
        comment: payload,
      };
    },
    setIsStateUpdatePending: (state, { payload }: PayloadAction<boolean>) => {
      state.isStateUpdatePending = payload;
    },
    setCarrierName: (state, { payload }: PayloadAction<string>) => {
      state.carrierName = payload;
    },
    setContactName: (state, { payload }: PayloadAction<string>) => {
      state.contactName = payload;
    },
    setContactPhones: (state, { payload }: PayloadAction<string[]>) => {
      state.contactPhones = payload;
    },
    setContactEmails: (state, { payload }) => {
      state.contactEmails = payload;
    },
    setSIDetailsClauses: (state, { payload }: PayloadAction<string[]>) => {
      state.details.clauses = payload;
    },
    setSIDetailsBLComment: (state, { payload }: PayloadAction<string>) => {
      state.details.blComment = payload;
    },
    setSIDetails: (state, { payload }: PayloadAction<ShipmentInstructionsDetailsDTM>) => ({
      ...state,
      details: payload,
    }),
    setSIDetailsMBLDraftDocument: (state, { payload }: PayloadAction<DocumentDTM | null>) => {
      state.details = ShipmentInstructionsDetailsDTM.fromPlain({
        ...state.details,
        draftMblDocument: payload,
      });
    },
    setSIDetailsMBLFinalDocument: (state, { payload }: PayloadAction<DocumentDTM | null>) => {
      state.details = ShipmentInstructionsDetailsDTM.fromPlain({
        ...state.details,
        finalMblDocument: payload,
      });
    },
    setDraftMBL: (state, { payload }: PayloadAction<MasterBillOfLadingViewDTM | null>) => {
      state.draftMBL = payload;
    },
    setFinalMBL: (state, { payload }: PayloadAction<MasterBillOfLadingViewDTM | null>) => {
      state.finalMBL = payload;
    },
    setIsOriginUS: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      isOriginUS: payload,
    }),
    setIsFreightedView: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      isFreightedView: payload,
    }),
  },
});

export const billOfLadingCommonActions = billOfLadingCommonStore.actions;
export const billOfLadingCommonReducer = billOfLadingCommonStore.reducer;
