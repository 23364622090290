import React from 'react';

import { IPropsIcon } from 'app-wrapper/interfaces/icon';

import { Icon } from './RoadFlySvg.styled';

export const RoadFlySvg = (props: IPropsIcon) => {
  const { width, height, fill } = props;
  return (
    <Icon
      className="RoadFlySvg"
      width={width || '15'}
      height={height || '15'}
      viewBox="0 0 15 15"
      fill={fill || 'currentColor'}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M5.77922 2.0625L8.54094 6.875H11.7128C12.4238 6.875 13.4081 7.42188 13.6542 7.75C13.4081 8.10547 12.4238 8.625 11.7128 8.625H8.54094L5.77922 13.4375H4.7675L6.13469 8.625H2.96281L1.97844 9.9375H0.830002L1.48625 7.75L0.830002 5.5625H1.97844L2.96281 6.875H6.13469L4.7675 2.0625H5.77922ZM6.29875 0.75H3.61906C3.31828 0.75 3.09953 1.05078 3.18156 1.32422L4.41203 5.5625H3.61906L2.74406 4.44141C2.66203 4.33203 2.55266 4.25 2.41594 4.25H-0.318436C-0.619217 4.25 -0.837967 4.52344 -0.755936 4.79688L0.119064 7.75L-0.755936 10.7305C-0.837967 11.0039 -0.619217 11.25 -0.318436 11.25H2.41594C2.55266 11.25 2.66203 11.1953 2.74406 11.0859L3.61906 9.9375H4.41203L3.18156 14.2031C3.09953 14.4766 3.31828 14.75 3.61906 14.75H6.29875C6.43547 14.75 6.59953 14.668 6.68156 14.5312L9.30656 9.9375H11.7128C12.9159 9.9375 14.9941 8.98047 14.9941 7.75C14.9941 6.54688 12.9159 5.5625 11.7128 5.5625H9.30656L6.68156 0.996094C6.59953 0.859375 6.43547 0.75 6.29875 0.75Z" fill="currentColor" />
    </Icon>
  );
};
