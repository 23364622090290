import React, { FC } from 'react';

import { useAppSelector } from 'app-wrapper/hooks';
import { R } from 'shipment-operations/repository';
import { UC } from 'shipment-operations/controllers';

import { HeaderComponent } from './Header.component';

interface IHeaderProps {
  isLoading: boolean
}

const Header: FC<IHeaderProps> = ({ isLoading }) => {
  const shipment = useAppSelector(R.selectors.shipment.getShipment);
  const companies = useAppSelector(R.selectors.createInvoiceCharges.getCompaniesForSelect);
  const selectedCompany = useAppSelector(R.selectors.createInvoiceCharges.getSelectedCompany);
  const dueDate = useAppSelector(R.selectors.createInvoiceCharges.getCreateInvoiceDueDate);
  const selectedCompanyError = useAppSelector(R.selectors.createInvoiceCharges.getSelectedCompanyError);
  const createInvoiceModalOwner = useAppSelector(R.selectors.createInvoiceCharges.getIsOpenCreateInvoiceModal);

  return (
    <HeaderComponent
      shipmentName={shipment?.shipmentName}
      companies={companies}
      selectedCompany={selectedCompany}
      setActiveCompany={UC.createInvoiceCharges.onSetSelectedCompany}
      setDueDate={UC.createInvoiceCharges.onSetDueDate}
      setBilledDate={UC.createInvoiceCharges.onSetBilledDate}
      clearSelectedCompanyError={UC.createInvoiceCharges.onClearSelectedCompanyError}
      dueDate={dueDate}
      isLoading={isLoading}
      selectedCompanyError={selectedCompanyError}
      modalOwner={createInvoiceModalOwner}
      onChangeReference={UC.createInvoiceCharges.setReference}
    />
  );
};

export { Header };
