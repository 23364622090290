import { apiWorker } from 'app-wrapper/repository/utilsServices';
import { EAccountDepartmentType, EOrganizationMemberRole, ETeamStatus } from 'user-management/constants';
import {
  IGetOrganizationTeamContract,
  TGetOrganizationTeamsContract,
  IPostOrganizationTeamContract,
  IPutOrganizationTeamContract,
} from 'user-management/models/contracts';
import {
  AccountDepartmentDTM,
  OrganizationMemberDTM,
  TeamDTM,
  TeamMemberDTM,
} from 'user-management/models/dtm';

export class OrganizationTeamsService {
  public url = '/organization-service/api/v1/organizations';

  public getTeam = async (organizationId: number | string, teamId: number | string): Promise<TeamDTM> => {
    const rawResponse = await apiWorker.requestGet<IGetOrganizationTeamContract>(`${this.url}/${organizationId}/teams/${teamId}`);
    const response = rawResponse.data;

    return TeamDTM.fromPlain({
      id: response.id,
      status: ETeamStatus.ACTIVE,
      name: response.name,
      membersCount: 0,
      customersCount: 0,
      customers: [],
      members: [],
    });
  };

  public getTeams = async (organizationId: number | string): Promise<TeamDTM[]> => {
    let teams: TeamDTM[] = [];

    const rawResponse = await apiWorker.requestGet<TGetOrganizationTeamsContract>(`${this.url}/${organizationId}/teams`);
    const response = rawResponse.data;

    teams = response.map((rawTeam) => TeamDTM.fromPlain({
      id: rawTeam.id,
      name: rawTeam.name,
      status: rawTeam.status,
      membersCount: rawTeam.membersCount,
      customersCount: rawTeam.customersCount,
      customers: [],
      members: [],
    }));

    return teams;
  }

  public getMembersByTeam = async (organizationId: number | string, teamId: number): Promise<TeamMemberDTM[]> => {
    const members: TeamMemberDTM[] = [];

    const rawResponse = await apiWorker.requestGetBySchema(`${this.url}/${organizationId}/teams/${teamId}/members` as '/api/v1/organizations/{organizationId}/teams/{teamId}/members');

    rawResponse.data.forEach((item) => {
      const organizationMember = TeamMemberDTM.fromPlain({
        ...item,
        team: item.team ? TeamDTM.fromPlain({
          id: item.team.id,
          name: item.team.name,
          status: item.team.status,
          membersCount: item.team.membersCount || 0,
          customersCount: item.team.customersCount || 0,
          customers: [],
          members: [],
        }) : undefined,
        member: item.member ? OrganizationMemberDTM.fromPlain({
          sub: item.member.user.sub,
          enabled: item.member.user.enabled,
          email: item.member.user.email,
          phone: item.member.user.phone,
          firstName: item.member.user.firstName,
          lastName: item.member.user.lastName,
          onboarded: item.member.onboarded,
          jobTitle: item.member.jobTitle || '',
          role: item.member.role as EOrganizationMemberRole,
          teams: [],
          departments: item.member.departments ? item.member.departments.map((department) => AccountDepartmentDTM.fromPlain({
            id: department.id,
            customId: '',
            type: department.type as EAccountDepartmentType,
            email: department.email,
            phone: department.phone,
            createdBy: '',
            createdAt: department.createdAt,
            members: [],
          })) : [],
        }) : undefined,
      });

      if (!organizationMember.isValid()) {
        console.error(organizationMember.validate());
      }

      members.push(organizationMember);
    });

    return members;
  };

  public createTeam = async (organizationId: number | string, body: IPostOrganizationTeamContract): Promise<TeamDTM> => {
    const rawResponse = await apiWorker.requestPost<IGetOrganizationTeamContract>(`${this.url}/${organizationId}/teams`, body);
    const response = rawResponse.data;

    return TeamDTM.fromPlain({
      id: response.id,
      status: response.status,
      name: response.name,
      membersCount: 0,
      customersCount: 0,
      customers: [],
      members: [],
    });
  }

  public editTeam = async (organizationId: number | string, body: IPutOrganizationTeamContract): Promise<TeamDTM> => {
    const rawResponse = await apiWorker.requestPut<IGetOrganizationTeamContract>(`${this.url}/${organizationId}/teams/${body.id}`, body);
    const response = rawResponse.data;

    return TeamDTM.fromPlain({
      id: response.id,
      status: response.status,
      name: response.name,
      membersCount: response.membersCount,
      customersCount: response.customersCount,
      customers: [],
      members: [],
    });
  }

  public removeCustomerFromTeam = async (organizationId: number, teamId: number, customerId: number) => {
    await apiWorker.requestDelete(`${this.url}/${organizationId}/teams/${teamId}/customers/${customerId}`);
  }

  public removeMemberFromTeam = async (organizationId: number, teamId: number, memberEmail: string) => {
    await apiWorker.requestDelete(`${this.url}/${organizationId}/teams/${teamId}/members/${memberEmail}`);
  }
}
