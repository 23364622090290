import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { useAuthToken } from 'app-wrapper/hooks/useAuthToken';

import { R } from 'shipment-operations/repository';
import { UC } from 'shipment-operations/controllers';

import { CargoDocumentUploadComponent } from './CargoDocumentUpload.component';

interface CargoDocumentUploadProps {
  cargoId: number;
}

export const CargoDocumentUpload: FC<CargoDocumentUploadProps> = ({ cargoId }) => {
  const {
    msdsDocument,
    errors,
  } = useSelector(R.selectors.manualBookingWizard.getCargoById(cargoId));
  const { shipmentId = '0' } = useParams();
  const authToken = useAuthToken();

  return (
    <CargoDocumentUploadComponent
      error={!!errors.msdsDocument}
      authToken={authToken}
      msdsDocument={msdsDocument}
      cargoId={cargoId}
      downloadCargoMsdsDocument={UC.bookingWizard.downloadMsdsDocument}
      shipmentId={shipmentId}
    />
  );
};
