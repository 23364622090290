import React, { FC } from 'react';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';

import { useAuthToken } from 'app-wrapper/hooks';
import { UC } from 'shipment-operations/controllers';
import { R } from 'shipment-operations/repository';
import { DocumentType } from 'shipment-operations/constants';

import { SeaworthyDocumentUploadComponent } from './SeaworthyDocumentUpload.component';

const SeaworthyDocumentUploadContainer: FC = () => {
  const authToken = useAuthToken();
  const { shipmentId = '' } = useParams();
  const error = useSelector(R.selectors.shipmentContainers.getSeaworthyCertificateError);
  const seaworthyDocument = useSelector(R.selectors.shipmentContainers.getSeaworthyCertificate);
  const isPending = useSelector(R.selectors.shipmentContainers.getUpdateLoadingState);
  const permissions = useSelector(R.selectors.shipment.getContainersPermissions);

  return (
    <SeaworthyDocumentUploadComponent
      shipmentId={shipmentId}
      authToken={authToken}
      error={error}
      documentType={DocumentType.CSW}
      containerDocument={seaworthyDocument}
      downloadDocument={UC.shipmentContainers.downloadDocument}
      setContainerDocument={UC.shipmentContainers.setContainerSeaworthyCertificate}
      containersAvailability={permissions.containersAvailability}
      isPending={isPending}
    />
  );
};

export { SeaworthyDocumentUploadContainer as SeaworthyDocumentUpload };
