import React, { FC, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import isEmpty from 'lodash/fp/isEmpty';
import { useTranslation } from 'react-i18next';
import Divider from 'antd/es/divider';
import Alert from 'antd/es/alert';

import { EmptyStateForPages, TableSkeleton } from 'app-wrapper/view/components';

import { ChargeDTM, ShipmentChargesPermissionsDtm } from 'shipment-operations/models/dtm';
import { CustomTable } from './CustomTable';
import { MainContainer, Title, Header } from './ShipmentTransportationAccessorials.styled';

interface IShipmentTransportationAccessorialsProps {
  isLoading: boolean
  fetchData: (shipmentId?: string) => void
  accessorialData: ChargeDTM[]
  penaltiesData: ChargeDTM[]
  successCreation: boolean
  closeDrawer: () => void
  openEditCharge: (id: number, host: string) => void
  setDeletedCharge: (id: number | null) => void
  onDelete: (id?: string) => void
  isLoadingDelete: boolean
  permissions: ShipmentChargesPermissionsDtm
  isError: boolean
  successDelete: boolean
}

const ShipmentTransportationAccessorials: FC<IShipmentTransportationAccessorialsProps> = ({
  isLoading, fetchData, accessorialData, penaltiesData, successCreation, closeDrawer, openEditCharge,
  setDeletedCharge, onDelete, isLoadingDelete, permissions, isError, successDelete,
}) => {
  const { t } = useTranslation();
  const { shipmentId } = useParams<'shipmentId'>();

  useEffect(() => {
    fetchData(shipmentId);
  }, [fetchData, successCreation]);

  useEffect(() => {
    if (successCreation) {
      closeDrawer();
    }
  }, [successCreation]);

  useEffect(() => {
    if (successDelete) {
      fetchData(shipmentId);
    }
  }, [successDelete]);

  if (isLoading) {
    return <TableSkeleton />;
  }

  if (isEmpty(accessorialData) && isEmpty(penaltiesData)) {
    return (
      <EmptyStateForPages
        headerText={t('No charges are known at this time.')}
        contentText={t('All accessorials, subject-to and penalties surcharges will be displayed here.')}
      />
    );
  }

  if (isError) {
    return (
      <MainContainer>
        <Alert
          message={t('basicErrors.REQUEST_COMMON.title')}
          description={t('basicErrors.REQUEST_COMMON.subTitle')}
          type="error"
        />
      </MainContainer>
    );
  }

  return (
    <MainContainer>
      {!isEmpty(accessorialData) ? (
        <>
          <Header>
            <Title>{t('accessorial')}</Title>
          </Header>
          <Divider style={{ margin: '20px 0 0 0' }} />
          <CustomTable
            data={accessorialData}
            openEdit={openEditCharge}
            setDeletedCharge={setDeletedCharge}
            onDelete={onDelete}
            isLoadingDelete={isLoadingDelete}
            permissions={permissions}
          />
        </>
      ) : null}
      {!isEmpty(penaltiesData) && (
      <>
        <Header>
          <Title>{t('penalties')}</Title>
        </Header>
        <Divider style={{ margin: '20px 0 0 0' }} />
        <CustomTable
          data={penaltiesData}
          openEdit={openEditCharge}
          setDeletedCharge={setDeletedCharge}
          onDelete={onDelete}
          isLoadingDelete={isLoadingDelete}
          permissions={permissions}
        />
      </>
      )}
    </MainContainer>
  );
};

export { ShipmentTransportationAccessorials };
