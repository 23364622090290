import React, { FC, ButtonHTMLAttributes } from 'react';
import omit from 'lodash/fp/omit';

import { SiderTriggerButton } from './SiderTrigger.styled';

interface ISiderTriggerProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  isCollapsed: boolean;
}

export const SiderTrigger: FC<ISiderTriggerProps> = ((
  props,
) => {
  const { className, isCollapsed } = props;
  const componentClassName = `${className} SiderTrigger`;

  return (
    <SiderTriggerButton
      type="button"
      className={componentClassName}
      {...omit('isCollapsed', props)}
    >
      <i
        className={`${isCollapsed ? 'isCollapsed' : ''} ant-menu-submenu-arrow`}
      />
    </SiderTriggerButton>
  );
});
