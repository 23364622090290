import React, { FC, ReactNode } from 'react';

import { ContainerBasicTypes } from 'shipment-operations/constants';
import { SubPageHeader } from 'app-wrapper/view/guideline/SubPageHeader/SubPageHeader.styled';
import { Wrapper } from 'app-wrapper/view/guideline/Wrapper';

import {
  ShipmentContainersScaffoldLayout,
  ShipmentContainersScaffoldContent,
  ShipmentContainersScaffoldBody,
} from './ShipmentContainersScaffold.styled';

interface IShipmentContainersScaffoldComponentProps {
  containerBasicType?: ContainerBasicTypes
  title: ReactNode
  header: ReactNode
  containersList: ReactNode
  containerData: ReactNode
  warningBlock?: ReactNode
}

export const ShipmentContainersScaffoldComponent: FC<IShipmentContainersScaffoldComponentProps> = ({
  containerBasicType,
  title,
  header,
  containersList,
  containerData,
  warningBlock,
}) => {
  const isHeaderBig = containerBasicType === ContainerBasicTypes.REEFER;

  return (
    <Wrapper noPaddingBottom>
      {warningBlock}
      <ShipmentContainersScaffoldLayout>
        <SubPageHeader>
          {title}
        </SubPageHeader>
        <ShipmentContainersScaffoldContent>
          {header}
          <ShipmentContainersScaffoldBody isHeaderBig={isHeaderBig}>
            {containersList}
            {containerData}
          </ShipmentContainersScaffoldBody>
        </ShipmentContainersScaffoldContent>
      </ShipmentContainersScaffoldLayout>
    </Wrapper>
  );
};
