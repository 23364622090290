import React from 'react';

import { IPropsIcon } from 'app-wrapper/interfaces/icon';

export const Vector = (props: IPropsIcon) => {
  const { width, height, fill } = props;

  return (
    <svg
      width={width || '14'}
      height={height || 13}
      viewBox="0 0 14 13"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.2075 6.41064L11.2138 4.8372C11.1971 4.82408 11.177 4.81592 11.1559 4.81366C11.1348 4.81141 11.1135 4.81514 11.0944 4.82443C11.0754 4.83373 11.0593 4.84821 11.0481 4.86622C11.0368 4.88423 11.0309 4.90504 11.031 4.92626V5.9372H7.5622V2.46845H8.5747C8.66845 2.46845 8.72158 2.35908 8.66376 2.28564L7.08876 0.291888C7.07834 0.278396 7.06496 0.267473 7.04966 0.259958C7.03435 0.252443 7.01753 0.248535 7.00048 0.248535C6.98343 0.248535 6.96661 0.252443 6.95131 0.259958C6.936 0.267473 6.92262 0.278396 6.9122 0.291888L5.3372 2.28564C5.32408 2.30232 5.31592 2.32236 5.31366 2.34346C5.31141 2.36457 5.31514 2.38588 5.32443 2.40496C5.33373 2.42404 5.34821 2.44011 5.36622 2.45134C5.38423 2.46257 5.40504 2.4685 5.42626 2.46845H6.4372V5.9372H2.96845V4.9247C2.96845 4.83095 2.85908 4.77783 2.78564 4.83564L0.791888 6.41064C0.778396 6.42106 0.767473 6.43444 0.759958 6.44974C0.752443 6.46505 0.748535 6.48187 0.748535 6.49892C0.748535 6.51597 0.752443 6.53279 0.759958 6.5481C0.767473 6.5634 0.778396 6.57678 0.791888 6.5872L2.78408 8.1622C2.85751 8.22001 2.96689 8.16845 2.96689 8.07314V7.0622H6.43564V10.531H5.42314C5.32939 10.531 5.27626 10.6403 5.33407 10.7138L6.90908 12.706C6.95439 12.7638 7.04189 12.7638 7.08564 12.706L8.66064 10.7138C8.71845 10.6403 8.66689 10.531 8.57157 10.531H7.5622V7.0622H11.031V8.0747C11.031 8.16845 11.1403 8.22158 11.2138 8.16376L13.2059 6.58876C13.2194 6.5782 13.2303 6.56474 13.2378 6.54938C13.2454 6.53403 13.2494 6.51718 13.2495 6.50008C13.2497 6.48297 13.246 6.46605 13.2387 6.45057C13.2314 6.43509 13.2208 6.42144 13.2075 6.41064Z"
        fill={fill || '#D1D7E2'}
      />
    </svg>
  );
};
