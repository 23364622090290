export interface ICargoWiseDocumentTypes {
  code: string
  description: string
}

export const CargoWiseDocumentTypes: ICargoWiseDocumentTypes[] = [
  {
    code: 'JRJ',
    description: 'Job Revenue Journal',
  },
  {
    code: 'GLJ',
    description: 'GL Journal',
  },
  {
    code: 'CLL',
    description: 'Claim Log',
  },
  {
    code: 'DDR',
    description: 'Direct Debit',
  },
  {
    code: 'COM',
    description: 'Compliance Tax Report',
  },
  {
    code: 'PIN',
    description: 'Payables Invoice',
  },
  {
    code: 'PAY',
    description: 'Payments',
  },
  {
    code: 'PPO',
    description: 'Payables Purchase Order',
  },
  {
    code: 'CRP',
    description: 'Compliance Report',
  },
  {
    code: 'BRC',
    description: 'Bank Reconciliation',
  },
  {
    code: 'BOA',
    description: 'BOM Assemble Instruction',
  },
  {
    code: 'MSD',
    description: 'Miscellaneous Sales Manager Document',
  },
  {
    code: 'EXD',
    description: 'Exporter Documents',
  },
  {
    code: 'QRA',
    description: 'Quarantine Application/Coversheet',
  },
  {
    code: 'PUB',
    description: 'Internally Created Public Document',
  },
  {
    code: 'MCD',
    description: 'Miscellaneous Customs Document',
  },
  {
    code: 'BOD',
    description: 'BOM Disassemble Instruction',
  },
  {
    code: 'INV',
    description: 'Invoice',
  },
  {
    code: 'PRV',
    description: 'Internally Created Private Document',
  },
  {
    code: 'COT',
    description: 'Cotton Certificate',
  },
  {
    code: 'ACV',
    description: 'Account Voucher',
  },
  {
    code: 'NAF',
    description: 'NAFTA Certificate',
  },
  {
    code: 'MSC',
    description: 'Miscellaneous Document',
  },
  {
    code: 'DEC',
    description: 'Declaration of Compliance',
  },
  {
    code: 'IEP',
    description: 'Import/Export Permit',
  },
  {
    code: 'CAT',
    description: 'Catalog',
  },
  {
    code: 'TDM',
    description: 'Trademark',
  },
  {
    code: 'PER',
    description: 'Permit',
  },
  {
    code: 'AES',
    description: 'AES Export Filling Document',
  },
  {
    code: 'KCA',
    description: 'Known-party Certificate of Approval',
  },
  {
    code: 'POF',
    description: 'Power of Attorney Forwarding',
  },
  {
    code: 'EXV',
    description: 'VAT/GST Exporter Exemption (AR and AP Invoicing)',
  },
  {
    code: 'QUO',
    description: 'Quotation',
  },
  {
    code: 'CDA',
    description: 'Container Detention Advice',
  },
  {
    code: 'SOA',
    description: 'Statement of Account',
  },
  {
    code: 'EXM',
    description: 'Examination Result',
  },
  {
    code: 'POA',
    description: 'Power of Attorney',
  },
  {
    code: 'UMR',
    description: 'Unique Mandate Reference',
  },
  {
    code: 'RM2',
    description: '2nd Request For Immediate Payment',
  },
  {
    code: 'RM1',
    description: 'Request for Immediate Payment',
  },
  {
    code: 'COL',
    description: 'Collection Letter',
  },
  {
    code: 'DEM',
    description: 'Letter of Demand',
  },
  {
    code: 'WTE',
    description: 'Withholding Tax Exemption',
  },
  {
    code: 'CRR',
    description: 'Credit Report',
  },
  {
    code: 'RSB',
    description: 'PTRS Reportable Small Business',
  },
  {
    code: 'SEC',
    description: 'Staff / Group Security Documents',
  },
  {
    code: 'ERT',
    description: 'Erasure Request',
  },
  {
    code: 'RES',
    description: 'Resume/CV',
  },
  {
    code: 'SDN',
    description: 'Shippers Departure Notice',
  },
  {
    code: 'DIS',
    description: 'Disbursement Note',
  },
  {
    code: 'AIN',
    description: 'Agents Instruction',
  },
  {
    code: 'POA',
    description: 'Power of Attorney',
  },
  {
    code: 'NOT',
    description: 'Shipment Notes',
  },
  {
    code: 'MUL',
    description: 'Multiple Copies',
  },
  {
    code: 'POC',
    description: 'Power of Attorney Customs',
  },
  {
    code: 'WNP',
    description: 'Warehouse Non Picked Items',
  },
  {
    code: 'TSC',
    description: 'Time Slot Confirmation',
  },
  {
    code: 'PKD',
    description: 'Packing Declaration',
  },
  {
    code: 'WPA',
    description: 'Warehouse Packing Slip',
  },
  {
    code: 'ARC',
    description: 'Arrival Notice and Charge Sheet',
  },
  {
    code: 'DLB',
    description: 'Delivery Labels',
  },
  {
    code: 'DOR',
    description: 'Delivery Order',
  },
  {
    code: 'CTR',
    description: 'Container Release',
  },
  {
    code: 'LET',
    description: 'Letter',
  },
  {
    code: 'CUP',
    description: 'Customs Preliminary',
  },
  {
    code: 'MAD',
    description: 'Miscellaneous Shipping Manager Document',
  },
  {
    code: 'MSH',
    description: 'Master House',
  },
  {
    code: 'WSS',
    description: 'Warehouse Stocktake Sheet',
  },
  {
    code: 'COR',
    description: 'Certificate of Receipt',
  },
  {
    code: 'ICR',
    description: 'Import Cartage Advice with Receipt',
  },
  {
    code: 'SAD',
    description: 'Shipping Advice',
  },
  {
    code: 'ARN',
    description: 'Arrival Notice',
  },
  {
    code: 'ERA',
    description: 'Export Receival Advice',
  },
  {
    code: 'WPU',
    description: 'Warehouse Putaway Sheet',
  },
  {
    code: 'VET',
    description: 'Veterinary Certificate',
  },
  {
    code: 'PMR',
    description: 'Package Manifest by Product',
  },
  {
    code: 'ARE',
    description: 'Authorization Release',
  },
  {
    code: 'ECM',
    description: 'Export Coload Master Manifest',
  },
  {
    code: 'DOO',
    description: 'Document of Origin',
  },
  {
    code: 'WIN',
    description: 'Warehouse Periodic Invoice',
  },
  {
    code: 'WPO',
    description: 'Warehouse Pick Order Summary',
  },
  {
    code: 'WMR',
    description: 'Weight/Measurement Report',
  },
  {
    code: 'AGI',
    description: 'Agents Invoice',
  },
  {
    code: 'ECA',
    description: 'Export Cartage Advice',
  },
  {
    code: 'PPL',
    description: 'Packing Package Label',
  },
  {
    code: 'PMP',
    description: 'Package Manifest by Package',
  },
  {
    code: 'LCO',
    description: 'Landed Costing',
  },
  {
    code: 'WPL',
    description: 'Warehouse Package Labels',
  },
  {
    code: 'HCC',
    description: 'Health Clearance Certificate',
  },
  {
    code: 'TSR',
    description: 'Time Slot Request',
  },
  {
    code: 'WWO',
    description: 'Warehouse Work Order',
  },
  {
    code: 'DRC',
    description: 'Dock Receipt',
  },
  {
    code: 'HBL',
    description: 'House Waybill/Bill of Lading',
  },
  {
    code: 'MBL',
    description: 'Airway Bill/Ocean Bill of Lading',
  },
  {
    code: 'BCT',
    description: 'Beneficiary Certificate',
  },
  {
    code: 'PAL',
    description: 'Pre Alert',
  },
  {
    code: 'REQ',
    description: 'Request Document',
  },
  {
    code: 'PRS',
    description: 'Profit Share Calculation Worksheet',
  },
  {
    code: 'CER',
    description: 'Customs Error',
  },
  {
    code: 'LDL',
    description: 'Load List',
  },
  {
    code: 'SHO',
    description: 'Shipping Order',
  },
  {
    code: 'BKC',
    description: 'Booking Confirmation',
  },
  {
    code: 'CLS',
    description: 'Container Liability Statement',
  },
  {
    code: 'RQS',
    description: 'Request for Service',
  },
  {
    code: 'MCF',
    description: 'Miscellaneous CFS Document',
  },
  {
    code: 'CST',
    description: 'Customs Status Advice',
  },
  {
    code: 'QPK',
    description: 'Quarantine Packing Declaration',
  },
  {
    code: 'WAC',
    description: 'Warehouse Adjustment Confirmation',
  },
  {
    code: 'CAR',
    description: 'Cartage Advice With Receipt',
  },
  {
    code: 'FWI',
    description: 'Forwarding Instruction',
  },
  {
    code: 'DAN',
    description: 'Documents Available Notice',
  },
  {
    code: 'LBL',
    description: 'Label',
  },
  {
    code: 'FUM',
    description: 'Fumigation Certificate',
  },
  {
    code: 'DGF',
    description: 'Dangerous Goods Form',
  },
  {
    code: 'RRA',
    description: 'Release/Removal Authority',
  },
  {
    code: 'BCA',
    description: 'Booking Cartage Advice',
  },
  {
    code: 'EPR',
    description: 'Entry Print',
  },
  {
    code: 'QRC',
    description: 'Quarantine Certificate',
  },
  {
    code: 'MFD',
    description: 'Miscellaneous Freight Document',
  },
  {
    code: 'WID',
    description: 'Warehouse Invoice Detail',
  },
  {
    code: 'EXA',
    description: 'Export Authority',
  },
  {
    code: 'ECD',
    description: 'Exchange Control Declaration',
  },
  {
    code: 'OBL',
    description: 'Original Bill Of Lading',
  },
  {
    code: 'LID',
    description: 'Letter of Indemnity',
  },
  {
    code: 'CUS',
    description: 'Customs Certificate',
  },
  {
    code: 'MAN',
    description: 'Manifest',
  },
  {
    code: 'ILR',
    description: 'Invoice Lines Report',
  },
  {
    code: 'QRP',
    description: 'Quarantine Remote Print',
  },
  {
    code: 'WSH',
    description: 'Worksheet',
  },
  {
    code: 'PSB',
    description: 'Scan Packing Barcodes',
  },
  {
    code: 'WIC',
    description: 'Warehouse Inwards Confirmation',
  },
  {
    code: 'WBL',
    description: 'Warehouse Bill of Lading',
  },
  {
    code: 'BDR',
    description: 'Bank Draft',
  },
  {
    code: 'GTP',
    description: 'Gate Pass',
  },
  {
    code: 'MDC',
    description: 'Manufacturer\'s Declaration',
  },
  {
    code: 'ECR',
    description: 'Export Cartage Advice with Receipt',
  },
  {
    code: 'WDL',
    description: 'Warehouse Delivery Labels',
  },
  {
    code: 'WTR',
    description: 'Warehouse Transfer Confirmation',
  },
  {
    code: 'MOD',
    description: 'Miscellaneous Order Management Document',
  },
  {
    code: 'POF',
    description: 'Power of Attorney Forwarding',
  },
  {
    code: 'OUT',
    description: 'Outturn Report',
  },
  {
    code: 'EFT',
    description: 'EFT Request',
  },
  {
    code: 'CAD',
    description: 'Cartage Advice',
  },
  {
    code: 'WPB',
    description: 'Warehouse Package Labels Basic',
  },
  {
    code: 'ICA',
    description: 'Import Cartage Advice',
  },
  {
    code: 'SLI',
    description: 'Shippers Letter of Instruction',
  },
  {
    code: 'PKL',
    description: 'Packing List',
  },
  {
    code: 'CLI',
    description: 'Container List',
  },
  {
    code: 'WCA',
    description: 'Warehouse Cartage Advice',
  },
  {
    code: 'ATD',
    description: 'Authority to Deal',
  },
  {
    code: 'IRT',
    description: 'Interim Receipt',
  },
  {
    code: 'MVC',
    description: 'Motor Vehicle Certificate',
  },
  {
    code: 'CAU',
    description: 'Customs Authority',
  },
  {
    code: 'WSI',
    description: 'Warehouse Pick Shortfall Items',
  },
  {
    code: 'CHG',
    description: 'Charge Sheet',
  },
  {
    code: 'WPS',
    description: 'Warehouse Picking Slip',
  },
  {
    code: 'DAL',
    description: 'Delay Alert',
  },
  {
    code: 'FDC',
    description: 'Food Control Certificate',
  },
  {
    code: 'INS',
    description: 'Insurance Certificate',
  },
  {
    code: 'DNO',
    description: 'Departure Notice',
  },
  {
    code: 'DCF',
    description: 'Documentary Collection Form',
  },
  {
    code: 'SAN',
    description: 'Sanitary Certificate',
  },
  {
    code: 'ICM',
    description: 'Import Coload Master Manifest',
  },
  {
    code: 'CDR',
    description: 'Container Detention Reminder',
  },
  {
    code: 'CIV',
    description: 'Commercial Invoice',
  },
  {
    code: 'BOE',
    description: 'Bill Of Entry',
  },
  {
    code: 'COO',
    description: 'Certificate of Origin',
  },
  {
    code: 'WSV',
    description: 'Warehouse Stocktake Variance',
  },
  {
    code: 'CSS',
    description: 'Container Summary Sheet',
  },
  {
    code: 'HXD',
    description: 'He Xiao Dan',
  },
  {
    code: 'POD',
    description: 'Proof Of Delivery',
  },
  {
    code: 'BKG',
    description: 'Booking Request',
  },
  {
    code: 'CSD',
    description: 'Consignment Security Declaration',
  },
  {
    code: 'KON',
    description: 'Kontainers Doc(s)',
  },
  {
    code: 'QPP',
    description: 'Quarantine Print Preview',
  },
  {
    code: 'HAR',
    description: 'HVLV Archived Data',
  },
  {
    code: 'DBL',
    description: 'Draft Bill of Lading',
  },
  {
    code: 'DMBL',
    description: 'Draft Bill of Lading (Draft)',
  },
  {
    code: 'FMBL',
    description: 'Draft Bill of Lading (Final)',
  },
];
