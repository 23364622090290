import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import {
  InputWithError, Option, SelectWithError, Tooltip,
} from 'app-wrapper/view/components';

import { OrganizationDTM, OrganizationFieldsErrorsDTM, OrganizationFieldsErrorsFieldDTM } from 'user-management/models/dtm';

import {
  EditProfileOrganizationContactInformation, EditProfileOrganizationHeader, EditProfileOrganizationSpaceColumn, EditProfileOrganizationSpaceInput, EditProfileOrganizationSpaceRow, EditProfileOrganizationTitle,
} from './EditProfileOrganizationContactInformation.styled';

interface IEditProfileOrganizationContactInformationProps {
  organization?: OrganizationDTM
  organizationFieldsErrors?: OrganizationFieldsErrorsDTM
  onChangeUpdatePrimaryName: (id: string) => void
  onBlurUpdatePrimaryName: () => void
  onChangeUpdatePrimaryEmail: (email: string) => void
  onBlurUpdatePrimaryEmail: () => void
  onFocusUpdatePrimaryEmail: () => void
  onChangeUpdatePrimaryPhone: (email: string) => void
  onBlurUpdatePrimaryPhone: () => void
  onFocusUpdatePrimaryPhone: () => void
  onChangeUpdatePrimaryPhone2: (email: string) => void
  onBlurUpdatePrimaryPhone2: () => void
  onFocusUpdatePrimaryPhone2: () => void
}

export const EditProfileOrganizationContactInformationComponent: FC<IEditProfileOrganizationContactInformationProps> = (props) => {
  const {
    organization,
    organizationFieldsErrors,
    onChangeUpdatePrimaryName,
    onBlurUpdatePrimaryName,
    onChangeUpdatePrimaryEmail,
    onBlurUpdatePrimaryEmail,
    onFocusUpdatePrimaryEmail,
    onChangeUpdatePrimaryPhone,
    onBlurUpdatePrimaryPhone,
    onFocusUpdatePrimaryPhone,
    onChangeUpdatePrimaryPhone2,
    onBlurUpdatePrimaryPhone2,
    onFocusUpdatePrimaryPhone2,
  } = props;
  const { t } = useTranslation();

  const onChangePrimaryName = useCallback((id: string) => {
    onChangeUpdatePrimaryName(id);
  }, []);

  const onBlurPrimaryName = useCallback(() => {
    onBlurUpdatePrimaryName();
  }, []);

  const onChangePrimaryEmail = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    onChangeUpdatePrimaryEmail(event.target.value);
  }, []);

  const onBlurPrimaryEmail = useCallback(() => {
    onBlurUpdatePrimaryEmail();
  }, []);

  const onFocusPrimaryEmail = useCallback(() => {
    onFocusUpdatePrimaryEmail();
  }, []);

  const onChangePrimaryPhone = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    onChangeUpdatePrimaryPhone(event.target.value);
  }, []);

  const onBlurPrimaryPhone = useCallback(() => {
    onBlurUpdatePrimaryPhone();
  }, []);

  const onFocusPrimaryPhone = useCallback(() => {
    onFocusUpdatePrimaryPhone();
  }, []);

  const onChangePrimaryPhone2 = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    onChangeUpdatePrimaryPhone2(event.target.value);
  }, []);

  const onBlurPrimaryPhone2 = useCallback(() => {
    onBlurUpdatePrimaryPhone2();
  }, []);

  const onFocusPrimaryPhone2 = useCallback(() => {
    onFocusUpdatePrimaryPhone2();
  }, []);

  const getHasErrorField = useCallback(
    (filed?: OrganizationFieldsErrorsFieldDTM) => !!(filed?.isVisited && filed?.message),
    [],
  );

  return (
    <EditProfileOrganizationContactInformation>
      <EditProfileOrganizationHeader>
        {t('PrimaryContact')}
      </EditProfileOrganizationHeader>
      <EditProfileOrganizationSpaceRow>
        <EditProfileOrganizationSpaceColumn widthProp="50%">
          <EditProfileOrganizationTitle>
            {`${t('Name')} *`}
          </EditProfileOrganizationTitle>
          <SelectWithError
            data-class="EditProfileOrganizationSelectPrimaryName"
            value={organization?.membersContactsValue?.id || ''}
            hasError={getHasErrorField(organizationFieldsErrors?.MembersContactsValue)}
            showSearch
            onChange={onChangePrimaryName}
            onBlur={onBlurPrimaryName}
            optionFilterProp="children"
          >
            {organization?.membersContacts?.map((item) => (
              <Option
                value={item.id || ''}
                key={`EditProfileOrganizationSelect_${item.id}`}
              >
                {item.getFullName() || item.email}
              </Option>
            ))}

          </SelectWithError>
        </EditProfileOrganizationSpaceColumn>
        <EditProfileOrganizationSpaceColumn widthProp="50%">
          <EditProfileOrganizationTitle>
            {`${t('Email')}`}
          </EditProfileOrganizationTitle>
          <Tooltip
            visible={!!organizationFieldsErrors?.primaryEmail?.message
              && organizationFieldsErrors?.primaryEmail?.message !== t('basicErrors.REQUIRED_MESSAGE')
              && !!organizationFieldsErrors?.primaryEmail?.isVisited}
            title={`${organizationFieldsErrors?.primaryEmail?.message === t('basicErrors.REQUIRED_MESSAGE') ? '' : organizationFieldsErrors?.primaryEmail?.message || ''}`}
            placement="bottom"
            mode="danger"
          >
            <InputWithError
              value={organization?.membersContactsValue?.email}
              onChange={onChangePrimaryEmail}
              onFocus={onFocusPrimaryEmail}
              onBlur={onBlurPrimaryEmail}
              disabled
            />
          </Tooltip>
        </EditProfileOrganizationSpaceColumn>
      </EditProfileOrganizationSpaceRow>
      <EditProfileOrganizationSpaceInput />
      <EditProfileOrganizationSpaceRow>
        <EditProfileOrganizationSpaceColumn widthProp="50%">
          <EditProfileOrganizationTitle>
            {`${t('Phone')}`}
          </EditProfileOrganizationTitle>
          <Tooltip
            visible={!!organizationFieldsErrors?.primaryPhone?.message
              && organizationFieldsErrors?.primaryPhone?.message !== t('basicErrors.REQUIRED_MESSAGE') && !!organizationFieldsErrors?.primaryPhone?.isVisited}
            title={`${organizationFieldsErrors?.primaryPhone?.message === t('basicErrors.REQUIRED_MESSAGE') ? '' : organizationFieldsErrors?.primaryPhone?.message || ''}`}
            placement="bottom"
            mode="danger"
          >
            <InputWithError
              value={organization?.membersContactsValue?.phone}
              onChange={onChangePrimaryPhone}
              onFocus={onFocusPrimaryPhone}
              onBlur={onBlurPrimaryPhone}
              disabled
            />
          </Tooltip>
        </EditProfileOrganizationSpaceColumn>
        <EditProfileOrganizationSpaceColumn widthProp="50%">
          <EditProfileOrganizationTitle>
            {`${t('Additional Phone')}`}
          </EditProfileOrganizationTitle>
          <Tooltip
            visible={!!organizationFieldsErrors?.primaryPhone2?.message
              && organizationFieldsErrors?.primaryPhone2?.message !== t('basicErrors.REQUIRED_MESSAGE') && !!organizationFieldsErrors?.primaryPhone2?.isVisited}
            title={`${organizationFieldsErrors?.primaryPhone2?.message === t('basicErrors.REQUIRED_MESSAGE') ? '' : organizationFieldsErrors?.primaryPhone2?.message || ''}`}
            placement="bottom"
            mode="danger"
          >
            <InputWithError
              value={organization?.membersContactsValue?.phone2 || ''}
              onChange={onChangePrimaryPhone2}
              onFocus={onFocusPrimaryPhone2}
              onBlur={onBlurPrimaryPhone2}
              disabled
            />
          </Tooltip>
        </EditProfileOrganizationSpaceColumn>
      </EditProfileOrganizationSpaceRow>
    </EditProfileOrganizationContactInformation>
  );
};
