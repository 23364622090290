import React, { ReactNode, forwardRef } from 'react';

import { PDFDocumentComponent } from './PDFDocument.component';

interface IPDFDocumentContainer {
  fileNamePrint?: string;
  header?: ReactNode;
}

const PDFDocumentContainer = forwardRef((props: IPDFDocumentContainer, ref) => (
  <PDFDocumentComponent
    fileNamePrint={props.fileNamePrint}
    header={props.header}
    ref={ref}
  />
));

export { PDFDocumentContainer as PDFDocument };
