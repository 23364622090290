import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { EOrganizationStatus } from 'user-management/models/dtm';
import { WarningBlock } from 'app-wrapper/view/components';

import {
  Wrap,
  Title,
  Warning,
} from './GettingStarted.styled';

interface GettingStartedComponentProps {
  status?: EOrganizationStatus;
}

export const GettingStartedComponent: FC<GettingStartedComponentProps> = ({ status }) => {
  const { t } = useTranslation();

  return (
    <Wrap>
      <Title>
        {t('Welcome to Skypace!')}
      </Title>

      {status === EOrganizationStatus.WAITING_APPROVAL ? (
        <Warning>
          <WarningBlock
            title={t('We are currently verifying your Organization.')}
            text={t('Your organization’s details will be reviewed within 2 days. We will contact you via email, and if the review is successful, you will be officially registered on the platform.')}
          />
        </Warning>
      ) : null}
    </Wrap>
  );
};
