import React from 'react';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';

import { UC } from 'shipment-operations/controllers';
import { R } from 'shipment-operations/repository';

import { FooterComponent } from './Footer.component';

const FooterContainer = () => {
  const { shipmentId = '' } = useParams();
  const permissions = useSelector(R.selectors.shipment.getDocumentsPermissions);

  return (
    <FooterComponent
      shipmentId={shipmentId}
      onSave={UC.shipmentDocumentsSeaworthyCertificates.saveContainersDocuments}
      seaworthyCertificatePermission={permissions.seaworthyCertificate}
    />
  );
};

export { FooterContainer as Footer };
