import styled from 'styled-components';
import Typography from 'antd/es/typography';

export const Section = styled.div`
  margin-top: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  border-radius: 3px;
  background-color: white;
`;

export const Title = styled(Typography)`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
`;
