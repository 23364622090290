import i18n from 'app-wrapper/i18n/i18n';
import themesColors from 'app-wrapper/view/themes/themesColors';

export enum EOrganizationStatus {
  ACTIVE = 'ACTIVE',
  DRAFT = 'DRAFT',
  WAITING_APPROVAL = 'WAITING_APPROVAL',
  REJECTED = 'REJECTED',
  INACTIVE = 'INACTIVE',
}

export const ORGANIZATION_STATUS_NAMES = {
  [EOrganizationStatus.ACTIVE]: i18n.t('Active'),
  [EOrganizationStatus.WAITING_APPROVAL]: i18n.t('Waiting for approval'),
  [EOrganizationStatus.REJECTED]: i18n.t('Rejected'),
  [EOrganizationStatus.DRAFT]: '',
  [EOrganizationStatus.INACTIVE]: '',
};

export const ORGANIZATION_STATUS_COLORS = {
  [EOrganizationStatus.ACTIVE]: 'rgba(117, 203, 63, 1)',
  [EOrganizationStatus.WAITING_APPROVAL]: themesColors.neutralBranding6,
  [EOrganizationStatus.REJECTED]: 'rgba(255, 77, 79, 1)',
  [EOrganizationStatus.DRAFT]: '',
  [EOrganizationStatus.INACTIVE]: '',
};
