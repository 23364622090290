import { createSelector } from 'reselect';

import { RootState } from 'app-wrapper/store';

const localState = (state: RootState) => state.shipmentDrawer;

const getContentType = createSelector(
  localState,
  (state) => state.content,
);

const getLoadingState = createSelector(
  localState,
  (state) => state.isLoading,
);

const getVisibleState = createSelector(
  localState,
  (state) => state.isVisible,
);

export const shipmentDrawerSelectors = {
  getContentType,
  getLoadingState,
  getVisibleState,
};
