import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { observer } from 'mobx-react-lite';

import { MobxStoresContext } from 'app-wrapper/mobxStores';

import { R } from 'shipment-operations/repository';
import { UC } from 'shipment-operations/controllers';

import { FooterComponent } from './Footer.component';

const FooterContainer = observer(() => {
  const isLoading = useSelector(R.selectors.bookingWizard.getIsLoading);
  const isContentUpdating = useSelector(R.selectors.bookingWizard.getIsContentUpdating);
  const isSecondStep = useSelector(R.selectors.bookingWizard.getIsSecondStep);
  const voyageDuration = useSelector(R.selectors.bookingWizard.getVoyageDuration);
  const origin = useSelector(R.selectors.bookingWizard.getShipmentOrigin);
  const destination = useSelector(R.selectors.bookingWizard.getShipmentDestination);
  const { weight, volume } = useSelector(R.selectors.bookingWizard.getCargosTotalWeightAndVolume);
  const containersAmount = useSelector(R.selectors.bookingWizard.getContainersAmount);
  const totalCost = useSelector(R.selectors.bookingWizard.getTotalCost);
  const isCustomerOrg = useSelector(R.selectors.bookingWizard.getIsCurrentOrganizationCustomer);
  const isAgreeAndBookDisabled = useSelector(R.selectors.bookingWizard.getIsAgreeAndBookDisabled);
  const getNameSCAC = useSelector(R.selectors.bookingWizard.getNameSCAC);

  const { additionalServicesDrawerStore } = useContext(MobxStoresContext);
  const addAdditionalServicesDrawerTotalCost = additionalServicesDrawerStore?.getAddAdditionalServicesBookingDrawerTotalCost;
  const servicesGetQuoteTotalCost = additionalServicesDrawerStore?.getAddAdditionalServicesDrawerServicesGetQuoteTotalCost;

  const sumTotalCost = Number(totalCost || 0) + (Number(addAdditionalServicesDrawerTotalCost || 0) - Number(servicesGetQuoteTotalCost || 0));

  return (
    <FooterComponent
      isLoading={isLoading}
      isContentUpdating={isContentUpdating}
      containersAmount={containersAmount}
      isSecondStep={isSecondStep}
      goSecondStep={UC.bookingWizard.goSecondStep}
      goFirstStep={UC.bookingWizard.goFirstStep}
      agreeAndBook={UC.bookingWizard.agreeAndBook}
      voyageDuration={voyageDuration}
      origin={origin}
      destination={destination}
      totalWeight={weight}
      totalVolume={volume}
      isCustomerOrg={isCustomerOrg}
      totalCost={`${sumTotalCost}`}
      nameSCAC={getNameSCAC}
      isAgreeAndBookDisabled={isAgreeAndBookDisabled}
    />
  );
});

export { FooterContainer as Footer };
