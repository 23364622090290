import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ShareInvoiceChargeDTM } from 'shipment-operations/models/dtm';

import {
  Wrap,
  Table,
  Title,
  CategoryTitle,
  TableCharges,
  TableCell,
  TableWrap,
} from './InvoicePDFCharges.styled';

interface InvoicePDFChargesComponentProps {
  originCharges: ShareInvoiceChargeDTM[];
  freightCharges: ShareInvoiceChargeDTM[];
  destinationCharges: ShareInvoiceChargeDTM[];
}

export const InvoicePDFChargesComponent: FC<InvoicePDFChargesComponentProps> = ({
  originCharges,
  freightCharges,
  destinationCharges,
}) => {
  const { t } = useTranslation();

  const columns = useMemo(() => [
    {
      title: t('Charge'),
      width: '40%',
      key: 'object',
      align: 'left' as 'left',
    },
    {
      title: t('Currency '),
      width: '10%',
      key: 'object',
      align: 'left' as 'left',
    },
    {
      title: t('Unit'),
      width: '10%',
      key: 'object',
      align: 'left' as 'left',
    },
    {
      title: t('Cost Per Unit'),
      width: '15%',
      key: 'object',
      align: 'right' as 'right',
    },
    {
      title: t('QTY'),
      width: '10%',
      key: 'object',
      align: 'right' as 'right',
    },
    {
      title: t('Total'),
      width: '15%',
      key: 'object',
      align: 'right' as 'right',
    },
  ], [t]);

  const chargesColumns = useMemo(() => [
    {
      title: t('Charge'),
      width: '40%',
      key: 'object',
      align: 'left' as 'left',
      render: (item: string, record: ShareInvoiceChargeDTM | {}) => {
        const charge = record as ShareInvoiceChargeDTM;

        return (
          <TableCell>
            {charge.chargeName}
          </TableCell>
        );
      },
    },
    {
      title: t('Currency '),
      width: '10%',
      key: 'object',
      align: 'left' as 'left',
      render: (item: string, record: ShareInvoiceChargeDTM | {}) => {
        const charge = record as ShareInvoiceChargeDTM;

        return (
          <TableCell>
            {charge.currency}
          </TableCell>
        );
      },
    },
    {
      title: t('Unit'),
      width: '10%',
      key: 'object',
      align: 'left' as 'left',
      render: (item: string, record: ShareInvoiceChargeDTM | {}) => {
        const charge = record as ShareInvoiceChargeDTM;

        return (
          <TableCell>
            {charge.unit}
          </TableCell>
        );
      },
    },
    {
      title: t('Cost Per Unit'),
      width: '15%',
      key: 'object',
      align: 'right' as 'right',
      render: (item: string, record: ShareInvoiceChargeDTM | {}) => {
        const charge = record as ShareInvoiceChargeDTM;

        return (
          <TableCell>
            {charge.costPerUnit.toFixed(2)}
          </TableCell>
        );
      },
    },
    {
      title: t('QTY'),
      width: '10%',
      key: 'object',
      align: 'right' as 'right',
      render: (item: string, record: ShareInvoiceChargeDTM | {}) => {
        const charge = record as ShareInvoiceChargeDTM;

        return (
          <TableCell>
            {charge.quantity.toFixed(2)}
          </TableCell>
        );
      },
    },
    {
      title: t('Total'),
      width: '15%',
      key: 'object',
      align: 'right' as 'right',
      render: (item: string, record: ShareInvoiceChargeDTM | {}) => {
        const charge = record as ShareInvoiceChargeDTM;

        return (
          <TableCell>
            {charge.total === 0 ? t('Included') : charge.total.toFixed(2)}
          </TableCell>
        );
      },
    },
  ], [t]);

  return (
    <Wrap>
      <Title>
        {t('Charges')}
      </Title>

      <Table
        dataSource={[]}
        columns={columns}
      />

      {originCharges.length ? (
        <>
          <CategoryTitle>
            {t('Origin')}
          </CategoryTitle>

          <TableCharges
            showHeader={false}
            pagination={false}
            dataSource={originCharges}
            columns={chargesColumns}
          />
        </>
      ) : null}

      {freightCharges.length ? (
        <TableWrap>
          <CategoryTitle>
            {t('Freight')}
          </CategoryTitle>

          <TableCharges
            showHeader={false}
            pagination={false}
            dataSource={freightCharges}
            columns={chargesColumns}
          />
        </TableWrap>
      ) : null}

      {destinationCharges.length ? (
        <TableWrap>
          <CategoryTitle>
            {t('Destination')}
          </CategoryTitle>

          <TableCharges
            showHeader={false}
            pagination={false}
            dataSource={destinationCharges}
            columns={chargesColumns}
          />
        </TableWrap>
      ) : null}
    </Wrap>
  );
};
