import React, { FC, useCallback, useMemo } from 'react';

import {
  RFRItemSubCardDescriptionsContainer,
  RFRItemSubCardDescriptionsContainerTitle,
  RFRItemSubCardDescriptionsContainerContent,
  RFRItemSubCardDescriptionsContainerFooter,
  RFRItemSubCardDescriptionsContainerSpanClip,
  CopyIcon,
} from './RFRItemSubCardDescriptions.styled';

interface IRFRItemSubCardDescriptionsProps {
  title?: string | null;
  content?: string | React.ReactNode;
  footer?: string[];
  footerLabel?: string;
  isVerticalCenter?: boolean;
  isFooterClipboard?: boolean;
}

export const RFRItemSubCardDescriptions: FC<IRFRItemSubCardDescriptionsProps> = ({
  title,
  content,
  footerLabel,
  footer,
  isVerticalCenter,
  isFooterClipboard,
}) => {
  const footerComponent = useMemo(() => {
    if (Number(footer?.length) > 0) {
      return `${footer?.join(', ')}`;
    }

    return null;
  }, [footer]);

  const onCopyFooter = useCallback((event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event?.preventDefault();
    event?.stopPropagation();

    navigator.clipboard.writeText(footerComponent || '');
  }, [footerComponent]);

  return (
    <RFRItemSubCardDescriptionsContainer isVerticalCenter={isVerticalCenter}>
      {title === null
        ? ''
        : (
          <RFRItemSubCardDescriptionsContainerTitle>
            <RFRItemSubCardDescriptionsContainerSpanClip>
              {title || ''}
            </RFRItemSubCardDescriptionsContainerSpanClip>
          </RFRItemSubCardDescriptionsContainerTitle>
        )}
      <RFRItemSubCardDescriptionsContainerContent>
        <RFRItemSubCardDescriptionsContainerSpanClip>
          {content || ''}
        </RFRItemSubCardDescriptionsContainerSpanClip>
      </RFRItemSubCardDescriptionsContainerContent>
      <RFRItemSubCardDescriptionsContainerFooter>
        <RFRItemSubCardDescriptionsContainerSpanClip isFooterClipboard={isFooterClipboard}>
          {footerLabel || ''}{footerComponent || ''}
        </RFRItemSubCardDescriptionsContainerSpanClip>
        {isFooterClipboard && (
          <CopyIcon onClick={onCopyFooter} />
        )}
      </RFRItemSubCardDescriptionsContainerFooter>
    </RFRItemSubCardDescriptionsContainer>
  );
};
