import styled from 'styled-components';
import DatePicker from 'antd/es/date-picker';

import { colors } from 'app-wrapper/view/themes/themesColors';

export const ShipmentTransportationLocationDataWrapper = styled.div.attrs({
  'data-class': 'ShipmentTransportationLocationDataWrapper',
})`
  display: flex;
  flex-flow: column nowrap;
  justify-content: stretch;
  height: 100%;
  width: 100%;
`;

export const ShipmentTransportationLocationDataContainer = styled.div.attrs({
  'data-class': 'ShipmentTransportationLocationDataContainer',
})`
  display: flex;
  position: relative;
  flex-flow: column nowrap;
  gap: 10px;
  padding: 24px;
  height: 100%;
  width: 100%;
  overflow-y: auto;
`;

export const ShipmentTransportationLocationDataItem = styled.div.attrs({
  'data-class': 'ShipmentTransportationLocationDataItem',
})`
  display: flex;
  flex-flow: column nowrap;
  height: 58px;
  width: 100%;
  position: relative;
  font-size: 12px;

  & > span {
    margin-bottom: 4px;
    height: 20px;
    line-height: 20px;
  }
`;

export const ShipmentTransportationLocationDataFooter = styled.div.attrs({
  'data-class': 'ShipmentTransportationLocationDataFooter',
})`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  gap: 8px;
  padding: 10px 16px;
  height: 52px;
  width: 100%;
`;

export const ShipmentTransportationLocationDataGrey = styled.span.attrs({
  'data-class': 'ShipmentTransportationLocationDataGrey',
})`
  color: ${colors.gray61};
`;

export const ShipmentTransportationLocationDataDatePicker = styled(DatePicker).attrs({
  'data-class': 'ShipmentTransportationDatePicker',
})`
  height: 32px;

  > .ant-picker-input {
    > input {
      font-size: 12px;
    }
  }
`;
