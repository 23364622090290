import styled from 'styled-components';
import AntdButton from 'antd/es/button';

const buttonSizePaddings = {
  small: '1px 8px',
  middle: '5px 16px',
  large: '8px 16px',
};

const squaredButtonSizePaddings = {
  small: '11px',
  middle: '7px',
  large: '5px',
};

export const Button = styled(AntdButton).attrs((props: {
  'data-class'?: string
}) => ({
  'data-class': props?.['data-class'] || 'AntdButton',
}))`
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  border-radius: 3px;
  background: ${({ theme, type = 'primary' }) => `${theme.button[type].bg}`};
  border: 1px ${({ theme, type = 'primary' }) => `${type !== 'dashed' ? 'solid' : 'dashed'} ${theme.button[type].border}`};
  box-shadow: ${({ theme, type = 'primary' }) => `0px 2px 0px ${theme.button[type].shadow}`};
  font-family: 'Roboto';
  font-weight: 400;
  font-size: ${({ size = 'middle' }) => (size === 'large' ? 16 : 14)}px;

  padding: ${({ size = 'middle' }) => buttonSizePaddings[size]};


  text-shadow: none;
  box-shadow: none;

  &:not([disabled]):active {
    background: ${({ theme, type = 'primary' }) => `${theme.button[type].activeBg}`};
    border-color: ${({ theme, type = 'primary' }) => `${theme.button[type].activeBorder}`};
    color: ${({ theme, type = 'primary' }) => `${theme.button[type].activeText}`};
  }

  &:hover,
  &:focus {
    background: ${({ theme, type = 'primary' }) => `${theme.button[type].hoveredBg}`};
    border-color: ${({ theme, type = 'primary' }) => `${theme.button[type].hoveredBorder}`};
    color: ${({ theme, type = 'primary' }) => `${theme.button[type].hoveredText}`};
  }

  &.ant-btn-dangerous:hover,
  &.ant-btn-dangerous:focus {
    background: ${({ theme, type = 'primary' }) => `${theme.button[type].hoveredBg}`};
    border-color: ${({ theme }) => theme.themesColors.characterBrandingDanger50};
    color: ${({ theme }) => theme.themesColors.characterBrandingDanger};
  }

  &.ant-btn-dangerous,
  &.ant-btn-dangerous {
    background: ${({ theme, type = 'primary' }) => `${theme.button[type].hoveredBg}`};
    border-color: ${({ theme }) => theme.themesColors.characterBrandingDanger};
    color: ${({ theme }) => theme.themesColors.characterBrandingDanger};
  }

  &.ant-btn-dangerous[disabled] {
    color: ${({ theme }) => theme.themesColors.characterSecondaryDot45};
  }

  ${({ theme, type = 'primary', disabled = false }) => disabled && `
    background: ${theme.button[type].disabledBg};
    color: ${theme.button[type].disabledText};
    border: ${theme.button[type].disabledBorder};
    cursor: not-allowed;
  `}
`;

export const SquareButton = styled(Button)`
  padding: ${({ size = 'middle' }) => squaredButtonSizePaddings[size]};
`;

export const SecondaryButton = styled(AntdButton)`
  border-radius: 3px;
`;
