import React, {
  FC,
  useCallback,
  useEffect,
  useState,
  useMemo,
} from 'react';
import {
  Outlet,
  useParams,
  useLocation,
} from 'react-router';

import { ShipmentContentLayout, ShipmentContentExpandedWidthLayout } from 'shipment-operations/view/components';
import { ShipmentHeader } from 'shipment-operations/view/pages/Shipment/components/ShipmentHeader';
import { RouteNames } from 'app-wrapper/constants';

import { MenuTrigger, Sider } from 'app-wrapper/view/components';
import { SubPage } from 'app-wrapper/view/guideline/SubPage';
import { SubMenuTriggerStickyContainer } from 'app-wrapper/view/pages/Home/Home.styled';
import { ShipmentMenu, ShipmentDrawer } from './components';
import {
  ShipmentLayout,
  ShipmentContent,
} from './Shipment.styled';

interface IShipmentComponentProps {
  onUpload: (shipmentId: string) => void
  onClear: () => void
  isSubmittedChanges: boolean
  shouldRefresh: boolean
}

export const ShipmentComponent: FC<IShipmentComponentProps> = ({
  onUpload, onClear, isSubmittedChanges, shouldRefresh,
}) => {
  const { shipmentId } = useParams<'shipmentId'>();
  const { pathname } = useLocation();
  const [isOpen, toggleSiderOpenCloseState] = useState(false);

  const siderToggleHandler = useCallback(() => toggleSiderOpenCloseState(!isOpen), [isOpen]);

  const routesWithExpandedMaxWidth = useMemo(() => [
    RouteNames.SHIPMENT_BILL_OF_LADING(shipmentId),
    RouteNames.SHIPMENT_OVERVIEW(shipmentId),
  ], [shipmentId]);

  const content = useMemo(() => (
    <ShipmentContent>
      <Sider
        trigger={null}
        collapsible
        collapsed={isOpen}
        width={213}
        collapsedWidth={47}
      >
        <ShipmentMenu />
        <SubMenuTriggerStickyContainer>
          <MenuTrigger onClick={siderToggleHandler} isCollapsed={isOpen} />
        </SubMenuTriggerStickyContainer>
      </Sider>
      <SubPage>
        <Outlet />
        <div id="subpage-footer" />
      </SubPage>
    </ShipmentContent>
  ), [isOpen, siderToggleHandler]);

  useEffect(() => {
    if (!shipmentId) {
      return onClear;
    }

    onUpload(shipmentId);

    return onClear;
  }, [isSubmittedChanges, shouldRefresh]);

  return (
    <ShipmentLayout>
      <ShipmentHeader />

      {routesWithExpandedMaxWidth.includes(pathname) ? (
        <ShipmentContentExpandedWidthLayout>
          {content}
        </ShipmentContentExpandedWidthLayout>
      ) : (
        <ShipmentContentLayout>
          {content}
        </ShipmentContentLayout>
      )}
      <ShipmentDrawer />
    </ShipmentLayout>
  );
};
