import React from 'react';

import { R } from 'shipment-operations/repository';
import { UC } from 'shipment-operations/controllers';
import { useAppSelector } from 'app-wrapper/hooks';

import { TransportationActiveChargesComponent } from './TransportationActiveCharges.component';

const TransportationActiveChargesContainer = () => {
  const error = useAppSelector(R.selectors.shipmentActiveCharge.getFullListError);
  const isLoading = useAppSelector(R.selectors.shipmentActiveCharge.getGlobalLoadingData);
  const description = useAppSelector(R.selectors.shipmentActiveCharge.getActiveChargeDescription);

  return (
    <TransportationActiveChargesComponent
      error={error}
      isLoading={isLoading}
      fetchCharges={UC.shipmentActiveCharge.loadFullListCharges}
      fetchCommonData={UC.shipmentActiveCharge.loadDataForCharges}
      onChangeDescription={UC.shipmentActiveCharge.setComments}
      description={description}
      onClear={UC.shipmentActiveCharge.onCloseActiveCharge}
    />
  );
};

export { TransportationActiveChargesContainer as TransportationActiveCharges };
