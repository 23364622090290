import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Spinner from 'antd/es/spin';
import { useParams } from 'react-router';

import {
  SubTitle,
  TableWrapper,
} from 'shipment-operations/view/drawers/ShipmentChanges/ShipmentChanges.styled';
import {
  CustomTable,
  TransportPlan,
} from 'shipment-operations/view/drawers/ShipmentChanges/components';
import { CARGO, CONTAINER } from 'shipment-operations/constants';
import { IChangesTable, ITransportPlanChanges } from 'shipment-operations/models/dtm';

import { GlobalLoader } from 'shipment-operations/view/drawers/MaerskChanges/MaerskChanges.styled';

interface IFirstStepComponentProps {
  cargoTable: IChangesTable[]
  containersTable: IChangesTable[]
  transportPlanCurrent: ITransportPlanChanges[]
  loading: boolean
  savedShipmentId: string
  loadData: (shipmentId: string) => void
}

const FirstStepComponent: FC<IFirstStepComponentProps> = ({
  cargoTable, containersTable, transportPlanCurrent, loading, loadData, savedShipmentId,
}) => {
  const { t } = useTranslation();
  const { shipmentId } = useParams();

  useEffect(() => {
    if (shipmentId || savedShipmentId) {
      loadData(shipmentId || savedShipmentId);
    }
  }, [savedShipmentId]);

  if (loading) {
    return (
      <GlobalLoader>
        <Spinner />
      </GlobalLoader>
    );
  }
  return (
    <>
      <TableWrapper>
        <SubTitle>{t('cargo')}</SubTitle>
        <CustomTable
          data={cargoTable}
          type={CARGO}
          withThreeColumns={false}
          withMismatches={false}
          isRequestTheSameWithShipment={false}
          isCustomer={false}
          maerskMode
        />
      </TableWrapper>
      <TableWrapper>
        <SubTitle>{t('Containers')}</SubTitle>
        <CustomTable
          data={containersTable}
          type={CONTAINER}
          withThreeColumns={false}
          withMismatches={false}
          isRequestTheSameWithShipment={false}
          isCustomer={false}
          maerskMode
        />
      </TableWrapper>
      <TableWrapper>
        <SubTitle>{t('transportPlan')}</SubTitle>
        <TransportPlan
          requestedTitle={t('Requested')}
          requestedData={transportPlanCurrent}
        />
      </TableWrapper>
    </>
  );
};

export { FirstStepComponent };
