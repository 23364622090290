// type ObjectWithId = {id: number}

import { store as _store } from 'app-wrapper/store';

export abstract class BaseRepository<T> {
  // abstract getAllRequest(): void

  abstract getRequest(...rest: any[]): Promise<T>

  abstract getStore(): T | undefined

  getRequestPromise: Promise<T> | null

  isLoaded: boolean;

  store: typeof _store

  constructor(store: typeof _store) {
    this.store = store;
    this.isLoaded = false;
  }

  // abstract getAll(): void

  async get(...rest: any[]) {
    let result;
    if (this.isLoaded && this.getStore()) {
      return this.getStore();
    }

    if (this.getRequestPromise) {
      result = await this.getRequestPromise;
      return result;
    }

    this.getRequestPromise = this.getRequest(...rest);

    try {
      result = await this.getRequestPromise;
      this.isLoaded = true;
    } finally {
      this.getRequestPromise = null;
    }

    return result;
  }

  async reloadGet(...rest: any[]) {
    this.getRequestPromise = null;
    this.isLoaded = false;
    const result = await this.get(...rest);
    return result;
  }

  clear() {
    this.getRequestPromise = null;
    this.isLoaded = false;
  }

  // abstract create(obj: ObjectWithId): void
  //
  // abstract update(obj: ObjectWithId): void
  //
  // save(obj: ObjectWithId) {
  //   if (obj.id) {
  //     this.create(obj);
  //   } else {
  //     this.update(obj);
  //   }
  // }
  //
  // abstract delete (): void
  //
  // abstract deleteAll (): void
}
