import React from 'react';

export const MailCodeSvg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 16 16" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.38288 5.33626L8.00195 1.1797L13.621 5.33626H2.38288ZM0.466446 5.34621L0.44827 5.32399L0.964824 4.94108L7.36187 0.21264C7.54574 0.0767159 7.77193 0.00292969 8.00476 0.00292969C8.23759 0.00292969 8.46379 0.0767159 8.64765 0.21264L15.041 4.94286L15.5575 5.32577L15.5403 5.34676C15.8037 5.39783 16.002 5.62919 16.002 5.90769V15.4315C16.002 15.7476 15.7466 16.0029 15.4305 16.0029H0.573382C0.25731 16.0029 0.00195312 15.7476 0.00195312 15.4315V5.90769C0.00195312 5.62818 0.201656 5.39615 0.466446 5.34621ZM3.55751 8.89182H4.4464V10.0762L5.54967 9.78061L5.77973 10.6392L4.7042 10.9274L5.56898 11.7922L4.94044 12.4207L4.00195 11.4822L3.08527 12.3989L2.45674 11.7704L3.32451 10.9026L2.22418 10.6077L2.45424 9.74915L3.55751 10.0448V8.89182ZM7.55751 8.89182H8.4464V10.0762L9.54967 9.78061L9.77973 10.6392L8.7042 10.9274L9.56898 11.7922L8.94044 12.4207L8.00195 11.4822L7.08528 12.3989L6.45674 11.7704L7.32451 10.9026L6.22418 10.6077L6.45424 9.74915L7.55751 10.0448V8.89182ZM12.4464 8.89182H11.5575V10.0448L10.4542 9.74915L10.2242 10.6077L11.3245 10.9026L10.4567 11.7704L11.0853 12.3989L12.0019 11.4822L12.9404 12.4207L13.569 11.7922L12.7042 10.9274L13.7797 10.6392L13.5497 9.78061L12.4464 10.0762V8.89182ZM1.33529 14.6696H14.6686V6.6696H13.7797H2.22418H1.33529V14.6696Z"
      fill="#73819B"
    />
  </svg>
);
