import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';
import { UC } from 'shipment-operations/controllers';
import { ValidationErrorType } from 'app-wrapper/types';

import { UnNumberInputComponent } from './UnNumberInput.component';

interface UnNumberInputContainerProps {
  cargoId: number;
}

const UnNumberInputContainer: FC<UnNumberInputContainerProps> = ({ cargoId }) => {
  const { unNumber = '', errors } = useSelector(R.selectors.bookingWizard.getCargoById(cargoId));
  const { unNumber: error } = errors;

  return (
    <UnNumberInputComponent
      cargoId={cargoId}
      unNumber={unNumber}
      touchCargoField={UC.bookingWizard.touchCargoField}
      setUnNumber={UC.bookingWizard.setUnNumber}
      showTooltip={errors.unNumber?.type === ValidationErrorType.ALERT}
      error={!!error}
    />
  );
};

export { UnNumberInputContainer as UnNumberInput };
