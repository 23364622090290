import styled from 'styled-components';

import {
  FlexRow,
  FlexCol,
  SubPageContent,
} from 'app-wrapper/view/guideline';

export const Content = styled(SubPageContent)`
  gap: 8px;
`;

export const Header = styled(FlexRow)`
  justify-content: space-between;
`;

export const Title = styled.span`
  margin-bottom: 12px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
`;

export const Results = styled(FlexRow)`
  gap: 16px;
  align-items: flex-start;
`;

export const Inputs = styled(FlexCol)`
  width: 30%;
`;

export const Documents = styled(FlexCol)`
  width: 70%;
`;

export const AboutTitle = styled(Title)`
  margin-bottom: 0;
`;

export const Text = styled.span`
  font-size: 14px;
  line-height: 22px;
`;
