import React from 'react';
import { observer } from 'mobx-react-lite';

import { useMobxStore } from 'app-wrapper/mobxStores';

import { HeaderComponent } from './Header.component';

export const Header = observer(() => {
  const { shipmentServices } = useMobxStore();

  return (
    <HeaderComponent
      dueDate={shipmentServices.getEarliestDueDate}
    />
  );
});
