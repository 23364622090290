import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { UC } from 'shipment-operations/controllers';

import { R } from 'shipment-operations/repository';

import { ShipmentTransportationTabsComponent } from './ShipmentTransportationTabs.component';

const ShipmentTransportationTabsContainer: FC = () => {
  const permissions = useSelector(R.selectors.shipment.getShipmentChargesPermissions);
  const transportationPermissions = useSelector(R.selectors.shipment.getShipmentTransportationPermissions);

  return (
    <ShipmentTransportationTabsComponent
      openAddCharge={UC.shipmentActiveCharge.openTransportationActiveCharge}
      openAddAccessorials={UC.shipmentActiveCharge.openAccessorialActiveCharge}
      permissions={permissions}
      transportationPermissions={transportationPermissions}
    />
  );
};

export {
  ShipmentTransportationTabsContainer as ShipmentTransportationTabs,
};
