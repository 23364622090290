import { apiWorker } from 'app-wrapper/repository/utilsServices';
import isEqual from 'lodash/isEqual';
import { BaseController, controller } from 'proto/BaseController';
import { R } from 'shipment-operations/repository';
import { UC } from 'shipment-operations/controllers';
import { ExportClearanceType } from 'shipment-operations/constants';
import { CustomsClearanceDTM } from 'shipment-operations/models/dtm';

import { validateRequiredField, validateExportClearanceNumber } from './ExportClearance.validate';

@controller
export class ExportClearanceController extends BaseController {
  public sendExportClearance = async (shipmentId: string) => {
    const { exportClearance, errors } = R.selectors.exportClearance.getWholeState(this.store.getState());

    this.dispatch(R.actions.exportClearance.setLoading(true));

    const isThereAnyErrors = Object.values(errors).filter((v) => !!v).length > 0;

    if (isThereAnyErrors) {
      this.dispatch(R.actions.exportClearance.setUpdateAttemptStatus(true));
      this.dispatch(R.actions.exportClearance.setLoading(false));

      return;
    }

    const customs = R.selectors.billOfLadingCommon.getExportCustoms(this.store.getState());
    let response: CustomsClearanceDTM | undefined;

    try {
      if (exportClearance.isCreated && customs?.id) {
        response = await R.services.exportClearance.putExportClearance(shipmentId, customs?.id, exportClearance);
      }
      if (!exportClearance.isCreated) {
        response = await R.services.exportClearance.postExportClearance(shipmentId, exportClearance);
      }
    } catch (e) {
      console.error('Export clearance error');
    }

    if (!response) {
      this.dispatch(R.actions.exportClearance.setUpdateAttemptStatus(true));
      this.dispatch(R.actions.exportClearance.setLoading(false));

      return;
    }

    if (response.number) {
      this.dispatch(R.actions.billOfLadingCommon.setExportCustoms(response));

      const shortShipment = await R.services.shipment.getShipmentShortById(+shipmentId);
      const siMissMatches = await R.services.shipmentChanges.getShipmentChangesBL(shipmentId);

      this.dispatch(R.actions.billOfLadingCommon.setSIMissMatches(siMissMatches.filter(({ current, previous }) => !isEqual(current, previous))));
      this.dispatch(R.actions.shipment.setShipment(shortShipment));
    }

    this.dispatch(R.actions.exportClearance.setExportClearance(response));
    this.dispatch(R.actions.exportClearance.setIntitialState());
    UC.shipmentDrawer.closeDrawer();
    this.dispatch(R.actions.exportClearance.setLoading(false));
    this.updateExportClearanceErrors();
  };

  public reset = () => {
    apiWorker.abortAllRequests();
    this.dispatch(R.actions.exportClearance.reset());
  };

  public discardChanges = () => {
    this.dispatch(R.actions.exportClearance.discardChanges());
    this.updateExportClearanceErrors();
  };

  public touchField = (field: string) => {
    this.dispatch(R.actions.exportClearance.touchField(field));
  };

  public setExportClearanceType = (type: ExportClearanceType) => {
    this.dispatch(R.actions.exportClearance.setExportClearanceType(type));
    this.updateExportClearanceTypeError();
  };

  public setExportClearanceNumber = (exportClearanceNumber?: string) => {
    const exportClearanceNumberFormated = !exportClearanceNumber ? undefined : exportClearanceNumber;
    this.dispatch(R.actions.exportClearance.setExportClearanceNumber(exportClearanceNumberFormated));
    this.updateExportClearanceNumberError();
  };

  private updateExportClearanceTypeError = () => {
    const { type } = R.selectors.exportClearance.getExportClearance(this.store.getState());
    const typeError = validateRequiredField(type);

    this.dispatch(R.actions.exportClearance.setExportClearanceTypeError(typeError));
  };

  private updateExportClearanceNumberError = () => {
    const { number } = R.selectors.exportClearance.getExportClearance(this.store.getState());
    const numberError = validateRequiredField(number) || validateExportClearanceNumber(number);

    this.dispatch(R.actions.exportClearance.setExportClearanceNumberError(numberError));
  };

  private updateExportClearanceErrors = () => {
    this.updateExportClearanceTypeError();
    this.updateExportClearanceNumberError();
  };
}
