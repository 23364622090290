import i18n from 'i18next';

import { ETransportationType } from 'monetary/constants';

export enum ShipmentRoutesType {
  ORIGIN_CONTAINER_YARD = 'ORIGIN_CONTAINER_YARD',
  ORIGIN_CONTAINER_TERMINAL = 'ORIGIN_CONTAINER_TERMINAL',
  DESTINATION_CONTAINER_YARD = 'DESTINATION_CONTAINER_YARD',
  DESTINATION_CONTAINER_TERMINAL = 'DESTINATION_CONTAINER_TERMINAL',
}

export const RoadTypes = [
  {
    value: ETransportationType.SEA,
    label: i18n.t('Sea'),
  },
  {
    value: ETransportationType.RAIL,
    label: i18n.t('Rail'),
  },
  {
    value: ETransportationType.WATER,
    label: i18n.t('Water'),
  },
  {
    value: ETransportationType.ROAD,
    label: i18n.t('Road'),
  },
];
