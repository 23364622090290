import styled from 'styled-components';

import {
  SubPageContent,
  TitleText,
  FlexRow,
} from 'app-wrapper/view/guideline';

export const Section = styled(SubPageContent)`
  padding: 20px 20px 0 20px;
  gap: 16px;
`;

export const Title = styled.span`
  ${TitleText}
`;

export const Header = styled(FlexRow)`
  justify-content: space-between;
`;

export const BaseContent = styled(FlexRow)`
  width: 100%;
  padding: 16px 24px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.themesColors.neutralBranding2};
  border-bottom: 1px solid ${({ theme }) => theme.themesColors.neutralBranding3};
`;
