import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { R } from 'monetary/repository';
import { UC } from 'monetary/controllers';

import { OceanRateDownloadModalComponent } from './OceanRateDownloadModal.component';

const OceanRateDownloadModalContainer: FC = () => {
  const isLoading = useSelector(R.selectors.oceanRatesModal.getLoading);
  const isFormDataFilled = useSelector(R.selectors.oceanRatesModal.isFormDataFilled);
  const isOriginLocationSearchListLoading = useSelector(R.selectors.oceanRatesModal.getOriginLocationSearchListLoading);
  const isDestinationLocationSearchListLoading = useSelector(R.selectors.oceanRatesModal.getDestinationLocationSearchListLoading);
  const isOpen = useSelector(R.selectors.oceanRatesModal.getOpenState);
  const effectiveDate = useSelector(R.selectors.oceanRatesModal.getEffectiveDate);
  const expirationDate = useSelector(R.selectors.oceanRatesModal.getExpirationDate);
  const originLocation = useSelector(R.selectors.oceanRatesModal.getOriginLocation);
  const destinationLocation = useSelector(R.selectors.oceanRatesModal.getDestinationLocation);
  const originLocationSearchList = useSelector(R.selectors.oceanRatesModal.getOriginLocationSearchList);
  const destinationLocationSearchList = useSelector(R.selectors.oceanRatesModal.getDestinationLocationSearchList);
  const carrierSCACList = useSelector(R.selectors.oceanRatesModal.getCarrierScacList);

  return (
    <OceanRateDownloadModalComponent
      isLoading={isLoading}
      isOpen={isOpen}
      isFormDataFilled={isFormDataFilled}
      carrierSCACList={carrierSCACList}
      isOriginLocationSearchListLoading={isOriginLocationSearchListLoading}
      originLocationSearchList={originLocationSearchList}
      isDestinationLocationSearchListLoading={isDestinationLocationSearchListLoading}
      destinationLocationSearchList={destinationLocationSearchList}
      effectiveDate={effectiveDate}
      expirationDate={expirationDate}
      originLocationValue={originLocation}
      destinationLocationValue={destinationLocation}
      onEffectiveDateChange={UC.oceanRatesModal.setEffectiveDate}
      onEffectiveDateBlur={UC.oceanRatesModal.blurEffectiveDateField}
      onExpirationDateChange={UC.oceanRatesModal.setExpirationDate}
      onExpirationDateBlur={UC.oceanRatesModal.blurExpirationDateField}
      onOriginLocationChange={UC.oceanRatesModal.setOriginLocation}
      onOriginLocationBlur={UC.oceanRatesModal.blurOriginLocationField}
      onOriginLocationSearch={UC.oceanRatesModal.searchOriginLocation}
      onDestinationLocationChange={UC.oceanRatesModal.setDestinationLocation}
      onDestinationLocationBlur={UC.oceanRatesModal.blurDestinationLocationField}
      onDestinationLocationSearch={UC.oceanRatesModal.searchDestinationLocation}
      onCarrierSCACChange={UC.oceanRatesModal.setCarrierSCACList}
      onDownload={UC.oceanRatesModal.downloadRates}
      onClose={UC.oceanRatesModal.closeModal}
    />
  );
};

export { OceanRateDownloadModalContainer as OceanRateDownloadModal };
