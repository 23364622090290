import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { R } from 'shipment-operations/repository';
import { UC } from 'shipment-operations/controllers';
import { PermissionAttributePolicy, TradeType } from 'shipment-operations/constants';
import { R as userManagementR } from 'user-management/repository';
import { EOrganizationMemberRole } from 'user-management/constants';

import { SBLShippingPartiesComponent } from './SBLShippingParties.component';

const SBLShippingPartiesContainer = () => {
  const { shipmentId = '' } = useParams();
  const {
    shipper,
    consignee,
    notifyParty,
    forwardingAgent,
    houseConsignee,
    houseNotifyParty,
    houseShipper,
  } = useSelector(R.selectors.billOfLadingCommon.getShippingParties);
  const paymentTerms = useSelector(R.selectors.billOfLadingCommon.getPaymentTerms);
  const isHBLPage = useSelector(R.selectors.billOfLadingCommon.getIsHBLPage);
  const portOfDischarge = useSelector(R.selectors.billOfLadingCommon.getPortOfDischarge);
  const permissions = useSelector(R.selectors.shipment.getSIPermissions);
  const organization = useSelector(userManagementR.selectors.userOrganizationData.getUserOrganization);

  const isCustomerOrg = useMemo(() => !!(organization && organization.role === EOrganizationMemberRole.CUSTOMER), [organization]);
  const isAbleToEdit = useMemo(() => !isHBLPage && permissions.editSISections === PermissionAttributePolicy.WRITE, [permissions, isHBLPage]);
  const isAbleToEditCustomer = useMemo(() => Boolean(!isHBLPage && paymentTerms && paymentTerms.tradeType === TradeType.EXPORT), [paymentTerms, isHBLPage]);

  const shipperData = useMemo(() => {
    if (isHBLPage) {
      return houseShipper;
    }

    return shipper;
  }, [houseShipper, shipper, isHBLPage]);

  const consigneeData = useMemo(() => {
    if (isHBLPage) {
      return houseConsignee;
    }

    return consignee;
  }, [houseConsignee, consignee, isHBLPage]);

  const notifyPartyData = useMemo(() => {
    if (isHBLPage) {
      return houseNotifyParty;
    }

    return notifyParty;
  }, [houseNotifyParty, notifyParty, isHBLPage]);

  return (
    <SBLShippingPartiesComponent
      shipper={shipperData}
      notifyParty={notifyPartyData}
      forwardingAgent={forwardingAgent}
      consignee={consigneeData}
      portOfDischarge={portOfDischarge}
      shipmentId={shipmentId}
      isCustomerOrg={isCustomerOrg}
      isEditable={isCustomerOrg ? isAbleToEditCustomer : isAbleToEdit}
      openEditShippingPartyDrawer={UC.billOfLading.openEditShippingPartyDrawer}
    />
  );
};

export { SBLShippingPartiesContainer as SBLShippingParties };
