import styled from 'styled-components';

export const Icon = styled.svg.attrs((props: {
  color: string
  'data-class'?: string
}) => ({
  color: props.color,
  'data-class': props?.['data-class'] || 'Icon',
}))`
  color: ${({ theme, color }) => color || theme.themesColors.primaryBranding6};
`;
