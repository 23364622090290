import React from 'react';
import { UC } from 'app-wrapper/controllers';
import { FooterComponent } from './Footer.component';

const FooterContainer = () => (
  <FooterComponent
    isLoading={false}
    acceptTermsAndConditions={UC.overview.acceptTermsAndConditions}
    closeDrawer={UC.overview.closeTermsOfConditionDrawer}
  />
);

export { FooterContainer as Footer };
