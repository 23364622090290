import React from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';

import { HBLFlowManagementComponent } from './HBLFlowManagement.component';

export const HBLFlowManagement = React.memo(() => {
  const {
    mblFinalId,
    finalMblDocument,
  } = useSelector(R.selectors.billOfLadingCommon.getSIDetails);

  return (
    <HBLFlowManagementComponent
      hasFinalMBL={!!mblFinalId}
      hasFinalMBLDocument={!!finalMblDocument}
    />
  );
});
