import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';

import { ShippingPartiesFormComponent } from './ShippingPartiesForm.component';

const ShippingPartiesContainer: FC = () => {
  const isShippingPartiesListLoading = useSelector(R.selectors.shippingParties.getShippingPartiesListLoadingState);
  const currentCompany = useSelector(R.selectors.shippingParties.getSelectedCurrentCompany);

  return (
    <ShippingPartiesFormComponent
      isLoading={isShippingPartiesListLoading}
      hasAttachedCompany={!!currentCompany}
    />
  );
};

export { ShippingPartiesContainer as ShippingPartiesForm };
