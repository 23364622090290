import React, { FC, useMemo, useCallback } from 'react';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';

import { RouteNames } from 'app-wrapper/constants';
import { ShipmentPreviewDTM } from 'shipment-operations/models/dtm';

import { InputSearch } from 'app-wrapper/view/components';
import {
  Wrap,
  Image,
  Content,
  Title,
  Text,
  AutoComplete,
  NotFound,
  NotFoundIcon,
  NotFoundText,
} from './Tracker.styled';
import trackerSrc from './assets/tracker.png';

interface TrackerComponentProps {
  value: string;
  setValue: (value: string) => void;
  onSearch: () => void;
  options: string[];
  shipmentOptions: ShipmentPreviewDTM[];
}

export const TrackerComponent: FC<TrackerComponentProps> = ({
  value,
  setValue,
  onSearch,
  options,
  shipmentOptions,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const optionsToRender = useMemo(() => options.map((shipmentName) => ({ value: shipmentName })), [options]);

  const handleSelect = useCallback((selectedShipmentName: string) => {
    const targetShipment = shipmentOptions.find(({ shipmentName }) => shipmentName === selectedShipmentName);

    if (!targetShipment) {
      return;
    }

    navigate(RouteNames.SHIPMENT_TRANSPORTATION_TRACKER(targetShipment.id));
    setValue('');
  }, [navigate, setValue, shipmentOptions]);

  return (
    <Wrap>
      <Image src={trackerSrc} />

      <Content>
        <Title>
          {t('Track Shipment')}
        </Title>

        <Text>
          {t('Container №, Shipment № or Cargo Reference')}
        </Text>
      </Content>

      <AutoComplete
        options={optionsToRender}
        value={value}
        onChange={setValue}
        onSearch={onSearch}
        onSelect={handleSelect}
        notFoundContent={(
          <NotFound>
            <NotFoundIcon />

            <NotFoundText>
              {t('Not results found')}
            </NotFoundText>
          </NotFound>
        )}
      >
        <InputSearch placeholder={t('Ex: MSCU4321, Shipment 4321, PO26345')} />
      </AutoComplete>
    </Wrap>
  );
};
