import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import isEmpty from 'lodash/fp/isEmpty';

import { InnerContainer } from 'shipment-operations/view/components';
import { IGroupedChargesDTM, ChargeViewDtm } from 'shipment-operations/models/dtm';
import { ContainersTable, CustomTable, TotalFooter } from 'shipment-operations/view/pages/ShipmentTransportationCharges/components';
import { TotalWrapper } from 'shipment-operations/view/pages/ShipmentTransportationCharges/ShipmentTransportationCharges.styled';

interface IAdditionalComponentProps {
  containers: ChargeViewDtm[]
  innerData: IGroupedChargesDTM
  tableData: ChargeViewDtm[]
  totalData: {
    apTotalCost: number
    arTotalCost: number
    profit: number
  }
}

const AdditionalComponent: FC<IAdditionalComponentProps> = ({
  containers, innerData, tableData, totalData,
}) => {
  const { t } = useTranslation();

  if (isEmpty(tableData) && isEmpty(containers)) {
    return null;
  }

  return (
    <>
      <InnerContainer>
        {!isEmpty(containers) && (
          <ContainersTable dataSource={containers} innerTableData={innerData} subTitle={!isEmpty(containers) ? t('additionalChargesTitle') : ''} />
        )}
        {!isEmpty(tableData) && (
          <CustomTable data={tableData} subTitle={!isEmpty(containers) ? '' : t('additionalChargesTitle')} />
        )}
      </InnerContainer>
      <TotalWrapper>
        <TotalFooter dataSource={[totalData]} />
      </TotalWrapper>
    </>
  );
};

export { AdditionalComponent };
