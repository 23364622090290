export enum ContainerBasicTypes {
  'USUAL' = 'USUAL',
  'REEFER' = 'REEFER',
}

// REEFER TYPES

export enum ContainerReeferTypes {
  '22R0' = '22R0',
  '25R1' = '25R1',
  '42R0' = '42R0',
  '45R1' = '45R1',
  'L5R1' = 'L5R1',
}

export const ContainerReeferTypesArray: ContainerReeferTypes[] = [
  ContainerReeferTypes['22R0'],
  ContainerReeferTypes['25R1'],
  ContainerReeferTypes['42R0'],
  ContainerReeferTypes['45R1'],
  ContainerReeferTypes.L5R1,
];

const isContainerReeferTypes = (value: string): value is ContainerReeferTypes => value in ContainerReeferTypes;

export const ContainerIsReeferType = (value: string | ContainerReeferTypes) => {
  if (isContainerReeferTypes(value)) {
    return ContainerReeferTypesArray.includes(value);
  }

  return false;
};

export const ContainerReeferTypesNamesLongConst = {
  [ContainerReeferTypes['22R0']]: '20` Reefer Standard',
  [ContainerReeferTypes['25R1']]: '20` Reefer High Cube',
  [ContainerReeferTypes['42R0']]: '40` Reefer Standard',
  [ContainerReeferTypes['45R1']]: '40` Reefer High Cube',
  [ContainerReeferTypes.L5R1]: '45` Reefer High Cube',
};

export const ContainerReeferTypesNamesShortConst = {
  [ContainerReeferTypes['22R0']]: '20` RF',
  [ContainerReeferTypes['25R1']]: '20` RE HC',
  [ContainerReeferTypes['42R0']]: '40` RF',
  [ContainerReeferTypes['45R1']]: '40` RE HC',
  [ContainerReeferTypes.L5R1]: '45` RE HC',
};

// USUAL TYPES

export enum ContainerUsualTypes {
  '22G0' = '22G0',
  '42G0' = '42G0',
  '45G0' = '45G0',
  'L5G0' = 'L5G0',
}

export const ContainerUsualTypesArray: ContainerUsualTypes[] = [
  ContainerUsualTypes['22G0'],
  ContainerUsualTypes['42G0'],
  ContainerUsualTypes['45G0'],
  ContainerUsualTypes.L5G0,
];

const isContainerUsualTypes = (value: string): value is ContainerUsualTypes => value in ContainerUsualTypes;

export const ContainerIsUsualType = (value: string | ContainerUsualTypes) => {
  if (isContainerUsualTypes(value)) {
    return ContainerUsualTypesArray.includes(value);
  }

  return false;
};

export const ContainerTypesConst = {
  [ContainerUsualTypes['22G0']]: '20',
  [ContainerUsualTypes['42G0']]: '40',
  [ContainerUsualTypes['45G0']]: '40',
  [ContainerUsualTypes.L5G0]: '45',
  [ContainerReeferTypes['22R0']]: '20',
  [ContainerReeferTypes['25R1']]: '20',
  [ContainerReeferTypes['42R0']]: '40',
  [ContainerReeferTypes['45R1']]: '40',
  [ContainerReeferTypes.L5R1]: '45',
};

export const ContainerUsualTypesNamesLongConst = {
  [ContainerUsualTypes['22G0']]: '20` Dry Standard',
  [ContainerUsualTypes['42G0']]: '40` Dry Standard',
  [ContainerUsualTypes['45G0']]: '40` Dry High Cube',
  [ContainerUsualTypes.L5G0]: '45` Dry High Cube',
};

export const ContainerUsualTypesNamesShortConst = {
  [ContainerUsualTypes['22G0']]: '20` ST',
  [ContainerUsualTypes['42G0']]: '40` ST',
  [ContainerUsualTypes['45G0']]: '40` HC',
  [ContainerUsualTypes.L5G0]: '45` HC',
};

export const commonContainersTypesLong = {
  ...ContainerUsualTypesNamesLongConst,
  ...ContainerReeferTypesNamesLongConst,
};

// ALL TYPES

export enum CommonContainerTypes {
  '22R0' = '22R0',
  '25R1' = '25R1',
  '42R0' = '42R0',
  '45R1' = '45R1',
  'L5R1' = 'L5R1',
  '22G0' = '22G0',
  '42G0' = '42G0',
  '45G0' = '45G0',
  'L5G0' = 'L5G0',
}

export const ContainerAllTypesArray: CommonContainerTypes[] = [
  CommonContainerTypes['22G0'],
  CommonContainerTypes['42G0'],
  CommonContainerTypes['45G0'],
  CommonContainerTypes.L5G0,
  CommonContainerTypes['22R0'],
  CommonContainerTypes['25R1'],
  CommonContainerTypes['42R0'],
  CommonContainerTypes['45R1'],
  CommonContainerTypes.L5R1,
];

export const isContainerAllTypes = (value: string): value is CommonContainerTypes => value in CommonContainerTypes;

export const ContainerIsAllType = (value: string | CommonContainerTypes) => {
  if (isContainerAllTypes(value)) {
    return ContainerAllTypesArray.includes(value);
  }

  return false;
};

export const ContainerAllTypesNamesLongConst = {
  ...ContainerReeferTypesNamesLongConst,
  ...ContainerUsualTypesNamesLongConst,
};

export const ContainerAllTypesNamesShortConst = {
  ...ContainerReeferTypesNamesShortConst,
  ...ContainerUsualTypesNamesShortConst,
};

export const ContainerAllTypesArraySort: string[] = [
  CommonContainerTypes['22G0'],
  CommonContainerTypes['42G0'],
  CommonContainerTypes['45G0'],
  CommonContainerTypes.L5G0,
  CommonContainerTypes['22R0'],
  CommonContainerTypes['25R1'],
  CommonContainerTypes['42R0'],
  CommonContainerTypes['45R1'],
  CommonContainerTypes.L5R1,
];

export enum EContainerVGMMethodTypes {
  WEIGHTED_AFTER_PACKING = 'WEIGHTED_AFTER_PACKING',
  CARGO_WEIGHT_ADDED_TO_CONTAINER = 'CARGO_WEIGHT_ADDED_TO_CONTAINER',
}

export enum EContainerVGMTypes {
  SUBMITTED = 'SUBMITTED',
  NOT_SUBMITTED = 'NOT_SUBMITTED',
}

export enum EContainerRailBillingTypes {
  SUBMITTED = 'SUBMITTED',
  NOT_SUBMITTED = 'NOT_SUBMITTED',
}
