interface INormalizationCostProps {
  toFixed?: number
  thousandthSeparatorComma?: boolean
}

export const normalizationCost = (value: number | string, options?: INormalizationCostProps) => {
  const {
    toFixed,
    thousandthSeparatorComma,
  } = options || {};
  let newValue: number | string = value;

  if (toFixed || typeof toFixed === 'number') {
    newValue = Number(newValue).toFixed(toFixed);
  }

  if (thousandthSeparatorComma) {
    newValue = Intl.NumberFormat('en-Us').format(Number(newValue));

    if (toFixed) {
      const temp = newValue.split('.');
      if (temp?.[1] && temp[1].length < toFixed) {
        newValue = `${newValue}${'0'.repeat(toFixed - temp[1].length)}`;
      } else if (!temp?.[1]) {
        newValue = `${newValue}.${'0'.repeat(toFixed)}`;
      }
    }
  }

  return newValue || newValue === 0 ? newValue : '';
};
