import styled from 'styled-components';
import Typography from 'antd/es/typography';

import { Input } from 'app-wrapper/view/components';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  transition: .2s height ease;
  overflow: hidden;
`;

export const Field = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const StyledInput = styled(Input)`
  width: 100%;
  font-size: 12px;
  font-weight: 400;
  color: ${({ theme }) => theme?.themesColors?.neutralBranding10};
  min-height: 32px;
`;

export const Label = styled(Typography)`
  margin-bottom: 4px;
  font-size: 12px;
  line-height: 22px;
`;
