import React, { FC } from 'react';
import WarningOutlined from '@ant-design/icons/WarningOutlined';

import {
  TasksBlockSummaryComponentsSubTitle,
  TasksBlockSummaryComponentsTitle, TasksBlockSummaryComponentsTitleContent,
  TasksBlockSummaryComponentsTitleContentCount,
  TasksBlockSummaryComponentsTitleContentIcon,
  TasksBlockSummaryComponentsTitleWrap,
  TasksBlockSummaryComponentsWrap,
} from './TasksBlockSummary.styled';

interface ITasksBlockSummaryComponentsProps {
  titleName?: string;
  titleSubName?: string;
  count?: string;
  iconInfo?: React.ReactNode;
}

export const TasksBlockSummaryComponents: FC<ITasksBlockSummaryComponentsProps> = (props) => {
  const {
    titleName,
    titleSubName,
    count,
    iconInfo,
  } = props;

  return (
    <TasksBlockSummaryComponentsWrap>
      <TasksBlockSummaryComponentsTitleWrap>
        <TasksBlockSummaryComponentsTitle>
          {titleName || ''}
        </TasksBlockSummaryComponentsTitle>
        <TasksBlockSummaryComponentsSubTitle>
          {titleSubName || ''}
        </TasksBlockSummaryComponentsSubTitle>
      </TasksBlockSummaryComponentsTitleWrap>

      <TasksBlockSummaryComponentsTitleContent>
        <TasksBlockSummaryComponentsTitleContentIcon>
          {iconInfo || <WarningOutlined />}
        </TasksBlockSummaryComponentsTitleContentIcon>

        <TasksBlockSummaryComponentsTitleContentCount>
          {count || ''}
        </TasksBlockSummaryComponentsTitleContentCount>
      </TasksBlockSummaryComponentsTitleContent>
    </TasksBlockSummaryComponentsWrap>
  );
};
