import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  Column,
  Title,
  Text,
  Image,
} from 'shipment-operations/view/pages/ShipmentBillOfLading/components/MBLFlowManagement/components/SubmittedContent/SubmittedContent.styled';

import folderSrc from './folder-warning.png';
import { Content } from './RejectedContent.styled';

export const RejectedContent = () => {
  const { t } = useTranslation();

  return (
    <Content>
      <Image
        src={folderSrc}
        alt=""
      />

      <Column>
        <Title>
          {t('Shipping Instructions have been rejected by the carrier')}
        </Title>

        <Text>
          {t('Your shipping instructions have been rejected by the carrier. Please contact the carrier representative to understand the reasons for the rejection.')}
        </Text>
      </Column>
    </Content>
  );
};
