import React, { FC, memo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import FilterFilled from '@ant-design/icons/FilterFilled';

import { CompanyAddressDTM } from 'user-management/models/dtm';
import { EmptyStateForPages } from 'app-wrapper/view/components';
import themesColors from 'app-wrapper/view/themes/themesColors';
import {
  SubPageContent, SubPageHeader, SubPageHeaderContainer, SubPageHeaderTitle, Wrapper,
} from 'app-wrapper/view/guideline';

import { AACTable } from './components';
import { AccountDrawer } from '../Account/components';

interface IAddressesAndContactsComponentProps {
  addresses?: CompanyAddressDTM[]
  isLoading?: boolean
  runComponent: () => void
  onClickAddAddress: () => void;
  onClickAddressItem: (id: string) => () => void;
  onClickEditAddress: (id: string) => () => void;
}

const AddressesAndContactsComponent: FC<IAddressesAndContactsComponentProps> = (props) => {
  const {
    addresses,
    isLoading,
    runComponent,
    onClickAddAddress,
    onClickAddressItem,
    onClickEditAddress,
  } = props;
  const { t } = useTranslation();

  useEffect(runComponent, []);

  return (
    <Wrapper>
      <SubPageHeader>
        <SubPageHeaderContainer>
          <SubPageHeaderTitle>
            {t('Addresses & Contacts')}
          </SubPageHeaderTitle>
        </SubPageHeaderContainer>
      </SubPageHeader>

      {addresses?.length || isLoading ? (
        <SubPageContent>
          <AACTable
            allData={addresses}
            isLoad={isLoading}
            nextPageTasks={() => ''}
            onStart={() => ''}
            onClickAddressItem={onClickAddressItem}
            onClickAddAddress={onClickAddAddress}
            onClickEditAddress={onClickEditAddress}
          />
        </SubPageContent>
      ) : null}

      {!addresses?.length && !isLoading ? (
        <EmptyStateForPages
          icon={(
            <FilterFilled
              width={28}
              height={22}
              size={28}
              style={{
                color: themesColors.neutralBranding65,
                width: '28px',
                height: '22px',
              }}
            />
          )}
          headerText={t('No contacts found')}
          contentText={t('There are no contracts at this time. Create a contract to get started.')}
          buttonText={t('Create contact')}
          onClickButton={onClickAddAddress}
        />
      ) : null}
      <AccountDrawer />
    </Wrapper>
  );
};

const AddressesAndContactsComponentCached = memo(AddressesAndContactsComponent);

export { AddressesAndContactsComponentCached as AddressesAndContactsComponent };
