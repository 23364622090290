import React, {
  FC, useCallback, useEffect, useMemo,
} from 'react';
import Tabs from 'antd/es/tabs';
import { useTranslation } from 'react-i18next';
import {
  Wrapper, SubPageHeader, SubPageHeaderContainer, SubPageHeaderTitle,
} from 'app-wrapper/view/guideline';

import { CreateInvoiceButton, Dashboard } from 'shipment-operations/view/pages/ShipmentCharges/components';
import { ShipmentChargesPermissionsDtm } from 'shipment-operations/models/dtm';

import {
  CREATE_AP_INVOICE,
  CREATE_INVOICE,
  CUSTOMER_VIEW,
  OPERATOR_VIEW,
  PermissionAttributePolicy,
} from 'shipment-operations/constants';
import { RouteNames } from 'app-wrapper/constants';
import {
  useParams, Outlet,
} from 'react-router';

interface IShipmentChargesComponent {
  permissions: ShipmentChargesPermissionsDtm;
  setTabOwner: (tabOwner: string) => void;
  switchPage: (path: string) => void;
  toggleOpenModal: (state: string) => void
  mode: string
  isLoading: boolean
}

const tabStyle = { overflow: 'unset' };

const ShipmentChargesComponent: FC<IShipmentChargesComponent> = ({
  permissions, setTabOwner, toggleOpenModal, mode, switchPage, isLoading,
}) => {
  const { t } = useTranslation();
  const { shipmentId } = useParams<'shipmentId'>();
  const onChangeTab = useCallback((key) => setTabOwner(key), []);
  const onChangeNavigationTab = useCallback((key) => switchPage(key), []);
  const openCreateInvoice = useCallback(() => toggleOpenModal(CREATE_INVOICE), []);
  const openCreateAPInvoice = useCallback(() => toggleOpenModal(CREATE_AP_INVOICE), []);
  const extraContent = useMemo(() => (
    <CreateInvoiceButton openCreateInvoice={openCreateInvoice} openCreateAPInvoice={openCreateAPInvoice} />
  ), []);

  useEffect(() => {
    if (permissions.shipmentChargesAvailability === PermissionAttributePolicy.READ) {
      setTabOwner(CUSTOMER_VIEW);
    }
  }, [permissions]);

  return (
    <Wrapper>
      <SubPageHeader>
        <SubPageHeaderContainer>
          <SubPageHeaderTitle>
            {t('Cost Tracking')}
          </SubPageHeaderTitle>
        </SubPageHeaderContainer>
      </SubPageHeader>
      {permissions.shipmentChargesAvailability === PermissionAttributePolicy.WRITE
        ? (
          <Tabs
            destroyInactiveTabPane
            onChange={onChangeTab}
            tabBarExtraContent={permissions.shipmentChargesAvailability === PermissionAttributePolicy.WRITE && mode === OPERATOR_VIEW && extraContent}
            style={tabStyle}
          >
            <Tabs.TabPane key={OPERATOR_VIEW} tab={t('operatorView')} />
            <Tabs.TabPane key={CUSTOMER_VIEW} tab={t('customerView')} />
          </Tabs>
        )
        : (
          <Tabs
            destroyInactiveTabPane
            onChange={onChangeNavigationTab}
            style={tabStyle}
          >
            <Tabs.TabPane tab={t('Charges')} key={RouteNames.SHIPMENT_CHARGES(shipmentId)} />
            <Tabs.TabPane tab={t('accessorials')} key={RouteNames.SHIPMENT_CHARGES_ACCESSORIALS(shipmentId)} />
            <Tabs.TabPane tab={t('freeTime')} key={RouteNames.SHIPMENT_CHARGES_FREE_TIME(shipmentId)} />
          </Tabs>
        )}
      {!isLoading && (mode === OPERATOR_VIEW) && (permissions.shipmentChargesAvailability === PermissionAttributePolicy.WRITE) && <Dashboard />}
      <Outlet />
    </Wrapper>
  );
};

export { ShipmentChargesComponent };
