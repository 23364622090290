import React, {
  FC,
  useMemo,
  useEffect,
  useCallback,
} from 'react';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';

import { Tooltip } from 'app-wrapper/view/components';
import { ExportClearanceType } from 'shipment-operations/constants';
import { FlexCol } from 'app-wrapper/view/guideline';

import {
  Title,
  Text,
  Label,
  InputITN,
  SubmitButton,
} from './InputActivity.styled';

interface IInputActivityComponentProps {
  type: ExportClearanceType;
  error?: string;
  activityInitialValue?: string;
  setInputValue: (value: string) => void;
  setInputError: (error?: string) => void;
  value: string;
  submitInputActivity: (type: ExportClearanceType, shipmentId: string) => void;
}

export const InputActivityComponent: FC<IInputActivityComponentProps> = ({
  type,
  error,
  activityInitialValue,
  setInputValue,
  setInputError,
  value,
  submitInputActivity,
}) => {
  const { shipmentId = '' } = useParams();
  const { t } = useTranslation();

  const placeholder = useMemo(() => {
    if (type === ExportClearanceType.ITN) {
      return t('Please Enter ITN');
    }

    if (type === ExportClearanceType.ISF) {
      return t('Please Enter ISF');
    }

    return t('Please Enter MRN');
  }, [type, t]);

  const handleChange = useCallback((e) => {
    setInputValue(e.target.value);
  }, [setInputValue]);

  const handleSubmit = useCallback(() => {
    submitInputActivity(type, shipmentId);
  }, [submitInputActivity, type, shipmentId]);

  const typeName = useMemo(() => {
    if (type === ExportClearanceType.ITN) {
      return t('ITN');
    }

    if (type === ExportClearanceType.ISF) {
      return t('ISF');
    }

    return t('MRN');
  }, [type]);

  useEffect(() => {
    setInputValue(activityInitialValue || '');
    setInputError(undefined);
  }, []);

  return (
    <FlexCol>
      <Title>
        {type === ExportClearanceType.ITN ? t('Enter ITN Number') : null}
        {type === ExportClearanceType.ISF ? t('Enter ISF Number') : null}
        {type === ExportClearanceType.MRN ? t('Enter MRN Number') : null}
      </Title>

      <Text>
        {type === ExportClearanceType.ITN ? t('The ITN is an Automated Export System (AES) generated number assigned to a shipment confirming that the EEI was accepted and is on file in the AES.') : null}
        {type === ExportClearanceType.ISF ? t('ISF transaction number: It is a unique number generated by the US Customs ACE ABI Interface, which confirms that ISF is in their system') : null}
        {type === ExportClearanceType.MRN ? t('MRN is a customs identification number that’s created each time a declaration is submitted for importing or exporting goods.') : null}
      </Text>

      <Label>
        {typeName}
      </Label>

      <Tooltip
        visible={!!error && type === ExportClearanceType.ITN}
        title={error ? t(`${typeName} Number must begin with X, followed by 14 digits.`) : undefined}
        mode="danger"
      >
        <InputITN
          error={!!error && type === ExportClearanceType.ITN}
          placeholder={placeholder}
          onChange={handleChange}
          value={value}
          maxLength={15}
        />
      </Tooltip>

      <SubmitButton
        disabled={!value.length || value === activityInitialValue}
        onClick={handleSubmit}
      >
        {t('Submit')}
      </SubmitButton>
    </FlexCol>
  );
};
