import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { R } from 'user-management/repository';

import { OrganizationContactInformationComponent } from './OrganizationContactInformation.component';

const OrganizationContactInformationContainer: FC = () => {
  const organization = useSelector(R.selectors.userOrganizationData.getUserOrganization);

  return (
    <OrganizationContactInformationComponent
      organization={organization}
    />
  );
};

export { OrganizationContactInformationContainer as OrganizationContactInformation };
