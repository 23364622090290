import {
  IsBoolean,
  IsOptional,
  IsString, ValidateNested,
} from 'class-validator';
import { BaseDTM } from 'proto/BaseDTM';

export interface IFreightSelectFieldDTM {
  code?: string
  description?: string
  timezoneId?: string
  id?: string
  type?: string
  forbidden?: boolean
  locationName?: string
  subdivisionCode?: string
  subdivisionName?: string
  countryCode?: string
  countryName?: string
  coordinates?: IFreightSelectFieldCoordinatesDTM
}

export class FreightSelectFieldDTM extends BaseDTM<IFreightSelectFieldDTM> {
  @IsOptional()
  @IsString()
  code?: string;

  @IsOptional()
  @IsString()
  description?: string;

  @IsOptional()
  @IsString()
  timezoneId?: string;

  @IsOptional()
  @IsString()
  id?: string;

  @IsOptional()
  @IsString()
  type?: string;

  @IsOptional()
  @IsBoolean()
  forbidden?: boolean;

  @IsOptional()
  @IsString()
  locationName?: string;

  @IsOptional()
  @IsString()
  subdivisionCode?: string;

  @IsOptional()
  @IsString()
  subdivisionName?: string;

  @IsOptional()
  @IsString()
  countryCode?: string;

  @IsOptional()
  @IsString()
  countryName?: string;

  @IsOptional()
  @ValidateNested()
  coordinates?: FreightSelectFieldCoordinatesDTM;
}

export interface IFreightSelectFieldCountryDTM {
  code?: string
  name?: string
  forbidden?: boolean
}

export class FreightSelectFieldCountryDTM extends BaseDTM<IFreightSelectFieldCountryDTM> {
  @IsOptional()
  @IsString()
  code?: string;

  @IsOptional()
  @IsString()
  name?: string;

  @IsOptional()
  @IsBoolean()
  forbidden?: boolean
}

export interface IFreightSelectFieldCoordinatesDTM {
  lat?: string
  lng?: string
}

export class FreightSelectFieldCoordinatesDTM extends BaseDTM<IFreightSelectFieldCoordinatesDTM> {
  @IsOptional()
  @IsString()
  lat?: string;

  @IsOptional()
  @IsString()
  lng?: string;
}
