import React from 'react';

import { IPropsIcon } from 'app-wrapper/interfaces/icon';

export const PriorityLowSvg = (props: IPropsIcon) => {
  const { width, height, style } = props;
  return (
    <svg
      className="PriorityLowSvg"
      width={width || '16'}
      height={height || '17'}
      viewBox="0 0 16 17"
      color={style?.color || '#99A3B5'}
      style={style || undefined}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect y="0.5" width="16" height="16" rx="2" fill="#F9F9FB" />
      <path fillRule="evenodd" clipRule="evenodd" d="M12.184 6.26035C11.8369 5.91322 11.274 5.91322 10.9269 6.26035L7.99989 9.18737L5.07288 6.26035C4.72574 5.91322 4.16293 5.91322 3.8158 6.26035C3.46866 6.60748 3.46866 7.17029 3.8158 7.51743L7.37135 11.073C7.71848 11.4201 8.2813 11.4201 8.62843 11.073L12.184 7.51743C12.5311 7.1703 12.5311 6.60748 12.184 6.26035Z" fill="#99A3B5" />
    </svg>
  );
};
