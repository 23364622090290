import {
  IsBoolean,
  IsDefined, IsOptional, IsString,
} from 'class-validator';
import { BaseDTM } from 'proto/BaseDTM';

export interface IHsCodeDTM {
  code: string
  description: string
  id?: string
  locationCode?: string
  locationName?: string
  timeZoneId?: string
  forbidden?: boolean
}

export class HsCodeDTM extends BaseDTM<IHsCodeDTM> {
  @IsDefined()
  @IsString()
  code: string

  @IsDefined()
  @IsString()
  description: string

  @IsOptional()
  @IsString()
  id?: string

  @IsOptional()
  @IsString()
  locationCode?: string

  @IsOptional()
  @IsString()
  locationName?: string

  @IsOptional()
  @IsString()
  timeZoneId?: string

  @IsOptional()
  @IsBoolean()
  forbidden?: boolean
}
