import React from 'react';
import { useSelector } from 'react-redux';

import { UC } from 'monetary/controllers';
import { R } from 'monetary/repository';
import { R as userManagementR } from 'user-management/repository';
import { EPermissionAttributePolicy } from 'monetary/constants';

import { RatesUploadComponent } from './RatesUpload.component';

const RatesUploadContainer = () => {
  const userOrgRole = useSelector(userManagementR.selectors.userOrganizationData.getUserOrganisationRole);
  const ratesList = useSelector(R.selectors.rates.getRatesList);
  const getRatesClearCacheAvailabilityPermissions = useSelector(R.selectors.moduleFunctionalityPermissions.getRatesClearCacheAvailabilityPermissions);
  const isLoading = useSelector(R.selectors.rates.getIsLoading);

  return (
    <RatesUploadComponent
      isPending={!userOrgRole}
      userOrgRole={userOrgRole}
      ratesList={ratesList}
      isAllowedClearCache={getRatesClearCacheAvailabilityPermissions === EPermissionAttributePolicy.WRITE}
      isDisableClearBtn={isLoading}
      dataUpload={UC.rates.fetchData}
      onClear={UC.rates.onClear}
      onRatesClear={UC.rates.onRatesClear}
      openOceanRatesDownload={UC.oceanRatesModal.openModal}
      openRatesDownload={UC.ratesModal.openModal}
      openSurchargesRatesDownload={UC.surchargesRatesModal.openModal}
    />
  );
};

export { RatesUploadContainer as RatesUpload };
