import i18n from 'app-wrapper/i18n/i18n';

export enum EPolicyTemplateSize {
  S = 's',
  SM = 'sm',
  M = 'm',
  L = 'l',
}

export const PolicyTemplateSizesNames: Record<EPolicyTemplateSize, string> = {
  [EPolicyTemplateSize.S]: i18n.t('Customer category: S'),
  [EPolicyTemplateSize.SM]: i18n.t('Customer category: S - M'),
  [EPolicyTemplateSize.M]: i18n.t('Customer category: M'),
  [EPolicyTemplateSize.L]: i18n.t('Customer category: L'),
};

export enum EMarkupPolicyStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}
