import { apiWorker } from 'app-wrapper/repository/utilsServices';

export class ShipmentBillingDocumentsService {
  public getBillingDocument = async (
    shipmentId: number,
    documentId: number,
    fileName = 'document',
  ) => {
    try {
      const response = await apiWorker.requestGet<Blob>(`/billing-service/api/v1/shipments/${shipmentId}/documents/${documentId}`, {
        responseType: 'blob',
      });
      const blob = response.data;

      if (!blob) {
        return;
      }

      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement('a');

      anchor.href = url;
      anchor.download = fileName;
      anchor.click();

      window.URL.revokeObjectURL(url);
      anchor.remove();
    } catch (e) {
      throw new Error('ShipmentDocument service error: getShipmentDocument');
    }
  };

  public getPaymentDocument = async (
    accountId: number,
    documentId: number,
    fileName = 'document',
  ) => {
    const response = await apiWorker.requestGet<Blob>(`/billing-service/api/v1/accounts/${accountId}/documents/${documentId}`, {
      responseType: 'blob',
    });
    const blob = response.data;

    if (!blob) {
      return;
    }

    const url = window.URL.createObjectURL(blob);
    const anchor = document.createElement('a');

    anchor.href = url;
    anchor.download = fileName;
    anchor.click();

    window.URL.revokeObjectURL(url);
    anchor.remove();
  };

  public deleteDocument = async (accountId: number, documentId: number) => {
    await apiWorker.requestDelete(`/billing-service/api/v1/accounts/${accountId}/documents/${documentId}`);
  };
}
