import { emptyScrollbar } from 'app-wrapper/view/themes/components/emptyScrollbar';
import styled from 'styled-components';

import { divFlex } from 'app-wrapper/view/themes/mixin';
import { Button } from 'app-wrapper/view/components';

export const CBByIdSpanEllipsis = styled.span.attrs({
  'data-class': 'CBByIdSpanEllipsis',
})`
  max-width: 100%;
  position: relative;
  display: inline;

  text-overflow: ellipsis;
  overflow: clip;
  white-space: nowrap;
`;

export const CBByIdContentTable = styled.div.attrs({
  'data-class': 'CBByIdContentTable',
})`
  ${divFlex}
  flex-direction: column;
  width: 100%;

  padding: 16px;
  background-color: ${({ theme }) => theme.themesColors.neutralBranding1};
`;

export const CBByIdContentTableTitleHeader = styled.div.attrs({
  'data-class': 'CBByIdContentTableTitleHeader',
})`
  ${divFlex}

  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.themesColors.neutralBranding4};
  padding-bottom: 16px;
  margin-bottom: 16px;
`;

export const CBByIdContentTableTitleHeaderFirst = styled.div.attrs({
  'data-class': 'CBByIdContentTableTitleHeaderFirst',
})`
  ${divFlex}
  flex-direction: column;
  justify-content: center;

  width: 33%;
  margin-left: 16px;
`;

export const CBByIdContentTableTitleHeaderAccount = styled.div.attrs({
  'data-class': 'CBByIdContentTableTitleHeaderAccount',
})`
  ${divFlex}
  align-items: center;

  width: 29%;
  margin-left: 16px;
`;

export const CBByIdContentTableTitleHeaderAccountWrap = styled.div.attrs({
  'data-class': 'CBByIdContentTableTitleHeaderAccount',
})`
  ${divFlex}
  flex-direction: column;
  justify-content: center;

  width: 100%;
  margin-left: 16px;
`;

export const CBByIdContentTableTitleHeaderEdit = styled.div.attrs({
  'data-class': 'CBByIdContentTableTitleHeaderEdit',
})`
  ${divFlex}
  flex-direction: column;
  justify-content: center;

  margin-left: auto;
`;

export const CBByIdContentTableTitleHeaderTitle = styled.div.attrs({
  'data-class': 'CBByIdContentTableTitleHeaderTitle',
})`
  ${divFlex}

  width: 100%;
  font-size: 20px;
  font-weight: 500;
  color: ${({ theme }) => theme.themesColors.neutralBranding10};
`;

export const CBByIdContentTableTitleHeaderTaxId = styled.div.attrs({
  'data-class': 'CBByIdContentTableTitleHeaderTaxId',
})`
  ${divFlex}

  width: 100%;
  font-size: 14px;
  font-weight: 400;
  color: ${({ theme }) => theme.themesColors.secondaryDot45};
`;

export const CBByIdContentTableTitleHeaderAccountIcon = styled.div.attrs({
  'data-class': 'CBByIdContentTableTitleHeaderAccountIcon',
})`
  ${divFlex}
  align-items: center;
  justify-content: center;

  width: 37px;
  height: 32px;
  color: ${({ theme }) => theme.themesColors.primaryBranding7};
  border: 1px solid ${({ theme }) => theme.themesColors.primaryBranding1};
  border-radius: 4px;
`;

export const CBByIdContentTableTitleHeaderCompanyIcon = styled.div.attrs({
  'data-class': 'CBByIdContentTableTitleHeaderCompanyIcon',
})`
  ${divFlex}
  align-items: center;
  justify-content: center;

  width: 64px;
  height: 64px;
  background-color: ${({ theme }) => theme.themesColors.primaryBranding1};
  color: ${({ theme }) => theme.themesColors.primaryBranding7};
  border: 1px solid ${({ theme }) => theme.themesColors.neutralBranding1};
  border-radius: 8px;
`;

export const CBByIdContentTableTitleHeaderSpan = styled.div.attrs({
  'data-class': 'CBByIdContentTableTitleHeaderSpan',
})`
  ${divFlex}

  width: 100%;
  font-size: 12px;
  font-weight: 400;
  color: ${({ theme }) => theme.themesColors.secondaryDot45};
`;

export const CBByIdContentTableTitleHeaderSpan14 = styled.div.attrs({
  'data-class': 'CBByIdContentTableTitleHeaderSpan14',
})`
  ${divFlex}

  width: 100%;
  font-size: 14px;
  font-weight: 400;
  color: ${({ theme }) => theme.themesColors.neutralBranding10};
`;

export const CBByIdContentTableHeaderEditCompanyBtn = styled(Button).attrs({
  'data-class': 'CBByIdContentTableHeaderEditCompanyBtn',
})`
  ${divFlex}
  width: 187px;
  height: 32px;

  svg {
    margin-right: 9px;
  }
`;

export const CBByIdContentTableTitleWrap = styled.div.attrs({
  'data-class': 'CBByIdContentTableTitleWrap',
})`
  ${divFlex}

  height: 48px;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.themesColors.neutralBranding3};
  padding-bottom: 16px;
`;

export const CBByIdContentTableTitle = styled.div.attrs({
  'data-class': 'CBByIdContentTableTitle',
})`
  ${divFlex}
  width: 100%;
  align-items: center;

  color: ${({ theme }) => theme.themesColors.neutralBranding10};
  font-size: 16px;
  font-weight: 500;
`;

export const CBByIdContentTableHeader = styled.div.attrs({
  'data-class': 'CBByIdContentTableHeader',
})`
  ${divFlex}
  align-items: center;

  height: 36px;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.themesColors.neutralBranding3};
  padding-top: 8px;
  padding-bottom: 8px;
`;

export const CBByIdContentTableHeaderAddBtn = styled(Button).attrs({
  'data-class': 'CBByIdContentTableHeaderAddBtn',
})`
  ${divFlex}
  width: 140px;
  height: 32px;

  svg {
    color: ${({ theme }) => theme.themesColors.neutralBranding1};
    margin-right: 9px;
  }
  span {
    color: ${({ theme }) => theme.themesColors.neutralBranding1};
  }
`;

export const CBByIdContentTableHeaderItem = styled.div.attrs((props: {
  isCenter?: boolean,
  width?: string,
  paddingLeft?: string,
}) => ({
  isCenter: props.isCenter,
  width: props.width,
  paddingLeft: props.paddingLeft,
  'data-class': 'CBByIdContentTableHeaderItem',
}))`
  ${divFlex}
  align-items: center;
  justify-content: ${({ isCenter }) => (isCenter ? 'center' : 'left')};

  width: ${({ width }) => (width || 'auto')};
  padding-left: ${({ paddingLeft }) => (paddingLeft || '0')};

  color: ${({ theme }) => theme.themesColors.secondaryDot45};
  font-size: 12px;
  font-weight: 400;
`;

export const CBByIdContentTableContentItem = styled.div.attrs({
  'data-class': 'CBByIdContentTableContentItem',
})`
  ${divFlex}
  flex-direction: column;
  width: 100%;

  border-bottom: 1px solid ${({ theme }) => theme.themesColors.neutralBranding3}
`;

export const CBByIdContentTableContentItemTop = styled.div.attrs({
  'data-class': 'CBByIdContentTableContentItemTop',
})`
  ${divFlex}
  ${emptyScrollbar}

  height: 55px;
`;

export const CBByIdContentTableContentTitle = styled.div.attrs((props: {
  isActive?: boolean,
  isCapitalize?: boolean,
}) => ({
  isActive: props.isActive,
  isCapitalize: props.isCapitalize,
  'data-class': 'CBByIdContentTableContentTitle',
}))`
  ${divFlex}
  width: calc(17.8%);
  align-items: center;
  justify-content: left;

  color: ${({ theme }) => theme.themesColors.characterBrandingTitle85};
  color: ${({ theme, isActive }) => (isActive ? theme.themesColors.primaryBranding6 : theme.themesColors.characterBrandingTitle85)};
  font-size: 14px;
  font-weight: 400;

  ${({ isCapitalize }) => isCapitalize && 'text-transform: capitalize'};
`;

export const CBByIdContentTableContentLink = styled.div.attrs((props: {
  width?: string,
}) => ({
  width: props.width,
  'data-class': 'CBByIdContentTableContentLink',
}))`
  ${divFlex}
  width: ${({ width }) => (width || '42.7%')};
  align-items: center;
  justify-content: left;

  color: ${({ theme }) => theme.themesColors.primaryBranding7};
  font-size: 14px;
  font-weight: 400;

  text-overflow: ellipsis;
  overflow: clip;
  white-space: nowrap;
  cursor: pointer;

  > span:first-letter {
    text-transform: uppercase;
  }
`;

export const CBByIdContentTableContentPrimary = styled.div.attrs({
  'data-class': 'CBByIdContentTableContentPrimary',
})`
  ${divFlex}
  width: 58px;
  height: 22px;
  align-items: center;
  justify-content: center;

  background-color: ${({ theme }) => theme.themesColors.polarGreen1};
  color: ${({ theme }) => theme.themesColors.polarGreen7};
  font-size: 12px;
  font-weight: 400;
  border: 1px solid ${({ theme }) => theme.themesColors.polarGreen3};
  margin-left: 8px;
  border-radius: 3px;
`;

export const CBByIdContentTableContactWrap = styled.div.attrs({
  'data-class': 'CBByIdContentTableContactWrap',
})`
  ${divFlex}
  width: 17.8%;
  align-items: center;
  justify-content: center;
`;

export const CBByIdContentTableContact = styled.div.attrs({
  'data-class': 'CBByIdContentTableContact',
})`
  ${divFlex}
  align-items: center;
  justify-content: center;
  min-width: 24px;
  width: 24px;
  height: 24px;

  margin: auto;
  color: ${({ theme }) => theme.themesColors.primaryBranding5};
  background-color: ${({ theme }) => theme.themesColors.primaryBranding1};
  font-size: 12px;
  font-weight: 400;
  border-radius: 100px;
`;
