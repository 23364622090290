import React from 'react';
import { useSelector } from 'react-redux';

import { R } from 'user-management/repository';
import { DocumentType } from 'shipment-operations/constants';

import { OrganizationDocumentUploadComponent } from './OrganizationDocumentUpload.component';

const OrganizationDocumentUploadContainer = () => {
  const type = useSelector(R.selectors.customer.getDocumentToUploadType) as DocumentType;
  const isLoading = useSelector(R.selectors.customer.getIsUploadLoading);

  return (
    <OrganizationDocumentUploadComponent
      documentType={type}
      isLoading={isLoading}
    />
  );
};

export { OrganizationDocumentUploadContainer as OrganizationDocumentUpload };
