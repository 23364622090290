import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { InputStyled } from './SealNumberInput.styled';

interface SealNumberInputComponentComponentProps {
  value: string;
  setValue: (containerId: string, value: string) => void;
  error: boolean;
  containerId: string;
}

export const SealNumberInputComponent: FC<SealNumberInputComponentComponentProps> = ({
  value,
  setValue,
  error,
  containerId,
}) => {
  const { t } = useTranslation();

  const handleChange = useCallback((e) => {
    setValue(containerId, e.target.value);
  }, [setValue, containerId]);

  return (
    <InputStyled
      value={value}
      onChange={handleChange}
      error={error}
      placeholder={t('Enter Seal Number')}
    />
  );
};
