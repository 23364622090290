import React from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';

import { InovicePDFShipmentDetailsComponent } from './InovicePDFShipmentDetails.component';

const InovicePDFShipmentDetailsContainer = () => {
  const shipmentDetails = useSelector(R.selectors.shareInvoice.getShipmentDetailsItems);

  return (
    <InovicePDFShipmentDetailsComponent
      items={shipmentDetails}
    />
  );
};

export { InovicePDFShipmentDetailsContainer as InovicePDFShipmentDetails };
