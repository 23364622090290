import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';
import { UC } from 'shipment-operations/controllers';

import { PackagingGroupSelectComponent } from './PackagingGroupSelect.component';

interface PackagingGroupSelectContainerProps {
  cargoId: number;
}

const PackagingGroupSelectContainer: FC<PackagingGroupSelectContainerProps> = ({ cargoId }) => {
  const { packingGroup, errors } = useSelector(R.selectors.bookingWizard.getCargoById(cargoId));
  const { packingGroup: error } = errors;

  return (
    <PackagingGroupSelectComponent
      packingGroup={packingGroup}
      touchCargoField={UC.bookingWizard.touchCargoField}
      setCargoPackingGroup={UC.bookingWizard.setPackingGroup}
      cargoId={cargoId}
      error={!!error}
    />
  );
};

export { PackagingGroupSelectContainer as PackagingGroupSelect };
