import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'app-wrapper/createSlice';

import { IShipmentState } from 'shipment-operations/models/states';
import { ModuleFunctionalityPermissionsDtm, PaymentTermsDTM, ShipmentPreviewDTM } from 'shipment-operations/models/dtm';
import { ContainerBasicTypes } from 'shipment-operations/constants';

const initialState: IShipmentState = {
  isLoading: false,
  permissions: ModuleFunctionalityPermissionsDtm.createEmpty(),
};

export const shipmentStore = createSlice({
  name: 'shipment',
  initialState,
  reducers: {
    setShipment: (state, action: PayloadAction<ShipmentPreviewDTM>) => ({
      ...state,
      isLoading: false,
      shipment: action.payload,
    }),
    setContainersBasicType: (state, { payload }: PayloadAction<ContainerBasicTypes>) => {
      state.containersBasicType = payload;
    },
    setDestinationTimeZone: (state, { payload }: PayloadAction<string>) => {
      state.destinationTimeZone = payload;
    },
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload;
    },
    setShipmentStatsInterval: (state, { payload }: PayloadAction<ReturnType<typeof setInterval> | undefined>) => {
      state.shipmentStatsInterval = payload;
    },
    setPermissions: (state, { payload }: PayloadAction<ModuleFunctionalityPermissionsDtm>) => {
      state.permissions = payload;
    },
    setContainersOwn: (state, { payload }: PayloadAction<boolean>) => {
      state.isOwnContainers = payload;
    },
    setPaymentTermsDTM: (state, { payload }: PayloadAction<PaymentTermsDTM | undefined>) => {
      state.paymentTerms = payload;
    },
    clear: () => (initialState),
  },
});

export const shipmentActions = shipmentStore.actions;

export const shipmentReducer = shipmentStore.reducer;
