import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'app-wrapper/createSlice';

import { IHomeInitialState } from 'app-wrapper/models/states';

const initialState: IHomeInitialState = {
  isCustomer: false,
  isLoading: false,
};

export const homeStore = createSlice({
  name: 'homeStore',
  initialState,
  reducers: {
    setIsCustomer: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      isCustomer: payload,
    }),
    setIsLoading: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      isLoading: payload,
    }),
  },
});

export const homeReducer = homeStore.reducer;
export const homeActions = homeStore.actions;
