import React from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';
import { UC } from 'shipment-operations/controllers';

import { PaymentDocumentsComponent } from './PaymentDocuments.component';

const PaymentDocumentsContainer = () => {
  const documents = useSelector(R.selectors.makePayment.getPaymentDocuments);
  const accountId = useSelector(R.selectors.makePayment.getAccountIdToAttachDocuments);

  return (
    <PaymentDocumentsComponent
      accountId={accountId}
      documents={documents}
      downloadPaymentDocument={UC.makePayment.downloadPaymentDocument}
    />
  );
};

export { PaymentDocumentsContainer as PaymentDocuments };
