import React from 'react';
import { observer } from 'mobx-react-lite';

import { useMobxStore } from 'app-wrapper/mobxStores';
import { UC } from 'app-wrapper/controllers';

import { ContractsFiltersComponent } from './ContractsFilters.component';

export const ContractsFilters = observer(() => {
  const { contracts } = useMobxStore();

  return (
    <ContractsFiltersComponent
      hasAnyActiveFilters={contracts.hasAnyActiveFilters}
      clearAllFilters={UC.contracts.clearAllFilters}
    />
  );
});
