import styled from 'styled-components';
import ArrowLeftOutlined from '@ant-design/icons/ArrowLeftOutlined';

export const StyledHead = styled.div`
  width: 100%;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1260px;
  margin: 0 auto;
`;

export const LeftContainer = styled.div`
  display: flex;
  align-content: center;
`;

export const StyledBackIcon = styled(ArrowLeftOutlined)`
  font-size: 20px;
  margin-right: 10px;
  margin-top: 5px;
  cursor: pointer;
  color: #202C3C;
`;

export const HeaderTitle = styled.p`
  font-size: 20px;
  font-weight: 500;
  margin: 0;
`;

export const StyledStatus = styled.span`
  position: relative;
  display: flex;
  align-items: center;
  margin-right: 15px;

  &::before {
    content: '';
    position: absolute;
    width: 5px;
    height: 5px;
    background-color: ${({ color }) => color};
    border-radius: 50%;
    right: -15px;
  }
`;
