import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { UC } from 'shipment-operations/controllers';
import { R } from 'shipment-operations/repository';

import { CargoFormMarksInputComponent } from './CargoFormMarksInput.component';

const CargoFormMarksInputContainer: FC = () => {
  const { marks } = useSelector(R.selectors.cargo.getCargo);
  const permissions = useSelector(R.selectors.shipment.getCargosPermissions);

  return (
    <CargoFormMarksInputComponent
      marks={marks}
      touchField={UC.cargo.touchField}
      setMarks={UC.cargo.setMarks}
      cargosAvailability={permissions.cargosAvailability}
    />
  );
};

export {
  CargoFormMarksInputContainer as CargoFormMarksInput,
};
