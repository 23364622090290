import React from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';

import { CommodityComponent } from './Commodity.component';

const CommodityContainer = () => {
  const cargos = useSelector(R.selectors.bookingWizard.getCargos);
  const isHazmatErrorVisible = useSelector(R.selectors.bookingWizard.getIsHazmatErrorVisible);
  const getIsHaveAccountLimit = useSelector(R.selectors.bookingWizard.getIsHaveAccountLimit);
  const getIsShowAccountLimit = useSelector(R.selectors.bookingWizard.getIsShowAccountLimit);
  const hsCodeBEValidationMessage = useSelector(R.selectors.bookingWizard.getHSCodeBEValidationErrorMessage);
  const shouldHaveAnyHazmats = useSelector(R.selectors.bookingWizard.getShouldHaveAnyHazmats);
  const isPending = useSelector(R.selectors.bookingWizard.getIsContentUpdating);

  return (
    <CommodityComponent
      isPending={isPending}
      cargos={cargos}
      isHazmatErrorVisible={isHazmatErrorVisible}
      isHaveLimit={getIsHaveAccountLimit}
      isShowAccountLimit={getIsShowAccountLimit}
      hsCodeBEValidationMessage={hsCodeBEValidationMessage}
      shouldHaveHazmats={shouldHaveAnyHazmats}
    />
  );
};

export { CommodityContainer as Commodity };
