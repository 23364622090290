import styled from 'styled-components';

import { FlexCol } from 'app-wrapper/view/guideline';

export const OverviewLayout = styled.div`
  width: 100%;
`;

export const SkeletonsLayout = styled(FlexCol)`
  gap: 16px;
`;
