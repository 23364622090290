import React, { useMemo } from 'react';

import { observer } from 'mobx-react-lite';
import { useMobxStore } from 'app-wrapper/mobxStores';
import { ShipmentPreviewDTM } from 'shipment-operations/models/dtm';
import { MissingTextValue, TableCell } from 'app-wrapper/view/guideline';
import { Link } from 'react-router-dom';
import { RouteNames } from 'app-wrapper/constants';
import { ShipmentComponent } from 'app-wrapper/view/pages/Overview/components/Dashboard/components/Shipment/Shipment.component';
import { useTranslation } from 'react-i18next';
import { ColumnType } from 'antd/es/table';
import FilterFilled from '@ant-design/icons/FilterFilled';

import { EmptyStateForSection } from 'app-wrapper/view/components';
import themesColors from 'app-wrapper/view/themes/themesColors';

const DepartingShipmentContainer = observer(() => {
  const { overviewDepartingShipmentStore } = useMobxStore();
  const { t } = useTranslation();

  const columns: ColumnType<object>[] = useMemo(() => [
    {
      title: t('Shipment'),
      key: 'id',
      align: 'left' as 'left',
      render: (shipment: ShipmentPreviewDTM) => (
        <TableCell>
          <Link to={RouteNames.SHIPMENT_OVERVIEW(shipment.id)}>{shipment.shipmentName}</Link>

        </TableCell>
      ),
    },
    {
      title: t('Reference'),
      key: 'reference',
      align: 'left' as 'left',
      render: (shipment: ShipmentPreviewDTM) => (
        <TableCell>
          {shipment?.reference || <MissingTextValue />}
        </TableCell>
      ),
    },
    {
      title: t('Carrier'),
      key: 'carrier',
      align: 'left' as 'left',
      render: (shipment: ShipmentPreviewDTM) => (
        <TableCell>
          {shipment.carrier}
        </TableCell>
      ),
    },
    {
      title: t('Origin'),
      key: 'origin',
      align: 'left' as 'left',
      render: (shipment: ShipmentPreviewDTM) => (
        <TableCell>
          {shipment.origin.address}
        </TableCell>
      ),
    },
    {
      title: t('ETD'),
      key: 'ETD',
      align: 'left' as 'left',
      render: (shipment: ShipmentPreviewDTM) => (
        <TableCell>
          {shipment.origin.estimatedDate?.getFormatDMMMHHmm()}
        </TableCell>
      ),
    },
  ], [t, overviewDepartingShipmentStore.initialState.shipments]);

  const redirectLink = `${RouteNames.SHIPMENTS_ACTIVE()}?status=CONFIRMED&status=IN_TRANSIT&sortBy=DEPARTURE`;

  return (
    <ShipmentComponent
      title={t('Departing Next')}
      emptyState={(
        <EmptyStateForSection
          icon={(
            <FilterFilled
              width={28}
              height={22}
              style={{
                color: themesColors.neutralBranding65,
                width: '28px',
                height: '22px',
              }}
            />
          )}
          headerText={t('No Departing Shipments Yet')}
          contentText={t('List of departing shipments here')}
          buttonText={t('Add')}
          isPlus
          onClickButton={() => ''}
        />
      )}
      shipments={overviewDepartingShipmentStore.shipmentsWithKey}
      columns={columns}
      redirectLink={redirectLink}
      isLoading={overviewDepartingShipmentStore.initialState.isLoading}
    />
  );
});

export { DepartingShipmentContainer as DepartingShipment };
