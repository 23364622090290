import React, { FC, useContext } from 'react';
import { useSelector } from 'react-redux';
import { observer } from 'mobx-react-lite';

import { MobxStoresContext } from 'app-wrapper/mobxStores';

import { R } from 'monetary/repository';
import { UC } from 'monetary/controllers';

import { AdditionalServicesComponent } from './AdditionalServices.component';

interface IAdditionalServicesContainerProps {
  formLocalState: { isCollapsed?: boolean };
  forceCollapse?: boolean;
}

const AdditionalServicesContainer: FC<IAdditionalServicesContainerProps> = observer((props) => {
  const {
    formLocalState,
    forceCollapse,
  } = props;
  const isDisabledAdd = useSelector(R.selectors.freightQuote.getAddAdditionalServicesDrawerIsDisabledAdd);
  const {
    isAllFieldDisabled,
  } = useSelector(R.selectors.freightQuote.getFreightQuoteCurrent);

  const { additionalServicesDrawerStore } = useContext(MobxStoresContext);
  const addAdditionalServicesDrawerChecked = additionalServicesDrawerStore?.getAddAdditionalServicesDrawerChecked;

  return (
    <AdditionalServicesComponent
      formLocalState={formLocalState}
      forceCollapse={forceCollapse}
      isDisabledAdd={isDisabledAdd}
      servicesChecked={addAdditionalServicesDrawerChecked}
      isAllFieldDisabled={isAllFieldDisabled}
      openAdditionalServiceDrawer={UC.AdditionalServices.openAdditionalServiceDrawer}
      onChangeChecked={UC.AdditionalServices.onChangeCheckedAdditionalServiceDrawerItemById}
    />
  );
});

export { AdditionalServicesContainer as AdditionalServices };
