import { createSlice } from 'app-wrapper/createSlice';
import moment from 'moment/moment';

import { ICreateInvoiceChargesState } from 'shipment-operations/models/states';

const initialState: ICreateInvoiceChargesState = {
  charges: [],
  error: false,
  isLoading: false,
  selectedCharges: [],
  companies: [],
  isLoadingCompanies: false,
  loadCompaniesError: false,
  selectedCompanyId: null,
  selectedCompanyError: false,
  dueDate: moment(new Date()).add(1, 'days'),
  billedDate: moment(new Date()),
  createInvoiceLoading: false,
  createInvoiceError: false,
  createInvoiceSuccess: false,
  typeOfInvoice: '',
  createdInvoice: null,
  isOpenCreateInvoiceModal: '',
  reference: '',
};

export const createInvoiceChargesSlice = createSlice({
  name: 'createInvoiceCharges',
  initialState,
  reducers: {
    setIsLoading: (state, { payload }) => ({
      ...state,
      isLoading: payload,
    }),
    setCharges: (state, { payload }) => ({
      ...state,
      isLoading: false,
      error: false,
      charges: payload,
    }),
    setError: (state, { payload }) => ({
      ...state,
      isLoading: false,
      error: payload,
    }),
    setSelectedCharge: (state, { payload }) => ({
      ...state,
      selectedCharges: (state.selectedCharges.includes(payload)
        ? state.selectedCharges.filter((item) => item !== payload) : [...state.selectedCharges, payload]),
    }),
    setSubTableCharges: (state, { payload }) => ({
      ...state,
      selectedCharges: payload.every((item: number) => state.selectedCharges.includes(item))
        ? state.selectedCharges.filter((item) => !payload.includes(item))
        : [...state.selectedCharges.filter((item) => !payload.includes(item)), ...payload],
    }),
    clearSelectedCharges: (state) => ({
      ...state,
      selectedCharges: [],
    }),
    onLoadCompaniesSuccess: (state, { payload }) => ({
      ...state,
      isLoadingCompanies: false,
      companies: payload,
      loadCompaniesError: false,
    }),
    setIsLoadingCompanies: (state, { payload }) => ({
      ...state,
      isLoadingCompanies: payload,
    }),
    setSelectedCompanyId: (state, { payload }) => ({
      ...state,
      selectedCompanyId: payload,
    }),
    setDueDate: (state, { payload }) => ({
      ...state,
      dueDate: payload,
    }),
    setBilledDate: (state, { payload }) => ({
      ...state,
      billedDate: payload,
    }),
    setCreateInvoiceLoading: (state, { payload }) => ({
      ...state,
      createInvoiceLoading: payload,
    }),
    setCreateInvoiceError: (state, { payload }) => ({
      ...state,
      createInvoiceError: payload,
    }),
    setCreateInvoiceSuccess: (state, { payload }) => ({
      ...state,
      createInvoiceSuccess: payload.status,
      typeOfInvoice: payload.invoiceType,
      createdInvoice: initialState.createdInvoice,
    }),
    setLoadCompaniesError: (state, { payload }) => ({
      ...state,
      loadCompaniesError: payload,
    }),
    onCreateInvoiceSuccess: (state, { payload }) => ({
      ...state,
      createInvoiceLoading: false,
      createInvoiceSuccess: true,
      createInvoiceError: false,
      typeOfInvoice: payload.type,
      createdInvoice: payload.invoice,
    }),
    onCreateInvoiceFailed: (state) => ({
      ...state,
      createInvoiceLoading: false,
      createInvoiceSuccess: false,
      createInvoiceError: true,
      typeOfInvoice: '',
      createdInvoice: null,
    }),
    setSelectedCompanyError: (state, { payload }) => ({
      ...state,
      selectedCompanyError: payload,
    }),
    onCloseCreateInvoiceModal: (state) => ({
      ...state,
      selectedCharges: [],
      companies: [],
      isLoadingCompanies: false,
      loadCompaniesError: false,
      selectedCompanyId: null,
      selectedCompanyError: false,
      dueDate: moment(new Date()).add(1, 'days'),
      createInvoiceLoading: false,
      createInvoiceError: false,
    }),
    setIsOpenCreateInvoiceModal: (state, { payload }) => ({
      ...state,
      isOpenCreateInvoiceModal: payload,
    }),
    setReference: (state, { payload }) => ({
      ...state,
      reference: payload,
    }),
    clear: () => initialState,
  },
});

export const createInvoiceChargesActions = createInvoiceChargesSlice.actions;
export const createInvoiceChargesReducer = createInvoiceChargesSlice.reducer;
