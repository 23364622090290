import moment from 'moment-timezone';

import { ParsedInvoiceDTM } from 'shipment-operations/models/dtm';
import { GetShipmentEntitiesContact } from 'shipment-operations/models/contracts';
import { apiWorker } from 'app-wrapper/repository/utilsServices';

const timeFormat = 'D MMM YYYY';

export class ShipmentBillingService {
  public getShipmentEntities = async (shipmentId: string, type: string) => {
    let result: ParsedInvoiceDTM[] | null;

    try {
      const response = await apiWorker.requestGet<GetShipmentEntitiesContact[]>(`/billing-service/api/v1/shipments/${shipmentId}/${type}/entities`);

      const parsedResponse = response.data.map((item) => {
        const parsedItem = ParsedInvoiceDTM.fromPlain({
          id: item.id,
          number: item.number,
          type: item.type === 'INVOICE' ? item.type.toLowerCase() : 'credit note',
          status: item.status ? item.status : 'VOID',
          createdAt: moment(item.createdAt).format(timeFormat),
          dueDate: item.dueDate ? moment(item.dueDate).format(timeFormat) : moment(item.createdAt).format(timeFormat),
          total: item.amount || 0,
          balance: item.balance,
        });
        if (!parsedItem.isValid()) {
          console.error('Data from API does not match with contract');
        }
        return parsedItem;
      });
      result = parsedResponse.filter((el) => el !== null) as ParsedInvoiceDTM[];
    } catch (e) {
      throw new Error('Something wrong, please try again');
    }
    return result;
  }
}
