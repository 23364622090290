import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'app-wrapper/createSlice';

import { ValidationErrorDTM } from 'app-wrapper/types';

import { ExportClearanceType } from 'shipment-operations/constants';
import { CustomsClearanceDTM } from 'shipment-operations/models/dtm';
import { IExportClearanceState } from 'shipment-operations/models/states';

const initialState: IExportClearanceState = {
  isLoading: true,
  touchedFields: {},
  errors: {},
  wasUpdateAttempted: false,
  exportClearance: CustomsClearanceDTM.fromPlain({
    isCreated: false,
    type: undefined,
    number: undefined,
  }),
  initialState: CustomsClearanceDTM.fromPlain({
    isCreated: false,
    type: undefined,
    number: undefined,
  }),
};

export const exportClearanceStore = createSlice({
  name: 'exportClearance',
  initialState,
  reducers: {
    reset: () => ({
      ...initialState,
    }),
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setUpdateAttemptStatus: (state, action: PayloadAction<boolean>) => {
      state.wasUpdateAttempted = action.payload;
    },
    touchField: (state, action: PayloadAction<string>) => {
      state.touchedFields[action.payload] = true;
    },
    clearTouchedFields: (state) => {
      state.touchedFields = {};
    },
    setIntitialState: (state) => {
      state.initialState = { ...state.exportClearance };
    },
    discardChanges: (state) => {
      state.exportClearance = { ...state.initialState };
      state.touchedFields = {};
      state.wasUpdateAttempted = false;
      state.errors = {};
    },
    setExportClearance: (state, action: PayloadAction<CustomsClearanceDTM>) => {
      state.exportClearance = action.payload;
    },
    setExportClearanceType: (state, action: PayloadAction<ExportClearanceType>) => {
      state.exportClearance.type = action.payload;
      state.touchedFields.exportClearanceType = true;
    },
    setExportClearanceTypeError: (state, action: PayloadAction<ValidationErrorDTM | undefined>) => {
      state.errors.type = action.payload;
    },
    setExportClearanceNumber: (state, action: PayloadAction<string | undefined>) => {
      state.exportClearance.number = action.payload;
      state.touchedFields.exportClearanceNumber = true;
    },
    setExportClearanceNumberError: (state, action: PayloadAction<ValidationErrorDTM | undefined>) => {
      state.errors.number = action.payload;
    },
  },
});

export const exportClearanceActions = exportClearanceStore.actions;
export const exportClearanceReducer = exportClearanceStore.reducer;
