import styled from 'styled-components';

import themesColors from 'app-wrapper/view/themes/themesColors';

export const ChangePasswordWrapper = styled.div.attrs({
  'data-class': 'ChangePasswordWrapper',
})`
  display: flex;
  flex-flow: column nowrap;
  justify-content: stretch;
  align-items: flex-start;
  height: 100%;
  width: 100%;

  overflow-y: auto;
`;

export const ChangePasswordContainer = styled.div.attrs({
  'data-class': 'ChangePasswordContainer',
})`
  display: flex;
  position: relative;
  flex-flow: column nowrap;
  gap: 10px;
  
  padding: 24px;

  height: 100%;
  width: 100%;
`;

export const ChangePasswordItem = styled.div.attrs({
  'data-class': 'ChangePasswordItem',
})`
  display: flex;
  flex-flow: column nowrap;
  height: 58px;
  width: 100%;
  position: relative;
  font-size: 12px;

  & > span {
    margin-bottom: 4px;
    height: 20px;
    line-height: 20px;
  }
  
  svg {
    margin-left: 4px;
    height: 10px;
    width: 10px;
    color: ${themesColors.secondaryDot45};
    
    &:hover {
      cursor: pointer;
    }
  }
`;

export const ChangePasswordFooter = styled.div.attrs({
  'data-class': 'ChangePasswordFooter',
})`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  gap: 8px;

  margin: auto auto 0 auto;
  padding: 10px 16px;
  height: 52px;
  width: 100%;
`;
