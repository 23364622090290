import {
  IsDefined, IsEnum, IsNumber, IsOptional, IsString, ValidateNested,
} from 'class-validator';
import { Type } from 'class-transformer';
import { BaseDTM } from 'proto/BaseDTM';

import {
  IShortContainerDTM,
  IShipmentDeclarationFileDTM,
  ShipmentDeclarationFileDTM,
} from 'shipment-operations/models/dtm';
import { ContainerReeferTypes, ContainerUsualTypes } from 'shipment-operations/constants';

export interface IShipmentHazmatContainerDTM extends IShortContainerDTM {
  file?: IShipmentDeclarationFileDTM;
}

export class ShipmentHazmatContainerDTM extends BaseDTM<IShipmentHazmatContainerDTM> {
  @IsNumber()
  @IsDefined()
  id: number

  @IsDefined()
  @IsEnum([...Object.values(ContainerReeferTypes), ...Object.values(ContainerUsualTypes)])
  type: ContainerReeferTypes | ContainerUsualTypes;

  @IsString()
  @IsOptional()
  number?: string;

  @ValidateNested()
  @IsDefined()
  @Type(() => ShipmentDeclarationFileDTM)
  file?: ShipmentDeclarationFileDTM
}
