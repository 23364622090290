import styled from 'styled-components';

import { FlexRow } from 'app-wrapper/view/guideline';
import { Button as BaseButton } from 'shipment-operations/view/pages/ShipmentBillOfLading/components/MBLFlowManagement/components/SubmittedContent/SubmittedContent.styled';
import { BaseContent } from 'shipment-operations/view/pages/ShipmentBillOfLading/components/MBLFlowManagement/MBLFlowManagement.styled';

export const Row = styled(FlexRow)`
  gap: 4px;
`;

export const InfoBlock = styled(BaseContent)`
  width: 100%;
  justify-content: space-between;
`;

export const InfoBlockItem = styled.div`
  width: 30%;
`;

export const DownloadBlock = styled(BaseContent)`
  width: fit-content;
`;

export const Button = styled(BaseButton)`
  margin-left: initial;
`;
