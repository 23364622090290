import styled from 'styled-components';

export const Wrap = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  max-width: 1660px;
  margin: 0 auto;
  background-color: ${({ theme }) => theme.themesColors.neutralBranding1};
`;

export const TeamNameWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const TeamName = styled.span`
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
`;

export const Manager = styled.div`
  padding-top: 8px;
  padding-bottom: 20px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const ManagerItem = styled.div`
  width: 33.3%;
  display: flex;
  flex-direction: row;
  align-items: center;

  svg {
    color: ${({ theme }) => theme.themesColors.primaryBranding7};
  }
`;

export const ManagerItemIcon = styled.div`
  margin-right: 16px;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.themesColors.primaryBranding1};
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ManagerItemContent = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ManagerItemContentTitle = styled.span`
  font-size: 12px;
  line-height: 20px;
  color: ${({ theme }) => theme.themesColors.secondaryDot45};
`;

export const ManagerItemContentSubTitle = styled.span`
  font-size: 14px;
  line-height: 22px;
  color: ${({ theme }) => theme.colors.characterBrandingTitle85};
`;
