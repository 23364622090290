import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';

import { VolumeInputComponent } from './VolumeInput.component';

interface VolumeInputContainerProps {
  cargoId: number;
}

const VolumeInputContainer: FC<VolumeInputContainerProps> = ({ cargoId }) => {
  const { volume = '' } = useSelector(R.selectors.manualBookingWizard.getCargoById(cargoId));

  return (
    <VolumeInputComponent volume={volume} />
  );
};

export { VolumeInputContainer as VolumeInput };
