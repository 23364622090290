import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { LocationDataEmptyContentButton, LocationDataEmptyContentWrapper } from './LocationDataEmptyContent.styled';

interface ILocationDataEmptyContentComponentProps {
  onAdd: () => void
  disabled?: boolean
}

export const LocationDataEmptyContent: FC<ILocationDataEmptyContentComponentProps> = ({
  onAdd,
  disabled,
}) => {
  const { t } = useTranslation();

  return (
    <LocationDataEmptyContentWrapper>
      <span>{t('No Data')}</span>
      <LocationDataEmptyContentButton
        disabled={disabled}
        type="dashed"
        onClick={onAdd}
      >
        {t('Add Location Details')}
      </LocationDataEmptyContentButton>
    </LocationDataEmptyContentWrapper>
  );
};
