import {
  ValidateNested,
  IsBoolean,
  IsNumber,
  IsString,
  IsOptional,
  IsEnum,
} from 'class-validator';
import { Type } from 'class-transformer';
import { BaseDTM } from 'proto/BaseDTM';

import { EOceanRateLoadChargeCodeType, EOceanRateLoadType } from '../Quotas';

interface IFreightOceanRatesApplicationLevelDTM {
  id?: number
  type: string
}

export class FreightOceanRatesApplicationLevelDTM extends BaseDTM<IFreightOceanRatesApplicationLevelDTM> {
  @IsOptional()
  @IsNumber()
  id: number;

  @IsOptional()
  @IsString()
  type: string;
}

interface IFreightOceanRatesApplianceRangeDTM {
  minValue?: number
  maxValue?: number
}

export class FreightOceanRatesApplianceRangeDTM extends BaseDTM<IFreightOceanRatesApplianceRangeDTM> {
  @IsOptional()
  @IsNumber()
  minValue: number;

  @IsOptional()
  @IsNumber()
  maxValue: number;
}

interface IFreightOceanRatesValidPeriodDTM {
  from?: string
  to?: string
}

export class FreightOceanRatesValidPeriodDTM extends BaseDTM<IFreightOceanRatesValidPeriodDTM> {
  @IsOptional()
  @IsString()
  from: string;

  @IsOptional()
  @IsString()
  to: string;
}

interface IFreightOceanRatesTransportLegsArrivalLocationDTM {
  code: string
  type: string
}

export class FreightOceanRatesTransportLegsArrivalLocationDTM extends BaseDTM<IFreightOceanRatesTransportLegsArrivalLocationDTM> {
  @IsString()
  code: string;

  @IsString()
  type: string;
}

interface IFreightOceanRatesTransportLegsDTM {
  id: number
  sequence: number
  arrivalLocation: IFreightOceanRatesTransportLegsArrivalLocationDTM
  departureLocation: IFreightOceanRatesTransportLegsArrivalLocationDTM
}

export class FreightOceanRatesTransportLegsDTM extends BaseDTM<IFreightOceanRatesTransportLegsDTM> {
  @IsNumber()
  id: number;

  @IsNumber()
  sequence: number;

  @ValidateNested()
  @Type(() => FreightOceanRatesTransportLegsArrivalLocationDTM)
  arrivalLocation: FreightOceanRatesTransportLegsArrivalLocationDTM;

  @ValidateNested()
  @Type(() => FreightOceanRatesTransportLegsArrivalLocationDTM)
  departureLocation: FreightOceanRatesTransportLegsArrivalLocationDTM;
}

interface IFreightOceanRatesCommodityGroupDTM {
  code: string
  type: string
  fakCommodityGroup: boolean
  generalCommodityGroup: boolean
  includedCommodities: string[]
  excludedCommodities: string[]
}

export class FreightOceanRatesCommodityGroupDTM extends BaseDTM<IFreightOceanRatesCommodityGroupDTM> {
  @IsString()
  code: string;

  @IsString()
  type: string;

  @IsBoolean()
  fakCommodityGroup: boolean;

  @IsBoolean()
  generalCommodityGroup: boolean;

  @IsString({ each: true })
  includedCommodities: string[];

  @IsString({ each: true })
  excludedCommodities: string[];
}

interface IFreightOceanRatesContractDTM {
  id?: number
  number?: string
  name: string
  scac: string
}

export class FreightOceanRatesContractDTM extends BaseDTM<IFreightOceanRatesContractDTM> {
  @IsOptional()
  @IsNumber()
  id: number;

  @IsOptional()
  @IsString()
  number: string;

  @IsString()
  name: string;

  @IsString()
  scac: string;
}

interface IFreightOceanRatesChargeCodeDTM {
  code: string
  description: string
  mode: string
  type: string
  subType?: string
  occurrence: string
  loadType: EOceanRateLoadChargeCodeType
  phases?: string[]
  group?: string
  status?: string
  organizationId?: number
  createdBy?: string
  createdAt?: string
  updatedBy?: string
  updatedAt?: string
}

export class FreightOceanRatesChargeCodeDTM extends BaseDTM<IFreightOceanRatesChargeCodeDTM> {
  @IsString()
  code: string;

  @IsString()
  description: string;

  @IsString()
  mode: string;

  @IsString()
  type: string;

  @IsOptional()
  @IsString()
  subType?: string;

  @IsString()
  occurrence: string;

  @IsEnum(EOceanRateLoadChargeCodeType)
  loadType: EOceanRateLoadChargeCodeType;

  @IsOptional()
  @IsString({ each: true })
  phases?: string[];

  @IsOptional()
  @IsString()
  group?: string;

  @IsOptional()
  @IsString()
  status?: string;

  @IsOptional()
  @IsNumber()
  organizationId?: number;

  @IsOptional()
  @IsString()
  createdBy?: string;

  @IsOptional()
  @IsString()
  createdAt?: string;

  @IsOptional()
  @IsString()
  updatedBy?: string;

  @IsOptional()
  @IsString()
  updatedAt?: string;
}

interface IFreightOceanRatesDTM {
  id?: number
  key?: number
  chargeCode: IFreightOceanRatesChargeCodeDTM
  applicationLevel?: IFreightOceanRatesApplicationLevelDTM
  designation: string
  priceBy: string
  measureBy: string
  applianceRange?: IFreightOceanRatesApplianceRangeDTM
  validPeriod?: IFreightOceanRatesValidPeriodDTM
  subjectTo?: string
  currency: string
  status?: string
  organizationId?: number
  createdBy?: string
  createdAt?: string
  source?: string
  productCode?: string
  unitType?: string
  buyPrice: number
  loadType: EOceanRateLoadType
  bookValidityDate?: string
  transportLegs: IFreightOceanRatesTransportLegsDTM[]
  commodityGroup: IFreightOceanRatesCommodityGroupDTM
  contract?: IFreightOceanRatesContractDTM
}

export class FreightOceanRatesDTM extends BaseDTM<IFreightOceanRatesDTM> {
  @IsOptional()
  @IsNumber()
  id?: number;

  @IsOptional()
  @IsNumber()
  key?: number;

  @ValidateNested()
  @Type(() => FreightOceanRatesChargeCodeDTM)
  chargeCode: FreightOceanRatesChargeCodeDTM;

  @IsOptional()
  @ValidateNested()
  @Type(() => FreightOceanRatesApplicationLevelDTM)
  applicationLevel?: FreightOceanRatesApplicationLevelDTM;

  @IsString()
  designation: string;

  @IsString()
  priceBy: string;

  @IsString()
  measureBy: string;

  @IsOptional()
  @ValidateNested()
  @Type(() => FreightOceanRatesApplianceRangeDTM)
  applianceRange?: FreightOceanRatesApplianceRangeDTM;

  @IsOptional()
  @ValidateNested()
  @Type(() => FreightOceanRatesValidPeriodDTM)
  validPeriod?: FreightOceanRatesValidPeriodDTM;

  @IsOptional()
  @IsString()
  subjectTo?: string;

  @IsString()
  currency: string;

  @IsOptional()
  @IsString()
  status?: string;

  @IsOptional()
  @IsNumber()
  organizationId?: number;

  @IsOptional()
  @IsString()
  createdBy?: string;

  @IsOptional()
  @IsString()
  createdAt?: string;

  @IsOptional()
  @IsString()
  source?: string;

  @IsOptional()
  @IsString()
  productCode?: string;

  @IsOptional()
  @IsString()
  unitType?: string;

  @IsNumber()
  buyPrice: number;

  @IsEnum(EOceanRateLoadType)
  loadType: EOceanRateLoadType;

  @IsOptional()
  @IsString()
  bookValidityDate?: string;

  @ValidateNested({ each: true })
  @Type(() => FreightOceanRatesTransportLegsDTM)
  transportLegs: FreightOceanRatesTransportLegsDTM[];

  @ValidateNested()
  @Type(() => FreightOceanRatesCommodityGroupDTM)
  commodityGroup: FreightOceanRatesCommodityGroupDTM;

  @IsOptional()
  @ValidateNested()
  @Type(() => FreightOceanRatesContractDTM)
  contract: FreightOceanRatesContractDTM;
}
