import React, { useCallback, FC } from 'react';
import Select from 'antd/es/select';
import DatePicker from 'antd/es/date-picker';
import moment from 'moment/moment';
import { useTranslation } from 'react-i18next';

import { InnerContainer, ItemWrapper, SubTitle } from './Header.styled';

const { Option } = Select;
const timeFormat = 'D MMM YYYY';

interface IHeaderComponentProps {
  number?: string
}

const HeaderComponent: FC<IHeaderComponentProps> = ({ number }) => {
  const { t } = useTranslation();
  const checkDate = useCallback((current) => current && current < moment().add(-1, 'days').endOf('day'), []);

  return (
    <InnerContainer>
      <ItemWrapper>
        <SubTitle>{t('invoice')}</SubTitle>
        {number && (
          <Select defaultValue={number} disabled>
            <Option value={number}>{number}</Option>
          </Select>
        )}
      </ItemWrapper>
      <ItemWrapper>
        <SubTitle>{t('dueDateAndTime')}</SubTitle>
        <DatePicker
          defaultValue={moment(new Date())}
          format={timeFormat}
          disabledDate={checkDate}
          allowClear={false}
        />
      </ItemWrapper>
      <ItemWrapper>
        <SubTitle>{t('reason')}</SubTitle>
        <Select defaultValue="Waiver" disabled>
          <Option value="Waiver">Waiver</Option>
        </Select>
      </ItemWrapper>
    </InnerContainer>
  );
};

export { HeaderComponent };
