import React, { FC, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import Skeleton from 'antd/es/skeleton';
import Alert from 'antd/es/alert';

import {
  StyledButton, FieldName, StyledTextArea, Container,
} from 'shipment-operations/view/components/ActiveCharge';

import Divider from 'antd/es/divider';
import { Header, Footer, Cost } from './components';

interface IAccessorialAddChargesComponentProps {
  error: boolean
  isLoading: boolean
  fetchCharges: () => void
  onChangeDescription: (value: string) => void
  onClear: () => void
  fetchCommonData: (shipmentId?: string) => void
  description?: string
}

const AccessorialActiveChargeComponent: FC<IAccessorialAddChargesComponentProps> = ({
  fetchCharges, fetchCommonData, isLoading, error, description, onChangeDescription, onClear,
}) => {
  const { t } = useTranslation();
  const { shipmentId } = useParams<'shipmentId'>();

  useEffect(() => {
    fetchCommonData(shipmentId);
  }, []);

  useEffect(() => () => onClear(), []);

  const handleFetchData = useCallback(() => fetchCharges(), []);

  const handleInputChange = useCallback((e) => onChangeDescription(e.target.value), [onChangeDescription]);

  if (isLoading) {
    return (
      <Container>
        <Skeleton />
      </Container>
    );
  }

  if (error) {
    return (
      <Container>
        <Alert
          message={t('shipmentContainerErrors.COMMON_ERROR_TITLE')}
          description={t('shipmentContainerErrors.COMMON_ERROR_SUBTITLE')}
          type="error"
          action={(
            <StyledButton size="middle" type="primary" onClick={handleFetchData}>
              {t('reload')}
            </StyledButton>
          )}
        />
      </Container>
    );
  }

  return (
    <Container>
      <Header />
      <Divider style={{ margin: '10px 0' }} />
      <Cost />
      <Divider style={{ margin: '8px 0' }} dashed />
      <FieldName>{t('addChargeMenu.Additional Notes')}</FieldName>
      <StyledTextArea
        rows={5}
        placeholder={t('addChargeMenu.Add your comment here')}
        onChange={handleInputChange}
        value={description}
      />
      <Footer />
    </Container>
  );
};

export { AccessorialActiveChargeComponent };
