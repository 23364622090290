import styled from 'styled-components';
import Typography from 'antd/es/typography';

export const Wrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 3px;
  background-color: rgba(255, 255, 255, 1);
  border: 1px solid rgba(234, 238, 248, 1);
`;

export const Rejected = styled.div`
  position: relative;
  border-bottom-left-radius: 3px;
  border-bottom: 1px solid rgba(234, 238, 248, 1);
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  padding-left: 16px;
`;

export const RejectedStub = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 4px;
  height: 60px;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  background-color: rgba(255, 77, 79, 1);
`;

export const RejectedText = styled(Typography)`
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  color: rgba(255, 77, 79, 1);
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
`;

export const Reason = styled.div`
  padding: 16px 24px;
  display: flex;
  flex-direction: column;
  background-color: rgba(255, 241, 240, 1);
  border: 1px solid rgba(255, 204, 199, 1);
  border-radius: 2px;
`;

export const ReasonTitle = styled(Typography)`
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  max-width: 1067px;
`;

export const ReasonText = styled.div`
  margin-top: 6px;
  font-size: 14px;
  line-height: 22px;
  max-width: 1067px;
`;

export const CommentIcon = styled.img`
  margin-right: 6px;
`;

export const AdminCommentWrap = styled.div`
  margin-top: 24px;
  width: 100%;
  max-width: 1067px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

export const AdminComment = styled.div`
  display: flex;
  flex-direction: column;
`;

export const AdminCommentTitle = styled(Typography)`
  margin-bottom: 4px;
  font-size: 14px;
  line-height: 22px;
  font-weight: 500;
`;

export const AdminCommentText = styled(Typography)`
  font-size: 14px;
  line-height: 22px;
  font-style: italic;
`;
