import styled from 'styled-components';
import {
  divFlex,
} from 'app-wrapper/view/themes/mixin';
import { CustomScrollbar } from 'app-wrapper/view/styles/mixins';

// Content

export const AllRequestedQuotesComponentDiv2 = styled.div.attrs({
  'data-class': 'AllRequestedQuotesComponentDiv',
})`
  ${divFlex}
  height: 100%;
  width: 100%;
`;

export const AllRequestedQuotesComponentDiv = styled(AllRequestedQuotesComponentDiv2)`
.ant-tabs-nav-operations {
  display: none;
}
`;

export const AllRequestedQuotesComponentQuotesDivWrapper = styled.div.attrs({
  'data-class': 'AllRequestedQuotesComponentQuotesDivWrapper',
})`
  ${divFlex}
  width: 100%;

  background-color: ${({ theme }) => theme.themesColors.lightGrayBorder};
`;

export const AllRequestedQuotesComponentQuotesDiv = styled.div.attrs({
  'data-class': 'AllRequestedQuotesComponentQuotesDiv',
})`
  ${divFlex}
  width: 100%;
  max-width: 1660px;
  margin: 0 auto;

  flex-direction: column;
  background-color: ${({ theme }) => theme.themesColors.lightGrayBorder};
  padding-left: 24px;
  padding-right: 24px;
`;

export const AllRequestedQuotesComponentHeader = styled.div`
  ${divFlex}
  width: 100%;
  height: ${({ theme }) => theme.freightQuotePage.height.filterHeader};
  border-bottom: 1px solid ${({ theme }) => theme.themesColors.neutralBranding4};
`;

export const AllRequestedQuotesComponentFilterShowOnly = styled.div.attrs({
  'data-class': 'AllRequestedQuotesComponentFilterShowOnly',
})`
  ${divFlex}
  justify-content: center;
  align-items: center;
  height: ${({ theme }) => theme.freightQuotePage.height.filterHeader};
`;

export const AllRequestedQuotesComponentTableHeader = styled.div.attrs({
  'data-class': 'AllRequestedQuotesComponentTableHeader',
})`
  ${divFlex}

  height: 28px;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.themesColors.neutralBranding3};
  padding-bottom: 10px;
  margin-top: 8px;
`;

export const AllRequestedQuotesComponentTableHeaderItem = styled.div.attrs((props: {
  isCenter?: boolean,
  width?: string,
  paddingLeft?: string,
}) => ({
  isCenter: props.isCenter,
  width: props.width,
  paddingLeft: props.paddingLeft,
  'data-class': 'AllRequestedQuotesComponentTableHeaderItem',
}))`
  ${divFlex}
  align-items: center;
  justify-content: ${({ isCenter }) => (isCenter ? 'center' : 'left')};

  width: ${({ width }) => (width || 'auto')};
  padding-left: ${({ paddingLeft }) => (paddingLeft || '0')};

  color: ${({ theme }) => theme.themesColors.secondaryDot45};
  font-size: 12px;
  font-weight: 400;
`;

export const AllRequestedQuotesComponentHeaderFound = styled.div`
  ${divFlex}
  color: ${({ theme }) => theme.themesColors.middleGraySecondary};
  align-items: center;
  font-size: 12px;
  padding-top: 5px;
  margin-left: auto;
`;

export const AllRequestedQuotesComponentQuotesItemsDiv = styled.div.attrs({
  'data-class': 'AllRequestedQuotesComponentQuotesItemsDiv',
})`
  ${divFlex}
  width: 100%;
  height: 100%;
  flex-direction: column;
  .infinite-scroll-component {
    ${CustomScrollbar}
    padding-bottom: 6px;
  }
`;

export const SkeletonShipmentWrap = styled.div.attrs({
  'data-class': 'SkeletonShipmentWrap',
})`
  ${divFlex}
  flex-direction: column;

  gap: 18px;
  margin-bottom: 18px;
`;
