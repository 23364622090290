import React from 'react';
import { useSelector } from 'react-redux';

import { R } from 'user-management/repository';
import { UC } from 'user-management/controllers';

import { UserManagementEditComponent } from './UserManagementEdit.component';

const UserManagementEditContainer = () => {
  const selectedRole = useSelector(R.selectors.userManagement.getSelectedMemberRole);
  const updateLoading = useSelector(R.selectors.userManagement.getUpdateLoading);
  const updateError = useSelector(R.selectors.userManagement.getUpdateError);
  const deleteLoading = useSelector(R.selectors.userManagement.getDeleteLoading);
  const deleteError = useSelector(R.selectors.userManagement.getDeleteError);
  const departments = useSelector(R.selectors.userManagement.getDepartments);
  const departmentsError = useSelector(R.selectors.userManagement.getDepartmentsError);
  const jobTitle = useSelector(R.selectors.userManagement.getJobTitle);
  const availableDepartments = useSelector(R.selectors.userManagement.getAvailableDepertments);
  const permissions = useSelector(R.selectors.moduleFunctionalityPermissions.getUserManagementProfilePermissions);

  return (
    <UserManagementEditComponent
      selectedRole={selectedRole}
      onChangeRole={UC.userManagement.onChangeRole}
      onClose={UC.userManagement.closeEditPanel}
      onEdit={UC.userManagement.onEdit}
      onDelete={UC.userManagement.onDelete}
      updateLoading={updateLoading}
      updateError={updateError}
      onCloseError={UC.userManagement.closeEditError}
      departments={departments}
      availableDepartments={availableDepartments}
      departmentsError={departmentsError}
      jobTitle={jobTitle}
      setJobTitle={UC.userManagement.setJobTitle}
      setDepartments={UC.userManagement.setDepartments}
      deleteLoading={deleteLoading}
      deleteError={deleteError}
      onCloseDeleteError={UC.userManagement.closeDeleteError}
      userDepartmentsAndTeamsEditPermissions={permissions?.userDepartmentsAndTeamsEdit}
    />
  );
};

export { UserManagementEditContainer as UserManagementEdit };
