import PhoneOutlined from '@ant-design/icons/PhoneOutlined';
import MailOutlined from '@ant-design/icons/MailOutlined';
import GlobalOutlined from '@ant-design/icons/GlobalOutlined';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { OrganizationDTM } from 'user-management/models/dtm';
import { NoDataLineItem } from 'user-management/view/components';

import {
  OrganizationAddressData,
  OrganizationAddressHeader,
  OrganizationAddressIconBG,
  OrganizationAddressItem,
  OrganizationAddressRow,
  OrganizationAddressTitle,
  OrganizationAddressValue,
  OrganizationAddressWrapper,
} from './OrganizationContactInformation.styled';

interface IOrganizationAddressComponentProps {
  organization?: OrganizationDTM
}

export const OrganizationContactInformationComponent: FC<IOrganizationAddressComponentProps> = ({
  organization,
}) => {
  const { t } = useTranslation();

  return (
    <OrganizationAddressWrapper>
      <OrganizationAddressHeader>{t('Contact Information')}</OrganizationAddressHeader>
      <OrganizationAddressRow>
        <OrganizationAddressItem>
          <OrganizationAddressIconBG>
            <PhoneOutlined />
          </OrganizationAddressIconBG>
          <OrganizationAddressData>
            <OrganizationAddressTitle>{t('Phone')}</OrganizationAddressTitle>
            <OrganizationAddressValue>{organization?.phone}</OrganizationAddressValue>
          </OrganizationAddressData>
        </OrganizationAddressItem>
        <OrganizationAddressItem>
          <OrganizationAddressIconBG>
            <PhoneOutlined />
          </OrganizationAddressIconBG>
          <OrganizationAddressData>
            <OrganizationAddressTitle>{t('Additional Phone')}</OrganizationAddressTitle>
            <OrganizationAddressValue>{organization?.phone2 ? organization?.phone2 : <NoDataLineItem />}</OrganizationAddressValue>
          </OrganizationAddressData>
        </OrganizationAddressItem>
      </OrganizationAddressRow>
      <OrganizationAddressRow>
        <OrganizationAddressItem>
          <OrganizationAddressIconBG>
            <MailOutlined />
          </OrganizationAddressIconBG>
          <OrganizationAddressData>
            <OrganizationAddressTitle>{t('Email')}</OrganizationAddressTitle>
            <OrganizationAddressValue>{organization?.email || <NoDataLineItem />}</OrganizationAddressValue>
          </OrganizationAddressData>
        </OrganizationAddressItem>
        <OrganizationAddressItem>
          <OrganizationAddressIconBG>
            <MailOutlined />
          </OrganizationAddressIconBG>
          <OrganizationAddressData>
            <OrganizationAddressTitle>{t('Additional Email')}</OrganizationAddressTitle>
            <OrganizationAddressValue><NoDataLineItem /></OrganizationAddressValue>
          </OrganizationAddressData>
        </OrganizationAddressItem>
      </OrganizationAddressRow>
      <OrganizationAddressRow>
        <OrganizationAddressItem>
          <OrganizationAddressIconBG>
            <GlobalOutlined />
          </OrganizationAddressIconBG>
          <OrganizationAddressData>
            <OrganizationAddressTitle>{t('Website')}</OrganizationAddressTitle>
            <OrganizationAddressValue>{organization?.webUrl?.getValidValue() || <NoDataLineItem />}</OrganizationAddressValue>
          </OrganizationAddressData>
        </OrganizationAddressItem>
      </OrganizationAddressRow>
    </OrganizationAddressWrapper>
  );
};
