import i18n from 'app-wrapper/i18n/i18n';

import { TTransportPlanMissMatchRelatesToType, TTransportPlanMissMatchField } from 'shipment-operations/models/dtm/TransportPlanMissMatches.dtm';
import { TBookingMissMatchField, TBookingMissMatchRelatesToType } from 'shipment-operations/models/dtm/BookingMissMatches.dtm';

const t = i18n.t.bind(i18n);

export const TransportPlanMissMatchFieldNames: Record<TTransportPlanMissMatchField, 'string'> = {
  ETA: t('ETA'),
  ETD: t('ETD'),
  VESSEL: t('Vessel'),
  VOYAGE: t('Voyage'),
};
export const TransportPlanMissMatchRelatesToTypeNames: Record<TTransportPlanMissMatchRelatesToType, 'string'> = {
  PORT_DISCHARGE: t('Port of Discharge'),
  PORT_LOADING: t('Port of Loading'),
  PLACE_OF_DELIVERY: t('Place of Delivery'),
  PLACE_OF_RECEIPT: t('Place of Receipt'),
  TRANSIT_PORT: t('Transit Port'),
  TRANSPORT_DETAILS: t('Transport Details'),
};

export const BookingMissMatchFieldNames: Record<TBookingMissMatchField, 'string'> = {
  DESCRIPTION: t('Description'),
  HS_CODE: t('HS Code'),
  UN_NUMBER: t('UN Number'),
  TYPE: t('Type'),
  SOC: t('SOC'),
  TEMPERATURE: t('Temperature'),
  GENSET: t('Genset'),
  IMO_CLASS: t('IMO Class'),
};
export const BookingMissMatchRelatesToTypeNames: Record<TBookingMissMatchRelatesToType, 'string'> = {
  CONTAINER: t('Container'),
  CARGO: t('Cargo'),
};
