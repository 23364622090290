import { createSelector } from 'reselect';
import uniqBy from 'lodash/fp/uniqBy';
import groupBy from 'lodash/fp/groupBy';
import partition from 'lodash/fp/partition';

import { RootState } from 'app-wrapper/store';
import {
  occurrenceAdditional, priceByBol, priceByContainer, typeFee,
} from 'shipment-operations/constants';
import { calculateCreditNoteSum } from 'shipment-operations/view/pages/ShipmentBillingInvoice/utils/calculateSum';
import { ContainerViewDTM, CreditNoteItemDTM } from 'shipment-operations/models/dtm';

const parseContainers = (data: CreditNoteItemDTM[]) => {
  const filtered = data.filter((item) => item.invoiceItem.charge.container);
  if (filtered.length) {
    const prepared = filtered.map((item) => (item.invoiceItem.charge.container ? ({
      number: item.invoiceItem.charge.container.number,
      type: item.invoiceItem.charge.container.type,
      id: item.invoiceItem.charge.container.id,
    }) : null));
    return prepared.length ? prepared.filter((el) => el) as ContainerViewDTM[] : [];
  }
  return [];
};

const localState = (state: RootState) => state.creditNote;

const getIsLoading = createSelector(
  localState,
  (state) => state.isLoading,
);

const getIsError = createSelector(
  localState,
  (state) => state.isError,
);

const getCreditNote = createSelector(
  localState,
  (state) => state.creditNote,
);

const getNumber = createSelector(
  getCreditNote,
  (note) => note?.number,
);

const getDescription = createSelector(
  getCreditNote,
  (note) => note?.description,
);

const getItems = createSelector(
  localState,
  (state) => state.items,
);

const getAdditionalData = createSelector(
  getItems,
  (charges) => partition((item) => item.invoiceItem.charge.additional, charges),
);

const getServicesData = createSelector(
  getAdditionalData,
  ([, data]) => partition((item) => item.invoiceItem.charge.chargeCode.occurrence === occurrenceAdditional, data),
);

const getFeesData = createSelector(
  getServicesData,
  ([, data]) => partition((item) => (item.invoiceItem.charge.chargeCode.type === typeFee || item.invoiceItem.charge.priceBy === priceByBol), data),
);

const getTransportationData = createSelector(
  getFeesData,
  ([, data]) => data,
);

const getServicesContainersData = createSelector(
  getServicesData,
  ([charges]) => charges.filter((item) => item.invoiceItem.charge.priceBy === priceByContainer),
);

const getServicesDataRest = createSelector(
  getServicesData,
  ([charges]) => charges.filter((item) => item.invoiceItem.charge.priceBy !== priceByContainer),
);

const getAdditionalContainersData = createSelector(
  getAdditionalData,
  ([data]) => data.filter((item) => item.invoiceItem.charge.priceBy === priceByContainer),
);

const getAdditionalRestData = createSelector(
  getAdditionalData,
  ([data]) => data.filter((item) => item.invoiceItem.charge.priceBy !== priceByContainer),
);

const getTransportationDataSum = createSelector(
  getTransportationData,
  (data) => calculateCreditNoteSum(data),
);

const getServicesDataSum = createSelector(
  getServicesData,
  ([data]) => calculateCreditNoteSum(data),
);

const getFeesDataSum = createSelector(
  getFeesData,
  ([data]) => calculateCreditNoteSum(data),
);

const getAdditionalDataSum = createSelector(
  getAdditionalData,
  ([data]) => calculateCreditNoteSum(data),
);

const getPreparedServicesContainersData = createSelector(
  getServicesContainersData,
  (data) => parseContainers(data),
);

const getTransportationContainers = createSelector(
  getTransportationData,
  (data) => parseContainers(data),
);

const getAdditionalContainers = createSelector(
  getAdditionalContainersData,
  (data) => parseContainers(data),
);

const uniqTransportationContainers = createSelector(
  getTransportationContainers,
  (containers) => uniqBy('id', containers),
);

const uniqAdditionalContainers = createSelector(
  getAdditionalContainers,
  (containers) => uniqBy('id', containers),
);

const uniqServicesContainers = createSelector(
  getPreparedServicesContainersData,
  (containers) => uniqBy('id', containers),
);

const groupedTransportationContainers = createSelector(
  getTransportationData,
  (data) => groupBy((item) => item.invoiceItem.charge.container?.id, data),
);

const groupedAdditionalContainers = createSelector(
  getAdditionalContainersData,
  (data) => groupBy((item) => item.invoiceItem.charge.container?.id, data),
);

const groupedServicesContainers = createSelector(
  getServicesContainersData,
  (data) => groupBy((item) => item.invoiceItem.charge.container?.id, data),
);

export const creditNoteSelectors = {
  getIsLoading,
  getIsError,
  getNumber,
  getCreditNote,
  getDescription,
  getTransportationData,
  getServicesData,
  getServicesContainersData,
  getServicesDataRest,
  getFeesData,
  getAdditionalData,
  groupedAdditionalContainers,
  groupedServicesContainers,
  groupedTransportationContainers,
  uniqServicesContainers,
  uniqAdditionalContainers,
  uniqTransportationContainers,
  getAdditionalDataSum,
  getFeesDataSum,
  getServicesDataSum,
  getTransportationDataSum,
  getAdditionalRestData,
};
