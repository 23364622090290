import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonProps } from 'antd/es/button';

import { Button, Icon } from './EditButton.styled';

interface EditButtonProps extends ButtonProps {
  iconOnly?: boolean;
}

export const EditButton: FC<EditButtonProps> = ({ iconOnly, ...props }) => {
  const { t } = useTranslation();

  return (
    <Button
      icon={<Icon />}
      type="dashed"
      {...props}
      style={{
        ...(iconOnly ? {
          width: 24,
          height: 24,
        } : {}),
      }}
    >
      {!iconOnly ? t('Edit') : null}
    </Button>
  );
};
