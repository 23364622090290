import React, { FC } from 'react';

import { useSelector } from 'react-redux';
import { R } from 'shipment-operations/repository';
import { PrintHBLComponent } from './PrintHBL.component';

const PrintHBLContainer: FC = () => {
  const isLoading = useSelector(R.selectors.documentHBLPDF.getIsLoading);
  const getHBLDocumentBOLFileNamePrint = useSelector(R.selectors.documentHBLPDF.getHBLDocumentBOLFileNamePrint);

  return (
    <PrintHBLComponent
      isLoading={isLoading}
      fileNamePrint={getHBLDocumentBOLFileNamePrint}
    />
  );
};

export { PrintHBLContainer as PrintHBL };
