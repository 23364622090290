import React, { FC, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import Spin from 'antd/es/spin';

import { EAccountDepartmentType } from 'user-management/constants';
import { OrganizationDTM, OrganizationMemberDTM } from 'user-management/models/dtm';
import {
  Input,
  Select,
  Option,
  Drawer,
} from 'app-wrapper/view/components';

import { TeamDrawerFooter, RoleSelect } from './components';
import {
  Content,
  Fields,
  SpinnerWrap,
  FieldLabel,
  FieldWrap,
  RolesTitle,
} from './CreateOrEditTeamDrawer.styled';

const DRAWER_WIDTH = 400;

interface ICreateOrEditTeamDrawerComponentProps {
  isVisible: boolean;
  onClose: () => void;
  isInEditMode: boolean;
  isLoading: boolean;
  customers: OrganizationDTM[];
  members: OrganizationMemberDTM[];
  rolesToEmailsState: Record<EAccountDepartmentType, string | undefined>;
  setEmailByRole: (role: EAccountDepartmentType, email: string) => void;
  tempCustomers: number[];
  tempTeamName: string;
  onChangeTempCustomers: (tempCustomers: number[]) => void;
  onChangeTempTeamName: (name: string) => void;
}

export const CreateOrEditTeamDrawerComponent: FC<ICreateOrEditTeamDrawerComponentProps> = ({
  isVisible,
  onClose,
  isInEditMode,
  isLoading,
  customers,
  members,
  rolesToEmailsState,
  setEmailByRole,
  tempCustomers,
  onChangeTempCustomers,
  tempTeamName,
  onChangeTempTeamName,
}) => {
  const { t } = useTranslation();

  const title = useMemo(() => (isInEditMode ? t('Edit Team') : t('Create Team')), [isInEditMode]);

  const handleNameChange = useCallback((e) => onChangeTempTeamName(e.target.value), [onChangeTempTeamName]);

  return (
    <Drawer
      visible={isVisible}
      onClose={onClose}
      maskClosable={false}
      width={DRAWER_WIDTH}
      push={false}
      title={title}
    >
      <Content data-class="create-team-drawer">
        {isLoading ? (
          <SpinnerWrap>
            <Spin size="large" />
          </SpinnerWrap>
        ) : (
          <>
            <Fields>
              <FieldWrap>
                <FieldLabel>
                  {t('Name')}
                </FieldLabel>

                <Input
                  data-class="create-team-name"
                  value={tempTeamName}
                  onChange={handleNameChange}
                  placeholder={t('Name')}
                />
              </FieldWrap>

              <FieldWrap>
                <FieldLabel>
                  {t('Customer(s)')}
                </FieldLabel>

                <Select
                  mode="multiple"
                  data-class="customers-select"
                  onChange={onChangeTempCustomers}
                  value={tempCustomers}
                  placeholder={t('Choose customer(s)')}
                  allowClear
                >
                  {customers.map((customer) => (
                    <Option
                      data-class="team-customer-option"
                      key={customer.id}
                      value={customer.id}
                    >
                      {customer.name}
                    </Option>
                  ))}
                </Select>
              </FieldWrap>

              <RolesTitle>
                {t('Roles')}
              </RolesTitle>

              <RoleSelect
                role={EAccountDepartmentType.BOOKING_OPS}
                email={rolesToEmailsState[EAccountDepartmentType.BOOKING_OPS]}
                label={t('Booking Ops *')}
                setRoleEmail={setEmailByRole}
                members={members}
              />

              <RoleSelect
                role={EAccountDepartmentType.DOCUMENTATION_OPS_EXPORT}
                email={rolesToEmailsState[EAccountDepartmentType.DOCUMENTATION_OPS_EXPORT]}
                label={t('Document Ops Export *')}
                setRoleEmail={setEmailByRole}
                members={members}
              />

              <RoleSelect
                role={EAccountDepartmentType.DOCUMENTATION_OPS_IMPORT}
                email={rolesToEmailsState[EAccountDepartmentType.DOCUMENTATION_OPS_IMPORT]}
                label={t('Document Ops Import *')}
                setRoleEmail={setEmailByRole}
                members={members}
              />

              <RoleSelect
                role={EAccountDepartmentType.DRAYAGE_EXPORT}
                email={rolesToEmailsState[EAccountDepartmentType.DRAYAGE_EXPORT]}
                label={t('Drayage Export *')}
                setRoleEmail={setEmailByRole}
                members={members}
              />

              <RoleSelect
                role={EAccountDepartmentType.DRAYAGE_IMPORT}
                email={rolesToEmailsState[EAccountDepartmentType.DRAYAGE_IMPORT]}
                label={t('Drayage Import *')}
                setRoleEmail={setEmailByRole}
                members={members}
              />

              <RoleSelect
                role={EAccountDepartmentType.ACCOUNTING_PAYABLES}
                email={rolesToEmailsState[EAccountDepartmentType.ACCOUNTING_PAYABLES]}
                label={t('Accounting Payables')}
                setRoleEmail={setEmailByRole}
                members={members}
              />

              <RoleSelect
                role={EAccountDepartmentType.ACCOUNTING_RECEIVABLES}
                email={rolesToEmailsState[EAccountDepartmentType.ACCOUNTING_RECEIVABLES]}
                label={t('Accounting Receivables')}
                setRoleEmail={setEmailByRole}
                members={members}
              />
            </Fields>

            <TeamDrawerFooter />
          </>
        )}
      </Content>
    </Drawer>
  );
};
