import styled from 'styled-components';
import { Button } from 'app-wrapper/view/components';

export const Wrapper = styled.div`
  padding: 20px;
  background-color: white;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: flex-end;
  z-index: 1000;
`;

export const RightButton = styled(Button)`
  margin-left: 15px;
`;
