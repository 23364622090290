import React from 'react';
import { observer } from 'mobx-react-lite';

import { useMobxStore } from 'app-wrapper/mobxStores';
import { UC } from 'app-wrapper/controllers';

import { CategoryFilterComponent } from './CategoryFilter.component';

export const CategoryFilter = observer(() => {
  const { contracts } = useMobxStore();

  return (
    <CategoryFilterComponent
      categoryFiltersGroup={contracts.state.categoryFiltersGroup}
      hasSelectedCategoryFilters={!!contracts.chosenCategoryFiltersGroup.length}
      setCategoryFilterByIndex={UC.contracts.setCategoryFilterByIndex}
      resetCategoryFiltersGroup={UC.contracts.resetCategoryFilters}
      applyFilters={UC.contracts.applyFilters}
    />
  );
});
