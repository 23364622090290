import notification from 'antd/es/notification';
import { Moment } from 'moment';
import { BaseController, controller } from 'proto/BaseController';
import { ShipmentRouteDTM } from 'shipment-operations/models/dtm';

import { R } from 'shipment-operations/repository';
import { DateDtm } from 'app-wrapper/models/dtm';
import {
  shipmentTransportationLocationAddressLine1Validate,
  shipmentTransportationLocationCityValidate,
  shipmentTransportationLocationCountryValidate,
  shipmentTransportationLocationPostcodeValidate,
  shipmentTransportationLocationStateValidate,
} from './ShipmentTransportationLocation.validate';

@controller
export class ShipmentTransportationLocationController extends BaseController {
  public firstDataUpload = async (shipmentId?: string) => {
    let routesList: ShipmentRouteDTM[] = [];

    if (!shipmentId) {
      console.error('no shipment id for ShipmentTransportationLocationController.firstDataUpload call');

      return;
    }

    this.dispatch(R.actions.shipmentTransportationLocation.setLoading(true));

    try {
      routesList = await R.services.shipmentRoutes.getList(shipmentId);
    } catch (e) {
      console.error('Shipment containers list request error');

      this.dispatch(R.actions.shipmentTransportationLocation.setLoading(false));

      return;
    }

    const bookingOverview = await R.services.inttra.getBookingOverview(shipmentId);

    if (bookingOverview && bookingOverview.freightuneCutoffs) {
      this.dispatch(R.actions.transportationOverview.setSavedFreightuneCutoffs(bookingOverview.freightuneCutoffs));
    }

    this.dispatch(R.actions.shipmentTransportationLocation.setRoutes(routesList));
    this.dispatch(R.actions.shipmentTransportationLocation.setLoading(false));
  }

  public updateData = async (shipmentId?: string) => {
    const currentData = R.selectors.shipmentTransportationLocationData.getFullData(this.store.getState());
    let updatedData: ShipmentRouteDTM | null = null;

    if (!shipmentId) {
      console.error('No shipment id for ShipmentTransportationLocationController.updateData');

      return;
    }

    if (this.isDataValid()) {
      return;
    }

    if (!currentData) {
      console.error('Empty state data for ShipmentTransportationLocationController.updateData');

      return;
    }

    this.dispatch(R.actions.shipmentTransportationLocationData.setLoading(true));

    try {
      updatedData = currentData.id
        ? await R.services.shipmentRoutes.putRoute(shipmentId, currentData)
        : await R.services.shipmentRoutes.postRoute(shipmentId, currentData);
    } catch (e) {
      console.error(e);

      this.dispatch(R.actions.shipmentTransportationLocationData.setLoading(false));

      return;
    }

    if (!updatedData) {
      console.error('Empty response data for ShipmentTransportationLocationController.updateData');

      this.dispatch(R.actions.shipmentTransportationLocationData.setLoading(false));

      return;
    }

    const routes = R.selectors.shipmentTransportationLocation.getShipmentRoutes(this.store.getState());
    const newRoutes = ([
      ...routes.filter((route) => route.type !== updatedData?.type),
      updatedData,
    ]);
    this.dispatch(R.actions.shipmentTransportationLocation.setRoutes(newRoutes));

    this.dispatch(R.actions.shipmentTransportationLocationData.setFullData(updatedData));
    this.dispatch(R.actions.shipmentTransportationLocationData.setLoading(false));

    this.dispatch(R.actions.shipmentDrawer.closeDrawer());
    this.dispatch(R.actions.shipmentTransportationLocationData.clear());
  }

  public terminalNameChange = (value: string) => {
    this.dispatch(R.actions.shipmentTransportationLocationData.setTerminalName(value));
  }

  public terminalNameBlur = () => {
    this.dispatch(R.actions.shipmentTransportationLocationData.clearTerminalNameError());
  }

  public passCodeChange = (value: string) => {
    this.dispatch(R.actions.shipmentTransportationLocationData.setPassCode(value));
  }

  public passCodeBlur = () => {
    this.dispatch(R.actions.shipmentTransportationLocationData.clearPassCodeError());
  }

  public address1Change = (value: string) => {
    this.dispatch(R.actions.shipmentTransportationLocationData.setAddress1(value));
  }

  public address1Blur = () => {
    this.shipmentTransportationLocationAddressLine1Validate();
  }

  public address2Change = (value: string) => {
    this.dispatch(R.actions.shipmentTransportationLocationData.setAddress2(value));
  }

  public address2Blur = () => {
    this.dispatch(R.actions.shipmentTransportationLocationData.clearAddress2Error());
  }

  public address3Change = (value: string) => {
    this.dispatch(R.actions.shipmentTransportationLocationData.setAddress3(value));
  }

  public address3Blur = () => {
    this.dispatch(R.actions.shipmentTransportationLocationData.clearAddress3Error());
  }

  public address4Change = (value: string) => {
    this.dispatch(R.actions.shipmentTransportationLocationData.setAddress4(value));
  }

  public address4Blur = () => {
    this.dispatch(R.actions.shipmentTransportationLocationData.clearAddress4Error());
  }

  public cityChange = (value: string) => {
    this.dispatch(R.actions.shipmentTransportationLocationData.setCity(value));
  }

  public cityBlur = () => {
    this.shipmentTransportationLocationCityValidate();
  }

  public stateChange = (value: string) => {
    this.dispatch(R.actions.shipmentTransportationLocationData.setState(value));
  }

  public stateBlur = () => {
    this.shipmentTransportationLocationStateValidate();
  }

  public postcodeChange = (value: string) => {
    this.dispatch(R.actions.shipmentTransportationLocationData.setPostcode(value));
  }

  public postcodeBlur = () => {
    this.shipmentTransportationLocationPostcodeValidate();
  }

  public countryChange = (value?: string) => {
    const countriesList = R.selectors.shipmentTransportationLocationData.getCountriesList(this.store.getState());
    const selectedCountry = countriesList.find(({ code }) => code === value);

    this.dispatch(R.actions.shipmentTransportationLocationData.setCountry(selectedCountry));

    this.shipmentTransportationLocationStateValidate();
  }

  public countryBlur = () => {
    this.shipmentTransportationLocationCountryValidate();
  }

  public releaseDateChange = (releaseDate: Moment | null) => {
    if (!releaseDate) {
      this.dispatch(R.actions.shipmentTransportationLocationData.setReleaseDate(undefined));

      return;
    }

    let date = DateDtm.fromPlain({
      date: releaseDate.format(),
      offset: releaseDate.utcOffset(),
    });

    const currentData = R.selectors.shipmentTransportationLocationData.getFullData(this.store.getState());
    if (!currentData?.releaseDate) {
      const destinationTimeZone = R.selectors.shipment.getDestinationTimeZone(this.store.getState());
      const tz = !destinationTimeZone ? '' : destinationTimeZone;

      date = DateDtm.fromPlain({
        date: releaseDate.format(),
        offset: releaseDate.utcOffset(tz).utcOffset(),
      });
    }

    this.dispatch(R.actions.shipmentTransportationLocationData.setReleaseDate(date));
  }

  public releaseDateBlur = () => {

  }

  public clearDataState = () => {
    this.dispatch(R.actions.shipmentTransportationLocation.clear());
  }

  public closeDrawer = () => {
    this.dispatch(R.actions.shipmentTransportationLocationData.clear());
    this.dispatch(R.actions.shipmentDrawer.closeDrawer());
  }

  private isDataValid = () => {
    let isError = false;

    if (this.shipmentTransportationLocationAddressLine1Validate()) {
      isError = true;
    }

    if (this.shipmentTransportationLocationCityValidate()) {
      isError = true;
    }

    if (this.shipmentTransportationLocationCountryValidate()) {
      isError = true;
    }

    if (this.shipmentTransportationLocationPostcodeValidate()) {
      isError = true;
    }

    if (this.shipmentTransportationLocationStateValidate()) {
      isError = true;
    }

    if (this.shipmentTransportationLocationReleaseDateValidate()) {
      isError = true;
    }

    return isError;
  }

  private shipmentTransportationLocationAddressLine1Validate = () => {
    const data = R.selectors.shipmentTransportationLocationData.getAddress1(this.store.getState());

    this.dispatch(R.actions.shipmentTransportationLocationData.clearAddress1Error());

    const shipmentTransportationLocationAddressLine1Error = shipmentTransportationLocationAddressLine1Validate(data);
    if (shipmentTransportationLocationAddressLine1Error) {
      this.dispatch(R.actions.shipmentTransportationLocationData.setAddress1Error(
        shipmentTransportationLocationAddressLine1Error,
      ));
    }

    return shipmentTransportationLocationAddressLine1Error;
  }

  private shipmentTransportationLocationCityValidate = () => {
    const data = R.selectors.shipmentTransportationLocationData.getCity(this.store.getState());

    this.dispatch(R.actions.shipmentTransportationLocationData.clearCityError());

    const shipmentTransportationLocationCityError = shipmentTransportationLocationCityValidate(data);
    if (shipmentTransportationLocationCityError) {
      this.dispatch(R.actions.shipmentTransportationLocationData.setCityError(
        shipmentTransportationLocationCityError,
      ));
    }

    return shipmentTransportationLocationCityError;
  }

  private shipmentTransportationLocationCountryValidate = () => {
    const data = R.selectors.shipmentTransportationLocationData.getCountry(this.store.getState());

    this.dispatch(R.actions.shipmentTransportationLocationData.clearCountryError());

    const shipmentTransportationLocationCountryError = shipmentTransportationLocationCountryValidate(data?.name);
    if (shipmentTransportationLocationCountryError) {
      this.dispatch(R.actions.shipmentTransportationLocationData.setCountryError(
        shipmentTransportationLocationCountryError,
      ));
    }

    return shipmentTransportationLocationCountryError;
  }

  private shipmentTransportationLocationPostcodeValidate = () => {
    const data = R.selectors.shipmentTransportationLocationData.getPostcode(this.store.getState());

    this.dispatch(R.actions.shipmentTransportationLocationData.clearPostcodeError());

    const shipmentTransportationLocationPostcodeError = shipmentTransportationLocationPostcodeValidate(data);
    if (shipmentTransportationLocationPostcodeError) {
      this.dispatch(R.actions.shipmentTransportationLocationData.setPostcodeError(
        shipmentTransportationLocationPostcodeError,
      ));
    }

    return shipmentTransportationLocationPostcodeError;
  }

  private shipmentTransportationLocationStateValidate = () => {
    const country = R.selectors.shipmentTransportationLocationData.getCountry(this.store.getState());
    const state = R.selectors.shipmentTransportationLocationData.getState(this.store.getState());

    this.dispatch(R.actions.shipmentTransportationLocationData.clearStateError());

    if (
      !country || country.code !== 'US'
    ) {
      return null;
    }

    const shipmentTransportationLocationStateError = shipmentTransportationLocationStateValidate(state);
    if (shipmentTransportationLocationStateError) {
      this.dispatch(R.actions.shipmentTransportationLocationData.setStateError(
        shipmentTransportationLocationStateError,
      ));
    }

    return shipmentTransportationLocationStateError;
  }

  private shipmentTransportationLocationReleaseDateValidate = () => {
    const minimalTimeDiff = 2; // hours, between port cutoff time and Earliest CY / CTO time

    this.dispatch(R.actions.shipmentTransportationLocationData.clearReleaseDateError());

    let error: Error | null = null;

    const date = R.selectors.shipmentTransportationLocationData.getReleaseDate(this.store.getState());

    const freightuneCutoffDate = R.selectors.transportationOverview.getSavedFreightuneCutoffDates(this.store.getState());
    if (!freightuneCutoffDate || !freightuneCutoffDate.portCarrierDate) {
      console.error(
        'ShipmentTransportationLocationController.releaseDateChange error: no savedFreightuneCutoffDates.portCarrierDate',
      );

      return error;
    }

    if (
      date && (freightuneCutoffDate.portCarrierDate.getDateAsMomentWithOffset().diff(
        date.getDateAsMomentWithOffset(),
        'hours',
      ) < minimalTimeDiff)
    ) {
      error = new Error(
        `Release date should be before origin cutoff port date (more then ${minimalTimeDiff} hours)`,
      );

      notification.error({
        message: error.message,
        placement: 'bottomRight',
        bottom: 60,
        onClose: () => {},
      });

      return error;
    }

    return error;
  }
}
