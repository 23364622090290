import React, { FC } from 'react';
import { find } from 'lodash/fp';

import { ClausesList } from 'shipment-operations/constants';

import { ClausesWrapper, StyledTableRow, Span } from './Clauses.styled';

export const Clauses: FC<{ clauses: string[] }> = ({ clauses }) => (
  <ClausesWrapper>
    {clauses.map((item) => (
      <StyledTableRow key={item}>
        <Span>{find(({ id }) => item === id, ClausesList)?.name}</Span>
      </StyledTableRow>
    ))}
  </ClausesWrapper>
);
