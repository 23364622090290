import styled from 'styled-components';

import { FlexRow } from 'app-wrapper/view/guideline';

export const Header = styled(FlexRow)`
  gap: 8px;
  padding: 16px;
  justify-content: flex-start;
  border-bottom: 1px solid ${({ theme }) => theme.themesColors.neutralBranding4};
`;

export const Title = styled.span`
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
`;

export const ProgressWrap = styled.div`
  margin-left: auto;
`;
