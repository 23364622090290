import { createSlice } from 'app-wrapper/createSlice';

export interface ShipmentsState {
  activeShipmentId: number;
}

const initialState: ShipmentsState = {
  activeShipmentId: 0,
};

export const shipmentSlice = createSlice({
  name: 'shipments',
  initialState,
  reducers: {
    setShipmentId: (state, { payload }) => ({ ...state, activeShipmentId: payload }),
    clearShipmentId: (state) => ({ ...state, activeShipmentId: 0 }),
  },
});

export const { setShipmentId, clearShipmentId } = shipmentSlice.actions;

export const shipmentSliceReducer = shipmentSlice.reducer;
