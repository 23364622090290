import React, { FC, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';

import { TStepStatus } from 'user-management/constants';

import {
  NewOrganizationWizardWrapperAsideContainer,
  NewOrganizationWizardWrapperAsideStep,
  NewOrganizationWizardWrapperAsideSteps,
  NewOrganizationWizardWrapperAsideTitle,
} from './NewOrganizationWizardWrapperAside.styled';

interface INewOrganizationWizardWrapperAsideComponentProps {
  status: TStepStatus
  percent: number
  isFirsStepDisabled: boolean
  isSecondStepDisabled: boolean
  isThirdStepDisabled: boolean
  activeStepNumber?: number
  onStepClick: (stepNumber: number) => void
  onStepChange: (pathName: string) => void
}

export const NewOrganizationWizardWrapperAsideComponent: FC<INewOrganizationWizardWrapperAsideComponentProps> = ({
  status,
  percent,
  isFirsStepDisabled,
  isSecondStepDisabled,
  isThirdStepDisabled,
  activeStepNumber,
  onStepClick,
  onStepChange,
}) => {
  const { t } = useTranslation();
  const location = useLocation();
  const onStepClickHandler = useCallback((index: number) => {
    onStepClick(index);
  }, [onStepClick]);

  useEffect(() => {
    onStepChange(location.pathname);
  }, [activeStepNumber, location]);

  return (
    <NewOrganizationWizardWrapperAsideContainer>
      <NewOrganizationWizardWrapperAsideTitle>
        {t('Create Organization Steps')}
      </NewOrganizationWizardWrapperAsideTitle>
      <NewOrganizationWizardWrapperAsideSteps
        direction="vertical"
        percent={percent}
        current={activeStepNumber}
        status={status}
      >
        <NewOrganizationWizardWrapperAsideStep
          disabled={isFirsStepDisabled}
          title={t('Add Organization Details')}
          description={t('General Info, Contacts, Business Identifiers')}
          onStepClick={onStepClickHandler}
        />
        <NewOrganizationWizardWrapperAsideStep
          disabled={isSecondStepDisabled}
          title={t('Submit Proof Documents')}
          description={t('Business Registration, Source of Funds and etc.')}
          onStepClick={onStepClickHandler}
        />
        <NewOrganizationWizardWrapperAsideStep
          disabled={isThirdStepDisabled}
          title={t('Review and Confirm')}
          description={t('Terms & Conditions')}
          onStepClick={onStepClickHandler}
        />
      </NewOrganizationWizardWrapperAsideSteps>
    </NewOrganizationWizardWrapperAsideContainer>
  );
};
