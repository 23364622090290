import styled from 'styled-components';

import { CheckboxesTree } from 'app-wrapper/view/components/CheckboxesTree/CheckboxesTree';

export const ErrorWrapper = styled.div`
  display: flex;
  width: 285px;
  justify-content: space-between;
`;

export const StyledCheckboxesTree = styled(CheckboxesTree)`
  text-transform: capitalize;
  margin-top: -20px;
  margin-left: -20px;
  .ant-tree-checkbox-checked .ant-tree-checkbox-inner {
    background-color: #73819B;
    border-color: #73819B;
  }

  .ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner::after {
    background-color: #73819B;
  }
  .ant-tree-checkbox-disabled.ant-tree-checkbox-checked .ant-tree-checkbox-inner::after {
    border-color: white;
  }
`;
