import styled from 'styled-components';

import { colors } from 'app-wrapper/view/themes/themesColors';

export const GensetFieldWrapper = styled.div.attrs({
  'data-class': 'GensetFieldWrapper',
})`
  box-sizing: border-box;
  margin-left: 32px;
  height: 50px;
  
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  
  .ant-checkbox-checked .ant-checkbox-inner {
    border-color: ${colors.buttonPrimaryColor};
    background-color: ${colors.buttonPrimaryColor};
  }
`;

export const GensetLabel = styled.span.attrs({
  'data-class': 'GensetLabel',
})`  
  margin-left: 8px;
`;
