import React, {
  FC, useCallback,
} from 'react';
import { useTranslation } from 'react-i18next';

import { Option } from 'app-wrapper/view/components';
import { PlusSvg } from 'app-wrapper/view/icons';

import { CompanyDTM } from 'shipment-operations/models/dtm';

import {
  SelectStyled, SelectStyledFooter, SelectStyledFooterText,
} from './ShipperSelect.styled';

interface IShippingPartiesCompanyNameComponentProps {
  companiesList: CompanyDTM[]
  company?: CompanyDTM | null
  error?: boolean
  setCompanyName: (companyId: number) => void
  openAddCompanyDrawer: () => void
}

export const ShipperSelectComponent: FC<IShippingPartiesCompanyNameComponentProps> = ({
  companiesList,
  company,
  error,
  setCompanyName,
  openAddCompanyDrawer,
}) => {
  const { t } = useTranslation();

  const dropdownRender = useCallback((menu) => (
    <>
      {menu}
      <SelectStyledFooter onClick={openAddCompanyDrawer}>
        <PlusSvg
          style={{ color: '#6C79DE' }}
          height={12}
          width={12}
        />
        <SelectStyledFooterText>{t('Add Company')}</SelectStyledFooterText>
      </SelectStyledFooter>
    </>
  ), [openAddCompanyDrawer]);

  return (
    <SelectStyled
      showSearch
      placeholder={t('Select Company')}
      optionFilterProp="children"
      value={company?.id || null}
      onChange={setCompanyName}
      error={error}
      dropdownRender={dropdownRender}
    >
      {companiesList.map((_company) => (
        <Option key={_company.id} value={_company.id}>
          {_company.name}
        </Option>
      ))}
    </SelectStyled>
  );
};
