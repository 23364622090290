import React, {
  ComponentClass,
  FC,
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import Typography from 'antd/es/typography';
import debounce from 'lodash/debounce';
import throttle from 'lodash/throttle';
import { RangePickerProps } from 'antd/lib/date-picker';
import moment from 'moment';
import { SizeType } from 'antd/lib/config-provider/SizeContext';

import {
  AlertBlock, Row, Col, Option, TypeSwitch,
} from 'app-wrapper/view/components';
import {
  useWindowSize,
} from 'app-wrapper/hooks';

import { useNotFoundContent } from 'monetary/hooks';
import { FreightFromLocationStateDatePortDTM, FreightFromLocationStateDTM, IFreightSelectFieldDTM } from 'monetary/models/dtm';
import { IDefaultFieldErrors } from 'monetary/models/errors';

import { useFeatureFlagEnabled } from 'posthog-js/react';
import { DRAYAGE_FEATURE_FLAG } from 'app-wrapper/constants/featureFlags';
import {
  AddressBlockRangePicker,
  AddressBlockSelect,
  RowAddress,
  RowAddressNotCollapsed,
  RowAddressBoth,
  RowAddressMsg,
} from './AddressBlock.styled';

interface IRelatedTarget {
  offsetParent?: {
    offsetParent?: {
      className?: string[]
    }
  }
}

export interface IAddressBlockComponentProps {
  isAllDisabled?: boolean
  isCollapsed?: boolean
  onChangeOriginDoorAutocomplete: (value: string) => void
  onChangeDestinationDoorAutocomplete: (value: string) => void
  originDoorAutocompleteStatus: string
  destinationDoorAutocompleteStatus: string
  originDoorAutocompleteOptions: {
    label: string
    value: string
    key: string
  }[]
  destinationDoorAutocompleteOptions: {
    label: string
    value: string
    key: string
  }[]
  onChangeOriginTypeToPort: (isPort: boolean) => void
  onChangeDestinationTypeToPort: (isPort: boolean) => void
  toPortOrigin: boolean
  toPortDestination: boolean
  onChangeOriginAddress: (value: IFreightSelectFieldDTM) => void
  onFocusOriginAddress: () => void
  onBlurOriginAddress: () => void
  onClearOriginAddress: () => void
  onChangeDestinationAddress: (value: IFreightSelectFieldDTM) => void
  onFocusDestinationAddress: () => void
  onBlurDestinationAddress: () => void
  onClearDestinationAddress: () => void
  getRFQPortAutocompleteOrigin: (value: string) => void
  getRFQPortAutocompleteDestination: (value: string) => void
  origin?: FreightFromLocationStateDTM
  destination?: FreightFromLocationStateDTM
  locationPortStatusOrigin: string
  locationPortStatusDestination: string
  onKeyPressOriginAddress: (key: string) => void
  onKeyPressDestinationAddress: (key: string) => void
  onChangePortDateOrigin: (props: FreightFromLocationStateDatePortDTM) => void
  onChangePortDateDestination: (props: FreightFromLocationStateDatePortDTM) => void
  originErrors: {
    isPort?: IDefaultFieldErrors
    location?: IDefaultFieldErrors
    datePort?: {
      error?: IDefaultFieldErrors
    }
    dateDoor?: {
      error?: IDefaultFieldErrors
    }
  }
  destinationErrors: {
    isPort?: IDefaultFieldErrors
    location?: IDefaultFieldErrors
    datePort?: {
      error?: IDefaultFieldErrors
    }
    dateDoor?: {
      error?: IDefaultFieldErrors
    }
  }
  isSubmitVisited?: boolean
  onFocusPortDateOrigin: () => void
  onBlurPortDateOrigin: () => void
  onFocusPortDateDestination: () => void
  onBlurPortDateDestination: () => void
}

const debounceTime = 300;
const debounceTimeGoogle = debounceTime + 600;

export const AddressBlockComponent: FC<IAddressBlockComponentProps> = ((props) => {
  const { isFullMediaWidth } = useWindowSize();
  const { t } = useTranslation();
  const isDrayageEnabled = useFeatureFlagEnabled(DRAYAGE_FEATURE_FLAG);
  const {
    isAllDisabled,
    isCollapsed,
    onChangeOriginDoorAutocomplete,
    onChangeDestinationDoorAutocomplete,
    originDoorAutocompleteOptions,
    destinationDoorAutocompleteOptions,
    originDoorAutocompleteStatus,
    destinationDoorAutocompleteStatus,
    onChangeOriginTypeToPort,
    onChangeDestinationTypeToPort,
    toPortOrigin,
    toPortDestination,
    onChangeOriginAddress,
    onFocusOriginAddress,
    onBlurOriginAddress,
    onClearOriginAddress,
    onChangeDestinationAddress,
    onFocusDestinationAddress,
    onBlurDestinationAddress,
    onClearDestinationAddress,
    getRFQPortAutocompleteOrigin,
    getRFQPortAutocompleteDestination,
    origin,
    originErrors,
    destination,
    destinationErrors,
    locationPortStatusOrigin,
    locationPortStatusDestination,
    onKeyPressOriginAddress,
    onKeyPressDestinationAddress,
    onChangePortDateOrigin,
    onChangePortDateDestination,
    isSubmitVisited,
    onFocusPortDateOrigin,
    onBlurPortDateOrigin,
    onFocusPortDateDestination,
    onBlurPortDateDestination,
  } = props;
  const [isResponsive, setIsResponsive] = useState(isFullMediaWidth);
  useEffect(() => {
    if (isCollapsed) {
      setIsResponsive(false);
    } else {
      // old changed
      setIsResponsive(true);
    }
  }, [isCollapsed]);

  const notFoundContent = useNotFoundContent(locationPortStatusOrigin, !!origin?.locationValues?.length);

  const notFoundContentDestination = useNotFoundContent(locationPortStatusDestination, !!destination?.locationValues?.length);

  const originDateRef = useRef<
    InstanceType<ComponentClass<RangePickerProps>>
  >(null);
  const departureDateRef = useRef<
    InstanceType<ComponentClass<RangePickerProps>>
  >(null);

  const debounceOriginAddress = useMemo(() => debounce((newValue) => {
    getRFQPortAutocompleteOrigin(newValue);
  }, debounceTime), [getRFQPortAutocompleteOrigin]);
  const throttledOriginAddress = useMemo(() => throttle((newValue) => {
    debounceOriginAddress(newValue);
  }, 40), [debounceOriginAddress]);

  const debounceDestinationAddress = useMemo(() => debounce((newValue) => {
    getRFQPortAutocompleteDestination(newValue);
  }, debounceTime), [getRFQPortAutocompleteDestination]);
  const throttledDestinationAddress = useMemo(() => throttle((newValue) => {
    debounceDestinationAddress(newValue);
  }, 40), [debounceDestinationAddress]);

  const disabledDateDeparture = useCallback((current: moment.Moment): boolean => {
    const minDate = origin?.datePort?.earliestDate ? moment(origin?.datePort?.earliestDate) : moment().add(4, 'days');

    if (destination?.datePort?.earliestDate) {
      const minDateSecond = origin?.datePort?.earliestDate
        ? moment(origin?.datePort?.earliestDate)
        : moment(destination?.datePort?.earliestDate).subtract(1, 'day');
      const maxDate = moment(destination?.datePort?.earliestDate).add(34, 'days');

      return current && (current < minDateSecond.endOf('day') || (current > maxDate.endOf('day')));
    }

    return current && current < minDate.endOf('day');
  }, [origin?.datePort?.earliestDate, destination?.datePort?.earliestDate]);
  const disabledDate = useCallback((current: moment.Moment): boolean => {
    if (origin?.datePort?.earliestDate) {
      const minDateSecond = moment(origin?.datePort?.earliestDate);
      const maxDate = moment(origin?.datePort?.earliestDate).add(34, 'days');

      return current && (current < minDateSecond.subtract(1, 'day').endOf('day') || (current > maxDate.endOf('day')));
    }

    return current && (current < moment().add(3, 'days').endOf('day'));
  }, [origin?.datePort?.earliestDate]);

  const originOnCalendarChange = useCallback(
    (dates, dateStrings) => {
      onChangePortDateOrigin(FreightFromLocationStateDatePortDTM.fromPlain({
        earliestDate: dateStrings[0] || '',
        latestDate: dateStrings[1] || '',
      }));
    },
    [onChangePortDateOrigin],
  );

  const departureOnCalendarChange = useCallback(
    (dates, dateStrings) => {
      onChangePortDateDestination(FreightFromLocationStateDatePortDTM.fromPlain({
        earliestDate: dateStrings[0],
        latestDate: dateStrings[1],
      }));
    },
    [onChangePortDateDestination],
  );

  const originTypeToPortOnChangeHandler = useCallback(
    (value) => {
      onChangeOriginTypeToPort(value);
    },
    [],
  );

  const departureTypeToPortOnChangeHandler = useCallback(
    (value) => {
      onChangeDestinationTypeToPort(value);
    },
    [],
  );

  const isErrorDateOrigin = !!originErrors.datePort?.error?.message
      && ((originErrors.datePort?.error?.isVisited
        && destinationErrors.datePort?.error?.isVisited)
        || isSubmitVisited);

  const isErrorDateDestination = useMemo(
    () => !!destinationErrors.datePort?.error?.message
      && ((originErrors.datePort?.error?.isVisited
        && destinationErrors.datePort?.error?.isVisited)
        || isSubmitVisited),
    [originErrors.datePort?.error, destinationErrors.datePort?.error, isSubmitVisited],
  );

  const onBlurPortDateOriginHandler = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      const relatedTarget = event?.relatedTarget as IRelatedTarget;

      if (relatedTarget === null
        || !relatedTarget?.offsetParent?.offsetParent?.className?.includes('AddressBlockRangePickerOrigin')) {
        onBlurPortDateOrigin();
      }
    },
    [onBlurPortDateOrigin],
  );

  const sizeSelect: SizeType = useMemo(() => (isFullMediaWidth ? 'large' : 'middle'), [isFullMediaWidth]);

  const firsDateComponent = useMemo(() => (
    <AddressBlockRangePicker
      className="AddressBlockRangePickerOrigin"
      isError={isErrorDateOrigin}
      value={[origin?.datePort?.earliestDate ? moment(origin?.datePort?.earliestDate) : null, origin?.datePort?.latestDate ? moment(origin?.datePort?.latestDate) : null]}
      placeholder={[`${t('Earliest date')} *`, `${t('Latest date')}`]}
      disabledDate={disabledDate}
      onFocus={onFocusPortDateOrigin}
      onBlur={onBlurPortDateOriginHandler}
      ref={originDateRef}
      size={sizeSelect}
      onCalendarChange={originOnCalendarChange}
      disabled={isAllDisabled}
      getPopupContainer={(triggerNode) => triggerNode.parentElement || document.body}
    />
  ), [
    origin?.datePort?.earliestDate,
    origin?.datePort?.latestDate,
    isAllDisabled,
    isErrorDateOrigin,
    disabledDate, originOnCalendarChange, t]);

  const onBlurPortDateDestinationHandler = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      const relatedTarget = event?.relatedTarget as IRelatedTarget;

      if (relatedTarget === null
        || !relatedTarget?.offsetParent?.offsetParent?.className?.includes('AddressBlockRangePickerDestination')) {
        onBlurPortDateDestination();
      }
    },
    [onBlurPortDateDestination],
  );

  const destinationDateComponent = useMemo(() => (
    <AddressBlockRangePicker
      className="AddressBlockRangePickerDestination"
      isError={isErrorDateDestination}
      value={[destination?.datePort?.earliestDate ? moment(destination?.datePort?.earliestDate) : null, destination?.datePort?.latestDate ? moment(destination?.datePort?.latestDate) : null]}
      placeholder={[`${t('Earliest date')} *`, `${t('Latest date')}`]}
      disabledDate={disabledDateDeparture}
      size={sizeSelect}
      ref={departureDateRef}
      onFocus={onFocusPortDateDestination}
      onBlur={onBlurPortDateDestinationHandler}
      onCalendarChange={departureOnCalendarChange}
      disabled={isAllDisabled}
      getPopupContainer={(triggerNode) => triggerNode.parentElement || document.body}
    />
  ), [
    destination?.datePort?.earliestDate,
    destination?.datePort?.latestDate,
    isAllDisabled,
    isErrorDateDestination,
    disabledDateDeparture, departureOnCalendarChange, t]);

  const debounceOriginAddressDoor = useMemo(() => debounce((newValue) => {
    onChangeOriginDoorAutocomplete(newValue);
  }, debounceTimeGoogle), [onChangeOriginDoorAutocomplete]);
  const throttledOriginAddressDoor = useMemo(() => throttle((newValue) => {
    debounceOriginAddressDoor(newValue);
  }, 40), [debounceOriginAddressDoor]);

  const debounceDestinationAddressDoor = useMemo(() => debounce((newValue) => {
    onChangeDestinationDoorAutocomplete(newValue);
  }, debounceTimeGoogle), [onChangeDestinationDoorAutocomplete]);
  const throttledDestinationAddressDoor = useMemo(() => throttle((newValue) => {
    debounceDestinationAddressDoor(newValue);
  }, 40), []);

  const notFoundOriginDoorContent = useNotFoundContent(originDoorAutocompleteStatus, !!originDoorAutocompleteOptions.length);

  const notFoundDestinationDoorContent = useNotFoundContent(destinationDoorAutocompleteStatus, !!destinationDoorAutocompleteOptions.length);

  const onClearOriginAddressHandler = useCallback(
    () => {
      onClearOriginAddress();
    },
    [onClearOriginAddress],
  );

  const onClearDestinationAddressHandler = useCallback(
    () => {
      onClearDestinationAddress();
    },
    [onClearDestinationAddress],
  );

  const onChangeOriginAddressHandler = useCallback(
    (value: string, option: unknown) => {
      if (value) {
        onChangeOriginAddress({
          code: value,
          description: (option as { label: string }).label,
          timezoneId: (option as { timezoneId: string })?.timezoneId,
        });
      }
    },
    [onChangeOriginAddress],
  );

  const onChangeOriginDoorAddressHandler = useCallback(
    (value) => {
      if (value) {
        onChangeOriginAddress({
          code: value,
          description: '',
        });
      }
    },
    [onChangeOriginAddress],
  );

  const onChangeDestinationDoorAddressHandler = useCallback(
    (value) => {
      if (value) {
        onChangeDestinationAddress({
          code: value,
          description: '',
        });
      }
    },
    [onChangeDestinationAddress],
  );

  const onChangeDestinationAddressHandler = useCallback(
    (value: string, option: unknown) => {
      if (value) {
        onChangeDestinationAddress({
          code: value,
          description: (option as { label: string }).label,
          timezoneId: (option as { timezoneId: string }).timezoneId,
        });
      }
    },
    [onChangeDestinationAddress],
  );

  const onSearchOriginPortAddressHandler = useCallback(
    (value) => {
      throttledOriginAddress(value?.trim());
    },
    [throttledOriginAddress],
  );

  const onSearchDestinationPortAddressHandler = useCallback(
    (value) => {
      throttledDestinationAddress(value?.trim());
    },
    [throttledDestinationAddress],
  );

  const onKeyPressOriginPortAddressHandler = useCallback((key: React.KeyboardEvent<HTMLDivElement>) => {
    onKeyPressOriginAddress(key.key);
  }, [onKeyPressOriginAddress]);

  const onKeyPressDestinationPortAddressHandler = useCallback((key: React.KeyboardEvent<HTMLDivElement>) => {
    onKeyPressDestinationAddress(key.key);
  }, [onKeyPressDestinationAddress]);

  const isErrorOriginLocation = useMemo(() => !!originErrors.location?.message
    && (originErrors.location?.isVisited || isSubmitVisited), [originErrors.location?.message, originErrors.location?.isVisited, isSubmitVisited]);

  const isErrorDestinationLocation = useMemo(() => !!destinationErrors.location?.message
    && (destinationErrors.location?.isVisited || isSubmitVisited), [destinationErrors.location?.message, destinationErrors.location?.isVisited, isSubmitVisited]);

  const isErrorDateMessageBox = useMemo(
    () => (!!destinationErrors.datePort?.error?.message
      && (destinationErrors.datePort?.error?.isVisited)
      && (originErrors.datePort?.error?.message)
      && (originErrors.datePort?.error?.isVisited))
      || (isSubmitVisited && destinationErrors.datePort?.error?.message && originErrors.datePort?.error?.message),
    [destinationErrors.datePort?.error, originErrors.datePort?.error, isSubmitVisited],
  );

  const MainRow = useMemo(() => (isCollapsed ? RowAddressNotCollapsed : RowAddress), [isCollapsed]);

  return (
    <MainRow className="RFRFormRequest__Row__col_Address">
      <RowAddressBoth className="RFRFormRequest__Row__col_both_col">
        <Col span={isResponsive ? 12 : 24} className="RFRFormRequest__Row__col_left">
          <Row justify="space-between" className="RFRFormRequest__Row__col_both_col__title">
            <div>
              <Typography.Title level={5}>{t('Origin')}</Typography.Title>
            </div>
            {isDrayageEnabled ? (
              <TypeSwitch
                data-class="originTypeToPort"
                leftText={t('Door')}
                rightText={t('Port')}
                onChange={originTypeToPortOnChangeHandler}
                disabled={isAllDisabled}
                value={!!origin?.isPort}
              />
            ) : null}
          </Row>

          {toPortOrigin
            ? (
              <>
                <Row className="RFRFormRequest__Row__col RFRFormRequest__Row__col_first">
                  <Col span={24}>
                    <AddressBlockSelect
                      isError={isErrorOriginLocation}
                      data-class="originAddressCode"
                      value={origin?.location?.code || null}
                      showSearch
                      allowClear={!!origin?.location?.code}
                      autoClearSearchValue={false}
                      disabled={isAllDisabled}
                      onSearch={onSearchOriginPortAddressHandler}
                      onClear={onClearOriginAddressHandler}
                      options={
                        origin?.locationValues?.map((item) => ({
                          label: item.description || '',
                          value: item.code || '',
                        }))
                      }
                      placeholder={`${t('unlocoPlaceholder')} *`}
                      notFoundContent={notFoundContent}
                      showArrow={false}
                      filterOption={false}
                      onChange={onChangeOriginAddressHandler}
                      onFocus={onFocusOriginAddress}
                      onBlur={onBlurOriginAddress}
                      onKeyDown={onKeyPressOriginPortAddressHandler}
                    >
                      {origin?.locationValues?.map(({ code, description, timezoneId }, index) => (
                        <Option key={`origin_locationValues_${index + 1}`} value={code || ''} timezoneId={timezoneId}>{description}</Option>
                      ))}
                    </AddressBlockSelect>
                  </Col>
                </Row>

                <Row className={`${isFullMediaWidth ? 'contentFullWidth' : ''} RFRFormRequest__Row__col RFRFormRequest__Row__col_second`}>
                  <Col span={24}>
                    {firsDateComponent}
                  </Col>
                </Row>
              </>
            )
            : (
              <>
                <Row className="RFRFormRequest__Row__col RFRFormRequest__Row__col_first">
                  <Col span={24}>
                    <AddressBlockSelect
                      value={origin?.location?.code || null}
                      data-class="originAddressCode"
                      isError={isErrorOriginLocation}
                      showSearch
                      allowClear={!!origin?.location?.code}
                      autoClearSearchValue={false}
                      disabled={isAllDisabled}
                      onFocus={onFocusOriginAddress}
                      onBlur={onBlurOriginAddress}
                      onSearch={throttledOriginAddressDoor}
                      onClear={onClearOriginAddressHandler}
                      options={originDoorAutocompleteOptions}
                      placeholder={`${t('OverviewSecondSectionsFormAddress')} *`}
                      notFoundContent={notFoundOriginDoorContent}
                      showArrow={false}
                      filterOption={false}
                      onChange={onChangeOriginDoorAddressHandler}
                      onKeyDown={onKeyPressOriginPortAddressHandler}
                    >
                      {origin?.locationValues?.map(({ code, description }, index) => (
                        <Option key={`origin_locationValues_${index + 1}`} value={code || ''}>{description}</Option>
                      ))}
                    </AddressBlockSelect>
                  </Col>
                </Row>

                <Row className={`${isFullMediaWidth ? 'contentFullWidth' : ''} RFRFormRequest__Row__col RFRFormRequest__Row__col_second`}>
                  <Col span={24}>
                    {firsDateComponent}
                  </Col>
                </Row>
              </>
            )}
        </Col>

        <Col span={isResponsive ? 12 : 24} className="RFRFormRequest__Row__col_right">
          <Row justify="space-between" className="RFRFormRequest__Row__col_right__title">
            <div>
              <Typography.Title level={5}>{t('Destination')}</Typography.Title>
            </div>
            <div>
              {isDrayageEnabled ? (
                <TypeSwitch
                  data-class="departureTypeToPort"
                  value={!!destination?.isPort}
                  leftText={t('Door')}
                  rightText={t('Port')}
                  disabled={isAllDisabled}
                  onChange={departureTypeToPortOnChangeHandler}
                />
              ) : null}
            </div>
          </Row>

          {toPortDestination
            ? (
              <>
                <Row className="RFRFormRequest__Row__col_first">
                  <Col span={24}>
                    <AddressBlockSelect
                      value={destination?.location?.code || null}
                      data-class="destinationAddressCode"
                      isError={isErrorDestinationLocation}
                      showSearch
                      allowClear={!!destination?.location?.code}
                      autoClearSearchValue={false}
                      disabled={isAllDisabled}
                      onClear={onClearDestinationAddressHandler}
                      onFocus={onFocusDestinationAddress}
                      onBlur={onBlurDestinationAddress}
                      onSearch={onSearchDestinationPortAddressHandler}
                      options={
                        destination?.locationValues?.map((item) => ({
                          label: item.description || '',
                          value: item.code || '',
                        }))
                      }
                      placeholder={`${t('unlocoPlaceholder')} *`}
                      notFoundContent={notFoundContentDestination}
                      showArrow={false}
                      filterOption={false}
                      onChange={onChangeDestinationAddressHandler}
                      onKeyDown={onKeyPressDestinationPortAddressHandler}
                    >
                      {destination?.locationValues?.map(({ code, description, timezoneId }, index) => (
                        <Option key={`destination_locationValues_${index + 1}`} value={code || ''} timezoneId={timezoneId}>{description}</Option>
                      ))}
                    </AddressBlockSelect>
                  </Col>
                </Row>

                <Row className={`${(isFullMediaWidth && 'contentFullWidth') || ''} RFRFormRequest__Row RFRFormRequest__Row__col_second`}>
                  <Col span={24}>
                    {destinationDateComponent}
                  </Col>
                </Row>
              </>
            )
            : (
              <>
                <Row className="RFRFormRequest__Row__col RFRFormRequest__Row__col_first">
                  <Col span={24}>
                    <AddressBlockSelect
                      value={destination?.location?.code || null}
                      data-class="destinationAddressCode"
                      isError={isErrorDestinationLocation}
                      showSearch
                      allowClear={!!destination?.location?.code}
                      autoClearSearchValue={false}
                      disabled={isAllDisabled}
                      onClear={onClearDestinationAddressHandler}
                      onFocus={onFocusDestinationAddress}
                      onBlur={onBlurDestinationAddress}
                      onSearch={throttledDestinationAddressDoor}
                      options={destinationDoorAutocompleteOptions}
                      placeholder={`${t('OverviewSecondSectionsFormAddress')} *`}
                      notFoundContent={notFoundDestinationDoorContent}
                      showArrow={false}
                      filterOption={false}
                      onChange={onChangeDestinationDoorAddressHandler}
                      onKeyDown={onKeyPressDestinationPortAddressHandler}
                    >
                      {destination?.locationValues?.map(({ code, description }, index) => (
                        <Option key={`destination_locationValues_${index + 1}`} value={code || ''}>{description}</Option>
                      ))}
                    </AddressBlockSelect>
                  </Col>
                </Row>

                <Row className={`${(isFullMediaWidth && 'contentFullWidth') || ''} RFRFormRequest__Row RFRFormRequest__Row__col_second`}>
                  <Col span={24}>
                    {destinationDateComponent}
                  </Col>
                </Row>
              </>
            )}
        </Col>
      </RowAddressBoth>
      {
        isErrorDateMessageBox && (
          <RowAddressMsg className="RFRFormRequest__Row__msg">
            <Col span={24}><AlertBlock type="warning" message={t('selectOrigin')} /></Col>
          </RowAddressMsg>
        )
      }
    </MainRow>
  );
});
