import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { SelectProps } from 'antd/es/select';
import { SelectWithError } from 'app-wrapper/view/components';

interface IEditProfileOrganizationAddressGeneralInformationComponentProps {
  value?: (string | undefined)[] | string
  options: SelectProps<string>['options']
  hasError?: boolean
  onChange: (name: string[]) => void
  onBlur: () => void
  onClear: () => void
  disabled: boolean
  dataClass?: string
}

export const EditProfileOrganizationCoverageAreaComponent: FC<IEditProfileOrganizationAddressGeneralInformationComponentProps> = ({
  value,
  options,
  hasError,
  onChange,
  onBlur,
  onClear,
  disabled,
  dataClass,
}) => {
  const { t } = useTranslation();

  const handleOnChange = useCallback((values: string[]) => {
    onChange(values);
  }, []);

  const handleOnBlur = useCallback(() => {
    onBlur();
  }, []);

  const handleOnClear = useCallback(() => {
    onClear();
  }, []);

  return (
    <SelectWithError
      data-class={dataClass}
      mode="multiple"
      value={value}
      showSearch
      placeholder={t('Please select')}
      onChange={handleOnChange}
      onBlur={handleOnBlur}
      onClear={handleOnClear}
      optionFilterProp="children"
      disabled={disabled}
      options={options}
      hasError={hasError}
    />
  );
};
