import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';

import { R as userManagementR } from 'user-management/repository';
import { EOrganizationMemberRole } from 'user-management/constants';

import { MBLFlowManagementComponent } from './MBLFlowManagement.component';

export const MBLFlowManagement = React.memo(() => {
  const {
    status,
    mblFinalId,
    finalMblDocument,
  } = useSelector(R.selectors.billOfLadingCommon.getSIDetails);
  const isDraftMBLApproved = useSelector(R.selectors.billOfLadingCommon.getIsDraftMBLApproved);
  const isThereAreAnyMissMatches = useSelector(R.selectors.shipment.getIsThereAreMissMatches);
  const { mblNumber } = useSelector(R.selectors.billOfLadingCommon.getBookingInformation);
  const organization = useSelector(userManagementR.selectors.userOrganizationData.getUserOrganization);
  const isOriginPartner = useSelector(R.selectors.shipment.getIsOriginPartner);
  const isDestinationPartner = useSelector(R.selectors.shipment.getIsDestinationPartner);
  const docCutoff = useSelector(R.selectors.billOfLadingCommon.getLowestSequenceTransportationDocCuttoff);
  const isCustomerOrg = useMemo(() => !!(organization && organization.role === EOrganizationMemberRole.CUSTOMER), [organization]);

  return (
    <MBLFlowManagementComponent
      siStatus={status}
      isDraftMBLApproved={isDraftMBLApproved}
      hasFinalMBL={!!mblFinalId}
      hasFinalMBLDocument={!!finalMblDocument}
      isThereAreAnyMissMatches={isThereAreAnyMissMatches}
      mblNumber={mblNumber}
      isOriginPartner={isOriginPartner}
      isDestinationOrg={isDestinationPartner}
      isCustomerOrg={isCustomerOrg}
      documentCutoff={docCutoff}
    />
  );
});
