import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { Button } from 'app-wrapper/view/components';

import { Wrapper } from 'shipment-operations/view/pages/CreateInvoice/components/StickyTotal/StickyTotal.styled';

interface ITotalProps {
  onCreateInvoice: (shipmentId?: string) => void
  isLoading: boolean
}

const StickyTotalComponent: FC<ITotalProps> = ({
  onCreateInvoice, isLoading,
}) => {
  const { t } = useTranslation();
  const { shipmentId } = useParams<'shipmentId'>();

  const handleCreateInvoice = useCallback(() => {
    onCreateInvoice(shipmentId);
  }, [shipmentId]);

  return (
    <Wrapper>
      <Button
        size="large"
        onClick={handleCreateInvoice}
        loading={isLoading}
      >{t('createInvoice')}
      </Button>
    </Wrapper>
  );
};

export { StickyTotalComponent };
