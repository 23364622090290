import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { R } from 'shipment-operations/repository';
import { UC } from 'shipment-operations/controllers';

import { FooterComponent } from './Footer.component';

const FooterContainer = () => {
  const isLoading = useSelector(R.selectors.manualBookingWizard.getIsLoading);
  const file = useSelector(R.selectors.manualBookingWizard.getConfirmationDocument);
  const { shipmentId = '0' } = useParams();

  return (
    <FooterComponent
      document={file}
      shipmentId={shipmentId}
      isLoading={isLoading}
      onSubmitManualBooking={UC.manualBookingWizard.submitBookingConfirmation}
    />
  );
};

export { FooterContainer as Footer };
