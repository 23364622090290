import React, { FC } from 'react';

import { NoCompanyAttached } from 'shipment-operations/view/pages/ShipmentShippingParties/components';

import {
  ShippingPartiesFormFooter,
  ShippingPartiesFormTitle,
  CompanySection,
  AddressSection,
} from './components';

import {
  Divider,
  FormContainer,
} from './ShippingPartiesForm.styled';

interface IShippingPartiesFormComponentProps {
  isLoading: boolean;
  hasAttachedCompany: boolean;
}

export const ShippingPartiesFormComponent: FC<IShippingPartiesFormComponentProps> = ({
  isLoading,
  hasAttachedCompany,
}) => (
  <FormContainer
    isLoading={isLoading}
    footer={<ShippingPartiesFormFooter />}
  >
    {hasAttachedCompany ? (
      <>
        <ShippingPartiesFormTitle />
        <Divider dashed />

        <CompanySection />
        <AddressSection />
      </>
    ) : <NoCompanyAttached />}
  </FormContainer>
);
