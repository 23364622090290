import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';

import { FooterComponent } from './Footer.component';

interface IFooterProps {
  sum: number
}

const Footer: FC<IFooterProps> = ({ sum }) => {
  const createInvoiceModalOwner = useSelector(R.selectors.createInvoiceCharges.getIsOpenCreateInvoiceModal);
  const relatedAdmin = useSelector(R.selectors.shipmentCharges.getRelatedAdmin);

  return (
    <FooterComponent
      sum={sum}
      modalOwner={createInvoiceModalOwner}
      isAdminFromUS={relatedAdmin?.getIsAdminFromUS() || false}
    />
  );
};

export { Footer };
