import styled from 'styled-components';

import { FlexCol } from 'app-wrapper/view/guideline';

export const Results = styled(FlexCol)`
  gap: 8px;
`;

export const Title = styled.span`
  margin-right: 8px;
  font-size: 12px;
  line-height: 20px;
  color: ${({ theme }) => theme.themesColors.characterSecondaryDot45};
`;
