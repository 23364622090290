import styled from 'styled-components';

import { Message as StyledMessage } from 'user-management/view/components/CreateOrEditTeamDrawer/CreateOrEditTeamDrawer.styled';

export const Fields = styled.div`
  padding: 0 24px;
`;

export const Message = styled(StyledMessage)`
  margin-top: 10px;
  margin-bottom: 0;
`;
