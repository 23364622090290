import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Tag from 'antd/es/tag';

import { PackageTypeNames } from 'app-wrapper/types/PackageType';
import { PackageType as PackageTypeIcon } from 'app-wrapper/view/icons/PackageType';
import { CargoWeight as CargoWeightIcon } from 'app-wrapper/view/icons/CargoWeight';
import { PackagesNumber as PackagesNumberIcon } from 'app-wrapper/view/icons/PackagesNumber';

import { CargoDTM } from 'shipment-operations/models/dtm';

import { CargoCardBottomContainer, HazmatTagContainer } from './CargoCardBottom.styled';

interface ICargoCardBottomProps {
  packageType?: CargoDTM['packageType']
  packagesNumber?: CargoDTM['packagesNumber']
  weight?: CargoDTM['weight']
  isHazmat?: boolean
}

export const CargoCardBottom: FC<ICargoCardBottomProps> = ({
  packageType,
  packagesNumber,
  weight,
  isHazmat,
}) => {
  const { t } = useTranslation();

  return (
    <CargoCardBottomContainer>
      {packageType && (<span><PackageTypeIcon />{t(PackageTypeNames[packageType])}</span>)}
      <span><PackagesNumberIcon />{packagesNumber || 0} {t('pcs')}</span>
      <span><CargoWeightIcon />{weight || 0} {t('kg')}</span>
      {isHazmat && <HazmatTagContainer><Tag color="gold">{t('Hazmat')}</Tag></HazmatTagContainer>}
    </CargoCardBottomContainer>
  );
};
