import { EShippingPartyTypes } from './ShippingParty.enum';

export const OptionalShippingParties = [
  EShippingPartyTypes.CUSTOMER,
  EShippingPartyTypes.SECOND_NOTIFY_PARTY,
  EShippingPartyTypes.FORWARDER_AGENT,
  EShippingPartyTypes.DELIVERY_AGENT,
  EShippingPartyTypes.ACCOUNT_HOLDER,
  EShippingPartyTypes.ULTIMATE_CUSTOMER,
];
