import React, { FC, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Checkbox from 'antd/es/checkbox';
import { useReactToPrint } from 'react-to-print';

import { SkypaceInformationDTM } from 'app-wrapper/constants';
import { AddressDTM } from 'user-management/models/dtm';
import { TermsOfConditionsUKPdfComponent, TermsOfConditionsUSPdfComponent } from 'shipment-operations/view/components/TermsOfConditionsPdf';

import {
  Wrap,
  Image,
  Content,
  Title,
  Text,
  Agree,
  AgreeText,
  Checked,
  AgreeTextPdf,
} from './SkypaceTermsAndConditions.styled';
import illustrationSrc from './assets/illustration.png';

interface SkypaceTermsAndConditionsComponentProps {
  isChecked: boolean;
  fileNamePrint?: string;
  createdByName?: string;
  header?: {
    company?: SkypaceInformationDTM;
  }
  adminAddress?: AddressDTM;
  onChange: (value: boolean) => void;
  fetchDataBeforePrinting: () => void;
}

const OLD_TITLE = document.title;

export const SkypaceTermsAndConditionsComponent: FC<SkypaceTermsAndConditionsComponentProps> = ({
  isChecked,
  fileNamePrint,
  createdByName,
  adminAddress,
  header,
  onChange,
  fetchDataBeforePrinting,
}) => {
  const { t } = useTranslation();
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    // @ts-ignore
    content: () => componentRef.current,
    onAfterPrint: () => {
      // support chrome
      document.title = OLD_TITLE;
    },
  });

  const onClickPrint = useCallback(async (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault();

    await fetchDataBeforePrinting();

    // support chrome
    document.title = fileNamePrint || OLD_TITLE;
    handlePrint();
  }, [fileNamePrint, fetchDataBeforePrinting]);

  const handleChange = useCallback((e) => {
    onChange(e.target.checked);
  }, [onChange]);

  return (
    <Wrap>
      <div style={{ display: 'none' }}>
        {adminAddress?.country === 'US' ? (
          <TermsOfConditionsUSPdfComponent
            fileNamePrint={fileNamePrint}
            createdByName={createdByName}
            header={header}
            ref={componentRef}
          />
        ) : null}

        {adminAddress?.country === 'GB' ? (
          <TermsOfConditionsUKPdfComponent
            fileNamePrint={fileNamePrint}
            createdByName={createdByName}
            header={header}
            ref={componentRef}
          />
        ) : null}
      </div>
      <Image src={illustrationSrc} />

      <Content>
        <Title>
          {t('Skypace’s Terms and Conditions of Service')}
        </Title>

        <Text>
          {t('We need a Terms and Conditions Agreement to establish legal protection, clearly define the roles and responsibilities of both the freight forwarder and the client, and mitigate risks associated with international shipping and logistics. This agreement ensures transparency and compliance with regulations.')}
        </Text>

        <Agree>
          {isChecked ? (
            <Checked />
          ) : null}

          <Checkbox checked={isChecked} onChange={handleChange} />

          <AgreeText>
            {t('I agree with')}
          </AgreeText>

          <AgreeTextPdf onClick={onClickPrint}>
            {t(' Skypace’s Terms and Conditions of Service')}
          </AgreeTextPdf>

          <AgreeText>
            {t('*')}
          </AgreeText>
        </Agree>
      </Content>
    </Wrap>
  );
};
