import React from 'react';

import { R } from 'shipment-operations/repository';

import { useAppSelector } from 'app-wrapper/hooks';

import { PaymentTable } from 'shipment-operations/view/components';

const MainContainer = () => {
  const amount = useAppSelector(R.selectors.makePayment.getPaymentViewAmount);
  const paid = useAppSelector(R.selectors.makePayment.getPaymentViewAmountPaid);
  const left = useAppSelector(R.selectors.makePayment.getPaymentViewLeftAmount);
  const data = useAppSelector(R.selectors.makePayment.getDataForPaymentViewTable);
  const isLoading = useAppSelector(R.selectors.makePayment.getIsLoadingPaymentView);

  return (
    <PaymentTable
      data={data}
      receivedAmount={amount}
      usedAmount={paid}
      leftAmount={left}
      viewMode
      isLoading={isLoading}
    />
  );
};

export { MainContainer as Main };
