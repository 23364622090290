import React, { FC } from 'react';

import { useAppSelector } from 'app-wrapper/hooks/hooks';

import { R } from 'authentication/repository';
import { UC } from 'authentication/controllers';

import { SignUpSuccessComponent } from './SignUpSuccess.component';

const SignUpSuccessContainer: FC = () => {
  const email = useAppSelector(R.selectors.signUpSuccess.getEmail);

  return (
    <SignUpSuccessComponent
      email={email}
      onClear={UC.signUpSuccess.onClear}
    />
  );
};

export { SignUpSuccessContainer as SignUpSuccess };
