import React from 'react';

import { IPropsIcon } from 'app-wrapper/interfaces/icon';

import { Icon } from './ChartBarTopBorderSvg.styled';

export const ChartBarTopBorderSvg = (props: IPropsIcon) => {
  const { width, height, fill } = props;
  return (
    <Icon
      className="ChartBarTopBorderSvg"
      width={width || '17'}
      height={height || '16'}
      viewBox="0 0 17 16"
      fill={fill || 'currentColor'}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_21290_1204)">
        <path d="M4.52393 12.2879H5.9525C6.03107 12.2879 6.09536 12.2236 6.09536 12.145V3.85931C6.09536 3.78074 6.03107 3.71646 5.9525 3.71646H4.52393C4.44535 3.71646 4.38107 3.78074 4.38107 3.85931V12.145C4.38107 12.2236 4.44535 12.2879 4.52393 12.2879ZM7.9525 7.28789H9.38107C9.45964 7.28789 9.52393 7.2236 9.52393 7.14503V3.85931C9.52393 3.78074 9.45964 3.71646 9.38107 3.71646H7.9525C7.87393 3.71646 7.80964 3.78074 7.80964 3.85931V7.14503C7.80964 7.2236 7.87393 7.28789 7.9525 7.28789ZM11.3811 8.5736H12.8096C12.8882 8.5736 12.9525 8.50932 12.9525 8.43074V3.85931C12.9525 3.78074 12.8882 3.71646 12.8096 3.71646H11.3811C11.3025 3.71646 11.2382 3.78074 11.2382 3.85931V8.43074C11.2382 8.50932 11.3025 8.5736 11.3811 8.5736ZM15.2382 0.859314H2.09535C1.77928 0.859314 1.52393 1.11467 1.52393 1.43074V14.5736C1.52393 14.8897 1.77928 15.145 2.09535 15.145H15.2382C15.5543 15.145 15.8096 14.8897 15.8096 14.5736V1.43074C15.8096 1.11467 15.5543 0.859314 15.2382 0.859314ZM14.5239 13.8593H2.80964V2.14503H14.5239V13.8593Z" fill="#4A55AD" />
      </g>
      <defs>
        <clipPath id="clip0_21290_1204">
          <rect width="16" height="16" fill="white" transform="translate(0.666504)" />
        </clipPath>
      </defs>
    </Icon>
  );
};
