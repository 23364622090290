import {
  IsDefined, IsNumber, IsOptional, IsString, ValidateNested,
} from 'class-validator';
import { Type } from 'class-transformer';
import { BaseDTM } from 'proto/BaseDTM';

import { InvoicesChargeDTM, IInvoicesChargeDTM } from 'shipment-operations/models/dtm';

export interface ICreditNoteItemDTM {
  amount: number
  costPerUnit: number
  id: number
  numberOfUnits: number
  invoiceItem: IInvoicesChargeDTM
  invoiced?: number
}

export class CreditNoteItemDTM extends BaseDTM<ICreditNoteItemDTM> {
  @IsNumber()
  @IsDefined()
  amount: number;

  @IsNumber()
  @IsDefined()
  costPerUnit: number;

  @IsNumber()
  @IsDefined()
  id: number;

  @IsNumber()
  @IsDefined()
  numberOfUnits: number;

  @IsNumber()
  invoiced?: number;

  @IsDefined()
  @ValidateNested()
  @Type(() => InvoicesChargeDTM)
  invoiceItem: InvoicesChargeDTM
}

export interface IFullCreditNoteDTM {
  amount: number
  createdAt: string
  createdBy: string
  description: string
  id: number
  number: string
  reason: string
  items: ICreditNoteItemDTM[]
  invoiceId: number
  invoiceNumber: string
}

export class FullCreditNoteDTM extends BaseDTM<IFullCreditNoteDTM> {
  @IsNumber()
  @IsDefined()
  amount: number;

  @IsString()
  @IsDefined()
  createdAt: string;

  @IsString()
  @IsDefined()
  createdBy: string;

  @IsString()
  @IsDefined()
  description: string;

  @IsNumber()
  @IsDefined()
  id: number;

  @IsString()
  @IsDefined()
  number: string;

  @IsString()
  @IsDefined()
  reason: string;

  @IsNumber()
  @IsDefined()
  invoiceId: number;

  @IsString()
  @IsDefined()
  invoiceNumber: string;

  @IsDefined()
  @ValidateNested()
  @Type(() => CreditNoteItemDTM)
  items: CreditNoteItemDTM[]
}

export interface ILinkedCreditNoteDTM {
  amount: number
  createdAt?: string
  id?: number
  number?: string
}

export class LinkedCreditNoteDTM extends BaseDTM<ILinkedCreditNoteDTM> {
  @IsNumber()
  @IsDefined()
  amount: number;

  @IsString()
  @IsOptional()
  createdAt?: string;

  @IsNumber()
  @IsOptional()
  id?: number;

  @IsString()
  @IsOptional()
  number?: string;
}

export interface ICreatedCreditNoteDTM {
  amount: number
  balance: number
  id: number
  number: string
}

export class CreatedCreditNoteDTM extends BaseDTM<ICreatedCreditNoteDTM> {
  @IsNumber()
  @IsDefined()
  amount: number;

  @IsNumber()
  @IsDefined()
  balance: number;

  @IsNumber()
  @IsDefined()
  id: number;

  @IsString()
  @IsDefined()
  number: string;
}

export interface IGroupedCreditNotesDTM {
  [key: string]: CreditNoteItemDTM[]
}
