import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { IMOClassType, IMOClassTypeNames } from 'app-wrapper/types/IMOClassType';
import { Option } from 'app-wrapper/view/components';
import { CargoDTM } from 'shipment-operations/models/dtm';

import { SelectStyled } from './IMOClassSelect.styled';

interface IMOClassSelectComponentProps {
  imoClass: CargoDTM['imoClass']
  touchCargoField: (field: string, cargoId: number) => void
  setImoClass: (value: IMOClassType, cargoId: number) => void
  cargoId: number;
  error?: boolean
}

export const IMOClassSelectComponent: FC<IMOClassSelectComponentProps> = ({
  imoClass,
  touchCargoField,
  setImoClass,
  cargoId,
  error,
}) => {
  const { t } = useTranslation();

  const setIMOClass = useCallback((value: IMOClassType) => {
    setImoClass(value, cargoId);
  }, [setImoClass, cargoId]);

  const touchField = useCallback((field: string) => {
    touchCargoField(field, cargoId);
  }, [touchCargoField, cargoId]);

  const handleBlur = useCallback(() => {
    touchField('imoClass');
  }, [touchField]);

  return (
    <SelectStyled
      value={imoClass}
      error={error}
      allowClear={!!imoClass}
      onBlur={handleBlur}
      onChange={setIMOClass}
      size="middle"
    >
      {Object.keys(IMOClassTypeNames).map((type) => (
        <Option key={type} value={type}>
          {t(IMOClassTypeNames[type as IMOClassType])}
        </Option>
      ))}
    </SelectStyled>
  );
};
