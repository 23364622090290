import React, {
  ChangeEvent, FC, useCallback, useEffect, useMemo,
} from 'react';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import Spinner from 'antd/es/spin';
import isEmpty from 'lodash/isEmpty';

import { ShipmentDrawerWrapper } from 'shipment-operations/view/pages/Shipment/components/ShipmentDrawer/ShipmentDrawer.styled';
import { Button } from 'app-wrapper/view/components';
import {
  CARGO, CHANGES,
  CONTAINER,
  CUTOFF,
  LOCATIONS,
  ShipmentBookingStatusEnum,
  ShipmentStatusEnum,
} from 'shipment-operations/constants';

import { IChangesTable, ITransportPlanChanges } from 'shipment-operations/models/dtm';
import { Validate } from 'shipment-operations/view/components';
import {
  CustomTable, EditableTable, Uploader, TransportPlan,
} from './components';

import {
  CarrierWrapper, FooterWrapper, InputsWrapper, InputTitle, SpinnerWrapper, StyledAlert, StyledInput, SubTitle,
  TableWrapper, UploadWrapper, Wrapper, Underline, ValidateWrapper,
} from './ShipmentChanges.styled';

interface ShipmentChangesComponentProps {
  bookingStatus?: ShipmentBookingStatusEnum;
  shipmentStatus?: ShipmentStatusEnum;
  carrierNumber?: string;
  inttraNumber?: string;
  onChangeCarrierNumber: (value: string) => void;
  onChangeInttraNumber: (value: string) => void;
  loadData: (shipmentId: string) => void;
  cargoTable: IChangesTable[];
  containersTable: IChangesTable[];
  cutOffTable: IChangesTable[];
  locationsTable: IChangesTable[];
  loading: boolean;
  isRequestTheSameWithShipment?: boolean;
  withMismatches: boolean;
  onConfirm: (shipmentId: string) => void;
  isActionLoading: boolean;
  onChangeCutOff: (row: IChangesTable) => void;
  onChangeLocation: (row: IChangesTable) => void;
  onClose: () => void;
  syncIntraNumber: () => void;
  isMaersk: boolean;
  partnerWarning?: boolean;
  transportPlanCurrent?: ITransportPlanChanges[];
  transportPlanPrevious?: ITransportPlanChanges[];
  transportPlanMismatchCurrent?: ITransportPlanChanges[];
  isTransportPlanMismatch?: boolean;
  savedShipmentId: string;
  isCustomer: boolean;
  reset: () => void;
  showValidate: boolean;
  isDisableSubmit: boolean;
  isEqualTransportPlan?: boolean;
  withLocationSection: boolean;
}

const CONFIRMED_STATUSES = [
  ShipmentBookingStatusEnum.BOOKING_CONFIRMED,
  ShipmentBookingStatusEnum.BOOKING_AMENDMENT_CONFIRMED,
];

const ShipmentChangesComponent: FC<ShipmentChangesComponentProps> = ({
  bookingStatus, shipmentStatus, carrierNumber, isRequestTheSameWithShipment, withMismatches, onConfirm, withLocationSection,
  inttraNumber, onChangeInttraNumber, onChangeCarrierNumber, loadData, cargoTable, containersTable, cutOffTable, locationsTable, loading,
  isActionLoading, onChangeCutOff, onChangeLocation, onClose, syncIntraNumber, isMaersk, partnerWarning, reset, showValidate, isDisableSubmit, isEqualTransportPlan,
  transportPlanCurrent, transportPlanPrevious, transportPlanMismatchCurrent, isTransportPlanMismatch, savedShipmentId, isCustomer,
}) => {
  const { t } = useTranslation();
  const { shipmentId } = useParams();

  useEffect(() => {
    syncIntraNumber();
    if (shipmentId || savedShipmentId) {
      loadData(shipmentId || savedShipmentId);
    }
  }, [shipmentId, savedShipmentId]);

  useEffect(() => () => reset(), []);

  const inttraChangeHandler = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      onChangeInttraNumber(event.target.value);
    },
    [inttraNumber],
  );
  const carrierChangeHandler = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      onChangeCarrierNumber(event.target.value);
    },
    [],
  );

  const handleConfirm = useCallback(() => {
    if (shipmentId || savedShipmentId) {
      onConfirm(shipmentId || savedShipmentId);
    }
  }, [shipmentId]);

  const partnerAmendmentSubmitted = (!isCustomer && (bookingStatus === ShipmentBookingStatusEnum.BOOKING_AMENDMENT_SUBMITTED) && !withMismatches);

  const generateAlertText = useMemo(() => {
    if (!bookingStatus) {
      return {};
    }
    if (partnerAmendmentSubmitted) {
      return {};
    }
    if (isCustomer && CONFIRMED_STATUSES.includes(bookingStatus) && !isRequestTheSameWithShipment) {
      return {
        text: t('AmendmentsSpecifiedBelow'),
        btnText: t('submitAmendments'),
      };
    }
    if (isCustomer && bookingStatus === ShipmentBookingStatusEnum.BOOKING_AMENDMENT_SUBMITTED) {
      return {
        text: t('CarrierHasBeenInformed'),
        btnText: '',
      };
    }
    if (!isCustomer && (bookingStatus === ShipmentBookingStatusEnum.BOOKING_SUBMITTED || bookingStatus === ShipmentBookingStatusEnum.BOOKING_AMENDMENT_SUBMITTED) && isMaersk) {
      return {
        text: t('doNotSupportDirectIntegration'),
        btnText: t('confirmShipment'),
      };
    }
    if (partnerWarning) {
      return {
        text: t('pleaseReviewAmendments'),
        btnText: t('confirmAmendments'),
      };
    }
    return {};
  }, [bookingStatus]);

  const withThreeColumns = useMemo(() => (
    bookingStatus === ShipmentBookingStatusEnum.BOOKING_AMENDMENT_SUBMITTED || !isRequestTheSameWithShipment
  ), [bookingStatus, shipmentStatus]);

  const title = useMemo(() => {
    if (isCustomer) {
      if (bookingStatus && CONFIRMED_STATUSES.includes(bookingStatus) && !isRequestTheSameWithShipment) {
        return t('Submit Shipment Amendments');
      }
      if (bookingStatus && bookingStatus === ShipmentBookingStatusEnum.BOOKING_AMENDMENT_SUBMITTED) {
        return t('Amendments Waiting Confirmation');
      }
    }
    if (partnerAmendmentSubmitted) {
      return t('Amendments Waiting Confirmation');
    }
    if (isMaersk && bookingStatus && (bookingStatus === ShipmentBookingStatusEnum.BOOKING_SUBMITTED || bookingStatus === ShipmentBookingStatusEnum.BOOKING_AMENDMENT_SUBMITTED)) {
      return t('Manage Request Manually');
    }
    if (partnerWarning) {
      return t('Review Carrier Response and Confirm');
    }
    return '';
  }, [isCustomer, bookingStatus]);

  return (
    <ShipmentDrawerWrapper
      title={title}
      visible
      width="1137px"
      closable
      maskClosable={false}
      onClose={onClose}
    >
      <Wrapper>
        {loading ? (
          <SpinnerWrapper>
            <Spinner />
          </SpinnerWrapper>
        ) : (
          <>
            {!isEmpty(generateAlertText) && (
              <StyledAlert
                showIcon
                type="warning"
                description={generateAlertText.text}
              />
            )}
            <>
              {!isCustomer && !partnerAmendmentSubmitted && (
                <>
                  <CarrierWrapper>
                    <SubTitle>{t('carrier')}</SubTitle>
                    <InputsWrapper>
                      <InputTitle>{t('inttraReferenceNumber')}</InputTitle>
                      <StyledInput
                        style={{ marginRight: '15px', borderColor: inttraNumber ? '' : 'red' }}
                        value={inttraNumber}
                        onChange={inttraChangeHandler}
                      />
                      <InputTitle>{t('carrierReferenceNumber')}</InputTitle>
                      <StyledInput
                        style={{ borderColor: carrierNumber ? '' : 'red' }}
                        value={carrierNumber}
                        onChange={carrierChangeHandler}
                      />
                    </InputsWrapper>
                  </CarrierWrapper>
                  <UploadWrapper>
                    <SubTitle>{t('bookingConfirmationDocument')}</SubTitle>
                    <Uploader />
                  </UploadWrapper>
                </>
              )}
              {!isEmpty(cargoTable) && !isCustomer && (
                <TableWrapper>
                  <SubTitle>{t('cargo')}</SubTitle>
                  <CustomTable
                    data={cargoTable}
                    type={CARGO}
                    withThreeColumns={withThreeColumns && !partnerAmendmentSubmitted}
                    withMismatches={withMismatches}
                    isRequestTheSameWithShipment={isRequestTheSameWithShipment}
                    isCustomer={isCustomer}
                    partnerAmendmentSubmitted={partnerAmendmentSubmitted}
                  />
                </TableWrapper>
              )}
              {!isEmpty(containersTable) && (
                <TableWrapper>
                  <SubTitle>{t('Containers')}</SubTitle>
                  <CustomTable
                    data={containersTable}
                    type={CONTAINER}
                    withThreeColumns={withThreeColumns && !partnerAmendmentSubmitted}
                    withMismatches={withMismatches}
                    isRequestTheSameWithShipment={isRequestTheSameWithShipment}
                    isCustomer={isCustomer}
                    partnerAmendmentSubmitted={partnerAmendmentSubmitted}
                  />
                </TableWrapper>
              )}
              {!isEqualTransportPlan && isCustomer && bookingStatus && CONFIRMED_STATUSES.includes(bookingStatus) && !isRequestTheSameWithShipment && (
              <TableWrapper>
                <SubTitle>{t('transportPlan')}</SubTitle>
                <Underline />
                <TransportPlan
                  currentTitle={t('currentlyConfirmed')}
                  requestedTitle={t('changesRequested')}
                  currentData={transportPlanPrevious}
                  requestedData={transportPlanCurrent}
                />
              </TableWrapper>
              )}
              {isCustomer && !isEqualTransportPlan && !isTransportPlanMismatch && (bookingStatus === ShipmentBookingStatusEnum.BOOKING_AMENDMENT_SUBMITTED) && (
                <TableWrapper>
                  <SubTitle>{t('transportPlan')}</SubTitle>
                  <Underline />
                  <TransportPlan
                    currentTitle={t('currentlyConfirmed')}
                    requestedTitle={t('changesRequested')}
                    currentData={transportPlanPrevious}
                    requestedData={transportPlanCurrent}
                  />
                </TableWrapper>
              )}
              {!isCustomer && !isEqualTransportPlan && isTransportPlanMismatch && (bookingStatus === ShipmentBookingStatusEnum.BOOKING_AMENDMENT_SUBMITTED) && (
                <TableWrapper>
                  <SubTitle>{t('transportPlan')}</SubTitle>
                  <Underline />
                  <TransportPlan
                    currentTitle={t('currentlyConfirmed')}
                    requestedTitle={t('changesRequested')}
                    currentData={transportPlanPrevious}
                    requestedData={transportPlanCurrent}
                  />
                </TableWrapper>
              )}
              {!isCustomer && isTransportPlanMismatch && (withThreeColumns ? (
                <TableWrapper>
                  <SubTitle>{t('transportPlan')}</SubTitle>
                  <Underline />
                  <TransportPlan
                    currentTitle={t('currentlyConfirmed')}
                    requestedTitle={t('changesRequested')}
                    currentData={transportPlanPrevious}
                    requestedData={transportPlanCurrent}
                    mismatchedTitle={t('carrierResponse')}
                    mismatchedData={transportPlanMismatchCurrent}
                    withMismatch
                  />
                </TableWrapper>
              ) : (
                <TableWrapper>
                  <SubTitle>{t('transportPlan')}</SubTitle>
                  <Underline />
                  <TransportPlan
                    requestedTitle={t('Requested')}
                    requestedData={transportPlanCurrent}
                    mismatchedTitle={t('carrierResponse')}
                    mismatchedData={transportPlanMismatchCurrent}
                    withMismatch
                  />
                </TableWrapper>
              ))}
              {showValidate && !isCustomer && !partnerAmendmentSubmitted && (
                <ValidateWrapper>
                  <Validate typeDrawer={CHANGES} />
                </ValidateWrapper>
              )}
              {!isCustomer && !partnerAmendmentSubmitted && (
                <>
                  <TableWrapper>
                    <SubTitle>{t('Cutoffs')}</SubTitle>
                    <EditableTable
                      data={cutOffTable}
                      type={CUTOFF}
                      withThreeColumns={withThreeColumns}
                      withMismatches={withMismatches}
                      isRequestTheSameWithShipment={isRequestTheSameWithShipment}
                      onChange={onChangeCutOff}
                      isCustomer={isCustomer}
                    />
                  </TableWrapper>
                  {withLocationSection && (
                    <TableWrapper>
                      <SubTitle>{t('Location')}</SubTitle>
                      <EditableTable
                        data={locationsTable}
                        type={LOCATIONS}
                        withThreeColumns={withThreeColumns}
                        withMismatches={withMismatches}
                        isRequestTheSameWithShipment={isRequestTheSameWithShipment}
                        onChange={onChangeLocation}
                        isCustomer={isCustomer}
                      />
                    </TableWrapper>
                  )}
                </>
              )}
            </>
          </>
        )}
      </Wrapper>
      <FooterWrapper>
        {generateAlertText.btnText && (
          <Button
            loading={isActionLoading}
            onClick={handleConfirm}
            disabled={isCustomer ? false : isDisableSubmit}
          >
            {generateAlertText.btnText}
          </Button>
        )}
      </FooterWrapper>
    </ShipmentDrawerWrapper>
  );
};

export { ShipmentChangesComponent };
