import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Divider from 'antd/es/divider';
import { useLocation } from 'react-router-dom';
import FilterFilled from '@ant-design/icons/FilterFilled';
import SearchOutlined from '@ant-design/icons/SearchOutlined';

import { EmptyStateForPages } from 'app-wrapper/view/components';
import { ShipFloodedSvg } from 'app-wrapper/view/icons';
import themesColors from 'app-wrapper/view/themes/themesColors';
import { ShipmentPreviewDTM } from 'shipment-operations/models/dtm';
import { ShipmentCard } from 'shipment-operations/view/components';
import { SkeletonShipment } from 'app-wrapper/view/components/Skeleton/Skeleton.component';

import {
  ShipmentListContainer,
  ShipmentListHeader,
  ShipmentListContent,
  ShipmentListPart,
  ShipmentListInfiniteScroll,
  ShipmentListPartContent,
  ShipmentListPartContentHeader,
  ShipmentListPartContentHeaderTitle,
  ShipmentListPartContentWrap,
  InnerHeader,
} from './styled';
import { ShipmentListFilters } from './components';

interface IShipmentListComponentProps {
  list: ShipmentPreviewDTM[]
  wasLastResponseEmpty: boolean
  isLoading?: boolean
  isClearAllVisible?: boolean
  searchValue?: string
  isClearSearch?: boolean
  onStartLoad: () => void
  loadMoreDataHandler: () => void
  clearHandler: () => void
  onClickGetNewQuote: () => void
  clearAllFilters: () => void
  onClickToCustomer: (id?: number) => () => void
}

export const ShipmentListComponent: FC<IShipmentListComponentProps> = ({
  list,
  wasLastResponseEmpty,
  isLoading,
  isClearAllVisible,
  searchValue,
  isClearSearch,
  onStartLoad,
  loadMoreDataHandler,
  clearHandler,
  onClickGetNewQuote,
  clearAllFilters,
  onClickToCustomer,
}) => {
  const { t } = useTranslation();
  const location = useLocation();

  useEffect(() => {
    onStartLoad();

    return () => {
      clearHandler();
    };
  }, [location.pathname]);

  const isNotShipmentExist = !isLoading && !list?.length && !isClearAllVisible && !isClearSearch;
  const isClearFilters = !isLoading && !list?.length && isClearAllVisible;
  const isClearSearchFilters = !isLoading && !list?.length && !isClearAllVisible && isClearSearch;

  return (
    <ShipmentListContainer data-cy="shipmentListPage">
      <ShipmentListHeader>
        <InnerHeader>
          {t('Shipments')}
        </InnerHeader>
      </ShipmentListHeader>

      <ShipmentListFilters />

      <ShipmentListContent>
        <ShipmentListPart id="shipmentListItems">
          <ShipmentListPartContentHeader>
            <ShipmentListPartContentHeaderTitle paddingLeft="17px" width="11.4%">
              {t('shipment')}
            </ShipmentListPartContentHeaderTitle>
            <ShipmentListPartContentHeaderTitle paddingLeft="43px" width="calc(15% + 1px + 8px)">
              {t('origin')}
            </ShipmentListPartContentHeaderTitle>
            <ShipmentListPartContentHeaderTitle paddingLeft="41px" width="15%">
              {t('destination')}
            </ShipmentListPartContentHeaderTitle>
            <ShipmentListPartContentHeaderTitle paddingLeft="40px" width="calc(15% + 1px)">
              {t('customer')}
            </ShipmentListPartContentHeaderTitle>
            <ShipmentListPartContentHeaderTitle paddingLeft="43px" width="calc(15% - 4px)">
              {t('carrier')}
            </ShipmentListPartContentHeaderTitle>
            <ShipmentListPartContentHeaderTitle isCenter width="6.2%">
              {t('Notifications')}
            </ShipmentListPartContentHeaderTitle>
            {/* <ShipmentListPartContentHeaderTitle isCenter width="calc(6.2% + 1px)">
              {t('Alerts')}
            </ShipmentListPartContentHeaderTitle> */}
            <ShipmentListPartContentHeaderTitle isEnd width="15%">
              {t('Status')}
            </ShipmentListPartContentHeaderTitle>
          </ShipmentListPartContentHeader>
          <ShipmentListPartContentWrap id="ShipmentListPartContentWrap">

            {isLoading || list?.length ? (
              <ShipmentListPartContent id="ShipmentListPartContent">
                <ShipmentListInfiniteScroll
                  dataLength={list.length}
                  next={loadMoreDataHandler}
                  hasMore={!wasLastResponseEmpty}
                  loader={isLoading && <SkeletonShipment rowCount={1} />}
                  endMessage={<Divider plain>{t('No more shipments')}</Divider>}
                  scrollableTarget="ShipmentListPartContent"
                >
                  <SkeletonShipment isLoading={isLoading && list.length === 0}>
                    {list.map((shipmentPreviewItem) => (
                      <ShipmentCard
                        isReference={!!shipmentPreviewItem.reference}
                        key={shipmentPreviewItem.shipmentName}
                        shipmentStats={shipmentPreviewItem.shipmentStats}
                        shipmentData={shipmentPreviewItem}
                        onClickToCustomer={onClickToCustomer(shipmentPreviewItem.id)}
                      />
                    ))}
                  </SkeletonShipment>
                </ShipmentListInfiniteScroll>
              </ShipmentListPartContent>
            ) : null}

            {isNotShipmentExist ? (
              <EmptyStateForPages
                icon={(
                  <ShipFloodedSvg
                    width={28}
                    height={22}
                    color={themesColors.neutralBranding65}
                  />
                )}
                headerText={t('No active shipments at the moment.')}
                contentText={t('You have no shipments at the moment. Book a new shipment to get started.')}
                buttonText={t('Book shipment')}
                onClickButton={onClickGetNewQuote}
              />
            ) : null}

            {isClearFilters ? (
              <EmptyStateForPages
                icon={(
                  <FilterFilled
                    style={{
                      color: themesColors.neutralBranding65,
                      width: '25px',
                      height: '23px',
                    }}
                  />
                )}
                headerText={t('No shipments found')}
                contentText={t('Filters maybe to restrictive')}
                buttonText={t('Clear filters')}
                onClickButton={clearAllFilters}
              />
            ) : null}

            {isClearSearchFilters ? (
              <EmptyStateForPages
                icon={(
                  <SearchOutlined
                    style={{
                      color: themesColors.neutralBranding65,
                      width: '25px',
                      height: '23px',
                    }}
                  />
                )}
                headerText={`${t('No results for')} ${searchValue || ''}`}
                contentText={t('You may want to try using different keyword, checking for typos, or adjusting your filters.')}
              />
            ) : null}
          </ShipmentListPartContentWrap>
        </ShipmentListPart>
      </ShipmentListContent>
    </ShipmentListContainer>
  );
};
