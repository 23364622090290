import React, { ReactNode, forwardRef } from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';

import { HBLDocumentPDFComponent } from './HBLDocumentPDF.component';

interface IHBLDocumentPDFContainerProps {
  fileNamePrint?: string;
  header?: ReactNode;
}

const HBLDocumentPDFContainer = forwardRef((props: IHBLDocumentPDFContainerProps, ref) => {
  const billOfLadingHBL = useSelector(R.selectors.documentHBLPDF.getHBLDocumentBOL);

  return (
    <HBLDocumentPDFComponent
      ref={ref}
      header={props.header}
      fileNamePrint={props.fileNamePrint}
      billOfLadingHBL={billOfLadingHBL}
    />
  );
});

export { HBLDocumentPDFContainer as HBLDocumentPDF };
