import React, { FC, memo } from 'react';
import { useSelector } from 'react-redux';

import { R as AppR } from 'app-wrapper/repository';

import { ShipmentMenuComponent } from './ShipmentMenu';

const ShipmentMenu: FC = () => {
  const shipmentByIdStats = useSelector(AppR.selectors.overview.getShipmentByIdStats);

  return (
    <ShipmentMenuComponent
      shipmentStats={shipmentByIdStats}
    />
  );
};

const ShipmentMenuCached = memo(ShipmentMenu);

export { ShipmentMenuCached as ShipmentMenu };
