import React from 'react';
import { useSelector } from 'react-redux';

import { R } from 'user-management/repository';
import { UC } from 'user-management/controllers';

import { TradeReferencesComponent } from './TradeReferences.component';

const TradeReferencesContainer = () => {
  const tradeReference = useSelector(R.selectors.customer.getTradeReference);
  const shouldValidate = useSelector(R.selectors.customer.getShouldValidateTradeReference);

  return (
    <TradeReferencesComponent
      shouldValidate={shouldValidate}
      tradeReference={tradeReference}
      setCompanyName={UC.customer.setTradeCompanyName}
      setFirstName={UC.customer.setTradeFirstName}
      setLastName={UC.customer.setTradeLastName}
      setPhone={UC.customer.setTradePhone}
      setEmail={UC.customer.setTradeEmail}
    />
  );
};

export { TradeReferencesContainer as TradeReferences };
