import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';
import { UC } from 'shipment-operations/controllers';

import { DescriptionInputComponent } from './DescriptionInput.component';

interface DescriptionInputContainerProps {
  cargoId: number;
}

const DescriptionInputContainer: FC<DescriptionInputContainerProps> = ({ cargoId }) => {
  const { description = '' } = useSelector(R.selectors.bookingWizard.getCargoById(cargoId));

  return (
    <DescriptionInputComponent
      cargoId={cargoId}
      description={description}
      touchCargoField={UC.bookingWizard.touchCargoField}
      setDescription={UC.bookingWizard.setDescription}
    />
  );
};

export { DescriptionInputContainer as DescriptionInput };
