import React from 'react';
import { observer } from 'mobx-react-lite';

import { useMobxStore } from 'app-wrapper/mobxStores';
import { UC } from 'shipment-operations/controllers';

import { DocumentsResultsComponent } from './DocumentsResults.component';

export const DocumentsResults = observer(() => {
  const { shipmentServices } = useMobxStore();

  return (
    <DocumentsResultsComponent
      documentActivities={shipmentServices.state.documentActivities}
      othersDocumentActivities={shipmentServices.state.othersDocumentActivities}
      downloadDocument={UC.shipmentDocumentsAll.downloadDocument}
      startEditingFlowFromDocuments={UC.shipmentServices.startEditingFlowFromDocuments}
      isThereAreAnyRelatedActivities={shipmentServices.getIsThereAnyRelatedActivities}
    />
  );
});
