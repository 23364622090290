import React, { FC } from 'react';

import { TeamMemberCard } from 'app-wrapper/view/guideline';
import { TeamMemberDTM } from 'user-management/models/dtm';

import { Content } from './TeamMembers.styled';

interface ITeamMembersComponentProps {
  teamMembers: TeamMemberDTM[];
}

export const TeamMembersComponent: FC<ITeamMembersComponentProps> = ({ teamMembers }) => (
  <Content>
    {teamMembers.map((teamMember) => (
      <TeamMemberCard key={teamMember.id} teamMember={teamMember} />
    ))}
  </Content>
);
