import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Typography from 'antd/es/typography';

import { DateDtm } from 'app-wrapper/models/dtm';

import { Container, Dates } from './TrackerHeaderDestination.styled';

export interface ITrackerHeaderDepartureProps {
  name: string
  apmTerminals?: string
  estimatedTime?: DateDtm
  actualTime?: DateDtm
  timeFormat?: string
  arrival?: boolean
}

export const TrackerHeaderDestination: FC<ITrackerHeaderDepartureProps> = ({
  name,
  apmTerminals,
  estimatedTime,
  actualTime,
  timeFormat = 'DD MMM, HH:mm',
  // TODO: move to model, when models become classes
  arrival = false,
}) => {
  const { t } = useTranslation();

  return (
    <Container alignEnd={arrival}>
      <Typography.Title level={5}>{name}</Typography.Title>
      <Typography.Text>{apmTerminals}</Typography.Text>
      <Dates>
        {actualTime ? (
          <>
            <Typography.Text>{t(arrival ? 'ATA' : 'ATD')}</Typography.Text>
            <Typography.Text>{actualTime?.getDateAsMomentWithOffset().format(timeFormat)}</Typography.Text>
          </>
        ) : (
          <>
            <Typography.Text>{t(arrival ? 'ETA' : 'ETD')}</Typography.Text>
            <Typography.Text>{estimatedTime ? estimatedTime.getDateAsMomentWithOffset().format(timeFormat) : ''}</Typography.Text>
          </>
        )}
      </Dates>
    </Container>
  );
};
