import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';
import { UC } from 'shipment-operations/controllers';

import { ShipmentOverviewReferencesComponent } from './ShipmentOverviewReferences.component';

const ShipmentOverviewReferencesContainer: FC = () => {
  const isLoading = useSelector(R.selectors.overview.getLoadingStatus);
  const references = useSelector(R.selectors.overview.getReferences);
  const permissions = useSelector(R.selectors.shipment.getOverviewPermissions);
  const getShipmentReference = useSelector(R.selectors.shipment.getShipment);

  return (
    <ShipmentOverviewReferencesComponent
      isLoading={isLoading}
      shipmentReference={getShipmentReference?.reference || ''}
      bookingReference={references.booking}
      inttraReference={references.inttra}
      contractReference={references.contract}
      mblReference={references.mbl}
      inttraReferenceAvailability={permissions.inttraReferenceNumberAvailability}
      contractAvailability={permissions.contractNumberAvailability}
      onChangeIsOpenModalShipmentReference={UC.overview.onChangeIsOpenModalShipmentReference}
    />
  );
};

export {
  ShipmentOverviewReferencesContainer as ShipmentOverviewReferences,
};
