import React from 'react';
import { useSelector } from 'react-redux';

import { UC } from 'user-management/controllers';
import { R } from 'user-management/repository';

import { TeamsComponent } from './Teams.component';

const TeamsContainer = () => {
  const isLoading = useSelector(R.selectors.teams.getIsLoading);
  const teams = useSelector(R.selectors.teams.getTeams);
  const isAdmin = useSelector(R.selectors.teams.getIsCurrentUserAdmin);

  return (
    <TeamsComponent
      teams={teams}
      isLoading={isLoading}
      init={UC.teams.initTeams}
      openCreateDrawer={UC.teams.openCreateTeamDrawer}
      isAdmin={isAdmin}
    />
  );
};

export { TeamsContainer as TeamsPage };
