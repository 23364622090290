import styled from 'styled-components';

export const TagContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 4px;

  width: 100%;

  > .ant-tag {
    width: fit-content;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
