import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import isEmpty from 'lodash/fp/isEmpty';
import ClockCircleOutlined from '@ant-design/icons/ClockCircleOutlined';
import WarningFilled from '@ant-design/icons/WarningFilled';
import Tag from 'antd/es/tag';

import { ContentKey } from 'shipment-operations/view/drawers/ShipmentChanges/components/CustomTable/CustomTable.styled';
import {
  CARGO,
  commonContainersTypesLong,
  CONTAINER, CUTOFF,
  LOCATIONS,
} from 'shipment-operations/constants';
import getOr from 'lodash/fp/getOr';

interface ICell {
  name: string
  value: string
}

interface ICustomCell {
  [key: string]: string
}

const useTable = (withThreeColumns: boolean, type: string, withMismatches: boolean, isRequestTheSameWithShipment?: boolean, isCustomer?: boolean, mismatchesTable?: boolean, maerskMode?: boolean, partnerAmendmentSubmitted?: boolean) => {
  const { t } = useTranslation();
  const generateTransportPlanTitle = (obj: ICell) => (
    <>
      <ContentKey>{obj.name} </ContentKey>
      <span>{obj.value}</span>
    </>
  );

  const generateCargoContainers = (obj: ICell, withTag?: boolean, isRemoved?: boolean) => {
    if (obj.name === 'Quantity' && withTag) {
      return obj.value ? (
        <>
          <ContentKey>{obj.name} </ContentKey>
          <Tag color={parseInt(obj.value, 10) > 0 ? 'green' : 'red'}>{parseInt(obj.value, 10) > 0 ? `+ ${obj.value}` : obj.value}</Tag>
        </>
      ) : (<></>);
    }
    return (
      <>
        <ContentKey>{obj.name} </ContentKey>
        {isRemoved ? <Tag color="red">{t('removed')}</Tag> : <span>{obj.value}</span>}
      </>
    );
  };

  const generateCargoContainersWithWarning = (obj: ICell, warning: boolean) => (
    <>
      <ContentKey>{obj.name} </ContentKey>
      {warning && <WarningFilled style={{ color: '#F1AE00', marginRight: '5px', marginLeft: '5px' }} />}
      <span style={{ color: warning ? '#F1AE00' : '' }}>{obj.value}</span>
    </>
  );

  const arrayRender = (value: ICustomCell[]) => (value.map((item: ICustomCell) => Object.keys(item).map((key) => (
    <div key={key}>
      <ContentKey>{t(`${key}`)} </ContentKey>
      <span>{item[key]}</span>
    </div>
  ))));

  const renderEditCell = (value: ICustomCell[] | string | ICell, withTag?: boolean, isRemoved?: boolean) => {
    if (isEmpty(value)) {
      return '';
    }
    if (type === LOCATIONS) {
      return generateTransportPlanTitle(value as ICell);
    }
    if ((type === CARGO) || (type === CONTAINER)) {
      return generateCargoContainers(value as ICell, withTag, isRemoved);
    }
    if (Array.isArray(value)) {
      return arrayRender(value);
    }
    return value;
  };

  const width = useMemo(() => {
    if (withThreeColumns) {
      return '25%';
    }
    return '33%';
  }, [withThreeColumns]);

  const changesRequested = useMemo(() => ({
    title: t('changesRequested'),
    dataIndex: 'requested',
    key: 'requested',
    width,
    editable: false,
    render: (value: ICustomCell[] | string | ICell, record: ICell) => {
      const isRemoved = getOr(false, ['isRemoved'], record);
      return renderEditCell(value, true, isRemoved);
    },
  }), []);

  const header = [
    {
      title: t('object'),
      dataIndex: 'title',
      key: 'title',
      width,
      render: (value: ICell[] | string | ICell) => {
        if (type === CONTAINER) {
          return commonContainersTypesLong[value as keyof typeof commonContainersTypesLong];
        }
        return value;
      },
    },
    {
      title: withThreeColumns ? t('currentlyConfirmed') : 'Requested',
      dataIndex: 'confirmed',
      key: 'confirmed',
      width,
      render: (value: ICustomCell[] | string | ICell) => renderEditCell(value, false),
    },
    {
      title: t('carrierResponse'),
      dataIndex: 'carrierConfirmed',
      key: 'carrierConfirmed',
      width,
      render: (value: ICustomCell[] | string | ICell, record: any) => {
        if (!isRequestTheSameWithShipment) {
          return t('notSubmitted');
        }
        if (!withMismatches) {
          if (type === CONTAINER || type === CARGO) {
            return (<ClockCircleOutlined style={{ color: '#D1D7E2' }} />);
          }
          return value;
        }
        if (!value) {
          return '';
        }
        if ((type === CUTOFF) || (type === LOCATIONS)) {
          return value;
        }
        if ((type === CARGO) || (type === CONTAINER)) {
          return generateCargoContainersWithWarning(value as ICell, record.isMismatch);
        }
        if (Array.isArray(value)) {
          return (value.map((item: ICustomCell) => Object.keys(item).map((key) => (
            <div key={key}>
              <ContentKey>{t(`${key}`)} </ContentKey>
              <span>{item[key]}</span>
            </div>
          ))));
        }
        return value;
      },
      editable: true,
    },
  ];
  if (withThreeColumns) {
    header.splice(2, 0, changesRequested);
  }
  if (isCustomer) {
    header.pop();
  }
  if (maerskMode) {
    header.pop();
  }
  if (partnerAmendmentSubmitted) {
    header.pop();
    header.splice(2, 0, changesRequested);
  }

  const mismatchHeader = [
    {
      title: t('object'),
      dataIndex: 'title',
      key: 'title',
      width: '33%',
      render: (value: ICell[] | string | ICell) => value,
    },
    {
      title: isCustomer ? 'Old value' : 'Current value',
      dataIndex: 'confirmed',
      key: 'confirmed',
      width: '33%',
      render: (value: ICustomCell[] | string | ICell) => {
        if ((type === CARGO) || (type === CONTAINER)) {
          return generateCargoContainers(value as ICell);
        }
        if (Array.isArray(value)) {
          return arrayRender(value);
        }
        if (type === LOCATIONS) {
          return generateTransportPlanTitle(value as ICell);
        }
        return value;
      },
    },
    {
      title: 'New value',
      dataIndex: 'carrierConfirmed',
      key: 'carrierConfirmed',
      width: '33%',
      editable: true,
      render: (value: ICustomCell[] | string | ICell, record: any) => {
        if (Array.isArray(value)) {
          return arrayRender(value);
        }
        if ((type === CARGO) || (type === CONTAINER)) {
          return generateCargoContainersWithWarning(value as ICell, record.isMismatch);
        }
        return value;
      },
    },
  ];

  return {
    header: mismatchesTable ? mismatchHeader : header,
  };
};

export { useTable };
