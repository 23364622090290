import { handleRequest, get } from 'app-wrapper/utils';

interface IAsyncThunkGetParams {
  urlGetParams?: Array<string>
  getParams?: { [key: string]: string }
  urlId?: string
  url: string
  afterUrlIdPrefix?: string
}

interface IRejectWithValue {
  rejectWithValue?: (value: unknown) => void
}

export const requestGet = async <ResultType, ParamsType>(
  params: ParamsType & IAsyncThunkGetParams,
  { rejectWithValue }: IRejectWithValue,
): Promise<ResultType> => {
  const getParamsNew: string[] = [];
  if (new URLSearchParams(params.getParams).toString()) {
    getParamsNew.push(new URLSearchParams(params.getParams).toString());
  }

  const getParamsNewString = getParamsNew.length
    ? `?${getParamsNew?.join('&') || ''}`
    : '';

  const oldGetParams = params?.urlGetParams || [];
  const getParams = oldGetParams.length ? `${getParamsNewString ? `${getParamsNewString}&` : '?'}${oldGetParams.join('&') || ''}` : getParamsNewString;

  const urlId = params.urlId ? `/${params.urlId}` : '';
  const getAfterUrlIdPrefix = params.afterUrlIdPrefix ? `/${params.afterUrlIdPrefix}` : '';
  const response = await get(
    `${params.url}${urlId}${getAfterUrlIdPrefix}${getParams}`,
  );
  const result = await handleRequest<ResultType>(response, rejectWithValue);
  return result;
};
