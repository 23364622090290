import React, {
  FC,
  SyntheticEvent,
  useMemo,
  useCallback,
} from 'react';
import { useTranslation } from 'react-i18next';

import { DocumentDTM } from 'shipment-operations/models/dtm';

import {
  DocumentCollapse,
  CollapsePanel,
  CollapseIcon,
  CollapseIconActive,
  CollapseLabel,
  CollapseLabelText,
  TableCell,
  TableDocuments,
  BoldText,
  UploadIcon,
  SuccessIcon,
  WaitingIcon,
} from './DocumentsSection.styled';
import { ActionMenu, OptionalIcon } from './components';

interface DocumentsSectionProps {
  organizationId: number,
  sectionName: string;
  isCollapsed?: boolean;
  documents: DocumentDTM[];
  onDownload: (organizationId: string, documentId: number) => void;
  onRemove: (organizationId: string, documentId: number) => void;
  openDocumentUploadDrawer: () => void;
  isOptional?: boolean;
}

export const DocumentsSection: FC<DocumentsSectionProps> = ({
  organizationId,
  sectionName,
  documents,
  isCollapsed,
  onDownload,
  onRemove,
  openDocumentUploadDrawer,
  isOptional,
}) => {
  const { t } = useTranslation();

  const handleOpenDocumentUploadDrawer = useCallback((e: SyntheticEvent) => {
    e.stopPropagation();

    openDocumentUploadDrawer();
  }, [openDocumentUploadDrawer]);

  const columns = useMemo(() => [
    {
      title: t('Document Name'),
      width: '80%',
      key: 'object',
      align: 'left' as 'left',
      render: (item: string, record: DocumentDTM | {}) => {
        const document = record as DocumentDTM;

        return (
          <BoldText>
            {document.name}
          </BoldText>
        );
      },
    },
    {
      title: t('Action'),
      width: '20%',
      key: 'object',
      align: 'right' as 'right',
      render: (item: string, record: DocumentDTM | {}) => {
        const document = record as DocumentDTM;

        const handleDelete = onRemove ? () => onRemove(String(organizationId), document.id) : undefined;
        const handleDownload = onDownload ? () => onDownload(String(organizationId), document.id) : undefined;

        return (
          <TableCell>
            <ActionMenu
              onDownload={handleDownload}
              onDelete={handleDelete}
            />
          </TableCell>
        );
      },
    },
  ], [t, organizationId]);

  return (
    <DocumentCollapse>
      <CollapsePanel
        key={sectionName}
        header={(
          <CollapseLabel>
            {isCollapsed ? (
              <CollapseIconActive />
            ) : (
              <CollapseIcon />
            )}

            <CollapseLabelText isCollapsed={isCollapsed}>
              {sectionName}

              {isOptional ? (
                <OptionalIcon />
              ) : (
                <>
                  {documents.length ? (
                    <SuccessIcon />
                  ) : (
                    <WaitingIcon />
                  )}
                </>
              )}
            </CollapseLabelText>

            <UploadIcon onClick={handleOpenDocumentUploadDrawer} />
          </CollapseLabel>
        )}
      >
        <TableDocuments
          pagination={false}
          columns={columns}
          dataSource={documents}
        />
      </CollapsePanel>
    </DocumentCollapse>
  );
};
