import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'app-wrapper/createSlice';

import { DateDtm } from 'app-wrapper/models/dtm';

import { TSurchargesRatesModalState } from 'monetary/models/states';
import { ECarrierSCAC } from 'monetary/constants';
import { FreightSelectFieldDTM } from 'monetary/models/dtm';

const initialState: TSurchargesRatesModalState = {
  isLoading: false,
  isOpen: false,
  date: undefined,
  dateError: undefined,
  originLocation: undefined,
  originLocationError: undefined,
  destinationLocation: undefined,
  destinationLocationError: undefined,
  isOriginLocationSearchListLoading: false,
  originLocationSearchList: [],
  isDestinationLocationSearchListLoading: false,
  destinationLocationSearchList: [],
  carrierScacList: [],
};

export const surchargesRatesSlice = createSlice({
  name: 'SurchargesRatesModal',
  initialState,
  reducers: {
    setOriginLocationSearchListLoading: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      isOriginLocationSearchListLoading: payload,
    }),
    setDestinationLocationSearchListLoading: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      isDestinationLocationSearchListLoading: payload,
    }),
    setLoading: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      isLoading: payload,
    }),
    setOpen: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      isOpen: payload,
    }),
    setDate: (state, { payload }: PayloadAction<DateDtm>) => ({
      ...state,
      date: payload,
    }),
    clearDate: (state) => ({
      ...state,
      date: undefined,
    }),
    setDateError: (state, { payload }: PayloadAction<Error>) => ({
      ...state,
      dateError: payload,
    }),
    clearDateError: (state) => ({
      ...state,
      dateError: undefined,
    }),
    setOriginLocation: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      originLocation: payload,
    }),
    clearOriginLocation: (state) => ({
      ...state,
      originLocation: undefined,
    }),
    setOriginLocationError: (state, { payload }: PayloadAction<Error>) => ({
      ...state,
      originLocationError: payload,
    }),
    clearOriginLocationError: (state) => ({
      ...state,
      originLocationError: undefined,
    }),
    setDestinationLocation: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      destinationLocation: payload,
    }),
    clearDestinationLocation: (state) => ({
      ...state,
      destinationLocation: undefined,
    }),
    setDestinationLocationError: (state, { payload }: PayloadAction<Error>) => ({
      ...state,
      destinationLocationError: payload,
    }),
    clearDestinationLocationError: (state) => ({
      ...state,
      destinationLocationError: undefined,
    }),
    setOriginLocationSearchList: (state, { payload }: PayloadAction<FreightSelectFieldDTM[]>) => ({
      ...state,
      originLocationSearchList: payload,
    }),
    setDestinationLocationSearchList: (state, { payload }: PayloadAction<FreightSelectFieldDTM[]>) => ({
      ...state,
      destinationLocationSearchList: payload,
    }),
    setCarrierScacList: (state, { payload }: PayloadAction<ECarrierSCAC[]>) => ({
      ...state,
      carrierScacList: payload,
    }),
  },
});

export const surchargesRatesModalActions = surchargesRatesSlice.actions;
export const surchargesRatesModalReducer = surchargesRatesSlice.reducer;
