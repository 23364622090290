import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { CustomModal } from 'shipment-operations/view/components';
import { TypeSwitch } from 'app-wrapper/view/components';
import { SkeletonSection } from 'app-wrapper/view/components/Skeleton/Skeleton.component';

import {
  TypeSwitchWrap,
  Content,
  ContentSkeletons,
  BookingDrawerDivider,
} from './BookingDrawer.styled';
import {
  StepsHeader,
  Footer,
  Commodity,
  TemperatureControl,
  TermsAndConditions,
  ContainersDetails,
  ShipperInformation,
  ShipperReference,
  ShipmentAdditionalServices,
} from './components';
import { ConsigneeInformationContainer } from './components/ConsigneeInformation';

interface BookingDrawerComponentProps {
  isOpen: boolean;
  onClose: () => void;
  shipmentId: string;
  init: (shipmentId: string) => void;
  hasTemperatureControl: boolean;
  isLoading: boolean;
  currentStep: number;
  shouldShowShipperInformation: boolean;
  shouldShowConsigneeInformation: boolean;
}

export const BookingDrawerComponent: FC<BookingDrawerComponentProps> = ({
  isOpen,
  onClose,
  shipmentId,
  init,
  isLoading,
  hasTemperatureControl,
  currentStep,
  shouldShowShipperInformation,
  shouldShowConsigneeInformation,
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (isOpen && shipmentId) {
      init(shipmentId);
    }
  }, [isOpen, shipmentId]);

  return isOpen ? (
    <CustomModal
      title={t('New Booking')}
      onClose={onClose}
      contentStyles={{
        left: 'unset',
        width: '1137px',
      }}
      childrenWrapperStyles={{
        position: 'relative',
        height: 'calc(100vh - 56px)',
        overflow: 'hidden',
      }}
      headerContent={(
        <>
          {/* Currently hide */}
          {false && (
            <TypeSwitchWrap>
              <TypeSwitch
                value={false}
                data-class="switchMetric"
                leftText={`${t('lbs')}/${t('inch')}`}
                rightText={`${t('kg')}/${t('cm')}`}
                disabledbg="true"
              />
            </TypeSwitchWrap>
          )}
        </>
      )}
    >
      <StepsHeader />

      {isLoading ? (
        <ContentSkeletons>
          <SkeletonSection height="143px" />
          <SkeletonSection height="55px" />
          <SkeletonSection height="56px" />
          <SkeletonSection height="66px" />
        </ContentSkeletons>
      ) : (
        <>
          {currentStep === 1 ? (
            <Content>
              <Commodity />

              {hasTemperatureControl ? <TemperatureControl /> : null}

              <ContainersDetails />

              <ShipperReference />

              {shouldShowShipperInformation ? (
                <ShipperInformation />
              ) : null}

              {shouldShowConsigneeInformation ? (
                <ConsigneeInformationContainer />
              ) : null}

              <BookingDrawerDivider />

              <ShipmentAdditionalServices />
            </Content>
          ) : (
            <TermsAndConditions />
          )}
        </>
      )}

      <Footer />
    </CustomModal>
  ) : null;
};
