import message from 'antd/es/message';
import i18n from 'i18next';
import { BaseController, controller } from 'proto/BaseController';
import { v4 as uuidv4 } from 'uuid';

import { R } from 'shipment-operations/repository';
import { WizardContactBookInitialState } from 'shipment-operations/repository/store/BookingWizard';
import { UC as UCSippingParty } from 'shipment-operations/controllers';

import { validationEmail, validationPhone } from 'app-wrapper/utils';
import { REQUEST_STATUS } from 'app-wrapper/constants';

import { EAccountDrawerContent } from 'user-management/constants';
import { ContactBookInitialState } from 'user-management/repository/store/ContactBook';
import {
  IGetCompanyListAddressRequest, IGetCompanyListContactRequest, IPostAddressContactPersonRequest, IPostCompanyListAddressRequest, TGetCompanyListRequest,
} from 'user-management/models/contracts';
import { CompanyCollectedDTM, EmptyCompanyAddressDTMFactory, EmptyCompanyContactDTMFactory } from 'user-management/models/dtm';
import { R as userManagementR } from 'user-management/repository';
import { UC as userManagementUC } from 'user-management/controllers';

import { NetworkErrorProto } from 'app-wrapper/models/errors';
import {
  AddressDTM,
  CompanyAddressDTM,
  CompanyContactDTM, CompanyDTM,
  ContactBookDefaultStateCompanyListStateDTM,
  ContactDTM,
  ICompanyAddressDTM,
  ShippingPartyAddressDTM,
} from 'shipment-operations/models/dtm';

import { shippingPartiesAddressValidate, shippingPartiesCompanyValidate, shippingPartiesContactValidate } from '../ShippingPartiesController/ShippingParties.validate';

const defaultPort = 'USOAK';

@controller
export class BookingWizardDrawerController extends BaseController {
  public openAddCompanyDrawer = () => {
    const customId = uuidv4();
    const addressId = uuidv4();
    const contactId = uuidv4();

    this.dispatch(R.actions.bookingWizard.setWCBUpdateCompanyList([]));
    this.dispatch(R.actions.bookingWizard.setWCBUpdateAddCompanyList(ContactBookDefaultStateCompanyListStateDTM.fromPlain({
      ...WizardContactBookInitialState.updateState.companyList?.[0],
      customId,
      addressesIds: [addressId],
      contactPersonsIds: [contactId],
    })));
    // @ts-ignore wrong DTM
    this.dispatch(R.actions.bookingWizard.setWCBUpdateAddCompanyListAddresses(EmptyCompanyAddressDTMFactory.build({
      customId: addressId,
    })));
    // @ts-ignore wrong DTM
    this.dispatch(R.actions.bookingWizard.setWCBUpdateAddCompanyListContactPersons(EmptyCompanyContactDTMFactory.build({
      customId: contactId,
    })));

    this.dispatch(R.actions.bookingWizard.setWCBUpdateSetActiveListTabIndex(customId));

    this.dispatch(userManagementR.actions.accountDrawer.openDrawer(EAccountDrawerContent.BOOKING_ADD_COMPANY));
  }

  public openSPAddCompanyDrawer = () => {
    const customId = uuidv4();
    const addressId = uuidv4();
    const contactId = uuidv4();

    this.dispatch(R.actions.bookingWizard.setWCBUpdateCompanyList([]));
    this.dispatch(R.actions.bookingWizard.setWCBUpdateAddCompanyList(ContactBookDefaultStateCompanyListStateDTM.fromPlain({
      ...WizardContactBookInitialState.updateState.companyList?.[0],
      customId,
      addressesIds: [addressId],
      contactPersonsIds: [contactId],
    })));
    // @ts-ignore wrong DTM
    this.dispatch(R.actions.bookingWizard.setWCBUpdateAddCompanyListAddresses(EmptyCompanyAddressDTMFactory.build({
      customId: addressId,
    })));
    // @ts-ignore wrong DTM
    this.dispatch(R.actions.bookingWizard.setWCBUpdateAddCompanyListContactPersons(EmptyCompanyContactDTMFactory.build({
      customId: contactId,
    })));

    this.dispatch(R.actions.bookingWizard.setWCBUpdateSetActiveListTabIndex(customId));

    this.dispatch(userManagementR.actions.accountDrawer.openDrawer(EAccountDrawerContent.SHIPMENT_PARTY_ADD_COMPANY));
  }

  public openSPAddAddressDrawer = () => {
    const customId = uuidv4();
    const addressId = uuidv4();
    const contactId = uuidv4();

    this.dispatch(R.actions.bookingWizard.setWCBUpdateCompanyList([]));
    this.dispatch(R.actions.bookingWizard.setWCBUpdateAddCompanyList(ContactBookDefaultStateCompanyListStateDTM.fromPlain({
      ...WizardContactBookInitialState.updateState.companyList?.[0],
      customId,
      addressesIds: [addressId],
      contactPersonsIds: [contactId],
    })));
    // @ts-ignore wrong DTM
    this.dispatch(R.actions.bookingWizard.setWCBUpdateAddCompanyListAddresses(EmptyCompanyAddressDTMFactory.build({
      customId: addressId,
    })));
    // @ts-ignore wrong DTM
    this.dispatch(R.actions.bookingWizard.setWCBUpdateAddCompanyListContactPersons(EmptyCompanyContactDTMFactory.build({
      customId: contactId,
    })));

    this.dispatch(R.actions.bookingWizard.setWCBUpdateSetActiveListTabIndex(customId));

    this.dispatch(userManagementR.actions.accountDrawer.openDrawer(EAccountDrawerContent.SHIPMENT_PARTY_ADD_ADDRESS));
  }

  public openSPAddContactDrawer = () => {
    const customId = uuidv4();
    const addressId = uuidv4();
    const contactId = uuidv4();

    this.dispatch(R.actions.bookingWizard.setWCBUpdateCompanyList([]));
    this.dispatch(R.actions.bookingWizard.setWCBUpdateAddCompanyList(ContactBookDefaultStateCompanyListStateDTM.fromPlain({
      ...WizardContactBookInitialState.updateState.companyList?.[0],
      customId,
      addressesIds: [addressId],
      contactPersonsIds: [contactId],
    })));
    // @ts-ignore wrong DTM
    this.dispatch(R.actions.bookingWizard.setWCBUpdateAddCompanyListAddresses(EmptyCompanyAddressDTMFactory.build({
      customId: addressId,
    })));
    // @ts-ignore wrong DTM
    this.dispatch(R.actions.bookingWizard.setWCBUpdateAddCompanyListContactPersons(EmptyCompanyContactDTMFactory.build({
      customId: contactId,
    })));

    this.dispatch(R.actions.bookingWizard.setWCBUpdateSetActiveListTabIndex(customId));

    this.dispatch(userManagementR.actions.accountDrawer.openDrawer(EAccountDrawerContent.SHIPMENT_PARTY_ADD_CONTACT));
  }

  public onWCBAddCompany = async () => {
    const { bookingWizard: { wizardContactBook: { updateState } } } = this.store.getState();

    if (this.validateDocumentsFiles()) {
      this.setAllVisited();
      return;
    }

    this.dispatch(R.actions.bookingWizard.setWCBDefaultGetRequestStatusStatus(REQUEST_STATUS.pending));

    this.setAllVisited(false);

    const companyListId = updateState.activeListTabIndex;

    const itemList = updateState.companyList.filter((item) => item.customId === companyListId)?.[0];

    let contactPersons: IGetCompanyListContactRequest[] | null = null;
    contactPersons = updateState?.companyListContactPersons
      ?.filter((item) => itemList.contactPersonsIds?.includes(item.customId || ''))
      .map((contactItem, contactIndex) => ({
        phone: contactItem.phone || null,
        phone2: contactItem.phone2 || null,
        fullName: contactItem.fullName || null,
        email: contactItem.email || null,
        id: contactItem.id || null,
        primary: contactIndex === 0,
      }))
      .filter((contactItem) => !!(contactItem?.phone
        || contactItem?.fullName
        || contactItem?.email
        || contactItem?.phone2));

    let addresses: IGetCompanyListAddressRequest[] | null = null;
    addresses = updateState?.companyListAddresses
      ?.filter((item) => itemList.addressesIds?.includes(item.customId || ''))
      ?.map((addressItem, addressIndex) => ({
        address1: addressItem.address1 || null,
        address2: addressItem.address2 || null,
        city: addressItem.city || null,
        country: addressItem.country || null,
        postalCode: addressItem.postalCode || null,
        state: addressItem.state || null,
        // TODO: fixed required closestProt in be
        closestPort: addressItem.closestPort || defaultPort,
        id: addressItem.id || null,
        company: addressItem.company || null,
        primary: addressIndex === 0,
        contacts: contactPersons || undefined,
      }))
      .filter((addressItem) => !!(addressItem.address1
        || addressItem.address2
        || addressItem.city
        || addressItem.country
        || addressItem.postalCode
        || addressItem.state
      ));

    const companyDataRequest: TGetCompanyListRequest = {
      name: itemList.name,
      taxId: itemList.taxId || null,
      id: itemList.id || null,
      addresses,
      contacts: null,
    };

    let response: CompanyCollectedDTM | undefined;

    try {
      if (itemList.id) {
        response = await R.services.contacts.putCompanyList(itemList.id, companyDataRequest);
      } else {
        response = await R.services.contacts.postCompanyList(companyDataRequest);
      }
    } catch (e) {
      if (e instanceof NetworkErrorProto) {
        console.error('onWCBAddCompany', e.getErrorMessage(), companyListId);
        message.error(e.getErrorMessage());

        this.dispatch(R.actions.bookingWizard.setWCBUpdateErrorsCompanyListStateNameById({
          indexList: companyListId,
          error: {
            message: e.getErrorMessage(),
            isTooltipVisible: true,
          },
        }));

        this.dispatch(R.actions.bookingWizard.setWCBDefaultGetRequestStatusStatus(REQUEST_STATUS.rejected));

        return;
      }

      this.dispatch(R.actions.bookingWizard.setWCBDefaultGetRequestStatusStatus(REQUEST_STATUS.rejected));

      return;
    }

    if (response) {
      const { bookingWizard: { companiesList } } = this.store.getState();

      // @ts-ignore wrong DTM
      this.dispatch(R.actions.bookingWizard.setCompaniesList([
        ...companiesList,
        ...response.companyList,
      ]));
      const company = response.companyList.find(({ id }) => id);

      // @ts-ignore wrong DTM
      this.dispatch(R.actions.bookingWizard.setSelectedCompany(company || null));
      this.dispatch(R.actions.bookingWizard.setCompanyError(false));
    }

    this.dispatch(userManagementR.actions.accountDrawer.closeDrawer());

    message.success(i18n.t('Company is Added'));

    this.dispatch(R.actions.bookingWizard.setWCBDefaultGetRequestStatusStatus(REQUEST_STATUS.complete));
  }

  public onCBAddCompany = async () => {
    const { bookingWizard: { wizardContactBook: { updateState } } } = this.store.getState();

    if (this.validateDocumentsFiles()) {
      this.setAllVisited();
      return;
    }

    this.dispatch(R.actions.bookingWizard.setWCBDefaultGetRequestStatusStatus(REQUEST_STATUS.pending));

    this.setAllVisited(false);

    const companyListId = updateState.activeListTabIndex;

    const itemList = updateState.companyList.filter((item) => item.customId === companyListId)?.[0];

    let contactPersons: IGetCompanyListContactRequest[] | null = null;
    contactPersons = updateState?.companyListContactPersons
      ?.filter((contactItem) => itemList.contactPersonsIds?.includes(contactItem.customId || '')
        && !!(contactItem?.phone
          || contactItem?.fullName
          || contactItem?.email
          || contactItem?.phone2))
      .map((contactItem, contactIndex) => ({
        phone: contactItem.phone || null,
        phone2: contactItem.phone2 || null,
        fullName: contactItem.fullName || null,
        email: contactItem.email || null,
        id: contactItem.id || null,
        primary: contactIndex === 0,
      }));

    let addresses: IGetCompanyListAddressRequest[] | null = null;
    addresses = updateState?.companyListAddresses
      ?.filter((addressItem) => itemList.addressesIds?.includes(addressItem.customId || '')
        && !!(addressItem.address1
          || addressItem.address2
          || addressItem.city
          || addressItem.country
          || addressItem.postalCode
          || addressItem.state
        ))
      ?.map((addressItem, addressIndex) => ({
        address1: addressItem.address1 || null,
        address2: addressItem.address2 || null,
        city: addressItem.city || null,
        country: addressItem.country || null,
        postalCode: addressItem.postalCode || null,
        state: addressItem.state || null,
        // TODO: fixed required closestProt in be
        closestPort: addressItem.closestPort || defaultPort,
        id: addressItem.id || null,
        company: addressItem.company || null,
        primary: addressIndex === 0,
        contacts: contactPersons || undefined,
      }));

    const companyDataRequest: TGetCompanyListRequest = {
      name: itemList.name,
      taxId: itemList.taxId || null,
      id: itemList.id || null,
      addresses,
      contacts: null,
    };

    let response: CompanyCollectedDTM | undefined;

    try {
      if (itemList.id) {
        response = await R.services.contacts.putCompanyList(itemList.id, companyDataRequest);
      } else {
        response = await R.services.contacts.postCompanyList(companyDataRequest);
      }
    } catch (e) {
      if (e instanceof NetworkErrorProto) {
        console.error('onWCBAddCompany', e.getErrorMessage(), companyListId);
        message.error(e.getErrorMessage());

        this.dispatch(R.actions.bookingWizard.setWCBUpdateErrorsCompanyListStateNameById({
          indexList: companyListId,
          error: {
            message: e.getErrorMessage(),
            isTooltipVisible: true,
          },
        }));

        this.dispatch(R.actions.bookingWizard.setWCBDefaultGetRequestStatusStatus(REQUEST_STATUS.rejected));

        return;
      }

      this.dispatch(R.actions.bookingWizard.setWCBDefaultGetRequestStatusStatus(REQUEST_STATUS.rejected));

      return;
    }

    if (response) {
      const customId = uuidv4();
      const addressId = uuidv4();
      const contactId = uuidv4();

      const { contactBook } = this.store.getState();
      const companyIds = response.companyList.map((item) => item.id);

      if (response?.companyList?.[0]) {
        this.dispatch(userManagementR.actions.contactBook.setUpdateAddCompanyList(ContactBookDefaultStateCompanyListStateDTM.fromPlain({
          ...ContactBookInitialState.updateState.companyList?.[0],
          ...itemList,
          id: companyIds?.[0],
          customId,
          isHide: true,
          addressesIds: [addressId],
          contactPersonsIds: [contactId],
        })));

        this.dispatch(userManagementR.actions.contactBook.setUpdateCompanyListAddresses([
          ...contactBook.updateState.companyListAddresses,
          ...updateState?.companyListAddresses
            ?.filter((addressItem) => itemList.addressesIds?.includes(addressItem.customId || '')) || [],
        ].map((item) => CompanyAddressDTM.fromPlain(item))));
        this.dispatch(userManagementR.actions.contactBook.setUpdateCompanyListContactPersons([
          ...contactBook.updateState.companyListContactPersons,
          ...updateState?.companyListContactPersons
            ?.filter((contactItem) => itemList.contactPersonsIds?.includes(contactItem.customId || '')) || [],
        ].map((item) => CompanyContactDTM.fromPlain(item))));
      }
    }

    this.dispatch(userManagementR.actions.accountDrawer.closeDrawer());

    message.success(i18n.t('Company is Added'));

    await userManagementUC.contactBook.onRunComponent();

    this.dispatch(R.actions.bookingWizard.setWCBDefaultGetRequestStatusStatus(REQUEST_STATUS.complete));
  }

  public onSPAddCompany = async () => {
    const { bookingWizard: { wizardContactBook: { updateState } } } = this.store.getState();

    if (this.validateDocumentsFiles()) {
      this.setAllVisited();
      return;
    }

    this.dispatch(R.actions.bookingWizard.setWCBDefaultGetRequestStatusStatus(REQUEST_STATUS.pending));

    this.setAllVisited(false);

    const companyListId = updateState.activeListTabIndex;

    const itemList = updateState.companyList.filter((item) => item.customId === companyListId)?.[0];

    let contactPersons: IGetCompanyListContactRequest[] | null = null;
    contactPersons = updateState?.companyListContactPersons
      ?.filter((contactItem) => itemList.contactPersonsIds?.includes(contactItem.customId || '')
        && !!(contactItem?.phone
          || contactItem?.fullName
          || contactItem?.email
          || contactItem?.phone2))
      .map((contactItem, contactIndex) => ({
        phone: contactItem.phone || null,
        phone2: contactItem.phone2 || null,
        fullName: contactItem.fullName || null,
        email: contactItem.email || null,
        id: contactItem.id || null,
        primary: contactIndex === 0,
      }));

    let addresses: IGetCompanyListAddressRequest[] | null = null;
    addresses = updateState?.companyListAddresses
      ?.filter((addressItem) => itemList.addressesIds?.includes(addressItem.customId || '')
        && !!(addressItem.address1
          || addressItem.address2
          || addressItem.city
          || addressItem.country
          || addressItem.postalCode
          || addressItem.state
        ))
      ?.map((addressItem, addressIndex) => ({
        address1: addressItem.address1 || null,
        address2: addressItem.address2 || null,
        city: addressItem.city || null,
        country: addressItem.country || null,
        postalCode: addressItem.postalCode || null,
        state: addressItem.state || null,
        // TODO: fixed required closestProt in be
        closestPort: addressItem.closestPort || defaultPort,
        id: addressItem.id || null,
        company: addressItem.company || null,
        primary: addressIndex === 0,
        contacts: contactPersons || undefined,
      }));

    const companyDataRequest: TGetCompanyListRequest = {
      name: itemList.name,
      taxId: itemList.taxId || null,
      id: itemList.id || null,
      addresses,
      contacts: null,
    };

    let response: CompanyCollectedDTM | undefined;

    try {
      if (itemList.id) {
        response = await R.services.contacts.putCompanyList(itemList.id, companyDataRequest);
      } else {
        response = await R.services.contacts.postCompanyList(companyDataRequest);
      }
    } catch (e) {
      if (e instanceof NetworkErrorProto) {
        console.error('onWCBAddCompany', e.getErrorMessage(), companyListId);
        message.error(e.getErrorMessage());

        this.dispatch(R.actions.bookingWizard.setWCBUpdateErrorsCompanyListStateNameById({
          indexList: companyListId,
          error: {
            message: e.getErrorMessage(),
            isTooltipVisible: true,
          },
        }));

        this.dispatch(R.actions.bookingWizard.setWCBDefaultGetRequestStatusStatus(REQUEST_STATUS.rejected));

        return;
      }

      this.dispatch(R.actions.bookingWizard.setWCBDefaultGetRequestStatusStatus(REQUEST_STATUS.rejected));

      return;
    }

    let companyId: number | undefined;

    if (response) {
      const { shippingParties: { companyList } } = this.store.getState();
      const companyIds = response.companyList.map((item) => item.id);

      companyId = companyIds?.[0];

      // @ts-ignore fix it
      this.dispatch(R.actions.shippingParties.setCompanyList([
        ...companyList?.filter((item) => !companyIds.includes(item.id)) || [],
        ...response?.companyList || [],
      ]));
    }

    if (companyId) {
      this.setCompanyName(companyId);
    }

    this.dispatch(userManagementR.actions.accountDrawer.closeDrawer());

    message.success(i18n.t('Company is Added'));

    this.dispatch(R.actions.bookingWizard.setWCBDefaultGetRequestStatusStatus(REQUEST_STATUS.complete));
  }

  public onSPAddAddress = async () => {
    const { bookingWizard: { wizardContactBook: { updateState } } } = this.store.getState();
    const selectedShippingParty = R.selectors.shippingParties.getSelectedShippingParty(this.store.getState());

    if (this.validateAddressDocumentsFiles()) {
      this.setAllVisited();
      return;
    }

    this.dispatch(R.actions.bookingWizard.setWCBDefaultGetRequestStatusStatus(REQUEST_STATUS.pending));

    this.setAllVisited(false);

    let response: ICompanyAddressDTM | undefined;
    const companyListId = updateState.activeListTabIndex;
    const companySelect = selectedShippingParty.company;

    const itemList = updateState.companyList.filter((item) => item.customId === companyListId)?.[0];

    let addresses: IGetCompanyListAddressRequest[] | null = null;
    addresses = updateState?.companyListAddresses
      ?.filter((addressItem) => itemList?.addressesIds?.includes(addressItem.customId || '')
        && !!(addressItem.address1
          || addressItem.address2
          || addressItem.city
          || addressItem.country
          || addressItem.postalCode
          || addressItem.state
        ))
      .map((addressItem) => ({
        address1: addressItem.address1 || null,
        address2: addressItem.address2 || null,
        city: addressItem.city || null,
        country: addressItem.country || null,
        postalCode: addressItem.postalCode || null,
        state: addressItem.state || null,
        // TODO: fixed required closestProt in be
        closestPort: addressItem.closestPort || defaultPort,
        id: addressItem.id || null,
        customId: addressItem.customId,
        company: addressItem.company || null,
        primary: !!addressItem.isPrimary,
      }));

    const contactPersons: IGetCompanyListContactRequest[] | undefined = updateState?.companyListContactPersons
      ?.filter((contactItem) => itemList?.contactPersonsIds?.includes(contactItem.customId || '')
        && !!(contactItem?.phone
          || contactItem?.fullName
          || contactItem?.email
          || contactItem?.phone2))
      .map((contactItem, contactIndex) => ({
        phone: contactItem.phone || null,
        phone2: contactItem.phone2 || null,
        fullName: contactItem.fullName || null,
        email: contactItem.email || null,
        id: contactItem.id || null,
        customId: contactItem.customId,
        primary: contactIndex === 0,
      }));

    if (!addresses?.[0]) {
      return;
    }
    const companyDataRequest: IPostCompanyListAddressRequest = {
      ...addresses?.[0],
      contacts: contactPersons.length ? contactPersons : undefined,
      id: companySelect?.id,
    };

    try {
      if (companySelect?.id) {
        response = await R.services.contacts.postAddAddress(companySelect?.id, companyDataRequest);
      }
    } catch (e) {
      if (e instanceof NetworkErrorProto) {
        console.error('onSPAddAddress', e.getErrorMessage());
        message.error(e.getErrorMessage());

        this.dispatch(R.actions.bookingWizard.setWCBDefaultGetRequestStatusStatus(REQUEST_STATUS.rejected));

        return;
      }

      this.dispatch(R.actions.bookingWizard.setWCBDefaultGetRequestStatusStatus(REQUEST_STATUS.rejected));

      return;
    }

    if (response) {
      const companiesList = R.selectors.shippingParties.getOwnCompaniesList(this.store.getState());
      const company = companiesList.find(({ id }) => id === selectedShippingParty?.company?.id);

      if (company) {
        this.dispatch(R.actions.shippingParties.setUpdatedCompany(CompanyDTM.fromPlain({
          ...company,
          addresses: [
            ...(company.addresses || []),
            CompanyAddressDTM.fromPlain({
              id: response.id,
              country: response.country,
              state: response.state,
              city: response.city,
              address1: response.address1,
              address2: response.address2,
              postalCode: response.postalCode,
              closestPort: response.closestPort,
              contacts: [],
              isPrimary: response.isPrimary,
              company: '',
            }),
          ],
          contacts: [
            ...(company.contacts || []),
            ...(response?.contacts ? response.contacts.map((contact) => CompanyContactDTM.fromPlain({
              id: contact.id,
              fullName: contact.fullName,
              email: contact.email,
              phone: contact.phone,
              phone2: contact.phone2,
              primary: contact.primary,
            })) : []),
          ],
        })));
      }

      this.dispatch(R.actions.shippingParties.setAddressList([
        ...selectedShippingParty.addressList,
        AddressDTM.fromPlain({
          id: response.id,
          country: response.country,
          state: response.state,
          city: response.city,
          address1: response.address1,
          address2: response.address2,
          postalCode: response.postalCode,
          closestPort: response.closestPort,
          contacts: response?.contacts?.map((item) => ContactDTM.fromPlain({
            id: item.id,
            fullName: item.fullName,
            email: item.email,
            phone: item.phone,
            phone2: item.phone2,
            primary: item.primary,
          })) || [],
          primary: response.isPrimary,
        }),
      ]));

      this.setAddress(response.id);
    }

    if (response?.contacts?.[0]) {
      const contact = ContactDTM.fromPlain({
        id: response?.contacts?.[0].id,
        fullName: response?.contacts?.[0].fullName,
        email: response?.contacts?.[0].email,
        phone: response?.contacts?.[0].phone,
        phone2: response?.contacts?.[0].phone2,
        primary: response?.contacts?.[0].primary,
      });
      this.dispatch(R.actions.shippingParties.setContactsList([
        ...selectedShippingParty.contactList,
        contact,
      ]));

      this.dispatch(R.actions.shippingParties.setContact(contact));
    }

    this.dispatch(userManagementR.actions.accountDrawer.closeDrawer());

    message.success(i18n.t('Address is Added'));

    this.dispatch(R.actions.bookingWizard.setWCBDefaultGetRequestStatusStatus(REQUEST_STATUS.complete));
  }

  public onSPAddContact = async () => {
    const { bookingWizard: { wizardContactBook: { updateState } } } = this.store.getState();
    const selectedShippingParty = R.selectors.shippingParties.getSelectedShippingParty(this.store.getState());

    if (this.validateContactDocumentsFiles()) {
      this.setAllVisited();
      return;
    }

    this.dispatch(R.actions.bookingWizard.setWCBDefaultGetRequestStatusStatus(REQUEST_STATUS.pending));

    this.setAllVisited(false);

    const companySelect = selectedShippingParty.company;
    const companyListId = updateState.activeListTabIndex;

    const itemList = updateState.companyList.filter((item) => item.customId === companyListId)?.[0];

    const contactPersons: IGetCompanyListContactRequest | undefined = updateState?.companyListContactPersons
      ?.filter((contactItem) => itemList?.contactPersonsIds?.includes(contactItem.customId || '')
        && !!(contactItem?.phone
          || contactItem?.fullName
          || contactItem?.email
          || contactItem?.phone2))
      .map((contactItem) => ({
        phone: contactItem.phone || null,
        phone2: contactItem.phone2 || null,
        fullName: contactItem.fullName || null,
        email: contactItem.email || null,
        id: contactItem.id || null,
        customId: contactItem.customId,
        primary: contactItem.primary,
      }))?.[0];

    let response: CompanyContactDTM | undefined;

    const request: IPostAddressContactPersonRequest = {
      email: contactPersons?.email || '',
      fullName: contactPersons?.fullName || '',
      phone: contactPersons?.phone || '',
      phone2: contactPersons?.phone2 || '',
      primary: contactPersons?.primary,
    };

    const companiesList = R.selectors.shippingParties.getOwnCompaniesList(this.store.getState());
    const company = companiesList.find(({ id }) => id === selectedShippingParty?.company?.id);
    const primaryAddress = company?.addresses.find(({ isPrimary }) => isPrimary);

    if (!primaryAddress) {
      return;
    }

    try {
      if (companySelect?.id) {
        response = await R.services.contacts.postAddressContactByID(`${companySelect?.id}`, `${primaryAddress.id}`, request);
      }
    } catch (e) {
      if (e instanceof NetworkErrorProto) {
        console.error('onSPAddContact', e.getErrorMessage());
        message.error(e.getErrorMessage());

        this.dispatch(R.actions.bookingWizard.setWCBDefaultGetRequestStatusStatus(REQUEST_STATUS.rejected));

        return;
      }

      this.dispatch(R.actions.bookingWizard.setWCBDefaultGetRequestStatusStatus(REQUEST_STATUS.rejected));

      return;
    }

    if (response) {
      const selectedShippingPartyAddressCheck = R.selectors.shippingParties.getSelectedShippingParty(this.store.getState());

      if (company) {
        this.dispatch(R.actions.shippingParties.setUpdatedCompany(CompanyDTM.fromPlain({
          ...company,
          contacts: [
            ...(company.contacts || []),
            CompanyContactDTM.fromPlain({
              id: response.id,
              fullName: response.fullName,
              email: response.email,
              phone: response.phone,
              phone2: response.phone2,
              primary: response.primary,
            }),
          ],
        })));
      }

      if (selectedShippingPartyAddressCheck.address) {
        this.dispatch(R.actions.shippingParties.setAddress(ShippingPartyAddressDTM.fromPlain({
          ...selectedShippingPartyAddressCheck.address,
          contacts: [
            ...selectedShippingPartyAddressCheck.address.contacts || [],
            ContactDTM.fromPlain({
              id: response.id,
              fullName: response.fullName,
              email: response.email,
              phone: response.phone,
              phone2: response.phone2,
              primary: response.primary,
            }),
          ],
        })));
      }

      this.dispatch(R.actions.shippingParties.setAddressList([
        ...selectedShippingParty.addressList.map((item) => {
          if (item.id === selectedShippingPartyAddressCheck?.address?.id && response) {
            return {
              ...item,
              contacts: [
                ...item.contacts || [],
                ContactDTM.fromPlain({
                  id: response.id,
                  fullName: response.fullName,
                  email: response.email,
                  phone: response.phone,
                  phone2: response.phone2,
                  primary: response.primary,
                }),
              ],
            };
          }

          return item;
        }),
      ]));

      this.dispatch(R.actions.shippingParties.setContactsList([
        ...selectedShippingParty.contactList,
        ContactDTM.fromPlain({
          id: response.id,
          fullName: response.fullName,
          email: response.email,
          phone: response.phone,
          phone2: response.phone2,
          primary: response.primary,
        }),
      ]));

      this.setContactPerson(response.id);
    }

    this.dispatch(userManagementR.actions.accountDrawer.closeDrawer());

    message.success(i18n.t('Contact is Added'));

    this.dispatch(R.actions.bookingWizard.setWCBDefaultGetRequestStatusStatus(REQUEST_STATUS.complete));
  }

  public setCompanyName = async (companyId: number) => {
    UCSippingParty.shippingParties.setCompanyName(companyId);

    this.validateCompanyName();
  }

  public validateCompanyName = () => {
    const selectedCompany = R.selectors.shippingParties.getSelectedCompany(this.store.getState());

    this.dispatch(R.actions.shippingParties.clearCompanyErrorForSelectedForm());
    const companyError = shippingPartiesCompanyValidate(selectedCompany);
    if (companyError) {
      this.dispatch(R.actions.shippingParties.setCompanyErrorForSelectedForm(companyError));
    }
  };

  public setContactPerson = async (contactId: number) => {
    UCSippingParty.shippingParties.setContactPerson(contactId);

    this.validateContactPerson();
  }

  public validateContactPerson = () => {
    const contact = R.selectors.shippingParties.getSelectedContact(this.store.getState());

    this.dispatch(R.actions.shippingParties.clearContactErrorForSelectedForm());

    const contactError = shippingPartiesContactValidate(contact);
    if (contactError) {
      this.dispatch(R.actions.shippingParties.setContactErrorForSelectedForm(contactError));
    }
  };

  public setAddress = async (addressId: number) => {
    UCSippingParty.shippingParties.setAddress(addressId);

    this.validateAddress();
  }

  public validateAddress = () => {
    const address = R.selectors.shippingParties.getSelectedAddress(this.store.getState());

    this.dispatch(R.actions.shippingParties.clearAddressErrorForSelectedForm());

    const addressError = shippingPartiesAddressValidate(address);
    if (addressError) {
      this.dispatch(R.actions.shippingParties.setAddressErrorForSelectedForm(addressError));
    }
  };

  public onCancelBookingAddCompany = () => {
    this.dispatch(R.actions.bookingWizard.setWCBUpdateCompanyList([]));

    this.dispatch(userManagementR.actions.accountDrawer.closeDrawer());
  }

  public onCancelShipmentPartyAddCompany = () => {
    this.dispatch(R.actions.bookingWizard.setWCBUpdateCompanyList([]));

    this.dispatch(userManagementR.actions.accountDrawer.closeDrawer());
  }

  public onCancelShipmentPartyAddAddress = () => {
    this.dispatch(R.actions.bookingWizard.setWCBUpdateCompanyList([]));

    this.dispatch(userManagementR.actions.accountDrawer.closeDrawer());
  }

  public onCancelShipmentPartyAddContact = () => {
    this.dispatch(R.actions.bookingWizard.setWCBUpdateCompanyList([]));

    this.dispatch(userManagementR.actions.accountDrawer.closeDrawer());
  }

  public validateOnlyShipmentPartyCompany = () => {
    this.validateDocumentsFiles();
  }

  public validateCompareShipmentPartyCompany = () => {
    this.compareUpdateState();
  }

  public validateFullShipmentPartyCompany = () => {
    this.validateDocumentsFiles();

    this.compareUpdateState();
  }

  public validateOnlyShipmentPartyAddress = () => {
    this.validateAddressDocumentsFiles();
  }

  public validateCompareShipmentPartyAddress = () => {
    this.compareUpdateAddressState();
  }

  public validateFullShipmentPartyAddress = () => {
    this.validateAddressDocumentsFiles();

    this.compareUpdateAddressState();
  }

  public validateOnlyShipmentPartyContactPerson = () => {
    this.validateContactDocumentsFiles();
  }

  public validateCompareShipmentPartyContactPerson = () => {
    this.compareUpdateContactState();
  }

  public validateFullShipmentPartyContactPerson = () => {
    this.validateContactDocumentsFiles();

    this.compareUpdateContactState();
  }

  public onChangeListNameByIndex = (updateCallback: Function) => (listId: string) => (name: string) => {
    this.dispatch(R.actions.bookingWizard.setWCBUpdateCompanyListById({
      listId,
      companyList: {
        name: name.slice(0, 35),
      },
    }));

    updateCallback();
  };

  public onBlurListNameByIndex = (updateCallback: Function) => (listId: string) => () => {
    this.dispatch(R.actions.bookingWizard.setWCBUpdateErrorsCompanyListStateNameById({
      indexList: listId,
      error: {
        isBlur: true,
        isVisited: true,
        isFocus: false,
      },
    }));

    updateCallback();
  };

  public onFocusListNameByIndex = (updateCallback: Function) => (listId: string) => () => {
    this.dispatch(R.actions.bookingWizard.setWCBUpdateErrorsCompanyListStateNameById({
      indexList: listId,
      error: {
        isBlur: false,
        isFocus: true,
      },
    }));

    updateCallback();
  };

  public onChangeTaxIdByIndex = (updateCallback: Function) => (listId: string) => (taxId: string) => {
    this.dispatch(R.actions.bookingWizard.setWCBUpdateCompanyListById({
      listId,
      companyList: {
        taxId: taxId.slice(0, 35),
      },
    }));

    updateCallback();
  };

  public onBlurTaxIdByIndex = (updateCallback: Function) => (listId: string) => () => {
    this.dispatch(R.actions.bookingWizard.setWCBUpdateErrorsCompanyListStateNameById({
      indexList: listId,
      error: {
        isBlur: true,
        isVisited: true,
        isFocus: false,
      },
    }));

    updateCallback();
  };

  public onFocusTaxIdByIndex = (updateCallback: Function) => (listId: string) => () => {
    this.dispatch(R.actions.bookingWizard.setWCBUpdateErrorsCompanyListStateNameById({
      indexList: listId,
      error: {
        isBlur: false,
        isFocus: true,
      },
    }));

    updateCallback();
  };

  public onChangeUpdateAddressesAddressLineFirstByIndex = (updateCallback: Function) => (indexAddresses: string) => (addressLineFirst: string) => {
    const { bookingWizard: { wizardContactBook: { updateState: { companyListAddresses } } } } = this.store.getState();

    this.dispatch(R.actions.bookingWizard.setWCBUpdateEditCompanyListAddressesById({
      listId: indexAddresses,
      companyListAddresses: CompanyAddressDTM.fromPlain({
        ...companyListAddresses.filter((item) => item.customId === indexAddresses)?.[0],
        address1: addressLineFirst.slice(0, 50),
      }),
    }));

    updateCallback();
  };

  public onBlurUpdateAddressesAddressLineFirstByIndex = (updateCallback: Function) => (indexAddresses: string) => () => {
    this.dispatch(R.actions.bookingWizard.setWCBUpdateErrorsCompanyListAddressesAddressLineFirstById({
      indexList: indexAddresses,
      error: {
        isBlur: true,
        isVisited: true,
        isFocus: false,
      },
    }));

    updateCallback();
  };

  public onFocusUpdateAddressesAddressLineFirstByIndex = (updateCallback: Function) => (indexAddresses: string) => () => {
    this.dispatch(R.actions.bookingWizard.setWCBUpdateErrorsCompanyListAddressesAddressLineFirstById({
      indexList: indexAddresses,
      error: {
        isBlur: false,
        isFocus: true,
      },
    }));

    updateCallback();
  };

  public onChangeUpdateAddressesAddressLineSecondByIndex = (updateCallback: Function) => (indexAddresses: string) => (addressLineSecond: string) => {
    const { bookingWizard: { wizardContactBook: { updateState: { companyListAddresses } } } } = this.store.getState();

    this.dispatch(R.actions.bookingWizard.setWCBUpdateEditCompanyListAddressesById({
      listId: indexAddresses,
      companyListAddresses: CompanyAddressDTM.fromPlain({
        ...companyListAddresses.filter((item) => item.customId === indexAddresses)?.[0],
        address2: addressLineSecond.slice(0, 50),
      }),
    }));

    updateCallback();
  };

  public onBlurUpdateAddressesAddressLineSecondByIndex = (updateCallback: Function) => (indexAddresses: string) => () => {
    this.dispatch(R.actions.bookingWizard.setWCBUpdateErrorsCompanyListAddressesAddressLineSecondById({
      indexList: indexAddresses,
      error: {
        isBlur: true,
        isVisited: true,
        isFocus: false,
      },
    }));

    updateCallback();
  };

  public onFocusUpdateAddressesAddressLineSecondByIndex = (updateCallback: Function) => (indexAddresses: string) => () => {
    this.dispatch(R.actions.bookingWizard.setWCBUpdateErrorsCompanyListAddressesAddressLineSecondById({
      indexList: indexAddresses,
      error: {
        isBlur: false,
        isFocus: true,
      },
    }));

    updateCallback();
  };

  public onChangeUpdateAddressesCityByIndex = (updateCallback: Function) => (indexAddresses: string) => (city: string) => {
    const { bookingWizard: { wizardContactBook: { updateState: { companyListAddresses } } } } = this.store.getState();

    this.dispatch(R.actions.bookingWizard.setWCBUpdateEditCompanyListAddressesById({
      listId: indexAddresses,
      companyListAddresses: CompanyAddressDTM.fromPlain({
        ...companyListAddresses.filter((item) => item.customId === indexAddresses)?.[0],
        city: city.slice(0, 50),
      }),
    }));

    updateCallback();
  };

  public onBlurUpdateAddressesCityByIndex = (updateCallback: Function) => (indexAddresses: string) => () => {
    this.dispatch(R.actions.bookingWizard.setWCBUpdateErrorsCompanyListAddressesCityById({
      indexList: indexAddresses,
      error: {
        isBlur: true,
        isVisited: true,
        isFocus: false,
      },
    }));

    updateCallback();
  };

  public onFocusUpdateAddressesCityByIndex = (updateCallback: Function) => (indexAddresses: string) => () => {
    this.dispatch(R.actions.bookingWizard.setWCBUpdateErrorsCompanyListAddressesCityById({
      indexList: indexAddresses,
      error: {
        isBlur: false,
        isFocus: true,
      },
    }));

    updateCallback();
  };

  public onChangeUpdateIsPrimaryByIndex = (indexAddresses: string) => (isPrimary: boolean) => {
    const { bookingWizard: { wizardContactBook: { updateState: { companyListAddresses } } } } = this.store.getState();

    const companyListAddress = companyListAddresses.filter((item) => item.customId === indexAddresses)?.[0];

    this.dispatch(R.actions.bookingWizard.setWCBUpdateEditCompanyListAddressesById({
      listId: indexAddresses,
      companyListAddresses: CompanyAddressDTM.fromPlain({
        ...companyListAddress,
        isPrimary,
      }),
    }));
  };

  public onChangeUpdateAddressesCountryByIndex = (updateCallback: Function) => (indexAddresses: string) => (country: string) => {
    const { bookingWizard: { wizardContactBook: { updateState: { companyListAddresses } } } } = this.store.getState();

    const companyListAddress = companyListAddresses.filter((item) => item.customId === indexAddresses)?.[0];

    this.dispatch(R.actions.bookingWizard.setWCBUpdateEditCompanyListAddressesById({
      listId: indexAddresses,
      companyListAddresses: CompanyAddressDTM.fromPlain({
        ...companyListAddress,
        country: country?.slice(0, 50) || '',
        state: undefined,
      }),
    }));

    updateCallback();
  };

  public onBlurUpdateAddressesCountryByIndex = (updateCallback: Function) => (indexAddresses: string) => () => {
    this.dispatch(R.actions.bookingWizard.setWCBUpdateErrorsCompanyListAddressesCountryById({
      indexList: indexAddresses,
      error: {
        isBlur: true,
        isVisited: true,
        isFocus: false,
      },
    }));

    updateCallback();
  };

  public onFocusUpdateAddressesCountryByIndex = (updateCallback: Function) => (indexAddresses: string) => () => {
    this.dispatch(R.actions.bookingWizard.setWCBUpdateErrorsCompanyListAddressesCountryById({
      indexList: indexAddresses,
      error: {
        isBlur: false,
        isFocus: true,
      },
    }));

    updateCallback();
  };

  public onClearUpdateAddressesCountryByIndex = (updateCallback: Function) => (indexAddresses: string) => () => {
    const { bookingWizard: { wizardContactBook: { updateState: { companyListAddresses } } } } = this.store.getState();

    this.dispatch(R.actions.bookingWizard.setWCBUpdateEditCompanyListAddressesById({
      listId: indexAddresses,
      companyListAddresses: CompanyAddressDTM.fromPlain({
        ...companyListAddresses.filter((item) => item.customId === indexAddresses)?.[0],
        country: '',
        state: undefined,
      }),
    }));

    updateCallback();
  };

  public onChangeUpdateAddressesStateByIndex = (updateCallback: Function) => (indexAddresses: string) => (state: string) => {
    const { bookingWizard: { wizardContactBook: { updateState: { companyListAddresses } } } } = this.store.getState();

    this.dispatch(R.actions.bookingWizard.setWCBUpdateEditCompanyListAddressesById({
      listId: indexAddresses,
      companyListAddresses: CompanyAddressDTM.fromPlain({
        ...companyListAddresses.filter((item) => item.customId === indexAddresses)?.[0],
        state: state.slice(0, 25),
      }),
    }));

    updateCallback();
  };

  public onClearUpdateAddressesStateByIndex = (updateCallback: Function) => (indexAddresses: string) => () => {
    const { bookingWizard: { wizardContactBook: { updateState: { companyListAddresses } } } } = this.store.getState();

    this.dispatch(R.actions.bookingWizard.setWCBUpdateEditCompanyListAddressesById({
      listId: indexAddresses,
      companyListAddresses: CompanyAddressDTM.fromPlain({
        ...companyListAddresses.filter((item) => item.customId === indexAddresses)?.[0],
        state: undefined,
      }),
    }));

    updateCallback();
  };

  public onBlurUpdateAddressesStateByIndex = (updateCallback: Function) => (indexAddresses: string) => () => {
    this.dispatch(R.actions.bookingWizard.setWCBUpdateErrorsCompanyListAddressesStateById({
      indexList: indexAddresses,
      error: {
        isBlur: true,
        isVisited: true,
        isFocus: false,
      },
    }));

    updateCallback();
  };

  public onFocusUpdateAddressesStateByIndex = (updateCallback: Function) => (indexAddresses: string) => () => {
    this.dispatch(R.actions.bookingWizard.setWCBUpdateErrorsCompanyListAddressesStateById({
      indexList: indexAddresses,
      error: {
        isBlur: false,
        isFocus: true,
      },
    }));

    updateCallback();
  };

  public onChangeUpdateAddressesPostalCodeByIndex = (updateCallback: Function) => (indexAddresses: string) => (postalCode: string) => {
    const { bookingWizard: { wizardContactBook: { updateState: { companyListAddresses } } } } = this.store.getState();

    this.dispatch(R.actions.bookingWizard.setWCBUpdateEditCompanyListAddressesById({
      listId: indexAddresses,
      companyListAddresses: CompanyAddressDTM.fromPlain({
        ...companyListAddresses.filter((item) => item.customId === indexAddresses)?.[0],
        postalCode: postalCode.slice(0, 10),
      }),
    }));

    updateCallback();
  };

  public onBlurUpdateAddressesPostalCodeByIndex = (updateCallback: Function) => (indexAddresses: string) => () => {
    this.dispatch(R.actions.bookingWizard.setWCBUpdateErrorsCompanyListAddressesPostalCodeById({
      indexList: indexAddresses,
      error: {
        isBlur: true,
        isVisited: true,
        isFocus: false,
      },
    }));

    updateCallback();
  };

  public onFocusUpdateAddressesPostalCodeByIndex = (updateCallback: Function) => (indexAddresses: string) => () => {
    this.dispatch(R.actions.bookingWizard.setWCBUpdateErrorsCompanyListAddressesPostalCodeById({
      indexList: indexAddresses,
      error: {
        isBlur: false,
        isFocus: true,
      },
    }));

    updateCallback();
  };

  public setUpdateAddCompanyListStateAddressItem = (updateCallback: Function) => (listId: string) => () => {
    const addressId = uuidv4();

    this.dispatch(R.actions.bookingWizard.setWCBUpdateAddCompanyListAddresses(CompanyAddressDTM.fromPlain({
      ...WizardContactBookInitialState.updateState.companyListAddresses[0],
      customId: addressId,
    })));
    this.dispatch(R.actions.bookingWizard.setWCBUpdateAddCompanyListAddressByIndex({
      listId,
      addressesId: addressId,
    }));

    updateCallback();
  };

  public setUpdateRemoveCompanyListStateAddressItem = (updateCallback: Function) => (indexList: string, indexValue: string) => () => {
    this.dispatch(R.actions.bookingWizard.setWCBUpdateRemoveCompanyListAddressByIndex({
      listId: indexList,
      addressesId: indexValue,
    }));

    updateCallback();
  };

  public onChangeUpdateContactsContactPersonByIndex = (updateCallback: Function) => (indexContacts: string) => (fullName: string) => {
    const { bookingWizard: { wizardContactBook: { updateState: { companyListContactPersons } } } } = this.store.getState();

    this.dispatch(R.actions.bookingWizard.setWCBUpdateEditCompanyListContactPersons({
      listId: indexContacts,
      companyListContactPersons: CompanyContactDTM.fromPlain({
        ...companyListContactPersons.filter((item) => item.customId === indexContacts)?.[0],
        fullName,
      }),
    }));

    updateCallback();
  };

  public onBlurUpdateContactsContactPersonByIndex = (updateCallback: Function) => (indexContacts: string) => () => {
    this.dispatch(R.actions.bookingWizard.setWCBUpdateErrorsCompanyListStateContactsContactPersonById({
      indexList: indexContacts,
      error: {
        isBlur: true,
        isVisited: true,
        isFocus: false,
      },
    }));

    updateCallback();
  };

  public onFocusUpdateContactsContactPersonByIndex = (updateCallback: Function) => (indexContacts: string) => () => {
    this.dispatch(R.actions.bookingWizard.setWCBUpdateErrorsCompanyListStateContactsContactPersonById({
      indexList: indexContacts,
      error: {
        isBlur: false,
        isFocus: true,
      },
    }));

    updateCallback();
  };

  public onChangeUpdateContactsAdditionalPhoneByIndex = (updateCallback: Function) => (indexContacts: string) => (additionalPhone: string) => {
    const { bookingWizard: { wizardContactBook: { updateState: { companyListContactPersons } } } } = this.store.getState();

    const { errorMessage, phone: checkPhone } = validationPhone(additionalPhone, { isRequired: false });

    if (errorMessage) {
      this.dispatch(R.actions.bookingWizard.setWCBUpdateErrorsCompanyListStateContactsAdditionalPhoneById({
        indexList: indexContacts,
        error: {
          message: errorMessage,
          isTooltipVisible: true,
        },
      }));
    } else {
      this.dispatch(R.actions.bookingWizard.setWCBUpdateErrorsCompanyListStateContactsAdditionalPhoneById({
        indexList: indexContacts,
        error: {
          message: errorMessage,
          isTooltipVisible: false,
        },
      }));
    }

    this.dispatch(R.actions.bookingWizard.setWCBUpdateEditCompanyListContactPersons({
      listId: indexContacts,
      companyListContactPersons: CompanyContactDTM.fromPlain({
        ...companyListContactPersons.filter((item) => item.customId === indexContacts)?.[0],
        phone2: checkPhone,
      }),
    }));

    updateCallback();
  };

  public onBlurUpdateContactsAdditionalPhoneByIndex = (updateCallback: Function) => (indexContacts: string) => () => {
    this.dispatch(R.actions.bookingWizard.setWCBUpdateErrorsCompanyListStateContactsAdditionalPhoneById({
      indexList: indexContacts,
      error: {
        isBlur: true,
        isVisited: true,
        isFocus: false,
      },
    }));

    updateCallback();
  };

  public onFocusUpdateContactsAdditionalPhoneByIndex = (updateCallback: Function) => (indexContacts: string) => () => {
    this.dispatch(R.actions.bookingWizard.setWCBUpdateErrorsCompanyListStateContactsAdditionalPhoneById({
      indexList: indexContacts,
      error: {
        isBlur: false,
        isFocus: true,
      },
    }));

    updateCallback();
  };

  public onChangeUpdateContactsEmailByIndex = (updateCallback: Function) => (indexContacts: string) => (email: string) => {
    const { bookingWizard: { wizardContactBook: { updateState: { companyListContactPersons } } } } = this.store.getState();

    const { errorMessage, email: checkEmail } = validationEmail(email);

    if (errorMessage) {
      this.dispatch(R.actions.bookingWizard.setWCBUpdateErrorsCompanyListStateContactsEmailById({
        indexList: indexContacts,
        error: {
          message: errorMessage,
          isTooltipVisible: true,
        },
      }));
    } else {
      this.dispatch(R.actions.bookingWizard.setWCBUpdateErrorsCompanyListStateContactsEmailById({
        indexList: indexContacts,
        error: {
          message: errorMessage,
          isTooltipVisible: false,
        },
      }));
    }
    this.dispatch(R.actions.bookingWizard.setWCBUpdateEditCompanyListContactPersons({
      listId: indexContacts,
      companyListContactPersons: CompanyContactDTM.fromPlain({
        ...companyListContactPersons.filter((item) => item.customId === indexContacts)?.[0],
        email: checkEmail,
      }),
    }));

    updateCallback();
  };

  public onBlurUpdateContactsEmailByIndex = (updateCallback: Function) => (indexContacts: string) => () => {
    this.dispatch(R.actions.bookingWizard.setWCBUpdateErrorsCompanyListStateContactsEmailById({
      indexList: indexContacts,
      error: {
        isBlur: true,
        isVisited: true,
        isFocus: false,
      },
    }));

    updateCallback();
  };

  public onFocusUpdateContactsEmailByIndex = (updateCallback: Function) => (indexContacts: string) => () => {
    this.dispatch(R.actions.bookingWizard.setWCBUpdateErrorsCompanyListStateContactsEmailById({
      indexList: indexContacts,
      error: {
        isBlur: false,
        isFocus: true,
      },
    }));

    updateCallback();
  };

  public onChangeIsPrimaryByIndex = (indexContacts: string) => (isPrimary: boolean) => {
    const { bookingWizard: { wizardContactBook: { updateState: { companyListContactPersons } } } } = this.store.getState();

    this.dispatch(R.actions.bookingWizard.setWCBUpdateEditCompanyListContactPersons({
      listId: indexContacts,
      companyListContactPersons: CompanyContactDTM.fromPlain({
        ...companyListContactPersons.filter((item) => item.customId === indexContacts)?.[0],
        primary: isPrimary,
      }),
    }));
  };

  public onChangeUpdateContactsPhoneByIndex = (updateCallback: Function) => (indexContacts: string) => (phone: string) => {
    const { bookingWizard: { wizardContactBook: { updateState: { companyListContactPersons } } } } = this.store.getState();
    const { errorMessage, phone: checkPhone } = validationPhone(phone);

    if (errorMessage) {
      this.dispatch(R.actions.bookingWizard.setWCBUpdateErrorsCompanyListStateContactsPhoneById({
        indexList: indexContacts,
        error: {
          message: errorMessage,
          isTooltipVisible: true,
        },
      }));
    } else {
      this.dispatch(R.actions.bookingWizard.setWCBUpdateErrorsCompanyListStateContactsPhoneById({
        indexList: indexContacts,
        error: {
          message: errorMessage,
          isTooltipVisible: false,
        },
      }));
    }

    this.dispatch(R.actions.bookingWizard.setWCBUpdateEditCompanyListContactPersons({
      listId: indexContacts,
      companyListContactPersons: CompanyContactDTM.fromPlain({
        ...companyListContactPersons.filter((item) => item.customId === indexContacts)?.[0],
        phone: checkPhone,
      }),
    }));

    updateCallback();
  };

  public onBlurUpdateContactsPhoneByIndex = (updateCallback: Function) => (indexContacts: string) => () => {
    this.dispatch(R.actions.bookingWizard.setWCBUpdateErrorsCompanyListStateContactsPhoneById({
      indexList: indexContacts,
      error: {
        isBlur: true,
        isVisited: true,
        isFocus: false,
      },
    }));

    updateCallback();
  };

  public onFocusUpdateContactsPhoneByIndex = (updateCallback: Function) => (indexContacts: string) => () => {
    this.dispatch(R.actions.bookingWizard.setWCBUpdateErrorsCompanyListStateContactsPhoneById({
      indexList: indexContacts,
      error: {
        isBlur: false,
        isFocus: true,
      },
    }));

    updateCallback();
  };

  public setUpdateAddCompanyListStateContactPersonByIndex = (updateCallback: Function) => (listId: string) => () => {
    const contactId = uuidv4();

    this.dispatch(R.actions.bookingWizard.setWCBUpdateAddCompanyListContactPersons(CompanyContactDTM.fromPlain({
      ...WizardContactBookInitialState.updateState.companyListContactPersons[0],
      customId: contactId,
    })));

    this.dispatch(R.actions.bookingWizard.setWCBUpdateAddCompanyListContactPersonByIndex({
      listId,
      contactPersonId: contactId,
    }));

    updateCallback();
  };

  public setUpdateRemoveCompanyListStateContactPersonItem = (updateCallback: Function) => (indexList: string, indexValue: string) => () => {
    this.dispatch(R.actions.bookingWizard.setWCBUpdateRemoveCompanyListContactPersonByIndex({
      listId: indexList,
      contactPersonId: indexValue,
    }));

    updateCallback();
  };

  private validateDocumentsFiles = () => {
    const { bookingWizard: { wizardContactBook: { updateState } } } = this.store.getState();
    const listId = updateState.activeListTabIndex;
    let hasError = false;
    const companyListFind = updateState.companyList?.find((item) => listId === item.customId);
    const companyListAddressFind = updateState?.companyListAddresses?.filter((item) => companyListFind?.addressesIds?.includes(item.customId || ''));

    if (!companyListFind?.name) {
      this.dispatch(R.actions.bookingWizard.setWCBUpdateErrorsCompanyListStateNameById({
        indexList: listId,
        error: {
          message: i18n.t('basicErrors.REQUIRED_MESSAGE'),
          isTooltipVisible: false,
        },
      }));
      hasError = true;
    } else {
      this.dispatch(R.actions.bookingWizard.setWCBUpdateErrorsCompanyListStateNameById({
        indexList: listId,
        error: {
          message: '',
          isTooltipVisible: false,
        },
      }));
    }

    companyListAddressFind?.forEach((addressItem, addressIndex) => {
      if (addressItem.address1
        || addressItem.address2
        || addressItem.city
        || addressItem.country
        || addressItem.state
        || addressItem.postalCode
        || addressIndex === 0
      ) {
        if (!addressItem.address1) {
          this.dispatch(R.actions.bookingWizard.setWCBUpdateErrorsCompanyListAddressesAddressLineFirstById({
            indexList: `${addressItem.customId}`,
            indexValue: addressIndex,
            error: {
              message: i18n.t('basicErrors.REQUIRED_MESSAGE'),
            },
          }));
          hasError = true;
        } else {
          this.dispatch(R.actions.bookingWizard.setWCBUpdateErrorsCompanyListAddressesAddressLineFirstById({
            indexList: `${addressItem.customId}`,
            indexValue: addressIndex,
            error: {
              message: '',
            },
          }));
        }
        if (!addressItem.city) {
          this.dispatch(R.actions.bookingWizard.setWCBUpdateErrorsCompanyListAddressesCityById({
            indexList: `${addressItem.customId}`,
            indexValue: addressIndex,
            error: {
              message: i18n.t('basicErrors.REQUIRED_MESSAGE'),
            },
          }));
          hasError = true;
        } else {
          this.dispatch(R.actions.bookingWizard.setWCBUpdateErrorsCompanyListAddressesCityById({
            indexList: `${addressItem.customId}`,
            indexValue: addressIndex,
            error: {
              message: '',
            },
          }));
        }
        if (!addressItem.country) {
          this.dispatch(R.actions.bookingWizard.setWCBUpdateErrorsCompanyListAddressesCountryById({
            indexList: `${addressItem.customId}`,
            indexValue: addressIndex,
            error: {
              message: i18n.t('basicErrors.REQUIRED_MESSAGE'),
            },
          }));
          hasError = true;
        } else {
          this.dispatch(R.actions.bookingWizard.setWCBUpdateErrorsCompanyListAddressesCountryById({
            indexList: `${addressItem.customId}`,
            indexValue: addressIndex,
            error: {
              message: '',
            },
          }));
        }
        if (addressItem.country === 'US' && !addressItem.state) {
          this.dispatch(R.actions.bookingWizard.setWCBUpdateErrorsCompanyListAddressesStateById({
            indexList: `${addressItem.customId}`,
            indexValue: addressIndex,
            error: {
              message: i18n.t('basicErrors.REQUIRED_MESSAGE'),
            },
          }));
          hasError = true;
        } else {
          this.dispatch(R.actions.bookingWizard.setWCBUpdateErrorsCompanyListAddressesStateById({
            indexList: `${addressItem.customId}`,
            indexValue: addressIndex,
            error: {
              message: '',
            },
          }));
        }
        if (!addressItem.postalCode) {
          this.dispatch(R.actions.bookingWizard.setWCBUpdateErrorsCompanyListAddressesPostalCodeById({
            indexList: `${addressItem.customId}`,
            indexValue: addressIndex,
            error: {
              message: i18n.t('basicErrors.REQUIRED_MESSAGE'),
            },
          }));
          hasError = true;
        } else {
          this.dispatch(R.actions.bookingWizard.setWCBUpdateErrorsCompanyListAddressesPostalCodeById({
            indexList: `${addressItem.customId}`,
            indexValue: addressIndex,
            error: {
              message: '',
            },
          }));
        }
      } else {
        this.dispatch(R.actions.bookingWizard.setWCBUpdateErrorsCompanyListAddressesAddressLineFirstById({
          indexList: `${addressItem.customId}`,
          indexValue: addressIndex,
          error: {
            message: '',
          },
        }));
        this.dispatch(R.actions.bookingWizard.setWCBUpdateErrorsCompanyListAddressesCityById({
          indexList: `${addressItem.customId}`,
          indexValue: addressIndex,
          error: {
            message: '',
          },
        }));
        this.dispatch(R.actions.bookingWizard.setWCBUpdateErrorsCompanyListAddressesCountryById({
          indexList: `${addressItem.customId}`,
          indexValue: addressIndex,
          error: {
            message: '',
          },
        }));
        this.dispatch(R.actions.bookingWizard.setWCBUpdateErrorsCompanyListAddressesStateById({
          indexList: `${addressItem.customId}`,
          indexValue: addressIndex,
          error: {
            message: '',
          },
        }));
        this.dispatch(R.actions.bookingWizard.setWCBUpdateErrorsCompanyListAddressesPostalCodeById({
          indexList: `${addressItem.customId}`,
          indexValue: addressIndex,
          error: {
            message: '',
          },
        }));
      }
      return hasError;
    });

    const contactPersonIds = companyListFind?.contactPersonsIds || [];
    updateState?.companyListContactPersons
      ?.filter((item) => contactPersonIds.includes(item.customId || ''))
      .forEach((contactItem, contactIndex) => {
        if (contactItem.fullName
          || contactItem.email
          || contactItem.phone
          || contactItem.phone2
          || contactIndex === 0
        ) {
          if (!contactItem.fullName) {
            this.dispatch(R.actions.bookingWizard.setWCBUpdateErrorsCompanyListStateContactsContactPersonById({
              indexList: `${contactItem.customId}`,
              indexValue: contactIndex,
              error: {
                message: i18n.t('basicErrors.REQUIRED_MESSAGE'),
              },
            }));
            hasError = true;
          } else {
            this.dispatch(R.actions.bookingWizard.setWCBUpdateErrorsCompanyListStateContactsContactPersonById({
              indexList: `${contactItem.customId}`,
              indexValue: contactIndex,
              error: {
                message: '',
              },
            }));
          }

          const { errorMessage: errorMessageEmail } = validationEmail(contactItem.email);
          if (!contactItem.email || errorMessageEmail) {
            this.dispatch(R.actions.bookingWizard.setWCBUpdateErrorsCompanyListStateContactsEmailById({
              indexList: `${contactItem.customId}`,
              indexValue: contactIndex,
              error: {
                message: errorMessageEmail || i18n.t('basicErrors.REQUIRED_MESSAGE'),
              },
            }));
            hasError = true;
          } else {
            this.dispatch(R.actions.bookingWizard.setWCBUpdateErrorsCompanyListStateContactsEmailById({
              indexList: `${contactItem.customId}`,
              indexValue: contactIndex,
              error: {
                message: '',
              },
            }));
          }

          const { errorMessage: errorMessagePhone } = validationPhone(contactItem.phone);
          if (!contactItem.phone || errorMessagePhone) {
            this.dispatch(R.actions.bookingWizard.setWCBUpdateErrorsCompanyListStateContactsPhoneById({
              indexList: `${contactItem.customId}`,
              indexValue: contactIndex,
              error: {
                message: errorMessagePhone || i18n.t('basicErrors.REQUIRED_MESSAGE'),
              },
            }));
            hasError = true;
          } else {
            this.dispatch(R.actions.bookingWizard.setWCBUpdateErrorsCompanyListStateContactsPhoneById({
              indexList: `${contactItem.customId}`,
              indexValue: contactIndex,
              error: {
                message: '',
              },
            }));
          }

          if (!contactItem.phone2) {
            this.dispatch(R.actions.bookingWizard.setWCBUpdateErrorsCompanyListStateContactsAdditionalPhoneById({
              indexList: `${contactItem.customId}`,
              indexValue: contactIndex,
              error: {
                message: '',
              },
            }));
          }
        } else {
          this.dispatch(R.actions.bookingWizard.setWCBUpdateErrorsCompanyListStateContactsContactPersonById({
            indexList: `${contactItem.customId}`,
            indexValue: contactIndex,
            error: {
              message: '',
            },
          }));
          this.dispatch(R.actions.bookingWizard.setWCBUpdateErrorsCompanyListStateContactsEmailById({
            indexList: `${contactItem.customId}`,
            indexValue: contactIndex,
            error: {
              message: '',
            },
          }));
          this.dispatch(R.actions.bookingWizard.setWCBUpdateErrorsCompanyListStateContactsPhoneById({
            indexList: `${contactItem.customId}`,
            indexValue: contactIndex,
            error: {
              message: '',
            },
          }));
        }
      });

    return hasError;
  };

  private validateAddressDocumentsFiles = () => {
    const { bookingWizard: { wizardContactBook: { updateState } } } = this.store.getState();
    const listId = updateState.activeListTabIndex;
    let hasError = false;
    const companyListFind = updateState.companyList?.filter((item) => listId === item.customId)?.[0];
    const companyListAddressFind = updateState?.companyListAddresses?.filter((item) => companyListFind?.addressesIds?.includes(item.customId || ''));

    companyListAddressFind?.forEach((addressItem, addressIndex) => {
      if (addressItem.address1
        || addressItem.address2
        || addressItem.city
        || addressItem.country
        || addressItem.state
        || addressItem.postalCode
        || addressIndex === 0
      ) {
        if (!addressItem.address1) {
          this.dispatch(R.actions.bookingWizard.setWCBUpdateErrorsCompanyListAddressesAddressLineFirstById({
            indexList: `${addressItem.customId}`,
            indexValue: addressIndex,
            error: {
              message: i18n.t('basicErrors.REQUIRED_MESSAGE'),
            },
          }));
          hasError = true;
        } else {
          this.dispatch(R.actions.bookingWizard.setWCBUpdateErrorsCompanyListAddressesAddressLineFirstById({
            indexList: `${addressItem.customId}`,
            indexValue: addressIndex,
            error: {
              message: '',
            },
          }));
        }
        if (!addressItem.city) {
          this.dispatch(R.actions.bookingWizard.setWCBUpdateErrorsCompanyListAddressesCityById({
            indexList: `${addressItem.customId}`,
            indexValue: addressIndex,
            error: {
              message: i18n.t('basicErrors.REQUIRED_MESSAGE'),
            },
          }));
          hasError = true;
        } else {
          this.dispatch(R.actions.bookingWizard.setWCBUpdateErrorsCompanyListAddressesCityById({
            indexList: `${addressItem.customId}`,
            indexValue: addressIndex,
            error: {
              message: '',
            },
          }));
        }
        if (!addressItem.country) {
          this.dispatch(R.actions.bookingWizard.setWCBUpdateErrorsCompanyListAddressesCountryById({
            indexList: `${addressItem.customId}`,
            indexValue: addressIndex,
            error: {
              message: i18n.t('basicErrors.REQUIRED_MESSAGE'),
            },
          }));
          hasError = true;
        } else {
          this.dispatch(R.actions.bookingWizard.setWCBUpdateErrorsCompanyListAddressesCountryById({
            indexList: `${addressItem.customId}`,
            indexValue: addressIndex,
            error: {
              message: '',
            },
          }));
        }
        if (addressItem.country === 'US' && !addressItem.state) {
          this.dispatch(R.actions.bookingWizard.setWCBUpdateErrorsCompanyListAddressesStateById({
            indexList: `${addressItem.customId}`,
            indexValue: addressIndex,
            error: {
              message: i18n.t('basicErrors.REQUIRED_MESSAGE'),
            },
          }));
          hasError = true;
        } else {
          this.dispatch(R.actions.bookingWizard.setWCBUpdateErrorsCompanyListAddressesStateById({
            indexList: `${addressItem.customId}`,
            indexValue: addressIndex,
            error: {
              message: '',
            },
          }));
        }
        if (!addressItem.postalCode) {
          this.dispatch(R.actions.bookingWizard.setWCBUpdateErrorsCompanyListAddressesPostalCodeById({
            indexList: `${addressItem.customId}`,
            indexValue: addressIndex,
            error: {
              message: i18n.t('basicErrors.REQUIRED_MESSAGE'),
            },
          }));
          hasError = true;
        } else {
          this.dispatch(R.actions.bookingWizard.setWCBUpdateErrorsCompanyListAddressesPostalCodeById({
            indexList: `${addressItem.customId}`,
            indexValue: addressIndex,
            error: {
              message: '',
            },
          }));
        }
      } else {
        this.dispatch(R.actions.bookingWizard.setWCBUpdateErrorsCompanyListAddressesAddressLineFirstById({
          indexList: `${addressItem.customId}`,
          indexValue: addressIndex,
          error: {
            message: '',
          },
        }));
        this.dispatch(R.actions.bookingWizard.setWCBUpdateErrorsCompanyListAddressesCityById({
          indexList: `${addressItem.customId}`,
          indexValue: addressIndex,
          error: {
            message: '',
          },
        }));
        this.dispatch(R.actions.bookingWizard.setWCBUpdateErrorsCompanyListAddressesCountryById({
          indexList: `${addressItem.customId}`,
          indexValue: addressIndex,
          error: {
            message: '',
          },
        }));
        this.dispatch(R.actions.bookingWizard.setWCBUpdateErrorsCompanyListAddressesStateById({
          indexList: `${addressItem.customId}`,
          indexValue: addressIndex,
          error: {
            message: '',
          },
        }));
        this.dispatch(R.actions.bookingWizard.setWCBUpdateErrorsCompanyListAddressesPostalCodeById({
          indexList: `${addressItem.customId}`,
          indexValue: addressIndex,
          error: {
            message: '',
          },
        }));
      }
      return hasError;
    });

    const contactPersonIds = companyListFind?.contactPersonsIds || [];
    updateState?.companyListContactPersons
      ?.filter((item) => contactPersonIds.includes(item.customId || ''))
      .forEach((contactItem, contactIndex) => {
        if (contactItem.fullName
          || contactItem.email
          || contactItem.phone
          || contactItem.phone2
          || contactIndex === 0
        ) {
          if (!contactItem.fullName) {
            this.dispatch(R.actions.bookingWizard.setWCBUpdateErrorsCompanyListStateContactsContactPersonById({
              indexList: `${contactItem.customId}`,
              indexValue: contactIndex,
              error: {
                message: i18n.t('basicErrors.REQUIRED_MESSAGE'),
              },
            }));
            hasError = true;
          } else {
            this.dispatch(R.actions.bookingWizard.setWCBUpdateErrorsCompanyListStateContactsContactPersonById({
              indexList: `${contactItem.customId}`,
              indexValue: contactIndex,
              error: {
                message: '',
              },
            }));
          }

          const { errorMessage: errorMessageEmail } = validationEmail(contactItem.email);
          if (!contactItem.email || errorMessageEmail) {
            this.dispatch(R.actions.bookingWizard.setWCBUpdateErrorsCompanyListStateContactsEmailById({
              indexList: `${contactItem.customId}`,
              indexValue: contactIndex,
              error: {
                message: errorMessageEmail || i18n.t('basicErrors.REQUIRED_MESSAGE'),
              },
            }));
            hasError = true;
          } else {
            this.dispatch(R.actions.bookingWizard.setWCBUpdateErrorsCompanyListStateContactsEmailById({
              indexList: `${contactItem.customId}`,
              indexValue: contactIndex,
              error: {
                message: '',
              },
            }));
          }

          const { errorMessage: errorMessagePhone } = validationPhone(contactItem.phone);
          if (!contactItem.phone || errorMessagePhone) {
            this.dispatch(R.actions.bookingWizard.setWCBUpdateErrorsCompanyListStateContactsPhoneById({
              indexList: `${contactItem.customId}`,
              indexValue: contactIndex,
              error: {
                message: errorMessagePhone || i18n.t('basicErrors.REQUIRED_MESSAGE'),
              },
            }));
            hasError = true;
          } else {
            this.dispatch(R.actions.bookingWizard.setWCBUpdateErrorsCompanyListStateContactsPhoneById({
              indexList: `${contactItem.customId}`,
              indexValue: contactIndex,
              error: {
                message: '',
              },
            }));
          }

          if (!contactItem.phone2) {
            this.dispatch(R.actions.bookingWizard.setWCBUpdateErrorsCompanyListStateContactsAdditionalPhoneById({
              indexList: `${contactItem.customId}`,
              indexValue: contactIndex,
              error: {
                message: '',
              },
            }));
          }
        } else {
          this.dispatch(R.actions.bookingWizard.setWCBUpdateErrorsCompanyListStateContactsContactPersonById({
            indexList: `${contactItem.customId}`,
            indexValue: contactIndex,
            error: {
              message: '',
            },
          }));
          this.dispatch(R.actions.bookingWizard.setWCBUpdateErrorsCompanyListStateContactsEmailById({
            indexList: `${contactItem.customId}`,
            indexValue: contactIndex,
            error: {
              message: '',
            },
          }));
          this.dispatch(R.actions.bookingWizard.setWCBUpdateErrorsCompanyListStateContactsPhoneById({
            indexList: `${contactItem.customId}`,
            indexValue: contactIndex,
            error: {
              message: '',
            },
          }));
        }
      });

    return hasError;
  };

  private validateContactDocumentsFiles = () => {
    const { bookingWizard: { wizardContactBook: { updateState } } } = this.store.getState();
    const listId = updateState.activeListTabIndex;
    let hasError = false;
    const companyListFind = updateState.companyList?.find((item) => listId === item.customId);

    const contactPersonIds = companyListFind?.contactPersonsIds || [];
    updateState?.companyListContactPersons
      ?.filter((item) => contactPersonIds.includes(item.customId || ''))
      .forEach((contactItem, contactIndex) => {
        if (contactItem.fullName
          || contactItem.email
          || contactItem.phone
          || contactItem.phone2
          || contactIndex === 0
        ) {
          if (!contactItem.fullName) {
            this.dispatch(R.actions.bookingWizard.setWCBUpdateErrorsCompanyListStateContactsContactPersonById({
              indexList: `${contactItem.customId}`,
              indexValue: contactIndex,
              error: {
                message: i18n.t('basicErrors.REQUIRED_MESSAGE'),
              },
            }));
            hasError = true;
          } else {
            this.dispatch(R.actions.bookingWizard.setWCBUpdateErrorsCompanyListStateContactsContactPersonById({
              indexList: `${contactItem.customId}`,
              indexValue: contactIndex,
              error: {
                message: '',
              },
            }));
          }

          const { errorMessage: errorMessageEmail } = validationEmail(contactItem.email);
          if (!contactItem.email || errorMessageEmail) {
            this.dispatch(R.actions.bookingWizard.setWCBUpdateErrorsCompanyListStateContactsEmailById({
              indexList: `${contactItem.customId}`,
              indexValue: contactIndex,
              error: {
                message: errorMessageEmail || i18n.t('basicErrors.REQUIRED_MESSAGE'),
              },
            }));
            hasError = true;
          } else {
            this.dispatch(R.actions.bookingWizard.setWCBUpdateErrorsCompanyListStateContactsEmailById({
              indexList: `${contactItem.customId}`,
              indexValue: contactIndex,
              error: {
                message: '',
              },
            }));
          }

          const { errorMessage: errorMessagePhone } = validationPhone(contactItem.phone);
          if (!contactItem.phone || errorMessagePhone) {
            this.dispatch(R.actions.bookingWizard.setWCBUpdateErrorsCompanyListStateContactsPhoneById({
              indexList: `${contactItem.customId}`,
              indexValue: contactIndex,
              error: {
                message: errorMessagePhone || i18n.t('basicErrors.REQUIRED_MESSAGE'),
              },
            }));
            hasError = true;
          } else {
            this.dispatch(R.actions.bookingWizard.setWCBUpdateErrorsCompanyListStateContactsPhoneById({
              indexList: `${contactItem.customId}`,
              indexValue: contactIndex,
              error: {
                message: '',
              },
            }));
          }

          if (!contactItem.phone2) {
            this.dispatch(R.actions.bookingWizard.setWCBUpdateErrorsCompanyListStateContactsAdditionalPhoneById({
              indexList: `${contactItem.customId}`,
              indexValue: contactIndex,
              error: {
                message: '',
              },
            }));
          }
        } else {
          this.dispatch(R.actions.bookingWizard.setWCBUpdateErrorsCompanyListStateContactsContactPersonById({
            indexList: `${contactItem.customId}`,
            indexValue: contactIndex,
            error: {
              message: '',
            },
          }));
          this.dispatch(R.actions.bookingWizard.setWCBUpdateErrorsCompanyListStateContactsEmailById({
            indexList: `${contactItem.customId}`,
            indexValue: contactIndex,
            error: {
              message: '',
            },
          }));
          this.dispatch(R.actions.bookingWizard.setWCBUpdateErrorsCompanyListStateContactsPhoneById({
            indexList: `${contactItem.customId}`,
            indexValue: contactIndex,
            error: {
              message: '',
            },
          }));
        }
      });

    return hasError;
  };

  private setAllVisited = (flag = true) => {
    const { bookingWizard: { wizardContactBook: { updateState: { errors } } } } = this.store.getState();

    errors.companyListState?.forEach((companyList) => {
      const indexList = companyList.customId;

      this.dispatch(R.actions.bookingWizard.setWCBUpdateErrorsCompanyListStateNameById({
        indexList,
        error: {
          isVisited: flag,
        },
      }));

      errors.addresses?.forEach((addressKey) => {
        const addressIndex = addressKey.customId;

        this.dispatch(R.actions.bookingWizard.setWCBUpdateErrorsCompanyListAddressesAddressLineFirstById({
          indexList: addressIndex,
          error: {
            isVisited: flag,
          },
        }));
        this.dispatch(R.actions.bookingWizard.setWCBUpdateErrorsCompanyListAddressesCityById({
          indexList: addressIndex,
          error: {
            isVisited: flag,
          },
        }));
        this.dispatch(R.actions.bookingWizard.setWCBUpdateErrorsCompanyListAddressesCountryById({
          indexList: addressIndex,
          error: {
            isVisited: flag,
          },
        }));
        this.dispatch(R.actions.bookingWizard.setWCBUpdateErrorsCompanyListAddressesStateById({
          indexList: addressIndex,
          error: {
            isVisited: flag,
          },
        }));
        this.dispatch(R.actions.bookingWizard.setWCBUpdateErrorsCompanyListAddressesPostalCodeById({
          indexList: addressIndex,
          error: {
            isVisited: flag,
          },
        }));
      });

      errors.contactPersons?.forEach((contactPersonKey) => {
        const contactIndex = contactPersonKey.customId;

        this.dispatch(R.actions.bookingWizard.setWCBUpdateErrorsCompanyListStateContactsContactPersonById({
          indexList: contactIndex,
          error: {
            isVisited: flag,
          },
        }));
        this.dispatch(R.actions.bookingWizard.setWCBUpdateErrorsCompanyListStateContactsEmailById({
          indexList: contactIndex,
          error: {
            isVisited: flag,
          },
        }));
        this.dispatch(R.actions.bookingWizard.setWCBUpdateErrorsCompanyListStateContactsPhoneById({
          indexList: contactIndex,
          error: {
            isVisited: flag,
          },
        }));
      });
    });
  }

  private compareUpdateState = () => {
    const { bookingWizard: { wizardContactBook: { updateState, defaultState } } } = this.store.getState();

    updateState.companyList
      .forEach((itemList) => {
        if (updateState.activeListTabIndex !== itemList.customId) {
          return;
        }

        const defaultStateCompanyList = defaultState.companyList?.filter((item) => item.customId === itemList.customId);

        let isCompare = false;
        if (!isCompare
          && itemList?.name !== defaultStateCompanyList?.[0]?.name
        ) {
          isCompare = true;
        }

        if (!isCompare
          && itemList?.taxId !== defaultStateCompanyList?.[0]?.taxId
        ) {
          isCompare = true;
        }

        const addressesIds = defaultState?.companyList?.filter((item) => item.customId === itemList.customId)?.[0]?.addressesIds || [];
        const contactPersonIds = defaultState?.companyList?.filter((item) => item.customId === itemList.customId)?.[0]?.contactPersonsIds || [];

        if (itemList?.addressesIds?.length !== defaultStateCompanyList?.[0]?.addressesIds?.length) {
          isCompare = true;
        }

        if (itemList?.contactPersonsIds?.length !== defaultStateCompanyList?.[0]?.contactPersonsIds?.length) {
          isCompare = true;
        }

        if (!isCompare) {
          updateState.companyListAddresses
            ?.filter((item) => itemList?.addressesIds?.includes(item.customId || ''))
            .forEach((addressItem) => {
              const defaultAddress = defaultState.companyListAddresses?.filter((item) => addressesIds?.includes(item.customId || ''))
                ?.filter((item) => item.customId === addressItem.customId)?.[0];

              if (!isCompare
                && (
                  (addressItem?.address1 !== defaultAddress?.address1)
                  || (addressItem?.address2 !== defaultAddress?.address2)
                  || (addressItem?.city !== defaultAddress?.city)
                  || (addressItem?.country !== defaultAddress?.country)
                  || (addressItem?.state !== defaultAddress?.state)
                  || (addressItem?.postalCode !== defaultAddress?.postalCode)
                )) {
                isCompare = true;
              }
            });
        }

        if (!isCompare) {
          updateState.companyListContactPersons
            ?.filter((item) => itemList?.contactPersonsIds?.includes(item.customId || ''))
            .forEach((contactItem) => {
              const defaultContactPerson = defaultState.companyListContactPersons?.filter((item) => contactPersonIds?.includes(item.customId || ''))
                ?.filter((item) => item.customId === contactItem.customId)?.[0];

              if (!isCompare
                && (
                  (contactItem?.phone2 !== defaultContactPerson?.phone2)
                  || (contactItem?.fullName !== defaultContactPerson?.fullName)
                  || (contactItem?.email !== defaultContactPerson?.email)
                  || (contactItem?.phone !== defaultContactPerson?.phone)
                )) {
                isCompare = true;
              }
            });
        }

        this.dispatch(R.actions.bookingWizard.setWCBUpdateCompanyListIsUpdateById({
          listId: `${itemList.customId}`,
          flag: isCompare,
        }));
      });
  };

  private compareUpdateAddressState = () => {
    const { bookingWizard: { wizardContactBook: { updateState, defaultState } } } = this.store.getState();

    updateState.companyList
      .forEach((itemList) => {
        if (updateState.activeListTabIndex !== itemList.customId) {
          return;
        }

        const defaultStateCompanyList = defaultState.companyList?.filter((item) => item.customId === itemList.customId);

        let isCompare = false;

        const addressesIds = defaultState?.companyList?.find((item) => item.customId === itemList.customId)?.addressesIds || [];

        if (itemList?.addressesIds?.length !== defaultStateCompanyList?.[0]?.addressesIds?.length) {
          isCompare = true;
        }

        if (!isCompare) {
          updateState.companyListAddresses
            ?.filter((item) => itemList?.addressesIds?.includes(item.customId || ''))
            .forEach((addressItem) => {
              const defaultAddress = defaultState.companyListAddresses?.find((item) => addressesIds?.includes(item.customId || '') && item.customId === addressItem.customId);

              if (!isCompare
                && (
                  (addressItem?.address1 !== defaultAddress?.address1)
                  || (addressItem?.address2 !== defaultAddress?.address2)
                  || (addressItem?.city !== defaultAddress?.city)
                  || (addressItem?.country !== defaultAddress?.country)
                  || (addressItem?.state !== defaultAddress?.state)
                  || (addressItem?.postalCode !== defaultAddress?.postalCode)
                )) {
                isCompare = true;
              }
            });
        }

        this.dispatch(R.actions.bookingWizard.setWCBUpdateCompanyListIsUpdateById({
          listId: `${itemList.customId}`,
          flag: isCompare,
        }));
      });
  };

  private compareUpdateContactState = () => {
    const { bookingWizard: { wizardContactBook: { updateState, defaultState } } } = this.store.getState();

    updateState.companyList
      .forEach((itemList) => {
        if (updateState.activeListTabIndex !== itemList.customId) {
          return;
        }

        const defaultStateCompanyList = defaultState.companyList?.filter((item) => item.customId === itemList.customId);

        let isCompare = false;

        const contactPersonIds = defaultState?.companyList?.find((item) => item.customId === itemList.customId)?.contactPersonsIds || [];

        if (itemList?.addressesIds?.length !== defaultStateCompanyList?.[0]?.addressesIds?.length) {
          isCompare = true;
        }

        if (!isCompare) {
          updateState.companyListContactPersons
            ?.filter((item) => itemList?.contactPersonsIds?.includes(item.customId || ''))
            .forEach((contactItem) => {
              const defaultContactPerson = defaultState.companyListContactPersons?.find((item) => contactPersonIds?.includes(item.customId || '') && item.customId === contactItem.customId);

              if (!isCompare
                && (
                  (contactItem?.phone2 !== defaultContactPerson?.phone2)
                  || (contactItem?.fullName !== defaultContactPerson?.fullName)
                  || (contactItem?.email !== defaultContactPerson?.email)
                  || (contactItem?.phone !== defaultContactPerson?.phone)
                )) {
                isCompare = true;
              }
            });
        }

        this.dispatch(R.actions.bookingWizard.setWCBUpdateCompanyListIsUpdateById({
          listId: `${itemList.customId}`,
          flag: isCompare,
        }));
      });
  };
}
