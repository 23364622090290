import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 8px 16px 8px 16px;
  border: 1px solid #EAEEF8;
  border-radius: 4px;
  display: flex;
`;

export const Main = styled.div`
  position: relative;
`;

export const Header = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

export const Title = styled.span`
  font-weight: 500;
  font-size: 14px;
  color: #202C3C;
  display: block;
  margin: 12px 0;
`;

export const RouteWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Point = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  border: 1.3px solid #73819B;
`;

export const Line = styled.div`
  width: 2px;
  height: 32px;
  background-color: #EAEEF8;
  margin: 3px 0;
`;

export const PlaceWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-height: 40px;
`;

export const PlaceMain = styled.span`
  font-weight: 500;
  font-size: 14px;
  color: #202C3C;
  width: 135px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const TimeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  font-weight: 400;
  font-size: 12px;
  color: #73819B;
`;

export const Dashed = styled.div`
  border: 0.5px dashed #EAEEF8;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px
`;

export const VesselVoyageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 400;
  font-size: 12px;
  color: #73819B;
  width: 100%;
`;

export const DataWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Value = styled.span`
  text-align: right;
`;
