import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Tooltip } from 'app-wrapper/view/components';

import { InputStyled } from './ContainerNumberInput.styled';

interface ContainerNumberInputComponentProps {
  value: string;
  setValue: (containerId: string, value: string) => void;
  error: boolean;
  containerId: string;
}

export const ContainerNumberInputComponent: FC<ContainerNumberInputComponentProps> = ({
  value,
  setValue,
  error,
  containerId,
}) => {
  const { t } = useTranslation();

  const handleChange = useCallback((e) => {
    setValue(containerId, e.target.value);
  }, [setValue, containerId]);

  return error ? (
    <Tooltip
      mode="danger"
      placement="bottom"
      title={t('Container number is not valid: should have 4 letters and 7 numbers')}
    >
      <InputStyled
        value={value}
        onChange={handleChange}
        error={error}
        placeholder={t('Enter Container Number')}
      />
    </Tooltip>
  ) : (
    <InputStyled
      value={value}
      onChange={handleChange}
      error={error}
      placeholder={t('Enter Container Number')}
    />
  );
};
