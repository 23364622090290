import React from 'react';

import { BookingConfirmationPDF } from 'shipment-operations/view/components';

import { PDFDocHeader } from './components';

const BookingConfirmationPDFContainer = () => (
  <BookingConfirmationPDF header={<PDFDocHeader />} />
);

export { BookingConfirmationPDFContainer as BookingConfirmationPDF };
