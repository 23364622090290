import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { InputStyled } from './UnNumberInput.styled';

interface UnNumberInputComponentProps {
  unNumber: string;
  error?: boolean;
}

export const UnNumberInputComponent: FC<UnNumberInputComponentProps> = ({
  unNumber,
  error,
}) => {
  const { t } = useTranslation();

  return (
    <InputStyled
      placeholder={t('UN Number')}
      value={unNumber}
      error={error}
      disabled
    />
  );
};
