import { Type } from 'class-transformer';
import {
  IsDefined, ValidateNested,
} from 'class-validator';
import { BaseDTM } from 'proto/BaseDTM';
import {
  AccountOrganizationProfileDtm,
  TAccountOrganizationProfileDtm,
} from 'user-management/models/dtm/ModuleFunctionalityPermissions/AccountOrganizationProfile.dtm';
import {
  CustomerOrganizationProfileDtm,
  TCustomerOrganizationProfileDtm,
} from 'user-management/models/dtm/ModuleFunctionalityPermissions/CustomerOrganizationProfile.dtm';
import {
  EditCustomerOrganizationProfileDrawerDtm,
  TEditCustomerOrganizationProfileDrawerDtm,
} from 'user-management/models/dtm/ModuleFunctionalityPermissions/EditCustomerOrganizationProfileDrawer.dtm';
import {
  EditOrganizationProfileDrawerDtm,
  TEditOrganizationProfileDrawerDtm,
} from 'user-management/models/dtm/ModuleFunctionalityPermissions/EditOrganizationProfileDrawer.dtm';
import {
  EditPartnerOrganizationProfileDrawerDtm,
  TEditPartnerOrganizationProfileDrawerDtm,
} from 'user-management/models/dtm/ModuleFunctionalityPermissions/EditPartnerOrganizationProfileDrawer.dtm';
import {
  PartnerOrganizationProfileDtm,
  TPartnerOrganizationProfileDtm,
} from 'user-management/models/dtm/ModuleFunctionalityPermissions/PartnerOrganizationProfile.dtm';
import {
  TUsersPermissionsDtm,
  UsersPermissionsDtm,
} from 'user-management/models/dtm/ModuleFunctionalityPermissions/UsersPermissions.dtm';
import {
  IUserManagementProfilePermissionsDTM,
  UserManagementProfilePermissionsDTM,
} from 'user-management/models/dtm/ModuleFunctionalityPermissions/UserManagementProfilePermissions.dtm';
import { ContractsPermissionsDTM, IContractsPermissionsDTM } from 'user-management/models/dtm';

import { TUserManagementPermissionsDtm, UserManagementPermissionsDtm } from './UserManagementPermissions.dtm';

export type TModuleFunctionalityPermissionsDtm = {
  userManagement: TUserManagementPermissionsDtm
  users: TUsersPermissionsDtm
  accountOrganizationProfile: TAccountOrganizationProfileDtm
  customerOrganizationProfile: TCustomerOrganizationProfileDtm
  partnerOrganizationProfile: TPartnerOrganizationProfileDtm
  editOrganizationProfileDrawer: TEditOrganizationProfileDrawerDtm
  editPartnerOrganizationProfileDrawer: TEditPartnerOrganizationProfileDrawerDtm
  editCustomerOrganizationProfileDrawer: TEditCustomerOrganizationProfileDrawerDtm
  userManagementProfile: IUserManagementProfilePermissionsDTM
  contracts: IContractsPermissionsDTM
}

export class ModuleFunctionalityPermissionsDtm extends BaseDTM<TModuleFunctionalityPermissionsDtm> {
  @IsDefined()
  @ValidateNested()
  @Type(() => UserManagementPermissionsDtm)
  userManagement: UserManagementPermissionsDtm

  @IsDefined()
  @ValidateNested()
  @Type(() => UserManagementProfilePermissionsDTM)
  userManagementProfile: UserManagementProfilePermissionsDTM

  @IsDefined()
  @Type(() => UsersPermissionsDtm)
  users: UsersPermissionsDtm

  @IsDefined()
  @Type(() => AccountOrganizationProfileDtm)
  accountOrganizationProfile: AccountOrganizationProfileDtm

  @IsDefined()
  @Type(() => CustomerOrganizationProfileDtm)
  customerOrganizationProfile: CustomerOrganizationProfileDtm

  @IsDefined()
  @Type(() => PartnerOrganizationProfileDtm)
  partnerOrganizationProfile: PartnerOrganizationProfileDtm

  @IsDefined()
  @Type(() => EditOrganizationProfileDrawerDtm)
  editOrganizationProfileDrawer: EditOrganizationProfileDrawerDtm

  @IsDefined()
  @Type(() => EditCustomerOrganizationProfileDrawerDtm)
  editCustomerOrganizationProfileDrawer: EditCustomerOrganizationProfileDrawerDtm

  @IsDefined()
  @Type(() => EditPartnerOrganizationProfileDrawerDtm)
  editPartnerOrganizationProfileDrawer: EditPartnerOrganizationProfileDrawerDtm

  @IsDefined()
  @Type(() => ContractsPermissionsDTM)
  contracts: ContractsPermissionsDTM
}
