import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { IMOClassType, IMOClassTypeNames } from 'app-wrapper/types/IMOClassType';
import { Option } from 'app-wrapper/view/components';
import { CargoDTM } from 'shipment-operations/models/dtm';

import { SelectStyled } from './IMOClassSelect.styled';

interface IMOClassSelectComponentProps {
  imoClass: CargoDTM['imoClass']
  error?: boolean
}

export const IMOClassSelectComponent: FC<IMOClassSelectComponentProps> = ({
  imoClass,
  error,
}) => {
  const { t } = useTranslation();

  return (
    <SelectStyled
      value={imoClass}
      error={error}
      allowClear={!!imoClass}
      size="middle"
      disabled
    >
      {Object.keys(IMOClassTypeNames).map((type) => (
        <Option key={type} value={type}>
          {t(IMOClassTypeNames[type as IMOClassType])}
        </Option>
      ))}
    </SelectStyled>
  );
};
