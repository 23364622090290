import styled from 'styled-components';

import { CustomScrollbar } from 'app-wrapper/view/styles/mixins';

export const Wrapper = styled.div.attrs({
  'data-class': 'ShipmentOverviewWrapper',
})`
  width: 100%;
  overflow: auto;

  ${CustomScrollbar}
`;

export const ShipmentOverviewLayout = styled.div.attrs({
  'data-class': 'ShipmentOverviewLayout',
})`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`;
