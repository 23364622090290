import { BaseController, controller } from 'proto/BaseController';
import { R } from 'shipment-operations/repository';
import { R as userManagementR } from 'user-management/repository';
import { UC } from 'shipment-operations/controllers';

import {
  ShipmentDrawerContent,
  ShipmentRoutesType,
  DocumentType,
  ExportClearanceType,
} from 'shipment-operations/constants';
import { ContainerDocumentDTM, CustomsClearanceDTM } from 'shipment-operations/models/dtm';
import { OrganizationPaymentMethodDTM, OrganizationTradeReferenceDTM } from 'user-management/models/dtm';

@controller
export class ShipmentDrawerController extends BaseController {
  openShipmentTransportationLocationOriginContainerYard = () => {
    const currentOriginContainerYardData = R.selectors.shipmentTransportationLocation.getOriginContainerYard(
      this.store.getState(),
    );

    if (currentOriginContainerYardData) {
      this.dispatch(R.actions.shipmentTransportationLocationData.setFullData(currentOriginContainerYardData));
      this.dispatch(R.actions.shipmentTransportationLocationData.setInitialData(currentOriginContainerYardData));
    } else {
      this.dispatch(R.actions.shipmentTransportationLocationData.setType(ShipmentRoutesType.ORIGIN_CONTAINER_YARD));
    }

    this.dispatch(R.actions.shipmentDrawer.openDrawer(
      ShipmentDrawerContent.SHIPMENT_TRANSPORTATION_LOCATION_ORIGIN_CONTAINER_YARD,
    ));
  }

  openShipmentTransportationLocationOriginContainerTerminalOperator = () => {
    const currentOriginContainerTerminalData = R.selectors.shipmentTransportationLocation.getOriginContainerTerminal(
      this.store.getState(),
    );

    if (currentOriginContainerTerminalData) {
      this.dispatch(R.actions.shipmentTransportationLocationData.setFullData(currentOriginContainerTerminalData));
      this.dispatch(R.actions.shipmentTransportationLocationData.setInitialData(currentOriginContainerTerminalData));
    } else {
      this.dispatch(R.actions.shipmentTransportationLocationData.setType(ShipmentRoutesType.ORIGIN_CONTAINER_TERMINAL));
    }

    this.dispatch(R.actions.shipmentDrawer.openDrawer(
      ShipmentDrawerContent.SHIPMENT_TRANSPORTATION_LOCATION_ORIGIN_CONTAINER_TERMINAL_OPERATOR,
    ));
  }

  openShipmentTransportationLocationDestinationContainerYard = () => {
    const currentDestinationContainerYardData = R.selectors.shipmentTransportationLocation.getDestinationContainerYard(
      this.store.getState(),
    );

    if (currentDestinationContainerYardData) {
      this.dispatch(R.actions.shipmentTransportationLocationData.setFullData(currentDestinationContainerYardData));
      this.dispatch(R.actions.shipmentTransportationLocationData.setInitialData(currentDestinationContainerYardData));
    } else {
      this.dispatch(R.actions.shipmentTransportationLocationData.setType(ShipmentRoutesType.DESTINATION_CONTAINER_YARD));
    }

    this.dispatch(R.actions.shipmentDrawer.openDrawer(
      ShipmentDrawerContent.SHIPMENT_TRANSPORTATION_LOCATION_DESTINATION_CONTAINER_YARD,
    ));
  }

  openShipmentTransportationLocationDestinationContainerTerminalOperator = () => {
    const currentDestinationContainerTerminalData = R.selectors.shipmentTransportationLocation.getDestinationContainerTerminal(
      this.store.getState(),
    );

    if (currentDestinationContainerTerminalData) {
      this.dispatch(R.actions.shipmentTransportationLocationData.setFullData(currentDestinationContainerTerminalData));
      this.dispatch(R.actions.shipmentTransportationLocationData.setInitialData(currentDestinationContainerTerminalData));
    } else {
      this.dispatch(R.actions.shipmentTransportationLocationData.setType(ShipmentRoutesType.DESTINATION_CONTAINER_TERMINAL));
    }

    this.dispatch(R.actions.shipmentDrawer.openDrawer(
      ShipmentDrawerContent.SHIPMENT_TRANSPORTATION_LOCATION_DESTINATION_CONTAINER_TERMINAL_OPERATOR,
    ));
  }

  openBillOfLadingEditPaymentsDrawer = () => {
    const paymentTerms = R.selectors.billOfLadingCommon.getPaymentTerms(this.store.getState());

    if (!paymentTerms) {
      return;
    }

    this.dispatch(R.actions.billOfLadingCommon.setPaymentsFormState({
      key: 'origin',
      value: paymentTerms.origin,
    }));
    this.dispatch(R.actions.billOfLadingCommon.setPaymentsFormState({
      key: 'freight',
      value: paymentTerms.freight,
    }));
    this.dispatch(R.actions.billOfLadingCommon.setPaymentsFormState({
      key: 'destination',
      value: paymentTerms.destination,
    }));
    this.dispatch(R.actions.billOfLadingCommon.setIncotermFormState(paymentTerms.incoterm));

    this.dispatch(R.actions.shipmentDrawer.openDrawer(ShipmentDrawerContent.SHIPMENT_BILL_OF_LADING_EDIT_PAYMENTS));
  }

  openBillOfLadingEditClausesDrawer = () => {
    const { clauses } = R.selectors.billOfLadingCommon.getSIDetails(this.store.getState());

    this.dispatch(R.actions.billOfLadingCommon.setClausesFormState(clauses));
    this.dispatch(R.actions.shipmentDrawer.openDrawer(ShipmentDrawerContent.SHIPMENT_BILL_OF_LADING_EDIT_CLAUSES));
  }

  openBillOfLadingEditCommentsDrawer = () => {
    const { blComment } = R.selectors.billOfLadingCommon.getSIDetails(this.store.getState());

    this.dispatch(R.actions.billOfLadingCommon.setCommentsFormState(blComment));
    this.dispatch(R.actions.shipmentDrawer.openDrawer(ShipmentDrawerContent.SHIPMENT_BILL_OF_LADING_EDIT_COMMENTS));
  }

  openSIDraftDocumentUploadDrawer = () => {
    const { draftMblDocument } = R.selectors.billOfLadingCommon.getSIDetails(this.store.getState());

    if (draftMblDocument) {
      this.dispatch(R.actions.billOfLadingCommon.setMBLFileFormState(ContainerDocumentDTM.fromPlain({
        uid: draftMblDocument.id.toString(),
        name: draftMblDocument.name,
        status: 'done',
        response: draftMblDocument,
        url: '/',
      })));
    } else {
      this.dispatch(R.actions.billOfLadingCommon.setMBLFileFormState(null));
    }

    this.dispatch(R.actions.shipmentDrawer.openDrawer(ShipmentDrawerContent.SHIPMENT_SI_DRAFT_DOCUMENT_UPLOAD));
  }

  openSIFinalDocumentUploadDrawer = () => {
    const { finalMblDocument } = R.selectors.billOfLadingCommon.getSIDetails(this.store.getState());

    if (finalMblDocument) {
      this.dispatch(R.actions.billOfLadingCommon.setMBLFileFormState(ContainerDocumentDTM.fromPlain({
        uid: finalMblDocument.id.toString(),
        name: finalMblDocument.name,
        status: 'done',
        response: finalMblDocument,
        url: '/',
      })));
    } else {
      this.dispatch(R.actions.billOfLadingCommon.setMBLFileFormState(null));
    }

    this.dispatch(R.actions.shipmentDrawer.openDrawer(ShipmentDrawerContent.SHIPMENT_SI_FINAL_DOCUMENT_UPLOAD));
  }

  openAdditionalDocumentUploadDrawer = () => {
    this.dispatch(R.actions.shipmentDocumentsAll.clearAdditionalFormData());
    this.dispatch(R.actions.shipmentDrawer.openDrawer(ShipmentDrawerContent.SHIPMENT_ADDITIONAL_DOCUMENT_UPLOAD));
  }

  openOrganizationDocumentUploadDrawer = (documentType: DocumentType) => {
    this.dispatch(userManagementR.actions.customer.clearDocumentUploadFormData());
    this.dispatch(userManagementR.actions.customer.setDocumentToUploadType(documentType));
    this.dispatch(R.actions.shipmentDrawer.openDrawer(ShipmentDrawerContent.ORGANIZATION_DOCUMENT_UPLOAD));
  }

  openNewOrganizationDocumentUploadDrawer = (documentType: DocumentType) => {
    this.dispatch(userManagementR.actions.newOrganizationDocuments.clearDocumentUploadFormData());
    this.dispatch(userManagementR.actions.newOrganizationDocuments.setDocumentToUploadType(documentType));
    this.dispatch(R.actions.shipmentDrawer.openDrawer(ShipmentDrawerContent.NEW_ORGANIZATION_DOCUMENT_UPLOAD));
  }

  openEditBillOfLadingDrawer = async (shipmentId: string) => {
    UC.billOfLading.reset();
    this.dispatch(R.actions.shipmentDrawer.openDrawer(ShipmentDrawerContent.EDIT_BILL_OF_LADING));

    await UC.billOfLading.fetchBillOfLading(shipmentId);
  }

  openEditExportClearanceDrawer = async () => {
    UC.exportClearance.reset();
    this.dispatch(R.actions.shipmentDrawer.openDrawer(ShipmentDrawerContent.SHIPMENT_BILL_OF_LADING_EDIT_EXPORT_CLEARANCE));
    this.dispatch(R.actions.exportClearance.setExportClearanceType(ExportClearanceType.ITN));

    const currentExportClearance = R.selectors.billOfLadingCommon.getExportCustoms(this.store.getState());

    if (currentExportClearance) {
      this.dispatch(R.actions.exportClearance.setExportClearance(CustomsClearanceDTM.fromPlain({
        ...currentExportClearance,
      })));
      this.dispatch(R.actions.exportClearance.setExportClearanceNumber(currentExportClearance.number));
    }

    this.dispatch(R.actions.exportClearance.setLoading(false));
  }

  openEditTradeReferenceDrawer = () => {
    const organization = userManagementR.selectors.customer.getOrganization(this.store.getState());

    this.dispatch(userManagementR.actions.customer.setTradeReference(organization?.tradeReference || OrganizationTradeReferenceDTM.createEmpty()));
    this.dispatch(userManagementR.actions.customer.setWasTradeReferenceOnceSubmitted(false));
    this.dispatch(R.actions.shipmentDrawer.openDrawer(ShipmentDrawerContent.EDIT_TRADE_REFERENCE));
  }

  openEditTradeAccountingDrawer = () => {
    const organization = userManagementR.selectors.customer.getOrganization(this.store.getState());

    this.dispatch(userManagementR.actions.customer.setPaymentMethodForm(OrganizationPaymentMethodDTM.fromPlain({
      ...OrganizationPaymentMethodDTM.createEmpty(),
      ...organization?.paymentMethod,
      type: organization?.paymentMethod?.getTypeSelect() || '',
    })));
    this.dispatch(userManagementR.actions.customer.setWasPaymentMethodOnceSubmitted(false));
    this.dispatch(R.actions.shipmentDrawer.openDrawer(ShipmentDrawerContent.EDIT_TRADE_ACCOUNTING));
  }

  closeDrawer = () => {
    this.dispatch(R.actions.shipmentDrawer.closeDrawer());
  }
}
