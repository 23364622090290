import { createSelector } from 'reselect';

import { RootState } from 'app-wrapper/store';
import i18n from 'app-wrapper/i18n/i18n';

import {
  IRouteLegDTM,
  ShareInvoiceChargeDTM,
  ShareInvoiceShipmentDetailsDTM,
  InvoicesChargeDTM,
} from 'shipment-operations/models/dtm';
import {
  FREIGHT_MODE_NAMES,
  LOAD_TYPE_NAMES,
  CONTAINER_TYPES_TO_NAME,
  ShipmentFreightMode,
  ContainerReeferTypes,
  ContainerUsualTypes,
  ChargeCodeMeasureBy,
  CHARGE_MEASURE_BY_NAMES,
} from 'shipment-operations/constants';

import { IQuotaServiceByIdContentRoutesLegsLocationDTM } from 'monetary/models/dtm';
import {
  TRouteLegPhase,
} from 'monetary/constants';
import { EOrganizationMemberRole } from 'user-management/constants';

const getLocationNameFromLocation = (location: IQuotaServiceByIdContentRoutesLegsLocationDTM): string => `${location.name}, ${location.country?.name}`;
const getChargeName = (item: InvoicesChargeDTM) => (item.charge.chargeCode.code === 'MSC' && item.charge.metadata?.originalDescription ? item.charge.metadata?.originalDescription : item.charge.chargeCode.description);

const localState = (state: RootState) => state.shareInvoice;

const getIsLoading = createSelector(
  localState,
  (state) => state.isLoading,
);

const getLowestSequenceLeg = createSelector(
  localState,
  (state) => state.lowestSequenceLeg,
);

const getLowestSequenceSeaLeg = createSelector(
  localState,
  (state) => state.lowestSequenceSeaLeg,
);

const getHighestSequenceSeaLeg = createSelector(
  localState,
  (state) => state.highestSequenceSeaLeg,
);

const getHighestSequenceLeg = createSelector(
  localState,
  (state) => state.highestSequenceLeg,
);

const getIsLoadingFinished = createSelector(
  localState,
  (state) => state.isLoadingFinished,
);

const getShipmentInvoiceFileNamePrint = createSelector(
  localState,
  () => {
    const fileName = 'Skypace. Invoice #';

    return fileName;
  },
);

const getPayableInvoice = createSelector(
  localState,
  (state) => state.payableInvoice,
);

const getReceivableInvoice = createSelector(
  localState,
  (state) => state.receivableInvoice,
);

const getBillFromContact = createSelector(
  localState,
  (state) => state.billFromContact,
);

const getBillTo = createSelector(
  localState,
  (state) => state.billToAddress,
);

const getBillFrom = createSelector(
  localState,
  (state) => state.billFromAddress,
);

const getBillFromCompany = createSelector(
  localState,
  (state) => state.billFromCompany,
);

const getBillToCompany = createSelector(
  localState,
  (state) => state.billToCompany,
);

const getBillFromAddress = createSelector(
  getBillFrom,
  (address) => (address ? `${address.address1} ${address.address2 || ''}` : ''),
);

const getBillFromAddressLine = createSelector(
  getBillFrom,
  (address) => (address ? `${address.city}, ${address.state || ''} ${address.postalCode}, ${address.country}` : ''),
);

const getBillFromPhone = createSelector(
  getBillFromContact,
  (contact) => (contact && contact.phone ? contact.phone : i18n.t('Phone is not specified') as string),
);

const getBillFromEmail = createSelector(
  getBillFromContact,
  (contact) => (contact && contact.email ? contact.email : i18n.t('Email is not specified') as string),
);

const getBillFromCompanyName = createSelector(
  getBillFromCompany,
  (company) => (company ? company.name : ''),
);

const getInvoiceNumber = createSelector(
  getReceivableInvoice,
  (invoice) => (invoice ? invoice.number : ''),
);

const getBillToCompanyName = createSelector(
  getBillToCompany,
  (company) => (company ? company.name : ''),
);

const getBillToAddress = createSelector(
  getBillTo,
  (address) => (address ? `${address.address1} ${address.address2 || ''}` : ''),
);

const getBillToAddressLine = createSelector(
  getBillTo,
  (address) => (address ? `${address.city}, ${address.state || ''} ${address.postalCode}, ${address.country}` : ''),
);

const getInvoiceCreatedAt = createSelector(
  getReceivableInvoice,
  (invoice) => (invoice && invoice.createdAt ? invoice.createdAt : ''),
);

const getInvoiceDueDate = createSelector(
  getReceivableInvoice,
  (invoice) => (invoice ? invoice.dueDate : ''),
);

const getInvoiceBilledAmount = createSelector(
  getReceivableInvoice,
  (invoice) => (invoice ? invoice.billed : 0),
);

const getFullShipment = createSelector(
  localState,
  (state) => state.fullShipment,
);

const getIsBillToOrganizationCustomer = createSelector(
  localState,
  (state) => state.billToOrganization?.role === EOrganizationMemberRole.CUSTOMER,
);

const getSeaTransportationWithLowestSequance = createSelector(
  localState,
  getFullShipment,
  (state, shipment) => {
    if (!shipment) {
      return null;
    }

    const { transportationPlans } = shipment;
    const [plan] = transportationPlans;
    const { route, transportations } = plan;
    const { legs } = route;

    const seaTransportations = transportations.filter(({ transport }) => transport.type === ShipmentFreightMode.SEA);
    const seaLegs = seaTransportations.map(({ transportLeg }) => legs.find(({ id }) => id === transportLeg) as IRouteLegDTM);

    let lowestSeaSequence = seaLegs[0].sequence;
    let lowestSeaLeg = seaLegs[0];

    seaLegs.forEach((leg) => {
      if (leg.sequence < lowestSeaSequence) {
        lowestSeaSequence = leg.sequence;
        lowestSeaLeg = leg;
      }
    });

    return transportations.find(({ transportLeg }) => transportLeg === lowestSeaLeg.id);
  },
);

const getRoutingInformationItems = createSelector(
  localState,
  getLowestSequenceLeg,
  getHighestSequenceLeg,
  getLowestSequenceSeaLeg,
  getHighestSequenceSeaLeg,
  (
    state,
    lowestLeg,
    highestLeg,
    lowestSeaLeg,
    highestSeaLeg,
  ) => {
    const items: ShareInvoiceShipmentDetailsDTM[] = [];

    if (lowestLeg?.departureLocation) {
      items.push(ShareInvoiceShipmentDetailsDTM.fromPlain({
        title: i18n.t('Place of Receipt'),
        subtitles: [getLocationNameFromLocation(lowestLeg?.departureLocation)],
      }));
    }

    if (lowestSeaLeg?.departureLocation) {
      items.push(ShareInvoiceShipmentDetailsDTM.fromPlain({
        title: i18n.t('Port of Loading'),
        subtitles: [getLocationNameFromLocation(lowestSeaLeg?.departureLocation)],
      }));
    }

    if (highestSeaLeg?.arrivalLocation) {
      items.push(ShareInvoiceShipmentDetailsDTM.fromPlain({
        title: i18n.t('Port of Discharge'),
        subtitles: [getLocationNameFromLocation(highestSeaLeg?.arrivalLocation)],
      }));
    }

    if (highestLeg?.arrivalLocation) {
      items.push(ShareInvoiceShipmentDetailsDTM.fromPlain({
        title: i18n.t('Place of Delivery'),
        subtitles: [getLocationNameFromLocation(highestLeg?.arrivalLocation)],
      }));
    }

    return items;
  },
);

const getCargoItems = createSelector(
  localState,
  getFullShipment,
  (state, shipment) => {
    if (!shipment) {
      return [];
    }

    const items: ShareInvoiceShipmentDetailsDTM[] = [];
    const { cargos } = shipment;

    const {
      volume,
      weight,
      packagesNumber,
    } = cargos.reduce((prev, next) => ({
      volume: prev.volume + (Number(next.volume) || 0),
      weight: prev.weight + (Number(next.weight) || 0),
      packagesNumber: prev.packagesNumber + (Number(next.packagesNumber) || 0),
    }), {
      volume: 0,
      weight: 0,
      packagesNumber: 0,
    });

    items.push(ShareInvoiceShipmentDetailsDTM.fromPlain({
      title: i18n.t('General Cargo'),
      subtitles: [
        `${i18n.t('Total Quantity')}: ${packagesNumber}`,
        `${i18n.t('Total Weight')}: ${weight}`,
        `${i18n.t('Total Volume')}: ${volume}`,
      ],
    }));

    return items;
  },
);

const getContainersItems = createSelector(
  localState,
  getFullShipment,
  (state, shipment) => {
    if (!shipment) {
      return [];
    }

    const items: ShareInvoiceShipmentDetailsDTM[] = [];
    const { containers } = shipment;
    const containersToTypeMap: Record<ContainerUsualTypes | ContainerReeferTypes, number> = {
      [ContainerUsualTypes['22G0']]: 0,
      [ContainerUsualTypes['45G0']]: 0,
      [ContainerUsualTypes['42G0']]: 0,
      [ContainerUsualTypes.L5G0]: 0,
      [ContainerReeferTypes['22R0']]: 0,
      [ContainerReeferTypes['25R1']]: 0,
      [ContainerReeferTypes['42R0']]: 0,
      [ContainerReeferTypes['45R1']]: 0,
      [ContainerReeferTypes.L5R1]: 0,
    };

    containers.forEach(({ type }) => {
      containersToTypeMap[type] += 1;
    });

    const subtitles: string[] = [];

    Object.keys(containersToTypeMap).forEach((containersKey) => {
      const containersAmount = containersToTypeMap[containersKey as ContainerUsualTypes | ContainerReeferTypes];

      if (containersAmount) {
        subtitles.push(`${CONTAINER_TYPES_TO_NAME[containersKey as ContainerUsualTypes | ContainerReeferTypes]} × ${containersAmount}`);
      }
    });

    items.push(ShareInvoiceShipmentDetailsDTM.fromPlain({
      title: i18n.t('Container(s)'),
      subtitles,
    }));

    return items;
  },
);

const getShipmentDetailsItems = createSelector(
  localState,
  getFullShipment,
  getSeaTransportationWithLowestSequance,
  getRoutingInformationItems,
  getCargoItems,
  getContainersItems,
  (
    state,
    shipment,
    lowestSeaTransportation,
    routingItems,
    cargoItems,
    containersItems,
  ) => {
    if (!shipment) {
      return [];
    }

    const { transportationPlans } = shipment;
    const plan = transportationPlans[0];

    let items: ShareInvoiceShipmentDetailsDTM[] = [];

    if (plan) {
      items.push(ShareInvoiceShipmentDetailsDTM.fromPlain({
        title: i18n.t('Transport'),
        subtitles: [FREIGHT_MODE_NAMES[plan.freightMode]],
      }));

      items.push(ShareInvoiceShipmentDetailsDTM.fromPlain({
        title: i18n.t('Mode'),
        subtitles: [LOAD_TYPE_NAMES[plan.loadType]],
      }));
    }

    if (lowestSeaTransportation) {
      items.push(ShareInvoiceShipmentDetailsDTM.fromPlain({
        title: i18n.t('Vessel'),
        subtitles: [lowestSeaTransportation.transport.name],
      }));

      if (lowestSeaTransportation.voyageCode) {
        items.push(ShareInvoiceShipmentDetailsDTM.fromPlain({
          title: i18n.t('Voyage'),
          subtitles: [`${lowestSeaTransportation.voyageCode}`],
        }));
      }
    }

    if (routingItems.length) {
      items = [
        ...items,
        ...routingItems,
      ];
    }

    if (cargoItems.length) {
      items = [
        ...items,
        ...cargoItems,
      ];
    }

    if (containersItems.length) {
      items = [
        ...items,
        ...containersItems,
      ];
    }

    items.push(ShareInvoiceShipmentDetailsDTM.fromPlain({
      title: i18n.t('Service Ref.'),
      subtitles: [`${shipment.id}`],
    }));

    items.push(ShareInvoiceShipmentDetailsDTM.fromPlain({
      title: i18n.t('Customer Ref.'),
      subtitles: [`${shipment.shipmentReference}`],
    }));

    return items;
  },
);

const chargeFormat = (items: InvoicesChargeDTM[]) => {
  const chargeCodeToChargeMap: Record<string, ShareInvoiceChargeDTM> = {};

  items.forEach((item) => {
    const { charge } = item;

    if (!chargeCodeToChargeMap[charge.chargeCode.code]) {
      chargeCodeToChargeMap[charge.chargeCode.code] = ShareInvoiceChargeDTM.fromPlain({
        chargeName: getChargeName(item),
        currency: charge.currency,
        unit: CHARGE_MEASURE_BY_NAMES[item.charge.measureBy as ChargeCodeMeasureBy],
        costPerUnit: item.costPerUnit,
        quantity: item.numberOfUnits,
        total: item.invoiced,
      });
    } else {
      const currentCharge = chargeCodeToChargeMap[charge.chargeCode.code];

      chargeCodeToChargeMap[charge.chargeCode.code] = ShareInvoiceChargeDTM.fromPlain({
        chargeName: getChargeName(item),
        currency: charge.currency,
        unit: CHARGE_MEASURE_BY_NAMES[item.charge.measureBy as ChargeCodeMeasureBy],
        costPerUnit: item.costPerUnit,
        quantity: item.numberOfUnits + currentCharge.quantity,
        total: item.invoiced + currentCharge.total,
      });
    }
  });
  return chargeCodeToChargeMap;
};

export const getOriginCharges = createSelector(
  localState,
  getReceivableInvoice,
  (state, invoice) => {
    if (!invoice) {
      return [];
    }

    const { charges } = invoice;

    const originItems = charges.filter(
      (item) => item.charge.designation === TRouteLegPhase.ORIGIN && item.invoiced !== 0,
    );

    return Object.values(chargeFormat(originItems));
  },
);

export const getFreightCharges = createSelector(
  localState,
  getReceivableInvoice,
  (state, invoice) => {
    if (!invoice) {
      return [];
    }

    const { charges } = invoice;

    const freightItems = charges.filter(
      (item) => item.charge.designation === TRouteLegPhase.FREIGHT && item.invoiced !== 0,
    );

    return Object.values(chargeFormat(freightItems));
  },
);

export const getDestinationCharges = createSelector(
  localState,
  getReceivableInvoice,
  (state, invoice) => {
    if (!invoice) {
      return [];
    }

    const { charges } = invoice;

    const destinationItems = charges.filter(
      (item) => item.charge.designation === TRouteLegPhase.DESTINATION && item.invoiced !== 0,
    );

    return Object.values(chargeFormat(destinationItems));
  },
);

const getIsTermVisible = createSelector(
  localState,
  (state) => state.isTermVisible,
);

const getCustomerCreditTerm = createSelector(
  localState,
  (state) => state.customerCreditTerm,
);

const getCustomerAccountingDepartment = createSelector(
  localState,
  (state) => state.customerAccountingDepartment,
);

const getCurrentOrgRelatedAdmin = createSelector(
  localState,
  (state) => state.currentOrgRelatedAdmin,
);

const getIsVATVisible = createSelector(
  localState,
  (state) => state.isVATVisible,
);

const getShipment = createSelector(
  localState,
  (state) => state.shipment,
);

export const shareInvoiceSelectors = {
  getIsLoading,
  getIsLoadingFinished,
  getShipmentInvoiceFileNamePrint,
  getPayableInvoice,
  getReceivableInvoice,
  getBillFromAddress,
  getBillFromAddressLine,
  getBillFromPhone,
  getBillFromEmail,
  getBillFromCompanyName,
  getInvoiceNumber,
  getBillToCompanyName,
  getBillToAddress,
  getBillToAddressLine,
  getInvoiceCreatedAt,
  getInvoiceDueDate,
  getInvoiceBilledAmount,
  getShipmentDetailsItems,
  getOriginCharges,
  getFreightCharges,
  getDestinationCharges,
  getIsBillToOrganizationCustomer,
  getIsTermVisible,
  getCustomerCreditTerm,
  getCustomerAccountingDepartment,
  getCurrentOrgRelatedAdmin,
  getIsVATVisible,
  getShipment,
};
