import styled from 'styled-components';
import Table from 'antd/es/table';
import Typography from 'antd/es/typography';
import Alert from 'antd/es/alert';
import { InfoCircleFilled } from '@ant-design/icons';

import { divFlex } from 'app-wrapper/view/themes/mixin';
import { ExpandIcon } from 'app-wrapper/view/components/ExpandIcon';

interface ITableProps {
  isOpen?: boolean,
}

export const SBLTableLayout = styled('div').attrs({
  'data-class': 'SDLTableLayout',
})`
  margin-top: 26px;
`;

export const SBLTableLayoutNoMargin = styled('div').attrs({
  'data-class': 'SDLTableLayout',
})``;

export const SBLWarningIcon = styled(InfoCircleFilled)`
  color: ${({ theme }) => theme.themesColors.characterBrandingWarning};
  margin-left: 4px;
`;

export const SBLAlert = styled(Alert)`
  width: 100%;
  margin-bottom: 8px;
`;

export const StyledExpandIcon = styled(ExpandIcon)`
  margin-top: 5px;
  margin-right: 14px;
`;

export const SBLTable = styled(Table)`
  .ant-table-thead > tr > th {
    background: white;
  }

  .ant-table-thead > tr > th:first-of-type {
    padding-left: 16px;
  }

  .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    display: none;
  }

  .ant-table-thead .ant-table-cell {
    color: #73819B;
    font-size: 12px;
    font-weight: 400;
  }

  tr.ant-table-expanded-row > td, tr.ant-table-expanded-row:hover > td {
    background-color: ${({ theme }) => theme.themesColors.neutralBranding1}
  }
`;

export const SBLTableHeader = styled('div')`
  background-color: ${({ theme }) => theme?.themesColors?.neutralBranding1};
  padding: 16px 16px 0 16px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
`;

export const SBLTableCell = styled(Typography)`
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
`;

export const SBLTableContainerNameCell = styled.div<ITableProps>`
  ${divFlex}
  flex-direction: row;
  align-items: center;
  font-size: 14px;
  line-height: 22px;
  cursor: pointer;
  color: ${({ theme, isOpen }) => {
    if (isOpen) {
      return theme.themesColors.primaryBranding6;
    }

    return theme.themesColors.characterBrandingTitle85;
  }}
`;
