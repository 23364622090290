import styled from 'styled-components';
import Typography from 'antd/es/typography';

import { divFlex } from 'app-wrapper/view/themes/mixin';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const StatusIndicator = styled.div`
  margin-right: 8px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
`;

export const StatusName = styled(Typography).attrs((props: {
  $colorText?: string
  'data-class'?: string
}) => ({
  $colorText: props.$colorText,
  'data-class': props?.['data-class'] || 'OrganizationStatus_StatusName',
}))`
  ${divFlex}
  align-items: center;

  font-size: 14px;
  line-height: 22px;

  color: ${({ theme, $colorText }) => $colorText || theme?.themesColors?.neutralBranding10};
`;

export const StatusIcon = styled.div.attrs((props: {
  $colorText?: string
  'data-class'?: string
}) => ({
  $colorText: props.$colorText,
  'data-class': props?.['data-class'] || 'OrganizationStatus_StatusIcon',
}))`
  ${divFlex}
  display: inline-flex;
  margin-left: 4px;

  color: ${({ theme, $colorText }) => $colorText || theme?.themesColors?.characterBrandingWarning};
`;
