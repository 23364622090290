import React, { useContext } from 'react';
import { MobxStoresContext } from 'app-wrapper/mobxStores';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';

import { UC } from 'user-management/controllers';

import { InputComponent } from 'user-management/view/pages/Account/components/AccountDrawer/components/UserEditProfile/components';

const JobTitleInputContainer = observer(() => {
  const { t } = useTranslation();
  const { userEditProfileStore } = useContext(MobxStoresContext);
  const { isLoading, jobTitle } = userEditProfileStore.state;

  return (
    <InputComponent
      isLoading={isLoading}
      onChange={UC.userEditProfile.setJobTitle}
      onBlur={() => null}
      value={jobTitle}
      title={t('Job Title')}
    />
  );
});

export { JobTitleInputContainer as JobTitleInput };
