import React, { useMemo, FC } from 'react';
import { useTranslation } from 'react-i18next';

import { EditableCell } from 'shipment-operations/view/pages/EditPayment/components';
import { EditableRow } from 'shipment-operations/view/components';

import { normalizationCost } from 'app-wrapper/utils';

import {
  StyledTable, TableHeader,
} from 'shipment-operations/view/components/CustomTable/CustomTable.styled';

import Divider from 'antd/es/divider';
import {
  AmountKey,
  AmountLine, AmountValue,
  AmountWrapper,
  BottomWrapper,
  UploadWrapper,
} from 'shipment-operations/view/components/PaymentTable/PaymentTable.styled';
import { useParams } from 'react-router-dom';
import { RECEIVABLES } from 'shipment-operations/constants';
import { useTableEditable } from './useTableEdit';

import { UploadPaymentDocument } from './components';
import { Wrapper, Title } from './Main.styled';

interface IMainComponentProps {
  data: {
    id?: string
    dueDate?: string
    total?: number
    balance?: number
    payment?: number
    innerId?: number
  }[]
  totalAmount: number
  usedAmount: number
  leftAmount: number
}

const MainComponent: FC<IMainComponentProps> = ({
  data, totalAmount, usedAmount, leftAmount,
}) => {
  const { t } = useTranslation();
  const { invoiceType } = useParams<'invoiceType'>();
  const columns = useMemo(() => [
    {
      title: t('payment.id'),
      dataIndex: 'id',
      key: 'id',
      width: '20%',
    },
    {
      title: t('payment.dueDate'),
      dataIndex: 'dueDate',
      key: 'dueDate',
      width: '20%',
    },
    {
      title: t('payment.total'),
      dataIndex: 'total',
      key: 'total',
      width: '20%',
      align: 'right' as 'right',
      render: (sum: number) => `$ ${normalizationCost(sum, { toFixed: 2, thousandthSeparatorComma: true })}`,
    },
    {
      title: t('payment.balance'),
      dataIndex: 'balance',
      key: 'balance',
      width: '20%',
      align: 'right' as 'right',
      render: (sum: number) => `$ ${normalizationCost(sum, { toFixed: 2, thousandthSeparatorComma: true })}`,
    },
    {
      title: t('payment.payment'),
      dataIndex: 'payment',
      key: 'payment',
      width: '20%',
      align: 'right' as 'right',
      editable: true,
    },
  ], []);

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const {
    dataSource: editableData,
    columns: editableColumns,
  } = useTableEditable(data, columns);

  return (
    <Wrapper>
      <Title>{invoiceType === RECEIVABLES ? t('payment.paidForInvoices') : t('payment.paidForBills')}</Title>
      <TableHeader columns={editableColumns} />
      <StyledTable
        components={components}
        pagination={false}
        showHeader={false}
        dataSource={editableData}
        columns={editableColumns}
      />
      <Divider />
      <BottomWrapper>
        <UploadWrapper data-class="edit-payment-upload">
          <UploadPaymentDocument />
        </UploadWrapper>

        <AmountWrapper>
          <AmountLine>
            <AmountKey>{invoiceType === RECEIVABLES ? t('payment.amountReceived') : t('payment.amountPaid')}</AmountKey>
            <AmountValue>$ {normalizationCost(totalAmount, { toFixed: 2, thousandthSeparatorComma: true })}</AmountValue>
          </AmountLine>
          <Divider style={{ margin: '8px 0' }} />
          <AmountLine>
            <AmountKey>{t('payment.amountUsed')}</AmountKey>
            <AmountValue>$ {normalizationCost(usedAmount, { toFixed: 2, thousandthSeparatorComma: true })}</AmountValue>
          </AmountLine>
          <Divider style={{ margin: '8px 0' }} />
          <AmountLine>
            <AmountKey>{t('payment.amountLeft')}</AmountKey>
            <AmountValue>$ {normalizationCost(leftAmount, { toFixed: 2, thousandthSeparatorComma: true })}</AmountValue>
          </AmountLine>
        </AmountWrapper>
      </BottomWrapper>
    </Wrapper>
  );
};

export { MainComponent };
