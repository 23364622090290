import React from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';

import { CarrierComponent } from './Carrier.component';

const CarrierContainer = () => {
  const isPending = useSelector(R.selectors.manualBookingWizard.getIsLoading);

  return (
    <CarrierComponent isPending={isPending} />
  );
};

export { CarrierContainer as Carrier };
