import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';

import { CargoFormTitleComponent } from './CargoFormTitle.component';

const CargoFormTitleContainer: FC = () => {
  const { name, description } = useSelector(R.selectors.cargo.getCargo);

  return (
    <CargoFormTitleComponent
      name={name}
      description={description}
    />
  );
};

export {
  CargoFormTitleContainer as CargoFormTitle,
};
