import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Header,
  TitleRow,
  TitleRowText,
  SubTitleRow,
  TransitWrap,
  TransitItem,
  TransitItemText,
  TransitStorageIconWrap,
  TransitStorageIcon,
  TransitShipIcon,
  TransitItemDivider,
  ScheduleWrap,
  ScheduleText,
  ScheduleTextGrey,
} from './Header.styled';

interface HeaderComponentProps {
  originDate: string;
  destinationDate: string;
  destinationLocationName: string;
  originLocationName: string;
  totalCost: number;
}

export const HeaderComponent: FC<HeaderComponentProps> = ({
  originDate,
  destinationDate,
  destinationLocationName,
  originLocationName,
  totalCost,
}) => {
  const { t } = useTranslation();

  return (
    <Header>
      <TitleRow>
        <TitleRowText>
          {t('Maxton Shipping')}
        </TitleRowText>

        <TitleRowText data-testid="roll-drawer-total-cost-testid">
          {t('$ ')}
          {totalCost.toFixed(2)}
        </TitleRowText>
      </TitleRow>

      <SubTitleRow>
        <TransitWrap>
          <TransitItem>
            <TransitStorageIconWrap>
              <TransitStorageIcon />
            </TransitStorageIconWrap>

            <TransitItemText>
              {originLocationName}
            </TransitItemText>
          </TransitItem>

          <TransitItemDivider />

          <TransitItem>
            <TransitShipIcon />
            <TransitItemText>
              {destinationLocationName}
            </TransitItemText>
          </TransitItem>
        </TransitWrap>

        <ScheduleWrap>
          <ScheduleTextGrey>
            {t('Current Schedule')}
          </ScheduleTextGrey>

          <ScheduleText>
            {t('ETD')}
            {' '}
            {originDate}
            {' '}
            -
            {' '}
            {t('ETA')}
            {' '}
            {destinationDate}
          </ScheduleText>
        </ScheduleWrap>
      </SubTitleRow>
    </Header>
  );
};
