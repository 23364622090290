import styled from 'styled-components';
import Typography from 'antd/es/typography';

import { divFlex } from 'app-wrapper/view/themes/mixin';

export const HeaderValue = styled.p`
  font-weight: 400;
  font-size: 13.5px;
  margin: 4px 0;
`;

export const NORContentWrap = styled.div.attrs({
  'data-class': 'OrganizationTermOfServiceComponent_NORContentWrap',
})`
  ${divFlex}
  flex-direction: column;
  width: 100%;
`;

export const PageBreak = styled.div.attrs((props: {
  marginBottom?: string
  'data-class'?: string
}) => ({
  marginBottom: props.marginBottom,
  'data-class': props?.['data-class'] || 'PageBreak',
}))`
  page-break-before: always;
  margin-bottom: ${({ marginBottom }) => marginBottom || ''};
`;

export const Wrap = styled.div`
  ${divFlex}

  flex-direction: column;
  margin: 0 32px;
  padding: 16px;
`;

export const HeaderBoldValue = styled(HeaderValue)`
  font-weight: 700;
`;

export const HeaderData = styled.div.attrs((props: {
  light?: boolean
  'data-class'?: string
}) => ({
  light: props.light,
  'data-class': props?.['data-class'] || 'HeaderData',
}))`
  ${divFlex}
  color: ${({ light, theme }) => (light ? theme?.themesColors?.neutralBranding10 : theme?.themesColors?.neutralBranding1)};
`;

export const Logo = styled.div`
  width: auto;
`;

export const HeaderWrapper = styled.div.attrs((props: {
  light?: boolean
  'data-class'?: string
}) => ({
  light: props.light,
  'data-class': props?.['data-class'] || 'HeaderWrapper',
}))`
  ${divFlex}
  background-color: ${({ light, theme }) => (light ? theme?.themesColors?.neutralBranding1 : theme?.themesColors?.neutralBranding10)};
  height: 113px;
  padding: 0 32px;
  align-items: center;
  justify-content: space-between;
  color: ${({ light, theme }) => (light ? theme?.themesColors?.neutralBranding10 : theme?.themesColors?.neutralBranding1)};
`;

export const Separator = styled.div`
  width: 100%;
  height: 0.3px;
  background-color: ${({ theme }) => theme?.themesColors?.neutralBranding6};
`;

export const Title = styled(Typography)`
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  padding-bottom: 16px;
  margin-bottom: 16px;
  border-bottom: 1px dashed rgba(234, 238, 248, 1);
`;

export const NORContentHeader = styled.div.attrs({
  'data-class': 'OrganizationTermOfServiceComponent_NORContentHeader',
})`
  ${divFlex}
  flex-direction: column;
  width: 100%;

  font-size: 14px;
  line-height: 22px;
  font-weight: 500;
  margin-bottom: 8px;
  color: ${({ theme }) => theme?.themesColors?.neutralBranding10};
`;

export const NORContent = styled.div.attrs((props: {
  isEnd: boolean
  'data-class'?: string
}) => ({
  isEnd: props.isEnd,
  'data-class': props?.['data-class'] || 'OrganizationTermOfServiceComponent_NORContent',
}))`
  ${divFlex}
  flex-direction: column;
  width: 100%;

  ${({ isEnd }) => (isEnd && 'margin-bottom: 64px;')};
`;

export const NORContentDate = styled.div.attrs({
  'data-class': 'OrganizationTermOfServiceComponent_NORContentDate',
})`
  ${divFlex}
  width: 100%;
  margin-bottom: 16px;

  font-size: 12px;
  font-weight: 400;
  color: ${({ theme }) => theme?.themesColors?.secondaryDot45};
`;

export const NORContentParagraph = styled.div.attrs((props: {
  isNoneMarginBottom: boolean
  'data-class'?: string
}) => ({
  isNoneMarginBottom: props.isNoneMarginBottom,
  'data-class': props?.['data-class'] || 'OrganizationTermOfServiceComponent_NORContentParagraph',
}))`
  ${divFlex}
  width: 100%;
  margin-bottom: ${({ isNoneMarginBottom }) => (isNoneMarginBottom ? '' : '16px')};
  display: block;

  text-align: justify;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  color: ${({ theme }) => theme?.themesColors?.neutralBranding10};
`;

export const NORContentParagraphPaddingLeft = styled(NORContentParagraph)`
  padding-left: 10px;
`;

export const NORContentBoldText = styled.span.attrs({
  'data-class': 'OrganizationTermOfServiceComponent_NORContentBoldText',
})`
  ${divFlex}
  display: inline-flex;

  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  color: ${({ theme }) => theme?.themesColors?.neutralBranding10};
`;

export const NORContentTitle = styled.div.attrs({
  'data-class': 'OrganizationTermOfServiceComponent_NORContentTitle',
})`
  ${divFlex}
  width: 100%;
  margin-bottom: 16px;
  padding-left: 22px;

  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  color: ${({ theme }) => theme?.themesColors?.neutralBranding10};
  background-color: ${({ theme }) => theme?.themesColors?.neutralBranding2};
`;

export const NORContentItem = styled.div.attrs((props: {
  isColumn: boolean
  isSecondItem: boolean
  isThirdItem: boolean
  isLastMarginBottom: boolean
  'data-class'?: string
}) => ({
  isColumn: props.isColumn,
  isSecondItem: props.isSecondItem,
  isThirdItem: props.isThirdItem,
  isLastMarginBottom: props.isLastMarginBottom,
  'data-class': props?.['data-class'] || 'OrganizationTermOfServiceComponent_NORContentItem',
}))`
  ${divFlex}
  width: calc(100% - 24px);
  ${({ isColumn }) => (isColumn && 'flex-direction: column')};
  ${({ isSecondItem }) => (isSecondItem && 'margin-left: 24px;')};
  ${({ isThirdItem }) => (isThirdItem && 'margin-left: 46px;')};

  margin-bottom: ${({ isLastMarginBottom }) => (isLastMarginBottom ? '16px' : '8px')};
`;

export const NORContentTextUnderline = styled.div.attrs({
  'data-class': 'OrganizationTermOfServiceComponent_NORContentItemNumber',
})`
  display: inline;
  text-decoration: underline;
`;

export const NORContentItemNumber = styled.div.attrs({
  'data-class': 'OrganizationTermOfServiceComponent_NORContentItemNumber',
})`
  ${divFlex}
  width: 18px;
  margin-right: 4px;

  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  color: ${({ theme }) => theme?.themesColors?.neutralBranding10};
`;

export const NORContentTitleEnd = styled.div.attrs({
  'data-class': 'OrganizationTermOfServiceComponent_NORContentTitleEnd',
})`
  ${divFlex}
  color: ${({ theme }) => theme?.themesColors?.secondaryDot45};

  font-size: 12px;
  line-height: 24px;
  font-weight: 400;
  padding-top: 8px;
  margin-bottom: 16px;
  border-top: 1px dashed rgba(234, 238, 248, 1);
`;

export const SignaturesWrap = styled.div.attrs((props: {
  marginTop?: string
  'data-class'?: string
}) => ({
  marginTop: props.marginTop,
  'data-class': props?.['data-class'] || 'SignaturesWrap',
}))`
  ${divFlex}

  flex-direction: column;
  margin: 0 32px;
  padding: 16px;

  margin-top: ${({ marginTop }) => marginTop || ''};
`;

export const Signatures = styled.div`
  margin-top: 140px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const Signature = styled.div`
  width: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const SignatureName = styled(Typography)`
  font-family: Caveat;
  margin-bottom: 4px;
  font-size: 20px;
  line-height: 24px;
  font-weight: 500;
  min-height: 24px;
`;

export const SignatureDivider = styled.div`
  width: 100%;
  height: 1px;
  background-color: rgba(0, 0, 0, 1);
`;

export const SignatureLabel = styled(Typography)`
  margin-top: 4px;
  font-size: 10px;
  line-height: 12px;
`;
