import styled from 'styled-components';
import Typography from 'antd/es/typography';

import { colors } from 'app-wrapper/view/themes/themesColors';

export const ShippingPartyGrid = styled.div`
  margin-top: 16px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  
  row-gap: 16px;
  column-gap: 32px;
`;

export const ShippingPartyField = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;

  > .anticon {
    color: #4A55AD;
  }
`;

export const ShippingPartyFieldContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0;
`;

export const ShippingPartyFieldLabel = styled(Typography.Text)`
  font-size: 12px !important;
  color: ${colors.gray61};
`;
