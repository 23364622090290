import { UC as appUC } from 'app-wrapper/controllers';
import { BaseController, controller } from 'proto/BaseController';
import { DocumentType, EAdditionalServiceStep, ExportClearanceType } from 'shipment-operations/constants';
import { ContainerDocumentDTM, CustomsClearanceDTM } from 'shipment-operations/models/dtm';
import { R } from 'shipment-operations/repository';
import { EShipmentOrganizationRole } from 'user-management/constants';
import { R as userManagementR } from 'user-management/repository';
import { validateExportClearanceNumber } from 'shipment-operations/controllers/ExportClearance/ExportClearance.validate';

@controller
export class AdditionalServicesController extends BaseController {
  public initServices = async (shipmentId: string) => {
    this.mobxStore.shipmentServices.setLoading(true);

    const services = await R.services.additionalService.getAdditionalServices(shipmentId);

    this.mobxStore.shipmentServices.setServices(services);
    this.mobxStore.shipmentServices.setLoading(false);
  };

  public initServiceById = async (shipmentId: string, serviceId: string) => {
    this.mobxStore.shipmentServices.setLoading(true);
    this.mobxStore.shipmentServices.setRolesInShipment([]);

    const currentOrg = await userManagementR.services.organization.getCurrentOrganization();
    const shipment = await R.services.shipment.getShipmentShortById(+shipmentId);
    const {
      originPartnerOrgId,
      destinationPartnerOrgId,
      customerOrgId,
    } = shipment;

    if (currentOrg.id === originPartnerOrgId) {
      this.mobxStore.shipmentServices.addRoleInShipment(EShipmentOrganizationRole.ORIGIN_PARTNER);
    }

    if (currentOrg.id === destinationPartnerOrgId) {
      this.mobxStore.shipmentServices.addRoleInShipment(EShipmentOrganizationRole.DESTINATION_PARTNER);
    }

    if (currentOrg.id === customerOrgId) {
      this.mobxStore.shipmentServices.addRoleInShipment(EShipmentOrganizationRole.CUSTOMER);
    }

    const service = await R.services.additionalService.getAdditionalServiceById(shipmentId, serviceId);

    this.mobxStore.shipmentServices.setDocumentActivities(service.documentActivities.filter((activity) => {
      const providersRoles = activity.providers.map(({ role }) => role);
      const overlapRoles = providersRoles.filter((role) => this.mobxStore.shipmentServices.state.rolesInShipment.includes(role));

      return !!overlapRoles.length;
    }));
    this.mobxStore.shipmentServices.setOthersDocumentActivities(service.documentActivities.filter((activity) => {
      const providersRoles = activity.providers.map(({ role }) => role);
      const overlapRoles = providersRoles.filter((role) => this.mobxStore.shipmentServices.state.rolesInShipment.includes(role));

      return !overlapRoles.length;
    }));
    this.mobxStore.shipmentServices.setInputActivities(service.inputActivities.filter((activity) => {
      const providersRoles = activity.providers.map(({ role }) => role);
      const overlapRoles = providersRoles.filter((role) => this.mobxStore.shipmentServices.state.rolesInShipment.includes(role));

      return !!overlapRoles.length;
    }));
    this.mobxStore.shipmentServices.setOthersInputActivities(service.inputActivities.filter((activity) => {
      const providersRoles = activity.providers.map(({ role }) => role);
      const overlapRoles = providersRoles.filter((role) => this.mobxStore.shipmentServices.state.rolesInShipment.includes(role));

      return !overlapRoles.length;
    }));

    this.mobxStore.shipmentServices.setIsManager(this.mobxStore.shipmentServices.state.rolesInShipment.includes(service.manager.role));

    if (this.mobxStore.shipmentServices.getAreRelatedActivitiesCompleted) {
      this.endEditingFlow();
    } else {
      this.initializeStep();
      this.startEditingFlow();
    }

    this.mobxStore.shipmentServices.setService(service);
    this.mobxStore.shipmentServices.setLoading(false);
  };

  public setStep = (step: EAdditionalServiceStep) => {
    this.mobxStore.shipmentServices.setStep(step);
  }

  public setDocumentToUpload = (document: ContainerDocumentDTM | null) => {
    this.mobxStore.shipmentServices.setDocumentToUpload(document);
  }

  public openDocumentUploadDrawer = (documentType: DocumentType, document: ContainerDocumentDTM | null) => {
    this.mobxStore.shipmentServices.setDocumentToUpload(document);
    this.mobxStore.shipmentServices.setDocumentToUploadType(documentType);
    this.mobxStore.shipmentServices.setInitialDocumentToUpload(document || null);
    this.mobxStore.shipmentServices.setDocumentsToDeleteIds(document ? [document.response.id] : []);

    appUC.drawer.openShipmentServicesDocumentUpload();
  }

  public updateServiceAfterChange = async (shipmentId: string, serviceId: string) => {
    const service = await R.services.additionalService.getAdditionalServiceById(shipmentId, serviceId);

    this.mobxStore.shipmentServices.setDocumentActivities(service.documentActivities.filter((activity) => {
      const providersRoles = activity.providers.map(({ role }) => role);
      const overlapRoles = providersRoles.filter((role) => this.mobxStore.shipmentServices.state.rolesInShipment.includes(role));

      return !!overlapRoles.length;
    }));
    this.mobxStore.shipmentServices.setOthersDocumentActivities(service.documentActivities.filter((activity) => {
      const providersRoles = activity.providers.map(({ role }) => role);
      const overlapRoles = providersRoles.filter((role) => this.mobxStore.shipmentServices.state.rolesInShipment.includes(role));

      return !overlapRoles.length;
    }));
    this.mobxStore.shipmentServices.setInputActivities(service.inputActivities.filter((activity) => {
      const providersRoles = activity.providers.map(({ role }) => role);
      const overlapRoles = providersRoles.filter((role) => this.mobxStore.shipmentServices.state.rolesInShipment.includes(role));

      return !!overlapRoles.length;
    }));
    this.mobxStore.shipmentServices.setOthersInputActivities(service.inputActivities.filter((activity) => {
      const providersRoles = activity.providers.map(({ role }) => role);
      const overlapRoles = providersRoles.filter((role) => this.mobxStore.shipmentServices.state.rolesInShipment.includes(role));

      return !overlapRoles.length;
    }));

    this.mobxStore.shipmentServices.setService(service);
  };

  public excludeDocumentIdFromDeletingList = (documentId: number) => {
    const documentsToDeleteIds = [...this.mobxStore.shipmentServices.state.documentsToDeleteIds];
    const targetIdIndex = documentsToDeleteIds.findIndex((id) => id === documentId);

    if (targetIdIndex !== -1) {
      documentsToDeleteIds.splice(targetIdIndex, 1);
    }

    this.mobxStore.shipmentServices.setDocumentsToDeleteIds(documentsToDeleteIds);
  };

  public saveUploadedDocument = async (shipmentId: string, serviceId: string) => {
    const {
      documentToUpload,
      documentToUploadType,
      step,
    } = this.mobxStore.shipmentServices.state;

    if (!documentToUpload || !documentToUploadType) {
      return;
    }

    const documentId = documentToUpload.response.id;

    if (step === EAdditionalServiceStep.UPLOAD_DOCUMENTS || step === EAdditionalServiceStep.ADD_DOCUMENTS) {
      this.mobxStore.shipmentServices.setProvidedDocumentForActivity(documentToUploadType, documentToUpload);
    } else if (step === EAdditionalServiceStep.VALIDATE_CUSTOMER_DOCUMENTS) {
      this.mobxStore.shipmentServices.setProvidedDocumentForOtherActivity(documentToUploadType, documentToUpload);
    }

    if (documentId) {
      this.excludeDocumentIdFromDeletingList(documentId);
    }

    await this.cleanupDeletedDocuments(shipmentId);
    await this.updateServiceAfterChange(shipmentId, serviceId);

    appUC.drawer.closeDrawer();
  }

  public initializeStep = () => {
    const { isManager, documentActivities, othersDocumentActivities } = this.mobxStore.shipmentServices.state;
    const inputITNActivity = this.mobxStore.shipmentServices.getInputITNActivity;
    const inputISFActivity = this.mobxStore.shipmentServices.getInputISFActivity;
    const inputMRNActivity = this.mobxStore.shipmentServices.getInputMRNActivity;

    if (isManager) {
      if (othersDocumentActivities.length) {
        this.setStep(EAdditionalServiceStep.VALIDATE_CUSTOMER_DOCUMENTS);

        return;
      }

      if (documentActivities.length) {
        this.setStep(EAdditionalServiceStep.ADD_DOCUMENTS);

        return;
      }

      if (inputITNActivity) {
        this.setStep(EAdditionalServiceStep.ENTER_ITN_NUMBER);

        return;
      }

      if (inputISFActivity) {
        this.setStep(EAdditionalServiceStep.ENTER_ISF_NUMBER);

        return;
      }

      if (inputMRNActivity) {
        this.setStep(EAdditionalServiceStep.ENTER_MRN_NUMBER);

        return;
      }

      return;
    }

    if (documentActivities.length) {
      this.setStep(EAdditionalServiceStep.UPLOAD_DOCUMENTS);

      return;
    }

    if (inputITNActivity) {
      this.setStep(EAdditionalServiceStep.ENTER_ITN_NUMBER);
    } else if (inputISFActivity) {
      this.setStep(EAdditionalServiceStep.ENTER_ISF_NUMBER);
    } else if (inputMRNActivity) {
      this.setStep(EAdditionalServiceStep.ENTER_MRN_NUMBER);
    }
  }

  public setInputValue = (value: string) => {
    this.mobxStore.shipmentServices.setInputValue(String(value));
    this.mobxStore.shipmentServices.setInputError(undefined);
  }

  public endEditingFlow = () => {
    this.mobxStore.shipmentServices.setIsInEditMode(false);
  }

  public startEditingFlow = () => {
    this.mobxStore.shipmentServices.setIsInEditMode(true);
  }

  public startEditingFlowFromBeginning = () => {
    this.initializeStep();
    this.startEditingFlow();
  };

  public startEditingFlowFromInputActivity = (type: ExportClearanceType) => {
    if (type === ExportClearanceType.ITN) {
      this.setStep(EAdditionalServiceStep.ENTER_ITN_NUMBER);
    } else if (type === ExportClearanceType.ISF) {
      this.setStep(EAdditionalServiceStep.ENTER_ISF_NUMBER);
    } else if (type === ExportClearanceType.MRN) {
      this.setStep(EAdditionalServiceStep.ENTER_MRN_NUMBER);
    }

    this.startEditingFlow();
  }

  public startEditingFlowFromDocuments = () => {
    if (this.mobxStore.shipmentServices.state.isManager) {
      if (this.mobxStore.shipmentServices.state.documentActivities.length) {
        this.setStep(EAdditionalServiceStep.ADD_DOCUMENTS);
      } else {
        this.setStep(EAdditionalServiceStep.VALIDATE_CUSTOMER_DOCUMENTS);
      }
    } else {
      this.setStep(EAdditionalServiceStep.UPLOAD_DOCUMENTS);
    }

    this.startEditingFlow();
  }

  public removeShipmentDocument = async (shipmentId: string, document: ContainerDocumentDTM) => {
    const { service } = this.mobxStore.shipmentServices.state;
    this.mobxStore.shipmentServices.setLoading(true);
    await R.services.shipmentDocument.onRemoveFile(shipmentId, String(document.response.id));

    if (service?.id) {
      await this.updateServiceAfterChange(shipmentId, String(service?.id));
    }

    this.mobxStore.shipmentServices.setLoading(false);
  }

  public setInputError = (error?: string) => {
    this.mobxStore.shipmentServices.setInputError(error);
  };

  public validateInputValue = () => {
    const { inputValue } = this.mobxStore.shipmentServices.state;
    const error = validateExportClearanceNumber(inputValue);

    if (error) {
      this.mobxStore.shipmentServices.setInputError(error.message);

      return;
    }

    this.mobxStore.shipmentServices.setInputError(undefined);
  };

  public getInputActivityByType = (type: ExportClearanceType) => {
    if (type === ExportClearanceType.ITN) {
      return this.mobxStore.shipmentServices.state.inputActivities.find((activity) => activity.code === ExportClearanceType.ITN);
    }

    if (type === ExportClearanceType.ISF) {
      return this.mobxStore.shipmentServices.state.inputActivities.find((activity) => activity.code === ExportClearanceType.ISF);
    }

    return this.mobxStore.shipmentServices.state.inputActivities.find((activity) => activity.code === ExportClearanceType.MRN);
  }

  public closeDocumentUploadDrawer = async (shipmentId: string) => {
    const { initialDocumentToUpload } = this.mobxStore.shipmentServices.state;

    if (initialDocumentToUpload) {
      this.excludeDocumentIdFromDeletingList(initialDocumentToUpload.response.id);
    }

    await this.cleanupDeletedDocuments(shipmentId);
    appUC.drawer.closeDrawer();
  }

  public cleanupDeletedDocuments = async (shipmentId: string) => {
    const { documentsToDeleteIds } = this.mobxStore.shipmentServices.state;

    await Promise.all(documentsToDeleteIds.map((id) => this.deleteDocument(shipmentId, String(id))));

    this.mobxStore.shipmentServices.cleanDocumentsToDeleteIds();
  }

  public addDocumentToDeleteId = (id: number) => {
    this.mobxStore.shipmentServices.addDocumentToDeleteId(id);
  }

  public deleteDocument = async (shipmentId: string, documentId: string) => {
    await R.services.shipmentDocument.onRemoveFile(shipmentId, documentId);
  }

  public submitInputActivity = async (type: ExportClearanceType, shipmentId: string) => {
    this.validateInputValue();

    const {
      inputValue,
      service,
      inputError,
    } = this.mobxStore.shipmentServices.state;
    const activity = this.getInputActivityByType(type);

    if (inputError && type === ExportClearanceType.ITN) {
      return;
    }

    if (activity && activity.getProvidedInput()) {
      let providedInput = activity.getProvidedInput();

      if (providedInput) {
        providedInput = CustomsClearanceDTM.fromPlain({
          ...providedInput,
          number: inputValue,
        });

        await R.services.exportClearance.putExportClearance(shipmentId, providedInput.id, providedInput);
      }
    } else {
      await R.services.exportClearance.postExportClearance(shipmentId, CustomsClearanceDTM.fromPlain({
        number: inputValue,
        type,
        selfCustomsClearance: false,
      }));
    }

    if (service) {
      await this.updateServiceAfterChange(shipmentId, String(service.id));
    }
  };
}
