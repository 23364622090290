import React from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';

import { ShipmentOverviewCargosComponent } from './ShipmentOverviewCargos.component';

const ShipmentOverviewCargosContainer = () => {
  const cargos = useSelector(R.selectors.overview.getCargos);
  const isLoading = useSelector(R.selectors.overview.getLoadingStatus);

  return (
    <ShipmentOverviewCargosComponent cargos={cargos} isLoading={isLoading} />
  );
};

export { ShipmentOverviewCargosContainer as ShipmentOverviewCargos };
