import { createSlice } from 'app-wrapper/createSlice';
import { IAddRatesState } from 'shipment-operations/models/states';

const initialState: IAddRatesState = {
  isLoading: false,
  charges: [],
  chargeCodes: [],
  error: false,
  editableDataByContainer: [],
  editableDataByShipment: [],
  uniqContainers: [],
  contractNumber: '',
  validFrom: '',
  validTo: '',
  bookValidity: '',
  headerErrors: [],
  shipmentTabError: false,
  containerTabError: false,
  addChargesLoading: false,
};

export const addRatesSlice = createSlice({
  name: 'addRates',
  initialState,
  reducers: {
    setIsLoading: (state, { payload }) => ({
      ...state,
      isLoading: payload,
    }),
    setCharges: (state, { payload }) => ({
      ...state,
      charges: payload,
    }),
    setChargeCodes: (state, { payload }) => ({
      ...state,
      chargeCodes: payload,
    }),
    setError: (state, { payload }) => ({
      ...state,
      isLoading: false,
      error: payload,
    }),
    setEditableDataByContainer: (state, { payload }) => ({
      ...state,
      editableDataByContainer: payload,
    }),
    setEditableDataByShipment: (state, { payload }) => ({
      ...state,
      editableDataByShipment: payload,
    }),
    setUniqContainers: (state, { payload }) => ({
      ...state,
      uniqContainers: payload,
    }),
    setHeaderValue: (state, { payload }) => ({
      ...state,
      [payload.name]: payload.value,
    }),
    setTransportationPlans: (state, { payload }) => ({
      ...state,
      transportationPlans: payload,
    }),
    setHeaderErrors: (state, { payload }) => ({
      ...state,
      headerErrors: payload,
    }),
    setTabError: (state, { payload }) => ({
      ...state,
      [payload.tab]: payload.value,
    }),
    setAddChargesLoading: (state, { payload }) => ({
      ...state,
      addChargesLoading: payload,
    }),
    setFRTCharge: (state, { payload }) => ({
      ...state,
      frtCharge: payload,
    }),
  },
});

export const addRatesActions = addRatesSlice.actions;
export const addRatesReducer = addRatesSlice.reducer;
