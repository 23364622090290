import React, { FC, useEffect, useCallback } from 'react';
import { useParams } from 'react-router';
import Spinner from 'antd/es/spin';
import { useTranslation } from 'react-i18next';
import isEmpty from 'lodash/isEmpty';

import { Button } from 'app-wrapper/view/components';
import { ShipmentDrawerWrapper } from 'shipment-operations/view/pages/Shipment/components/ShipmentDrawer/ShipmentDrawer.styled';
import {
  SpinnerWrapper,
  SubTitle,
  TableWrapper,
  Wrapper,
  FooterWrapper,
} from 'shipment-operations/view/drawers/ShipmentChanges/ShipmentChanges.styled';
import { IChangesTable } from 'shipment-operations/models/dtm';

import { CustomTable } from './CustomTable';

interface IBLMismatchesComponentProps {
  loadData: (shipmentId: string) => void;
  loading: boolean;
  cargoTable: IChangesTable[];
  entryNumbers: IChangesTable[];
  containersTable: IChangesTable[];
  paymentTermsTable: IChangesTable[];
  BLOptionsTable: IChangesTable[];
  shipmentPartiesTable: IChangesTable[];
  withSubmitButton: boolean;
  onClose: () => void;
  approveDraftHBL: (shipmentId: string) => void;
}

const BLMismatchesComponent: FC<IBLMismatchesComponentProps> = ({
  loadData, loading, cargoTable, entryNumbers, containersTable,
  paymentTermsTable, BLOptionsTable, shipmentPartiesTable, onClose, withSubmitButton, approveDraftHBL,
}) => {
  const { shipmentId } = useParams();
  const { t } = useTranslation();

  useEffect(() => {
    if (shipmentId) {
      loadData(shipmentId);
    }
  }, [shipmentId]);

  const handleApproveDraftMBL = useCallback(() => {
    if (shipmentId) {
      approveDraftHBL(shipmentId);
    }
  }, [approveDraftHBL, shipmentId]);

  return (
    <ShipmentDrawerWrapper
      title={t('Unsubmitted BL Amendments')}
      visible
      width="1137px"
      closable
      maskClosable={false}
      onClose={onClose}
    >
      <Wrapper>
        {loading ? (
          <SpinnerWrapper>
            <Spinner />
          </SpinnerWrapper>
        ) : (
          <>
            {!isEmpty(shipmentPartiesTable) && (
              <TableWrapper>
                <SubTitle>{t('ShippingParty')}</SubTitle>
                <CustomTable
                  data={shipmentPartiesTable}
                  withKeyRender
                />
              </TableWrapper>
            )}
            {!isEmpty(cargoTable) && (
              <TableWrapper>
                <SubTitle>{t('cargo')}</SubTitle>
                <CustomTable
                  data={cargoTable}
                  withKeyRender
                />
              </TableWrapper>
            )}
            {!isEmpty(entryNumbers) && (
              <TableWrapper>
                <SubTitle>{t('customInformation')}</SubTitle>
                <CustomTable
                  data={entryNumbers}
                  withKeyRender
                />
              </TableWrapper>
            )}
            {!isEmpty(containersTable) && (
              <TableWrapper>
                <SubTitle>{t('Containers')}</SubTitle>
                <CustomTable
                  data={containersTable}
                  withKeyRender
                />
              </TableWrapper>
            )}
            {!isEmpty(paymentTermsTable) && (
              <TableWrapper>
                <SubTitle>{t('paymentTerms')}</SubTitle>
                <CustomTable
                  data={paymentTermsTable}
                  withKeyRender
                />
              </TableWrapper>
            )}
            {!isEmpty(BLOptionsTable) && (
              <TableWrapper>
                <SubTitle>{t('BLoptions')}</SubTitle>
                <CustomTable
                  data={BLOptionsTable}
                  withKeyRender
                  isBlOptions
                />
              </TableWrapper>
            )}
          </>
        )}
      </Wrapper>

      {withSubmitButton ? (
        <FooterWrapper>
          <Button onClick={handleApproveDraftMBL}>
            {t('Submit Amendments')}
          </Button>
        </FooterWrapper>
      ) : null}
    </ShipmentDrawerWrapper>
  );
};

export { BLMismatchesComponent };
