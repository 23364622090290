import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  Wrap,
  Text,
  Title,
  Image,
} from './AllTasksDone.styled';
import noActivitiesSrc from './assets/done.png';

export const AllTasksDone = () => {
  const { t } = useTranslation();

  return (
    <Wrap>
      <Image src={noActivitiesSrc} />

      <Title>
        {t('Well Done! No tasks for today')}
      </Title>

      <Text>
        {t('Tasks related to shipping, account or finance appear here.')}
      </Text>
    </Wrap>
  );
};
