import Typography from 'antd/es/typography';
import { divFlex } from 'app-wrapper/view/themes/mixin';
import styled from 'styled-components';

import { FlexCol } from 'app-wrapper/view/guideline';

import { Message as StyledMessage } from 'user-management/view/components/CreateOrEditTeamDrawer/CreateOrEditTeamDrawer.styled';

export const Fields = styled(FlexCol)`
  padding: 0 24px;
`;

export const FieldLabel = styled.span`
  margin-bottom: 4px;
  font-size: 12px;
  line-height: 20px;
  
  :not(:first-of-type) {
    margin-top: 10px;
  }
`;

export const Message = styled(StyledMessage)`
  margin-top: 10px;
  margin-bottom: 0;
`;

export const AgreeWrap = styled.div.attrs((props: {
  isError: boolean
  'data-class'?: string
}) => ({
  isError: props.isError,
  'data-class': props?.['data-class'] || 'AgreeWrap',
}))`
  ${divFlex}

  margin-top: 10px;

  span.ant-checkbox-inner {
    border-color: ${({ theme, isError }) => isError && theme?.themesColors?.characterBrandingDanger};
  }
`;

export const AgreeText = styled(Typography).attrs((props: {
  isError: boolean
  'data-class'?: string
}) => ({
  isError: props.isError,
  'data-class': props?.['data-class'] || 'AgreeText',
}))`
  margin-left: 4px;
  font-size: 14px;
  line-height: 22px;

  &:first-of-type {
    margin-left: 12px;
  }

  color: ${({ theme, isError }) => isError && theme?.themesColors?.characterBrandingDanger};
`;
