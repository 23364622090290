import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { Button } from 'app-wrapper/view/components';
import { Wrapper, RightButton } from './Footer.styled';

interface IFooterProps {
  onCancel: () => void;
  onOk: (shipmentId: string, type: string) => void;
  addLoading: boolean;
  typeDrawer: string;
}

const Footer: FC<IFooterProps> = ({
  onCancel, onOk, addLoading, typeDrawer,
}) => {
  const { t } = useTranslation();
  const { shipmentId } = useParams<'shipmentId'>();

  const handleOk = useCallback(() => {
    if (shipmentId) {
      onOk(shipmentId, typeDrawer);
    }
  }, []);

  return (
    <Wrapper>
      <Button type="default" size="large" onClick={onCancel}>{t('back')}</Button>
      <RightButton size="large" onClick={handleOk} loading={addLoading}>
        {t('addEstimate')}
      </RightButton>
    </Wrapper>
  );
};

export { Footer };
