import React, { useMemo } from 'react';

import { useSelector } from 'react-redux';
import { R } from 'shipment-operations/repository';
import { UC } from 'shipment-operations/controllers';
import { UC as appUC } from 'app-wrapper/controllers';
import { hasAccess } from 'app-wrapper/utils';

import { PermissionAttributePolicy } from 'shipment-operations/constants';
import { R as appR } from 'app-wrapper/repository';
import { ShipmentMismatchesComponent } from 'shipment-operations/view/drawers/ShipmentMismatches/ShipmentMismatches.component';

const ShipmentMismatchesContainer = () => {
  const permissions = useSelector(R.selectors.shipment.getShipmentChangesPermissions);
  const loading = useSelector(R.selectors.shipmentChanges.getLoading);
  const cutOffTableMismatch = useSelector(R.selectors.shipmentChanges.getCutOffTableMismatch);
  const cutOffTable = useSelector(R.selectors.shipmentChanges.getCutOffTable);
  const actionLoading = useSelector(R.selectors.shipmentChanges.getActionLoading);
  const cargoTable = useSelector(R.selectors.shipmentChanges.getCargoTableMismatch);
  const containersTable = useSelector(R.selectors.shipmentChanges.getContainersTableMismatch);
  const locationsTableMismatch = useSelector(R.selectors.shipmentChanges.getLocationsTableMismatch);
  const locationsTable = useSelector(R.selectors.shipmentChanges.getLocationsTable);
  const isAcceptLoading = useSelector(R.selectors.shipmentChanges.getAcceptLoading);
  const transportPlanCurrent = useSelector(R.selectors.shipmentChanges.getTransportPlanCurrent);
  const transportPlanMismatchCurrent = useSelector(R.selectors.shipmentChanges.getTransportPlanMismatchCurrent);
  const transportPlanMismatchPrevious = useSelector(R.selectors.shipmentChanges.getTransportPlanMismatchPrevious);
  const isTransportPlanMismatch = useSelector(R.selectors.shipmentChanges.getIsTransportPlanMismatches);
  const isCustomerShipment = hasAccess(permissions, PermissionAttributePolicy.READ);
  const shipmentId = useSelector(R.selectors.overview.getShipmentId);
  const isCustomerGlobal = useSelector(appR.selectors.home.getIsCustomer);
  const carrierNumber = useSelector(R.selectors.shipmentChanges.getCarrierNumber);
  const inttraNumber = useSelector(R.selectors.shipmentChanges.getInttraNumber);
  const isCustomer = isCustomerShipment || isCustomerGlobal;
  const showValidate = useSelector(R.selectors.shipmentChanges.getIsShowValidate);
  const validationLoaded = useSelector(R.selectors.shipmentTrackerRoutes.getValidationLoaded);
  const withLocationSection = useSelector(R.selectors.shipmentChanges.getIsWithLocationSection);

  const isDisableSubmit = useMemo(() => (showValidate && !validationLoaded), [showValidate, validationLoaded]);

  return (
    <ShipmentMismatchesComponent
      isCustomer={isCustomer}
      loading={loading}
      loadData={UC.shipmentChanges.loadData}
      cutOffTable={isCustomer ? cutOffTableMismatch : cutOffTable}
      onAccept={UC.shipmentChanges.onAccept}
      onChangeCutOff={UC.shipmentChanges.onChangeCutOff}
      onChangeLocation={UC.shipmentChanges.onChangeLocation}
      onConfirm={UC.shipmentChanges.onConfirmData}
      syncIntraNumber={UC.shipmentChanges.syncIntraNumber}
      closeDrawer={appUC.drawer.closeDrawer}
      actionLoading={actionLoading}
      cargoTable={cargoTable}
      containersTable={containersTable}
      locationsTable={isCustomer ? locationsTableMismatch : locationsTable}
      isAcceptLoading={isAcceptLoading}
      transportPlanCurrent={transportPlanCurrent}
      transportPlanMismatchCurrent={transportPlanMismatchCurrent}
      transportPlanMismatchPrevious={transportPlanMismatchPrevious}
      isTransportPlanMismatch={isTransportPlanMismatch}
      savedShipmentId={shipmentId}
      onChangeCarrierNumber={UC.shipmentChanges.onChangeCarrierNumber}
      onChangeInttraNumber={UC.shipmentChanges.onChangeInttraNumber}
      carrierNumber={carrierNumber}
      inttraNumber={inttraNumber}
      reset={UC.shipmentChanges.onReset}
      showValidate={showValidate}
      isDisableSubmit={isDisableSubmit}
      withLocationSection={withLocationSection}
    />
  );
};

export {
  ShipmentMismatchesContainer as ShipmentMismatches,
};
