import React, { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { CompanyContactDTM } from 'user-management/models/dtm';
import { ContactPersonBlockComponent } from 'user-management/view/components';

import { useWindowSize } from 'app-wrapper/hooks';
import { TypeSwitch } from 'app-wrapper/view/components';

import {
  AACEABlockButtonCancel,
  AACEABlockButtonSave,
  AACEABlockContent,
  AACEABlockFooter,
  AACEABlockWrap,
} from './AACContactAddContactPerson.styled';

interface IAACContactAddContactPersonComponentProps {
  contactPersonForm?: CompanyContactDTM
  isDisabledCancelBtn?: boolean
  isDisabledSaveBtn?: boolean
  firstBlockName?: string
  onCancelClick: () => void
  onSaveClick: (addressId: string) => () => void
  onChangeAddressIsPrimary: (isPrimary: boolean) => void
  onChangeContactPerson: (contactPerson: string) => void
  onBlurContactPerson: () => void
  onFocusContactPerson: () => void
  onChangeAdditionalPhone: (additionalPhone: string) => void
  onBlurAdditionalPhone: () => void
  onFocusAdditionalPhone: () => void
  onChangeEmail: (email: string) => void
  onBlurEmail: () => void
  onFocusEmail: () => void
  onChangePhone: (phone: string) => void
  onBlurPhone: () => void
  onFocusPhone: () => void
}

const AACContactAddContactPersonComponent: FC<IAACContactAddContactPersonComponentProps> = (props) => {
  const {
    contactPersonForm,
    isDisabledCancelBtn,
    isDisabledSaveBtn,
    firstBlockName,
    onChangeAddressIsPrimary,
    onCancelClick,
    onSaveClick,
    onChangeContactPerson,
    onBlurContactPerson,
    onFocusContactPerson,
    onChangeAdditionalPhone,
    onBlurAdditionalPhone,
    onFocusAdditionalPhone,
    onChangeEmail,
    onBlurEmail,
    onFocusEmail,
    onChangePhone,
    onBlurPhone,
    onFocusPhone,
  } = props;
  const { t } = useTranslation();
  const largeInput = useWindowSize().isFullMediaWidth;
  const { addressId } = useParams<'addressId'>();

  return (
    <AACEABlockWrap>
      <AACEABlockContent>

        <ContactPersonBlockComponent
          largeInput={largeInput}
          firstBlockName={firstBlockName}
          contactPerson={contactPersonForm}
          errorsName={contactPersonForm?.fullNameErrors}
          errorsEmail={contactPersonForm?.emailErrors}
          errorsPhone={contactPersonForm?.phoneErrors}
          errorsPhone2={contactPersonForm?.phone2Errors}
          onChangeContactPerson={onChangeContactPerson}
          onBlurContactPerson={onBlurContactPerson}
          onFocusContactPerson={onFocusContactPerson}
          onChangeAdditionalPhone={onChangeAdditionalPhone}
          onBlurAdditionalPhone={onBlurAdditionalPhone}
          onFocusAdditionalPhone={onFocusAdditionalPhone}
          onChangeEmail={onChangeEmail}
          onBlurEmail={onBlurEmail}
          onFocusEmail={onFocusEmail}
          onChangePhone={onChangePhone}
          onBlurPhone={onBlurPhone}
          onFocusPhone={onFocusPhone}
          primaryComponent={(
            <TypeSwitch
              onChange={onChangeAddressIsPrimary}
              data-class="AACContactAddContactPersonAddressItem"
              rightText={t('Primary')}
              checkedChildren="On"
              unCheckedChildren="Off"
              value={!!contactPersonForm?.primary}
              disabledbg="true"
            />
          )}
        />

      </AACEABlockContent>

      <AACEABlockFooter>
        <AACEABlockButtonCancel
          onClick={onCancelClick}
          disabled={isDisabledCancelBtn}
        >
          {t('addChargeMenu.cancel')}
        </AACEABlockButtonCancel>
        <AACEABlockButtonSave
          onClick={onSaveClick(addressId || '')}
          disabled={isDisabledSaveBtn}
        >
          {t('addChargeMenu.save')}
        </AACEABlockButtonSave>
      </AACEABlockFooter>
    </AACEABlockWrap>
  );
};

const AACContactAddContactPersonComponentCached = memo(AACContactAddContactPersonComponent);

export { AACContactAddContactPersonComponentCached as AACContactAddContactPersonComponent };
