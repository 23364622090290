import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';
import { UC } from 'shipment-operations/controllers';

import { BillOfLadingTypeSelectComponent } from './BillOfLadingTypeSelect.component';

const BillOfLadingTypeSelectContainer: FC = () => {
  const { type } = useSelector(R.selectors.billOfLading.getAllBillOfLadingInformation);
  const initialType = useSelector(R.selectors.billOfLading.getInitialStateType);
  const permissions = useSelector(R.selectors.shipment.getDocumentsPermissions);
  const isVesselDeparted = useSelector(R.selectors.billOfLading.getIsVesselDeparted);
  const isEnoughTimeBeforeVesselArrival = useSelector(R.selectors.billOfLading.getIsEnoughTimeRemainingBeforeVesselArrival);

  return (
    <BillOfLadingTypeSelectComponent
      billOfLadingType={type}
      initialBLType={initialType}
      setBillOfLadingType={UC.billOfLading.setType}
      touchField={UC.billOfLading.touchField}
      billOfLadingPermission={permissions.billOfLadingDocuments}
      isVesselDeparted={isVesselDeparted}
      isEnoughTimeBeforeVesselArrival={isEnoughTimeBeforeVesselArrival}
    />
  );
};

export {
  BillOfLadingTypeSelectContainer as BillOfLadingTypeSelect,
};
