import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { UC } from 'monetary/controllers';
import { RateDtm } from 'monetary/models/dtm';
import { ERateTypes } from 'monetary/constants';
import { R } from 'monetary/repository';

import { RateItemComponent } from './RateItem.component';

interface IRateItemContainerProps {
  rateItem: RateDtm
  openRatesDownload: (rateType: ERateTypes) => void
}

const RateItemContainer: FC<IRateItemContainerProps> = ({ rateItem, openRatesDownload }) => {
  const isLoading = useSelector(R.selectors.rates.getIsLoading);
  const getFileSend = useSelector(R.selectors.rates.getFileSend);

  return (
    <RateItemComponent
      rateItem={rateItem}
      fileSend={getFileSend}
      openRatesDownload={openRatesDownload}
      onRateUpload={UC.rates.sendFile}
      onCloseMessageWarning={UC.rates.onCloseMessageWarningItem}
      isLoading={isLoading}
    />
  );
};

export { RateItemContainer as RateItem };
