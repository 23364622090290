import React from 'react';
import { useSelector } from 'react-redux';

import { R } from 'user-management/repository';
import { UC } from 'user-management/controllers';

import { NewOrganizationSuccessPageComponent } from './NewOrganizationSuccessPage.component';

const NewOrganizationSuccessPageContainer = () => {
  const isLoading = useSelector(R.selectors.newOrganizationWizardReviewAndConfirm.getLoading);

  return (
    <NewOrganizationSuccessPageComponent
      initData={UC.newOrganizationSuccess.initData}
      handleClose={UC.newOrganizationSuccess.handleClose}
      handleViewMyOrganization={UC.newOrganizationSuccess.handleViewMyOrganization}
      isLoading={isLoading}
    />
  );
};

export { NewOrganizationSuccessPageContainer as NewOrganizationSuccessPage };
