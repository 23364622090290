import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';
import { UC } from 'shipment-operations/controllers';

import { AddOrRemoveCargoComponent } from './AddOrRemoveCargo.component';

interface AddOrRemoveCargoContainerProps {
  cargoId: number;
}

const AddOrRemoveCargoContainer: FC<AddOrRemoveCargoContainerProps> = ({ cargoId }) => {
  const isFirstCargo = useSelector(R.selectors.bookingWizard.getIsFirstCargo(cargoId));

  return (
    <AddOrRemoveCargoComponent
      cargoId={cargoId}
      isFirstCargo={isFirstCargo}
      removeCargo={UC.bookingWizard.removeCargo}
      addCargo={UC.bookingWizard.addNewCargo}
    />
  );
};

export { AddOrRemoveCargoContainer as AddOrRemoveCargo };
