import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Spin from 'antd/es/spin';

import { OrganizationMemberDTM, TeamDTM } from 'user-management/models/dtm';
import { Select, Option, Drawer } from 'app-wrapper/view/components';
import {
  Content,
  FieldLabel,
  FieldWrap,
  SpinnerWrap,
} from 'user-management/view/components/CreateOrEditTeamDrawer/CreateOrEditTeamDrawer.styled';

import { TeamDrawerFooter } from './components';
import { Fields } from './AddOrChangeTeamDrawer.styled';

const DRAWER_WIDTH = 400;

interface IAddOrChangeTeamDrawerComponentProps {
  isVisible: boolean;
  isEditMode: boolean;
  onClose: () => void;
  isLoading: boolean;
  teams: TeamDTM[];
  members: OrganizationMemberDTM[];
  tempTeam?: number;
  setTempTeam: (teamId: number) => void;
  tempAccountManager?: string;
  setTempAccountManager: (email: string) => void;
}

export const AddOrChangeTeamDrawerComponent: FC<IAddOrChangeTeamDrawerComponentProps> = ({
  isVisible,
  isEditMode,
  onClose,
  isLoading,
  teams,
  members,
  tempTeam,
  setTempTeam,
  tempAccountManager,
  setTempAccountManager,
}) => {
  const { t } = useTranslation();

  return (
    <Drawer
      visible={isVisible}
      onClose={onClose}
      maskClosable={false}
      width={DRAWER_WIDTH}
      push={false}
      title={isEditMode ? t('Change Team') : t('Add Team')}
    >
      <Content>
        {isLoading ? (
          <SpinnerWrap>
            <Spin size="large" />
          </SpinnerWrap>
        ) : (
          <>
            <Fields>
              <FieldWrap>
                <FieldLabel>
                  {t('Account Manager')}
                </FieldLabel>

                <Select
                  placeholder={t('Choose Account Manager')}
                  allowClear
                  value={tempAccountManager}
                  onChange={setTempAccountManager}
                >
                  {members.map((member) => (
                    <Option key={member.email} value={member.email}>
                      {member.getFullName()}
                    </Option>
                  ))}
                </Select>
              </FieldWrap>

              <FieldWrap>
                <FieldLabel>
                  {t('Team')}
                </FieldLabel>

                <Select
                  placeholder={t('Choose Team')}
                  allowClear
                  value={tempTeam}
                  onChange={setTempTeam}
                >
                  {teams.map((team) => (
                    <Option key={team.id} value={team.id}>
                      {team.name}
                    </Option>
                  ))}
                </Select>
              </FieldWrap>
            </Fields>

            <TeamDrawerFooter />
          </>
        )}
      </Content>
    </Drawer>
  );
};
