import { PayloadAction } from '@reduxjs/toolkit';

import { createSlice } from 'app-wrapper/createSlice';
import { REQUEST_STATUS } from 'app-wrapper/constants';

import {
  IAccountDepartmentDTM,
  IAccountDepartmentsStateDefaultStateDTM,
  IAccountDepartmentsStateDefaultStateDepartmentListStateDTM,
  IAccountDepartmentsStateUpdateStateDTM,
  IAccountDepartmentsStateUpdateStateDepartmentListStateErrorsDTM,
  IAccountDepartmentsStateUpdateStateErrorsDTM,
  TOrganizationMemberDTM,
} from 'user-management/models/dtm';

import { AccountDepartmentsInitialState } from './AccountDepartments.initState';

export const AccountDepartmentsStore = createSlice({
  name: 'AccountDepartmentsStore',
  initialState: AccountDepartmentsInitialState,
  reducers: {

    resetState: (state) => ({
      ...AccountDepartmentsInitialState,
      defaultState: {
        ...AccountDepartmentsInitialState.defaultState,
        isRequest: state.defaultState.isRequest,
      },
    }),

    // Update

    setUpdate: (state, action: PayloadAction<IAccountDepartmentsStateUpdateStateDTM>) => {
      const { payload } = action;

      return {
        ...state,
        updateState: payload,
      };
    },
    setUpdateDepartmentList: (state, action: PayloadAction<IAccountDepartmentDTM[]>) => {
      const { payload } = action;

      return {
        ...state,
        updateState: {
          ...state.updateState,
          departmentList: payload,
        },
      };
    },
    setUpdateDepartmentById: (state, action: PayloadAction<IAccountDepartmentDTM | undefined>) => {
      const { payload } = action;

      return {
        ...state,
        updateState: {
          ...state.updateState,
          departmentById: payload,
        },
      };
    },
    setUpdateMembersList: (state, action: PayloadAction<TOrganizationMemberDTM[]>) => {
      const { payload } = action;

      return {
        ...state,
        updateState: {
          ...state.updateState,
          membersList: payload,
        },
      };
    },
    setUpdateGetRequestStatus: (state, action: PayloadAction<REQUEST_STATUS>) => {
      const { payload } = action;

      return {
        ...state,
        updateState: {
          ...state.updateState,
          getRequestStatus: payload,
        },
      };
    },
    setUpdateDepartmentListPage: (state, action: PayloadAction<string>) => {
      const { payload } = action;

      return {
        ...state,
        updateState: {
          ...state.updateState,
          departmentListPage: payload,
        },
      };
    },
    setUpdateDepartmentListSize: (state, action: PayloadAction<string>) => {
      const { payload } = action;

      return {
        ...state,
        updateState: {
          ...state.updateState,
          departmentListSize: payload,
        },
      };
    },
    setUpdateIsUpdate: (state, action: PayloadAction<boolean>) => {
      const { payload } = action;

      return {
        ...state,
        updateState: {
          ...state.updateState,
          isUpdate: payload,
        },
      };
    },
    setUpdateErrors: (state, action: PayloadAction<IAccountDepartmentsStateUpdateStateErrorsDTM>) => {
      const { payload } = action;

      return {
        ...state,
        updateState: {
          ...state.updateState,
          errors: payload,
        },
      };
    },
    setUpdateErrorsEditDepartmentDetailsDrawer: (state, action: PayloadAction<IAccountDepartmentsStateUpdateStateDepartmentListStateErrorsDTM | undefined>) => {
      const { payload } = action;

      return {
        ...state,
        updateState: {
          ...state.updateState,
          errorsEditDepartmentDetailsDrawer: payload,
        },
      };
    },
    setUpdateActiveListTabIndex: (state, action: PayloadAction<string>) => {
      const { payload } = action;

      return {
        ...state,
        updateState: {
          ...state.updateState,
          activeListTabIndex: payload,
        },
      };
    },
    setUpdateActiveDepartmentEdit: (state, action: PayloadAction<IAccountDepartmentsStateDefaultStateDepartmentListStateDTM>) => {
      const { payload } = action;

      return {
        ...state,
        updateState: {
          ...state.updateState,
          activeDepartmentEdit: payload,
        },
      };
    },

    // Default

    setDefault: (state, action: PayloadAction<IAccountDepartmentsStateDefaultStateDTM>) => {
      const { payload } = action;

      return {
        ...state,
        defaultState: payload,
      };
    },
    setDefaultDepartmentList: (state, action: PayloadAction<IAccountDepartmentDTM[]>) => {
      const { payload } = action;

      return {
        ...state,
        defaultState: {
          ...state.defaultState,
          departmentList: payload,
        },
      };
    },
    setDefaultDepartmentById: (state, action: PayloadAction<IAccountDepartmentDTM | undefined>) => {
      const { payload } = action;

      return {
        ...state,
        defaultState: {
          ...state.defaultState,
          departmentById: payload,
        },
      };
    },
    setDefaultDMembersList: (state, action: PayloadAction<TOrganizationMemberDTM[]>) => {
      const { payload } = action;

      return {
        ...state,
        defaultState: {
          ...state.defaultState,
          membersList: payload,
        },
      };
    },
    setDefaultGetRequestStatus: (state, action: PayloadAction<REQUEST_STATUS>) => {
      const { payload } = action;

      return {
        ...state,
        defaultState: {
          ...state.defaultState,
          getRequestStatus: payload,
        },
      };
    },
    setDefaultDepartmentListPage: (state, action: PayloadAction<string>) => {
      const { payload } = action;

      return {
        ...state,
        defaultState: {
          ...state.defaultState,
          departmentListPage: payload,
        },
      };
    },
    setDefaultDepartmentListSize: (state, action: PayloadAction<string>) => {
      const { payload } = action;

      return {
        ...state,
        defaultState: {
          ...state.defaultState,
          departmentListSize: payload,
        },
      };
    },
    setDefaultIsRequest: (state, action: PayloadAction<boolean>) => {
      const { payload } = action;

      return {
        ...state,
        defaultState: {
          ...state.defaultState,
          isRequest: payload,
        },
      };
    },
    setDefaultIsEmptyRequest: (state, action: PayloadAction<boolean>) => {
      const { payload } = action;

      return {
        ...state,
        defaultState: {
          ...state.defaultState,
          isEmptyRequest: payload,
        },
      };
    },
    setDefaultIsEndPage: (state, action: PayloadAction<boolean>) => {
      const { payload } = action;

      return {
        ...state,
        defaultState: {
          ...state.defaultState,
          isEndPage: payload,
        },
      };
    },
    setDefaultActiveListTabIndex: (state, action: PayloadAction<string>) => {
      const { payload } = action;

      return {
        ...state,
        defaultState: {
          ...state.defaultState,
          activeListTabIndex: payload,
        },
      };
    },
    setDefaultActiveDepartmentEdit: (state, action: PayloadAction<IAccountDepartmentsStateDefaultStateDepartmentListStateDTM>) => {
      const { payload } = action;

      return {
        ...state,
        defaultState: {
          ...state.defaultState,
          activeDepartmentEdit: payload,
        },
      };
    },
  },
});

export const accountDepartmentsReducer = AccountDepartmentsStore.reducer;
export const accountDepartmentsActions = AccountDepartmentsStore.actions;
