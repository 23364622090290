import { Type } from 'class-transformer';
import {
  IsDefined,
  IsEnum,
  IsNumber,
  IsOptional,
  IsString,
  ValidateNested,
} from 'class-validator';

import { EContractCategory } from 'shipment-operations/constants';
import { EMarkupPolicyStatus } from 'user-management/constants';
import { DateDtm } from 'app-wrapper/models/dtm';

import { BaseDTM } from 'proto/BaseDTM';

export interface IMarkupRuleContractCategoryDTM {
  category: `${EContractCategory}`;
  percent?: number;
  minAmount?: number;
}

export class MarkupRuleContractCategoryDTM extends BaseDTM<IMarkupRuleContractCategoryDTM> {
  @IsDefined()
  @IsEnum(EContractCategory)
  category: EContractCategory;

  @IsOptional()
  @IsNumber()
  percent?: number;

  @IsOptional()
  @IsNumber()
  minAmount?: number;
}

export interface IMarkupRuleApplicationRangeDTM {
  minValue?: number;
  maxValue?: number;
}

export class MarkupRuleApplicationRangeDTM extends BaseDTM<IMarkupRuleApplicationRangeDTM> {
  @IsOptional()
  @IsNumber()
  minValue?: number;

  @IsOptional()
  @IsNumber()
  maxValue?: number;
}

export interface IMarkupRuleDTM {
  id?: number;
  applianceRange?: IMarkupRuleApplicationRangeDTM;
  contractCategories: IMarkupRuleContractCategoryDTM[];
}

export class MarkupRuleDTM extends BaseDTM<IMarkupRuleDTM> {
  @IsOptional()
  @IsNumber()
  id?: number;

  @IsOptional()
  @ValidateNested()
  @Type(() => MarkupRuleApplicationRangeDTM)
  applianceRange?: MarkupRuleApplicationRangeDTM;

  @IsDefined()
  @ValidateNested({ each: true })
  @Type(() => MarkupRuleContractCategoryDTM)
  contractCategories: MarkupRuleContractCategoryDTM[];
}

export interface IMarkupPolicyValidPeriodDTM {
  from?: DateDtm;
  to?: DateDtm;
}

export class MarkupPolicyValidPeriodDTM extends BaseDTM<IMarkupPolicyValidPeriodDTM> {
  @IsOptional()
  @ValidateNested()
  @Type(() => DateDtm)
  from?: DateDtm;

  @IsOptional()
  @ValidateNested()
  @Type(() => DateDtm)
  to?: DateDtm;
}

export interface IMarkupPolicyDTM {
  id: number;
  status: `${EMarkupPolicyStatus}`;
  createdBy: string;
  createdAt?: DateDtm;
  organizationId: number;
  validPeriod: IMarkupPolicyValidPeriodDTM;
  rules: IMarkupRuleDTM[];
}

export class MarkupPolicyDTM extends BaseDTM<IMarkupPolicyDTM> {
  @IsDefined()
  @IsNumber()
  id: number;

  @IsDefined()
  @IsEnum(EMarkupPolicyStatus)
  status: EMarkupPolicyStatus;

  @IsDefined()
  @IsString()
  createdBy: string;

  @IsOptional()
  @ValidateNested()
  @Type(() => DateDtm)
  createdAt?: DateDtm;

  @IsDefined()
  @IsNumber()
  organizationId: number;

  @IsDefined()
  @ValidateNested()
  @Type(() => MarkupPolicyValidPeriodDTM)
  validPeriod: MarkupPolicyValidPeriodDTM;

  @IsDefined()
  @ValidateNested({ each: true })
  @Type(() => MarkupRuleDTM)
  rules: MarkupRuleDTM[];
}
