import { v4 as uuidv4 } from 'uuid';
import moment from 'moment/moment';

import {
  DocumentDTM,
  ShipmentChangesDtm,
  ShipmentPreviewDTM,
  ShipmentPreviewTransactionStatusDTM,
} from 'shipment-operations/models/dtm';
import { apiWorker } from 'app-wrapper/repository/utilsServices';
import {
  GetShipmentChangesContract,
  GetShipmentMismatchesContract,
  IGetShortShipmentResponse,
} from 'shipment-operations/models/contracts';
import { IErrorExceptionDTM } from 'app-wrapper/models/contracts';
import { ShipmentTransactionStatusNames } from 'shipment-operations/constants';
import { DateDtm } from 'app-wrapper/models/dtm';
import { ELocationType } from 'app-wrapper/types/LocationType';

export class ShipmentChangesService {
  getShipmentChanges = async (shipmentId: string) => {
    let result: ShipmentChangesDtm[] | null = [];

    try {
      const rawResponse = await apiWorker.requestGet<GetShipmentChangesContract[]>(`shipment-service/api/v1/shipments/${shipmentId}/changes`);
      const response = rawResponse.data;
      const parsedResponse = response.map((item) => {
        const parsedItem = ShipmentChangesDtm.fromPlain({
          id: item.id,
          previous: item.previous,
          current: item.current,
          valueType: item.valueType,
          field: item.field,
          relatesToType: item.relatesToType,
          mismatch: false,
          key: uuidv4(),
        });
        if (!parsedItem.isValid()) {
          console.error('Data from API does not match with contract Shipment Changes');
        }
        return parsedItem;
      });
      result = parsedResponse.filter((el) => el !== null) as ShipmentChangesDtm[];
    } catch (e) {
      console.error('GET SHIPMENT CHANGES: SERVICE ERROR');
    }
    return result;
  }

  getShipmentChangesBL = async (shipmentId: string) => {
    const rawResponse = await apiWorker.requestGet<GetShipmentChangesContract[]>(`shipment-service/api/v1/shipments/${shipmentId}/si-changes`);
    const response = rawResponse.data;
    const parsedResponse = response.map((item) => {
      const parsedItem = ShipmentChangesDtm.fromPlain({
        id: item.id,
        previous: item.previous,
        current: item.current,
        valueType: item.valueType,
        field: item.field,
        relatesToType: item.relatesToType,
        mismatch: false,
        key: uuidv4(),
      });
      if (!parsedItem.isValid()) {
        console.error('Data from API does not match with contract Shipment Changes');
      }
      return parsedItem;
    });
    return parsedResponse.filter((el) => el !== null) as ShipmentChangesDtm[];
  }

  getCargoContainersMismatches = async (shipmentId: string) => {
    let result: {
      id?: number;
      mismatches: ShipmentChangesDtm[] | null;
    } = { id: undefined, mismatches: [] };

    try {
      const rawResponse = await apiWorker.requestGet<GetShipmentMismatchesContract>(`shipment-service/api/v1/shipments/${shipmentId}/mismatches`);
      const response = rawResponse.data;
      const parsedResponse = response.mismatches.map((item) => {
        const parsedItem = ShipmentChangesDtm.fromPlain({
          id: item.id,
          previous: item.previous,
          mismatchesCurrent: item.current,
          mismatchesPrevious: item.previous,
          valueType: item.valueType,
          field: item.field,
          relatesToType: item.relatesToType,
          mismatch: true,
          current: null,
          key: uuidv4(),
        });
        if (!parsedItem.isValid()) {
          console.error('Data from API does not match with contract Shipment Changes, getCargoContainersMismatches');
        }
        return parsedItem;
      });
      result = { mismatches: parsedResponse.filter((el) => el !== null) as ShipmentChangesDtm[], id: response.id };
    } catch (e) {
      console.error('GET CARGO CONTAINERS MISMATCHES: SERVICE ERROR');
    }
    return result;
  }

  getLocations = async (shipmentId: string) => {
    let result: {
      id?: number;
      mismatches: ShipmentChangesDtm[] | null;
    } = { id: undefined, mismatches: [] };

    try {
      const rawResponse = await apiWorker.requestGet<GetShipmentMismatchesContract>(`shipment-service/api/v1/shipments/${shipmentId}/location-mismatches`);
      const response = rawResponse.data;
      const parsedResponse = response.mismatches.map((item) => {
        const parsedItem = ShipmentChangesDtm.fromPlain({
          id: item.id,
          previous: item.previous,
          mismatchesCurrent: item.current,
          mismatchesPrevious: item.previous,
          valueType: item.valueType,
          field: item.field,
          relatesToType: item.relatesToType,
          mismatch: true,
          key: uuidv4(),
          location: item.location,
        });
        if (!parsedItem.isValid()) {
          console.error('Data from API does not match with contract Shipment Changes, getLocations');
        }
        return parsedItem;
      });
      result = { id: response.id, mismatches: parsedResponse.filter((el) => el !== null) as ShipmentChangesDtm[] };
    } catch (e) {
      console.error('GET LOCATIONS MISMATCHES: SERVICE ERROR');
    }
    return result;
  }

  confirmData = async (shipmentId: string) => {
    try {
      await apiWorker.requestPost(`shipment-service/api/v1/shipments/${shipmentId}/booking`);
    } catch (e) {
      console.error('CONFIRM DATA: SERVICE ERROR');
      throw new Error(`${(e as IErrorExceptionDTM)?.response?.data?.message || 'Something wrong, please try again'}`);
    }
    return 'success';
  }

  sendManualConfirmation = async (shipmentId: string, body: any) => {
    try {
      await apiWorker.requestPost(`shipment-service/api/v1/shipments/${shipmentId}/booking/confirm`, body);
    } catch (e) {
      console.error('SEND MANUAL CONFIRMATION: SERVICE ERROR');
      throw new Error('Something wrong, please try again');
    }
    return 'success';
  }

  rejectData = async (shipmentId: string) => {
    try {
      await apiWorker.requestPost(`shipment-service/api/v1/shipments/${shipmentId}/booking/cancel`, {
        type: 'OTHER',
        message: 'Amendments made by carrier has been rejected.',
      });
    } catch (e) {
      console.error('REJECT DATA: SERVICE ERROR');
      throw new Error('Something wrong, please try again');
    }
    return 'success';
  }

  acceptLocationMismatches = async (shipmentId: string, mismatchId: number) => {
    try {
      await apiWorker.requestPost(`shipment-service/api/v1/shipments/${shipmentId}/location-mismatches/${mismatchId}/accept`);
    } catch (e) {
      console.error('ACCEPT LOCATION MISMATCHES: SERVICE ERROR');
      throw new Error('Something wrong, please try again');
    }
    return 'success';
  }

  acceptMismatches = async (shipmentId: string, mismatchId: number) => {
    try {
      await apiWorker.requestPost(`shipment-service/api/v1/shipments/${shipmentId}/mismatches/${mismatchId}/accept`);
    } catch (e) {
      console.error('ACCEPT MISMATCHES: SERVICE ERROR');
      throw new Error('Something wrong, please try again');
    }
    return 'success';
  }

  searchReference = async (shipmentId: string, refNumber: string) => {
    const res = await apiWorker.requestPost<IGetShortShipmentResponse>(`shipment-service/api/v1/shipments/${shipmentId}/booking/${refNumber}/process`);
    const body = res.data;
    const shipmentData = ShipmentPreviewDTM.fromPlain({
      id: body.id,
      status: body.status,
      transactionStatus: body.transactionStatus ? ShipmentPreviewTransactionStatusDTM.fromPlain({
        displayValue: ShipmentTransactionStatusNames[body.transactionStatus],
        enumValue: body.transactionStatus,
      }) : undefined,
      bookingStatus: body.bookingDetails.status,
      shipmentName: `${body.shipmentName}`,
      mainVesselName: body.mainVesselName || undefined,
      reference: body.shipmentReference,
      carrier: body.carrier.name,
      scac: body.carrier.scac,
      oceanBookingId: body.bookingDetails.oceanBookingId,
      isRequestTheSameWithShipment: body.bookingDetails.requestTheSameWithShipment,
      siStatus: body.siDetails.status,
      mblDraftId: body.siDetails.mblDraftId,
      mblFinalId: body.siDetails.mblFinalId,
      siRequestTheSameWithShipment: body.siDetails.requestTheSameWithShipment,
      rollPlanId: body.rollPlanId,
      manualConfirmationDocument: body.bookingDetails.manualConfirmationDocument ? DocumentDTM.fromPlain({
        ...body.bookingDetails.manualConfirmationDocument,
        createdAt: DateDtm.fromPlain({
          date: body.bookingDetails.manualConfirmationDocument.createdAt,
          offset: moment.parseZone(body.bookingDetails.manualConfirmationDocument.createdAt).utcOffset(),
        }),
      }) : null,
      siSentAt: body.siDetails.sentAt ? DateDtm.fromPlain({
        date: body.siDetails.sentAt,
        offset: moment.parseZone(body.siDetails.sentAt).utcOffset(),
      }) : null,
      createdAt: body.createdAt ? DateDtm.fromPlain({
        date: body.createdAt,
        offset: moment.parseZone(body.createdAt).utcOffset(),
      }) : null,
      confirmedAt: body.confirmedAt ? DateDtm.fromPlain({
        date: body.confirmedAt,
        offset: moment.parseZone(body.confirmedAt).utcOffset(),
      }) : null,
      containerCount: body.containerCount,
      isSelfService: body.isSelfService,
      origin: {
        name: body.from.companyName,
        address: '',
        countryCode: body.from.country?.code,
        estimatedDate: undefined,
        realDate: undefined,
        type: body.from.type as ELocationType,
      },
      destination: {
        name: body.to.companyName,
        address: '',
        countryCode: body.to.country?.code,
        estimatedDate: undefined,
        realDate: undefined,
        type: body.to.type as ELocationType,
      },
      customer: body.customer ? {
        companyName: body.customer.company.name,
        name: body.customer.contact.name,
        email: body.customer.contact.email,
        phone: body.customer.contact.phone,
        id: body.customer.company.id,
      } : null,
      carrierReferenceNumber: body.bookingDetails.carrierReferenceNumber,
      contractOwnerOrgId: body.contractOwnerOrgId || 0,
      destinationPartnerOrgId: body.destinationPartnerOrgId || 0,
      originPartnerOrgId: body.originPartnerOrgId || 0,
      customerOrgId: body.customerOrgId || 0,
      organizationId: body.organizationId || 0,
      earliestEmptyReleaseDate: body.earliestEmptyReleaseDate,
      hasReeferContainer: body.hasReeferContainer,
      hasSelfOwnedContainer: body.hasSelfOwnedContainer,
      cancelReasonDto: body.cancelReasonDto && {
        type: body.cancelReasonDto.type,
        message: body.cancelReasonDto.message,
      },
    });
    return shipmentData;
  }
}
