import React, { FC } from 'react';
import { WareHouseSvg, WaypointTypePortIcon } from 'app-wrapper/view/icons';
import themesColors from 'app-wrapper/view/themes/themesColors';
import { ELocationType } from 'app-wrapper/types/LocationType';

interface FreightTypeLocationIconComponentProps {
  type?: ELocationType | string; // old DTM's have string type
}

export const FreightTypeLocationIconComponent: FC<FreightTypeLocationIconComponentProps> = ({ type }) => (
  type === ELocationType.DOOR
    ? (<WareHouseSvg fill={themesColors.neutralBranding6} />)
    : (<WaypointTypePortIcon color={themesColors.neutralBranding6} />)
);
