import { createSlice } from 'app-wrapper/createSlice';

import { ICountryListDTM } from 'app-wrapper/models/dtm/CountryList.dtm';

import { CountriesJson } from './countries';

const countries: ICountryListDTM[] = Object.entries(CountriesJson).map(([code, name]) => ({
  code,
  name,
}));

const initialState = {
  countriesList: countries,
};

export const countriesListStore = createSlice({
  name: 'countriesListStore',
  initialState,
  reducers: {},
});

export const countriesListActions = countriesListStore.actions;
export const countriesListReducer = countriesListStore.reducer;
