import styled, { css } from 'styled-components';
import Radio from 'antd/es/radio';

import { divFlex } from 'app-wrapper/view/themes/mixin';
import { Button } from 'app-wrapper/view/components';

const { Group } = Radio;

const cssButton = css`
  font-size: 14px;
  font-weight: 400;
  padding: 1px 8px 1px 8px;
  border-radius: 3px;
  color: ${({ theme }) => theme?.themesColors?.neutralBranding1};
  height: 24px;
`;

export const FREWrapper = styled.div.attrs({
  'data-class': 'FREWrapper',
})`
  ${divFlex}
`;

export const FREHeader = styled.div.attrs({
  'data-class': 'FREHeader',
})`
  ${divFlex}
`;

export const FREHeaderNameWrapper = styled.div.attrs({
  'data-class': 'FREHeaderNameWrapper',
})`
  ${divFlex}
  cursor: pointer;
`;

export const FREHeaderUpdateDot = styled.div.attrs({
  'data-class': 'FREHeaderUpdateDot',
})`
  ${divFlex}
  width: 6px;
  height: 6px;
  border-radius: 50%;
  margin-right: 2px;
  background-color: ${({ theme }) => theme?.themesColors?.primaryBranding6};
`;

export const FREHeaderName = styled.div.attrs((props: {
  isActive: boolean,
}) => ({
  isActive: props.isActive,
  'data-class': 'FREHeaderName',
}))`
  ${divFlex}
  font-size: 12px;
  font-weight: 400;
  align-items: center;
  color: ${({ theme, isActive }) => (isActive ? theme?.themesColors?.darkGrayMiddle : theme?.themesColors?.neutralBranding10)};
`;

export const FREHeaderArrow = styled.div.attrs((props: {
  isActive: boolean,
}) => ({
  isActive: props.isActive,
  'data-class': 'FREHeaderArrow',
}))`
  ${divFlex}
  margin-left: 6px;

  justify-content: center;
  align-items: center;

  svg {
    color: ${({ theme, isActive }) => (isActive ? theme?.themesColors?.primaryBranding6 : theme?.themesColors?.neutralBranding7)};
  }
`;

export const FREModal = styled.div.attrs({
  'data-class': 'FREModal',
})`
  ${divFlex}
  position: absolute;
  z-index: 7;
  right: -22px;
  top: 24px;
`;

export const FREModalWrapper = styled.div.attrs({
  'data-class': 'FREModalWrapper',
})`
  ${divFlex}
  flex-direction: column;
  padding: 16px;

  background-color: ${({ theme }) => theme?.themesColors?.neutralBranding1};
  border-radius: 2px;
  width: 312px;
  max-height: 236px;
  filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.15));

  ::after {
    content: '';
    position: absolute;
    top: -10px;
    right: 16px;

    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 10px 10px 10px;
    border-color: transparent transparent ${({ theme }) => theme?.themesColors?.neutralBranding1} transparent;
  }
`;

export const FREModalRadio = styled.div.attrs({
  'data-class': 'FREModalRadio',
})`
  ${divFlex}
  height: 100%;
  max-height: 172px;
  overflow-y: auto;
`;

export const FREModalRadioGroup = styled(Group).attrs({
  'data-class': 'FREModalRadioGroup',
})`
  ${divFlex}
  flex-direction: column;
  height: 100%;

  .ant-checkbox-wrapper + .ant-checkbox-wrapper {
    margin-left: 0;
    margin-top: 8px;
  }
`;

export const FREModalRadioGroupItem = styled(Radio).attrs({
  'data-class': 'FREModalRadioGroupItem',
})`
  ${divFlex}
  color: ${({ theme }) => theme?.themesColors?.middleGrayDisable};

  span {
    font-size: 14px;
    font-weight: 400;
    color: ${({ theme }) => theme?.themesColors?.characterTitleDot85};
  }
  span.ant-checkbox-inner {
    border-color: ${({ theme }) => theme?.themesColors?.middleGrayDisable};
  }
  span.ant-checkbox-checked {
    span.ant-checkbox-inner {
      background-color: ${({ theme }) => theme?.themesColors?.secondaryDot45};
      border-color: ${({ theme }) => theme?.themesColors?.secondaryDot45};
    }
  }
  .ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: ${({ theme }) => theme?.themesColors?.secondaryDot45};
  }

  .ant-checkbox-checked::after {
    border: none;
  }
`;

export const FREModalButtons = styled.div.attrs({
  'data-class': 'FREModalButtons',
})`
  ${divFlex}
  padding-top: 8px;
  justify-content: end;
`;

export const FREModalButtonReset = styled(Button).attrs({
  'data-class': 'FREModalButtonReset',
})`
  ${divFlex}
  ${cssButton}
  margin-right: 8px;
  border: none;
  color: ${({ theme }) => theme?.themesColors?.darkGrayMiddle};
  background: none;
  box-shadow: none;

  :hover, :focus, :active, :not([disabled]):active {
    color: ${({ theme }) => theme?.themesColors?.primaryBranding6};
    background: none;
    box-shadow: none;
    text-shadow: none
  }
  :active, :not([disabled]):active {
    color: ${({ theme }) => theme?.themesColors?.primaryBranding5};
    background: none;
    box-shadow: none;
    text-shadow: none
  }
  :disabled {
    color: ${({ theme }) => theme?.themesColors?.neutralBranding6};
    background: none;
    box-shadow: none;
  }
  ::after {
    border: none;
    content: '';
    box-shadow: none;
    display: none;
    text-shadow: none
  }
`;

export const FREModalButtonApply = styled(Button).attrs({
  'data-class': 'FREModalButtonApply',
})`
  ${divFlex}
  ${cssButton}
`;
