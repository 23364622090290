import React from 'react';

import { UC } from 'shipment-operations/controllers';
import { R } from 'shipment-operations/repository';

import { useAppSelector } from 'app-wrapper/hooks';

import { EditPaymentComponent } from './EditPayment.component';

const EditPaymentContainer = () => {
  const isErrorEdit = useAppSelector(R.selectors.makePayment.getIsErrorEdit);
  const errorType = useAppSelector(R.selectors.makePayment.getErrorType);

  return (
    <EditPaymentComponent
      onClose={UC.makePayment.closeEditMode}
      onCloseError={UC.makePayment.onClearEditError}
      isErrorEdit={isErrorEdit}
      errorType={errorType}
    />
  );
};

export { EditPaymentContainer as EditPayment };
