import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import { RouteNames } from 'app-wrapper/constants';

import {
  Wrap,
  Image,
  Title,
  SubTitle,
  Content,
  ExploreButton,
  CompassIcon,
} from './ExploreCommandCenter.styled';
import lampIconSrc from './assets/lamp-icon.jpg';

export const ExploreCommandCenter = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const goToCommandCenter = useCallback(() => {
    navigate(RouteNames.COMMAND_CENTER_TASKS());
  }, [navigate]);

  return (
    <Wrap>
      <Image src={lampIconSrc} alt="" />

      <Content>
        <Title>
          {t('Your Personal Guide to the Platform and Shipments')}
        </Title>

        <SubTitle>
          {t('In the Action Center, we will update you on pending tasks and notify you about important events.')}
        </SubTitle>
      </Content>

      <ExploreButton
        icon={<CompassIcon />}
        type="default"
        onClick={goToCommandCenter}
      >
        {t('Explore Action Center')}
      </ExploreButton>
    </Wrap>
  );
};
