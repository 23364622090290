import React, { FC } from 'react';

import { InvoicesChargeDTM } from 'shipment-operations/models/dtm';
import { CustomTableComponent } from 'shipment-operations/view/components';

interface ICustomTableProps {
  data: InvoicesChargeDTM[]
  fullTable: boolean
  withTooltip?: boolean
}

const CustomTable: FC<ICustomTableProps> = ({ data, fullTable, withTooltip = false }) => (
  <CustomTableComponent data={data} fullTable={fullTable} withTooltip={withTooltip} />
);

export { CustomTable };
