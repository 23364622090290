import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Content,
  Image,
  Title,
  Text,
} from './AgreeTermsAndCondition.styled';
import illustrationSrc from './assets/terms_and_conditions_Illustration.png';
import { StepManagerButton, StepWrap } from '../../StepsManager.styled';
import { TermsAndConditionsDrawerContainer } from '../TermsAndConditionsDrawer';

interface AgreeTermsAndConditionComponentProps {
  showTermsOfConditionDrawer: () => void
}

export const AgreeTermsAndConditionComponent: FC<AgreeTermsAndConditionComponentProps> = ({
  showTermsOfConditionDrawer,
}) => {
  const { t } = useTranslation();

  const handleShowTermsOfConditionDrawer = useCallback(() => {
    showTermsOfConditionDrawer();
  }, []);

  return (
    <>
      <TermsAndConditionsDrawerContainer />
      <StepWrap>
        <Content>
          <Title>
            {t('Terms & Conditions')}
          </Title>

          <Text>
            {t('You will not be able to perform any other activities until you accept the Terms of Service.')}
          </Text>

          <StepManagerButton onClick={handleShowTermsOfConditionDrawer}>
            {t('Open Terms of Service')}
          </StepManagerButton>
        </Content>

        <Image src={illustrationSrc} />
      </StepWrap>
    </>
  );
};
