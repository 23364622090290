import { resetMobxStore } from 'app-wrapper/mobxStores';
import { BaseController, controller } from 'proto/BaseController';

import { R as UserManagementR } from 'user-management/repository';
import { R as MonetaryR } from 'monetary/repository';
import { OrganizationDTM, OrganizationMemberDTM } from 'user-management/models/dtm';

import { R } from 'authentication/repository';
import { UserAuthDataDTM } from 'authentication/models/dtm';
import { IErrorsFieldState } from 'authentication/models/states';

import { IErrorExceptionDTM } from 'app-wrapper/models/contracts';
import i18n from 'app-wrapper/i18n/i18n';
import { resetStore } from 'app-wrapper/store';
import { RouteNames } from 'app-wrapper/constants';
import message from 'antd/es/message';

@controller
export class AuthController extends BaseController {
  public async signIn() {
    let organization: OrganizationDTM | undefined;
    let organizationMember: OrganizationMemberDTM | undefined;

    const { email, password } = this.store.getState().auth;

    this.dispatch(R.actions.auth.setIsLoading(true));

    let userResponse: UserAuthDataDTM | undefined;

    try {
      userResponse = await R.services.auth.signInAuth({ email, password });
    } catch (e) {
      this.dispatch(R.actions.auth.setResponseError((e as IErrorExceptionDTM).message || 'Unknown error'));
      this.dispatch(R.actions.auth.setIsLoading(false));

      return;
    }

    if (!userResponse) {
      return;
    }

    this.dispatch(R.actions.auth.setUser(userResponse));
    this.dispatch(R.actions.auth.setPassword(''));

    // test implementation
    try {
      organization = await this.repositories.organizationRepository.get();
    } catch (e) {
      console.error(e);

      return;
    }
    if (!organization) {
      return;
    }

    try {
      organizationMember = await UserManagementR.services.organization.getOrganizationMember(organization.id, userResponse.email);
    } catch (e) {
      console.error(e);

      return;
    }
    if (!organizationMember) {
      return;
    }

    const userManagementFunctionalityAvailability = UserManagementR.services.moduleFunctionalityPermissions.getFunctionalityPermissions(organizationMember.role, organization.role, organization.status);
    const monetaryAvailability = MonetaryR.services.moduleFunctionalityPermissions.getFunctionalityPermissions(organization.role);
    if (!userManagementFunctionalityAvailability || !monetaryAvailability) {
      return;
    }

    this.dispatch(MonetaryR.actions.moduleFunctionalityPermissions.setPermissions(monetaryAvailability));
    this.dispatch(UserManagementR.actions.moduleFunctionalityPermissions.setPermissions(userManagementFunctionalityAvailability));

    this.dispatch(R.actions.auth.setIsLoading(false));

    const { lastPath } = this.store.getState().routes;
    this.navigate(lastPath || RouteNames.OVERVIEW());
  }

  public confirmEmail = async (
    username: string,
    confirmationCode: string,
  ): Promise<void> => {
    try {
      await R.services.auth.confirmSignUp(username, confirmationCode);

      message.success(i18n.t('Thank you! Your email has been verified. You can now sign in.'), 10);
    } catch (e) {
      console.error('Email confirmation error');
      message.error(i18n.t('Something isn’t quite right. Please try to sign in. In case of problem, please contact support@skypace.com'), 10);
    }
  }

  public checkAuth = async () => {
    let userResponse: UserAuthDataDTM | undefined;
    let organization: OrganizationDTM | undefined;
    let organizationMember: OrganizationMemberDTM | undefined;

    this.dispatch(R.actions.auth.setIsLoading(true));

    try {
      await R.services.auth.setLoggedIn();

      userResponse = await R.services.auth.onCheckUser();
    } catch (e) {
      this.dispatch(R.actions.auth.logOut());

      // here is all unauthorized screens, if you will add the new one - do it hare too
      if (
        this.location.pathname !== RouteNames['SIGN-UP']()
        && this.location.pathname !== RouteNames.SIGN_UP_EMAIL_CONFIRMATION()
        && this.location.pathname !== RouteNames.SIGN_UP_SUCCESS()
        && this.location.pathname !== RouteNames.FORGOT_PASSWORD_EMAIL_SEND()
        && this.location.pathname !== RouteNames.FORGOT_PASSWORD_NEW_PASSWORD()
        && this.location.pathname !== RouteNames.FORGOT_PASSWORD()
      ) {
        this.navigate(RouteNames.SIGN_IN());
      }

      return;
    }

    if (!userResponse) {
      return;
    }

    this.dispatch(R.actions.auth.setUser(userResponse));

    try {
      organization = await UserManagementR.services.organization.getCurrentOrganization();
    } catch (e) {
      console.error(e);

      return;
    }

    if (!organization) {
      return;
    }

    this.dispatch(UserManagementR.actions.userOrganizationData.setUserOrganization(organization));

    try {
      organizationMember = await UserManagementR.services.organization.getOrganizationMember(organization.id, userResponse.email);
    } catch (e) {
      console.error(e);

      return;
    }
    if (!organizationMember) {
      return;
    }

    const userManagementFunctionalityAvailability = UserManagementR.services.moduleFunctionalityPermissions.getFunctionalityPermissions(organizationMember.role, organization.role, organization.status);
    const monetaryAvailability = MonetaryR.services.moduleFunctionalityPermissions.getFunctionalityPermissions(organization.role);
    if (!userManagementFunctionalityAvailability || !monetaryAvailability) {
      return;
    }

    this.dispatch(MonetaryR.actions.moduleFunctionalityPermissions.setPermissions(monetaryAvailability));
    this.dispatch(UserManagementR.actions.moduleFunctionalityPermissions.setPermissions(userManagementFunctionalityAvailability));

    this.dispatch(R.actions.auth.setIsLoading(false));
  }

  public logOut = async () => {
    try {
      await R.services.auth.outAuth();

      this.dispatch(UserManagementR.actions.userOrganizationData.clear());
      this.dispatch(R.actions.auth.logOut());
      this.dispatch(MonetaryR.actions.moduleFunctionalityPermissions.clear());
      this.dispatch(UserManagementR.actions.moduleFunctionalityPermissions.clear());
      resetStore();
      resetMobxStore(this.mobxStore);

      this.navigate(RouteNames.SIGN_IN());
    } catch (e: unknown) {
      console.error('log: signOut', e);
    }
  };

  public setEmail = (email: string) => {
    const { errors } = this.store.getState().auth;
    const emailError = errors.email;

    const value = email.replace(/\s+/g, '') || '';
    const error = this.requiredValidation(value, emailError);

    this.dispatch(R.actions.auth.setEmailError({
      message: error,
    }));
    this.dispatch(R.actions.auth.setEmail(value));
  }

  public setEmailBlur = () => {
    this.dispatch(R.actions.auth.setEmailError({
      isBlur: true,
      isFocus: false,
    }));
    this.validateEmail();
  }

  public setEmailFocus = () => {
    this.dispatch(R.actions.auth.setEmailError({
      isBlur: false,
      isFocus: true,
    }));
    this.validateEmail();
  }

  public setPassword = (password: string) => {
    const { errors } = this.store.getState().auth;
    const passwordError = errors.password;

    const value = password || '';
    const error = this.requiredValidation(value, passwordError);

    this.dispatch(R.actions.auth.setPasswordError({
      message: error,
    }));
    this.dispatch(R.actions.auth.setPassword(value));
  }

  public setPasswordBlur = () => {
    this.dispatch(R.actions.auth.setPasswordError({
      isBlur: true,
      isFocus: false,
    }));
    this.validatePassword();
  }

  public setPasswordFocus = () => {
    this.dispatch(R.actions.auth.setPasswordError({
      isBlur: false,
      isFocus: true,
    }));
  }

  private validatePassword = () => {
    const { errors, password } = this.store.getState().auth;
    const passwordError = errors.password;

    const value = password || '';
    const error = this.requiredValidation(value, passwordError);

    this.dispatch(R.actions.auth.setPasswordError({
      message: error,
    }));
  }

  private validateEmail = () => {
    const { errors, email } = this.store.getState().auth;
    const emailError = errors.email;

    const value = email || '';
    const error = this.requiredValidation(value, emailError);

    this.dispatch(R.actions.auth.setEmailError({
      message: error,
    }));
    this.dispatch(R.actions.auth.setEmail(value));
  }

  private requiredValidation = (value: string, fieldError: IErrorsFieldState) => {
    let error: string | null = null;

    if (fieldError.isBlur) {
      if (!value) {
        error = i18n.t('Validations.mixed.required');
      }
    }
    return error;
  };
}
