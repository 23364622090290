import { Input } from 'app-wrapper/view/components/Input';
import styled, { css } from 'styled-components';
import { divFlex, responsive } from 'app-wrapper/view/themes/mixin';

const inputCss = css`
  font-size: 12px;
  font-weight: 400;
  color: ${({ theme }) => theme?.themesColors?.neutralBranding10};
  min-height: 32px;
`;

const inputTopMarginCss = css`
  margin-top: 4px;
`;

const inputLargeCss = css`
  height: 40px;
`;

export const InputWithError = styled(Input).attrs((props: {
  hasError: boolean,
  largeInput: boolean,
  'data-class'?: string,
}) => ({
  hasError: props.hasError,
  largeInput: props.largeInput,
  'data-class': props['data-class'] || 'InputWithError',
}))`
  ${divFlex}
  ${inputCss}
  ${inputTopMarginCss}
  ${({ largeInput }) => largeInput && inputLargeCss};
  width: 100%;

  ${responsive.forFullMedia`
    min-height: ${({ theme }: { theme: { inputConst: { hdHeight: string } } }) => theme?.inputConst?.hdHeight};
  `}
  ${({ hasError, theme }) => hasError && `border-color: ${theme.themesColors.characterBrandingDanger} !important; border-style: solid;`};
`;
