import React, { FC, memo } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { UC } from 'app-wrapper/controllers';
import { R } from 'app-wrapper/repository';
import {
  CommandCenterNotificationShipmentComponent,
} from 'app-wrapper/view/pages/CommandCenterPage/CommandCenter/CommandCenterNotificationShipment.component';

const CommandCenterNotificationContainer: FC = () => {
  const { shipmentId } = useParams<'shipmentId'>();

  const getCommandCenterCurrentAllNotifications = useSelector(R.selectors.commandCenter.getCommandCenterCurrentAllNotifications);

  const getCommandCenterNotificationCurrentStatus = useSelector(R.selectors.commandCenter.getCommandCenterNotificationCurrentStatus);
  const getCommandCenterNotificationCurrentStatusAdd = useSelector(R.selectors.commandCenter.getCommandCenterNotificationCurrentStatusAdd);
  const getCommandCenterNotificationCurrent = useSelector(R.selectors.commandCenter.getCommandCenterNotificationCurrent);

  const getCommandCenterNotificationCurrentIsClearAllFilters = useSelector(R.selectors.commandCenter.getCommandCenterNotificationCurrentIsClearAllFilters);

  return (
    <CommandCenterNotificationShipmentComponent
      shipmentId={shipmentId}
      onResetTaskPage={UC.CommandCenter.onResetTaskPage}
      onStartNotifications={UC.CommandCenter.onStartNotificationsPage(shipmentId)}
      nextPageNotifications={UC.CommandCenter.nextPageNotifications(shipmentId)}
      onHoverNotificationItem={UC.CommandCenter.onHoverNotificationItem}
      onUnviewedNotificationItem={UC.CommandCenter.onUnviewedNotificationItem}
      onAllNotificationMakeViews={UC.CommandCenter.onAllNotificationMakeViews(shipmentId)}
      onChangeFilterShowOnlyUnread={UC.CommandCenter.onChangeFilterShowOnlyUnread(shipmentId)}
      onApplyPriority={UC.CommandCenter.onApplyPriority(shipmentId)}
      onResetPriority={UC.CommandCenter.onResetPriority(shipmentId)}
      onChangeGroupPriority={UC.CommandCenter.onChangeGroupPriority()}
      onApplyReceivedDate={UC.CommandCenter.onApplyFilterReceivedDates(shipmentId)}
      onResetReceivedDate={UC.CommandCenter.onResetFilterReceivedDates(shipmentId)}
      dateOnReceivedDateChange={UC.CommandCenter.onChangeFilterReceivedDates()}
      onApplyModule={UC.CommandCenter.onApplyModule(shipmentId)}
      onResetModule={UC.CommandCenter.onResetModule(shipmentId)}
      onChangeGroupModule={UC.CommandCenter.onChangeGroupModule()}
      onClearAllFilters={UC.CommandCenter.onClearAllFilters(shipmentId)}
      filters={getCommandCenterNotificationCurrent?.filters}
      isVisibleClearAll={getCommandCenterNotificationCurrentIsClearAllFilters}
      isLoadNotification={getCommandCenterNotificationCurrentStatus}
      isLoadNotificationAdd={getCommandCenterNotificationCurrentStatusAdd}
      allNotifications={getCommandCenterCurrentAllNotifications}
      hideHeader
      isShipmentHeader
      infoCountUnread={getCommandCenterNotificationCurrent?.countUnreadNotifications}
      infoCountImportant={getCommandCenterNotificationCurrent?.countImportantNotifications}
      infoCountRegular={getCommandCenterNotificationCurrent?.countRegularNotifications}
    />
  );
};

const CommandCenterNotificationContainerCached = memo(CommandCenterNotificationContainer);

export { CommandCenterNotificationContainerCached as ShipmentNotificationPage };
