import styled, { css } from 'styled-components';
import Button from 'antd/es/button';

import { divFlex, responsive } from 'app-wrapper/view/themes/mixin';
import { emptyScrollbar } from 'app-wrapper/view/themes/components';
import { Input } from 'app-wrapper/view/components';

const inputCss = css`
  font-size: 12px;
  font-weight: 400;
  color: ${({ theme }) => theme?.themesColors?.neutralBranding10};
  min-height: 32px;
`;

const inputTopMarginCss = css`
  margin-top: 4px;
`;

const inputLargeCss = css`
  height: 40px;
`;

export const EditProfileOrganizationWrap = styled.div.attrs({
  'data-class': 'EditProfileOrganizationWrap',
})`
  ${divFlex}
  ${emptyScrollbar}
  flex-direction: column;
  align-items: center;

  padding: 16px 24px 0 24px;
  height: 100%;
  width: 100%;
  background-color: ${({ theme }) => theme.themesColors.neutralBranding3};
`;

export const EditProfileOrganizationContent = styled.div.attrs({
  'data-class': 'EditProfileOrganizationContent',
})`
  ${divFlex}
  ${emptyScrollbar}
  flex-direction: column;
  overflow: auto;

  font-weight: 400;
  font-size: 12px;
  color: ${({ theme }) => theme.themesColors.neutralBranding10};

  padding: 16px;
  height: 100%;
  width: 100%;
  border: 1px solid ${({ theme }) => theme.themesColors.neutralBranding4};
  background-color: ${({ theme }) => theme.themesColors.neutralBranding1};
`;

export const EditProfileOrganizationOrganization = styled.div.attrs({
  'data-class': 'EditProfileOrganizationOrganization',
})`
  ${divFlex}
  flex-direction: column;
  justify-content: center;
`;

export const EditProfileOrganizationTitle = styled.div.attrs({
  'data-class': 'EditProfileOrganizationTitle',
})`
  ${divFlex}
  justify-content: start;

  font-weight: 400;
  font-size: 12px;
  color: ${({ theme }) => theme.themesColors.neutralBranding10};
`;

export const EditProfileOrganizationInput = styled(Input).attrs((props: {
  hasError: boolean,
  largeInput: boolean,
}) => ({
  hasError: props.hasError,
  largeInput: props.largeInput,
  'data-class': 'EditProfileOrganizationInput',
}))`
  ${divFlex}
  ${inputCss}
  ${inputTopMarginCss}
  ${({ largeInput }) => largeInput && inputLargeCss};
  width: 100%;

  ${responsive.forFullMedia`
    min-height: ${({ theme }: { theme: { inputConst: { hdHeight: string } } }) => theme?.inputConst?.hdHeight};
  `}
  ${({ hasError, theme }) => hasError && `border-color: ${theme.themesColors.characterBrandingDanger} !important; border-style: solid;`};
`;

export const EditProfileOrganizationDivider = styled.div.attrs({
  'data-class': 'EditProfileOrganizationDivider',
})`
  ${divFlex}
  width: 100%;

  margin-top: 16px;
  margin-bottom: 16px;
  border: 1px dashed ${({ theme }) => theme.themesColors.neutralBranding4};
`;

export const EditProfileOrganizationFooter = styled.div.attrs({
  'data-class': 'EditProfileOrganizationFooter',
})`
  ${divFlex}
  justify-content: end;
  z-index: 4;
  width: calc(100% + 48px);
  height: 64px;
  bottom: 0px;
  align-items: center;
  padding: 14px 24px;

  background-color: ${({ theme }) => theme.themesColors.neutralBranding1};
  box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px rgba(0, 0, 0, 0.08), 0px 9px 28px 8px rgba(0, 0, 0, 0.05);
`;

export const EditProfileOrganizationButtonCancel = styled(Button).attrs({
  'data-class': 'EditProfileOrganizationButtonCancel',
})`
  ${divFlex}

  font-size: 16px;
  font-weight: 400;
  color: ${({ theme }) => theme?.themesColors?.neutralBranding10};
  background: ${({ theme }) => theme?.themesColors?.neutralBranding1};
  border: 1px solid ${({ theme }) => theme?.themesColors?.neutralBranding5};
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
  border-radius: 3px;

  width: 67px;
  height: 40px;
  align-items: center;
  justify-content: center;
  right: 0;
`;

export const EditProfileOrganizationButtonSave = styled(Button).attrs({
  'data-class': 'EditProfileOrganizationButtonSave',
})`
  ${divFlex}

  font-size: 16px;
  font-weight: 400;
  color: ${({ theme }) => theme?.themesColors?.neutralBranding1};
  background-color: ${({ theme }) => theme?.themesColors?.neutralBranding8};
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
  border-radius: 3px;

  width: 67px;
  height: 40px;
  align-items: center;
  justify-content: center;
  right: 0;
  margin-left: 8px;
`;
