import { emptyScrollbar } from 'app-wrapper/view/themes/components/emptyScrollbar';
import styled from 'styled-components';

import { divFlex } from 'app-wrapper/view/themes/mixin';
import { Button } from 'app-wrapper/view/components';

export const AccountDprtmSpanEllipsis = styled.span.attrs({
  'data-class': 'AccountDprtmSpanEllipsis',
})`
  max-width: 100%;
  position: relative;
  display: inline;

  text-overflow: ellipsis;
  overflow: clip;
  white-space: nowrap;
`;

export const AccountDprtmContentTableHeader = styled.div.attrs({
  'data-class': 'AccountDprtmContentTableHeader',
})`
  ${divFlex}
  align-items: center;

  height: 36px;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.themesColors.neutralBranding3};
`;

export const AccountDprtmContentTableHeaderAddBtn = styled(Button).attrs({
  'data-class': 'AccountDprtmContentTableHeaderAddBtn',
})`
  ${divFlex}
  width: 140px;
  height: 32px;

  svg {
    color: ${({ theme }) => theme.themesColors.neutralBranding1};
    margin-right: 9px;
  }
  span {
    color: ${({ theme }) => theme.themesColors.neutralBranding1};
  }
`;

export const AccountDprtmContentTableHeaderItem = styled.div.attrs((props: {
  isCenter?: boolean,
  width?: string,
  paddingLeft?: string,
}) => ({
  isCenter: props.isCenter,
  width: props.width,
  paddingLeft: props.paddingLeft,
  'data-class': 'AccountDprtmContentTableHeaderItem',
}))`
  ${divFlex}
  align-items: center;
  justify-content: ${({ isCenter }) => (isCenter ? 'center' : 'left')};

  width: ${({ width }) => (width || 'auto')};
  padding-left: ${({ paddingLeft }) => (paddingLeft || '0')};

  color: ${({ theme }) => theme.themesColors.secondaryDot45};
  font-size: 12px;
  font-weight: 400;
`;

export const AccountDprtmContentTableContentItem = styled.div.attrs({
  'data-class': 'AccountDprtmContentTableContentItem',
})`
  ${divFlex}
  flex-direction: column;
  width: 100%;

  border-bottom: 1px solid ${({ theme }) => theme.themesColors.neutralBranding3}
`;

export const AccountDprtmContentTableContentItemTop = styled.div.attrs({
  'data-class': 'AccountDprtmContentTableContentItemTop',
})`
  ${divFlex}
  ${emptyScrollbar}

  height: 55px;
`;

export const AccountDprtmContentTableContentTitle = styled.div.attrs((props: {
  isActive?: boolean,
  isCapitalize?: boolean,
  isFirst?: boolean,
  isLeft?: boolean,
}) => ({
  isActive: props.isActive,
  isCapitalize: props.isCapitalize,
  isFirst: props.isFirst,
  isLeft: props.isLeft,
  'data-class': 'AccountDprtmContentTableContentTitle',
}))`
  ${divFlex}
  width: 28%;
  align-items: center;
  justify-content: ${({ isLeft }) => (isLeft ? 'left' : 'center')};

  margin-left: 2px;
  color: ${({ theme }) => theme.themesColors.characterBrandingTitle85};
  color: ${({ theme, isActive }) => (isActive ? theme.themesColors.primaryBranding6 : theme.themesColors.characterBrandingTitle85)};
  font-size: 14px;
  font-weight: 400;

  ${({ isCapitalize }) => isCapitalize && 'text-transform: capitalize'};
`;

export const AccountDprtmContentTableContentLink = styled.div.attrs((props: {
  width?: string,
}) => ({
  width: props.width,
  'data-class': 'AccountDprtmContentTableContentLink',
}))`
  ${divFlex}
  width: 28%;
  align-items: center;
  justify-content: left;

  color: ${({ theme }) => theme.themesColors.primaryBranding7};
  font-size: 14px;
  font-weight: 400;

  text-overflow: ellipsis;
  overflow: clip;
  white-space: nowrap;
  cursor: pointer;

  > span:first-letter {
    text-transform: uppercase;
  }
`;

export const AccountDprtmContentTableContentPrimary = styled.div.attrs({
  'data-class': 'AccountDprtmContentTableContentPrimary',
})`
  ${divFlex}
  width: 58px;
  height: 22px;
  align-items: center;
  justify-content: center;

  background-color: ${({ theme }) => theme.themesColors.polarGreen1};
  color: ${({ theme }) => theme.themesColors.polarGreen7};
  font-size: 12px;
  font-weight: 400;
  border: 1px solid ${({ theme }) => theme.themesColors.polarGreen3};
  margin-left: 8px;
  border-radius: 3px;
`;

export const AccountDprtmContentTableContactWrap = styled.div.attrs({
  'data-class': 'AccountDprtmContentTableContactWrap',
})`
  ${divFlex}
  width: 15%;
  align-items: center;
  justify-content: left;
`;

export const AccountDprtmContentTableContact = styled.div.attrs({
  'data-class': 'AccountDprtmContentTableContact',
})`
  ${divFlex}
  flex-direction: row;
  align-items: center;
  gap: 8px;
  justify-content: center;
  min-width: 24px;
  height: 24px;
  font-size: 16px;

  margin: auto auto auto 0;
  color: ${({ theme }) => theme.themesColors.characterSecondaryDot45};
`;

export const AccountDprtmContentTableContactText = styled.span`
  font-size: 14px;
  line-height: 22px;
  color: initial;
`;
