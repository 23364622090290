import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Wrap,
  Text,
  UploadIcon,
} from './DownloadCurrentSetup.styled';

interface IDownloadCurrentStepProps {
  onClick?: () => void;
  text?: string;
}

export const DownloadCurrentStep: FC<IDownloadCurrentStepProps> = ({ onClick, text }) => {
  const { t } = useTranslation();

  return (
    <Wrap onClick={onClick}>
      <UploadIcon />

      <Text>
        {text || t('Download Current Setup')}
      </Text>
    </Wrap>
  );
};
