import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import {
  Wrapper,
  SubPageHeader,
  SubPageHeaderContainer,
  SubPageHeaderTitle,
  GapVerticalContainerStyled,
  GapHorizontalContainerStyled,
} from 'app-wrapper/view/guideline';
import { ShipmentChargesContainer } from 'shipment-operations/view/pages/ShipmentCharges/ShipmentCharges.styled';
import { SkeletonSection } from 'app-wrapper/view/components/Skeleton/Skeleton.component';
import {
  NavigationBar,
  ShipmentInstructionsContent,
  EditShippingPartyDrawer,
  MBLFlowManagement,
  HBLFlowManagement,
} from './components';

interface IShipmentInstructionsProps {
  fetchBillOfLadingPageInformation: (shipmentId: string) => void;
  isLoading: boolean;
  cleanup: () => void;
  isHBLPage: boolean;
}

export const ShipmentBillOfLadingComponent: FC<IShipmentInstructionsProps> = ({
  fetchBillOfLadingPageInformation,
  isLoading,
  cleanup,
  isHBLPage,
}) => {
  const { shipmentId = '0' } = useParams();
  const { t } = useTranslation();

  useEffect(() => {
    fetchBillOfLadingPageInformation(shipmentId);

    return () => {
      cleanup();
    };
  }, [shipmentId]);

  return (
    <Wrapper>
      <SubPageHeader>
        <SubPageHeaderContainer>
          <SubPageHeaderTitle>
            {t('Bills of Lading')}
          </SubPageHeaderTitle>
        </SubPageHeaderContainer>
      </SubPageHeader>
      {isLoading ? (
        <GapVerticalContainerStyled>
          <SkeletonSection height="55px" />
          <SkeletonSection height="187px" />
          <SkeletonSection height="118px" />
          <GapHorizontalContainerStyled>
            <SkeletonSection height="110px" />
            <SkeletonSection height="110px" />
          </GapHorizontalContainerStyled>
          <SkeletonSection height="224px" />
          <SkeletonSection height="106px" />
          <SkeletonSection height="222px" />
          <SkeletonSection height="146px" />
          <SkeletonSection height="110px" />
          <SkeletonSection height="110px" />
          <SkeletonSection height="118px" />
          <GapHorizontalContainerStyled>
            <SkeletonSection height="232px" width="33%" />
            <SkeletonSection height="232px" width="66%" />
          </GapHorizontalContainerStyled>
        </GapVerticalContainerStyled>
      )
        : (
          <>
            <NavigationBar />

            <EditShippingPartyDrawer />

            <ShipmentChargesContainer>
              {!isHBLPage ? <MBLFlowManagement /> : <HBLFlowManagement />}

              <ShipmentInstructionsContent />
            </ShipmentChargesContainer>
          </>
        )}
    </Wrapper>
  );
};
