import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';
import { UC } from 'shipment-operations/controllers';
import { BillOfLadingOriginalsDistributionComponent } from './BillOfLadingOriginalsDistribution.component';

const BillOfLadingOriginalsDistributionContainer: FC = () => {
  const {
    originals,
    errors,
    touchedFields,
    wasUpdateAttempted,
    shippingParties,
    type,
  } = useSelector(R.selectors.billOfLading.getAllBillOfLadingInformation);
  const permissions = useSelector(R.selectors.shipment.getDocumentsPermissions);
  const isOnlyHouseConsigneeAvailable = useSelector(R.selectors.billOfLading.getIsOnlyHouseConsigneeAvailable);
  const shippingPartiesRecord = useSelector(R.selectors.billOfLadingCommon.getShippingPartiesAsMap);

  return (
    <BillOfLadingOriginalsDistributionComponent
      shippingPartiesRecord={shippingPartiesRecord}
      shippingPartiesMap={shippingParties}
      billOfLadingType={type}
      entries={originals}
      errors={errors.originals}
      touchedFields={touchedFields}
      wasUpdateAttempted={wasUpdateAttempted}
      touchField={UC.billOfLading.touchField}
      setShippingParty={UC.billOfLading.setOriginalParty}
      setAmount={UC.billOfLading.setOriginalAmount}
      addEntry={UC.billOfLading.addOriginal}
      setFreighted={UC.billOfLading.setOriginalFreighted}
      removeEntry={UC.billOfLading.removeOriginal}
      billOfLadingPermission={permissions.billOfLadingDocuments}
      isOnlyHouseConsigneeAvailable={isOnlyHouseConsigneeAvailable}
    />
  );
};

export {
  BillOfLadingOriginalsDistributionContainer as BillOfLadingOriginalsDistribution,
};
