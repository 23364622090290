import {
  IsString,
  IsDefined,
  IsNumber,
  IsEnum,
  ValidateNested,
  IsOptional,
} from 'class-validator';
import { Type } from 'class-transformer';
import { BaseDTM } from 'proto/BaseDTM';

import { ShipmentRoutesType, ShipmentStatusEnum } from 'shipment-operations/constants';
import {
  ITransportPlanDTM,
  ICargoDTM,
  IContainerInputDTM,
  ITemperatureControlDTM,
  IBookingDetailsDTM,
  IShipmentInstructionsDetailsDTM,
  IExportCustomsDTM,
  IPaymentTermsDTM,
  TransportPlanDTM,
  CargoDTM,
  ContainerDTM,
  TemperatureControlDTM,
  BookingDetailsDTM,
  ShipmentInstructionsDetailsDTM,
  ExportCustomsDTM,
  PaymentTermsDTM,
} from 'shipment-operations/models/dtm';
import { DateDtm, IDateDTM } from 'app-wrapper/models/dtm';
import { TOrganizationDTM, OrganizationDTM } from 'user-management/models/dtm';

export interface IFullShipmentLocationDTM {
  id: string
  type?: ShipmentRoutesType
  terminalName?: string
  passCode?: string
  address1?: string
  address2?: string
  address3?: string
  address4?: string
  city?: string
  state?: string
  postcode?: string
  country?: string
  time?: IDateDTM
}

export class FullShipmentLocationDTM extends BaseDTM<IFullShipmentLocationDTM> {
  @IsString()
  @IsDefined()
  id: string;

  @IsOptional()
  @IsEnum(ShipmentRoutesType)
  type: ShipmentRoutesType;

  @IsString()
  @IsOptional()
  terminalName: string;

  @IsString()
  @IsOptional()
  passCode: string;

  @IsString()
  @IsOptional()
  address1: string;

  @IsString()
  @IsOptional()
  address2: string;

  @IsString()
  @IsOptional()
  address3: string;

  @IsString()
  @IsOptional()
  address4: string;

  @IsString()
  @IsOptional()
  city: string;

  @IsString()
  @IsOptional()
  state: string;

  @IsString()
  @IsOptional()
  postcode: string;

  @IsString()
  @IsOptional()
  country: string;

  @IsOptional()
  @ValidateNested()
  @Type(() => DateDtm)
  time?: DateDtm;
}

export interface IFullShipmentDTM {
  id: number;
  createdBy: string;
  status: ShipmentStatusEnum;
  earliestEmptyReleaseDate: DateDtm | null;
  organization: TOrganizationDTM;
  transportationPlans: ITransportPlanDTM[];
  locations: IFullShipmentLocationDTM[];
  cargos: ICargoDTM[];
  containers: IContainerInputDTM[];
  temperatureControl: ITemperatureControlDTM | null;
  carrierName: string;
  bookingDetails: IBookingDetailsDTM | null;
  siDetails: IShipmentInstructionsDetailsDTM;
  exportCustoms: IExportCustomsDTM;
  paymentTerms: IPaymentTermsDTM;
  shipmentReference: string;
  mainVesselName: string;
}

export class FullShipmentDTM extends BaseDTM<IFullShipmentDTM> {
  @IsDefined()
  @IsNumber()
  id: number;

  @IsDefined()
  @IsString()
  createdBy: string;

  @IsDefined()
  @IsEnum(ShipmentStatusEnum)
  status: ShipmentStatusEnum;

  @IsOptional()
  @ValidateNested()
  @Type(() => DateDtm)
  earliestEmptyReleaseDate: DateDtm | null;

  @IsDefined()
  @ValidateNested()
  @Type(() => OrganizationDTM)
  organization: OrganizationDTM;

  @IsDefined()
  @ValidateNested({ each: true })
  @Type(() => TransportPlanDTM)
  transportationPlans: TransportPlanDTM[];

  @IsDefined()
  @ValidateNested({ each: true })
  @Type(() => FullShipmentLocationDTM)
  locations: FullShipmentLocationDTM[];

  @IsDefined()
  @ValidateNested({ each: true })
  @Type(() => CargoDTM)
  cargos: CargoDTM[];

  @IsDefined()
  @ValidateNested({ each: true })
  @Type(() => ContainerDTM)
  containers: ContainerDTM[];

  @IsOptional()
  @ValidateNested()
  @Type(() => TemperatureControlDTM)
  temperatureControl: TemperatureControlDTM | null;

  @IsDefined()
  @IsString()
  carrierName: string;

  @IsOptional()
  @ValidateNested()
  @Type(() => BookingDetailsDTM)
  bookingDetails: IBookingDetailsDTM | null;

  @IsDefined()
  @ValidateNested()
  @Type(() => ShipmentInstructionsDetailsDTM)
  siDetails: ShipmentInstructionsDetailsDTM;

  @IsDefined()
  @ValidateNested()
  @Type(() => ExportCustomsDTM)
  exportCustoms: ExportCustomsDTM;

  @IsDefined()
  @ValidateNested()
  @Type(() => PaymentTermsDTM)
  paymentTerms: PaymentTermsDTM;

  @IsDefined()
  @IsString()
  shipmentReference: string;

  @IsDefined()
  @IsString()
  mainVesselName: string;
}
