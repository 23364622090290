import React from 'react';
import { IPropsIcon } from 'app-wrapper/interfaces/icon';

import { Icon } from './ArrowLeftSvg.styled';

export const ArrowLeftSvg = (props: IPropsIcon) => {
  const { width, height, fill } = props;
  return (
    <Icon
      className="ArrowLeftSvg"
      width={width || '14'}
      height={height || '14'}
      viewBox="0 0 14 14"
      fill={fill || 'currentColor'}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M13.6563 6.32117H3.04292L9.39537 0.892593C9.49695 0.805093 9.43527 0.642593 9.30104 0.642593H7.6957C7.62495 0.642593 7.55784 0.667593 7.50523 0.712236L0.650329 6.56759C0.587546 6.62117 0.537194 6.6874 0.502686 6.76181C0.468177 6.83621 0.450317 6.91705 0.450317 6.99884C0.450317 7.08064 0.468177 7.16148 0.502686 7.23588C0.537194 7.31029 0.587546 7.37652 0.650329 7.43009L7.54514 13.3212C7.57235 13.3444 7.605 13.3569 7.63947 13.3569H9.29923C9.43346 13.3569 9.49513 13.1926 9.39355 13.1069L3.04292 7.67831H13.6563C13.7361 7.67831 13.8014 7.61402 13.8014 7.53545V6.46402C13.8014 6.38545 13.7361 6.32117 13.6563 6.32117Z" fill="currentColor" fillOpacity="0.85" />
    </Icon>
  );
};
