import { createSelector } from 'reselect';

import { RootState } from 'app-wrapper/store';

const getLocalState = (state: RootState) => state.transportationOverview;

const getWholeState = createSelector(
  getLocalState,
  (state) => state,
);

const getTransportationOverview = createSelector(
  getLocalState,
  (state) => state.transportationOverview,
);

const getLoading = createSelector(
  getLocalState,
  (state) => state.isLoading,
);

const getIsCutoffEditModalOpen = createSelector(
  getLocalState,
  (state) => state.isCutoffEditModalOpen,
);

const getCutoffFtDates = createSelector(
  getLocalState,
  (state) => state.transportationOverview?.freightuneCutoffs,
);

const getSavedCarrierCutoffDates = createSelector(
  getLocalState,
  (state) => state.carrierCutoffsSavedData,
);

const getSavedFreightuneCutoffDates = createSelector(
  getLocalState,
  (state) => state.freightuneCutoffsSavedData,
);

const isCutoffsDataChanged = createSelector(
  getLocalState,
  (state) => (!state.carrierCutoffsSavedData && (
    !!state.transportationOverview.documentationCarrierDate
    || !!state.transportationOverview.portCarrierDate
    || !!state.transportationOverview.vgmCarrierDate
    || !!state.transportationOverview.hazmatCarrierDate
  )) || (!!state.carrierCutoffsSavedData && (
    state.carrierCutoffsSavedData.documentationCarrierDate !== state.transportationOverview.documentationCarrierDate
    || state.carrierCutoffsSavedData.portCarrierDate !== state.transportationOverview.portCarrierDate
    || state.carrierCutoffsSavedData.vgmCarrierDate !== state.transportationOverview.vgmCarrierDate
    || state.carrierCutoffsSavedData.hazmatCarrierDate !== state.transportationOverview.hazmatCarrierDate
  )),
);

const getOceanCarrierName = createSelector(
  getLocalState,
  (state) => state.oceanCarrierName,
);

const getCarrierSCAC = createSelector(
  getLocalState,
  (state) => state.carrierSCAC,
);

export const transportationOverviewSelectors = {
  getCutoffFtDates,
  getIsCutoffEditModalOpen,
  getLoading,
  getSavedCarrierCutoffDates,
  getSavedFreightuneCutoffDates,
  getTransportationOverview,
  getWholeState,
  isCutoffsDataChanged,
  getOceanCarrierName,
  getCarrierSCAC,
};
