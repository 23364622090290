import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { R } from 'user-management/repository';

import { CIContactInformationComponent } from './CIContactInformation.component';

const CIContactInformationContainer: FC = () => {
  const organization = useSelector(R.selectors.userOrganizationData.getInternalOrganization);

  return (
    <CIContactInformationComponent
      organization={organization}
    />
  );
};

export { CIContactInformationContainer as CIContactInformation };
