import InfoCircleOutlined from '@ant-design/icons/InfoCircleOutlined';
import React, {
  FC,
  useCallback,
  ChangeEvent,
  useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import ArrowLeftOutlined from '@ant-design/icons/ArrowLeftOutlined';
import LockOutlined from '@ant-design/icons/LockOutlined';
import UnlockOutlined from '@ant-design/icons/UnlockOutlined';

import { RouteNames } from 'app-wrapper/constants';
import { MailCodeSvg, SkypaceDarkLogo } from 'app-wrapper/view/icons';
import { Tooltip } from 'app-wrapper/view/components';

import {
  BottomMessage,
  LogoWrapper,
  PasswordResetNewPasswordButton,
  PasswordResetNewPasswordForm,
  PasswordResetNewPasswordMessageHeader,
  PasswordResetNewPasswordTitle,
  PasswordResetNewPasswordWrapper,
  PasswordResetNewPasswordPasswordInput,
  PasswordResetNewPasswordPreviousLinkWrapper,
} from './PasswordResetNewPassword.styled';

interface IPasswordResetNewPasswordComponentProps {
  loading: boolean
  codeValue?: string
  codeError?: Error
  onCodeFieldChange: (email: string) => void
  onCodeFieldBlur: () => void
  newPasswordValue?: string
  newPasswordError?: Error
  onNewPasswordFieldChange: (email: string) => void
  onNewPasswordFieldBlur: () => void
  confirmNewPasswordValue?: string
  confirmNewPasswordError?: Error
  onConfirmNewPasswordFieldChange: (email: string) => void
  onConfirmNewPasswordFieldBlur: () => void
  onPasswordChange: () => void
  onScreenLeave: () => void
}

export const PasswordResetNewPasswordComponent: FC<IPasswordResetNewPasswordComponentProps> = ({
  loading,
  codeValue,
  codeError,
  onCodeFieldChange,
  onCodeFieldBlur,
  newPasswordValue,
  newPasswordError,
  onNewPasswordFieldChange,
  onNewPasswordFieldBlur,
  confirmNewPasswordValue,
  confirmNewPasswordError,
  onConfirmNewPasswordFieldChange,
  onConfirmNewPasswordFieldBlur,
  onPasswordChange,
  onScreenLeave,
}) => {
  const { t } = useTranslation();

  useEffect(() => onScreenLeave, []);

  const codeChangeHandler = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      onCodeFieldChange(event.currentTarget.value);
    },
    [],
  );

  const codeBlurHandler = useCallback(
    () => {
      onCodeFieldBlur();
    },
    [],
  );

  const newPasswordChangeHandler = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      onNewPasswordFieldChange(event.currentTarget.value);
    },
    [],
  );

  const newPasswordBlurHandler = useCallback(
    () => {
      onNewPasswordFieldBlur();
    },
    [],
  );

  const confirmNewPasswordChangeHandler = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      onConfirmNewPasswordFieldChange(event.currentTarget.value);
    },
    [],
  );

  const confirmNewPasswordBlurHandler = useCallback(
    () => {
      onConfirmNewPasswordFieldBlur();
    },
    [],
  );

  const changeHandler = useCallback(
    () => {
      onPasswordChange();
    },
    [],
  );

  return (
    <PasswordResetNewPasswordWrapper>
      <PasswordResetNewPasswordForm>
        <LogoWrapper>
          <SkypaceDarkLogo />
        </LogoWrapper>
        <PasswordResetNewPasswordTitle>
          {t('Empowering Global Trade Logistics')}
        </PasswordResetNewPasswordTitle>
        <PasswordResetNewPasswordMessageHeader>
          {t('Change Password')}
          <span>
            <Tooltip title={t('Password hint')}>
              <InfoCircleOutlined />
            </Tooltip>
          </span>
        </PasswordResetNewPasswordMessageHeader>
        <Tooltip mode="danger" title={codeError?.message} visible={!!codeError}>
          <PasswordResetNewPasswordPasswordInput
            placeholder={t('Code from Email')}
            data-class="CodeInput"
            prefix={<MailCodeSvg />}
            value={codeValue}
            error={!!codeError}
            size="large"
            onChange={codeChangeHandler}
            onBlur={codeBlurHandler}
          />
        </Tooltip>
        <Tooltip mode="danger" title={newPasswordError?.message} visible={!!newPasswordError}>
          <PasswordResetNewPasswordPasswordInput
            placeholder={t('New Password')}
            data-class="NewPasswordInput"
            prefix={<LockOutlined />}
            value={newPasswordValue}
            error={!!newPasswordError}
            size="large"
            onChange={newPasswordChangeHandler}
            onBlur={newPasswordBlurHandler}
          />
        </Tooltip>
        <Tooltip mode="danger" title={confirmNewPasswordError?.message} visible={!!confirmNewPasswordError}>
          <PasswordResetNewPasswordPasswordInput
            placeholder={t('Confirm New Password')}
            data-class="ConfirmNewPasswordInput"
            prefix={<UnlockOutlined />}
            value={confirmNewPasswordValue}
            error={!!confirmNewPasswordError}
            size="large"
            onChange={confirmNewPasswordChangeHandler}
            onBlur={confirmNewPasswordBlurHandler}
          />
        </Tooltip>
        <PasswordResetNewPasswordButton size="large" onClick={changeHandler} loading={loading}>
          {t('Change')}
        </PasswordResetNewPasswordButton>
        <PasswordResetNewPasswordPreviousLinkWrapper>
          <Link to={RouteNames.FORGOT_PASSWORD_EMAIL_SEND()}><ArrowLeftOutlined />{t('Request New Code')}</Link>
        </PasswordResetNewPasswordPreviousLinkWrapper>
      </PasswordResetNewPasswordForm>
      <BottomMessage />
    </PasswordResetNewPasswordWrapper>
  );
};
