import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { UC } from 'shipment-operations/controllers';
import { R } from 'shipment-operations/repository';

import { CargoFormDescriptionInputComponent } from './CargoFormDescriptionInput.component';

const CargoFormDescriptionInputContainer: FC = () => {
  const { description } = useSelector(R.selectors.cargo.getCargo);
  const permissions = useSelector(R.selectors.shipment.getCargosPermissions);

  return (
    <CargoFormDescriptionInputComponent
      description={description}
      touchField={UC.cargo.touchField}
      setDescription={UC.cargo.setDescription}
      cargosAvailability={permissions.cargosAvailability}
    />
  );
};

export {
  CargoFormDescriptionInputContainer as CargoFormDescriptionInput,
};
