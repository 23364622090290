import React from 'react';

import { useAppSelector } from 'app-wrapper/hooks';

import { R } from 'shipment-operations/repository';
import { UC } from 'shipment-operations/controllers';

import { PaymentFooter } from './Footer.component';

const FooterContainer = () => {
  const isLoading = useAppSelector(R.selectors.makePayment.getIsLoadingEdit);
  const disabled = useAppSelector(R.selectors.makePayment.getIsDisableEdit);

  return (
    <PaymentFooter
      disabled={disabled}
      isLoading={isLoading}
      onClick={UC.makePayment.onEdit}
      buttonName="payment.save"
    />
  );
};

export { FooterContainer as Footer };
