import { ENotificationStatus } from 'app-wrapper/constants';
import { UC } from 'app-wrapper/controllers';
import { R } from 'app-wrapper/repository';
import { DrawersUseCase } from 'app-wrapper/usecases/Drawers.useCase';
import { BaseController, controller } from 'proto/BaseController';
import { ETasksType, EContainerRailBillingTypes } from 'shipment-operations/constants';
import { ContainerDTM } from 'shipment-operations/models/dtm';
import { R as shipmentR } from 'shipment-operations/repository';

@controller
export class RailBillingDrawerController extends BaseController {
  public init = async (shipmentId: string) => {
    this.dispatch(R.actions.railBillingDrawer.setIsDrawerOpened(true));
    this.dispatch(R.actions.railBillingDrawer.setIsLoading(true));

    const shipment = await shipmentR.services.shipment.getShipmentShortById(+shipmentId);
    const containers = await shipmentR.services.shipmentContainers.getContainersList(shipmentId);
    const markedContainers = containers.map((container) => (container.railBilling && container.railBilling.status === EContainerRailBillingTypes.SUBMITTED ? container.id : ''))
      .filter((item) => !!item.length);

    const commandCenterState = R.selectors.commandCenter.getCommandCenter(this.store.getState()).currentState;
    const commandCenterTasks = [
      ...commandCenterState.content,
      ...(commandCenterState.contentLater || []),
      ...(commandCenterState.contentToday || []),
      ...(commandCenterState.contentThisWeek || []),
      ...(commandCenterState.contentOverdue || []),
    ];
    const overviewTasks = R.selectors.overview.getTasks(this.store.getState());
    const task = [...commandCenterTasks, ...overviewTasks].find(({ metadata }) => metadata && metadata.shipmentId === String(shipment.id) && metadata.taskType === ETasksType.SUBMIT_RAIL_BILLING);

    this.dispatch(R.actions.railBillingDrawer.setDueDate(task?.dueDate || null));
    this.dispatch(R.actions.railBillingDrawer.setMarkedContainers(markedContainers));
    this.dispatch(R.actions.railBillingDrawer.setContainers(containers));
    this.dispatch(R.actions.railBillingDrawer.setShipment(shipment));
    this.dispatch(R.actions.railBillingDrawer.setIsLoading(false));
  };

  public markAllContainers = () => {
    const containers = R.selectors.railBillingDrawer.getContainers(this.store.getState());

    this.dispatch(R.actions.railBillingDrawer.setMarkedContainers(containers.map(({ id }) => id)));
  };

  public markContainer = (id: string) => {
    const markedContainers = R.selectors.railBillingDrawer.getMarkedContainers(this.store.getState());

    this.dispatch(R.actions.railBillingDrawer.setMarkedContainers([
      ...markedContainers,
      id,
    ]));
  };

  public acceptRailBilling = async () => {
    const shipment = R.selectors.railBillingDrawer.getShipment(this.store.getState());
    let containers = R.selectors.railBillingDrawer.getContainers(this.store.getState());

    if (!shipment) {
      return;
    }

    this.dispatch(R.actions.railBillingDrawer.setIsLoading(true));

    containers = containers.map((container) => ContainerDTM.fromPlain({
      ...container,
      railBilling: {
        status: EContainerRailBillingTypes.SUBMITTED,
      },
    }));

    await Promise.all(containers.map((container) => shipmentR.services.shipmentContainers.putContainer(String(shipment.id), container)));

    const commandCenterState = R.selectors.commandCenter.getCommandCenter(this.store.getState()).currentState;
    const commandCenterTasks = [
      ...commandCenterState.content,
      ...(commandCenterState.contentLater || []),
      ...(commandCenterState.contentToday || []),
      ...(commandCenterState.contentThisWeek || []),
      ...(commandCenterState.contentOverdue || []),
    ];
    const overviewTasks = R.selectors.overview.getTasks(this.store.getState());
    const task = [...commandCenterTasks, ...overviewTasks].find(({ metadata }) => metadata && metadata.shipmentId === String(shipment.id) && metadata.taskType === ETasksType.SUBMIT_RAIL_BILLING);

    await R.services.CommandCenter.putTask({
      ...task,
      status: ENotificationStatus.DONE,
    });

    UC.CommandCenter.removeTaskFromTheStore(String(shipment.id), ETasksType.SUBMIT_RAIL_BILLING);
    UC.overview.removeTaskFromDashboard(String(shipment.id), ETasksType.SUBMIT_RAIL_BILLING);

    this.closeRailBillingDrawer();
  };

  public closeRailBillingDrawer = () => {
    this.dispatch(R.actions.railBillingDrawer.setIsLoading(false));
    this.dispatch(R.actions.railBillingDrawer.setIsDrawerOpened(false));
    new DrawersUseCase(this).closeDrawer();
  };
}
