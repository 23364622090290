import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { OrganizationCoverageAreasDTM } from 'user-management/models/dtm';
import {
  OrganizationAddressHeader,
  OrganizationAddressWrapper,
} from 'user-management/view/pages/OrganizationProfile/components/OrganizationAddress/OrganizationAddress.styled';
import countries from 'app-wrapper/repository/store/CountriesList/countries.json';
import { Label, LabelsRow } from 'app-wrapper/view/guideline';

interface IUserDataComponentProps {
  coverageAreas?: OrganizationCoverageAreasDTM[]
}

export const OrganizationCoverageAreasComponent: FC<IUserDataComponentProps> = ({
  coverageAreas,
}) => {
  const { t } = useTranslation();
  return (
    <OrganizationAddressWrapper>
      <OrganizationAddressHeader>{t('Coverage Area')}</OrganizationAddressHeader>
      <LabelsRow>
        {(coverageAreas || []).map((coverageArea) => (
          <Label key={coverageArea.countryCode}>
            {coverageArea?.countryCode ? countries[coverageArea.countryCode as keyof typeof countries] : null}
          </Label>
        ))}
      </LabelsRow>
    </OrganizationAddressWrapper>
  );
};
