import React from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';
import { UC } from 'shipment-operations/controllers';

import { DrawerFooterComponent } from './DrawerFooter.component';

const DrawerFooterContainer = () => {
  const isLoading = useSelector(R.selectors.billOfLadingCommon.getIsEditShippingPartyDrawerLoading);

  return (
    <DrawerFooterComponent
      onClose={UC.billOfLading.closeEditShippingPartyDrawer}
      onSave={UC.billOfLading.saveEditShippingPartyDrawerChanges}
      isDisabled={isLoading}
    />
  );
};

const DrawerFooterContainerCached = React.memo(DrawerFooterContainer);

export { DrawerFooterContainerCached as DrawerFooter };
