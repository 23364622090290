import styled from 'styled-components';

import { Select } from 'app-wrapper/view/components';

export const SelectStyled = styled(Select)`
  &.ant-select {
    min-height: 32px;
    height: 32px;
  }
`;
