import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';

import {
  AppFiltersCheckboxDTM,
  AppFiltersCheckboxGroupDTM,
  AppFiltersMinMaxDTM,
  DateDtm,
} from 'app-wrapper/models/dtm';
import { apiWorker } from 'app-wrapper/repository/utilsServices';
import { ServerErrorAxios } from 'app-wrapper/types/ServerError';

import {
  GetRateServiceResponse,
  IGetAllQuotasRequestResponse,
  IGetBookingIdRequest,
  IGetBookingIdResponse,
  IGetHsCodeResponse,
  IGetQuotasMakeResponse,
  IGetRFQDoorAutocompleteResponse,
  IGetRFQRequestByIdResponse,
  IPostQuotaBookmarkRequest,
  IPostQuotaBookmarkResponse,
  ISessionRFQDoorAutocomplete,
  LocationsServiceResponse,
  postCreateQuotaParamsRequest,
  GetOrganizationsContract,
  IGetAllRFRQuotasResponse,
  IGetAllRFRSchedulesResponse,
  GetCheckRFRSchedulesParams,
  PostRateRequestParams,
  GetRFRRequestByIdResponse,
  IGetCommodityItemsParams,
  IGetCommodityItemsResponse,
  PutRateRequestParams,
  GetOceanRatesParams,
  GetOceanRatesResponseContract,
  postCreateQuotaGetParamsRequest,
  GetOrganizationByIdContract,
  GetAdditionalServiceResponse,
} from 'monetary/models/contracts';
import { TGetCompanyListResponse } from 'monetary/models/contracts/Quotas/getCompoanyListResponse';
import {
  IGetQuotaRequestServiceByIdResponse,
  IGetRFQServiceFiltersResponse,
  ILocationServiceParamsResponse,
  IQuotaRequestServiceByIdResponseContent,
} from 'monetary/models/contracts/Quotas/getQuotaRequestServiceByIdResponse';
import {
  CustomerInfoDTM,
  FreightDoorsAutocompleteDTM,
  FreightFiltersDTM,
  FreightOceanRatesDTM,
  FreightQuotaContentCommodity,
  FreightQuotaContentContainerDTM,
  FreightQuotaContentContainerRequestsContainerDTM,
  FreightQuotaContentContainerRequestsDTM,
  FreightQuotaContentContainerRequestsLocationDTM,
  FreightQuotaContentContractsDTM,
  FreightQuotaContentCostDTM,
  FreightQuotaContentCustomerDTM,
  FreightQuotaContentDTM,
  FreightQuotaContentRouteLegDTM,
  FreightQuotaContentRoutesDTM,
  FreightQuotaContentRoutesLocationDTM,
  FreightQuotaContentSchedulesChargesApplianceRangeDTM,
  FreightQuotaContentSchedulesChargesChargeCodeDTM,
  FreightQuotaContentSchedulesChargesDTM,
  FreightQuotaContentSchedulesDTM,
  FreightQuotaContentValidPeriodDTM,
  FreightQuotaPageableDTM,
  FreightQuotaTransportationDTM,
  FreightQuotaTransportationScheduleDTM,
  FreightQuotaTransportationTransportDTM,
  FreightRFRQuotaContentDTM,
  FreightRFRQuotaContentParamsDTM,
  FreightRFRQuotaContentParamsLocationDTM,
  FreightRFRQuotaScheduleCarrierCodeDTM,
  FreightRFRQuotaScheduleDTM,
  FreightRFRQuotaScheduleRouteDTM,
  FreightRFRQuotaScheduleRouteTransportLegDTM,
  FreightRFRQuotaScheduleRouteTransportLegVoyageTransportDTM,
  FreightSelectFieldCoordinatesDTM,
  FreightSelectFieldCountryDTM,
  QuotaServiceByIdContentRoutesLegsLocationDTM,
} from 'monetary/models/dtm';
import {
  AdditionalRatesServiceApplicationLevelDTM,
  AdditionalRatesServiceApplicationRangeDTM,
  AdditionalRatesServiceChargeCodeDTM,
  AdditionalRatesServiceDTM,
  AdditionalRatesServiceValidPeriodDTM,
  AdditionalServiceCheckedDTM,
  AdditionalServiceDTM,
  CompanyDTM,
  CompanyForViewDTM,
  HsCodeDTM,
  IGetAdditionalServiceParamsDTM,
  IGetAllQuotasParamsDTM,
  IGetQueryParamsDTM,
  IQuotaServiceFiltersParamsDTM,
  LocationsServiceDTM,
  QuotaBookmark,
  RFQQuotasDTM,
  RFRQuotasDTM,
} from 'monetary/models/dtm/Quotas';

import { TGetCurrentOrganizationResponseContract } from 'user-management/models/contracts';
import {
  EOrganizationStatus,
  OrganizationDTM,
  BusinessRegistrationNumberDtm,
  OrganizationMembershipDtm,
} from 'user-management/models/dtm';
import { EFreightLoadType } from 'monetary/constants';

interface IAsyncThunkGetParams {
  urlGetParams?: Array<string>
  getParams?: { [key: string]: string }
  urlId?: string
  url: string
  afterUrlIdPrefix?: string
}

export class RFQServiceByIdService {
  constructor() {
    try {
      const script = window.document.createElement('script');
      script.type = 'text/javascript';
      script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&libraries=places&language=en`;

      window.document.head.appendChild(script);
    } catch (e) {
      console.error('HTML tag "head" not found');
    }
  }

  private base = '/quota-service/api/v1/requests';

  private baseRateRequests = '/rate-service/api/v1/rate-requests';

  private oceanRates = '/rate-service/api/v1/ocean/rates';

  private baseServiceRateRequests = '/schedule-service/api/v1/schedules';

  private urlOrganizationBase = '/organization-service/api/v1/organizations'

  private urlCompany = '/contact-service/api/v1/companies';

  private urlOrganization = 'organization-service/api/v1/organizations'

  private shipmentServiceUrl = '/shipment-service/api/v1/shipments';

  private commodityHsCode = '/commodity-service/api/v1/commodities';

  private commodityItems = '/commodity-service/api/v1/commodity-items';

  private locationService = '/location-service/api/v1/locations';

  private rateService = '/rate-service/api/v1/services/codes';

  private additionalService = '/rate-service/api/v1/services/rates';

  private page = 0;

  private size = 6;

  private googleAutocompleteSessionStorage = 'getRFQDoorAutocomplete'

  public getHasOwnQuotaRequests = async (): Promise<boolean> => {
    try {
      const response = await apiWorker.requestGet<IGetQuotaRequestServiceByIdResponse>(`${this.base}?onlyMine=true`);

      if (response.data) {
        return response.data.content.length !== 0;
      }
    } catch (e) {
      console.error('RFQServiceById ERROR: getHasOwnQuotaRequests');
    }

    return false;
  }

  public getRFQServiceById = async (props: IGetAllQuotasParamsDTM) => {
    const {
      serviceId,
      size,
      page,
      filters,
    } = props;
    let response: IGetQuotaRequestServiceByIdResponse | null = null;
    let result: RFQQuotasDTM | null = null;

    const {
      minTotalCost = '',
      maxTotalCost = '',
      minTotalTransitTime = '',
      maxTotalTransitTime = '',
      carriers = [],
      isFavorite = false,
      includeRelatedPorts = false,
    } = filters || {};

    const getParams: ILocationServiceParamsResponse['getParams'] = {
      page: `${page || this.page}`,
      size: `${size || this.size}`,
    };

    if (minTotalCost) {
      getParams.minTotalCost = `${minTotalCost}`;
    }
    if (maxTotalCost) {
      getParams.maxTotalCost = `${maxTotalCost}`;
    }
    if (minTotalTransitTime) {
      getParams.minTotalTransitTime = `${minTotalTransitTime}`;
    }
    if (maxTotalTransitTime) {
      getParams.maxTotalTransitTime = `${maxTotalTransitTime}`;
    }
    if (carriers?.length) {
      getParams.carriers = carriers.join(',');
    }
    getParams.onlyFavorites = `${isFavorite}`;
    getParams.includeRelatedPorts = `${includeRelatedPorts}`;

    if (window.sessionStorage.getItem('getRFQServiceById') === 'true') {
      await fetch(`https://aad11d6e-fa8a-41ba-8921-0227da5a3c7f.mock.pstmn.io/quotas?${serviceId}`)
        .then(async (value) => {
          await value.json().then<IGetQuotaRequestServiceByIdResponse>((res) => {
            response = res;
            return res;
          });
        });
    } else {
      const responseRaw = await apiWorker.requestGet<IGetQuotaRequestServiceByIdResponse>(this.formatQuotasUrl({
        getParams,
        urlId: `${serviceId}`,
        url: this.base,
        afterUrlIdPrefix: 'quotas',
      }));

      response = responseRaw.data;
    }

    if (response) {
      const content: Array<FreightQuotaContentDTM | null> = response.content.map((itemContent) => {
        const newItem = FreightQuotaContentDTM.fromPlain({
          id: itemContent.id,
          tradeType: itemContent.tradeType,
          incoterm: itemContent.incoterm,
          includeRelatedPorts: itemContent.includeRelatedPorts,
          cost: FreightQuotaContentCostDTM.fromPlain({
            totalCost: itemContent.cost.totalCost,
            originTotalCost: itemContent.cost.originTotalCost,
            freightTotalCost: itemContent.cost.freightTotalCost,
            destinationTotalCost: itemContent.cost.destinationTotalCost,
          }),
          routes: itemContent.routes.map((itemRoute) => FreightQuotaContentRoutesDTM.fromPlain({
            origin: this.getLocation(QuotaServiceByIdContentRoutesLegsLocationDTM.fromPlain({
              id: itemRoute.origin.id,
              address: itemRoute.origin.address,
              city: itemRoute.origin.city,
              code: itemRoute.origin.code,
              coordinates: itemRoute.origin?.coordinates && FreightSelectFieldCoordinatesDTM.fromPlain({
                lat: `${itemRoute?.origin?.coordinates?.lat || ''}`,
                lng: `${itemRoute?.origin?.coordinates?.lng || ''}`,
              }),
              country: FreightSelectFieldCountryDTM.fromPlain({
                code: itemRoute.origin?.country?.code,
                name: itemRoute.origin?.country?.name,
              }),
              name: itemRoute.origin?.name,
              placeId: itemRoute.origin?.placeId,
              postalCode: itemRoute.origin?.postalCode,
              state: FreightSelectFieldCountryDTM.fromPlain({
                code: itemRoute.origin.state?.code,
                name: itemRoute.origin.state?.name,
              }),
              timeZoneId: itemRoute.origin?.timeZoneId,
              type: itemRoute.origin?.type,
            })),
            destination: this.getLocation(QuotaServiceByIdContentRoutesLegsLocationDTM.fromPlain({
              id: itemRoute.destination.id,
              address: itemRoute.destination?.address,
              city: itemRoute.destination?.city,
              code: itemRoute.destination?.code,
              coordinates: itemRoute.destination?.coordinates && FreightSelectFieldCoordinatesDTM.fromPlain({
                lat: `${itemRoute?.destination?.coordinates?.lat || ''}`,
                lng: `${itemRoute?.destination?.coordinates?.lng || ''}`,
              }),
              country: FreightSelectFieldCountryDTM.fromPlain({
                code: itemRoute.destination?.country?.code,
                name: itemRoute.destination?.country?.name,
              }),
              name: itemRoute.destination?.name,
              placeId: itemRoute.destination?.placeId,
              postalCode: itemRoute.destination?.postalCode,
              state: FreightSelectFieldCountryDTM.fromPlain({
                code: itemRoute.destination?.state?.code,
                name: itemRoute.destination?.state?.name,
              }),
              timeZoneId: itemRoute.destination?.timeZoneId,
              type: itemRoute.destination?.type,
            })),
            legs: itemRoute.legs.map((itemRouteLeg) => FreightQuotaContentRouteLegDTM.fromPlain({
              id: itemRouteLeg.id,
              phase: itemRouteLeg.phase,
              arrivalLocation: QuotaServiceByIdContentRoutesLegsLocationDTM.fromPlain({
                type: itemRouteLeg.arrivalLocation.type,
                id: itemRouteLeg.arrivalLocation.id,
                placeId: itemRouteLeg.arrivalLocation.placeId,
                country: FreightSelectFieldCountryDTM.fromPlain({
                  code: itemRouteLeg.arrivalLocation.country?.code,
                  name: itemRouteLeg.arrivalLocation.country?.name,
                }),
                state: FreightSelectFieldCountryDTM.fromPlain({
                  code: itemRouteLeg.arrivalLocation.state?.code,
                  name: itemRouteLeg.arrivalLocation.state?.name,
                }),
                city: itemRouteLeg.arrivalLocation.city,
                name: itemRouteLeg.arrivalLocation.name,
                address: itemRouteLeg.arrivalLocation.address,
                postalCode: itemRouteLeg.arrivalLocation.postalCode,
                code: itemRouteLeg.arrivalLocation.code,
                timeZoneId: itemRouteLeg.arrivalLocation.timeZoneId,
                coordinates: FreightSelectFieldCoordinatesDTM.fromPlain({
                  lat: `${itemRouteLeg?.arrivalLocation?.coordinates?.lat}` || '',
                  lng: `${itemRouteLeg?.arrivalLocation?.coordinates?.lng}` || '',
                }),
              }),
              departureLocation: QuotaServiceByIdContentRoutesLegsLocationDTM.fromPlain({
                type: itemRouteLeg.departureLocation.type,
                id: itemRouteLeg.departureLocation.id,
                placeId: itemRouteLeg.departureLocation.placeId,
                country: FreightSelectFieldCountryDTM.fromPlain({
                  code: itemRouteLeg.departureLocation.country?.code,
                  name: itemRouteLeg.departureLocation.country?.name,
                }),
                state: FreightSelectFieldCountryDTM.fromPlain({
                  code: itemRouteLeg.departureLocation.state?.code,
                  name: itemRouteLeg.departureLocation.state?.name,
                }),
                city: itemRouteLeg.departureLocation.city,
                name: itemRouteLeg.departureLocation.name,
                address: itemRouteLeg.departureLocation.address,
                postalCode: itemRouteLeg.departureLocation.postalCode,
                code: itemRouteLeg.departureLocation.code,
                timeZoneId: itemRouteLeg.departureLocation.timeZoneId,
                coordinates: FreightSelectFieldCoordinatesDTM.fromPlain({
                  lat: `${itemRouteLeg?.departureLocation?.coordinates?.lat || ''}` || '',
                  lng: `${itemRouteLeg?.departureLocation?.coordinates?.lng || ''}` || '',
                }),
              }),
            })),
            containerId: itemRoute?.containerId,
            containerIds: itemRoute?.containerIds?.map((itemRouteContainerIds) => itemRouteContainerIds) || [],
          })),
          schedules: itemContent.schedules.map((itemContentSchedules) => FreightQuotaContentSchedulesDTM.fromPlain({
            id: itemContentSchedules.id,
            oceanScheduleId: itemContentSchedules.oceanScheduleId,
            recommended: itemContentSchedules.recommended,
            departureTime: itemContentSchedules.departureTime,
            arrivalTime: itemContentSchedules.arrivalTime,
            totalDuration: itemContentSchedules.totalDuration,
            terminalCutOff: itemContentSchedules.terminalCutOff,
            documentCutOff: itemContentSchedules.documentCutOff,
            plans: itemContentSchedules.plans || [],
            hazmatCutOff: itemContentSchedules.hazmatCutOff,
            charges: itemContentSchedules.charges.map((itemContentSchedulesCharge) => FreightQuotaContentSchedulesChargesDTM.fromPlain({
              unitType: itemContentSchedulesCharge.unitType,
              subjectTo: itemContentSchedulesCharge.subjectTo,
              currency: itemContentSchedulesCharge.currency,
              costPerUnit: itemContentSchedulesCharge.costPerUnit,
              numberOfUnits: itemContentSchedulesCharge.numberOfUnits,
              totalCost: itemContentSchedulesCharge.totalCost,
              containerId: itemContentSchedulesCharge.containerId,
              contractId: itemContentSchedulesCharge.contractId,
              transportationIds: itemContentSchedulesCharge.transportationIds.map((itemContentSchedulesChargeTransportationIds) => itemContentSchedulesChargeTransportationIds),
              applied: itemContentSchedulesCharge.applied,
              applianceRange: itemContentSchedulesCharge.applianceRange ? FreightQuotaContentSchedulesChargesApplianceRangeDTM.fromPlain(itemContentSchedulesCharge.applianceRange) : undefined,
              designation: itemContentSchedulesCharge.designation,
              measureBy: itemContentSchedulesCharge.measureBy,
              priceBy: itemContentSchedulesCharge.priceBy,
              chargeCode: FreightQuotaContentSchedulesChargesChargeCodeDTM.fromPlain({
                customId: uuidv4(),
                code: itemContentSchedulesCharge.chargeCode.code,
                description: itemContentSchedulesCharge.chargeCode.description,
                originalDescription: itemContentSchedulesCharge?.metadata?.originalDescription,
                mode: itemContentSchedulesCharge.chargeCode.mode,
                occurrence: itemContentSchedulesCharge.chargeCode.occurrence,
                type: itemContentSchedulesCharge.chargeCode.type,
                subType: itemContentSchedulesCharge.chargeCode.subType || '',
              }),
              metadata: {
                excludedCommodities: itemContentSchedulesCharge?.metadata?.excludedCommodities,
              },
            })),
            transportations: itemContentSchedules.transportations.map((itemContentSchedulesTransportations) => FreightQuotaTransportationDTM.fromPlain({
              id: itemContentSchedulesTransportations.id,
              transport: FreightQuotaTransportationTransportDTM.fromPlain({
                number: itemContentSchedulesTransportations.transport.number,
                name: itemContentSchedulesTransportations.transport.name,
                type: itemContentSchedulesTransportations.transport.type,
              }),
              schedule: FreightQuotaTransportationScheduleDTM.fromPlain({
                type: itemContentSchedulesTransportations.schedule.type,
                departureTime: itemContentSchedulesTransportations.schedule.departureTime,
                arrivalTime: itemContentSchedulesTransportations.schedule.arrivalTime,
                transitDuration: itemContentSchedulesTransportations.schedule.transitDuration,
                terminalCutOff: itemContentSchedulesTransportations.schedule.terminalCutOff,
                documentCutOff: itemContentSchedulesTransportations.schedule.documentCutOff,
                dropTime: itemContentSchedulesTransportations.schedule.dropTime,
                pickupTime: itemContentSchedulesTransportations.schedule.pickupTime,
                hazmatCutOff: itemContentSchedulesTransportations.schedule.hazmatCutOff,
              }),
              arrivalTerminal: itemContentSchedulesTransportations?.arrivalTerminal?.name,
              departureTerminal: itemContentSchedulesTransportations?.departureTerminal?.name,
              containers: itemContentSchedulesTransportations.containers.map((itemContentSchedulesTransportationsContainer) => itemContentSchedulesTransportationsContainer),
              transportLeg: itemContentSchedulesTransportations.transportLeg,
              voyageCode: itemContentSchedulesTransportations.voyageCode,
            })),
          })),
          contracts: itemContent.contracts.map((itemContentContract) => FreightQuotaContentContractsDTM.fromPlain({
            id: itemContentContract.id,
            number: itemContentContract.number,
            name: itemContentContract.name,
            scac: itemContentContract.scac,
          })),
          containers: itemContent.containers.map((itemContentContainer) => FreightQuotaContentContainerDTM.fromPlain({
            id: itemContentContainer.id,
            type: itemContentContainer.type,
            ownContainer: itemContentContainer.ownContainer,
            rateId: itemContentContainer.rateId,
            weight: itemContentContainer.weight,
            volume: itemContentContainer.volume,
            commodities: itemContentContainer.commodities.map((itemContentContainerCommodity) => FreightQuotaContentCommodity.fromPlain({
              code: itemContentContainerCommodity.code,
              description: itemContentContainerCommodity.description,
              imoClass: itemContentContainerCommodity.imoClass,
              unNumber: itemContentContainerCommodity.unNumber,
              value: itemContentContainerCommodity.value,
            })),
          })),
          customer: FreightQuotaContentCustomerDTM.fromPlain({
            companyId: itemContent?.customer?.companyId,
            contactId: itemContent?.customer?.contactId,
            addressId: itemContent?.customer?.addressId,
            organizationId: itemContent?.customer?.organizationId,
          }),
          bookmark: QuotaBookmark.fromPlain({
            id: itemContent?.bookmark?.id,
            name: itemContent?.bookmark?.name,
            createdAt: itemContent?.bookmark?.createdAt,
            createdBy: itemContent?.bookmark?.createdBy,
          }),
          validPeriod: FreightQuotaContentValidPeriodDTM.fromPlain({
            from: itemContent?.validPeriod?.from ? DateDtm.fromPlain({
              date: itemContent?.validPeriod?.from,
              offset: itemContent?.validPeriod?.from ? moment.parseZone(itemContent.validPeriod.from).utcOffset() : 0,
            }) : undefined,
            to: itemContent?.validPeriod?.to ? DateDtm.fromPlain({
              date: itemContent?.validPeriod?.to,
              offset: itemContent?.validPeriod?.to ? moment.parseZone(itemContent.validPeriod.to).utcOffset() : 0,
            }) : undefined,
          }),
        });

        const errors = newItem.validate();

        if (errors.length) {
          console.error('DTM valid RFQ: error', errors);
        }

        return newItem;
      });

      const list = content.filter((el) => el !== null) as FreightQuotaContentDTM[];

      const pegeable: FreightQuotaPageableDTM = FreightQuotaPageableDTM.fromPlain({
        sort: {
          empty: response.pageable.sort.empty,
          sorted: response.pageable.sort.sorted,
          unsorted: response.pageable.sort.unsorted,
        },
        offset: response.pageable.offset,
        pageNumber: response.pageable.pageNumber,
        pageSize: response.pageable.pageSize,
        paged: response.pageable.paged,
        unpaged: response.pageable.unpaged,
      });

      result = RFQQuotasDTM.fromPlain({
        content: list,
        pageable: pegeable,
        last: response.last,
        totalPages: response.totalPages,
        totalElements: response.totalElements,
        size: response.size,
        number: response.number,
        sort: {
          empty: response.sort.empty,
          sorted: response.sort.sorted,
          unsorted: response.sort.unsorted,
        },
        first: response.first,
        numberOfElements: response.numberOfElements,
        empty: response.empty,
      });

      list?.forEach((item) => {
        const errors = item.validate();

        if (errors.length) {
          console.error('DTM valid RFQ: error', errors);
        }
      });
    }

    const errors = result?.validate();

    if (errors?.length) {
      console.error('DTM valid RFQ: error', errors);
    }

    return result;
  }

  public getRFQRequestById = async (requestId: string) => {
    let result: FreightQuotaContentDTM | null = null;

    const responseRaw = await apiWorker.requestGet<IGetRFQRequestByIdResponse>(`${this.base}/${requestId}`);

    const response = responseRaw.data;
    result = FreightQuotaContentDTM.fromPlain({
      incoterm: response.incoterm,
      id: response.id,
      loadType: response.loadType,
      freightMode: response.freightMode,
      tradeType: response.tradeType,
      isSelfServiceRequest: response.isSelfServiceRequest,
      includeRelatedPorts: response.includeRelatedPorts,
      customer: {
        addressId: response.customer?.addressId,
        companyId: response.customer?.companyId,
        contactId: response.customer?.contactId,
        organizationId: response.customer?.organizationId,
        type: response.customer?.type,
      },
      containerRequests: response.containerRequests.map((item) => ({
        origin: {
          type: item.origin.type,
          location: {
            type: item.origin.location.type,
            id: item.origin.location.id,
            country: FreightSelectFieldCountryDTM.fromPlain({
              code: item.origin.location.country?.code,
              name: item.origin.location.country?.name,
            }),
            state: FreightSelectFieldCountryDTM.fromPlain({
              code: item.origin.location.state?.code,
              name: item.origin.location.state?.name,
            }),
            coordinates: FreightSelectFieldCoordinatesDTM.fromPlain({
              lat: `${item.origin?.location?.coordinates?.lat || ''}`,
              lng: `${item.origin?.location?.coordinates?.lng || ''}`,
            }),
            code: item.origin.location.code,
            name: item.origin.location.name,
            subdivisionName: item.origin?.location?.subdivisionName,
            timeZoneId: item.origin.location.timeZoneId,
          },
          earliestDate: item.origin.earliestDate || undefined,
          latestDate: item.origin.latestDate || undefined,
        },
        destination: {
          type: item.destination.type,
          location: {
            type: item.destination.location.type,
            id: item.destination.location.id,
            country: FreightSelectFieldCountryDTM.fromPlain({
              code: item.destination.location.country?.code,
              name: item.destination.location.country?.name,
            }),
            state: FreightSelectFieldCountryDTM.fromPlain({
              code: item.destination.location.state?.code,
              name: item.destination.location.state?.name,
            }),
            coordinates: FreightSelectFieldCoordinatesDTM.fromPlain({
              lat: `${item.destination?.location?.coordinates?.lat || ''}`,
              lng: `${item.destination?.location?.coordinates?.lng || ''}`,
            }),
            code: item.destination.location.code,
            name: item.destination.location.name,
            subdivisionName: item.destination?.location?.subdivisionName,
            timeZoneId: item.destination.location.timeZoneId,
          },
          earliestDate: item.destination.earliestDate || undefined,
          latestDate: item.destination.latestDate || undefined,
        },
        container: {
          id: item.container.id,
          rateId: item.container.rateId,
          type: item.container.type,
          volume: item.container.volume,
          weight: item.container.weight,
          ownContainer: item.container.ownContainer,
          commodities: item.container.commodities.map((itemCommodity) => ({
            values: [{
              hsCode: {
                code: itemCommodity.code || undefined,
                description: itemCommodity.description || undefined,
                timezoneId: item.origin.location.timeZoneId,
                id: uuidv4(),
              },
              description: itemCommodity.description || undefined,
              IMOClass: itemCommodity.imoClass || undefined,
              UNNumber: itemCommodity.unNumber || undefined,
              goodsValue: itemCommodity.value || undefined,
            }],
            code: itemCommodity.code || undefined,
          })),
        },
      })),
      additionalServices: response.additionalServices?.map((item) => AdditionalServiceCheckedDTM.fromPlain({
        code: item.code,
        phase: item.phase,
        quantity: item.quantity,
      })),
      bookmark: QuotaBookmark.fromPlain({
        id: response.bookmark?.id,
        name: response.bookmark?.name,
        createdAt: response.bookmark?.createdAt,
        createdBy: response.bookmark?.createdBy,
      }),
    });

    return result;
  }

  public getRFQDoorAutocomplete = async (value: string) => {
    let status: string = '';
    let response: google.maps.places.AutocompletePrediction[]
      | null = null;
    let result: FreightDoorsAutocompleteDTM[] | null = null;

    try {
      const auto = new window.google.maps.places.AutocompleteService();

      response = await new Promise((resolve, reject) => {
        auto.getPlacePredictions({
          input: value,
          componentRestrictions: { country: 'us' },
          types: [
            'premise',
            'street_address',
          ],
        }, (responseGoogle, statusGoogle) => {
          if (status === window.google.maps.places.PlacesServiceStatus.UNKNOWN_ERROR) {
            reject(status);
          }

          status = statusGoogle;
          resolve(responseGoogle);
        });
      });
    } catch (e) {
      console.error('FETCH RFQ: getRFQDoorAutocomplete', e);

      throw e;
    }

    if (response && status === window.google.maps.places.PlacesServiceStatus.OK) {
      result = response?.map((item) => FreightDoorsAutocompleteDTM.fromPlain({
        description: item.description,
        placeId: item.place_id,
        id: item.id,
      })) || [];
    }

    result?.forEach((item) => {
      const errors = item.validate();

      if (errors.length) {
        console.error('DTM valid RFQ: error', errors);
      }
    });

    return result;
  }

  public getSessionRFQDoorAutocomplete = (value: string) => {
    const storageStore = window.sessionStorage.getItem(this.googleAutocompleteSessionStorage);
    const storage: ISessionRFQDoorAutocomplete | null = storageStore ? JSON.parse(storageStore) : null;

    if (storage?.[value]) {
      if (storage[value].maxAge > Date.now()) {
        return storage[value].result;
      }

      throw new Error('Value is expired');
    }

    return null;
  }

  public setSessionRFQDoorAutocomplete = (newValue: string, newResult: IGetRFQDoorAutocompleteResponse[]) => {
    const prevStorageStore = window.sessionStorage.getItem(this.googleAutocompleteSessionStorage);
    const prevStorage: ISessionRFQDoorAutocomplete | null = prevStorageStore ? JSON.parse(prevStorageStore) : null;

    let newStorage: ISessionRFQDoorAutocomplete = {};

    if (prevStorage) {
      newStorage = {
        ...prevStorage,
        [newValue]: {
          result: newResult,
          maxAge: Date.now() + 24 * 60 * 60 * 1000,
        },
      };
    } else {
      newStorage = {
        [newValue]: {
          result: newResult,
          maxAge: Date.now() + 24 * 60 * 60 * 1000,
        },
      };
    }

    window.sessionStorage.setItem(this.googleAutocompleteSessionStorage, JSON.stringify(newStorage));
  }

  public deleteSessionRFQDoorAutocomplete = (value: string) => {
    const prevStorageStore = window.sessionStorage.getItem(this.googleAutocompleteSessionStorage);
    const prevStorage: ISessionRFQDoorAutocomplete | null = prevStorageStore ? JSON.parse(prevStorageStore) : null;

    let newStorage: ISessionRFQDoorAutocomplete = {};

    if (prevStorage) {
      newStorage = {
        ...prevStorage,
      };
    }

    if (prevStorage?.[value]) {
      newStorage = {
        ...prevStorage,
      };

      delete newStorage[value];
    }

    window.sessionStorage.setItem(this.googleAutocompleteSessionStorage, JSON.stringify(newStorage));
  }

  public getRFQServiceFilters = async (serviceId: number, params?: IQuotaServiceFiltersParamsDTM) => {
    let result: FreightFiltersDTM | null = null;
    let response: IGetRFQServiceFiltersResponse | null = null;
    const {
      includeRelatedPorts,
    } = params || {};

    try {
      const responseRaw = await apiWorker.requestGet<IGetRFQServiceFiltersResponse>(this.formatQuotasUrl({
        urlId: `${serviceId}`,
        url: `${this.base}`,
        afterUrlIdPrefix: 'filters',
      }), {
        params: {
          includeRelatedPorts,
        },
      });

      response = responseRaw.data;
    } catch (e) {
      throw new Error('getRFQServiceFilters');
    }

    if (response) {
      const group = response.carriers?.map((itemCarrier) => AppFiltersCheckboxGroupDTM.fromPlain({
        name: itemCarrier,
        value: itemCarrier,
        checked: false,
      })) || [];

      result = FreightFiltersDTM.fromPlain({
        filterPrice: AppFiltersMinMaxDTM.fromPlain({
          filterMin: '',
          filterMax: '',
          lastFilterMin: '',
          lastFilterMax: '',
          filterMinDefault: response.minTotalCost || 0,
          filterMaxDefault: response.maxTotalCost || 0,
          limitMin: response.minTotalCost || 0,
          limitMax: response.maxTotalCost || 0,
          disableReset: true,
          isUpdate: false,
        }),
        filterTransitTime: AppFiltersMinMaxDTM.fromPlain({
          filterMin: '',
          filterMax: '',
          lastFilterMin: '',
          lastFilterMax: '',
          filterMinDefault: response.minTotalTransitTime || 0,
          filterMaxDefault: response.maxTotalTransitTime || 0,
          limitMin: response.minTotalTransitTime || 0,
          limitMax: response.maxTotalTransitTime || 0,
          disableReset: true,
          isUpdate: false,
        }),
        filterCarrier: AppFiltersCheckboxDTM.fromPlain({
          group: [...group],
          lastGroup: [...group],
          groupDefault: [...group],
          disableReset: true,
          isUpdate: false,
        }),
      });
    }

    return result;
  }

  public getRFQCompanyNames = async () => {
    let list: CompanyDTM[] = [];

    const rawResponse = await apiWorker.requestGet<TGetCompanyListResponse>(`${this.urlCompany}?size=1000`);
    const response = rawResponse.data;

    if (!response.content.length) {
      return list;
    }

    list = response.content.map((item) => {
      const newShipmentPreviewDtm: CompanyDTM = CompanyDTM.fromPlain({
        id: item.id,
        name: item.name,
        phone: item.phone,
        phone2: item.phone,
        email: item.email,
        usci: item.usci,
        taxId: item.taxId,
      });

      return newShipmentPreviewDtm;
    });

    return list;
  }

  public getRFQOrganizationNames = async () => {
    let list: CompanyForViewDTM[] = [];

    const rawResponse = await apiWorker.requestGet<GetOrganizationsContract>(`${this.urlOrganization}?statuses=ACTIVE,WAITING_APPROVAL&page=0&size=1000`);
    const response = rawResponse.data.content;

    if (!response?.length) {
      return list;
    }

    list = response.map((item) => CompanyForViewDTM.fromPlain({
      id: Number(item.id),
      name: item?.name,
    }));

    return list;
  }

  public getRFQOrganizationNamesById = async (organizationId: string) => {
    let company: CompanyForViewDTM | undefined;
    if (!organizationId) {
      return company;
    }

    const rawResponse = await apiWorker.requestGet<GetOrganizationByIdContract>(`${this.urlOrganization}/${organizationId}?statuses=ACTIVE,WAITING_APPROVAL`);
    const response = rawResponse.data;

    if (!response) {
      return company;
    }

    company = CompanyForViewDTM.fromPlain({
      id: response.id,
      name: response.name,
    });

    return company;
  }

  public getBookingId = async (params: IGetBookingIdRequest) => {
    let bookingId: number | null = null;
    let response: IGetBookingIdResponse | null = null;

    const rawResponse = await apiWorker.requestPost(`${this.shipmentServiceUrl}`, params);

    response = rawResponse.data;

    if (response?.id) {
      bookingId = response.id;
    }

    return bookingId;
  }

  public getQuotasMakeCheckStatus = async (urlId: string | number) => {
    let quotasStatus: string | null = null;
    let response: IGetQuotasMakeResponse | null = null;

    const rawResponse = await apiWorker.requestGet<IGetQuotasMakeResponse>(`${this.base}/${urlId}`);

    response = rawResponse.data;

    if (response?.status) {
      quotasStatus = response.status;
    }

    return quotasStatus;
  }

  public getAllQuotas = async (params: IGetAllQuotasParamsDTM) => {
    const { page = 0, size = 6, filters } = params;
    let allQuotas: RFQQuotasDTM | null = null;
    let response: IGetAllQuotasRequestResponse | null = null;

    const rawResponse = await apiWorker.requestGet<IGetAllQuotasRequestResponse>(`${this.base}?page=${page}&size=${size}&sort=createdAt,desc&onlyFavorites=${filters?.isFavorite || false}`);

    response = rawResponse.data;

    if (response) {
      const content: FreightQuotaContentDTM[] = response.content.map((itemContent) => FreightQuotaContentDTM.fromPlain({
        id: itemContent.id,
        freightMode: itemContent.freightMode,
        createdBy: String(itemContent.createdBy),
        createdAt: itemContent.createdAt,
        loadType: itemContent.loadType,
        tradeType: itemContent.tradeType,
        incoterm: itemContent.incoterm,
        cost: FreightQuotaContentCostDTM.fromPlain({
          totalCost: itemContent?.cost?.totalCost,
          originTotalCost: itemContent?.cost?.originTotalCost,
          freightTotalCost: itemContent?.cost?.freightTotalCost,
          destinationTotalCost: itemContent?.cost?.destinationTotalCost,
        }),
        routes: itemContent?.routes?.map((itemRoute) => FreightQuotaContentRoutesDTM.fromPlain({
          origin: this.getLocation(QuotaServiceByIdContentRoutesLegsLocationDTM.fromPlain({
            id: itemRoute.origin.id,
            type: itemRoute.origin.type,
            address: itemRoute.origin.address,
            city: itemRoute.origin.city,
            code: itemRoute.origin.code,
            coordinates: FreightSelectFieldCoordinatesDTM.fromPlain({
              lat: `${itemRoute?.origin?.coordinates?.lat || ''}`,
              lng: `${itemRoute?.origin?.coordinates?.lng || ''}`,
            }),
            country: FreightSelectFieldCountryDTM.fromPlain({
              code: itemRoute.origin.country?.code,
              name: itemRoute.origin.country?.name,
            }),
            name: itemRoute.origin.name,
            placeId: itemRoute.origin.placeId,
            postalCode: itemRoute.origin.postalCode,
            state: FreightSelectFieldCountryDTM.fromPlain({
              code: itemRoute.origin.state?.code,
              name: itemRoute.origin.state?.name,
            }),
            timeZoneId: itemRoute.origin.timeZoneId,
          })),
          destination: this.getLocation(QuotaServiceByIdContentRoutesLegsLocationDTM.fromPlain({
            id: itemRoute.destination.id,
            type: itemRoute.destination.type,
            address: itemRoute.destination.address,
            city: itemRoute.destination.city,
            code: itemRoute.destination.code,
            coordinates: FreightSelectFieldCoordinatesDTM.fromPlain({
              lat: `${itemRoute?.destination?.coordinates?.lat || ''}`,
              lng: `${itemRoute?.destination?.coordinates?.lng || ''}`,
            }),
            country: FreightSelectFieldCountryDTM.fromPlain({
              code: itemRoute.destination.country?.code,
              name: itemRoute.destination.country?.name,
            }),
            name: itemRoute.destination.name,
            placeId: itemRoute.destination.placeId,
            postalCode: itemRoute.destination.postalCode,
            state: FreightSelectFieldCountryDTM.fromPlain({
              code: itemRoute.destination.state?.code,
              name: itemRoute.destination.state?.name,
            }),
            timeZoneId: itemRoute.destination.timeZoneId,
          })),
          legs: itemRoute.legs.map((itemRouteLeg) => FreightQuotaContentRouteLegDTM.fromPlain({
            id: itemRouteLeg.id,
            phase: itemRouteLeg.phase,
            arrivalLocation: QuotaServiceByIdContentRoutesLegsLocationDTM.fromPlain({
              type: itemRouteLeg.arrivalLocation.type,
              id: itemRouteLeg.arrivalLocation.id,
              placeId: itemRouteLeg.arrivalLocation.placeId,
              country: FreightSelectFieldCountryDTM.fromPlain({
                code: itemRouteLeg.arrivalLocation.country?.code,
                name: itemRouteLeg.arrivalLocation.country?.name,
              }),
              state: FreightSelectFieldCountryDTM.fromPlain({
                code: itemRouteLeg.arrivalLocation.state?.code,
                name: itemRouteLeg.arrivalLocation.state?.name,
              }),
              city: itemRouteLeg.arrivalLocation.city,
              name: itemRouteLeg.arrivalLocation.name,
              address: itemRouteLeg.arrivalLocation.address,
              postalCode: itemRouteLeg.arrivalLocation.postalCode,
              code: itemRouteLeg.arrivalLocation.code,
              timeZoneId: itemRouteLeg.arrivalLocation.timeZoneId,
              coordinates: FreightSelectFieldCoordinatesDTM.fromPlain({
                lat: `${itemRouteLeg?.arrivalLocation?.coordinates?.lat || ''}` || '',
                lng: `${itemRouteLeg?.arrivalLocation?.coordinates?.lng || ''}` || '',
              }),
            }),
            departureLocation: QuotaServiceByIdContentRoutesLegsLocationDTM.fromPlain({
              type: itemRouteLeg.departureLocation.type,
              id: itemRouteLeg.departureLocation.id,
              placeId: itemRouteLeg.departureLocation.placeId,
              country: FreightSelectFieldCountryDTM.fromPlain({
                code: itemRouteLeg.departureLocation.country?.code,
                name: itemRouteLeg.departureLocation.country?.name,
              }),
              state: FreightSelectFieldCountryDTM.fromPlain({
                code: itemRouteLeg.departureLocation.state?.code,
                name: itemRouteLeg.departureLocation.state?.name,
              }),
              city: itemRouteLeg.departureLocation.city,
              name: itemRouteLeg.departureLocation.name,
              address: itemRouteLeg.departureLocation.address,
              postalCode: itemRouteLeg.departureLocation.postalCode,
              code: itemRouteLeg.departureLocation.code,
              timeZoneId: itemRouteLeg.departureLocation.timeZoneId,
              coordinates: FreightSelectFieldCoordinatesDTM.fromPlain({
                lat: `${itemRouteLeg?.departureLocation?.coordinates?.lat || ''}`,
                lng: `${itemRouteLeg?.departureLocation?.coordinates?.lng || ''}`,
              }),
            }),
          })),
          containerId: itemRoute.containerId,
          containerIds: itemRoute?.containerIds?.map((itemRouteContainerIds) => itemRouteContainerIds) || [],
        })),
        schedules: itemContent?.schedules?.map((itemContentSchedules) => FreightQuotaContentSchedulesDTM.fromPlain({
          id: itemContentSchedules.id,
          oceanScheduleId: itemContentSchedules.oceanScheduleId,
          recommended: itemContentSchedules.recommended,
          departureTime: itemContentSchedules.departureTime,
          arrivalTime: itemContentSchedules.arrivalTime,
          totalDuration: itemContentSchedules.totalDuration,
          terminalCutOff: itemContentSchedules.terminalCutOff,
          documentCutOff: itemContentSchedules.documentCutOff,
          hazmatCutOff: itemContentSchedules.hazmatCutOff,
          plans: [],
          charges: itemContentSchedules.charges.map((itemContentSchedulesCharge) => FreightQuotaContentSchedulesChargesDTM.fromPlain({
            unitType: itemContentSchedulesCharge.unitType,
            subjectTo: itemContentSchedulesCharge.subjectTo,
            currency: itemContentSchedulesCharge.currency,
            costPerUnit: itemContentSchedulesCharge.costPerUnit,
            numberOfUnits: itemContentSchedulesCharge.numberOfUnits,
            totalCost: itemContentSchedulesCharge.totalCost,
            containerId: itemContentSchedulesCharge.containerId,
            contractId: itemContentSchedulesCharge.contractId,
            transportationIds: itemContentSchedulesCharge.transportationIds.map((itemContentSchedulesChargeTransportationIds) => itemContentSchedulesChargeTransportationIds),
            applied: itemContentSchedulesCharge.applied,
            applianceRange: itemContentSchedulesCharge.applianceRange ? FreightQuotaContentSchedulesChargesApplianceRangeDTM.fromPlain({
              minValue: itemContentSchedulesCharge.applianceRange?.minValue,
              maxValue: itemContentSchedulesCharge.applianceRange?.maxValue,
            }) : undefined,
            measureBy: itemContentSchedulesCharge.measureBy,
            designation: itemContentSchedulesCharge.designation,
            priceBy: itemContentSchedulesCharge.priceBy,
            chargeCode: FreightQuotaContentSchedulesChargesChargeCodeDTM.fromPlain({
              code: itemContentSchedulesCharge.chargeCode.code,
              description: itemContentSchedulesCharge.chargeCode.description,
              mode: itemContentSchedulesCharge.chargeCode.mode,
              occurrence: itemContentSchedulesCharge.chargeCode.occurrence,
              type: itemContentSchedulesCharge.chargeCode.type,
              subType: itemContentSchedulesCharge.chargeCode.subType || '',
            }),
          })),
          transportations: itemContentSchedules.transportations.map((itemContentSchedulesTransportations) => FreightQuotaTransportationDTM.fromPlain({
            id: itemContentSchedulesTransportations.id,
            transport: FreightQuotaTransportationTransportDTM.fromPlain({
              number: itemContentSchedulesTransportations.transport.number,
              name: itemContentSchedulesTransportations.transport.name,
              type: itemContentSchedulesTransportations.transport.type,
            }),
            schedule: FreightQuotaTransportationScheduleDTM.fromPlain({
              type: itemContentSchedulesTransportations.schedule.type,
              departureTime: itemContentSchedulesTransportations.schedule.departureTime,
              arrivalTime: itemContentSchedulesTransportations.schedule.arrivalTime,
              transitDuration: itemContentSchedulesTransportations.schedule.transitDuration,
              terminalCutOff: itemContentSchedulesTransportations.schedule.terminalCutOff,
              documentCutOff: itemContentSchedulesTransportations.schedule.documentCutOff,
              dropTime: itemContentSchedulesTransportations.schedule.dropTime,
              pickupTime: itemContentSchedulesTransportations.schedule.pickupTime,
              hazmatCutOff: itemContentSchedulesTransportations.schedule.hazmatCutOff,
            }),
            arrivalTerminal: itemContentSchedulesTransportations?.arrivalTerminal?.name,
            departureTerminal: itemContentSchedulesTransportations?.departureTerminal?.name,
            containers: itemContentSchedulesTransportations.containers.map((itemContentSchedulesTransportationsContainer) => itemContentSchedulesTransportationsContainer),
            transportLeg: itemContentSchedulesTransportations.transportLeg,
            voyageCode: itemContentSchedulesTransportations.voyageCode,
          })),
        })),
        contracts: itemContent?.contracts?.map((itemContentContract) => FreightQuotaContentContractsDTM.fromPlain({
          id: itemContentContract.id,
          number: itemContentContract.number,
          name: itemContentContract.name,
          scac: itemContentContract.scac,
        })),
        containers: itemContent?.containers?.map((itemContentContainer) => FreightQuotaContentContainerDTM.fromPlain({
          id: itemContentContainer.id,
          type: itemContentContainer.type,
          ownContainer: itemContentContainer.ownContainer,
          rateId: itemContentContainer.rateId,
          weight: itemContentContainer.weight,
          volume: itemContentContainer.volume,
          commodities: itemContentContainer.commodities.map((itemContentContainerCommodity) => FreightQuotaContentCommodity.fromPlain({
            code: itemContentContainerCommodity.code,
            description: itemContentContainerCommodity.description,
            imoClass: itemContentContainerCommodity.imoClass,
            unNumber: itemContentContainerCommodity.unNumber,
            value: itemContentContainerCommodity.value,
          })),
        })),
        containerRequests: itemContent?.containerRequests?.map((itemContainerReq) => FreightQuotaContentContainerRequestsDTM.fromPlain({
          origin: FreightQuotaContentContainerRequestsLocationDTM.fromPlain({
            type: itemContainerReq.origin.type,
            location: FreightQuotaContentRoutesLocationDTM.fromPlain({
              type: itemContainerReq.origin.location.type,
              id: itemContainerReq.origin.location.id,
              country: FreightSelectFieldCountryDTM.fromPlain({
                code: itemContainerReq.origin.location.country?.code,
                name: itemContainerReq.origin.location.country?.name,
              }),
              state: FreightSelectFieldCountryDTM.fromPlain({
                code: itemContainerReq.origin.location.state?.code,
                name: itemContainerReq.origin.location.state?.name,
              }),
              coordinates: FreightSelectFieldCoordinatesDTM.fromPlain({
                lat: `${itemContainerReq?.origin?.location?.coordinates?.lat || ''}`,
                lng: `${itemContainerReq?.origin?.location?.coordinates?.lng || ''}`,
              }),
              code: itemContainerReq.origin.location.code,
              name: itemContainerReq.origin.location.name,
              timeZoneId: itemContainerReq.origin.location.timeZoneId,
            }),
            earliestDate: itemContainerReq.origin.earliestDate || undefined,
            latestDate: itemContainerReq.origin.latestDate || undefined,
          }),
          destination: FreightQuotaContentContainerRequestsLocationDTM.fromPlain({
            type: itemContainerReq.destination.type,
            location: FreightQuotaContentRoutesLocationDTM.fromPlain({
              type: itemContainerReq.destination.location.type,
              id: itemContainerReq.destination.location.id,
              country: FreightSelectFieldCountryDTM.fromPlain({
                code: itemContainerReq.destination.location.country?.code,
                name: itemContainerReq.destination.location.country?.name,
              }),
              state: FreightSelectFieldCountryDTM.fromPlain({
                code: itemContainerReq.destination.location.state?.code,
                name: itemContainerReq.destination.location.state?.name,
              }),
              coordinates: FreightSelectFieldCoordinatesDTM.fromPlain({
                lat: `${itemContainerReq?.destination?.location?.coordinates?.lat || ''}`,
                lng: `${itemContainerReq?.destination?.location?.coordinates?.lng || ''}`,
              }),
              code: itemContainerReq.destination.location.code,
              name: itemContainerReq.destination.location.name,
              timeZoneId: itemContainerReq.destination.location.timeZoneId,
            }),
            earliestDate: itemContainerReq.destination.earliestDate || undefined,
            latestDate: itemContainerReq.destination.latestDate || undefined,
          }),
          container: FreightQuotaContentContainerRequestsContainerDTM.fromPlain({
            id: itemContainerReq.container.id,
            type: itemContainerReq.container.type,
            ownContainer: itemContainerReq.container.ownContainer,
            rateId: itemContainerReq.container.rateId,
            commodities: itemContainerReq.container.commodities,
            weight: itemContainerReq.container.weight,
            volume: itemContainerReq.container.volume,
          }),
        })),
        additionalServices: itemContent?.additionalServices?.map((item) => AdditionalServiceCheckedDTM.fromPlain({
          code: item.code,
          phase: item.phase,
        })),
        customer: FreightQuotaContentCustomerDTM.fromPlain({
          addressId: itemContent.customer?.addressId,
          companyId: itemContent.customer?.companyId,
          contactId: itemContent.customer?.contactId,
          organizationId: itemContent.customer?.organizationId,
        }),
        bookmark: QuotaBookmark.fromPlain({
          id: itemContent?.bookmark?.id,
          name: itemContent?.bookmark?.name,
          createdAt: itemContent?.bookmark?.createdAt,
          createdBy: itemContent?.bookmark?.createdBy,
        }),
        validPeriod: FreightQuotaContentValidPeriodDTM.fromPlain({
          from: itemContent?.validPeriod?.from ? DateDtm.fromPlain({
            date: itemContent?.validPeriod?.from,
            offset: moment(itemContent?.validPeriod?.from).utcOffset(),
          }) : undefined,
          to: itemContent?.validPeriod?.to ? DateDtm.fromPlain({
            date: itemContent?.validPeriod?.to,
            offset: moment(itemContent?.validPeriod?.to).utcOffset(),
          }) : undefined,
        }),
        favoriteQuotasCount: itemContent.favoriteQuotasCount,
        quotasCount: itemContent.quotasCount,
      }));

      content?.forEach((item) => {
        const errors = item.validate();

        if (errors.length) {
          console.error('DTM valid RFQ: error', errors);
        }
      });

      const pegeable: FreightQuotaPageableDTM = FreightQuotaPageableDTM.fromPlain({
        sort: {
          empty: response.pageable.sort.empty,
          sorted: response.pageable.sort.sorted,
          unsorted: response.pageable.sort.unsorted,
        },
        offset: response.pageable.offset,
        pageNumber: response.pageable.pageNumber,
        pageSize: response.pageable.pageSize,
        paged: response.pageable.paged,
        unpaged: response.pageable.unpaged,
      });

      allQuotas = RFQQuotasDTM.fromPlain({
        content,
        pageable: pegeable,
        last: response.last,
        totalPages: response.totalPages,
        totalElements: response.totalElements,
        size: response.size,
        number: response.number,
        sort: {
          empty: response.sort.empty,
          sorted: response.sort.sorted,
          unsorted: response.sort.unsorted,
        },
        first: response.first,
        numberOfElements: response.numberOfElements,
        empty: response.empty,
      });
    }

    const errors = allQuotas?.validate();

    if (errors?.length) {
      console.error('DTM valid RFQ: error', errors);
    }

    return allQuotas;
  }

  // new
  public getHsCode = async (params: IGetQueryParamsDTM): Promise<HsCodeDTM[] | null> => {
    const { query, page = 0, size = 5 } = params;
    let hsCodes: HsCodeDTM[] | null = null;
    let response: IGetHsCodeResponse[] | null = null;

    const rawResponse = await apiWorker.requestGet<IGetHsCodeResponse[]>(`${this.commodityHsCode}?query=${encodeURIComponent(query)}&page=${page}&size=${size}`);

    if (rawResponse.status !== 200) {
      throw new Error(' Response not ok');
    }

    response = rawResponse.data;

    if (response?.length) {
      hsCodes = response.map((hsCodeItem) => HsCodeDTM.fromPlain({
        id: uuidv4(),
        code: hsCodeItem.code,
        locationCode: hsCodeItem?.locationCode || undefined,
        locationName: hsCodeItem?.locationName || undefined,
        timeZoneId: hsCodeItem?.timeZoneId || undefined,
        description: hsCodeItem?.description || '',
        forbidden: hsCodeItem?.forbidden,
      }));

      hsCodes?.forEach((item) => {
        const errors = item.validate();

        if (errors.length) {
          console.error('DTM valid RFQ: error', errors);
        }
      });
    }

    return hsCodes;
  }

  public getCommodityItems = async (params: IGetCommodityItemsParams): Promise<HsCodeDTM[] | null> => {
    let hsCodes: HsCodeDTM[] | null = null;
    let response: IGetHsCodeResponse[] | null = null;

    const rawResponse = await apiWorker.requestGet<IGetCommodityItemsResponse[]>(`${this.commodityItems}`, {
      params: {
        ...params,
        codes: params?.codes?.join(','),
      },
    });

    response = rawResponse.data;

    if (response?.length) {
      hsCodes = response.map((hsCodeItem) => HsCodeDTM.fromPlain({
        id: uuidv4(),
        code: hsCodeItem.code,
        locationCode: hsCodeItem?.locationCode || undefined,
        locationName: hsCodeItem?.locationName || undefined,
        timeZoneId: hsCodeItem?.timeZoneId || undefined,
        description: hsCodeItem?.description || '',
      }));

      hsCodes?.forEach((item) => {
        const errors = item.validate();

        if (errors.length) {
          console.error('DTM valid RFQ: error', errors);
        }
      });
    }

    return hsCodes;
  }

  // new
  public postCreateQuota = async (params: postCreateQuotaParamsRequest, getParams?: postCreateQuotaGetParamsRequest) => {
    let result: { id: number } | null = null;
    let response: IGetQuotasMakeResponse | null = null;

    const rawResponse = await apiWorker.requestPost<IGetQuotasMakeResponse>(`${this.base}`, params, {
      params: getParams,
    });

    response = rawResponse.data;

    if (response) {
      result = { id: response.id };
    }

    return result;
  }

  // new
  public getLocationService = async (params: IGetQueryParamsDTM) => {
    const {
      query, page = 1, size = 5, markOutsideRegions,
    } = params;

    let result: LocationsServiceDTM[] | null = null;
    let response: LocationsServiceResponse[] | null = null;

    try {
      const rawResponse = await apiWorker.requestGet<LocationsServiceResponse[]>(`${this.locationService}?query=${encodeURIComponent(query)}${markOutsideRegions ? '&markOutsideRegions=true' : ''}&function=PORT,RAIL_TERMINAL&page=${page}&size=${size}`);

      if (rawResponse.status !== 200) {
        throw new Error(' Response not ok');
      }

      response = rawResponse.data;

      if (response) {
        result = response.map((itemLocation) => LocationsServiceDTM.fromPlain({
          code: itemLocation.code,
          country: itemLocation.country
            ? FreightSelectFieldCountryDTM.fromPlain({
              code: itemLocation.country.code,
              name: itemLocation.country.name,
              forbidden: itemLocation.country.forbidden,
            })
            : undefined,
          subdivisionCode: itemLocation.subdivisionCode || undefined,
          subdivisionName: itemLocation.subdivisionName || undefined,
          subdivisionType: itemLocation.subdivisionType || undefined,
          locationCode: itemLocation.locationCode || undefined,
          locationName: itemLocation.locationName || undefined,
          coordinates: itemLocation.coordinates
            ? FreightSelectFieldCoordinatesDTM.fromPlain({
              lat: `${itemLocation?.coordinates?.lat || ''}`,
              lng: `${itemLocation?.coordinates?.lng || ''}`,
            })
            : undefined,
          iataCode: itemLocation.iataCode || undefined,
          timeZoneId: itemLocation.timeZoneId || undefined,
        }));
      }
    } catch (e) {
      throw new Error('RFQ: getLocationService, list getting error');
    }

    result?.forEach((item) => {
      const errors = item.validate();

      if (errors.length) {
        console.error('DTM valid RFQ: error', errors);
      }
    });

    return result;
  }

  public getCompanyDetails = async (id: number) => {
    let result: CustomerInfoDTM | undefined;

    try {
      const response = await apiWorker.requestGetBySchema(`/contact-service/api/v1/companies/${id}` as '/api/v1/companies/{companyId}');
      const parsedRes = response.data;

      result = CustomerInfoDTM.fromPlain({
        name: parsedRes.name,
        address: parsedRes?.addresses?.[0]?.address1,
        city: parsedRes?.addresses?.[0]?.city,
        country: parsedRes?.addresses?.[0]?.country,
        postalCode: parsedRes?.addresses?.[0]?.postalCode,
        state: parsedRes?.addresses?.[0]?.state,
        email: parsedRes?.contacts?.[0]?.email,
        fullName: parsedRes?.contacts?.[0]?.fullName,
        phone: parsedRes?.contacts?.[0]?.phone,
      });
    } catch (e) {
      throw new Error('Something wrong, please try again');
    }

    const errors = result.validate();

    if (errors.length) {
      console.error('DTM valid RFQ: error', errors);
    }

    return result;
  }

  public getOrganizationDetails = async (id: number) => {
    let result: CustomerInfoDTM | undefined;

    try {
      const response = await apiWorker.requestGet<TGetCurrentOrganizationResponseContract>(`/organization-service/api/v1/organizations/${id}`);
      const parsedRes = response.data;

      result = CustomerInfoDTM.fromPlain({
        name: parsedRes.name,
        address: parsedRes.address.address1,
        city: parsedRes.address.city,
        country: parsedRes.address.country,
        postalCode: parsedRes.address.postalCode,
        state: parsedRes.address.state,
        email: parsedRes.contactInformation.email,
        phone: parsedRes.contactInformation.phone,
      });
    } catch (e) {
      throw new Error('Something wrong, please try again');
    }

    const errors = result.validate();

    if (errors.length) {
      console.error('DTM valid RFQ: error', errors);
    }

    return result;
  }

  public getRateService = async () => {
    let result: AdditionalServiceDTM[] | null = null;
    let response: GetRateServiceResponse[] | null = null;

    const rawResponse = await apiWorker.requestGet<GetRateServiceResponse[]>(`${this.rateService}`);

    response = rawResponse.data;

    if (response) {
      result = response.map((itemLocation) => AdditionalServiceDTM.fromPlain({
        code: itemLocation.code,
        phases: itemLocation.phases,
        description: itemLocation.description,
        mode: itemLocation.mode,
        loadType: itemLocation.loadType,
        type: itemLocation.type,
        subType: itemLocation.subType,
        occurrence: itemLocation.occurrence,
        group: itemLocation.group,
        designation: itemLocation.designation,
        priceBy: itemLocation.priceBy,
        measureBy: itemLocation.measureBy,
        status: itemLocation.status,
        organizationId: itemLocation.organizationId,
        createdBy: itemLocation.createdBy,
        createdAt: itemLocation.createdAt,
        updatedBy: itemLocation.updatedBy || undefined,
        updatedAt: itemLocation.updatedAt || undefined,
      }));
    }

    result?.forEach((item) => {
      const errors = item.validate();

      if (errors.length) {
        console.error('DTM valid RFQ: error', errors, item);
      }
    });

    return result;
  }

  public getAdditionalService = async (params?: IGetAdditionalServiceParamsDTM) => {
    let result: AdditionalRatesServiceDTM[] | undefined;

    const rawResponse = await apiWorker.requestGetBySchema(`${this.additionalService}` as '/api/v1/services/rates', {
      params: {
        ...params,
      },
    });

    const response = rawResponse.data as GetAdditionalServiceResponse[];

    if (response) {
      result = response.map((itemService) => AdditionalRatesServiceDTM.fromPlain({
        id: itemService.id,
        customId: uuidv4(),
        key: itemService.key,
        countDocument: String(1),
        chargeCode: itemService.chargeCode && AdditionalRatesServiceChargeCodeDTM.fromPlain({
          code: itemService.chargeCode.code,
          description: itemService.chargeCode.description,
          mode: itemService.chargeCode.mode,
          loadType: itemService.chargeCode.loadType,
          additionalInformation: itemService.chargeCode.additionalInformation,
          type: itemService.chargeCode.type,
          subType: itemService.chargeCode.subType,
          occurrence: itemService.chargeCode.occurrence,
          phases: itemService.chargeCode.phases,
          group: itemService.chargeCode.group,
          status: itemService.chargeCode.status,
          organizationId: itemService.chargeCode.organizationId,
          createdBy: itemService.chargeCode.createdBy,
          createdAt: itemService.chargeCode.createdAt,
          updatedBy: itemService.chargeCode.updatedBy,
          updatedAt: itemService.chargeCode.updatedAt,
        }),
        applicationLevel: itemService.applicationLevel && AdditionalRatesServiceApplicationLevelDTM.fromPlain({
          id: itemService.applicationLevel.id,
          type: itemService.applicationLevel.type,
        }),
        designation: itemService.designation,
        priceBy: itemService.priceBy,
        measureBy: itemService.measureBy,
        applianceRange: itemService.applianceRange && AdditionalRatesServiceApplicationRangeDTM.fromPlain({
          minValue: itemService.applianceRange.minValue,
          maxValue: itemService.applianceRange.maxValue,
        }),
        validPeriod: itemService.validPeriod && AdditionalRatesServiceValidPeriodDTM.fromPlain({
          from: itemService.validPeriod.from,
          to: itemService.validPeriod.to,
        }),
        subjectTo: itemService.subjectTo,
        currency: itemService.currency,
        status: itemService.status,
        organizationId: itemService.organizationId,
        createdBy: itemService.createdBy,
        createdAt: itemService.createdAt,
        scac: itemService.scac,
        containerType: itemService.containerType,
        buyPrice: itemService.buyPrice,
      }));
    }

    result?.forEach((item) => {
      const errors = item.validate();

      if (errors.length) {
        console.error('DTM valid RFQ: error', errors, item);
      }
    });

    return result;
  }

  public getQuotaById = async (requestId: number, quotaId: number): Promise<FreightQuotaContentDTM | null> => {
    let quotaDTM: FreightQuotaContentDTM | null = null;

    try {
      const response = await apiWorker.requestGet<IQuotaRequestServiceByIdResponseContent>(`${this.base}/${requestId}/quotas/${quotaId}`);

      if (response.data) {
        const itemContent = response.data;

        quotaDTM = FreightQuotaContentDTM.fromPlain({
          id: itemContent.id,
          tradeType: itemContent.tradeType,
          incoterm: itemContent.incoterm,
          cost: FreightQuotaContentCostDTM.fromPlain({
            totalCost: itemContent.cost.totalCost,
            originTotalCost: itemContent.cost.originTotalCost,
            freightTotalCost: itemContent.cost.freightTotalCost,
            destinationTotalCost: itemContent.cost.destinationTotalCost,
          }),
          routes: itemContent.routes.map((itemRoute) => FreightQuotaContentRoutesDTM.fromPlain({
            origin: this.getLocation(QuotaServiceByIdContentRoutesLegsLocationDTM.fromPlain({
              id: itemRoute.origin.id,
              address: itemRoute.origin.address,
              city: itemRoute.origin.city,
              code: itemRoute.origin.code,
              coordinates: FreightSelectFieldCoordinatesDTM.fromPlain({
                lat: `${itemRoute?.origin?.coordinates?.lat || ''}`,
                lng: `${itemRoute?.origin?.coordinates?.lng || ''}`,
              }),
              country: FreightSelectFieldCountryDTM.fromPlain({
                code: itemRoute.origin.country?.code,
                name: itemRoute.origin.country?.name,
              }),
              name: itemRoute.origin.name,
              placeId: itemRoute.origin.placeId,
              postalCode: itemRoute.origin.postalCode,
              state: FreightSelectFieldCountryDTM.fromPlain({
                code: itemRoute.origin.state?.code,
                name: itemRoute.origin.state?.name,
              }),
              timeZoneId: itemRoute.origin.timeZoneId,
              type: itemRoute.origin.type,
            })),
            destination: this.getLocation(QuotaServiceByIdContentRoutesLegsLocationDTM.fromPlain({
              id: itemRoute.destination.id,
              address: itemRoute.destination.address,
              city: itemRoute.destination.city,
              code: itemRoute.destination.code,
              coordinates: FreightSelectFieldCoordinatesDTM.fromPlain({
                lat: `${itemRoute?.destination?.coordinates?.lat || ''}`,
                lng: `${itemRoute?.destination?.coordinates?.lng || ''}`,
              }),
              country: FreightSelectFieldCountryDTM.fromPlain({
                code: itemRoute.destination.country?.code,
                name: itemRoute.destination.country?.name,
              }),
              name: itemRoute.destination.name,
              placeId: itemRoute.destination.placeId,
              postalCode: itemRoute.destination.postalCode,
              state: FreightSelectFieldCountryDTM.fromPlain({
                code: itemRoute.destination.state?.code,
                name: itemRoute.destination.state?.name,
              }),
              timeZoneId: itemRoute.destination.timeZoneId,
              type: itemRoute.destination.type,
            })),
            legs: itemRoute.legs.map((itemRouteLeg) => FreightQuotaContentRouteLegDTM.fromPlain({
              id: itemRouteLeg.id,
              phase: itemRouteLeg.phase,
              arrivalLocation: QuotaServiceByIdContentRoutesLegsLocationDTM.fromPlain({
                type: itemRouteLeg.arrivalLocation.type,
                id: itemRouteLeg.arrivalLocation.id,
                placeId: itemRouteLeg.arrivalLocation.placeId,
                country: FreightSelectFieldCountryDTM.fromPlain({
                  code: itemRouteLeg.arrivalLocation.country?.code,
                  name: itemRouteLeg.arrivalLocation.country?.name,
                }),
                state: FreightSelectFieldCountryDTM.fromPlain({
                  code: itemRouteLeg.arrivalLocation.state?.code,
                  name: itemRouteLeg.arrivalLocation.state?.name,
                }),
                city: itemRouteLeg.arrivalLocation.city,
                name: itemRouteLeg.arrivalLocation.name,
                address: itemRouteLeg.arrivalLocation.address,
                postalCode: itemRouteLeg.arrivalLocation.postalCode,
                code: itemRouteLeg.arrivalLocation.code,
                timeZoneId: itemRouteLeg.arrivalLocation.timeZoneId,
                coordinates: FreightSelectFieldCoordinatesDTM.fromPlain({
                  lat: `${itemRouteLeg?.arrivalLocation?.coordinates?.lat || ''}` || '',
                  lng: `${itemRouteLeg?.arrivalLocation?.coordinates?.lng || ''}` || '',
                }),
              }),
              departureLocation: QuotaServiceByIdContentRoutesLegsLocationDTM.fromPlain({
                type: itemRouteLeg.departureLocation.type,
                id: itemRouteLeg.departureLocation.id,
                placeId: itemRouteLeg.departureLocation.placeId,
                country: FreightSelectFieldCountryDTM.fromPlain({
                  code: itemRouteLeg.departureLocation.country?.code,
                  name: itemRouteLeg.departureLocation.country?.name,
                }),
                state: FreightSelectFieldCountryDTM.fromPlain({
                  code: itemRouteLeg.departureLocation.state?.code,
                  name: itemRouteLeg.departureLocation.state?.name,
                }),
                city: itemRouteLeg.departureLocation.city,
                name: itemRouteLeg.departureLocation.name,
                address: itemRouteLeg.departureLocation.address,
                postalCode: itemRouteLeg.departureLocation.postalCode,
                code: itemRouteLeg.departureLocation.code,
                timeZoneId: itemRouteLeg.departureLocation.timeZoneId,
                coordinates: FreightSelectFieldCoordinatesDTM.fromPlain({
                  lat: `${itemRouteLeg?.departureLocation?.coordinates?.lat || ''}` || '',
                  lng: `${itemRouteLeg?.departureLocation?.coordinates?.lng || ''}` || '',
                }),
              }),
            })),
            containerId: itemRoute.containerId,
            containerIds: itemRoute?.containerIds?.map((itemRouteContainerIds) => itemRouteContainerIds) || [],
          })),
          schedules: itemContent.schedules.map((itemContentSchedules) => FreightQuotaContentSchedulesDTM.fromPlain({
            id: itemContentSchedules.id,
            oceanScheduleId: itemContentSchedules.oceanScheduleId,
            recommended: itemContentSchedules.recommended,
            departureTime: itemContentSchedules.departureTime,
            arrivalTime: itemContentSchedules.arrivalTime,
            totalDuration: itemContentSchedules.totalDuration,
            terminalCutOff: itemContentSchedules.terminalCutOff,
            documentCutOff: itemContentSchedules.documentCutOff,
            plans: itemContentSchedules.plans || [],
            hazmatCutOff: itemContentSchedules.hazmatCutOff,
            charges: itemContentSchedules.charges.map((itemContentSchedulesCharge) => FreightQuotaContentSchedulesChargesDTM.fromPlain({
              unitType: itemContentSchedulesCharge.unitType,
              subjectTo: itemContentSchedulesCharge.subjectTo,
              currency: itemContentSchedulesCharge.currency,
              costPerUnit: itemContentSchedulesCharge.costPerUnit,
              numberOfUnits: itemContentSchedulesCharge.numberOfUnits,
              totalCost: itemContentSchedulesCharge.totalCost,
              containerId: itemContentSchedulesCharge.containerId,
              contractId: itemContentSchedulesCharge.contractId,
              transportationIds: itemContentSchedulesCharge.transportationIds.map((itemContentSchedulesChargeTransportationIds) => itemContentSchedulesChargeTransportationIds),
              applied: itemContentSchedulesCharge.applied,
              applianceRange: itemContentSchedulesCharge.applianceRange ? FreightQuotaContentSchedulesChargesApplianceRangeDTM.fromPlain(itemContentSchedulesCharge.applianceRange) : undefined,
              designation: itemContentSchedulesCharge.designation,
              measureBy: itemContentSchedulesCharge.measureBy,
              priceBy: itemContentSchedulesCharge.priceBy,
              chargeCode: FreightQuotaContentSchedulesChargesChargeCodeDTM.fromPlain({
                code: itemContentSchedulesCharge.chargeCode.code,
                description: itemContentSchedulesCharge.chargeCode.description,
                originalDescription: itemContentSchedulesCharge?.metadata?.originalDescription,
                mode: itemContentSchedulesCharge.chargeCode.mode,
                occurrence: itemContentSchedulesCharge.chargeCode.occurrence,
                type: itemContentSchedulesCharge.chargeCode.type,
                subType: itemContentSchedulesCharge.chargeCode.subType || '',
              }),
              metadata: {
                excludedCommodities: itemContentSchedulesCharge?.metadata?.excludedCommodities,
              },
            })),
            transportations: itemContentSchedules.transportations.map((itemContentSchedulesTransportations) => FreightQuotaTransportationDTM.fromPlain({
              id: itemContentSchedulesTransportations.id,
              transport: FreightQuotaTransportationTransportDTM.fromPlain({
                number: itemContentSchedulesTransportations.transport.number,
                name: itemContentSchedulesTransportations.transport.name,
                type: itemContentSchedulesTransportations.transport.type,
              }),
              schedule: FreightQuotaTransportationScheduleDTM.fromPlain({
                type: itemContentSchedulesTransportations.schedule.type,
                departureTime: itemContentSchedulesTransportations.schedule.departureTime,
                arrivalTime: itemContentSchedulesTransportations.schedule.arrivalTime,
                transitDuration: itemContentSchedulesTransportations.schedule.transitDuration,
                terminalCutOff: itemContentSchedulesTransportations.schedule.terminalCutOff,
                documentCutOff: itemContentSchedulesTransportations.schedule.documentCutOff,
                dropTime: itemContentSchedulesTransportations.schedule.dropTime,
                pickupTime: itemContentSchedulesTransportations.schedule.pickupTime,
                hazmatCutOff: itemContentSchedulesTransportations.schedule.hazmatCutOff,
              }),
              arrivalTerminal: itemContentSchedulesTransportations?.arrivalTerminal?.name,
              departureTerminal: itemContentSchedulesTransportations?.departureTerminal?.name,
              containers: itemContentSchedulesTransportations.containers.map((itemContentSchedulesTransportationsContainer) => itemContentSchedulesTransportationsContainer),
              transportLeg: itemContentSchedulesTransportations.transportLeg,
              voyageCode: itemContentSchedulesTransportations.voyageCode,
            })),
          })),
          contracts: itemContent.contracts.map((itemContentContract) => FreightQuotaContentContractsDTM.fromPlain({
            id: itemContentContract.id,
            number: itemContentContract.number,
            name: itemContentContract.name,
            scac: itemContentContract.scac,
          })),
          containers: itemContent.containers.map((itemContentContainer) => FreightQuotaContentContainerDTM.fromPlain({
            id: itemContentContainer.id,
            type: itemContentContainer.type,
            ownContainer: itemContentContainer.ownContainer,
            rateId: itemContentContainer.rateId,
            weight: itemContentContainer.weight,
            volume: itemContentContainer.volume,
            commodities: itemContentContainer.commodities.map((itemContentContainerCommodity) => FreightQuotaContentCommodity.fromPlain({
              code: itemContentContainerCommodity.code,
              description: itemContentContainerCommodity.description,
              imoClass: itemContentContainerCommodity.imoClass,
              unNumber: itemContentContainerCommodity.unNumber,
              value: itemContentContainerCommodity.value,
            })),
          })),
          customer: FreightQuotaContentCustomerDTM.fromPlain({
            companyId: itemContent?.customer?.companyId,
            contactId: itemContent?.customer?.contactId,
            addressId: itemContent?.customer?.addressId,
            organizationId: itemContent?.customer?.organizationId,
          }),
          bookmark: QuotaBookmark.fromPlain({
            id: itemContent?.bookmark?.id,
            name: itemContent?.bookmark?.name,
            createdAt: itemContent?.bookmark?.createdAt,
            createdBy: itemContent?.bookmark?.createdBy,
          }),
          validPeriod: FreightQuotaContentValidPeriodDTM.fromPlain({
            from: itemContent?.validPeriod?.from ? DateDtm.fromPlain({
              date: itemContent?.validPeriod?.from,
              offset: moment(itemContent?.validPeriod?.from).utcOffset(),
            }) : undefined,
            to: itemContent?.validPeriod?.to ? DateDtm.fromPlain({
              date: itemContent?.validPeriod?.to,
              offset: moment(itemContent?.validPeriod?.to).utcOffset(),
            }) : undefined,
          }),
        });
      }
    } catch (e) {
      throw new Error('RFQServiceById Service ERROR: getQuotaById');
    }

    return quotaDTM;
  }

  public postCreateQuotaBookmarkById = async (props: {
    requestId: string
    quotaId: string
    params: IPostQuotaBookmarkRequest
  }) => {
    const {
      requestId,
      quotaId,
      params,
    } = props;
    let result: QuotaBookmark | null = null;
    let response: IPostQuotaBookmarkResponse | null = null;

    try {
      const rawResponse = await apiWorker.requestPostBySchema(`${this.base}/${requestId || ''}/quotas/${quotaId || ''}/bookmark` as '/api/v1/requests/{requestId}/quotas/{quotaId}/bookmark', params);

      if (rawResponse.status !== 200) {
        throw new Error(' Response not ok');
      }

      response = rawResponse.data;

      if (response) {
        result = QuotaBookmark.fromPlain({
          id: response.id,
          createdAt: response.createdAt,
          createdBy: response.createdBy,
          name: response.name,
        });
      }

      const errors = result?.validate();

      if (errors?.length) {
        console.error('DTM valid RFQ (postCreateQuotaBookmarkById): error', errors, result);

        return null;
      }
    } catch (e) {
      throw new Error('RFQ: postCreateQuotaBookmarkById error');
    }

    return result;
  }

  public deleteCreateQuotaBookmarkById = async (props: {
    requestId: string
    quotaId: string
    bookId: string
  }) => {
    const {
      requestId,
      quotaId,
      bookId,
    } = props;

    try {
      const rawResponse = await apiWorker.requestDelete<IPostQuotaBookmarkResponse>(`${this.base}/${requestId}/quotas/${quotaId || ''}/bookmarks/${bookId || ''}`);

      if (rawResponse.status !== 200) {
        throw new Error(' Response not ok');
      }
    } catch (e) {
      throw new Error('RFQ: postCreateQuotaBookmarkById error');
    }
  }

  public postCreateRequestBookmarkById = async (props: {
    requestId: string
    params: IPostQuotaBookmarkRequest
  }) => {
    const {
      requestId,
      params,
    } = props;
    let result: QuotaBookmark | null = null;
    let response: IPostQuotaBookmarkResponse | null = null;

    try {
      const rawResponse = await apiWorker.requestPost<IPostQuotaBookmarkResponse>(`${this.base}/${requestId || ''}/bookmark`, params);

      if (rawResponse.status !== 200) {
        throw new Error('postCreateRequestBookmarkById: Response not ok');
      }

      response = rawResponse.data;

      if (response) {
        result = QuotaBookmark.fromPlain({
          id: response.id,
          createdAt: response.createdAt,
          createdBy: response.createdBy,
          name: response.name,
        });
      }

      const errors = result?.validate();

      if (errors?.length) {
        console.error('DTM valid RFQ (postCreateRequestBookmarkById): error', errors, result);

        return null;
      }
    } catch (e) {
      if ((e as unknown as ServerErrorAxios)?.response?.data?.message) {
        throw (e as unknown as ServerErrorAxios)?.response?.data?.message;
      }
      if ((e as unknown as ServerErrorAxios)?.response?.data?.details) {
        throw (e as unknown as ServerErrorAxios)?.response?.data?.details[0];
      }

      throw new Error('RFQ: postCreateRequestBookmarkById error');
    }

    return result;
  }

  public getAllRFRQuotas = async (params: IGetAllQuotasParamsDTM) => {
    const { page = 0, size = 6 } = params;
    let allQuotas: RFRQuotasDTM | undefined;

    const rawResponse = await apiWorker.requestGet<IGetAllRFRQuotasResponse>(`${this.baseRateRequests}?page=${page}&size=${size}&sort=createdAt,desc`);

    const response = rawResponse.data;

    if (response) {
      const content: FreightRFRQuotaContentDTM[] = response.content.map((itemContent) => this.formatFreightRFRQuotaContentDTM(itemContent));

      content?.forEach((item) => {
        const errors = item.validate();

        if (errors.length) {
          console.error('DTM valid RFR: error', errors);
        }
      });

      const pegeable: FreightQuotaPageableDTM = FreightQuotaPageableDTM.fromPlain({
        sort: {
          empty: response.pageable.sort.empty,
          sorted: response.pageable.sort.sorted,
          unsorted: response.pageable.sort.unsorted,
        },
        offset: response.pageable.offset,
        pageNumber: response.pageable.pageNumber,
        pageSize: response.pageable.pageSize,
        paged: response.pageable.paged,
        unpaged: response.pageable.unpaged,
      });

      allQuotas = RFRQuotasDTM.fromPlain({
        content,
        pageable: pegeable,
        last: response.last,
        totalPages: response.totalPages,
        totalElements: response.totalElements,
        size: response.size,
        number: response.number,
        sort: {
          empty: response.sort.empty,
          sorted: response.sort.sorted,
          unsorted: response.sort.unsorted,
        },
        first: response.first,
        numberOfElements: response.numberOfElements,
        empty: response.empty,
      });
    }

    const errors = allQuotas?.validate();

    if (errors?.length) {
      console.error('DTM valid RFR: error', errors);
    }

    return allQuotas;
  }

  public getRFRRequestById = async (params: IGetAllQuotasParamsDTM) => {
    const { id } = params;
    let rfrRequest: FreightRFRQuotaContentDTM | undefined;

    const rawResponse = await apiWorker.requestGet<GetRFRRequestByIdResponse>(`${this.baseRateRequests}/${id}`);

    const response = rawResponse.data;

    if (response) {
      rfrRequest = this.formatFreightRFRQuotaContentDTM(response);

      const errors = rfrRequest.validate();

      if (errors.length) {
        console.error('DTM valid getRFRRequestById: error', errors);
      }
    }

    const errors = rfrRequest?.validate();

    if (errors?.length) {
      console.error('DTM valid getRFRRequestById: error', errors);
    }

    return rfrRequest;
  }

  public getCheckRFRSchedules = async (params: GetCheckRFRSchedulesParams) => {
    let allSchedules: FreightRFRQuotaScheduleDTM[] | undefined;
    let response: IGetAllRFRSchedulesResponse[] | undefined;

    try {
      const rawResponse = await apiWorker.requestGet<IGetAllRFRSchedulesResponse[]>(`${this.baseServiceRateRequests}`, {
        params,
      });

      if (rawResponse.status !== 200) {
        throw new Error('getCheckRFRSchedules Response not ok');
      }

      response = rawResponse.data;
    } catch (e) {
      if ((e as unknown as ServerErrorAxios)?.response?.data?.message) {
        throw (e as unknown as ServerErrorAxios)?.response?.data?.message;
      }
      if ((e as unknown as ServerErrorAxios)?.response?.data?.details) {
        throw (e as unknown as ServerErrorAxios)?.response?.data?.details[0];
      }

      throw new Error('RFQ: getCheckRFRSchedules, list getting error');
    }

    if (response) {
      allSchedules = response.map((itemSchedule) => FreightRFRQuotaScheduleDTM.fromPlain({
        id: itemSchedule?.id,
        carrier: {
          code: itemSchedule?.carrier.code,
          name: itemSchedule?.carrier.name,
        },
        documentCutoff: itemSchedule?.documentCutoff,
        hazmatCutoff: itemSchedule?.hazmatCutoff,
        vgmCutoff: itemSchedule?.vgmCutoff,
        ctoAvailable: itemSchedule?.ctoAvailable,
        terminalCutoff: itemSchedule?.terminalCutoff,
        departureTime: itemSchedule?.departureTime,
        arrivalTime: itemSchedule?.arrivalTime,
        totalDuration: itemSchedule?.totalDuration,
        route: FreightRFRQuotaScheduleRouteDTM.fromPlain({
          origin: itemSchedule?.route.origin,
          destination: itemSchedule?.route.destination,
          transportLeg: itemSchedule?.route?.transportLeg?.map((item) => FreightRFRQuotaScheduleRouteTransportLegDTM.fromPlain({
            id: item.id,
            sequence: item.sequence,
            departureTime: item.departureTime,
            arrivalTime: item.arrivalTime,
            transitDuration: item.transitDuration,
            arrivalLocation: item.arrivalLocation,
            departureLocation: item.departureLocation,
            voyage: {
              code: item.voyage.code,
              operator: FreightRFRQuotaScheduleCarrierCodeDTM.fromPlain({
                code: item.voyage.operator.code,
                name: item.voyage?.operator.name,
              }),
              transport: FreightRFRQuotaScheduleRouteTransportLegVoyageTransportDTM.fromPlain({
                number: item.voyage.transport.number,
                name: item.voyage?.transport.name,
                type: item.voyage?.transport.type,
              }),
            },
          })),
        }),
      }));

      allSchedules?.forEach((item) => {
        const errors = item.validate();

        if (errors.length) {
          console.error('DTM valid getCheckRFRSchedules: error', errors);
        }
      });
    }

    return allSchedules;
  }

  public postRateRequest = async (params: PostRateRequestParams) => {
    await apiWorker.requestPost(`${this.baseRateRequests}`, {
      ...params,
    });
  }

  public postRateRequests = async (params: PostRateRequestParams) => {
    let response: IGetQuotaRequestServiceByIdResponse | undefined;
    let result: RFQQuotasDTM | undefined;

    try {
      const rawResponse = await apiWorker.requestPost(`${this.baseRateRequests}`, {
        ...params,
      });

      response = rawResponse.data;

      if (response) {
        const content: Array<FreightQuotaContentDTM | null> = response.content.map((itemContent) => {
          const newItem = FreightQuotaContentDTM.fromPlain({
            id: itemContent.id,
            tradeType: itemContent.tradeType,
            incoterm: itemContent.incoterm,
            cost: FreightQuotaContentCostDTM.fromPlain({
              totalCost: itemContent.cost.totalCost,
              originTotalCost: itemContent.cost.originTotalCost,
              freightTotalCost: itemContent.cost.freightTotalCost,
              destinationTotalCost: itemContent.cost.destinationTotalCost,
            }),
            routes: itemContent.routes.map((itemRoute) => FreightQuotaContentRoutesDTM.fromPlain({
              origin: this.getLocation(QuotaServiceByIdContentRoutesLegsLocationDTM.fromPlain({
                id: itemRoute.origin.id,
                address: itemRoute.origin.address,
                city: itemRoute.origin.city,
                code: itemRoute.origin.code,
                coordinates: FreightSelectFieldCoordinatesDTM.fromPlain({
                  lat: `${itemRoute?.origin?.coordinates?.lat || ''}`,
                  lng: `${itemRoute?.origin?.coordinates?.lng || ''}`,
                }),
                country: FreightSelectFieldCountryDTM.fromPlain({
                  code: itemRoute.origin.country?.code,
                  name: itemRoute.origin.country?.name,
                }),
                name: itemRoute.origin.name,
                placeId: itemRoute.origin.placeId,
                postalCode: itemRoute.origin.postalCode,
                state: FreightSelectFieldCountryDTM.fromPlain({
                  code: itemRoute.origin.state?.code,
                  name: itemRoute.origin.state?.name,
                }),
                timeZoneId: itemRoute.origin.timeZoneId,
                type: itemRoute.origin.type,
              })),
              destination: this.getLocation(QuotaServiceByIdContentRoutesLegsLocationDTM.fromPlain({
                id: itemRoute.destination.id,
                address: itemRoute.destination.address,
                city: itemRoute.destination.city,
                code: itemRoute.destination.code,
                coordinates: FreightSelectFieldCoordinatesDTM.fromPlain({
                  lat: `${itemRoute?.destination?.coordinates?.lat || ''}`,
                  lng: `${itemRoute?.destination?.coordinates?.lng || ''}`,
                }),
                country: FreightSelectFieldCountryDTM.fromPlain({
                  code: itemRoute.destination.country?.code,
                  name: itemRoute.destination.country?.name,
                }),
                name: itemRoute.destination.name,
                placeId: itemRoute.destination.placeId,
                postalCode: itemRoute.destination.postalCode,
                state: FreightSelectFieldCountryDTM.fromPlain({
                  code: itemRoute.destination.state?.code,
                  name: itemRoute.destination.state?.name,
                }),
                timeZoneId: itemRoute.destination.timeZoneId,
                type: itemRoute.destination.type,
              })),
              legs: itemRoute.legs.map((itemRouteLeg) => FreightQuotaContentRouteLegDTM.fromPlain({
                id: itemRouteLeg.id,
                phase: itemRouteLeg.phase,
                arrivalLocation: QuotaServiceByIdContentRoutesLegsLocationDTM.fromPlain({
                  type: itemRouteLeg.arrivalLocation.type,
                  id: itemRouteLeg.arrivalLocation.id,
                  placeId: itemRouteLeg.arrivalLocation.placeId,
                  country: FreightSelectFieldCountryDTM.fromPlain({
                    code: itemRouteLeg.arrivalLocation.country?.code,
                    name: itemRouteLeg.arrivalLocation.country?.name,
                  }),
                  state: FreightSelectFieldCountryDTM.fromPlain({
                    code: itemRouteLeg.arrivalLocation.state?.code,
                    name: itemRouteLeg.arrivalLocation.state?.name,
                  }),
                  city: itemRouteLeg.arrivalLocation.city,
                  name: itemRouteLeg.arrivalLocation.name,
                  address: itemRouteLeg.arrivalLocation.address,
                  postalCode: itemRouteLeg.arrivalLocation.postalCode,
                  code: itemRouteLeg.arrivalLocation.code,
                  timeZoneId: itemRouteLeg.arrivalLocation.timeZoneId,
                  coordinates: FreightSelectFieldCoordinatesDTM.fromPlain({
                    lat: `${itemRouteLeg?.arrivalLocation?.coordinates?.lat || ''}` || '',
                    lng: `${itemRouteLeg?.arrivalLocation?.coordinates?.lng || ''}` || '',
                  }),
                }),
                departureLocation: QuotaServiceByIdContentRoutesLegsLocationDTM.fromPlain({
                  type: itemRouteLeg.departureLocation.type,
                  id: itemRouteLeg.departureLocation.id,
                  placeId: itemRouteLeg.departureLocation.placeId,
                  country: FreightSelectFieldCountryDTM.fromPlain({
                    code: itemRouteLeg.departureLocation.country?.code,
                    name: itemRouteLeg.departureLocation.country?.name,
                  }),
                  state: FreightSelectFieldCountryDTM.fromPlain({
                    code: itemRouteLeg.departureLocation.state?.code,
                    name: itemRouteLeg.departureLocation.state?.name,
                  }),
                  city: itemRouteLeg.departureLocation.city,
                  name: itemRouteLeg.departureLocation.name,
                  address: itemRouteLeg.departureLocation.address,
                  postalCode: itemRouteLeg.departureLocation.postalCode,
                  code: itemRouteLeg.departureLocation.code,
                  timeZoneId: itemRouteLeg.departureLocation.timeZoneId,
                  coordinates: FreightSelectFieldCoordinatesDTM.fromPlain({
                    lat: `${itemRouteLeg?.departureLocation?.coordinates?.lat || ''}` || '',
                    lng: `${itemRouteLeg?.departureLocation?.coordinates?.lng || ''}` || '',
                  }),
                }),
              })),
              containerId: itemRoute.containerId,
              containerIds: itemRoute?.containerIds?.map((itemRouteContainerIds) => itemRouteContainerIds) || [],
            })),
            schedules: itemContent.schedules.map((itemContentSchedules) => FreightQuotaContentSchedulesDTM.fromPlain({
              id: itemContentSchedules.id,
              oceanScheduleId: itemContentSchedules.oceanScheduleId,
              recommended: itemContentSchedules.recommended,
              departureTime: itemContentSchedules.departureTime,
              arrivalTime: itemContentSchedules.arrivalTime,
              totalDuration: itemContentSchedules.totalDuration,
              terminalCutOff: itemContentSchedules.terminalCutOff,
              documentCutOff: itemContentSchedules.documentCutOff,
              plans: itemContentSchedules.plans || [],
              hazmatCutOff: itemContentSchedules.hazmatCutOff,
              charges: itemContentSchedules.charges.map((itemContentSchedulesCharge) => FreightQuotaContentSchedulesChargesDTM.fromPlain({
                unitType: itemContentSchedulesCharge.unitType,
                subjectTo: itemContentSchedulesCharge.subjectTo,
                currency: itemContentSchedulesCharge.currency,
                costPerUnit: itemContentSchedulesCharge.costPerUnit,
                numberOfUnits: itemContentSchedulesCharge.numberOfUnits,
                totalCost: itemContentSchedulesCharge.totalCost,
                containerId: itemContentSchedulesCharge.containerId,
                contractId: itemContentSchedulesCharge.contractId,
                transportationIds: itemContentSchedulesCharge.transportationIds.map((itemContentSchedulesChargeTransportationIds) => itemContentSchedulesChargeTransportationIds),
                applied: itemContentSchedulesCharge.applied,
                applianceRange: itemContentSchedulesCharge.applianceRange ? FreightQuotaContentSchedulesChargesApplianceRangeDTM.fromPlain(itemContentSchedulesCharge.applianceRange) : undefined,
                designation: itemContentSchedulesCharge.designation,
                measureBy: itemContentSchedulesCharge.measureBy,
                priceBy: itemContentSchedulesCharge.priceBy,
                chargeCode: FreightQuotaContentSchedulesChargesChargeCodeDTM.fromPlain({
                  code: itemContentSchedulesCharge.chargeCode.code,
                  description: itemContentSchedulesCharge.chargeCode.description,
                  originalDescription: itemContentSchedulesCharge?.metadata?.originalDescription,
                  mode: itemContentSchedulesCharge.chargeCode.mode,
                  occurrence: itemContentSchedulesCharge.chargeCode.occurrence,
                  type: itemContentSchedulesCharge.chargeCode.type,
                  subType: itemContentSchedulesCharge.chargeCode.subType || '',
                }),
                metadata: {
                  excludedCommodities: itemContentSchedulesCharge?.metadata?.excludedCommodities,
                },
              })),
              transportations: itemContentSchedules.transportations.map((itemContentSchedulesTransportations) => FreightQuotaTransportationDTM.fromPlain({
                id: itemContentSchedulesTransportations.id,
                transport: FreightQuotaTransportationTransportDTM.fromPlain({
                  number: itemContentSchedulesTransportations.transport.number,
                  name: itemContentSchedulesTransportations.transport.name,
                  type: itemContentSchedulesTransportations.transport.type,
                }),
                schedule: FreightQuotaTransportationScheduleDTM.fromPlain({
                  type: itemContentSchedulesTransportations.schedule.type,
                  departureTime: itemContentSchedulesTransportations.schedule.departureTime,
                  arrivalTime: itemContentSchedulesTransportations.schedule.arrivalTime,
                  transitDuration: itemContentSchedulesTransportations.schedule.transitDuration,
                  terminalCutOff: itemContentSchedulesTransportations.schedule.terminalCutOff,
                  documentCutOff: itemContentSchedulesTransportations.schedule.documentCutOff,
                  dropTime: itemContentSchedulesTransportations.schedule.dropTime,
                  pickupTime: itemContentSchedulesTransportations.schedule.pickupTime,
                  hazmatCutOff: itemContentSchedulesTransportations.schedule.hazmatCutOff,
                }),
                arrivalTerminal: itemContentSchedulesTransportations?.arrivalTerminal?.name,
                departureTerminal: itemContentSchedulesTransportations?.departureTerminal?.name,
                containers: itemContentSchedulesTransportations.containers.map((itemContentSchedulesTransportationsContainer) => itemContentSchedulesTransportationsContainer),
                transportLeg: itemContentSchedulesTransportations.transportLeg,
                voyageCode: itemContentSchedulesTransportations.voyageCode,
              })),
            })),
            contracts: itemContent.contracts.map((itemContentContract) => FreightQuotaContentContractsDTM.fromPlain({
              id: itemContentContract.id,
              number: itemContentContract.number,
              name: itemContentContract.name,
              scac: itemContentContract.scac,
            })),
            containers: itemContent.containers.map((itemContentContainer) => FreightQuotaContentContainerDTM.fromPlain({
              id: itemContentContainer.id,
              type: itemContentContainer.type,
              ownContainer: itemContentContainer.ownContainer,
              rateId: itemContentContainer.rateId,
              weight: itemContentContainer.weight,
              volume: itemContentContainer.volume,
              commodities: itemContentContainer.commodities.map((itemContentContainerCommodity) => FreightQuotaContentCommodity.fromPlain({
                code: itemContentContainerCommodity.code,
                description: itemContentContainerCommodity.description,
                imoClass: itemContentContainerCommodity.imoClass,
                unNumber: itemContentContainerCommodity.unNumber,
                value: itemContentContainerCommodity.value,
              })),
            })),
            customer: FreightQuotaContentCustomerDTM.fromPlain({
              companyId: itemContent?.customer?.companyId,
              contactId: itemContent?.customer?.contactId,
              addressId: itemContent?.customer?.addressId,
              organizationId: itemContent?.customer?.organizationId,
            }),
            bookmark: QuotaBookmark.fromPlain({
              id: itemContent?.bookmark?.id,
              name: itemContent?.bookmark?.name,
              createdAt: itemContent?.bookmark?.createdAt,
              createdBy: itemContent?.bookmark?.createdBy,
            }),
            validPeriod: FreightQuotaContentValidPeriodDTM.fromPlain({
              from: itemContent?.validPeriod?.from ? DateDtm.fromPlain({
                date: itemContent?.validPeriod?.from,
                offset: moment(itemContent?.validPeriod?.from).utcOffset(),
              }) : undefined,
              to: itemContent?.validPeriod?.to ? DateDtm.fromPlain({
                date: itemContent?.validPeriod?.to,
                offset: moment(itemContent?.validPeriod?.to).utcOffset(),
              }) : undefined,
            }),
          });

          const errors = newItem.validate();

          if (errors.length) {
            console.error('DTM valid RFQ: error', errors);
            return null;
          }

          return newItem;
        });

        const list = content.filter((el) => el !== null) as FreightQuotaContentDTM[];

        const pegeable: FreightQuotaPageableDTM = FreightQuotaPageableDTM.fromPlain({
          sort: {
            empty: response.pageable.sort.empty,
            sorted: response.pageable.sort.sorted,
            unsorted: response.pageable.sort.unsorted,
          },
          offset: response.pageable.offset,
          pageNumber: response.pageable.pageNumber,
          pageSize: response.pageable.pageSize,
          paged: response.pageable.paged,
          unpaged: response.pageable.unpaged,
        });

        result = RFQQuotasDTM.fromPlain({
          content: list,
          pageable: pegeable,
          last: response.last,
          totalPages: response.totalPages,
          totalElements: response.totalElements,
          size: response.size,
          number: response.number,
          sort: {
            empty: response.sort.empty,
            sorted: response.sort.sorted,
            unsorted: response.sort.unsorted,
          },
          first: response.first,
          numberOfElements: response.numberOfElements,
          empty: response.empty,
        });

        list?.forEach((item) => {
          const errors = item.validate();

          if (errors.length) {
            console.error('DTM valid RFQ: error', errors);
          }
        });
      }
    } catch (e) {
      if ((e as unknown as ServerErrorAxios)?.response?.data?.message) {
        throw (e as unknown as ServerErrorAxios)?.response?.data?.message;
      }
      if ((e as unknown as ServerErrorAxios)?.response?.data?.details) {
        throw (e as unknown as ServerErrorAxios)?.response?.data?.details[0];
      }

      throw new Error('RFQ: postRateRequests, list getting error');
    }

    return result;
  }

  public putRateRequestById = async (params: PutRateRequestParams) => {
    let rfrRequest: FreightRFRQuotaContentDTM | undefined;

    if (!params.id) {
      throw new Error('putRateRequestById Response not ID');
    }

    const rawResponse = await apiWorker.requestPut<GetRFRRequestByIdResponse>(`${this.baseRateRequests}/${params.id}`, {
      ...params,
    });

    const response = rawResponse.data;

    if (response) {
      rfrRequest = this.formatFreightRFRQuotaContentDTM(response);

      const errors = rfrRequest.validate();

      if (errors.length) {
        console.error('DTM valid putRateRequestById: error', errors);
      }
    }

    return rfrRequest;
  }

  public getOceanRates = async (params: GetOceanRatesParams) => {
    let oceanRates: FreightOceanRatesDTM | undefined;
    let response: GetOceanRatesResponseContract | undefined;

    try {
      const rawResponse = await apiWorker.requestGet<GetOceanRatesResponseContract>(`${this.oceanRates}`, {
        params: {
          ...params,
          ids: params?.ids?.join(',') || undefined,
          carriers: params?.carriers?.join(',') || undefined,
          contractNumbers: params?.contractNumbers?.join(',') || undefined,
          containers: params?.containers?.join(',') || undefined,
          commodityCodes: params?.commodityCodes?.join(',') || undefined,
        },
      });
      if (rawResponse.status !== 200) {
        throw new Error('getOceanRates Response not ok');
      }

      response = rawResponse.data;
    } catch (e) {
      if ((e as unknown as ServerErrorAxios)?.response?.data?.message) {
        throw (e as unknown as ServerErrorAxios)?.response?.data?.message;
      }
      if ((e as unknown as ServerErrorAxios)?.response?.data?.details) {
        throw (e as unknown as ServerErrorAxios)?.response?.data?.details[0];
      }

      throw new Error('RFR: getOceanRates, list getting error');
    }

    if (response) {
      // oceanRates = this.formatFreightRFRQuotaContentDTM(response);

      // const errors = rfrRequest.validate();

      // if (errors.length) {
      //   console.error('DTM valid putRateRequestById: error', errors);
      // }
    }

    return oceanRates;
  }

  private formatQuotasUrl = (params: IAsyncThunkGetParams) => {
    const getParamsNew: string[] = [];

    const paramsNew = new URLSearchParams(params.getParams).toString();

    if (paramsNew) {
      getParamsNew.push(paramsNew);
    }

    const getParamsNewString = getParamsNew.length
      ? `?${getParamsNew?.join('&') || ''}`
      : '';

    const oldGetParams = params?.urlGetParams || [];
    const getParams = oldGetParams.length ? `${getParamsNewString ? `${getParamsNewString}&` : '?'}${oldGetParams.join('&') || ''}` : getParamsNewString;

    const urlId = params.urlId ? `/${params.urlId}` : '';
    const getAfterUrlIdPrefix = params.afterUrlIdPrefix ? `/${params.afterUrlIdPrefix}` : '';

    return `${params.url}${urlId}${getAfterUrlIdPrefix}${getParams}`;
  }

  private getLocation = (location: QuotaServiceByIdContentRoutesLegsLocationDTM): FreightQuotaContentRoutesLocationDTM => FreightQuotaContentRoutesLocationDTM.fromPlain({
    id: location.id || Number((Math.random() * 10000).toFixed(0)),
    type: location.type,
    placeId: location.placeId,
    country: FreightSelectFieldCountryDTM.fromPlain({
      code: location?.country?.code || '',
      name: location?.country?.name || '',
    }),
    state: FreightSelectFieldCountryDTM.fromPlain({
      code: location?.state?.code || '',
      name: location?.state?.code || '',
    }),
    city: location?.city,
    name: location?.name,
    code: location.code,
    address: location?.address,
    postalCode: location?.postalCode,
    timeZoneId: location?.timeZoneId,
    coordinates: FreightSelectFieldCoordinatesDTM.fromPlain({
      lat: `${location?.coordinates?.lat || ''}`,
      lng: `${location?.coordinates?.lng || ''}`,
    }),
  });

  public getCurrentOrganization = async () => {
    let organization: OrganizationDTM | null = null;

    try {
      const rawResponse = await apiWorker.requestGet<TGetCurrentOrganizationResponseContract>(`${this.urlOrganizationBase}/current`);

      const response = rawResponse.data;

      const memberships = response.memberships && response.memberships.map((item) => OrganizationMembershipDtm.fromPlain({
        id: `${item.id}`,
        type: item.type,
        value: item.value,
        isNew: false,
      }));

      organization = OrganizationDTM.fromPlain({
        id: response.id,
        status: response.status as EOrganizationStatus,
        role: response.role,
        name: response.name,
        note: response.note,
        createdAt: response.createdAt ? DateDtm.fromPlain({
          date: response.createdAt,
          offset: 0,
        }) : undefined,
        address: {
          country: response.address.country,
          state: response.address.state,
          city: response.address.city,
          address1: response.address.address1,
          address2: response.address.address2,
          postalCode: response.address.postalCode,
          closestPort: response.address.closestPort,
        },
        primaryContact: response?.primaryContact ? {
          email: response.primaryContact.email,
          firstName: response.primaryContact.firstName,
          lastName: response.primaryContact.lastName,
          phone: response.primaryContact.phone,
          // phone2: response.primaryContact.phone2,
          enabled: response.primaryContact?.enabled,
          sub: response.primaryContact?.sub,
        } : undefined,
        phone: response.contactInformation.phone,
        phone2: response.contactInformation.phone2,
        email: response.contactInformation.email,
        webUrl: (response.contactInformation.webUrl
          ? ({
            webUrl: response.contactInformation.webUrl || '',
          })
          : undefined),
        businessRegistrationNumber: BusinessRegistrationNumberDtm.fromPlain({
          type: response.registrationNumber.type,
          value: response.registrationNumber.value,
        }),
        memberships,
        taxId: response.taxId,
        coverageAreas: response?.coverageAreas?.map((item) => ({
          type: item.type,
          countryCode: item.countryCode,
        })) || undefined,
        accountHolderId: response.accountHolderId,
        documents: [],
      });

      if (!organization.isValid()) {
        console.error(`RFQ: OrganizationDTM validation errors (getCurrentOrganization): ${organization.validate().join(', ')}`, organization.validate());
      }
    } catch (e) {
      throw new Error('RFQ: Current organization getting error');
    }

    return organization;
  }

  private formatFreightRFRQuotaContentDTM = (data: GetRFRRequestByIdResponse) => FreightRFRQuotaContentDTM.fromPlain({
    id: data.id,
    params: FreightRFRQuotaContentParamsDTM.fromPlain({
      origin: FreightRFRQuotaContentParamsLocationDTM.fromPlain({
        code: data?.params?.origin?.code || '',
        country: data?.params?.origin?.country,
        subdivisionCode: data?.params?.origin?.subdivisionCode,
        subdivisionName: data?.params?.origin?.subdivisionName,
        subdivisionType: data?.params?.origin?.subdivisionType,
        locationCode: data?.params?.origin?.locationCode,
        locationName: data?.params?.origin?.locationName,
        coordinates: data?.params?.origin?.coordinates,
        timeZoneId: data?.params?.origin?.timeZoneId,
        iataCode: data?.params?.origin?.iataCode,
        alternativeCodes: data?.params?.origin?.alternativeCodes,
      }),
      destination: FreightRFRQuotaContentParamsLocationDTM.fromPlain({
        code: data?.params?.destination?.code || '',
        country: data?.params?.destination?.country,
        subdivisionCode: data?.params?.destination?.subdivisionCode,
        subdivisionName: data?.params?.destination?.subdivisionName,
        subdivisionType: data?.params?.destination?.subdivisionType,
        locationCode: data?.params?.destination?.locationCode,
        locationName: data?.params?.destination?.locationName,
        coordinates: data?.params?.destination?.coordinates,
        timeZoneId: data?.params?.destination?.timeZoneId,
        iataCode: data?.params?.destination?.iataCode,
        alternativeCodes: data?.params?.destination?.alternativeCodes,
      }),
      loadType: data?.params?.loadType as EFreightLoadType,
      carriers: data?.params?.carriers,
      commodities: data?.params?.commodities,
      containers: data?.params?.containers,
      fromDate: data?.params?.fromDate,
      toDate: data?.params?.toDate,
      status: data?.params?.status,
      services: data?.params?.services?.map((itemService) => AdditionalServiceCheckedDTM.fromPlain({
        code: itemService.code,
        phase: itemService.phase,
      })),
    }),
    completedAt: data.completedAt,
    completedBy: data.completedBy,
    createdAt: data.createdAt,
    createdBy: data.createdBy,
    partnerOrgId: data.partnerOrgId || 0,
    status: data.status,
    organizationId: data.organizationId,
  })
}
