import React from 'react';
import { observer } from 'mobx-react-lite';

import { useMobxStore } from 'app-wrapper/mobxStores';

import { MarkupPolicyComponent } from './MarkupPolicy.component';

export const MarkupPolicy = observer(() => {
  const { customerSales } = useMobxStore();

  return (
    <MarkupPolicyComponent hasMarkupPolicy={!!customerSales.state.markupPolicy} />
  );
});
