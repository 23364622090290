import React, { FC } from 'react';

import { ShipmentDetailsActionPanel } from 'shipment-operations/view/components';
import { Wrapper, SubPageHeader } from 'app-wrapper/view/guideline';
import {
  ShipmentDetailsContent,
} from './ShipmentDetailsLayout.styled';

interface IShipmentDetailsLayoutProps {
  title?: React.ReactNode
  warningBlock?: React.ReactNode
  footer?: React.ReactNode
  navigationBar?: React.ReactNode
  gap?: number
}

export const ShipmentDetailsLayout: FC<IShipmentDetailsLayoutProps> = ({
  title,
  warningBlock,
  children,
  footer,
  gap,
  navigationBar,
}) => (
  <Wrapper noPaddingBottom>
    {warningBlock || null}
    {title && (
      <SubPageHeader>
        {title}
      </SubPageHeader>
    )}
    {navigationBar}
    <ShipmentDetailsContent gap={gap}>
      {children}
    </ShipmentDetailsContent>
    {footer && (
      <ShipmentDetailsActionPanel>
        {footer}
      </ShipmentDetailsActionPanel>
    )}
  </Wrapper>
);
