import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ShipmentChargeChangeDTM } from 'app-wrapper/models/dtm';
import { MissingTextValue } from 'app-wrapper/view/guideline';
import { CostChangeDifference } from 'app-wrapper/view/drawers/ShipmentCostChangesDrawer/components/CostChangesTable/components';
import {
  TableCell,
  CustomTable,
  MissingValueWrap,
  TableCellDifference,
} from './FooterTotalTable.styled';

interface IFooterTotalTableProps {
  costChanges: ShipmentChargeChangeDTM[];
}

export const FooterTotalTable: FC<IFooterTotalTableProps> = ({ costChanges }) => {
  const { t } = useTranslation();

  const totalCharge = useMemo(() => {
    const newValue = costChanges.reduce((prev, current) => (Number(current.newVersion?.totalCost) || 0) + prev, 0);
    const oldValue = costChanges.reduce((prev, current) => (Number(current.prevVersion?.totalCost) || 0) + prev, 0);

    return {
      newValue,
      oldValue,
    };
  }, [costChanges]);

  const columns = useMemo(() => [
    {
      title: t('Charge'),
      width: '28.7%',
      key: 'object',
      align: 'left' as 'left',
      render: () => (
        <TableCell>
          {t('Total')}
        </TableCell>
      ),
    },
    {
      title: t('Old Value'),
      width: '25.6%',
      key: 'object',
      align: 'right' as 'right',
      render: (item: string, record: typeof totalCharge | {}) => {
        const costChange = record as typeof totalCharge;

        return (
          <TableCell>
            {costChange.oldValue ? `$ ${costChange.oldValue.toFixed(2)}` : (
              <MissingValueWrap>
                <MissingTextValue />
              </MissingValueWrap>
            )}
          </TableCell>
        );
      },
    },
    {
      title: t('Difference'),
      width: '22.4%',
      key: 'object',
      align: 'right' as 'right',
      render: (item: string, record: typeof totalCharge | {}) => {
        const costChange = record as typeof totalCharge;

        return (
          <TableCellDifference>
            <CostChangeDifference
              prevCost={costChange?.oldValue || 0}
              newCost={costChange?.newValue || 0}
            />
          </TableCellDifference>
        );
      },
    },
    {
      title: t('New Value'),
      width: '24%',
      key: 'object',
      align: 'right' as 'right',
      render: (item: string, record: typeof totalCharge | {}) => {
        const costChange = record as typeof totalCharge;

        return (
          <TableCell>
            {costChange.newValue ? `$ ${costChange.newValue.toFixed(2)}` : (
              <MissingValueWrap>
                <MissingTextValue />
              </MissingValueWrap>
            )}
          </TableCell>
        );
      },
    },
  ], [t]);

  return (
    <CustomTable
      showHeader={false}
      columns={columns}
      dataSource={[totalCharge]}
      pagination={false}
    />
  );
};
