import React from 'react';
import { useSelector } from 'react-redux';

import { R } from 'user-management/repository';
import { UC } from 'user-management/controllers';

import { CustomerTeamViewComponent } from './CustomerTeamView.component';

export const CustomerTeamView = () => {
  const isLoading = useSelector(R.selectors.teams.getIsLoading);
  const accountManager = useSelector(R.selectors.teams.getAccountManager);
  const teamMembers = useSelector(R.selectors.teams.getTeamMembers);

  return (
    <CustomerTeamViewComponent
      isLoading={isLoading}
      accountManager={accountManager}
      teamMembers={teamMembers}
      init={UC.teams.initCustomerSkypaceTeam}
    />
  );
};
