import React from 'react';

export const IconEX1 = () => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M27.2518 4.39791H8.90144C8.40359 4.39791 8 4.8015 8 5.29935V30.9446H24.3931C24.3931 33.6978 26.7474 35.6216 29.505 35.6074C32.2154 35.5938 34.4325 33.2963 34.4325 30.5863V11.5433H29.6478C28.8845 11.5433 27.2518 9.87267 27.2518 9.10983V4.39791Z" fill="#E4EBF7" />
    <path d="M27.1139 14.4759H29.0004V21.8183C29.0004 22.6534 28.8193 23.3526 28.4571 23.9161C28.0949 24.4795 27.6019 24.9046 26.9781 25.1914C26.3593 25.4731 25.6676 25.614 24.9029 25.614C24.1131 25.614 23.4088 25.4731 22.79 25.1914C22.1712 24.9046 21.6833 24.4795 21.3261 23.9161C20.9739 23.3526 20.7979 22.6534 20.7979 21.8183V14.4759H22.6844V21.8183C22.6844 22.3465 22.7749 22.7817 22.956 23.1237C23.1371 23.4608 23.3937 23.7098 23.7257 23.8708C24.0578 24.0318 24.4502 24.1123 24.9029 24.1123C25.3557 24.1123 25.7456 24.0318 26.0726 23.8708C26.4046 23.7098 26.6612 23.4608 26.8423 23.1237C27.0234 22.7817 27.1139 22.3465 27.1139 21.8183V14.4759Z" fill="#A6B9DB" />
    <path d="M19.4014 23.9614V25.463H13.5683V23.9614H19.4014ZM14.104 14.4759V25.463H12.21V14.4759H14.104ZM18.6392 19.064V20.543H13.5683V19.064H18.6392ZM19.3636 14.4759V15.9851H13.5683V14.4759H19.3636Z" fill="#A6B9DB" />
    <path d="M24.3874 30.8639H5.58594C5.58594 33.3233 7.37492 35.1014 9.72206 35.4954L10.5484 35.5645L29.3874 35.527C26.647 35.527 24.3175 33.4225 24.3874 30.8639Z" fill="#597EF7" />
    <path opacity="0.6" d="M27.208 10.3936V11.3326C27.208 11.5344 27.292 11.7271 27.4399 11.8644C27.5878 12.0016 27.7862 12.0711 27.9874 12.056L34.4154 11.5779L27.208 10.3936Z" fill="#CCD7EE" />
    <path d="M30.934 7.97589L27.2002 4.375L27.21 11.2763C27.21 11.4422 27.3445 11.5768 27.5104 11.5768H34.4155L30.934 7.97589Z" fill="#FAAD14" />
  </svg>
);

