import React, {
  FC,
} from 'react';
import { useSelector } from 'react-redux';

import { R } from 'monetary/repository';
import { UC } from 'monetary/controllers';

import { FreightQuoteFormRequestComponent } from './FreightQuoteFormRequest.component';

interface IFreightQuoteFormRequestComponentProps {
  quotaSearchId?: string | null
  forceCollapse?: boolean
  hideButton?: boolean
  onStartFirstRun?: boolean
  stayUrl?: string
  onSubmitReuseRequest?: () => void
}

export const FreightQuoteFormRequest: FC<IFreightQuoteFormRequestComponentProps> = ({
  forceCollapse,
  quotaSearchId,
  hideButton,
  onStartFirstRun,
  stayUrl,
  onSubmitReuseRequest,
}) => {
  const {
    isCollapse, status, quotaStatusMessage, isAllRequestedPage,
  } = useSelector(R.selectors.freightQuote.getFreightQuoteCurrent);
  const getRFQNewQuotaPermissions = useSelector(R.selectors.moduleFunctionalityPermissions.getRFQNewQuotaPermissions);

  const rfqFormClientInformation = !getRFQNewQuotaPermissions?.rfqFormClientInformation;

  return (
    <FreightQuoteFormRequestComponent
      forceCollapse={forceCollapse}
      quotaSearchId={quotaSearchId}
      hideButton={hideButton}
      // new
      isAllRequestedPage={isAllRequestedPage}
      onStartFirstRun={onStartFirstRun ? UC.FreightQuote.onStartFirstRun : (() => '')}
      onSubmit={UC.FreightQuote.onSubmit}
      onSubmitReuseRequest={onSubmitReuseRequest}
      onCollapseChange={UC.FreightQuote.onCollapseChanged}
      isCollapsed={isCollapse}
      requestQuotaStatus={status}
      quotaStatusMessage={quotaStatusMessage}
      rfqFormClientInformation={rfqFormClientInformation}
      stayUrl={stayUrl}
    />
  );
};
