import * as yup from 'yup';
import i18n from 'i18next';

yup.addMethod(yup.array, 'commodityLengthTemperature', function commodityLengthTemperature(property) {
  return this.test(
    'commodityLengthTemperature',
    i18n.t('Validations.mixed.required'),
    function commodityLengthTemperatureTest(_value, _context) {
      return !(this.parent[property] && this.parent.commodities.length > 1);
    },
  );
});

declare module 'yup' {
  interface ArraySchema<T> {
    commodityLengthTemperature(
      property?: string, mapper?: T
    ): ArraySchema<T>;
  }
}
