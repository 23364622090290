import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Option } from 'app-wrapper/view/components';

import { OrganizationPaymentMethodDTM } from 'user-management/models/dtm';

import { DeferredPaymentValues } from 'shipment-operations/constants';

import {
  Content,
  Field,
  Label,
  StyledInputNumber,
  FieldSelect,
  LabelSpan,
} from './TradeReferences.styled';

interface TradeReferencesComponentProps {
  getPaymentMethod?: OrganizationPaymentMethodDTM;
  shouldValidate?: boolean;
  isPrepayment?: boolean;
  setPaymentType: (value: string) => void;
  setTerm: (value: number) => void;
  setCreditLimit: (value: number) => void;
}

export const TradeReferencesComponent: FC<TradeReferencesComponentProps> = ({
  getPaymentMethod,
  shouldValidate,
  isPrepayment,
  setPaymentType,
  setTerm,
  setCreditLimit,
}) => {
  const { t } = useTranslation();

  const creditLimit = getPaymentMethod?.creditLimit || 0;
  const creditTerm = getPaymentMethod?.creditTerm || 0;
  const type = getPaymentMethod?.type || '';

  const handlePaymentTypeChange = useCallback((e) => {
    setPaymentType(e);
  }, [setPaymentType]);

  const handleTermChange = useCallback((e) => {
    setTerm(Number(e));
  }, [setTerm]);

  const handleCreditLimitChange = useCallback((e) => {
    setCreditLimit(Number(e));
  }, [setCreditLimit]);

  const formatterInput = useCallback((value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ','), []);
  const parserInput = useCallback((value) => value!.replace(/\$\s?|(,*)/g, ''), []);
  const getPopupContainer = useCallback(() => document.body, []);

  return (
    <Content>
      <Field>
        <Label>
          {t('Payment Terms')}
        </Label>

        <FieldSelect
          error={shouldValidate && !type?.length}
          value={type || null}
          onChange={handlePaymentTypeChange}
          getPopupContainer={getPopupContainer}
        >
          <Option key={`${0}_TradeReferencesComponentStyledLabel.map`} value="Deferred Payment">
            {t('Deferred Payment')}
          </Option>
          <Option key={`${1}_TradeReferencesComponentStyledLabel.map`} value="Prepayment">
            {t('Prepayment')}
          </Option>
        </FieldSelect>
      </Field>

      {!isPrepayment && (
        <Field>
          <Label marginTop="8px">
            {t('Term')}<LabelSpan> {t('Day(s)')}</LabelSpan>
          </Label>

          <FieldSelect
            error={shouldValidate && !creditTerm}
            value={creditTerm || null}
            onChange={handleTermChange}
            getPopupContainer={getPopupContainer}
          >
            {DeferredPaymentValues.map((item) => (
              <Option key={`${item}_TradeReferencesComponentStyledLabel.map`} value={item}>
                {item}
              </Option>
            ))}
          </FieldSelect>
        </Field>
      )}

      {!isPrepayment && (
        <Field>
          <Label marginTop="8px">
            {t('Credit Limit')}<LabelSpan> $</LabelSpan>
          </Label>

          <StyledInputNumber
            value={creditLimit}
            formatter={formatterInput}
            parser={parserInput}
            isError={shouldValidate && !creditLimit}
            onChange={handleCreditLimitChange}
          />
        </Field>
      )}

    </Content>
  );
};
