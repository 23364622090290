import styled from 'styled-components';

import {
  RangePicker, Select, Row,
} from 'app-wrapper/view/components';
import { divFlex, responsive } from 'app-wrapper/view/themes/mixin';
import Checkbox from 'antd/es/checkbox';

export const AddressBlockSelect = styled(Select).attrs((props: {
  isError: boolean
  'data-class'?: string
}) => ({
  isError: props.isError,
  'data-class': props?.['data-class'] || 'AddressBlockSelect',
}))`
  .ant-select-selector {
    ${({ isError, theme }) => isError && `border-color: ${theme.themesColors.characterBrandingDanger} !important;`};
    ${({ isError }) => isError && 'z-index: 2;'};
  }

  input {
    color: ${({ theme }) => theme.themesColors.neutralBranding10}
  }
`;

export const AddressBlockRangePicker = styled(RangePicker).attrs((props: {
  isError: boolean
}) => ({
  isError: props.isError,
  'data-class': 'AddressBlockRangePicker',
}))`
  width: 100%;
  min-height: 32px;

  ${responsive.forFullMedia`
    min-height: 40px;
  `}

  ${({ isError, theme }) => isError && `border-color: ${theme.themesColors.characterBrandingDanger} !important;`};
  ${({ isError }) => isError && 'z-index: 2;'};

  input {
    font-size: 12px !important;
  }
`;

export const AddressBlockIncludeRelatedPort = styled.div.attrs((props: {
  isError: boolean
}) => ({
  isError: props.isError,
  'data-class': 'AddressBlockIncludeRelatedPort',
}))`
  ${divFlex}

  width: 100%;
  margin-top: 18px;

  .ant-checkbox-wrapper .ant-checkbox-inner {
    border-color: ${({ theme }) => theme.themesColors.neutralBranding6};
  }

  .ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-checked::after {
    border-color: ${({ theme }) => theme.themesColors.secondaryDot45};
  }

  .ant-checkbox-wrapper .ant-checkbox-checked .ant-checkbox-inner {
    border-color: ${({ theme }) => theme.themesColors.secondaryDot45};
    background-color: ${({ theme }) => theme.themesColors.secondaryDot45};
  }
`;

export const AddressBlockIncludeRelatedPortText = styled.div.attrs((props: {
  isError: boolean
}) => ({
  isError: props.isError,
  'data-class': 'AddressBlockIncludeRelatedPortText',
}))`
  ${divFlex}

  font-size: 12px;
`;

export const AddressBlockIncludeRelatedPortCheckbox = styled(Checkbox).attrs((props: {
  isError: boolean
}) => ({
  isError: props.isError,
  'data-class': 'AddressBlockIncludeRelatedPortCheckbox',
}))`
`;

export const RowAddress = styled(Row)`
  display: flex;
  position: relative;
  width: 100%;
  margin-top: 12px;
`;

export const RowAddressNotCollapsed = styled(RowAddress)`
  .ant-picker-panel-container .ant-picker-panels {
    display: flex;
    flex-direction: column;
  }
  .ant-picker-dropdown {
    top: 32px !important;
  }
`;

export const RowAddressBoth = styled(Row)`
  display: flex;
  position: relative;
  width: 100%;
`;

export const RowAddressMsg = styled(Row)`
  width: 100%;
`;
