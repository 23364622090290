import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'app-wrapper/createSlice';

import { UserAuthDataDTM } from 'authentication/models/dtm';

import { AuthInitialState } from './AuthInitial.state';

export const authSlice = createSlice({
  name: 'auth',
  initialState: AuthInitialState,
  reducers: {
    setIsLoading: (state, { payload }) => {
      state.isLoading = payload;
    },
    setUser: (state, { payload }: PayloadAction<UserAuthDataDTM>) => ({
      ...state,
      isLoading: false,
      name: payload.firstName,
      user: {
        email: payload.email,
        firstName: payload.firstName,
        lastName: payload.lastName,
        userId: payload.userId,
        isAdmin: payload.isAdmin,
      },
      error: '',
      email: payload.email,
    }),
    logOut: (state) => {
      state.user = {
        email: '',
        firstName: '',
        lastName: '',
        userId: undefined,
        isAdmin: undefined,
      };
      state.email = '';
      state.name = '';
      state.error = '';
      state.isLoading = false;
    },
    setResponseError: (state, { payload }) => {
      state.error = payload;
    },
    setEmailError: (state, { payload }) => ({
      ...state,
      errors: {
        ...state.errors,
        email: {
          ...state.errors.email,
          ...payload,
        },
      },
    }),
    setEmail: (state, { payload }) => ({
      ...state,
      email: payload || '',
    }),
    setPasswordError: (state, { payload }) => ({
      ...state,
      errors: {
        ...state.errors,
        password: {
          ...state.errors.password,
          ...payload,
        },
      },
    }),
    setPassword: (state, { payload }) => ({
      ...state,
      password: payload,
    }),
  },
});

export const AuthActions = authSlice.actions;
export const AuthReducer = authSlice.reducer;
