import React, { FC } from 'react';
import Spin from 'antd/es/spin';

import { SpinnerWrap } from 'app-wrapper/view/guideline';

import { SBLScaffoldContainer } from 'shipment-operations/view/pages/ShipmentBillOfLading/components/ShipmentInstructionsContent/components/SBLScaffold/SBLScaffold.container';

interface IShipmentBillOfLadingProps {
  isLoading: boolean;
}

export const ShipmentInstructionsContentComponent: FC<IShipmentBillOfLadingProps> = ({ isLoading }) => {
  if (isLoading) {
    return (
      <SpinnerWrap>
        <Spin size="large" />
      </SpinnerWrap>
    );
  }

  return (
    <SBLScaffoldContainer />
  );
};
