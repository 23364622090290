import styled from 'styled-components';

import { colors } from 'app-wrapper/view/themes/themesColors';

import { Tag } from './Tag.styled';

export const WhiteTag = styled(Tag)`
  background-color: white;
  color: ${colors.gray61};
`;
