import React from 'react';
import { observer } from 'mobx-react-lite';

import { UC } from 'app-wrapper/controllers';
import { useMobxStore } from 'app-wrapper/mobxStores';

import { ContractCarrierComponent } from './ContractCarrier.component';

export const ContractCarrier = observer(() => {
  const { contracts } = useMobxStore();

  return (
    <ContractCarrierComponent
      carrier={contracts.state.contractCarrier}
      setCarrier={UC.contracts.setContractCarrier}
    />
  );
});
