import React, { FC } from 'react';
import isEmpty from 'lodash/fp/isEmpty';
import { useTranslation } from 'react-i18next';

import { CreditNoteItemDTM } from 'shipment-operations/models/dtm';
import {
  InnerContainer, StyledTotal, Title,
} from 'shipment-operations/view/components';
import { CustomTable } from 'shipment-operations/view/pages/CreditNote/components/CustomTable';

interface IFeesComponentProps {
  sum: number
  data: CreditNoteItemDTM[]
}

const FeesComponent: FC<IFeesComponentProps> = ({
  sum, data,
}) => {
  const { t } = useTranslation();

  return (!isEmpty(data) ? (
    <>
      <InnerContainer>
        <Title>{t('fees')}</Title>
        <CustomTable data={data} />
      </InnerContainer>
      <StyledTotal>$ {sum.toFixed(2)}</StyledTotal>
    </>
  ) : null);
};

export { FeesComponent };
