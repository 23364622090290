import styled from 'styled-components';
import AntSwitch from 'antd/es/switch';

export const Switch = styled(AntSwitch).attrs({
  'data-class': 'switch',
})`
  min-width: 28px;
  height: 16px;
  background-color: #d1d7e2;

  &.ant-switch-checked .ant-switch-handle {
    left: calc(100% - 12px - 2px);
  }

  .ant-switch-handle {
    width: 12px;
    height: 12px;
  }

  .ant-switch-inner {
    margin: 0 0 0 25px;
  }

  &.ant-switch-checked .ant-switch-inner {
    margin: 0 25px 0 0;
  }
`;
