import styled from 'styled-components';

import { Button } from 'app-wrapper/view/components';
import { colors } from 'app-wrapper/view/themes/themesColors';

export const ShipmentContainersFormTitleContainer = styled.div.attrs({
  'data-class': 'ShipmentContainersFormTitleContainer',
})`
  display: flex;
  flex-flow: column nowrap;
  justify-content: stretch;
  align-items: stretch;
`;

export const ShipmentContainersFormTitleFirstRow = styled.div.attrs({
  'data-class': 'ShipmentContainersFormTitleFirstRow',
})`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  height: 24px;
`;

export const ShipmentContainersFormTitleName = styled.span.attrs({
  'data-class': 'ShipmentContainersFormTitleName',
})`
  font-size: 16px;
  font-weight: 500;
`;

export const ShipmentContainersFormTitleGrayName = styled.span.attrs({
  'data-class': 'ShipmentContainersFormTitleGrayName',
})`
  color: ${colors.gray61} !important
`;

export const ShipmentContainersFormTitleButton = styled(Button).attrs({
  'data-class': 'ShipmentContainersFormTitleButton',
})`
  font-size: 12px;
`;

export const ShipmentContainersFormTitleSecondRow = styled.span.attrs({
  'data-class': 'ShipmentContainersFormTitleSecondRow',
})`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  justify-content: flex-start;
  height: 20px;
  
  > * {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
    justify-content: flex-start;
    color: ${colors.gray61};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 12px;
  }
`;
