import message from 'antd/es/message';
import { BaseController, controller } from 'proto/BaseController';

import i18n from 'app-wrapper/i18n/i18n';
import { RouteNames } from 'app-wrapper/constants';

import { R } from 'authentication/repository';

@controller
export class PasswordResetEmailSendController extends BaseController {
  public sendEmail = async () => {
    this.validateEmail();

    const email = R.selectors.passwordResetEmailSend.getEmail(this.store.getState());

    if (!email) {
      console.error('No email');
      return;
    }

    this.dispatch(R.actions.passwordResetEmailSend.setLoading(true));

    try {
      await R.services.auth.passwordResetEmailSend(email);
    } catch (e) {
      if (e instanceof Error) {
        message.error(e.message);

        this.dispatch(R.actions.passwordResetEmailSend.setLoading(false));

        return;
      }

      // for other cases, when we have e as string or other
      this.dispatch(R.actions.passwordResetEmailSend.setLoading(false));

      return;
    }

    this.dispatch(R.actions.passwordResetNewPassword.setEmail(email));

    message.info(i18n.t('We have sent a confirmation code to your Email'));

    this.dispatch(R.actions.passwordResetEmailSend.setLoading(false));

    this.navigate(RouteNames.FORGOT_PASSWORD_NEW_PASSWORD());
  }

  public setEmail = (value: string) => {
    this.dispatch(R.actions.passwordResetEmailSend.setEmail(value));
  }

  public onBlurEmail = () => {
    this.validateEmail();
  }

  public onClear = () => {
    this.dispatch(R.actions.passwordResetEmailSend.clear());
  }

  private validateEmail = () => {
    const email = R.selectors.passwordResetEmailSend.getEmail(this.store.getState());

    this.dispatch(R.actions.passwordResetEmailSend.clearEmailError());

    if (!email) {
      this.dispatch(R.actions.passwordResetEmailSend.setEmailError(new Error(i18n.t('basicErrors.REQUIRED_MESSAGE'))));
    }
  }
}
