import { services } from './services';
import { actions, reducers, selectors } from './store';

import * as utilsServices from './utilsServices';

export * from './store';

export const R = {
  actions,
  reducers,
  selectors,
  services,
  utilsServices,
};
