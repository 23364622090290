import styled from 'styled-components';

export const Footer = styled.div.attrs((props: {
  position?: string
  'data-class'?: string
}) => ({
  position: props.position,
  'data-class': props?.['data-class'] || 'Footer',
}))`
  width: 100%;
  position: ${({ position }) => position || 'sticky'};
  left: 0;
  right: 0;
  bottom: 0;
  min-height: 64px;
  background-color: white;
  padding: 14px 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px rgba(0, 0, 0, 0.08), 0px 9px 28px 8px rgba(0, 0, 0, 0.05);
`;
