import React from 'react';

import { IPropsIcon } from 'app-wrapper/interfaces/icon';

import { Icon } from './FavoritesCheckQuotesSvg.styled';

export const FavoritesCheckQuotesSvg = (props: IPropsIcon) => {
  const { width, height, fill } = props;
  return (
    <Icon
      className="FavoritesCheckQuotesSvg"
      width={width || '12'}
      height={height || '16'}
      viewBox="0 0 12 16"
      fill={fill || 'currentColor'}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M9.99999 0.888885C10.7222 0.888885 11.3333 1.5 11.3333 2.22222V15.1111L5.99999 12L0.666656 15.1111V2.22222C0.666656 1.5 1.24999 0.888885 1.99999 0.888885H9.99999Z" fill="currentColor" />
    </Icon>
  );
};
