import { createSelector } from 'reselect';
import reduce from 'lodash/fp/reduce';
import map from 'lodash/fp/map';
import sortBy from 'lodash/fp/sortBy';

import { RootState } from 'app-wrapper/store';
import { DataTypeBillingDTM } from 'shipment-operations/models/dtm';

const localState = (state: RootState) => state.shipmentBilling;

const getIsLoading = createSelector(
  localState,
  (state) => state.isLoading,
);

const getData = createSelector(
  localState,
  (state) => sortBy((item) => new Date(item.dueDate), state.data),
);

const getError = createSelector(
  localState,
  (state) => state.error,
);

const getDataForTable = createSelector(
  getData,
  (data) => map((item) => (DataTypeBillingDTM.fromPlain({
    key: Math.random(),
    id: item.id,
    number: item.number,
    type: item.type,
    created: item.createdAt,
    dueDate: item.dueDate,
    status: item.status,
    total: item.total,
    balance: item.balance,
  })), data),
);

export const getTotalValues = createSelector(
  getData,
  (data) => {
    const totalSum = reduce((acc, cur) => acc + cur.total, 0, data);
    const totalBalance = reduce((acc, cur) => acc + cur.balance, 0, data);
    return [{ totalSum, totalBalance, key: 1 }];
  },
);

export const shipmentBillingSelectors = {
  getData,
  getError,
  getIsLoading,
  getDataForTable,
  getTotalValues,
};
