import React, { useContext } from 'react';
import { MobxStoresContext } from 'app-wrapper/mobxStores';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';

import { UC } from 'user-management/controllers';

import { InputComponent } from 'user-management/view/pages/Account/components/AccountDrawer/components/UserEditProfile/components';

const PhoneInputContainer = observer(() => {
  const { t } = useTranslation();
  const { userEditProfileStore } = useContext(MobxStoresContext);
  const { isLoading, phone, phoneError } = userEditProfileStore.state;

  return (
    <InputComponent
      isLoading={isLoading}
      onChange={UC.userEditProfile.setPhone}
      onBlur={UC.userEditProfile.onBlurPhone}
      value={phone}
      error={phoneError}
      title={t('Phone')}
    />
  );
});

export { PhoneInputContainer as PhoneInput };
