import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import get from 'lodash/fp/get';
import isEmpty from 'lodash/fp/isEmpty';
import getOr from 'lodash/fp/getOr';
import noop from 'lodash/fp/noop';
import InfoCircleOutlined from '@ant-design/icons/InfoCircleOutlined';
import Table from 'antd/es/table';

import { Tooltip } from 'app-wrapper/view/components';

import { ChargeViewDtm, ShipmentChargesPermissionsDtm } from 'shipment-operations/models/dtm';
import {
  StyledBodyCell, StyledTitle, CustomUnit, StyledExpandIcon,
} from 'shipment-operations/view/pages/ShipmentCharges/components/CustomTable/CustomTable.styled';
import { StyledType } from 'shipment-operations/view/pages/ShipmentTransportationCharges/components/CustomTable/CustomTable.styled';
import {
  CUSTOMER_VIEW,
  measureByConst,
  PermissionAttributePolicy,
  ShortChargeStatusEnum as statuses,
} from 'shipment-operations/constants';
import {
  matchedIcon,
} from 'shipment-operations/view/pages/ShipmentCharges/components/ContainersTable/ContainersTable.component';
import { normalizationCost } from 'app-wrapper/utils';
import {
  FirstHeaderWrapper, SubHeader,
} from 'shipment-operations/view/pages/ShipmentCharges/components/ContainersTable/ContainersTable.styled';

const { Column, ColumnGroup } = Table;

const useTable = (permissions: ShipmentChargesPermissionsDtm, onOpenHistory: (key: number) => void, openedKeys: number[], mode: string, isPercentage: boolean, subTitle?: string) => {
  const { t } = useTranslation();

  const renderProfit = useCallback((value, record) => (
    <StyledBodyCell active={record.active}>
      {isPercentage ? `% ${record.profitPercentage.toFixed(2)}` : `$ ${normalizationCost(value, { thousandthSeparatorComma: true, toFixed: 2 })}`}
    </StyledBodyCell>
  ), [isPercentage]);

  const renderWithActive = useCallback((value, record) => <StyledBodyCell active={record.active}>{value}</StyledBodyCell>, []);

  const renderSum = useCallback((value, record) => (
    <StyledBodyCell active={record.active}>{`$ ${normalizationCost(value, { thousandthSeparatorComma: true, toFixed: 2 })}`}</StyledBodyCell>
  ), []);

  const fullColumns = useMemo(() => (
    <>
      <ColumnGroup align="left" title={<FirstHeaderWrapper>{subTitle}</FirstHeaderWrapper>}>
        <Column
          title={t('shipmentOperation.transportation.service')}
          dataIndex="description"
          key="description"
          width="19%"
          render={(title: string, record: ChargeViewDtm | {}) => {
            const active = get(['active'], record);
            const includedCharges = getOr([], ['includedCharges'], record);
            return (
              <>
                <StyledTitle active={active}>{title}</StyledTitle>
                {!isEmpty(includedCharges) && (
                  <Tooltip
                    maxWidth="300px"
                    title={includedCharges.map((item: string) => <p key={`shipmentOperation_${item}`} style={{ margin: '0 3px 0 0', textTransform: 'capitalize' }}>{item}</p>)}
                    placement="top"
                  >
                    <InfoCircleOutlined style={{ marginLeft: '7px', cursor: 'pointer' }} />
                  </Tooltip>
                )}
              </>
            );
          }}
        />
        <Column
          title={t('Unit')}
          dataIndex="unitType"
          key="unitType"
          width="12%"
          render={(title: string, record: ChargeViewDtm | {}) => {
            const active = get(['active'], record);
            return <CustomUnit active={active}>{t(`createInvoiceTable.${measureByConst[title as keyof typeof measureByConst]}`)}</CustomUnit>;
          }}
        />
      </ColumnGroup>
      <ColumnGroup title={<SubHeader>{t('payables')}</SubHeader>}>
        <Column
          title={t('costPerUnit')}
          dataIndex="buyCostPerUnit"
          key="buyCostPerUnit"
          width="10%"
          align={'right' as 'right'}
          render={renderSum}
        />
        <Column
          title={t('QTY')}
          dataIndex="buyQuantity"
          key="buyQuantity"
          width="4%"
          align={'right' as 'right'}
          render={renderWithActive}
        />
        <Column
          title={t('Buy')}
          dataIndex="buyTotalCost"
          key="buyTotalCost"
          width="9%"
          align={'right' as 'right'}
          render={renderSum}
        />
        <Column
          title={t('Posted')}
          dataIndex="posted"
          key="posted"
          width="8%"
          align="center"
          render={(value: statuses) => matchedIcon[value]}
        />
      </ColumnGroup>
      <ColumnGroup title={<SubHeader>{t('receivables')}</SubHeader>}>
        <Column
          title={t('costPerUnit')}
          dataIndex="costPerUnit"
          key="costPerUnit"
          width="10%"
          align={'right' as 'right'}
          render={renderSum}
        />
        <Column
          title={t('QTY')}
          dataIndex="quantity"
          key="quantity"
          width="4%"
          align={'right' as 'right'}
          render={renderWithActive}
        />
        <Column
          title={t('sell')}
          dataIndex="totalCost"
          key="totalCost"
          width="9%"
          align={'right' as 'right'}
          render={renderSum}
        />
        <Column
          title={t('Invoiced')}
          dataIndex="invoiced"
          key="invoiced"
          width="5%"
          align="center"
          render={(value: statuses) => matchedIcon[value]}
        />
      </ColumnGroup>
      <ColumnGroup align="right">
        <Column
          title={t('shipmentOperation.transportation.profit')}
          dataIndex="profit"
          key="profit"
          width="10%"
          align={'right' as 'right'}
          render={renderProfit}
        />
      </ColumnGroup>
    </>
  ), [openedKeys, isPercentage, mode]);

  const columns = useMemo(() => (
    <>
      <Column
        title={t('shipmentOperation.transportation.service')}
        dataIndex="description"
        key="description"
        width="24%"
        render={(title: string, record: ChargeViewDtm | {}) => {
          const active = get(['active'], record);
          const key = get(['key'], record);
          const history = getOr([], ['history'], record);
          const includedCharges = getOr([], ['includedCharges'], record);
          const hasHistory = history.length > 0;
          return (
            <StyledType active={active} onClick={hasHistory ? () => onOpenHistory(key) : noop}>
              {hasHistory && <StyledExpandIcon isActive={openedKeys.includes(key)} />}
              <StyledTitle active={active}>
                {title}
              </StyledTitle>
              {!isEmpty(includedCharges) && (
                <Tooltip
                  maxWidth="300px"
                  title={includedCharges.map((item: string) => <p key={`shipmentOperation_columns_${item}`} style={{ margin: '0 3px 0 0', textTransform: 'capitalize' }}>{item}</p>)}
                  placement="top"
                >
                  <InfoCircleOutlined style={{ marginLeft: '7px', cursor: 'pointer' }} />
                </Tooltip>
              )}
            </StyledType>
          );
        }}
      />
      <Column
        title={t('Unit')}
        dataIndex="unitType"
        key="unitType"
        width="19%"
        align={'right' as 'right'}
        render={(title: string, record: ChargeViewDtm | {}) => {
          const active = get(['active'], record);
          return <CustomUnit active={active}>{t(`createInvoiceTable.${measureByConst[title as keyof typeof measureByConst]}`)}</CustomUnit>;
        }}
      />
      <Column
        title={t('costPerUnit')}
        dataIndex="buyCostPerUnit"
        key="buyCostPerUnit"
        width="19%"
        align={'right' as 'right'}
        render={(val: number) => `$ ${normalizationCost(val, { thousandthSeparatorComma: true, toFixed: 2 })}`}
      />
      <Column
        title={t('QTY')}
        dataIndex="buyQuantity"
        key="buyQuantity"
        width="19%"
        align={'right' as 'right'}
      />
      <Column
        title={t('total')}
        dataIndex="buyTotalCost"
        key="buyTotalCost"
        width="19%"
        align={'right' as 'right'}
        render={(val: number) => `$ ${normalizationCost(val, { thousandthSeparatorComma: true, toFixed: 2 })}`}
      />
    </>
  ), [openedKeys, isPercentage, mode]);

  if (mode === CUSTOMER_VIEW) {
    return { columns };
  }

  return { columns: permissions.shipmentChargesAvailability === PermissionAttributePolicy.WRITE ? fullColumns : columns };
};

export { useTable };
