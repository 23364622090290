import React, {
  FC, memo, useCallback, useMemo, useRef, useState,
} from 'react';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import debounce from 'lodash/debounce';
import throttle from 'lodash/throttle';

import {
  VerticalFormItemSvg,
} from 'app-wrapper/view/icons';
import { CollapseOnChangeHandler } from 'app-wrapper/hooks';
import {
  Collapse, Panel, Tooltip,
} from 'app-wrapper/view/components';
import themesColors from 'app-wrapper/view/themes/themesColors';
import { ENotificationCriticality, RouteNames } from 'app-wrapper/constants';

import { sanitizeHtml } from 'app-wrapper/utils/sanitizeHtml';
import { CommandCenterComponentTaskDTM } from 'app-wrapper/models/dtm';
import {
  CCContentTableNotificationContentReceivedDate,
  CCContentTableNotificationContentLink,
  CCContentTableNotificationContentExpand,
  CCContentTableNotificationContentItem,
  CCContentTableNotificationContentItemBottom,
  CCContentTableNotificationContentItemTop,
  CCContentTableNotificationContentPriority,
  CCContentTableNotificationContentTitle,
  CCContentTableNotificationContentModule,
  CCContentTableNotificationContentItemBottomDescription,
  CCSpanEllipsis,
  TextLink,
  CCContentTableNotificationContentPriorityViewed,
  CCContentTableNotificationContentPriorityBorder,
} from './ContentTableNotification.styled';

interface IContentTableNotificationItemProps {
  onHoverNotificationItem: (id: string, shipmentId?: string) => void;
  onUnviewedNotificationItem: (notificationId: string, viewId: string, shipmentId?: string) => void;
  shipmentId?: string;
  item: CommandCenterComponentTaskDTM
}

const debounceTime = 50;
const throttleTime = 50;

const ContentTableNotificationItemComponent: FC<IContentTableNotificationItemProps> = (props) => {
  const {
    onHoverNotificationItem,
    onUnviewedNotificationItem,
    shipmentId,
    item,
  } = props;

  const navigate = useNavigate();
  const { t } = useTranslation();

  const endVisible = useRef<HTMLElement>(null);

  const [
    changeIsCollapseState, setChangeIsCollapseState,
  ] = useState<Array<{
    id: string;
    activeKey: string[];
  }>>([]);

  const changeErrorIsCollapseCallback = useCallback(
    (id: string) => () => {
      setChangeIsCollapseState((prev) => {
        let isFindId = false;

        const newState = prev.map((_item) => {
          if (_item.id === id) {
            isFindId = true;

            return {
              id,
              activeKey: _item.activeKey?.length ? [] : ['1'],
            };
          }

          return _item;
        });

        if (!isFindId) {
          newState.push({
            id,
            activeKey: ['1'],
          });
        }

        return newState;
      });
    },
    [],
  );
  const collapseOnChangeHandler = useCallback(
    (id: string) => CollapseOnChangeHandler(
      endVisible.current, changeErrorIsCollapseCallback(id),
    ),
    [],
  );

  const onMouseEnterNotificationItem = useCallback(
    (index: string) => {
      onHoverNotificationItem(index, shipmentId);
    },
    [onHoverNotificationItem],
  );

  const debounceOnFocusItem = useMemo(() => debounce((index: string) => {
    onMouseEnterNotificationItem(index);
  }, debounceTime), [onMouseEnterNotificationItem]);
  const throttledOnFocusItem = useMemo(() => throttle((index: string) => {
    debounceOnFocusItem(index);
  }, throttleTime), [debounceOnFocusItem]);

  const onMouseEnterNotificationItemHandler = useCallback(
    (index?: string, isViewed?: boolean) => () => {
      if (!index || isViewed) return;
      throttledOnFocusItem(index);
    },
    [],
  );

  const debounceUnviewedNotification = useMemo(() => debounce((notificationId: string, viewId: string) => {
    onUnviewedNotificationItem(notificationId, viewId, shipmentId);
  }, debounceTime), [onMouseEnterNotificationItem, shipmentId]);
  const throttledUnviewedNotification = useMemo(() => throttle((notificationId: string, viewId: string) => {
    debounceUnviewedNotification(notificationId, viewId);
  }, throttleTime), [debounceUnviewedNotification]);

  const onUnviewedNotificationItemHandler = useCallback(
    (notificationId?: string, viewId?: string, isViewed?: boolean) => () => {
      if (!notificationId || !viewId || !isViewed) return;
      throttledUnviewedNotification(notificationId, viewId);
    },
    [],
  );

  const handleGoToShipment = useCallback((id?: number) => {
    if (id) {
      navigate(RouteNames.SHIPMENT_OVERVIEW(id));
    }
  }, [navigate]);

  const getPopupContainer = useCallback(() => document.body, [navigate]);

  return (
    <CCContentTableNotificationContentItem
      key={item.customId}
    >
      <CCContentTableNotificationContentItemTop>
        <CCContentTableNotificationContentExpand
          onClick={item.description ? changeErrorIsCollapseCallback(item.customId || item.id) : undefined}
          isEmpty={!!item.description}
        >
          {!!item.description && (
            <VerticalFormItemSvg
              rotate={changeIsCollapseState.filter((itemCollapse) => itemCollapse.id === item.customId)?.[0]?.activeKey.length ? 0 : -90}
              style={{
                color: changeIsCollapseState.filter((itemCollapse) => itemCollapse.id === item.customId)?.[0]?.activeKey.length ? themesColors.primaryBranding6 : themesColors.secondaryDot45,
              }}
            />
          )}
        </CCContentTableNotificationContentExpand>
        <CCContentTableNotificationContentPriority>
          {!item.isViewed ? (
            <Tooltip
              placement="top"
              title={t('Mark as Read')}
              getPopupContainer={getPopupContainer}
            >
              <CCContentTableNotificationContentPriorityBorder
                isWarning={!item.isViewed && item.priority === ENotificationCriticality.HIGH}
                isActive={!item.isViewed}
                onClick={onMouseEnterNotificationItemHandler(item.id, item.isViewed)}
              >
                <CCContentTableNotificationContentPriorityViewed
                  isWarning={!item.isViewed && item.priority === ENotificationCriticality.HIGH}
                  isActive={!item.isViewed}
                />
              </CCContentTableNotificationContentPriorityBorder>
            </Tooltip>
          ) : (
            <Tooltip
              placement="top"
              title={t('Mark as Unread')}
              getPopupContainer={getPopupContainer}
            >
              <CCContentTableNotificationContentPriorityBorder
                isWarning={!item.isViewed && item.priority === ENotificationCriticality.HIGH}
                isActive={!item.isViewed}
              >
                <CCContentTableNotificationContentPriorityViewed
                  onClick={onUnviewedNotificationItemHandler(item.id, item.viewedId, item.isViewed)}
                  isWarning={!item.isViewed && item.priority === ENotificationCriticality.HIGH}
                  isActive={!item.isViewed}
                />
              </CCContentTableNotificationContentPriorityBorder>
            </Tooltip>
          )}
        </CCContentTableNotificationContentPriority>

        <CCContentTableNotificationContentTitle isActive={!!changeIsCollapseState.filter((itemCollapse) => itemCollapse.id === item.customId)?.[0]?.activeKey.length}>
          <CCSpanEllipsis
            dangerouslySetInnerHTML={{ __html: sanitizeHtml(item.title || '') }}
          />
        </CCContentTableNotificationContentTitle>
        <CCContentTableNotificationContentReceivedDate>
          {item.dueDate?.getFormatDMMMHHmm()}
        </CCContentTableNotificationContentReceivedDate>
        <CCContentTableNotificationContentLink>
          <TextLink onClick={() => handleGoToShipment(item.shipmentId)}>
            {item.shipmentName || ''}
          </TextLink>
        </CCContentTableNotificationContentLink>
        <CCContentTableNotificationContentModule>
          <CCSpanEllipsis>
            {item.module}
          </CCSpanEllipsis>
        </CCContentTableNotificationContentModule>
      </CCContentTableNotificationContentItemTop>

      <CCContentTableNotificationContentItemBottom>
        <Collapse
          onChange={collapseOnChangeHandler(item.customId || item.id)}
          activeKey={changeIsCollapseState.filter((itemCollapse) => itemCollapse.id === item.customId)?.[0]?.activeKey || []}
        >
          <Panel header="" key="1" extra={null}>
            <CCContentTableNotificationContentItemBottomDescription
              dangerouslySetInnerHTML={{ __html: sanitizeHtml(item.description || '') }}
            />
          </Panel>
        </Collapse>
      </CCContentTableNotificationContentItemBottom>
    </CCContentTableNotificationContentItem>
  );
};

const ContentTableNotificationItemCache = memo(ContentTableNotificationItemComponent);

export { ContentTableNotificationItemCache as ContentTableNotificationItem };
