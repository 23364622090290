import styled from 'styled-components';
import Badge from 'antd/es/badge';

export const ShipmentPartyCardTitle = styled.span`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  font-weight: 500;
  font-size: 14px;
  color: black;
`;

export const UnalignedBadge = styled(Badge)`
  > * { top: -4px !important; }
`;
