import React from 'react';

import { IPropsIcon } from 'app-wrapper/interfaces/icon';

import { Icon } from './VerticalFormItemSvg.styled';

export const VerticalFormItemSvg = (props: IPropsIcon) => {
  const {
    width, height, style, rotate,
  } = props;

  const styles = {
    ...style,
  };

  return (
    <Icon
      className="VerticalFormItemSvg"
      width={width || '8'}
      height={height || '5'}
      viewBox="0 0 8 5"
      style={styles || undefined}
      rotate={rotate}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 1L4 4L7 1"
        stroke={style?.color || '#99A3B5'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
};
