import styled from 'styled-components';
import Typography from 'antd/es/typography';

import { DatePicker } from 'app-wrapper/view/components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Text = styled(Typography)`
  margin-right: 8px;
  width: 36px;
  font-size: 12px;
  line-height: 20px;
  text-align: end;
`;

export const DatePickerStyled = styled(DatePicker)`
  width: 290px;
  height: 32px;
  min-height: 32px;

  > .ant-picker-input {
    > input {
      font-size: 12px;
    }
  }
`;

export const DatePickerError = styled(DatePickerStyled)`
  &.ant-picker {
    border: 1px solid red;
  }
`;
