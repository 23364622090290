import React from 'react';
import { useSelector } from 'react-redux';

import { R } from 'user-management/repository';

import { PartnersInformationComponent } from './PartnersInformation.component';

export const PartnersInformation = React.memo(() => {
  const adminInfo = useSelector(R.selectors.newOrganizationWizardReviewAndConfirm.getCurrentOrgRelatedAdmin);
  const relatedPartnerInfo = useSelector(R.selectors.newOrganizationWizardReviewAndConfirm.getCurrentOrgRelatedPartner);

  return (
    <PartnersInformationComponent
      adminInfo={adminInfo}
      relatedPartnerInfo={relatedPartnerInfo}
    />
  );
});
