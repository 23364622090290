import React from 'react';
import { useSelector } from 'react-redux';

import { R } from 'user-management/repository';

import { RequiredWarningComponent } from './RequiredWarning.component';

const RequiredWarningContainer = () => {
  const isBLICDocumentRequired = useSelector(R.selectors.newOrganizationDocuments.getShouldRequireBLICDocument);
  const isRequiredWarningShown = useSelector(R.selectors.newOrganizationDocuments.getIsRequiredWarningShown);

  return (
    <RequiredWarningComponent isBLICDocumentRequired={isBLICDocumentRequired} isWarningShown={isRequiredWarningShown} />
  );
};

export { RequiredWarningContainer as RequiredWarning };
