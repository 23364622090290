import styled from 'styled-components';
import Typography from 'antd/es/typography';

export const GeneralInfo = styled.div`
  width: 100%;
  padding: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const Column = styled.div`
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const DateColumn = styled(Column)`
  margin-top: auto;
`;

export const TotalColumn = styled(Column)``;

export const Title = styled(Typography)`
  font-size: 22.8px;
  font-weight: 600;
  line-height: 33.6px;
  margin-bottom: 4.8px;
`;

export const Text = styled(Typography)`
  font-size: 12px;
  line-height: 19.2px;
`;

export const CompanyName = styled(Text)`
  font-weight: 700;
`;

export const TextBold = styled(Text)`
  margin-left: 4.8px;
  font-weight: 700;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

export const TotalRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 4.8px;
`;

export const TotalAmount = styled(Typography)`
  min-width: fit-content;
  font-size: 22.8px;
  line-height: 33.6px;
  color: rgba(32, 44, 60, 1);
`;

export const TotalTitle = styled(TotalAmount)`
  width: 100%;
  font-weight: 600;
`;

export const VATTitle = styled.span`
  font-size: 10px;
  line-height: 16px;
`;

export const VATValue = styled(VATTitle)`
  font-weight: 700;
`;

export const VATRow = styled(TotalRow)`
  padding-bottom: 4px;
  border-bottom: 0.5px solid ${({ theme }) => theme.themesColors.neutralBranding6};
`;
