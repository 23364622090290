import styled from 'styled-components';

export const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
  width: 260px;
  height: 40px;

  button {
    width: 120px;
  }
`;
