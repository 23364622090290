import React from 'react';
import Lottie from 'lottie-react';

import skypacePreloader from './skypacePreloader.json';

interface ISkypaceSpinProps {
  loop?: boolean
}

export const SkypaceSpin = (props: ISkypaceSpinProps) => {
  const {
    loop = true,
  } = props;

  return (
    <Lottie
      animationData={skypacePreloader}
      loop={loop}
    />
  );
};
