import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'app-wrapper/createSlice';

import { CommodityDTM } from 'shipment-operations/models/dtm';
import { ICommodityState } from 'shipment-operations/models/states';

const initialState: ICommodityState = {
  isLoading: false,
  commodities: [],
};

export const commodityStore = createSlice({
  name: 'commodity',
  initialState,
  reducers: {
    clearCommodities: (state) => {
      state.commodities = [];
    },
    setQuery: (state, action: PayloadAction<string>) => {
      state.query = action.payload;
    },
    setCommodities: (state, action: PayloadAction<CommodityDTM[]>) => {
      state.commodities = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setStatusCommoditiesRequest: (state, action: PayloadAction<string>) => {
      state.statusCommoditiesRequest = action.payload;
    },
  },
});

export const commodityActions = commodityStore.actions;
export const commodityReducer = commodityStore.reducer;
