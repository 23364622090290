import {
  IsDefined, IsOptional, IsString,
} from 'class-validator';
import { BaseDTM } from 'proto/BaseDTM';

export interface IAddressDTM {
  country: string
  state?: string
  city: string
  address1: string
  address2?: string
  postalCode: string
  closestPort?: string
}

export class AddressDTM extends BaseDTM<IAddressDTM> {
  @IsDefined()
  @IsString()
  country: string;

  @IsOptional()
  @IsString()
  state?: string;

  @IsDefined()
  @IsString()
  city: string;

  @IsDefined()
  @IsString()
  address1: string;

  @IsOptional()
  @IsString()
  address2?: string;

  @IsDefined()
  @IsString()
  postalCode: string;

  @IsOptional()
  @IsString()
  closestPort?: string;

  getAddressLine = () => {
    let addressLine = this.city;
    if (this.state) {
      addressLine += `, ${this.state}`;
    }
    addressLine += `, ${this.country}, ${this.address1}, ${this.postalCode}`;

    return addressLine;
  }
}
