import { PayloadAction } from '@reduxjs/toolkit';

import { TRatesState } from 'monetary/models/states';
import { RatesListDtm } from 'monetary/models/dtm';
import { createSlice } from 'app-wrapper/createSlice';

const initialState: TRatesState = {
  isLoading: false,
  ratesList: undefined,
};

export const ratesSlice = createSlice({
  name: 'rates',
  initialState,
  reducers: {
    setIsLoading: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      isLoading: payload,
    }),
    setFileSend: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      fileSend: payload,
    }),
    setRatesList: (state, { payload }: PayloadAction<RatesListDtm>) => ({
      ...state,
      ratesList: payload,
    }),
  },
});

export const ratesActions = ratesSlice.actions;
export const ratesReducer = ratesSlice.reducer;
