import React from 'react';
import { useSelector } from 'react-redux';

import { R } from 'user-management/repository';

import { OrganizationWarningComponent } from './OrganizationWarning.component';

const OrganizationWarningContainer = () => {
  const organization = useSelector(R.selectors.userOrganizationData.getUserOrganization);

  return (
    <OrganizationWarningComponent organization={organization} />
  );
};

export { OrganizationWarningContainer as OrganizationWarning };
