import { createSelector } from 'reselect';

import { RootState } from 'app-wrapper/store';

const localState = (state: RootState) => state.userInvite;

const getLoading = createSelector(
  localState,
  (state) => state.isLoading,
);

const getEmail = createSelector(
  localState,
  (state) => state.email,
);

const getEmailError = createSelector(
  localState,
  (state) => state.emailError,
);

const getRole = createSelector(
  localState,
  (state) => state.role,
);

const getRoleError = createSelector(
  localState,
  (state) => state.roleError,
);

const wasAnyFormFieldChanged = createSelector(
  localState,
  (state) => (
    !state.email
    || !state.role
    || !!state.emailError
    || !!state.roleError
  ),
);

const getDepartments = createSelector(
  localState,
  (state) => state.departments,
);

const getTeams = createSelector(
  localState,
  (state) => state.teams,
);

const getDepartmentsError = createSelector(
  localState,
  (state) => state.departmentsError,
);

const getAvailableTeams = createSelector(
  localState,
  (state) => state.availableTeams,
);

const getAvailableDepartments = createSelector(
  localState,
  (state) => state.availableDepartments,
);

const getJobTitle = createSelector(
  localState,
  (state) => state.jobTitle,
);

export const userInviteSelectors = {
  getEmail,
  getEmailError,
  getRole,
  getRoleError,
  getLoading,
  wasAnyFormFieldChanged,
  getDepartments,
  getTeams,
  getDepartmentsError,
  getAvailableTeams,
  getAvailableDepartments,
  getJobTitle,
};
