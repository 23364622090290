import React, { FC } from 'react';

import { FieldErrors } from 'react-hook-form';

import { Errors } from 'app-wrapper/view/components';
import { InputController, IInputControllerProps } from 'app-wrapper/view/controllers/InputController';

type InputFieldControllerProps = IInputControllerProps & {
  errors: FieldErrors
}

const InputFieldController: FC<InputFieldControllerProps> = ({
  name,
  errors,
  ...other
}) => (
  <>
    <InputController name={name} {...other} />
    <Errors name={name} errors={errors} />
  </>
);

export default InputFieldController;
