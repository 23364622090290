import React, {
  FC, useCallback, useEffect, useRef, useState
  ,
} from 'react';
import isEmpty from 'lodash/fp/isEmpty';
import Typography from 'antd/es/typography';
import { useTranslation } from 'react-i18next';
import Spin from 'antd/es/spin';
import { ReloadOutlined } from '@ant-design/icons';
import filter from 'lodash/fp/filter';
import { DataNode } from 'antd/es/tree';

import { Col } from 'app-wrapper/view/components/Col/Col';
import { Collapse } from 'app-wrapper/view/components/Collapse/Collapse';
import { Panel, ExpandIcon, Button } from 'app-wrapper/view/components';
import { Row } from 'app-wrapper/view/components/Row/Row';
import { keyPrefix } from 'app-wrapper/utils/prepareDataForTree';
import themesColors from 'app-wrapper/view/themes/themesColors';
import { useCollapseOnChangeHandler } from 'app-wrapper/hooks';
import { REQUEST_STATUS } from 'app-wrapper/constants';

import { CheckedKeysValues } from 'monetary/view/components/RFRFormRequest/Services/Services.container';

import { ErrorWrapper, StyledCheckboxesTree } from './Services.styled';

type Key = string | number

interface IProps {
  onCheck: (checkedKeysValue: string[]) => void
  reloadData: () => void
  isAllDisabled: boolean
  isReuseRequest: boolean
  defaultCheckedKeys?: Key[] | string[]
  defaultExpandAll?: boolean
  // new
  requestStatus?: string
  serviceStatus?: string
  treeData?: DataNode[]
  defaultExpandedKeys?: Key[]
  defaultKeys?: Key[]
}

const { Text } = Typography;

const StyledExpandIcon = (props: any) => {
  const { isActive } = props;
  return (<ExpandIcon color={isActive && themesColors.primaryBranding6} isActive={isActive} />);
};

export const ServicesComponent: FC<IProps> = ((props) => {
  const {
    onCheck,
    reloadData,
    isAllDisabled,
    isReuseRequest,
    defaultCheckedKeys,
    defaultExpandAll,
    requestStatus,
    serviceStatus,
    treeData,
    defaultExpandedKeys,
    defaultKeys,
  } = props;

  const { t } = useTranslation();
  const endVisible = useRef<HTMLElement>(null);

  const [checkedKeys, setCheckedKeys] = useState<Key[]>([]);
  const [activeKey, setActiveKey] = useState<string[]>([]);
  const [expandKeys, setExpandKeys] = useState<Key[]>([]);

  const togglePanel = useCallback(
    () => {
      setActiveKey((prev) => (prev.length ? [] : ['1']));
    },
    [],
  );

  const collapseOnChangeHandler = useCollapseOnChangeHandler(endVisible.current, togglePanel);

  useEffect(() => {
    if (requestStatus === REQUEST_STATUS.pending) {
      if (defaultCheckedKeys?.length) {
        setActiveKey(['1']);
        setCheckedKeys(defaultCheckedKeys);
        const expandKeysAfterRequest: Key[] = [];

        treeData?.forEach((itemTree) => {
          if (itemTree.children) {
            itemTree.children.forEach((itemTreeChildren) => {
              if (itemTreeChildren.children) {
                itemTreeChildren.children.forEach((itemTreeChildrenSecond) => {
                  defaultCheckedKeys.forEach((itemChecked) => {
                    if (typeof itemChecked === 'string' && itemChecked.includes(`${itemTreeChildrenSecond.key}`)) {
                      if (!expandKeysAfterRequest.includes(itemTree.key)) {
                        expandKeysAfterRequest.push(itemTree.key);
                      }
                      if (!expandKeysAfterRequest.includes(itemTreeChildren.key)) {
                        expandKeysAfterRequest.push(itemTreeChildren.key);
                      }
                    }
                  });
                });
              }
            });
          }
        });

        setExpandKeys(expandKeysAfterRequest);
        return;
      }

      setActiveKey([]);
      setExpandKeys([]);
    }
    if (isReuseRequest) {
      if (defaultCheckedKeys?.length) {
        setActiveKey(['1']);
        setCheckedKeys(defaultCheckedKeys);
      }
    }
  }, [requestStatus, defaultCheckedKeys, defaultKeys, isReuseRequest]);

  const onCheckHandler = useCallback(
    (checkedKeysValue: CheckedKeysValues) => {
      const preparedKeysValue = filter(
        (item: string) => !item.startsWith(keyPrefix), checkedKeysValue,
      );

      onCheck(preparedKeysValue);
      setCheckedKeys(preparedKeysValue);
    },
    [keyPrefix, setCheckedKeys],
  );

  const onExpandHandler = useCallback(
    (value: Key[]) => {
      setExpandKeys(value);
    },
    [],
  );

  const showData = serviceStatus === REQUEST_STATUS.complete;

  return (
    <Row className="RFRFormRequest__Row__col__servicesPanel">
      <Col span={24}>
        <Collapse expandIcon={StyledExpandIcon} onChange={collapseOnChangeHandler} activeKey={activeKey}>
          <Panel header={t('Services')} key="1">
            {serviceStatus === REQUEST_STATUS.pending && <Spin />}
            {serviceStatus === REQUEST_STATUS.rejected && (
              <ErrorWrapper>
                <Text type="danger">{t('commonErrorLoadData')}</Text>
                <Button icon={<ReloadOutlined />} shape="circle" onClick={reloadData} />
              </ErrorWrapper>
            )}
            {isEmpty(treeData) && serviceStatus !== REQUEST_STATUS.pending && (
              <Typography.Text>
                {t('emptyAdditionalServices')}
              </Typography.Text>
            )}
            {!isEmpty(treeData) && showData && (
              <StyledCheckboxesTree
                key={`StyledCheckboxesTree_${defaultExpandAll}`}
                treeData={treeData}
                onCheck={onCheckHandler}
                checkedKeys={isAllDisabled ? defaultCheckedKeys : checkedKeys}
                checkable
                disabled={isAllDisabled}
                defaultExpandAll={defaultExpandAll}
                defaultExpandedKeys={defaultExpandedKeys}
                expandedKeys={expandKeys}
                onExpand={onExpandHandler}
              />
            )}
            <span
              id="RFRFormRequest__Row__col__servicesPanel__ref_endVisible"
              ref={endVisible}
            />
          </Panel>
        </Collapse>
      </Col>
    </Row>
  );
});
