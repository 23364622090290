import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'antd/es/skeleton';

import {
  Container,
  ContainerWrap,
  Button,
} from './Footer.styled';

interface FooterComponentProps {
  onRollShipment: (shipmentId: string, planIds: number[]) => void;
  shipmentId: string;
  planIds: number[];
  isLoading: boolean;
}

export const FooterComponent: FC<FooterComponentProps> = ({
  isLoading,
  shipmentId,
  onRollShipment,
  planIds,
}) => {
  const { t } = useTranslation();

  const handleRollShipment = useCallback(() => {
    onRollShipment(shipmentId, planIds);
  }, [onRollShipment, shipmentId, planIds]);

  return (
    <Container>
      {isLoading ? <Skeleton /> : (
        <ContainerWrap>
          <Button
            data-testid="roll-shipment-drawer-submit"
            onClick={handleRollShipment}
          >
            {t('Roll Shipment')}
          </Button>
        </ContainerWrap>
      )}
    </Container>
  );
};
