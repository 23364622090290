import {
  IsDefined, IsString, IsEnum, ValidateNested, IsNumber, IsOptional, IsBoolean,
} from 'class-validator';
import { Type } from 'class-transformer';

import { ERateTypes } from 'monetary/constants';
import { BaseDTM } from 'proto/BaseDTM';

export type TRateChangesDtm = {
  newRecordsNum: number
  updatedRecordsNum: number
  removedRecordsNum: number
};

export class RateChangesDtm extends BaseDTM<TRateChangesDtm> {
  @IsDefined()
  @IsNumber()
  newRecordsNum: number

  @IsDefined()
  @IsNumber()
  updatedRecordsNum: number

  @IsDefined()
  @IsNumber()
  removedRecordsNum: number

  getDataAsString = () => `newRecordsNum: ${this.newRecordsNum}, removedRecordsNum: ${this.removedRecordsNum}, updatedRecordsNum: ${this.updatedRecordsNum}`
}

export type TRateDtm = {
  type: ERateTypes
  templateLink: string
  templateLinkTooltip?: string
  warningMessage?: string
  isErrorMessage?: boolean
};

export class RateDtm extends BaseDTM<TRateDtm> {
  @IsDefined()
  @IsEnum(ERateTypes)
  type: ERateTypes;

  @IsDefined()
  @IsString()
  templateLink: string;

  @IsOptional()
  @IsString()
  templateLinkTooltip?: string;

  @IsOptional()
  @IsString()
  warningMessage?: string;

  @IsOptional()
  @IsBoolean()
  isErrorMessage?: boolean;
}

export type TRatesListDtm = {
  [ERateTypes.OCEAN_BASE_RATE]: RateDtm
  [ERateTypes.OCEAN_SURCHARGES]: RateDtm
  [ERateTypes.SERVICE_RATES]: RateDtm
  [ERateTypes.FEES]: RateDtm
  [ERateTypes.PENALTIES_AND_FREE_TIME]: RateDtm
};

export class RatesListDtm extends BaseDTM<TRatesListDtm> {
  @IsDefined()
  @ValidateNested()
  @Type(() => RateDtm)
  OCEAN_BASE_RATE: RateDtm

  @IsDefined()
  @ValidateNested()
  @Type(() => RateDtm)
  OCEAN_SURCHARGES: RateDtm

  @IsDefined()
  @ValidateNested()
  @Type(() => RateDtm)
  SERVICE_RATES: RateDtm

  @IsDefined()
  @ValidateNested()
  @Type(() => RateDtm)
  FEES: RateDtm

  @IsDefined()
  @ValidateNested()
  @Type(() => RateDtm)
  PENALTIES_AND_FREE_TIME: RateDtm
}
