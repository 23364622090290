import {
  IsBoolean, IsDefined, IsNumber, IsOptional, IsString, ValidateNested, IsArray,
} from 'class-validator';
import { Type } from 'class-transformer';
import { BaseDTM } from 'proto/BaseDTM';

import {
  IMetaData,
  MetaDataDTM,
  IChargeCodeDTM,
  IApplianceRangeDTM,
  IChargesContainerDTM,
  IBudgetDTM,
  IContractDTM,
  ChargeCodeDTM,
  ApplianceRangeDTM,
  ChargesContainerDTM,
  BudgetDTM,
  ContractDTM,
  IChargeCompanyDTM,
  ChargeCompanyDTM,
} from 'shipment-operations/models/dtm';

export interface IShortChargeDTM {
  id: number
  active: boolean
  additional?: boolean
  status?: string
  createdAt: string
  createdBy: string
  designation: string
  measureBy: string
  priceBy: string
  chargeCode: IChargeCodeDTM
  applianceRange?: IApplianceRangeDTM
  subjectTo?: string
  applied: boolean
  currency: string
  costPerUnit: number
  numberOfUnits: number
  totalCost: number
  container?: IChargesContainerDTM
  contract?: IContractDTM
  transportationIds: number[]
  rateId?: number
  description?: string
  invoiced: number
  balance: number
  budget: IBudgetDTM
  documents?: any
  metadata?: IMetaData
  debtor?: IChargeCompanyDTM
  creditor?: IChargeCompanyDTM
}

export class ShortChargeDTM extends BaseDTM<IShortChargeDTM> {
  @IsNumber()
  @IsDefined()
  id: number;

  @IsBoolean()
  @IsDefined()
  active: boolean;

  @IsOptional()
  @IsBoolean()
  additional?: boolean;

  @IsOptional()
  @IsString()
  status?: string;

  @IsString()
  @IsDefined()
  createdAt: string;

  @IsString()
  @IsDefined()
  createdBy: string;

  @IsString()
  @IsDefined()
  designation: string;

  @IsString()
  @IsDefined()
  measureBy: string;

  @IsString()
  @IsDefined()
  priceBy: string;

  @ValidateNested()
  @IsDefined()
  chargeCode: ChargeCodeDTM;

  @IsOptional()
  @ValidateNested()
  applianceRange?: ApplianceRangeDTM;

  @IsOptional()
  @IsString()
  subjectTo?: string;

  @IsBoolean()
  @IsDefined()
  applied: boolean;

  @IsString()
  @IsDefined()
  currency: string;

  @IsNumber()
  @IsDefined()
  costPerUnit: number;

  @IsNumber()
  @IsDefined()
  numberOfUnits: number;

  @IsNumber()
  @IsDefined()
  totalCost: number;

  @IsOptional()
  @ValidateNested()
  container?: ChargesContainerDTM;

  @IsOptional()
  @ValidateNested()
  @Type(() => ContractDTM)
  contract?: ContractDTM;

  @IsArray()
  @IsNumber({}, { each: true })
  @IsDefined()
  transportationIds: number[];

  @IsNumber()
  @IsOptional()
  rateId?: number;

  @IsOptional()
  @IsString()
  description?: string;

  @IsNumber()
  @IsDefined()
  invoiced: number;

  @IsNumber()
  @IsDefined()
  balance: number;

  @ValidateNested()
  @IsDefined()
  budget: BudgetDTM;

  @IsOptional()
  @IsDefined()
  documents: any;

  @ValidateNested()
  @IsOptional()
  metadata?: MetaDataDTM;

  @IsOptional()
  @ValidateNested()
  @Type(() => ChargeCompanyDTM)
  debtor?: ChargeCompanyDTM

  @IsOptional()
  @ValidateNested()
  @Type(() => ChargeCompanyDTM)
  creditor?: ChargeCompanyDTM
}
