import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'app-wrapper/createSlice';

import { IShipmentDrawerState } from 'shipment-operations/models/states';
import { ShipmentDrawerContent } from 'shipment-operations/constants';

const initialState: IShipmentDrawerState = {
  isLoading: false,
  isVisible: false,
};

export const shipmentDrawerStore = createSlice({
  name: 'shipmentDrawer',
  initialState,
  reducers: {
    openDrawer: (state, action: PayloadAction<ShipmentDrawerContent>) => {
      state.isVisible = true;
      state.content = action.payload;
    },
    closeDrawer: (state) => {
      state.isVisible = false;
      state.content = undefined;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
  },
});

export const shipmentDrawerActions = shipmentDrawerStore.actions;

export const shipmentDrawerReducer = shipmentDrawerStore.reducer;
