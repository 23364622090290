import { SizeType } from 'antd/es/config-provider/SizeContext';
import styled from 'styled-components';

type ITwoInputsAndSmallButtonViewProps = {
  size: SizeType
}

const getPxSize = (size: SizeType) => {
  switch (size) {
    case 'large':
      return '40px';
    case 'middle':
      return '32px';
    case 'small':
      return '30px';
    default:
      return '';
  }
};

// TODO: move it to component with implementation
export const TwoInputsAndSmallButtonView = styled.div`
  display: grid;
  grid-template-columns: ${({ size }: ITwoInputsAndSmallButtonViewProps) => (
    `1fr 1fr ${getPxSize(size)}`
  )};
  gap: 4px;
  > button {
    padding: 0;
  }
  & > * {
    font-size: 12px;
  }
`;
