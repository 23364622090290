import AntDrawer from 'antd/es/drawer';
import styled from 'styled-components';

export const ShipmentDrawerWrapper = styled(AntDrawer).attrs({
  'data-class': 'ShipmentDrawerWrapper',
})`
  .ant-drawer-body{
    padding: 0;
  }

  .ant-drawer-header-title {
    flex-direction: row-reverse;
  }

  .ant-drawer-close {
    margin-right: 0;
  }
`;

export const ShipmentDrawerWrapperLarge = styled(ShipmentDrawerWrapper)`
  .ant-drawer-content-wrapper {
    width: 400px !important;
  }
`;
