import React, {
  ChangeEvent, FC, useCallback, useEffect,
} from 'react';
import { useParams } from 'react-router';
import Spinner from 'antd/es/spin';
import { useTranslation } from 'react-i18next';

import { Button } from 'app-wrapper/view/components';
import { ShipmentDrawerWrapper } from 'shipment-operations/view/pages/Shipment/components/ShipmentDrawer/ShipmentDrawer.styled';
import {
  SpinnerWrapper,
  SubTitle,
  TableWrapper,
  Underline,
  Wrapper,
  FooterWrapper,
  CarrierWrapper,
  InputsWrapper,
  InputTitle,
  StyledInput, UploadWrapper, StyledAlert,
} from 'shipment-operations/view/drawers/ShipmentChanges/ShipmentChanges.styled';
import {
  CustomTable, EditableTable,
  TransportPlan, Uploader,
} from 'shipment-operations/view/drawers/ShipmentChanges/components';
import {
  CARGO, CONTAINER, CUTOFF, LOCATIONS,
} from 'shipment-operations/constants';
import { IChangesTable, ITransportPlanChanges } from 'shipment-operations/models/dtm';

interface BookingConfirmationComponentProps {
  loadData: (shipmentId: string) => void;
  withMismatches: boolean;
  savedShipmentId: string;
  loading: boolean;
  onClose: () => void;
  cargoTable: IChangesTable[]
  containersTable: IChangesTable[]
  transportPlanCurrent: ITransportPlanChanges[]
  transportPlanMismatchCurrent: ITransportPlanChanges[]
  cutOffTable: IChangesTable[]
  locationsTable: IChangesTable[]
  onChangeCutOff: (row: IChangesTable) => void;
  onChangeLocation: (row: IChangesTable) => void;
  isActionLoading: boolean;
  onConfirm: (shipmentId: string) => void;
  inttraNumber: string;
  carrierNumber: string;
  onChangeCarrierNumber: (value: string) => void;
  onChangeInttraNumber: (value: string) => void;
  syncIntraNumber: () => void;
  reset: () => void;
}

const BookingConfirmationComponent: FC<BookingConfirmationComponentProps> = ({
  loadData, withMismatches, savedShipmentId, loading, onClose, reset,
  cargoTable, containersTable, transportPlanCurrent, onConfirm, isActionLoading,
  transportPlanMismatchCurrent, cutOffTable, locationsTable, onChangeLocation, onChangeCutOff,
  inttraNumber, carrierNumber, onChangeInttraNumber, onChangeCarrierNumber, syncIntraNumber,
}) => {
  const { t } = useTranslation();
  const { shipmentId } = useParams();
  useEffect(() => {
    syncIntraNumber();
    if (shipmentId || savedShipmentId) {
      loadData(shipmentId || savedShipmentId);
    }
  }, [savedShipmentId]);

  useEffect(() => {
    if (!loading) {
      syncIntraNumber();
    }
  }, [savedShipmentId, loading]);

  useEffect(() => () => reset(), []);

  const handleConfirm = useCallback(() => {
    if (shipmentId || savedShipmentId) {
      onConfirm(shipmentId || savedShipmentId);
    }
  }, [shipmentId]);

  const inttraChangeHandler = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      onChangeInttraNumber(event.target.value);
    },
    [inttraNumber],
  );
  const carrierChangeHandler = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      onChangeCarrierNumber(event.target.value);
    },
    [],
  );

  return (
    <ShipmentDrawerWrapper
      title={withMismatches ? t('Review Carrier Response and Confirm') : 'Review shipment request'}
      visible
      width="1137px"
      closable
      maskClosable={false}
      onClose={onClose}
    >
      <Wrapper>
        {loading ? (
          <SpinnerWrapper>
            <Spinner />
          </SpinnerWrapper>
        ) : (
          <>
            {withMismatches && (
              <StyledAlert
                showIcon
                type="warning"
                description={t('pleaseReviewAmendments')}
              />
            )}
            {withMismatches ? (
              <>
                <CarrierWrapper>
                  <SubTitle>{t('carrier')}</SubTitle>
                  <InputsWrapper>
                    <InputTitle>{t('inttraReferenceNumber')}</InputTitle>
                    <StyledInput
                      style={{ marginRight: '15px', borderColor: inttraNumber ? '' : 'red' }}
                      value={inttraNumber}
                      onChange={inttraChangeHandler}
                    />
                    <InputTitle>{t('carrierReferenceNumber')}</InputTitle>
                    <StyledInput
                      style={{ borderColor: carrierNumber ? '' : 'red' }}
                      value={carrierNumber}
                      onChange={carrierChangeHandler}
                    />
                  </InputsWrapper>
                </CarrierWrapper>
                <UploadWrapper>
                  <SubTitle>{t('bookingConfirmationDocument')}</SubTitle>
                  <Uploader />
                </UploadWrapper>
                <TableWrapper>
                  <SubTitle>{t('cargo')}</SubTitle>
                  <CustomTable
                    data={cargoTable}
                    type={CARGO}
                    withThreeColumns={false}
                    withMismatches={withMismatches}
                    isRequestTheSameWithShipment
                    isCustomer={false}
                  />
                </TableWrapper>
                <TableWrapper>
                  <SubTitle>{t('Containers')}</SubTitle>
                  <CustomTable
                    data={containersTable}
                    type={CONTAINER}
                    withThreeColumns={false}
                    withMismatches={withMismatches}
                    isRequestTheSameWithShipment
                    isCustomer={false}
                  />
                </TableWrapper>
                <TableWrapper>
                  <SubTitle>{t('transportPlan')}</SubTitle>
                  <Underline />
                  <TransportPlan
                    requestedTitle={t('Requested')}
                    requestedData={transportPlanCurrent}
                    mismatchedTitle={t('carrierResponse')}
                    mismatchedData={transportPlanMismatchCurrent}
                    withMismatch
                  />
                </TableWrapper>
                <TableWrapper>
                  <SubTitle>{t('Cutoffs')}</SubTitle>
                  <EditableTable
                    data={cutOffTable}
                    type={CUTOFF}
                    withThreeColumns={false}
                    withMismatches={withMismatches}
                    isRequestTheSameWithShipment
                    onChange={onChangeCutOff}
                    isCustomer={false}
                  />
                </TableWrapper>
                <TableWrapper>
                  <SubTitle>{t('Location')}</SubTitle>
                  <EditableTable
                    data={locationsTable}
                    type={LOCATIONS}
                    withThreeColumns={false}
                    withMismatches={withMismatches}
                    isRequestTheSameWithShipment
                    onChange={onChangeLocation}
                    isCustomer={false}
                  />
                </TableWrapper>
              </>
            ) : (
              <>
                <TableWrapper>
                  <SubTitle>{t('cargo')}</SubTitle>
                  <CustomTable
                    data={cargoTable}
                    type={CARGO}
                    withThreeColumns={false}
                    withMismatches={false}
                    isRequestTheSameWithShipment={false}
                    isCustomer={false}
                    maerskMode
                  />
                </TableWrapper>
                <TableWrapper>
                  <SubTitle>{t('Containers')}</SubTitle>
                  <CustomTable
                    data={containersTable}
                    type={CONTAINER}
                    withThreeColumns={false}
                    withMismatches={false}
                    isRequestTheSameWithShipment={false}
                    isCustomer={false}
                    maerskMode
                  />
                </TableWrapper>
                <TableWrapper>
                  <SubTitle>{t('transportPlan')}</SubTitle>
                  <TransportPlan
                    requestedTitle={t('Requested')}
                    requestedData={transportPlanCurrent}
                  />
                </TableWrapper>
              </>
            )}
          </>
        )}
      </Wrapper>
      {withMismatches && (
        <FooterWrapper>
          <Button loading={isActionLoading} onClick={handleConfirm}>{t('confirmAmendments')}</Button>
        </FooterWrapper>
      )}
    </ShipmentDrawerWrapper>
  );
};

export { BookingConfirmationComponent };
