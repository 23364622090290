import React, { FC, memo } from 'react';
import { useSelector } from 'react-redux';

import { REQUEST_STATUS } from 'app-wrapper/constants';

import { UC } from 'shipment-operations/controllers';
import { R } from 'shipment-operations/repository';

import { ShipmentPartyAddAddressComponent } from './ShipmentPartyAddAddress.component';

const ShipmentPartyAddAddressContainer: FC = () => {
  const contactBookDefault = useSelector(R.selectors.bookingWizard.getContactBookDefault);
  const contactBook = useSelector(R.selectors.bookingWizard.getContactBookUpdate);

  const listIndex = contactBook.activeListTabIndex;

  const companyListState = useSelector(R.selectors.bookingWizard.getContactBookUpdateCompanyListByIds(listIndex));

  const addressesIds = companyListState?.addressesIds || [];
  const contactPersonsIds = companyListState?.contactPersonsIds || [];

  const companyListAddresses = useSelector(R.selectors.bookingWizard.getContactBookUpdateCompanyListAddressesByIds(addressesIds));
  const companyListContactPersons = useSelector(R.selectors.bookingWizard.getContactBookUpdateCompanyListContactPersonsByIds(contactPersonsIds));

  return (
    <ShipmentPartyAddAddressComponent
      companyListAddresses={companyListAddresses}
      companyListContactPersons={companyListContactPersons}
      companyListStateIndex={listIndex}
      isUpdating={contactBookDefault.getManualRequestStatus === REQUEST_STATUS.pending || !companyListState?.isUpdate}
      updateForm={UC.bookingWizardDrawer.onSPAddAddress}
      onDiscard={UC.bookingWizardDrawer.onCancelShipmentPartyAddAddress}
    />
  );
};

const ShipmentPartyAddAddressContainerCache = memo(ShipmentPartyAddAddressContainer);

export { ShipmentPartyAddAddressContainerCache as ShipmentPartyAddAddressPage };
