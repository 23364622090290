import React, { FC } from 'react';

import { UC } from 'user-management/controllers';

import { OrganizationProfileComponent } from './OrganizationProfile.component';

const OrganizationProfileContainer: FC = () => (
  <OrganizationProfileComponent onUpload={UC.organizationProfile.uploadData} />
);

export {
  OrganizationProfileContainer as OrganizationProfile,
};
