import { EShippingPartyTypes } from 'shipment-operations/constants/ShippingParty.enum';

export const SHIPPING_PARTIES_HBL_TAB = [
  // required
  EShippingPartyTypes.HOUSE_SHIPPER,
  EShippingPartyTypes.HOUSE_CONSIGNEE,
  EShippingPartyTypes.HOUSE_NOTIFY_PARTY,
];

export const SHIPPING_PARTIES_MBL_TAB = [
  // required
  EShippingPartyTypes.SHIPPER,
  EShippingPartyTypes.CONSIGNEE,
  EShippingPartyTypes.NOTIFY_PARTY,
];

export const SHIPPING_PARTIES_OTHERS_TAB = [
  EShippingPartyTypes.ULTIMATE_CUSTOMER,
  EShippingPartyTypes.CUSTOMER,
  EShippingPartyTypes.SECOND_NOTIFY_PARTY,
];
