import AntLayout from 'antd/es/layout';
import Typography from 'antd/es/typography';
import styled from 'styled-components';

export const SubPageHeader = styled(AntLayout.Header).attrs({
  'data-class': 'SubPageHeader',
})`
  line-height: 22px;
  height: 35px;
  display: flex;
  align-items: center;
  padding: 0;
  margin-bottom: 10px;

  color: inherit;
  background-color: inherit;
`;

export const SubPageHeaderTitle = styled(Typography.Title).attrs({
  'data-class': 'SubPageHeaderTitle',
  level: 4,
})`
  color: rgba(0, 0, 0, 0.85);
  font-size: 20px;
  line-height: 1.4;
  font-weight: 500 !important;
  margin: 0 !important;
  padding: 0;
`;

export const SubPageHeaderContainer = styled.div.attrs({
  'data-class': 'SubPageHeaderContainer',
})`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  width: 100%;
`;
