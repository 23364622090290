import React, { FC, ReactNode, CSSProperties } from 'react';

import {
  Wrapper, Content, StyledClose, Header, Title, ChildrenWrapper,
} from './CustomModal.styled';

interface ICustomModal {
  onClose: () => void
  title: string
  customId?: string
  headerContent?: ReactNode;
  contentStyles?: CSSProperties;
  childrenWrapperStyles?: CSSProperties;
  footer?: ReactNode
}

const CustomModal: FC<ICustomModal> = ({
  onClose, footer, children, title, customId, headerContent, contentStyles = {}, childrenWrapperStyles = {},
}) => (
  <Wrapper id={customId}>
    <Content
      style={{ ...contentStyles }}
    >
      <div>
        <Header>
          <Title>{title}</Title>
          {headerContent}
          <StyledClose onClick={onClose}>close</StyledClose>
        </Header>
        <ChildrenWrapper style={{ ...childrenWrapperStyles }}>
          {children}
        </ChildrenWrapper>
      </div>
      {footer}
    </Content>
  </Wrapper>
);

export { CustomModal };
