export enum EFreightIncoterms {
  EXW = 'EXW',
  FCA = 'FCA',
  FAS = 'FAS',
  FOB = 'FOB',
  DPU = 'DPU',
  DAP = 'DAP',
  CFR = 'CFR',
  CIF = 'CIF',
}

export enum EFreightIncotermsByImport {
  EXW = 'EXW',
  FCA = 'FCA',
  FAS = 'FAS',
  FOB = 'FOB',
}

export const FreightIncotermsByImportArray = [
  EFreightIncotermsByImport.EXW,
  EFreightIncotermsByImport.FCA,
  EFreightIncotermsByImport.FAS,
  EFreightIncotermsByImport.FOB,
];

export enum EFreightIncotermsByExport {
  DPU = 'DPU',
  DAP = 'DAP',
  CFR = 'CFR',
  CIF = 'CIF',
}

export const FreightIncotermsByExportArray = [
  EFreightIncotermsByExport.DPU,
  EFreightIncotermsByExport.DAP,
  EFreightIncotermsByExport.CFR,
  EFreightIncotermsByExport.CIF,
];
