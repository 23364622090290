export enum PaymentsType {
  COLLECT = 'Collect',
  PREPAID = 'Prepaid',
}

export enum IncotermsType {
  EXW = 'EXW',
  FCA = 'FCA',
  FAS = 'FAS',
  FOB = 'FOB',
  DPU = 'DPU',
  DAP = 'DAP',
  CFR = 'CFR',
  CIF = 'CIF',
}

export enum TradeType {
  IMPORT = 'IMPORT',
  EXPORT = 'EXPORT',
}

export enum ContractOwner {
  ORIGIN = 'ORIGIN',
  DESTINATION = 'DESTINATION',
}
