import styled from 'styled-components';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import Typography from 'antd/es/typography';

export const Warning = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px 24px;
  min-height: 42px;
  height: fit-content;
  background-color: rgba(255, 251, 230, 1);
  border: 1px solid rgba(255, 229, 143, 1);
`;

export const WarningIcon = styled(ExclamationCircleOutlined)`
  margin-top: 2px;
  margin-bottom: auto;
  color: rgba(250, 173, 20, 1);
  font-size: 18px;
`;

export const Content = styled.div`
  width: 100%;
  margin-left: 8px;
  display: flex;
  flex-direction: column;
`;

export const WarningTitle = styled(Typography)`
  font-size: 16px;
  line-height: 24px;
`;

export const WarningText = styled(Typography)`
  margin-top: 4px;
  font-size: 14px;
  line-height: 22px;
`;
