import styled from 'styled-components';
import Typography from 'antd/es/typography';
import Spin from 'antd/es/spin';

import { Button } from 'app-wrapper/view/components';
import { divFlex } from 'app-wrapper/view/themes/mixin';
import { emptyScrollbar } from 'app-wrapper/view/themes/components';

export const Wrap = styled.div`
  ${divFlex}
  ${emptyScrollbar}
  flex-direction: column;
  align-items: center;

  margin-left: auto;
  margin-right: auto;
  height: 100%;
  background-color: ${({ theme }) => theme.themesColors.neutralBranding3};
`;

export const Content = styled.div`
  ${divFlex}
  ${emptyScrollbar}
  flex-direction: column;
  overflow: auto;

  font-weight: 400;
  font-size: 12px;
  color: ${({ theme }) => theme.themesColors.neutralBranding10};
  padding: 16px 24px 0 24px;
  width: 688px;
  height: 100%;
  border: 1px solid ${({ theme }) => theme.themesColors.neutralBranding4};
  background-color: ${({ theme }) => theme.themesColors.neutralBranding1};
`;

export const Title = styled(Typography)`
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  padding-bottom: 16px;
  margin-bottom: 16px;
  border-bottom: 1px dashed rgba(234, 238, 248, 1);
`;

export const Spinner = styled(Spin)`
  margin: auto;
`;

export const Footer = styled.div`
  ${divFlex}
  justify-content: end;
  z-index: 4;
  width: 100%;
  height: 64px;
  bottom: 0px;
  align-items: center;
  padding: 14px 24px;

  background-color: ${({ theme }) => theme.themesColors.neutralBranding1};
  box-shadow: 0px -12px 24px -10px rgba(0,0,0,0.21);
`;

export const NextButton = styled(Button)`
  margin-left: 8px;
`;

export const CancelButton = styled(Button)`
  margin-right: 24px;
`;
