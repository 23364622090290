import styled from 'styled-components';
import Typography from 'antd/es/typography';
import AntdUpload from 'antd/es/upload';
import AntdButton from 'antd/es/button';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const Title = styled(Typography)`
  margin-bottom: 4px;
  font-size: 12px;
  line-height: 20px;
`;

export const Button = styled(AntdButton).attrs((props: {
  hasError: boolean,
}) => ({
  hasError: props.hasError,
  'data-class': 'Button',
}))`
  width: 100%;
  font-size: 12px;
  box-shadow: none;
  color: ${({ theme }) => theme?.themesColors?.secondaryDot45};

  ${({ hasError, theme }) => hasError && `border-color: ${theme.themesColors.characterBrandingDanger} !important; border-style: solid;`};
`;

export const Upload = styled(AntdUpload).attrs(() => ({
  'data-class': 'Upload',
}))`
  width: 100%;
  display: flex;
  flex-direction: column;

  button {
    min-height: 30px;
    height: 30px;
  }

  span.ant-upload-list-item-name {
    color: ${({ theme }) => theme.themesColors.primaryBranding6};
    cursor: pointer;
  }

  .ant-upload-list-item-error .ant-upload-list-item-name {
    color: ${({ theme }) => theme.themesColors.characterBrandingDanger};
  }
`;
