import { plainToInstance, Type } from 'class-transformer';
import {
  IsDefined, ValidateNested,
} from 'class-validator';
import { BaseDTM } from 'proto/BaseDTM';

import { TRFQNewQuotaAvailabilityDTM, RFQNewQuotaAvailabilityDTM } from './RFQNewQuotaAvailability.dtm';
import { RFQAllRequestQuotaAvailabilityDTM, TRFQAllRequestQuotaAvailabilityDTM } from './RFQAllRequestQuotaAvailability.dtm';
import { ShipmentChargesAvailabilityDTM, TShipmentChargesAvailabilityDTM } from './ShipmentCharges.dtm';
import { RatesUploadAvailabilityDTM, TRatesUploadAvailabilityDTM } from './RatesUploadAvailability.dtm';

export type TModuleFunctionalityPermissionsDtm = {
  RFQNewQuotaAvailability: TRFQNewQuotaAvailabilityDTM
  requestAllQuotas: TRFQAllRequestQuotaAvailabilityDTM
  shipmentCharges: TShipmentChargesAvailabilityDTM
  ratesUpload: TRatesUploadAvailabilityDTM
}

export class ModuleFunctionalityPermissionsDtm extends BaseDTM<TModuleFunctionalityPermissionsDtm> {
  @IsDefined()
  @ValidateNested()
  @Type(() => RFQNewQuotaAvailabilityDTM)
  RFQNewQuotaAvailability: RFQNewQuotaAvailabilityDTM

  @IsDefined()
  @ValidateNested()
  @Type(() => RFQAllRequestQuotaAvailabilityDTM)
  requestAllQuotas: RFQAllRequestQuotaAvailabilityDTM

  @IsDefined()
  @ValidateNested()
  @Type(() => ShipmentChargesAvailabilityDTM)
  shipmentCharges: ShipmentChargesAvailabilityDTM

  @IsDefined()
  @ValidateNested()
  @Type(() => RatesUploadAvailabilityDTM)
  ratesUpload: RatesUploadAvailabilityDTM

  static createEmpty = () => plainToInstance(ModuleFunctionalityPermissionsDtm, {
    requestAllQuotas: {
      organizationsListLoad: undefined,
      quotaItemContractName: undefined,
      selfService: undefined,
    },
    RFQNewQuotaAvailability: {
      newQuotaClientAdmin: undefined,
      quotaItemContractName: undefined,
    },
    shipmentCharges: {
      shipmentChargesAvailability: undefined,
    },
  });
}
