import styled from 'styled-components';

import themesColors from 'app-wrapper/view/themes/themesColors';

export const NewOrganizationWizardWrapperScaffoldContainer = styled.div.attrs({
  'data-class': 'NewOrganizationWizardWrapperScaffoldContainer',
})`
  display: grid;
  grid-template-columns: auto 430px;
  grid-template-rows: 56px auto;
  grid-template-areas: 'NewOrganizationWizardWrapperScaffoldHeader NewOrganizationWizardWrapperScaffoldHeader'
  'NewOrganizationWizardWrapperScaffoldContent NewOrganizationWizardWrapperScaffoldAside';

  width: 100%;
  height: calc(100vh - 48px);
  
  background-color: ${themesColors.neutralBranding3};
  
  color: ${themesColors.neutralBranding10}
`;

export const NewOrganizationWizardWrapperScaffoldHeader = styled.div.attrs({
  'data-class': 'NewOrganizationWizardWrapperScaffoldHeader',
})`
  grid-area: NewOrganizationWizardWrapperScaffoldHeader;

  width: 100%;
`;

export const NewOrganizationWizardWrapperScaffoldContent = styled.div.attrs({
  'data-class': 'NewOrganizationWizardWrapperScaffoldContent',
})`
  grid-area: NewOrganizationWizardWrapperScaffoldContent;
  overflow-y: auto;
`;

export const NewOrganizationWizardWrapperScaffoldAside = styled.div.attrs({
  'data-class': 'NewOrganizationWizardWrapperScaffoldAside',
})`
  grid-area: NewOrganizationWizardWrapperScaffoldAside;
`;
