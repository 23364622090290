import styled from 'styled-components';
import { divFlex, RFQItemblockBorder } from './main';

export const RFQItemContent = styled.div`
  ${divFlex}
  ${RFQItemblockBorder}
  background-color: ${({ theme }) => theme.themesColors.neutralBranding2};
  width: 100%;
  height: 32px;
  padding: 0px 16px;
`;

export default RFQItemContent;
