import React, {
  FC, memo, useCallback,
} from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'antd/es/modal';

import { useWindowSize } from 'app-wrapper/hooks';
import { TypeSwitch } from 'app-wrapper/view/components';

import {

  ICompanyAddressDTM,
} from 'user-management/models/dtm';
import { ContactBookAddressItem } from './components';

import {
  CBByIdContentBodyForm,
  CBByIdContentBodyFormFooter,
  CBByIdContentBodyFormWrap,
  CBByIdContentBodyFormWrapper,
} from './ContactBookByIdEditAddress.styled';

interface IContactBookByIdEditAddressComponentProps {
  companyListAddresses?: ICompanyAddressDTM[]
  companyListStateIndex: string
  isUpdating?: boolean
  updateForm: () => void
  onDiscard: () => void
  removeAddress: () => void
  onChangeAddressIsPrimary: (id: string) => (isPrimary: boolean) => void
}

const ContactBookByIdEditAddressComponent: FC<IContactBookByIdEditAddressComponentProps> = (props) => {
  const {
    companyListAddresses,
    companyListStateIndex,
    isUpdating,
    updateForm,
    onDiscard,
    removeAddress,
    onChangeAddressIsPrimary,
  } = props;
  const { t } = useTranslation();
  const largeInput = useWindowSize().isFullMediaWidth;

  const handleClickRemove = useCallback(() => {
    Modal.confirm({
      title: t('Are you sure you want to remove this Address from the company?'),
      okText: 'Yes',
      cancelText: 'No',
      onOk: removeAddress,
    });
  }, [removeAddress, t]);

  return (
    <CBByIdContentBodyFormWrap>
      <CBByIdContentBodyForm>
        <CBByIdContentBodyFormWrapper>

          {companyListAddresses?.map((addressItem, addressIndex) => (
            <ContactBookAddressItem
              key={`ContactBookAddressItem-${addressItem.customId}`}
              largeInput={largeInput}
              isFirst={addressIndex === 0}
              isLast={false}
              addressIndex={addressIndex}
              companyListStateIndex={companyListStateIndex}
              address={addressItem}
              hideDivider
              primaryComponent={(
                <TypeSwitch
                  onChange={onChangeAddressIsPrimary(addressItem?.customId || '')}
                  data-class="ContactBookByIdAddContactPersonAddressItem"
                  rightText={t('Primary')}
                  checkedChildren="On"
                  unCheckedChildren="Off"
                  value={!!addressItem.isPrimary}
                  disabledbg={addressItem?.primaryDefault ? '' : 'true'}
                />
              )}
            />
          ))}

        </CBByIdContentBodyFormWrapper>

      </CBByIdContentBodyForm>

      <CBByIdContentBodyFormFooter
        saveName={t('Save')}
        discardName={t('cancel')}
        hasRemove
        discardChanges={onDiscard}
        updateForm={updateForm}
        removeChanges={handleClickRemove}
        disabledUpdate={isUpdating}
      />
    </CBByIdContentBodyFormWrap>
  );
};

const ContactBookByIdEditAddressComponentCache = memo(ContactBookByIdEditAddressComponent);

export { ContactBookByIdEditAddressComponentCache as ContactBookByIdEditAddressComponent };
