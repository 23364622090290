import React from 'react';

import { useAppSelector } from 'app-wrapper/hooks';
import { R } from 'shipment-operations/repository';
import { UC } from 'shipment-operations/controllers';

import { useSelector } from 'react-redux';
import { ShipmentTransportationChargesComponent } from './ShipmentTransportationCharges.component';

const ShipmentTransportationChargesContainer = () => {
  const isLoading = useAppSelector(R.selectors.shipmentTransportationCharges.getIsLoading);
  const isError = useAppSelector(R.selectors.shipmentTransportationCharges.getIsError);
  const isPercentage = useAppSelector(R.selectors.shipmentTransportationCharges.getIsPercentage);

  const successCreation = useAppSelector(R.selectors.shipmentActiveCharge.getActiveChargeSuccessCreation);
  const successDelete = useAppSelector(R.selectors.shipmentActiveCharge.getIsDeleteSuccess);
  const permissions = useSelector(R.selectors.shipment.getDashboardsPermissions);

  return (
    <ShipmentTransportationChargesComponent
      fetchData={UC.shipmentTransportationCharges.loadData}
      onToggle={UC.shipmentTransportationCharges.togglePercentage}
      closeDrawer={UC.shipmentActiveCharge.closeDrawer}
      onLeave={UC.shipmentTransportationCharges.onLeave}
      onClearSuccess={UC.shipmentActiveCharge.onClearSuccess}
      onClearDelete={UC.shipmentActiveCharge.onClearDelete}
      isLoading={isLoading}
      isError={isError}
      isPercentage={isPercentage}
      successCreation={successCreation}
      successDelete={successDelete}
      permissions={permissions}
    />
  );
};

export { ShipmentTransportationChargesContainer as ShipmentTransportationCharges };
