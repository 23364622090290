import React from 'react';
import { useSelector } from 'react-redux';

import { R } from 'user-management/repository';
import { UC } from 'user-management/controllers';

import { NewOrganizationDocumentsComponent } from './NewOrganizationDocuments.component';

const NewOrganizationDocumentsContainer = () => {
  const isLoading = useSelector(R.selectors.newOrganizationDocuments.getLoading);

  return (
    <NewOrganizationDocumentsComponent
      isLoading={isLoading}
      initData={UC.newOrganizationDocuments.initData}
      goNextStep={UC.newOrganizationDocuments.goNextStep}
      saveStep={UC.newOrganizationDocuments.cancelWizard}
      goPrevStep={UC.newOrganizationDocuments.goPrevStep}
      cancelWizard={UC.newOrganizationDocuments.cancelWizard}
    />
  );
};

export { NewOrganizationDocumentsContainer as NewOrganizationDocuments };
