import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { SkypaceLogo } from 'app-wrapper/view/icons';
import { ESkypaceInformationUK, SkypaceInformationDTM } from 'app-wrapper/constants';

import {
  Header,
  Wrap,
  InfoWrap,
  WrapTitle,
  WrapText,
} from './PDFHeader.styled';

interface InvoicePDFHeaderComponentProps {
  companyName: string;
  company?: SkypaceInformationDTM
}

export const PDFHeaderComponent: FC<InvoicePDFHeaderComponentProps> = ({
  company,
}: InvoicePDFHeaderComponentProps) => {
  const { t } = useTranslation();

  return (
    <Header>
      <SkypaceLogo />

      <Wrap>
        <InfoWrap>
          <WrapTitle>
            {company?.companyName || ESkypaceInformationUK.companyName}
          </WrapTitle>

          <WrapText>
            {company?.companyAddress1 || ESkypaceInformationUK.companyAddress1}<br />
            {company?.companyAddress2 || ESkypaceInformationUK.companyAddress2}
          </WrapText>
        </InfoWrap>

        <InfoWrap>
          <WrapTitle>
            {t('Contact Information')}
          </WrapTitle>

          <WrapText>
            {company?.companyPhone || ESkypaceInformationUK.companyPhone}
          </WrapText>

          <WrapText>
            {company?.companyEmail || ESkypaceInformationUK.companyEmail}
          </WrapText>
        </InfoWrap>
      </Wrap>
    </Header>
  );
};
