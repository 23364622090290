import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Select, Option } from 'app-wrapper/view/components';
import { IBillOfLadingCommonState } from 'shipment-operations/models/states';
import {
  IncotermsType,
  PaymentsType,
  PaymentsTypeNames,
  TradeType,
  TradeTypeNames,
} from 'shipment-operations/constants';

import { Footer } from './components';
import {
  Header,
  LabelGrey,
  Form,
  FormItem,
  FormItemLabel,
  Label,
  LabelPayee,
  SpinnerWrap,
  Spinner,
  LabelRow,
} from './BillOfLadingEditPayments.styled';

interface BillOfLadingEditPaymentsComponentProps {
  formState: IBillOfLadingCommonState['paymentsFormState'];
  setIncotermFormState: (value: IncotermsType) => void;
  originAmount: number;
  freightAmount: number;
  destinationAmount: number;
  originPayeeCompany: string;
  freightPayeeCompany: string;
  destinationPayeeCompany: string;
  tradeType: TradeType;
  isPending: boolean;
  incoterms: IncotermsType[];
}

const SELECT_OPTIONS = [
  {
    value: PaymentsType.PREPAID,
    name: PaymentsTypeNames[PaymentsType.PREPAID],
  },
  {
    value: PaymentsType.COLLECT,
    name: PaymentsTypeNames[PaymentsType.COLLECT],
  },
];

export const BillOfLadingEditPaymentsComponent: FC<BillOfLadingEditPaymentsComponentProps> = ({
  originAmount,
  freightAmount,
  destinationAmount,
  originPayeeCompany,
  freightPayeeCompany,
  destinationPayeeCompany,
  tradeType,
  formState,
  setIncotermFormState,
  isPending,
  incoterms,
}) => {
  const { t } = useTranslation();

  const handleIncotermChange = useCallback((value) => {
    setIncotermFormState(value);
  }, [setIncotermFormState]);

  return (
    <Form>
      {isPending ? (
        <SpinnerWrap>
          <Spinner />
        </SpinnerWrap>
      ) : (
        <>
          <Header>
            <LabelGrey>
              {t('Trade')}
            </LabelGrey>

            <Label>
              {TradeTypeNames[tradeType]}
            </Label>
          </Header>

          <FormItem>
            <FormItemLabel>
              <Label>
                {t('Incoterms')}
              </Label>
            </FormItemLabel>

            <Select
              value={formState.incoterm}
              onChange={handleIncotermChange}
            >
              {incoterms.map((incoterm) => (
                <Option key={incoterm} value={incoterm}>{incoterm}</Option>
              ))}
            </Select>
          </FormItem>

          <FormItem>
            <FormItemLabel>
              <Label>
                {t('Origin')}
              </Label>

              <LabelRow>
                {originPayeeCompany ? (
                  <LabelPayee>
                    {originPayeeCompany}
                  </LabelPayee>
                ) : null}

                <Label>
                  $ {originAmount.toFixed(2)}
                </Label>
              </LabelRow>
            </FormItemLabel>

            <Select
              value={formState.origin}
              disabled
            >
              {SELECT_OPTIONS.map(({ name, value }) => (
                <Option key={value} value={value}>{name}</Option>
              ))}
            </Select>
          </FormItem>

          <FormItem>
            <FormItemLabel>
              <Label>
                {t('Freight')}
              </Label>

              <LabelRow>
                {freightPayeeCompany ? (
                  <LabelPayee>
                    {freightPayeeCompany}
                  </LabelPayee>
                ) : null}

                <Label>
                  $ {freightAmount.toFixed(2)}
                </Label>
              </LabelRow>
            </FormItemLabel>

            <Select
              value={formState.freight}
              disabled
            >
              {SELECT_OPTIONS.map(({ name, value }) => (
                <Option key={value} value={value}>{name}</Option>
              ))}
            </Select>
          </FormItem>

          <FormItem>
            <FormItemLabel>
              <Label>
                {t('Destination')}
              </Label>

              <LabelRow>
                {destinationPayeeCompany ? (
                  <LabelPayee>
                    {destinationPayeeCompany}
                  </LabelPayee>
                ) : null}

                <Label>
                  $ {destinationAmount.toFixed(2)}
                </Label>
              </LabelRow>
            </FormItemLabel>

            <Select
              value={formState.destination}
              disabled
            >
              {SELECT_OPTIONS.map(({ name, value }) => (
                <Option key={value} value={value}>{name}</Option>
              ))}
            </Select>
          </FormItem>

          <Footer />
        </>
      )}
    </Form>
  );
};
