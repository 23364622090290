import i18n from 'app-wrapper/i18n/i18n';

export enum DocumentType {
  MSDS = 'MSDS',
  MSC = 'MSC',
  MISC = 'MISC',
  AES = 'AES',
  IMO = 'IMO',
  CSW = 'CSW',
  DMBL = 'DMBL',
  FMBL = 'FMBL',
  ALO = 'ALO',
  CCI = 'CCI',
  BLIC = 'BLIC',
  TCER = 'TCER',
  FPPI = 'FPPI',
  SLI = 'SLI',
  COMINV = 'COMINV',
  PCKL = 'PCKL',
  USPPI = 'USPPI',
  ISFPOA = 'ISFPOA',
  ISF = 'ISF',
  EX1 = 'EX1',
  EX1POA = 'EX1POA',
}

export const OrganizationDocumentsNames = [
  {
    code: DocumentType.ALO,
    description: 'Bill stating Name & Address of Organization',
  },
  {
    code: DocumentType.CCI,
    description: 'Copy of Company Identity/Business Card',
  },
  {
    code: DocumentType.BLIC,
    description: 'Business License',
  },
  {
    code: DocumentType.TCER,
    description: 'Tax Registration',
  },
  {
    code: DocumentType.MISC,
    description: 'Other',
  },
];

export const ADDITIONAL_SERVICES_DOCUMENTS_NAMES = [
  {
    code: DocumentType.FPPI,
    description: i18n.t('FPPI Document'),
  },
  {
    code: DocumentType.SLI,
    description: i18n.t('SLI Document'),
  },
  {
    code: DocumentType.COMINV,
    description: i18n.t('Commercial Invoice'),
  },
  {
    code: DocumentType.PCKL,
    description: i18n.t('Packing List'),
  },
  {
    code: DocumentType.AES,
    description: i18n.t('Customs Declaration'),
  },
  {
    code: DocumentType.USPPI,
    description: i18n.t('US PPI Document'),
  },
  {
    code: DocumentType.ISFPOA,
    description: i18n.t('Consignee\'s Power of Attorney'),
  },
  {
    code: DocumentType.ISF,
    description: i18n.t('ISF Form'),
  },
  {
    code: DocumentType.EX1,
    description: i18n.t('Customs Declaration'),
  },
  {
    code: DocumentType.EX1POA,
    description: i18n.t('Export Power of Attorney'),
  },
];
