export enum IMOClassType {
  CLASS_2 = 'CLASS_2',
  CLASS_2_1 = 'CLASS_2_1',
  CLASS_2_2 = 'CLASS_2_2',
  CLASS_2_3 = 'CLASS_2_3',
  CLASS_3 = 'CLASS_3',
  CLASS_4 = 'CLASS_4',
  CLASS_4_1 = 'CLASS_4_1',
  CLASS_4_2 = 'CLASS_4_2',
  CLASS_4_3 = 'CLASS_4_3',
  CLASS_5 = 'CLASS_5',
  CLASS_5_1 = 'CLASS_5_1',
  CLASS_5_2 = 'CLASS_5_2',
  CLASS_6 = 'CLASS_6',
  CLASS_6_1 = 'CLASS_6_1',
  CLASS_6_2 = 'CLASS_6_2',
  CLASS_8 = 'CLASS_8',
  CLASS_9 = 'CLASS_9',
}

export const IMOClassTypeNames = {
  [IMOClassType.CLASS_2]: 'Class 2',
  [IMOClassType.CLASS_2_1]: 'Class 2.1',
  [IMOClassType.CLASS_2_2]: 'Class 2.2',
  [IMOClassType.CLASS_2_3]: 'Class 2.3',
  [IMOClassType.CLASS_3]: 'Class 3',
  [IMOClassType.CLASS_4]: 'Class 4',
  [IMOClassType.CLASS_4_1]: 'Class 4.1',
  [IMOClassType.CLASS_4_2]: 'Class 4.2',
  [IMOClassType.CLASS_4_3]: 'Class 4.3',
  [IMOClassType.CLASS_5]: 'Class 5',
  [IMOClassType.CLASS_5_1]: 'Class 5.1',
  [IMOClassType.CLASS_5_2]: 'Class 5.2',
  [IMOClassType.CLASS_6]: 'Class 6',
  [IMOClassType.CLASS_6_1]: 'Class 6.1',
  [IMOClassType.CLASS_6_2]: 'Class 6.2',
  [IMOClassType.CLASS_8]: 'Class 8',
  [IMOClassType.CLASS_9]: 'Class 9',
};

export const IMOClassShortTypeNames = {
  [IMOClassType.CLASS_2]: '2',
  [IMOClassType.CLASS_2_1]: '2.1',
  [IMOClassType.CLASS_2_2]: '2.2',
  [IMOClassType.CLASS_2_3]: '2.3',
  [IMOClassType.CLASS_3]: '3',
  [IMOClassType.CLASS_4]: '4',
  [IMOClassType.CLASS_4_1]: '4.1',
  [IMOClassType.CLASS_4_2]: '4.2',
  [IMOClassType.CLASS_4_3]: '4.3',
  [IMOClassType.CLASS_5]: '5',
  [IMOClassType.CLASS_5_1]: '5.1',
  [IMOClassType.CLASS_5_2]: '5.2',
  [IMOClassType.CLASS_6]: '6',
  [IMOClassType.CLASS_6_1]: '6.1',
  [IMOClassType.CLASS_6_2]: '6.2',
  [IMOClassType.CLASS_8]: '8',
  [IMOClassType.CLASS_9]: '9',
};
