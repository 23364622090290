import styled from 'styled-components';

import Table from 'antd/es/table';

export const TableHeader = styled(Table)`
  .ant-table-tbody {
    display: none;
  }
  .ant-table-thead .ant-table-cell {
    padding-top: 8px;
    padding-bottom: 8px;
    border-bottom: 1px solid #EAEEF8;
    border-top: 1px solid #EAEEF8;
  }
  .ant-table-thead .ant-table-cell {
    color: #73819B;
    font-size: 12px;
    font-weight: 400;
  }
  .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    display: none;
  }
  .ant-table-thead > tr > th {
    background: white;
    border-bottom: none;
  }
  .ant-table-container table > thead > tr:first-child th:last-child,
  .ant-table-tbody .ant-table-cell:last-child {
    padding-right: 0;
  }
  .ant-table-container table > thead > tr:first-child th:first-child {
    padding-left: 0;
  }
`;

export const StyledSection = styled.div`
  background: #F9F9FB;
  border-radius: 3px;
  width: 100%;
  font-weight: 400;
  font-size: 12px;
  color: #73819B;
  height: 24px;
  padding-left: 16px;
  line-height: 24px;
  margin-top: 8px;
`;

export const StyledTable = styled(Table)`
  .ant-table-tbody .ant-table-cell {
    font-size: 14px;
    color: #202C3C;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .ant-table-tbody > tr.ant-table-row:hover > td {
    background: none !important;
  }
  .ant-table-tbody > tr:last-child > .ant-table-cell {
    border-bottom: none;
  }
  .ant-table-container table > thead > tr:first-child th:last-child,
  .ant-table-tbody .ant-table-cell:last-child {
    padding-right: 0;
  }
  .ant-table-tbody > tr >  .ant-table-cell:first-child {
    padding-left: 0;
  }
`;

export const CustomCell = styled.span`
  text-transform: capitalize;
`;

export const TableWithCheckBoxes = styled(StyledTable)`
  .ant-table-tbody .ant-table-cell:first-child {
    padding-left: 16px;
    padding-right: 0;
  }
  .ant-table-tbody .ant-table-cell:nth-child(2) {
    padding-left: 10px;
  }
`;

export const HeaderWithCheckboxes = styled(TableHeader)`
  .ant-table-thead .ant-table-cell .ant-table-selection {
    visibility: hidden;
  }
  .ant-table-thead .ant-table-cell:nth-child(2) {
    padding-left: 8px;
  }
`;
