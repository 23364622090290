import React, { forwardRef } from 'react';
import { ColProps } from 'antd/es/col';

import { ColWrap } from './Col.styled';

export const Col = forwardRef<any, ColProps>((props, ref) => (
  <ColWrap
    className="Col"
    ref={ref as React.RefObject<any>}
    {...props}
  />
));
