import React, { FC } from 'react';

import { Bold } from 'shipment-operations/view/components/HBLDocumentPDF/components';
import { Wrapper } from 'shipment-operations/view/components/HBLDocumentPDF/components/Cell/Cell.styled';

import { Sign, Row } from './ChargeCell.styled';

interface ChargeCellProps {
  label: string
  value: string | number
  sign?: string
}

export const ChargeCell: FC<ChargeCellProps> = ({ label, value, sign }) => (
  <Wrapper justifyContent="flex-end">
    <Row>
      <Bold bottomMargin>{label}</Bold>
      {!!sign && <Sign>{sign}</Sign>}
    </Row>
    <span>{value}</span>
  </Wrapper>
);
