import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { MBLShippingPartyDTM } from 'shipment-operations/models/dtm';
import { NoDataStub } from 'shipment-operations/view/components';

import {
  MBLShippingPartiesItem,
  MBLShippingPartiesItemText,
  MBLShippingPartiesTitle,
  MBLShippingPartiesItemTitle,
  MBLShippingPartiesRow,
  MBLShippingParties,
} from './MBLShippingParties.styled';

const convertShippingParty = ({ company, contact, address }: MBLShippingPartyDTM) => [
  company?.name,
  ...(address?.addresses || []),
  contact?.name,
  contact?.phone,
  contact?.email,
].filter((item) => !!item).map((item) => String(item));

interface MBLShippingPartiesComponentProps {
  shipper?: MBLShippingPartyDTM;
  consignee?: MBLShippingPartyDTM;
  notifyParty?: MBLShippingPartyDTM;
}

export const MBLShippingPartiesComponent: FC<MBLShippingPartiesComponentProps> = ({ shipper, consignee, notifyParty }) => {
  const { t } = useTranslation();

  const shipperInfo = useMemo(() => (shipper ? convertShippingParty(shipper) : undefined), [shipper]);
  const consigneeInfo = useMemo(() => (consignee ? convertShippingParty(consignee) : undefined), [consignee]);
  const notifyPartyInfo = useMemo(() => (notifyParty ? convertShippingParty(notifyParty) : undefined), [notifyParty]);

  return (
    <MBLShippingParties>
      <MBLShippingPartiesTitle>
        {t('Shipping Parties')}
      </MBLShippingPartiesTitle>

      <MBLShippingPartiesRow>
        <MBLShippingPartiesItem>
          <MBLShippingPartiesItemTitle>
            {t('Shipper')}
          </MBLShippingPartiesItemTitle>

          {shipperInfo ? shipperInfo.map((text) => (
            <MBLShippingPartiesItemText key={`MBLShippingPartiesComponent_${text}`}>
              {text}
            </MBLShippingPartiesItemText>
          )) : <NoDataStub />}
        </MBLShippingPartiesItem>

        <MBLShippingPartiesItem>
          <MBLShippingPartiesItemTitle>
            {t('Consignee')}
          </MBLShippingPartiesItemTitle>

          {consigneeInfo ? consigneeInfo.map((text) => (
            <MBLShippingPartiesItemText key={`MBLShippingPartiesComponent_Consignee_${text}`}>
              {text}
            </MBLShippingPartiesItemText>
          )) : <NoDataStub />}
        </MBLShippingPartiesItem>

        <MBLShippingPartiesItem>
          <MBLShippingPartiesItemTitle>
            {t('Notify')}
          </MBLShippingPartiesItemTitle>

          {notifyPartyInfo ? notifyPartyInfo.map((text) => (
            <MBLShippingPartiesItemText key={`MBLShippingPartiesComponent_Notify_${text}`}>
              {text}
            </MBLShippingPartiesItemText>
          )) : <NoDataStub />}
        </MBLShippingPartiesItem>

        <MBLShippingPartiesItem>
          <MBLShippingPartiesItemTitle>
            {t('Forwarder')}
          </MBLShippingPartiesItemTitle>

          <MBLShippingPartiesItemText>
            {`Maxton Shipping Inc.
              3555 Cincinnati Ave., Roclin, CA 95765, USA
              + 1 650 410 8900
              +1 650 410 8902 (Fax)
              info@maxtonshipping.com
              ${t('Contact')}: Uladzimir Nikalaueu
              ${t('Reference')}: GF8934590
              ${t('Tax ID')}: 757034-05
            `}
          </MBLShippingPartiesItemText>
        </MBLShippingPartiesItem>
      </MBLShippingPartiesRow>
    </MBLShippingParties>
  );
};
