import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';
import { UC } from 'shipment-operations/controllers';

import { SealNumberInputComponent } from './SealNumberInput.component';

interface SealNumberInputContainerProps {
  containerId: string;
}

const SealNumberInputContainer: FC<SealNumberInputContainerProps> = ({ containerId }) => {
  const { sealNumber } = useSelector(R.selectors.bookingWizard.getContainerById(containerId));
  const { sealNumber: error } = useSelector(R.selectors.bookingWizard.getContainerErrorsById(containerId));

  return (
    <SealNumberInputComponent
      containerId={containerId}
      value={sealNumber}
      setValue={UC.bookingWizard.setSealNumber}
      error={!!error}
    />
  );
};

export { SealNumberInputContainer as SealNumberInput };
