import styled from 'styled-components';
import { InfoCircleOutlined } from '@ant-design/icons';
import Typography from 'antd/es/typography';
import { divFlex } from 'app-wrapper/view/themes/mixin';

export const Commodity = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 16px 8px 16px;
  background-color: white;
`;

export const CommodityHeader = styled(Typography)`
  width: 100%;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  padding-bottom: 16px;
  margin-bottom: 16px;
  border-bottom: 1px solid #EAEEF8;
`;

export const CommodityHeaderAlert = styled.div.attrs({
  'data-class': 'BookingDrawer_CommodityHeaderAlert',
})`
  ${divFlex}
  width: 100%;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 8px;

  .ant-alert {
    width: 100%;
  }
`;

export const CommodityRowsHeader = styled.div`
  margin-bottom: 4px;
  display: grid;
  gap: 4px;
  grid-template-columns: 255px 255px 95.5px 95.5px 95.5px 95.5px 105px 32px;
`;

export const CommodityRowsHeaderNoHazmat = styled.div`
  margin-bottom: 4px;
  display: grid;
  gap: 4px;
  grid-template-columns: 210px 210px 210px 122.75px 122.75px 122.75px 32px;
`;

export const CommodityRowsHeaderTitle = styled.div`
  width: 100%;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
`;

export const Warning = styled.div`
  display: flex;
  margin-bottom: 8px;
  width: 100%;
  padding: 8px;
  flex-direction: row;
  align-items: center;
  background-color: #FFFBE6;
  border: 1px solid #FFE58F;
  border-radius: 2px;
`;

export const WarningIcon = styled(InfoCircleOutlined)`
  margin-right: 8px;
  color: #FAAD14;
  font-size: 16px;
`;

export const WarningText = styled(Typography)`
  margin-top: 2px;
  font-size: 13px;
  line-height: 22px;
`;
