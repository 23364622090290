import React from 'react';
import { useSelector } from 'react-redux';

import { UC } from 'user-management/controllers';
import { R } from 'user-management/repository';

import { OrganizationMenuItemComponent } from './OrganizationMenuItem.component';

const OrganizationMenuItemContainer = ({ onClosePopover }: { onClosePopover: () => void }) => {
  const organization = useSelector(R.selectors.userOrganizationData.getUserOrganization);
  const isLoading = useSelector(R.selectors.userOrganizationData.isOrganizationsDataLoading);

  return (
    <OrganizationMenuItemComponent
      isLoading={isLoading}
      organization={organization}
      initDataUpload={UC.userMenu.uploadData}
      clear={UC.userMenu.uploadData}
      onClosePopover={onClosePopover}
    />
  );
};

export { OrganizationMenuItemContainer as OrganizationMenuItem };
