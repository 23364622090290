import Card from 'antd/es/card';
import styled from 'styled-components';
import Divider from 'antd/es/divider';
import { NavLink } from 'react-router-dom';

import { divFlex } from 'app-wrapper/view/themes/mixin';

export const ShipmentCardContainer = styled(NavLink).attrs((props: {
  $isClickable?: boolean,
}) => ({
  $isClickable: props.$isClickable,
  'data-class': 'ShipmentCardContainer',
}))`
  ${divFlex}
  padding: 0;

  width: 100%;
  height:  76px;

  border: 1px solid ${({ theme }) => theme.themesColors.neutralBranding4};
  border-radius: 4px;

  background-color: ${({ theme }) => theme.themesColors.neutralBranding1};
  line-height: inherit !important;
  color: ${({ theme }) => theme.themesColors.neutralBranding13} !important;

  ${({ $isClickable }) => !$isClickable && 'cursor: auto;'};
  ${({ $isClickable }) => !$isClickable && 'border: none;'};
  ${({ $isClickable }) => !$isClickable && 'box-shadow: none;'};

  &:hover {
    ${({ $isClickable }) => !$isClickable && 'border: none;'};
    ${({ $isClickable }) => !$isClickable && 'box-shadow: none;'};
  }
`;

export const ShipmentCardSectionShipment = styled.div.attrs((props: {
  $isClickable?: boolean,
}) => ({
  $isClickable: props.$isClickable,
  'data-class': 'ShipmentCardSectionShipment',
}))`
  ${divFlex}
  width: 11.4%;
  padding-left: 16px;

  div[data-class="SCDescriptionsContainerContent"] span[data-class="SCDescriptionsContainerSpanClip"] {
    color: ${({ theme, $isClickable }) => ($isClickable ? theme.themesColors.primaryBranding7 : theme.themesColors.neutralBranding10)};

    ${({ $isClickable }) => !$isClickable && 'font-size: 20px;'};
  }
  ${({ $isClickable }) => !$isClickable && 'padding-left: 0;'};
`;

export const ShipmentCardSectionShipmentReferenceWrap = styled.div.attrs((props: {
  isReferenceHeader: boolean
  'data-class'?: string
}) => ({
  isReferenceHeader: props.isReferenceHeader,
  'data-class': props?.['data-class'] || 'ShipmentCardSectionShipmentReferenceWrap',
}))`
  ${divFlex}
  justify-content: center;
  align-items: center;
  top: 8px;
  position: absolute;

  max-width: 100%;
  padding: 4px;
  height: 22px;
  background-color: ${({ theme }) => theme?.themesColors?.neutralBranding3};
  border: 1px dashed ${({ theme }) => theme?.themesColors?.neutralBranding4};
  ${({ isReferenceHeader }) => !isReferenceHeader && 'border: none; background: none;'};
  ${({ isReferenceHeader }) => !isReferenceHeader && 'padding-left: 0;'};
`;

export const ShipmentCardSectionShipmentReferenceText = styled.div.attrs((props: {
  isReferenceHeader: boolean
  'data-class'?: string
}) => ({
  isReferenceHeader: props.isReferenceHeader,
  'data-class': props?.['data-class'] || 'ShipmentCardSectionShipmentReferenceText',
}))`
  ${divFlex}

  font-weight: 400;
  font-size: 12px;
  color: ${({ theme }) => theme?.themesColors?.neutralBranding10};
  ${({ isReferenceHeader, theme }) => !isReferenceHeader && `color: ${theme?.themesColors?.secondaryDot45}`};
`;

export const ShipmentCardSectionShipmentReferenceIcon = styled.div.attrs({
  'data-class': 'ShipmentCardSectionShipmentReferenceIcon',
})`
  ${divFlex}

  cursor: pointer;
  margin-left: 3px;
  svg {
    color: ${({ theme }) => theme?.themesColors?.secondaryDot45};
  }
`;

export const ShipmentCardSectionCarrier = styled.div.attrs({
  'data-class': 'ShipmentCardSectionCarrier',
})`
  ${divFlex}
  width: 15%;
`;

export const ShipmentCardSectionVerticalDivider = styled.div.attrs({
  'data-class': 'ShipmentCardSectionVerticalDivider',
})`
  ${divFlex}
  width: 0;
  height: 52px;

  margin: auto 0;
  border-left: 1px dashed ${({ theme }) => theme.themesColors.neutralBranding5};
`;

export const ShipmentCardSectionVerticalDividerIcon = styled.div.attrs({
  'data-class': 'ShipmentCardSectionVerticalDividerIcon',
})`
  ${divFlex}
  justify-content: center;
  align-items: center;
  height: 100%;

  margin: 0;
`;

export const ShipmentCardSectionOrigin = styled.div.attrs({
  'data-class': 'ShipmentCardSectionOrigin',
})`
  ${divFlex}
  width: 15%;

  div[data-class="SCDescriptionsContainer"] {
    width: calc(100% - 16px - 16px - 8px);
  }
`;

export const ShipmentCardSectionDestination = styled.div.attrs({
  'data-class': 'ShipmentCardSectionDestination',
})`
  ${divFlex}
  width: 15%;

  div[data-class="SCDescriptionsContainer"] {
    width: calc(100% - 16px - 16px - 8px);
  }
`;

export const ShipmentCardSectionCustomer = styled.div.attrs({
  'data-class': 'ShipmentCardSectionCustomer',
})`
  ${divFlex}
  width: 15%;
`;

export const ShipmentCardSectionTasks = styled.div.attrs({
  'data-class': 'ShipmentCardSectionTasks',
})`
  ${divFlex}
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 6.2%;

  .ant-tooltip-inner {
    margin-top: 8px !important;
  }
`;

export const ShipmentCardSectionTasksIcon = styled.div.attrs((props: {
  colorSvg?: string,
}) => ({
  colorSvg: props.colorSvg,
  'data-class': 'ShipmentCardSectionTasksIcon',
}))`
  ${divFlex}

  svg {
    ${({ colorSvg }) => colorSvg && `color: ${colorSvg}`};
    width: 19px;
    height: 19px;
  }
`;

export const ShipmentCardSectionTasksText = styled.div.attrs((props: {
  colorBg?: string,
  colorText?: string,
}) => ({
  colorBg: props.colorBg,
  colorText: props.colorText,
  'data-class': 'ShipmentCardSectionTasksText',
}))`
  ${divFlex}
  min-width: 23px;
  justify-content: center;

  color: ${({ theme, colorText }) => colorText || theme.themesColors.secondaryDot45};
  font-size: 12px;
  font-weight: 400;

  border-radius: 100px;
  ${({ colorBg }) => colorBg && `background-color: ${colorBg}`};
`;

export const ShipmentCardSectionStatus = styled.div.attrs({
  'data-class': 'ShipmentCardSectionStatus',
})`
  ${divFlex}
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 21.2%;
  padding-right: 16px;
`;

export const ShipmentCardSectionStatusTitle = styled.div.attrs({
  'data-class': 'ShipmentCardSectionStatusTitle',
})`
  ${divFlex}
  justify-content: end;
  align-items: center;

  width: 100%;
`;

export const ShipmentCardSectionStatusTitleSpan = styled.div.attrs({
  'data-class': 'ShipmentCardSectionStatusTitleSpan',
})`
  ${divFlex}
  display: inline;
  max-width: 85%;

  color: ${({ theme }) => theme.themesColors.characterBrandingTitle85};
  font-size: 14px;
  font-weight: 400;

  text-overflow: ellipsis;
  overflow: clip;
  white-space: nowrap;
`;

export const ShipmentCardSectionStatusDescription = styled.div.attrs((props: {
  colorText?: string,
}) => ({
  colorText: props.colorText,
  'data-class': 'ShipmentCardSectionStatusDescription',
}))`
  ${divFlex}
  justify-content: end;
  align-items: center;
  text-overflow: ellipsis;
  display: inline;
  overflow: clip;
  padding-left: 4px;
  text-align: end;

  width: 100%;
  color: ${({ theme, colorText }) => (colorText || theme.themesColors.secondaryDot45)};
`;

export const ShipmentCardSectionLinkById = styled.a.attrs({
  'data-class': 'ShipmentCardSectionLinkById',
})`
  ${divFlex}

  color: ${({ theme }) => theme.themesColors.primaryBranding7};
  font-size: 12px;
  font-weight: 400;
`;

export const ShipmentCardSectionIcon = styled.div.attrs({
  'data-class': 'ShipmentCardSectionIcon',
})`
  ${divFlex}

  justify-content: center;
  align-items: center;
  margin-left: 16px;
  margin-right: 8px;
`;

export const ShipmentCardSectionContentDescriptions = styled.div.attrs({
  'data-class': 'ShipmentCardSectionContentDescriptions',
})`
  ${divFlex}

  justify-content: center;
  align-items: center;
  margin-left: 16px;
  margin-right: 8px;
`;

export const ShipmentCardSectionDivider = styled(Divider).attrs({
  'data-class': 'ShipmentCardSectionDivider',
})`
  ${divFlex}
  width: 100%;
  margin: 0;
`;

export const ShipmentListLoadingCardStyled = styled(Card).attrs({
  'data-class': 'ShipmentListLoadingCardStyled',
})`
  ${divFlex}
  width: 100%;
`;

export const ShipmentListSpan = styled.div.attrs({
  'data-class': 'ShipmentListSpan',
})`
  text-transform: capitalize;
`;
