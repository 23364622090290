import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from 'app-wrapper/view/components';

import { Footer } from './DrawerFooter.styled';

interface ITeamDrawerFooterComponentProps {
  onClose: () => void;
  onEditWorkgroup: () => void;
  onAddMember: () => void;
  isAbleToSave: boolean;
  isCustomerView: boolean;
}

export const DrawerFooterComponent: FC<ITeamDrawerFooterComponentProps> = ({
  onClose,
  onEditWorkgroup,
  onAddMember,
  isAbleToSave,
  isCustomerView,
}) => {
  const { t } = useTranslation();

  const handleSave = useCallback(() => {
    if (isCustomerView) {
      onAddMember();

      return;
    }

    onEditWorkgroup();
  }, [isCustomerView, onAddMember, onEditWorkgroup]);

  return (
    <Footer>
      <Button type="default" onClick={onClose}>
        {t('Cancel')}
      </Button>

      <Button
        data-class="add-workgroup-member-button"
        onClick={handleSave}
        disabled={!isAbleToSave}
      >
        {isCustomerView ? t('Add') : t('Save')}
      </Button>
    </Footer>
  );
};
