import React, {
  ReactNode,
  useState,
  useEffect,
} from 'react';
import ReactDOM from 'react-dom';
import { Footer as FooterStyledComponent } from './Footer.styled';

interface FooterProps {
  children: ReactNode
}

export const Footer = (props: FooterProps) => {
  const [footer, setFooter] = useState<HTMLElement | null>(null);
  const { children } = props;

  useEffect(() => {
    setFooter(document.getElementById('subpage-footer'));
  }, []);

  if (!footer || !children) {
    return null;
  }

  return ReactDOM.createPortal(
    <FooterStyledComponent>{children}</FooterStyledComponent>,
    footer,
  );
};
