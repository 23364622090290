import React from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';
import { UC } from 'shipment-operations/controllers';

import { DrawerFooterComponent } from './DrawerFooter.component';

const DrawerFooterContainer = () => {
  const isAbleToSave = useSelector(R.selectors.shipmentPeople.getIsAbleToAddMember);
  const isCustomerView = useSelector(R.selectors.shipmentPeople.getIsCustomerView);

  return (
    <DrawerFooterComponent
      onClose={UC.shipmentPeople.closeAddMemberDrawer}
      onAddMember={UC.shipmentPeople.addWorkgroupMember}
      onEditWorkgroup={UC.shipmentPeople.editWorkgroup}
      isCustomerView={isCustomerView}
      isAbleToSave={isAbleToSave}
    />
  );
};

export { DrawerFooterContainer as DrawerFooter };
