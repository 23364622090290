import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'app-wrapper/createSlice';

import { DateDtm } from 'app-wrapper/models/dtm';

import { TRatesModalState } from 'monetary/models/states';
import { ECarrierSCAC, ERateTypes } from 'monetary/constants';

const initialState: TRatesModalState = {
  isLoading: false,
  isOpen: false,
  rateType: undefined,
  date: undefined,
  dateError: undefined,
  carrierScacList: [],
};

export const ratesSlice = createSlice({
  name: 'ratesModal',
  initialState,
  reducers: {
    setLoading: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      isLoading: payload,
    }),
    setOpen: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      isOpen: payload,
    }),
    setRateType: (state, { payload }: PayloadAction<ERateTypes>) => ({
      ...state,
      rateType: payload,
    }),
    clearRateType: (state) => ({
      ...state,
      rateType: undefined,
    }),
    setDate: (state, { payload }: PayloadAction<DateDtm>) => ({
      ...state,
      date: payload,
    }),
    clearDate: (state) => ({
      ...state,
      date: undefined,
    }),
    setDateError: (state, { payload }: PayloadAction<Error>) => ({
      ...state,
      dateError: payload,
    }),
    clearDateError: (state) => ({
      ...state,
      dateError: undefined,
    }),
    setCarrierScacList: (state, { payload }: PayloadAction<ECarrierSCAC[]>) => ({
      ...state,
      carrierScacList: payload,
    }),
  },
});

export const ratesModalActions = ratesSlice.actions;
export const ratesModalReducer = ratesSlice.reducer;
