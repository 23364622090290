import styled from 'styled-components';

import { FlexCol } from 'app-wrapper/view/guideline';

import { Title as BaseTitle } from 'shipment-operations/view/pages/ShipmentAdditionalService/components/ServiceWorkflow/сomponents/RequiredActionsFlow/components/StepsForm/components/InputActivity/InputActivity.styled';

export const Title = styled(BaseTitle)``;

export const Content = styled(FlexCol)`
  justify-content: flex-start;
`;
