import styled from 'styled-components';
import Typography from 'antd/es/typography';

import { divFlex } from 'app-wrapper/view/themes/mixin';

export const MBLShippingParties = styled('div').attrs({
  'data-class': 'SBLShippingParties',
})`
  ${divFlex}
  width: 100%;
  flex-direction: column;
`;

export const MBLShippingPartiesTitle = styled(Typography)`
  width: 100%;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
`;

export const MBLShippingPartiesRow = styled('div').attrs({
  'data-class': 'SBLShippingParties',
})`
  ${divFlex}
  width: 100%;
  flex-direction: row;
`;

export const MBLShippingPartiesItem = styled('div')`
  ${divFlex}
  margin-top: 14px;
  padding-right: 14px;
  padding-bottom: 20px;
  flex-direction: column;
  width: 25%;
  min-height: 195px;
  max-height: 195px;
`;

export const MBLShippingPartiesItemTitle = styled(Typography)`
  font-size: 12px;
  line-height: 20px;
  color: ${({ theme }) => theme.themesColors.secondaryDot45}
`;

export const MBLShippingPartiesItemText = styled(Typography)`
  font-size: 14px;
  line-height: 20px;
  white-space: pre-line;
`;
