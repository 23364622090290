import React, { FC, useRef, useCallback } from 'react';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useReactToPrint } from 'react-to-print';

import { PrintRFQ } from 'monetary/view/components/RFQResponse/RFQItem/PrintRFQ';

import { MenuItem, LinkText } from './PrintNRAAgreementRFQ.styled';
import {
  NRADocumentPage,
  NRAPageBetweenAdminAndContractOwner,
  NRAPageBetweenAdminAndAdmin,
  NRADocumentHeaderBetweenAdminAndContractOwner,
  NRADocumentHeaderBetweenCustomerAndAdmin,
  NRADocumentHeaderBetweenAdminAndAdmin,
} from './components';

interface PrintQuoteRFQComponentProps {
  onClearCustomerInfo?: () => void;
  onPrint?: (quotaIndex: number) => void;
  isLoading: boolean;
  fileNamePrint?: string
  quotaIndex?: number;
  loadData: (shipmentId?: string, isNra?: boolean) => Promise<{
    id: number,
    fileName?: string,
  }>;
  loadDataForLinkCase: (shipmentId: string) => void;
  showAsLink?: boolean;
  shipmentIdForRFQCase?: string;
  shouldPrintCustomerToAdminNRA?: boolean;
  shouldPrintContractOwnerToAdminNRA?: boolean;
  shouldPrintAdminToAdminNRA?: boolean;
}

const OLD_TITLE = document.title;

export const PrintNRAAgreementRFQComponent: FC<PrintQuoteRFQComponentProps> = ({
  onPrint,
  onClearCustomerInfo,
  isLoading,
  fileNamePrint,
  quotaIndex,
  loadData,
  showAsLink,
  loadDataForLinkCase,
  shipmentIdForRFQCase,
  shouldPrintCustomerToAdminNRA,
  shouldPrintAdminToAdminNRA,
  shouldPrintContractOwnerToAdminNRA,
}) => {
  const { shipmentId = '' } = useParams();
  const { t } = useTranslation();
  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    // @ts-ignore
    content: () => componentRef.current,
    // documentTitle: fileNamePrint,
    onAfterPrint: () => {
      if (onClearCustomerInfo) {
        onClearCustomerInfo();
      }

      // support chrome
      document.title = OLD_TITLE;
    },
  });

  const onClickPrint = useCallback(async () => {
    if (isLoading) {
      return;
    }

    if (quotaIndex && onPrint) {
      await onPrint(quotaIndex);
      // support chrome
      document.title = fileNamePrint || OLD_TITLE;
      handlePrint();

      return;
    }

    if (showAsLink) {
      await loadDataForLinkCase(shipmentIdForRFQCase || shipmentId);
    }

    const localQuotaIndex = await loadData(shipmentId || shipmentIdForRFQCase, true);

    if (onPrint) {
      await onPrint(localQuotaIndex.id);

      // support chrome
      document.title = localQuotaIndex.fileName || fileNamePrint || OLD_TITLE;
      handlePrint();
    }
  }, [onPrint, handlePrint, isLoading, loadData, shipmentId, quotaIndex, loadDataForLinkCase, showAsLink, shipmentIdForRFQCase, fileNamePrint]);

  if (showAsLink) {
    return (
      <>
        <div style={{ display: 'none' }}>
          <PrintRFQ
            ref={componentRef}
            fileNamePrint={fileNamePrint}
            additionalPage={<NRADocumentPage />}
            headerComponent={<NRADocumentHeaderBetweenCustomerAndAdmin />}
          />
        </div>

        <LinkText onClick={onClickPrint}>
          {t('Negotiated Rate Arrangement')}
        </LinkText>
      </>
    );
  }

  return (
    <>
      <div style={{ display: 'none' }}>
        <PrintRFQ
          ref={componentRef}
          fileNamePrint={fileNamePrint}
          headerComponent={(
            <>
              {shouldPrintCustomerToAdminNRA ? <NRADocumentHeaderBetweenCustomerAndAdmin /> : null}
              {shouldPrintContractOwnerToAdminNRA ? <NRADocumentHeaderBetweenAdminAndContractOwner /> : null}
              {shouldPrintAdminToAdminNRA ? <NRADocumentHeaderBetweenAdminAndAdmin /> : null}
            </>
          )}
          additionalPage={(
            <>
              {shouldPrintCustomerToAdminNRA ? <NRADocumentPage /> : null}
              {shouldPrintContractOwnerToAdminNRA ? <NRAPageBetweenAdminAndContractOwner /> : null}
              {shouldPrintAdminToAdminNRA ? <NRAPageBetweenAdminAndAdmin /> : null}
            </>
          )}
        />
      </div>

      <MenuItem onClick={onClickPrint}>
        {t('Print')}
      </MenuItem>
    </>
  );
};
