import { BaseController, controller } from 'proto/BaseController';
import message from 'antd/es/message';
import { v4 as uuidv4 } from 'uuid';

import { REQUEST_STATUS, RouteNames } from 'app-wrapper/constants';
import i18n from 'app-wrapper/i18n/i18n';
import { validationEmail, validationPhone } from 'app-wrapper/utils';

import { R as RShipment } from 'shipment-operations/repository';

import { EAccountDrawerContent } from 'user-management/constants';
import {
  IGetCompanyListAddressRequest,
  IGetCompanyListContactRequest,
  IPostAddressContactPersonRequest,
  IPostCompanyListAddressRequest,
  TGetCompanyListRequest,
} from 'user-management/models/contracts';
import {
  CompanyAddressDTM,
  CompanyCollectedDTM,
  CompanyContactDTM,
  CompanyDTM,
  ContactBookDefaultStateCompanyListStateContactPersonDTM,
  ContactBookDefaultStateCompanyListStateDTM,
  ContactBookStateErrorsFieldDTM,
  EmptyCompanyAddressDTMFactory,
  EmptyCompanyContactDTMFactory,
  ICompanyDTM,
  IContactBookDefaultStateCompanyListStateDTM,
  IContactBookUpdateStateAddressesErrorsDTM,
  IContactBookUpdateStateDTM,
} from 'user-management/models/dtm';
import { R } from 'user-management/repository';
import { ContactBookInitialState } from 'user-management/repository/store/ContactBook';
import { NetworkErrorProto } from 'app-wrapper/models/errors';

import { EmptyContactBookDefaultStateCompanyListStateDTMFactory } from 'user-management/models/dtm/CompanyBookState.dtm.factory';

@controller
export class ContactBookController extends BaseController {
  public setUpdateAddCompanyListState = (companyList: IContactBookDefaultStateCompanyListStateDTM) => {
    this.dispatch(R.actions.contactBook.setUpdateAddCompanyList(ContactBookDefaultStateCompanyListStateDTM.fromPlain(companyList)));

    this.validateDocumentsFiles();

    this.compareUpdateState();
  };

  public setUpdateAddCompanyListBaseState = () => {
    const customId = uuidv4();
    const addressId = uuidv4();
    const contactId = uuidv4();

    this.dispatch(R.actions.contactBook.setUpdateAddCompanyList(ContactBookDefaultStateCompanyListStateDTM.fromPlain({
      ...ContactBookInitialState.updateState.companyList?.[0],
      customId,
      addressesIds: [addressId],
      contactPersonsIds: [contactId],
    })));
    this.dispatch(R.actions.contactBook.setDefaultAddCompanyList(ContactBookDefaultStateCompanyListStateDTM.fromPlain({
      ...ContactBookInitialState.updateState.companyList?.[0],
      customId,
      addressesIds: [addressId],
      contactPersonsIds: [contactId],
    })));
    this.dispatch(R.actions.contactBook.setUpdateAddCompanyListAddresses(EmptyCompanyAddressDTMFactory.build({
      customId: addressId,
    })));
    this.dispatch(R.actions.contactBook.setDefaultAddCompanyListAddresses(EmptyCompanyAddressDTMFactory.build({
      customId: addressId,
    })));
    this.dispatch(R.actions.contactBook.setUpdateAddCompanyListContactPersons(EmptyCompanyContactDTMFactory.build({
      customId: contactId,
    })));
    this.dispatch(R.actions.contactBook.setDefaultAddCompanyListContactPersons(EmptyCompanyContactDTMFactory.build({
      customId: contactId,
    })));

    this.dispatch(R.actions.contactBook.setUpdateSetActiveListTabIndex(customId));

    this.validateDocumentsFiles();

    this.compareUpdateState();
  };

  public onRunComponent = async () => {
    this.dispatch(R.actions.contactBook.resetUpdate());
    this.dispatch(R.actions.contactBook.resetDefault());
    this.dispatch(R.actions.contactBook.setDefaultCompanyListPage('0'));
    this.dispatch(R.actions.contactBook.setDefaultCompanyListSize('20'));

    await this.getCompanyList('20');
  }

  public onRunContactBookById = async (idCompany: string) => {
    this.dispatch(R.actions.contactBook.resetUpdate());
    this.dispatch(R.actions.contactBook.resetDefault());

    await this.getCompanyByID(idCompany);
  }

  public onRunAddressAndContactsPage = async () => {
    this.dispatch(R.actions.contactBook.resetUpdate());
    this.dispatch(R.actions.contactBook.resetDefault());

    await this.getCompanyPrimary();
  }

  public onRunAddressAndContactsSecondPage = async (id: string) => {
    this.dispatch(R.actions.contactBook.resetUpdate());
    this.dispatch(R.actions.contactBook.resetDefault());

    await this.getCompanyAddressById(id);
  }

  public getCompanyList = async (size = '1000') => {
    this.dispatch(R.actions.contactBook.setDefaultGetRequestStatusStatus(REQUEST_STATUS.pending));
    const { contactBook: { defaultState } } = this.store.getState();

    let response: ICompanyDTM[] = [];
    let listAddresses: CompanyAddressDTM[] = [];
    let listContacts: CompanyContactDTM[] = [];

    try {
      const companyCollected = await R.services.contacts.getCompanyList(undefined, {
        size,
        page: `${Number(defaultState.companyListPage || 0)}`,
      });

      response = companyCollected.companyList;
      listAddresses = companyCollected.addresses.sort((a) => (a.isPrimary ? -1 : 1));
      listContacts = companyCollected.contacts.sort((a) => (a.primary ? -1 : 1));
    } catch (e) {
      this.dispatch(R.actions.contactBook.setDefaultGetRequestStatusStatus(REQUEST_STATUS.rejected));
      this.dispatch(R.actions.contactBook.setDefaultIsRequest(true));
      return;
    }

    if (response.length) {
      this.dispatch(R.actions.contactBook.setEmptyUpdateCompanyListState());
      this.dispatch(R.actions.contactBook.setUpdateCompanyList([]));
    }
    let firstId: string | undefined;

    const collator = new Intl.Collator();
    this.parseResponse(response)
      .sort((a, b) => collator.compare(a.name, b.name))
      .forEach((itemList) => {
        if (!itemList.isPrimary) {
          if (!firstId) {
            firstId = itemList.customId;
          }
          this.dispatch(R.actions.contactBook.setUpdateAddCompanyList(ContactBookDefaultStateCompanyListStateDTM.fromPlain({ ...itemList })));
          this.dispatch(R.actions.contactBook.setDefaultAddCompanyList(ContactBookDefaultStateCompanyListStateDTM.fromPlain({ ...itemList })));
        }
      });

    if (firstId) {
      this.dispatch(R.actions.contactBook.setUpdateSetActiveListTabIndex(firstId));
    }

    this.dispatch(R.actions.contactBook.setDefaultCompanyListAddresses(listAddresses));
    this.dispatch(R.actions.contactBook.setDefaultCompanyListContactPersons(listContacts));

    this.dispatch(R.actions.contactBook.setUpdateCompanyListAddresses(listAddresses));
    this.dispatch(R.actions.contactBook.setUpdateCompanyListContactPersons(listContacts));

    this.dispatch(R.actions.contactBook.setDefaultCompanyListPage(`${Number(defaultState.companyListPage || 0) + 1}`));

    this.dispatch(R.actions.contactBook.setDefaultGetRequestStatusStatus(REQUEST_STATUS.complete));
    this.dispatch(R.actions.contactBook.setDefaultIsRequest(true));
  };

  public nextPageCompanyList = async () => {
    this.dispatch(R.actions.contactBook.setDefaultGetRequestStatusNextPageStatus(REQUEST_STATUS.pending));
    const { contactBook: { defaultState } } = this.store.getState();

    let response: ICompanyDTM[] = [];
    const addressesIds: number[] = [];
    const contactsIds: number[] = [];

    let listAddresses: CompanyAddressDTM[] = defaultState?.companyListAddresses?.map((item) => {
      addressesIds.push(item.id);
      return CompanyAddressDTM.fromPlain(item);
    }) || [];
    let listContacts: CompanyContactDTM[] = defaultState?.companyListContactPersons.map((item) => {
      contactsIds.push(item.id);
      return CompanyContactDTM.fromPlain(item);
    }) || [];

    try {
      const companyCollected = await R.services.contacts.getCompanyList(undefined, {
        page: defaultState.companyListPage,
        size: defaultState.companyListSize,
      });

      response = companyCollected.companyList;
      listAddresses = [
        ...listAddresses,
        ...companyCollected.addresses.filter((item) => !addressesIds.includes(item.id))].sort((a) => (a.isPrimary ? -1 : 1));
      listContacts = [
        ...listContacts,
        ...companyCollected.contacts.filter((item) => !contactsIds.includes(item.id)),
      ].sort((a) => (a.primary ? -1 : 1));
    } catch (e) {
      this.dispatch(R.actions.contactBook.setDefaultGetRequestStatusNextPageStatus(REQUEST_STATUS.rejected));
      this.dispatch(R.actions.contactBook.setDefaultIsRequest(true));
      throw e;
    }

    if (response.length < 20) {
      this.dispatch(R.actions.contactBook.setDefaultIsEndPage(true));
    }

    const collator = new Intl.Collator();
    const oldIds = defaultState.companyList.map((item) => item.id);

    this.parseResponse(response)
      .sort((a, b) => collator.compare(a.name, b.name))
      .forEach((itemList) => {
        if (!itemList.isPrimary && !oldIds.includes(itemList.id)) {
          this.dispatch(R.actions.contactBook.setUpdateAddCompanyList(ContactBookDefaultStateCompanyListStateDTM.fromPlain({ ...itemList })));
          this.dispatch(R.actions.contactBook.setDefaultAddCompanyList(ContactBookDefaultStateCompanyListStateDTM.fromPlain({ ...itemList })));
        }
      });

    this.dispatch(R.actions.contactBook.setDefaultCompanyListAddresses(listAddresses));
    this.dispatch(R.actions.contactBook.setDefaultCompanyListContactPersons(listContacts));

    this.dispatch(R.actions.contactBook.setUpdateCompanyListAddresses(listAddresses));
    this.dispatch(R.actions.contactBook.setUpdateCompanyListContactPersons(listContacts));

    this.dispatch(R.actions.contactBook.setDefaultCompanyListPage(`${Number(defaultState.companyListPage || 0) + 1}`));

    this.dispatch(R.actions.contactBook.setDefaultGetRequestStatusNextPageStatus(REQUEST_STATUS.complete));
    this.dispatch(R.actions.contactBook.setDefaultIsRequest(true));
  };

  public getCompanyByID = async (id: string) => {
    this.dispatch(R.actions.contactBook.setDefaultGetRequestStatusStatus(REQUEST_STATUS.pending));

    let response: ICompanyDTM[] = [];
    let listAddresses: CompanyAddressDTM[] = [];
    let listContacts: CompanyContactDTM[] = [];

    try {
      const companyCollected = await R.services.contacts.getCompanyById(id);

      response = companyCollected.companyList;
      listAddresses = companyCollected.addresses.sort((a) => (a.isPrimary ? -1 : 1));
      listContacts = companyCollected.contacts.sort((a) => (a.primary ? -1 : 1));
    } catch (e) {
      this.dispatch(R.actions.contactBook.setDefaultIsRequest(true));
      this.dispatch(R.actions.contactBook.setDefaultGetRequestStatusStatus(REQUEST_STATUS.rejected));
      throw e;
    }

    if (response.length) {
      this.dispatch(R.actions.contactBook.setEmptyUpdateCompanyListState());
      this.dispatch(R.actions.contactBook.setUpdateCompanyList([]));
    }
    let firstId: string | undefined;

    const collator = new Intl.Collator();
    this.parseResponse(response)
      .sort((a, b) => collator.compare(a.name, b.name))
      .forEach((itemList) => {
        if (!itemList.isPrimary) {
          if (!firstId) {
            firstId = itemList.customId;
          }
          this.dispatch(R.actions.contactBook.setUpdateAddCompanyList(ContactBookDefaultStateCompanyListStateDTM.fromPlain({ ...itemList })));
          this.dispatch(R.actions.contactBook.setDefaultAddCompanyList(ContactBookDefaultStateCompanyListStateDTM.fromPlain({ ...itemList })));
        }
      });

    if (firstId) {
      this.dispatch(R.actions.contactBook.setUpdateSetActiveListTabIndex(firstId));
    }

    this.dispatch(R.actions.contactBook.setDefaultCompanyListAddresses(listAddresses));
    this.dispatch(R.actions.contactBook.setDefaultCompanyListContactPersons(listContacts));

    this.dispatch(R.actions.contactBook.setUpdateCompanyListAddresses(listAddresses));
    this.dispatch(R.actions.contactBook.setUpdateCompanyListContactPersons(listContacts));
    this.dispatch(R.actions.contactBook.setDefaultGetRequestStatusStatus(REQUEST_STATUS.complete));
    this.dispatch(R.actions.contactBook.setDefaultIsRequest(true));
  };

  public getCompanyPrimary = async () => {
    this.dispatch(R.actions.contactBook.setDefaultGetRequestStatusStatus(REQUEST_STATUS.pending));

    let response: CompanyDTM[] = [];
    let listAddresses: CompanyAddressDTM[] = [];
    let listContacts: CompanyContactDTM[] = [];

    try {
      const companyCollected = await R.services.contacts.getCompanyList(true);

      response = companyCollected.companyList;
      listAddresses = companyCollected.addresses;
      listContacts = companyCollected.contacts;
    } catch (e) {
      this.dispatch(R.actions.contactBook.setDefaultGetRequestStatusStatus(REQUEST_STATUS.rejected));

      throw e;
    }

    if (response.length) {
      this.dispatch(R.actions.contactBook.setEmptyUpdateCompanyListState());
    }

    const collator = new Intl.Collator();
    this.parseResponse(response)
      .sort((a, b) => collator.compare(a.name, b.name))
      .forEach((itemList) => {
        if (itemList.isPrimary) {
          this.dispatch(R.actions.contactBook.setEmptyFormUpdateCompanyListState());
          this.dispatch(R.actions.contactBook.setDefaultCompanyPrimaryState({ ...itemList }));
        }
      });

    this.dispatch(R.actions.contactBook.setDefaultCompanyListAddresses(listAddresses));
    this.dispatch(R.actions.contactBook.setDefaultCompanyListContactPersons(listContacts));
    this.dispatch(R.actions.contactBook.setDefaultGetRequestStatusStatus(REQUEST_STATUS.complete));
  };

  public getCompanyAddressById = async (addressId: string) => {
    this.dispatch(R.actions.contactBook.setDefaultGetRequestStatusStatus(REQUEST_STATUS.pending));

    let response: ICompanyDTM[] = [];
    let contacts: CompanyContactDTM[] | null = null;
    let addresses: CompanyAddressDTM[] | null = null;

    try {
      const companyCollected = await R.services.contacts.getCompanyList(true);

      response = companyCollected.companyList;
    } catch (e) {
      this.dispatch(R.actions.contactBook.setDefaultGetRequestStatusStatus(REQUEST_STATUS.rejected));
      throw e;
    }

    if (!response) {
      return;
    }

    try {
      contacts = await R.services.contacts.getCompanyContactById(response?.[0].id, addressId);
    } catch (e) {
      this.dispatch(R.actions.contactBook.setDefaultGetRequestStatusStatus(REQUEST_STATUS.rejected));
      throw e;
    }

    try {
      const address = await R.services.contacts.getAddress(response?.[0].id, Number(addressId));
      if (address) {
        addresses = [CompanyAddressDTM.fromPlain(address)];
      }
    } catch (e) {
      this.dispatch(R.actions.contactBook.setDefaultGetRequestStatusStatus(REQUEST_STATUS.rejected));
      throw e;
    }

    if (contacts) {
      this.dispatch(R.actions.contactBook.setDefaultCompanyPrimaryContactsByIAddress(contacts));
      this.dispatch(R.actions.contactBook.setDefaultCompanyPrimaryIdByIAddress(response?.[0].id));
    }

    if (addresses) {
      this.dispatch(R.actions.contactBook.setDefaultCompanyPrimaryAddressesByIAddress(addresses));
    }

    this.dispatch(R.actions.contactBook.setDefaultGetRequestStatusStatus(REQUEST_STATUS.complete));
  };

  public openAddressesAndContactsAddAddressDrawer = () => {
    const customId = uuidv4();
    const addressId = uuidv4();
    const contactId = uuidv4();

    this.dispatch(R.actions.contactBook.setUpdateCompanyList([]));
    this.dispatch(R.actions.contactBook.setUpdateAddCompanyList(ContactBookDefaultStateCompanyListStateDTM.fromPlain({
      ...ContactBookInitialState.updateState.companyList?.[0],
      customId,
      addressesIds: [addressId],
      contactPersonsIds: [contactId],
    })));
    this.dispatch(R.actions.contactBook.setUpdateAddCompanyListAddresses(EmptyCompanyAddressDTMFactory.build({
      customId: addressId,
    })));
    this.dispatch(R.actions.contactBook.setUpdateAddCompanyListContactPersons(EmptyCompanyContactDTMFactory.build({
      customId: contactId,
    })));

    this.dispatch(R.actions.accountDrawer.openDrawer(EAccountDrawerContent.USER_ADDRESSES_AND_CONTACTS_ADD_ADDRESS));
  }

  public openAACContactEditContactPersonDrawer = (contactIndex: number) => () => {
    const { contactBook: { defaultState } } = this.store.getState();

    const contact = defaultState?.companyPrimaryByIAddress?.contacts.filter((item, index) => index === contactIndex)?.[0];

    if (!contact) {
      return;
    }

    this.dispatch(R.actions.contactBook.setUpdateContactPersonForm(CompanyContactDTM.fromPlain({
      ...EmptyCompanyContactDTMFactory.build(),
      id: contact.id,
      fullName: contact?.fullName,
      email: contact?.email,
      phone: contact?.phone,
      phone2: contact?.phone2,
      primary: contact?.primary,
    })));

    this.dispatch(R.actions.accountDrawer.openDrawer(EAccountDrawerContent.USER_AAC_CONTACT_EDIT_CONTACT_PERSON));
  }

  public openAACContactAddContactPersonDrawer = () => {
    this.dispatch(R.actions.accountDrawer.openDrawer(EAccountDrawerContent.USER_AAC_CONTACT_ADD_CONTACT_PERSON));
  }

  public closeAACContactEditContactPersonDrawer = () => {
    this.dispatch(R.actions.accountDrawer.closeDrawer());

    this.dispatch(R.actions.contactBook.resetUpdateContactPersonForm());
  }

  public closeAACContactAddContactPersonDrawer = () => {
    this.dispatch(R.actions.accountDrawer.closeDrawer());

    this.dispatch(R.actions.contactBook.resetUpdateContactPersonForm());
  }

  public onSaveAACContactAddContactPersonDrawer = (addressId: string) => async () => {
    this.setIsVisitedAACContactPerson();
    if (this.validateAACContactPerson()) {
      return;
    }
    this.dispatch(R.actions.contactBook.setDefaultGetRequestStatusStatus(REQUEST_STATUS.pending));
    const { contactBook: { defaultState, updateState } } = this.store.getState();

    const request: IPostAddressContactPersonRequest = {
      email: updateState.contactPersonForm?.email,
      fullName: updateState.contactPersonForm?.fullName,
      phone: updateState.contactPersonForm?.phone,
      phone2: updateState.contactPersonForm?.phone2,
      primary: updateState.contactPersonForm?.primary,
    };

    try {
      if (updateState.contactPersonForm) {
        await R.services.contacts.postAddressContactByID(`${defaultState.companyPrimaryByIAddress?.id}`, addressId, request);
      }
    } catch (e) {
      this.dispatch(R.actions.contactBook.setDefaultGetRequestStatusStatus(REQUEST_STATUS.rejected));
      throw e;
    }

    this.dispatch(R.actions.accountDrawer.closeDrawer());

    this.dispatch(R.actions.contactBook.resetUpdateContactPersonForm());
    this.dispatch(R.actions.contactBook.setDefaultGetRequestStatusStatus(REQUEST_STATUS.complete));

    message.success(i18n.t('Contact is Added'));

    this.onRunAddressAndContactsSecondPage(addressId);
  }

  public onSaveAACContactEditContactPersonDrawer = async (addressId: string) => {
    this.setIsVisitedAACContactPerson();
    if (this.validateAACContactPerson()) {
      return;
    }
    this.dispatch(R.actions.contactBook.setDefaultGetRequestStatusStatus(REQUEST_STATUS.pending));
    const { contactBook: { defaultState, updateState } } = this.store.getState();

    const request: IPostAddressContactPersonRequest = {
      email: updateState.contactPersonForm?.email,
      fullName: updateState.contactPersonForm?.fullName,
      phone: updateState.contactPersonForm?.phone,
      phone2: updateState.contactPersonForm?.phone2,
      primary: updateState.contactPersonForm?.primary,
    };

    try {
      if (updateState.contactPersonForm) {
        await R.services.contacts.putAddressContactByID(`${defaultState.companyPrimaryByIAddress?.id}`, addressId, `${updateState.contactPersonForm.id}`, request);
      }
    } catch (e) {
      this.dispatch(R.actions.contactBook.setDefaultGetRequestStatusStatus(REQUEST_STATUS.rejected));
      throw e;
    }

    this.dispatch(R.actions.accountDrawer.closeDrawer());

    this.dispatch(R.actions.contactBook.resetUpdateContactPersonForm());
    this.dispatch(R.actions.contactBook.setDefaultGetRequestStatusStatus(REQUEST_STATUS.complete));

    message.success(i18n.t('Address is Updated'));

    this.onRunAddressAndContactsSecondPage(addressId);
  }

  public onRemoveAACContactEditContactPersonDrawer = async (addressId: string) => {
    this.dispatch(R.actions.contactBook.setDefaultGetRequestStatusStatus(REQUEST_STATUS.pending));
    const { contactBook: { defaultState, updateState } } = this.store.getState();

    try {
      if (updateState.contactPersonForm) {
        await R.services.contacts.deleteAddressContactByID(`${defaultState.companyPrimaryByIAddress?.id}`, addressId, `${updateState.contactPersonForm.id}`);
      }
    } catch (e) {
      this.dispatch(R.actions.contactBook.setDefaultGetRequestStatusStatus(REQUEST_STATUS.rejected));
      throw e;
    }

    this.dispatch(R.actions.accountDrawer.closeDrawer());

    this.dispatch(R.actions.contactBook.resetUpdateContactPersonForm());
    this.dispatch(R.actions.contactBook.setDefaultGetRequestStatusStatus(REQUEST_STATUS.complete));

    message.success(i18n.t('Contact is Deleted'));

    this.onRunAddressAndContactsSecondPage(addressId);
  }

  public onChangeFullNameAACContactPerson = (fullName: string) => {
    const validValue = fullName?.slice(0, 50);
    this.dispatch(R.actions.contactBook.setUpdateContactPersonFullNameForm(validValue));

    this.validateAACContactPerson();
  }

  public onBlurFullNameAACContactPerson = () => {
    const { contactBook: { updateState: { contactPersonForm } } } = this.store.getState();
    this.dispatch(R.actions.contactBook.setUpdateContactPersonFullNameFormErrors(ContactBookStateErrorsFieldDTM.fromPlain({
      ...contactPersonForm?.fullNameErrors,
      isBlur: true,
      isFocus: false,
      isVisited: true,
    })));

    this.validateAACContactPerson();
  }

  public onFocusFullNameAACContactPerson = () => {
    const { contactBook: { updateState: { contactPersonForm } } } = this.store.getState();
    this.dispatch(R.actions.contactBook.setUpdateContactPersonFullNameFormErrors(ContactBookStateErrorsFieldDTM.fromPlain({
      ...contactPersonForm?.fullNameErrors,
      isBlur: false,
      isFocus: true,
    })));

    this.validateAACContactPerson();
  }

  public onChangeEmailAACContactPerson = (email: string) => {
    const validValue = email?.slice(0, 50);
    this.dispatch(R.actions.contactBook.setUpdateContactPersonEmailForm(validValue));

    this.validateAACContactPerson();
  }

  public onBlurEmailAACContactPerson = () => {
    const { contactBook: { updateState: { contactPersonForm } } } = this.store.getState();
    this.dispatch(R.actions.contactBook.setUpdateContactPersonEmailFormErrors(ContactBookStateErrorsFieldDTM.fromPlain({
      ...contactPersonForm?.emailErrors,
      isBlur: true,
      isFocus: false,
      isVisited: true,
    })));

    this.validateAACContactPerson();
  }

  public onFocusEmailAACContactPerson = () => {
    const { contactBook: { updateState: { contactPersonForm } } } = this.store.getState();
    this.dispatch(R.actions.contactBook.setUpdateContactPersonEmailFormErrors(ContactBookStateErrorsFieldDTM.fromPlain({
      ...contactPersonForm?.emailErrors,
      isBlur: false,
      isFocus: true,
    })));

    this.validateAACContactPerson();
  }

  public onChangePhoneAACContactPerson = (phone: string) => {
    const validValue = validationPhone(phone);
    this.dispatch(R.actions.contactBook.setUpdateContactPersonPhoneForm(validValue.phone));

    this.validateAACContactPerson();
  }

  public onBlurPhoneAACContactPerson = () => {
    const { contactBook: { updateState: { contactPersonForm } } } = this.store.getState();
    this.dispatch(R.actions.contactBook.setUpdateContactPersonPhoneFormErrors(ContactBookStateErrorsFieldDTM.fromPlain({
      ...contactPersonForm?.phoneErrors,
      isBlur: true,
      isFocus: false,
      isVisited: true,
    })));

    this.validateAACContactPerson();
  }

  public onFocusPhoneAACContactPerson = () => {
    const { contactBook: { updateState: { contactPersonForm } } } = this.store.getState();
    this.dispatch(R.actions.contactBook.setUpdateContactPersonPhoneFormErrors(ContactBookStateErrorsFieldDTM.fromPlain({
      ...contactPersonForm?.phoneErrors,
      isBlur: false,
      isFocus: true,
    })));

    this.validateAACContactPerson();
  }

  public onChangePhone2AACContactPerson = (phone2: string) => {
    const validValue = validationPhone(phone2);
    this.dispatch(R.actions.contactBook.setUpdateContactPersonPhone2Form(validValue.phone));

    this.validateAACContactPerson();
  }

  public onBlurPhone2AACContactPerson = () => {
    const { contactBook: { updateState: { contactPersonForm } } } = this.store.getState();
    this.dispatch(R.actions.contactBook.setUpdateContactPersonPhone2FormErrors(ContactBookStateErrorsFieldDTM.fromPlain({
      ...contactPersonForm?.phone2Errors,
      isBlur: true,
      isFocus: false,
      isVisited: true,
    })));

    this.validateAACContactPerson();
  }

  public onFocusPhone2AACContactPerson = () => {
    const { contactBook: { updateState: { contactPersonForm } } } = this.store.getState();
    this.dispatch(R.actions.contactBook.setUpdateContactPersonPhone2FormErrors(ContactBookStateErrorsFieldDTM.fromPlain({
      ...contactPersonForm?.phone2Errors,
      isBlur: false,
      isFocus: true,
    })));

    this.validateAACContactPerson();
  }

  public onChangePrimaryAACContactPerson = (primary: boolean) => {
    this.dispatch(R.actions.contactBook.setUpdateContactPersonPrimaryForm(primary));

    this.validateAACContactPerson();
  }

  public deleteCompanyById = (companyId: number | null, listId: string) => async () => {
    if (!companyId) {
      return;
    }
    const { contactBook: { updateState: { companyList } } } = this.store.getState();

    this.dispatch(R.actions.contactBook.setDefaultGetRequestStatusStatus(REQUEST_STATUS.pending));

    try {
      await R.services.contacts.deleteCompanyById(companyId);
    } catch (e) {
      this.dispatch(R.actions.contactBook.setDefaultGetRequestStatusStatus(REQUEST_STATUS.rejected));

      throw e;
    }

    this.dispatch(R.actions.contactBook.removeUpdateCompanyListByIndex({ listId }));
    this.dispatch(R.actions.contactBook.removeDefaultAddCompanyListStateById({ listId }));
    this.dispatch(R.actions.contactBook.resetUpdateErrorsCompanyListStateById({ listIndex: listId }));
    this.dispatch(R.actions.contactBook.setUpdateSetActiveListTabIndex(companyList?.[0]?.customId || ''));

    this.dispatch(R.actions.contactBook.setDefaultGetRequestStatusStatus(REQUEST_STATUS.complete));
  }

  public postCompanyList = async (companyData: TGetCompanyListRequest) => {
    const response = await R.services.contacts.postCompanyList(companyData);

    return response;
  };

  public putCompanyList = async (companyId: number, companyData: TGetCompanyListRequest) => {
    const response = await R.services.contacts.putCompanyList(companyId, companyData);

    return response;
  };

  public onClickListItemModal = (callback: (id: string) => void) => (id: string) => {
    callback(id);
    return () => '';
  };

  public onClickListItem = (id: string) => () => {
    const { contactBook: { updateState: { activeListTabIndex } } } = this.store.getState();

    this.onDiscardContactBook(activeListTabIndex)();

    this.compareUpdateState();

    this.dispatch(R.actions.contactBook.setUpdateSetActiveListTabIndex(id));
  };

  // Update events

  public onUpdateContactBook = async () => {
    const { contactBook: { updateState, defaultState } } = this.store.getState();

    if (this.validateDocumentsFiles()) {
      this.setAllVisited();
      return;
    }

    this.dispatch(R.actions.contactBook.setDefaultGetRequestStatusStatus(REQUEST_STATUS.pending));

    this.setAllVisited(false);

    const companyListId = updateState.activeListTabIndex;

    const itemList = updateState.companyList.filter((item) => item.customId === companyListId)?.[0];

    let contactPersons: IGetCompanyListContactRequest[] | null = null;
    contactPersons = updateState?.companyListContactPersons
      ?.filter((item) => itemList.contactPersonsIds?.includes(item.customId || ''))
      .map((contactItem, contactIndex) => ({
        phone: contactItem.phone || null,
        phone2: contactItem.phone2 || null,
        fullName: contactItem.fullName || null,
        email: contactItem.email || null,
        id: contactItem.id || null,
        primary: contactIndex === 0,
      }))
      .filter((contactItem) => !!(contactItem?.phone
        || contactItem?.fullName
        || contactItem?.email
        || contactItem?.phone2));

    let addresses: IGetCompanyListAddressRequest[] | null = null;
    addresses = updateState?.companyListAddresses
      ?.filter((item) => itemList.addressesIds?.includes(item.customId || ''))
      ?.map((addressItem, addressIndex) => ({
        address1: addressItem.address1 || null,
        address2: addressItem.address2 || null,
        city: addressItem.city || null,
        country: addressItem.country || null,
        postalCode: addressItem.postalCode || null,
        state: addressItem.state || null,
        // TODO: fixed required closestProt in be
        closestPort: addressItem.closestPort || 'USOAK',
        id: addressItem.id || null,
        company: addressItem.company || null,
        primary: addressIndex === 0,
        contacts: contactPersons || undefined,
      }))
      .filter((addressItem) => !!(addressItem.address1
        || addressItem.address2
        || addressItem.city
        || addressItem.country
        || addressItem.postalCode
        || addressItem.state
      ));

    const companyDataRequest: TGetCompanyListRequest = {
      name: itemList.name,
      taxId: itemList.taxId || null,
      id: itemList.id || null,
      addresses,
      contacts: null,
    };

    let response: CompanyCollectedDTM | undefined;

    try {
      if (itemList.id) {
        response = await R.services.contacts.putCompanyList(itemList.id, companyDataRequest);
      } else {
        response = await R.services.contacts.postCompanyList(companyDataRequest);
      }
    } catch (e) {
      if (e instanceof NetworkErrorProto) {
        this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListStateNameById({
          indexList: companyListId,
          error: {
            message: e.getErrorMessage(),
            isTooltipVisible: true,
          },
        }));

        this.dispatch(R.actions.contactBook.setDefaultGetRequestStatusStatus(REQUEST_STATUS.rejected));

        throw e;
      }

      this.dispatch(R.actions.contactBook.setDefaultGetRequestStatusStatus(REQUEST_STATUS.rejected));

      throw e;
    }

    if (response) {
      this.dispatch(R.actions.contactBook.setUpdateCompanyListByIndex({
        listId: companyListId,
        companyList: ContactBookDefaultStateCompanyListStateDTM.fromPlain({
          ...itemList,
          isHide: false,
          id: response.companyList?.[0].id || 0,
        }),
      }));
      this.dispatch(R.actions.contactBook.setDefaultAddCompanyListStateById({
        listId: companyListId,
        companyList: ContactBookDefaultStateCompanyListStateDTM.fromPlain({
          ...itemList,
          id: response.companyList?.[0].id || 0,
        }),
      }));

      const foundAddresses = defaultState?.companyListAddresses.map((item) => item.customId);
      const foundContacts = defaultState?.companyListContactPersons.map((item) => item.customId);
      itemList.addressesIds?.forEach((item) => {
        if (!foundAddresses.includes(item)) {
          this.dispatch(R.actions.contactBook.setDefaultAddCompanyListAddresses(this.getCompanyAddressCompareId(response?.addresses || [], CompanyAddressDTM.fromPlain({
            ...updateState?.companyListAddresses
              ?.filter((itemAddress) => itemAddress.customId === item)?.[0],
          }))));
        }
      });
      itemList.contactPersonsIds?.forEach((item) => {
        if (!foundContacts.includes(item)) {
          this.dispatch(R.actions.contactBook.setDefaultAddCompanyListContactPersons(this.getContactPersonsCompareId(response?.contacts || [], CompanyContactDTM.fromPlain({
            ...updateState?.companyListContactPersons
              ?.filter((itemContact) => itemContact.customId === item)?.[0],
          }))));
        }
      });
      itemList.addressesIds?.forEach((item) => {
        this.dispatch(R.actions.contactBook.setDefaultEditCompanyListAddressesById({
          listId: item,
          companyListAddresses: this.getCompanyAddressCompareId(response?.addresses || [], CompanyAddressDTM.fromPlain({
            ...updateState?.companyListAddresses
              ?.filter((itemAddress) => itemAddress.customId === item)?.[0],
          })),
        }));
      });
      itemList.contactPersonsIds?.forEach((item) => {
        this.dispatch(R.actions.contactBook.setDefaultEditCompanyListContactPersonsById({
          listId: item,
          companyListContactPersons: this.getContactPersonsCompareId(response?.contacts || [], CompanyContactDTM.fromPlain({
            ...updateState?.companyListContactPersons
              ?.filter((itemContact) => itemContact.customId === item)?.[0],
          })),
        }));
      });

      itemList.addressesIds?.forEach((item) => {
        this.dispatch(R.actions.contactBook.setUpdateEditCompanyListAddressesById({
          listId: item,
          companyListAddresses: this.getCompanyAddressCompareId(response?.addresses || [], CompanyAddressDTM.fromPlain({
            ...updateState?.companyListAddresses
              ?.filter((itemAddress) => itemAddress.customId === item)?.[0],
          })),
        }));
      });
      itemList.contactPersonsIds?.forEach((item) => {
        this.dispatch(R.actions.contactBook.setUpdateEditCompanyListContactPersonsById({
          listId: item,
          companyListContactPersons: this.getContactPersonsCompareId(response?.contacts || [], CompanyContactDTM.fromPlain({
            ...updateState?.companyListContactPersons
              ?.filter((itemContact) => itemContact.customId === item)?.[0],
          })),
        }));
      });

      this.compareUpdateState();

      await this.onRunComponent();
    }

    this.dispatch(R.actions.accountDrawer.closeDrawer());

    message.success(i18n.t('Company is Added'));

    this.dispatch(R.actions.contactBook.setDefaultGetRequestStatusStatus(REQUEST_STATUS.complete));
  }

  public onRemoveAddressesAndContacts = async () => {
    const { contactBook: { updateState, defaultState } } = this.store.getState();

    this.dispatch(R.actions.contactBook.setDefaultGetRequestStatusStatus(REQUEST_STATUS.pending));

    if (!defaultState?.companyPrimaryState?.id) {
      return;
    }
    const itemList = updateState.companyList[0];

    let addresses: IGetCompanyListAddressRequest[] | null = null;
    addresses = updateState?.companyListAddresses
      ?.filter((item) => itemList.addressesIds?.includes(item.customId || ''))
      ?.map((addressItem) => ({
        address1: addressItem.address1 || null,
        address2: addressItem.address2 || null,
        city: addressItem.city || null,
        country: addressItem.country || null,
        postalCode: addressItem.postalCode || null,
        state: addressItem.state || null,
        // TODO: fixed required closestProt in be
        closestPort: addressItem.closestPort || 'USOAK',
        id: addressItem.id || null,
        customId: addressItem.customId,
        company: addressItem.company || null,
        primary: !!addressItem.isPrimary,
      }))
      .filter((addressItem) => !!(addressItem.address1
        || addressItem.address2
        || addressItem.city
        || addressItem.country
        || addressItem.postalCode
        || addressItem.state
      ));

    if (!addresses?.[0].id) {
      return;
    }

    try {
      await R.services.contacts.deleteAddress(defaultState.companyPrimaryState.id, addresses?.[0].id);
    } catch (e) {
      this.dispatch(R.actions.contactBook.setDefaultGetRequestStatusStatus(REQUEST_STATUS.rejected));
      throw e;
    }

    this.dispatch(R.actions.contactBook.deleteDefaultCompanyPrimaryStateById(addresses?.[0].customId || ''));
    this.dispatch(R.actions.contactBook.resetUpdate());

    this.dispatch(R.actions.accountDrawer.closeDrawer());

    this.dispatch(R.actions.contactBook.setDefaultGetRequestStatusStatus(REQUEST_STATUS.complete));

    message.success(i18n.t('Address is Deleted'));
  }

  public onAddAddressesAndContacts = async () => {
    const { contactBook: { updateState, defaultState } } = this.store.getState();

    if (this.validateAAC()) {
      this.setAllVisitedAAC();
      return;
    }

    this.dispatch(R.actions.contactBook.setDefaultGetRequestStatusStatus(REQUEST_STATUS.pending));

    this.setAllVisitedAAC(false);

    const itemList = updateState.companyList?.[0];

    let addresses: IGetCompanyListAddressRequest[] | null = null;
    addresses = updateState?.companyListAddresses
      ?.filter((item) => itemList?.addressesIds?.includes(item.customId || ''))
      .map((addressItem) => ({
        address1: addressItem.address1 || null,
        address2: addressItem.address2 || null,
        city: addressItem.city || null,
        country: addressItem.country || null,
        postalCode: addressItem.postalCode || null,
        state: addressItem.state || null,
        // TODO: fixed required closestProt in be
        closestPort: addressItem.closestPort || 'USOAK',
        id: addressItem.id || null,
        customId: addressItem.customId,
        company: addressItem.company || null,
        primary: !!addressItem.isPrimary,
      }))
      .filter((addressItem) => !!(addressItem.address1
        || addressItem.address2
        || addressItem.city
        || addressItem.country
        || addressItem.postalCode
        || addressItem.state
      ));

    let contactPersons: IGetCompanyListContactRequest[] | null = null;
    contactPersons = updateState?.companyListContactPersons
      ?.filter((item) => itemList?.contactPersonsIds?.includes(item.customId || ''))
      .map((contactItem, contactIndex) => ({
        phone: contactItem.phone || null,
        phone2: contactItem.phone2 || null,
        fullName: contactItem.fullName || null,
        email: contactItem.email || null,
        id: contactItem.id || null,
        customId: contactItem.customId,
        primary: contactIndex === 0,
      }))
      .filter((contactItem) => !!(contactItem?.phone
        || contactItem?.fullName
        || contactItem?.email
        || contactItem?.phone2));

    if (!addresses?.[0] || !defaultState?.companyPrimaryState?.id) {
      return;
    }
    const companyDataRequest: IPostCompanyListAddressRequest = {
      ...addresses?.[0],
      contacts: contactPersons,
      id: undefined,
    };

    try {
      await R.services.contacts.postAddress(defaultState.companyPrimaryState.id, companyDataRequest);

      this.dispatch(R.actions.contactBook.resetDefault());
      await this.getCompanyPrimary();
    } catch (e) {
      this.dispatch(R.actions.contactBook.setDefaultGetRequestStatusStatus(REQUEST_STATUS.rejected));
      throw e;
    }

    this.dispatch(R.actions.contactBook.resetUpdate());

    this.dispatch(R.actions.accountDrawer.closeDrawer());

    this.dispatch(R.actions.contactBook.setDefaultGetRequestStatusStatus(REQUEST_STATUS.complete));

    message.success(i18n.t('Address is Added'));
  }

  public onNextPageAddressesAndContactsAddressById = (id: string) => async () => {
    await this.navigate(RouteNames.ADDRESSES_CONTACTS_SECOND(id));
  }

  public onBackAddressesAndContactsAddressById = async () => {
    await this.navigate(RouteNames.ADDRESSES_CONTACTS());
  }

  public onBackContactBookById = async () => {
    await this.navigate(RouteNames.CONTACT_BOOK());
  }

  public onBackContactBookByIdContactPerson = (companyId: string) => async () => {
    await this.navigate(RouteNames.CONTACT_BOOK_BY_ID(companyId));
  }

  public onClickContactBookAddressByIdContact = (companyId: string, addressId: string) => async () => {
    await this.navigate(RouteNames.CONTACT_BOOK_BY_ID_CONTACT_BY_ID(companyId, addressId));
  }

  public onClickContactBookItem = (companyId: string) => async () => {
    await this.navigate(RouteNames.CONTACT_BOOK_BY_ID(companyId));
  }

  public onOpenContactBookAddContact = () => {
    const customId = uuidv4();
    const addressId = uuidv4();
    const contactId = uuidv4();

    this.dispatch(RShipment.actions.bookingWizard.setWCBUpdateCompanyList([]));
    this.dispatch(RShipment.actions.bookingWizard.setWCBUpdateAddCompanyList(ContactBookDefaultStateCompanyListStateDTM.fromPlain({
      ...ContactBookInitialState.updateState.companyList?.[0],
      customId,
      addressesIds: [addressId],
      contactPersonsIds: [contactId],
    })));
    // @ts-ignore fix it
    this.dispatch(RShipment.actions.bookingWizard.setWCBUpdateAddCompanyListAddresses(EmptyCompanyAddressDTMFactory.build({
      customId: addressId,
    })));
    // @ts-ignore fix it
    this.dispatch(RShipment.actions.bookingWizard.setWCBUpdateAddCompanyListContactPersons(EmptyCompanyContactDTMFactory.build({
      customId: contactId,
    })));

    this.dispatch(RShipment.actions.bookingWizard.setWCBUpdateSetActiveListTabIndex(customId));

    this.dispatch(R.actions.accountDrawer.openDrawer(EAccountDrawerContent.CONTACT_BOOK_ADD_CONTACT));
  }

  public onEditAddressesAndContactsAddressById = (id: string) => async () => {
    const { contactBook: { defaultState } } = this.store.getState();
    this.dispatch(R.actions.contactBook.resetUpdate());
    const customId = uuidv4();

    const addressItem = defaultState.companyListAddresses.filter((item) => id === item.customId);
    if (addressItem.length) {
      this.dispatch(R.actions.contactBook.setUpdateCompanyList([]));
      this.dispatch(R.actions.contactBook.setUpdateAddCompanyList(ContactBookDefaultStateCompanyListStateDTM.fromPlain({
        ...ContactBookInitialState.updateState.companyList?.[0],
        customId,
        addressesIds: [id],
      })));

      this.dispatch(R.actions.contactBook.setUpdateAddCompanyListAddresses(EmptyCompanyAddressDTMFactory.build({
        ...addressItem?.[0],
        contacts: addressItem?.[0]?.contacts?.map((item) => CompanyContactDTM.fromPlain(item)),
        customId: id,
      })));
    }

    this.dispatch(R.actions.contactBook.setUpdateSetActiveListTabIndex(customId));

    this.dispatch(R.actions.accountDrawer.openDrawer(EAccountDrawerContent.USER_ADDRESSES_AND_CONTACTS_EDIT_ADDRESS));
  }

  public onEditSaveAddressesAndContactsAddressById = async () => {
    const { contactBook: { updateState, defaultState } } = this.store.getState();

    if (this.validateAACEdit()) {
      this.setAllVisitedAAC();
      return;
    }

    this.dispatch(R.actions.contactBook.setDefaultGetRequestStatusStatus(REQUEST_STATUS.pending));

    this.setAllVisitedAAC(false);

    const itemList = updateState.companyList[0];

    let addresses: IGetCompanyListAddressRequest[] | null = null;
    const addressesList = updateState?.companyListAddresses
      ?.filter((item) => itemList.addressesIds?.includes(item.customId || ''));
    addresses = addressesList?.map((addressItem) => ({
      address1: addressItem.address1 || null,
      address2: addressItem.address2 || null,
      city: addressItem.city || null,
      country: addressItem.country || null,
      postalCode: addressItem.postalCode || null,
      state: addressItem.state || null,
      // TODO: fixed required closestProt in be
      closestPort: addressItem.closestPort || 'USOAK',
      id: addressItem.id || null,
      company: addressItem.company || null,
      primary: !!addressItem.isPrimary,
    }))
      .filter((addressItem) => !!(addressItem.address1
        || addressItem.address2
        || addressItem.city
        || addressItem.country
        || addressItem.postalCode
        || addressItem.state
      ));

    if (!addresses?.[0] || !defaultState?.companyPrimaryState?.id) {
      return;
    }
    const companyDataRequest: IPostCompanyListAddressRequest = {
      ...addresses?.[0],
      id: addresses?.[0].id || undefined,
    };

    try {
      await R.services.contacts.putAddress(defaultState.companyPrimaryState.id, companyDataRequest.id || 0, companyDataRequest);

      await this.getCompanyPrimary();
    } catch (e) {
      this.dispatch(R.actions.contactBook.setDefaultGetRequestStatusStatus(REQUEST_STATUS.rejected));
      throw e;
    }

    this.dispatch(R.actions.contactBook.setDefaultEditCompanyListAddressesById({
      listId: addresses?.[0].customId || '',
      companyListAddresses: CompanyAddressDTM.fromPlain({ ...addressesList?.[0] }),
    }));
    this.dispatch(R.actions.contactBook.resetUpdate());

    this.dispatch(R.actions.accountDrawer.closeDrawer());

    message.success(i18n.t('Address is Updated'));

    this.dispatch(R.actions.contactBook.setDefaultGetRequestStatusStatus(REQUEST_STATUS.complete));
  }

  public closeCBByIdEditCompanyDrawer = () => {
    this.dispatch(R.actions.accountDrawer.closeDrawer());
  }

  public onCancelContactBookAddContact = () => {
    const { contactBook: { updateState } } = this.store.getState();
    const listId = updateState.activeListTabIndex;

    const companyListById = updateState.companyList?.filter((item) => item.customId === listId)?.[0];
    const companyList = updateState.companyList?.filter((item) => item.customId !== listId)
      .map((item) => ContactBookDefaultStateCompanyListStateDTM.fromPlain(item));
    const addressIds = companyListById?.addressesIds;
    const contactIds = companyListById?.contactPersonsIds;

    this.dispatch(R.actions.contactBook.setUpdateCompanyListAddresses(updateState.companyListAddresses.filter((item) => !addressIds?.includes(item.customId || '')).map((item) => CompanyAddressDTM.fromPlain(item))));
    this.dispatch(R.actions.contactBook.setUpdateCompanyListContactPersons(updateState.companyListContactPersons.filter((item) => !contactIds?.includes(item.customId || '')).map((item) => CompanyContactDTM.fromPlain(item))));
    this.dispatch(R.actions.contactBook.setUpdateCompanyList(companyList));

    this.dispatch(R.actions.accountDrawer.closeDrawer());
  }

  public onDiscardContactBook = (listIndex: string) => () => {
    const { contactBook: { defaultState, updateState } } = this.store.getState();

    this.validateDocumentsFiles();
    this.dispatch(R.actions.contactBook.resetUpdateErrorsCompanyListStateById({ listIndex }));

    if (!defaultState.companyList.length) {
      const addressId = uuidv4();
      const ContactPersonId = uuidv4();
      const addresses: CompanyAddressDTM[] = [
        ...updateState.companyListAddresses.map((item) => CompanyAddressDTM.fromPlain(item)),
        CompanyAddressDTM.fromPlain({
          ...ContactBookInitialState.updateState.companyListAddresses?.[0],
          customId: addressId,
        }),
      ];

      const contactPersons: CompanyContactDTM[] = [
        ...updateState.companyListContactPersons.map((item) => CompanyContactDTM.fromPlain(item)),
        CompanyContactDTM.fromPlain({
          ...ContactBookInitialState.updateState.companyListContactPersons?.[0],
          customId: ContactPersonId,
        })];

      const companyList: ContactBookDefaultStateCompanyListStateDTM = ContactBookDefaultStateCompanyListStateDTM.fromPlain({
        customId: listIndex,
        addressesIds: [addressId],
        contactPersonsIds: [ContactPersonId],
        ...defaultState.companyList.filter((item) => item.customId === listIndex)?.[0],
      });

      this.dispatch(R.actions.contactBook.setUpdateCompanyListByIndex({
        listId: listIndex,
        companyList: {
          ...companyList,
        },
      }));

      this.dispatch(R.actions.contactBook.setUpdateCompanyListAddresses(addresses));
      this.dispatch(R.actions.contactBook.setUpdateCompanyListContactPersons(contactPersons));

      this.dispatch(R.actions.contactBook.resetUpdateErrors());

      return;
    }

    defaultState.companyList.forEach((item) => {
      if (listIndex !== item.customId) {
        return;
      }
      const addressId = item.addressesIds;
      const ContactPersonId = item.contactPersonsIds;

      const addresses: CompanyAddressDTM[] = [
        ...defaultState.companyListAddresses.map((itemAddress) => CompanyAddressDTM.fromPlain(itemAddress)),
      ];

      const contactPersons: CompanyContactDTM[] = [
        ...defaultState.companyListContactPersons.map((itemContact) => CompanyContactDTM.fromPlain(itemContact))];

      addressId?.forEach((itemAddressId) => {
        this.dispatch(R.actions.contactBook.setUpdateEditCompanyListAddressesById({
          listId: itemAddressId,
          companyListAddresses: CompanyAddressDTM.fromPlain(addresses.filter((itemListAddress) => itemListAddress.customId === itemAddressId)?.[0]),
        }));
      });
      ContactPersonId?.forEach((itemContactId) => {
        this.dispatch(R.actions.contactBook.setUpdateEditCompanyListContactPersons({
          listId: itemContactId,
          companyListContactPersons: CompanyContactDTM.fromPlain(contactPersons.filter((itemListAddress) => itemListAddress.customId === itemContactId)?.[0]),
        }));
      });
      this.dispatch(R.actions.contactBook.setUpdateCompanyListContactPersons(contactPersons));

      const companyList: ContactBookDefaultStateCompanyListStateDTM = ContactBookDefaultStateCompanyListStateDTM.fromPlain({
        isUpdate: item.isUpdate,
        name: item.name,
        customId: listIndex,
        id: item.id,
        usci: item.usci,
        taxId: item.taxId,
        addressesIds: [...addressId || []],
        contactPersonsIds: [...ContactPersonId || []],
      });

      this.dispatch(R.actions.contactBook.setUpdateCompanyListByIndex({
        listId: listIndex,
        companyList: {
          ...companyList,
        },
      }));
      this.dispatch(R.actions.contactBook.resetUpdateErrors());
    });
  }

  public onChangeListNameByIndex = (listId: string) => (name: string) => {
    this.dispatch(R.actions.contactBook.setUpdateCompanyListById({
      listId,
      companyList: {
        name: name.slice(0, 35),
      },
    }));

    this.validateDocumentsFiles();

    this.compareUpdateState();
  };

  public onBlurListNameByIndex = (listId: string) => () => {
    this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListStateNameById({
      indexList: listId,
      error: {
        isBlur: true,
        isVisited: true,
        isFocus: false,
      },
    }));

    this.validateDocumentsFiles();
  };

  public onFocusListNameByIndex = (listId: string) => () => {
    this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListStateNameById({
      indexList: listId,
      error: {
        isBlur: false,
        isFocus: true,
      },
    }));

    this.validateDocumentsFiles();
  };

  public onChangeTaxIdByIndex = (listId: string) => (taxId: string) => {
    this.dispatch(R.actions.contactBook.setUpdateCompanyListById({
      listId,
      companyList: {
        taxId: taxId.slice(0, 35),
      },
    }));

    this.validateDocumentsFiles();

    this.compareUpdateState();
  };

  public onBlurTaxIdByIndex = (listId: string) => () => {
    this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListStateNameById({
      indexList: listId,
      error: {
        isBlur: true,
        isVisited: true,
        isFocus: false,
      },
    }));

    this.validateDocumentsFiles();
  };

  public onFocusTaxIdByIndex = (listId: string) => () => {
    this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListStateNameById({
      indexList: listId,
      error: {
        isBlur: false,
        isFocus: true,
      },
    }));

    this.validateDocumentsFiles();
  };

  public onChangeUpdateContactsContactPersonByIndex = (indexContacts: string) => (fullName: string) => {
    const { contactBook: { updateState: { companyListContactPersons } } } = this.store.getState();
    this.dispatch(R.actions.contactBook.setUpdateEditCompanyListContactPersons({
      listId: indexContacts,
      companyListContactPersons: CompanyContactDTM.fromPlain({
        ...companyListContactPersons.filter((item) => item.customId === indexContacts)?.[0],
        fullName,
      }),
    }));

    this.compareUpdateState();
  };

  public onBlurUpdateContactsContactPersonByIndex = (indexContacts: string) => () => {
    this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListStateContactsContactPersonById({
      indexList: indexContacts,
      error: {
        isBlur: true,
        isVisited: true,
        isFocus: false,
      },
    }));

    this.validateDocumentsFiles();
  };

  public onFocusUpdateContactsContactPersonByIndex = (indexContacts: string) => () => {
    this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListStateContactsContactPersonById({
      indexList: indexContacts,
      error: {
        isBlur: false,
        isFocus: true,
      },
    }));

    this.validateDocumentsFiles();
  };

  public onChangeUpdateAACContactsContactPersonByIndex = (indexContacts: string) => (fullName: string) => {
    const { contactBook: { updateState: { companyListContactPersons } } } = this.store.getState();
    this.dispatch(R.actions.contactBook.setUpdateEditCompanyListContactPersons({
      listId: indexContacts,
      companyListContactPersons: CompanyContactDTM.fromPlain({
        ...companyListContactPersons.filter((item) => item.customId === indexContacts)?.[0],
        fullName,
      }),
    }));

    this.compareUpdateStateAAC();
  };

  public onBlurUpdateAACContactsContactPersonByIndex = (indexContacts: string) => () => {
    this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListStateContactsContactPersonById({
      indexList: indexContacts,
      error: {
        isBlur: true,
        isVisited: true,
        isFocus: false,
      },
    }));

    this.validateAAC();
  };

  public onFocusUpdateAACContactsContactPersonByIndex = (indexContacts: string) => () => {
    this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListStateContactsContactPersonById({
      indexList: indexContacts,
      error: {
        isBlur: false,
        isFocus: true,
      },
    }));

    this.validateAAC();
  };

  public onChangeUpdateContactsEmailByIndex = (indexContacts: string) => (email: string) => {
    const { contactBook: { updateState: { companyListContactPersons } } } = this.store.getState();

    const { errorMessage, email: checkEmail } = validationEmail(email);

    if (errorMessage) {
      this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListStateContactsEmailById({
        indexList: indexContacts,
        error: {
          message: errorMessage,
          isTooltipVisible: true,
        },
      }));
    } else {
      this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListStateContactsEmailById({
        indexList: indexContacts,
        error: {
          message: errorMessage,
          isTooltipVisible: false,
        },
      }));
    }
    this.dispatch(R.actions.contactBook.setUpdateEditCompanyListContactPersons({
      listId: indexContacts,
      companyListContactPersons: CompanyContactDTM.fromPlain({
        ...companyListContactPersons.filter((item) => item.customId === indexContacts)?.[0],
        email: checkEmail,
      }),
    }));

    this.compareUpdateState();
  };

  public onBlurUpdateContactsEmailByIndex = (indexContacts: string) => () => {
    this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListStateContactsEmailById({
      indexList: indexContacts,
      error: {
        isBlur: true,
        isVisited: true,
        isFocus: false,
      },
    }));

    this.validateDocumentsFiles();
  };

  public onFocusUpdateContactsEmailByIndex = (indexContacts: string) => () => {
    this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListStateContactsEmailById({
      indexList: indexContacts,
      error: {
        isBlur: false,
        isFocus: true,
      },
    }));

    this.validateDocumentsFiles();
  };

  public onChangeUpdateAACContactsEmailByIndex = (indexContacts: string) => (email: string) => {
    const { contactBook: { updateState: { companyListContactPersons } } } = this.store.getState();

    const { errorMessage, email: checkEmail } = validationEmail(email);

    if (errorMessage) {
      this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListStateContactsEmailById({
        indexList: indexContacts,
        error: {
          message: errorMessage,
          isTooltipVisible: true,
        },
      }));
    } else {
      this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListStateContactsEmailById({
        indexList: indexContacts,
        error: {
          message: errorMessage,
          isTooltipVisible: false,
        },
      }));
    }

    this.dispatch(R.actions.contactBook.setUpdateEditCompanyListContactPersons({
      listId: indexContacts,
      companyListContactPersons: CompanyContactDTM.fromPlain({
        ...companyListContactPersons.filter((item) => item.customId === indexContacts)?.[0],
        email: checkEmail,
      }),
    }));

    this.compareUpdateStateAAC();
  };

  public onBlurUpdateAACContactsEmailByIndex = (indexContacts: string) => () => {
    this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListStateContactsEmailById({
      indexList: indexContacts,
      error: {
        isBlur: true,
        isVisited: true,
        isFocus: false,
      },
    }));

    this.validateAAC();
  };

  public onFocusUpdateAACContactsEmailByIndex = (indexContacts: string) => () => {
    this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListStateContactsEmailById({
      indexList: indexContacts,
      error: {
        isBlur: false,
        isFocus: true,
      },
    }));

    this.validateAAC();
  };

  public onChangeUpdateContactsPhoneByIndex = (indexContacts: string) => (phone: string) => {
    const { contactBook: { updateState: { companyListContactPersons } } } = this.store.getState();

    const { errorMessage, phone: checkPhone } = validationPhone(phone);

    if (errorMessage) {
      this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListStateContactsPhoneById({
        indexList: indexContacts,
        error: {
          message: errorMessage,
          isTooltipVisible: true,
        },
      }));
    } else {
      this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListStateContactsPhoneById({
        indexList: indexContacts,
        error: {
          message: errorMessage,
          isTooltipVisible: false,
        },
      }));
    }

    this.dispatch(R.actions.contactBook.setUpdateEditCompanyListContactPersons({
      listId: indexContacts,
      companyListContactPersons: CompanyContactDTM.fromPlain({
        ...companyListContactPersons.filter((item) => item.customId === indexContacts)?.[0],
        phone: checkPhone,
      }),
    }));

    this.compareUpdateState();
  };

  public onBlurUpdateContactsPhoneByIndex = (indexContacts: string) => () => {
    this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListStateContactsPhoneById({
      indexList: indexContacts,
      error: {
        isBlur: true,
        isVisited: true,
        isFocus: false,
      },
    }));

    this.validateDocumentsFiles();
  };

  public onFocusUpdateContactsPhoneByIndex = (indexContacts: string) => () => {
    this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListStateContactsPhoneById({
      indexList: indexContacts,
      error: {
        isBlur: false,
        isFocus: true,
      },
    }));

    this.validateDocumentsFiles();
  };

  public onChangeUpdateAACContactsPhoneByIndex = (indexContacts: string) => (phone: string) => {
    const { contactBook: { updateState: { companyListContactPersons } } } = this.store.getState();

    const { errorMessage, phone: checkPhone } = validationPhone(phone);

    if (errorMessage) {
      this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListStateContactsPhoneById({
        indexList: indexContacts,
        error: {
          message: errorMessage,
          isTooltipVisible: true,
        },
      }));
    } else {
      this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListStateContactsPhoneById({
        indexList: indexContacts,
        error: {
          message: errorMessage,
          isTooltipVisible: false,
        },
      }));
    }

    this.dispatch(R.actions.contactBook.setUpdateEditCompanyListContactPersons({
      listId: indexContacts,
      companyListContactPersons: CompanyContactDTM.fromPlain({
        ...companyListContactPersons.filter((item) => item.customId === indexContacts)?.[0],
        phone: checkPhone,
      }),
    }));

    this.compareUpdateStateAAC();
  };

  public onBlurUpdateAACContactsPhoneByIndex = (indexContacts: string) => () => {
    this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListStateContactsPhoneById({
      indexList: indexContacts,
      error: {
        isBlur: true,
        isVisited: true,
        isFocus: false,
      },
    }));

    this.validateAAC();
  };

  public onFocusUpdateAACContactsPhoneByIndex = (indexContacts: string) => () => {
    this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListStateContactsPhoneById({
      indexList: indexContacts,
      error: {
        isBlur: false,
        isFocus: true,
      },
    }));

    this.validateAAC();
  };

  public onChangeUpdateContactsAdditionalPhoneByIndex = (indexContacts: string) => (additionalPhone: string) => {
    const { contactBook: { updateState: { companyListContactPersons } } } = this.store.getState();

    const { errorMessage, phone: checkPhone } = validationPhone(additionalPhone, { isRequired: false });

    if (errorMessage) {
      this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListStateContactsAdditionalPhoneById({
        indexList: indexContacts,
        error: {
          message: errorMessage,
          isTooltipVisible: true,
        },
      }));
    } else {
      this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListStateContactsAdditionalPhoneById({
        indexList: indexContacts,
        error: {
          message: errorMessage,
          isTooltipVisible: false,
        },
      }));
    }

    this.dispatch(R.actions.contactBook.setUpdateEditCompanyListContactPersons({
      listId: indexContacts,
      companyListContactPersons: CompanyContactDTM.fromPlain({
        ...companyListContactPersons.filter((item) => item.customId === indexContacts)?.[0],
        phone2: checkPhone,
      }),
    }));

    this.compareUpdateState();
  };

  public onBlurUpdateContactsAdditionalPhoneByIndex = (indexContacts: string) => () => {
    this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListStateContactsAdditionalPhoneById({
      indexList: indexContacts,
      error: {
        isBlur: true,
        isVisited: true,
        isFocus: false,
      },
    }));

    this.validateDocumentsFiles();
  };

  public onFocusUpdateContactsAdditionalPhoneByIndex = (indexContacts: string) => () => {
    this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListStateContactsAdditionalPhoneById({
      indexList: indexContacts,
      error: {
        isBlur: false,
        isFocus: true,
      },
    }));

    this.validateDocumentsFiles();
  };

  public onChangeUpdateAACContactsAdditionalPhoneByIndex = (indexContacts: string) => (additionalPhone: string) => {
    const { contactBook: { updateState: { companyListContactPersons } } } = this.store.getState();

    const { errorMessage, phone: checkPhone } = validationPhone(additionalPhone, { isRequired: false });

    if (errorMessage) {
      this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListStateContactsAdditionalPhoneById({
        indexList: indexContacts,
        error: {
          message: errorMessage,
          isTooltipVisible: true,
        },
      }));
    } else {
      this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListStateContactsAdditionalPhoneById({
        indexList: indexContacts,
        error: {
          message: errorMessage,
          isTooltipVisible: false,
        },
      }));
    }

    this.dispatch(R.actions.contactBook.setUpdateEditCompanyListContactPersons({
      listId: indexContacts,
      companyListContactPersons: CompanyContactDTM.fromPlain({
        ...companyListContactPersons.filter((item) => item.customId === indexContacts)?.[0],
        phone2: checkPhone,
      }),
    }));

    this.compareUpdateStateAAC();
  };

  public onBlurUpdateAACContactsAdditionalPhoneByIndex = (indexContacts: string) => () => {
    this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListStateContactsAdditionalPhoneById({
      indexList: indexContacts,
      error: {
        isBlur: true,
        isVisited: true,
        isFocus: false,
      },
    }));

    this.validateAAC();
  };

  public onFocusUpdateAACContactsAdditionalPhoneByIndex = (indexContacts: string) => () => {
    this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListStateContactsAdditionalPhoneById({
      indexList: indexContacts,
      error: {
        isBlur: false,
        isFocus: true,
      },
    }));

    this.validateAAC();
  };

  public onChangeUpdateAddressesAddressLineFirstByIndex = (indexAddresses: string) => (addressLineFirst: string) => {
    const { contactBook: { updateState: { companyListAddresses } } } = this.store.getState();

    this.dispatch(R.actions.contactBook.setUpdateEditCompanyListAddressesById({
      listId: indexAddresses,
      companyListAddresses: CompanyAddressDTM.fromPlain({
        ...companyListAddresses.filter((item) => item.customId === indexAddresses)?.[0],
        address1: addressLineFirst.slice(0, 50),
      }),
    }));

    this.compareUpdateState();
  };

  public onBlurUpdateAddressesAddressLineFirstByIndex = (indexAddresses: string) => () => {
    this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListAddressesAddressLineFirstById({
      indexList: indexAddresses,
      error: {
        isBlur: true,
        isVisited: true,
        isFocus: false,
      },
    }));

    this.validateDocumentsFiles();
  };

  public onFocusUpdateAddressesAddressLineFirstByIndex = (indexAddresses: string) => () => {
    this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListAddressesAddressLineFirstById({
      indexList: indexAddresses,
      error: {
        isBlur: false,
        isFocus: true,
      },
    }));

    this.validateDocumentsFiles();
  };

  public onChangeUpdateAACAddressLineFirstByIndex = (indexAddresses: string) => (addressLineFirst: string) => {
    const { contactBook: { updateState: { companyListAddresses } } } = this.store.getState();

    this.dispatch(R.actions.contactBook.setUpdateEditCompanyListAddressesById({
      listId: indexAddresses,
      companyListAddresses: CompanyAddressDTM.fromPlain({
        ...companyListAddresses.filter((item) => item.customId === indexAddresses)?.[0],
        address1: addressLineFirst.slice(0, 50),
      }),
    }));

    this.compareUpdateStateAAC();
  };

  public onBlurUpdateAACAddressLineFirstByIndex = (indexAddresses: string) => () => {
    this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListAddressesAddressLineFirstById({
      indexList: indexAddresses,
      error: {
        isBlur: true,
        isVisited: true,
        isFocus: false,
      },
    }));

    this.validateAAC();
  };

  public onFocusUpdateAACAddressLineFirstByIndex = (indexAddresses: string) => () => {
    this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListAddressesAddressLineFirstById({
      indexList: indexAddresses,
      error: {
        isBlur: false,
        isFocus: true,
      },
    }));

    this.validateAAC();
  };

  public onChangeUpdateAddressesAddressLineSecondByIndex = (indexAddresses: string) => (addressLineSecond: string) => {
    const { contactBook: { updateState: { companyListAddresses } } } = this.store.getState();

    this.dispatch(R.actions.contactBook.setUpdateEditCompanyListAddressesById({
      listId: indexAddresses,
      companyListAddresses: CompanyAddressDTM.fromPlain({
        ...companyListAddresses.filter((item) => item.customId === indexAddresses)?.[0],
        address2: addressLineSecond.slice(0, 50),
      }),
    }));

    this.compareUpdateState();
  };

  public onBlurUpdateAddressesAddressLineSecondByIndex = (indexAddresses: string) => () => {
    this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListAddressesAddressLineSecondById({
      indexList: indexAddresses,
      error: {
        isBlur: true,
        isVisited: true,
        isFocus: false,
      },
    }));

    this.validateDocumentsFiles();
  };

  public onFocusUpdateAddressesAddressLineSecondByIndex = (indexAddresses: string) => () => {
    this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListAddressesAddressLineSecondById({
      indexList: indexAddresses,
      error: {
        isBlur: false,
        isFocus: true,
      },
    }));

    this.validateDocumentsFiles();
  };

  public onChangeUpdateAACAddressLineSecondByIndex = (indexAddresses: string) => (addressLineSecond: string) => {
    const { contactBook: { updateState: { companyListAddresses } } } = this.store.getState();

    this.dispatch(R.actions.contactBook.setUpdateEditCompanyListAddressesById({
      listId: indexAddresses,
      companyListAddresses: CompanyAddressDTM.fromPlain({
        ...companyListAddresses.filter((item) => item.customId === indexAddresses)?.[0],
        address2: addressLineSecond.slice(0, 50),
      }),
    }));

    this.compareUpdateStateAAC();
  };

  public onBlurUpdateAACAddressLineSecondByIndex = (indexAddresses: string) => () => {
    this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListAddressesAddressLineSecondById({
      indexList: indexAddresses,
      error: {
        isBlur: true,
        isVisited: true,
        isFocus: false,
      },
    }));

    this.validateAAC();
  };

  public onFocusUpdateAACAddressLineSecondByIndex = (indexAddresses: string) => () => {
    this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListAddressesAddressLineSecondById({
      indexList: indexAddresses,
      error: {
        isBlur: false,
        isFocus: true,
      },
    }));

    this.validateAAC();
  };

  public onChangeUpdateAddressesCityByIndex = (indexAddresses: string) => (city: string) => {
    const { contactBook: { updateState: { companyListAddresses } } } = this.store.getState();

    this.dispatch(R.actions.contactBook.setUpdateEditCompanyListAddressesById({
      listId: indexAddresses,
      companyListAddresses: CompanyAddressDTM.fromPlain({
        ...companyListAddresses.filter((item) => item.customId === indexAddresses)?.[0],
        city: city.slice(0, 50),
      }),
    }));

    this.compareUpdateState();
  };

  public onBlurUpdateAddressesCityByIndex = (indexAddresses: string) => () => {
    this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListAddressesCityById({
      indexList: indexAddresses,
      error: {
        isBlur: true,
        isVisited: true,
        isFocus: false,
      },
    }));

    this.validateDocumentsFiles();
  };

  public onFocusUpdateAddressesCityByIndex = (indexAddresses: string) => () => {
    this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListAddressesCityById({
      indexList: indexAddresses,
      error: {
        isBlur: false,
        isFocus: true,
      },
    }));

    this.validateDocumentsFiles();
  };

  public onChangeUpdateAACCityByIndex = (indexAddresses: string) => (city: string) => {
    const { contactBook: { updateState: { companyListAddresses } } } = this.store.getState();

    this.dispatch(R.actions.contactBook.setUpdateEditCompanyListAddressesById({
      listId: indexAddresses,
      companyListAddresses: CompanyAddressDTM.fromPlain({
        ...companyListAddresses.filter((item) => item.customId === indexAddresses)?.[0],
        city: city.slice(0, 50),
      }),
    }));

    this.compareUpdateStateAAC();
  };

  public onBlurUpdateAACCityByIndex = (indexAddresses: string) => () => {
    this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListAddressesCityById({
      indexList: indexAddresses,
      error: {
        isBlur: true,
        isVisited: true,
        isFocus: false,
      },
    }));

    this.validateAAC();
  };

  public onFocusUpdateAACCityByIndex = (indexAddresses: string) => () => {
    this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListAddressesCityById({
      indexList: indexAddresses,
      error: {
        isBlur: false,
        isFocus: true,
      },
    }));

    this.validateAAC();
  };

  public onChangeUpdateAddressesCountryByIndex = (indexAddresses: string) => (country: string) => {
    const { contactBook: { updateState: { companyListAddresses } } } = this.store.getState();

    const companyListAddress = companyListAddresses.filter((item) => item.customId === indexAddresses)?.[0];

    this.dispatch(R.actions.contactBook.setUpdateEditCompanyListAddressesById({
      listId: indexAddresses,
      companyListAddresses: CompanyAddressDTM.fromPlain({
        ...companyListAddress,
        country: country?.slice(0, 50) || '',
        state: undefined,
      }),
    }));

    this.compareUpdateState();
  };

  public onBlurUpdateAddressesCountryByIndex = (indexAddresses: string) => () => {
    this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListAddressesCountryById({
      indexList: indexAddresses,
      error: {
        isBlur: true,
        isVisited: true,
        isFocus: false,
      },
    }));

    this.validateDocumentsFiles();
  };

  public onFocusUpdateAddressesCountryByIndex = (indexAddresses: string) => () => {
    this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListAddressesCountryById({
      indexList: indexAddresses,
      error: {
        isBlur: false,
        isFocus: true,
      },
    }));

    this.validateDocumentsFiles();
  };

  public onClearUpdateAddressesCountryByIndex = (indexAddresses: string) => () => {
    const { contactBook: { updateState: { companyListAddresses } } } = this.store.getState();

    this.dispatch(R.actions.contactBook.setUpdateEditCompanyListAddressesById({
      listId: indexAddresses,
      companyListAddresses: CompanyAddressDTM.fromPlain({
        ...companyListAddresses.filter((item) => item.customId === indexAddresses)?.[0],
        country: '',
        state: undefined,
      }),
    }));

    this.compareUpdateState();
    this.validateDocumentsFiles();
  };

  public onChangeUpdateAACCountryByIndex = (indexAddresses: string) => (country: string) => {
    const { contactBook: { updateState: { companyListAddresses } } } = this.store.getState();

    const companyListAddress = companyListAddresses.filter((item) => item.customId === indexAddresses)?.[0];

    this.dispatch(R.actions.contactBook.setUpdateEditCompanyListAddressesById({
      listId: indexAddresses,
      companyListAddresses: CompanyAddressDTM.fromPlain({
        ...companyListAddress,
        country: country?.slice(0, 50) || '',
        state: undefined,
      }),
    }));

    this.compareUpdateStateAAC();
  };

  public onBlurUpdateAACCountryByIndex = (indexAddresses: string) => () => {
    this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListAddressesCountryById({
      indexList: indexAddresses,
      error: {
        isBlur: true,
        isVisited: true,
        isFocus: false,
      },
    }));

    this.validateAAC();
  };

  public onFocusUpdateAACCountryByIndex = (indexAddresses: string) => () => {
    this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListAddressesCountryById({
      indexList: indexAddresses,
      error: {
        isBlur: false,
        isFocus: true,
      },
    }));

    this.validateAAC();
  };

  public onClearUpdateAACCountryByIndex = (indexAddresses: string) => () => {
    const { contactBook: { updateState: { companyListAddresses } } } = this.store.getState();

    const companyListAddress = companyListAddresses.filter((item) => item.customId === indexAddresses)?.[0];

    this.dispatch(R.actions.contactBook.setUpdateEditCompanyListAddressesById({
      listId: indexAddresses,
      companyListAddresses: CompanyAddressDTM.fromPlain({
        ...companyListAddress,
        country: '',
        state: undefined,
      }),
    }));

    this.compareUpdateStateAAC();
    this.validateAAC();
  };

  public onChangeUpdateAddressesStateByIndex = (indexAddresses: string) => (state: string) => {
    const { contactBook: { updateState: { companyListAddresses } } } = this.store.getState();

    this.dispatch(R.actions.contactBook.setUpdateEditCompanyListAddressesById({
      listId: indexAddresses,
      companyListAddresses: CompanyAddressDTM.fromPlain({
        ...companyListAddresses.filter((item) => item.customId === indexAddresses)?.[0],
        state: state.slice(0, 25),
      }),
    }));

    this.compareUpdateState();
  };

  public onClearUpdateAddressesStateByIndex = (indexAddresses: string) => () => {
    const { contactBook: { updateState: { companyListAddresses } } } = this.store.getState();

    this.dispatch(R.actions.contactBook.setUpdateEditCompanyListAddressesById({
      listId: indexAddresses,
      companyListAddresses: CompanyAddressDTM.fromPlain({
        ...companyListAddresses.filter((item) => item.customId === indexAddresses)?.[0],
        state: undefined,
      }),
    }));

    this.compareUpdateStateAAC();
    this.validateAAC();
  };

  public onBlurUpdateAddressesStateByIndex = (indexAddresses: string) => () => {
    this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListAddressesStateById({
      indexList: indexAddresses,
      error: {
        isBlur: true,
        isVisited: true,
        isFocus: false,
      },
    }));

    this.validateDocumentsFiles();
  };

  public onFocusUpdateAddressesStateByIndex = (indexAddresses: string) => () => {
    this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListAddressesStateById({
      indexList: indexAddresses,
      error: {
        isBlur: false,
        isFocus: true,
      },
    }));

    this.validateDocumentsFiles();
  };

  public onChangeUpdateAACStateByIndex = (indexAddresses: string) => (state: string) => {
    const { contactBook: { updateState: { companyListAddresses } } } = this.store.getState();

    this.dispatch(R.actions.contactBook.setUpdateEditCompanyListAddressesById({
      listId: indexAddresses,
      companyListAddresses: CompanyAddressDTM.fromPlain({
        ...companyListAddresses.filter((item) => item.customId === indexAddresses)?.[0],
        state: state.slice(0, 25),
      }),
    }));

    this.compareUpdateStateAAC();
  };

  public onClearUpdateAACStateByIndex = (indexAddresses: string) => () => {
    const { contactBook: { updateState: { companyListAddresses } } } = this.store.getState();

    this.dispatch(R.actions.contactBook.setUpdateEditCompanyListAddressesById({
      listId: indexAddresses,
      companyListAddresses: CompanyAddressDTM.fromPlain({
        ...companyListAddresses.filter((item) => item.customId === indexAddresses)?.[0],
        state: undefined,
      }),
    }));

    this.compareUpdateStateAAC();
    this.validateAAC();
  };

  public onBlurUpdateAACStateByIndex = (indexAddresses: string) => () => {
    this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListAddressesStateById({
      indexList: indexAddresses,
      error: {
        isBlur: true,
        isVisited: true,
        isFocus: false,
      },
    }));

    this.validateAAC();
  };

  public onFocusUpdateAACStateByIndex = (indexAddresses: string) => () => {
    this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListAddressesStateById({
      indexList: indexAddresses,
      error: {
        isBlur: false,
        isFocus: true,
      },
    }));

    this.validateAAC();
  };

  public onChangeUpdateAddressesPostalCodeByIndex = (indexAddresses: string) => (postalCode: string) => {
    const { contactBook: { updateState: { companyListAddresses } } } = this.store.getState();

    this.dispatch(R.actions.contactBook.setUpdateEditCompanyListAddressesById({
      listId: indexAddresses,
      companyListAddresses: CompanyAddressDTM.fromPlain({
        ...companyListAddresses.filter((item) => item.customId === indexAddresses)?.[0],
        postalCode: postalCode.slice(0, 10),
      }),
    }));

    this.compareUpdateState();
  };

  public onBlurUpdateAddressesPostalCodeByIndex = (indexAddresses: string) => () => {
    this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListAddressesPostalCodeById({
      indexList: indexAddresses,
      error: {
        isBlur: true,
        isVisited: true,
        isFocus: false,
      },
    }));

    this.validateDocumentsFiles();
  };

  public onFocusUpdateAddressesPostalCodeByIndex = (indexAddresses: string) => () => {
    this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListAddressesPostalCodeById({
      indexList: indexAddresses,
      error: {
        isBlur: false,
        isFocus: true,
      },
    }));

    this.validateDocumentsFiles();
  };

  public onChangeUpdateAACIsPrimaryByIndex = (indexAddresses: string) => (isPrimary: boolean) => {
    const { contactBook: { updateState: { companyListAddresses } } } = this.store.getState();

    this.dispatch(R.actions.contactBook.setUpdateEditCompanyListAddressesById({
      listId: indexAddresses,
      companyListAddresses: CompanyAddressDTM.fromPlain({
        ...companyListAddresses.filter((item) => item.customId === indexAddresses)?.[0],
        isPrimary,
      }),
    }));

    this.compareUpdateStateAAC();
  };

  public onChangeUpdateAACPostalCodeByIndex = (indexAddresses: string) => (postalCode: string) => {
    const { contactBook: { updateState: { companyListAddresses } } } = this.store.getState();

    this.dispatch(R.actions.contactBook.setUpdateEditCompanyListAddressesById({
      listId: indexAddresses,
      companyListAddresses: CompanyAddressDTM.fromPlain({
        ...companyListAddresses.filter((item) => item.customId === indexAddresses)?.[0],
        postalCode: postalCode.slice(0, 10),
      }),
    }));

    this.compareUpdateStateAAC();
  };

  public onBlurUpdateAACPostalCodeByIndex = (indexAddresses: string) => () => {
    this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListAddressesPostalCodeById({
      indexList: indexAddresses,
      error: {
        isBlur: true,
        isVisited: true,
        isFocus: false,
      },
    }));

    this.validateAAC();
  };

  public onFocusUpdateAACPostalCodeByIndex = (indexAddresses: string) => () => {
    this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListAddressesPostalCodeById({
      indexList: indexAddresses,
      error: {
        isBlur: false,
        isFocus: true,
      },
    }));

    this.validateAAC();
  };

  public setUpdateAddCompanyListStateAddressItem = (listId: string) => () => {
    const addressId = uuidv4();

    this.dispatch(R.actions.contactBook.setUpdateAddCompanyListAddresses(CompanyAddressDTM.fromPlain({
      ...ContactBookInitialState.updateState.companyListAddresses[0],
      customId: addressId,
    })));
    this.dispatch(R.actions.contactBook.setUpdateAddCompanyListAddressByIndex({
      listId,
      addressesId: addressId,
    }));

    this.validateDocumentsFiles();

    this.compareUpdateState();
  };

  public setUpdateRemoveCompanyListStateAddressItem = (indexList: string, indexValue: string) => () => {
    this.dispatch(R.actions.contactBook.setUpdateRemoveCompanyListAddressByIndex({
      listId: indexList,
      addressesId: indexValue,
    }));

    this.validateDocumentsFiles();

    this.compareUpdateState();
  };

  public setUpdateAACAddCompanyListStateAddressItem = (indexAddresses: string) => () => {
    const addressId = uuidv4();

    this.dispatch(R.actions.contactBook.setUpdateAddCompanyListAddressByIndex({
      listId: indexAddresses,
      addressesId: addressId,
    }));
    this.dispatch(R.actions.contactBook.setUpdateAddCompanyListAddresses(CompanyAddressDTM.fromPlain({
      ...ContactBookInitialState.updateState.companyListAddresses[0],
      customId: addressId,
    })));

    this.validateAAC();

    this.compareUpdateStateAAC();
  };

  public setUpdateAACRemoveCompanyListStateAddressItem = (indexList: string, indexValue: string) => () => {
    this.dispatch(R.actions.contactBook.setUpdateRemoveCompanyListAddressByIndex({
      listId: indexList,
      addressesId: indexValue,
    }));
    this.dispatch(R.actions.contactBook.removeUpdateCompanyListAddressesIdsById(indexList));

    this.validateAAC();

    this.compareUpdateStateAAC();
  };

  public changeErrorAfterRemoveCompanyListStateAddressItem = (indexAddresses: string, addresses: IContactBookUpdateStateAddressesErrorsDTM) => {
    this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListAddresses({
      indexList: indexAddresses,
      addresses,
    }));

    this.validateDocumentsFiles();

    this.compareUpdateState();
  };

  public setUpdateAddCompanyListStateContactPersonByIndex = (listId: string) => () => {
    const contactId = uuidv4();

    this.dispatch(R.actions.contactBook.setUpdateAddCompanyListContactPersons(CompanyContactDTM.fromPlain({
      ...ContactBookInitialState.updateState.companyListContactPersons[0],
      customId: contactId,
    })));

    this.dispatch(R.actions.contactBook.setUpdateAddCompanyListContactPersonByIndex({
      listId,
      contactPersonId: contactId,
    }));

    this.validateDocumentsFiles();

    this.compareUpdateState();
  };

  public setUpdateAddActiveCompanyEditContactPerson = (id: string) => () => {
    const { contactBook: { updateState: { activeCompanyEdit } } } = this.store.getState();
    const contactId = uuidv4() || id;

    this.dispatch(R.actions.contactBook.setUpdateAddCompanyListContactPersons(CompanyContactDTM.fromPlain({
      ...ContactBookInitialState.updateState.companyListContactPersons[0],
      customId: contactId,
    })));

    this.dispatch(R.actions.contactBook.setUpdateActiveCompanyEdit(ContactBookDefaultStateCompanyListStateDTM.fromPlain({
      ...EmptyContactBookDefaultStateCompanyListStateDTMFactory.build(),
      ...activeCompanyEdit,
      contactPersonsIds: [
        ...activeCompanyEdit?.contactPersonsIds || [],
        contactId,
      ],
    })));

    this.validateDocumentsFiles();

    this.compareUpdateState();
  };

  public setUpdateAACAddCompanyListStateContactPersonByIndex = (indexList: string) => () => {
    const contactPersonId = uuidv4();

    this.dispatch(R.actions.contactBook.setUpdateAddCompanyListContactPersonByIndex({
      listId: indexList,
      contactPersonId,
    }));
    this.dispatch(R.actions.contactBook.setUpdateAddCompanyListContactPersons(CompanyContactDTM.fromPlain({
      ...ContactBookInitialState.updateState.companyListContactPersons[0],
      customId: contactPersonId,
    })));
    this.validateAAC();

    this.compareUpdateStateAAC();
  };

  public setUpdateRemoveCompanyListStateContactPersonItem = (indexList: string, indexValue: string) => () => {
    this.dispatch(R.actions.contactBook.setUpdateRemoveCompanyListContactPersonByIndex({
      listId: indexList,
      contactPersonId: indexValue,
    }));

    this.validateDocumentsFiles();

    this.compareUpdateState();
  };

  public setUpdateRemoveActiveCompanyEditContactPersonItem = (indexList: string, contactId: string) => () => {
    const { contactBook: { updateState: { activeCompanyEdit } } } = this.store.getState();

    this.dispatch(R.actions.contactBook.removeUpdateContactsById(contactId));

    this.dispatch(R.actions.contactBook.setUpdateActiveCompanyEdit(ContactBookDefaultStateCompanyListStateDTM.fromPlain({
      ...EmptyContactBookDefaultStateCompanyListStateDTMFactory.build(),
      ...activeCompanyEdit,
      contactPersonsIds: [
        ...activeCompanyEdit?.contactPersonsIds?.filter((item) => item !== contactId) || [],
      ],
    })));

    this.validateDocumentsFiles();

    this.compareUpdateState();
  };

  public setUpdateAACRemoveCompanyListStateContactPersonItem = (indexList: string, indexValue: string) => () => {
    this.dispatch(R.actions.contactBook.setUpdateRemoveCompanyListContactPersonByIndex({
      listId: indexList,
      contactPersonId: indexValue,
    }));

    this.validateAAC();

    this.compareUpdateStateAAC();
  };

  private validateAACContactPerson = () => {
    const { contactBook: { updateState: { contactPersonForm: contactPersonFormState } } } = this.store.getState();
    let hasError = false;
    const contactPersonForm = contactPersonFormState && CompanyContactDTM.fromPlain({ ...contactPersonFormState });

    this.dispatch(R.actions.contactBook.setUpdateContactPersonFullNameFormErrors(
      ContactBookStateErrorsFieldDTM.fromPlain({
        ...contactPersonForm?.fullNameErrors,
        message: contactPersonForm?.getValidateFullName()?.message,
      }),
    ));
    const getValidateEmail = contactPersonForm?.getValidateEmail();
    this.dispatch(R.actions.contactBook.setUpdateContactPersonEmailFormErrors(
      ContactBookStateErrorsFieldDTM.fromPlain({
        ...contactPersonForm?.emailErrors,
        message: getValidateEmail?.message,
        isTooltipVisible: getValidateEmail?.isTooltipVisible,
      }),
    ));
    const getValidatePhone = contactPersonForm?.getValidatePhone();
    this.dispatch(R.actions.contactBook.setUpdateContactPersonPhoneFormErrors(
      ContactBookStateErrorsFieldDTM.fromPlain({
        ...contactPersonForm?.phoneErrors,
        message: getValidatePhone?.message,
        isTooltipVisible: getValidatePhone?.isTooltipVisible,
      }),
    ));
    const getValidatePhone2 = contactPersonForm?.getValidatePhone2();
    this.dispatch(R.actions.contactBook.setUpdateContactPersonPhone2FormErrors(
      ContactBookStateErrorsFieldDTM.fromPlain({
        ...contactPersonForm?.phone2Errors,
        message: getValidatePhone2?.message,
        isTooltipVisible: getValidatePhone2?.isTooltipVisible,
      }),
    ));

    hasError = !!(contactPersonForm?.hasErrorFullName() || contactPersonForm?.hasErrorEmail() || contactPersonForm?.hasErrorPhone() || contactPersonForm?.hasErrorPhone2());

    return hasError;
  }

  private parseResponse = (data: ICompanyDTM[]) => {
    const companyLists: IContactBookDefaultStateCompanyListStateDTM[] = [];

    data.forEach((item) => {
      const companyList: IContactBookDefaultStateCompanyListStateDTM = {
        ...ContactBookInitialState.updateState.companyList[0],
        name: item.name,
        id: item.id,
        customId: item.customId,
        addressesIds: item.addressesIds,
        contactPersonsIds: item.contactPersonsIds,
        usci: item.usci || null,
        taxId: item.taxId || null,
        isPrimary: item.isPrimary,
        primaryContactCompany: ContactBookDefaultStateCompanyListStateContactPersonDTM.fromPlain({
          id: Number(item.primaryContact?.id),
          email: `${item.primaryContact?.email || ''}`,
          customId: item.customId,
          contactPerson: `${item.primaryContact?.fullName || ''}`,
          phone: `${item.primaryContact?.phone || ''}`,
          additionalPhone: `${item.primaryContact?.phone2 || ''}`,
        }),
      };

      companyLists.push(companyList);
    });

    return companyLists;
  }

  private setIsVisitedAACContactPerson = (flag = true) => {
    const { contactBook: { updateState: { contactPersonForm } } } = this.store.getState();

    this.dispatch(R.actions.contactBook.setUpdateContactPersonFullNameFormErrors(
      ContactBookStateErrorsFieldDTM.fromPlain({
        ...contactPersonForm?.fullNameErrors,
        isVisited: flag,
      }),
    ));
    this.dispatch(R.actions.contactBook.setUpdateContactPersonEmailFormErrors(
      ContactBookStateErrorsFieldDTM.fromPlain({
        ...contactPersonForm?.emailErrors,
        isVisited: flag,
      }),
    ));
    this.dispatch(R.actions.contactBook.setUpdateContactPersonPhoneFormErrors(
      ContactBookStateErrorsFieldDTM.fromPlain({
        ...contactPersonForm?.phoneErrors,
        isVisited: flag,
      }),
    ));
    this.dispatch(R.actions.contactBook.setUpdateContactPersonPhone2FormErrors(
      ContactBookStateErrorsFieldDTM.fromPlain({
        ...contactPersonForm?.phone2Errors,
        isVisited: flag,
      }),
    ));
  }

  private setAllVisited = (flag = true) => {
    const { contactBook: { updateState: { errors } } } = this.store.getState();

    errors.companyListState?.forEach((companyList) => {
      const indexList = companyList.customId;

      this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListStateNameById({
        indexList,
        error: {
          isVisited: flag,
        },
      }));
      this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListStateTaxIdById({
        indexList,
        error: {
          isVisited: flag,
        },
      }));

      errors.addresses?.forEach((addressKey) => {
        const addressIndex = addressKey.customId;

        this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListAddressesAddressLineFirstById({
          indexList: addressIndex,
          error: {
            isVisited: flag,
          },
        }));
        this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListAddressesCityById({
          indexList: addressIndex,
          error: {
            isVisited: flag,
          },
        }));
        this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListAddressesCountryById({
          indexList: addressIndex,
          error: {
            isVisited: flag,
          },
        }));
        this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListAddressesStateById({
          indexList: addressIndex,
          error: {
            isVisited: flag,
          },
        }));
        this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListAddressesPostalCodeById({
          indexList: addressIndex,
          error: {
            isVisited: flag,
          },
        }));
      });

      errors.contactPersons?.forEach((contactPersonKey) => {
        const contactIndex = contactPersonKey.customId;

        this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListStateContactsContactPersonById({
          indexList: contactIndex,
          error: {
            isVisited: flag,
          },
        }));
        this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListStateContactsEmailById({
          indexList: contactIndex,
          error: {
            isVisited: flag,
          },
        }));
        this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListStateContactsPhoneById({
          indexList: contactIndex,
          error: {
            isVisited: flag,
          },
        }));
      });
    });
  }

  private setAllVisitedContactBookByIdEditDrawer = (flag = true) => {
    const { contactBook: { updateState: { errorsEditCompanyDetailsDrawer } } } = this.store.getState();

    this.dispatch(R.actions.contactBook.setUpdateErrorsEditCompanyDetailsDrawer({
      customId: '',
      ...errorsEditCompanyDetailsDrawer,
      name: {
        ...errorsEditCompanyDetailsDrawer?.name,
        isVisited: flag,
      },
      taxId: {
        ...errorsEditCompanyDetailsDrawer?.taxId,
        isVisited: flag,
      },
    }));
  }

  private setAllVisitedAAC = (flag = true) => {
    const { contactBook: { updateState: { errors } } } = this.store.getState();

    errors.companyListState?.forEach((companyList) => {
      const indexList = companyList.customId;

      this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListStateNameById({
        indexList,
        error: {
          isVisited: flag,
        },
      }));

      errors.addresses?.forEach((addressKey) => {
        const addressIndex = addressKey.customId;

        this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListAddressesAddressLineFirstById({
          indexList: addressIndex,
          error: {
            isVisited: flag,
          },
        }));
        this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListAddressesCityById({
          indexList: addressIndex,
          error: {
            isVisited: flag,
          },
        }));
        this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListAddressesCountryById({
          indexList: addressIndex,
          error: {
            isVisited: flag,
          },
        }));
        this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListAddressesStateById({
          indexList: addressIndex,
          error: {
            isVisited: flag,
          },
        }));
        this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListAddressesPostalCodeById({
          indexList: addressIndex,
          error: {
            isVisited: flag,
          },
        }));
      });

      errors.contactPersons?.forEach((contactPersonKey) => {
        const contactIndex = contactPersonKey.customId;

        this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListStateContactsContactPersonById({
          indexList: contactIndex,
          error: {
            isVisited: flag,
          },
        }));
        this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListStateContactsEmailById({
          indexList: contactIndex,
          error: {
            isVisited: flag,
          },
        }));
        this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListStateContactsPhoneById({
          indexList: contactIndex,
          error: {
            isVisited: flag,
          },
        }));
      });
    });
  }

  private validateContactBookByIdEditDrawer = () => {
    const { contactBook: { updateState } } = this.store.getState();
    let hasError = false;
    const companyListFind = updateState.activeCompanyEdit;

    if (!companyListFind?.name) {
      const newState = this.store.getState().contactBook.updateState;
      this.dispatch(R.actions.contactBook.setUpdateErrorsEditCompanyDetailsDrawer({
        customId: '',
        ...newState.errorsEditCompanyDetailsDrawer,
        name: {
          ...newState.errorsEditCompanyDetailsDrawer?.name,
          message: i18n.t('basicErrors.REQUIRED_MESSAGE'),
          isTooltipVisible: false,
        },
      }));

      hasError = true;
    } else {
      const newState = this.store.getState().contactBook.updateState;
      this.dispatch(R.actions.contactBook.setUpdateErrorsEditCompanyDetailsDrawer({
        customId: '',
        ...newState.errorsEditCompanyDetailsDrawer,
        name: {
          ...newState.errorsEditCompanyDetailsDrawer?.name,
          message: '',
          isTooltipVisible: false,
        },
      }));
    }

    if (!companyListFind?.taxId) {
      const newState = this.store.getState().contactBook.updateState;
      this.dispatch(R.actions.contactBook.setUpdateErrorsEditCompanyDetailsDrawer({
        customId: '',
        ...newState.errorsEditCompanyDetailsDrawer,
        taxId: {
          ...newState.errorsEditCompanyDetailsDrawer?.taxId,
          message: i18n.t('basicErrors.REQUIRED_MESSAGE'),
          isTooltipVisible: false,
        },
      }));

      hasError = true;
    } else {
      const newState = this.store.getState().contactBook.updateState;
      this.dispatch(R.actions.contactBook.setUpdateErrorsEditCompanyDetailsDrawer({
        customId: '',
        ...newState.errorsEditCompanyDetailsDrawer,
        taxId: {
          ...newState.errorsEditCompanyDetailsDrawer?.taxId,
          message: '',
          isTooltipVisible: false,
        },
      }));
    }

    return hasError;
  }

  private validateDocumentsFiles = () => {
    const { contactBook: { updateState } } = this.store.getState();
    const listId = updateState.activeListTabIndex;
    let hasError = false;
    const companyListFind = updateState.companyList?.filter((item) => listId === item.customId)?.[0];
    const companyListAddressFind = updateState?.companyListAddresses?.filter((item) => companyListFind?.addressesIds?.includes(item.customId || ''));

    if (!companyListFind?.name) {
      this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListStateNameById({
        indexList: listId,
        error: {
          message: i18n.t('basicErrors.REQUIRED_MESSAGE'),
          isTooltipVisible: false,
        },
      }));
      hasError = true;
    } else {
      this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListStateNameById({
        indexList: listId,
        error: {
          message: '',
          isTooltipVisible: false,
        },
      }));
    }

    if (!companyListFind?.taxId) {
      this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListStateTaxIdById({
        indexList: listId,
        error: {
          message: i18n.t('basicErrors.REQUIRED_MESSAGE'),
          isTooltipVisible: false,
        },
      }));
      hasError = true;
    } else {
      this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListStateTaxIdById({
        indexList: listId,
        error: {
          message: '',
          isTooltipVisible: false,
        },
      }));
    }

    companyListAddressFind?.forEach((addressItem, addressIndex) => {
      if (addressItem.address1
        || addressItem.address2
        || addressItem.city
        || addressItem.country
        || addressItem.state
        || addressItem.postalCode
        || addressIndex === 0
      ) {
        if (!addressItem.address1) {
          this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListAddressesAddressLineFirstById({
            indexList: `${addressItem.customId}`,
            indexValue: addressIndex,
            error: {
              message: i18n.t('basicErrors.REQUIRED_MESSAGE'),
            },
          }));
          hasError = true;
        } else {
          this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListAddressesAddressLineFirstById({
            indexList: `${addressItem.customId}`,
            indexValue: addressIndex,
            error: {
              message: '',
            },
          }));
        }
        if (!addressItem.city) {
          this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListAddressesCityById({
            indexList: `${addressItem.customId}`,
            indexValue: addressIndex,
            error: {
              message: i18n.t('basicErrors.REQUIRED_MESSAGE'),
            },
          }));
          hasError = true;
        } else {
          this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListAddressesCityById({
            indexList: `${addressItem.customId}`,
            indexValue: addressIndex,
            error: {
              message: '',
            },
          }));
        }
        if (!addressItem.country) {
          this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListAddressesCountryById({
            indexList: `${addressItem.customId}`,
            indexValue: addressIndex,
            error: {
              message: i18n.t('basicErrors.REQUIRED_MESSAGE'),
            },
          }));
          hasError = true;
        } else {
          this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListAddressesCountryById({
            indexList: `${addressItem.customId}`,
            indexValue: addressIndex,
            error: {
              message: '',
            },
          }));
        }
        if (addressItem.country === 'US' && !addressItem.state) {
          this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListAddressesStateById({
            indexList: `${addressItem.customId}`,
            indexValue: addressIndex,
            error: {
              message: i18n.t('basicErrors.REQUIRED_MESSAGE'),
            },
          }));
          hasError = true;
        } else {
          this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListAddressesStateById({
            indexList: `${addressItem.customId}`,
            indexValue: addressIndex,
            error: {
              message: '',
            },
          }));
        }
        if (!addressItem.postalCode) {
          this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListAddressesPostalCodeById({
            indexList: `${addressItem.customId}`,
            indexValue: addressIndex,
            error: {
              message: i18n.t('basicErrors.REQUIRED_MESSAGE'),
            },
          }));
          hasError = true;
        } else {
          this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListAddressesPostalCodeById({
            indexList: `${addressItem.customId}`,
            indexValue: addressIndex,
            error: {
              message: '',
            },
          }));
        }
      } else {
        this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListAddressesAddressLineFirstById({
          indexList: `${addressItem.customId}`,
          indexValue: addressIndex,
          error: {
            message: '',
          },
        }));
        this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListAddressesCityById({
          indexList: `${addressItem.customId}`,
          indexValue: addressIndex,
          error: {
            message: '',
          },
        }));
        this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListAddressesCountryById({
          indexList: `${addressItem.customId}`,
          indexValue: addressIndex,
          error: {
            message: '',
          },
        }));
        this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListAddressesStateById({
          indexList: `${addressItem.customId}`,
          indexValue: addressIndex,
          error: {
            message: '',
          },
        }));
        this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListAddressesPostalCodeById({
          indexList: `${addressItem.customId}`,
          indexValue: addressIndex,
          error: {
            message: '',
          },
        }));
      }
      return hasError;
    });

    const contactPersonIds = companyListFind?.contactPersonsIds || [];
    updateState?.companyListContactPersons
      ?.filter((item) => contactPersonIds.includes(item.customId || ''))
      .forEach((contactItem, contactIndex) => {
        if (contactItem.fullName
          || contactItem.email
          || contactItem.phone
          || contactItem.phone2
          || contactIndex === 0
        ) {
          if (!contactItem.fullName) {
            this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListStateContactsContactPersonById({
              indexList: `${contactItem.customId}`,
              indexValue: contactIndex,
              error: {
                message: i18n.t('basicErrors.REQUIRED_MESSAGE'),
              },
            }));
            hasError = true;
          } else {
            this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListStateContactsContactPersonById({
              indexList: `${contactItem.customId}`,
              indexValue: contactIndex,
              error: {
                message: '',
              },
            }));
          }

          const { errorMessage: errorMessageEmail } = validationEmail(contactItem.email);
          if (!contactItem.email || errorMessageEmail) {
            this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListStateContactsEmailById({
              indexList: `${contactItem.customId}`,
              indexValue: contactIndex,
              error: {
                message: errorMessageEmail || i18n.t('basicErrors.REQUIRED_MESSAGE'),
              },
            }));
            hasError = true;
          } else {
            this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListStateContactsEmailById({
              indexList: `${contactItem.customId}`,
              indexValue: contactIndex,
              error: {
                message: '',
              },
            }));
          }

          const { errorMessage: errorMessagePhone } = validationPhone(contactItem.phone);
          if (!contactItem.phone || errorMessagePhone) {
            this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListStateContactsPhoneById({
              indexList: `${contactItem.customId}`,
              indexValue: contactIndex,
              error: {
                message: errorMessagePhone || i18n.t('basicErrors.REQUIRED_MESSAGE'),
              },
            }));
            hasError = true;
          } else {
            this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListStateContactsPhoneById({
              indexList: `${contactItem.customId}`,
              indexValue: contactIndex,
              error: {
                message: '',
              },
            }));
          }

          if (!contactItem.phone2) {
            this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListStateContactsAdditionalPhoneById({
              indexList: `${contactItem.customId}`,
              indexValue: contactIndex,
              error: {
                message: '',
              },
            }));
          }
        } else {
          this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListStateContactsContactPersonById({
            indexList: `${contactItem.customId}`,
            indexValue: contactIndex,
            error: {
              message: '',
            },
          }));
          this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListStateContactsEmailById({
            indexList: `${contactItem.customId}`,
            indexValue: contactIndex,
            error: {
              message: '',
            },
          }));
          this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListStateContactsPhoneById({
            indexList: `${contactItem.customId}`,
            indexValue: contactIndex,
            error: {
              message: '',
            },
          }));
        }
      });

    return hasError;
  };

  private validateAAC = () => {
    const { contactBook: { updateState } } = this.store.getState();
    let hasError = false;

    const addressesIds = updateState?.companyList?.[0]?.addressesIds || [];

    updateState?.companyListAddresses
      ?.filter((item) => addressesIds?.includes(item.customId || ''))
      .forEach((addressItem, addressIndex) => {
        if (!addressItem.address1) {
          this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListAddressesAddressLineFirstById({
            indexList: `${addressItem.customId}`,
            indexValue: addressIndex,
            error: {
              message: i18n.t('basicErrors.REQUIRED_MESSAGE'),
            },
          }));
          hasError = true;
        } else {
          this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListAddressesAddressLineFirstById({
            indexList: `${addressItem.customId}`,
            indexValue: addressIndex,
            error: {
              message: '',
            },
          }));
        }

        if (!addressItem.city) {
          this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListAddressesCityById({
            indexList: `${addressItem.customId}`,
            indexValue: addressIndex,
            error: {
              message: i18n.t('basicErrors.REQUIRED_MESSAGE'),
            },
          }));
          hasError = true;
        } else {
          this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListAddressesCityById({
            indexList: `${addressItem.customId}`,
            indexValue: addressIndex,
            error: {
              message: '',
            },
          }));
        }

        if (addressItem?.state) {
          this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListAddressesStateById({
            indexList: `${addressItem.customId}`,
            indexValue: addressIndex,
            error: {
              message: '',
            },
          }));
        } else if (addressItem?.country === 'US') {
          this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListAddressesStateById({
            indexList: `${addressItem.customId}`,
            indexValue: addressIndex,
            error: {
              message: i18n.t('basicErrors.REQUIRED_MESSAGE'),
            },
          }));
          hasError = true;
        } else if (addressItem?.country && addressItem?.country !== 'US') {
          this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListAddressesStateById({
            indexList: `${addressItem.customId}`,
            indexValue: addressIndex,
            error: {
              message: '',
            },
          }));
        } else if (!addressItem?.country) {
          this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListAddressesStateById({
            indexList: `${addressItem.customId}`,
            indexValue: addressIndex,
            error: {
              message: '',
            },
          }));
        }

        if (!addressItem.country) {
          this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListAddressesCountryById({
            indexList: `${addressItem.customId}`,
            indexValue: addressIndex,
            error: {
              message: i18n.t('basicErrors.REQUIRED_MESSAGE'),
            },
          }));
          hasError = true;
        } else {
          this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListAddressesCountryById({
            indexList: `${addressItem.customId}`,
            indexValue: addressIndex,
            error: {
              message: '',
            },
          }));
        }

        if (!addressItem.postalCode) {
          this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListAddressesPostalCodeById({
            indexList: `${addressItem.customId}`,
            indexValue: addressIndex,
            error: {
              message: i18n.t('basicErrors.REQUIRED_MESSAGE'),
            },
          }));
          hasError = true;
        } else {
          this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListAddressesPostalCodeById({
            indexList: `${addressItem.customId}`,
            indexValue: addressIndex,
            error: {
              message: '',
            },
          }));
        }
        return hasError;
      });

    const contactPersonIds = updateState?.companyList?.[0]?.contactPersonsIds || [];
    updateState?.companyListContactPersons
      ?.filter((item) => contactPersonIds.includes(item.customId || ''))
      .forEach((contactItem, contactIndex) => {
        if (!contactItem.fullName) {
          this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListStateContactsContactPersonById({
            indexList: `${contactItem.customId}`,
            indexValue: contactIndex,
            error: {
              message: i18n.t('basicErrors.REQUIRED_MESSAGE'),
            },
          }));
          hasError = true;
        } else {
          this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListStateContactsContactPersonById({
            indexList: `${contactItem.customId}`,
            indexValue: contactIndex,
            error: {
              message: '',
            },
          }));
        }

        const { errorMessage: errorMessageEmail } = validationEmail(contactItem.email);
        if (!contactItem.email || errorMessageEmail) {
          this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListStateContactsEmailById({
            indexList: `${contactItem.customId}`,
            indexValue: contactIndex,
            error: {
              message: errorMessageEmail || i18n.t('basicErrors.REQUIRED_MESSAGE'),
            },
          }));
          hasError = true;
        } else {
          this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListStateContactsEmailById({
            indexList: `${contactItem.customId}`,
            indexValue: contactIndex,
            error: {
              message: '',
            },
          }));
        }

        const { errorMessage: errorMessagePhone } = validationPhone(contactItem.phone);
        if (!contactItem.phone || errorMessagePhone) {
          this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListStateContactsPhoneById({
            indexList: `${contactItem.customId}`,
            indexValue: contactIndex,
            error: {
              message: errorMessagePhone || i18n.t('basicErrors.REQUIRED_MESSAGE'),
            },
          }));
          hasError = true;
        } else {
          this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListStateContactsPhoneById({
            indexList: `${contactItem.customId}`,
            indexValue: contactIndex,
            error: {
              message: '',
            },
          }));
        }

        if (!contactItem.phone2) {
          this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListStateContactsAdditionalPhoneById({
            indexList: `${contactItem.customId}`,
            indexValue: contactIndex,
            error: {
              message: '',
            },
          }));
        }
      });

    return hasError;
  };

  private validateAACEdit = () => {
    const { contactBook: { updateState } } = this.store.getState();
    let hasError = false;

    const addressesIds = updateState?.companyList?.[0]?.addressesIds || [];
    updateState?.companyListAddresses
      ?.filter((item) => addressesIds.includes(item.customId || ''))
      .forEach((addressItem, addressIndex) => {
        if (!addressItem.address1) {
          this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListAddressesAddressLineFirstById({
            indexList: `${addressItem.customId}`,
            indexValue: addressIndex,
            error: {
              message: i18n.t('basicErrors.REQUIRED_MESSAGE'),
            },
          }));
          hasError = true;
        } else {
          this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListAddressesAddressLineFirstById({
            indexList: `${addressItem.customId}`,
            indexValue: addressIndex,
            error: {
              message: '',
            },
          }));
        }

        if (!addressItem.city) {
          this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListAddressesCityById({
            indexList: `${addressItem.customId}`,
            indexValue: addressIndex,
            error: {
              message: i18n.t('basicErrors.REQUIRED_MESSAGE'),
            },
          }));
          hasError = true;
        } else {
          this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListAddressesCityById({
            indexList: `${addressItem.customId}`,
            indexValue: addressIndex,
            error: {
              message: '',
            },
          }));
        }

        if (addressItem?.state) {
          this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListAddressesStateById({
            indexList: `${addressItem.customId}`,
            indexValue: addressIndex,
            error: {
              message: '',
            },
          }));
        } else if (addressItem?.country === 'US') {
          this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListAddressesStateById({
            indexList: `${addressItem.customId}`,
            indexValue: addressIndex,
            error: {
              message: i18n.t('basicErrors.REQUIRED_MESSAGE'),
            },
          }));
          hasError = true;
        } else if (addressItem?.country && addressItem?.country !== 'US') {
          this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListAddressesStateById({
            indexList: `${addressItem.customId}`,
            indexValue: addressIndex,
            error: {
              message: '',
            },
          }));
        } else if (!addressItem?.country) {
          this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListAddressesStateById({
            indexList: `${addressItem.customId}`,
            indexValue: addressIndex,
            error: {
              message: '',
            },
          }));
        }

        if (!addressItem.country) {
          this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListAddressesCountryById({
            indexList: `${addressItem.customId}`,
            indexValue: addressIndex,
            error: {
              message: i18n.t('basicErrors.REQUIRED_MESSAGE'),
            },
          }));
          hasError = true;
        } else {
          this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListAddressesCountryById({
            indexList: `${addressItem.customId}`,
            indexValue: addressIndex,
            error: {
              message: '',
            },
          }));
        }

        if (!addressItem.postalCode) {
          this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListAddressesPostalCodeById({
            indexList: `${addressItem.customId}`,
            indexValue: addressIndex,
            error: {
              message: i18n.t('basicErrors.REQUIRED_MESSAGE'),
            },
          }));
          hasError = true;
        } else {
          this.dispatch(R.actions.contactBook.setUpdateErrorsCompanyListAddressesPostalCodeById({
            indexList: `${addressItem.customId}`,
            indexValue: addressIndex,
            error: {
              message: '',
            },
          }));
        }
        return hasError;
      });

    return hasError;
  };

  private compareUpdateState = () => {
    const { contactBook: { updateState, defaultState } } = this.store.getState();

    updateState.companyList
      .forEach((itemList) => {
        if (updateState.activeListTabIndex !== itemList.customId) {
          return;
        }

        const defaultStateCompanyList = defaultState.companyList?.filter((item) => item.customId === itemList.customId);

        let isCompare = false;
        if (!isCompare
          && itemList?.name !== defaultStateCompanyList?.[0]?.name
        ) {
          isCompare = true;
        }

        if (!isCompare
          && itemList?.taxId !== defaultStateCompanyList?.[0]?.taxId
        ) {
          isCompare = true;
        }

        const addressesIds = defaultState?.companyList?.filter((item) => item.customId === itemList.customId)?.[0]?.addressesIds || [];
        const contactPersonIds = defaultState?.companyList?.filter((item) => item.customId === itemList.customId)?.[0]?.contactPersonsIds || [];

        if (itemList?.addressesIds?.length !== defaultStateCompanyList?.[0]?.addressesIds?.length) {
          isCompare = true;
        }

        if (itemList?.contactPersonsIds?.length !== defaultStateCompanyList?.[0]?.contactPersonsIds?.length) {
          isCompare = true;
        }

        if (!isCompare) {
          updateState.companyListAddresses
            ?.filter((item) => itemList?.addressesIds?.includes(item.customId || ''))
            .forEach((addressItem) => {
              const defaultAddress = defaultState.companyListAddresses?.filter((item) => addressesIds?.includes(item.customId || ''))
                ?.filter((item) => item.customId === addressItem.customId)?.[0];

              if (!isCompare
                && (
                  (addressItem?.address1 !== defaultAddress?.address1)
                  || (addressItem?.address2 !== defaultAddress?.address2)
                  || (addressItem?.city !== defaultAddress?.city)
                  || (addressItem?.country !== defaultAddress?.country)
                  || (addressItem?.state !== defaultAddress?.state)
                  || (addressItem?.postalCode !== defaultAddress?.postalCode)
                )) {
                isCompare = true;
              }
            });
        }

        if (!isCompare) {
          updateState.companyListContactPersons
            ?.filter((item) => itemList?.contactPersonsIds?.includes(item.customId || ''))
            .forEach((contactItem) => {
              const defaultContactPerson = defaultState.companyListContactPersons?.filter((item) => contactPersonIds?.includes(item.customId || ''))
                ?.filter((item) => item.customId === contactItem.customId)?.[0];

              if (!isCompare
                && (
                  (contactItem?.phone2 !== defaultContactPerson?.phone2)
                  || (contactItem?.fullName !== defaultContactPerson?.fullName)
                  || (contactItem?.email !== defaultContactPerson?.email)
                  || (contactItem?.phone !== defaultContactPerson?.phone)
                )) {
                isCompare = true;
              }
            });
        }

        this.dispatch(R.actions.contactBook.setUpdateCompanyListIsUpdateById({
          listId: `${itemList.customId}`,
          flag: isCompare,
        }));
      });
  };

  private compareUpdateStateAAC = () => {
    const { contactBook: { updateState, defaultState } } = this.store.getState();

    updateState.companyList
      .forEach((itemList) => {
        if (updateState.activeListTabIndex !== itemList.customId) {
          return;
        }
        let isCompare = false;

        const defaultStateCompanyList = defaultState.companyList?.filter((item) => item.customId === itemList.customId);

        if (!isCompare
          && itemList?.isPrimary !== defaultStateCompanyList?.[0]?.isPrimary
        ) {
          isCompare = true;
        }

        if (!isCompare
          && itemList?.taxId !== defaultStateCompanyList?.[0]?.taxId
        ) {
          isCompare = true;
        }

        const addressesIds = defaultState.companyList?.filter((item) => item.customId === itemList.customId)?.[0].contactPersonsIds || [];
        const contactPersonIds = defaultState.companyList?.filter((item) => item.customId === itemList.customId)?.[0].contactPersonsIds || [];

        if (itemList?.addressesIds?.length !== defaultStateCompanyList?.[0].addressesIds?.length) {
          isCompare = true;
        }

        if (itemList?.contactPersonsIds?.length !== defaultStateCompanyList?.[0].contactPersonsIds?.length) {
          isCompare = true;
        }

        if (!isCompare) {
          updateState.companyListAddresses
            ?.filter((item) => itemList?.addressesIds?.includes(item.customId || ''))
            .forEach((addressItem) => {
              const defaultAddress = defaultState.companyListAddresses?.filter((item) => addressesIds?.includes(item.customId || ''))?.[0];

              if (!isCompare
                && (
                  (addressItem?.address1 !== defaultAddress?.address1)
                  || (addressItem?.address2 !== defaultAddress?.address2)
                  || (addressItem?.city !== defaultAddress?.city)
                  || (addressItem?.country !== defaultAddress?.country)
                  || (addressItem?.state !== defaultAddress?.state)
                  || (addressItem?.postalCode !== defaultAddress?.postalCode)
                )) {
                isCompare = true;
              }
            });
        }

        if (!isCompare) {
          updateState.companyListContactPersons
            ?.filter((item) => itemList?.contactPersonsIds?.includes(item.customId || ''))
            .forEach((contactItem) => {
              const defaultContactPerson = defaultState.companyListContactPersons?.filter((item) => contactPersonIds?.includes(item.customId || ''))?.[0];

              if (!isCompare
                && (
                  (contactItem?.phone2 !== defaultContactPerson?.phone2)
                  || (contactItem?.fullName !== defaultContactPerson?.fullName)
                  || (contactItem?.email !== defaultContactPerson?.email)
                  || (contactItem?.phone !== defaultContactPerson?.phone)
                )) {
                isCompare = true;
              }
            });
        }

        this.dispatch(R.actions.contactBook.setUpdateCompanyListIsUpdateById({
          listId: `${itemList.customId}`,
          flag: isCompare,
        }));
      });
  };

  private compareUpdateStateAACEdit = () => {
    const { contactBook: { updateState, defaultState } } = this.store.getState();

    updateState.companyList
      .forEach((itemList) => {
        if (updateState.activeListTabIndex !== itemList.customId) {
          return;
        }
        let isCompare = false;

        const defaultStateCompanyList = defaultState.companyList?.filter((item) => item.customId === itemList.customId);

        if (!isCompare
          && itemList?.isPrimary !== defaultStateCompanyList?.[0]?.isPrimary
        ) {
          isCompare = true;
        }

        if (!isCompare
          && itemList?.taxId !== defaultStateCompanyList?.[0]?.taxId
        ) {
          isCompare = true;
        }

        const addressesIds = defaultState.companyList?.filter((item) => item.customId === itemList.customId)?.[0].contactPersonsIds || [];

        if (itemList?.addressesIds?.length !== defaultStateCompanyList?.[0].addressesIds?.length) {
          isCompare = true;
        }

        if (itemList?.contactPersonsIds?.length !== defaultStateCompanyList?.[0].contactPersonsIds?.length) {
          isCompare = true;
        }

        if (!isCompare) {
          updateState.companyListAddresses
            ?.filter((item) => itemList?.addressesIds?.includes(item.customId || ''))
            .forEach((addressItem) => {
              const defaultAddress = defaultState.companyListAddresses?.filter((item) => addressesIds?.includes(item.customId || ''))?.[0];

              if (!isCompare
                && (
                  (addressItem?.address1 !== defaultAddress?.address1)
                  || (addressItem?.address2 !== defaultAddress?.address2)
                  || (addressItem?.city !== defaultAddress?.city)
                  || (addressItem?.country !== defaultAddress?.country)
                  || (addressItem?.state !== defaultAddress?.state)
                  || (addressItem?.postalCode !== defaultAddress?.postalCode)
                )) {
                isCompare = true;
              }
            });
        }

        this.dispatch(R.actions.contactBook.setUpdateCompanyListIsUpdateById({
          listId: `${itemList.customId}`,
          flag: isCompare,
        }));
      });
  };

  private setUpdate = (documents: IContactBookUpdateStateDTM) => {
    this.dispatch(R.actions.contactBook.setUpdate(documents));

    this.compareUpdateState();
  };

  private getCompanyAddressCompareId = (newAddress: CompanyAddressDTM[], oldAddress?: CompanyAddressDTM) => {
    let address: CompanyAddressDTM | undefined = oldAddress ? {
      ...oldAddress,
    } : EmptyCompanyAddressDTMFactory.build();

    newAddress.forEach((itemAddress) => {
      if (oldAddress?.address1 === itemAddress.address1
        && oldAddress?.city === itemAddress.city
        && oldAddress?.postalCode === itemAddress.postalCode
        && oldAddress?.country === itemAddress.country) {
        address = {
          ...oldAddress,
          id: itemAddress.id,
        };
      }
    });

    return address;
  }

  private getContactPersonsCompareId = (newContact: CompanyContactDTM[], oldContact?: CompanyContactDTM) => {
    let contactPerson: CompanyContactDTM | undefined = oldContact ? {
      ...oldContact,
    } : EmptyCompanyContactDTMFactory.build();

    newContact.forEach((itemContact) => {
      if (oldContact?.email === itemContact.email
        && oldContact?.fullName === itemContact.fullName
        && oldContact?.phone === itemContact.phone) {
        contactPerson = {
          ...oldContact,
          id: itemContact.id,
        };
      }
    });

    return contactPerson;
  }
}
