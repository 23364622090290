import { EUserStatus } from './UserStatus.enum';

export type TUserAntdStatus = 'success' | 'default' | 'processing'

export const getUserAntdStatus: (userStatus: EUserStatus) => TUserAntdStatus = (userStatus: EUserStatus) => {
  let result: TUserAntdStatus = 'default';

  if (userStatus === EUserStatus.active) {
    result = 'success';
  }

  if (userStatus === EUserStatus.invited) {
    result = 'processing';
  }

  if (userStatus === EUserStatus.inactive) {
    result = 'default';
  }

  return result;
};
