import styled from 'styled-components';

import { Input } from 'app-wrapper/view/components';
import { divFlex } from 'app-wrapper/view/themes/mixin';
import { emptyScrollbar } from 'app-wrapper/view/themes/components';

export const ShipmentListFiltersWrap = styled.div.attrs({
  'data-class': 'ShipmentListFiltersWrap',
})`
  ${divFlex}
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  margin: 8px auto;
  height: 50px;
  max-width: 1660px;
  padding: 0 24px;

  font-weight: 400;
  font-size: 12px;
  border-bottom: 1px solid ${({ theme }) => theme.themesColors.neutralBranding4};
`;

export const SLWTitle = styled.div.attrs({
  'data-class': 'SLWTitle',
})`
  ${divFlex}

  color: ${({ theme }) => theme.themesColors.secondaryDot45};

  font-weight: 400;
  font-size: 12px;
`;

export const SLWStatus = styled.div.attrs({
  'data-class': 'SLWStatus',
})`
  ${divFlex}

  .ant-checkbox-wrapper > span {
    text-transform: capitalize;
  }
`;

export const SLWSortBy = styled.div.attrs({
  'data-class': 'SLWSortBy',
})`
  ${divFlex}

  .ant-radio-wrapper > span {
    text-transform: capitalize;
  }
  .ant-radio-wrapper:hover .ant-radio,
  .ant-radio-checked .ant-radio-inner,
  .ant-radio-wrapper:hover .ant-radio, .ant-radio:hover .ant-radio-inner {
    border-color: ${({ theme }) => theme.themesColors.neutralBranding9};
  }
  .ant-radio-inner::after {
    background-color: ${({ theme }) => theme.themesColors.neutralBranding9};
  }
  div[data-class="FREModalWrapper"],
  div[data-class="FREModalRadio"] {
    ${emptyScrollbar};
  }
`;

export const StyledInput = styled(Input)`
  margin-left: auto;
  width: 270px;
  height: 32px;
  min-height: 32px;
  color: #73819B;
  ::placeholder {
    color: #73819B;
  }
`;

export const FiltersContainer = styled.div`
  display: flex;
  gap: 33px;
`;

export const RightFiltersContainer = styled.div`
  height: 32px;
  min-height: 32px;
`;

export const InputSearchContainer = styled.div`
  width: 270px;
`;

export const SearchWrapper = styled.div`
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--character-branding-disabled-apm-placeholder-25, #D1D7E2);
  background: white;
  margin-right: 20px;
`;
