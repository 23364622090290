import React, {
  FC, useCallback, useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';

import { Option } from 'app-wrapper/view/components';
import { PlusSvg } from 'app-wrapper/view/icons';

import { CompanyDTM } from 'shipment-operations/models/dtm';
import { PermissionAttributePolicy } from 'shipment-operations/constants';
import {
  SelectStyled, SelectStyledFooter, SelectStyledFooterText,
} from './ShippingPartiesCompanyName.syled';

interface IShippingPartiesCompanyNameComponentProps {
  companyList: CompanyDTM[]
  availability?: PermissionAttributePolicy
  selectedCompany?: CompanyDTM
  selectedCurrentCompany?: CompanyDTM
  companyError?: Error
  setCompanyName: (companyName: CompanyDTM['id']) => void
  onBlur: () => void
  openAddCompanyDrawer: () => void
}

export const ShippingPartiesCompanyNameComponent: FC<IShippingPartiesCompanyNameComponentProps> = ({
  companyList,
  availability,
  selectedCompany,
  selectedCurrentCompany,
  companyError,
  setCompanyName,
  onBlur,
  openAddCompanyDrawer,
}) => {
  const selectValue = selectedCurrentCompany === selectedCompany ? selectedCurrentCompany?.id : selectedCompany?.id;
  const isError = companyError !== undefined;

  const { t } = useTranslation();

  const isDisabled = useMemo(() => availability !== PermissionAttributePolicy.WRITE, [availability]);

  const dropdownRender = useCallback((menu) => (
    <>
      {menu}
      <SelectStyledFooter onClick={openAddCompanyDrawer}>
        <PlusSvg
          style={{ color: '#6C79DE' }}
          height={12}
          width={12}
        />
        <SelectStyledFooterText>{t('Add Company')}</SelectStyledFooterText>
      </SelectStyledFooter>
    </>
  ), [openAddCompanyDrawer]);

  return (
    <SelectStyled
      showSearch
      placeholder={t('Select Company')}
      optionFilterProp="children"
      value={selectValue}
      onChange={setCompanyName}
      error={isError}
      disabled={isDisabled}
      aria-errormessage={companyError?.message}
      onBlur={onBlur}
      dropdownRender={dropdownRender}
    >
      {
        companyList.map((company) => (
          <>
            <Option key={company.id} value={company.id}>{company.name}</Option>
          </>
        ))
      }
    </SelectStyled>
  );
};
