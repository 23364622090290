import styled from 'styled-components';

export const TableRow = styled.div`
  display: flex;
  border-bottom: 0.5px dashed #D1D7E2;
  font-size: 0.7rem;
  padding: 5px 0 5px 0;
`;

export const ClausesWrapper = styled.div`
  height: fit-content;
  border-top: 1px solid #D1D7E2;
`;

export const StyledTableRow = styled(TableRow)`
  &:last-child {
    border-bottom-style: solid;
  }
`;

export const Span = styled.span<{fontSize?: number}>`
  font-size: ${({ fontSize }) => fontSize}rem;
  text-transform: capitalize;
`;
