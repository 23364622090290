import React, { ChangeEvent, FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import InfoCircleOutlined from '@ant-design/icons/InfoCircleOutlined';

import { Button, InputPassword, Tooltip } from 'app-wrapper/view/components';

import {
  ChangePasswordContainer,
  ChangePasswordFooter,
  ChangePasswordItem,
  ChangePasswordWrapper,
} from './ChangePassword.styled';

interface ITransportationLocationDataComponentProps {
  isLoading: boolean
  wasAnyFormFieldChanged: boolean
  currentPasswordValue?: string
  currentPasswordError?: Error
  currentPasswordChange: (value: string) => void
  currentPasswordBlur: () => void
  newPasswordValue?: string
  newPasswordError?: Error
  newPasswordChange: (value: string) => void
  newPasswordBlur: () => void
  confirmNewPasswordValue?: string
  confirmNewPasswordError?: Error
  confirmNewPasswordChange: (value: string) => void
  confirmNewPasswordBlur: () => void
  onOk: () => void
  onCancel: () => void
}

export const ChangePasswordComponent: FC<ITransportationLocationDataComponentProps> = ({
  isLoading,
  wasAnyFormFieldChanged,
  currentPasswordValue,
  currentPasswordError,
  currentPasswordChange,
  currentPasswordBlur,
  newPasswordValue,
  newPasswordError,
  newPasswordChange,
  newPasswordBlur,
  confirmNewPasswordValue,
  confirmNewPasswordError,
  confirmNewPasswordChange,
  confirmNewPasswordBlur,
  onOk,
  onCancel,
}) => {
  const { t } = useTranslation();

  const onCurrentPasswordChangeHandler = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    currentPasswordChange(event.target.value);
  }, []);

  const onNewPasswordChangeHandler = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    newPasswordChange(event.target.value);
  }, []);

  const onConfirmNewPasswordChangeHandler = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    confirmNewPasswordChange(event.target.value);
  }, []);

  const onOkHandler = useCallback(() => {
    onOk();
  }, []);

  return (
    <ChangePasswordWrapper>
      <ChangePasswordContainer>
        <ChangePasswordItem>
          <span>{t('Current Password')}</span>
          <Tooltip mode="danger" title={currentPasswordError?.message} visible={!!currentPasswordError && !!currentPasswordError?.message}>
            <InputPassword
              maxLength={50}
              dataClass="CurrentPasswordInput"
              disabled={isLoading}
              value={currentPasswordValue}
              error={!!currentPasswordError}
              onChange={onCurrentPasswordChangeHandler}
              onBlur={currentPasswordBlur}
            />
          </Tooltip>
        </ChangePasswordItem>

        <ChangePasswordItem>
          <span>
            {t('New Password')}
            <Tooltip title={t('Password hint')}>
              <InfoCircleOutlined />
            </Tooltip>
          </span>
          <Tooltip mode="danger" title={newPasswordError?.message} visible={!!newPasswordError && !!newPasswordError?.message}>
            <InputPassword
              maxLength={50}
              dataClass="NewPasswordInput"
              disabled={isLoading}
              value={newPasswordValue}
              error={!!newPasswordError}
              onChange={onNewPasswordChangeHandler}
              onBlur={newPasswordBlur}
            />
          </Tooltip>
        </ChangePasswordItem>

        <ChangePasswordItem>
          <span>{t('Confirm New Password')}</span>
          <Tooltip mode="danger" title={confirmNewPasswordError?.message} visible={!!confirmNewPasswordError && !!confirmNewPasswordError?.message}>
            <InputPassword
              maxLength={50}
              dataClass="ConfirmNewPasswordInput"
              disabled={isLoading}
              value={confirmNewPasswordValue}
              error={!!confirmNewPasswordError}
              onChange={onConfirmNewPasswordChangeHandler}
              onBlur={confirmNewPasswordBlur}
            />
          </Tooltip>
        </ChangePasswordItem>
      </ChangePasswordContainer>

      <ChangePasswordFooter>
        <Button type="default" onClick={onCancel}>{t('Cancel')}</Button>
        <Button
          data-class="SubmitChangePassword"
          disabled={wasAnyFormFieldChanged}
          loading={isLoading}
          onClick={onOkHandler}
        >
          {t('Save')}
        </Button>
      </ChangePasswordFooter>
    </ChangePasswordWrapper>
  );
};
