import styled from 'styled-components';
import UserSwitchOutlined from '@ant-design/icons/UserSwitchOutlined';

export const ShippingPartyTitleIcon = styled(UserSwitchOutlined)`
  svg {
    width: 15px;
    height: 17px;
    fill:  ${({ theme }) => theme.themesColors.darkGrayMiddle};
  }
`;
