import styled, { css } from 'styled-components';
import Spin from 'antd/es/spin';

import { divFlex, responsive } from 'app-wrapper/view/themes/mixin';
import { emptyScrollbar } from 'app-wrapper/view/themes/components';
import { Input, Button } from 'app-wrapper/view/components';

const inputCss = css`
  font-size: 12px;
  font-weight: 400;
  color: ${({ theme }) => theme?.themesColors?.neutralBranding10};
  min-height: 32px;
`;

const inputTopMarginCss = css`
  margin-top: 4px;
`;

const inputLargeCss = css`
  height: 40px;
`;

export const EditProfileOrganizationWrap = styled.div.attrs({
  'data-class': 'EditProfileOrganizationWrap',
})`
  ${divFlex}
  ${emptyScrollbar}
  flex-direction: column;
  align-items: center;

  margin-left: auto;
  margin-right: auto;
  height: 100%;
  background-color: ${({ theme }) => theme.themesColors.neutralBranding3};
`;

export const EditProfileOrganizationContent = styled.div.attrs({
  'data-class': 'EditProfileOrganizationContent',
})`
  ${divFlex}
  ${emptyScrollbar}
  flex-direction: column;
  overflow: auto;

  font-weight: 400;
  font-size: 12px;
  color: ${({ theme }) => theme.themesColors.neutralBranding10};
  padding: 16px 24px 0 24px;
  width: 688px;
  height: 100%;
  border: 1px solid ${({ theme }) => theme.themesColors.neutralBranding4};
  background-color: ${({ theme }) => theme.themesColors.neutralBranding1};
`;

export const EditProfileOrganizationOrganization = styled.div.attrs({
  'data-class': 'EditProfileOrganizationOrganization',
})`
  ${divFlex}
  flex-direction: column;
  justify-content: center;
`;

export const EditProfileOrganizationTitle = styled.div.attrs({
  'data-class': 'EditProfileOrganizationTitle',
})`
  ${divFlex}
  justify-content: start;

  font-weight: 400;
  font-size: 12px;
  color: ${({ theme }) => theme.themesColors.neutralBranding10};
`;

export const EditProfileOrganizationInput = styled(Input).attrs((props: {
  hasError: boolean,
  largeInput: boolean,
}) => ({
  hasError: props.hasError,
  largeInput: props.largeInput,
  'data-class': 'EditProfileOrganizationInput',
}))`
  ${divFlex}
  ${inputCss}
  ${inputTopMarginCss}
  ${({ largeInput }) => largeInput && inputLargeCss};
  width: 100%;

  ${responsive.forFullMedia`
    min-height: ${({ theme }: { theme: { inputConst: { hdHeight: string } } }) => theme?.inputConst?.hdHeight};
  `}
  ${({ hasError, theme }) => hasError && `border-color: ${theme.themesColors.characterBrandingDanger} !important; border-style: solid;`};
`;

export const EditProfileOrganizationDivider = styled.div.attrs({
  'data-class': 'EditProfileOrganizationDivider',
})`
  ${divFlex}
  width: 100%;

  margin-top: 16px;
  margin-bottom: 16px;
  border: 1px dashed ${({ theme }) => theme.themesColors.neutralBranding4};
`;

export const EditProfileOrganizationFooter = styled.div.attrs({
  'data-class': 'EditProfileOrganizationFooter',
})`
  ${divFlex}
  justify-content: end;
  z-index: 4;
  width: 100%;
  height: 64px;
  bottom: 0px;
  align-items: center;
  padding: 14px 24px;

  background-color: ${({ theme }) => theme.themesColors.neutralBranding1};
  box-shadow: 0px -12px 24px -10px rgba(0,0,0,0.21);
`;

export const NewOrganizationDataNextButton = styled(Button).attrs({
  'data-class': 'NewOrganizationDataNextButton',
})`
  margin-left: 8px;
`;

export const NewOrganizationDataCancelButton = styled(Button).attrs({
  'data-class': 'NewOrganizationDataCancelButton',
})`
  margin-right: 24px;
`;

export const Spinner = styled(Spin)`
  margin: auto;
`;
