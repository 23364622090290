import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  UserOutlined,
  PhoneOutlined,
  MailOutlined,
  BankOutlined,
} from '@ant-design/icons';

import { OrganizationTradeReferenceDTM } from 'user-management/models/dtm';

import {
  Wrap,
  Title,
  Content,
  Item,
  ItemIcon,
  ItemContent,
  ItemTitle,
  ItemSubtitle,
  EditButton,
  EditIcon,
} from './TradeReferences.styled';

const DEFAULT_VALUE = '-';

interface TradeReferencesComponentProps {
  tradeReference: OrganizationTradeReferenceDTM | null;
  openEditDrawer: () => void;
}

export const TradeReferencesComponent: FC<TradeReferencesComponentProps> = ({ tradeReference, openEditDrawer }) => {
  const { t } = useTranslation();

  return (
    <Wrap>
      <Title>
        {t('Trade References')}
      </Title>

      <EditButton
        onClick={openEditDrawer}
        type="default"
        icon={<EditIcon />}
      >
        {t('Edit')}
      </EditButton>

      <Content>
        <Item>
          <ItemIcon>
            <BankOutlined />
          </ItemIcon>

          <ItemContent>
            <ItemTitle>
              {t('Company Name')}
            </ItemTitle>

            <ItemSubtitle>
              {tradeReference?.companyName || DEFAULT_VALUE}
            </ItemSubtitle>
          </ItemContent>
        </Item>

        <Item>
          <ItemIcon>
            <UserOutlined />
          </ItemIcon>

          <ItemContent>
            <ItemTitle>
              {t('Contact person')}
            </ItemTitle>

            <ItemSubtitle>
              {tradeReference?.getFullName() || DEFAULT_VALUE}
            </ItemSubtitle>
          </ItemContent>
        </Item>

        <Item>
          <ItemIcon>
            <MailOutlined />
          </ItemIcon>

          <ItemContent>
            <ItemTitle>
              {t('Email')}
            </ItemTitle>

            <ItemSubtitle>
              {tradeReference?.email || DEFAULT_VALUE}
            </ItemSubtitle>
          </ItemContent>
        </Item>

        <Item>
          <ItemIcon>
            <PhoneOutlined />
          </ItemIcon>

          <ItemContent>
            <ItemTitle>
              {t('Phone')}
            </ItemTitle>

            <ItemSubtitle>
              {tradeReference?.phone || DEFAULT_VALUE}
            </ItemSubtitle>
          </ItemContent>
        </Item>
      </Content>
    </Wrap>
  );
};
