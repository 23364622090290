import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';
import { UC } from 'shipment-operations/controllers';
import { PermissionAttributePolicy } from 'shipment-operations/constants';

import { SBLCommentsInformationComponent } from './SBLCommentsInformation.component';

const SBLCommentsInformationContainer = () => {
  const { blComment } = useSelector(R.selectors.billOfLadingCommon.getSIDetails);
  const permissions = useSelector(R.selectors.shipment.getSIPermissions);

  const isAbleToEdit = useMemo(() => permissions.editSISections === PermissionAttributePolicy.WRITE, [permissions]);

  const onOpenEdit = useMemo(() => (isAbleToEdit ? UC.shipmentDrawer.openBillOfLadingEditCommentsDrawer : undefined), [isAbleToEdit]);

  return (
    <SBLCommentsInformationComponent
      isIncomplete={!blComment}
      onOpenEdit={onOpenEdit}
      comment={blComment}
    />
  );
};

export { SBLCommentsInformationContainer as SBLCommentsInformation };
