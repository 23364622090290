import {
  IsString, IsEnum, IsDefined, ValidateNested, IsOptional, IsNumber,
} from 'class-validator';
import { Type } from 'class-transformer';
import { UploadFile, UploadFileStatus } from 'antd/lib/upload/interface';
import { BaseDTM } from 'proto/BaseDTM';

import { DocumentType } from 'shipment-operations/constants';

interface IDocumentDTM {
  id: number
  name: string
  type: DocumentType
}

class DocumentDTM extends BaseDTM<IDocumentDTM> {
  @IsString()
  @IsDefined()
  id: number;

  @IsString()
  @IsDefined()
  name: string;

  @IsDefined()
  @IsEnum(DocumentType)
  type: DocumentType;
}

export interface IShipmentDeclarationDTM {
  id: number
  document: IDocumentDTM
  shipmentId?: number
  containerId?: number
}

export class ShipmentDeclarationDTM extends BaseDTM<IShipmentDeclarationDTM> {
  @IsString()
  @IsDefined()
  id: number;

  @IsNumber()
  @IsOptional()
  shipmentId?: number;

  @IsNumber()
  @IsOptional()
  containerId?: number;

  @IsDefined()
  @ValidateNested()
  @Type(() => DocumentDTM)
  document: DocumentDTM
}

export interface IShipmentDeclarationFileDTM extends UploadFile {
  fileId?: string | null
  idIndex?: number
}

export class ShipmentDeclarationFileDTM extends BaseDTM<IShipmentDeclarationFileDTM> {
  @IsString()
  @IsDefined()
  uid: string

  @IsString()
  @IsDefined()
  name: string

  @IsOptional()
  @IsString()
  fileId?: string

  @IsOptional()
  @IsNumber()
  idIndex?: number

  @IsOptional()
  error?: any;

  @IsOptional()
  @IsString()
  response?: string;

  @IsOptional()
  @IsString()
  status?: UploadFileStatus;

  static plainToCorrectFileFormat = (input: IShipmentDeclarationFileDTM) => ({
    uid: input.uid,
    name: input.name,
    error: new Error(input.response?.message),
    status: input.status,
    fileId: input.fileId,
    idIndex: input.idIndex,
    response: input.response,
  });
}
