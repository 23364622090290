import { R } from 'monetary/repository';
import { UC } from 'monetary/controllers';
import React, {
  FC, memo, useMemo,
} from 'react';
import { useSelector } from 'react-redux';

import { AddressBlockComponent } from './AddressBlock.component';

export const AddressBlock: FC = memo(() => {
  const getCurrent = useSelector(R.selectors.freightQuote.getFreightQuoteCurrent);
  const getOrigin = useSelector(R.selectors.freightQuote.getFreightQuoteCurrentOrigin);
  const getDestination = useSelector(R.selectors.freightQuote.getFreightQuoteCurrentDestination);
  const getErrors = useSelector(R.selectors.freightQuote.getFreightQuoteErrors);

  // incotermsTrade

  const originErrors = useMemo(() => ({
    isPort: getErrors?.origin?.isPort,
    dateDoor: getErrors?.origin?.dateDoor,
    datePort: getErrors?.origin?.datePort,
    location: getErrors?.origin?.location,
  }), [getErrors?.origin]);

  const destinationErrors = useMemo(() => ({
    isPort: getErrors?.destination?.isPort,
    dateDoor: getErrors?.destination?.dateDoor,
    datePort: getErrors?.destination?.datePort,
    location: getErrors?.destination?.location,
  }), [getErrors?.destination]);

  return (
    <AddressBlockComponent
      isAllDisabled={getCurrent.isAllFieldDisabled}
      isCollapsed={!getCurrent.isCollapse}
      isIncludeRelatedPort={getCurrent?.form?.isIncludeRelatedPort}
      // origin
      origin={getOrigin}
      onChangeOriginTypeToPort={UC.FreightQuote.onChangeOriginTypeToPort}
      onChangeIsIncludeRelatedPort={UC.FreightQuote.onChangeIsIncludeRelatedPort}
      onChangePortDateOrigin={UC.FreightQuote.onChangePortDateOrigin}
      onFocusPortDateOrigin={UC.FreightQuote.onFocusPortDateOrigin}
      onBlurPortDateOrigin={UC.FreightQuote.onBlurPortDateOrigin}
      // destination
      onChangeDestinationTypeToPort={UC.FreightQuote.onChangeDestinationTypeToPort}
      destination={getDestination}
      onChangePortDateDestination={UC.FreightQuote.onChangePortDateDestination}
      onFocusPortDateDestination={UC.FreightQuote.onFocusPortDateDestination}
      onBlurPortDateDestination={UC.FreightQuote.onBlurPortDateDestination}
      // errors
      originErrors={originErrors}
      destinationErrors={destinationErrors}
      isSubmitVisited={getCurrent.isSubmitVisited}
    />
  );
});
