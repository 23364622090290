import { createSelector } from 'reselect';
import isEqual from 'lodash/isEqual';

import { RootState } from 'app-wrapper/store';

import { BillOfLadingType } from 'shipment-operations/constants';
import { IBillOfLadingDTM } from 'shipment-operations/models/dtm';

const localState = (state: RootState) => state.billOfLading;

const getAllBillOfLadingInformation = createSelector(
  localState,
  (state) => state,
);

const getInitialStateType = createSelector(
  localState,
  (state) => state.initialState.type,
);

const getLoading = createSelector(
  localState,
  (state) => state.isLoading,
);

const getBillOfLadingBase = <T extends IBillOfLadingDTM>(state: T) => ({
  id: state.id,
  type: state.type,
  copies: state.copies.map(({ party, amount, freighted }) => ({ party, amount, freighted })),
  originals: state.originals.map(({ party, amount, freighted }) => ({ party, amount, freighted })),
});

const getBillOfLadingDraftStatus = createSelector(
  localState,
  (state) => {
    const initialBillOfLading = getBillOfLadingBase(state.initialState);
    const currentBillOfLading = getBillOfLadingBase(state);

    return !isEqual(initialBillOfLading, currentBillOfLading);
  },
);

const getUpdateAttemptStatus = createSelector(
  localState,
  (state) => state.wasUpdateAttempted,
);

const getBillOfLadingErrors = createSelector(
  localState,
  (state) => state.errors,
);

const getDocumentsDistributionDisplayStatus = createSelector(
  localState,
  (state) => state.type !== BillOfLadingType.EBL,
);

const getIsVesselDeparted = createSelector(
  localState,
  (state) => state.isVesselDeparted,
);

const getIsEnoughTimeRemainingBeforeVesselArrival = createSelector(
  localState,
  (state) => state.isEnoughTimeRemainingBeforeVesselArrival,
);

const getIsOnlyHouseConsigneeAvailable = createSelector(
  localState,
  (state) => state.isOnlyHouseConsigneeAvailable && state.type === BillOfLadingType.OBR,
);

export const billOfLadingSelectors = {
  getAllBillOfLadingInformation,
  getInitialStateType,
  getBillOfLadingDraftStatus,
  getDocumentsDistributionDisplayStatus,
  getUpdateAttemptStatus,
  getBillOfLadingErrors,
  getLoading,
  getIsVesselDeparted,
  getIsOnlyHouseConsigneeAvailable,
  getIsEnoughTimeRemainingBeforeVesselArrival,
};
