import * as yup from 'yup';
import i18n from 'i18next';

yup.addMethod(yup.string, 'dnbIdFormat', function isValidDnbId(translation?: string) {
  return this.matches(/^[0-9]{9}$/, {
    message: translation || i18n.t('Validations.string.dnbIdFormat'),
    excludeEmptyString: true,
  });
});

declare module 'yup' {
  interface StringSchema {
    dnbIdFormat(translation?: string): StringSchema;
  }
}
