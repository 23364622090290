import styled from 'styled-components';

export const Footer = styled.div`
  border-top: 1px solid rgba(240, 240, 240, 1);
  margin-top: auto;
  padding: 10px 16px;
  width: 100%;
  height: 52px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 8px;
`;
