import React, { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';
import { UC } from 'shipment-operations/controllers';
import { PermissionAttributePolicy } from 'shipment-operations/constants';

import { AirVentFieldComponent } from './AirVentField.component';

interface AirVentFieldContainerProps {
  containersAvailability?: PermissionAttributePolicy;
}

const AirVentFieldContainer: FC<AirVentFieldContainerProps> = ({ containersAvailability }) => {
  const { airVent: { flowRate, flowRateUnit } } = useSelector(R.selectors.temperatureControl.getTemperatureControl);
  const flowRateErrorFieldIsActive = useSelector(R.selectors.temperatureControl.isFlowRateErrorFieldIsActive);
  const flowRateUnitErrorFieldIsActive = useSelector(R.selectors.temperatureControl.isFlowRateUnitErrorFieldIsActive);
  const permissions = useSelector(R.selectors.shipment.getContainersPermissions);

  const availability = useMemo(() => containersAvailability || permissions.containersAvailability, [permissions.containersAvailability, containersAvailability]);

  return (
    <AirVentFieldComponent
      flowRate={flowRate}
      flowRateError={flowRateErrorFieldIsActive}
      flowRateUnit={flowRateUnit}
      flowRateUnitError={flowRateUnitErrorFieldIsActive}
      setFlowRate={UC.temperatureControl.setFlowRate}
      setFlowRateUnit={UC.temperatureControl.setFlowRateUnit}
      touchField={UC.temperatureControl.touchField}
      containersAvailability={availability}
    />
  );
};

export {
  AirVentFieldContainer as AirVentField,
};
