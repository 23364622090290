import AntTable from 'antd/es/table';
import styled from 'styled-components';
import ClockCircleOutlinedIcon from '@ant-design/icons/ClockCircleOutlined';

import themesColors from 'app-wrapper/view/themes/themesColors';
import { Button } from 'app-wrapper/view/components';

export const UserManagementContentTable = styled(AntTable).attrs({
  'data-class': 'UserManagementContentTable',
})`
  .ant-table-content table {
    table-layout: fixed !important;

    color: ${themesColors.neutralBranding10};
  }

  .ant-table-thead > tr > th {
    background: white;
  }

  .ant-table-thead > tr > th:first-of-type {
    padding-left: 0;
  }

  .ant-table-tbody > tr > td:first-of-type {
    padding-left: 0px;
  }

  .ant-table-tbody > tr > td {
    padding-left: 8px;
    padding-top: 5.5px;
    padding-bottom: 7.5px;
  }

  .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    display: none;
  }

  .ant-table-thead .ant-table-cell {
    color: #73819B;
    font-size: 12px;
    line-height: 20px;
    font-weight: 400;
    padding: 8px;
  }

  .ant-table-tbody > tr:last-of-type > td {
    border-bottom: none;
  }

  .ant-table-cell:first-of-type {
    & > article {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;

export const UserManagementContentEditButton = styled(Button).attrs({
  'data-class': 'UserManagementContentEditButton',
})`
  color: ${themesColors.secondaryDot45};
`;

export const ClockCircleOutlinedEmail = styled.span.attrs((props: {
  isInvited: boolean
}) => ({
  isInvited: props.isInvited,
  'data-class': 'ClockCircleOutlinedEmail',
}))`
  color: ${({ isInvited }) => (isInvited ? themesColors.secondaryDot45 : themesColors.neutralBranding10)};
`;

export const InvitedName = styled.span`
  margin-left: 8px;
  color: ${themesColors.secondaryDot45};
`;

export const ClockCircleOutlinedIconStyled = styled(ClockCircleOutlinedIcon).attrs({
  'data-class': 'ClockCircleOutlinedIconStyled',
})`
  color: ${themesColors.neutralBranding6};
`;

export const UserName = styled.span`
  cursor: pointer;
  color: #4A55AD;
`;
