import React, { FC, useEffect } from 'react';
import get from 'lodash/fp/get';
import { useParams } from 'react-router';
import Spinner from 'antd/es/spin';
import Tabs from 'antd/es/tabs';
import { useTranslation } from 'react-i18next';

import { DataType } from 'shipment-operations/constants';
import { EditableRow } from 'shipment-operations/view/components';
import { ShipmentDrawerWrapper } from 'shipment-operations/view/pages/Shipment/components/ShipmentDrawer/ShipmentDrawer.styled';
import { SpinnerWrap } from 'app-wrapper/view/guideline';
import { EditableCell, Header, Footer } from './components';
import { useTableEditable } from './useTableEdit';
import {
  Wrapper, MainTable, RedDot, TableWrapper,
} from './AddRates.styled';

interface IAddRatesComponentProps {
  onClose: () => void;
  loadCharges: (shipmentId: string) => void;
  loading: boolean;
  containersData: DataType[];
  shipmentData: DataType[];
  deleteCharge: (id: number, type: string) => void;
  addCharge: (title: string, type: string, designation: string) => void;
  onChangeCharge: (row: DataType, type: string) => void;
  uniqContainers: string[];
  shipmentTabError: boolean;
  containerTabError: boolean;
  onClear: () => void;
  typeDrawer: string;
}

export type FixedType = 'left' | 'right' | boolean;

const AddRatesComponent: FC<IAddRatesComponentProps> = ({
  onClose, loadCharges, loading, containersData, deleteCharge, addCharge, uniqContainers,
  shipmentData, onChangeCharge, shipmentTabError, containerTabError, onClear, typeDrawer,
}) => {
  const { shipmentId } = useParams<'shipmentId'>();
  const { t } = useTranslation();

  useEffect(() => {
    if (shipmentId) {
      loadCharges(shipmentId);
    }
  }, []);

  useEffect(() => () => {
    onClear();
  }, []);

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const { dataSource: containersDataSource, columns: containersColumns } = useTableEditable(containersData, onChangeCharge, addCharge, deleteCharge, uniqContainers);
  const { dataSource: shipmentDataSource, columns: shipmentColumns } = useTableEditable(shipmentData, onChangeCharge, addCharge, deleteCharge);

  return (
    <ShipmentDrawerWrapper
      title={t('addRates')}
      visible
      width="1137px"
      closable
      maskClosable={false}
      onClose={onClose}
    >
      {loading ? (
        <SpinnerWrap>
          <Spinner />
        </SpinnerWrap>
      ) : (
        <Wrapper>
          <Header />
          <Tabs>
            <Tabs.TabPane
              tab={(
                <div>
                  <span>{t('containersCharges')}</span>
                  {containerTabError && <RedDot />}
                </div>
              )}
              key="1"
            >
              <TableWrapper>
                <MainTable
                  columns={containersColumns}
                  dataSource={containersDataSource}
                  scroll={{ x: 1200, y: 550 }}
                  pagination={false}
                  rowClassName={(record: DataType | {}) => {
                    const title = get('title', record);
                    return title ? 'title' : '';
                  }}
                  bordered
                  components={components}
                />
              </TableWrapper>
            </Tabs.TabPane>
            <Tabs.TabPane
              tab={(
                <div>
                  <span>{t('shipmentCharges')}</span>
                  {shipmentTabError && <RedDot />}
                </div>
            )}
              key="2"
            >
              <MainTable
                columns={shipmentColumns}
                dataSource={shipmentDataSource}
                scroll={{ x: 1200, y: 550 }}
                pagination={false}
                rowClassName={(record: DataType | {}) => {
                  const title = get('title', record);
                  return title ? 'title' : '';
                }}
                bordered
                components={components}
              />
            </Tabs.TabPane>
          </Tabs>
          <Footer typeDrawer={typeDrawer} />
        </Wrapper>
      )}
    </ShipmentDrawerWrapper>
  );
};

export { AddRatesComponent };
