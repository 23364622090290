import React, {
  ChangeEvent,
  FC,
  useCallback,
  useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';

import { Tooltip } from 'app-wrapper/view/components';
import { PermissionAttributePolicy } from 'shipment-operations/constants';

import { ContainerNumberInputField, ContainerNumberInputWrapper } from './ShipmentContainerNumberInputField.styled';

interface IShipmentContainerNumberInputFieldComponentProps {
  value?: string
  error?: Error
  disabled: boolean
  onChange: (value: string) => void
  onBlur: () => void
  containersAvailability?: PermissionAttributePolicy;
}

export const ShipmentContainerNumberInputFieldComponent: FC<IShipmentContainerNumberInputFieldComponentProps> = ({
  value,
  error,
  disabled,
  onChange,
  onBlur,
  containersAvailability,
}) => {
  const { t } = useTranslation();

  const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value);
  }, []);

  const handleBlur = useCallback(() => {
    onBlur();
  }, []);

  const isDisabled = useMemo(() => containersAvailability !== PermissionAttributePolicy.WRITE || disabled, [containersAvailability, disabled]);

  return (
    <Tooltip mode="danger" placement="bottom" title={error?.message} visible={!!error}>
      <ContainerNumberInputWrapper>
        <span>{ t('Container Number') }</span>
        <ContainerNumberInputField
          value={value}
          error={!!error}
          disabled={isDisabled}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </ContainerNumberInputWrapper>
    </Tooltip>
  );
};
