import React, {
  FC, useContext, Fragment, cloneElement,
} from 'react';
import { observer } from 'mobx-react-lite';
import { MobxStoresContext } from 'app-wrapper/mobxStores';
import { DrawersComponent } from './Drawers.component';

export const DrawersContainer: FC = observer(() => {
  const { drawerStore } = useContext(MobxStoresContext);
  const drawers = drawerStore.state.currentDrawers;

  if (drawers.length === 0) {
    return null;
  }

  return (
    <DrawersComponent>
      {drawers.map((drawer) => (
        <Fragment key={drawer.id}>
          {cloneElement(drawer.reactElement, { drawerId: drawer.id })}
        </Fragment>
      ))}
    </DrawersComponent>
  );
});
