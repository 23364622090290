import { Type } from 'class-transformer';
import {
  ValidateNested, IsOptional, IsBoolean, IsNumber,
} from 'class-validator';
import { BaseDTM } from 'proto/BaseDTM';

import { IFreightQuotaContentDTM, FreightQuotaContentDTM } from './freightQuotaContent.dtm';
import { IFreightQuotaPageableDTM, FreightQuotaPageableDTM } from './freightQuotaPeageable.dtm';
import { IFreightQuotasOptionDTM, FreightQuotasOptionDTM } from './freightQuotasOption.dtm';

export interface IFreightQuotasStateDataSortDTM {
  empty?: boolean
  sorted?: boolean
  unsorted?: boolean
}

export class FreightQuotasStateDataSortDTM extends BaseDTM<IFreightQuotasStateDataSortDTM> {
  @IsOptional()
  @IsBoolean()
  empty?: boolean;

  @IsOptional()
  @IsBoolean()
  sorted?: boolean;

  @IsOptional()
  @IsBoolean()
  unsorted?: boolean;
}

export interface IFreightQuotasStateDataDTM {
  content?: IFreightQuotaContentDTM[]
  pageable?: IFreightQuotaPageableDTM
  last?: boolean
  totalPages?: number
  totalElements?: number
  size?: number
  number?: number
  sort?: IFreightQuotasStateDataSortDTM
  first?: boolean
  numberOfElements?: number
  empty?: boolean
}

export class FreightQuotasStateDataDTM extends BaseDTM<IFreightQuotasStateDataDTM> {
  @IsOptional()
  @ValidateNested({ each: true })
  @Type(() => FreightQuotaContentDTM)
  content?: FreightQuotaContentDTM[];

  @IsOptional()
  @ValidateNested()
  @Type(() => FreightQuotaPageableDTM)
  pageable?: FreightQuotaPageableDTM;

  @IsOptional()
  @IsBoolean()
  last?: boolean;

  @IsOptional()
  @IsNumber()
  totalPages?: number;

  @IsOptional()
  @IsNumber()
  totalElements?: number;

  @IsOptional()
  @IsNumber()
  size?: number;

  @IsOptional()
  @IsNumber()
  number?: number;

  @IsOptional()
  @ValidateNested()
  @Type(() => FreightQuotasStateDataSortDTM)
  sort?: FreightQuotasStateDataSortDTM;

  @IsOptional()
  @IsBoolean()
  first?: boolean;

  @IsOptional()
  @IsNumber()
  numberOfElements?: number;

  @IsOptional()
  @IsBoolean()
  empty?: boolean;
}

export interface IFreightQuotasStateDTM {
  isLoadingPrint?: boolean
  isFavorite?: boolean
  isFavoriteLoading?: boolean
  printedQuotaId?: number
  quotasOption?: IFreightQuotasOptionDTM
  data?: IFreightQuotasStateDataDTM
  totalElements?: number
  allQuotas?: IFreightQuotaContentDTM[]
  indexSchedules?: {
    [key: number]: {
      idIndex: number
    }
  }
}

export class FreightQuotasStateDTM extends BaseDTM<IFreightQuotasStateDTM> {
  @IsOptional()
  @IsBoolean()
  isLoadingPrint?: boolean;

  @IsOptional()
  @IsBoolean()
  isFavorite?: boolean;

  @IsOptional()
  @IsBoolean()
  isFavoriteLoading?: boolean;

  @IsOptional()
  @IsNumber()
  printedQuotaId?: number;

  @IsOptional()
  @ValidateNested()
  @Type(() => FreightQuotasOptionDTM)
  quotasOption?: FreightQuotasOptionDTM;

  @IsOptional()
  @ValidateNested()
  @Type(() => FreightQuotasStateDataDTM)
  data?: FreightQuotasStateDataDTM;

  @IsOptional()
  @IsNumber()
  totalElements?: number;

  @IsOptional()
  @ValidateNested({ each: true })
  @Type(() => FreightQuotaContentDTM)
  allQuotas?: FreightQuotaContentDTM[];

  @IsOptional()
  @IsNumber()
  indexSchedule?: number;
}
