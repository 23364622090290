import styled from 'styled-components';

import { divFlex } from 'app-wrapper/view/themes/mixin';

export const SelectItemTitle = styled.div.attrs({
  'data-class': 'SelectItemTitle',
})`
  ${divFlex}
  justify-content: start;
  align-items: center;

  font-weight: 500;
  font-size: 14px;
  color: ${({ theme }) => theme.themesColors.neutralBranding10};
`;
