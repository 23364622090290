import React, {
  FC,
  useCallback,
  useEffect, useRef, useState,
} from 'react';

import { FreightRFRQuotaContentDTM } from 'monetary/models/dtm';

// import { REQUEST_STATUS } from 'app-wrapper/constants';
import { SiderTrigger } from 'app-wrapper/view/components';

import { RFRTabs } from './RFRTabs';
import { AddressBlock } from './AddressBlock';
import { Commodity } from './Commodity';
import { Containers } from './Containers';
import { Services } from './Services';
// import { FormFooter } from './FormFooter';

import { RFRFormRequestDivider, RFRFormRequestRequest, RFRFormRequestRequestGetQuotas } from './RFRFormRequest.styled';
import { Carriers } from './Carriers';
import { Customer } from './Customer';

export interface IRFRFormRequestComponentProps {
  quotaSearchId?: string | null
  forceCollapse?: boolean
  hideButton?: boolean
  isAllRequestedPage?: boolean
  onStartFirstRun: () => void
  onSubmit: () => void
  onSubmitReuseRequest?: () => void
  onCollapseChange: (isForceFalse?: boolean) => void
  isCollapsed?: boolean
  isReject?: boolean
  requestQuotaStatus?: string
  quotaStatusMessage?: string
  rfrRequest?: FreightRFRQuotaContentDTM;
}

export const RFRFormRequestComponent: FC<IRFRFormRequestComponentProps> = (props) => {
  const {
    isAllRequestedPage,
    onStartFirstRun,
    onSubmit,
    onSubmitReuseRequest,
    quotaSearchId,
    // hideButton,
    forceCollapse = false,
    onCollapseChange,
    isCollapsed,
  } = props;

  const formRef = useRef(null);

  const [formLocalState, setFormLocalState] = useState<{ isCollapsed?: boolean }>({
    isCollapsed: true,
  });

  useEffect(() => {
    if (forceCollapse) {
      onCollapseChange(true);
    }
  }, [forceCollapse]);

  useEffect(() => {
    setFormLocalState((prev) => ({ ...prev, isCollapsed }));
  }, [isCollapsed]);

  // first run
  useEffect(() => {
    if (!isAllRequestedPage) {
      onStartFirstRun();
    }
  }, []);

  const [isQuotaSearchFirst, setIsQuotaSearchFirst] = useState(false);

  useEffect(() => {
    if (quotaSearchId && !isQuotaSearchFirst) {
      setIsQuotaSearchFirst(true);
    }
  }, [
    isQuotaSearchFirst,
    quotaSearchId,
  ]);

  const onCollapseChangeHandler = useCallback(
    () => {
      onCollapseChange();
    },
    [onCollapseChange],
  );

  const onSubmitHandler = useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      if (onSubmitReuseRequest) {
        onSubmitReuseRequest();
        return;
      }
      onSubmit();
    },
    [onSubmitReuseRequest],
  );

  return (
    <RFRFormRequestRequest
      id="RFRFormRequestRequest"
      className={`${isCollapsed ? 'RFRFormRequest__isCollapsed' : 'RFRFormRequest__isNotCollapsed'} RFRFormRequest ${forceCollapse ? 'RFRFormRequest__isForceCollapse' : ''}`}
      onSubmit={onSubmitHandler}
      ref={formRef}
    >
      <SiderTrigger onClick={onCollapseChangeHandler} isCollapsed={!isCollapsed} />

      <RFRFormRequestRequestGetQuotas className="RFRFormRequest__get-quotes-form">
        <div>
          <RFRTabs />

          <RFRFormRequestDivider />

          <AddressBlock />

          <Commodity />

          <Containers
            forceCollapse={forceCollapse}
            formLocalState={formLocalState}
            setFormLocalState={setFormLocalState}
          />

          <Carriers
            forceCollapse={forceCollapse}
            formLocalState={formLocalState}
            setFormLocalState={setFormLocalState}
          />

          <Customer
            forceCollapse={forceCollapse}
            formLocalState={formLocalState}
          />

          <Services />
        </div>

      </RFRFormRequestRequestGetQuotas>

      {/* <FormFooter
        isFormCollapsed={isCollapsed}
        hideButton={hideButton}
        isReuseRequest={!!onSubmitReuseRequest}
      /> */}
    </RFRFormRequestRequest>
  );
};
