import { Moment } from 'moment';
import moment from 'moment/moment';

import { GetShortChargesContract } from 'shipment-operations/models/contracts';
import {
  InvoicesChargeDTM, ShortChargeDTM,
} from 'shipment-operations/models/dtm';
import { ContainerTypesConst, InvoiceStatusesEnum } from 'shipment-operations/constants';
import { apiWorker } from 'app-wrapper/repository/utilsServices';

const timeFormat = 'D MMM YYYY';

export interface ICreateInvoiceBody {
  type: string
  billTo?: {
    reference: {
      type: string
      companyId: number | undefined
      addressId: number | undefined
    }
  }
  billFrom?: {
    reference: {
      type: string
      companyId: number | undefined
      addressId: number | undefined
    }
  }
  dueDate: Moment
  description: string
  items: {
    charge: {
      id: number
    }
  }[]
}

export class CreateInvoiceChargesService {
  public getCharges = async (shipmentId: string, type: string) => {
    let result: ShortChargeDTM[] | null;

    try {
      const response = await apiWorker.requestGet<GetShortChargesContract[]>(`/billing-service/api/v1/shipments/${shipmentId}/${type}/charges`);

      const parsedResponse = response.data.map((parsedRes) => {
        const parsedItem = (ShortChargeDTM.fromPlain({
          applied: parsedRes.applied,
          active: parsedRes.active,
          additional: parsedRes.additional,
          description: parsedRes.description,
          status: parsedRes.status,
          createdAt: parsedRes.createdAt,
          createdBy: parsedRes.createdBy,
          applianceRange: parsedRes.applianceRange ? {
            minValue: parsedRes.applianceRange?.minValue,
            maxValue: parsedRes.applianceRange?.maxValue,
          } : undefined,
          currency: parsedRes.currency,
          contract: parsedRes.contract && {
            id: parsedRes.contract.id,
            name: parsedRes.contract.name,
            number: parsedRes.contract.number,
            scac: parsedRes.contract.scac,
          },
          id: parsedRes.id,
          designation: parsedRes.designation,
          measureBy: parsedRes.measureBy,
          priceBy: parsedRes.priceBy,
          chargeCode: {
            occurrence: parsedRes.chargeCode.occurrence,
            description: parsedRes.chargeCode.code === 'MSC' ? (parsedRes.metadata?.originalDescription?.toLowerCase() || parsedRes.chargeCode.description) : parsedRes.chargeCode.description,
            type: parsedRes.chargeCode.type,
            code: parsedRes.chargeCode.code,
            subType: parsedRes.chargeCode.subType,
            loadType: parsedRes.chargeCode.loadType,
            mode: parsedRes.chargeCode.mode,
            status: parsedRes.chargeCode.status,
          },
          costPerUnit: parsedRes.costPerUnit,
          numberOfUnits: parsedRes.numberOfUnits,
          totalCost: parsedRes.totalCost,
          subjectTo: parsedRes.subjectTo,
          container: parsedRes.container ? {
            number: parsedRes.container.number,
            type: ContainerTypesConst[parsedRes.container.type as keyof typeof ContainerTypesConst],
            id: parsedRes.container.id,
          } : undefined,
          budget: {
            balance: parsedRes.budget.balance,
            costPerUnit: parsedRes.budget.costPerUnit,
            numberOfUnits: parsedRes.budget.numberOfUnits,
            totalCost: parsedRes.budget.totalCost,
          },
          creditor: parsedRes.creditor && {
            id: parsedRes.creditor.id,
            organizationId: parsedRes.creditor.organizationId,
            name: parsedRes.creditor.name,
            phone: parsedRes.creditor.phone,
            phone2: parsedRes.creditor.phone2,
            email: parsedRes.creditor.email,
            usci: parsedRes.creditor.usci,
            taxId: parsedRes.creditor.taxId,
          },
          debtor: parsedRes.debtor && {
            id: parsedRes.debtor.id,
            organizationId: parsedRes.debtor.organizationId,
            name: parsedRes.debtor.name,
            phone: parsedRes.debtor.phone,
            phone2: parsedRes.debtor.phone2,
            email: parsedRes.debtor.email,
            usci: parsedRes.debtor.usci,
            taxId: parsedRes.debtor.taxId,
          },
          transportationIds: parsedRes.transportationIds,
          rateId: parsedRes.rateId,
          invoiced: parsedRes.invoiced,
          balance: parsedRes.balance,
        }));
        if (!parsedItem.isValid()) {
          console.error('Data from API does not match with contract');
        }
        return parsedItem;
      });
      result = parsedResponse.filter((el) => el !== null) as ShortChargeDTM[];
    } catch (e) {
      throw new Error('Something wrong, please try again');
    }
    return result;
  }

  public createInvoice = async (shipmentId: string, body: ICreateInvoiceBody, type: string) => {
    const res = await apiWorker.requestPost(
      `/billing-service/api/v1/shipments/${shipmentId}/${type}/invoices`,
      body,
    );

    const parsedRes = res.data;

    const result = {
      id: parsedRes.id,
      status: parsedRes.status as InvoiceStatusesEnum,
      companyName: parsedRes.billTo.name,
      accountId: parsedRes.billTo.id,
      accountIdFrom: parsedRes.billFrom.id,
      billedDate: moment(parsedRes.createdAt).format(timeFormat),
      dueDate: moment(parsedRes.dueDate).format(timeFormat),
      billed: parsedRes.billed,
      adjusted: parsedRes.adjusted,
      paid: parsedRes.paid,
      balance: parsedRes.balance,
      number: parsedRes.number,
      charges: parsedRes.items.map((item: InvoicesChargeDTM) => ({
        id: item.id,
        charge: {
          id: item.charge.id,
          status: item.charge.status,
          createdAt: item.charge.createdAt,
          createdBy: item.charge.createdBy,
          designation: item.charge.designation,
          priceBy: item.charge.priceBy,
          measureBy: item.charge.measureBy,
          chargeCode: {
            code: item.charge.chargeCode.code,
            description: item.charge.chargeCode.description,
            mode: item.charge.chargeCode.mode,
            type: item.charge.chargeCode.type,
            subType: item.charge.chargeCode.subType,
            occurrence: item.charge.chargeCode.occurrence,
          },
          applianceRange: {
            minValue: item.charge.applianceRange?.minValue,
            maxValue: item.charge.applianceRange?.maxValue,
          },
          subjectTo: item.charge.subjectTo,
          applied: item.charge.applied,
          currency: item.charge.currency,
          costPerUnit: item.charge.costPerUnit,
          numberOfUnits: item.charge.numberOfUnits,
          totalCost: item.charge.totalCost,
          container: item.charge.container ? {
            id: item.charge.container.id,
            type: ContainerTypesConst[item.charge.container.type as keyof typeof ContainerTypesConst],
            number: item.charge.container.number,
          } : undefined,
          contract: item.charge.contract,
          transportationIds: item.charge.transportationIds,
          rateId: item.charge.rateId,
        },
        costPerUnit: item.costPerUnit,
        numberOfUnits: item.numberOfUnits,
        amount: item.amount,
        invoiced: item.invoiced,
        adjustment: item.invoiced,
      })),
    };

    return result;
  }
}
