import reduce from 'lodash/fp/reduce';
import getOr from 'lodash/fp/getOr';

import { ChargeDTM, InvoicesChargeDTM } from 'shipment-operations/models/dtm';

export const calculateChargesSum = (data: ChargeDTM[]) => {
  const sum = reduce(
    (acc: number, cur: InvoicesChargeDTM | {}) => acc + getOr(0, ['totalCost'], cur), 0, data,
  );
  return parseFloat(sum.toFixed(2));
};
