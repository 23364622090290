import React, { FC, useEffect, useMemo } from 'react';
import { Outlet } from 'react-router-dom';

import { ContainersChangesWarning, SubmitAmendments } from 'shipment-operations/view/components';
import {
  PermissionAttributePolicy,
  ShipmentBookingStatusEnum, ShipmentStatusEnum,
} from 'shipment-operations/constants';
import { ShipmentPreviewDTM } from 'shipment-operations/models/dtm';
import { hasAccess, hasReadAccess } from 'app-wrapper/utils';

import {
  ShipmentContainersHeader,
  ShipmentContainersList,
  ShipmentContainersScaffold,
  ShipmentContainersTitle,
  ShipmentContainersFormFooter,
} from './components';

import { ShipmentDetailsForms } from '../../components/ShipmentDetailsForms';

interface IShipmentContainersComponentProps {
  isLoading: boolean;
  cleanup: () => void;
  isShowWarning?: boolean;
  toggleChangesDrawer: () => void;
  toggleMaerskDrawer: () => void;
  shipment?: ShipmentPreviewDTM;
  isMaersk: boolean
  partnerWarning?: boolean
  permissions?: PermissionAttributePolicy
  isRequestTheSame?: boolean
}

export const ShipmentContainersComponent: FC<IShipmentContainersComponentProps> = ({
  isLoading,
  cleanup,
  isShowWarning,
  toggleMaerskDrawer,
  toggleChangesDrawer,
  shipment,
  isMaersk,
  partnerWarning,
  permissions,
  isRequestTheSame,
}) => {
  useEffect(() => () => cleanup(), []);

  const isCustomer = hasAccess(permissions, PermissionAttributePolicy.READ);

  const handleOpen = () => {
    if (isMaersk && !isCustomer && (shipment?.bookingStatus === ShipmentBookingStatusEnum.BOOKING_SUBMITTED)) {
      toggleMaerskDrawer();
    } else {
      toggleChangesDrawer();
    }
  };

  const isCancelled = shipment?.status === ShipmentStatusEnum.CANCELLED;

  const getWarningBlock = useMemo(() => {
    if (isShowWarning) {
      return (
        <ContainersChangesWarning
          status={shipment?.bookingStatus}
          handleDrawerOpen={handleOpen}
          isRequestTheSame={shipment?.isRequestTheSameWithShipment}
          isCustomer={hasReadAccess(permissions)}
          isMaersk={isMaersk}
          partnerWarning={partnerWarning}
        />
      );
    }
    if (!isRequestTheSame && !isCustomer) {
      return (
        <SubmitAmendments />
      );
    }
    return null;
  }, [isShowWarning, isRequestTheSame]);

  return (
    <ShipmentContainersScaffold
      title={<ShipmentContainersTitle />}
      warningBlock={!isCancelled && getWarningBlock}
      header={<ShipmentContainersHeader />}
      containersList={<ShipmentContainersList />}
      containerData={(
        <ShipmentDetailsForms
          isLoading={isLoading}
          footer={<ShipmentContainersFormFooter />}
        >
          <Outlet />
        </ShipmentDetailsForms>
      )}
    />
  );
};
