import { createSelector } from 'reselect';
import { RootState } from 'app-wrapper/store';

const localState = (state: RootState) => state.ratesModal;

const getLoading = createSelector(
  localState,
  (state) => state.isLoading,
);

const getOpenState = createSelector(
  localState,
  (state) => state.isOpen,
);

const getRateType = createSelector(
  localState,
  (state) => state.rateType,
);

const getDate = createSelector(
  localState,
  (state) => state.date,
);

const getDateError = createSelector(
  localState,
  (state) => state.dateError,
);

const getCarrierScacList = createSelector(
  localState,
  (state) => state.carrierScacList,
);

const isFormDataFilled = createSelector(
  localState,
  (state) => (
    !!state.date
  ),
);

const isFormDataValid = createSelector(
  localState,
  (state) => (
    !state.dateError
  ),
);

export const ratesModalSelectors = {
  getCarrierScacList,
  getDate,
  getDateError,
  getLoading,
  getOpenState,
  getRateType,
  isFormDataFilled,
  isFormDataValid,
};
