import React, { useCallback, useMemo, FC } from 'react';
import { useTranslation } from 'react-i18next';
import get from 'lodash/fp/get';
import { useParams } from 'react-router-dom';
import Panel from 'antd/es/collapse/CollapsePanel';
import Alert from 'antd/es/alert';
import moment from 'moment';

import { useToggleCollapse } from 'shipment-operations/view/hooks';
import { LinkedCreditNoteDTM } from 'shipment-operations/models/dtm';
import { NameOfDocument } from 'shipment-operations/view/pages/ShipmentBilling/ShipmentBilling.styled';

import { RouteNames } from 'app-wrapper/constants';

import {
  CollapseTitlePayments, CustomTable, CustomTableHeader,
  StyledCollapse,
} from 'shipment-operations/view/pages/ShipmentBillingInvoice/components/Footer/Footer.styled';
import { StyledExpandIcon } from 'shipment-operations/view/components';

interface IPaymentsComponentProps {
  data?: LinkedCreditNoteDTM[]
  isError?: boolean
}

const timeFormat = 'D MMM YYYY';

const PaymentsComponent: FC<IPaymentsComponentProps> = ({ data, isError }) => {
  const { t } = useTranslation();
  const { invoiceType } = useParams<'invoiceType'>();

  const { openedKeys, onToggleCollapse } = useToggleCollapse();
  const onToggle = useCallback((key) => () => onToggleCollapse(key), [onToggleCollapse]);

  const columns = useMemo(() => [
    {
      title: 'Date',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: '33%',
      render: (item: string) => moment(item).format(timeFormat),
    },
    {
      title: 'Reference',
      dataIndex: 'number',
      key: 'number',
      width: '40%',
      render: (item: string, record: LinkedCreditNoteDTM | {}) => {
        const id = get(['id'], record);

        return (
          <NameOfDocument to={RouteNames.FINANCES_PAYMENT_VIEW(id, invoiceType)}>{item}</NameOfDocument>
        );
      },
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      width: '26%',
      align: 'right' as 'right',
      render: (sum: number) => `($ ${sum.toFixed(2)})`,
    },
  ], []);

  return (data && data?.length) ? (
    <StyledCollapse ghost expandIcon={StyledExpandIcon} onChange={onToggle(1)}>
      <Panel
        key={1}
        header={
          <CollapseTitlePayments active={openedKeys.includes(1)}>{t('payment.payment')}</CollapseTitlePayments>
        }
      >
        {!isError ? (
          <>
            <CustomTableHeader
              columns={columns}
              pagination={false}
            />
            <CustomTable
              showHeader={false}
              pagination={false}
              columns={columns}
              dataSource={data}
            />
          </>
        ) : (
          <Alert
            message={t('creditNote.errorTitle')}
            description={t('creditNote.errorSubTitle')}
            type="error"
          />
        )}
      </Panel>
    </StyledCollapse>
  ) : null;
};

export { PaymentsComponent };
