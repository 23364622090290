import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { SkypaceDarkLogo } from 'app-wrapper/view/icons';

import { EmailSvg } from './components';
import {
  SignUpSuccessWrapper,
  SignUpSuccessTitle,
  LogoWrapper,
  BottomMessage,
  SignUpSuccessForm,
  SignUpSuccessMessageHeader,
  SignUpSuccessMessage,
  SignUpSuccessCard,
  SignUpSuccessCardEmailImgWrapper,
} from './SignUpSuccess.styled';

interface ISignUpSuccessComponentProps {
  email?: string
  onClear: () => void
}

export const SignUpSuccessComponent: FC<ISignUpSuccessComponentProps> = ({ email, onClear }) => {
  const { t } = useTranslation();

  useEffect(() => onClear);

  return (
    <SignUpSuccessWrapper>
      <SignUpSuccessForm>
        <LogoWrapper>
          <SkypaceDarkLogo />
        </LogoWrapper>
        <SignUpSuccessTitle>
          {t('Empowering Global Trade Logistics')}
        </SignUpSuccessTitle>
        <SignUpSuccessCard>
          <SignUpSuccessCardEmailImgWrapper>
            <EmailSvg />
          </SignUpSuccessCardEmailImgWrapper>
          <SignUpSuccessMessageHeader>
            {t('Verify your Email')}
          </SignUpSuccessMessageHeader>
          <SignUpSuccessMessage>
            {t('To complete registration, please follow instructions sent to ')}
            <span>{email}</span>
          </SignUpSuccessMessage>
        </SignUpSuccessCard>
      </SignUpSuccessForm>
      <BottomMessage />
    </SignUpSuccessWrapper>
  );
};
