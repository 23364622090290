import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { UC } from 'shipment-operations/controllers';
import { UC as appUC } from 'app-wrapper/controllers';
import { R } from 'shipment-operations/repository';
import { hasAccess } from 'app-wrapper/utils';

import { PermissionAttributePolicy, ShipmentBookingStatusEnum } from 'shipment-operations/constants';
import { R as appR } from 'app-wrapper/repository';
import { ShipmentChangesComponent } from './ShipmentChanges.component';

const ShipmentChangesContainer = () => {
  const permissions = useSelector(R.selectors.shipment.getShipmentChangesPermissions);
  const bookingStatus = useSelector(R.selectors.shipment.getBookingStatus);
  const bookingStatusRequested = useSelector(R.selectors.shipmentChanges.getBookingStatusRequestedShipment);
  const shipmentStatus = useSelector(R.selectors.shipment.getShipmentStatus);
  const isRequestTheSameWithShipment = useSelector(R.selectors.shipment.getIsRequestTheSameWithShipment);
  const carrierNumber = useSelector(R.selectors.shipmentChanges.getCarrierNumber);
  const inttraNumber = useSelector(R.selectors.shipmentChanges.getInttraNumber);
  const cargoTable = useSelector(R.selectors.shipmentChanges.getCargoTable);
  const containersTable = useSelector(R.selectors.shipmentChanges.getContainersTable);
  const cutOffTable = useSelector(R.selectors.shipmentChanges.getCutOffTable);
  const locationsTable = useSelector(R.selectors.shipmentChanges.getLocationsTable);
  const loading = useSelector(R.selectors.shipmentChanges.getLoading);
  const withMismatches = useSelector(R.selectors.shipmentChanges.getWithMismatches);
  const isActionLoading = useSelector(R.selectors.shipmentChanges.getActionLoading);
  const isMaersk = useSelector(R.selectors.shipment.getIsMaersk);
  const transportPlanCurrent = useSelector(R.selectors.shipmentChanges.getTransportPlanCurrent);
  const transportPlanPrevious = useSelector(R.selectors.shipmentChanges.getTransportPlanPrevious);
  const transportPlanMismatchCurrent = useSelector(R.selectors.shipmentChanges.getTransportPlanMismatchCurrent);
  const isTransportPlanMismatch = useSelector(R.selectors.shipmentChanges.getIsTransportPlanMismatches);
  const shipmentId = useSelector(R.selectors.overview.getShipmentId);
  const isCustomerGlobal = useSelector(appR.selectors.home.getIsCustomer);
  const showValidate = useSelector(R.selectors.shipmentChanges.getIsShowValidate);
  const isEqualTransportPlan = useSelector(R.selectors.shipmentChanges.getIsEqualTransportPlan);
  const validationLoaded = useSelector(R.selectors.shipmentTrackerRoutes.getValidationLoaded);
  const withLocationSection = useSelector(R.selectors.shipmentChanges.getIsWithLocationSection);

  const isDisableSubmit = useMemo(() => (showValidate && !validationLoaded), [showValidate, validationLoaded]);

  const partnerWarning = useMemo(() => withMismatches && permissions === PermissionAttributePolicy.WRITE && (bookingStatus === ShipmentBookingStatusEnum.BOOKING_AMENDMENT_SUBMITTED || bookingStatus === ShipmentBookingStatusEnum.BOOKING_SUBMITTED), [withMismatches, bookingStatus]);
  const isCustomerShipment = hasAccess(permissions, PermissionAttributePolicy.READ);
  const isCustomer = isCustomerShipment || isCustomerGlobal;

  return (
    <ShipmentChangesComponent
      bookingStatus={bookingStatus || bookingStatusRequested}
      shipmentStatus={shipmentStatus}
      carrierNumber={carrierNumber}
      inttraNumber={inttraNumber}
      onChangeCarrierNumber={UC.shipmentChanges.onChangeCarrierNumber}
      onChangeInttraNumber={UC.shipmentChanges.onChangeInttraNumber}
      loadData={UC.shipmentChanges.loadData}
      onConfirm={UC.shipmentChanges.onConfirmData}
      onChangeCutOff={UC.shipmentChanges.onChangeCutOff}
      onChangeLocation={UC.shipmentChanges.onChangeLocation}
      onClose={appUC.drawer.closeDrawer}
      syncIntraNumber={UC.shipmentChanges.syncIntraNumber}
      cargoTable={cargoTable}
      containersTable={containersTable}
      cutOffTable={cutOffTable}
      locationsTable={locationsTable}
      loading={loading}
      isRequestTheSameWithShipment={isRequestTheSameWithShipment}
      withMismatches={withMismatches}
      isActionLoading={isActionLoading}
      isMaersk={isMaersk}
      partnerWarning={partnerWarning}
      transportPlanCurrent={transportPlanCurrent}
      transportPlanPrevious={transportPlanPrevious}
      transportPlanMismatchCurrent={transportPlanMismatchCurrent}
      isTransportPlanMismatch={isTransportPlanMismatch}
      savedShipmentId={shipmentId}
      isCustomer={isCustomer}
      reset={UC.shipmentChanges.onReset}
      showValidate={showValidate}
      isDisableSubmit={isDisableSubmit}
      isEqualTransportPlan={isEqualTransportPlan}
      withLocationSection={withLocationSection}
    />
  );
};

export { ShipmentChangesContainer as ShipmentChanges };
