import React, { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { R } from 'shipment-operations/repository';
import { PermissionAttributePolicy } from 'shipment-operations/constants';
import { UC } from 'shipment-operations/controllers';

import { ExportClearanceNumberInputComponent } from './ExportClearanceNumberInput.component';

const ExportClearanceNumberInputContainer: FC = () => {
  const { number } = useSelector(R.selectors.exportClearance.getExportClearance);
  const { number: numberError } = useSelector(R.selectors.exportClearance.getErrors);
  const touchedFields = useSelector(R.selectors.exportClearance.getTouchedFields);
  const wasUpdateAttempted = useSelector(R.selectors.exportClearance.getUpdateAttemptStatus);
  const permissions = useSelector(R.selectors.shipment.getExportClearancePermissions);

  const isDisabled = useMemo(() => permissions.exportClearance !== PermissionAttributePolicy.WRITE, [permissions]);

  const isErrorDisplayed = !!numberError && (touchedFields.exportClearanceNumber || wasUpdateAttempted);

  return (
    <ExportClearanceNumberInputComponent
      exportClearanceNumber={number}
      error={isErrorDisplayed}
      setExportClearanceNumber={UC.exportClearance.setExportClearanceNumber}
      disabled={isDisabled}
    />
  );
};

export {
  ExportClearanceNumberInputContainer as ExportClearanceNumberInput,
};
