import React from 'react';

import { UC } from 'shipment-operations/controllers';
import { R } from 'shipment-operations/repository';
import { useAppSelector } from 'app-wrapper/hooks';

import {
  ShipmentBillingInvoiceComponent,
} from 'shipment-operations/view/pages/ShipmentBillingInvoice/ShipmentBillingInvoice.component';

const ShipmentBillingInvoice = () => {
  const error = useAppSelector(R.selectors.shipmentBillingInvoice.getError);
  const isLoading = useAppSelector(R.selectors.shipmentBillingInvoice.getGlobalLoading);
  const createCreditNoteSuccess = useAppSelector(R.selectors.shipmentBillingInvoice.getCreditNoteSuccess);
  const isOpenCreditNote = useAppSelector(R.selectors.shipmentBillingInvoice.getIsOpenCreateCreditNote);
  const isOpenPayment = useAppSelector(R.selectors.shipmentBillingInvoice.getIsOpenPayment);
  const createPaymentSuccess = useAppSelector(R.selectors.makePayment.getIsSuccessCreation);
  const makePaymentButtonAvailability = useAppSelector(R.selectors.shipmentBillingInvoice.getMakePaymentButtonAvailability);

  return (
    <ShipmentBillingInvoiceComponent
      fetchData={UC.shipmentBillingInvoice.loadData}
      error={error}
      isLoading={isLoading}
      createCreditNoteSuccess={createCreditNoteSuccess}
      onResetCreditNote={UC.shipmentBillingInvoice.resetCreditNote}
      isOpenCreditNote={isOpenCreditNote}
      isOpenPayment={isOpenPayment}
      createPaymentSuccess={createPaymentSuccess}
      closePaymentSuccess={UC.makePayment.onCloseSuccess}
      makePaymentButtonAvailability={makePaymentButtonAvailability}
    />
  );
};

export { ShipmentBillingInvoice };
