import styled from 'styled-components';

import { colors } from 'app-wrapper/view/themes/themesColors';

export const CarrierContactCardContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 16px;
  background: #FFFFFF;
  border: 1px solid ${colors.purple15};
  border-radius: 3px;
  overflow: hidden;
  margin-bottom: 10px;
`;
