import React, { FC, memo } from 'react';
import { useSelector } from 'react-redux';

import { UC } from 'user-management/controllers';
import { R } from 'user-management/repository';

import { ContactBookByIdEditAddressComponent } from './ContactBookByIdEditAddress.component';

const ContactBookByIdEditAddressContainer: FC = () => {
  const contactBook = useSelector(R.selectors.contactBook.getContactBookUpdate);

  const listIndex = contactBook.activeListTabIndex;

  const companyListState = contactBook.activeCompanyEdit;

  const addressesIds = companyListState?.addressesIds || [];

  const companyListAddresses = useSelector(R.selectors.contactBook.getContactBookUpdateCompanyListAddressesByIds(addressesIds));

  const onChangeAddressIsPrimary = UC.contactBookDrawers.onChangeUpdateAddressesIsPrimaryByIndex(UC.contactBookDrawers.onChangeContactBookByIdEditAddressDrawer);

  return (
    <ContactBookByIdEditAddressComponent
      companyListAddresses={companyListAddresses}
      companyListStateIndex={listIndex}
      updateForm={UC.contactBookDrawers.onSaveCBByIdEditAddress}
      onDiscard={UC.contactBookDrawers.onCancelContactBookByIdEditAddress}
      removeAddress={UC.contactBookDrawers.onRemoveContactBookByIdEditAddress}
      onChangeAddressIsPrimary={onChangeAddressIsPrimary}
    />
  );
};

const ContactBookByIdEditAddressContainerCache = memo(ContactBookByIdEditAddressContainer);

export { ContactBookByIdEditAddressContainerCache as ContactBookByIdEditAddressPage };
