import {
  IsEnum,
  IsOptional,
} from 'class-validator';
import { BaseDTM } from 'proto/BaseDTM';

import { PermissionAttributePolicy } from 'shipment-operations/constants';

export type TOverviewPermissionsDTM = {
  manualConfirmationAvailability?: PermissionAttributePolicy;
  bookingAmendAvailability?: PermissionAttributePolicy;
  resubmitAvailability?: PermissionAttributePolicy;
  shipmentRollAvailability?: PermissionAttributePolicy;
  cancelShipmentAvailability?: PermissionAttributePolicy;
  mismatchesAvailability?: PermissionAttributePolicy;
  inttraReferenceNumberAvailability?: PermissionAttributePolicy;
  contractNumberAvailability?: PermissionAttributePolicy;
  shipmentChanges?: PermissionAttributePolicy;
}

export class OverviewPermissionsDTM extends BaseDTM<TOverviewPermissionsDTM> {
  @IsOptional()
  @IsEnum(PermissionAttributePolicy)
  manualConfirmationAvailability?: PermissionAttributePolicy;

  @IsOptional()
  @IsEnum(PermissionAttributePolicy)
  bookingAmendAvailability?: PermissionAttributePolicy;

  @IsOptional()
  @IsEnum(PermissionAttributePolicy)
  shipmentRollAvailability?: PermissionAttributePolicy;

  @IsOptional()
  @IsEnum(PermissionAttributePolicy)
  cancelShipmentAvailability?: PermissionAttributePolicy;

  @IsOptional()
  @IsEnum(PermissionAttributePolicy)
  mismatchesAvailability?: PermissionAttributePolicy;

  @IsOptional()
  @IsEnum(PermissionAttributePolicy)
  inttraReferenceNumberAvailability?: PermissionAttributePolicy;

  @IsOptional()
  @IsEnum(PermissionAttributePolicy)
  contractNumberAvailability?: PermissionAttributePolicy;

  @IsOptional()
  @IsEnum(PermissionAttributePolicy)
  resubmitAvailability?: PermissionAttributePolicy;

  @IsOptional()
  @IsEnum(PermissionAttributePolicy)
  shipmentChanges?: PermissionAttributePolicy;;
}
