import styled from 'styled-components';

export const Wrapper = styled.div`
  border: 0.5px solid #D1D7E2;
  padding: 5px 9px;
`;

export const ShippingParties = styled.div`
  display: flex;
  flex: 1;
  border-bottom: 0.5px solid #D1D7E2;
  margin-bottom: 8px;
  padding-bottom: 8px;
`;

export const ShipmentTransportWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  border-bottom: 0.5px solid #D1D7E2;
  margin-bottom: 8px;
  padding-bottom: 8px;
  font-size: 10px;
`;

export const Column = styled.div<{flex?: number}>`
  display: flex;
  flex-direction: column;
  flex: ${({ flex = '1' }) => flex};
  padding: 0;
  padding-right: 8px;
  margin-right: 8px;
  border-right: 0.5px solid #D1D7E2;

  &:last-child {
    border-right: none;
    padding-right: 0;
    margin-right: 0;
  }
`;

export const CustomColumn = styled(Column)`
  &:last-child {
    margin-right: -10px;
  }
`;

export const Row = styled.div<{ fontSize?: number }>`
  display: flex;
  flex-direction: row;
  width: 100%;
  flex: 1;
  padding-bottom: 8px;
  margin-bottom: 8px;
  border-bottom: 0.5px solid #D1D7E2;
  font-size: 10px;

  &:last-child {
    border-bottom: none;
    padding-bottom: 0px;
    margin-bottom: 0px;
  }
`;

export const TitleWrapper = styled.div`
  display: grid;
  place-items: center;
  font-size: 12px;
  margin: 5px 0 8px 0;
`;

export const TableHeader = styled.div`
  display: flex;
  background: #F4F5F8;
  border-radius: 4px;
  margin-bottom: 5px;
  padding: 3px;
  align-items: center;
  font-size: 10px;
`;

export const TableRow = styled.div`
  display: flex;
  border-bottom: 0.5px dashed #D1D7E2;
  font-size: 12px;
  padding: 5px 0 5px 0;
`;

export const PageWrapper = styled.div`
  width: calc(100% - 48px);
  margin: 0 auto;
  position: relative;
  border: 0.5px solid #D1D7E2;
  padding: 2px;
  font-size: 16px;
  text-align: start;
`;

export const Main = styled.div`
  padding: 5px 9px;
`;

export const Bold = styled.span<{bottomMargin?: boolean}>`
  font-weight: 700;
  text-transform: capitalize;
  ${({ bottomMargin }) => bottomMargin && 'margin-bottom: 4px;'}
`;

export const Span = styled.span<{fontSize?: number}>`
  font-size: ${({ fontSize }) => fontSize}rem;
  text-transform: capitalize;
`;

export const Card = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
`;
