import React, { useCallback } from 'react';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';

import { RouteNames } from 'app-wrapper/constants';
import { BackTitleIcon } from 'app-wrapper/view/guideline';

import { GoBackWrap } from './GoBack.styled';

export const GoBack = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleGoBack = useCallback(() => {
    navigate(RouteNames.USER_MANAGEMENT());
  }, [navigate]);

  return (
    <GoBackWrap onClick={handleGoBack}>
      <BackTitleIcon />

      {t('Members')}
    </GoBackWrap>
  );
};
