import React, { FC, useCallback } from 'react';
import Alert from 'antd/es/alert';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';

import { StyledButton } from './SubmitAmendments.styled';

interface ISubmitAmendmentsComponentProps {
  loading: boolean;
  onSubmit: (shipmentId: string) => void;
}

const SubmitAmendmentsComponent: FC<ISubmitAmendmentsComponentProps> = ({ loading, onSubmit }) => {
  const { shipmentId } = useParams<'shipmentId'>();
  const { t } = useTranslation();
  const handleSubmit = useCallback(() => {
    if (shipmentId) {
      onSubmit(shipmentId);
    }
  }, [onSubmit, shipmentId]);

  return (
    <Alert
      message={t('You have made amendments to the shipment that require a confirmation from the carrier.')}
      type="warning"
      style={{ marginBottom: '20px' }}
      action={(
        <StyledButton size="small" type="primary" loading={loading} onClick={handleSubmit}>
          {t('Submit')}
        </StyledButton>
      )}
    />
  );
};

export { SubmitAmendmentsComponent };
