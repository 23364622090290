import React, { FC } from 'react';

import { useAppSelector } from 'app-wrapper/hooks';
import { R } from 'shipment-operations/repository';
import { InvoiceStatusesEnum as statuses } from 'shipment-operations/constants';

import { HeaderComponent } from 'shipment-operations/view/components/ShipmentEntities';

interface HeaderProps {
  isAPInvoiceType: boolean;
}

const Header: FC<HeaderProps> = ({ isAPInvoiceType }) => {
  const status = statuses.APPLIED;
  const number = useAppSelector(R.selectors.creditNote.getNumber);

  return (
    <HeaderComponent
      status={status}
      number={number}
      title="creditNoteTitle"
      isAPInvoiceType={isAPInvoiceType}
      hidePDFIcon
    />
  );
};

export { Header };
