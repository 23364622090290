import React, { FC, memo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { R } from 'user-management/repository';
import { UC } from 'user-management/controllers';

import { ContactBookByIdEditContactPersonComponent } from './ContactBookByIdEditContactPerson.component';

const ContactBookByIdEditContactPersonContainer: FC = () => {
  const contactBookUpdate = useSelector(R.selectors.contactBook.getContactBookUpdate);
  const { t } = useTranslation();

  const companyListState = contactBookUpdate.activeCompanyEdit;

  const contactPersonsIds = companyListState?.contactPersonsIds || [];
  const companyListContactPersons = useSelector(R.selectors.contactBook.getContactBookUpdateCompanyListContactPersonsByIds(contactPersonsIds))?.[0];

  const errors = useSelector(R.selectors.contactBook.getContactBookUpdateErrorsContactPersonsById(companyListContactPersons?.customId || ''));

  return (
    <ContactBookByIdEditContactPersonComponent
      contactPerson={companyListContactPersons}
      errors={errors}
      firstBlockName={`${t('Full Name')} *`}
      isDisabledCancelBtn={false}
      isDisabledSaveBtn={false}
      onCancelClick={UC.contactBookDrawers.closeCBByIdEditContactDrawer}
      onSaveClick={UC.contactBookDrawers.onSaveContactBookByIdEditContactPersonDrawer}
      removeContact={UC.contactBookDrawers.onRemoveContactBookByIdEditContactPersonDrawer}
      onChangeAddressIsPrimary={UC.contactBookDrawers.onChangeUpdateContactsIsPrimaryByIndex(UC.contactBookDrawers.onChangeContactBookByIdEditContactPersonDrawer)}
      onChangeContactPerson={UC.contactBookDrawers.onChangeUpdateContactsContactPersonByIndex(UC.contactBookDrawers.onChangeContactBookByIdEditContactPersonDrawer)}
      onBlurContactPerson={UC.contactBookDrawers.onBlurUpdateContactsContactPersonByIndex(UC.contactBookDrawers.onValidateContactBookByIdEditContactPersonDrawer)}
      onFocusContactPerson={UC.contactBookDrawers.onFocusUpdateContactsContactPersonByIndex(UC.contactBookDrawers.onValidateContactBookByIdEditContactPersonDrawer)}
      onChangeAdditionalPhone={UC.contactBookDrawers.onChangeUpdateContactsAdditionalPhoneByIndex(UC.contactBookDrawers.onChangeContactBookByIdEditContactPersonDrawer)}
      onBlurAdditionalPhone={UC.contactBookDrawers.onBlurUpdateContactsAdditionalPhoneByIndex(UC.contactBookDrawers.onValidateContactBookByIdEditContactPersonDrawer)}
      onFocusAdditionalPhone={UC.contactBookDrawers.onFocusUpdateContactsAdditionalPhoneByIndex(UC.contactBookDrawers.onValidateContactBookByIdEditContactPersonDrawer)}
      onChangeEmail={UC.contactBookDrawers.onChangeUpdateContactsEmailByIndex(UC.contactBookDrawers.onChangeContactBookByIdEditContactPersonDrawer)}
      onBlurEmail={UC.contactBookDrawers.onBlurUpdateContactsEmailByIndex(UC.contactBookDrawers.onValidateContactBookByIdEditContactPersonDrawer)}
      onFocusEmail={UC.contactBookDrawers.onFocusUpdateContactsEmailByIndex(UC.contactBookDrawers.onValidateContactBookByIdEditContactPersonDrawer)}
      onChangePhone={UC.contactBookDrawers.onChangeUpdateContactsPhoneByIndex(UC.contactBookDrawers.onChangeContactBookByIdEditContactPersonDrawer)}
      onBlurPhone={UC.contactBookDrawers.onBlurUpdateContactsPhoneByIndex(UC.contactBookDrawers.onValidateContactBookByIdEditContactPersonDrawer)}
      onFocusPhone={UC.contactBookDrawers.onFocusUpdateContactsPhoneByIndex(UC.contactBookDrawers.onValidateContactBookByIdEditContactPersonDrawer)}
    />
  );
};

const ContactBookByIdEditContactPersonContainerCached = memo(ContactBookByIdEditContactPersonContainer);

export { ContactBookByIdEditContactPersonContainerCached as ContactBookByIdEditContactPersonPage };
