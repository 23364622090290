import React from 'react';
import { useSelector } from 'react-redux';

import { R } from 'user-management/repository';
import { UC } from 'user-management/controllers';
import { DocumentType } from 'shipment-operations/constants';
import { useAuthToken } from 'app-wrapper/hooks';

import { DocumentUploadComponent } from './DocumentUpload.component';

const DocumentUploadContainer = () => {
  const authToken = useAuthToken();
  const document = useSelector(R.selectors.newOrganizationDocuments.getDocumentToUpload);
  const organizationId = useSelector(R.selectors.newOrganizationDocuments.getOrganizationId);
  const type = useSelector(R.selectors.newOrganizationDocuments.getDocumentToUploadType) as DocumentType;

  return (
    <DocumentUploadComponent
      authToken={authToken}
      organizationId={organizationId}
      containerDocument={document}
      setContainerDocument={UC.newOrganizationDocuments.setDocumentToUpload}
      downloadDocument={UC.newOrganizationDocuments.downloadDocumentToUpload}
      addDocumentsToRemoveOnCancelId={UC.newOrganizationDocuments.addDocumentsToRemoveId}
      documentType={type}
    />
  );
};

export { DocumentUploadContainer as DocumentUpload };
