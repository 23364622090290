import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  Wrap,
  Image,
  Content,
  Title,
  Text,
  TextColored,
  ContactLink,
  ContactButton,
  ContactIcon,
} from './ContactUs.styled';
import contactSrc from './assets/contact.png';

export const ContactUs = () => {
  const { t } = useTranslation();

  return (
    <Wrap>
      <Image src={contactSrc} />

      <Content>
        <Title>
          {t('Do you still have any questions?')}
        </Title>

        <Text>
          {t('For detailed support, connect with our team by Email')}

          <TextColored>
            {t('support@skypace.com')}
          </TextColored>

          {t('or phone +1 415 651 84 45')}
        </Text>
      </Content>

      <ContactLink
        href="mailto:support@skypace.com"
      >
        <ContactButton
          type="default"
          icon={<ContactIcon />}
        >
          {t('Contact Us by Email')}
        </ContactButton>
      </ContactLink>
    </Wrap>
  );
};
