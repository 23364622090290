import React, { FC } from 'react';
import { useParams, Navigate } from 'react-router';

import { RouteNames } from 'app-wrapper/constants';

interface IAccountDefaultNavigateProps {
  partner?: boolean;
}

export const AccountDefaultNavigate: FC<IAccountDefaultNavigateProps> = ({ partner }: IAccountDefaultNavigateProps) => {
  const { customerId } = useParams<'customerId'>();

  return (
    <Navigate
      to={partner ? RouteNames.ACCOUNT_PARTNER_GENERAL_INFO(customerId) : RouteNames.ACCOUNT_CUSTOMER_GENERAL_INFO(customerId)}
    />
  );
};
