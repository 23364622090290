import React, { FC, memo } from 'react';
import { CommandCenterTasksCompleted } from './CommandCenter';

const CommandCenterTasksCompletedPage: FC = () => (
  <CommandCenterTasksCompleted />
);

const CommandCenterTasksCompletedPageCache = memo(CommandCenterTasksCompletedPage);

export { CommandCenterTasksCompletedPageCache as CommandCenterTasksCompletedPage };
