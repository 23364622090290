import Table from 'antd/es/table';
import styled from 'styled-components';

export const StyledTable = styled(Table)`
  .ant-table-tbody > tr > td > .ant-table-wrapper:only-child .ant-table {
    margin: 0 !important;
  }
  .ant-table {
    margin: 0 !important;
  }
`;

export const StyledDescription = styled.div`
  padding-left: 30px;
  display: flex;
  color: #73819B;
`;

export const Content = styled.div`
  font-size: 12px;
`;

export const StyledArrow = styled.div`
  width: 9px;
  height: 15px;
  border-bottom: 1px solid #D1D7E2;
  border-left: 1px solid #D1D7E2;
  margin-right: 15px;
  margin-top: -7px;
`;

export const StyledCell = styled.div`
  color: #73819B;
`;

export const StyledDelta = styled(StyledCell)`
  font-size: 10px;
  position: absolute;
  margin: auto;
  left: 0;
  bottom: -12px;
  right: 0;
`;

export const DescriptionDate = styled.div`
  font-size: 12px;
  font-weight: 400;
  color: #202C3C;
`;

export const Document = styled.div`
  cursor: pointer;
  text-decoration: underline;
  margin-left: 4px;
`;

export const DoubleCell = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;
