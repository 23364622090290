import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { R as RApp } from 'app-wrapper/repository';

import { UC } from 'user-management/controllers';
import { R } from 'user-management/repository';
import { ContactsAddressBlockComponent } from 'user-management/view/components';
import {
  ICompanyAddressDTM,
} from 'user-management/models/dtm';

interface IAddressesAndContactsAddressItemContainerProps {
  largeInput: boolean
  addressIndex: number
  companyListStateIndex: string
  isFirst: boolean
  isLast: boolean
  primaryComponent?: React.ReactNode
  address?: ICompanyAddressDTM
}

export const AddressesAndContactsAddressItemContainer: FC<IAddressesAndContactsAddressItemContainerProps> = (props) => {
  const {
    largeInput,
    addressIndex,
    companyListStateIndex,
    isFirst,
    isLast,
    primaryComponent,
    address,
  } = props;

  const countriesList = useSelector(RApp.selectors.countriesList.getCountriesList);

  const errors = useSelector(R.selectors.contactBook.getContactBookUpdateErrorsAddressesById(address?.customId || ''));

  return (
    <ContactsAddressBlockComponent
      largeInput={largeInput}
      countriesList={countriesList}
      isFirst={isFirst}
      isLast={isLast}
      address={address}
      addressIndex={addressIndex}
      primaryComponent={primaryComponent}
      companyListStateIndex={companyListStateIndex}
      errors={errors}
      onChangeAddressLineFirst={UC.contactBook.onChangeUpdateAACAddressLineFirstByIndex}
      onBlurAddressLineFirst={UC.contactBook.onBlurUpdateAACAddressLineFirstByIndex}
      onFocusAddressLineFirst={UC.contactBook.onFocusUpdateAACAddressLineFirstByIndex}
      onChangeAddressLineSecond={UC.contactBook.onChangeUpdateAACAddressLineSecondByIndex}
      onBlurAddressLineSecond={UC.contactBook.onBlurUpdateAACAddressLineSecondByIndex}
      onFocusAddressLineSecond={UC.contactBook.onFocusUpdateAACAddressLineSecondByIndex}
      onChangeCity={UC.contactBook.onChangeUpdateAACCityByIndex}
      onBlurCity={UC.contactBook.onBlurUpdateAACCityByIndex}
      onFocusCity={UC.contactBook.onFocusUpdateAACCityByIndex}
      onChangeCountry={UC.contactBook.onChangeUpdateAACCountryByIndex}
      onBlurCountry={UC.contactBook.onBlurUpdateAACCountryByIndex}
      onFocusCountry={UC.contactBook.onFocusUpdateAACCountryByIndex}
      onClearCountry={UC.contactBook.onClearUpdateAACCountryByIndex}
      onChangeState={UC.contactBook.onChangeUpdateAACStateByIndex}
      onClearState={UC.contactBook.onClearUpdateAACStateByIndex}
      onBlurState={UC.contactBook.onBlurUpdateAACStateByIndex}
      onFocusState={UC.contactBook.onFocusUpdateAACStateByIndex}
      onChangePostalCode={UC.contactBook.onChangeUpdateAACPostalCodeByIndex}
      onBlurPostalCode={UC.contactBook.onBlurUpdateAACPostalCodeByIndex}
      onFocusPostalCode={UC.contactBook.onFocusUpdateAACPostalCodeByIndex}
      addAddressItem={UC.contactBook.setUpdateAACAddCompanyListStateAddressItem}
      onRemoveAddress={UC.contactBook.setUpdateAACRemoveCompanyListStateAddressItem}
    />
  );
};

export { AddressesAndContactsAddressItemContainer as AddressesAndContactsAddressItem };
