import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';
import { UC } from 'shipment-operations/controllers';

import { ShipmentContainerDataComponent } from './ShipmentContainerData.component';

const ShipmentContainerDataContainer: FC = () => {
  const isOwnContainer = useSelector(R.selectors.shipment.getOwnContainersState);
  const hasHazmat = useSelector(R.selectors.shipmentContainers.getHasHazmat);
  const isLoading = useSelector(R.selectors.shipmentContainers.getLoadingState);
  const isInDraft = useSelector(R.selectors.shipmentContainers.isContainerChanged);

  return (
    <ShipmentContainerDataComponent
      isLoading={isLoading}
      isInDraft={isInDraft}
      isOwnContainer={isOwnContainer}
      hasHazmat={hasHazmat}
      stateClear={UC.shipmentContainers.clearFormDataState}
      dataUpload={UC.shipmentContainers.uploadContainerData}
    />
  );
};

export { ShipmentContainerDataContainer as ShipmentContainerData };
