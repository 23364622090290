import React from 'react';
import { observer } from 'mobx-react-lite';

import { UC } from 'app-wrapper/controllers';
import { useMobxStore } from 'app-wrapper/mobxStores';

import { ContractNumberComponent } from './ContractNumber.component';

export const ContractNumber = observer(() => {
  const { contracts } = useMobxStore();

  return (
    <ContractNumberComponent
      contractNumber={contracts.state.contractNumber}
      setContractNumber={UC.contracts.setContractNumber}
    />
  );
});
