import React from 'react';

import { IPropsIcon } from 'app-wrapper/interfaces/icon';

import { ContactCustomersSvgWrapper } from './ContactCustomersSvg.styled';

export const ContactCustomersSvg = (props: IPropsIcon) => {
  const {
    width, height, style, fill, className, widthWrapper,
  } = props;

  return (
    <ContactCustomersSvgWrapper width={widthWrapper} className={className}>
      <svg
        className="ContactCustomersSvg"
        width={width || '15'}
        height={height || '12'}
        viewBox="0 0 15 12"
        color={style?.color}
        style={style || undefined}
        fill={fill || 'currentColor'}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M14 1.50012H11.5V0.625122C11.5 0.556372 11.4438 0.500122 11.375 0.500122H10.5C10.4312 0.500122 10.375 0.556372 10.375 0.625122V1.50012H8.0625V0.625122C8.0625 0.556372 8.00625 0.500122 7.9375 0.500122H7.0625C6.99375 0.500122 6.9375 0.556372 6.9375 0.625122V1.50012H4.625V0.625122C4.625 0.556372 4.56875 0.500122 4.5 0.500122H3.625C3.55625 0.500122 3.5 0.556372 3.5 0.625122V1.50012H1C0.723437 1.50012 0.5 1.72356 0.5 2.00012V11.0001C0.5 11.2767 0.723437 11.5001 1 11.5001H14C14.2766 11.5001 14.5 11.2767 14.5 11.0001V2.00012C14.5 1.72356 14.2766 1.50012 14 1.50012ZM9.82812 9.50012H9.14219C9.07656 9.50012 9.02344 9.44856 9.01875 9.38293C8.95938 8.59231 8.3 7.96887 7.5 7.96887C6.7 7.96887 6.04062 8.59387 5.98125 9.38293C5.97656 9.44856 5.92344 9.50012 5.85781 9.50012H5.17188C5.15492 9.50014 5.13814 9.49672 5.12255 9.49005C5.10696 9.48338 5.09289 9.47361 5.0812 9.46133C5.06951 9.44905 5.06044 9.43452 5.05453 9.41863C5.04863 9.40273 5.04603 9.38581 5.04688 9.36887C5.09062 8.53606 5.54688 7.81106 6.2125 7.39856C5.91897 7.07589 5.75677 6.65508 5.75781 6.21887C5.75781 5.25168 6.5375 4.46887 7.49844 4.46887C8.45937 4.46887 9.23906 5.25168 9.23906 6.21887C9.23906 6.67356 9.06719 7.08606 8.78438 7.39856C9.45156 7.81262 9.90625 8.53606 9.95 9.36887C9.95625 9.44075 9.9 9.50012 9.82812 9.50012ZM7.5 5.40637C7.05469 5.40637 6.69219 5.77043 6.69219 6.21887C6.69219 6.66731 7.05469 7.03137 7.5 7.03137C7.94531 7.03137 8.30781 6.66731 8.30781 6.21887C8.30781 5.77043 7.94531 5.40637 7.5 5.40637Z" fill="currentColor" />
      </svg>
    </ContactCustomersSvgWrapper>
  );
};
