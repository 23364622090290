import React, { FC, memo, useEffect } from 'react';
import { useParams } from 'react-router';
import Spinner from 'antd/es/spin';

import { ShipmentDrawer } from 'shipment-operations/view/pages/Shipment/components';

import {
  Wrap,
  ContentWrap,
  SpinnerWrap,
} from './CustomerAccounting.styled';
import { TradeReferences } from './components';

interface CustomerAccountingComponentProps {
  isLoading: boolean;
  initData: (customerId: string) => void;
}

const CustomerAccountingComponent: FC<CustomerAccountingComponentProps> = ({
  isLoading,
  initData,
}) => {
  const { customerId = '' } = useParams();

  useEffect(() => {
    initData(customerId);
  }, []);

  return (
    <Wrap>
      {isLoading ? (
        <SpinnerWrap>
          <Spinner size="large" />
        </SpinnerWrap>
      ) : (
        <ContentWrap>
          <TradeReferences />
        </ContentWrap>
      )}

      <ShipmentDrawer />
    </Wrap>
  );
};

const CustomerAccountingComponentCached = memo(CustomerAccountingComponent);

export { CustomerAccountingComponentCached as CustomerAccountingComponent };
