import React, { FC } from 'react';

import { R } from 'shipment-operations/repository';
import { useAppSelector } from 'app-wrapper/hooks';
import { IGroupedChargesDTM, ChargeViewDtm } from 'shipment-operations/models/dtm';

import { ContainersTableComponent } from './ContainersTable.component';

interface IContainersTableProps {
  dataSource: ChargeViewDtm[]
  innerTableData: IGroupedChargesDTM
  subTitle?: string
}

const ContainersTableContainer: FC<IContainersTableProps> = ({ dataSource, innerTableData, subTitle }) => {
  const isPercentage = useAppSelector(R.selectors.shipmentTransportationCharges.getIsPercentage);

  return (
    <ContainersTableComponent
      dataSource={dataSource}
      innerTableData={innerTableData}
      isPercentage={isPercentage}
      subTitle={subTitle}
    />
  );
};

export { ContainersTableContainer as ContainersTable };
