import React from 'react';

import { R } from 'shipment-operations/repository';
import { useAppSelector } from 'app-wrapper/hooks';

import { ServicesComponent } from './Services.component';

const ServicesContainer = () => {
  const data = useAppSelector(R.selectors.shipmentTransportationCharges.groupedServicesContainers);
  const totalData = useAppSelector(R.selectors.shipmentTransportationCharges.getServicesDataTotal);
  const containers = useAppSelector(R.selectors.shipmentTransportationCharges.getServicesContainersTable);
  const tableData = useAppSelector(R.selectors.shipmentTransportationCharges.getServicesDataForTable);

  return (
    <ServicesComponent
      innerData={data}
      containers={containers}
      tableData={tableData}
      totalData={totalData}
    />
  );
};

export { ServicesContainer as Services };
