import React from 'react';
import { useSelector } from 'react-redux';

import { R } from 'user-management/repository';

import { GettingStartedComponent } from './GettingStarted.component';

const GettingStartedContainer = () => {
  const organization = useSelector(R.selectors.userOrganizationData.getUserOrganization);

  return (
    <GettingStartedComponent status={organization?.status} />
  );
};

export { GettingStartedContainer as GettingStarted };
