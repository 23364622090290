import moment from 'moment';

import { apiWorker } from 'app-wrapper/repository/utilsServices';
import { FullCreditNoteDTM } from 'shipment-operations/models/dtm';
import { ContainerTypesConst } from 'shipment-operations/constants';
import { GetCreditNoteContract } from 'shipment-operations/models/contracts';

const TIME_FORMAT = 'D MMM YYYY';

export class CreditNoteService {
  public getCreditNote = async (shipmentId: string, creditNoteId: string, category: string) => {
    let result: FullCreditNoteDTM | null;

    try {
      const res = await apiWorker.requestGet<GetCreditNoteContract>(`/billing-service/api/v1/shipments/${shipmentId}/${category}/adjustments/${creditNoteId}`);
      const parsedRes = res.data;

      result = FullCreditNoteDTM.fromPlain({
        id: parsedRes.id,
        number: parsedRes.number,
        createdAt: moment(parsedRes.createdAt).format(TIME_FORMAT),
        createdBy: parsedRes.createdBy,
        reason: parsedRes.reason.toLowerCase(),
        amount: parsedRes.amount,
        description: parsedRes.description,
        invoiceId: parsedRes.invoiceId,
        invoiceNumber: parsedRes.invoiceNumber,
        items: parsedRes.items.map((item) => ({
          amount: item.amount,
          costPerUnit: item.costPerUnit,
          id: item.id,
          numberOfUnits: item.numberOfUnits,
          invoiceItem: {
            id: item.id,
            charge: {
              active: item.invoiceItem.charge.active,
              id: item.invoiceItem.charge.id,
              additional: item.invoiceItem.charge.additional,
              status: item.invoiceItem.charge.status,
              createdAt: item.invoiceItem.charge.createdAt,
              createdBy: item.invoiceItem.charge.createdBy,
              buyCostPerUnit: item.invoiceItem.charge.buyCostPerUnit,
              buyTotalCost: item.invoiceItem.charge.buyTotalCost,
              invoiced: item.invoiceItem.charge.invoiced,
              balance: item.invoiceItem.charge.balance,
              budget: {
                balance: item.invoiceItem.charge.budget.balance,
                costPerUnit: item.invoiceItem.charge.budget.costPerUnit,
                numberOfUnits: item.invoiceItem.charge.budget.numberOfUnits,
                totalCost: item.invoiceItem.charge.budget.totalCost,
              },
              designation: item.invoiceItem.charge.designation,
              priceBy: item.invoiceItem.charge.priceBy,
              measureBy: item.invoiceItem.charge.measureBy,
              chargeCode: {
                code: item.invoiceItem.charge.chargeCode.code,
                description: item.invoiceItem.charge.chargeCode.code === 'MSC' ? (item.invoiceItem.charge.metadata?.originalDescription?.toLowerCase() || item.invoiceItem.charge.chargeCode.description) : item.invoiceItem.charge.chargeCode.description,
                mode: item.invoiceItem.charge.chargeCode.mode,
                type: item.invoiceItem.charge.chargeCode.type,
                subType: item.invoiceItem.charge.chargeCode.subType,
                occurrence: item.invoiceItem.charge.chargeCode.occurrence,
              },
              applianceRange: {
                minValue: item.invoiceItem.charge.applianceRange?.minValue,
                maxValue: item.invoiceItem.charge.applianceRange?.maxValue,
              },
              subjectTo: item.invoiceItem.charge.subjectTo,
              applied: item.invoiceItem.charge.applied,
              currency: item.invoiceItem.charge.currency,
              costPerUnit: item.invoiceItem.charge.costPerUnit,
              numberOfUnits: item.invoiceItem.charge.numberOfUnits,
              totalCost: item.invoiceItem.charge.totalCost,
              container: {
                id: item.invoiceItem.charge.container?.id,
                type: ContainerTypesConst[item.invoiceItem.charge.container?.type as keyof typeof ContainerTypesConst],
                number: item.invoiceItem.charge.container?.number,
              },
              contract: item.invoiceItem.charge.contract && {
                id: item.invoiceItem.charge.contract.id,
                name: item.invoiceItem.charge.contract.name,
                number: item.invoiceItem.charge.contract.number,
                scac: item.invoiceItem.charge.contract.scac,
              },
              transportationIds: item.invoiceItem.charge.transportationIds,
              rateId: item.invoiceItem.charge.rateId,
            },
            costPerUnit: item.costPerUnit,
            numberOfUnits: item.numberOfUnits,
            amount: item.amount,
            invoiced: item.invoiced || 0,
            adjustment: item.invoiced,
          },
        })),
      });
    } catch (e) {
      throw new Error('Something wrong, please try again');
    }

    return result;
  }
}
