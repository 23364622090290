import styled from 'styled-components';

import themesColors from 'app-wrapper/view/themes/themesColors';

export const MenuTriggerButton = styled.button`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 40px;
  padding: 0 16px;
  
  border: none;
  border-top: 1px solid ${themesColors.neutralBranding3};
  background: transparent;
  
  color: ${themesColors.darkGrayMiddle};

  cursor: pointer;
`;
