import React, { FC } from 'react';
import { IRFQItemDetailComponentProps, RFQItemDetailComponent } from './RFQItemDetail.component';

export const RFQItemDetail: FC<IRFQItemDetailComponentProps> = ({
  routing,
  breakdown,
  accessorials,
  paymentTermsOrigin,
  paymentTermsDestination,
  idIndex,
}) => (
  <RFQItemDetailComponent
    routing={routing}
    breakdown={breakdown}
    accessorials={accessorials}
    paymentTermsOrigin={paymentTermsOrigin}
    paymentTermsDestination={paymentTermsDestination}
    idIndex={idIndex}
  />
);
