import styled from 'styled-components';
import {
  divFlex, EmptyScrollbar, MainContentSidebar, responsive,
} from 'app-wrapper/view/themes/mixin';

// Content

export const HistoryQuotesComponentDiv2 = styled.div.attrs({
  'data-class': 'HistoryQuotesComponentDiv',
})`
  ${divFlex}
  height: 100%;
  width: 100%;
`;

export const HistoryQuotesComponentDiv = styled(HistoryQuotesComponentDiv2)`
.ant-tabs-nav-operations {
  display: none;
}
`;

export const HistoryQuotesComponentFormDiv = styled(MainContentSidebar)`
  .HistoryQuotesComponentForm {
    width: 100%;
    .FreightQuoteFormRequest__Row__col_TabsController {
      .FreightQuoteFormRequest__Row:nth-child(2) {
        display: none;
      }
    }
  }
`;

export const HistoryQuotesComponentQuotesDivWrapper = styled.div.attrs({
  'data-class': 'HistoryQuotesComponentQuotesDivWrapper',
})`
  ${divFlex}
  width: 70%;
  min-width: 548px;
  ${responsive.for1768Min`
    min-width: calc(100% - 480px + 24px);
  `}
  ${responsive.forMediumDesktopMax`
    min-width: 518px;
  `}
  ${responsive.forMediumMediaDesktopMax`
    min-width: 508px;
  `}
  background-color: ${({ theme }) => theme.themesColors.lightGrayBorder};
`;

export const HistoryQuotesComponentQuotesDiv = styled.div.attrs({
  'data-class': 'HistoryQuotesComponentQuotesDiv',
})`
  ${divFlex}
  width: 100%;
  flex-direction: column;
  background-color: ${({ theme }) => theme.themesColors.lightGrayBorder};
  padding-left: 24px;
  padding-right: 24px;
  min-width: calc(548px + 24px + 24px);
  max-width: calc(928px + 24px + 24px);
  ${responsive.forMediumDesktopMax`
    min-width: calc(518px + 24px + 24px);
  `}
  ${responsive.forMediumMediaDesktopMax`
    min-width: calc(508px + 24px + 24px);
  `}
`;

export const HistoryQuotesComponentHeader = styled.div`
  ${divFlex}
  width: 100%;
  height: ${({ theme }) => theme.freightQuotePage.height.filterHeader};
`;

export const HistoryQuotesComponentItemInfiniteScrollSpin = styled.div`
  ${divFlex}
  align-items: center;
  justify-content: center;
`;

export const HistoryQuotesComponentHeaderFound = styled.div`
  ${divFlex}
  color: ${({ theme }) => theme.themesColors.middleGraySecondary};
  align-items: center;
  font-size: 12px;
  padding-top: 5px;
`;

export const HistoryQuotesComponentQuotesItemsDiv = styled.div.attrs({
  'data-class': 'HistoryQuotesComponentQuotesItemsDiv',
})`
  ${divFlex}
  width: 100%;
  height: 100%;
  flex-direction: column;
  .infinite-scroll-component {
    ${EmptyScrollbar}
  }
`;
