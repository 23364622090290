import styled from 'styled-components';

export const Information = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  border-radius: 3px;
  padding: 16px;
  width: 100%;
  background-color: ${({ theme }) => theme?.themesColors?.neutralBranding1};
  border: 1px solid ${({ theme }) => theme?.themesColors?.neutralBranding4};
`;

export const Title = styled.span`
  margin-bottom: 8px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Text = styled.span`
  font-size: 14px;
  line-height: 22px;
`;

export const TextBold = styled(Text)`
  margin-right: 4px;
  font-weight: 500;
`;
