import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';

import {
  ShipmentContainersFormCardListComponent,
} from './ShipmentContainersFormCardList.component';

export const ShipmentContainersFormCardListContainer: FC = () => {
  const containers = useSelector(R.selectors.shipmentContainers.getContainerList);
  const selectedContainer = useSelector(R.selectors.shipmentContainers.getSelectedContainer);
  const cargoList = useSelector(R.selectors.shipmentContainers.getCargoList);
  const changedCargoList = useSelector(R.selectors.shipmentContainers.getChangedCargoList);
  const isInDraft = useSelector(R.selectors.shipmentContainers.isContainerChanged);
  const permissions = useSelector(R.selectors.shipment.getContainersPermissions);

  return (
    <ShipmentContainersFormCardListComponent
      containers={containers}
      selectedContainer={selectedContainer}
      cargoList={cargoList}
      isInDraft={isInDraft}
      changedCargoList={changedCargoList}
      containersAvailability={permissions.containersAvailability}
    />
  );
};

export {
  ShipmentContainersFormCardListContainer as ShipmentContainersFormCardList,
};
