import { ENotificationStatus } from 'app-wrapper/constants';
import { UC } from 'app-wrapper/controllers/index';
import { ShipmentChargeChangeDTM, ContainerWithChangeChargesDTM } from 'app-wrapper/models/dtm';
import { R } from 'app-wrapper/repository';
import { ETasksType, ChargeCodePriceBy } from 'shipment-operations/constants';
import { R as shipmentR } from 'shipment-operations/repository';
import { DrawersUseCase } from 'app-wrapper/usecases/Drawers.useCase';
import { BaseController, controller } from 'proto/BaseController';

@controller
export class ShipmentCostChangesDrawerController extends BaseController {
  public init = async (taskId: string) => {
    this.mobxStore.shipmentCostChangesDrawer.setLoading(true);
    const metadata = await R.services.CommandCenter.getShipmentCostChangesTaskMetadataById(taskId);

    if (!metadata) {
      this.closeDrawer();
    }

    if (metadata) {
      const groupedContainersWithCharges = this.groupChangeChargesByContainer(metadata.chargeChanges);

      this.mobxStore.shipmentCostChangesDrawer.setContainers(groupedContainersWithCharges);
      this.mobxStore.shipmentCostChangesDrawer.setBOLCharges(metadata.chargeChanges.filter(({ priceBy }) => priceBy === ChargeCodePriceBy.BOL));
    }

    this.mobxStore.shipmentCostChangesDrawer.setCostChangesMetadata(metadata);

    if (metadata?.shipmentId) {
      const shipment = await shipmentR.services.shipment.getShipmentShortById(+metadata.shipmentId);

      this.mobxStore.shipmentCostChangesDrawer.setShipment(shipment);
    }

    this.mobxStore.shipmentCostChangesDrawer.setLoading(false);
  };

  public acceptShipmentCostChanges = async (taskId: string) => {
    const { shipment } = this.mobxStore.shipmentCostChangesDrawer.state;

    if (!shipment) {
      return;
    }

    this.mobxStore.shipmentCostChangesDrawer.setLoading(true);

    const commandCenterState = R.selectors.commandCenter.getCommandCenter(this.store.getState()).currentState;
    const commandCenterTasks = [
      ...commandCenterState.content,
      ...(commandCenterState.contentLater || []),
      ...(commandCenterState.contentToday || []),
      ...(commandCenterState.contentThisWeek || []),
      ...(commandCenterState.contentOverdue || []),
    ];
    const overviewTasks = R.selectors.overview.getTasks(this.store.getState());
    const task = [...commandCenterTasks, ...overviewTasks].find(({ metadata, id }) => metadata && metadata.shipmentId === String(shipment.id) && id === taskId);

    await R.services.CommandCenter.putTask({
      ...task,
      status: ENotificationStatus.DONE,
    });

    UC.CommandCenter.removeTaskFromTheStore(String(shipment.id), ETasksType.CONFIRM_SHIPMENT_COST_CHANGE_TASK, taskId);
    UC.overview.removeTaskFromDashboard(String(shipment.id), ETasksType.CONFIRM_SHIPMENT_COST_CHANGE_TASK, taskId);

    this.mobxStore.shipmentCostChangesDrawer.setLoading(false);
    this.closeDrawer();
  };

  public closeDrawer = () => {
    this.mobxStore.shipmentCostChangesDrawer.setLoading(false);
    new DrawersUseCase(this).closeDrawer();
  };

  private groupChangeChargesByContainer = (charges: ShipmentChargeChangeDTM[]): ContainerWithChangeChargesDTM[] => {
    const containersWithCharges: ContainerWithChangeChargesDTM[] = [];

    charges
      .filter(({ priceBy }) => priceBy === ChargeCodePriceBy.CONTAINER)
      .forEach((charge) => {
        if (charge.container) {
          const { container } = charge;

          const targetContainer = containersWithCharges.find(({ id }) => container?.id === id);

          if (targetContainer) {
            targetContainer.charges.push(charge);
          } else {
            containersWithCharges.push(ContainerWithChangeChargesDTM.fromPlain({
              key: container?.id,
              id: container?.id,
              type: container?.type,
              number: container?.number,
              charges: [charge],
            }));
          }
        }
      });

    return containersWithCharges;
  }
}
