export const chargeCodeType = {
  CHARGE: 'CHARGE',
  SURCHARGE: 'SURCHARGE',
  FEE: 'FEE',
} as const;

export const chargeCodeCode = {
  PKP: 'PKP',
  FRT: 'FRT',
  DLV: 'DLV',
  MSC: 'MSC',
} as const;

export const chargeCodeDesignation = {
  ORIGIN: 'ORIGIN',
  DESTINATION: 'DESTINATION',
  FREIGHT: 'FREIGHT',
} as const;

export const chargeCodePriceBy = {
  CONTAINER: 'CONTAINER',
  BOL: 'BOL',
} as const;

export const chargeCodeMeasureBy = {
  CONTAINER_TYPE: 'CONTAINER_TYPE',
  WM: 'WM',
  KG_LB: 'KG_LB',
  DAY: 'DAY',
  CBM_CF: 'CBM_CF',
  HOUR: 'HOUR',
  KM_MILE: 'KM_MILE',
  FLAT: 'FLAT',
} as const;

export const chargeCodeMode = {
  ROAD: 'ROAD',
  OCEAN: 'OCEAN',
  AIR: 'AIR',
  ALL: 'ALL',
} as const;

export const chargeCodeOccurrence = {
  MANDATORY: 'MANDATORY',
  ADDITIONAL: 'ADDITIONAL',
  CONDITIONAL: 'CONDITIONAL',
} as const;

export const chargeCodeSubType = {
  ACCESSORIAL: 'ACCESSORIAL',
  EXCEPTION: 'EXCEPTION',
  FREE_TIME: 'FREE_TIME',
} as const;

export const chargeCodeSubjectTo = {
  APPLICABLE: 'APPLICABLE',
  INCLUDED: 'INCLUDED',
} as const;

export const FRT = 'FRT';

export const addRatesCodeFilter = ['SURCHARGE', 'CHARGE'];
