import React, { useCallback, FC } from 'react';
import { useTranslation } from 'react-i18next';

import { CargoWiseDocumentTypes } from 'shipment-operations/constants';

import {
  Wrapper,
  Title,
  Select,
  Subtitle,
} from './AdditionalDocumentUpload.styled';
import { Footer, DocumentUpload } from './components';

interface AdditionalDocumentUploadComponentProps {
  additionalType: string;
  setAdditionalType: (type: string) => void;
}

export const AdditionalDocumentUploadComponent: FC<AdditionalDocumentUploadComponentProps> = ({
  additionalType,
  setAdditionalType,
}) => {
  const { t } = useTranslation();

  const handleClear = useCallback(() => {
    setAdditionalType('');
  }, [setAdditionalType]);

  return (
    <Wrapper>
      <Title>
        {t('Additional Documents')}
      </Title>
      <Subtitle>
        {t('Document Type')}
      </Subtitle>
      <Select
        optionsData={CargoWiseDocumentTypes}
        allowClear
        value={additionalType}
        onChange={(value) => setAdditionalType(value)}
        placeholder={t('SelectDocumentType')}
        showSearch
        onClear={handleClear}
      />
      <Subtitle>
        {t('Document')}
      </Subtitle>
      <DocumentUpload />
      <Footer />
    </Wrapper>
  );
};
