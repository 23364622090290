import styled from 'styled-components';
import DatePicker from 'antd/es/date-picker';

import { divFlex, responsive } from 'app-wrapper/view/themes/mixin';
import { Button } from 'app-wrapper/view/components';

export const Wrapper = styled.div.attrs({
  'data-class': 'ModalRateDateComponent_Wrapper',
})`
  ${divFlex}
  position: sticky;

  z-index: 11;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  background-color: white;
  box-shadow: 0 9px 28px 8px rgba(0, 0, 0, 0.05), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12);

  div[data-testid="modalHeader"],
  div[data-testid="modalFooter"] {
    display: none;
  }
`;

export const ModalRateContent = styled.div.attrs({
  'data-class': 'ModalRateDateComponent_ModalRateContent',
})`
  ${divFlex}
  flex-direction: column;

  padding: 32px 32px 0 32px;
`;

export const ModalRateHeader = styled.div.attrs({
  'data-class': 'ModalRateDateComponent_ModalRateHeader',
})`
  ${divFlex}

  color: ${({ theme }) => theme?.themesColors?.neutralBranding10};
  font-size: 16px;
  font-weight: 500;
`;

export const ModalRateTextInfoWrap = styled.div.attrs({
  'data-class': 'ModalRateDateComponent_ModalRateTextInfoWrap',
})`
  ${divFlex}

  margin-top: 8px;
  border: 1px solid ${({ theme }) => theme?.themesColors?.primaryBranding3};
  background-color: ${({ theme }) => theme?.themesColors?.primaryBranding1};
  border-radius: 3px;
  padding: 9px 16px;
`;

export const ModalRateTextInfoIcon = styled.div.attrs({
  'data-class': 'ModalRateDateComponent_ModalRateTextInfoIcon',
})`
  ${divFlex}
  justify-content: center;
  align-items: center;
`;

export const ModalRateTextInfo = styled.div.attrs({
  'data-class': 'ModalRateDateComponent_ModalRateTextInfo',
})`
  ${divFlex}

  color: ${({ theme }) => theme?.themesColors?.neutralBranding10};
  font-size: 14px;
  font-weight: 400;
  margin-left: 10px;
`;

export const ModalRateDate = styled.div.attrs({
  'data-class': 'ModalRateDateComponent_ModalRateDate',
})`
  ${divFlex}
  flex-direction: column;
  margin-top: 8px;
`;

export const ModalRateDateTitle = styled.div.attrs({
  'data-class': 'ModalRateDateComponent_ModalRateDateTitle',
})`
  ${divFlex}

  color: ${({ theme }) => theme?.themesColors?.neutralBranding10};
  font-size: 12px;
  font-weight: 400;
`;

export const ModalRateDateSelect = styled.div.attrs({
  'data-class': 'ModalRateDateComponent_ModalRateDateSelect',
})`
  ${divFlex}

  color: ${({ theme }) => theme?.themesColors?.neutralBranding10};
  font-size: 12px;
  font-weight: 400;
  margin-top: 4px;
`;

export const ModalRateDateDatePicker = styled(DatePicker).attrs((props: {
  isError: boolean
}) => ({
  isError: props.isError,
  'data-class': 'ModalRateDateComponent_ModalRateDateDatePicker',
}))`
  width: 100%;
  min-height: 32px;

  ${responsive.forFullMedia`
    min-height: 40px;
  `}

  ${({ isError, theme }) => isError && `border-color: ${theme.themesColors.characterBrandingDanger} !important;`};
  ${({ isError }) => isError && 'z-index: 2;'};

  input {
    font-size: 12px !important;
  }
`;

export const ModalRateFooter = styled.div.attrs({
  'data-class': 'ModalRateDateComponent_ModalRateFooter',
})`
  ${divFlex}

  color: ${({ theme }) => theme?.themesColors?.neutralBranding10};
  font-size: 12px;
  font-weight: 400;
  margin-top: 24px;
  justify-content: end;
`;

export const ModalRateCancelBtn = styled(Button).attrs({
  'data-class': 'ModalRateDateComponent_ModalRateCancelBtn',
})`
  ${divFlex}
  align-items: center;
  justify-content: center;

  background-color: ${({ theme }) => theme.themesColors.neutralBranding1};
  height: 32px;
  border: 1px solid ${({ theme }) => theme.themesColors.neutralBranding5};
  border-radius: 3px;
  text-transform: capitalize;

  span {
    color: ${({ theme }) => theme.themesColors.characterBrandingTitle85};
    font-size: 14px;
    font-weight: 400;
    text-shadow: none;
  }
`;

export const ModalRateSaveBtn = styled(Button).attrs({
  'data-class': 'ModalRateDateComponent_ModalRateSaveBtn',
})`
  ${divFlex}
  align-items: center;
  justify-content: center;

  background-color: ${({ theme }) => theme.themesColors.neutralBranding8};
  height: 32px;
  border: 1px solid ${({ theme }) => theme.themesColors.neutral5};
  border-radius: 3px;
  text-transform: capitalize;
  margin-left: 8px;

  span {
    color: ${({ theme }) => theme.themesColors.neutralBranding1};
    font-size: 14px;
    font-weight: 400;
    text-shadow: none;
  }
`;
