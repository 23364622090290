import styled from 'styled-components';
import Typography from 'antd/es/typography';
import { InfoCircleOutlined } from '@ant-design/icons';

import { DatePicker } from 'app-wrapper/view/components';

export const Section = styled.div`
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  padding: 16px;
  background: #FFFFFF;
  border: 1px solid #EAEEF8;
  border-radius: 3px;
`;

export const Row = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 16px;
`;

export const SectionTitle = styled(Typography)`
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #202C3C;
`;

export const DatePickerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const DatePickerLabel = styled(Typography)`
  min-width: fit-content;
  margin-right: 8px;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
`;

export const DatePickerStyled = styled(DatePicker)`
  width: 177px;
  min-width: 177px;

  input {
    font-size: 12px;
    line-height: 20px;
  }
`;

export const HeaderContent = styled.div`
  display: grid;
  gap: 4px;
  grid-template-columns: 110px 200px 200px 535px;
  padding: 8px 0;
  margin-top: 8px;
  border-top: 1px solid #EAEEF8;
`;

export const HeaderText = styled(Typography)`
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ContentRow = styled.div`
  display: grid;
  gap: 4px;
  grid-template-columns: 110px 200px 200px 535px;
  padding: 8px 0;
  border-top: 1px solid #EAEEF8;
`;

export const Warning = styled.div`
  margin-top: 8px;
  display: flex;
  margin-bottom: 8px;
  width: 100%;
  padding: 8px;
  flex-direction: row;
  align-items: center;
  background-color: #FFFBE6;
  border: 1px solid #FFE58F;
  border-radius: 2px;
`;

export const WarningIcon = styled(InfoCircleOutlined)`
  margin-right: 8px;
  color: #FAAD14;
  font-size: 16px;
`;

export const WarningText = styled(Typography)`
  margin-top: 2px;
  font-size: 13px;
  line-height: 22px;
`;
