import i18n from 'i18next';

import { EAccountDrawerContent } from './AccountDrawerContent.enum';

export const AccountDrawerContentNamesList = {
  [EAccountDrawerContent.USER_EDIT_PROFILE]: i18n.t('Edit Profile'),
  [EAccountDrawerContent.USER_EDIT_PROFILE_ORGANIZATION]: i18n.t('editOrganizationProfile'),
  [EAccountDrawerContent.USER_ACCOUNT_DEPARTMENT_EDIT]: i18n.t('Customer Onboarding'),
  [EAccountDrawerContent.USER_INVITE]: i18n.t('Invite User'),
  [EAccountDrawerContent.USER_PASSWORD_CHANGE]: i18n.t('Change Password'),
  [EAccountDrawerContent.USER_CUSTOMER_ADD_INTERNAL]: i18n.t('CreateCustomerOrganization'),
  [EAccountDrawerContent.USER_ADDRESSES_AND_CONTACTS_ADD_ADDRESS]: i18n.t('AddAddress'),
  [EAccountDrawerContent.USER_ADDRESSES_AND_CONTACTS_EDIT_ADDRESS]: i18n.t('Edit Address'),
  [EAccountDrawerContent.USER_ADDRESSES_AND_CONTACTS_SECOND]: i18n.t('Edit Address'),
  [EAccountDrawerContent.USER_AAC_CONTACT_ADD_CONTACT_PERSON]: i18n.t('AddContactPerson'),
  [EAccountDrawerContent.USER_AAC_CONTACT_EDIT_CONTACT_PERSON]: i18n.t('Edit Contact Person'),
  [EAccountDrawerContent.CONTACT_BOOK_ADD_CONTACT]: i18n.t('Add Company'),
  [EAccountDrawerContent.CONTACT_BOOK_BY_ID_ADD_ADDRESS]: i18n.t('AddAddress'),
  [EAccountDrawerContent.CONTACT_BOOK_BY_ID_EDIT_COMPANY]: i18n.t('Edit Company Details'),
  [EAccountDrawerContent.CONTACT_BOOK_BY_ID_EDIT_ADDRESS]: i18n.t('Edit Address'),
  [EAccountDrawerContent.CONTACT_BOOK_BY_ID_ADD_CONTACT]: i18n.t('AddContactPerson'),
  [EAccountDrawerContent.CONTACT_BOOK_BY_ID_EDIT_CONTACT]: i18n.t('Edit Contact Person'),
  [EAccountDrawerContent.USER_MANAGEMENT_EDIT]: i18n.t('editUser'),
  [EAccountDrawerContent.BOOKING_ADD_COMPANY]: i18n.t('Add Company'),
  [EAccountDrawerContent.SHIPMENT_PARTY_ADD_COMPANY]: i18n.t('Add Company'),
  [EAccountDrawerContent.SHIPMENT_PARTY_ADD_ADDRESS]: i18n.t('Add Address'),
  [EAccountDrawerContent.SHIPMENT_PARTY_ADD_CONTACT]: i18n.t('Add Contact'),
  [EAccountDrawerContent.RFQ_ADD_ADDITIONAL_SERVICES]: i18n.t('Add Service(s)'),
};
