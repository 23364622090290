import styled from 'styled-components';
import {
  MainContent, responsive,
} from 'app-wrapper/view/themes/mixin';
import {
  MainContentCollapse, MainContentRightWrapperFullCollapse, MainContentSidebarFullCollapse, divFlex,
} from 'app-wrapper/view/themes/mixin/main';
import { Button } from 'app-wrapper/view/components';

// Content

export const RCQByIdSecondStepSpinWrap = styled.div.attrs({
  'data-class-second': 'RCQByIdSecondStepSpinWrap',
})`
  ${divFlex}
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;
`;

export const RCQByIdSecondStepDiv = styled(MainContent).attrs({
  'data-class-second': 'RCQByIdSecondStepDiv',
})`
  width: calc(100% - 24px);
  padding-left: 24px;
`;

export const RCQByIdSecondStepWrap = styled.div.attrs({
  'data-class-second': 'RCQByIdSecondStepWrap',
})`

  padding-left: 24px;
`;

export const RCQByIdSecondStepFirst = styled(MainContentCollapse).attrs({
  'data-class-second': 'RCQByIdSecondStepFirst',
})`


.FreightQuoteFormRequest__isCollapsed {
    min-width: calc(762px - 24px);

    .FreightQuoteFormRequest {
      &__Row {
        &__col {
          &_both_col {
            height: 95px;
          }
        }

        &__Commodity, &__Containers {
          &__content {
            &_title {
              margin-top: 8px;
            }
          }
        }
      }
    }
  }
`;

export const RCQByIdSecondStepSecond = styled(MainContent).attrs({
  'data-class-second': 'RCQByIdSecondStepSecond',
})`

`;

export const RCQByIdSecondStepSecondHeaderFirst = styled.div.attrs((props: {
  iconColor: string,
}) => ({
  iconColor: props.iconColor,
  'data-class': 'RCQByIdSecondStepSecondHeaderFirst',
}))`
  margin-left: 0px;
`;

export const RCQByIdSecondStepSecondHeaderSecond = styled.div.attrs((props: {
  iconColor: string,
}) => ({
  iconColor: props.iconColor,
  'data-class': 'RCQByIdSecondStepSecondHeaderSecond',
}))`
  margin-left: auto;
`;

export const RCQByIdSecondStepSecondHeaderThird = styled.div.attrs((props: {
  iconColor: string,
}) => ({
  iconColor: props.iconColor,
  'data-class': 'RCQByIdSecondStepSecondHeaderThird',
}))`
  margin-left: 8px;
`;

export const RCQByIdSecondStepSecondHeaderButton = styled(Button).attrs({
  'data-class': 'RCQByIdSecondStepSecondHeaderButton',
})`
`;

export const RCQByIdSecondStepSecondFooter = styled.div.attrs({
  'data-class': 'RCQByIdSecondStepSecondFooter',
})`
  ${divFlex}
  width: calc(100% + 22px);
  height: 64px;
  align-items: center;

  position: absolute;
  bottom: 0;
  right: -24px;
  padding: 8px 24px;
  z-index: 6;
  box-shadow: 0px -3px 10px 1px rgba(0,0,0,0.04);
  background-color: ${({ theme }) => theme.themesColors.neutralBranding1};
`;

export const RCQByIdSecondStepSecondFooterButton = styled(Button).attrs({
  'data-class': 'RCQByIdSecondStepSecondFooterButton',
})`

`;

export const RCQByIdSecondStepMainContentSidebarFullCollapse = styled(
  MainContentSidebarFullCollapse,
).attrs((props: {
  isCollapse: boolean,
}) => ({
  ...props,
  isCollapse: props.isCollapse,
  'data-class-second': 'RCQByIdSecondStepMainContentSidebarFullCollapse',
}))`
  .FreightQuoteFormRequest {
    &__Row {
      &__col {
        &_both_col {
          ${responsive.forFullMedia`
            ${({ isCollapse }: { isCollapse: boolean }) => !isCollapse && 'height: 235px;'}
          `}
        }
      }
    }
  }
  .FreightQuoteFormRequest {
    ${responsive.forFullMedia`
      width: 100%;
      max-width: calc(762px - 24px);
      min-width: 670px;
      ${({ isCollapse }: { isCollapse: boolean }) => !isCollapse && 'min-width: calc(390px - 24px);'}
  `}
    ${responsive.forFullMediaMax`
      width: 100%;
      max-width: calc(480px - 24px);
    `}
    input:disabled,
    .ant-select-disabled span {
      color: ${({ theme }) => theme.themesColors.secondaryDot45};
    }
    .ant-radio-button-wrapper-disabled span {
      color: ${({ theme }) => theme.themesColors.characterBrandingDisabled025};
    }
    .ant-picker-suffix {
      svg {
        color: ${({ theme }) => theme.themesColors.characterBrandingDisabled025};
      }
    }
    .TypeSwitchController.disabled-bg {
      .TypeSwitchController__text {
        color: ${({ theme }) => theme.themesColors.secondaryDot45};
      }
      .TypeSwitchController__text-inactive {
        color: ${({ theme }) => theme.themesColors.characterBrandingDisabled025};
      }
      .ant-switch {
        background-color: ${({ theme }) => theme.themesColors.characterBrandingDisabled025};
      }
    }
  }
  .FreightQuoteFormRequest.FreightQuoteFormRequest__isCollapsed {
      ${responsive.forFullMedia`
        min-width: 670px;
      `}
    }
`;

export const RCQByIdSecondStepMainContentRightWrapperFullCollapse = styled(
  MainContentRightWrapperFullCollapse,
).attrs({
  'data-class-second': 'RCQByIdSecondStepMainContentRightWrapperFullCollapse',
})`

`;

export const RCQByIdSecondStepWrapper = styled.div.attrs({
  'data-class': 'RCQByIdSecondStepWrapper',
})`
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 0;
  background-color: ${({ theme }) => theme.themesColors.lightGrayBorder};
`;
