import { R } from 'monetary/repository';
import { UC } from 'monetary/controllers';
import React, {
  FC, memo, useMemo,
} from 'react';
import { useSelector } from 'react-redux';

import {
  AddressBlockSelectComponent,
} from 'monetary/view/components/FreightQuoteFormRequest/AddressBlock/AddressBlockSelect.component';
import { useTranslation } from 'react-i18next';

export const AddressBlockOriginContainer: FC = memo(() => {
  const getOriginDoorAddress = useSelector(R.selectors.freightQuote.getOriginDoorAddress);

  const getCurrent = useSelector(R.selectors.freightQuote.getFreightQuoteCurrent);
  const getOrigin = useSelector(R.selectors.freightQuote.getFreightQuoteCurrentOrigin);
  const getErrors = useSelector(R.selectors.freightQuote.getFreightQuoteErrors);

  const getLocationPortStatus = useSelector(R.selectors.freightQuote.getFreightQuoteCurrentLocationPortStatus);

  const errors = useMemo(() => ({
    isPort: getErrors?.origin?.isPort,
    dateDoor: getErrors?.origin?.dateDoor,
    datePort: getErrors?.origin?.datePort,
    location: getErrors?.origin?.location,
  }), [getErrors?.origin]);

  const { t } = useTranslation();

  const notForbiddenOptions = useMemo(
    () => getOrigin?.locationValues || [],
    // Revert in next pr
    // () => getOrigin?.locationValues?.filter((value) => (getCurrent.incotermsTrade === EFreightIncotermsTrade.EXPORT ? !value.forbidden : true)) || [],
    [getOrigin?.locationValues, getCurrent.incotermsTrade],
  );

  const forbiddenOptions = useMemo(
    () => [],
    // Revert in next pr
    // () => getOrigin?.locationValues?.filter((value) => (getCurrent.incotermsTrade === EFreightIncotermsTrade.EXPORT ? value.forbidden : false)) || [],
    [getOrigin?.locationValues, getCurrent.incotermsTrade],
  );

  return (
    <AddressBlockSelectComponent
      dataClass="originAddressCode"
      isAllDisabled={getCurrent.isAllFieldDisabled}
      isIncludeRelatedPort={getCurrent?.form?.isIncludeRelatedPort}
      // origin
      options={notForbiddenOptions}
      disabledOptions={forbiddenOptions}
      origin={getOrigin}
      incotermsTrade={getCurrent.incotermsTrade}
      onChangeDoorAutocomplete={UC.FreightQuote.getRFQDoorAutocompleteOrigin}
      doorAutocompleteStatus={getCurrent.doorsAutocomplete?.origin?.status || ''}
      toPort={!!getOrigin?.isPort}
      onChangeAddress={UC.FreightQuote.onChangeOriginAddress}
      onFocusAddress={UC.FreightQuote.onFocusOriginAddress}
      onBlurAddress={UC.FreightQuote.onBlurOriginAddress}
      onClearAddress={UC.FreightQuote.onClearOriginAddress}
      getRFQPortAutocomplete={UC.FreightQuote.getRFQPortAutocompleteOrigin}
      locationPortStatus={getLocationPortStatus?.origin?.status || ''}
      onKeyPressAddress={UC.FreightQuote.onKeyPressOriginAddress}
      doorAutocompleteOptions={getOriginDoorAddress}
      // errors
      errors={errors}
      isSubmitVisited={getCurrent.isSubmitVisited}
      placeholder={`${t('OverviewSecondSectionsFormAddress')} *`}
    />
  );
});
