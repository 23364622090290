import {
  IGetExportCustomsResponse,
  IPostExportCustomsRequest,
  IPostExportCustomsResponse,
  IPutExportCustomsRequest,
  IPutExportCustomsResponse,
} from 'shipment-operations/models/contracts';
import { apiWorker } from 'app-wrapper/repository/utilsServices';
import { CustomsClearanceDTM } from 'shipment-operations/models/dtm';

export class ExportClearanceService {
  private formatUrl = (shipmentId?: string) => (
    `/shipment-service/api/v1/shipments/${shipmentId}/customs`
  );

  public getExportClearance = async (shipmentId: string) => {
    let exportClearance: CustomsClearanceDTM[] = [];

    try {
      const response = await apiWorker.requestGet<IGetExportCustomsResponse[]>(this.formatUrl(shipmentId));

      if (response.status === 204) {
        return exportClearance;
      }

      exportClearance = response.data.map((customs) => CustomsClearanceDTM.fromPlain({
        isCreated: true,
        type: customs?.entryNumber?.type,
        number: customs?.entryNumber?.number,
        selfCustomsClearance: customs.selfCustomsClearance,
        id: customs.id,
      }));
    } catch (e) {
      throw new Error('Export clearance service error while sending get request');
    }

    return exportClearance;
  };

  public putExportClearance = async (shipmentId: string, customsId: number, data: CustomsClearanceDTM) => {
    const exportClearanceRequestData: IPutExportCustomsRequest = {
      id: data.id,
      selfCustomsClearance: false,
      entryNumber: {
        type: data.type!,
        number: data.number!,
      },
    };

    let exportClearance: CustomsClearanceDTM | undefined;

    try {
      const response = await apiWorker.requestPut<IPutExportCustomsResponse>(`${this.formatUrl(shipmentId)}/${customsId}`, exportClearanceRequestData);

      const body = response.data;

      exportClearance = CustomsClearanceDTM.fromPlain({
        isCreated: true,
        type: body?.entryNumber?.type,
        number: body?.entryNumber?.number,
        id: body.id,
      });
    } catch (e) {
      throw new Error('Export clearance service error while sending put request');
    }

    return exportClearance;
  };

  public postExportClearance = async (shipmentId: string, data: CustomsClearanceDTM) => {
    const exportClearanceRequestData: IPostExportCustomsRequest = {
      selfCustomsClearance: false,
      entryNumber: {
        type: data.type!,
        number: data.number!,
      },
    };

    let exportClearance: CustomsClearanceDTM | undefined;
    try {
      const response = await apiWorker.requestPost<IPostExportCustomsResponse>(this.formatUrl(shipmentId), exportClearanceRequestData);

      const body = response.data;

      exportClearance = CustomsClearanceDTM.fromPlain({
        isCreated: true,
        type: body?.entryNumber?.type,
        number: body?.entryNumber?.number,
        id: body.id,
      });
    } catch (e) {
      throw new Error('Export clearance service error while sending post request');
    }

    return exportClearance;
  };
}
