import { useState } from 'react';

import { InvoiceDataTypeDTM, UpdatedCreditNoteItemDTM } from 'shipment-operations/models/dtm';
import { UC } from 'shipment-operations/controllers';

interface IColumns {
  title: string
  dataIndex: string
  key: string
  width: string
  editable?: boolean
}

const useTableEditable = (data: InvoiceDataTypeDTM[], defaultColumns: IColumns[]) => {
  const [dataSource, setDataSource] = useState(data);

  const handleSave = (row: InvoiceDataTypeDTM) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    setDataSource(newData);
    const preparedItem = UpdatedCreditNoteItemDTM.fromPlain({
      invoiceItem: {
        id: row.key,
      },
      costPerUnit: row.adjustment,
      numberOfUnits: 1,
      amount: row.adjustment,
    });
    UC.shipmentBillingInvoice.setUpdatedItem(preparedItem);
  };

  const columns = defaultColumns.map((col: IColumns) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: any) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });

  return {
    dataSource,
    columns,
  };
};

export { useTableEditable };
