import { ValidationErrorType } from 'app-wrapper/types';
import i18n from 'i18next';

import { CargoReferenceType } from 'shipment-operations/constants';
import { CargoReferenceDTM, CargoReferenceErrorsDTM } from 'shipment-operations/models/dtm';

export const validateReferences = (references: CargoReferenceDTM[]) => {
  const errors: CargoReferenceErrorsDTM[] = new Array(references.length).fill(CargoReferenceErrorsDTM.fromPlain({ type: undefined, value: undefined }));

  references.forEach((reference, i) => {
    if (!!reference.value && !reference.type) {
      const error = {
        type: ValidationErrorType.DEFAULT,
        message: i18n.t('shipmentContainerErrors.REFERENCE_TYPE_REQUIRED_MESSAGE'),
      };
      errors[i] = CargoReferenceErrorsDTM.fromPlain({ type: error });
    }

    if (!!reference.type && !reference.value) {
      const error = {
        type: ValidationErrorType.DEFAULT,
        message: i18n.t('shipmentContainerErrors.REFERENCE_VALUE_REQUIRED_MESSAGE'),
      };
      errors[i] = CargoReferenceErrorsDTM.fromPlain({ value: error });
    }
  });

  const poReferences = references.filter(({ type }) => (type === CargoReferenceType.PO_NUMBER));

  if (poReferences.length > 1) {
    references.forEach(({ type }, i) => {
      if (type !== CargoReferenceType.PO_NUMBER) {
        return;
      }

      const error = {
        type: ValidationErrorType.ALERT,
        message: i18n.t('shipmentContainerErrors.ONLY_ONE_PO_REFERENCE_ALLOWED_MESSAGE'),
      };
      errors[i] = CargoReferenceErrorsDTM.fromPlain({ type: error });
    });
  }

  return errors.length ? errors : undefined;
};
