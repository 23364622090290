import styled from 'styled-components';
import AntdTag from 'antd/es/tag';

import { FlexRow, FlexCol } from 'app-wrapper/view/guideline';

export const Item = styled(FlexRow)`
  width: 50%;
  align-items: flex-start;
`;

export const IconWrap = styled.div`
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.themesColors.primaryBranding1};
  width: 32px;
  height: 32px;
  margin-right: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: ${({ theme }) => theme.themesColors.primaryBranding5};
`;

export const ItemContentWrap = styled(FlexCol)`
  width: calc(100% - 48px);
`;

export const ItemTitle = styled.span`
  font-size: 12px;
  line-height: 20px;
  color: ${({ theme }) => theme.themesColors.characterSecondaryDot45};
`;

export const ItemList = styled.div`
  display: flex;
  row-gap: 4px;
  flex-wrap: wrap;
`;

export const Tag = styled(AntdTag)``;
