import styled from 'styled-components';

import { Input, Select } from 'app-wrapper/view/components';

export const AirVentFieldWrapper = styled.div.attrs({
  'data-class': 'AirVentFieldWrapper',
})`
  box-sizing: border-box;
  margin-left: 32px;
  height: 50px;
  
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
`;

export const AirVentLabel = styled.span.attrs({
  'data-class': 'AirVentLabel',
})`
  min-width: fit-content;
  margin-right: 8px;
`;

export const AirVentInput = styled(Input).attrs({
  'data-class': 'AirVentInput',
})`  
  width: 70px;
  height: 32px;
  min-height: 32px;
  margin-right: 4px;
  
  &.ant-input {
    font-size: 12px;
  }
`;

export const AirVentSelect = styled(Select).attrs({
  'data-class': 'AirVentSelect',
})`  
  width: 230px;
  min-height: 32px;
  height: 32px;
  
  &.ant-select {
    font-size: 12px;
  }
  
  &.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
    height: 32px;
  }
  
  &.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-item {
    line-height: 30px;
  }
  
  &.ant-select-single.ant-select-lg:not(.ant-select-customize-input):not(.ant-select-customize-input) .ant-select-selection-search-input {
    height: 30px;
  }
  
  &.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-placeholder {
    line-height: 32px;
  }
`;
