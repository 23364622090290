import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import { TFieldErrorDTM } from 'app-wrapper/models/dtm';
import { BaseUseCase } from 'app-wrapper/usecases/Base.useCase';
import { BaseController } from 'proto/BaseController';

export class InputUseCase extends BaseUseCase {
  value: string

  setValue: ActionCreatorWithPayload<string, string>

  setError: ActionCreatorWithPayload<TFieldErrorDTM, string>

  validate: () => void

  constructor(
    controller: BaseController,
    setValue: ActionCreatorWithPayload<string, string>,
    setError: ActionCreatorWithPayload<TFieldErrorDTM, string>,
    validate: () => void,
  ) {
    super(controller);
    this.setValue = setValue;
    this.setError = setError;
    this.validate = validate;
  }

  onChange(value: string) {
    const validValue = value.slice(0, 50);
    this.controller.dispatch(this.setValue(validValue));

    this.controller.dispatch(this.setError({
      isFocus: true,
      isBlur: false,
    }));

    this.validate();
  }

  onClear() {
    this.controller.dispatch(this.setValue(''));

    this.controller.dispatch(this.setError({
      isFocus: true,
      isBlur: false,
    }));

    this.validate();
  }

  public onBlur() {
    this.controller.dispatch(this.setError({
      isFocus: false,
      isBlur: true,
      isVisited: true,
    }));
    this.validate();
  }
}
