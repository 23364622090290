import React, {
  FC, memo, useCallback,
} from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import Skeleton from 'antd/es/skeleton';

import { OrganizationStatus } from 'user-management/view/components';
import { OrganizationDTM } from 'user-management/models/dtm';
import { EReviewAction } from 'user-management/constants';

import {
  UMCContentTableContentLink,
  UMCContentTableContentItem,
  UMCContentTableContentItemTop,
  UMCContentTableContentTitle,
  UMCContentTableHeader,
  UMCContentTableHeaderItem,
  UMCSpanEllipsis,
} from './PartnerContentTable.styled';

interface IPartnerContentTableProps {
  paginate: () => void;
  goToOrganization: (id: string) => void;
  isLoading: boolean;
  partners?: OrganizationDTM[];
  isEnd?: boolean;
}

const PartnerContentTable: FC<IPartnerContentTableProps> = ({
  paginate,
  goToOrganization,
  isLoading,
  partners,
  isEnd,
}) => {
  const { t } = useTranslation();

  const onClickCustomerInternalHandler = useCallback(
    (id: string) => () => {
      goToOrganization(id);
    },
    [goToOrganization],
  );

  return (
    <>
      <UMCContentTableHeader>
        <UMCContentTableHeaderItem
          width="20%"
        >
          {t('Company')}
        </UMCContentTableHeaderItem>
        <UMCContentTableHeaderItem
          width="20%"
        >
          {t('Location')}
        </UMCContentTableHeaderItem>
        <UMCContentTableHeaderItem
          width="20%"
        >
          {t('Phone')}
        </UMCContentTableHeaderItem>
        <UMCContentTableHeaderItem
          width="20%"
        >
          {t('Email')}
        </UMCContentTableHeaderItem>
        <UMCContentTableHeaderItem
          width="20%"
          marginLeft="0 !important"
          marginRight="8px"
        >
          {t('Status')}
        </UMCContentTableHeaderItem>
      </UMCContentTableHeader>

      <>
        <InfiniteScroll
          dataLength={Number(partners?.length || 0)}
          next={paginate}
          hasMore={!isEnd}
          loader={isLoading && (<Skeleton />)}
          scrollableTarget="Wrapper"
        >
          {partners?.map((item) => (
            <UMCContentTableContentItem key={item.customId}>
              <UMCContentTableContentItemTop>
                <UMCContentTableContentLink
                  onClick={onClickCustomerInternalHandler(`${item.id || ''}`)}
                >
                  <UMCSpanEllipsis>
                    {item.name}
                  </UMCSpanEllipsis>
                </UMCContentTableContentLink>
                <UMCContentTableContentTitle>
                  <UMCSpanEllipsis>
                    {item.getAddressCityCountry()}
                  </UMCSpanEllipsis>
                </UMCContentTableContentTitle>
                <UMCContentTableContentTitle>
                  <UMCSpanEllipsis>
                    {item?.phone || ''}
                  </UMCSpanEllipsis>
                </UMCContentTableContentTitle>
                <UMCContentTableContentTitle>
                  <UMCSpanEllipsis>
                    {item?.email || ''}
                  </UMCSpanEllipsis>
                </UMCContentTableContentTitle>
                <UMCContentTableContentTitle isCapitalize>
                  <UMCSpanEllipsis>
                    {item.status ? (
                      <OrganizationStatus
                        status={item.status}
                        isAdditionalInfoTooltip={item.review?.action === EReviewAction.REQUEST_ADDITIONAL_INFO}
                        tooltipName={item.review?.note}
                      />
                    ) : null}
                  </UMCSpanEllipsis>
                </UMCContentTableContentTitle>
              </UMCContentTableContentItemTop>
            </UMCContentTableContentItem>
          ))}
        </InfiniteScroll>
        {isLoading && (<Skeleton />)}
      </>
    </>
  );
};

const PartnerContentTableCache = memo(PartnerContentTable);

export { PartnerContentTableCache as PartnerContentTable };
