import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { R } from 'shipment-operations/repository';
import { IQuotaServiceByIdContentRoutesLegsLocationDTM } from 'monetary/models/dtm';

import { SBLRoutingInformationComponent } from './SBLRoutingInformation.component';

const EMPTY_CELL = '-';

const getLocationNameFromLocation = (location: IQuotaServiceByIdContentRoutesLegsLocationDTM): string => `${location.name}, ${location.country?.name}`;

const SBLRoutingInformationContainer = () => {
  const { t } = useTranslation();
  const lowestLeg = useSelector(R.selectors.billOfLadingCommon.getLowestSequenceLeg);
  const highestLeg = useSelector(R.selectors.billOfLadingCommon.getHighestSequenceLeg);
  const lowestSeaLeg = useSelector(R.selectors.billOfLadingCommon.getLowestSequenceSeaLeg);
  const highestSeaLeg = useSelector(R.selectors.billOfLadingCommon.getHighestSequenceSeaLeg);

  const items = useMemo(() => {
    const finalItems = [
      {
        title: t('Place of Receipt'),
        subtitle: EMPTY_CELL,
      },
      {
        title: t('Port of Load'),
        subtitle: EMPTY_CELL,
      },
      {
        title: t('Port of Discharge'),
        subtitle: EMPTY_CELL,
      },
      {
        title: t('Place of Delivery'),
        subtitle: EMPTY_CELL,
      },
    ];

    if (lowestLeg?.departureLocation) {
      finalItems[0].subtitle = getLocationNameFromLocation(lowestLeg?.departureLocation);
    }

    if (lowestSeaLeg?.departureLocation) {
      finalItems[1].subtitle = getLocationNameFromLocation(lowestSeaLeg?.departureLocation);
    }

    if (highestSeaLeg?.arrivalLocation) {
      finalItems[2].subtitle = getLocationNameFromLocation(highestSeaLeg?.arrivalLocation);
    }

    if (highestLeg?.arrivalLocation) {
      finalItems[3].subtitle = getLocationNameFromLocation(highestLeg?.arrivalLocation);
    }

    return finalItems;
  }, [lowestLeg, highestLeg, lowestSeaLeg, highestSeaLeg, t]);

  return <SBLRoutingInformationComponent items={items} />;
};

export { SBLRoutingInformationContainer as SBLRoutingInformation };
