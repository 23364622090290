import React, { useCallback, FC } from 'react';
import AntdButton from 'antd/es/button';
import AntdUpload from 'antd/es/upload';
import UploadOutlined from '@ant-design/icons/UploadOutlined';
import { useTranslation } from 'react-i18next';
import DeleteOutlined from '@ant-design/icons/DeleteOutlined';
import { RcFile, UploadProps } from 'antd/es/upload/interface';
import { addPrefix, showWarning } from 'app-wrapper/utils';
import { useParams } from 'react-router';
import { useAuthToken } from 'app-wrapper/hooks';
import { ContainerDocumentDTM } from 'shipment-operations/models/dtm';

const MAX_FILE_SIZE = 1024 * 1024 * 10; // 10MB
const { LIST_IGNORE } = AntdUpload;

interface IUploaderComponentProps {
  setDocument: (document: ContainerDocumentDTM | null) => void;
  document: ContainerDocumentDTM[];
  downloadDocument: (shipmentId: string) => void;
}

const UploaderComponent: FC<IUploaderComponentProps> = ({ setDocument, document, downloadDocument }) => {
  const { t } = useTranslation();
  const { shipmentId = '0' } = useParams();
  const authToken = useAuthToken();

  const setDocumentHandler = useCallback((value: ContainerDocumentDTM[]) => {
    setDocument(value[0] || null);
  }, [setDocument]);

  const handleBeforeUpload = useCallback((file: RcFile) => {
    const isAcceptableSize = file.size < MAX_FILE_SIZE;
    if (!isAcceptableSize) {
      showWarning({ message: t('File is too large'), duration: 4 });
    }
    return isAcceptableSize || LIST_IGNORE;
  }, []);

  const handleChange: UploadProps['onChange'] = useCallback(({ file }) => {
    if (file.status === 'removed') {
      setDocumentHandler([]);
      return;
    }

    if (file.response) {
      file.url = '/';
    }

    if (file.response && file.status === 'error') {
      file.error = new Error(file.response?.message);
      file.response = file.response.message ? file.response.message : {};
    }

    setDocumentHandler([ContainerDocumentDTM.fromPlain({
      name: file.name,
      url: file.url,
      uid: file.uid,
      response: file.response,
      status: file.status,
    })]);
  }, [setDocumentHandler]);

  const handlePreview: UploadProps['onPreview'] = useCallback((file) => {
    if (!file.status) {
      return;
    }

    downloadDocument(shipmentId);
  }, [downloadDocument]);

  const handleRemove: UploadProps['onRemove'] = useCallback(() => {
    setDocumentHandler([]);
  }, [setDocumentHandler]);

  const postUrl = addPrefix(`/shipment-service/api/v1/shipments/${shipmentId}/documents?type=OBC`);

  return (
    <AntdUpload
      maxCount={1}
      multiple={false}
      showUploadList={{ showRemoveIcon: true, removeIcon: <DeleteOutlined /> }}
      beforeUpload={handleBeforeUpload}
      action={postUrl}
      headers={{ Authorization: authToken }}
      fileList={document}
      onPreview={handlePreview}
      onChange={handleChange}
      onRemove={handleRemove}
    >
      <AntdButton
        icon={<UploadOutlined />}
        type="dashed"
        style={{ width: '500px' }}
      >
        {t('Upload document')}
      </AntdButton>
    </AntdUpload>
  );
};

export { UploaderComponent };
