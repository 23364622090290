import React, { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from 'app-wrapper/view/components';
import { Footer, FooterContainer } from 'app-wrapper/view/guideline';
import { hasAccess } from 'app-wrapper/utils';
import { PermissionAttributePolicy, PAYABLES } from 'shipment-operations/constants';

interface IPaymentFooterProps {
  isLoading?: boolean
  onClick: (paymentType: string) => void
  buttonName: string
  permissions?: PermissionAttributePolicy
  editPayablesPermissions?: PermissionAttributePolicy
  paymentType: string;
}

const PaymentFooter: FC<IPaymentFooterProps> = ({
  isLoading,
  onClick,
  buttonName,
  permissions,
  paymentType,
  editPayablesPermissions,
}) => {
  const { t } = useTranslation();

  const handleClick = useCallback(() => onClick(paymentType), [onClick, paymentType]);

  const hasWriteRights = useMemo(() => !!permissions && hasAccess(permissions, PermissionAttributePolicy.WRITE), [permissions]);
  const isAbleToEditPayables = useMemo(() => !!editPayablesPermissions && hasAccess(editPayablesPermissions, PermissionAttributePolicy.WRITE), [editPayablesPermissions]);

  if (paymentType === PAYABLES && !isAbleToEditPayables) {
    return null;
  }

  return (
    <Footer>
      <FooterContainer>
        {hasWriteRights ? (
          <Button
            htmlType="submit"
            loading={isLoading}
            onClick={handleClick}
          >
            {t(`${buttonName}`)}
          </Button>
        ) : null}
      </FooterContainer>
    </Footer>
  );
};

export { PaymentFooter };
