import styled from 'styled-components';

export const Label = styled.span.attrs({
  'data-class': 'Label',
})`
  border: 1px solid ${({ theme }) => theme.themesColors.neutralBranding4};
  background-color: ${({ theme }) => theme.themesColors.neutralBranding3};
  padding: 1px 8px;
`;

export const LabelsRow = styled.div.attrs({
  'data-class': 'LabelsRow',
})`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-top: 12px;
  flex-wrap: wrap;
  gap: 8px;
`;
