import styled from 'styled-components';
import AntdSelect from 'antd/es/select';

import { SelectCss } from 'app-wrapper/view/styles/mixins';
import { divFlex } from 'app-wrapper/view/themes/mixin';

export const SelectWrapper = styled.div.attrs((props: {
  hasPrefixIcon: boolean,
  cssWidth: string,
  cssMarginLeft: string,
  dataClass: string,
}) => ({
  hasPrefixIcon: props.hasPrefixIcon,
  cssWidth: props.cssWidth,
  cssMarginLeft: props.cssMarginLeft,
  'data-class': props.dataClass || 'SelectWrapper',
}))`
  position: relative;
  width: ${({ cssWidth }) => cssWidth || '100%'};
  ${({ cssMarginLeft }) => cssMarginLeft && `margin-left: ${cssMarginLeft}`};

  .prefix-icon-wrapper {
    ${divFlex}
    align-items: center;
    justify-content: center;

    position: absolute;
    z-index: 1;
    width: calc(16px);
    margin-left: 13px;
    height: 100%;
  }

  && .ant-select .ant-select-selector {
    ${({ hasPrefixIcon }) => hasPrefixIcon && 'padding-left: calc(16px + 13px + 10px)'};
    ;
  }
`;

export const Select = styled(AntdSelect)`
  ${SelectCss}

  box-sizing: border-box;
  width: 100%;
  font-size: 12px;

  &.ant-select-error .ant-select-selector {
    border: 1px solid red !important;
    box-shadow: none;
  }

  .ant-select-selection-placeholder {
    color: #73819b;
    opacity: 1;
  }
`;
