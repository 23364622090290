import React, { FC, useEffect } from 'react';
import { Outlet, useParams } from 'react-router';

import { ShipmentBookingStatusEnum } from 'shipment-operations/constants';
import { NotAvailable, ShipmentTransportationHeader } from 'shipment-operations/view/pages/ShipmentTransportation/components';
import { Wrapper } from 'app-wrapper/view/guideline/Wrapper';

import { ShipmentTransportationTabs } from './components';

interface ITransportationOceanBookingProps {
  bookingStatus?: ShipmentBookingStatusEnum
  loadData: (shipmentId: string) => void
  reset: () => void
}

export const TransportationOceanBookingComponent: FC<ITransportationOceanBookingProps> = ({
  bookingStatus,
  loadData,
  reset,
}) => {
  const { shipmentId = '0' } = useParams();
  const shipmentStatusIsNotAvailable = bookingStatus === ShipmentBookingStatusEnum.BOOKING_SUBMITTED
    || bookingStatus === ShipmentBookingStatusEnum.BOOKING_PREPARATION;

  useEffect(() => {
    loadData(shipmentId);

    return reset;
  }, []);

  return (
    <Wrapper>
      <ShipmentTransportationHeader />
      {shipmentStatusIsNotAvailable ? (
        <NotAvailable />
      ) : (
        <>
          <ShipmentTransportationTabs />
          <Outlet />
        </>
      )}
    </Wrapper>
  );
};
