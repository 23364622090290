import styled from 'styled-components';

import { Button } from 'app-wrapper/view/components';
import { colors } from 'app-wrapper/view/themes/themesColors';

export const LocationDataEmptyContentWrapper = styled.div.attrs({
  'data-class': 'LocationDataEmptyContentWrapper',
})`
  margin-top: 100px;
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  
  & > span {
    margin: 0 auto 8px auto;
    color: ${colors.gray43};
    font-size: 14px;
    width: 51px;
    height: 22px;
    text-align: center;
  }
`;

export const LocationDataEmptyContentButton = styled(Button).attrs({
  'data-class': 'LocationDataEmptyContentButton',
})`
  margin: 0 auto 64px auto;
  width: 183px;
`;
