import { createSelector } from 'reselect';
import { RootState } from 'app-wrapper/store';

const localState = (state: RootState) => state.surchargesRatesModal;

const getLoading = createSelector(
  localState,
  (state) => state.isLoading,
);

const getOriginLocationSearchListLoading = createSelector(
  localState,
  (state) => state.isOriginLocationSearchListLoading,
);

const getDestinationLocationSearchListLoading = createSelector(
  localState,
  (state) => state.isDestinationLocationSearchListLoading,
);

const getOpenState = createSelector(
  localState,
  (state) => state.isOpen,
);

const getDate = createSelector(
  localState,
  (state) => state.date,
);

const getDateError = createSelector(
  localState,
  (state) => state.dateError,
);

const getOriginLocation = createSelector(
  localState,
  (state) => state.originLocation,
);

const getOriginLocationError = createSelector(
  localState,
  (state) => state.originLocationError,
);

const getDestinationLocation = createSelector(
  localState,
  (state) => state.destinationLocation,
);

const getDestinationLocationError = createSelector(
  localState,
  (state) => state.destinationLocationError,
);

const getOriginLocationSearchList = createSelector(
  localState,
  (state) => state.originLocationSearchList,
);

const getDestinationLocationSearchList = createSelector(
  localState,
  (state) => state.destinationLocationSearchList,
);

const getCarrierScacList = createSelector(
  localState,
  (state) => state.carrierScacList,
);

const isFormDataFilled = createSelector(
  localState,
  (state) => (
    !!state.date
    && !!state.originLocation
    && !!state.destinationLocation
  ),
);

const isFormDataValid = createSelector(
  localState,
  (state) => (
    !state.dateError
    && !state.originLocationError
    && !state.destinationLocationError
  ),
);

export const surchargesRatesModalSelectors = {
  getCarrierScacList,
  getDate,
  getDateError,
  getDestinationLocation,
  getDestinationLocationError,
  getDestinationLocationSearchList,
  getDestinationLocationSearchListLoading,
  getLoading,
  getOpenState,
  getOriginLocation,
  getOriginLocationError,
  getOriginLocationSearchList,
  getOriginLocationSearchListLoading,
  isFormDataFilled,
  isFormDataValid,
};
