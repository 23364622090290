import styled from 'styled-components';

export const ContactBookTitleContainer = styled.div.attrs({
  'data-class': 'ContactBookTitleContainer',
})`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  max-width: 1660px;
  margin: 0 auto;
  padding: 11px 0;

  button {
    padding: 4px 16px;
  }
  .PlusSvg {
    color: ${({ theme }) => theme?.themesColors?.neutralBranding1};
    margin-right: 9px;
  }
`;

export const ContactBookTitleLeftPart = styled.div.attrs({
  'data-class': 'ContactBookTitleLeftPart',
})`
  display: flex;
  align-items: center;
  gap: 16px;

  h4 {
    color: ${({ theme }) => theme?.themesColors?.neutralBranding10};
    font-size: 20px;
    font-weight: 500;
  }

  span.ant-scroll-number-only-unit {
    color: ${({ theme }) => theme?.themesColors?.neutralBranding10};
  }
`;
