import React, { FC } from 'react';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';

import { useAuthToken } from 'app-wrapper/hooks';
import { UC } from 'shipment-operations/controllers';
import { R } from 'shipment-operations/repository';
import { DocumentType } from 'shipment-operations/constants';

import { IMODeclarationUploadComponent } from './IMODeclarationUpload.component';

const IMODeclarationUploadContainer: FC = () => {
  const authToken = useAuthToken();
  const { shipmentId = '' } = useParams();
  const error = useSelector(R.selectors.shipmentContainers.getIMODeclarationError);
  const imoDeclaration = useSelector(R.selectors.shipmentContainers.getSelectedContainerIMODeclaration);
  const isPending = useSelector(R.selectors.shipmentContainers.getUpdateLoadingState);
  const permissions = useSelector(R.selectors.shipment.getContainersPermissions);
  return (
    <IMODeclarationUploadComponent
      shipmentId={shipmentId}
      authToken={authToken}
      error={error}
      documentType={DocumentType.IMO}
      containerDocument={imoDeclaration}
      downloadDocument={UC.shipmentContainers.downloadDocument}
      setContainerDocument={UC.shipmentContainers.setIMODeclaration}
      containersAvailability={permissions.containersAvailability}
      isPending={isPending}
    />
  );
};

export { IMODeclarationUploadContainer as IMODeclarationUpload };
