import { createSelector } from 'reselect';

import { RootState } from 'app-wrapper/store';

const localState = (state: RootState) => state.userManagementModuleFunctionalityPermissions;

const getPermissions = createSelector(
  localState,
  (state) => state.permissions,
);

const getUserManagementPermissions = createSelector(
  localState,
  (state) => state.permissions?.userManagement,
);

const getUsersPermissions = createSelector(
  localState,
  (state) => state.permissions?.users,
);

const getContractsPermissions = createSelector(
  localState,
  (state) => state.permissions?.contracts,
);

const getUserManagementProfilePermissions = createSelector(
  getPermissions,
  (permissions) => permissions?.userManagementProfile,
);

const getAccountOrganizationProfilePermissions = createSelector(
  localState,
  (state) => state.permissions?.accountOrganizationProfile,
);

const getCustomerOrganizationProfilePermissions = createSelector(
  localState,
  (state) => state.permissions?.customerOrganizationProfile,
);

const getPartnerOrganizationProfilePermissions = createSelector(
  localState,
  (state) => state.permissions?.partnerOrganizationProfile,
);

const getEditOrganizationProfileDrawerPermissions = createSelector(
  localState,
  (state) => state.permissions?.editOrganizationProfileDrawer,
);

const getEditPartnerOrganizationProfileDrawerPermissions = createSelector(
  localState,
  (state) => state.permissions?.editPartnerOrganizationProfileDrawer,
);

const getIsAdmin = createSelector(
  getUserManagementPermissions,
  (user) => user?.isAdmin,
);

export const moduleFunctionalityPermissionsSelectors = {
  getPermissions,
  getUserManagementPermissions,
  getIsAdmin,
  getUsersPermissions,
  getAccountOrganizationProfilePermissions,
  getCustomerOrganizationProfilePermissions,
  getPartnerOrganizationProfilePermissions,
  getUserManagementProfilePermissions,
  getEditOrganizationProfileDrawerPermissions,
  getEditPartnerOrganizationProfileDrawerPermissions,
  getContractsPermissions,
};
