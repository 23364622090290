import styled from 'styled-components';

import { PageHeader, Button as BaseButton } from 'app-wrapper/view/components';
import { Wrapper, FlexCol } from 'app-wrapper/view/guideline';

export const PageLayout = styled(FlexCol)`
  margin: 0;
  height: calc(100vh - 48px);
  background: ${({ theme }) => theme?.themesColors?.lightGrayBorder};
`;

export const Page = styled(Wrapper)`
  padding-top: 0;
`;

export const HeaderWrap = styled.div`
  width: 100%;
  height: 56px;
  background-color: #FFF;
`;

export const Header = styled(PageHeader)`
  padding: 0 24px;

  .ant-page-header-content {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
`;

export const Title = styled.span`
  font-size: 20px;
  line-height: 28px;
  font-weight: 500;
`;

export const Button = styled(BaseButton)`
  margin-left: auto;
  
  span {
    font-weight: 400; 
  }
`;
