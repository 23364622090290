import styled from 'styled-components';

import themesColors from 'app-wrapper/view/themes/themesColors';
import {
  Input, InputPassword, Button, AlertBlock,
} from 'app-wrapper/view/components';

export const SignInWrapper = styled.div.attrs({
  'data-class': 'SignInWrapper',
})`
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-around;
  align-items: center;

  width: 100%;
  height: 100%;
`;

export const SignInForm = styled.div.attrs({
  'data-class': 'SignInForm',
})`
  margin-top: auto;
  width: 360px;
  height: fit-content;
`;

export const LogoWrapper = styled.div.attrs({
  'data-class': 'LogoWrapper',
})`
  width: fit-content;
  margin: 0 auto 12px auto;
`;

export const SignInTitle = styled.p.attrs({
  'data-class': 'SignInTitle',
})`
  margin: auto auto 24px auto;

  color: ${themesColors.secondaryDot45};
  font-size: 14px;
  line-height: 22px;
  text-align: center;
`;

export const ErrorWrapper = styled.span.attrs({
  'data-class': 'ErrorWrapper',
})`
  text-align: center;
`;

export const SignInEmailInput = styled(Input).attrs({
  'data-class': 'SignInEmailInput',
})`
  margin-bottom: 12px;
  color: ${themesColors.secondaryDot45};

  .ant-input {
    color: ${themesColors.secondaryDot45};
  }

  .ant-input::placeholder {
    color: ${themesColors.secondaryDot45};
  }
`;

export const SignInPasswordInput = styled(InputPassword).attrs({
  'data-class': 'SignInPasswordInput',
})`
  margin-bottom: 4px;
  color: ${themesColors.secondaryDot45};

  .ant-input {
    color: ${themesColors.secondaryDot45};
  }

  .ant-input::placeholder {
    color: ${themesColors.secondaryDot45};
  }
`;

export const SignInButton = styled(Button).attrs({
  'data-class': 'SignInButton',
})`
  width: 100%;
  margin-bottom: 12px;
`;

export const ForgotPasswordLinkWrapper = styled.p.attrs({
  'data-class': 'ForgotPasswordLinkWrapper',
})`
  text-align: right;

  > a {
    color: ${themesColors.primaryBranding7};
  }
`;

export const SignUpLinkWrapper = styled.div.attrs({
  'data-class': 'SignUpLinkWrapper',
})`
  text-align: center;

  a {
    color: ${themesColors.primaryBranding7};
  }
`;

export const SignInAlertBlock = styled(AlertBlock).attrs({
  'data-class': 'SignInAlertBlock',
})`
  margin: 24px 0 12px 0;

  font-size: 14px;
`;

export const BottomMessage = styled.p.attrs({
  'data-class': 'BottomMessage',
})`
  margin: auto auto 20px auto;

  text-align: center;
  font-size: 14px;
  color: ${themesColors.secondaryDot45};
`;
