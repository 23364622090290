import React, { FC } from 'react';

import { R } from 'shipment-operations/repository';
import { useAppSelector } from 'app-wrapper/hooks';

import { TotalFooterComponent } from './TotalFooter.component';

interface ITotalFooterProps {
  dataSource: {
    apTotalCost: number
    arTotalCost: number
    profit: number
    totalCost: number
  }
}

const TotalFooterContainer: FC<ITotalFooterProps> = ({ dataSource }) => {
  const permissions = useAppSelector(R.selectors.shipment.getShipmentChargesPermissions);
  const mode = useAppSelector(R.selectors.shipmentCharges.getTabOwner);
  const isPercentage = useAppSelector(R.selectors.shipmentCharges.getIsPercentage);

  return (
    <TotalFooterComponent
      dataSource={dataSource}
      permissions={permissions}
      mode={mode}
      isPercentage={isPercentage}
    />
  );
};

export { TotalFooterContainer as TotalFooter };
