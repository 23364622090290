import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';

import { NRADocumentHeaderComponent } from 'shipment-operations/view/pages/ShipmentDocumentsAll/components/DocumentsSection/components/ActionMenu/components/PrintNRAAgreementRFQ/components/NRADocumentHeader';

export const PDFDocHeader = () => {
  const { t } = useTranslation();
  const adminInfo = useSelector(R.selectors.shipmentDocumentsAll.getCurrentOrgRelatedAdminPublicInfo);
  const bookingOpsDepartment = useSelector(R.selectors.shipmentDocumentsAll.getAccountHolderBookingOpsDepartment);
  const shipment = useSelector(R.selectors.shipmentDocumentsAll.getShipment);

  return adminInfo ? (
    <NRADocumentHeaderComponent
      companyName={adminInfo.name}
      address={adminInfo.address}
      departmentName={t('Booking')}
      phone={bookingOpsDepartment?.phone || ''}
      email={bookingOpsDepartment?.email || ''}
      isAdminFromUS={adminInfo.getIsAdminFromUS()}
      isOriginOrDestinationUS={shipment?.isUsShipmentOriginOrDestination() || false}
    />
  ) : null;
};
