import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'app-wrapper/createSlice';

import { IShipmentDocumentsSeaworthyCertificatesState } from 'shipment-operations/models/states';
import { ContainerDocumentDTM, ContainerDTM } from 'shipment-operations/models/dtm';

const initialState: IShipmentDocumentsSeaworthyCertificatesState = {
  isLoading: false,
  loadingHappened: false,
  isUpdateLoading: false,
  containers: [],
  hasSOC: false,
  containersIdsWithErrors: [],
  initialContainers: [],
};

export const shipmentDocumentsSeaworthyCertificatesStore = createSlice({
  name: 'temperatureControl',
  initialState,
  reducers: {
    setIsLoading: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      isLoading: payload,
    }),
    setIsUpdateLoading: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      isUpdateLoading: payload,
    }),
    setHasLoadingHappened: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      loadingHappened: payload,
    }),
    setContainers: (state, { payload }: PayloadAction<ContainerDTM[]>) => ({
      ...state,
      containers: [...payload],
    }),
    setInitialContainers: (state, { payload }: PayloadAction<ContainerDTM[]>) => ({
      ...state,
      initialContainers: [...payload],
    }),
    setHasSOC: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      hasSOC: payload,
    }),
    setContainerSeaworthyCertificate: (state, { payload }: PayloadAction<{ containerId: string, document: ContainerDocumentDTM | null }>) => {
      const targetContainer = state.containers.find((container) => container.id === payload.containerId) as ContainerDTM;
      const targetContainerIndex = state.containers.findIndex((container) => container.id === payload.containerId);

      state.containers[targetContainerIndex] = ContainerDTM.fromPlain({
        ...targetContainer,
        seaworthyCertificate: payload.document,
      });
    },
    addErrorContainerId: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      containersIdsWithErrors: [
        ...state.containersIdsWithErrors,
        payload,
      ],
    }),
    clearContainersIdsWithErrors: (state) => ({
      ...state,
      containersIdsWithErrors: [],
    }),
    clear: () => (initialState),
  },
});

export const shipmentDocumentsSeaworthyCertificatesActions = shipmentDocumentsSeaworthyCertificatesStore.actions;
export const shipmentDocumentsSeaworthyCertificatesReducer = shipmentDocumentsSeaworthyCertificatesStore.reducer;
