import React from 'react';

import { IPropsIcon } from 'app-wrapper/interfaces/icon';

export const RectangleRhombusSvg = (props: IPropsIcon) => {
  const { width, height, style } = props;
  return (
    <svg
      className="RectangleRhombusSvg"
      width={width || '11'}
      height={height || '12'}
      viewBox="0 0 11 12"
      color={style?.color || '#6C79DE'}
      style={style || undefined}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0.707107 6.70808C0.316583 6.31756 0.316583 5.68439 0.707107 5.29387L4.94975 1.05123C5.34027 0.660705 5.97344 0.660705 6.36396 1.05123L10.6066 5.29387C10.9971 5.68439 10.9971 6.31756 10.6066 6.70808L6.36396 10.9507C5.97344 11.3412 5.34027 11.3412 4.94975 10.9507L0.707107 6.70808Z" fill="#FAFAFA" />
      <path fillRule="evenodd" clipRule="evenodd" d="M2.12132 6.00098L5.65685 9.53651L9.19239 6.00098L5.65685 2.46544L2.12132 6.00098ZM0.707107 5.29387C0.316583 5.68439 0.316583 6.31756 0.707107 6.70808L4.94975 10.9507C5.34027 11.3412 5.97344 11.3412 6.36396 10.9507L10.6066 6.70808C10.9971 6.31756 10.9971 5.68439 10.6066 5.29387L6.36396 1.05123C5.97344 0.660705 5.34027 0.660705 4.94975 1.05123L0.707107 5.29387Z" fill="#6C79DE" />
    </svg>
  );
};
