import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { DateDtm } from 'app-wrapper/models/dtm';

import {
  Information,
  Title,
  Row,
  Text,
  TextBold,
} from 'app-wrapper/view/drawers/VGMTaskDrawer/components/VGMInformation/VGMInformation.styled';

interface IRailBillingInformationProps {
  dueDate: DateDtm | null;
}

export const RailBillingInformation: FC<IRailBillingInformationProps> = ({ dueDate }) => {
  const { t } = useTranslation();

  return (
    <Information>
      <Title>
        {t('Provide Rail Billing Information')}
      </Title>

      <Text>
        {t('To ensure the successful loading and transportation of your shipment, please follow these steps:')}
      </Text>

      <br />

      <Row>
        <Text>1.</Text>

        <TextBold>
          {t('Submit Rail Billing Information:')}
        </TextBold>

        <Text>
          {t(`For each container in your shipment, you must provide Rail Billing information to the carrier before ${dueDate ? dueDate.getFormatDMMMHHmm() : ''}`)}
        </Text>
      </Row>

      <Row>
        <Text>2.</Text>

        <TextBold>
          {t('Verify Submission in Skypace:')}
        </TextBold>

        <Text>
          {t('After submitting Rail Billing to the carrier, indicate that the information has been submitted for each container in your shipment.')}
        </Text>
      </Row>

      <br />

      <Text>
        {t('This step is crucial for tracking and verifying that all necessary information has been provided.')}
      </Text>
    </Information>
  );
};
