import React from 'react';
import { useSelector } from 'react-redux';
import { observer } from 'mobx-react-lite';

import { R } from 'user-management/repository';
import { UC } from 'user-management/controllers';

import { SalesRepresentativeComponent } from './SalesRepresentative.component';

export const SalesRepresentative = observer(() => {
  const customerOrg = useSelector(R.selectors.customer.getOrganization);
  const permissions = useSelector(R.selectors.moduleFunctionalityPermissions.getCustomerOrganizationProfilePermissions);

  return (
    <SalesRepresentativeComponent
      salesManager={customerOrg?.salesManager}
      openSalesRepresentativeDrawer={UC.salesRepresentative.openSalesRepresentativeDrawer}
      salesRepresentativePermissions={permissions?.salesRepresentativePermissions}
    />
  );
});
