import styled from 'styled-components';

import themesColors from 'app-wrapper/view/themes/themesColors';

import imagePath from './images/freightune-signin-bg-2.png';

export const AuthWrapper = styled.div.attrs({
  'data-class': 'AuthWrapper',
})`
  display: flex;
  flex-flow: row nowrap;

  width: 100vw;
  height: 100vh;

  background: ${themesColors.neutralBranding1};
`;

export const AuthLeftSide = styled.div.attrs({
  'data-class': 'AuthLeftSide',
})`
  width: 50%;
  min-width: 50%;
  max-width: 50%;
  height: 100%;

  background: url(${imagePath}) no-repeat;
  background-size: cover;
`;

export const AuthRightSide = styled.div.attrs({
  'data-class': 'AuthRightSide',
})`
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-around;
  align-items: center;

  width: 50%;
  min-width: 50%;
  max-width: 50%;
  height: 100%;
`;
