import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { EmptySvg, PlusSvg } from 'app-wrapper/view/icons';
import themesColors from 'app-wrapper/view/themes/themesColors';

import {
  NoDataStub,
  NoDataStubTitle,
  NoDataButton,
  NoDataButtonStub,
} from './NoDataStub.styled';

interface NoDataStubComponentProps {
  onAddData?: () => void;
}

const NoDataStubComponent: FC<NoDataStubComponentProps> = ({ onAddData }) => {
  const { t } = useTranslation();

  return (
    <NoDataStub>
      <EmptySvg width={108} height={70} />

      <NoDataStubTitle>
        {t('No Data')}
      </NoDataStubTitle>

      {onAddData ? (
        <NoDataButton
          onClick={onAddData}
          type="dashed"
          icon={<PlusSvg style={{ color: themesColors.characterBrandingTitle85 }} />}
        >
          {t('Add')}
        </NoDataButton>
      ) : <NoDataButtonStub />}
    </NoDataStub>
  );
};

export { NoDataStubComponent as NoDataStub };
