import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { EOrganizationStatus } from 'user-management/models/dtm';
import { EStepStatus, Step, Progress } from 'app-wrapper/view/guideline';

import {
  Content,
  Header,
  Steps,
  StepWrapper,
  Title,
  Wrap,
  ProgressWrap,
} from './StepsManager.styled';
import {
  BookFirstShipment,
  InviteMembers,
  MarkAsCompleted,
  OrganizationActive,
  OrganizationCreated,
  RegisterAccountStep,
  RequestFirstQuota,
} from './components';
import { AgreeTermsAndConditionContainer } from './components/AgreeTermsAndCondition/AgreeTermsAndCondition.container';

interface StepsManagerComponentProps {
  status?: EOrganizationStatus
  hasRequiredDocuments?: boolean
  hasOwnRequestedQuotas?: boolean
  hasOwnBookedShipments?: boolean
  isUserInvited?: boolean
  isUserPrimary: boolean;
  isTermsAndConditionAccepted: boolean
  isTheOnlyMemberInOrganization: boolean
  step: number
  goToOrganizationRegistrationStep: () => void
  goToQuotasStep: () => void
  goToBookShipmentStep: () => void
  goToAgreeTermsAndConditions: () => void
  goToInviteMembers: () => void
}

export const StepsManagerComponent: FC<StepsManagerComponentProps> = ({
  status,
  hasRequiredDocuments,
  hasOwnBookedShipments,
  hasOwnRequestedQuotas,
  isTheOnlyMemberInOrganization,
  isUserInvited,
  isUserPrimary,
  isTermsAndConditionAccepted,
  step,
  goToQuotasStep,
  goToBookShipmentStep,
  goToOrganizationRegistrationStep,
  goToAgreeTermsAndConditions,
  goToInviteMembers,
}) => {
  const { t } = useTranslation();

  const firstStepStatus = useMemo<EStepStatus>(() => {
    if (status === EOrganizationStatus.ACTIVE) {
      return EStepStatus.COMPLETED;
    }

    if (status === EOrganizationStatus.WAITING_APPROVAL) {
      return EStepStatus.PASSED;
    }

    return EStepStatus.WAITING;
  }, [status]);

  const showAgreeToTermsAndConditionsStep = status === EOrganizationStatus.ACTIVE && !isTermsAndConditionAccepted;
  const isInviteMembersStepVisible = useMemo(() => status === EOrganizationStatus.ACTIVE && isUserPrimary, [isUserPrimary, status]);

  const agreeToTermsAndConditionsStepStatus = EStepStatus.WAITING;

  const hasOwnRequestedQuotasStatus = useMemo<EStepStatus>(() => (hasOwnRequestedQuotas ? EStepStatus.COMPLETED : EStepStatus.WAITING), [hasOwnRequestedQuotas]);
  const hasOwnBookedShipmentsStatus = useMemo<EStepStatus>(() => (hasOwnBookedShipments ? EStepStatus.COMPLETED : EStepStatus.WAITING), [hasOwnBookedShipments]);
  const inviteMembersStatus = useMemo<EStepStatus>(() => (isTheOnlyMemberInOrganization ? EStepStatus.WAITING : EStepStatus.COMPLETED), [isTheOnlyMemberInOrganization]);

  const organizationPercentage = useMemo(() => {
    if (status === EOrganizationStatus.ACTIVE) {
      return 40;
    }

    if (status === EOrganizationStatus.WAITING_APPROVAL) {
      return 30;
    }

    if (status === EOrganizationStatus.DRAFT && hasRequiredDocuments) {
      return 20;
    }

    if (status === EOrganizationStatus.DRAFT && !hasRequiredDocuments) {
      return 10;
    }

    return 0;
  }, [status, hasRequiredDocuments]);

  const percentage = useMemo(() => {
    if (!isUserPrimary) {
      if (!status) {
        return organizationPercentage + (hasOwnRequestedQuotas ? 30 : 0) + (hasOwnBookedShipments ? 30 : 0);
      }

      return (hasOwnRequestedQuotas ? 50 : 0) + (hasOwnBookedShipments ? 50 : 0);
    }

    if (status && isUserPrimary && (hasOwnRequestedQuotas || hasOwnBookedShipments || !isTheOnlyMemberInOrganization)) {
      return organizationPercentage + (hasOwnRequestedQuotas ? 20 : 0) + (hasOwnBookedShipments ? 20 : 0) + (!isTheOnlyMemberInOrganization ? 20 : 0);
    }

    if (status === EOrganizationStatus.ACTIVE) {
      return 40;
    }

    if (status === EOrganizationStatus.WAITING_APPROVAL) {
      return 30;
    }

    if (status === EOrganizationStatus.DRAFT && hasRequiredDocuments) {
      return 20;
    }

    if (status === EOrganizationStatus.DRAFT && !hasRequiredDocuments) {
      return 10;
    }

    return 0;
  }, [status, hasRequiredDocuments, hasOwnRequestedQuotas, hasOwnBookedShipments, isUserPrimary, isTheOnlyMemberInOrganization, organizationPercentage]);

  return (
    <Wrap>
      <Header>
        <Title>
          {t('Steps to Get Started')}
        </Title>

        <MarkAsCompleted />

        <ProgressWrap>
          <Progress percentage={percentage} />
        </ProgressWrap>
      </Header>

      <Content>
        <Steps>
          <Step
            onClick={goToQuotasStep}
            name={t('Browse Pricing')}
            status={hasOwnRequestedQuotasStatus}
            isChosen={step === 1}
          />

          {!isUserInvited ? (
            <Step
              onClick={goToOrganizationRegistrationStep}
              name={t('Register Organization Account')}
              status={firstStepStatus}
              isChosen={step === 0}
            />
          ) : null}

          {showAgreeToTermsAndConditionsStep ? (
            <Step
              onClick={goToAgreeTermsAndConditions}
              name={t('Agree to Terms and Conditions')}
              status={agreeToTermsAndConditionsStepStatus}
              isChosen={step === 3}
            />
          ) : null}

          <Step
            onClick={goToBookShipmentStep}
            name={t('Book Your First Shipment')}
            status={hasOwnBookedShipmentsStatus}
            isChosen={step === 2}
          />

          {isInviteMembersStepVisible ? (
            <Step
              onClick={goToInviteMembers}
              name={t('Invite Colleagues')}
              status={inviteMembersStatus}
              isChosen={step === 4}
            />
          ) : null}
        </Steps>

        <StepWrapper>
          {step === 0 && !isUserInvited ? (
            <>
              {!status || status === EOrganizationStatus.DRAFT ? (
                <RegisterAccountStep />
              ) : null}

              {status === EOrganizationStatus.WAITING_APPROVAL ? (
                <OrganizationCreated />
              ) : null}

              {status === EOrganizationStatus.ACTIVE ? (
                <OrganizationActive />
              ) : null}
            </>
          ) : null}

          {step === 3 ? <AgreeTermsAndConditionContainer /> : null}
          {step === 1 ? <RequestFirstQuota /> : null}
          {step === 2 ? <BookFirstShipment /> : null}
          {step === 4 ? <InviteMembers /> : null}
        </StepWrapper>
      </Content>
    </Wrap>
  );
};
