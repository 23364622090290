import React from 'react';

import { IPropsIcon } from 'app-wrapper/interfaces/icon';

import { Icon } from './CloseSvg.styled';

export const CloseSvg = (props: IPropsIcon) => {
  const {
    width, height, fill, onClick,
  } = props;
  return (
    <Icon
      className="CloseSvg"
      width={width || '10'}
      height={height || '10'}
      viewBox="0 0 10 10"
      onClick={onClick}
      fill={fill || 'currentColor'}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M5.69379 4.99937L9.20941 0.808747C9.26834 0.739104 9.21879 0.633301 9.12772 0.633301H8.05897C7.99602 0.633301 7.93575 0.661426 7.89423 0.70964L4.99468 4.16634L2.09513 0.70964C2.05495 0.661426 1.99468 0.633301 1.93039 0.633301H0.861645C0.770573 0.633301 0.72102 0.739104 0.779948 0.808747L4.29557 4.99937L0.779948 9.19C0.766748 9.20552 0.758279 9.22451 0.755547 9.2447C0.752816 9.26489 0.755936 9.28544 0.764538 9.30392C0.773139 9.32239 0.786861 9.33801 0.804074 9.34891C0.821287 9.35982 0.841268 9.36556 0.861645 9.36544H1.93039C1.99334 9.36544 2.05361 9.33732 2.09513 9.2891L4.99468 5.83241L7.89423 9.2891C7.93441 9.33732 7.99468 9.36544 8.05897 9.36544H9.12772C9.21879 9.36544 9.26834 9.25964 9.20941 9.19L5.69379 4.99937Z" fill="currentColor" />
    </Icon>
  );
};
