import styled from 'styled-components';

import { Input } from 'app-wrapper/view/components';

export const CardVolumeField = styled(Input).attrs({
  'data-class': 'CardVolumeField',
})`  
  width: 92px;
  font-size: 12px;
  height: 32px;
  min-height: 32px;
`;
