import styled from 'styled-components';
import AntProgress from 'antd/es/progress';

import { FlexRow } from 'app-wrapper/view/guideline';

export const ProgressWrap = styled(FlexRow)`
  align-items: center;
  gap: 8px;
`;

export const Progress = styled(AntProgress)`
  margin-left: auto;
  width: 364px;

  .ant-progress-bg {
    background-color: rgba(108, 121, 222, 1);
  }

  .ant-progress-inner, .ant-progress-bg {
    height: 6px !important;
  }
`;

export const ProgressText = styled.span`
  font-size: 14px;
  line-height: 22px;
`;
