import React from 'react';

import { UC } from 'shipment-operations/controllers';
import { useAppSelector } from 'app-wrapper/hooks';
import { R } from 'shipment-operations/repository';

import { StickyTotalComponent } from './StickyTotal.component';

const StickyTotal = () => {
  const isLoading = useAppSelector(R.selectors.createInvoiceCharges.getCreateInvoiceLoading);

  return (
    <StickyTotalComponent
      onCreateInvoice={UC.createInvoiceCharges.onCreateInvoice}
      isLoading={isLoading}
    />
  );
};

export { StickyTotal };
