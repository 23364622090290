import styled from 'styled-components';

import { FlexRow } from 'app-wrapper/view/guideline';

export const Filters = styled(FlexRow)`
  margin: 0 auto;
  max-width: 1660px;
  padding: 16px 24px;
  width: 100%;
  justify-content: flex-start;
  gap: 30px;
`;

export const Wrap = styled(FlexRow)`
  gap: 33px;
`;

export const Title = styled.span`
  color: #73819B;
  font-weight: 400;
  font-size: 12px;
`;
