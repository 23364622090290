import React from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';
import { UC } from 'shipment-operations/controllers';

import { SBLClausesInformationComponent } from './SBLClausesInformation.component';

const SBLClausesInformationContainer = ({ isHBLPage }: { isHBLPage: boolean }) => {
  const { clauses } = useSelector(R.selectors.billOfLadingCommon.getSIDetails);
  const onOpenEdit = UC.shipmentDrawer.openBillOfLadingEditClausesDrawer;

  return (
    <SBLClausesInformationComponent
      onOpenEdit={isHBLPage ? undefined : onOpenEdit}
      isIncomplete={!clauses.length}
      clauses={clauses}
    />
  );
};

export { SBLClausesInformationContainer as SBLClausesInformation };
