import React, { FC, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { DocumentType } from 'shipment-operations/constants';
import { DocumentsCollapseItemDTM } from 'shipment-operations/models/dtm';

import { CustomerDocumentsMap, OrganizationDTM } from 'user-management/models/dtm';

import moment from 'moment/moment';
import { DateDtm } from 'app-wrapper/models/dtm';
import {
  Wrap,
  Header,
  Title,
  TitleSecond,
  HeaderTitle,
} from './Documents.styled';
import { DocumentsSection } from '../DocumentsSection';
import { DocumentSectionPrint } from '../DocumentSectionPrint';

interface DocumentsComponentProps {
  documentTypesMap: CustomerDocumentsMap;
  openDocumentUploadDrawer: (documentType: DocumentType) => void;
  onDownload: (organizationId: string, documentId: number) => void;
  onDelete: (organizationId: string, documentId: number) => void;
  isOrgAdmin: boolean;
  disableDelete?: boolean;
  organizationId: number;
  isBLICRequired: boolean;
  isTermsServiceShow?: boolean;
  customerOrganization?: OrganizationDTM;
  fetchTermsData: () => void;
}

export const DocumentsComponent: FC<DocumentsComponentProps> = ({
  documentTypesMap,
  openDocumentUploadDrawer,
  onDownload,
  onDelete,
  isOrgAdmin,
  disableDelete,
  organizationId,
  isBLICRequired,
  isTermsServiceShow,
  customerOrganization,
  fetchTermsData,
}) => {
  const { t } = useTranslation();

  const documentALO = useMemo(() => documentTypesMap[DocumentType.ALO] || [], [documentTypesMap]);
  const documentCCI = useMemo(() => documentTypesMap[DocumentType.CCI] || [], [documentTypesMap]);
  const documentBLIC = useMemo(() => documentTypesMap[DocumentType.BLIC] || [], [documentTypesMap]);
  const documentTCER = useMemo(() => documentTypesMap[DocumentType.TCER] || [], [documentTypesMap]);
  const documentMSC = useMemo(() => documentTypesMap[DocumentType.MISC] || [], [documentTypesMap]);

  const openDocumentALOUploadDrawer = useCallback((e) => {
    e.stopPropagation();

    openDocumentUploadDrawer(DocumentType.ALO);
  }, [openDocumentUploadDrawer]);
  const openDocumentCCIUploadDrawer = useCallback((e) => {
    e.stopPropagation();

    openDocumentUploadDrawer(DocumentType.CCI);
  }, [openDocumentUploadDrawer]);
  const openDocumentBLICUploadDrawer = useCallback((e) => {
    e.stopPropagation();

    openDocumentUploadDrawer(DocumentType.BLIC);
  }, [openDocumentUploadDrawer]);
  const openDocumentTCERUploadDrawer = useCallback((e) => {
    e.stopPropagation();

    openDocumentUploadDrawer(DocumentType.TCER);
  }, [openDocumentUploadDrawer]);
  const openDocumentMSCUploadDrawer = useCallback((e) => {
    e.stopPropagation();

    openDocumentUploadDrawer(DocumentType.MISC);
  }, [openDocumentUploadDrawer]);

  return (
    <Wrap>
      <Header>
        <Title>
          {t('Group')}
        </Title>

        <TitleSecond>
          {t('Action')}
        </TitleSecond>
      </Header>

      <DocumentsSection
        sectionName={t('Bill stating Name & Address of Organization')}
        documents={documentALO}
        openDocumentUploadDrawer={openDocumentALOUploadDrawer}
        onDownload={onDownload}
        onRemove={onDelete}
        isOrgAdmin={isOrgAdmin}
        disableDelete={disableDelete}
        organizationId={organizationId}
      />
      <DocumentsSection
        sectionName={t('Copy of Company Identity/Business Card')}
        documents={documentCCI}
        openDocumentUploadDrawer={openDocumentCCIUploadDrawer}
        onDownload={onDownload}
        onRemove={onDelete}
        isOrgAdmin={isOrgAdmin}
        disableDelete={disableDelete}
        organizationId={organizationId}
      />

      {isBLICRequired ? (
        <DocumentsSection
          sectionName={t('Business License')}
          documents={documentBLIC}
          openDocumentUploadDrawer={openDocumentBLICUploadDrawer}
          onDownload={onDownload}
          onRemove={onDelete}
          isOrgAdmin={isOrgAdmin}
          disableDelete={disableDelete}
          organizationId={organizationId}
        />
      ) : null}

      <DocumentsSection
        sectionName={t('Tax Registration')}
        documents={documentTCER}
        openDocumentUploadDrawer={openDocumentTCERUploadDrawer}
        onDownload={onDownload}
        onRemove={onDelete}
        isOrgAdmin={isOrgAdmin}
        disableDelete={disableDelete}
        organizationId={organizationId}
      />
      <DocumentsSection
        sectionName={t('Other')}
        documents={documentMSC}
        openDocumentUploadDrawer={openDocumentMSCUploadDrawer}
        onDownload={onDownload}
        onRemove={onDelete}
        isOrgAdmin={isOrgAdmin}
        disableDelete={disableDelete}
        organizationId={organizationId}
      />

      {isTermsServiceShow ? (
        <>
          <HeaderTitle>
            {t('Documents from Skypace')}
          </HeaderTitle>

          <DocumentSectionPrint
            sectionName={t('Terms of Service')}
            fileNamePrint="Skypace. Terms of Service"
            documents={[DocumentsCollapseItemDTM.fromPlain({
              id: 0,
              name: 'Skypace. Terms of Service',
              uploadedDate: customerOrganization?.termsAndCondition()?.createdAt
                ? DateDtm.fromPlain({
                  date: customerOrganization?.termsAndCondition()?.createdAt || '',
                  offset: moment.parseZone(customerOrganization?.termsAndCondition()?.createdAt || '').utcOffset(),
                }) : null,
              objectName: '-',
              createdBy: 'System',
              type: 'name',
            })]}
            onDownload={fetchTermsData}
          />
        </>
      ) : null}
    </Wrap>
  );
};
