import { createSelector } from 'reselect';

import { RootState } from 'app-wrapper/store';

const localState = (state: RootState) => state.newOrganizationReviewAndConfirm;

const getLoading = createSelector(
  localState,
  (state) => state.isLoading,
);

const getOrganization = createSelector(
  localState,
  (state) => state.organization,
);

const getCurrentOrgRelatedAdmin = createSelector(
  localState,
  (state) => state.currentOrgRelatedAdmin,
);

const getCurrentOrgRelatedAdminAddress = createSelector(
  getCurrentOrgRelatedAdmin,
  (relatedAdmin) => relatedAdmin?.address,
);

const getAreTermsChecked = createSelector(
  localState,
  (state) => state.areTermsChecked,
);

const getCurrentOrgRelatedPartner = createSelector(
  localState,
  (state) => state.currentOrgRelatedPartner,
);

export const newOrganizationWizardReviewAndConfirmSelectors = {
  getLoading,
  getOrganization,
  getCurrentOrgRelatedAdmin,
  getCurrentOrgRelatedAdminAddress,
  getAreTermsChecked,
  getCurrentOrgRelatedPartner,
};
