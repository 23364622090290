import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { hasAccess } from 'app-wrapper/utils';
import { TableSkeleton } from 'app-wrapper/view/components';
import { PlusSvg } from 'app-wrapper/view/icons';
import { PermissionAttributePolicy } from 'shipment-operations/constants';

import {
  ContractsTable,
  ContractsFilters,
} from './components';
import {
  Page,
  Header,
  HeaderWrap,
  Title,
  PageLayout,
  Button,
} from './Contracts.styled';

interface IContractsComponentProps {
  isLoading: boolean;
  openCreateContractDrawer: () => void;
  init: () => void;
  contractsPermissions?: PermissionAttributePolicy;
}

export const ContractsComponent: FC<IContractsComponentProps> = ({
  isLoading,
  init,
  openCreateContractDrawer,
  contractsPermissions,
}) => {
  const { t } = useTranslation();

  const isAbleToEditContracts = Boolean(contractsPermissions && hasAccess(contractsPermissions, PermissionAttributePolicy.WRITE));

  useEffect(() => {
    init();
  }, []);

  return (
    <PageLayout>
      <HeaderWrap>
        <Header>
          <Title>
            {t('Contracts')}
          </Title>

          <Button
            onClick={openCreateContractDrawer}
            disabled={!isAbleToEditContracts || isLoading}
            icon={<PlusSvg style={{ color: 'white', marginRight: 8, marginBottom: 2 }} />}
          >
            {t('Add Contract')}
          </Button>
        </Header>
      </HeaderWrap>

      <ContractsFilters />

      <Page>
        {isLoading ? (
          <TableSkeleton />
        ) : <ContractsTable />}
      </Page>
    </PageLayout>
  );
};
