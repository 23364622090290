import styled from 'styled-components';

import { divFlex, responsive } from 'app-wrapper/view/themes/mixin';
import { Collapse, Panel, Select } from 'app-wrapper/view/components';

export const CInfWrapper = styled.div.attrs((props: {
  hasError: boolean,
}) => ({
  hasError: props.hasError,
  'data-class': 'CInfWrapper',
}))`
  ${divFlex}
  width: 100%;
  flex-direction: column;

  .ant-collapse-item {
    flex-direction: column;
  }

  .ant-select-selector {
    min-width: ${({ theme }) => theme?.inputConst?.defaultHeight};
    ${({ hasError, theme }) => hasError && `border-color: ${theme.themesColors.characterBrandingDanger} !important; border-style: solid;`};
  }
  ${responsive.forFullMedia`
    .ant-select-selector {
      min-width: ${({ theme }: { theme: { inputConst: { hdHeight: string } } }) => theme?.inputConst?.hdHeight};
    }
  `}
`;

export const CInfCollapse = styled(Collapse).attrs((props: {
  hasError: boolean,
}) => ({
  hasError: props.hasError,
  'data-class': 'CInfCollapse',
}))`
  ${divFlex}
  width: 100%;

  background-color: ${({ theme }) => theme?.themesColors?.neutralBranding1};
  border-left: none;
  border-right: none;
  border-top: none;
  .ant-collapse-content-active {
    border: none;
  }

  .ant-collapse-item > .ant-collapse-header {
    padding: 16px 16px 16px 0;
  }

  .anticon-right.ant-collapse-arrow {
    width: 8px;
  }
  .ant-collapse-content > .ant-collapse-content-box {
    padding-top: 0px;
  }
`;

export const CInfPanel = styled(Panel).attrs({
  'data-class': 'CInfPanel',
})`
  ${divFlex}
  width: 100%;
`;

export const CInfPanelTitleWrap = styled.div.attrs({
  'data-class': 'CInfPanelTitleWrap',
})`
  ${divFlex}
  width: 100%;

  box-shadow: inset 0px -1px 0px #f4f5f8;
  padding-bottom: 9px;
  padding-top: 8px;
  margin-bottom: 0px;
  margin-top: 17px;
`;

export const CInfPanelTitle = styled.div.attrs({
  'data-class': 'CInfPanelTitle',
})`
  ${divFlex}
  margin-right: 8px;

  ${({ theme }) => theme?.fonts?.BodyTextMedium};
`;

export const CInfCompany = styled.div.attrs({
  'data-class': 'CInfCompany',
})`
  ${divFlex}
  width: 100%;
`;

export const CInfCompanyContent = styled.div.attrs((props: {
  isCollapse: boolean
  'data-class'?: string
}) => ({
  isCollapse: props.isCollapse,
  'data-class': props?.['data-class'] || 'CInfCompanyContent',
}))`
  ${divFlex}
  flex-wrap: wrap;
  margin-top: ${({ isCollapse }) => (isCollapse ? '6px' : '16px')};
  margin-bottom: 16px;
`;

export const CInfCompanyName = styled.div.attrs({
  'data-class': 'CInfCompanyName',
})`
  ${divFlex}
  width: 100%;
  margin-bottom: 8px;

  font-size: 12px;
  font-weight: 400;
  color: ${({ theme }: any) => theme?.themesColors?.neutralBranding10};
`;

export const CInfCompanyEndSpan = styled.span.attrs({
  'data-class': 'CInfCompanyEndSpan',
})`
`;

export const CInfCompanySelectWrapper = styled.div.attrs({
  'data-class': 'CInfCompanySelectWrapper',
})`
  ${divFlex}
  width: 100%;
  max-width: 297px;
`;

export const CInfCompanySelect = styled(Select).attrs((props: {
  hasError: boolean,
}) => ({
  hasError: props.hasError,
  'data-class': 'CInfCompanySelect',
}))`
  ${divFlex}
  width: 100%;

  .ant-select-selector {
    min-width: ${({ theme }) => theme?.inputConst?.defaultHeight};
    ${({ hasError, theme }) => hasError && `border-color: ${theme.themesColors.characterBrandingDanger} !important; border-style: solid;`};
  }
  ${responsive.forFullMedia`
    .ant-select-selector {
      min-width: ${({ theme }: { theme: { inputConst: { hdHeight: string } } }) => theme?.inputConst?.hdHeight};
    }
  `}
`;
