import React, { FC, useContext } from 'react';
import { observer } from 'mobx-react-lite';

import { MobxStoresContext } from 'app-wrapper/mobxStores';

import { UC } from 'monetary/controllers';

import { RFQAddAdditionalServicesComponent } from './RFQAddAdditionalServices.component';

const BookingAddAdditionalServicesContainer: FC = observer(() => {
  const { additionalServicesDrawerStore } = useContext(MobxStoresContext);
  const addAdditionalServicesOriginDrawer = additionalServicesDrawerStore?.getAddAdditionalServicesOriginBookingDrawer;
  const addAdditionalServicesFreightDrawer = additionalServicesDrawerStore?.getAddAdditionalServicesFreightBookingDrawer;
  const addAdditionalServicesDestinationDrawer = additionalServicesDrawerStore?.getAddAdditionalServicesDestinationBookingDrawer;

  const addAdditionalServicesDrawerTotalCost = additionalServicesDrawerStore?.getAddAdditionalServicesBookingDrawerTotalCost;
  const addAdditionalServicesDrawerChecked = additionalServicesDrawerStore?.getAddAdditionalServicesBookingDrawerChecked;

  return (
    <RFQAddAdditionalServicesComponent
      isBooking
      servicesOrigin={addAdditionalServicesOriginDrawer}
      servicesFreight={addAdditionalServicesFreightDrawer}
      servicesDestination={addAdditionalServicesDestinationDrawer}
      totalCost={addAdditionalServicesDrawerTotalCost}
      totalCheckedServices={addAdditionalServicesDrawerChecked?.length}
      onCloseDrawer={UC.AdditionalServices.closeAdditionalServiceBookingDrawer}
      addServicesDrawer={UC.AdditionalServices.addAdditionalServiceBookingDrawer}
    />
  );
});

export { BookingAddAdditionalServicesContainer as BookingAddAdditionalServicesPage };
