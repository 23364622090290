import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'app-wrapper/createSlice';

import { TSignUpSuccessState } from 'authentication/models/states';

export const initialState: TSignUpSuccessState = {
  email: undefined,
};

export const signUpSuccessSlice = createSlice({
  name: 'signUpSuccess',
  initialState,
  reducers: {
    setEmail: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      email: payload,
    }),
  },
});

export const signUpSuccessActions = signUpSuccessSlice.actions;
export const signUpSuccessReducer = signUpSuccessSlice.reducer;
