import React, { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { Button } from 'app-wrapper/view/components';

import { FooterWrapper } from './Footer.styled';

interface FooterProps {
  onCancel: (organizationId: string | number) => void;
  onSubmit: (organizationId: string | number) => void;
  disabled: boolean;
  organizationId: number;
}

export const FooterComponent: FC<FooterProps> = ({
  onSubmit,
  onCancel,
  disabled,
  organizationId,
}) => {
  const { customerId = '' } = useParams();
  const { t } = useTranslation();

  const id = useMemo(() => customerId || organizationId, [organizationId, customerId]);

  const handleSubmit = useCallback(() => {
    onSubmit(id);
  }, [onSubmit, id]);

  const handleCancel = useCallback(() => {
    onCancel(id);
  }, [onCancel, id]);

  return (
    <FooterWrapper>
      <Button
        onClick={handleCancel}
        type="dashed"
        style={{
          marginRight: '8px',
        }}
      >
        {t('Cancel')}
      </Button>

      <Button onClick={handleSubmit} disabled={disabled}>
        {t('Upload')}
      </Button>
    </FooterWrapper>
  );
};
