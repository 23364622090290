import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';
import { UC } from 'shipment-operations/controllers';
import {
  ShipmentBookingStatusEnum,
  ShipmentStatusEnum,
  ShippingInstructionsStatusEnum,
} from 'shipment-operations/constants';

import { NavigationBarComponent } from './NavigationBar.component';

const SUCCESS_TYPES: string[] = [ShipmentStatusEnum.SI_SUBMITTED, ShipmentStatusEnum.SI_CONFIRMED];
const HBL_STUB_SI_STATUSES = [
  ShippingInstructionsStatusEnum.SI_PREPARATION,
  ShippingInstructionsStatusEnum.SI_FAILED,
  ShippingInstructionsStatusEnum.SI_AMENDMENT_DECLINED,
  ShippingInstructionsStatusEnum.SI_DECLINED,
];

const NavigationBarContainer = () => {
  const isFreightedView = useSelector(R.selectors.billOfLadingCommon.getIsFreightedView);
  const { isSuccessful } = useSelector(R.selectors.billOfLadingCommon.getSubmitSI);
  const shipment = useSelector(R.selectors.shipment.getShipment);
  const isHBLPage = useSelector(R.selectors.billOfLadingCommon.getIsHBLPage);

  const siStatus = useMemo(() => (shipment ? shipment.siStatus : ShippingInstructionsStatusEnum.SI_PREPARATION), [shipment]);
  const showHBLStub = useMemo(() => HBL_STUB_SI_STATUSES.includes(siStatus), [siStatus]);
  const isFreightSwitchHidden = useMemo(() => !!(showHBLStub || shipment?.bookingStatus === ShipmentBookingStatusEnum.BOOKING_PREPARATION || (shipment && isSuccessful && SUCCESS_TYPES.includes(shipment?.status))), [shipment, isSuccessful, showHBLStub]);

  return (
    <NavigationBarComponent
      isHBLPage={isHBLPage}
      isFreightedView={isFreightedView}
      setIsFreightedView={UC.billOfLading.setIsViewFreighted}
      isFreightSwitchHidden={isFreightSwitchHidden}
      goToHBL={UC.billOfLading.goToHBL}
      goToMBL={UC.billOfLading.goToMBL}
    />
  );
};

export { NavigationBarContainer as NavigationBar };
