import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { BookingConfirmationFreightItemsDTM } from 'shipment-operations/models/dtm';

import {
  Wrap,
  FreightAndCharges,
  Title,
  Items,
  Item,
  ItemText,
  ItemTitle,
  ItemTitleTag,
  ItemTitleWrap,
  ItemLast,
} from './PDFFreightAndCharges.styled';

interface PDFFreightAndChargesComponentProps {
  paymentTermsItems: BookingConfirmationFreightItemsDTM[];
  totalValue: string;
}

export const PDFFreightAndChargesComponent: FC<PDFFreightAndChargesComponentProps> = ({
  paymentTermsItems,
  totalValue,
}) => {
  const { t } = useTranslation();

  return (
    <Wrap>
      <FreightAndCharges>
        <Title>
          {t('Freight and Charges')}
        </Title>

        <Items>
          {paymentTermsItems.map((paymentTerm) => (
            <Item key={`PDFFreightAndChargesComponent_${paymentTerm.title}`}>
              <ItemTitleWrap>
                <ItemTitle>
                  {paymentTerm.title}
                </ItemTitle>

                <ItemTitleTag>
                  {paymentTerm.subtitle}
                </ItemTitleTag>
              </ItemTitleWrap>

              <ItemText>
                {paymentTerm.text}
              </ItemText>
            </Item>
          ))}

          <ItemLast>
            <ItemTitle>
              {t('Declared Cargo Value')}
            </ItemTitle>

            <ItemText>
              {totalValue}
            </ItemText>
          </ItemLast>
        </Items>
      </FreightAndCharges>
    </Wrap>
  );
};
