import React, { FC, memo } from 'react';
import { useSelector } from 'react-redux';

import { REQUEST_STATUS } from 'app-wrapper/constants';

import { UC } from 'user-management/controllers';
import { R } from 'user-management/repository';

import { ContactBookByIdEditCompanyComponent } from './ContactBookByIdEditCompany.component';

const ContactBookByIdEditCompanyContainer: FC = () => {
  const contactBookDefault = useSelector(R.selectors.contactBook.getContactBookDefault);
  const contactBook = useSelector(R.selectors.contactBook.getContactBookUpdate);

  const listIndex = contactBook.activeListTabIndex;

  const companyListState = contactBook.activeCompanyEdit;
  const errors = useSelector(R.selectors.contactBook.getContactBookUpdateErrorsEditCompanyDetailsDrawer);

  return (
    <ContactBookByIdEditCompanyComponent
      companyListState={companyListState}
      companyListStateIndex={listIndex}
      isUpdating={contactBookDefault.getManualRequestStatus === REQUEST_STATUS.pending || !companyListState?.isUpdate}
      errors={errors}
      onRemoveCompany={UC.contactBookDrawers.onRemoveContactBookByIdEditCompany}
      updateForm={UC.contactBookDrawers.onSaveContactBookByIdEditCompany}
      onDiscard={UC.contactBookDrawers.onCancelContactBookByIdEditCompany}
      onChangeListName={UC.contactBookDrawers.onChangeContactBookByIdEditCompanyName}
      onBlurListName={UC.contactBookDrawers.onBlurContactBookByIdEditCompanyName}
      onFocusListName={UC.contactBookDrawers.onFocusContactBookByIdEditCompanyName}
      onChangeTaxId={UC.contactBookDrawers.onChangeContactBookByIdEditCompanyTaxId}
      onBlurTaxId={UC.contactBookDrawers.onBlurContactBookByIdEditCompanyTaxId}
      onFocusTaxId={UC.contactBookDrawers.onFocusContactBookByIdEditCompanyTaxId}
    />
  );
};

const ContactBookByIdEditCompanyContainerCache = memo(ContactBookByIdEditCompanyContainer);

export { ContactBookByIdEditCompanyContainerCache as ContactBookByIdEditCompanyPage };
