import AntDrawer from 'antd/es/drawer';
import styled from 'styled-components';

import { divFlex } from 'app-wrapper/view/themes/mixin';

export const AccountDrawerWrapper = styled(AntDrawer).attrs({
  'data-class': 'AccountDrawerWrapper',
})`
  .ant-drawer-body{
    padding: 0;
  }

  .ant-drawer-header-title {
    flex-direction: row-reverse;
  }

  .ant-drawer-close {
    margin-right: 0;
  }
`;

export const AccountDrawerCloset = styled.div.attrs({
  'data-class': 'AccountDrawerCloset',
})`
  ${divFlex}
  width: 30px;
  height: 30px;
  justify-content: center;
  align-items: center;

  cursor: pointer;
  border: 1px solid ${({ theme }) => theme.themesColors.neutralBranding5};
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
`;
