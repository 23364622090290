import { createSelector } from 'reselect';

import { RootState } from 'app-wrapper/store';

const localState = (state: RootState) => state.changePassword;

const getLoading = createSelector(
  localState,
  (state) => state.isLoading,
);

const getCurrentPassword = createSelector(
  localState,
  (state) => state.currentPassword,
);

const getCurrentPasswordError = createSelector(
  localState,
  (state) => state.currentPasswordError,
);

const getNewPassword = createSelector(
  localState,
  (state) => state.newPassword,
);

const getNewPasswordError = createSelector(
  localState,
  (state) => state.newPasswordError,
);

const getConfirmNewPassword = createSelector(
  localState,
  (state) => state.confirmNewPassword,
);

const getConfirmNewPasswordError = createSelector(
  localState,
  (state) => state.confirmNewPasswordError,
);

const wasAnyFormFieldChanged = createSelector(
  localState,
  (state) => (
    !state.currentPassword
    || !state.newPassword
    || !state.confirmNewPassword
    || !!state.currentPasswordError
    || !!state.newPasswordError
    || !!state.confirmNewPasswordError
  ),
);

export const changePasswordSelectors = {
  getConfirmNewPassword,
  getConfirmNewPasswordError,
  getCurrentPassword,
  getCurrentPasswordError,
  getLoading,
  getNewPassword,
  getNewPasswordError,
  wasAnyFormFieldChanged,
};
