import styled from 'styled-components';

import { Input } from 'app-wrapper/view/components';
import { divFlex } from 'app-wrapper/view/themes/mixin';

export const ShippingPartyReference = styled.div.attrs({
  'data-class': 'ShippingPartyReference',
})`
  ${divFlex}
`;

export const ShippingPartyReferenceInput = styled(Input).attrs({
  'data-class': 'ShippingPartyReferenceInput',
})`
  width: 177px;
  min-width: 177px;
  min-height: 32px;
  height: 32px;

  .ant-select {
    border: 1px solid #d9d9d9;
    height: 32px !important;
    min-height: 32px !important;
  }

  .ant-select-selector {
    height: 32px !important;
  }

  .ant-select-selection-placeholder, .ant-select-selection-item {
    line-height: 32px !important;
  }

  input {
    height: 32px !important;
  }
`;
