import {
  ContentSection, InfoCardComponent, StatusLight,
  TableListContentCell,
  TableListContentExpand,
  TableListContentItem, TableListContentItemBottom,
  TableListContentItemTop,
} from 'app-wrapper/view/guideline';
import { Collapse, Panel } from 'app-wrapper/view/components';
import React, { memo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import themesColors from 'app-wrapper/view/themes/themesColors';
import { VerticalFormItemSvg } from 'app-wrapper/view/icons';

export const DepartureContainerComponent = memo(() => {
  const { t } = useTranslation();
  const [isCollapsed, setIsCollapsed] = useState(false);
  const handleExpand = useCallback(() => {
    setIsCollapsed(!isCollapsed);
  }, [isCollapsed]);
  return (
    <ContentSection>
      <TableListContentItem key={1}>
        <TableListContentItemTop>
          <TableListContentCell
            width="20"
          >
            <TableListContentExpand
              onClick={handleExpand}
            >
              <VerticalFormItemSvg
                rotate={isCollapsed ? 0 : -90}
                style={{ color: isCollapsed ? themesColors.primaryBranding6 : themesColors.primaryBranding7 }}
              />
            </TableListContentExpand>
            20’ RE / ID-1
          </TableListContentCell>
          <TableListContentCell
            width="20"
          >
            HLCU-1213213
          </TableListContentCell>
          <TableListContentCell
            width="20"
          >
            10 Nov 2025, 18:00
          </TableListContentCell>
          <TableListContentCell
            width="20"
          >
            19 Nov 2025, 21:00
          </TableListContentCell>
          <TableListContentCell
            width="10"
          >
            001
          </TableListContentCell>
          <TableListContentCell
            width="10"
            align="right"
          >
            <StatusLight colorIcon={themesColors.secondaryDot45} /> Dispatched
          </TableListContentCell>
        </TableListContentItemTop>

        <TableListContentItemBottom>
          <Collapse
            activeKey={isCollapsed ? '1' : ''}
          >
            <Panel header="" key="1" extra={null}>
              <TableListContentItemTop>
                <TableListContentCell
                  width="20"
                >
                  <TableListContentExpand />
                  <InfoCardComponent
                    title={t('Drayage Carrier')}
                    value="Hapag Lloyd"
                  />
                </TableListContentCell>
                <TableListContentCell
                  width="20"
                >
                  <InfoCardComponent
                    title={t('Pickup Reference(s)')}
                    value="Content"
                  />
                </TableListContentCell>
              </TableListContentItemTop>
            </Panel>
          </Collapse>
        </TableListContentItemBottom>
      </TableListContentItem>
    </ContentSection>
  );
});
