import { divFlex } from 'app-wrapper/view/themes/mixin';
import styled from 'styled-components';

export const ContentBlock = styled.div.attrs({
  'data-class': 'ContentBlock',
})`
  ${divFlex}
  background-color: rgba(255, 255, 255, 1);
  border-radius: 3px;
  border: 1px solid rgba(234, 238, 248, 1);
  height: 100%;
  flex-direction: column;

  padding: 16px;
  background-color: ${({ theme }) => theme.themesColors.neutralBranding1};
`;
