import React, { FC } from 'react';

import { BillOfLadingType } from 'shipment-operations/constants';

import {
  Footer,
  BillOfLadingCopiesDistribution,
  BillOfLadingOriginalsDistribution,
  BillOfLadingTypeSelect,
} from './components';
import {
  Form,
  Spinner,
  SpinnerWrap,
  BillOfLadingDistributions,
} from './EditBillOfLading.styled';

interface EditBillOfLadingComponentProps {
  isPending: boolean;
  billOfLadingType?: BillOfLadingType;
}

export const EditBillOfLadingComponent: FC<EditBillOfLadingComponentProps> = ({
  isPending,
  billOfLadingType,
}) => (
  <Form>
    {isPending ? (
      <SpinnerWrap>
        <Spinner />
      </SpinnerWrap>
    ) : (
      <>
        <BillOfLadingTypeSelect />
        <BillOfLadingDistributions>
          {billOfLadingType !== BillOfLadingType.EBL && <BillOfLadingOriginalsDistribution />}
          <BillOfLadingCopiesDistribution />
        </BillOfLadingDistributions>

        <Footer />
      </>
    )}
  </Form>
);
