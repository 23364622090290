import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Badge from 'antd/es/badge';
import PlusOutlined from '@ant-design/icons/PlusOutlined';

import { colors } from 'app-wrapper/view/themes/themesColors';
import { PermissionAttributePolicy } from 'shipment-operations/constants';
import { Button } from 'app-wrapper/view/components';
import { SubPageHeaderContainer, SubPageHeaderTitle } from 'app-wrapper/view/guideline';

import { CargoTitleLeftPart } from './CargoTitle.styled';

const BADGE_STYLE = {
  backgroundColor: 'transparent',
  borderColor: colors.gray43,
  color: 'black',
};

interface ICargoTitleComponentProps {
  amountOfCargos: number
  addCargo: () => void
  cargosAvailability?: PermissionAttributePolicy
}

export const CargoTitleComponent: FC<ICargoTitleComponentProps> = ({
  amountOfCargos,
  addCargo,
  cargosAvailability,
}) => {
  const { t } = useTranslation();

  const isDisabled = useMemo(() => cargosAvailability !== PermissionAttributePolicy.WRITE, [cargosAvailability]);

  return (
    <SubPageHeaderContainer>
      <CargoTitleLeftPart>
        <SubPageHeaderTitle>
          {t('Cargo')}
        </SubPageHeaderTitle>
        <Badge count={amountOfCargos} style={BADGE_STYLE} size="default" />
      </CargoTitleLeftPart>
      <Button
        disabled={isDisabled}
        icon={<PlusOutlined />}
        size="middle"
        onClick={addCargo}
      >
        {t('Add Cargo')}
      </Button>
    </SubPageHeaderContainer>
  );
};
