import styled from 'styled-components';
import Typography from 'antd/es/typography';
import Spin from 'antd/es/spin';

import { SelectSearch } from 'app-wrapper/view/components';
import { divFlex } from 'app-wrapper/view/themes/mixin';

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  padding: 24px;
`;

export const Title = styled(Typography)`
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 22px;
  font-weight: 500;
`;

export const Subtitle = styled(Typography)`
  margin-top: 10px;
  margin-bottom: 4px;
  font-size: 12px;
  line-height: 20px;
`;

export const Spinner = styled(Spin)`
  margin: auto;
`;

export const Select = styled(SelectSearch)`
  ${divFlex}
  min-width: 330px;
  width: 100%;
  height: 32px;
  min-height: 32px;

  div.ant-select-selector, input {
    min-height: 32px !important;
    height: 32px !important;
  }

  .ant-select-selection-placeholder {
    font-size: 12px;
    font-weight: 400;
    color: ${({ theme }) => theme?.themesColors?.secondaryDot45};
  }

  .ant-select-selection-item > div, .ant-select-selection-placeholder {
    line-height: 32px !important;
  }
`;
