import React from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';
import { UC } from 'shipment-operations/controllers';

import { AdditionalDocumentUploadComponent } from './AdditionalDocumentUpload.component';

const AdditionalDocumentUploadContainer = () => {
  const type = useSelector(R.selectors.shipmentDocumentsAll.getAdditionalType);

  return (
    <AdditionalDocumentUploadComponent
      additionalType={type}
      setAdditionalType={UC.shipmentDocumentsAll.setAdditionalDocType}
    />
  );
};

export { AdditionalDocumentUploadContainer as AdditionalDocumentUpload };
