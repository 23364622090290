import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'app-wrapper/createSlice';
import { REQUEST_STATUS } from 'app-wrapper/constants';

import {
  IShipmentDocumentDefaultStateDocumentsDTM,
  ShipmentDocumentDefaultStateDTM,
  IShipmentDocumentFileListDTM,
  IShipmentDocumentFileListStateDTM,
  IShipmentDocumentStateChangeDTM,
  ShipmentDocumentUpdateStateDTM,
  IShipmentDocumentUpdateStateErrorsDTM,
} from 'shipment-operations/models/dtm';

import { ShipmentDocumentInitialState } from './ShipmentDocument.store';

export const ShipmentDocument = createSlice({
  name: 'ShipmentDocument',
  initialState: ShipmentDocumentInitialState,
  reducers: {
    setChangeState: (state, action: PayloadAction<IShipmentDocumentStateChangeDTM>) => {
      const { payload } = action;

      state.fileList = {
        ...state.fileList,
        ...payload.fileList,
      };
      state.defaultState = {
        ...state.defaultState,
        ...payload.defaultState,
      };
      state.updateState = {
        ...state.updateState,
        ...payload.updateState,
      };
    },

    setUpdate: (state, action: PayloadAction<ShipmentDocumentUpdateStateDTM>) => {
      const { payload } = action;

      state.updateState = payload;
    },
    setIsUpdate: (state, action: PayloadAction<boolean>) => {
      const { payload } = action;

      state.updateState = {
        ...state.updateState,
        isUpdate: payload,
      };
    },

    setUpdateDocuments: (state, action: PayloadAction<IShipmentDocumentDefaultStateDocumentsDTM[]>) => {
      const { payload } = action;

      state.updateState = {
        ...state.updateState,
        documents: payload,
      };
    },
    setUpdateAddDocuments: (state, action: PayloadAction<IShipmentDocumentDefaultStateDocumentsDTM>) => {
      const { payload } = action;

      state.updateState.documents = [
        ...state.updateState.documents,
        payload,
      ];
    },
    setUpdateDeleteDocumentBy: (state, action: PayloadAction<number>) => {
      const { payload } = action;

      state.updateState = {
        ...state.updateState,
        documents: state.updateState.documents.filter((document, index) => index !== payload),
      };
    },
    setUpdateDocumentsFromDefaultState: (state) => {
      state.updateState = {
        notes: state.defaultState.notes,
        additionalId: state.defaultState.additionalId,
        referenceId: state.defaultState.referenceId,
        reference: state.defaultState.reference,
        isUpdate: false,
        documents: state.defaultState.documents.map((document) => ({
          documentType: document.documentType,
          uploadFiles: document.uploadFiles,
          fileName: document.fileName,
        })),
        errors: {
          documents: [],
        },
      };
    },

    setUpdateDocumentByIndex: (state, action: PayloadAction<{
      index: number
      document: IShipmentDocumentDefaultStateDocumentsDTM
    }>) => {
      const { index, document } = action.payload;

      state.updateState.documents = state.updateState.documents.map((documentItem, documentIndex) => {
        if (documentIndex === index) {
          return document;
        }
        return documentItem;
      });
    },
    setUpdateDocumentTypeByIndex: (state, action: PayloadAction<{
      index: number;
      documentType: string | null
    }>) => {
      const { index, documentType } = action.payload;

      state.updateState = {
        ...state.updateState,
        documents: state.updateState.documents.map((documentItem, documentIndex) => {
          if (documentIndex === index) {
            return {
              ...documentItem,
              documentType: documentType || null,
            };
          }

          return documentItem;
        }),
      };
    },
    setUpdateDocumentFileByIndex: (state, action: PayloadAction<{
      index: number
      documentFile: string
      fileName: string
      documentType?: string | null
    }>) => {
      const {
        index, documentFile, fileName, documentType,
      } = action.payload;

      state.updateState = {
        ...state.updateState,
        documents: state.updateState.documents.map((documentItem, documentIndex) => {
          if (documentIndex === index) {
            return {
              ...documentItem,
              documentType: documentType || documentType === null
                ? documentType
                : documentItem.documentType,
              uploadFiles: documentFile,
              fileName,
            };
          }
          return documentItem;
        }),
      };
    },

    setUpdateNotes: (state, action: PayloadAction<string>) => {
      const { payload } = action;

      state.updateState = {
        ...state.updateState,
        notes: payload,
      };
    },

    setUpdateReference: (state, action: PayloadAction<string>) => {
      const { payload } = action;

      state.updateState = {
        ...state.updateState,
        reference: payload,
      };
    },

    setUpdateFileListAll: (state, action: PayloadAction<IShipmentDocumentFileListStateDTM>) => {
      const { payload } = action;

      state.fileList = payload;
    },

    setUpdateFileList: (state, action: PayloadAction<{
      key: string
      file: IShipmentDocumentFileListDTM[]
    }>) => {
      const { key, file } = action.payload;

      state.fileList = {
        ...state.fileList,
        [key]: file,
      };
    },

    setUpdateRemoveFileListById: (state, action: PayloadAction<{
      key: string
    }>) => {
      const { key } = action.payload;
      const newState = { ...state.fileList };

      delete newState[key];

      state.fileList = { ...newState };
    },

    resetUpdateFileList: (state) => {
      state.fileList = {};
    },

    resetUpdateAllError: (state) => {
      state.updateState = {
        ...state.updateState,
        errors: { ...ShipmentDocumentInitialState.updateState.errors },
      };
    },

    setUpdateChangeErrors: (state, action: PayloadAction<IShipmentDocumentUpdateStateErrorsDTM>) => {
      const { payload } = action;

      state.updateState.errors = {
        ...state.updateState.errors,
        ...payload,
      };
    },

    // Default

    setDefault: (state, action: PayloadAction<ShipmentDocumentDefaultStateDTM>) => {
      const { payload } = action;

      state.defaultState = payload;
    },
    setDefaultIsRequest: (state, action: PayloadAction<boolean>) => {
      const { payload } = action;

      state.defaultState = {
        ...state.defaultState,
        isRequest: payload,
      };
    },
    setDefaultIsEmptyRequest: (state, action: PayloadAction<boolean>) => {
      const { payload } = action;

      state.defaultState = {
        ...state.defaultState,
        isEmptyRequest: payload,
      };
    },
    setDefaultGetAdditionalRequestStatus: (state, action: PayloadAction<REQUEST_STATUS>) => {
      const { payload } = action;

      state.defaultState = {
        ...state.defaultState,
        getAdditionalStatus: payload,
      };
    },
    setDefaultGetManualAdditionalRequestStatus: (state, action: PayloadAction<REQUEST_STATUS>) => {
      const { payload } = action;

      state.defaultState = {
        ...state.defaultState,
        getManualAdditionalStatus: payload,
      };
    },

    setDefaultDocumentsById: (state, action: PayloadAction<{
      id: number
      document: IShipmentDocumentDefaultStateDocumentsDTM
    }>) => {
      const { id, document } = action.payload;

      state.defaultState.documents[id] = document;
    },

    resetDefaultDocumentsFromState: (state) => {
      state.defaultState = {
        notes: ShipmentDocumentInitialState.defaultState.notes,
        additionalId: ShipmentDocumentInitialState.defaultState.additionalId,
        referenceId: ShipmentDocumentInitialState.defaultState.referenceId,
        reference: ShipmentDocumentInitialState.defaultState.reference,
        isEmptyRequest: ShipmentDocumentInitialState.defaultState.isEmptyRequest,
        isRequest: ShipmentDocumentInitialState.defaultState.isRequest,
        getAdditionalStatus: ShipmentDocumentInitialState.defaultState.getAdditionalStatus,
        getManualAdditionalStatus: ShipmentDocumentInitialState.defaultState.getManualAdditionalStatus,
        documents: ShipmentDocumentInitialState.defaultState.documents.map((document) => ({
          documentType: document.documentType,
          uploadFiles: document.uploadFiles,
          fileName: document.fileName,
        })),
      };
    },
  },
});

export const shipmentDocumentReducer = ShipmentDocument.reducer;
export const shipmentDocumentActions = ShipmentDocument.actions;
