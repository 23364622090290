import React from 'react';
import { useSelector } from 'react-redux';

import { R } from 'user-management/repository';
import { UC as shipmentOperationsUC } from 'shipment-operations/controllers';
import { UC } from 'user-management/controllers';
import { UC as appUC } from 'app-wrapper/controllers';

import { DocumentsComponent } from './Documents.component';

const DocumentsContainer = () => {
  const documentsTypesMap = useSelector(R.selectors.customer.getDocumentsTypesMap);
  const isOrgAdmin = useSelector(R.selectors.customer.getIsOrganizationAdmin);
  const organizationId = useSelector(R.selectors.customer.getOrganizationId);
  const getOrganization = useSelector(R.selectors.customer.getOrganization);
  const userManagementPermissions = useSelector(R.selectors.moduleFunctionalityPermissions.getUserManagementPermissions);
  const isBLICRequired = useSelector(R.selectors.customer.getShouldRequireBLICDocument);

  const isTermsServiceShow = userManagementPermissions?.documentsUserTermsOfService && getOrganization?.isTermsAndConditionAccepted();

  const disableDelete = userManagementPermissions?.organizationDeletedDocumentsDetails;

  return (
    <DocumentsComponent
      openDocumentUploadDrawer={shipmentOperationsUC.shipmentDrawer.openOrganizationDocumentUploadDrawer}
      documentTypesMap={documentsTypesMap}
      onDownload={UC.customer.downloadDocumentById}
      onDelete={UC.customer.deleteDocumentWithUpdate}
      isOrgAdmin={isOrgAdmin}
      disableDelete={disableDelete}
      organizationId={organizationId}
      isBLICRequired={isBLICRequired}
      isTermsServiceShow={isTermsServiceShow}
      customerOrganization={getOrganization || undefined}
      fetchTermsData={appUC.agreements.fetchDataBeforePrinting}
    />
  );
};

export { DocumentsContainer as Documents };
