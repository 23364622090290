import React from 'react';
import { useSelector } from 'react-redux';

import { R } from 'user-management/repository';

import { TeamMembersComponent } from './TeamMembers.component';

export const TeamMembers = () => {
  const team = useSelector(R.selectors.teams.getTeam);
  const teamMembers = useSelector(R.selectors.teams.getTeamMembers);

  return team ? (
    <TeamMembersComponent teamMembers={teamMembers} />
  ) : null;
};
