import i18n from 'i18next';

import { ERateTypes } from './RateTypes.enum';

export const RateNames = {
  [ERateTypes.OCEAN_BASE_RATE]: i18n.t('Ocean Base Rate'),
  [ERateTypes.OCEAN_SURCHARGES]: i18n.t('Ocean Surcharges'),
  [ERateTypes.SERVICE_RATES]: i18n.t('Service Rates'),
  [ERateTypes.FEES]: i18n.t('Fees'),
  [ERateTypes.PENALTIES_AND_FREE_TIME]: i18n.t('Penalties and Free Time'),
};
