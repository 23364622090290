export const IN_PROGRESS = 'IN_PROGRESS';
export const NOT_ACHIEVED = 'NOT_ACHIEVED';
export const PASSED = 'PASSED';

export const FREE_DATE = 'FREE_DATE';
export const DEFINED_DATE = 'DEFINED_DATE';

export const DEPARTURE = 'DEPARTURE';
export const ARRIVAL = 'ARRIVAL';

export const DEPARTURE_CODES = ['VD', 'OA', 'RL'];
export const ARRIVAL_CODES = ['AR', 'VA', 'D'];

export const EXPECTED = 'EXPECTED';
export const ACTUAL = 'ACTUAL';

export const TERMINAL_CUTOFF = 'terminalCutOff';
export const VGM_CUTOFF = 'vgmCutOff';
export const DOCUMENT_CUTOFF = 'documentCutOff';

export const MISSING_DATES_SEARCH_PARAM = 'missingDates';

export const N_A = 'N/A';
