import React, { FC } from 'react';
import Skeleton from 'antd/es/skeleton';
import { useTranslation } from 'react-i18next';

import { PermissionAttributePolicy } from 'shipment-operations/constants';
import {
  AirVentField,
  GensetField,
  TemperatureField,
} from 'shipment-operations/view/pages/ShipmentContainers/components/ShipmentContainersHeader/components';

import {
  TemperatureControl,
  TemperatureControlContent,
  TemperatureControlHeader,
} from './TemperatureControl.styled';

interface TemperatureControlComponentProps {
  isUpdatePending: boolean;
}

export const TemperatureControlComponent: FC<TemperatureControlComponentProps> = ({ isUpdatePending }) => {
  const { t } = useTranslation();

  return isUpdatePending ? <Skeleton /> : (
    <TemperatureControl>
      <TemperatureControlHeader>
        {t('Temperature Control')}
      </TemperatureControlHeader>

      <TemperatureControlContent>
        <TemperatureField containersAvailability={PermissionAttributePolicy.WRITE} />
        <AirVentField containersAvailability={PermissionAttributePolicy.WRITE} />
        <GensetField isBookingRoute />
      </TemperatureControlContent>
    </TemperatureControl>
  );
};
