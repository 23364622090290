export enum EOrganizationIndustry {
  AEROSPACE = 'AEROSPACE',
  AGRICULTURE_FORESTRY = 'AGRICULTURE_FORESTRY',
  AUTOMOTIVE = 'AUTOMOTIVE',
  CHEMICALS = 'CHEMICALS',
  CONSTRUCTION_BUILDING_MATERIALS = 'CONSTRUCTION_BUILDING_MATERIALS',
  CONSUMER_GOODS = 'CONSUMER_GOODS',
  DEFENSE = 'DEFENSE',
  EDUCATION_TRAINING = 'EDUCATION_TRAINING',
  ELECTRONICS_TECHNOLOGY = 'ELECTRONICS_TECHNOLOGY',
  ENERGY_UTILITIES = 'ENERGY_UTILITIES',
  ENVIRONMENTAL_WASTE_SERVICES = 'ENVIRONMENTAL_WASTE_SERVICES',
  FINANCIAL_SERVICES = 'FINANCIAL_SERVICES',
  FOOD_BEVERAGE = 'FOOD_BEVERAGE',
  GENERAL_TRADE = 'GENERAL_TRADE',
  GOVERNMENT = 'GOVERNMENT',
  HEALTHCARE_PHARMACEUTICALS = 'HEALTHCARE_PHARMACEUTICALS',
  INDUSTRIAL_GOODS_MACHINERY = 'INDUSTRIAL_GOODS_MACHINERY',
  LEGAL_PROFESSIONAL_SERVICES = 'LEGAL_PROFESSIONAL_SERVICES',
  MARITIME_SHIPPING = 'MARITIME_SHIPPING',
  MINING_METALS = 'MINING_METALS',
  PAPER_PACKAGING = 'PAPER_PACKAGING',
  PUBLISHING_MEDIA = 'PUBLISHING_MEDIA',
  REAL_ESTATE = 'REAL_ESTATE',
  RECREATIONAL_GOODS = 'RECREATIONAL_GOODS',
  RESEARCH_DEVELOPMENT = 'RESEARCH_DEVELOPMENT',
  RETAIL = 'RETAIL',
  SPORTS_LEISURE = 'SPORTS_LEISURE',
  TECHNOLOGY_HARDWARE_SOFTWARE = 'TECHNOLOGY_HARDWARE_SOFTWARE',
  TELECOMMUNICATIONS = 'TELECOMMUNICATIONS',
  TEXTILES_APPAREL = 'TEXTILES_APPAREL',
  TRANSPORTATION_LOGISTICS = 'TRANSPORTATION_LOGISTICS',
  TRAVEL_TOURISM = 'TRAVEL_TOURISM',
  OTHER = 'OTHER',
}

export const OrganizationIndustryArray: EOrganizationIndustry[] = [
  EOrganizationIndustry.AEROSPACE,
  EOrganizationIndustry.AGRICULTURE_FORESTRY,
  EOrganizationIndustry.AUTOMOTIVE,
  EOrganizationIndustry.CHEMICALS,
  EOrganizationIndustry.CONSTRUCTION_BUILDING_MATERIALS,
  EOrganizationIndustry.CONSUMER_GOODS,
  EOrganizationIndustry.DEFENSE,
  EOrganizationIndustry.EDUCATION_TRAINING,
  EOrganizationIndustry.ELECTRONICS_TECHNOLOGY,
  EOrganizationIndustry.ENERGY_UTILITIES,
  EOrganizationIndustry.ENVIRONMENTAL_WASTE_SERVICES,
  EOrganizationIndustry.FINANCIAL_SERVICES,
  EOrganizationIndustry.FOOD_BEVERAGE,
  EOrganizationIndustry.GENERAL_TRADE,
  EOrganizationIndustry.GOVERNMENT,
  EOrganizationIndustry.HEALTHCARE_PHARMACEUTICALS,
  EOrganizationIndustry.INDUSTRIAL_GOODS_MACHINERY,
  EOrganizationIndustry.LEGAL_PROFESSIONAL_SERVICES,
  EOrganizationIndustry.MARITIME_SHIPPING,
  EOrganizationIndustry.MINING_METALS,
  EOrganizationIndustry.PAPER_PACKAGING,
  EOrganizationIndustry.PUBLISHING_MEDIA,
  EOrganizationIndustry.REAL_ESTATE,
  EOrganizationIndustry.RECREATIONAL_GOODS,
  EOrganizationIndustry.RESEARCH_DEVELOPMENT,
  EOrganizationIndustry.RETAIL,
  EOrganizationIndustry.SPORTS_LEISURE,
  EOrganizationIndustry.TECHNOLOGY_HARDWARE_SOFTWARE,
  EOrganizationIndustry.TELECOMMUNICATIONS,
  EOrganizationIndustry.TEXTILES_APPAREL,
  EOrganizationIndustry.TRANSPORTATION_LOGISTICS,
  EOrganizationIndustry.TRAVEL_TOURISM,
  EOrganizationIndustry.OTHER,
];
