import styled from 'styled-components';

import { FlexCol, FlexRow } from 'app-wrapper/view/guideline';

export const Section = styled(FlexCol)`
  padding: 16px;
  border-radius: 3px;
  border: 1px solid ${({ theme }) => theme.themesColors.neutralBranding4};
`;

export const SectionHeader = styled(FlexRow)`
  justify-content: space-between;
  margin-bottom: 16px;
`;

export const Title = styled.span`
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
`;
