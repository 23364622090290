import styled from 'styled-components';
import Typography from 'antd/es/typography';

import { colors } from 'app-wrapper/view/themes/themesColors';

export const ReferencesLayout = styled.div.attrs({
  'data-class': 'ShipmentOverview_ReferencesLayout',
})`
  height: max-content;
  padding: 16px;

  background: white;
  border: 1px solid ${colors.purple15};
  border-radius: 3px;
`;

export const ReferencesContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: 8px;
`;

export const ReferenceContainer = styled.div.attrs((props: {
  'data-class'?: string
}) => ({
  'data-class': props?.['data-class'] || 'ShipmentOverview_ReferenceContainer',
}))`
  display: flex;
  flex-direction: column;
  gap: 0px;

  > .ant-typography {
    > .ant-typography-copy, .anticon.anticon-edit {
      color: ${colors.gray61} !important;
    }
  }

  svg {
    height: 16px;
  }
`;

export const ReferenceLabel = styled(Typography.Text)`
  font-size: 12px !important;
  color: ${colors.gray61};
`;

export const Title = styled.span`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
`;
