import React, {
  FC, useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { CollapseProps } from 'antd/es/collapse';

import { ExpandIcon, Option } from 'app-wrapper/view/components';
import { useCollapseOnChangeHandler, useWindowSize } from 'app-wrapper/hooks';
import themesColors from 'app-wrapper/view/themes/themesColors';
import { QUOTAS_STATUS, REQUEST_STATUS } from 'app-wrapper/constants';

import { IDefaultFieldErrors } from 'monetary/models/errors';
import { IFreightSelectFieldDTM } from 'monetary/models/dtm';

import {
  CInfCollapse,
  CInfCompany,
  CInfCompanyContent,
  CInfCompanyEndSpan,
  CInfCompanyName,
  CInfCompanySelect,
  CInfCompanySelectWrapper,
  CInfPanel,
  CInfPanelTitle,
  CInfPanelTitleWrap,
  CInfWrapper,
} from './ClientInformation.styled';

interface IClientInformationComponentProps {
  formLocalState: { isCollapsed?: boolean };
  forceCollapse?: boolean;
  companyNameValue: string | null
  companyNameData: IFreightSelectFieldDTM[]
  errorsClientInformation?: IDefaultFieldErrors
  requestStatus?: string
  isAllDisabled?: boolean
  isSubmitVisited?: boolean
  updateCompanyName: (value: string) => void
  clearCompanyName: () => void
  blurCompanyName: () => void
  focusCompanyName: () => void
}

export const ClientInformationComponent: FC<IClientInformationComponentProps> = (props) => {
  const {
    formLocalState,
    forceCollapse,
    companyNameValue,
    companyNameData,
    errorsClientInformation,
    requestStatus,
    isAllDisabled,
    isSubmitVisited,
    updateCompanyName,
    clearCompanyName,
    blurCompanyName,
    focusCompanyName,
  } = props;

  const { isFullMediaWidth } = useWindowSize();
  const [isResponsive, setIsResponsive] = useState(isFullMediaWidth);
  useEffect(() => {
    if (forceCollapse) {
      setIsResponsive(false);
    } else {
      setIsResponsive(!!formLocalState?.isCollapsed);
    }
  }, [forceCollapse, formLocalState?.isCollapsed]);

  const { t } = useTranslation();
  const endVisible = useRef<HTMLElement>(null);
  const [activeKey, setActiveKey] = useState<string[]>([]);

  useEffect(() => {
    if (requestStatus === QUOTAS_STATUS.pending) {
      if (companyNameValue) {
        setActiveKey(['3']);
        return;
      }
      setActiveKey([]);
    }
  }, [requestStatus, companyNameValue]);

  const togglePanel = useCallback(
    () => {
      setActiveKey((prev) => (prev.length ? [] : ['3']));
    },
    [],
  );

  const collapseOnChangeHandler = useCollapseOnChangeHandler(endVisible.current, togglePanel);

  const hasErrorType = useMemo(() => {
    const errDocument = errorsClientInformation;

    return !!(errDocument
      && errDocument?.message
      && (errDocument?.isBlur || errDocument?.isVisited || isSubmitVisited));
  }, [errorsClientInformation, isSubmitVisited]);

  const onClearCompanyName = useCallback(
    clearCompanyName,
    [clearCompanyName],
  );

  const onBlurCompanyName = useCallback(
    blurCompanyName,
    [blurCompanyName],
  );

  const onFocusCompanyName = useCallback(
    focusCompanyName,
    [focusCompanyName],
  );

  const StyledExpandIcon = useCallback((propsIcon: CollapseProps) => {
    const { isActive } = propsIcon as { isActive: true };

    return (<ExpandIcon color={isActive && themesColors.primaryBranding6} isActive={isActive} />);
  }, [themesColors]);

  useEffect(() => {
    if (hasErrorType && isSubmitVisited) {
      setActiveKey(['3']);
      return;
    }
    if (requestStatus === REQUEST_STATUS.pending) {
      if (companyNameValue) {
        setActiveKey(['3']);
        return;
      }
      setActiveKey([]);
    }
  }, [hasErrorType, isSubmitVisited, requestStatus, companyNameValue]);

  const contentComponent = useMemo(() => (
    <>
      {isResponsive && (
        <CInfPanelTitleWrap>
          <CInfPanelTitle>
            {t('Client Information')}
          </CInfPanelTitle>
        </CInfPanelTitleWrap>
      )}

      <CInfCompanyContent isCollapse={!isResponsive}>
        <CInfCompany>
          <CInfCompanyName>
            <span>{t('Company name')}</span>
          </CInfCompanyName>
        </CInfCompany>

        <CInfCompanySelectWrapper>
          <CInfCompanySelect
            value={companyNameValue}
            showSearch
            allowClear={!!companyNameValue}
            hasError={hasErrorType}
            onChange={updateCompanyName}
            onClear={onClearCompanyName}
            onBlur={onBlurCompanyName}
            onFocus={onFocusCompanyName}
            optionFilterProp="children"
            disabled={isAllDisabled}
          >
            {companyNameData.map((item) => (
              <Option value={item.code || ''} key={`CInfCompanySelect_${item.code}`}>
                {item.description}
              </Option>
            ))}
          </CInfCompanySelect>
        </CInfCompanySelectWrapper>
      </CInfCompanyContent>
    </>
  ), [companyNameValue, hasErrorType, isAllDisabled, isResponsive, companyNameData]);

  const collapseComponent = useMemo(() => (
    <CInfCollapse expandIcon={StyledExpandIcon} onChange={collapseOnChangeHandler} activeKey={activeKey}>
      <CInfPanel header={t('ClientInformation')} key="3">
        {contentComponent}
      </CInfPanel>
    </CInfCollapse>
  ), [contentComponent, activeKey]);

  return (
    <CInfWrapper>
      {!isResponsive ? (
        collapseComponent
      ) : (
        contentComponent
      )}

      <CInfCompanyEndSpan
        ref={endVisible}
      />
    </CInfWrapper>
  );
};
