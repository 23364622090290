import React, { FC } from 'react';

import {
  TooltipStyled,
  Icon,
} from './RejectionTooltip.styled';

interface IRejectionTooltipComponentProps {
  note: string;
}

const RejectionTooltipComponent: FC<IRejectionTooltipComponentProps> = ({ note }) => (
  <TooltipStyled
    placement="bottom"
    title={note}
    destroyTooltipOnHide
  >
    <Icon />
  </TooltipStyled>
);

export { RejectionTooltipComponent as RejectionTooltip };
