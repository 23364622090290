import React, { FC, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Switch from 'antd/es/switch';
import Alert from 'antd/es/alert';
import { useParams } from 'react-router-dom';

import { useOnclickOutsideHook } from 'app-wrapper/hooks';
import { TableSkeleton } from 'app-wrapper/view/components';
import {
  Additional,
  Dashboard,
  Fees,
  Services,
  Transportation,
} from 'shipment-operations/view/pages/ShipmentTransportationCharges/components';

import { PermissionAttributePolicy } from 'shipment-operations/constants';
import {
  Header,
  Main,
  MainContainer,
  StyledButton,
  Title,
  ToggleTitle,
  Wrapper,
} from './ShipmentTransportationCharges.styled';

interface IShipmentTransportationChargesComponentProps {
  fetchData: (shipmentId?: string) => void
  onToggle: (status: boolean) => void
  isLoading: boolean
  isError: boolean
  isPercentage: boolean
  successCreation: boolean
  closeDrawer: () => void
  onLeave: () => void
  onClearSuccess: () => void
  successDelete: boolean
  onClearDelete: () => void
  permissions?: PermissionAttributePolicy
}

const ShipmentTransportationChargesComponent: FC<IShipmentTransportationChargesComponentProps> = ({
  fetchData, isLoading, isError, isPercentage, onToggle, successCreation,
  closeDrawer, onLeave, onClearSuccess, successDelete, permissions, onClearDelete,
}) => {
  const { t } = useTranslation();
  const { shipmentId } = useParams<'shipmentId'>();

  const handleFetchData = useCallback(() => {
    fetchData(shipmentId);
  }, []);

  useEffect(() => {
    fetchData(shipmentId);
  }, [shipmentId, fetchData]);

  useEffect(() => {
    if (successCreation) {
      closeDrawer();
      fetchData(shipmentId);
    }
  }, [successCreation]);

  useEffect(() => {
    if (successDelete) {
      fetchData(shipmentId);
      onClearDelete();
    }
  }, [successDelete]);

  useEffect(() => () => onLeave(), []);

  const ref = useOnclickOutsideHook(() => onClearSuccess(), {});

  if (isLoading) {
    return (
      <TableSkeleton />
    );
  }

  return (
    <Main>
      <Wrapper>
        {isError ? (
          <Alert
            message={t('creditNote.errorMsg')}
            description={t('creditNote.errorDescription')}
            type="error"
            action={(
              <StyledButton size="middle" type="primary" onClick={handleFetchData}>
                {t('reload')}
              </StyledButton>
            )}
          />
        ) : (
          <>
            <div ref={ref}>
              {successCreation && (
                <Alert type="success" message={t('Charge was successfully created')} style={{ marginBottom: '20px' }} />
              )}
            </div>
            {permissions === PermissionAttributePolicy.WRITE && (
              <div style={{ marginBottom: '20px' }}>
                <Dashboard />
              </div>
            )}
            <MainContainer>
              <Header>
                <Title>{t('Charges')}</Title>
                <div>
                  <Switch size="small" checkedChildren="%" unCheckedChildren="$" checked={isPercentage} onClick={onToggle} />
                  <ToggleTitle>{t('shipmentOperation.profit')}</ToggleTitle>
                </div>
              </Header>
              <Transportation />
              <Services />
              <Fees />
              <Additional />
            </MainContainer>
          </>
        )}
      </Wrapper>
    </Main>
  );
};

export { ShipmentTransportationChargesComponent };
