import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 16px 24px;
  position: relative;
  height: 100%;
`;

export const Name = styled.span`
  font-size: 12px;
  color: #202C3C;
`;

export const Field = styled.div`
  margin: 16px 0;
`;

export const Footer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 52px;
  background: var(--neutral-1, #FFF);
  box-shadow: 0 1px 0 0 #F0F0F0 inset;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px;
`;

export const BtnWrapper = styled.div`
  display: flex;
`;
