import {
  IsEnum,
  IsOptional,
} from 'class-validator';
import { BaseDTM } from 'proto/BaseDTM';

import { PermissionAttributePolicy } from 'shipment-operations/constants';

export type TShipmentBillingInvoicePayablesPermissionsDtm = {
  makePaymentButton?: PermissionAttributePolicy;
}

export class ShipmentBillingInvoicePayablesPermissionsDtm extends BaseDTM<TShipmentBillingInvoicePayablesPermissionsDtm> {
  @IsOptional()
  @IsEnum(PermissionAttributePolicy)
  makePaymentButton?: PermissionAttributePolicy;
}
