import message from 'antd/es/message';
import i18n from 'i18next';

import { DateDtm } from 'app-wrapper/models/dtm';

import { R } from 'monetary/repository';
import { ECarrierSCAC, ERateTypes } from 'monetary/constants';
import { BaseController, controller } from 'proto/BaseController';

@controller
export class RatesModalController extends BaseController {
  searchOriginLocationTimeoutId: number | undefined = undefined

  searchDestinationLocationTimeoutId: number | undefined = undefined

  downloadRates = async () => {
    this.validateDate();

    this.dispatch(R.actions.ratesModal.setLoading(true));

    const date = R.selectors.ratesModal.getDate(this.store.getState());
    const rateType = R.selectors.ratesModal.getRateType(this.store.getState());
    const carrierScacList = R.selectors.ratesModal.getCarrierScacList(this.store.getState());

    const isFormDataValid = R.selectors.ratesModal.isFormDataValid(this.store.getState());

    if (
      !isFormDataValid || (
        !date
        || !carrierScacList
      )
    ) {
      return;
    }

    try {
      if (rateType === ERateTypes.SERVICE_RATES) {
        await R.services.rate.downloadServiceRate(date, carrierScacList);
      }

      if (rateType === ERateTypes.FEES) {
        await R.services.rate.downloadFeesRate(date, carrierScacList);
      }

      if (rateType === ERateTypes.PENALTIES_AND_FREE_TIME) {
        await R.services.rate.downloadPenaltiesRate(date, carrierScacList);
      }
    } catch (e) {
      message.error(i18n.t('Something went wrong'));

      this.dispatch(R.actions.ratesModal.setLoading(false));

      return;
    }

    this.dispatch(R.actions.ratesModal.setOpen(false));
    this.dispatch(R.actions.ratesModal.clear());

    message.success(i18n.t('File downloaded'));
  }

  openModal = (rateType: ERateTypes) => {
    this.dispatch(R.actions.ratesModal.setRateType(rateType));
    this.dispatch(R.actions.ratesModal.setOpen(true));
  }

  closeModal = () => {
    this.dispatch(R.actions.ratesModal.setOpen(false));
    this.dispatch(R.actions.ratesModal.clear());
  }

  setDate = (date: DateDtm | null) => {
    if (!date) {
      this.dispatch(R.actions.ratesModal.clearDate());

      return;
    }

    this.dispatch(R.actions.ratesModal.setDate(date));
  }

  blurEffectiveDateField = () => {
    this.validateDate();
  }

  setCarrierSCACList = (carrierScacList: ECarrierSCAC[]) => {
    this.dispatch(R.actions.ratesModal.setCarrierScacList(carrierScacList));
  }

  private validateDate = () => {
    const effectiveDate = R.selectors.ratesModal.getDate(this.store.getState());

    this.dispatch(R.actions.ratesModal.clearDateError());

    if (!effectiveDate) {
      this.dispatch(R.actions.ratesModal.setDateError(new Error(i18n.t('basicErrors.REQUIRED_MESSAGE'))));
    }
  }
}
