import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';

import { GensetConfirmationComponent } from './GensetConfirmation.component';

interface IGensetConfirmationContainerProps {
  isBookingRoute?: boolean
}

const GensetConfirmationContainer: FC<IGensetConfirmationContainerProps> = ({ isBookingRoute }) => {
  const withGenset = useSelector(R.selectors.bookingWizard.getIsWithGensetCharges);
  const withGensetShipment = useSelector(R.selectors.temperatureControl.getIsWithGensetCharges);
  const gensetSum = useSelector(R.selectors.bookingWizard.getSumOfGenset);
  const gensetSumShipment = useSelector(R.selectors.temperatureControl.getSumOfGenset);
  const { genset } = useSelector(R.selectors.temperatureControl.getTemperatureControl);

  return (
    <GensetConfirmationComponent
      withGenset={isBookingRoute ? withGenset : withGensetShipment}
      gensetSum={isBookingRoute ? gensetSum : gensetSumShipment}
      isCheckedGenset={genset}
    />
  );
};

export {
  GensetConfirmationContainer as GensetConfirmation,
};
