import styled from 'styled-components';
import AntPageHeader from 'antd/es/page-header';

export const PageHeader = styled(AntPageHeader).attrs({
  'data-class': 'page-header',
})`
  background-color: #FFF;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 0;
  height: 56px;
  max-width: 1660px;
  margin: 0 auto;
  width: 100%;

  span {
    font-weight: 500;
  }
`;
