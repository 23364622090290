import {
  IsString, IsDefined, IsBoolean, IsOptional,
} from 'class-validator';
import { BaseDTM } from 'proto/BaseDTM';

export interface ICommodityDTM {
  code: string
  name: string
  forbidden?: boolean
}

export class CommodityDTM extends BaseDTM<ICommodityDTM> {
  @IsDefined()
  @IsString()
  code: string;

  @IsDefined()
  @IsString()
  name: string;

  @IsOptional()
  @IsBoolean()
  forbidden?: boolean;
}
