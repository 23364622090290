import { action, observable } from 'mobx';

export abstract class BaseMobxStore<T> {
  @observable
  initialState: T

  @observable
  state: T

  @action
  init(initialState: T) {
    this.state = initialState;
  }

  @action
  clear() {
    this.state = { ...this.initialState };
  }
}

// export interface IPaginationState<T> {
//   isLoading: boolean;
//   items: T[];
// }
//
// export abstract class PaginationStore<T extends IPaginationState<T>> extends BaseMobxStore<IPaginationState<T>>{
//   constructor(_initialState?: T) {
//     super();
//     makeObservable(this);
//     this.init(_initialState || this.initialState);
//   }
//
//   @action
//   setLoading(isLoading: boolean) {
//     this.state.isLoading = isLoading;
//   }
//
//   @action
//   addItems(items: T[]) {
//     this.state.items = items;
//
//     const requestsIds = items?.reduce((prev: string[], item) => {
//       prev.push(String(item.id));
//       return prev;
//     }, []) || [];
//
//     this.state.items = [
//       ...items,
//       ...this.state.items
//         ?.filter((itemContent) => !requestsIds
//           .includes(`${itemContent.id}`)) || [],
//     ];
//   }
// }
