import { freightQuoteReducer, freightQuoteActions } from './FreightQuote/FreightQuote.store';
import { FreightQuoteSelectors } from './FreightQuote';

import { invoicesReducer, invoicesActions, invoicesSelectors } from './Invoices';
import { oceanRatesModalReducer, oceanRatesModalActions, oceanRatesModalSelectors } from './OceanRatesModal';
import { paymentsReducer, paymentsActions, paymentsSelectors } from './Payments';
import { ratesModalReducer, ratesModalActions, ratesModalSelectors } from './RatesModal';
import { ratesReducer, ratesActions, ratesSelectors } from './Rates';
import { moduleFunctionalityPermissionsActions, moduleFunctionalityPermissionsReducer, moduleFunctionalityPermissionsSelectors } from './ModuleFunctionalityPermissions';
import { surchargesRatesModalReducer, surchargesRatesModalActions, surchargesRatesModalSelectors } from './SurchargesRatesModal';
import { fakExceptionSelectors, FAKExceptionActions, FAKExceptionReducer } from './FAKException';

export const actions = {
  freightQuoteActions,

  invoices: invoicesActions,
  oceanRatesModal: oceanRatesModalActions,
  payments: paymentsActions,
  rates: ratesActions,
  ratesModal: ratesModalActions,
  moduleFunctionalityPermissions: moduleFunctionalityPermissionsActions,
  surchargesRatesModal: surchargesRatesModalActions,
  fakException: FAKExceptionActions,
};

export const reducers = {
  freightQuoteReducer,

  invoices: invoicesReducer,
  oceanRatesModal: oceanRatesModalReducer,
  payments: paymentsReducer,
  rates: ratesReducer,
  ratesModal: ratesModalReducer,
  moduleFunctionalityPermissions: moduleFunctionalityPermissionsReducer,
  surchargesRatesModal: surchargesRatesModalReducer,
  fakException: FAKExceptionReducer,
};

export const selectors = {
  freightQuote: FreightQuoteSelectors,
  invoices: invoicesSelectors,
  oceanRatesModal: oceanRatesModalSelectors,
  payments: paymentsSelectors,
  rates: ratesSelectors,
  ratesModal: ratesModalSelectors,
  moduleFunctionalityPermissions: moduleFunctionalityPermissionsSelectors,
  surchargesRatesModal: surchargesRatesModalSelectors,
  fakException: fakExceptionSelectors,
};
