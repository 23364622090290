import React, { FC } from 'react';

import { Section } from './TermsAndConditions.styled';
import { NRAAgreement, SkypaceTermsAndConditions } from './components';

interface TermsAndConditionsComponentProps {
  isUsShipmentOriginOrDestination: boolean
}

export const TermsAndConditionsComponent: FC<TermsAndConditionsComponentProps> = ({ isUsShipmentOriginOrDestination }) => (
  <Section>
    {isUsShipmentOriginOrDestination ? <NRAAgreement /> : null}

    <SkypaceTermsAndConditions />
  </Section>
);
