import { plainToInstance } from 'class-transformer';
import { IsNumber, IsDefined } from 'class-validator';

type TCommonNetworkErrorInput = {
  status: number
  message: string
}

export class CommonNetworkError {
  @IsNumber()
  @IsDefined()
  status: number;

  @IsDefined()
  message: string;

  static fromPlain = (input: TCommonNetworkErrorInput) => plainToInstance(CommonNetworkError, input);
}
