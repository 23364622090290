import React, {
  FC, memo, useCallback, useEffect, useMemo, useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { REQUEST_STATUS } from 'app-wrapper/constants';

import { OrganizationDTM } from 'user-management/models/dtm';

import {
  SubPageContent,
  SubPageHeader,
  SubPageHeaderContainer,
  SubPageHeaderTitle,
  Wrapper,
} from 'app-wrapper/view/guideline';
import { Button, EmptyStateForPages } from 'app-wrapper/view/components';
import PlusOutlined from '@ant-design/icons/PlusOutlined';
import { CustomerContentTable, CustomerNotVerifiedTable } from './components';
import {
  UMCContent,
  UMCWrapTabsLink,
  UMCWrapLinkText,
  UMCTextAlert,
} from './CustomerList.styled';

interface ICustomersComponentProps {
  isAdmin: boolean;
  onResetInternalPage: () => void;
  onStartInternal: () => void;
  onEndInternal: () => void;
  nextOrganizationActive: () => void;
  nextOrganizationWaitingApproval: () => void;
  nextOrganizationReject: () => void;
  onClickCustomerInternal: (id: string) => void;
  onClickCustomerAddInternal: () => void;
  isLoadInternal?: string;
  isLoadAddInternal?: string;
  organizationWaitingApproval?: OrganizationDTM[];
  organizationReject?: OrganizationDTM[];
  allDataInternal?: OrganizationDTM[];
  isEndOrganizationActive?: boolean;
  isEndOrganizationWaitingApproval?: boolean;
  isEndOrganizationReject?: boolean;
}

const CustomersListComponent: FC<ICustomersComponentProps> = (props) => {
  const {
    isAdmin,
    onResetInternalPage,
    onStartInternal,
    onEndInternal,
    nextOrganizationActive,
    nextOrganizationWaitingApproval,
    nextOrganizationReject,
    onClickCustomerInternal,
    onClickCustomerAddInternal,
    isLoadInternal,
    isLoadAddInternal,
    organizationWaitingApproval,
    organizationReject,
    allDataInternal,
    isEndOrganizationActive,
    isEndOrganizationWaitingApproval,
    isEndOrganizationReject,
  } = props;
  const { t } = useTranslation();
  const [activeTabCustomer, setActiveTabCustomer] = useState<number>(0);

  useEffect(() => {
    const foo = async () => {
      onResetInternalPage();

      onStartInternal();
    };

    foo();

    return onEndInternal;
  }, []);

  const handleOnClickActiveTabCustomerActive = useCallback(
    () => {
      setActiveTabCustomer(0);
    },
    [setActiveTabCustomer],
  );

  const handleOnClickActiveTabCustomerWaitingApproval = useCallback(
    () => {
      setActiveTabCustomer(1);
    },
    [setActiveTabCustomer],
  );

  const handleOnClickActiveTabCustomerReject = useCallback(
    () => {
      setActiveTabCustomer(2);
    },
    [setActiveTabCustomer],
  );

  const getOrganizations = useMemo(() => {
    if (activeTabCustomer === 0) {
      return allDataInternal;
    }

    if (activeTabCustomer === 1) {
      return organizationWaitingApproval;
    }

    if (activeTabCustomer === 2) {
      return organizationReject;
    }

    return undefined;
  }, [activeTabCustomer, allDataInternal, organizationWaitingApproval, organizationReject]);

  const getIsOrganizationsEnd = useMemo(() => {
    if (activeTabCustomer === 0) {
      return isEndOrganizationActive;
    }

    if (activeTabCustomer === 1) {
      return isEndOrganizationWaitingApproval;
    }

    if (activeTabCustomer === 2) {
      return isEndOrganizationReject;
    }

    return false;
  }, [activeTabCustomer, isEndOrganizationActive, isEndOrganizationWaitingApproval, isEndOrganizationReject]);

  const getIsOrganizationsNextPage = useMemo(() => {
    if (activeTabCustomer === 0) {
      return nextOrganizationActive;
    }

    if (activeTabCustomer === 1) {
      return nextOrganizationWaitingApproval;
    }

    if (activeTabCustomer === 2) {
      return nextOrganizationReject;
    }

    return () => '';
  }, [activeTabCustomer, nextOrganizationActive, nextOrganizationWaitingApproval, nextOrganizationReject]);

  const getCustomerTable = useMemo(() => {
    if (activeTabCustomer === 0) {
      return isLoadInternal === REQUEST_STATUS.pending || getOrganizations?.length ? (
        <CustomerContentTable
          nextPageTasks={getIsOrganizationsNextPage}
          onClickCustomerInternal={onClickCustomerInternal}
          isLoad={isLoadInternal}
          isLoadAdd={isLoadAddInternal}
          allData={getOrganizations}
          isEnd={getIsOrganizationsEnd}
        />
      ) : (
        <EmptyStateForPages
          headerText={t('No active customers yet.')}
          contentText={t('All approve and activated customers will be displayed here. Start by creating your first customer.')}
          buttonText={t('Create customer')}
          onClickButton={onClickCustomerAddInternal}
        />
      );
    }

    if (activeTabCustomer === 1) {
      return isLoadInternal === REQUEST_STATUS.pending || getOrganizations?.length ? (
        <CustomerNotVerifiedTable
          paginate={getIsOrganizationsNextPage}
          onClickCustomer={onClickCustomerInternal}
          isPending={isLoadInternal}
          isPendingAdd={isLoadAddInternal}
          customers={getOrganizations}
          isEnd={getIsOrganizationsEnd}
        />
      ) : (
        <EmptyStateForPages
          headerText={t('You’re all caught up.')}
          contentText={t('There are no new customer registration requests that require your attention.')}
        />
      );
    }

    if (activeTabCustomer === 2) {
      return isLoadInternal === REQUEST_STATUS.pending || getOrganizations?.length ? (
        <CustomerContentTable
          nextPageTasks={getIsOrganizationsNextPage}
          onClickCustomerInternal={onClickCustomerInternal}
          isLoad={isLoadInternal}
          isLoadAdd={isLoadAddInternal}
          allData={getOrganizations}
          isEnd={getIsOrganizationsEnd}
        />
      ) : (
        <EmptyStateForPages
          headerText={t('No rejected customers.')}
          contentText={t('All rejected customers will be displayed here.')}
        />
      );
    }

    return null;
  }, [activeTabCustomer, isLoadAddInternal, isLoadInternal, getOrganizations, getIsOrganizationsEnd, onClickCustomerAddInternal, getIsOrganizationsNextPage, onClickCustomerInternal]);

  return (
    <Wrapper>
      <SubPageHeader>
        <SubPageHeaderContainer>
          <SubPageHeaderTitle>
            {t('Customers')}
          </SubPageHeaderTitle>

          <Button
            disabled={!isAdmin}
            onClick={onClickCustomerAddInternal}
            icon={<PlusOutlined />}
          >
            <span>{t('addCustomer')}</span>
          </Button>
        </SubPageHeaderContainer>
      </SubPageHeader>

      <UMCWrapTabsLink>
        <UMCWrapLinkText onClick={handleOnClickActiveTabCustomerActive} isActive={activeTabCustomer === 0}>{t('Active')}</UMCWrapLinkText>
        <UMCWrapLinkText
          data-class="customers-not-verified-tab"
          onClick={handleOnClickActiveTabCustomerWaitingApproval}
          isActive={activeTabCustomer === 1}
        >
          {t('Not Verified')}

          {organizationWaitingApproval && organizationWaitingApproval.length > 0 ? (
            <UMCTextAlert data-class="customers-non-verified-tooltip" />
          ) : null}
        </UMCWrapLinkText>
        <UMCWrapLinkText onClick={handleOnClickActiveTabCustomerReject} isActive={activeTabCustomer === 2}>{t('Rejected')}</UMCWrapLinkText>
      </UMCWrapTabsLink>

      {isLoadInternal === REQUEST_STATUS.pending || getOrganizations?.length ? (
        <SubPageContent>
          <UMCContent>
            {getCustomerTable}
          </UMCContent>
        </SubPageContent>
      ) : getCustomerTable}
    </Wrapper>
  );
};

const CustomersComponentCache = memo(CustomersListComponent);

export { CustomersComponentCache as CustomersComponent };
