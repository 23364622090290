import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { UC } from 'shipment-operations/controllers';
import { R } from 'shipment-operations/repository';
import { ChargeViewDtm } from 'shipment-operations/models/dtm';

import { CustomTable } from './CustomTable.component';

interface ICustomTableProps {
  data: ChargeViewDtm[]
  innerTable?: boolean
  subTitle?: string
}

const CustomTableContainer: FC<ICustomTableProps> = ({ data, innerTable, subTitle }) => {
  const permissions = useSelector(R.selectors.shipment.getShipmentChargesPermissions);
  const mode = useSelector(R.selectors.shipmentCharges.getTabOwner);
  const isPercentage = useSelector(R.selectors.shipmentCharges.getIsPercentage);

  return (
    <CustomTable
      data={data}
      innerTable={innerTable}
      permissions={permissions}
      onDownloadDocument={UC.shipmentActiveCharge.downloadDocument}
      mode={mode}
      subTitle={subTitle}
      isPercentage={isPercentage}
    />
  );
};

export { CustomTableContainer as CustomTable };
