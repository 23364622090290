import styled from 'styled-components';

import themesColors from 'app-wrapper/view/themes/themesColors';
import {
  Input, InputPassword, Button,
} from 'app-wrapper/view/components';
import { divFlex } from 'app-wrapper/view/themes/mixin';

export const SignUpWrapper = styled.div.attrs({
  'data-class': 'SignUpWrapper',
})`
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-around;
  align-items: center;

  width: 100%;
  height: 100%;
`;

export const SignUpForm = styled.div.attrs({
  'data-class': 'SignUpForm',
})`
  margin-top: auto;
  width: 360px;
  height: fit-content;
`;

export const LogoWrapper = styled.div.attrs({
  'data-class': 'SignUpButton',
})`
  width: fit-content;
  margin: 0 auto 12px auto;
`;

export const AlertWrap = styled.div.attrs({
  'data-class': 'AlertWrap',
})`
  ${divFlex}

  margin-bottom: 16px;

  > div {
    width: 100%;
  }
`;

export const SignUpTitle = styled.p.attrs({
  'data-class': 'SignUpTitle',
})`
  margin: auto auto 24px auto;

  color: ${themesColors.secondaryDot45};
  font-size: 14px;
  line-height: 22px;
  text-align: center;
`;

export const SignUpMessageHeader = styled.p.attrs({
  'data-class': 'SignUpMessageHeader',
})`
  margin: auto auto 4px auto;

  color: ${themesColors.neutralBranding10};
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: left;
`;

export const SignUpMessage = styled.p.attrs({
  'data-class': 'SignUpMessage',
})`
  margin: auto auto 12px auto;

  color: ${themesColors.secondaryDot45};
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  text-align: left;
`;

export const SignUpEmailInput = styled(Input).attrs({
  'data-class': 'SignUpEmailInput',
})`
  margin-bottom: 12px;
  color: ${themesColors.secondaryDot45};
  
  .ant-input {
    color: ${themesColors.secondaryDot45};
  }

  .ant-input::placeholder {
    color: ${themesColors.secondaryDot45};
  }
`;

export const SignUpPasswordInput = styled(InputPassword).attrs({
  'data-class': 'SignUpPasswordInput',
})`
  margin-bottom: 4px;
  color: ${themesColors.secondaryDot45};
  
  .ant-input {
    color: ${themesColors.secondaryDot45};
  }

  .ant-input::placeholder {
    color: ${themesColors.secondaryDot45};
  }
`;

export const SignUpButton = styled(Button).attrs({
  'data-class': 'SignUpButton',
})`
  width: 100%;
  margin-bottom: 12px;
`;

export const SignUpLinkWrapper = styled.div.attrs({
  'data-class': 'SignUpLinkWrapper',
})`
  text-align: center;

  a {
    color: ${themesColors.primaryBranding7};
  }
  
  svg {
    margin-right: 8px;
    height: 12px;
  }
`;

export const BottomMessage = styled.p.attrs({
  'data-class': 'BottomMessage',
})`
  margin: auto auto 20px auto;
  
  text-align: center;
  font-size: 14px;
  color: ${themesColors.secondaryDot45};
`;
