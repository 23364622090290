import { InvoicesResponseDTM } from 'monetary/models/dtm/Invoices.dtm';

import { R } from 'monetary/repository';
import { BaseController, controller } from 'proto/BaseController';

@controller
export class InvoicesController extends BaseController {
  fetchData = async (type: string) => {
    let response: InvoicesResponseDTM | null;

    this.dispatch(R.actions.invoices.setIsLoading(true));
    const isFirstTimeLoading = R.selectors.invoices.getFirstTimeLoading(this.store.getState());
    if (isFirstTimeLoading) {
      this.dispatch(R.actions.invoices.setFirstTimeLoading(false));
    }
    try {
      const page = R.selectors.invoices.getActivePage(this.store.getState());
      response = await R.services.invoices.getData(type, page - 1);
    } catch {
      this.dispatch(R.actions.invoices.setError(true));
      return;
    }
    this.dispatch(R.actions.invoices.setData(response));
  }

  onChangeActivePage = (page: number) => {
    this.dispatch(R.actions.invoices.onChangeActivePage(page));
  }

  onClear = () => {
    this.dispatch(R.actions.invoices.clear());
  }
}
