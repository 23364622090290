import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import i18n from 'app-wrapper/i18n/i18n';
import { EContractCategory } from 'shipment-operations/constants';
import { Field, FieldLabel } from 'app-wrapper/view/guideline';

import { Select } from 'app-wrapper/view/drawers/CreateContractDrawer/components/ContractCarrier/ContractCarrier.styled';

const OPTIONS = [
  {
    value: EContractCategory.A,
    label: i18n.t('A'),
  },
  {
    value: EContractCategory.B,
    label: i18n.t('B'),
  },
  {
    value: EContractCategory.C,
    label: i18n.t('C'),
  },
  {
    value: EContractCategory.D,
    label: i18n.t('D'),
  },
];

interface IContractCategoryComponentProps {
  category?: EContractCategory;
  setCategory: (category: EContractCategory) => void;
}

export const ContractCategoryComponent: FC<IContractCategoryComponentProps> = ({
  category,
  setCategory,
}) => {
  const { t } = useTranslation();

  return (
    <Field>
      <FieldLabel>
        {t('Contract Category')}
      </FieldLabel>

      <Select
        placeholder=""
        value={category}
        onChange={setCategory}
        options={OPTIONS}
      />
    </Field>
  );
};
