import React, {
  FC, memo, useCallback, useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';
import EyeInvisibleOutlined from '@ant-design/icons/EyeInvisibleOutlined';
import BellOutlined from '@ant-design/icons/BellOutlined';
import FilterFilled from '@ant-design/icons/FilterFilled';

import {
  EmptyStateForPages,
  PageHeader,
  TasksBlockSummaryComponents,
  TasksFilterHeaderComponent,
} from 'app-wrapper/view/components';
import {
  AppFiltersCheckboxGroupDTM,
  CommandCenterComponentTaskDTM,
  INotificationViewedFiltersDTM, NotificationTaskFilterDatesDTM,
} from 'app-wrapper/models/dtm';
import themesColors from 'app-wrapper/view/themes/themesColors';
import { BellWarningGoldSvg } from 'app-wrapper/view/icons';
import { REQUEST_STATUS } from 'app-wrapper/constants';

import {
  SubPageHeader, SubPageHeaderContainer, SubPageHeaderTitle, Wrapper,
} from 'app-wrapper/view/guideline';
import {
  CCContent,
  CCContentFilters,
  CCContentHeader,
  CCContentHeaderItem,
  CCContentStatistics,
  CCContentStatisticsBlock,
  CCWrapHeader,
} from './CommandCenter.styled';
import { ContentTableNotification } from './components';

interface ICommandCenterNotificationComponentProps {
  onResetTaskPage: () => void;
  nextPageNotifications: () => void;
  onStartNotifications: () => void;
  onHoverNotificationItem: (id: string, shipmentId?: string) => void;
  onUnviewedNotificationItem: (notificationId: string, viewId: string, shipmentId?: string) => void;
  onAllNotificationMakeViews: () => void;
  onChangeFilterShowOnlyUnread?: (value: boolean) => void
  onApplyPriority?: () => void
  onResetPriority?: () => void
  onChangeGroupPriority?: (id: number, value: AppFiltersCheckboxGroupDTM) => void
  onApplyReceivedDate?: () => void
  onResetReceivedDate?: () => void
  dateOnReceivedDateChange?: (dates: NotificationTaskFilterDatesDTM) => void
  onChangeGroupModule?: (id: number, value: AppFiltersCheckboxGroupDTM) => void
  onApplyModule?: () => void
  onResetModule?: () => void
  onClearAllFilters?: () => void
  shipmentId?: string;
  isLoadNotification?: string;
  isLoadNotificationAdd?: string;
  hideHeader?: boolean;
  isShipmentHeader?: boolean;
  allNotifications?: CommandCenterComponentTaskDTM[];
  infoCountUnread?: number;
  infoCountImportant?: number;
  infoCountRegular?: number;
  filters?: INotificationViewedFiltersDTM;
  isVisibleClearAll?: boolean;
}

const CommandCenterNotificationShipmentComponent: FC<ICommandCenterNotificationComponentProps> = (props) => {
  const {
    onResetTaskPage,
    nextPageNotifications,
    onStartNotifications,
    onHoverNotificationItem,
    onUnviewedNotificationItem,
    onAllNotificationMakeViews,
    onChangeFilterShowOnlyUnread,
    onApplyPriority,
    onResetPriority,
    onChangeGroupPriority,
    onApplyReceivedDate,
    onResetReceivedDate,
    dateOnReceivedDateChange,
    onChangeGroupModule,
    onApplyModule,
    onResetModule,
    onClearAllFilters,
    shipmentId,
    isLoadNotification,
    isLoadNotificationAdd,
    hideHeader,
    isShipmentHeader,
    allNotifications,
    infoCountUnread,
    infoCountImportant,
    infoCountRegular,
    filters,
    isVisibleClearAll,
  } = props;
  const { t } = useTranslation();

  const title = t('Notifications');

  useEffect(() => {
    onResetTaskPage();

    onStartNotifications();
  }, []);

  const onClearAllFiltersHandler = useCallback(
    () => {
      if (onClearAllFilters) {
        onClearAllFilters();
      }
    },
    [onClearAllFilters],
  );

  return (
    <>
      {!hideHeader && (
        <CCWrapHeader>
          <PageHeader title={title} />
        </CCWrapHeader>
      )}

      <Wrapper>
        <CCContent>
          {isShipmentHeader && (
            <SubPageHeader>
              <SubPageHeaderContainer>
                <SubPageHeaderTitle>
                  {title}
                </SubPageHeaderTitle>
              </SubPageHeaderContainer>
            </SubPageHeader>
          )}
          {/* temporarily hidden */}
          {false && (
            <CCContentHeader>
              <CCContentHeaderItem isActive>
                {t('Notifications')}
              </CCContentHeaderItem>
            </CCContentHeader>
          )}

          <CCContentStatistics>
            <CCContentStatisticsBlock
              svgWidth="20px"
              svgHeight="17px"
            >
              <TasksBlockSummaryComponents
                titleName={t('Unread')}
                count={`${infoCountUnread || 0}`}
                iconInfo={<EyeInvisibleOutlined style={{ color: themesColors.secondaryDot45 }} />}
              />
            </CCContentStatisticsBlock>
            <CCContentStatisticsBlock isNext>
              <TasksBlockSummaryComponents
                titleName={t('Important')}
                count={`${infoCountImportant || 0}`}
                iconInfo={<BellWarningGoldSvg style={{ color: !infoCountImportant ? themesColors.secondaryDot45 : undefined }} />}
              />
            </CCContentStatisticsBlock>
            <CCContentStatisticsBlock
              isNext
              svgWidth="15px"
              svgHeight="20px"
            >
              <TasksBlockSummaryComponents
                titleName={t('Regular')}
                count={`${infoCountRegular || 0}`}
                iconInfo={<BellOutlined style={{ color: themesColors.secondaryDot45 }} />}
              />
            </CCContentStatisticsBlock>
          </CCContentStatistics>

          <CCContentFilters>
            <TasksFilterHeaderComponent
              isVisibleShowOnlyUnread
              isDisabledShowOnlyUnread={filters?.isDisabledOnlyUnread}
              valueShowOnlyUnread={filters?.isOnlyUnread}
              onChangeFilterShowOnlyUnread={onChangeFilterShowOnlyUnread}
              isVisibleShowOnlyPriority
              groupPriority={filters?.filterPriority?.group}
              isDisabledResetPriority={filters?.filterPriority?.disableReset}
              onApplyPriority={onApplyPriority}
              onChangeGroupPriority={onChangeGroupPriority}
              onResetPriority={onResetPriority}
              isVisibleDates
              receivedDates={filters?.filterReceivedDates}
              isVisibleModule
              isVisibleClearAll={isVisibleClearAll}
              isDisabledResetModule={filters?.filterModule?.disableReset}
              groupModule={filters?.filterModule?.group}
              onApplyReceivedDate={onApplyReceivedDate}
              onResetReceivedDate={onResetReceivedDate}
              dateOnReceivedDateChange={dateOnReceivedDateChange}
              onChangeGroupModule={onChangeGroupModule}
              onApplyModule={onApplyModule}
              onResetModule={onResetModule}
              onClearAllFilters={onClearAllFilters}
            />
          </CCContentFilters>

          {allNotifications?.length || isLoadNotification === REQUEST_STATUS.pending || isLoadNotificationAdd === REQUEST_STATUS.pending ? (
            <ContentTableNotification
              infiniteScrollId="Wrapper"
              onStartNotifications={() => ''}
              nextPageTasks={nextPageNotifications}
              onHoverNotificationItem={onHoverNotificationItem}
              onUnviewedNotificationItem={onUnviewedNotificationItem}
              onAllNotificationMakeViews={onAllNotificationMakeViews}
              isLoad={isLoadNotification}
              shipmentId={shipmentId}
              isLoadAdd={isLoadNotificationAdd}
              allTasks={allNotifications}
            />
          ) : null}

          {!isVisibleClearAll && !allNotifications?.length && isLoadNotification !== REQUEST_STATUS.pending && isLoadNotificationAdd !== REQUEST_STATUS.pending ? (
            <EmptyStateForPages
              icon={(
                <FilterFilled
                  width={28}
                  height={22}
                  style={{
                    color: themesColors.neutralBranding65,
                    width: '28px',
                    height: '22px',
                  }}
                />
              )}
              headerText={t('No new notifications.')}
              contentText={t('You’re all caught up. We’ll keep you posted with the latest updates.')}
            />
          ) : null}
          {isVisibleClearAll && !allNotifications?.length && isLoadNotification !== REQUEST_STATUS.pending && isLoadNotificationAdd !== REQUEST_STATUS.pending ? (
            <EmptyStateForPages
              icon={(
                <FilterFilled
                  width={28}
                  height={22}
                  style={{
                    color: themesColors.neutralBranding65,
                    width: '28px',
                    height: '22px',
                  }}
                />
              )}
              headerText={t('No notifications found')}
              contentText={t('Filters maybe to restrictive')}
              buttonText={t('Clear filters')}
              onClickButton={onClearAllFiltersHandler}
            />
          ) : null}
        </CCContent>
      </Wrapper>
    </>
  );
};

const CommandCenterNotificationShipmentComponentCache = memo(CommandCenterNotificationShipmentComponent);

export { CommandCenterNotificationShipmentComponentCache as CommandCenterNotificationShipmentComponent };
