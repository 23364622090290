import styled from 'styled-components';
import Typography from 'antd/es/typography';
import Tag from 'antd/es/tag';

export const Wrap = styled.div`
  page-break-inside: avoid;
  padding: 8px 24px;
`;

export const MarksAndNumber = styled.div`
  padding-bottom: 8px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 0.2px solid rgba(209, 215, 226, 1);
`;

export const MarksWrap = styled.div`
  width: 40%;
  display: flex;
  flex-direction: column;
`;

export const Title = styled(Typography)`
  font-size: 9.6px;
  line-height: 14.4px;
  font-weight: 700;
`;

export const Marks = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Mark = styled(Tag)`
  padding: 0 3.2px;
  font-size: 7.2px;
  line-height: 9.6px;
  font-weight: 600;
  border: 0.2px solid rgba(234, 238, 248, 1);
`;

export const Notes = styled.div`
  display: flex;
  flex-direction: column;
  width: 60%;
  font-size: 9.6px;
  line-height: 14.4px;
`;

export const Note = styled.div`
  font-size: 9.6px;
  line-height: 14.4px;
`;
