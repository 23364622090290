import React from 'react';

import { R } from 'shipment-operations/repository';
import { useAppSelector } from 'app-wrapper/hooks';

import { AdditionalComponent } from './Additional.component';

const Additional = () => {
  const containers = useAppSelector(R.selectors.createInvoiceCharges.uniqAdditionalContainers);
  const data = useAppSelector(R.selectors.createInvoiceCharges.groupedAdditionalContainers);
  const sum = useAppSelector(R.selectors.createInvoiceCharges.getAdditionalDataSum);
  const tableData = useAppSelector(R.selectors.createInvoiceCharges.getAdditionalRestData);

  return (
    <AdditionalComponent
      data={data}
      sum={sum}
      containers={containers}
      tableData={tableData}
    />
  );
};

export { Additional };
