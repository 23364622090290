import React, { memo } from 'react';
import { useSelector } from 'react-redux';

import { R } from 'user-management/repository';

import { UC } from 'shipment-operations/controllers';
import { OrganizationPaymentMethod } from 'shipment-operations/constants';

import { TradeReferencesComponent } from './TradeReferences.component';

const TradeReferencesContainer = () => {
  const organization = useSelector(R.selectors.customer.getOrganization);

  return (
    <TradeReferencesComponent
      tradeReference={organization?.paymentMethod}
      isPrepayment={organization?.paymentMethod?.type === OrganizationPaymentMethod.PREPAYMENT}
      openEditDrawer={UC.shipmentDrawer.openEditTradeAccountingDrawer}
    />
  );
};

const TradeReferencesContainerCached = memo(TradeReferencesContainer);

export { TradeReferencesContainerCached as TradeReferences };
