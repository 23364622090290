import styled from 'styled-components';

export const TypeSwitchController = styled.div`
  .Switch {
    width: 28px;
    margin: 0 8px;
  }

  .ant-switch-checked {
    background-color: ${({ theme }) => theme.themesColors.secondaryDot45};
  }

  .ant-switch:disabled {
    background-color: ${({ theme }) => theme.themesColors.secondaryDot45};
  }
`;

export const TypeSwitchControllerWithText = styled(TypeSwitchController)`
  .Switch {
    min-width: 41px;

    .ant-switch-inner {
      margin: -3px 7px 0 17px;
    }

    &.ant-switch-checked .ant-switch-inner {
      margin: -3px 17px 0 7px;
    }
  }
`;

export const TypeSwitchControllerText = styled.span.attrs((props: {
  isInvert?: boolean,
}) => ({
  isInvert: props.isInvert,
  'data-class': 'TypeSwitchControllerText',
}))`
  font-size: 12px;
  line-height: 20px;
  color: ${({ isInvert, theme }) => (isInvert
    ? theme.themesColors.secondaryDot45
    : theme.themesColors.characterBrandingTitle85)};
`;

export const TypeSwitchControllerTextInactive = styled(TypeSwitchControllerText).attrs((props: {
  isInvert?: boolean,
}) => ({
  isInvert: props.isInvert,
  'data-class': 'TypeSwitchControllerTextInactive',
}))`
  color: ${({ isInvert, theme }) => (isInvert
    ? theme.themesColors.characterBrandingTitle85
    : theme.themesColors.secondaryDot45)};
  /* color: ${({ theme }) => (theme.themesColors.secondaryDot45)} */
`;
