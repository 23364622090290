import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ShippingInstructionsStatusEnum } from 'shipment-operations/constants';

import {
  StatusTag,
  PendingSubmissionContent,
  FooterInformation,
  SubmittedContent,
  ConfirmedContent,
  ClearedContent,
  MissMatchesContent,
  FailedContent,
  RejectedContent,
} from './components';
import {
  Section,
  Header,
  Title,
} from './MBLFlowManagement.styled';

const SUBMITTED_STATUSES = [
  ShippingInstructionsStatusEnum.SI_SUBMITTED,
  ShippingInstructionsStatusEnum.SI_AMENDMENT_SUBMITTED,
  ShippingInstructionsStatusEnum.SI_CONFIRMED,
  ShippingInstructionsStatusEnum.SI_AMENDMENT_CONFIRMED,
  ShippingInstructionsStatusEnum.SI_FAILED,
  ShippingInstructionsStatusEnum.SI_DECLINED,
];

interface IMBLFlowManagementComponentProps {
  siStatus: ShippingInstructionsStatusEnum;
  hasFinalMBL: boolean;
  hasFinalMBLDocument: boolean;
  isDraftMBLApproved: boolean;
  isThereAreAnyMissMatches: boolean;
  mblNumber: string;
  isOriginPartner: boolean;
  isCustomerOrg: boolean;
  isDestinationOrg: boolean;
  documentCutoff?: string;
}

export const MBLFlowManagementComponent: FC<IMBLFlowManagementComponentProps> = ({
  siStatus,
  hasFinalMBLDocument,
  hasFinalMBL,
  isDraftMBLApproved,
  isThereAreAnyMissMatches,
  mblNumber,
  isOriginPartner,
  isCustomerOrg,
  isDestinationOrg,
  documentCutoff,
}) => {
  const { t } = useTranslation();

  const isFailedStatus = isOriginPartner && siStatus === ShippingInstructionsStatusEnum.SI_FAILED;
  const isRejectedStatus = isOriginPartner && siStatus === ShippingInstructionsStatusEnum.SI_DECLINED;
  const isPendingStatus = siStatus === ShippingInstructionsStatusEnum.SI_PREPARATION;
  const isSubmittedStatus = SUBMITTED_STATUSES.includes(siStatus) && !isDraftMBLApproved && !hasFinalMBL && !hasFinalMBLDocument;
  const isConfirmedStatus = isDraftMBLApproved && !hasFinalMBL && !hasFinalMBLDocument;
  const isClearedStatus = hasFinalMBLDocument || hasFinalMBL;

  return (
    <Section>
      <Header>
        <Title>
          {t('Master Bill Of Lading')}
          &nbsp;
          (#{mblNumber})
        </Title>

        <StatusTag
          isPendingSubmission={isPendingStatus}
          isSubmitted={isSubmittedStatus}
          isConfirmed={isConfirmedStatus}
          isCleared={isClearedStatus}
          isFailedStatus={isFailedStatus}
          isRejectedStatus={isRejectedStatus}
        />
      </Header>

      {isFailedStatus || isRejectedStatus ? (
        <>
          {isFailedStatus ? (
            <FailedContent />
          ) : null}

          {isRejectedStatus ? (
            <RejectedContent />
          ) : null}
        </>
      ) : (
        <>
          {isThereAreAnyMissMatches && ((isCustomerOrg || isDestinationOrg) || (isOriginPartner && isDraftMBLApproved)) ? (
            <>
              {hasFinalMBL || hasFinalMBLDocument ? (
                <ClearedContent />
              ) : null}

              <MissMatchesContent />
            </>
          ) : (
            <>
              {isPendingStatus ? (
                <PendingSubmissionContent />
              ) : null}

              {isSubmittedStatus ? (
                <SubmittedContent />
              ) : null}

              {isConfirmedStatus ? (
                <ConfirmedContent />
              ) : null}

              {isClearedStatus ? (
                <ClearedContent />
              ) : null}
            </>
          )}
        </>
      )}

      {!isThereAreAnyMissMatches && (isPendingStatus || isClearedStatus) ? (
        <FooterInformation
          isPendingSubmission={isPendingStatus}
          isCleared={isClearedStatus}
          hasFinalMBLDocument={hasFinalMBLDocument}
          isOriginPartner={isOriginPartner}
          isCustomer={isCustomerOrg}
          isDestinationPartner={isDestinationOrg}
          documentCutoff={documentCutoff}
        />
      ) : null}
    </Section>
  );
};
