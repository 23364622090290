import { CargoReferenceType } from './CargoReferenceType.enum';

export const CargoReferenceTypeNames = {
  [CargoReferenceType.SKU]: 'SKU',
  [CargoReferenceType.PO_NUMBER]: 'PO number',
};

export const CargoReferenceTypeNamesShort = {
  [CargoReferenceType.SKU]: 'SKU',
  [CargoReferenceType.PO_NUMBER]: 'PO',
};
