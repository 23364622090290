import styled from 'styled-components';
import Badge from 'antd/es/badge';
import Typography from 'antd/es/typography';

import { colors } from 'app-wrapper/view/themes/themesColors';
import { Button } from 'app-wrapper/view/components';

export const UnalignedBadge = styled(Badge)`
  > * { top: -4px !important; }
`;

export const ShippingPartiesFormTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  > * {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
    margin-bottom: 0 !important;
  }
`;

export const ShippingPartiesFormTitleStatusContainer = styled(Typography.Title)`
  overflow: hidden;
`;

export const ShippingPartiesSwitchContainer = styled.div`
  gap: 8px;
`;

export const ShippingPartiesSwitchDescription = styled(Typography.Text)`
  font-size: 12px !important;
  color: ${colors.gray61};
`;

export const ShippingPartiesRemoveButton = styled(Button).attrs({
  'data-class': 'ShippingPartiesRemoveButton',
})`
  font-size: 12px;
`;
