import React, { useMemo } from 'react';
import { FieldValues } from 'react-hook-form/dist/types/index.d';
import { SwitchProps } from 'antd/es/switch';
import omit from 'lodash/fp/omit';

import { Switch } from 'app-wrapper/view/components';
import { ControllerPropsI, useControllerProps } from 'app-wrapper/hooks';

import {
  TypeSwitchControllerText,
  TypeSwitchController,
  TypeSwitchControllerWithText,
  TypeSwitchControllerTextInactive,
} from './TypeSwitchController.styled';

interface TypeSwitchControllerI {
  leftText?: string
  rightText?: string
  disabledbg?: string
}

const TypeSwitchControllerComponent = <T extends FieldValues>(
  props: ControllerPropsI<SwitchProps, T> & TypeSwitchControllerI,
) => {
  const [controllerProps, { field }] = useControllerProps<SwitchProps, T>(props);

  const componentProps = omit(['leftText', 'rightText'], controllerProps);

  const {
    className,
    leftText,
    rightText,
    disabledbg,
    checkedChildren,
    unCheckedChildren,
  } = props;
  const { value } = field;

  const leftContent = useMemo(() => {
    if (leftText) {
      return value ? (
        <TypeSwitchControllerTextInactive className="controller-text-left">
          {leftText}
        </TypeSwitchControllerTextInactive>
      ) : (
        <TypeSwitchControllerText className="controller-text-left">
          {leftText}
        </TypeSwitchControllerText>
      );
    }

    return null;
  }, [leftText, value]);

  const rightContent = useMemo(() => {
    if (rightText) {
      return value ? (
        <TypeSwitchControllerText className="controller-text-right">
          {rightText}
        </TypeSwitchControllerText>
      ) : (
        <TypeSwitchControllerTextInactive className="controller-text-right">
          {rightText}
        </TypeSwitchControllerTextInactive>
      );
    }

    return null;
  }, [rightText, value]);

  const switchComponent = useMemo(() => (
    <Switch
      className={className || 'Switch'}
      checked={value}
      disabled={!disabledbg}
      {...field}
      {...componentProps}
    />
  ), [className, value, field, componentProps, disabledbg]);

  return checkedChildren || unCheckedChildren ? (
    <TypeSwitchControllerWithText>
      {leftContent}
      {switchComponent}
      {rightContent}
    </TypeSwitchControllerWithText>
  ) : (
    <TypeSwitchController>
      {leftContent}
      {switchComponent}
      {rightContent}
    </TypeSwitchController>
  );
};

export default TypeSwitchControllerComponent;
