import {
  IsEnum,
  IsOptional,
} from 'class-validator';
import { BaseDTM } from 'proto/BaseDTM';

import { PermissionAttributePolicy } from 'shipment-operations/constants';

export type TShipmentChargesPermissionsDtm = {
  shipmentChargesAvailability?: PermissionAttributePolicy;
  shipmentChargesProfit?: PermissionAttributePolicy;
  activeChargeOrigin?: PermissionAttributePolicy;
  activeChargeDestination?: PermissionAttributePolicy;
}

export class ShipmentChargesPermissionsDtm extends BaseDTM<TShipmentChargesPermissionsDtm> {
  @IsOptional()
  @IsEnum(PermissionAttributePolicy)
  shipmentChargesAvailability?: PermissionAttributePolicy

  @IsOptional()
  @IsEnum(PermissionAttributePolicy)
  shipmentChargesProfit?: PermissionAttributePolicy

  @IsOptional()
  @IsEnum(PermissionAttributePolicy)
  activeChargeOrigin?: PermissionAttributePolicy

  @IsOptional()
  @IsEnum(PermissionAttributePolicy)
  activeChargeDestination?: PermissionAttributePolicy;
}
