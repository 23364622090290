import { ExclamationCircleFilled } from '@ant-design/icons';
import Typography from 'antd/es/typography';
import styled from 'styled-components';

import {
  FlexRow,
  TitleText,
  BodyTextRegular,
} from 'app-wrapper/view/guideline';
import { Button as BaseButton } from 'app-wrapper/view/components';

import { BaseContent } from 'shipment-operations/view/pages/ShipmentBillOfLading/components/MBLFlowManagement/MBLFlowManagement.styled';

export const Content = styled(BaseContent)`
  margin-bottom: 20px;
`;

export const Row = styled(FlexRow)`
  width: 80%;
  justify-content: space-between;
`;

export const ButtonWrap = styled.div`
  margin-left: auto;
`;

export const Button = styled(BaseButton)`
  ${TitleText}
  font-weight: 400;
  height: 40px;
`;

export const Alert = styled.div`
  width: 100%;
  padding: 9px 16px;
  min-height: 40px;
  height: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: rgba(255, 251, 230, 1);
  border: 1px solid rgba(255, 229, 143, 1);
  border-radius: 2px;
`;

export const AlertIcon = styled(ExclamationCircleFilled)`
  color: rgba(250, 173, 20, 1);
`;

export const AlertText = styled(Typography)`
  ${BodyTextRegular}
  margin-left: 10px;
`;
