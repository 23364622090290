import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'app-wrapper/createSlice';

import { IAccountDrawerState } from 'user-management/models/states';
import { EAccountDrawerContent, EAccountDrawerType } from 'user-management/constants';

const initialState: IAccountDrawerState = {
  isLoading: false,
  isVisible: false,
  type: EAccountDrawerType.ORGANIZATION,
};

export const accountDrawerStore = createSlice({
  name: 'AccountDrawer',
  initialState,
  reducers: {
    openDrawer: (state, action: PayloadAction<EAccountDrawerContent>) => {
      state.isVisible = true;
      state.content = action.payload;
    },
    closeDrawer: (state) => {
      state.isVisible = false;
      state.content = undefined;
      state.type = EAccountDrawerType.ORGANIZATION;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setDynamicDrawerTitle: (state, action: PayloadAction<string | undefined>) => {
      state.dynamicDrawerTitle = action.payload;
    },
    setType: (state, action: PayloadAction<EAccountDrawerType>) => {
      state.type = action.payload;
    },
  },
});

export const accountDrawerActions = accountDrawerStore.actions;

export const accountDrawerReducer = accountDrawerStore.reducer;
