import {
  IsBoolean,
  IsDefined, IsEmail, IsNumber, IsOptional, IsString,
} from 'class-validator';
import { BaseDTM } from 'proto/BaseDTM';

export interface IContactDTM {
  id: number
  fullName: string
  email: string
  phone: string
  phone2?: string
  primary?: boolean
}

export class ContactDTM extends BaseDTM<IContactDTM> {
  @IsNumber()
  @IsDefined()
  id: number;

  @IsDefined()
  @IsString()
  fullName: string;

  @IsDefined()
  @IsEmail()
  email: string;

  @IsDefined()
  @IsString()
  phone: string;

  @IsOptional()
  @IsString()
  phone2?: string;

  @IsOptional()
  @IsBoolean()
  primary?: boolean;
}
