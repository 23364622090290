import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from 'app-wrapper/view/components';
import { ShipmentDrawer } from 'shipment-operations/view/pages/Shipment/components';

import {
  Wrap,
  Title,
  Spinner,
  Footer,
  NextButton,
  CancelButton,
  Content,
} from './NewOrganizationDocuments.styled';
import { Documents, RequiredWarning } from './components';

interface SubmitProofDocumentsWizardItemComponentProps {
  isLoading: boolean;
  initData: () => void;
  goNextStep: () => void;
  saveStep: () => void;
  goPrevStep: () => void;
  cancelWizard: () => void;
}

export const NewOrganizationDocumentsComponent: FC<SubmitProofDocumentsWizardItemComponentProps> = ({
  isLoading,
  initData,
  goNextStep,
  saveStep,
  goPrevStep,
  cancelWizard,
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    initData();
  }, []);

  return (
    <Wrap>
      {isLoading ? <Spinner /> : (
        <Content>
          <RequiredWarning />

          <Title>
            {t('Submit Proof Documents')}
          </Title>

          <Documents />
          <ShipmentDrawer />
        </Content>
      )}
      <Footer>
        <CancelButton
          disabled={isLoading}
          type="default"
          onClick={cancelWizard}
        >
          {t('Cancel')}
        </CancelButton>

        <Button
          disabled={isLoading}
          type="default"
          onClick={goPrevStep}
        >
          {t('Back')}
        </Button>

        <NextButton
          disabled={isLoading}
          type="default"
          onClick={saveStep}
        >
          {t('Save for Later')}
        </NextButton>

        <NextButton
          disabled={isLoading}
          onClick={goNextStep}
        >
          {t('Next')}
        </NextButton>
      </Footer>
    </Wrap>
  );
};
