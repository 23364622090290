import i18n from 'app-wrapper/i18n/i18n';
import type { DataNode } from 'antd/es/tree';

import { TRouteLegPhase } from 'shipment-operations/constants';
import { AdditionalServiceDTM, AdditionalServiceGroupEnum } from 'monetary/models/dtm/Quotas/AdditionalServices';

export const keyPrefix = 'parent_';

const FREIGHT_ROOT_NODE = {
  title: i18n.t('Freight'),
  key: TRouteLegPhase.FREIGHT,
  checkable: false,
};
const ORIGIN_ROOT_NODE = {
  title: i18n.t('Origin'),
  key: TRouteLegPhase.ORIGIN,
  checkable: false,
};
const DESTINATION_ROOT_NODE = {
  title: i18n.t('Destination'),
  key: TRouteLegPhase.DESTINATION,
  checkable: false,
};
const GROUP_NAMES: Record<AdditionalServiceGroupEnum, string> = {
  [AdditionalServiceGroupEnum.FORMALITIES]: i18n.t('Formalities'),
  [AdditionalServiceGroupEnum.FREIGHT_HANDLING]: i18n.t('Freight Handling'),
};

export const formatGroupNodes = (parentKey: TRouteLegPhase, items: AdditionalServiceDTM[]): DataNode[] => {
  const formalitiesItems = items.filter(({ group }) => group === AdditionalServiceGroupEnum.FORMALITIES);
  const freightHandlingItems = items.filter(({ group }) => group === AdditionalServiceGroupEnum.FREIGHT_HANDLING);

  return [
    ...(formalitiesItems.length ? [{
      title: GROUP_NAMES[AdditionalServiceGroupEnum.FORMALITIES],
      key: `${parentKey}-${AdditionalServiceGroupEnum.FORMALITIES}`,
      checkable: false,
      children: formalitiesItems.map((item) => ({
        title: item.description,
        key: `${parentKey}-${AdditionalServiceGroupEnum.FORMALITIES}-${item.code}`,
        checkable: true,
      })),
    }] : []),
    ...(freightHandlingItems.length ? [{
      title: GROUP_NAMES[AdditionalServiceGroupEnum.FREIGHT_HANDLING],
      key: `${parentKey}-${AdditionalServiceGroupEnum.FREIGHT_HANDLING}`,
      checkable: false,
      children: freightHandlingItems.map((item) => ({
        title: item.description,
        key: `${parentKey}-${AdditionalServiceGroupEnum.FREIGHT_HANDLING}-${item.code}`,
        checkable: true,
      })),
    }] : []),
  ];
};

export const prepareDataForTree = (data: AdditionalServiceDTM[]): DataNode[] => {
  const freightItems = data.filter(({ phases }) => phases && phases.includes(TRouteLegPhase.FREIGHT));
  const destinationItems = data.filter(({ phases }) => phases && phases.includes(TRouteLegPhase.DESTINATION));
  const originItems = data.filter(({ phases }) => phases && phases.includes(TRouteLegPhase.ORIGIN));

  return [
    ...(originItems.length ? [{
      ...ORIGIN_ROOT_NODE,
      children: formatGroupNodes(TRouteLegPhase.ORIGIN, originItems),
    }] : []),
    ...(freightItems.length ? [{
      ...FREIGHT_ROOT_NODE,
      children: formatGroupNodes(TRouteLegPhase.FREIGHT, freightItems),
    }] : []),
    ...(destinationItems.length ? [{
      ...DESTINATION_ROOT_NODE,
      children: formatGroupNodes(TRouteLegPhase.DESTINATION, destinationItems),
    }] : []),
  ];
};

export const getAdditionalServiceFromTreeNodeKey = (treeNodeKey: string, items: AdditionalServiceDTM[]): { phase: string, code: string } | null => {
  const [phaseKey, groupKey, key] = treeNodeKey.split('-');

  const targetService = items.find((item) => item.phases && item.phases.includes(phaseKey as TRouteLegPhase) && item.group === groupKey && key === item.code) as AdditionalServiceDTM;

  return targetService ? {
    code: targetService.code,
    phase: phaseKey,
  } : null;
};
