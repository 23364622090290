import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'antd/es/skeleton';
import Typography from 'antd/es/typography';
import MailOutlined from '@ant-design/icons/MailOutlined';
import PhoneOutlined from '@ant-design/icons/PhoneOutlined';

import { IconFrame } from 'shipment-operations/view/components';

import {
  CarrierContactCardContainer,
  CarrierContactCardLabel,
  CarrierContactName,
  CarrierContactCopiableTextContainer,
  CarrierContactLeftPart,
  CarrierContactRightPart,
} from './styled';

export interface ICarrierContactCardComponentProps {
  isLoading: boolean
  name?: string
  phoneList: string[]
  emailList: string[]
}

export const CarrierContactCardComponent: FC<ICarrierContactCardComponentProps> = ({
  isLoading,
  name,
  phoneList,
  emailList,
}) => {
  const { t } = useTranslation();

  if (isLoading) {
    return (
      <CarrierContactCardContainer>
        <Typography.Title level={5}>
          {t('Carrier Contact')}
        </Typography.Title>
        <Skeleton />
      </CarrierContactCardContainer>
    );
  }

  if ((phoneList.length + emailList.length) === 0 && !name) {
    return <></>;
  }

  return (
    <CarrierContactCardContainer>
      <CarrierContactLeftPart>
        <CarrierContactCardLabel>{t('Carrier Contact')}</CarrierContactCardLabel>
        <CarrierContactName level={5}>{name}</CarrierContactName>
      </CarrierContactLeftPart>
      <CarrierContactRightPart>
        {phoneList.map((phone) => (
          <CarrierContactCopiableTextContainer key={phone}>
            <IconFrame><PhoneOutlined /></IconFrame>
            <Typography.Text copyable>{phone}</Typography.Text>
          </CarrierContactCopiableTextContainer>
        ))}
        {emailList.map((email) => (
          <CarrierContactCopiableTextContainer key={email}>
            <IconFrame><MailOutlined /></IconFrame>
            <Typography.Text copyable>{email}</Typography.Text>
          </CarrierContactCopiableTextContainer>
        ))}
      </CarrierContactRightPart>
    </CarrierContactCardContainer>
  );
};
