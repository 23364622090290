import React, {
  FC,
  useRef,
  useCallback,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useReactToPrint } from 'react-to-print';

import { HBLDocumentPDF } from 'shipment-operations/view/components/HBLDocumentPDF';

import { MenuItem } from './PrintHBL.styled';

interface PrintHBLComponentProps {
  isLoading: boolean;
  fileNamePrint?: string;
}

const oldTitle = document.title;

export const PrintHBLComponent: FC<PrintHBLComponentProps> = ({
  isLoading,
  fileNamePrint,
}) => {
  const { t } = useTranslation();
  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    // @ts-ignore
    content: () => componentRef.current,
    onAfterPrint: () => {
      // support chrome
      document.title = oldTitle;
    },
  });

  const handleClick = useCallback(() => {
    if (isLoading) {
      return;
    }

    // @ts-ignore only for e2e test
    if (!window.Cypress) {
      // support chrome
      document.title = fileNamePrint || oldTitle;
      handlePrint();
    }
  }, [handlePrint, fileNamePrint]);

  return (
    <>
      <div data-class="pdfLayout" style={{ display: 'none' }}>
        <HBLDocumentPDF
          fileNamePrint={fileNamePrint}
          ref={componentRef}
        />
      </div>

      <MenuItem onClick={handleClick}>
        {t('Print')}
      </MenuItem>
    </>
  );
};
