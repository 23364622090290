import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Bold } from 'shipment-operations/view/components/HBLDocumentPDF/components';
import { AccountDepartmentDTM, AdminPublicInfoDTM } from 'user-management/models/dtm';

import { OrganizationInfo } from './components';
import { Card } from '../Common.styled';
import { License, Logo } from './OrganizationCard.styled';

interface OrganizationCardProps {
  pricingDepartment: AccountDepartmentDTM | null;
  adminPublicInfo: AdminPublicInfoDTM | null;
  isOriginOrDestinationFromUS: boolean;
}

export const OrganizationCard: FC<OrganizationCardProps> = ({
  adminPublicInfo,
  pricingDepartment,
  isOriginOrDestinationFromUS,
}) => {
  const { t } = useTranslation();

  return (
    <Card>
      <Logo />
      <OrganizationInfo
        adminPublicInfo={adminPublicInfo}
        pricingDepartment={pricingDepartment}
        isOriginOrDestinationFromUS={isOriginOrDestinationFromUS}
      />

      {isOriginOrDestinationFromUS ? (
        <License>
          <Bold>
            {t('FMC License:')}
          </Bold>
          &nbsp;
          {t('#027662')}
        </License>
      ) : null}
    </Card>
  );
};
