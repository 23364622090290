import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { AdditionalServiceDocumentActivityDTM } from 'shipment-operations/models/dtm';
import { FlexRow } from 'app-wrapper/view/guideline';
import { EditButton } from 'app-wrapper/view/components';
import { DocumentsSection } from 'shipment-operations/view/pages/ShipmentAdditionalService/components/ServiceWorkflow/сomponents/RequiredActionsFlow/components/StepsForm/components/UploadDocuments/components';

import {
  Results,
  Title,
} from './DocumentsResults.styled';

interface IDocumentsResultsComponentProps {
  documentActivities: AdditionalServiceDocumentActivityDTM[];
  othersDocumentActivities: AdditionalServiceDocumentActivityDTM[];
  downloadDocument: (shipmentId: string, documentId: number, documentName: string) => void;
  startEditingFlowFromDocuments: () => void;
  isThereAreAnyRelatedActivities: boolean;
}

export const DocumentsResultsComponent: FC<IDocumentsResultsComponentProps> = ({
  documentActivities,
  othersDocumentActivities,
  downloadDocument,
  startEditingFlowFromDocuments,
  isThereAreAnyRelatedActivities,
}) => {
  const { t } = useTranslation();

  const requiredDocumentActivities = useMemo(() => documentActivities.filter((activity) => activity.isRequired || (!activity.isRequired && activity.getProvidedDocument())), [documentActivities]);
  const requiredOthersDocumentActivities = useMemo(() => othersDocumentActivities.filter((activity) => activity.isRequired || (!activity.isRequired && activity.getProvidedDocument())), [documentActivities]);

  return (
    <Results>
      <FlexRow>
        <Title>
          {t('Documents')}
        </Title>

        {isThereAreAnyRelatedActivities ? (
          <EditButton
            onClick={startEditingFlowFromDocuments}
            iconOnly
          />
        ) : null}
      </FlexRow>

      {requiredDocumentActivities.map((activity) => (
        <DocumentsSection
          key={activity.type}
          type={activity.code}
          documents={activity.providedDocuments}
          onDownload={downloadDocument}
          hideStatus
        />
      ))}
      {requiredOthersDocumentActivities.map((activity) => (
        <DocumentsSection
          key={activity.type}
          type={activity.code}
          documents={activity.providedDocuments}
          onDownload={downloadDocument}
          hideStatus
          showWaitingFromSkypace
        />
      ))}
    </Results>
  );
};
