import styled from 'styled-components';

export const TotalFooter = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-top: 1px solid #EAEEF8;
  border-bottom: 1px solid #EAEEF8;
  background: #F9F9FB;
`;
