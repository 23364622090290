import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useLocation, useNavigate } from 'react-router';
import Tabs from 'antd/es/tabs';

import { RouteNames } from 'app-wrapper/constants';
import { SubPageTabs } from 'app-wrapper/view/guideline';

const DrayageTransportationTabsComponent = () => {
  const { t } = useTranslation();
  const { shipmentId = '0' } = useParams();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const isExportRoute = pathname.includes('export');

  const onChangeTab = useCallback((tab) => {
    navigate(tab, { replace: true });
  }, []);

  return (
    <SubPageTabs
      activeKey={pathname}
      onChange={onChangeTab}
    >
      <Tabs.TabPane
        tab={t('Overview')}
        key={isExportRoute ? RouteNames.SHIPMENT_TRANSPORTATION_EXPORT_OVERVIEW(shipmentId) : RouteNames.SHIPMENT_TRANSPORTATION_IMPORT_OVERVIEW(shipmentId)}
      />
      <Tabs.TabPane
        tab={t('Tracker')}
        key={isExportRoute ? RouteNames.SHIPMENT_TRANSPORTATION_EXPORT_TRACKER(shipmentId) : RouteNames.SHIPMENT_TRANSPORTATION_IMPORT_TRACKER(shipmentId)}
      />
      <Tabs.TabPane
        tab={t('Charges')}
        key={isExportRoute ? RouteNames.SHIPMENT_TRANSPORTATION_EXPORT_CHARGES(shipmentId) : RouteNames.SHIPMENT_TRANSPORTATION_IMPORT_CHARGES(shipmentId)}
      />
    </SubPageTabs>
  );
};

export { DrayageTransportationTabsComponent };
