import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import folderSrc from 'shipment-operations/view/pages/ShipmentBillOfLading/components/MBLFlowManagement/components/SubmittedContent/folder.png';
import {
  Column,
  Content,
  Title,
  Text,
  Image,
  Button,
} from 'shipment-operations/view/pages/ShipmentBillOfLading/components/MBLFlowManagement/components/SubmittedContent/SubmittedContent.styled';

interface IConfirmedContentComponentProps {
  isCustomer: boolean;
  isOriginPartner: boolean;
  isDestinationPartner: boolean;
  openUploadFileDrawer: () => void;
}

export const ConfirmedContentComponent: FC<IConfirmedContentComponentProps> = ({
  isCustomer,
  isOriginPartner,
  isDestinationPartner,
  openUploadFileDrawer,
}) => {
  const { t } = useTranslation();

  return (
    <Content>
      <Image
        src={folderSrc}
        alt=""
      />

      <Column>
        <Title>
          {isCustomer || isDestinationPartner ? t('Your draft MBL has been confirmed by the carrier.') : null}
          {isOriginPartner ? t('Your draft MBL has been confirmed by the carrier.') : null}
        </Title>

        <Text>
          {isCustomer || isDestinationPartner ? t('The final version will be available after the vessel departs.') : null}
          {isOriginPartner ? t('We anticipate receiving e-version approval from the carrier once the vessel departs. If you already received final MBL document outside the system, please verify it against the information below and upload.') : null}
        </Text>
      </Column>

      {isOriginPartner ? (
        <Button onClick={openUploadFileDrawer}>
          {t('Upload MBL')}
        </Button>
      ) : null}
    </Content>
  );
};
