import React, { ChangeEvent, FC, useCallback } from 'react';
import DatePicker from 'antd/es/date-picker';
import Input from 'antd/es/input';
import { useTranslation } from 'react-i18next';

import { Wrapper, DateWrapper, Label } from './Header.styled';

interface IHeaderProps {
  onChangeHeader: (value: string, name: string) => void;
  errors: string[];
}

const HeaderComponent: FC<IHeaderProps> = ({ onChangeHeader, errors }) => {
  const { t } = useTranslation();

  const onChangeContract = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    onChangeHeader(event.target.value, 'contractNumber');
  }, []);

  const onChangeValidFrom = useCallback((date) => {
    onChangeHeader(date, 'validFrom');
  }, []);

  const onChangeValidTo = useCallback((date) => {
    onChangeHeader(date, 'validTo');
  }, []);

  const onChangeBookValidity = useCallback((date) => {
    onChangeHeader(date, 'bookValidity');
  }, []);

  return (
    <Wrapper>
      <DateWrapper>
        <Label>{t('Contract number')}*</Label>
        <Input
          onChange={onChangeContract}
          style={{ borderColor: errors.includes('contractNumber') ? 'red' : '' }}
        />
      </DateWrapper>
      <DateWrapper>
        <Label>{t('validFrom')}*</Label>
        <DatePicker
          onChange={onChangeValidFrom}
          style={{ borderColor: errors.includes('validFrom') ? 'red' : '' }}
        />
      </DateWrapper>
      <DateWrapper>
        <Label>{t('validTo')}*</Label>
        <DatePicker
          onChange={onChangeValidTo}
          style={{ borderColor: errors.includes('validTo') ? 'red' : '' }}
        />
      </DateWrapper>
      <DateWrapper>
        <Label>{t('bookValidity')}</Label>
        <DatePicker onChange={onChangeBookValidity} />
      </DateWrapper>
    </Wrapper>
  );
};

export { HeaderComponent };
