import { UploadFile } from 'antd/lib/upload/interface';
import {
  IsString, ValidateNested, IsBoolean, IsOptional, IsDefined, IsNumber, IsEnum,
} from 'class-validator';
import { Type } from 'class-transformer';

import { REQUEST_STATUS } from 'app-wrapper/constants';
import { BaseDTM } from 'proto/BaseDTM';

export interface IShipmentDocumentFileListDTM extends UploadFile {
  fileId?: string | null
  idIndex?: number
}

export interface IShipmentDocumentFileListStateDTM {
  [key: string]: IShipmentDocumentFileListDTM[]
}

interface IShipmentDocumentErrorsField {
  isBlur?: boolean
  isFocus?: boolean
  message?: string
  isVisited?: boolean
}

class ShipmentDocumentErrorsField implements IShipmentDocumentErrorsField {
  @IsBoolean()
  @IsOptional()
  isBlur?: boolean

  @IsBoolean()
  @IsOptional()
  isFocus?: boolean

  @IsBoolean()
  @IsOptional()
  isVisited?: boolean

  @IsOptional()
  @IsString()
  message?: string
}

export type TShipmentDocumentErrorsFieldKeys = 'isBlur' | 'isFocus' | 'message' | 'isVisited';

export interface IShipmentDocumentDefaultStateDocumentsDTM {
  documentType: string | null
  uploadFiles: string
  fileName: string
}

export class ShipmentDocumentDefaultStateDocumentsDTM extends BaseDTM<IShipmentDocumentDefaultStateDocumentsDTM> {
  @IsOptional()
  @IsString()
  documentType: string | null

  @IsDefined()
  @IsString()
  uploadFiles: string

  @IsDefined()
  @IsString()
  fileName: string
}

interface IDocumentsDTM {
  documentType?: IShipmentDocumentErrorsField
  uploadFiles?: IShipmentDocumentErrorsField
}

class DocumentsDTM extends BaseDTM<IDocumentsDTM> {
  @IsOptional()
  @ValidateNested()
  @Type(() => ShipmentDocumentErrorsField)
  documentType: ShipmentDocumentErrorsField

  @IsOptional()
  @ValidateNested()
  @Type(() => ShipmentDocumentErrorsField)
  uploadFiles: ShipmentDocumentErrorsField
}

export interface IShipmentDocumentUpdateStateErrorsDTM {
  documents: IDocumentsDTM[]
}

export class ShipmentDocumentUpdateStateErrorsDTM extends BaseDTM<IShipmentDocumentUpdateStateErrorsDTM> {
  @IsOptional()
  @ValidateNested()
  @Type(() => DocumentsDTM)
  documents: DocumentsDTM[]
}

export interface IShipmentDocumentUpdateStateDTM {
  notes: string
  reference: string
  referenceId: number | null
  additionalId: number | null
  isUpdate: boolean
  documents: IShipmentDocumentDefaultStateDocumentsDTM[]
  errors: IShipmentDocumentUpdateStateErrorsDTM
}

export class ShipmentDocumentUpdateStateDTM extends BaseDTM<IShipmentDocumentUpdateStateDTM> {
  @IsDefined()
  @IsString()
  notes: string

  @IsDefined()
  @IsString()
  reference: string

  @IsOptional()
  @IsNumber()
  referenceId: number | null

  @IsOptional()
  @IsNumber()
  additionalId: number | null

  @IsDefined()
  @IsBoolean()
  isUpdate: boolean

  @IsDefined()
  @ValidateNested()
  @Type(() => ShipmentDocumentDefaultStateDocumentsDTM)
  documents: ShipmentDocumentDefaultStateDocumentsDTM[]

  @IsDefined()
  @ValidateNested()
  @Type(() => ShipmentDocumentUpdateStateErrorsDTM)
  errors: ShipmentDocumentUpdateStateErrorsDTM
}

export interface IShipmentDocumentDefaultStateDTM {
  notes: string
  reference: string
  referenceId: number | null
  additionalId: number | null
  documents: IShipmentDocumentDefaultStateDocumentsDTM[]
  isRequest: boolean
  isEmptyRequest: boolean
  getAdditionalStatus: REQUEST_STATUS | null
  getManualAdditionalStatus: REQUEST_STATUS | null
}

export class ShipmentDocumentDefaultStateDTM extends BaseDTM<IShipmentDocumentDefaultStateDTM> {
  @IsDefined()
  @IsString()
  notes: string

  @IsDefined()
  @IsString()
  reference: string

  @IsOptional()
  @IsNumber()
  referenceId: number | null

  @IsOptional()
  @IsNumber()
  additionalId: number | null

  @IsDefined()
  @ValidateNested()
  @Type(() => ShipmentDocumentDefaultStateDocumentsDTM)
  documents: ShipmentDocumentDefaultStateDocumentsDTM[]

  @IsDefined()
  @IsBoolean()
  isRequest: boolean

  @IsDefined()
  @IsBoolean()
  isEmptyRequest: boolean

  @IsOptional()
  @IsEnum(REQUEST_STATUS)
  getAdditionalStatus: REQUEST_STATUS | null

  @IsOptional()
  @IsEnum(REQUEST_STATUS)
  getManualAdditionalStatus: REQUEST_STATUS | null
}

export interface IShipmentDocumentStateDTM {
  defaultState: IShipmentDocumentDefaultStateDTM
  updateState: IShipmentDocumentUpdateStateDTM
  fileList: IShipmentDocumentFileListStateDTM
}

export interface IShipmentDocumentStateChangeDTM {
  defaultState?: IShipmentDocumentDefaultStateDTM
  updateState?: IShipmentDocumentUpdateStateDTM
  fileList?: IShipmentDocumentFileListStateDTM
}
