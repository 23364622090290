import styled from 'styled-components';
import Typography from 'antd/es/typography';

export const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 26px auto;
`;

export const Image = styled.img`
  width: 100px;
  height: 100px;
`;

export const Title = styled(Typography)`
  margin-top: 10px;
  font-size: 16px;
  line-height: 22px;
  font-weight: 500;
`;

export const Text = styled(Typography)`
  font-size: 12px;
  line-height: 20px;
  color: rgba(115, 129, 155, 1);
`;
