import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import EditOutlined from '@ant-design/icons/EditOutlined';

import { CountryDTM, ShipmentRouteDTM } from 'shipment-operations/models/dtm';
import { PermissionAttributePolicy } from 'shipment-operations/constants';
import { hasAccess, hasReadAccess } from 'app-wrapper/utils';

import { LocationDataEmptyContent } from './components';
import {
  Content,
  Item,
  ItemSubTitle,
  ItemTitle,
  ItemTitleBold,
  LocationDataButton,
} from './LocationData.styled';

const DEFAULT_TIME_FORMAT = 'DD MMM, HH:mm';

interface ILocationDataProps {
  title: string;
  releaseDateLabel: string;
  timeFormat?: string;
  subTitle: string;
  isLoading: boolean;
  locationData?: ShipmentRouteDTM;
  countriesList: CountryDTM[];
  onEdit: () => void;
  locationsAvailability?: PermissionAttributePolicy;
  isDateHidden?: boolean;
}

export const LocationData: FC<ILocationDataProps> = ({
  title,
  releaseDateLabel,
  timeFormat = DEFAULT_TIME_FORMAT,
  subTitle,
  isLoading,
  locationData,
  onEdit,
  countriesList,
  locationsAvailability,
  isDateHidden,
}) => {
  const { t } = useTranslation();

  const isAbleToEdit = hasAccess(locationsAvailability, PermissionAttributePolicy.WRITE);

  const countryName = useMemo(() => {
    const countryItem = countriesList.find(({ code }) => code === locationData?.country);

    return countryItem ? countryItem.name : '';
  }, [countriesList, locationData?.country]);

  const address = useMemo(() => {
    if (!locationData) {
      return '';
    }

    const {
      address1,
      address2,
      address3,
      address4,
    } = locationData;
    const addressLine = address1 || address2 || address3 || address4;

    return `${addressLine}, ${locationData.city}, ${locationData.state ? `${locationData.state}, ` : ''} ${locationData.postcode}, ${countryName}`;
  }, [locationData]);

  return (
    <Content>
      {!isLoading && hasReadAccess(locationsAvailability) && !locationData && <LocationDataEmptyContent disabled={!isAbleToEdit} onAdd={onEdit} />}

      {locationData ? (
        <>
          {!isLoading && hasReadAccess(locationsAvailability) && locationData && (
            <LocationDataButton
              disabled={!isAbleToEdit}
              type="dashed"
              size="small"
              icon={<EditOutlined />}
              onClick={onEdit}
            >
              {t('Edit')}
            </LocationDataButton>
          )}

          <Item>
            <ItemTitleBold>
              {t(title)}
            </ItemTitleBold>

            <ItemSubTitle>
              {t(subTitle)}
            </ItemSubTitle>
          </Item>

          <Item>
            <ItemSubTitle>
              {t('Terminal Name')}
            </ItemSubTitle>

            <ItemTitle>
              {locationData?.terminalName || t('-')}
            </ItemTitle>
          </Item>

          <Item>
            <ItemSubTitle>
              {t('Pass Through Code')}
            </ItemSubTitle>

            <ItemTitle>
              {locationData?.passCode || t('-')}
            </ItemTitle>
          </Item>

          <Item>
            <ItemSubTitle>
              {t('Address')}
            </ItemSubTitle>

            <ItemTitle>
              {address || t('-')}
            </ItemTitle>
          </Item>

          {!isDateHidden ? (
            <Item>
              <ItemSubTitle>
                {t(releaseDateLabel)}
              </ItemSubTitle>

              <ItemTitle>
                {locationData?.releaseDate ? locationData?.releaseDate?.getDateAsMomentWithOffset().format(timeFormat) : t('-')}
              </ItemTitle>
            </Item>
          ) : null}
        </>
      ) : null}
    </Content>
  );
};
