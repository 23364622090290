import styled from 'styled-components';

export const AppWrapper = styled.div`
  * {
    box-sizing: border-box;
  }

  body {
    font-size: 14px;
    background: rgba(224,224,224,1);
  }
`;
