import styled from 'styled-components';

export const EditableCellWrapper = styled.div`
  border: 1px solid #D1D7E2;
  border-radius: 2px;
  padding: 6px 8px 5px 0;
  font-size: 12px;
`;

export const ChildrenWrapper = styled.div`
  .ant-form-item-with-help .ant-form-item-explain {
    display: none;
  }
  .ant-form-item-explain-error {
    display: none;
  }
  .ant-form-item-control {
    flex-direction: row;
    display: block !important;
  }

  .ant-input-number-handler-wrap {
    visibility: hidden;
  }
  
  .ant-input-number-input {
    text-align: right;
  }
`;
