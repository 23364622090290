import React, { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  AccountDprtmEditCPBlockButtonCancel,
  AccountDprtmEditCPBlockButtonSave,
  AccountDprtmEditCPBlockContent,
  AccountDprtmEditCPBlockFooter,
  AccountDprtmEditCPBlockWrap,
} from './AccountDepartmentEdit.styled';
import { AddressesAndContactsEditAddressAddressItem } from './components';

interface IAccountDepartmentEditComponentProps {
  isDisabledCancelBtn?: boolean
  isDisabledSaveBtn?: boolean
  onCancelClick: () => void
  onSaveClick: () => void
}

const AccountDepartmentEditComponent: FC<IAccountDepartmentEditComponentProps> = (props) => {
  const {
    isDisabledCancelBtn,
    isDisabledSaveBtn,
    onCancelClick,
    onSaveClick,
  } = props;
  const { t } = useTranslation();

  return (
    <AccountDprtmEditCPBlockWrap>
      <AccountDprtmEditCPBlockContent>

        <AddressesAndContactsEditAddressAddressItem />

      </AccountDprtmEditCPBlockContent>

      <AccountDprtmEditCPBlockFooter>
        <AccountDprtmEditCPBlockButtonCancel
          onClick={onCancelClick}
          disabled={isDisabledCancelBtn}
        >
          {t('addChargeMenu.cancel')}
        </AccountDprtmEditCPBlockButtonCancel>
        <AccountDprtmEditCPBlockButtonSave
          onClick={onSaveClick}
          disabled={isDisabledSaveBtn}
        >
          {t('addChargeMenu.save')}
        </AccountDprtmEditCPBlockButtonSave>
      </AccountDprtmEditCPBlockFooter>
    </AccountDprtmEditCPBlockWrap>
  );
};

const AccountDepartmentEditComponentCached = memo(AccountDepartmentEditComponent);

export { AccountDepartmentEditComponentCached as AccountDepartmentEditComponent };
