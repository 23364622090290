import React from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';
import { UC } from 'shipment-operations/controllers';

import { ClearedContentComponent } from './ClearedContent.component';

export const ClearedContent = React.memo(() => {
  const documentHBLBOLFileNamePrint = useSelector(R.selectors.documentHBLPDF.getHBLDocumentBOLFileNamePrint);
  const isLoading = useSelector(R.selectors.documentHBLPDF.getIsLoading);

  return (
    <ClearedContentComponent
      isLoading={isLoading}
      fetchHBLPDFData={UC.billOfLading.getHBLDocumentInformation}
      fileNamePrint={documentHBLBOLFileNamePrint}
    />
  );
});
