import React, { FC, useMemo } from 'react';

import {
  SCDescriptionsContainer,
  SCDescriptionsContainerTitle,
  SCDescriptionsContainerContent,
  SCDescriptionsContainerFooter,
  SCDescriptionsContainerSpanClip,
} from './ShipmentCardDescriptions.styled';

interface IShipmentCardDescriptionsProps {
  title?: string | React.ReactNode | null;
  content?: string | React.ReactNode;
  footer?: string[] | React.ReactNode;
  isVerticalCenter?: boolean;
}

export const SCDescriptions: FC<IShipmentCardDescriptionsProps> = ({
  title,
  content,
  footer,
  isVerticalCenter,
}) => {
  const footerComponent = useMemo(() => {
    if (Array.isArray(footer) && Number(footer?.length) < 2) {
      return `${footer?.[0] || ''}`;
    }

    return footer || null;
  }, [footer]);

  return (
    <SCDescriptionsContainer isVerticalCenter={isVerticalCenter}>
      {title === null
        ? ''
        : (
          <SCDescriptionsContainerTitle>
            <SCDescriptionsContainerSpanClip>
              {title || ''}
            </SCDescriptionsContainerSpanClip>
          </SCDescriptionsContainerTitle>
        )}
      <SCDescriptionsContainerContent>
        <SCDescriptionsContainerSpanClip>
          {content || ''}
        </SCDescriptionsContainerSpanClip>
      </SCDescriptionsContainerContent>
      <SCDescriptionsContainerFooter>
        <SCDescriptionsContainerSpanClip>
          {footerComponent || ''}
        </SCDescriptionsContainerSpanClip>
      </SCDescriptionsContainerFooter>
    </SCDescriptionsContainer>
  );
};
