import React from 'react';
import { useSelector } from 'react-redux';

import { UC } from 'shipment-operations/controllers';
import { UC as appUC } from 'app-wrapper/controllers';
import { R } from 'shipment-operations/repository';

import { ApproveEMBLDrawerComponent } from './ApproveEMBLDrawer.component';

const ApproveEMBLDrawerContainer = () => {
  const isLoading = useSelector(R.selectors.billOfLadingCommon.getIsFlowStepLoading);

  return (
    <ApproveEMBLDrawerComponent
      loading={isLoading}
      approveDraftMBL={UC.billOfLading.approveDraftMBL}
      onClose={appUC.drawer.closeDrawer}
    />
  );
};

export { ApproveEMBLDrawerContainer as ApproveEMBLDrawer };
