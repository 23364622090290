import moment from 'moment';

import {
  ShipmentBillingInvoiceDtm,
  UpdatedCreditNoteItemDTM,
  CreatedCreditNoteDTM,
  LinkedCreditNoteDTM,
} from 'shipment-operations/models/dtm';
import { GetShipmentInvoiceContract, GetLinkedCreditNotesContract } from 'shipment-operations/models/contracts';
import { ContainerTypesConst, InvoiceStatusesEnum } from 'shipment-operations/constants';
import { apiWorker } from 'app-wrapper/repository/utilsServices';

const timeFormat = 'D MMM YYYY';

export class ShipmentBillingInvoiceService {
  public getShipmentInvoice = async (shipmentId: string, invoiceId: string, invoiceType: string) => {
    let result: ShipmentBillingInvoiceDtm | null;

    try {
      const response = await apiWorker.requestGet<GetShipmentInvoiceContract>(`/billing-service/api/v1/shipments/${shipmentId}/${invoiceType}/invoices/${invoiceId}`);
      const parsedRes = response.data;

      result = ShipmentBillingInvoiceDtm.fromPlain({
        id: parsedRes.id,
        status: parsedRes.status as InvoiceStatusesEnum,
        companyName: parsedRes.billTo.name,
        accountId: parsedRes.billTo.id,
        accountIdFrom: parsedRes.billFrom.id,
        companyNameFrom: parsedRes.billFrom.name,
        phoneFrom: parsedRes.billFrom.phone,
        emailFrom: parsedRes.billFrom.email,
        reference: parsedRes.reference,
        billedDate: moment(parsedRes.createdAt).format(timeFormat),
        createdAt: moment(parsedRes.createdAt).format(timeFormat),
        dueDate: moment(parsedRes.dueDate).format(timeFormat),
        billed: parsedRes.billed,
        adjusted: parsedRes.adjusted,
        paid: parsedRes.paid,
        balance: parsedRes.balance,
        number: parsedRes.number,
        charges: parsedRes.items.map((item) => ({
          id: item.id,
          charge: {
            id: item.charge.id,
            additional: item.charge.additional,
            status: item.charge.status,
            createdAt: item.charge.createdAt,
            createdBy: item.charge.createdBy,
            designation: item.charge.designation,
            priceBy: item.charge.priceBy,
            measureBy: item.charge.measureBy,
            chargeCode: {
              code: item.charge.chargeCode.code,
              description: item.charge.chargeCode.code === 'MSC' ? (item.charge.metadata?.originalDescription?.toLowerCase() || item.charge.chargeCode.description) : item.charge.chargeCode.description,
              mode: item.charge.chargeCode.mode,
              type: item.charge.chargeCode.type,
              subType: item.charge.chargeCode.subType,
              occurrence: item.charge.chargeCode.occurrence,
              loadType: item.charge.chargeCode.loadType,
              status: '',
            },
            applianceRange: {
              minValue: item.charge.applianceRange?.minValue,
              maxValue: item.charge.applianceRange?.maxValue,
            },
            subjectTo: item.charge.subjectTo,
            applied: item.charge.applied,
            currency: item.charge.currency,
            costPerUnit: item.charge.costPerUnit,
            numberOfUnits: item.charge.numberOfUnits,
            totalCost: item.charge.totalCost,
            container: item.charge.container ? {
              id: item.charge.container.id,
              type: ContainerTypesConst[item.charge.container.type as keyof typeof ContainerTypesConst],
              number: item.charge.container.number,
            } : {
              id: 0,
              type: '',
              number: '',
            },
            contract: item.charge.contract && {
              id: item.charge.contract.id,
              name: item.charge.contract.name,
              number: item.charge.contract.number,
              scac: item.charge.contract.scac,
            },
            transportationIds: item.charge.transportationIds,
            rateId: item.charge.rateId,
            active: item.charge.active,
            invoiced: item.charge.invoiced,
            balance: item.charge.balance,
            budget: item.charge.budget,
          },
          costPerUnit: item.costPerUnit,
          numberOfUnits: item.numberOfUnits,
          amount: item.amount,
          invoiced: item.invoiced,
        })),
        billFrom: parsedRes.billFrom && {
          id: parsedRes.billFrom.id,
          createdAt: parsedRes.billFrom.createdAt,
          organizationId: parsedRes.billFrom.organizationId,
          name: parsedRes.billFrom.name,
          phone: parsedRes.billFrom.phone,
          phone2: parsedRes.billFrom.phone2,
          email: parsedRes.billFrom.email,
          usci: parsedRes.billFrom.usci,
          taxId: parsedRes.billFrom.taxId,
        },
        billTo: parsedRes.billTo && {
          id: parsedRes.billTo.id,
          createdAt: parsedRes.billTo.createdAt,
          organizationId: parsedRes.billTo.organizationId,
          name: parsedRes.billTo.name,
          phone: parsedRes.billTo.phone,
          phone2: parsedRes.billTo.phone2,
          email: parsedRes.billTo.email,
          usci: parsedRes.billTo.usci,
          taxId: parsedRes.billTo.taxId,
        },
        transactions: parsedRes.transactions?.map((item) => ({
          id: item.id,
          createdAt: item.createdAt,
          createdBy: item.createdBy,
          amount: item.amount,
          source: {
            id: item.source?.id,
            number: item.source?.number,
            status: item.source?.status,
            createdBy: item.source?.createdBy,
            paid: item.source?.paid,
            balance: item.source?.balance,
            type: item?.source?.type,
          },
        })),
      });
    } catch {
      throw new Error('Something wrong, please try again');
    }
    return result;
  }

  public getInvoices = async (shipmentId: string | number, invoiceIds: string, category: string) => {
    let result: ShipmentBillingInvoiceDtm[] | null;
    try {
      const response = await apiWorker.requestGet<GetShipmentInvoiceContract[]>(
        `/billing-service/api/v1/shipments/${shipmentId}/${category}/invoices?invoiceIds=${invoiceIds}`,
      );
      const parsedResponse = response.data.map((parsedRes) => {
        const parsedItem = ShipmentBillingInvoiceDtm.fromPlain({
          id: parsedRes.id,
          status: parsedRes.status as InvoiceStatusesEnum,
          companyName: parsedRes.billTo.name,
          accountId: parsedRes.billTo.id,
          accountIdFrom: parsedRes.billFrom.id,
          billedDate: moment(parsedRes.createdAt).format(timeFormat),
          dueDate: moment(parsedRes.dueDate).format(timeFormat),
          billed: parsedRes.billed,
          adjusted: parsedRes.adjusted,
          paid: parsedRes.paid,
          balance: parsedRes.balance,
          number: parsedRes.number,
          charges: parsedRes.items.map((item) => ({
            id: item.id,
            charge: {
              id: item.charge.id,
              additional: item.charge.additional,
              status: item.charge.status,
              createdAt: item.charge.createdAt,
              createdBy: item.charge.createdBy,
              designation: item.charge.designation,
              priceBy: item.charge.priceBy,
              measureBy: item.charge.measureBy,
              chargeCode: {
                code: item.charge.chargeCode.code,
                description: item.charge.chargeCode.description,
                mode: item.charge.chargeCode.mode,
                type: item.charge.chargeCode.type,
                subType: item.charge.chargeCode.subType,
                occurrence: item.charge.chargeCode.occurrence,
                loadType: item.charge.chargeCode.loadType,
                status: '',
              },
              applianceRange: {
                minValue: item.charge.applianceRange?.minValue,
                maxValue: item.charge.applianceRange?.maxValue,
              },
              subjectTo: item.charge.subjectTo,
              applied: item.charge.applied,
              currency: item.charge.currency,
              costPerUnit: item.charge.costPerUnit,
              numberOfUnits: item.charge.numberOfUnits,
              totalCost: item.charge.totalCost,
              container: item.charge.container ? {
                id: item.charge.container.id,
                type: ContainerTypesConst[item.charge.container.type as keyof typeof ContainerTypesConst],
                number: item.charge.container.number,
              } : {
                id: 0,
                type: '',
                number: '',
              },
              contract: item.charge.contract && {
                id: item.charge.contract.id,
                name: item.charge.contract.name,
                number: item.charge.contract.number,
                scac: item.charge.contract.scac,
              },
              transportationIds: item.charge.transportationIds,
              rateId: item.charge.rateId,
              active: item.charge.active,
              invoiced: item.charge.invoiced,
              balance: item.charge.balance,
              budget: item.charge.budget,
            },
            costPerUnit: item.costPerUnit,
            numberOfUnits: item.numberOfUnits,
            amount: item.amount,
            invoiced: item.invoiced,
          })),
          shipment: {
            id: parsedRes.shipment?.id,
            organizationId: parsedRes.shipment?.organizationId,
            shipmentName: parsedRes.shipment.shipmentName,
          },
        });

        if (!parsedItem.isValid()) {
          console.error('Data from API does not match with contract');
        }

        return parsedItem;
      });
      result = parsedResponse.filter((el) => el !== null) as ShipmentBillingInvoiceDtm[];
    } catch (e) {
      throw new Error('Something wrong, please try again');
    }
    return result;
  }

  public createCreditNote = async (
    shipmentId: string, invoiceId: string, items: UpdatedCreditNoteItemDTM[], description: string, reason: string, type: string,
  ) => {
    let result: CreatedCreditNoteDTM | null;
    try {
      const res = await apiWorker.requestPost(
        `/billing-service/api/v1/shipments/${shipmentId}/${type}/invoices/${invoiceId}/adjustments`,
        {
          description,
          items,
          reason,
        },
      );

      const parsedRes = res.data;

      result = CreatedCreditNoteDTM.fromPlain({
        id: parsedRes.id,
        balance: parsedRes.balance,
        number: parsedRes.number,
        amount: parsedRes.amount,
      });

      return result;
    } catch (e) {
      throw new Error('Something wrong, please try again');
    }
  }

  public getLinkedCreditNotes = async (shipmentId: string, invoiceId: string, type: string) => {
    let result: LinkedCreditNoteDTM[] | null;
    try {
      const response = await apiWorker.requestGet<GetLinkedCreditNotesContract[]>(`/billing-service/api/v1/shipments/${shipmentId}/${type}/invoices/${invoiceId}/adjustments`);

      const parsedResponse = response.data.map((item) => {
        const parsedItem = LinkedCreditNoteDTM.fromPlain({
          id: item.id,
          number: item.number,
          createdAt: moment(item.createdAt).format(timeFormat),
          amount: item.amount,
        });
        if (!parsedItem.isValid()) {
          console.error('Data from API does not match with contract');
        }
        return parsedItem;
      });
      result = parsedResponse.filter((el) => el !== null) as LinkedCreditNoteDTM[];
    } catch (e) {
      throw new Error('Something wrong, please try again');
    }
    return result;
  }
}
