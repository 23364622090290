import React, { memo } from 'react';

import { useSelector } from 'react-redux';
import { R } from 'shipment-operations/repository';
import { UC } from 'shipment-operations/controllers';
import {
  ProfitSwitcherComponent,
} from 'shipment-operations/view/pages/ShipmentCharges/components/ProfitSwitcher/ProfitSwitcher.component';

const ProfitSwitcherContainer = memo(() => {
  const isPercentage = useSelector(R.selectors.shipmentCharges.getIsPercentage);
  const permissions = useSelector(R.selectors.shipment.getShipmentChargesPermissions);

  return (
    <ProfitSwitcherComponent
      permissions={permissions}
      isPercentage={isPercentage}
      onToggle={UC.shipmentCharges.togglePercentage}
    />
  );
});

export { ProfitSwitcherContainer };
