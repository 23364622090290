import styled from 'styled-components';

export const Wrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

export const DocumentUploadSection = styled.div`
  width: 100%;
`;
