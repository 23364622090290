import { R } from 'monetary/repository';
import React, {
  FC,
} from 'react';
import { useSelector } from 'react-redux';
import { CarriersComponent } from './Carriers.component';

interface ICarriersComponentProps {
  formLocalState: { isCollapsed?: boolean }
  setFormLocalState: React.Dispatch<{ isCollapsed?: boolean }>
  forceCollapse?: boolean
}

export const Carriers: FC<ICarriersComponentProps> = (({
  formLocalState,
  setFormLocalState,
  forceCollapse,
}) => {
  const {
    isSubmitVisited,
    isSubmitClicked,
    isAllFieldDisabled,
    isCollapse,
    RFRById,
  } = useSelector(R.selectors.freightQuote.getFreightQuoteCurrent);
  const getFreightQuoteCurrentFormCarriersValues = RFRById?.params?.carriers;

  return (
    <CarriersComponent
      formLocalState={formLocalState}
      isAllDisabled={isAllFieldDisabled}
      setFormLocalState={setFormLocalState}
      forceCollapse={forceCollapse}
      isCollapsed={isCollapse}
      carriersValues={getFreightQuoteCurrentFormCarriersValues}
      isSubmitVisited={isSubmitVisited}
      isSubmitClicked={isSubmitClicked}
    />
  );
});
