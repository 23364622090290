import React from 'react';
import { useSelector } from 'react-redux';

import { UC } from 'user-management/controllers';
import { R } from 'user-management/repository';

import { AddOrChangeTeamDrawerComponent } from './AddOrChangeTeamDrawer.component';

export const AddOrChangeTeamDrawer = () => {
  const isOpened = useSelector(R.selectors.teams.getIsAddTeamDrawerOpened);
  const isLoading = useSelector(R.selectors.teams.getIsAddTeamDrawerLoading);
  const teams = useSelector(R.selectors.teams.getTeams);
  const members = useSelector(R.selectors.teams.getAccountManagersMembers);
  const tempAccountTeam = useSelector(R.selectors.teams.getTempAccountTeam);
  const tempAccountManager = useSelector(R.selectors.teams.getTempAccountManager);
  const isEditMode = useSelector(R.selectors.teams.getIsAddOrChangeDrawerInEditMode);

  return (
    <AddOrChangeTeamDrawerComponent
      isVisible={isOpened}
      isEditMode={isEditMode}
      onClose={UC.teams.closeChangeTeamDrawer}
      isLoading={isLoading}
      teams={teams}
      members={members}
      tempTeam={tempAccountTeam}
      tempAccountManager={tempAccountManager}
      setTempTeam={UC.teams.setTempAccountTeam}
      setTempAccountManager={UC.teams.setTempAccountManager}
    />
  );
};
