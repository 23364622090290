import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';

import { AddOrRemoveCargoComponent } from './AddOrRemoveCargo.component';

interface AddOrRemoveCargoContainerProps {
  cargoId: number;
}

const AddOrRemoveCargoContainer: FC<AddOrRemoveCargoContainerProps> = ({ cargoId }) => {
  const isFirstCargo = useSelector(R.selectors.manualBookingWizard.getIsFirstCargo(cargoId));

  return (
    <AddOrRemoveCargoComponent
      isFirstCargo={isFirstCargo}
    />
  );
};

export { AddOrRemoveCargoContainer as AddOrRemoveCargo };
