import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';

import { ShipmentInstructionsContentComponent } from './ShipmentInstructionsContent.component';

const ShipmentInstructionsContentContainer: FC = () => {
  const isLoading = useSelector(R.selectors.billOfLadingCommon.getIsLoading);

  return (
    <ShipmentInstructionsContentComponent isLoading={isLoading} />
  );
};

export { ShipmentInstructionsContentContainer as ShipmentInstructionsContent };
