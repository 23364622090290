import styled, { css } from 'styled-components';

export const textColorFirst = css`
  color: ${({ theme }) => theme.themesColors.characterBrandingTitle85};
`;

export const divFlex = css`
  display: flex;
  position: relative;
  ${textColorFirst}
`;

// Div

export const EmptyStateForSectionDiv = styled.div.attrs({
  'data-class': 'EmptyStateForSectionDiv',
})`
  ${divFlex}
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-width: 270px;
  align-items: center;
  justify-content: center;
`;

export const EmptyStateForSectionDivWrapper = styled.div.attrs({
  'data-class': 'EmptyStateForSectionDivWrapper',
})`
  ${divFlex}
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const EmptyStateForSectionIconWrap = styled.div.attrs({
  'data-class': 'EmptyStateForSectionIconWrap',
})`
  ${divFlex}
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const EmptyStateForSectionIconSecondWrap = styled.div.attrs({
  'data-class': 'EmptyStateForSectionIconSecondWrap',
})`
  ${divFlex}
  position: absolute;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  top: 16px;
`;

// Content

export const EmptyStateForSectionText = styled.div.attrs({
  'data-class': 'EmptyStateForSectionText',
})`
  ${divFlex}
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 12px;
`;

export const EmptyStateForSectionTextFirst = styled.div.attrs({
  'data-class': 'EmptyStateForSectionTextFirst',
})`
  ${divFlex}
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.themesColors.neutralBranding10};
  ${({ theme }) => theme?.fonts?.BodyTextMedium};
`;

export const EmptyStateForSectionTextSecond = styled.div.attrs({
  'data-class': 'EmptyStateForSectionTextSecond',
})`
  ${divFlex}
  flex-direction: column;
  align-items: center;
  justify-content: center;

  margin-top: 4px;
  color: ${({ theme }) => theme.themesColors.secondaryDot45};
  ${({ theme }) => theme?.fonts?.SmallTextFootnoteDescription};
`;

export const EmptyStateForSectionTextButton = styled.div.attrs({
  'data-class': 'EmptyStateForSectionTextButton',
})`
  ${divFlex}
  align-items: center;
  justify-content: center;

  margin-top: 0px;
  color: ${({ theme }) => theme.themesColors.neutralBranding10};
  ${({ theme }) => theme?.fonts?.SmallTextFootnoteDescription};

  svg {
    margin-right: 8px;
    color: inherit;
  }

  button {
    height: 22px;
    padding: 4px 8px;
  }
`;
