import React, { useMemo, FC } from 'react';
import { useParams } from 'react-router-dom';
import Divider from 'antd/es/divider';
import { useTranslation } from 'react-i18next';
import get from 'lodash/fp/get';

import { normalizationCost } from 'app-wrapper/utils';
import { RouteNames } from 'app-wrapper/constants';
import { SkeletonSection } from 'app-wrapper/view/components/Skeleton/Skeleton.component';
import { RECEIVABLES } from 'shipment-operations/constants';
import { StyledTable, TableHeader } from 'shipment-operations/view/components/CustomTable/CustomTable.styled';

import { PaymentDocuments } from './components';
import {
  Wrapper,
  Title,
  StyledCell,
  BottomWrapper,
  AmountWrapper,
  UploadWrapper,
  AmountLine,
  AmountKey,
  AmountValue,
  NameOfDocument,
  SkeletonWrapper,
} from './PaymentTable.styled';

interface ITableItem {
  id?: string
  dueDate?: string
  total?: number
  balance?: number
  payment?: number
  shipmentId?: number
  linkId?: number
}

interface IPaymentTable {
  data: ITableItem[]
  receivedAmount: number
  usedAmount: number
  leftAmount: number
  viewMode: boolean
  isLoading?: boolean
}

const PaymentTable: FC<IPaymentTable> = ({
  data, receivedAmount, usedAmount, leftAmount, viewMode, isLoading,
}) => {
  const { t } = useTranslation();
  const { invoiceType } = useParams<'invoiceType'>();
  const columns = useMemo(() => (viewMode ? [
    {
      title: t('payment.id'),
      dataIndex: 'id',
      key: 'id',
      width: '25%',
      render: (id: string, record: ITableItem | {}) => {
        const shipmentId = get(['shipmentId'], record);
        const linkId = get(['linkId'], record);
        return (
          <NameOfDocument to={RouteNames.SHIPMENT_BILLING_INVOICE(shipmentId, linkId, invoiceType)}>{id}</NameOfDocument>
        );
      },
    },
    {
      title: t('payment.dueDate'),
      dataIndex: 'dueDate',
      key: 'dueDate',
      width: '25%',
    },
    {
      title: t('payment.total'),
      dataIndex: 'total',
      key: 'total',
      width: '25%',
      align: 'right' as 'right',
      render: (sum: number) => `$ ${normalizationCost(sum, { toFixed: 2, thousandthSeparatorComma: true })}`,
    },
    {
      title: t('payment.payment'),
      dataIndex: 'payment',
      key: 'payment',
      width: '25%',
      align: 'right' as 'right',
      render: (title: string) => `$ ${normalizationCost(title, { toFixed: 2, thousandthSeparatorComma: true })}`,
    },
  ] : [
    {
      title: t('payment.id'),
      dataIndex: 'id',
      key: 'id',
      width: '20%',
    },
    {
      title: t('payment.dueDate'),
      dataIndex: 'dueDate',
      key: 'dueDate',
      width: '20%',
    },
    {
      title: t('payment.total'),
      dataIndex: 'total',
      key: 'total',
      width: '20%',
      align: 'right' as 'right',
      render: (sum: number) => `$ ${normalizationCost(sum, { toFixed: 2, thousandthSeparatorComma: true })}`,
    },
    {
      title: t('payment.balance'),
      dataIndex: 'balance',
      key: 'balance',
      width: '20%',
      align: 'right' as 'right',
      render: (sum: number) => `$ ${normalizationCost(sum, { toFixed: 2, thousandthSeparatorComma: true })}`,
    },
    {
      title: t('payment.payment'),
      dataIndex: 'payment',
      key: 'payment',
      width: '20%',
      align: 'right' as 'right',
      render: (title: string) => <StyledCell>$ {normalizationCost(title, { toFixed: 2, thousandthSeparatorComma: true })}</StyledCell>,
    },
  ]), [viewMode]);

  if (isLoading) {
    return (
      <SkeletonWrapper>
        <SkeletonSection height="317px" />
      </SkeletonWrapper>
    );
  }

  return (
    <Wrapper>
      <Title>{invoiceType === RECEIVABLES ? t('payment.invoice') : t('payment.bill')}</Title>

      <TableHeader columns={columns} />
      <StyledTable
        columns={columns}
        pagination={false}
        dataSource={data}
        showHeader={false}
      />
      <Divider />
      <BottomWrapper>
        <UploadWrapper>
          <PaymentDocuments />
        </UploadWrapper>
        <AmountWrapper>
          <AmountLine>
            <AmountKey>{invoiceType === RECEIVABLES ? t('payment.amountReceived') : t('payment.amountPaid')}</AmountKey>
            <AmountValue>$ {normalizationCost(receivedAmount, { toFixed: 2, thousandthSeparatorComma: true })}</AmountValue>
          </AmountLine>
          <Divider style={{ margin: '8px 0' }} />
          <AmountLine>
            <AmountKey>{t('payment.amountUsed')}</AmountKey>
            <AmountValue>$ {normalizationCost(usedAmount, { toFixed: 2, thousandthSeparatorComma: true })}</AmountValue>
          </AmountLine>
          <Divider style={{ margin: '8px 0' }} />
          <AmountLine>
            <AmountKey>{t('payment.amountLeft')}</AmountKey>
            <AmountValue>$ {normalizationCost(leftAmount, { toFixed: 2, thousandthSeparatorComma: true })}</AmountValue>
          </AmountLine>
        </AmountWrapper>
      </BottomWrapper>
    </Wrapper>
  );
};

export { PaymentTable };
