import React, { ChangeEvent, FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { DEPARTMENT_TYPE_NAMES, EOrganizationMemberRole, OrganizationMemberRoleNames } from 'user-management/constants';
import { hasAccess } from 'app-wrapper/utils';
import { PermissionAttributePolicy } from 'shipment-operations/constants';
import {
  Button,
  Input,
  Option,
  Select,
  Tooltip,
} from 'app-wrapper/view/components';
import { AccountDepartmentDTM } from 'user-management/models/dtm';

import {
  UserInviteContainer,
  UserInviteFooter,
  UserInviteItem,
  UserInviteWrapper,
} from './UserInvite.styled';

interface IUserInviteComponentProps {
  isLoading: boolean
  wasAnyFormFieldChanged: boolean
  emailValue?: string
  emailError?: Error
  emailChange: (value: string) => void
  emailBlur: () => void
  departments: string[];
  setDepartments: (departments: string[]) => void;
  departmentsError?: boolean;
  availableDepartments: AccountDepartmentDTM[];
  roleValue?: EOrganizationMemberRole
  roleError?: Error
  roleChange: (value: EOrganizationMemberRole) => void
  roleBlur: () => void
  jobTitle: string;
  setJobTitle: (jobTitle: string) => void;
  onOk: () => void
  onCancel: () => void
  userDepartmentsAndTeamsEditPermissions?: PermissionAttributePolicy
}

export const UserInviteComponent: FC<IUserInviteComponentProps> = ({
  isLoading,
  wasAnyFormFieldChanged,
  emailValue,
  emailError,
  emailChange,
  emailBlur,
  roleValue,
  roleError,
  roleChange,
  roleBlur,
  departments,
  setDepartments,
  availableDepartments,
  departmentsError,
  onOk,
  onCancel,
  jobTitle,
  setJobTitle,
  userDepartmentsAndTeamsEditPermissions,
}) => {
  const { t } = useTranslation();

  const onEmailChangeHandler = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    emailChange(event.target.value);
  }, []);

  const handleJobTitleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setJobTitle(e.target.value);
  }, [setJobTitle]);

  const onEmailBlurHandler = useCallback(() => {
    emailBlur();
  }, []);

  const onRoleChangeHandler = useCallback((value: EOrganizationMemberRole) => {
    roleChange(value);
  }, []);

  const onRoleBlurHandler = useCallback(() => {
    roleBlur();
  }, []);

  const onOkHandler = useCallback(() => {
    onOk();
  }, []);

  const isAbleToEditDepartmentsAndTeams = hasAccess(userDepartmentsAndTeamsEditPermissions, PermissionAttributePolicy.WRITE);

  return (
    <UserInviteWrapper>
      <UserInviteContainer>
        <UserInviteItem>
          <span>{t('Email')}</span>
          <Tooltip mode="danger" title={emailError?.message} visible={!!emailError}>
            <Input
              maxLength={50}
              data-class="UserInviteEmail"
              disabled={isLoading}
              value={emailValue}
              error={!!emailError}
              onChange={onEmailChangeHandler}
              onBlur={onEmailBlurHandler}
            />
          </Tooltip>
        </UserInviteItem>

        <UserInviteItem>
          <span>{t('Role')}</span>
          <Tooltip mode="danger" title={roleError?.message} visible={!!roleError}>
            <Select
              data-class="UserInviteRole"
              disabled={isLoading}
              value={roleValue}
              error={!!roleError}
              onChange={onRoleChangeHandler}
              onBlur={onRoleBlurHandler}
            >
              <Option value={EOrganizationMemberRole.admin}>
                {OrganizationMemberRoleNames[EOrganizationMemberRole.admin]}
              </Option>
              <Option value={EOrganizationMemberRole.member}>
                {OrganizationMemberRoleNames[EOrganizationMemberRole.member]}
              </Option>
            </Select>
          </Tooltip>
        </UserInviteItem>

        <UserInviteItem>
          <span>{t('Job Title')}</span>

          <Input
            data-class="user-invite-job-title"
            disabled={isLoading}
            value={jobTitle}
            onChange={handleJobTitleChange}
          />
        </UserInviteItem>

        {isAbleToEditDepartmentsAndTeams ? (
          <>
            <UserInviteItem>
              <span>{t('Departments*')}</span>

              <Select
                mode="multiple"
                data-class="user-invite-departments-select"
                disabled={isLoading}
                value={departments}
                onChange={setDepartments}
                error={departmentsError}
                allowClear
              >
                {availableDepartments.map((department) => (
                  <Option key={String(department?.id)} value={String(department?.id)}>
                    {DEPARTMENT_TYPE_NAMES[department.type]}
                  </Option>
                ))}
              </Select>
            </UserInviteItem>
          </>
        ) : null}
      </UserInviteContainer>

      <UserInviteFooter>
        <Button type="default" onClick={onCancel}>{t('Cancel')}</Button>
        <Button
          data-class="UserInviteButton"
          disabled={wasAnyFormFieldChanged}
          loading={isLoading}
          onClick={onOkHandler}
        >
          {t('Send Invite')}
        </Button>
      </UserInviteFooter>
    </UserInviteWrapper>
  );
};
