import { createSelector } from 'reselect';

import { RootState } from 'app-wrapper/store';

const localState = (state: RootState) => state.vgmTaskDrawer;

const getIsLoading = createSelector(
  localState,
  (state) => state.isLoading,
);

const getIsDrawerOpened = createSelector(
  localState,
  (state) => state.isDrawerOpened,
);

const getShipment = createSelector(
  localState,
  (state) => state.shipment,
);

const getContainers = createSelector(
  localState,
  (state) => state.containers,
);

const getMarkedContainers = createSelector(
  localState,
  (state) => state.markedContainers,
);

const getIsAbleToAccept = createSelector(
  localState,
  (state) => {
    const { containers, markedContainers } = state;
    let isAbleToAccept = true;

    containers.forEach(({ id }) => {
      if (!markedContainers.includes(id)) {
        isAbleToAccept = false;
      }
    });

    return isAbleToAccept;
  },
);

const getDueDate = createSelector(
  localState,
  (state) => state.dueDate,
);

export const vgmTaskDrawerSelectors = {
  getIsLoading,
  getIsDrawerOpened,
  getShipment,
  getContainers,
  getMarkedContainers,
  getIsAbleToAccept,
  getDueDate,
};
