import styled from 'styled-components';

export const Wrap = styled.div`
  width: 100%;
  gap: 8px;
  display: flex;
  flex-direction: row;
`;

export const Content = styled.div`
  padding: 12px 16px;
  min-width: calc(50% - 4px);
  min-height: 74px;
  display: flex;
  flex-direction: row;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 3px;
  border: 1px solid rgba(234, 238, 248, 1);
`;
