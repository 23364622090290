import React, { FC, memo } from 'react';
import { useSelector } from 'react-redux';

import { REQUEST_STATUS } from 'app-wrapper/constants';

import { UC } from 'user-management/controllers';
import { R } from 'user-management/repository';

import { OrganizationPaymentMethod } from 'shipment-operations/constants';

import { CustomerAddInternalComponent } from './CustomerAddInternal.component';

const CustomerAddInternalContainer: FC = () => {
  const customerOrganization = useSelector(R.selectors.userOrganizationData.getCustomersAddInternal);
  const organizationFieldsErrors = useSelector(R.selectors.userOrganizationData.getCustomersAddInternalErrors);
  const salesManagers = useSelector(R.selectors.userOrganizationData.getSalesManagers);
  const tempSalesManager = useSelector(R.selectors.userOrganizationData.getTempSalesManager);
  const tempSalesManagerError = useSelector(R.selectors.userOrganizationData.getTempSalesManagerError);

  const getOrganizationsAddStatus = useSelector(R.selectors.userOrganizationData.getOrganizationsAddStatus);

  return (
    <CustomerAddInternalComponent
      organization={customerOrganization}
      isOrganizationsAddStatus={getOrganizationsAddStatus === REQUEST_STATUS.pending}
      organizationFieldsErrors={organizationFieldsErrors}
      isDeferredPayment={customerOrganization.paymentMethod?.getTypeReq() === OrganizationPaymentMethod.DEFERRED_PAYMENT}
      closeCAIDrawer={UC.organizationProfile.closeCustomerAddInternalDrawer}
      saveCAI={UC.organizationProfile.saveCustomerAddInternal}
      onChangeUpdateEmail={UC.organizationProfile.onChangeCustomerAddInternalPrimaryEmail}
      onBlurUpdateEmail={UC.organizationProfile.onBlurCustomerAddInternalPrimaryEmail}
      onFocusUpdateEmail={UC.organizationProfile.onFocusCustomerAddInternalPrimaryEmail}
      onChangeUpdatePhone={UC.organizationProfile.onChangeCustomerAddInternalPhone}
      onChangeUpdatePhone2={UC.organizationProfile.onChangeCustomerAddInternalPhone2}
      salesManagers={salesManagers}
      tempSalesManager={tempSalesManager}
      tempSalesManagerError={tempSalesManagerError}
      setTempSalesManager={UC.organizationProfile.setTempSalesManager}
      onChangeUpdateEmailInformation={UC.organizationProfile.onChangeCustomerAddInternalEmail}
      onChangeUpdateWebUrl={UC.organizationProfile.onChangeCustomerAddInternalWebUrl}
      onFocusUpdatePhone={UC.organizationProfile.onFocusCustomerAddInternalPhone}
      onBlurUpdatePhone={UC.organizationProfile.onBlurCustomerAddInternalPhone}
      onFocusUpdatePhone2={UC.organizationProfile.onFocusCustomerAddInternalPhone2}
      onBlurUpdatePhone2={UC.organizationProfile.onBlurCustomerAddInternalPhone2}
      onFocusUpdateEmailInformation={UC.organizationProfile.onFocusCustomerAddInternalEmailInformation}
      onBlurUpdateEmailInformation={UC.organizationProfile.onFocusCustomerAddInternalEmail}
      onFocusUpdateWebUrl={UC.organizationProfile.onFocusCustomerAddInternalWebUrl}
      onBlurUpdateWebUrl={UC.organizationProfile.onBlurCustomerAddInternalWebUrl}
      onChangeUpdatePrimaryName={UC.organizationProfile.onChangeCustomerAddInternalPrimaryName}
      onChangeUpdatePrimaryLastName={UC.organizationProfile.onChangeCustomerAddInternalPrimaryLastName}
      onBlurUpdatePrimaryName={UC.organizationProfile.onBlurCustomerAddInternalPrimaryName}
      onBlurUpdatePrimaryLastName={UC.organizationProfile.onBlurCustomerAddInternalPrimaryLastName}
      onChangeUpdatePrimaryPhone={UC.organizationProfile.onChangeCustomerAddInternalPrimaryPhone}
      onFocusUpdatePrimaryPhone={UC.organizationProfile.onFocusCustomerAddInternalPrimaryPhone}
      onBlurUpdatePrimaryPhone={UC.organizationProfile.onBlurCustomerAddInternalPrimaryPhone}
      onChangeUpdatePaymentType={UC.organizationProfile.onChangeCustomerAddInternalPaymentType}
      onBlurCustomerAddInternalPaymentType={UC.organizationProfile.onBlurCustomerAddInternalPaymentType}
      onChangeUpdatePaymentCreditTerm={UC.organizationProfile.onChangeCustomerAddInternalPaymentCreditTerm}
      onBlurCustomerAddInternalPaymentCreditTerm={UC.organizationProfile.onBlurCustomerAddInternalPaymentCreditTerm}
      onChangeUpdatePaymentCreditLimit={UC.organizationProfile.onChangeCustomerAddInternalPaymentCreditLimit}
      onBlurCustomerAddInternalPaymentCreditLimit={UC.organizationProfile.onBlurCustomerAddInternalPaymentCreditLimit}
    />
  );
};

export const CustomerAddInternalPage = memo(CustomerAddInternalContainer);
