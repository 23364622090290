import React, { FC } from 'react';

import { Bold } from 'shipment-operations/view/components/HBLDocumentPDF/components';

import { Wrapper, Sign } from './Cell.styled';

interface CellProps {
  label: string
  value: string | number
  sign?: string
}

const CellComponent: FC<CellProps> = ({ label, value, sign }) => (
  <Wrapper>
    <Bold bottomMargin>{label}</Bold>
    <span>{value}</span>
    {!!sign && <Sign>{sign}</Sign>}
  </Wrapper>
);

export { CellComponent as Cell };
