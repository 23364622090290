import React from 'react';

import { IPropsIcon } from 'app-wrapper/interfaces/icon';

import { Icon } from './NavigatorIconSvg.styled';

export const NavigatorIconSvg = (props: IPropsIcon) => {
  const { width, height, fill } = props;
  return (
    <Icon
      className="NavigatorIconSvg"
      width={width || '15'}
      height={height || '15'}
      viewBox="0 0 15 15"
      fill={fill || 'currentColor'}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M8.04688 7.23047V4.05859H10.125C10.3984 4.05859 10.5625 3.70312 10.3438 3.48438L7.71875 0.859375C7.58203 0.722656 7.39062 0.722656 7.25391 0.859375L4.62891 3.48438C4.41016 3.70312 4.57422 4.05859 4.84766 4.05859H6.95312V7.23047H3.78125V5.125C3.78125 4.85156 3.42578 4.6875 3.20703 4.90625L0.582031 7.53125C0.445312 7.66797 0.445312 7.85938 0.582031 7.99609L3.20703 10.6211C3.42578 10.8398 3.78125 10.6758 3.78125 10.4023V8.32422H6.95312V11.4688H4.84766C4.57422 11.4688 4.41016 11.8242 4.62891 12.043L7.25391 14.668C7.39062 14.8047 7.58203 14.8047 7.71875 14.668L10.3438 12.043C10.5625 11.8242 10.3984 11.4688 10.125 11.4688H8.01953V8.32422H11.1914V10.4023C11.1914 10.6758 11.5469 10.8398 11.7656 10.6211L14.3906 7.99609C14.5273 7.85938 14.5273 7.66797 14.3906 7.53125L11.7656 4.90625C11.5469 4.6875 11.1914 4.85156 11.1914 5.125V7.23047H8.04688Z" fill="currentColor" />
    </Icon>
  );
};
