import React from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';
import { UC } from 'shipment-operations/controllers';

import { FirstStepComponent } from './FirstStep.component';

const FirstStepContainer = () => {
  const cargoTable = useSelector(R.selectors.shipmentChanges.getCargoTable);
  const containersTable = useSelector(R.selectors.shipmentChanges.getContainersTable);
  const transportPlanCurrent = useSelector(R.selectors.shipmentChanges.getTransportPlanCurrent);
  const loading = useSelector(R.selectors.shipmentChanges.getLoading);
  const shipmentId = useSelector(R.selectors.overview.getShipmentId);

  return (
    <FirstStepComponent
      cargoTable={cargoTable}
      containersTable={containersTable}
      transportPlanCurrent={transportPlanCurrent}
      loading={loading}
      loadData={UC.shipmentChanges.loadData}
      savedShipmentId={shipmentId}
    />
  );
};

export { FirstStepContainer as FirstStep };
