import styled from 'styled-components';

import { Button as BaseButton } from 'app-wrapper/view/components';
import { FlexCol } from 'app-wrapper/view/guideline';

export const Content = styled(FlexCol)`
  margin: 250px auto auto auto;
  align-items: center;
`;

export const Image = styled.img`
  margin-bottom: 4px;
  width: 72px;
  height: auto;
`;

export const ContentTitle = styled.span`
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
`;

export const ContentText = styled.span`
  margin-bottom: 16px;
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.themesColors.characterSecondaryDot45};
`;

export const Button = styled(BaseButton)`
  width: 145px;
`;
