import React, { FC, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Spin from 'antd/es/spin';
import { UploadFile } from 'antd/lib/upload/interface';

import { ShipmentHazmatContainerDTM } from 'shipment-operations/models/dtm';

import {
  SDHLoaderWrapper,
  SDHTitle,
  SDHLayout,
  SDHTable,
} from './ShipmentDocumentsHazmat.styled';
import { SDHContainerColumn, SDHUploadDocument } from './components';

interface IShipmentDocumentsHazmatComponentProps {
  fetchContainers: (shipmentId?: string) => void;
  containers: ShipmentHazmatContainerDTM[];
  isLoading: boolean;
}

export const ShipmentDocumentsHazmatComponent: FC<IShipmentDocumentsHazmatComponentProps> = ({
  fetchContainers,
  containers,
  isLoading,
}) => {
  const { t } = useTranslation();
  const { shipmentId = '' } = useParams<'shipmentId'>();

  useEffect(() => {
    fetchContainers(shipmentId);
  }, []);

  const columns = useMemo(() => [
    {
      title: 'Container',
      dataIndex: 'container',
      key: 'container',
      width: '49%',
      render: (item: string, record: ShipmentHazmatContainerDTM | {}) => (
        <SDHContainerColumn {...record as ShipmentHazmatContainerDTM} />
      ),
    },
    {
      title: 'Document',
      dataIndex: 'document',
      key: 'document',
      width: '49%',
      render: (item: string, record: ShipmentHazmatContainerDTM | {}) => {
        const container = record as ShipmentHazmatContainerDTM;

        return (
          <SDHUploadDocument
            containerId={container?.id}
            file={container?.file as UploadFile}
          />
        );
      },
    },
  ], []);

  return (
    <SDHLayout>
      <SDHTitle>
        {t('IMO Declarations')}
      </SDHTitle>

      {isLoading ? (
        <SDHLoaderWrapper>
          <Spin />
        </SDHLoaderWrapper>
      ) : (
        <SDHTable
          columns={columns}
          dataSource={containers}
          pagination={false}
        />
      )}
    </SDHLayout>
  );
};
