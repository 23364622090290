import React from 'react';
import { observer } from 'mobx-react-lite';

import { useMobxStore } from 'app-wrapper/mobxStores';
import { UC as appUC } from 'app-wrapper/controllers';

import { ServiceDocumentUploadComponent } from './ServiceDocumentUpload.component';

const ServiceDocumentUploadContainer = observer(() => {
  const { shipmentServices } = useMobxStore();

  return (
    <ServiceDocumentUploadComponent
      documentType={shipmentServices.state.documentToUploadType}
      onClose={appUC.drawer.closeDrawer}
    />
  );
});

export { ServiceDocumentUploadContainer as ServiceDocumentUpload };
