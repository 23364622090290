import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { R as userManagementR } from 'user-management/repository';
import { R } from 'authentication/repository';

import { UserContactsComponent } from './UserContacts.component';

const UserContactsContainer: FC = () => {
  const email = useSelector(R.selectors.auth.getEmail);
  const phone = useSelector(userManagementR.selectors.userOrganizationData.getOrganizationUserPhone);

  return (
    <UserContactsComponent
      email={email}
      phone={phone}
    />
  );
};

export { UserContactsContainer as UserContacts };
