import { v4 as uuidv4 } from 'uuid';

import { apiWorker } from 'app-wrapper/repository/utilsServices';

import {
  TGetShippingPartyListResponse,
  IPostShippingPartyResponse,
  IPutShippingPartyResponse,
} from 'shipment-operations/models/contracts';
import {
  AddressDTM,
  ContactDTM,
  ShippingPartyDTM,
  ShippingPartyReference,
} from 'shipment-operations/models/dtm';
import { EShippingPartyTypes } from 'shipment-operations/constants';

export class ShippingPartiesService {
  base = '/shipment-service/api/v1/shipments'

  public getById = async (shipmentId: string, partyId: number) => {
    let shippingParty: ShippingPartyDTM | null = null;

    const rawResponse = await apiWorker.requestGet<IPutShippingPartyResponse>(`${this.base}/${shipmentId}/parties/${partyId}`);

    const addressList = rawResponse.data.contactData.company.addresses?.map((address) => AddressDTM.fromPlain({
      id: address.id,
      country: address.country,
      state: address.state,
      city: address.city,
      address1: address.address1,
      address2: address.address2,
      postalCode: address.postalCode,
      closestPort: address.closestPort,
    })) || [];

    const contactList = rawResponse.data.contactData.company.contacts?.map((contact) => ContactDTM.fromPlain({
      id: contact.id,
      fullName: contact.fullName,
      email: contact.email,
      phone: contact.phone,
      phone2: contact.phone2,
    })) || [];

    shippingParty = ShippingPartyDTM.fromPlain({
      addressList,
      contactList,
      company: {
        ...rawResponse.data.contactData?.company,
        addresses: rawResponse.data.contactData?.company.addresses || [],
        contacts: rawResponse.data.contactData?.company.contacts || [],
      },
      id: rawResponse.data.id,
      role: rawResponse.data.role as EShippingPartyTypes,
      address: {
        ...rawResponse.data.contactData.address,
        closestPort: rawResponse.data.contactData.address.closestPort || '',
      },
      contact: rawResponse.data.contactData.contact,
      hasSystemUpdatePolicy: rawResponse.data.hasSystemUpdatePolicy,
      isFreightForwarderOrgType: rawResponse.data.isFreightForwarderOrgType,
      references: rawResponse.data.references ? rawResponse.data.references.map((ref) => ({
        id: `${ref.id}`,
        value: ref.value,
        isNew: false,
      })) : [
        ShippingPartyReference.fromPlain({
          id: uuidv4(),
          value: '',
        }),
      ],
    });

    return shippingParty;
  }

  public getList = async (shipmentId: string) => {
    let list: ShippingPartyDTM[] = [];

    const rawResponse = await apiWorker.requestGet<TGetShippingPartyListResponse>(`${this.base}/${shipmentId}/parties`);

    if (rawResponse.data.length > 0) {
      const parsedResponse = rawResponse.data.map((item) => {
        const addressList = item.contactData?.company?.addresses?.map((address) => AddressDTM.fromPlain({
          id: address.id,
          country: address.country,
          state: address.state,
          city: address.city,
          address1: address.address1,
          address2: address.address2,
          postalCode: address.postalCode,
          closestPort: address.closestPort || '',
        })) || [];

        const contactList = item.contactData?.company?.contacts?.map((contact) => ContactDTM.fromPlain({
          id: contact.id,
          fullName: contact.fullName,
          email: contact.email,
          phone: contact.phone,
          phone2: contact.phone2,
        })) || [];

        const parsedItem = ShippingPartyDTM.fromPlain({
          addressList,
          contactList,
          company: {
            ...item.contactData?.company,
            addresses: item.contactData?.company.addresses || [],
            contacts: item.contactData?.company.contacts || [],
          },
          address: {
            ...item.contactData?.address,
            closestPort: item.contactData?.address?.closestPort || '',
          },
          contact: item.contactData?.contact,
          id: item.id,
          role: item.role as EShippingPartyTypes,
          hasSystemUpdatePolicy: item.hasSystemUpdatePolicy,
          isFreightForwarderOrgType: item.isFreightForwarderOrgType,
          references: item.references ? item.references.map((ref) => ({
            id: `${ref.id}`,
            value: ref.value,
            isNew: false,
          })) : [
            ShippingPartyReference.fromPlain({
              id: uuidv4(),
              value: '',
            }),
          ],
        });

        if (!parsedItem.isValid()) {
          console.error(`ShippingPartiesService.getList error: ${parsedItem.validate()}`, parsedItem.validate());
        }

        return parsedItem;
      });
      list = parsedResponse.filter((el) => el !== null) as ShippingPartyDTM[];
    }

    return list;
  }

  public postShippingParty = async (shipmentId: number, shippingParty: ShippingPartyDTM) => {
    let newShippingParty: ShippingPartyDTM | null = null;

    const references = shippingParty.references
      .filter((ref) => ref.value !== '')
      .map((ref) => ({
        value: ref.value,
      }));

    const requestBody = {
      role: shippingParty.role,
      currentOrganization: false,
      contactData: {
        address: {
          ...shippingParty.address,
          id: null,
        },
        contact: {
          ...shippingParty.contact,
          id: null,
        },
        company: shippingParty.company,
      },
      contact: shippingParty.company?.id ? {
        companyId: shippingParty.company.id,
      } : undefined,
      references,
    };

    const rawResponse = await apiWorker.requestPost<IPostShippingPartyResponse>(`${this.base}/${shipmentId}/parties`, requestBody);

    const addressList = rawResponse.data.contactData.company.addresses?.map((address) => AddressDTM.fromPlain({
      id: address.id,
      country: address.country,
      state: address.state,
      city: address.city,
      address1: address.address1,
      address2: address.address2,
      postalCode: address.postalCode,
      closestPort: address.closestPort,
    })) || [];

    const contactList = rawResponse.data.contactData.company.contacts?.map((contact) => ContactDTM.fromPlain({
      id: contact.id,
      fullName: contact.fullName,
      email: contact.email,
      phone: contact.phone,
      phone2: contact.phone2,
    })) || [];

    newShippingParty = ShippingPartyDTM.fromPlain({
      addressList,
      contactList,
      company: {
        ...rawResponse.data.contactData?.company,
        addresses: rawResponse.data.contactData?.company.addresses || [],
        contacts: rawResponse.data.contactData?.company.contacts || [],
      },
      id: rawResponse.data.id,
      role: rawResponse.data.role as EShippingPartyTypes,
      address: {
        ...rawResponse.data.contactData.address,
        closestPort: rawResponse.data.contactData.address.closestPort || '',
      },
      contact: rawResponse.data.contactData.contact,
      hasSystemUpdatePolicy: rawResponse.data.hasSystemUpdatePolicy,
      isFreightForwarderOrgType: rawResponse.data.isFreightForwarderOrgType,
      references: rawResponse.data.references ? rawResponse.data.references.map((ref) => ({
        id: `${ref.id}`,
        value: ref.value,
        isNew: false,
      })) : [
        ShippingPartyReference.fromPlain({
          id: uuidv4(),
          value: '',
        }),
      ],
    });
    if (!newShippingParty.isValid()) {
      console.error(`ShippingPartiesService.getList error: ${newShippingParty.validate()}`);
    }

    return newShippingParty;
  }

  public putShippingPartyWithContactData = async (shipmentId: number, shippingParty: ShippingPartyDTM) => {
    let newShippingParty: ShippingPartyDTM | null = null;

    const references = shippingParty.references
      .filter((ref) => ref.value !== '')
      .map((ref) => ({
        value: ref.value,
      }));

    const requestBody = {
      role: shippingParty.role,
      currentOrganization: false,
      contact: shippingParty.company?.id ? {
        companyId: shippingParty.company.id,
      } : undefined,
      contactData: {
        company: {
          ...shippingParty.company,
          taxId: shippingParty.company?.taxId || null,
        },
        address: {
          ...shippingParty.address,
          address1: shippingParty.address?.address1 || null,
          address2: shippingParty.address?.address2 || null,
          country: shippingParty.address?.country || null,
          state: shippingParty.address?.state || null,
          city: shippingParty.address?.city || null,
          postalCode: shippingParty.address?.postalCode || null,
          id: null,
        },
        contact: {
          ...shippingParty.contact,
          fullName: shippingParty.contact?.fullName || null,
          email: shippingParty.contact?.email || null,
          phone: shippingParty.contact?.phone || null,
          phone2: shippingParty.contact?.phone2 || null,
          id: null,
        },
      },
      references,
    };

    const rawResponse = await apiWorker.requestPut<IPutShippingPartyResponse>(`${this.base}/${shipmentId}/parties/${shippingParty.id}`, requestBody);

    const addressList = rawResponse.data.contactData.company.addresses?.map((address) => AddressDTM.fromPlain({
      id: address.id,
      country: address.country,
      state: address.state,
      city: address.city,
      address1: address.address1,
      address2: address.address2,
      postalCode: address.postalCode,
      closestPort: address.closestPort || '',
    })) || [];

    const contactList = rawResponse.data.contactData.company.contacts?.map((contact) => ContactDTM.fromPlain({
      id: contact.id,
      fullName: contact.fullName,
      email: contact.email,
      phone: contact.phone,
      phone2: contact.phone2,
    })) || [];

    newShippingParty = ShippingPartyDTM.fromPlain({
      addressList,
      contactList,
      company: {
        ...rawResponse.data.contactData?.company,
        addresses: rawResponse.data.contactData?.company.addresses || [],
        contacts: rawResponse.data.contactData?.company.contacts || [],
      },
      id: rawResponse.data.id,
      role: rawResponse.data.role as EShippingPartyTypes,
      address: {
        ...rawResponse.data.contactData.address,
        closestPort: rawResponse.data.contactData.address.closestPort || '',
      },
      contact: rawResponse.data.contactData.contact,
      hasSystemUpdatePolicy: rawResponse.data.hasSystemUpdatePolicy,
      isFreightForwarderOrgType: rawResponse.data.isFreightForwarderOrgType,
      references: rawResponse.data.references ? rawResponse.data.references.map((ref) => ({
        id: `${ref.id}`,
        value: ref.value,
        isNew: false,
      })) : [
        ShippingPartyReference.fromPlain({
          id: uuidv4(),
          value: '',
        }),
      ],
    });

    if (!newShippingParty.isValid()) {
      console.error(`ShippingPartiesService.getList error: ${newShippingParty.validate()}`, newShippingParty.validate());
    }

    return newShippingParty;
  }

  public putShippingParty = async (shipmentId: number, shippingParty: ShippingPartyDTM) => {
    let newShippingParty: ShippingPartyDTM | null = null;

    const references = shippingParty.references
      .filter((ref) => ref.value !== '')
      .map((ref) => ({
        value: ref.value,
      }));

    const requestBody = {
      role: shippingParty.role,
      currentOrganization: false,
      contactData: {
        company: {
          ...shippingParty.company,
          taxId: shippingParty.company?.taxId || null,
        },
        address: {
          ...shippingParty.address,
          address1: shippingParty.address?.address1 || null,
          address2: shippingParty.address?.address2 || null,
          country: shippingParty.address?.country || null,
          state: shippingParty.address?.state || null,
          city: shippingParty.address?.city || null,
          postalCode: shippingParty.address?.postalCode || null,
          id: null,
        },
        contact: {
          ...shippingParty.contact,
          fullName: shippingParty.contact?.fullName || null,
          email: shippingParty.contact?.email || null,
          phone: shippingParty.contact?.phone || null,
          phone2: shippingParty.contact?.phone2 || null,
          id: null,
        },
      },
      contact: shippingParty.company?.id ? {
        companyId: shippingParty.company.id,
      } : undefined,
      references,
    };

    const rawResponse = await apiWorker.requestPut<IPutShippingPartyResponse>(`${this.base}/${shipmentId}/parties/${shippingParty.id}`, requestBody);

    const addressList = rawResponse.data.contactData.company.addresses?.map((address) => AddressDTM.fromPlain({
      id: address.id,
      country: address.country,
      state: address.state,
      city: address.city,
      address1: address.address1,
      address2: address.address2,
      postalCode: address.postalCode,
      closestPort: address.closestPort,
    })) || [];

    const contactList = rawResponse.data.contactData.company.contacts?.map((contact) => ContactDTM.fromPlain({
      id: contact.id,
      fullName: contact.fullName,
      email: contact.email,
      phone: contact.phone,
      phone2: contact.phone2,
    })) || [];

    newShippingParty = ShippingPartyDTM.fromPlain({
      addressList,
      contactList,
      company: {
        ...rawResponse.data.contactData?.company,
        addresses: rawResponse.data.contactData?.company.addresses || [],
        contacts: rawResponse.data.contactData?.company.contacts || [],
      },
      id: rawResponse.data.id,
      role: rawResponse.data.role as EShippingPartyTypes,
      address: {
        ...rawResponse.data.contactData.address,
        closestPort: rawResponse.data.contactData.address.closestPort || '',
      },
      contact: rawResponse.data.contactData.contact,
      hasSystemUpdatePolicy: rawResponse.data.hasSystemUpdatePolicy,
      isFreightForwarderOrgType: rawResponse.data.isFreightForwarderOrgType,
      references: rawResponse.data.references ? rawResponse.data.references.map((ref) => ({
        id: `${ref.id}`,
        value: ref.value,
        isNew: false,
      })) : [
        ShippingPartyReference.fromPlain({
          id: uuidv4(),
          value: '',
        }),
      ],
    });
    if (!newShippingParty.isValid()) {
      console.error(`ShippingPartiesService.getList error: ${newShippingParty.validate()}`, newShippingParty.validate());
    }

    return newShippingParty;
  }

  public deleteShippingParty = async (shipmentId: string, shippingPartyId: string) => {
    try {
      await apiWorker.requestDelete(`${this.base}/${shipmentId}/parties/${shippingPartyId}`);
    } catch (e) {
      throw new Error('Shipment getting error');
    }

    return undefined;
  }
}
