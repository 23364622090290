import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from 'app-wrapper/view/components';

import { FooterWrapper } from './Footer.styled';

interface FooterProps {
  onCancel: () => void;
  onSubmit: (shipmentId: string) => void;
  shipmentId: string;
}

export const FooterComponent: FC<FooterProps> = ({
  onSubmit,
  onCancel,
  shipmentId,
}) => {
  const { t } = useTranslation();

  const handleSubmit = useCallback(() => {
    onSubmit(shipmentId);
  }, [onSubmit, shipmentId]);

  return (
    <FooterWrapper>
      <Button
        onClick={onCancel}
        type="dashed"
        style={{
          marginRight: '8px',
        }}
      >
        {t('Cancel')}
      </Button>

      <Button onClick={handleSubmit}>
        {t('Submit Document')}
      </Button>
    </FooterWrapper>
  );
};
