import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Dropdown from 'antd/es/dropdown';
import Menu from 'antd/es/menu';

import { ShipmentChargesPermissionsDtm } from 'shipment-operations/models/dtm';
import { ACCESSORIAL, PermissionAttributePolicy } from 'shipment-operations/constants';
import {
  StyledHeaderCell,
  StyledHeaderIcon, StyledMoreIcon,
} from 'shipment-operations/view/pages/ShipmentTransportationAccessorials/ShipmentTransportationAccessorials.styled';
import {
  StyledMenuItem,
  StyledRemove,
} from 'shipment-operations/view/pages/ShipmentTransportationCharges/components/CustomTable/CustomTable.styled';

const useTable = (
  permissions: ShipmentChargesPermissionsDtm, openEdit: (id: number, host: string) => void,
  setDeletedCharge: (id: number | null) => void, setIsModalOpen: (value: boolean) => void, uniqueContainers: string[],
) => {
  const { t } = useTranslation();

  const openOnRemove = useCallback((id) => {
    setDeletedCharge(id);
    setIsModalOpen(true);
  }, []);

  const expandMenuItems = useCallback((item) => (
    (
      <Menu>
        <StyledMenuItem
          onClick={() => openEdit(item.key, ACCESSORIAL)}
          key={1}
        >
          {t('Edit')}
        </StyledMenuItem>
        <StyledRemove
          key={2}
          onClick={() => openOnRemove(item.key)}
        >
          {t('remove')}
        </StyledRemove>
      </Menu>
    )
  ), []);

  const fullColumns = useMemo(() => [
    {
      title: t('Service'),
      dataIndex: 'service',
      key: 'service',
      width: '30%',
      render: (text: string) => <span style={{ textTransform: 'capitalize' }}>{text}</span>,
    },
    {
      title: t('Unit Type'),
      dataIndex: 'unitType',
      key: 'unitType',
      width: '20%',
    },
    {
      title: (
        <div>
          <StyledHeaderCell>AP</StyledHeaderCell>
          <StyledHeaderIcon />
          {t('perUnit')}
        </div>
      ),
      dataIndex: 'apPerUnit',
      key: 'apPerUnit',
      width: '25%',
      align: 'right' as 'right',
    },
    {
      title: (
        <div>
          <StyledHeaderCell>AR</StyledHeaderCell>
          <StyledHeaderIcon />
          {t('perUnit')}
        </div>
      ),
      dataIndex: 'arPerUnit',
      key: 'arPerUnit',
      width: '25%',
      align: 'right' as 'right',
      render: (text: string, record: { id: number, key: number } | {}) => (
        (
          <div>
            {text}
            <Dropdown arrow overlay={expandMenuItems(record)}>
              <StyledMoreIcon />
            </Dropdown>
          </div>
        )
      ),
    },
  ], [uniqueContainers]);

  const columns = useMemo(() => [
    {
      title: t('Service'),
      dataIndex: 'service',
      key: 'service',
      width: '30%',
      render: (text: string) => <span style={{ textTransform: 'capitalize' }}>{text}</span>,
    },
    {
      title: t('Unit Type'),
      dataIndex: 'unitType',
      key: 'unitType',
      width: '20%',
    },
    {
      title: (
        <div>
          <StyledHeaderCell>AP</StyledHeaderCell>
          <StyledHeaderIcon />
          {t('perUnit')}
        </div>
      ),
      dataIndex: 'apPerUnit',
      key: 'apPerUnit',
      width: '25%',
      align: 'right' as 'right',
    },
  ], [uniqueContainers]);

  return { columns: permissions.shipmentChargesAvailability === PermissionAttributePolicy.WRITE ? fullColumns : columns };
};

export { useTable };
