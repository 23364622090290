import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'app-wrapper/createSlice';

import { TUserInviteState } from 'user-management/models/states';
import { EOrganizationMemberRole } from 'user-management/constants';
import { AccountDepartmentDTM, TeamDTM } from 'user-management/models/dtm';

export const initialState: TUserInviteState = {
  isLoading: false,
  departments: [],
  teams: [],
  departmentsError: false,
  availableTeams: [],
  availableDepartments: [],
  jobTitle: '',
};

export const userInviteStore = createSlice({
  name: 'userInvite',
  initialState,
  reducers: {
    setEmail: (state, action: PayloadAction<string>) => {
      state.email = action.payload;
    },
    setEmailError: (state, action: PayloadAction<Error>) => {
      state.emailError = action.payload;
    },
    clearEmailError: (state) => {
      state.emailError = undefined;
    },
    setRole: (state, action: PayloadAction<EOrganizationMemberRole>) => {
      state.role = action.payload;
    },
    setRoleError: (state, action: PayloadAction<Error>) => {
      state.roleError = action.payload;
    },
    clearRoleError: (state) => {
      state.roleError = undefined;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setDepartments: (state, { payload }: PayloadAction<string[]>) => ({
      ...state,
      departments: payload,
    }),
    setAvailableTeams: (state, { payload }: PayloadAction<TeamDTM[]>) => ({
      ...state,
      availableTeams: payload,
    }),
    setAvailableDepartments: (state, { payload }: PayloadAction<AccountDepartmentDTM[]>) => ({
      ...state,
      availableDepartments: payload,
    }),
    setDepartmentsError: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      departmentsError: payload,
    }),
    setTeams: (state, { payload }: PayloadAction<string[]>) => ({
      ...state,
      teams: payload,
    }),
    setJobTitle: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      jobTitle: payload,
    }),
    clear: () => (initialState),
  },
});

export const userInviteActions = userInviteStore.actions;
export const userInviteReducer = userInviteStore.reducer;
