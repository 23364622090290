import React, { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { validationEmail, validationPhone } from 'app-wrapper/utils';
import { VerticalFormItemSvg } from 'app-wrapper/view/icons';
import themesColors from 'app-wrapper/view/themes/themesColors';
import { OrganizationTradeReferenceDTM } from 'user-management/models/dtm';
import { Tooltip } from 'app-wrapper/view/components';

import {
  Wrap,
  CollapseHeader,
  CollapseHeaderIcon,
  CollapseHeaderTitle,
  Content,
  InfoWarning,
  InfoWarningIcon,
  InfoWarningTitle,
  Field,
  Row,
  PairedField,
  StyledInput,
  Label,
} from './TradeReferences.styled';

interface TradeReferencesComponentProps {
  isTradeReferenceCollapsed: boolean;
  setIsTradeReferenceCollapsed: (isCollapsed: boolean) => void;
  tradeReference: OrganizationTradeReferenceDTM;
  shouldValidate: boolean;
  setCompanyName: (name: string) => void;
  setFirstName: (name: string) => void;
  setLastName: (name: string) => void;
  setEmail: (email: string) => void;
  setPhone: (phone: string) => void;
}

export const TradeReferencesComponent: FC<TradeReferencesComponentProps> = ({
  isTradeReferenceCollapsed,
  setIsTradeReferenceCollapsed,
  tradeReference,
  shouldValidate,
  setCompanyName,
  setEmail,
  setPhone,
  setFirstName,
  setLastName,
}) => {
  const { t } = useTranslation();
  const {
    companyName,
    firstName,
    lastName,
    phone,
    email,
  } = tradeReference;

  const handleSetIsCollapsed = useCallback(() => {
    setIsTradeReferenceCollapsed(!isTradeReferenceCollapsed);
  }, [setIsTradeReferenceCollapsed, isTradeReferenceCollapsed]);

  const handleCompanyChange = useCallback((e) => {
    setCompanyName(e.target.value);
  }, [setCompanyName]);

  const handleFirstNameChange = useCallback((e) => {
    setFirstName(e.target.value);
  }, [setFirstName]);

  const handleLastNameChange = useCallback((e) => {
    setLastName(e.target.value);
  }, [setLastName]);

  const handleEmailChange = useCallback((e) => {
    setEmail(e.target.value);
  }, [setEmail]);

  const handlePhoneChange = useCallback((e) => {
    setPhone(e.target.value);
  }, [setPhone]);

  const emailError = useMemo(() => {
    const message = validationEmail(email).errorMessage;

    if (shouldValidate && email && message) {
      return message;
    }

    return '';
  }, [shouldValidate, phone, email]);

  const phoneError = useMemo(() => {
    const message = validationPhone(phone).errorMessage;

    if (shouldValidate && phone && message) {
      return message;
    }

    return '';
  }, [shouldValidate, phone, email]);

  return (
    <Wrap>
      <CollapseHeader onClick={handleSetIsCollapsed}>
        <VerticalFormItemSvg
          rotate={isTradeReferenceCollapsed ? 0 : -90}
          style={{
            color: isTradeReferenceCollapsed ? themesColors.primaryBranding6 : themesColors.secondaryDot45,
          }}
        />

        <CollapseHeaderTitle
          style={{
            color: isTradeReferenceCollapsed ? themesColors.primaryBranding6 : themesColors.neutralBranding10,
          }}
        >
          {t('Trade References (Recommended)')}
        </CollapseHeaderTitle>

        <CollapseHeaderIcon />
      </CollapseHeader>

      <Content
        style={{
          height: !isTradeReferenceCollapsed ? '0px' : '278px',
        }}
      >
        <InfoWarning>
          <InfoWarningIcon />

          <InfoWarningTitle>
            {t('Providing this information can help us to speed up the verification process.')}
          </InfoWarningTitle>
        </InfoWarning>

        <Row>
          <Field>
            <Label>
              {t('Company Name')}
            </Label>

            <StyledInput
              error={shouldValidate && !companyName.length}
              value={companyName}
              onChange={handleCompanyChange}
            />
          </Field>
        </Row>

        <Row>
          <PairedField>
            <Label>
              {t('First Name')}
            </Label>

            <StyledInput
              error={shouldValidate && !firstName.length}
              value={firstName}
              onChange={handleFirstNameChange}
            />
          </PairedField>

          <PairedField>
            <Label>
              {t('Last Name')}
            </Label>

            <StyledInput
              value={lastName}
              error={shouldValidate && !lastName.length}
              onChange={handleLastNameChange}
            />
          </PairedField>
        </Row>

        <Row>
          <PairedField>
            <Label>
              {t('Email')}
            </Label>

            <Tooltip
              visible={!!emailError.length}
              title={emailError}
              mode="danger"
            >
              <StyledInput
                error={!!emailError.length || (shouldValidate && !phone && !email)}
                value={email}
                onChange={handleEmailChange}
              />
            </Tooltip>
          </PairedField>

          <PairedField>
            <Label>
              {t('Phone')}
            </Label>

            <Tooltip
              visible={!!phoneError.length}
              title={phoneError}
              mode="danger"
            >
              <StyledInput
                error={!!phoneError.length || (shouldValidate && !phone && !email)}
                onChange={handlePhoneChange}
                value={phone}
                type="number"
              />
            </Tooltip>
          </PairedField>
        </Row>
      </Content>
    </Wrap>
  );
};
