import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';

import { PackTypeSelectComponent } from './PackTypeSelect.component';

interface PackTypeSelectContainerProps {
  cargoId: number;
}

const PackTypeSelectContainer: FC<PackTypeSelectContainerProps> = ({ cargoId }) => {
  const { packageType, errors } = useSelector(R.selectors.manualBookingWizard.getCargoById(cargoId));
  const { packageType: error } = errors;

  return (
    <PackTypeSelectComponent
      packageType={packageType}
      error={!!error}
    />
  );
};

export { PackTypeSelectContainer as PackTypeSelect };
