import React, { FC, useCallback, useMemo } from 'react';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import Tooltip from 'antd/es/tooltip';

import { Button } from 'app-wrapper/view/components';
import { DateDtm } from 'app-wrapper/models/dtm';

import {
  WarningWrap,
  WarningText,
  WarningIcon,
  WarningActions,
  CancelButton,
} from './CustomerBookingConfirmation.styled';

interface CustomerBookingConfirmationComponentProps {
  isWaitingAcceptFromCustomer: boolean
  onAccept: () => void
  onCancel: () => void
  confirmedAt: DateDtm | null
  hasAnyMissMatches: boolean
  showNraText: boolean
}

export const CustomerBookingConfirmationComponent: FC<CustomerBookingConfirmationComponentProps> = ({
  onAccept,
  onCancel,
  confirmedAt,
  isWaitingAcceptFromCustomer,
  hasAnyMissMatches,
  showNraText,
}) => {
  const { shipmentId = '' } = useParams();
  const { t } = useTranslation();

  const handleCancel = useCallback(() => {
    onCancel();
  }, [shipmentId, onCancel]);

  const date = useMemo(() => {
    if (!confirmedAt) {
      return '';
    }

    const newDate = DateDtm.fromPlain({
      date: moment(confirmedAt.date).add(72, 'hours').toString(),
      offset: confirmedAt.offset,
    });

    return newDate.getDateDMMMYYYYHHmm();
  }, [confirmedAt]);

  if (!isWaitingAcceptFromCustomer) {
    return null;
  }

  return (
    <>
      <WarningWrap>
        <WarningIcon />

        <WarningText>
          {t('Please acknowledge your acceptance of Skypace\'s Terms and Conditions <Nra Text>for this shipment. Failure to do so before <Due Date> will result in cancellation of the shipment.', { dueDate: date, nraText: showNraText ? `${t('and Negotiated Rate Arrangement')} ` : '' })}
        </WarningText>

        <WarningActions>
          <CancelButton type="default" onClick={handleCancel}>
            {t('Cancel Booking')}
          </CancelButton>

          {hasAnyMissMatches ? (
            <Tooltip
              placement="topLeft"
              title={t('Accept shifted dates and mismatches to confirm the shipment')}
            >
              <Button disabled>
                {t('Review & Accept')}
              </Button>
            </Tooltip>
          ) : (
            <Button onClick={onAccept}>
              {t('Review & Accept')}
            </Button>
          )}
        </WarningActions>
      </WarningWrap>
    </>
  );
};
