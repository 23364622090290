import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Wrap,
  Header,
  Content,
  Spinner,
  Image,
  ImageWrap,
  Title,
  SubTitle,
  Buttons,
  BackToOverviewButton,
  ViewOrganizationButton,
} from './NewOrganizationSuccessPage.styled';
import image from './image.png';

interface INewOrganizationSuccessPageComponentProps {
  isLoading: boolean;
  initData: () => void;
  handleClose: () => void;
  handleViewMyOrganization: () => void;
}

export const NewOrganizationSuccessPageComponent: FC<INewOrganizationSuccessPageComponentProps> = ({
  initData,
  isLoading,
  handleClose,
  handleViewMyOrganization,
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    initData();
  }, []);

  return (
    <Wrap>
      <Header>
        {t('Create Organization')}
      </Header>

      {isLoading ? (
        <Spinner size="large" />
      ) : (
        <Content>
          <ImageWrap>
            <Image
              alt=""
              src={image}
            />
          </ImageWrap>

          <Title>
            {t('You have successfully created your organization')}
          </Title>

          <SubTitle>
            {t('Your organization’s details will be reviewed within 2 days. We will contact you via email, and if the review is successful, you will be officially registered on the platform.')}
          </SubTitle>

          <Buttons>
            <ViewOrganizationButton onClick={handleViewMyOrganization}>
              {t('View My Organization')}
            </ViewOrganizationButton>

            <BackToOverviewButton type="default" onClick={handleClose}>
              {t('Back to Overview')}
            </BackToOverviewButton>
          </Buttons>
        </Content>
      )}
    </Wrap>
  );
};
