import styled from 'styled-components';

import { divFlex, responsive } from 'app-wrapper/view/themes/mixin';
import { Button } from 'app-wrapper/view/components';
import ImageQuoteScreen from 'monetary/view/assets/Image_quote_screen.png';

export const FreightQuoteContentWrapper = styled.div.attrs({
  'data-class': 'FreightQuoteContentWrapper',
})`
  height: ${({ theme }) => `calc(80% - ${theme.dimensions.freightQuotePage.height.heightPageHeader})`};

.ant-page-header {
  padding-left: 24px;
  border-bottom: 1px solid ${({ theme }) => theme.themesColors.neutralBranding3};
}
`;

export const FreightQuoteContentHeaderWrap = styled.div.attrs({
  'data-class': 'FreightQuoteContentHeaderWrap',
})`
  .ant-page-header {
    ${divFlex}
    flex-direction: row;
  }
  .ant-page-header-content {
    ${divFlex}
    margin-left: auto;
    align-items: center;
    justify-content: center;
  }
`;

export const FreightQuoteContentHeaderSaveBtn = styled(Button).attrs({
  'data-class': 'FreightQuoteContentHeaderSaveBtn',
})`
  ${divFlex}
  align-items: center;
  justify-content: center;

  background-color: ${({ theme }) => theme.themesColors.neutralBranding1};
  height: 32px;
  border: 1px solid ${({ theme }) => theme.themesColors.neutral5};
  border-radius: 3px;
  text-transform: capitalize;

  span {
    color: ${({ theme }) => theme.themesColors.characterTitleDot85};
    font-size: 14px;
    font-weight: 400;
    text-shadow: none;
  }
`;

export const FreightQuoteContentPage = styled.div.attrs({
  'data-class': 'FreightQuoteContentPage',
})`
  ${divFlex}
  margin-left: 24px;

  background-color: ${({ theme }) => `${theme.freightQuotePage.bg}`};
  height: ${({ theme }) => `calc(100vh -  ${theme.dimensions.freightQuotePage.height.topHeaderContent} - ${theme.dimensions.freightQuotePage.height.topHeader})`};
`;

export const FreightQuoteContentComponentDiv = styled.div.attrs((props: {
  isCollapseAndEmptyQuota: boolean
  'data-class'?: string
}) => ({
  isCollapseAndEmptyQuota: props.isCollapseAndEmptyQuota,
  'data-class': props?.['data-class'] || 'FreightQuoteContentComponentDiv',
}))`
  display: flex;
  width: 100%;

  .FreightQuoteFormRequest {
    min-width: 390px;
    max-width: calc(480px - 24px);
    width: 30%;
  }

  ${responsive.forMediumMediaDesktopMax`
    min-width: 953px;
  `}

  ${responsive.forFullMediaMax`
    .FreightQuoteFormRequest, .FreightQuoteContent__bg_wrapper {
      width: 30%;
      min-width: 393px;
      max-width: 510px;
      ${({ isCollapseAndEmptyQuota }: { isCollapseAndEmptyQuota: boolean }) => isCollapseAndEmptyQuota && 'min-width: calc(762px - 24px)'};
    }

    .RFQResponse {
      width: 70%;
      min-width: 400px;
      max-width: 928px;
    }
  `}

  ${responsive.forFullMedia`
    .FreightQuoteContent__bg_wrapper {
      min-width: 390px;
      max-width: calc(480px - 24px);
      width: 30%;
      ${({ isCollapseAndEmptyQuota }: { isCollapseAndEmptyQuota: boolean }) => isCollapseAndEmptyQuota && 'min-width: calc(762px - 24px)'};
    }

    .RFQResponse {
      width: 70%;
      min-width: 633px;
      max-width: 928px;
    }
  `}

  ${responsive.forMediumMediaDesktopMax`
    .FreightQuoteFormRequest, .FreightQuoteContent__bg_wrapper {
      width: 30%;
      max-width: 670px;
      min-width: 320px;
      ${({ isCollapseAndEmptyQuota }: { isCollapseAndEmptyQuota: boolean }) => isCollapseAndEmptyQuota && 'min-width: calc(762px - 24px)'};
    }

    .RFQResponse {
      width: 70%;
      min-width: 548px;
      max-width: 928px;
    }
  `}

  .FreightQuoteFormRequest__isCollapsed .FreightQuoteFormRequest__footer__totals__items_item {
    max-width: unset;
    flex: unset;
    width: auto;
    margin-right: 20px;
  }

  .FreightQuoteContent__isCollapsed .FreightQuoteContent__bg_wrapper {
    min-width: 390px;
    max-width: calc(480px - 24px);
  }

  .FreightQuoteFormRequest__isCollapsed {
    min-width: calc(762px - 24px);

    .FreightQuoteFormRequest {
      &__Row {
        &__col {
          &_both_col {
            height: 95px;
          }
        }

        &__Commodity, &__Containers {
          &__content {
            &_title {
              margin-top: 8px;
            }
          }
        }
      }
    }
  }

  .FreightQuoteFormRequest__isNotCollapsed, .FreightQuoteContent__isCollapsed .FreightQuoteContent__bg_wrapper {
    .FreightQuoteFormRequest {
      &__Row {
        &__col {
          &_both_col {
            ${responsive.forFullMedia`
              height: 235px;
            `}
          }
        }
      }
    }
  }

  .FreightQuoteFormRequest__isForceCollapse {
    min-width: 390px;
    max-width: calc(480px - 24px);
    width: 100%;

    ${responsive.forFullMediaMax`
      min-width: 390px;
      max-width: calc(480px - 24px);
    `}

    .FreightQuoteFormRequest {
      &__Row {
        &__col {
          &_both_col {
            ${responsive.forFullMedia`
              height: 253px;
            `}
          }

          &_right {
            ${responsive.forFullMedia`
              top: 2px;
            `}
          }
        }

        &__Commodity, &__Containers {
          &__content {
            &_title {
              margin-top: 8px;
            }
          }
        }
      }
    }
  }
`;

export const BackgroundWrapper = styled.div`
  display: block;
  background: rgba(0, 0, 0, 0.45);
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 100%;
`;

export const FreightQuoteContentContainer = styled.div.attrs((props: {
  isCollapseAndEmptyQuota: boolean
  isEmptyBg: boolean
  'data-class'?: string
}) => ({
  isCollapseAndEmptyQuota: props.isCollapseAndEmptyQuota,
  isEmptyBg: props.isEmptyBg,
  'data-class': props?.['data-class'] || 'FreightQuoteContentContainer',
}))`
  display: flex;
  position: absolute;
  width: 100%;
  height: 100%;

  background-image: url(${ImageQuoteScreen});
  background-repeat: no-repeat;
  background-position: right;
  ${({ isCollapseAndEmptyQuota }) => `background-position-x: ${isCollapseAndEmptyQuota ? '704px' : '404px'};`}
  background-size: cover;

  ${({ isEmptyBg }) => isEmptyBg && 'background: none;'};

  .RFQResponse {
    position: relative;
  }
`;

export const FreightQuoteContentContainerBGWrapper = styled.div`
  display: flex;
  position: relative;
`;
