import {
  IsDefined, IsEnum, IsOptional, IsString, ValidateNested,
} from 'class-validator';
import { Type } from 'class-transformer';
import { BaseDTM } from 'proto/BaseDTM';

import { ShipmentRoutesType } from 'shipment-operations/constants';
import { IDateDTM, DateDtm } from 'app-wrapper/models/dtm';

export interface IShipmentRouteDTM {
  id: string
  type?: ShipmentRoutesType
  terminalName?: string
  passCode?: string
  address1?: string
  address2?: string
  address3?: string
  address4?: string
  city?: string
  state?: string
  postcode?: string
  country?: string
  releaseDate?: IDateDTM
}

export class ShipmentRouteDTM extends BaseDTM<IShipmentRouteDTM> {
  @IsString()
  @IsDefined()
  id: string;

  @IsOptional()
  @IsEnum(ShipmentRoutesType)
  type: ShipmentRoutesType;

  @IsString()
  @IsOptional()
  terminalName: string;

  @IsString()
  @IsOptional()
  passCode: string;

  @IsString()
  @IsOptional()
  address1: string;

  @IsString()
  @IsOptional()
  address2: string;

  @IsString()
  @IsOptional()
  address3: string;

  @IsString()
  @IsOptional()
  address4: string;

  @IsString()
  @IsOptional()
  city: string;

  @IsString()
  @IsOptional()
  state: string;

  @IsString()
  @IsOptional()
  postcode: string;

  @IsString()
  @IsOptional()
  country: string;

  @IsOptional()
  @ValidateNested()
  @Type(() => DateDtm)
  releaseDate?: DateDtm;
}
