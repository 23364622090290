import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  TitleWrapper,
  TableHeader,
  Row,
  Bold,
} from 'shipment-operations/view/components/HBLDocumentPDF/components';
import { Cell } from 'shipment-operations/view/components/HBLDocumentPDF/components/CargoAndContainers/CargoAndContainers.styled';
import { CargoDTM, HBLDocumentCargoTotalDTM } from 'shipment-operations/models/dtm';

import { Total, GoodsDescriptionStyledTableRow } from '../../CargoTables.styled';
import { GoodsDescriptionItem } from '../GoodsDescriptionItem';

interface GoodsDescriptionComponentProps {
  cargos: CargoDTM[];
  cargo: HBLDocumentCargoTotalDTM;
}

export const GoodsDescription: FC<GoodsDescriptionComponentProps> = ({ cargos, cargo }) => {
  const { t } = useTranslation();

  const values = useMemo(() => ({
    volume: cargo.volume,
    weight: cargo.weight,
    netWeight: cargo.netWeight,
    packagesNumber: cargo.packagesNumber,
  }), [cargo]);

  return (
    <table data-class="GoodsDescriptionTable" style={{ width: '100%' }}>
      <thead>
        <tr>
          <td>
            <TitleWrapper>
              <Bold>{t('Goods Description')}</Bold>
            </TitleWrapper>
            <TableHeader>
              <Cell flex={2} justifyContent="flex-start"><Bold>{t('Description')}</Bold></Cell>
              <Cell justifyContent="flex-end"><Bold>{t('HS Code')}</Bold></Cell>
              <Cell justifyContent="flex-end"><Bold>{t('Pack(s)')}</Bold></Cell>
              <Cell justifyContent="center"><Bold>{t('PCS')}</Bold></Cell>
              <Cell justifyContent="flex-end"><Bold>{t('Gross Weight, kg')}</Bold></Cell>
              <Cell justifyContent="flex-end"><Bold>{t('Volume, cbm')}</Bold></Cell>
            </TableHeader>
          </td>
        </tr>
      </thead>
      <tbody>
        {
          cargos.map((item) => (
            <GoodsDescriptionStyledTableRow style={{ pageBreakInside: 'avoid' }} key={item.id}>
              <td>
                <GoodsDescriptionItem item={item} />
              </td>
            </GoodsDescriptionStyledTableRow>
          ))
        }
        <tr>
          <Total>
            <Row>
              <Cell flex={2} justifyContent="flex-start"><Bold>{t('Total')}</Bold></Cell>
              <Cell />
              <Cell />
              <Cell justifyContent="center"><Bold>{values.packagesNumber}</Bold></Cell>
              <Cell justifyContent="flex-end"><Bold>{values.weight.toFixed(2)}</Bold></Cell>
              <Cell justifyContent="flex-end"><Bold>{values.volume.toFixed(2)}</Bold></Cell>
            </Row>
          </Total>
        </tr>
      </tbody>
    </table>
  );
};
