import React from 'react';

import { IPropsIcon } from 'app-wrapper/interfaces/icon';

import { Icon } from './MessageResponse.styled';

export const MessageResponse = (props: IPropsIcon) => {
  const { width, height, fill } = props;
  return (
    <Icon
      className="MessageResponse"
      width={width || '90'}
      height={height || '80'}
      viewBox="0 0 90 80"
      fill={fill || 'currentColor'}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M45.1033 79.4921C69.737 79.4921 89.7066 75.7608 89.7066 71.1579C89.7066 66.5551 69.737 62.8237 45.1033 62.8237C20.4696 62.8237 0.5 66.5551 0.5 71.1579C0.5 75.7608 20.4696 79.4921 45.1033 79.4921Z" fill="#F5F5F7" fillOpacity="0.8" />
      <path fillRule="evenodd" clipRule="evenodd" d="M80.7855 46.6734L65.0454 27.3017C64.2902 26.3899 63.1862 25.8379 62.0237 25.8379H28.1816C27.0198 25.8379 25.9158 26.3899 25.1606 27.3017L9.42108 46.6734V56.7938H80.7862V46.6734H80.7855Z" fill="#AEB8C2" />
      <path fillRule="evenodd" clipRule="evenodd" d="M22.7566 0.835449H67.4493C68.1473 0.835449 68.8166 1.1127 69.3102 1.60622C69.8037 2.09974 70.0809 2.76909 70.0809 3.46703V64.8775C70.0809 65.5755 69.8037 66.2448 69.3102 66.7383C68.8166 67.2319 68.1473 67.5091 67.4493 67.5091H22.7566C22.0586 67.5091 21.3893 67.2319 20.8958 66.7383C20.4023 66.2448 20.125 65.5755 20.125 64.8775V3.46703C20.125 2.76909 20.4023 2.09974 20.8958 1.60622C21.3893 1.1127 22.0586 0.835449 22.7566 0.835449V0.835449Z" fill="#F5F5F7" />
      <path fillRule="evenodd" clipRule="evenodd" d="M28.5776 7.3833H61.6283C61.9773 7.3833 62.312 7.52193 62.5587 7.76869C62.8055 8.01545 62.9441 8.35012 62.9441 8.69909V25.1182C62.9441 25.4671 62.8055 25.8018 62.5587 26.0486C62.312 26.2953 61.9773 26.434 61.6283 26.434H28.5776C28.2287 26.434 27.894 26.2953 27.6472 26.0486C27.4005 25.8018 27.2619 25.4671 27.2619 25.1182V8.69909C27.2619 8.35012 27.4005 8.01545 27.6472 7.76869C27.894 7.52193 28.2287 7.3833 28.5776 7.3833V7.3833ZM28.75 33.5767H61.4559C61.8506 33.5767 62.2291 33.7335 62.5082 34.0126C62.7873 34.2917 62.9441 34.6702 62.9441 35.0649C62.9441 35.4596 62.7873 35.8381 62.5082 36.1172C62.2291 36.3963 61.8506 36.553 61.4559 36.553H28.75C28.3553 36.553 27.9768 36.3963 27.6977 36.1172C27.4186 35.8381 27.2619 35.4596 27.2619 35.0649C27.2619 34.6702 27.4186 34.2917 27.6977 34.0126C27.9768 33.7335 28.3553 33.5767 28.75 33.5767V33.5767ZM28.75 41.3155H61.4559C61.8507 41.3155 62.2293 41.4724 62.5085 41.7515C62.7876 42.0307 62.9444 42.4093 62.9444 42.804C62.9444 43.1988 62.7876 43.5774 62.5085 43.8565C62.2293 44.1357 61.8507 44.2925 61.4559 44.2925H28.75C28.3552 44.2925 27.9766 44.1357 27.6975 43.8565C27.4184 43.5774 27.2615 43.1988 27.2615 42.804C27.2615 42.4093 27.4184 42.0307 27.6975 41.7515C27.9766 41.4724 28.3552 41.3155 28.75 41.3155V41.3155ZM80.6402 69.9353C80.1303 71.9557 78.3395 73.4616 76.2092 73.4616H13.9967C11.8665 73.4616 10.0757 71.955 9.56646 69.9353C9.46934 69.5503 9.42029 69.1547 9.42041 68.7576V46.6741H26.7349C28.6474 46.6741 30.1888 48.2846 30.1888 50.2399V50.2662C30.1888 52.2208 31.748 53.7991 33.6605 53.7991H56.5454C58.4579 53.7991 60.0171 52.2063 60.0171 50.2511V50.2432C60.0171 48.2879 61.5586 46.6734 63.4711 46.6734H80.7855V68.7583C80.7855 69.1642 80.7349 69.5583 80.6402 69.9353V69.9353Z" fill="#DCE0E6" />
    </Icon>
  );
};
