import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Badge from 'antd/es/badge';

import { colors } from 'app-wrapper/view/themes/themesColors';
import { Button } from 'app-wrapper/view/components';
import { PlusSvg } from 'app-wrapper/view/icons';

import { SubPageHeaderTitle } from 'app-wrapper/view/guideline';

import { ContactBookTitleContainer, ContactBookTitleLeftPart } from './ContactBookTitle.styled';

const BADGE_STYLE = {
  backgroundColor: 'transparent',
  borderColor: colors.gray43,
  color: 'black',
};

interface IContactBookTitleComponentProps {
  amountOfCompanies: number
  addCompany: () => void
}

export const ContactBookTitleComponent: FC<IContactBookTitleComponentProps> = ({
  amountOfCompanies,
  addCompany,
}) => {
  const { t } = useTranslation();

  return (
    <ContactBookTitleContainer>
      <ContactBookTitleLeftPart>
        <SubPageHeaderTitle>
          {t('Companies')}
        </SubPageHeaderTitle>
        <Badge count={amountOfCompanies} style={BADGE_STYLE} size="default" />
      </ContactBookTitleLeftPart>
      <Button
        icon={(
          <PlusSvg
            height={11}
            width={11}
          />
        )}
        size="middle"
        onClick={addCompany}
      >
        {t('AddCompany')}
      </Button>
    </ContactBookTitleContainer>
  );
};
