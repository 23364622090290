import React, {
  useCallback, useEffect, useRef, FC,
} from 'react';
import * as yup from 'yup';
import omit from 'lodash/fp/omit';
import { Link, useNavigate } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';

import { useAppDispatch, useRequest } from 'app-wrapper/hooks';
import InputFieldController from 'app-wrapper/view/controllers/InputFieldController/InputFieldController';
import {
  Errors, PageContainer, CheckboxField, Button,
} from 'app-wrapper/view/components';
import { registerOrganization, RegisterOrganizationParams } from 'app-wrapper/data';

import {
  OrganisationRegistrationRow,
  OrganisationRegistration,
  OrganisationRegistrationBase,
  OrganisationRegistrationButton,
  OrganisationRegistrationCompanyInformation,
  OrganisationRegistrationFloor,
  OrganisationRegistrationHouse,
  OrganisationRegistrationHR,
  OrganisationRegistrationInput,
  OrganisationRegistrationPostcode,
  OrganisationRegistrationRowIndent,
  OrganisationRegistrationStep,
  OrganisationRegistrationStreet,
  OrganisationRegistrationTitle,
} from './OrganizationRegistration.styled';

interface IFormValues {
  base: null
  name: string
  ein: string

  address: {
    street: string
    house: string
    step: string
    floor: string
    city: string
    country: string
    postalCode: string
    closestPort: string
  }
  phone: string
  phone2: string
  state: string
  webUrl: string
  wcaId: string
  dnbId: string
  member: boolean,
  termsAndConditions: boolean,
}

export const OrganizationRegistration: FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const schemaRef = useRef(yup.object({
    name: yup.string().required(),
    ein: yup.string().required().einFormat(),
    address: yup.object({
      city: yup.string().required(),
      country: yup.string().required(),
      postalCode: yup.string().required(),
      street: yup.string().required(),
      house: yup.string().required(),
      closestPort: yup.string().required().portFormat(),
    }),
    phone: yup.string(), // .required().phoneFormat(),
    phone2: yup.string().phoneFormat(),
    dnbId: yup.string().required().dnbIdFormat(),
    wcaId: yup.string().required().wcaIdFormat(),
    member: yup.string().required(),
    termsAndConditions: yup.string().required(),
    webUrl: yup.string().url(),
  }));

  const formMethods = useForm<IFormValues>({
    defaultValues: {
      name: '',
      ein: '',
      address: {
        street: '',
        house: '',
        step: '',
        floor: '',
        city: '',
        country: '',
        closestPort: '',
        postalCode: '',
      },
      phone: '',
      phone2: '',
      state: '',
      webUrl: '',
      wcaId: '',
      dnbId: '',
      member: false,
      termsAndConditions: false,
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(schemaRef.current),
    criteriaMode: 'all',
  });

  const {
    register,
    handleSubmit,
    setFocus,
    setError,
    formState,
  } = formMethods;
  const { errors } = formState;

  useEffect(() => {
    setFocus('name');
  }, [setFocus]);

  const handleRequest = useRequest(setError);

  const onSubmit = useCallback((params: IFormValues) => {
    const organisationParams: RegisterOrganizationParams = omit(
      ['base', 'address', 'termsAndConditions', 'state', 'member'],
      params,
    );
    organisationParams.address = {
      address1: `${params.address.street}, ${params.address.house}`,
      address2: `${params.address.step}, ${params.address.floor}`,
      city: params.address.city,
      country: params.address.country,
      closestPort: params.address.closestPort,
      postalCode: params.address.postalCode,
    };
    handleRequest(dispatch(registerOrganization(organisationParams)).unwrap()
      .then(() => navigate('/')));
  }, [dispatch, handleRequest, navigate]);

  const nameInputController = 'name';
  const einInputController = 'ein';
  const addressCountryInputController = 'address.country';
  const addressCityInputController = 'address.city';
  const addressPostalCodeInputController = 'address.postalCode';
  const addressStreetInputController = 'address.street';
  const addressHouseInputController = 'address.house';
  const addressStepInputController = 'address.step';
  const addressFloorInputController = 'address.floor';
  const addressClosestPortInputController = 'address.closestPort';
  const phoneInputController = 'phone';
  const phoneSecondInputController = 'phone2';
  const stateInputController = 'state';
  const webUrlInputController = 'webUrl';
  const wcaIdInputController = 'wcaId';
  const dnbIdInputController = 'dnbId';

  return (
    <FormProvider {...formMethods}>
      <PageContainer>
        <OrganisationRegistration>
          <form onSubmit={handleSubmit(onSubmit)}>
            <OrganisationRegistrationTitle>
              {t('Sign up for Freightune')}
            </OrganisationRegistrationTitle>
            <OrganisationRegistrationBase>
              <Errors name="base" errors={errors} />
            </OrganisationRegistrationBase>
            <OrganisationRegistrationCompanyInformation>
              {t('Company')}
            </OrganisationRegistrationCompanyInformation>

            <OrganisationRegistrationRowIndent>
              <OrganisationRegistrationInput>
                <InputFieldController
                  name={nameInputController}
                  placeholder={`${t('Company Name')}*`}
                  errors={errors}
                />
              </OrganisationRegistrationInput>

              <OrganisationRegistrationInput>
                <InputFieldController
                  name={einInputController}
                  placeholder={`${t('EIN / VAT')}*`}
                  errors={errors}
                />
              </OrganisationRegistrationInput>
            </OrganisationRegistrationRowIndent>

            <OrganisationRegistrationRowIndent>
              <OrganisationRegistrationInput>
                <InputFieldController
                  name={addressCountryInputController}
                  placeholder={`${t('Country')}*`}
                  errors={errors}
                />
              </OrganisationRegistrationInput>

              <OrganisationRegistrationInput>
                <InputFieldController
                  name={addressCityInputController}
                  placeholder={`${t('City')}*`}
                  errors={errors}
                />
              </OrganisationRegistrationInput>
            </OrganisationRegistrationRowIndent>

            <OrganisationRegistrationRowIndent>
              <OrganisationRegistrationPostcode>
                <InputFieldController
                  name={addressPostalCodeInputController}
                  placeholder={`${t('Postcode')}*`}
                  errors={errors}
                />
              </OrganisationRegistrationPostcode>

              <OrganisationRegistrationStreet>
                <InputFieldController
                  name={addressStreetInputController}
                  placeholder={`${t('Street')}*`}
                  errors={errors}
                />
              </OrganisationRegistrationStreet>

              <OrganisationRegistrationHouse>
                <InputFieldController
                  name={addressHouseInputController}
                  placeholder={`${t('House')}*`}
                  errors={errors}
                />
              </OrganisationRegistrationHouse>

              <OrganisationRegistrationStep>
                <InputFieldController
                  name={addressStepInputController}
                  placeholder={`${t('Step')}`}
                  errors={errors}
                />
              </OrganisationRegistrationStep>

              <OrganisationRegistrationFloor>
                <InputFieldController
                  name={addressFloorInputController}
                  placeholder={`${t('Floor')}`}
                  errors={errors}
                />
              </OrganisationRegistrationFloor>
            </OrganisationRegistrationRowIndent>

            <OrganisationRegistrationRowIndent>
              <OrganisationRegistrationInput>
                <InputFieldController
                  name={addressClosestPortInputController}
                  placeholder={`${t('Closest port')}*`}
                  errors={errors}
                />
              </OrganisationRegistrationInput>
            </OrganisationRegistrationRowIndent>

            <OrganisationRegistrationRowIndent>
              <OrganisationRegistrationInput>
                <InputFieldController
                  name={phoneInputController}
                  placeholder={`${t('Phone')}*`}
                  errors={errors}
                />
              </OrganisationRegistrationInput>

              <OrganisationRegistrationInput>
                <InputFieldController
                  name={phoneSecondInputController}
                  placeholder={`${t('Phone')}2`}
                  errors={errors}
                />
              </OrganisationRegistrationInput>
            </OrganisationRegistrationRowIndent>

            <OrganisationRegistrationRowIndent>
              <OrganisationRegistrationInput>
                <InputFieldController
                  name={stateInputController}
                  placeholder={`${t('State')}`}
                  errors={errors}
                />
              </OrganisationRegistrationInput>

              <OrganisationRegistrationInput>
                <InputFieldController
                  name={webUrlInputController}
                  placeholder={t('Web URL')}
                  errors={errors}
                />
              </OrganisationRegistrationInput>
            </OrganisationRegistrationRowIndent>

            <OrganisationRegistrationRow>
              <OrganisationRegistrationInput>
                <InputFieldController
                  name={wcaIdInputController}
                  placeholder={t('WCA ID')}
                  errors={errors}
                />
              </OrganisationRegistrationInput>

              <OrganisationRegistrationInput>
                <InputFieldController
                  name={dnbIdInputController}
                  placeholder={`${t('DNB ID')}*`}
                  errors={errors}
                />
              </OrganisationRegistrationInput>
            </OrganisationRegistrationRow>

            <OrganisationRegistrationRow>
              <OrganisationRegistrationInput>
                <CheckboxField
                  text={t('Company is a member of WCA')}
                  errors={errors}
                  {...register('member')}
                />
              </OrganisationRegistrationInput>
            </OrganisationRegistrationRow>

            <OrganisationRegistrationHR />

            <OrganisationRegistrationRow>
              <OrganisationRegistrationInput>
                <CheckboxField
                  text={(
                    <span>
                      {t('I agree with')} <Link to="terms_and_conditions">{t('Terms and Conditions')}</Link>
                    </span>
                  )}
                  inputName="terms_and_conditions"
                  data-testid="terms_and_conditions"
                  errors={errors}
                  {...register('termsAndConditions')}
                />
              </OrganisationRegistrationInput>

              <OrganisationRegistrationButton>
                <Button htmlType="submit">{t('Sign Up')}</Button>
              </OrganisationRegistrationButton>
            </OrganisationRegistrationRow>
          </form>
        </OrganisationRegistration>
      </PageContainer>
    </FormProvider>
  );
};
