import styled from 'styled-components';

import themesColors from 'app-wrapper/view/themes/themesColors';

export const NewOrganizationWizardWrapperHeaderContainer = styled.div.attrs({
  'data-class': 'NewOrganizationWizardWrapperHeaderContainer',
})`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;

  width: 100%;
  height: 100%;

  padding: 8px 8px 8px 24px;

  background-color: ${themesColors.neutralBranding1};
  
  font-size: 20px;
  font-weight: 500;
  
  box-shadow: 0px -1px 0px 0px ${themesColors.neutralBranding3} inset;
`;
