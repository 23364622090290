import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Spin from 'antd/es/spin';

import { Drawer } from 'app-wrapper/view/components';
import { AddressSection, CompanySection } from 'shipment-operations/view/pages/ShipmentShippingParties/components/ShippingPartiesForm/components';
import { SpinnerWrap } from 'user-management/view/components/CreateOrEditTeamDrawer/CreateOrEditTeamDrawer.styled';

import { DrawerFooter } from './components';
import { ContentWrap } from './EditShippingPartyDrawer.styled';

const DRAWER_WIDTH = 734;

interface IAddOrChangeTeamDrawerComponentProps {
  isVisible: boolean;
  onClose: () => void;
  isLoading: boolean;
}

export const EditShippingPartyDrawerComponent: FC<IAddOrChangeTeamDrawerComponentProps> = ({
  isVisible,
  onClose,
  isLoading,
}) => {
  const { t } = useTranslation();

  return (
    <Drawer
      visible={isVisible}
      onClose={onClose}
      maskClosable={false}
      width={DRAWER_WIDTH}
      push={false}
      title={t('Edit Shipping Party')}
    >
      <ContentWrap>
        {isLoading ? (
          <SpinnerWrap>
            <Spin size="large" />
          </SpinnerWrap>
        ) : (
          <>
            <CompanySection hideDrawerButtons />
            <AddressSection hideDrawerButtons />
          </>
        )}
      </ContentWrap>

      <DrawerFooter />
    </Drawer>
  );
};
