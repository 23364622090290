import {
  IsNumber,
  IsOptional,
  IsBoolean,
  IsString,
} from 'class-validator';

import { KeysQuotasStatus } from 'app-wrapper/constants';
import { BaseDTM } from 'proto/BaseDTM';

export interface IFreightQuotasOptionDTM {
  id?: string
  page?: number
  size?: number
  nextStatus?: KeysQuotasStatus
  nextRequestStatus?: KeysQuotasStatus
  fullCompleteStatus?: null | string
  quotasCreateFailedMessage?: string
  isEnd?: boolean
  hasMore?: boolean
}

export class FreightQuotasOptionDTM extends BaseDTM<IFreightQuotasOptionDTM> {
  @IsOptional()
  @IsString()
  id?: string;

  @IsNumber()
  @IsOptional()
  page?: number;

  @IsNumber()
  @IsOptional()
  size?: number;

  @IsOptional()
  nextStatus?: KeysQuotasStatus;

  @IsOptional()
  nextRequestStatus?: KeysQuotasStatus;

  @IsOptional()
  @IsString()
  fullCompleteStatus?: string | null;

  @IsOptional()
  @IsString()
  quotasCreateFailedMessage?: string;

  @IsOptional()
  @IsBoolean()
  isEnd?: boolean;

  @IsOptional()
  @IsBoolean()
  hasMore?: boolean;
}
