import React, { ReactNode, forwardRef } from 'react';

import {
  PDFHeader,
  PDFGeneralInfo,
  PDFShipmentDetails,
  PDFCutoffDetails,
  PDFCargoAndContainers,
  PDFMarksAndNumbers,
  PDFDropoffDetails,
  PDFFreightAndCharges,
} from './components';
import { Document } from './PDFDocument.styled';

interface IPDFDocumentComponent {
  fileNamePrint?: string;
  header?: ReactNode;
}

export const PDFDocumentComponent = forwardRef((props: IPDFDocumentComponent, ref) => (
  <table
    ref={ref as React.RefObject<HTMLTableElement>}
    style={{
      minWidth: '100%',
      padding: 0,
    }}
  >
    {props?.fileNamePrint ? (<title>{props?.fileNamePrint}</title>) : null}
    <thead>
      <th>
        <td>
          <div style={{ height: '38px' }} />
        </td>
      </th>
    </thead>
    <tbody>
      <tr>
        <td>
          {props.header || <PDFHeader />}
          <Document>
            <PDFGeneralInfo />
            <PDFShipmentDetails />
            <PDFDropoffDetails />
            <PDFCutoffDetails />
            <PDFCargoAndContainers />
            <PDFMarksAndNumbers />
            <PDFFreightAndCharges />
          </Document>
        </td>
      </tr>
    </tbody>
  </table>
));
