import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { IncompleteStub } from 'shipment-operations/view/components';

import {
  SBLReferencesInformationWrapper,
  SBLReferencesInformation,
  SBLReferencesList,
  SBLReferencesItemTitle,
  SBLReferencesItemSubTitle,
  SBLReferencesItem,
  SBLReferencesTitle,
  Tag,
  RowItem,
  EditIcon,
  ClockIcon,
} from './SBLReferencesInformation.styled';

interface ISBLReferencesInformationComponentProps {
  items: Array<{
    title: string;
    subtitle?: string;
    redirectURL?: string;
    onAction?: () => void;
    isWaitingFromSkypace?: boolean;
  }>;
  mblPagePlacement?: boolean;
}

export const SBLReferencesInformationComponent: FC<ISBLReferencesInformationComponentProps> = ({ items, mblPagePlacement = false }) => {
  const { t } = useTranslation();

  if (!items.length) {
    return null;
  }

  return (
    <SBLReferencesInformationWrapper>
      <SBLReferencesInformation>
        <SBLReferencesTitle>
          {t('References')}
        </SBLReferencesTitle>

        <SBLReferencesList>
          {items.map(({
            title,
            subtitle,
            redirectURL,
            onAction,
            isWaitingFromSkypace,
          }) => (
            <SBLReferencesItem key={title}>
              <SBLReferencesItemTitle>
                {title}
              </SBLReferencesItemTitle>

              {subtitle ? (
                <RowItem>
                  <SBLReferencesItemSubTitle>
                    {subtitle}
                  </SBLReferencesItemSubTitle>

                  {onAction && !isWaitingFromSkypace ? <EditIcon onClick={onAction} /> : null}
                </RowItem>
              ) : (
                <>
                  {isWaitingFromSkypace ? (
                    <Tag icon={<ClockIcon />}>
                      {t('Waiting from Skypace')}
                    </Tag>
                  ) : (
                    <>
                      {redirectURL && !onAction ? <IncompleteStub redirectURL={redirectURL} /> : null}
                      {!redirectURL && onAction ? <IncompleteStub redirectURL="" clickAction={onAction} /> : null}
                      {mblPagePlacement ? <Tag>{t('Not Provided')}</Tag> : null}
                    </>
                  )}
                </>
              )}
            </SBLReferencesItem>
          ))}
        </SBLReferencesList>
      </SBLReferencesInformation>
    </SBLReferencesInformationWrapper>
  );
};
