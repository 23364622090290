import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { NoDataStub } from 'shipment-operations/view/components';
import { ClausesList } from 'shipment-operations/constants';

import {
  SBLClausesInformation,
  SBLClausesInformationTitleWrap,
  SBLClausesInformationList,
  SBLClausesInformationListItem,
  SBLClausesInformationTitle,
} from './SBLClausesInformation.styled';
import { SBLEditButton } from '../SBLEditButton';

interface ISBLBillingInstructionsComponentProps {
  onOpenEdit?: () => void;
  clauses: string[];
  isIncomplete?: boolean;
}

export const SBLClausesInformationComponent: FC<ISBLBillingInstructionsComponentProps> = ({
  isIncomplete,
  onOpenEdit,
  clauses,
}) => {
  const { t } = useTranslation();

  return (
    <SBLClausesInformation>
      <SBLClausesInformationTitleWrap>
        <SBLClausesInformationTitle>
          {t('Clauses')}
        </SBLClausesInformationTitle>

        {!isIncomplete && onOpenEdit ? (
          <SBLEditButton onClick={onOpenEdit}>
            {t('Edit')}
          </SBLEditButton>
        ) : null}
      </SBLClausesInformationTitleWrap>

      {isIncomplete ? (
        <NoDataStub onAddData={onOpenEdit} />
      ) : (
        <SBLClausesInformationList>
          {clauses.map((clauseId) => {
            const clause = ClausesList.find(({ id }) => id === clauseId);

            return clause ? (
              <SBLClausesInformationListItem>
                {clause.name}
              </SBLClausesInformationListItem>
            ) : null;
          })}
        </SBLClausesInformationList>
      )}
    </SBLClausesInformation>
  );
};
