import styled from 'styled-components';

import { FlexCol } from 'app-wrapper/view/guideline';

export const List = styled(FlexCol)`
  gap: 8px;
`;

export const PhaseTitle = styled.span`
  margin-top: 4px;
  font-size: 14px;
  line-height: 22px;
  font-weight: 500;
`;
