import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Footer,
  ExportClearanceTypeSelect,
  ExportClearanceNumberInput,
} from './components';
import {
  Form,
  Spinner,
  SpinnerWrap,
  Content,
  Label,
} from './BillOfLadingEditExportClearance.styled';

interface BillOfLadingEditExportClearanceComponentProps {
  isPending: boolean;
}

export const BillOfLadingEditExportClearanceComponent: FC<BillOfLadingEditExportClearanceComponentProps> = ({
  isPending,
}) => {
  const { t } = useTranslation();

  return (
    <Form>
      {isPending ? (
        <SpinnerWrap>
          <Spinner />
        </SpinnerWrap>
      ) : (
        <Content>
          <Label>{t('Type')}</Label>
          <ExportClearanceTypeSelect />
          <Label>{t('Entry Number')}</Label>
          <ExportClearanceNumberInput />

          <Footer />
        </Content>
      )}
    </Form>
  );
};
