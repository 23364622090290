import React, {
  FC,
  useCallback,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router';

import { MenuTrigger, Sider } from 'app-wrapper/view/components';

import { SubPage } from 'app-wrapper/view/guideline/SubPage';
import { SubMenuTriggerStickyContainer } from 'app-wrapper/view/pages/Home/Home.styled';
import { AccountMenu, AccountDrawer } from './components';
import {
  AccountLayout,
  AccountContent,
  AccountHeader,
} from './Account.styled';

export const Account: FC = () => {
  const { t } = useTranslation();
  const [isOpen, toggleSiderOpenCloseState] = useState(false);

  const siderToggleHandler = useCallback(() => toggleSiderOpenCloseState(!isOpen), [isOpen]);

  return (
    <AccountLayout>
      <AccountHeader>
        {t('My Account')}
      </AccountHeader>
      <AccountContent>
        <Sider
          trigger={null}
          collapsible
          collapsed={isOpen}
          width={213}
          collapsedWidth={47}
        >
          <AccountMenu />
          <SubMenuTriggerStickyContainer>
            <MenuTrigger onClick={siderToggleHandler} isCollapsed={isOpen} />
          </SubMenuTriggerStickyContainer>
        </Sider>
        <SubPage>
          <Outlet />
          <div id="subpage-footer" />
        </SubPage>
      </AccountContent>
      <AccountDrawer />
    </AccountLayout>
  );
};
