import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { NoDataStub } from 'shipment-operations/view/components';

import {
  SBLInformation,
  SBLTitle,
  SBLRow,
  SBLItem,
  SBLItemTitle,
  SBLItemSubTitle,
  SBLHeader,
} from './SBLBillOfLadingInformation.styled';
import { SBLEditButton } from '../SBLEditButton';

interface ISBLBillOfLadingInformationComponentProps {
  items: Array<{
    title: string;
    subtitle: string;
  }>;
  isIncomplete?: boolean;
  isEditable: boolean;
  openEditDrawer: (shipmentId: string) => void;
}

export const SBLBillOfLadingInformationComponent: FC<ISBLBillOfLadingInformationComponentProps> = ({
  items,
  isIncomplete,
  isEditable,
  openEditDrawer,
}) => {
  const { t } = useTranslation();
  const { shipmentId = '' } = useParams();

  const handleOpenEditDrawer = useCallback(() => {
    openEditDrawer(shipmentId);
  }, [openEditDrawer, shipmentId]);

  return (
    <SBLInformation>
      <SBLHeader>
        <SBLTitle>
          {t('BL Release Type')}
        </SBLTitle>

        {!isIncomplete && isEditable ? (
          <SBLEditButton onClick={handleOpenEditDrawer}>
            {t('Edit')}
          </SBLEditButton>
        ) : null}
      </SBLHeader>

      {isIncomplete ? (
        <NoDataStub
          onAddData={isEditable ? handleOpenEditDrawer : undefined}
        />
      ) : (
        <SBLRow>
          {items.map(({ title, subtitle }) => (
            <SBLItem key={title}>
              <SBLItemTitle>
                {title}
              </SBLItemTitle>

              <SBLItemSubTitle>
                {subtitle}
              </SBLItemSubTitle>
            </SBLItem>
          ))}
        </SBLRow>
      )}
    </SBLInformation>
  );
};
