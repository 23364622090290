import styled from 'styled-components';
import Typography from 'antd/es/typography';

import { divFlex } from 'app-wrapper/view/themes/mixin';

export const Wrap = styled.div.attrs((props: {
  'data-class'?: string
}) => ({
  'data-class': props?.['data-class'] || 'DocumentsComponent_Wrap',
}))`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Header = styled.div.attrs((props: {
  'data-class'?: string
}) => ({
  'data-class': props?.['data-class'] || 'DocumentsComponent_Header',
}))`
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 0 12px 8px 12px;
`;

export const HeaderTitle = styled.div.attrs((props: {
  'data-class'?: string
}) => ({
  'data-class': props?.['data-class'] || 'DocumentsComponent_HeaderTitle',
}))`
  ${divFlex}
  margin-top: 12px;

  width: 100%;
  flex-direction: row;
  padding: 0 12px 8px 12px;

  color: ${({ theme }) => theme?.themesColors?.neutralBranding10};
  font-size: 18px;
  font-weight: 500;
`;

export const Title = styled(Typography).attrs((props: {
  'data-class'?: string
}) => ({
  'data-class': props?.['data-class'] || 'DocumentsComponent_Title',
}))`
  font-size: 12px;
  line-height: 20px;
  color: rgba(115, 129, 155, 1);
`;

export const TitleSecond = styled(Title).attrs((props: {
  'data-class'?: string
}) => ({
  'data-class': props?.['data-class'] || 'DocumentsComponent_TitleSecond',
}))`
  margin-left: auto;
`;
