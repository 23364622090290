import styled from 'styled-components';

import { colors } from 'app-wrapper/view/themes/themesColors';

export const CargoCardBottomContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;

  width: 100%;
  > * {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 4px;
    color: ${colors.gray61};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 12px;
  }
`;

export const HazmatTagContainer = styled.div`
  margin-left: auto;
`;
