import React from 'react';
import { observer } from 'mobx-react-lite';

import { useMobxStore } from 'app-wrapper/mobxStores';
import { UC } from 'user-management/controllers';

import { MarkupPolicyResultComponent } from './MarkupPolicyResult.component';

export const MarkupPolicyResult = observer(() => {
  const { customerSales } = useMobxStore();

  return (
    <MarkupPolicyResultComponent
      downloadCurrentStep={UC.customerSales.downloadMarkupPolicy}
      markupPolicy={customerSales.state.markupPolicy}
    />
  );
});
