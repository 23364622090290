import React, { FC, useEffect } from 'react';
import { useParams } from 'react-router';

import { hasReadAccess } from 'app-wrapper/utils';
import { PermissionAttributePolicy } from 'shipment-operations/constants';
import { SkeletonSection } from 'app-wrapper/view/components/Skeleton/Skeleton.component';

import {
  CarrierCard,
  CarrierContactCard,
  ReferencesCard,
  ShippingPartiesCard,
} from './components';
import {
  OverviewLayout,
  SkeletonsLayout,
} from './styled';

interface IShipmentTransportationOverviewProps {
  onNoAccess: (shipmentId: string) => void
  tabAvailability?: PermissionAttributePolicy;
  isLoading: boolean
}

export const ShipmentTransportationOverviewComponent: FC<IShipmentTransportationOverviewProps> = ({
  onNoAccess,
  tabAvailability,
  isLoading,
}) => {
  const { shipmentId = '0' } = useParams<'shipmentId'>();

  useEffect(() => {
    if (!isLoading && !hasReadAccess(tabAvailability)) {
      onNoAccess(shipmentId);
    }
  }, [isLoading]);

  return (
    <OverviewLayout>
      {isLoading ? (
        <SkeletonsLayout>
          <SkeletonSection height="305px" />
          <SkeletonSection height="116px" />
          <SkeletonSection height="106px" />
          <SkeletonSection height="361px" />
        </SkeletonsLayout>
      ) : (
        <>
          <CarrierCard />
          <ReferencesCard />
          <CarrierContactCard />
          <ShippingPartiesCard />
        </>
      )}
    </OverviewLayout>
  );
};
