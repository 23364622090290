import React, { FC } from 'react';

import { useAppSelector } from 'app-wrapper/hooks/hooks';

import { R } from 'authentication/repository';
import { UC } from 'authentication/controllers';

import { PasswordResetNewPasswordComponent } from './PasswordResetNewPassword.component';

const PasswordResetNewPasswordContainer: FC = () => {
  const isLoading = useAppSelector(R.selectors.passwordResetNewPassword.getLoading);
  const codeValue = useAppSelector(R.selectors.passwordResetNewPassword.getCode);
  const codeError = useAppSelector(R.selectors.passwordResetNewPassword.getCodeError);
  const newPasswordValue = useAppSelector(R.selectors.passwordResetNewPassword.getNewPassword);
  const newPasswordError = useAppSelector(R.selectors.passwordResetNewPassword.getNewPasswordError);
  const confirmNewPasswordValue = useAppSelector(R.selectors.passwordResetNewPassword.getConfirmNewPassword);
  const confirmNewPasswordError = useAppSelector(R.selectors.passwordResetNewPassword.getConfirmNewPasswordError);

  return (
    <PasswordResetNewPasswordComponent
      loading={isLoading}
      codeValue={codeValue}
      codeError={codeError}
      onCodeFieldChange={UC.passwordResetNewPassword.setCode}
      onCodeFieldBlur={UC.passwordResetNewPassword.onBlurCode}
      newPasswordValue={newPasswordValue}
      newPasswordError={newPasswordError}
      onNewPasswordFieldChange={UC.passwordResetNewPassword.setNewPassword}
      onNewPasswordFieldBlur={UC.passwordResetNewPassword.onBlurNewPassword}
      confirmNewPasswordValue={confirmNewPasswordValue}
      confirmNewPasswordError={confirmNewPasswordError}
      onConfirmNewPasswordFieldChange={UC.passwordResetNewPassword.setConfirmNewPassword}
      onConfirmNewPasswordFieldBlur={UC.passwordResetNewPassword.onBlurConfirmNewPassword}
      onPasswordChange={UC.passwordResetNewPassword.sendPassword}
      onScreenLeave={UC.passwordResetNewPassword.onClear}
    />
  );
};

export { PasswordResetNewPasswordContainer as PasswordResetNewPassword };
