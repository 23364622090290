import React, { FC } from 'react';

import { IValidationErrorDTM, ValidationErrorType } from 'app-wrapper/types';
import { AlertBlock } from 'app-wrapper/view/components';

import { useTranslation } from 'react-i18next';

interface ICargoFormHsErrorAlertComponentProps {
  hsError?: IValidationErrorDTM, // TODO: replace when Cargo state will be transfered
}

export const CargoFormHsErrorAlertComponent: FC<ICargoFormHsErrorAlertComponentProps> = ({
  hsError,
}) => {
  const { t } = useTranslation();

  if (!hsError) {
    return null;
  }

  if (hsError.type !== ValidationErrorType.ALERT) {
    return null;
  }

  return (
    <AlertBlock
      type="warning"
      message={t(hsError.message)}
    />
  );
};
