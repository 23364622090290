import React, { FC, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { ICountryListDTM } from 'app-wrapper/models/dtm';
import { Button } from 'app-wrapper/view/components';

import { OrganizationDTM, OrganizationFieldsErrorsDTM } from 'user-management/models/dtm';

import {
  EditProfileOrganizationAddressComponent,
  EditProfileOrganizationContactComponent,
  EditProfileOrganizationFinancial,
  EditProfileOrganizationGeneralInformation,
  TradeReferences,
} from './components';
import {
  EditProfileOrganizationContent,
  EditProfileOrganizationDivider,
  EditProfileOrganizationFooter,
  EditProfileOrganizationWrap,
  NewOrganizationDataCancelButton,
  NewOrganizationDataNextButton,
  Spinner,
} from './NewOrganizationData.styled';

interface INewOrganizationDataComponentProps {
  isLoading: boolean;
  organization?: OrganizationDTM
  organizationFieldsErrors?: OrganizationFieldsErrorsDTM
  countriesList?: ICountryListDTM[]
  onOpen: () => void
  onClose: () => void
  onCancel: () => void
  onSave: () => void
  onNext: () => void
  onChangeUpdateAddress1: (address1: string) => void
  onChangeUpdateAddress2: (address2: string) => void
  onChangeUpdateAddressCity: (city: string) => void
  onChangeUpdateAddressPostalCode: (postalCode: string) => void
  onChangeUpdateAddressCountry: (country: string) => void
  onChangeUpdatePhone: (phone: string) => void
  onChangeUpdatePhone2: (phone2: string) => void
  onChangeUpdateEmail: (email: string) => void
  onChangeUpdateWebUrl: (webUrl: string) => void
  onBlurUpdateAddress1: () => void
  onBlurUpdateAddress2: () => void
  onBlurUpdateAddressCity: () => void
  onBlurUpdateAddressPostalCode: () => void
  onBlurUpdateAddressCountry: () => void
  onClearUpdateAddressCountry: () => void
  onBlurUpdatePhone: () => void
  onFocusUpdatePhone: () => void
  onBlurUpdatePhone2: () => void
  onFocusUpdatePhone2: () => void
  onBlurUpdateEmail: () => void
  onFocusUpdateEmail: () => void
  onBlurUpdateWebUrl: () => void
  onFocusUpdateWebUrl: () => void
}

export const NewOrganizationDataComponent: FC<INewOrganizationDataComponentProps> = ({
  isLoading,
  onOpen,
  onClose,
  onCancel,
  countriesList,
  onBlurUpdateAddress1,
  onBlurUpdateAddress2,
  onBlurUpdateAddressCity,
  onBlurUpdateAddressCountry,
  onBlurUpdateAddressPostalCode,
  onBlurUpdateEmail,
  onBlurUpdatePhone,
  onBlurUpdatePhone2,
  onBlurUpdateWebUrl,
  onChangeUpdateAddress1,
  onChangeUpdateAddress2,
  onChangeUpdateAddressCity,
  onChangeUpdateAddressCountry,
  onChangeUpdateAddressPostalCode,
  onChangeUpdateEmail,
  onChangeUpdatePhone,
  onChangeUpdatePhone2,
  onChangeUpdateWebUrl,
  onClearUpdateAddressCountry,
  onFocusUpdateEmail,
  onFocusUpdatePhone,
  onFocusUpdatePhone2,
  onFocusUpdateWebUrl,
  organization,
  organizationFieldsErrors,
  onSave,
  onNext,
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    onOpen();

    return onClose;
  }, []);

  const onCancelClickHandler = useCallback(() => {
    onCancel();
  }, []);

  const onSaveClickHandler = useCallback(() => {
    onSave();
  }, []);

  const onNextClickHandler = useCallback(() => {
    onNext();
  }, []);

  return (
    <EditProfileOrganizationWrap>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <EditProfileOrganizationContent>
            <EditProfileOrganizationGeneralInformation />

            <EditProfileOrganizationDivider />

            <EditProfileOrganizationAddressComponent
              organization={organization}
              organizationFieldsErrors={organizationFieldsErrors}
              countriesList={countriesList}
              onChangeUpdateAddress1={onChangeUpdateAddress1}
              onChangeUpdateAddress2={onChangeUpdateAddress2}
              onChangeUpdateAddressCity={onChangeUpdateAddressCity}
              onChangeUpdateAddressPostalCode={onChangeUpdateAddressPostalCode}
              onChangeUpdateAddressCountry={onChangeUpdateAddressCountry}
              onBlurUpdateAddress1={onBlurUpdateAddress1}
              onBlurUpdateAddress2={onBlurUpdateAddress2}
              onBlurUpdateAddressCity={onBlurUpdateAddressCity}
              onBlurUpdateAddressPostalCode={onBlurUpdateAddressPostalCode}
              onBlurUpdateAddressCountry={onBlurUpdateAddressCountry}
              onClearUpdateAddressCountry={onClearUpdateAddressCountry}
            />

            <EditProfileOrganizationDivider />

            <EditProfileOrganizationContactComponent
              organization={organization}
              organizationFieldsErrors={organizationFieldsErrors}
              onBlurUpdatePhone={onBlurUpdatePhone}
              onFocusUpdatePhone={onFocusUpdatePhone}
              onBlurUpdatePhone2={onBlurUpdatePhone2}
              onFocusUpdatePhone2={onFocusUpdatePhone2}
              onBlurUpdateEmail={onBlurUpdateEmail}
              onFocusUpdateEmail={onFocusUpdateEmail}
              onBlurUpdateWebUrl={onBlurUpdateWebUrl}
              onFocusUpdateWebUrl={onFocusUpdateWebUrl}
              onChangeUpdatePhone={onChangeUpdatePhone}
              onChangeUpdatePhone2={onChangeUpdatePhone2}
              onChangeUpdateEmail={onChangeUpdateEmail}
              onChangeUpdateWebUrl={onChangeUpdateWebUrl}
            />

            <EditProfileOrganizationDivider />

            <EditProfileOrganizationFinancial />

            <TradeReferences />

          </EditProfileOrganizationContent>

          <EditProfileOrganizationFooter>
            <NewOrganizationDataCancelButton
              type="default"
              disabled={!organization}
              onClick={onCancelClickHandler}
            >
              {t('Cancel')}
            </NewOrganizationDataCancelButton>

            <Button
              disabled
              type="default"
            >
              {t('Back')}
            </Button>

            <NewOrganizationDataNextButton
              disabled={!organization?.id}
              type="default"
              onClick={onSaveClickHandler}
            >
              {t('Save for Later')}
            </NewOrganizationDataNextButton>

            <NewOrganizationDataNextButton
              disabled={!organization}
              onClick={onNextClickHandler}
            >
              {t('Next')}
            </NewOrganizationDataNextButton>
          </EditProfileOrganizationFooter>
        </>
      )}
    </EditProfileOrganizationWrap>
  );
};
