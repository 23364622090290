import React, { FC } from 'react';
import AntCheckbox from 'antd/es/checkbox';
import { useTranslation } from 'react-i18next';

import { GensetFieldWrapper, GensetLabel } from './GensetField.styled';

interface IGensetFieldComponentProps {
  genset: boolean
}

export const GensetFieldComponent: FC<IGensetFieldComponentProps> = ({
  genset,
}) => {
  const { t } = useTranslation();

  return (
    <GensetFieldWrapper>
      <AntCheckbox
        disabled
        checked={genset}
      />
      <GensetLabel>{t('Genset')}</GensetLabel>
    </GensetFieldWrapper>
  );
};
