import styled from 'styled-components';
import Typography from 'antd/es/typography';

export const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 0 12px 8px 12px;
`;

export const Title = styled(Typography)`
  font-size: 12px;
  line-height: 20px;
  color: rgba(115, 129, 155, 1);
`;

export const TitleSecond = styled(Title)`
  margin-left: auto;
`;
