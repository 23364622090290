import { useState } from 'react';

// import { InvoiceDataTypeDTM } from 'shipment-operations/models/dtm';
import { UC } from 'shipment-operations/controllers';

interface IColumns {
  title: string
  dataIndex: string
  key: string
  width: string
  editable?: boolean
}

const useTableEditable = (data: any, defaultColumns: IColumns[]) => {
  const [dataSource, setDataSource] = useState(data);

  const handleSave = (row: any) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    setDataSource(newData);
    UC.makePayment.onUpdateEditCell(row.innerId, row.payment, row.balance);
  };

  const columns = defaultColumns.map((col: IColumns) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: any) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });

  return {
    dataSource,
    columns,
  };
};

export { useTableEditable };
