import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { normalizationCost } from 'app-wrapper/utils';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import {
  Wrapper, InnerWrapper, Title, Description,
} from './GensetConfirmation.styled';

interface GensetConfirmationComponentProps {
  withGenset: boolean;
  gensetSum?: number;
  isCheckedGenset: boolean;
}

const GensetConfirmationComponent: FC<GensetConfirmationComponentProps> = ({ withGenset, gensetSum, isCheckedGenset }) => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      {!withGenset && (
        <>
          <ExclamationCircleOutlined style={{ fontSize: '24px', color: '#faad14' }} />
          <InnerWrapper>
            <Title>{t('Shipment Total Cost will be increased')}</Title>
            <Description>{t('gensetNoChangeDescription')}</Description>
          </InnerWrapper>
        </>
      )}
      {withGenset && isCheckedGenset && (
        <>
          <ExclamationCircleOutlined style={{ fontSize: '24px', color: '#faad14' }} />
          <InnerWrapper>
            <Title>{t('gensetIncreaseBy', { amount: normalizationCost(gensetSum || 0, { toFixed: 2, thousandthSeparatorComma: true }) })}</Title>
            <Description>{t('gensetDescription')}</Description>
          </InnerWrapper>
        </>
      )}
      {withGenset && !isCheckedGenset && (
        <>
          <ExclamationCircleOutlined style={{ fontSize: '24px', color: '#faad14' }} />
          <InnerWrapper>
            <Title>{t('gensetDecreaseBy', { amount: normalizationCost(gensetSum || 0, { toFixed: 2, thousandthSeparatorComma: true }) })}</Title>
            <Description>{t('gensetDescriptionOff')}</Description>
          </InnerWrapper>
        </>
      )}
    </Wrapper>
  );
};

export { GensetConfirmationComponent };
