import { IsEnum, IsOptional } from 'class-validator';
import { EPermissionAttributePolicy } from 'monetary/constants';
import { BaseDTM } from 'proto/BaseDTM';

import { PermissionAttributePolicy } from 'shipment-operations/constants';

export interface IUserManagementProfilePermissionsDTM {
  userDepartmentsAndTeamsEdit?: EPermissionAttributePolicy;
  userDepartmentsAndTeamsView?: EPermissionAttributePolicy;
}

export class UserManagementProfilePermissionsDTM extends BaseDTM<IUserManagementProfilePermissionsDTM> {
  @IsOptional()
  @IsEnum(PermissionAttributePolicy)
  userDepartmentsAndTeamsEdit?: PermissionAttributePolicy;

  @IsOptional()
  @IsEnum(PermissionAttributePolicy)
  userDepartmentsAndTeamsView?: PermissionAttributePolicy;
}
