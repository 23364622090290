import { Type } from 'class-transformer';
import {
  IsDefined,
  IsOptional,
  IsBoolean,
  IsNumber,
  ValidateNested,
} from 'class-validator';
import { BaseDTM } from 'proto/BaseDTM';

export interface IFreightQuotaPageableSortDTM {
  empty: boolean
  sorted: boolean
  unsorted: boolean
}

export class FreightQuotaPageableSortDTM extends BaseDTM<IFreightQuotaPageableSortDTM> {
  @IsDefined()
  @IsBoolean()
  empty: boolean;

  @IsDefined()
  @IsBoolean()
  sorted: boolean;

  @IsDefined()
  @IsBoolean()
  unsorted: boolean;
}

export interface IFreightQuotaPageableDTM {
  sort?: IFreightQuotaPageableSortDTM;
  offset?: number
  pageNumber?: number
  pageSize?: number
  paged?: boolean
  unpaged?: boolean
}

export class FreightQuotaPageableDTM extends BaseDTM<IFreightQuotaPageableDTM> {
  @IsOptional()
  @ValidateNested()
  @Type(() => FreightQuotaPageableSortDTM)
  sort?: FreightQuotaPageableSortDTM;

  @IsOptional()
  @IsNumber()
  offset?: number;

  @IsOptional()
  @IsNumber()
  pageNumber?: number;

  @IsOptional()
  @IsNumber()
  pageSize?: number;

  @IsOptional()
  @IsBoolean()
  paged?: boolean;

  @IsOptional()
  @IsBoolean()
  unpaged?: boolean;
}
