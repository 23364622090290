import {
  IsBoolean,
  IsDefined,
} from 'class-validator';
import { BaseDTM } from 'proto/BaseDTM';

export type TUserManagementPermissionsDtm = {
  userInviteButton: boolean
  isAdmin: boolean
  organizationDeletedDocumentsDetails: boolean
  organizationEditProfileIdentifiers: boolean
  organizationEditProfileIdentifiersType: boolean
  organizationEditProfileIdentifiersTypeIndustry: boolean
  organizationEditProfileCountry: boolean
  organizationAccountShowDepartments: boolean
  documentsUserTermsOfService: boolean
  isVisibleAssigneeOrganizationTasks: boolean
}

export class UserManagementPermissionsDtm extends BaseDTM<TUserManagementPermissionsDtm> {
  @IsDefined()
  @IsBoolean()
  userInviteButton: boolean

  @IsDefined()
  @IsBoolean()
  isAdmin: boolean

  @IsDefined()
  @IsBoolean()
  organizationDeletedDocumentsDetails: boolean

  @IsDefined()
  @IsBoolean()
  organizationEditProfileIdentifiers: boolean

  @IsBoolean()
  organizationEditProfileIdentifiersType: boolean

  @IsBoolean()
  organizationEditProfileIdentifiersTypeIndustry: boolean

  @IsDefined()
  @IsBoolean()
  organizationEditProfileCountry: boolean

  @IsDefined()
  @IsBoolean()
  organizationAccountShowDepartments: boolean

  @IsBoolean()
  documentsUserTermsOfService: boolean

  @IsBoolean()
  isVisibleAssigneeOrganizationTasks: boolean
}
