import {
  IsOptional,
  IsDefined,
  IsNumber,
  IsString,
  IsEnum,
  ValidateNested,
  IsBoolean,
} from 'class-validator';
import { Type } from 'class-transformer';

import { BaseDTM } from 'proto/BaseDTM';

import { EUserStatus } from 'user-management/constants';

import {
  ChargeCodeDesignation, ChargeCodeMeasureBy, ChargeCodeMode, ChargeCodeOccurrence, ChargeCodePriceBy, ChargeCodeSubType, ChargeCodeSubjectTo, ChargeCodeType, TRouteLegPhase,
} from 'shipment-operations/constants';
import { EOceanRateLoadChargeCodeType } from '../FreightLoadType';

export enum AdditionalServiceGroupEnum {
  FORMALITIES = 'FORMALITIES',
  FREIGHT_HANDLING = 'FREIGHT_HANDLING',
}

export interface IAdditionalServiceDTM {
  code: string;
  key?: string;
  description: string;
  mode: string;
  loadType: string;
  type: string;
  subType?: string;
  occurrence: string;
  group: AdditionalServiceGroupEnum;
  phases: Array<TRouteLegPhase> | null;
  designation?: string;
  priceBy?: string;
  measureBy?: string;
  status: string;
  organizationId: number;
  createdBy: string;
  createdAt: string;
  updatedBy?: string;
  updatedAt?: string;
}

export class AdditionalServiceDTM extends BaseDTM<IAdditionalServiceDTM> {
  @IsDefined()
  @IsString()
  code: string;

  @IsOptional()
  @IsString()
  key?: string;

  @IsDefined()
  @IsString()
  description: string;

  @IsDefined()
  @IsString()
  mode: string;

  @IsDefined()
  @IsString()
  loadType: string;

  @IsDefined()
  @IsString()
  type: string;

  @IsOptional()
  @IsString()
  subType?: string;

  @IsDefined()
  @IsString()
  occurrence: string;

  @IsDefined()
  @IsEnum(AdditionalServiceGroupEnum)
  group: AdditionalServiceGroupEnum;

  @IsOptional()
  @IsEnum(TRouteLegPhase, { each: true })
  phases: TRouteLegPhase[] | null

  @IsOptional()
  @IsString()
  designation?: string;

  @IsOptional()
  @IsString()
  priceBy?: string;

  @IsOptional()
  @IsString()
  measureBy?: string;

  @IsDefined()
  @IsString()
  status: string;

  @IsDefined()
  @IsNumber()
  organizationId: number;

  @IsDefined()
  @IsString()
  createdBy: string;

  @IsDefined()
  @IsString()
  createdAt: string;

  @IsOptional()
  updatedBy?: string;

  @IsOptional()
  updatedAt?: string;
}

export type IAdditionalServiceCheckedDTM = {
  code: string,
  phase: TRouteLegPhase | string | null,
  quantity?: number
}

export class AdditionalServiceCheckedDTM extends BaseDTM<IAdditionalServiceCheckedDTM> {
  @IsDefined()
  @IsString()
  code: string;

  @IsDefined()
  @IsString()
  phase: TRouteLegPhase | string | null;

  @IsNumber()
  quantity: number;
}

export interface IAdditionalRatesServiceChargeCodeDTM {
  code: string;
  description: string;
  mode: ChargeCodeMode;
  loadType: EOceanRateLoadChargeCodeType;
  additionalInformation?: string;
  type: ChargeCodeType;
  subType?: ChargeCodeSubType;
  occurrence: ChargeCodeOccurrence;
  phases?: ChargeCodeDesignation[];
  group?: AdditionalServiceGroupEnum;
  status?: EUserStatus;
  organizationId?: number;
  createdBy?: string;
  createdAt?: string;
  updatedBy?: string;
  updatedAt?: string;
}

export class AdditionalRatesServiceChargeCodeDTM extends BaseDTM<IAdditionalRatesServiceChargeCodeDTM> {
  @IsString()
  code: string;

  @IsString()
  description: string;

  @IsEnum(ChargeCodeMode)
  mode: ChargeCodeMode;

  @IsEnum(EOceanRateLoadChargeCodeType)
  loadType: EOceanRateLoadChargeCodeType;

  @IsOptional()
  @IsString()
  additionalInformation?: string;

  @IsEnum(ChargeCodeType)
  type: ChargeCodeType;

  @IsOptional()
  @IsEnum(ChargeCodeSubType)
  subType?: ChargeCodeSubType;

  @IsEnum(ChargeCodeOccurrence)
  occurrence: ChargeCodeOccurrence;

  @IsOptional()
  @IsEnum(ChargeCodeDesignation, { each: true })
  phases?: ChargeCodeDesignation[];

  @IsOptional()
  @IsEnum(AdditionalServiceGroupEnum)
  group?: AdditionalServiceGroupEnum;

  @IsOptional()
  @IsEnum(EUserStatus)
  status?: EUserStatus;

  @IsOptional()
  @IsNumber()
  organizationId?: number;

  @IsOptional()
  @IsString()
  createdBy?: string;

  @IsOptional()
  @IsString()
  createdAt?: string;

  @IsOptional()
  @IsString()
  updatedBy?: string;

  @IsOptional()
  @IsString()
  updatedAt?: string;
}

export interface IAdditionalRatesServiceApplicationLevelDTM {
  id?: number;
  type: string;
}

export class AdditionalRatesServiceApplicationLevelDTM extends BaseDTM<IAdditionalRatesServiceApplicationLevelDTM> {
  @IsOptional()
  @IsNumber()
  id?: number;

  @IsString()
  type: string;
}

export interface IAdditionalRatesServiceApplicationRangeDTM {
  minValue?: number;
  maxValue?: number;
}

export class AdditionalRatesServiceApplicationRangeDTM extends BaseDTM<IAdditionalRatesServiceApplicationRangeDTM> {
  @IsOptional()
  @IsNumber()
  minValue?: number;

  @IsOptional()
  @IsNumber()
  maxValue: number;
}

export interface IAdditionalRatesServiceValidPeriodDTM {
  from?: string;
  to?: string;
}

export class AdditionalRatesServiceValidPeriodDTM extends BaseDTM<IAdditionalRatesServiceValidPeriodDTM> {
  @IsOptional()
  @IsString()
  from?: string;

  @IsOptional()
  @IsString()
  to?: string;
}

export interface IAdditionalRatesServiceDTM {
  id?: number;
  customId?: string;
  key?: number;
  countDocument?: string;
  chargeCode: IAdditionalRatesServiceChargeCodeDTM
  applicationLevel?: IAdditionalRatesServiceApplicationLevelDTM
  designation: ChargeCodeDesignation;
  priceBy: ChargeCodePriceBy;
  measureBy: ChargeCodeMeasureBy;
  applianceRange?: IAdditionalRatesServiceApplicationRangeDTM
  validPeriod?: IAdditionalRatesServiceValidPeriodDTM
  subjectTo: ChargeCodeSubjectTo;
  currency: string;
  status?: EUserStatus;
  organizationId?: number;
  createdBy?: string;
  createdAt?: string;
  scac: string;
  containerType?: string;
  buyPrice: number;
  isChecked?: boolean;
}

export class AdditionalRatesServiceDTM extends BaseDTM<IAdditionalRatesServiceDTM> {
  @IsOptional()
  @IsString()
  id?: number;

  @IsOptional()
  @IsString()
  customId?: string;

  @IsOptional()
  @IsNumber()
  key?: number;

  @IsOptional()
  @IsString()
  countDocument?: string;

  @ValidateNested()
  @Type(() => AdditionalRatesServiceChargeCodeDTM)
  chargeCode: AdditionalRatesServiceChargeCodeDTM;

  @IsOptional()
  @ValidateNested()
  @Type(() => AdditionalRatesServiceApplicationLevelDTM)
  applicationLevel?: AdditionalRatesServiceApplicationLevelDTM;

  @IsEnum(ChargeCodeDesignation)
  designation: ChargeCodeDesignation;

  @IsEnum(ChargeCodePriceBy)
  priceBy: ChargeCodePriceBy;

  @IsEnum(ChargeCodeMeasureBy)
  measureBy: ChargeCodeMeasureBy;

  @IsOptional()
  @ValidateNested()
  @Type(() => AdditionalRatesServiceApplicationRangeDTM)
  applianceRange?: AdditionalRatesServiceApplicationRangeDTM;

  @IsOptional()
  @ValidateNested()
  @Type(() => AdditionalRatesServiceValidPeriodDTM)
  validPeriod?: AdditionalRatesServiceValidPeriodDTM;

  @IsEnum(ChargeCodeSubjectTo)
  subjectTo: ChargeCodeSubjectTo;

  @IsString()
  currency: string;

  @IsOptional()
  @IsEnum(EUserStatus)
  status?: EUserStatus;

  @IsOptional()
  @IsString()
  organizationId?: number;

  @IsOptional()
  @IsString()
  createdBy?: string;

  @IsOptional()
  @IsString()
  createdAt?: string;

  @IsString()
  scac: string;

  @IsOptional()
  @IsString()
  containerType?: string;

  @IsString()
  buyPrice: number;

  @IsOptional()
  @IsBoolean()
  isChecked: boolean;
}
