import {
  IsEnum, IsOptional, IsString, IsDefined, IsNumber, ValidateNested, IsBoolean,
} from 'class-validator';
import { Type, instanceToInstance } from 'class-transformer';

import { IValidationErrorDTM, ValidationErrorDTM } from 'app-wrapper/types';
import { BaseDTM } from 'proto/BaseDTM';

import { BillOfLadingType, EShippingPartyTypes } from 'shipment-operations/constants';

export interface IShippingPartiesMapDTM {
  [id: number]: EShippingPartyTypes
}

export interface IDocumentsDistributionErrorsDTM {
  party?: IValidationErrorDTM,
  amount?: IValidationErrorDTM,
}

export class DocumentsDistributionErrorsDTM extends BaseDTM<IDocumentsDistributionErrorsDTM> {
  @IsOptional()
  @ValidateNested()
  @Type(() => ValidationErrorDTM)
  party: ValidationErrorDTM

  @IsOptional()
  @ValidateNested()
  @Type(() => ValidationErrorDTM)
  amount: ValidationErrorDTM
}

export interface IDocumentsDistributionDTM {
  id: string
  freighted: boolean
  party?: EShippingPartyTypes
  amount?: string
}

export class DocumentsDistributionDTM extends BaseDTM<IDocumentsDistributionDTM> {
  @IsString()
  @IsDefined()
  id: string

  @IsOptional()
  @IsEnum(EShippingPartyTypes)
  party?: EShippingPartyTypes;

  @IsOptional()
  @IsString()
  amount?: string;

  @IsDefined()
  @IsBoolean()
  freighted: boolean;
}

export interface IBillOfLadingDTM {
  id?: number
  type?: BillOfLadingType
  originals: IDocumentsDistributionDTM[]
  copies: IDocumentsDistributionDTM[]
}

export class BillOfLadingDTM extends BaseDTM<IBillOfLadingDTM> {
  @IsOptional()
  @IsNumber()
  id?: number

  @IsOptional()
  @IsEnum(BillOfLadingType)
  type: BillOfLadingType

  @IsDefined()
  @ValidateNested()
  @Type(() => DocumentsDistributionDTM)
  originals: DocumentsDistributionDTM[]

  @IsDefined()
  @ValidateNested()
  @Type(() => DocumentsDistributionDTM)
  copies: DocumentsDistributionDTM[]

  static getInstanceCopy = (input: BillOfLadingDTM) => instanceToInstance(input);
}
