import React from 'react';
import { useSelector } from 'react-redux';

import { UC } from 'shipment-operations/controllers';
import { R } from 'shipment-operations/repository';
import { UC as appUC } from 'app-wrapper/controllers';

import { BLMismatchesComponent } from './BLMismatches.component';

const BLMismatchesContainer = () => {
  const loading = useSelector(R.selectors.shipmentChanges.getLoading);
  const cargoTable = useSelector(R.selectors.shipmentChanges.getCargoTableBL);
  const entryNumbers = useSelector(R.selectors.shipmentChanges.getEntryNumbers);
  const containersTable = useSelector(R.selectors.shipmentChanges.getContainersTableBL);
  const paymentTermsTable = useSelector(R.selectors.shipmentChanges.getPaymentTerms);
  const BLOptionsTable = useSelector(R.selectors.shipmentChanges.getBlOptions);
  const shipmentPartiesTable = useSelector(R.selectors.shipmentChanges.getShipmentPartiesTableBL);
  const isWithSubmitButton = useSelector(R.selectors.shipmentChanges.getIsWithSubmitButton);

  return (
    <BLMismatchesComponent
      loadData={UC.shipmentChanges.loadBlMismatches}
      cargoTable={cargoTable}
      entryNumbers={entryNumbers}
      containersTable={containersTable}
      paymentTermsTable={paymentTermsTable}
      BLOptionsTable={BLOptionsTable}
      shipmentPartiesTable={shipmentPartiesTable}
      loading={loading}
      onClose={appUC.drawer.closeDrawer}
      approveDraftHBL={UC.billOfLading.approveDraftHBLFromMissMatchesDrawer}
      withSubmitButton={isWithSubmitButton}
    />
  );
};

export { BLMismatchesContainer as BLMismatches };
