import React, { FC, memo } from 'react';
import { useSelector } from 'react-redux';

import { R } from 'user-management/repository';
import { UC } from 'user-management/controllers';

import {
  EditProfileOrganizationFinancialComponent,
} from './EditProfileOrganizationFinancial.component';

const EditProfileOrganizationFinancialContainer: FC = () => {
  const organization = useSelector(R.selectors.newOrganizationData.getUserOrganizationUpdate);
  const organizationFieldsErrors = useSelector(R.selectors.newOrganizationData.organizationFieldsErrors);

  return (
    <EditProfileOrganizationFinancialComponent
      organization={organization}
      organizationFieldsErrors={organizationFieldsErrors}
      onChangeUpdateBusinessRegistrationNumber={UC.newOrganizationData.onChangeUpdateBusinessRegistrationNumber}
      onAddMembership={UC.newOrganizationData.onAddUpdateMembership}
      onRemoveMembership={UC.newOrganizationData.onRemoveUpdateMembership}
      onChangeUpdateMembershipType={UC.newOrganizationData.onChangeUpdateMembershipType}
      onChangeUpdateMembershipValue={UC.newOrganizationData.onChangeUpdateMembershipValue}
      onChangeUpdateTaxId={UC.newOrganizationData.onChangeUpdateTaxId}
      onBlurUpdateBusinessRegistrationNumber={UC.newOrganizationData.onBlurUpdateBusinessRegistrationNumber}
      onBlurUpdateMembership={UC.newOrganizationData.onBlurUpdateMembership}
      onBlurUpdateTaxId={UC.newOrganizationData.onBlurUpdateTaxId}
    />
  );
};

export const EditProfileOrganizationFinancial = memo(EditProfileOrganizationFinancialContainer);
