import styled from 'styled-components';
import ArrowLeftOutlined from '@ant-design/icons/ArrowLeftOutlined';

import { SubPageHeader } from 'app-wrapper/view/guideline';

export const SubPageHeaderStyled = styled(SubPageHeader)`
  position: relative;
`;

export const StyledBackIcon = styled(ArrowLeftOutlined)`
  font-size: 20px;
  margin-right: 10px;
  cursor: pointer;
  color: #202C3C;
`;

export const ShareInvoiceWrap = styled.div`
  margin-left: 8px;
`;

export const LeftContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const SkeletonWrap = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 9;
`;

export const StyledStatus = styled.span`
  position: relative;
  display: flex;
  align-items: center;
  margin-right: 15px;

  &::before {
    content: '';
    position: absolute;
    width: 5px;
    height: 5px;
    background-color: ${({ color }) => color};
    border-radius: 50%;
    right: -15px;
  }
`;
