import Role from 'app-wrapper/enums/Roles';
import { RequiredShipmentRoles } from 'app-wrapper/constants';
import {
  get as requestGet,
  put as requestPut,
} from 'app-wrapper/utils/fetchApi';

import { IShippingPartyForm } from './types';

// TODO: move networking to redux

interface IShipmentContactResponse {
  companyId: number
  addressId: number
  contactId: number
}
interface IShipmentResponse {
  role: string,
  contact: IShipmentContactResponse
  references: any[],
}

export class ShipmentPartyService {
  private base = '/shipment-service/api/v1/shipments';

  private contactBase = '/contact-service/api/v1/companies';

  public createDefaultShipmentParty = (
    _role: keyof typeof Role, _id: number,
  ): IShippingPartyForm => ({
    id: _id,
    role: _role,
    currentOrganization: false,
    references: [{
      id: 0,
      value: '',
    }],
  })

  public getDefaultRoles = () => RequiredShipmentRoles.map(this.createDefaultShipmentParty);

  public getShipmentPartiesRaw = (shipmentId: number) => (
    requestGet(`${this.base}/${shipmentId}/parties`)
      .then((response) => (response.ok ? response.json() : null))
  )

  public getShipmentParties = (shipmentId: number) => (
    requestGet(`${this.base}/${shipmentId}/parties`)
      .then((response) => (
        response.ok ? response.json() : []
      ))
      .then((data) => {
        const preparedData = data.map((item: IShipmentResponse) => ({
          ...item,
          ...(!item.references ? { references: [{ id: 0, value: '' }] } : {}),
        }));
        const promises: any = [];
        preparedData.forEach((elem: IShipmentResponse) => {
          const { companyId, addressId, contactId } = elem.contact;
          promises.push(requestGet(`${this.contactBase}/${companyId}`));
          promises.push(requestGet(`${this.contactBase}/${companyId}/contacts/${contactId}`));
          promises.push(requestGet(`${this.contactBase}/${companyId}/addresses/${addressId}`));
        });
        return Promise.all(promises);
      })
      .then((response: any) => response.json())
  )

  public updateShipmentParty = (shipmentId: number, _shipmentParty: IShippingPartyForm) => (
    requestPut(`${this.base}/${shipmentId}/parties/${_shipmentParty.id}`)
      .then((response) => (
        response.ok ? response.json() : []
      ))
  )
}
