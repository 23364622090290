import React from 'react';
import { observer } from 'mobx-react-lite';

import { useMobxStore } from 'app-wrapper/mobxStores';
import { UC } from 'app-wrapper/controllers';

import { CarriersFilterComponent } from './CarriersFilter.component';

export const CarriersFilter = observer(() => {
  const { contracts } = useMobxStore();

  return (
    <CarriersFilterComponent
      carriersFiltersGroup={contracts.state.carriersFiltersGroup}
      hasSelectedCarriersFilters={!!contracts.chosenCarriersFiltersGroup.length}
      setCarrierFilterByIndex={UC.contracts.setCarrierFilterByIndex}
      resetCarrierFiltersGroup={UC.contracts.resetCarriersFilters}
      applyFilters={UC.contracts.applyFilters}
    />
  );
});
