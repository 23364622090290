import React from 'react';

import { useAppSelector } from 'app-wrapper/hooks';

import { UC } from 'shipment-operations/controllers';
import { R } from 'shipment-operations/repository';

import { MakePaymentComponent } from './MakePayment.component';

const MakePaymentContainer = () => {
  const isError = useAppSelector(R.selectors.makePayment.getIsErrorCreation);

  return (
    <MakePaymentComponent
      onClose={UC.shipmentBillingInvoice.closePayment}
      onCloseError={UC.makePayment.onCloseError}
      isError={isError}
    />
  );
};

export { MakePaymentContainer as MakePayment };
