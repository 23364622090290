import React, { FC } from 'react';
import isEmpty from 'lodash/fp/isEmpty';

import { calculateInvoiceSum } from 'shipment-operations/view/pages/ShipmentBillingInvoice/utils/calculateSum';
import { InvoicesChargeDTM, ContainerViewDTM, IGroupedInvoicesChargesDTM } from 'shipment-operations/models/dtm';

import {
  HeaderTableWrapper,
  InnerContainer,
  StyledTotal,
  Title,
} from 'shipment-operations/view/components';
import { CustomTable, ExpandPanel } from 'shipment-operations/view/pages/CreateCreditNote/components/FirstStep/components';
import { useTranslation } from 'react-i18next';
import Divider from 'antd/es/divider';

interface IServicesComponentProps {
  data: IGroupedInvoicesChargesDTM
  sum: number
  containers: ContainerViewDTM[]
  tableData: InvoicesChargeDTM[]
}

const ServicesComponent: FC<IServicesComponentProps> = ({
  containers, data, tableData, sum,
}) => {
  const { t } = useTranslation();

  return ((!isEmpty(data) || !isEmpty(tableData)) ? (
    <>
      <InnerContainer>
        <Title>{t('services')}</Title>
        {!isEmpty(containers) && (
        <>
          <Divider style={{ margin: '0' }} />
          <HeaderTableWrapper>
            <span>{t('Containers')}</span>
            <span>{t('total')}</span>
          </HeaderTableWrapper>
          <Divider style={{ margin: '0' }} />
        </>
        )}
        {!isEmpty(data) && containers.map((item) => (
          <ExpandPanel
            container={item}
            sum={calculateInvoiceSum(data[item.id])}
            key={item.id}
            keys={data[item.id].map((elem) => elem.id)}
          >
            <CustomTable data={data[item.id]} fullTable />
          </ExpandPanel>
        ))}
        {!isEmpty(tableData) && (<CustomTable data={tableData} fullTable={false} />)}
      </InnerContainer>
      <StyledTotal>$ {sum.toFixed(2)}</StyledTotal>
    </>
  ) : null);
};

export { ServicesComponent };
