import styled from 'styled-components';
import Typography from 'antd/es/typography';
import Button from 'antd/es/button';

export const NoDataStub = styled.div.attrs({
  'data-class': 'NoDataStub',
})`
  width: 100%;
  height: 100%;
  padding: 8px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px dashed ${({ theme }) => theme.themesColors.neutralBranding4};
`;

export const NoDataStubTitle = styled(Typography)`
  margin-top: 8px;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
`;

export const NoDataButton = styled(Button)`
  width: 80px;
  margin-top: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  color: ${({ theme }) => theme.themesColors.characterBrandingTitle85};

  &:hover {
    border-color: ${({ theme }) => theme.themesColors.neutralBranding5};
    color: ${({ theme }) => theme.themesColors.characterBrandingTitle85};
  }
`;

export const NoDataButtonStub = styled.div`
  margin-top: 16px;
`;
