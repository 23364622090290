import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 58px;
  padding: 20px 24px;
  background-color: rgba(32, 44, 60, 1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;

export const Title = styled.div`
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  width: fit-content;
  text-align: center;
  font-weight: 600;
  font-size: 14px;
  color: white;
`;
