import React, { FC, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import Spinner from 'antd/es/spin';

import i18n from 'app-wrapper/i18n/i18n';
import { EShipmentCancellationType } from 'shipment-operations/constants';
import { EOrganizationMemberRole } from 'user-management/constants';

import {
  Label,
  Modal,
  ReasonSelect,
  SpinnerLayout,
  TextArea,
  Title,
  TitleContent,
  TitleContentSubTitle,
  TitleContentTitle,
  TitleIcon,
} from './CancelShipmentModal.styled';

const CANCELLATION_OPTIONS = [
  {
    value: EShipmentCancellationType.BOOKING_WAS_DECLINED,
    label: i18n.t('Booking was declined'),
  },
  {
    value: EShipmentCancellationType.NO_MANUAL_BOOKING_CONFIRMATION,
    label: i18n.t('No manual booking confirmation'),
  },
  {
    value: EShipmentCancellationType.BOOKING_WAS_NOT_CONFIRMED_BY_CARRIER,
    label: i18n.t('Booking was not confirmed by carrier'),
  },
  {
    value: EShipmentCancellationType.NO_NRA_AND_TC_AGREEMENTS,
    label: i18n.t('No NRA and TC agreements'),
  },
  {
    value: EShipmentCancellationType.CUSTOMER_NOT_AGREED_WITH_TRANSPORT_CHANGES,
    label: i18n.t('Customer not agreed with transport changes'),
  },
  {
    value: EShipmentCancellationType.MISSING_REQUIRED_LOCATIONS,
    label: i18n.t('Missing required locations'),
  },
  {
    value: EShipmentCancellationType.OTHER,
    label: i18n.t('Other'),
  },
];

interface CancelShipmentModalComponentProps {
  organizationRole?: EOrganizationMemberRole;
  isOpen: boolean;
  isCancelShipmentPending: boolean;
  onClose: () => void;
  onOk: (shipmentId: string) => void;
  cancellationMessage: string;
  setCancellationMessage: (message: string) => void;
  setCancellationType: (type: EShipmentCancellationType) => void;
  cancellationType?: EShipmentCancellationType;
}

export const CancelShipmentModalComponent: FC<CancelShipmentModalComponentProps> = ({
  organizationRole,
  isOpen,
  onOk,
  onClose,
  cancellationMessage,
  isCancelShipmentPending,
  cancellationType,
  setCancellationMessage,
  setCancellationType,
}) => {
  const { shipmentId = '' } = useParams();
  const { t } = useTranslation();

  useEffect(() => {
    if (isOpen && organizationRole === EOrganizationMemberRole.CUSTOMER) {
      setCancellationType(EShipmentCancellationType.OTHER);
    }
  }, [isOpen]);

  const handleMessageChange = useCallback((e) => {
    setCancellationMessage(e.target.value);
  }, [cancellationMessage]);

  const handleOk = useCallback(() => {
    onOk(shipmentId);
  }, [onOk, shipmentId]);

  return (
    <Modal
      visible={isOpen}
      onCancel={onClose}
      onOk={handleOk}
      okText={t('Cancel shipment')}
      cancelText={t('Undo')}
      okButtonProps={{
        disabled: !cancellationType || isCancelShipmentPending,
      }}
      cancelButtonProps={{
        disabled: isCancelShipmentPending,
      }}
    >
      {isCancelShipmentPending ? (
        <SpinnerLayout>
          <Spinner size="large" />
        </SpinnerLayout>
      ) : null}
      <Title data-class="cancel-shipment-modal-title">
        <TitleIcon />

        <TitleContent>
          <TitleContentTitle>
            {t('Cancel Shipment')}
          </TitleContentTitle>

          <TitleContentSubTitle data-class="cancel-shipment-subtitle">
            {organizationRole !== EOrganizationMemberRole.CUSTOMER
              ? t('Please specify the cancellation reason. You can also provide additional notes, if needed')
              : t('Please let us know why you’re cancelling.')}
          </TitleContentSubTitle>
        </TitleContent>
      </Title>

      {organizationRole !== EOrganizationMemberRole.CUSTOMER ? (
        <>
          <Label>
            {t('Cancellation Reason *')}
          </Label>

          <ReasonSelect
            data-class="cancel-shipment-reason-select"
            options={CANCELLATION_OPTIONS}
            value={cancellationType}
            placeholder={t('Reason')}
            onChange={setCancellationType}
            allowClear
          />
        </>
      ) : null}

      {cancellationType === EShipmentCancellationType.OTHER ? (
        <>
          <Label>
            {t('Cancellation reason (optional)')}
          </Label>

          <TextArea
            data-class="cancel-shipment-reason-message"
            placeholder={t('Type your comment here...')}
            value={cancellationMessage}
            onChange={handleMessageChange}
          />
        </>
      ) : null}
    </Modal>
  );
};
