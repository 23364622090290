import { R as AuthR } from 'authentication/repository';
import { BaseController, controller } from 'proto/BaseController';

import { OrganizationDTM, OrganizationMemberDTM } from 'user-management/models/dtm';
import { R } from 'user-management/repository';
import { EAccountDrawerContent } from 'user-management/constants';

@controller
export class UserProfileController extends BaseController {
  public uploadData = async () => {
    let organization: OrganizationDTM | null;
    let organizationMember: OrganizationMemberDTM | undefined;

    try {
      organization = await R.services.organization.getCurrentOrganization();
    } catch (e) {
      console.error(e);

      return;
    }
    if (!organization) {
      return;
    }

    const userEmail = AuthR.selectors.auth.getEmail(this.store.getState());

    try {
      organizationMember = await R.services.organization.getOrganizationMember(organization.id, userEmail);
    } catch (e) {
      console.error(e);

      return;
    }

    if (!organization) {
      return;
    }

    this.dispatch(R.actions.userOrganizationData.setUserOrganization(organization));
    this.dispatch(R.actions.userOrganizationData.setCurrentOrganizationUserData(organizationMember));
  };

  public clear = () => {
    this.dispatch(R.actions.userOrganizationData.clear());
  }

  public openChangePassword = () => {
    this.dispatch(R.actions.accountDrawer.openDrawer(EAccountDrawerContent.USER_PASSWORD_CHANGE));
  }

  public openEditProfile = () => {
    const user = R.selectors.userOrganizationData.getOrganizationUserMemberData(this.store.getState());

    if (user?.jobTitle) {
      this.mobxStore.userEditProfileStore.setJobTitle(user?.jobTitle);
    }

    this.dispatch(R.actions.accountDrawer.openDrawer(EAccountDrawerContent.USER_EDIT_PROFILE));
    this.dispatch(R.actions.accountDrawer.setDynamicDrawerTitle(''));
  }
}
