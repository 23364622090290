import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { UC } from 'shipment-operations/controllers';
import { R } from 'shipment-operations/repository';

import { ShipmentTransportationComponent } from './ShipmentTransportation.component';

const ShipmentTransportationContainer: FC = () => {
  const shipment = useSelector(R.selectors.shipment.getShipment);

  return (
    <ShipmentTransportationComponent
      bookingStatus={shipment?.bookingStatus}
      loadData={UC.transportationOverview.fetchTransportationOverviewData}
      reset={UC.transportationOverview.reset}
    />
  );
};

export {
  ShipmentTransportationContainer as ShipmentTransportation,
};
