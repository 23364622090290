import React, { FC, useContext } from 'react';
import { UC } from 'user-management/controllers';

import { observer } from 'mobx-react-lite';
import { MobxStoresContext } from 'app-wrapper/mobxStores';
import { UserEditProfileComponent } from './UserEditProfile.component';

const UserEditProfileContainer: FC = observer(() => {
  const { userEditProfileStore } = useContext(MobxStoresContext);
  const { isLoading } = userEditProfileStore.state;

  return (
    <UserEditProfileComponent
      isLoading={isLoading}
      onFormOpen={UC.userEditProfile.onFormOpen}
      onOk={UC.userEditProfile.changeUserProfileData}
      onCancel={UC.userEditProfile.closeUserEditProfileDrawer}
    />
  );
});

export { UserEditProfileContainer as UserEditProfile };
