import React, {
  FC, memo,
} from 'react';
import { useTranslation } from 'react-i18next';

import {
  freightLoadType,
} from 'monetary/constants';

import {
  Col, Option, Row, TabPane, Tabs,
} from 'app-wrapper/view/components';
import {
  ContainerIcon, RoadFlySvg, RoadShipSvg, RoadSvg,
} from 'app-wrapper/view/icons';
import themesColors from 'app-wrapper/view/themes/themesColors';

import {
  RFRTabs, RFRTabsFreightMode, RFRTabsSelect,
} from './RFRTabs.styled';

interface IRFRTabsComponentProps {
  loadType: string
  onChangeFreightLoadType: (value: string) => void
}

const RFRTabsComponent: FC<IRFRTabsComponentProps> = (({
  loadType,
  onChangeFreightLoadType,
}) => {
  const { t } = useTranslation();

  return (
    <RFRTabs className="RFRFormRequest__Row__col_RFRTabs">
      <Row className="RFRFormRequest__Row" justify="center">
        <Col span={24}>
          <Tabs
            className="RFRFormRequest__Row__Tabs"
            moreIcon={<></>}
          >
            <TabPane
              key="Sea"
              tab={(
                <span className="RFRFormRequest__Row__Tabs__item">
                  <RoadShipSvg />
                  {t('Sea')}
                </span>
              )}
            />
            <TabPane
              key="Road"
              tab={(
                <span className="RFRFormRequest__Row__Tabs__item">
                  <RoadSvg />
                  {t('Road')}
                </span>
              )}
              disabled
            />
            <TabPane
              key="Air"
              tab={(
                <span className="RFRFormRequest__Row__Tabs__item">
                  <RoadFlySvg />
                  {t('Air')}
                </span>
              )}
              disabled
            />
          </Tabs>
        </Col>
      </Row>

      <RFRTabsFreightMode>
        <RFRTabsSelect
          value={loadType || null}
          disabled
          onChange={onChangeFreightLoadType}
          optionFilterProp="children"
          prefixIcon={<ContainerIcon color={themesColors.secondaryDot45} />}
          cssWidth="100%"
        >
          <Option
            value={freightLoadType.FCL}
            key="RFRTabsSelect_FCL"
          >
            {t('FCL')}
          </Option>
          <Option
            value={freightLoadType.LCL}
            key="RFRTabsSelect_LCL"
          >
            {t('LCL')}
          </Option>
          <Option
            value={freightLoadType.Breakbulk}
            key="RFRTabsSelect_Breakbulk"
          >
            {t('Breakbulk')}
          </Option>
        </RFRTabsSelect>
      </RFRTabsFreightMode>
    </RFRTabs>
  );
});

const RFRTabsComponentCache = memo(RFRTabsComponent);

export { RFRTabsComponentCache as RFRTabsComponent };
