import styled from 'styled-components';

export const CargoFormName = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const CargoFormTopPanel = styled.div`
  display: flex;
  justify-content: space-between;

  > button {
    font-size: 12px;
  }
`;
