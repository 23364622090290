import React, { FC } from 'react';
import AntTabs, { TabPaneProps } from 'antd/es/tabs';

export const TabPane: FC<TabPaneProps> = (props) => {
  const { children } = props;

  return (
    <AntTabs.TabPane
      {...props}
    >
      { children }
    </AntTabs.TabPane>
  );
};
