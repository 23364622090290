import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'app-wrapper/createSlice';

import { ShipmentRouteDTM } from 'shipment-operations/models/dtm';
import { IShipmentTransportationLocationState } from 'shipment-operations/models/states';

const initialState: IShipmentTransportationLocationState = {
  isLoading: false,
  routes: [],
};

export const shipmentTransportationLocationStore = createSlice({
  name: 'ShipmentTransportationLocation',
  initialState,
  reducers: {
    setRoutes: (state, action: PayloadAction<ShipmentRouteDTM[]>) => {
      state.routes = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    clear: () => (initialState),
  },
});

export const shipmentTransportationLocationActions = shipmentTransportationLocationStore.actions;
export const shipmentTransportationLocationReducer = shipmentTransportationLocationStore.reducer;
