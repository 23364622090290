import styled from 'styled-components';
import AntdTabs from 'antd/es/tabs';
import Typography from 'antd/es/typography';

export const Navigation = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid #EAEEF8;
`;

export const NavigationSkeleton = styled.div`
  width: 100%;
  height: 64px;
`;

export const Tabs = styled(AntdTabs)`
  margin-right: auto;

  .ant-tabs-nav-operations {
    display: none !important;
  }
`;

export const TabPane = styled(Typography)`
  font-size: 14px;
  line-height: 22px;
`;
