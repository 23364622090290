import styled from 'styled-components';

import Skeleton from 'antd/es/skeleton';

interface IShipmentDetailsListContainerProps {
  height?: string
}

export const ShipmentDetailsListScroll = styled.div.attrs({
  'data-class': 'ShipmentDetailsListContainer',
})<IShipmentDetailsListContainerProps>`
  height: 100%;
  overflow-y: auto;
`;

export const ShipmentDetailsListItems = styled.ul.attrs({
  'data-class': 'ShipmentDetailsListItems',
})`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 6px;
  padding: 0;
`;

export const ShipmentDetailsListSkeleton = styled(Skeleton).attrs({
  'data-class': 'ShipmentDetailsListSkeleton',
})`
  width: 90%;
  margin: 0 auto 0 0;
`;
