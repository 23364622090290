import React, {
  FC, useCallback, useEffect, useState,
} from 'react';

import { StarSvg, VerticalFormItemSvg } from 'app-wrapper/view/icons';
import { dateFormatETAmin } from 'app-wrapper/utils';
import { useIsMenuCollapsedSlice } from 'app-wrapper/hooks';
import themesColors from 'app-wrapper/view/themes/themesColors';

import { IFreightQuotaContentSchedulesDTM } from 'monetary/models/dtm';

import {
  CarouselArrowNext,
  CarouselArrowPrev,
  CarouselArrowWrapper,
  CarouselDiv,
  CarouselOptionItem,
  CarouselOptionItemWrapper,
} from './RFQCarousel.styled';

interface IRFQCarouselComponentProps {
  contentItemWidth: number
  schedules?: IFreightQuotaContentSchedulesDTM[]
  setIndexSchedule: (indexSchedule: number) => void
}

const miniWidth = 640;

export const RFQCarouselComponent: FC<IRFQCarouselComponentProps> = ({
  contentItemWidth,
  schedules,
  setIndexSchedule,
}) => {
  const [listCount, setListCount] = useState(3);
  const [activeTab, setActiveTab] = useState(0);
  const [indexRecommended, setIndexRecommended] = useState(0);
  const [viewTabs, setViewTabs] = useState({
    active: 0,
    arr: [0, 1, 2],
  });
  const [items, setItems] = useState<string[]>([]);
  const [firstRun, setFirstRun] = useState(true);
  const isMenuCollapsedSlice = useIsMenuCollapsedSlice();

  const setItemsCurrent = useCallback(
    (indexTab: number) => {
      const tabs = items.reduce((prev: number[], item, index) => {
        if ((index >= indexTab && index < indexTab + listCount)
          && prev.length < listCount) {
          prev.push(index);
        }
        return prev;
      }, []);
      setViewTabs((prev) => ({ ...prev, active: indexTab, arr: tabs }));
    },
    [items, listCount],
  );

  useEffect(() => {
    const itemsLoc = schedules?.reduce((prev: string[], item, index) => {
      if (item.recommended) setIndexRecommended(index);
      prev.push(
        `ETD ${dateFormatETAmin(item?.departureTime || '')} - ETA ${dateFormatETAmin(item?.arrivalTime || '')}`,
      );
      return prev;
    }, []) || [];
    setItems(
      itemsLoc,
    );
  }, [schedules]);

  const onChangeListCount = useCallback(
    () => {
      const itemsInList = contentItemWidth < miniWidth ? 2 : 3;

      setListCount(itemsInList);
      setItemsCurrent(viewTabs.active);
    },
    [contentItemWidth, setItemsCurrent, viewTabs.active],
  );

  useEffect(() => {
    onChangeListCount();
  }, [contentItemWidth, setItemsCurrent, viewTabs.active, onChangeListCount]);

  useEffect(() => {
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 250);
  }, [isMenuCollapsedSlice]);

  const carouselOnChange = useCallback(
    (number) => {
      if (viewTabs.active > 0 && number === -1) {
        const indexTab = viewTabs.active - 1;
        setItemsCurrent(indexTab);
      }
      if (viewTabs.active + listCount < items.length && number === 1) {
        const indexTab = viewTabs.active + 1;
        setItemsCurrent(indexTab);
      }
    },
    [items.length, listCount, setItemsCurrent, viewTabs.active],
  );

  const carouselItemOnClick = useCallback(
    (index: number) => {
      setIndexSchedule(index);
      setActiveTab(index);
    },
    [setIndexSchedule],
  );

  useEffect(() => {
    if (items.length && firstRun) {
      setFirstRun(false);
      carouselItemOnClick(indexRecommended);

      if (indexRecommended === 0) {
        setItemsCurrent(indexRecommended);

        return;
      }

      setItemsCurrent(indexRecommended - 1);
    }
  }, [carouselItemOnClick, firstRun, indexRecommended, items, listCount, setItemsCurrent]);

  return (
    <CarouselDiv className="RFQItemDiv__CarouselDiv">
      <CarouselArrowWrapper>
        {viewTabs.active !== 0 && (
          <CarouselArrowPrev
            className="RFQItemDiv__CarouselDiv__btn_prev"
            onClick={() => carouselOnChange(-1)}
          >
            <VerticalFormItemSvg rotate={90} style={{ color: themesColors.neutralBranding9 }} />
          </CarouselArrowPrev>
        )}
      </CarouselArrowWrapper>
      <CarouselOptionItemWrapper className="RFQItemDiv__CarouselDiv__options">
        {items.map((item, index) => {
          const isActive = index === activeTab;
          const showToItems = contentItemWidth < miniWidth || items.length === 2;
          const showOneItem = items.length === 1;

          return (
            viewTabs.arr.includes(index) && (
              <CarouselOptionItem
                className="RFQItemDiv__CarouselDiv__options_item"
                isActive={isActive}
                isTwoItem={showToItems}
                isOneItem={showOneItem}
                key={`CarouselOptionItem_${item}_${index + 0}`}
                onClick={() => carouselItemOnClick(index)}
              >
                {index === indexRecommended && <StarSvg />}
                <span>{items[index]}</span>
              </CarouselOptionItem>
            )
          );
        })}
      </CarouselOptionItemWrapper>
      <CarouselArrowWrapper>
        {viewTabs.active < items.length - listCount && (
          <CarouselArrowNext
            className="RFQItemDiv__CarouselDiv__btn_next"
            onClick={() => carouselOnChange(1)}
          >
            <VerticalFormItemSvg rotate={-90} style={{ color: themesColors.neutralBranding9 }} />
          </CarouselArrowNext>
        )}
      </CarouselArrowWrapper>
    </CarouselDiv>
  );
};
