import i18n from 'i18next';

export const SHIPMENT = 'SHIPMENT';
export const CONTAINER = 'CONTAINER';

export const applyToOptions = [
  {
    value: SHIPMENT,
    label: i18n.t('addChargeMenu.shipment'),
  },
  {
    value: CONTAINER,
    label: i18n.t('addChargeMenu.container'),
  },
];

export const applyToModel = {
  SHIPMENT: 'BOL',
  CONTAINER: 'CONTAINER',
};

export const USD = 'USD';

export const ORIGIN = 'ORIGIN';
export const FREIGHT = 'FREIGHT';
export const DESTINATION = 'DESTINATION';

export const phasesOptions = [
  {
    value: ORIGIN,
    label: 'Origin',
  },
  {
    value: FREIGHT,
    label: 'Freight',
  },
  {
    value: DESTINATION,
    label: 'Destination',
  },
];

export const OriginFreightOptions = [
  {
    value: ORIGIN,
    label: 'Origin',
  },
  {
    value: FREIGHT,
    label: 'Freight',
  },
];

export const FreightDestinationOptions = [
  {
    value: FREIGHT,
    label: 'Freight',
  },
  {
    value: DESTINATION,
    label: 'Destination',
  },
];

export const OriginOption = [
  {
    value: ORIGIN,
    label: 'Origin',
  },
];

export const DestinationOption = [
  {
    value: DESTINATION,
    label: 'Destination',
  },
];

export const CUSTOMER_VIEW = 'CUSTOMER_VIEW';
export const OPERATOR_VIEW = 'OPERATOR_VIEW';

export const MEASURE_BY = 'measureBy';
export const APPLY_TO = 'applyTo';
