import React from 'react';
import { useSelector } from 'react-redux';

import { R } from 'user-management/repository';

import { TeamsAndDepartmentsComponent } from './TeamsAndDepartments.component';

const TeamsAndDepartmentsContainer = () => {
  const user = useSelector(R.selectors.userManagement.getUserProfile);

  return user ? (
    <TeamsAndDepartmentsComponent departments={user.departments} />
  ) : null;
};

const TeamsAndDepartmentsContainerCached = React.memo(TeamsAndDepartmentsContainer);

export { TeamsAndDepartmentsContainerCached as TeamsAndDepartments };
