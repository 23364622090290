import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'app-wrapper/createSlice';

import {
  CountryDTM,
  OrganizationDTM,
  BusinessRegistrationNumberDtm,
  OrganizationFieldsErrorsDTM,
  OrganizationMemberDTM,
  OrganizationPrimaryContactDTM,
  OrganizationMembershipDtm,
  TOrganizationFieldsErrorsFieldDTM,
  TOrganizationMemberDTM,
  OrganizationFieldsErrorsFieldDTM,
  OrganizationPaymentMethodDTM,
  TeamDTM, OrganizationCoverageAreasDTM,
} from 'user-management/models/dtm';
import { WebsiteUrl } from 'user-management/models/dtm/WebsiteUrl';
import { TUserOrganizationDataState } from 'user-management/models/states';
import { EBusinessRegistrationNumberType, EOrganizationType } from 'user-management/constants';

import { CountriesJson } from './countries';

const countries = Object.entries(CountriesJson).map(([code, name]) => (CountryDTM.fromPlain({
  code,
  name,
})));

export const initialState: TUserOrganizationDataState = {
  organizationsDataIsLoading: false,
  organization: undefined,
  organizations: undefined,
  organizationUpdate: OrganizationDTM.fromPlain({
    id: 0,
    status: undefined,
    note: '',
    address: {
      address1: '',
      city: '',
      closestPort: '',
      country: '',
      postalCode: '',
      state: undefined,
    },
    documents: [],
    businessRegistrationNumber: BusinessRegistrationNumberDtm.fromPlain({ type: EBusinessRegistrationNumberType.EIN, value: '' }),
    memberships: [],
    name: '',
    phone: '',
    industry: '',
    coverageAreas: [],
  }),
  customersAddInternal: OrganizationDTM.fromPlain({
    id: 0,
    status: undefined,
    note: '',
    address: {
      address1: '',
      city: '',
      closestPort: '',
      country: '',
      postalCode: '',
      state: undefined,
    },
    documents: [],
    businessRegistrationNumber: BusinessRegistrationNumberDtm.fromPlain({ type: EBusinessRegistrationNumberType.EIN, value: '' }),
    memberships: [OrganizationMembershipDtm.createEmpty()],
    name: '',
    phone: '',
  }),
  organizationFieldsErrors: undefined,
  organizationUserData: undefined,
  countries,
  internalID: 2,
  teams: [],
  accountManagers: [],
  salesManagers: [],
  tempTeam: undefined,
  tempAccountManager: undefined,
  tempAccountManagerError: false,
  tempTeamError: false,
  tempSalesManager: undefined,
  tempSalesManagerError: false,
};

export const UserOrganizationDataStore = createSlice({
  name: 'UserOrganizationData',
  initialState,
  reducers: {
    setOrganizationDataLoading: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      organizationsDataIsLoading: payload,
    }),
    setUserOrganization: (state, { payload }: PayloadAction<OrganizationDTM>) => ({
      ...state,
      organization: payload,
    }),
    resetUserOrganization: (state) => ({
      ...state,
      organization: initialState.organization,
    }),
    setOrganizationMember: (state, { payload }: PayloadAction<OrganizationMemberDTM>) => ({
      ...state,
      organizationMember: payload,
    }),
    setCustomerAddInternal: (state, { payload }: PayloadAction<OrganizationDTM>) => ({
      ...state,
      customersAddInternal: payload,
    }),
    setCustomerInternalOrganization: (state, { payload }: PayloadAction<OrganizationDTM>) => ({
      ...state,
      organizationInternal: payload,
    }),
    resetCustomerInternalOrganization: (state) => ({
      ...state,
      organizationInternal: initialState.organizationInternal,
    }),
    setCustomerInternalId: (state, { payload }: PayloadAction<number>) => ({
      ...state,
      internalID: payload,
    }),
    setTeams: (state, { payload }: PayloadAction<TeamDTM[]>) => ({
      ...state,
      teams: payload,
    }),
    setAccountManagers: (state, { payload }: PayloadAction<OrganizationMemberDTM[]>) => ({
      ...state,
      accountManagers: payload,
    }),
    setUserOrganizations: (state, { payload }: PayloadAction<OrganizationDTM[]>) => ({
      ...state,
      organizations: payload,
    }),
    setTempTeamError: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      tempTeamError: payload,
    }),
    setTempAccountManagerError: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      tempAccountManagerError: payload,
    }),
    setUserOrganizationsActive: (state, { payload }: PayloadAction<OrganizationDTM[]>) => ({
      ...state,
      organizationsActive: payload,
    }),
    setUserOrganizationsWaitingApproval: (state, { payload }: PayloadAction<OrganizationDTM[]>) => ({
      ...state,
      organizationsWaitingApproval: payload,
    }),
    setUserOrganizationsReject: (state, { payload }: PayloadAction<OrganizationDTM[]>) => ({
      ...state,
      organizationsReject: payload,
    }),
    addsUserOrganizations: (state, { payload }: PayloadAction<OrganizationDTM[]>) => ({
      ...state,
      organizations: [
        ...state.organizations || [],
        ...payload,
      ],
    }),
    addsUserOrganizationsActive: (state, { payload }: PayloadAction<OrganizationDTM[]>) => ({
      ...state,
      organizationsActive: [
        ...state.organizationsActive || [],
        ...payload,
      ],
    }),
    addsUserOrganizationsWaitingApproval: (state, { payload }: PayloadAction<OrganizationDTM[]>) => ({
      ...state,
      organizationsWaitingApproval: [
        ...state.organizationsWaitingApproval || [],
        ...payload,
      ],
    }),
    addsUserOrganizationsReject: (state, { payload }: PayloadAction<OrganizationDTM[]>) => ({
      ...state,
      organizationsReject: [
        ...state.organizationsReject || [],
        ...payload,
      ],
    }),
    addUserOrganizations: (state, { payload }: PayloadAction<OrganizationDTM>) => {
      const newValues = state.organizations?.filter((item) => item.id !== payload.id) || [];
      newValues.push(payload);

      return {
        ...state,
        organizations: newValues,
      };
    },
    setUserOrganizationsStatus: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      organizationsStatus: payload,
    }),
    setUserOrganizationsAddStatus: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      organizationsAddStatus: payload,
    }),
    setUserOrganizationsIsEnd: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      organizationsIsEnd: payload,
    }),
    setUserOrganizationsActiveIsEnd: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      organizationsActiveIsEnd: payload,
    }),
    setUserOrganizationsWaitingApprovalIsEnd: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      organizationsWaitingApprovalIsEnd: payload,
    }),
    setUserOrganizationsRejectIsEnd: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      organizationsRejectIsEnd: payload,
    }),
    setUserOrganizationsPage: (state, { payload }: PayloadAction<number>) => ({
      ...state,
      organizationsPage: payload,
    }),
    setUserOrganizationsPageIncrement: (state) => ({
      ...state,
      organizationsPage: (state?.organizationsPage || 0) + 1,
    }),
    setUserOrganizationsActivePageIncrement: (state) => ({
      ...state,
      organizationsActivePage: (state?.organizationsActivePage || 0) + 1,
    }),
    setUserOrganizationsActivePage: (state, { payload }: PayloadAction<number>) => ({
      ...state,
      organizationsActivePage: payload,
    }),
    setUserOrganizationsWaitingApprovalPageIncrement: (state) => ({
      ...state,
      organizationsWaitingApprovalPage: (state?.organizationsWaitingApprovalPage || 0) + 1,
    }),
    setUserOrganizationsRejectPageIncrement: (state) => ({
      ...state,
      organizationsRejectPage: (state?.organizationsRejectPage || 0) + 1,
    }),
    setUserErrorMessage: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      errorMessage: payload,
    }),
    setUserSuccessMessage: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      successMessage: payload,
    }),
    setUserOrganizationUpdate: (state, { payload }: PayloadAction<OrganizationDTM>) => ({
      ...state,
      organizationUpdate: payload,
    }),
    resetUserOrganizationUpdate: (state) => ({
      ...state,
      organizationUpdate: {
        ...initialState.organizationUpdate,
      },
    }),
    resetCustomerAddInternal: (state) => ({
      ...state,
      tempAccountManager: undefined,
      tempTeam: undefined,
      tempAccountManagerError: false,
      tempTeamError: false,
      customersAddInternal: {
        ...initialState.customersAddInternal,
      },
    }),
    setUserOrganizationUpdateName: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      organizationUpdate: {
        ...state.organizationUpdate,
        name: payload,
      },
    }),
    setUserOrganizationUpdateCoverageAreas: (state, { payload }: PayloadAction<OrganizationCoverageAreasDTM[]>) => ({
      ...state,
      organizationUpdate: {
        ...state.organizationUpdate,
        coverageAreas: payload,
      },
    }),
    clearUserOrganizationUpdateCoverageAreas: (state) => ({
      ...state,
      organizationUpdate: {
        ...state.organizationUpdate,
        coverageAreas: [],
      },
    }),
    setUserOrganizationErrorsCoverageAreas: (state, { payload }: PayloadAction<TOrganizationFieldsErrorsFieldDTM>) => ({
      ...state,
      organizationFieldsErrors: OrganizationFieldsErrorsDTM.fromPlain({
        ...state.organizationFieldsErrors,
        coverageAreas: {
          ...state.organizationFieldsErrors?.coverageAreas,
          ...payload,
        },
      }),
    }),
    setUserOrganizationUpdateType: (state, { payload }: PayloadAction<EOrganizationType>) => ({
      ...state,
      organizationUpdate: {
        ...state.organizationUpdate,
        type: payload,
      },
    }),
    setUserOrganizationUpdateIndustry: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      organizationUpdate: {
        ...state.organizationUpdate,
        industry: payload,
      },
    }),
    setSalesManagers: (state, { payload }: PayloadAction<OrganizationMemberDTM[]>) => ({
      ...state,
      salesManagers: payload,
    }),
    setTempSalesManager: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      tempSalesManager: payload,
    }),
    setTempSalesManagerError: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      tempSalesManagerError: payload,
    }),
    setUserOrganizationUpdateAddress1: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      organizationUpdate: {
        ...state.organizationUpdate,
        address: {
          ...state.organizationUpdate.address,
          address1: payload,
        },
      },
    }),
    setTempTeam: (state, { payload }: PayloadAction<number | undefined>) => ({
      ...state,
      tempTeam: payload,
    }),
    setTempAccountManager: (state, { payload }: PayloadAction<string | undefined>) => ({
      ...state,
      tempAccountManager: payload,
    }),
    setUserOrganizationUpdateAddress2: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      organizationUpdate: {
        ...state.organizationUpdate,
        address: {
          ...state.organizationUpdate.address,
          address2: payload,
        },
      },
    }),
    setUserOrganizationUpdateAddressCity: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      organizationUpdate: {
        ...state.organizationUpdate,
        address: {
          ...state.organizationUpdate.address,
          city: payload,
        },
      },
    }),
    setUserOrganizationUpdateAddressState: (state, { payload }: PayloadAction<string | undefined>) => ({
      ...state,
      organizationUpdate: {
        ...state.organizationUpdate,
        address: {
          ...state.organizationUpdate.address,
          state: payload,
        },
      },
    }),
    setUserOrganizationUpdateAddressPostalCode: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      organizationUpdate: {
        ...state.organizationUpdate,
        address: {
          ...state.organizationUpdate.address,
          postalCode: payload,
        },
      },
    }),
    setUserOrganizationUpdateAddressCountry: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      organizationUpdate: {
        ...state.organizationUpdate,
        address: {
          ...state.organizationUpdate.address,
          country: payload,
        },
      },
    }),
    setUserOrganizationUpdatePhone: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      organizationUpdate: {
        ...state.organizationUpdate,
        phone: payload,
      },
    }),
    setUserOrganizationUpdatePhone2: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      organizationUpdate: {
        ...state.organizationUpdate,
        phone2: payload,
      },
    }),
    setUserOrganizationUpdateEmail: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      organizationUpdate: {
        ...state.organizationUpdate,
        email: payload,
      },
    }),
    setUserOrganizationUpdateWebUrl: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      organizationUpdate: {
        ...state.organizationUpdate,
        webUrl: WebsiteUrl.fromPlain({ webUrl: payload }),
      },
    }),
    setUserOrganizationUpdateBusinessRegistrationNumber: (state, { payload }: PayloadAction<BusinessRegistrationNumberDtm>) => ({
      ...state,
      organizationUpdate: {
        ...state.organizationUpdate,
        businessRegistrationNumber: payload,
      },
    }),
    setUserOrganizationUpdateMemberships: (state, { payload }: PayloadAction<OrganizationMembershipDtm>) => {
      let done = false;
      const newMembershipsList = state.organizationUpdate.memberships ? state.organizationUpdate.memberships.map((item) => {
        if (item.id === payload.id) {
          done = true;

          return payload;
        }

        return item;
      }) : [];

      if (!done) {
        newMembershipsList.push(payload);
      }

      return ({
        ...state,
        organizationUpdate: {
          ...state.organizationUpdate,
          memberships: newMembershipsList,
        },
      });
    },
    removeUserOrganizationUpdateMemberships: (state, { payload }: PayloadAction<string>) => {
      const newMembershipsList = state.organizationUpdate.memberships ? state.organizationUpdate.memberships.filter((item) => {
        if (item.id === payload) {
          return undefined;
        }

        return item;
      }) : [];

      return ({
        ...state,
        organizationUpdate: {
          ...state.organizationUpdate,
          memberships: newMembershipsList,
        },
      });
    },
    setUserOrganizationUpdateTaxId: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      organizationUpdate: {
        ...state.organizationUpdate,
        taxId: payload,
      },
    }),
    setUserOrganizationUpdatePrimaryFirstName: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      organizationUpdate: {
        ...state.organizationUpdate,
        primaryContact: OrganizationPrimaryContactDTM.fromPlain({
          email: '',
          phone: '',
          lastName: '',
          ...state.organizationUpdate.primaryContact,
          firstName: payload,
        }),
      },
    }),
    setUserOrganizationUpdatePrimaryLastName: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      organizationUpdate: {
        ...state.organizationUpdate,
        primaryContact: OrganizationPrimaryContactDTM.fromPlain({
          email: '',
          phone: '',
          firstName: '',
          ...state.organizationUpdate.primaryContact,
          lastName: payload,
        }),
      },
    }),
    setUserOrganizationUpdatePrimaryEmail: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      organizationUpdate: {
        ...state.organizationUpdate,
        primaryContact: OrganizationPrimaryContactDTM.fromPlain({
          lastName: '',
          phone: '',
          firstName: '',
          ...state.organizationUpdate.primaryContact,
          email: payload,
        }),
      },
    }),
    setUserOrganizationUpdatePrimaryPhone: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      organizationUpdate: {
        ...state.organizationUpdate,
        primaryContact: OrganizationPrimaryContactDTM.fromPlain({
          lastName: '',
          email: '',
          firstName: '',
          ...state.organizationUpdate.primaryContact,
          phone: payload,
        }),
      },
    }),
    setUserOrganizationUpdatePrimaryPhone2: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      organizationUpdate: {
        ...state.organizationUpdate,
        primaryContact: OrganizationPrimaryContactDTM.fromPlain({
          lastName: '',
          phone: payload,
          email: '',
          firstName: '',
          ...state.organizationUpdate.primaryContact,
          // phone2: payload,
        }),
      },
    }),
    setUserOrganizationUpdateMembersContacts: (state, { payload }: PayloadAction<TOrganizationMemberDTM[]>) => ({
      ...state,
      organizationUpdate: {
        ...state.organizationUpdate,
        membersContacts: payload.map((item) => OrganizationMemberDTM.fromPlain(item)),
      },
    }),
    emptyUserOrganizationUpdateMembersContactsAll: (state) => ({
      ...state,
      organizationUpdate: {
        ...state.organizationUpdate,
        membersContacts: [],
        membersContactsValue: undefined,
        membersContactsValueOld: undefined,
      },
      organizationsActivePage: 0,
      organizationsWaitingApprovalPage: 0,
      organizationsRejectPage: 0,
    }),
    setUserOrganizationUpdateMembersContactsValue: (state, { payload }: PayloadAction<TOrganizationMemberDTM>) => ({
      ...state,
      organizationUpdate: {
        ...state.organizationUpdate,
        membersContactsValue: OrganizationMemberDTM.fromPlain(payload),
      },
    }),
    setUserOrganizationUpdateMembersContactsValueOld: (state, { payload }: PayloadAction<TOrganizationMemberDTM>) => ({
      ...state,
      organizationUpdate: {
        ...state.organizationUpdate,
        membersContactsValueOld: OrganizationMemberDTM.fromPlain(payload),
      },
    }),
    resetUserOrganizationErrors: (state) => ({
      ...state,
      organizationFieldsErrors: undefined,
    }),
    setUserOrganizationErrorsName: (state, { payload }: PayloadAction<TOrganizationFieldsErrorsFieldDTM>) => ({
      ...state,
      organizationFieldsErrors: OrganizationFieldsErrorsDTM.fromPlain({
        ...state.organizationFieldsErrors,
        name: {
          ...state.organizationFieldsErrors?.name,
          ...payload,
        },
      }),
    }),
    setUserOrganizationErrorsType: (state, { payload }: PayloadAction<TOrganizationFieldsErrorsFieldDTM>) => ({
      ...state,
      organizationFieldsErrors: OrganizationFieldsErrorsDTM.fromPlain({
        ...state.organizationFieldsErrors,
        type: {
          ...state.organizationFieldsErrors?.type,
          ...payload,
        },
      }),
    }),
    setUserOrganizationErrorsIndustry: (state, { payload }: PayloadAction<TOrganizationFieldsErrorsFieldDTM>) => ({
      ...state,
      organizationFieldsErrors: OrganizationFieldsErrorsDTM.fromPlain({
        ...state.organizationFieldsErrors,
        industry: {
          ...state.organizationFieldsErrors?.industry,
          ...payload,
        },
      }),
    }),
    setUserOrganizationErrorsAddress1: (state, { payload }: PayloadAction<TOrganizationFieldsErrorsFieldDTM>) => ({
      ...state,
      organizationFieldsErrors: OrganizationFieldsErrorsDTM.fromPlain({
        ...state.organizationFieldsErrors,
        address1: {
          ...state.organizationFieldsErrors?.address1,
          ...payload,
        },
      }),
    }),
    setUserOrganizationErrorsAddress2: (state, { payload }: PayloadAction<TOrganizationFieldsErrorsFieldDTM>) => ({
      ...state,
      organizationFieldsErrors: OrganizationFieldsErrorsDTM.fromPlain({
        ...state.organizationFieldsErrors,
        address2: {
          ...state.organizationFieldsErrors?.address2,
          ...payload,
        },
      }),
    }),
    setUserOrganizationErrorsAddressCity: (state, { payload }: PayloadAction<TOrganizationFieldsErrorsFieldDTM>) => ({
      ...state,
      organizationFieldsErrors: OrganizationFieldsErrorsDTM.fromPlain({
        ...state.organizationFieldsErrors,
        addressCity: {
          ...state.organizationFieldsErrors?.addressCity,
          ...payload,
        },
      }),
    }),
    setUserOrganizationErrorsAddressState: (state, { payload }: PayloadAction<TOrganizationFieldsErrorsFieldDTM>) => ({
      ...state,
      organizationFieldsErrors: OrganizationFieldsErrorsDTM.fromPlain({
        ...state.organizationFieldsErrors,
        addressState: {
          ...state.organizationFieldsErrors?.addressState,
          ...payload,
        },
      }),
    }),
    setUserOrganizationErrorsAddressPostalCode: (state, { payload }: PayloadAction<TOrganizationFieldsErrorsFieldDTM>) => ({
      ...state,
      organizationFieldsErrors: OrganizationFieldsErrorsDTM.fromPlain({
        ...state.organizationFieldsErrors,
        addressPostalCode: {
          ...state.organizationFieldsErrors?.addressPostalCode,
          ...payload,
        },
      }),
    }),
    setUserOrganizationErrorsAddressCountry: (state, { payload }: PayloadAction<TOrganizationFieldsErrorsFieldDTM>) => ({
      ...state,
      organizationFieldsErrors: OrganizationFieldsErrorsDTM.fromPlain({
        ...state.organizationFieldsErrors,
        addressCountry: {
          ...state.organizationFieldsErrors?.addressCountry,
          ...payload,
        },
      }),
    }),
    setUserOrganizationErrorsPhone: (state, { payload }: PayloadAction<TOrganizationFieldsErrorsFieldDTM>) => ({
      ...state,
      organizationFieldsErrors: OrganizationFieldsErrorsDTM.fromPlain({
        ...state.organizationFieldsErrors,
        phone: {
          ...state.organizationFieldsErrors?.phone,
          ...payload,
        },
      }),
    }),
    setUserOrganizationErrorsPhone2: (state, { payload }: PayloadAction<TOrganizationFieldsErrorsFieldDTM>) => ({
      ...state,
      organizationFieldsErrors: OrganizationFieldsErrorsDTM.fromPlain({
        ...state.organizationFieldsErrors,
        phone2: {
          ...state.organizationFieldsErrors?.phone2,
          ...payload,
        },
      }),
    }),
    setUserOrganizationErrorsEmail: (state, { payload }: PayloadAction<TOrganizationFieldsErrorsFieldDTM>) => ({
      ...state,
      organizationFieldsErrors: OrganizationFieldsErrorsDTM.fromPlain({
        ...state.organizationFieldsErrors,
        email: {
          ...state.organizationFieldsErrors?.email,
          ...payload,
        },
      }),
    }),
    setUserOrganizationErrorsWebUrl: (state, { payload }: PayloadAction<TOrganizationFieldsErrorsFieldDTM>) => ({
      ...state,
      organizationFieldsErrors: OrganizationFieldsErrorsDTM.fromPlain({
        ...state.organizationFieldsErrors,
        webUrl: {
          ...state.organizationFieldsErrors?.webUrl,
          ...payload,
        },
      }),
    }),
    setUserOrganizationErrorsBusinessRegistrationNumber: (state, { payload }: PayloadAction<TOrganizationFieldsErrorsFieldDTM>) => ({
      ...state,
      organizationFieldsErrors: OrganizationFieldsErrorsDTM.fromPlain({
        ...state.organizationFieldsErrors,
        businessRegistrationNumber: {
          ...state.organizationFieldsErrors?.businessRegistrationNumber,
          ...payload,
        },
      }),
    }),
    setUserOrganizationErrorsMembership: (state, { payload }: PayloadAction<TOrganizationFieldsErrorsFieldDTM>) => {
      const oldMembership = state.organizationFieldsErrors?.memberships?.find((item) => item.id === payload.id);
      const membershipsErrors = state.organizationFieldsErrors?.memberships?.filter((item) => item.id !== payload.id) || [];

      if (oldMembership) {
        membershipsErrors.push(OrganizationFieldsErrorsFieldDTM.fromPlain({
          ...oldMembership,
          ...payload,
        }));
      } else {
        membershipsErrors.push(OrganizationFieldsErrorsFieldDTM.fromPlain(payload));
      }

      return ({
        ...state,
        organizationFieldsErrors: OrganizationFieldsErrorsDTM.fromPlain({
          ...state.organizationFieldsErrors,
          memberships: membershipsErrors,
        }),
      });
    },
    setUserOrganizationErrorsAllMembershipsVisited: (state, { payload }: PayloadAction<boolean>) => {
      const newMemberships = state.organizationFieldsErrors?.memberships?.map((item) => OrganizationFieldsErrorsFieldDTM.fromPlain({
        ...item,
        isVisited: payload,
      }));

      return ({
        ...state,
        organizationFieldsErrors: OrganizationFieldsErrorsDTM.fromPlain({
          ...state.organizationFieldsErrors,
          memberships: newMemberships,
        }),
      });
    },
    setUserOrganizationErrorsTaxId: (state, { payload }: PayloadAction<TOrganizationFieldsErrorsFieldDTM>) => ({
      ...state,
      organizationFieldsErrors: OrganizationFieldsErrorsDTM.fromPlain({
        ...state.organizationFieldsErrors,
        taxId: {
          ...state.organizationFieldsErrors?.taxId,
          ...payload,
        },
      }),
    }),
    setUserOrganizationErrorsPrimaryName: (state, { payload }: PayloadAction<TOrganizationFieldsErrorsFieldDTM>) => ({
      ...state,
      organizationFieldsErrors: OrganizationFieldsErrorsDTM.fromPlain({
        ...state.organizationFieldsErrors,
        primaryName: {
          ...state.organizationFieldsErrors?.primaryName,
          ...payload,
        },
      }),
    }),
    setUserOrganizationErrorsPrimaryEmail: (state, { payload }: PayloadAction<TOrganizationFieldsErrorsFieldDTM>) => ({
      ...state,
      organizationFieldsErrors: OrganizationFieldsErrorsDTM.fromPlain({
        ...state.organizationFieldsErrors,
        primaryEmail: {
          ...state.organizationFieldsErrors?.primaryEmail,
          ...payload,
        },
      }),
    }),
    setUserOrganizationErrorsPrimaryPhone: (state, { payload }: PayloadAction<TOrganizationFieldsErrorsFieldDTM>) => ({
      ...state,
      organizationFieldsErrors: OrganizationFieldsErrorsDTM.fromPlain({
        ...state.organizationFieldsErrors,
        primaryPhone: {
          ...state.organizationFieldsErrors?.primaryPhone,
          ...payload,
        },
      }),
    }),
    setUserOrganizationErrorsPrimaryPhone2: (state, { payload }: PayloadAction<TOrganizationFieldsErrorsFieldDTM>) => ({
      ...state,
      organizationFieldsErrors: OrganizationFieldsErrorsDTM.fromPlain({
        ...state.organizationFieldsErrors,
        primaryPhone2: {
          ...state.organizationFieldsErrors?.primaryPhone2,
          ...payload,
        },
      }),
    }),
    setUserOrganizationErrorsMembersContactsValue: (state, { payload }: PayloadAction<TOrganizationFieldsErrorsFieldDTM>) => ({
      ...state,
      organizationFieldsErrors: OrganizationFieldsErrorsDTM.fromPlain({
        ...state.organizationFieldsErrors,
        MembersContactsValue: {
          ...state.organizationFieldsErrors?.MembersContactsValue,
          ...payload,
        },
      }),
    }),
    setCustomerAddInternalName: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      customersAddInternal: {
        ...state.customersAddInternal,
        name: payload,
      },
    }),
    setCustomerAddInternalType: (state, { payload }: PayloadAction<EOrganizationType>) => ({
      ...state,
      customersAddInternal: {
        ...state.customersAddInternal,
        type: payload,
      },
    }),
    setCustomerAddInternalIndustry: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      customersAddInternal: {
        ...state.customersAddInternal,
        industry: payload,
      },
    }),
    setUserCustomerAddInternalAddress1: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      customersAddInternal: {
        ...state.customersAddInternal,
        address: {
          ...state.customersAddInternal.address,
          address1: payload,
        },
      },
    }),
    setUserCustomerAddInternalAddress2: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      customersAddInternal: {
        ...state.customersAddInternal,
        address: {
          ...state.customersAddInternal.address,
          address2: payload,
        },
      },
    }),
    setCustomerAddInternalAddressCity: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      customersAddInternal: {
        ...state.customersAddInternal,
        address: {
          ...state.customersAddInternal.address,
          city: payload,
        },
      },
    }),
    setUserCustomerAddInternalAddressState: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      customersAddInternal: {
        ...state.customersAddInternal,
        address: {
          ...state.customersAddInternal.address,
          state: payload,
        },
      },
    }),
    setCustomerAddInternalAddressPostalCode: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      customersAddInternal: {
        ...state.customersAddInternal,
        address: {
          ...state.customersAddInternal.address,
          postalCode: payload,
        },
      },
    }),
    setCustomerAddInternalAddressCountry: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      customersAddInternal: {
        ...state.customersAddInternal,
        address: {
          ...state.customersAddInternal.address,
          country: payload,
        },
      },
    }),
    setCustomerAddInternalEmail: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      customersAddInternal: {
        ...state.customersAddInternal,
        email: payload,
      },
    }),
    setCustomerAddInternalBusinessRegistrationNumber: (state, { payload }: PayloadAction<BusinessRegistrationNumberDtm>) => ({
      ...state,
      customersAddInternal: {
        ...state.customersAddInternal,
        businessRegistrationNumber: payload,
      },
    }),
    setCustomerAddInternalMembership: (state, { payload }: PayloadAction<OrganizationMembershipDtm>) => {
      let done = false;
      const newMembershipsList = state.customersAddInternal.memberships ? state.customersAddInternal.memberships.map((item) => {
        if (item.id === payload.id) {
          done = true;

          return payload;
        }

        return item;
      }) : [];

      if (!done) {
        newMembershipsList.push(payload);
      }

      return ({
        ...state,
        customersAddInternal: {
          ...state.customersAddInternal,
          memberships: newMembershipsList,
        },
      });
    },
    removeCustomerAddInternalMembership: (state, { payload }: PayloadAction<string>) => {
      const newMembershipsList = state.customersAddInternal.memberships ? state.customersAddInternal.memberships.filter((item) => {
        if (item.id === payload) {
          return undefined;
        }

        return item;
      }) : [];

      return ({
        ...state,
        customersAddInternal: {
          ...state.customersAddInternal,
          memberships: newMembershipsList,
        },
      });
    },
    setCustomerAddInternalTaxId: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      customersAddInternal: {
        ...state.customersAddInternal,
        taxId: payload,
      },
    }),
    setCustomerAddInternalPhone: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      customersAddInternal: {
        ...state.customersAddInternal,
        phone: payload,
      },
    }),
    setCustomerAddInternalPhone2: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      customersAddInternal: {
        ...state.customersAddInternal,
        phone2: payload,
      },
    }),
    setCustomerAddInternalEmailInformation: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      customersAddInternal: {
        ...state.customersAddInternal,
        emailInformation: payload,
      },
    }),
    setCustomerAddInternalWebUrl: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      customersAddInternal: {
        ...state.customersAddInternal,
        webUrl: WebsiteUrl.fromPlain({ webUrl: payload }),
      },
    }),
    setCustomerAddInternalPrimaryFirstName: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      customersAddInternal: {
        ...state.customersAddInternal,
        primaryContact: OrganizationPrimaryContactDTM.fromPlain({
          email: '',
          phone: '',
          lastName: '',
          ...state.customersAddInternal.primaryContact,
          firstName: payload,
        }),
      },
    }),
    setCustomerAddInternalPrimaryLastName: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      customersAddInternal: {
        ...state.customersAddInternal,
        primaryContact: OrganizationPrimaryContactDTM.fromPlain({
          email: '',
          phone: '',
          firstName: '',
          ...state.customersAddInternal.primaryContact,
          lastName: payload,
        }),
      },
    }),
    setCustomerAddInternalPrimaryEmail: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      customersAddInternal: {
        ...state.customersAddInternal,
        primaryContact: OrganizationPrimaryContactDTM.fromPlain({
          lastName: '',
          phone: '',
          firstName: '',
          ...state.customersAddInternal.primaryContact,
          email: payload,
        }),
      },
    }),
    setCustomerAddInternalPrimaryPhone: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      customersAddInternal: {
        ...state.customersAddInternal,
        primaryContact: OrganizationPrimaryContactDTM.fromPlain({
          lastName: '',
          email: '',
          firstName: '',
          ...state.customersAddInternal.primaryContact,
          phone: payload,
        }),
      },
    }),
    setCustomerAddInternalPrimaryPhone2: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      customersAddInternal: {
        ...state.customersAddInternal,
        primaryContact: OrganizationPrimaryContactDTM.fromPlain({
          lastName: '',
          phone: payload,
          email: '',
          firstName: '',
          ...state.customersAddInternal.primaryContact,
          // phone2: payload,
        }),
      },
    }),
    setCustomerAddInternalPaymentMethod: (state, { payload }: PayloadAction<OrganizationPaymentMethodDTM>) => ({
      ...state,
      customersAddInternal: {
        ...state.customersAddInternal,
        paymentMethod: payload,
      },
    }),
    resetCustomerAddInternalErrors: (state) => ({
      ...state,
      customersAddInternalErrors: undefined,
    }),
    setCustomerAddInternalErrorsName: (state, { payload }: PayloadAction<TOrganizationFieldsErrorsFieldDTM>) => ({
      ...state,
      customersAddInternalErrors: OrganizationFieldsErrorsDTM.fromPlain({
        ...state.customersAddInternalErrors,
        name: {
          ...state.customersAddInternalErrors?.name,
          ...payload,
        },
      }),
    }),
    setCustomerAddInternalErrorsAddress1: (state, { payload }: PayloadAction<TOrganizationFieldsErrorsFieldDTM>) => ({
      ...state,
      customersAddInternalErrors: OrganizationFieldsErrorsDTM.fromPlain({
        ...state.customersAddInternalErrors,
        address1: {
          ...state.customersAddInternalErrors?.address1,
          ...payload,
        },
      }),
    }),
    setCustomerAddInternalErrorsAddress2: (state, { payload }: PayloadAction<TOrganizationFieldsErrorsFieldDTM>) => ({
      ...state,
      customersAddInternalErrors: OrganizationFieldsErrorsDTM.fromPlain({
        ...state.customersAddInternalErrors,
        address2: {
          ...state.customersAddInternalErrors?.address2,
          ...payload,
        },
      }),
    }),
    setCustomerAddInternalErrorsAddressCity: (state, { payload }: PayloadAction<TOrganizationFieldsErrorsFieldDTM>) => ({
      ...state,
      customersAddInternalErrors: OrganizationFieldsErrorsDTM.fromPlain({
        ...state.customersAddInternalErrors,
        addressCity: {
          ...state.customersAddInternalErrors?.addressCity,
          ...payload,
        },
      }),
    }),
    setCustomerAddInternalErrorsAddressState: (state, { payload }: PayloadAction<TOrganizationFieldsErrorsFieldDTM>) => ({
      ...state,
      customersAddInternalErrors: OrganizationFieldsErrorsDTM.fromPlain({
        ...state.customersAddInternalErrors,
        addressState: {
          ...state.customersAddInternalErrors?.addressState,
          ...payload,
        },
      }),
    }),
    setCustomerAddInternalErrorsAddressPostalCode: (state, { payload }: PayloadAction<TOrganizationFieldsErrorsFieldDTM>) => ({
      ...state,
      customersAddInternalErrors: OrganizationFieldsErrorsDTM.fromPlain({
        ...state.customersAddInternalErrors,
        addressPostalCode: {
          ...state.customersAddInternalErrors?.addressPostalCode,
          ...payload,
        },
      }),
    }),
    setCustomerAddInternalErrorsAddressCountry: (state, { payload }: PayloadAction<TOrganizationFieldsErrorsFieldDTM>) => ({
      ...state,
      customersAddInternalErrors: OrganizationFieldsErrorsDTM.fromPlain({
        ...state.customersAddInternalErrors,
        addressCountry: {
          ...state.customersAddInternalErrors?.addressCountry,
          ...payload,
        },
      }),
    }),
    setCustomerAddInternalErrorsEmail: (state, { payload }: PayloadAction<TOrganizationFieldsErrorsFieldDTM>) => ({
      ...state,
      customersAddInternalErrors: OrganizationFieldsErrorsDTM.fromPlain({
        ...state.customersAddInternalErrors,
        email: {
          ...state.customersAddInternalErrors?.email,
          ...payload,
        },
      }),
    }),
    setCustomerAddInternalErrorsTaxId: (state, { payload }: PayloadAction<TOrganizationFieldsErrorsFieldDTM>) => ({
      ...state,
      customersAddInternalErrors: OrganizationFieldsErrorsDTM.fromPlain({
        ...state.customersAddInternalErrors,
        taxId: {
          ...state.customersAddInternalErrors?.taxId,
          ...payload,
        },
      }),
    }),
    setCustomerAddInternalErrorsPhone: (state, { payload }: PayloadAction<TOrganizationFieldsErrorsFieldDTM>) => ({
      ...state,
      customersAddInternalErrors: OrganizationFieldsErrorsDTM.fromPlain({
        ...state.customersAddInternalErrors,
        phone: {
          ...state.customersAddInternalErrors?.phone,
          ...payload,
        },
      }),
    }),
    setCustomerAddInternalErrorsPhone2: (state, { payload }: PayloadAction<TOrganizationFieldsErrorsFieldDTM>) => ({
      ...state,
      customersAddInternalErrors: OrganizationFieldsErrorsDTM.fromPlain({
        ...state.customersAddInternalErrors,
        phone2: {
          ...state.customersAddInternalErrors?.phone2,
          ...payload,
        },
      }),
    }),
    setCustomerAddInternalErrorsEmailInformation: (state, { payload }: PayloadAction<TOrganizationFieldsErrorsFieldDTM>) => ({
      ...state,
      customersAddInternalErrors: OrganizationFieldsErrorsDTM.fromPlain({
        ...state.customersAddInternalErrors,
        emailInformation: {
          ...state.customersAddInternalErrors?.emailInformation,
          ...payload,
        },
      }),
    }),
    setCustomerAddInternalErrorsWebUrl: (state, { payload }: PayloadAction<TOrganizationFieldsErrorsFieldDTM>) => ({
      ...state,
      customersAddInternalErrors: OrganizationFieldsErrorsDTM.fromPlain({
        ...state.customersAddInternalErrors,
        webUrl: {
          ...state.customersAddInternalErrors?.webUrl,
          ...payload,
        },
      }),
    }),
    setCustomerAddInternalErrorsPrimaryName: (state, { payload }: PayloadAction<TOrganizationFieldsErrorsFieldDTM>) => ({
      ...state,
      customersAddInternalErrors: OrganizationFieldsErrorsDTM.fromPlain({
        ...state.customersAddInternalErrors,
        primaryName: {
          ...state.customersAddInternalErrors?.primaryName,
          ...payload,
        },
      }),
    }),
    setCustomerAddInternalErrorsPrimaryLastName: (state, { payload }: PayloadAction<TOrganizationFieldsErrorsFieldDTM>) => ({
      ...state,
      customersAddInternalErrors: OrganizationFieldsErrorsDTM.fromPlain({
        ...state.customersAddInternalErrors,
        primaryLastName: {
          ...state.customersAddInternalErrors?.primaryLastName,
          ...payload,
        },
      }),
    }),
    setCustomerAddInternalErrorsPrimaryEmail: (state, { payload }: PayloadAction<TOrganizationFieldsErrorsFieldDTM>) => ({
      ...state,
      customersAddInternalErrors: OrganizationFieldsErrorsDTM.fromPlain({
        ...state.customersAddInternalErrors,
        primaryEmail: {
          ...state.customersAddInternalErrors?.primaryEmail,
          ...payload,
        },
      }),
    }),
    setCustomerAddInternalErrorsPrimaryPhone: (state, { payload }: PayloadAction<TOrganizationFieldsErrorsFieldDTM>) => ({
      ...state,
      customersAddInternalErrors: OrganizationFieldsErrorsDTM.fromPlain({
        ...state.customersAddInternalErrors,
        primaryPhone: {
          ...state.customersAddInternalErrors?.primaryPhone,
          ...payload,
        },
      }),
    }),
    setCustomerAddInternalErrorsPrimaryPhone2: (state, { payload }: PayloadAction<TOrganizationFieldsErrorsFieldDTM>) => ({
      ...state,
      customersAddInternalErrors: OrganizationFieldsErrorsDTM.fromPlain({
        ...state.customersAddInternalErrors,
        primaryPhone2: {
          ...state.customersAddInternalErrors?.primaryPhone2,
          ...payload,
        },
      }),
    }),
    setCustomerAddInternalErrorsPaymentType: (state, { payload }: PayloadAction<TOrganizationFieldsErrorsFieldDTM>) => ({
      ...state,
      customersAddInternalErrors: OrganizationFieldsErrorsDTM.fromPlain({
        ...state.customersAddInternalErrors,
        paymentType: {
          ...state.customersAddInternalErrors?.paymentType,
          ...payload,
        },
      }),
    }),
    setCustomerAddInternalErrorsType: (state, { payload }: PayloadAction<TOrganizationFieldsErrorsFieldDTM>) => ({
      ...state,
      customersAddInternalErrors: OrganizationFieldsErrorsDTM.fromPlain({
        ...state.customersAddInternalErrors,
        type: {
          ...state.customersAddInternalErrors?.type,
          ...payload,
        },
      }),
    }),
    setCustomerAddInternalErrorsIndustry: (state, { payload }: PayloadAction<TOrganizationFieldsErrorsFieldDTM>) => ({
      ...state,
      customersAddInternalErrors: OrganizationFieldsErrorsDTM.fromPlain({
        ...state.customersAddInternalErrors,
        industry: {
          ...state.customersAddInternalErrors?.industry,
          ...payload,
        },
      }),
    }),
    setCustomerAddInternalErrorsPaymentCreditTerm: (state, { payload }: PayloadAction<TOrganizationFieldsErrorsFieldDTM>) => ({
      ...state,
      customersAddInternalErrors: OrganizationFieldsErrorsDTM.fromPlain({
        ...state.customersAddInternalErrors,
        paymentCreditTerm: {
          ...state.customersAddInternalErrors?.paymentCreditTerm,
          ...payload,
        },
      }),
    }),
    setCustomerAddInternalErrorsPaymentCreditLimit: (state, { payload }: PayloadAction<TOrganizationFieldsErrorsFieldDTM>) => ({
      ...state,
      customersAddInternalErrors: OrganizationFieldsErrorsDTM.fromPlain({
        ...state.customersAddInternalErrors,
        paymentCreditLimit: {
          ...state.customersAddInternalErrors?.paymentCreditLimit,
          ...payload,
        },
      }),
    }),
    setCustomerAddInternalErrorsMembersContactsValue: (state, { payload }: PayloadAction<TOrganizationFieldsErrorsFieldDTM>) => ({
      ...state,
      organizationFieldsErrors: OrganizationFieldsErrorsDTM.fromPlain({
        ...state.organizationFieldsErrors,
        MembersContactsValue: {
          ...state.organizationFieldsErrors?.MembersContactsValue,
          ...payload,
        },
      }),
    }),
    setCustomerAddInternalErrorsBusinessRegistrationNumber: (state, { payload }: PayloadAction<TOrganizationFieldsErrorsFieldDTM>) => ({
      ...state,
      customersAddInternalErrors: OrganizationFieldsErrorsDTM.fromPlain({
        ...state.customersAddInternalErrors,
        businessRegistrationNumber: {
          ...state.customersAddInternalErrors?.businessRegistrationNumber,
          ...payload,
        },
      }),
    }),
    setCustomerAddInternalErrorsMembership: (state, { payload }: PayloadAction<TOrganizationFieldsErrorsFieldDTM>) => {
      const oldMembership = state.customersAddInternalErrors?.memberships?.find((item) => item.id === payload.id);
      const membershipsErrors = state.customersAddInternalErrors?.memberships?.filter((item) => item.id !== payload.id) || [];

      if (oldMembership) {
        membershipsErrors.push(OrganizationFieldsErrorsFieldDTM.fromPlain({
          ...oldMembership,
          ...payload,
        }));
      } else {
        membershipsErrors.push(OrganizationFieldsErrorsFieldDTM.fromPlain(payload));
      }

      return ({
        ...state,
        customersAddInternalErrors: OrganizationFieldsErrorsDTM.fromPlain({
          ...state.customersAddInternalErrors,
          memberships: membershipsErrors,
        }),
      });
    },
    setCustomerAddInternalErrorsAllMembershipsVisited: (state, { payload }: PayloadAction<boolean>) => {
      const newMemberships = state.customersAddInternalErrors?.memberships?.map((item) => OrganizationFieldsErrorsFieldDTM.fromPlain({
        ...item,
        isVisited: payload,
      }));

      return ({
        ...state,
        customersAddInternalErrors: OrganizationFieldsErrorsDTM.fromPlain({
          ...state.customersAddInternalErrors,
          memberships: newMemberships,
        }),
      });
    },
    setCurrentOrganizationUserData: (state, { payload }: PayloadAction<OrganizationMemberDTM>) => ({
      ...state,
      organizationUserData: payload,
    }),
    clearInternalCustomer: (state) => ({
      ...initialState,
      organization: state.organization,
    }),
  },
});

export const userOrganizationDataReducer = UserOrganizationDataStore.reducer;
export const userOrganizationDataActions = UserOrganizationDataStore.actions;
