import styled from 'styled-components';

import { divFlex } from 'app-wrapper/view/themes/mixin';
import { Col, Row } from 'app-wrapper/view/components';

export const FQFRFooterTotalsItemTitle = styled.div.attrs((props: {
  isFormCollapsed: boolean,
}) => ({
  isFormCollapsed: props.isFormCollapsed,
  'data-class': 'FQFRFooterTotalsItemTitle',
}))`
  ${divFlex}
  flex-direction: ${({ isFormCollapsed }) => (isFormCollapsed ? 'normal' : 'column')};
  justify-content: ${({ isFormCollapsed }) => (isFormCollapsed ? 'center' : 'space-between')};
  padding-top: ${({ isFormCollapsed }) => (isFormCollapsed ? '0' : '5px')};
  align-items: center;
  margin: auto;
  height: ${({ isFormCollapsed }) => (isFormCollapsed ? '100%' : '43px')};
  width: 100%;

  font-size: 12px;
  font-weight: 400;
  color: ${({ theme }) => theme?.themesColors?.darkGrayMiddle};

  svg {
    ${({ isFormCollapsed }) => !isFormCollapsed && 'margin-right: unset;'}
  }
`;

export const FQFRFooterTotalsItemTitleBlockWrapper = styled.div.attrs((props: {
  isFormCollapsed: boolean,
}) => ({
  isFormCollapsed: props.isFormCollapsed,
  'data-class': 'FQFRFooterTotalsItemTitleBlockWrapper',
}))`
  ${divFlex}
  width: 100%;
  justify-content: ${({ isFormCollapsed }) => (isFormCollapsed ? 'left' : 'center')};
`;

export const FQFRFooterTotalsItemTitleBlock = styled.div.attrs((props: {
  isText: boolean,
  isSeparate: boolean,
  isSvg: boolean,
}) => ({
  isText: props.isText,
  isSeparate: props.isSeparate,
  isSvg: props.isSvg,
  'data-class': 'FQFRFooterTotalsItemTitleBlock',
}))`
  ${divFlex}
  justify-content: center;
  align-items: center;
  width: 100%;

  ${({ isSeparate }) => isSeparate && 'margin-right: 5px;'}
  ${({ isSeparate }) => isSeparate && 'width: 6px;'}
  ${({ isSvg }) => isSvg && 'max-width: 26px;'}

  ${({ isText }) => isText && 'line-height: 19px;'}
  ${({ isText }) => isText && 'width: auto;'}
  ${({ isText }) => isText && 'display: block;'}
  ${({ isText }) => isText && 'overflow: hidden;'}
  ${({ isText }) => isText && 'text-overflow: ellipsis;'}
  ${({ isText }) => isText && 'white-space: nowrap;'}

  font-size: 12px;
  font-weight: 400;
  color: ${({ theme }) => theme?.themesColors?.darkGrayMiddle};
`;

export const FQFRFormFooter = styled(Row)`
  overflow-x: hidden;
  height: 64px;
  padding-top: 10px;
  padding-bottom: 6px;
`;

export const FQFRFormFooterTotals = styled(Col)`
  overflow-x: hidden;
  overflow-y: auto;
`;

export const FQFRFormFooterSubmit = styled(Col).attrs((props: {
  $isDisabled: boolean
  'data-class'?: string
}) => ({
  $isDisabled: props.$isDisabled,
  'data-class': props?.['data-class'] || 'FQFRFormFooterSubmit',
}))`
  button {
    min-width: 112px;
    max-height: 40px;
  }

  svg {
    width: 24px !important;
    height: 24px !important;
    margin-top: 8px;
    ${({ $isDisabled }) => $isDisabled && 'opacity: 0.2'};
  }
`;
