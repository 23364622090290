import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ShareInvoiceShipmentDetailsDTM } from 'shipment-operations/models/dtm';

import {
  Wrap,
  Title,
  ItemsList,
  Item,
  ItemTitle,
  ItemSubTitle,
} from './InovicePDFShipmentDetails.styled';

interface InvoicePDFHeaderComponentProps {
  items: ShareInvoiceShipmentDetailsDTM[];
}

export const InovicePDFShipmentDetailsComponent: FC<InvoicePDFHeaderComponentProps> = ({ items }: InvoicePDFHeaderComponentProps) => {
  const { t } = useTranslation();

  return (
    <Wrap>
      <Title>
        {t('Details')}
      </Title>

      <ItemsList>
        {items.map((item) => (
          <Item key={`InovicePDFShipmentDetailsComponent_${item.title}`}>
            <ItemTitle>
              {item.title}
            </ItemTitle>

            {item.subtitles.map((subtitle) => (
              <ItemSubTitle key={`InovicePDFShipmentDetailsComponent_${subtitle}`}>
                {subtitle}
              </ItemSubTitle>
            ))}
          </Item>
        ))}
      </ItemsList>
    </Wrap>
  );
};
