import styled from 'styled-components';

import { EmptyScrollbar, divFlex } from 'app-wrapper/view/themes/mixin';

export const CCWrap = styled.div.attrs({
  'data-class': 'CCWrap',
})`
  ${divFlex}
  ${EmptyScrollbar}

  flex-direction: column;
  width: 100%;
  padding-left: 24px;

  background-color: ${({ theme }) => theme.themesColors.neutralBranding3};
`;

export const CCWrapHeader = styled.div.attrs({
  'data-class': 'CCWrapHeader',
})`
  ${divFlex}

  width: 100%;
  padding-left: 24px;
  height: 56px;

  background-color: ${({ theme }) => theme.themesColors.neutralBranding1};
`;

export const CCWrapShipmentHeader = styled.div.attrs({
  'data-class': 'CCWrapShipmentHeader',
})`
  ${divFlex}

  height: 56px;

  .ant-page-header {
    background: none;
  }
`;

export const CCContent = styled.div.attrs({
  'data-class': 'CCContent',
})`
  ${divFlex}

  flex-direction: column;
  width: 100%;
  height: 100%;
  max-width: 1660px;
  margin: 0 auto;

  color: ${({ theme }) => theme.themesColors.neutralBranding10};
`;

export const CCContentHeader = styled.div.attrs({
  'data-class': 'CCContentHeader',
})`
  ${divFlex}

  width: 100%;
`;

export const CCContentStatistics = styled.div.attrs({
  'data-class': 'CCContentStatistics',
})`
  ${divFlex}

  width: 100%;
  margin-top: 8px;
`;

export const CCContentFilters = styled.div.attrs({
  'data-class': 'CCContentFilters',
})`
  ${divFlex}

  width: 100%;
  margin-top: 13px;
`;

export const CCContentStatisticsBlock = styled.div.attrs((props: {
  isNext: boolean
  svgWidth: string
  svgHeight: string
  'data-class'?: string
}) => ({
  isNext: props.isNext,
  svgWidth: props.svgWidth,
  svgHeight: props.svgHeight,
  'data-class': props?.['data-class'] || 'CCContentStatisticsBlock',
}))`
  ${divFlex}

  width: 100%;
  margin-left: ${({ isNext }) => (isNext ? '8px' : '')};

  svg {
    width: ${({ svgWidth }) => (svgWidth || '')};
    height: ${({ svgHeight }) => (svgHeight || '')};
  }
`;

export const CCContentHeaderItemActiveTab = styled.div.attrs({
  'data-class': 'CCContentHeaderItemActiveTab',
})`
  ${divFlex}
  width: 7px;
  height: 7px;

  margin-left: 2px;
  background-color: ${({ theme }) => theme.themesColors.primaryBranding6};
  border-radius: 50%;
`;

export const CCContentHeaderItem = styled.div.attrs((props: {
  isActive?: boolean,
}) => ({
  isActive: props.isActive,
  'data-class': 'CCContentHeaderItem',
}))`
  ${divFlex}

  padding: 12px 0px 12px 0; // TODO test
  margin-right: 32px;
  cursor: pointer;

  color: ${({ theme, isActive }) => (isActive ? theme.themesColors.characterBrandingTitle85 : theme.themesColors.secondaryDot45)};

  ${({ theme, isActive }) => isActive && `
    ::after {
      content: '';
      position: absolute;
      bottom: 0;
      height: 2px;
      width: 100%;

      background-color: ${theme.themesColors.primaryBranding6};
    }
  `};
`;
