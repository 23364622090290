import React, { FC, useMemo } from 'react';

import { TeamDTM } from 'user-management/models/dtm';

import {
  TeamName,
  TeamCustomers,
  TeamMembers,
} from './components';
import { TeamsTable } from './TeamsTable.styled';

interface ITeamsTableComponentProps {
  teams: TeamDTM[];
}

const TeamsTableComponent: FC<ITeamsTableComponentProps> = ({ teams }) => {
  const columns = useMemo(() => ([
    {
      title: 'Team',
      dataIndex: 'team',
      key: 'team',
      width: '30%',
      render: (item: string, record: TeamDTM | {}) => {
        const team = record as TeamDTM;

        return (
          <TeamName id={team.id} name={team.name} />
        );
      },
    },
    {
      title: 'Customer(s)',
      dataIndex: 'customers',
      key: 'customers',
      width: '55%',
      render: (item: string, record: TeamDTM | {}) => {
        const team = record as TeamDTM;

        return (
          <TeamCustomers customers={team.customers} />
        );
      },
    },
    {
      title: 'Members',
      dataIndex: 'members',
      key: 'members',
      width: '15%',
      align: 'left' as 'left',
      render: (item: string, record: TeamDTM | {}) => {
        const team = record as TeamDTM;

        return <TeamMembers membersCount={team.membersCount} />;
      },
    },
  ]), []);

  return (
    <TeamsTable
      dataSource={teams}
      columns={columns}
      pagination={false}
    />
  );
};

export { TeamsTableComponent as TeamsTable };
