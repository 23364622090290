import React, {
  FC, memo, useCallback, useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';
import Upload from 'antd/es/upload';
import EditOutlined from '@ant-design/icons/EditOutlined';
import PlusOutlined from '@ant-design/icons/PlusOutlined';
import TagsOutlined from '@ant-design/icons/TagsOutlined';
import { Tooltip } from 'app-wrapper/view/components';

import { OrganizationStatus, RejectionTooltip } from 'user-management/view/components';
import { EOrganizationIndustry, OrganizationIndustryNames } from 'user-management/constants';
import {
  CustomerOrganizationProfileDtm,
  EOrganizationStatus,
  OrganizationDTM,
  PartnerOrganizationProfileDtm,
} from 'user-management/models/dtm';

import { hasReadAccess } from 'app-wrapper/utils';
import {
  CIButton,
  CIContent,
  CIContentCards,
  CIContentDivider,
  CIContentDividerDashed,
  CIContentHeader,
  CIContentLeft,
  CIContentRight,
  CIStatusRow,
  CIIcon,
  CIName,
  CINameBox,
  CITypeBox,
  CITypeBoxName,
  CITypeBoxSecond,
  CITypeBoxTitle,
  CIWrap,
  CIWrapper,
} from './CustomersInternal';
import {
  CIAddress, CIContactInformation, CIFinancialAndLegal, CIPrimaryContact,
} from './components';
import { AccountDrawer } from '../Account/components';
import { CIPrimaryContactIconBG } from './components/CIPrimaryContact/CIPrimaryContact.styled';
import {
  OrganizationCoverageAreasContainer,
} from './components/OrganizationCovarageAreas';

const { Dragger } = Upload;

interface ICustomersInternalComponentProps {
  onStart: () => void
  onEnd: () => void
  organization?: OrganizationDTM
  isAdmin?: boolean
  onEditProfileOrganization: () => void
  permissions?: CustomerOrganizationProfileDtm | PartnerOrganizationProfileDtm
}

const CustomersInternalComponent: FC<ICustomersInternalComponentProps> = (props) => {
  const { t } = useTranslation();
  const {
    onStart,
    onEnd,
    organization,
    isAdmin,
    onEditProfileOrganization,
    permissions,
  } = props;

  useEffect(() => {
    onStart();

    return onEnd;
  }, []);

  const onEditProfileClick = useCallback(() => {
    onEditProfileOrganization();
  }, []);

  return (
    <CIWrapper>
      <CIWrap>
        <CIContent>
          <CIContentHeader>
            <Tooltip
              title={t('Feature coming soon')}
              placement="top"
              isGetPopupContainerDefault
            >
              <CIIcon>
                <Dragger
                  name="file"
                  showUploadList={false}
                  multiple={false}
                  onChange={() => {}}
                  onDrop={() => {}}
                  disabled
                >
                  <PlusOutlined />
                  <p>{t('Upload Logo')}</p>
                </Dragger>
              </CIIcon>
            </Tooltip>
            <CINameBox>
              {organization?.status ? (
                <CIStatusRow>
                  <OrganizationStatus status={organization.status} />

                  {organization?.review?.note && organization.status === EOrganizationStatus.REJECTED ? (
                    <RejectionTooltip note={organization?.review?.note} />
                  ) : null}
                </CIStatusRow>
              ) : null}

              <CIName>{organization?.name || ''}</CIName>
            </CINameBox>
            <CITypeBox>

              <CIPrimaryContactIconBG>
                <TagsOutlined />
              </CIPrimaryContactIconBG>
              <CITypeBoxSecond>
                <CITypeBoxTitle>{t('Type')} / {t('Industry')}</CITypeBoxTitle>
                <CITypeBoxName>
                  {organization?.type ? organization?.type : ''}
                  <CITypeBoxTitle>&nbsp;/&nbsp;</CITypeBoxTitle>
                  {organization?.industry ? OrganizationIndustryNames[organization.industry as EOrganizationIndustry] : 'N/A'}
                </CITypeBoxName>
              </CITypeBoxSecond>
            </CITypeBox>
            <CIButton
              icon={<EditOutlined />}
              onClick={onEditProfileClick}
              disabled={!isAdmin}
            >
              {t('Edit Profile')}
            </CIButton>
          </CIContentHeader>

          <CIContentDivider />

          <CIContentCards>
            <CIContentLeft>
              <CIAddress />

              <CIContentDividerDashed />

              <CIFinancialAndLegal />

              {
                (hasReadAccess(permissions?.coverageAreas))
                  ? (
                    <>
                      <CIContentDividerDashed />
                      <OrganizationCoverageAreasContainer />
                    </>
                  )
                  : null
              }
            </CIContentLeft>

            <CIContentRight>
              <CIPrimaryContact />

              <CIContentDividerDashed />

              <CIContactInformation />
            </CIContentRight>
          </CIContentCards>
        </CIContent>
      </CIWrap>
      <AccountDrawer />
    </CIWrapper>
  );
};

const CustomersInternalComponentCache = memo(CustomersInternalComponent);

export { CustomersInternalComponentCache as CustomersInternalComponent };
