import styled from 'styled-components';

import themesColors from 'app-wrapper/view/themes/themesColors';
import {
  Input, InputPassword, Button, AlertBlock,
} from 'app-wrapper/view/components';

export const PasswordResetEmailSendWrapper = styled.div.attrs({
  'data-class': 'PasswordResetEmailSendWrapper',
})`
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-around;
  align-items: center;

  width: 100%;
  height: 100%;
`;

export const PasswordResetEmailSendForm = styled.div.attrs({
  'data-class': 'PasswordResetEmailSendForm',
})`
  margin-top: auto;
  width: 360px;
  height: fit-content;
`;

export const LogoWrapper = styled.div.attrs({
  'data-class': 'PasswordResetEmailSendButton',
})`
  margin: 0 auto 12px auto;
  width: fit-content;
`;

export const PasswordResetEmailSendTitle = styled.p.attrs({
  'data-class': 'PasswordResetEmailSendTitle',
})`
  margin: auto auto 24px auto;

  color: ${themesColors.secondaryDot45};
  font-size: 14px;
  line-height: 22px;
  text-align: center;
`;

export const PasswordResetEmailSendMessageHeader = styled.p.attrs({
  'data-class': 'PasswordResetEmailSendMessageHeader',
})`
  margin: auto auto 4px auto;

  color: ${themesColors.neutralBranding10};
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: left;
`;

export const PasswordResetEmailSendMessage = styled.p.attrs({
  'data-class': 'PasswordResetEmailSendMessage',
})`
  margin: auto auto 12px auto;

  color: ${themesColors.secondaryDot45};
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  text-align: left;
`;

export const ErrorWrapper = styled.span.attrs({
  'data-class': 'PasswordResetEmailSendButton',
})`
  text-align: center;
`;

export const PasswordResetEmailSendEmailInput = styled(Input).attrs({
  'data-class': 'PasswordResetEmailSendEmailInput',
})`
  margin-bottom: 12px;
  color: ${themesColors.secondaryDot45};
  
  .ant-input {
    color: ${themesColors.secondaryDot45};
  }

  .ant-input::placeholder {
    color: ${themesColors.secondaryDot45};
  }
`;

export const PasswordResetEmailSendPasswordInput = styled(InputPassword).attrs({
  'data-class': 'PasswordResetEmailSendPasswordInput',
})`
  margin-bottom: 4px;
  color: ${themesColors.secondaryDot45};
  
  .ant-input {
    color: ${themesColors.secondaryDot45};
  }

  .ant-input::placeholder {
    color: ${themesColors.secondaryDot45};
  }
`;

export const PasswordResetEmailSendButton = styled(Button).attrs({
  'data-class': 'PasswordResetEmailSendButton',
})`
  width: 100%;
  margin-bottom: 12px;
`;

export const SignUpLinkWrapper = styled.div.attrs({
  'data-class': 'SignUpLinkWrapper',
})`
  text-align: center;

  a {
    color: ${themesColors.primaryBranding7};
  }
  
  svg {
    margin-right: 8px;
    height: 12px;
  }
`;

export const PasswordResetEmailSendAlertBlock = styled(AlertBlock).attrs({
  'data-class': 'PasswordResetEmailSendAlertBlock',
})`
  margin: 24px 0 12px 0;

  font-size: 14px;
`;

export const BottomMessage = styled.p.attrs({
  'data-class': 'BottomMessage',
})`
  margin: auto auto 20px auto;
  
  text-align: center;
  font-size: 14px;
  color: ${themesColors.secondaryDot45};
`;
