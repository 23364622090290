import styled from 'styled-components';

export const FooterContainer = styled.div.attrs({
  'data-class': 'FooterContainer',
})`
  width: 100%;
  height: 100%;
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: flex-end;
`;
