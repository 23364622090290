import styled from 'styled-components';
import {
  Select as UnstyledSelect,
  Option as UnstyledOption,
} from 'app-wrapper/view/components';

export const Select = styled(UnstyledSelect).attrs((props: {
  'data-class'?: string
}) => ({
  'data-class': props?.['data-class'] || 'HsCodeInputComponent_Select',
}))`
  width: 100%;

  > .ant-select-selector {
    > .ant-select-selection-item {
      display: flex;
      flex-direction: row;
      gap: 4px;
    }
  }

  .ant-select-item-empty span {
    font-size: 12px;
    ${({ theme }) => theme?.fonts?.SmallTextFootnoteDescription};
    ${({ theme }) => `color: ${theme.themesColors.secondaryDot45};`};
  }
`;

export const Option = styled(UnstyledOption)`
`;

export const OptionHeader = styled.div.attrs((props: {
  isDisabled: boolean
  'data-class'?: string
}) => ({
  isDisabled: props.isDisabled,
  'data-class': props?.['data-class'] || 'OptionHeader',
}))`
  color: ${({ theme, isDisabled }) => (isDisabled ? theme?.themesColors?.secondaryDot45 : theme?.themesColors?.neutralBranding10)};
  font-weight: 500;
  font-size: 12px;
`;

export const OptionTitle = styled.div.attrs((props: {
  isDisabled: boolean
  'data-class'?: string
}) => ({
  isDisabled: props.isDisabled,
  'data-class': props?.['data-class'] || 'OptionTitle',
}))`
  color: ${({ theme, isDisabled }) => (isDisabled ? theme?.themesColors?.secondaryDot45 : theme?.themesColors?.neutralBranding10)};
  color: ${({ theme, isDisabled }) => (isDisabled ? theme?.themesColors?.neutralBranding6 : theme?.themesColors?.neutralBranding10)};
  font-weight: 500;
`;

export const OptionDescription = styled.div.attrs((props: {
  isDisabled: boolean
  'data-class'?: string
}) => ({
  isDisabled: props.isDisabled,
  'data-class': props?.['data-class'] || 'OptionDescription',
}))`
  color: ${({ theme, isDisabled }) => (isDisabled ? theme?.themesColors?.neutralBranding6 : theme?.themesColors?.neutralBranding10)};
  font-weight: 400;
  white-space: normal;
`;
