import styled from 'styled-components';
import Tabs from 'antd/es/tabs';
import themesColors from 'app-wrapper/view/themes/themesColors';

export const ShipmentDetailsFormsTabs = styled(Tabs).attrs({
  'data-class': 'ShipmentDetailsFormsTabs',
})`
  overflow: initial;
  height: 100%;
  
  .ant-tabs-content-holder {
    background: white;
  }
  
  .ant-tabs-nav {
    margin: 0 0 -1px 0;
  }

  .ant-tabs-tab {
    color: #72819b;
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: ${themesColors.primaryBranding9};
  }
`;
