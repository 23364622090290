import React, {
  FC, useState, useEffect, useMemo,
} from 'react';
import Panel from 'antd/es/collapse/CollapsePanel';
import Spin from 'antd/es/spin';
import { useTranslation } from 'react-i18next';
import isEmpty from 'lodash/isEmpty';

import { addKey } from 'monetary/repository/store/FAKException';
import { StyledExpandIcon } from 'monetary/view/components';
import { FAKExceptionsDTM } from 'monetary/models/dtm/Quotas';

import {
  Wrapper, StyledPanelHeader, InnerTableWrapper, InnerPanel, TablePointer, StyledCollapse,
  StyledPanelHeaderInner, StyledRow, StyledCode, SpinnerWrapper,
} from './FAKException.styled';

interface IFAKExceptionComponentProps {
  loadData: () => void
  data: {
    isLoading: boolean
    isEmptyData: boolean
    data: FAKExceptionsDTM[]
    isError: boolean
  }
}

const InnerTable = ({ item }: { item: FAKExceptionsDTM }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <StyledCollapse ghost expandIcon={StyledExpandIcon} key={item.key} onChange={() => item.children && setIsOpen(!isOpen)}>
      <Panel
        key={item.key || 0}
        header={(
          <StyledPanelHeader style={{ color: isOpen ? '#6C79DE' : '' }}>
            <span style={{ fontWeight: 500 }}>{item.title}</span>
            <StyledCode>{item.code}</StyledCode>
          </StyledPanelHeader>
        )}
        showArrow={!!item.children}
        style={{ marginLeft: (item.children) ? '0' : '20px' }}
      >
        {item.children?.map((prop) => {
          if (!prop) {
            return null;
          }
          return (
            <InnerTableWrapper key={prop.key}>
              <TablePointer />
              <StyledPanelHeaderInner>
                <span>{prop.title}</span>
                <StyledCode>{prop.code}</StyledCode>
              </StyledPanelHeaderInner>
              {prop.children && prop.children.length && prop.children.map((elem) => (
                <InnerPanel key={item.key}>
                  <TablePointer />
                  <StyledRow>
                    <span>{elem.title}</span>
                    <StyledCode>{elem.code}</StyledCode>
                  </StyledRow>
                </InnerPanel>
              ))}
            </InnerTableWrapper>
          );
        })}
      </Panel>
    </StyledCollapse>
  );
};

const FAKExceptionComponent: FC<IFAKExceptionComponentProps> = ({
  loadData, data,
}) => {
  const { t } = useTranslation();
  useEffect(() => {
    loadData();
  }, []);

  const preparedData = useMemo(() => {
    if (isEmpty(data) || isEmpty(data.data)) {
      return [];
    }
    const withoutHead = data.data.reduce((acc: FAKExceptionsDTM[], cur) => [...acc, ...cur.children || []], []);
    return addKey(withoutHead);
  }, [data]);

  if (isEmpty(data)) {
    return null;
  }

  if (data.isLoading) {
    return (
      <SpinnerWrapper>
        <Spin />
      </SpinnerWrapper>
    );
  }

  if (data.isEmptyData) {
    return (
      <SpinnerWrapper>
        <span>{t('noFAKExceptions')}</span>
      </SpinnerWrapper>
    );
  }

  return (
    <Wrapper>
      {preparedData.map((item) => <InnerTable item={item} key={item.key} />)}
    </Wrapper>
  );
};

export { FAKExceptionComponent };
