import styled from 'styled-components';

export const OrganisationRegistration = styled.div`
  width: 900px;
  height: 820px;
  padding: 68px 76px;
  background: rgba(255, 255, 255, 1);
  position: relative;
`;

export const OrganisationRegistrationBase = styled.div`
  width: 343px;
  position: absolute;
  top: 159px;
  left: 402px;
  text-align: center;
`;

export const OrganisationRegistrationTitle = styled.div`
  color: #202c3c;
  margin-bottom: 38px;
  font-size: 24px;
  line-height: 24px;
`;

export const OrganisationRegistrationCompanyInformation = styled.div`
  font-family: Roboto;
  font-size: 19px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.15px;
  margin-top: 44px;
  margin-bottom: 24px;
`;

export const OrganisationRegistrationRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10px;
`;

export const OrganisationRegistrationRowIndent = styled(OrganisationRegistrationRow)`
  margin-bottom: 20px;
`;

export const OrganisationRegistrationInput = styled.div`
  width: 49.5%;
`;

export const OrganisationRegistrationButton = styled.div`
  width: 157px;
  height: 56px;
`;

export const OrganisationRegistrationPostcode = styled(OrganisationRegistrationInput)`
  width: 158px;
`;

export const OrganisationRegistrationStreet = styled(OrganisationRegistrationInput)`
  width: 295px;
`;

export const OrganisationRegistrationHouse = styled(OrganisationRegistrationInput)`
  width: 88px;
`;

export const OrganisationRegistrationStep = styled(OrganisationRegistrationInput)`
  width: 88px;
`;

export const OrganisationRegistrationFloor = styled(OrganisationRegistrationInput)`
  width: 88px;
`;

export const OrganisationRegistrationHR = styled.hr`
  margin-top: 26px;
  margin-bottom: 44px;
  border: 1px solid #E0E0E0;
`;
