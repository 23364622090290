import styled from 'styled-components';
import Button from 'antd/es/button';

import { Container } from 'shipment-operations/view/components';

export const Main = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Wrapper = styled.div`
  width: 100%;
  background-color: #F4F5F8;
  max-width: 1600px;
`;

export const MainContainer = styled(Container)`
  margin-top: 0;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 18px 0 18px;
`;

export const Title = styled.h2`
  font-weight: 500;
  font-size: 20px;
  color: #202C3C;
  margin: 0;
`;

export const ToggleTitle = styled.span`
  font-weight: 400;
  font-size: 12px;
  color: #202C3C;
  margin-left: 10px;
`;

export const StyledButton = styled(Button)`
  background-color: #4E5D70;
  border: none;

  &:hover {
    background-color: #798aa2;
  }
`;

export const TotalWrapper = styled.div`
  padding: 0 16px;
  background: #F9F9FB;
  border-top: 1px solid #EAEEF8;
  border-bottom: 1px solid #EAEEF8;
`;
