import React, {
  FC, useEffect, useMemo, useRef, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import Skeleton from 'antd/es/skeleton';
import FilterFilled from '@ant-design/icons/FilterFilled';

import { elementInViewport } from 'app-wrapper/utils';
import { SkypaceSpin } from 'app-wrapper/view/icons';
import { EmptyStateForPages } from 'app-wrapper/view/components';
import themesColors from 'app-wrapper/view/themes/themesColors';

import { IRFRQuotesItemProps, RFRQuotesItem } from 'monetary/view/components';

import {
  AllRFRQuotesComponentDiv,
  AllRFRQuotesComponentQuotesDiv,
  AllRFRQuotesComponentQuotesDivWrapper,
  AllRFRQuotesComponentQuotesItemsDiv,
  AllRFRQuotesComponentTableHeader,
  AllRFRQuotesComponentTableHeaderItem,
  AllRFRQuotesEndComponent,
  ARFRQItemInfiniteScrollSpin,
} from './AllRFRQuotesComponent.styled';

export interface IAllRFRQuotesComponentProps {
  totalElements?: number
  dataElements: IRFRQuotesItemProps[]
  isLoad: boolean
  isFavorite?: boolean
  isFavoriteDisabled?: boolean
  isLoadingAllRFRNextPage?: boolean
  hasNextPage?: boolean
  nextScrollOnHandler: () => void
}

export const AllRFRQuotes: FC<IAllRFRQuotesComponentProps> = ({
  dataElements, isLoad, nextScrollOnHandler,
  isLoadingAllRFRNextPage,
  hasNextPage,
}) => {
  const { t } = useTranslation();

  const componentSpin = useMemo(() => (
    <ARFRQItemInfiniteScrollSpin>
      <SkypaceSpin />
    </ARFRQItemInfiniteScrollSpin>
  ), []);

  const infiniteVisible = useRef<HTMLDivElement>(null);
  const [firstRunInfiniteVisible, setFirstRunInfiniteVisible] = useState(false);

  useEffect(() => {
    if (elementInViewport(infiniteVisible?.current || null)
      && dataElements.length
      && !firstRunInfiniteVisible) {
      setFirstRunInfiniteVisible(true);
      nextScrollOnHandler();
    }
  }, [nextScrollOnHandler, infiniteVisible, dataElements.length, firstRunInfiniteVisible]);

  const [sentryRef] = useInfiniteScroll({
    loading: !!(isLoad || isLoadingAllRFRNextPage),
    hasNextPage: !!hasNextPage,
    onLoadMore: nextScrollOnHandler,
  });

  return (
    <AllRFRQuotesComponentDiv>
      <AllRFRQuotesComponentQuotesDivWrapper>
        <AllRFRQuotesComponentQuotesDiv>
          <AllRFRQuotesComponentQuotesItemsDiv>
            <AllRFRQuotesComponentTableHeader>
              <AllRFRQuotesComponentTableHeaderItem
                width="16.1%"
                paddingLeft="16px"
              >
                {t('Request')}
              </AllRFRQuotesComponentTableHeaderItem>
              <AllRFRQuotesComponentTableHeaderItem
                width="16.1%"
                paddingLeft="55px"
              >
                {t('Origin')}
              </AllRFRQuotesComponentTableHeaderItem>
              <AllRFRQuotesComponentTableHeaderItem
                width="16.1%"
                paddingLeft="63px"
              >
                {t('Destination')}
              </AllRFRQuotesComponentTableHeaderItem>
              <AllRFRQuotesComponentTableHeaderItem
                width="16.1%"
                paddingLeft="64px"
              >
                {t('Container&Commodity')}
              </AllRFRQuotesComponentTableHeaderItem>
              <AllRFRQuotesComponentTableHeaderItem
                width="16.1%"
                paddingLeft="62px"
              >
                {t('Customer')}
              </AllRFRQuotesComponentTableHeaderItem>
              <AllRFRQuotesComponentTableHeaderItem
                width="18.1%"
                isRight
              >
                {t('Status')}
              </AllRFRQuotesComponentTableHeaderItem>
            </AllRFRQuotesComponentTableHeader>

            {dataElements.map((itemQuote, index) => (
              <RFRQuotesItem
                container={itemQuote.container}
                name={itemQuote.name}
                linkTo={itemQuote.linkTo}
                createQuotaDate={itemQuote.createQuotaDate}
                id={itemQuote.id}
                status={itemQuote.status}
                type={itemQuote.type}
                origin={itemQuote.origin}
                destination={itemQuote.destination}
                containersType={itemQuote.containersType}
                profile={itemQuote.profile}
                customer={itemQuote.customer}
                hsCodes={itemQuote.hsCodes}
                key={`AllRFRQuotesComponent_allQuotesData_${itemQuote.id}_${index + 0}`}
                bookmark={itemQuote.bookmark}
                quotasCount={itemQuote.quotasCount}
                favoriteQuotasCount={itemQuote.favoriteQuotasCount}
              />
            ))}

            {dataElements.length || (isLoad || isLoadingAllRFRNextPage) ? (
              <AllRFRQuotesEndComponent ref={sentryRef}>
                {dataElements.length && isLoadingAllRFRNextPage ? componentSpin : null}
                {!dataElements.length && isLoad && <Skeleton />}
              </AllRFRQuotesEndComponent>
            ) : null}
          </AllRFRQuotesComponentQuotesItemsDiv>

          {!dataElements.length && !isLoad && !isLoadingAllRFRNextPage ? (
            <EmptyStateForPages
              icon={(
                <FilterFilled
                  width={28}
                  height={22}
                  style={{
                    color: themesColors.neutralBranding65,
                    width: '28px',
                    height: '22px',
                  }}
                />
              )}
              headerText={t('You’re all caught up.')}
              contentText={t('You have no requests for rates at the moment. You will be notified once you help will be needed.')}
            />
          ) : null}
        </AllRFRQuotesComponentQuotesDiv>

      </AllRFRQuotesComponentQuotesDivWrapper>
    </AllRFRQuotesComponentDiv>
  );
};
