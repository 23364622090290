import React from 'react';

import { IPropsIcon } from 'app-wrapper/interfaces/icon';

export const ContainerOutlinedSvg = (props: IPropsIcon) => {
  const {
    width, height, fill,
  } = props;

  return (
    <svg
      width={width || '16'}
      height={height || '16'}
      fill={fill || 'currentColor'}
      viewBox="0 0 35 26"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M35 2V1C35 0.5 34.5 0 34 0H1C0.4375 0 0 0.5 0 1V2C0 2.5625 0.4375 3 1 3V23C0.4375 23 0 23.5 0 24V25C0 25.5625 0.4375 26 1 26H34C34.5 26 35 25.5625 35 25V24C35 23.5 34.5 23 34 23V3C34.5 3 35 2.5625 35 2ZM31 23H4V3H31V23ZM7.5 21H9.5C9.75 21 10 20.8125 10 20.5V5.5C10 5.25 9.75 5 9.5 5H7.5C7.1875 5 7 5.25 7 5.5V20.5C7 20.8125 7.1875 21 7.5 21ZM19.5 21H21.5C21.75 21 22 20.8125 22 20.5V5.5C22 5.25 21.75 5 21.5 5H19.5C19.1875 5 19 5.25 19 5.5V20.5C19 20.8125 19.1875 21 19.5 21ZM25.5 21H27.5C27.75 21 28 20.8125 28 20.5V5.5C28 5.25 27.75 5 27.5 5H25.5C25.1875 5 25 5.25 25 5.5V20.5C25 20.8125 25.1875 21 25.5 21ZM13.5 21H15.5C15.75 21 16 20.8125 16 20.5V5.5C16 5.25 15.75 5 15.5 5H13.5C13.1875 5 13 5.25 13 5.5V20.5C13 20.8125 13.1875 21 13.5 21Z"
      />
    </svg>
  );
};
