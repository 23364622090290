import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  FileExcelOutlined,
  UserOutlined,
  CalendarOutlined,
} from '@ant-design/icons';

import { MarkupPolicyDTM } from 'user-management/models/dtm';
import {
  ValueBlockWithIcon,
  DownloadCurrentStep,
  MissingTextValue,
} from 'app-wrapper/view/guideline';

import { Content } from './MarkupPolicyResult.styled';

interface IMarkupPolicyResultComponentProps {
  downloadCurrentStep: () => void;
  markupPolicy?: MarkupPolicyDTM;
}

export const MarkupPolicyResultComponent: FC<IMarkupPolicyResultComponentProps> = ({
  downloadCurrentStep,
  markupPolicy,
}) => {
  const { t } = useTranslation();

  if (!markupPolicy) {
    return null;
  }

  const { createdBy, createdAt } = markupPolicy;

  return (
    <Content>
      <ValueBlockWithIcon
        icon={<FileExcelOutlined />}
        label={t('Markup Policy')}
        value={(
          <DownloadCurrentStep onClick={downloadCurrentStep} />
        )}
      />

      <ValueBlockWithIcon
        icon={<UserOutlined />}
        label={t('Uploaded By')}
        value={createdBy}
      />

      <ValueBlockWithIcon
        icon={<CalendarOutlined />}
        label={t('Last Date')}
        value={createdAt ? createdAt.getDateDMMMYYYYWithOffset() : (
          <MissingTextValue />
        )}
      />
    </Content>
  );
};
