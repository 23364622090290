import { DateDtm } from 'app-wrapper/models/dtm';
import { apiWorker } from 'app-wrapper/repository/utilsServices';
import moment from 'moment/moment';
import {
  IGetShipmentAdditionalServiceContract,
  TGetShipmentAdditionalServicesContract,
} from 'shipment-operations/models/contracts';
import {
  AdditionalServiceDTM,
  AdditionalServiceDocumentActivityDTM,
  AdditionalServiceInputActivityDTM,
  CustomsClearanceDTM,
  ContainerDocumentDTM,
} from 'shipment-operations/models/dtm';

export class AdditionalServicesService {
  public getAdditionalServices = async (shipmentId: string): Promise<AdditionalServiceDTM[]> => {
    let services: AdditionalServiceDTM[] = [];

    const response = await apiWorker.requestGet<TGetShipmentAdditionalServicesContract>(this.formatUrl(shipmentId));
    const { data } = response;

    services = data.map((rawService) => AdditionalServiceDTM.fromPlain({
      ...rawService,
      documentActivities: rawService.documentActivities.map((documentActivity) => AdditionalServiceDocumentActivityDTM.fromPlain({
        ...documentActivity,
        dueDate: documentActivity.dueDate ? DateDtm.fromPlain({
          date: documentActivity.dueDate,
          offset: moment.parseZone(documentActivity.dueDate).utcOffset(),
        }) : undefined,
        // @ts-ignore no status and url
        providedDocuments: documentActivity.providedDocuments.map((document) => ContainerDocumentDTM.fromPlain({
          uid: String(document.id),
          name: document.name,
          response: {
            id: document.id,
            name: document.name,
            type: document.type,
          },
        })),
      })),
      inputActivities: rawService.inputActivities.map((inputActivity) => AdditionalServiceInputActivityDTM.fromPlain({
        ...inputActivity,
        dueDate: inputActivity.dueDate ? DateDtm.fromPlain({
          date: inputActivity.dueDate,
          offset: moment.parseZone(inputActivity.dueDate).utcOffset(),
        }) : undefined,
        providedInputs: inputActivity.providedInputs.map((input) => CustomsClearanceDTM.fromPlain({
          type: input.entryNumber.type,
          number: String(input.entryNumber.number),
          selfCustomsClearance: input.selfCustomsClearance,
        })),
      })),
    }));

    return services;
  };

  public getAdditionalServiceById = async (shipmentId: string, serviceId: string) => {
    const response = await apiWorker.requestGet<IGetShipmentAdditionalServiceContract>(`${this.formatUrl(shipmentId)}/${serviceId}`);
    const { data } = response;

    return AdditionalServiceDTM.fromPlain({
      ...data,
      documentActivities: data.documentActivities.map((documentActivity) => AdditionalServiceDocumentActivityDTM.fromPlain({
        ...documentActivity,
        dueDate: documentActivity.dueDate ? DateDtm.fromPlain({
          date: documentActivity.dueDate,
          offset: moment.parseZone(documentActivity.dueDate).utcOffset(),
        }) : undefined,
        // @ts-ignore no status and url
        providedDocuments: documentActivity.providedDocuments.map((document) => ContainerDocumentDTM.fromPlain({
          uid: String(document.id),
          name: document.name,
          response: {
            id: document.id,
            name: document.name,
            type: document.type,
          },
        })),
      })),
      inputActivities: data.inputActivities.map((inputActivity) => AdditionalServiceInputActivityDTM.fromPlain({
        ...inputActivity,
        dueDate: inputActivity.dueDate ? DateDtm.fromPlain({
          date: inputActivity.dueDate,
          offset: moment.parseZone(inputActivity.dueDate).utcOffset(),
        }) : undefined,
        providedInputs: inputActivity.providedInputs.map((input) => CustomsClearanceDTM.fromPlain({
          isCreated: true,
          id: input.id,
          type: input.entryNumber.type,
          number: String(input.entryNumber.number),
          selfCustomsClearance: input.selfCustomsClearance,
        })),
      })),
    });
  }

  private formatUrl = (shipmentId?: string) => (
    `/shipment-service/api/v1/shipments/${shipmentId}/additional-services`
  );
}
