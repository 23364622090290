import {
  IsNumber, IsString, IsDefined,
} from 'class-validator';
import { BaseDTM } from 'proto/BaseDTM';

export interface IParsedInvoiceDTM {
  id: number
  number: string
  type: string
  status: string
  createdAt: string
  dueDate: string
  total: number
  balance: number
}

export class ParsedInvoiceDTM extends BaseDTM<IParsedInvoiceDTM> {
  @IsNumber()
  @IsDefined()
  id: number;

  @IsString()
  @IsDefined()
  number: string;

  @IsString()
  @IsDefined()
  type: string;

  @IsString()
  @IsDefined()
  status: string;

  @IsString()
  @IsDefined()
  createdAt: string;

  @IsString()
  @IsDefined()
  dueDate: string;

  @IsNumber()
  @IsDefined()
  total: number;

  @IsNumber()
  @IsDefined()
  balance: number;
}

export interface IDataTypeBillingDTM {
  key: number
  id: number
  number: string
  type: string
  created: string
  dueDate: string
  status: string
  total: number
  balance: number
}

export class DataTypeBillingDTM extends BaseDTM<IDataTypeBillingDTM> {
  @IsDefined()
  @IsNumber()
  key: number;

  @IsDefined()
  @IsNumber()
  id: number;

  @IsString()
  @IsDefined()
  number: string;

  @IsString()
  @IsDefined()
  type: string;

  @IsString()
  @IsDefined()
  created: string;

  @IsString()
  @IsDefined()
  dueDate: string;

  @IsString()
  @IsDefined()
  status: string;

  @IsNumber()
  @IsDefined()
  total: number;

  @IsNumber()
  @IsDefined()
  balance: number;
}
