import React from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';
import { UC } from 'shipment-operations/controllers';

import { BillOfLadingEditClausesComponent } from './BillOfLadingEditClauses.component';

const BillOfLadingEditClausesContainer = () => {
  const formState = useSelector(R.selectors.billOfLadingCommon.getClausesFormState);
  const formErrors = useSelector(R.selectors.billOfLadingCommon.getClausesFormErrors);
  const isPending = useSelector(R.selectors.billOfLadingCommon.getIsStatePending);

  return (
    <BillOfLadingEditClausesComponent
      formState={formState}
      formErrors={formErrors}
      setFormState={UC.billOfLading.setClausesFromState}
      isPending={isPending}
    />
  );
};

export { BillOfLadingEditClausesContainer as BillOfLadingEditClauses };
