import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import {
  OrganizationCoverageAreasContainer,
} from 'user-management/view/pages/OrganizationProfile/components/OrganizationCovarageAreas';
import {
  OrganizationAddress,
  OrganizationContactInformation,
  OrganizationData,
  OrganizationFinancialAndLegal,
  OrganizationProfileScaffold,
  OrganizationWarning,
} from './components';
import { OrganizationPrimaryContact } from './components/OrganizationPrimaryContact';

interface IOrganizationProfileComponentProps {
  onUpload: () => void
}

export const OrganizationProfileComponent: FC<IOrganizationProfileComponentProps> = ({ onUpload }) => {
  const { t } = useTranslation();

  useEffect(() => {
    onUpload();
  }, []);

  return (
    <OrganizationProfileScaffold
      title={t('Organization Profile')}
      header={<OrganizationData />}
      leftTopContentBox={<OrganizationAddress />}
      leftBottomContentBox={<OrganizationFinancialAndLegal />}
      rightTopContentBox={<OrganizationPrimaryContact />}
      rightContentBox={<OrganizationContactInformation />}
      coverageArea={<OrganizationCoverageAreasContainer />}
      warningBlock={<OrganizationWarning />}
    />
  );
};
