import styled, { css } from 'styled-components';

import { divFlex } from 'app-wrapper/view/themes/mixin';

const scaffoldSection = css`
  background-color: ${({ theme }) => theme?.themesColors?.neutralBranding1};
  border-radius: 3px;
  padding: 16px;
`;

export const MBLScaffoldWrapper = styled('div').attrs({
  'data-class': 'ScaffoldLayout',
})`
  ${divFlex}
  flex-direction: column;
  gap: 8px;
  width: 100%;
  overflow-y: auto;
`;

export const ScaffoldRow = styled('div')`
  ${divFlex}
  width: 100%;
  gap: 8px;
  flex-direction: row;
  justify-content: space-between;
`;

export const CompanyBody = styled.div`
  ${scaffoldSection}
  min-height: 107px;
`;

export const CarrierBody = styled.div`
  ${scaffoldSection}
  width: 50%;
  min-height: 106px;
`;

export const TransportBody = styled.div`
  ${scaffoldSection}
  width: 50%;
  min-height: 106px;
`;

export const ShippingPartiesBody = styled.div`
  ${scaffoldSection}
  width: 100%;
`;

export const BOLInstructionsBody = styled.div`
  ${scaffoldSection}
  width: 100%;
  min-height: 110px;
`;

export const RoutingBody = styled.div`
  ${scaffoldSection}
  width: 100%;
  min-height: 110px;
`;

export const RoutingInstructionsBody = styled.div`
  ${scaffoldSection}
  width: 100%;
  height: fit-content;
`;

export const ReferencesBody = styled.div`
  ${scaffoldSection}
  width: 100%;
  height: fit-content;
`;

export const GoodsTableBody = styled('div')`
  ${divFlex}
  width: 100%;
`;

export const HazmatTableBody = styled('div')`
  ${divFlex}
  width: 100%;
`;

export const ContainersTableBody = styled('div')`
  width: 100%;
`;

export const ChargesBody = styled.div`
  ${divFlex}
  width: 100%;
  flex-direction: column;
`;

export const BLReleaseBody = styled.div`
  ${scaffoldSection}
  width: 66.6%;
  min-height: 106px;
`;

export const ShippedOnBoardBody = styled.div`
  ${scaffoldSection}
  width: 33.3%;
  min-height: 106px;
`;

export const ClausesBody = styled.div`
  ${scaffoldSection}
  width: 33.3%;
`;

export const AdditionalNotesBody = styled.div`
  ${scaffoldSection}
  width: 66%;
  height: 100%;
`;

export const TermsAndConditionsBody = styled.div`
  ${scaffoldSection}
  width: 100%;
`;
