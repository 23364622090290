import React from 'react';

import { R } from 'shipment-operations/repository';
import { useAppSelector } from 'app-wrapper/hooks';

import { TransportationComponent } from './Transportation.component';

const TransportationContainer = () => {
  const containers = useAppSelector(R.selectors.shipmentTransportationCharges.getTransportationContainersTable);
  const data = useAppSelector(R.selectors.shipmentTransportationCharges.groupedTransportationContainers);
  const totalData = useAppSelector(R.selectors.shipmentTransportationCharges.getTransportationDataTotal);

  return (
    <TransportationComponent
      containers={containers}
      innerData={data}
      totalData={totalData}
    />
  );
};

export { TransportationContainer as Transportation };
