import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { REQUEST_STATUS } from 'app-wrapper/constants';

import { UC } from 'user-management/controllers';
import { R } from 'user-management/repository';

import { ContactBookComponent } from './ContactBook.component';

const ContactBookContainer: FC = () => {
  const contactBookDefault = useSelector(R.selectors.contactBook.getContactBookDefault);
  const contactBook = useSelector(R.selectors.contactBook.getContactBookUpdate);

  const companyList = useSelector(R.selectors.contactBook.getContactBookUpdateCompanyListVisible);

  const { companyListAddresses } = contactBook;

  return (
    <ContactBookComponent
      companyListState={companyList}
      companyListAddresses={companyListAddresses}
      isLoading={contactBookDefault.getRequestStatus === REQUEST_STATUS.pending}
      isLoadingNextPage={contactBookDefault.getRequestStatusNextPage === REQUEST_STATUS.pending}
      hasNextPage={!contactBookDefault.isEndPage}
      ContactBookNextPage={UC.contactBook.nextPageCompanyList}
      runComponent={UC.contactBook.onRunComponent}
      clickListItem={UC.contactBook.onClickContactBookItem}
      onOpenContactBookAddContact={UC.contactBook.onOpenContactBookAddContact}
    />
  );
};

export { ContactBookContainer as ContactBookPage };
