import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';

import { ShippingNameInputComponent } from './ShippingNameInput.component';

interface ShippingNameInputContainerProps {
  cargoId: number;
}

const ShippingNameInputContainer: FC<ShippingNameInputContainerProps> = ({ cargoId }) => {
  const { shippingName = '', errors } = useSelector(R.selectors.manualBookingWizard.getCargoById(cargoId));
  const { shippingName: error } = errors;

  return (
    <ShippingNameInputComponent
      shippingName={shippingName}
      error={!!error}
    />
  );
};

export { ShippingNameInputContainer as ShippingNameInput };
