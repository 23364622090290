import styled from 'styled-components';

import { divFlex } from 'app-wrapper/view/themes/mixin';

export const RCQLoadQuotaBlockWrap = styled.div.attrs({
  'data-class': 'RCQLoadQuotaBlockWrap',
})`
  ${divFlex}
  width: 580px;
  height: 258px;
`;

export const RCQLoadQuotaBlockContentWrap = styled.div.attrs({
  'data-class': 'RCQLoadQuotaBlockContentWrap',
})`
  ${divFlex}
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;
`;

export const RCQLoadQuotaBlockContentWrapImage = styled.div.attrs((props: {
  image: string
}) => ({
  image: props.image,
  'data-class': 'RCQLoadQuotaBlockContentWrapImage',
}))`
  ${divFlex}
`;

export const RCQLoadQuotaBlockHeader = styled.div.attrs({
  'data-class': 'RCQLoadQuotaBlockHeader',
})`
  ${divFlex}
  align-items: center;

  font-weight: 500;
  font-size: 20px;
  margin-top: 8px;
`;

export const RCQLoadQuotaBlockHeaderTitle = styled.div.attrs({
  'data-class': 'RCQLoadQuotaBlockHeaderTitle',
})`
  ${divFlex}
  align-items: center;

  font-weight: 400;
  font-size: 14px;
  color: ${({ theme }) => theme.themesColors.secondaryDot45};
`;
