import React, { FC, useContext } from 'react';
import { observer } from 'mobx-react-lite';

import { MobxStoresContext } from 'app-wrapper/mobxStores';

import { UC } from 'monetary/controllers';

import { RFQAddAdditionalServicesComponent } from './RFQAddAdditionalServices.component';

const RFQAddAdditionalServicesContainer: FC = observer(() => {
  const { additionalServicesDrawerStore } = useContext(MobxStoresContext);
  const addAdditionalServicesOriginDrawer = additionalServicesDrawerStore?.getAddAdditionalServicesOriginDrawer;
  const addAdditionalServicesFreightDrawer = additionalServicesDrawerStore?.getAddAdditionalServicesFreightDrawer;
  const addAdditionalServicesDestinationDrawer = additionalServicesDrawerStore?.getAddAdditionalServicesDestinationDrawer;

  const addAdditionalServicesDrawerTotalCost = additionalServicesDrawerStore?.getAddAdditionalServicesDrawerTotalCost;
  const addAdditionalServicesDrawerChecked = additionalServicesDrawerStore?.getAddAdditionalServicesDrawerChecked;

  return (
    <RFQAddAdditionalServicesComponent
      servicesOrigin={addAdditionalServicesOriginDrawer}
      servicesFreight={addAdditionalServicesFreightDrawer}
      servicesDestination={addAdditionalServicesDestinationDrawer}
      totalCost={addAdditionalServicesDrawerTotalCost}
      totalCheckedServices={addAdditionalServicesDrawerChecked?.length}
      onCloseDrawer={UC.AdditionalServices.closeAdditionalServiceDrawer}
      addServicesDrawer={UC.AdditionalServices.addAdditionalServiceDrawer}
    />
  );
});

export { RFQAddAdditionalServicesContainer as RFQAddAdditionalServicesPage };
