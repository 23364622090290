import React from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';

import { PDFShipmentDetailsComponent } from './PDFShipmentDetails.component';

const PDFShipmentDetailsContainer = () => {
  const shipmentDetails = useSelector(R.selectors.bookingConfirmationPDF.getBookingDetailsItems);

  return (
    <PDFShipmentDetailsComponent
      items={shipmentDetails}
    />
  );
};

export { PDFShipmentDetailsContainer as PDFShipmentDetails };
