import styled, { css } from 'styled-components';

export const textColorFirst = css`
  color: ${({ theme }) => theme.colors.characterBrandingTitle85};
`;

export const divFlex = css`
  display: flex;
  position: relative;
  ${textColorFirst}
`;

// Content

export const HistoryRequestedQuotesPageDiv = styled.div`
  ${divFlex}
  padding-left: 24px;
  width: 100%;
  height: 100%;
  flex-direction: column;
`;
