import { createAsyncThunk } from '@reduxjs/toolkit';

import { post, handleRequest } from 'app-wrapper/utils/';

import { IRequestServerError } from 'monetary/models/dtm/Quotas';

export interface IAsyncThunkPostParams {
  urlGetParams?: Array<string>
}

export interface IRejectWithValue {
  rejectValue: IRequestServerError
}

export const asyncThunkPost = <ParamsType, ResultType = any, RejectType = IRejectWithValue>(
  typePrefix: string,
  url: string,
) => createAsyncThunk<ResultType, ParamsType, IRejectWithValue | RejectType>(
  typePrefix,
  async (
    params: ParamsType & IAsyncThunkPostParams, { rejectWithValue },
  ): Promise<ResultType> => {
    const response = await post(
      `${url}${params.urlGetParams ? `?${params.urlGetParams.join('&')}` : ''}`,
      params,
      { 'Content-Type': 'application/json' },
    );
    const result = await handleRequest<ResultType, typeof rejectWithValue>(response, rejectWithValue);
    return result;
  },
);
