import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { R } from 'shipment-operations/repository';
import { UC } from 'shipment-operations/controllers';
import { DocumentsDistributionDTM } from 'shipment-operations/models/dtm';
import { BillOfLadingTypeNames, PermissionAttributePolicy } from 'shipment-operations/constants';

import { SBLBillOfLadingInformationComponent } from './SBLBillOfLadingInformation.component';

const SBLBillOfLadingInformationContainer = () => {
  const { t } = useTranslation();
  const billOfLading = useSelector(R.selectors.billOfLadingCommon.getBillOfLading);
  const permissions = useSelector(R.selectors.shipment.getSIPermissions);
  const isHBLPage = useSelector(R.selectors.billOfLadingCommon.getIsHBLPage);

  const calculateBillingItemsSum = useCallback((items: DocumentsDistributionDTM[], isFreighted: boolean) => {
    const targetItems = items.filter(({ freighted }) => freighted === isFreighted);

    return targetItems.reduce((prev, next) => prev + Number(next.amount), 0);
  }, []);

  const billingItems = useMemo(() => {
    const originalsFreightedSum = calculateBillingItemsSum(billOfLading.originals, true);
    const originalsUnfreightedSum = calculateBillingItemsSum(billOfLading.originals, false);
    const copiesFreightedSum = calculateBillingItemsSum(billOfLading.copies, true);
    const copiesUnfreightedSum = calculateBillingItemsSum(billOfLading.copies, false);
    const type = billOfLading?.type;

    return [
      {
        title: t('Bill of Lading Details'),
        subtitle: type ? BillOfLadingTypeNames[type] : '',
      },
      {
        title: t('Overall Number of Originals BOLs'),
        subtitle: `${originalsFreightedSum} ${t('Freighted')} ${originalsUnfreightedSum} ${t('Unfreighted')}`,
      },
      {
        title: t('Overall Number of Copy BOLs'),
        subtitle: `${copiesFreightedSum} ${t('Freighted')} ${copiesUnfreightedSum} ${t('Unfreighted')}`,
      },
    ];
  }, [billOfLading, t, calculateBillingItemsSum]);

  const isAbleToEdit = useMemo(() => !isHBLPage && permissions.editSISections === PermissionAttributePolicy.WRITE, [permissions, isHBLPage]);

  return (
    <SBLBillOfLadingInformationComponent
      items={billingItems}
      isIncomplete={!billOfLading.type}
      isEditable={isAbleToEdit}
      openEditDrawer={UC.shipmentDrawer.openEditBillOfLadingDrawer}
    />
  );
};

export { SBLBillOfLadingInformationContainer as SBLBillOfLadingInformation };
