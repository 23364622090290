import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import { FlexRow } from 'app-wrapper/view/guideline';

import {
  Footer,
  InfoIcon,
  Text,
  DueDate,
  DueDateText,
} from './FooterInformation.styled';

const TIME_FORMAT = 'DD MMM, HH:mm';

interface IFooterInformationProps {
  isPendingSubmission?: boolean;
  isCleared?: boolean;
  hasFinalMBLDocument?: boolean;
  isCustomer: boolean;
  isDestinationPartner: boolean;
  isOriginPartner: boolean;
  documentCutoff?: string;
}

export const FooterInformation: FC<IFooterInformationProps> = ({
  isPendingSubmission,
  isCleared,
  hasFinalMBLDocument,
  isCustomer,
  isDestinationPartner,
  isOriginPartner,
  documentCutoff,
}) => {
  const { t } = useTranslation();

  return (
    <Footer>
      {isPendingSubmission ? (
        <FlexRow>
          <Text>
            {t('To submit information for MBL, please make sure that all required information is provided.')}
          </Text>

          <InfoIcon />
        </FlexRow>
      ) : null}

      {isCleared && isOriginPartner ? (
        <FlexRow>
          <Text>
            {hasFinalMBLDocument ? t('Some information about MBL') : t('Please upload a copy of Final MBL received from the carrier')}
          </Text>

          <InfoIcon />
        </FlexRow>
      ) : null}

      {isCleared && (isCustomer || isDestinationPartner) ? (
        <FlexRow>
          <Text>
            {hasFinalMBLDocument ? t('Your MBL has been cleared by the carrier. A copy of the MBL is available and can be downloaded') : t('Your MBL has been cleared by the carrier. Once we receive it, a copy of the MBL will be available for download.')}
          </Text>

          <InfoIcon />
        </FlexRow>
      ) : null}

      {isPendingSubmission && documentCutoff ? (
        <DueDate>
          <Text>
            {t('Due Date:')}
          </Text>
          &nbsp;
          <DueDateText>
            {moment(documentCutoff.slice(0, 19)).format(TIME_FORMAT)}
          </DueDateText>
        </DueDate>
      ) : null}
    </Footer>
  );
};
