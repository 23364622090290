import i18n from 'app-wrapper/i18n/i18n';

export enum EAccountDepartmentType {
  CUSTOMER_ONBOARDING = 'CUSTOMER_ONBOARDING',
  ACCOUNT_MANAGEMENT = 'ACCOUNT_MANAGEMENT',
  BOOKING_OPS = 'BOOKING_OPS',
  DOCUMENTATION_OPS_EXPORT = 'DOCUMENTATION_OPS_EXPORT',
  DOCUMENTATION_OPS_IMPORT = 'DOCUMENTATION_OPS_IMPORT',
  DRAYAGE_EXPORT = 'DRAYAGE_EXPORT',
  DRAYAGE_IMPORT = 'DRAYAGE_IMPORT',
  ACCOUNTING_PAYABLES = 'ACCOUNTING_PAYABLES',
  ACCOUNTING_RECEIVABLES = 'ACCOUNTING_RECEIVABLES',
  PRICING = 'PRICING',
  RATE_MANAGEMENT = 'RATE_MANAGEMENT',
  SALES = 'SALES',
}

export const DEPARTMENT_TYPE_NAMES: Record<EAccountDepartmentType, string> = {
  [EAccountDepartmentType.CUSTOMER_ONBOARDING]: i18n.t('Customer Onboarding'),
  [EAccountDepartmentType.ACCOUNT_MANAGEMENT]: i18n.t('Account Management'),
  [EAccountDepartmentType.BOOKING_OPS]: i18n.t('Booking OPS'),
  [EAccountDepartmentType.DOCUMENTATION_OPS_EXPORT]: i18n.t('Documentation OPS (Export)'),
  [EAccountDepartmentType.DOCUMENTATION_OPS_IMPORT]: i18n.t('Documentation OPS (Import)'),
  [EAccountDepartmentType.DRAYAGE_EXPORT]: i18n.t('Drayage (Export)'),
  [EAccountDepartmentType.DRAYAGE_IMPORT]: i18n.t('Drayage (Import)'),
  [EAccountDepartmentType.ACCOUNTING_PAYABLES]: i18n.t('Accounting Payables'),
  [EAccountDepartmentType.ACCOUNTING_RECEIVABLES]: i18n.t('Accounting Receivables'),
  [EAccountDepartmentType.PRICING]: i18n.t('Pricing'),
  [EAccountDepartmentType.RATE_MANAGEMENT]: i18n.t('Rate Management'),
  [EAccountDepartmentType.SALES]: i18n.t('Sales'),
};

export const AccountDepartmentTypeArraySort: string[] = [
  EAccountDepartmentType.CUSTOMER_ONBOARDING,
  EAccountDepartmentType.ACCOUNT_MANAGEMENT,
  EAccountDepartmentType.BOOKING_OPS,
  EAccountDepartmentType.DOCUMENTATION_OPS_EXPORT,
  EAccountDepartmentType.DOCUMENTATION_OPS_IMPORT,
  EAccountDepartmentType.DRAYAGE_EXPORT,
  EAccountDepartmentType.DRAYAGE_IMPORT,
  EAccountDepartmentType.ACCOUNTING_PAYABLES,
  EAccountDepartmentType.ACCOUNTING_RECEIVABLES,
  EAccountDepartmentType.PRICING,
  EAccountDepartmentType.RATE_MANAGEMENT,
  EAccountDepartmentType.SALES,
];
