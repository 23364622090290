import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import filter from 'lodash/fp/filter';
import isEmpty from 'lodash/fp/isEmpty';

import { useTableColumns } from 'shipment-operations/view/hooks';
import { InvoicesChargeDTM } from 'shipment-operations/models/dtm';
import { prepareDataForTable } from 'shipment-operations/view/pages/ShipmentBillingInvoice/utils/prepareDataForTable';
import { subjectToIncluded } from 'shipment-operations/constants';

import {
  TableHeader, StyledSection, StyledTable,
} from './CustomTable.styled';

interface ICustomTableProps {
  data: InvoicesChargeDTM[]
  fullTable: boolean
  withTooltip?: boolean
}

const CustomTableComponent: FC<ICustomTableProps> = ({ data, fullTable, withTooltip = false }) => {
  const { t } = useTranslation();
  const { columns, fullColumns } = useTableColumns();
  const includedCharges = useMemo(() => data.filter((item) => item.charge.subjectTo === subjectToIncluded).map((item) => item.charge.chargeCode.description), [data]);
  const notIncludedCharges = useMemo(() => data.filter((item) => item.charge.subjectTo !== subjectToIncluded), [data]);

  const originData = useMemo(() => {
    const filtered = filter((item) => item.charge.designation === 'ORIGIN', withTooltip ? notIncludedCharges : data);
    return prepareDataForTable(filtered);
  }, [data]);

  const freightData = useMemo(() => {
    const filtered = filter((item) => item.charge.designation === 'FREIGHT', data);
    const filterByIncluded = filter((item) => item.charge.designation === 'FREIGHT' && item.charge.subjectTo !== subjectToIncluded, data);
    return prepareDataForTable(withTooltip ? filterByIncluded : filtered, includedCharges);
  }, [data]);

  const destinationData = useMemo(() => {
    const filtered = filter((item) => item.charge.designation === 'DESTINATION', withTooltip ? notIncludedCharges : data);
    return prepareDataForTable(filtered);
  }, [data]);

  return (
    <>
      <TableHeader columns={fullTable ? fullColumns : columns} />
      {!isEmpty(originData) && (
        <>
          <StyledSection>{t('Origin')}</StyledSection>
          <StyledTable
            columns={fullTable ? fullColumns : columns}
            showHeader={false}
            dataSource={originData}
            pagination={false}
          />
        </>
      )}
      {!isEmpty(freightData) && (
        <>
          <StyledSection>{t('freight')}</StyledSection>
          <StyledTable
            columns={fullTable ? fullColumns : columns}
            showHeader={false}
            dataSource={freightData}
            pagination={false}
          />
        </>
      )}
      {!isEmpty(destinationData) && (
        <>
          <StyledSection>{t('destination')}</StyledSection>
          <StyledTable
            columns={fullTable ? fullColumns : columns}
            showHeader={false}
            dataSource={destinationData}
            pagination={false}
          />
        </>
      )}
    </>
  );
};

export { CustomTableComponent };
