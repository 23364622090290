import styled from 'styled-components';
import Typography from 'antd/es/typography';
import AntTable from 'antd/es/table';

export const Wrap = styled.div`
  padding: 0 24px;
  display: flex;
  flex-direction: column;
  page-break-inside: avoid;
`;

export const Title = styled(Typography)`
  margin-bottom: 10.8px;
  font-size: 12px;
  line-height: 19.2px;
  font-weight: 600;
`;

export const Table = styled(AntTable)`
  .ant-table-content table {
    table-layout: fixed !important;
  }

  .ant-table-thead > tr > th {
    background-color: #FCFAFF;
    border-bottom: none;
    border-radius: 2px;
  }

  .ant-table-thead > tr > th:first-of-type {
    padding-left: 0;
  }

  .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    display: none;
  }

  .ant-table-thead .ant-table-cell {
    padding: 2px 2px 2px 0;
    background-color: #FCFAFF;
    color: rgba(32, 44, 60, 1);
    font-size: 9.6px;
    line-height: 14.4px;
    font-weight: 700;
    border-bottom: none;
  }

  .ant-table-tbody .ant-table-cell {
    padding: 7px 2px 5px 0;
    color: rgba(32, 44, 60, 1);
    font-size: 9.6px;
    line-height: 14.4px;
  }

  .ant-table-tbody > tr:not(:last-of-type) .ant-table-cell {
    border-bottom: 0.2px dashed rgba(209, 215, 226, 1);
  }

  .ant-table-tbody > tr:last-of-type .ant-table-cell {
    border-bottom: 0.2px solid rgba(209, 215, 226, 1);
  }

  .ant-table-cell:first-of-type {
    & > article {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .ant-table-placeholder {
    display: none;
  }
`;

export const TableCellBold = styled.div`
  font-weight: 600;
`;

export const TableCell = styled.div``;

export const Containers = styled.div`
  padding: 8px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 0.2px solid rgba(209, 215, 226, 1);
`;

export const ContainerItem = styled.div`
  min-width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &:last-of-type {
    padding-left: 8px;
    border-left: 0.2px solid rgba(209, 215, 226, 1);
  }
`;

export const ContainerItemTitle = styled(Typography)`
  font-size: 9.6px;
  line-height: 14.4px;
  font-weight: 700;
`;

export const ContainerItemSubtitle = styled(Typography)`
  font-size: 9.6px;
  line-height: 12px;
`;
