import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { REQUEST_STATUS } from 'app-wrapper/constants';

import { UC } from 'user-management/controllers';
import { R } from 'user-management/repository';

import { ContactBookByIdContactPersonComponent } from './ContactBookByIdContactPerson.component';

const ContactBookByIdContactPersonContainer: FC = () => {
  const ContactBookByIdContactPersonDefault = useSelector(R.selectors.contactBook.getContactBookDefault);
  const { addressId = '0' } = useParams();

  const listIndex = ContactBookByIdContactPersonDefault.activeListTabIndex || '';

  const companyListState = useSelector(R.selectors.contactBook.getContactBookDefaultCompanyListByIds(listIndex));

  const addressesIds = companyListState?.addressesIds || [];

  const companyListAddresses = useSelector(R.selectors.contactBook.getContactBookDefaultCompanyListAddressesByIds(addressesIds)).filter((item) => `${item.id}` === addressId)?.[0];

  return (
    <ContactBookByIdContactPersonComponent
      companyListAddresses={companyListAddresses}
      isLoading={ContactBookByIdContactPersonDefault.getRequestStatus === REQUEST_STATUS.pending}
      runComponent={UC.contactBookDrawers.onRunContactBookByIdContactPerson}
      onClickAddContact={UC.contactBookDrawers.onClickContactBookByIdAddContactPerson}
      onClickEditContact={UC.contactBookDrawers.onClickContactBookByIdEditContactPerson}
      onClickArrow={UC.contactBook.onBackContactBookByIdContactPerson}
    />
  );
};

export { ContactBookByIdContactPersonContainer as ContactBookByIdContactPersonPage };
