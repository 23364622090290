import React from 'react';

import { IPropsIcon } from 'app-wrapper/interfaces/icon';

export const RoadShipSvg = (props: IPropsIcon) => {
  const { width, height, fill } = props;

  return (
    <svg
      className="RoadShipSvg"
      width={width || '18'}
      height={height || '15'}
      viewBox="0 0 18 15"
    >
      <path d="M13.0558 11.1406L15.1066 9.41797C15.8722 8.76172 15.6261 7.50391 14.6691 7.17578L13.1652 6.65625V3.15625C13.1652 2.80078 12.8644 2.5 12.5089 2.5H11.1964V1.40625C11.1964 1.05078 10.8956 0.75 10.5402 0.75H6.60266C6.21985 0.75 5.94641 1.05078 5.94641 1.40625V2.5H4.63391C4.2511 2.5 3.97766 2.80078 3.97766 3.15625V6.65625L2.44641 7.17578C1.48938 7.50391 1.24329 8.76172 2.00891 9.41797L4.05969 11.1406C3.40344 12.5352 2.11829 13.4375 0.149536 13.4375C-0.0418701 13.4375 -0.178589 13.6016 -0.178589 13.7656V14.4219C-0.178589 14.6133 -0.0418701 14.75 0.149536 14.75C1.8175 14.75 3.26672 14.2852 4.4425 12.9453C4.60657 13.9844 5.50891 14.75 6.60266 14.75H10.5402C11.6066 14.75 12.5089 13.9844 12.673 12.9453C13.8488 14.3125 15.298 14.75 16.9933 14.75C17.1573 14.75 17.3214 14.6133 17.3214 14.4219V13.7656C17.3214 13.6016 17.1573 13.4375 16.9933 13.4375C15.0245 13.4375 13.712 12.5625 13.0558 11.1406ZM7.04016 1.84375H10.1027V2.5H7.04016V1.84375ZM5.29016 3.8125H11.8527V6.19141L8.98157 5.20703C8.87219 5.17969 8.68079 5.125 8.54407 5.125C8.43469 5.125 8.24329 5.17969 8.13391 5.20703L5.29016 6.19141V3.8125ZM11.4152 12.5625C11.4152 13.0547 11.005 13.4375 10.5402 13.4375H6.60266C6.11047 13.4375 5.72766 13.0547 5.72766 12.5625V10.8125L2.88391 8.40625L8.57141 6.4375L14.2589 8.40625L11.4152 10.8125V12.5625Z" fill={fill || 'currentColor'} />
    </svg>
  );
};
