import React, { FC, useCallback } from 'react';
import TooltipAntd, { TooltipPropsWithTitle } from 'antd/es/tooltip';

import { TooltipWrapperCss2 } from './Tooltip.styled';

interface ITooltip extends TooltipPropsWithTitle {
  className?: string;
  maxWidth?: string;
  isGetPopupContainerDefault?: boolean;
  mode?: 'default' | 'danger';
}

const classUuid = 'CustomTooltipComponent';

export const TooltipComponent: FC<ITooltip> = (props) => {
  const {
    children, mode = 'default', className,
    maxWidth,
    isGetPopupContainerDefault,
  } = props;

  const overlayInnerStyle = {
    color: mode === 'danger' ? '#FF4D4F' : undefined,
  };

  const overlayStyle = {
    maxHeight: '200px',
    maxWidth,
  };

  const TooltipWrapperCss = TooltipWrapperCss2(classUuid, {
    styled: {
      width: maxWidth,
    },
  });

  const getPopupContainer = useCallback(
    (triggerNode: HTMLElement) => (isGetPopupContainerDefault ? document.body : triggerNode.parentElement || document.body),
    [isGetPopupContainerDefault],
  );

  return (
    <>
      <TooltipWrapperCss />
      <TooltipAntd
        overlayClassName={`${className || ''} ${classUuid}`}
        color={`${(mode === 'danger' && 'white')}`}
        overlayInnerStyle={overlayInnerStyle}
        overlayStyle={overlayStyle}
        placement={(mode === 'danger' && 'bottomLeft') || 'bottom'}
        getPopupContainer={getPopupContainer}
        {...props}
      >
        {children || ''}
      </TooltipAntd>
    </>
  );
};

export { TooltipComponent as Tooltip };
