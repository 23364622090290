import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { EmptyStateForPages } from 'app-wrapper/view/components';
import teamImageSrc from 'user-management/view/pages/CustomerInternalTeam/no-team.png';

import {
  Wrap,
  Image,
} from './NoCompanyAttached.styled';

interface INoCompanyAttachedComponentProps {
  openSelectCompanyDrawer: () => void;
  isAbleToEditCompany: boolean;
}

export const NoCompanyAttachedComponent: FC<INoCompanyAttachedComponentProps> = ({ openSelectCompanyDrawer, isAbleToEditCompany }) => {
  const { t } = useTranslation();

  return (
    <Wrap>
      <EmptyStateForPages
        icon={(
          <Image
            style={{
              width: '25px',
              height: '25px',
            }}
            src={teamImageSrc}
          />
        )}
        disabled={!isAbleToEditCompany}
        headerText={t('No company is specified yet.')}
        contentText={t('No company is specified to the shipping party. Specify a company, address and contact (optionally) and it will appear on Bill of Lading.')}
        buttonText={t('Specify company')}
        onClickButton={openSelectCompanyDrawer}
      />
    </Wrap>
  );
};
