import React, {
  FC, memo, useCallback, useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';

import { Divider } from 'app-wrapper/view/components';

import { CompanyContactDTM, IContactBookStateErrorsFieldDTM } from 'user-management/models/dtm';
import { TooltipError } from 'user-management/view/components';

import {
  ContactPersonBlock,
  ContactPersonBlockFirst,
  ContactPersonBlockFirstInput,
  ContactPersonBlockFirstTitle,
  ContactPersonBlockName,
  ContactPersonBlockSecond,
  ContactPersonBlockSecondPhone,
  ContactPersonBlockSecondPhoneWrapper,
  ContactPersonBlockSecondInputDiv,
  ContactPersonBlockSecondAPhone,
  ContactPersonBlockSecondAPhoneWrapper,
  ContactPersonBlockSecondTitle,
  ContactPersonBlockSecondTitleDiv,
  CBDividerMargins,
  ContactPersonBlockFirstInputDiv,
  ContactPersonBlockFirstInputWrapper,
  ContactPersonBlockFirstInputWrapperRight,
  ContactPersonBlockNameWrapper,
  CBContentBodyFormPrimary,
} from './ContactPersonBlock.styled';

interface IContactPersonBlockComponentProps {
  largeInput: boolean
  isPrimary?: boolean
  titleName?: string
  firstBlockName?: string
  contactPerson?: CompanyContactDTM
  errorsName?: IContactBookStateErrorsFieldDTM
  errorsEmail?: IContactBookStateErrorsFieldDTM
  errorsPhone?: IContactBookStateErrorsFieldDTM
  errorsPhone2?: IContactBookStateErrorsFieldDTM
  primaryComponent?: React.ReactNode
  onChangeContactPerson: (contactPerson: string) => void
  onBlurContactPerson: () => void
  onFocusContactPerson: () => void
  onChangeAdditionalPhone: (additionalPhone: string) => void
  onBlurAdditionalPhone: () => void
  onFocusAdditionalPhone: () => void
  onChangeEmail: (email: string) => void
  onBlurEmail: () => void
  onFocusEmail: () => void
  onChangePhone: (phone: string) => void
  onBlurPhone: () => void
  onFocusPhone: () => void
}

const ContactPersonBlockComponentLocal: FC<IContactPersonBlockComponentProps> = (props) => {
  const {
    largeInput,
    isPrimary,
    titleName,
    firstBlockName,
    contactPerson,
    errorsName,
    errorsEmail,
    errorsPhone,
    errorsPhone2,
    primaryComponent,
    onChangeContactPerson,
    onBlurContactPerson,
    onFocusContactPerson,
    onChangeAdditionalPhone,
    onBlurAdditionalPhone,
    onFocusAdditionalPhone,
    onChangeEmail,
    onBlurEmail,
    onFocusEmail,
    onChangePhone,
    onBlurPhone,
    onFocusPhone,
  } = props;
  const { t } = useTranslation();

  const onChangeHandlerContactPerson = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onChangeContactPerson(event.target.value);
    },
    [onChangeContactPerson],
  );

  const hasErrorContactPerson = useMemo(() => !!(errorsName
    && errorsName?.message
    && (errorsName?.isBlur || errorsName?.isVisited)), [errorsName]);

  const onChangeHandlerAdditionalPhone = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onChangeAdditionalPhone(event.target.value);
    },
    [onChangeAdditionalPhone],
  );

  const hasErrorAdditionalPhone = useMemo(() => !!(errorsPhone2
    && errorsPhone2?.message
    && (errorsPhone2?.isBlur || errorsPhone2?.isVisited)), [errorsPhone2]);

  const onChangeHandlerEmail = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onChangeEmail(event.target.value);
    },
    [onChangeEmail],
  );

  const hasErrorEmail = useMemo(() => !!(errorsEmail
    && errorsEmail?.message
    && (errorsEmail?.isBlur || errorsEmail?.isVisited)), [errorsEmail]);

  const onChangeHandlerPhone = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onChangePhone(event.target.value);
    },
    [onChangePhone],
  );

  const hasErrorPhone = useMemo(() => !!(errorsPhone
    && errorsPhone?.message
    && (errorsPhone?.isBlur || errorsPhone?.isVisited)), [errorsPhone]);

  const isVisibleEmail = useMemo(() => {
    const msg = errorsEmail?.message !== t('basicerrors?.REQUIRED_MESSAGE');
    return !!(hasErrorEmail && errorsEmail?.isTooltipVisible && msg);
  }, [errorsEmail, hasErrorEmail]);

  const isVisiblePhone = useMemo(() => {
    const msg = errorsPhone?.message !== t('basicerrors?.REQUIRED_MESSAGE');

    return !!(hasErrorPhone && errorsPhone?.isTooltipVisible && msg);
  }, [errorsPhone, hasErrorPhone]);

  const isVisibleAdditionalPhone = useMemo(() => {
    const msg = errorsPhone2?.message !== t('basicerrors?.REQUIRED_MESSAGE');

    return !!(hasErrorAdditionalPhone && hasErrorAdditionalPhone && errorsPhone2?.isTooltipVisible && msg);
  }, [errorsPhone2, hasErrorAdditionalPhone]);

  return (
    <ContactPersonBlock>
      <ContactPersonBlockNameWrapper>
        <ContactPersonBlockName>
          {`${titleName || t('Contact Person')}`}
          {primaryComponent
            || (isPrimary && <CBContentBodyFormPrimary>{t('Primary')}</CBContentBodyFormPrimary>)}
        </ContactPersonBlockName>
      </ContactPersonBlockNameWrapper>

      <ContactPersonBlockFirst>
        <ContactPersonBlockFirstInputDiv>
          <ContactPersonBlockFirstInputWrapper>
            <ContactPersonBlockFirstTitle>
              {firstBlockName || `${t('Contact Person')} ${t('requiredSymbol')}`}
            </ContactPersonBlockFirstTitle>
            <ContactPersonBlockFirstInput
              largeInput={largeInput}
              value={contactPerson?.fullName || ''}
              hasError={hasErrorContactPerson}
              onChange={onChangeHandlerContactPerson}
              onBlur={onBlurContactPerson}
              onFocus={onFocusContactPerson}
            />
          </ContactPersonBlockFirstInputWrapper>
          <ContactPersonBlockFirstInputWrapperRight>
            <ContactPersonBlockFirstTitle>
              {`${t('Email')} ${t('requiredSymbol')}`}
            </ContactPersonBlockFirstTitle>
            <TooltipError
              message={errorsEmail?.message || ''}
              visible={isVisibleEmail}
            >
              <ContactPersonBlockFirstInput
                largeInput={largeInput}
                value={contactPerson?.email || ''}
                hasError={hasErrorEmail}
                onChange={onChangeHandlerEmail}
                onBlur={onBlurEmail}
                onFocus={onFocusEmail}
              />
            </TooltipError>
          </ContactPersonBlockFirstInputWrapperRight>
        </ContactPersonBlockFirstInputDiv>
      </ContactPersonBlockFirst>

      <ContactPersonBlockSecond>
        <ContactPersonBlockSecondInputDiv>
          <ContactPersonBlockSecondPhoneWrapper>
            <ContactPersonBlockSecondTitle>
              {`${t('Phone')} ${t('requiredSymbol')}`}
            </ContactPersonBlockSecondTitle>
            <TooltipError
              message={errorsPhone?.message || ''}
              visible={isVisiblePhone}
            >
              <ContactPersonBlockSecondPhone
                largeInput={largeInput}
                value={contactPerson?.phone || ''}
                hasError={hasErrorPhone}
                onChange={onChangeHandlerPhone}
                onBlur={onBlurPhone}
                onFocus={onFocusPhone}
              />
            </TooltipError>
          </ContactPersonBlockSecondPhoneWrapper>
          <ContactPersonBlockSecondAPhoneWrapper>
            <ContactPersonBlockSecondTitleDiv>
              <ContactPersonBlockSecondTitle>
                {`${t('Additional Phone')}`}
              </ContactPersonBlockSecondTitle>
            </ContactPersonBlockSecondTitleDiv>
            <TooltipError
              message={errorsPhone2?.message || ''}
              visible={isVisibleAdditionalPhone}
            >
              <ContactPersonBlockSecondAPhone
                largeInput={largeInput}
                value={contactPerson?.phone2 || ''}
                onChange={onChangeHandlerAdditionalPhone}
                hasError={hasErrorAdditionalPhone}
                onBlur={onBlurAdditionalPhone}
                onFocus={onFocusAdditionalPhone}
              />
            </TooltipError>
          </ContactPersonBlockSecondAPhoneWrapper>
        </ContactPersonBlockSecondInputDiv>
      </ContactPersonBlockSecond>

      <Divider dashed style={CBDividerMargins} />

    </ContactPersonBlock>
  );
};

const ContactPersonBlockComponentCached = memo(ContactPersonBlockComponentLocal);

export { ContactPersonBlockComponentCached as ContactPersonBlockComponent };
