import React from 'react';
import { useSelector } from 'react-redux';

import { R } from 'user-management/repository';
import { UC } from 'user-management/controllers';

import { TeamCustomersComponent } from './TeamCustomers.component';

export const TeamCustomers = () => {
  const team = useSelector(R.selectors.teams.getTeam);
  const isCurrentUserAdmin = useSelector(R.selectors.teams.getIsCurrentUserAdmin);

  return team ? (
    <TeamCustomersComponent
      customers={team.customers}
      isCurrentUserAdmin={isCurrentUserAdmin}
      removeCustomer={UC.teams.removeCustomerFromTeam}
    />
  ) : null;
};
