import React from 'react';

import { IPropsIcon } from 'app-wrapper/interfaces/icon';

import { Icon } from './SkypaceBgLogoSvg.styled';

export const SkypaceBgLogoSvg = (props: IPropsIcon) => {
  const { width, height, fill } = props;
  return (
    <Icon
      className="SkypaceBgLogoSvg"
      width={width || '130'}
      height={height || '203'}
      viewBox="0 0 130 203"
      fill={fill || 'currentColor'}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M111.5 213C173.08 213 223 163.08 223 101.5C223 39.9202 173.08 -10 111.5 -10C49.9202 -10 0 39.9202 0 101.5C0 163.08 49.9202 213 111.5 213Z" fill="url(#paint0_linear_20303_16)" />
      <path d="M147.465 152.221H97.1802C94.1195 152.221 91.7146 149.816 91.7146 146.755C91.7146 143.695 94.1195 141.29 97.1802 141.29H147.465C156.21 141.29 163.424 133.419 163.424 123.8C163.424 114.18 156.21 106.309 147.465 106.309H77.0665C62.1999 106.309 50.1754 93.629 50.1754 77.8878C50.1754 62.1466 62.1999 49.4662 77.0665 49.4662H126.258C129.318 49.4662 131.723 51.8711 131.723 54.9319C131.723 57.9927 129.318 60.3976 126.258 60.3976H77.0665C68.3214 60.3976 61.1067 68.2682 61.1067 77.8878C61.1067 87.5074 68.3214 95.378 77.0665 95.378H147.465C162.331 95.378 174.356 108.058 174.356 123.8C174.356 139.541 162.331 152.221 147.465 152.221Z" fill="white" />
      <path d="M65.5897 155.283C69.8158 155.283 73.2417 151.857 73.2417 147.631C73.2417 143.405 69.8158 139.979 65.5897 139.979C61.3637 139.979 57.9378 143.405 57.9378 147.631C57.9378 151.857 61.3637 155.283 65.5897 155.283Z" fill="white" />
      <defs>
        <linearGradient id="paint0_linear_20303_16" x1="111.5" y1="-10" x2="111.5" y2="213" gradientUnits="userSpaceOnUse">
          <stop stopColor="#F4F5F8" />
          <stop offset="1" stopColor="#F4F5F8" stopOpacity="0.1" />
        </linearGradient>
      </defs>
    </Icon>
  );
};
