import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { R } from 'monetary/repository';
import { UC } from 'monetary/controllers';

import { RFRListPageComponent } from './RFRListPage.component';

const RFRListPageContainer: FC = () => {
  const getFreightQuoteCurrent = useSelector(R.selectors.freightQuote.getFreightQuoteCurrent);

  return (
    <RFRListPageComponent
      allQuotesState={getFreightQuoteCurrent?.AllRFRQuotas}
      quotaStatus={getFreightQuoteCurrent?.status}
      isFavorite={getFreightQuoteCurrent?.filtersRequestAllQuotas?.isFavorite}
      isFavoriteDisabled={getFreightQuoteCurrent?.filtersRequestAllQuotas?.isDisabled}
      isLoadingAllRFR={getFreightQuoteCurrent.isLoadingAllRFR}
      isLoadingAllRFRNextPage={getFreightQuoteCurrent.isLoadingAllRFRNextPage}
      hasNextPage={getFreightQuoteCurrent.hasNextPageAllRFR}
      onRunRFRQuotasPage={UC.FreightRFRQuote.onRunAllRFRListPage}
      onScrollNextInfinityPage={UC.FreightRFRQuote.nextPageAllRFRListPage}
    />
  );
};

export { RFRListPageContainer as RFRListPage };
