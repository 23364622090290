import React from 'react';
import { useTranslation } from 'react-i18next';

import folderSrc from 'shipment-operations/view/pages/ShipmentBillOfLading/components/MBLFlowManagement/components/RejectedContent/folder-warning.png';
import {
  Column,
  Title,
  Text,
  Image,
} from 'shipment-operations/view/pages/ShipmentBillOfLading/components/MBLFlowManagement/components/SubmittedContent/SubmittedContent.styled';
import { Content } from 'shipment-operations/view/pages/ShipmentBillOfLading/components/MBLFlowManagement/components/RejectedContent/RejectedContent.styled';

export const FailedContent = () => {
  const { t } = useTranslation();

  return (
    <Content>
      <Image
        src={folderSrc}
        alt=""
      />

      <Column>
        <Title>
          {t('Shipping Instructions file has not been delivered to Inttra')}
        </Title>

        <Text>
          {t('Please contact technical support team to resolve the issue.')}
        </Text>
      </Column>
    </Content>
  );
};
