import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';
import { UC } from 'shipment-operations/controllers';

import { DatePickerComponent } from './DatePicker.component';

interface DatePickerContainerProps {
  id: number;
  type: 'ETA' | 'ETD';
}

const DatePickerContainer: FC<DatePickerContainerProps> = ({ id, type }) => {
  const transportation = useSelector(R.selectors.manualBookingWizard.getTransportationById(id));
  const leg = useSelector(R.selectors.manualBookingWizard.getTransportLegById(transportation.transportLeg));
  const { etd, eta } = useSelector(R.selectors.manualBookingWizard.getTransportationErrorsById(id));

  return (
    <DatePickerComponent
      type={type}
      transportation={transportation}
      leg={leg}
      setETDDate={UC.manualBookingWizard.setETDDate}
      setETADate={UC.manualBookingWizard.setETADate}
      errorETA={!!eta}
      errorETD={!!etd}
    />
  );
};

export { DatePickerContainer as DatePicker };
