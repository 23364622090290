import styled from 'styled-components';

export const Title = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 1.2rem;
  text-align: center;
  color: #202C3C;
  margin-bottom: 10px;
`;

export const Main = styled.div`
  padding: 9px;
  font-size: 0.3rem;
  column-count: 3;
  column-rule: 0.5px solid #D1D7E2;
  column-gap: 1rem;
`;

export const ParagraphTitle = styled.div`
  display: inline-block;
  font-weight: 700;
  color: #000000;
  margin-top: 2px;
`;
