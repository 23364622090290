import { ClockCircleSvg, VerticalFormItemSvg } from 'app-wrapper/view/icons';
import styled from 'styled-components';
import { divFlex } from './main';

export const RFQItemHeader = styled.div.attrs({
  'data-class': 'RFQItemHeader',
})`
  ${divFlex}
  flex-direction: column;
  width: 100%;
  padding: 0px 16px;
`;

export const RFQItemHeaderFirst = styled.div.attrs({
  'data-class': 'RFQItemHeaderFirst',
})`
  ${divFlex}
  height: 23px;
`;

export const RFQItemHeaderFirstTitle = styled.div.attrs({
  'data-class': 'RFQItemHeaderFirstTitle',
})`
  ${divFlex}
  align-items: center;

  font-size: 16px;
  font-weight: 500;
`;

export const RFQItemHeaderSecondTitle = styled.div.attrs({
  'data-class': 'RFQItemHeaderSecondTitle',
})`
  ${divFlex}
  justify-content: start;
  align-items: center;

  margin-left: 12px;
  color: ${({ theme }) => theme.themesColors.secondaryDot45};
  font-size: 12px;
  font-weight: 400;
`;

export const RFQItemHeaderIncotermTitle = styled.div.attrs({
  'data-class': 'RFQItemHeaderIncotermTitle',
})`
  ${divFlex}
  justify-content: start;
  align-items: center;

  margin-left: auto;
  color: ${({ theme }) => theme.themesColors.secondaryDot45};
  font-size: 12px;
  font-weight: 400;
`;

export const RFQItemHeaderFirstSummary = styled.div.attrs({
  'data-class': 'RFQItemHeaderFirstSummary',
})`
  ${divFlex}
  align-items: center;

  font-size: 16px;
  font-weight: 500;
  margin-left: 10px;
`;

export const RFQItemHeaderSecond = styled.div.attrs({
  'data-class': 'RFQItemHeaderSecond',
})`
  ${divFlex}
  height: 32px;
  padding-bottom: 9px;
`;

export const RFQItemHeaderSecondShipping = styled.div.attrs({
  'data-class': 'RFQItemHeaderSecondShipping',
})`
  ${divFlex}
`;

const RFQItemHeaderSecondShippingAddress = styled.div.attrs({
  'data-class': 'RFQItemHeaderSecondShippingAddress',
})`
  ${divFlex}
  font-size: 14px;
`;

export const RFQItemHeaderSecondShippingFirstAddress = styled(RFQItemHeaderSecondShippingAddress)`
`;

export const RFQItemHeaderSecondShippingArrow = styled.div.attrs({
  'data-class': 'RFQItemHeaderSecondShippingArrow',
})`
  ${divFlex}
  align-items: center;
  svg {
    transform: rotate(-90deg);
    color: ${({ theme }) => theme.themesColors.characterBrandingDisabled025};
  }
  margin-left: 12px;
  margin-right: 12px;
`;

export const RFQItemHeaderSecondShippingArrowSvg = styled(VerticalFormItemSvg)`
`;

export const RFQItemHeaderSecondShippingSecondAddress = styled(RFQItemHeaderSecondShippingAddress)`
`;

export const RFQItemHeaderSecondTime = styled.div.attrs({
  'data-class': 'RFQItemHeaderSecondTime',
})`
  ${divFlex}
  align-items: center;
  margin-left: auto;
`;

export const RFQItemHeaderSecondTimeIconDiv = styled.div.attrs({
  'data-class': 'RFQItemHeaderSecondTimeIconDiv',
})`
  ${divFlex}
  align-items: center;
  margin-right: 5px;
  margin-top: -1px;
`;

export const RFQItemHeaderSecondTimeIcon = styled(ClockCircleSvg)``;

export const RFQItemHeaderSecondTimeText = styled.div`
`;
