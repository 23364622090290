import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { EditButton } from 'app-wrapper/view/components';

import { InputResults, DocumentsResults } from './components';
import {
  Content,
  Header,
  Title,
  AboutTitle,
  Text,
  Results,
  Inputs,
  Documents,
} from './ServiceDetails.styled';

interface IServiceDetailsComponentProps {
  startEditingFlow: () => void;
  serviceInfo?: string;
  isThereAreAnyRelatedActivities: boolean;
}

export const ServiceDetailsComponent: FC<IServiceDetailsComponentProps> = ({
  startEditingFlow,
  serviceInfo,
  isThereAreAnyRelatedActivities,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Content>
        <Header>
          <Title>
            {t('Service Details')}
          </Title>

          {isThereAreAnyRelatedActivities ? (
            <EditButton onClick={startEditingFlow} />
          ) : null}
        </Header>

        <Results>
          <Inputs>
            <InputResults />
          </Inputs>

          <Documents>
            <DocumentsResults />
          </Documents>
        </Results>
      </Content>

      {serviceInfo ? (
        <Content>
          <Results>
            <Inputs>
              <AboutTitle>
                {t('About Service')}
              </AboutTitle>
            </Inputs>

            <Documents>
              <Text>
                {serviceInfo}
              </Text>
            </Documents>
          </Results>
        </Content>
      ) : null}
    </>
  );
};
