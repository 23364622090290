import { IsDefined, IsString } from 'class-validator';
import { BaseDTM } from 'proto/BaseDTM';

enum EFreightPort {
  DOOR = 'DOOR',
  PORT = 'PORT'
}

export type TFreightPortDTM = {
  code: string
  type: EFreightPort
  earliestDate: string
  latestDate: string
};

export class FreightPortDTM extends BaseDTM<TFreightPortDTM> {
  @IsDefined()
  @IsString()
  code: string;

  @IsDefined()
  @IsString()
  type: EFreightPort;

  @IsDefined()
  @IsString()
  earliestDate: string;

  @IsDefined()
  @IsString()
  latestDate: string;
}
