import React, { FC, useCallback } from 'react';
import Divider from 'antd/es/divider';
import { useTranslation } from 'react-i18next';

import {
  GrayFieldName,
  InputItem, InputItemRight,
  InputsRow, InputsTotalValue, StyledProfit,
} from 'shipment-operations/view/components/ActiveCharge';
import { checkSign } from 'app-wrapper/utils';

interface ITotalComponentProps {
  totalAR: number
  totalAP: number
  budgetAR?: number
  budgetAP?: number
  profit: number
  balanceAP: number
  balanceAR: number
}

const TotalComponent: FC<ITotalComponentProps> = ({
  totalAR, totalAP, profit, balanceAP, balanceAR,
  budgetAP = 0, budgetAR = 0,
}) => {
  const { t } = useTranslation();

  const renderProfit = useCallback(
    (item: number) => checkSign(item, '$'),
    [],
  );

  return (
    <>
      <InputsRow>
        <InputItem>
          <GrayFieldName>
            {t('addChargeMenu.total')}
          </GrayFieldName>
        </InputItem>
        <InputItemRight>
          {checkSign(totalAP, '$')}
        </InputItemRight>
        <InputItemRight>
          {checkSign(totalAR, '$')}
        </InputItemRight>
      </InputsRow>
      <Divider style={{ margin: '8px 0' }} dashed />
      <InputsRow>
        <InputItem>
          <GrayFieldName>
            {t('addChargeMenu.budget')}
          </GrayFieldName>
        </InputItem>
        <InputsTotalValue>
          {checkSign(budgetAP, '$')}
        </InputsTotalValue>
        <InputsTotalValue>
          {checkSign(budgetAR, '$')}
        </InputsTotalValue>
      </InputsRow>
      <Divider style={{ margin: '8px 0' }} dashed />
      <InputsRow>
        <InputItem>
          <GrayFieldName>
            {t('addChargeMenu.balance')}
          </GrayFieldName>
        </InputItem>
        <InputsTotalValue>
          {checkSign(balanceAP, '$')}
        </InputsTotalValue>
        <InputsTotalValue>
          {checkSign(balanceAR, '$')}
        </InputsTotalValue>
      </InputsRow>
      <Divider style={{ margin: '8px 0' }} dashed />
      <InputsRow>
        <InputItem>
          <GrayFieldName>
            {t('addChargeMenu.profit')}
          </GrayFieldName>
        </InputItem>
        <InputItemRight />
        <StyledProfit>
          {renderProfit(profit)}
        </StyledProfit>
      </InputsRow>
    </>
  );
};

export { TotalComponent };
