import styled from 'styled-components';
import Table from 'antd/es/table';
import Typography from 'antd/es/typography';

export const SDLTableLayout = styled('div').attrs({
  'data-class': 'SDLTableLayout',
})`
  margin-top: 8px;
`;

export const SDLTable = styled(Table)`
  .ant-table-content table {
    table-layout: fixed !important;
  }

  .ant-table-thead > tr > th {
    background: white;
  }

  .ant-table-thead > tr > th:first-of-type {
    padding-left: 16px;
  }

  .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    display: none;
  }

  .ant-table-thead .ant-table-cell {
    color: #73819B;
    font-size: 12px;
    font-weight: 400;
  }

  .ant-table-cell:first-of-type {
    & > article {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;

export const SDLTableHeader = styled.div.attrs((props: {
  isNested: boolean,
}) => ({
  isNested: props.isNested,
}))`
  background-color: ${({ theme }) => theme?.themesColors?.neutralBranding1};
  padding: 16px 16px 0 16px;
  font-weight: 500;
  font-size: ${({ isNested }) => {
    if (isNested) {
      return '14px';
    }

    return '16px';
  }};
  line-height: 24px;
`;

export const SDLTableCell = styled(Typography)`
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
`;

export const SDLTableCellName = styled(Typography)`
  max-width: 100%;
  width: fit-content;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
`;

export const SDLTableCellEllipsis = styled(Typography)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const SDLTableIncompleteCell = styled.div`
  display: flex;
  justify-content: flex-end;
`;
