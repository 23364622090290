import React, { FC, useEffect } from 'react';
import { Outlet, useParams } from 'react-router';

import { ShipmentBookingStatusEnum } from 'shipment-operations/constants';

import { Wrapper } from 'app-wrapper/view/guideline/Wrapper';
import { ShipmentTransportationHeader, ShipmentTransportationTabs, NotAvailable } from './components';

interface IShipmentTransportationComponentProps {
  bookingStatus?: ShipmentBookingStatusEnum
  loadData: (shipmentId: string) => void
  reset: () => void
}

export const ShipmentTransportationComponent: FC<IShipmentTransportationComponentProps> = ({
  bookingStatus,
  loadData,
  reset,
}) => {
  const { shipmentId = '0' } = useParams();
  const shipmentStatusIsNotAvailable = bookingStatus === ShipmentBookingStatusEnum.BOOKING_SUBMITTED
    || bookingStatus === ShipmentBookingStatusEnum.BOOKING_PREPARATION;

  useEffect(() => {
    loadData(shipmentId);
    return reset;
  }, []);

  return (
    <Wrapper>
      <ShipmentTransportationHeader />
      {
        shipmentStatusIsNotAvailable ? (
          <NotAvailable />
        ) : (
          <>
            <ShipmentTransportationTabs />
            <Outlet />
          </>
        )
      }
    </Wrapper>
  );
};
