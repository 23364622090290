import React from 'react';
import { useSelector } from 'react-redux';

import { UC } from 'user-management/controllers';
import { R } from 'user-management/repository';

import { CreateOrEditTeamDrawerComponent } from './CreateOrEditTeamDrawer.component';

export const CreateOrEditTeamDrawer = () => {
  const isOpened = useSelector(R.selectors.teams.getIsDrawerOpened);
  const isInEditMode = useSelector(R.selectors.teams.getIsDrawerInEditMode);
  const isLoading = useSelector(R.selectors.teams.getIsDrawerLoading);
  const customers = useSelector(R.selectors.teams.getCustomers);
  const members = useSelector(R.selectors.teams.getMembers);
  const tempCustomers = useSelector(R.selectors.teams.getTempCustomers);
  const tempTeamName = useSelector(R.selectors.teams.getTempTeamName);
  const rolesToEmailsState = useSelector(R.selectors.teams.getRolesToEmailsState);

  return (
    <CreateOrEditTeamDrawerComponent
      isVisible={isOpened}
      isInEditMode={isInEditMode}
      onClose={UC.teams.closeTeamDrawer}
      isLoading={isLoading}
      customers={customers}
      members={members}
      tempCustomers={tempCustomers}
      tempTeamName={tempTeamName}
      rolesToEmailsState={rolesToEmailsState}
      setEmailByRole={UC.teams.setRoleMember}
      onChangeTempCustomers={UC.teams.onChangeTempCustomers}
      onChangeTempTeamName={UC.teams.onChangeTempTeamName}
    />
  );
};
