import styled from 'styled-components';
import Typography from 'antd/es/typography';
import AntTable from 'antd/es/table';

export const Wrap = styled.div`
  padding: 0 24px;
  display: flex;
  flex-direction: column;
`;

export const Title = styled(Typography)`
  margin-bottom: 10.8px;
  font-size: 12px;
  line-height: 19.2px;
  font-weight: 600;
`;

export const Table = styled(AntTable)`
  .ant-table-content table {
    table-layout: fixed !important;
  }

  .ant-table-thead > tr > th {
    background-color: #FCFAFF;
    border-bottom: none;
    border-radius: 2px;
  }

  .ant-table-thead > tr > th:first-of-type {
    padding-left: 0;
  }

  .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    display: none;
  }

  .ant-table-thead .ant-table-cell {
    padding: 2px 2px 2px 0;
    background-color: #FCFAFF;
    color: rgba(32, 44, 60, 1);
    font-size: 9.6px;
    line-height: 14.4px;
    font-weight: 700;
    border-bottom: none;
  }

  .ant-table-cell:first-of-type {
    & > article {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .ant-table-placeholder {
    display: none;
  }
`;

export const TableTitle = styled(Typography)`
  width: 100%;
  font-weight: 700;
  font-size: 9.6px;
  line-height: 14.4px;
`;

export const CategoryTitle = styled(Typography)`
  margin-top: 10px;
  margin-bottom: 6px;
  font-size: 9.6px;
  line-height: 19.2px;
  font-weight: 600;
`;

export const TableCharges = styled(AntTable)`
  .ant-table-content table {
    table-layout: fixed !important;
  }

  .ant-table-thead > tr > th {
    border-bottom: none;
  }

  .ant-table-thead > tr > th:first-of-type {
    padding-left: 0;
  }

  .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    display: none;
  }

  .ant-table-tbody .ant-table-cell {
    padding: 7px 2px 5px 0;
    color: rgba(32, 44, 60, 1);
    font-size: 9.6px;
    line-height: 14.4px;
    border-bottom: 0.5px dashed rgba(209, 215, 226, 1);
  }

  .ant-table-cell:first-of-type {
    & > article {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .ant-table-placeholder {
    display: none;
  }
`;

export const TableCell = styled.div``;

export const TableWrap = styled.div`
  display: flex;
  flex-direction: column;
  page-break-inside: avoid;
`;
