import React from 'react';
import { useSelector } from 'react-redux';

import { R } from 'app-wrapper/repository';
import { UC } from 'app-wrapper/controllers';
import { R as userManagementR } from 'user-management/repository';

import { MarkAsCompletedComponent } from './MarkAsCompleted.component';

const MarkAsCompletedContainer = () => {
  const hasOwnRequestedQuotas = useSelector(R.selectors.overview.getHasOwnRequestedQuotas);
  const hasOwnBookedShipment = useSelector(R.selectors.overview.getHasOwnBookedShipments);
  const organization = useSelector(userManagementR.selectors.userOrganizationData.getUserOrganization);
  const isUserOnboarded = useSelector(R.selectors.overview.getIsUserOnboarded);

  return (
    <MarkAsCompletedComponent
      hasOwnRequestedQuotas={hasOwnRequestedQuotas}
      hasOwnBookedShipments={hasOwnBookedShipment}
      status={organization?.status}
      isUserOnboarded={isUserOnboarded}
      onMarkAsCompleted={UC.overview.markAsCompleted}
    />
  );
};

export { MarkAsCompletedContainer as MarkAsCompleted };
