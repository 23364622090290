import React, {
  FC, memo, useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { CollapseProps } from 'antd/es/collapse';

import { useCollapseOnChangeHandler, useWindowSize } from 'app-wrapper/hooks';
import { QUOTAS_STATUS } from 'app-wrapper/constants';
import themesColors from 'app-wrapper/view/themes/themesColors';
import { ExpandIcon, Tooltip } from 'app-wrapper/view/components';
import { normalizationCost, validateDecimalFraction } from 'app-wrapper/utils';
import { CloseSvg, PlusSvg } from 'app-wrapper/view/icons';

import { AdditionalRatesServiceDTM } from 'monetary/models/dtm/Quotas';

import {
  AdditionalServicesAddService,
  AdditionalServicesCollapse, AdditionalServicesComponentWrap, AdditionalServicesEndSpan, AdditionalServicesPanel,
  AdditionalServicesService,
  AdditionalServicesServiceContent,
  AdditionalServicesServiceTitle,
  AdditionalServicesServiceTitleWrap,
} from './AdditionalServices.styled';

interface IAdditionalServicesComponentProps {
  servicesChecked?: AdditionalRatesServiceDTM[];
  requestStatus?: string;
  isDisabledAdd?: boolean;
  forceCollapse?: boolean;
  formLocalState: { isCollapsed?: boolean };
  isAllFieldDisabled?: boolean;
  openAdditionalServiceDrawer: () => void;
  onChangeChecked: (id?: string, isChangeCheckedState?: boolean) => (value: boolean) => Promise<void>;
}

const AdditionalServicesComponent: FC<IAdditionalServicesComponentProps> = ((props) => {
  const {
    servicesChecked,
    requestStatus,
    isDisabledAdd,
    forceCollapse,
    formLocalState,
    isAllFieldDisabled,
    openAdditionalServiceDrawer,
    onChangeChecked,
  } = props;

  const { isFullMediaWidth } = useWindowSize();
  const [isResponsive, setIsResponsive] = useState(isFullMediaWidth);
  useEffect(() => {
    if (forceCollapse) {
      setIsResponsive(false);
    } else {
      setIsResponsive(!!formLocalState?.isCollapsed);
    }
  }, [forceCollapse, formLocalState?.isCollapsed]);

  const { t } = useTranslation();
  const endVisible = useRef<HTMLElement>(null);
  const [activeKey, setActiveKey] = useState<string[]>([]);

  useEffect(() => {
    if (requestStatus === QUOTAS_STATUS.pending) {
      if (servicesChecked?.length) {
        setActiveKey(['4']);
        return;
      }
      setActiveKey([]);
    }
  }, [requestStatus, servicesChecked]);

  const togglePanel = useCallback(
    () => {
      setActiveKey((prev) => (prev.length ? [] : ['4']));
    },
    [],
  );

  const onChangeCheckedHandle = useCallback(
    (id?: string) => () => {
      onChangeChecked(id, true)(false);
    },
    [onChangeChecked],
  );

  const StyledExpandIcon = useCallback((propsIcon: CollapseProps) => {
    const { isActive } = propsIcon as { isActive: true };

    return (<ExpandIcon color={isActive && themesColors.primaryBranding6} isActive={isActive} />);
  }, [themesColors]);

  const collapseOnChangeHandler = useCollapseOnChangeHandler(endVisible.current, togglePanel);

  const getTotalCost = useCallback(
    (countDocument?: number, buyPrice?: number) => normalizationCost(validateDecimalFraction(`${Number(countDocument || 0) * Number(buyPrice || 0)}`, 12, 2, { forcePrecision: true }), {
      toFixed: 2,
      thousandthSeparatorComma: true,
    }),
    [],
  );

  const itemsComponent = useMemo(() => (
    <>
      {isResponsive && (
        <AdditionalServicesServiceTitleWrap>
          <AdditionalServicesServiceTitle>
            {t('Value Added Services')}
          </AdditionalServicesServiceTitle>
        </AdditionalServicesServiceTitleWrap>
      )}
      <AdditionalServicesServiceContent isCollapse={!isResponsive}>
        {servicesChecked?.map((item) => (
          <Tooltip
            isGetPopupContainerDefault
            placement="top"
            title={(
              <>
                {t('Cost per unit')}: ${Number(item?.buyPrice || NaN)}<br />
                {t('Units')}: {item?.countDocument || 0}<br />
                {t('Total')}: ${getTotalCost(Number(item?.buyPrice || 0), Number(item?.countDocument || 0))}<br />
              </>
            )}
            key={item.customId}
          >
            <AdditionalServicesService>
              {item?.chargeCode?.description}
              <CloseSvg
                width={7}
                height={7}
                onClick={onChangeCheckedHandle(item.customId)}
              />
            </AdditionalServicesService>
          </Tooltip>
        ))}
        <Tooltip
          title={isDisabledAdd && t('To select services, enter data on origin, destination.')}
        >
          <AdditionalServicesAddService
            disabled={isDisabledAdd || isAllFieldDisabled}
            onClick={openAdditionalServiceDrawer}
          >
            <PlusSvg />
            {t('Add Service(s)')}
          </AdditionalServicesAddService>
        </Tooltip>
      </AdditionalServicesServiceContent>
    </>
  ), [isResponsive, servicesChecked, isDisabledAdd]);

  const collapseComponent = useMemo(() => (
    <AdditionalServicesCollapse expandIcon={StyledExpandIcon} onChange={collapseOnChangeHandler} activeKey={activeKey}>
      <AdditionalServicesPanel header={t('Value Added Services')} key="4">
        {itemsComponent}
      </AdditionalServicesPanel>
    </AdditionalServicesCollapse>
  ), [itemsComponent, activeKey]);

  return (
    <AdditionalServicesComponentWrap>
      {!isResponsive ? (
        collapseComponent
      ) : (
        itemsComponent
      )}

      <AdditionalServicesEndSpan ref={endVisible} />
    </AdditionalServicesComponentWrap>
  );
});

const cachedAdditionalServicesComponent = memo(AdditionalServicesComponent);

export { cachedAdditionalServicesComponent as AdditionalServicesComponent };
