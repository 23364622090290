import React from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';
import { UC } from 'shipment-operations/controllers';

import { BillOfLadingEditCommentsComponent } from './BillOfLadingEditComments.component';

const BillOfLadingEditCommentsContainer = () => {
  const formState = useSelector(R.selectors.billOfLadingCommon.getCommentsFormState);
  const isPending = useSelector(R.selectors.billOfLadingCommon.getIsStatePending);

  return (
    <BillOfLadingEditCommentsComponent
      formState={formState}
      setFormState={UC.billOfLading.setCommentsFromState}
      isPending={isPending}
    />
  );
};

export { BillOfLadingEditCommentsContainer as BillOfLadingEditComments };
