import React, { FC, useRef, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useReactToPrint } from 'react-to-print';

import { SkypaceInformationDTM } from 'app-wrapper/constants';
import { AddressDTM } from 'user-management/models/dtm';
import { TermsOfConditionsUKPdfComponent, TermsOfConditionsUSPdfComponent } from 'shipment-operations/view/components';

import { MenuItem } from './SkypaceTermsAndConditions.styled';

interface PrintQuoteRFQComponentProps {
  onClearCustomerInfo?: () => void;
  isLoading: boolean;
  fileNamePrint?: string
  adminAddress?: AddressDTM;
  createdByName?: string;
  header?: {
    company?: SkypaceInformationDTM;
  }
  fetchDataBeforePrinting: () => void;
}

const OLD_TITLE = document.title;

export const SkypaceTermsAndConditionsComponent: FC<PrintQuoteRFQComponentProps> = ({
  onClearCustomerInfo,
  isLoading,
  fileNamePrint,
  adminAddress,
  createdByName,
  header,
  fetchDataBeforePrinting,
}) => {
  const { t } = useTranslation();
  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    // @ts-ignore
    content: () => componentRef.current,
    onAfterPrint: () => {
      if (onClearCustomerInfo) {
        onClearCustomerInfo();
      }

      // support chrome
      document.title = OLD_TITLE;
    },
  });

  const onClickPrint = useCallback(async () => {
    if (isLoading) {
      return;
    }

    await fetchDataBeforePrinting();

    document.title = fileNamePrint || OLD_TITLE;
    handlePrint();
  }, [handlePrint, isLoading, fileNamePrint, fetchDataBeforePrinting]);

  return (
    <>
      <div style={{ display: 'none' }}>
        {adminAddress?.country === 'US' ? (
          <TermsOfConditionsUSPdfComponent
            fileNamePrint={fileNamePrint}
            createdByName={createdByName}
            header={header}
            ref={componentRef}
          />
        ) : null}

        {adminAddress?.country === 'GB' ? (
          <TermsOfConditionsUKPdfComponent
            fileNamePrint={fileNamePrint}
            createdByName={createdByName}
            header={header}
            ref={componentRef}
          />
        ) : null}
      </div>

      <MenuItem onClick={onClickPrint}>
        {t('Print')}
      </MenuItem>
    </>
  );
};
