import {
  IsArray,
  IsDefined,
  IsString,
} from 'class-validator';
import { BaseDTM } from 'proto/BaseDTM';

export interface IBookingConfirmationFreightItemsDTM {
  title: string;
  subtitle: string;
  text: string;
}

export class BookingConfirmationFreightItemsDTM extends BaseDTM<IBookingConfirmationFreightItemsDTM> {
  @IsDefined()
  @IsString()
  title: string;

  @IsDefined()
  @IsArray()
  subtitle: string;

  @IsDefined()
  @IsArray()
  text: string;
}

interface IBookingConfirmationPDFGeneralInfoDTM {
  bcNumber: string;
  companyName: string;
  address: string;
  addressLine: string;
  fullName: string;
  phone: string;
  email: string;
}

export class BookingConfirmationPDFGeneralInfoDTM extends BaseDTM<IBookingConfirmationPDFGeneralInfoDTM> {
  @IsDefined()
  @IsString()
  bcNumber: string;

  @IsDefined()
  @IsArray()
  companyName: string;

  @IsDefined()
  @IsArray()
  address: string;

  @IsDefined()
  @IsArray()
  fullName: string;

  @IsDefined()
  @IsArray()
  addressLine: string;

  @IsDefined()
  @IsArray()
  phone: string;

  @IsDefined()
  @IsArray()
  email: string;
}
