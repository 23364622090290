import { useTranslation } from 'react-i18next';
import { UseFormSetError } from 'react-hook-form';

import { showWarning } from 'app-wrapper/utils';
import { ServerError } from 'app-wrapper/types/ServerError';

export const useRequest = (
  setError: UseFormSetError<any> | undefined | null,
  isNotification: boolean = true,
) => {
  const { t } = useTranslation();

  return (request: Promise<any>) => (
    request.catch((response: ServerError) => {
      const { message } = response;

      if (setError) {
        setError('base', { type: 'manual', types: { manual: message }, message });
      }
      if (isNotification) {
        showWarning({ message: t('Error'), description: message });
      }

      return response;
    })
  );
};
