import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { R } from 'monetary/repository';
import { UC } from 'monetary/controllers';

import { RateDownloadModalComponent } from './RateDownloadModal.component';

const RateDownloadModalContainer: FC = () => {
  const isLoading = useSelector(R.selectors.ratesModal.getLoading);
  const isFormDataFilled = useSelector(R.selectors.ratesModal.isFormDataFilled);
  const isOpen = useSelector(R.selectors.ratesModal.getOpenState);
  const date = useSelector(R.selectors.ratesModal.getDate);
  const carrierSCACList = useSelector(R.selectors.ratesModal.getCarrierScacList);

  return (
    <RateDownloadModalComponent
      isLoading={isLoading}
      isOpen={isOpen}
      isFormDataFilled={isFormDataFilled}
      carrierSCACList={carrierSCACList}
      date={date}
      onDateChange={UC.ratesModal.setDate}
      onDateBlur={UC.ratesModal.blurEffectiveDateField}
      onCarrierSCACChange={UC.ratesModal.setCarrierSCACList}
      onDownload={UC.ratesModal.downloadRates}
      onClose={UC.ratesModal.closeModal}
    />
  );
};

export { RateDownloadModalContainer as RateDownloadModal };
