import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import DeleteOutlined from '@ant-design/icons/lib/icons/DeleteOutlined';

import { FormStatus } from 'app-wrapper/view/components';
import { FormStatusType } from 'app-wrapper/types/FormStatusType';
import { SHIPMENT_CONTAINERS } from 'app-wrapper/models/routes';
import { ShippingPartyDTM } from 'shipment-operations/models/dtm';
import { EShippingPartyTypes } from 'shipment-operations/constants';

import {
  ShippingPartiesFormTitleContainer,
  ShippingPartiesFormTitleStatusContainer,
  ShippingPartiesRemoveButton,
  ShippingPartiesSwitchContainer,
  UnalignedBadge,
} from './ShippingPartiesFormTitle.styled';

interface IShippingPartiesFormComponentProps {
  type: EShippingPartyTypes
  title: string
  isRequired: boolean
  isInDraft: boolean
  isLoading: boolean
  shippingPartiesCurrentData: ShippingPartyDTM
  onShippingPartyRemove: (shippingPartyId?: string) => void
  isAbleToEditCompany: boolean;
}

export const ShippingPartiesFormTitleComponent: FC<IShippingPartiesFormComponentProps> = ({
  title,
  type,
  isRequired,
  isInDraft,
  isLoading,
  shippingPartiesCurrentData,
  onShippingPartyRemove,
  isAbleToEditCompany,
}) => {
  const { t } = useTranslation();
  const { shipmentId } = useParams<SHIPMENT_CONTAINERS>();

  let formStatusType: FormStatusType = 'complete';

  if (
    !shippingPartiesCurrentData.company
    && !shippingPartiesCurrentData.address
    && !shippingPartiesCurrentData.contact
  ) {
    formStatusType = 'incomplete';
  }

  const finalTitle = isRequired ? `${title} *` : `${title} `;

  const onRemoveHandle = useCallback(() => {
    onShippingPartyRemove(shipmentId);
  }, [shipmentId]);

  return (
    <ShippingPartiesFormTitleContainer>
      <ShippingPartiesFormTitleStatusContainer level={5}>
        {!!isInDraft && <span>{finalTitle}<UnalignedBadge color="red" /></span>}
        {!isInDraft && finalTitle}
        <FormStatus type={formStatusType} />
      </ShippingPartiesFormTitleStatusContainer>
      <ShippingPartiesSwitchContainer>
        {type !== EShippingPartyTypes.SHIPPER ? (
          <ShippingPartiesRemoveButton
            disabled={!isAbleToEditCompany}
            icon={<DeleteOutlined />}
            type="dashed"
            size="small"
            onClick={onRemoveHandle}
            loading={isLoading}
          >
            {t('Remove')}
          </ShippingPartiesRemoveButton>
        ) : null}
      </ShippingPartiesSwitchContainer>
    </ShippingPartiesFormTitleContainer>
  );
};
