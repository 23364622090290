import React, {
  ChangeEvent,
  FC,
  useCallback,
} from 'react';
import { useTranslation } from 'react-i18next';

import { InputStyled } from './ShippingNameInput.styled';

interface ShippingNameInputComponentProps {
  cargoId: number;
  shippingName: string;
  touchCargoField: (field: string, cargoId: number) => void
  setShippingName: (value: string, cargoId: number) => void
  error: boolean
}

export const ShippingNameInputComponent: FC<ShippingNameInputComponentProps> = ({
  cargoId,
  shippingName,
  touchCargoField,
  setShippingName,
  error,
}) => {
  const { t } = useTranslation();

  const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setShippingName(event.target.value, cargoId);
  }, [setShippingName, cargoId]);

  const handleBlur = useCallback(() => {
    touchCargoField('shippingName', cargoId);
  }, [touchCargoField, cargoId]);

  return (
    <InputStyled
      placeholder={t('Enter Shipping Name')}
      value={shippingName}
      onChange={handleChange}
      onBlur={handleBlur}
      error={error}
    />
  );
};
