import React, { memo } from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';

import { DashboardsCustomerComponent } from './DashboardsCustomer.component';

const DashboardsCustomerContainer = memo(() => {
  const totalInvoiced = useSelector(R.selectors.overview.getTotalInvoiced);
  const totalInvoicedPercent = useSelector(R.selectors.overview.getTotalInvoicedPercent);
  const totalPaid = useSelector(R.selectors.overview.getTotalPaid);
  const totalPaidPercent = useSelector(R.selectors.overview.getTotalPaidPercent);
  const loading = useSelector(R.selectors.overview.getLoadingStatus);
  const buyTotalCost = useSelector(R.selectors.overview.getBuyTotalCost);
  const apBudgetTotalCost = useSelector(R.selectors.overview.getApBudgetTotalCost);
  const payablesDifferent = useSelector(R.selectors.overview.getPayablesDifferent);
  const payablesDifferentPercent = useSelector(R.selectors.overview.getPayablesDifferentPercent);

  return (
    <DashboardsCustomerComponent
      totalInvoiced={totalInvoiced}
      totalInvoicedPercent={totalInvoicedPercent}
      totalPaid={totalPaid}
      totalPaidPercent={totalPaidPercent}
      loading={loading}
      buyTotalCost={buyTotalCost}
      apBudgetTotalCost={apBudgetTotalCost}
      payablesDifferent={payablesDifferent}
      payablesDifferentPercent={payablesDifferentPercent}
    />
  );
});

export { DashboardsCustomerContainer as DashboardsCustomer };
