import React, {
  ChangeEvent, FC, useCallback, useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
// import Tooltip from 'antd/es/tooltip';

import { Option, Tooltip } from 'app-wrapper/view/components';
import { PlusSvg } from 'app-wrapper/view/icons';

import { PermissionAttributePolicy } from 'shipment-operations/constants';
import { ContainerNumberDTM } from 'shipment-operations/models/dtm';

import {
  ContainerNumberFooter,
  ContainerNumberFooterManualNumber,
  ContainerNumberFooterText,
  ContainerNumberFooterTextWrap,
  ContainerNumberNotFound,
  ContainerNumberSelect, ContainerNumberSelectWrapper, ContainerNumberTextManual,
} from './ShipmentContainerNumberSelectField.styled';

interface IShipmentContainerNumberSelectFieldComponentProps {
  containerNumber?: string
  containerNumberManual?: string
  containerNumberManualError?: string;
  setContainerNumberManualError: (error?: string) => void;
  disabled: boolean
  containerNumberList?: ContainerNumberDTM[]
  onChange: (value: string) => void
  onChangeContainerManualNumber: (value: string) => void
  onAddContainerNumber: () => void
  containersAvailability?: PermissionAttributePolicy
}

export const ShipmentContainerNumberSelectFieldComponent: FC<IShipmentContainerNumberSelectFieldComponentProps> = ({
  containerNumber,
  containerNumberManual,
  containerNumberManualError,
  setContainerNumberManualError,
  disabled,
  containerNumberList,
  onChange,
  onChangeContainerManualNumber,
  onAddContainerNumber,
  containersAvailability,
}) => {
  const { t } = useTranslation();

  const handleChange = useCallback((value: string) => {
    onChange(value);
  }, []);

  const onChangeContainerManualNumberHandle = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    onChangeContainerManualNumber(event.target.value);
  }, []);

  const handleDropdownVisibilityChange = useCallback((open: boolean) => {
    if (!open) {
      setContainerNumberManualError(undefined);
    }
  }, [setContainerNumberManualError]);

  const isDisabled = useMemo(() => containersAvailability !== PermissionAttributePolicy.WRITE, [containersAvailability]);

  const dropdownRender = useCallback((menu) => (
    <>
      {menu}
      <ContainerNumberFooter>
        <Tooltip
          isGetPopupContainerDefault
          visible={!!containerNumberManualError}
          mode="danger"
          placement="top"
          title={t(`${containerNumberManualError}`)}
        >
          <ContainerNumberFooterManualNumber
            error={!!containerNumberManualError}
            value={containerNumberManual}
            onChange={onChangeContainerManualNumberHandle}
            placeholder={t('Enter manual number')}
          />
        </Tooltip>

        <ContainerNumberFooterTextWrap onClick={onAddContainerNumber}>
          <PlusSvg
            style={{ color: '#6C79DE' }}
            height={9}
            width={9}
          />
          <ContainerNumberFooterText>{t('Add Number')}</ContainerNumberFooterText>
        </ContainerNumberFooterTextWrap>
      </ContainerNumberFooter>
    </>
  ), [onAddContainerNumber, containerNumberManual, containerNumberManualError]);

  return (
    <ContainerNumberSelectWrapper>
      <span>{t('Container Number')}</span>
      <ContainerNumberSelect
        allowClear
        value={containerNumber}
        disabled={isDisabled || disabled}
        onChange={handleChange}
        placeholder={t('Please select')}
        notFoundContent={(
          <ContainerNumberNotFound>
            {!containerNumber ? t('Container Numbers will be available once provided by Carrier') : null}
          </ContainerNumberNotFound>
        )}
        dropdownRender={dropdownRender}
        onDropdownVisibleChange={handleDropdownVisibilityChange}
      >
        {
          containerNumberList && containerNumberList.map((item) => (
            <Option key={item.number} value={item.number}>
              {item.number}
              {item.isManual ? (<ContainerNumberTextManual>{`(${t('Manually added')})`}</ContainerNumberTextManual>) : ''}
            </Option>
          ))
        }
      </ContainerNumberSelect>
    </ContainerNumberSelectWrapper>
  );
};
