import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';
import { UC } from 'shipment-operations/controllers';

import { CompanySectionComponent } from './CompanySection.component';

interface CompanySectionProps {
  hideDrawerButtons?: boolean;
}

const CompanySectionContainer: FC<CompanySectionProps> = ({ hideDrawerButtons }) => {
  const currentCompany = useSelector(R.selectors.shippingParties.getSelectedCurrentCompany);
  const selectedShippingParty = useSelector(R.selectors.shippingParties.getSelectedFormType);
  const permissionsForRole = useSelector(R.selectors.shippingParties.getShippingPartyPermissionsByRole(selectedShippingParty));

  return (
    <CompanySectionComponent
      company={currentCompany}
      openSelectCompanyDrawer={UC.shippingParties.openSelectCompanyDrawer}
      hideDrawerButtons={hideDrawerButtons}
      isAbleToEditCompany={permissionsForRole.isAbleToEditCompany}
    />
  );
};

const CompanySectionContainerCached = React.memo(CompanySectionContainer);

export { CompanySectionContainerCached as CompanySection };
