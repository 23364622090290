import styled from 'styled-components';

import { divFlex } from 'app-wrapper/view/themes/mixin';

export const FreightQuoteContentModalSave = styled.div.attrs({
  'data-class': 'FreightQuoteContentModalSave',
})`
  ${divFlex}
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 200;
  width: 100vw;
  height: 100vh;
`;

export const FreightQuoteContentModalSaveWrap = styled.div.attrs({
  'data-class': 'FreightQuoteContentModalSaveWrap',
})`
  ${divFlex}
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  background-color: rgba(0, 0, 0, 0.45);
`;

export const FreightQuoteContentModalSaveContent = styled.div.attrs({
  'data-class': 'FreightQuoteContentModalSaveContent',
})`
  ${divFlex}
  flex-direction: column;
  width: 416px;
  height: 200px;
  border-radius: 3px;
  align-items: start;
  justify-content: center;
  padding: 32px 32px 24px 32px;

  background-color: ${({ theme }) => theme.themesColors.neutralBranding1};
`;

export const FreightQuoteContentModalSaveContentHeader = styled.div.attrs({
  'data-class': 'FreightQuoteContentModalSaveContentHeader',
})`
  ${divFlex}
  align-items: center;
  color: ${({ theme }) => theme.themesColors.neutralBranding10};
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 8px;
`;

export const FreightQuoteContentModalSaveContentTitle = styled.div.attrs({
  'data-class': 'FreightQuoteContentModalSaveContentTitle',
})`
  ${divFlex}
  align-items: center;
  color: ${({ theme }) => theme.themesColors.neutralBranding10};
  font-size: 14px;
  font-weight: 400;
`;

export const FreightQuoteContentModalSaveContentFooter = styled.div.attrs({
  'data-class': 'FreightQuoteContentModalSaveContentFooter',
})`
  ${divFlex}
  align-items: center;
  color: ${({ theme }) => theme.themesColors.neutralBranding10};
  font-size: 14px;
  font-weight: 400;

  margin-left: auto;
`;

export const FreightQuoteContentModalSaveContentFooterBtnWrap = styled.div.attrs({
  'data-class': 'FreightQuoteContentModalSaveContentFooterBtnWrap',
})`
  ${divFlex}

  margin-left: 8px;
`;

export const FreightQuoteContentModalSaveContentFooterDivider = styled.div.attrs({
  'data-class': 'FreightQuoteContentModalSaveContentFooterDivider',
})`
  ${divFlex}

  margin-left: 8px;
`;

export const FreightQuoteContentModalSaveContentName = styled.div.attrs((props: {
  'data-class'?: string
}) => ({
  'data-class': props?.['data-class'] || 'FreightQuoteContentModalSaveContentName',
}))`
  ${divFlex}

  width: 100%;
  margin-top: 4px;
  margin-bottom: 24px;
`;
