import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ShipmentPreviewDTM } from 'shipment-operations/models/dtm';

import {
  Info,
  InfoCol,
  InfoDate,
  InfoRow,
  InfoShipmentName,
  DividerIcon,
  ShipmentReference,
} from './ShipmentInfo.styled';

interface IShipmentInfoProps {
  shipment?: ShipmentPreviewDTM | null;
  borderless?: boolean;
}

export const ShipmentInfo: FC<IShipmentInfoProps> = ({ shipment, borderless }) => {
  const { t } = useTranslation();

  const originName = useMemo(() => (shipment?.origin ? shipment?.origin.address : ''), [shipment]);
  const originDate = useMemo(() => (shipment?.origin && shipment?.origin.estimatedDate ? shipment?.origin.estimatedDate.getFormatDMMMHHmmWithOffset() : ''), [shipment]);
  const destinationName = useMemo(() => (shipment?.destination ? shipment?.destination.address : ''), [shipment]);
  const destinationDate = useMemo(() => (shipment?.destination && shipment?.destination.estimatedDate ? shipment?.destination.estimatedDate.getFormatDMMMHHmmWithOffset() : ''), [shipment]);

  if (!shipment) {
    return null;
  }

  return (
    <Info
      style={{
        ...(borderless ? { border: 'none' } : {}),
      }}
    >
      <InfoCol>
        <InfoShipmentName>
          {`${t('Shipment: ')}${shipment?.shipmentName}`}
        </InfoShipmentName>

        <InfoRow>
          {originName}

          <InfoDate>
            {t('ETD ')}
            {originDate}
          </InfoDate>

          <DividerIcon />

          {destinationName}

          <InfoDate>
            {t('ETA ')}
            {destinationDate}
          </InfoDate>
        </InfoRow>
      </InfoCol>

      {shipment?.reference ? (
        <ShipmentReference>
          {shipment?.reference}
        </ShipmentReference>
      ) : null}
    </Info>
  );
};
