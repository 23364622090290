import {
  IsEnum, IsString, IsDefined,
} from 'class-validator';
import { BaseDTM } from 'proto/BaseDTM';

export enum ValidationErrorType {
  DEFAULT = 'DEFAULT',
  ALERT = 'ALERT'
}

export interface IValidationErrorDTM {
  type: ValidationErrorType
  message: string
}

export class ValidationErrorDTM extends BaseDTM<IValidationErrorDTM> {
  @IsEnum(ValidationErrorType)
  @IsDefined()
  type: ValidationErrorType;

  @IsString()
  @IsDefined()
  message: string;
}
