import React, { FC, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import Tooltip from 'antd/es/tooltip';

import i18next from 'app-wrapper/i18n/i18n';
import { ShippingPartyOverviewDTM, IRouteLegDTM } from 'shipment-operations/models/dtm';
import { RouteNames } from 'app-wrapper/constants';

import { ShippingPartyInfo } from './components';
import {
  SBLShippingParties,
  SBLShippingPartiesItem,
  SBLShippingPartiesItemText,
  SBLShippingPartiesItemTitle,
  SBLShippingPartiesTitle,
  SBLWarningIcon,
  ShippingPartiesRow,
  SBLNoDataStub,
  EditIcon,
} from './SBLShippingParties.styled';

interface ISBLShippingPartiesComponentProps {
  notifyParty: ShippingPartyOverviewDTM;
  forwardingAgent: ShippingPartyOverviewDTM;
  shipper: ShippingPartyOverviewDTM;
  consignee: ShippingPartyOverviewDTM;
  portOfDischarge?: IRouteLegDTM['arrivalLocation'];
  shipmentId: string;
  isEditable: boolean;
  isCustomerOrg: boolean;
  openEditShippingPartyDrawer: (shipmentId: string, shippingPartyId?: number) => void;
}

const REQUIRED_TAX_ID_WARNING = i18next.t('Tax ID must be specified');

export const SBLShippingPartiesComponent: FC<ISBLShippingPartiesComponentProps> = ({
  consignee,
  shipper,
  notifyParty,
  forwardingAgent,
  portOfDischarge,
  shipmentId,
  isCustomerOrg,
  isEditable,
  openEditShippingPartyDrawer,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const isPortAtChina = portOfDischarge ? portOfDischarge.country && portOfDischarge.country.name === 'China' : false;

  const isShipperTaxIdMissing = useMemo(() => isPortAtChina && !shipper.taxId, [shipper, isPortAtChina]);
  const isConsigneeTaxIdMissing = useMemo(() => isPortAtChina && !consignee.taxId, [consignee, isPortAtChina]);
  const isShipperEmpty = useMemo(() => !shipper.phone && !shipper.email && !shipper.companyName, [shipper]);
  const isConsigneeEmpty = useMemo(() => !consignee.phone && !consignee.email && !consignee.companyName, [consignee]);
  const isNotifyPartyEmpty = useMemo(() => !notifyParty.phone && !notifyParty.email && !notifyParty.companyName, [notifyParty]);
  const isForwardingAgentEmpty = useMemo(() => !forwardingAgent.phone && !forwardingAgent.email && !forwardingAgent.companyName, [forwardingAgent]);

  const goToShippingParties = useCallback(() => {
    navigate(RouteNames.SHIPMENT_SHIPPING_PARTIES(shipmentId));
  }, [navigate, shipmentId]);

  return (
    <SBLShippingParties>
      <SBLShippingPartiesTitle>
        {t('Shipping Parties')}
      </SBLShippingPartiesTitle>

      <ShippingPartiesRow>
        <SBLShippingPartiesItem>
          <SBLShippingPartiesItemTitle>
            {t('Shipper')}

            {isShipperTaxIdMissing ? (
              <Tooltip title={REQUIRED_TAX_ID_WARNING} placement="top">
                <SBLWarningIcon />
              </Tooltip>
            ) : null}

            {isEditable && !isShipperEmpty && !isCustomerOrg ? (
              <EditIcon onClick={() => openEditShippingPartyDrawer(shipmentId, shipper.id)} />
            ) : null}
          </SBLShippingPartiesItemTitle>

          <SBLShippingPartiesItemText>
            {isShipperEmpty ? (
              <SBLNoDataStub onAddData={isEditable ? goToShippingParties : undefined} />
            ) : <ShippingPartyInfo shippingParty={shipper} />}
          </SBLShippingPartiesItemText>
        </SBLShippingPartiesItem>

        <SBLShippingPartiesItem>
          <SBLShippingPartiesItemTitle>
            {t('Consignee')}

            {isConsigneeTaxIdMissing ? (
              <Tooltip title={REQUIRED_TAX_ID_WARNING} placement="top">
                <SBLWarningIcon />
              </Tooltip>
            ) : null}

            {isEditable && !isConsigneeEmpty ? (
              <EditIcon onClick={() => openEditShippingPartyDrawer(shipmentId, consignee.id)} />
            ) : null}
          </SBLShippingPartiesItemTitle>

          <SBLShippingPartiesItemText>
            {isConsigneeEmpty ? (
              <SBLNoDataStub onAddData={isEditable ? goToShippingParties : undefined} />
            ) : <ShippingPartyInfo shippingParty={consignee} />}
          </SBLShippingPartiesItemText>
        </SBLShippingPartiesItem>

        <SBLShippingPartiesItem>
          <SBLShippingPartiesItemTitle>
            {t('Notify')}

            {isEditable && !isNotifyPartyEmpty ? (
              <EditIcon onClick={() => openEditShippingPartyDrawer(shipmentId, notifyParty.id)} />
            ) : null}
          </SBLShippingPartiesItemTitle>

          <SBLShippingPartiesItemText>
            {isNotifyPartyEmpty ? (
              <SBLNoDataStub onAddData={isEditable ? goToShippingParties : undefined} />
            ) : <ShippingPartyInfo shippingParty={notifyParty} />}
          </SBLShippingPartiesItemText>
        </SBLShippingPartiesItem>

        <SBLShippingPartiesItem>
          <SBLShippingPartiesItemTitle>
            {t('Forwarder')}

            {isEditable && !isForwardingAgentEmpty && !isCustomerOrg ? (
              <EditIcon onClick={() => openEditShippingPartyDrawer(shipmentId, forwardingAgent.id)} />
            ) : null}
          </SBLShippingPartiesItemTitle>

          <SBLShippingPartiesItemText>
            {isForwardingAgentEmpty ? (
              <SBLNoDataStub onAddData={isEditable ? goToShippingParties : undefined} />
            ) : <ShippingPartyInfo shippingParty={forwardingAgent} />}
          </SBLShippingPartiesItemText>
        </SBLShippingPartiesItem>
      </ShippingPartiesRow>
    </SBLShippingParties>
  );
};
