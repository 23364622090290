import React, { FC } from 'react';

import { NoDataStub } from 'shipment-operations/view/components';

import {
  MBLTextInfo,
  MBLTextInfoTitle,
  MBLTextInfoText,
} from './MBLTextInfo.styled';

interface MBLTextInfoComponentProps {
  title: string;
  text: string;
}

export const MBLTextInfoComponent: FC<MBLTextInfoComponentProps> = ({ title, text }) => (
  <MBLTextInfo>
    <MBLTextInfoTitle>
      {title}
    </MBLTextInfoTitle>

    {text ? (
      <MBLTextInfoText>
        {text}
      </MBLTextInfoText>
    ) : <NoDataStub />}
  </MBLTextInfo>
);
