import React from 'react';

import { IPropsIcon } from 'app-wrapper/interfaces/icon';

import { Icon } from './SkypaceBgLogo2Svg.styled';

export const SkypaceBgLogo2Svg = (props: IPropsIcon) => {
  const { width, height, fill } = props;
  return (
    <Icon
      className="SkypaceBgLogo2Svg"
      width={width || '130'}
      height={height || '223'}
      viewBox="0 0 130 223"
      fill={fill || 'currentColor'}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M112 223C173.58 223 223.5 173.08 223.5 111.5C223.5 49.9202 173.58 0 112 0C50.4202 0 0.5 49.9202 0.5 111.5C0.5 173.08 50.4202 223 112 223Z" fill="url(#paint0_linear_20432_15538)" />
      <path d="M147.965 162.221H97.6802C94.6194 162.221 92.2145 159.816 92.2145 156.755C92.2145 153.695 94.6194 151.29 97.6802 151.29H147.965C156.71 151.29 163.924 143.419 163.924 133.8C163.924 124.18 156.71 116.309 147.965 116.309H77.5665C62.6998 116.309 50.6753 103.629 50.6753 87.8878C50.6753 72.1466 62.6998 59.4662 77.5665 59.4662H126.758C129.818 59.4662 132.223 61.8711 132.223 64.9319C132.223 67.9927 129.818 70.3976 126.758 70.3976H77.5665C68.8214 70.3976 61.6067 78.2681 61.6067 87.8878C61.6067 97.5074 68.8214 105.378 77.5665 105.378H147.965C162.831 105.378 174.856 118.058 174.856 133.8C174.856 149.541 162.831 162.221 147.965 162.221Z" fill="white" />
      <path d="M66.0899 165.283C70.316 165.283 73.7419 161.857 73.7419 157.631C73.7419 153.405 70.316 149.979 66.0899 149.979C61.8639 149.979 58.438 153.405 58.438 157.631C58.438 161.857 61.8639 165.283 66.0899 165.283Z" fill="white" />
      <defs>
        <linearGradient id="paint0_linear_20432_15538" x1="112" y1="0" x2="112" y2="223" gradientUnits="userSpaceOnUse">
          <stop stopColor="#F4F5F8" />
          <stop offset="1" stopColor="#F4F5F8" stopOpacity="0.1" />
        </linearGradient>
      </defs>
    </Icon>
  );
};
