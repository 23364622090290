import React from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';

import { HeaderComponent } from './Header.component';

const HeaderContainer = () => {
  const originDate = useSelector(R.selectors.rollShipmentWizard.getOriginDate);
  const destinationDate = useSelector(R.selectors.rollShipmentWizard.getDestinationDate);
  const originLocationName = useSelector(R.selectors.rollShipmentWizard.getOriginLocationName);
  const destinationLocationName = useSelector(R.selectors.rollShipmentWizard.getDestinationLocationName);
  const totalCost = useSelector(R.selectors.rollShipmentWizard.getShipmentAppliedChargesTotalCost);

  return (
    <HeaderComponent
      originDate={originDate}
      destinationDate={destinationDate}
      originLocationName={originLocationName}
      destinationLocationName={destinationLocationName}
      totalCost={totalCost}
    />
  );
};

export { HeaderContainer as Header };
