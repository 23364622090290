import {
  IsDefined,
  ValidateNested,
  IsString,
  IsEnum, IsNumber, IsArray, IsOptional,
} from 'class-validator';
import { Type } from 'class-transformer';
import { BaseDTM } from 'proto/BaseDTM';

import {
  EShipmentOrganizationRole,
  EOrganizationMemberRole,
  EAccountDepartmentType,
  DEPARTMENT_TYPE_NAMES,
} from 'user-management/constants';
import { EWorkgroupTypesEnum } from 'shipment-operations/constants';

export interface IWorkgroupOrganizationDTM {
  id: number;
  shipmentRoles: EShipmentOrganizationRole[];
  organizationRole: EOrganizationMemberRole;
}

export class WorkgroupOrganizationDTM extends BaseDTM<IWorkgroupOrganizationDTM> {
  @IsDefined()
  @IsNumber()
  id: number;

  @IsDefined()
  @IsArray()
  shipmentRoles: EShipmentOrganizationRole[];

  @IsDefined()
  @IsEnum(EOrganizationMemberRole)
  organizationRole: EOrganizationMemberRole;
}

export interface IWorkgroupMemberDTM {
  id: number;
  workgroupId: number;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  jobTitle: string;
  role: EAccountDepartmentType;
  organization: IWorkgroupOrganizationDTM;
}

export class WorkgroupMemberDTM extends BaseDTM<IWorkgroupMemberDTM> {
  @IsDefined()
  @IsNumber()
  id: number;

  @IsDefined()
  @IsNumber()
  workgroupId: number;

  @IsDefined()
  @IsString()
  firstName: string;

  @IsDefined()
  @IsString()
  lastName: string;

  @IsDefined()
  @IsString()
  email: string;

  @IsDefined()
  @IsString()
  phone: string;

  @IsDefined()
  @IsString()
  jobTitle: string;

  @IsDefined()
  @IsEnum(EAccountDepartmentType)
  role: EAccountDepartmentType;

  @IsDefined()
  @ValidateNested()
  @Type(() => WorkgroupOrganizationDTM)
  organization: WorkgroupOrganizationDTM;

  public getFirstLetterOfName = () => (this.firstName.length ? this.firstName[0].toUpperCase() : '');

  public getRoleName = () => {
    if (!this.role) {
      return '';
    }

    return DEPARTMENT_TYPE_NAMES[this.role];
  }

  public getFullName = () => `${this.firstName} ${this.lastName}`.trim();
}

export interface IWorkgroupDTM {
  type: EWorkgroupTypesEnum;
  name: string;
  members: IWorkgroupMemberDTM[];
  organizationId?: number;
}

export class WorkgroupDTM extends BaseDTM<IWorkgroupDTM> {
  @IsDefined()
  @IsEnum(EWorkgroupTypesEnum)
  type: EWorkgroupTypesEnum;

  @IsDefined()
  @IsString()
  name: string;

  @IsDefined()
  @ValidateNested({ each: true })
  @Type(() => WorkgroupMemberDTM)
  members: WorkgroupMemberDTM[];

  @IsOptional()
  @IsNumber()
  organizationId?: number;
}
