import { useEffect, useMemo, useState } from 'react';

interface WindowSize {
  width: number
  height: number
}

interface IReturn {
  windowSize: WindowSize
  isFullWidth: boolean
  isLargeWidth: boolean
  isMediumWidth: boolean
  isFullMediaWidth: boolean
  isFullMediaWidthMax: boolean
  isMediumMediaWidthMax: boolean
}

export const useWindowSize = (): IReturn => {
  const [windowSize, setWindowSize] = useState<WindowSize>({
    width: 0,
    height: 0,
  });

  useEffect(() => {
    const handler = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    handler();

    window.addEventListener('resize', handler);

    return () => {
      window.removeEventListener('resize', handler);
    };
  }, []);

  const isFullWidth = useMemo(() => {
    const { width } = windowSize;
    return width >= 1920;
  }, [windowSize]);

  const isFullMediaWidth = useMemo(() => {
    const { width } = windowSize;
    return width >= 1600;
  }, [windowSize]);

  const isFullMediaWidthMax = useMemo(() => {
    const { width } = windowSize;
    return width < 1600;
  }, [windowSize]);

  const isMediumMediaWidthMax = useMemo(() => {
    const { width } = windowSize;
    return width < 1200;
  }, [windowSize]);

  const isLargeWidth = useMemo(() => {
    const { width } = windowSize;
    return width >= 1440;
  }, [windowSize]);

  const isMediumWidth = useMemo(() => {
    const { width } = windowSize;
    return width >= 1366;
  }, [windowSize]);

  return {
    windowSize,
    isFullWidth,
    isLargeWidth,
    isMediumWidth,
    isFullMediaWidth,
    isFullMediaWidthMax,
    isMediumMediaWidthMax,
  };
};
