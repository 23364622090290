import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { UC } from 'shipment-operations/controllers';
import { R } from 'shipment-operations/repository';

import { ContactsContactPersonBlockComponent } from 'user-management/view/components';
import { ICompanyContactDTM } from 'user-management/models/dtm';

interface IContactPersonItemContainerProps {
  largeInput: boolean
  personItem: ICompanyContactDTM
  contactIndex: number
  companyListStateIndex: string
  isFirst: boolean
  isLast: boolean
}

export const ContactPersonItemContainer: FC<IContactPersonItemContainerProps> = (props) => {
  const {
    largeInput,
    personItem,
    contactIndex,
    companyListStateIndex,
    isFirst,
    isLast,
  } = props;

  const errors = useSelector(R.selectors.bookingWizard.getContactBookUpdateErrorsContactPersonsById(personItem?.customId || ''));

  const validateAll = UC.bookingWizardDrawer.validateFullShipmentPartyCompany;
  const validateOnly = UC.bookingWizardDrawer.validateOnlyShipmentPartyCompany;
  const validateCompare = UC.bookingWizardDrawer.validateCompareShipmentPartyCompany;

  return (
    <ContactsContactPersonBlockComponent
      largeInput={largeInput}
      isFirst={isFirst}
      isLast={isLast}
      contactPerson={personItem}
      contactIndex={contactIndex}
      companyListStateIndex={companyListStateIndex}
      errors={errors}
      onChangeContactPerson={UC.bookingWizardDrawer.onChangeUpdateContactsContactPersonByIndex(validateCompare)}
      onBlurContactPerson={UC.bookingWizardDrawer.onBlurUpdateContactsContactPersonByIndex(validateOnly)}
      onFocusContactPerson={UC.bookingWizardDrawer.onFocusUpdateContactsContactPersonByIndex(validateOnly)}
      onChangeAdditionalPhone={UC.bookingWizardDrawer.onChangeUpdateContactsAdditionalPhoneByIndex(validateCompare)}
      onBlurAdditionalPhone={UC.bookingWizardDrawer.onBlurUpdateContactsAdditionalPhoneByIndex(validateOnly)}
      onFocusAdditionalPhone={UC.bookingWizardDrawer.onFocusUpdateContactsAdditionalPhoneByIndex(validateOnly)}
      onChangeEmail={UC.bookingWizardDrawer.onChangeUpdateContactsEmailByIndex(validateCompare)}
      onBlurEmail={UC.bookingWizardDrawer.onBlurUpdateContactsEmailByIndex(validateOnly)}
      onFocusEmail={UC.bookingWizardDrawer.onFocusUpdateContactsEmailByIndex(validateOnly)}
      onChangePhone={UC.bookingWizardDrawer.onChangeUpdateContactsPhoneByIndex(validateCompare)}
      onBlurPhone={UC.bookingWizardDrawer.onBlurUpdateContactsPhoneByIndex(validateOnly)}
      onFocusPhone={UC.bookingWizardDrawer.onFocusUpdateContactsPhoneByIndex(validateOnly)}
      addContactPersonItem={UC.bookingWizardDrawer.setUpdateAddCompanyListStateContactPersonByIndex(validateAll)}
      onRemoveContactPerson={UC.bookingWizardDrawer.setUpdateRemoveCompanyListStateContactPersonItem(validateAll)}
    />
  );
};

export { ContactPersonItemContainer as ContactPersonItem };
