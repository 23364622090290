interface IIsInViewport {
  top?: number
  bottom?: number
  left?: number
  right?: number
}

export const isInViewport = (element: HTMLElement, props?: IIsInViewport) => {
  if (!element) return false;
  const {
    bottom, top, left, right,
  } = props || {};
  const rect = element.getBoundingClientRect();
  return (
    rect.top >= 0 - (top || 0)
    && rect.left >= 0 - (left || 0)
    && rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) - (bottom || 0)
    && rect.right <= (window.innerWidth || document.documentElement.clientWidth) - (right || 0)
  );
};
