import { routesActions, routesReducer, routesSelectors } from 'app-wrapper/repository/store/Routes';
import { commandCenterActions, commandCenterReducer, commandCenterSelectors } from './CommandCenter';
import { countriesListActions, countriesListReducer, countriesListSelectors } from './CountriesList';
import { homeActions, homeReducer, homeSelectors } from './Home';
import { overviewActions, overviewReducer, overviewSelectors } from './Overview';
import { agreementsActions, agreementsReducer, agreementsSelectors } from './Agreements';
import { vgmTaskDrawerActions, vgmTaskDrawerReducer, vgmTaskDrawerSelectors } from './VGMTaskDrawer';
import { railBillingDrawerSelectors, railBillingDrawerReducer, railBillingDrawerActions } from './RailBillingDrawer';

export const actions = {
  countriesList: countriesListActions,
  commandCenter: commandCenterActions,
  home: homeActions,
  overview: overviewActions,
  routes: routesActions,
  agreements: agreementsActions,
  vgmTaskDrawer: vgmTaskDrawerActions,
  railBillingDrawer: railBillingDrawerActions,
};

export const reducers = {
  countriesList: countriesListReducer,
  commandCenter: commandCenterReducer,
  home: homeReducer,
  overview: overviewReducer,
  routes: routesReducer,
  agreements: agreementsReducer,
  vgmTaskDrawer: vgmTaskDrawerReducer,
  railBillingDrawer: railBillingDrawerReducer,
};

export const selectors = {
  countriesList: countriesListSelectors,
  commandCenter: commandCenterSelectors,
  home: homeSelectors,
  overview: overviewSelectors,
  routes: routesSelectors,
  agreements: agreementsSelectors,
  vgmTaskDrawer: vgmTaskDrawerSelectors,
  railBillingDrawer: railBillingDrawerSelectors,
};
