import AntTable from 'antd/es/table';
import styled from 'styled-components';

import { YellowTag } from 'app-wrapper/view/guideline';

export const TableCell = styled.span``;

export const MissingValueWrap = styled.div`
  width: 32px;
  margin-left: auto;
`;

export const TableCellNumber = styled(TableCell)`
  text-transform: capitalize;
`;

export const TableCellDifference = styled(TableCell)`
  * {
    width: fit-content;
  }
`;

export const Wrap = styled.div``;

export const DesignationHeader = styled.div`
  margin-bottom: 8px;
  padding: 2px 16px;
  border-radius: 3px;
  background-color: rgba(249, 249, 251, 0.6);
  color: rgba(115, 129, 155, 1);
  font-size: 12px;
  line-height: 20px;
`;

export const TableWrap = styled.div`
  padding-left: 16px;
  margin-top: -8px;
  background: white;
`;

export const CustomTable = styled(AntTable)`
  .ant-table-content table {
    table-layout: fixed !important;
  }

  .ant-table-tbody .ant-table-cell {
    background-color: white !important;
    color: rgba(32, 44, 60, 1);
    font-size: 14px;
    line-height: 22px;

    &:last-of-type {
      padding-right: 0;
    }
  }

  .ant-table-row .ant-table-cell {
    border-bottom: 1px solid rgba(234, 238, 248, 1) !important;
  }

  .ant-table-row:last-of-type .ant-table-cell {
    border-bottom: 0 !important;
  }
`;

export const Tag = styled(YellowTag)`
  margin-left: 4px;
  height: 22px;
  line-height: 20px;
`;
