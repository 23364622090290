import styled, { css } from 'styled-components';

import { UserManagementFormFooter } from 'user-management/view/components';

import { divFlex } from 'app-wrapper/view/themes/mixin';
import { PageHeader, Input, Button } from 'app-wrapper/view/components';
import { emptyScrollbar } from 'app-wrapper/view/themes/components';

const titleCss = css`
  font-size: 12px;
  font-weight: 400;
  color: ${({ theme }) => theme?.themesColors?.neutralBranding10};
`;

const inputCss = css`
  font-size: 12px;
  font-weight: 400;
  color: ${({ theme }) => theme?.themesColors?.neutralBranding10};
  height: 32px;
`;

const inputLargeCss = css`
  height: 40px;
`;

const inputTopMarginCss = css`
  margin-top: 6px;
`;

export const SPAddAddressDividerMargins = {
  margin: '0',
  marginTop: '16px',
};

export const SPAddAddressWrapper = styled.div.attrs({
  'data-class': 'SPAddAddressWrapper',
})`
  ${divFlex}
  flex-direction: column;

  background-color: ${({ theme }) => theme?.themesColors?.neutralBranding3};
  height: 100%;
`;

export const SPAddAddressContent = styled.div.attrs({
  'data-class': 'SPAddAddressContent',
})`
  ${divFlex}
  flex-direction: column;

  height: 100%;

  padding: 0 24px;
`;

export const SPAddAddressContentBody = styled.div.attrs({
  'data-class': 'SPAddAddressContentBody',
})`
  ${divFlex}

  height: 100%;
  width: 100%;
  max-width: 1179px;
`;

export const SPAddAddressContentBodyList = styled.div.attrs({
  'data-class': 'SPAddAddressContentBodyList',
})`
  ${divFlex}
  ${emptyScrollbar}
  flex-direction: column;

  overflow-y: auto;

  height: 100%;
  max-height: calc(100vh - 48px - 56px - 54px);
  width: 100%;
  padding-bottom: 16px;
  padding-top: 24px;

  > div {
    margin-top: 6px;
  }
  > div:first-child {
    margin-top: 0;
  }
`;

export const SPAddAddressContentBodyFormWrap = styled.div.attrs({
  'data-class': 'SPAddAddressContentBodyFormWrap',
})`
  ${divFlex}
  ${emptyScrollbar}
  flex-direction: column;

  background-color: ${({ theme }) => theme?.themesColors?.neutralBranding3};
  height: 100%;
  width: 100%;
  padding: 16px 24px 0 24px;
`;

export const SPAddAddressContentBodyForm = styled.div.attrs({
  'data-class': 'SPAddAddressContentBodyForm',
})`
  ${divFlex}
  ${emptyScrollbar}
  flex-direction: column;

  height: 100%;
  width: 100%;

  background-color: ${({ theme }) => theme?.themesColors?.neutralBranding1};
  border: 1px solid ${({ theme }) => theme?.themesColors?.neutralBranding4};
  border-radius: 3px;
`;

export const SPAddAddressContentBodyFormWrapper = styled.div.attrs({
  'data-class': 'SPAddAddressContentBodyFormWrapper',
})`
  ${divFlex}
  ${emptyScrollbar}
  flex-direction: column;
  overflow-y: auto;

  height: calc(100% - 64px);
  width: 100%;

  background-color: ${({ theme }) => theme?.themesColors?.neutralBranding1};
  padding: 16px;
`;

export const SPAddAddressContentBodyFormFooter = styled(UserManagementFormFooter).attrs({
  'data-class': 'SPAddAddressContentBodyFormFooter',
})`
  width: 100%;
`;

export const SPAddAddressContentBodyFormCompanyFirstBlock = styled.div.attrs({
  'data-class': 'SPAddAddressContentBodyFormCompanyFirstBlock',
})`
  ${divFlex}
  width: 100%;
`;

export const ContactBookContactPersonItemDeleteButton = styled(Button).attrs({
  'data-class': 'ContactBookContactPersonItemDeleteButton',
})`
  ${divFlex}
  ${titleCss}

  height: 22px;
  align-items: center;
  justify-content: center;
  width: 82px;
  right: 0;
  margin-left: auto;
`;

export const SPAddAddressContentBodyFormCompanyName = styled.div.attrs({
  'data-class': 'SPAddAddressContentBodyFormCompanyName',
})`
  ${divFlex}
  flex-direction: column;
  width: calc(50% - 2px);
`;

export const SPAddAddressContentBodyFormCompanyNameTitleWrapper = styled.div.attrs({
  'data-class': 'SPAddAddressContentBodyFormCompanyNameTitleWrapper',
})`
  ${divFlex}
  width: 100%;
  height: 22px;
`;

export const SPAddAddressContentBodyFormCompanyNameTitle = styled.div.attrs({
  'data-class': 'SPAddAddressContentBodyFormCompanyNameTitle',
})`
  ${divFlex}
  ${titleCss}
`;

export const SPAddAddressContentBodyFormCompanyNameInput = styled(Input).attrs((props: {
  hasError: boolean,
  largeInput: boolean,
}) => ({
  hasError: props.hasError,
  largeInput: props.largeInput,
  'data-class': 'SPAddAddressContentBodyFormCompanyNameInput',
}))`
  ${divFlex}
  ${inputCss}
  ${inputTopMarginCss}

  ${({ largeInput }) => largeInput && inputLargeCss};
  margin-top: 4px;
  width: 100%;

  ${({ hasError, theme }) => hasError && `border-color: ${theme.themesColors.characterBrandingDanger} !important; border-style: solid;`};
`;

export const SPAddAddressContentBodyFormCompanyTaxId = styled.div.attrs({
  'data-class': 'SPAddAddressContentBodyFormCompanyTaxId',
})`
  ${divFlex}
  flex-direction: column;
  width: calc(50% - 2px);
  margin-left: 4px;
`;

export const SPAddAddressContentBodyFormCompanyTaxIdTitleWrapper = styled.div.attrs({
  'data-class': 'SPAddAddressContentBodyFormCompanyTaxIdTitleWrapper',
})`
  ${divFlex}
  width: 100%;
  height: 22px;
`;

export const SPAddAddressContentBodyFormCompanyTaxIdTitle = styled.div.attrs({
  'data-class': 'SPAddAddressContentBodyFormCompanyTaxIdTitle',
})`
  ${divFlex}
  ${titleCss}
`;

export const SPAddAddressContentBodyFormCompanyTaxIdInput = styled(Input).attrs((props: {
  hasError: boolean,
  largeInput: boolean,
}) => ({
  hasError: props.hasError,
  largeInput: props.largeInput,
  'data-class': 'SPAddAddressContentBodyFormCompanyTaxIdInput',
}))`
  ${divFlex}
  ${inputCss}
  ${inputTopMarginCss}

  ${({ largeInput }) => largeInput && inputLargeCss};
  margin-top: 4px;
  width: 100%;

  ${({ hasError, theme }) => hasError && `border-color: ${theme.themesColors.characterBrandingDanger} !important; border-style: solid;`};
`;

export const SPAddAddressLayoutHeader = styled(PageHeader).attrs({
  'data-class': 'SPAddAddressLayoutHeader',
})`
  width: 100%;
  height: 56px;
  padding-left: 24px;

  .ant-page-header-heading {
    ${divFlex}
    align-items: center;
    height: 100%;
  }

  .ant-page-header-heading-title {
    font-size: 20px;
    font-weight: 500;
  }
`;
