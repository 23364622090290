import React, {
  FC, memo, SyntheticEvent, useCallback, useMemo, useRef, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useNavigate } from 'react-router-dom';

import { CollapseOnChangeHandler } from 'app-wrapper/hooks';
import { CommandCenterComponentTaskDTM } from 'app-wrapper/models/dtm';
import themesColors from 'app-wrapper/view/themes/themesColors';
import {
  Collapse, Panel, Tooltip, TableSkeleton,
} from 'app-wrapper/view/components';
import {
  VerticalFormItemSvg,
} from 'app-wrapper/view/icons';
import { sanitizeHtml } from 'app-wrapper/utils/sanitizeHtml';
import { RouteNames } from 'app-wrapper/constants';
import { ETasksType } from 'shipment-operations/constants';

import {
  CCContentTableContentDueDate,
  CCContentTableContentLink,
  CCContentTableContentExpand,
  CCContentTableContentItem,
  CCContentTableContentItemBottom,
  CCContentTableContentItemTop,
  CCContentTableContentTitle,
  CCContentTableContentModule,
  CCContentTableContentAssignee,
  CCContentTableContentAction,
  CCContentTableContentItemBottomDescription,
  CCSpanEllipsis,
  CCContentTableContentHeaderBlock,
  CCContentTableContentExpandButton,
  CCContentTableContentExpandButtonWrap,
  CCContentTableContentExpandButtonCount,
  CCContentTableContentHeaderSubBlock,
  TextLink,
} from './ContentTableTasksSection.styled';

import { DatePriority } from '../../DatePriority';
import { ContentTableTasksAssigneeUsers } from './ContentTableTasksAssigneeUsers.component';

interface IContentTableTasksSectionComponentProps {
  nameSection?: string;
  isCompleted?: boolean;
  hideExtend?: boolean;
  isLoad?: boolean;
  dataTasks?: CommandCenterComponentTaskDTM[];
  countTasks?: number;
  openAgreementsDrawer?: (shipmentId: string) => void;
  openVGMDrawer?: (shipmentId: string) => void;
  openShipmentCostChangesDrawer?: (taskId: string) => void;
  openRailBillingDrawer?: (shipmentId: string) => void;
  openMaerskDrawer?: () => void;
  setShipmentId?: (shipmentId: string) => void;
  openMismatchDrawer?: () => void;
  openChangesDrawer?: (withValidate?: boolean) => void;
  openBookingConfirmationDrawer?: () => void;
}

const overdueDefaultVisibleElements = 3;

const ContentTableTasksSectionComponent: FC<IContentTableTasksSectionComponentProps> = (props) => {
  const {
    nameSection,
    isCompleted,
    hideExtend,
    isLoad,
    dataTasks,
    countTasks,
    openAgreementsDrawer,
    openVGMDrawer,
    openRailBillingDrawer,
    openMaerskDrawer,
    setShipmentId,
    openMismatchDrawer,
    openChangesDrawer,
    openBookingConfirmationDrawer,
    openShipmentCostChangesDrawer,
  } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();

  const getCountTasks = useMemo(() => {
    if (countTasks) {
      return (
        <CCContentTableContentHeaderSubBlock>
          {countTasks}
        </CCContentTableContentHeaderSubBlock>
      );
    }

    return null;
  }, [countTasks]);

  const [expandButton, setExpandButton] = useState(false);

  const expandButtonOnClick = useCallback(
    () => {
      setExpandButton((prev) => !prev);
    },
    [],
  );
  const [
    changeIsCollapseState, setChangeIsCollapseState,
  ] = useState<Array<{
    id: string;
    activeKey: string[];
  }>>([]);

  const endVisible = useRef<HTMLElement>(null);

  const changeErrorIsCollapseCallback = useCallback(
    (id: string) => () => {
      setChangeIsCollapseState((prev) => {
        let isFindId = false;

        const newState = prev.map((item) => {
          if (item.id === id) {
            isFindId = true;

            return {
              id,
              activeKey: item.activeKey?.length ? [] : ['1'],
            };
          }

          return item;
        });

        if (!isFindId) {
          newState.push({
            id,
            activeKey: ['1'],
          });
        }

        return newState;
      });
    },
    [],
  );
  const collapseOnChangeHandler = useCallback(
    (id: string) => CollapseOnChangeHandler(
      endVisible.current, changeErrorIsCollapseCallback(id),
    ),
    [],
  );

  const overdueAllCount = dataTasks && dataTasks?.length > overdueDefaultVisibleElements
    ? dataTasks?.length - overdueDefaultVisibleElements
    : 0;

  const getObjectName = useCallback((item: CommandCenterComponentTaskDTM) => {
    if (item.rateOrganizationIdName) {
      return item.rateOrganizationIdName ? `${item.rateOrganizationType?.toLocaleLowerCase() || ''} #${item.rateOrganizationIdName.toLowerCase() || ''}` : '';
    }

    if (item.rateRequestName) {
      return item.rateRequestName ? `${item.rateRequestType?.toLocaleLowerCase() || ''} #${item.rateRequestName.toLowerCase() || ''}` : '';
    }

    if (item.shipmentName) {
      return item.shipmentName || '';
    }

    return '';
  }, []);

  const handleGoToShipment = useCallback((item: CommandCenterComponentTaskDTM) => {
    if (item.shipmentName && item?.shipmentId) {
      navigate(RouteNames.SHIPMENT_OVERVIEW(item.shipmentId));
    }

    if (item.rateRequestName && item?.requestId) {
      navigate(RouteNames.RATES_FREIGHT_BY_ID(item.requestId));
    }

    if (item.rateOrganizationIdName && item?.organizationId) {
      navigate(RouteNames.ACCOUNT_CUSTOMER_GENERAL_INFO(item.organizationId));
    }
  }, [navigate]);

  const handleTaskActionClick = useCallback((item: CommandCenterComponentTaskDTM, e: SyntheticEvent) => {
    if (setShipmentId) {
      setShipmentId(String(item.shipmentId) || '');
    }
    if (item.taskType === ETasksType.NRA_AGREEMENT_TASK && item.shipmentId && openAgreementsDrawer) {
      e.preventDefault();
      openAgreementsDrawer(String(item.shipmentId));
    } else if (item.taskType === ETasksType.VGM_TASK && item.shipmentId && openVGMDrawer) {
      e.preventDefault();
      openVGMDrawer(String(item.shipmentId));
    } else if (item.taskType === ETasksType.SUBMIT_RAIL_BILLING && item.shipmentId && openRailBillingDrawer) {
      e.preventDefault();
      openRailBillingDrawer(String(item.shipmentId));
    } else if (item.taskType === ETasksType.CREATE_BOOKING_REQUEST_TASK && openMaerskDrawer) {
      e.preventDefault();
      openMaerskDrawer();
    } else if (item.taskType === ETasksType.VALIDATE_BOOKING_INFORMATION && openMismatchDrawer) {
      e.preventDefault();
      openMismatchDrawer();
    } else if (item.taskType === ETasksType.VALIDATE_SCHEDULE_UPDATES && openMismatchDrawer) {
      e.preventDefault();
      openMismatchDrawer();
    } else if (item.taskType === ETasksType.VALIDATE_SHIPMENT_CHANGES && openChangesDrawer) {
      e.preventDefault();
      openChangesDrawer(true);
    } else if (item.taskType === ETasksType.SUBMIT_BOOKING_AMEND_REQUEST && openChangesDrawer) {
      e.preventDefault();
      openChangesDrawer();
    } else if (item.taskType === ETasksType.CONFIRM_SHIPMENT_REQUEST && openBookingConfirmationDrawer) {
      e.preventDefault();
      openBookingConfirmationDrawer();
    } else if (item.taskType === ETasksType.CONFIRM_SHIPMENT_COST_CHANGE_TASK && openShipmentCostChangesDrawer) {
      e.preventDefault();
      openShipmentCostChangesDrawer(String(item.id));
    }
  }, [openAgreementsDrawer, openVGMDrawer, openRailBillingDrawer, openShipmentCostChangesDrawer]);

  if (isLoad) {
    return <TableSkeleton />;
  }

  if (!dataTasks?.length) {
    return null;
  }

  return (
    <div>
      {nameSection && (
        <CCContentTableContentHeaderBlock>
          {nameSection || ''}{getCountTasks}
        </CCContentTableContentHeaderBlock>
      )}
      {dataTasks?.slice(0, expandButton || hideExtend ? dataTasks?.length : 3).map((item) => (
        <CCContentTableContentItem key={item.customId}>
          <CCContentTableContentItemTop>
            <CCContentTableContentExpand
              onClick={item.description ? changeErrorIsCollapseCallback(item.customId || item.id) : undefined}
              isEmpty={!!item.description}
            >
              {!!item.description && (
                <VerticalFormItemSvg
                  rotate={changeIsCollapseState.filter((itemCollapse) => itemCollapse.id === item.customId)?.[0]?.activeKey.length ? 0 : -90}
                  style={{
                    color: changeIsCollapseState.filter((itemCollapse) => itemCollapse.id === item.customId)?.[0]?.activeKey.length ? themesColors.primaryBranding6 : themesColors.secondaryDot45,
                  }}
                />
              )}
            </CCContentTableContentExpand>
            {/* temporarily hidden */}
            {/* <CCContentTableContentPriority>
                {getPriority(item.priority)}
              </CCContentTableContentPriority> */}
            <CCContentTableContentTitle isActive={!!changeIsCollapseState.filter((itemCollapse) => itemCollapse.id === item.customId)?.[0]?.activeKey.length}>
              <CCSpanEllipsis
                dangerouslySetInnerHTML={{ __html: sanitizeHtml(item.title || '') }}
              />
            </CCContentTableContentTitle>
            <CCContentTableContentDueDate isWarning={item.dateWarning}>
              <DatePriority date={item.dueDate?.getFormatDMMMHHmm() || ''} mode={item.datePriority} isWarning={item.dateWarning} />
            </CCContentTableContentDueDate>
            <CCContentTableContentLink>
              {isCompleted
                ? (
                  <CCSpanEllipsis>
                    {item.completedAt?.getFormatDMMMHHmm()}
                  </CCSpanEllipsis>
                )
                : (
                  <TextLink onClick={() => handleGoToShipment(item)}>
                    {getObjectName(item)}
                  </TextLink>
                )}
            </CCContentTableContentLink>
            <CCContentTableContentModule>
              <CCSpanEllipsis>
                {item.module}
              </CCSpanEllipsis>
            </CCContentTableContentModule>
            <CCContentTableContentAssignee isBgNone={!!item?.assigneeUsers?.length}>
              {item.assignee?.firstName && (
                <Tooltip
                  title={(
                    <>
                      {item?.assignee?.getFullName() || ''}<br />
                      {item?.assignee?.email || ''}<br />
                      {item?.assignee?.phone || ''}
                    </>
                  )}
                  placement="top"
                >
                  {!!item.assignee && item.assignee.getFirstLetterOfName().toLocaleUpperCase()}
                </Tooltip>
              )}
              {!item.assignee?.firstName && item?.assigneeUsers?.length && (
                <ContentTableTasksAssigneeUsers users={item.assigneeUsers} />
              )}
            </CCContentTableContentAssignee>
            <CCContentTableContentAction>
              <NavLink onClick={(e) => handleTaskActionClick(item, e)} to={item.link()}>
                <VerticalFormItemSvg
                  rotate={-90}
                  style={{
                    color: themesColors.neutralBranding1,
                  }}
                />
              </NavLink>
            </CCContentTableContentAction>
          </CCContentTableContentItemTop>

          <CCContentTableContentItemBottom isActive={!!changeIsCollapseState.filter((itemCollapse) => itemCollapse.id === item.customId)?.[0]?.activeKey.length}>
            <Collapse
              onChange={collapseOnChangeHandler(item?.customId || item.id)}
              activeKey={changeIsCollapseState.filter((itemCollapse) => itemCollapse.id === item.customId)?.[0]?.activeKey || []}
            >
              <Panel header="" key="1" extra={null}>
                <CCContentTableContentItemBottomDescription
                  dangerouslySetInnerHTML={{ __html: sanitizeHtml(item.description || '') }}
                />
              </Panel>
            </Collapse>
          </CCContentTableContentItemBottom>
        </CCContentTableContentItem>
      ))}

      {!hideExtend && (
        <CCContentTableContentExpandButtonWrap>
          {(overdueAllCount > 0) && (
            <CCContentTableContentExpandButton onClick={expandButtonOnClick}>
              <VerticalFormItemSvg
                rotate={expandButton ? 180 : 0}
                style={{
                  color: themesColors.neutralBranding7,
                }}
              />
              {expandButton
                ? t('collapseAll')
                : t('expandAll')}
              {!expandButton && dataTasks?.length
                ? (
                  <CCContentTableContentExpandButtonCount>
                    ({overdueAllCount})
                  </CCContentTableContentExpandButtonCount>
                )
                : ''}
            </CCContentTableContentExpandButton>
          )}
        </CCContentTableContentExpandButtonWrap>
      )}
    </div>
  );
};

const cachedContentTableTasksSectionComponent = memo(ContentTableTasksSectionComponent);

export { cachedContentTableTasksSectionComponent as ContentTableTasksSectionComponent };
