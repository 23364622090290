import styled from 'styled-components';
import AntLayout from 'antd/es/layout';

import { SiderTrigger } from 'app-wrapper/view/components';

export const ShipmentLayout = styled(AntLayout)`
  display: flex;
  flex-direction: column;

  margin: 0;
  height: calc(100vh - 48px);
  overflow: hidden;
  background: ${({ theme }) => theme?.themesColors?.lightGrayBorder};
`;

export const ShipmentContent = styled(AntLayout)`
  margin: 0;
  height: 100%;
  background: ${({ theme }) => theme?.themesColors?.lightGrayBorder};
`;

export const SiderTriggerStyled = styled(SiderTrigger)`
  background: ${({ theme }) => theme?.themesColors?.lightGrayBackground};
`;
