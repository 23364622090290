import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';
import { UC } from 'shipment-operations/controllers';
import { PermissionAttributePolicy } from 'shipment-operations/constants';

import { HSCodeInputComponent } from './HSCodeInput.component';

interface HSCodeInputContainerProps {
  cargoId: number;
  dropdownMatchSelectWidth?: number;
}

const HSCodeInputContainer: FC<HSCodeInputContainerProps> = ({ cargoId, dropdownMatchSelectWidth }) => {
  const {
    code,
    errors,
  } = useSelector(R.selectors.bookingWizard.getCargoById(cargoId));
  const {
    isLoading, query,
    statusCommoditiesRequest,
  } = useSelector(R.selectors.commodity.getCommodity);
  const hasTemperatureControl = useSelector(R.selectors.bookingWizard.getHasTemperatureControl);
  const commodities = useSelector(R.selectors.commodity.getCommodities);

  const isError = !!(errors?.code || errors?.name);

  return (
    <HSCodeInputComponent
      commodities={commodities}
      dropdownMatchSelectWidth={dropdownMatchSelectWidth}
      isQueryEmpty={!!query}
      isLoadingCommodities={isLoading}
      statusCommoditiesRequest={statusCommoditiesRequest}
      isTemperatureControlled={hasTemperatureControl}
      isError={isError}
      hsCode={code}
      searchCommodities={UC.cargo.searchCommodities}
      setHsCode={UC.bookingWizard.setHsCode}
      touchCargoField={UC.bookingWizard.touchCargoField}
      cargoId={cargoId}
      cargosAvailability={PermissionAttributePolicy.WRITE}
    />
  );
};

export { HSCodeInputContainer as HSCodeInput };
