import React, { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';
import { UC } from 'shipment-operations/controllers';

import { PermissionAttributePolicy, ShipmentBookingStatusEnum } from 'shipment-operations/constants';
import { ShipmentDrawerComponent } from './ShipmentDrawer.component';

const ShipmentDrawerContainer: FC = () => {
  const isVisible = useSelector(R.selectors.shipmentDrawer.getVisibleState);
  const contentType = useSelector(R.selectors.shipmentDrawer.getContentType);
  const bookingStatus = useSelector(R.selectors.shipment.getBookingStatus);
  const permissions = useSelector(R.selectors.shipment.getDashboardsPermissions);
  const isRequestTheSame = useSelector(R.selectors.shipment.getIsRequestTheSameWithShipment);
  const withMismatches = useSelector(R.selectors.shipmentChanges.getWithMismatches);
  const isMaersk = useSelector(R.selectors.shipment.getIsMaersk);

  const partnerWarning = useMemo(() => withMismatches && permissions === PermissionAttributePolicy.WRITE && (bookingStatus === ShipmentBookingStatusEnum.BOOKING_AMENDMENT_SUBMITTED || bookingStatus === ShipmentBookingStatusEnum.BOOKING_SUBMITTED), [withMismatches, bookingStatus]);

  return (
    <ShipmentDrawerComponent
      isVisible={isVisible}
      activeContent={contentType}
      onClose={UC.overview.closeDrawer}
      bookingStatus={bookingStatus}
      permissions={permissions}
      isRequestTheSame={isRequestTheSame}
      isMaersk={isMaersk}
      partnerWarning={partnerWarning}
    />
  );
};

export { ShipmentDrawerContainer as ShipmentDrawer };
