import styled from 'styled-components';
import { divFlex } from 'app-wrapper/view/themes/mixin';

// Div

export const AllRequestedQuotesComponentNonContentDiv = styled.div.attrs({
  'data-class': 'AllRequestedQuotesComponentNonContentDiv',
})`
  ${divFlex}
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  min-height: 83vh;
`;

export const AllRequestedQuotesComponentNonContentDivWrapperDiv = styled.div`
  ${divFlex}
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100px;
`;

export const AllRequestedQuotesComponentNonContentDivWrapper = styled.div`
  ${divFlex}
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const AllRequestedQuotesComponentNonContentDivWrapperIcon = styled.div`
  ${divFlex}
  position: absolute;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 1px solid ${({ theme }) => theme.themesColors.characterBrandingDanger};
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 0.2;
`;

// Content

export const AllRequestedQuotesComponentNonContentText = styled.div`
  ${divFlex}
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
  margin-bottom: 24px;
`;

export const AllRequestedQuotesComponentNonContentTextFirst = styled.div`
  ${divFlex}
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.themesColors.characterBrandingTitle85};
  font-weight: 400;
  font-size: 14px;
`;

export const AllRequestedQuotesComponentNonContentTextSecond = styled.div`
  ${divFlex}
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.themesColors.characterBrandingTitle85};
  font-weight: 400;
  font-size: 12px;
`;

export const AllRequestedQuotesComponentNonContentTextButton = styled.div`
  ${divFlex}
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.themesColors.neutralBranding1};
  font-weight: 400;
  font-size: 16px;
`;
