import { apiWorker } from 'app-wrapper/repository/utilsServices';

import { PostPaymentContract, IGetPaymentContract } from 'shipment-operations/models/contracts';
import {
  CreatePaymentResponseDTM, CreatePaymentBodyDTM, CreateTransactionBodyDTM, GetPaymentResponseDTM,
} from 'shipment-operations/models/dtm';
import { PaymentsStatusesEnum, paymentEditError, transactionEditError } from 'shipment-operations/constants';
import { IErrorExceptionDTM } from 'app-wrapper/models/contracts';
import { CommonNetworkError } from 'app-wrapper/models/errors';

export class MakePaymentService {
  public onCreatePayment = async (accountId: number, category: string, body: CreatePaymentBodyDTM) => {
    let result: CreatePaymentResponseDTM | null;
    try {
      const response = await apiWorker.requestPost<PostPaymentContract>(
        `/billing-service/api/v1/accounts/${accountId}/${category}/payments`,
        body,
      );
      result = CreatePaymentResponseDTM.fromPlain({
        id: response.data.id,
        type: response.data.type,
        number: response.data.number,
        status: response.data.status,
        createdAt: response.data.createdAt,
        createdBy: response.data.createdBy,
        paid: response.data.paid,
        balance: response.data.balance,
        amount: response.data.amount,
      });
    } catch (e) {
      throw new Error('Something wrong, please try again');
    }

    return result;
  }

  public onEditPayment = async (category: string, paymentId?: string, body?: CreatePaymentBodyDTM) => {
    let result: CreatePaymentResponseDTM | null;
    try {
      const response = await apiWorker.requestPut<PostPaymentContract>(
        `/billing-service/api/v1/${category}/payments/${paymentId}`,
        body,
      );
      result = CreatePaymentResponseDTM.fromPlain({
        id: response.data.id,
        type: response.data.type,
        number: response.data.number,
        status: response.data.status,
        createdAt: response.data.createdAt,
        createdBy: response.data.createdBy,
        paid: response.data.paid,
        balance: response.data.balance,
        amount: response.data.amount,
      });
    } catch (e) {
      const response = (e as IErrorExceptionDTM).response?.data?.message || '';
      const message = (paymentEditError.includes(response) ? response : 'default');
      throw CommonNetworkError.fromPlain({
        status: 400,
        message,
      });
    }

    return result;
  }

  public onCreateTransaction = async (shipmentId: string, category: string, invoiceId: number, body: CreateTransactionBodyDTM) => {
    try {
      await apiWorker.requestPost(
        `/billing-service/api/v1/shipments/${shipmentId}/${category}/invoices/${invoiceId}/transactions`,
        body,
      );
    } catch (e) {
      throw new Error('Something wrong, please try again');
    }
    return 'Success';
  }

  public onEditTransaction = async (shipmentId: string | number, category: string, transactionId: number, invoiceId: number, body: CreateTransactionBodyDTM) => {
    try {
      await apiWorker.requestPut(
        `/billing-service/api/v1/shipments/${shipmentId}/${category}/invoices/${invoiceId}/transactions/${transactionId}`,
        body,
      );
    } catch (e) {
      const response = (e as IErrorExceptionDTM).response?.data?.message || '';
      const message = (transactionEditError.includes(response) ? response : 'default');
      throw CommonNetworkError.fromPlain({
        status: 400,
        message,
      });
    }
    return 'Success';
  }

  public onGetPayment = async (category: string, paymentId: string | number) => {
    let result: GetPaymentResponseDTM | null;
    try {
      const response = await apiWorker.requestGet<IGetPaymentContract>(
        `/billing-service/api/v1/${category}/payments/${paymentId}`,
      );
      result = GetPaymentResponseDTM.fromPlain({
        createdAt: response.data.createdAt,
        amount: response.data.amount,
        paid: response.data.paid,
        id: response.data.id,
        type: response.data.type,
        number: response.data.number,
        status: response.data.status as PaymentsStatusesEnum,
        createdBy: response.data.createdBy,
        balance: response.data.balance,
        transactions: response.data.transactions,
        reference: response.data.reference,
        creditor: response.data.creditor && {
          id: response.data.creditor.id,
          organizationId: response.data.creditor.organizationId,
          name: response.data.creditor.name,
          phone: response.data.creditor.phone,
          phone2: response.data.creditor.phone2,
          email: response.data.creditor.email,
          usci: response.data.creditor.usci,
          taxId: response.data.creditor.taxId,
        },
        debtor: response.data.debtor && {
          id: response.data.debtor.id,
          organizationId: response.data.debtor.organizationId,
          name: response.data.debtor.name,
          phone: response.data.debtor.phone,
          phone2: response.data.debtor.phone2,
          email: response.data.debtor.email,
          usci: response.data.debtor.usci,
          taxId: response.data.debtor.taxId,
        },
        documents: response.data.documents,
      });
    } catch (e) {
      throw new Error('Something wrong, please try again');
    }

    return result;
  }
}
