import {
  createSlice as createReduxSlice, CreateSliceOptions, SliceCaseReducers,
} from '@reduxjs/toolkit';

export const createSlice = <State,
  CaseReducers extends SliceCaseReducers<State>,
  Name extends string = string>(options: CreateSliceOptions<State, CaseReducers, Name>) => createReduxSlice({
    ...options,
    reducers: {
      ...options.reducers,
      clear: () => ({ ...options.initialState }),
    },
  });
