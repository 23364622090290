import React, {
  ChangeEvent,
  FC,
  useCallback,
  useMemo,
} from 'react';

import { Input } from 'app-wrapper/view/components';
import { CargoDTM } from 'shipment-operations/models/dtm';
import { PermissionAttributePolicy } from 'shipment-operations/constants';

interface ICargoFormPackagesNumberInputComponentProps {
  packagesNumber: CargoDTM['packagesNumber']
  touchField: (field: string) => void
  setPackagesNumber: (packagesNumber: CargoDTM['packagesNumber']) => void
  error?: boolean
  cargosAvailability?: PermissionAttributePolicy
}

export const CargoFormPackagesNumberInputComponent: FC<ICargoFormPackagesNumberInputComponentProps> = ({
  packagesNumber,
  touchField,
  setPackagesNumber,
  error,
  cargosAvailability,
}) => {
  const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setPackagesNumber(event.target.value);
  }, []);

  const handleBlur = useCallback(() => {
    touchField('packagesNumber');
  }, []);

  const isDisabled = useMemo(() => cargosAvailability !== PermissionAttributePolicy.WRITE, [cargosAvailability]);

  return (
    <Input
      disabled={isDisabled}
      value={packagesNumber}
      onChange={handleChange}
      onBlur={handleBlur}
      error={error}
    />
  );
};
