import moment from 'moment-timezone';

export const formatLocationDate = (data: string[],
  time: string,
  timeZoneId: string | null,
  typeDate?: 'earliest' | 'latest') => {
  if (!data.length || !time) return undefined;

  switch (typeDate) {
    case 'earliest':
      return moment(time).startOf('day').tz(timeZoneId || 'UTC', true).startOf('day')
        .format();
    case 'latest':
      return moment(time).startOf('day').tz(timeZoneId || 'UTC', true).endOf('day')
        .format();
    default:
      return moment(time).tz(timeZoneId || 'UTC', true).startOf('day').format();
  }
};
