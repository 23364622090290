import React, { FC, useCallback, useMemo } from 'react';
import Collapse from 'antd/es/collapse';

import { StyledExpandIcon } from 'shipment-operations/view/components';
import { useToggleCollapse } from 'shipment-operations/view/hooks';

import { IFreightQuotaContentDTM, IFreightQuotaContentSchedulesChargesDTM } from 'monetary/models/dtm';
import { calculateRange, generateUnitType } from 'monetary/repository/store/FreightQuote/FreightQuotePrint.selectors';

import { CustomPanel } from './CustomPanel';
import { Wrapper, StyledPanel, Separator } from './RFQFreeTime.styled';

interface IRFQFreeTimeComponentProps {
  matchedQuota?: IFreightQuotaContentDTM
}

const RFQFreeTimeComponent: FC<IRFQFreeTimeComponentProps> = ({ matchedQuota }) => {
  const { openedKeys, onToggleCollapse } = useToggleCollapse();
  const onToggle = useCallback((key) => () => onToggleCollapse(key), [onToggleCollapse]);

  const dictionaryOfContainers = useMemo(() => matchedQuota?.containers?.reduce((acc, cur) => ({ ...acc, [cur?.id || '']: cur.type }), {}), [matchedQuota]);
  const notAppliedCharges = useMemo(() => {
    const charges = matchedQuota?.schedules?.[0]?.charges;
    const filteredCharges = charges && charges?.filter((item) => !item.applied && ['ACCESSORIAL', 'EXCEPTION', 'FREE_TIME'].includes(item?.chargeCode?.subType || '') && item.subjectTo !== 'INCLUDED');
    return filteredCharges?.map((item) => ({
      ...item,
      chargeCode: {
        ...item.chargeCode,
        description: item.chargeCode?.code === 'MSC' ? (item.chargeCode?.originalDescription?.toLowerCase() || item.chargeCode.description) : item.chargeCode?.description,
      },
      customContainerType: dictionaryOfContainers && item?.containerId ? dictionaryOfContainers[item.containerId as unknown as keyof typeof dictionaryOfContainers] as string : '',
      customUnitType: generateUnitType(item?.priceBy || '', item?.measureBy || ''),
    }));
  }, [matchedQuota, dictionaryOfContainers]);
  const freeTimeCharges = useMemo(() => {
    const filtered = notAppliedCharges?.filter((item) => item.chargeCode?.subType === 'FREE_TIME');

    return filtered?.map((item) => ({ ...item, customAppliance: calculateRange(item.applianceRange?.maxValue, item.applianceRange?.minValue) }));
  }, [matchedQuota, dictionaryOfContainers, notAppliedCharges]);

  const origin = useMemo(() => (freeTimeCharges?.length ? freeTimeCharges.filter((item) => item.designation === 'ORIGIN') : []), [freeTimeCharges]);
  const freight = useMemo(() => (freeTimeCharges?.length ? freeTimeCharges.filter((item) => item.designation === 'FREIGHT') : []), [freeTimeCharges]);
  const destination = useMemo(() => (freeTimeCharges?.length ? freeTimeCharges.filter((item) => item.designation === 'DESTINATION') : []), [freeTimeCharges]);

  const innerPanel = (data: IFreightQuotaContentSchedulesChargesDTM[]) => [...new Set(data.map((item) => item.chargeCode?.description))].map((name) => {
    const filtered = data.filter((item) => item.chargeCode?.description === name);

    return (
      <CustomPanel key={`RFQFreeTimeComponent_${name}`} data={filtered} name={name || ''} />
    );
  });

  return (
    <Wrapper>
      {origin && !!origin.length && (
        <>
          <Collapse ghost expandIcon={StyledExpandIcon} key={1} onChange={onToggle(1)}>
            <StyledPanel
              key={1}
              header={(<span style={{ fontWeight: 'bold', color: openedKeys.includes(1) ? '#6C79DE' : '' }}>Origin</span>)}
            >
              {innerPanel(origin)}
            </StyledPanel>
          </Collapse>
        </>
      )}
      {freight && !!freight.length && (
        <>
          <Separator />
          <Collapse ghost expandIcon={StyledExpandIcon} key={2} onChange={onToggle(2)}>
            <StyledPanel
              key={2}
              header={(<span style={{ fontWeight: 'bold', color: openedKeys.includes(2) ? '#6C79DE' : '' }}>Freight</span>)}
            >
              {innerPanel(freight)}
            </StyledPanel>
          </Collapse>
        </>
      )}
      {destination && !!destination.length && (
        <>
          <Separator />
          <Collapse ghost expandIcon={StyledExpandIcon} key={3} onChange={onToggle(3)}>
            <StyledPanel
              key={3}
              header={(<span style={{ fontWeight: 'bold', color: openedKeys.includes(3) ? '#6C79DE' : '' }}>Destination</span>)}
            >
              {innerPanel(destination)}
            </StyledPanel>
          </Collapse>
        </>
      )}
    </Wrapper>
  );
};

export { RFQFreeTimeComponent };
