import React, {
  FC, useEffect, useMemo, useCallback,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate } from 'react-router-dom';
import Alert from 'antd/es/alert';

import { EditPayment } from 'shipment-operations/view/pages';
import { PaymentsStatusesEnum as statuses } from 'shipment-operations/constants';
import { Layout } from 'app-wrapper/view/guideline';
import { Header, Main, Footer } from './components';
import {
  StyledHead, LeftContainer, StyledBackIcon, HeaderTitle, StyledStatus,
} from './PaymentView.styled';

interface IPaymentViewComponentProps {
  fetchData: (paymentId?: string, category?: string) => void
  checkRefresh: (paymentId?: string, category?: string) => void
  number?: string
  isError: boolean
  status?: statuses
  isOpenEdit: boolean
  shouldRefresh: boolean
}

const PaymentViewComponent: FC<IPaymentViewComponentProps> = ({
  fetchData, number, isError, status, isOpenEdit, shouldRefresh, checkRefresh,
}) => {
  const { t } = useTranslation();
  const { paymentId } = useParams<'paymentId'>();
  const { invoiceType } = useParams<'invoiceType'>();
  const navigate = useNavigate();

  const matchedStatus = useMemo(() => ({
    [statuses.APPLIED]: <StyledStatus color="#6C79DE">{t('payment.applied')}</StyledStatus>,
    [statuses.OPEN]: <StyledStatus color="#75CB3F">{t('payment.open')}</StyledStatus>,
    [statuses.PARTIAL]: <StyledStatus color="#F1AE00">{t('payment.partial')}</StyledStatus>,
  }), [t]);

  const onReturn = useCallback(() => navigate(-1), []);

  useEffect(() => {
    fetchData(paymentId, invoiceType);
  }, [paymentId]);

  useEffect(() => {
    checkRefresh(paymentId, invoiceType);
  }, [shouldRefresh]);

  return (
    <>
      {isError ? (
        <div style={{ marginTop: '26px' }}>
          <StyledBackIcon onClick={onReturn} />
          <Alert
            style={{ marginTop: '20px' }}
            message={t('basicErrors.REQUEST_COMMON.title')}
            description={t('basicErrors.REQUEST_COMMON.subTitle')}
            type="error"
          />
        </div>
      ) : (
        <>
          <StyledHead>
            <LeftContainer>
              <StyledBackIcon onClick={onReturn} />
              <HeaderTitle>{t('payment.paymentNumber', { payment: number })}</HeaderTitle>
            </LeftContainer>
            {status && matchedStatus[status]}
          </StyledHead>
          <Layout>
            {isOpenEdit && (
              <EditPayment />
            )}
            <Header />
            <Main />
            <Footer />
          </Layout>
          <div id="subpage-footer" />
        </>
      )}
    </>
  );
};

export { PaymentViewComponent };
