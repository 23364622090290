import React, { FC } from 'react';

import {
  WaypointTypePortIcon,
} from 'app-wrapper/view/icons';
import themesColors from 'app-wrapper/view/themes/themesColors';

import { IAllRequestedQuotesComponentQuotesItemContentDTM, IQuotaBookmark } from 'monetary/models/dtm/Quotas';

import {
  ARQCardContainer,
  ARQCardSectionIcon,
  RFRQuotesItemDiv,
} from './RFRQuotesItem.styled';
import { ARQItemSubCardDescriptions } from '../ARQItemSubCard/ARQItemSubCardDescriptions';
import { RFRItemSubCard } from '../RFRItemSubCard';

export interface IRFRQuotesItemProps {
  name?: string;
  id?: number;
  status?: string;
  createQuotaDate?: string;
  type?: string;
  origin?: IAllRequestedQuotesComponentQuotesItemContentDTM;
  destination?: IAllRequestedQuotesComponentQuotesItemContentDTM;
  linkTo?: string;
  container: {
    type: string;
    count: number;
  }
  containersType?: string[];
  hsCodes?: string[];
  profile?: {
    name: string;
    position: string;
  }
  customer?: {
    name: string;
    email?: string;
    position?: string;
  }
  bookmark?: IQuotaBookmark
  quotasCount?: number
  favoriteQuotasCount?: number
}

export const RFRQuotesItem: FC<IRFRQuotesItemProps> = (
  props,
) => {
  const quotasData = props;

  return (
    <RFRQuotesItemDiv>

      <ARQCardContainer to="">
        <RFRItemSubCard
          quotasData={quotasData}
        >
          <ARQCardSectionIcon>
            <WaypointTypePortIcon color={themesColors.neutralBranding6} />
          </ARQCardSectionIcon>
          <ARQItemSubCardDescriptions
            title="title"
            content="content"
          />
        </RFRItemSubCard>
      </ARQCardContainer>

    </RFRQuotesItemDiv>
  );
};
