import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ExportClearanceType, ExportClearanceTypeNames } from 'shipment-operations/constants';
import { AdditionalServiceInputActivityDTM } from 'shipment-operations/models/dtm';
import { EditButton } from 'app-wrapper/view/components';

import {
  Result,
  ResultHeader,
  ResultsHeaderTitle,
  ResultsValue,
  Tag,
  ClockIcon,
} from './InputResult.styled';

interface IInputResultProps {
  activity: AdditionalServiceInputActivityDTM;
  isOwnActivity?: boolean;
  startEditingFlowFromInputActivity: (type: ExportClearanceType) => void;
}

export const InputResult: FC<IInputResultProps> = ({
  activity,
  isOwnActivity,
  startEditingFlowFromInputActivity,
}) => {
  const { t } = useTranslation();
  const value = activity.getProvidedInputValue();

  return (
    <Result>
      <ResultHeader>
        <ResultsHeaderTitle>
          {ExportClearanceTypeNames[activity.code]}
        </ResultsHeaderTitle>

        {isOwnActivity ? (
          <EditButton
            onClick={() => startEditingFlowFromInputActivity(activity.code)}
            iconOnly
          />
        ) : null}
      </ResultHeader>

      {isOwnActivity ? (
        <>
          <ResultsValue>
            {value || t('-')}
          </ResultsValue>
        </>
      ) : (
        <>
          {value ? (
            <ResultsValue>
              {value}
            </ResultsValue>
          ) : (
            <Tag icon={<ClockIcon />}>
              {t('Waiting from Skypace')}
            </Tag>
          )}
        </>
      )}
    </Result>
  );
};
