import React, { memo } from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';

import { DashboardComponent } from 'shipment-operations/view/components';

const DashboardContainer = memo(() => {
  const loading = useSelector(R.selectors.overview.getLoadingStatus);
  const totalProfit = useSelector(R.selectors.overview.getTotalProfit);
  const totalProfitPercent = useSelector(R.selectors.overview.getTotalProfitPercent);
  const totalCost = useSelector(R.selectors.overview.getTotalCost);
  const buyTotalCost = useSelector(R.selectors.overview.getBuyTotalCost);
  const arBudgetTotalCost = useSelector(R.selectors.overview.getArBudgetTotalCost);
  const apBudgetTotalCost = useSelector(R.selectors.overview.getApBudgetTotalCost);
  const receivablesDifferent = useSelector(R.selectors.overview.getReceivablesDifferent);
  const payablesDifferent = useSelector(R.selectors.overview.getPayablesDifferent);
  const receivablesDifferentPercent = useSelector(R.selectors.overview.getReceivablesDifferentPercent);
  const payablesDifferentPercent = useSelector(R.selectors.overview.getPayablesDifferentPercent);

  return (
    <DashboardComponent
      loading={loading}
      totalProfit={totalProfit}
      totalProfitPercent={totalProfitPercent}
      totalCost={totalCost}
      buyTotalCost={buyTotalCost}
      arBudgetTotalCost={arBudgetTotalCost}
      apBudgetTotalCost={apBudgetTotalCost}
      receivablesDifferent={receivablesDifferent}
      receivablesDifferentPercent={receivablesDifferentPercent}
      payablesDifferent={payablesDifferent}
      payablesDifferentPercent={payablesDifferentPercent}
    />
  );
});

export { DashboardContainer as Dashboard };
