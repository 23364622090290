import styled from 'styled-components';

export const ShippingPartyListDescription = styled.p`
  font-weight: 400;
  font-size: 12px;
  line-height: 21px;
  color:  ${({ theme }) => theme.bookingPage.shippingPartiesStep.descriptionColor} !important;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin: 0 !important;
`;
