import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { RequiredShippingParties, ShippingPartyNames } from 'shipment-operations/constants';
import { R } from 'shipment-operations/repository';
import { UC } from 'shipment-operations/controllers';

import { ShippingPartiesFormTitleComponent } from './ShippingPartiesFormTitle.component';

const ShippingPartiesFormTitleContainer: FC = () => {
  const selected = useSelector(R.selectors.shippingParties.getSelectedFormType);
  const isLoading = useSelector(R.selectors.shippingParties.getFormDataLoadingState);
  const isInDraft = useSelector(R.selectors.shippingParties.isSelectedFormChanged);
  const currentFormState = useSelector(R.selectors.shippingParties.getSelectedCurrentShippingParty);
  const selectedPartyRole = useSelector(R.selectors.shippingParties.getSelectedFormType);
  const permissons = useSelector(R.selectors.shippingParties.getShippingPartyPermissionsByRole(selectedPartyRole));

  return (
    <ShippingPartiesFormTitleComponent
      type={selected}
      title={ShippingPartyNames[selected]}
      isRequired={RequiredShippingParties.includes(selected)}
      isInDraft={isInDraft}
      isLoading={isLoading}
      isAbleToEditCompany={permissons.isAbleToEditCompany}
      shippingPartiesCurrentData={currentFormState}
      onShippingPartyRemove={UC.shippingParties.removeShippingParty}
    />
  );
};

export { ShippingPartiesFormTitleContainer as ShippingPartiesFormTitle };
