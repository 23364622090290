import { dateFormatETAmin } from 'app-wrapper/utils';

export const dateFormatFromTo = (from: string, to: string, isSameToOne = false) => {
  const first = `${from && dateFormatETAmin(from)}`;
  const second = `${to && dateFormatETAmin(to)}`;
  if (isSameToOne && first && first === second) {
    return `${first}`;
  }
  return `${first && second ? `${first} - ` : `${first}`}${second}`;
};
