import i18n from 'i18next';
import toNumber from 'lodash/toNumber';

import { ValidationErrorDTM, ValidationErrorType } from 'app-wrapper/types';
import { validateDecimalFraction } from 'app-wrapper/utils';
import { BaseController, controller } from 'proto/BaseController';

import { AirVentUnitsType, ContainerBasicTypes } from 'shipment-operations/constants';
import { R } from 'shipment-operations/repository';
import { ChargeDTM, ShipmentPreviewDTM, TemperatureControlDTM } from 'shipment-operations/models/dtm';

@controller
export class TemperatureControlController extends BaseController {
  public fetchTemperatureControl = async (shipmentId?: string) => {
    let response: TemperatureControlDTM | null = null;

    if (!shipmentId) {
      console.error('No shipment id for TemperatureControlController.fetchTemperatureControl');

      return;
    }

    const containerBasicType = R.selectors.shipment.getContainersBasicType(this.store.getState());

    if (containerBasicType !== ContainerBasicTypes.REEFER) {
      return;
    }

    this.dispatch(R.actions.temperatureControl.setLoading(true));

    try {
      response = await R.services.temperatureControl.getTemperatureControlData(shipmentId);
    } catch (e) {
      console.error('GET TEMPERATURE CONTROL: CONTROLLER ERROR');

      this.dispatch(R.actions.temperatureControl.setLoading(false));

      return;
    }

    if (!response) {
      this.dispatch(R.actions.temperatureControl.setLoading(false));

      return;
    }

    const companyId = R.selectors.shipment.getShipmentCustomerCompanyId(this.store.getState());

    let charges: ChargeDTM[] | null = [];
    charges = await R.services.shipmentCharges.getCustomerCharges(shipmentId, companyId);
    this.dispatch(R.actions.temperatureControl.setCharges(charges || []));

    this.dispatch(R.actions.temperatureControl.setTemperatureControlData(response));

    this.updateTemperatureErrors();
    this.updateAirVentErrors();
    this.updateInitialState();

    this.dispatch(R.actions.temperatureControl.setLoading(false));
  };

  public resetTemperatureControl = () => {
    const initialState = R.selectors.temperatureControl.getTemperatureControlInitialState(this.store.getState());

    this.dispatch(R.actions.temperatureControl.clearWithNewFieldsState(initialState));

    this.updateTemperatureErrors();
    this.updateAirVentErrors();
  };

  public sendTemperatureControl = async (shipmentId?: string) => {
    if (!shipmentId) {
      console.error('No shipment id for TemperatureControlController.sendTemperatureControl');

      return;
    }

    this.dispatch(R.actions.temperatureControl.setLoading(true));

    this.updateAirVentErrors();
    this.updateTemperatureErrors();

    const temperatureControl = R.selectors.temperatureControl.getTemperatureControl(this.store.getState());
    const temperatureControlErrors = R.selectors.temperatureControl.getTemperatureControlErrors(this.store.getState());

    if (Object.values(temperatureControlErrors).filter((e) => !!e).length) {
      this.dispatch(R.actions.temperatureControl.setUpdateAttemptStatus(true));
      this.dispatch(R.actions.temperatureControl.setLoading(false));

      return;
    }

    let response: TemperatureControlDTM | null = null;

    try {
      if (temperatureControl.id) {
        response = await R.services.temperatureControl.putTemperatureControlData(shipmentId, temperatureControl);
      } else {
        response = await R.services.temperatureControl.postTemperatureControlData(shipmentId, temperatureControl);
      }
    } catch (e) {
      console.error('GET TEMPERATURE CONTROL: CONTROLLER ERROR');

      this.dispatch(R.actions.temperatureControl.setLoading(false));

      return;
    }

    if (!response) {
      return;
    }

    this.dispatch(R.actions.temperatureControl.setTemperatureControlData(response));

    this.updateTemperatureErrors();
    this.updateAirVentErrors();
    this.updateInitialState();
    this.updateShipmentShort(shipmentId);

    this.dispatch(R.actions.temperatureControl.setLoading(false));
  }

  private updateShipmentShort = async (shipmentId: string) => {
    let shipment: ShipmentPreviewDTM | null = null;
    shipment = await R.services.shipment.getShipmentShortById(+shipmentId);
    if (shipment) {
      this.dispatch(R.actions.shipment.setShipment(shipment));
    }
  }

  public setGenset = (genset: boolean) => {
    this.dispatch(R.actions.temperatureControl.setGenset(genset));
  };

  public applyNewTotalCost = () => {
    const totalCost = R.selectors.bookingWizard.getTotalCost(this.store.getState());
    const gensetSum = R.selectors.bookingWizard.getSumOfGenset(this.store.getState()) || 0;
    const isCheckedGenset = R.selectors.temperatureControl.getTemperatureControl(this.store.getState()).genset;
    const final = parseInt(totalCost, 10) + ((isCheckedGenset ? gensetSum : -gensetSum) || 0);
    this.dispatch(R.actions.bookingWizard.setTotalCost(String(final)));
  }

  public setTemperature = (temperature?: string) => {
    const formatedTemperature = this.validateDecimalFractionWithSign(temperature);

    const error = this.validateTemperature(formatedTemperature);
    this.dispatch(R.actions.temperatureControl.setTemperature({ temperature: formatedTemperature, error }));
    this.dispatch(R.actions.temperatureControl.touchField('temperature'));
  };

  public setFlowRate = (flowRate?: string) => {
    const slicedFlowRate = flowRate?.replace(/\D/g, '').slice(0, 3);
    this.dispatch(R.actions.temperatureControl.setFlowRate(!slicedFlowRate ? undefined : slicedFlowRate));
    this.dispatch(R.actions.temperatureControl.touchField('flowRate'));
    this.updateAirVentErrors();
  };

  public setFlowRateUnit = (flowRateUnit?: AirVentUnitsType) => {
    this.dispatch(R.actions.temperatureControl.setFlowRateUnit(flowRateUnit));
    this.dispatch(R.actions.temperatureControl.touchField('flowRateUnit'));
    this.updateAirVentErrors();
  };

  public touchField = (field: string) => {
    this.dispatch(R.actions.temperatureControl.touchField(field));
  };

  private validateDecimalFractionWithSign = (decimalFraction?: string) => {
    if (!decimalFraction) {
      return undefined;
    }

    const hasSign = decimalFraction[0] === '-';
    const formatedDecimalFraction = validateDecimalFraction(decimalFraction, 4, 1);
    if (hasSign) {
      return `-${formatedDecimalFraction}`;
    }
    return formatedDecimalFraction;
  }

  public clear = () => {
    this.dispatch(R.actions.temperatureControl.clear());
  }

  private validateTemperature = (temperature?: string) => {
    if (!temperature) {
      return ValidationErrorDTM.fromPlain({ type: ValidationErrorType.DEFAULT, message: i18n.t('basicErrors.REQUIRED_MESSAGE') });
    }
    if (toNumber(temperature) < -50.0 || toNumber(temperature) > 99.9) {
      return ValidationErrorDTM.fromPlain({ type: ValidationErrorType.ALERT, message: i18n.t('shipmentContainerErrors.TEMPERATURE_BOUNDS_MESSAGE') });
    }

    return undefined;
  };

  private updateAirVentErrors = () => {
    const { airVent: { flowRate, flowRateUnit } } = R.selectors.temperatureControl.getTemperatureControl(this.store.getState());

    if (!flowRate && !!flowRateUnit) {
      this.dispatch(R.actions.temperatureControl.setAirVentErrors({
        flowRate: ValidationErrorDTM.fromPlain({ type: ValidationErrorType.DEFAULT, message: i18n.t('basicErrors.REQUIRED_MESSAGE') }),
        flowRateUnit: undefined,
      }));
      return;
    }

    if (!!flowRate && !flowRateUnit) {
      this.dispatch(R.actions.temperatureControl.setAirVentErrors({
        flowRate: undefined,
        flowRateUnit: ValidationErrorDTM.fromPlain({ type: ValidationErrorType.DEFAULT, message: i18n.t('basicErrors.REQUIRED_MESSAGE') }),
      }));
      return;
    }

    this.dispatch(R.actions.temperatureControl.setAirVentErrors({
      flowRate: undefined,
      flowRateUnit: undefined,
    }));
  }

  public updateTemperatureErrors = () => {
    const { temperature } = R.selectors.temperatureControl.getTemperatureControl(this.store.getState());

    this.dispatch(R.actions.temperatureControl.setTemperatureErrors({
      temperature: this.validateTemperature(temperature),
    }));
  };

  private updateInitialState = () => {
    const temperatureControl = R.selectors.temperatureControl.getTemperatureControl(this.store.getState());
    this.dispatch(R.actions.temperatureControl.setInitialState(temperatureControl));
  };
}
