import styled from 'styled-components';

import { ExpandIcon } from 'app-wrapper/view/components/ExpandIcon';

interface ISectionProps {
  inner?: boolean,
}

interface ICellProps {
  active?: boolean,
}

export const StyledExpandIcon = styled(ExpandIcon)`
  margin-top: 5px;
  margin-right: 6px;
`;

export const StyledSection = styled.div<ISectionProps>`
  background: #F9F9FB;
  border-radius: 3px;
  width: 100%;
  font-weight: 400;
  font-size: 12px;
  color: #73819B;
  height: 24px;
  padding-left: ${({ inner }) => (inner ? '20px' : '16px')};
  line-height: 24px;
`;

export const StyledTitle = styled.span<ICellProps>`
  text-transform: capitalize;
  text-decoration: ${({ active }) => !active && 'line-through'};
  color: ${({ active }) => !active && '#D1D7E2'}
`;

export const StyledBodyCell = styled.div<ICellProps>`
  text-decoration: ${({ active }) => !active && 'line-through'};
  color: ${({ active }) => !active && '#D1D7E2'}
`;

export const CustomUnit = styled.span<ICellProps>`
  text-transform: lowercase;
  text-decoration: ${({ active }) => !active && 'line-through'};
  color: ${({ active }) => (active ? '#73819B' : '#D1D7E2')}
`;
