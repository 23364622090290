import styled from 'styled-components';
import Button from 'antd/es/button';
import { RightOutlined } from '@ant-design/icons';

export const MoreDetailsButtonIcon = styled(RightOutlined)`
  font-size: 11px;
  color: ${({ theme }) => theme.themesColors.neutralBranding7};

  svg {
    margin-bottom: 0.5px;
  }
`;

export const MoreDetailsButton = styled(Button)`
  width: fit-content;
  margin-top: 8px;

  &:hover, &:hover ${MoreDetailsButtonIcon} {
    border-color: ${({ theme }) => theme.themesColors.primaryBranding5};
    color: ${({ theme }) => theme.themesColors.primaryBranding5};
  }
`;
