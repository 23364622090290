import styled from 'styled-components';

export const SiderTriggerButton = styled.button`
  width: 16px;
  height: 38px;
  position: absolute;
  right: -14px;
  float: right;
  background: white;
  border: none;
  transform: perspective(22px) rotateY(31deg);
  top: 4px;
  cursor: pointer;

  .ant-menu-submenu-arrow {
    width: 0px;
    color: #99a3b5;
    &::after,
    &::before {
      position: absolute;
      height: 1.5px;
      width: 6px;
      background-color: currentColor;
      border-radius: 2px;
      transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
        transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
        color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      content: "";
    }

    &::after {
      transform: rotate(-145deg) translateX(-2px);
    }
    &::before {
      transform: rotate(-35deg) translateX(2px);
    }
    &.isCollapsed {
      right: 13px;
      &::after {
        transform: rotate(145deg) translateX(2px);
      }
      &::before {
        transform: rotate(35deg) translateX(-2px);
      }
    }
  }
`;
