import React, { FC, ReactElement } from 'react';

import PlusOutlined from '@ant-design/icons/PlusOutlined';

import { ShippingFormAddNewItem } from './styled';

interface IDropdownMenuWithNewItemProps {
  label: string;
  menu: ReactElement;
  onClick: () => void;
}

export const DropdownMenuWithNewItem: FC<IDropdownMenuWithNewItemProps> = ({
  label, menu, onClick,
}) => (
  <>
    {menu}
    <ShippingFormAddNewItem onClick={onClick}>
      <PlusOutlined /> {label}
    </ShippingFormAddNewItem>
  </>
);
