import React, {
  ChangeEvent,
  FC,
  useCallback,
} from 'react';
import { useTranslation } from 'react-i18next';

import { InputStyled } from './VolumeInput.styled';

interface VolumeInputComponentProps {
  cargoId: number;
  volume: string;
  touchCargoField: (field: string, cargoId: number) => void
  setVolume: (description: string, cargoId: number) => void
}

export const VolumeInputComponent: FC<VolumeInputComponentProps> = ({
  cargoId,
  volume,
  touchCargoField,
  setVolume,
}) => {
  const { t } = useTranslation();

  const handleChangeVolume = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setVolume(event.target.value, cargoId);
  }, [setVolume, cargoId]);

  const handleBlur = useCallback(() => {
    touchCargoField('volume', cargoId);
  }, [touchCargoField, cargoId]);

  return (
    <InputStyled
      placeholder={t('Enter Volume')}
      value={volume}
      onChange={handleChangeVolume}
      onBlur={handleBlur}
    />
  );
};
