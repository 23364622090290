import { Factory } from 'fishery';

import { ContactBookDefaultStateCompanyListStateDTM } from './ContactBookState.dtm';

export const EmptyContactBookDefaultStateCompanyListStateDTMFactory = Factory.define<ContactBookDefaultStateCompanyListStateDTM>(() => ContactBookDefaultStateCompanyListStateDTM.fromPlain({
  id: 0,
  indexId: 0,
  name: '',
  customId: '',
  usci: '',
  taxId: '',
  isUpdate: false,
  isPrimary: false,
  addressesIds: [],
  contactPersonsIds: [],
}));
