import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Badge from 'antd/es/badge';

import { colors } from 'app-wrapper/view/themes/themesColors';

import { SubPageHeaderContainer, SubPageHeaderTitle } from 'app-wrapper/view/guideline';

import { ShipmentContainersAddContainerModal } from './components';

import {
  ShipmentContainerTitleLeftPart,
} from './ShipmentContainersTitle.styled';

const BADGE_STYLE = {
  backgroundColor: 'transparent',
  borderColor: colors.gray43,
  color: 'black',
};

interface ICargoTitleComponentProps {
  amountOfCargos: number
}

export const ShipmentContainersTitleComponent: FC<ICargoTitleComponentProps> = ({
  amountOfCargos,
}) => {
  const { t } = useTranslation();

  return (
    <SubPageHeaderContainer>
      <ShipmentContainerTitleLeftPart>
        <SubPageHeaderTitle>
          {t('Containers')}
        </SubPageHeaderTitle>
        <Badge count={amountOfCargos} style={BADGE_STYLE} size="default" />
      </ShipmentContainerTitleLeftPart>
      <ShipmentContainersAddContainerModal />
    </SubPageHeaderContainer>
  );
};
