import React from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';

import { TemperatureControlComponent } from './TemperatureControl.component';

const TemperatureControlContainer = () => {
  const isUpdatePending = useSelector(R.selectors.bookingWizard.getIsContentUpdating);

  return (
    <TemperatureControlComponent
      isUpdatePending={isUpdatePending}
    />
  );
};

export { TemperatureControlContainer as TemperatureControl };
