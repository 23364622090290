import React from 'react';
import { useSelector } from 'react-redux';

import { R } from 'user-management/repository';
import { UC as shipmentOperationsUC } from 'shipment-operations/controllers';
import { UC } from 'user-management/controllers';

import { DocumentsComponent } from './Documents.component';

const DocumentsContainer = () => {
  const documentsTypesMap = useSelector(R.selectors.customer.getDocumentsTypesMap);
  const isOrgAdmin = useSelector(R.selectors.customer.getIsOrganizationAdmin);
  const isBLICRequired = useSelector(R.selectors.customer.getShouldRequireBLICDocument);

  return (
    <DocumentsComponent
      openDocumentUploadDrawer={shipmentOperationsUC.shipmentDrawer.openOrganizationDocumentUploadDrawer}
      documentTypesMap={documentsTypesMap}
      onDownload={UC.customer.downloadDocumentById}
      onDelete={UC.customer.deleteDocumentWithUpdate}
      isOrgAdmin={isOrgAdmin}
      isBLICRequired={isBLICRequired}
    />
  );
};

export { DocumentsContainer as Documents };
