import styled from 'styled-components';
import Checkbox from 'antd/es/checkbox/Checkbox';

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`;

export const StyledCheckbox = styled(Checkbox)`
  margin-top: 2px;
  pointer-events: none;
`;

export const Column = styled.div`
  margin-left: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const Name = styled.span`
  font-size: 14px;
  line-height: 22px;
`;

export const Departments = styled.div`
  font-size: 12px;
  line-height: 20px;
  color: ${({ theme }) => theme.themesColors.secondaryDot45};
`;
