import { RouteNames } from 'app-wrapper/constants';
import { validationEmail, validationPhone } from 'app-wrapper/utils';
import { BaseController, controller } from 'proto/BaseController';
import { EAccountDepartmentType, EAccountDrawerContent } from 'user-management/constants';
import { TGetOrganizationDepartmentsRequestContract } from 'user-management/models/contracts';

import { AccountDepartmentDTM, IAccountDepartmentDTM, OrganizationMemberDTM } from 'user-management/models/dtm';
import { R } from 'user-management/repository';
import { R as shipmentR } from 'shipment-operations/repository';

@controller
export class AccountDepartmentsController extends BaseController {
  public resetDepartments = async () => {
    this.dispatch(R.actions.accountDepartments.resetState());
  };

  public getAccountDepartments = async (id?: string) => R.services.accountDepartment.getOrganizationDepartments({ organizationId: `${id}` });

  public initAccountMember = async () => {
    const organization = await R.services.organization.getCurrentOrganization();

    this.dispatch(shipmentR.actions.shipmentPeople.setCurrentOrganization(organization));
  }

  public onRunAccountDepartmentsPage = async () => {
    this.dispatch(R.actions.accountDepartments.setDefaultIsRequest(true));

    const userOrganizationData = this.store.getState().userOrganizationData.organization;
    const listMembers: OrganizationMemberDTM[] = [];
    let departmentList: AccountDepartmentDTM[] = [];
    let organizationId: string | undefined = `${userOrganizationData?.id || ''}`;

    this.initAccountMember();

    if (!userOrganizationData?.id) {
      const resOrg = await R.services.organization.getCurrentOrganization();

      organizationId = `${resOrg.id || ''}`;

      if (!organizationId) {
        this.dispatch(R.actions.accountDepartments.setDefaultIsRequest(false));
        return;
      }
    }

    this.resetDepartments();

    try {
      const departmentCollected = await R.services.accountDepartment.getOrganizationDepartments({ organizationId: `${organizationId}` });
      departmentList = departmentCollected;
    } catch (e) {
      this.dispatch(R.actions.accountDepartments.setDefaultIsRequest(false));

      throw e;
    }

    departmentList.forEach((item) => {
      item.members?.forEach((itemMember) => listMembers.push(itemMember));
    });

    if (departmentList.length) {
      this.dispatch(R.actions.accountDepartments.setDefaultDepartmentList(departmentList));
      this.dispatch(R.actions.accountDepartments.setDefaultDMembersList(listMembers));
    }

    this.dispatch(R.actions.accountDepartments.setDefaultIsRequest(false));
  }

  public onNextPageAccountDepartmentsById = (id: string) => async () => {
    await this.resetDepartments();

    this.navigate(RouteNames.ACCOUNT_DEPARTMENTS_BY_ID(id));
  }

  public openAccountDepartmentsAddDrawer = async () => {
  }

  public onRunAccountDepartmentsByIdPage = async (departmentId?: string) => {
    const userOrganizationData = this.store.getState().userOrganizationData.organization;

    let department: AccountDepartmentDTM | undefined;
    const listMembers: OrganizationMemberDTM[] = [];
    let organizationId: string | undefined = `${userOrganizationData?.id || ''}`;

    this.dispatch(R.actions.accountDepartments.setDefaultIsRequest(true));

    if (!userOrganizationData?.id) {
      const resOrg = await R.services.organization.getCurrentOrganization();

      organizationId = `${resOrg.id || ''}`;

      if (!organizationId) {
        this.dispatch(R.actions.accountDepartments.setDefaultIsRequest(false));
        return;
      }
    }

    if (!departmentId) {
      return;
    }

    this.resetDepartments();

    try {
      const departmentCollected = await R.services.accountDepartment.getOrganizationDepartmentsById({
        organizationId: `${organizationId}`,
        departmentId,
      });
      department = departmentCollected;
    } catch (e) {
      this.dispatch(R.actions.accountDepartments.setDefaultIsRequest(false));

      throw e;
    }

    department?.members?.forEach((itemMember) => listMembers.push(itemMember));

    if (department) {
      this.dispatch(R.actions.accountDepartments.setDefaultDepartmentById({
        ...department,
      }));
      this.dispatch(R.actions.accountDepartments.setDefaultDMembersList(listMembers));
    }

    const organizationMembersList = await this.onLoadOrganizationMembers(departmentId);

    if (department) {
      this.dispatch(R.actions.accountDepartments.setDefaultDepartmentById({
        ...department,
        members: organizationMembersList,
      }));
    }

    this.dispatch(R.actions.accountDepartments.setDefaultIsRequest(false));
  }

  public onClickPageAccountDepartmentsByIdRemove = async (memberId?: string) => {
    const userOrganizationData = this.store.getState().userOrganizationData.organization;
    const department = this.store.getState().accountDepartments.defaultState;
    const member = department?.departmentById?.members?.find((item) => item.id === memberId);
    const departmentId = department?.departmentById?.id;

    this.dispatch(R.actions.accountDepartments.setDefaultIsRequest(true));

    try {
      await R.services.accountDepartment.deleteOrganizationDepartmentsByIdMemberByEmail({
        organizationId: `${userOrganizationData?.id || ''}`,
        departmentId: `${departmentId || ''}`,
        memberEmail: member?.email,
      });
    } catch (e) {
      this.dispatch(R.actions.accountDepartments.setDefaultIsRequest(false));

      throw e;
    }

    const memberNew = department?.departmentById?.members?.filter((item) => item.id !== memberId);
    const memberListNew = department?.membersList?.filter((item) => item.id !== memberId);
    const newDepartment: IAccountDepartmentDTM | undefined = department?.departmentById && {
      ...department?.departmentById,
      members: memberNew,
    };

    if (newDepartment) {
      this.dispatch(R.actions.accountDepartments.setDefaultDepartmentById(newDepartment));
      this.dispatch(R.actions.accountDepartments.setDefaultDMembersList(memberListNew || []));
    }

    this.dispatch(R.actions.accountDepartments.setDefaultIsRequest(false));
  }

  public onClickPageAccountDepartmentsByIdEditDepartment = async () => {
    const department = this.store.getState().accountDepartments.defaultState;

    this.dispatch(R.actions.accountDepartments.setUpdateDepartmentById(department.departmentById ? { ...department.departmentById } : undefined));

    if (!department?.membersList?.length) {
      const organizationMembersList = await this.onLoadOrganizationMembers();

      if (organizationMembersList) {
        this.dispatch(R.actions.accountDepartments.setDefaultDMembersList(organizationMembersList));
      }
    }

    const organizationMembersList = await this.onLoadOrganizationMembers(`${department.departmentById?.id || ''}`);

    if (department.departmentById) {
      this.dispatch(R.actions.accountDepartments.setUpdateDepartmentById({
        ...department.departmentById,
        members: organizationMembersList,
      }));
    }

    this.dispatch(R.actions.accountDepartments.setUpdateErrorsEditDepartmentDetailsDrawer(undefined));

    this.dispatch(R.actions.accountDrawer.setDynamicDrawerTitle(department.departmentById && AccountDepartmentDTM.fromPlain({
      ...department.departmentById,
    })?.getTypeName()));
    this.dispatch(R.actions.accountDrawer.openDrawer(EAccountDrawerContent.USER_ACCOUNT_DEPARTMENT_EDIT));
  }

  public onLoadOrganizationMembers = async (departmentId?: string) => {
    const userOrganizationData = this.store.getState().userOrganizationData.organization;
    const { accountDepartments } = this.store.getState();

    const findDepartment = (`${accountDepartments.defaultState?.departmentById?.id}` === departmentId ? [`${accountDepartments.defaultState?.departmentById?.type || ''}`] : undefined);

    let organizationMembersList: OrganizationMemberDTM[] | undefined;

    if (!userOrganizationData?.id) {
      return organizationMembersList;
    }

    organizationMembersList = await R.services.organization.getOrganizationMembersList(userOrganizationData.id, {
      departments: findDepartment,
    });

    if (!organizationMembersList) {
      return organizationMembersList;
    }

    return organizationMembersList;
  }

  public onClickPageAccountDepartmentsArrow = async () => {
    await this.navigate(RouteNames.ACCOUNT_DEPARTMENTS());
  }

  public onCloseAccountDepartmentEditDrawer = async () => {
    this.dispatch(R.actions.accountDepartments.setUpdateDepartmentById(undefined));

    this.dispatch(R.actions.accountDrawer.setDynamicDrawerTitle(''));
    this.dispatch(R.actions.accountDrawer.closeDrawer());
  }

  public onSaveAccountDepartmentEditDrawer = async () => {
    const userOrganizationData = this.store.getState().userOrganizationData.organization;
    const department = this.store.getState().accountDepartments.defaultState;
    const departmentUpdate = this.store.getState().accountDepartments.updateState;
    const member = department?.departmentById?.members?.find((item) => item.id);
    const departmentId = department?.departmentById?.id;

    if (!departmentUpdate || this.onValidateAccountDepartmentEditDrawer()) {
      return;
    }

    const departmentRequest: TGetOrganizationDepartmentsRequestContract = {
      id: departmentUpdate?.departmentById?.id,
      type: departmentUpdate?.departmentById?.type || '' as EAccountDepartmentType,
      email: departmentUpdate?.departmentById?.email || '',
      phone: departmentUpdate?.departmentById?.phone || '',
      createdBy: departmentUpdate?.departmentById?.createdBy,
      createdAt: departmentUpdate?.departmentById?.createdAt,
      members: departmentUpdate?.departmentById?.members?.map((item) => item.email),
    };

    this.dispatch(R.actions.accountDepartments.setDefaultIsRequest(true));

    try {
      await R.services.accountDepartment.putOrganizationDepartmentsById({
        organizationId: `${userOrganizationData?.id || ''}`,
        departmentId: `${departmentId || ''}`,
        memberEmail: member?.email,
        departments: departmentRequest,
      });
    } catch (e) {
      this.dispatch(R.actions.accountDepartments.setDefaultIsRequest(false));

      throw e;
    }

    const organizationMembersList = await this.onLoadOrganizationMembers(`${department.departmentById?.id || ''}`);
    const memberListNew = department?.membersList?.filter((item) => item.id);
    const newDepartment: IAccountDepartmentDTM | undefined = departmentUpdate?.departmentById && {
      ...departmentUpdate?.departmentById,
      members: organizationMembersList,
    };

    if (newDepartment) {
      this.dispatch(R.actions.accountDepartments.setDefaultDepartmentById(newDepartment));
      this.dispatch(R.actions.accountDepartments.setDefaultDMembersList(memberListNew || []));
    }

    this.dispatch(R.actions.accountDepartments.setDefaultIsRequest(false));

    this.dispatch(R.actions.accountDrawer.setDynamicDrawerTitle(''));
    this.dispatch(R.actions.accountDrawer.closeDrawer());
  }

  public onEditEmailAccountDepartmentEditDrawer = async (email: string) => {
    const validValue = email?.slice(0, 50);
    const department = this.store.getState().accountDepartments.updateState;

    this.dispatch(R.actions.accountDepartments.setUpdateDepartmentById(department.departmentById ? {
      ...department.departmentById,
      email: validValue,
    } : undefined));
    this.dispatch(R.actions.accountDepartments.setUpdateErrorsEditDepartmentDetailsDrawer({
      ...department?.errorsEditDepartmentDetailsDrawer,
      email: {
        ...department?.errorsEditDepartmentDetailsDrawer?.email,
        isVisited: true,
        isTooltipVisible: true,
      },
    }));

    this.onValidateAccountDepartmentEditDrawer();
  }

  public onEditPhoneAccountDepartmentEditDrawer = async (phone: string) => {
    const validValue = validationPhone(phone);
    const department = this.store.getState().accountDepartments.updateState;

    this.dispatch(R.actions.accountDepartments.setUpdateDepartmentById(department.departmentById ? {
      ...department.departmentById,
      phone: validValue.phone,
    } : undefined));
    this.dispatch(R.actions.accountDepartments.setUpdateErrorsEditDepartmentDetailsDrawer({
      ...department?.errorsEditDepartmentDetailsDrawer,
      phone: {
        ...department?.errorsEditDepartmentDetailsDrawer?.phone,
        isVisited: true,
        isTooltipVisible: true,
      },
    }));

    this.onValidateAccountDepartmentEditDrawer();
  }

  public onEditMembersAccountDepartmentEditDrawer = async (member?: string[]) => {
    const departmentDefault = this.store.getState().accountDepartments.defaultState;
    const department = this.store.getState().accountDepartments.updateState;
    const newMember = departmentDefault.membersList?.filter((item) => member?.includes(OrganizationMemberDTM.fromPlain(item).getFullName()));

    this.dispatch(R.actions.accountDepartments.setUpdateDepartmentById(department.departmentById ? {
      ...department.departmentById,
      members: newMember,
    } : undefined));
  }

  private onValidateAccountDepartmentEditDrawer = () => {
    let department = this.store.getState().accountDepartments.updateState;
    const departmentById = this.store.getState().accountDepartments.updateState?.departmentById;
    let isError = false;

    if (departmentById?.phone && !validationPhone(departmentById?.phone).errorMessage) {
      this.dispatch(R.actions.accountDepartments.setUpdateErrorsEditDepartmentDetailsDrawer({
        ...department?.errorsEditDepartmentDetailsDrawer,
        phone: {
          ...department?.errorsEditDepartmentDetailsDrawer?.phone,
          message: '',
        },
      }));
    } else if (departmentById?.phone && validationPhone(departmentById?.phone).errorMessage) {
      this.dispatch(R.actions.accountDepartments.setUpdateErrorsEditDepartmentDetailsDrawer({
        ...department?.errorsEditDepartmentDetailsDrawer,
        phone: {
          ...department?.errorsEditDepartmentDetailsDrawer?.phone,
          message: validationPhone(departmentById?.phone).errorMessage,
        },
      }));

      isError = true;
    }

    department = this.store.getState().accountDepartments.updateState;
    if (departmentById?.email === '') {
      this.dispatch(R.actions.accountDepartments.setUpdateErrorsEditDepartmentDetailsDrawer({
        ...department?.errorsEditDepartmentDetailsDrawer,
        email: {
          ...department?.errorsEditDepartmentDetailsDrawer?.email,
          message: '',
        },
      }));
    } else if (departmentById?.email) {
      this.dispatch(R.actions.accountDepartments.setUpdateErrorsEditDepartmentDetailsDrawer({
        ...department?.errorsEditDepartmentDetailsDrawer,
        email: {
          ...department?.errorsEditDepartmentDetailsDrawer?.email,
          message: validationEmail(departmentById?.email).errorMessage,
        },
      }));

      if (validationEmail(departmentById?.email).errorMessage) {
        isError = true;
      }
    }

    return isError;
  }
}
