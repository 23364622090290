import { ChargeCodeDesignation } from 'shipment-operations/constants';

export const priceByContainer = 'CONTAINER';
export const priceByBol = 'BOL';

export const occurrenceConditional = 'CONDITIONAL';
export const occurrenceAdditional = 'ADDITIONAL';
export const occurrenceMandatory = 'MANDATORY';

export const typeFee = 'FEE';

export const subjectToIncluded = 'INCLUDED';

export const additionalSubTypes = ['ACCESSORIAL', 'EXCEPTION', 'FREE_TIME'];

export const seaMode = ['OCEAN', 'ALL'];
export const seaLoadType = ['FCL', 'ALL'];

export const accessorialSubTypes = ['ACCESSORIAL', 'EXCEPTION'];

export const ChargeCodeDesignationNames: Record<ChargeCodeDesignation, string> = {
  FREIGHT: 'Freight',
  ORIGIN: 'Origin',
  DESTINATION: 'Destination',
};

export const MscChargeDescription = 'Miscellaneous Charge';
