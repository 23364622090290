import React, { FC, useContext } from 'react';
import { observer } from 'mobx-react-lite';

import { MobxStoresContext } from 'app-wrapper/mobxStores';

import { UC } from 'monetary/controllers';

import { RFQAddAdditionalServicesComponent } from './RFQAddAdditionalServices.component';

const ShipmentAddAdditionalServicesContainer: FC = observer(() => {
  const { additionalServicesDrawerStore } = useContext(MobxStoresContext);
  const addAdditionalServicesOriginDrawer = additionalServicesDrawerStore?.getAddAdditionalServicesOriginShipmentDrawer;
  const addAdditionalServicesFreightDrawer = additionalServicesDrawerStore?.getAddAdditionalServicesFreightShipmentDrawer;
  const addAdditionalServicesDestinationDrawer = additionalServicesDrawerStore?.getAddAdditionalServicesDestinationShipmentDrawer;

  const addAdditionalServicesDrawerTotalCost = additionalServicesDrawerStore?.getAddAdditionalServicesShipmentDrawerTotalCost;
  const addAdditionalServicesDrawerChecked = additionalServicesDrawerStore?.getAddAdditionalServicesShipmentDrawerChecked;

  return (
    <RFQAddAdditionalServicesComponent
      isShipment
      servicesOrigin={addAdditionalServicesOriginDrawer}
      servicesFreight={addAdditionalServicesFreightDrawer}
      servicesDestination={addAdditionalServicesDestinationDrawer}
      totalCost={addAdditionalServicesDrawerTotalCost}
      totalCheckedServices={addAdditionalServicesDrawerChecked?.length}
      onCloseDrawer={UC.AdditionalServices.closeAdditionalServiceShipmentDrawer}
      addServicesDrawer={UC.AdditionalServices.addAdditionalServiceShipmentDrawer}
      isLoading={additionalServicesDrawerStore.state.isLoading}
    />
  );
});

export { ShipmentAddAdditionalServicesContainer as ShipmentAddAdditionalServicesPage };
