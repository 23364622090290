import React from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';

import { InvoicePDFChargesComponent } from './InvoicePDFCharges.component';

const InvoicePDFChargesContainer = () => {
  const originCharges = useSelector(R.selectors.shareInvoice.getOriginCharges);
  const freightCharges = useSelector(R.selectors.shareInvoice.getFreightCharges);
  const destinationCharges = useSelector(R.selectors.shareInvoice.getDestinationCharges);

  return (
    <InvoicePDFChargesComponent
      originCharges={originCharges}
      freightCharges={freightCharges}
      destinationCharges={destinationCharges}
    />
  );
};

export { InvoicePDFChargesContainer as InvoicePDFCharges };
