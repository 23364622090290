import { HazmatDTM } from 'shipment-operations/models/dtm';

export const getHazmatStatus = <T extends HazmatDTM>(cargo: T) => {
  const {
    unNumber,
    imoClass,
    packingGroup,
    shippingName,
    msdsDocument,
    contactName,
    contactNumber,
  } = cargo;

  const msdsStatus = (
    msdsDocument
    && !!msdsDocument.length
    && msdsDocument[0].status === 'done'
    && !!msdsDocument[0].response.id
    && !!msdsDocument[0].response.name
    && !!msdsDocument[0].response.type
  );

  const isHazmat = (
    msdsStatus
    || !!imoClass
    || !!unNumber
    || !!packingGroup
    || !!shippingName
    || !!contactName
    || !!contactNumber
  );

  return isHazmat;
};
