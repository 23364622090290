import React from 'react';
import { observer } from 'mobx-react-lite';

import { useMobxStore } from 'app-wrapper/mobxStores';
import { UC } from 'shipment-operations/controllers';

import { ValidateCustomerDocumentsComponent } from './ValidateCustomerDocuments.component';

export const ValidateCustomerDocuments = observer(() => {
  const { shipmentServices } = useMobxStore();

  return (
    <ValidateCustomerDocumentsComponent
      documentActivities={shipmentServices.state.othersDocumentActivities}
      openDocumentUploadDrawer={UC.shipmentServices.openDocumentUploadDrawer}
      downloadDocument={UC.shipmentDocumentsAll.downloadDocument}
      removeServiceDocument={UC.shipmentServices.removeShipmentDocument}
    />
  );
});
