import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';
import { UC } from 'shipment-operations/controllers';

import { PostalCodeInputComponent } from './PostalCodeInput.component';

const PostalCodeInputContainer: FC = () => {
  const postalCode = useSelector(R.selectors.shippingParties.getPostalCode);
  const requiredError = useSelector(R.selectors.shippingParties.getIsRequiredErrorVisible);

  return (
    <PostalCodeInputComponent
      postalCode={postalCode}
      setPostalCode={UC.shippingParties.setPostalCode}
      error={requiredError}
    />
  );
};

const PostalCodeInputContainerCached = React.memo(PostalCodeInputContainer);

export {
  PostalCodeInputContainerCached as PostalCodeInput,
};
