import styled from 'styled-components';
import Collapse from 'antd/es/collapse';

export const Wrapper = styled.div.attrs({
  'data-class': 'RFQItemDetail_FAKExceptionWrapper',
})`
  padding: 0 16px;
  .ant-collapse {
    padding: 0 !important;
    margin-bottom: 0 !important;
    background-color: white !important;
  }
`;

export const SpinnerWrapper = styled.div`
  min-height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  .ant-spin {
    background: white !important;
  }
`;

export const StyledPanelHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const StyledPanelHeaderInner = styled(StyledPanelHeader).attrs({
  'data-class': 'RFQItemDetail_FAKExceptionStyledPanelHeaderInner',
})`
  min-height: 38px;
  height: auto;
  align-items: center;
`;

export const InnerTableWrapper = styled.div.attrs({
  'data-class': 'RFQItemDetail_FAKExceptionInnerTableWrapper',
})`
  margin-left: 50px;
  font-weight: 500;
  position: relative;
  border-top: 1px solid #EAEEF8;
`;

export const InnerPanel = styled.div.attrs({
  'data-class': 'RFQItemDetail_FAKExceptionInnerPanel',
})`
  font-weight: 400;
  margin-left: 50px;
  display: flex;
  justify-content: space-between;
  position: relative;
  border-top: 1px solid #EAEEF8;
`;

export const TablePointer = styled.div.attrs({
  'data-class': 'RFQItemDetail_FAKExceptionTablePointer',
})`
  width: 9px;
  height: 15px;
  border-left: 1px solid #D1D7E2;
  border-bottom: 1px solid #D1D7E2;
  position: absolute;
  left: -20px;
  top: 5px;
`;

export const StyledCollapse = styled(Collapse)`
  border-bottom: 1px solid #EAEEF8;
  &:last-child {
    border-bottom: none;
  }

  .ant-collapse-header {
    padding: 12px 0 !important;
  }
  
  .ant-collapse-content-box {
    padding: 0 !important;
  }
`;

export const StyledRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 6px 0;
`;

export const StyledCode = styled.span`
  font-weight: 400;
  font-size: 14px;
  color: #73819B;
`;
