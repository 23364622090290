import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ShipmentChargeChangeDTM } from 'app-wrapper/models/dtm';
import { ChargeCodeDesignation } from 'shipment-operations/constants';
import { MissingTextValue } from 'app-wrapper/view/guideline';
import { FooterTotalTable } from 'app-wrapper/view/drawers/ShipmentCostChangesDrawer/components';
import { CostChangeDifference } from 'app-wrapper/view/drawers/ShipmentCostChangesDrawer/components/CostChangesTable/components';
import {
  CustomTable,
  MissingValueWrap,
  TableCell,
  TableCellDifference,
  TableCellNumber,
  Tag,
} from 'app-wrapper/view/drawers/ShipmentCostChangesDrawer/components/CostChangesTable/CostChangesTable.styled';

import {
  Wrap,
  Title,
  HeaderTable,
  HeaderWrap,
  DesignationHeaderStyled,
} from './FeesAndServicesChargeChangesTable.styled';

interface IFeesAndServiceProps {
  charges: ShipmentChargeChangeDTM[];
}

export const FeesAndServicesChargeChangesTable: FC<IFeesAndServiceProps> = ({ charges }) => {
  const { t } = useTranslation();

  const originCharges = charges.filter(({ prevVersion, newVersion }) => (newVersion ? newVersion.designation === ChargeCodeDesignation.ORIGIN : prevVersion?.designation === ChargeCodeDesignation.ORIGIN));
  const freightCharges = charges.filter(({ prevVersion, newVersion }) => (newVersion ? newVersion.designation === ChargeCodeDesignation.FREIGHT : prevVersion?.designation === ChargeCodeDesignation.FREIGHT));
  const destinationCharges = charges.filter(({ prevVersion, newVersion }) => (newVersion ? newVersion.designation === ChargeCodeDesignation.DESTINATION : prevVersion?.designation === ChargeCodeDesignation.DESTINATION));

  const columns = useMemo(() => [
    {
      title: t('Charge'),
      width: '31%',
      key: 'object',
      align: 'left' as 'left',
    },
    {
      title: t('Old Value'),
      width: '23%',
      key: 'object',
      align: 'right' as 'right',
    },
    {
      title: t('Difference'),
      width: '23%',
      key: 'object',
      align: 'right' as 'right',
    },
    {
      title: t('New Value'),
      width: '23%',
      key: 'object',
      align: 'right' as 'right',
    },
  ], []);

  const chargesColumns = useMemo(() => [
    {
      title: t('Charge'),
      width: '31%',
      key: 'object',
      align: 'left' as 'left',
      render: (item: string, record: ShipmentChargeChangeDTM | {}) => {
        const costChange = record as ShipmentChargeChangeDTM;
        const { prevVersion, newVersion } = costChange;

        return (
          <TableCellNumber>
            {costChange.chargeDescription}

            {!prevVersion && newVersion ? (
              <Tag>
                {t('New')}
              </Tag>
            ) : null}
          </TableCellNumber>
        );
      },
    },
    {
      title: t('Old Value'),
      width: '23%',
      key: 'object',
      align: 'right' as 'right',
      render: (item: string, record: ShipmentChargeChangeDTM | {}) => {
        const costChange = record as ShipmentChargeChangeDTM;

        return (
          <TableCell>
            {costChange.prevVersion?.totalCost ? `$ ${costChange.prevVersion?.totalCost.toFixed(2)}` : (
              <MissingValueWrap>
                <MissingTextValue />
              </MissingValueWrap>
            )}
          </TableCell>
        );
      },
    },
    {
      title: t('Difference'),
      width: '23%',
      key: 'object',
      align: 'right' as 'right',
      render: (item: string, record: ShipmentChargeChangeDTM | {}) => {
        const costChange = record as ShipmentChargeChangeDTM;

        return (
          <TableCellDifference>
            <CostChangeDifference
              prevCost={costChange?.prevVersion?.totalCost || 0}
              newCost={costChange?.newVersion?.totalCost || 0}
            />
          </TableCellDifference>
        );
      },
    },
    {
      title: t('New Value'),
      width: '23%',
      key: 'object',
      align: 'right' as 'right',
      render: (item: string, record: ShipmentChargeChangeDTM | {}) => {
        const costChange = record as ShipmentChargeChangeDTM;

        return (
          <TableCell>
            {typeof costChange.newVersion?.totalCost === 'number' ? `$ ${costChange.newVersion?.totalCost.toFixed(2)}` : (
              <MissingValueWrap>
                <MissingTextValue />
              </MissingValueWrap>
            )}
          </TableCell>
        );
      },
    },
  ], [t]);

  if (!charges.length) {
    return null;
  }

  return (
    <>
      <Wrap>
        <Title>
          {t('Fees and Services')}
        </Title>

        <HeaderWrap>
          <HeaderTable
            columns={columns}
            pagination={false}
          />
        </HeaderWrap>

        {originCharges.length ? (
          <>
            <DesignationHeaderStyled>{t('Origin')}</DesignationHeaderStyled>

            <CustomTable
              showHeader={false}
              columns={chargesColumns}
              dataSource={originCharges}
              pagination={false}
            />
          </>
        ) : null}

        {freightCharges.length ? (
          <>
            <DesignationHeaderStyled>{t('Freight')}</DesignationHeaderStyled>

            <CustomTable
              showHeader={false}
              columns={chargesColumns}
              dataSource={freightCharges}
              pagination={false}
            />
          </>
        ) : null}

        {destinationCharges.length ? (
          <>
            <DesignationHeaderStyled>{t('Destination')}</DesignationHeaderStyled>

            <CustomTable
              showHeader={false}
              columns={chargesColumns}
              dataSource={destinationCharges}
              pagination={false}
            />
          </>
        ) : null}
      </Wrap>

      <FooterTotalTable costChanges={charges} />
    </>
  );
};
