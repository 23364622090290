import React, { FC } from 'react';
import Divider from 'antd/es/divider';
import { useTranslation } from 'react-i18next';
import isEmpty from 'lodash/fp/isEmpty';

import {
  HeaderTableWrapper,
  InnerContainer,
  StyledTotal,
  Title,
} from 'shipment-operations/view/components';
import { CustomTable } from 'shipment-operations/view/pages/CreditNote/components/CustomTable';
import { ExpandPanel } from 'shipment-operations/view/pages/ShipmentBillingInvoice/components';
import { calculateCreditNoteSum } from 'shipment-operations/view/pages/ShipmentBillingInvoice/utils/calculateSum';
import { ContainerViewDTM, IGroupedCreditNotesDTM } from 'shipment-operations/models/dtm';

interface ITransportationComponent {
  containers: ContainerViewDTM[]
  data: IGroupedCreditNotesDTM
  sum: number
}

const TransportationComponent: FC<ITransportationComponent> = ({
  sum, data, containers,
}) => {
  const { t } = useTranslation();

  if (isEmpty(data) && isEmpty(containers)) {
    return null;
  }

  return (
    <>
      <InnerContainer>
        <Title>{t('transportation')}</Title>
        <Divider style={{ margin: '0' }} />
        <HeaderTableWrapper>
          <span>{t('Containers')}</span>
          <span>{t('total')}</span>
        </HeaderTableWrapper>
        <Divider style={{ margin: '0' }} />
        {containers.map((item) => (
          <ExpandPanel container={item} sum={calculateCreditNoteSum(data[item.id])} key={item.id}>
            <CustomTable data={data[item.id]} />
          </ExpandPanel>
        ))}
      </InnerContainer>
      <StyledTotal>$ {sum.toFixed(2)}</StyledTotal>
    </>
  );
};

export { TransportationComponent };
