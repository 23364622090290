import { BaseController, controller } from 'proto/BaseController';
import { EShippingPartyTypes, EWorkgroupTypesEnum } from 'shipment-operations/constants';
import i18n from 'app-wrapper/i18n/i18n';
import { IWorkgroupMemberDTM, WorkgroupDTM } from 'shipment-operations/models/dtm';
import { R } from 'shipment-operations/repository';
import { IAddShipmentWorkgroupMemberContract } from 'shipment-operations/models/contracts';
import { EAccountDepartmentType, EOrganizationMemberRole, EShipmentOrganizationRole } from 'user-management/constants';
import { OrganizationMemberDTM } from 'user-management/models/dtm';
import { R as userManagementR } from 'user-management/repository';

@controller
export class ShipmentPeopleController extends BaseController {
  public init = async (shipmentId: string) => {
    this.dispatch(R.actions.shipmentPeople.setLoading(true));

    const shipment = await R.services.shipment.getShipmentShortById(+shipmentId);
    const organization = await userManagementR.services.organization.getCurrentOrganization();
    const shippingParties = await R.services.shippingParties.getList(shipmentId);

    this.dispatch(R.actions.shipmentPeople.setShippingParties(shippingParties));
    this.dispatch(R.actions.shipmentPeople.setShipment(shipment));
    this.dispatch(R.actions.shipmentPeople.setCurrentOrganization(organization));

    await this.initWorkgroup();
  }

  public initWorkgroup = async () => {
    const shipment = R.selectors.shipmentPeople.getShipment(this.store.getState());
    const currentOrganization = R.selectors.shipmentPeople.getCurrentOrganization(this.store.getState());

    if (!shipment || !currentOrganization) {
      return;
    }

    const members = await R.services.shipmentWorkgroup.getShipmentWorkgroup(String(shipment.id));

    const [anyMember] = members;

    this.dispatch(R.actions.shipmentPeople.setWorkgroupId(anyMember.workgroupId));

    if (currentOrganization.role === EOrganizationMemberRole.CUSTOMER) {
      this.groupWorkgroupsForCustomer(members);
    } else {
      this.groupWorkgroupsForPartner(members);
    }

    this.dispatch(R.actions.shipmentPeople.setWorkgroupMembers(members));
    this.dispatch(R.actions.shipmentPeople.setWorkgroupMembersEmails(members.map((member) => member.email)));
    this.dispatch(R.actions.shipmentPeople.setLoading(false));
  };

  public groupWorkgroupsForCustomer = (members: IWorkgroupMemberDTM[]) => {
    const currentOrganization = R.selectors.shipmentPeople.getCurrentOrganization(this.store.getState());

    if (!currentOrganization) {
      return;
    }

    const customerCompanyName = R.selectors.shipmentPeople.getShippingPartyCompanyName(EShippingPartyTypes.CUSTOMER)(this.store.getState());

    const workgroups = [
      WorkgroupDTM.fromPlain({
        name: customerCompanyName,
        organizationId: currentOrganization.id,
        type: EWorkgroupTypesEnum.CUSTOMER_REPRESENTATIVES,
        members: members.filter((member) => member.organization.organizationRole === EOrganizationMemberRole.CUSTOMER),
      }),
      WorkgroupDTM.fromPlain({
        name: i18n.t('Skypace Team'),
        type: EWorkgroupTypesEnum.SKYPACE_TEAM,
        members: members.filter((member) => member.organization.organizationRole === EOrganizationMemberRole.PARTNER || member.organization.organizationRole.includes(EOrganizationMemberRole.admin)),
      }),
    ];

    this.dispatch(R.actions.shipmentPeople.setWorkgroups(workgroups));
  }

  public groupWorkgroupsForPartner = (members: IWorkgroupMemberDTM[]) => {
    const shipment = R.selectors.shipmentPeople.getShipment(this.store.getState());
    const currentOrganization = R.selectors.shipmentPeople.getCurrentOrganization(this.store.getState());

    if (!shipment || !currentOrganization || !members.length) {
      return;
    }

    const destinationMembers = members.filter((member) => member.organization.shipmentRoles.includes(EShipmentOrganizationRole.DESTINATION_PARTNER) && !member.organization.organizationRole.includes(EOrganizationMemberRole.admin));
    const originMembers = members.filter((member) => member.organization.shipmentRoles.includes(EShipmentOrganizationRole.ORIGIN_PARTNER) && !member.organization.organizationRole.includes(EOrganizationMemberRole.admin));
    const adminMembers = members.filter((member) => member.organization.organizationRole.includes(EOrganizationMemberRole.admin));
    const isImportVisible = shipment.destinationPartnerOrgId === currentOrganization.id || destinationMembers.length;
    const isExportVisible = shipment.originPartnerOrgId === currentOrganization.id || originMembers.length;

    const customerCompanyName = R.selectors.shipmentPeople.getShippingPartyCompanyName(EShippingPartyTypes.CUSTOMER)(this.store.getState());
    const forwarderCompanyName = R.selectors.shipmentPeople.getShippingPartyCompanyName(EShippingPartyTypes.FORWARDER_AGENT)(this.store.getState());
    const deliveryCompanyName = R.selectors.shipmentPeople.getShippingPartyCompanyName(EShippingPartyTypes.DELIVERY_AGENT)(this.store.getState());

    const workgroups = [
      WorkgroupDTM.fromPlain({
        name: customerCompanyName,
        type: EWorkgroupTypesEnum.CUSTOMER_REPRESENTATIVES,
        members: members.filter((member) => member.organization.organizationRole === EOrganizationMemberRole.CUSTOMER),
      }),
      ...(isImportVisible ? [
        WorkgroupDTM.fromPlain({
          name: deliveryCompanyName,
          type: EWorkgroupTypesEnum.IMPORT,
          members: destinationMembers,
          organizationId: shipment.destinationPartnerOrgId,
        }),
      ] : []),
      ...(isExportVisible ? [
        WorkgroupDTM.fromPlain({
          type: EWorkgroupTypesEnum.EXPORT,
          name: forwarderCompanyName,
          members: originMembers,
          organizationId: shipment.originPartnerOrgId,
        }),
      ] : []),
      WorkgroupDTM.fromPlain({
        organizationId: adminMembers.length ? adminMembers[0].organization.id : 0,
        type: EWorkgroupTypesEnum.SKYPACE_REPRESENTATIVES,
        name: i18n.t('Skypace'),
        members: members.filter((member) => member.organization.organizationRole.includes(EOrganizationMemberRole.admin)),
      }),
    ];

    this.dispatch(R.actions.shipmentPeople.setWorkgroups(workgroups));
  }

  public setTempMember = (email: string) => {
    this.dispatch(R.actions.shipmentPeople.setTempMember(email));
  }

  public openAddMemberDrawer = async (workgroup?: WorkgroupDTM) => {
    if (!workgroup) {
      await this.initMemberDrawerForCustomer();
    } else {
      await this.initMemberDrawerForPartners(workgroup);
    }
  };

  public setRoleMember = (role: EAccountDepartmentType, email: string) => {
    this.dispatch(R.actions.shipmentPeople.setRolesToEmailsStateItem({ role, email }));
  }

  public initMemberDrawerForPartners = async (workgroup: WorkgroupDTM) => {
    const currentOrganization = R.selectors.shipmentPeople.getCurrentOrganization(this.store.getState());

    if (!currentOrganization) {
      return;
    }

    this.dispatch(R.actions.shipmentPeople.setIsDrawerOpened(true));
    this.dispatch(R.actions.shipmentPeople.setIsDrawerLoading(true));
    this.dispatch(R.actions.shipmentPeople.clearRolesToEmailsState());

    const roles: EAccountDepartmentType[] = [];

    workgroup.members.forEach((member) => {
      const { role, email } = member;

      roles.push(role);

      this.dispatch(R.actions.shipmentPeople.setRolesToEmailsInitialStateItem({ role, email }));
      this.dispatch(R.actions.shipmentPeople.setRolesToEmailsStateItem({ role, email }));
    });

    const members = await userManagementR.services.organization.getOrganizationMembersList(currentOrganization.id);

    this.dispatch(R.actions.shipmentPeople.setRolesToEdit(roles));
    this.dispatch(R.actions.shipmentPeople.setDrawerMembers(members.filter(({ enabled }) => enabled)));
    this.dispatch(R.actions.shipmentPeople.setIsDrawerLoading(false));
  };

  public initMemberDrawerForCustomer = async () => {
    const currentOrganization = R.selectors.shipmentPeople.getCurrentOrganization(this.store.getState());
    const workgroupMembers = R.selectors.shipmentPeople.getWorkgroupMembersEmails(this.store.getState());

    if (!currentOrganization) {
      return;
    }

    this.dispatch(R.actions.shipmentPeople.setIsDrawerOpened(true));
    this.dispatch(R.actions.shipmentPeople.setIsDrawerLoading(true));

    const isCustomer = currentOrganization.role === EOrganizationMemberRole.CUSTOMER;
    let members: OrganizationMemberDTM[];

    try {
      members = await userManagementR.services.organization.getOrganizationMembersList(currentOrganization.id);
    } catch (e) {
      this.dispatch(R.actions.shipmentPeople.setIsDrawerLoading(false));
      throw e;
    }

    if (!isCustomer) {
      members = members.filter((member) => !!member.departments.length);
    }

    this.dispatch(R.actions.shipmentPeople.setDrawerMembers(members.filter((member) => member.enabled && !workgroupMembers.includes(member.email))));
    this.dispatch(R.actions.shipmentPeople.setIsDrawerLoading(false));
  }

  public removeWorkgroupMember = async (memberId: number) => {
    const shipment = R.selectors.shipmentPeople.getShipment(this.store.getState());
    const workgroupId = R.selectors.shipmentPeople.getWorkgroupId(this.store.getState());

    if (!shipment) {
      return;
    }

    this.dispatch(R.actions.shipmentPeople.setLoading(true));

    await R.services.shipmentWorkgroup.removeShipmentWorkgroupMember(shipment.id, workgroupId, memberId);

    await this.initWorkgroup();
  }

  public editWorkgroup = async () => {
    const shipment = R.selectors.shipmentPeople.getShipment(this.store.getState());
    const workgroupId = R.selectors.shipmentPeople.getWorkgroupId(this.store.getState());
    const workgroupMembers = R.selectors.shipmentPeople.getWorkgroupMembers(this.store.getState());
    const currentOrganization = R.selectors.shipmentPeople.getCurrentOrganization(this.store.getState());
    const rolesToEmailsInitialState = R.selectors.shipmentPeople.getRolesToEmailsInitialState(this.store.getState());
    const rolesToEmailsState = R.selectors.shipmentPeople.getRolesToEmailsState(this.store.getState());
    const membersToDeleteIds: number[] = [];
    const membersToAdd: IAddShipmentWorkgroupMemberContract[] = [];

    if (!shipment || !currentOrganization) {
      return;
    }

    this.dispatch(R.actions.shipmentPeople.setIsDrawerLoading(true));

    Object.keys(rolesToEmailsState).forEach((role) => {
      const newMemberEmail = rolesToEmailsState[role as EAccountDepartmentType];
      const oldMemberEmail = rolesToEmailsInitialState[role as EAccountDepartmentType];

      if (newMemberEmail && newMemberEmail !== oldMemberEmail) {
        const oldMember = workgroupMembers.find((member) => member.email === oldMemberEmail);

        if (oldMember) {
          membersToDeleteIds.push(oldMember.id);

          membersToAdd.push({
            email: newMemberEmail,
            role: role as EAccountDepartmentType,
            organizationId: currentOrganization.id,
          });
        }
      }
    });

    await Promise.all(membersToDeleteIds.map((memberId) => R.services.shipmentWorkgroup.removeShipmentWorkgroupMember(shipment.id, workgroupId, memberId)));
    await Promise.all(membersToAdd.map((member) => R.services.shipmentWorkgroup.addShipmentWorkgroupMember(shipment.id, workgroupId, member)));

    this.dispatch(R.actions.shipmentPeople.setIsDrawerLoading(false));
    this.dispatch(R.actions.shipmentPeople.setLoading(true));
    this.closeAddMemberDrawer();
    await this.initWorkgroup();
  }

  public addWorkgroupMember = async () => {
    const shipment = R.selectors.shipmentPeople.getShipment(this.store.getState());
    const workgroupId = R.selectors.shipmentPeople.getWorkgroupId(this.store.getState());
    const currentOrganization = R.selectors.shipmentPeople.getCurrentOrganization(this.store.getState());
    const memberToAdd = R.selectors.shipmentPeople.getTempMember(this.store.getState());

    if (!shipment || !currentOrganization || !memberToAdd) {
      return;
    }

    this.dispatch(R.actions.shipmentPeople.setIsDrawerLoading(true));

    try {
      await R.services.shipmentWorkgroup.addShipmentWorkgroupMember(shipment.id, workgroupId, {
        email: memberToAdd,
        organizationId: currentOrganization.id,
        role: undefined,
      });
    } catch (e) {
      this.dispatch(R.actions.shipmentPeople.setIsDrawerLoading(false));
      throw e;
    }

    this.dispatch(R.actions.shipmentPeople.setIsDrawerLoading(false));
    this.closeAddMemberDrawer();
    this.dispatch(R.actions.shipmentPeople.setLoading(true));
    await this.initWorkgroup();
  };

  public closeAddMemberDrawer = () => {
    this.dispatch(R.actions.shipmentPeople.setTempMember(undefined));
    this.dispatch(R.actions.shipmentPeople.setIsDrawerOpened(false));
  }
}
