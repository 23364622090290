import { SelectProps } from 'antd/es/select';
import CountriesJsonRaw from './countries.json';

interface Country {
  [key: string]: string
}

export const CountriesJson: Country = CountriesJsonRaw;

export const COUNTRIES_SELECT_OPTIONS: SelectProps<string>['options'] = Object.keys(CountriesJson)
  .map((key) => ({
    label: CountriesJson[key],
    value: key,
  }));

export const isCountry = (key: keyof typeof CountriesJson) => Boolean(CountriesJson[key]);
