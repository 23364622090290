import React, {
  FC, memo, useCallback, useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { VerticalFormItemSvg } from 'app-wrapper/view/icons';
import themesColors from 'app-wrapper/view/themes/themesColors';
import { useOnclickOutsideHook } from 'app-wrapper/hooks';

import {
  FMMEWrapper,
  FMMEHeader,
  FMMEHeaderName,
  FMMEModal,
  FMMEModalWrapper,
  FMMEModalInputs,
  FMMEModalInputMin,
  FMMEModalInputMax,
  FMMEModalButtons,
  FMMEModalButtonReset,
  FMMEModalButtonApply,
  FMMEHeaderArrow,
  FMMEHeaderNameWrapper,
  FMMEHeaderUpdateDot,
} from './FilterMinMaxElement.styled';

interface IFilterMinMaxElementProps {
  name: string
  placeholderMin: string
  placeholderMax: string
  valueMin: string
  valueMax: string
  disabledReset: boolean
  onApply: () => void
  onReset: () => void
  onChangeMin: (value: string) => void
  onChangeMax: (value: string) => void
}

const FilterMinMaxElement: FC<IFilterMinMaxElementProps> = (props) => {
  const {
    name,
    placeholderMin,
    placeholderMax,
    valueMin,
    valueMax,
    disabledReset,
    onApply,
    onReset,
    onChangeMin,
    onChangeMax,
  } = props;
  const { t } = useTranslation();
  const [isActive, setIsActive] = useState(false);

  const handleClickOutside = useCallback(
    () => {
      setIsActive((prev) => {
        if (prev) {
          onApply();
        }

        return false;
      });
    },
    [setIsActive, onApply],
  );
  const refWrapper = useOnclickOutsideHook(handleClickOutside);

  const onChangeMinHandle = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onChangeMin(event.target.value);
    },
    [onChangeMin],
  );

  const onChangeMaxHandle = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onChangeMax(event.target.value);
    },
    [onChangeMax],
  );

  const onClickWrapper = useCallback(
    () => setIsActive((prev) => !prev),
    [setIsActive],
  );

  const onApplyHandler = useCallback(
    () => {
      setIsActive(false);

      onApply();
    },
    [disabledReset, onApply],
  );

  const onResetHandler = useCallback(() => {
    setIsActive(false);

    onReset();
  }, [onReset]);

  return (
    <FMMEWrapper ref={refWrapper}>
      <FMMEHeader>
        <FMMEHeaderNameWrapper
          onClick={onClickWrapper}
        >
          {!disabledReset && (
            <FMMEHeaderUpdateDot />
          )}
          <FMMEHeaderName
            isActive={isActive}
          >
            {name}
          </FMMEHeaderName>
          <FMMEHeaderArrow
            isActive={isActive}
          >
            <VerticalFormItemSvg
              style={{
                color: isActive ? themesColors?.primaryBranding6 : themesColors?.neutralBranding7,
              }}
              rotate={isActive ? 180 : 0}
            />
          </FMMEHeaderArrow>
        </FMMEHeaderNameWrapper>
        {isActive && (
          <FMMEModal>
            <FMMEModalWrapper>
              <FMMEModalInputs>
                <FMMEModalInputMin
                  value={valueMin}
                  onChange={onChangeMinHandle}
                  placeholder={placeholderMin}
                />
                <FMMEModalInputMax
                  value={valueMax}
                  onChange={onChangeMaxHandle}
                  placeholder={placeholderMax}
                />
              </FMMEModalInputs>
              <FMMEModalButtons>
                <FMMEModalButtonReset
                  onClick={onResetHandler}
                  disabled={disabledReset}
                >
                  {t('reset')}
                </FMMEModalButtonReset>
                <FMMEModalButtonApply
                  onClick={onApplyHandler}
                >
                  {t('apply')}
                </FMMEModalButtonApply>
              </FMMEModalButtons>
            </FMMEModalWrapper>
          </FMMEModal>
        )}
      </FMMEHeader>
    </FMMEWrapper>
  );
};

const cachedFilterMinMaxElement = memo(FilterMinMaxElement);

export { cachedFilterMinMaxElement as FilterMinMaxElement };
