import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { AppFiltersCheckboxGroupDTM } from 'app-wrapper/models/dtm';
import { FilterCheckboxElement } from 'app-wrapper/view/components';

interface ICategoryFilterComponentProps {
  hasSelectedCategoryFilters: boolean;
  categoryFiltersGroup: AppFiltersCheckboxGroupDTM[];
  setCategoryFilterByIndex: (index: number, filter: AppFiltersCheckboxGroupDTM) => void;
  resetCategoryFiltersGroup: () => void;
  applyFilters: () => void;
}

export const CategoryFilterComponent: FC<ICategoryFilterComponentProps> = ({
  categoryFiltersGroup,
  hasSelectedCategoryFilters,
  setCategoryFilterByIndex,
  resetCategoryFiltersGroup,
  applyFilters,
}) => {
  const { t } = useTranslation();

  return (
    <FilterCheckboxElement
      isRight
      name={t('Category')}
      group={categoryFiltersGroup}
      disabledReset={!hasSelectedCategoryFilters}
      onApply={applyFilters}
      onReset={resetCategoryFiltersGroup}
      onChangeGroup={setCategoryFilterByIndex}
    />
  );
};
