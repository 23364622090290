import { Type } from 'class-transformer';
import { IsOptional, ValidateNested } from 'class-validator';

import { IDefaultFieldErrors, DefaultFieldErrors } from 'monetary/models/errors';
import { BaseDTM } from 'proto/BaseDTM';

import { IFreightSelectFieldDTM, FreightSelectFieldDTM } from './freightSelectField.dtm';

interface IFreightClientInformationErrorsDTM {
  companyName?: IDefaultFieldErrors
}

export class FreightClientInformationErrorsDTM extends BaseDTM<IFreightClientInformationErrorsDTM> {
  @IsOptional()
  @ValidateNested()
  @Type(() => DefaultFieldErrors)
  companyName?: DefaultFieldErrors;
}

export interface IFreightClientInformationCompanyNameDTM {
  dataValues?: IFreightSelectFieldDTM[]
}

export class FreightClientInformationCompanyNameDTM extends BaseDTM<IFreightClientInformationCompanyNameDTM> {
  @IsOptional()
  @ValidateNested({ each: true })
  @Type(() => FreightSelectFieldDTM)
  dataValues?: FreightSelectFieldDTM[];
}

interface IFreightClientInformationDTM {
  companyName?: IFreightClientInformationCompanyNameDTM
  errors?: IFreightClientInformationErrorsDTM
}

export class FreightClientInformationDTM extends BaseDTM<IFreightClientInformationDTM> {
  @IsOptional()
  @ValidateNested()
  @Type(() => FreightClientInformationCompanyNameDTM)
  companyName?: FreightClientInformationCompanyNameDTM;

  @IsOptional()
  @ValidateNested()
  @Type(() => FreightClientInformationErrorsDTM)
  errors?: FreightClientInformationErrorsDTM;
}
