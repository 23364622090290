import styled from 'styled-components';

export const SubPage = styled.div.attrs({
  'data-class': 'SubPage',
})`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  max-width: 1660px;
  margin: 0 auto;
`;
