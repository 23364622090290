import React from 'react';

import { UC } from 'app-wrapper/controllers';
import { AgreeTermsAndConditionComponent } from './AgreeTermsAndCondition.component';

export const AgreeTermsAndConditionContainer = () => (
  <AgreeTermsAndConditionComponent
    showTermsOfConditionDrawer={UC.overview.showTermsOfConditionDrawer}
  />
);
