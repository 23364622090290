import styled from 'styled-components';
import AntTypography from 'antd/es/typography';

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

export const Title = styled(AntTypography)`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
`;

export const ItemsWrap = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Item = styled.div`
  padding: 8px 0;
  border-bottom: 1px dashed #EAEEF8;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;

  &:last-of-type {
    border-bottom: none;
    padding-bottom: 0;
  }
`;
