import styled from 'styled-components';

import { Wrapper, FlexCol } from 'app-wrapper/view/guideline';
import { Divider as BaseDivider } from 'app-wrapper/view/components';

export const Page = styled(Wrapper)``;

export const Content = styled(FlexCol)`
  padding: 16px;
  margin-top: 8px;
  border-radius: 3px;
  background-color: ${({ theme }) => theme.themesColors.neutralBranding1};
`;

export const Divider = styled(BaseDivider)`
  margin: 16px 0;
`;

export const ContentWrap = styled(FlexCol)`
  width: 50%;
  min-height: 78px;
  padding: 16px 0;

  &:last-of-type {
    border-left: 1px dashed ${({ theme }) => theme.themesColors.neutralBranding4};
    padding-left: 16px;
  }
`;

export const ContentWrapTitle = styled.span`
  margin-bottom: 12px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
`;
