import { createSelector } from 'reselect';

import { RootState } from 'app-wrapper/store';

const localState = (state: RootState) => state.partners;

const getLoading = createSelector(
  localState,
  (state) => state.isLoading,
);

const getPartners = createSelector(
  localState,
  (state) => state.partners,
);

const getPage = createSelector(
  localState,
  (state) => state.page,
);

const getIsPaginationEnded = createSelector(
  localState,
  (state) => state.isPaginationEnded,
);

export const partnersSelectors = {
  getLoading,
  getPartners,
  getPage,
  getIsPaginationEnded,
};
