import React, { FC, useMemo } from 'react';

import { HBLDocumentBOLDTM } from 'shipment-operations/models/dtm';
import { EShippingPartyTypes, TradeType } from 'shipment-operations/constants';

import {
  Main,
  PageWrapper,
  ParticipantCard,
  ShipmentTransport,
  GoodsDescription,
  CargoTables,
} from 'shipment-operations/view/components/HBLDocumentPDF/components';

import { Row, Column } from './SecondPart.styled';

interface SecondPageProps {
  data: HBLDocumentBOLDTM;
}

export const SecondPage: FC<SecondPageProps> = ({ data }) => {
  const { shipmentParties, transportationPlanInfo } = data;

  const houseConsignee = useMemo(() => shipmentParties.find(({ role }) => role === EShippingPartyTypes.HOUSE_CONSIGNEE), [shipmentParties]);
  const houseShipper = useMemo(() => shipmentParties.find(({ role }) => role === EShippingPartyTypes.HOUSE_SHIPPER), [shipmentParties]);
  const customer = useMemo(() => (data ? data.shipmentParties.find(({ role }) => role === EShippingPartyTypes.CUSTOMER) : undefined), [data]);
  const ultimateCustomer = useMemo(() => (data ? data.shipmentParties.find(({ role }) => role === EShippingPartyTypes.ULTIMATE_CUSTOMER) : undefined), [data]);

  const shipperOnBehalfTitle = useMemo(() => {
    if (!data) {
      return undefined;
    }

    const { paymentTerms } = data;

    if (customer && ultimateCustomer && customer.isFreightForwarderOrgType && paymentTerms.tradeType === TradeType.EXPORT) {
      return `${customer.company?.name} on behalf of
        ${ultimateCustomer.company?.name}
      `;
    }

    return undefined;
  }, [data, customer, ultimateCustomer]);

  const consigneeOnBehalfTitle = useMemo(() => {
    if (!data) {
      return undefined;
    }

    const { paymentTerms } = data;

    if (customer && ultimateCustomer && customer.isFreightForwarderOrgType && paymentTerms.tradeType === TradeType.IMPORT) {
      return `${customer.company?.name} on behalf of
        ${ultimateCustomer.company?.name}
      `;
    }

    return undefined;
  }, [data, customer, ultimateCustomer]);

  return (
    <PageWrapper>
      <Main>
        <Row>
          <Column>
            {houseShipper ? (
              <ParticipantCard data={houseShipper} onBehalfTitle={shipperOnBehalfTitle} />
            ) : null}
          </Column>
          <Column>
            {houseConsignee ? (
              <ParticipantCard data={houseConsignee} onBehalfTitle={consigneeOnBehalfTitle} />
            ) : null}
          </Column>
        </Row>
        <ShipmentTransport transportationPlanInfo={transportationPlanInfo} />
        <GoodsDescription cargos={data.cargos} cargo={data.cargo} />
        <CargoTables containers={data.containersWithCargo} temperatureControl={data.temperatureControl} />
      </Main>
    </PageWrapper>
  );
};
