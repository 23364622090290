import styled from 'styled-components';

import { divFlex } from 'app-wrapper/view/themes/mixin';

export const CIContactInformationWrapper = styled.div.attrs({
  'data-class': 'CIContactInformationWrapper',
})`
  ${divFlex}
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const CIContactInformationIconBG = styled.div.attrs({
  'data-class': 'CIContactInformationIconBG',
})`
  ${divFlex}
  align-items: center;
  justify-content: center;

  width: 32px;
  min-width: 32px;
  height: 32px;
  min-height: 32px;

  background-color: transparent;
  border-radius: 4px;
  color: ${({ theme }) => theme.themesColors.primaryBranding5};
  border: 1px solid ${({ theme }) => theme.themesColors.neutralBranding4};
  font-size: 14px;

  svg {
    color: ${({ theme }) => theme.themesColors.primaryBranding7};
  }
`;

export const CIContactInformationRow = styled.div.attrs({
  'data-class': 'CIContactInformationRow',
})`
  ${divFlex}
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-top: 12px;
`;

export const CIContactInformationItem = styled.div.attrs({
  'data-class': 'UserOrganizationIconBG',
})`
  ${divFlex}
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;

  width: 50%;
`;

export const CIContactInformationHeader = styled.div.attrs({
  'data-class': 'CIContactInformationHeader',
})`
  ${divFlex}

  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
`;

export const CIContactInformationData = styled.div.attrs({
  'data-class': 'CIContactInformationData',
})`
  ${divFlex}
  flex-flow: column nowrap;
  justify-content: center;
  align-items: flex-start;

  margin-left: 16px;
`;

export const CIContactInformationTitle = styled.div.attrs({
  'data-class': 'CIContactInformationTitle',
})`
  ${divFlex}

  font-size: 12px;
  color: ${({ theme }) => theme.themesColors.darkGrayMiddle};
  line-height: 20px;
  font-weight: 400;
`;

export const CIContactInformationLink = styled.div.attrs({
  'data-class': 'CIContactInformationLink',
})`
  ${divFlex}

  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: ${({ theme }) => theme.themesColors.primaryBranding7};
`;

export const CIContactInformationValue = styled.div.attrs({
  'data-class': 'CIContactInformationValue',
})`
  ${divFlex}

  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
`;
