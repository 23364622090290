import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import {
  SBLRoutingInformation,
  SBLRoutingTitle,
  SBLRoutingRow,
  SBLRoutingItem,
  SBLRoutingItemTitle,
  SBLRoutingItemSubTitle,
} from './SBLRoutingInformation.styled';

interface ISBLRoutingInformationComponentProps {
  items: Array<{
    title: string;
    subtitle: string;
  }>;
}

export const SBLRoutingInformationComponent: FC<ISBLRoutingInformationComponentProps> = ({ items }) => {
  const { t } = useTranslation();

  return (
    <SBLRoutingInformation>
      <SBLRoutingTitle>
        {t('Routing')}
      </SBLRoutingTitle>

      <SBLRoutingRow>
        {items.map(({ title, subtitle }) => (
          <SBLRoutingItem key={title}>
            <SBLRoutingItemTitle>
              {title}
            </SBLRoutingItemTitle>

            <SBLRoutingItemSubTitle>
              {subtitle}
            </SBLRoutingItemSubTitle>
          </SBLRoutingItem>
        ))}
      </SBLRoutingRow>
    </SBLRoutingInformation>
  );
};
