import React, { forwardRef } from 'react';
import AntAutoComplete, { AutoCompleteProps } from 'antd/es/auto-complete';
import { RefSelectProps } from 'antd/es/select';

export const AutoComplete = forwardRef<RefSelectProps, AutoCompleteProps>(
  (props, ref) => (
    <AntAutoComplete
      className="AutoComplete"
      ref={ref}
      {...props}
    />
  ),
);
