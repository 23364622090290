import moment, { Moment } from 'moment';
import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'antd/es/modal';

import { Button } from 'app-wrapper/view/components';
import { DateDtm } from 'app-wrapper/models/dtm';

import { ECarrierSCAC, CarrierSCACNames } from 'monetary/constants';
import { FreightSelectFieldDTM } from 'monetary/models/dtm';

import {
  SurchargesRateDownloadModalBody,
  SurchargesRateDownloadModalDatePicker,
  SurchargesRateDownloadModalFooter,
  SurchargesRateDownloadModalItem,
  SurchargesRateDownloadModalSelect,
  SurchargesRateDownloadModalTitle,
} from './SurchargesRateDownloadModal.styled';

const timeFormat = 'DD/MM/YYYY';

const carrierSCACOptions = Object.keys(ECarrierSCAC).map((ECarrierSCACItem) => {
  const item = ECarrierSCACItem as ECarrierSCAC;
  return {
    label: CarrierSCACNames[item],
    value: item,
  };
});

const modalWidth = 416;

interface IOceanRateItemComponentProps {
  isOpen: boolean
  isLoading: boolean
  isFormDataFilled: boolean
  carrierSCACList: ECarrierSCAC[]
  isOriginLocationSearchListLoading: boolean
  originLocationSearchList: FreightSelectFieldDTM[]
  isDestinationLocationSearchListLoading: boolean
  destinationLocationSearchList: FreightSelectFieldDTM[]
  date?: DateDtm
  originLocationValue?: string
  destinationLocationValue?: string
  onDateChange: (date: DateDtm | null) => void
  onDateBlur: () => void
  onOriginLocationChange: (code: string) => void
  onOriginLocationBlur: () => void
  onOriginLocationSearch: (searchValue: string) => void
  onDestinationLocationChange: (code: string) => void
  onDestinationLocationBlur: () => void
  onDestinationLocationSearch: (searchValue: string) => void
  onCarrierSCACChange: (scacList: ECarrierSCAC[]) => void
  onDownload: () => void
  onClose: () => void
}

export const SurchargesRateDownloadModalComponent: FC<IOceanRateItemComponentProps> = ({
  carrierSCACList,
  date,
  destinationLocationSearchList,
  destinationLocationValue,
  isDestinationLocationSearchListLoading,
  isFormDataFilled,
  isLoading,
  isOpen,
  isOriginLocationSearchListLoading,
  onCarrierSCACChange,
  onClose,
  onDateBlur,
  onDateChange,
  onDestinationLocationBlur,
  onDestinationLocationChange,
  onDestinationLocationSearch,
  onDownload,
  onOriginLocationBlur,
  onOriginLocationChange,
  onOriginLocationSearch,
  originLocationSearchList,
  originLocationValue,
}) => {
  const { t } = useTranslation();

  const onDateChangeHandler = useCallback((dateValue: Moment | null) => {
    const newDate = dateValue ? DateDtm.fromPlain({
      date: dateValue?.format(),
      offset: moment().utcOffset(),
    }) : null;

    onDateChange(newDate);
  }, []);

  const onDateBlurHandler = useCallback(() => {
    onDateBlur();
  }, []);

  const onOriginLocationChangeHandler = useCallback((code: string) => {
    onOriginLocationChange(code);
  }, []);

  const onOriginLocationBlurHandler = useCallback(() => {
    onOriginLocationBlur();
  }, []);

  const onOriginLocationSearchHandler = useCallback((searchValue: string) => {
    onOriginLocationSearch(searchValue);
  }, []);

  const onDestinationLocationChangeHandler = useCallback((code: string) => {
    onDestinationLocationChange(code);
  }, []);

  const onDestinationLocationBlurHandler = useCallback(() => {
    onDestinationLocationBlur();
  }, []);

  const onDestinationLocationSearchHandler = useCallback((searchValue: string) => {
    onDestinationLocationSearch(searchValue);
  }, []);

  const onCarrierSCACChangeHandler = useCallback((values: string[]) => {
    onCarrierSCACChange(values as ECarrierSCAC[]);
  }, []);

  const onDownloadHandler = useCallback(() => {
    onDownload();
  }, []);

  const onCloseHandler = useCallback(() => {
    onClose();
  }, []);

  return (
    <Modal
      visible={isOpen}
      closable={false}
      width={modalWidth}
      onCancel={onCloseHandler}
      footer={(
        <SurchargesRateDownloadModalFooter>
          <Button
            type="ghost"
            disabled={isLoading}
            onClick={onCloseHandler}
          >
            {t('Cancel')}
          </Button>
          <Button
            onClick={onDownloadHandler}
            loading={isLoading}
            disabled={!isFormDataFilled}
          >
            {t('Download')}
          </Button>
        </SurchargesRateDownloadModalFooter>
      )}
    >
      <SurchargesRateDownloadModalBody>
        <SurchargesRateDownloadModalTitle>{t('Download Rates')}</SurchargesRateDownloadModalTitle>
        <SurchargesRateDownloadModalItem>
          <span>{t('Date')} *</span>
          <SurchargesRateDownloadModalDatePicker
            disabled={isLoading}
            value={date?.getDateAsMomentWithOffset() || null}
            format={timeFormat}
            // isError={!!effectiveDateError}
            onChange={onDateChangeHandler}
            onBlur={onDateBlurHandler}
            allowClear={false}
          />
        </SurchargesRateDownloadModalItem>
        <SurchargesRateDownloadModalItem>
          <span>{t('Origin Location UNLOCO')} *</span>
          <SurchargesRateDownloadModalSelect
            showSearch
            loading={isOriginLocationSearchListLoading}
            disabled={isLoading}
            showArrow={false}
            filterOption={false}
            // isError={!!originLocationError}
            options={
              originLocationSearchList.map((item) => ({
                label: item.description || '',
                value: item.code || '',
              }))
            }
            value={originLocationValue}
            onSearch={onOriginLocationSearchHandler}
            onChange={onOriginLocationChangeHandler}
            onBlur={onOriginLocationBlurHandler}
          />
        </SurchargesRateDownloadModalItem>
        <SurchargesRateDownloadModalItem>
          <span>{t('Destination Location UNLOCO')} *</span>
          <SurchargesRateDownloadModalSelect
            showSearch
            loading={isDestinationLocationSearchListLoading}
            disabled={isLoading}
            showArrow={false}
            filterOption={false}
            // isError={!!destinationLocationError}
            options={
              destinationLocationSearchList.map((item) => ({
                label: item.description || '',
                value: item.code || '',
              }))
            }
            value={destinationLocationValue}
            onSearch={onDestinationLocationSearchHandler}
            onChange={onDestinationLocationChangeHandler}
            onBlur={onDestinationLocationBlurHandler}
          />
        </SurchargesRateDownloadModalItem>
        <SurchargesRateDownloadModalItem>
          <span>{t('Carrier SCAC')}</span>
          <SurchargesRateDownloadModalSelect
            showSearch
            mode="multiple"
            disabled={isLoading}
            options={carrierSCACOptions}
            showArrow={false}
            filterOption={false}
            value={carrierSCACList}
            onChange={onCarrierSCACChangeHandler}
          />
        </SurchargesRateDownloadModalItem>
      </SurchargesRateDownloadModalBody>
    </Modal>
  );
};
