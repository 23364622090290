import { RootState } from 'app-wrapper/store';
import { createSelector } from 'reselect';

const localState = (state: RootState) => state.routes;

const getLastPath = createSelector(
  localState,
  (state) => state.lastPath,
);

const getAssetManifestHash = createSelector(
  localState,
  (state) => state.assetManifestHash,
);

export const routesSelectors = {
  getLastPath,
  getAssetManifestHash,
};
