import React, { FC } from 'react';

import {
  SupportDialogBlockContent,
  SupportDialogBlockFooter,
  SupportDialogBlockHeader,
  SupportDialogBlockIonWrap,
  SupportDialogBlockTextWrap,
  SupportDialogBlockWrap,
} from './SupportDialogBlock.styled';

interface ISupportDialogBlockProps {
  icon?: React.ReactNode;
  iconColor?: string;
  headerText?: string;
  footerText?: string;
  marginBottom?: string;
}

export const SupportDialogBlockComponent: FC<ISupportDialogBlockProps> = (props) => {
  const {
    icon,
    iconColor,
    headerText,
    footerText,
    marginBottom,
  } = props;

  return (
    <SupportDialogBlockWrap marginBottom={marginBottom}>
      <SupportDialogBlockContent>
        <SupportDialogBlockIonWrap iconColor={iconColor}>
          {icon || null}
        </SupportDialogBlockIonWrap>

        <SupportDialogBlockTextWrap>
          <SupportDialogBlockHeader>
            {headerText || null}
          </SupportDialogBlockHeader>

          <SupportDialogBlockFooter>
            {footerText || null}
          </SupportDialogBlockFooter>
        </SupportDialogBlockTextWrap>
      </SupportDialogBlockContent>
    </SupportDialogBlockWrap>
  );
};
