import React, {
  FC, memo, useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
// import Skeleton from 'antd/es/skeleton';
import { UserOutlined } from '@ant-design/icons';

import { REQUEST_STATUS } from 'app-wrapper/constants';
import { TableSkeleton } from 'app-wrapper/view/components';
import { AccountDepartmentDTM } from 'user-management/models/dtm';

import {
  AccountDprtmContentTableContentLink,
  AccountDprtmContentTableContentItem,
  AccountDprtmContentTableContentItemTop,
  AccountDprtmContentTableContentTitle,
  AccountDprtmContentTableHeader,
  AccountDprtmContentTableHeaderItem,
  AccountDprtmSpanEllipsis,
  AccountDprtmContentTableContact,
  AccountDprtmContentTableContactText,
  AccountDprtmContentTableContactWrap,
} from './AccountDprtmTable.styled';

interface IAccountDprtmTableProps {
  onStart: () => void;
  nextPageTasks: () => void;
  onClickDepartmentItem: (id: string) => () => void;
  isLoad?: boolean;
  isLoadAdd?: string;
  allData?: AccountDepartmentDTM[];
  isEnd?: boolean;
}

const AccountDprtmTable: FC<IAccountDprtmTableProps> = (props) => {
  const {
    onStart,
    nextPageTasks,
    onClickDepartmentItem,
    isLoad,
    isLoadAdd,
    allData,
    isEnd,
  } = props;

  const { t } = useTranslation();

  useEffect(() => {
    onStart();
  }, []);

  return (
    <>

      <AccountDprtmContentTableHeader>
        <AccountDprtmContentTableHeaderItem
          width="28%"
        >
          {t('Department')}
        </AccountDprtmContentTableHeaderItem>
        <AccountDprtmContentTableHeaderItem
          width="28%"
        >
          {t('Email')}
        </AccountDprtmContentTableHeaderItem>
        <AccountDprtmContentTableHeaderItem
          width="28%"
        >
          {t('Phone')}
        </AccountDprtmContentTableHeaderItem>
        <AccountDprtmContentTableHeaderItem
          width="15%"
        >
          {t('Members')}
        </AccountDprtmContentTableHeaderItem>
      </AccountDprtmContentTableHeader>

      <>

        <InfiniteScroll
          dataLength={Number(allData?.length || 0)}
          next={nextPageTasks}
          hasMore={!isEnd}
          loader={
            isLoad
            && (<TableSkeleton />)
          }
          scrollableTarget="Wrapper"
        >
          {allData?.map((item) => (
            <AccountDprtmContentTableContentItem key={`AccountDprtmContentTableContentItem_${item.customId}`}>
              <AccountDprtmContentTableContentItemTop>
                <AccountDprtmContentTableContentLink
                  onClick={onClickDepartmentItem(`${item.id || ''}`)}
                >
                  <AccountDprtmSpanEllipsis>
                    {item?.getTypeName() || ''}
                  </AccountDprtmSpanEllipsis>
                </AccountDprtmContentTableContentLink>

                <AccountDprtmContentTableContentTitle isLeft>
                  <AccountDprtmSpanEllipsis>
                    {item.email || ''}
                  </AccountDprtmSpanEllipsis>
                </AccountDprtmContentTableContentTitle>

                <AccountDprtmContentTableContentTitle isLeft>
                  <AccountDprtmSpanEllipsis>
                    {item?.phone || ''}
                  </AccountDprtmSpanEllipsis>
                </AccountDprtmContentTableContentTitle>

                <AccountDprtmContentTableContactWrap>
                  <AccountDprtmContentTableContact>
                    <UserOutlined />

                    <AccountDprtmContentTableContactText>
                      {item?.membersCount}
                    </AccountDprtmContentTableContactText>
                  </AccountDprtmContentTableContact>
                </AccountDprtmContentTableContactWrap>
              </AccountDprtmContentTableContentItemTop>

            </AccountDprtmContentTableContentItem>
          ))}
        </InfiniteScroll>
        {isLoadAdd === REQUEST_STATUS.pending && (<TableSkeleton />)}
      </>

    </>
  );
};

const AccountDprtmTableCache = memo(AccountDprtmTable);

export { AccountDprtmTableCache as AccountDprtmTable };
