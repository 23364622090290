import React from 'react';
import { observer } from 'mobx-react-lite';

import { useMobxStore } from 'app-wrapper/mobxStores';

import { WorkflowDetailsComponent } from './WorkflowDetails.component';

export const WorkflowDetails = observer(() => {
  const { shipmentServices } = useMobxStore();

  return (
    <WorkflowDetailsComponent
      isInEditMode={shipmentServices.state.isInEditMode}
      isManager={shipmentServices.state.isManager}
      isProvider={!!shipmentServices.state.documentActivities.length}
      service={shipmentServices.state.service}
    />
  );
});
