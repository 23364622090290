import React, { memo } from 'react';
import { useSelector } from 'react-redux';

import { UC } from 'shipment-operations/controllers';
import { UC as appUC } from 'app-wrapper/controllers';
import { R } from 'shipment-operations/repository';

import { Footer } from './Footer.component';

const FooterContainer = memo(({ typeDrawer }: { typeDrawer: string }) => {
  const addLoading = useSelector(R.selectors.addRates.getIsLoadingAddCharges);

  return (
    <Footer
      onCancel={appUC.drawer.closeDrawer}
      onOk={UC.addRates.saveCharges}
      addLoading={addLoading}
      typeDrawer={typeDrawer}
    />
  );
});

export { FooterContainer as Footer };
