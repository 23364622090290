import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';

import { PackagingGroupSelectComponent } from './PackagingGroupSelect.component';

interface PackagingGroupSelectContainerProps {
  cargoId: number;
}

const PackagingGroupSelectContainer: FC<PackagingGroupSelectContainerProps> = ({ cargoId }) => {
  const { packingGroup, errors } = useSelector(R.selectors.manualBookingWizard.getCargoById(cargoId));
  const { packingGroup: error } = errors;

  return (
    <PackagingGroupSelectComponent
      packingGroup={packingGroup}
      error={!!error}
    />
  );
};

export { PackagingGroupSelectContainer as PackagingGroupSelect };
