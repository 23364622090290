import { IAuthState } from 'authentication/models/states';

export const AuthInitialState: IAuthState = {
  status: '',
  isLoading: true,
  wasSignUpSuccessful: false,
  error: '',
  user: {
    email: '',
    firstName: '',
    lastName: '',
    userId: undefined,
    isAdmin: undefined,
  },
  signUp: {
    email: '',
    password: '',
    firstName: '',
    lastName: '',
  },
  errors: {
    email: {
      isBlur: false,
      isFocus: false,
      message: undefined,
    },
    password: {
      isBlur: false,
      isFocus: false,
      message: undefined,
    },
  },
  errorsSignUp: {
    email: {
      isBlur: false,
      isFocus: false,
      message: undefined,
    },
    password: {
      isBlur: false,
      isFocus: false,
      message: undefined,
    },
    firstName: {
      isBlur: false,
      isFocus: false,
      message: undefined,
    },
    lastName: {
      isBlur: false,
      isFocus: false,
      message: undefined,
    },
  },
  name: '',
  username: '',
  password: '',
  email: '',
};
