import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';

import { CarrierContactCardComponent } from './CarrierContactCard.component';

const CarrierContactCardContainer: FC = () => {
  const isLoading = useSelector(R.selectors.transportationOverview.getLoading);
  const transporationOverview = useSelector(R.selectors.transportationOverview.getTransportationOverview);

  return (
    <CarrierContactCardComponent
      isLoading={isLoading}
      name={transporationOverview.contactName}
      phoneList={transporationOverview.phoneList}
      emailList={transporationOverview.emailList}
    />
  );
};

export {
  CarrierContactCardContainer as CarrierContactCard,
};
