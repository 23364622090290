import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import {
  RFQServiceByIdContentRoutesTransportation,
} from 'monetary/models/dtm/Quotas';
import { RouteLegDTM } from 'shipment-operations/models/dtm';

import {
  Row,
  Leg,
  LastRow,
  FirstRow,
  LegName,
  LegNameText,
  LegNameTextBold,
  CheckIcon,
  CircleWrap,
  CircleIcon,
  InputsWrap,
} from './TransportLegForm.styled';
import {
  VesselInput,
  VoyageInput,
  DatePicker,
} from './components';

interface TransportLegFormComponentProps {
  transportation: RFQServiceByIdContentRoutesTransportation;
  routeLeg: RouteLegDTM;
  index: number;
}

export const TransportLegFormComponent: FC<TransportLegFormComponentProps> = ({ transportation, index, routeLeg }) => {
  const { t } = useTranslation();

  return (
    <Leg>
      <FirstRow>
        <LegName>
          <CheckIcon />

          <LegNameTextBold>
            {t('Transport Leg ')}
            {index + 1}
          </LegNameTextBold>
        </LegName>

        <InputsWrap>
          <VesselInput id={transportation.id} />
          <VoyageInput id={transportation.id} />
        </InputsWrap>
      </FirstRow>

      <Row>
        <LegName>
          <CircleWrap>
            <CircleIcon />
          </CircleWrap>

          <LegNameText>
            {routeLeg.departureLocation.getLocationName()}
          </LegNameText>
        </LegName>

        <DatePicker id={transportation.id} type="ETD" />
      </Row>

      <LastRow>
        <LegName>
          <CircleWrap>
            <CircleIcon />
          </CircleWrap>

          <LegNameText>
            {routeLeg.arrivalLocation.getLocationName()}
          </LegNameText>
        </LegName>

        <DatePicker id={transportation.id} type="ETA" />
      </LastRow>
    </Leg>
  );
};
