import { Type } from 'class-transformer';
import {
  IsBoolean,
  IsDefined, IsNumber, IsOptional, IsString, ValidateNested,
} from 'class-validator';
import { BaseDTM } from 'proto/BaseDTM';

export interface IDetailAccessorialsContainerDTM {
  name: string
  steps: string[]
  summary: number
  cost?: number
  totalUnits?: number
  perUnit?: string
  applianceRangeMinValue?: number
  containerType?: string
}

export class DetailAccessorialsContainerDTM extends BaseDTM<IDetailAccessorialsContainerDTM> {
  @IsDefined()
  @IsString()
  name: string;

  @IsDefined()
  @IsString({ each: true })
  steps: string[];

  @IsDefined()
  @IsNumber()
  summary: number;

  @IsOptional()
  @IsNumber()
  cost: number;

  @IsOptional()
  @IsNumber()
  totalUnits: number;

  @IsOptional()
  @IsString()
  perUnit: string;

  @IsOptional()
  @IsNumber()
  applianceRangeMinValue: number;

  @IsOptional()
  @IsString()
  containerType: string;
}

export interface IDetailAccessorialsTransportationDTM {
  summary: number
  containers: IDetailAccessorialsContainerDTM[]
}

export class DetailAccessorialsTransportationDTM extends BaseDTM<IDetailAccessorialsTransportationDTM> {
  @IsDefined()
  @IsNumber()
  summary: number;

  @IsDefined()
  @ValidateNested({ each: true })
  @Type(() => DetailAccessorialsContainerDTM)
  containers: DetailAccessorialsContainerDTM[];
}

export interface IDetailAccessorialsDTM {
  fieldName: string
  summary: number
  transportation?: IDetailAccessorialsTransportationDTM
  freeTime?: IDetailAccessorialsContainerDTM[]
  accessorials?: IDetailAccessorialsContainerDTM[]
  penalties?: IDetailAccessorialsContainerDTM[]
  isLast?: boolean
}

export class DetailAccessorialsDTM extends BaseDTM<IDetailAccessorialsDTM> {
  @IsDefined()
  @IsString()
  fieldName: string;

  @IsDefined()
  @IsNumber()
  summary: number;

  @IsOptional()
  @ValidateNested()
  @Type(() => DetailAccessorialsTransportationDTM)
  transportation?: DetailAccessorialsTransportationDTM;

  @IsOptional()
  @ValidateNested({ each: true })
  @Type(() => DetailAccessorialsContainerDTM)
  freeTime?: DetailAccessorialsContainerDTM[];

  @IsOptional()
  @ValidateNested({ each: true })
  @Type(() => DetailAccessorialsContainerDTM)
  accessorials?: DetailAccessorialsContainerDTM[];

  @IsOptional()
  @ValidateNested({ each: true })
  @Type(() => DetailAccessorialsContainerDTM)
  penalties?: DetailAccessorialsContainerDTM[];

  @IsOptional()
  @IsBoolean()
  isLast: boolean;
}

export interface IAccessorialsFilterReturnDTM {
  accessorialsOrigin: IDetailAccessorialsDTM
  accessorialsFreight: IDetailAccessorialsDTM
  accessorialsDestination: IDetailAccessorialsDTM
}

export class AccessorialsFilterReturnDTM extends BaseDTM<IAccessorialsFilterReturnDTM> {
  @IsDefined()
  @ValidateNested()
  @Type(() => DetailAccessorialsDTM)
  accessorialsOrigin: DetailAccessorialsDTM;

  @IsDefined()
  @ValidateNested()
  @Type(() => DetailAccessorialsDTM)
  accessorialsFreight: DetailAccessorialsDTM;

  @IsDefined()
  @ValidateNested()
  @Type(() => DetailAccessorialsDTM)
  accessorialsDestination: DetailAccessorialsDTM;
}
