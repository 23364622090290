import { BaseController, controller } from 'proto/BaseController';
import { R } from 'shipment-operations/repository';
import { apiWorker } from 'app-wrapper/repository/utilsServices';

@controller
export class CreditNoteController extends BaseController {
  loadData = async (shipmentId?: string, creditNoteId?: string, category?: string) => {
    if (!shipmentId || !creditNoteId || !category) {
      return;
    }
    let response;

    this.dispatch(R.actions.creditNote.setIsLoading(true));
    try {
      response = await R.services.creditNote.getCreditNote(shipmentId, creditNoteId, category);
    } catch (e) {
      this.dispatch(R.actions.creditNote.setIsError(true));
      return;
    }
    this.dispatch(R.actions.creditNote.setCreditNote(response));
  }

  public cleanup = () => {
    apiWorker.abortAllRequests();
  };
}
