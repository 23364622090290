import styled from 'styled-components';

import { colors } from 'app-wrapper/view/themes/themesColors';

interface IContainerProps {
  alignEnd?: boolean
}

export const Container = styled.div<IContainerProps>`
  display: flex;
  flex-direction: column;
  align-items: ${({ alignEnd = false }) => (alignEnd ? 'flex-end' : 'flex-start')};
  justify-content: center;

  > .ant-typography {
    margin-bottom: 0;
  }
`;

export const Dates = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;

  > :nth-child(1) {
    color: ${colors.gray61};
  }
  > :nth-child(3) {
    color: ${colors.gray61};
  }
`;
