import styled from 'styled-components';
import Table from 'antd/es/table';
import Tag from 'antd/es/tag';
import Typography from 'antd/es/typography';
import { Tooltip } from 'app-wrapper/view/components';
import { InfoCircleFilled } from '@ant-design/icons';

import { StyledTable } from 'shipment-operations/view/components/CustomTable/CustomTable.styled';

export const SDLTableLayout = styled('div').attrs({
  'data-class': 'SDLTableLayout',
})``;

export const SDLTable = styled(Table)`
  .ant-table-content table {
    table-layout: fixed !important;
  }

  .ant-table-thead > tr > th {
    background: white;
  }

  .ant-table-thead > tr > th:first-of-type {
    padding-left: 16px;
  }

  .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    display: none;
  }

  .ant-table-thead .ant-table-cell {
    color: #73819B;
    font-size: 12px;
    font-weight: 400;
  }

  .ant-table-tbody > tr:last-of-type > td {
    border-bottom: none;
  }

  .ant-table-cell:first-of-type {
    & > article {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;

export const SDLTooltip = styled(Tooltip)`
  white-space: pre-line;
`;

export const SDLWarningIcon = styled(InfoCircleFilled)`
  color: ${({ theme }) => theme.themesColors.characterBrandingWarning};
  margin-left: 4px;
`;

export const SDLTableHeader = styled.div.attrs((props: {
  isNested: boolean,
}) => ({
  isNested: props.isNested,
}))`
  background-color: ${({ theme }) => theme?.themesColors?.neutralBranding1};
  padding: 16px 16px 0 16px;
  font-weight: 500;
  font-size: ${({ isNested }) => {
    if (isNested) {
      return '14px';
    }

    return '16px';
  }};
  line-height: 24px;
`;

export const SDLTableHeaderCustom = styled(Typography)`
  background-color: ${({ theme }) => theme?.themesColors?.neutralBranding1};
  padding: 16px 16px 0 16px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
`;

export const SDLTableNameCell = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const SDLTableCell = styled(Typography)`
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
`;

export const SDLTableCellEllipsis = styled(Typography)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const SDLTableFooterCell = styled(SDLTableCell)`
  font-weight: 500;
`;

export const SDLTableFooterTotal = styled(SDLTableFooterCell)`
  padding-left: 16px;
`;

export const SDLTableFooter = styled(StyledTable)`
  .ant-table-content table {
    table-layout: fixed !important;
  }

  .ant-table-cell {
    background: #F9F9FB;
    padding-top: 18px !important;
    padding-bottom: 18px !important;
  }

  .ant-table-tbody > tr.ant-table-row:hover > td {
    background: #F9F9FB !important;
  }

  .ant-table-tbody > tr:last-child > .ant-table-cell {
    padding-right: 16px;
  }
`;

export const SDLFooterDivider = styled.div`
  width: calc(100% - 16px);
  height: 1px;
  background-color: #f0f0f0;
`;

export const SDLFooterWrap = styled.div`
  background: ${({ theme }) => theme.themesColors.neutralBranding1};
  width: 100%;
  padding-left: 16px;
  padding-bottom: 16px;
`;

export const SDLHazmatTag = styled(Tag)`
  transform: translateY(-4px);
  margin-left: 6px;
  height: 14px;
  font-size: 10px;
  line-height: 11px;
  padding-left: 4px;
  padding-right: 4px;
`;
