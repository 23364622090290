import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'app-wrapper/createSlice';

import { IShipmentDocumentsAllState } from 'shipment-operations/models/states';
import {
  DocumentsCollapseItemDTM,
  ContainerDocumentDTM,
  ShippingPartyDTM,
  ShipmentConfirmationDTM,
  ShipmentPreviewDTM,
} from 'shipment-operations/models/dtm';
import { AdminPublicInfoDTM, OrganizationDTM, AccountDepartmentDTM } from 'user-management/models/dtm';
import { ReferenceDTM } from 'app-wrapper/types';

const initialState: IShipmentDocumentsAllState = {
  isLoading: false,
  isRFQLoading: false,
  additionalDocumentsMap: {},
  skypaceDownloadDocumentsMap: {},
  additionalDocument: null,
  documentHBL: null,
  additionalDocumentType: '',
  payableInvoices: [],
  receivableInvoices: [],
  notes: '',
  references: [],
  additionalDetailsId: 0,
  bookingConfirmationDocument: null,
  quoteDocument: null,
  documentNRA: null,
  documentNRABetweenContractOwnerAndAdmin: null,
  documentNRABetweenAdminAndAdmin: null,
  carrierBookingConfirmation: null,
  quoteIndex: undefined,
  shouldUsePostForAdditionalDetails: false,
  customer: null,
  customerUltimate: null,
  houseShipper: null,
  houseConsignee: null,
  createdByNameNRA: '',
  createdAtNRA: '',
  customerRelatedAdminPublicInfo: null,
  customerRelatedAdminPricingDepartment: null,
  contractOwnerRelatedAdminPublicInfo: null,
  contractOwnerRelatedAdminPricingDepartment: null,
  contractOwnerOrganization: null,
  contractOwnerPricingDepartment: null,
  termsAndConditionsConfirmation: null,
  currentOrgRelatedAdminPublicInfo: null,
  accountHolderPricingDepartment: null,
  accountHolderDocOpsExportDepartment: null,
  accountHolderBookingOpsDepartment: null,
  shipment: undefined,
};

export const shipmentDocumentsAllSlice = createSlice({
  name: 'shipmentDocumentsAll',
  initialState,
  reducers: {
    setIsLoading: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      isLoading: payload,
    }),
    setIsRFQLoading: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      isRFQLoading: payload,
    }),
    setAdditionalDocumentsMap: (state, { payload }: PayloadAction<Record<string, DocumentsCollapseItemDTM[]>>) => ({
      ...state,
      additionalDocumentsMap: payload,
    }),
    setSkypaceDownloadDocumentsMap: (state, { payload }: PayloadAction<Record<string, DocumentsCollapseItemDTM[]>>) => ({
      ...state,
      skypaceDownloadDocumentsMap: payload,
    }),
    setCustomerRelatedAdminPublicInfo: (state, { payload }: PayloadAction<AdminPublicInfoDTM | null>) => ({
      ...state,
      customerRelatedAdminPublicInfo: payload,
    }),
    setContractOwnerRelatedAdminPublicInfo: (state, { payload }: PayloadAction<AdminPublicInfoDTM | null>) => ({
      ...state,
      contractOwnerRelatedAdminPublicInfo: payload,
    }),
    setTermsAndConditionsConfirmation: (state, { payload }: PayloadAction<ShipmentConfirmationDTM | null>) => ({
      ...state,
      termsAndConditionsConfirmation: payload,
    }),
    setContractOwnerOrganization: (state, { payload }: PayloadAction<OrganizationDTM | null>) => ({
      ...state,
      contractOwnerOrganization: payload,
    }),
    setShipment: (state, { payload }: PayloadAction<ShipmentPreviewDTM | undefined>) => ({
      ...state,
      shipment: payload,
    }),
    setContractOwnerPricingDepartment: (state, { payload }: PayloadAction<AccountDepartmentDTM | null>) => ({
      ...state,
      contractOwnerPricingDepartment: payload,
    }),
    setContractOwnerRelatedAdminPricingDepartment: (state, { payload }: PayloadAction<AccountDepartmentDTM | null>) => ({
      ...state,
      contractOwnerRelatedAdminPricingDepartment: payload,
    }),
    setAccountHolderBookingOpsDepartment: (state, { payload }: PayloadAction<AccountDepartmentDTM | null>) => ({
      ...state,
      accountHolderBookingOpsDepartment: payload,
    }),
    setCustomerRelatedAdminPricingDepartment: (state, { payload }: PayloadAction<AccountDepartmentDTM | null>) => ({
      ...state,
      customerRelatedAdminPricingDepartment: payload,
    }),
    setAccountHolderDocOpsExportDepartment: (state, { payload }: PayloadAction<AccountDepartmentDTM | null>) => ({
      ...state,
      accountHolderDocOpsExportDepartment: payload,
    }),
    removeAdditionalDocumentFromMap: (state, { payload }: PayloadAction<DocumentsCollapseItemDTM>) => {
      const { id, type } = payload;
      const additionalDocsMap = { ...state.additionalDocumentsMap };
      const typeDocuments = [...additionalDocsMap[type]];
      const targetDocumentIndex = typeDocuments.findIndex((document) => id === document.id);
      typeDocuments.splice(targetDocumentIndex, 1);

      if (!typeDocuments.length) {
        delete additionalDocsMap[type];
      } else {
        additionalDocsMap[type] = [
          ...typeDocuments,
        ];
      }

      state.additionalDocumentsMap = {
        ...additionalDocsMap,
      };
    },
    setShouldUsePostForAdditionalDetails: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      shouldUsePostForAdditionalDetails: payload,
    }),
    setPayableInvoices: (state, { payload }: PayloadAction<DocumentsCollapseItemDTM[]>) => ({
      ...state,
      payableInvoices: payload,
    }),
    setCurrentOrgRelatedAdminPublicInfo: (state, { payload }: PayloadAction<AdminPublicInfoDTM | null>) => ({
      ...state,
      currentOrgRelatedAdminPublicInfo: payload,
    }),
    setAccountHolderPricingDepartment: (state, { payload }: PayloadAction<AccountDepartmentDTM | null>) => ({
      ...state,
      accountHolderPricingDepartment: payload,
    }),
    setDocumentNRABetweenContractOwnerAndAdmin: (state, { payload }: PayloadAction<DocumentsCollapseItemDTM | null>) => ({
      ...state,
      documentNRABetweenContractOwnerAndAdmin: payload,
    }),
    setDocumentNRABetweenAdminAndAdmin: (state, { payload }: PayloadAction<DocumentsCollapseItemDTM | null>) => ({
      ...state,
      documentNRABetweenAdminAndAdmin: payload,
    }),
    setDocumentHBL: (state, { payload }: PayloadAction<DocumentsCollapseItemDTM | null>) => ({
      ...state,
      documentHBL: payload,
    }),
    setCustomer: (state, { payload }: PayloadAction<ShippingPartyDTM | null>) => ({
      ...state,
      customer: payload,
    }),
    setCustomerUltimate: (state, { payload }: PayloadAction<ShippingPartyDTM | null>) => ({
      ...state,
      customerUltimate: payload,
    }),
    setHouseShipper: (state, { payload }: PayloadAction<ShippingPartyDTM | null>) => ({
      ...state,
      houseShipper: payload,
    }),
    setHouseConsignee: (state, { payload }: PayloadAction<ShippingPartyDTM | null>) => ({
      ...state,
      houseConsignee: payload,
    }),
    setReceivableInvoices: (state, { payload }: PayloadAction<DocumentsCollapseItemDTM[]>) => ({
      ...state,
      receivableInvoices: payload,
    }),
    setAdditionalDocument: (state, { payload }: PayloadAction<ContainerDocumentDTM | null>) => ({
      ...state,
      additionalDocument: payload,
    }),
    setQuoteIndex: (state, { payload }: PayloadAction<number | undefined>) => ({
      ...state,
      quoteIndex: payload,
    }),
    setQuoteIndexNRA: (state, { payload }: PayloadAction<number>) => ({
      ...state,
      quoteIndexNRA: payload,
    }),
    setCreatedByNameNRA: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      createdByNameNRA: payload,
    }),
    setCreatedByNameConfirmationNRA: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      createdByNameConfirmationNRA: payload,
    }),
    setCreatedAtNRA: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      createdAtNRA: payload,
    }),
    setAdditionalType: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      additionalDocumentType: payload,
    }),
    setNotes: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      notes: payload,
    }),
    setAdditionalDetailsId: (state, { payload }: PayloadAction<number>) => ({
      ...state,
      additionalDetailsId: payload,
    }),
    setReferences: (state, { payload }: PayloadAction<ReferenceDTM[]>) => ({
      ...state,
      references: payload,
    }),
    setBookingConfirmationDocument: (state, { payload }: PayloadAction<DocumentsCollapseItemDTM | null>) => ({
      ...state,
      bookingConfirmationDocument: payload,
    }),
    setCarrierBookingConfirmation: (state, { payload }: PayloadAction<DocumentsCollapseItemDTM | null>) => ({
      ...state,
      carrierBookingConfirmation: payload,
    }),
    setQuoteDocument: (state, { payload }: PayloadAction<DocumentsCollapseItemDTM | null>) => ({
      ...state,
      quoteDocument: payload,
    }),
    setNRADocument: (state, { payload }: PayloadAction<DocumentsCollapseItemDTM | null>) => ({
      ...state,
      documentNRA: payload,
    }),
    clearAdditionalFormData: (state) => ({
      ...state,
      additionalDocument: null,
      additionalDocumentType: '',
      notes: '',
      references: [],
      additionalDetailsId: 0,
    }),
    clearBookingConfirmationDocument: (state) => ({
      ...state,
      bookingConfirmationDocument: null,
    }),
    clear: () => initialState,
  },
});

export const shipmentDocumentsAllActions = shipmentDocumentsAllSlice.actions;
export const shipmentDocumentsAllReducer = shipmentDocumentsAllSlice.reducer;
