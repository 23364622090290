import React, {
  FC,
  useCallback,
  useEffect, useRef, useState,
} from 'react';
import { SiderTrigger } from 'app-wrapper/view/components';

import { AddressBlock } from '../AddressBlock';
import { FreightQuoteFooter } from '../FreightQuoteFooter';
import { Commodity } from '../Commodity';
import { Containers } from '../Containers';
import { RFQTabs } from '../RFQTabs';
import { ClientInformation } from '../ClientInformation';

import { FQFormDivider, FQFormRequest, FQFormRequestGetQuotas } from './FreightQuoteFormRequest.styled';
import { AdditionalServices } from '../AdditionalServices';

export interface IFreightQuoteFormRequestComponentProps {
  quotaSearchId?: string | null
  forceCollapse?: boolean
  hideButton?: boolean
  isAllRequestedPage?: boolean
  onStartFirstRun: () => void
  onSubmit: (stayUrl?: string) => void
  onSubmitReuseRequest?: () => void
  onCollapseChange: (isForceFalse?: boolean) => void
  isCollapsed?: boolean
  requestQuotaStatus?: string
  quotaStatusMessage?: string
  stayUrl?: string
  rfqFormClientInformation?: boolean
}

export const FreightQuoteFormRequestComponent: FC<IFreightQuoteFormRequestComponentProps> = (props) => {
  const {
    isAllRequestedPage,
    onStartFirstRun,
    onSubmit,
    onSubmitReuseRequest,
    quotaSearchId,
    hideButton,
    forceCollapse = false,
    onCollapseChange,
    isCollapsed,
    stayUrl,
    rfqFormClientInformation,
  } = props;

  const formRef = useRef(null);

  const [formLocalState, setFormLocalState] = useState<{ isCollapsed?: boolean }>({
    isCollapsed: true,
  });

  useEffect(() => {
    if (forceCollapse) {
      onCollapseChange(true);
    }
  }, [forceCollapse]);

  useEffect(() => {
    setFormLocalState((prev) => ({ ...prev, isCollapsed }));
  }, [isCollapsed]);

  // first run
  useEffect(() => {
    if (!isAllRequestedPage) {
      onStartFirstRun();
    }
  }, []);

  const [isQuotaSearchFirst, setIsQuotaSearchFirst] = useState(false);

  useEffect(() => {
    if (quotaSearchId && !isQuotaSearchFirst) {
      setIsQuotaSearchFirst(true);
    }
  }, [
    isQuotaSearchFirst,
    quotaSearchId,
  ]);

  const onCollapseChangeHandler = useCallback(
    () => {
      onCollapseChange();
    },
    [onCollapseChange],
  );

  const onSubmitHandler = useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      if (onSubmitReuseRequest) {
        onSubmitReuseRequest();
        return;
      }
      onSubmit(stayUrl);
    },
    [onSubmitReuseRequest],
  );

  return (
    <FQFormRequest
      id="FQFormRequest"
      className={`${isCollapsed ? 'FreightQuoteFormRequest__isCollapsed' : 'FreightQuoteFormRequest__isNotCollapsed'} FreightQuoteFormRequest ${forceCollapse ? 'FreightQuoteFormRequest__isForceCollapse' : ''}`}
      onSubmit={onSubmitHandler}
      ref={formRef}
    >
      <SiderTrigger onClick={onCollapseChangeHandler} isCollapsed={!isCollapsed} />

      <FQFormRequestGetQuotas className="FreightQuoteFormRequest__get-quotes-form">
        <div>
          <RFQTabs />

          <FQFormDivider />

          <AddressBlock />

          <Commodity />

          <Containers
            forceCollapse={forceCollapse}
            formLocalState={formLocalState}
            setFormLocalState={setFormLocalState}
          />

          {/* <Services /> */}

          <AdditionalServices
            forceCollapse={forceCollapse}
            formLocalState={formLocalState}
          />

          {/* <Logistics /> */}

          {!rfqFormClientInformation && (
            <ClientInformation
              forceCollapse={forceCollapse}
              formLocalState={formLocalState}
            />
          )}
        </div>

      </FQFormRequestGetQuotas>

      <FreightQuoteFooter
        isFormCollapsed={isCollapsed}
        hideButton={hideButton}
        isReuseRequest={!!onSubmitReuseRequest}
      />
    </FQFormRequest>
  );
};
