import React, { FC, memo } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { R } from 'user-management/repository';
import { UC } from 'user-management/controllers';

import { CustomersInternalComponent } from './CustomersInternal.component';

const CustomersInternalContainer: FC = () => {
  const organization = useSelector(R.selectors.userOrganizationData.getInternalOrganization);
  const isAdmin = useSelector(R.selectors.userOrganizationData.isAdmin);

  const { customerId } = useParams<'customerId'>();
  const customerOrganizationProfile = useSelector(R.selectors.moduleFunctionalityPermissions.getCustomerOrganizationProfilePermissions);

  return (
    <CustomersInternalComponent
      onStart={UC.organizationProfile.onStartCustomerInternalPage(customerId || '')}
      onEnd={UC.organizationProfile.onEndCustomerInternalPage}
      organization={organization}
      isAdmin={isAdmin}
      permissions={customerOrganizationProfile}
      onEditProfileOrganization={UC.organizationProfile.openEditCustomerProfileOrganization}
    />
  );
};

const CustomersInternalContainerCache = memo(CustomersInternalContainer);

export { CustomersInternalContainerCache as CustomersInternalPage };
