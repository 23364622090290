import React, { FC, useCallback } from 'react';

import { Input } from 'app-wrapper/view/components';
import { MAX_LENGTH_20 } from 'app-wrapper/constants';

interface IAdditionalPhoneInputComponentProps {
  additionalPhone?: string
  setAdditionalPhone: (phone: string) => void;
}

export const AdditionalPhoneInputComponent: FC<IAdditionalPhoneInputComponentProps> = ({
  additionalPhone,
  setAdditionalPhone,
}) => {
  const handleChange = useCallback((e) => {
    setAdditionalPhone(e.target.value);
  }, [setAdditionalPhone]);

  return (
    <Input
      value={additionalPhone}
      onChange={handleChange}
      maxLength={MAX_LENGTH_20}
    />
  );
};
