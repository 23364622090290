import React from 'react';

import { useSelector } from 'react-redux';
import { R } from 'shipment-operations/repository';
import { TermsAndConditionsComponent } from './TermsAndConditions.component';

const TermsAndConditionsContainer = () => {
  const isUsShipmentOriginOrDestination = Boolean(useSelector(R.selectors.bookingWizard.getShipment)?.isUsShipmentOriginOrDestination());

  return (
    <TermsAndConditionsComponent
      isUsShipmentOriginOrDestination={isUsShipmentOriginOrDestination}
    />
  );
};

export { TermsAndConditionsContainer as TermsAndConditions };
