import React from 'react';
import { useSelector } from 'react-redux';

import i18n from 'app-wrapper/i18n/i18n';
import { UC as appUC } from 'app-wrapper/controllers';
import { R as appR } from 'app-wrapper/repository';
import { R } from 'shipment-operations/repository';

import { SkypaceTermsAndConditionsComponent } from './SkypaceTermsAndConditions.component';

const SkypaceTermsAndConditionsContainer = () => {
  const isLoading = useSelector(R.selectors.shipmentDocumentsAll.getIsRFQLoading);
  const shipmentTermsOfServiceFileNamePrint = useSelector(R.selectors.bookingWizard.getShipmentTermsOfServiceFileNamePrint);
  const confirmation = useSelector(R.selectors.shipmentDocumentsAll.getTermsAndConditionsConfirmation);
  const adminAddress = useSelector(appR.selectors.agreements.getCurrentOrgRelatedAdminAddress);
  const bookingDepartment = useSelector(appR.selectors.agreements.getAccountHolderBookingOpsDepartment);
  const headerData = useSelector(appR.selectors.agreements.getSkypaceAdminData(bookingDepartment, i18n.t('Booking')));

  return (
    <SkypaceTermsAndConditionsComponent
      header={{
        company: headerData,
      }}
      adminAddress={adminAddress}
      isLoading={isLoading}
      createdByName={confirmation?.getCreatedByFullName()}
      fileNamePrint={shipmentTermsOfServiceFileNamePrint}
      fetchDataBeforePrinting={appUC.agreements.fetchDataBeforePrinting}
    />
  );
};

export { SkypaceTermsAndConditionsContainer as SkypaceTermsAndConditions };
