import { createSelector } from 'reselect';

import { RootState } from 'app-wrapper/store';

const localState = (state: RootState) => state.countriesList;

const getCountriesList = createSelector(
  localState,
  (state) => state.countriesList,
);

export const countriesListSelectors = {
  getCountriesList,
};
