import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ADDITIONAL_SERVICES_DOCUMENTS_NAMES } from 'shipment-operations/constants';
import { ShipmentDrawerWrapper } from 'shipment-operations/view/pages/Shipment/components/ShipmentDrawer/ShipmentDrawer.styled';

import {
  Wrapper,
  Title,
  Select,
  Subtitle,
  Spinner,
} from './ServiceDocumentUpload.styled';
import { Footer, DocumentUpload } from './components';

interface IServiceDocumentUploadComponentProps {
  documentType?: string;
  isLoading?: boolean;
  onClose: () => void;
}

export const ServiceDocumentUploadComponent: FC<IServiceDocumentUploadComponentProps> = ({
  documentType,
  isLoading,
  onClose,
}) => {
  const { t } = useTranslation();

  return (
    <ShipmentDrawerWrapper
      title={t('Documents')}
      visible
      closable
      maskClosable={false}
      onClose={onClose}
    >
      <Wrapper>
        {isLoading ? (
          <Spinner size="large" />
        ) : (
          <>
            <Title>
              {t('Document Upload')}
            </Title>
            <Subtitle>
              {t('Document Type')}
            </Subtitle>
            <Select
              optionsData={ADDITIONAL_SERVICES_DOCUMENTS_NAMES}
              allowClear
              value={documentType}
              placeholder={t('SelectDocumentType')}
              disabled
            />
            <Subtitle>
              {t('Document')}
            </Subtitle>
            <DocumentUpload />
            <Footer />
          </>
        )}
      </Wrapper>
    </ShipmentDrawerWrapper>
  );
};
