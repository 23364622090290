import React, { FC, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import isEmpty from 'lodash/fp/isEmpty';
import { TableRowSelection } from 'antd/es/table/interface';

import { useTableColumns } from 'shipment-operations/view/hooks';
import { StyledCheckbox } from 'shipment-operations/view/pages/ShipmentCharges/ShipmentCharges.styled';

import {
  StyledSection, TableWithCheckBoxes, HeaderWithCheckboxes,
} from 'shipment-operations/view/components/CustomTable/CustomTable.styled';

export interface DataType {
  key: number
  service: string
  measure: string
  cost: string
  quantity: number
  total: string
}

interface ICustomTableProps {
  fullTable: boolean
  onItemSelect: (id: number) => void
  onSubTableSelect: (id: number[]) => void
  selectedCharges: number[]
  originData: {
    keys: number[]
    tableData: DataType[]
  },
  freightData: {
    keys: number[]
    tableData: DataType[]
  },
  destinationData: {
    keys: number[]
    tableData: DataType[]
  },
}

const CustomTableSelectable: FC<ICustomTableProps> = ({
  fullTable, onItemSelect, onSubTableSelect, selectedCharges,
  originData, freightData, destinationData,
}) => {
  const { t } = useTranslation();
  const { columns, fullColumns } = useTableColumns();

  const isOriginChecked = useMemo(() => originData.keys.every((item) => selectedCharges.includes(item)), [selectedCharges, originData.keys]);
  const isFreightChecked = useMemo(() => freightData.keys.every((item) => selectedCharges.includes(item)), [selectedCharges, freightData.keys]);
  const isDestinationChecked = useMemo(() => destinationData.keys.every((item) => selectedCharges.includes(item)), [selectedCharges, destinationData.keys]);

  const handleOriginClick = useCallback(() => onSubTableSelect(originData.keys), [onSubTableSelect, originData.keys]);
  const handleFreightClick = useCallback(() => onSubTableSelect(freightData.keys), [onSubTableSelect, freightData.keys]);
  const handleDestinationClick = useCallback(() => onSubTableSelect(destinationData.keys), [onSubTableSelect, destinationData.keys]);

  const rowSelection = {
    selectedRowKeys: selectedCharges,
    onSelect: (record: DataType) => {
      if (onItemSelect) {
        onItemSelect(record.key);
      }
    },
  } as TableRowSelection<object>;

  return (
    <>
      <HeaderWithCheckboxes
        columns={fullTable ? fullColumns : columns}
        rowSelection={rowSelection}
      />
      {!isEmpty(originData.tableData) && (
        <>
          <StyledSection>
            <StyledCheckbox
              onChange={handleOriginClick}
              checked={isOriginChecked}
            />
            {t('Origin')}
          </StyledSection>
          <TableWithCheckBoxes
            columns={fullTable ? fullColumns : columns}
            showHeader={false}
            dataSource={originData.tableData}
            pagination={false}
            rowSelection={rowSelection}
          />
        </>
      )}
      {!isEmpty(freightData.tableData) && (
        <>
          <StyledSection>
            <StyledCheckbox
              onChange={handleFreightClick}
              checked={isFreightChecked}
            />
            {t('freight')}
          </StyledSection>
          <TableWithCheckBoxes
            columns={fullTable ? fullColumns : columns}
            showHeader={false}
            dataSource={freightData.tableData}
            pagination={false}
            rowSelection={rowSelection}
          />
        </>
      )}
      {!isEmpty(destinationData.tableData) && (
        <>
          <StyledSection>
            <StyledCheckbox
              onChange={handleDestinationClick}
              checked={isDestinationChecked}
            />
            {t('destination')}
          </StyledSection>
          <TableWithCheckBoxes
            columns={fullTable ? fullColumns : columns}
            showHeader={false}
            dataSource={destinationData.tableData}
            pagination={false}
            rowSelection={rowSelection}
          />
        </>
      )}
    </>
  );
};

export { CustomTableSelectable };
