import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';
import { UC } from 'shipment-operations/controllers';

import { ShippingPartiesFormFooterComponent } from './ShippingPartiesFormFooter.component';

const ShippingPartiesFormFooterContainer: FC = () => {
  const isFormDataLoading = useSelector(R.selectors.shippingParties.getIsSelectCompanyDrawerLoading);
  const formType = useSelector(R.selectors.shippingParties.getSelectedFormType);
  const attachedCompany = useSelector(R.selectors.shippingParties.getSelectedCompany);
  const hasAnyFieldsChanged = useSelector(R.selectors.shippingParties.getHasShippingPartyFieldsChanged);

  return (
    <ShippingPartiesFormFooterComponent
      isLoading={isFormDataLoading}
      activeShippingParty={formType}
      hasAttachedCompany={!!attachedCompany}
      updateParties={UC.shippingParties.updateShippingPartyContactData}
      hasAnyFieldsChanged={hasAnyFieldsChanged}
    />
  );
};

export { ShippingPartiesFormFooterContainer as ShippingPartiesFormFooter };
