import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from 'app-wrapper/view/components';

import { FooterWrapper } from './Footer.styled';

interface FooterProps {
  onCancel: () => void;
  onSubmit: () => void;
  disabled: boolean;
}

export const FooterComponent: FC<FooterProps> = ({
  onSubmit,
  onCancel,
  disabled,
}) => {
  const { t } = useTranslation();

  const handleSubmit = useCallback(() => {
    onSubmit();
  }, [onSubmit]);

  const handleCancel = useCallback(() => {
    onCancel();
  }, [onCancel]);

  return (
    <FooterWrapper>
      <Button
        onClick={handleCancel}
        type="dashed"
        style={{
          marginRight: '8px',
        }}
      >
        {t('Cancel')}
      </Button>

      <Button onClick={handleSubmit} disabled={disabled}>
        {t('Upload')}
      </Button>
    </FooterWrapper>
  );
};
