import React from 'react';

import { IPropsIcon } from 'app-wrapper/interfaces/icon';

import { Icon } from './WareHouseSvg.styled';

export const WareHouseSvg = (props: IPropsIcon) => {
  const { width, height, fill } = props;
  return (
    <Icon
      className="WareHouseSvg"
      width={width || '17'}
      height={height || '13'}
      viewBox="0 0 17 13"
      fill={fill || 'currentColor'}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M13.7778 9.11111H9.50001V6.38889C9.50001 6.19444 9.30557 6 9.11112 6H3.66668C3.44793 6 3.27779 6.19444 3.27779 6.38889V12.6111C3.27779 12.8299 3.44793 13 3.66668 13H13.7778C13.9722 13 14.1667 12.8299 14.1667 12.6111V9.5C14.1667 9.30555 13.9722 9.11111 13.7778 9.11111ZM8.33335 11.8333H4.44446V10.2778H8.33335V11.8333ZM8.33335 8.72222H4.44446V7.16667H8.33335V8.72222ZM13 11.8333H9.50001V10.2778H13V11.8333ZM15.4549 3.25347L9.35418 0.701388C8.94099 0.531249 8.47918 0.531249 8.06599 0.701388L1.96529 3.25347C1.33335 3.49653 0.944458 4.10417 0.944458 4.78472V12.6111C0.944458 12.8299 1.1146 13 1.33335 13H1.72224C1.91668 13 2.11112 12.8299 2.11112 12.6111V4.78472C2.11112 4.56597 2.20835 4.39583 2.40279 4.32292L8.52779 1.77083C8.64932 1.72222 8.77085 1.72222 8.89237 1.77083L15.0174 4.32292C15.2118 4.39583 15.3333 4.56597 15.3333 4.78472V12.6111C15.3333 12.8299 15.5035 13 15.7222 13H16.1111C16.3056 13 16.5 12.8299 16.5 12.6111V4.78472C16.5 4.10417 16.0868 3.49653 15.4549 3.25347Z" fill="currentColor" />
    </Icon>
  );
};
