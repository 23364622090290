import React, { FC, ReactNode } from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';

import { ShipmentContainersScaffoldComponent } from './ShipmentContainersScaffold.component';

interface IShipmentContainersScaffoldContainerProps {
  title: ReactNode
  header: ReactNode
  containersList: ReactNode
  containerData: ReactNode
  warningBlock?: ReactNode
}

const ShipmentContainersScaffoldContainer: FC<IShipmentContainersScaffoldContainerProps> = ({
  title,
  header,
  containersList,
  containerData,
  warningBlock,
}) => {
  const containerBasicType = useSelector(R.selectors.shipment.getContainersBasicType);

  return (
    <ShipmentContainersScaffoldComponent
      containerBasicType={containerBasicType}
      warningBlock={warningBlock}
      title={title}
      header={header}
      containersList={containersList}
      containerData={containerData}
    />
  );
};

export { ShipmentContainersScaffoldContainer as ShipmentContainersScaffold };
