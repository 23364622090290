import React, { FC, useCallback } from 'react';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';

import { Button } from 'app-wrapper/view/components';
import { Footer } from 'app-wrapper/view/guideline/Footer/Footer.styled';

interface IPaymentFooterProps {
  disabled: boolean
  isLoading: boolean
  onClick: (shipmentId?: string, invoiceType?: string) => void
  buttonName: string
}

const PaymentFooter: FC<IPaymentFooterProps> = ({
  disabled, isLoading, onClick, buttonName,
}) => {
  const { t } = useTranslation();
  const { shipmentId } = useParams<'shipmentId'>();
  const { invoiceType } = useParams<'invoiceType'>();

  const handleClick = useCallback(() => onClick(shipmentId, invoiceType), [shipmentId, invoiceType]);

  return (
    <Footer>
      <Button
        htmlType="submit"
        disabled={disabled}
        loading={isLoading}
        onClick={handleClick}
      >
        {t(`${buttonName}`)}
      </Button>
    </Footer>
  );
};

export { PaymentFooter };
