import React, {
  FC, memo, useCallback, useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';

import { useWindowSize } from 'app-wrapper/hooks';

import {

  ICompanyAddressDTM, ICompanyContactDTM, IContactBookDefaultStateCompanyListStateDTM, IContactBookUpdateStateCompanyListStateErrorsDTM,
} from 'user-management/models/dtm';
import { ContactBookAddressItem, ContactBookContactPersonItem } from 'user-management/view/pages/ContactBook/components';
import { TooltipError } from 'user-management/view/components';

import {
  CBContentBodyForm,
  CBContentBodyFormCompanyFirstBlock,
  CBContentBodyFormCompanyName,
  CBContentBodyFormCompanyNameInput,
  CBContentBodyFormCompanyNameTitle,
  CBContentBodyFormCompanyNameTitleWrapper,
  CBContentBodyFormCompanyTaxId,
  CBContentBodyFormCompanyTaxIdInput,
  CBContentBodyFormCompanyTaxIdTitle,
  CBContentBodyFormCompanyTaxIdTitleWrapper,
  CBContentBodyFormFooter,
  CBContentBodyFormWrap,
  CBContentBodyFormWrapper,
} from './ContactBookAddContact.styled';

interface IContactBookAddContactComponentProps {
  companyListState: IContactBookDefaultStateCompanyListStateDTM[]
  companyListAddresses?: ICompanyAddressDTM[]
  companyListContactPersons?: ICompanyContactDTM[]
  companyListStateIndex: string
  isUpdating: boolean
  errors: IContactBookUpdateStateCompanyListStateErrorsDTM
  updateForm: () => void
  onDiscard: () => void
  onChangeListName: (index: string) => (name: string) => void
  onBlurListName: () => void
  onFocusListName: () => void
  onChangeTaxId: (index: string) => (name: string) => void
  onBlurTaxId: () => void
  onFocusTaxId: () => void
}

const ContactBookAddContactComponent: FC<IContactBookAddContactComponentProps> = (props) => {
  const {
    companyListState,
    companyListAddresses,
    companyListContactPersons,
    companyListStateIndex,
    isUpdating,
    errors,
    updateForm,
    onDiscard,
    onChangeListName,
    onBlurListName,
    onFocusListName,
    onChangeTaxId,
    onBlurTaxId,
    onFocusTaxId,
  } = props;
  const { t } = useTranslation();
  const largeInput = useWindowSize().isFullMediaWidth;

  const onChangeListNameHandler = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onChangeListName(companyListStateIndex)(event.target.value);
    },
    [companyListStateIndex],
  );

  const onChangeTaxIdHandler = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onChangeTaxId(companyListStateIndex)(event.target.value);
    },
    [companyListStateIndex],
  );

  const hasErrorName = useMemo(() => {
    const errDocument = errors?.name;

    return !!(errDocument
      && errDocument?.message
      && (errDocument?.isBlur || errDocument?.isVisited));
  }, [companyListStateIndex, errors]);

  const tooltipMessage = errors?.name?.message || '';
  const tooltipVisible = errors?.name?.isTooltipVisible || false;

  return (
    <CBContentBodyFormWrap>
      <CBContentBodyForm>
        <CBContentBodyFormWrapper>
          <CBContentBodyFormCompanyFirstBlock>
            <CBContentBodyFormCompanyName>
              <CBContentBodyFormCompanyNameTitleWrapper>
                <CBContentBodyFormCompanyNameTitle>
                  {`${t('CompanyName')} ${t('requiredSymbol')}`}
                </CBContentBodyFormCompanyNameTitle>
              </CBContentBodyFormCompanyNameTitleWrapper>
              <TooltipError
                message={tooltipMessage}
                visible={tooltipVisible}
              >
                <CBContentBodyFormCompanyNameInput
                  largeInput={largeInput}
                  value={companyListState.filter((item) => item.customId === companyListStateIndex)?.[0]?.name}
                  hasError={hasErrorName}
                  onChange={onChangeListNameHandler}
                  onBlur={onBlurListName}
                  onFocus={onFocusListName}
                />
              </TooltipError>
            </CBContentBodyFormCompanyName>

            <CBContentBodyFormCompanyTaxId>
              <CBContentBodyFormCompanyTaxIdTitleWrapper>
                <CBContentBodyFormCompanyTaxIdTitle>
                  {t('Tax ID')}
                </CBContentBodyFormCompanyTaxIdTitle>
              </CBContentBodyFormCompanyTaxIdTitleWrapper>
              <CBContentBodyFormCompanyTaxIdInput
                largeInput={largeInput}
                value={companyListState.filter((item) => item.customId === companyListStateIndex)?.[0]?.taxId || ''}
                onChange={onChangeTaxIdHandler}
                onBlur={onBlurTaxId}
                onFocus={onFocusTaxId}
              />
            </CBContentBodyFormCompanyTaxId>
          </CBContentBodyFormCompanyFirstBlock>

          {companyListAddresses?.map((addressItem, addressIndex) => (
            <ContactBookAddressItem
              key={`ContactBookAddressItem-${addressItem.customId}`}
              largeInput={largeInput}
              isFirst
              isLast={false}
              addressIndex={addressIndex}
              companyListStateIndex={companyListStateIndex}
              address={addressItem}
            />
          ))}

          {companyListContactPersons?.map((personItem, personIndex) => (
            <ContactBookContactPersonItem
              key={`ContactBookContactPersonItem-${personItem.customId}`}
              largeInput={largeInput}
              isFirst={personIndex === 0}
              isLast={personIndex === companyListContactPersons.length - 1}
              contactIndex={personIndex}
              personItem={personItem}
              companyListStateIndex={companyListStateIndex}
            />
          ))}

        </CBContentBodyFormWrapper>

      </CBContentBodyForm>

      <CBContentBodyFormFooter
        saveName={t('Add')}
        discardName={t('cancel')}
        discardChanges={onDiscard}
        updateForm={updateForm}
        disabledUpdate={isUpdating}
      />
    </CBContentBodyFormWrap>
  );
};

const ContactBookAddContactComponentCache = memo(ContactBookAddContactComponent);

export { ContactBookAddContactComponentCache as ContactBookAddContactComponent };
