import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';
import { UC } from 'shipment-operations/controllers';
import { UC as appUC } from 'app-wrapper/controllers';
import { MaerskChangesComponent } from 'shipment-operations/view/drawers/MaerskChanges/MaerskChanges.component';

const MaerskChangesContainer = () => {
  const isSearchSuccess = useSelector(R.selectors.shipmentChanges.getIsSearchSuccess);
  const actionLoading = useSelector(R.selectors.shipmentChanges.getActionLoading);
  const shipmentId = useSelector(R.selectors.overview.getShipmentId);
  const bookingStatus = useSelector(R.selectors.shipment.getBookingStatus);
  const inttraNumber = useSelector(R.selectors.shipment.getOceanBookingId);
  const updatedIntraNumber = useSelector(R.selectors.shipmentChanges.getShortShipmentOceanBookingId);
  const updatedBookingStatus = useSelector(R.selectors.shipmentChanges.getBookingStatusRequestedShipment);
  const showValidate = useSelector(R.selectors.shipmentChanges.getIsShowValidate);
  const validationLoaded = useSelector(R.selectors.shipmentTrackerRoutes.getValidationLoaded);
  const isDisableSubmit = useMemo(() => !!(showValidate && !validationLoaded), [showValidate, validationLoaded]);

  return (
    <MaerskChangesComponent
      isSearchSuccess={isSearchSuccess}
      onConfirm={UC.shipmentChanges.onConfirmData}
      actionLoading={actionLoading}
      onClose={appUC.drawer.closeDrawer}
      savedShipmentId={shipmentId}
      reset={UC.shipmentChanges.onReset}
      bookingStatus={bookingStatus}
      inttraNumber={inttraNumber}
      updatedIntraNumber={updatedIntraNumber}
      updatedBookingStatus={updatedBookingStatus}
      isDisableSubmit={isDisableSubmit}
    />
  );
};

export { MaerskChangesContainer as MaerskChanges };
