import { FlexCol } from 'app-wrapper/view/guideline';
import { BodyTextRegular, SmallTextFootnoteDescription } from 'app-wrapper/view/guideline/Font/Font.styled';
import styled from 'styled-components';

export const TableEmptyStateContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 100%;
`;

export const TableEmptyStateContainerWrapper = styled(FlexCol)`
  align-items: center;
`;

export const TableEmptyStateTitle = styled.span`
  ${BodyTextRegular}
  color: ${({ theme }) => theme.themesColors.neutralBranding10}
`;

export const TableEmptyStateText = styled.span`
  ${SmallTextFootnoteDescription}
  color: ${({ theme }) => theme.themesColors.secondaryDot45}
`;
