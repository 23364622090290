import { action, makeObservable } from 'mobx';

import { BaseMobxStore } from 'proto/BaseMobxStore';
import { ShipmentPreviewDTM } from 'shipment-operations/models/dtm';
import {
  ShipmentCostChangeTaskMetadataDTM,
  ContainerWithChangeChargesDTM,
  ShipmentChargeChangeDTM,
} from 'app-wrapper/models/dtm';

interface ShipmentCostChangesDrawerState {
  shipment: ShipmentPreviewDTM | null;
  costChangesMetadata: ShipmentCostChangeTaskMetadataDTM | null;
  containers: ContainerWithChangeChargesDTM[];
  bolCharges: ShipmentChargeChangeDTM[]
  isLoading: boolean
}

export const initialState: ShipmentCostChangesDrawerState = {
  shipment: null,
  costChangesMetadata: null,
  isLoading: true,
  containers: [],
  bolCharges: [],
};

export class ShipmentCostChangesDrawerStore<T extends ShipmentCostChangesDrawerState = ShipmentCostChangesDrawerState> extends BaseMobxStore<ShipmentCostChangesDrawerState> {
  initialState = { ...initialState }

  constructor(_initialState?: T) {
    super();
    makeObservable(this);
    this.init(_initialState || this.initialState);
  }

  @action
  setCostChangesMetadata(metadata: ShipmentCostChangeTaskMetadataDTM | null) {
    this.state.costChangesMetadata = metadata;
  }

  @action
  setShipment(shipment: ShipmentPreviewDTM | null) {
    this.state.shipment = shipment;
  }

  @action
  setLoading(loading: boolean) {
    this.state.isLoading = loading;
  }

  @action
  setContainers(containers: ContainerWithChangeChargesDTM[]) {
    this.state.containers = containers;
  }

  @action
  setBOLCharges(bolCharges: ShipmentChargeChangeDTM[]) {
    this.state.bolCharges = bolCharges;
  }
}
