import styled from 'styled-components';

import { Button } from 'app-wrapper/view/components';

export const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 16px;
`;

export const Item = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;
  padding: 8px 0;
  border-bottom: 1px dashed ${({ theme }) => theme.themesColors.neutralBranding4};

  &:last-of-type {
    border-bottom: 0;
    padding-bottom: 0;
  }

  &:first-of-type {
    padding-top: 0;
  }
`;

export const ItemTitle = styled.span`
  font-size: 14px;
  line-height: 22px;
`;

export const ItemSubTitle = styled.span`
  font-size: 12px;
  line-height: 20px;
  color: ${({ theme }) => theme.themesColors.secondaryDot45};
`;

export const ItemTitleBold = styled(ItemTitle)`
  font-weight: 500;
`;

export const LocationDataButton = styled(Button)`
  position: absolute;
  top: 16px;
  right: 16px;
  width: 59px;
  font-size: 12px;
`;
