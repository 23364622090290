import React from 'react';

import { IPropsIcon } from 'app-wrapper/interfaces/icon';

import { Icon } from './FavoritesQuotesSvg.styled';

export const FavoritesQuotesSvg = (props: IPropsIcon) => {
  const { width, height, fill } = props;
  return (
    <Icon
      className="FavoritesQuotesSvg"
      width={width || '12'}
      height={height || '15'}
      viewBox="0 0 12 15"
      fill={fill || 'currentColor'}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M9.99996 0.388885C10.7222 0.388885 11.3333 0.999997 11.3333 1.72222V14.6111L5.99996 11.5L0.666626 14.6111V1.72222C0.666626 0.999997 1.24996 0.388885 1.99996 0.388885H9.99996ZM9.99996 12.3056V1.88889C9.99996 1.80555 9.91663 1.72222 9.83329 1.72222H2.16663C2.05551 1.72222 1.99996 1.80555 1.99996 1.88889V12.3056L5.99996 9.97222L9.99996 12.3056Z" fill="currentColor" />
    </Icon>
  );
};
