import React, { FC, memo } from 'react';
import { useSelector } from 'react-redux';

import { R } from 'user-management/repository';
import { UC } from 'user-management/controllers';

import { AddressesAndContactsComponent } from './AddressesAndContactsAddAddress.component';

const AddressesAndContactsContainer: FC = () => {
  const contactBookUpdate = useSelector(R.selectors.contactBook.getContactBookUpdate);

  const PrimaryById = contactBookUpdate.companyList?.[0].customId || '';
  const addressesIds = contactBookUpdate.companyList?.[0].addressesIds || [];
  const contactPersonsIds = contactBookUpdate.companyList?.[0].contactPersonsIds || [];

  const companyListAddresses = useSelector(R.selectors.contactBook.getContactBookUpdateCompanyListAddressesByIds(addressesIds));
  const companyListContactPersons = useSelector(R.selectors.contactBook.getContactBookUpdateCompanyListContactPersonsByIds(contactPersonsIds));

  return (
    <AddressesAndContactsComponent
      companyListStateIndex={PrimaryById}
      isDisabledCancelBtn={false}
      isDisabledSaveBtn={false}
      onCancelClick={UC.organizationProfile.closeAddressesAndContactsAddAddressDrawer}
      onSaveClick={UC.contactBook.onAddAddressesAndContacts}
      onChangeAddressIsPrimary={UC.contactBook.onChangeUpdateAACIsPrimaryByIndex(addressesIds?.[0])}
      companyListAddresses={companyListAddresses}
      companyListContactPersons={companyListContactPersons}
    />
  );
};

const AddressesAndContactsContainerCached = memo(AddressesAndContactsContainer);

export { AddressesAndContactsContainerCached as AddressesAndContactsPage };
