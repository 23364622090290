import { createSelector } from 'reselect';

import { RootState } from 'app-wrapper/store';
import { SkypaceInformationDTM } from 'app-wrapper/constants';

const localState = (state: RootState) => state.documentHBLPDF;
const localStateShipment = (state: RootState) => state.shipment;
const getUserOrganizationData = (state: RootState) => state.userOrganizationData;

const getIsLoading = createSelector(
  localState,
  (state) => state.isLoading,
);

const getHBLDocumentBOL = createSelector(
  localState,
  (state) => state.billOfLadingHBL,
);
const getShipment = createSelector(
  localStateShipment,
  (state) => state.shipment,
);

const getHBLDocumentBOLFileNamePrint = createSelector(
  localState,
  getShipment,
  (state, shipment) => {
    let fileName = 'Skypace. House BL #';

    if (shipment?.id) {
      fileName += `${shipment.id}`;
    }

    fileName += `.${shipment?.origin.address || ''}`;
    fileName += ` - ${shipment?.destination.address || ''}`;

    return fileName;
  },
);

const getHeaderSkypaceInformation = createSelector(
  getUserOrganizationData,
  (state) => {
    if (state?.organization?.address.country?.toLocaleLowerCase() === 'us') {
      return SkypaceInformationDTM.getCompanyUS();
    }

    return SkypaceInformationDTM.getCompanyUK();
  },
);

const getCurrentOrgRelatedAdminPublicInfo = createSelector(
  localState,
  (state) => state.currentOrgRelatedAdminPublicInfo,
);

const getAccountHolderRelatedDocExportDepartment = createSelector(
  localState,
  (state) => state.accountHolderRelatedDocExportDepartment,
);

export const documentHBLSelectors = {
  getIsLoading,
  getHBLDocumentBOL,
  getHBLDocumentBOLFileNamePrint,
  getHeaderSkypaceInformation,
  getCurrentOrgRelatedAdminPublicInfo,
  getAccountHolderRelatedDocExportDepartment,
};
