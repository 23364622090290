import React, { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'antd/es/modal';

import { InvoiceStatusesEnum, PermissionAttributePolicy } from 'shipment-operations/constants';
import { ShipmentChargesPermissionsDtm } from 'shipment-operations/models/dtm';

import { Button } from 'app-wrapper/view/components';
import { Footer } from 'app-wrapper/view/guideline';

interface IButtonsProps {
  onOpenCreditNote: () => void
  isEmptyItems: boolean
  openPayment: () => void
  status?: string
  permissions: ShipmentChargesPermissionsDtm
}

const ButtonsComponent: FC<IButtonsProps> = ({
  onOpenCreditNote, isEmptyItems, openPayment, status, permissions,
}) => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const isDisabled = useMemo(() => (status === InvoiceStatusesEnum.PAID || status === InvoiceStatusesEnum.VOID), [status]);
  const isCustomer = useMemo(() => permissions.shipmentChargesAvailability === PermissionAttributePolicy.READ, [permissions]);

  return (
    <Footer>
      <Modal
        visible={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        okText={t('proceed')}
        cancelText={t('cancel')}
      >
        <p>Test modal</p>
      </Modal>
      {!isCustomer && (
      <Button
        size="large"
        type="default"
        style={{ marginRight: '8px' }}
        onClick={onOpenCreditNote}
        disabled={isEmptyItems}
      >
        {t('createCreditNote')}
      </Button>
      )}
      <Button
        htmlType="submit"
        size="large"
        onClick={isCustomer ? () => setIsModalOpen(true) : openPayment}
        disabled={isDisabled}
      >
        {t('makePayment')}
      </Button>
    </Footer>
  );
};

export { ButtonsComponent };
