import styled from 'styled-components';

import themesColors from 'app-wrapper/view/themes/themesColors';

export const UserContactsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const UserContactsIconBG = styled.div.attrs({
  'data-class': 'UserContactsIconBG',
})`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  background-color: transparent;
  border-radius: 4px;
  color: ${themesColors.primaryBranding5};
  border: 1px solid ${themesColors.neutralBranding4};
  font-size: 14px;
`;

export const UserContactsHeader = styled.div`
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
`;

export const UserContactsRow = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  height: 44px;
  width: 100%;
  margin-top: 12px;
`;

export const UserContactsItem = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  width: 50%;
`;

export const UserContactsData = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: flex-start;
  
  margin-left: 16px;
`;

export const UserContactsTitle = styled.div`
  font-size: 12px;
  color: ${themesColors.darkGrayMiddle};
  line-height: 20px;
  font-weight: 400;
`;

export const UserContactsValue = styled.div`
  display: flex;
  flex-flow: row nowrap;
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
`;
