import React, { FC, memo } from 'react';
import { useSelector } from 'react-redux';

import { R } from 'user-management/repository';
import { UC } from 'user-management/controllers';

import {
  EditProfileOrganizationFinancialComponent,
} from './EditProfileOrganizationFinancial.component';

const EditProfileOrganizationFinancialContainer: FC = () => {
  const organization = useSelector(R.selectors.userOrganizationData.getUserOrganizationUpdate);
  const organizationFieldsErrors = useSelector(R.selectors.userOrganizationData.organizationFieldsErrors);
  const userManagementPermissions = useSelector(R.selectors.moduleFunctionalityPermissions.getUserManagementPermissions);

  const organizationDisabledFieldsErrors = userManagementPermissions?.organizationEditProfileIdentifiers;

  return (
    <EditProfileOrganizationFinancialComponent
      organization={organization}
      organizationDisabledFieldsErrors={organizationDisabledFieldsErrors}
      organizationFieldsErrors={organizationFieldsErrors}
      onChangeUpdateBusinessRegistrationNumber={UC.organizationProfile.onChangeUpdateBusinessRegistrationNumber}
      onAddMembership={UC.organizationProfile.onAddUpdateMembership}
      onRemoveMembership={UC.organizationProfile.onRemoveUpdateMembership}
      onChangeUpdateMembershipType={UC.organizationProfile.onChangeUpdateMembershipType}
      onChangeUpdateMembershipValue={UC.organizationProfile.onChangeUpdateMembershipValue}
      onChangeUpdateTaxId={UC.organizationProfile.onChangeUpdateTaxId}
      onBlurUpdateBusinessRegistrationNumber={UC.organizationProfile.onBlurUpdateBusinessRegistrationNumber}
      onBlurUpdateMembership={UC.organizationProfile.onBlurUpdateMembership}
      onBlurUpdateTaxId={UC.organizationProfile.onBlurUpdateTaxId}
    />
  );
};

export const EditProfileOrganizationFinancial = memo(EditProfileOrganizationFinancialContainer);
