import styled from 'styled-components';

import themesColors from 'app-wrapper/view/themes/themesColors';
import { Button } from 'app-wrapper/view/components';

export const UserDataContainer = styled.div.attrs({
  'data-class': 'UserDataContainer',
})`
  display: grid;
  grid-template-columns: 40px 125px auto;
  grid-template-rows: 22px 20px auto;
  grid-template-areas: "UserIconContainer UserName UserName"
  "UserIconContainer UserEmail UserEmail"
  "UserIconContainer MyAccount SignOut";
  
  width: 320px;
  height: 74px;
`;

export const UserIconContainer = styled.div.attrs({
  'data-class': 'UserIconContainer',
})`
  grid-area: UserIconContainer;
  width: 40px;
  height: 40px;
`;

export const UserIconDefaultBG = styled.div.attrs({
  'data-class': 'UserIconDefaultBG',
})`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: ${themesColors.primaryBranding1};
  border-radius: 50%;
  color: ${themesColors.primaryBranding5};
`;

export const UserName = styled.div.attrs({
  'data-class': 'UserName',
})`
  grid-area: UserName;

  padding-left: 12px;
  width: 100%;
  height: 22px;

  font-weight: 700;
  font-size: 14px;
`;

export const UserEmail = styled.div.attrs({
  'data-class': 'UserEmail',
})`
  grid-area: UserEmail;
  
  padding-left: 12px;
  width: 100%;
  height: 20px;
  
  font-weight: 400;
  font-size: 12px;
  color: ${themesColors.darkGrayMiddle};
`;

export const MyAccount = styled.div.attrs({
  'data-class': 'MyAccount',
})`
  grid-area: MyAccount;
  margin-top: 8px;
  padding-left: 12px;
  width: 100%;
  height: 40px;
`;

export const SignOut = styled.div.attrs({
  'data-class': 'SignOut',
})`
  grid-area: SignOut;
  margin-top: 8px;
  padding-left: 8px;
  width: 100%;
  height: 40px;
`;

export const SignOutButton = styled(Button).attrs({
  'data-class': 'SignOutButton',
})`
  color: ${themesColors.characterBrandingDanger};
`;
