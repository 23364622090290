import { createAsyncThunk } from '@reduxjs/toolkit';

import Address from 'app-wrapper/types/Address';
import Company from 'app-wrapper/types/Company';
import CompanyContact from 'app-wrapper/types/CompanyContact';
import { asyncThunkPost, asyncThunkGet, post } from 'app-wrapper/utils';

import {
  IEntityGetParams,
  IEntitySearchParams,
  INewCompanyParams,
} from './store';

export const searchCompanies = asyncThunkGet<IEntitySearchParams>(
  'companies/search',
  '/contact-service/api/v1/companies',
);

export const getCompanyAddress = asyncThunkGet<IEntityGetParams, Address[]>(
  'companies/addressSearch',
  '/contact-service/api/v1/companies',
  'addresses',
);

export const getCompanyContacts = asyncThunkGet<IEntityGetParams, CompanyContact[]>(
  'companies/contacts',
  '/contact-service/api/v1/companies',
  'contacts',
);

export const createCompany = asyncThunkPost<INewCompanyParams, Company>(
  'companies/create',
  '/contact-service/api/v1/companies',
);

export const createCompanyContact = createAsyncThunk(
  'companies/createContact',
  async ({
    id,
    body,
  }: any) => {
    const response = await post(`/contact-service/api/v1/companies/${id}/contacts`, body);
    return response.json();
  },
);

export const createCompanyAddress = createAsyncThunk(
  'companies/createAddress',
  async ({
    id,
    body,
  }: any) => {
    const response = await post(`/contact-service/api/v1/companies/${id}/addresses`, body);
    return response.json();
  },
);
