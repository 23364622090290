import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useParams } from 'react-router';

import { Button } from 'app-wrapper/view/components';
import { EShippingPartyTypes, ShippingPartyNames } from 'shipment-operations/constants';

interface IShippingPartiesFormFooterComponentProps {
  isLoading: boolean
  hasAttachedCompany: boolean
  hasAnyFieldsChanged: boolean
  activeShippingParty: EShippingPartyTypes
  updateParties: (shipmentId: string) => void
}

export const ShippingPartiesFormFooterComponent: FC<IShippingPartiesFormFooterComponentProps> = ({
  isLoading,
  hasAttachedCompany,
  activeShippingParty,
  updateParties,
  hasAnyFieldsChanged,
}) => {
  const { t } = useTranslation();
  const { shipmentId = '' } = useParams<'shipmentId'>();

  const sendData = useCallback(() => {
    updateParties(shipmentId);
  }, [shipmentId]);

  return (
    <>
      <Button
        size="large"
        disabled={!hasAttachedCompany || !hasAnyFieldsChanged}
        loading={isLoading}
        onClick={sendData}
        data-class="ShippingPartiesFormFooter_ButtonUpdate"
      >
        {`${t('Update')} ${ShippingPartyNames[activeShippingParty]}`}
      </Button>
    </>
  );
};
