import styled from 'styled-components';
import { WarningOutlined } from '@ant-design/icons';

import { FlexCol } from 'app-wrapper/view/guideline';

export const Wrap = styled(FlexCol)`
`;

export const TextRow = styled.span``;

export const WarningIcon = styled(WarningOutlined)`
  color: rgb(241, 174, 0);
  cursor: pointer;
  margin-left: 4px;
`;
