import moment from 'moment';
import { AxiosError } from 'axios';

import { get as requestGet, post as requestPost } from 'app-wrapper/utils/fetchApi';
import { DateDtm, DocumentDTM } from 'app-wrapper/models/dtm';

import { TransportationOverviewReferenceType, EShipmentCancellationType } from 'shipment-operations/constants';
import { IGetTransportationOverviewResponse, IPostCutoffRequest } from 'shipment-operations/models/contracts';
import { TransportationOverviewDTM, TransportPlanDTM } from 'shipment-operations/models/dtm';
import { BadInttraRequestError } from 'shipment-operations/models/errors';
import { apiWorker } from 'app-wrapper/repository/utilsServices';

export class InttraService {
  private base = '/shipment-service/api/v1/shipments';

  public getBookingOverview = async (shipmentId: string) => {
    let transportationOverviewData: TransportationOverviewDTM | null = null;

    try {
      const rawResponse = await requestGet(`${this.base}/${shipmentId}/booking/overview`);

      if (!rawResponse.ok) {
        // get error message from body or default to response status
        const error = (rawResponse && rawResponse.body) || rawResponse.status;

        return Promise.reject(error);
      }

      if (rawResponse.status === 204) {
        return null;
      }

      const response = await rawResponse.json() as IGetTransportationOverviewResponse;

      const documentationCarrierDate = response.carrierCutOffDates.find((cutoff) => cutoff.type === 'DOCUMENTATION')?.value;
      const docTimeWithTz = this.getShiftedDate(documentationCarrierDate);

      const portCarrierDate = response.carrierCutOffDates.find((cutoff) => cutoff.type === 'PORT')?.value;
      const portTimeWithTz = this.getShiftedDate(portCarrierDate);

      const vgmCarrierDate = response.carrierCutOffDates.find((cutoff) => cutoff.type === 'VGM')?.value;
      const vgmTimeWithTz = this.getShiftedDate(vgmCarrierDate);

      const hazmatCarrierDate = response.carrierCutOffDates.find((cutoff) => cutoff.type === 'HAZMATS')?.value;
      const hazmatTimeWithTz = this.getShiftedDate(hazmatCarrierDate);

      const freightuneDocumentationCarrierDate = response.freightuneCutOffDates.find((cutoff) => cutoff.type === 'DOCUMENTATION')?.value;
      const freightuneDocTimeWithTz = this.getShiftedDate(freightuneDocumentationCarrierDate);

      const freightunePortCarrierDate = response.freightuneCutOffDates.find((cutoff) => cutoff.type === 'PORT')?.value;
      const freightunePortTimeWithTz = this.getShiftedDate(freightunePortCarrierDate);

      const freightuneVgmCarrierDate = response.freightuneCutOffDates.find((cutoff) => cutoff.type === 'VGM')?.value;
      const freightuneVgmTimeWithTz = this.getShiftedDate(freightuneVgmCarrierDate);

      const freightuneHazmatCarrierDate = response.freightuneCutOffDates.find((cutoff) => cutoff.type === 'HAZMATS')?.value;
      const freightuneHazmatTimeWithTz = this.getShiftedDate(freightuneHazmatCarrierDate);

      transportationOverviewData = TransportationOverviewDTM.fromPlain({
        carrierName: response.carrier.scac,
        carrierReference: response.carrier.reference,
        termsAndConditions: response.carrier.termsAndConditions ? response.carrier.termsAndConditions.join('\n') : '',
        comments: response.carrier.comments ? response.carrier.comments.join('\n') : '',

        contactName: response.carrier.contact.name,
        phoneList: response.carrier.contact.phones,
        emailList: response.carrier.contact.emails,

        mblNumber: response.references.find(({ type }) => type === TransportationOverviewReferenceType.MBL)?.value,
        inttraReferenceNumber: response.references.find(({ type }) => type === TransportationOverviewReferenceType.INTTRA_REFERENCE)?.value,
        contractNumber: response.references.find(({ type }) => type === TransportationOverviewReferenceType.CONTRACT)?.value,
        documentationCarrierDate: docTimeWithTz,
        portCarrierDate: portTimeWithTz,
        vgmCarrierDate: vgmTimeWithTz,
        hazmatCarrierDate: hazmatTimeWithTz,
        freightuneCutoffs: {
          documentationCarrierDate: freightuneDocTimeWithTz,
          portCarrierDate: freightunePortTimeWithTz,
          vgmCarrierDate: freightuneVgmTimeWithTz,
          hazmatCarrierDate: freightuneHazmatTimeWithTz,
        },
        shipper: {
          references: [],
        },
        consignee: {
          references: [],
        },
        notifyParty: {
          references: [],
        },
      });
    } catch (e) {
      throw new Error('Inttra service error. Get Booking overview');
    }

    return transportationOverviewData;
  };

  public cancelBooking = async (shipmentId: string, type: EShipmentCancellationType, message: string) => {
    try {
      const response = await requestPost(`${this.base}/${shipmentId}/booking/cancel`, {
        message,
        type,
      });

      if (!response.ok) {
        const body = await response.json();
        if (!body.message) {
          return undefined;
        }
        const error = new BadInttraRequestError(body.message);
        return Promise.reject(error);
      }
    } catch (e) {
      throw new Error('Inttra service error. Booking cancel');
    }

    return undefined;
  };

  public submitBooking = async (shipmentId: string, corrections?: string) => {
    await apiWorker.requestPostBySchema(`${this.base}/${shipmentId}/booking` as '/api/v1/shipments/{shipmentId}/booking', corrections);

    return true;
  };

  public submitShipmentInstructions = async (shipmentId: string) => {
    await apiWorker.requestPostBySchema(`${this.base}/${shipmentId}/shipment-instruction` as '/api/v1/shipments/{shipmentId}/shipment-instruction');

    return true;
  };

  public submitDocumentationCutoff = async (shipmentId: string, cutoff: DateDtm) => {
    try {
      const cutoffRequest: IPostCutoffRequest = {
        type: 'DOCUMENTATION',
        value: cutoff.getDateAsMomentWithOffset().format(),
      };

      const documentationCutoffResponse = await requestPost(`${this.base}/${shipmentId}/cutoffs`, cutoffRequest);

      if (!documentationCutoffResponse.ok) {
        const body = await documentationCutoffResponse.json();
        const error = new Error(body.message);

        return Promise.reject(error);
      }
    } catch (e) {
      throw new Error('Inttra service error. Submit documentation cutoff');
    }

    return undefined;
  };

  public submitPortCutoff = async (shipmentId: string, cutoff: DateDtm) => {
    try {
      const cutoffRequest: IPostCutoffRequest = {
        type: 'PORT',
        value: cutoff.getDateAsMomentWithOffset().format(),
      };

      const portCutoffResponse = await requestPost(`${this.base}/${shipmentId}/cutoffs`, cutoffRequest);

      if (!portCutoffResponse.ok) {
        const body = await portCutoffResponse.json();
        const error = new Error(body.message);

        return Promise.reject(error);
      }
    } catch (e) {
      throw new Error('Inttra service error. Submit port cutoff');
    }

    return undefined;
  };

  public submitVgmCutoff = async (shipmentId: string, cutoff: DateDtm) => {
    try {
      const cutoffRequest: IPostCutoffRequest = {
        type: 'VGM',
        value: cutoff.getDateAsMomentWithOffset().format(),
      };

      const vgmCutoffResponse = await requestPost(`${this.base}/${shipmentId}/cutoffs`, cutoffRequest);

      if (!vgmCutoffResponse.ok) {
        const body = await vgmCutoffResponse.json();
        const error = new Error(body.message);

        return Promise.reject(error);
      }
    } catch (e) {
      throw new Error('Inttra service error. Submit port cutoff');
    }

    return undefined;
  };

  public submitHazmatCutoff = async (shipmentId: string, cutoff: DateDtm) => {
    try {
      const cutoffRequest: IPostCutoffRequest = {
        type: 'HAZMATS',
        value: cutoff.getDateAsMomentWithOffset().format(),
      };

      const hazmatCutoffResponse = await requestPost(`${this.base}/${shipmentId}/cutoffs`, cutoffRequest);

      if (!hazmatCutoffResponse.ok) {
        const body = await hazmatCutoffResponse.json();
        const error = new Error(body.message);

        return Promise.reject(error);
      }
    } catch (e) {
      throw new Error('Inttra service error. Submit cutoff');
    }

    return undefined;
  }

  public manualConfirmBooking = async (shipmentId: string, transportPlan: TransportPlanDTM, carrierReferenceNumber: string, confirmationDocument: DocumentDTM) => {
    try {
      await apiWorker.requestPost(`${this.base}/${shipmentId}/booking/confirm`, {
        carrierReferenceNumber,
        confirmationFile: confirmationDocument,
        transportationPlan: transportPlan,
      });
    } catch (e) {
      const error = e as AxiosError<{ message: string }>;

      throw new Error(error?.response?.data.message);
    }
  }

  private getShiftedDate = (date?: string | null) => {
    if (!date) {
      return undefined;
    }

    const docTime = DateDtm.fromPlain({
      date,
      offset: moment.parseZone(date).utcOffset(),
    });

    return date ? docTime : undefined;
  }
}
