import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import filter from 'lodash/fp/filter';
import isEmpty from 'lodash/fp/isEmpty';

import { CreditNoteItemDTM } from 'shipment-operations/models/dtm';
import { prepareCreditNotesForTable } from 'shipment-operations/view/pages/ShipmentBillingInvoice/utils/prepareDataForTable';

import {
  CustomCell, TableHeader, StyledSection, StyledTable,
} from 'shipment-operations/view/components/CustomTable/CustomTable.styled';

interface ICustomTableProps {
  data: CreditNoteItemDTM[]
}

const CustomTable: FC<ICustomTableProps> = ({ data }) => {
  const { t } = useTranslation();

  const columns = useMemo(() => [
    {
      title: t('createInvoiceTable.service'),
      dataIndex: 'service',
      key: 'service',
      render: (title: string) => <CustomCell>{title}</CustomCell>,
      width: '80%',
    },
    {
      title: t('createInvoiceTable.total'),
      dataIndex: 'total',
      key: 'total',
      align: 'right' as 'right',
      width: '20%',
      render: (sum: number) => `$ ${sum}`,
    },
  ], []);

  const originData = useMemo(() => {
    const filtered = filter((item) => item.invoiceItem.charge.designation === 'ORIGIN', data);
    return prepareCreditNotesForTable(filtered);
  }, [data]);

  const freightData = useMemo(() => {
    const filtered = filter((item) => item.invoiceItem.charge.designation === 'FREIGHT', data);
    return prepareCreditNotesForTable(filtered);
  }, [data]);

  const destinationData = useMemo(() => {
    const filtered = filter((item) => item.invoiceItem.charge.designation === 'DESTINATION', data);
    return prepareCreditNotesForTable(filtered);
  }, [data]);

  return (
    <>
      <TableHeader columns={columns} />
      {!isEmpty(originData) && (
        <>
          <StyledSection>{t('Origin')}</StyledSection>
          <StyledTable
            columns={columns}
            showHeader={false}
            dataSource={originData}
            pagination={false}
          />
        </>
      )}
      {!isEmpty(freightData) && (
        <>
          <StyledSection>{t('freight')}</StyledSection>
          <StyledTable
            columns={columns}
            showHeader={false}
            dataSource={freightData}
            pagination={false}
          />
        </>
      )}
      {!isEmpty(destinationData) && (
        <>
          <StyledSection>{t('destination')}</StyledSection>
          <StyledTable
            columns={columns}
            showHeader={false}
            dataSource={destinationData}
            pagination={false}
          />
        </>
      )}
    </>
  );
};

export { CustomTable };
