import React, { FC, memo } from 'react';
import { useSelector } from 'react-redux';

import { UC } from 'user-management/controllers';
import { R } from 'user-management/repository';

import { CustomersComponent } from './CustomersList.component';
import { AccountDrawer } from '../Account/components';

const CustomersListContainer: FC = () => {
  const organizations = useSelector(R.selectors.userOrganizationData.getOrganizationsActiveCustomer);
  const getOrganizationsWaitingApprovalCustomer = useSelector(R.selectors.userOrganizationData.getOrganizationsWaitingApprovalCustomer);
  const getOrganizationsRejectCustomer = useSelector(R.selectors.userOrganizationData.getOrganizationsRejectCustomer);
  const getOrganizationsActiveIsEnd = useSelector(R.selectors.userOrganizationData.getOrganizationsActiveIsEnd);
  const getOrganizationsWaitingApprovalIsEnd = useSelector(R.selectors.userOrganizationData.getOrganizationsWaitingApprovalIsEnd);
  const getOrganizationsRejectIsEnd = useSelector(R.selectors.userOrganizationData.getOrganizationsRejectIsEnd);
  const getOrganizationsStatus = useSelector(R.selectors.userOrganizationData.getOrganizationsStatus);
  const isAdmin = useSelector(R.selectors.userOrganizationData.isAdmin);

  return (
    <>
      <CustomersComponent
        isAdmin={isAdmin}
        onStartInternal={UC.organizationProfile.onStartCustomerPage}
        onEndInternal={UC.organizationProfile.onEndCustomerPage}
        nextOrganizationActive={UC.organizationProfile.nextPageCustomerActive}
        nextOrganizationWaitingApproval={UC.organizationProfile.nextPageCustomerWaitingApproval}
        nextOrganizationReject={UC.organizationProfile.nextPageCustomerReject}
        onResetInternalPage={UC.organizationProfile.onResetInternalPage}
        onClickCustomerInternal={UC.organizationProfile.onClickCustomerInternal}
        onClickCustomerAddInternal={UC.organizationProfile.openCustomerAddInternal}
        allDataInternal={organizations}
        organizationWaitingApproval={getOrganizationsWaitingApprovalCustomer}
        organizationReject={getOrganizationsRejectCustomer}
        isEndOrganizationActive={getOrganizationsActiveIsEnd}
        isEndOrganizationWaitingApproval={getOrganizationsWaitingApprovalIsEnd}
        isEndOrganizationReject={getOrganizationsRejectIsEnd}
        isLoadInternal={getOrganizationsStatus}
      />
      <AccountDrawer />
    </>
  );
};

const CustomersContainerCache = memo(CustomersListContainer);

export { CustomersContainerCache as CustomersListPage };
