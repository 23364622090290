import React from 'react';

import { IPropsIcon } from 'app-wrapper/interfaces/icon';

export const CargoWeight = (props: IPropsIcon) => {
  const { width, height, fill } = props;
  return (
    <svg
      width={width || '14'}
      height={height || '13'}
      fill={fill || 'currentColor'}
      viewBox="0 0 14 13"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M13.1882 11.0933L11.4139 3.99609C11.3167 3.63151 11.0007 3.38845 10.6605 3.38845H8.88615C9.05629 3.07248 9.15351 2.75651 9.15351 2.41623C9.15351 1.22526 8.18129 0.277344 7.01462 0.277344C5.82365 0.277344 4.87573 1.22526 4.87573 2.41623C4.87573 2.75651 4.94865 3.07248 5.11879 3.38845H3.34448C3.0042 3.38845 2.68823 3.63151 2.59101 3.99609L0.816702 11.0933C0.622258 11.9197 1.18129 12.6975 1.95906 12.6975H12.0459C12.8236 12.6975 13.3827 11.9197 13.1882 11.0933ZM6.0424 2.39193C6.0424 1.8572 6.45559 1.4197 7.01462 1.4197C7.54934 1.4197 7.98684 1.8572 7.98684 2.39193C7.98684 2.95095 7.54934 3.36415 7.01462 3.36415C6.45559 3.36415 6.0424 2.95095 6.0424 2.39193ZM12.0216 11.5308H1.98337C1.95906 11.5308 1.93476 11.4579 1.95906 11.385L3.66045 4.53082H10.3445L12.0459 11.385C12.0702 11.4579 12.0459 11.5308 12.0216 11.5308Z" fill="#D1D7E2" />
    </svg>
  );
};
