import React, { FC, memo } from 'react';

import { useAppSelector } from 'app-wrapper/hooks';

import { R } from 'user-management/repository';
import { UC } from 'user-management/controllers';

import { UC as shipmentUC } from 'shipment-operations/controllers';

import { AccountDepartmentsComponent } from './AccountDepartments.component';

const AccountDepartmentsContainer: FC = () => {
  const contactBookDefault = useAppSelector(R.selectors.accountDepartments.getAccountDepartmentsDefault);
  const accountDepartmentsDefaultDepartmentList = useAppSelector(R.selectors.accountDepartments.getAccountDepartmentsDefaultDepartmentList);

  return (
    <AccountDepartmentsComponent
      departments={accountDepartmentsDefaultDepartmentList}
      isLoading={contactBookDefault.isRequest}
      runComponent={UC.accountDepartments.onRunAccountDepartmentsPage}
      openAddMemberDrawer={shipmentUC.shipmentPeople.openAddMemberDrawer}
      onClickDepartmentItem={UC.accountDepartments.onNextPageAccountDepartmentsById}
    />
  );
};

const AccountDepartmentsContainerCached = memo(AccountDepartmentsContainer);

export { AccountDepartmentsContainerCached as AccountDepartmentsPage };
