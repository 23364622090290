import React from 'react';

import { useSelector } from 'react-redux';
import { R } from 'user-management/repository';
import { TermsAndConditionsComponent } from './TermsAndConditions.component';
import { TermsAndConditionsNotUSDrawer } from './TermsAndConditionsNotUSDrawer.component';

const TermsAndConditionsContainer = () => {
  const organization = useSelector(R.selectors.userOrganizationData.getUserOrganization);
  const isCountryUS = organization?.address?.country === 'US';

  return (
    isCountryUS
      ? <TermsAndConditionsComponent />
      : <TermsAndConditionsNotUSDrawer />
  );
};

export { TermsAndConditionsContainer as TermsAndConditions };
