import styled from 'styled-components';
import AntCheckbox from 'antd/es/checkbox';

export const Checkbox = styled(AntCheckbox)`
  margin: 2.5px;
  width: 15px;
  height: 15px;
  position: relative;
  border: 1.5px solid #4f4f4f;
  border-radius: 4px;
  overflow: hidden;
`;
