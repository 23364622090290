import styled from 'styled-components';
import Table from 'antd/es/table';
import InputNumber from 'antd/es/input-number';

export const Wrapper = styled.div`
  padding: 20px;
  background-color: #F4F5F8;
  width: 100%;
  height: 100%;
  position: relative;
`;

export const MainTable = styled(Table)`
  .title {
    background-color: #F9F9FB;
    :hover {
      background-color: #F9F9FB !important;
    }
  }
  .title .titleColumn {
    background-color: #F9F9FB;
    :hover {
      background-color: #F9F9FB !important;
    }
  }
  .ant-table-tbody > tr.ant-table-row:hover > td {
    background: white;
  }
  .ant-table-tbody > tr.title:hover > td {
    background: #F9F9FB;
  }
  .ant-table-thead > tr > th {
    background: #F9F9FB;
  }
`;

export const StyledInput = styled(InputNumber)`
  width: 100%;
  &:focus {
    outline: none;
    box-shadow: none;
  }
`;

export const RedDot = styled.div`
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: red;
  display: inline-block;
  margin-left: 6px;
  margin-bottom: 6px;
`;

export const TableWrapper = styled.div`
  height: 100%;
`;

export const ChargeColumn = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
