import React, { forwardRef } from 'react';
import { SwitchProps } from 'antd/es/switch';

import { Switch } from './Switch.styled';

// TODO: fix types
export const SwitchComponent = forwardRef<any, SwitchProps>((props, ref) => (
  <Switch
    ref={ref as React.RefObject<any>}
    {...props}
    className={
      /* eslint-disable-next-line react/prop-types */
      `${props.className}`
    }
  />
));

export { SwitchComponent as Switch };
