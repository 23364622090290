import React, { FC } from 'react';
import isEmpty from 'lodash/fp/isEmpty';
import { useTranslation } from 'react-i18next';
import Divider from 'antd/es/divider';

import { calculateChargesSum } from 'shipment-operations/view/pages/ShipmentCharges/utils/calculateSum';
import { ChargeDTM, ContainerViewDTM, IGroupedChargesDTM } from 'shipment-operations/models/dtm';

import {
  HeaderTableWrapper,
  InnerContainer,
  StyledTotal,
  Title,
} from 'shipment-operations/view/components';
import { CustomTable, ExpandPanel } from 'shipment-operations/view/pages/CreateInvoice/components/index';

interface IAdditionalComponentProps {
  data: IGroupedChargesDTM
  sum: number
  containers: ContainerViewDTM[]
  tableData: ChargeDTM[]
}

const AdditionalComponent: FC<IAdditionalComponentProps> = ({
  containers, data, tableData, sum,
}) => {
  const { t } = useTranslation();

  return ((!isEmpty(data) || !isEmpty(tableData)) ? (
    <>
      <InnerContainer>
        <Title>{t('additionalChargesTitle')}</Title>
        {!isEmpty(containers) && (
          <>
            <Divider style={{ margin: '0' }} />
            <HeaderTableWrapper>
              <span>{t('container')}</span>
              <span>{t('total')}</span>
            </HeaderTableWrapper>
            <Divider style={{ margin: '0' }} />
          </>
        )}
        {!isEmpty(data) && containers.map((item) => (
          <ExpandPanel
            container={item}
            sum={calculateChargesSum(data[item.id])}
            key={item.id}
            keys={data[item.id].map((elem) => elem.id)}
          >
            <CustomTable data={data[item.id]} fullTable />
          </ExpandPanel>
        ))}
        {!isEmpty(tableData) && (<CustomTable data={tableData} fullTable={false} />)}
      </InnerContainer>
      <StyledTotal>$ {sum.toFixed(2)}</StyledTotal>
    </>
  ) : null);
};

export { AdditionalComponent };
