import React from 'react';

import { useAppSelector } from 'app-wrapper/hooks';

import { R } from 'shipment-operations/repository';
import { UC } from 'shipment-operations/controllers';

import { PaymentFooter } from './Footer.component';

const FooterContainer = () => {
  const disabled = useAppSelector(R.selectors.makePayment.getIsRecordDisabled);
  const isLoading = useAppSelector(R.selectors.makePayment.getIsLoadingRecord);

  return (
    <PaymentFooter
      disabled={disabled}
      isLoading={isLoading}
      onClick={UC.makePayment.onRecord}
      buttonName="Record"
    />
  );
};

export { FooterContainer as Footer };
