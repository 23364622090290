import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { HBLDocumentChargesInfoDTM, PaymentTermsDTM } from 'shipment-operations/models/dtm';
import { Bold } from 'shipment-operations/view/components/HBLDocumentPDF/components';

import {
  Row,
  Column,
  CustomColumn,
  Span,
} from '../Common.styled';
import { ChargeCell } from '../index';
import { Wrapper } from './Charges.styled';

interface ChargesComponentProps {
  chargesInfo : HBLDocumentChargesInfoDTM;
  paymentTerms: PaymentTermsDTM;
}

export const Charges: FC<ChargesComponentProps> = ({ chargesInfo, paymentTerms }) => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <Row>
        <Column flex={3}>
          <Bold bottomMargin>
            {t('Freight and Charges')}
          </Bold>
          <Row>
            <ChargeCell
              label={t('Origin')}
              value={chargesInfo.originChargesSum}
              sign={paymentTerms.origin}
            />
            <ChargeCell
              label={t('Freight')}
              value={chargesInfo.freightChargesSum}
              sign={paymentTerms.freight}
            />
            <ChargeCell
              label={t('Destination')}
              value={chargesInfo.destinationChargesSum}
              sign={paymentTerms.destination}
            />
          </Row>
        </Column>
        <CustomColumn>
          <Bold bottomMargin>
            {t('Declared Cargo Value')}
          </Bold>
          <Span fontSize={0.6}>{t('(For AD valorem purpose only)')}<br /> {t('Refer to Clause 26 on Reverse hereof')}</Span>
          {chargesInfo.cargoSum}
        </CustomColumn>
      </Row>
      <Row />
    </Wrapper>
  );
};
