import React from 'react';
import { IPropsIcon } from 'app-wrapper/interfaces/icon';

import { Icon } from './LogoMenuSvg.styled';

export const LogoMenuSvg = (props: IPropsIcon) => {
  const { width, height, fill } = props;
  return (
    <Icon
      className="LogoMenuSvg"
      width={width || '47'}
      height={height || '48'}
      viewBox="0 0 47 48"
      fill={fill || 'currentColor'}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M23.5 11C16.0353 11 10 17.0353 10 24.5C10 31.9647 16.0353 38 23.5 38C30.9647 38 37 31.9647 37 24.5C37 17.0353 30.9647 11 23.5 11ZM28.9 18.0412C29.4029 18.0412 29.8265 18.4647 29.8265 18.9676C29.8265 19.4706 29.4029 19.8941 28.9 19.8941C28.3971 19.8941 27.9735 19.4706 27.9735 18.9676C27.9735 18.4647 28.3971 18.0412 28.9 18.0412ZM17.9412 31.0118C17.4382 31.0118 17.0147 30.5882 17.0147 30.0853C17.0147 29.5824 17.4382 29.1588 17.9412 29.1588C18.4441 29.1588 18.8676 29.5824 18.8676 30.0853C18.8676 30.5882 18.4706 31.0118 17.9412 31.0118ZM27.7618 30.7206H21.6735C21.3029 30.7206 21.0118 30.4294 21.0118 30.0588C21.0118 29.6882 21.3029 29.3971 21.6735 29.3971H27.7618C28.8206 29.3971 29.6941 28.4441 29.6941 27.2794C29.6941 26.1147 28.8206 25.1618 27.7618 25.1618H19.2382C17.4382 25.1618 15.9824 23.6265 15.9824 21.7206C15.9824 19.8147 17.4382 18.2794 19.2382 18.2794H25.1941C25.5647 18.2794 25.8559 18.5706 25.8559 18.9412C25.8559 19.3118 25.5647 19.6029 25.1941 19.6029H19.2382C18.1794 19.6029 17.3059 20.5559 17.3059 21.7206C17.3059 22.8853 18.1794 23.8382 19.2382 23.8382H27.7618C29.5618 23.8382 31.0176 25.3735 31.0176 27.2794C31.0176 29.1853 29.5618 30.7206 27.7618 30.7206Z" fill="#202C3B" />
      <defs>
        <filter id="filter0_i_1715_98735" x="0" y="0" width="47" height="48" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dx="-1" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.956863 0 0 0 0 0.960784 0 0 0 0 0.972549 0 0 0 1 0" />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_1715_98735" />
        </filter>
      </defs>
    </Icon>
  );
};
