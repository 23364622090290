import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { IShortContainerDTM } from 'shipment-operations/models/dtm';
import { ContainerTypesConst } from 'shipment-operations/constants';
import { ContainerType } from 'shipment-operations/view/components/ExpandPanel/ExpandPanel.styled';

import { SDHContainerColumn, SDHContainerColumnName } from './SDHContainerColumn.styled';

export interface ISDHContainerColumnComponent extends Omit<IShortContainerDTM, 'number'> {
  number?: string | null;
}

const SDHContainerColumnComponent: FC<ISDHContainerColumnComponent> = (props) => {
  const { number, id, type } = props;
  const { t } = useTranslation();

  const containerName = useMemo(() => {
    if (number) {
      return number;
    }

    return t('Number pending entry');
  }, [id, number, t]);

  const containerType = useMemo(() => ContainerTypesConst[type], [type]);

  return (
    <SDHContainerColumn>
      {containerType ? (
        <ContainerType active={false}>
          {ContainerTypesConst[type]} &apos;
        </ContainerType>
      ) : null}

      <SDHContainerColumnName>
        {containerName}
      </SDHContainerColumnName>
    </SDHContainerColumn>
  );
};

export { SDHContainerColumnComponent as SDHContainerColumn };
