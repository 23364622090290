import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { TeamDTM, OrganizationMemberDTM } from 'user-management/models/dtm';
import { Select, Option } from 'app-wrapper/view/components';

import { Field, Label } from './CustomerAddInternalTeam.styled';

interface CustomerAddInternalTeamComponentProps {
  teams: TeamDTM[];
  accountManagers: OrganizationMemberDTM[];
  tempTeam?: number;
  tempAccountManager?: string;
  setTempTeam: (teamId: number) => void;
  setTempAccountManager: (email: string) => void;
  teamError: boolean;
  accountManagerError: boolean;
}

export const CustomerAddInternalTeamComponent: FC<CustomerAddInternalTeamComponentProps> = ({
  teams,
  accountManagers,
  tempTeam,
  tempAccountManager,
  setTempTeam,
  setTempAccountManager,
  teamError,
  accountManagerError,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Field>
        <Label>{t('Account Manager *')}</Label>

        <Select
          data-class="customer-internal-manager-select"
          placeholder={t('Choose Account Manager')}
          value={tempAccountManager}
          onChange={setTempAccountManager}
          error={accountManagerError}
        >
          {accountManagers.map((manager) => (
            <Option
              data-class="manager-select-option"
              key={manager.email}
              value={manager.email}
            >
              {manager.getFullName()}
            </Option>
          ))}
        </Select>
      </Field>

      <Field>
        <Label>{t('Account Team *')}</Label>

        <Select
          data-class="customer-internal-team-select"
          placeholder={t('Choose Team')}
          value={tempTeam}
          onChange={setTempTeam}
          error={teamError}
        >
          {teams.map((team) => (
            <Option
              data-class="team-select-option"
              key={team.id}
              value={team.id}
            >
              {team.name}
            </Option>
          ))}
        </Select>
      </Field>
    </>
  );
};
