import React, {
  useState, useEffect, useCallback, FC,
} from 'react';
import Steps from 'antd/es/steps';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { ShipmentDrawerWrapper } from 'shipment-operations/view/pages/Shipment/components/ShipmentDrawer/ShipmentDrawer.styled';
import { Button } from 'app-wrapper/view/components';
import {
  Wrapper, FooterWrapper,
} from 'shipment-operations/view/drawers/ShipmentChanges/ShipmentChanges.styled';

import { FirstStep, SecondStep, ThirdStep } from 'shipment-operations/view/drawers/MaerskChanges/components';
import { TabsWrapper } from 'shipment-operations/view/drawers/MaerskChanges/MaerskChanges.styled';
import { ShipmentBookingStatusEnum } from 'shipment-operations/constants';

interface IMaerskChangesComponentProps {
  isSearchSuccess: boolean;
  onConfirm: (shipmentId: string) => void;
  actionLoading: boolean;
  onClose: (val: boolean) => void;
  savedShipmentId: string;
  reset: () => void;
  bookingStatus?: ShipmentBookingStatusEnum;
  inttraNumber?: string | null;
  updatedIntraNumber?: string | null;
  updatedBookingStatus?: ShipmentBookingStatusEnum;
  isDisableSubmit: boolean;
}

const MaerskChangesComponent: FC<IMaerskChangesComponentProps> = ({
  isSearchSuccess, onConfirm, actionLoading, onClose, savedShipmentId, reset, inttraNumber, bookingStatus,
  updatedIntraNumber, updatedBookingStatus, isDisableSubmit,
}) => {
  const { t } = useTranslation();
  const { shipmentId } = useParams();
  const [currentStep, setCurrentStep] = useState(0);

  const handleClose = useCallback(() => onClose(false), []);

  useEffect(() => {
    if (isSearchSuccess) {
      setCurrentStep(2);
    }
  }, [isSearchSuccess]);

  useEffect(() => {
    if ((bookingStatus === ShipmentBookingStatusEnum.BOOKING_SUBMITTED && inttraNumber) || (updatedBookingStatus === ShipmentBookingStatusEnum.BOOKING_SUBMITTED && updatedIntraNumber)) {
      setCurrentStep(2);
    }
  }, [inttraNumber, updatedIntraNumber]);

  useEffect(() => () => reset(), []);

  const handleConfirm = useCallback(() => {
    if (shipmentId || savedShipmentId) {
      onConfirm(shipmentId || savedShipmentId);
    }
  }, [shipmentId]);

  const handleClickNext = useCallback(() => {
    setCurrentStep(currentStep + 1);
  }, [currentStep]);

  const handleClickBack = useCallback(() => {
    setCurrentStep(currentStep - 1);
  }, [currentStep]);

  return (
    <ShipmentDrawerWrapper
      title={t('Maersk changes')}
      visible
      width="1137px"
      closable
      maskClosable={false}
      onClose={handleClose}
    >
      <Wrapper>
        <TabsWrapper>
          <Steps current={currentStep}>
            <Steps.Step title={t('Request booking')} />
            <Steps.Step title={t('Retrieve confirmation')} />
            <Steps.Step title={t('Review and confirm')} />
          </Steps>
        </TabsWrapper>
        {currentStep === 0 && <FirstStep />}
        {currentStep === 1 && <SecondStep />}
        {currentStep === 2 && <ThirdStep />}
      </Wrapper>
      <FooterWrapper $isLeftAlign={currentStep === 1}>
        {currentStep === 0 && (
          <Button onClick={handleClickNext}>{t('next')}</Button>
        )}
        {currentStep === 1 && (
          <Button onClick={handleClickBack}>{t('back')}</Button>
        )}
        {currentStep === 2 && (
          <Button disabled={isDisableSubmit} loading={actionLoading} onClick={handleConfirm}>{t('confirmShipment')}</Button>
        )}
      </FooterWrapper>
    </ShipmentDrawerWrapper>
  );
};

export { MaerskChangesComponent };
