import styled from 'styled-components';
import {
  MainContent, responsive,
} from 'app-wrapper/view/themes/mixin';
import {
  MainContentCollapse, MainContentRightWrapperFullCollapse, MainContentSidebarFullCollapse, divFlex,
} from 'app-wrapper/view/themes/mixin/main';
import ImageQuoteScreen from 'monetary/view/assets/Image_quote_screen.png';

// Content

export const AllRequestedSecondStepSpinWrap = styled.div.attrs({
  'data-class-second': 'AllRequestedSecondStepSpinWrap',
})`
  ${divFlex}
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;
`;

export const AllRequestedSecondStepDiv = styled(MainContent).attrs({
  'data-class-second': 'AllRequestedSecondStepDiv',
})`
  width: calc(100% - 24px);
  padding-left: 24px;
`;

export const AllRequestedSecondStepWrap = styled.div.attrs({
  'data-class-second': 'AllRequestedSecondStepWrap',
})`

  padding-left: 24px;
`;

export const AllRequestedSecondStepFirst = styled(MainContentCollapse).attrs({
  'data-class-second': 'AllRequestedSecondStepFirst',
})`


.FreightQuoteFormRequest__isCollapsed {
    min-width: calc(762px - 24px);

    .FreightQuoteFormRequest {
      &__Row {
        &__col {
          &_both_col {
            height: 95px;
          }
        }

        &__Commodity, &__Containers {
          &__content {
            &_title {
              margin-top: 8px;
            }
          }
        }
      }
    }
  }
`;

export const AllRequestedSecondStepSecond = styled(MainContent).attrs((props: {
  isCollapseAndEmptyQuota: boolean
  isEmptyBg: boolean
  'data-class'?: string
}) => ({
  isCollapseAndEmptyQuota: props.isCollapseAndEmptyQuota,
  isEmptyBg: props.isEmptyBg,
  'data-class': props?.['data-class'] || 'AllRequestedSecondStepSecond',
}))`

  background-image: url(${ImageQuoteScreen});
  background-repeat: no-repeat;
  background-position: right;
  ${({ isCollapseAndEmptyQuota }) => `background-position-x: ${isCollapseAndEmptyQuota ? '704px' : '404px'};`}
  background-size: cover;

  ${({ isEmptyBg }) => isEmptyBg && 'background: none;'};
`;

export const AllRequestedSecondStepMainContentSidebarFullCollapse = styled(
  MainContentSidebarFullCollapse,
).attrs((props: {
  isCollapse: boolean,
}) => ({
  ...props,
  isCollapse: props.isCollapse,
  'data-class-second': 'AllRequestedSecondStepMainContentSidebarFullCollapse',
}))`
  .FreightQuoteFormRequest {
    &__Row {
      &__col {
        &_both_col {
          ${responsive.forFullMedia`
            ${({ isCollapse }: { isCollapse: boolean }) => !isCollapse && 'height: 235px;'}
          `}
        }
      }
    }
  }
  .FreightQuoteFormRequest {
    ${responsive.forFullMedia`
      width: 100%;
      max-width: calc(762px - 24px);
      min-width: 670px;
      ${({ isCollapse }: { isCollapse: boolean }) => !isCollapse && 'min-width: calc(390px - 24px);'}
  `}
    ${responsive.forFullMediaMax`
      width: 100%;
      max-width: calc(480px - 24px);
    `}
    input:disabled,
    .ant-select-disabled span {
      color: ${({ theme }) => theme.themesColors.secondaryDot45};
    }
    .ant-radio-button-wrapper-disabled span {
      color: ${({ theme }) => theme.themesColors.characterBrandingDisabled025};
    }
    .ant-picker-suffix {
      svg {
        color: ${({ theme }) => theme.themesColors.characterBrandingDisabled025};
      }
    }
    .TypeSwitchController.disabled-bg {
      .TypeSwitchController__text {
        color: ${({ theme }) => theme.themesColors.secondaryDot45};
      }
      .TypeSwitchController__text-inactive {
        color: ${({ theme }) => theme.themesColors.characterBrandingDisabled025};
      }
      .ant-switch {
        background-color: ${({ theme }) => theme.themesColors.characterBrandingDisabled025};
      }
    }
  }
  .FreightQuoteFormRequest.FreightQuoteFormRequest__isCollapsed {
      ${responsive.forFullMedia`
        min-width: 670px;
      `}
    }
`;

export const AllRequestedSecondStepMainContentRightWrapperFullCollapse = styled(
  MainContentRightWrapperFullCollapse,
).attrs({
  'data-class-second': 'AllRequestedSecondStepMainContentRightWrapperFullCollapse',
})`
  background: none;

  div[data-class="MainContentRight"] {
    background: none;
  }
`;

export const AllRequestedSecondStepWrapper = styled.div.attrs({
  'data-class': 'AllRequestedSecondStepWrapper',
})`
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 0;
  background-color: ${({ theme }) => theme.themesColors.lightGrayBorder};
`;
