import React, { FC } from 'react';

import { BoxWrapper } from './Box.styled';

interface IBoxProps {
  selected?: boolean;
  hasError?: boolean;
  onClick?: () => void;
}

export const Box: FC<IBoxProps> = ({
  children, selected, hasError, onClick,
}) => (
  <BoxWrapper selected={selected} hasError={hasError} onClick={onClick}>{children}</BoxWrapper>
);

// TODO: delete, because moved to shipment-operations/view/components/ShipmentDetailsCard
