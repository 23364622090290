import { BaseController, controller } from 'proto/BaseController';

import { R } from 'user-management/repository';
import { RouteNames } from 'app-wrapper/constants';

@controller
export class NewOrganizationWizardController extends BaseController {
  public goToWizard = () => {
    this.navigate(RouteNames.NEW_ORGANIZATION_WIZARD());
  };

  public stepClick = (stepNumber: number) => {
    if (stepNumber === 0) {
      this.navigate(RouteNames.ADD_COMPANY_DETAILS_WIZARD_ITEM());

      return;
    }

    if (stepNumber === 1) {
      this.navigate(RouteNames.SUBMIT_PROOF_DOCUMENTS_WIZARD_ITEM());

      return;
    }

    if (stepNumber === 2) {
      this.navigate(RouteNames.NEW_ORGANIZATION_REVIEW_AND_CONFIRM_WIZARD_ITEM());

      return;
    }

    this.navigate(RouteNames.NEW_ORGANIZATION_WIZARD());
  };

  public onStepChange = (path: string) => {
    const activeStepNumber = R.selectors.newOrganizationWizardSteps.getActiveStepNumber(this.store.getState());

    if (activeStepNumber !== 0 && path === RouteNames.ADD_COMPANY_DETAILS_WIZARD_ITEM()) {
      this.dispatch(R.actions.newOrganizationWizardSteps.setActiveStepNumber(0));

      return;
    }

    if (activeStepNumber !== 1 && path === RouteNames.SUBMIT_PROOF_DOCUMENTS_WIZARD_ITEM()) {
      this.dispatch(R.actions.newOrganizationWizardSteps.setActiveStepNumber(1));

      return;
    }

    if (activeStepNumber !== 2 && path === RouteNames.NEW_ORGANIZATION_REVIEW_AND_CONFIRM_WIZARD_ITEM()) {
      this.dispatch(R.actions.newOrganizationWizardSteps.setActiveStepNumber(2));
    }
  }

  public clear = () => {
    this.dispatch(R.actions.userOrganizationData.clear());
  }
}
