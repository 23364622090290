import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { ShippingPartyRoutes } from 'shipment-operations/constants';
import { EOrganizationMemberRole } from 'user-management/constants';
import { SkeletonSection } from 'app-wrapper/view/components/Skeleton/Skeleton.component';

import {
  Navigation,
  TabPane,
  Tabs,
  NavigationSkeleton,
} from './NavigationBar.styled';

interface NavigationBarComponentProps {
  openTab: (route: ShippingPartyRoutes) => void;
  activeTab: ShippingPartyRoutes
  orgRole?: EOrganizationMemberRole
  isLoading: boolean;
}

export const NavigationBarComponent: FC<NavigationBarComponentProps> = ({
  openTab,
  activeTab,
  orgRole,
  isLoading,
}) => {
  const { t } = useTranslation();

  const handleChange = useCallback((key: string) => {
    openTab(key as ShippingPartyRoutes);
  }, []);

  if (isLoading) {
    return (
      <NavigationSkeleton>
        <SkeletonSection
          width="350px"
          height="46px"
        />
      </NavigationSkeleton>
    );
  }

  return (
    <Navigation>
      <Tabs onChange={handleChange}>
        <Tabs.TabPane
          active={activeTab === ShippingPartyRoutes.HOUSE_BILL_OF_LADING}
          key={ShippingPartyRoutes.HOUSE_BILL_OF_LADING}
          tab={(
            <TabPane>
              {t('House Bill of Lading')}
            </TabPane>
          )}
        />

        {orgRole !== EOrganizationMemberRole.CUSTOMER ? (
          <Tabs.TabPane
            active={activeTab === ShippingPartyRoutes.MASTER_BILL_OF_LADING}
            key={ShippingPartyRoutes.MASTER_BILL_OF_LADING}
            tab={(
              <TabPane>
                {t('Master Bill of Lading')}
              </TabPane>
            )}
          />
        ) : null}

        <Tabs.TabPane
          active={activeTab === ShippingPartyRoutes.OTHER}
          key={ShippingPartyRoutes.OTHER}
          tab={(
            <TabPane>
              {t('Other')}
            </TabPane>
            )}
        />
      </Tabs>
    </Navigation>
  );
};
