import i18n from 'app-wrapper/i18n/i18n';

export enum MBLType {
  Draft = 'Draft',
  Final = 'Final',
}

export enum MBLReferenceType {
  BookingNumber = 'BookingNumber',
  TransactionReferenceNumber = 'TransactionReferenceNumber',
  ContractNumber = 'ContractNumber',
}

export enum MBLCommentType {
  TermsAndConditions = 'TermsAndConditions',
  BlClause = 'BlClause',
  General = 'General',
  BillOfLadingRemarks = 'BillOfLadingRemarks',
  RoutingInstructions = 'RoutingInstructions',
}

export enum MBLLocationType {
  PlaceOfReceipt = 'PlaceOfReceipt',
  PlaceOfDelivery = 'PlaceOfDelivery',
  PortOfDischarge = 'PortOfDischarge',
  PortOfLoading = 'PortOfLoading',
  BillOfLadingOrigin = 'BillOfLadingOrigin',
  FreightPaymentLocation = 'FreightPaymentLocation',
  BillOfLadingRelease = 'BillOfLadingRelease'
}

export enum ShippingInstructionsStatusEnum {
  SI_PREPARATION = 'SI_PREPARATION',
  SI_SUBMITTED = 'SI_SUBMITTED',
  SI_CONFIRMED = 'SI_CONFIRMED',
  SI_DECLINED = 'SI_DECLINED',
  SI_AMENDMENT_SUBMITTED = 'SI_AMENDMENT_SUBMITTED',
  SI_AMENDMENT_CONFIRMED = 'SI_AMENDMENT_CONFIRMED',
  SI_AMENDMENT_DECLINED = 'SI_AMENDMENT_DECLINED',
  SI_FAILED = 'SI_FAILED',
}

export enum ShippingInstructionsRoutes {
  MBL_SI = 'MBL_SI',
  HBL_DRAFT = 'HBL_DRAFT',
}

export enum MBLChargeIndicatorType {
  PREPAID = 'Prepaid',
  COLLECT = 'Collect',
}

export const MBLChargeIndicatorTypeNames = {
  [MBLChargeIndicatorType.PREPAID]: i18n.t('Prepaid'),
  [MBLChargeIndicatorType.COLLECT]: i18n.t('Collect'),
};
