import React, { FC, memo } from 'react';
import { useSelector } from 'react-redux';

import { R as AppR } from 'app-wrapper/repository';
import { R as userManagementR } from 'user-management/repository';

import { MainMenuComponent } from './MainMenu';

interface IMainMenu {
  isCollapsed: boolean
  isCustomer?: boolean
  isOrganizationActive?: boolean
  isOrganizationInDraft?: boolean
  isOrganizationInRejected: boolean
}

const MainMenu: FC<IMainMenu> = (props) => {
  const {
    isCollapsed,
    isCustomer,
    isOrganizationActive,
    isOrganizationInDraft,
    isOrganizationInRejected,
  } = props;

  const tasksStats = useSelector(AppR.selectors.overview.getTasksStats);
  const contractsPermissions = useSelector(userManagementR.selectors.moduleFunctionalityPermissions.getContractsPermissions);

  return (
    <MainMenuComponent
      isCollapsed={isCollapsed}
      isCustomer={isCustomer}
      isOrganizationActive={isOrganizationActive}
      isOrganizationInDraft={isOrganizationInDraft}
      isOrganizationInRejected={isOrganizationInRejected}
      contractsPermission={contractsPermissions?.contractsPermission}
      tasksStats={tasksStats}
    />
  );
};

const MainMenuCached = memo(MainMenu);

export { MainMenuCached as MainMenu };
