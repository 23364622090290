import AntdDivider from 'antd/es/divider';
import styled from 'styled-components';

export const ShipmentContainerFormContent = styled.div.attrs({
  'data-class': 'ShipmentContainerFormContent',
})`
  width: 100%;
`;

export const ShipmentContainerFormNumbersDataSection = styled.div.attrs({
  'data-class': 'ShipmentContainerFormNumbersDataSection',
})`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  gap: 8px;
  width: 100%;
`;

export const Divider = styled(AntdDivider)`
  margin: 0;
`;
