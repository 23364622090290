import styled from 'styled-components';
import { EditOutlined } from '@ant-design/icons';

import { Button as BaseButton } from 'app-wrapper/view/components';

export const Button = styled(BaseButton)`
  height: 22px;
  padding: 0 7px;
`;

export const Icon = styled(EditOutlined)`
  font-size: 14px;
`;
