import React, { FC, ReactNode } from 'react';

import {
  SubPageContent,
  SubPageHeader,
  SubPageHeaderContainer,
  SubPageHeaderTitle,
  Wrapper,
} from 'app-wrapper/view/guideline';
import {
  UserProfileContent,
  UserProfileContentHeader,
  UserProfileHorizontalSeparator,
  UserProfileRightBox,
  UserProfileLeftBox,
  UserProfileVerticalSeparator,
} from './UserProfileScaffold.styled';

interface IUserProfileScaffoldComponentProps {
  title: ReactNode
  header: ReactNode
  leftContentBox: ReactNode
  rightContentBox: ReactNode
}

export const UserProfileScaffold: FC<IUserProfileScaffoldComponentProps> = ({
  title,
  header,
  leftContentBox,
  rightContentBox,
}) => (
  <Wrapper>
    <SubPageHeader>
      <SubPageHeaderContainer>
        <SubPageHeaderTitle>
          {title}
        </SubPageHeaderTitle>
      </SubPageHeaderContainer>
    </SubPageHeader>
    <SubPageContent>
      <UserProfileContent>
        <UserProfileContentHeader>
          {header}
        </UserProfileContentHeader>
        <UserProfileHorizontalSeparator />
        <UserProfileLeftBox>
          {leftContentBox}
        </UserProfileLeftBox>
        <UserProfileVerticalSeparator />
        <UserProfileRightBox>
          {rightContentBox}
        </UserProfileRightBox>
      </UserProfileContent>
    </SubPageContent>
  </Wrapper>
);
