import React, { memo } from 'react';

import { ECarrierSCAC } from 'monetary/constants';
import { CarrierLogo } from 'app-wrapper/view/guideline';
import { CompanyLogoContainer } from './CompanyLogo.styled';

interface InfoCardComponentProps {
  carrierSCAC: ECarrierSCAC
}

export const CompanyLogoComponent = memo(({ carrierSCAC }: InfoCardComponentProps) => (
  <CompanyLogoContainer>
    <CarrierLogo carrierSCAC={carrierSCAC} />
  </CompanyLogoContainer>
));
