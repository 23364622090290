import React, {
  FC, useMemo,
} from 'react';
import { useSelector } from 'react-redux';

import { addPrefix } from 'app-wrapper/utils';

import { UC } from 'shipment-operations/controllers';
import { R } from 'shipment-operations/repository';

import { ShipmentDocumentsAdditionalUploadComponent } from './ShipmentDocumentsAdditionalUpload.component';

const type = 'MSDS';

interface IShipmentDocumentsAdditionalUpload {
  idIndex: number
  keyIndex: string
  shipmentId?: number | string
  authToken: string
  documentType?: string | null
  disabledBtn: boolean
  hasError?: boolean
  onUploadDone?: (value: string, name: string) => void
  isBlurDocumentFile: (index: number) => void
  isFocusDocumentFile: (index: number) => void
}

const ShipmentDocumentsAdditionalUploadContainer: FC<IShipmentDocumentsAdditionalUpload> = (props) => {
  const {
    idIndex,
    keyIndex,
    shipmentId = '',
    authToken,
    documentType,
    disabledBtn,
    hasError,
    onUploadDone,
    isBlurDocumentFile,
    isFocusDocumentFile,
  } = props;
  const shipmentDocument = useSelector(R.selectors.shipmentDocuments.getShipmentDocument);
  const permissions = useSelector(R.selectors.shipment.getDocumentsPermissions);

  const postUrl = useMemo(() => addPrefix(`/shipment-service/api/v1/shipments/${shipmentId}/documents?type=${documentType || type}`), [documentType, shipmentId]);

  return (
    <ShipmentDocumentsAdditionalUploadComponent
      idIndex={idIndex}
      name="file"
      authToken={authToken}
      postUrl={postUrl}
      msdsDocument={shipmentDocument.fileList[keyIndex]}
      validateMsdsDocument={() => {}}
      previewMsdsDocument={UC.shipmentDocumentAdditional.downloadFileDocument}
      onRemove={UC.shipmentDocumentAdditional.onRemoveFileHandler(shipmentId, idIndex, keyIndex)}
      onUploadDone={onUploadDone}
      onChangeRun={UC.shipmentDocumentAdditional.setUpdateFileListHandler(keyIndex)}
      onChangeRemoved={UC.shipmentDocumentAdditional.setUpdateRemoveFileListByIdHandler(idIndex, keyIndex)}
      onChangeDone={UC.shipmentDocumentAdditional.setUpdateFileListHandler(keyIndex)}
      isBlurDocumentFile={isBlurDocumentFile}
      isFocusDocumentFile={isFocusDocumentFile}
      disabledBtn={disabledBtn}
      hasError={hasError}
      additionalDocumentsPermissions={permissions.additionalDocuments}
    />
  );
};

export { ShipmentDocumentsAdditionalUploadContainer as ShipmentDocumentsAdditionalUpload };
