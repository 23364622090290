import React from 'react';
import { useSelector } from 'react-redux';
import isNumber from 'lodash/fp/isNumber';

import { R } from 'monetary/repository';

import { RFQFreeTimeComponent } from './RFQFreeTime.component';

const RFQFreeTimeContainer = ({ idIndex }: { idIndex?: number }) => {
  const allQuotas = useSelector(R.selectors.freightQuote.FreightQuotePrintSelectors.allQuotes);

  return (
    <RFQFreeTimeComponent
      matchedQuota={(allQuotas && isNumber(idIndex)) ? allQuotas[idIndex] : undefined}
    />
  );
};

export { RFQFreeTimeContainer as RFQFreeTime };
