import styled, { css } from 'styled-components';

export const textColorFirst = css`
  color: ${({ theme }) => theme.themesColors.characterBrandingTitle85};
`;

export const divFlex = css`
  display: flex;
  position: relative;
  ${textColorFirst}
`;

// Div

export const ContainerComponentItemDiv = styled.div.attrs({
  'data-class': 'ContainerComponentItemDiv',
})`
  ${divFlex}
  flex-direction: column;
  background-color: ${({ theme }) => theme.themesColors.neutralBranding1};
  padding: 14px 0px;
  padding-bottom: 8px;
  border: 1px solid ${({ theme }) => theme.themesColors.neutralBranding3};
  border-radius: 4px;
  margin-bottom: 16px;
`;

export const HistoryQuotesComponentQuotesItemDiv = styled(ContainerComponentItemDiv).attrs({
  'data-class-second': 'HistoryQuotesComponentQuotesItemDiv',
})`
  padding: 6px 0px;
  padding-bottom: 0;
`;

export const HistoryQuotesComponentQuotesItemInfiniteScrollSpin = styled.div`
  ${divFlex}
  align-items: center;
  justify-content: center;
`;

// Content Header

export const HistoryQuotesComponentQuotesItemHeader = styled.div.attrs({
  'data-class': 'HistoryQuotesComponentQuotesItemHeader',
})`
${divFlex}
width: 100%;
border-bottom: 1px solid ${({ theme }) => theme.themesColors.neutralBranding3};
padding-left: 16px;
padding-right: 16px;
padding-bottom: 6px;
`;

export const HistoryQuotesComponentQuotesItemHeaderLeft = styled.div`
${divFlex}
`;

export const HistoryQuotesComponentQuotesItemHeaderLeftIcon = styled.div`
${divFlex}
align-items: center;
svg {
  color: ${({ theme }) => theme.themesColors.neutralBranding6};
}
`;

const HistoryQuotesComponentQuotesItemHeaderLeftTextCss = css`
color: ${({ theme }) => theme.themesColors.neutralBranding10};
font-weight: 500;
font-size: 16px;
`;

export const HistoryQuotesComponentQuotesItemHeaderLeftText = styled.div`
${divFlex}
align-items: center;
${HistoryQuotesComponentQuotesItemHeaderLeftTextCss}
padding-left: 8px;
padding-right: 6px;
`;

export const HistoryQuotesComponentQuotesItemHeaderLeftTextLink = styled.div`
${divFlex}
cursor: pointer;
a {
  ${HistoryQuotesComponentQuotesItemHeaderLeftTextCss}
}
`;

export const HistoryQuotesComponentQuotesItemHeaderLeftTextIcon = styled.div`
${divFlex}
align-items: center;
`;

export const HistoryQuotesComponentQuotesItemHeaderRight = styled.div`
${divFlex}
margin-left: auto;
align-items: center;
`;

export const HistoryQuotesComponentQuotesItemHeaderRightText = styled.div`
${divFlex}
font-weight: 400;
font-size: 14px;
padding-right: 8px;
`;

export const HistoryQuotesComponentQuotesItemHeaderRightIcon = styled.div.attrs((props: {
  colorStatus: string,
}) => ({
  colorStatus: props.colorStatus,
}))`
${divFlex}
width: 6px;
height: 6px;
border-radius: 50%;
background-color: ${(props) => (props.colorStatus)};
align-items: center;
`;

// Content

export const HistoryQuotesComponentQuotesItemContent = styled.div.attrs({
  'data-class': 'HistoryQuotesComponentQuotesItemContent',
})`
${divFlex}
width: 100%;
padding-top: 8px;
padding-bottom: 7px;
padding-left: 16px;
padding-right: 16px;
`;

export const HistoryQuotesComponentQuotesItemContentLeft = styled.div`
${divFlex}
`;

const ItemContentLeftDiv = css`
flex-direction: column;
padding-left: 8px;
padding-right: 15px;
`;

const ItemContentLeftDivIcon = css`
align-items: center;
`;

const ItemContentLeftOriginTitle = css`
color: ${({ theme }) => theme.themesColors.secondaryDot45};
font-weight: 400;
font-size: 12px;
line-height: 19px;
`;

const ItemContentLeftOriginFirstText = css`
color: ${({ theme }) => theme.themesColors.neutralBranding10};
font-weight: 400;
font-size: 14px;
line-height: 19px;
`;

const ItemContentLeftOriginSecondText = css`
color: ${({ theme }) => theme.themesColors.neutralBranding10};
font-weight: 400;
font-size: 14px;
line-height: 19px;
`;

const ItemContentLeftOriginSecondTextPre = css`
color: ${({ theme }) => theme.themesColors.secondaryDot45};
font-weight: 400;
font-size: 14px;
line-height: 19px;
padding-right: 6px;
`;

export const HistoryQuotesComponentQuotesItemContentLeftOrigin = styled.div.attrs({
  'data-class': 'HistoryQuotesComponentQuotesItemContentLeftOrigin',
})`
${divFlex}
`;

export const HistoryQuotesComponentQuotesItemContentLeftOriginContent = styled.div`
${divFlex}
${ItemContentLeftDiv}
`;

export const HistoryQuotesComponentQuotesItemContentLeftOriginIcon = styled.div`
${divFlex}
${ItemContentLeftDivIcon}
`;

export const HistoryQuotesComponentQuotesItemContentLeftOriginTitle = styled.div`
${divFlex}
${ItemContentLeftOriginTitle}
`;

export const HistoryQuotesComponentQuotesItemContentLeftOriginFirstText = styled.div`
${divFlex}
${ItemContentLeftOriginFirstText}
`;

export const HistoryQuotesComponentQuotesItemContentLeftOriginSecondTextPre = styled.div`
${divFlex}
${ItemContentLeftOriginSecondTextPre}
`;

export const HistoryQuotesComponentQuotesItemContentLeftOriginSecondText = styled.div`
${divFlex}
${ItemContentLeftOriginSecondText}
`;

export const HistoryQuotesComponentQuotesItemContentLeftSeparate = styled.div`
${divFlex}
align-items: center;
`;

export const HistoryQuotesComponentQuotesItemContentLeftDestination = styled.div.attrs({
  'data-class': 'HistoryQuotesComponentQuotesItemContentLeftDestination',
})`
${divFlex}
padding-left: 15px;
`;

export const HistoryQuotesComponentQuotesItemContentLeftDestinationContent = styled.div`
${divFlex}
${ItemContentLeftDiv}
`;

export const HistoryQuotesComponentQuotesItemContentLeftDestinationIcon = styled.div`
${divFlex}
${ItemContentLeftDivIcon}
`;

export const HistoryQuotesComponentQuotesItemContentLeftDestinationTitle = styled.div`
${divFlex}
${ItemContentLeftOriginTitle}
`;

export const HistoryQuotesComponentQuotesItemContentLeftDestinationFirstText = styled.div`
${divFlex}
${ItemContentLeftOriginFirstText}
`;

export const HistoryQuotesComponentQuotesItemContentLeftDestinationSecondTextPre = styled.div`
${divFlex}
${ItemContentLeftOriginSecondTextPre}
`;

export const HistoryQuotesComponentQuotesItemContentLeftDestinationSecondText = styled.div`
${divFlex}
${ItemContentLeftOriginSecondText}
`;

export const HistoryQuotesComponentQuotesItemContentRight = styled.div`
${divFlex}
margin-left: auto;
flex-direction: column;
justify-content: space-between;
`;

export const HistoryQuotesComponentQuotesItemContentRightFirst = styled.div`
${divFlex}
margin-left: auto;
`;

export const HistoryQuotesComponentQuotesItemContentRightFirstIcon = styled.div`
${divFlex}
svg {
  color: ${({ theme }) => theme.themesColors.characterBrandingDisabled025};
}
align-items: center;
margin-right: 8px;
`;

export const HistoryQuotesComponentQuotesItemContentRightFirstText = styled.div`
${divFlex}
color: ${({ theme }) => theme.themesColors.neutralBranding10};
font-weight: 400;
font-size: 14px;
line-height: 19px;
`;

export const HistoryQuotesComponentQuotesItemContentRightSecond = styled.div`
${divFlex}
margin-left: auto;
`;

export const HistoryQuotesComponentQuotesItemContentRightSecondItem = styled.div`
${divFlex}
color: ${({ theme }) => theme.themesColors.neutralBranding10};
background-color: ${({ theme }) => theme.themesColors.neutralBranding3};
font-weight: 400;
font-size: 12px;
border: 1px solid ${({ theme }) => theme.themesColors.neutralBranding4};
border-radius: 2px;
margin-right: 4px;
padding: 2px 8px;
align-items: center;
`;

export const HistoryQuotesComponentQuotesItemContentRightSecondItems = styled.div`
${divFlex}
color: ${({ theme }) => theme.themesColors.secondaryDot45};
font-weight: 400;
font-size: 12px;
background-color: ${({ theme }) => theme.themesColors.neutralBranding1};
border: 1px solid ${({ theme }) => theme.themesColors.neutralBranding4};
border-radius: 2px;
padding: 2px 8px;
align-items: center;
`;

// Content Footer

export const HistoryQuotesComponentQuotesItemFooter = styled.div.attrs({
  'data-class': 'HistoryQuotesComponentQuotesItemFooter',
})`
${divFlex}
width: 100%;
border-top: 1px solid ${({ theme }) => theme.themesColors.neutralBranding3};
padding-left: 16px;
padding-right: 16px;
padding-top: 8px;
align-items: center;
`;

export const HistoryQuotesComponentQuotesItemFooterIcon = styled.div`
${divFlex}
svg {
  color: ${({ theme }) => theme.themesColors.characterBrandingDisabled025};
}
`;

export const HistoryQuotesComponentQuotesItemFooterFirstText = styled.div`
${divFlex}
color: ${({ theme }) => theme.themesColors.secondaryDot45};
font-weight: 400;
font-size: 12px;
padding-left: 11px;
padding-right: 8px;
`;

export const HistoryQuotesComponentQuotesItemFooterSecondText = styled.div`
${divFlex}
color: ${({ theme }) => theme.themesColors.neutralBranding10};
font-weight: 400;
font-size: 12px;
padding-right: 8px;
`;

export const HistoryQuotesComponentQuotesItemFooterInfoIcon = styled.div`
${divFlex}
svg {
  color: ${({ theme }) => theme.themesColors.secondaryDot45};
}
`;
