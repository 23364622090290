import React, { FC, useCallback } from 'react';
import { OrganizationFieldsErrorsFieldDTM } from 'user-management/models/dtm';

import {
  OrganizationStateSelectComponent,
} from 'user-management/view/components/OrganizationStateSelect';
import { UC } from 'user-management/controllers';
import { useSelector } from 'react-redux';
import { R } from 'user-management/repository';
import { useTranslation } from 'react-i18next';

export const EditProfileOrganizationStateContainer: FC = () => {
  const organization = useSelector(R.selectors.userOrganizationData.getUserOrganizationUpdate);
  const organizationFieldsErrors = useSelector(R.selectors.userOrganizationData.organizationFieldsErrors);

  const getHasErrorField = useCallback(
    (filed?: OrganizationFieldsErrorsFieldDTM) => !!(filed?.isVisited && filed?.message),
    [],
  );

  const { t } = useTranslation();

  return (
    <OrganizationStateSelectComponent
      data-class="EditProfileOrganizationSelectCountryState"
      value={organization?.address?.state}
      hasError={getHasErrorField(organizationFieldsErrors?.addressState)}
      showSearch
      allowClear={!!organization?.address.country}
      placeholder={t('Please select')}
      country={organization?.address?.country}
      onChange={UC.organizationProfile.onChangeUpdateAddressState}
      onClear={UC.organizationProfile.onClearUpdateAddressState}
      onBlur={UC.organizationProfile.onBlurUpdateAddressState}
    />
  );
};
