import React from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';
import { R as monetaryR } from 'monetary/repository';

import { NRADocumentPageComponent } from 'shipment-operations/view/pages/ShipmentDocumentsAll/components/DocumentsSection/components/ActionMenu/components/PrintNRAAgreementRFQ/components/NRADocumentPage/NRADocumentPage.component';

const NRAPageBetweenAdminAndAdminContainer = () => {
  const quotaId = useSelector(monetaryR.selectors.freightQuote.FreightQuotePrintSelectors.printedQuotaId);
  const validity = useSelector(monetaryR.selectors.freightQuote.FreightQuotePrintSelectors.getValidity);
  const shipment = useSelector(R.selectors.shipment.getShipment);
  const customerRelatedAdmin = useSelector(R.selectors.shipmentDocumentsAll.getCustomerRelatedAdminPublicInfo);
  const contractOwnerRelatedAdmin = useSelector(R.selectors.shipmentDocumentsAll.getContractOwnerRelatedAdminPublicInfo);

  return (
    <NRADocumentPageComponent
      quotaId={quotaId}
      validityFrom={validity.from}
      validityTo={validity.to}
      customerAddress={customerRelatedAdmin ? `${customerRelatedAdmin.name}${customerRelatedAdmin?.address ? `, ${customerRelatedAdmin?.address.getAddressLine()}` : ''}` : ''}
      isNRABetweenContractOwnerAndAdmin
      createdByName={customerRelatedAdmin?.primaryContact ? customerRelatedAdmin?.primaryContact.getFullName() : ''}
      rightSignaturePerson={contractOwnerRelatedAdmin?.primaryContact ? contractOwnerRelatedAdmin?.primaryContact.getFullName() : ''}
      createdAt={shipment?.createdAt ? shipment?.createdAt.getDateDMMMYYYYHHmm() : ''}
    />
  );
};

export { NRAPageBetweenAdminAndAdminContainer as NRAPageBetweenAdminAndAdmin };
