import React, { FC } from 'react';

import { ShipmentChargesPermissionsDtm } from 'shipment-operations/models/dtm';

import { useTable } from './useTable';
import { FooterStyled } from './TotalFooter.styled';

interface ITotalFooterProps {
  dataSource: {
    apTotalCost: number
    arTotalCost: number
    profit: number
    totalCost: number
  }
  permissions: ShipmentChargesPermissionsDtm
  mode: string
  isPercentage: boolean
}

const TotalFooterComponent: FC<ITotalFooterProps> = ({
  dataSource, permissions, mode, isPercentage,
}) => {
  const { columns } = useTable(permissions, mode, isPercentage);

  return (
    <FooterStyled
      pagination={false}
      showHeader={false}
      columns={columns}
      dataSource={[dataSource]}
      data-name="totalTable"
    />
  );
};

export { TotalFooterComponent };
