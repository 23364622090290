import React, { FC, memo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { AccountDepartmentDTM } from 'user-management/models/dtm';

import { EditPanSvg } from 'app-wrapper/view/icons';

import {
  SubPageContent,
  SubPageHeader,
  SubPageHeaderContainer,
  SubPageHeaderTitle,
  Wrapper,
} from 'app-wrapper/view/guideline';
import { AccountDprtmByIdTable } from './components';
import { AccountDprtmByIdDivHeaderArrowIcon } from './AccountDepartmentsById.styled';
import { AccountDrawer } from '../Account/components';
import { AccountDprtmByIdContentTableHeaderEditBtn } from './components/AccountDprtmByIdTable/AccountDprtmByIdTable.styled';

interface IAccountDepartmentsByIdComponentProps {
  department?: AccountDepartmentDTM
  isLoading?: boolean
  isAdmin?: boolean
  runComponent: (departmentId?: string) => void
  onClickRemoveDepartment: (memberId?: string) => void;
  onClickEditAddress: () => void;
  onClickArrow: () => void
}

const AccountDepartmentsByIdComponent: FC<IAccountDepartmentsByIdComponentProps> = (props) => {
  const {
    department,
    isLoading,
    isAdmin,
    runComponent,
    onClickRemoveDepartment,
    onClickEditAddress,
    onClickArrow,
  } = props;
  const { t } = useTranslation();
  const { departmentId } = useParams<'departmentId'>();

  useEffect(() => {
    runComponent(departmentId);
  }, []);

  return (
    <Wrapper>
      <SubPageHeader>
        <SubPageHeaderContainer>
          <SubPageHeaderTitle>
            <AccountDprtmByIdDivHeaderArrowIcon onClick={onClickArrow} />
            {department?.getTypeName()}
          </SubPageHeaderTitle>
          {isAdmin && (
            <AccountDprtmByIdContentTableHeaderEditBtn
              isRight
              onClick={onClickEditAddress}
            >
              <EditPanSvg />
              <span>{t('Edit Department')}</span>
            </AccountDprtmByIdContentTableHeaderEditBtn>
          )}
        </SubPageHeaderContainer>
      </SubPageHeader>
      <SubPageContent>
        <AccountDprtmByIdTable
          department={department}
          isLoad={isLoading}
          isAdmin={isAdmin}
          nextPageTasks={() => ''}
          onStart={() => ''}
          onClickRemoveDepartment={onClickRemoveDepartment}
        />
        <AccountDrawer />
      </SubPageContent>
    </Wrapper>
  );
};

const AccountDepartmentsByIdComponentCached = memo(AccountDepartmentsByIdComponent);

export { AccountDepartmentsByIdComponentCached as AccountDepartmentsByIdComponent };
