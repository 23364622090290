import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'antd/es/skeleton';

import { ShipmentPreviewDTM } from 'shipment-operations/models/dtm';
import { CustomModal } from 'shipment-operations/view/components';
import { ShipmentInfo } from 'app-wrapper/view/drawers/components';

import {
  Footer,
  NRAAgreement,
  SkypaceTermsAndConditions,
} from './components';

interface BookingDrawerComponentProps {
  isOpen: boolean;
  onClose: () => void;
  isLoading: boolean;
  isNRAVisible: boolean;
  shipment?: ShipmentPreviewDTM;
}

export const AgreementsDrawerComponent: FC<BookingDrawerComponentProps> = ({
  isOpen,
  onClose,
  isLoading,
  isNRAVisible,
  shipment,
}) => {
  const { t } = useTranslation();

  return isOpen ? (
    <CustomModal
      title={t('Terms & Conditions')}
      onClose={onClose}
      contentStyles={{
        left: 'unset',
        width: '1137px',
      }}
      childrenWrapperStyles={{
        position: 'relative',
        height: 'calc(100vh - 56px)',
        overflow: 'hidden',
      }}
    >
      {isLoading ? (
        <Skeleton />
      ) : (
        <>
          <ShipmentInfo shipment={shipment} />

          {isNRAVisible ? <NRAAgreement /> : null}

          <SkypaceTermsAndConditions />
        </>
      )}

      <Footer />
    </CustomModal>
  ) : null;
};
