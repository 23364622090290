import React, { FC, memo } from 'react';
import { useSelector } from 'react-redux';

import { R } from 'user-management/repository';
import { UC } from 'user-management/controllers';

import { AddressesAndContactsEditAddressComponent } from './AddressesAndContactsEditAddress.component';

const AddressesAndContactsEditAddressContainer: FC = () => {
  const contactBookUpdate = useSelector(R.selectors.contactBook.getContactBookUpdate);

  const PrimaryById = contactBookUpdate.companyList?.[0].customId || '';
  const addressesIds = contactBookUpdate.companyList?.[0].addressesIds || [];

  const companyListAddresses = useSelector(R.selectors.contactBook.getContactBookUpdateCompanyListAddressesByIds(addressesIds));

  const getContactBookDefaultCompanyPrimaryStateAddressById = useSelector(R.selectors.contactBook.getContactBookDefaultCompanyPrimaryStateAddressById(companyListAddresses?.[0]?.customId || ''));

  return (
    <AddressesAndContactsEditAddressComponent
      companyListStateIndex={PrimaryById}
      isDisabledCancelBtn={false}
      isDisabledSaveBtn={false}
      isDisabledRemoveBtn={!!getContactBookDefaultCompanyPrimaryStateAddressById?.[0]?.isPrimary}
      onCancelClick={UC.organizationProfile.closeAddressesAndContactsAddAddressDrawer}
      onRemoveClick={UC.contactBook.onRemoveAddressesAndContacts}
      onSaveClick={UC.contactBook.onEditSaveAddressesAndContactsAddressById}
      onChangeAddressIsPrimary={UC.contactBook.onChangeUpdateAACIsPrimaryByIndex(addressesIds?.[0])}
      companyListAddresses={companyListAddresses}
    />
  );
};

const AddressesAndContactsEditAddressContainerCached = memo(AddressesAndContactsEditAddressContainer);

export { AddressesAndContactsEditAddressContainerCached as AddressesAndContactsEditAddressPage };
