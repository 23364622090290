import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';
import { UC } from 'shipment-operations/controllers';

import { VolumeInputComponent } from './VolumeInput.component';

interface VolumeInputContainerProps {
  cargoId: number;
}

const VolumeInputContainer: FC<VolumeInputContainerProps> = ({ cargoId }) => {
  const { volume = '' } = useSelector(R.selectors.bookingWizard.getCargoById(cargoId));

  return (
    <VolumeInputComponent
      cargoId={cargoId}
      volume={volume}
      touchCargoField={UC.bookingWizard.touchCargoField}
      setVolume={UC.bookingWizard.setVolume}
    />
  );
};

export { VolumeInputContainer as VolumeInput };
