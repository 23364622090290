import React from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';
import { UC } from 'shipment-operations/controllers';

import { CarrierReferenceNumberComponent } from './CarrierReferenceNumber.component';

const CarrierReferenceNumberContainer = () => {
  const carrierReferenceNumber = useSelector(R.selectors.manualBookingWizard.getCarrierReferenceNumber);
  const isDisabled = useSelector(R.selectors.manualBookingWizard.getIsCarrierReferenceDisabled);
  const { carrierReferenceNumber: error } = useSelector(R.selectors.manualBookingWizard.getErrors);

  return (
    <CarrierReferenceNumberComponent
      referenceNumber={carrierReferenceNumber}
      setReferenceNumber={UC.manualBookingWizard.setCarrierReferenceNumber}
      error={!!error}
      disabled={isDisabled}
    />
  );
};

export { CarrierReferenceNumberContainer as CarrierReferenceNumber };
