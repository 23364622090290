import i18n from 'i18next';

import { EOrganizationIndustry } from './OrganizationIndustry.enum';

export const OrganizationIndustryNames = {
  [EOrganizationIndustry.AEROSPACE]: i18n.t('Aerospace'),
  [EOrganizationIndustry.AGRICULTURE_FORESTRY]: i18n.t('Agriculture & Forestry'),
  [EOrganizationIndustry.AUTOMOTIVE]: i18n.t('Automotive'),
  [EOrganizationIndustry.CHEMICALS]: i18n.t('Chemicals'),
  [EOrganizationIndustry.CONSTRUCTION_BUILDING_MATERIALS]: i18n.t('Construction & Building Materials'),
  [EOrganizationIndustry.CONSUMER_GOODS]: i18n.t('Consumer Goods'),
  [EOrganizationIndustry.DEFENSE]: i18n.t('Defense'),
  [EOrganizationIndustry.EDUCATION_TRAINING]: i18n.t('Education & Training'),
  [EOrganizationIndustry.ELECTRONICS_TECHNOLOGY]: i18n.t('Electronics & Technology'),
  [EOrganizationIndustry.ENERGY_UTILITIES]: i18n.t('Energy & Utilities'),
  [EOrganizationIndustry.ENVIRONMENTAL_WASTE_SERVICES]: i18n.t('Environmental & Waste Services'),
  [EOrganizationIndustry.FINANCIAL_SERVICES]: i18n.t('Financial Services'),
  [EOrganizationIndustry.FOOD_BEVERAGE]: i18n.t('Food & Beverage'),
  [EOrganizationIndustry.GENERAL_TRADE]: i18n.t('General Trade'),
  [EOrganizationIndustry.GOVERNMENT]: i18n.t('Government'),
  [EOrganizationIndustry.HEALTHCARE_PHARMACEUTICALS]: i18n.t('Healthcare & Pharmaceuticals'),
  [EOrganizationIndustry.INDUSTRIAL_GOODS_MACHINERY]: i18n.t('Industrial Goods & Machinery'),
  [EOrganizationIndustry.LEGAL_PROFESSIONAL_SERVICES]: i18n.t('Legal & Professional Services'),
  [EOrganizationIndustry.MARITIME_SHIPPING]: i18n.t('Maritime & Shipping'),
  [EOrganizationIndustry.MINING_METALS]: i18n.t('Mining & Metals'),
  [EOrganizationIndustry.PAPER_PACKAGING]: i18n.t('Paper & Packaging'),
  [EOrganizationIndustry.PUBLISHING_MEDIA]: i18n.t('Publishing & Media'),
  [EOrganizationIndustry.REAL_ESTATE]: i18n.t('Real Estate'),
  [EOrganizationIndustry.RECREATIONAL_GOODS]: i18n.t('Recreational Goods/Toys & Games'),
  [EOrganizationIndustry.RESEARCH_DEVELOPMENT]: i18n.t('Research & Development'),
  [EOrganizationIndustry.RETAIL]: i18n.t('Retail'),
  [EOrganizationIndustry.SPORTS_LEISURE]: i18n.t('Sports & Leisure'),
  [EOrganizationIndustry.TECHNOLOGY_HARDWARE_SOFTWARE]: i18n.t('Technology - Hardware / Software'),
  [EOrganizationIndustry.TELECOMMUNICATIONS]: i18n.t('Telecommunications'),
  [EOrganizationIndustry.TEXTILES_APPAREL]: i18n.t('Textiles & Apparel'),
  [EOrganizationIndustry.TRANSPORTATION_LOGISTICS]: i18n.t('Transportation & Logistics'),
  [EOrganizationIndustry.TRAVEL_TOURISM]: i18n.t('Travel & Tourism'),
  [EOrganizationIndustry.OTHER]: i18n.t('Other'),
};
