import HomeOutlined from '@ant-design/icons/HomeOutlined';
import BorderlessTableOutlined from '@ant-design/icons/BorderlessTableOutlined';
import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { OrganizationDTM } from 'user-management/models/dtm';
import { NoDataLineItem } from 'user-management/view/components';
import { EMembershipType, MembershipTypeNames } from 'user-management/constants';

import {
  CIFinancialAndLegalData,
  CIFinancialAndLegalHeader,
  CIFinancialAndLegalIconBG,
  CIFinancialAndLegalItem,
  CIFinancialAndLegalRow,
  CIFinancialAndLegalTitle,
  CIFinancialAndLegalValue,
  CIFinancialAndLegalWrapper,
} from './CIFinancialAndLegal.styled';

interface ICIFinancialAndLegalComponentProps {
  organization?: OrganizationDTM
}

export const CIFinancialAndLegalComponent: FC<ICIFinancialAndLegalComponentProps> = ({
  organization,
}) => {
  const { t } = useTranslation();
  const wca = useMemo(() => (
    organization?.memberships?.find((item) => (item.type === EMembershipType.WCA_ID))
  ), [organization]);
  const dnb = useMemo(() => (
    organization?.memberships?.find((item) => (item.type === EMembershipType.DNB_ID))
  ), [organization]);

  return (
    <CIFinancialAndLegalWrapper>
      <CIFinancialAndLegalHeader>{t('Financial & Legal Information')}</CIFinancialAndLegalHeader>
      <CIFinancialAndLegalRow>
        <CIFinancialAndLegalItem>
          <CIFinancialAndLegalIconBG>
            <HomeOutlined />
          </CIFinancialAndLegalIconBG>
          <CIFinancialAndLegalData>
            <CIFinancialAndLegalTitle>{t('Tax ID')}</CIFinancialAndLegalTitle>
            <CIFinancialAndLegalValue>{organization?.taxId || <NoDataLineItem />}</CIFinancialAndLegalValue>
          </CIFinancialAndLegalData>
        </CIFinancialAndLegalItem>
        <CIFinancialAndLegalItem>
          <CIFinancialAndLegalIconBG>
            <BorderlessTableOutlined />
          </CIFinancialAndLegalIconBG>
          <CIFinancialAndLegalData>
            <CIFinancialAndLegalTitle>{t('EIN Number')}</CIFinancialAndLegalTitle>
            <CIFinancialAndLegalValue>{organization?.businessRegistrationNumber.getValidValue() || <NoDataLineItem />}</CIFinancialAndLegalValue>
          </CIFinancialAndLegalData>
        </CIFinancialAndLegalItem>
      </CIFinancialAndLegalRow>
      <CIFinancialAndLegalRow>
        <CIFinancialAndLegalItem>
          <CIFinancialAndLegalIconBG>
            <BorderlessTableOutlined />
          </CIFinancialAndLegalIconBG>
          <CIFinancialAndLegalData>
            <CIFinancialAndLegalTitle>{MembershipTypeNames[EMembershipType.WCA_ID]}</CIFinancialAndLegalTitle>
            <CIFinancialAndLegalValue>{wca?.getValidValue() || <NoDataLineItem />}</CIFinancialAndLegalValue>
          </CIFinancialAndLegalData>
        </CIFinancialAndLegalItem>
        <CIFinancialAndLegalItem>
          <CIFinancialAndLegalIconBG>
            <BorderlessTableOutlined />
          </CIFinancialAndLegalIconBG>
          <CIFinancialAndLegalData>
            <CIFinancialAndLegalTitle>{MembershipTypeNames[EMembershipType.DNB_ID]}</CIFinancialAndLegalTitle>
            <CIFinancialAndLegalValue>{dnb?.getValidValue() || <NoDataLineItem />}</CIFinancialAndLegalValue>
          </CIFinancialAndLegalData>
        </CIFinancialAndLegalItem>
      </CIFinancialAndLegalRow>
    </CIFinancialAndLegalWrapper>
  );
};
