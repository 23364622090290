import styled from 'styled-components';

export const FooterWrapper = styled.div`
  width: 100%;
  background-color: white;
  position: absolute;
  bottom: 0;
  left: 0;
  box-shadow: inset 0 1px 0 #F0F0F0;
  height: 52px;
  padding: 10px 16px;
  display: flex;
  align-items: end;
  justify-content: end;
`;
