import styled from 'styled-components';
import Typography from 'antd/es/typography';
import Table from 'antd/es/table';

import { colors } from 'app-wrapper/view/themes/themesColors';
import { divFlex } from 'app-wrapper/view/themes/mixin';

export const OverviewContainersWrap = styled.div.attrs({
  'data-class': 'OverviewContainersWrap',
})`
  ${divFlex}
  padding: 16px;
  flex-direction: column;
  background-color: ${({ theme }) => theme.themesColors.neutralBranding1};
  border: 1px solid ${colors.purple15};
`;

export const OverviewContainersTitle = styled(Typography)`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
`;

export const OverviewContainersTable = styled(Table)`
  .ant-table-thead > tr > th {
    background: white;
  }

  .ant-table-thead > tr > th:first-of-type {
    padding-left: 0px;
  }

  .ant-table-tbody > tr > td:first-of-type {
    padding-left: 0px;
  }

  .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    display: none;
  }

  .ant-table-thead .ant-table-cell {
    color: #73819B;
    font-size: 12px;
    font-weight: 400;
  }

  tr.ant-table-expanded-row > td, tr.ant-table-expanded-row:hover > td {
    background-color: ${({ theme }) => theme.themesColors.neutralBranding1}
  }
  
  tr.ant-table-placeholder > td {
    border-bottom: 0;
    padding-bottom: 0px;
  }
`;

export const OverviewContainersTableCell = styled(Typography)`
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
`;
