import React from 'react';
import { observer } from 'mobx-react-lite';

import { useMobxStore } from 'app-wrapper/mobxStores';

import { UC } from 'app-wrapper/controllers';

import { CreateContractDrawerComponent } from './CreateContractDrawer.component';

const CreateContractDrawerContainer = observer(() => {
  const { contracts } = useMobxStore();

  return (
    <CreateContractDrawerComponent
      isLoading={contracts.state.isDrawerLoading}
      onClose={UC.drawer.closeDrawer}
      onAccept={UC.contracts.createContract}
      isAbleToAccept={contracts.isAbleToCreateContract}
    />
  );
});

export { CreateContractDrawerContainer as CreateContractDrawer };
