import React, { FC, ReactNode } from 'react';

import { SkeletonSection } from 'app-wrapper/view/components/Skeleton/Skeleton.component';

import {
  ShipmentDetailsFormsFooter,
  ShipmentDetailsFormsScroll,
  ShipmentDetailsFormsWrapper,
} from './ShipmentDetailsForms.styled';

interface IShipmentDetailsFormProps {
  footer?: ReactNode
  isLoading?: boolean
}

export const ShipmentDetailsForms: FC<IShipmentDetailsFormProps> = ({
  footer,
  isLoading = false,
  children,
}) => (
  isLoading ? <SkeletonSection /> : (
    <ShipmentDetailsFormsWrapper>
      <>
        <ShipmentDetailsFormsScroll>
          {children}
        </ShipmentDetailsFormsScroll>
        {footer && (
        <ShipmentDetailsFormsFooter>
          {footer}
        </ShipmentDetailsFormsFooter>
        )}
      </>
    </ShipmentDetailsFormsWrapper>
  )
);
