import React from 'react';
import { useSelector } from 'react-redux';

import { UC } from 'shipment-operations/controllers';
import { R } from 'shipment-operations/repository';
import { ShipmentTransportationAccessorials } from './ShipmentTransportationAccessorials.component';

const ShipmentTransportationAccessorialsContainer = () => {
  const isLoading = useSelector(R.selectors.shipmentAccessorial.getIsLoading);
  const isError = useSelector(R.selectors.shipmentAccessorial.getError);
  const accessorialData = useSelector(R.selectors.shipmentAccessorial.getAccessorial);
  const penaltiesData = useSelector(R.selectors.shipmentAccessorial.getPenalties);

  const successCreation = useSelector(R.selectors.shipmentActiveCharge.getActiveChargeSuccessCreation);
  const isLoadingDelete = useSelector(R.selectors.shipmentActiveCharge.getIsLoadingDelete);
  const successDelete = useSelector(R.selectors.shipmentActiveCharge.getIsDeleteSuccess);
  const permissions = useSelector(R.selectors.shipment.getShipmentChargesPermissions);

  return (
    <ShipmentTransportationAccessorials
      fetchData={UC.shipmentAccessorial.loadData}
      isLoading={isLoading}
      accessorialData={accessorialData}
      penaltiesData={penaltiesData}
      successCreation={successCreation}
      closeDrawer={UC.shipmentActiveCharge.closeDrawer}
      openEditCharge={UC.shipmentActiveCharge.openEditCharge}
      setDeletedCharge={UC.shipmentActiveCharge.setDeletedCharge}
      onDelete={UC.shipmentActiveCharge.deleteCharge}
      isLoadingDelete={isLoadingDelete}
      permissions={permissions}
      isError={isError}
      successDelete={successDelete}
    />
  );
};

export { ShipmentTransportationAccessorialsContainer as ShipmentTransportationAccessorials };
