import React from 'react';
import { useSelector } from 'react-redux';

import { R } from 'user-management/repository';
import { UC } from 'user-management/controllers';

import { AdditionalInformationModalComponent } from './AdditionalInformationModal.component';

const AdditionalInformationModalContainer = () => {
  const isModalOpened = useSelector(R.selectors.customer.getIsAdditionalInformationModalOpened);
  const reviewNote = useSelector(R.selectors.customer.getAdditionalInfoNote);
  const isModalPending = useSelector(R.selectors.customer.getIsModalPending);

  return (
    <AdditionalInformationModalComponent
      reviewNote={reviewNote}
      setReviewNote={UC.customer.setAdditionalInfoNote}
      isOpen={isModalOpened}
      onClose={UC.customer.closeAdditionalInformationModal}
      onOk={UC.customer.requestAdditionalInformation}
      isPending={isModalPending}
    />
  );
};

export { AdditionalInformationModalContainer as AdditionalInformationModal };
