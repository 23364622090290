import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'antd/es/skeleton';

import { CustomModal } from 'shipment-operations/view/components';
import { ShipmentPreviewDTM, ContainerDTM } from 'shipment-operations/models/dtm';
import { Footer, MarkingContainers } from 'app-wrapper/view/drawers/components';
import { DateDtm } from 'app-wrapper/models/dtm';

import { RailBillingInformation } from './components';
import { Content } from './RailBillingDrawer.styled';

interface BookingDrawerComponentProps {
  init: (shipmentId: string) => Promise<void>
  onClose: () => void;
  shipmentId: string
  isLoading: boolean;
  isAbleToAccept: boolean;
  onAccept: () => void;
  shipment?: ShipmentPreviewDTM;
  containers: ContainerDTM[];
  markedContainers: string[];
  markAllContainers: () => void;
  markContainer: (id: string) => void;
  dueDate: DateDtm | null;
}

export const RailBillingDrawerComponent: FC<BookingDrawerComponentProps> = ({
  onClose,
  onAccept,
  init,
  isLoading,
  isAbleToAccept,
  shipment,
  shipmentId,
  containers,
  markedContainers,
  markAllContainers,
  markContainer,
  dueDate,
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    init(shipmentId);
  }, []);

  return (
    <CustomModal
      title={t('Verify Rail Billing Submission')}
      onClose={onClose}
      contentStyles={{
        left: 'unset',
        width: '1137px',
      }}
      childrenWrapperStyles={{
        position: 'relative',
        height: 'calc(100vh - 56px)',
        overflow: 'hidden',
      }}
    >
      {isLoading ? (
        <Skeleton />
      ) : (
        <Content>
          <RailBillingInformation dueDate={dueDate} />

          <MarkingContainers
            containers={containers}
            shipment={shipment}
            markAll={markAllContainers}
            markContainer={markContainer}
            markedContainers={markedContainers}
            markedLabel={t('Rail Billing Provided')}
          />
        </Content>
      )}

      <Footer
        onAccept={onAccept}
        closeDrawer={onClose}
        isLoading={isLoading}
        isAbleToAccept={isAbleToAccept}
        hideCancel
        okText={t('Complete Task')}
      />
    </CustomModal>
  );
};
