import styled from 'styled-components';

import AntdUpload from 'antd/es/upload';
import AntdButton from 'antd/es/button';
import { colors } from 'app-wrapper/view/themes/themesColors';

interface IButtonProps {
  error: number
}

export const Button = styled(AntdButton)<IButtonProps>`
  width: 100%;
  height: 32px;
  min-height: 32px;
  font-size: 12px;
  box-shadow: none;
  ${({ error }) => !!error && 'border-color: red !important;'};
  color: ${colors.gray61}
`;

export const Upload = styled(AntdUpload)`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 32px;
  margin-bottom: 20px;

  .ant-upload-list, .ant-upload-list-item {
    margin-top: 0px !important;
    line-height: 20px;
    font-size: 12px;
  }
`;
