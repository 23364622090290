import styled from 'styled-components';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';

import { Button as ButtonCommon } from 'app-wrapper/view/components';

export const Button = styled(ButtonCommon)`
  height: 32px;
  border-radius: 2px;
`;

export const PlusIcon = styled(PlusOutlined)`
  svg {
    width: 15px;
    height: 15px;
    color: #314258;
  }
`;

export const MinusIon = styled(MinusOutlined)`
  svg {
    width: 15px;
    height: 15px;
    color: #314258;
  }
`;
