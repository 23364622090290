import {
  IsString,
  IsDefined,
  ValidateNested,
  IsNumber,
  IsBoolean,
  IsEnum,
  IsOptional,
} from 'class-validator';
import { Type } from 'class-transformer';
import { BaseDTM } from 'proto/BaseDTM';

import { ShipmentBookingStatusEnum } from 'shipment-operations/constants';

export interface IManualConfirmationDocumentDTM {
  id: number;
  name: string;
  type: string;
}

export class ManualConfirmationDocumentDTM extends BaseDTM<IManualConfirmationDocumentDTM> {
  @IsDefined()
  @IsNumber()
  id: number;

  @IsDefined()
  @IsString()
  name: string;

  @IsDefined()
  @IsString()
  type: string;
}

export interface IBookingDetailsDTM {
  status: ShipmentBookingStatusEnum;
  oceanBookingId: string;
  carrierReferenceNumber: string;
  manualConfirmationDocument: IManualConfirmationDocumentDTM | null;
  requestTheSameWithShipment: boolean;
}

export class BookingDetailsDTM extends BaseDTM<IBookingDetailsDTM> {
  @IsDefined()
  @IsEnum(ShipmentBookingStatusEnum)
  status: ShipmentBookingStatusEnum;

  @IsDefined()
  @IsString()
  oceanBookingId: string;

  @IsDefined()
  @IsString()
  carrierReferenceNumber: string;

  @IsOptional()
  @ValidateNested()
  @Type(() => ManualConfirmationDocumentDTM)
  manualConfirmationDocument: ManualConfirmationDocumentDTM | null;

  @IsDefined()
  @IsBoolean()
  requestTheSameWithShipment: boolean;
}
