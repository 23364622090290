import React, { FC } from 'react';
import Divider from 'antd/es/divider';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import { normalizationCost } from 'app-wrapper/utils';
import { useParams } from 'react-router-dom';
import { RECEIVABLES, PermissionAttributePolicy } from 'shipment-operations/constants';
import { SkeletonSection } from 'app-wrapper/view/components/Skeleton/Skeleton.component';
import {
  Wrapper,
  Card,
  SkeletonCard,
  FieldName,
  FieldValue,
  DateWrapper,
  PaymentDate,
  PostedDate,
  TotalTitle,
  TotalValue,
} from './Header.styled';

interface IHeaderComponentsProps {
  postedDate?: string
  reference?: string
  amount?: number
  isLoading: boolean
  debtorName?: string
  creditorName?: string
  permissions?: PermissionAttributePolicy
}

const timeFormat = 'D MMM YYYY';

const HeaderComponents: FC<IHeaderComponentsProps> = ({
  postedDate, reference, amount, isLoading,
  debtorName, creditorName, permissions,
}) => {
  const { t } = useTranslation();
  const { invoiceType } = useParams<'invoiceType'>();

  if (isLoading) {
    return (
      <Wrapper>
        <SkeletonCard>
          <SkeletonSection height="124px" />
        </SkeletonCard>

        <SkeletonCard>
          <SkeletonSection height="124px" />
        </SkeletonCard>

        <SkeletonCard>
          <SkeletonSection height="124px" />
        </SkeletonCard>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <Card>
        <FieldName>{invoiceType === RECEIVABLES ? t('payment.customer') : t('payment.vendor')}</FieldName>
        <FieldValue>{(invoiceType === RECEIVABLES ? debtorName : creditorName) || '-'}</FieldValue>
        <Divider style={{ margin: '2px 0' }} />
        <FieldName>{t('payment.bankAccountNumber')}</FieldName>
        <FieldValue>
          {permissions === 'WRITE' ? (
            '23 0112 3456 7801 1234 5678'
          ) : ('-')}
        </FieldValue>
      </Card>

      <Card>
        <DateWrapper>
          {invoiceType === RECEIVABLES && (
            <PaymentDate>
              <FieldName>{t('payment.paymentDate')}</FieldName>
              <FieldValue>-</FieldValue>
            </PaymentDate>
          )}
          <PostedDate>
            <FieldName>{invoiceType === RECEIVABLES ? t('payment.postedDate') : t('payment.paidDate')}</FieldName>
            <FieldValue>{moment(postedDate).format(timeFormat)}</FieldValue>
          </PostedDate>
        </DateWrapper>
        <Divider style={{ margin: '2px 0' }} />
        <FieldName>{invoiceType === RECEIVABLES ? t('payment.receivedReference') : t('payment.reference')}</FieldName>
        <FieldValue>{reference}</FieldValue>
      </Card>

      <Card>
        <TotalTitle>{invoiceType === RECEIVABLES ? t('payment.Total Received Amount') : t('payment.Total Paid Amount')}</TotalTitle>
        <TotalValue>$ {normalizationCost(amount || 0, { toFixed: 2, thousandthSeparatorComma: true })}</TotalValue>
      </Card>
    </Wrapper>
  );
};

export { HeaderComponents };
