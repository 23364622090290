import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';
import { UC } from 'shipment-operations/controllers';

import { IMOClassSelectComponent } from './IMOClassSelect.component';

interface IMOClassSelectContainerProps {
  cargoId: number;
}

const IMOClassSelectContainer: FC<IMOClassSelectContainerProps> = ({ cargoId }) => {
  const { imoClass, errors } = useSelector(R.selectors.bookingWizard.getCargoById(cargoId));
  const { imoClass: error } = errors;

  return (
    <IMOClassSelectComponent
      imoClass={imoClass}
      touchCargoField={UC.bookingWizard.touchCargoField}
      setImoClass={UC.bookingWizard.setImoClass}
      cargoId={cargoId}
      error={!!error}
    />
  );
};

export { IMOClassSelectContainer as IMOClassSelect };
