import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import isEmpty from 'lodash/fp/isEmpty';

import { ChargeViewDtm, IGroupedChargesDTM } from 'shipment-operations/models/dtm';
import { InnerContainer } from 'shipment-operations/view/components';
import { ContainersTable, TotalFooter } from 'shipment-operations/view/pages/ShipmentTransportationCharges/components';
import { TotalWrapper } from 'shipment-operations/view/pages/ShipmentTransportationCharges/ShipmentTransportationCharges.styled';

interface ITransportationComponentProps {
  containers: ChargeViewDtm[]
  innerData: IGroupedChargesDTM
  totalData: {
    apTotalCost: number
    arTotalCost: number
    profit: number
  }
}

const TransportationComponent: FC<ITransportationComponentProps> = ({ containers, innerData, totalData }) => {
  const { t } = useTranslation();

  if (isEmpty(containers)) {
    return null;
  }

  return (
    <>
      <InnerContainer>
        <ContainersTable dataSource={containers} innerTableData={innerData} subTitle={t('transportation')} />
      </InnerContainer>
      <TotalWrapper>
        <TotalFooter dataSource={[totalData]} />
      </TotalWrapper>
    </>
  );
};

export { TransportationComponent };
