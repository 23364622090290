import { apiWorker } from 'app-wrapper/repository/utilsServices';
import {
  TGetRateDownloadResponseContract,
  TPostRateUploadSuccessResponseContract,
} from 'monetary/models/contracts';
import { RateChangesDtm, RateDtm, RatesListDtm } from 'monetary/models/dtm';
import { ECarrierSCAC, ERateTypes } from 'monetary/constants';
import { DateDtm } from 'app-wrapper/models/dtm';

export class RateService {
  private baseUrl = 'rate-service/api/v1';

  private baseQuotaUrl = 'quota-service/api/v1';

  public getRates = () => {
    const oceanBaseRate = RateDtm.fromPlain({
      type: ERateTypes.OCEAN_BASE_RATE,
      templateLink: 'https://docs.google.com/spreadsheets/d/1GPLFnbhew32xK3Ygi06WLaSZqTRvXz1P07JVHzhYV8s/edit?usp=sharing',
    });
    const oceanSurchargesRate = RateDtm.fromPlain({
      type: ERateTypes.OCEAN_SURCHARGES,
      templateLink: 'https://docs.google.com/spreadsheets/d/1GcS81y0r19rtguMXBOm-q-imfAMiguevq8Jz7SFOQr0/edit?usp=sharing',
    });
    const serviceRatesRate = RateDtm.fromPlain({
      type: ERateTypes.SERVICE_RATES,
      templateLink: 'https://docs.google.com/spreadsheets/d/13giHjCwaTRzTxtY_pmkBU8VhkBeK8KLcmqKvvaSbWbQ/edit?usp=sharing',
    });
    const feesRate = RateDtm.fromPlain({
      type: ERateTypes.FEES,
      templateLink: 'https://docs.google.com/spreadsheets/d/1i-qFlrnc917iBWLchbq4r_FCXd773cBxXjBannqm67U/edit?usp=sharing',
    });
    const penaltiesAndFreeTimeRate = RateDtm.fromPlain({
      type: ERateTypes.PENALTIES_AND_FREE_TIME,
      templateLink: 'https://docs.google.com/spreadsheets/d/1WH6tgs9xAnWOwGQbxevQTW_9c8GC93HgdGbd3gWwbWk/edit?usp=sharing',
    });

    return RatesListDtm.fromPlain({
      [ERateTypes.OCEAN_BASE_RATE]: oceanBaseRate,
      [ERateTypes.OCEAN_SURCHARGES]: oceanSurchargesRate,
      [ERateTypes.SERVICE_RATES]: serviceRatesRate,
      [ERateTypes.FEES]: feesRate,
      [ERateTypes.PENALTIES_AND_FREE_TIME]: penaltiesAndFreeTimeRate,
    });
  }

  public uploadOceanRate = async (rateDocument: File) => {
    let result: RateChangesDtm | null = null;

    const formData = new FormData();
    formData.append('file', rateDocument);

    const response = await apiWorker.requestPost<TPostRateUploadSuccessResponseContract>(`${this.baseUrl}/ocean/rates/upload`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    result = RateChangesDtm.fromPlain({
      newRecordsNum: response.data.newRecordsNum,
      updatedRecordsNum: response.data.updatedRecordsNum,
      removedRecordsNum: response.data.removedRecordsNum,
    });

    return result;
  }

  public downloadOceanRate = async (
    effectiveDate: DateDtm,
    expirationDate: DateDtm,
    originLocation?: string,
    destinationLocation?: string,
    carrierCodes?: ECarrierSCAC[],
  ) => {
    const requestParams = `fromDate=${effectiveDate.getDateYYYYMMDD()}`
      + `&toDate=${expirationDate.getDateYYYYMMDD()}`
      + `${originLocation ? `&origin=${originLocation}` : ''}`
      + `${destinationLocation ? `&destination=${destinationLocation}` : ''}`
      + `&carriers=${carrierCodes ? carrierCodes.join(', ') : ''}`;
    try {
      const response = await apiWorker.requestGet<TGetRateDownloadResponseContract>(
        `${this.baseUrl}/ocean/rates/download?${requestParams}`, {
          responseType: 'blob',
        },
      );

      const fileName = `${effectiveDate.getDateYYYYMMDD()}_${expirationDate.getDateYYYYMMDD()}_`
        + `${originLocation || ''}_${destinationLocation || ''}_${carrierCodes ? carrierCodes.join(', ') : ''}.csv`;

      const url = window.URL.createObjectURL(response.data);

      await this.downloadFile(url, fileName);
    } catch (e) {
      throw new Error('Something went wrong');
    }
  }

  public uploadOceanSurchargesRate = async (rateDocument: File) => {
    let result: RateChangesDtm | null = null;

    const formData = new FormData();
    formData.append('file', rateDocument);

    const response = await apiWorker.requestPost(`${this.baseUrl}/ocean/surcharges/upload`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    result = RateChangesDtm.fromPlain({
      newRecordsNum: response.data.newRecordsNum,
      updatedRecordsNum: response.data.updatedRecordsNum,
      removedRecordsNum: response.data.removedRecordsNum,
    });

    return result;
  }

  public downloadOceanSurchargesRate = async (
    date: DateDtm,
    originLocation: string,
    destinationLocation: string,
    carrierCodes: ECarrierSCAC[],
  ) => {
    const requestParams = `time=${date.getDateAsMomentWithOffset().toISOString()}`
      + `&origin=${originLocation}`
      + `&destination=${destinationLocation}`
      + `&carriers=${carrierCodes ? carrierCodes.join(', ') : ''}`;
    try {
      const response = await apiWorker.requestGet<TGetRateDownloadResponseContract>(
        `${this.baseUrl}/ocean/surcharges/download?${requestParams}`, {
          responseType: 'blob',
        },
      );

      const fileName = `${date.getDateYYYYMMDD()}_`
        + `${originLocation}_${destinationLocation}_${carrierCodes ? carrierCodes.join(', ') : ''}.csv`;

      const url = window.URL.createObjectURL(response.data);

      await this.downloadFile(url, fileName);
    } catch (e) {
      throw new Error('Something went wrong');
    }
  }

  public uploadServiceRate = async (rateDocument: File) => {
    let result: RateChangesDtm | null = null;

    const formData = new FormData();
    formData.append('file', rateDocument);

    const response = await apiWorker.requestPost(`${this.baseUrl}/services/rates/upload`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    result = RateChangesDtm.fromPlain({
      newRecordsNum: response.data.newRecordsNum,
      updatedRecordsNum: response.data.updatedRecordsNum,
      removedRecordsNum: response.data.removedRecordsNum,
    });

    return result;
  }

  public downloadServiceRate = async (
    date: DateDtm,
    carrierCodes: ECarrierSCAC[],
  ) => {
    const requestParams = `time=${date.getDateAsMomentWithOffset().toISOString()}`
      + `&carriers=${carrierCodes ? carrierCodes.join(', ') : ''}`;
    try {
      const response = await apiWorker.requestGet<TGetRateDownloadResponseContract>(
        `${this.baseUrl}/services/rates/download?${requestParams}`, {
          responseType: 'blob',
        },
      );

      const fileName = `${date.getDateYYYYMMDD()}_${carrierCodes ? carrierCodes.join(', ') : ''}.xlsx`;

      const url = window.URL.createObjectURL(response.data);

      await this.downloadFile(url, fileName);
    } catch (e) {
      throw new Error('Something went wrong');
    }
  }

  public uploadFeesRate = async (rateDocument: File) => {
    let result: RateChangesDtm | null = null;

    const formData = new FormData();
    formData.append('file', rateDocument);

    const response = await apiWorker.requestPost(`${this.baseUrl}/fees/rates/upload`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    result = RateChangesDtm.fromPlain({
      newRecordsNum: response.data.newRecordsNum,
      updatedRecordsNum: response.data.updatedRecordsNum,
      removedRecordsNum: response.data.removedRecordsNum,
    });

    return result;
  }

  public downloadFeesRate = async (
    date: DateDtm,
    carrierCodes: ECarrierSCAC[],
  ) => {
    const requestParams = `time=${date.getDateAsMomentWithOffset().toISOString()}`
      + `&carriers=${carrierCodes ? carrierCodes.join(', ') : ''}`;
    try {
      const response = await apiWorker.requestGet<TGetRateDownloadResponseContract>(
        `${this.baseUrl}/fees/rates/download?${requestParams}`, {
          responseType: 'blob',
        },
      );

      const fileName = `${date.getDateYYYYMMDD()}_${carrierCodes ? carrierCodes.join(', ') : ''}.xlsx`;

      const url = window.URL.createObjectURL(response.data);

      await this.downloadFile(url, fileName);
    } catch (e) {
      throw new Error('Something went wrong');
    }
  }

  public uploadPenaltiesRate = async (rateDocument: File) => {
    let result: RateChangesDtm | null = null;

    const formData = new FormData();
    formData.append('file', rateDocument);

    const response = await apiWorker.requestPost(`${this.baseUrl}/penalties/rates/upload`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    result = RateChangesDtm.fromPlain({
      newRecordsNum: response.data.newRecordsNum,
      updatedRecordsNum: response.data.updatedRecordsNum,
      removedRecordsNum: response.data.removedRecordsNum,
    });

    return result;
  }

  public downloadPenaltiesRate = async (
    date: DateDtm,
    carrierCodes: ECarrierSCAC[],
  ) => {
    const requestParams = `time=${date.getDateAsMomentWithOffset().toISOString()}`
      + `&carriers=${carrierCodes ? carrierCodes.join(', ') : ''}`;
    try {
      const response = await apiWorker.requestGet<TGetRateDownloadResponseContract>(
        `${this.baseUrl}/penalties/rates/download?${requestParams}`, {
          responseType: 'blob',
        },
      );

      const fileName = `${date.getDateYYYYMMDD()}_${carrierCodes ? carrierCodes.join(', ') : ''}.xlsx`;

      const url = window.URL.createObjectURL(response.data);

      await this.downloadFile(url, fileName);
    } catch (e) {
      throw new Error('Something went wrong');
    }
  }

  private downloadFile = async (url: string, name: string) => {
    const link = document.createElement('a');

    link.href = url;
    link.setAttribute('target', '_blank');
    link.download = name;

    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);
  }

  public clearCacheRates = async () => {
    await apiWorker.requestDelete(
      `${this.baseUrl}/cache`,
    );
  }

  public clearCacheQuotas = async () => {
    await apiWorker.requestDelete(
      `${this.baseQuotaUrl}/cache`,
    );
  }
}
