import { PayloadAction } from '@reduxjs/toolkit';

import { createSlice } from 'app-wrapper/createSlice';
import { EAccountDepartmentType } from 'user-management/constants';
import { ITeamsState } from 'user-management/models/states';
import {
  TeamDTM,
  OrganizationMemberDTM,
  OrganizationDTM,
  TeamMemberDTM,
} from 'user-management/models/dtm';

export const initialState: ITeamsState = {
  isLoading: true,
  teams: [],
  team: undefined,
  currentUser: undefined,
  isDrawerOpened: false,
  isDrawerInEditMode: false,
  isDrawerLoading: false,
  customers: [],
  members: [],
  organizationId: 0,
  tempCustomers: [],
  tempMembers: [],
  tempTeamName: '',
  customerTeam: null,
  isAddTeamDrawerLoading: false,
  isAddTeamDrawerOpened: false,
  teamToAddOrChangeId: undefined,
  accountTeam: undefined,
  accountManager: undefined,
  customerOrganization: undefined,
  tempAccountManager: undefined,
  tempAccountTeam: undefined,
  teamMembers: [],
  rolesToEmailsState: {
    [EAccountDepartmentType.BOOKING_OPS]: undefined,
    [EAccountDepartmentType.DOCUMENTATION_OPS_EXPORT]: undefined,
    [EAccountDepartmentType.DOCUMENTATION_OPS_IMPORT]: undefined,
    [EAccountDepartmentType.DRAYAGE_IMPORT]: undefined,
    [EAccountDepartmentType.DRAYAGE_EXPORT]: undefined,
    [EAccountDepartmentType.ACCOUNTING_PAYABLES]: undefined,
    [EAccountDepartmentType.ACCOUNTING_RECEIVABLES]: undefined,
    [EAccountDepartmentType.CUSTOMER_ONBOARDING]: undefined,
    [EAccountDepartmentType.ACCOUNT_MANAGEMENT]: undefined,
    [EAccountDepartmentType.PRICING]: undefined,
    [EAccountDepartmentType.RATE_MANAGEMENT]: undefined,
    [EAccountDepartmentType.SALES]: undefined,
  },
};

export const teamsStore = createSlice({
  name: 'teams',
  initialState,
  reducers: {
    setIsLoading: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      isLoading: payload,
    }),
    setIsDrawerOpened: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      isDrawerOpened: payload,
    }),
    setIsDrawerInEditMode: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      isDrawerInEditMode: payload,
    }),
    setIsDrawerLoading: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      isDrawerLoading: payload,
    }),
    setOrganizationId: (state, { payload }: PayloadAction<number>) => ({
      ...state,
      organizationId: payload,
    }),
    setTeams: (state, { payload }: PayloadAction<TeamDTM[]>) => ({
      ...state,
      teams: payload,
    }),
    setTeam: (state, { payload }: PayloadAction<TeamDTM | undefined>) => ({
      ...state,
      team: payload,
    }),
    setTempCustomers: (state, { payload }: PayloadAction<number[]>) => ({
      ...state,
      tempCustomers: payload,
    }),
    setTempTeamName: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      tempTeamName: payload,
    }),
    setTempMembers: (state, { payload }: PayloadAction<string[]>) => ({
      ...state,
      tempMembers: payload,
    }),
    setCurrentUser: (state, { payload }: PayloadAction<OrganizationMemberDTM | undefined>) => ({
      ...state,
      currentUser: payload,
    }),
    setCustomers: (state, { payload }: PayloadAction<OrganizationDTM[]>) => ({
      ...state,
      customers: payload,
    }),
    setMembers: (state, { payload }: PayloadAction<OrganizationMemberDTM[]>) => ({
      ...state,
      members: payload,
    }),
    setCustomerTeam: (state, { payload }: PayloadAction<TeamDTM | null>) => ({
      ...state,
      customerTeam: payload,
    }),
    setIsAddTeamDrawerOpened: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      isAddTeamDrawerOpened: payload,
    }),
    setTeamMembers: (state, { payload }: PayloadAction<TeamMemberDTM[]>) => ({
      ...state,
      teamMembers: payload,
    }),
    setRolesToEmailsStateItem: (state, { payload }: PayloadAction<{ email: string; role: EAccountDepartmentType }>) => {
      const { role, email } = payload;

      state.rolesToEmailsState[role] = email;
    },
    clearRolesToEmailsState: (state) => ({
      ...state,
      rolesToEmailsState: {
        [EAccountDepartmentType.BOOKING_OPS]: undefined,
        [EAccountDepartmentType.DOCUMENTATION_OPS_EXPORT]: undefined,
        [EAccountDepartmentType.DOCUMENTATION_OPS_IMPORT]: undefined,
        [EAccountDepartmentType.DRAYAGE_IMPORT]: undefined,
        [EAccountDepartmentType.DRAYAGE_EXPORT]: undefined,
        [EAccountDepartmentType.ACCOUNTING_PAYABLES]: undefined,
        [EAccountDepartmentType.ACCOUNTING_RECEIVABLES]: undefined,
        [EAccountDepartmentType.CUSTOMER_ONBOARDING]: undefined,
        [EAccountDepartmentType.ACCOUNT_MANAGEMENT]: undefined,
        [EAccountDepartmentType.PRICING]: undefined,
        [EAccountDepartmentType.RATE_MANAGEMENT]: undefined,
        [EAccountDepartmentType.SALES]: undefined,
      },
    }),
    setIsAddTeamDrawerLoading: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      isAddTeamDrawerLoading: payload,
    }),
    setTeamToAddOrChangeId: (state, { payload }: PayloadAction<number | undefined>) => ({
      ...state,
      teamToAddOrChangeId: payload,
    }),
    setAccountTeam: (state, { payload }: PayloadAction<TeamDTM | undefined>) => ({
      ...state,
      accountTeam: payload,
    }),
    setAccountManager: (state, { payload }: PayloadAction<OrganizationMemberDTM | undefined>) => ({
      ...state,
      accountManager: payload,
    }),
    setCustomerOrganization: (state, { payload }: PayloadAction<OrganizationDTM | undefined>) => ({
      ...state,
      customerOrganization: payload,
    }),
    setTempAccountManager: (state, { payload }: PayloadAction<string | undefined>) => ({
      ...state,
      tempAccountManager: payload,
    }),
    setTempAccountTeam: (state, { payload }: PayloadAction<number | undefined>) => ({
      ...state,
      tempAccountTeam: payload,
    }),
  },
});

export const teamsReducer = teamsStore.reducer;
export const teamsActions = teamsStore.actions;
