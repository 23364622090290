import React from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';
import { UC } from 'shipment-operations/controllers';
import { EditTransportPlanComponent } from './EditTransportPlan.component';

const EditTransportPlanContainer = ({ onClose }: { onClose: () => void }) => {
  const data = useSelector(R.selectors.shipmentTrackerRoutes.getPreparedLegs);
  const isLoadingUpdate = useSelector(R.selectors.shipmentTrackerRoutes.getIsLoadingUpdate);
  const isSuccess = useSelector(R.selectors.shipmentTrackerRoutes.getIsUpdateSuccess);
  const schedules = useSelector(R.selectors.shipmentTrackerRoutes.getEditableSchedules);
  const errorTerminal = useSelector(R.selectors.shipmentTrackerRoutes.getIsErrorTerminalCutOff);
  const voyageErrorIndexes = useSelector(R.selectors.shipmentTrackerRoutes.getVoyageErrorIndexes);
  const vesselErrorIndexes = useSelector(R.selectors.shipmentTrackerRoutes.getVesselErrorIndexes);
  const isDisabledUpdate = useSelector(R.selectors.shipmentTrackerRoutes.getIsDisableUpdate);
  const isChangedPortCutoff = useSelector(R.selectors.shipmentTrackerRoutes.getIsChangedPortCutoff);

  return (
    <EditTransportPlanComponent
      onSetLocation={UC.shipmentTracker.setLocation}
      onSetDate={UC.shipmentTracker.setDate}
      onSetMode={UC.shipmentTracker.setMode}
      onSetVoyage={UC.shipmentTracker.setVoyage}
      onSetVessel={UC.shipmentTracker.setVessel}
      onSearchLocation={UC.shipmentTracker.searchLocation}
      onUpdate={UC.shipmentTracker.updateTracker}
      onChangeSchedule={UC.shipmentTracker.setSchedule}
      originalData={data}
      onClose={onClose}
      isLoadingUpdate={isLoadingUpdate}
      isSuccess={isSuccess}
      schedules={schedules}
      errorTerminal={errorTerminal}
      voyageErrorIndexes={voyageErrorIndexes}
      vesselErrorIndexes={vesselErrorIndexes}
      isDisabledUpdate={isDisabledUpdate}
      isChangedPortCutoff={isChangedPortCutoff}
    />
  );
};

export { EditTransportPlanContainer as EditTransportPlan };
