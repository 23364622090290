import React, {
  FC,
  ChangeEvent,
  useCallback,
  useMemo,
} from 'react';

import { Input } from 'app-wrapper/view/components';
import { PermissionAttributePolicy } from 'shipment-operations/constants';
import { CargoDTM } from 'shipment-operations/models/dtm';

interface ICargoFormVolumeInputComponentProps {
  volume: CargoDTM['volume']
  touchField: (field: string) => void
  setVolume: (volume: CargoDTM['volume']) => void
  cargosAvailability?: PermissionAttributePolicy
}

export const CargoFormVolumeInputComponent: FC<ICargoFormVolumeInputComponentProps> = ({
  volume,
  touchField,
  setVolume,
  cargosAvailability,
}) => {
  const handleChangeVolume = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setVolume(event.target.value);
  }, []);

  const handleBlur = useCallback(() => {
    touchField('volume');
  }, []);

  const isDisabled = useMemo(() => cargosAvailability !== PermissionAttributePolicy.WRITE, [cargosAvailability]);

  return (
    <Input
      disabled={isDisabled}
      value={volume}
      onBlur={handleBlur}
      onChange={handleChangeVolume}
    />
  );
};
