import styled from 'styled-components';

interface IShippingPartyListTitleWrapperProps {
  $hasDescription: boolean,
}
export const ShippingPartyListTitleWrapper = styled.div<IShippingPartyListTitleWrapperProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${(props) => (!props.$hasDescription ? 'height: 43px;' : '')}
`;
