import styled from 'styled-components';

export const CheckboxFieldWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const CheckboxFieldText = styled.span`
  margin-left: 8px;
`;
