import { createSelector } from 'reselect';

import { RootState } from 'app-wrapper/store';
import { EOrganizationType, EReviewAction } from 'user-management/constants';
import { OrganizationPaymentMethod } from 'shipment-operations/constants';
import { OrganizationPaymentMethodDTM } from 'user-management/models/dtm';

const localState = (state: RootState) => state.customer;

const getLoading = createSelector(
  localState,
  (state) => state.isLoading,
);

const getIsUploadLoading = createSelector(
  localState,
  (state) => state.isUploadLoading,
);

const getDocumentsTypesMap = createSelector(
  localState,
  (state) => state.documentsTypeMap,
);

const getDocumentToUpload = createSelector(
  localState,
  (state) => state.documentToUpload,
);

const getDocumentToUploadType = createSelector(
  localState,
  (state) => state.documentToUploadType,
);

const getDocuments = createSelector(
  localState,
  (state) => state.documents,
);

const getDocumentById = (documentId: number) => createSelector(
  localState,
  getDocuments,
  (state, documents) => documents.find((document) => document.id === documentId),
);

const getIsOrganizationAdmin = createSelector(
  localState,
  (state) => state.isOrganizationAdmin,
);

const getOrganizationId = createSelector(
  localState,
  (state) => state.organizationId,
);

const getOrganization = createSelector(
  localState,
  (state) => state.organization,
);

const getIsRejectModalOpened = createSelector(
  localState,
  (state) => state.isRejectModalOpened,
);

const getRejectionNote = createSelector(
  localState,
  (state) => state.rejectionNote,
);

const getShouldRequireBLICDocument = createSelector(
  getOrganization,
  (organization) => Boolean(organization && (organization.type === EOrganizationType.NVOCC || organization.type === EOrganizationType.FF)),
);

const getIsAdditionalInformationModalOpened = createSelector(
  localState,
  (state) => state.isAdditionalModalOpened,
);

const getAdditionalInfoNote = createSelector(
  localState,
  (state) => state.additionalInfoNote,
);

const getIsModalPending = createSelector(
  localState,
  (state) => state.isModalPending,
);

const getHasRequestedAdditionalInfo = createSelector(
  getOrganization,
  (organization) => {
    if (!organization) {
      return false;
    }

    const { review } = organization;

    return review?.action === EReviewAction.REQUEST_ADDITIONAL_INFO;
  },
);

const getReviewNote = createSelector(
  getOrganization,
  (organization) => {
    if (!organization || !organization.review) {
      return '';
    }

    return organization.review.note;
  },
);

const getReviewAction = createSelector(
  getOrganization,
  (organization) => {
    if (!organization || !organization.review) {
      return undefined;
    }

    return organization.review.action;
  },
);

const getRejectReason = createSelector(
  localState,
  (state) => state.rejectReason,
);

const getTradeReference = createSelector(
  localState,
  (state) => state.tradeReference,
);

const getPaymentMethod = createSelector(
  localState,
  (state) => state.paymentMethod,
);

const getPaymentMethodForm = createSelector(
  localState,
  (state) => state.paymentMethodForm,
);

const getPaymentMethodFormReq = createSelector(
  localState,
  (state) => {
    if (state.paymentMethodForm?.type.toUpperCase() === OrganizationPaymentMethod.PREPAYMENT) {
      return OrganizationPaymentMethodDTM.fromPlain({
        id: state.paymentMethodForm.id,
        type: state.paymentMethodForm.getTypeReq(),
      });
    }

    return OrganizationPaymentMethodDTM.fromPlain({
      ...OrganizationPaymentMethodDTM.createEmpty(),
      ...state.paymentMethodForm,
      type: state?.paymentMethodForm?.getTypeReq() || '',
    });
  },
);

const getWasTradeReferenceOnceSubmitted = createSelector(
  localState,
  (state) => state.wasTradeReferenceOnceSubmitted,
);

const getWasPaymentMethodOnceSubmitted = createSelector(
  localState,
  (state) => state.wasPaymentMethodOnceSubmitted,
);

const getShouldValidatePaymentMethod = createSelector(
  getPaymentMethodForm,
  getWasPaymentMethodOnceSubmitted,
  (paymentMethod, wasSubmittedOnce) => {
    const creditLimit = paymentMethod?.creditLimit || 0;
    const creditTerm = paymentMethod?.creditTerm || 0;
    const type = paymentMethod?.type || '';

    if (type?.toUpperCase() === OrganizationPaymentMethod.PREPAYMENT) {
      return !!type;
    }

    return !!(type || creditTerm || creditLimit) && wasSubmittedOnce;
  },
);

const getShouldValidateTradeReference = createSelector(
  getTradeReference,
  getWasTradeReferenceOnceSubmitted,
  (tradeReference, wasSubmittedOnce) => {
    const {
      companyName,
      firstName,
      lastName,
      email,
      phone,
    } = tradeReference;

    return !!(companyName || firstName || lastName || email || phone) && wasSubmittedOnce;
  },
);

const getIsTradeReferencePending = createSelector(
  localState,
  (state) => state.isTradeReferencePending,
);

const getIsTradeReferenceEmpty = createSelector(
  getTradeReference,
  (tradeReference) => !tradeReference.companyName.length,
);

export const customerSelectors = {
  getLoading,
  getIsUploadLoading,
  getDocumentsTypesMap,
  getDocumentToUpload,
  getDocumentToUploadType,
  getDocuments,
  getDocumentById,
  getIsOrganizationAdmin,
  getOrganizationId,
  getOrganization,
  getIsRejectModalOpened,
  getRejectionNote,
  getShouldRequireBLICDocument,
  getIsAdditionalInformationModalOpened,
  getAdditionalInfoNote,
  getIsModalPending,
  getHasRequestedAdditionalInfo,
  getReviewNote,
  getReviewAction,
  getRejectReason,
  getTradeReference,
  getPaymentMethod,
  getPaymentMethodForm,
  getPaymentMethodFormReq,
  getWasTradeReferenceOnceSubmitted,
  getWasPaymentMethodOnceSubmitted,
  getShouldValidatePaymentMethod,
  getShouldValidateTradeReference,
  getIsTradeReferencePending,
  getIsTradeReferenceEmpty,
};
