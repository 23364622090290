import * as yup from 'yup';
import i18n from 'i18next';

yup.addMethod(yup.string, 'einFormat', function isValidEin(translation?: string) {
  return this.matches(/^[0-9]{2}-[0-9]{7}([a-zA-Z0-9]{2})?$/, {
    message: translation || i18n.t('Validations.string.einFormat'),
    excludeEmptyString: true,
  });
});

declare module 'yup' {
  interface StringSchema {
    einFormat(translation?: string): StringSchema;
  }
}
