import i18n from 'i18next';
import { EOrganizationMemberRole } from './OrganizationMemberRole.enum';

export const OrganizationMemberRoleNames = {
  [EOrganizationMemberRole.admin]: i18n.t('Administrator'),
  [EOrganizationMemberRole.member]: i18n.t('Regular Member'),
  [EOrganizationMemberRole.CUSTOMER]: i18n.t('Customer Member'),
  [EOrganizationMemberRole.PARTNER]: i18n.t('Partner Member'),
};

export const ROLES_OPTIONS = [
  {
    value: 'ADMIN',
    label: i18n.t('admin'),
  },
  {
    value: 'MEMBER',
    label: i18n.t('regularMember'),
  },
];
