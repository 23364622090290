import styled from 'styled-components';
import Typography from 'antd/es/typography';
import AntTag from 'antd/es/tag';

import { divFlex } from 'app-wrapper/view/themes/mixin';

export const SBLTransportInformation = styled('div').attrs({
  'data-class': 'SBLTransportInformation',
})`
  ${divFlex}
  flex-direction: column;
`;

export const SBLTransportTitle = styled(Typography)`
  width: 100%;
  margin-bottom: 12px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
`;

export const SBLTransportRow = styled('div')`
  ${divFlex}
  flex-direction: row;
  justify-content: space-between;
`;

export const SBLTransportItem = styled('div')`
  ${divFlex}
  width: 50%;
  flex-direction: column;
`;

export const SBLTransportItemTitle = styled(Typography)`
  font-size: 12px;
  line-height: 20px;
  color: ${({ theme }) => theme.themesColors.secondaryDot45};
`;

export const SBLTransportItemSubTitle = styled(Typography)`
  font-size: 14px;
  line-height: 22px;
`;

export const Tag = styled(AntTag)`
  width: fit-content;
  background-color: #F4F5F8;
  border: 1px solid #EAEEF8;
  color: #73819B;
  font-weight: 400;
  font-size: 11px;
  line-height: 18px;
`;
