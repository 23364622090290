import React, { FC } from 'react';

import { UC } from 'shipment-operations/controllers';

import { ShipmentLocationsComponent } from './ShipmentLocations.component';

const ShipmentLocationsContainer: FC = () => (
  <ShipmentLocationsComponent
    init={UC.shipmentTransportationLocation.firstDataUpload}
    cleanup={UC.shipmentTransportationLocation.clearDataState}
  />
);

export { ShipmentLocationsContainer as ShipmentLocations };
