import React from 'react';

import { useAppSelector } from 'app-wrapper/hooks';
import { R } from 'shipment-operations/repository';

import { CardsWrapperComponent } from './CardsWrapper.component';

const CardsWrapper = () => {
  const creditNote = useAppSelector(R.selectors.creditNote.getCreditNote);
  return (
    <CardsWrapperComponent creditNote={creditNote} />
  );
};

export { CardsWrapper };
