import React from 'react';

import { UC } from 'shipment-operations/controllers';

import { NotesComponent } from './Notes.component';

const NotesContainer = () => (
  <NotesComponent
    onInputChange={UC.shipmentBillingInvoice.setNotes}
  />
);

export { NotesContainer as Notes };
