import {
  IsEnum, IsString, IsDefined, IsNumber, IsOptional, ValidateNested, IsBoolean,
  IsArray,
} from 'class-validator';
import { Type } from 'class-transformer';

import {
  ENotificationCriticality, ENotificationDomain, ENotificationStatus, REQUEST_STATUS,
} from 'app-wrapper/constants';
import { BaseDTM } from 'proto/BaseDTM';
import { DateDtm } from './Date';
import {
  AppFiltersCheckboxDTM,
  AppFiltersCheckboxGroupDTM,
  AppFiltersCheckboxTreeDTM,
  IAppFiltersCheckboxDTM,
  IAppFiltersCheckboxTreeDTM,
} from './freightFilters.dtm';

export interface INotificationTaskContentMetadataDTM {
  taskType?: string;
  shipmentId?: string;
  shipmentName?: string;
}

export class NotificationTaskContentMetadataDTM extends BaseDTM<INotificationTaskContentMetadataDTM> {
  @IsOptional()
  @IsString()
  taskType?: string;

  @IsOptional()
  @IsString()
  shipmentId?: string;

  @IsOptional()
  @IsString()
  shipmentName?: string;
}

export interface INotificationTaskContentActionsDTM {
  type?: string;
  id?: number;
  title?: string;
  link?: string;
}

export class NotificationTaskContentActionsDTM extends BaseDTM<INotificationTaskContentActionsDTM> {
  @IsOptional()
  @IsString()
  type?: string;

  @IsOptional()
  @IsNumber()
  id?: number;

  @IsOptional()
  @IsString()
  title?: string;

  @IsOptional()
  @IsString()
  link?: string;
}

export interface INotificationTaskContentTargetAudienceDTM {
  type?: string;
  id?: number;
  organizationIds?: number[];
  organizationId?: number;
}

export class NotificationTaskContentTargetAudienceDTM extends BaseDTM<INotificationTaskContentTargetAudienceDTM> {
  @IsOptional()
  @IsString()
  type?: string;

  @IsOptional()
  @IsNumber()
  id?: number;

  @IsOptional()
  @IsArray()
  organizationIds?: number[];

  @IsOptional()
  @IsNumber()
  organizationId?: number;
}

export interface INotificationTaskContentObjectReferenceDTM {
  id?: number;
  shipmentId?: number;
  rateRequestId?: number;
  organizationId?: number;
  type?: string;
}

export class NotificationTaskContentObjectReferenceDTM extends BaseDTM<NotificationTaskContentObjectReferenceDTM> {
  @IsOptional()
  @IsNumber()
  id?: number;

  @IsOptional()
  @IsNumber()
  shipmentId?: number;

  @IsOptional()
  @IsNumber()
  rateRequestId?: number;

  @IsOptional()
  @IsNumber()
  organizationId?: number;

  @IsOptional()
  @IsString()
  type?: string;
}

export interface INotificationTaskContentAssigneeDTM {
  type?: string;
  sub?: string;
  enabled?: boolean;
  email?: string;
  phone?: string;
  firstName?: string;
  lastName?: string;
}

export class NotificationTaskContentAssigneeDTM extends BaseDTM<INotificationTaskContentAssigneeDTM> {
  @IsOptional()
  @IsString()
  type?: string;

  @IsOptional()
  @IsString()
  sub?: string;

  @IsOptional()
  @IsBoolean()
  enabled?: boolean;

  @IsOptional()
  @IsString()
  email?: string;

  @IsOptional()
  @IsString()
  phone?: string;

  @IsOptional()
  @IsString()
  firstName?: string;

  @IsOptional()
  @IsString()
  lastName?: string;

  getFullName = () => {
    const firstName = this.firstName || '';
    const lastName = this.lastName || '';
    const isNameSet = this.firstName && this.lastName;

    return isNameSet ? `${firstName} ${lastName}` : '';
  }

  getFirstLetterOfName = () => (this.firstName?.length ? this.firstName[0].toUpperCase() : '');
}

export interface INotificationTaskContentViewedDTM {
  id: number
  viewedAt: string
  viewedBy: string
}

export class NotificationTaskContentViewedDTM extends BaseDTM<INotificationTaskContentViewedDTM> {
  @IsNumber()
  id: number;

  @IsString()
  viewedAt: string;

  @IsString()
  viewedBy: string;
}
export interface INotificationTaskContentDTM {
  id?: string;
  customId?: string;
  createdAt?: DateDtm;
  completedAt?: DateDtm;
  createdBy?: string;
  title?: string;
  description?: string;
  criticality?: ENotificationCriticality;
  status?: ENotificationStatus;
  domain?: ENotificationDomain;
  dueDate?: DateDtm;
  assignee?: INotificationTaskContentAssigneeDTM;
  assigneeOrganizationId?: number;
  assigneeOrganizationName?: string;
  assigneeUsers?: INotificationTaskContentAssigneeDTM[];
  organizationId?: number;
  view?: boolean;
  viewId?: string;
  objectReference?: INotificationTaskContentObjectReferenceDTM;
  targetAudience?: INotificationTaskContentTargetAudienceDTM;
  actions?: INotificationTaskContentActionsDTM[];
  metadata?: INotificationTaskContentMetadataDTM;
}

export class NotificationTaskContentDTM extends BaseDTM<INotificationTaskContentDTM> {
  @IsOptional()
  @IsString()
  id?: string;

  @IsOptional()
  @IsString()
  customId?: string;

  @IsOptional()
  @ValidateNested()
  @Type(() => DateDtm)
  createdAt?: DateDtm;

  @IsOptional()
  @ValidateNested()
  @Type(() => DateDtm)
  completedAt?: DateDtm;

  @IsOptional()
  @IsString()
  createdBy?: string;

  @IsOptional()
  @IsString()
  title?: string;

  @IsOptional()
  @IsNumber()
  assigneeOrganizationId?: number;

  @IsOptional()
  @IsString()
  assigneeOrganizationName?: string;

  @IsOptional()
  @IsString()
  description?: string;

  @IsOptional()
  @IsEnum(ENotificationCriticality)
  criticality?: ENotificationCriticality;

  @IsOptional()
  @IsEnum(ENotificationStatus)
  status?: ENotificationStatus;

  @IsOptional()
  @IsEnum(ENotificationDomain)
  domain?: ENotificationDomain;

  @IsOptional()
  @ValidateNested()
  @Type(() => DateDtm)
  dueDate?: DateDtm;

  @IsOptional()
  @ValidateNested()
  @Type(() => NotificationTaskContentAssigneeDTM)
  assignee?: NotificationTaskContentAssigneeDTM;

  @IsOptional()
  @ValidateNested({ each: true })
  @Type(() => NotificationTaskContentAssigneeDTM)
  assigneeUsers?: NotificationTaskContentAssigneeDTM[];

  @IsOptional()
  @IsNumber()
  organizationId?: number;

  @IsOptional()
  @IsBoolean()
  view?: boolean;

  @IsOptional()
  @IsString()
  viewId?: string;

  @IsOptional()
  @ValidateNested()
  @Type(() => NotificationTaskContentObjectReferenceDTM)
  objectReference?: NotificationTaskContentObjectReferenceDTM;

  @IsOptional()
  @ValidateNested()
  @Type(() => NotificationTaskContentTargetAudienceDTM)
  targetAudience?: NotificationTaskContentTargetAudienceDTM;

  @IsOptional()
  @ValidateNested({ each: true })
  @Type(() => NotificationTaskContentActionsDTM)
  actions?: NotificationTaskContentActionsDTM[];

  @IsOptional()
  @ValidateNested()
  @Type(() => NotificationTaskContentMetadataDTM)
  metadata?: NotificationTaskContentMetadataDTM;
}

interface INotificationTaskFilterDatesDTM {
  earliestDate?: string
  latestDate?: string
}

export class NotificationTaskFilterDatesDTM extends BaseDTM<INotificationTaskFilterDatesDTM> {
  @IsOptional()
  @IsString()
  earliestDate?: string;

  @IsOptional()
  @IsString()
  latestDate?: string;
}

export interface INotificationTaskFilterReceivedDatesDTM {
  dates?: INotificationTaskFilterDatesDTM
  disableReset?: boolean
  isUpdate?: boolean
}

export class NotificationTaskFilterReceivedDatesDTM extends BaseDTM<INotificationTaskFilterReceivedDatesDTM> {
  @IsOptional()
  @ValidateNested()
  @Type(() => NotificationTaskFilterDatesDTM)
  dates?: NotificationTaskFilterDatesDTM;

  @IsOptional()
  @IsBoolean()
  disableReset?: boolean;

  @IsOptional()
  @IsBoolean()
  isUpdate?: boolean;
}

export interface INotificationViewedFiltersDTM {
  isOnlyUnread?: boolean;
  isDisabledOnlyUnread?: boolean;
  isRequest?: boolean;
  filterPriority?: IAppFiltersCheckboxDTM;
  filterAssignee?: IAppFiltersCheckboxDTM;
  filterAssigneeOrganization?: IAppFiltersCheckboxDTM;
  filterObject?: IAppFiltersCheckboxDTM;
  filterTreeDataObject?: IAppFiltersCheckboxTreeDTM;
  filterModule?: IAppFiltersCheckboxDTM;
  filterReceivedDates?: INotificationTaskFilterReceivedDatesDTM;
  filterCompletionDates?: INotificationTaskFilterReceivedDatesDTM;
}

export class NotificationViewedFiltersDTM extends BaseDTM<INotificationViewedFiltersDTM> {
  @IsOptional()
  @IsBoolean()
  isOnlyUnread?: boolean;

  @IsOptional()
  @IsBoolean()
  isDisabledOnlyUnread?: boolean;

  @IsOptional()
  @IsBoolean()
  isRequest?: boolean;

  @IsOptional()
  @ValidateNested()
  @Type(() => AppFiltersCheckboxDTM)
  filterPriority?: AppFiltersCheckboxDTM;

  @IsOptional()
  @ValidateNested()
  @Type(() => AppFiltersCheckboxDTM)
  filterAssignee?: AppFiltersCheckboxDTM;

  @IsOptional()
  @ValidateNested()
  @Type(() => AppFiltersCheckboxDTM)
  filterAssigneeOrganization?: AppFiltersCheckboxDTM;

  @IsOptional()
  @ValidateNested()
  @Type(() => AppFiltersCheckboxDTM)
  filterObject?: AppFiltersCheckboxDTM;

  @IsOptional()
  @ValidateNested()
  @Type(() => AppFiltersCheckboxTreeDTM)
  filterTreeDataObject?: AppFiltersCheckboxTreeDTM;

  @IsOptional()
  @ValidateNested()
  @Type(() => AppFiltersCheckboxDTM)
  filterModule?: AppFiltersCheckboxDTM;

  @IsOptional()
  @ValidateNested()
  @Type(() => NotificationTaskFilterReceivedDatesDTM)
  filterReceivedDates?: NotificationTaskFilterReceivedDatesDTM;

  @IsOptional()
  @ValidateNested()
  @Type(() => NotificationTaskFilterReceivedDatesDTM)
  filterCompletionDates?: NotificationTaskFilterReceivedDatesDTM;

  get filterAssigneeGroup() {
    const unassigned = this.filterAssignee?.group?.find((item) => item.value === 'false' && item.checked);
    const emailChecked = this.filterAssignee?.group?.find((item) => item.value !== 'false' && item.checked);
    return this.filterAssignee?.group?.map((item) => AppFiltersCheckboxGroupDTM.fromPlain(
      { ...item, disabled: (unassigned && item.value !== 'false') || (emailChecked && item.value === 'false') },
    ));
  }
}

export interface INotificationTaskDTM {
  content: INotificationTaskContentDTM[];
  filters?: INotificationViewedFiltersDTM;
  contentOverdue?: INotificationTaskContentDTM[];
  contentToday?: INotificationTaskContentDTM[];
  contentThisWeek?: INotificationTaskContentDTM[];
  contentLater?: INotificationTaskContentDTM[];
  totalPages?: number;
  totalElements?: number;
  totalElementsOverdue?: number;
  totalElementsToday?: number;
  totalElementsThisWeek?: number;
  totalElementsLater?: number;
  infoCountAllTasks?: number;
  infoCountOverdueTasks?: number;
  infoCountDueTasks?: number;
  infoCountPlannedTasks?: number;
  currentPage?: number;
  status?: REQUEST_STATUS;
  criticality?: string[];
  statusPage?: string;
  statusAdd?: REQUEST_STATUS;
  isNextFirstRunDownload?: boolean;
}

export class NotificationTaskDTM extends BaseDTM<INotificationTaskDTM> {
  @IsDefined()
  @ValidateNested({ each: true })
  @Type(() => NotificationTaskContentDTM)
  content: NotificationTaskContentDTM[];

  @IsOptional()
  @ValidateNested()
  @Type(() => NotificationViewedFiltersDTM)
  filters?: NotificationViewedFiltersDTM;

  @IsOptional()
  @ValidateNested({ each: true })
  @Type(() => NotificationTaskContentDTM)
  contentOverdue?: NotificationTaskContentDTM[];

  @IsOptional()
  @ValidateNested({ each: true })
  @Type(() => NotificationTaskContentDTM)
  contentToday?: NotificationTaskContentDTM[];

  @IsOptional()
  @ValidateNested({ each: true })
  @Type(() => NotificationTaskContentDTM)
  contentThisWeek?: NotificationTaskContentDTM[];

  @IsOptional()
  @ValidateNested({ each: true })
  @Type(() => NotificationTaskContentDTM)
  contentLater?: NotificationTaskContentDTM[];

  @IsOptional()
  @IsNumber()
  totalPages?: number;

  @IsOptional()
  @IsNumber()
  totalElements?: number;

  @IsOptional()
  @IsNumber()
  totalElementsOverdue?: number;

  @IsOptional()
  @IsNumber()
  totalElementsToday?: number;

  @IsOptional()
  @IsNumber()
  totalElementsThisWeek?: number;

  @IsOptional()
  @IsNumber()
  totalElementsLater?: number;

  @IsOptional()
  @IsNumber()
  infoCountAllTasks?: number;

  @IsOptional()
  @IsNumber()
  infoCountOverdueTasks?: number;

  @IsOptional()
  @IsNumber()
  infoCountDueTasks?: number;

  @IsOptional()
  @IsNumber()
  infoCountPlannedTasks?: number;

  @IsOptional()
  @IsNumber()
  currentPage?: number;

  @IsOptional()
  @IsEnum(REQUEST_STATUS)
  status?: REQUEST_STATUS;

  @IsOptional()
  @IsString({ each: true })
  criticality?: string[];

  @IsOptional()
  @IsString()
  statusPage?: string;

  @IsOptional()
  @IsEnum(REQUEST_STATUS)
  statusAdd?: REQUEST_STATUS;

  @IsOptional()
  @IsBoolean()
  isNextFirstRunDownload?: boolean;
}

export interface INotificationViewedDTM {
  content: INotificationTaskContentDTM[];
  filters?: INotificationViewedFiltersDTM;
  totalPages?: number;
  totalElements?: number;
  currentPage?: number;
  status?: REQUEST_STATUS;
  statusAdd?: REQUEST_STATUS;
  countUnreadNotifications?: number;
  countImportantNotifications?: number;
  countRegularNotifications?: number;
}

export class NotificationViewedDTM extends BaseDTM<INotificationViewedDTM> {
  @IsDefined()
  @ValidateNested({ each: true })
  @Type(() => NotificationTaskContentDTM)
  content: NotificationTaskContentDTM[];

  @IsOptional()
  @ValidateNested()
  @Type(() => NotificationViewedFiltersDTM)
  filters?: NotificationViewedFiltersDTM;

  @IsOptional()
  @IsNumber()
  totalPages?: number;

  @IsOptional()
  @IsNumber()
  totalElements?: number;

  @IsOptional()
  @IsNumber()
  currentPage?: number;

  @IsOptional()
  @IsEnum(REQUEST_STATUS)
  status?: REQUEST_STATUS;

  @IsOptional()
  @IsEnum(REQUEST_STATUS)
  statusAdd?: REQUEST_STATUS;

  @IsOptional()
  @IsNumber()
  countUnreadNotifications?: number;

  @IsOptional()
  @IsNumber()
  countImportantNotifications?: number;

  @IsOptional()
  @IsNumber()
  countRegularNotifications?: number;
}

export interface ITasksFiltersCreatedDTM {
  from?: string
  to?: string
}

export class TasksFiltersCreatedDTM extends BaseDTM<ITasksFiltersCreatedDTM> {
  @IsOptional()
  @IsString()
  from?: string;

  @IsOptional()
  @IsString()
  to?: string;
}

export interface ITasksFiltersOrganizationDTM {
  id: number
  name: string
}

export class TasksFiltersOrganizationDTM extends BaseDTM<ITasksFiltersOrganizationDTM> {
  @IsNumber()
  id: number;

  @IsString()
  name: string;
}

export interface ITasksFiltersAssigneeDTM {
  organizations?: ITasksFiltersOrganizationDTM[]
  users?: INotificationTaskContentAssigneeDTM[]
}

export class TasksFiltersAssigneeDTM extends BaseDTM<ITasksFiltersAssigneeDTM> {
  @ValidateNested({ each: true })
  @Type(() => TasksFiltersOrganizationDTM)
  organizations?: TasksFiltersOrganizationDTM[];

  @ValidateNested({ each: true })
  @Type(() => NotificationTaskContentAssigneeDTM)
  users?: NotificationTaskContentAssigneeDTM[];
}

export interface ITasksFiltersReferenceDTM {
  organizations?: ITasksFiltersOrganizationDTM[]
  paymentIds?: number[]
  rateRequestIds?: number[]
  shipmentIds?: number[]
}

export class TasksFiltersReferenceDTM extends BaseDTM<ITasksFiltersReferenceDTM> {
  @IsDefined()
  @ValidateNested({ each: true })
  @Type(() => TasksFiltersOrganizationDTM)
  organizations?: TasksFiltersOrganizationDTM[];

  @IsOptional()
  @IsArray()
  paymentIds?: number[];

  @IsOptional()
  @IsArray()
  rateRequestIds?: number[];

  @IsOptional()
  @IsArray()
  shipmentIds?: number[];
}

export interface ITasksFiltersDTM {
  createdAt: ITasksFiltersCreatedDTM
  completedAt: ITasksFiltersCreatedDTM
  criticality: ENotificationCriticality[]
  domains: ENotificationDomain[]
  dueDate: ITasksFiltersCreatedDTM
  targetAudience: ITasksFiltersAssigneeDTM
  objectReference: ITasksFiltersReferenceDTM
  assignee: ITasksFiltersAssigneeDTM
}

export class TasksFiltersDTM extends BaseDTM<ITasksFiltersDTM> {
  @ValidateNested()
  @Type(() => TasksFiltersCreatedDTM)
  createdAt: TasksFiltersCreatedDTM;

  @ValidateNested()
  @Type(() => TasksFiltersCreatedDTM)
  completedAt: TasksFiltersCreatedDTM;

  @IsArray()
  criticality: ENotificationCriticality[];

  @IsArray()
  domains: ENotificationDomain[];

  @ValidateNested()
  @Type(() => TasksFiltersCreatedDTM)
  dueDate: TasksFiltersCreatedDTM;

  @ValidateNested()
  @Type(() => TasksFiltersAssigneeDTM)
  targetAudience: TasksFiltersAssigneeDTM;

  @ValidateNested()
  @Type(() => TasksFiltersReferenceDTM)
  objectReference: TasksFiltersReferenceDTM;

  @ValidateNested()
  @Type(() => TasksFiltersAssigneeDTM)
  assignee: TasksFiltersAssigneeDTM;
}
