import isNumber from 'lodash/fp/isNumber';

import { R } from 'monetary/repository';
import { BaseController, controller } from 'proto/BaseController';

@controller
export class FAKExceptionController extends BaseController {
  public loadData = async (index?: number) => {
    this.dispatch(R.actions.fakException.setIsLoading({ id: index, isLoading: true }));
    if (!isNumber(index)) {
      this.dispatch(R.actions.fakException.setEmptyData({ id: index, isEmptyData: true }));
      return;
    }
    const allQuotas = R.selectors.freightQuote.FreightQuotePrintSelectors.allQuotes(this.store.getState());
    const matchedQuota = (allQuotas && isNumber(index)) ? allQuotas[index] : undefined;
    if (!matchedQuota) {
      this.dispatch(R.actions.fakException.setEmptyData({ id: index, isEmptyData: true }));
      return;
    }

    const savedCharges = matchedQuota.schedules ? matchedQuota.schedules[0].charges : [];
    if (!savedCharges || !savedCharges.length) {
      this.dispatch(R.actions.fakException.setEmptyData({ id: index, isEmptyData: true }));
      return;
    }
    const FRTCharge = savedCharges.find((charge) => charge?.chargeCode?.code === 'FRT');
    const commoditiesCodes = FRTCharge?.metadata?.excludedCommodities;
    if (!commoditiesCodes || !commoditiesCodes.length) {
      this.dispatch(R.actions.fakException.setEmptyData({ id: index, isEmptyData: true }));
      return;
    }

    let response;
    try {
      response = await R.services.FAKException.getData(commoditiesCodes);
    } catch (e) {
      this.dispatch(R.actions.fakException.setError({ id: index, isError: true }));
      return;
    }
    this.dispatch(R.actions.fakException.setData({ id: index, data: response }));
  }
}
