import styled from 'styled-components';
import Layout from 'antd/es/layout';

export const ShipmentDetailsActionPanel = styled(Layout.Footer).attrs({
  'data-class': 'ShipmentDetailsActionPanel',
})`
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
  margin-left: -24px;
  margin-right: -24px;
  height: 64px;
  padding: 0px 24px;

  z-index: 2;
  color: inherit;
  background-color: white;
  box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px rgba(0, 0, 0, 0.08), 0px 9px 28px 8px rgba(0, 0, 0, 0.05);
`;
