import Card from 'antd/es/card';
import styled from 'styled-components';
import Divider from 'antd/es/divider';
import { NavLink } from 'react-router-dom';

import { divFlex } from 'app-wrapper/view/themes/mixin';

export const RFRItemSubCardContainer = styled(NavLink).attrs((props: {
  isClickable?: boolean,
}) => ({
  isClickable: props.isClickable,
  'data-class': 'RFRItemSubCardContainer',
}))`
  ${divFlex}
  padding: 0;

  width: 100%;
  height:  76px;

  border: 1px solid ${({ theme }) => theme.themesColors.neutralBranding4};
  border-radius: 4px;

  background-color: ${({ theme }) => theme.themesColors.neutralBranding1};
  line-height: inherit !important;
  color: ${({ theme }) => theme.themesColors.neutralBranding13} !important;

  ${({ isClickable }) => !isClickable && 'cursor: auto;'};
  ${({ isClickable }) => !isClickable && 'border: none;'};
  ${({ isClickable }) => !isClickable && 'box-shadow: none;'};

  &:hover {
    ${({ isClickable }) => !isClickable && 'border: none;'};
    ${({ isClickable }) => !isClickable && 'box-shadow: none;'};
  }
`;

export const RFRItemSubCardSectionRequest = styled.div.attrs((props: {
  isClickable?: boolean,
  width?: string,
}) => ({
  isClickable: props.isClickable,
  width: props.width,
  'data-class': 'RFRItemSubCardSectionRequest',
}))`
  ${divFlex}
  width: ${({ width }) => (width || '20%')};
  padding-left: 16px;

  div[data-class="RFRItemSubCardDescriptionsContainerContent"] span[data-class="RFRItemSubCardDescriptionsContainerSpanClip"] {
    color: ${({ theme, isClickable }) => (isClickable ? theme.themesColors.primaryBranding7 : theme.themesColors.neutralBranding10)};

    ${({ isClickable }) => !isClickable && 'font-size: 20px;'};
  }
  ${({ isClickable }) => !isClickable && 'padding-left: 0;'};
`;

export const RFRItemSubCardSectionContainerAndCommodity = styled.div.attrs({
  'data-class': 'RFRItemSubCardSectionContainerAndCommodity',
})`
  ${divFlex}
  width: 16.1%;
`;

export const RFRItemSubCardSectionCustomerTitle = styled.div.attrs({
  'data-class': 'RFRItemSubCardSectionCustomerTitle',
})`
  ${divFlex}

  font-size: 12px;
  font-weight: 400;
  color: ${({ theme }) => theme.themesColors.secondaryDot45};
`;

export const RFRItemSubCardSectionVerticalDivider = styled.div.attrs({
  'data-class': 'RFRItemSubCardSectionVerticalDivider',
})`
  ${divFlex}
  width: 0;
  height: 52px;

  margin: auto 0;
  border-left: 1px dashed ${({ theme }) => theme.themesColors.neutralBranding5};
`;

export const RFRItemSubCardSectionVerticalDividerIcon = styled.div.attrs({
  'data-class': 'RFRItemSubCardSectionVerticalDividerIcon',
})`
  ${divFlex}
  justify-content: center;
  align-items: center;
  height: 100%;

  margin: 0;
`;

export const RFRItemSubCardSectionOrigin = styled.div.attrs({
  'data-class': 'RFRItemSubCardSectionOrigin',
})`
  ${divFlex}
  width: 16.1%;

  div[data-class="SCDescriptionsContainer"] {
    width: calc(100% - 16px - 16px - 8px);
  }
`;

export const RFRItemSubCardSectionDestination = styled.div.attrs({
  'data-class': 'RFRItemSubCardSectionDestination',
})`
  ${divFlex}
  width: 16.1%;

  div[data-class="SCDescriptionsContainer"] {
    width: calc(100% - 16px - 16px - 8px);
  }
`;

export const RFRItemSubCardSectionCustomer = styled.div.attrs({
  'data-class': 'RFRItemSubCardSectionCustomer',
})`
  ${divFlex}
  width: 16.1%;

  svg {
    color: ${({ theme }) => theme.themesColors.neutralBranding6};
  }
`;

export const RFRItemSubCardSectionTasks = styled.div.attrs({
  'data-class': 'RFRItemSubCardSectionTasks',
})`
  ${divFlex}
  justify-content: end;
  align-items: center;

  width: 16.1%;
`;

export const RFRItemSubCardSectionTasksIcon = styled.div.attrs((props: {
  colorSvg?: string,
  marginBottomSvg?: string,
}) => ({
  colorSvg: props.colorSvg,
  marginBottomSvg: props.marginBottomSvg,
  'data-class': 'RFRItemSubCardSectionTasksIcon',
}))`
  ${divFlex}
  width: 7px;
  height: 7px;

  border-radius: 50%;
  margin-right: 8px;
  ${({ colorSvg }) => colorSvg && `background-color: ${colorSvg}`};
`;

export const RFRItemSubCardSectionTasksText = styled.div.attrs((props: {
  colorBg?: string,
}) => ({
  colorBg: props.colorBg,
  'data-class': 'RFRItemSubCardSectionTasksText',
}))`
  ${divFlex}
  min-width: 23px;
  justify-content: center;

  color: ${({ theme }) => theme.themesColors.neutralBranding10};
  font-size: 14px;
  font-weight: 400;

  border-radius: 100px;
  ${({ colorBg }) => colorBg && `background-color: ${colorBg}`};
`;

export const RFRItemSubCardSectionIcon = styled.div.attrs((props: {
  marginLeft?: string,
  marginRight?: string,
}) => ({
  marginLeft: props.marginLeft,
  marginRight: props.marginRight,
  'data-class': 'RFRItemSubCardSectionIcon',
}))`
  ${divFlex}

  justify-content: center;
  align-items: center;
  margin-left: ${({ marginLeft }) => (marginLeft || '16px')};
  margin-right: ${({ marginRight }) => (marginRight || '8px')};
`;

export const RFRItemSubCardSectionContentDescriptions = styled.div.attrs({
  'data-class': 'RFRItemSubCardSectionContentDescriptions',
})`
  ${divFlex}

  justify-content: center;
  align-items: center;
  margin-left: 16px;
  margin-right: 8px;
`;

export const RFRItemSubCardSectionDivider = styled(Divider).attrs({
  'data-class': 'RFRItemSubCardSectionDivider',
})`
  ${divFlex}
  width: 100%;
  margin: 0;
`;

export const ARQListLoadingCardStyled = styled(Card).attrs({
  'data-class': 'ARQListLoadingCardStyled',
})`
  ${divFlex}
  width: 100%;
`;
