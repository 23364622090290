import {
  IsString, IsDefined, IsOptional, IsNumber,
} from 'class-validator';
import { BaseDTM } from 'proto/BaseDTM';

export interface ICustomerDTM {
  companyName: string
  name: string
  email?: string
  phone?: string
  id?: number
}

export class CustomerDTM extends BaseDTM<ICustomerDTM> {
  @IsString()
  @IsDefined()
  companyName: string;

  @IsString()
  @IsDefined()
  name: string;

  // TODO: disabled due to out of sync with the backend
  // @IsEmail()
  @IsString()
  @IsOptional()
  email: string;

  @IsString()
  @IsOptional()
  phone: string;

  @IsNumber()
  @IsOptional()
  id?: number;
}
