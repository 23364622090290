import React, { FC } from 'react';

import {
  Button,
  MinusIon,
  PlusIcon,
} from './AddOrRemoveCargo.styled';

interface AddOrRemoveCargoComponentProps {
  isFirstCargo: boolean;
}

export const AddOrRemoveCargoComponent: FC<AddOrRemoveCargoComponentProps> = ({ isFirstCargo }) => (
  <Button type="dashed" disabled>
    {isFirstCargo ? <PlusIcon /> : <MinusIon />}
  </Button>
);
