import styled from 'styled-components';

import { divFlex } from 'app-wrapper/view/themes/mixin';

export const TooltipErrorWrapper = styled.div.attrs({
  'data-class': 'TooltipErrorWrapper',
})`
  ${divFlex}
  flex-direction: column;
  .ant-tooltip-inner {
    font-size: 14px;
    font-weight: 400;
    color: ${({ theme }) => theme?.themesColors?.characterBrandingDanger}
  }
`;
