import styled from 'styled-components';
import Divider from 'antd/es/divider';

interface IShipmentContainersHeaderLayoutParams {
  isTemperatureControlActive: boolean
}

export const ShipmentContainersHeaderLayout = styled.div.attrs({
  'data-class': 'ShipmentContainersHeaderLayout',
})<IShipmentContainersHeaderLayoutParams>`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  height: ${({ isTemperatureControlActive }) => (isTemperatureControlActive ? '100' : '50')}px;

  border: 1px solid #EAEEF8;
  border-radius: 3px;
  
  background: white;
  color: black;
`;

export const ShipmentContainersHeaderTopRow = styled.div.attrs({
  'data-class': 'ShipmentContainersHeaderTopRow',
})`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
  margin: 0;
  padding: 10px 16px;
  width: 100%;
  height: 50px;
  font-size: 12px;
`;

export const ShipmentContainersHeaderBottomRow = styled.div.attrs({
  'data-class': 'ShipmentContainersHeaderBottomRow',
})`
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-end;
  justify-content: flex-start;
  margin: 0;
  width: 100%;
  height: 46px;
`;

export const ShipmentContainersHeaderDivider = styled(Divider).attrs({
  'data-class': 'ShipmentContainersHeaderDivider',
})`
  margin: 0;
`;

export const DisableBlock = styled.div`
  width: 100%;
  height: 100%;
  opacity: 0.3;
  cursor: not-allowed;
  z-index: 10;
  position: relative;
`;

export const DisableEvents = styled.div`
  pointer-events: none;
`;
