import React from 'react';

import { useAppSelector } from 'app-wrapper/hooks';
import { R } from 'shipment-operations/repository';

import { TransportationComponent } from './Transportation.component';

const Transportation = () => {
  const containers = useAppSelector(R.selectors.createInvoiceCharges.uniqTransportationContainers);
  const data = useAppSelector(R.selectors.createInvoiceCharges.groupedTransportationContainers);
  const sum = useAppSelector(R.selectors.createInvoiceCharges.getTransportationDataSum);

  return (
    <TransportationComponent
      containers={containers}
      data={data}
      sum={sum}
    />
  );
};

export { Transportation };
