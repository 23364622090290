import React, { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import {
  SkypaceLoadingCheck, SkypaceSpin, SkypaceLoadingWait, SkypaceBgLogoSvg,
} from 'app-wrapper/view/icons';

import {
  RFQLoaderContent,
  RFQLoaderContentWrap, RFQLoaderHeader, RFQLoaderHeaderLogo, RFQLoaderHeaderTitle, RFQLoaderContentWrapImage, RFQLoaderStep, RFQLoaderStepDescription, RFQLoaderStepLoading, RFQLoaderStepLogo, RFQLoaderWrap,
} from './RFQLoader.styled';

interface IRFQLoaderComponentProps {
  nameLogo?: string;
  nameStep1?: string;
  nameStep2?: string;
  nameStep3?: string;
  isLoadingCompleteStep1?: boolean;
  isLoadingCompleteStep2?: boolean;
  isLoadingCompleteStep3?: boolean;
  isLoadingCompleteStep4?: boolean;
}

const RFQLoaderComponent = (props: IRFQLoaderComponentProps) => {
  const { t } = useTranslation();
  const {
    nameLogo,
    nameStep1 = t('Exploring Available Schedules'),
    nameStep2 = t('Analyzing Market Prices'),
    nameStep3 = t('Creating Best Quotes'),
    isLoadingCompleteStep1,
    isLoadingCompleteStep2,
    isLoadingCompleteStep3,
    isLoadingCompleteStep4,
  } = props;

  const getLoadingLogo = useCallback(
    (complete: boolean) => (complete ? (<SkypaceLoadingCheck />) : (<SkypaceLoadingWait />)),
    [],
  );

  return (
    <RFQLoaderWrap>

      <RFQLoaderContentWrapImage>
        <SkypaceBgLogoSvg />
      </RFQLoaderContentWrapImage>

      <RFQLoaderContentWrap>
        <RFQLoaderHeader>
          <RFQLoaderHeaderLogo>
            <SkypaceSpin
              loop
            />
            <RFQLoaderHeaderTitle>
              {nameLogo || ''}
            </RFQLoaderHeaderTitle>
          </RFQLoaderHeaderLogo>
          {!isLoadingCompleteStep4 && <RFQLoaderStepLoading isLoading={!isLoadingCompleteStep4} />}
        </RFQLoaderHeader>

        <RFQLoaderContent>
          <RFQLoaderStep>
            <RFQLoaderStepLogo>
              {getLoadingLogo(!!isLoadingCompleteStep1)}
            </RFQLoaderStepLogo>
            <RFQLoaderStepDescription isLoading={isLoadingCompleteStep1}>
              {nameStep1}
            </RFQLoaderStepDescription>
          </RFQLoaderStep>

          <RFQLoaderStep marginTop="8px">
            <RFQLoaderStepLogo>
              {getLoadingLogo(!!isLoadingCompleteStep2)}
            </RFQLoaderStepLogo>
            <RFQLoaderStepDescription isLoading={isLoadingCompleteStep2}>
              {nameStep2}
            </RFQLoaderStepDescription>
          </RFQLoaderStep>

          <RFQLoaderStep marginTop="8px">
            <RFQLoaderStepLogo>
              {getLoadingLogo(!!isLoadingCompleteStep3)}
            </RFQLoaderStepLogo>
            <RFQLoaderStepDescription isLoading={isLoadingCompleteStep3}>
              {nameStep3}
            </RFQLoaderStepDescription>
          </RFQLoaderStep>
        </RFQLoaderContent>

      </RFQLoaderContentWrap>
    </RFQLoaderWrap>
  );
};

const RFQLoaderComponentCache = memo(RFQLoaderComponent);

export { RFQLoaderComponentCache as RFQLoaderComponent };
