import React from 'react';
import { useSelector } from 'react-redux';

import { UC } from 'shipment-operations/controllers';
import { UC as appUC } from 'app-wrapper/controllers';
import { R } from 'shipment-operations/repository';

import { BookingConfirmationComponent } from './BookingConfirmation.component';

const BookingConfirmationContainer = () => {
  const withMismatches = useSelector(R.selectors.shipmentChanges.getWithMismatches);
  const shipmentId = useSelector(R.selectors.overview.getShipmentId);
  const loading = useSelector(R.selectors.shipmentChanges.getLoading);
  const cargoTable = useSelector(R.selectors.shipmentChanges.getCargoTable);
  const containersTable = useSelector(R.selectors.shipmentChanges.getContainersTable);
  const transportPlanCurrent = useSelector(R.selectors.shipmentChanges.getTransportPlanCurrent);
  const transportPlanMismatchCurrent = useSelector(R.selectors.shipmentChanges.getTransportPlanMismatchCurrent);
  const cutOffTable = useSelector(R.selectors.shipmentChanges.getCutOffTable);
  const locationsTable = useSelector(R.selectors.shipmentChanges.getLocationsTable);
  const isActionLoading = useSelector(R.selectors.shipmentChanges.getActionLoading);
  const carrierNumber = useSelector(R.selectors.shipmentChanges.getCarrierNumber);
  const inttraNumber = useSelector(R.selectors.shipmentChanges.getInttraNumber);

  return (
    <BookingConfirmationComponent
      loadData={UC.shipmentChanges.loadData}
      withMismatches={withMismatches}
      savedShipmentId={shipmentId}
      loading={loading}
      onClose={appUC.drawer.closeDrawer}
      cargoTable={cargoTable}
      containersTable={containersTable}
      transportPlanCurrent={transportPlanCurrent}
      transportPlanMismatchCurrent={transportPlanMismatchCurrent}
      cutOffTable={cutOffTable}
      locationsTable={locationsTable}
      onChangeCutOff={UC.shipmentChanges.onChangeCutOff}
      onChangeLocation={UC.shipmentChanges.onChangeLocation}
      onConfirm={UC.shipmentChanges.onConfirmData}
      isActionLoading={isActionLoading}
      carrierNumber={carrierNumber}
      inttraNumber={inttraNumber}
      syncIntraNumber={UC.shipmentChanges.syncIntraNumber}
      onChangeCarrierNumber={UC.shipmentChanges.onChangeCarrierNumber}
      onChangeInttraNumber={UC.shipmentChanges.onChangeInttraNumber}
      reset={UC.shipmentChanges.onReset}
    />
  );
};

export { BookingConfirmationContainer as BookingConfirmation };
