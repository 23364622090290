import styled from 'styled-components';
import AntRadio from 'antd/es/radio';
import Typography from 'antd/es/typography';
import { RightOutlined, CaretUpOutlined } from '@ant-design/icons';

import { Button } from 'app-wrapper/view/components';

export const Wrap = styled.div.attrs((props: {
  isChosen: boolean,
}) => ({
  isChosen: props.isChosen,
}))`
  width: 100%;
  margin-top: 8px;
  position: relative;
  display: flex;
  padding: 16px 16px 16px 16px;
  background-color: #FFFFFF;
  box-shadow: ${({ isChosen }) => (isChosen ? '5px 0px 0px 0px rgba(108, 121, 222, 1) inset' : '0px 1px 0px 0px rgba(244, 245, 248, 1) inset')};
  border-radius: 3px;
  min-height: 92px;
  cursor: pointer;
`;

export const ContentWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const Column = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Radio = styled(AntRadio)`
  margin-right: 12px;
  margin-bottom: auto;
  width: 16px;
  height: 16px;
`;

export const RowEnd = styled(Row)`
  justify-content: space-between;
`;

export const RowsDivider = styled.div`
  margin-top: 9px;
`;

export const DateText = styled(Typography)`
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
`;

export const Text = styled(Typography)`
  margin-left: 4px;
  font-size: 14px;
  line-height: 22px;
`;

export const TotalPrice = styled(DateText)`
  margin-left: 12px;
`;

export const DifferencePrice = styled.span`
  margin-left: 4px;
  font-size: 12px;
  line-height: 20px;
`;

export const BiggerPriceIcon = styled(CaretUpOutlined)`
  font-size: 10px;
`;

export const SmallerPriceIcon = styled(BiggerPriceIcon)`
  transform: rotate(180deg);
`;

export const DividerArrow = styled(RightOutlined)`
  margin: 0 12px;

  svg {
    width: 10px;
    height: 10px;
  }

  * {
    fill: rgba(209, 215, 226, 1);
  }
`;

export const TextGrey = styled(Typography)`
  font-size: 14px;
  line-height: 22px;
  color: rgba(115, 129, 155, 1);
`;

export const PortDate = styled(Text)`
  margin-left: 8px;
`;

export const DocumentTitle = styled(Text)`
  margin-left: 16px;
  color: rgba(115, 129, 155, 1);
`;

export const DocumentSubTitle = styled(Text)`
  margin-left: 8px;
`;

export const RoutingButton = styled(Button)`
  min-height: 32px;
  height: 32px;
  box-shadow: none;
`;

export const RoutingButtonIcon = styled(RightOutlined).attrs((props: {
  isExpanded: boolean,
}) => ({
  isExpanded: props.isExpanded,
}))`
  transform: ${({ isExpanded }) => (isExpanded ? 'rotate(90deg)' : 'none')};
  transition: .2s all ease-in !important;

  svg {
    width: 10px;
    height: 10px;
  }

 * {
   fill: rgba(153, 163, 172, 1);
 }
`;

export const ExpandedData = styled.div`
  margin-top: 16px;
  padding: 16px;
  background-color: rgba(249, 249, 251, 1);
`;
