import React, {
  FC, memo, useCallback, useMemo,

} from 'react';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import Modal from 'antd/es/modal';

import { useWindowSize } from 'app-wrapper/hooks';
import {
  MainContentRight, MainContentRightBg, MainContentSidebar,
} from 'app-wrapper/view/themes/mixin';
import { SkypaceSpin } from 'app-wrapper/view/icons';
import { AlertBlock } from 'app-wrapper/view/components';

import {
  RFQResponse, RCQLoaderCompleteFollowComponent, RFRCompletedRequestComponent, RFRRejectRequestComponent,
} from 'monetary/view/components';

import {
  RFRByIdSecondStepDiv,
  RFRByIdSecondStepFirst,
  RFRByIdSecondStepMainContentRightCompleteWrap,
  RFRByIdSecondStepMainContentRightCompleteWrapContent,
  RFRByIdSecondStepMainContentRightRequestWrap,
  RFRByIdSecondStepMainContentRightWrapperFullCollapse,
  RFRByIdSecondStepMainContentSidebarFullCollapse,
  RFRByIdSecondStepSecond,
  RFRByIdSecondStepSecondFooter,
  RFRByIdSecondStepSecondFooterButton,
  RFRByIdSecondStepSecondFooterSidebar,
  RFRByIdSecondStepSecondFooterWrap,
  RFRByIdSecondStepSecondHeaderFirst,
  RFRByIdSecondStepSecondHeaderSecond,
  RFRByIdSecondStepSecondHeaderThird,
  RFRByIdSecondStepWrapper,
} from './RFRByIdSecondStep.styled';

import { RFRFormRequest } from './components';

interface IRFRByIdSecondStepComponentProps {
  isCollapse?: boolean;
  isLoad?: boolean;
  isHaveQuotaId?: boolean;
  isLoadCompletedRequest?: boolean;
  isCompletedDone?: boolean;
  isLoadCompletedRequestComplete?: boolean;
  isLoadCompletedRequestDone?: boolean;
  isLoadCompletedRequestReject?: boolean;
  completeRequest?: boolean;
  onCanceledRequest: () => void
  onCompletedRequest: () => void
  onCheckRatesRequest: () => void
}

const RFRByIdSecondStepComponent: FC<IRFRByIdSecondStepComponentProps> = (props) => {
  const {
    isCollapse,
    isLoad,
    isHaveQuotaId,
    isLoadCompletedRequest,
    isCompletedDone,
    isLoadCompletedRequestComplete,
    isLoadCompletedRequestDone,
    isLoadCompletedRequestReject,
    completeRequest,
    onCanceledRequest,
    onCompletedRequest,
    onCheckRatesRequest,
  } = props;
  const { customQuotaId } = useParams();
  const { t } = useTranslation();

  const { isFullMediaWidth } = useWindowSize();

  const loaderComponent = useMemo(() => (
    <RCQLoaderCompleteFollowComponent
      nameLogo={t('To complete the request, follow these steps:')}
      nameStep1={t('Add Rates based on Request Params')}
      nameStep2={t('Validate that Rates are returned')}
      nameStep3={t('Complete Request')}
      nameStep4=""
    />
  ), [completeRequest]);

  const disabledRejectQuotes = isLoadCompletedRequest || isLoadCompletedRequestDone;
  const disabledCheckQuotes = !isHaveQuotaId || isLoadCompletedRequest;
  const disabledCompleteQuotes = isCompletedDone || isLoadCompletedRequest || isLoadCompletedRequestDone;

  const onCanceledRequestHandler = useCallback(() => {
    Modal.confirm({
      title: t('Do you want to reject the request?'),
      okText: t('Yes'),
      cancelText: t('No'),
      onOk: onCanceledRequest,
    });
  }, [onCanceledRequest]);

  return (
    <RFRByIdSecondStepDiv>

      <RFRByIdSecondStepWrapper />
      <MainContentRightBg
        isActive={(isCollapse && !isFullMediaWidth) || isCollapse}
      />
      <RFRByIdSecondStepFirst>
        <RFRByIdSecondStepMainContentSidebarFullCollapse isCollapse={isCollapse} className="FreightQuoteContent">
          <RFRFormRequest
            quotaSearchId={customQuotaId}
            hideButton
          />
        </RFRByIdSecondStepMainContentSidebarFullCollapse>
      </RFRByIdSecondStepFirst>
      <RFRByIdSecondStepSecond>
        <RFRByIdSecondStepSecondFooterWrap>
          <RFRByIdSecondStepSecondFooterSidebar />

          <RFRByIdSecondStepSecondFooter>
            <RFRByIdSecondStepSecondHeaderFirst>
              <RFRByIdSecondStepSecondFooterButton
                disabled={disabledRejectQuotes}
                onClick={onCanceledRequestHandler}
                danger
                type="default"
              >
                {t('Reject Request')}
              </RFRByIdSecondStepSecondFooterButton>
            </RFRByIdSecondStepSecondHeaderFirst>
            <RFRByIdSecondStepSecondHeaderSecond>
              <RFRByIdSecondStepSecondFooterButton
                disabled={disabledCheckQuotes}
                onClick={onCheckRatesRequest}
                type="default"
              >
                {t('Check Quotes')}
              </RFRByIdSecondStepSecondFooterButton>
            </RFRByIdSecondStepSecondHeaderSecond>
            <RFRByIdSecondStepSecondHeaderThird>
              <RFRByIdSecondStepSecondFooterButton
                disabled={disabledCompleteQuotes}
                onClick={onCompletedRequest}
              >
                {t('Complete Request')}
              </RFRByIdSecondStepSecondFooterButton>
            </RFRByIdSecondStepSecondHeaderThird>
          </RFRByIdSecondStepSecondFooter>

        </RFRByIdSecondStepSecondFooterWrap>
        <MainContentSidebar />
        <RFRByIdSecondStepMainContentRightWrapperFullCollapse>
          <MainContentRight>

            {!isLoad && isCompletedDone && isLoadCompletedRequestComplete && (
              <RFRByIdSecondStepMainContentRightRequestWrap>
                <RFRCompletedRequestComponent />
              </RFRByIdSecondStepMainContentRightRequestWrap>
            )}
            {!isLoad && isCompletedDone && isLoadCompletedRequestReject && (
              <RFRByIdSecondStepMainContentRightRequestWrap>
                <RFRRejectRequestComponent />
              </RFRByIdSecondStepMainContentRightRequestWrap>
            )}
            {!isLoad && isCompletedDone && !isLoadCompletedRequestDone && (
              <RFRByIdSecondStepMainContentRightCompleteWrap>
                {completeRequest && isCompletedDone && (<AlertBlock type="warning" message={t('No quotes found. Please validate that your rates are set up correctly.')} />)}
                <RFRByIdSecondStepMainContentRightCompleteWrapContent>
                  {loaderComponent}
                </RFRByIdSecondStepMainContentRightCompleteWrapContent>
              </RFRByIdSecondStepMainContentRightCompleteWrap>
            )}
            <RFQResponse
              isFooter
              hideBook
              hideFavorite
              NoneContentRecommended={
                isLoad
                  ? <SkypaceSpin />
                  : <></>
              }
            />

          </MainContentRight>
        </RFRByIdSecondStepMainContentRightWrapperFullCollapse>
      </RFRByIdSecondStepSecond>
    </RFRByIdSecondStepDiv>
  );
};

const CachedRFRByIdSecondStepComponent = memo(RFRByIdSecondStepComponent);

export { CachedRFRByIdSecondStepComponent as RFRByIdSecondStepComponent };
