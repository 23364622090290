import React from 'react';

import { R } from 'shipment-operations/repository';
import { useAppSelector } from 'app-wrapper/hooks';
import { UC } from 'shipment-operations/controllers';

import { CostComponent } from 'shipment-operations/view/components/ControlChargeTransportation';

const CostContainer = () => {
  const APCostPerUnit = useAppSelector(R.selectors.shipmentActiveCharge.getActiveChargeAPCostPerUnit);
  const ARCostPerUnit = useAppSelector(R.selectors.shipmentActiveCharge.getActiveChargeARCostPerUnit);
  const APNumberOfUnits = useAppSelector(R.selectors.shipmentActiveCharge.getActiveChargeAPNumberOfUnits);
  const ARNumberOfUnits = useAppSelector(R.selectors.shipmentActiveCharge.getActiveChargeARNumberOfUnits);

  const selectedChargeMeasure = useAppSelector(R.selectors.shipmentActiveCharge.getActiveChargeSelectedChargeMeasure);
  const selectedChargeType = useAppSelector(R.selectors.shipmentActiveCharge.getActiveChargeSelectedChargeType);
  const errors = useAppSelector(R.selectors.shipmentActiveCharge.getActiveChargeErrors);

  const isFilledInfo = useAppSelector(R.selectors.shipmentActiveCharge.isFilledInfo);

  return (
    <CostComponent
      APCostPerUnit={APCostPerUnit}
      APNumberOfUnits={APNumberOfUnits}
      onChangeInput={UC.shipmentActiveCharge.setActiveChargeValue}
      ARCostPerUnit={ARCostPerUnit}
      ARNumberOfUnits={ARNumberOfUnits}
      errors={errors}
      selectedChargeMeasure={selectedChargeMeasure}
      selectedChargeType={selectedChargeType}
      isFilledInfo={isFilledInfo}
    />
  );
};

export { CostContainer as Cost };
