import React from 'react';
import { useTranslation } from 'react-i18next';

import { ShipmentDrawerContent } from 'shipment-operations/constants';

import { UC } from 'shipment-operations/controllers';
import { useAppSelector } from 'app-wrapper/hooks';
import { R } from 'shipment-operations/repository';

import { FooterComponent } from 'shipment-operations/view/components/ControlChargeTransportation';

const FooterContainer = () => {
  const { t } = useTranslation();
  const isLoading = useAppSelector(R.selectors.shipmentActiveCharge.getActiveChargeIsLoadingCreate);
  const mode = useAppSelector(R.selectors.shipmentDrawer.getContentType);

  return (
    <FooterComponent
      onCancel={UC.shipmentDrawer.closeDrawer}
      onAction={mode === ShipmentDrawerContent.SHIPMENT_ACCESSORIAL_ADD_CHARGE ? UC.shipmentActiveCharge.addCharge : UC.shipmentActiveCharge.editCharge}
      isLoading={isLoading}
      actionName={mode === ShipmentDrawerContent.SHIPMENT_ACCESSORIAL_ADD_CHARGE ? t('addChargeMenu.add') : t('addChargeMenu.save')}
    />
  );
};

export { FooterContainer as Footer };
