import { AnyAction, createAsyncThunk, ThunkDispatch } from '@reduxjs/toolkit';

import { requestGet } from './requestGet';

export interface IAsyncThunkGetParams {
  urlGetParams?: Array<string>
  getParams?: { [key: string]: string }
  urlId?: string
}

export const asyncThunkGet = <ParamsType, ResultType = any>(
  typePrefix: string,
  url: string,
  afterUrlIdPrefix?: string,
  callbackFunc?: (dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
    resultRequest: Awaited<ResultType>, params: IAsyncThunkGetParams) => void,
) => createAsyncThunk(
    typePrefix,
    async (params: ParamsType & IAsyncThunkGetParams,
      { rejectWithValue, dispatch }): Promise<ResultType> => {
      const result = await requestGet<ResultType, ParamsType>({
        ...params,
        url,
        afterUrlIdPrefix,
      }, { rejectWithValue });

      if (callbackFunc) {
        callbackFunc(dispatch, result, params);
      }
      return result;
    },
  );
