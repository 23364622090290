import styled from 'styled-components';

import themesColors from 'app-wrapper/view/themes/themesColors';

export const OrganizationDataLoadingContainer = styled.div.attrs({
  'data-class': 'OrganizationDataLoadingContainer',
})`
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  width: 320px;
`;

export const CreateNewOrganizationContainer = styled.div.attrs({
  'data-class': 'CreateNewOrganizationContainer',
})`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  width: 320px;
`;

export const OrganizationDataContainer = styled.div.attrs({
  'data-class': 'OrganizationDataContainer',
})`
  display: grid;
  grid-template-columns: 40px 125px auto;
  grid-template-rows: 22px 20px auto;
  grid-template-areas:"OrganizationIconContainer OrganizationName OrganizationName"
  "OrganizationIconContainer OrganizationNumber OrganizationNumber"
  "OrganizationIconContainer OrganizationAccountManage OrganizationAccountManage";
  
  width: 320px;
`;

export const OrganizationIconContainer = styled.div.attrs({
  'data-class': 'OrganizationIconContainer',
})`
  grid-area: OrganizationIconContainer;
  width: 40px;
  height: 40px;
`;

export const OrganizationIconDefaultBG = styled.div.attrs({
  'data-class': 'OrganizationIconDefaultBG',
})`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border: 1px solid ${themesColors.primaryBranding1};
  border-radius: 50%;
  color: ${themesColors.primaryBranding5};
`;

export const OrganizationName = styled.div.attrs({
  'data-class': 'OrganizationName',
})`
  grid-area: OrganizationName;

  padding-left: 12px;
  width: 100%;
  height: 22px;

  font-weight: 700;
  font-size: 14px;
`;

export const OrganizationNumber = styled.div.attrs({
  'data-class': 'OrganizationNumber',
})`
  grid-area: OrganizationNumber;
  
  padding-left: 12px;
  width: 100%;
  height: 20px;
  
  font-weight: 400;
  font-size: 12px;
  color: ${themesColors.darkGrayMiddle};
`;

export const OrganizationAccountManage = styled.div.attrs({
  'data-class': 'OrganizationAccountManage',
})`
  grid-area: OrganizationAccountManage;
  margin-top: 8px;
  padding-left: 12px;
  width: 100%;
`;
