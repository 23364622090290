import { createSlice } from 'app-wrapper/createSlice';

import { ICreditNoteState } from 'shipment-operations/models/states';

const initialState: ICreditNoteState = {
  isLoading: false,
  isError: false,
  creditNote: null,
  items: [],
};

export const CreditNoteSlice = createSlice({
  name: 'creditNote',
  initialState,
  reducers: {
    setIsLoading: (state, { payload }) => ({
      ...state,
      isLoading: payload,
    }),
    setIsError: (state, { payload }) => ({
      ...state,
      isError: payload,
      isLoading: false,
    }),
    setCreditNote: (state, { payload }) => ({
      ...state,
      creditNote: payload,
      items: payload.items,
      isLoading: false,
    }),
  },
});

export const creditNoteActions = CreditNoteSlice.actions;
export const creditNoteReducers = CreditNoteSlice.reducer;
