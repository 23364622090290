import styled, { css } from 'styled-components';

export const textColorFirst = css`
  color: ${({ theme }) => theme.colors.characterBrandingTitle85};
`;

export const divFlex = css`
  display: flex;
  position: relative;
  ${textColorFirst}
`;

// Content

export const RCQPageDiv = styled.div.attrs({
  'data-class': 'RCQPageDiv',
})`
  ${divFlex}
  width: 100%;
  height: 100%;
  flex-direction: column;

  .anticon-arrow-left {
    font-size: 14px;
    svg {
      color: ${({ theme }) => theme.themesColors.characterTitleDot85};
    }
  }
  .ant-page-header {
    padding-left: 24px;
    border-bottom: 1px solid ${({ theme }) => theme.themesColors.neutralBranding3};
  }
  .PageHeader {
    height: 71px;
    .ant-page-header-heading-title {
      line-height: 28px;
    }
    .ant-page-header-content {
      padding-top: 0;
    }
  }
`;

export const RCQPageHeaderContentRightFirst = styled.div.attrs((props: {
  afterColor: string,
  textColor: string,
}) => ({
  afterColor: props.afterColor,
  textColor: props.textColor,
  'data-class': 'RCQPageHeaderContentRightFirst',
}))`
  ${divFlex}
  color: ${({ theme, textColor }) => (textColor || theme.themesColors.characterBrandingSuccess)};
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
  &::after {
    content: '';
    position: absolute;
    right: -13px;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 50%;
    background-color: ${({ theme, afterColor }) => (afterColor || theme.themesColors.neutralBranding1)};
    width: 6px;
    height: 6px;
  }
`;
