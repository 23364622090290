import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { R as userManagementR } from 'user-management/repository';
import { R } from 'shipment-operations/repository';
import { UC } from 'shipment-operations/controllers';
import { EOrganizationMemberRole } from 'user-management/constants';

import { ConfirmedContentComponent } from './ConfirmedContent.component';

export const ConfirmedContent = React.memo(() => {
  const organization = useSelector(userManagementR.selectors.userOrganizationData.getUserOrganization);
  const isOriginPartner = useSelector(R.selectors.shipment.getIsOriginPartner);
  const isDestinationPartner = useSelector(R.selectors.shipment.getIsDestinationPartner);

  const isCustomerOrg = useMemo(() => !!(organization && organization.role === EOrganizationMemberRole.CUSTOMER), [organization]);

  return (
    <ConfirmedContentComponent
      isCustomer={isCustomerOrg}
      isOriginPartner={isOriginPartner}
      isDestinationPartner={isDestinationPartner}
      openUploadFileDrawer={UC.shipmentDrawer.openSIFinalDocumentUploadDrawer}
    />
  );
});
