import React from 'react';

import { IPropsIcon } from 'app-wrapper/interfaces/icon';

export const PackageType = (props: IPropsIcon) => {
  const { width, height, fill } = props;
  return (
    <svg
      width={width || '14'}
      height={height || '13'}
      fill={fill || '#D1D7E2'}
      viewBox="0 0 14 13"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M13.1494 4.77387L11.9098 1.07943C11.7639 0.617622 11.3264 0.277344 10.816 0.277344H3.15978C2.64936 0.277344 2.21186 0.617622 2.06603 1.07943L0.826443 4.77387C0.777832 4.8954 0.777832 5.01693 0.777832 5.13845V11.5551C0.777832 12.2114 1.28825 12.7218 1.9445 12.7218H12.0556C12.6876 12.7218 13.2223 12.2114 13.2223 11.5551V5.13845C13.2223 5.01693 13.198 4.8954 13.1494 4.77387ZM11.9827 4.94401H7.58339V1.44401H10.816L11.9827 4.94401ZM3.15978 1.44401H6.41672V4.94401H1.99311L3.15978 1.44401ZM1.9445 11.5551V6.11068H12.0556V11.5551H1.9445Z" fill={fill} />
    </svg>
  );
};
