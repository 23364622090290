import React from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';
import { R as userManagementR } from 'user-management/repository';
import { UC } from 'shipment-operations/controllers';

import { ShipmentDocumentsAllComponent } from './ShipmentDocumentsAll.component';

const ShipmentDocumentsAllContainer = () => {
  const isLoading = useSelector(R.selectors.shipmentDocumentsAll.getIsLoading);
  const isInvoiceLoading = useSelector(R.selectors.shareInvoice.getIsLoading);
  const isRFQLoading = useSelector(R.selectors.shipmentDocumentsAll.getIsRFQLoading);
  const additionalDocumentsMap = useSelector(R.selectors.shipmentDocumentsAll.getAdditionalDocumentsMap);
  const skypaceDownloadDocumentsMap = useSelector(R.selectors.shipmentDocumentsAll.getSkypaceDownloadDocumentsMap);
  const bookingConfirmationDocument = useSelector(R.selectors.shipmentDocumentsAll.getBookingConfirmationDocument);
  const quoteDocument = useSelector(R.selectors.shipmentDocumentsAll.getQuoteDocument);
  const payableInvoices = useSelector(R.selectors.shipmentDocumentsAll.getPayableInvoices);
  const receivableInvoices = useSelector(R.selectors.shipmentDocumentsAll.getReceivableInvoices);
  const documentHBL = useSelector(R.selectors.shipmentDocumentsAll.getDocumentHBL);
  const documentNRA = useSelector(R.selectors.shipmentDocumentsAll.getDocumentNRA);
  const documentNRABetweenContractOwnerAndAdmin = useSelector(R.selectors.shipmentDocumentsAll.getDocumentNRABetweenContractOwnerAndAdmin);
  const documentNRABetweenAdminAndAdmin = useSelector(R.selectors.shipmentDocumentsAll.getDocumentNRABetweenAdminAndAdmin);
  const documentSkypaceTerms = useSelector(R.selectors.shipmentDocumentsAll.documentSkypaceTerms);
  const carrierBookingConfirmation = useSelector(R.selectors.shipmentDocumentsAll.getCarrierBookingConfirmationDocument);
  const currentOrgRole = useSelector(userManagementR.selectors.userOrganizationData.getUserOrganisationRole);
  const permissions = useSelector(R.selectors.shipment.getDocumentsPermissions);
  const isHazmatDataLoading = useSelector(R.selectors.shipmentDocumentsHazmat.getIsHazHazmatIsLoading);
  const isSeaworthyDataLoading = useSelector(R.selectors.shipmentDocumentsSeaworthyCertificates.getIsLoading);

  return (
    <ShipmentDocumentsAllComponent
      isInvoiceLoading={isInvoiceLoading}
      isRFQLoading={isRFQLoading}
      isLoading={isLoading || isHazmatDataLoading || isSeaworthyDataLoading}
      currentOrgRole={currentOrgRole}
      fetchData={UC.shipmentDocumentsAll.initData}
      additionalDocumentsMap={additionalDocumentsMap}
      skypaceDownloadDocumentsMap={skypaceDownloadDocumentsMap}
      onDownload={UC.shipmentDocumentsAll.downloadDocument}
      onRemoveAdditional={UC.shipmentDocumentsAll.onRemoveAdditionalFile}
      onUploadAdditional={UC.shipmentDrawer.openAdditionalDocumentUploadDrawer}
      bookingConfirmationDocument={bookingConfirmationDocument}
      carrierBookingConfirmation={carrierBookingConfirmation}
      payableInvoices={payableInvoices}
      receivableInvoices={receivableInvoices}
      quoteDocument={quoteDocument}
      documentHBL={documentHBL}
      documentNRA={documentNRA}
      documentNRABetweenContractOwnerAndAdmin={documentNRABetweenContractOwnerAndAdmin}
      documentNRABetweenAdminAndAdmin={documentNRABetweenAdminAndAdmin}
      documentSkypaceTerms={documentSkypaceTerms}
      clean={UC.shipmentDocumentsAll.cleanup}
      viewInvoiceDocumentNameWithPrefix={permissions?.viewInvoiceDocumentNameWithPrefix}
    />
  );
};

export { ShipmentDocumentsAllContainer as ShipmentDocumentsAll };
