import styled from 'styled-components';

import AntdUpload from 'antd/es/upload';
import AntdButton from 'antd/es/button';

export const Button = styled(AntdButton).attrs((props: {
  hasError: boolean,
}) => ({
  hasError: props.hasError,
  'data-class': 'Button',
}))`
  width: 100%;
  font-size: 12px;
  box-shadow: none;
  color: ${({ theme }) => theme?.themesColors?.secondaryDot45};

  ${({ hasError, theme }) => hasError && `border-color: ${theme.themesColors.characterBrandingDanger} !important; border-style: solid;`};
`;

export const Upload = styled(AntdUpload).attrs(() => ({
  'data-class': 'Upload',
}))`
  width: 100%;
  display: flex;
  flex-direction: column;

  span.ant-upload-list-item-name {
    cursor: pointer;
  }
`;
