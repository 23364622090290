import React from 'react';

import { UC } from 'shipment-operations/controllers';
import { R } from 'shipment-operations/repository';
import { useAppSelector } from 'app-wrapper/hooks';

import { CreditNoteComponent } from './CreditNote.component';

const CreditNoteContainer = () => {
  const description = useAppSelector(R.selectors.creditNote.getDescription);
  const creditNote = useAppSelector(R.selectors.creditNote.getCreditNote);
  const isLoading = useAppSelector(R.selectors.creditNote.getIsLoading);
  const isError = useAppSelector(R.selectors.creditNote.getIsError);

  return (
    <CreditNoteComponent
      fetchData={UC.creditNote.loadData}
      description={description}
      creditNote={creditNote}
      isLoading={isLoading}
      isError={isError}
      cleanup={UC.creditNote.cleanup}
    />
  );
};

export { CreditNoteContainer as CreditNote };
