import React, { FC, useMemo } from 'react';

import { useAppSelector } from 'app-wrapper/hooks';
import { QUOTAS_STATUS, REQUEST_STATUS } from 'app-wrapper/constants';

import { R } from 'monetary/repository';

import { RFQContent } from './RFQContent';
import { RFQResponseDiv } from './RFQResponse.styled';

import { NoneContent } from './NoneContent';

interface IRFQResponse {
  NoneContentRecommended?: React.ReactNode
  foundText?: string
  isAllRequestPage?: boolean
  isFooter?: boolean
  isLoadingCompleteStep1?: boolean;
  isLoadingCompleteStep2?: boolean;
  isLoadingCompleteStep3?: boolean;
  isLoadingCompleteStep4?: boolean;
  hideBook?: boolean;
  hideFavorite?: boolean;
}

export const RFQResponse: FC<IRFQResponse> = (props) => {
  const {
    NoneContentRecommended,
    foundText,
    isAllRequestPage,
    isFooter,
    isLoadingCompleteStep1,
    isLoadingCompleteStep2,
    isLoadingCompleteStep3,
    isLoadingCompleteStep4,
    hideBook,
    hideFavorite,
  } = props;
  const getFreightQuoteCurrent = useAppSelector(R.selectors.freightQuote.getFreightQuoteCurrent);

  const { filters } = getFreightQuoteCurrent;

  const isQuotaNotPending = useMemo(() => getFreightQuoteCurrent.quotasCheckStatus?.status !== 'pending'
    && getFreightQuoteCurrent?.quotas?.quotasOption?.fullCompleteStatus !== QUOTAS_STATUS.pending
    && getFreightQuoteCurrent?.status !== 'pending', [getFreightQuoteCurrent?.quotas]);

  const isVisibleFilters = (
    filters?.isRequestFilters
    && (filters?.valuesFilters?.filterPrice?.isUpdate
      || filters?.valuesFilters?.filterTransitTime?.isUpdate
      || filters?.isIncludeRelatedPortRequest
      || filters?.valuesFilters?.filterCarrier?.isUpdate)
  );

  const isVisibleRFQContent = (
    isVisibleFilters
    || (getFreightQuoteCurrent.quotas?.data
      && isQuotaNotPending)
  )
    || (getFreightQuoteCurrent?.quotas?.allQuotas?.length
      || getFreightQuoteCurrent?.quotas?.quotasOption?.fullCompleteStatus === QUOTAS_STATUS.failed);

  const isLoading = (getFreightQuoteCurrent.status === REQUEST_STATUS.pending && getFreightQuoteCurrent?.quotasCheckStatus?.status !== QUOTAS_STATUS.complete);
  const isLoadingQuotas = getFreightQuoteCurrent.status === QUOTAS_STATUS.failed && getFreightQuoteCurrent?.quotas?.quotasOption?.fullCompleteStatus === QUOTAS_STATUS.complete;

  return (
    <RFQResponseDiv className="RFQResponse">
      {isVisibleRFQContent
        ? (
          <RFQContent
            isAllRequestPage={isAllRequestPage}
            isFooter={isFooter}
            foundText={foundText}
            NoneContentRecommended={NoneContentRecommended}
            hideBook={hideBook}
            hideFavorite={hideFavorite}
          />
        )
        : NoneContentRecommended || (
          <NoneContent
            isLoading={isLoading && !isLoadingCompleteStep4}
            isLoadingQuotas={isLoadingQuotas}
            isLoadingCompleteStep1={isLoadingCompleteStep1}
            isLoadingCompleteStep2={isLoadingCompleteStep2}
            isLoadingCompleteStep3={isLoadingCompleteStep3}
            isLoadingCompleteStep4={isLoadingCompleteStep4}
          />
        )}
    </RFQResponseDiv>
  );
};
