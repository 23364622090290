import styled from 'styled-components';
import AntTable from 'antd/es/table';
import AntTypography from 'antd/es/typography';
import AntTag from 'antd/es/tag';

export const Table = styled(AntTable)`
  .ant-table-content table {
    table-layout: fixed !important;
  }

  .ant-table-thead > tr > th {
    background: white;
  }

  .ant-table-thead > tr > th:first-of-type {
    padding-left: 0;
  }

  .ant-table-tbody > tr > td:first-of-type {
    padding-left: 0px;
    padding-top: 8px;
    vertical-align: top;
  }

  .ant-table-tbody > tr > td {
    padding: 8px;
  }

  .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    display: none;
  }

  .ant-table-thead .ant-table-cell {
    color: #73819B;
    font-size: 12px;
    line-height: 20px;
    font-weight: 400;
    padding: 8px;
  }

  .ant-table-tbody > tr:last-of-type > td {
    border-bottom: none;
  }

  .ant-table-cell:first-of-type {
    & > article {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;

export const ColumnText = styled(AntTypography)`
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
`;

export const RowTitle = styled(ColumnText)`
  color: #202C3C;
`;

export const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ColumnRow = styled.div`
  margin-top: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;

  :first-of-type {
    margin-top: 0;
  }
`;

export const ColumnKey = styled(ColumnText)`
  margin-right: 4px;
  color: #73819B;
`;

export const ColumnValue = styled(ColumnText)`
  color: #202C3C;
`;

export const Tag = styled(AntTag)`
  background-color: #F4F5F8;
  border: 1px solid #EAEEF8;
  color: #73819B;
  font-weight: 400;
  font-size: 11px;
  line-height: 18px;
`;
