import styled from 'styled-components';

import { Button } from 'app-wrapper/view/components';
import themesColors from 'app-wrapper/view/themes/themesColors';
import { divFlex } from 'app-wrapper/view/themes/mixin';

export const OrganizationDataWrapper = styled.div.attrs({
  'data-class': 'OrganizationDataWrapper',
})`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const OrganizationDataIcon = styled.div.attrs({
  'data-class': 'OrganizationDataIcon',
})`
  display: flex;
  align-items: stretch;
  justify-content: stretch;
  width: 160px;
  height: 80px;
  font-size: 14px;

  .ant-upload.ant-upload-drag .anticon-plus {
    color: ${themesColors.neutralBranding10};
  }

  span:not([role='img']) {
    width: 100%;
    height: 100%;
  };

  p {
    color: ${themesColors.darkGrayMiddle};
  }

  svg {
    width: 11px;
    height: 11px;
  }
`;

export const OrganizationDataTypeBox = styled.div.attrs({
  'data-class': 'OrganizationDataTypeBox',
})`
  ${divFlex}
  justify-content: center;
  align-items: center;

  margin-left: auto;
`;

export const OrganizationDataPrimaryContactIconBG = styled.div.attrs({
  'data-class': 'OrganizationDataPrimaryContactIconBG',
})`
  ${divFlex}
  align-items: center;
  justify-content: center;

  width: 32px;
  min-width: 32px;
  height: 32px;
  min-height: 32px;

  background-color: transparent;
  border-radius: 4px;
  color: ${({ theme }) => theme.themesColors.primaryBranding5};
  border: 1px solid ${({ theme }) => theme.themesColors.neutralBranding4};
  font-size: 14px;

  svg {
    color: ${({ theme }) => theme.themesColors.primaryBranding7};
  }
`;

export const OrganizationDataTypeBoxSecond = styled.div.attrs({
  'data-class': 'OrganizationDataTypeBoxSecond',
})`
  ${divFlex}
  flex-direction: column;

  margin-left: 16px;
`;

export const OrganizationDataTypeBoxTitle = styled.div.attrs({
  'data-class': 'OrganizationDataTypeBoxTitle',
})`
  ${divFlex}

  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
  color: ${({ theme }) => theme.themesColors.secondaryDot45};
`;

export const OrganizationDataTypeBoxName = styled.div.attrs({
  'data-class': 'OrganizationDataTypeBoxName',
})`
  ${divFlex}

  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: ${({ theme }) => theme.themesColors.neutralBranding10};
`;

export const OrganizationDataNameBox = styled.div.attrs({
  'data-class': 'OrganizationDataNameBox',
})`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: flex-start;
  height: 100%;
  margin-left: 16px;
`;

export const OrganizationDataName = styled.div.attrs({
  'data-class': 'OrganizationDataName',
})`
  font-size: 20px;
  line-height: 28px;
  font-weight: 500;
`;

export const OrganizationDataType = styled.div.attrs({
  'data-class': 'OrganizationDataType',
})`
  font-size: 14px;
  color: ${themesColors.darkGrayMiddle};
  line-height: 22px;
  font-weight: 400;
`;

export const OrganizationDataButton = styled(Button).attrs({
  'data-class': 'OrganizationDataButton',
})`
  margin-left: auto;
  margin-right: 0;
`;
