import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { UC } from 'user-management/controllers';
import { R } from 'user-management/repository';

import { AccountDepartmentEditMemberComponentComp } from './AccountDepartmentEditMemberComponent.component';

const AddressesAndContactsEditAddressAddressItemContainer: FC = () => {
  const department = useSelector(R.selectors.accountDepartments.getAccountDepartmentsUpdateDepartmentById);
  const memberList = useSelector(R.selectors.accountDepartments.getAccountDepartmentsDefaultMembersList);
  const getAccountDepartmentsUpdate = useSelector(R.selectors.accountDepartments.getAccountDepartmentsUpdate);

  return (
    <AccountDepartmentEditMemberComponentComp
      department={department}
      errorsEmail={getAccountDepartmentsUpdate?.errorsEditDepartmentDetailsDrawer?.email}
      errorsPhone={getAccountDepartmentsUpdate?.errorsEditDepartmentDetailsDrawer?.phone}
      memberList={memberList}
      onChangeEmail={UC.accountDepartments.onEditEmailAccountDepartmentEditDrawer}
      onBlurEmail={() => ''}
      onFocusEmail={() => ''}
      onChangePhone={UC.accountDepartments.onEditPhoneAccountDepartmentEditDrawer}
      onBlurPhone={() => ''}
      onFocusPhone={() => ''}
      onChangeMembers={UC.accountDepartments.onEditMembersAccountDepartmentEditDrawer}
    />
  );
};

export { AddressesAndContactsEditAddressAddressItemContainer as AddressesAndContactsEditAddressAddressItem };
