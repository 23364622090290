import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import notification from 'antd/es/notification';

import { CustomModal } from 'shipment-operations/view/components';

import { GapVerticalContainerStyled } from 'app-wrapper/view/guideline/GapVerticalContainer';
import { Header, Footer, Main } from './components';

interface IMakePaymentComponent {
  onClose: () => void
  isError: boolean
  onCloseError: () => void
}

const MakePaymentComponent: FC<IMakePaymentComponent> = ({ onClose, isError, onCloseError }) => {
  const { t } = useTranslation();
  const { invoiceId } = useParams<'invoiceId'>();

  useEffect(() => {
    if (isError) {
      notification.error({
        message: t('basicErrors.REQUEST_COMMON.title'),
        description: t('basicErrors.REQUEST_COMMON.subTitle'),
        placement: 'bottomRight',
        bottom: 60,
        onClose: onCloseError,
      });
    }
  }, [isError]);

  return (
    <CustomModal
      onClose={onClose}
      title={t('payment.title', { invoice: `INV-${invoiceId}` })}
      footer={<Footer />}
    >
      <GapVerticalContainerStyled>
        <Header />
        <Main />
      </GapVerticalContainerStyled>
    </CustomModal>
  );
};

export { MakePaymentComponent };
