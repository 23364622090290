import React, { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { validationEmail, validationPhone } from 'app-wrapper/utils';
import { OrganizationTradeReferenceDTM } from 'user-management/models/dtm';
import { Tooltip } from 'app-wrapper/view/components';

import {
  Content,
  Field,
  StyledInput,
  Label,
} from './TradeReferences.styled';

interface TradeReferencesComponentProps {
  tradeReference: OrganizationTradeReferenceDTM;
  shouldValidate: boolean;
  setCompanyName: (name: string) => void;
  setFirstName: (name: string) => void;
  setLastName: (name: string) => void;
  setEmail: (email: string) => void;
  setPhone: (phone: string) => void;
}

export const TradeReferencesComponent: FC<TradeReferencesComponentProps> = ({
  tradeReference,
  shouldValidate,
  setCompanyName,
  setEmail,
  setPhone,
  setFirstName,
  setLastName,
}) => {
  const { t } = useTranslation();
  const {
    companyName,
    firstName,
    lastName,
    phone,
    email,
  } = tradeReference;

  const handleCompanyChange = useCallback((e) => {
    setCompanyName(e.target.value);
  }, [setCompanyName]);

  const handleFirstNameChange = useCallback((e) => {
    setFirstName(e.target.value);
  }, [setFirstName]);

  const handleLastNameChange = useCallback((e) => {
    setLastName(e.target.value);
  }, [setLastName]);

  const handleEmailChange = useCallback((e) => {
    setEmail(e.target.value);
  }, [setEmail]);

  const handlePhoneChange = useCallback((e) => {
    setPhone(e.target.value);
  }, [setPhone]);

  const emailError = useMemo(() => {
    const message = validationEmail(email).errorMessage;

    if (shouldValidate && email && message) {
      return message;
    }

    return '';
  }, [shouldValidate, phone, email]);

  const phoneError = useMemo(() => {
    const message = validationPhone(phone).errorMessage;

    if (shouldValidate && phone && message) {
      return message;
    }

    return '';
  }, [shouldValidate, phone, email]);

  return (
    <Content>
      <Field>
        <Label>
          {t('Company Name')}
        </Label>

        <StyledInput
          error={shouldValidate && !companyName.length}
          value={companyName}
          onChange={handleCompanyChange}
        />
      </Field>

      <Field>
        <Label>
          {t('First Name')}
        </Label>

        <StyledInput
          error={shouldValidate && !firstName.length}
          value={firstName}
          onChange={handleFirstNameChange}
        />
      </Field>

      <Field>
        <Label>
          {t('Last Name')}
        </Label>

        <StyledInput
          value={lastName}
          error={shouldValidate && !lastName.length}
          onChange={handleLastNameChange}
        />
      </Field>

      <Field>
        <Label>
          {t('Email')}
        </Label>

        <Tooltip
          visible={!!emailError.length}
          title={emailError}
          mode="danger"
        >
          <StyledInput
            error={!!emailError.length || (shouldValidate && !phone && !email)}
            value={email}
            onChange={handleEmailChange}
          />
        </Tooltip>
      </Field>

      <Field>
        <Label>
          {t('Phone')}
        </Label>

        <Tooltip
          visible={!!phoneError.length}
          title={phoneError}
          mode="danger"
        >
          <StyledInput
            error={!!phoneError.length || (shouldValidate && !phone && !email)}
            onChange={handlePhoneChange}
            value={phone}
            type="number"
          />
        </Tooltip>
      </Field>
    </Content>
  );
};
