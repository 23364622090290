import React, { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';

import { AirVentFieldComponent } from './AirVentField.component';

const AirVentFieldContainer: FC = () => {
  const temperatureControl = useSelector(R.selectors.manualBookingWizard.getTemperatureControl);

  const flowRate = useMemo(() => (temperatureControl ? temperatureControl?.airVent?.flowRate : undefined), [temperatureControl]);
  const flowRateUnit = useMemo(() => (temperatureControl ? temperatureControl?.airVent?.flowRateUnit : undefined), [temperatureControl]);

  return (
    <AirVentFieldComponent
      flowRate={flowRate}
      flowRateUnit={flowRateUnit}
    />
  );
};

export {
  AirVentFieldContainer as AirVentField,
};
