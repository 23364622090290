import styled from 'styled-components';
import Table from 'antd/es/table';

interface ITableProps {
  isOpen?: boolean,
  active?: boolean,
}

interface IMainTableProps {
  shortVersion?: boolean
}

export const StyledTable = styled(Table)<IMainTableProps>`
  .ant-table-thead .ant-table-cell {
    padding-top: 8px;
    padding-bottom: 8px;
    border-bottom: 1px solid #EAEEF8;
    border-top: 1px solid #EAEEF8;
  }
  .ant-table-thead .ant-table-cell {
    color: #73819B;
    font-size: 12px;
    font-weight: 400;
  }
  .ant-table-thead > tr > th {
    background: white;
    border-bottom: none;
  }
  .ant-table-thead > tr:last-child > th:last-child {
    padding-right: 0;
  }
  .ant-table-container table > thead > tr:first-child th:last-child,
  .ant-table-tbody .ant-table-cell:last-child {
    padding-right: 0;
  }
  .ant-table-container table > thead > tr:first-child th:first-child {
    padding-left: 0;
  }
  .ant-table-container table > thead > tr:last-child th:first-child {
    padding-left: 0;
  }
  .ant-table-tbody > tr.ant-table-row:hover > td {
    background: none !important;
  }
  .ant-table-tbody > tr >  .ant-table-cell:first-child {
    padding: 0;
  }
  ${({ shortVersion }) => shortVersion && `
    .ant-table-thead > tr:first-child > th:last-child {
      border-bottom: none;
  }`}

  ${({ shortVersion }) => shortVersion && `
    .ant-table-thead > tr:first-child > th {
      border-top: none !important;
  }`}

  .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    display: none;
  }

  .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan]):nth-child(2)::before,
  .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan]):nth-child(6)::before,
  .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan]):nth-child(10)::before {
    background-color: #EBEEF7;
    display: block;
  }

  .ant-table-tbody tr .ant-table-cell:nth-child(2)::before,
  .ant-table-tbody tr .ant-table-cell:nth-child(10)::before,
  .ant-table-tbody tr .ant-table-cell:nth-child(6)::before {
    position: absolute;
    top: 20%;
    bottom: 20%;
    right: 0;
    width: 1px;
    background-color: #EBEEF7;
    transition: background-color 0.3s;
    content: '';
  }

  .ant-table-tbody > tr > td > .ant-table-wrapper:only-child .ant-table, .ant-table-tbody > tr > td > .ant-table-expanded-row-fixed > .ant-table-wrapper:only-child .ant-table {
    margin: -18px 0 -16px 0;
  }
`;

export const TableWithoutHeader = styled(StyledTable)`
  .ant-table-thead {
    visibility: collapse;
  }
  .ant-table-tbody > tr > .ant-table-cell:first-child {
    padding-top: 16px;
    padding-bottom: 16px;
  }
  .ant-table-expanded-row .ant-table-cell {
    background: white;
  }

  .ant-table-tbody > .ant-table-cell {
    padding-top: 16px;
    padding-bottom: 16px;
  }
`;

export const TableHeader = styled(Table)<IMainTableProps>`
  .ant-table-tbody {
    display: none;
  }
  .ant-table-thead .ant-table-cell {
    padding-top: 8px;
    padding-bottom: 8px;
    border-bottom: 1px solid #EAEEF8;
    border-top: 1px solid #EAEEF8;
    line-height: 22px !important;
  }
  .ant-table-thead .ant-table-cell {
    color: #73819B;
    font-size: 12px;
    font-weight: 400;
  }
  .ant-table-thead > tr > th {
    background: white;
    border-bottom: none;
  }
  .ant-table-container table > thead > tr:first-child th:last-child,
  .ant-table-tbody .ant-table-cell:last-child {
    padding-right: 0;
  }
  .ant-table-container table > thead > tr:first-child th:first-child {
    padding-left: 0;
  }
  .ant-table-container table > thead > tr:last-child th:first-child {
    padding-left: 0;
  }
  .ant-table-thead > tr > th:last-child {
    padding-right: 0 !important;
  }
  ${({ shortVersion }) => shortVersion && `
    .ant-table-thead > tr:first-child > th:last-child {
      border-bottom: none;
  }`}
  ${({ shortVersion }) => shortVersion && `
    .ant-table-thead > tr:first-child > th {
      border-top: none !important;
  }`}
  .ant-table-tbody > tr.ant-table-row:hover > td {
    background: none !important;
  }
  .ant-table-tbody > tr >  .ant-table-cell:first-child {
    padding-left: 16px;
  }

  .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    display: none;
  }

  .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan]):nth-child(2)::before,
  .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan]):nth-child(6)::before,
  .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan]):nth-child(10)::before {
    background-color: #EBEEF7;
    display: block;
  }

  .ant-table-tbody > tr > td > .ant-table-wrapper:only-child .ant-table, .ant-table-tbody > tr > td > .ant-table-expanded-row-fixed > .ant-table-wrapper:only-child .ant-table {
    margin: -18px 0 -16px 0;
  }
  .inner-table .ant-table-tbody > tr >  .ant-table-cell:first-child {
    padding-left: 20px;
  }
  .inner-table .ant-table-container table > thead > tr:first-child th:first-child {
    padding-left: 20px !important;
  }
`;

export const StyledType = styled.div<ITableProps>`
  text-decoration: ${({ active }) => !active && 'line-through'};
  cursor: pointer;
  display: flex;
  align-items: center;
  color: ${({ isOpen, active }) => {
    if (!active) {
      return '#D1D7E2';
    }
    if (isOpen) {
      return '#6C79DE';
    }
    return null;
  }}
`;

export const StyledBodyCell = styled.div<ITableProps>`
  text-decoration: ${({ active }) => !active && 'line-through'};
  color: ${({ isOpen, active }) => {
    if (!active) {
      return '#D1D7E2';
    }
    if (isOpen) {
      return '#73819B';
    }
    return null;
  }}
`;

export const FirstHeaderWrapper = styled.span`
  color: #202C3C;
  font-weight: 500;
  font-size: 16px;
  text-align: left;
  line-height: 12px;
  &:before {
    position: absolute;
    top: 20%;
    bottom: 20%;
    right: 0;
    width: 1px;
    background-color: #EBEEF7;
    transition: background-color 0.3s;
    content: '';
  }
`;

export const SubHeader = styled.span`
  font-size: 12px;
  font-weight: 500;
  line-height: 12px;
  color: #202C3C;
  &:before {
    position: absolute;
    top: 20%;
    bottom: 20%;
    right: 0;
    width: 1px;
    background-color: #EBEEF7;
    transition: background-color 0.3s;
    content: '';
  }
`;
