import React, { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'antd/es/modal';

import { OrganizationDTM } from 'user-management/models/dtm';

import {
  CustomersTable,
  TableCell,
  Row,
  DeleteIcon,
} from './TeamCustomers.styled';

interface ITeamCustomersComponentProps {
  customers: OrganizationDTM[];
  isCurrentUserAdmin: boolean;
  removeCustomer: (customerId: number) => void;
}

export const TeamCustomersComponent: FC<ITeamCustomersComponentProps> = ({
  customers,
  isCurrentUserAdmin,
  removeCustomer,
}) => {
  const { t } = useTranslation();

  const handleCustomerRemove = useCallback((customerId: number) => {
    Modal.confirm({
      title: t('Are you sure you want to remove this Customer?'),
      okText: t('Yes'),
      cancelText: t('No'),
      onOk: () => removeCustomer(customerId),
    });
  }, [removeCustomer, t]);

  const columns = useMemo(() => ([
    {
      title: t('Company'),
      dataIndex: 'company',
      key: 'company',
      width: '25%',
      render: (item: string, record: OrganizationDTM | {}) => {
        const organization = record as OrganizationDTM;

        return (
          <TableCell>
            {organization.name}
          </TableCell>
        );
      },
    },
    {
      title: t('Country'),
      dataIndex: 'country',
      key: 'country',
      width: '25%',
      render: (item: string, record: OrganizationDTM | {}) => {
        const organization = record as OrganizationDTM;

        return (
          <TableCell>
            {organization.address.country}
          </TableCell>
        );
      },
    },
    {
      title: t('City'),
      dataIndex: 'city',
      key: 'city',
      width: '25%',
      render: (item: string, record: OrganizationDTM | {}) => {
        const organization = record as OrganizationDTM;

        return (
          <TableCell>
            {organization.address.city}
          </TableCell>
        );
      },
    },
    {
      title: t('Phone'),
      dataIndex: 'phone',
      key: 'phone',
      width: '25%',
      render: (item: string, record: OrganizationDTM | {}) => {
        const organization = record as OrganizationDTM;

        return (
          <Row>
            <TableCell>
              {organization.phone || t('-')}
            </TableCell>

            {isCurrentUserAdmin ? (
              <DeleteIcon
                data-class="customer-delete-icon"
                onClick={() => handleCustomerRemove(organization.id)}
              />
            ) : null}
          </Row>
        );
      },
    },
  ]), [t]);

  return (
    <CustomersTable
      dataSource={customers}
      columns={columns}
      pagination={false}
    />
  );
};
