import { R } from 'monetary/repository';
import { UC } from 'monetary/controllers';
import React, {
  FC, memo,
} from 'react';
import { useSelector } from 'react-redux';
import { ContainersComponent } from './Containers.component';

interface IContainersComponentProps {
  formLocalState: { isCollapsed?: boolean }
  setFormLocalState: React.Dispatch<{ isCollapsed?: boolean }>
  forceCollapse?: boolean
}

const Containers: FC<IContainersComponentProps> = (({
  formLocalState,
  setFormLocalState,
  forceCollapse,
}) => {
  const {
    form,
    isSubmitVisited,
    isSubmitClicked,
    isAllFieldDisabled,
    isCollapse,
    RFRById,
  } = useSelector(R.selectors.freightQuote.getFreightQuoteCurrent);
  const getFreightQuoteCurrentFormContainersValues = RFRById?.params?.containers;
  const getErrors = useSelector(R.selectors.freightQuote.getFreightQuoteErrors);

  return (
    <ContainersComponent
      formLocalState={formLocalState}
      isAllDisabled={isAllFieldDisabled}
      setFormLocalState={setFormLocalState}
      forceCollapse={forceCollapse}
      isCollapsed={isCollapse}
      isMetric={form?.containers?.isMetric}
      socControl={form?.containers?.socControl}
      gensetControl={RFRById?.gensetControl}
      containersValues={getFreightQuoteCurrentFormContainersValues}
      isContainersError={getErrors?.containers?.isContainersError}
      isSubmitVisited={isSubmitVisited}
      isSubmitClicked={isSubmitClicked}
      temperatureControl={form?.commodity?.temperatureControl}
      onChangeContainersSocControl={UC.FreightQuote.onChangeContainersSocControl}
      // Type
      onChangeContainersType={UC.FreightQuote.onChangeContainersType}
      onClearContainersType={UC.FreightQuote.onClearContainersType}
      onFocusContainersType={UC.FreightQuote.onFocusContainersType}
      onBlurContainersType={UC.FreightQuote.onBlurContainersType}
    />
  );
});

const cachedContainers = memo(Containers);

export { cachedContainers as Containers };
