import React, { FC, useCallback } from 'react';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';

import { ShippingInstructionsStatusEnum } from 'shipment-operations/constants';
import { Spin } from 'shipment-operations/view/pages/ShipmentBillOfLading/components/HBLFlowManagement/components/ClearedContent/ClearedContent.styled';

import folderSrc from './folder.png';
import {
  Column,
  Content,
  Title,
  Text,
  Image,
  Button,
} from './SubmittedContent.styled';

const HIDDEN_BUTTON_STATUSES = [
  ShippingInstructionsStatusEnum.SI_SUBMITTED,
  ShippingInstructionsStatusEnum.SI_AMENDMENT_SUBMITTED,
];

interface ISubmittedContentComponentProps {
  siStatus: ShippingInstructionsStatusEnum;
  isLoading: boolean;
  isCustomer: boolean;
  hasMBLDraft: boolean;
  isOriginPartner: boolean;
  isDestinationPartner: boolean;
  approveDraftMBL: (shipmentId: string) => void;
  openReviewAndApproveEMBLDrawer: () => void;
}

export const SubmittedContentComponent: FC<ISubmittedContentComponentProps> = ({
  siStatus,
  isLoading,
  isCustomer,
  isOriginPartner,
  hasMBLDraft,
  isDestinationPartner,
  approveDraftMBL,
  openReviewAndApproveEMBLDrawer,
}) => {
  const { shipmentId = '' } = useParams();
  const { t } = useTranslation();

  const handleApproveDraftHBL = useCallback(() => {
    if (hasMBLDraft) {
      openReviewAndApproveEMBLDrawer();

      return;
    }

    approveDraftMBL(shipmentId);
  }, [shipmentId, approveDraftMBL, hasMBLDraft, openReviewAndApproveEMBLDrawer]);

  return (
    <Content>
      <Image
        src={folderSrc}
        alt=""
      />

      <Column>
        <Title>
          {isCustomer || isDestinationPartner ? t('Draft MBL has been sent to the carrier.') : null}
          {isOriginPartner ? (
            <>
              {hasMBLDraft ? t('Your draft eMBL is now available.') : t('Draft MBL has been sent to the carrier')}
            </>
          ) : null}
        </Title>

        <Text>
          {isCustomer || isDestinationPartner ? t('We have sent your draft MBL to the carrier for verification and confirmation. This process typically takes about 48 hours.') : null}
          {isOriginPartner ? (
            <>
              {hasMBLDraft
                ? t('We’ve received the draft version from the carrier. Please review it carefully and approve if there are no significant discrepancies. If you find any issues, please contact the carrier’s representative for an updated version.')
                : t('We anticipate receiving e-version approval from the carrier within the next 48 hours. If you already received the carrier’s draft outside the system, please verify it against the information below and approve it if no discrepancies are found.')}
            </>
          ) : null}
        </Text>
      </Column>

      {isOriginPartner && !HIDDEN_BUTTON_STATUSES.includes(siStatus) ? (
        <Button
          style={{
            minWidth: 92,
          }}
          onClick={!isLoading ? handleApproveDraftHBL : undefined}
        >
          {isLoading ? <Spin size="small" /> : (
            <>
              {hasMBLDraft ? t('Review & Approve') : t('Approve')}
            </>
          )}
        </Button>
      ) : null}
    </Content>
  );
};
