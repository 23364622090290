import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'app-wrapper/createSlice';

import { TransportPlanMissMatchesDTM, BookingMissMatchesDTM } from 'shipment-operations/models/dtm';
import { IShipmentMissMatchesState } from 'shipment-operations/models/states';

const initialState: IShipmentMissMatchesState = {
  transportPlanMissMatches: [],
  transportPlanBookingId: 0,
  cargoMissMatchesGroups: [],
  containerMissMatchesGroups: [],
  bookingId: 0,
  correctionsText: '',
};

export const shipmentMissMatchesStore = createSlice({
  name: 'shipmentMissMatchesStore',
  initialState,
  reducers: {
    setTransportMissMatches: (state, action: PayloadAction<TransportPlanMissMatchesDTM>) => ({
      ...state,
      transportPlanMissMatches: action.payload.missMatches,
      transportPlanBookingId: action.payload.id,
    }),
    setBookingMissMatches: (state, action: PayloadAction<BookingMissMatchesDTM>) => ({
      ...state,
      bookingId: action.payload.id,
      cargoMissMatchesGroups: action.payload.cargoMissMatches,
      containerMissMatchesGroups: action.payload.containerMissMatches,
    }),
    setCorrectionsText: (state, action: PayloadAction<string>) => ({
      ...state,
      correctionsText: action.payload,
    }),
    clearBookingMissMatches: (state) => ({
      ...state,
      cargoMissMatchesGroups: [...initialState.cargoMissMatchesGroups],
      containerMissMatchesGroups: [...initialState.containerMissMatchesGroups],
      bookingId: initialState.bookingId,
    }),
    clearTransportMissMatches: (state) => ({
      ...state,
      transportPlanMissMatches: [...initialState.transportPlanMissMatches],
      transportPlanBookingId: initialState.transportPlanBookingId,
    }),
  },
});

export const shipmentMissMatchesActions = shipmentMissMatchesStore.actions;
export const shipmentMissMatchesReducer = shipmentMissMatchesStore.reducer;
