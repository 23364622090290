import { FreightModeDTM, FreightLoadTypeDTM } from 'monetary/models/dtm/Quotas';
import {
  ContainerReeferTypes,
  ContainerReeferTypesNamesShortConst,
  ContainerUsualTypes,
  ContainerUsualTypesNamesShortConst,
} from 'shipment-operations/constants';
import i18n from 'app-wrapper/i18n/i18n';

export const FREIGHT_MODE_NAMES: Record<FreightModeDTM, string> = {
  SEA: i18n.t('Sea Freight'),
  ROAD: i18n.t('Road Freight'),
  AIR: i18n.t('Air Freight'),
};

export const LOAD_TYPE_NAMES: Record<FreightLoadTypeDTM, string> = {
  FCL: i18n.t('Full Container Load'),
  LCL: i18n.t('Less Container Load'),
  Breakbulk: '',
};

export const CONTAINER_TYPES_TO_NAME: Record<ContainerReeferTypes | ContainerUsualTypes, string> = {
  '22G0': ContainerUsualTypesNamesShortConst['22G0'],
  '42G0': ContainerUsualTypesNamesShortConst['42G0'],
  '45G0': ContainerUsualTypesNamesShortConst['45G0'],
  L5G0: ContainerUsualTypesNamesShortConst.L5G0,
  '22R0': ContainerReeferTypesNamesShortConst['22R0'],
  '42R0': ContainerReeferTypesNamesShortConst['42R0'],
  '25R1': ContainerReeferTypesNamesShortConst['25R1'],
  '45R1': ContainerReeferTypesNamesShortConst['45R1'],
  L5R1: ContainerReeferTypesNamesShortConst.L5R1,
};
