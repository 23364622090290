import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from 'app-wrapper/view/components';

import { Footer } from './TeamDrawerFooter.styled';

interface ITeamDrawerFooterComponentProps {
  onClose: () => void;
  onSave: () => void;
  isEditMode: boolean;
  isAbleToSave: boolean;
}

export const TeamDrawerFooterComponent: FC<ITeamDrawerFooterComponentProps> = ({
  onClose,
  onSave,
  isEditMode,
  isAbleToSave,
}) => {
  const { t } = useTranslation();

  return (
    <Footer>
      <Button type="default" onClick={onClose}>
        {t('Cancel')}
      </Button>

      <Button onClick={onSave} disabled={!isAbleToSave}>
        {isEditMode ? t('Save') : t('Add')}
      </Button>
    </Footer>
  );
};
