import {
  IsEnum,
  IsOptional,
} from 'class-validator';

import { EPermissionAttributePolicy } from 'monetary/constants';
import { BaseDTM } from 'proto/BaseDTM';

export type TRatesUploadAvailabilityDTM = {
  ratesUploadAvailability?: EPermissionAttributePolicy
  ratesClearCacheAvailability?: EPermissionAttributePolicy
}

export class RatesUploadAvailabilityDTM extends BaseDTM<TRatesUploadAvailabilityDTM> {
  @IsOptional()
  @IsEnum(EPermissionAttributePolicy)
  ratesUploadAvailability: EPermissionAttributePolicy

  @IsOptional()
  @IsEnum(EPermissionAttributePolicy)
  ratesClearCacheAvailability: EPermissionAttributePolicy
}
