import toNumber from 'lodash/fp/toNumber';
import { AxiosError } from 'axios';

import { apiWorker } from 'app-wrapper/repository/utilsServices';
import { ServerError } from 'app-wrapper/types/ServerError';

import { AirVentUnitsType } from 'shipment-operations/constants';
import {
  IPostTemperatureControlRequest,
  IPostTemperatureControlResponse,
  IPutTemperatureControlRequest,
  IPutTemperatureControlResponse,
  IGetTemperatureControlResponse,
} from 'shipment-operations/models/contracts';
import { TemperatureControlDTM } from 'shipment-operations/models/dtm';
import { AirVentDTM } from 'shipment-operations/models/dtm/AirVent.dtm';
import { NetworkErrorProto } from 'app-wrapper/models/errors';

export class TemperatureControlService {
  private base = '/shipment-service/api/v1/shipments';

  public getTemperatureControl = async (shipmentId: number) => {
    try {
      const rawResponse = await apiWorker.requestPost(`${this.base}/${shipmentId}/containers/has-temperature-type`);

      if (rawResponse.data) {
        return true;
      }
    } catch (e) {
      const error = e as AxiosError<ServerError>;
      const defaultMessage = 'getTemperatureControl getting error';

      if (error.status === 500) {
        throw NetworkErrorProto.fromPlain({
          message: defaultMessage,
          code: error?.response?.data?.code,
          status: error.status,
        });
      }

      if (error?.response?.data?.message || error?.response?.data?.details) {
        throw NetworkErrorProto.fromPlain({
          message: error?.response?.data?.message,
          status: error.status,
          details: error?.response?.data?.details,
          code: error?.response?.data?.code,
        });
      }

      throw NetworkErrorProto.fromPlain({
        message: defaultMessage,
        status: error.status,
        code: error?.response?.data?.code,
      });
    }

    return false;
  };

  public getTemperatureControlData = async (shipmentId: string) => {
    let result: TemperatureControlDTM | null = null;

    const rawResponse = await apiWorker.requestGet<IGetTemperatureControlResponse>(`${this.base}/${shipmentId}/temperature-control`);

    if (rawResponse.status === 204) {
      return result;
    }

    const response = rawResponse.data;

    result = TemperatureControlDTM.fromPlain({
      id: response.id,
      temperature: response.temperature ? `${response.temperature}` : '',
      airVent: AirVentDTM.fromPlain({
        flowRate: response.airVent ? `${response.airVent.flowRate}` : undefined,
        flowRateUnit: response.airVent ? response.airVent.flowRateUnit as AirVentUnitsType : undefined,
      }),
      genset: response.genset,
    });

    return result;
  }

  public putTemperatureControlData = async (shipmentId: string, data: TemperatureControlDTM) => {
    let result: TemperatureControlDTM | null = null;

    if (!data.id) {
      console.error(
        'TemperatureControlService.postTemperatureControlData error - no TemperatureControlDTM.id,'
        + ' add it or use TemperatureControlService.putTemperatureControlData',
      );

      return result;
    }

    const requestData: IPutTemperatureControlRequest = {
      genset: data.genset,
      temperature: toNumber(data.temperature),
      airVent: data.airVent.flowRate && data.airVent.flowRateUnit ? {
        flowRate: toNumber(data.airVent.flowRate),
        flowRateUnit: data.airVent.flowRateUnit as AirVentUnitsType,
      } : null,
    };

    try {
      const rawResponse = await apiWorker.requestPut<IPutTemperatureControlResponse>(`${this.base}/${shipmentId}/temperature-control`, requestData);
      const response = rawResponse.data;

      result = TemperatureControlDTM.fromPlain({
        id: response.id,
        temperature: response.temperature ? `${response.temperature}` : '',
        airVent: AirVentDTM.fromPlain({
          flowRate: response.airVent ? `${response.airVent.flowRate}` : undefined,
          flowRateUnit: response.airVent ? response.airVent.flowRateUnit as AirVentUnitsType : undefined,
        }),
        genset: response.genset,
      });
    } catch (e) {
      throw new Error('TemperatureControlData putting error');
    }

    return result;
  };

  public postTemperatureControlData = async (shipmentId: string, data: TemperatureControlDTM) => {
    let result: TemperatureControlDTM | null = null;

    const requestData: IPostTemperatureControlRequest = {
      genset: data.genset,
      temperature: toNumber(data.temperature),
      airVent: data.airVent.flowRate && data.airVent.flowRateUnit ? {
        flowRate: toNumber(data.airVent.flowRate),
        flowRateUnit: data.airVent.flowRateUnit as AirVentUnitsType,
      } : null,
    };

    try {
      const rawResponse = await apiWorker.requestPost<IPostTemperatureControlResponse>(`${this.base}/${shipmentId}/temperature-control`, requestData);
      const response = rawResponse.data;

      result = TemperatureControlDTM.fromPlain({
        id: response.id,
        temperature: response.temperature ? `${response.temperature}` : '',
        airVent: AirVentDTM.fromPlain({
          flowRate: response.airVent ? `${response.airVent.flowRate}` : undefined,
          flowRateUnit: response.airVent ? response.airVent.flowRateUnit as AirVentUnitsType : undefined,
        }),
        genset: response.genset,
      });
    } catch (e) {
      throw new Error('TemperatureControlData pushing error');
    }

    return result;
  };
}
