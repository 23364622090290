import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';
import { UC } from 'shipment-operations/controllers';
import { HsCodeInputComponent } from 'shipment-operations/view/components';

const CargoFormHsCodeInputContainer: FC = () => {
  const {
    code,
    errors,
    touchedFields,
    wasUpdateAttempted,
  } = useSelector(R.selectors.cargo.getCargo);
  const { commodities, isLoading, query } = useSelector(R.selectors.commodity.getCommodity);
  const temperatureControl = useSelector(R.selectors.cargo.getTemperatureControl);
  const permissions = useSelector(R.selectors.shipment.getCargosPermissions);

  const isError = !!(errors?.code || errors?.name) && (touchedFields.code || wasUpdateAttempted);

  return (
    <HsCodeInputComponent
      isQueryEmpty={!!query}
      dropdownMatchSelectWidth={400}
      isLoadingCommodities={isLoading}
      isTemperatureControlled={!!temperatureControl}
      isError={isError}
      commodities={commodities}
      hsCode={code}
      setHsCode={UC.cargo.setHsCode}
      touchField={UC.cargo.touchField}
      searchCommodities={UC.cargo.searchCommodities}
      cargosAvailability={permissions.cargosAvailability}
    />
  );
};

export {
  CargoFormHsCodeInputContainer as CargoFormHsCodeInput,
};
