import React, { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import {
  RCQLoadingFailed, SkypaceBgLogo2Svg,
} from 'app-wrapper/view/icons';
import { ExpandIcon } from 'app-wrapper/view/components';
import themesColors from 'app-wrapper/view/themes/themesColors';

import {
  RCQLoaderCompleteFollowContent,
  RCQLoaderCompleteFollowContentWrap, RCQLoaderCompleteFollowHeader, RCQLoaderCompleteFollowHeaderLogo, RCQLoaderCompleteFollowHeaderTitle, RCQLoaderCompleteFollowContentWrapImage, RCQLoaderCompleteFollowStep, RCQLoaderCompleteFollowStepDescription, RCQLoaderCompleteFollowStepLogo, RCQLoaderCompleteFollowWrap, RCQLoaderCompleteFollowStepLogoIcon, RCQLoaderCompleteFollowStepDescriptionIcon,
} from './RCQLoaderCompleteFollow.styled';

interface IRCQLoaderCompleteFollowComponentProps {
  nameLogo?: string;
  nameStep1?: string;
  nameStep2?: string;
  nameStep3?: string;
  nameStep4?: string;
  isLoadingFailedStep1?: boolean;
  isLoadingFailedStep2?: boolean;
  isLoadingFailedStep3?: boolean;
  isLoadingFailedStep4?: boolean;
}

const RCQLoaderCompleteFollowComponent = (props: IRCQLoaderCompleteFollowComponentProps) => {
  const { t } = useTranslation();
  const {
    nameLogo = t('To complete the request, follow these steps:'),
    nameStep1 = t('Check schedules'),
    nameStep2 = t('Upload rates or ask your partner'),
    nameStep3 = t('Pull quotes and check them'),
    nameStep4 = t('Submit quotes to the customer'),
    isLoadingFailedStep1,
    isLoadingFailedStep2,
    isLoadingFailedStep3,
    isLoadingFailedStep4,
  } = props;

  const getLoadingLogo = useCallback(
    (complete: boolean) => (complete ? null : (<RCQLoadingFailed />)),
    [],
  );

  return (
    <RCQLoaderCompleteFollowWrap>

      <RCQLoaderCompleteFollowContentWrapImage>
        <SkypaceBgLogo2Svg />
      </RCQLoaderCompleteFollowContentWrapImage>

      <RCQLoaderCompleteFollowContentWrap>
        <RCQLoaderCompleteFollowHeader>
          <RCQLoaderCompleteFollowHeaderLogo>
            <RCQLoaderCompleteFollowHeaderTitle>
              {nameLogo || ''}
            </RCQLoaderCompleteFollowHeaderTitle>
          </RCQLoaderCompleteFollowHeaderLogo>
        </RCQLoaderCompleteFollowHeader>

        <RCQLoaderCompleteFollowContent>
          <RCQLoaderCompleteFollowStep>
            <RCQLoaderCompleteFollowStepLogo>
              {!isLoadingFailedStep1 ? '1' : ''}
              <RCQLoaderCompleteFollowStepLogoIcon>
                {getLoadingLogo(!isLoadingFailedStep1)}
              </RCQLoaderCompleteFollowStepLogoIcon>
            </RCQLoaderCompleteFollowStepLogo>
            <RCQLoaderCompleteFollowStepDescription isLoading={isLoadingFailedStep1}>
              {nameStep1}
              <RCQLoaderCompleteFollowStepDescriptionIcon>
                <ExpandIcon color={themesColors.secondaryDot45} />
              </RCQLoaderCompleteFollowStepDescriptionIcon>
            </RCQLoaderCompleteFollowStepDescription>
          </RCQLoaderCompleteFollowStep>

          <RCQLoaderCompleteFollowStep marginTop="8px">
            <RCQLoaderCompleteFollowStepLogo>
              {!isLoadingFailedStep2 ? '2' : ''}
              <RCQLoaderCompleteFollowStepLogoIcon>
                {getLoadingLogo(!isLoadingFailedStep2)}
              </RCQLoaderCompleteFollowStepLogoIcon>
            </RCQLoaderCompleteFollowStepLogo>
            <RCQLoaderCompleteFollowStepDescription isLoading={isLoadingFailedStep2}>
              {nameStep2}
              <RCQLoaderCompleteFollowStepDescriptionIcon>
                <ExpandIcon color={themesColors.secondaryDot45} />
              </RCQLoaderCompleteFollowStepDescriptionIcon>
            </RCQLoaderCompleteFollowStepDescription>
          </RCQLoaderCompleteFollowStep>

          <RCQLoaderCompleteFollowStep marginTop="8px">
            <RCQLoaderCompleteFollowStepLogo>
              {!isLoadingFailedStep3 ? '3' : ''}
              <RCQLoaderCompleteFollowStepLogoIcon>
                {getLoadingLogo(!isLoadingFailedStep3)}
              </RCQLoaderCompleteFollowStepLogoIcon>
            </RCQLoaderCompleteFollowStepLogo>
            <RCQLoaderCompleteFollowStepDescription isLoading={isLoadingFailedStep3}>
              {nameStep3}
            </RCQLoaderCompleteFollowStepDescription>
          </RCQLoaderCompleteFollowStep>

          {nameStep4 && (
            <RCQLoaderCompleteFollowStep marginTop="8px">
              <RCQLoaderCompleteFollowStepLogo>
                {!isLoadingFailedStep4 ? '4' : ''}
                <RCQLoaderCompleteFollowStepLogoIcon>
                  {getLoadingLogo(!isLoadingFailedStep4)}
                </RCQLoaderCompleteFollowStepLogoIcon>
              </RCQLoaderCompleteFollowStepLogo>
              <RCQLoaderCompleteFollowStepDescription isLoading={isLoadingFailedStep3}>
                {nameStep4}
              </RCQLoaderCompleteFollowStepDescription>
            </RCQLoaderCompleteFollowStep>
          )}
        </RCQLoaderCompleteFollowContent>

      </RCQLoaderCompleteFollowContentWrap>
    </RCQLoaderCompleteFollowWrap>
  );
};

const RCQLoaderCompleteFollowComponentCache = memo(RCQLoaderCompleteFollowComponent);

export { RCQLoaderCompleteFollowComponentCache as RCQLoaderCompleteFollowComponent };
