import React, { FC } from 'react';
import Skeleton from 'antd/es/skeleton';
import { useTranslation } from 'react-i18next';

import {
  Section,
  Title,
} from './DocumentSection.styled';
import { ConfirmationDocument } from './components';

interface DocumentSectionComponentProps {
  isPending: boolean;
}

export const DocumentSectionComponent: FC<DocumentSectionComponentProps> = ({ isPending }) => {
  const { t } = useTranslation();

  return isPending ? <Skeleton /> : (
    <Section>
      <Title>
        {t('Booking Confirmation Document')}
      </Title>

      <ConfirmationDocument />
    </Section>
  );
};
