import React, { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  ICompanyAddressDTM,
} from 'user-management/models/dtm';

import { useWindowSize } from 'app-wrapper/hooks';
import { TypeSwitch } from 'app-wrapper/view/components';

import {
  AACEABlockButtonCancel,
  AACEABlockButtonRemove,
  AACEABlockButtonSave,
  AACEABlockContent,
  AACEABlockFooter,
  AACEABlockWrap,
} from './AddressesAndContactsEditAddress.styled';
import { AddressesAndContactsEditAddressAddressItem } from './components';

interface IAddressesAndContactsEditAddressComponentProps {
  companyListAddresses?: ICompanyAddressDTM[]
  companyListStateIndex: string
  isDisabledCancelBtn?: boolean
  isDisabledSaveBtn?: boolean
  isDisabledRemoveBtn?: boolean
  onRemoveClick: () => void
  onCancelClick: () => void
  onSaveClick: () => void
  onChangeAddressIsPrimary: (isPrimary: boolean) => void
}

const AddressesAndContactsEditAddressComponent: FC<IAddressesAndContactsEditAddressComponentProps> = (props) => {
  const {
    companyListAddresses,
    companyListStateIndex,
    isDisabledCancelBtn,
    isDisabledSaveBtn,
    isDisabledRemoveBtn,
    onChangeAddressIsPrimary,
    onRemoveClick,
    onCancelClick,
    onSaveClick,
  } = props;
  const { t } = useTranslation();
  const largeInput = useWindowSize().isFullMediaWidth;

  return (
    <AACEABlockWrap>
      <AACEABlockContent>

        {companyListAddresses?.map((addressItem, addressIndex) => (
          <AddressesAndContactsEditAddressAddressItem
            key={`AddressesAndContactsEditAddressAddressItem-${addressItem.customId}`}
            largeInput={largeInput}
            isFirst
            primaryComponent={(
              <TypeSwitch
                onChange={onChangeAddressIsPrimary}
                data-class="AddressesAndContactsEditAddressAddressItem"
                rightText={t('Primary')}
                checkedChildren="On"
                unCheckedChildren="Off"
                value={!!companyListAddresses?.[addressIndex].isPrimary}
                disabled={isDisabledRemoveBtn}
                disabledbg="true"
              />
            )}
            isLast={false}
            addressIndex={addressIndex}
            address={addressItem}
            companyListStateIndex={companyListStateIndex}
          />
        ))}

      </AACEABlockContent>

      <AACEABlockFooter>
        <AACEABlockButtonRemove
          onClick={onRemoveClick}
          disabled={isDisabledRemoveBtn}
        >
          {t('addChargeMenu.remove')}
        </AACEABlockButtonRemove>
        <AACEABlockButtonCancel
          onClick={onCancelClick}
          disabled={isDisabledCancelBtn}
        >
          {t('addChargeMenu.cancel')}
        </AACEABlockButtonCancel>
        <AACEABlockButtonSave
          onClick={onSaveClick}
          disabled={isDisabledSaveBtn}
        >
          {t('addChargeMenu.save')}
        </AACEABlockButtonSave>
      </AACEABlockFooter>
    </AACEABlockWrap>
  );
};

const AddressesAndContactsEditAddressComponentCached = memo(AddressesAndContactsEditAddressComponent);

export { AddressesAndContactsEditAddressComponentCached as AddressesAndContactsEditAddressComponent };
