import { createSelector } from 'reselect';

import { RootState } from 'app-wrapper/store';

const localState = (state: RootState) => state.userEditProfile;

const getLoading = createSelector(
  localState,
  (state) => state.isLoading,
);

const getFirstName = createSelector(
  localState,
  (state) => state.firstName,
);

const getFirstNameError = createSelector(
  localState,
  (state) => state.firstNameError,
);

const getLastName = createSelector(
  localState,
  (state) => state.lastName,
);

const getLastNameError = createSelector(
  localState,
  (state) => state.lastNameError,
);

const wasAnyFormFieldChanged = createSelector(
  localState,
  (state) => (
    !state.firstName
    || !state.lastName
    || !!state.firstNameError
    || !!state.lastNameError
  ),
);

export const userEditProfileSelectors = {
  getFirstName,
  getFirstNameError,
  getLastName,
  getLastNameError,
  getLoading,
  wasAnyFormFieldChanged,
};
