import React from 'react';
import { WarningOutlined } from '@ant-design/icons';
import notification from 'antd/es/notification';
import { ArgsProps } from 'antd/lib/notification';

export type NotificationProps = Omit<ArgsProps, 'className' | 'icon'>;

export const showWarning = (props: NotificationProps) => {
  notification.open({
    ...props,
    className: 'warning-notification',
    style: {
      border: '3px solid #FFE2DF',
      backgroundColor: '#FFF2F0',
    },
    icon: <WarningOutlined />,
  });
};
