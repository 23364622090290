import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';

import { TransportLegFormComponent } from './TransportLegForm.component';

interface TransportLegFormContainerProps {
  transportationId: number;
  index: number;
}

const TransportLegFormContainer: FC<TransportLegFormContainerProps> = ({ transportationId, index }) => {
  const transportation = useSelector(R.selectors.manualBookingWizard.getTransportationById(transportationId));
  const leg = useSelector(R.selectors.manualBookingWizard.getTransportLegById(transportation.transportLeg));

  return (
    <TransportLegFormComponent
      routeLeg={leg}
      transportation={transportation}
      index={index}
    />
  );
};

export { TransportLegFormContainer as TransportLegForm };
