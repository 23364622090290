import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Wrapper,
  Text,
  InputStyled,
} from './CarrierReferenceNumber.styled';

interface CarrierReferenceNumberComponentProps {
  referenceNumber: string;
  setReferenceNumber: (value: string) => void;
  error?: boolean;
  disabled?: boolean;
}

export const CarrierReferenceNumberComponent: FC<CarrierReferenceNumberComponentProps> = ({
  referenceNumber,
  setReferenceNumber,
  error,
  disabled = false,
}) => {
  const { t } = useTranslation();

  const handleChange = useCallback((e) => {
    setReferenceNumber(e.target.value);
  }, [setReferenceNumber]);

  return (
    <Wrapper>
      <Text>
        {t('Carrier Reference Number')}
      </Text>

      <InputStyled
        data-testid="carrier-reference-number-testid"
        value={referenceNumber}
        onChange={handleChange}
        error={error}
        disabled={disabled}
      />
    </Wrapper>
  );
};
