import styled from 'styled-components';
import Typography from 'antd/es/typography';

export const Wrap = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Item = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

export const ItemTag = styled.div`
  min-width: 4px;
  min-height: 4px;
  margin-top: 9px;
  border-radius: 50%;
  background-color: rgba(32, 44, 60, 1);
  margin-right: 8px;
`;

export const ItemText = styled(Typography)`
  font-size: 14px;
  line-height: 22px;
`;

export const ItemTextColored = styled.span`
  margin: 0 3px;
  color: rgba(74, 85, 173, 1);
`;
