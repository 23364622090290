import { IGetAssetManifestResponse } from 'app-wrapper/models/contracts/IGetAssetManifestResponse.contract';
import { apiWorker } from 'app-wrapper/repository/utilsServices';
import sha256 from 'crypto-js/sha256';

export class AssetManifestService {
  public getAssetManifestHash = async (origin: string) => {
    const rawResponse = await apiWorker.requestGet<IGetAssetManifestResponse>('/asset-manifest.json',
      {
        baseURL: new URL(origin).toString(),
      });

    const result = sha256(JSON.stringify(rawResponse.data)).toString();

    return result;
  }
}
