import message from 'antd/es/message';
import { RouteNames } from 'app-wrapper/constants';
import { hasAccess } from 'app-wrapper/utils';

import { R as AuthR } from 'authentication/repository';
import { BaseController, controller } from 'proto/BaseController';
import { useSelector } from 'react-redux';
import { PermissionAttributePolicy } from 'shipment-operations/constants';

import { OrganizationDTM, OrganizationMemberDTM } from 'user-management/models/dtm';
import { R } from 'user-management/repository';
import { EAccountDrawerContent, EOrganizationMemberRole } from 'user-management/constants';
import i18n from 'app-wrapper/i18n/i18n';

@controller
export class UserManagementController extends BaseController {
  public initUserManagementProfile = async (email: string) => {
    this.dispatch(R.actions.userManagement.setIsLoading(true));

    const currentUserEmail = AuthR.selectors.auth.getEmail(this.store.getState());
    const organization = await R.services.organization.getCurrentOrganization();

    if (organization) {
      const user = await R.services.organization.getOrganizationMember(organization?.id, email);
      const currentUser = await R.services.organization.getOrganizationMember(organization?.id, currentUserEmail);

      this.dispatch(R.actions.userOrganizationData.setCurrentOrganizationUserData(currentUser));
      this.dispatch(R.actions.userManagement.setUserProfile(user));
      this.dispatch(R.actions.userManagement.setUserOrgId(organization?.id));
    }

    this.dispatch(R.actions.userManagement.setIsLoading(false));
  };

  public clearUserManagementMemberProfile = () => {
    this.dispatch(R.actions.userManagement.setUserList([]));
    this.dispatch(R.actions.userManagement.setUserOrgId(undefined));
    this.dispatch(R.actions.userManagement.setUserProfile(undefined));
  };

  public uploadData = async () => {
    this.dispatch(R.actions.accountDrawer.closeDrawer());

    let organization: OrganizationDTM | undefined;
    let organizationMembersList: OrganizationMemberDTM[];
    let organizationMember: OrganizationMemberDTM | undefined;

    this.dispatch(R.actions.userManagement.setIsLoading(true));

    try {
      organization = await R.services.organization.getCurrentOrganization();
    } catch (e) {
      console.error(e);

      return;
    }
    if (!organization) {
      return;
    }

    this.dispatch(R.actions.userOrganizationData.setUserOrganization(organization));

    try {
      organizationMembersList = await R.services.organization.getOrganizationMembersList(organization.id);
    } catch (e) {
      console.error(e);

      return;
    }
    if (!organizationMembersList) {
      return;
    }

    this.dispatch(R.actions.userManagement.setUserList(organizationMembersList));

    const userEmail = AuthR.selectors.auth.getEmail(this.store.getState());

    try {
      organizationMember = await R.services.organization.getOrganizationMember(organization.id, userEmail);
    } catch (e) {
      console.error(e);

      return;
    }
    if (!organization) {
      return;
    }

    this.dispatch(R.actions.userManagement.setActionValue({ state: 'deleteSuccess', value: false }));
    this.dispatch(R.actions.userManagement.setActionValue({ state: 'updateSuccess', value: false }));
    this.dispatch(R.actions.userOrganizationData.setCurrentOrganizationUserData(organizationMember));
    this.dispatch(R.actions.userManagement.setIsLoading(false));
  };

  public openUserInviteDrawer = async () => {
    this.dispatch(R.actions.accountDrawer.openDrawer(EAccountDrawerContent.USER_INVITE));

    this.dispatch(R.actions.userInvite.setLoading(true));

    let currentOrg = R.selectors.userOrganizationData.getUserOrganization(this.store.getState());

    if (!currentOrg) {
      currentOrg = await R.services.organization.getCurrentOrganization();
    }

    if (currentOrg) {
      const teams = await R.services.teams.getTeams(currentOrg.id);
      const departments = await R.services.accountDepartment.getOrganizationDepartments({
        organizationId: String(currentOrg.id),
      });

      this.dispatch(R.actions.userInvite.setAvailableDepartments(departments));
      this.dispatch(R.actions.userInvite.setAvailableTeams(teams));
      this.dispatch(R.actions.userInvite.setJobTitle(''));
    }

    this.dispatch(R.actions.userInvite.setLoading(false));
  }

  public openEditPanel = async (id: string) => {
    const users = R.selectors.userManagement.getUserList(this.store.getState());
    const matchedUser = users.find((item) => item.id === id);

    if (matchedUser) {
      await this.initAvailableTeamsAndDepartments(matchedUser);
    }

    this.dispatch(R.actions.accountDrawer.openDrawer(EAccountDrawerContent.USER_MANAGEMENT_EDIT));
    this.dispatch(R.actions.userManagement.setSelectedMemberId(id));
    this.dispatch(R.actions.userManagement.setJobTitle(matchedUser?.jobTitle || ''));
    this.dispatch(R.actions.userManagement.setSelectedMemberRole(matchedUser?.role));
  }

  public setTeams = (teams: string[]) => {
    this.dispatch(R.actions.userManagement.setTeams(teams));
  }

  public setDepartments = (departments: string[]) => {
    this.dispatch(R.actions.userManagement.setDepartments(departments));
    this.validateDepartments();
  };

  public setJobTitle = (jobTitle: string) => {
    this.dispatch(R.actions.userManagement.setJobTitle(jobTitle));
  }

  public validateDepartments = () => {
    const permissions = useSelector(R.selectors.moduleFunctionalityPermissions.getUserManagementProfilePermissions);

    if (!hasAccess(permissions?.userDepartmentsAndTeamsEdit, PermissionAttributePolicy.WRITE)) {
      this.dispatch(R.actions.userManagement.setDepartmentsError(false));

      return;
    }

    const departments = R.selectors.userManagement.getDepartments(this.store.getState());

    this.dispatch(R.actions.userManagement.setDepartmentsError(!departments.length));
  };

  public openEditMemberDrawer = async (member?: OrganizationMemberDTM) => {
    if (!member) {
      return;
    }

    await this.initAvailableTeamsAndDepartments(member);

    this.dispatch(R.actions.userManagement.setUserList([member]));
    this.dispatch(R.actions.userManagement.setSelectedMemberId(member.id));
    this.dispatch(R.actions.userManagement.setSelectedMemberRole(member?.role));
    this.dispatch(R.actions.userManagement.setJobTitle(member?.jobTitle || ''));
    this.dispatch(R.actions.accountDrawer.openDrawer(EAccountDrawerContent.USER_MANAGEMENT_EDIT));
  };

  public initAvailableTeamsAndDepartments = async (member: OrganizationMemberDTM) => {
    let currentOrg = R.selectors.userOrganizationData.getUserOrganization(this.store.getState());

    if (!currentOrg) {
      currentOrg = await R.services.organization.getCurrentOrganization();
    }

    if (currentOrg) {
      const teams = await R.services.teams.getTeams(currentOrg.id);
      const departments = await R.services.accountDepartment.getOrganizationDepartments({
        organizationId: String(currentOrg.id),
      });

      this.dispatch(R.actions.userManagement.setAvailableDepartments(departments));
      this.dispatch(R.actions.userManagement.setAvailableTeams(teams));
    }

    this.dispatch(R.actions.userManagement.setTeams(member ? member?.teams.map((team) => String(team.id)) : []));
    this.dispatch(R.actions.userManagement.setDepartments(member ? member?.departments.map((department) => String(department.id)) : []));
  }

  public onChangeRole = (role: string) => {
    this.dispatch(R.actions.userManagement.setSelectedMemberRole(role));
  }

  public closeEditPanel = () => {
    this.dispatch(R.actions.accountDrawer.closeDrawer());
  }

  public onEdit = async () => {
    this.dispatch(R.actions.userManagement.setActionValue({ state: 'updateLoading', value: true }));

    let updatedMember: OrganizationMemberDTM | undefined;
    const organization = R.selectors.userOrganizationData.getUserOrganization(this.store.getState());
    const users = R.selectors.userManagement.getUserList(this.store.getState());
    const id = R.selectors.userManagement.getSelectedMemberId(this.store.getState());
    const role = R.selectors.userManagement.getSelectedMemberRole(this.store.getState());
    const matchedUser = users.find((item) => item.id === id);
    const departments = R.selectors.userManagement.getDepartments(this.store.getState());
    const jobTitle = R.selectors.userManagement.getJobTitle(this.store.getState());
    const availableDepartments = R.selectors.userManagement.getAvailableDepertments(this.store.getState());

    this.validateDepartments();

    const departmentsError = R.selectors.userManagement.getDepartmentsError(this.store.getState());

    if (!matchedUser || departmentsError) {
      this.dispatch(R.actions.userManagement.setActionValue({ state: 'updateLoading', value: false }));

      return null;
    }

    const body = OrganizationMemberDTM.fromPlain({
      ...matchedUser,
      jobTitle,
      role: role as EOrganizationMemberRole,
      departments: availableDepartments.filter((department) => departments.includes(String(department.id))),
    });

    try {
      if (organization && matchedUser) {
        updatedMember = await R.services.organization.putOrganizationMember(organization.id, matchedUser.email, body);
      }
    } catch (e) {
      this.dispatch(R.actions.userManagement.setActionValue({ state: 'updateLoading', value: false }));

      return this.dispatch(R.actions.userManagement.setActionValue({ state: 'updateError', value: true }));
    }

    this.dispatch(R.actions.userManagement.setActionValue({ state: 'updateLoading', value: false }));
    this.dispatch(R.actions.userManagement.setActionValue({ state: 'updateError', value: false }));
    this.dispatch(R.actions.userManagement.setActionValue({ state: 'updateSuccess', value: true }));
    message.success(i18n.t('User role successfully updated'));

    const shouldSetUpdatedMember = !!R.selectors.userManagement.getUserProfile(this.store.getState());

    if (shouldSetUpdatedMember && updatedMember) {
      this.dispatch(R.actions.userManagement.setUserProfile(updatedMember));
      this.closeEditPanel();
    }

    return null;
  }

  public closeEditError = () => {
    this.dispatch(R.actions.userManagement.setActionValue({ state: 'updateError', value: false }));
  }

  public closeDeleteError = () => {
    this.dispatch(R.actions.userManagement.setActionValue({ state: 'deleteError', value: false }));
  }

  public onDelete = async () => {
    this.dispatch(R.actions.userManagement.setActionValue({ state: 'deleteLoading', value: true }));
    const organization = R.selectors.userOrganizationData.getUserOrganization(this.store.getState());
    const users = R.selectors.userManagement.getUserList(this.store.getState());
    const id = R.selectors.userManagement.getSelectedMemberId(this.store.getState());
    const matchedUser = users.find((item) => item.id === id);

    try {
      if (organization && matchedUser) {
        await R.services.organization.deleteMember(organization.id, matchedUser.email);
      }
    } catch (e) {
      this.dispatch(R.actions.userManagement.setActionValue({ state: 'deleteLoading', value: false }));
      return this.dispatch(R.actions.userManagement.setActionValue({ state: 'deleteError', value: true }));
    }

    this.dispatch(R.actions.userManagement.setActionValue({ state: 'deleteLoading', value: false }));
    this.dispatch(R.actions.userManagement.setActionValue({ state: 'deleteError', value: false }));
    this.dispatch(R.actions.userManagement.setActionValue({ state: 'deleteSuccess', value: true }));

    this.navigate(RouteNames.USER_MANAGEMENT());
    message.success(i18n.t('User was deleted'));

    return null;
  }

  public clear = () => {
    this.dispatch(R.actions.userManagement.clear());
  }
}
