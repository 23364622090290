import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { R } from 'user-management/repository';

import { OrganizationFinancialAndLegalComponent } from './OrganizationFinancialAndLegal.component';

const OrganizationFinancialAndLegalContainer: FC = () => {
  const organization = useSelector(R.selectors.userOrganizationData.getUserOrganization);

  return (
    <OrganizationFinancialAndLegalComponent
      organization={organization}
    />
  );
};

export { OrganizationFinancialAndLegalContainer as OrganizationFinancialAndLegal };
