import React from 'react';
import { useSelector } from 'react-redux';

import { R } from 'app-wrapper/repository';
import { UC } from 'app-wrapper/controllers';

import { HomeComponent } from './Home.component';

const HomeContainer = () => {
  const isLoading = useSelector(R.selectors.home.getIsLoading);

  return (
    <HomeComponent
      isLoading={isLoading}
      init={UC.home.init}
    />
  );
};

export { HomeContainer as HomeComponent };
