import I18n from 'app-wrapper/i18n/i18n';
import { createSelector } from 'reselect';
import moment from 'moment-timezone';
import get from 'lodash/fp/get';
import isNull from 'lodash/fp/isNull';
import isUndefined from 'lodash/fp/isUndefined';
import isBoolean from 'lodash/fp/isBoolean';
import isNumber from 'lodash/fp/isNumber';
import isEqual from 'lodash/isEqual';
import i18next from 'i18next';
import { v4 as uuidv4 } from 'uuid';

import { RootState } from 'app-wrapper/store';
import {
  IChangesTable, ITransportPlanChanges, LoadPlanDTM, LocationDTM, RouteLegDTM, TransportPlanDTM,
} from 'shipment-operations/models/dtm';
import {
  AirVentUnitsNamesConst, CARGO, commonContainersTypesLong, CONTAINER,
  ContainerReeferTypesArray,
  CUTOFF,
  DOCUMENTATION,
  ETA,
  ETD,
  GENSET, LOAD_PLAN,
  ORIGIN_CONTAINER_TERMINAL,
  ORIGIN_CONTAINER_YARD,
  PORT,
  REFERENCES,
  SHIPMENT_PARTY,
  ShipmentBookingStatusEnum,
  SOC,
  TERMINAL_DATE,
  TERMINAL_NAME,
  TRANSPORTATION_PLAN, TYPE,
  VESSEL,
  VGM,
  VOYAGE,
} from 'shipment-operations/constants';
import { shipmentSelectors } from 'shipment-operations/repository/store/Shipment';
import { homeSelectors } from 'app-wrapper/repository/store/Home';

import { RFQServiceByIdContentRoutesTransportation } from 'monetary/models/dtm/Quotas';
import { PackageTypeNames, ReversePackageTypesCodes } from 'app-wrapper/types/PackageType';
import { MaerskCodes } from 'monetary/constants';

const timeFormat = 'D MMM YYYY, HH:mm';

const localState = (state: RootState) => state.shipmentChanges;

const getInttraNumber = createSelector(
  localState,
  (state) => state.inttraNumber,
);

const getLoading = createSelector(
  localState,
  (state) => state.isLoadingData,
);

const getCarrierNumber = createSelector(
  localState,
  (state) => state.carrierNumber,
);

const getDocument = createSelector(
  localState,
  (state) => state.document,
);

const getWithMismatches = createSelector(
  localState,
  (state) => state.withMismatches,
);

const getData = createSelector(
  localState,
  (state) => state.data,
);

const getActionLoading = createSelector(
  localState,
  (state) => state.actionLoading,
);

const getRejectLoading = createSelector(
  localState,
  (state) => state.rejectLoading,
);

const getIsChangedConfirm = createSelector(
  localState,
  (state) => state.isChangedConfirm,
);

const getTransportPlan = createSelector(
  getData,
  (data) => data.find((item) => item.relatesToType === TRANSPORTATION_PLAN),
);

const getMismatchedTransportPlan = createSelector(
  getData,
  (data) => data.find((item) => item.relatesToType === TRANSPORTATION_PLAN && item.mismatch),
);

const getChangedCutOffs = createSelector(
  localState,
  (state) => state.changedCutOffs,
);

const getChangedLocations = createSelector(
  localState,
  (state) => state.changedLocations,
);

const getChangedTransportPlan = createSelector(
  localState,
  (state) => state.changedTransportPlan,
);

const getCutoffsErrors = createSelector(
  localState,
  (state) => state.cutOffsErrors,
);

const getLocationsError = createSelector(
  localState,
  (state) => state.locationsErrors,
);

const getTransportPlanErrors = createSelector(
  localState,
  (state) => state.transportPlanErrors,
);

const getTransportPlanDatesErrors = createSelector(
  localState,
  (state) => state.transportPlanDatesErrors,
);

const calculateMismatch = (current?: string | number | LocationDTM | TransportPlanDTM | null | LoadPlanDTM[], mismatch?: string | number | LocationDTM | TransportPlanDTM | null | LoadPlanDTM[], isDate?: boolean, withMismatches?: boolean) => {
  if (mismatch || isNumber(mismatch)) {
    return isDate ? moment.parseZone(mismatch as string).format(timeFormat) : mismatch;
  }
  if (!withMismatches) {
    return isDate ? moment() : '';
  }
  if (isUndefined(mismatch) && current) {
    return isDate ? moment.parseZone(current as string).format(timeFormat) : current;
  }
  return isDate ? moment() : '';
};

const calculateSOCMismatch = (current?: string | number | LocationDTM | TransportPlanDTM | null, mismatch?: string | number | LocationDTM | TransportPlanDTM | null | LoadPlanDTM[]) => {
  if (isBoolean(mismatch)) {
    return mismatch ? i18next.t('yes') : i18next.t('no');
  }
  if (isUndefined(mismatch) && current) {
    return current;
  }
  return '-';
};

const getShipmentShort = createSelector(
  localState,
  (state) => state.shipmentShort,
);

const getWithValidationDrawer = createSelector(
  localState,
  (state) => state.validationDrawer,
);

const getBookingStatusCommon = createSelector(
  shipmentSelectors.getBookingStatus,
  getShipmentShort,
  (bookingStatus, shipmentShort) => shipmentShort?.bookingStatus || bookingStatus,
);

const getIsMaerskCommon = createSelector(
  shipmentSelectors.getIsMaersk,
  getShipmentShort,
  (isMaersk, shipmentShort) => (shipmentShort?.scac ? MaerskCodes.includes(shipmentShort?.scac) : isMaersk),
);

const getCargoTable = createSelector(
  getData,
  getWithMismatches,
  homeSelectors.getIsCustomer,
  getWithValidationDrawer,
  getBookingStatusCommon,
  getIsMaerskCommon,
  (data, withMismatches, isCustomer, withValidation, bookingStatus, isMaersk) => {
    const cargos = data.filter((item) => item.relatesToType === 'CARGO');

    const uniqCargoIds = cargos.reduce((acc: any, item: any) => (acc.includes(item.id) ? acc : [...acc, item.id]), []);

    return uniqCargoIds.reduce((acc: any, cur: any) => {
      const linkedCargos = cargos.filter((elem) => elem.id === cur);

      const descriptionConfirmed = linkedCargos.find((elem) => elem.field === 'DESCRIPTION')?.previous;
      const descriptionRequested = linkedCargos.find((elem) => elem.field === 'DESCRIPTION')?.current;
      const descriptionMismatch = linkedCargos.find((elem) => elem.field === 'DESCRIPTION' && elem.mismatch)?.mismatchesCurrent;
      const descriptionCarrierConfirmed = calculateMismatch(descriptionRequested, descriptionMismatch, false, withMismatches);

      const hsCodeConfirmed = linkedCargos.find((elem) => elem.field === 'HS_CODE')?.previous;
      const hsCodeRequested = linkedCargos.find((elem) => elem.field === 'HS_CODE')?.current;
      const hsCodeMismatch = linkedCargos.find((elem) => elem.field === 'HS_CODE' && elem.mismatch)?.mismatchesCurrent;
      const hsCodeCarrierConfirmed = calculateMismatch(hsCodeRequested, hsCodeMismatch, false, withMismatches);
      const isReviewCarrierRes = (bookingStatus === ShipmentBookingStatusEnum.BOOKING_AMENDMENT_SUBMITTED) && (withMismatches || isMaersk || withValidation);
      let result = [
        {
          title: '',
          key: uuidv4(),
          label: 'Description',
          isDate: false,
          confirmed: {
            name: 'Description',
            value: descriptionConfirmed,
          },
          requested: {
            name: 'Description',
            value: descriptionRequested,
          },
          carrierConfirmed: {
            name: 'Description',
            value: descriptionCarrierConfirmed,
          },
          isMismatch: !isUndefined(descriptionMismatch),
          isRemoved: !isNull(descriptionConfirmed) && isNull(descriptionRequested),
        },
        {
          title: '',
          key: uuidv4(),
          label: 'HS Code',
          isDate: false,
          confirmed: {
            name: 'HS Code',
            value: hsCodeConfirmed,
          },
          requested: {
            name: 'HS Code',
            value: hsCodeRequested,
          },
          carrierConfirmed: {
            name: 'HS Code',
            value: hsCodeCarrierConfirmed,
          },
          isMismatch: !isUndefined(hsCodeMismatch),
          isRemoved: !isNull(hsCodeConfirmed) && isNull(hsCodeRequested),
        }];
      if (bookingStatus !== ShipmentBookingStatusEnum.BOOKING_SUBMITTED && isCustomer) {
        result = result.filter((item) => (item.confirmed.value !== item.requested.value));
      }
      if (isReviewCarrierRes) {
        result = result.map((item) => ({
          ...item,
          requested: {
            name: item.requested.value === item.confirmed.value ? '' : item.requested.name,
            value: item.requested.value === item.confirmed.value ? '' : item.requested.value,
          },
        }));
      }
      if (result.length) {
        result[0].title = I18n.t('Cargo');
      }
      return [...acc, ...result];
    }, []);
  },
);

const shipmentPartiesFields = [
  { label: I18n.t('Company name'), field: 'COMPANY_NAME' },
  { label: I18n.t('Address 1'), field: 'ADDRESS_1' },
  { label: I18n.t('Address 2'), field: 'ADDRESS_2' },
  { label: I18n.t('Country'), field: 'ADDRESS_COUNTRY' },
  { label: I18n.t('State'), field: 'ADDRESS_STATE' },
  { label: I18n.t('City'), field: 'ADDRESS_CITY' },
  { label: I18n.t('Postal code'), field: 'ADDRESS_POSTAL_CODE' },
  { label: I18n.t('Full Name'), field: 'CONTACT_FULL_NAME' },
  { label: I18n.t('Phone'), field: 'CONTACT_PHONE' },
  { label: I18n.t('TaxID'), field: 'COMPANY_TAX_ID' },
];

const getShipmentPartiesTableBL = createSelector(
  getData,
  (data) => {
    const shipmentParties = data.filter((item) => item.relatesToType === SHIPMENT_PARTY);
    const uniqShipmentPartiesIds = shipmentParties.reduce((acc: (string | undefined)[], item) => (acc.includes(item.id) ? acc : [...acc, item.id]), []);
    return uniqShipmentPartiesIds.reduce((acc: any, cur) => {
      const linkedShipmentParties = shipmentParties.filter((elem) => elem.id === cur);

      const result = shipmentPartiesFields.map(({ label, field }) => {
        const confirmed = linkedShipmentParties.find((elem) => elem.field === field)?.previous;
        const requested = linkedShipmentParties.find((elem) => elem.field === field)?.current;
        return confirmed !== requested && {
          title: '',
          key: uuidv4(),
          label,
          isDate: false,
          confirmed: { name: label, value: confirmed },
          requested: { name: label, value: requested },
          carrierConfirmed: { name: label, value: '' },
        };
      });

      const filteredResult: IChangesTable[] = [];
      result.forEach((item) => (item ? filteredResult.push(item) : null));

      if (filteredResult.length && cur) {
        filteredResult[0].title = I18n.t(cur);
      }

      return [...acc, ...filteredResult];
    }, []);
  },
);

const cargoFields = [
  { label: I18n.t('Description'), field: 'DESCRIPTION' },
  { label: I18n.t('HS_CODE'), field: 'HS_CODE' },
  { label: I18n.t('Packaging'), field: 'PACKAGE_TYPE', mapValue: true },
  { label: I18n.t('Quantity'), field: 'PACKAGES_NUMBER' },
  { label: I18n.t('Weight'), field: 'WEIGHT' },
  { label: I18n.t('Volume'), field: 'VOLUME' },
];

const getCargoTableBL = createSelector(
  getData,
  (data) => {
    const cargos = data.filter((item) => item.relatesToType === CARGO);
    const uniqCargoIds = cargos.reduce((acc: (string | undefined)[], item) => (acc.includes(item.id) ? acc : [...acc, item.id]), []);
    return uniqCargoIds.reduce((acc: IChangesTable[], cur) => {
      const linkedCargos = cargos.filter((elem) => elem.id === cur);

      const result = cargoFields.map(({ label, field, mapValue }) => {
        const confirmed = linkedCargos.find((elem) => elem.field === field)?.previous;
        const requested = linkedCargos.find((elem) => elem.field === field)?.current;

        const confirmedValue = mapValue
          ? PackageTypeNames[ReversePackageTypesCodes[confirmed as keyof typeof ReversePackageTypesCodes]]
          : confirmed;

        const requestedValue = mapValue
          ? PackageTypeNames[ReversePackageTypesCodes[requested as keyof typeof ReversePackageTypesCodes]]
          : requested;

        return confirmedValue !== requestedValue && {
          title: '',
          key: uuidv4(),
          label,
          isDate: false,
          confirmed: { name: label, value: confirmedValue },
          requested: { name: label, value: requestedValue },
          carrierConfirmed: { name: label, value: '' },
        };
      });

      const filteredResult: IChangesTable[] = [];
      result.forEach((item) => (item ? filteredResult.push(item) : null));

      if (filteredResult.length) {
        filteredResult[0].title = I18n.t('Cargo');
      }

      return [...acc, ...filteredResult];
    }, []);
  },
);

const getCargoTableMismatch = createSelector(
  getData,
  (data) => {
    const cargos = data.filter((item) => item.relatesToType === 'CARGO' && item.mismatch);

    const uniqCargoIds = cargos.reduce((acc: (string | undefined)[], item) => (acc.includes(item.id) ? acc : [...acc, item.id]), []);

    return uniqCargoIds.reduce((acc: IChangesTable[], cur) => {
      const linkedCargos = cargos.filter((elem) => elem.id === cur);
      const description = linkedCargos.find((elem) => elem.field === 'DESCRIPTION');
      const hsCode = linkedCargos.find((elem) => elem.field === 'HS_CODE');
      let result: IChangesTable[] = [];
      if (description) {
        result = [
          {
            title: '',
            key: uuidv4(),
            label: 'Description',
            isDate: false,
            confirmed: {
              name: 'Description',
              value: description?.mismatchesPrevious,
            },
            requested: {
              name: 'Description',
              value: '',
            },
            carrierConfirmed: {
              name: 'Description',
              value: description?.mismatchesCurrent,
            },
            isMismatch: true,
          }];
      }
      if (hsCode) {
        result = [...result,
          {
            title: '',
            key: uuidv4(),
            label: 'HS Code',
            isDate: false,
            confirmed: {
              name: 'HS Code',
              value: hsCode?.mismatchesPrevious,
            },
            requested: {
              name: 'HS Code',
              value: '',
            },
            carrierConfirmed: {
              name: 'HS Code',
              value: hsCode?.mismatchesCurrent,
            },
            isMismatch: true,
          }];
      }
      if (result.length) {
        result[0].title = I18n.t('Cargo');
      }
      return [...acc, ...result];
    }, []);
  },
);

const getContainersTable = createSelector(
  getData,
  getWithMismatches,
  homeSelectors.getIsCustomer,
  getWithValidationDrawer,
  getBookingStatusCommon,
  getIsMaerskCommon,
  (data, withMismatches, isCustomer, withValidation, bookingStatus, isMaersk) => {
    const containers = data.filter((item) => item.relatesToType === 'CONTAINER');

    const uniqContainerIds = containers.reduce((acc: any, item: any) => (acc.includes(item.id) ? acc : [...acc, item.id]), []);

    return uniqContainerIds.reduce((acc: any, cur: any) => {
      const linkedContainers = containers.filter((elem) => elem.id === cur);
      const isReefer = ContainerReeferTypesArray.includes(cur);

      const quantityConfirmed = linkedContainers.find((elem) => elem.field === 'QUANTITY')?.previous;
      const quantityRequested = linkedContainers.find((elem) => elem.field === 'QUANTITY')?.current;
      const quantityMismatch = linkedContainers.find((elem) => elem.field === 'QUANTITY' && elem.mismatch)?.mismatchesCurrent;
      const quantityCarrierConfirmed = calculateMismatch(quantityRequested, quantityMismatch, false, withMismatches);

      const temperatureConfirmed = linkedContainers.find((elem) => elem.field === 'TEMPERATURE')?.previous;
      const temperatureRequested = linkedContainers.find((elem) => elem.field === 'TEMPERATURE')?.current;
      const temperatureMismatch = linkedContainers.find((elem) => elem.field === 'TEMPERATURE' && elem.mismatch)?.mismatchesCurrent;
      const temperatureCarrierConfirmed = calculateMismatch(temperatureRequested, temperatureMismatch, false, withMismatches);

      const flowRateConfirmed = linkedContainers.find((elem) => elem.field === 'FLOW_RATE')?.previous;
      const flowRateRequested = linkedContainers.find((elem) => elem.field === 'FLOW_RATE')?.current;
      const flowRateMismatch = linkedContainers.find((elem) => elem.field === 'FLOW_RATE' && elem.mismatch)?.mismatchesCurrent;
      const flowRateCarrierConfirmed = calculateMismatch(flowRateRequested, flowRateMismatch, false, withMismatches);

      const flowRateUnitConfirmed = linkedContainers.find((elem) => elem.field === 'FLOW_RATE_UNIT')?.previous;
      const flowRateUnitRequested = linkedContainers.find((elem) => elem.field === 'FLOW_RATE_UNIT')?.current;
      const flowRateUnitMismatch = linkedContainers.find((elem) => elem.field === 'FLOW_RATE_UNIT' && elem.mismatch)?.mismatchesCurrent;
      const flowRateUnitCarrierConfirmed = calculateMismatch(flowRateUnitRequested, flowRateUnitMismatch, false, withMismatches);

      let gensetConfirmed = linkedContainers.find((elem) => elem.field === GENSET)?.previous;
      if (isNull(gensetConfirmed)) {
        gensetConfirmed = '-' as string;
      } else {
        gensetConfirmed = gensetConfirmed ? i18next.t('yes') as string : i18next.t('no') as string;
      }
      let gensetRequested = linkedContainers.find((elem) => elem.field === GENSET)?.current;
      if (isNull(gensetRequested)) {
        gensetRequested = '-' as string;
      } else {
        gensetRequested = gensetRequested ? i18next.t('yes') as string : i18next.t('no') as string;
      }
      const gensetMismatch = linkedContainers.find((elem) => elem.field === GENSET && elem.mismatch)?.mismatchesCurrent;
      const gensetCarrierConfirmed = calculateSOCMismatch(gensetRequested, gensetMismatch);

      let socConfirmed = linkedContainers.find((elem) => elem.field === SOC)?.previous;
      if (isNull(socConfirmed)) {
        socConfirmed = '-' as string;
      } else {
        socConfirmed = socConfirmed ? i18next.t('yes') as string : i18next.t('no') as string;
      }
      let socRequested = linkedContainers.find((elem) => elem.field === SOC)?.current;
      if (isNull(socRequested)) {
        socRequested = '-' as string;
      } else {
        socRequested = socRequested ? i18next.t('yes') as string : i18next.t('no') as string;
      }
      const socMismatch = linkedContainers.find((elem) => elem.field === SOC && elem.mismatch)?.mismatchesCurrent;
      const socCarrierConfirmed = calculateSOCMismatch(socRequested, socMismatch);

      const isReviewCarrierRes = (bookingStatus === ShipmentBookingStatusEnum.BOOKING_AMENDMENT_SUBMITTED) && (withMismatches || isMaersk || withValidation);

      const requestedValue = () => {
        if (typeof quantityRequested === 'number' && typeof quantityConfirmed === 'number') {
          return quantityRequested - quantityConfirmed;
        }
        return '';
      };

      let result = [
        {
          title: '',
          key: uuidv4(),
          label: 'Quantity',
          isDate: false,
          confirmed: {
            name: 'Quantity',
            value: quantityConfirmed,
          },
          requested: {
            name: 'Quantity',
            value: requestedValue() as string | number | TransportPlanDTM | LocationDTM | null | undefined | LoadPlanDTM[],
          },
          carrierConfirmed: {
            name: 'Quantity',
            value: quantityCarrierConfirmed,
          },
          isMismatch: !isUndefined(quantityMismatch),
          isSameValue: quantityConfirmed === quantityRequested,
        },
        {
          title: '',
          key: uuidv4(),
          label: 'SOC',
          isDate: false,
          confirmed: {
            name: 'SOC',
            value: socConfirmed,
          },
          requested: {
            name: 'SOC',
            value: socRequested,
          },
          carrierConfirmed: {
            name: 'SOC',
            value: socCarrierConfirmed,
          },
          isMismatch: !isUndefined(socMismatch),
          isSameValue: socConfirmed === socRequested,
        }];
      if (isReefer) {
        result = [
          ...result,
          {
            title: '',
            key: uuidv4(),
            label: 'Genset',
            isDate: false,
            confirmed: {
              name: 'Genset',
              value: gensetConfirmed,
            },
            requested: {
              name: 'Genset',
              value: gensetRequested,
            },
            carrierConfirmed: {
              name: 'Genset',
              value: gensetCarrierConfirmed,
            },
            isMismatch: !isUndefined(gensetMismatch),
            isSameValue: gensetConfirmed === gensetRequested,
          },
          {
            title: '',
            key: uuidv4(),
            label: 'Temperature',
            isDate: false,
            confirmed: {
              name: 'Temperature',
              value: temperatureConfirmed,
            },
            requested: {
              name: 'Temperature',
              value: temperatureRequested,
            },
            carrierConfirmed: {
              name: 'Temperature',
              value: temperatureCarrierConfirmed,
            },
            isMismatch: !isUndefined(temperatureMismatch),
            isSameValue: temperatureConfirmed === temperatureRequested,
          },
          {
            title: '',
            key: uuidv4(),
            label: 'Flow Rate',
            isDate: false,
            confirmed: {
              name: 'Flow Rate',
              value: flowRateConfirmed,
            },
            requested: {
              name: 'Flow Rate',
              value: flowRateRequested,
            },
            carrierConfirmed: {
              name: 'Flow Rate',
              value: flowRateCarrierConfirmed,
            },
            isMismatch: !isUndefined(flowRateMismatch),
            isSameValue: flowRateConfirmed === flowRateRequested,
          },
          {
            title: '',
            key: uuidv4(),
            label: 'Flow Rate Unit',
            isDate: false,
            confirmed: {
              name: 'Flow Rate Unit',
              value: flowRateUnitConfirmed ? AirVentUnitsNamesConst[flowRateUnitConfirmed as keyof typeof AirVentUnitsNamesConst] : '',
            },
            requested: {
              name: 'Flow Rate Unit',
              value: flowRateUnitRequested ? AirVentUnitsNamesConst[flowRateUnitRequested as keyof typeof AirVentUnitsNamesConst] : '',
            },
            carrierConfirmed: {
              name: 'Flow Rate Unit',
              value: flowRateUnitCarrierConfirmed ? AirVentUnitsNamesConst[flowRateUnitCarrierConfirmed as keyof typeof AirVentUnitsNamesConst] : '',
            },
            isMismatch: !isUndefined(flowRateUnitMismatch),
            isSameValue: flowRateUnitConfirmed === flowRateUnitRequested,
          },
        ];
      }
      if (bookingStatus !== ShipmentBookingStatusEnum.BOOKING_SUBMITTED && isCustomer) {
        result = result.filter((item) => !item.isSameValue);
      }
      if (isReviewCarrierRes) {
        result = result.map((item) => {
          if (item.label === 'Quantity') {
            return {
              ...item,
              requested: {
                name: quantityRequested === quantityConfirmed ? '' : item.requested.name,
                value: quantityRequested === quantityConfirmed ? '' : item.requested.value,
              },
            };
          }
          return ({
            ...item,
            requested: {
              name: item.requested.value === item.confirmed.value ? '' : item.requested.name,
              value: item.requested.value === item.confirmed.value ? '' : item.requested.value,
            },
          });
        });
      }
      if (result.length) {
        result[0].title = cur;
      }
      return [...acc, ...result];
    }, []);
  },
);

const getContainersTableMismatch = createSelector(
  getData,
  (data) => {
    const containers = data.filter((item) => item.relatesToType === 'CONTAINER' && item.mismatch);

    const uniqContainerIds = containers.reduce((acc: any, item: any) => (acc.includes(item.id) ? acc : [...acc, item.id]), []);

    return uniqContainerIds.reduce((acc: any, cur: any) => {
      const linkedContainers = containers.filter((elem) => elem.id === cur);

      const quantity = linkedContainers.find((elem) => elem.field === 'QUANTITY');
      const temperature = linkedContainers.find((elem) => elem.field === 'TEMPERATURE');
      const flowRate = linkedContainers.find((elem) => elem.field === 'FLOW_RATE');
      const flowRateUnit = linkedContainers.find((elem) => elem.field === 'FLOW_RATE_UNIT');
      const genset = linkedContainers.find((elem) => elem.field === GENSET);
      let gensetConfirmed = genset?.mismatchesPrevious;
      if (isNull(gensetConfirmed)) {
        gensetConfirmed = '-' as string;
      } else {
        gensetConfirmed = gensetConfirmed ? i18next.t('yes') as string : i18next.t('no') as string;
      }
      let gensetCarrierConfirmed = genset?.mismatchesCurrent;
      if (isNull(gensetCarrierConfirmed)) {
        gensetCarrierConfirmed = '-' as string;
      } else {
        gensetCarrierConfirmed = gensetCarrierConfirmed ? i18next.t('yes') as string : i18next.t('no') as string;
      }

      const soc = linkedContainers.find((elem) => elem.field === SOC);
      let socConfirmed = soc?.mismatchesPrevious;
      if (isNull(socConfirmed)) {
        socConfirmed = '-' as string;
      } else {
        socConfirmed = socConfirmed ? i18next.t('yes') as string : i18next.t('no') as string;
      }
      let socCarrierConfirmed = soc?.mismatchesCurrent;
      if (isNull(socCarrierConfirmed)) {
        socCarrierConfirmed = '-' as string;
      } else {
        socCarrierConfirmed = socCarrierConfirmed ? i18next.t('yes') as string : i18next.t('no') as string;
      }

      let result: IChangesTable[] = [];
      if (quantity) {
        result = [...result,
          {
            title: '',
            key: uuidv4(),
            label: 'Quantity',
            isDate: false,
            confirmed: {
              name: 'Quantity',
              value: quantity?.mismatchesPrevious,
            },
            requested: {
              name: 'Quantity',
              value: '',
            },
            carrierConfirmed: {
              name: 'Quantity',
              value: quantity?.mismatchesCurrent,
            },
            isMismatch: true,
          }];
      }
      if (genset) {
        result = [...result,
          {
            title: '',
            key: uuidv4(),
            label: 'Genset',
            isDate: false,
            confirmed: {
              name: 'Genset',
              value: gensetConfirmed,
            },
            requested: {
              name: 'Genset',
              value: '',
            },
            carrierConfirmed: {
              name: 'Genset',
              value: gensetCarrierConfirmed,
            },
            isMismatch: true,
          }];
      }
      if (soc) {
        result = [...result,
          {
            title: '',
            key: uuidv4(),
            label: 'SOC',
            isDate: false,
            confirmed: {
              name: 'SOC',
              value: socConfirmed,
            },
            requested: {
              name: 'SOC',
              value: '',
            },
            carrierConfirmed: {
              name: 'SOC',
              value: socCarrierConfirmed,
            },
            isMismatch: true,
          }];
      }
      if (temperature) {
        result = [...result,
          {
            title: '',
            key: uuidv4(),
            label: 'Temperature',
            isDate: false,
            confirmed: {
              name: 'Temperature',
              value: temperature?.mismatchesPrevious,
            },
            requested: {
              name: 'Temperature',
              value: '',
            },
            carrierConfirmed: {
              name: 'Temperature',
              value: temperature?.mismatchesCurrent,
            },
            isMismatch: true,
          }];
      }
      if (flowRate) {
        result = [...result,
          {
            title: '',
            key: uuidv4(),
            label: 'Flow Rate',
            isDate: false,
            confirmed: {
              name: 'Flow Rate',
              value: flowRate?.mismatchesPrevious,
            },
            requested: {
              name: 'Flow Rate',
              value: '',
            },
            carrierConfirmed: {
              name: 'Flow Rate',
              value: flowRate?.mismatchesCurrent,
            },
            isMismatch: true,
          }];
      }
      if (flowRateUnit) {
        result = [...result,
          {
            title: '',
            key: uuidv4(),
            label: 'Flow Rate Unit',
            isDate: false,
            confirmed: {
              name: 'Flow Rate Unit',
              value: flowRateUnit?.mismatchesPrevious ? AirVentUnitsNamesConst[flowRateUnit?.mismatchesPrevious as keyof typeof AirVentUnitsNamesConst] : '',
            },
            requested: {
              name: 'Flow Rate Unit',
              value: '',
            },
            carrierConfirmed: {
              name: 'Flow Rate Unit',
              value: flowRateUnit?.mismatchesCurrent ? AirVentUnitsNamesConst[flowRateUnit?.mismatchesCurrent as keyof typeof AirVentUnitsNamesConst] : '',
            },
            isMismatch: true,
          }];
      }
      if (result.length) {
        result[0].title = cur;
      }
      return [...acc, ...result];
    }, []);
  },
);

const generateTableBlValue = (value: string | number | TransportPlanDTM | LocationDTM | null | undefined | boolean | LoadPlanDTM[]) => {
  if (typeof value === 'boolean') {
    return value ? i18next.t('yes') : i18next.t('no');
  }
  return value;
};

const getContainersTableBL = createSelector(
  getData,
  (data) => {
    const containers = data.filter((item) => item.relatesToType === CONTAINER);

    const uniqContainerIds = containers.reduce((acc: (string | undefined)[], item) => (acc.includes(item.id) ? acc : [...acc, item.id]), []);

    return uniqContainerIds.reduce((acc: IChangesTable[], cur) => {
      const linkedContainers = containers.filter((elem) => elem.id === cur);
      const linkedProperties = linkedContainers.map((elem) => elem.field);

      const parsedLinkedProperties = linkedProperties.filter((elem) => elem !== REFERENCES && elem !== LOAD_PLAN && elem !== TYPE);

      let result = parsedLinkedProperties.map((item) => {
        const confirmed = linkedContainers.find((elem) => elem.field === item)?.previous;
        const requested = linkedContainers.find((elem) => elem.field === item)?.current;
        return {
          title: '',
          key: uuidv4(),
          label: item,
          isDate: false,
          confirmed: {
            name: i18next.t(`${item}`),
            value: generateTableBlValue(confirmed),
          },
          requested: {
            name: i18next.t(`${item}`),
            value: generateTableBlValue(requested),
          },
          carrierConfirmed: {
            name: item,
            value: '',
          },
        };
      });

      const loadPlanCurrent = linkedContainers.find((elem) => elem.field === 'LOAD_PLAN')?.current as LoadPlanDTM[];
      const loadVolumeCurrent = loadPlanCurrent?.filter((elem) => elem.field === 'VOLUME').reduce((accum: number, elem: LoadPlanDTM) => accum + (elem.current as number), 0);
      const loadWeightCurrent = loadPlanCurrent?.filter((elem) => elem.field === 'WEIGHT').reduce((accum: number, elem: LoadPlanDTM) => accum + (elem.current as number), 0);
      const loadPackagesCurrent = loadPlanCurrent?.filter((elem) => elem.field === 'PACKAGES_NUMBER').reduce((accum: number, elem: LoadPlanDTM) => accum + (elem.current as number), 0);

      const loadPlanPrevious = linkedContainers.find((elem) => elem.field === 'LOAD_PLAN')?.previous as LoadPlanDTM[];
      const loadVolumePrevious = loadPlanPrevious?.filter((elem) => elem.field === 'VOLUME').reduce((accum: number, elem: LoadPlanDTM) => accum + (elem.previous as number), 0);
      const loadWeightPrevious = loadPlanPrevious?.filter((elem) => elem.field === 'WEIGHT').reduce((accum: number, elem: LoadPlanDTM) => accum + (elem.previous as number), 0);
      const loadPackagesPrevious = loadPlanPrevious?.filter((elem) => elem.field === 'PACKAGES_NUMBER').reduce((accum: number, elem: LoadPlanDTM) => accum + (elem.previous as number), 0);

      result = [
        ...result,
        {
          title: '',
          key: uuidv4(),
          label: 'Loaded Volume',
          isDate: false,
          confirmed: {
            name: 'Loaded Volume',
            value: loadVolumePrevious,
          },
          requested: {
            name: 'Loaded Volume',
            value: loadVolumeCurrent,
          },
          carrierConfirmed: {
            name: '',
            value: '',
          },
        },
        {
          title: '',
          key: uuidv4(),
          label: 'Loaded Weight',
          isDate: false,
          confirmed: {
            name: 'Loaded Weight',
            value: loadWeightPrevious,
          },
          requested: {
            name: 'Loaded Weight',
            value: loadWeightCurrent,
          },
          carrierConfirmed: {
            name: '',
            value: '',
          },
        },
        {
          title: '',
          key: uuidv4(),
          label: 'Loaded Packages',
          isDate: false,
          confirmed: {
            name: 'Loaded Packages',
            value: loadPackagesPrevious,
          },
          requested: {
            name: 'Loaded Packages',
            value: loadPackagesCurrent,
          },
          carrierConfirmed: {
            name: '',
            value: '',
          },
        },
      ];

      result = result.filter((item) => item);
      result = result.filter((item) => (item?.confirmed.value !== item?.requested.value));
      const type = linkedContainers.find((elem) => elem.field === 'TYPE')?.previous || linkedContainers.find((elem) => elem.field === 'TYPE')?.current;
      if (result.length && result[0]) {
        result[0].title = commonContainersTypesLong[type as keyof typeof commonContainersTypesLong];
      }

      return [...acc, ...result];
    }, []);
  },
);

const getEntryNumbers = createSelector(
  getData,
  (data) => {
    const selected = data.filter((item) => item.relatesToType === 'ENTRY_NUMBER');
    const type = selected.find((item) => item.field === 'TYPE');
    const number = selected.find((item) => item.field === 'NUMBER');

    return [{
      title: '',
      key: uuidv4(),
      label: 'Type',
      isDate: false,
      confirmed: {
        name: 'Type',
        value: type?.previous,
      },
      requested: {
        name: 'Type',
        value: type?.current,
      },
      carrierConfirmed: {
        name: '',
        value: '',
      },
    }, {
      title: 'Entry number',
      key: uuidv4(),
      label: 'Number',
      isDate: false,
      confirmed: {
        name: 'Number',
        value: number?.previous,
      },
      requested: {
        name: 'Number',
        value: number?.current,
      },
      carrierConfirmed: {
        name: '',
        value: '',
      },
    }].filter((item) => (item?.confirmed.value !== item?.requested.value));
  },
);

const getPaymentTerms = createSelector(
  getData,
  (data) => {
    const selected = data.filter((item) => item.relatesToType === 'PAYMENT_TERMS');
    const origin = selected.find((item) => item.field === 'ORIGIN');
    const freight = selected.find((item) => item.field === 'FREIGHT');
    const destination = selected.find((item) => item.field === 'DESTINATION');

    return [{
      title: 'Origin',
      key: uuidv4(),
      label: 'Origin',
      isDate: false,
      confirmed: {
        name: '',
        value: origin?.previous,
      },
      requested: {
        name: '',
        value: origin?.current,
      },
      carrierConfirmed: {
        name: '',
        value: '',
      },
    }, {
      title: 'Freight',
      key: uuidv4(),
      label: 'Freight',
      isDate: false,
      confirmed: {
        name: '',
        value: freight?.previous,
      },
      requested: {
        name: '',
        value: freight?.current,
      },
      carrierConfirmed: {
        name: '',
        value: '',
      },
    },
    {
      title: 'Destination',
      key: uuidv4(),
      label: 'Destination',
      isDate: false,
      confirmed: {
        name: '',
        value: destination?.previous,
      },
      requested: {
        name: '',
        value: destination?.current,
      },
      carrierConfirmed: {
        name: '',
        value: '',
      },
    }].filter((item) => (item?.confirmed.value !== item?.requested.value));
  },
);

const getBlOptions = createSelector(
  getData,
  (data) => {
    const selected = data.filter((item) => item.relatesToType === 'BILL_OF_LADING_OPTIONS');
    const type = selected.find((item) => item.field === 'TYPE');

    return [{
      title: 'Type',
      key: uuidv4(),
      label: 'Type',
      isDate: false,
      confirmed: {
        name: '',
        value: type?.previous,
      },
      requested: {
        name: '',
        value: type?.current,
      },
      carrierConfirmed: {
        name: '',
        value: '',
      },
    }].filter((item) => (item?.confirmed.value !== item?.requested.value));
  },
);

const getIsTransportPlanMismatches = createSelector(
  getData,
  (data) => {
    const transportPlan = data.find((item) => item.relatesToType === TRANSPORTATION_PLAN && item.mismatch);
    return !!transportPlan;
  },
);

const getTransportPlanCurrent = createSelector(
  getData,
  (data) => {
    const transportPlan = data.find((item) => item.relatesToType === TRANSPORTATION_PLAN && !item.mismatch);
    if (!transportPlan) {
      return [];
    }

    const currentLegs = get(['current', 'route', 'legs'], transportPlan);
    const currentTransportation = get(['current', 'transportations'], transportPlan);
    return currentLegs.reduce((acc: ITransportPlanChanges[], leg: RouteLegDTM | undefined, index: number) => {
      const matchedTransportation = currentTransportation.find((item: RFQServiceByIdContentRoutesTransportation) => item.transportLeg === leg?.id);
      const prevLeg = currentLegs[index - 1];
      const matchedPrevTransportation = currentTransportation.find((item: RFQServiceByIdContentRoutesTransportation) => item.transportLeg === prevLeg?.id);
      const isUSADeparture = leg?.departureLocation.country?.code === 'US';
      const isUSAArrival = leg?.arrivalLocation.country?.code === 'US';
      const departureLocation = isUSADeparture ? `${leg?.departureLocation.name}, ${leg?.departureLocation.state?.code}, ${leg?.departureLocation.country?.code}` : `${leg?.departureLocation.name}, ${leg?.departureLocation.country?.code}`;
      const arrivalLocation = isUSAArrival ? `${leg?.arrivalLocation.name}, ${leg?.arrivalLocation.state?.code}, ${leg?.arrivalLocation.country?.code}` : `${leg?.arrivalLocation.name}, ${leg?.arrivalLocation.country?.code}`;
      if (index !== currentLegs.length - 1) {
        const elem = {
          customId: uuidv4(),
          type: matchedTransportation.transport.type,
          location: departureLocation,
          nextLocation: arrivalLocation,
          etdTime: moment.parseZone(matchedTransportation.schedule.departureTime).format(timeFormat),
          etaTime: index ? moment.parseZone(matchedPrevTransportation.schedule.arrivalTime).format(timeFormat) : null,
          vessel: matchedTransportation.transport.name,
          voyage: matchedTransportation.voyageCode,
        };
        return [...acc, elem];
      }
      const elem = {
        customId: uuidv4(),
        type: matchedTransportation.transport.type,
        location: departureLocation,
        nextLocation: arrivalLocation,
        etdTime: moment.parseZone(matchedTransportation.schedule.departureTime).format(timeFormat),
        etaTime: index ? moment.parseZone(matchedPrevTransportation.schedule.arrivalTime).format(timeFormat) : null,
        vessel: matchedTransportation.transport.name,
        voyage: matchedTransportation.voyageCode,
      };
      const lastElement = {
        customId: uuidv4(),
        type: '',
        location: arrivalLocation,
        nextLocation: arrivalLocation,
        etdTime: '',
        etaTime: moment.parseZone(matchedTransportation.schedule.arrivalTime).format(timeFormat),
        vessel: '',
        voyage: '',
      };
      return [...acc, elem, lastElement];
    }, []);
  },
);

const getTransportPlanPrevious = createSelector(
  getData,
  (data) => {
    const transportPlan = data.find((item) => item.relatesToType === TRANSPORTATION_PLAN && !item.mismatch);
    if (!transportPlan) {
      return [];
    }

    const previousLegs = get(['previous', 'route', 'legs'], transportPlan);
    const previousTransportation = get(['previous', 'transportations'], transportPlan);
    return previousLegs.reduce((acc: ITransportPlanChanges[], leg: RouteLegDTM | undefined, index: number) => {
      const matchedTransportation = previousTransportation.find((item: RFQServiceByIdContentRoutesTransportation) => item.transportLeg === leg?.id);
      const prevLeg = previousLegs[index - 1];
      const matchedPrevTransportation = previousTransportation.find((item: RFQServiceByIdContentRoutesTransportation) => item.transportLeg === prevLeg?.id);
      const isUSADeparture = leg?.departureLocation.country?.code === 'US';
      const isUSAArrival = leg?.arrivalLocation.country?.code === 'US';
      const departureLocation = isUSADeparture ? `${leg?.departureLocation.name}, ${leg?.departureLocation.state?.code}, ${leg?.departureLocation.country?.code}` : `${leg?.departureLocation.name}, ${leg?.departureLocation.country?.code}`;
      const arrivalLocation = isUSAArrival ? `${leg?.arrivalLocation.name}, ${leg?.arrivalLocation.state?.code}, ${leg?.arrivalLocation.country?.code}` : `${leg?.arrivalLocation.name}, ${leg?.arrivalLocation.country?.code}`;
      if (index !== previousLegs.length - 1) {
        const elem = {
          customId: uuidv4(),
          type: matchedTransportation.transport.type,
          location: departureLocation,
          nextLocation: arrivalLocation,
          etdTime: moment.parseZone(matchedTransportation.schedule.departureTime).format(timeFormat),
          etaTime: index ? moment.parseZone(matchedPrevTransportation.schedule.arrivalTime).format(timeFormat) : null,
          vessel: matchedTransportation.transport.name,
          voyage: matchedTransportation.voyageCode,
        };
        return [...acc, elem];
      }
      const elem = {
        customId: uuidv4(),
        type: matchedTransportation.transport.type,
        location: departureLocation,
        nextLocation: arrivalLocation,
        etdTime: moment.parseZone(matchedTransportation.schedule.departureTime).format(timeFormat),
        etaTime: index ? moment.parseZone(matchedPrevTransportation.schedule.arrivalTime).format(timeFormat) : null,
        vessel: matchedTransportation.transport.name,
        voyage: matchedTransportation.voyageCode,
      };
      const lastElement = {
        customId: uuidv4(),
        type: '',
        location: arrivalLocation,
        nextLocation: arrivalLocation,
        etdTime: '',
        etaTime: moment.parseZone(matchedTransportation.schedule.arrivalTime).format(timeFormat),
        vessel: '',
        voyage: '',
      };
      return [...acc, elem, lastElement];
    }, []);
  },
);

const getTransportPlanMismatchCurrent = createSelector(
  getData,
  (data) => {
    const transportPlan = data.find((item) => item.relatesToType === TRANSPORTATION_PLAN && item.mismatch);
    if (!transportPlan) {
      return [];
    }

    const currentLegs = get(['mismatchesCurrent', 'route', 'legs'], transportPlan);
    const currentTransportation = get(['mismatchesCurrent', 'transportations'], transportPlan);
    return currentLegs.reduce((acc: ITransportPlanChanges[], leg: RouteLegDTM | undefined, index: number) => {
      const matchedTransportation = currentTransportation.find((item: RFQServiceByIdContentRoutesTransportation) => item.transportLeg === leg?.id);
      const prevLeg = currentLegs[index - 1];
      const matchedPrevTransportation = currentTransportation.find((item: RFQServiceByIdContentRoutesTransportation) => item.transportLeg === prevLeg?.id);
      const isUSADeparture = leg?.departureLocation.country?.code === 'US';
      const isUSAArrival = leg?.arrivalLocation.country?.code === 'US';
      const departureLocation = isUSADeparture ? `${leg?.departureLocation.name}, ${leg?.departureLocation.state?.code}, ${leg?.departureLocation.country?.code}` : `${leg?.departureLocation.name}, ${leg?.departureLocation.country?.code}`;
      const arrivalLocation = isUSAArrival ? `${leg?.arrivalLocation.name}, ${leg?.arrivalLocation.state?.code}, ${leg?.arrivalLocation.country?.code}` : `${leg?.arrivalLocation.name}, ${leg?.arrivalLocation.country?.code}`;
      if (index !== currentLegs.length - 1) {
        const elem = {
          customId: uuidv4(),
          type: matchedTransportation.transport.type,
          location: departureLocation,
          nextLocation: arrivalLocation,
          etdTime: moment.parseZone(matchedTransportation.schedule.departureTime).format(timeFormat),
          etaTime: index ? moment.parseZone(matchedPrevTransportation.schedule.arrivalTime).format(timeFormat) : null,
          vessel: matchedTransportation.transport.name,
          voyage: matchedTransportation.voyageCode,
        };
        return [...acc, elem];
      }
      const elem = {
        customId: uuidv4(),
        type: matchedTransportation.transport.type,
        location: departureLocation,
        nextLocation: arrivalLocation,
        etdTime: moment.parseZone(matchedTransportation.schedule.departureTime).format(timeFormat),
        etaTime: index ? moment.parseZone(matchedPrevTransportation.schedule.arrivalTime).format(timeFormat) : null,
        vessel: matchedTransportation.transport.name,
        voyage: matchedTransportation.voyageCode,
      };
      const lastElement = {
        customId: uuidv4(),
        type: '',
        location: arrivalLocation,
        nextLocation: arrivalLocation,
        etdTime: '',
        etaTime: moment.parseZone(matchedTransportation.schedule.arrivalTime).format(timeFormat),
        vessel: '',
        voyage: '',
      };
      return [...acc, elem, lastElement];
    }, []);
  },
);

const getTransportPlanMismatchPrevious = createSelector(
  getData,
  (data) => {
    const transportPlan = data.find((item) => item.relatesToType === TRANSPORTATION_PLAN && item.mismatch);
    if (!transportPlan) {
      return [];
    }

    const prevLegs = get(['mismatchesPrevious', 'route', 'legs'], transportPlan);
    const prevTransportation = get(['mismatchesPrevious', 'transportations'], transportPlan);
    return prevLegs.reduce((acc: ITransportPlanChanges[], leg: RouteLegDTM | undefined, index: number) => {
      const matchedTransportation = prevTransportation.find((item: RFQServiceByIdContentRoutesTransportation) => item.transportLeg === leg?.id);
      const prevLeg = prevLegs[index - 1];
      const matchedPrevTransportation = prevTransportation.find((item: RFQServiceByIdContentRoutesTransportation) => item.transportLeg === prevLeg?.id);
      const isUSADeparture = leg?.departureLocation.country?.code === 'US';
      const isUSAArrival = leg?.arrivalLocation.country?.code === 'US';
      const departureLocation = isUSADeparture ? `${leg?.departureLocation.name}, ${leg?.departureLocation.state?.code}, ${leg?.departureLocation.country?.code}` : `${leg?.departureLocation.name}, ${leg?.departureLocation.country?.code}`;
      const arrivalLocation = isUSAArrival ? `${leg?.arrivalLocation.name}, ${leg?.arrivalLocation.state?.code}, ${leg?.arrivalLocation.country?.code}` : `${leg?.arrivalLocation.name}, ${leg?.arrivalLocation.country?.code}`;
      if (index !== prevLegs.length - 1) {
        const elem = {
          customId: uuidv4(),
          type: matchedTransportation.transport.type,
          location: departureLocation,
          nextLocation: arrivalLocation,
          etdTime: moment.parseZone(matchedTransportation.schedule.departureTime).format(timeFormat),
          etaTime: index ? moment.parseZone(matchedPrevTransportation.schedule.arrivalTime).format(timeFormat) : null,
          vessel: matchedTransportation.transport.name,
          voyage: matchedTransportation.voyageCode,
        };
        return [...acc, elem];
      }
      const elem = {
        customId: uuidv4(),
        type: matchedTransportation.transport.type,
        location: departureLocation,
        nextLocation: arrivalLocation,
        etdTime: moment.parseZone(matchedTransportation.schedule.departureTime).format(timeFormat),
        etaTime: index ? moment.parseZone(matchedPrevTransportation.schedule.arrivalTime).format(timeFormat) : null,
        vessel: matchedTransportation.transport.name,
        voyage: matchedTransportation.voyageCode,
      };
      const lastElement = {
        customId: uuidv4(),
        type: '',
        location: arrivalLocation,
        nextLocation: arrivalLocation,
        etdTime: '',
        etaTime: moment.parseZone(matchedTransportation.schedule.arrivalTime).format(timeFormat),
        vessel: '',
        voyage: '',
      };
      return [...acc, elem, lastElement];
    }, []);
  },
);

const getTransportPlanTable = createSelector(
  getData,
  getWithMismatches,
  getTransportPlanErrors,
  getTransportPlanDatesErrors,
  homeSelectors.getIsCustomer,
  getBookingStatusCommon,
  getIsMaerskCommon,
  (data, withMismatches, errors, errorDates, isCustomer, bookingStatus, isMaersk) => {
    const transportPlan = data.find((item) => item.relatesToType === TRANSPORTATION_PLAN);
    if (!transportPlan) {
      return [];
    }

    const currentLegs = get(['current', 'route', 'legs'], transportPlan);
    const previousLegs = get(['previous', 'route', 'legs'], transportPlan);
    const currentTransportation = get(['current', 'transportations'], transportPlan);
    const previousTransportation = get(['previous', 'transportations'], transportPlan);
    return currentLegs.reduce((acc: any, leg: RouteLegDTM | undefined, index: number) => {
      const matchedPreviousLeg = previousLegs.find((prevLeg: RouteLegDTM) => leg?.departureLocation.code === prevLeg.departureLocation.code
        && leg?.arrivalLocation.code === prevLeg.arrivalLocation.code);
      const matchedPreviousTransportation = previousTransportation.find((item: RFQServiceByIdContentRoutesTransportation) => item.transportLeg === matchedPreviousLeg?.id);
      const matchedCurrentTransportation = currentTransportation.find((item: RFQServiceByIdContentRoutesTransportation) => item.transportLeg === leg?.id);

      const mismatchedVessel = data.find((item) => (item.field === VESSEL) && parseInt(item.id || '0', 10) === leg?.id)?.mismatchesCurrent;
      const mismatchedVoyage = data.find((item) => (item.field === VOYAGE) && parseInt(item.id || '0', 10) === leg?.id)?.mismatchesCurrent;
      const mismatchedETD = data.find((item) => (item.field === ETD) && parseInt(item.id || '0', 10) === leg?.id)?.mismatchesCurrent;
      const mismatchedETA = data.find((item) => (item.field === ETA) && parseInt(item.id || '0', 10) === leg?.id)?.mismatchesCurrent;

      const isReviewCarrierRes = (bookingStatus === ShipmentBookingStatusEnum.BOOKING_AMENDMENT_SUBMITTED) && (withMismatches || isMaersk);

      let elem = [
        {
          title: `Leg ${index + 1}`,
          key: uuidv4(),
          label: '',
          isDate: false,
          confirmed: {
            name: '',
            value: '',
          },
          requested: {
            name: '',
            value: '',
          },
          carrierConfirmed: '',
          isMismatch: false,
          emptyRender: true,
        },
        {
          title: '',
          confirmed: {
            name: 'Vessel',
            value: matchedPreviousTransportation?.transport?.name,
          },
          requested: {
            name: 'Vessel',
            value: matchedCurrentTransportation.transport.name,
          },
          carrierConfirmed: calculateMismatch(matchedCurrentTransportation.transport.name, mismatchedVessel, false, withMismatches),
          isDate: false,
          label: i18next.t('vessel'),
          fieldName: 'VESSEL',
          key: `${leg?.id}-VESSEL`,
          type: leg?.id,
          isMismatch: !isUndefined(mismatchedVessel),
          isError: false,
        },
        {
          title: '',
          confirmed: {
            name: 'Voyage',
            value: matchedPreviousTransportation?.voyageCode,
          },
          requested: {
            name: 'Voyage',
            value: matchedCurrentTransportation.voyageCode,
          },
          carrierConfirmed: calculateMismatch(matchedCurrentTransportation.transport.number, mismatchedVoyage, false, withMismatches),
          isDate: false,
          label: i18next.t('voyage'),
          fieldName: 'VOYAGE',
          key: `${leg?.id}-VOYAGE`,
          type: leg?.id,
          isMismatch: !isUndefined(mismatchedVoyage),
          isError: false,
        },
        {
          title: leg?.departureLocation.name,
          confirmed: {
            name: 'ETD',
            value: matchedPreviousTransportation ? moment.parseZone(matchedPreviousTransportation.schedule.departureTime).format(timeFormat) : '',
          },
          requested: {
            name: 'ETD',
            value: moment.parseZone(matchedCurrentTransportation.schedule.departureTime).format(timeFormat),
          },
          carrierConfirmed: calculateMismatch(matchedCurrentTransportation.schedule.departureTime, mismatchedETD, true, withMismatches),
          isDate: true,
          label: i18next.t('ETD'),
          fieldName: 'ETD',
          key: `${leg?.id}-ETD`,
          type: leg?.id,
          isMismatch: !isUndefined(mismatchedETD),
          isError: !!errors.find((item) => item.id === leg?.id && item.value === 'ETD'),
          datesError: leg?.id ? errorDates.includes(leg?.id) : false,
        },
        {
          title: leg?.arrivalLocation.name,
          confirmed: {
            name: 'ETA',
            value: matchedPreviousTransportation ? moment.parseZone(matchedPreviousTransportation.schedule.arrivalTime).format(timeFormat) : '',
          },
          requested: {
            name: 'ETA',
            value: moment.parseZone(matchedCurrentTransportation.schedule.arrivalTime).format(timeFormat),
          },
          carrierConfirmed: calculateMismatch(matchedCurrentTransportation.schedule.arrivalTime, mismatchedETA, true, withMismatches),
          isDate: true,
          label: i18next.t('ETA'),
          fieldName: 'ETA',
          key: `${leg?.id}-ETA`,
          type: leg?.id,
          isMismatch: !isUndefined(mismatchedETA),
          isError: !!errors.find((item) => item.id === leg?.id && item.value === 'ETA'),
        },
      ];
      if ((bookingStatus !== ShipmentBookingStatusEnum.BOOKING_SUBMITTED) && isCustomer) {
        elem = elem.filter((item, indexArr) => (indexArr === 0) || (item.confirmed.value !== item.requested.value));
      }
      if (isReviewCarrierRes) {
        elem = elem.map((item) => ({
          ...item,
          requested: {
            name: item.requested.value === item.confirmed.value ? '' : item.requested.name,
            value: item.requested.value === item.confirmed.value ? '' : item.requested.value,
          },
        }));
      }
      return elem.length > 1 ? [...acc, ...elem] : [...acc];
    }, []);
  },
);

const getTransportPlanTableMismatch = createSelector(
  getData,
  (data) => {
    const transportPlan = data.filter((item) => item.relatesToType === 'TRANSPORT_LEG' && item.mismatch);
    const uniqTransportIds = transportPlan?.reduce((acc: string[], item: any) => (acc.includes(item.id) ? acc : [...acc, item.id]), []);
    return uniqTransportIds.reduce((acc: IChangesTable[], cur: string, index) => {
      const linkedTransport = transportPlan.filter((elem) => elem.id === cur);
      const vessel = linkedTransport.find((elem) => elem.field === VESSEL);
      const voyage = linkedTransport.find((elem) => elem.field === VOYAGE);
      const etd = linkedTransport.find((elem) => elem.field === ETD);
      const eta = linkedTransport.find((elem) => elem.field === ETA);
      let result: IChangesTable[] = [];
      if (vessel) {
        result = [...result,
          {
            title: '',
            key: uuidv4(),
            label: 'Vessel',
            isDate: false,
            confirmed: {
              name: 'Vessel',
              value: vessel?.mismatchesPrevious,
            },
            requested: {
              name: 'Vessel',
              value: '',
            },
            carrierConfirmed: vessel?.mismatchesCurrent,
            isMismatch: true,
          }];
      }
      if (voyage) {
        result = [...result,
          {
            title: '',
            key: uuidv4(),
            label: 'Voyage',
            isDate: false,
            confirmed: {
              name: 'Voyage',
              value: voyage?.mismatchesPrevious,
            },
            requested: {
              name: 'Voyage',
              value: '',
            },
            carrierConfirmed: voyage?.mismatchesCurrent,
            isMismatch: true,
          }];
      }
      if (etd) {
        result = [...result,
          {
            title: etd?.location?.name || '',
            key: uuidv4(),
            label: 'ETD',
            isDate: true,
            confirmed: {
              name: 'ETD',
              value: etd?.mismatchesPrevious ? moment.parseZone(etd?.mismatchesPrevious as string).format(timeFormat) : moment(),
            },
            requested: {
              name: 'ETD',
              value: '',
            },
            carrierConfirmed: etd?.mismatchesCurrent ? moment.parseZone(etd?.mismatchesCurrent as string).format(timeFormat) : moment(),
            isMismatch: true,
          }];
      }
      if (eta) {
        result = [...result,
          {
            title: eta?.location?.name || '',
            key: uuidv4(),
            label: 'ETA',
            isDate: true,
            confirmed: {
              name: 'ETA',
              value: eta?.mismatchesPrevious ? moment.parseZone(eta?.mismatchesPrevious as string).format(timeFormat) : moment(),
            },
            requested: {
              name: 'ETA',
              value: '',
            },
            carrierConfirmed: eta?.mismatchesCurrent ? moment.parseZone(eta?.mismatchesCurrent as string).format(timeFormat) : moment(),
            isMismatch: true,
          }];
      }
      result = [
        {
          title: `Leg ${index + 1}`,
          key: uuidv4(),
          label: '',
          isDate: false,
          confirmed: {
            name: '',
            value: '',
          },
          requested: {
            name: '',
            value: '',
          },
          carrierConfirmed: {
            name: '',
            value: '',
          },
          isMismatch: false,
          emptyRender: true,
        },
        ...result,
      ];
      return [...acc, ...result];
    }, []);
  },
);

export const getCutOffTable = createSelector(
  getData,
  getWithMismatches,
  homeSelectors.getIsCustomer,
  getCutoffsErrors,
  getBookingStatusCommon,
  getIsMaerskCommon,
  (data, withMismatches, isCustomer, errors, bookingStatus, isMaersk) => {
    const portCutoff = data.find((item) => item.relatesToType === CUTOFF && item.field === PORT)?.previous;
    const vgmCutoff = data.find((item) => item.relatesToType === CUTOFF && item.field === VGM)?.previous;
    const docCutoff = data.find((item) => item.relatesToType === CUTOFF && item.field === DOCUMENTATION)?.previous;

    const portCutoffMismatch = data.find((item) => item.relatesToType === CUTOFF && item.field === PORT && item.mismatch)?.mismatchesCurrent;
    const vgmCutoffMismatch = data.find((item) => item.relatesToType === CUTOFF && item.field === VGM && item.mismatch)?.mismatchesCurrent;
    const docCutoffMismatch = data.find((item) => item.relatesToType === CUTOFF && item.field === DOCUMENTATION && item.mismatch)?.mismatchesCurrent;

    const portCutoffRequested = data.find((item) => item.relatesToType === CUTOFF && item.field === PORT)?.current;
    const vgmCutoffRequested = data.find((item) => item.relatesToType === CUTOFF && item.field === VGM)?.current;
    const docCutoffRequested = data.find((item) => item.relatesToType === CUTOFF && item.field === DOCUMENTATION)?.current;

    const isReviewCarrierRes = (bookingStatus === ShipmentBookingStatusEnum.BOOKING_AMENDMENT_SUBMITTED) && (withMismatches || isMaersk);

    let result = [
      {
        title: i18next.t('portCutOff'),
        confirmed: portCutoff ? moment.parseZone(portCutoff as string).format(timeFormat) : '',
        requested: portCutoffRequested ? moment.parseZone(portCutoffRequested as string).format(timeFormat) : '',
        carrierConfirmed: calculateMismatch(portCutoffRequested, portCutoffMismatch, true, withMismatches),
        key: 'PORT',
        fieldName: 'PORT',
        label: i18next.t('portCutoff'),
        isDate: true,
        isMismatch: !isUndefined(portCutoffMismatch),
        isError: errors.includes('PORT'),
      },
      {
        title: i18next.t('vgmCutoff'),
        confirmed: vgmCutoff ? moment.parseZone(vgmCutoff as string).format(timeFormat) : '',
        requested: vgmCutoffRequested ? moment.parseZone(vgmCutoffRequested as string).format(timeFormat) : '',
        carrierConfirmed: calculateMismatch(vgmCutoffRequested, vgmCutoffMismatch, true, withMismatches),
        key: 'VGM',
        fieldName: 'VGM',
        label: i18next.t('vgm'),
        isDate: true,
        isMismatch: !isUndefined(vgmCutoffMismatch),
        isError: errors.includes('VGM'),
      },
      {
        title: i18next.t('documentationCutoff'),
        confirmed: docCutoff ? moment.parseZone(docCutoff as string).format(timeFormat) : '',
        requested: docCutoffRequested ? moment.parseZone(docCutoffRequested as string).format(timeFormat) : '',
        carrierConfirmed: calculateMismatch(docCutoffRequested, docCutoffMismatch, true, withMismatches),
        key: 'DOCUMENTATION',
        fieldName: 'DOCUMENTATION',
        label: i18next.t('documentation'),
        isDate: true,
        isMismatch: !isUndefined(docCutoffMismatch),
        isError: errors.includes('DOCUMENTATION'),
      },
    ];

    if ((bookingStatus !== ShipmentBookingStatusEnum.BOOKING_SUBMITTED) && isCustomer) {
      result = result.filter((item) => (item.confirmed !== item.requested));
    }
    if (isReviewCarrierRes) {
      result = result.map((item) => ({
        ...item,
        requested: item.requested === item.confirmed ? '' : item.requested,
      }));
    }
    return result;
  },
);

export const getCutOffTableMismatch = createSelector(
  getData,
  getCutoffsErrors,
  (data, errors) => {
    const port = data.find((item) => item.relatesToType === CUTOFF && item.field === PORT && item.mismatch);
    const vgm = data.find((item) => item.relatesToType === CUTOFF && item.field === VGM && item.mismatch);
    const doc = data.find((item) => item.relatesToType === CUTOFF && item.field === DOCUMENTATION && item.mismatch);

    let result: IChangesTable[] = [];
    if (port) {
      result = [...result,
        {
          title: i18next.t('portCutOff'),
          confirmed: port?.mismatchesPrevious ? moment.parseZone(port?.mismatchesPrevious as string).format(timeFormat) : '',
          requested: '',
          carrierConfirmed: port?.mismatchesCurrent ? moment.parseZone(port?.mismatchesCurrent as string).format(timeFormat) : '',
          key: 'PORT',
          fieldName: 'PORT',
          label: i18next.t('portCutoff'),
          isDate: true,
          isMismatch: true,
          isError: errors.includes('PORT'),
        }];
    }
    if (vgm) {
      result = [...result,
        {
          title: i18next.t('VGM'),
          confirmed: vgm?.mismatchesPrevious ? moment.parseZone(vgm?.mismatchesPrevious as string).format(timeFormat) : '',
          requested: '',
          carrierConfirmed: vgm?.mismatchesCurrent ? moment.parseZone(vgm?.mismatchesCurrent as string).format(timeFormat) : '',
          key: 'VGM',
          fieldName: 'VGM',
          label: i18next.t('vgm'),
          isDate: true,
          isMismatch: true,
          isError: errors.includes('VGM'),
        }];
    }
    if (doc) {
      result = [...result,
        {
          title: i18next.t('Documentation'),
          confirmed: doc?.mismatchesPrevious ? moment.parseZone(doc?.mismatchesPrevious as string).format(timeFormat) : '',
          requested: '',
          carrierConfirmed: doc?.mismatchesCurrent ? moment.parseZone(doc?.mismatchesCurrent as string).format(timeFormat) : '',
          key: 'DOCUMENTATION',
          fieldName: 'DOCUMENTATION',
          label: i18next.t('documentation'),
          isDate: true,
          isMismatch: true,
          isError: errors.includes('DOCUMENTATION'),
        }];
    }
    return result;
  },
);

export const getLocationsTable = createSelector(
  getData,
  getWithMismatches,
  homeSelectors.getIsCustomer,
  getLocationsError,
  getBookingStatusCommon,
  getIsMaerskCommon,
  (data, withMismatches, isCustomer, errors, bookingStatus, isMaersk) => {
    const pickUpRelease = data.find((item) => item.relatesToType === ORIGIN_CONTAINER_YARD && item.field === TERMINAL_DATE)?.previous;
    const pickUpTerminal = data.find((item) => item.relatesToType === ORIGIN_CONTAINER_YARD && item.field === TERMINAL_NAME)?.previous;
    const pickUpPassCode = data.find((item) => item.relatesToType === ORIGIN_CONTAINER_YARD && item.field === 'PASSCODE')?.previous;
    const pickUpAddress1 = data.find((item) => item.relatesToType === ORIGIN_CONTAINER_YARD && item.field === 'ADDRESS_1')?.previous;
    const pickUpAddress2 = data.find((item) => item.relatesToType === ORIGIN_CONTAINER_YARD && item.field === 'ADDRESS_2')?.previous;
    const pickUpAddress3 = data.find((item) => item.relatesToType === ORIGIN_CONTAINER_YARD && item.field === 'ADDRESS_3')?.previous;
    const pickUpAddress4 = data.find((item) => item.relatesToType === ORIGIN_CONTAINER_YARD && item.field === 'ADDRESS_4')?.previous;
    const pickUpCity = data.find((item) => item.relatesToType === ORIGIN_CONTAINER_YARD && item.field === 'CITY')?.previous;
    const pickUpState = data.find((item) => item.relatesToType === ORIGIN_CONTAINER_YARD && item.field === 'STATE')?.previous;
    const pickUpZipcode = data.find((item) => item.relatesToType === ORIGIN_CONTAINER_YARD && item.field === 'ZIPCODE')?.previous;
    const pickUpCountry = data.find((item) => item.relatesToType === ORIGIN_CONTAINER_YARD && item.field === 'COUNTRY')?.previous;

    const pickUpReleaseRequested = data.find((item) => item.relatesToType === ORIGIN_CONTAINER_YARD && item.field === TERMINAL_DATE)?.current;
    const pickUpTerminalRequested = data.find((item) => item.relatesToType === ORIGIN_CONTAINER_YARD && item.field === TERMINAL_NAME)?.current;
    const pickUpPassCodeRequested = data.find((item) => item.relatesToType === ORIGIN_CONTAINER_YARD && item.field === 'PASSCODE')?.current;
    const pickUpAddress1Requested = data.find((item) => item.relatesToType === ORIGIN_CONTAINER_YARD && item.field === 'ADDRESS_1')?.current;
    const pickUpAddress2Requested = data.find((item) => item.relatesToType === ORIGIN_CONTAINER_YARD && item.field === 'ADDRESS_2')?.current;
    const pickUpAddress3Requested = data.find((item) => item.relatesToType === ORIGIN_CONTAINER_YARD && item.field === 'ADDRESS_3')?.current;
    const pickUpAddress4Requested = data.find((item) => item.relatesToType === ORIGIN_CONTAINER_YARD && item.field === 'ADDRESS_4')?.current;
    const pickUpCityRequested = data.find((item) => item.relatesToType === ORIGIN_CONTAINER_YARD && item.field === 'CITY')?.current;
    const pickUpStateRequested = data.find((item) => item.relatesToType === ORIGIN_CONTAINER_YARD && item.field === 'STATE')?.current;
    const pickUpZipcodeRequested = data.find((item) => item.relatesToType === ORIGIN_CONTAINER_YARD && item.field === 'ZIPCODE')?.current;
    const pickUpCountryRequested = data.find((item) => item.relatesToType === ORIGIN_CONTAINER_YARD && item.field === 'COUNTRY')?.current;

    const pickUpReleaseMismatch = data.find((item) => item.relatesToType === ORIGIN_CONTAINER_YARD && item.field === TERMINAL_DATE && item.mismatch)?.mismatchesCurrent;
    const pickUpTerminalMismatch = data.find((item) => item.relatesToType === ORIGIN_CONTAINER_YARD && item.field === TERMINAL_NAME && item.mismatch)?.mismatchesCurrent;
    const pickUpPassCodeMismatch = data.find((item) => item.relatesToType === ORIGIN_CONTAINER_YARD && item.field === 'PASSCODE' && item.mismatch)?.mismatchesCurrent;
    const pickUpAddress1Mismatch = data.find((item) => item.relatesToType === ORIGIN_CONTAINER_YARD && item.field === 'ADDRESS_1' && item.mismatch)?.mismatchesCurrent;
    const pickUpAddress2Mismatch = data.find((item) => item.relatesToType === ORIGIN_CONTAINER_YARD && item.field === 'ADDRESS_2' && item.mismatch)?.mismatchesCurrent;
    const pickUpAddress3Mismatch = data.find((item) => item.relatesToType === ORIGIN_CONTAINER_YARD && item.field === 'ADDRESS_3' && item.mismatch)?.mismatchesCurrent;
    const pickUpAddress4Mismatch = data.find((item) => item.relatesToType === ORIGIN_CONTAINER_YARD && item.field === 'ADDRESS_4' && item.mismatch)?.mismatchesCurrent;
    const pickUpCityMismatch = data.find((item) => item.relatesToType === ORIGIN_CONTAINER_YARD && item.field === 'CITY' && item.mismatch)?.mismatchesCurrent;
    const pickUpStateMismatch = data.find((item) => item.relatesToType === ORIGIN_CONTAINER_YARD && item.field === 'STATE' && item.mismatch)?.mismatchesCurrent;
    const pickUpZipcodeMismatch = data.find((item) => item.relatesToType === ORIGIN_CONTAINER_YARD && item.field === 'ZIPCODE' && item.mismatch)?.mismatchesCurrent;
    const pickUpCountryMismatch = data.find((item) => item.relatesToType === ORIGIN_CONTAINER_YARD && item.field === 'COUNTRY' && item.mismatch)?.mismatchesCurrent;

    const dropOffRelease = data.find((item) => item.relatesToType === ORIGIN_CONTAINER_TERMINAL && item.field === TERMINAL_DATE)?.previous;
    const dropOffTerminal = data.find((item) => item.relatesToType === ORIGIN_CONTAINER_TERMINAL && item.field === TERMINAL_NAME)?.previous;
    const dropOffPassCode = data.find((item) => item.relatesToType === ORIGIN_CONTAINER_TERMINAL && item.field === 'PASSCODE')?.previous;
    const dropOffAddress1 = data.find((item) => item.relatesToType === ORIGIN_CONTAINER_TERMINAL && item.field === 'ADDRESS_1')?.previous;
    const dropOffAddress2 = data.find((item) => item.relatesToType === ORIGIN_CONTAINER_TERMINAL && item.field === 'ADDRESS_2')?.previous;
    const dropOffAddress3 = data.find((item) => item.relatesToType === ORIGIN_CONTAINER_TERMINAL && item.field === 'ADDRESS_3')?.previous;
    const dropOffAddress4 = data.find((item) => item.relatesToType === ORIGIN_CONTAINER_TERMINAL && item.field === 'ADDRESS_4')?.previous;
    const dropOffCity = data.find((item) => item.relatesToType === ORIGIN_CONTAINER_TERMINAL && item.field === 'CITY')?.previous;
    const dropOffState = data.find((item) => item.relatesToType === ORIGIN_CONTAINER_TERMINAL && item.field === 'STATE')?.previous;
    const dropOffZipcode = data.find((item) => item.relatesToType === ORIGIN_CONTAINER_TERMINAL && item.field === 'ZIPCODE')?.previous;
    const dropOffCountry = data.find((item) => item.relatesToType === ORIGIN_CONTAINER_TERMINAL && item.field === 'COUNTRY')?.previous;

    const dropOffTerminalRequested = data.find((item) => item.relatesToType === ORIGIN_CONTAINER_TERMINAL && item.field === TERMINAL_NAME)?.current;
    const dropOffPassCodeRequested = data.find((item) => item.relatesToType === ORIGIN_CONTAINER_TERMINAL && item.field === 'PASSCODE')?.current;
    const dropOffReleaseRequested = data.find((item) => item.relatesToType === ORIGIN_CONTAINER_TERMINAL && item.field === TERMINAL_DATE)?.current;
    const dropOffAddress1Requested = data.find((item) => item.relatesToType === ORIGIN_CONTAINER_TERMINAL && item.field === 'ADDRESS_1')?.current;
    const dropOffAddress2Requested = data.find((item) => item.relatesToType === ORIGIN_CONTAINER_TERMINAL && item.field === 'ADDRESS_2')?.current;
    const dropOffAddress3Requested = data.find((item) => item.relatesToType === ORIGIN_CONTAINER_TERMINAL && item.field === 'ADDRESS_3')?.current;
    const dropOffAddress4Requested = data.find((item) => item.relatesToType === ORIGIN_CONTAINER_TERMINAL && item.field === 'ADDRESS_4')?.current;
    const dropOffCityRequested = data.find((item) => item.relatesToType === ORIGIN_CONTAINER_TERMINAL && item.field === 'CITY')?.current;
    const dropOffStateRequested = data.find((item) => item.relatesToType === ORIGIN_CONTAINER_TERMINAL && item.field === 'STATE')?.current;
    const dropOffZipcodeRequested = data.find((item) => item.relatesToType === ORIGIN_CONTAINER_TERMINAL && item.field === 'ZIPCODE')?.current;
    const dropOffCountryRequested = data.find((item) => item.relatesToType === ORIGIN_CONTAINER_TERMINAL && item.field === 'COUNTRY')?.current;

    const dropOffReleaseMismatch = data.find((item) => item.relatesToType === ORIGIN_CONTAINER_TERMINAL && item.field === TERMINAL_DATE && item.mismatch)?.mismatchesCurrent;
    const dropOffTerminalMismatch = data.find((item) => item.relatesToType === ORIGIN_CONTAINER_TERMINAL && item.field === TERMINAL_NAME && item.mismatch)?.mismatchesCurrent;
    const dropOffPassCodeMismatch = data.find((item) => item.relatesToType === ORIGIN_CONTAINER_TERMINAL && item.field === 'PASSCODE' && item.mismatch)?.mismatchesCurrent;
    const dropOffAddress1Mismatch = data.find((item) => item.relatesToType === ORIGIN_CONTAINER_TERMINAL && item.field === 'ADDRESS_1' && item.mismatch)?.mismatchesCurrent;
    const dropOffAddress2Mismatch = data.find((item) => item.relatesToType === ORIGIN_CONTAINER_TERMINAL && item.field === 'ADDRESS_2' && item.mismatch)?.mismatchesCurrent;
    const dropOffAddress3Mismatch = data.find((item) => item.relatesToType === ORIGIN_CONTAINER_TERMINAL && item.field === 'ADDRESS_3' && item.mismatch)?.mismatchesCurrent;
    const dropOffAddress4Mismatch = data.find((item) => item.relatesToType === ORIGIN_CONTAINER_TERMINAL && item.field === 'ADDRESS_4' && item.mismatch)?.mismatchesCurrent;
    const dropOffCityMismatch = data.find((item) => item.relatesToType === ORIGIN_CONTAINER_TERMINAL && item.field === 'CITY' && item.mismatch)?.mismatchesCurrent;
    const dropOffStateMismatch = data.find((item) => item.relatesToType === ORIGIN_CONTAINER_TERMINAL && item.field === 'STATE' && item.mismatch)?.mismatchesCurrent;
    const dropOffZipcodeMismatch = data.find((item) => item.relatesToType === ORIGIN_CONTAINER_TERMINAL && item.field === 'ZIPCODE' && item.mismatch)?.mismatchesCurrent;
    const dropOffCountryMismatch = data.find((item) => item.relatesToType === ORIGIN_CONTAINER_TERMINAL && item.field === 'COUNTRY' && item.mismatch)?.mismatchesCurrent;

    const isReviewCarrierRes = (bookingStatus === ShipmentBookingStatusEnum.BOOKING_AMENDMENT_SUBMITTED) && (withMismatches || isMaersk);

    let result = [
      {
        title: i18next.t('pickUpEmpty'),
        confirmed: {
          name: 'Terminal',
          value: pickUpTerminal,
        },
        requested: {
          name: 'Terminal',
          value: pickUpTerminalRequested,
        },
        carrierConfirmed: calculateMismatch(pickUpTerminalRequested, pickUpTerminalMismatch, false, withMismatches),
        label: i18next.t('terminal'),
        isDate: false,
        fieldName: 'terminalName',
        type: ORIGIN_CONTAINER_YARD,
        key: `${ORIGIN_CONTAINER_YARD}-terminalName`,
        isMismatch: !isUndefined(pickUpTerminalMismatch),
        isError: !!errors.find((item) => item.type === ORIGIN_CONTAINER_YARD && item.value === 'terminalName'),
      },
      {
        title: '',
        confirmed: {
          name: 'Pass Through Code',
          value: pickUpPassCode,
        },
        requested: {
          name: 'Pass Through Code',
          value: pickUpPassCodeRequested,
        },
        carrierConfirmed: calculateMismatch(pickUpPassCodeRequested, pickUpPassCodeMismatch, false, withMismatches),
        label: i18next.t('passThroughCode'),
        isDate: false,
        fieldName: 'passCode',
        type: ORIGIN_CONTAINER_YARD,
        key: `${ORIGIN_CONTAINER_YARD}-passCode`,
        isMismatch: !isUndefined(pickUpPassCodeMismatch),
        isError: false,
      },
      {
        title: '',
        confirmed: {
          name: 'Address Line 1',
          value: pickUpAddress1,
        },
        requested: {
          name: 'Address Line 1',
          value: pickUpAddress1Requested,
        },
        carrierConfirmed: calculateMismatch(pickUpAddress1Requested, pickUpAddress1Mismatch, false, withMismatches),
        label: i18next.t('addressLine1'),
        isDate: false,
        fieldName: 'address1',
        type: ORIGIN_CONTAINER_YARD,
        key: `${ORIGIN_CONTAINER_YARD}-address1`,
        isMismatch: !isUndefined(pickUpAddress1Mismatch),
        isError: !!errors.find((item) => item.type === ORIGIN_CONTAINER_YARD && item.value === 'address1'),
      },
      {
        title: '',
        confirmed: {
          name: 'Address Line 2',
          value: pickUpAddress2,
        },
        requested: {
          name: 'Address Line 2',
          value: pickUpAddress2Requested,
        },
        carrierConfirmed: calculateMismatch(pickUpAddress2Requested, pickUpAddress2Mismatch, false, withMismatches),
        label: i18next.t('addressLine2'),
        isDate: false,
        fieldName: 'address2',
        type: ORIGIN_CONTAINER_YARD,
        key: `${ORIGIN_CONTAINER_YARD}-address2`,
        isMismatch: !isUndefined(pickUpAddress2Mismatch),
        isError: false,
      },
      {
        title: '',
        confirmed: {
          name: 'Address Line 3',
          value: pickUpAddress3,
        },
        requested: {
          name: 'Address Line 3',
          value: pickUpAddress3Requested,
        },
        carrierConfirmed: calculateMismatch(pickUpAddress3Requested, pickUpAddress3Mismatch, false, withMismatches),
        label: i18next.t('addressLine3'),
        isDate: false,
        fieldName: 'address3',
        type: ORIGIN_CONTAINER_YARD,
        key: `${ORIGIN_CONTAINER_YARD}-address3`,
        isMismatch: !isUndefined(pickUpAddress3Mismatch),
        isError: false,
      },
      {
        title: '',
        confirmed: {
          name: 'Address Line 4',
          value: pickUpAddress4,
        },
        requested: {
          name: 'Address Line 4',
          value: pickUpAddress4Requested,
        },
        carrierConfirmed: calculateMismatch(pickUpAddress4Requested, pickUpAddress4Mismatch, false, withMismatches),
        label: i18next.t('addressLine4'),
        isDate: false,
        fieldName: 'address4',
        type: ORIGIN_CONTAINER_YARD,
        key: `${ORIGIN_CONTAINER_YARD}-address4`,
        isMismatch: !isUndefined(pickUpAddress4Mismatch),
        isError: false,
      },
      {
        title: '',
        confirmed: {
          name: 'City',
          value: pickUpCity,
        },
        requested: {
          name: 'City',
          value: pickUpCityRequested,
        },
        carrierConfirmed: calculateMismatch(pickUpCityRequested, pickUpCityMismatch, false, withMismatches),
        label: i18next.t('city'),
        isDate: false,
        fieldName: 'city',
        type: ORIGIN_CONTAINER_YARD,
        key: `${ORIGIN_CONTAINER_YARD}-city`,
        isMismatch: !isUndefined(pickUpCityMismatch),
        isError: !!errors.find((item) => item.type === ORIGIN_CONTAINER_YARD && item.value === 'city'),
      },
      {
        title: '',
        confirmed: {
          name: 'State',
          value: pickUpState,
        },
        requested: {
          name: 'State',
          value: pickUpStateRequested,
        },
        carrierConfirmed: calculateMismatch(pickUpStateRequested, pickUpStateMismatch, false, withMismatches),
        label: i18next.t('state'),
        isDate: false,
        fieldName: 'state',
        type: ORIGIN_CONTAINER_YARD,
        key: `${ORIGIN_CONTAINER_YARD}-state`,
        isMismatch: !isUndefined(pickUpStateMismatch),
        isError: !!errors.find((item) => item.type === ORIGIN_CONTAINER_YARD && item.value === 'state'),
      },
      {
        title: '',
        confirmed: {
          name: 'Postcode',
          value: pickUpZipcode,
        },
        requested: {
          name: 'Postcode',
          value: pickUpZipcodeRequested,
        },
        carrierConfirmed: calculateMismatch(pickUpZipcodeRequested, pickUpZipcodeMismatch, false, withMismatches),
        label: i18next.t('postcode'),
        isDate: false,
        fieldName: 'postcode',
        type: ORIGIN_CONTAINER_YARD,
        key: `${ORIGIN_CONTAINER_YARD}-postcode`,
        isMismatch: !isUndefined(pickUpZipcodeMismatch),
        isError: false,
      },
      {
        title: '',
        confirmed: {
          name: 'Country',
          value: pickUpCountry,
        },
        requested: {
          name: 'Country',
          value: pickUpCountryRequested,
        },
        carrierConfirmed: calculateMismatch(pickUpCountryRequested, pickUpCountryMismatch, false, withMismatches),
        label: i18next.t('country'),
        isDate: false,
        fieldName: 'country',
        type: ORIGIN_CONTAINER_YARD,
        key: `${ORIGIN_CONTAINER_YARD}-country`,
        isMismatch: !isUndefined(pickUpCountryMismatch),
        isError: !!errors.find((item) => item.type === ORIGIN_CONTAINER_YARD && item.value === 'country'),
      },
      {
        title: '',
        confirmed: {
          name: 'Earliest CY Empty Release',
          value: pickUpRelease ? moment.parseZone(pickUpRelease as string).format(timeFormat) : '',
        },
        requested: {
          name: 'Earliest CY Empty Release',
          value: pickUpReleaseRequested ? moment.parseZone(pickUpReleaseRequested as string).format(timeFormat) : '',
        },
        carrierConfirmed: calculateMismatch(pickUpReleaseRequested, pickUpReleaseMismatch, true, withMismatches),
        label: i18next.t('Earliest CY Empty Release'),
        isDate: true,
        fieldName: 'time',
        type: ORIGIN_CONTAINER_YARD,
        key: `${ORIGIN_CONTAINER_YARD}-time`,
        isMismatch: !isUndefined(pickUpReleaseMismatch),
        isError: !!errors.find((item) => item.type === ORIGIN_CONTAINER_YARD && item.value === 'time'),
      },
      {
        title: i18next.t('dropOffFull'),
        confirmed: {
          name: 'Terminal',
          value: dropOffTerminal,
        },
        requested: {
          name: 'Terminal',
          value: dropOffTerminalRequested,
        },
        carrierConfirmed: calculateMismatch(dropOffTerminalRequested, dropOffTerminalMismatch, false, withMismatches),
        label: i18next.t('terminal'),
        isDate: false,
        fieldName: 'terminalName',
        type: ORIGIN_CONTAINER_TERMINAL,
        key: `${ORIGIN_CONTAINER_TERMINAL}-terminalName`,
        isMismatch: !isUndefined(dropOffTerminalMismatch),
        isError: !!errors.find((item) => item.type === ORIGIN_CONTAINER_TERMINAL && item.value === 'terminalName'),
      },
      {
        title: '',
        confirmed: {
          name: 'Pass Through Code',
          value: dropOffPassCode,
        },
        requested: {
          name: 'Pass Through Code',
          value: dropOffPassCodeRequested,
        },
        carrierConfirmed: calculateMismatch(dropOffPassCodeRequested, dropOffPassCodeMismatch, false, withMismatches),
        label: i18next.t('passThroughCode'),
        isDate: false,
        fieldName: 'passCode',
        type: ORIGIN_CONTAINER_TERMINAL,
        key: `${ORIGIN_CONTAINER_TERMINAL}-passCode`,
        isMismatch: !isUndefined(dropOffPassCodeMismatch),
        isError: false,
      },
      {
        title: '',
        confirmed: {
          name: 'Address Line 1',
          value: dropOffAddress1,
        },
        requested: {
          name: 'Address Line 1',
          value: dropOffAddress1Requested,
        },
        carrierConfirmed: calculateMismatch(dropOffAddress1Requested, dropOffAddress1Mismatch, false, withMismatches),
        label: i18next.t('addressLine1'),
        isDate: false,
        fieldName: 'address1',
        type: ORIGIN_CONTAINER_TERMINAL,
        key: `${ORIGIN_CONTAINER_TERMINAL}-address1`,
        isMismatch: !isUndefined(dropOffAddress1Mismatch),
        isError: !!errors.find((item) => item.type === ORIGIN_CONTAINER_TERMINAL && item.value === 'address1'),
      },
      {
        title: '',
        confirmed: {
          name: 'Address Line 2',
          value: dropOffAddress2,
        },
        requested: {
          name: 'Address Line 2',
          value: dropOffAddress2Requested,
        },
        carrierConfirmed: calculateMismatch(dropOffAddress2Requested, dropOffAddress2Mismatch, false, withMismatches),
        label: i18next.t('addressLine2'),
        isDate: false,
        fieldName: 'address2',
        type: ORIGIN_CONTAINER_TERMINAL,
        key: `${ORIGIN_CONTAINER_TERMINAL}-address2`,
        isMismatch: !isUndefined(dropOffAddress2Mismatch),
        isError: false,
      },
      {
        title: '',
        confirmed: {
          name: 'Address Line 3',
          value: dropOffAddress3,
        },
        requested: {
          name: 'Address Line 3',
          value: dropOffAddress3Requested,
        },
        carrierConfirmed: calculateMismatch(dropOffAddress3Requested, dropOffAddress3Mismatch, false, withMismatches),
        label: i18next.t('addressLine3'),
        isDate: false,
        fieldName: 'address3',
        type: ORIGIN_CONTAINER_TERMINAL,
        key: `${ORIGIN_CONTAINER_TERMINAL}-address3`,
        isMismatch: !isUndefined(dropOffAddress3Mismatch),
        isError: false,
      },
      {
        title: '',
        confirmed: {
          name: 'Address Line 4',
          value: dropOffAddress4,
        },
        requested: {
          name: 'Address Line 4',
          value: dropOffAddress4Requested,
        },
        carrierConfirmed: calculateMismatch(dropOffAddress4Requested, dropOffAddress4Mismatch, false, withMismatches),
        label: i18next.t('addressLine4'),
        isDate: false,
        fieldName: 'address4',
        type: ORIGIN_CONTAINER_TERMINAL,
        key: `${ORIGIN_CONTAINER_TERMINAL}-address4`,
        isMismatch: !isUndefined(dropOffAddress4Mismatch),
        isError: false,
      },
      {
        title: '',
        confirmed: {
          name: 'City',
          value: dropOffCity,
        },
        requested: {
          name: 'City',
          value: dropOffCityRequested,
        },
        carrierConfirmed: calculateMismatch(dropOffCityRequested, dropOffCityMismatch, false, withMismatches),
        label: i18next.t('city'),
        isDate: false,
        fieldName: 'city',
        type: ORIGIN_CONTAINER_TERMINAL,
        key: `${ORIGIN_CONTAINER_TERMINAL}-city`,
        isMismatch: !isUndefined(dropOffCityMismatch),
        isError: !!errors.find((item) => item.type === ORIGIN_CONTAINER_TERMINAL && item.value === 'city'),
      },
      {
        title: '',
        confirmed: {
          name: 'State',
          value: dropOffState,
        },
        requested: {
          name: 'State',
          value: dropOffStateRequested,
        },
        carrierConfirmed: calculateMismatch(dropOffStateRequested, dropOffStateMismatch, false, withMismatches),
        label: i18next.t('state'),
        isDate: false,
        fieldName: 'state',
        type: ORIGIN_CONTAINER_TERMINAL,
        key: `${ORIGIN_CONTAINER_TERMINAL}-state`,
        isMismatch: !isUndefined(dropOffStateMismatch),
        isError: !!errors.find((item) => item.type === ORIGIN_CONTAINER_TERMINAL && item.value === 'state'),
      },
      {
        title: '',
        confirmed: {
          name: 'Postcode',
          value: dropOffZipcode,
        },
        requested: {
          name: 'Postcode',
          value: dropOffZipcodeRequested,
        },
        carrierConfirmed: calculateMismatch(dropOffZipcodeRequested, dropOffZipcodeMismatch, false, withMismatches),
        label: i18next.t('postcode'),
        isDate: false,
        fieldName: 'postcode',
        type: ORIGIN_CONTAINER_TERMINAL,
        key: `${ORIGIN_CONTAINER_TERMINAL}-postcode`,
        isMismatch: !isUndefined(dropOffZipcodeMismatch),
        isError: false,
      },
      {
        title: '',
        confirmed: {
          name: 'Country',
          value: dropOffCountry,
        },
        requested: {
          name: 'Country',
          value: dropOffCountryRequested,
        },
        carrierConfirmed: calculateMismatch(dropOffCountryRequested, dropOffCountryMismatch, false, withMismatches),
        label: i18next.t('country'),
        isDate: false,
        fieldName: 'country',
        type: ORIGIN_CONTAINER_TERMINAL,
        key: `${ORIGIN_CONTAINER_TERMINAL}-country`,
        isMismatch: !isUndefined(dropOffCountryMismatch),
        isError: !!errors.find((item) => item.type === ORIGIN_CONTAINER_TERMINAL && item.value === 'country'),
      },
      {
        title: '',
        confirmed: {
          name: 'Earliest CTO Full Drop Date',
          value: dropOffRelease ? moment.parseZone(dropOffRelease as string).format(timeFormat) : '',
        },
        requested: {
          name: 'Earliest CTO Full Drop Date',
          value: dropOffReleaseRequested ? moment.parseZone(dropOffReleaseRequested as string).format(timeFormat) : '',
        },
        carrierConfirmed: calculateMismatch(dropOffReleaseRequested, dropOffReleaseMismatch, true, withMismatches),
        label: i18next.t('Earliest CTO Full Drop Date'),
        isDate: true,
        fieldName: 'time',
        type: ORIGIN_CONTAINER_TERMINAL,
        key: `${ORIGIN_CONTAINER_TERMINAL}-time`,
        isMismatch: !isUndefined(dropOffReleaseMismatch),
        isError: !!errors.find((item) => item.type === ORIGIN_CONTAINER_TERMINAL && item.value === 'time'),
      },
    ];

    if (bookingStatus !== ShipmentBookingStatusEnum.BOOKING_SUBMITTED && isCustomer) {
      result = result.filter((item) => (item.confirmed.value !== item.requested.value));
    }
    if (isReviewCarrierRes) {
      result = result.map((item) => ({
        ...item,
        requested: {
          name: item.requested.value === item.confirmed.value ? '' : item.requested.name,
          value: item.requested.value === item.confirmed.value ? '' : item.requested.value,
        },
      }));
    }
    return result;
  },
);

export const getLocationsTableMismatch = createSelector(
  getData,
  getLocationsError,
  (data, errors) => {
    const mismatchData = data.filter((item) => item.mismatch);
    const pickUpRelease = mismatchData.find((item) => item.relatesToType === ORIGIN_CONTAINER_YARD && item.field === TERMINAL_DATE);
    const pickUpTerminal = mismatchData.find((item) => item.relatesToType === ORIGIN_CONTAINER_YARD && item.field === TERMINAL_NAME);
    const pickUpPassCode = mismatchData.find((item) => item.relatesToType === ORIGIN_CONTAINER_YARD && item.field === 'PASSCODE');
    const pickUpAddress1 = mismatchData.find((item) => item.relatesToType === ORIGIN_CONTAINER_YARD && item.field === 'ADDRESS_1');
    const pickUpAddress2 = mismatchData.find((item) => item.relatesToType === ORIGIN_CONTAINER_YARD && item.field === 'ADDRESS_2');
    const pickUpAddress3 = mismatchData.find((item) => item.relatesToType === ORIGIN_CONTAINER_YARD && item.field === 'ADDRESS_3');
    const pickUpAddress4 = mismatchData.find((item) => item.relatesToType === ORIGIN_CONTAINER_YARD && item.field === 'ADDRESS_4');
    const pickUpCity = mismatchData.find((item) => item.relatesToType === ORIGIN_CONTAINER_YARD && item.field === 'CITY');
    const pickUpState = mismatchData.find((item) => item.relatesToType === ORIGIN_CONTAINER_YARD && item.field === 'STATE');
    const pickUpZipcode = mismatchData.find((item) => item.relatesToType === ORIGIN_CONTAINER_YARD && item.field === 'ZIPCODE');
    const pickUpCountry = mismatchData.find((item) => item.relatesToType === ORIGIN_CONTAINER_YARD && item.field === 'COUNTRY');

    const dropOffRelease = mismatchData.find((item) => item.relatesToType === ORIGIN_CONTAINER_TERMINAL && item.field === TERMINAL_DATE);
    const dropOffTerminal = mismatchData.find((item) => item.relatesToType === ORIGIN_CONTAINER_TERMINAL && item.field === TERMINAL_NAME);
    const dropOffPassCode = mismatchData.find((item) => item.relatesToType === ORIGIN_CONTAINER_TERMINAL && item.field === 'PASSCODE');
    const dropOffAddress1 = mismatchData.find((item) => item.relatesToType === ORIGIN_CONTAINER_TERMINAL && item.field === 'ADDRESS_1');
    const dropOffAddress2 = mismatchData.find((item) => item.relatesToType === ORIGIN_CONTAINER_TERMINAL && item.field === 'ADDRESS_2');
    const dropOffAddress3 = mismatchData.find((item) => item.relatesToType === ORIGIN_CONTAINER_TERMINAL && item.field === 'ADDRESS_3');
    const dropOffAddress4 = mismatchData.find((item) => item.relatesToType === ORIGIN_CONTAINER_TERMINAL && item.field === 'ADDRESS_4');
    const dropOffCity = mismatchData.find((item) => item.relatesToType === ORIGIN_CONTAINER_TERMINAL && item.field === 'CITY');
    const dropOffState = mismatchData.find((item) => item.relatesToType === ORIGIN_CONTAINER_TERMINAL && item.field === 'STATE');
    const dropOffZipcode = mismatchData.find((item) => item.relatesToType === ORIGIN_CONTAINER_TERMINAL && item.field === 'ZIPCODE');
    const dropOffCountry = mismatchData.find((item) => item.relatesToType === ORIGIN_CONTAINER_TERMINAL && item.field === 'COUNTRY');

    let result: IChangesTable[] = [];
    if (pickUpTerminal) {
      result = [...result, {
        title: i18next.t('pickUpEmpty'),
        confirmed: {
          name: 'Terminal',
          value: pickUpTerminal?.mismatchesPrevious,
        },
        requested: {
          name: 'Terminal',
          value: '',
        },
        carrierConfirmed: pickUpTerminal?.mismatchesCurrent,
        label: i18next.t('terminal'),
        isDate: false,
        fieldName: 'terminalName',
        type: ORIGIN_CONTAINER_YARD,
        key: `${ORIGIN_CONTAINER_YARD}-terminalName`,
        isMismatch: true,
        isError: !!errors.find((item) => item.type === ORIGIN_CONTAINER_YARD && item.value === 'terminalName'),
      }];
    }
    if (pickUpPassCode) {
      result = [...result, {
        title: '',
        confirmed: {
          name: 'Pass Through Code',
          value: pickUpPassCode?.mismatchesPrevious,
        },
        requested: {
          name: 'Pass Through Code',
          value: '',
        },
        carrierConfirmed: pickUpPassCode?.mismatchesCurrent,
        label: i18next.t('passThroughCode'),
        isDate: false,
        fieldName: 'passCode',
        type: ORIGIN_CONTAINER_YARD,
        key: `${ORIGIN_CONTAINER_YARD}-passCode`,
        isMismatch: true,
        isError: false,
      }];
    }
    if (pickUpAddress1) {
      result = [...result, {
        title: '',
        confirmed: {
          name: 'Address Line 1',
          value: pickUpAddress1?.mismatchesPrevious,
        },
        requested: {
          name: 'Address Line 1',
          value: '',
        },
        carrierConfirmed: pickUpAddress1?.mismatchesCurrent,
        label: i18next.t('addressLine1'),
        isDate: false,
        fieldName: 'address1',
        type: ORIGIN_CONTAINER_YARD,
        key: `${ORIGIN_CONTAINER_YARD}-address1`,
        isMismatch: true,
        isError: !!errors.find((item) => item.type === ORIGIN_CONTAINER_YARD && item.value === 'address1'),
      }];
    }
    if (pickUpAddress2) {
      result = [...result, {
        title: '',
        confirmed: {
          name: 'Address Line 2',
          value: pickUpAddress2?.mismatchesPrevious,
        },
        requested: {
          name: 'Address Line 2',
          value: '',
        },
        carrierConfirmed: pickUpAddress2?.mismatchesCurrent,
        label: i18next.t('addressLine2'),
        isDate: false,
        fieldName: 'address2',
        type: ORIGIN_CONTAINER_YARD,
        key: `${ORIGIN_CONTAINER_YARD}-address2`,
        isMismatch: true,
        isError: false,
      }];
    }
    if (pickUpAddress3) {
      result = [...result, {
        title: '',
        confirmed: {
          name: 'Address Line 3',
          value: pickUpAddress3?.mismatchesPrevious,
        },
        requested: {
          name: 'Address Line 3',
          value: '',
        },
        carrierConfirmed: pickUpAddress3?.mismatchesCurrent,
        label: i18next.t('addressLine3'),
        isDate: false,
        fieldName: 'address3',
        type: ORIGIN_CONTAINER_YARD,
        key: `${ORIGIN_CONTAINER_YARD}-address3`,
        isMismatch: true,
        isError: false,
      }];
    }
    if (pickUpAddress4) {
      result = [...result, {
        title: '',
        confirmed: {
          name: 'Address Line 4',
          value: pickUpAddress4?.mismatchesPrevious,
        },
        requested: {
          name: 'Address Line 4',
          value: '',
        },
        carrierConfirmed: pickUpAddress4?.mismatchesCurrent,
        label: i18next.t('addressLine4'),
        isDate: false,
        fieldName: 'address4',
        type: ORIGIN_CONTAINER_YARD,
        key: `${ORIGIN_CONTAINER_YARD}-address4`,
        isMismatch: true,
        isError: false,
      }];
    }
    if (pickUpCity) {
      result = [...result, {
        title: '',
        confirmed: {
          name: 'City',
          value: pickUpCity?.mismatchesPrevious,
        },
        requested: {
          name: 'City',
          value: '',
        },
        carrierConfirmed: pickUpCity?.mismatchesCurrent,
        label: i18next.t('city'),
        isDate: false,
        fieldName: 'city',
        type: ORIGIN_CONTAINER_YARD,
        key: `${ORIGIN_CONTAINER_YARD}-city`,
        isMismatch: true,
        isError: !!errors.find((item) => item.type === ORIGIN_CONTAINER_YARD && item.value === 'city'),
      }];
    }
    if (pickUpState) {
      result = [...result, {
        title: '',
        confirmed: {
          name: 'State',
          value: pickUpState?.mismatchesPrevious,
        },
        requested: {
          name: 'State',
          value: '',
        },
        carrierConfirmed: pickUpState?.mismatchesCurrent,
        label: i18next.t('state'),
        isDate: false,
        fieldName: 'state',
        type: ORIGIN_CONTAINER_YARD,
        key: `${ORIGIN_CONTAINER_YARD}-state`,
        isMismatch: true,
        isError: !!errors.find((item) => item.type === ORIGIN_CONTAINER_YARD && item.value === 'state'),
      }];
    }
    if (pickUpZipcode) {
      result = [...result, {
        title: '',
        confirmed: {
          name: 'Postcode',
          value: pickUpZipcode?.mismatchesPrevious,
        },
        requested: {
          name: 'Postcode',
          value: '',
        },
        carrierConfirmed: pickUpZipcode?.mismatchesCurrent,
        label: i18next.t('postcode'),
        isDate: false,
        fieldName: 'postcode',
        type: ORIGIN_CONTAINER_YARD,
        key: `${ORIGIN_CONTAINER_YARD}-postcode`,
        isMismatch: true,
        isError: false,
      }];
    }
    if (pickUpCountry) {
      result = [...result, {
        title: '',
        confirmed: {
          name: 'Country',
          value: pickUpCountry?.mismatchesPrevious,
        },
        requested: {
          name: 'Country',
          value: '',
        },
        carrierConfirmed: pickUpCountry?.mismatchesCurrent,
        label: i18next.t('country'),
        isDate: false,
        fieldName: 'country',
        type: ORIGIN_CONTAINER_YARD,
        key: `${ORIGIN_CONTAINER_YARD}-country`,
        isMismatch: true,
        isError: !!errors.find((item) => item.type === ORIGIN_CONTAINER_YARD && item.value === 'country'),
      }];
    }
    if (pickUpRelease) {
      result = [...result, {
        title: '',
        confirmed: {
          name: 'Earliest CY Empty Release',
          value: pickUpRelease?.mismatchesPrevious,
        },
        requested: {
          name: 'Earliest CY Empty Release',
          value: '',
        },
        carrierConfirmed: pickUpRelease?.mismatchesCurrent,
        label: i18next.t('Earliest CY Empty Release'),
        isDate: true,
        fieldName: 'time',
        type: ORIGIN_CONTAINER_YARD,
        key: `${ORIGIN_CONTAINER_YARD}-time`,
        isMismatch: true,
        isError: !!errors.find((item) => item.type === ORIGIN_CONTAINER_YARD && item.value === 'time'),
      }];
    }
    if (dropOffTerminal) {
      result = [...result, {
        title: i18next.t('dropOffFull'),
        confirmed: {
          name: 'Terminal',
          value: dropOffTerminal?.mismatchesPrevious,
        },
        requested: {
          name: 'Terminal',
          value: '',
        },
        carrierConfirmed: dropOffTerminal?.mismatchesCurrent,
        label: i18next.t('terminal'),
        isDate: false,
        fieldName: 'terminalName',
        type: ORIGIN_CONTAINER_TERMINAL,
        key: `${ORIGIN_CONTAINER_TERMINAL}-terminalName`,
        isMismatch: true,
        isError: !!errors.find((item) => item.type === ORIGIN_CONTAINER_TERMINAL && item.value === 'terminalName'),
      }];
    }
    if (dropOffPassCode) {
      result = [...result, {
        title: '',
        confirmed: {
          name: 'Pass Through Code',
          value: dropOffPassCode?.mismatchesPrevious,
        },
        requested: {
          name: 'Pass Through Code',
          value: '',
        },
        carrierConfirmed: dropOffPassCode?.mismatchesCurrent,
        label: i18next.t('passThroughCode'),
        isDate: false,
        fieldName: 'passCode',
        type: ORIGIN_CONTAINER_TERMINAL,
        key: `${ORIGIN_CONTAINER_TERMINAL}-passCode`,
        isMismatch: true,
        isError: false,
      }];
    }
    if (dropOffAddress1) {
      result = [...result, {
        title: '',
        confirmed: {
          name: 'Address Line 1',
          value: dropOffAddress1?.mismatchesPrevious,
        },
        requested: {
          name: 'Address Line 1',
          value: '',
        },
        carrierConfirmed: dropOffAddress1?.mismatchesCurrent,
        label: i18next.t('addressLine1'),
        isDate: false,
        fieldName: 'address1',
        type: ORIGIN_CONTAINER_TERMINAL,
        key: `${ORIGIN_CONTAINER_TERMINAL}-address1`,
        isMismatch: true,
        isError: !!errors.find((item) => item.type === ORIGIN_CONTAINER_TERMINAL && item.value === 'address1'),
      }];
    }
    if (dropOffAddress2) {
      result = [...result, {
        title: '',
        confirmed: {
          name: 'Address Line 2',
          value: dropOffAddress2?.mismatchesPrevious,
        },
        requested: {
          name: 'Address Line 2',
          value: '',
        },
        carrierConfirmed: dropOffAddress2?.mismatchesCurrent,
        label: i18next.t('addressLine2'),
        isDate: false,
        fieldName: 'address2',
        type: ORIGIN_CONTAINER_TERMINAL,
        key: `${ORIGIN_CONTAINER_TERMINAL}-address2`,
        isMismatch: true,
        isError: false,
      }];
    }
    if (dropOffAddress3) {
      result = [...result, {
        title: '',
        confirmed: {
          name: 'Address Line 3',
          value: dropOffAddress3?.mismatchesPrevious,
        },
        requested: {
          name: 'Address Line 3',
          value: '',
        },
        carrierConfirmed: dropOffAddress3?.mismatchesCurrent,
        label: i18next.t('addressLine3'),
        isDate: false,
        fieldName: 'address3',
        type: ORIGIN_CONTAINER_TERMINAL,
        key: `${ORIGIN_CONTAINER_TERMINAL}-address3`,
        isMismatch: true,
        isError: false,
      }];
    }
    if (dropOffAddress4) {
      result = [...result, {
        title: '',
        confirmed: {
          name: 'Address Line 4',
          value: dropOffAddress4?.mismatchesPrevious,
        },
        requested: {
          name: 'Address Line 4',
          value: '',
        },
        carrierConfirmed: dropOffAddress4?.mismatchesCurrent,
        label: i18next.t('addressLine4'),
        isDate: false,
        fieldName: 'address4',
        type: ORIGIN_CONTAINER_TERMINAL,
        key: `${ORIGIN_CONTAINER_TERMINAL}-address4`,
        isMismatch: true,
        isError: false,
      }];
    }
    if (dropOffCity) {
      result = [...result, {
        title: '',
        confirmed: {
          name: 'City',
          value: dropOffCity?.mismatchesPrevious,
        },
        requested: {
          name: 'City',
          value: '',
        },
        carrierConfirmed: dropOffCity?.mismatchesCurrent,
        label: i18next.t('city'),
        isDate: false,
        fieldName: 'city',
        type: ORIGIN_CONTAINER_TERMINAL,
        key: `${ORIGIN_CONTAINER_TERMINAL}-city`,
        isMismatch: true,
        isError: !!errors.find((item) => item.type === ORIGIN_CONTAINER_TERMINAL && item.value === 'city'),
      }];
    }
    if (dropOffState) {
      result = [...result, {
        title: '',
        confirmed: {
          name: 'State',
          value: dropOffState?.mismatchesPrevious,
        },
        requested: {
          name: 'State',
          value: '',
        },
        carrierConfirmed: dropOffState?.mismatchesCurrent,
        label: i18next.t('state'),
        isDate: false,
        fieldName: 'state',
        type: ORIGIN_CONTAINER_TERMINAL,
        key: `${ORIGIN_CONTAINER_TERMINAL}-state`,
        isMismatch: true,
        isError: !!errors.find((item) => item.type === ORIGIN_CONTAINER_TERMINAL && item.value === 'state'),
      }];
    }
    if (dropOffZipcode) {
      result = [...result, {
        title: '',
        confirmed: {
          name: 'Postcode',
          value: dropOffZipcode?.mismatchesPrevious,
        },
        requested: {
          name: 'Postcode',
          value: '',
        },
        carrierConfirmed: dropOffZipcode?.mismatchesCurrent,
        label: i18next.t('postcode'),
        isDate: false,
        fieldName: 'postcode',
        type: ORIGIN_CONTAINER_TERMINAL,
        key: `${ORIGIN_CONTAINER_TERMINAL}-postcode`,
        isMismatch: true,
        isError: false,
      }];
    }
    if (dropOffCountry) {
      result = [...result, {
        title: '',
        confirmed: {
          name: 'Country',
          value: dropOffCountry?.mismatchesPrevious,
        },
        requested: {
          name: 'Country',
          value: '',
        },
        carrierConfirmed: dropOffCountry?.mismatchesCurrent,
        label: i18next.t('country'),
        isDate: false,
        fieldName: 'country',
        type: ORIGIN_CONTAINER_TERMINAL,
        key: `${ORIGIN_CONTAINER_TERMINAL}-country`,
        isMismatch: true,
        isError: !!errors.find((item) => item.type === ORIGIN_CONTAINER_TERMINAL && item.value === 'country'),
      }];
    }
    if (dropOffRelease) {
      result = [...result, {
        title: '',
        confirmed: {
          name: 'Earliest CTO Full Drop Date',
          value: dropOffRelease?.mismatchesPrevious,
        },
        requested: {
          name: 'Earliest CTO Full Drop Date',
          value: '',
        },
        carrierConfirmed: dropOffRelease?.mismatchesCurrent,
        label: i18next.t('Earliest CTO Full Drop Date'),
        isDate: true,
        fieldName: 'time',
        type: ORIGIN_CONTAINER_TERMINAL,
        key: `${ORIGIN_CONTAINER_TERMINAL}-time`,
        isMismatch: true,
        isError: !!errors.find((item) => item.type === ORIGIN_CONTAINER_TERMINAL && item.value === 'time'),
      }];
    }
    return result;
  },
);

const getMismatchId = createSelector(
  localState,
  (state) => state.mismatchId,
);

const getLocationMismatchId = createSelector(
  localState,
  (state) => state.locationMismatchId,
);

const getAcceptLoading = createSelector(
  localState,
  (state) => state.acceptLoading,
);

const getShouldRefresh = createSelector(
  localState,
  (state) => state.shouldRefresh,
);

const getShouldUpdateShipment = createSelector(
  localState,
  (state) => state.shouldUpdateShipment,
);

const getIsSearchLoading = createSelector(
  localState,
  (state) => state.isSearchLoading,
);

const getIsSearchSuccess = createSelector(
  localState,
  (state) => state.isSearchSuccess,
);

const getSearchError = createSelector(
  localState,
  (state) => state.searchError,
);

const getSearchErrorMessage = createSelector(
  localState,
  (state) => state.searchErrorMessage,
);

const getBookingStatusRequestedShipment = createSelector(
  getShipmentShort,
  (shipmentShort) => shipmentShort?.bookingStatus,
);

const getShortShipmentOceanBookingId = createSelector(
  getShipmentShort,
  (shipmentShort) => shipmentShort?.oceanBookingId,
);

const getShortShipmentCompanyId = createSelector(
  getShipmentShort,
  (shipmentShort) => shipmentShort?.customer?.id,
);

const getOriginTimeZone = createSelector(
  getShipmentShort,
  (shipment) => shipment?.origin?.estimatedDate?.getDateAsMomentWithOffset().format().substring(19),
);

export const getPortCutoff = createSelector(
  getData,
  (data) => {
    const matched = data.find((item) => item.relatesToType === CUTOFF && item.field === PORT && item.mismatch);
    return matched || { value: '', mismatchesCurrent: '' };
  },
);

export const getChangedPortCutoff = createSelector(
  getChangedCutOffs,
  (cutOffs) => cutOffs.find((item) => item.type === PORT),
);

export const getIsChangedCutoff = createSelector(
  getPortCutoff,
  getChangedPortCutoff,
  (portCutoff, changedPortCutoff) => portCutoff.mismatchesCurrent !== changedPortCutoff?.value,
);

export const getIsShowValidate = createSelector(
  getCutOffTable,
  getChangedCutOffs,
  (saved, editable) => {
    const savedPort = saved.find((item) => item.fieldName === PORT);
    const editablePort = editable.find((item) => item.type === PORT);
    return moment.parseZone(savedPort?.requested).format(timeFormat) !== moment.parseZone(editablePort?.value).format(timeFormat);
  },
);

export const getIsEqualTransportPlan = createSelector(
  getData,
  (data) => {
    const transportPlan = data.find((item) => item.relatesToType === TRANSPORTATION_PLAN && !item.mismatch);
    return isEqual(transportPlan?.current, transportPlan?.previous);
  },
);

const getIsWithSubmitButton = createSelector(
  localState,
  (state) => state.withSubmitButton,
);

const getIsWithLocationSection = createSelector(
  localState,
  (state) => state.withLocationSection,
);

export const shipmentChangesSelectors = {
  getInttraNumber,
  getCarrierNumber,
  getDocument,
  getCargoTable,
  getContainersTable,
  getTransportPlanTable,
  getCutOffTable,
  getLocationsTable,
  getLoading,
  getWithMismatches,
  getActionLoading,
  getIsChangedConfirm,
  getTransportPlan,
  getChangedCutOffs,
  getChangedLocations,
  getChangedTransportPlan,
  getData,
  getRejectLoading,
  getMismatchId,
  getLocationMismatchId,
  getAcceptLoading,
  getCutoffsErrors,
  getLocationsError,
  getTransportPlanErrors,
  getTransportPlanDatesErrors,
  getCargoTableMismatch,
  getContainersTableMismatch,
  getCutOffTableMismatch,
  getLocationsTableMismatch,
  getTransportPlanTableMismatch,
  getShouldRefresh,
  getShouldUpdateShipment,
  getCargoTableBL,
  getEntryNumbers,
  getContainersTableBL,
  getPaymentTerms,
  getBlOptions,
  getTransportPlanCurrent,
  getTransportPlanPrevious,
  getTransportPlanMismatchCurrent,
  getMismatchedTransportPlan,
  getTransportPlanMismatchPrevious,
  getIsTransportPlanMismatches,
  getIsSearchLoading,
  getIsSearchSuccess,
  getSearchError,
  getSearchErrorMessage,
  getBookingStatusRequestedShipment,
  getShipmentShort,
  getShortShipmentOceanBookingId,
  getShipmentPartiesTableBL,
  getIsChangedCutoff,
  getIsShowValidate,
  getShortShipmentCompanyId,
  getIsEqualTransportPlan,
  getIsWithSubmitButton,
  getIsWithLocationSection,
  getOriginTimeZone,
};
