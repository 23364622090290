import { EditOutlined, KeyOutlined, UserOutlined } from '@ant-design/icons';
import React, { FC, useCallback } from 'react';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';

import { OrganizationMemberDTM } from 'user-management/models/dtm';

import {
  UserDataButton,
  UserDataButtonLast,
  UserDataIconDefaultBG,
  UserDataName,
  UserDataNameBox,
  UserDataType,
  UserDataWrapper,
} from './UserData.styled';

interface IUserDataComponentProps {
  isAdmin: boolean;
  userEmail?: string;
  user?: OrganizationMemberDTM;
  userName: string
  onChangePassword?: () => void
  onEditProfile: (member?: OrganizationMemberDTM) => void
}

export const UserDataComponent: FC<IUserDataComponentProps> = ({
  isAdmin,
  userName,
  userEmail,
  onChangePassword,
  onEditProfile,
  user,
}) => {
  const { email = '' } = useParams();
  const { t } = useTranslation();

  const onChangePasswordClick = useCallback(() => {
    if (onChangePassword) {
      onChangePassword();
    }
  }, [onChangePassword]);

  const onEditProfileClick = useCallback(() => {
    onEditProfile(user);
  }, [onEditProfile, user]);

  return (
    <UserDataWrapper>
      <UserDataIconDefaultBG>
        <UserOutlined />
      </UserDataIconDefaultBG>

      <UserDataNameBox>
        <UserDataName>{userName}</UserDataName>

        <UserDataType>{user?.jobTitle || ''}</UserDataType>
      </UserDataNameBox>

      {onChangePassword ? (
        <UserDataButton
          type="default"
          icon={<KeyOutlined />}
          onClick={onChangePasswordClick}
        >
          {t('Change Password')}
        </UserDataButton>
      ) : null}

      {isAdmin && (email !== userEmail) ? (
        <UserDataButtonLast
          onClick={onEditProfileClick}
          icon={<EditOutlined />}
        >
          {t('Edit Profile')}
        </UserDataButtonLast>
      ) : null}
    </UserDataWrapper>
  );
};
