import {
  IsEnum,
  IsOptional,
} from 'class-validator';
import { BaseDTM } from 'proto/BaseDTM';

import { PermissionAttributePolicy } from 'shipment-operations/constants';

export type TExportClearancePermissionsDtm = {
  exportClearance?: PermissionAttributePolicy;
}

export class ExportClearancePermissionsDtm extends BaseDTM<TExportClearancePermissionsDtm> {
  @IsOptional()
  @IsEnum(PermissionAttributePolicy)
  exportClearance?: PermissionAttributePolicy;
}
