import {
  IsArray,
  IsString,
  IsDefined,
  ValidateNested,
  IsNumber,
  IsBoolean,
  IsOptional,
} from 'class-validator';
import { plainToInstance, Type } from 'class-transformer';
import { BaseDTM } from 'proto/BaseDTM';

import { ShippingInstructionsStatusEnum } from 'shipment-operations/constants';
import { DocumentDTM, IDocumentDTM } from 'app-wrapper/models/dtm';

export interface IShipmentInstructionsDetailsDTM {
  clauses: string[];
  blComment: string;
  id: number
  status: `${ShippingInstructionsStatusEnum}`
  mblDraftId: number | null
  mblFinalId: number | null
  draftMblDocument: IDocumentDTM | null
  finalMblDocument: IDocumentDTM | null
  sentAt: string
  requestTheSameWithShipment: boolean
}

export class ShipmentInstructionsDetailsDTM extends BaseDTM<IShipmentInstructionsDetailsDTM> {
  @IsArray()
  @IsString({ each: true })
  clauses: string[];

  @IsDefined()
  @IsString()
  blComment: string;

  @IsDefined()
  @IsNumber()
  id: number;

  @IsDefined()
  @IsString()
  status: ShippingInstructionsStatusEnum;

  @IsOptional()
  @IsNumber()
  mblDraftId: number | null;

  @IsOptional()
  @IsNumber()
  mblFinalId: number | null;

  @IsOptional()
  @ValidateNested()
  @Type(() => DocumentDTM)
  draftMblDocument: DocumentDTM | null;

  @IsOptional()
  @ValidateNested()
  @Type(() => DocumentDTM)
  finalMblDocument: DocumentDTM | null;

  @IsDefined()
  @IsString()
  sentAt: string;

  @IsDefined()
  @IsBoolean()
  requestTheSameWithShipment: boolean;

  static createEmpty = () => plainToInstance(ShipmentInstructionsDetailsDTM, {
    id: 0,
    status: ShippingInstructionsStatusEnum.SI_PREPARATION,
    clauses: [],
    blComment: '',
    mblDraftId: null,
    mblFinalId: null,
    draftMblDocument: null,
    finalMblDocument: null,
  });
}
