import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import i18n from 'app-wrapper/i18n/i18n';

import {
  Wrap,
  Item,
  ItemTag,
  ItemText,
} from './SecurityOrSafetyConcerns.styled';
import { AccountRejected } from '../AccountRejected';
import { PathToReapply } from '../PathToReapply';

const REAPPLY_ITEMS = [
  i18n.t('Address the mentioned security or safety concerns by providing clarifications, additional documents, or corrective action plans.'),
  i18n.t('Ensure that your business practices adhere to universally accepted safety and security standards.'),
  i18n.t('Engage in a dialog with our team to understand specific concerns and discuss potential resolutions.'),
];

interface SecurityOrSafetyConcernsProps {
  note?: string;
}

export const SecurityOrSafetyConcerns: FC<SecurityOrSafetyConcernsProps> = ({ note }) => {
  const { t } = useTranslation();

  return (
    <Wrap>
      <AccountRejected
        title={t('There are concerns related to the security or safety of engaging in business activities with the applicant')}
        subtitle={t('Elements in your application or associated with your business profile have raised security or safety concerns, which need to be addressed to ensure the wellbeing and compliance of our platform and other users.')}
        adminComment={note}
      />

      <PathToReapply
        content={(
          <>
            {REAPPLY_ITEMS.map((text) => (
              <Item key={`SecurityOrSafetyConcerns_${text}`}>
                <ItemTag />

                <ItemText>
                  {text}
                </ItemText>
              </Item>
            ))}
          </>
        )}
      />
    </Wrap>
  );
};
