import React, { FC } from 'react';
import { TabsProps } from 'antd/es/tabs';

import { Tabs } from './Tabs.styled';

export const TabsComponent: FC<TabsProps> = (props) => (
  <Tabs
    {...props}
  />
);

export { TabsComponent as Tabs };
