import React, { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Menu from 'antd/es/menu';
import Layout from 'antd/es/layout';
import {
  FundOutlined,
  FileAddFilled,
  BankFilled,
  HomeFilled,
} from '@ant-design/icons';

import { ShipmentAllStatsDTM } from 'shipment-operations/models/dtm';
import {
  RECEIVABLES,
  PAYABLES,
  PermissionAttributePolicy,
} from 'shipment-operations/constants';
import { RouteNames } from 'app-wrapper/constants';
import { setIsMenuCollapsed } from 'app-wrapper/data';
import { getKeyMenuByPath, hasReadAccess } from 'app-wrapper/utils';
import { CompassSvg, ShipFloodedSvg } from 'app-wrapper/view/icons';
import { MainMenuIcon } from 'app-wrapper/view/guideline';

import {
  MainMenu,
  MainMenuContactBookSvg,
  MainMenuWrapper,
  MainMenuItem,
  MainItemSubMenu,
  SubSubMenu,
  LinkLock, SubSubMenuItem,
  MainMenuWarningDot,
} from './MainMenu.styled';

interface IMainMenu {
  isCollapsed: boolean
  isCustomer?: boolean
  isOrganizationActive?: boolean
  isOrganizationInDraft?: boolean
  isOrganizationInRejected: boolean
  contractsPermission?: PermissionAttributePolicy
  tasksStats?: ShipmentAllStatsDTM;
}

export const MainMenuComponent: FC<IMainMenu> = ({
  isCollapsed,
  isCustomer,
  isOrganizationActive,
  isOrganizationInDraft,
  contractsPermission,
  isOrganizationInRejected,
  tasksStats,
}) => {
  const { t } = useTranslation();
  const [currentTab, setCurrentTab] = useState<string>('');
  const location = useLocation();

  const isAbleToViewContracts = Boolean(contractsPermission && hasReadAccess(contractsPermission));

  const handleClick = (e: { key: any; }) => {
    setCurrentTab(e.key);
  };
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setIsMenuCollapsed(isCollapsed));
  }, [dispatch, isCollapsed]);

  useEffect(() => {
    setCurrentTab(getKeyMenuByPath(location.pathname, {
      altPath: [{
        originalUrl: RouteNames.COMMAND_CENTER_TASKS(),
        alternativeUrl: RouteNames.COMMAND_CENTER_TASKS_COMPLETED(),
      },
      {
        originalUrl: RouteNames.COMMAND_CENTER_TASKS_ALERTS(),
        alternativeUrl: RouteNames.COMMAND_CENTER_TASKS_ALERTS_COMPLETED(),
      },
      ],
    }));
  }, [location.pathname]);

  const collapsedClass = isCollapsed ? 'sub-menu-collapsed' : 'sub-menu-not-collapsed';

  return (
    <MainMenuWrapper>
      <Layout.Sider
        trigger={null}
        collapsible
        collapsed={isCollapsed}
        width={213}
        collapsedWidth={47}
      >
        <MainMenu
          onClick={handleClick}
          selectedKeys={[currentTab]}
          mode="inline"
          defaultSelectedKeys={['4']}
        >
          <MainMenuItem
            key={getKeyMenuByPath(RouteNames.OVERVIEW())}
            icon={<HomeFilled />}
          >
            <NavLink to={RouteNames.OVERVIEW()}>{t('Overview')}</NavLink>
          </MainMenuItem>

          {!isOrganizationInRejected
            ? (
              <MainItemSubMenu
                key="sub3"
                icon={<MainMenuIcon><CompassSvg />{(isCollapsed && (<MainMenuWarningDot isCollapsed isSub isVisible={!!(tasksStats && ShipmentAllStatsDTM.fromPlain({ ...tasksStats })?.getShipmentAllHighNotifications() > 0)} />))}</MainMenuIcon>}
                title={(
                  <>
                    <span>{t('Action Center')}</span>
                    <MainMenuWarningDot isVisible={!!(tasksStats && ShipmentAllStatsDTM.fromPlain({ ...tasksStats })?.getShipmentAllHighNotifications() > 0)} />
                  </>
                )}
              >
                <Menu.Item
                  key={getKeyMenuByPath(RouteNames.COMMAND_CENTER_TASKS())}
                >
                  <NavLink to={RouteNames.COMMAND_CENTER_TASKS()}>{t('Tasks')}<MainMenuWarningDot isSub isVisible={!!tasksStats?.taskHighStats?.expiring} /></NavLink>
                </Menu.Item>

                <Menu.Item
                  key={getKeyMenuByPath(RouteNames.COMMAND_CENTER_TASKS_ALERTS())}
                >
                  <NavLink to={RouteNames.COMMAND_CENTER_TASKS_ALERTS()}>{t('Alerts')}<MainMenuWarningDot isSub isVisible={!!tasksStats?.alertsHighStats?.expiring} /></NavLink>
                </Menu.Item>

                <Menu.Item
                  key={getKeyMenuByPath(RouteNames.COMMAND_CENTER_NOTIFICATION())}
                >
                  <NavLink to={RouteNames.COMMAND_CENTER_NOTIFICATION()}>{t('Notifications')}<MainMenuWarningDot isSub isVisible={!!tasksStats?.notificationHighStats?.unreadCritical} /></NavLink>
                </Menu.Item>
              </MainItemSubMenu>
            ) : (
              <MainMenuItem
                key="ActionCenter_3"
                icon={<MainMenuIcon><CompassSvg /></MainMenuIcon>}
                title="Finances"
                disabled
              >
                <NavLink to={RouteNames.COMMAND_CENTER_TASKS()}>
                  {t('Action Center')}
                </NavLink>

                <LinkLock />
              </MainMenuItem>
            )}

          {!isOrganizationInRejected
            ? (
              <MainItemSubMenu key="sub1" icon={<FileAddFilled />} title={t('Freight Quote')}>
                <Menu.Item key={getKeyMenuByPath(RouteNames.FREIGHT_QUOTE())}>
                  <NavLink to={RouteNames.FREIGHT_QUOTE()}>{t('New')}</NavLink>
                </Menu.Item>
                <Menu.Item key={getKeyMenuByPath(RouteNames.ALL_REQUESTED_QUOTES())}>
                  <NavLink to={RouteNames.ALL_REQUESTED_QUOTES()}>
                    {t('Requests')}
                  </NavLink>
                </Menu.Item>
                {/* Currently is hide */}
                {false && (
                  <Menu.Item key={getKeyMenuByPath(RouteNames.CUSTOM_QUOTE())}>
                    <NavLink to={RouteNames.CUSTOM_QUOTE()}>
                      {t('Custom Quotes')}
                    </NavLink>
                  </Menu.Item>
                )}
              </MainItemSubMenu>
            )
            : (
              <MainMenuItem
                key="Finances_5"
                icon={<FileAddFilled />}
                title="Finances"
                disabled
              >
                <NavLink to={RouteNames.FREIGHT_QUOTE()}>
                  {t('Freight Quote')}
                </NavLink>

                <LinkLock />
              </MainMenuItem>
            )}
          {isOrganizationActive ? (
            <MainItemSubMenu
              key={getKeyMenuByPath(RouteNames.SHIPMENTS())}
              icon={<MainMenuIcon><ShipFloodedSvg /></MainMenuIcon>}
              disabled={!isOrganizationActive}
              title={t('Shipments')}
            >
              <Menu.Item key={getKeyMenuByPath(RouteNames.SHIPMENTS_ACTIVE())}>
                <NavLink to={RouteNames.SHIPMENTS_ACTIVE()}>
                  {t('active')}
                </NavLink>
              </Menu.Item>
              <Menu.Item key={getKeyMenuByPath(RouteNames.SHIPMENTS_COMPLETED())}>
                <NavLink to={RouteNames.SHIPMENTS_COMPLETED()}>
                  {t('completed')}
                </NavLink>
              </Menu.Item>
              <Menu.Item key={getKeyMenuByPath(RouteNames.SHIPMENTS_CANCELLED())}>
                <NavLink to={RouteNames.SHIPMENTS_CANCELLED()}>
                  {t('cancelled')}
                </NavLink>
              </Menu.Item>
              {!isOrganizationActive ? (
                <LinkLock />
              ) : null}
            </MainItemSubMenu>
          ) : (
            <MainMenuItem
              key="3"
              icon={<MainMenuIcon><ShipFloodedSvg /></MainMenuIcon>}
              title={t('Shipments')}
              disabled
            >
              <NavLink to={RouteNames.SHIPMENTS_ACTIVE()}>
                {t('Shipments')}
              </NavLink>

              <LinkLock />
            </MainMenuItem>
          )}

          {isOrganizationActive
            ? (
              <MainItemSubMenu
                key="Finances_SubMenu_5"
                className="main-item-sub-menu"
                icon={<BankFilled />}
                title="Finances"
                disabled={!isOrganizationActive}
              >
                {!isCustomer ? (
                  <SubSubMenu
                    className={collapsedClass}
                    key="sub5_1"
                    title="Receivables"
                  >
                    <SubSubMenuItem className={collapsedClass} key={getKeyMenuByPath(RouteNames.FINANCES_INVOICES())}>
                      <NavLink to={RouteNames.FINANCES_INVOICES()} state={{ from: RECEIVABLES }}>
                        {t('Billing')}
                      </NavLink>
                    </SubSubMenuItem>
                    <SubSubMenuItem
                      className={collapsedClass}
                      key={getKeyMenuByPath(RouteNames.FINANCES_PAYMENTS())}
                    >
                      <NavLink to={RouteNames.FINANCES_PAYMENTS()} state={{ from: RECEIVABLES }}>
                        {t('Transactions')}
                      </NavLink>
                    </SubSubMenuItem>
                  </SubSubMenu>
                ) : null}
                <SubSubMenu className={collapsedClass} key="sub5_2" title="Payables">
                  <SubSubMenuItem className={collapsedClass} key={getKeyMenuByPath(RouteNames.FINANCES_AP_INVOICES())}>
                    <NavLink to={RouteNames.FINANCES_AP_INVOICES()} state={{ from: PAYABLES }}>
                      {t('Billing')}
                    </NavLink>
                  </SubSubMenuItem>
                  <SubSubMenuItem className={collapsedClass} key={getKeyMenuByPath(RouteNames.FINANCES_AP_PAYMENTS())}>
                    <NavLink to={RouteNames.FINANCES_AP_PAYMENTS()} state={{ from: PAYABLES }}>
                      {t('Transactions')}
                    </NavLink>
                  </SubSubMenuItem>
                </SubSubMenu>
              </MainItemSubMenu>
            )
            : (
              <MainMenuItem
                key="Finances_MainMenuItem"
                icon={<BankFilled />}
                title="Finances"
                disabled
              >
                <NavLink to={RouteNames.FINANCES_INVOICES()}>
                  {t('Finances')}
                </NavLink>

                <LinkLock />
              </MainMenuItem>
            )}
          <MainMenuItem
            key={getKeyMenuByPath(RouteNames.CONTACT_BOOK())}
            icon={<MainMenuIcon><MainMenuContactBookSvg /></MainMenuIcon>}
            disabled={!isOrganizationActive || isOrganizationInDraft}
          >
            <NavLink to={RouteNames.CONTACT_BOOK()}>
              {t('ContactBook')}
            </NavLink>

            {!isOrganizationActive || isOrganizationInDraft ? (
              <LinkLock />
            ) : null}
          </MainMenuItem>

          {!isCustomer && isOrganizationActive ? (
            <MainItemSubMenu key="sub2" icon={<FundOutlined />} title={t('Rates')}>
              <Menu.Item
                key={getKeyMenuByPath(RouteNames.RATES_UPLOAD())}
              >
                <NavLink to={RouteNames.RATES_UPLOAD()}>
                  {t('All Rates')}
                </NavLink>
              </Menu.Item>

              <Menu.Item
                key={getKeyMenuByPath(RouteNames.RATES_FREIGHT_LIST())}
              >
                <NavLink to={RouteNames.RATES_FREIGHT_LIST()}>
                  {t('Rates Requests')}
                </NavLink>
              </Menu.Item>

              {isAbleToViewContracts ? (
                <Menu.Item
                  key={getKeyMenuByPath(RouteNames.CONTRACTS())}
                >
                  <NavLink to={RouteNames.CONTRACTS()}>
                    {t('Contracts')}
                  </NavLink>
                </Menu.Item>
              ) : null}
            </MainItemSubMenu>
          ) : null}
        </MainMenu>
      </Layout.Sider>
    </MainMenuWrapper>
  );
};
