import React from 'react';
import { observer } from 'mobx-react-lite';
import { useSelector } from 'react-redux';

import { UC } from 'app-wrapper/controllers';
import { useMobxStore } from 'app-wrapper/mobxStores';
import { R as userManagementR } from 'user-management/repository';

import { ContractsTableComponent } from './ContractsTable.component';

export const ContractsTable = observer(() => {
  const { contracts } = useMobxStore();
  const contractsPermissions = useSelector(userManagementR.selectors.moduleFunctionalityPermissions.getContractsPermissions);

  return (
    <ContractsTableComponent
      contracts={contracts.state.contracts}
      deleteContractById={UC.contracts.deleteContractById}
      openEditContractDrawer={UC.contracts.openEditContractDrawer}
      contractsPermissions={contractsPermissions?.contractsPermission}
      loadMore={UC.contracts.loadMore}
      isLoading={contracts.state.isPaginationLoading}
      wasLastPaginationRequestLast={contracts.state.wasPaginationRequestLast}
    />
  );
});
