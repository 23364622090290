import React, { memo, useCallback } from 'react';
import { useSelector } from 'react-redux';

import { UC as appUC } from 'app-wrapper/controllers';
import { UC } from 'shipment-operations/controllers';
import { R } from 'shipment-operations/repository';

import { ValidateComponent } from './Validate.component';

const ValidateContainer = memo(({ typeDrawer }: { typeDrawer: string }) => {
  const loading = useSelector(R.selectors.shipmentTrackerRoutes.getValidationLoading);
  const isLoaded = useSelector(R.selectors.shipmentTrackerRoutes.getValidationLoaded);
  const isChangedCharges = useSelector(R.selectors.shipmentTrackerRoutes.getIsChangedChargesSum);
  const currentTotal = useSelector(R.selectors.shipmentTrackerRoutes.getSavedChargesSum);
  const newTotal = useSelector(R.selectors.shipmentTrackerRoutes.getNewTotal);
  const showAddCharge = useSelector(R.selectors.shipmentTrackerRoutes.getIsShowAddCharge);
  const shipmentId = useSelector(R.selectors.overview.getShipmentId);

  const handleAddChargeDrawer = useCallback(() => appUC.drawer.openAddRates(typeDrawer), []);
  const handleOpenChargesCostChanges = useCallback(() => appUC.drawer.openChargesCostChanges(), []);

  return (
    <ValidateComponent
      onValidate={UC.shipmentTracker.validate}
      loading={loading}
      isChangedCharges={isChangedCharges}
      isLoaded={isLoaded}
      currentTotal={currentTotal}
      newTotal={newTotal}
      showAddCharge={showAddCharge}
      openAddChargesDrawer={handleAddChargeDrawer}
      openChangesCost={handleOpenChargesCostChanges}
      typeDrawer={typeDrawer}
      savedShipmentId={shipmentId}
    />
  );
});

export { ValidateContainer as Validate };
