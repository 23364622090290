import React from 'react';
import { useSelector } from 'react-redux';

import { R } from 'user-management/repository';
import { UC } from 'user-management/controllers';
import { DocumentType } from 'shipment-operations/constants';
import { useAuthToken } from 'app-wrapper/hooks';

import { DocumentUploadComponent } from './DocumentUpload.component';

const DocumentUploadContainer = () => {
  const authToken = useAuthToken();
  const document = useSelector(R.selectors.customer.getDocumentToUpload);
  const type = useSelector(R.selectors.customer.getDocumentToUploadType) as DocumentType;
  const organizationId = useSelector(R.selectors.customer.getOrganizationId);

  return (
    <DocumentUploadComponent
      authToken={authToken}
      organizationId={organizationId}
      containerDocument={document}
      setContainerDocument={UC.customer.setDocumentToUpload}
      removeDocument={UC.customer.deleteDocumentToUpload}
      downloadDocument={UC.customer.downloadDocumentToUpload}
      documentType={type}
    />
  );
};

export { DocumentUploadContainer as DocumentUpload };
