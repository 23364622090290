import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  RFRCompletedRequestSvg, RFRRatesRequestSvg, SkypaceSpin,
} from 'app-wrapper/view/icons';

import {
  RFRCompletedRequestContentWrap, RFRCompletedRequestHeader, RFRCompletedRequestHeaderTitle, RFRCompletedRequestContentWrapImage, RFRCompletedRequestWrap,
} from './RFRCompletedRequest.styled';

interface IRFRCompletedRequestComponentProps {
  nameLogo?: string;
  step1?: boolean;
  step2?: boolean;
  step3?: boolean;
  isLoading?: boolean;
}

const RFRCompletedRequestComponent = (props: IRFRCompletedRequestComponentProps) => {
  const { t } = useTranslation();
  const {
    nameLogo,
    step1 = true,
    step2,
    step3,
    isLoading,
  } = props;

  return (
    <RFRCompletedRequestWrap>
      <RFRCompletedRequestContentWrap>

        {isLoading && (
          <SkypaceSpin />
        )}

        {step1 && !isLoading && (
          <>
            <RFRCompletedRequestContentWrapImage>
              <RFRCompletedRequestSvg />
            </RFRCompletedRequestContentWrapImage>

            <RFRCompletedRequestHeader>
              {nameLogo || t('The request was completed successfully') || ''}
            </RFRCompletedRequestHeader>
            <RFRCompletedRequestHeaderTitle>
              {t('Customer who created the request was notified about this.') || ''}
            </RFRCompletedRequestHeaderTitle>
          </>
        )}

        {step2 && !isLoading && (
          <>
            <RFRCompletedRequestHeader>
              {}
            </RFRCompletedRequestHeader>
            <RFRCompletedRequestHeaderTitle>
              {}
            </RFRCompletedRequestHeaderTitle>
          </>
        )}

        {step3 && !isLoading && (
          <>
            <RFRCompletedRequestContentWrapImage>
              <RFRRatesRequestSvg />
            </RFRCompletedRequestContentWrapImage>

            <RFRCompletedRequestHeader>
              {nameLogo || t('Thank you! Our team is working on it.') || ''}
            </RFRCompletedRequestHeader>
            <RFRCompletedRequestHeaderTitle>
              {t('You will be notified by email once we have quotes for you, within the next 72 hours.') || ''}
            </RFRCompletedRequestHeaderTitle>
          </>
        )}

      </RFRCompletedRequestContentWrap>
    </RFRCompletedRequestWrap>
  );
};

const RFRCompletedRequestComponentCache = memo(RFRCompletedRequestComponent);

export { RFRCompletedRequestComponentCache as RFRCompletedRequestComponent };
