import styled, { css } from 'styled-components';

export const textColorFirst = css`
  color: ${({ theme }) => theme.themesColors.characterBrandingTitle85};
`;

export const divFlex = css`
  display: flex;
  position: relative;
  ${textColorFirst}
`;

export const borderAfter = css`
  position: absolute;
  content: '';
  border: 1px solid ${({ theme }) => theme.themesColors.neutralBranding5};
  left: calc(50% + 0.5px);
  transform: translateX(-50%);
  z-index: 2;
  height: 101%;
`;

// Content

export const AccessorialsTabItemRowItemWrapper = styled.div.attrs((props: { borderShow: boolean }) => ({
  borderShow: props.borderShow,
  'data-class': 'AccessorialsTabItemRowItemWrapper',
}))`
  ${divFlex}
  width: calc(100% - 19px - 19px);
  margin-left: 19px;
  margin-right: 19px;
  ${({ borderShow }) => borderShow && 'border-bottom: 1px solid;'};
  border-color: ${({ theme }) => theme.themesColors.neutralBranding4};
  border-radius: 3px;

  .ant-collapse {
    width: 100%;
    border: none;
    background: ${({ theme }) => theme.themesColors.neutralBranding1} !important;
    padding-right: 0 !important;
    padding-left: 0 !important;
    margin-bottom: 0px !important;
    padding-bottom: 0 !important;

    .ant-collapse-item-active {
      margin-bottom: 7px !important;
    }

    .ant-collapse-header {
      font-weight: 500;
      height: 55px;
      padding-top: 16px;
      padding-bottom: 16px;
      padding-left: 0 !important;
      transition: none;
    }
    .ant-collapse-content {
      .ant-collapse-content-box {
        padding: 0;
      }
      .ant-collapse-header {
      padding-top: 8px;
      padding-bottom: 8px;
      }
    }
    .ant-collapse {
      .ant-collapse-header {
        font-weight: 400;
        height: 40px;
      }
    .ant-collapse {
      .ant-collapse-header {
          height: 36px;
        }
      }
    }
  }
  .AccessorialsTabItemRowItemContainerItem__Fees {
    height: 38px;
  }
  .AccessorialsTabItemRowItem__Fees,
  .AccessorialsTabItemRowItem__Services {
    .ant-collapse-item-active {
      margin-left: 34px !important;
      > .ant-collapse-header {
        left: -34px !important;
        width: 100% !important;
      }
      .ant-collapse-content-active {
        padding-left: 0 !important;
        margin-left: 7px !important;
        width: calc(100% - 42px) !important;
      }
    }
  }
  .ant-collapse-content {
    border-color: ${({ theme }) => theme.themesColors.neutralBranding4};
  }
  .ant-collapse-content-box {
    padding-right: 0;
  }
  .ant-collapse-header {
    width: 100%;
    padding-right: 0 !important;
  }
  .ant-collapse-item {
    border-color: ${({ theme }) => theme.themesColors.neutralBranding4};

    .ant-collapse-item {
      width: calc(100%);
    }

    &:nth-last-child(1) {
      border-bottom: none;
    }
    &.ant-collapse-item-active {
      margin-left: 19px;
      > .ant-collapse-header {
        color: ${({ theme }) => theme.themesColors.primaryBranding6} !important;
        left: -19px;
        width: calc(100% + 19px);
      }
      .ant-collapse-content-box {
        padding-left: 0;
        .ant-collapse-header {
          padding-left: 0;
        }
      }
      .ant-collapse-content {
        .ant-collapse-content {
          .ant-collapse-content {
            margin-left: 10px;
            width: calc(100% - 10px);
          }
        }
      }
      .ant-collapse-item-active {
        margin-left: 0px;
        width: 100%;
        > .ant-collapse-header {
          left: 0px;
          width: 100%;
        }
        .ant-collapse-content {
          margin-left: 41px;
          width: calc(100% - 41px);
        }
        .ant-collapse-item {
          margin-left: -15px;
          width: calc(100% + 15px);
        }
        .ant-collapse-item-active {
          margin-left: 19px;
          width: calc(100% - 19px);
          > .ant-collapse-header {
            left: -34px;
            width: calc(100% + 34px);
          }
          .ant-collapse-content {
            padding-left: 0;
          }
        }
      }
    }
  }
  .FreightQuoteFormRequest__Row__Commodity__content_title_icon {
    color: ${({ theme }) => theme.themesColors.darkGrayMiddle};
    margin-left: 4px;
  }
`;

export const AccessorialsTabItemRowItemSummary = styled.div`
  ${divFlex}
  font-size: 16px;
  font-weight: 700;
  color: ${({ theme }) => theme.themesColors.neutralBranding10};
`;

export const AccessorialsTabItemRowItemSummaryNext = styled.div`
  ${divFlex}
  font-size: 15px;
  font-weight: 500;
  color: ${({ theme }) => theme.themesColors.neutralBranding10};
`;

export const AccessorialsTabItemRowContainerItemSummary = styled.div`
  ${divFlex}
  font-size: 13px;
  font-weight: 400;
  color: ${({ theme }) => theme.themesColors.neutralBranding10};
`;
