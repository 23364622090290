import React, { FC, HTMLAttributes } from 'react';

import { colors } from 'app-wrapper/view/themes/themesColors';

interface IIconProps extends HTMLAttributes<SVGElement> {
  color?: string
  height?: number | string
  width?: number | string
}

export const WaypointTypeDoorIcon: FC<IIconProps> = ({
  color = colors.gray43,
  width = 16,
  height = 13,
  ...other
}) => (
  <svg width={width} height={height} viewBox="0 0 16 13" {...other}>
    <path d="M13.2777 9.11112H8.99989V6.3889C8.99989 6.19445 8.80545 6.00001 8.611 6.00001H3.16656C2.94781 6.00001 2.77767 6.19445 2.77767 6.3889V12.6111C2.77767 12.8299 2.94781 13 3.16656 13H13.2777C13.4721 13 13.6666 12.8299 13.6666 12.6111V9.50001C13.6666 9.30556 13.4721 9.11112 13.2777 9.11112ZM7.83322 11.8333H3.94434V10.2778H7.83322V11.8333ZM7.83322 8.72223H3.94434V7.16667H7.83322V8.72223ZM12.4999 11.8333H8.99989V10.2778H12.4999V11.8333ZM14.9548 3.25348L8.85406 0.701396C8.44086 0.531257 7.97906 0.531257 7.56586 0.701396L1.46517 3.25348C0.833225 3.49653 0.444336 4.10417 0.444336 4.78473V12.6111C0.444336 12.8299 0.614475 13 0.833225 13H1.22211C1.41656 13 1.611 12.8299 1.611 12.6111V4.78473C1.611 4.56598 1.70822 4.39584 1.90267 4.32292L8.02767 1.77084C8.1492 1.72223 8.27072 1.72223 8.39225 1.77084L14.5173 4.32292C14.7117 4.39584 14.8332 4.56598 14.8332 4.78473V12.6111C14.8332 12.8299 15.0034 13 15.2221 13H15.611C15.8054 13 15.9999 12.8299 15.9999 12.6111V4.78473C15.9999 4.10417 15.5867 3.49653 14.9548 3.25348Z" fill={color} />
  </svg>
);
