import {
  IsEnum,
  IsOptional,
} from 'class-validator';

import { EPermissionAttributePolicy } from 'monetary/constants';
import { BaseDTM } from 'proto/BaseDTM';

export type TRFQAllRequestQuotaAvailabilityDTM = {
  quotaItemContractName?: EPermissionAttributePolicy
  organizationsListLoad?: EPermissionAttributePolicy
}

export class RFQAllRequestQuotaAvailabilityDTM extends BaseDTM<TRFQAllRequestQuotaAvailabilityDTM> {
  @IsOptional()
  @IsEnum(EPermissionAttributePolicy)
  quotaItemContractName: EPermissionAttributePolicy

  @IsOptional()
  @IsEnum(EPermissionAttributePolicy)
  organizationsListLoad: EPermissionAttributePolicy
}
