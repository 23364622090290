import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ECarrierSCAC } from 'monetary/constants';

import { CarrierLogo } from 'app-wrapper/view/guideline';
import {
  MBLCompanyInformation,
  MBLCompanyInformationItem,
  MBLCompanyText,
  MBLCompanyTitle,
  MBLCompanyLogoWrapper,
  Tag,
} from './MBLCompanyInfo.styled';

interface MBLCompanyInfoComponentProps {
  freightPaymentLocation: string;
  contactDetails: string[];
  carrierSCAC: string;
}

export const MBLCompanyInfo: FC<MBLCompanyInfoComponentProps> = ({
  freightPaymentLocation,
  contactDetails,
  carrierSCAC,
}) => {
  const { t } = useTranslation();

  return (
    <MBLCompanyInformation>
      <MBLCompanyLogoWrapper>
        <CarrierLogo carrierSCAC={carrierSCAC as ECarrierSCAC} />
      </MBLCompanyLogoWrapper>

      <MBLCompanyInformationItem>
        <MBLCompanyTitle>
          {t('Booking Office / Freight Payment Location')}
        </MBLCompanyTitle>

        {freightPaymentLocation ? (
          <MBLCompanyText>
            {freightPaymentLocation}
          </MBLCompanyText>
        ) : <Tag>{t('Not Provided')}</Tag>}
      </MBLCompanyInformationItem>

      <MBLCompanyInformationItem>
        <MBLCompanyTitle>
          {t('Contact Details')}
        </MBLCompanyTitle>

        {contactDetails.length ? (
          <>
            {contactDetails.map((text) => (
              <MBLCompanyText key={`MBLCompanyInfo_${text}`}>
                {text}
              </MBLCompanyText>
            ))}
          </>
        ) : <Tag>{t('Not Provided')}</Tag>}
      </MBLCompanyInformationItem>
    </MBLCompanyInformation>
  );
};
