import React, {
  FC, memo, useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import Skeleton from 'antd/es/skeleton';

import { REQUEST_STATUS } from 'app-wrapper/constants';
import {
  EditPanSvg, PlusSvg,
} from 'app-wrapper/view/icons';

import {
  CompanyAddressDTM,
} from 'user-management/models/dtm';

import {
  CBByIdContentTable,
  CBByIdContentTableContentLink,
  CBByIdContentTableContentItem,
  CBByIdContentTableContentItemTop,
  CBByIdContentTableContentTitle,
  CBByIdContentTableHeader,
  CBByIdContentTableHeaderItem,
  CBByIdSpanEllipsis,
  CBByIdContentTableTitle,
  CBByIdContentTableTitleWrap,
  CBByIdContentTableHeaderAddBtn,
  CBByIdContentTableContentPrimary,
} from './ContactBookByIdListTable.styled';

interface ICBByIdTableProps {
  onStart: () => void;
  nextPageTasks: () => void;
  onClickAddAddress: () => void;
  onClickEditAddress: (id: string) => () => void;
  isLoad?: boolean;
  isLoadAdd?: string;
  companyListAddresses?: CompanyAddressDTM
  isEnd?: boolean;
}

const CBByIdTable: FC<ICBByIdTableProps> = (props) => {
  const {
    onStart,
    nextPageTasks,
    onClickAddAddress,
    onClickEditAddress,
    isLoad,
    isLoadAdd,
    companyListAddresses,
    isEnd,
  } = props;

  const { t } = useTranslation();

  useEffect(() => {
    onStart();
  }, []);

  return (
    <CBByIdContentTable>
      <CBByIdContentTableTitleWrap>
        <CBByIdContentTableTitle>
          {companyListAddresses?.getBothAddressLine()}
        </CBByIdContentTableTitle>

        <CBByIdContentTableHeaderAddBtn
          onClick={onClickAddAddress}
        >
          <PlusSvg />
          <span>{t('AddContactPerson')}</span>
        </CBByIdContentTableHeaderAddBtn>
      </CBByIdContentTableTitleWrap>

      <CBByIdContentTableHeader>
        <CBByIdContentTableHeaderItem
          width="23.7%"
        >
          {t('ContactPerson')}
        </CBByIdContentTableHeaderItem>
        <CBByIdContentTableHeaderItem
          width="23.7%"
        >
          {t('Email')}
        </CBByIdContentTableHeaderItem>
        <CBByIdContentTableHeaderItem
          width="23.7%"
        >
          {t('Phone')}
        </CBByIdContentTableHeaderItem>
        <CBByIdContentTableHeaderItem
          width="23.7%"
        >
          {t('Additional Phone')}
        </CBByIdContentTableHeaderItem>
      </CBByIdContentTableHeader>

      <div>
        <InfiniteScroll
          dataLength={Number(companyListAddresses?.contacts?.length || 0)}
          next={nextPageTasks}
          hasMore={!isEnd}
          loader={
            isLoad
            && (<Skeleton />)
          }
          scrollableTarget="Layout"
        >
          {companyListAddresses?.contacts?.map((item) => (
            <CBByIdContentTableContentItem key={`CBByIdContentTableContentItem_${item.id}`}>
              <CBByIdContentTableContentItemTop>
                <CBByIdContentTableContentTitle width="23.7%">
                  <CBByIdSpanEllipsis>
                    {item.fullName}
                  </CBByIdSpanEllipsis>
                  {item.primary && (<CBByIdContentTableContentPrimary>{t('Primary')}</CBByIdContentTableContentPrimary>)}
                </CBByIdContentTableContentTitle>
                <CBByIdContentTableContentTitle width="23.7%">
                  <CBByIdSpanEllipsis>
                    {item.email}
                  </CBByIdSpanEllipsis>
                </CBByIdContentTableContentTitle>
                <CBByIdContentTableContentTitle width="23.7%">
                  <CBByIdSpanEllipsis>
                    {item?.phone || ''}
                  </CBByIdSpanEllipsis>
                </CBByIdContentTableContentTitle>
                <CBByIdContentTableContentTitle width="23.7%">
                  <CBByIdSpanEllipsis>
                    {item?.phone2 || ''}
                  </CBByIdSpanEllipsis>
                </CBByIdContentTableContentTitle>
                <CBByIdContentTableContentLink
                  width="3.9%"
                  onClick={onClickEditAddress(item.customId || '')}
                >
                  <EditPanSvg />
                </CBByIdContentTableContentLink>
              </CBByIdContentTableContentItemTop>

            </CBByIdContentTableContentItem>
          ))}
        </InfiniteScroll>
        {isLoadAdd === REQUEST_STATUS.pending && (<Skeleton />)}
      </div>

    </CBByIdContentTable>
  );
};

const CBByIdTableCache = memo(CBByIdTable);

export { CBByIdTableCache as CBByIdTable };
