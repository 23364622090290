import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { UC } from 'shipment-operations/controllers';
import { R } from 'shipment-operations/repository';

import { ShipperSelectComponent } from './ShipperSelect.component';

const ShipperSelectContainer: FC = () => {
  const companiesList = useSelector(R.selectors.bookingWizard.getCompaniesList);
  const company = useSelector(R.selectors.bookingWizard.getSelectedCompany);
  const companyError = useSelector(R.selectors.bookingWizard.getCompanyError);

  return (
    <ShipperSelectComponent
      companiesList={companiesList}
      company={company}
      error={companyError}
      setCompanyName={UC.bookingWizard.setShipperCompany}
      openAddCompanyDrawer={UC.bookingWizardDrawer.openAddCompanyDrawer}
    />
  );
};

export { ShipperSelectContainer as ShipperSelect };
