import React, { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'antd/es/skeleton';

import { ContainerDocumentDTM } from 'shipment-operations/models/dtm';

import {
  Container,
  ContainerWrap,
  Button,
} from './Footer.styled';

interface FooterComponentProps {
  onSubmitManualBooking: (shipmentId: string) => void;
  shipmentId: string;
  isLoading: boolean;
  document: ContainerDocumentDTM | null;
}

export const FooterComponent: FC<FooterComponentProps> = ({
  isLoading,
  shipmentId,
  onSubmitManualBooking,
  document,
}) => {
  const { t } = useTranslation();

  const handleSubmitManualBooking = useCallback(() => {
    onSubmitManualBooking(shipmentId);
  }, [onSubmitManualBooking, shipmentId]);

  const isDisabled = useMemo(() => !!(document && document.status === 'uploading'), [document]);

  return (
    <Container>
      {isLoading ? <Skeleton /> : (
        <ContainerWrap>
          <Button
            disabled={isDisabled}
            data-testid="manual-booking-wizard-submit-button"
            onClick={handleSubmitManualBooking}
          >
            {t('Submit Confirmation')}
          </Button>
        </ContainerWrap>
      )}
    </Container>
  );
};
