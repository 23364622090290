import styled from 'styled-components';
import Typography from 'antd/es/typography';
import { SearchOutlined } from '@ant-design/icons';

import { AutoComplete as CommonAutoComplete } from 'app-wrapper/view/components';

export const Wrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Image = styled.img`
  width: 50px;
  height: 50px;
`;

export const Content = styled.div`
  margin-left: 12px;
  display: flex;
  flex-direction: column;
`;

export const Title = styled(Typography)`
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
`;

export const Text = styled(Typography)`
  font-size: 12px;
  line-height: 20px;
  color: rgba(115, 129, 155, 1);
`;

export const AutoComplete = styled(CommonAutoComplete)`
  width: 254px;
  margin-left: auto;
`;

export const SearchWrap = styled.button`
  margin-left: 4px;
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  background-color: rgba(78, 93, 112, 1);
  border: none;
  cursor: pointer;
`;

export const SearchIcon = styled(SearchOutlined)`
  font-size: 16px;
  color: rgba(255,255,255,1);
`;

export const NotFound = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const NotFoundIcon = styled(SearchOutlined)`
  font-size: 12px;
  color: rgba(115, 129, 155, 1);
`;

export const NotFoundText = styled(Typography)`
  margin-left: 8px;
  font-size: 12px;
  line-height: 20px;
  color: rgba(115, 129, 155, 1);
`;
