import React, { FC, memo } from 'react';
import { useSelector } from 'react-redux';

import { DataType } from 'shipment-operations/constants';
import { R } from 'shipment-operations/repository';
import { EditableCellComponent } from './EditableCell.component';

interface EditableCellProps {
  editable: boolean;
  children: React.ReactNode;
  dataIndex: string
  record: DataType
  handleSave: (record: DataType, type: string) => void;
  emptyRender?: boolean
}

const EditableCellContainer: FC<EditableCellProps> = memo((props) => {
  const chargesOption = useSelector(R.selectors.addRates.getChargeCodesOptions);
  const isError = useSelector(R.selectors.addRates.getIsTableError);
  const uniqContainers = useSelector(R.selectors.addRates.getUniqContainers);

  return (
    <EditableCellComponent
      chargesOption={chargesOption}
      isError={isError}
      uniqContainers={uniqContainers}
      {...props}
    />
  );
});

export { EditableCellContainer as EditableCell };
