import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Option } from 'app-wrapper/view/components';
import { CargoDTM } from 'shipment-operations/models/dtm';
import { PackingGroupType, PackingGroupTypeNames } from 'shipment-operations/constants';

import { SelectStyled } from './PackagingGroupSelect.styled';

interface PackagingGroupSelectComponentProps {
  packingGroup: CargoDTM['packingGroup']
  error?: boolean
}

export const PackagingGroupSelectComponent: FC<PackagingGroupSelectComponentProps> = ({
  packingGroup,
  error,
}) => {
  const { t } = useTranslation();

  return (
    <SelectStyled
      value={packingGroup}
      error={error}
      allowClear={!!packingGroup}
      size="middle"
      disabled
    >
      {Object.keys(PackingGroupTypeNames).map((type) => (
        <Option key={type} value={type}>
          {t(PackingGroupTypeNames[type as PackingGroupType])}
        </Option>
      ))}
    </SelectStyled>
  );
};
