import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { UC } from 'shipment-operations/controllers';
import { R } from 'shipment-operations/repository';

import { ShipmentShippingPartiesComponent } from './ShipmentShippingParties.component';

const ShipmentShippingPartiesContainer: FC = () => {
  const isFormsHaveChanges = useSelector(R.selectors.shippingParties.isFormsHaveChanges);

  return (
    <ShipmentShippingPartiesComponent
      isFormsHaveChanges={isFormsHaveChanges}
      uploadData={UC.shippingParties.firstDataUpload}
      discardChanges={UC.shippingParties.setFormToUnchangedState}
      clearPageState={UC.shippingParties.clearData}
    />
  );
};

export { ShipmentShippingPartiesContainer as ShipmentShippingParties };
