import styled, { css } from 'styled-components';
import TextArea from 'antd/es/input/TextArea';

import { divFlex } from 'app-wrapper/view/themes/mixin';
import { Input } from 'app-wrapper/view/components';

const leftRowMinWidth = '143px';
export const SDCFMainContentRirhtRowMinWidth = '143px';

export const SDCFMainContentLeftRowWidth = '35%';
export const SDCFMainContentRightRowWidth = '65%';

export const SDCFMainContentTextTitle = css`
  font-size: 12px;
  font-weight: 400;
  color: ${({ theme }) => theme?.themesColors?.neutralBranding10};
  margin-bottom: 4px;
`;

const borderInput = css`
  border-color: ${({ theme }) => theme?.themesColors?.neutralBranding5};
`;

export const SDCFMainContentSpin = styled.div.attrs({
  'data-class': 'SDCFMainContentSpin',
})`
  ${divFlex}
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 16px;
`;

export const SDCFMainContentForm = styled.div.attrs({
  'data-class': 'SDCFMainContentForm',
})`
  ${divFlex}
  flex-direction: column;
  width: 100%;
  margin-top: 16px;
  max-width: 700px;
`;

export const SDCFMainContentFormDocument = styled.div.attrs((props: {
  isNext: boolean,
}) => ({
  isNext: props.isNext,
  'data-class': 'SDCFMainContentFormDocument',
}))`
  ${divFlex}
  ${({ isNext }) => isNext && 'margin-top: 8px;'};
`;

export const SDCFMainContentFormDocumentType = styled.div.attrs({
  'data-class': 'SDCFMainContentFormDocumentType',
})`
  ${divFlex}
  flex-direction: column;
  min-width: ${leftRowMinWidth};
  width: ${SDCFMainContentLeftRowWidth};
`;

export const SDCFMainContentFormDocumentTypeTitle = styled.div.attrs({
  'data-class': 'SDCFMainContentFormDocumentTypeTitle',
})`
  ${divFlex}
  ${SDCFMainContentTextTitle}
`;

export const SDCFMainContentFormDocumentFile = styled.div.attrs({
  'data-class': 'SDCFMainContentFormDocumentFile',
})`
  ${divFlex}
  flex-direction: column;
  min-width: ${SDCFMainContentRirhtRowMinWidth};
  width: ${SDCFMainContentRightRowWidth};
`;

export const SDCFMainContentFormDocumentFileTitle = styled.div.attrs({
  'data-class': 'SDCFMainContentFormDocumentFileTitle',
})`
  ${divFlex}
  ${SDCFMainContentTextTitle}
  margin-left: 4px;
`;

export const SDCFMainContentFormNotes = styled.div.attrs({
  'data-class': 'SDCFMainContentFormNotes',
})`
  ${divFlex}
  flex-direction: column;
  margin-top: 16px;
`;

export const SDCFMainContentFormNotesTitle = styled.div.attrs({
  'data-class': 'SDCFMainContentFormNotesTitle',
})`
  ${divFlex}
  ${SDCFMainContentTextTitle}
  line-height: 20px;
  flex-direction: column;
`;

export const SDCFMainContentFormNotesTextarea = styled(TextArea).attrs({
  'data-class': 'SDCFMainContentFormNotesTextarea',
})`
  ${divFlex}
  ${borderInput}
  flex-direction: column;
  font-size: 12px;

  ::placeholder {
    font-size: 12px;
    font-weight: 400;
    color: ${({ theme }) => theme?.themesColors?.secondaryDot45};
  }
`;

export const SDCFMainContentFormReference = styled.div.attrs({
  'data-class': 'SDCFMainContentFormReference',
})`
  ${divFlex}
  flex-direction: column;
  margin-top: 16px;
`;

export const SDCFMainContentFormReferenceTitle = styled.div.attrs({
  'data-class': 'SDCFMainContentFormReferenceTitle',
})`
  ${divFlex}
  ${SDCFMainContentTextTitle}
  line-height: 20px;
`;

export const SDCFMainContentFormReferenceInput = styled(Input).attrs({
  'data-class': 'SDCFMainContentFormReferenceInput',
})`
  ${divFlex};
  ${borderInput}
  max-width: 292px;
  font-size: 12px;
`;
