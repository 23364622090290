import React, { FC, memo } from 'react';
import { CommandCenterTasks } from './CommandCenter';

const CommandCenterTasksPage: FC = () => (
  <CommandCenterTasks />
);

const CommandCenterTasksPageCache = memo(CommandCenterTasksPage);

export { CommandCenterTasksPageCache as CommandCenterTasksPage };
