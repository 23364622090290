import React, {
  FC, useCallback,
} from 'react';
import { useTranslation } from 'react-i18next';
import { ShipmentPreviewDTM } from 'shipment-operations/models/dtm';
import {
  ContentBlock, TableTitle, Table, TableContentRow, SpinnerWrap,
} from 'app-wrapper/view/guideline';
import { useNavigate } from 'react-router';
import { Button, ServicesDivider } from 'app-wrapper/view/components';
import { ColumnType } from 'antd/es/table';
import Spinner from 'antd/es/spin';
import {
  TableEmptyStateContainer,
} from './Shipment.styled';

interface IContentTableTasksProps {
  shipments: ShipmentPreviewDTM[]
  columns: ColumnType<object>[]
  title: string
  emptyState: React.ReactNode
  redirectLink: string
  isLoading: boolean
}

export const ShipmentComponent: FC<IContentTableTasksProps> = ({
  shipments, columns, redirectLink, title, isLoading,
  emptyState,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleViewMoreClick = useCallback(() => {
    navigate(redirectLink);
  }, []);

  return (
    <ContentBlock style={{ width: '100%', minHeight: '258px' }}>
      {isLoading ? (
        <SpinnerWrap>
          <Spinner size="large" />
        </SpinnerWrap>
      ) : (
        <TableContentRow style={{ height: '100%' }}>
          <TableTitle>
            {title}
          </TableTitle>

          {shipments.length > 0 ? (
            <>
              <div>
                <ServicesDivider noMargin />

                <Table
                  pagination={false}
                  dataSource={shipments}
                  columns={columns}
                />
              </div>
              <div>
                {shipments.length > 2
                  ? (
                    <Button type="default" onClick={handleViewMoreClick}>
                      {t('View more')}
                    </Button>
                  )
                  : null}
              </div>
            </>
          ) : (
            <>
              <ServicesDivider noMargin />
              <TableEmptyStateContainer>
                {emptyState}
              </TableEmptyStateContainer>
            </>
          )}
        </TableContentRow>
      )}
    </ContentBlock>
  );
};
