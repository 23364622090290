import { createSelector } from 'reselect';

import { RootState } from 'app-wrapper/store';
import { RFQServiceByIdContentRoutesTransportation } from 'monetary/models/dtm/Quotas';
import {
  CargoDTM,
  ManualBookingTransportationErrorsDTM,
  RouteLegDTM,
  TransportPlanDTM,
  ContainerTypeWithQTYDTM,
} from 'shipment-operations/models/dtm';

const localState = (state: RootState) => state.manualBookingWizard;

const getIsLoading = createSelector(
  localState,
  (state) => state.isLoading,
);

const getIsWizardOpened = createSelector(
  localState,
  (state) => state.isWizardOpened,
);

const getTransportations = createSelector(
  localState,
  (state) => state.transportations,
);

const getTransportLegs = createSelector(
  localState,
  (state) => state.legs,
);

const getIsCarrierReferenceDisabled = createSelector(
  localState,
  (state) => state.isCarrierReferenceDisabled,
);

const getTransportationById = (transportationId: number) => createSelector(
  getTransportations,
  (transportations) => transportations.find(({ id }) => id === transportationId) as RFQServiceByIdContentRoutesTransportation,
);

const getTransportLegById = (legId: number) => createSelector(
  getTransportLegs,
  (legs) => legs.find(({ id }) => id === legId) as RouteLegDTM,
);

const getTransportationErrorsById = (transportationId: number) => createSelector(
  localState,
  (state) => state.transportationsErrors.find((errors) => errors.transportationId === transportationId) as ManualBookingTransportationErrorsDTM,
);

const getErrors = createSelector(
  localState,
  (state) => state.errors,
);

const getCarrierReferenceNumber = createSelector(
  localState,
  (state) => state.carrierReferenceNumber,
);

const getConfirmationDocument = createSelector(
  localState,
  (state) => state.bookingConfirmationDocument,
);

const getCargos = createSelector(
  localState,
  (state) => state.cargos,
);

const getDefaultCargo = createSelector(
  localState,
  (state) => state.defaultCargo,
);

const getHasTemperatureControl = createSelector(
  localState,
  (state) => state.hasTemperatureControl,
);

const getHasHazmats = createSelector(
  localState,
  (state) => state.hasHazmats,
);

const getCargoById = (cargoId: number) => createSelector(
  localState,
  ({ cargos }) => {
    const cargo = cargos.find(({ id }) => id === cargoId) as CargoDTM;

    return cargo;
  },
);

const getIsHazmatToggledByCargoId = (cargoId: number) => createSelector(
  localState,
  (state) => {
    const { toggledHazmatCargoIds } = state;

    return !!toggledHazmatCargoIds.find((id) => id === cargoId);
  },
);

const getIsFirstCargo = (cargoId: number) => createSelector(
  localState,
  (state) => {
    const { cargos } = state;
    const cargoIndex = cargos.findIndex(({ id }) => cargoId === id);

    return cargoIndex === 0;
  },
);

const getTemperatureControl = createSelector(
  localState,
  (state) => state.temperatureControl,
);

const getTransportPlanWithEditedTransportations = createSelector(
  localState,
  (state) => {
    const { transportPlan, transportations } = state;

    return TransportPlanDTM.fromPlain({
      ...transportPlan as TransportPlanDTM,
      transportations,
    });
  },
);

const getIsManualBookingWizardValid = createSelector(
  localState,
  (state) => {
    const { errors, transportationsErrors } = state;
    let isValid = true;

    if (transportationsErrors.some((transportationErrors) => transportationErrors.hasErrors())) {
      isValid = false;
    }

    if (errors.hasErrors()) {
      isValid = false;
    }

    return isValid;
  },
);

const getResponseErrorMessage = createSelector(
  localState,
  (state) => state.responseErrorMessage,
);

const getContainersWithQuantity = createSelector(
  localState,
  (state) => {
    const { containers } = state;
    const containersWithQuantity: ContainerTypeWithQTYDTM[] = [];

    containers.forEach((container) => {
      const containerWithQTY = containersWithQuantity.find(({ type }) => type === container.type);

      if (containerWithQTY) {
        containerWithQTY.quantity += 1;
      } else {
        containersWithQuantity.push(ContainerTypeWithQTYDTM.fromPlain({
          type: container.type,
          quantity: 1,
        }));
      }
    });

    return containersWithQuantity;
  },
);

const getHasContainers = createSelector(
  localState,
  (state) => !!state.containers.length,
);

export const manualBookingWizardSelectors = {
  getIsWizardOpened,
  getIsLoading,
  getTransportations,
  getTransportLegs,
  getIsCarrierReferenceDisabled,
  getTransportationById,
  getTransportLegById,
  getTransportationErrorsById,
  getErrors,
  getCarrierReferenceNumber,
  getConfirmationDocument,
  getCargos,
  getDefaultCargo,
  getHasTemperatureControl,
  getHasHazmats,
  getCargoById,
  getIsHazmatToggledByCargoId,
  getIsFirstCargo,
  getTemperatureControl,
  getTransportPlanWithEditedTransportations,
  getIsManualBookingWizardValid,
  getResponseErrorMessage,
  getContainersWithQuantity,
  getHasContainers,
};
