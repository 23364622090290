import React, { FC } from 'react';
import capitalize from 'lodash/capitalize';
import { useTranslation } from 'react-i18next';

import { ChargeDTM } from 'shipment-operations/models/dtm';

import {
  TableRow,
  Cell,
} from './ChargeItem.styled';

interface ChargeItemProps {
  charge: ChargeDTM;
}

export const ChargeItem: FC<ChargeItemProps> = ({ charge }) => {
  const { t } = useTranslation();

  return (
    <TableRow>
      <Cell
        flex={3}
        justifyContent="flex-start"
      >
        {charge.chargeCode.description}
      </Cell>
      <Cell justifyContent="flex-start">{charge.apPaymentTerms ? capitalize(charge.apPaymentTerms.toLowerCase()) : 'N/A'}</Cell>
      <Cell justifyContent="flex-start">{charge.currency}</Cell>
      <Cell justifyContent="flex-end">{charge.buyTotalCost ? charge.buyTotalCost.toFixed(2) : t('Included')}</Cell>
    </TableRow>
  );
};
