import { R as AuthR } from 'authentication/repository';
import { BaseController, controller } from 'proto/BaseController';
import { EAccountDepartmentType } from 'user-management/constants';
import { IPostOrganizationTeamContract, IPutOrganizationTeamContract } from 'user-management/models/contracts';
import {
  EOrganizationStatus,
  OrganizationDTM,
  OrganizationMemberDTM,
  TeamDTM,
} from 'user-management/models/dtm';
import { R as userManagementR, R } from 'user-management/repository';
import { TeamsUseCase } from 'user-management/usecases';

const CUSTOMERS_COUNT_PER_PAGE = 1000;

@controller
export class TeamsController extends BaseController {
  public initTeams = async () => {
    let teams: TeamDTM[] = [];
    const userEmail = AuthR.selectors.auth.getEmail(this.store.getState());

    this.dispatch(R.actions.teams.setIsLoading(true));

    const organization = await R.services.organization.getCurrentOrganization();

    this.dispatch(R.actions.teams.setOrganizationId(organization.id));

    const currentUser = await userManagementR.services.organization.getOrganizationMember(organization.id, userEmail);
    teams = await R.services.teams.getTeams(organization.id);

    teams = await Promise.all(teams.map((team) => new TeamsUseCase(this).fullFilTeamWithCustomers(team)));

    this.dispatch(R.actions.teams.setTeams(teams));
    this.dispatch(R.actions.teams.setCurrentUser(currentUser));
    this.dispatch(R.actions.teams.setIsLoading(false));
  }

  public initTeam = async (teamId: number | string) => {
    let organization: OrganizationDTM | null;
    let currentUser: OrganizationMemberDTM | undefined;
    const userEmail = AuthR.selectors.auth.getEmail(this.store.getState());

    this.dispatch(R.actions.teams.setIsLoading(true));

    try {
      organization = await R.services.organization.getCurrentOrganization();
      currentUser = await userManagementR.services.organization.getOrganizationMember(organization.id, userEmail);
    } catch (e) {
      this.dispatch(R.actions.teams.setIsLoading(false));

      throw e;
    }

    this.dispatch(R.actions.teams.setOrganizationId(organization.id));

    let team = await R.services.teams.getTeam(organization.id, teamId);
    team = await this.fullfilTeamWithMembersAndCustomers(team);
    const teamMembers = await R.services.teams.getMembersByTeam(organization.id, +teamId);

    this.dispatch(R.actions.teams.setTeamMembers(teamMembers));
    this.dispatch(R.actions.teams.setCurrentUser(currentUser));
    this.dispatch(R.actions.teams.setTeam(team));
    this.dispatch(R.actions.teams.setIsLoading(false));
  }

  public onChangeTempCustomers = (tempCustomers: number[]) => {
    this.dispatch(R.actions.teams.setTempCustomers(tempCustomers));
  };

  public onChangeTempTeamName = (name: string) => {
    this.dispatch(R.actions.teams.setTempTeamName(name));
  };

  public removeCustomerFromTeam = async (customerId: number) => {
    const orgId = R.selectors.teams.getOrganizationId(this.store.getState());
    const team = R.selectors.teams.getTeam(this.store.getState());

    if (!team) {
      return;
    }

    this.dispatch(R.actions.teams.setIsLoading(true));
    await R.services.teams.removeCustomerFromTeam(orgId, team.id, customerId);
    this.dispatch(R.actions.teams.setIsLoading(false));

    const { customers } = team;
    const tempCustomers = [...customers];
    const customerIndex = tempCustomers.findIndex(({ id }) => customerId === id);
    tempCustomers.splice(customerIndex, 1);

    this.dispatch(R.actions.teams.setTeam(TeamDTM.fromPlain({
      ...team,
      customers: tempCustomers,
    })));
  }

  public initTeamDrawer = async () => {
    this.dispatch(R.actions.teams.setIsDrawerLoading(true));
    const teamToEdit = R.selectors.teams.getTeam(this.store.getState());
    const organizationId = R.selectors.teams.getOrganizationId(this.store.getState());

    let customers: OrganizationDTM[] = [];
    let members: OrganizationMemberDTM[] = [];

    customers = await R.services.organization.getOrganizations(0, CUSTOMERS_COUNT_PER_PAGE, EOrganizationStatus.ACTIVE);
    members = await R.services.organization.getOrganizationMembersList(organizationId);

    this.dispatch(R.actions.teams.setCustomers([
      ...(teamToEdit?.customers ? teamToEdit.customers : []),
      ...customers.filter(({ accountTeam }) => !accountTeam),
    ]));
    this.dispatch(R.actions.teams.setMembers(members.filter((member) => member.enabled)));

    if (teamToEdit) {
      this.dispatch(R.actions.teams.setTempTeamName(teamToEdit.name));
      this.dispatch(R.actions.teams.setTempMembers(teamToEdit.members.map(({ email }) => email)));
      this.dispatch(R.actions.teams.setTempCustomers(teamToEdit.customers.map(({ id }) => id)));
    }

    this.dispatch(R.actions.teams.setIsDrawerLoading(false));
  }

  public openCreateTeamDrawer = () => {
    this.dispatch(R.actions.teams.setIsDrawerOpened(true));
    this.dispatch(R.actions.teams.setIsDrawerInEditMode(false));
    this.dispatch(R.actions.teams.setTeam(undefined));
    this.dispatch(R.actions.teams.clearRolesToEmailsState());

    this.initTeamDrawer();
  }

  public openEditTeamDrawer = () => {
    this.dispatch(R.actions.teams.setIsDrawerOpened(true));
    this.dispatch(R.actions.teams.setIsDrawerInEditMode(true));
    this.dispatch(R.actions.teams.clearRolesToEmailsState());

    const teamMembers = R.selectors.teams.getTeamMembers(this.store.getState());

    teamMembers.forEach((teamMember) => {
      const { role, member } = teamMember;

      if (member) {
        this.dispatch(R.actions.teams.setRolesToEmailsStateItem({ role, email: member.email }));
      }
    });

    this.initTeamDrawer();
  }

  public closeTeamDrawer = () => {
    this.dispatch(R.actions.teams.setIsDrawerOpened(false));
    this.dispatch(R.actions.teams.setTempTeamName(''));
    this.dispatch(R.actions.teams.setTempMembers([]));
    this.dispatch(R.actions.teams.setTempCustomers([]));
  }

  public createTeam = async () => {
    const teamName = R.selectors.teams.getTempTeamName(this.store.getState());
    const tempCustomers = R.selectors.teams.getTempCustomers(this.store.getState());
    const teamMembersWithRoles = R.selectors.teams.getTeamMembersWithRoles(this.store.getState());

    const teamToCreate: IPostOrganizationTeamContract = {
      name: teamName,
      customers: tempCustomers,
      members: teamMembersWithRoles,
    };

    const orgId = R.selectors.teams.getOrganizationId(this.store.getState());

    await R.services.teams.createTeam(orgId, teamToCreate);

    this.closeTeamDrawer();
    this.initTeams();
  };

  public saveTeam = async () => {
    const orgId = R.selectors.teams.getOrganizationId(this.store.getState());
    const team = R.selectors.teams.getTeam(this.store.getState());
    const teamName = R.selectors.teams.getTempTeamName(this.store.getState());
    const tempCustomers = R.selectors.teams.getTempCustomers(this.store.getState());
    const teamMembersWithRoles = R.selectors.teams.getTeamMembersWithRoles(this.store.getState());

    if (!team) {
      return;
    }

    const teamToCreate: IPutOrganizationTeamContract = {
      id: team.id,
      status: team.status,
      name: teamName,
      customers: tempCustomers,
      members: teamMembersWithRoles,
    };

    await R.services.teams.editTeam(orgId, teamToCreate);
    this.closeTeamDrawer();
    this.initTeam(team.id);
  };

  public handleCreateOrSaveTeam = () => {
    const isDrawerInEditMode = R.selectors.teams.getIsDrawerInEditMode(this.store.getState());

    if (isDrawerInEditMode) {
      this.saveTeam();
    } else {
      this.createTeam();
    }
  }

  public initCustomerSkypaceTeam = async () => {
    this.dispatch(R.actions.teams.setIsLoading(true));

    const organization = await R.services.organization.getCurrentOrganization();

    if (!organization?.accountTeam || !organization.accountHolderId) {
      this.dispatch(R.actions.teams.setIsLoading(false));

      return;
    }

    this.dispatch(R.actions.teams.setOrganizationId(organization.accountHolderId));

    const teamMembers = await R.services.teams.getMembersByTeam(organization.accountHolderId, organization?.accountTeam.id);

    const team = TeamDTM.fromPlain({
      ...organization?.accountTeam,
    });

    this.dispatch(R.actions.teams.setTeamMembers(teamMembers));
    this.dispatch(R.actions.teams.setAccountTeam(team));
    this.dispatch(R.actions.teams.setAccountManager(organization?.accountManager));
    this.dispatch(R.actions.teams.setIsLoading(false));
  }

  public initCustomerInternalTeam = async (customerId: string) => {
    this.dispatch(R.actions.teams.setIsLoading(true));
    this.dispatch(R.actions.teams.setCustomerOrganization(undefined));
    this.dispatch(R.actions.teams.setAccountTeam(undefined));
    this.dispatch(R.actions.teams.setAccountManager(undefined));

    const userEmail = AuthR.selectors.auth.getEmail(this.store.getState());

    const currentOrganization = await R.services.organization.getCurrentOrganization();
    const currentUser = await userManagementR.services.organization.getOrganizationMember(currentOrganization?.id, userEmail);

    this.dispatch(R.actions.teams.setCurrentUser(currentUser));

    if (currentOrganization) {
      this.dispatch(R.actions.teams.setOrganizationId(currentOrganization.id));
    }

    const organization = await R.services.organization.getOrganizationById(customerId);

    this.dispatch(R.actions.teams.setCustomerOrganization(organization));
    this.dispatch(R.actions.customer.setOrganization(organization));

    if (organization && organization.accountTeam) {
      const team = await this.fullfilTeamWithMembersAndCustomers(organization.accountTeam);

      if (organization.accountHolderId) {
        const teamMembers = await R.services.teams.getMembersByTeam(organization.accountHolderId, team.id);

        this.dispatch(R.actions.teams.setTeamMembers(teamMembers));
      }

      this.dispatch(R.actions.teams.setAccountTeam(team));
    }

    if (organization && organization.accountManager) {
      this.dispatch(R.actions.teams.setAccountManager(organization.accountManager));
    }

    this.dispatch(R.actions.teams.setIsLoading(false));
  }

  public openChangeTeamDrawer = async () => {
    const accountTeam = R.selectors.teams.getAccountTeam(this.store.getState());
    const accountManager = R.selectors.teams.getAccountManager(this.store.getState());
    const organizationId = R.selectors.teams.getOrganizationId(this.store.getState());

    this.dispatch(R.actions.teams.setIsAddTeamDrawerOpened(true));
    this.dispatch(R.actions.teams.setIsAddTeamDrawerLoading(true));

    const teams = await R.services.teams.getTeams(organizationId);
    const members = await R.services.organization.getOrganizationMembersList(organizationId);

    if (accountTeam) {
      this.dispatch(R.actions.teams.setTempAccountTeam(accountTeam.id));
    }

    if (accountManager) {
      this.dispatch(R.actions.teams.setTempAccountManager(accountManager.email));
    }

    this.dispatch(R.actions.teams.setTeams(teams));
    this.dispatch(R.actions.teams.setMembers(members));
    this.dispatch(R.actions.teams.setIsAddTeamDrawerLoading(false));
  }

  public setRoleMember = (role: EAccountDepartmentType, email: string) => {
    this.dispatch(R.actions.teams.setRolesToEmailsStateItem({ role, email }));
  }

  public setTempAccountTeam = (teamId: number) => {
    this.dispatch(R.actions.teams.setTempAccountTeam(teamId));
  }

  public setTempAccountManager = (email: string) => {
    this.dispatch(R.actions.teams.setTempAccountManager(email));
  }

  public addOrChangeTeam = async () => {
    let customerOrganization = R.selectors.teams.getCustomerOrganization(this.store.getState());
    const teams = R.selectors.teams.getTeams(this.store.getState());
    const members = R.selectors.teams.getAccountManagersMembers(this.store.getState());
    const teamId = R.selectors.teams.getTempAccountTeam(this.store.getState());
    const accountManagerEmail = R.selectors.teams.getTempAccountManager(this.store.getState());

    const teamToSave = teams.find((team) => team.id === teamId);
    const accountManagerToSave = members.find(({ email }) => email === accountManagerEmail);

    if (!customerOrganization) {
      return;
    }

    customerOrganization = OrganizationDTM.fromPlain({
      ...customerOrganization,
      accountManager: accountManagerToSave || undefined,
      accountTeam: teamToSave || undefined,
    });

    await R.services.organization.putCurrentOrganization(customerOrganization);

    this.closeChangeTeamDrawer();
    this.initCustomerInternalTeam(String(customerOrganization.id));
  }

  public closeChangeTeamDrawer = () => {
    this.dispatch(R.actions.teams.setIsAddTeamDrawerOpened(false));
    this.dispatch(R.actions.teams.setTeamToAddOrChangeId(undefined));
    this.dispatch(R.actions.teams.setCustomerOrganization(undefined));
    this.dispatch(R.actions.teams.setTempAccountTeam(undefined));
    this.dispatch(R.actions.teams.setTempAccountManager(undefined));
  }

  public fullfilTeamWithMembersAndCustomers = async (team: TeamDTM): Promise<TeamDTM> => {
    const customers = await R.services.organization.getOrganizations(0, CUSTOMERS_COUNT_PER_PAGE, EOrganizationStatus.ACTIVE, undefined, team.id);

    return TeamDTM.fromPlain({
      ...team,
      members: [],
      customers,
    });
  };

  onClosePage() {
    super.onClosePage();
    this.dispatch(R.actions.teams.clear());
  }
}
