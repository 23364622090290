import React from 'react';
import { observer } from 'mobx-react-lite';

import { UC } from 'app-wrapper/controllers';
import { useMobxStore } from 'app-wrapper/mobxStores';

import { SearchInputComponent } from './SearchInput.component';

export const SearchInput = observer(() => {
  const { contracts } = useMobxStore();

  return (
    <SearchInputComponent
      searchQuery={contracts.state.searchQuery}
      setSearchQuery={UC.contracts.setSearchQuery}
    />
  );
});
