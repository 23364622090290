import { apiWorker } from 'app-wrapper/repository/utilsServices';
import { GetCommoditiesResponse } from 'shipment-operations/models/contracts';
import { CommodityDTM, IGetQueryParamsDTM } from 'shipment-operations/models/dtm';

export class CommodityService {
  private base = '/commodity-service/api/v1/commodities';

  getCommodities = async (queryURI: string, params?: IGetQueryParamsDTM): Promise<CommodityDTM[]> => {
    const encodedQuery = encodeURIComponent(queryURI);
    const { page = 0, size = 5 } = params || {};

    const response = await apiWorker.requestGet<GetCommoditiesResponse>(`${this.base}`,
      {
        params: {
          query: encodedQuery,
          page,
          size,
        },
      });

    return response.data.map(({ code, description, forbidden }) => CommodityDTM.fromPlain({
      code,
      name: description,
      forbidden,
    }));
  }
}
