import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'app-wrapper/createSlice';

import { REQUEST_STATUS } from 'app-wrapper/constants';

import {
  CompanyAddressDTM,
  CompanyContactDTM,
  CompanyDTM,
  ContactBookDefaultStateCompanyListStateDTM,
  ContactBookStateErrorsFieldDTM,
  EmptyCompanyContactDTMFactory,
  EmptyCompanyDTMFactory,
  EmptyContactBookDefaultStateCompanyListStateDTMFactory,
  IContactBookDefaultStateCompanyListStateDTM,
  IContactBookDefaultStateDTM,
  IContactBookStateChangeDTM,
  IContactBookUpdateStateCompanyListStateErrorsDTM,
  IContactBookUpdateStateDTM,
  IRemoveDefaultAddCompanyListStateByIdDTM,
  IRemoveUpdateCompanyListStateByIndexDTM,
  IResetUpdateErrorsCompanyListStateByIdDTM,
  ISetUpdateAddCompanyListAddressesById,
  ISetUpdateAddCompanyListContactPersonsById,
  ISetUpdateAddCompanyListStateAddressByIndexDTM,
  ISetUpdateAddCompanyListStateByIndexDTM,
  ISetUpdateAddCompanyListStateContactPersonByIndexDTM,
  ISetUpdateCompanyListIsUpdateByIdByIndexDTM,
  ISetUpdateCompanyListStateAddressesByIdDTM,
  ISetUpdateCompanyListStateByIdDTM,
  ISetUpdateCompanyListStateContactsByIdDTM,
  ISetUpdateCompanyListStatePrimaryContactByIdDTM,
  ISetUpdateCompanyPrimaryAddressesStateByIndexDTM,
  ISetUpdateErrorsByIdDTM,
  ISetUpdateErrorsCompanyListAddressesDTM,
  ISetUpdateErrorsCompanyListContactPersonsDTM,
  ISetUpdateRemoveCompanyListStateAddressByIndexDTM,
} from 'user-management/models/dtm';

import { ContactBookInitialState } from './ContactBook.initState';

export const ContactBook = createSlice({
  name: 'ContactBook',
  initialState: ContactBookInitialState,
  reducers: {
    setChangeState: (state, action: PayloadAction<IContactBookStateChangeDTM>) => {
      const { payload } = action;

      state.defaultState = {
        ...state.defaultState,
        ...payload.defaultState,
      };
      state.updateState = {
        ...state.updateState,
        ...payload.updateState,
      };
    },

    // Update

    setUpdate: (state, action: PayloadAction<IContactBookUpdateStateDTM>) => {
      const { payload } = action;

      state.updateState = payload;
    },

    resetUpdate: (state) => {
      state.updateState = {
        activeListTabIndex: ContactBookInitialState.updateState.activeListTabIndex,
        companyListAddresses: [],
        companyListContactPersons: [],
        companyList: [{
          isUpdate: false,
          name: '',
          id: null,
          usci: null,
          taxId: null,
          addressesIds: [],
          contactPersonsIds: [],
        }],
        companyListState: [{
          isUpdate: false,
          name: '',
          id: null,
          usci: null,
          taxId: null,
          addressesIds: [],
          contactPersonsIds: [],
        }],
        errors: {
          companyListState: [{
            name: {},
            taxId: {},
            customId: '',
          }],
          addresses: [],
          contactPersons: [],
        },
        isUpdate: ContactBookInitialState.updateState.isUpdate,
      };
    },
    resetUpdateErrors: (state) => ({
      ...state,
      updateState: {
        ...state.updateState,
        errors: {
          ...state.updateState.errors,
          companyListState: [{
            name: {},
            taxId: {},
            customId: '',
          }],
          addresses: [],
          contactPersons: [],
        },
      },
    }),
    resetUpdateErrorsCompanyListStateById: (state, action: PayloadAction<IResetUpdateErrorsCompanyListStateByIdDTM>) => {
      const { listIndex } = action.payload;

      return {
        ...state,
        updateState: {
          ...state.updateState,
          errors: {
            ...state.updateState.errors,
            companyListState: state.updateState.errors.companyListState?.map((item) => {
              if (item.customId === listIndex) {
                return {
                  name: {},
                  taxId: {},
                  customId: '',
                  addresses: {
                    0: {},
                  },
                  contactPersons: {
                    0: {},
                  },
                };
              }
              return item;
            }),
          },
        },
      };
    },
    setIsUpdate: (state, action: PayloadAction<boolean>) => {
      const { payload } = action;

      state.updateState.isUpdate = payload;
    },
    setUpdateCompanyList: (state, action: PayloadAction<ContactBookDefaultStateCompanyListStateDTM[]>) => {
      const { payload } = action;

      return {
        ...state,
        updateState: {
          ...state.updateState,
          companyList: payload,
        },
      };
    },
    setUpdateActiveCompanyEdit: (state, action: PayloadAction<ContactBookDefaultStateCompanyListStateDTM>) => {
      const { payload } = action;

      return {
        ...state,
        updateState: {
          ...state.updateState,
          activeCompanyEdit: payload,
        },
      };
    },
    resetUpdateActiveCompanyEdit: (state) => ({
      ...state,
      updateState: {
        ...state.updateState,
        activeCompanyEdit: undefined,
      },
    }),
    setUpdateAddCompanyList: (state, action: PayloadAction<ContactBookDefaultStateCompanyListStateDTM>) => {
      const { payload } = action;

      return {
        ...state,
        updateState: {
          ...state.updateState,
          companyList: [
            ...state.updateState.companyList,
            {
              ...payload,
            },
          ],
        },
      };
    },
    setUpdateCompanyListAddresses: (state, action: PayloadAction<CompanyAddressDTM[]>) => {
      const { payload } = action;

      return {
        ...state,
        updateState: {
          ...state.updateState,
          companyListAddresses: payload,
        },
      };
    },
    setUpdateAddCompanyListAddresses: (state, action: PayloadAction<CompanyAddressDTM>) => {
      const { payload } = action;

      return {
        ...state,
        updateState: {
          ...state.updateState,
          companyListAddresses: [
            ...state.updateState.companyListAddresses,
            payload,
          ],
        },
      };
    },
    setUpdateEditCompanyListAddressesById: (state, action: PayloadAction<ISetUpdateAddCompanyListAddressesById>) => {
      const { listId, companyListAddresses } = action.payload;

      return {
        ...state,
        updateState: {
          ...state.updateState,
          companyListAddresses: state.updateState.companyListAddresses.map((item) => {
            if (item.customId === listId) {
              return companyListAddresses;
            }
            return item;
          }),
        },
      };
    },
    setUpdateEditCompanyListContactPersonsById: (state, action: PayloadAction<ISetUpdateAddCompanyListContactPersonsById>) => {
      const { listId, companyListContactPersons } = action.payload;

      return {
        ...state,
        updateState: {
          ...state.updateState,
          companyListContactPersons: state.updateState.companyListContactPersons.map((item) => {
            if (item.customId === listId) {
              return companyListContactPersons;
            }
            return item;
          }),
        },
      };
    },
    setUpdateCompanyListContactPersons: (state, action: PayloadAction<CompanyContactDTM[]>) => {
      const { payload } = action;

      return {
        ...state,
        updateState: {
          ...state.updateState,
          companyListContactPersons: payload,
        },
      };
    },
    setUpdateAddCompanyListContactPersons: (state, action: PayloadAction<CompanyContactDTM>) => {
      const { payload } = action;

      return {
        ...state,
        updateState: {
          ...state.updateState,
          companyListContactPersons: [
            ...state.updateState.companyListContactPersons,
            payload,
          ],
        },
      };
    },
    setUpdateEditCompanyListContactPersons: (state, action: PayloadAction<ISetUpdateAddCompanyListContactPersonsById>) => {
      const { listId, companyListContactPersons } = action.payload;

      return {
        ...state,
        updateState: {
          ...state.updateState,
          companyListContactPersons: state.updateState.companyListContactPersons.map((item) => {
            if (item.customId === listId) {
              return companyListContactPersons;
            }
            return item;
          }),
        },
      };
    },
    setUpdateCompanyListByIndex: (state, action: PayloadAction<ISetUpdateAddCompanyListStateByIndexDTM>) => {
      const { listId, companyList: companyListNew } = action.payload;

      const listState = state.updateState.companyList || [];
      const companyList = listState.length
        ? listState.map((item) => {
          if (listId === item.customId) {
            return companyListNew;
          }
          return item;
        })
        : [companyListNew];

      return {
        ...state,
        updateState: {
          ...state.updateState,
          companyList,
        },
      };
    },
    removeUpdateCompanyListByIndex: (state, action: PayloadAction<IRemoveUpdateCompanyListStateByIndexDTM>) => {
      const { listId } = action.payload;

      state.updateState.companyList = [...state.updateState?.companyList?.filter((item) => listId !== item.customId) || []];
    },
    setUpdateAddCompanyListAddressByIndex: (state, action: PayloadAction<ISetUpdateAddCompanyListStateAddressByIndexDTM>) => {
      const { listId, addressesId } = action.payload;

      const listState = state.updateState.companyList || [];
      const companyListState = listState.map((item) => {
        if (listId === item.customId) {
          return {
            ...item,
            addressesIds: [
              ...item.addressesIds || [],
              addressesId,
            ],
          };
        }
        return item;
      });

      return {
        ...state,
        updateState: {
          ...state.updateState,
          companyList: companyListState,
        },
      };
    },
    setUpdateRemoveCompanyListAddressByIndex: (state, action: PayloadAction<ISetUpdateRemoveCompanyListStateAddressByIndexDTM>) => {
      const { listId, addressesId } = action.payload;

      const listState = state.updateState.companyList || [];
      const companyListState = listState.map((item) => {
        if (listId === item.customId) {
          return {
            ...item,
            addressesIds: [
              ...item?.addressesIds?.filter((itemId) => itemId !== addressesId) || [],
            ],
          };
        }
        return item;
      });

      return {
        ...state,
        updateState: {
          ...state.updateState,
          companyList: companyListState,
        },
      };
    },
    setUpdateAddCompanyListContactPersonByIndex: (state, action: PayloadAction<ISetUpdateAddCompanyListStateContactPersonByIndexDTM>) => {
      const { listId, contactPersonId } = action.payload;

      const listState = state.updateState.companyList || [];
      const companyListState = listState.map((item) => {
        if (listId === item.customId) {
          return {
            ...item,
            contactPersonsIds: [
              ...item.contactPersonsIds || [],
              contactPersonId,
            ],
          };
        }
        return item;
      });

      return {
        ...state,
        updateState: {
          ...state.updateState,
          companyList: companyListState,
        },
      };
    },
    setUpdateRemoveCompanyListContactPersonByIndex: (state, action: PayloadAction<ISetUpdateAddCompanyListStateContactPersonByIndexDTM>) => {
      const { listId, contactPersonId } = action.payload;

      const listState = state.updateState.companyList || [];
      const companyListState = listState.map((item) => {
        if (listId === item.customId) {
          return {
            ...item,
            contactPersonsIds: [
              ...item?.contactPersonsIds?.filter((itemId) => itemId !== contactPersonId) || [],
            ],
          };
        }
        return item;
      });

      return {
        ...state,
        updateState: {
          ...state.updateState,
          companyList: companyListState,
        },
      };
    },
    setEmptyUpdateCompanyListState: (state) => {
      state.updateState.companyListState = [];
    },
    setEmptyFormUpdateCompanyListState: (state) => {
      state.updateState.companyListState = [{
        isUpdate: false,
        name: '',
        id: null,
        usci: null,
        taxId: null,
        addresses: [
          {
            addressLineFirst: '',
            addressLineSecond: '',
            city: '',
            country: '',
            postalCode: '',
            state: '',
            closestPort: '',
            company: '',
            id: null,
          },
        ],
        contactPersons: [
          {
            additionalPhone: '',
            contactPerson: '',
            email: '',
            phone: '',
            id: null,
          },
        ],
      }];
    },
    setUpdateSetActiveListTabIndex: (state, action: PayloadAction<string>) => {
      const { payload } = action;

      state.updateState.activeListTabIndex = payload;
    },
    setUpdateCompanyListById: (state, action: PayloadAction<ISetUpdateCompanyListStateByIdDTM>) => {
      const { listId, companyList } = action.payload;

      const listState = state.updateState.companyList || [];
      const companyListState = listState?.map((item) => {
        if (listId === item.customId) {
          return {
            ...item,
            ...companyList,
          };
        }
        return item;
      });

      return {
        ...state,
        updateState: {
          ...state.updateState,
          companyList: companyListState,
        },
      };
    },
    setUpdateCompanyListContactsIdsById: (state, action: PayloadAction<ISetUpdateCompanyListStateContactsByIdDTM>) => {
      const { listId, contactPersonsIds } = action.payload;

      const listState = state.updateState.companyList || [];
      const companyListState = listState?.map((item) => {
        if (listId === item.customId) {
          return {
            ...item,
            contactPersonsIds,
          };
        }
        return item;
      });

      return {
        ...state,
        updateState: {
          ...state.updateState,
          companyList: companyListState,
        },
      };
    },
    setUpdateCompanyListAddressesIdsById: (state, action: PayloadAction<ISetUpdateCompanyListStateAddressesByIdDTM>) => {
      const { listId, addressesIds } = action.payload;

      const listState = state.updateState.companyList || [];
      const companyListState = listState?.map((item) => {
        if (listId === item.customId) {
          return {
            ...item,
            addressesIds,
          };
        }
        return item;
      });

      return {
        ...state,
        updateState: {
          ...state.updateState,
          companyList: companyListState,
        },
      };
    },
    removeUpdateCompanyListAddressesIdsById: (state, action: PayloadAction<string>) => {
      const listId = action.payload;

      const listState = state.updateState.companyList || [];
      const companyListState = listState?.filter((item) => item.customId === listId);

      return {
        ...state,
        updateState: {
          ...state.updateState,
          companyList: companyListState,
        },
      };
    },
    setUpdateAddressPrimaryContactById: (state, action: PayloadAction<ISetUpdateCompanyListStatePrimaryContactByIdDTM>) => {
      const { listId, primaryContact } = action.payload;

      const listState = state.updateState.companyListAddresses || [];
      const companyListState = listState?.map((item) => {
        if (listId === item.customId) {
          return {
            ...item,
            primaryContact,
          };
        }
        return item;
      });

      return {
        ...state,
        updateState: {
          ...state.updateState,
          companyListAddresses: companyListState,
        },
      };
    },
    removeUpdateAddressPrimaryContactById: (state, action: PayloadAction<string>) => {
      const listId = action.payload;

      const listState = state.updateState.companyListAddresses || [];
      const companyListState = listState?.filter((item) => item.customId !== listId);

      return {
        ...state,
        updateState: {
          ...state.updateState,
          companyListAddresses: companyListState,
        },
      };
    },
    removeUpdateContactsById: (state, action: PayloadAction<string>) => {
      const listId = action.payload;

      const listState = state.updateState.companyListContactPersons || [];
      const companyListState = listState?.filter((item) => item.customId !== listId);

      return {
        ...state,
        updateState: {
          ...state.updateState,
          companyListContactPersons: companyListState,
        },
      };
    },
    setUpdateCompanyListIsUpdateById: (state, action: PayloadAction<ISetUpdateCompanyListIsUpdateByIdByIndexDTM>) => {
      const { listId, flag } = action.payload;

      const listState = state.updateState.companyList || [];
      const companyListState = listState?.map((item) => {
        if (listId === item.customId) {
          return {
            ...item,
            isUpdate: flag,
          };
        }
        return item;
      });

      return {
        ...state,
        updateState: {
          ...state.updateState,
          companyList: companyListState,
        },
      };
    },
    setUpdateErrorsEditCompanyDetailsDrawer: (state, action: PayloadAction<IContactBookUpdateStateCompanyListStateErrorsDTM | undefined>) => {
      const { payload } = action;

      return {
        ...state,
        updateState: {
          ...state.updateState,
          errorsEditCompanyDetailsDrawer: payload,
        },
      };
    },
    setUpdateErrorsCompanyListContactPerson: (state, action: PayloadAction<ISetUpdateErrorsCompanyListContactPersonsDTM>) => {
      const { indexList, contactPersons } = action.payload;

      if (!state.updateState.errors.contactPersons) {
        state.updateState.errors.contactPersons = [];
      }

      state.updateState.errors.contactPersons = state.updateState.errors.contactPersons.map((item) => {
        if (item.customId === indexList) {
          return contactPersons;
        }
        return item;
      });
    },
    setUpdateErrorsCompanyListState: (state, action: PayloadAction<IContactBookUpdateStateCompanyListStateErrorsDTM[]>) => {
      const companyListState = action.payload;

      return {
        ...state,
        updateState: {
          ...state.updateState,
          errors: {
            ...state.updateState.errors,
            companyListState,
          },
        },
      };
    },
    setUpdateErrorsCompanyListStateNameById: (state, action: PayloadAction<ISetUpdateErrorsByIdDTM>) => {
      const { indexList, error } = action.payload;
      const oldError = state.updateState.errors.companyListState.filter((item) => item.customId === indexList)?.[0] || [];

      return {
        ...state,
        updateState: {
          ...state.updateState,
          errors: {
            ...state.updateState.errors,
            companyListState: [
              ...state.updateState.errors.companyListState?.filter((item) => item.customId !== indexList) || [],
              {
                ...oldError,
                customId: indexList,
                name: {
                  ...oldError.name,
                  ...error,
                },
              },
            ],
          },
        },
      };
    },
    setUpdateErrorsCompanyListStateTaxIdById: (state, action: PayloadAction<ISetUpdateErrorsByIdDTM>) => {
      const { indexList, error } = action.payload;
      const oldError = state.updateState.errors.companyListState.filter((item) => item.customId === indexList)?.[0] || [];

      return {
        ...state,
        updateState: {
          ...state.updateState,
          errors: {
            ...state.updateState.errors,
            companyListState: [
              ...state.updateState.errors.companyListState?.filter((item) => item.customId !== indexList) || [],
              {
                ...oldError,
                customId: indexList,
                taxId: {
                  ...oldError.taxId,
                  ...error,
                },
              },
            ],
          },
        },
      };
    },
    setUpdateErrorsCompanyListStateContactsContactPersonById: (state, action: PayloadAction<ISetUpdateErrorsByIdDTM>) => {
      const { indexList, error } = action.payload;
      const oldError = state.updateState.errors?.contactPersons?.filter((item) => item.customId === indexList)?.[0] || [];

      return {
        ...state,
        updateState: {
          ...state.updateState,
          errors: {
            ...state.updateState.errors,
            contactPersons: [
              ...state.updateState.errors.contactPersons?.filter((item) => item.customId !== indexList) || [],
              {
                ...oldError,
                customId: indexList,
                contactPerson: {
                  ...oldError.contactPerson,
                  ...error,
                },
              },
            ],
          },
        },
      };
    },
    setUpdateErrorsCompanyListStateContactsEmailById: (state, action: PayloadAction<ISetUpdateErrorsByIdDTM>) => {
      const { indexList, error } = action.payload;
      const oldError = state.updateState.errors?.contactPersons?.filter((item) => item.customId === indexList)?.[0] || [];

      return {
        ...state,
        updateState: {
          ...state.updateState,
          errors: {
            ...state.updateState.errors,
            contactPersons: [
              ...state.updateState.errors.contactPersons?.filter((item) => item.customId !== indexList) || [],
              {
                ...oldError,
                customId: indexList,
                email: {
                  ...oldError.email,
                  ...error,
                },
              },
            ],
          },
        },
      };
    },
    setUpdateErrorsCompanyListStateContactsPhoneById: (state, action: PayloadAction<ISetUpdateErrorsByIdDTM>) => {
      const { indexList, error } = action.payload;
      const oldError = state.updateState.errors?.contactPersons?.filter((item) => item.customId === indexList)?.[0] || [];

      return {
        ...state,
        updateState: {
          ...state.updateState,
          errors: {
            ...state.updateState.errors,
            contactPersons: [
              ...state.updateState.errors.contactPersons?.filter((item) => item.customId !== indexList) || [],
              {
                ...oldError,
                customId: indexList,
                phone: {
                  ...oldError.phone,
                  ...error,
                },
              },
            ],
          },
        },
      };
    },
    setUpdateErrorsCompanyListStateContactsAdditionalPhoneById: (state, action: PayloadAction<ISetUpdateErrorsByIdDTM>) => {
      const { indexList, error } = action.payload;
      const oldError = state.updateState.errors?.contactPersons?.filter((item) => item.customId === indexList)?.[0] || [];

      return {
        ...state,
        updateState: {
          ...state.updateState,
          errors: {
            ...state.updateState.errors,
            contactPersons: [
              ...state.updateState.errors.contactPersons?.filter((item) => item.customId !== indexList) || [],
              {
                ...oldError,
                customId: indexList,
                additionalPhone: {
                  ...oldError.additionalPhone,
                  ...error,
                },
              },
            ],
          },
        },
      };
    },
    setUpdateErrorsCompanyListAddresses: (state, action: PayloadAction<ISetUpdateErrorsCompanyListAddressesDTM>) => {
      const { indexList, addresses } = action.payload;
      const oldError = state.updateState.errors?.addresses?.filter((item) => item.customId === indexList)?.[0] || [];

      return {
        ...state,
        updateState: {
          ...state.updateState,
          errors: {
            ...state.updateState.errors,
            addresses: [
              ...state.updateState.errors.addresses?.filter((item) => item.customId !== indexList) || [],
              {
                ...oldError,
                ...addresses,
                customId: indexList,
              },
            ],
          },
        },
      };
    },
    setUpdateErrorsCompanyListAddressesAddressLineFirstById: (state, action: PayloadAction<ISetUpdateErrorsByIdDTM>) => {
      const { indexList, error } = action.payload;
      const oldError = state.updateState.errors?.addresses?.filter((item) => item.customId === indexList)?.[0] || [];

      return {
        ...state,
        updateState: {
          ...state.updateState,
          errors: {
            ...state.updateState.errors,
            addresses: [
              ...state.updateState.errors.addresses?.filter((item) => item.customId !== indexList) || [],
              {
                ...oldError,
                customId: indexList,
                addressLineFirst: {
                  ...oldError.addressLineFirst,
                  ...error,
                },
              },
            ],
          },
        },
      };
    },
    setUpdateErrorsCompanyListAddressesAddressLineSecondById: (state, action: PayloadAction<ISetUpdateErrorsByIdDTM>) => {
      const { indexList, error } = action.payload;
      const oldError = state.updateState.errors?.addresses?.filter((item) => item.customId === indexList)?.[0] || [];

      return {
        ...state,
        updateState: {
          ...state.updateState,
          errors: {
            ...state.updateState.errors,
            addresses: [
              ...state.updateState.errors.addresses?.filter((item) => item.customId !== indexList) || [],
              {
                ...oldError,
                customId: indexList,
                addressLineSecond: {
                  ...oldError.addressLineSecond,
                  ...error,
                },
              },
            ],
          },
        },
      };
    },
    setUpdateErrorsCompanyListAddressesCityById: (state, action: PayloadAction<ISetUpdateErrorsByIdDTM>) => {
      const { indexList, error } = action.payload;
      const oldError = state.updateState.errors?.addresses?.filter((item) => item.customId === indexList)?.[0] || [];

      return {
        ...state,
        updateState: {
          ...state.updateState,
          errors: {
            ...state.updateState.errors,
            addresses: [
              ...state.updateState.errors.addresses?.filter((item) => item.customId !== indexList) || [],
              {
                ...oldError,
                customId: indexList,
                city: {
                  ...oldError.city,
                  ...error,
                },
              },
            ],
          },
        },
      };
    },
    setUpdateErrorsCompanyListAddressesCountryById: (state, action: PayloadAction<ISetUpdateErrorsByIdDTM>) => {
      const { indexList, error } = action.payload;
      const oldError = state.updateState.errors?.addresses?.filter((item) => item.customId === indexList)?.[0] || [];

      return {
        ...state,
        updateState: {
          ...state.updateState,
          errors: {
            ...state.updateState.errors,
            addresses: [
              ...state.updateState.errors.addresses?.filter((item) => item.customId !== indexList) || [],
              {
                ...oldError,
                customId: indexList,
                country: {
                  ...oldError.country,
                  ...error,
                },
              },
            ],
          },
        },
      };
    },
    setUpdateErrorsCompanyListAddressesStateById: (state, action: PayloadAction<ISetUpdateErrorsByIdDTM>) => {
      const { indexList, error } = action.payload;
      const oldError = state.updateState.errors?.addresses?.filter((item) => item.customId === indexList)?.[0] || [];

      return {
        ...state,
        updateState: {
          ...state.updateState,
          errors: {
            ...state.updateState.errors,
            addresses: [
              ...state.updateState.errors.addresses?.filter((item) => item.customId !== indexList) || [],
              {
                ...oldError,
                customId: indexList,
                state: {
                  ...oldError.state,
                  ...error,
                },
              },
            ],
          },
        },
      };
    },
    setUpdateErrorsCompanyListAddressesPostalCodeById: (state, action: PayloadAction<ISetUpdateErrorsByIdDTM>) => {
      const { indexList, error } = action.payload;
      const oldError = state.updateState.errors?.addresses?.filter((item) => item.customId === indexList)?.[0] || [];

      return {
        ...state,
        updateState: {
          ...state.updateState,
          errors: {
            ...state.updateState.errors,
            addresses: [
              ...state.updateState.errors.addresses?.filter((item) => item.customId !== indexList) || [],
              {
                ...oldError,
                customId: indexList,
                postalCode: {
                  ...oldError.postalCode,
                  ...error,
                },
              },
            ],
          },
        },
      };
    },
    setUpdateCompanyPrimaryByIAddress: (state, action: PayloadAction<CompanyDTM>) => {
      const { payload } = action;

      state.updateState.companyPrimaryByIAddress = payload;
    },
    setUpdateContactPersonForm: (state, action: PayloadAction<CompanyContactDTM>) => {
      const { payload } = action;

      state.updateState.contactPersonForm = payload;
    },
    resetUpdateContactPersonForm: (state) => ({
      ...state,
      updateState: {
        ...state.updateState,
        contactPersonForm: {
          ...EmptyCompanyContactDTMFactory.build(),
        },
      },
    }),
    setUpdateContactPersonIdForm: (state, action: PayloadAction<number>) => {
      const { payload } = action;

      return {
        ...state,
        updateState: {
          ...state.updateState,
          contactPersonForm: {
            ...EmptyCompanyContactDTMFactory.build(),
            ...state.updateState.contactPersonForm,
            id: payload,
          },
        },
      };
    },
    setUpdateContactPersonCargoWiseCodeForm: (state, action: PayloadAction<string>) => {
      const { payload } = action;

      return {
        ...state,
        updateState: {
          ...state.updateState,
          contactPersonForm: {
            ...EmptyCompanyContactDTMFactory.build(),
            ...state.updateState.contactPersonForm,
            cargoWiseCode: payload,
          },
        },
      };
    },
    setUpdateContactPersonEmailForm: (state, action: PayloadAction<string>) => {
      const { payload } = action;

      return {
        ...state,
        updateState: {
          ...state.updateState,
          contactPersonForm: {
            ...EmptyCompanyContactDTMFactory.build(),
            ...state.updateState.contactPersonForm,
            email: payload,
          },
        },
      };
    },
    setUpdateContactPersonEmailFormErrors: (state, action: PayloadAction<ContactBookStateErrorsFieldDTM>) => {
      const { payload } = action;

      return {
        ...state,
        updateState: {
          ...state.updateState,
          contactPersonForm: {
            ...EmptyCompanyContactDTMFactory.build(),
            ...state.updateState.contactPersonForm,
            emailErrors: payload,
          },
        },
      };
    },
    setUpdateContactPersonFullNameForm: (state, action: PayloadAction<string>) => {
      const { payload } = action;

      return {
        ...state,
        updateState: {
          ...state.updateState,
          contactPersonForm: {
            ...EmptyCompanyContactDTMFactory.build(),
            ...state.updateState.contactPersonForm,
            fullName: payload,
          },
        },
      };
    },
    setUpdateContactPersonFullNameFormErrors: (state, action: PayloadAction<ContactBookStateErrorsFieldDTM>) => {
      const { payload } = action;

      return {
        ...state,
        updateState: {
          ...state.updateState,
          contactPersonForm: {
            ...EmptyCompanyContactDTMFactory.build(),
            ...state.updateState.contactPersonForm,
            fullNameErrors: payload,
          },
        },
      };
    },
    setUpdateContactPersonPhoneForm: (state, action: PayloadAction<string>) => {
      const { payload } = action;

      return {
        ...state,
        updateState: {
          ...state.updateState,
          contactPersonForm: {
            ...EmptyCompanyContactDTMFactory.build(),
            ...state.updateState.contactPersonForm,
            phone: payload,
          },
        },
      };
    },
    setUpdateContactPersonPhoneFormErrors: (state, action: PayloadAction<ContactBookStateErrorsFieldDTM>) => {
      const { payload } = action;

      return {
        ...state,
        updateState: {
          ...state.updateState,
          contactPersonForm: {
            ...EmptyCompanyContactDTMFactory.build(),
            ...state.updateState.contactPersonForm,
            phoneErrors: payload,
          },
        },
      };
    },
    setUpdateContactPersonPhone2Form: (state, action: PayloadAction<string>) => {
      const { payload } = action;

      return {
        ...state,
        updateState: {
          ...state.updateState,
          contactPersonForm: {
            ...EmptyCompanyContactDTMFactory.build(),
            ...state.updateState.contactPersonForm,
            phone2: payload,
          },
        },
      };
    },
    setUpdateContactPersonPhone2FormErrors: (state, action: PayloadAction<ContactBookStateErrorsFieldDTM>) => {
      const { payload } = action;

      return {
        ...state,
        updateState: {
          ...state.updateState,
          contactPersonForm: {
            ...EmptyCompanyContactDTMFactory.build(),
            ...state.updateState.contactPersonForm,
            phone2Errors: payload,
          },
        },
      };
    },
    setUpdateContactPersonPrimaryForm: (state, action: PayloadAction<boolean>) => {
      const { payload } = action;

      return {
        ...state,
        updateState: {
          ...state.updateState,
          contactPersonForm: {
            ...EmptyCompanyContactDTMFactory.build(),
            ...state.updateState.contactPersonForm,
            primary: payload,
          },
        },
      };
    },

    // Default

    setDefault: (state, action: PayloadAction<IContactBookDefaultStateDTM>) => {
      const { payload } = action;

      state.defaultState = payload;
    },
    resetDefault: (state) => {
      state.defaultState = {
        getManualRequestStatus: ContactBookInitialState.defaultState.getManualRequestStatus,
        getRequestStatus: ContactBookInitialState.defaultState.getRequestStatus,
        isEmptyRequest: ContactBookInitialState.defaultState.isEmptyRequest,
        isRequest: ContactBookInitialState.defaultState.isRequest,
        companyListAddresses: [],
        companyListContactPersons: [],
        companyList: [{
          isUpdate: false,
          name: '',
          id: null,
          usci: null,
          taxId: null,
          addressesIds: [],
          contactPersonsIds: [],
        }],
      };
    },
    setDefaultIsRequest: (state, action: PayloadAction<boolean>) => {
      const { payload } = action;

      state.defaultState.isRequest = payload;
    },
    setDefaultSetActiveListTabIndex: (state, action: PayloadAction<string>) => {
      const { payload } = action;

      state.defaultState.activeListTabIndex = payload;
    },
    setDefaultGetRequestStatusStatus: (state, action: PayloadAction<REQUEST_STATUS>) => {
      const { payload } = action;

      state.defaultState.getRequestStatus = payload;
    },
    setDefaultGetRequestStatusNextPageStatus: (state, action: PayloadAction<REQUEST_STATUS>) => {
      const { payload } = action;

      state.defaultState.getRequestStatusNextPage = payload;
    },
    setDefaultIsEndPage: (state, action: PayloadAction<boolean>) => {
      const { payload } = action;

      state.defaultState.isEndPage = payload;
    },
    setDefaultCompanyListPage: (state, action: PayloadAction<string>) => {
      const { payload } = action;

      state.defaultState.companyListPage = payload;
    },
    setDefaultCompanyListSize: (state, action: PayloadAction<string>) => {
      const { payload } = action;

      state.defaultState.companyListSize = payload;
    },
    setDefaultAddCompanyList: (state, action: PayloadAction<ContactBookDefaultStateCompanyListStateDTM>) => {
      const { payload } = action;

      return {
        ...state,
        defaultState: {
          ...state.defaultState,
          companyList: [
            ...state.defaultState.companyList,
            {
              ...payload,
            },
          ],
        },
      };
    },
    setDefaultCompanyListById: (state, action: PayloadAction<ISetUpdateCompanyListStateByIdDTM>) => {
      const { listId, companyList } = action.payload;

      const listState = state.defaultState.companyList || [];
      const companyListState = listState?.map((item) => {
        if (listId === item.customId) {
          return {
            ...item,
            ...companyList,
          };
        }
        return item;
      });

      return {
        ...state,
        defaultState: {
          ...state.defaultState,
          companyList: companyListState,
        },
      };
    },
    setDefaultCompanyListAddresses: (state, action: PayloadAction<CompanyAddressDTM[]>) => {
      const { payload } = action;

      return {
        ...state,
        defaultState: {
          ...state.defaultState,
          companyListAddresses: payload,
        },
      };
    },
    setDefaultAddCompanyListAddresses: (state, action: PayloadAction<CompanyAddressDTM>) => {
      const { payload } = action;

      return {
        ...state,
        defaultState: {
          ...state.defaultState,
          companyListAddresses: [
            ...state.defaultState.companyListAddresses,
            payload,
          ],
        },
      };
    },
    setDefaultEditCompanyListAddressesById: (state, action: PayloadAction<ISetUpdateAddCompanyListAddressesById>) => {
      const { listId, companyListAddresses } = action.payload;

      return {
        ...state,
        defaultState: {
          ...state.defaultState,
          companyListAddresses: state.defaultState.companyListAddresses.map((item) => {
            if (item.customId === listId) {
              return companyListAddresses;
            }
            return item;
          }),
        },
      };
    },
    setDefaultCompanyListContactPersons: (state, action: PayloadAction<CompanyContactDTM[]>) => {
      const { payload } = action;

      return {
        ...state,
        defaultState: {
          ...state.defaultState,
          companyListContactPersons: payload,
        },
      };
    },
    setDefaultAddCompanyListContactPersons: (state, action: PayloadAction<CompanyContactDTM>) => {
      const { payload } = action;

      return {
        ...state,
        defaultState: {
          ...state.defaultState,
          companyListContactPersons: [
            ...state.defaultState.companyListContactPersons,
            payload,
          ],
        },
      };
    },
    setDefaultEditCompanyListContactPersonsById: (state, action: PayloadAction<ISetUpdateAddCompanyListContactPersonsById>) => {
      const { listId, companyListContactPersons } = action.payload;

      return {
        ...state,
        defaultState: {
          ...state.defaultState,
          companyListContactPersons: state.defaultState.companyListContactPersons.map((item) => {
            if (item.customId === listId) {
              return companyListContactPersons;
            }
            return item;
          }),
        },
      };
    },
    setDefaultCompanyPrimaryState: (state, action: PayloadAction<IContactBookDefaultStateCompanyListStateDTM>) => {
      const { payload } = action;

      state.defaultState.companyPrimaryState = payload;
    },
    setDefaultAddCompanyPrimaryAddressIdsState: (state, action: PayloadAction<string>) => {
      const { payload } = action;

      return {
        ...state,
        defaultState: {
          ...state.defaultState,
          companyPrimaryState: {
            ...EmptyContactBookDefaultStateCompanyListStateDTMFactory.build(),
            ...state.defaultState.companyPrimaryState,
            addressesIds: [
              ...state.defaultState.companyPrimaryState?.addressesIds || [],
              payload,
            ],
          },
        },
      };
    },
    setDefaultCompanyPrimaryStateById: (state, action: PayloadAction<ISetUpdateCompanyPrimaryAddressesStateByIndexDTM>) => {
      const { listIndex, addresses } = action.payload;

      return {
        ...state,
        defaultState: {
          ...state.defaultState,
          companyPrimaryState: {
            ...state.defaultState.companyPrimaryState,
            isUpdate: state.defaultState.companyPrimaryState?.isUpdate || false,
            name: state.defaultState.companyPrimaryState?.name || '',
            contactPersons: state.defaultState.companyPrimaryState?.contactPersons || [],
            id: state.defaultState.companyPrimaryState?.id || null,
            taxId: state.defaultState.companyPrimaryState?.taxId || null,
            usci: state.defaultState.companyPrimaryState?.usci || null,
            addresses: state.defaultState.companyPrimaryState?.addresses?.map((item, index) => {
              if (listIndex === index) {
                return addresses;
              }
              return item;
            }) || [],
          },
        },
      };
    },
    deleteDefaultCompanyPrimaryStateById: (state, action: PayloadAction<string>) => {
      const { payload: listId } = action;

      return {
        ...state,
        defaultState: {
          ...state.defaultState,
          companyPrimaryState: {
            ...EmptyContactBookDefaultStateCompanyListStateDTMFactory.build(),
            ...state.defaultState.companyPrimaryState,
            addressesIds: state.defaultState.companyPrimaryState?.addressesIds?.filter((item) => listId !== item) || [],
          },
        },
      };
    },
    setDefaultCompanyPrimaryByIAddress: (state, action: PayloadAction<CompanyDTM>) => {
      const { payload } = action;

      return {
        ...state,
        defaultState: {
          ...state.defaultState,
          companyPrimaryByIAddress: {
            ...EmptyCompanyDTMFactory.build(),
            ...state.defaultState.companyPrimaryByIAddress,
            ...payload,
          },
        },
      };
    },
    setDefaultContactPersonForm: (state, action: PayloadAction<CompanyContactDTM>) => {
      const { payload } = action;

      state.defaultState.contactPersonForm = payload;
    },
    setDefaultCompanyPrimaryContactsByIAddress: (state, action: PayloadAction<CompanyContactDTM[]>) => {
      const { payload } = action;

      return {
        ...state,
        defaultState: {
          ...state.defaultState,
          companyPrimaryByIAddress: {
            ...EmptyCompanyDTMFactory.build(),
            ...state.defaultState.companyPrimaryByIAddress,
            contacts: payload,
          },
        },
      };
    },
    setDefaultCompanyPrimaryAddressesByIAddress: (state, action: PayloadAction<CompanyAddressDTM[]>) => {
      const { payload } = action;

      return {
        ...state,
        defaultState: {
          ...state.defaultState,
          companyPrimaryByIAddress: {
            ...EmptyCompanyDTMFactory.build(),
            ...state.defaultState.companyPrimaryByIAddress,
            addresses: payload,
          },
        },
      };
    },
    setDefaultCompanyPrimaryIdByIAddress: (state, action: PayloadAction<number>) => {
      const { payload } = action;

      return {
        ...state,
        defaultState: {
          ...state.defaultState,
          companyPrimaryByIAddress: {
            ...EmptyCompanyDTMFactory.build(),
            ...state.defaultState.companyPrimaryByIAddress,
            id: payload,
          },
        },
      };
    },
    setDefaultAddCompanyListStateById: (state, action: PayloadAction<ISetUpdateAddCompanyListStateByIndexDTM>) => {
      const { listId, companyList } = action.payload;

      return {
        ...state,
        defaultState: {
          ...state.defaultState,
          companyList: [
            ...state.defaultState?.companyList?.map((item) => {
              if (item.customId === listId) {
                return companyList;
              }
              return item;
            }) || [],
          ],
        },
      };
    },
    removeDefaultAddCompanyListStateById: (state, action: PayloadAction<IRemoveDefaultAddCompanyListStateByIdDTM>) => {
      const { listId } = action.payload;

      return {
        ...state,
        defaultState: {
          ...state.defaultState,
          companyList: [...state.defaultState?.companyList?.filter((item) => listId !== item.customId) || []],
        },
      };
    },
  },
});

export const contactBookReducer = ContactBook.reducer;
export const contactBookActions = ContactBook.actions;
