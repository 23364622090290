import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';

import { SBLTransportInformationComponent } from './SBLTransportInformation.component';

const EMPTY_CELL = '-';

const SBLTransportInformationContainer: FC = () => {
  const { t } = useTranslation();
  const lowestTransportation = useSelector(R.selectors.billOfLadingCommon.getLowestSequenceTransportation);

  const items = useMemo(() => [
    {
      title: t('Vessel'),
      subtitle: lowestTransportation?.transport.name || EMPTY_CELL,
    },
    {
      title: t('Voyage'),
      subtitle: lowestTransportation?.voyageCode || EMPTY_CELL,
    },
  ], [lowestTransportation, t]);

  return <SBLTransportInformationComponent items={items} />;
};

export { SBLTransportInformationContainer as SBLTransportInformation };
