import styled from 'styled-components';

import { divFlex } from 'app-wrapper/view/themes/mixin';

export const ErrorsGroupDiv = styled.div.attrs((props: {
  isEmpty: boolean,
}) => ({
  isEmpty: props.isEmpty,
  'data-class': 'ErrorsGroupDiv',
}))`
  ${divFlex}
  position: absolute;
  z-index: 2;
  ${({ isEmpty }) => isEmpty && 'display: none;'};
  color: ${({ theme }) => theme?.themesColors?.characterBrandingDanger};
`;

export const ErrorsGroupItem = styled.p`
  margin: 1px 1px;
`;
