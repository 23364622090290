import { createSlice } from 'app-wrapper/createSlice';

import { IShipmentChangesState } from 'shipment-operations/models/states';
import { PayloadAction } from '@reduxjs/toolkit';
import { ShipmentPreviewDTM } from 'shipment-operations/models/dtm';

const initialState: IShipmentChangesState = {
  inttraNumber: '',
  carrierNumber: '',
  isLoadingData: false,
  data: [],
  isErrorLoadingData: false,
  document: null,
  withMismatches: false,
  mismatchId: undefined,
  locationMismatchId: undefined,
  actionLoading: false,
  isChangedConfirm: false,
  changedCutOffs: [],
  changedLocations: [],
  changedTransportPlan: [],
  rejectLoading: false,
  acceptLoading: false,
  cutOffsErrors: [],
  locationsErrors: [],
  transportPlanErrors: [],
  transportPlanDatesErrors: [],
  shouldRefresh: false,
  shouldUpdateShipment: false,
  isSearchLoading: false,
  isSearchSuccess: false,
  searchError: false,
  searchErrorMessage: '',
  validationDrawer: false,
  withSubmitButton: false,
  withLocationSection: false,
};

export const shipmentChangesSlice = createSlice({
  name: 'shipmentChanges',
  initialState,
  reducers: {
    setInttraNumber: (state, { payload }) => {
      state.inttraNumber = payload;
    },
    setCarrierNumber: (state, { payload }) => {
      state.carrierNumber = payload;
    },
    setIsLoadingData: (state, { payload }) => {
      state.isLoadingData = payload;
    },
    setData: (state, { payload }) => {
      state.data = payload;
      state.isLoadingData = false;
      state.isErrorLoadingData = false;
    },
    setIsErrorLoadingData: (state, { payload }) => {
      state.isErrorLoadingData = payload;
    },
    setDocument: (state, { payload }) => {
      state.document = payload;
    },
    setWithMismatches: (state, { payload }) => {
      state.withMismatches = payload;
    },
    setActionLoading: (state, { payload }) => {
      state.actionLoading = payload;
    },
    setWithSubmitButton: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      withSubmitButton: payload,
    }),
    setChangedConfirm: (state, { payload }) => {
      state.isChangedConfirm = payload;
    },
    setChangedCutOffs: (state, { payload }) => {
      state.changedCutOffs = payload;
    },
    setChangedLocations: (state, { payload }) => {
      state.changedLocations = payload;
    },
    setChangedTransportPlan: (state, { payload }) => {
      state.changedTransportPlan = payload;
    },
    setRejectLoading: (state, { payload }) => {
      state.rejectLoading = payload;
    },
    setMismatchId: (state, { payload }) => {
      state.mismatchId = payload;
    },
    setLocationMismatchId: (state, { payload }) => {
      state.locationMismatchId = payload;
    },
    setAcceptLoading: (state, { payload }) => {
      state.acceptLoading = payload;
    },
    setCutOffsErrors: (state, { payload }) => {
      state.cutOffsErrors = payload;
    },
    setLocationsErrors: (state, { payload }) => {
      state.locationsErrors = payload;
    },
    setTransportPlanErrors: (state, { payload }) => {
      state.transportPlanErrors = payload;
    },
    setTransportPlanDatesErrors: (state, { payload }) => {
      state.transportPlanDatesErrors = payload;
    },
    setShouldRefresh: (state, { payload }) => {
      state.shouldRefresh = payload;
    },
    setShouldUpdateShipment: (state, { payload }) => {
      state.shouldUpdateShipment = payload;
    },
    setIsSearchLoading: (state, { payload }) => {
      state.isSearchLoading = payload;
    },
    setIsSearchSuccess: (state, { payload }) => {
      state.isSearchSuccess = payload;
    },
    setSearchError: (state, { payload }) => {
      state.searchError = payload;
    },
    setSearchErrorMessage: (state, { payload }) => {
      state.searchErrorMessage = payload;
    },
    setShipment: (state, action: PayloadAction<ShipmentPreviewDTM>) => {
      state.shipmentShort = action.payload;
    },
    setValidationDrawer: (state, { payload }) => {
      state.validationDrawer = payload;
    },
    setWithLocationSection: (state, { payload }) => {
      state.withLocationSection = payload;
    },
    reset: (state) => ({
      ...initialState,
      withMismatches: state.withMismatches,
      shouldRefresh: state.shouldRefresh,
      shouldUpdateShipment: state.shouldUpdateShipment,
    }),
  },
});

export const shipmentChangesActions = shipmentChangesSlice.actions;
export const shipmentChangesReducer = shipmentChangesSlice.reducer;
