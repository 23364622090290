import React, { FC, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import Spin from 'antd/es/spin';

import { PermissionAttributePolicy } from 'shipment-operations/constants';
import { ContainerDTM } from 'shipment-operations/models/dtm';

import {
  LoaderWrapper,
  Title,
  Layout,
  Content,
  TableStyled,
} from './ShipmentDocumentsSeaworthyCertificates.styled';
import {
  ContainerColumn,
  UploadDocument,
  Footer,
} from './components';

const SEAWORTHY_CERTIFICATE_PATHNAME = 'seaworthy-certificate';

interface IShipmentDocumentsSeaworthyCertificatesComponentProps {
  containers: ContainerDTM[];
  isLoading: boolean;
  setInitialState: () => void;
  hasSOC: boolean;
  hasLoadingHappened: boolean;
  goBackToDocuments: (shipmentId: string) => void;
  shipmentId: string;
  seaworthyCertificatesPermission?: PermissionAttributePolicy;
}

export const ShipmentDocumentsSeaworthyCertificatesComponent: FC<IShipmentDocumentsSeaworthyCertificatesComponentProps> = ({
  containers,
  isLoading,
  setInitialState,
  hasSOC,
  goBackToDocuments,
  hasLoadingHappened,
  shipmentId,
  seaworthyCertificatesPermission,
}) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const isDisabled = useMemo(() => seaworthyCertificatesPermission !== PermissionAttributePolicy.WRITE, [seaworthyCertificatesPermission]);

  useEffect(() => () => {
    setInitialState();
  }, []);

  useEffect(() => {
    if (hasLoadingHappened && !hasSOC && pathname.includes(SEAWORTHY_CERTIFICATE_PATHNAME)) {
      goBackToDocuments(shipmentId);
    }
  }, [hasSOC, pathname, hasLoadingHappened]);

  const columns = useMemo(() => [
    {
      title: 'Container',
      dataIndex: 'container',
      key: 'container',
      width: '49%',
      render: (item: string, record: ContainerDTM | {}) => (
        <ContainerColumn container={record as ContainerDTM} />
      ),
    },
    {
      title: 'Document',
      dataIndex: 'document',
      key: 'document',
      width: '49%',
      render: (item: string, record: ContainerDTM | {}) => {
        const container = record as ContainerDTM;

        return (
          <UploadDocument
            disabled={isDisabled}
            containerId={container?.id}
            file={container?.seaworthyCertificate}
          />
        );
      },
    },
  ], [isDisabled]);

  return (
    <Layout>
      <Title>
        {t('Seaworthy Certificates')}
      </Title>

      {isLoading ? (
        <LoaderWrapper>
          <Spin />
        </LoaderWrapper>
      ) : (
        <>
          <Content>
            <TableStyled
              columns={columns}
              dataSource={containers}
              pagination={false}
            />
          </Content>

          <Footer />
        </>
      )}
    </Layout>
  );
};
