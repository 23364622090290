import { ShipmentAllStatsDTM } from 'shipment-operations/models/dtm';

import { R as userManagementR } from 'user-management/repository';
import { OrganizationDTM } from 'user-management/models/dtm';

import { BaseUseCase } from 'app-wrapper/usecases/Base.useCase';
import { R } from 'app-wrapper/repository';
import {
  CommandCenterGetCommandCenterGetNotificationsDTM, CommandCenterGetTasksDTM,
} from 'app-wrapper/models/dtm';

const TasksBlockSize = '100';

const defaultParams = CommandCenterGetTasksDTM.fromPlain({
  page: `${0}`,
  size: TasksBlockSize,
  sort: 'dueDate,asc',
  query: 'TODO',
});

const getTasksStats = async (paramsTasks?: CommandCenterGetTasksDTM) => new Promise<ShipmentAllStatsDTM | undefined>((resolve, reject) => {
  try {
    const res = R.services.CommandCenter.getOrganizationStats({
      ...defaultParams,
      ...paramsTasks,
    });

    resolve(res);
  } catch (e) {
    reject(e);
  }
});

export class OverviewDashboardUseCase extends BaseUseCase {
  public async resetStatisticsTasksAndNotification() {
    this.dispatch(R.actions.overview.setInfoCountAllTasks(0));
    this.dispatch(R.actions.overview.setInfoCountOverdueTasks(0));
    this.dispatch(R.actions.overview.setInfoCountDueTasks(0));
    this.dispatch(R.actions.overview.setInfoCountPlannedTasks(0));
    this.dispatch(R.actions.overview.setCountUnreadNotifications(0));
    this.dispatch(R.actions.overview.setCountImportantNotifications(0));
    this.dispatch(R.actions.overview.setCountRegularNotifications(0));
    this.dispatch(R.actions.overview.setTasks([]));
    this.dispatch(R.actions.overview.setCountAllTasks(0));
  }

  public async onLoadStatisticsTasks(shipmentId?: string) {
    const { organization } = this.store.getState().userOrganizationData;
    let organizationReq: OrganizationDTM | undefined;

    if (!organization) {
      organizationReq = await userManagementR.services.organization.getCurrentOrganization();
    }

    const organizationId = organization?.id || organizationReq?.id;
    const assigneeOrganizationIds = organizationId ? [organizationId] : undefined;
    const { email } = this.store.getState().auth;
    const assigneeOrganizationUsers = email ? [email] : undefined;

    const params = CommandCenterGetTasksDTM.fromPlain({
      page: `${0}`,
      size: '1',
      query: 'TODO',
      shipmentId,
      assigneeUserEmails: assigneeOrganizationUsers,
      assigneeOrganizationIds,
    });

    const [res11] = await Promise.all<ShipmentAllStatsDTM | undefined>([
      getTasksStats(CommandCenterGetTasksDTM.fromPlain({
        ...params,
      })),
    ]);

    const tasksOverdue1 = res11?.taskStats.overdue;
    const tasksDue1 = res11?.taskStats.expiring;
    const tasksPlanned1 = res11?.taskStats.todo;

    this.dispatch(R.actions.overview.setInfoCountOverdueTasks(tasksOverdue1 || 0));
    this.dispatch(R.actions.overview.setInfoCountDueTasks(tasksDue1 || 0));
    this.dispatch(R.actions.overview.setInfoCountPlannedTasks(tasksPlanned1 || 0));
  }

  public async onLoadStatisticsNotifications(shipmentId?: string) {
    const organization = await this.repositories.organizationRepository.get();
    const assigneeAudienceOrganizationIds = organization?.id ? [organization?.id] : undefined;

    const params = CommandCenterGetCommandCenterGetNotificationsDTM.fromPlain({
      page: `${0}`,
      size: '1',
      sort: 'createdAt,desc',
      shipmentId,
      assigneeAudienceOrganizationIds,
    });

    const [res11] = await Promise.all<ShipmentAllStatsDTM | undefined>([
      getTasksStats(CommandCenterGetTasksDTM.fromPlain({
        ...params,
        targetAudienceOrganizationIds: assigneeAudienceOrganizationIds,
      })),
    ]);

    const notificationUnread1 = res11?.notificationStats.unread;
    const notificationImportant1 = res11?.notificationStats.unreadCritical;
    const notificationRegular = res11?.notificationStats.unreadRegular;

    this.dispatch(R.actions.overview.setCountUnreadNotifications(notificationUnread1 || 0));
    this.dispatch(R.actions.overview.setCountImportantNotifications(notificationImportant1 || 0));
    this.dispatch(R.actions.overview.setCountRegularNotifications((notificationRegular || 0)));
  }
}
