import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  useNavigate,
  Outlet,
  useParams,
  useLocation,
} from 'react-router';
import Tabs from 'antd/es/tabs';

import { RouteNames } from 'app-wrapper/constants';
import { EOrganizationStatus } from 'user-management/models/dtm';
import { EReviewAction } from 'user-management/constants';

import {
  BackTitleIcon,
  SubPageHeader,
  SubPageHeaderContainer,
  SubPageHeaderTitle,
  Wrapper,
} from 'app-wrapper/view/guideline';
import {
  TabsStyled,
  Footer,
  ApproveButton,
  RejectButton,
  AdditionalInfoButton,
  AdditionalInfoIcon,
  Warning,
  WarningWrap,
} from './Partner.styled';
import { RejectModal, AdditionalInformationModal } from './components';

interface PartnerComponentProps {
  initData: (organizationId: string) => void;
  isAdmin: boolean;
  organizationStatus?: EOrganizationStatus;
  isLoading: boolean;
  onReject: () => void;
  onRequestAdditionalInfo: () => void;
  onApprove: () => void;
  hasRequestedAdditionalInfo: boolean;
  reviewNote?: string;
  reviewAction?: EReviewAction;
}

export const PartnerComponent: FC<PartnerComponentProps> = ({
  initData,
  isAdmin,
  organizationStatus,
  isLoading,
  onApprove,
  onReject,
  onRequestAdditionalInfo,
  hasRequestedAdditionalInfo,
  reviewNote,
  reviewAction,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { customerId = '' } = useParams();
  const { pathname } = useLocation();

  const handleGoBack = useCallback(() => navigate(RouteNames.ACCOUNT_PARTNERS()), [navigate]);

  const onChangeTab = useCallback((tab) => {
    navigate(tab, { replace: true });
  }, []);

  useEffect(() => {
    initData(customerId);
  }, []);

  const areButtonsDisabled = useMemo(() => !isAdmin || isLoading || organizationStatus !== EOrganizationStatus.WAITING_APPROVAL, [isAdmin, isLoading, organizationStatus]);

  return (
    <Wrapper>
      <SubPageHeader>
        <SubPageHeaderContainer>
          <SubPageHeaderTitle>
            <BackTitleIcon onClick={handleGoBack} />
            {t('Partners')}
          </SubPageHeaderTitle>
        </SubPageHeaderContainer>
      </SubPageHeader>

      {reviewAction === EReviewAction.REQUEST_ADDITIONAL_INFO && reviewNote ? (
        <WarningWrap>
          <Warning
            title={t('You specified what information is needed and it was sent to the client\'s Email.')}
            text={t(`We emailed this comment to: ${reviewNote}`)}
          />
        </WarningWrap>
      ) : null}

      <TabsStyled
        activeKey={pathname}
        onChange={onChangeTab}
      >
        <Tabs.TabPane tab={t('General Info')} key={RouteNames.ACCOUNT_PARTNER_GENERAL_INFO(customerId)} />
        <Tabs.TabPane tab={t('Documents')} key={RouteNames.ACCOUNT_PARTNER_DOCUMENTS(customerId)} />
        <Tabs.TabPane tab={t('Notes')} key={RouteNames.ACCOUNT_PARTNER_NOTES(customerId)} />
        <Tabs.TabPane tab={t('Accounting')} key={RouteNames.ACCOUNT_PARTNER_ACCOUNTING(customerId)} />
      </TabsStyled>

      <Outlet />

      <RejectModal />
      <AdditionalInformationModal />

      {!areButtonsDisabled ? (
        <Footer>
          {!hasRequestedAdditionalInfo ? (
            <AdditionalInfoButton
              onClick={onRequestAdditionalInfo}
              icon={<AdditionalInfoIcon />}
              type="default"
            >
              {t('Request Additional Information')}
            </AdditionalInfoButton>
          ) : null}

          <RejectButton
            type="default"
            onClick={onReject}
          >
            {t('Reject')}
          </RejectButton>

          <ApproveButton
            onClick={onApprove}
          >
            {t('Approve')}
          </ApproveButton>
        </Footer>
      ) : null}
    </Wrapper>
  );
};
