import styled from 'styled-components';
import ArrowLeftOutlined from '@ant-design/icons/ArrowLeftOutlined';

export const AccountDprtmByIdDivHeaderArrowIcon = styled(ArrowLeftOutlined).attrs({
  'data-class': 'AccountDprtmByIdDivHeaderArrowIcon',
})`
  margin-right: 16px;
  width: 13px;
  height: 13px;

  svg {
    width: 13px;
    height: 13px;
  }
`;
