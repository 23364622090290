import styled from 'styled-components';

import { colors } from 'app-wrapper/view/themes/themesColors';

export const ShippingPartyCardsLayout = styled.div`
  height: max-content;
  padding: 16px;

  background: white;
  border: 1px solid ${colors.purple15};
  border-radius: 3px;
`;

export const Title = styled.span`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
`;
