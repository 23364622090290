import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';

import { useMobxStore } from 'app-wrapper/mobxStores';
import { ExportClearanceType } from 'shipment-operations/constants';
import { UC } from 'shipment-operations/controllers';

import { InputActivityComponent } from './InputActivity.components';

interface InputActivityProps {
  type: ExportClearanceType;
  activityInitialValue?: string;
}

export const InputActivity: FC<InputActivityProps> = observer(({ type, activityInitialValue }) => {
  const { shipmentServices } = useMobxStore();

  return (
    <InputActivityComponent
      value={shipmentServices.state.inputValue}
      activityInitialValue={activityInitialValue}
      setInputValue={UC.shipmentServices.setInputValue}
      setInputError={UC.shipmentServices.setInputError}
      error={shipmentServices.state.inputError}
      type={type}
      submitInputActivity={UC.shipmentServices.submitInputActivity}
    />
  );
});
