import styled from 'styled-components';

import { divFlex } from 'app-wrapper/view/themes/mixin';

export const RCQCancelQuotaBlockWrap = styled.div.attrs({
  'data-class': 'RCQCancelQuotaBlockWrap',
})`
  ${divFlex}
  width: 580px;
  height: 258px;
`;

export const RCQCancelQuotaBlockContentWrap = styled.div.attrs({
  'data-class': 'RCQCancelQuotaBlockContentWrap',
})`
  ${divFlex}
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;
`;

export const RCQCancelQuotaBlockContentWrapImage = styled.div.attrs((props: {
  image: string
}) => ({
  image: props.image,
  'data-class': 'RCQCancelQuotaBlockContentWrapImage',
}))`
  ${divFlex}
`;

export const RCQCancelQuotaBlockHeader = styled.div.attrs({
  'data-class': 'RCQCancelQuotaBlockHeader',
})`
  ${divFlex}
  align-items: center;

  font-weight: 500;
  font-size: 20px;
  margin-top: 8px;
`;

export const RCQCancelQuotaBlockHeaderTitle = styled.div.attrs({
  'data-class': 'RCQCancelQuotaBlockHeaderTitle',
})`
  ${divFlex}
  align-items: center;

  font-weight: 400;
  font-size: 14px;
  color: ${({ theme }) => theme.themesColors.secondaryDot45};
`;
