import { emptyScrollbar } from 'app-wrapper/view/themes/components/emptyScrollbar';
import styled from 'styled-components';

import { divFlex } from 'app-wrapper/view/themes/mixin';
import { Button } from 'app-wrapper/view/components';

export const AACSpanEllipsis = styled.span.attrs({
  'data-class': 'AACSpanEllipsis',
})`
  max-width: 100%;
  position: relative;
  display: inline;

  text-overflow: ellipsis;
  overflow: clip;
  white-space: nowrap;
`;

export const AACContentTableTitleWrap = styled.div.attrs({
  'data-class': 'AACContentTableTitleWrap',
})`
  ${divFlex}

  height: 48px;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.themesColors.neutralBranding3};
  padding-bottom: 16px;
`;

export const AACContentTableTitle = styled.div.attrs({
  'data-class': 'AACContentTableTitle',
})`
  ${divFlex}
  width: 100%;
  align-items: center;

  color: ${({ theme }) => theme.themesColors.neutralBranding10};
  font-size: 16px;
  font-weight: 500;
`;

export const AACContentTableHeader = styled.div.attrs({
  'data-class': 'AACContentTableHeader',
})`
  ${divFlex}
  align-items: center;

  height: 36px;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.themesColors.neutralBranding3};
`;

export const AACContentTableHeaderAddBtn = styled(Button).attrs({
  'data-class': 'AACContentTableHeaderAddBtn',
})`
  ${divFlex}
  width: 140px;
  height: 32px;

  svg {
    color: ${({ theme }) => theme.themesColors.neutralBranding1};
    margin-right: 9px;
  }
  span {
    color: ${({ theme }) => theme.themesColors.neutralBranding1};
  }
`;

export const AACContentTableHeaderItem = styled.div.attrs((props: {
  isCenter?: boolean,
  width?: string,
  paddingLeft?: string,
}) => ({
  isCenter: props.isCenter,
  width: props.width,
  paddingLeft: props.paddingLeft,
  'data-class': 'AACContentTableHeaderItem',
}))`
  ${divFlex}
  align-items: center;
  justify-content: ${({ isCenter }) => (isCenter ? 'center' : 'left')};

  width: ${({ width }) => (width || 'auto')};
  padding-left: ${({ paddingLeft }) => (paddingLeft || '0')};

  color: ${({ theme }) => theme.themesColors.secondaryDot45};
  font-size: 12px;
  font-weight: 400;
`;

export const AACContentTableContentItem = styled.div.attrs({
  'data-class': 'AACContentTableContentItem',
})`
  ${divFlex}
  flex-direction: column;
  width: 100%;

  border-bottom: 1px solid ${({ theme }) => theme.themesColors.neutralBranding3}
`;

export const AACContentTableContentItemTop = styled.div.attrs({
  'data-class': 'AACContentTableContentItemTop',
})`
  ${divFlex}
  ${emptyScrollbar}

  height: 55px;
`;

export const AACContentTableContentTitle = styled.div.attrs((props: {
  isActive?: boolean,
  isCapitalize?: boolean,
  isFirst?: boolean,
  isLeft?: boolean,
}) => ({
  isActive: props.isActive,
  isCapitalize: props.isCapitalize,
  isFirst: props.isFirst,
  isLeft: props.isLeft,
  'data-class': 'AACContentTableContentTitle',
}))`
  ${divFlex}
  width: calc(17.8%);
  align-items: center;
  justify-content: ${({ isLeft }) => (isLeft ? 'left' : 'center')};

  margin-left: 10px;
  color: ${({ theme }) => theme.themesColors.characterBrandingTitle85};
  color: ${({ theme, isActive }) => (isActive ? theme.themesColors.primaryBranding6 : theme.themesColors.characterBrandingTitle85)};
  font-size: 14px;
  font-weight: 400;

  ${({ isCapitalize }) => isCapitalize && 'text-transform: capitalize'};
`;

export const AACContentTableContentLink = styled.div.attrs((props: {
  width?: string,
}) => ({
  width: props.width,
  'data-class': 'AACContentTableContentLink',
}))`
  ${divFlex}
  width: ${({ width }) => (width || '42.7%')};
  align-items: center;
  justify-content: left;

  color: ${({ theme }) => theme.themesColors.primaryBranding7};
  font-size: 14px;
  font-weight: 400;

  text-overflow: ellipsis;
  overflow: clip;
  white-space: nowrap;
  cursor: pointer;

  > span:first-letter {
    text-transform: uppercase;
  }
`;

export const AACContentTableContentPrimary = styled.div.attrs({
  'data-class': 'AACContentTableContentPrimary',
})`
  ${divFlex}
  width: 58px;
  height: 22px;
  align-items: center;
  justify-content: center;

  background-color: ${({ theme }) => theme.themesColors.polarGreen1};
  color: ${({ theme }) => theme.themesColors.polarGreen7};
  font-size: 12px;
  font-weight: 400;
  border: 1px solid ${({ theme }) => theme.themesColors.polarGreen3};
  margin-left: 8px;
  border-radius: 3px;
`;

export const AACContentTableContactWrap = styled.div.attrs({
  'data-class': 'AACContentTableContactWrap',
})`
  ${divFlex}
  width: 17.8%;
  align-items: center;
  justify-content: center;
`;

export const AACContentTableContact = styled.div.attrs({
  'data-class': 'AACContentTableContact',
})`
  ${divFlex}
  align-items: center;
  justify-content: center;
  min-width: 24px;
  width: 24px;
  height: 24px;

  margin: auto;
  color: ${({ theme }) => theme.themesColors.primaryBranding5};
  background-color: ${({ theme }) => theme.themesColors.primaryBranding1};
  font-size: 12px;
  font-weight: 400;
  border-radius: 100px;
`;
