import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { EStepStatus, Step } from 'app-wrapper/view/guideline';
import { AdditionalServiceDocumentActivityDTM, AdditionalServiceInputActivityDTM } from 'shipment-operations/models/dtm';
import { EAdditionalServiceStep, ExportClearanceType } from 'shipment-operations/constants';

import {
  InputActivity,
  UploadDocuments,
  SubmitInformationToSkypace,
  ValidateCustomerDocuments,
  CompleteService,
} from './components';
import { Form, StepForm, Steps } from './StepsForm.styled';

interface IStepsFormComponentProps {
  documentActivities: AdditionalServiceDocumentActivityDTM[];
  othersDocumentActivities: AdditionalServiceDocumentActivityDTM[];
  inputITNActivity?: AdditionalServiceInputActivityDTM;
  inputMRNActivity?: AdditionalServiceInputActivityDTM;
  inputISFActivity?: AdditionalServiceInputActivityDTM;
  step?: EAdditionalServiceStep;
  setStep: (step: EAdditionalServiceStep) => void;
  isManager: boolean;
}

export const StepsFormComponent: FC<IStepsFormComponentProps> = ({
  documentActivities,
  othersDocumentActivities,
  inputMRNActivity,
  inputITNActivity,
  inputISFActivity,
  step,
  setStep,
  isManager,
}) => {
  const { t } = useTranslation();

  const areDocumentActivitiesCompleted = useMemo(() => AdditionalServiceDocumentActivityDTM.calculateIfRequiredActivitiesCompleted(documentActivities), [documentActivities]);
  const areOthersDocumentsActivitiesCompleted = useMemo(() => AdditionalServiceDocumentActivityDTM.calculateIfRequiredActivitiesCompleted(othersDocumentActivities), [othersDocumentActivities]);

  return (
    <Form>
      <Steps>
        {isManager ? (
          <>
            {othersDocumentActivities.length ? (
              <Step
                name={t('Review documents provided by other parties')}
                status={areOthersDocumentsActivitiesCompleted ? EStepStatus.COMPLETED : EStepStatus.WAITING}
                onClick={() => setStep(EAdditionalServiceStep.VALIDATE_CUSTOMER_DOCUMENTS)}
                isChosen={step === EAdditionalServiceStep.VALIDATE_CUSTOMER_DOCUMENTS}
              />
            ) : null}

            {documentActivities.length ? (
              <Step
                name={t('Add Documents')}
                status={areDocumentActivitiesCompleted ? EStepStatus.COMPLETED : EStepStatus.WAITING}
                onClick={() => setStep(EAdditionalServiceStep.ADD_DOCUMENTS)}
                isChosen={step === EAdditionalServiceStep.ADD_DOCUMENTS}
              />
            ) : null}

            {inputITNActivity ? (
              <Step
                name={t('Enter ITN Number')}
                status={inputITNActivity.isCompleted() ? EStepStatus.COMPLETED : EStepStatus.WAITING}
                onClick={() => setStep(EAdditionalServiceStep.ENTER_ITN_NUMBER)}
                isChosen={step === EAdditionalServiceStep.ENTER_ITN_NUMBER}
              />
            ) : null}

            {inputMRNActivity ? (
              <Step
                name={t('Enter MRN Number')}
                status={inputMRNActivity.isCompleted() ? EStepStatus.COMPLETED : EStepStatus.WAITING}
                onClick={() => setStep(EAdditionalServiceStep.ENTER_MRN_NUMBER)}
                isChosen={step === EAdditionalServiceStep.ENTER_MRN_NUMBER}
              />
            ) : null}

            {inputISFActivity ? (
              <Step
                name={t('Enter ISF Number')}
                status={inputISFActivity.isCompleted() ? EStepStatus.COMPLETED : EStepStatus.WAITING}
                onClick={() => setStep(EAdditionalServiceStep.ENTER_ISF_NUMBER)}
                isChosen={step === EAdditionalServiceStep.ENTER_ISF_NUMBER}
              />
            ) : null}

            <Step
              name={t('Complete Service')}
              status={EStepStatus.WAITING}
              onClick={() => setStep(EAdditionalServiceStep.COMPLETE_SERVICE)}
              isChosen={step === EAdditionalServiceStep.COMPLETE_SERVICE}
            />
          </>
        ) : (
          <>
            {documentActivities.length ? (
              <Step
                name={t('Upload Documents')}
                status={areDocumentActivitiesCompleted ? EStepStatus.COMPLETED : EStepStatus.WAITING}
                onClick={() => setStep(EAdditionalServiceStep.UPLOAD_DOCUMENTS)}
                isChosen={step === EAdditionalServiceStep.UPLOAD_DOCUMENTS}
              />
            ) : null}

            {inputITNActivity ? (
              <Step
                name={t('Enter ITN Number')}
                status={inputITNActivity.isCompleted() ? EStepStatus.COMPLETED : EStepStatus.WAITING}
                onClick={() => setStep(EAdditionalServiceStep.ENTER_ITN_NUMBER)}
                isChosen={step === EAdditionalServiceStep.ENTER_ITN_NUMBER}
              />
            ) : null}

            {inputMRNActivity ? (
              <Step
                name={t('Enter MRN Number')}
                status={inputMRNActivity.isCompleted() ? EStepStatus.COMPLETED : EStepStatus.WAITING}
                onClick={() => setStep(EAdditionalServiceStep.ENTER_MRN_NUMBER)}
                isChosen={step === EAdditionalServiceStep.ENTER_MRN_NUMBER}
              />
            ) : null}

            {inputISFActivity ? (
              <Step
                name={t('Enter ISF Number')}
                status={inputISFActivity.isCompleted() ? EStepStatus.COMPLETED : EStepStatus.WAITING}
                onClick={() => setStep(EAdditionalServiceStep.ENTER_ISF_NUMBER)}
                isChosen={step === EAdditionalServiceStep.ENTER_ISF_NUMBER}
              />
            ) : null}

            <Step
              name={t('Submit Information to Skypace')}
              status={EStepStatus.WAITING}
              onClick={() => setStep(EAdditionalServiceStep.SUBMIT_SKYPACE_INFORMATION)}
              isChosen={step === EAdditionalServiceStep.SUBMIT_SKYPACE_INFORMATION}
            />
          </>
        )}
      </Steps>

      <StepForm>
        {step === EAdditionalServiceStep.ENTER_ITN_NUMBER ? (
          <InputActivity
            activityInitialValue={inputITNActivity?.getProvidedInputValue()}
            type={ExportClearanceType.ITN}
          />
        ) : null}
        {step === EAdditionalServiceStep.ENTER_ISF_NUMBER ? (
          <InputActivity
            activityInitialValue={inputISFActivity?.getProvidedInputValue()}
            type={ExportClearanceType.ISF}
          />
        ) : null}
        {step === EAdditionalServiceStep.ENTER_MRN_NUMBER ? (
          <InputActivity
            activityInitialValue={inputMRNActivity?.getProvidedInputValue()}
            type={ExportClearanceType.MRN}
          />
        ) : null}
        {step === EAdditionalServiceStep.VALIDATE_CUSTOMER_DOCUMENTS ? <ValidateCustomerDocuments /> : null}
        {step === EAdditionalServiceStep.UPLOAD_DOCUMENTS ? <UploadDocuments /> : null}
        {step === EAdditionalServiceStep.ADD_DOCUMENTS ? <UploadDocuments /> : null}
        {step === EAdditionalServiceStep.SUBMIT_SKYPACE_INFORMATION ? <SubmitInformationToSkypace /> : null}
        {step === EAdditionalServiceStep.COMPLETE_SERVICE ? <CompleteService /> : null}
      </StepForm>
    </Form>
  );
};
