import { BaseController, controller } from 'proto/BaseController';
import { EShipmentConfirmationTypes } from 'shipment-operations/constants';
import { ShipmentConfirmationDTM } from 'shipment-operations/models/dtm';

import { R as appR } from 'app-wrapper/repository';
import { R as userManagementR, R } from 'user-management/repository';
import { RouteNames } from 'app-wrapper/constants';
import { EOrganizationStatus, OrganizationDTM } from 'user-management/models/dtm';

@controller
export class NewOrganizationWizardReviewAndConfirmController extends BaseController {
  public initData = async () => {
    let organization: OrganizationDTM | null = null;

    this.dispatch(R.actions.newOrganizationWizardReviewAndConfirm.setIsLoading(true));

    try {
      organization = await R.services.organization.getCurrentOrganization();
    } catch (e) {
      console.error('NewOrganizationWizardReviewAndConfirmController ERROR: initData');
    }

    if (organization) {
      this.dispatch(R.actions.newOrganizationWizardReviewAndConfirm.setOrganization(organization));

      const relatedAdmin = await R.services.organization.getRelatedAdminPublicInfo(organization?.id);

      this.dispatch(appR.actions.agreements.setCurrentOrgRelatedAdmin(relatedAdmin));
      this.dispatch(R.actions.newOrganizationWizardReviewAndConfirm.setCurrentOrgRelatedAdmin(relatedAdmin));
    }

    if (organization?.accountHolderId) {
      const relatedPartner = await R.services.organization.getCustomerRelatedPartnerPublicInfo(organization?.accountHolderId);

      this.dispatch(R.actions.newOrganizationWizardReviewAndConfirm.setCurrentOrgRelatedPartner(relatedPartner));
    }

    this.dispatch(R.actions.newOrganizationWizardReviewAndConfirm.setIsLoading(false));
  }

  public setAreTermsChecked = (isChecked: boolean) => {
    this.dispatch(R.actions.newOrganizationWizardReviewAndConfirm.setAreTermsChecked(isChecked));
  }

  public confirmAndReview = async () => {
    const organization = R.selectors.newOrganizationWizardReviewAndConfirm.getOrganization(this.store.getState());

    if (!organization) {
      return;
    }

    await userManagementR.services.organization.postConfirmations(Number(organization?.id), ShipmentConfirmationDTM.fromPlain({
      type: EShipmentConfirmationTypes.TERMS_AND_CONDITIONS,
    }));

    try {
      await R.services.organization.putCurrentOrganization(OrganizationDTM.fromPlain({
        ...organization,
        status: EOrganizationStatus.WAITING_APPROVAL,
      }));
    } catch (e) {
      console.error('NewOrganizationWizardReviewAndConfirmController ERROR: confirmAndReview');

      return;
    }

    const reloadedOrganization = await userManagementR.services.organization.getCurrentOrganization(true);

    this.dispatch(userManagementR.actions.userOrganizationData.setUserOrganization(reloadedOrganization));

    this.navigate(RouteNames.NEW_ORGANIZATION_SUCCESS());
  }

  public goPrevStep = () => {
    this.navigate(RouteNames.SUBMIT_PROOF_DOCUMENTS_WIZARD_ITEM());
  }

  public cancelWizard = () => {
    this.navigate(RouteNames.OVERVIEW());
  }
}
