import React from 'react';
import { observer } from 'mobx-react-lite';

import { useMobxStore } from 'app-wrapper/mobxStores';

import { ServiceWorkflowComponent } from './ServiceWorkflow.component';

export const ServiceWorkflow = observer(() => {
  const { shipmentServices } = useMobxStore();

  return (
    <ServiceWorkflowComponent isInEditMode={shipmentServices.state.isInEditMode} />
  );
});
