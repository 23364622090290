import styled from 'styled-components';

import { FlexCol } from 'app-wrapper/view/guideline';

export const Wrap = styled(FlexCol)`
  width: 100%;
  height: 100%;
  padding: 200px 0;
  align-items: center;
  justify-content: center;
  border: 1px dashed ${({ theme }) => theme?.themesColors?.neutralBranding4};
`;

export const Image = styled.img`
  margin-bottom: 4.5px;
  width: 72px;
  height: auto;
`;

export const Title = styled.span`
  margin-bottom: 4px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  color: rgba(32, 44, 60, 1);
`;

export const SubTitle = styled.span`
  margin-bottom: 16px;
  font-size: 12px;
  line-height: 20px;
  color: rgba(115, 129, 155, 1);
`;
