import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { UploadProps } from 'antd/es/upload/interface';
import DeleteOutlined from '@ant-design/icons/DeleteOutlined';
import UploadOutlined from '@ant-design/icons/UploadOutlined';

import { useResponsiveSize } from 'app-wrapper/hooks';
import { addPrefix } from 'app-wrapper/utils';

import { CargoDTM } from 'shipment-operations/models/dtm';

import { Upload, Button } from './CargoDocumentUpload.styled';

interface ICargoDocumentUploadComponentProps {
  error: boolean
  authToken: string
  cargoId: number
  shipmentId: string;
  msdsDocument: CargoDTM['msdsDocument']
  downloadCargoMsdsDocument: (shipmentId: number, cargoId: number) => void
}

export const CargoDocumentUploadComponent: FC<ICargoDocumentUploadComponentProps> = ({
  error,
  authToken,
  msdsDocument,
  downloadCargoMsdsDocument,
  shipmentId,
  cargoId,
}) => {
  const { t } = useTranslation();
  const size = useResponsiveSize();

  const downloadMsdsDocument = useCallback(() => downloadCargoMsdsDocument(+shipmentId, cargoId), [downloadCargoMsdsDocument, cargoId, shipmentId]);

  const handlePreview: UploadProps['onPreview'] = useCallback((file) => {
    if (!file.status) {
      return;
    }
    downloadMsdsDocument();
  }, []);

  const postUrl = addPrefix(`/shipment-service/api/v1/shipments/${shipmentId}/documents?type=MSDS`);
  const isErrorOutlineDisplayed = error && !msdsDocument.length;

  return (
    <Upload
      maxCount={1}
      multiple={false}
      showUploadList={{ showRemoveIcon: true, removeIcon: <DeleteOutlined /> }}
      action={postUrl}
      headers={{ Authorization: authToken }}
      fileList={msdsDocument}
      onPreview={handlePreview}
      disabled
    >
      <Button
        error={+isErrorOutlineDisplayed}
        icon={<UploadOutlined />}
        type="dashed"
        size={size}
        disabled
      >
        {t('Upload document')}
      </Button>
    </Upload>
  );
};
