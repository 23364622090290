import React from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';
import { UC } from 'shipment-operations/controllers';

import { AddressLine1InputComponent } from 'shipment-operations/view/pages/ShipmentShippingParties/components/ShippingPartiesForm/components/AddressSection/components/AddressLine1Input/AddressLine1Input.component';

const AddressLine2Input = () => {
  const addressLine = useSelector(R.selectors.shippingParties.getAddressLine2);

  return (
    <AddressLine1InputComponent addressLine={addressLine} setAddressLine={UC.shippingParties.setAddressLine2} />
  );
};

const AddressLine2InputCached = React.memo(AddressLine2Input);

export { AddressLine2InputCached as AddressLine2Input };
