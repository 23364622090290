import React from 'react';
import { useSelector } from 'react-redux';

import { UC } from 'authentication/controllers';
import { R } from 'authentication/repository';

import { UserMenuItemComponent } from './UserMenuItem.component';

const UserMenuItemContainer = ({ onClosePopover }: { onClosePopover: () => void }) => {
  const userName = useSelector(R.selectors.auth.getUserFullName);
  const userEmail = useSelector(R.selectors.auth.getEmail);

  return (
    <UserMenuItemComponent
      userName={userName}
      userEmail={userEmail}
      onSignOut={UC.auth.logOut}
      onClosePopover={onClosePopover}
    />
  );
};

export { UserMenuItemContainer as UserMenuItem };
