export enum ECarrierSCAC {
  'APLU' = 'APLU',
  'CMDU' = 'CMDU',
  'COSU' = 'COSU',
  'EGLV' = 'EGLV',
  'HLCU' = 'HLCU',
  'SUDU' = 'SUDU',
  'HDMU' = 'HDMU',
  'MAEU' = 'MAEU',
  'MSCU' = 'MSCU',
  'OOLU' = 'OOLU',
  'WHLU' = 'WHLU',
  'YMLU' = 'YMJA',
  'ZIMU' = 'ZIMU',
  'MCSM' = 'MCSM',
  'PCIU' = 'PCIU',
  'ECUW' = 'ECUW',
  'ESPU' = 'ESPU',
  'ANNU' = 'ANNU',
  'MATS' = 'MATS',
  'SMLM' = 'SMLM',
  'ACLU' = 'ACLU',
  'ONEY' = 'ONEY',
  'IILU' = 'IILU',
  'SMLU' = 'SMLU',
  'WWSU' = 'WWSU',
  '11DX' = '11DX',
}
