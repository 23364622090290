import React from 'react';
import { createGlobalStyle } from 'styled-components';

import { TooltipLocCss } from 'app-wrapper/view/styles/mixins';

export const TooltipWrapperCss2 = (classId: string, options?: {
  styled: React.CSSProperties,
}) => createGlobalStyle`
  .${classId} {
    ${TooltipLocCss}
    .ant-tooltip-inner {
      ${options?.styled.width && `width: ${options?.styled.width}`};
    }
  }
`;
