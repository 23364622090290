import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';
import { UC } from 'shipment-operations/controllers';

import { ContainerNumberInputComponent } from './ContainerNumberInput.component';

interface ContainerNumberInputContainerProps {
  containerId: string;
}

const ContainerNumberInputContainer: FC<ContainerNumberInputContainerProps> = ({ containerId }) => {
  const { number = '' } = useSelector(R.selectors.bookingWizard.getContainerById(containerId));
  const { number: error } = useSelector(R.selectors.bookingWizard.getContainerErrorsById(containerId));

  return (
    <ContainerNumberInputComponent
      containerId={containerId}
      value={number}
      setValue={UC.bookingWizard.setContainerNumber}
      error={!!error}
    />
  );
};

export { ContainerNumberInputContainer as ContainerNumberInput };
