import React, { FC, useEffect } from 'react';

import {
  SubPageContent,
  SubPageHeader,
  Wrapper,
} from 'app-wrapper/view/guideline';
import {
  UserManagementHeader,
  UserManagementContent,
} from './components';

interface IUserManagementComponentProps {
  onUpload: () => void
  onClear: () => void
  updateSuccess: boolean
  deleteSuccess: boolean
}

export const UserManagementComponent: FC<IUserManagementComponentProps> = ({
  onUpload, onClear, updateSuccess, deleteSuccess,
}) => {
  useEffect(() => {
    onUpload();

    return onClear;
  }, []);

  useEffect(() => {
    if (updateSuccess || deleteSuccess) {
      onUpload();
    }
  }, [updateSuccess, deleteSuccess]);

  return (
    <Wrapper>
      <SubPageHeader>
        <UserManagementHeader />
      </SubPageHeader>
      <SubPageContent>
        <UserManagementContent>
          <UserManagementContent />
        </UserManagementContent>
      </SubPageContent>
    </Wrapper>
  );
};
