import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { R as userManagementR } from 'user-management/repository';
import { R } from 'shipment-operations/repository';
import { UC } from 'shipment-operations/controllers';

import { CountryInputComponent } from './CountryInput.component';

const CountryInputContainer: FC = () => {
  const country = useSelector(R.selectors.shippingParties.getCountry);
  const requiredError = useSelector(R.selectors.shippingParties.getIsRequiredErrorVisible);
  const countries = useSelector(userManagementR.selectors.newOrganizationData.organizationCountriesLimited);

  return (
    <CountryInputComponent
      countries={countries || []}
      country={country}
      setCountry={UC.shippingParties.setCountry}
      error={requiredError}
    />
  );
};

const CountryInputContainerCached = React.memo(CountryInputContainer);

export {
  CountryInputContainerCached as CountryInput,
};
