import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'app-wrapper/createSlice';

import { TNewOrganizationWizardReviewAndConfirmState } from 'user-management/models/states';
import { OrganizationDTM, AdminPublicInfoDTM } from 'user-management/models/dtm';

export const initialState: TNewOrganizationWizardReviewAndConfirmState = {
  isLoading: false,
  organization: null,
  currentOrgRelatedAdmin: null,
  currentOrgRelatedPartner: null,
  areTermsChecked: false,
};

export const newOrganizationWizardReviewAndConfirmStore = createSlice({
  name: 'newOrganizationWizardReviewAndConfirm',
  initialState,
  reducers: {
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setOrganization: (state, { payload }: PayloadAction<OrganizationDTM>) => ({
      ...state,
      organization: payload,
    }),
    setCurrentOrgRelatedAdmin: (state, { payload }: PayloadAction<AdminPublicInfoDTM | null>) => ({
      ...state,
      currentOrgRelatedAdmin: payload,
    }),
    setCurrentOrgRelatedPartner: (state, { payload }: PayloadAction<AdminPublicInfoDTM | null>) => ({
      ...state,
      currentOrgRelatedPartner: payload,
    }),
    setAreTermsChecked: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      areTermsChecked: payload,
    }),
    clear: () => (initialState),
  },
});

export const newOrganizationWizardReviewAndConfirmActions = newOrganizationWizardReviewAndConfirmStore.actions;
export const newOrganizationWizardReviewAndConfirmReducer = newOrganizationWizardReviewAndConfirmStore.reducer;
