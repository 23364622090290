import toNumber from 'lodash/fp/toNumber';
import { v4 as uuidv4 } from 'uuid';

import { IDocumentDTM } from 'app-wrapper/models/dtm';
import { apiWorker } from 'app-wrapper/repository/utilsServices';

import {
  CargoBaseDTM, CargoDTM, MSDSDocumentDTM,
} from 'shipment-operations/models/dtm';
import {
  GetCargosResponse,
  IPutCargoRequest, IPutCargoResponse,
  IPostCargoRequest, IPostCargoResponse,
} from 'shipment-operations/models/contracts';

export class CargoService {
  private base = '/shipment-service/api/v1/shipments';

  public getCargos = async (shipmentId: number): Promise<CargoDTM[]> => {
    const response = await apiWorker.requestGet<GetCargosResponse>(`${this.base}/${shipmentId}/cargos`);

    const cargos = this.convertFromGetCargosResponse(response.data);

    return cargos;
  }

  public getCargoBases = async (shipmentId: number): Promise<CargoBaseDTM[]> => {
    const response = await apiWorker.requestGet<GetCargosResponse>(`${this.base}/${shipmentId}/cargos`);

    const cargos = this.convertFromGetCargoBasesResponse(response.data);

    return cargos;
  }

  public postCargo = async (shipmentId: number, cargo: CargoDTM): Promise<CargoDTM | null> => {
    let cargoRes: CargoDTM | null = null;
    const response = await apiWorker.requestPost<IPostCargoResponse>(`${this.base}/${shipmentId}/cargos`, this.convertToPostCargoRequest(cargo));

    cargoRes = this.convertFromPostCargoResponse(response.data);

    return cargoRes;
  }

  public putCargo = async (shipmentId: number, cargo: CargoDTM): Promise<CargoDTM | null> => {
    let cargoRes: CargoDTM | null = null;
    const response = await apiWorker.requestPut<IPutCargoRequest>(`${this.base}/${shipmentId}/cargos/${cargo.id}`, this.convertToPutCargoRequest(cargo));

    cargoRes = this.convertFromPutCargoResponse(response.data);

    return cargoRes;
  }

  public deleteCargo = async (shipmentId: number, cargoId: number): Promise<boolean> => {
    await apiWorker.requestDelete(`${this.base}/${shipmentId}/cargos/${cargoId}`);

    return true;
  }

  private convertFromDocumentResponse = (document?: IDocumentDTM) => (
    document ? ([MSDSDocumentDTM.fromPlain({
      uid: document.id.toString(),
      name: document.name,
      status: 'done',
      response: document,
      url: '/',
    })]) : []
  );

  private convertFromGetCargosResponse = (cargos: GetCargosResponse): CargoDTM[] => cargos.map((cargo) => {
    const {
      id,
      code = '',
      name = '',
      description = '',
      value,
      packageType,
      packagesNumber,
      weight,
      volume,
      marks = '',
      loadSummary,
    } = cargo;
    const { references = [], hazmat } = cargo;

    const baseFields = CargoBaseDTM.fromPlain({
      code,
      name,
      description: description || '',
      packageType,
      marks: marks || '',
      loadSummary,
      references: references.length ? references.map((reference) => ({
        ...reference,
        id: uuidv4(),
      })) : [{ id: uuidv4() }],
      packagesNumber: packagesNumber ? packagesNumber.toString() : '',
      value: value ? value.toString() : '',
      weight: weight ? weight.toString() : '',
      volume: volume ? volume.toString() : '',
      unNumber: hazmat?.unNumber || '',
      imoClass: hazmat?.imoClass,
      shippingName: hazmat?.shippingName || '',
      packingGroup: hazmat?.packingGroup,
      msdsDocument: this.convertFromDocumentResponse(hazmat?.msdsDocument),
      contactName: hazmat?.emergencyContact?.name || '',
      contactNumber: hazmat?.emergencyContact?.phone || '',
    });

    const msdsStatus = (
      baseFields.msdsDocument
      && !!baseFields.msdsDocument.length
      && baseFields.msdsDocument[0].status === 'done'
      && !!baseFields.msdsDocument[0].response.id
      && !!baseFields.msdsDocument[0].response.name
      && !!baseFields.msdsDocument[0].response.type
    );

    const isHazmat = (
      msdsStatus
      || !!baseFields.imoClass
      || !!baseFields.unNumber
      || !!baseFields.packingGroup
      || !!baseFields.shippingName
      || !!baseFields.contactName
      || !!baseFields.contactNumber
    );

    return CargoDTM.fromPlain({
      renderId: `${id}`,
      touchedFields: {},
      errors: {},
      initialState: { ...baseFields },
      wasUpdateAttempted: false,
      hsCodeValidationStatus: code ? 'REQUEST_SENT_AND_VALID' : 'REQUEST_NOT_SENT',
      isHazmatCollapseOpen: isHazmat,
      isHazmat,
      id,
      ...baseFields,
    });
  });

  private convertFromGetCargoBasesResponse = (cargos: GetCargosResponse): CargoBaseDTM[] => cargos.map((cargo) => {
    const {
      id,
      code = '',
      name = '',
      description = '',
      value = '',
      packageType,
      packagesNumber,
      weight,
      volume,
      marks = '',
      loadSummary,
    } = cargo;
    const { references = [], hazmat } = cargo;

    return CargoBaseDTM.fromPlain({
      code,
      name,
      description,
      packageType,
      marks,
      loadSummary,
      baseId: `${id}`,
      references: references.length ? references.map((reference) => ({
        ...reference,
        id: uuidv4(),
      })) : [{ id: uuidv4() }],
      packagesNumber: packagesNumber ? packagesNumber.toString() : '',
      value: value ? value.toString() : '',
      weight: weight ? weight.toString() : '',
      volume: volume ? volume.toString() : '',
      unNumber: hazmat?.unNumber,
      imoClass: hazmat?.imoClass,
      shippingName: hazmat?.shippingName,
      packingGroup: hazmat?.packingGroup,
      msdsDocument: this.convertFromDocumentResponse(hazmat?.msdsDocument),
      contactName: hazmat?.emergencyContact?.name,
      contactNumber: hazmat?.emergencyContact?.phone,
    });
  });

  private convertToPostCargoRequest = (cargo: CargoDTM): IPostCargoRequest => {
    const {
      code,
      name,
      description,
      value,
      packageType,
      packagesNumber,
      weight,
      volume,
      marks,
      references = [],
      isHazmat,
      unNumber,
      imoClass,
      packingGroup,
      shippingName,
      msdsDocument,
      contactName,
      contactNumber,
    } = cargo;

    return {
      code: code as IPostCargoRequest['code'],
      name: name as IPostCargoRequest['name'],
      packageType: packageType as IPostCargoRequest['packageType'],
      description,
      marks,
      packagesNumber: toNumber(packagesNumber),
      value: toNumber(value),
      weight: toNumber(weight),
      volume: toNumber(volume),
      references: (
        references
          .filter((ref) => !!ref.value && !!ref.type)
          .map((ref) => ({ type: ref.type, value: ref.value }))
      ) as IPostCargoRequest['references'],
      hazmat: isHazmat ? {
        unNumber,
        imoClass,
        packingGroup,
        shippingName,
        msdsDocument: msdsDocument[0].response,
        emergencyContact: {
          name: contactName,
          phone: contactNumber,
        },
      } as IPostCargoRequest['hazmat'] : undefined,
    };
  };

  private convertFromPostCargoResponse = (cargo: IPostCargoResponse): CargoDTM => {
    const {
      id,
      code,
      name,
      description,
      value,
      packageType,
      packagesNumber,
      weight,
      volume,
      marks,
    } = cargo;
    const { references = [], hazmat } = cargo;

    const baseFields = CargoBaseDTM.fromPlain({
      code,
      name,
      description,
      packageType,
      marks,
      references: references.length ? references.map((reference) => ({
        ...reference,
        id: uuidv4(),
      })) : [{ id: uuidv4() }],
      packagesNumber: packagesNumber ? packagesNumber.toString() : undefined,
      value: value ? value.toString() : undefined,
      weight: weight ? weight.toString() : undefined,
      volume: volume ? volume.toString() : undefined,
      unNumber: hazmat?.unNumber,
      imoClass: hazmat?.imoClass,
      shippingName: hazmat?.shippingName,
      packingGroup: hazmat?.packingGroup,
      msdsDocument: this.convertFromDocumentResponse(hazmat?.msdsDocument),
      contactName: hazmat?.emergencyContact?.name,
      contactNumber: hazmat?.emergencyContact?.phone,
      loadSummary: {
        packagesNumber: 0,
        volume: 0,
        weight: 0,
      },
    });

    const msdsStatus = (
      baseFields.msdsDocument
      && !!baseFields.msdsDocument.length
      && baseFields.msdsDocument[0].status === 'done'
      && !!baseFields.msdsDocument[0].response.id
      && !!baseFields.msdsDocument[0].response.name
      && !!baseFields.msdsDocument[0].response.type
    );

    const isHazmat = (
      msdsStatus
      || !!baseFields.imoClass
      || !!baseFields.unNumber
      || !!baseFields.packingGroup
      || !!baseFields.shippingName
      || !!baseFields.contactName
      || !!baseFields.contactNumber
    );

    return CargoDTM.fromPlain({
      renderId: `${id}`,
      touchedFields: {},
      errors: {},
      initialState: { ...baseFields },
      wasUpdateAttempted: false,
      hsCodeValidationStatus: code ? 'REQUEST_SENT_AND_VALID' : 'REQUEST_NOT_SENT',
      isHazmat,
      isHazmatCollapseOpen: isHazmat,
      id,
      ...baseFields,
    });
  };

  private convertToPutCargoRequest = (cargo: CargoDTM): IPutCargoRequest => {
    const {
      id,
      code,
      name,
      packageType,
      packagesNumber,
      description,
      value,
      weight,
      volume,
      marks,
      references = [],
      isHazmat,
      unNumber,
      imoClass,
      packingGroup,
      shippingName,
      msdsDocument,
      contactName,
      contactNumber,
    } = cargo;

    return {
      id: id as IPutCargoRequest['id'],
      code: code as IPutCargoRequest['code'],
      name: name as IPutCargoRequest['name'],
      packageType: packageType as IPutCargoRequest['packageType'],
      description,
      marks,
      packagesNumber: toNumber(packagesNumber),
      value: toNumber(value),
      weight: toNumber(weight),
      volume: toNumber(volume),
      references: (
        references
          .filter((ref) => !!ref.value && !!ref.type)
          .map((ref) => ({ type: ref.type, value: ref.value }))
      ) as IPutCargoRequest['references'],
      hazmat: isHazmat ? {
        unNumber,
        imoClass,
        packingGroup,
        shippingName,
        msdsDocument: msdsDocument[0].response,
        emergencyContact: {
          name: contactName,
          phone: contactNumber,
        },
      } as IPutCargoRequest['hazmat'] : undefined,
    };
  };

  private convertFromPutCargoResponse = (cargo: IPutCargoResponse): CargoDTM => {
    const {
      id,
      code,
      name,
      description,
      value,
      packageType,
      packagesNumber,
      weight,
      volume,
      marks,
    } = cargo;
    const { references = [], hazmat } = cargo;

    const baseFields = CargoBaseDTM.fromPlain({
      code,
      name,
      description,
      packageType,
      marks,
      references: references.length ? references.map((reference) => ({
        ...reference,
        id: uuidv4(),
      })) : [{ id: uuidv4() }],
      packagesNumber: packagesNumber ? packagesNumber.toString() : undefined,
      value: value ? value.toString() : undefined,
      weight: weight ? weight.toString() : undefined,
      volume: volume ? volume.toString() : undefined,
      unNumber: hazmat?.unNumber,
      imoClass: hazmat?.imoClass,
      shippingName: hazmat?.shippingName,
      packingGroup: hazmat?.packingGroup,
      msdsDocument: this.convertFromDocumentResponse(hazmat?.msdsDocument),
      contactName: hazmat?.emergencyContact?.name,
      contactNumber: hazmat?.emergencyContact?.phone,
      loadSummary: {
        packagesNumber: 0,
        volume: 0,
        weight: 0,
      },
    });

    const msdsStatus = (
      baseFields.msdsDocument
      && !!baseFields.msdsDocument.length
      && baseFields.msdsDocument[0].status === 'done'
      && !!baseFields.msdsDocument[0].response.id
      && !!baseFields.msdsDocument[0].response.name
      && !!baseFields.msdsDocument[0].response.type
    );

    const isHazmat = (
      msdsStatus
      || !!baseFields.imoClass
      || !!baseFields.unNumber
      || !!baseFields.packingGroup
      || !!baseFields.shippingName
      || !!baseFields.contactName
      || !!baseFields.contactNumber
    );

    return CargoDTM.fromPlain({
      renderId: `${id}`,
      touchedFields: {},
      errors: {},
      wasUpdateAttempted: false,
      hsCodeValidationStatus: code ? 'REQUEST_SENT_AND_VALID' : 'REQUEST_NOT_SENT',
      isHazmat,
      isHazmatCollapseOpen: isHazmat,
      initialState: { ...baseFields },
      id,
      ...baseFields,
    });
  };
}
