import React, { FC, useMemo } from 'react';

import { ShippingPartyOverviewDTM } from 'shipment-operations/models/dtm';

import { Wrap, TextRow } from 'shipment-operations/view/pages/ShipmentBillOfLading/components/ShipmentInstructionsContent/components/SBLShippingParties/components/ShippingPartyInfo/ShippingPartyInfo.styled';

interface ShippingPartyCardProps {
  shippingParty: ShippingPartyOverviewDTM;
}

export const ShippingPartyCard: FC<ShippingPartyCardProps> = ({ shippingParty }) => {
  const {
    address,
    companyName,
    contactPerson,
    email,
    phone,
    references,
    taxId = '',
  } = shippingParty;
  const {
    address1 = '',
    address2 = '',
    city = '',
    state = '',
    country = '',
    postalCode = '',
  } = address || {};
  const joinedReferences = useMemo(() => references.map((reference) => reference.value).join(', '), [references]);

  const rows = useMemo(() => [
    companyName,
    address1,
    address2,
    `${city}${state ? `, ${state}` : ''}, ${country}, ${postalCode}`,
    contactPerson,
    `${email}, ${phone}`,
    joinedReferences,
    taxId,
  ], [companyName, address1, address2, city, state, country, postalCode, contactPerson, email, phone, references, taxId, joinedReferences]);

  return (
    <Wrap>
      {rows.map((row) => (
        <TextRow key={row}>
          {row}
        </TextRow>
      ))}
    </Wrap>
  );
};
