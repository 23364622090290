import styled from 'styled-components';
import { Input } from 'app-wrapper/view/components';
import Alert from 'antd/es/alert';

export const Wrapper = styled.div`
  padding: 24px 24px 80px 24px;
  position: relative;
  height: 100%;
  background-color: #F4F5F8;
  overflow: scroll;
`;

export const FooterWrapper = styled.div.attrs((props: {
  $isLeftAlign?: boolean,
}) => ({
  $isLeftAlign: props.$isLeftAlign,
}))`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 64px;
  box-shadow: 0 9px 28px 8px rgba(0, 0, 0, 0.05), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12);
  display: flex;
  align-items: center;
  justify-content: ${(props) => (props.$isLeftAlign ? 'flex-start' : 'flex-end')};
  padding: 0 24px;
  background-color: white;
`;

export const CarrierWrapper = styled.div`
  background-color: white;
  padding: 16px;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

export const InputsWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const InputTitle = styled.span`
  margin: 0;
`;

export const SubTitle = styled.span`
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  color: #202C3C;
  margin-bottom: 10px;
  display: block;
`;

export const Underline = styled.div`
  width: 100%;
  height: 1px;
  background-color: #EAEEF8;
`;

export const StyledInput = styled(Input)`
  width: 240px;
  margin-left: 10px;
`;

export const UploadWrapper = styled.div`
  background-color: white;
  padding: 16px;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const TableWrapper = styled.div`
  padding: 16px;
  background-color: white;
  margin-top: 10px;
`;

export const SpinnerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
`;

export const StyledAlert = styled(Alert)`
  margin-bottom: 10px;
  white-space: pre-line;
`;

export const ValidateWrapper = styled.div`
  margin-top: 10px;
`;
