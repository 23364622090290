import moment from 'moment';

import { apiWorker } from 'app-wrapper/repository/utilsServices';
import { GetPaymentsContract } from 'monetary/models/contracts';
import { PaymentsResponseDTM } from 'monetary/models/dtm';
import { PaymentsStatusesEnum } from 'shipment-operations/constants';
import { GetPaymentResponseDTM } from 'shipment-operations/models/dtm';

const timeFormat = 'D MMM YYYY';

export class PaymentsService {
  public getData = async (category: string, page: number) => {
    let result: PaymentsResponseDTM;

    try {
      const response = await apiWorker.requestGet<GetPaymentsContract>(`billing-service/api/v1/${category}/payments?page=${page}&size=15&sort=id,desc`);
      const parsedResponse = response.data.content.map((parsedRes) => {
        const parsedItem = GetPaymentResponseDTM.fromPlain({
          createdAt: moment(parsedRes.createdAt).format(timeFormat),
          amount: parsedRes.amount,
          paid: parsedRes.paid,
          id: parsedRes.id,
          number: parsedRes.number,
          status: parsedRes.status as PaymentsStatusesEnum,
          createdBy: parsedRes.createdBy,
          balance: parsedRes.balance,
          transactions: parsedRes.transactions,
          reference: parsedRes.reference,
          debtor: parsedRes.debtor,
          creditor: parsedRes.creditor,
          documents: parsedRes.documents,
        });
        if (!parsedItem.isValid()) {
          console.error('Data from API does not match with contract');
        }
        return parsedItem;
      });
      const { totalElements } = response.data;
      result = PaymentsResponseDTM.fromPlain({
        totalElements,
        data: parsedResponse.filter((el) => el !== null) as GetPaymentResponseDTM[],
      });
    } catch (e) {
      throw new Error('Something wrong, please try again');
    }

    return result;
  }
}
