import styled from 'styled-components';
import Typography from 'antd/es/typography';

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 16px 13px 16px;
  border-radius: 3px;
  background-color: white;
`;

export const SectionTitle = styled(Typography)`
  width: 100%;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;
