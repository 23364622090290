import { ArrowLeftOutlined } from '@ant-design/icons';
import React, {
  FC,
  useCallback,
  ChangeEvent, useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import MailOutlined from '@ant-design/icons/MailOutlined';

import { RouteNames } from 'app-wrapper/constants';
import { SkypaceDarkLogo } from 'app-wrapper/view/icons';

import {
  BottomMessage,
  LogoWrapper,
  PasswordResetEmailSendButton,
  PasswordResetEmailSendEmailInput,
  PasswordResetEmailSendForm,
  PasswordResetEmailSendMessageHeader,
  PasswordResetEmailSendMessage,
  PasswordResetEmailSendTitle,
  PasswordResetEmailSendWrapper,
  SignUpLinkWrapper,
} from './PasswordResetEmailSend.styled';

interface IPasswordResetEmailSendComponentProps {
  loading: boolean
  emailValue?: string
  emailError?: Error
  onEmailFieldChange: (email: string) => void
  onEmailBlur: () => void
  onPasswordResetEmailSend: () => void
  onScreenLeave: () => void
}

export const PasswordResetEmailSendComponent: FC<IPasswordResetEmailSendComponentProps> = ({
  loading,
  emailValue,
  emailError,
  onEmailFieldChange,
  onEmailBlur,
  onPasswordResetEmailSend,
  onScreenLeave,
}) => {
  const { t } = useTranslation();

  useEffect(() => onScreenLeave, []);

  const emailChangeHandler = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      onEmailFieldChange(event.currentTarget.value);
    },
    [],
  );

  const emailBlurHandler = useCallback(
    () => {
      onEmailBlur();
    },
    [],
  );

  const passwordResetEmailSendHandler = useCallback(
    () => {
      onPasswordResetEmailSend();
    },
    [],
  );

  return (
    <PasswordResetEmailSendWrapper>
      <PasswordResetEmailSendForm>
        <LogoWrapper>
          <SkypaceDarkLogo />
        </LogoWrapper>
        <PasswordResetEmailSendTitle>
          {t('Empowering Global Trade Logistics')}
        </PasswordResetEmailSendTitle>
        <PasswordResetEmailSendMessageHeader>
          {t('Forgot password?')}
        </PasswordResetEmailSendMessageHeader>
        <PasswordResetEmailSendMessage>
          {t('Enter Email associated with your account and we will send you a confirmation code on this address.')}
        </PasswordResetEmailSendMessage>
        <PasswordResetEmailSendEmailInput
          placeholder={t('Email')}
          prefix={<MailOutlined />}
          value={emailValue}
          error={!!emailError}
          size="large"
          onChange={emailChangeHandler}
          onBlur={emailBlurHandler}
        />
        <PasswordResetEmailSendButton
          data-class="PasswordResetEmailSend"
          size="large"
          onClick={passwordResetEmailSendHandler}
          loading={loading}
        >
          {t('Request Password Reset')}
        </PasswordResetEmailSendButton>
        <SignUpLinkWrapper>
          <Link to={RouteNames.SIGN_IN()}><ArrowLeftOutlined />{t('Back to Login')}</Link>
        </SignUpLinkWrapper>
      </PasswordResetEmailSendForm>
      <BottomMessage />
    </PasswordResetEmailSendWrapper>
  );
};
