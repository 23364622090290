import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { UC } from 'shipment-operations/controllers';
import { R } from 'shipment-operations/repository';

import { ShippingPartyReferenceInputComponent } from './ShippingPartyReferenceInput.component';

const ShippingPartyReferenceInputContainer: FC = () => {
  const shipmentPartyReference = useSelector(R.selectors.bookingWizard.getShipmentPartyReference);
  const shipmentPartyReferenceError = useSelector(R.selectors.bookingWizard.getShipmentPartyReferenceError);

  return (
    <ShippingPartyReferenceInputComponent
      value={shipmentPartyReference}
      isError={shipmentPartyReferenceError}
      onChange={UC.bookingWizard.onChangeShippingPartyReference}
    />
  );
};

export { ShippingPartyReferenceInputContainer as ReferenceInput };
