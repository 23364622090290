import styled from 'styled-components';

import { colors } from 'app-wrapper/view/themes/themesColors';

export const ReferenceCardItemContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0;

  > .ant-typography {
    > .ant-typography-copy {
      color: ${colors.gray61} !important;
    }
  }
`;

export const ItemsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
`;
