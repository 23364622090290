import React from 'react';
import { useSelector } from 'react-redux';

import { R } from 'user-management/repository';
import { UC } from 'user-management/controllers';

import { TradeReferencesComponent } from './TradeReferences.component';

const TradeReferencesContainer = () => {
  const isTradeReferenceCollapsed = useSelector(R.selectors.newOrganizationData.getIsTradeReferenceCollapsed);
  const tradeReference = useSelector(R.selectors.newOrganizationData.getTradeReference);
  const shouldValidate = useSelector(R.selectors.newOrganizationData.getShouldValidateTradeReference);

  return (
    <TradeReferencesComponent
      setIsTradeReferenceCollapsed={UC.newOrganizationData.setIsTradeReferenceCollapsed}
      isTradeReferenceCollapsed={isTradeReferenceCollapsed}
      shouldValidate={shouldValidate}
      tradeReference={tradeReference}
      setCompanyName={UC.newOrganizationData.setTradeCompanyName}
      setFirstName={UC.newOrganizationData.setTradeFirstName}
      setLastName={UC.newOrganizationData.setTradeLastName}
      setPhone={UC.newOrganizationData.setTradePhone}
      setEmail={UC.newOrganizationData.setTradeEmail}
    />
  );
};

export { TradeReferencesContainer as TradeReferences };
