import styled from 'styled-components';
import Typography from 'antd/es/typography';

export const MBLTextInfo = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const MBLTextInfoTitle = styled(Typography)`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
`;

export const MBLTextInfoText = styled(Typography)`
  margin-top: 8px;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
`;
