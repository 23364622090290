import React, { FC, HTMLAttributes } from 'react';

import { colors } from 'app-wrapper/view/themes/themesColors';

interface IIconProps extends HTMLAttributes<SVGElement> {
  color?: string
  height?: number | string
  width?: number | string
}

export const ContainerIcon: FC<IIconProps> = ({
  color = colors.gray43,
  width = 17,
  height = 13,
  ...other
}) => (
  <svg width={width} height={height} fill={color} viewBox="0 0 17 13" {...other}>
    <path d="M16.4999 1.61106V1.16661C16.4999 0.94439 16.2777 0.722168 16.0554 0.722168H1.38878C1.13878 0.722168 0.944336 0.94439 0.944336 1.16661V1.61106C0.944336 1.86106 1.13878 2.0555 1.38878 2.0555V10.9444C1.13878 10.9444 0.944336 11.1666 0.944336 11.3888V11.8333C0.944336 12.0833 1.13878 12.2777 1.38878 12.2777H16.0554C16.2777 12.2777 16.4999 12.0833 16.4999 11.8333V11.3888C16.4999 11.1666 16.2777 10.9444 16.0554 10.9444V2.0555C16.2777 2.0555 16.4999 1.86106 16.4999 1.61106ZM14.7221 10.9444H2.72211V2.0555H14.7221V10.9444ZM4.27767 10.0555H5.16656C5.27767 10.0555 5.38878 9.97217 5.38878 9.83328V3.16661C5.38878 3.0555 5.27767 2.94439 5.16656 2.94439H4.27767C4.13878 2.94439 4.05545 3.0555 4.05545 3.16661V9.83328C4.05545 9.97217 4.13878 10.0555 4.27767 10.0555ZM9.611 10.0555H10.4999C10.611 10.0555 10.7221 9.97217 10.7221 9.83328V3.16661C10.7221 3.0555 10.611 2.94439 10.4999 2.94439H9.611C9.47211 2.94439 9.38878 3.0555 9.38878 3.16661V9.83328C9.38878 9.97217 9.47211 10.0555 9.611 10.0555ZM12.2777 10.0555H13.1666C13.2777 10.0555 13.3888 9.97217 13.3888 9.83328V3.16661C13.3888 3.0555 13.2777 2.94439 13.1666 2.94439H12.2777C12.1388 2.94439 12.0554 3.0555 12.0554 3.16661V9.83328C12.0554 9.97217 12.1388 10.0555 12.2777 10.0555ZM6.94434 10.0555H7.83322C7.94434 10.0555 8.05545 9.97217 8.05545 9.83328V3.16661C8.05545 3.0555 7.94434 2.94439 7.83322 2.94439H6.94434C6.80545 2.94439 6.72211 3.0555 6.72211 3.16661V9.83328C6.72211 9.97217 6.80545 10.0555 6.94434 10.0555Z" fill={color} />
  </svg>
);
