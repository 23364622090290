import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  Column,
  Content,
  Title,
  Text,
  Image,
} from 'shipment-operations/view/pages/ShipmentBillOfLading/components/MBLFlowManagement/components/SubmittedContent/SubmittedContent.styled';
import folderSrc from 'shipment-operations/view/pages/ShipmentBillOfLading/components/MBLFlowManagement/components/SubmittedContent/folder.png';

export const DraftContent = () => {
  const { t } = useTranslation();

  return (
    <Content>
      <Image
        src={folderSrc}
        alt=""
      />

      <Column>
        <Title>
          {t('This is a draft version of your House BL')}
        </Title>

        <Text>
          {t('The final version will be available to view and print once MBL is cleared by the carrier.')}
        </Text>
      </Column>
    </Content>
  );
};
