import React, { FC, ReactNode } from 'react';

import { SOScaffoldWrapper } from './SOScaffold.styled';

interface ISOScaffoldComponentProps {
  transportMissMatches?: ReactNode;
  taskTable?: ReactNode;
  statisticsTable?: ReactNode;
  bookingMissMatches?: ReactNode;
  paymentsInfo?: ReactNode;
  trackerInfo?: ReactNode;
  bookingDetailsInfo?: ReactNode;
  lastActivityLogsInInfo?: ReactNode;
  referencesInfo?: ReactNode;
  shippingPartiesInfo?: ReactNode;
  skypaceTeamInfo?: ReactNode;
  containersInfo?: ReactNode;
  cargoInfo?: ReactNode;
  dashboard?: ReactNode;
}

const SOScaffoldComponent: FC<ISOScaffoldComponentProps> = ({
  transportMissMatches,
  taskTable,
  statisticsTable,
  bookingMissMatches,
  paymentsInfo,
  trackerInfo,
  bookingDetailsInfo,
  lastActivityLogsInInfo,
  referencesInfo,
  shippingPartiesInfo,
  skypaceTeamInfo,
  containersInfo,
  cargoInfo,
  dashboard,
}) => (
  <SOScaffoldWrapper>
    {statisticsTable}
    {taskTable}
    {trackerInfo}
    {transportMissMatches}
    {bookingMissMatches}
    {paymentsInfo}
    {dashboard}
    {bookingDetailsInfo}
    {lastActivityLogsInInfo}
    {referencesInfo}
    {skypaceTeamInfo}
    {shippingPartiesInfo}
    {containersInfo}
    {cargoInfo}
  </SOScaffoldWrapper>
);

export { SOScaffoldComponent as SOScaffold };
