import React, {
  FC, memo, useCallback,
} from 'react';
import { useTranslation } from 'react-i18next';

import {
  EFreightIncotermsTrade, FreightIncotermsByExportArray, FreightIncotermsByImportArray, freightLoadType,
} from 'monetary/constants';
import { IDefaultFieldErrors } from 'monetary/models/errors';

import {
  Col, Option, Row, TabPane, Tabs,
} from 'app-wrapper/view/components';
import {
  ContainerIcon, RoadShipSvg,
} from 'app-wrapper/view/icons';
import themesColors from 'app-wrapper/view/themes/themesColors';

import {
  RFQTabs, RFQTabsFreightMode, RFQTabsSelect,
} from './RFQTabs.styled';

interface IFreightRFQTabsComponentProps {
  loadType: string
  incoterms: string
  incotermsTrade: string
  isSubmitVisited?: boolean
  incotermsTradeError?: IDefaultFieldErrors
  isAllDisabled?: boolean
  onChangeFreightLoadType: (value: string) => void
  onChangeFreightIncoterms: (value: string) => void
  onChangeFreightIncotermsTrade: (value: string) => void
  onFocusFreightIncotermsTrade: () => void
  onBlurFreightIncotermsTrade: () => void
}

const FreightRFQTabsComponent: FC<IFreightRFQTabsComponentProps> = (({
  loadType,
  incoterms,
  incotermsTrade,
  isSubmitVisited,
  incotermsTradeError,
  isAllDisabled,
  onChangeFreightLoadType,
  onChangeFreightIncoterms,
  onChangeFreightIncotermsTrade,
  onFocusFreightIncotermsTrade,
  onBlurFreightIncotermsTrade,
}) => {
  const { t } = useTranslation();

  const getHasErrorField = useCallback(
    (filed?: IDefaultFieldErrors) => !!((filed?.isVisited && filed?.message) || (filed?.message && isSubmitVisited)),
    [isSubmitVisited],
  );

  return (
    <RFQTabs className="FreightQuoteFormRequest__Row__col_RFQTabs">
      <Row className="FreightQuoteFormRequest__Row" justify="center">
        <Col span={24}>
          <Tabs
            className="FreightQuoteFormRequest__Row__Tabs"
            moreIcon={<></>}
          >
            <TabPane
              key="Sea"
              tab={(
                <span className="FreightQuoteFormRequest__Row__Tabs__item">
                  <RoadShipSvg />
                  {t('Sea')}
                </span>
              )}
            />
            {/* Currently hidden */}
            {/* <TabPane
              key="Road"
              tab={(
                <span className="FreightQuoteFormRequest__Row__Tabs__item">
                  <RoadSvg />
                  {t('Road')}
                </span>
              )}
              disabled
            />
            <TabPane
              key="Air"
              tab={(
                <span className="FreightQuoteFormRequest__Row__Tabs__item">
                  <RoadFlySvg />
                  {t('Air')}
                </span>
              )}
              disabled
            /> */}
          </Tabs>
        </Col>
      </Row>

      <RFQTabsFreightMode>
        <RFQTabsSelect
          value={loadType || null}
          disabled
          onChange={onChangeFreightLoadType}
          optionFilterProp="children"
          prefixIcon={<ContainerIcon color={themesColors.secondaryDot45} />}
          cssWidth="35%"
        >
          <Option
            value={freightLoadType.FCL}
            key="RFQTabsSelect_FCL"
          >
            {t('FCL')}
          </Option>
          <Option
            value={freightLoadType.LCL}
            key="RFQTabsSelect_LCL"
          >
            {t('LCL')}
          </Option>
          <Option
            value={freightLoadType.Breakbulk}
            key="RFQTabsSelect_Breakbulk"
          >
            {t('Breakbulk')}
          </Option>
        </RFQTabsSelect>

        <RFQTabsSelect
          value={incotermsTrade || null}
          isLeft
          cssMarginLeft="4px"
          onChange={onChangeFreightIncotermsTrade}
          hasError={getHasErrorField(incotermsTradeError)}
          onFocus={onFocusFreightIncotermsTrade}
          onBlur={onBlurFreightIncotermsTrade}
          optionFilterProp="children"
          placeholder={`${t('ImportOrExport')} *`}
          cssWidth="38%"
          disabled={isAllDisabled}
        >
          <Option
            value={EFreightIncotermsTrade.IMPORT}
            key="RFQTabsSelect_IMPORT"
          >
            {t('Import')}
          </Option>
          <Option
            value={EFreightIncotermsTrade.EXPORT}
            key="RFQTabsSelect_EXPORT"
          >
            {t('Export')}
          </Option>
        </RFQTabsSelect>

        <RFQTabsSelect
          value={incoterms || null}
          disabled={!incotermsTrade || isAllDisabled}
          isRight
          onChange={onChangeFreightIncoterms}
          optionFilterProp="children"
          cssWidth="26%"
        >
          {!!(incotermsTrade === EFreightIncotermsTrade.IMPORT) && FreightIncotermsByImportArray.map((item) => (
            <Option
              value={item}
              key={`RFQTabsSelect_${item}`}
            >
              {item}
            </Option>
          ))}
          {!!(incotermsTrade === EFreightIncotermsTrade.EXPORT) && FreightIncotermsByExportArray.map((item) => (
            <Option
              value={item}
              key={`RFQTabsSelect_${item}`}
            >
              {item}
            </Option>
          ))}
        </RFQTabsSelect>
      </RFQTabsFreightMode>
    </RFQTabs>
  );
});

const FreightRFQTabsComponentCache = memo(FreightRFQTabsComponent);

export { FreightRFQTabsComponentCache as RFQTabsComponent };
