import styled from 'styled-components';

import { divFlex } from 'app-wrapper/view/themes/mixin';
import { CustomScrollbar } from 'app-wrapper/view/styles/mixins';

export const CCSpanEllipsis = styled.span.attrs({
  'data-class': 'CCSpanEllipsis',
})`
  max-width: 100%;
  position: relative;
  display: inline;

  text-overflow: ellipsis;
  overflow: clip;
  white-space: nowrap;
`;

export const TextLink = styled(CCSpanEllipsis)`
  color: rgba(74, 85, 173, 1);
  cursor: pointer;
`;

export const CCContentTableNotification = styled.div.attrs({
  'data-class': 'CCContentTableNotification',
})`
  ${divFlex}
  flex-direction: column;

  margin-top: 13px;
  padding: 16px;
  background-color: ${({ theme }) => theme.themesColors.neutralBranding1};
`;

export const CCContentTableNotificationHeader = styled.div.attrs({
  'data-class': 'CCContentTableNotificationHeader',
})`
  ${divFlex}

  height: 28px;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.themesColors.neutralBranding3};
  padding-bottom: 10px;
`;

export const CCContentTableNotificationHeaderItemBtn = styled.div.attrs({
  'data-class': 'CCContentTableNotificationHeaderItemBtn',
})`
  ${divFlex}
  margin-left: 16px;

  svg {
    margin-right: 5px;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }

  &:hover, &:focus-within, &:active {
    svg{
      color: ${({ theme }) => theme.themesColors.primaryBranding5};
    }
  }

  span {
    font-size: 12px;
  }
`;

export const CCContentTableNotificationHeaderItem = styled.div.attrs((props: {
  isCenter?: boolean,
  width?: string,
  paddingLeft?: string,
}) => ({
  isCenter: props.isCenter,
  width: props.width,
  paddingLeft: props.paddingLeft,
  'data-class': 'CCContentTableNotificationHeaderItem',
}))`
  ${divFlex}
  align-items: center;
  justify-content: ${({ isCenter }) => (isCenter ? 'center' : 'left')};

  width: ${({ width }) => (width || 'auto')};
  padding-left: ${({ paddingLeft }) => (paddingLeft || '0')};

  color: ${({ theme }) => theme.themesColors.secondaryDot45};
  font-size: 12px;
  font-weight: 400;
`;

export const CCContentTableNotificationContent = styled.div.attrs({
  'data-class': 'CCContentTableNotificationContent',
})`
  ${divFlex}
  ${CustomScrollbar}
  flex-direction: column;
`;

export const CCContentTableNotificationContentItem = styled.div.attrs({
  'data-class': 'CCContentTableNotificationContentItem',
})`
  ${divFlex}
  flex-direction: column;
  width: 100%;

  border-bottom: 1px solid ${({ theme }) => theme.themesColors.neutralBranding3}
`;

export const CCContentTableNotificationContentItemTop = styled.div.attrs({
  'data-class': 'CCContentTableNotificationContentItemTop',
})`
  ${divFlex}

  height: 55px;
`;

export const CCContentTableNotificationContentExpand = styled.div.attrs((props: {
  isActive?: boolean,
  isEmpty?: boolean,
}) => ({
  isActive: props.isActive,
  isEmpty: props.isEmpty,
  'data-class': 'CCContentTableNotificationContentExpand',
}))`
  ${divFlex}
  align-items: center;
  width: 8px;

  margin-left: 4px;
  cursor: ${({ isEmpty }) => (isEmpty ? 'pointer' : 'auto')};
  height: 100%;
`;

export const CCContentTableNotificationContentPriority = styled.div.attrs((props: {
  isActive?: boolean,
}) => ({
  isActive: props.isActive,
  'data-class': 'CCContentTableNotificationContentPriority',
}))`
  ${divFlex}
  align-items: center;
  justify-content: center;
  width: 16px;

  cursor: pointer;
  height: 100%;
  margin-left: 15px;
`;

export const CCContentTableNotificationContentPriorityViewed = styled.div.attrs((props: {
  isActive?: boolean,
  isWarning?: boolean,
}) => ({
  isActive: props.isActive,
  isWarning: props.isWarning,
  'data-class': 'CCContentTableNotificationContentPriorityViewed',
}))`
  ${divFlex}
  align-items: center;
  width: 7px;
  height: 7px;

  border-radius: 50%;
  border-width: 1px;

  background-color: ${({ theme, isActive }) => (isActive ? theme.themesColors.primaryBranding6 : theme.themesColors.neutralBranding6)};

  ${({ theme, isWarning }) => (isWarning ? `background-color: ${theme.themesColors.characterBrandingWarning}` : '')};
`;

export const CCContentTableNotificationContentPriorityBorder = styled.div.attrs((props: {
  isActive?: boolean,
  isWarning?: boolean,
}) => ({
  isActive: props.isActive,
  isWarning: props.isWarning,
  'data-class': 'CCContentTableNotificationContentPriorityBorder',
}))`
  ${divFlex}
  align-items: center;
  justify-content: center;
  width: 15px;
  height: 15px;

  border-radius: 50%;

  &:hover {
    border: 1px solid;
    border-color: ${({ theme, isActive }) => (isActive ? theme.themesColors.primaryBranding6Dot5 : theme.themesColors.neutralBranding6Dot5)};

    ${({ theme, isWarning }) => (isWarning ? `border-color: ${theme.themesColors.characterBrandingWarningDot5}` : '')};
  }
`;

export const CCContentTableNotificationContentTitle = styled.div.attrs((props: {
  isActive?: boolean,
}) => ({
  isActive: props.isActive,
  'data-class': 'CCContentTableNotificationContentTitle',
}))`
  ${divFlex}
  width: calc(57% - 12px - 31px - 10px);
  align-items: center;
  justify-content: left;

  margin-left: 10px;
  color: ${({ theme, isActive }) => (isActive ? theme.themesColors.primaryBranding6 : theme.themesColors.characterBrandingTitle85)};
  font-size: 14px;
  font-weight: 500;
`;

export const CCContentTableNotificationContentReceivedDate = styled.div.attrs({
  'data-class': 'CCContentTableNotificationContentReceivedDate',
})`
  ${divFlex}
  width: 14.3%;
  align-items: center;
  justify-content: left;

  color: ${({ theme }) => theme.themesColors.neutralBranding10};
  font-size: 14px;
  font-weight: 400;
`;

export const CCContentTableNotificationContentLink = styled.div.attrs({
  'data-class': 'CCContentTableNotificationContentLink',
})`
  ${divFlex}
  width: 14.3%;
  align-items: center;
  justify-content: left;

  color: ${({ theme }) => theme.themesColors.neutralBranding10};
  font-size: 14px;
  font-weight: 400;

  text-overflow: ellipsis;
  overflow: clip;
  white-space: nowrap;

  > span:first-letter {
    text-transform: uppercase;
  }
`;

export const CCContentTableNotificationContentModule = styled.div.attrs({
  'data-class': 'CCContentTableNotificationContentModule',
})`
  ${divFlex}
  width: 14%;
  align-items: center;
  justify-content: left;

  color: ${({ theme }) => theme.themesColors.characterBrandingTitle85};
  font-size: 14px;
  font-weight: 400;

  text-transform: capitalize;
`;

export const CCContentTableNotificationContentItemBottom = styled.div.attrs((props: {
  isActive?: boolean,
}) => ({
  isActive: props.isActive,
  'data-class': 'CCContentTableNotificationContentItemBottom',
}))`
  ${divFlex}
  flex-direction: column;
  ${({ isActive }) => isActive && 'margin-top: -11px;'};

  .ant-collapse-header {
    display: none !important;
  }
  .ant-collapse-content-box {
    padding: 0;
  }
  .ant-collapse.ant-collapse-icon-position-left,
  .ant-collapse-content.ant-collapse-content-active,
  .ant-collapse-item.ant-collapse-item-active,
  .ant-collapse-item {
    border: none !important;
    background: none;
  }
`;

export const CCContentTableNotificationContentItemBottomDescription = styled.div.attrs({
  'data-class': 'CCContentTableNotificationContentItemBottomDescription',
})`
  display: block;
  width: calc(41% - 12px - 31px - 10px);

  color: ${({ theme }) => theme.themesColors.secondaryDot45};
  font-size: 14px;
  font-weight: 400;
  margin-left: 53px;
  margin-bottom: 12px;
  padding-right: 8px;
`;
