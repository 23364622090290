import { Type } from 'class-transformer';
import {
  IsBoolean,
  IsDefined,
  IsEnum,
  IsNumber,
  IsOptional, IsString, ValidateNested,
} from 'class-validator';
import { BaseDTM } from 'proto/BaseDTM';

import { DetailRoutingContainerStepDTM, IDetailRoutingContainerStepDTM } from './detailRouting.dtm';
import { EFreightPaymentTerms } from '../Freight/freightPaymentTerms.dtm';

export interface IDetailBreakdownStepsDTM {
  summary: number
  steps: IDetailRoutingContainerStepDTM[]
}

export class DetailBreakdownStepsDTM extends BaseDTM<IDetailBreakdownStepsDTM> {
  @IsDefined()
  @IsNumber()
  summary: number;

  @IsDefined()
  @ValidateNested({ each: true })
  @Type(() => DetailRoutingContainerStepDTM)
  steps: DetailRoutingContainerStepDTM[];
}

export interface IDetailBreakdownContainerDTM {
  name: string
  steps: IDetailRoutingContainerStepDTM[]
  summary: number
  cost: number
  totalUnits: number
  containerId?: number
}

export class DetailBreakdownContainerDTM extends BaseDTM<IDetailBreakdownContainerDTM> {
  @IsDefined()
  @IsString()
  name: string;

  @IsDefined()
  @ValidateNested({ each: true })
  @Type(() => DetailRoutingContainerStepDTM)
  steps: DetailRoutingContainerStepDTM[];

  @IsDefined()
  @IsNumber()
  summary: number;

  @IsDefined()
  @IsNumber()
  cost: number;

  @IsDefined()
  @IsNumber()
  totalUnits: number;

  @IsOptional()
  @IsNumber()
  containerId?: number;
}

export interface IDetailBreakdownTransportationDTM {
  summary: number
  containers?: IDetailBreakdownContainerDTM[]
}

export class DetailBreakdownTransportationDTM extends BaseDTM<IDetailBreakdownTransportationDTM> {
  @IsDefined()
  @IsNumber()
  summary: number;

  @IsOptional()
  @ValidateNested({ each: true })
  @Type(() => DetailBreakdownContainerDTM)
  containers?: DetailBreakdownContainerDTM[];
}

export interface IDetailBreakdownServicesDTM {
  summary: number
  containers?: IDetailBreakdownContainerDTM[]
  other?: IDetailBreakdownStepsDTM
}

export class DetailBreakdownServicesDTM extends BaseDTM<IDetailBreakdownServicesDTM> {
  @IsDefined()
  @IsNumber()
  summary: number;

  @IsOptional()
  @ValidateNested({ each: true })
  @Type(() => DetailBreakdownContainerDTM)
  containers?: DetailBreakdownContainerDTM[];

  @IsOptional()
  @ValidateNested()
  @Type(() => DetailBreakdownStepsDTM)
  other?: DetailBreakdownStepsDTM;
}

export interface IDetailBreakdownDTM {
  fieldName: string
  summary: number
  isCollect?: boolean
  paymentTerms: EFreightPaymentTerms | string
  transportation?: IDetailBreakdownTransportationDTM
  fees?: IDetailBreakdownStepsDTM
  service?: IDetailBreakdownServicesDTM
  isLast?: boolean
}

export class DetailBreakdownDTM extends BaseDTM<IDetailBreakdownDTM> {
  @IsDefined()
  @IsString()
  fieldName: string;

  @IsDefined()
  @IsNumber()
  summary: number;

  @IsOptional()
  @IsBoolean()
  isCollect?: boolean;

  @IsDefined()
  @IsEnum(EFreightPaymentTerms)
  paymentTerms: EFreightPaymentTerms;

  @IsOptional()
  @ValidateNested()
  @Type(() => DetailBreakdownTransportationDTM)
  transportation?: DetailBreakdownTransportationDTM;

  @IsOptional()
  @ValidateNested()
  @Type(() => DetailBreakdownStepsDTM)
  fees?: DetailBreakdownStepsDTM;

  @IsOptional()
  @ValidateNested()
  @Type(() => DetailBreakdownServicesDTM)
  service?: DetailBreakdownServicesDTM;

  @IsOptional()
  @IsBoolean()
  isLast?: boolean;
}

// TODO: changed this logic
export interface IDetailBreakdownStepsContainersTypesDTM {
  [key: string]: number[]
}

// TODO: changed this logic
export interface IDetailBreakdownStepsContainersTypesByIdDTM {
  [key: string]: string
}
