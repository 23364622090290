import React, {
  useEffect, FC,
} from 'react';
import { useTranslation } from 'react-i18next';
import Tabs from 'antd/es/tabs';
import Skeleton from 'antd/es/skeleton';

import { RECEIVABLES, PAYABLES, PermissionAttributePolicy } from 'shipment-operations/constants';
import { DataTypeBillingDTM, ShipmentChargesPermissionsDtm } from 'shipment-operations/models/dtm';
import {
  Wrapper, SubPageHeader, SubPageHeaderContainer, SubPageHeaderTitle,
} from 'app-wrapper/view/guideline';

import { CustomTable } from './CustomTable';

interface ITotalData {
  totalSum: number
  totalBalance: number
  key: number
}

interface IShipmentBillingComponentProps {
  data: DataTypeBillingDTM[]
  isLoading: boolean
  error: boolean
  fetchData: (shipmentId?: string, type?: string) => void
  totalData: ITotalData[]
  resetData: () => void
  permissions: ShipmentChargesPermissionsDtm
}

const ShipmentBillingComponent: FC<IShipmentBillingComponentProps> = ({
  data, isLoading, error, fetchData, totalData, resetData, permissions,
}) => {
  const { t } = useTranslation();

  useEffect(() => () => resetData(), []);

  if (!permissions.shipmentChargesAvailability) {
    return (
      <Wrapper>
        <Skeleton />
      </Wrapper>
    );
  }

  return (
    <Wrapper data-class="ShipmentBillingComponentWrapper">
      <SubPageHeader>
        <SubPageHeaderContainer>
          <SubPageHeaderTitle>{t('shipmentBillingTitle')}</SubPageHeaderTitle>
        </SubPageHeaderContainer>
      </SubPageHeader>
      <Tabs defaultActiveKey="1" destroyInactiveTabPane>
        {permissions.shipmentChargesAvailability === PermissionAttributePolicy.WRITE ? (
          <Tabs.TabPane tab={t('Receivables')} key={RECEIVABLES}>
            <CustomTable
              data={data}
              fetchData={fetchData}
              totalData={totalData}
              isLoading={isLoading}
              type={RECEIVABLES}
              error={error}
            />
          </Tabs.TabPane>
        ) : null}
        <Tabs.TabPane tab={t('Payables')} key={PAYABLES}>
          <CustomTable
            data={data}
            fetchData={fetchData}
            totalData={totalData}
            isLoading={isLoading}
            type={PAYABLES}
            error={error}
          />
        </Tabs.TabPane>
      </Tabs>
    </Wrapper>
  );
};

export { ShipmentBillingComponent };
