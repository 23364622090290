export enum ENotificationCriticality {
  HIGH = 'HIGH',
  MEDIUM = 'MEDIUM',
  LOW = 'LOW',
}

export enum ENotificationStatus {
  TODO = 'TODO',
  DONE = 'DONE',
}

export enum ENotificationDomain {
  DOCUMENTATION = 'DOCUMENTATION',
  BOOKING = 'BOOKING',
  PLAN_TRACK = 'PLAN_TRACK',
  BILLING = 'BILLING',
  USER_MANAGEMENT = 'USER_MANAGEMENT',
  QUOTES_AND_RATES = 'QUOTES_AND_RATES',
}
