import React, {
  FC, memo,
} from 'react';
import { useTranslation } from 'react-i18next';

import { useWindowSize } from 'app-wrapper/hooks';

import {
  ICompanyContactDTM,
  ICompanyAddressDTM,
} from 'user-management/models/dtm';

import {
  SPAddAddressContentBodyForm,
  SPAddAddressContentBodyFormFooter,
  SPAddAddressContentBodyFormWrap,
  SPAddAddressContentBodyFormWrapper,
} from './ShipmentPartyAddAddress.styled';
import { AddressItem, ContactPersonItem } from './components';

interface IShipmentPartyAddAddressComponentProps {
  companyListAddresses?: ICompanyAddressDTM[]
  companyListContactPersons?: ICompanyContactDTM[]
  companyListStateIndex: string
  isUpdating: boolean
  updateForm: () => void
  onDiscard: () => void
}

const ShipmentPartyAddAddressComponent: FC<IShipmentPartyAddAddressComponentProps> = (props) => {
  const {
    companyListAddresses,
    companyListContactPersons,
    companyListStateIndex,
    isUpdating,
    updateForm,
    onDiscard,
  } = props;
  const { t } = useTranslation();
  const largeInput = useWindowSize().isFullMediaWidth;

  return (
    <SPAddAddressContentBodyFormWrap>
      <SPAddAddressContentBodyForm>
        <SPAddAddressContentBodyFormWrapper>

          {companyListAddresses?.map((addressItem, addressIndex) => (
            <AddressItem
              key={`ContactBookAddressItem-${addressItem.customId}`}
              largeInput={largeInput}
              isFirst
              isLast={false}
              addressIndex={addressIndex}
              companyListStateIndex={companyListStateIndex}
              address={addressItem}
            />
          ))}

          {companyListContactPersons?.map((personItem, personIndex) => (
            <ContactPersonItem
              key={`ContactBookContactPersonItem-${personItem.customId}`}
              largeInput={largeInput}
              isFirst
              isLast={false}
              contactIndex={personIndex}
              personItem={personItem}
              companyListStateIndex={companyListStateIndex}
            />
          ))}

        </SPAddAddressContentBodyFormWrapper>

      </SPAddAddressContentBodyForm>

      <SPAddAddressContentBodyFormFooter
        saveName={t('Add')}
        discardName={t('cancel')}
        discardChanges={onDiscard}
        updateForm={updateForm}
        disabledUpdate={isUpdating}
      />
    </SPAddAddressContentBodyFormWrap>
  );
};

const ShipmentPartyAddAddressComponentCache = memo(ShipmentPartyAddAddressComponent);

export { ShipmentPartyAddAddressComponentCache as ShipmentPartyAddAddressComponent };
