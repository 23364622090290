import React, { FC, memo } from 'react';
import { useSelector } from 'react-redux';

import { R } from 'monetary/repository';
import { UC } from 'monetary/controllers';

import { FreightQuoteContentModalSaveComponent } from './FreightQuoteContentModalSave.component';

const FreightQuoteContentModalSaveContainer: FC = () => {
  const {
    isLoadingRequestName, savedRequestName,
  } = useSelector(R.selectors.freightQuote.getFreightQuoteCurrent);

  return (
    <FreightQuoteContentModalSaveComponent
      savedRequestName={savedRequestName}
      isLoadingRequestName={isLoadingRequestName}
      onChangeSavedName={UC.FreightQuote.onChangeRequestSavedName}
      onSaveRequestSavedName={UC.FreightQuote.onSaveRequestSavedName}
      onCloseRequestSavedName={UC.FreightQuote.onCloseRequestSavedName}
    />
  );
};

const FreightQuoteContentModalSaveContainerCached = memo(FreightQuoteContentModalSaveContainer);

export { FreightQuoteContentModalSaveContainerCached as FreightQuoteContentModalSave };
