import React, { FC, useEffect } from 'react';
import { useParams, useLocation } from 'react-router';

import { useBlocker } from 'app-wrapper/hooks';

import {
  ShipmentDetailsLayout,
  ShipmentDetailsList,
  ShipmentDetailsForm,
  ContainersChangesWarning,
} from 'shipment-operations/view/components';
import { SkeletonSection } from 'app-wrapper/view/components/Skeleton/Skeleton.component';
import { PermissionAttributePolicy, ShipmentBookingStatusEnum } from 'shipment-operations/constants';
import { ShipmentPreviewDTM } from 'shipment-operations/models/dtm';
import { hasAccess, hasReadAccess } from 'app-wrapper/utils';
import {
  CargoTitle,
  CargoList,
  CargoForm,
} from './components';

interface IShipmentCargoComponentProps {
  isLoading: boolean
  isInDraft: boolean
  fetchCargos: (shipmentId: number) => void
  reset: () => void
  isShowWarning?: boolean;
  toggleChangesDrawer: () => void;
  toggleMaerskDrawer: () => void;
  shipment?: ShipmentPreviewDTM;
  isMaersk: boolean
  partnerWarning?: boolean
  permissions?: PermissionAttributePolicy
}

export const ShipmentCargoComponent: FC<IShipmentCargoComponentProps> = ({
  isLoading,
  isInDraft,
  fetchCargos,
  reset,
  isShowWarning,
  toggleMaerskDrawer,
  toggleChangesDrawer,
  shipment,
  isMaersk,
  partnerWarning,
  permissions,
}) => {
  const location = useLocation();
  const { shipmentId = '0' } = useParams();

  useEffect(() => {
    fetchCargos(+shipmentId);
    return reset;
  }, [location]);

  useBlocker(
    reset,
    () => {},
    isInDraft,
  );

  const isCustomer = hasAccess(permissions, PermissionAttributePolicy.READ);

  const handleOpen = () => {
    if (isMaersk && !isCustomer && (shipment?.bookingStatus === ShipmentBookingStatusEnum.BOOKING_SUBMITTED)) {
      toggleMaerskDrawer();
    } else {
      toggleChangesDrawer();
    }
  };

  if (isLoading) {
    return (
      <ShipmentDetailsLayout
        title={<CargoTitle />}
      >
        <ShipmentDetailsList>
          <SkeletonSection height="102px" />
        </ShipmentDetailsList>

        <ShipmentDetailsForm isLoading />
      </ShipmentDetailsLayout>
    );
  }

  return (
    <>
      <ShipmentDetailsLayout
        title={<CargoTitle />}
        warningBlock={isShowWarning ? (
          <ContainersChangesWarning
            status={shipment?.bookingStatus}
            handleDrawerOpen={handleOpen}
            isRequestTheSame={shipment?.isRequestTheSameWithShipment}
            isCustomer={hasReadAccess(permissions)}
            isMaersk={isMaersk}
            partnerWarning={partnerWarning}
          />
        ) : null}
      >
        <CargoList />
        <CargoForm />
      </ShipmentDetailsLayout>
    </>
  );
};
