import styled from 'styled-components';
import {
  ExpandTable,
} from 'shipment-operations/view/pages/ShipmentTransportationCharges/components/CustomTable/CustomTable.styled';

export const TableBody = styled(ExpandTable)`
  .expandContainersRow {
    position: relative;
    top: -1px;
  }
`;
