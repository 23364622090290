import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { MailOutlined, PhoneOutlined } from '@ant-design/icons';

import { TeamMemberDTM } from 'user-management/models/dtm';
import { DEPARTMENT_TYPE_NAMES } from 'user-management/constants';
import { MissingTextValue } from 'app-wrapper/view/guideline';

import {
  Member,
  MemberMainInfo,
  NameLetter,
  Info,
  InfoFullName,
  InfoJobTitle,
  Item,
  ItemText,
  ItemIconWrap,
  RoleTitle,
} from './TeamMemberCard.styled';

interface ITeamMemberCardProps {
  teamMember: TeamMemberDTM;
}

export const TeamMemberCard: FC<ITeamMemberCardProps> = ({ teamMember }) => {
  const { t } = useTranslation();
  const { member, role } = teamMember;

  if (!member) {
    return null;
  }

  return (
    <Member>
      <Item>
        <RoleTitle>
          {DEPARTMENT_TYPE_NAMES[role]}
        </RoleTitle>
      </Item>

      <MemberMainInfo>
        <NameLetter>
          {member.getFirstLetterOfName()}
        </NameLetter>

        <Info>
          <InfoFullName>
            {member.getFullName()}
          </InfoFullName>

          <InfoJobTitle>
            {member.jobTitle ? (
              <>
                {member.jobTitle ? `${t('Job Title: ')} ${member.jobTitle}` : ''}
                <br />
              </>
            ) : null}
          </InfoJobTitle>
        </Info>
      </MemberMainInfo>

      <Item>
        <ItemIconWrap>
          <MailOutlined />
        </ItemIconWrap>

        <ItemText>
          {member.email}
        </ItemText>
      </Item>

      <Item>
        <ItemIconWrap>
          <PhoneOutlined />
        </ItemIconWrap>

        {member.phone ? (
          <ItemText>
            {member.phone}
          </ItemText>
        ) : <MissingTextValue />}
      </Item>
    </Member>
  );
};
