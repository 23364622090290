import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { R as userManagementR } from 'user-management/repository';
import { R } from 'shipment-operations/repository';
import { EOrganizationMemberRole } from 'user-management/constants';
import { UC } from 'shipment-operations/controllers';

import { ClearedContentComponent } from './ClearedContent.component';

export const ClearedContent = React.memo(() => {
  const organization = useSelector(userManagementR.selectors.userOrganizationData.getUserOrganization);
  const isOriginPartner = useSelector(R.selectors.shipment.getIsOriginPartner);
  const isDestinationPartner = useSelector(R.selectors.shipment.getIsDestinationPartner);
  const siDetails = useSelector(R.selectors.billOfLadingCommon.getSIDetails);

  const isCustomerOrg = useMemo(() => !!(organization && organization.role === EOrganizationMemberRole.CUSTOMER), [organization]);

  return (
    <ClearedContentComponent
      isCustomer={isCustomerOrg}
      isOriginPartner={isOriginPartner}
      isDestinationPartner={isDestinationPartner}
      downloadFinalMBL={UC.billOfLading.downloadFinalDocument}
      openUploadFileDrawer={UC.shipmentDrawer.openSIFinalDocumentUploadDrawer}
      siDetails={siDetails}
    />
  );
});
