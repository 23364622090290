import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import {
  SBLCarrierInformationComponent,
  SBLTransportInformationComponent,
  SBLReferencesInformationComponent,
} from 'shipment-operations/view/pages/ShipmentBillOfLading/components/ShipmentInstructionsContent/components';
import {
  SBLGoodsTableComponent,
  SBLHazmatTableComponent,
  SBLContainersTableComponent,
} from 'shipment-operations/view/pages/ShipmentBillOfLading/components/ShipmentInstructionsContent/components/SBLCargoTables/components';
import {
  CargoDTM,
  ContainerWithCargoDTM,
  MBLShippingPartyDTM,
  MBLViewChargeDTM, TemperatureControlDTM,
} from 'shipment-operations/models/dtm';

import { MBLScaffold } from '../MBLScaffold';
import {
  MBLCompanyInfo,
  MBLShippingPartiesComponent,
  MBLRowInformationComponent,
  MBLTextInfoComponent,
  MBLChargesTableComponent,
  MBLClausesComponent,
} from './components';

interface MBLContentComponentProps {
  freightPaymentLocation: string;
  contactDetails: string[];
  carrierSCAC: string;
  carrierInfoItems: Array<{
    title: string;
    subtitle: string;
  }>;
  transportInfoItems: Array<{
    title: string;
    subtitle: string;
  }>;
  consignee?: MBLShippingPartyDTM;
  shipper?: MBLShippingPartyDTM;
  notifyParty?: MBLShippingPartyDTM;
  billOfLadingItems: Array<{
    title: string;
    subtitle: string;
  }>;
  routingItems: Array<{
    title: string;
    subtitle: string;
  }>;
  routingInstructions: string;
  referencesItems: Array<{
    title: string;
    subtitle: string;
  }>;
  cargos: CargoDTM[];
  hazmatCargos: CargoDTM[];
  containers: ContainerWithCargoDTM[];
  chargesItems: MBLViewChargeDTM[];
  blReleaseItems: Array<{
    title: string;
    subtitle: string;
  }>;
  shippedOnBoardItems: Array<{
    title: string;
    subtitle: string;
  }>;
  additionalNotes: string;
  termsAndConditions: string;
  clauses: string[];
  temperatureControl: TemperatureControlDTM | null;
  isChargesSectionVisible: boolean;
}

export const MBLContentComponent: FC<MBLContentComponentProps> = ({
  freightPaymentLocation,
  contactDetails,
  carrierSCAC,
  carrierInfoItems,
  transportInfoItems,
  consignee,
  shipper,
  notifyParty,
  billOfLadingItems,
  routingItems,
  routingInstructions,
  referencesItems,
  cargos,
  containers,
  hazmatCargos,
  chargesItems,
  blReleaseItems,
  shippedOnBoardItems,
  additionalNotes,
  termsAndConditions,
  clauses,
  temperatureControl,
  isChargesSectionVisible,
}) => {
  const { t } = useTranslation();

  return (
    <MBLScaffold
      companyBody={(
        <MBLCompanyInfo
          carrierSCAC={carrierSCAC}
          freightPaymentLocation={freightPaymentLocation}
          contactDetails={contactDetails}
        />
      )}
      carrierBody={(
        <SBLCarrierInformationComponent
          items={carrierInfoItems}
        />
      )}
      transportBody={(
        <SBLTransportInformationComponent
          items={transportInfoItems}
        />
      )}
      shippingPartiesBody={(
        <MBLShippingPartiesComponent
          consignee={consignee}
          notifyParty={notifyParty}
          shipper={shipper}
        />
      )}
      billOfLadingInstructionsBody={(
        <MBLRowInformationComponent
          title={t('Bill of Lading Instructions')}
          items={billOfLadingItems}
        />
      )}
      routingBody={(
        <MBLRowInformationComponent
          title={t('Routing')}
          items={routingItems}
        />
      )}
      routingInstructionsBody={routingInstructions ? (
        <MBLTextInfoComponent
          title={t('Routing Instructions')}
          text={routingInstructions}
        />
      ) : null}
      referencesBody={(
        <SBLReferencesInformationComponent
          mblPagePlacement
          items={referencesItems}
        />
      )}
      goodsTableBody={(
        <SBLGoodsTableComponent
          hideNoDataAddButton
          isNested
          cargos={cargos}
          titleStyles={{
            fontSize: '16px',
          }}
        />
      )}
      hazmatTableBody={hazmatCargos.length ? (
        <SBLHazmatTableComponent
          cargos={hazmatCargos}
        />
      ) : undefined}
      containersTableBody={(
        <SBLContainersTableComponent
          hideTitle
          containers={containers}
          temperatureControl={temperatureControl || TemperatureControlDTM.fromPlain({ genset: false })}
          isAbleToEdit
        />
      )}
      chargesBody={chargesItems.length && isChargesSectionVisible ? (
        <MBLChargesTableComponent chargesItems={chargesItems} />
      ) : null}
      billOfLadingReleaseBody={(
        <MBLRowInformationComponent
          title={t('Bill of Lading Release')}
          items={blReleaseItems}
        />
      )}
      shippedOnBoardBody={(
        <MBLRowInformationComponent
          title={t('Shipped on Board')}
          items={shippedOnBoardItems}
        />
      )}
      clausesBody={(
        <MBLClausesComponent clauses={clauses} />
      )}
      additionalNotesBody={(
        <MBLTextInfoComponent
          title={t('Additional Notes')}
          text={additionalNotes}
        />
      )}
      termsAndConditionsBody={termsAndConditions ? (
        <MBLTextInfoComponent
          title={t('Terms & Conditions')}
          text={termsAndConditions}
        />
      ) : null}
    />
  );
};
