import message from 'antd/es/message';
import i18n from 'i18next';
import notification from 'antd/es/notification';

import { BaseController, controller } from 'proto/BaseController';
import { R } from 'user-management/repository';
import {
  OrganizationDTM,
  OrganizationMemberDTM,
  CustomerDocumentsMap,
  EOrganizationStatus,
  OrganizationReviewDTM,
  OrganizationTradeReferenceDTM,
  OrganizationPaymentMethodDTM,
} from 'user-management/models/dtm';
import { DocumentType } from 'shipment-operations/constants';
import { ContainerDocumentDTM, DocumentDTM } from 'shipment-operations/models/dtm';
import { UC as shipmentOperationsUC } from 'shipment-operations/controllers';
import { R as AuthR } from 'authentication/repository';
import { EOrganizationMemberRole, EReviewAction, EReviewReason } from 'user-management/constants';
import { validationEmail, validationPhone } from 'app-wrapper/utils';
import { NetworkErrorProto } from 'app-wrapper/models/errors';

@controller
export class CustomerController extends BaseController {
  public initData = async (organizationId: string) => {
    this.dispatch(R.actions.customer.setLoading(true));

    await this.fetchOrganizationDocuments(organizationId);

    let organizationMember: OrganizationMemberDTM | undefined;
    let currentOrganization: OrganizationDTM | null = null;

    try {
      currentOrganization = await R.services.organization.getCurrentOrganization(true);
    } catch (e) {
      console.error('CustomerController ERROR: getCurrentOrganization');
    }

    const userEmail = AuthR.selectors.auth.getEmail(this.store.getState());

    if (currentOrganization) {
      this.dispatch(R.actions.customer.setOrganizationId(currentOrganization.id));

      try {
        organizationMember = await R.services.organization.getOrganizationMember(currentOrganization.id, userEmail);
      } catch (e) {
        console.error('CustomerController ERROR: getCurrentOrganization');
      }
    }

    if (organizationMember) {
      this.dispatch(R.actions.customer.setIsOrganizationAdmin(organizationMember.role === EOrganizationMemberRole.admin));
    }

    this.dispatch(R.actions.customer.setLoading(false));
  };

  public initDataForCurrent = async () => {
    this.dispatch(R.actions.customer.setLoading(true));

    let organizationMember: OrganizationMemberDTM | undefined;
    let currentOrganization: OrganizationDTM | null = null;

    try {
      currentOrganization = await R.services.organization.getCurrentOrganization(true);
    } catch (e) {
      console.error('CustomerController ERROR: getCurrentOrganization');
    }

    const userEmail = AuthR.selectors.auth.getEmail(this.store.getState());

    if (currentOrganization) {
      this.dispatch(R.actions.userOrganizationData.setUserOrganization(currentOrganization));
      this.dispatch(R.actions.customer.setOrganizationId(currentOrganization.id));

      await this.fetchOrganizationDocuments(currentOrganization.id);

      try {
        organizationMember = await R.services.organization.getOrganizationMember(currentOrganization.id, userEmail);
      } catch (e) {
        console.error('CustomerController ERROR: getCurrentOrganization');
      }
    }

    if (organizationMember) {
      this.dispatch(R.actions.customer.setIsOrganizationAdmin(organizationMember.role === EOrganizationMemberRole.admin));
    }

    this.dispatch(R.actions.customer.setLoading(false));
  };

  public fetchOrganizationDocuments = async (organizationId: string | number) => {
    let organization: OrganizationDTM | null = null;

    try {
      organization = await R.services.organization.getOrganizationById(organizationId, true);
    } catch (e) {
      console.error('CustomerController ERROR: getOrganization');
    }

    this.dispatch(R.actions.customer.setOrganization(organization));

    if (organization?.documents) {
      this.dispatch(R.actions.customer.setDocuments(organization?.documents));
      this.dispatch(R.actions.customer.setDocumentsTypeMap(this.formatDocumentsTypesMap(organization?.documents)));
    }
  };

  public setDocumentToUpload = (document: ContainerDocumentDTM | null) => {
    this.dispatch(R.actions.customer.setDocumentToUpload(document));
  };

  public downloadDocumentToUpload = (organizationId: string | number) => {
    const document = R.selectors.customer.getDocumentToUpload(this.store.getState());

    if (document) {
      this.downloadDocument(organizationId, document?.response.id, document?.response.name);
    }
  };

  public downloadDocumentById = async (organizationId: string | number, documentId: number) => {
    const document = R.selectors.customer.getDocumentById(documentId)(this.store.getState());

    if (document) {
      try {
        R.services.organizationDocuments.downloadOrganizationDocument(organizationId, document.id, document.name);
      } catch (e) {
        console.error('CustomerController CONTROLLER: downloadDocument');
      }
    }
  }

  public downloadDocument = async (organizationId: string | number, documentId: number, documentName: string) => {
    try {
      R.services.organizationDocuments.downloadOrganizationDocument(organizationId, documentId, documentName);
    } catch (e) {
      console.error('CustomerController CONTROLLER: downloadDocument');
    }
  }

  public deleteDocumentWithUpdate = async (organizationId: string | number, documentId: number) => {
    await this.deleteDocument(organizationId, documentId);
    await this.fetchOrganizationDocuments(String(organizationId));
  }

  public deleteDocument = async (organizationId: string | number, documentId: number) => {
    try {
      await R.services.organizationDocuments.deleteDocument(organizationId, documentId);
    } catch (e) {
      console.error('CustomerController error: deleteDocument');
    }
  }

  public cancelDocumentUpload = async (organizationId: string | number) => {
    await this.deleteDocumentToUpload(organizationId);

    shipmentOperationsUC.shipmentDrawer.closeDrawer();
  }

  public deleteDocumentToUpload = async (organizationId: string | number) => {
    const documentToUpload = R.selectors.customer.getDocumentToUpload(this.store.getState());

    this.dispatch(R.actions.customer.setIsUploadLoading(true));

    if (documentToUpload && documentToUpload.response && documentToUpload.response.id) {
      await this.deleteDocument(organizationId, documentToUpload.response.id);
    }

    this.dispatch(R.actions.customer.setIsUploadLoading(false));
  };

  public saveUploadedDocument = async (organizationId: string | number) => {
    this.dispatch(R.actions.customer.setIsUploadLoading(true));

    await this.fetchOrganizationDocuments(organizationId);

    this.dispatch(R.actions.customer.setIsUploadLoading(false));
    shipmentOperationsUC.shipmentDrawer.closeDrawer();
  }

  public openRejectModal = () => {
    this.dispatch(R.actions.customer.setRejectReason(''));
    this.dispatch(R.actions.customer.setRejectionNote(''));
    this.dispatch(R.actions.customer.setIsRejectModalOpened(true));
  };

  public setRejectionNote = (note: string) => {
    this.dispatch(R.actions.customer.setRejectionNote(note));
  }

  public handleReject = async () => {
    this.dispatch(this.dispatch(R.actions.customer.setLoading(true)));
    const oldOrganization: OrganizationDTM | null = R.selectors.customer.getOrganization(this.store.getState());

    let organization: OrganizationDTM | null = R.selectors.customer.getOrganization(this.store.getState());

    if (!organization) {
      return;
    }

    const rejectReason = R.selectors.customer.getRejectReason(this.store.getState());
    const rejectNote = R.selectors.customer.getRejectionNote(this.store.getState());

    try {
      organization = await R.services.organization.postOrganizationReview(organization.id, OrganizationReviewDTM.fromPlain({
        action: EReviewAction.REJECT,
        note: rejectNote,
        reason: rejectReason as EReviewReason,
      }));
    } catch (e) {
      console.error('CustomerController CONTROLLER: handleReject');

      this.dispatch(this.dispatch(R.actions.customer.setLoading(false)));
      this.dispatch(R.actions.customer.setIsRejectModalOpened(false));
      return;
    }

    if (organization && oldOrganization) {
      this.dispatch(R.actions.customer.setOrganization({
        ...oldOrganization,
        id: organization.id,
        status: EOrganizationStatus.REJECTED,
      }));
      this.dispatch(R.actions.userOrganizationData.setCustomerInternalOrganization({
        ...oldOrganization,
        id: organization.id,
        status: EOrganizationStatus.REJECTED,
      }));

      message.success(i18n.t('Organization has been rejected'));
    }

    this.dispatch(R.actions.customer.setLoading(false));
    this.dispatch(R.actions.customer.setIsRejectModalOpened(false));
  }

  public setRejectReason = (reason: string) => {
    this.dispatch(R.actions.customer.setRejectReason(reason || ''));
  };

  public closeRejectModal = () => {
    this.dispatch(R.actions.customer.setIsRejectModalOpened(false));
  };

  public closeAdditionalInformationModal = () => {
    this.dispatch(R.actions.customer.setIsAdditionalModalOpened(false));
  };

  public openAdditionalInformationModal = () => {
    this.dispatch(R.actions.customer.setAdditionalInfoNote(''));
    this.dispatch(R.actions.customer.setIsAdditionalModalOpened(true));
  };

  public setAdditionalInfoNote = (note: string) => {
    this.dispatch(R.actions.customer.setAdditionalInfoNote(note));
  };

  public requestAdditionalInformation = async () => {
    let organization: OrganizationDTM | null = R.selectors.customer.getOrganization(this.store.getState());

    if (!organization) {
      return;
    }

    this.dispatch(R.actions.customer.setIsModalPending(true));

    const note = R.selectors.customer.getAdditionalInfoNote(this.store.getState());

    try {
      organization = await R.services.organization.postOrganizationReview(organization.id, OrganizationReviewDTM.fromPlain({
        note,
        action: EReviewAction.REQUEST_ADDITIONAL_INFO,
      }));
    } catch (e) {
      console.error('Customer Controller ERROR: requestAdditionalInformation');
    }

    this.dispatch(R.actions.customer.setOrganization(organization));
    this.dispatch(R.actions.customer.setIsAdditionalModalOpened(false));
    this.dispatch(R.actions.customer.setIsModalPending(false));
  }

  public setTradeReference = (tradeReference: OrganizationTradeReferenceDTM) => {
    this.dispatch(R.actions.customer.setTradeReference(tradeReference));
  }

  public setTradeCompanyName = (name: string) => {
    const tradeReference = R.selectors.customer.getTradeReference(this.store.getState());

    this.dispatch(R.actions.customer.setTradeReference(OrganizationTradeReferenceDTM.fromPlain({
      ...tradeReference,
      companyName: name,
    })));
    this.dispatch(R.actions.customer.setWasTradeReferenceOnceSubmitted(false));
  };

  public setPaymentTypeForm = (value: string) => {
    const setPaymentMethodForm = R.selectors.customer.getPaymentMethodForm(this.store.getState());

    this.dispatch(R.actions.customer.setPaymentMethodForm(OrganizationPaymentMethodDTM.fromPlain({
      ...OrganizationPaymentMethodDTM.createEmpty(),
      ...setPaymentMethodForm,
      type: value,
    })));
    this.dispatch(R.actions.customer.setWasPaymentMethodOnceSubmitted(false));
  };

  public setTermForm = (value: number) => {
    const setPaymentMethodForm = R.selectors.customer.getPaymentMethodForm(this.store.getState());

    this.dispatch(R.actions.customer.setPaymentMethodForm(OrganizationPaymentMethodDTM.fromPlain({
      ...OrganizationPaymentMethodDTM.createEmpty(),
      ...setPaymentMethodForm,
      creditTerm: value,
    })));
    this.dispatch(R.actions.customer.setWasPaymentMethodOnceSubmitted(false));
  };

  public setCreditLimitForm = (value: number) => {
    const setPaymentMethodForm = R.selectors.customer.getPaymentMethodForm(this.store.getState());
    const validValue = `${value}`.replace(/[(\D)-]/g, '').slice(0, 22);

    this.dispatch(R.actions.customer.setPaymentMethodForm(OrganizationPaymentMethodDTM.fromPlain({
      ...OrganizationPaymentMethodDTM.createEmpty(),
      ...setPaymentMethodForm,
      creditLimit: Number(Number(validValue).toFixed(2)),
    })));
    this.dispatch(R.actions.customer.setWasPaymentMethodOnceSubmitted(false));
  };

  public setTradeFirstName = (name: string) => {
    const tradeReference = R.selectors.customer.getTradeReference(this.store.getState());

    this.dispatch(R.actions.customer.setTradeReference(OrganizationTradeReferenceDTM.fromPlain({
      ...tradeReference,
      firstName: name,
    })));
    this.dispatch(R.actions.customer.setWasTradeReferenceOnceSubmitted(false));
  };

  public setTradeLastName = (name: string) => {
    const tradeReference = R.selectors.customer.getTradeReference(this.store.getState());

    this.dispatch(R.actions.customer.setTradeReference(OrganizationTradeReferenceDTM.fromPlain({
      ...tradeReference,
      lastName: name,
    })));
    this.dispatch(R.actions.customer.setWasTradeReferenceOnceSubmitted(false));
  };

  public setTradeEmail = (email: string) => {
    const tradeReference = R.selectors.customer.getTradeReference(this.store.getState());

    this.dispatch(R.actions.customer.setTradeReference(OrganizationTradeReferenceDTM.fromPlain({
      ...tradeReference,
      email,
    })));
    this.dispatch(R.actions.customer.setWasTradeReferenceOnceSubmitted(false));
  };

  public setTradePhone = (phone: string) => {
    const tradeReference = R.selectors.customer.getTradeReference(this.store.getState());

    this.dispatch(R.actions.customer.setTradeReference(OrganizationTradeReferenceDTM.fromPlain({
      ...tradeReference,
      phone,
    })));
    this.dispatch(R.actions.customer.setWasTradeReferenceOnceSubmitted(false));
  };

  public checkIfTradeReferenceValidationPassed = () => {
    this.dispatch(R.actions.customer.setWasTradeReferenceOnceSubmitted(true));

    const shouldValidate = R.selectors.customer.getShouldValidateTradeReference(this.store.getState());
    const tradeReference = R.selectors.customer.getTradeReference(this.store.getState());

    if (!shouldValidate) {
      return true;
    }

    const {
      companyName,
      firstName,
      lastName,
      email,
      phone,
    } = tradeReference;
    const emailError = email.length ? validationEmail(email).errorMessage : '';
    const phoneError = phone.length ? validationPhone(phone).errorMessage : '';

    return !!(companyName.length && firstName.length && lastName.length && (email.length || phone.length) && !emailError.length && !phoneError.length);
  }

  public checkIfPaymentMethodValidationPassed = () => {
    this.dispatch(R.actions.customer.setWasPaymentMethodOnceSubmitted(true));

    const paymentMethod = R.selectors.customer.getPaymentMethodForm(this.store.getState());

    if (!paymentMethod) {
      return true;
    }

    const creditLimit = paymentMethod?.creditLimit || 0;
    const creditTerm = paymentMethod?.creditTerm || 0;
    const type = paymentMethod?.type || '';

    if (type === 'Deferred Payment') {
      return !!(type.length && creditTerm && creditLimit);
    }

    return !!(type.length);
  }

  public saveTradeReferences = async () => {
    if (!this.checkIfTradeReferenceValidationPassed()) {
      return;
    }

    let organization: OrganizationDTM | null = R.selectors.customer.getOrganization(this.store.getState());
    const tradeReference = R.selectors.customer.getTradeReference(this.store.getState());
    const isTradeReferenceEmpty = R.selectors.customer.getIsTradeReferenceEmpty(this.store.getState());

    if (!organization) {
      return;
    }

    organization = {
      ...organization,
      tradeReference: isTradeReferenceEmpty ? undefined : tradeReference,
    };

    try {
      await R.services.organization.putCurrentOrganization(organization);
    } catch (e) {
      const errorMessage = e as string || 'Unknown error';

      notification.error({
        message: i18n.t('RFQRequestErrors.COMMON_ERROR_TITLE'),
        description: errorMessage || '',
        placement: 'bottomLeft',
        bottom: 60,
        duration: null,
      });

      throw Error(errorMessage);
    }

    this.dispatch(R.actions.customer.setOrganization(organization));
    shipmentOperationsUC.shipmentDrawer.closeDrawer();
  };

  public savePaymentMethod = async () => {
    if (!this.checkIfPaymentMethodValidationPassed()) {
      return;
    }

    let organization: OrganizationDTM | null = R.selectors.customer.getOrganization(this.store.getState());
    const paymentMethod = R.selectors.customer.getPaymentMethodFormReq(this.store.getState());
    const isPaymentMethodEmpty = OrganizationPaymentMethodDTM.createEmpty();

    if (!organization) {
      return;
    }

    organization = {
      ...organization,
      paymentMethod: paymentMethod || isPaymentMethodEmpty,
    };

    await R.services.organization.putCurrentOrganization(organization);

    message.success(i18n.t('Organization is Updated'));

    this.dispatch(R.actions.customer.setOrganization(organization));
    shipmentOperationsUC.shipmentDrawer.closeDrawer();
  };

  public approveOrganization = async () => {
    const oldOrganization: OrganizationDTM | null = R.selectors.customer.getOrganization(this.store.getState());
    let organization: OrganizationDTM | null = R.selectors.customer.getOrganization(this.store.getState());

    if (!organization) {
      return;
    }

    this.dispatch(R.actions.customer.setLoading(true));

    try {
      organization = await R.services.organization.postOrganizationReview(organization.id, OrganizationReviewDTM.fromPlain({
        action: EReviewAction.APPROVE,
      }));
    } catch (e) {
      if (e instanceof NetworkErrorProto) {
        console.error('CustomerController CONTROLLER: approveOrganization - ', e.getErrorMessage());

        message.error(e.getErrorMessage());
      }

      this.dispatch(R.actions.customer.setLoading(false));

      return;
    }

    if (organization && oldOrganization) {
      this.dispatch(R.actions.customer.setOrganization(OrganizationDTM.fromPlain({
        ...oldOrganization,
        id: organization.id,
        status: EOrganizationStatus.ACTIVE,
      })));
      this.dispatch(R.actions.userOrganizationData.setCustomerInternalOrganization({
        ...oldOrganization,
        id: organization.id,
        status: EOrganizationStatus.ACTIVE,
      }));

      message.success(i18n.t('Organization has been activated'));
    }

    this.dispatch(R.actions.customer.setLoading(false));
  }

  private formatDocumentsTypesMap = (documents: DocumentDTM[]) => {
    const documentsTypesMap: CustomerDocumentsMap = {
      [DocumentType.ALO]: documents.filter((document) => document.type === DocumentType.ALO),
      [DocumentType.CCI]: documents.filter((document) => document.type === DocumentType.CCI),
      [DocumentType.BLIC]: documents.filter((document) => document.type === DocumentType.BLIC),
      [DocumentType.TCER]: documents.filter((document) => document.type === DocumentType.TCER),
      [DocumentType.MISC]: documents.filter((document) => document.type === DocumentType.MISC),
    };

    return documentsTypesMap;
  };
}
