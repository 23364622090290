import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { R } from 'monetary/repository';
import { UC } from 'monetary/controllers';

import { SurchargesRateDownloadModalComponent } from './SurchargesRateDownloadModal.component';

const SurchargesRateDownloadModalContainer: FC = () => {
  const isLoading = useSelector(R.selectors.surchargesRatesModal.getLoading);
  const isFormDataFilled = useSelector(R.selectors.surchargesRatesModal.isFormDataFilled);
  const isOriginLocationSearchListLoading = useSelector(R.selectors.surchargesRatesModal.getOriginLocationSearchListLoading);
  const isDestinationLocationSearchListLoading = useSelector(R.selectors.surchargesRatesModal.getDestinationLocationSearchListLoading);
  const isOpen = useSelector(R.selectors.surchargesRatesModal.getOpenState);
  const date = useSelector(R.selectors.surchargesRatesModal.getDate);
  const originLocation = useSelector(R.selectors.surchargesRatesModal.getOriginLocation);
  const destinationLocation = useSelector(R.selectors.surchargesRatesModal.getDestinationLocation);
  const originLocationSearchList = useSelector(R.selectors.surchargesRatesModal.getOriginLocationSearchList);
  const destinationLocationSearchList = useSelector(R.selectors.surchargesRatesModal.getDestinationLocationSearchList);
  const carrierSCACList = useSelector(R.selectors.surchargesRatesModal.getCarrierScacList);

  return (
    <SurchargesRateDownloadModalComponent
      isLoading={isLoading}
      isOpen={isOpen}
      isFormDataFilled={isFormDataFilled}
      carrierSCACList={carrierSCACList}
      isOriginLocationSearchListLoading={isOriginLocationSearchListLoading}
      originLocationSearchList={originLocationSearchList}
      isDestinationLocationSearchListLoading={isDestinationLocationSearchListLoading}
      destinationLocationSearchList={destinationLocationSearchList}
      date={date}
      originLocationValue={originLocation}
      destinationLocationValue={destinationLocation}
      onDateChange={UC.surchargesRatesModal.setDate}
      onDateBlur={UC.surchargesRatesModal.blurDateField}
      onOriginLocationChange={UC.surchargesRatesModal.setOriginLocation}
      onOriginLocationBlur={UC.surchargesRatesModal.blurOriginLocationField}
      onOriginLocationSearch={UC.surchargesRatesModal.searchOriginLocation}
      onDestinationLocationChange={UC.surchargesRatesModal.setDestinationLocation}
      onDestinationLocationBlur={UC.surchargesRatesModal.blurDestinationLocationField}
      onDestinationLocationSearch={UC.surchargesRatesModal.searchDestinationLocation}
      onCarrierSCACChange={UC.surchargesRatesModal.setCarrierSCACList}
      onDownload={UC.surchargesRatesModal.downloadRates}
      onClose={UC.surchargesRatesModal.closeModal}
    />
  );
};

export { SurchargesRateDownloadModalContainer as SurchargesRateDownloadModal };
