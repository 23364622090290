import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';

import { ReferencesCardComponent } from './ReferencesCard.component';

const ReferencesCardContainer: FC = () => {
  const isLoading = useSelector(R.selectors.transportationOverview.getLoading);
  const transporationOverview = useSelector(R.selectors.transportationOverview.getTransportationOverview);
  const permissions = useSelector(R.selectors.shipment.getTransportationPermissions);

  return (
    <ReferencesCardComponent
      isLoading={isLoading}
      mblNumber={transporationOverview.mblNumber}
      inttraReferenceNumber={transporationOverview.inttraReferenceNumber}
      contractNumber={transporationOverview.contractNumber}
      agentsReference={transporationOverview.agentsReference}
      inttraReferenceNumberAvailability={permissions.inttraReferenceNumberAvailability}
      contractNumberAvailability={permissions.contractNumberAvailability}
    />
  );
};

export {
  ReferencesCardContainer as ReferencesCard,
};
