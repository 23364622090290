import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { AdditionalServiceDTM } from 'shipment-operations/models/dtm';
import { ChargeCodeDesignation } from 'shipment-operations/constants';

import { Service } from './components';
import { List, PhaseTitle } from './ServicesList.styled';

interface ServicesListComponentProps {
  services: AdditionalServiceDTM[];
}

export const ServicesListComponent: FC<ServicesListComponentProps> = ({ services }) => {
  const { t } = useTranslation();

  const originServices = services.filter(({ phase }) => phase === ChargeCodeDesignation.ORIGIN);
  const freightServices = services.filter(({ phase }) => phase === ChargeCodeDesignation.FREIGHT);
  const destinationServices = services.filter(({ phase }) => phase === ChargeCodeDesignation.DESTINATION);

  return (
    <List>
      {originServices.length ? (
        <>
          <PhaseTitle>
            {t('Origin')}
          </PhaseTitle>

          {originServices.map((service) => (
            <Service
              key={service.id}
              service={service}
            />
          ))}
        </>
      ) : null}

      {freightServices.length ? (
        <>
          <PhaseTitle>
            {t('Freight')}
          </PhaseTitle>

          {freightServices.map((service) => (
            <Service
              key={service.id}
              service={service}
            />
          ))}
        </>
      ) : null}

      {destinationServices.length ? (
        <>
          <PhaseTitle>
            {t('Destination')}
          </PhaseTitle>

          {destinationServices.map((service) => (
            <Service
              key={service.id}
              service={service}
            />
          ))}
        </>
      ) : null}
    </List>
  );
};
