import EnvironmentOutlined from '@ant-design/icons/EnvironmentOutlined';
import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { OrganizationDTM } from 'user-management/models/dtm';
import { NoDataLineItem } from 'user-management/view/components';

import {
  CIAddressData,
  CIAddressHeader,
  CIAddressIconBG,
  CIAddressItem,
  CIAddressRow,
  CIAddressTitle,
  CIAddressValue,
  CIAddressWrapper,
} from './CIAddress.styled';

interface ICIAddressComponentProps {
  organization?: OrganizationDTM
}

export const CIAddressComponent: FC<ICIAddressComponentProps> = ({
  organization,
}) => {
  const { t } = useTranslation();

  const addressValue = useMemo(() => organization && OrganizationDTM.fromPlain(organization)?.getAddressLine(), [organization]);

  const addressValue2 = null;

  return (
    <CIAddressWrapper>
      <CIAddressHeader>{t('Address')}</CIAddressHeader>
      <CIAddressRow>
        <CIAddressItem>
          <CIAddressIconBG>
            <EnvironmentOutlined />
          </CIAddressIconBG>
          <CIAddressData>
            <CIAddressTitle>{t('Address #1')}</CIAddressTitle>
            <CIAddressValue>{addressValue}</CIAddressValue>
          </CIAddressData>
        </CIAddressItem>
        {/* temporarily hidden */}
        {false && (
          <CIAddressItem>
            <CIAddressIconBG>
              <EnvironmentOutlined />
            </CIAddressIconBG>
            <CIAddressData>
              <CIAddressTitle>{t('Address #2')}</CIAddressTitle>
              <CIAddressValue>
                <CIAddressValue>{addressValue2 || <NoDataLineItem />}</CIAddressValue>
              </CIAddressValue>
            </CIAddressData>
          </CIAddressItem>
        )}
      </CIAddressRow>
    </CIAddressWrapper>
  );
};
