import {
  IsArray,
  IsOptional,
  IsString,
} from 'class-validator';
import { BaseDTM } from 'proto/BaseDTM';

export interface ICommandCenterGetCommandCenterGetNotificationsDTM {
  query?: string
  criticality?: string[]
  viewed?: string
  dueDateTo?: string
  dueDateFrom?: string
  createdAtTo?: string
  createdAtFrom?: string
  sort?: string
  page?: string
  size?: string
  shipmentId?: string
  domain?: string
  assigneeUserEmails?: string[]
  assigneeOrganizationIds?: number[]
  assigneeAudienceOrganizationIds?: number[]
}

export class CommandCenterGetCommandCenterGetNotificationsDTM extends BaseDTM<ICommandCenterGetCommandCenterGetNotificationsDTM> {
  @IsOptional()
  @IsString()
  query?: string;

  @IsOptional()
  @IsString({ each: true })
  criticality?: string[];

  @IsOptional()
  @IsString()
  viewed?: string;

  @IsOptional()
  @IsString()
  dueDateTo?: string;

  @IsOptional()
  @IsString()
  dueDateFrom?: string;

  @IsOptional()
  @IsString()
  createdAtTo?: string;

  @IsOptional()
  @IsString()
  createdAtFrom?: string;

  @IsOptional()
  @IsString()
  sort?: string;

  @IsOptional()
  @IsString()
  page?: string;

  @IsOptional()
  @IsString()
  size?: string;

  @IsOptional()
  @IsString()
  shipmentId?: string;

  @IsOptional()
  @IsString()
  domain?: string;

  @IsOptional()
  @IsString({ each: true })
  assigneeUserEmails?: string[];

  @IsOptional()
  @IsArray()
  assigneeOrganizationIds?: number[];

  @IsOptional()
  @IsArray()
  assigneeAudienceOrganizationIds?: number[];
}
