import React, {
  ChangeEvent,
  FC,
  useCallback,
} from 'react';
import { useTranslation } from 'react-i18next';

import { ValidationErrorDTM } from 'app-wrapper/types';

import { InputStyled } from './WeightInput.styled';

interface WeightInputComponentProps {
  cargoId: number;
  weight: string;
  touchCargoField: (field: string, cargoId: number) => void
  setWeight: (description: string, cargoId: number) => void
  error?: ValidationErrorDTM;
}

export const WeightInputComponent: FC<WeightInputComponentProps> = ({
  cargoId,
  weight,
  touchCargoField,
  setWeight,
  error,
}) => {
  const { t } = useTranslation();

  const handleChangeWeight = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setWeight(event.target.value, cargoId);
  }, [setWeight, cargoId]);

  const handleBlur = useCallback(() => {
    touchCargoField('weight', cargoId);
  }, [touchCargoField, cargoId]);

  return (
    <InputStyled
      placeholder={t('Enter Weight')}
      value={weight}
      onChange={handleChangeWeight}
      onBlur={handleBlur}
      error={!!error}
    />
  );
};
