import React, {
  useMemo, FC, useCallback, useState, Fragment,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import groupBy from 'lodash/fp/groupBy';
import filter from 'lodash/fp/filter';
import isEmpty from 'lodash/fp/isEmpty';
import uniqBy from 'lodash/fp/uniqBy';
import uniq from 'lodash/fp/uniq';
import Modal from 'antd/es/modal';

import { normalizationCost } from 'app-wrapper/utils';

import { generateUnitType } from 'monetary/repository/store/FreightQuote/FreightQuotePrint.selectors';
import { ChargeDTM, ShipmentChargesPermissionsDtm } from 'shipment-operations/models/dtm';

import { StyledSection } from 'shipment-operations/view/components/CustomTable/CustomTable.styled';
import { PermissionAttributePolicy } from 'shipment-operations/constants';
import {
  TableName, TableWrapper, CustomTableHeader, CustomStyledTable,
} from './ShipmentTransportationAccessorials.styled';
import { useTable } from './useTable';

interface CustomTableProps {
  data: ChargeDTM[]
  openEdit: (id: number, host: string) => void
  setDeletedCharge: (id: number | null) => void
  onDelete: (id?: string) => void
  isLoadingDelete: boolean
  permissions: ShipmentChargesPermissionsDtm
}

const filterCharges = (charges: ChargeDTM[]) => {
  const unique = uniqBy((item) => item.chargeCode.description, charges);
  const activeFalse = filter((item) => !item.active, charges).map((item) => item.chargeCode.code);
  return unique.filter((item) => !activeFalse.includes(item.chargeCode.code));
};

const CustomTable: FC<CustomTableProps> = ({
  data, openEdit, setDeletedCharge, onDelete, isLoadingDelete, permissions,
}) => {
  const { t } = useTranslation();
  const { shipmentId } = useParams<'shipmentId'>();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const uniqueContainers = useMemo(() => {
    const byContainer = filter((item) => item.priceBy === 'CONTAINER', data);
    const containers = byContainer.map((item) => item.container.type);
    return uniq(containers);
  }, [data]);

  const { columns } = useTable(permissions, openEdit, setDeletedCharge, setIsModalOpen, uniqueContainers);

  const groupedByType = useMemo(() => {
    const byContainer = filter((item) => item.priceBy === 'CONTAINER', data);
    return groupBy((item) => item.container?.type, byContainer);
  }, [data]);

  const byBol = useMemo(() => data.filter((item) => item.priceBy === 'BOL'), [data]);

  const closeOnRemove = useCallback(() => {
    setDeletedCharge(null);
    setIsModalOpen(false);
  }, []);

  const handleDelete = useCallback(() => onDelete(shipmentId), []);

  const renderTable = useCallback((charges: ChargeDTM[], containerName: string) => {
    const originData = () => {
      const filtered = filter((item) => item.designation === 'ORIGIN', charges);
      return filterCharges(filtered);
    };
    const freightData = () => {
      const filtered = filter((item) => item.designation === 'FREIGHT', charges);
      return filterCharges(filtered);
    };
    const destinationData = () => {
      const filtered = filter((item) => item.designation === 'DESTINATION', charges);
      return filterCharges(filtered);
    };

    const prepareData = (items: ChargeDTM[]) => items.map((item) => ({
      service: item.chargeCode.description,
      unitType: generateUnitType(item?.priceBy || '', item?.measureBy || ''),
      apPerUnit: `$ ${normalizationCost(item.buyCostPerUnit, { thousandthSeparatorComma: true, toFixed: 2 })}`,
      arPerUnit: `$ ${normalizationCost(item.totalCost, { thousandthSeparatorComma: true, toFixed: 2 })}`,
      id: item.id,
      key: item.id,
    }));

    const tableOriginData = prepareData(originData());
    const tableFreightData = prepareData(freightData());
    const tableDestinationData = prepareData(destinationData());

    return (!isEmpty(tableOriginData) || !isEmpty(tableFreightData) || !isEmpty(tableDestinationData)) && (
      <Fragment key={containerName}>
        <TableName>{containerName}</TableName>
        <TableWrapper>
          <CustomTableHeader columns={columns} fullColumns={permissions.shipmentChargesAvailability === PermissionAttributePolicy.WRITE} />
          {!isEmpty(tableOriginData) && (
            <>
              <StyledSection>{t('Origin')}</StyledSection>
              <CustomStyledTable
                columns={columns}
                showHeader={false}
                dataSource={tableOriginData}
                pagination={false}
                fullColumns={permissions.shipmentChargesAvailability === PermissionAttributePolicy.WRITE}
              />
            </>
          )}
          {!isEmpty(tableFreightData) && (
          <>
            <StyledSection>{t('freight')}</StyledSection>
            <CustomStyledTable
              columns={columns}
              showHeader={false}
              dataSource={tableFreightData}
              pagination={false}
              fullColumns={permissions.shipmentChargesAvailability === PermissionAttributePolicy.WRITE}
            />
          </>
          )}
          {!isEmpty(tableDestinationData) && (
          <>
            <StyledSection>{t('destination')}</StyledSection>
            <CustomStyledTable
              columns={columns}
              showHeader={false}
              dataSource={tableDestinationData}
              pagination={false}
              fullColumns={permissions.shipmentChargesAvailability === PermissionAttributePolicy.WRITE}
            />
          </>
          )}
        </TableWrapper>
      </Fragment>
    );
  }, []);

  return (
    <>
      {uniqueContainers.length ? uniqueContainers.map((item) => renderTable(groupedByType[item], item)) : null}
      {byBol.length ? renderTable(byBol, t('PerBL')) : null}
      <Modal
        onCancel={closeOnRemove}
        visible={isModalOpen}
        okText={t('yes')}
        cancelText={t('no')}
        onOk={handleDelete}
        okButtonProps={{ loading: isLoadingDelete }}
      >
        <p>{t('Are you sure you want to delete this item')}</p>
      </Modal>
    </>
  );
};

export { CustomTable };
