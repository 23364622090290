import React, { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { HBLViewChargeDTM } from 'shipment-operations/models/dtm';
import { NoDataStub } from 'shipment-operations/view/components';
import { ChargeCodeDesignation } from 'shipment-operations/constants';

import {
  Table,
  SubTitle,
  TableCell,
  Title,
  Wrapper,
} from './SBLChargesTable.styled';

interface MBLChargesTableComponentProps {
  chargesItems: HBLViewChargeDTM[];
}

export const SBLChargesTableComponent: FC<MBLChargesTableComponentProps> = ({ chargesItems }) => {
  const { t } = useTranslation();

  const renderDefaultCell = useCallback((value) => (
    <TableCell>
      {value}
    </TableCell>
  ), []);

  const originCharges = useMemo(() => chargesItems.filter((charge) => charge.designation === ChargeCodeDesignation.ORIGIN), [chargesItems]);
  const freightCharges = useMemo(() => chargesItems.filter((charge) => charge.designation === ChargeCodeDesignation.FREIGHT), [chargesItems]);
  const destinationCharges = useMemo(() => chargesItems.filter((charge) => charge.designation === ChargeCodeDesignation.DESTINATION), [chargesItems]);

  const columns = useMemo(() => [
    {
      title: t('Charge'),
      width: '30%',
      key: 'charge',
      align: 'left' as 'left',
      render: (item: string, record: {} | HBLViewChargeDTM) => {
        const charge = record as HBLViewChargeDTM;

        return (
          <TableCell>
            {charge.name}
          </TableCell>
        );
      },
    },
    {
      title: t('Payment Term'),
      width: '13%',
      key: 'paymentTerm',
      dataIndex: 'paymentTerm',
      align: 'left' as 'left',
      render: renderDefaultCell,
    },
    {
      title: t('Currency'),
      width: '13%',
      key: 'currency',
      dataIndex: 'currency',
      align: 'left' as 'left',
      render: renderDefaultCell,
    },
    {
      title: t('Total'),
      width: '30%',
      dataIndex: 'price',
      key: 'price',
      align: 'right' as 'right',
      render: renderDefaultCell,
    },
  ], [t]);

  return (
    <Wrapper>
      <Title>
        {t('Charges')}
      </Title>

      {originCharges.length ? (
        <>
          <SubTitle>
            {t('Origin')}
          </SubTitle>

          <Table
            locale={{
              emptyText: <NoDataStub />,
            }}
            pagination={false}
            dataSource={originCharges}
            columns={columns}
          />
        </>
      ) : null}

      {freightCharges.length ? (
        <>
          <SubTitle>
            {t('Freight')}
          </SubTitle>

          <Table
            locale={{
              emptyText: <NoDataStub />,
            }}
            pagination={false}
            dataSource={freightCharges}
            columns={columns}
          />
        </>
      ) : null}

      {destinationCharges.length ? (
        <>
          <SubTitle>
            {t('Destination')}
          </SubTitle>

          <Table
            locale={{
              emptyText: <NoDataStub />,
            }}
            pagination={false}
            dataSource={destinationCharges}
            columns={columns}
          />
        </>
      ) : null}
    </Wrapper>
  );
};
