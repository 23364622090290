import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';
import { UC } from 'shipment-operations/controllers';

import { HazmatToggleComponent } from './HazmatToggle.component';

interface HazmatToggleContainerProps {
  cargoId: number;
}

const HazmatToggleContainer: FC<HazmatToggleContainerProps> = ({ cargoId }) => {
  const isHazmatToggled = useSelector(R.selectors.bookingWizard.getIsHazmatToggledByCargoId(cargoId));

  return (
    <HazmatToggleComponent
      cargoId={cargoId}
      isHazmatToggled={isHazmatToggled}
      closeHazmatSection={UC.bookingWizard.closeHazmatSectionByCargoId}
      openHazmatSection={UC.bookingWizard.openHazmatSectionByCargoId}
    />
  );
};

export { HazmatToggleContainer as HazmatToggle };
