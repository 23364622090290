import styled from 'styled-components';
import Typography from 'antd/es/typography';

export const ShippingFormFieldLabel = styled(Typography.Text)`
  display: block;
  margin-bottom: 4px;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
`;
