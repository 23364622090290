import styled from 'styled-components';
import Typography from 'antd/es/typography';

import { Input } from 'app-wrapper/view/components';

export const Wrapper = styled.div`
  margin-left: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Text = styled(Typography)`
  margin-right: 8px;
  font-size: 12px;
  line-height: 20px;
`;

export const InputStyled = styled(Input)`
  width: 304px;
  height: 32px;
  min-height: 32px;

  ::placeholder {
    color: #73819B;
  }
`;
