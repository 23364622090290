import React from 'react';

import { useSelector } from 'react-redux';
import { R } from 'app-wrapper/repository';
import { UC } from 'app-wrapper/controllers';
import { TermsAndConditionsDrawerComponent } from './TermsAndConditionsDrawer.component';

const TermsAndConditionsDrawerContainer = () => {
  const isOpen = useSelector(R.selectors.overview.getIsVisibleTermsAndConditionDrawer);

  return (
    <TermsAndConditionsDrawerComponent isOpen={isOpen} onClose={UC.overview.closeTermsOfConditionDrawer} />
  );
};

export { TermsAndConditionsDrawerContainer };
