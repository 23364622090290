import i18n from 'app-wrapper/i18n/i18n';

export enum EFreightPaymentTerms {
  PREPAID = 'PREPAID',
  COLLECT = 'COLLECT',
  // stub to display default state
  DEFAULT = 'DEFAULT',
}

export type TFreightPaymentTermsList = {
  [key in EFreightPaymentTerms]: string
}

export const getNameFreightPaymentTerms: TFreightPaymentTermsList = {
  [EFreightPaymentTerms.PREPAID]: i18n.t('Prepaid'),
  [EFreightPaymentTerms.COLLECT]: i18n.t('Collect'),
  [EFreightPaymentTerms.DEFAULT]: '',
};
