import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  StatusTag,
  FooterInformation,
  ClearedContent,
  DraftContent,
} from './components';
import {
  Section,
  Header,
  Title,
} from './HBLFlowManagement.styled';

interface IHBLFlowManagementComponentProps {
  hasFinalMBL: boolean;
  hasFinalMBLDocument: boolean;
}

export const HBLFlowManagementComponent: FC<IHBLFlowManagementComponentProps> = ({
  hasFinalMBLDocument,
  hasFinalMBL,
}) => {
  const { t } = useTranslation();

  const isClearedStatus = useMemo(() => hasFinalMBLDocument || hasFinalMBL, [hasFinalMBL, hasFinalMBLDocument]);

  return (
    <Section>
      <Header>
        <Title>
          {t('House Bill Of Lading')}
        </Title>

        <StatusTag isCleared={isClearedStatus} />
      </Header>

      {isClearedStatus ? (
        <ClearedContent />
      ) : (
        <DraftContent />
      )}

      {isClearedStatus ? (
        <FooterInformation
          isCleared={isClearedStatus}
        />
      ) : null}
    </Section>
  );
};
