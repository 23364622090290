import notification from 'antd/es/notification';
import i18n from 'app-wrapper/i18n/i18n';
import { CommonNetworkError } from 'app-wrapper/models/errors';

import isEmpty from 'lodash/fp/isEmpty';
import { Moment } from 'moment/moment';
import { BaseController, controller } from 'proto/BaseController';
import {
  DocumentType,
  PAYMENT_DATE,
  RECEIVABLES,
  RECEIVED_AMOUNT,
  REFERENCE,
} from 'shipment-operations/constants';
import {
  ContainerDocumentDTM, CreatePaymentBodyDTM,
  CreatePaymentResponseDTM, CreateTransactionBodyDTM,
  GetPaymentResponseDTM,
  PaymentDocumentDTM,
  ShipmentBillingInvoiceDtm,
} from 'shipment-operations/models/dtm';

import { R } from 'shipment-operations/repository';

@controller
export class MakePaymentController extends BaseController {
  cleanInputsErrors = (field: string) => {
    const errors = R.selectors.makePayment.getInputsErrors(this.store.getState());
    if (!isEmpty(errors)) {
      const updatedErrors = errors.filter((item) => item !== field);
      this.dispatch(R.actions.makePayment.onSetInputsErrors(updatedErrors));
    }
  }

  onChangeReceivedAmount = (sum: number) => {
    this.cleanInputsErrors(RECEIVED_AMOUNT);
    this.dispatch(R.actions.makePayment.onChangeReceivedAmount(sum));
  }

  onChangePaymentDate = (date: Moment | null) => {
    this.cleanInputsErrors(PAYMENT_DATE);
    this.dispatch(R.actions.makePayment.onChangePaymentDate(date?.toISOString()));
  }

  onChangeReference = (value: string) => {
    this.cleanInputsErrors(REFERENCE);
    this.dispatch(R.actions.makePayment.onChangeReference(value));
  }

  onCloseError = () => {
    this.dispatch(R.actions.makePayment.onSetCreatePaymentError(false));
  }

  onCloseSuccess = () => {
    this.dispatch(R.actions.makePayment.onSetCreatePaymentSuccess(false));
    this.dispatch(R.actions.makePayment.onSetCreatedPayment({}));
  }

  onRecord = async (shipmentId?: string, category?: string) => {
    if (!shipmentId || !category) {
      return false;
    }
    this.dispatch(R.actions.makePayment.onSetLoadingRecord(true));

    const paymentDate = R.selectors.makePayment.getPaymentDate(this.store.getState());
    const amount = R.selectors.makePayment.getReceivedAmount(this.store.getState());
    const reference = R.selectors.makePayment.getReference(this.store.getState());
    const invoice = R.selectors.shipmentBillingInvoice.getInvoice(this.store.getState());

    if (!invoice) {
      return false;
    }

    const fieldsGroup = [
      {
        key: PAYMENT_DATE,
        value: paymentDate,
      },
      {
        key: RECEIVED_AMOUNT,
        value: amount,
      },
    ];

    const errors: string[] = [];
    fieldsGroup.forEach((item) => !item.value && errors.push(item.key));
    if (!isEmpty(errors)) {
      this.dispatch(R.actions.makePayment.onSetInputsErrors(errors));
      return false;
    }

    const body = CreatePaymentBodyDTM.fromPlain({
      createdAt: paymentDate,
      reference: reference || null,
      type: 'PAYMENT',
      amount,
      documents: [],
    });

    let response: CreatePaymentResponseDTM;

    const accountId = category === RECEIVABLES ? invoice.accountId : invoice.accountIdFrom;

    try {
      response = await R.services.makePayment.onCreatePayment(accountId, category, body);
    } catch (e) {
      return this.dispatch(R.actions.makePayment.onSetCreatePaymentError(true));
    }

    return this.createTransaction(paymentDate, response.id, response.number, shipmentId, category);
  }

  public deleteDocument = (documentUID: string) => {
    const tempDocuments = [...R.selectors.makePayment.getTempDocuments(this.store.getState())];
    const documentsIdsToDelete = R.selectors.makePayment.getDocumentsToDeleteIds(this.store.getState());
    const documentIndex = tempDocuments.findIndex((item) => item.uid === documentUID);
    const documentId = tempDocuments[documentIndex]?.response?.id;
    tempDocuments.splice(documentIndex, 1);

    this.dispatch(R.actions.makePayment.setTempDocuments(tempDocuments));
    if (typeof documentId === 'number' && documentId >= 0) {
      this.dispatch(R.actions.makePayment.setDocumentIdsToDelete([...documentsIdsToDelete, documentId]));
    }
  }

  onEditPayment = async (shipmentId?: string | number, paymentId?: string, cb?: () => void, category?: string) => {
    if (!shipmentId || !category) {
      return false;
    }

    const paymentDate = R.selectors.makePayment.getEditedDate(this.store.getState());
    const amount = R.selectors.makePayment.getEditedTotalAmount(this.store.getState());
    const reference = R.selectors.makePayment.getEditedReference(this.store.getState());
    const tempDocuments = R.selectors.makePayment.getTempDocuments(this.store.getState());

    this.dispatch(R.actions.makePayment.setIsLoadingEditPayment(true));

    const body = CreatePaymentBodyDTM.fromPlain({
      createdAt: paymentDate,
      reference: reference || null,
      type: 'PAYMENT',
      amount,
      documents: tempDocuments.map((document) => PaymentDocumentDTM.fromPlain({
        id: document.response.id,
        name: document.response.name,
      })),
    });

    try {
      await R.services.makePayment.onEditPayment(category, paymentId, body);
    } catch (e) {
      if (e instanceof CommonNetworkError) {
        this.dispatch(R.actions.makePayment.setEditErrorType(e.message));
        this.dispatch(R.actions.makePayment.setIsErrorEditPayment(true));
      }
      return false;
    }

    const documentIdsToDelete = R.selectors.makePayment.getDocumentsToDeleteIds(this.store.getState());
    const accountId = R.selectors.makePayment.getAccountIdToAttachDocuments(this.store.getState());

    await Promise.all(documentIdsToDelete.map((documentId) => R.services.shipmentBillingDocuments.deleteDocument(accountId, documentId)));

    this.dispatch(R.actions.makePayment.setIsLoadingEditPayment(false));
    if (cb) {
      cb();
    }
    return true;
  }

  createTransaction = async (date: string, paymentId: number, paymentNumber: string, shipmentId?: string, category?: string) => {
    if (!shipmentId || !category) {
      return;
    }
    const invoice = R.selectors.shipmentBillingInvoice.getInvoice(this.store.getState());
    const amount = R.selectors.shipmentBillingInvoice.getUsedAmount(this.store.getState());

    if (!invoice?.id) {
      return;
    }

    const body = CreateTransactionBodyDTM.fromPlain({
      amount,
      createdAt: date,
      source: {
        type: 'PAYMENT',
        id: paymentId,
      },
    });

    try {
      await R.services.makePayment.onCreateTransaction(shipmentId, category, invoice.id, body);
    } catch (e) {
      this.dispatch(R.actions.makePayment.onSetCreatePaymentError(true));
      return;
    }

    notification.success({
      message: `${i18n.t('payment.createPaymentSuccessStart')} ${paymentNumber} ${i18n.t('payment.createPaymentSuccessEnd')}`,
      placement: 'topRight',
      duration: 5,
    });

    this.dispatch(R.actions.shipmentBillingInvoice.setIsOpenPayment(false));
    this.dispatch(R.actions.makePayment.clear());
    this.dispatch(R.actions.makePayment.onSetCreatePaymentSuccess(true));
    this.dispatch(R.actions.makePayment.onSetCreatedPayment({ id: paymentId, number: paymentNumber }));
  }

  editTransaction = async (shipmentId?: string | number, paymentId?: string, cb?: () => void, category?: string) => {
    if (!shipmentId || !paymentId || !category) {
      return false;
    }
    const invoice = R.selectors.shipmentBillingInvoice.getInvoice(this.store.getState());
    const transactions = R.selectors.makePayment.getEditedTransactions(this.store.getState());
    const paymentDate = R.selectors.makePayment.getEditedDate(this.store.getState());
    // now we have only one item in array of transaction, refactor in a loop

    if (!invoice?.id || !transactions || !paymentDate) {
      return false;
    }

    this.dispatch(R.actions.makePayment.setIsLoadingEditTransaction(true));

    const parsedPaymentId = parseInt(paymentId || '0', 10);

    const body = CreateTransactionBodyDTM.fromPlain({
      amount: transactions ? transactions[0].value : 0,
      createdAt: paymentDate,
      source: {
        type: 'PAYMENT',
        id: parsedPaymentId,
      },
    });

    try {
      await R.services.makePayment.onEditTransaction(shipmentId, category, transactions[0].id, invoice.id, body);
    } catch (e) {
      if (e instanceof CommonNetworkError) {
        this.dispatch(R.actions.makePayment.setIsErrorEditTransaction(true));
        this.dispatch(R.actions.makePayment.setEditErrorType(e.message));
      }
      return false;
    }

    this.dispatch(R.actions.makePayment.setIsLoadingEditTransaction(false));
    if (cb) {
      cb();
    }
    return true;
  }

  public checkIfDocumentsHasChanged = () => {
    const tempDocuments = R.selectors.makePayment.getTempDocuments(this.store.getState());
    const documents = R.selectors.makePayment.getPaymentDocuments(this.store.getState());
    let hasChanged = tempDocuments.length !== documents.length;

    documents.forEach((document, documentIndex) => {
      if (!tempDocuments[documentIndex] || tempDocuments[documentIndex].response.id !== document.id) {
        hasChanged = true;
      }
    });

    return hasChanged;
  }

  onEdit = async (paymentId?: string, category?: string) => {
    if (!paymentId || !category) {
      return;
    }
    const linkedInvoices = R.selectors.makePayment.getLinkedInvoices(this.store.getState());
    const shipmentId = linkedInvoices ? linkedInvoices[0].shipment?.id : '';
    if (!shipmentId) {
      return;
    }
    const isErrorEditFields = R.selectors.makePayment.getIsDisableEdit(this.store.getState());
    if (isErrorEditFields) {
      return;
    }
    const transactions = R.selectors.makePayment.getEditedTransactions(this.store.getState());
    const paymentView = R.selectors.makePayment.getDataForPaymentEditTable(this.store.getState());
    const editedPayment = R.selectors.makePayment.getEditedTotalAmount(this.store.getState());
    const originalPayment = R.selectors.makePayment.getPaymentViewAmount(this.store.getState());

    const originalReference = R.selectors.makePayment.getPaymentViewReference(this.store.getState());
    const editedReference = R.selectors.makePayment.getEditedReference(this.store.getState());

    const originalDate = R.selectors.makePayment.getPaymentViewPostedDate(this.store.getState());
    const editedDate = R.selectors.makePayment.getEditedDate(this.store.getState());

    const editedTransaction = transactions ? transactions[0].value : 0;
    const originalTransaction = paymentView[0].payment || 0;

    const documentsHasChanged = this.checkIfDocumentsHasChanged();

    const successCase = () => {
      this.dispatch(R.actions.makePayment.setShouldRefresh(true));
      this.closeEditMode();
    };

    if (editedPayment === originalPayment && editedTransaction === originalTransaction && originalReference === editedReference && originalDate === editedDate && !documentsHasChanged) {
      return;
    }

    if (editedPayment !== originalPayment && editedTransaction === originalTransaction) {
      await this.onEditPayment(shipmentId, paymentId, successCase, category);
      return;
    }

    if (editedPayment === originalPayment && editedTransaction !== originalTransaction) {
      await this.editTransaction(shipmentId, paymentId, successCase, category);
      return;
    }

    if (editedPayment !== originalPayment && editedTransaction > originalTransaction) {
      const updatedPayment = await this.onEditPayment(shipmentId, paymentId, () => {}, category);
      if (updatedPayment) {
        await this.editTransaction(shipmentId, paymentId, successCase, category);
      }
      return;
    }

    if (editedPayment > originalPayment && editedTransaction < originalTransaction) {
      const updatedPayment = await this.onEditPayment(shipmentId, paymentId, () => {}, category);
      if (updatedPayment) {
        await this.editTransaction(shipmentId, paymentId, successCase, category);
      }
      return;
    }

    if (editedPayment < originalPayment && editedTransaction < originalTransaction) {
      const updatedTransaction = await this.editTransaction(shipmentId, paymentId, () => {}, category);
      if (updatedTransaction) {
        await this.onEditPayment(shipmentId, paymentId, successCase, category);
      }
    }

    if (originalDate !== editedDate || originalReference !== editedReference) {
      await this.onEditPayment(shipmentId, paymentId, successCase, category);
    }

    if (documentsHasChanged) {
      await this.onEditPayment(shipmentId, paymentId, successCase, category);
    }
  }

  shouldRefreshPayment = (paymentId?: string, category?: string) => {
    const shouldRefresh = R.selectors.makePayment.getShouldRefresh(this.store.getState());
    if (shouldRefresh) {
      this.getPayment(paymentId, category);
    }
  }

  getPayment = async (paymentId?: string, category?: string) => {
    if (!paymentId || !category) {
      return;
    }

    this.dispatch(R.actions.makePayment.clearPaymentViewError(false));
    this.dispatch(R.actions.makePayment.onSetPaymentViewLoading(true));
    this.dispatch(R.actions.makePayment.setPaymentType(category));

    let payment: GetPaymentResponseDTM | null;

    try {
      payment = await R.services.makePayment.onGetPayment(category, paymentId);
    } catch (e) {
      this.dispatch(R.actions.makePayment.onSetLoadPaymentViewError());
      return;
    }

    this.dispatch(R.actions.makePayment.onSetPaymentView(payment));

    const linkedInvoices = payment.transactions.map((item) => item.destination.id);
    const shipmentsId = payment.transactions.map((item) => item.destination.shipment.id);

    let invoices: ShipmentBillingInvoiceDtm[] | null;

    try {
      invoices = await R.services.shipmentBillingInvoice.getInvoices(shipmentsId[0], linkedInvoices.join(', '), category);
    } catch (e) {
      this.dispatch(R.actions.makePayment.onSetLoadPaymentViewError());
      return;
    }

    this.dispatch(R.actions.makePayment.onSetLinkedInvoices(invoices));
  }

  public downloadPaymentDocument = (accountId: number, documentId: number, documentName: string) => {
    R.services.shipmentBillingDocuments.getPaymentDocument(accountId, documentId, documentName);
  }

  public addUploadingPaymentDocument = (document: ContainerDocumentDTM) => {
    const tempDocuments = [...R.selectors.makePayment.getTempDocuments(this.store.getState())];
    const documentIndex = tempDocuments.findIndex((item) => item.uid === document.uid);

    if (documentIndex === -1) {
      tempDocuments.push(document);
    } else {
      tempDocuments.splice(documentIndex, 1, document);
    }

    this.dispatch(R.actions.makePayment.setTempDocuments(tempDocuments));
  }

  openEditMode = () => {
    const payment = R.selectors.makePayment.getPaymentView(this.store.getState());
    const transactions = payment?.transactions.map((item) => ({
      id: item.id,
      value: item.amount,
    }));
    this.dispatch(R.actions.makePayment.onOpenEdit(transactions));

    const paymentDocuments = R.selectors.makePayment.getPaymentDocuments(this.store.getState());

    this.dispatch(R.actions.makePayment.setTempDocuments(paymentDocuments.map((document) => ContainerDocumentDTM.fromPlain({
      name: document.name,
      uid: String(document.id),
      url: '',
      status: 'done',
      response: {
        ...document,
        type: DocumentType.CSW,
      },
    }))));
    this.dispatch(R.actions.makePayment.setDocumentIdsToDelete([]));
  }

  closeEditMode = () => {
    this.dispatch(R.actions.makePayment.onCloseEdit());
  }

  onChangeEditTotalAmount = (sum: number) => {
    this.dispatch(R.actions.makePayment.onChangeEditTotalAmount(sum));
  }

  onChangeEditDate = (date: Moment | null) => {
    this.dispatch(R.actions.makePayment.onChangeEditDate(date?.toISOString()));
  }

  onChangeEditReference = (ref: string) => {
    this.dispatch(R.actions.makePayment.onChangeEditReference(ref));
  }

  onUpdateEditCell = (id: number, value: number, balance: number) => {
    const transactions = R.selectors.makePayment.getEditedTransactions(this.store.getState());

    const updatedTransactions = transactions?.map((item) => (item.id === id ? { id, value, balance } : item));
    this.dispatch(R.actions.makePayment.onSetEditTransactions(updatedTransactions));
  }

  onClearEditError = () => {
    this.dispatch(R.actions.makePayment.setIsErrorEditPayment(false));
    this.dispatch(R.actions.makePayment.setIsErrorEditTransaction(false));
  }
}
