import { DateDtm } from 'app-wrapper/models/dtm';
import {
  IsString,
  IsNumber,
  IsEnum,
  ValidateNested,
  IsArray, IsOptional,
} from 'class-validator';
import { Type } from 'class-transformer';
import { BaseDTM } from 'proto/BaseDTM';

import {
  CargoReferenceDTM,
  ICargoReferenceDTM,
  CargoDTM,
  ContainerDTM,
  PaymentTermsDTM,
  ShippingPartyDTM,
  BillOfLadingDTM,
  ExportCustomsDTM,
  RouteLegDTM,
  ChargeDTM,
  ContainerWithCargoDTM,
  IPaymentTermsDTM,
  IShippingPartyDTM,
  IBillOfLadingDTM,
  IExportCustomsDTM,
  ICargoDTM,
  IContainerInputDTM,
  IRouteLegDTM,
  IChargeDTM,
  IContainerWithCargoDTM,
  TemperatureControlDTM,
} from 'shipment-operations/models/dtm';
import { OrganizationDTM, TOrganizationDTM } from 'user-management/models/dtm';
import { ContainerUsualTypes, ContainerReeferTypes } from 'shipment-operations/constants';
import { ETransportationType } from 'monetary/constants';

export interface IHBLMarksAndNumbersDTM {
  references: ICargoReferenceDTM[];
  marks: string[];
}

export class HBLMarksAndNumbersDTM extends BaseDTM<IHBLMarksAndNumbersDTM> {
  @ValidateNested({ each: true })
  @Type(() => CargoReferenceDTM)
  references: CargoReferenceDTM[];

  @IsArray()
  marks: string[];
}

export interface IHBLDocumentCargoTotalDTM {
  packagesNumber: number;
  netWeight: number;
  weight: number;
  volume: number;
  references: string[];
  marks: string[];
}

export class HBLDocumentCargoTotalDTM extends BaseDTM<IHBLDocumentCargoTotalDTM> {
  @IsNumber()
  packagesNumber: number;

  @IsNumber()
  netWeight: number;

  @IsNumber()
  weight: number;

  @IsNumber()
  volume: number;

  @IsString({ each: true })
  references: string[];

  @IsString({ each: true })
  marks: string[];
}

export interface IHBLDocumentContainerTotalDTM {
  quantity: number
  containerTypes: (ContainerUsualTypes | ContainerReeferTypes)[]
  marks: string[];
  references: ICargoReferenceDTM[];
}

export class HBLDocumentContainerTotalDTM extends BaseDTM<IHBLDocumentContainerTotalDTM> {
  @IsNumber()
  quantity: number

  @IsArray()
  containerTypes: (ContainerUsualTypes | ContainerReeferTypes)[]

  @IsString({ each: true })
  marks: string[];

  @ValidateNested({ each: true })
  @Type(() => CargoReferenceDTM)
  references: CargoReferenceDTM[];
}

export interface IHBLDocumentChargesInfoDTM {
  originChargesSum: string;
  freightChargesSum: string;
  destinationChargesSum: string;
  cargoSum: string;
}

export class HBLDocumentChargesInfoDTM extends BaseDTM<IHBLDocumentChargesInfoDTM> {
  @IsString()
  originChargesSum: string;

  @IsString()
  freightChargesSum: string;

  @IsString()
  destinationChargesSum: string;

  @IsString()
  cargoSum: string;
}

export interface IHBLDocumentTransportationPlanDTM {
  vesselName: string;
  voyagerNumber: string;
  preCarriageLocation: string;
  onCarriageLocation: string;
  placeOfReceipt: string;
  portOfLoading: string;
  portOfDischarge: string;
  placeOfDelivery: string;
  firstLegTransportType: ETransportationType;
  lastLegTransportType: ETransportationType;
  firstSeaLegTransportType: ETransportationType;
  lastSeaLegTransportType: ETransportationType;
}

export class HBLDocumentTransportationPlanDTM extends BaseDTM<IHBLDocumentTransportationPlanDTM> {
  @IsString()
  vesselName: string;

  @IsString()
  voyagerNumber: string;

  @IsString()
  preCarriageLocation: string;

  @IsString()
  onCarriageLocation: string;

  @IsString()
  placeOfReceipt: string;

  @IsString()
  portOfLoading: string;

  @IsString()
  portOfDischarge: string;

  @IsString()
  placeOfDelivery: string;

  @IsEnum(ETransportationType)
  firstLegTransportType: ETransportationType;

  @IsEnum(ETransportationType)
  lastLegTransportType: ETransportationType;

  @IsEnum(ETransportationType)
  firstSeaLegTransportType: ETransportationType;

  @IsEnum(ETransportationType)
  lastSeaLegTransportType: ETransportationType;
}

export interface IHBLDocumentBOLDTM {
  organization: TOrganizationDTM;
  id: number;
  type: string;
  paymentTerms: IPaymentTermsDTM;
  shipmentParties: IShippingPartyDTM[];
  transportationPlanInfo: IHBLDocumentTransportationPlanDTM;
  billOfLading?: IBillOfLadingDTM;
  exportCustoms?: IExportCustomsDTM;
  importCustoms?: IExportCustomsDTM;
  cargo: IHBLDocumentCargoTotalDTM;
  cargos: ICargoDTM[];
  container: IHBLDocumentContainerTotalDTM;
  containers: IContainerInputDTM[];
  containersWithCargo: IContainerWithCargoDTM[];
  legs: IRouteLegDTM[];
  chargesInfo: IHBLDocumentChargesInfoDTM;
  charges: IChargeDTM[];
  clauses: string[];
  temperatureControl?: TemperatureControlDTM;
  departureTime?: DateDtm;
}

export class HBLDocumentBOLDTM extends BaseDTM<IHBLDocumentBOLDTM> {
  @Type(() => OrganizationDTM)
  @ValidateNested()
  organization: OrganizationDTM;

  @IsNumber()
  id: number;

  @IsString()
  type: string;

  @Type(() => PaymentTermsDTM)
  @ValidateNested()
  paymentTerms: PaymentTermsDTM;

  @Type(() => ContainerWithCargoDTM)
  @ValidateNested({ each: true })
  containersWithCargo: ContainerWithCargoDTM[];

  @Type(() => ShippingPartyDTM)
  @ValidateNested({ each: true })
  shipmentParties: ShippingPartyDTM[];

  @Type(() => HBLDocumentTransportationPlanDTM)
  @ValidateNested()
  transportationPlanInfo: HBLDocumentTransportationPlanDTM;

  @IsOptional()
  @Type(() => BillOfLadingDTM)
  @ValidateNested()
  billOfLading?: BillOfLadingDTM;

  @IsOptional()
  @Type(() => ExportCustomsDTM)
  @ValidateNested()
  exportCustoms: ExportCustomsDTM;

  @IsOptional()
  @Type(() => ExportCustomsDTM)
  @ValidateNested()
  importCustoms: ExportCustomsDTM;

  @Type(() => HBLDocumentCargoTotalDTM)
  @ValidateNested()
  cargo: HBLDocumentCargoTotalDTM;

  @Type(() => CargoDTM)
  @ValidateNested({ each: true })
  cargos: CargoDTM[];

  @Type(() => HBLDocumentContainerTotalDTM)
  @ValidateNested()
  container: HBLDocumentContainerTotalDTM;

  @Type(() => ContainerDTM)
  @ValidateNested({ each: true })
  containers: ContainerDTM[];

  @Type(() => RouteLegDTM)
  @ValidateNested({ each: true })
  legs: RouteLegDTM[];

  @Type(() => HBLDocumentChargesInfoDTM)
  @ValidateNested()
  chargesInfo: HBLDocumentChargesInfoDTM;

  @Type(() => ChargeDTM)
  @ValidateNested({ each: true })
  charges: ChargeDTM[];

  @IsString({ each: true })
  clauses: string[];

  @IsOptional()
  @Type(() => TemperatureControlDTM)
  @ValidateNested()
  temperatureControl?: TemperatureControlDTM;

  @IsOptional()
  @Type(() => DateDtm)
  departureTime?: DateDtm;
}
