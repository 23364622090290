import React, {
  FC,
  ReactNode,
  useEffect,
} from 'react';

import {
  SBLCargoTables,
  SBLRoutingInformation,
  SBLScaffoldBillingInstructions,
  SBLScaffoldCarrier,
  SBLScaffoldCompany,
  SBLScaffoldPayments,
  SBLScaffoldRow,
  SBLScaffoldShippingParties,
  SBLScaffoldTransport,
  SBLCharges,
  SBLClauses,
  SBLComments,
} from './SBLScaffold.styled';

interface ISBLScaffoldComponentProps {
  showClauses?: boolean;
  clearError: () => void;
  companyBody?: ReactNode;
  carrierBody?: ReactNode;
  transportBody?: ReactNode;
  shippingPartiesBody?: ReactNode;
  billInstructionBody?: ReactNode;
  paymentsBody?: ReactNode;
  routingBody?: ReactNode;
  referencesBody?: ReactNode;
  cargoTables?: ReactNode;
  clausesBody?: ReactNode;
  commentsBody?: ReactNode;
  chargesBody?: ReactNode;
}

const SBLScaffoldComponent: FC<ISBLScaffoldComponentProps> = ({
  companyBody,
  carrierBody,
  transportBody,
  shippingPartiesBody,
  billInstructionBody,
  paymentsBody,
  routingBody,
  referencesBody,
  cargoTables,
  clearError,
  clausesBody,
  commentsBody,
  chargesBody,
  showClauses,
}) => {
  useEffect(() => () => {
    clearError();
  }, []);

  return (
    <>
      <SBLScaffoldCompany>
        {companyBody}
      </SBLScaffoldCompany>

      <SBLScaffoldRow>
        <SBLScaffoldCarrier>
          {carrierBody}
        </SBLScaffoldCarrier>

        <SBLScaffoldTransport>
          {transportBody}
        </SBLScaffoldTransport>
      </SBLScaffoldRow>

      <SBLScaffoldShippingParties>
        {shippingPartiesBody}
      </SBLScaffoldShippingParties>

      <SBLRoutingInformation>
        {routingBody}
      </SBLRoutingInformation>

      <SBLCargoTables>
        {cargoTables}
      </SBLCargoTables>

      <SBLScaffoldBillingInstructions>
        {billInstructionBody}
      </SBLScaffoldBillingInstructions>

      {paymentsBody ? (
        <SBLScaffoldPayments>
          {paymentsBody}
        </SBLScaffoldPayments>
      ) : null}

      {chargesBody ? (
        <SBLCharges>
          {chargesBody}
        </SBLCharges>
      ) : null}

      {referencesBody}

      <SBLScaffoldRow>
        {showClauses
          ? (
            <SBLClauses>
              {clausesBody}
            </SBLClauses>
          )
          : null}

        {commentsBody ? (
          <SBLComments fullWidth={!showClauses}>
            {commentsBody}
          </SBLComments>
        ) : null}
      </SBLScaffoldRow>
    </>
  );
};

export { SBLScaffoldComponent as SBLScaffold };
