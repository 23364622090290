import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Wrap,
  Item,
  ItemTag,
  ItemText,
  ItemTextColored,
} from './OutsideServiceableRegion.styled';
import { AccountRejected } from '../AccountRejected';
import { PathToReapply } from '../PathToReapply';

interface OutsideServiceableRegionProps {
  note?: string;
}

export const OutsideServiceableRegion: FC<OutsideServiceableRegionProps> = ({ note }) => {
  const { t } = useTranslation();

  return (
    <Wrap>
      <AccountRejected
        title={t('Business location is outside of our serviceable regions')}
        subtitle={t('Our services are currently not available in the geographical location of your business, restricting our ability to onboard and serve you adequately.')}
        adminComment={note}
      />

      <PathToReapply
        content={(
          <>
            <Item>
              <ItemTag />

              <ItemText>
                {t('If you have operations in a location that is within our serviceable regions, consider registering with that location instead.')}
              </ItemText>
            </Item>

            <Item>
              <ItemTag />

              <ItemText>
                {t('Keep an eye on our')}

                <ItemTextColored>
                  {t('announcements')}
                </ItemTextColored>

                {t('or')}

                <ItemTextColored>
                  {t('subscribe to our newsletter')}
                </ItemTextColored>

                {t('to be informed when our services expand to your location.')}
              </ItemText>
            </Item>
          </>
        )}
      />
    </Wrap>
  );
};
