import styled from 'styled-components';

export const InnerContainer = styled.div`
  width: 100%;
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 31%;
  
  input {
    font-size: 12px;
    padding: 3px 0;
  }
  
  .ant-select {
    font-size: 12px;
  }
`;

export const SubTitle = styled.span`
  color: #202C3C;
  font-weight: 400;
  font-size: 12px;
  margin-bottom: 5px;
`;
