import InboxOutlined from '@ant-design/icons/InboxOutlined';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { NoDataLineItemWrapper } from './NoDataLineItem.styled';

export const NoDataLineItem: FC = () => {
  const { t } = useTranslation();

  return (
    <NoDataLineItemWrapper>
      <InboxOutlined />
      <span>{t('No Data')}</span>
    </NoDataLineItemWrapper>
  );
};
