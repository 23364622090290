import styled from 'styled-components';

import {
  Wrapper,
  SubPageHeaderContainer,
  SubPageHeaderTitle,
  FlexRow,
} from 'app-wrapper/view/guideline';

export const Page = styled(Wrapper)`
  .ant-tabs {
    overflow: initial;
  }
`;

export const Content = styled.div`
  height: fit-content;
`;

export const PageHeader = styled(SubPageHeaderContainer)`
  margin-bottom: 10px;
`;

export const PageHeaderTitle = styled(SubPageHeaderTitle)`
  cursor: pointer;
`;

export const PageHeaderRightRow = styled(FlexRow)`
  gap: 16px;
`;
