import React, { FC, useCallback, useMemo } from 'react';
import FileAddOutlined from '@ant-design/icons/FileAddOutlined';
import MessageOutlined from '@ant-design/icons/MessageOutlined';

import {
  EOrganizationStatus,
  ORGANIZATION_STATUS_NAMES,
  ORGANIZATION_STATUS_COLORS,
} from 'user-management/models/dtm';

import themesColors from 'app-wrapper/view/themes/themesColors';
import { Tooltip } from 'app-wrapper/view/components';

import {
  Wrapper,
  StatusName,
  StatusIndicator,
  StatusIcon,
} from './OrganizationStatus.styled';

interface OrganizationStatusProps {
  status: EOrganizationStatus;
  tooltipName?: string;
  isRejectTooltip?: boolean;
  isAdditionalInfoTooltip?: boolean;
}

export const OrganizationStatus: FC<OrganizationStatusProps> = ({
  status, tooltipName,
  isRejectTooltip,
  isAdditionalInfoTooltip,
}) => {
  const statusColor = useMemo(() => {
    if (status === EOrganizationStatus.WAITING_APPROVAL) {
      return themesColors.secondaryDot45;
    }

    return undefined;
  }, [status]);

  const getPopupContainer = useCallback(() => document.body, []);

  return (
    <Wrapper>
      <StatusIndicator
        style={{
          backgroundColor: ORGANIZATION_STATUS_COLORS[status],
        }}
      />

      <StatusName
        $colorText={statusColor}
      >
        {ORGANIZATION_STATUS_NAMES[status]}
        {isRejectTooltip
          ? (
            <Tooltip
              title={tooltipName}
              placement="top"
              getPopupContainer={getPopupContainer}
            >
              <StatusIcon $colorText={themesColors.secondaryDot45}>
                <MessageOutlined />
              </StatusIcon>
            </Tooltip>
          )
          : null}

        {isAdditionalInfoTooltip
          ? (
            <Tooltip
              title={tooltipName}
              placement="top"
              getPopupContainer={getPopupContainer}
            >
              <StatusIcon>
                <FileAddOutlined />
              </StatusIcon>
            </Tooltip>
          )
          : null}
      </StatusName>
    </Wrapper>
  );
};
