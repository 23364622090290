import { createSelector } from 'reselect';

import { RootState } from 'app-wrapper/store';

const localState = (state: RootState) => state.home;

const getIsCustomer = createSelector(
  localState,
  (state) => state.isCustomer,
);

const getIsLoading = createSelector(
  localState,
  (state) => state.isLoading,
);

export const homeSelectors = {
  getIsCustomer,
  getIsLoading,
};
