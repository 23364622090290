import React, { FC, useRef, useCallback } from 'react';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useReactToPrint } from 'react-to-print';

import { PrintRFQ } from 'monetary/view/components/RFQResponse/RFQItem/PrintRFQ';

import { QuotePDFHeader } from './components';
import { MenuItem } from './PrintQuoteRFQ.styled';

const OLD_TITLE = document.title;

interface PrintQuoteRFQComponentProps {
  onClearCustomerInfo?: () => void;
  onPrint?: (quotaIndex: number) => void;
  fileNamePrint?: string
  isLoading: boolean;
  quotaIndex?: number;
  loadData: (shipmentId: string) => Promise<{
    id: number,
    fileName?: string,
  }>;
}

export const PrintQuoteRFQComponent: FC<PrintQuoteRFQComponentProps> = ({
  onPrint,
  onClearCustomerInfo,
  fileNamePrint,
  isLoading,
  quotaIndex,
  loadData,
}) => {
  const { shipmentId = '' } = useParams();
  const { t } = useTranslation();
  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    // @ts-ignore
    content: () => componentRef.current,
    onAfterPrint: () => {
      if (onClearCustomerInfo) {
        onClearCustomerInfo();
      }

      // support chrome
      document.title = OLD_TITLE;
    },
  });

  const onClickPrint = useCallback(async () => {
    if (isLoading) {
      return;
    }

    if (quotaIndex && onPrint) {
      await onPrint(quotaIndex);

      // support chrome
      document.title = fileNamePrint || OLD_TITLE;
      handlePrint();

      return;
    }

    const localQuotaIndex = await loadData(shipmentId);

    if (onPrint) {
      await onPrint(localQuotaIndex.id);
      // support chrome
      document.title = localQuotaIndex.fileName || fileNamePrint || OLD_TITLE;
      handlePrint();
    }
  }, [onPrint, handlePrint, isLoading, loadData, shipmentId, quotaIndex, fileNamePrint]);

  return (
    <>
      <div style={{ display: 'none' }}>
        <PrintRFQ
          ref={componentRef}
          fileNamePrint={fileNamePrint}
          headerComponent={<QuotePDFHeader />}
        />
      </div>

      <MenuItem onClick={onClickPrint}>
        {t('Print')}
      </MenuItem>
    </>
  );
};
