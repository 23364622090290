import styled from 'styled-components';

import { EmptyScrollbar, divFlex } from 'app-wrapper/view/themes/mixin';
import { CustomScrollbar } from 'app-wrapper/view/styles/mixins';

export const ShipmentListPart = styled.div.attrs({
  'data-class': 'ShipmentListPart',
})`
  ${divFlex}
  ${EmptyScrollbar}

  flex-direction: column;
  width: 100%;
  padding: 0px 24px 24px 24px;
  height: calc(100vh - 56px - 48px - 46px - 20px);
  overflow-y: auto;
`;

export const ShipmentListPartContentWrap = styled.div.attrs({
  'data-class': 'ShipmentListPartContentWrap',
})`
  ${divFlex}
  height: calc(100vh - 56px - 48px - 38px - 24px);
  ${EmptyScrollbar}

  div[data-class="EmptyStateForPagesIconSecondWrap"] .anticon-filter svg {
    width: 25px;
    height: 22px;
  }
  div[data-class="EmptyStateForPagesIconSecondWrap"] .anticon-search svg {
    width: 29px;
    height: 29px;
    margin-top: -4px;
    margin-left: -3px;
  }
`;

export const ShipmentListPartContent = styled.div.attrs({
  'data-class': 'ShipmentListPartContent',
})`
  ${divFlex}
  flex-direction: column;
  width: 100%;
  overflow-y: auto;
  ${CustomScrollbar}

  .infinite-scroll-component__outerdiv {
    width: 100%;
  }

  .infinite-scroll-component  {
    gap: 8px;
  }
`;

export const ShipmentListPartContentHeader = styled.div.attrs({
  'data-class': 'ShipmentListPartContentHeader',
})`
  ${divFlex}

  padding: 8px 0;
  width: 100%;
`;

export const ShipmentListPartContentHeaderTitle = styled.div.attrs((props: {
  width: string,
  paddingLeft?: string,
  isCenter?: boolean,
  isEnd?: boolean,
}) => ({
  width: props.width,
  paddingLeft: props.paddingLeft,
  isCenter: props.isCenter,
  isEnd: props.isEnd,
  'data-class': 'ShipmentListPartContentHeaderTitle',
}))`
  ${divFlex}

  color: ${({ theme }) => theme.themesColors.secondaryDot45};
  font-size: 12px;
  font-weight: 400;
  text-transform: capitalize;

  width: ${({ width }) => width || '100%'};
  padding-left: ${({ paddingLeft }) => paddingLeft || '0'};
  ${({ isCenter }) => isCenter && 'justify-content: center;'};
  ${({ isEnd }) => isEnd && 'justify-content: end; padding-right: 16px;'};
`;
