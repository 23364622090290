import { createSelector } from 'reselect';
import { EShippingPartyTypes } from 'shipment-operations/constants';

import { EOrganizationMemberRole } from 'user-management/constants';
import { RootState } from 'app-wrapper/store';

const localState = (state: RootState) => state.shipmentPeople;

const getIsLoading = createSelector(
  localState,
  (state) => state.isLoading,
);

const getWorkgroups = createSelector(
  localState,
  (state) => state.workgroups,
);

const getIsDrawerOpened = createSelector(
  localState,
  (state) => state.isDrawerOpened,
);

const getCurrentOrganization = createSelector(
  localState,
  (state) => state.currentOrganization,
);

const getIsCustomerView = createSelector(
  getCurrentOrganization,
  (currentOrg) => Boolean(currentOrg && currentOrg.role === EOrganizationMemberRole.CUSTOMER),
);

const getCurrentOrganizationId = createSelector(
  localState,
  (state) => state.currentOrganization?.id,
);

const getShipment = createSelector(
  localState,
  (state) => state.shipment,
);

const getIsDrawerLoading = createSelector(
  localState,
  (state) => state.isDrawerLoading,
);

const getDrawerMembers = createSelector(
  localState,
  (state) => state.drawerMembers,
);

const getTempMember = createSelector(
  localState,
  (state) => state.tempMember,
);

const getIsAbleToAddMember = createSelector(
  localState,
  getIsCustomerView,
  (state, isCustomerView) => (isCustomerView ? !!state.tempMember : true),
);

const getWorkgroupMembersEmails = createSelector(
  localState,
  (state) => state.workgroupMembersEmails,
);

const getWorkgroupId = createSelector(
  localState,
  (state) => state.workgroupId,
);

const getRolesToEmailsState = createSelector(
  localState,
  (state) => state.rolesToEmailsState,
);

const getRolesToEmailsInitialState = createSelector(
  localState,
  (state) => state.rolesToEmailsInitialState,
);

const getRolesToEdit = createSelector(
  localState,
  (state) => state.rolesToEdit,
);

const getWorkgroupMembers = createSelector(
  localState,
  (state) => state.workgroupMembers,
);

const getShippingParties = createSelector(
  localState,
  (state) => state.shippingParties,
);

const getShippingPartyCompanyName = (role: EShippingPartyTypes) => createSelector(
  getShippingParties,
  (parties) => {
    const targetParty = parties.find((party) => party.role === role);

    return targetParty?.company?.name || '';
  },
);

export const shipmentPeopleSelectors = {
  getIsLoading,
  getWorkgroups,
  getIsDrawerOpened,
  getIsCustomerView,
  getIsDrawerLoading,
  getCurrentOrganization,
  getCurrentOrganizationId,
  getShipment,
  getDrawerMembers,
  getTempMember,
  getIsAbleToAddMember,
  getWorkgroupMembersEmails,
  getWorkgroupId,
  getRolesToEmailsState,
  getRolesToEdit,
  getRolesToEmailsInitialState,
  getWorkgroupMembers,
  getShippingPartyCompanyName,
};
