import { TitleText } from 'app-wrapper/view/guideline/Font';
import { divFlex } from 'app-wrapper/view/themes/mixin';
import styled from 'styled-components';

export const ContentSection = styled.div.attrs({
  'data-class': 'ContentSection',
})`
  ${divFlex}
  width: 100%;

  padding: 16px;
  border-radius: 3px;
  border: 1px solid ${({ theme }) => theme.themesColors.neutralBranding4};
`;

export const ContentSectionTitle = styled.h6.attrs({
  'data-class': 'ContentSectionTitle',
})`
  ${TitleText}
`;
