import styled from 'styled-components';

import { Input } from 'app-wrapper/view/components';

export const InputStyled = styled(Input)`
  height: 32px;
  min-height: 32px;
  
  ::placeholder {
    color: #73819B;
  }
`;
