import React from 'react';
import isEmpty from 'lodash/fp/isEmpty';

import { useAppSelector } from 'app-wrapper/hooks';
import { R } from 'shipment-operations/repository';

import { UC } from 'shipment-operations/controllers';
import { ButtonsComponent } from './Buttons.component';

const Buttons = () => {
  const invoicedItems = useAppSelector(R.selectors.createCreditNote.getInvoicedItems);
  const status = useAppSelector(R.selectors.shipmentBillingInvoice.getStatus);
  const permissions = useAppSelector(R.selectors.shipment.getShipmentChargesPermissions);

  return (
    <ButtonsComponent
      onOpenCreditNote={UC.shipmentBillingInvoice.openCreateCreditNote}
      openPayment={UC.shipmentBillingInvoice.openPayment}
      isEmptyItems={isEmpty(invoicedItems)}
      status={status}
      permissions={permissions}
    />
  );
};

export { Buttons };
