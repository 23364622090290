export const PAYMENT_DATE = 'paymentDate';
export const RECEIVED_AMOUNT = 'receivedAmount';
export const REFERENCE = 'reference';

export enum PaymentsStatusesEnum {
  OPEN = 'OPEN',
  APPLIED = 'APPLIED',
  PARTIAL = 'PARTIAL',
}

export const paymentEditError = ['Payment amount can\'t be decreased.', 'Payment with transactions can\'t be deleted.'];
export const transactionEditError = [
  'Invoice already paid.',
  'Too big transaction amount.',
  'Not enough money to pay.',
];
