import React from 'react';
import { useSelector } from 'react-redux';

import { R } from 'user-management/repository';

import { PartnerUserProfileComponent } from './PartnerUserProfile.component';

export const PartnerUserProfile = React.memo(() => {
  const user = useSelector(R.selectors.userOrganizationData.getOrganizationUserMemberData);

  return (
    <PartnerUserProfileComponent user={user} />
  );
});
