import { IsEnum, IsNumber, IsOptional } from 'class-validator';
import { BaseDTM } from 'proto/BaseDTM';
import { AirVentUnitsType } from 'shipment-operations/constants';

export interface IAirVentDTM {
  flowRate?: string
  flowRateUnit?: AirVentUnitsType
}

export class AirVentDTM extends BaseDTM<IAirVentDTM> {
  @IsOptional()
  @IsNumber()
  flowRate?: string;

  @IsOptional()
  @IsEnum(AirVentUnitsType)
  flowRateUnit?: AirVentUnitsType;
}
