import React from 'react';

import { IPropsIcon } from 'app-wrapper/interfaces/icon';

import { Icon } from './RFRRatesRequestSvg.styled';

export const RFRRatesRequestSvg = (props: IPropsIcon) => {
  const { width, height, fill } = props;
  return (
    <Icon
      className="RFRRatesRequestSvg"
      width={width || '144'}
      height={height || '144'}
      viewBox="0 0 144 144"
      fill={fill || 'currentColor'}
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="144" height="144" rx="72" fill="#F9F9FB" />
      <path fillRule="evenodd" clipRule="evenodd" d="M33.9981 57.6108C32.3221 57.6108 30.9635 56.2889 30.9635 54.6582C30.9635 53.0275 32.3221 51.7056 33.9981 51.7056C35.6741 51.7056 37.0327 53.0275 37.0327 54.6582C37.0327 56.2889 35.6741 57.6108 33.9981 57.6108ZM43.1056 55.9938V53.3225L40.9417 52.7876C40.7831 52.2286 40.555 51.6977 40.2672 51.2042L41.4087 49.3367L39.4674 47.4478L37.5481 48.5586C37.0408 48.2784 36.4951 48.0566 35.9206 47.9022L35.3708 45.7969H32.6253L32.0755 47.9022C31.501 48.0566 30.9554 48.2784 30.4481 48.5586L28.5288 47.4478L26.5875 49.3367L27.729 51.2042C27.4411 51.6977 27.2131 52.2287 27.0544 52.7876L24.8906 53.3225V55.9938L27.0544 56.5288C27.2131 57.0877 27.4411 57.6186 27.729 58.1122L26.5875 59.9797L28.5288 61.8685L30.4481 60.7578C30.9554 61.0379 31.501 61.2598 32.0755 61.4142L32.6253 63.5195H35.3708L35.9206 61.4142C36.4951 61.2598 37.0408 61.0379 37.5481 60.7578L39.4674 61.8685L41.4087 59.9797L40.2672 58.1122C40.555 57.6186 40.7831 57.0877 40.9417 56.5288L43.1056 55.9938Z" fill="#8BBC06" />
      <path fillRule="evenodd" clipRule="evenodd" d="M47.6363 68.6192C46.0792 68.6192 44.817 67.3911 44.817 65.8761C44.817 64.3611 46.0792 63.133 47.6363 63.133C49.1933 63.133 50.4556 64.3611 50.4556 65.8761C50.4556 67.3911 49.1933 68.6192 47.6363 68.6192ZM56.0975 67.117V64.6352L54.0873 64.1382C53.9398 63.6189 53.728 63.1257 53.4605 62.6672L54.5211 60.9322L52.7175 59.1774L50.9344 60.2093C50.4631 59.949 49.9561 59.7429 49.4224 59.5995L48.9116 57.6436H46.3609L45.8501 59.5995C45.3164 59.7429 44.8095 59.949 44.3382 60.2093L42.5551 59.1774L40.7515 60.9322L41.812 62.6672C41.5445 63.1257 41.3327 63.6189 41.1853 64.1382L39.175 64.6352V67.117L41.1853 67.614C41.3327 68.1333 41.5445 68.6265 41.812 69.0851L40.7515 70.82L42.5551 72.5748L44.3382 71.5429C44.8095 71.8032 45.3164 72.0093 45.8501 72.1527L46.3609 74.1087H48.9116L49.4224 72.1527C49.9561 72.0093 50.4631 71.8032 50.9344 71.5429L52.7175 72.5748L54.5211 70.82L53.4605 69.0851C53.728 68.6265 53.9398 68.1333 54.0873 67.614L56.0975 67.117Z" fill="#E9EFF8" />
      <path d="M104.565 49.1494H49.1563C47.9136 49.1494 46.9062 50.1568 46.9062 51.3994V76.9219C46.9062 78.1646 47.9136 79.1719 49.1563 79.1719H104.565C105.808 79.1719 106.815 78.1646 106.815 76.9219V51.3994C106.815 50.1568 105.808 49.1494 104.565 49.1494Z" fill="#597EF7" />
      <path d="M97.0625 56.6494H71.6562C71.242 56.6494 70.9062 56.9852 70.9062 57.3994V58.8994C70.9062 59.3136 71.242 59.6494 71.6562 59.6494H97.0625C97.4767 59.6494 97.8125 59.3136 97.8125 58.8994V57.3994C97.8125 56.9852 97.4767 56.6494 97.0625 56.6494Z" fill="#2F54EB" />
      <path d="M85.25 68.6494H71.6562C71.242 68.6494 70.9062 68.9852 70.9062 69.3994V70.8994C70.9062 71.3136 71.242 71.6494 71.6562 71.6494H85.25C85.6642 71.6494 86 71.3136 86 70.8994V69.3994C86 68.9852 85.6642 68.6494 85.25 68.6494Z" fill="#597EF7" />
      <path d="M97.0625 62.6494H71.6562C71.242 62.6494 70.9062 62.9852 70.9062 63.3994V64.8994C70.9062 65.3136 71.242 65.6494 71.6562 65.6494H97.0625C97.4767 65.6494 97.8125 65.3136 97.8125 64.8994V63.3994C97.8125 62.9852 97.4767 62.6494 97.0625 62.6494Z" fill="#2F54EB" />
      <path d="M61.1562 56.6494H52.1562C51.3278 56.6494 50.6562 57.321 50.6562 58.1494V67.1494C50.6562 67.9778 51.3278 68.6494 52.1562 68.6494H61.1562C61.9847 68.6494 62.6562 67.9778 62.6562 67.1494V58.1494C62.6562 57.321 61.9847 56.6494 61.1562 56.6494Z" fill="#2F54EB" />
      <path d="M66.4531 56.6494V76.1813" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M94.8153 67.4531H39.4063C38.1636 67.4531 37.1562 68.4605 37.1562 69.7031V95.2256C37.1562 96.4683 38.1636 97.4756 39.4063 97.4756H94.8153C96.058 97.4756 97.0653 96.4683 97.0653 95.2256V69.7031C97.0653 68.4605 96.058 67.4531 94.8153 67.4531Z" fill="#E4EBF7" />
      <path d="M87.3125 74.9531H61.9062C61.492 74.9531 61.1562 75.2889 61.1562 75.7031V77.2031C61.1562 77.6173 61.492 77.9531 61.9062 77.9531H87.3125C87.7267 77.9531 88.0625 77.6173 88.0625 77.2031V75.7031C88.0625 75.2889 87.7267 74.9531 87.3125 74.9531Z" fill="#597EF7" />
      <path d="M75.5 86.9531H61.9062C61.492 86.9531 61.1562 87.2889 61.1562 87.7031V89.2031C61.1562 89.6173 61.492 89.9531 61.9062 89.9531H75.5C75.9142 89.9531 76.25 89.6173 76.25 89.2031V87.7031C76.25 87.2889 75.9142 86.9531 75.5 86.9531Z" fill="#597EF7" />
      <path d="M87.3125 80.9531H61.9062C61.492 80.9531 61.1562 81.2889 61.1562 81.7031V83.2031C61.1562 83.6173 61.492 83.9531 61.9062 83.9531H87.3125C87.7267 83.9531 88.0625 83.6173 88.0625 83.2031V81.7031C88.0625 81.2889 87.7267 80.9531 87.3125 80.9531Z" fill="#597EF7" />
      <path d="M51.4062 74.9531H42.4062C41.5778 74.9531 40.9062 75.6247 40.9062 76.4531V85.4531C40.9062 86.2816 41.5778 86.9531 42.4062 86.9531H51.4062C52.2347 86.9531 52.9062 86.2816 52.9062 85.4531V76.4531C52.9062 75.6247 52.2347 74.9531 51.4062 74.9531Z" fill="#597EF7" />
      <path d="M56.7031 74.9531V94.485" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path fillRule="evenodd" clipRule="evenodd" d="M85.3968 116.658L84.3362 115.597L92.8227 107.111L93.8834 108.172L85.3968 116.658Z" fill="#A3B5D6" />
      <path fillRule="evenodd" clipRule="evenodd" d="M101.58 108.977C96.2988 108.977 92.0176 104.695 92.0176 99.4141C92.0176 94.1328 96.2988 89.8516 101.58 89.8516C106.861 89.8516 111.143 94.1328 111.143 99.4141C111.143 104.695 106.861 108.977 101.58 108.977ZM101.58 86.6641C94.5384 86.6641 88.8301 92.3724 88.8301 99.4141C88.8301 106.456 94.5384 112.164 101.58 112.164C108.622 112.164 114.33 106.456 114.33 99.4141C114.33 92.3724 108.622 86.6641 101.58 86.6641Z" fill="#FAAD14" />
      <path fillRule="evenodd" clipRule="evenodd" d="M94.8203 99.4141C94.8203 95.6807 97.8468 92.6542 101.58 92.6542C105.314 92.6542 108.34 95.6807 108.34 99.4141C108.34 103.147 105.314 106.174 101.58 106.174C97.8468 106.174 94.8203 103.147 94.8203 99.4141Z" fill="#FAAD14" />
      <path fillRule="evenodd" clipRule="evenodd" d="M101.58 103.164C99.509 103.164 97.8301 101.485 97.8301 99.4141C97.8301 97.343 99.509 95.6641 101.58 95.6641C103.651 95.6641 105.33 97.343 105.33 99.4141C105.33 101.485 103.651 103.164 101.58 103.164Z" fill="white" />
      <path fillRule="evenodd" clipRule="evenodd" d="M81.2864 120.769L80.2258 119.708C79.9329 119.415 79.9329 118.94 80.2258 118.647L87.6774 111.196C87.9703 110.903 88.4451 110.903 88.738 111.196C88.738 111.196 88.738 111.196 88.738 111.196L89.7987 112.256C90.0916 112.549 90.0916 113.024 89.7987 113.317L82.347 120.769C82.0542 121.062 81.5793 121.062 81.2864 120.769C81.2864 120.769 81.2864 120.769 81.2864 120.769Z" fill="#FAAD14" />
      <path fillRule="evenodd" clipRule="evenodd" d="M109.732 64.3355L117.406 67.8771C117.607 67.9698 117.851 67.8947 117.951 67.7094C117.979 67.6574 117.994 67.5999 117.994 67.5417L117.994 60.4584C117.994 60.2513 117.812 60.0834 117.588 60.0834C117.525 60.0834 117.463 60.0969 117.406 60.123L109.732 63.6646C109.532 63.7573 109.45 63.9825 109.551 64.1678C109.59 64.2403 109.654 64.2992 109.732 64.3355Z" fill="#E4EBF7" />
    </Icon>
  );
};
