import styled from 'styled-components';
import { WarningFilled, QuestionCircleOutlined } from '@ant-design/icons';

import { Button } from 'app-wrapper/view/components';

export const Wrapper = styled.div`
  width: 100%;
  padding: 16px;
  border: 2px solid #FFCCC7;
  background: #FFFFFF;
  border-radius: 3px;
`;

export const WarningWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: 8px;
  border: 1px solid #FFCCC7;
  border-radius: 2px;
  background: #FFF1F0;
`;

export const WarningWrapperTitle = styled.span.attrs({
  'data-class': 'overview-miss-matches-warning-title',
})`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #202C3C;
`;

export const WarningIconWrap = styled.div`
  width: 40px;
  height: 40px;
  margin-right: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 34px;
  background: rgba(255, 204, 199, 0.5);
`;

export const WarningIcon = styled(WarningFilled)`
  svg {
    width: 22px;
    height: 18.5px;
  }

  color: #FF4D4F;
`;

export const Actions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  padding-top: 16px;
  border-top: 1px solid #EAEEF8;
`;

export const AskForCorrectionsButton = styled(Button)`
  margin-right: auto;
  border: 1px solid #DEE1E9;
  background: white;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
  border-radius: 3px;
  height: 32px;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #202C3C;
  
  &:hover, &:focus {
    background: white;
    color: #202C3C;
    border: 1px solid #DEE1E9;
  }
`;

export const AskForCorrectionsIcon = styled(QuestionCircleOutlined)`
  margin-top: 2px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.25);
`;

export const CancelButton = styled(Button)`
  height: 32px;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #FF4D4F;
  border: 1px solid #FF4D4F;
  background: white;
  
  &:hover, &:focus {
    color: #FF4D4F;
    border: 1px solid #FF4D4F;
    background: white;
  }
`;

export const AcceptButton = styled(Button)`
  height: 32px;
  margin-left: 8px;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
`;
