import React from 'react';

import { useAppSelector } from 'app-wrapper/hooks';
import { R } from 'shipment-operations/repository';

import { CardsWrapperComponent } from './CardsWrapper.component';

const CardsWrapper = () => {
  const invoice = useAppSelector(R.selectors.shipmentBillingInvoice.getInvoice);
  const companyFrom = useAppSelector(R.selectors.shipmentBillingInvoice.getLinkedCompanyFrom);
  const companyTo = useAppSelector(R.selectors.shipmentBillingInvoice.getLinkedCompanyTo);
  const shipment = useAppSelector(R.selectors.shipment.getShipment);

  return (
    <CardsWrapperComponent
      invoice={invoice}
      companyFrom={companyFrom}
      companyTo={companyTo}
      shipmentName={shipment?.shipmentName}
    />
  );
};

export { CardsWrapper };
