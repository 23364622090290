import styled from 'styled-components';
import Menu from 'antd/es/menu';
import Typography from 'antd/es/typography';
import { NavLink } from 'react-router-dom';
import { LockOutlined } from '@ant-design/icons';

import themesColors from 'app-wrapper/view/themes/themesColors';

const { Item, SubMenu } = Menu;

export const StyledMenu = styled(Menu)`
  height: calc(100% - 40px);
  border-right: none;
  background-color: transparent;
  z-index: 1;
  position: relative;
  
  .ant-menu {
    border: none;
    svg {
      color: ${themesColors.darkGrayMiddle};
    }
  }
  
  &.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: ${themesColors.primaryBranding1};
    
    & .ant-menu-title-content {
      color: ${themesColors.darkGrayMiddle};
    }
  }
  
  &.ant-menu:not(.ant-menu-horizontal).ant-menu-inline-collapsed .ant-menu-item-selected {
    background-color: ${themesColors.neutralBranding2};
  }
  
  &.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected a {
    color: ${themesColors.darkGrayMiddle};
  }
  
  &.ant-menu:not(.ant-menu-horizontal) a:hover {
    color: ${themesColors.darkGrayMiddle};
  }
`;

export const StyledItem = styled(Item)`  
  & a:hover {
    color: ${themesColors.darkGrayMiddle};
  }
`;

export const StyledSubMenu = styled(SubMenu)`
  &.ant-menu-submenu-selected .ant-menu-title-content {
    color: ${themesColors.darkGrayMiddle};
  }
`;

export const Link = styled(NavLink)`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const LinkText = styled(Typography)`
  font-size: 14px;
  line-height: 22px;
`;

export const LinkLock = styled(LockOutlined)`
  margin-left: auto;
  font-size: 12px !important;
  color: rgba(115, 129, 155, 1);
`;
