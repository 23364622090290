import React, { FC, useCallback } from 'react';

import { StyledCheckbox } from 'shipment-operations/view/pages/ShipmentCharges/ShipmentCharges.styled';

interface ICheckboxProps {
  onChange: () => void
  checked: boolean
  mLeft: string
}

const Checkbox: FC<ICheckboxProps> = ({ onChange, checked, mLeft }) => {
  const handleClick = useCallback((e) => e.stopPropagation(), []);

  return (
    <StyledCheckbox
      onChange={onChange}
      checked={checked}
      mLeft={mLeft}
      onClick={handleClick}
    />
  );
};

export { Checkbox };
