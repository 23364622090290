import React, {
  FC, memo, useCallback,
} from 'react';
import { useTranslation } from 'react-i18next';

import { ICountryListDTM } from 'app-wrapper/models/dtm';

import { OrganizationDTM, OrganizationFieldsErrorsDTM } from 'user-management/models/dtm';

import {
  EditProfileOrganizationAddressComponent,
  EditProfileOrganizationContactComponent,
  EditProfileOrganizationFinancial,
  EditProfileOrganizationGeneralInformation,
  EditProfileOrganizationContactInformationComponent,
} from './components';
import {
  EditProfileOrganizationButtonCancel,
  EditProfileOrganizationButtonSave,
  EditProfileOrganizationContent,
  EditProfileOrganizationDivider,
  EditProfileOrganizationFooter,
  EditProfileOrganizationWrap,
} from './EditProfileOrganization.styled';

interface IEditProfileOrganizationComponentProps {
  organization?: OrganizationDTM
  organizationEditProfileCountry?: boolean
  organizationFieldsErrors?: OrganizationFieldsErrorsDTM
  countriesList?: ICountryListDTM[]
  closeEditProfileOrganizationDrawer: () => void
  saveEditProfileOrganization: () => void
  onChangeUpdateAddress1: (address1: string) => void
  onChangeUpdateAddress2: (address2: string) => void
  onChangeUpdateAddressCity: (city: string) => void
  onChangeUpdateAddressPostalCode: (postalCode: string) => void
  onChangeUpdateAddressCountry: (country: string) => void
  onChangeUpdatePhone: (phone: string) => void
  onChangeUpdatePhone2: (phone2: string) => void
  onChangeUpdateEmail: (email: string) => void
  onChangeUpdateWebUrl: (webUrl: string) => void
  onBlurUpdateAddress1: () => void
  onBlurUpdateAddress2: () => void
  onBlurUpdateAddressCity: () => void
  onBlurUpdateAddressPostalCode: () => void
  onBlurUpdateAddressCountry: () => void
  onClearUpdateAddressCountry: () => void
  onBlurUpdatePhone: () => void
  onFocusUpdatePhone: () => void
  onBlurUpdatePhone2: () => void
  onFocusUpdatePhone2: () => void
  onBlurUpdateEmail: () => void
  onFocusUpdateEmail: () => void
  onBlurUpdateWebUrl: () => void
  onFocusUpdateWebUrl: () => void
  onChangeUpdatePrimaryName: (id: string) => void
  onBlurUpdatePrimaryName: () => void
  onChangeUpdatePrimaryEmail: (email: string) => void
  onBlurUpdatePrimaryEmail: () => void
  onFocusUpdatePrimaryEmail: () => void
  onChangeUpdatePrimaryPhone: (email: string) => void
  onBlurUpdatePrimaryPhone: () => void
  onFocusUpdatePrimaryPhone: () => void
  onChangeUpdatePrimaryPhone2: (email: string) => void
  onBlurUpdatePrimaryPhone2: () => void
  onFocusUpdatePrimaryPhone2: () => void
}

const EditProfileOrganizationComponent: FC<IEditProfileOrganizationComponentProps> = (props) => {
  const {
    closeEditProfileOrganizationDrawer,
    organizationEditProfileCountry,
    countriesList,
    onBlurUpdateAddress1,
    onBlurUpdateAddress2,
    onBlurUpdateAddressCity,
    onBlurUpdateAddressCountry,
    onBlurUpdateAddressPostalCode,
    onBlurUpdateEmail,
    onBlurUpdatePhone,
    onBlurUpdatePhone2,
    onBlurUpdatePrimaryEmail,
    onBlurUpdatePrimaryName,
    onBlurUpdatePrimaryPhone,
    onBlurUpdatePrimaryPhone2,
    onBlurUpdateWebUrl,
    onChangeUpdateAddress1,
    onChangeUpdateAddress2,
    onChangeUpdateAddressCity,
    onChangeUpdateAddressCountry,
    onChangeUpdateAddressPostalCode,
    onChangeUpdateEmail,
    onChangeUpdatePhone,
    onChangeUpdatePhone2,
    onChangeUpdatePrimaryEmail,
    onChangeUpdatePrimaryName,
    onChangeUpdatePrimaryPhone,
    onChangeUpdatePrimaryPhone2,
    onChangeUpdateWebUrl,
    onClearUpdateAddressCountry,
    onFocusUpdateEmail,
    onFocusUpdatePhone,
    onFocusUpdatePhone2,
    onFocusUpdatePrimaryEmail,
    onFocusUpdatePrimaryPhone,
    onFocusUpdatePrimaryPhone2,
    onFocusUpdateWebUrl,
    organization,
    organizationFieldsErrors,
    saveEditProfileOrganization,
  } = props;
  const { t } = useTranslation();

  const onCancelClick = useCallback(() => {
    closeEditProfileOrganizationDrawer();
  }, []);

  const onSaveClick = useCallback(() => {
    saveEditProfileOrganization();
  }, []);

  return (
    <EditProfileOrganizationWrap>
      <EditProfileOrganizationContent>
        <EditProfileOrganizationGeneralInformation />

        <EditProfileOrganizationDivider />

        <EditProfileOrganizationAddressComponent
          organization={organization}
          organizationEditProfileCountry={organizationEditProfileCountry}
          organizationFieldsErrors={organizationFieldsErrors}
          countriesList={countriesList}
          onChangeUpdateAddress1={onChangeUpdateAddress1}
          onChangeUpdateAddress2={onChangeUpdateAddress2}
          onChangeUpdateAddressCity={onChangeUpdateAddressCity}
          onChangeUpdateAddressPostalCode={onChangeUpdateAddressPostalCode}
          onChangeUpdateAddressCountry={onChangeUpdateAddressCountry}
          onBlurUpdateAddress1={onBlurUpdateAddress1}
          onBlurUpdateAddress2={onBlurUpdateAddress2}
          onBlurUpdateAddressCity={onBlurUpdateAddressCity}
          onBlurUpdateAddressPostalCode={onBlurUpdateAddressPostalCode}
          onBlurUpdateAddressCountry={onBlurUpdateAddressCountry}
          onClearUpdateAddressCountry={onClearUpdateAddressCountry}
        />

        <EditProfileOrganizationDivider />

        <EditProfileOrganizationContactInformationComponent
          organization={organization}
          organizationFieldsErrors={organizationFieldsErrors}
          onChangeUpdatePrimaryName={onChangeUpdatePrimaryName}
          onBlurUpdatePrimaryName={onBlurUpdatePrimaryName}
          onChangeUpdatePrimaryEmail={onChangeUpdatePrimaryEmail}
          onBlurUpdatePrimaryEmail={onBlurUpdatePrimaryEmail}
          onFocusUpdatePrimaryEmail={onFocusUpdatePrimaryEmail}
          onChangeUpdatePrimaryPhone={onChangeUpdatePrimaryPhone}
          onBlurUpdatePrimaryPhone={onBlurUpdatePrimaryPhone}
          onFocusUpdatePrimaryPhone={onFocusUpdatePrimaryPhone}
          onChangeUpdatePrimaryPhone2={onChangeUpdatePrimaryPhone2}
          onBlurUpdatePrimaryPhone2={onBlurUpdatePrimaryPhone2}
          onFocusUpdatePrimaryPhone2={onFocusUpdatePrimaryPhone2}
        />

        <EditProfileOrganizationDivider />

        <EditProfileOrganizationContactComponent
          organization={organization}
          organizationFieldsErrors={organizationFieldsErrors}
          onBlurUpdatePhone={onBlurUpdatePhone}
          onFocusUpdatePhone={onFocusUpdatePhone}
          onBlurUpdatePhone2={onBlurUpdatePhone2}
          onFocusUpdatePhone2={onFocusUpdatePhone2}
          onBlurUpdateEmail={onBlurUpdateEmail}
          onFocusUpdateEmail={onFocusUpdateEmail}
          onBlurUpdateWebUrl={onBlurUpdateWebUrl}
          onFocusUpdateWebUrl={onFocusUpdateWebUrl}
          onChangeUpdatePhone={onChangeUpdatePhone}
          onChangeUpdatePhone2={onChangeUpdatePhone2}
          onChangeUpdateEmail={onChangeUpdateEmail}
          onChangeUpdateWebUrl={onChangeUpdateWebUrl}
        />

        <EditProfileOrganizationDivider />

        <EditProfileOrganizationFinancial />

      </EditProfileOrganizationContent>

      <EditProfileOrganizationFooter>
        <EditProfileOrganizationButtonCancel
          onClick={onCancelClick}
        >
          {t('addChargeMenu.cancel')}
        </EditProfileOrganizationButtonCancel>
        <EditProfileOrganizationButtonSave
          onClick={onSaveClick}
        >
          {t('addChargeMenu.save')}
        </EditProfileOrganizationButtonSave>
      </EditProfileOrganizationFooter>
    </EditProfileOrganizationWrap>
  );
};

const EditProfileOrganizationComponentCached = memo(EditProfileOrganizationComponent);

export { EditProfileOrganizationComponentCached as EditProfileOrganizationComponent };
