import { RFQServiceByIdService } from './RFQServiceById';
import { InvoicesService } from './Invoices';
import { PaymentsService } from './Payments';
import { RateService } from './Rate';
import { ModuleFunctionalityPermissionsService } from './ModuleFunctionalityPermissions';
import { FAKExceptionService } from './FAKException';
import { ContractsService } from './Contracts';

export const services = {
  RFQServiceById: new RFQServiceByIdService(),
  invoices: new InvoicesService(),
  payments: new PaymentsService(),
  rate: new RateService(),
  moduleFunctionalityPermissions: new ModuleFunctionalityPermissionsService(),
  FAKException: new FAKExceptionService(),
  contracts: new ContractsService(),
};
