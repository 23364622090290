import styled from 'styled-components';
import Typography from 'antd/es/typography';

import { Button } from 'app-wrapper/view/components';

export const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  margin: 8px 24px;
  border-radius: 3px;
  background-color: rgba(255, 255, 255, 1);
  border: 1px solid rgba(234, 238, 248, 1);
`;

export const StepWrap = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const ProgressWrap = styled.div`
  margin-left: auto;
`;

export const Header = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Title = styled(Typography)`
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  border-top: 1px solid rgba(234, 238, 248, 1);
`;

export const Steps = styled.div`
  width: 30%;
  display: flex;
  flex-direction: column;
  border-right: 1px solid rgba(234, 238, 248, 1);
`;

export const StepWrapper = styled.div`
  width: 70%;
  min-height: 300px;
  padding: 27px 32px;
`;

export const StepManagerButton = styled(Button)`
  width: fit-content;
  margin-top: 20px;
  height: 32px;
  font-size: 14px;
  line-height: 22px;
`;
