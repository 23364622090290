import styled from 'styled-components';
import Radio from 'antd/es/radio';

import { RadioGroupCss } from 'app-wrapper/view/styles/mixins';

export const RadioGroup = styled(Radio.Group).attrs({
  'data-class': 'radio-group',
})`
  ${RadioGroupCss}
`;
