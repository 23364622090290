import i18next from 'i18next';

import { IBaseErrorsResponseDTM } from 'monetary/models/errors';

export class MonetaryValidationsError extends Error {
  // statusCode = 400;

  constructor(err: IBaseErrorsResponseDTM) {
    const messageDefault = i18next.t('RFQRequestErrors.COMMON_ERROR_TITLE');
    let message = '';
    let messageArray: string[] = [];

    if (err.message) {
      message = err.message;
    }

    if (err.details && err.details.length) {
      messageArray = [...err.details];
    }
    super(message || messageArray.join('    ') || messageDefault);
  }
}
