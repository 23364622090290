import React, { FC, memo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { AddWorkgroupMember } from 'shipment-operations/view/pages/ShipmentPeople/components';

import { AccountDepartmentDTM } from 'user-management/models/dtm';

import { EmptyStateForPages } from 'app-wrapper/view/components';
import {
  SubPageContent,
  SubPageHeader,
  SubPageHeaderContainer,
  SubPageHeaderTitle,
  Wrapper,
} from 'app-wrapper/view/guideline';

import { AccountDprtmTable } from './components';
import { AccountDrawer } from '../Account/components';

interface IAccountDepartmentsComponentProps {
  departments?: AccountDepartmentDTM[]
  isLoading?: boolean
  runComponent: () => void
  openAddMemberDrawer: () => void
  onClickDepartmentItem: (id: string) => () => void;
}

const AccountDepartmentsComponent: FC<IAccountDepartmentsComponentProps> = (props) => {
  const {
    departments,
    isLoading,
    runComponent,
    openAddMemberDrawer,
    onClickDepartmentItem,
  } = props;
  const { t } = useTranslation();

  useEffect(runComponent, []);

  return (
    <Wrapper data-class="AccountDepartmentsComponent_Wrapper">
      <SubPageHeader>
        <SubPageHeaderContainer>
          <SubPageHeaderTitle>
            {t('Departments')}
          </SubPageHeaderTitle>
        </SubPageHeaderContainer>
      </SubPageHeader>
      {!isLoading && !departments?.length ? null : (
        <SubPageContent>
          <AccountDprtmTable
            allData={departments}
            isLoad={isLoading}
            nextPageTasks={() => ''}
            onStart={() => ''}
            onClickDepartmentItem={onClickDepartmentItem}
          />
        </SubPageContent>
      )}

      <AddWorkgroupMember />
      <AccountDrawer />

      {!isLoading && !departments?.length ? (
        <EmptyStateForPages
          headerText={t('No members added yet.')}
          contentText={t('Begin adding members of your organization to this department.')}
          buttonText={t('Add member')}
          onClickButton={openAddMemberDrawer}
        />
      ) : null}
    </Wrapper>
  );
};

const AccountDepartmentsComponentCached = memo(AccountDepartmentsComponent);

export { AccountDepartmentsComponentCached as AccountDepartmentsComponent };
