import styled from 'styled-components';

import { Wrapper, FlexCol } from 'app-wrapper/view/guideline';

export const Page = styled(Wrapper)`
  padding: 16px 24px;
`;

export const Title = styled.h3`
  margin-bottom: 10px;
  font-size: 20px;
  line-height: 28px;
`;

export const Content = styled(FlexCol)`
  align-items: center;
  justify-content: center;
  height: 100%;
`;
