import {
  IsOptional, IsString, ValidateNested,
} from 'class-validator';
import { Type } from 'class-transformer';
import { BaseDTM } from 'proto/BaseDTM';

export interface IFAKExceptionsDTM {
  code: string
  description: string
  itemType: string
  key?: string
  title?: string
  children?: IFAKExceptionsDTM[] | null
}

export class FAKExceptionsDTM extends BaseDTM<IFAKExceptionsDTM> {
  @IsString()
  code: string

  @IsString()
  description: string

  @IsString()
  itemType: string

  @IsString()
  @IsOptional()
  key?: string

  @IsString()
  @IsOptional()
  title?: string

  @IsOptional()
  @ValidateNested()
  @Type(() => FAKExceptionsDTM)
  children?: FAKExceptionsDTM[] | null;
}
