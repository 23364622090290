import React, {
  FC,
  useCallback,
  useRef,
} from 'react';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useReactToPrint } from 'react-to-print';
import {
  FileTextOutlined,
  UserOutlined,
  CalendarOutlined,
} from '@ant-design/icons';

import {
  ValueBlockWithIcon,
  DownloadCurrentStep,
  MissingTextValue,
} from 'app-wrapper/view/guideline';
import { HBLDocumentPDF } from 'shipment-operations/view/components';

import {
  Row,
  InfoBlock,
  InfoBlockItem,
  DownloadBlock,
  Button,
  Spin,
} from './ClearedContent.styled';

interface IClearedContentComponentProps {
  isLoading: boolean;
  fileNamePrint?: string;
  fetchHBLPDFData: (shipmentId: string) => Promise<void>;
}

const OLD_TITLE = document.title;

export const ClearedContentComponent: FC<IClearedContentComponentProps> = ({
  fetchHBLPDFData,
  fileNamePrint,
  isLoading,
}) => {
  const { shipmentId = '' } = useParams();
  const { t } = useTranslation();
  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    // @ts-ignore
    content: () => componentRef.current,
    onAfterPrint: () => {
      document.title = OLD_TITLE;
    },
  });

  const handleDocumentPrint = useCallback(async () => {
    await fetchHBLPDFData(shipmentId);

    // @ts-ignore only for e2e test
    if (!window.Cypress) {
      document.title = fileNamePrint || OLD_TITLE;
      handlePrint();
    }
  }, [handlePrint, shipmentId, fetchHBLPDFData]);

  return (
    <Row>
      <div data-class="pdfLayout" style={{ display: 'none' }}>
        <HBLDocumentPDF fileNamePrint={fileNamePrint} ref={componentRef} />
      </div>

      <InfoBlock>
        <InfoBlockItem>
          <ValueBlockWithIcon
            icon={<FileTextOutlined />}
            label={t('File name')}
            value={(
              <DownloadCurrentStep
                onClick={!isLoading ? handleDocumentPrint : undefined}
                text={t('HBL Document')}
              />
            )}
          />
        </InfoBlockItem>

        <InfoBlockItem>
          <ValueBlockWithIcon
            icon={<UserOutlined />}
            label={t('Uploaded By')}
            value={t('Skypace')}
          />
        </InfoBlockItem>

        <InfoBlockItem>
          <ValueBlockWithIcon
            icon={<CalendarOutlined />}
            label={t('Uploaded')}
            value={<MissingTextValue />}
          />
        </InfoBlockItem>
      </InfoBlock>

      <DownloadBlock>
        <Button onClick={isLoading ? undefined : handleDocumentPrint}>
          {isLoading ? <Spin size="small" /> : t('Download')}
        </Button>
      </DownloadBlock>
    </Row>
  );
};
