import React, {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import Spin from 'antd/es/spin';

import {
  IShipmentDocumentDefaultStateDocumentsDTM,
  IShipmentDocumentUpdateStateDTM,
} from 'shipment-operations/models/dtm';
import { PermissionAttributePolicy } from 'shipment-operations/constants';

import {
  SDCFMainContentForm,
  SDCFMainContentFormDocument,
  SDCFMainContentFormDocumentFile,
  SDCFMainContentFormDocumentFileTitle,
  SDCFMainContentFormDocumentType,
  SDCFMainContentFormDocumentTypeTitle,
  SDCFMainContentFormNotes,
  SDCFMainContentFormNotesTextarea,
  SDCFMainContentFormNotesTitle,
  SDCFMainContentFormReference,
  SDCFMainContentFormReferenceInput,
  SDCFMainContentFormReferenceTitle,
  SDCFMainContentSpin,
} from './ShipmentDocumentsForm.styled';
import { SDCFMainContentFormDocumentComponent } from '../SDCFMainContentFormDocumentComponent';

interface IShipmentDocumentsAdditionalFormComponent {
  authToken: string
  shipmentId?: string | number
  updateState: IShipmentDocumentUpdateStateDTM
  isLoad: boolean
  updateDocumentType: (value: string, index: number) => void
  clearDocumentType: (index: number) => void
  onUploadDoneHandler: (props: { value: string, name: string }, index: number) => void
  onChangeNotesHandler: (notes: string) => void
  onChangeReferenceHandler: (reference: string) => void
  onRemoveItem: (index: number) => void
  setUpdateDocuments: (documents: IShipmentDocumentDefaultStateDocumentsDTM[]) => void
  additionalDocumentsPermissions?: PermissionAttributePolicy
}

export const ShipmentDocumentsAdditionalFormComponent: FC<IShipmentDocumentsAdditionalFormComponent> = ({
  shipmentId,
  authToken,
  updateState,
  isLoad,
  updateDocumentType,
  clearDocumentType,
  onUploadDoneHandler,
  onChangeNotesHandler,
  onChangeReferenceHandler,
  onRemoveItem,
  setUpdateDocuments,
  additionalDocumentsPermissions,
}) => {
  const { t } = useTranslation();

  const isEditDisabled = useMemo(() => additionalDocumentsPermissions !== PermissionAttributePolicy.WRITE, [additionalDocumentsPermissions]);

  const onRun = useCallback(() => {
    setUpdateDocuments([
      {
        documentType: null,
        uploadFiles: '',
        fileName: '',
      },
    ]);
  }, []);

  useEffect(() => {
    onRun();
  }, [onRun]);

  const componentSpin = useMemo(() => (
    <SDCFMainContentSpin>
      <Spin size="default" />
    </SDCFMainContentSpin>
  ), []);

  const onChangeNotes = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement>) => {
      if (!event?.target?.value && event?.target?.value !== '') {
        return;
      }
      onChangeNotesHandler(event.target.value);
    },
    [onChangeNotesHandler],
  );

  const onChangeReference = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (!event?.target?.value && event?.target?.value !== '') {
        return;
      }
      onChangeReferenceHandler(event.target.value);
    },
    [onChangeReferenceHandler],
  );

  return isLoad
    ? componentSpin
    : (
      <SDCFMainContentForm>

        <SDCFMainContentFormDocument>
          <SDCFMainContentFormDocumentType>
            <SDCFMainContentFormDocumentTypeTitle>
              {t('DocumentType')}
            </SDCFMainContentFormDocumentTypeTitle>
          </SDCFMainContentFormDocumentType>
          <SDCFMainContentFormDocumentFile>
            <SDCFMainContentFormDocumentFileTitle>
              {t('Document')}
            </SDCFMainContentFormDocumentFileTitle>
          </SDCFMainContentFormDocumentFile>
        </SDCFMainContentFormDocument>

        {updateState.documents.map((documentFileItem, index) => (
          <SDCFMainContentFormDocumentComponent
            key={`SDCFMainContentFormDocumentComponent_updateState_${index + 0}_${documentFileItem.fileName}`}
            documentFile={documentFileItem}
            index={index}
            authToken={authToken}
            shipmentId={shipmentId}
            clearDocumentType={clearDocumentType}
            onRemoveItem={onRemoveItem}
            updateDocumentType={updateDocumentType}
            onUploadDoneHandler={onUploadDoneHandler}
            disabled={isEditDisabled}
          />
        ))}

        <SDCFMainContentFormNotes>
          <SDCFMainContentFormNotesTitle>
            {t('Notes')}
          </SDCFMainContentFormNotesTitle>
          <SDCFMainContentFormNotesTextarea
            value={updateState.notes}
            onChange={onChangeNotes}
            placeholder={t('TypeYourCommentsHereDots')}
            disabled={isEditDisabled}
          />
        </SDCFMainContentFormNotes>

        <SDCFMainContentFormReference>
          <SDCFMainContentFormReferenceTitle>
            {t('ShipmentReference')}
          </SDCFMainContentFormReferenceTitle>
          <SDCFMainContentFormReferenceInput
            disabled={isEditDisabled}
            value={updateState.reference}
            onChange={onChangeReference}
          />
        </SDCFMainContentFormReference>

      </SDCFMainContentForm>
    );
};
