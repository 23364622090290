import styled from 'styled-components';
import CloseCircleOutlined from '@ant-design/icons/CloseCircleOutlined';
import { divFlex } from 'app-wrapper/view/themes/mixin';

export const CanceledWrap = styled.div.attrs({
  'data-class': 'ShipmentCanceledWrap',
})`
  ${divFlex}
  width: 100%;
  padding: 12px 16px 12px 16px;
  flex-direction: row;
  background-color: ${({ theme }) => theme?.themesColors?.redBranding1};
  border: 1px solid ${({ theme }) => theme?.themesColors?.dustRed};
  border-radius: 2px;
`;

export const CanceledContent = styled.div.attrs({
  'data-class': 'CanceledContent',
})`
  ${divFlex}

  justify-content: center;
  flex-direction: column;
`;

export const CanceledHeader = styled.div.attrs({
  'data-class': 'ShipmentCanceledHeader',
})`
  ${divFlex}
  margin-bottom: 8px;

  white-space: pre-line;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
`;

export const CanceledText = styled.div.attrs({
  'data-class': 'ShipmentCanceledText',
})`
  ${divFlex}
  margin-bottom: 5px;

  white-space: pre-line;
  font-size: 14px;
  line-height: 22px;
`;

export const CanceledIcon = styled(CloseCircleOutlined)`
  margin-top: 2px;
  margin-right: 16px;
  color: ${({ theme }) => theme?.themesColors?.characterBrandingDanger};
  font-size: 21px;
`;
