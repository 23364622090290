import styled from 'styled-components';

import themesColors from 'app-wrapper/view/themes/themesColors';

export const UserMenuContainer = styled.div.attrs({
  'data-class': 'UserMenuContainer',
})`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  
  padding: 4px 0;
  width: 288px;
  
  align-self: stretch;
`;

export const OrganizationHeader = styled.div.attrs({
  'data-class': 'OrganizationHeaderText',
})`
  display: flex;
  flex-flow: row nowrap;
  justify-content: stretch;
  align-items: center;
  margin-top: 18px;
  margin-bottom: 10px;
  width: 100%;
  height: 20px;
  
  color: ${themesColors.darkGrayMiddle};
  
  span {
    font-size: 12px;
  }
`;

export const OrganizationHeaderLine = styled.div.attrs({
  'data-class': 'OrganizationHeaderLine',
})`
  margin-left: 16px;
  border: 1px solid ${themesColors.neutralBranding4};
  height: 1px;
  width: 185px;
`;
