import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { UC } from 'shipment-operations/controllers';
import { R } from 'shipment-operations/repository';
import { PermissionAttributePolicy } from 'shipment-operations/constants';

import { ShippingPartiesContactPersonComponent } from './ShippingPartiesContactPerson.component';

const ShippingPartiesContactPersonContainer: FC = () => {
  const selectedContact = useSelector(R.selectors.shippingParties.getSelectedContact);
  const selectedCurrentContact = useSelector(R.selectors.shippingParties.getSelectedCurrentContact);
  const contactError = useSelector(R.selectors.shippingParties.getContactErrorFromSelectedForm);
  const contactList = useSelector(R.selectors.shippingParties.getContactList);
  const isSelectedCompanyRelatesToUser = useSelector(R.selectors.shippingParties.getIsSelectedCompanyRelatesToUser);

  return (
    <ShippingPartiesContactPersonComponent
      contactList={contactList}
      availability={PermissionAttributePolicy.WRITE}
      selectedContact={selectedContact}
      selectedCurrentContact={selectedCurrentContact}
      contactError={contactError}
      setContact={UC.shippingParties.setContactPerson}
      onBlur={UC.shippingParties.validateContactPerson}
      openAddContactDrawer={UC.bookingWizardDrawer.openSPAddContactDrawer}
      isSelectedCompanyRelatesToUser={isSelectedCompanyRelatesToUser}
    />
  );
};

export {
  ShippingPartiesContactPersonContainer as ShippingPartiesContactPerson,
};
