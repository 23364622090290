import React, { FC, useCallback } from 'react';
import Collapse from 'antd/es/collapse';
import { useTranslation } from 'react-i18next';

import { StyledExpandIcon } from 'shipment-operations/view/components';

import { useToggleCollapse } from 'shipment-operations/view/hooks/useToggleCollapse';

import { ContainerViewDTM } from 'shipment-operations/models/dtm';

import { Checkbox } from 'shipment-operations/view/pages/CreateInvoice/components/Checkbox';
import {
  ContainerTitleWrapper, ContainerType, MainPanelSum,
  PanelTitleWrapper, StyledPanel, CheckboxWrapper,
} from './ExpandPanelSelectable.styled';

interface IExpandPanelProps {
  container: ContainerViewDTM
  sum: number
  selectedCharges: number[]
  onSubTableSelect: (id: number[]) => void
  keys: number[]
}

const ExpandPanelSelectable: FC<IExpandPanelProps> = ({
  container, sum, selectedCharges,
  onSubTableSelect, keys, children,
}) => {
  const { t } = useTranslation();
  const { openedKeys, onToggleCollapse } = useToggleCollapse();
  const onToggle = useCallback((key) => () => onToggleCollapse(key), [onToggleCollapse]);
  const handleSubTableClick = useCallback(() => onSubTableSelect(keys), [onSubTableSelect, keys]);

  return (
    <Collapse ghost expandIcon={StyledExpandIcon} onChange={onToggle(container.id)} key={container.id}>
      <StyledPanel
        key={container.id}
        header={(
          <PanelTitleWrapper active={openedKeys.includes(container.id)}>
            <CheckboxWrapper>
              <Checkbox
                mLeft="2.5px"
                onChange={handleSubTableClick}
                checked={keys.every((item) => selectedCharges.includes(item))}
              />
              <ContainerTitleWrapper>
                <ContainerType
                  active={openedKeys.includes(container.id)}
                >
                  {container.type} &apos;
                </ContainerType>
                <span>{container.number ? container.number : t('Number pending entry')}</span>
              </ContainerTitleWrapper>
            </CheckboxWrapper>
            <MainPanelSum active={openedKeys.includes(container.id)}>
              $ {sum.toFixed(2)}
            </MainPanelSum>
          </PanelTitleWrapper>
        )}
      >
        {children}
      </StyledPanel>
    </Collapse>
  );
};

export { ExpandPanelSelectable };
