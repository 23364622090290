import React from 'react';
import { observer } from 'mobx-react-lite';

import { useMobxStore } from 'app-wrapper/mobxStores';
import { FeesAndServicesChargeChangesTable } from 'app-wrapper/view/guideline';

export const FeesAndServices = observer(() => {
  const { shipmentCostChangesDrawer } = useMobxStore();

  return (
    <FeesAndServicesChargeChangesTable charges={shipmentCostChangesDrawer.state.bolCharges} />
  );
});
