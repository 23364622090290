import React from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';

import { FooterComponent } from './Footer.component';

const Footer = () => {
  const invoice = useSelector(R.selectors.shipmentBillingInvoice.getInvoice);
  const isVATVisible = useSelector(R.selectors.shipmentBillingInvoice.getIsVATVisible);
  const relatedAdmin = useSelector(R.selectors.shipmentBillingInvoice.getRelatedAdmin);

  return (
    <FooterComponent
      invoice={invoice}
      isVATVisible={isVATVisible}
      isAdminFromUS={relatedAdmin?.getIsAdminFromUS() || false}
    />
  );
};

export { Footer };
