import React, { FC, useEffect } from 'react';
import Spin from 'antd/es/spin';
import { useTranslation } from 'react-i18next';
import Divider from 'antd/es/divider';

import { useBlocker } from 'app-wrapper/hooks';
import { PermissionAttributePolicy } from 'shipment-operations/constants';
import {
  ShipmentPreviewDTM,
  IShipmentDocumentDefaultStateDTM,
} from 'shipment-operations/models/dtm';

import {
  SDCLoaderWrapper,
  SDCMainContent,
  SDCMainContentTitle,
  SDCDividerMargins,
  SDCMainContentWrapper,
  SDCShipmentDetailsFooter,
} from './ShipmentDocumentsAdditional.styled';

import { ShipmentDocumentsAdditionalForm, ShipmentDocumentsFooter } from './components';

interface IShipmentDocumentsAdditionalComponentProps {
  authToken: string
  shipment?: ShipmentPreviewDTM;
  shipmentDocumentDefault: IShipmentDocumentDefaultStateDTM;
  isLoading: boolean
  disabledDiscard?: boolean
  disabledUpdate?: boolean
  onDiscardHandler: () => void
  onUpdateHandler: () => void
  onLeavePage: () => void
  onEnterPage: () => void
  getRunAdditionalDetails: (shipmentName: number) => void
  additionalDocumentsPermission?: PermissionAttributePolicy
}

export const ShipmentDocumentsAdditionalComponent: FC<IShipmentDocumentsAdditionalComponentProps> = (props) => {
  const {
    authToken,
    shipment,
    shipmentDocumentDefault,
    isLoading,
    disabledDiscard,
    disabledUpdate,
    onDiscardHandler,
    onUpdateHandler,
    onLeavePage,
    onEnterPage,
    getRunAdditionalDetails,
    additionalDocumentsPermission,
  } = props;
  const { t } = useTranslation();

  useEffect(() => {
    onEnterPage();
  }, []);

  useEffect(() => {
    getRunAdditionalDetails(Number(shipment?.shipmentName));
  }, [shipment?.shipmentName, shipmentDocumentDefault.isRequest]);

  useBlocker(
    onLeavePage,
    () => {},
    !disabledUpdate,
  );

  return (isLoading
    ? (
      <SDCLoaderWrapper>
        <Spin size="large" />
      </SDCLoaderWrapper>
    ) : (
      <SDCMainContent>
        <SDCMainContentWrapper>
          <SDCMainContentTitle>
            {t('AdditionalDetails')}
          </SDCMainContentTitle>
          <Divider dashed style={SDCDividerMargins} />
          <ShipmentDocumentsAdditionalForm
            authToken={authToken}
            shipmentId={shipment?.shipmentName}
          />
        </SDCMainContentWrapper>
        <SDCShipmentDetailsFooter>
          <ShipmentDocumentsFooter
            discardChanges={onDiscardHandler}
            disabledDiscard={disabledDiscard}
            updateParties={onUpdateHandler}
            disabledUpdate={disabledUpdate}
            additionalDocumentsPermission={additionalDocumentsPermission}
          />
        </SDCShipmentDetailsFooter>
      </SDCMainContent>
    )
  );
};
