import styled from 'styled-components';
import Typography from 'antd/es/typography';

export const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 24px;
`;

export const Title = styled(Typography)`
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
`;

export const Warning = styled.div`
  margin-top: 8px;
  margin-bottom: -16px;
`;
