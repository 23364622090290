import React, {
  FC,
  SyntheticEvent,
  useCallback,
  useMemo,
} from 'react';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';

import { DocumentDTM } from 'shipment-operations/models/dtm';
import { ExpandIcon } from 'app-wrapper/view/components';
import themesColors from 'app-wrapper/view/themes/themesColors';

import {
  DocumentCollapse,
  CollapsePanel,
  CollapseIcon,
  CollapseIconActive,
  CollapseLabel,
  CollapseLabelText,
  TableCell,
  TableDocuments,
  BoldText,
  UploadIcon,
  SuccessIcon,
  WaitingIcon,
} from './DocumentsSection.styled';
import { ActionMenu } from './components';

interface DocumentsSectionProps {
  sectionName: string;
  isCollapsed?: boolean;
  documents: DocumentDTM[];
  isOrgAdmin: boolean;
  onDownload: (organizationId: string, documentId: number) => void;
  onRemove: (organizationId: string, documentId: number) => void;
  openDocumentUploadDrawer: (e: SyntheticEvent) => void;
}

export const DocumentsSection: FC<DocumentsSectionProps> = ({
  sectionName,
  documents,
  isOrgAdmin,
  isCollapsed,
  onDownload,
  onRemove,
  openDocumentUploadDrawer,
}) => {
  const { t } = useTranslation();
  const { customerId = '' } = useParams();

  const columns = useMemo(() => [
    {
      title: t('Document Name'),
      width: '50%',
      key: 'object',
      align: 'left' as 'left',
      render: (item: string, record: DocumentDTM | {}) => {
        const document = record as DocumentDTM;

        return (
          <BoldText>
            {document.name}
          </BoldText>
        );
      },
    },
    {
      title: t('Uploaded Date'),
      width: '20%',
      key: 'object',
      align: 'left' as 'left',
      render: (item: string, record: DocumentDTM | {}) => {
        const document = record as DocumentDTM;

        return (
          <TableCell>
            {document.createdAt ? document.createdAt.getDateDMMMYYYYHHmmWithOffset() : '-'}
          </TableCell>
        );
      },
    },
    {
      title: t('Uploaded By'),
      width: '20%',
      key: 'object',
      align: 'left' as 'left',
      render: (item: string, record: DocumentDTM | {}) => {
        const document = record as DocumentDTM;

        return (
          <TableCell>
            {document.createdBy || '-'}
          </TableCell>
        );
      },
    },
    {
      title: t('Action'),
      width: '10%',
      key: 'object',
      align: 'right' as 'right',
      render: (item: string, record: DocumentDTM | {}) => {
        const document = record as DocumentDTM;

        const handleDelete = onRemove ? () => onRemove(customerId, document.id) : undefined;
        const handleDownload = onDownload ? () => onDownload(customerId, document.id) : undefined;

        return (
          <TableCell>
            <ActionMenu
              onDownload={handleDownload}
              onDelete={handleDelete}
            />
          </TableCell>
        );
      },
    },
  ], [t]);

  const onExpandIcon = useCallback(
    ({ isActive }) => ExpandIcon({
      isActive,
      color: `${isActive ? themesColors.primaryBranding6 : themesColors.neutralBranding7}`,
    }),
    [],
  );

  return (
    <DocumentCollapse expandIcon={onExpandIcon}>
      <CollapsePanel
        key={sectionName}
        header={(
          <CollapseLabel>
            {isCollapsed ? (
              <CollapseIconActive />
            ) : (
              <CollapseIcon />
            )}

            <CollapseLabelText isCollapsed={isCollapsed}>
              {sectionName}

              {documents.length ? (
                <SuccessIcon />
              ) : (
                <WaitingIcon />
              )}
            </CollapseLabelText>

            {isOrgAdmin ? (
              <UploadIcon onClick={openDocumentUploadDrawer} />
            ) : null}
          </CollapseLabel>
        )}
      >
        <TableDocuments
          pagination={false}
          columns={columns}
          dataSource={documents}
        />
      </CollapsePanel>
    </DocumentCollapse>
  );
};
