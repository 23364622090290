const FILE_NAME = 'markup-policy.xlsx';

export const downloadBlobAsFile = (file: Blob) => {
  const href = URL.createObjectURL(file);
  const link = document.createElement('a');

  link.href = href;
  link.setAttribute('download', FILE_NAME);
  document.body.appendChild(link);
  link.click();

  document.body.removeChild(link);
  URL.revokeObjectURL(href);
};
