import React, {
  ChangeEvent,
  FC,
  useCallback,
  useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'app-wrapper/view/components';

import { PermissionAttributePolicy } from 'shipment-operations/constants';

import {
  ContainerSealNumberField, ContainerSealNumberWrapper,
} from 'shipment-operations/view/pages/ShipmentContainerData/components/ShipmentContainerReferenceField/ShipmentContainerReferenceField.styled';

interface IShipmentContainerSealNumberFieldComponentProps {
  value?: string
  error?: Error
  disabled: boolean
  onChange: (value: string) => void
  onBlur: () => void
  containersAvailability?: PermissionAttributePolicy
}

export const ShipmentContainerReferenceFieldComponent: FC<IShipmentContainerSealNumberFieldComponentProps> = ({
  value,
  disabled,
  onChange,
  onBlur,
  error,
  containersAvailability,
}) => {
  const { t } = useTranslation();

  const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value);
  }, []);

  const handleBlur = useCallback(() => {
    onBlur();
  }, []);

  const isDisabled = useMemo(() => containersAvailability !== PermissionAttributePolicy.WRITE || disabled, [containersAvailability, disabled]);

  return (
    <Tooltip mode="danger" placement="bottom" title={error?.message} visible={!!error}>
      <ContainerSealNumberWrapper>
        <span>{ t('Reference') }</span>
        <ContainerSealNumberField
          value={value}
          disabled={isDisabled}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </ContainerSealNumberWrapper>
    </Tooltip>
  );
};
