import React from 'react';

import {
  Controller,
  FieldPath,
  useFormContext,
} from 'react-hook-form';
import { InputProps } from 'antd/es/input';

import { Input } from './InputController.styled';

export interface IInputControllerProps extends InputProps {
  name: FieldPath<any>
}

export const InputController: React.FC<IInputControllerProps> = ({
  name,
  onChange: onChangeCustom,
  onBlur: onBlurCustom,
  ...other
}) => {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      render={({
        field: {
          ref, value, onChange, onBlur,
        },
      }) => {
        const handleChange: InputProps['onChange'] = (event) => {
          if (onChangeCustom) {
            onChangeCustom(event);
          }
          onChange(event.target.value);
        };

        const handleBlur: InputProps['onBlur'] = (event) => {
          if (onBlurCustom) {
            onBlurCustom(event);
          }
          onBlur();
        };

        return (
          <Input
            ref={ref}
            className="Input"
            name={name}
            value={value}
            onChange={handleChange}
            onBlur={handleBlur}
            {...other}
          />
        );
      }}
    />
  );
};
