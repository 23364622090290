import styled from 'styled-components';

import { colors } from 'app-wrapper/view/themes/themesColors';

export const ShippingPartyCardsLayout = styled.div`
  padding: 16px;

  background: white;
  border: 1px solid ${colors.purple15};
  border-radius: 3px;
`;

export const Title = styled.span`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
`;

export const CardTitle = styled.span`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
`;

export const Content = styled.div`
  margin-top: 16px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const Gap = styled.div`
  height: 10px;
`;

export const Card = styled.div`
  width: 30%;
  height: fit-content;
`;
