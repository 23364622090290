import styled from 'styled-components';

export const ContactCustomersSvgWrapper = styled.span.attrs((props: {
  width?: number | string
}) => ({
  width: props.width,
  'data-class': 'ContactCustomersSvgWrapper',
}))`
  ${({ width }) => width && `width: ${width}px;`}
  color: ${({ theme }) => theme?.themesColors?.primaryBranding6};
`;
