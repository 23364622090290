import {
  IsOptional,
  IsString,
  IsDefined,
  ValidateNested,
} from 'class-validator';
import { Type } from 'class-transformer';
import { BaseDTM } from 'proto/BaseDTM';

interface IFreightDoorsAutocompleteLocationOptionsDTM {
  description: string
  value: string
  key: string
}

export class FreightDoorsAutocompleteLocationOptionsDTM extends BaseDTM<IFreightDoorsAutocompleteLocationOptionsDTM> {
  @IsDefined()
  @IsString()
  description: string;

  @IsDefined()
  @IsString()
  value: string;

  @IsDefined()
  @IsString()
  key: string;
}

interface IFreightDoorsAutocompleteLocationDTM {
  value?: string
  status?: string
  options?: IFreightDoorsAutocompleteLocationOptionsDTM[]
}

export class FreightDoorsAutocompleteLocationDTM extends BaseDTM<IFreightDoorsAutocompleteLocationDTM> {
  @IsOptional()
  @IsString()
  value?: string;

  @IsOptional()
  @IsString()
  status?: string;

  @IsOptional()
  @ValidateNested({ each: true })
  @Type(() => FreightDoorsAutocompleteLocationOptionsDTM)
  options?: FreightDoorsAutocompleteLocationOptionsDTM[];
}
