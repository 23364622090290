import styled from 'styled-components';
import Popover from 'antd/es/popover';
import Typography from 'antd/es/typography';
import { ExclamationCircleFilled } from '@ant-design/icons';

import { Button } from 'app-wrapper/view/components';

export const Popup = styled(Popover)``;

export const Content = styled.div`
  width: 236px;
  padding: 12px;
  display: flex;
  flex-direction: column;
`;

export const WarningIcon = styled(ExclamationCircleFilled)`
  margin-top: 4px;
  color: rgba(250, 173, 20, 1);
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Text = styled(Typography)`
  margin-left: 8px;
  font-size: 14px;
  line-height: 22px;
`;

export const OrgButton = styled(Button)`
  height: 24px;
  margin-left: auto;
  margin-top: 8px;
  cursor: pointer;
`;
