import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'antd/es/skeleton';

import { CustomModal } from 'shipment-operations/view/components';

import {
  Footer,
  NRAAgreement,
  SkypaceTermsAndConditions,
} from './components';

interface ITermsAgreementDrawerComponentProps {
  isOpen: boolean
  onClose: () => void
  isLoading: boolean
  showNRA: boolean
}

export const TermsAgreementDrawerComponent: FC<ITermsAgreementDrawerComponentProps> = ({
  isOpen,
  onClose,
  isLoading,
  showNRA,
}) => {
  const { t } = useTranslation();

  return isOpen ? (
    <CustomModal
      title={t('Terms & Conditions')}
      onClose={onClose}
      contentStyles={{
        left: 'unset',
        width: '1137px',
      }}
      childrenWrapperStyles={{
        position: 'relative',
        height: 'calc(100vh - 56px)',
        overflow: 'hidden',
      }}
    >
      {isLoading ? (
        <Skeleton />
      ) : (
        <>
          {showNRA ? <NRAAgreement /> : null}

          <SkypaceTermsAndConditions />
        </>
      )}

      <Footer />
    </CustomModal>
  ) : null;
};
