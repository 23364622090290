import styled from 'styled-components';

import { ShipmentDrawerWrapper } from 'shipment-operations/view/components/ShipmentTrackerHeader/ShipmentTrackerHeader.styled';

export const Drawer = styled(ShipmentDrawerWrapper)`
  .ant-drawer-body {
    background-color: white;
  }

  button {
    height: 32px !important;
    min-height: 32px !important;
  }
`;
