import React, {
  useContext, useRef, useState, useEffect,
} from 'react';
import Form from 'antd/es/form';
import moment from 'moment';
import get from 'lodash/fp/get';
import WarningFilled from '@ant-design/icons/WarningFilled';
import { Tooltip } from 'app-wrapper/view/components';

import { EditableContext } from 'shipment-operations/view/components/EditableRow';
import { ChildrenWrapper } from 'shipment-operations/view/pages/CreateCreditNote/components/SecondStep/components/CustomTableEditable/components/Cell/Cell.styled';

import {
  EditableCellWrapper, StyledCalendar, StyledInput, StyledLabel, StyledDatePicker,
} from './EditableTable.styled';

const timeFormat = 'D MMM YYYY, HH:mm';

interface IRecordProps {
  id?: string
  dueDate?: string
  total?: number
  balance?: number
  payment?: number
  innerId?: number
  key: string
  fieldName: string
}

interface EditableCellProps {
  editable: boolean;
  children: React.ReactNode;
  dataIndex: string
  record: IRecordProps
  handleSave: (record: IRecordProps, type: string) => void;
  label?: string
  isMismatch?: boolean
  isError?: boolean
  emptyRender?: boolean
  datesError?: boolean
}

const EditableCell: React.FC<EditableCellProps> = ({
  dataIndex,
  record,
  editable,
  children,
  label,
  isMismatch,
  isError,
  datesError,
  emptyRender,
  handleSave,
  ...restProps
}) => {
  const isDate = get(['isDate'], record);
  const [editing, setEditing] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const form = useContext(EditableContext)!;

  useEffect(() => {
    if (editing) {
      inputRef.current!.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    if (isDate) {
      form.setFieldsValue({ [dataIndex]: record[dataIndex as keyof typeof record] ? moment(record[dataIndex as keyof typeof record]) : moment().format(timeFormat) });
    } else {
      form.setFieldsValue({ [dataIndex]: record[dataIndex as keyof typeof record] });
    }
  };

  const save = async () => {
    try {
      const values = await form.validateFields();
      values.carrierConfirmed = moment(values.carrierConfirmed).format('D MMM YYYY, HH:mm');
      toggleEdit();
      handleSave({ ...record, ...values }, dataIndex);
    } catch (errInfo) {
      const value = {
        [dataIndex]: 0,
      };
      handleSave({ ...record, ...value }, dataIndex);
      toggleEdit();
    }
  };

  const saveInput = async () => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      handleSave({ ...record, ...values }, dataIndex);
    } catch (errInfo) {
      const value = {
        [dataIndex]: 0,
      };
      handleSave({ ...record, ...value }, dataIndex);
      toggleEdit();
    }
  };

  if (emptyRender) {
    return <td {...restProps} />;
  }

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{ margin: 0 }}
        name={dataIndex}
      >
        {isDate ? (
          <StyledDatePicker
            // @ts-ignore
            ref={inputRef}
            onBlur={save}
            format={timeFormat}
            showTime
            isMismatches={isMismatch}
            allowClear={false}
          />
        ) : (
          <StyledInput
            // @ts-ignore
            ref={inputRef}
            onBlur={saveInput}
            isMismatches={isMismatch}
          />
        )}
      </Form.Item>
    ) : (
      <>
        {datesError ? (
          <Tooltip placement="top" mode="danger" title="ETD must be earlier than ETA in the same Leg">
            <EditableCellWrapper onClick={toggleEdit} id="editableCell" style={{ borderColor: 'red' }}>
              {children}
              {isDate && (<StyledCalendar />)}
            </EditableCellWrapper>
          </Tooltip>
        ) : (
          <EditableCellWrapper onClick={toggleEdit} id="editableCell" style={{ borderColor: isError ? 'red' : '' }}>
            {children}
            {isDate && (<StyledCalendar />)}
          </EditableCellWrapper>
        )}
      </>
    );
  }

  return (
    <td {...restProps}>
      <ChildrenWrapper>
        {label && (<StyledLabel>{label}</StyledLabel>)}
        {isMismatch && (<WarningFilled style={{ color: '#F1AE00' }} />)}
        {childNode}
      </ChildrenWrapper>
    </td>
  );
};

export { EditableCell };
