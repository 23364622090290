import React from 'react';

import { useAppSelector } from 'app-wrapper/hooks';
import { R } from 'shipment-operations/repository';

import { PaymentsComponent } from './Payments.component';

const PaymentsContainer = () => {
  const data = useAppSelector(R.selectors.shipmentBillingInvoice.getPaymentsTable);

  return (
    <PaymentsComponent data={data} />
  );
};

export { PaymentsContainer as Payment };
