import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ContainerCardCargoDataContainer } from './ContainerCardCargoData.styled';

interface IContainerCardCargoDataProps {
  packagesNumber?: string
  weight?: string
  volume?: string
}

export const ContainerCardCargoData: FC<IContainerCardCargoDataProps> = ({
  packagesNumber,
  weight,
  volume,
}) => {
  const { t } = useTranslation();

  return (
    <ContainerCardCargoDataContainer>
      <span>{packagesNumber || 0} {t('pcs')}</span>
      <span>{weight || 0} {t('kg')}</span>
      <span>{volume || 0} {t('cbm')}</span>
    </ContainerCardCargoDataContainer>
  );
};
