import { BellGoldSvg, BellSvg } from 'app-wrapper/view/icons';
import styled, { css } from 'styled-components';
import { divFlex, RFQItemblockBorder, textColorSecond } from './main';

const cutoffMini = css`
flex-direction: column;
align-items: center;
`;

export const RFQItemCutoff = styled
  .div
  .attrs(
    (props: {
      isMiniVisible: boolean,
    }) => ({
      isMiniVisible: props.isMiniVisible,
    }),
  )`
  ${divFlex}
  ${RFQItemblockBorder}
  align-items: center;
  padding: ${(props) => (props.isMiniVisible ? '4px' : '5px')} 16px;
  height: ${(props) => (props.isMiniVisible ? '48px' : '32px')};
`;

export const RFQItemCutoffTitle = styled
  .div
  .attrs(
    (props: {
      isMiniVisible: boolean,
    }) => ({
      isMiniVisible: props.isMiniVisible,
    }),
  )`
  ${divFlex}
  align-items: center;
  ${(props) => !props.isMiniVisible && 'min-width: 114px;'}
  ${(props) => props.isMiniVisible && cutoffMini};
  border-right: 1px solid ${({ theme }) => theme.themesColors.neutralBranding3};
`;

export const RFQItemCutoffTitleIconDiv = styled.div`
${divFlex}
align-items: center;
margin-right: 5px;
`;

export const RFQItemCutoffTitleIcon = styled(BellSvg)`
${divFlex}
`;

export const RFQItemCutoffGoldTitleIcon = styled(BellGoldSvg)`
${divFlex}
`;

export const RFQItemCutoffTitleText = styled
  .div
  .attrs(
    (props: {
      isMiniVisible: boolean,
    }) => ({
      isMiniVisible: props.isMiniVisible,
    }),
  )`
  ${divFlex}
  ${(props) => !props.isMiniVisible && 'margin-left: 14px;'}
`;

export const RFQItemCutoffToPort = styled
  .div
  .attrs(
    (props: {
      isMiniVisible: boolean,
    }) => ({
      isMiniVisible: props.isMiniVisible,
    }),
  )`
  ${divFlex}
  ${(props) => !props.isMiniVisible && 'min-width: 143px;'}
  ${(props) => props.isMiniVisible && cutoffMini}
  margin-left: auto;
  justify-content: end;
`;

export const RFQItemCutoffToPortName = styled.div`
${divFlex}
${textColorSecond}
`;

export const RFQItemCutoffToPortContent = styled.div`
${divFlex}
margin-left: 8px;
`;

export const RFQItemCutoffToDoc = styled
  .div
  .attrs(
    (props: {
      isMiniVisible: boolean,
    }) => ({
      isMiniVisible: props.isMiniVisible,
    }),
  )`
  ${divFlex}
  ${(props) => !props.isMiniVisible && 'min-width: 179px;'}
  ${(props) => props.isMiniVisible && cutoffMini}
  justify-content: end;
  margin-left: 6.5%;
`;

export const RFQItemCutoffToDocName = styled.div`
${divFlex}
${textColorSecond}
`;

export const RFQItemCutoffToDocContent = styled.div`
${divFlex}
margin-left: 4px;
`;

export const RFQItemCutoffToHazmat = styled
  .div
  .attrs(
    (props: {
      isMiniVisible: boolean,
    }) => ({
      isMiniVisible: props.isMiniVisible,
    }),
  )`
  ${divFlex}
  ${textColorSecond}
  // ${(props) => !props.isMiniVisible && 'min-width: 133px;'}
  ${(props) => props.isMiniVisible && cutoffMini}
  margin-left: 6.5%;
  justify-content: end;
`;

export const RFQItemCutoffToHazmatName = styled.div`
${divFlex}
${textColorSecond}
`;

export const RFQItemCutoffToHazmatContent = styled.div`
${divFlex}
margin-left: 4px;
`;
