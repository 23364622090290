import styled from 'styled-components';
import AntDivider from 'antd/es/divider';

import {
  FlexRow,
  FlexCenter,
  SubPageContent,
  FlexCol,
  BodyTextMedium,
  BodyTextRegular,
} from 'app-wrapper/view/guideline';

export const Details = styled(FlexRow)`
  margin-bottom: 8px;
  gap: 8px;
`;

export const AlertsWrap = styled.div`
  margin-bottom: 8px;
`;

export const Card = styled(SubPageContent)`
  width: 50%;
  flex-direction: row;
  align-items: center;
  padding: 8px;
`;

export const CardNumberWrap = styled(FlexCenter)`
  margin-left: 12px;
  width: 38px;
  height: 94px;
`;

export const CardNumber = styled.span`
  font-size: 24px;
  line-height: 32px;
  font-weight: 500;
  color: rgba(207, 209, 214, 1);
`;

export const Divider = styled(AntDivider)`
  margin: 0 20px;
  height: 94px;
`;

export const Image = styled.img`
  width: 56px;
  height: 56px;
`;

export const Information = styled(FlexCol)`
  margin-left: 20px;
`;

export const InformationTitle = styled.span`
  ${BodyTextMedium}
`;

export const InformationText = styled.span`
  ${BodyTextRegular}
  color: rgba(115, 129, 155, 1);
`;
