import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { TeamMemberDTM } from 'user-management/models/dtm';
import { TeamMembers, TeamName } from 'user-management/view/pages/Teams/components/TeamsTable/components';
import { DEPARTMENT_TYPE_NAMES } from 'user-management/constants';

import {
  Content,
  Title,
  Text,
  Table,
} from './MemberTeamsTable.styled';

interface MemberTeamsTableComponentProps {
  teams: TeamMemberDTM[];
}

export const MemberTeamsTableComponent: FC<MemberTeamsTableComponentProps> = ({ teams }) => {
  const { t } = useTranslation();

  const columns = useMemo(() => ([
    {
      title: 'Team',
      dataIndex: 'team',
      key: 'team',
      width: '33%',
      render: (item: string, record: TeamMemberDTM | {}) => {
        const team = record as TeamMemberDTM;

        return team?.team ? (
          <TeamName id={team.team.id} name={team.team.name} />
        ) : null;
      },
    },
    {
      title: 'Role',
      dataIndex: 'customers',
      key: 'customers',
      width: '33%',
      render: (item: string, record: TeamMemberDTM | {}) => {
        const team = record as TeamMemberDTM;

        return (
          <Text>
            {DEPARTMENT_TYPE_NAMES[team.role]}
          </Text>
        );
      },
    },
    {
      title: 'Members',
      dataIndex: 'members',
      key: 'members',
      width: '33%',
      align: 'left' as 'left',
      render: (item: string, record: TeamMemberDTM | {}) => {
        const team = record as TeamMemberDTM;

        return <TeamMembers membersCount={team?.team?.membersCount || 0} />;
      },
    },
  ]), []);

  return (
    <Content>
      <Title>
        {t('Teams')}
      </Title>

      <Table
        dataSource={teams}
        columns={columns}
        pagination={false}
      />
    </Content>
  );
};
