import i18n from 'app-wrapper/i18n/i18n';
import { NotificationMessageError } from 'app-wrapper/models/errors/NotificationMessageError';
import { currentSession } from 'app-wrapper/utils';
import { Auth } from '@aws-amplify/auth';

import { IErrorExceptionDTM } from 'app-wrapper/models/contracts';

import {
  ISignInDTM,
  ISignUpDTM,
  ISignInResponseDTM,
  ISignUpResponseDTM,
  UserAuthDataDTM,
} from 'authentication/models/dtm';
import {
  TChangePasswordErrorContract,
  TForgotPasswordErrorContract,
  TGetUserDataErrorContract,
} from 'authentication/models/contracts';
import { ErrorNoCurrentUser } from 'authentication/models/errors';

export class AuthService {
  public signUpAuth = async ({
    firstName, lastName, email, password,
  }: ISignUpDTM) => {
    try {
      let result: ISignUpResponseDTM | null = null;
      const response = await Auth.signUp({
        username: email,
        password,
        attributes: {
          given_name: firstName,
          family_name: lastName,
        },
      });

      result = {
        username: response?.user.getUsername() || '',
      };
      return result;
    } catch (e: unknown) {
      throw new Error(`${(e as IErrorExceptionDTM)?.message || 'Something wrong, please try again'}`);
    }
  };

  public signInAuth = async ({ email, password }: ISignInDTM) => {
    try {
      const response = await Auth.signIn(email, password);

      const userDataRaw = response.getIdToken ? response.getIdToken().payload : response.signInUserSession.idToken.payload;

      const userData = UserAuthDataDTM.fromPlain({
        email: userDataRaw?.email,
        firstName: userDataRaw?.given_name,
        lastName: userDataRaw?.family_name,
        isAdmin: userDataRaw['cognito:groups']?.includes('FreightuneAdmin'),
      });

      if (!userData.isValid()) {
        console.error(userData.validate());
      }

      return userData;
    } catch (e: unknown) {
      throw new Error(`${(e as IErrorExceptionDTM)?.message || 'Something wrong, please try again'}`);
    }
  };

  public outAuth = async () => {
    try {
      currentSession.signOut();
    } catch (e) {
      throw new Error('Something wrong, please try again');
    }
  };

  public setLoggedIn = async () => {
    try {
      const x = await Auth.currentSession();

      return x;
    } catch (e) {
      if (e === 'No current user') {
        throw new ErrorNoCurrentUser();
      }

      throw new Error('Something wrong, please try again');
    }
  };

  public onCheckUser = async () => {
    try {
      const response = await Auth.currentSession();

      const userDataRaw = response.getIdToken().payload;

      const userData = UserAuthDataDTM.fromPlain({
        email: userDataRaw?.email,
        firstName: userDataRaw?.given_name,
        lastName: userDataRaw?.family_name,
        userId: userDataRaw?.['cognito:username'],
        isAdmin: userDataRaw['cognito:groups']?.includes('FreightuneAdmin'),
      });

      if (!userData.isValid()) {
        console.error(userData.validate());
      }

      return userData;
    } catch (e) {
      throw new Error('User not found');
    }
  };

  public confirmSignUp = async (
    username: string, confirmationCode: string,
  ) => {
    try {
      const response: ISignInResponseDTM = await Auth.confirmSignUp(username, confirmationCode);
      return response;
    } catch (e) {
      throw new Error('User not found');
    }
  };

  public changePassword = async (oldPassword: string, newPassword: string) => {
    try {
      const currentUserData = await Auth.currentAuthenticatedUser({ bypassCache: true });

      await Auth.changePassword(currentUserData, oldPassword, newPassword);
    } catch (e) {
      const errorData = e as TChangePasswordErrorContract;
      const errorString = e as string;

      if (['NotAuthorizedException', 'InvalidParameterException'].includes(errorData.code)) {
        throw new NotificationMessageError(i18n.t('Your current password is incorrect'));
      }

      throw new NotificationMessageError(errorData?.message ? i18n.t(errorData.message) : errorString);
    }
  };

  public passwordResetEmailSend = async (email: string) => {
    try {
      await Auth.forgotPassword(email);
    } catch (e) {
      const error = e as TForgotPasswordErrorContract;

      throw new Error(error.message);
    }
  };

  public passwordResetNewPassword = async (email: string, code: string, newPassword: string) => {
    try {
      await Auth.forgotPasswordSubmit(email, code, newPassword);
    } catch (e) {
      const error = e as TForgotPasswordErrorContract;

      throw new Error(error.message);
    }
  };

  public changeUserData = async (firstName: string, lastName: string) => {
    try {
      const currentUserData = await Auth.currentAuthenticatedUser({ bypassCache: true });

      await Auth.updateUserAttributes(currentUserData, {
        given_name: firstName,
        family_name: lastName,
      });
    } catch (e) {
      const error = e as TForgotPasswordErrorContract;

      throw new Error(error.message);
    }
  };

  public getUserData = async () => {
    try {
      const cognitoUserData = await Auth.currentAuthenticatedUser({ bypassCache: true });

      const userData = UserAuthDataDTM.fromPlain({
        email: cognitoUserData.attributes.email,
        firstName: cognitoUserData.attributes.given_name,
        lastName: cognitoUserData.attributes.family_name,
        isAdmin: cognitoUserData.attributes['cognito:groups']?.includes('FreightuneAdmin'),
      });

      if (!userData.isValid()) {
        console.error(userData.validate());
      }

      return userData;
    } catch (e) {
      const error = e as TGetUserDataErrorContract;

      throw new Error(error.message);
    }
  }
}
