import React, { FC, ReactNode, useCallback } from 'react';

import {
  Popup,
  Content,
  Row,
  WarningIcon,
  Text,
  OrgButton,
} from './DraftOrganizationPopover.styled';

interface DraftOrganizationPopoverComponentProps {
  children: ReactNode
  onButtonClick?: () => void
  popoverText?: string
  buttonText?: string
  trigger?: string
  isHidePopover?: boolean
  isEmpty?: boolean
}

export const DraftOrganizationPopoverComponent: FC<DraftOrganizationPopoverComponentProps> = ({
  children,
  onButtonClick,
  popoverText,
  buttonText,
  trigger,
  isHidePopover,
  isEmpty,
}) => {
  const handleButtonClick = useCallback(() => {
    if (onButtonClick) {
      onButtonClick();
    }
  }, []);

  if (isEmpty) {
    return null;
  }

  if (isHidePopover && children) {
    return <>{children}</>;
  }

  return (
    <Popup
      placement="bottomRight"
      trigger={trigger}
      content={(
        <Content>
          <Row>
            <WarningIcon />

            {popoverText ? (
              <Text>
                {popoverText}
              </Text>
            ) : null}
          </Row>

          {onButtonClick && buttonText ? (
            <OrgButton onClick={handleButtonClick}>
              {buttonText}
            </OrgButton>
          ) : null}
        </Content>
      )}
    >
      {children}
    </Popup>
  );
};
