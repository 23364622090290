import { R } from 'app-wrapper/repository';
import { BaseController, controller } from 'proto/BaseController';
import { OrganizationDTM } from 'user-management/models/dtm';
import { EOrganizationMemberRole } from 'user-management/constants';

@controller
export class HomeControllerController extends BaseController {
  public init = async () => {
    let organization: OrganizationDTM | null = null;

    this.dispatch(R.actions.home.setIsLoading(true));

    try {
      organization = await this.repositories.organizationRepository.get() || null;
    } catch (e) {
      console.error('HomeControllerController: init getCurrentOrganization');
    }

    this.dispatch(R.actions.home.setIsCustomer(organization ? organization.role === EOrganizationMemberRole.CUSTOMER : false));
    this.dispatch(R.actions.home.setIsLoading(false));
  }
}
