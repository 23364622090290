import { R } from 'app-wrapper/repository';
import debounce from 'lodash/debounce';
import { BaseController, controller } from 'proto/BaseController';
import { EShipmentConfirmationTypes, ETasksType, ShipmentStatusEnum } from 'shipment-operations/constants';
import { ShipmentConfirmationDTM } from 'shipment-operations/models/dtm';
import { R as userManagementR } from 'user-management/repository';
import { R as monetaryR } from 'monetary/repository';
import { R as shipmentOperationsR } from 'shipment-operations/repository';
import {
  CommandCenterGetCommandCenterGetNotificationsDTM,
  CommandCenterGetTasksDTM,
  NotificationTaskContentViewedDTM,
  NotificationTaskDTM,
} from 'app-wrapper/models/dtm';
import {
  EOrganizationStatus,
  OrganizationDTM,
  OrganizationMemberDTM,
} from 'user-management/models/dtm';
import { R as AuthR } from 'authentication/repository';
import { ENotificationCriticality } from 'app-wrapper/constants';
import { OverviewDashboardUseCase } from 'app-wrapper/usecases/OverviewDashboard.useCase';

@controller
export class OverviewController extends BaseController {
  public onLoadStatistics = async () => {
    await this.onLoadStatisticsTasks();
    this.onLoadStatisticsNotifications();
  }

  public init = async () => {
    let organization: OrganizationDTM | null = null;
    let organizationMember: OrganizationMemberDTM | undefined;
    let isUserOnboarded: undefined | boolean = false;

    new OverviewDashboardUseCase(this).resetStatisticsTasksAndNotification();
    this.dispatch(R.actions.overview.setIsLoading(true));

    this.onLoadStatistics();

    try {
      organization = await this.repositories.organizationRepository.get() || null;
    } catch (e) {
      console.error('OverviewController: init getCurrentOrganization');
    }

    let userEmail: string | undefined;

    if (organization) {
      this.dispatch(userManagementR.actions.userOrganizationData.setUserOrganization(organization));

      userEmail = AuthR.selectors.auth.getEmail(this.store.getState());

      if (organization.primaryContact) {
        const isUserInvited = organization.primaryContact.email !== userEmail;

        this.dispatch(R.actions.overview.setIsUserInvited(isUserInvited));

        if (isUserInvited) {
          this.dispatch(R.actions.overview.setStep(1));
        }
      }

      try {
        organizationMember = await userManagementR.services.organization.getOrganizationMember(organization.id, userEmail);
      } catch (e) {
        console.error('OverviewController ERROR: getOrganizationMember');
      }

      if (organizationMember) {
        isUserOnboarded = organizationMember.onboarded;

        this.dispatch(userManagementR.actions.userOrganizationData.setCurrentOrganizationUserData(organizationMember));
        this.dispatch(R.actions.overview.setIsUserOnboarded(isUserOnboarded));

        this.dispatch(R.actions.overview.setIsUserPrimary(organizationMember.primary));
      }
    }

    this.loadDashboard();

    let members: OrganizationMemberDTM[] = [];

    if (organization?.id) {
      members = await userManagementR.services.organization.getOrganizationMembersList(organization.id);
    }

    this.dispatch(R.actions.overview.setIsUserTheOnlyMember(members.length === 1));

    let hasOwnRequestedQuotas = false;
    let hasOwnBookedShipments = false;

    try {
      hasOwnRequestedQuotas = await monetaryR.services.RFQServiceById.getHasOwnQuotaRequests();
    } catch (e) {
      console.error('OverviewController: init getHasOwnQuotaRequests');
    }

    if (organization?.status === EOrganizationStatus.ACTIVE) {
      try {
        hasOwnBookedShipments = await shipmentOperationsR.services.shipment.getHasOwnBookedShipments();
      } catch (e) {
        console.error('OverviewController: init getHasOwnBookedShipments');
      }
      if (!organization.isTermsAndConditionAccepted()) {
        this.dispatch(R.actions.overview.setStep(3));
      }
    }

    this.dispatch(R.actions.overview.setHasOwnRequestedQuotas(hasOwnRequestedQuotas));
    this.dispatch(R.actions.overview.setHasOwnBookedShipments(hasOwnBookedShipments));

    try {
      if (organization && hasOwnRequestedQuotas && hasOwnBookedShipments && ((organizationMember && organizationMember.primary && members.length > 1) || (organizationMember && !organizationMember.primary)) && userEmail && organizationMember && !organizationMember?.onboarded) {
        await userManagementR.services.organization.putOrganizationMember(organization.id, userEmail, OrganizationMemberDTM.fromPlain({
          ...organizationMember,
          onboarded: true,
        }));

        this.dispatch(R.actions.overview.setIsUserOnboarded(true));
      }
    } finally {
      this.dispatch(R.actions.overview.setIsLoading(false));
    }
  }

  public loadDashboard = async () => {
    let tasks: NotificationTaskDTM | null = null;
    let notifications: NotificationTaskDTM | null = null;
    const { email } = this.store.getState().auth;
    const assigneeUserEmails = email ? [email] : undefined;
    const { organization } = this.store.getState().userOrganizationData;
    const assigneeAudienceOrganizationIds = organization?.id ? [organization?.id] : undefined;

    this.dispatch(R.actions.overview.setIsLoadingTasksBoard(true));
    this.loadShipments();

    try {
      tasks = await R.services.CommandCenter.getTasks(CommandCenterGetTasksDTM.fromPlain({
        page: '0',
        sort: 'dueDate,desc',
        query: 'TODO',
        size: '3',
        criticality: [ENotificationCriticality.HIGH],
        assigneeUserEmails,
      }));
    } catch (e) {
      console.error('OverviewController: init getCurrentOrganization');
    }

    this.dispatch(R.actions.overview.setIsLoadingTasksBoard(false));

    if (tasks) {
      this.dispatch(R.actions.overview.setTasks(tasks.content));
      this.dispatch(R.actions.overview.setCountAllTasks(tasks.totalElements));
    }

    try {
      notifications = await R.services.CommandCenter.getNotifications(CommandCenterGetCommandCenterGetNotificationsDTM.fromPlain({
        page: '0',
        sort: 'createdAt,desc',
        assigneeAudienceOrganizationIds,
      }));
    } catch (e) {
      console.error('OverviewController: init getCurrentOrganization');
    }

    if (notifications) {
      this.dispatch(R.actions.overview.setNotifications(notifications.content.splice(0, 5)));
    }

    this.dispatch(R.actions.overview.setIsLoadingNotificationsStatistics(false));
  }

  public removeTaskFromDashboard = (shipmentId: string, taskType: ETasksType, taskId?: string) => {
    const tasks = [...R.selectors.overview.getTasks(this.store.getState())];
    const task = tasks.find(({ metadata, id }) => metadata && metadata.shipmentId === shipmentId && (taskId ? taskId === id : metadata.taskType === taskType));

    if (task) {
      const taskIndex = tasks.findIndex(({ id }) => id === task.id);
      tasks.splice(taskIndex, 1);

      this.dispatch(R.actions.overview.setTasks(tasks));
    }
  };

  public onHoverNotificationItem = async (id: string) => {
    if (!id) return;
    let result: NotificationTaskContentViewedDTM | undefined;

    try {
      result = await R.services.CommandCenter.setNotificationsViewed(id);
    } catch (e) {
      console.error('AW: Overview.controller -> onHoverNotificationItem');

      return;
    }

    if (result) {
      this.dispatch(R.actions.overview.onCheckNotificationViewedById({
        id,
        viewedId: `${result.id}`,
      }));
    }
  }

  public onUnviewedNotificationItem = async (notificationId: string, viewId: string) => {
    if (!notificationId || !viewId) return;

    try {
      await R.services.CommandCenter.setNotificationsUnviewed(notificationId, viewId);
    } catch (e) {
      console.error('AW: Overview.controller -> onUnviewedNotificationItem');

      return;
    }

    this.dispatch(R.actions.overview.onCheckUnviewedNotificationViewedById(notificationId));
  }

  public goToOrganizationRegistrationStep = () => {
    this.dispatch(R.actions.overview.setStep(0));
  }

  public goToQuotasStep = () => {
    this.dispatch(R.actions.overview.setStep(1));
  }

  public goToBookShipmentStep = () => {
    this.dispatch(R.actions.overview.setStep(2));
  }

  public goToAgreeTermsAndConditions = () => {
    this.dispatch(R.actions.overview.setStep(3));
  }

  public goToInviteMembers = () => {
    this.dispatch(R.actions.overview.setStep(4));
  }

  public showTermsOfConditionDrawer = () => {
    this.dispatch(R.actions.overview.setVisibleTermsAndConditionDrawer(true));
  }

  public closeTermsOfConditionDrawer = () => {
    this.dispatch(R.actions.overview.setVisibleTermsAndConditionDrawer(false));
  }

  public acceptTermsAndConditions = async () => {
    const organization = userManagementR.selectors.userOrganizationData.getUserOrganization(this.store.getState());
    const hasOwnBookedShipments = R.selectors.overview.getHasOwnBookedShipments(this.store.getState());

    if (!organization) return;

    await userManagementR.services.organization.postConfirmations(Number(organization?.id), ShipmentConfirmationDTM.fromPlain({
      type: EShipmentConfirmationTypes.TERMS_AND_CONDITIONS,
    }));

    const reloadedOrganization = await userManagementR.services.organization.getCurrentOrganization(true);

    this.dispatch(userManagementR.actions.userOrganizationData.setUserOrganization(reloadedOrganization));

    this.dispatch(R.actions.overview.setStep(hasOwnBookedShipments ? 2 : 1));
    this.closeTermsOfConditionDrawer();
  };

  public markAsCompleted = async () => {
    const organization = userManagementR.selectors.userOrganizationData.getUserOrganization(this.store.getState());
    const userEmail = AuthR.selectors.auth.getEmail(this.store.getState());
    let organizationMember: OrganizationMemberDTM | undefined;

    this.dispatch(R.actions.overview.setIsLoading(true));

    if (organization) {
      try {
        organizationMember = await userManagementR.services.organization.getOrganizationMember(organization.id, userEmail);
      } catch (e) {
        console.error('OverviewController ERROR: getOrganizationMember');
      }
    }

    if (organization && organizationMember && !organizationMember.onboarded) {
      await userManagementR.services.organization.putOrganizationMember(organization.id, userEmail, OrganizationMemberDTM.fromPlain({
        ...organizationMember,
        onboarded: true,
      }));
    }

    this.dispatch(R.actions.overview.setIsUserOnboarded(true));
    this.dispatch(R.actions.overview.setIsLoading(false));
  }

  public setTrackerValue = (value: string) => {
    this.dispatch(R.actions.overview.setTrackerValue(value));
  }

  public debouncedSearch = debounce(() => {
    this.handleSearch();
  }, 1000);

  public handleSearch = async () => {
    const trackerValue = R.selectors.overview.getTrackerValue(this.store.getState());

    this.dispatch(R.actions.overview.setTrackerOptions([]));

    if (!trackerValue || !trackerValue.length) {
      return;
    }

    try {
      const { shipmentList } = await shipmentOperationsR.services.shipment.postShipmentSearch({
        query: trackerValue,
      });

      this.dispatch(R.actions.overview.setTrackerOptions(shipmentList ? shipmentList.map(({ shipmentName }) => shipmentName) : []));
      this.dispatch(R.actions.overview.setTrackerShipmentOptions(shipmentList || []));
    } catch (e) {
      console.error('OverviewController ERROR: postShipmentSearch');
    }
  };

  public onLoadStatisticsTasks = async (shipmentId?: string) => {
    this.dispatch(R.actions.overview.setIsLoadingTasksStatistics(true));

    try {
      await new OverviewDashboardUseCase(this).onLoadStatisticsTasks(shipmentId);
    } finally {
      this.dispatch(R.actions.overview.setIsLoadingTasksStatistics(false));
    }
  }

  public onLoadStatisticsNotifications = async (shipmentId?: string) => {
    this.dispatch(R.actions.overview.setIsLoadingNotificationsStatistics(true));

    try {
      await new OverviewDashboardUseCase(this).onLoadStatisticsNotifications(shipmentId);
    } finally {
      this.dispatch(R.actions.overview.setIsLoadingNotificationsStatistics(false));
    }
  }

  private async loadShipments() {
    const departingShipmentPromise = shipmentOperationsR.services.shipment.postShipmentSearch({
      locations: [],
      companies: [],
      containerTypes: [],
      statuses: [ShipmentStatusEnum.IN_TRANSIT, ShipmentStatusEnum.CONFIRMED],
      pageRequest: { page: 0, size: 3, sort: 'DEPARTURE' },
      query: '',
    });

    const arrivingShipmentPromise = shipmentOperationsR.services.shipment.postShipmentSearch({
      locations: [],
      companies: [],
      containerTypes: [],
      statuses: [ShipmentStatusEnum.IN_TRANSIT],
      pageRequest: { page: 0, size: 3, sort: 'ARRIVAL' },
      query: '',
    });

    const pendingConfirmationsShipmentPromise = shipmentOperationsR.services.shipment.postShipmentSearch({
      locations: [],
      companies: [],
      containerTypes: [],
      statuses: [ShipmentStatusEnum.PENDING_CONFIRMATION],
      pageRequest: { page: 0, size: 3, sort: 'CREATED_AT' },
      query: '',
    });

    const [departingShipment, arrivingShipment, pendingConfirmationsShipment] = await Promise.all([departingShipmentPromise, arrivingShipmentPromise, pendingConfirmationsShipmentPromise]);

    this.mobxStore.overviewDepartingShipmentStore.setShipments(departingShipment.shipmentList || []);
    this.mobxStore.overviewDepartingShipmentStore.setLoading(false);
    this.mobxStore.overviewArrivingShipmentStore.setShipments(arrivingShipment.shipmentList || []);
    this.mobxStore.overviewArrivingShipmentStore.setLoading(false);
    this.mobxStore.overviewPendingConfirmationsShipmentStore.setShipments(pendingConfirmationsShipment.shipmentList || []);
    this.mobxStore.overviewPendingConfirmationsShipmentStore.setLoading(false);
  }
}
