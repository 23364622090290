import React, {
  FC, useCallback, useMemo, useRef,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useReactToPrint } from 'react-to-print';

import { DocumentsCollapseItemDTM } from 'shipment-operations/models/dtm';
import { ExpandIcon } from 'app-wrapper/view/components';
import themesColors from 'app-wrapper/view/themes/themesColors';

import {
  DocumentCollapse,
  CollapsePanel,
  CollapseIcon,
  CollapseIconActive,
  CollapseLabel,
  CollapseLabelText,
  TableCell,
  TableDocuments,
  BoldText,
} from './DocumentSectionPrint.styled';
import { ActionMenu } from './components';
import { OrganizationTermOfService } from '../OrganizationTermOfService';

interface DocumentSectionPrintProps {
  sectionName: string;
  fileNamePrint?: string;
  isCollapsed?: boolean;
  documents: DocumentsCollapseItemDTM[];
  onDownload?: (documentName?: string) => void;
  onRemove?: () => void;
  showBCPrintMenuItem?: boolean;
  showQuotePrintMenuItem?: boolean;
  showInvoicePrintMenuItem?: boolean;
  showHBLPrintMenuItem?: boolean;
  showNRAPrintMenuItem?: boolean;
  isAPInvoiceType?: boolean;
}

const oldTitle = document.title;

export const DocumentSectionPrint: FC<DocumentSectionPrintProps> = ({
  sectionName,
  documents,
  fileNamePrint,
  isCollapsed,
  onDownload,
  onRemove,
  showBCPrintMenuItem,
  showQuotePrintMenuItem,
  showInvoicePrintMenuItem,
  showHBLPrintMenuItem,
  showNRAPrintMenuItem,
  isAPInvoiceType,
}) => {
  const { t } = useTranslation();
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    // @ts-ignore
    content: () => componentRef.current,
    onAfterPrint: () => {
      // support chrome
      document.title = oldTitle;
    },
  });

  const onClickPrint = useCallback(async () => {
    if (onDownload) {
      await onDownload(fileNamePrint);
      // support chrome
      document.title = fileNamePrint || oldTitle;
      handlePrint();
    }
  }, [fileNamePrint]);

  const columns = useMemo(() => [
    {
      title: t('Document Name'),
      width: '35%',
      key: 'object',
      align: 'left' as 'left',
      render: (item: string, record: DocumentsCollapseItemDTM | {}) => {
        const document = record as DocumentsCollapseItemDTM;

        return (
          <BoldText>
            {document.name}
          </BoldText>
        );
      },
    },
    {
      title: t('Uploaded Date'),
      width: '15%',
      key: 'object',
      align: 'left' as 'left',
      render: (item: string, record: DocumentsCollapseItemDTM | {}) => {
        const document = record as DocumentsCollapseItemDTM;

        return (
          <TableCell>
            {document.uploadedDate ? document.uploadedDate.getDateDMMMYYYYHHmmWithOffset() : '-'}
          </TableCell>
        );
      },
    },
    {
      title: t('Object'),
      width: '20%',
      key: 'object',
      align: 'left' as 'left',
      render: (item: string, record: DocumentsCollapseItemDTM | {}) => {
        const document = record as DocumentsCollapseItemDTM;

        return (
          <TableCell>
            {document.objectName}
          </TableCell>
        );
      },
    },
    {
      title: t('Uploaded By'),
      width: '20%',
      key: 'object',
      align: 'left' as 'left',
      render: (item: string, record: DocumentsCollapseItemDTM | {}) => {
        const document = record as DocumentsCollapseItemDTM;

        return (
          <TableCell>
            {document.createdBy}
          </TableCell>
        );
      },
    },
    {
      title: t('Action'),
      width: '10%',
      key: 'object',
      align: 'right' as 'right',
      render: (item: string, record: DocumentsCollapseItemDTM | {}) => {
        const document = record as DocumentsCollapseItemDTM;

        const handleDelete = onRemove ? () => onRemove() : undefined;

        return (
          <TableCell>
            <ActionMenu
              onDownload={onClickPrint}
              onDelete={handleDelete}
              showBCPrintMenuItem={showBCPrintMenuItem}
              showQuotePrintMenuItem={showQuotePrintMenuItem}
              showHBLPrintMenuItem={showHBLPrintMenuItem}
              invoiceId={document.id}
              isAPInvoiceType={isAPInvoiceType}
            />
          </TableCell>
        );
      },
    },
  ], [t, showBCPrintMenuItem, showQuotePrintMenuItem, showInvoicePrintMenuItem, showHBLPrintMenuItem, showNRAPrintMenuItem, isAPInvoiceType, onClickPrint]);

  const onExpandIcon = useCallback(
    ({ isActive }) => ExpandIcon({
      isActive,
      color: `${isActive ? themesColors.primaryBranding6 : themesColors.neutralBranding7}`,
    }),
    [],
  );

  return (
    <DocumentCollapse expandIcon={onExpandIcon}>
      <div style={{ display: 'none' }}>
        <OrganizationTermOfService ref={componentRef} />
      </div>
      <CollapsePanel
        key={sectionName}
        header={(
          <CollapseLabel>
            {isCollapsed ? (
              <CollapseIconActive />
            ) : (
              <CollapseIcon />
            )}

            <CollapseLabelText isCollapsed={isCollapsed}>
              {sectionName}
            </CollapseLabelText>
          </CollapseLabel>
        )}
      >
        <TableDocuments
          pagination={false}
          columns={columns}
          dataSource={documents}
        />
      </CollapsePanel>
    </DocumentCollapse>
  );
};
