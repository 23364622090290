import React, { FC } from 'react';

import {
  MBLCarrierItem,
  MBLCarrierItemTitle,
  MBLRowItems,
  MBLCarrierItemSubTitle,
  MBLRowInformation,
  MBLRowTitle,
} from './MBLRowInformation.styled';

interface MBLRowInformationComponentProps {
  title: string;
  items: Array<{
    title: string;
    subtitle: string;
  }>;
}

export const MBLRowInformationComponent: FC<MBLRowInformationComponentProps> = ({ title, items }) => (
  <MBLRowInformation>
    <MBLRowTitle>
      {title}
    </MBLRowTitle>

    <MBLRowItems>
      {items.map((item) => (
        <MBLCarrierItem
          key={item.title}
          style={{
            width: `${100 / items.length}%`,
          }}
        >
          <MBLCarrierItemTitle>
            {item.title}
          </MBLCarrierItemTitle>

          <MBLCarrierItemSubTitle>
            {item.subtitle}
          </MBLCarrierItemSubTitle>
        </MBLCarrierItem>
      ))}
    </MBLRowItems>
  </MBLRowInformation>
);
