import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'antd/es/skeleton';
import InfoCircleOutlined from '@ant-design/icons/InfoCircleOutlined';

import { Tooltip } from 'app-wrapper/view/components';

import {
  Section,
  Row,
  SectionTitle,
  SelectLabel,
  SelectWrapper, SelectsWrapper,
} from './ShipperInformation.styled';
import { ShipperSelect } from './components';
import { ReferenceInput } from './components/ShippingPartyReferenceInput';

interface ContainersDetailsComponentProps {
  isPending: boolean;
  isShowSection: boolean;
}

export const ShipperInformationComponent: FC<ContainersDetailsComponentProps> = ({
  isPending,
  isShowSection,
}) => {
  const { t } = useTranslation();

  if (!isShowSection) {
    return null;
  }

  return isPending ? (
    <Skeleton />
  ) : (
    <Section>
      <Row>
        <SectionTitle>
          {t('Shipper Information')}
          <Tooltip title={t('TBD')} placement="top">
            <InfoCircleOutlined />
          </Tooltip>
        </SectionTitle>

        <SelectsWrapper>
          <SelectWrapper>
            <SelectLabel>
              {t('Company Name')}
            </SelectLabel>

            <ShipperSelect />
          </SelectWrapper>

          <SelectWrapper>
            <SelectLabel>
              {t('Reference')} ({t('optional')})
            </SelectLabel>

            <ReferenceInput />
          </SelectWrapper>
        </SelectsWrapper>
      </Row>
    </Section>
  );
};
