import React from 'react';

import { useAppSelector } from 'app-wrapper/hooks';
import { R } from 'shipment-operations/repository';
import { UC } from 'shipment-operations/controllers';
import { HeaderComponent } from 'shipment-operations/view/components/PaymentHeader';

const HeaderContainer = () => {
  const invoice = useAppSelector(R.selectors.shipmentBillingInvoice.getInvoice);
  const receivedAmount = useAppSelector(R.selectors.makePayment.getReceivedAmount);
  const errors = useAppSelector(R.selectors.makePayment.getInputsErrors);

  return (
    <HeaderComponent
      invoice={invoice}
      onChangeReceivedAmount={UC.makePayment.onChangeReceivedAmount}
      onChangePaymentDate={UC.makePayment.onChangePaymentDate}
      onChangeReference={UC.makePayment.onChangeReference}
      receivedAmount={receivedAmount}
      errors={errors}
      isShowAmountError={false}
    />
  );
};

export { HeaderContainer as Header };
