import React, { FC } from 'react';

import { useAppSelector } from 'app-wrapper/hooks/hooks';

import { R } from 'authentication/repository';
import { UC } from 'authentication/controllers';

import { PasswordResetEmailSendComponent } from './PasswordResetEmailSend.component';

const PasswordResetEmailSendContainer: FC = () => {
  const isLoading = useAppSelector(R.selectors.passwordResetEmailSend.getLoading);
  const emailValue = useAppSelector(R.selectors.passwordResetEmailSend.getEmail);
  const emailError = useAppSelector(R.selectors.passwordResetEmailSend.getEmailError);

  return (
    <PasswordResetEmailSendComponent
      loading={isLoading}
      emailValue={emailValue}
      emailError={emailError}
      onEmailFieldChange={UC.passwordResetEmailSend.setEmail}
      onEmailBlur={UC.passwordResetEmailSend.onBlurEmail}
      onPasswordResetEmailSend={UC.passwordResetEmailSend.sendEmail}
      onScreenLeave={UC.passwordResetEmailSend.onClear}
    />
  );
};

export { PasswordResetEmailSendContainer as PasswordResetEmailSend };
