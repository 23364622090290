import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Spinner from 'antd/es/spin';

import { ShipmentDrawer } from 'shipment-operations/view/pages/Shipment/components';

import {
  SpinnerWrap,
  SubPageHeader,
  SubPageHeaderContainer,
  SubPageHeaderTitle,
  Wrapper,
  SubPageContent,
} from 'app-wrapper/view/guideline';
import { Documents } from './components';

interface CustomerDocumentsComponentProps {
  initData: () => void;
  isLoading: boolean;
}

export const OrganizationDocumentsComponent: FC<CustomerDocumentsComponentProps> = ({
  initData,
  isLoading,
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    initData();
  }, []);

  return (
    <Wrapper>
      {isLoading ? (
        <SpinnerWrap>
          <Spinner size="large" />
        </SpinnerWrap>
      ) : (
        <>
          <SubPageHeader>
            <SubPageHeaderContainer>
              <SubPageHeaderTitle>
                {t('Organization Documents')}
              </SubPageHeaderTitle>
            </SubPageHeaderContainer>
          </SubPageHeader>

          <SubPageContent>
            <Documents />
          </SubPageContent>
        </>
      )}

      <ShipmentDrawer />
    </Wrapper>
  );
};
