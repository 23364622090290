import React, {
  FC,
  useEffect,
  useCallback,
} from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'antd/es/skeleton';

import { CustomModal } from 'shipment-operations/view/components';
import { Footer, ShipmentInfo } from 'app-wrapper/view/drawers/components';
import { ShipmentCostChangeTaskMetadataDTM } from 'app-wrapper/models/dtm';
import { ShipmentPreviewDTM } from 'shipment-operations/models/dtm';

import { CostsUpdateInfo, ContainersTable, FeesAndServices } from './components';
import { Content, InnerContent } from './ShipmentCostChangesDrawer.styled';

interface ShipmentCostChangesDrawerComponentProps {
  onClose: () => void
  isLoading: boolean
  onAccept: (taskId: string) => void
  init: (taskId: string) => void
  taskId?: string
  shipment: ShipmentPreviewDTM | null
  metadata: ShipmentCostChangeTaskMetadataDTM | null
}

export const ShipmentCostChangesDrawerComponent: FC<ShipmentCostChangesDrawerComponentProps> = ({
  onClose,
  onAccept,
  isLoading,
  init,
  taskId,
  shipment,
  metadata,
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (taskId) {
      init(taskId);
    }
  }, [taskId]);

  const handleAccept = useCallback(() => {
    if (taskId) {
      onAccept(taskId);
    }
  }, [onAccept, taskId]);

  return (
    <CustomModal
      title={t('Accept pricing changes')}
      onClose={onClose}
      contentStyles={{
        left: 'unset',
        width: '1137px',
      }}
      childrenWrapperStyles={{
        position: 'relative',
        height: 'calc(100vh - 56px)',
      }}
    >
      {isLoading ? (
        <Skeleton />
      ) : (
        <Content>
          <CostsUpdateInfo metadata={metadata} />

          <InnerContent>
            <ShipmentInfo shipment={shipment} borderless />

            <ContainersTable />
            <FeesAndServices />
          </InnerContent>
        </Content>
      )}

      <Footer
        onAccept={handleAccept}
        closeDrawer={onClose}
        isLoading={isLoading}
        isAbleToAccept
        okText={t('Accept')}
      />
    </CustomModal>
  );
};
