import React from 'react';
import { useSelector } from 'react-redux';

import { UC } from 'app-wrapper/controllers';
import { R } from 'app-wrapper/repository';
import { R as userManagementR } from 'user-management/repository';

import { OverviewComponent } from './Overview.component';

const OverviewContainer = () => {
  const isLoading = useSelector(R.selectors.overview.getIsLoading);
  const isUserOnboarded = useSelector(R.selectors.overview.getIsUserOnboarded);
  const isOrganizationRejected = useSelector(userManagementR.selectors.userOrganizationData.getIsOrganizationRejected);
  const organization = useSelector(userManagementR.selectors.userOrganizationData.getUserOrganization);

  const showTermsAccept = organization?.mustAccepTermsAndConditions();

  return (
    <OverviewComponent
      isUserOnboarded={isUserOnboarded}
      init={UC.overview.init}
      isLoading={isLoading}
      isOrganizationRejected={isOrganizationRejected}
      showTermsAccept={showTermsAccept}
    />
  );
};

export { OverviewContainer as OverviewPage };
