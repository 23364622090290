export enum EServiceActivityType {
  DOCUMENT = 'DOCUMENT',
  INPUT = 'INPUT',
}

export enum EAdditionalServiceStep {
  UPLOAD_DOCUMENTS = 'DOCUMENT_ACTIVITIES',
  SUBMIT_SKYPACE_INFORMATION = 'SUBMIT_SKYPACE_INFORMATION',
  VALIDATE_CUSTOMER_DOCUMENTS = 'VALIDATE_CUSTOMER_DOCUMENTS',
  ADD_DOCUMENTS = 'ADD_DOCUMENTS',
  COMPLETE_SERVICE = 'COMPLETE_SERVICE',
  ENTER_ITN_NUMBER = 'ENTER_ITN_NUMBER',
  ENTER_MRN_NUMBER = 'ENTER_MRN_NUMBER',
  ENTER_ISF_NUMBER = 'ENTER_ISF_NUMBER',
}

export const AES_PPI_CODE = '3064';
export const AES_USPPI_CODE = '3063';
export const ISF_SERVICE_CODE = '3003';
export const EX1_SERVICE_CODE = '3065';

export const SERVICES_WITH_ITN = [AES_PPI_CODE, AES_USPPI_CODE];
