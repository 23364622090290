import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Tag from 'antd/es/tag';

import { DateDtm } from 'app-wrapper/models/dtm';

import { Header, Title } from './Header.styled';

interface IHeaderComponentProps {
  dueDate?: DateDtm;
}

export const HeaderComponent: FC<IHeaderComponentProps> = ({ dueDate }) => {
  const { t } = useTranslation();

  return (
    <Header>
      <Title>
        {t('Required Actions')}
      </Title>

      {dueDate ? (
        <Tag>
          {dueDate.getFormatDMMMHHmmWithOffset()}
        </Tag>
      ) : null}
    </Header>
  );
};
