import styled from 'styled-components';
import Typography from 'antd/es/typography';
import { RightOutlined } from '@ant-design/icons';

import { Button as ComponentButton } from 'app-wrapper/view/components';
import {
  ShipIcon,
  GorizontalMenuSvg,
  NavigatorIconSvg,
  WeightIconSvg,
} from 'app-wrapper/view/icons';

export const Container = styled.div.attrs((props: {
  'data-class'?: string
}) => ({
  'data-class': props?.['data-class'] || 'BookingDrawer_Footer_Container',
}))`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 64px;
  padding: 12px 24px;
  background: #FFFFFF;
  box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px rgba(0, 0, 0, 0.08), 0px 9px 28px 8px rgba(0, 0, 0, 0.05);
`;

export const ContainerWrap = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
`;

export const CarrierInfo = styled.div`
  height: 100%;
  padding-right: 24px;
  display: flex;
  flex-direction: column;
  border-right: 1px dashed #EAEEF8;
`;

export const CarrierInfoRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const CarrierShipIcon = styled(ShipIcon)`
  width: 15px;
  margin-right: 4px;
  color: #73819B;
`;

export const DestinationInfo = styled(CarrierInfo)`
  flex-direction: row;
  padding-left: 24px;
`;

export const DestinationInfoCol = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DestinationInfoRow = styled(CarrierInfoRow)``;

export const DividerIcon = styled(RightOutlined)`
  margin: 0 4px;

  svg {
    width: 10px;
    height: 20px;
    fill: #D1D7E2;
  }
`;

export const ShipmentTotalInfo = styled(CarrierInfo)`
  border-right: 0;
  padding-left: 24px;
`;

export const ShipmentTotalInfoRow = styled(CarrierInfoRow)``;

export const ShipmentTotalItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 24px;

  svg {
    margin-right: 4px;
    width: 14px;
    height: 14px;
    color: #D1D7E2;
  }
  
  article {
    margin-top: 1px;
  }
`;

export const GorizontalMenuIcon = styled(GorizontalMenuSvg)``;

export const NavigatorIcon = styled(NavigatorIconSvg)``;

export const WeightIcon = styled(WeightIconSvg)``;

export const Text = styled(Typography)`
  font-size: 14px;
  line-height: 22px;
`;

export const DividerText = styled(Text)`
  color: #D1D7E2;
  margin: 0 4px;
`;

export const TextBold = styled(Text)`
  font-weight: 500;
`;

export const TextGrey = styled(Text)`
  color: #73819B;
`;

export const Actions = styled.div`
  display: flex;
  flex-direction: row;
  position: absolute;
  right: 0;
  gap: 8px;
`;

export const Button = styled(ComponentButton)`
  height: 40px;
`;
