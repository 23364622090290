import React, {
  FC, useCallback, useMemo,
} from 'react';

import { Option } from 'app-wrapper/view/components';
import { PlusSvg } from 'app-wrapper/view/icons';

import { ContactDTM, ShippingPartyContactDTM } from 'shipment-operations/models/dtm';
import { PermissionAttributePolicy } from 'shipment-operations/constants';
import { useTranslation } from 'react-i18next';
import {
  SelectStyled, SelectStyledFooter, SelectStyledFooterText,
} from './ShippingPartiesContactPerson.syled';

interface IShippingPartiesContactPersonInputComponentProps {
  contactList: ContactDTM[]
  availability?: PermissionAttributePolicy
  selectedContact?: ShippingPartyContactDTM
  selectedCurrentContact?: ShippingPartyContactDTM
  contactError?: Error
  setContact: (contactId: ContactDTM['id']) => void
  onBlur: () => void
  openAddContactDrawer: () => void
  isSelectedCompanyRelatesToUser: boolean
}

export const ShippingPartiesContactPersonComponent: FC<IShippingPartiesContactPersonInputComponentProps> = ({
  contactList,
  availability,
  selectedContact,
  selectedCurrentContact,
  contactError,
  setContact,
  onBlur,
  openAddContactDrawer,
  isSelectedCompanyRelatesToUser,
}) => {
  const isError = contactError !== undefined;
  const selectValue = selectedCurrentContact === selectedContact ? selectedCurrentContact?.id : selectedContact?.id;

  const { t } = useTranslation();

  const isDisabled = useMemo(() => availability !== PermissionAttributePolicy.WRITE, [availability]);

  const dropdownRender = useCallback((menu) => (
    <>
      {menu}
      <SelectStyledFooter onClick={openAddContactDrawer}>
        <PlusSvg
          style={{ color: '#6C79DE' }}
          height={12}
          width={12}
        />
        <SelectStyledFooterText>{t('Add Contact')}</SelectStyledFooterText>
      </SelectStyledFooter>
    </>
  ), [openAddContactDrawer]);

  return (
    <SelectStyled
      showSearch
      placeholder={t('Select Contact Person')}
      optionFilterProp="children"
      disabled={isDisabled || !isSelectedCompanyRelatesToUser}
      value={contactList.length ? selectValue : undefined}
      error={isError && !selectValue}
      aria-errormessage={contactError?.message}
      onChange={setContact}
      onBlur={onBlur}
      dropdownRender={dropdownRender}
    >
      {
        contactList.map((contact) => (
          <>
            <Option key={contact.id} value={contact.id}>{contact.fullName}</Option>
          </>
        ))
      }
    </SelectStyled>
  );
};
