import styled from 'styled-components';
import {
  divFlex, EmptyScrollbar,
} from 'app-wrapper/view/themes/mixin';

// Content

export const ARCQItemInfiniteScrollSpin = styled.div`
${divFlex}
align-items: center;
justify-content: center;
`;

export const AllRCQQuotesComponentDiv2 = styled.div.attrs({
  'data-class': 'AllRCQQuotesComponentDiv',
})`
  ${divFlex}
  height: 100%;
  width: 100%;
`;

export const AllRCQQuotesComponentDiv = styled(AllRCQQuotesComponentDiv2)`
.ant-tabs-nav-operations {
  display: none;
}
`;

export const AllRCQQuotesComponentQuotesDivWrapper = styled.div.attrs({
  'data-class': 'AllRCQQuotesComponentQuotesDivWrapper',
})`
  ${divFlex}
  width: 100%;

  background-color: ${({ theme }) => theme.themesColors.lightGrayBorder};
`;

export const AllRCQQuotesComponentQuotesDiv = styled.div.attrs({
  'data-class': 'AllRCQQuotesComponentQuotesDiv',
})`
  ${divFlex}
  width: 100%;
  max-width: 1660px;
  margin: 0 auto;
  
  flex-direction: column;
  background-color: ${({ theme }) => theme.themesColors.lightGrayBorder};
  padding-left: 24px;
  padding-right: 24px;
`;

export const AllRCQQuotesComponentHeader = styled.div`
  ${divFlex}
  width: 100%;
  height: ${({ theme }) => theme.freightQuotePage.height.filterHeader};
  border-bottom: 1px solid ${({ theme }) => theme.themesColors.neutralBranding4};
`;

export const AllRCQQuotesComponentFilterShowOnly = styled.div.attrs({
  'data-class': 'AllRCQQuotesComponentFilterShowOnly',
})`
  ${divFlex}
  justify-content: center;
  align-items: center;
  height: ${({ theme }) => theme.freightQuotePage.height.filterHeader};
`;

export const AllRCQQuotesComponentTableHeader = styled.div.attrs({
  'data-class': 'AllRCQQuotesComponentTableHeader',
})`
  ${divFlex}

  height: 28px;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.themesColors.neutralBranding3};
  padding-bottom: 10px;
  margin-top: 8px;
`;

export const AllRCQQuotesComponentTableHeaderItem = styled.div.attrs((props: {
  isCenter?: boolean,
  isRight?: boolean,
  width?: string,
  paddingLeft?: string,
}) => ({
  isCenter: props.isCenter,
  isRight: props.isRight,
  width: props.width,
  paddingLeft: props.paddingLeft,
  'data-class': 'AllRCQQuotesComponentTableHeaderItem',
}))`
  ${divFlex}
  align-items: center;
  justify-content: ${({ isCenter }) => (isCenter ? 'center' : 'left')};
  justify-content: ${({ isRight }) => (isRight ? 'right' : 'left')};

  width: ${({ width }) => (width || 'auto')};
  padding-left: ${({ paddingLeft }) => (paddingLeft || '0')};

  color: ${({ theme }) => theme.themesColors.secondaryDot45};
  font-size: 12px;
  font-weight: 400;
`;

export const AllRCQQuotesComponentHeaderFound = styled.div`
  ${divFlex}
  color: ${({ theme }) => theme.themesColors.middleGraySecondary};
  align-items: center;
  font-size: 12px;
  padding-top: 5px;
  margin-left: auto;
`;

export const AllRCQQuotesComponentQuotesItemsDiv = styled.div.attrs({
  'data-class': 'AllRCQQuotesComponentQuotesItemsDiv',
})`
  ${divFlex}
  width: 100%;
  height: 100%;
  flex-direction: column;
  .infinite-scroll-component {
    ${EmptyScrollbar}
  }
`;
