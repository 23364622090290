import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'app-wrapper/createSlice';
import { FieldErrorDTM, TFieldErrorDTM } from 'app-wrapper/models/dtm';

import { TSignUpState } from 'authentication/models/states';

export const initialState: TSignUpState = {
  isLoading: false,

};

export const signUpSlice = createSlice({
  name: 'signUp',
  initialState,
  reducers: {
    setRequestError: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      requestError: payload,
    }),
    setEmail: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      email: payload,
    }),
    setEmailError: (state, { payload }: PayloadAction<TFieldErrorDTM>) => ({
      ...state,
      emailError: FieldErrorDTM.fromPlain({
        ...state.emailError,
        ...payload,
      }),
    }),
    clearEmailError: (state) => ({
      ...state,
      emailError: undefined,
    }),
    setPassword: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      password: payload,
    }),
    setPasswordError: (state, { payload }: PayloadAction<TFieldErrorDTM>) => ({
      ...state,
      passwordError: FieldErrorDTM.fromPlain({
        ...state.passwordError,
        ...payload,
      }),
    }),
    clearPasswordError: (state) => ({
      ...state,
      passwordError: undefined,
    }),
    setFirstName: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      firstName: payload,
    }),
    setFirstNameError: (state, { payload }: PayloadAction<TFieldErrorDTM>) => ({
      ...state,
      firstNameError: FieldErrorDTM.fromPlain({
        ...state.firstNameError,
        ...payload,
      }),
    }),
    clearFirstNameError: (state) => ({
      ...state,
      firstNameError: undefined,
    }),
    setLastName: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      lastName: payload,
    }),
    setLastNameError: (state, { payload }: PayloadAction<TFieldErrorDTM>) => ({
      ...state,
      lastNameError: FieldErrorDTM.fromPlain({
        ...state.lastNameError,
        ...payload,
      }),
    }),
    clearLastNameError: (state) => ({
      ...state,
      lastNameError: undefined,
    }),
    setLoading: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      isLoading: payload,
    }),
  },
});

export const signUpActions = signUpSlice.actions;
export const signUpReducer = signUpSlice.reducer;
