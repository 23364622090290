import styled from 'styled-components';

import AntdUpload from 'antd/es/upload';
import AntdButton from 'antd/es/button';
import { colors } from 'app-wrapper/view/themes/themesColors';

interface IButtonProps {
  error: number
}

export const Button = styled(AntdButton)<IButtonProps>`
  width: 100%;
  font-size: 12px;
  box-shadow: none;
  ${({ error }) => !!error && 'border-color: red !important;'};
  color: ${colors.gray61}
`;

export const Upload = styled(AntdUpload)`
  display: flex;
  flex-direction: column;
`;
