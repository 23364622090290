import i18n from 'app-wrapper/i18n/i18n';

import { PaymentsType, TradeType } from 'shipment-operations/constants';

export const PaymentsTypeNames = {
  [PaymentsType.COLLECT]: i18n.t('Collect'),
  [PaymentsType.PREPAID]: i18n.t('Prepaid'),
};

export const TradeTypeNames = {
  [TradeType.EXPORT]: i18n.t('Export'),
  [TradeType.IMPORT]: i18n.t('Import'),
};
