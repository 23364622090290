import styled from 'styled-components';

import { Button as ButtonCommon } from 'app-wrapper/view/components';
import { ExpandIcon as ExpandIconCommon } from 'app-wrapper/view/components/ExpandIcon';

export const ExpandIcon = styled(ExpandIconCommon)``;

export const Button = styled(ButtonCommon)`
  padding-left: 16px;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  height: 32px;
  border-radius: 2px;
  
  span:first-of-type {
    transition: .2s all ease;
    margin-right: 4px;
    margin-top: 1px;
  }
`;
