import React from 'react';

export const IconImportFilling = () => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.06934 17.1863H35.0492V31.5769C35.0492 32.1982 34.5455 32.7019 33.9242 32.7019H6.19434C5.57302 32.7019 5.06934 32.1982 5.06934 31.5769V17.1863Z" fill="#597EF7" />
    <path d="M4.99218 11.7324L2.06672 18.1683C1.95552 18.4005 1.97122 18.6736 2.10831 18.8915C2.24539 19.1095 2.4847 19.2419 2.74218 19.2422H37.2661C37.5292 19.2422 37.7731 19.1042 37.9087 18.8787C38.0443 18.6532 38.0518 18.3731 37.9284 18.1406L35.0634 11.7324H4.99218Z" fill="#E4EBF7" />
    <path d="M29.0066 7.26562L35.0638 11.7323H4.99023L11.0151 7.26562H29.0066Z" fill="#ADC6FF" />
    <path d="M11.0151 7.26562V11.7323H4.99023L11.0151 7.26562Z" fill="#597EF7" />
    <path d="M29.0059 7.26562V11.7291L35.063 11.7323L29.0059 7.26562Z" fill="#597EF7" />
  </svg>
);

