import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { useAuthToken } from 'app-wrapper/hooks/useAuthToken';

import { R } from 'shipment-operations/repository';
import { UC } from 'shipment-operations/controllers';

import { CargoDocumentUploadComponent } from './CargoDocumentUpload.component';

interface CargoDocumentUploadProps {
  cargoId: number;
}

export const CargoDocumentUpload: FC<CargoDocumentUploadProps> = ({ cargoId }) => {
  const {
    msdsDocument,
    errors,
  } = useSelector(R.selectors.bookingWizard.getCargoById(cargoId));
  const shipmentId = useSelector(R.selectors.bookingWizard.getShipmentId);
  const authToken = useAuthToken();

  return (
    <CargoDocumentUploadComponent
      error={!!errors.msdsDocument}
      authToken={authToken}
      msdsDocument={msdsDocument}
      setCargoMsdsDocument={UC.bookingWizard.setMsdsDocument}
      touchCargoField={UC.bookingWizard.touchCargoField}
      cargoId={cargoId}
      downloadCargoMsdsDocument={UC.bookingWizard.downloadMsdsDocument}
      shipmentId={shipmentId}
    />
  );
};
