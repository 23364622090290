import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import {
  CargoWeight as CargoWeightIcon,
  PackagesNumber as PackagesNumberIcon,
  Vector as VectorIcon,
} from 'app-wrapper/view/icons';
import {
  ShipmentContainersFormLoadPlanContainer,
} from './ShipmentContainersFormLoadPlan.styled';
import {
  ShipmentContainersFormTitleSecondRow,
} from '../ShipmentContainersFormTitle/ShipmentContainersFormTitle.styled';

interface ICargoTitleComponentProps {
  commonPackagesNumber: number
  commonWeight: number
  commonVolume: number
}

export const ShipmentContainersFormLoadPlanCounterComponent: FC<ICargoTitleComponentProps> = ({
  commonPackagesNumber,
  commonWeight,
  commonVolume,
}) => {
  const { t } = useTranslation();

  return (
    <ShipmentContainersFormLoadPlanContainer>
      <ShipmentContainersFormTitleSecondRow>
        <span>
          <PackagesNumberIcon />{commonPackagesNumber} {t('pcs')}
        </span>
        <span>
          <CargoWeightIcon />{commonWeight} {t('kg')}
        </span>
        <span>
          <VectorIcon />{commonVolume} {t('cbm')}
        </span>
      </ShipmentContainersFormTitleSecondRow>
    </ShipmentContainersFormLoadPlanContainer>
  );
};
