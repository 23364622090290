import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { NoDataStub } from 'shipment-operations/view/components';

import {
  Wrapper,
  Title,
  Item,
  ItemsWrap,
} from './MBLClauses.styled';

interface MBLClausesComponentProps {
  clauses: string[];
}

export const MBLClausesComponent: FC<MBLClausesComponentProps> = ({ clauses }) => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <Title
        style={{
          marginBottom: clauses.length ? '12px' : '0',
        }}
      >
        {t('Clauses')}
      </Title>

      {clauses.length ? (
        <ItemsWrap>
          {clauses.map((clause) => (
            <Item key={`MBLClausesComponent_${clause}`}>
              {clause}
            </Item>
          ))}
        </ItemsWrap>
      ) : <NoDataStub />}
    </Wrapper>
  );
};
