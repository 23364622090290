import React, { ReactNode, forwardRef, LegacyRef } from 'react';

import { HBLDocumentBOLDTM } from 'shipment-operations/models/dtm';
import {
  Page,
  FirstPage,
  SecondPage,
  ThirdPage,
  TermsAndConditions,
} from 'shipment-operations/view/components/HBLDocumentPDF/components';

interface HBLDocumentPDFComponentProps {
  billOfLadingHBL: HBLDocumentBOLDTM | null;
  fileNamePrint?: string;
  header?: ReactNode;
}

export const HBLDocumentPDFComponent = forwardRef(({
  billOfLadingHBL,
  fileNamePrint,
  header,
}: HBLDocumentPDFComponentProps, ref) => {
  if (!billOfLadingHBL) {
    return null;
  }

  return (
    <div ref={ref as LegacyRef<HTMLDivElement>}>
      {fileNamePrint ? (<title>{fileNamePrint}</title>) : null}
      <Page
        header={header}
      >
        <FirstPage />
      </Page>

      <Page>
        <TermsAndConditions />
      </Page>

      <Page>
        <SecondPage data={billOfLadingHBL} />
      </Page>

      <Page>
        <ThirdPage data={billOfLadingHBL} />
      </Page>
    </div>
  );
});
