import React from 'react';
import { useSelector } from 'react-redux';

import { R } from 'user-management/repository';

import { MemberTeamsTableComponent } from './MemberTeamsTable.component';

export const MemberTeamsTable = React.memo(() => {
  const member = useSelector(R.selectors.userManagement.getUserProfile);

  return (
    <MemberTeamsTableComponent teams={member?.teams || []} />
  );
});
