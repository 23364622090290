import React, { FC } from 'react';
import { Outlet } from 'react-router';

import {
  AuthLeftSide,
  AuthRightSide,
  AuthWrapper,
} from './Auth.styled';

export const Auth: FC = () => (
  <AuthWrapper>
    <AuthLeftSide />
    <AuthRightSide>
      <Outlet />
    </AuthRightSide>
  </AuthWrapper>
);
