import React from 'react';
import times from 'lodash/times';

import {
  Wrapper, Row, RowItem, Paragraph,
} from './TableSkeleton.styled';

const COLUMN_HEADER = 5;
const ROW = 14;

const TableSkeleton = () => (
  <Wrapper>
    <Row>
      {times(COLUMN_HEADER, (index) => (
        <RowItem key={index} width="20%">
          <Paragraph width="100px" />
        </RowItem>
      ))}
    </Row>
    {times(ROW, (index) => (
      <Row key={index}>
        {times(5, (count) => (
          <RowItem key={count} width="20%">
            <Paragraph width="150px" />
          </RowItem>
        ))}
      </Row>
    ))}
  </Wrapper>
);

export { TableSkeleton };
