import React from 'react';
import { observer } from 'mobx-react-lite';

import { useMobxStore } from 'app-wrapper/mobxStores';
import { UC } from 'user-management/controllers';

import { CustomerSalesComponent } from './CustomerSales.component';

export const CustomerSales = observer(() => {
  const { customerSales } = useMobxStore();

  return (
    <CustomerSalesComponent
      isLoading={customerSales.state.isLoading}
      init={UC.customerSales.initCustomerSales}
    />
  );
});
