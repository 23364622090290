import React, { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { ShipmentChargesPermissionsDtm } from 'shipment-operations/models/dtm';

import Switch from 'antd/es/switch';
import {
  ToggleTitle,
} from 'shipment-operations/view/pages/ShipmentTransportationCharges/ShipmentTransportationCharges.styled';
import { PermissionAttributePolicy } from 'shipment-operations/constants';

interface IMainContainerProps {
  isPercentage: boolean
  onToggle: (status: boolean) => void
  permissions: ShipmentChargesPermissionsDtm
}

const ProfitSwitcher: FC<IMainContainerProps> = ({
  isPercentage, onToggle, permissions,
}) => {
  const { t } = useTranslation();

  return (
    <>{permissions.shipmentChargesProfit === PermissionAttributePolicy.WRITE
      && (
      <div>
        <Switch size="small" checkedChildren="%" unCheckedChildren="$" checked={isPercentage} onClick={onToggle} />
        <ToggleTitle>{t('shipmentOperation.profit')}</ToggleTitle>
      </div>
      )}
    </>
  );
};

const ProfitSwitcherComponent = memo(ProfitSwitcher);

export { ProfitSwitcherComponent };
