import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'app-wrapper/createSlice';

import { RoutesState } from 'app-wrapper/repository/store/Routes/Routes.state';

export const routesSlice = createSlice({
  name: 'routes',
  initialState: RoutesState,
  reducers: {
    setLastPath: (state, { payload }: PayloadAction<string>) => {
      state.lastPath = payload;
    },
    setCurrentPath: (state, { payload }: PayloadAction<string>) => {
      state.currentPath = payload;
    },
    setAssetManifestHash: (state, { payload }: PayloadAction<string>) => {
      state.assetManifestHash = payload;
    },
  },
});

export const routesActions = routesSlice.actions;
export const routesReducer = routesSlice.reducer;
