import {
  IsEnum,
  IsNumber,
  IsString,
  IsOptional,
} from 'class-validator';
import { BaseDTM } from 'proto/BaseDTM';

import { EShipmentConfirmationTypes } from 'shipment-operations/constants';

export interface IShipmentConfirmationDTM {
  id?: number;
  type: EShipmentConfirmationTypes;
  createdAt?: string;
  createdBy?: string;
  createdByFirstName?: string;
  createdByLastName?: string;
}

export class ShipmentConfirmationDTM extends BaseDTM<IShipmentConfirmationDTM> {
  @IsOptional()
  @IsNumber()
  id: number;

  @IsEnum(EShipmentConfirmationTypes)
  type: EShipmentConfirmationTypes;

  @IsOptional()
  @IsString()
  createdAt: string;

  @IsOptional()
  @IsString()
  createdBy: string;

  @IsOptional()
  @IsString()
  createdByFirstName?: string;

  @IsOptional()
  @IsString()
  createdByLastName?: string;

  getCreatedByFullName = () => {
    const firstName = this.createdByFirstName || '';
    const lastName = this.createdByLastName || '';
    const isNameSet = this.createdByFirstName && this.createdByLastName;

    return isNameSet ? `${firstName} ${lastName}` : '';
  }
}
