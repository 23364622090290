import styled from 'styled-components';
import ExclamationCircleOutlined from '@ant-design/icons/ExclamationCircleOutlined';

interface ILineProps {
  active?: boolean
}

interface IInfoBlockWrapper {
  lastItem?: boolean
}

const IconWrapperWidth = '16px';
const IconSize = '16px';

export const StyledStepIcon = styled.div`
  width: ${IconSize};
  height: ${IconSize};
  border-radius: 50%;
  background-color: #6C79DE;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledActiveStepIcon = styled(StyledStepIcon)`
  background-color: white;
  border: 2px solid #6C79DE;
`;

export const NonActiveStepIcon = styled(StyledActiveStepIcon)`
  border-color: #D1D7E2;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const StepItem = styled.div`
  display: flex;
`;

export const IconsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: ${IconWrapperWidth};
  align-items: center;
`;

export const Line = styled.div<ILineProps>`
  min-height: 30px;
  height: calc(100% - ${IconSize});
  width: 1px;
  background-color: ${({ active }) => (active ? '#6C79DE' : '#D1D7E2')};
`;

export const InfoBlock = styled.div<IInfoBlockWrapper>`
  margin-left: 10px;
  border-bottom: ${({ lastItem }) => !lastItem && '1px solid #EAEEF8'};
  width: 100%;
  padding-bottom: 10px;
  display: flex;
  margin-bottom: 10px;
`;

export const DescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 30%;
`;

export const Title = styled.span`
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 8px;
`;

export const Location = styled.span`
  font-weight: 500;
  font-size: 12px;
  color: #202C3C;
`;

export const DetailsKey = styled.span`
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #73819B;
`;

export const DetailsValue = styled(DetailsKey)`
  color: #202C3C;
`;

export const Details = styled.span`
  font-weight: 400;
  font-size: 12px;
  color: #202C3C;
`;

export const EstimatedDateWrapper = styled.div`
  width: 25%;
`;

export const ActualDateWrapper = styled.div`
  width: 25%;
`;

export const Header = styled.div`
  display: flex;
  height: 36px;
  border-top: 1px solid #EAEEF8;
  border-bottom: 1px solid #EAEEF8;
  margin-bottom: 10px;
  width: 100%;
  align-items: center;
  font-weight: 400;
  font-size: 12px;
  color: #73819B;
`;

export const HeaderItemEvent = styled.div`
  width: calc(30% + ${IconWrapperWidth});
`;

export const HeaderItemEstimated = styled.div`
  width: calc(25% - 4px);
`;

export const HeaderItemActual = styled.div`
  width: 25%;
`;

export const WarningTitle = styled.span`
  display: block;
  color: #73819B;
  font-size: 12px;
`;

export const WarningTooltipIcon = styled(ExclamationCircleOutlined)`
  margin-left: 5px;
  cursor: pointer;
`;
