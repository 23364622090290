import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { AppFiltersCheckboxGroupDTM } from 'app-wrapper/models/dtm';
import { FilterCheckboxElement } from 'app-wrapper/view/components';

interface ICarriersFilterComponentProps {
  hasSelectedCarriersFilters: boolean;
  carriersFiltersGroup: AppFiltersCheckboxGroupDTM[];
  setCarrierFilterByIndex: (index: number, filter: AppFiltersCheckboxGroupDTM) => void;
  resetCarrierFiltersGroup: () => void;
  applyFilters: () => void;
}

export const CarriersFilterComponent: FC<ICarriersFilterComponentProps> = ({
  hasSelectedCarriersFilters,
  carriersFiltersGroup,
  setCarrierFilterByIndex,
  resetCarrierFiltersGroup,
  applyFilters,
}) => {
  const { t } = useTranslation();

  return (
    <FilterCheckboxElement
      isRight
      name={t('Carriers')}
      group={carriersFiltersGroup}
      disabledReset={!hasSelectedCarriersFilters}
      onApply={applyFilters}
      onReset={resetCarrierFiltersGroup}
      onChangeGroup={setCarrierFilterByIndex}
    />
  );
};
