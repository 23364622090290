import React, { FC, useCallback } from 'react';

import { PaymentDocumentDTM } from 'shipment-operations/models/dtm';

import {
  DocumentItem,
  DocumentIcon,
  DocumentItemName,
} from './PaymentDocument.styled';

interface IPaymentDocumentComponentProps {
  document: PaymentDocumentDTM;
  accountId: number;
  downloadPaymentDocument: (accountId: number, documentId: number, documentName: string) => void;
}

const PaymentDocumentComponent: FC<IPaymentDocumentComponentProps> = ({ document, accountId, downloadPaymentDocument }) => {
  const handleDownload = useCallback(() => {
    downloadPaymentDocument(accountId, document.id, document.name);
  }, [document, accountId, downloadPaymentDocument]);

  return (
    <DocumentItem onClick={handleDownload}>
      <DocumentIcon />

      <DocumentItemName>
        {document.name}
      </DocumentItemName>
    </DocumentItem>
  );
};

export { PaymentDocumentComponent as PaymentDocument };
