import i18n from 'i18next';
import { setLocale } from 'yup';

import './validations';

setLocale({
  mixed: {
    required: i18n.t('Validations.mixed.required'),
  },
  string: {
    max: i18n.t('Validations.string.max'),
  },
});
