import styled from 'styled-components';
import Button from 'antd/es/button';
import Tag from 'antd/es/tag';

import { divFlex } from 'app-wrapper/view/themes/mixin';

export const SBLIncompleteStub = styled.div`
  ${divFlex}
  flex-direction: row;
`;

export const SBLIncompleteTag = styled(Tag)`
  margin-right: 0;
`;

export const SBLIncompleteButton = styled(Button)`
  ${divFlex}
  align-items: center;
  justify-content: center;
  width: 22px;
  height: 22px;
  margin-left: 4px;
  background-color: transparent;
  border: 1px solid ${({ theme }) => theme.themesColors.characterBrandingWarning};
  box-shadow: none;

  &:hover {
    border: 1px solid ${({ theme }) => theme.themesColors.characterBrandingWarning};
    background-color: transparent;
  }
`;
