import styled from 'styled-components';

export const Sign = styled.span<{fontSize?: number}>`
  position: absolute;
  top: 0;
  right: 0;
  background: #F4F5F8;
  border: 1px solid #EAEEF8;
  padding: 2px;
  border-radius: 4px;
  font-size: ${({ fontSize }) => fontSize || '0.45'}rem;
  font-weight: 600;
`;

export const Bold = styled.span<{bottomMargin?: boolean}>`
  font-size: 10px;
  font-weight: 700;
  text-transform: capitalize;
  ${({ bottomMargin }) => bottomMargin && 'margin-bottom: 4px;'}
`;

export const Wrapper = styled.div`
  display: contents;
`;

export const SectionWrapper = styled.div`
  font-size: 12px;
`;

export const PageBreak = styled.div`
  page-break-before: always;
`;

export const Cell = styled.div<{justifyContent?: string; flex?: number}>`
  display: flex;
  justify-content: ${({ justifyContent }) => justifyContent};
  align-items: flex-start;
  flex: ${({ flex = 1 }) => flex};
  flex-wrap: wrap;
  gap: 4px 4px;
  text-transform: capitalize;
  font-size: 10px;
`;

export const StyledSign = styled(Sign)`
  position: relative;
`;

export const MarksAndNumbersWrapper = styled.div`
  height: fit-content;
  margin-top: 4px;
`;
