import React from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';

import { ShipperInformationComponent } from './ShipperInformation.component';

const ShipperInformationContainer = () => {
  const isContentUpdating = useSelector(R.selectors.bookingWizard.getIsContentUpdating);
  const isShowShipperSection = useSelector(R.selectors.bookingWizard.getShouldShowShipperSection);

  return (
    <ShipperInformationComponent
      isPending={isContentUpdating}
      isShowSection={isShowShipperSection}
    />
  );
};

export { ShipperInformationContainer as ShipperInformation };
