import styled, { css } from 'styled-components';

export const textColorFirst = css`
  color: ${({ theme }) => theme.themesColors.characterBrandingTitle85};
`;

export const divFlex = css`
  display: flex;
  position: relative;
  ${textColorFirst}
`;

export const RFQItemDetailDiv = styled.div.attrs({
  'data-class': 'RFQItemDetailDiv',
})`
  ${divFlex}
`;

// Content

export const RFQItemDetailDivContentTabsTitle = styled.div.attrs({
  'data-class': 'RFQItemDetailDivContentTabsTitle',
})`
  color: ${({ theme }) => theme.themesColors.characterBrandingTitle85};
`;

export const RFQItemDetailDivContent = styled.div.attrs({
  'data-class': 'RFQItemDetailDivContent',
})`
  ${divFlex}
  width: 100%;
  flex-direction: column;
  border-radius: 4px;
  .ant-tabs-tabpane-active > div > div {
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 11px;
  }
  .ant-tabs-tab {
    width: auto;
  }
  .ant-tabs-tab + .ant-tabs-tab {
    margin: 0 0 0 32px;
  }
  .ant-tabs-tabpane-active > div > div:last-child {
    margin-bottom: 26px;
  }
  .ant-tabs-tabpane-active > div > div:first-child,
    .ant-tabs-tabpane-active > div > div:nth-child(3) {
      background: ${({ theme }) => theme.themesColors.lightGrayBackground};
  }
  .ant-tabs-tabpane-active > div > div:nth-child(2) {
    background: ${({ theme }) => theme.themesColors.neutralBranding1};
  }
  .ant-tabs-nav {
    background: ${({ theme }) => theme.themesColors.neutralBranding1};
  }
  .ant-tabs-content-holder {
    padding: 0;
  }
  .ant-tabs-nav {
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 0;
    &::before {
      margin-left: 16px;
      margin-right: 16px;
      color: ${({ theme }) => theme.themesColors.neutralBranding5};
    }
  }
  .ant-tabs-tab-disabled {
    ${RFQItemDetailDivContentTabsTitle} {
      color: ${({ theme }) => theme.themesColors.secondaryDot45};
    }
  }
`;
