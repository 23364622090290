// @ts-nocheck
import React, {
  FC,
  useMemo,
} from 'react';

import Button from 'antd/es/button';
import MinusOutlined from '@ant-design/icons/MinusOutlined';
import PlusOutlined from '@ant-design/icons/PlusOutlined';
import { SizeType } from 'antd/lib/config-provider/SizeContext';

import {
  FieldArrayPath,
  FieldPath,
  useFieldArray, UseFormReturn,
} from 'react-hook-form';

import { useWindowSize } from 'app-wrapper/hooks';
import { InputController } from 'app-wrapper/view/controllers/InputController';

import { ReferencesFormContainer } from 'shipment-operations/view/pages/Booking/styled';
import { IShippingPartyForm } from 'shipment-operations/view/pages/Booking/ShippingParties/types';

import { Container } from './ShippingPartyReferenceForm.styled';

const RED_BORDER_HIGHLIGHTING = {
  border: '1px solid red',
  boxShadow: 'none',
};

interface IReferencesFormProps {
  formControl: UseFormReturn<any>
  fieldName: FieldArrayPath<any>
  disabled: boolean,
}

export const ShippingPartyReferenceForm: FC<IReferencesFormProps> = ({
  formControl, fieldName, disabled,
}) => {
  const { isFullMediaWidth } = useWindowSize();
  const arrayControl = useFieldArray({
    control: formControl.control,
    name: fieldName,
    keyName: 'key',
  });

  const size: SizeType = useMemo(() => (isFullMediaWidth ? 'large' : 'middle'), [isFullMediaWidth]);

  const getName = (field: FieldPath<IShippingPartyForm>, i: number) => (
    `${fieldName}.${i}.${field}` as FieldPath<IShippingPartyForm>
  );

  const getErrorOutline = (field: FieldPath<IShippingPartyForm>, i: number) => (
    formControl.getFieldState(getName(field, i)).error ? RED_BORDER_HIGHLIGHTING : {}
  );

  const handleAdd = () => {
    const maxId = (arrayControl.fields).reduce((max, cur) => (
      cur.id > max ? cur.id : max
    ), -1);
    arrayControl.append({
      id: maxId + 1,
    });
  };

  const handleRemove = (removedIndex: number) => {
    arrayControl.remove(removedIndex);
  };

  const createNumberInputHandler = (i: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
    event.target.value = event.target.value.slice(0, 16);

    if (!event.target.value && !formControl.getValues(getName('value', i))) {
      formControl.clearErrors(getName('type', i));
    }
  };

  return (
    <Container style={{ width: '100%' }}>
      {
        (arrayControl.fields).map((ref, i) => (
          <ReferencesFormContainer key={ref.key}>
            <InputController
              disabled={disabled}
              name={getName('value', i)}
              onChange={createNumberInputHandler(i)}
              style={getErrorOutline('id', i)}
            />
            <Button
              icon={i === 0 ? <PlusOutlined /> : <MinusOutlined />}
              disabled={disabled}
              type="dashed"
              size={size}
              onClick={i === 0 ? handleAdd : () => handleRemove(i)}
            />
          </ReferencesFormContainer>
        ))
      }
    </Container>
  );
};
