import React, { FC } from 'react';
import { ButtonProps } from 'antd/es/button';

import { SBLEditButton, SBLEditButtonIcon } from './SBLEditButton.styled';

const SBLEditButtonComponent: FC<ButtonProps> = (props) => (
  <SBLEditButton icon={<SBLEditButtonIcon />} {...props} />
);

export { SBLEditButtonComponent as SBLEditButton };
