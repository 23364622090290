import React, {
  FC, useEffect, useRef, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import FilterFilled from '@ant-design/icons/FilterFilled';

import { elementInViewport } from 'app-wrapper/utils';
import { EmptyStateForPages, TypeSwitch } from 'app-wrapper/view/components';

import { ARQQuotesItem, FreightQuoteContentModalSave, IARQQuotesItem } from 'monetary/view/components';

import themesColors from 'app-wrapper/view/themes/themesColors';
import { SkeletonShipment } from 'app-wrapper/view/components/Skeleton/Skeleton.component';

import { RouteNames } from 'app-wrapper/constants';
import {
  AllRequestedQuotesComponentDiv,
  AllRequestedQuotesComponentFilterShowOnly,
  AllRequestedQuotesComponentHeader,
  AllRequestedQuotesComponentQuotesDiv,
  AllRequestedQuotesComponentQuotesDivWrapper,
  AllRequestedQuotesComponentQuotesItemsDiv,
  AllRequestedQuotesComponentTableHeader,
  AllRequestedQuotesComponentTableHeaderItem,
  SkeletonShipmentWrap,
} from './AllRequestedQuotes.styled';

export interface IAllRequestedQuotesComponentProps {
  totalElements?: number
  dataElements: IARQQuotesItem[]
  isLoad: boolean
  isFavorite?: boolean
  isFavoriteDisabled?: boolean
  savedRequestName?: string
  isShowModalRequestName?: boolean;
  isLoadingRequestName?: boolean;
  hasMore?: boolean;
  linkToFunc: () => void
  onChangeSavedName: (name: string) => void;
  nextScrollOnHandler: () => void
  onChangeAllRequestShowSaved: (value: boolean) => void
  onOpenRequestSavedName: () => void
  onSaveRequestSavedName: (id?: number) => void;
  onCloseRequestSavedName: () => void;
  onClickEmptyQuotesButton: () => void;
}

export const AllRequestedQuotes: FC<IAllRequestedQuotesComponentProps> = ({
  dataElements, isLoad, nextScrollOnHandler,
  isFavorite,
  isShowModalRequestName,
  hasMore,
  onChangeAllRequestShowSaved,
  onOpenRequestSavedName,
  onClickEmptyQuotesButton,
  linkToFunc,
}) => {
  const { t } = useTranslation();

  const infiniteVisible = useRef<HTMLDivElement>(null);
  const [firstRunInfiniteVisible, setFirstRunInfiniteVisible] = useState(false);

  useEffect(() => {
    if (elementInViewport(infiniteVisible?.current || null)
      && dataElements.length
      && !firstRunInfiniteVisible) {
      setFirstRunInfiniteVisible(true);
      // test without this
      // nextScrollOnHandler();
    }
  }, [nextScrollOnHandler, infiniteVisible, dataElements.length, firstRunInfiniteVisible, hasMore]);

  return (
    <AllRequestedQuotesComponentDiv>
      <AllRequestedQuotesComponentQuotesDivWrapper>

        {isShowModalRequestName && (
          <FreightQuoteContentModalSave />
        )}

        <AllRequestedQuotesComponentQuotesDiv>
          <AllRequestedQuotesComponentHeader>
            <AllRequestedQuotesComponentFilterShowOnly>
              <TypeSwitch
                onChange={onChangeAllRequestShowSaved}
                data-class="AllRequestedQuotesShowOnlySaved"
                rightText={t('ShowOnlySaved')}
                checkedChildren="On"
                unCheckedChildren="Off"
                value={!!isFavorite}
                disabled={isLoad}
                disabledbg="true"
              />
            </AllRequestedQuotesComponentFilterShowOnly>
          </AllRequestedQuotesComponentHeader>
          <AllRequestedQuotesComponentQuotesItemsDiv>
            <AllRequestedQuotesComponentTableHeader>
              <AllRequestedQuotesComponentTableHeaderItem
                width="4%"
                paddingLeft="41px"
              >
                {}
              </AllRequestedQuotesComponentTableHeaderItem>
              <AllRequestedQuotesComponentTableHeaderItem
                width="17.2%"
                paddingLeft="44px"
              >
                {t('Origin')}
              </AllRequestedQuotesComponentTableHeaderItem>
              <AllRequestedQuotesComponentTableHeaderItem
                width="17.2%"
                paddingLeft="49px"
              >
                {t('Destination')}
              </AllRequestedQuotesComponentTableHeaderItem>
              <AllRequestedQuotesComponentTableHeaderItem
                width="17.2%"
                paddingLeft="48px"
              >
                {t('Container')}
              </AllRequestedQuotesComponentTableHeaderItem>
              <AllRequestedQuotesComponentTableHeaderItem
                width="17.2%"
                paddingLeft="48px"
              >
                {t('Commodity')}
              </AllRequestedQuotesComponentTableHeaderItem>
              <AllRequestedQuotesComponentTableHeaderItem
                width="calc(17.2% - 8px)"
                paddingLeft="45px"
              >
                {t('Created by')}
              </AllRequestedQuotesComponentTableHeaderItem>
              <AllRequestedQuotesComponentTableHeaderItem
                width="10%"
                paddingLeft="8px"
                isCenter
              >
                {t('Quotes')}
              </AllRequestedQuotesComponentTableHeaderItem>
            </AllRequestedQuotesComponentTableHeader>

            {dataElements.length ? (
              <InfiniteScroll
                dataLength={Number(dataElements.length)}
                next={nextScrollOnHandler}
                hasMore
                loader={
                  isLoad
                  && <SkeletonShipment rowCount={1} />
                }
                scrollableTarget="scrollableDiv"
                height="calc(100vh - 56px - 46px - 92px)"
              >
                <SkeletonShipmentWrap>
                  {dataElements.map((itemQuote, index) => (
                    <ARQQuotesItem
                      container={itemQuote.container}
                      name={itemQuote.name}
                      linkTo={`${RouteNames.ALL_REQUESTED_QUOTES()}?quotaid=${itemQuote.id}`}
                      linkToFunc={linkToFunc}
                      id={itemQuote.id}
                      status={itemQuote.status}
                      type={itemQuote.type}
                      createdBy={itemQuote.createdBy}
                      createdAt={itemQuote.createdAt}
                      origin={itemQuote.origin}
                      destination={itemQuote.destination}
                      containersType={itemQuote.containersType}
                      containersTypes={itemQuote.containersTypes}
                      commodity={itemQuote.commodity}
                      profile={itemQuote.profile}
                      customer={itemQuote.customer}
                      hsCodes={itemQuote.hsCodes}
                      key={`AllRequestedQuotesComponent_allQuotesData_${itemQuote.id}_${index + 0}`}
                      bookmark={itemQuote.bookmark}
                      quotasCount={itemQuote.quotasCount}
                      favoriteQuotasCount={itemQuote.favoriteQuotasCount}
                      onOpenRequestSavedName={onOpenRequestSavedName}
                    />
                  ))}
                </SkeletonShipmentWrap>
              </InfiniteScroll>
            ) : null}
            {!dataElements.length && isLoad ? (<SkeletonShipmentWrap><SkeletonShipment rowCount={10} /></SkeletonShipmentWrap>) : null}

            {!dataElements.length && !isLoad ? (
              <EmptyStateForPages
                icon={(
                  <FilterFilled
                    width={28}
                    height={22}
                    style={{
                      color: themesColors.neutralBranding65,
                      width: '28px',
                      height: '22px',
                    }}
                  />
                )}
                headerText={t('Your search history is empty.')}
                contentText={t('Start browsing our prices and routes that interest you.')}
                buttonText={t('Search pricing')}
                onClickButton={onClickEmptyQuotesButton}
              />
            ) : null}
            <div ref={infiniteVisible} />
          </AllRequestedQuotesComponentQuotesItemsDiv>
        </AllRequestedQuotesComponentQuotesDiv>
      </AllRequestedQuotesComponentQuotesDivWrapper>
    </AllRequestedQuotesComponentDiv>
  );
};
