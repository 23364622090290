import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';

import { ShipmentTransportationHeaderComponent } from './ShipmentTransportationHeader.component';

const ShipmentTransportationHeaderContainer: FC = () => {
  const { carrierReference } = useSelector(R.selectors.transportationOverview.getTransportationOverview);
  const oceanCarrierName = useSelector(R.selectors.transportationOverview.getOceanCarrierName);
  const shipment = useSelector(R.selectors.shipment.getShipment);

  return (
    <ShipmentTransportationHeaderComponent
      carrierName={oceanCarrierName}
      bookingNumber={carrierReference}
      status={shipment?.bookingStatus}
    />
  );
};
export {
  ShipmentTransportationHeaderContainer as ShipmentTransportationHeader,
};
