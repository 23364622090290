import i18n from 'app-wrapper/i18n/i18n';

import { chargeCodeMeasureBy, chargeCodePriceBy } from 'monetary/constants';

export const accessorialsFilterPerUnit = (priceBy: string, measureBy: string, containerType?: string) => {
  if (priceBy === chargeCodePriceBy.CONTAINER
    && measureBy === chargeCodeMeasureBy.FLAT) {
    return i18n.t('measureBy.perContainer', { containerType: containerType || '' });
  }

  if (priceBy === chargeCodePriceBy.BOL) {
    if (measureBy === chargeCodeMeasureBy.FLAT) {
      return i18n.t('measureBy.perBL');
    }
  }

  switch (measureBy) {
    case chargeCodeMeasureBy.KM_MILE:
      return i18n.t('measureBy.KM_MILE');
    case chargeCodeMeasureBy.DAY:
      return i18n.t('measureBy.DAY');
    case chargeCodeMeasureBy.HOUR:
      return i18n.t('measureBy.HOUR');
    case chargeCodeMeasureBy.KG_LB:
      return i18n.t('measureBy.KG_LB');
    case chargeCodeMeasureBy.WM:
      return i18n.t('measureBy.WM');
    case chargeCodeMeasureBy.CBM_CF:
      return i18n.t('measureBy.CBM_CF');
    default:
      return '';
  }
};
