import BorderlessTableOutlined from '@ant-design/icons/BorderlessTableOutlined';
import HomeOutlined from '@ant-design/icons/HomeOutlined';
import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { EMembershipType, MembershipTypeNames } from 'user-management/constants';
import { OrganizationDTM } from 'user-management/models/dtm';
import { NoDataLineItem } from 'user-management/view/components';

import {
  OrganizationAddressData,
  OrganizationAddressHeader,
  OrganizationAddressIconBG,
  OrganizationAddressItem,
  OrganizationAddressRow,
  OrganizationAddressTitle,
  OrganizationAddressValue,
  OrganizationAddressWrapper,
} from './OrganizationFinancialAndLegal.styled';

interface IOrganizationAddressComponentProps {
  organization?: OrganizationDTM
}

export const OrganizationFinancialAndLegalComponent: FC<IOrganizationAddressComponentProps> = ({
  organization,
}) => {
  const { t } = useTranslation();
  const wca = useMemo(() => (
    organization?.memberships?.find((item) => (item.type === EMembershipType.WCA_ID))
  ), [organization]);
  const dnb = useMemo(() => (
    organization?.memberships?.find((item) => (item.type === EMembershipType.DNB_ID))
  ), [organization]);

  return (
    <OrganizationAddressWrapper>
      <OrganizationAddressHeader>{t('Financial & Legal Information')}</OrganizationAddressHeader>
      <OrganizationAddressRow>
        <OrganizationAddressItem>
          <OrganizationAddressIconBG>
            <HomeOutlined />
          </OrganizationAddressIconBG>
          <OrganizationAddressData>
            <OrganizationAddressTitle>{t('Tax ID')}</OrganizationAddressTitle>
            <OrganizationAddressValue>{organization?.taxId || <NoDataLineItem />}</OrganizationAddressValue>
          </OrganizationAddressData>
        </OrganizationAddressItem>
        <OrganizationAddressItem>
          <OrganizationAddressIconBG>
            <BorderlessTableOutlined />
          </OrganizationAddressIconBG>
          <OrganizationAddressData>
            <OrganizationAddressTitle>{t('EIN Number')}</OrganizationAddressTitle>
            <OrganizationAddressValue>{organization?.businessRegistrationNumber.getValidValue() || <NoDataLineItem />}</OrganizationAddressValue>
          </OrganizationAddressData>
        </OrganizationAddressItem>
      </OrganizationAddressRow>
      <OrganizationAddressRow>
        <OrganizationAddressItem>
          <OrganizationAddressIconBG>
            <BorderlessTableOutlined />
          </OrganizationAddressIconBG>
          <OrganizationAddressData>
            <OrganizationAddressTitle>{MembershipTypeNames[EMembershipType.WCA_ID]}</OrganizationAddressTitle>
            <OrganizationAddressValue>{wca?.getValidValue() || <NoDataLineItem />}</OrganizationAddressValue>
          </OrganizationAddressData>
        </OrganizationAddressItem>
        <OrganizationAddressItem>
          <OrganizationAddressIconBG>
            <BorderlessTableOutlined />
          </OrganizationAddressIconBG>
          <OrganizationAddressData>
            <OrganizationAddressTitle>{MembershipTypeNames[EMembershipType.DNB_ID]}</OrganizationAddressTitle>
            <OrganizationAddressValue>{dnb?.getValidValue() || <NoDataLineItem />}</OrganizationAddressValue>
          </OrganizationAddressData>
        </OrganizationAddressItem>
      </OrganizationAddressRow>
    </OrganizationAddressWrapper>
  );
};
