import React from 'react';
import { useSelector } from 'react-redux';

import { R } from 'user-management/repository';
import { UC } from 'user-management/controllers';

import { OrganizationPaymentMethod } from 'shipment-operations/constants';

import { TradeReferencesComponent } from './TradeReferences.component';

const TradeReferencesContainer = () => {
  const getPaymentMethod = useSelector(R.selectors.customer.getPaymentMethodForm);
  const shouldValidate = useSelector(R.selectors.customer.getWasPaymentMethodOnceSubmitted);

  return (
    <TradeReferencesComponent
      shouldValidate={shouldValidate}
      getPaymentMethod={getPaymentMethod}
      isPrepayment={getPaymentMethod?.type?.toUpperCase() === OrganizationPaymentMethod.PREPAYMENT}
      setPaymentType={UC.customer.setPaymentTypeForm}
      setTerm={UC.customer.setTermForm}
      setCreditLimit={UC.customer.setCreditLimitForm}
    />
  );
};

export { TradeReferencesContainer as TradeReferences };
