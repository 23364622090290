import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';

import { ShipmentOverviewShippingPartiesComponent } from './ShipmentOverviewShippingParties.component';

const ShipmentOverviewShippingPartiesContainer: FC = () => {
  const isLoading = useSelector(R.selectors.overview.getLoadingStatus);
  const houseShipper = useSelector(R.selectors.overview.getHouseShipper);
  const houseConsignee = useSelector(R.selectors.overview.getHouseConsignee);
  const houseNotifyParty = useSelector(R.selectors.overview.getHouseNotifyParty);
  const isLoadingPage = useSelector(R.selectors.overview.getLoadingStatus);

  return (
    <ShipmentOverviewShippingPartiesComponent
      isLoading={isLoading}
      isLoadingPage={isLoadingPage}
      houseShipper={houseShipper}
      houseConsignee={houseConsignee}
      houseNotifyParty={houseNotifyParty}
    />
  );
};

export {
  ShipmentOverviewShippingPartiesContainer as ShipmentOverviewShippingParties,
};
