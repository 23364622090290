import { Type } from 'class-transformer';
import {
  IsBoolean,
  IsDefined,
  IsEnum,
  IsNumber,
  IsOptional, IsString, ValidateNested,
} from 'class-validator';
import { BaseDTM } from 'proto/BaseDTM';
import { EFreightPaymentTerms } from '../Freight/freightPaymentTerms.dtm';
import { FreightQuotaTransportationDTM, IFreightQuotaTransportationDTM } from '../Freight/freightQuotaContent.dtm';
import { DetailBreakdownTransportationDTM, IDetailBreakdownTransportationDTM } from './detailBreakdown.dtm';

export interface IDetailRoutingContainerStepDTM {
  name?: string
  summary?: number
  cost?: number
  totalUnits: number
  numberOfUnits?: number
  infoSteps?: string[]
  code?: string
  isUnits?: boolean
  isIncludedBaseRate?: boolean
  descriptionText?: string
}

export class DetailRoutingContainerStepDTM extends BaseDTM<IDetailRoutingContainerStepDTM> {
  @IsOptional()
  @IsString()
  name?: string;

  @IsOptional()
  @IsNumber()
  summary?: number;

  @IsOptional()
  @IsNumber()
  cost?: number;

  @IsDefined()
  @IsNumber()
  totalUnits: number;

  @IsOptional()
  @IsNumber()
  numberOfUnits?: number;

  @IsOptional()
  @IsString({ each: true })
  infoSteps?: string[];

  @IsOptional()
  @IsString()
  code?: string;

  @IsOptional()
  @IsBoolean()
  isUnits?: boolean;

  @IsOptional()
  @IsBoolean()
  isIncludedBaseRate?: boolean;

  @IsOptional()
  @IsString()
  descriptionText?: string;
}

export interface IDetailRoutingStepsDTM {
  summary: number
  steps: IDetailRoutingContainerStepDTM[]
}

export class DetailRoutingStepsDTM extends BaseDTM<IDetailRoutingStepsDTM> {
  @IsDefined()
  @IsNumber()
  summary: number;

  @IsDefined()
  @ValidateNested({ each: true })
  @Type(() => DetailRoutingContainerStepDTM)
  steps: DetailRoutingContainerStepDTM[];
}

export interface IDetailRoutingDTM {
  from: string
  to: string
  duration: number
  etd: string
  eta: string
  transportType: string
  paymentTerms: EFreightPaymentTerms
  VesselName?: string
  VoyageNumber?: string
  arrivalTerminal?: string
  departureTerminal?: string
  transportations?: IFreightQuotaTransportationDTM[]
  fees?: IDetailRoutingStepsDTM
  service?: IDetailBreakdownTransportationDTM
}

export class DetailRoutingDTM extends BaseDTM<IDetailRoutingDTM> {
  @IsDefined()
  @IsString()
  from: string;

  @IsDefined()
  @IsString()
  to: string;

  @IsDefined()
  @IsNumber()
  duration: number;

  @IsDefined()
  @IsString()
  etd: string;

  @IsDefined()
  @IsString()
  eta: string;

  @IsDefined()
  @IsString()
  transportType: string;

  @IsDefined()
  @IsEnum(EFreightPaymentTerms)
  paymentTerms: EFreightPaymentTerms;

  @IsOptional()
  @IsString()
  VesselName?: string;

  @IsOptional()
  @IsString()
  VoyageNumber?: string;

  @IsOptional()
  @IsString()
  arrivalTerminal?: string;

  @IsOptional()
  @IsString()
  departureTerminal?: string;

  @IsOptional()
  @ValidateNested({ each: true })
  @Type(() => FreightQuotaTransportationDTM)
  transportations?: FreightQuotaTransportationDTM[];

  @IsOptional()
  @ValidateNested()
  @Type(() => DetailRoutingStepsDTM)
  fees?: DetailRoutingStepsDTM;

  @IsOptional()
  @ValidateNested()
  @Type(() => DetailBreakdownTransportationDTM)
  service?: DetailBreakdownTransportationDTM;
}
