import React, { useMemo, FC } from 'react';
import { useTranslation } from 'react-i18next';
import get from 'lodash/fp/get';
import { useLocation } from 'react-router';

import { normalizationCost } from 'app-wrapper/utils';
import {
  NameOfDocument,
  StyledStatus,
} from 'shipment-operations/view/pages/ShipmentBilling/ShipmentBilling.styled';
import { RouteNames } from 'app-wrapper/constants';
import { ShareInvoice } from 'shipment-operations/view/components';
import { InvoiceStatusesEnum as statuses, RECEIVABLES } from 'shipment-operations/constants';
import { InvoicesTableDTM } from 'monetary/models/dtm/Invoices.dtm';
import { CustomTable } from 'monetary/view/components';
import { Layout } from 'app-wrapper/view/guideline/Layout/Layout.styled';

interface ITableComponentProps {
  data: InvoicesTableDTM[]
  totalElements: number
  onChangePage: (page: number) => void
  type: string
  activePage: number
}

const TableComponent: FC<ITableComponentProps> = ({
  data, totalElements, onChangePage, type, activePage,
}) => {
  const { pathname } = useLocation();
  const { t } = useTranslation();

  const status = useMemo(() => ({
    [statuses.PAID]: <StyledStatus color="#75CB3F">{t('billingStatuses.paid')}</StyledStatus>,
    [statuses.UNPAID]: <StyledStatus color="#6C79DE">{t('billingStatuses.unpaid')}</StyledStatus>,
    [statuses.PARTIALLY_PAID]: <StyledStatus color="#F1AE00">{t('billingStatuses.partiallyPaid')}</StyledStatus>,
    [statuses.APPLIED]: <StyledStatus color="#75CB3F">{t('billingStatuses.applied')}</StyledStatus>,
    [statuses.VOID]: <StyledStatus color="#82909D">{t('billingStatuses.void')}</StyledStatus>,
  }), [t]);

  const columns = useMemo(() => [
    {
      title: '',
      dataIndex: 'download',
      key: 'download',
      render: (item: string, record: InvoicesTableDTM | {}) => {
        const invoice = record as InvoicesTableDTM;

        return (
          <ShareInvoice invoiceId={invoice.id} shipmentId={String(invoice.shipment)} isAPInvoiceType={pathname === RouteNames.FINANCES_AP_INVOICES()} />
        );
      },
    },
    {
      title: t('ID'),
      dataIndex: 'id',
      key: 'id',
      align: 'left' as 'left',
      render: (item: string, record: InvoicesTableDTM | {}) => {
        const shipment = get(['shipment'], record);
        const number = get(['number'], record);
        return (
          <NameOfDocument to={RouteNames.SHIPMENT_BILLING_INVOICE(shipment, item, type)}>{number}</NameOfDocument>
        );
      },
    },
    {
      title: type === RECEIVABLES ? t('debtorName') : t('vendor'),
      dataIndex: 'debtorName',
      key: 'debtorName',
      align: 'left' as 'left',
      render: (item: string, record: InvoicesTableDTM | {}) => {
        const debtorName = get(['debtorName'], record);
        const vendor = get(['vendor'], record);
        return type === RECEIVABLES ? debtorName : vendor;
      },
    },
    {
      title: t('shipment'),
      dataIndex: 'shipmentName',
      key: 'shipmentName',
      align: 'left' as 'left',
      render: (item: string) => item,
    },
    {
      title: t('created'),
      dataIndex: 'created',
      key: 'created',
      align: 'left' as 'left',
    },
    {
      title: t('dueDate'),
      dataIndex: 'dueDate',
      key: 'dueDate',
      align: 'left' as 'left',
    },
    {
      title: t('status'),
      dataIndex: 'status',
      key: 'status',
      align: 'left' as 'left',
      render: (item: statuses) => status[item],
    },
    {
      title: t('total'),
      dataIndex: 'total',
      key: 'total',
      align: 'right' as 'right',
      render: (sum: number) => <>$ {normalizationCost(sum, { toFixed: 2, thousandthSeparatorComma: true })}</>,
    },
    {
      title: t('balance'),
      dataIndex: 'balance',
      key: 'balance',
      align: 'right' as 'right',
      render: (sum: number) => <>$ {normalizationCost(sum, { toFixed: 2, thousandthSeparatorComma: true })}</>,
    },
  ], [data]);

  return (
    <Layout>
      <CustomTable
        columns={columns}
        dataSource={data}
        pagination={{
          onChange: (page) => onChangePage(page),
          pageSize: 15,
          total: totalElements,
          showSizeChanger: false,
          current: activePage,
        }}
      />
    </Layout>
  );
};

export { TableComponent };
