import styled, { css } from 'styled-components';

const textColorFirst = css`
  color: ${({ theme }) => theme.themesColors.characterBrandingTitle85};
`;

const divFlex = css`
  display: flex;
  position: relative;
  ${textColorFirst}
`;

export const CarouselDiv = styled.div`
  ${divFlex}
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const CarouselArrow = css`
  ${divFlex}
  align-items: center;
  justify-content: center;
  width: 22px;
  height: 22px;
  background-color: ${({ theme }) => theme.themesColors.neutralBranding1};
  border: 1px solid ${({ theme }) => theme.themesColors.neutralBranding5};
  border-radius: 3px;
  cursor: pointer;
  svg {
    color: ${({ theme }) => theme.themesColors.neutralBranding9};
  }
`;

export const CarouselArrowWrapper = styled.div`
  ${divFlex}
  align-items: center;
  justify-content: center;
  width: 22px;
  height: 22px;
`;

export const CarouselArrowPrev = styled.div`
  ${CarouselArrow}
`;

export const CarouselArrowNext = styled.div`
  ${CarouselArrow}
`;

export const CarouselOptionItemWrapper = styled.div`
  ${divFlex}
  width: 100%;
  padding: 0 12px;
`;

const activeTab = css`
  border: 1px solid ${({ theme }) => theme.themesColors.neutralBranding6};
  background-color: ${({ theme }) => theme.themesColors.neutralBranding1};
`;

const deactivateTabSpan = css`
  text-decoration-line: underline;
  text-decoration-style: dotted;
  text-decoration-color: ${({ theme }) => theme.themesColors.middleGraySecondary};
  text-underline-offset: 4px;

  &:hover {
    text-decoration-line: none;
    text-decoration-style: none;
    color: ${({ theme }) => theme.themesColors.primaryBranding6};
  }
`;

interface ICarouselOptionItemProps {
  isActive: boolean;
  isOneItem: boolean;
  isTwoItem: boolean;
}

export const CarouselOptionItem = styled.div<ICarouselOptionItemProps>`
  ${divFlex}
  align-items: center;
  justify-content: center;
  height: 32px;
  width: ${({ isTwoItem, isOneItem }) => {
    if (isOneItem) return '50%';

    if (isTwoItem) return '50%';

    return '33.3%';
  }};
  cursor: pointer;

  span {
    ${(props) => !props.isActive && deactivateTabSpan}
  }

  ${(props) => props.isActive && activeTab}
  color: ${({ theme, isActive }) => (isActive
    ? theme.themesColors.neutralBranding10
    : theme.themesColors.darkGrayMiddle)};
  svg {
    margin-top: -1px;
    margin-right: 8px;
  }
  user-select: none;
`;
