import styled from 'styled-components';
import Spin from 'antd/es/spin';
import Typography from 'antd/es/typography';

import themesColors from 'app-wrapper/view/themes/themesColors';
import { Button } from 'app-wrapper/view/components';

export const Wrap = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const Header = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;

  width: 100%;
  height: 56px;

  padding: 8px 8px 8px 24px;

  background-color: ${themesColors.neutralBranding1};

  font-size: 20px;
  font-weight: 500;

  box-shadow: 0px -1px 0px 0px ${themesColors.neutralBranding3} inset;
`;

export const Content = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(249, 249, 251, 1);
`;

export const Spinner = styled(Spin)`
  margin: auto;
`;

export const ImageWrap = styled.div`
  width: 164px;
  height: 164px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: white;
`;

export const Image = styled.img`
  width: 100px;
  height: auto;
`;

export const Title = styled(Typography)`
  margin-top: 8px;
  max-width: 570px;
  font-size: 20px;
  line-height: 28px;
  font-weight: 500;
`;

export const SubTitle = styled(Typography)`
  max-width: 570px;
  font-size: 14px;
  line-height: 22px;
  color: rgba(115, 129, 155, 1);
  text-align: center;
`;

export const Buttons = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: row;
`;

export const ViewOrganizationButton = styled(Button)``;

export const BackToOverviewButton = styled(Button)`
  margin-left: 8px;
`;
