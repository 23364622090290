import { emptyScrollbar } from 'app-wrapper/view/themes/components/emptyScrollbar';
import styled from 'styled-components';
import CopyOutlined from '@ant-design/icons/CopyOutlined';

import { divFlex } from 'app-wrapper/view/themes/mixin';
import { Button } from 'app-wrapper/view/components';

export const AccountDprtmByIdContentTableSpanEllipsis = styled.span.attrs({
  'data-class': 'AccountDprtmByIdContentTableSpanEllipsis',
})`
  max-width: 100%;
  position: relative;
  display: inline;

  text-overflow: ellipsis;
  overflow: clip;
  white-space: nowrap;

  svg {
    color: ${({ theme }) => theme.themesColors.neutralBranding6};
  }
`;

export const AccountDprtmByIdContentTableTitleWrap = styled.div.attrs({
  'data-class': 'AccountDprtmByIdContentTableTitleWrap',
})`
  ${divFlex}

  height: 48px;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.themesColors.neutralBranding3};
  padding-bottom: 16px;
`;

export const AccountDprtmByIdPrimaryContact = styled.div.attrs({
  'data-class': 'AccountDprtmByIdPrimaryContact',
})`
  ${divFlex}
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;

  width: 50%;
`;

export const AccountDprtmByIdPrimaryContactIconBG = styled.div.attrs({
  'data-class': 'AccountDprtmByIdPrimaryContactIconBG',
})`
  ${divFlex}
  align-items: center;
  justify-content: center;

  width: 32px;
  min-width: 32px;
  height: 32px;
  min-height: 32px;

  background-color: transparent;
  border-radius: 4px;
  color: ${({ theme }) => theme.themesColors.primaryBranding5};
  border: 1px solid ${({ theme }) => theme.themesColors.neutralBranding4};
  font-size: 14px;

  svg {
    color: ${({ theme }) => theme.themesColors.primaryBranding7};
  }
`;

export const AccountDprtmByIdPrimaryContactData = styled.div.attrs({
  'data-class': 'AccountDprtmByIdPrimaryContactData',
})`
  ${divFlex}
  flex-flow: column nowrap;
  justify-content: center;
  align-items: flex-start;

  margin-left: 16px;
`;

export const AccountDprtmByIdPrimaryContactTitle = styled.div.attrs({
  'data-class': 'AccountDprtmByIdPrimaryContactTitle',
})`
  ${divFlex}

  font-size: 12px;
  color: ${({ theme }) => theme.themesColors.darkGrayMiddle};
  line-height: 20px;
  font-weight: 400;
`;

export const AccountDprtmByIdCopyIcon = styled(CopyOutlined).attrs({
  'data-class': 'AccountDprtmByIdCopyIcon',
})`
  color: ${({ theme }) => theme.themesColors.secondaryDot45};
  font-size: 12px;
  margin-left: 3px;
  cursor: copy !important;
`;

export const AccountDprtmByIdPrimaryContactValue = styled.div.attrs({
  'data-class': 'AccountDprtmByIdPrimaryContactValue',
})`
  ${divFlex}
  align-items: center;

  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
`;

export const AccountDprtmByIdContentTableTitle = styled.div.attrs({
  'data-class': 'AccountDprtmByIdContentTableTitle',
})`
  ${divFlex}
  width: 100%;
  align-items: center;

  color: ${({ theme }) => theme.themesColors.neutralBranding10};
  font-size: 16px;
  font-weight: 500;
`;

export const AccountDprtmByIdContentTableHeader = styled.div.attrs({
  'data-class': 'AccountDprtmByIdContentTableHeader',
})`
  ${divFlex}
  align-items: center;

  height: 36px;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.themesColors.neutralBranding3};
`;

export const AccountDprtmByIdContentTableHeaderEditBtn = styled(Button).attrs((props: {
  isRight?: boolean,
}) => ({
  isRight: props.isRight,
  'data-class': 'AccountDprtmByIdContentTableHeaderEditBtn',
}))`
  ${divFlex}
  width: 155px;
  height: 32px;
  ${({ isRight }) => (isRight ? 'margin-left: auto;' : '')};

  svg {
    color: ${({ theme }) => theme.themesColors.neutralBranding1};
    margin-right: 9px;
  }
  span {
    color: ${({ theme }) => theme.themesColors.neutralBranding1};
  }
`;

export const AccountDprtmByIdContentTableHeaderItem = styled.div.attrs((props: {
  isCenter?: boolean,
  width?: string,
  paddingLeft?: string,
}) => ({
  isCenter: props.isCenter,
  width: props.width,
  paddingLeft: props.paddingLeft,
  'data-class': 'AccountDprtmByIdContentTableHeaderItem',
}))`
  ${divFlex}
  align-items: center;
  justify-content: ${({ isCenter }) => (isCenter ? 'center' : 'left')};

  width: ${({ width }) => (width || 'auto')};
  padding-left: ${({ paddingLeft }) => (paddingLeft || '0')};

  color: ${({ theme }) => theme.themesColors.secondaryDot45};
  font-size: 12px;
  font-weight: 400;
`;
export const AccountDprtmByIdContentTableContentItem = styled.div.attrs({
  'data-class': 'AccountDprtmByIdContentTableContentItem',
})`
  ${divFlex}
  flex-direction: column;
  width: 100%;

  border-bottom: 1px solid ${({ theme }) => theme.themesColors.neutralBranding3}
`;

export const AccountDprtmByIdContentTableContentItemTop = styled.div.attrs({
  'data-class': 'AccountDprtmByIdContentTableContentItemTop',
})`
  ${divFlex}
  ${emptyScrollbar}

  height: 55px;
`;

export const AccountDprtmByIdContentTableContentTitle = styled.div.attrs((props: {
  isActive?: boolean,
  isCapitalize?: boolean,
  isFirst?: boolean,
  isLeft?: boolean,
}) => ({
  isActive: props.isActive,
  isCapitalize: props.isCapitalize,
  isFirst: props.isFirst,
  isLeft: props.isLeft,
  'data-class': 'AccountDprtmByIdContentTableContentTitle',
}))`
  ${divFlex}
  width: 25%;
  align-items: center;
  justify-content: ${({ isLeft }) => (isLeft ? 'left' : 'center')};

  /* margin-left: 2px; */
  color: ${({ theme }) => theme.themesColors.characterBrandingTitle85};
  color: ${({ theme, isActive }) => (isActive ? theme.themesColors.primaryBranding6 : theme.themesColors.characterBrandingTitle85)};
  font-size: 14px;
  font-weight: 400;

  ${({ isCapitalize }) => isCapitalize && 'text-transform: capitalize'};
`;

export const AccountDprtmByIdContentTableContentLink = styled.div.attrs((props: {
  width?: string,
}) => ({
  width: props.width,
  'data-class': 'AccountDprtmByIdContentTableContentLink',
}))`
  ${divFlex}
  width: ${({ width }) => (width || '42.7%')};
  align-items: center;
  justify-content: left;

  margin-left: auto;
  color: ${({ theme }) => theme.themesColors.primaryBranding7};
  font-size: 14px;
  font-weight: 400;

  text-overflow: ellipsis;
  overflow: clip;
  white-space: nowrap;
  cursor: pointer;

  > span:first-letter {
    text-transform: uppercase;
  }

  svg {
    color: ${({ theme }) => theme.themesColors.secondaryDot45};
  }
`;
