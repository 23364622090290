import { createSlice } from 'app-wrapper/createSlice';

import { asyncThunkPost } from 'app-wrapper/utils';

export interface OrganizationState {
  status: string
  result: unknown
}

export interface RegisterOrganizationParams {
  address?: {
    address1: string
    address2: string
    city: string
    country: string
    closestPort: string
    postalCode: string
  }

  name: string
  ein: string
  phone: string
  phone2: string
  state?: string
  webUrl: string
  wcaId: string
  dnbId: string
  member?: boolean
  termsAndConditions?: boolean
}

const initialState: OrganizationState = {
  status: '',
  result: null,
};

export const registerOrganization = asyncThunkPost<RegisterOrganizationParams>(
  'organization/register',
  '/api/v1/organizations',
);

export const organizationSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(registerOrganization.fulfilled, (state, { payload }) => {
        state.status = 'idle';
        state.result = payload;
      })
      .addCase(registerOrganization.rejected, (state, { payload }) => {
        state.status = 'rejected';
        state.result = payload;
      });
  },
});

export const organizationReducer = organizationSlice.reducer;
