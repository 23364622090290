import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { PackageTypeNames, PackageType, ReversePackageTypesCodes } from 'app-wrapper/types/PackageType';
import { Option } from 'app-wrapper/view/components';
import { CargoDTM } from 'shipment-operations/models/dtm';

import { SelectStyled } from './PackTypeSelect.styled';

interface PackTypeSelectComponentProps {
  packageType: CargoDTM['packageType']
  error?: boolean
}

export const PackTypeSelectComponent: FC<PackTypeSelectComponentProps> = ({
  packageType,
  error,
}) => {
  const { t } = useTranslation();

  return (
    <SelectStyled
      placeholder={t('Type')}
      value={packageType ? ReversePackageTypesCodes[packageType as unknown as keyof typeof ReversePackageTypesCodes] : undefined}
      error={error}
      allowClear={!!packageType}
      size="middle"
      disabled
    >
      {Object.keys(PackageTypeNames).map((type) => (
        <Option key={type} value={type}>
          {t(PackageTypeNames[type as PackageType])}
        </Option>
      ))}
    </SelectStyled>
  );
};
