import { useMemo } from 'react';
import { SizeType } from 'antd/lib/config-provider/SizeContext';

import { useWindowSize } from 'app-wrapper/hooks';

export const useResponsiveSize = (): SizeType => {
  const { isFullMediaWidth } = useWindowSize();
  return useMemo(() => (
    isFullMediaWidth ? 'large' : 'middle'
  ), [isFullMediaWidth]);
};
