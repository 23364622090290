import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';
import { IGroupedChargesDTM, ChargeViewDtm } from 'shipment-operations/models/dtm';

import { ContainersTableComponent } from './ContainersTable.component';

interface IContainersTableProps {
  dataSource: ChargeViewDtm[]
  innerTableData: IGroupedChargesDTM
  subTitle?: string
}

const ContainersTableContainer: FC<IContainersTableProps> = ({ dataSource, innerTableData, subTitle }) => {
  const permissions = useSelector(R.selectors.shipment.getShipmentChargesPermissions);
  const mode = useSelector(R.selectors.shipmentCharges.getTabOwner);
  const isPercentage = useSelector(R.selectors.shipmentCharges.getIsPercentage);

  return (
    <ContainersTableComponent
      dataSource={dataSource}
      innerTableData={innerTableData}
      permissions={permissions}
      mode={mode}
      subTitle={subTitle}
      isPercentage={isPercentage}
    />
  );
};

export { ContainersTableContainer as ContainersTable };
