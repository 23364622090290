import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { UC } from 'user-management/controllers';
import { R } from 'user-management/repository';

import { UserProfileComponent } from './UserProfile.component';

const UserProfileContainer: FC = () => {
  const isCustomerOrg = useSelector(R.selectors.userOrganizationData.getIsCustomerOrganization);

  return (
    <UserProfileComponent
      isCustomerOrg={isCustomerOrg}
      onUpload={UC.userProfile.uploadData}
    />
  );
};

export {
  UserProfileContainer as UserProfile,
};
