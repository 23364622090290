import styled from 'styled-components';

import { divFlex } from 'app-wrapper/view/themes/mixin';

export const GetStepBreakdownItemRowItemWrapper = styled.div.attrs(() => ({
  'data-class': 'GetStepBreakdownItemRowItemWrapper',
}))`
  .ant-collapse {
    width: 100%;
    border: none;
    background: ${({ theme }) => theme.themesColors.neutralBranding1} !important;
    padding-right: 0 !important;
    padding-left: 0 !important;
    margin-bottom: 7px !important;
    padding-bottom: 0 !important;
    .ant-collapse-header {
      font-weight: 500;
      height: 55px;
      padding-top: 16px;
      padding-bottom: 16px;
      padding-left: 0 !important;
      transition: none;
    }
    .ant-collapse-content {
      .ant-collapse-content-box {
        padding: 0;
      }
      .ant-collapse-header {
      padding-top: 8px;
      padding-bottom: 8px;
      }
    }
    .ant-collapse {
      .ant-collapse-header {
        font-weight: 400;
        height: 40px;
      }
    .ant-collapse {
      .ant-collapse-header {
          height: 36px;
        }
      }
    }
  }
  .GetStepBreakdownItemRowItemContainerItem__Fees {
    height: 38px;
  }
  .GetStepBreakdownItemRowItem__Fees,
  .GetStepBreakdownItemRowItem__Services {
    .ant-collapse-item-active {
      margin-left: 34px !important;
      > .ant-collapse-header {
        left: -34px !important;
        width: 100% !important;
      }
      .ant-collapse-content-active {
        padding-left: 0 !important;
        margin-left: 7px !important;
        width: calc(100% - 42px) !important;
      }
    }
  }
  .ant-collapse-content {
    border-color: ${({ theme }) => theme.themesColors.neutralBranding4};
  }
  .ant-collapse-content-box {
    padding-right: 0;
  }
  .ant-collapse-header {
    width: 100%;
    padding-right: 0 !important;
  }
  .ant-collapse-item {
    border-color: ${({ theme }) => theme.themesColors.neutralBranding4};
    &:nth-last-child(1) {
      border-bottom: none;
    }
    &.ant-collapse-item-active {
      margin-left: 34px;
      > .ant-collapse-header {
        color: ${({ theme }) => theme.themesColors.primaryBranding6} !important;
        left: -34px;
        width: calc(100% + 34px);
      }
      .ant-collapse-content-box {
        padding-left: 0;
        .ant-collapse-header {
          padding-left: 0;
        }
      }
      .ant-collapse-content {
        .ant-collapse-content {
          .ant-collapse-content {
            margin-left: 10px;
          }
        }
      }
      .ant-collapse-item-active {
        margin-left: 0px;
        width: 100%;
        > .ant-collapse-header {
          left: 0px;
          width: 100%;
        }
        .ant-collapse-content {
          padding-left: 34px;
          width: 100%;
        }
        .ant-collapse-item-active {
          margin-left: 34px;
          width: calc(100% - 34px);
          > .ant-collapse-header {
            left: -34px;
            width: calc(100% + 34px);
          }
          .ant-collapse-content-box {
            width: calc(100% - 10px);
          }
          .ant-collapse-content {
            padding-left: 0;
          }
        }
      }
    }
  }
  .FreightQuoteFormRequest__Row__Commodity__content_title_icon {
    color: ${({ theme }) => theme.themesColors.darkGrayMiddle};
  }
`;

export const GetStepBreakdownItemRowItem = styled.div.attrs({
  'data-class': 'GetStepBreakdownItemRowItem',
})`
  ${divFlex}
`;

export const GetStepBreakdownItemRowItemSummaryNext = styled.div.attrs((props: {
  isCollect: boolean,
}) => ({
  isCollect: props.isCollect,
  'data-class': 'GetStepBreakdownItemRowItemSummaryNext',
}))`
  ${divFlex}
  font-size: 15px;
  font-weight: 500;
  color: ${({ theme, isCollect }) => (isCollect ? theme.themesColors.neutralBranding6 : theme.themesColors.neutralBranding10)};
`;

export const GetStepBreakdownItemRowItemContainerItem = styled.div.attrs({
  'data-class': 'GetStepBreakdownItemRowItemContainerItem',
})`
  ${divFlex}
  width: 100%;
  height: 32px;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid  ${({ theme }) => theme.themesColors.neutralBranding4};
  &:nth-last-child(1) {
    border-bottom: none;
  }
`;

export const GetStepBreakdownItemRowItemContainerItemSvg = styled.div.attrs({
  'data-class': 'GetStepBreakdownItemRowItemContainerItemSvg',
})`
  ${divFlex}
  transform: translateY(-50%);
  position: absolute;
  left: -21px;
`;

export const GetStepBreakdownItemRowItemContainerItemText = styled.div.attrs({
  'data-class': 'GetStepBreakdownItemRowItemContainerItemText',
})`
  ${divFlex}
  font-size: 12px;
  font-weight: 400;
  align-items: center;
  text-transform: capitalize;
`;

export const GetStepBreakdownItemRowItemContainerItemTextCost = styled.div.attrs((props: {
  isCollect: boolean,
}) => ({
  isCollect: props.isCollect,
  'data-class': 'GetStepBreakdownItemRowItemContainerItemTextCost',
}))`
  ${divFlex}
  font-size: 12px;
  font-weight: 400;
  color: ${({ theme, isCollect }) => (isCollect ? theme.themesColors.neutralBranding6 : theme.themesColors.darkGrayMiddle)};
  align-items: center;
  margin-left: auto;
  text-transform: none;
`;

export const GetStepBreakdownItemRowItemContainerItemTextInfo = styled.div.attrs({
  'data-class': 'GetStepBreakdownItemRowItemContainerItemTextInfo',
})`
  ${divFlex}
  font-size: 12px;
  font-weight: 400;
  align-items: center;
`;

export const GetStepBreakdownItemRowItemContainerItemCost = styled.div.attrs((props: {
  isCollect: boolean,
}) => ({
  isCollect: props.isCollect,
  'data-class': 'GetStepBreakdownItemRowItemContainerItemCost',
}))`
  ${divFlex}
  font-size: 12px;
  font-weight: 400;
  color: ${({ theme, isCollect }) => (isCollect ? theme.themesColors.neutralBranding6 : theme.themesColors.darkGrayMiddle)};
  align-items: center;
  margin-left: auto;
`;

export const GetStepBreakdownItemRowItemContainerItemTotalCost = styled.div.attrs((props: {
  isCollect: boolean,
}) => ({
  isCollect: props.isCollect,
  'data-class': 'GetStepBreakdownItemRowItemContainerItemTotalCost',
}))`
  ${divFlex}
  font-size: 12px;
  font-weight: 400;
  color: ${({ theme, isCollect }) => (isCollect ? theme.themesColors.neutralBranding6 : theme.themesColors.neutralBranding10)};
  align-items: center;
  margin-left: 8px;
`;
