import React, { FC } from 'react';

import { useAppSelector } from 'app-wrapper/hooks/hooks';

import { R } from 'authentication/repository';
import { UC } from 'authentication/controllers';

import { SignUpComponent } from './SignUp.component';

const SignUpContainer: FC = () => {
  const isLoading = useAppSelector(R.selectors.signUp.getLoading);
  const emailValue = useAppSelector(R.selectors.signUp.getEmail);
  const emailError = useAppSelector(R.selectors.signUp.getEmailError);
  const passwordValue = useAppSelector(R.selectors.signUp.getPassword);
  const passwordError = useAppSelector(R.selectors.signUp.getPasswordError);
  const firstNameValue = useAppSelector(R.selectors.signUp.getFirstName);
  const firstNameError = useAppSelector(R.selectors.signUp.getFirstNameError);
  const lastNameValue = useAppSelector(R.selectors.signUp.getLastName);
  const lastNameError = useAppSelector(R.selectors.signUp.getLastNameError);
  const getRequestError = useAppSelector(R.selectors.signUp.getRequestError);

  return (
    <SignUpComponent
      loading={isLoading}
      alertText={getRequestError}
      firstNameValue={firstNameValue}
      firstNameError={firstNameError}
      lastNameValue={lastNameValue}
      lastNameError={lastNameError}
      emailValue={emailValue}
      emailError={emailError}
      passwordValue={passwordValue}
      passwordError={passwordError}
      onFirstNameFieldChange={UC.signUp.setFirstName}
      onFirstNameBlur={UC.signUp.onBlurFirstName}
      onLastNameFieldChange={UC.signUp.setLastName}
      onLastNameBlur={UC.signUp.onBlurLastName}
      onEmailFieldChange={UC.signUp.setEmail}
      onEmailBlur={UC.signUp.onBlurEmail}
      onPasswordFieldChange={UC.signUp.setPassword}
      onPasswordBlur={UC.signUp.onBlurPassword}
      onSignUp={UC.signUp.registerNewAccount}
      onClear={UC.signUp.onClear}
    />
  );
};

export { SignUpContainer as SignUp };
