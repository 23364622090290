import React, {
  FC, useCallback, useMemo, useRef, useState,
} from 'react';
import InfoCircleOutlined from '@ant-design/icons/InfoCircleOutlined';
import { useTranslation } from 'react-i18next';

import { normalizationCost } from 'app-wrapper/utils';
import { Collapse, Panel } from 'app-wrapper/view/components/Collapse';
import { ExpandIcon } from 'app-wrapper/view/components/ExpandIcon';
import { CollapseOnChangeHandler } from 'app-wrapper/hooks';
import themesColors from 'app-wrapper/view/themes/themesColors';
import { Vector5Svg } from 'app-wrapper/view/icons';
import { Tooltip } from 'app-wrapper/view/components';

import { IDetailRoutingContainerStepDTM } from 'monetary/models/dtm';

import {
  GetStepBreakdownItemRowItem,
  GetStepBreakdownItemRowItemContainerItem,
  GetStepBreakdownItemRowItemContainerItemSvg,
  GetStepBreakdownItemRowItemContainerItemText,
  GetStepBreakdownItemRowItemContainerItemTextCost,
  GetStepBreakdownItemRowItemContainerItemTextInfo,
  GetStepBreakdownItemRowItemContainerItemTotalCost,
  GetStepBreakdownItemRowItemSummaryNext,
  GetStepBreakdownItemRowItemWrapper,
} from './GetStepBreakdownItem.styled';

interface IComponentSteps {
  summary: number
  steps: IDetailRoutingContainerStepDTM[]
}

interface IGetStepBreakdownItem {
  fieldName: string
  nameComponent: string
  isCollect?: boolean
  dataComponent?: IComponentSteps
}

export const GetStepBreakdownItem: FC<IGetStepBreakdownItem> = (props) => {
  const {
    fieldName,
    nameComponent,
    isCollect,
    dataComponent,
  } = props;
  const endVisible = useRef<HTMLElement>(null);
  const { t } = useTranslation();
  const [
    changeCollapseState, setChangeCollapseState,
  ] = useState<{ [key: string]: string[] }>({ [fieldName]: [] });

  const onExpandIcon = useCallback(
    ({ isActive }) => ExpandIcon({
      isActive,
      color: `${isActive ? themesColors.primaryBranding6 : themesColors.neutralBranding7}`,
    }),
    [],
  );

  const onCollapseChangeHandler = useCallback(
    (name: string) => CollapseOnChangeHandler(
      endVisible.current, () => {
        setChangeCollapseState((prev) => ({
          ...prev,
          [name]: prev?.[name]?.length ? [] : [name],
        }
        ));
      },
    ),
    [],
  );

  const isShow = useMemo(() => !!(dataComponent && dataComponent.steps.length), [dataComponent]);

  return isShow
    ? (
      <GetStepBreakdownItemRowItemWrapper>
        <GetStepBreakdownItemRowItem className={`GetStepBreakdownItemRowItem__${nameComponent}`}>
          <Collapse
            expandIcon={onExpandIcon}
            onChange={onCollapseChangeHandler(nameComponent)}
            activeKey={changeCollapseState[nameComponent]}
          >
            <Panel
              header={t(nameComponent)}
              key={nameComponent}
              extra={(
                <GetStepBreakdownItemRowItemSummaryNext isCollect={isCollect}>
                  $ {normalizationCost(dataComponent?.summary || '', { toFixed: 2, thousandthSeparatorComma: true })}
                </GetStepBreakdownItemRowItemSummaryNext>
              )}
            >
              {dataComponent?.steps.map((itemStep, indexStep) => (
                <GetStepBreakdownItemRowItemContainerItem
                  className={`GetStepBreakdownItemRowItemContainerItem__${nameComponent}`}
                  key={`${nameComponent}_steps_${itemStep.name}_${indexStep + 0}`}
                >
                  <GetStepBreakdownItemRowItemContainerItemSvg>
                    <Vector5Svg />
                  </GetStepBreakdownItemRowItemContainerItemSvg>
                  <GetStepBreakdownItemRowItemContainerItemText>
                    {itemStep.name}
                    {!!itemStep.infoSteps?.length && (
                      <GetStepBreakdownItemRowItemContainerItemTextInfo>
                        <Tooltip title={itemStep.infoSteps.join(' ')} placement="top" maxWidth="300px">
                          <InfoCircleOutlined
                            className="FreightQuoteFormRequest__Row__Commodity__content_title_icon"
                          />
                        </Tooltip>
                      </GetStepBreakdownItemRowItemContainerItemTextInfo>
                    )}
                  </GetStepBreakdownItemRowItemContainerItemText>
                  {itemStep.isUnits && (
                    <GetStepBreakdownItemRowItemContainerItemTextCost isCollect={isCollect}>
                      {itemStep.descriptionText
                        ? itemStep.descriptionText
                        : `$ ${normalizationCost(itemStep?.cost || 0, { toFixed: 2, thousandthSeparatorComma: true })} x ${itemStep.totalUnits} ${t('unit(s)')}`}
                    </GetStepBreakdownItemRowItemContainerItemTextCost>
                  )}
                  <GetStepBreakdownItemRowItemContainerItemTotalCost isCollect={isCollect}>
                    $ {normalizationCost(itemStep?.summary || 0, { toFixed: 2, thousandthSeparatorComma: true })}
                  </GetStepBreakdownItemRowItemContainerItemTotalCost>
                </GetStepBreakdownItemRowItemContainerItem>
              ))}

            </Panel>
          </Collapse>
        </GetStepBreakdownItemRowItem>
      </GetStepBreakdownItemRowItemWrapper>
    )
    : null;
};
