import { AppFiltersCheckboxGroupDTM } from 'app-wrapper/models/dtm';
import { action, makeObservable, computed } from 'mobx';
import { BaseMobxStore } from 'proto/BaseMobxStore';

import { ECarrierSCAC } from 'monetary/constants';
import { EContractCategory } from 'shipment-operations/constants';
import { ContractDTM } from 'shipment-operations/models/dtm';

interface ContractsState {
  isLoading: boolean;
  isPaginationLoading: boolean;
  isDrawerLoading: boolean;
  contracts: ContractDTM[];
  contractToEdit?: ContractDTM;
  contractNumber?: string;
  contractCarrier?: ECarrierSCAC;
  contractCategory?: EContractCategory;
  categoryFiltersGroup: AppFiltersCheckboxGroupDTM[];
  carriersFiltersGroup: AppFiltersCheckboxGroupDTM[];
  initialCategoryFilters: AppFiltersCheckboxGroupDTM[];
  initialCarriersFilters: AppFiltersCheckboxGroupDTM[];
  searchQuery: string;
  page: number;
  wasPaginationRequestLast: boolean;
}

export const initialState: ContractsState = {
  isLoading: false,
  isPaginationLoading: false,
  isDrawerLoading: false,
  contracts: [],
  contractNumber: undefined,
  contractCarrier: undefined,
  contractCategory: undefined,
  contractToEdit: undefined,
  categoryFiltersGroup: [],
  carriersFiltersGroup: [],
  initialCarriersFilters: [],
  initialCategoryFilters: [],
  searchQuery: '',
  page: 0,
  wasPaginationRequestLast: false,
};

export class ContractsStore<T extends ContractsState = ContractsState> extends BaseMobxStore<ContractsState> {
  initialState = { ...initialState }

  constructor(_initialState?: T) {
    super();
    makeObservable(this);
    this.init(_initialState || this.initialState);
  }

  @action
  setIsLoading(isLoading: boolean) {
    this.state.isLoading = isLoading;
  }

  @action
  setIsPaginationLoading(isLoading: boolean) {
    this.state.isPaginationLoading = isLoading;
  }

  @action
  setIsDrawerLoading(isLoading: boolean) {
    this.state.isDrawerLoading = isLoading;
  }

  @action
  setCategoryFiltersGroup(group: AppFiltersCheckboxGroupDTM[]) {
    this.state.categoryFiltersGroup = group;
  }

  @action
  setCategoryFilterByIndex(index: number, filter: AppFiltersCheckboxGroupDTM) {
    const filters = [...this.state.categoryFiltersGroup];
    filters.splice(index, 1, filter);

    this.setCategoryFiltersGroup(filters);
  }

  @action
  setCarriersFiltersGroup(group: AppFiltersCheckboxGroupDTM[]) {
    this.state.carriersFiltersGroup = group;
  }

  @action
  setCarriersFiltersByIndex(index: number, filter: AppFiltersCheckboxGroupDTM) {
    const filters = [...this.state.carriersFiltersGroup];
    filters.splice(index, 1, filter);

    this.setCarriersFiltersGroup(filters);
  }

  @action
  setInitialCarriersFilters(filters: AppFiltersCheckboxGroupDTM[]) {
    this.state.initialCarriersFilters = filters;
  }

  @action
  setInitialCategoriesFilters(filters: AppFiltersCheckboxGroupDTM[]) {
    this.state.initialCategoryFilters = filters;
  }

  @action
  setContracts(contracts: ContractDTM[]) {
    this.state.contracts = contracts;
  }

  @action
  setSearchQuery(query: string) {
    this.state.searchQuery = query;
  }

  @action
  setContractNumber(contractNumber?: string) {
    this.state.contractNumber = contractNumber;
  }

  @action
  setContractCarrier(carrier?: ECarrierSCAC) {
    this.state.contractCarrier = carrier;
  }

  @action
  setContractToEdit(contract?: ContractDTM) {
    this.state.contractToEdit = contract;
  }

  @action
  clearDrawerForm() {
    this.state.contractCategory = undefined;
    this.state.contractNumber = undefined;
    this.state.contractCarrier = undefined;
  }

  @action
  setContractCategory(category?: EContractCategory) {
    this.state.contractCategory = category;
  }

  @action
  setPage(page: number) {
    this.state.page = page;
  }

  @action
  setWasPaginationRequestLast(wasLast: boolean) {
    this.state.wasPaginationRequestLast = wasLast;
  }

  @computed
  get chosenCategoryFiltersGroup() {
    return this.state.categoryFiltersGroup.filter(({ checked }) => checked);
  }

  @computed
  get chosenCarriersFiltersGroup() {
    return this.state.carriersFiltersGroup.filter(({ checked }) => checked);
  }

  @computed
  get hasCategoryFiltersChanged() {
    let hasChanged = false;

    const filters = this.state.categoryFiltersGroup;
    const initialFilters = this.state.initialCategoryFilters;

    filters.forEach((filter) => {
      const targetInitialFilter = initialFilters.find(({ value }) => value === filter.value);

      if (targetInitialFilter?.checked !== filter.checked) {
        hasChanged = true;
      }
    });

    return hasChanged;
  }

  @computed
  get hasCarriersFiltersChanged() {
    let hasChanged = false;

    const filters = this.state.carriersFiltersGroup;
    const initialFilters = this.state.initialCarriersFilters;

    filters.forEach((filter) => {
      const targetInitialFilter = initialFilters.find(({ value }) => value === filter.value);

      if (targetInitialFilter?.checked !== filter.checked) {
        hasChanged = true;
      }
    });

    return hasChanged;
  }

  @computed
  get hasAnyActiveFilters() {
    const { carriersFiltersGroup, categoryFiltersGroup } = this.state;

    return carriersFiltersGroup.some(({ checked }) => checked) || categoryFiltersGroup.some(({ checked }) => checked);
  }

  @computed
  get isAbleToCreateContract() {
    return Boolean(this.state.contractNumber && this.state.contractCategory && this.state.contractCarrier);
  }

  @computed
  get isAbleToSaveEditContract() {
    return Boolean(this.state.contractNumber && this.state.contractCategory);
  }
}
