import AntSkeleton from 'antd/es/skeleton';
import styled, { css } from 'styled-components';

const RGB_GRADIENT_BACKGROUND_COLOR = '190, 190, 190';
const RGB_GRADIENT_COLOR = '129, 129, 129';

export const Skeleton = styled(AntSkeleton)``;

export const SkeletonNode = styled.div.attrs((props: {
  className: string,
  $height?: string,
  $width?: string,
  $active?: boolean,
}) => ({
  'data-class': 'SkeletonNode',
  $height: props.$height,
  $width: props.$width,
  $active: props.$active,
}))`
  background: rgba(${RGB_GRADIENT_BACKGROUND_COLOR}, 0.05);
  height: ${({ $height }) => ($height || 'auto')};
  width: ${({ $width }) => ($width || '100%')};
`;

export const ActiveAnimation = css`
  @keyframes skeleton-loading {
    0% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0 50%;
    }
  }

  background: linear-gradient(90deg, rgba(${RGB_GRADIENT_BACKGROUND_COLOR}, 0.05) 25%, rgba(${RGB_GRADIENT_COLOR}, 0.06) 37%, rgba(${RGB_GRADIENT_BACKGROUND_COLOR}, 0.05) 63%);
  background-size: 400% 100%;
  animation: skeleton-loading 1.4s ease infinite;
`;

export const SkeletonNodeBackground = styled.span.attrs((props: {
  $height?: string,
}) => ({
  'data-class': 'SkeletonNodeBackground',
  $height: props.$height,
}))`
  display: flex;
  width: 100%;
  height: ${({ $height }) => ($height || '100%')};
  ${ActiveAnimation};
`;
