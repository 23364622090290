import React from 'react';

import { UC } from 'shipment-operations/controllers';
import { R } from 'shipment-operations/repository';

import { useAppSelector } from 'app-wrapper/hooks';

import { PaymentViewComponent } from './PaymentView.component';

const PaymentViewContainer = () => {
  const paymentNumber = useAppSelector(R.selectors.makePayment.getPaymentViewNumber);
  const isError = useAppSelector(R.selectors.makePayment.getPaymentViewError);
  const status = useAppSelector(R.selectors.makePayment.getPaymentViewStatus);
  const isOpenEdit = useAppSelector(R.selectors.makePayment.getIsOpenEdit);
  const shouldRefresh = useAppSelector(R.selectors.makePayment.getShouldRefresh);

  return (
    <PaymentViewComponent
      fetchData={UC.makePayment.getPayment}
      checkRefresh={UC.makePayment.shouldRefreshPayment}
      number={paymentNumber}
      isError={isError}
      status={status}
      isOpenEdit={isOpenEdit}
      shouldRefresh={shouldRefresh}
    />
  );
};

export { PaymentViewContainer as PaymentView };
