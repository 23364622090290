import React, { FC, useState, useCallback } from 'react';
import AntCheckbox, { CheckboxChangeEvent } from 'antd/es/checkbox';
import Modal from 'antd/es/modal';
import { useTranslation } from 'react-i18next';

import { GensetConfirmation } from 'shipment-operations/view/pages/ShipmentContainers/components/ShipmentContainersHeader/components';

import { GensetFieldWrapper, GensetLabel } from './GensetField.styled';

interface IGensetFieldComponentProps {
  genset: boolean
  setGenset: (value: boolean) => void
  applyGenset: () => void
  isBookingRoute?: boolean
  withGenset?: boolean
}

export const GensetFieldComponent: FC<IGensetFieldComponentProps> = ({
  genset,
  setGenset,
  applyGenset,
  isBookingRoute,
  withGenset,
}) => {
  const { t } = useTranslation();
  const [checked, setChecked] = useState(genset);
  const [isOpenConfirm, setIsOpenConfirm] = useState(false);

  const onChange = useCallback((e: CheckboxChangeEvent) => {
    setChecked(e.target.checked);
    setGenset(e.target.checked);
    setIsOpenConfirm(true);
    if (!withGenset && !e.target.checked) {
      setIsOpenConfirm(false);
    }
  }, [genset]);

  const onCancel = useCallback(() => {
    setIsOpenConfirm(false);
    setGenset(!checked);
  }, [genset]);

  const onOk = useCallback(() => {
    setIsOpenConfirm(false);
    applyGenset();
  }, [genset]);

  return (
    <>
      <Modal
        onCancel={onCancel}
        visible={isOpenConfirm}
        onOk={onOk}
      >
        <GensetConfirmation isBookingRoute={isBookingRoute} />
      </Modal>
      <GensetFieldWrapper>
        <AntCheckbox
          checked={genset}
          onChange={onChange}
        />
        <GensetLabel>{t('Genset')}</GensetLabel>
      </GensetFieldWrapper>
    </>
  );
};
