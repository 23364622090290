import {
  IsBoolean,
  IsOptional,
} from 'class-validator';
import { EPermissionAttributePolicy } from 'monetary/constants';
import { BaseDTM } from 'proto/BaseDTM';
import { PermissionAttributePolicy } from 'shipment-operations/constants';

export type TUsersPermissionsDtm = {
  page?: EPermissionAttributePolicy
  downloadUsersButton?: EPermissionAttributePolicy
}

export class UsersPermissionsDtm extends BaseDTM<TUsersPermissionsDtm> {
  @IsOptional()
  @IsBoolean()
  page?: PermissionAttributePolicy

  @IsOptional()
  @IsBoolean()
  downloadUsersButton?: PermissionAttributePolicy
}
