import React, { memo } from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';
import { UC } from 'shipment-operations/controllers';
import { UC as appUC } from 'app-wrapper/controllers';

import { AddRatesComponent } from './AddRates.component';

const AddRatesContainer = memo(({ typeDrawer }: { typeDrawer: string }) => {
  const loading = useSelector(R.selectors.addRates.getIsLoading);
  const containersData = useSelector(R.selectors.addRates.getEditableDataByContainer);
  const shipmentData = useSelector(R.selectors.addRates.getEditableDataByShipment);
  const uniqContainers = useSelector(R.selectors.addRates.getUniqContainers);
  const shipmentTabError = useSelector(R.selectors.addRates.getIsShipmentTabError);
  const containerTabError = useSelector(R.selectors.addRates.getIsContainerTabError);

  return (
    <AddRatesComponent
      onClose={appUC.drawer.closeDrawer}
      loadCharges={UC.addRates.loadCharges}
      deleteCharge={UC.addRates.deleteCharge}
      addCharge={UC.addRates.addCharge}
      onChangeCharge={UC.addRates.onChangeCharge}
      onClear={UC.addRates.onClear}
      loading={loading}
      containersData={containersData}
      shipmentData={shipmentData}
      uniqContainers={uniqContainers}
      shipmentTabError={shipmentTabError}
      containerTabError={containerTabError}
      typeDrawer={typeDrawer}
    />
  );
});

export { AddRatesContainer as AddRates };
