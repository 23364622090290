import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import Input from 'antd/es/input';

import { IBillOfLadingCommonState } from 'shipment-operations/models/states';

import { Footer } from './components';
import {
  Form,
  FormItem,
  FormItemLabel,
  Label,
  Spinner,
  SpinnerWrap,
} from './BillOfLadingEditComments.styled';

const MAX_INPUT_LENGTH = 256;

interface BillOfLadingEditCommentsComponentProps {
  formState: IBillOfLadingCommonState['commentsFormState'];
  setFormState: (comment: string) => void;
  isPending: boolean;
}

export const BillOfLadingEditCommentsComponent: FC<BillOfLadingEditCommentsComponentProps> = ({ formState, setFormState, isPending }) => {
  const { t } = useTranslation();

  const handleChange = useCallback((e) => {
    setFormState(e.target.value);
  }, [setFormState]);

  return (
    <Form>
      {isPending ? (
        <SpinnerWrap>
          <Spinner />
        </SpinnerWrap>
      ) : (
        <>
          <FormItem>
            <FormItemLabel>
              <Label>
                {t('BL Comments')}
              </Label>
            </FormItemLabel>

            <Input.TextArea
              value={formState.comment}
              onChange={handleChange}
              maxLength={MAX_INPUT_LENGTH}
            />
          </FormItem>

          <Footer />
        </>
      )}
    </Form>
  );
};
