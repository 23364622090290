import BellFilledIcon from '@ant-design/icons/BellFilled';
import styled from 'styled-components';
import Typography from 'antd/es/typography';

import { colors } from 'app-wrapper/view/themes/themesColors';

export const TrackerHeaderDatesContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 0;
`;

export const TrackerHeaderDateWithLabel = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0;

  > :nth-child(1) {
    color: ${colors.gray61};
  }
`;

export const Label = styled(Typography.Text)`
  font-size: 12px;
`;

export const BellFilled = styled(BellFilledIcon)`
  color: ${colors.yellow60};
`;
