import React from 'react';
import { IPropsIcon } from 'app-wrapper/interfaces/icon';

import { Icon } from './GorizontalMenuSvg.styled';

export const GorizontalMenuSvg = (props: IPropsIcon) => {
  const { width, height, fill } = props;
  return (
    <Icon
      className="GorizontalMenuSvg"
      width={width || '18'}
      height={height || '13'}
      viewBox="0 0 18 13"
      fill={fill || 'currentColor'}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M17.75 1.5V1.0625C17.75 0.84375 17.5312 0.625 17.3125 0.625H0.6875C0.441406 0.625 0.25 0.84375 0.25 1.0625V1.5C0.25 1.74609 0.441406 1.9375 0.6875 1.9375V11.5625C0.441406 11.5625 0.25 11.7812 0.25 12V12.4375C0.25 12.6836 0.441406 12.875 0.6875 12.875H17.3125C17.5312 12.875 17.75 12.6836 17.75 12.4375V12C17.75 11.7812 17.5312 11.5625 17.3125 11.5625V1.9375C17.5312 1.9375 17.75 1.74609 17.75 1.5ZM16 11.5625H2V1.9375H16V11.5625ZM3.96875 10.25H4.84375C4.95312 10.25 5.0625 10.168 5.0625 10.0312V3.46875C5.0625 3.35938 4.95312 3.25 4.84375 3.25H3.96875C3.83203 3.25 3.75 3.35938 3.75 3.46875V10.0312C3.75 10.168 3.83203 10.25 3.96875 10.25ZM10.0938 10.25H10.9688C11.0781 10.25 11.1875 10.168 11.1875 10.0312V3.46875C11.1875 3.35938 11.0781 3.25 10.9688 3.25H10.0938C9.95703 3.25 9.875 3.35938 9.875 3.46875V10.0312C9.875 10.168 9.95703 10.25 10.0938 10.25ZM13.1562 10.25H14.0312C14.1406 10.25 14.25 10.168 14.25 10.0312V3.46875C14.25 3.35938 14.1406 3.25 14.0312 3.25H13.1562C13.0195 3.25 12.9375 3.35938 12.9375 3.46875V10.0312C12.9375 10.168 13.0195 10.25 13.1562 10.25ZM7.03125 10.25H7.90625C8.01562 10.25 8.125 10.168 8.125 10.0312V3.46875C8.125 3.35938 8.01562 3.25 7.90625 3.25H7.03125C6.89453 3.25 6.8125 3.35938 6.8125 3.46875V10.0312C6.8125 10.168 6.89453 10.25 7.03125 10.25Z" fill="currentColor" />
    </Icon>
  );
};
