import styled, { css } from 'styled-components';

import themesColors from '../themesColors';
import responsive from './responsive';

export const textColorFirst = css`
  color: ${themesColors.characterBrandingTitle85};
`;

export const divFlex = css`
  display: flex;
  position: relative;
  ${textColorFirst}
`;

export const EmptyScrollbar = css`
  scrollbar-width: none;
  &::-webkit-scrollbar {
    width: 0;
  }
`;

export const ShadowHeaderTop = css`
  box-shadow: 0px -3px 10px 1px rgba(0, 0, 0, 0.04);
`;

export const MainContent = styled.div.attrs({
  'data-class': 'MainContent',
})`
  ${divFlex}
  height: 100%;
  width: 100%;
`;

export const MainContentCollapse = styled.div.attrs({
  'data-class': 'MainContentCollapse',
})`
  ${divFlex}
  position: absolute;
  height: 100%;
  width: 100%;
`;

const MainContentSidebarCss = css`
  width: 30%;
  min-width: 390px;
  max-width: calc(480px - 24px);
`;

export const MainContentSidebar = styled.div.attrs((props: {
  isCollapse: boolean,
}) => ({
  isCollapse: props.isCollapse,
  'data-class': 'MainContentSidebar',
}))`
  ${divFlex}
  ${MainContentSidebarCss}
  height: 100%;
`;

const MainContentSidebarFullCollapseCss = css`
  ${responsive.forFullMedia`
    width: 45%;
    max-width: calc(762px - 24px);
    min-width: 670px;
  `}
`;

export const MainContentSidebarFullCollapse = styled(MainContentSidebar).attrs({
  'data-class': 'MainContentSidebarFullCollapse',
})`
  ${({ isCollapse }) => (!isCollapse ? MainContentSidebarCss : MainContentSidebarFullCollapseCss)}
`;

export const MainContentRightWrapper = styled.div.attrs({
  'data-class': 'MainContentRightWrapper',
})`
  ${divFlex}
  width: 70%;
  min-width: 548px;
  ${responsive.forMediumDesktopMax`
    min-width: 518px;
  `}
  ${responsive.forMediumMediaDesktopMax`
    min-width: 508px;
  `}
  ${responsive.forFullDesktop`
    min-width: calc(100% - 480px + 24px);
  `}
  background-color: ${({ theme }) => theme.themesColors.lightGrayBorder};
`;

export const MainContentRightWrapperFullCollapse = styled(MainContentRightWrapper).attrs({
  'data-class': 'MainContentRightWrapperFullCollapse',
})`
  ${responsive.forFullMedia`
    width: 70%;
    min-width: 670px;
  `}
  ${responsive.forFullDesktop`
    min-width: calc(100% - 762px + 24px);
  `}
  max-width: 880px;
  margin: 0 auto;
`;

export const MainContentRight = styled.div.attrs({
  'data-class': 'MainContentRight',
})`
  ${divFlex}
  width: 100%;
  flex-direction: column;
  background-color: ${({ theme }) => theme.themesColors.lightGrayBorder};
  padding-left: 24px;
  padding-right: 24px;
  min-width: calc(548px + 24px + 24px);
  max-width: calc(928px + 24px + 24px);
  ${responsive.forMediumDesktopMax`
    min-width: calc(518px + 24px + 24px);
  `}
  ${responsive.forMediumMediaDesktopMax`
    min-width: calc(508px + 24px + 24px);
  `}
`;

export const MainContentRightBg = styled.div.attrs((props: {
  isActive: boolean,
}) => ({
  isActive: props.isActive,
  'data-class': 'MainContentRightBg',
}))`
  ${divFlex}
  ${({ isActive }) => !isActive && 'display: none;'}
  position: absolute;
  z-index: 2;
  height: 100%;
  width: 100%;
  flex-direction: column;
  background-color: ${({ theme }) => theme.themesColors.characterSecondaryDot45};
`;
