import React from 'react';
import { useSelector } from 'react-redux';

import { R } from 'user-management/repository';

import { EditTradeReferenceComponent } from './EditTradeReference.component';

const EditTradeReferenceContainer = () => {
  const isPending = useSelector(R.selectors.customer.getIsTradeReferencePending);

  return (
    <EditTradeReferenceComponent
      isPending={isPending}
    />
  );
};

export { EditTradeReferenceContainer as EditTradeReference };
