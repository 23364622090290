import React from 'react';

import { IPropsIcon } from 'app-wrapper/interfaces/icon';

import { Icon } from './SkypaceLoadingWait.styled';

export const SkypaceLoadingWait = (props: IPropsIcon) => {
  const { width, height, fill } = props;
  return (
    <Icon
      className="SkypaceLoadingWait"
      width={width || '15'}
      height={height || '15'}
      viewBox="0 0 15 15"
      fill={fill || 'currentColor'}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M7.5 0.388885C3.57301 0.388885 0.388885 3.57301 0.388885 7.5C0.388885 11.427 3.57301 14.6111 7.5 14.6111C11.427 14.6111 14.6111 11.427 14.6111 7.5C14.6111 3.57301 11.427 0.388885 7.5 0.388885ZM7.5 13.4048C4.23968 13.4048 1.59523 10.7603 1.59523 7.5C1.59523 4.23968 4.23968 1.59523 7.5 1.59523C10.7603 1.59523 13.4048 4.23968 13.4048 7.5C13.4048 10.7603 10.7603 13.4048 7.5 13.4048Z" fill="currentColor" />
      <path d="M10.2731 9.50803L8.00964 7.87152V3.94295C8.00964 3.87311 7.9525 3.81597 7.88266 3.81597H7.11917C7.04933 3.81597 6.99218 3.87311 6.99218 3.94295V8.31438C6.99218 8.35565 7.01123 8.39375 7.04456 8.41756L9.66996 10.3318C9.7271 10.3731 9.80647 10.3604 9.84774 10.3049L10.3017 9.68581C10.343 9.62708 10.3303 9.54771 10.2731 9.50803Z" fill="currentColor" />
    </Icon>
  );
};
