import React, { FC } from 'react';

import CheckCircleFilled from '@ant-design/icons/CheckCircleFilled';
import ClockCircleFilled from '@ant-design/icons/ClockCircleFilled';

import { colors } from 'app-wrapper/view/themes/themesColors';
import { FormStatusType } from 'app-wrapper/types/FormStatusType';

interface IFormStatusProps {
  type: FormStatusType | null
}

export const FormStatus: FC<IFormStatusProps> = ({ type }) => {
  switch (type) {
    case 'complete':
      return <CheckCircleFilled style={{ color: colors.green60 }} />;
    case 'error':
      return <ClockCircleFilled style={{ color: colors.red60 }} />;
    case 'incomplete':
      return <ClockCircleFilled style={{ color: colors.gray43 }} />;
    default:
      return <></>;
  }
};
