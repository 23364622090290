import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import i18n from 'app-wrapper/i18n/i18n';

import {
  Wrap,
  Item,
  ItemTag,
  ItemText,
} from './OrganizationAlreadyExists.styled';
import { AccountRejected } from '../AccountRejected';
import { PathToReapply } from '../PathToReapply';

const REAPPLY_ITEMS = [
  i18n.t('We recommend collaborating with all relevant parties within your organization to ensure that the approved account is set up in a manner that satisfies all departmental needs.'),
  i18n.t('If you believe the other application should be considered instead, please reach out to our support team with relevant documentation and explanations.'),
  i18n.t('If additional user access is required for different departments or branches within your organization, please utilize the multi-user feature within your account (if available) or discuss with our team how additional users can be onboarded under the same organization account.'),
  i18n.t('For any amendments, additional documentation, or changes to the registered account, please log in and submit them for reevaluation, or reach out to our support team for assistance.'),
];

interface OrganizationAlreadyExistsProps {
  note?: string;
}

export const OrganizationAlreadyExists: FC<OrganizationAlreadyExistsProps> = ({ note }) => {
  const { t } = useTranslation();

  return (
    <Wrap>
      <AccountRejected
        title={t('Multiple Registrations from the Same Organization')}
        subtitle={t('We have identified multiple registration applications originating from your organization. To maintain data integrity and security, we can only approve one registration per organization. We have proceeded with the registration that was either submitted first or contains more complete information (as per your internal policy).')}
        adminComment={note}
      />

      <PathToReapply
        content={(
          <>
            {REAPPLY_ITEMS.map((text) => (
              <Item key={`PathToReapply_${text}`}>
                <ItemTag />

                <ItemText>
                  {text}
                </ItemText>
              </Item>
            ))}
          </>
        )}
      />
    </Wrap>
  );
};
