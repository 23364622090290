import styled from 'styled-components';

export const ShipmentTitleBlock = styled.div.attrs({
  'data-class': 'ShipmentTitleBlock',
})`
  display: flex;
  flex-direction: row;
  margin-right: 17px;
  width: 14px;

  > span {
    padding: 0;
  }
`;
