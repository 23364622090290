import styled from 'styled-components';
import Menu from 'antd/es/menu';

export const MenuItem = styled(Menu.Item)`
  min-width: 143px;
`;

export const LinkText = styled.span`
  margin-left: 4px;
  font-size: 14px;
  line-height: 22px;
  color: rgba(74, 85, 173, 1);
  cursor: pointer;
`;
