import React, { FC, memo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { CompanyDTM } from 'user-management/models/dtm';

import { AACTable } from './components';
import { AACDiv, AACDivHeader, AACDivHeaderHeaderArrowIcon } from './AddressesAndContactsSecond.styled';
import { AccountDrawer } from '../Account/components';

interface IAddressesAndContactsSecondComponentProps {
  company?: CompanyDTM;
  isLoading?: boolean
  runComponent: (id: string) => void
  onClickAddAddress: () => void;
  onClickEditAddress: (id: number) => () => void;
  onClickArrow: () => void
}

const AddressesAndContactsSecondComponent: FC<IAddressesAndContactsSecondComponentProps> = (props) => {
  const {
    company,
    isLoading,
    runComponent,
    onClickAddAddress,
    onClickEditAddress,
    onClickArrow,
  } = props;
  const { t } = useTranslation();
  const { addressId } = useParams<'addressId'>();

  useEffect(() => {
    if (addressId) {
      runComponent(addressId);
    }
  }, []);

  return (
    <AACDiv>
      <AACDivHeader>
        <AACDivHeaderHeaderArrowIcon onClick={onClickArrow} />
        {t('Addresses & Contacts')}
      </AACDivHeader>
      <AACTable
        company={company}
        isLoad={isLoading}
        nextPageTasks={() => ''}
        onStart={() => ''}
        onClickAddAddress={onClickAddAddress}
        onClickEditAddress={onClickEditAddress}
      />

      <AccountDrawer />
    </AACDiv>
  );
};

const AddressesAndContactsSecondComponentCached = memo(AddressesAndContactsSecondComponent);

export { AddressesAndContactsSecondComponentCached as AddressesAndContactsSecondComponent };
