import React from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';
import { UC } from 'shipment-operations/controllers';

import { TaxIdInputComponent } from './TaxIDInput.component';

export const TaxIDInput = () => {
  const taxId = useSelector(R.selectors.shippingParties.getTaxId);

  return (
    <TaxIdInputComponent taxId={taxId} setTaxId={UC.shippingParties.setTaxId} />
  );
};
