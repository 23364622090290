import themesColors from 'app-wrapper/view/themes/themesColors';
import styled from 'styled-components';

export const SubPageContent = styled.div.attrs({
  'data-class': 'SubPageContent',
})`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 16px;
  border-radius: 2px;
  border: 1px solid rgba(234, 238, 248, 1);
  background-color: ${themesColors.neutralBranding1};
`;
