import React, { FC, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import Spin from 'antd/es/spin';
import Tabs from 'antd/es/tabs';

import { RouteNames } from 'app-wrapper/constants';
import { TeamDTM } from 'user-management/models/dtm';
import { Button } from 'app-wrapper/view/components';
import { CreateOrEditTeamDrawer } from 'user-management/view/components';

import {
  SpinnerWrap,
  SubPageHeader,
  SubPageHeaderContainer,
  SubPageHeaderTitle,
  Wrapper,
  BackTitleIcon,
} from 'app-wrapper/view/guideline';
import { SubPageTabs } from 'app-wrapper/view/guideline/SubPageTabs';
import { TeamMembers, TeamCustomers } from './components';
import {
  EditTeamIcon,
} from './TeamPage.styled';

interface ITeamPageProps {
  team?: TeamDTM;
  teamId: string;
  init: (teamId: string) => void;
  isLoading: boolean;
  openEditDrawer: () => void;
  isAdmin?: boolean;
}

export const TeamPageComponent: FC<ITeamPageProps> = ({
  team,
  init,
  isLoading,
  teamId,
  openEditDrawer,
  isAdmin,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const goBackToTeams = useCallback(() => navigate(RouteNames.ACCOUNT_TEAMS()), [navigate]);

  useEffect(() => {
    init(teamId);
  }, [teamId]);

  return (
    <Wrapper>
      {isLoading || !team ? (
        <SpinnerWrap>
          <Spin />
        </SpinnerWrap>
      ) : (
        <>
          <CreateOrEditTeamDrawer />

          <SubPageHeader>
            <SubPageHeaderContainer>
              <SubPageHeaderTitle onClick={goBackToTeams}>
                <BackTitleIcon />

                {team?.name}
              </SubPageHeaderTitle>

              {isAdmin ? (
                <Button
                  icon={<EditTeamIcon />}
                  onClick={openEditDrawer}
                >
                  {t('Edit Team')}
                </Button>
              ) : null}
            </SubPageHeaderContainer>
          </SubPageHeader>

          <SubPageTabs defaultActiveKey="1">
            <Tabs.TabPane
              data-class="team-page-roles-tab"
              tab={t('Roles')}
              key="ROLES"
            >
              <TeamMembers />
            </Tabs.TabPane>

            <Tabs.TabPane
              data-class="team-page-customers-tab"
              tab={t('Customers')}
              key="CUSTOMERS"
            >
              <TeamCustomers />
            </Tabs.TabPane>
          </SubPageTabs>
        </>
      )}
    </Wrapper>
  );
};
