import styled from 'styled-components';

import { Wrapper } from 'app-wrapper/view/guideline';

export const StyledWrapper = styled(Wrapper)`
  background-color: #F4F5F8;
`;

export const Message = styled.div`
  border: 1px solid #EAEEF8;
  border-radius: 3px;
  padding: 16px;
  background-color: white;
  margin-bottom: 8px;
`;
