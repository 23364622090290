import React, {
  useContext, useMemo, FC,
} from 'react';
import Form from 'antd/es/form';
import i18n from 'i18next';
import isNumber from 'lodash/fp/isNumber';

import {
  CommonContainerTypes, ContainerAllTypesArray, measureByWithoutContainerOptions, DataType,
} from 'shipment-operations/constants';
import { EditableContext } from 'shipment-operations/view/components/EditableRow';
import { ChildrenWrapper } from 'shipment-operations/view/pages/CreateCreditNote/components/SecondStep/components/CustomTableEditable/components/Cell/Cell.styled';

import { StyledInput } from 'shipment-operations/view/drawers/AddRates/AddRates.styled';
import { FRT } from 'monetary/constants';
import { StyledSelect } from './EditableCell.styled';

interface EditableCellProps {
  editable: boolean;
  children: React.ReactNode;
  dataIndex: string
  record: DataType
  handleSave: (record: DataType, type: string) => void;
  emptyRender?: boolean
  chargesOption: { value: string, label: string }[]
  isError: boolean
  uniqContainers: string[]
}

const currencyOptions = [
  { value: 'USD', label: i18n.t('USD') },
];

const applicabilityOptions = [
  { value: 'Included', label: i18n.t('Included') },
  { value: 'applicable', label: i18n.t('Applicable') },
  { value: 'subjectTo', label: i18n.t('subjectTo') },
];

const EditableCellComponent: FC<EditableCellProps> = ({
  dataIndex,
  record,
  editable,
  children,
  emptyRender,
  handleSave,
  chargesOption,
  isError,
  uniqContainers,
  ...restProps
}) => {
  const form = useContext(EditableContext)!;

  const saveInput = async () => {
    const values = await form.validateFields();
    const filteredValues = Object.entries(values)
      .filter(([_, value]) => value !== undefined)
      .reduce((acc: { [key: string]: any }, [key, value]) => {
        acc[key] = value;
        return acc;
      }, {});
    if (filteredValues.applicability === 'Included') {
      uniqContainers.forEach((key) => {
        filteredValues[key] = 0;
        filteredValues.price = 0;
      });
    }
    form.setFieldsValue(filteredValues);
    handleSave({ ...record, ...filteredValues }, dataIndex);
  };

  const options = useMemo(() => {
    if (dataIndex === 'currency') {
      return currencyOptions;
    }
    if (dataIndex === 'applicability') {
      return applicabilityOptions;
    }
    if (dataIndex === 'measureBy') {
      return measureByWithoutContainerOptions;
    }
    if (dataIndex === 'charge') {
      return chargesOption;
    }
    return [{ value: '', label: '' }];
  }, [chargesOption]);

  if (emptyRender) {
    return <td {...restProps}>{children}</td>;
  }

  const isDisable = (): boolean => {
    if (dataIndex === 'currency') {
      return true;
    }
    if (record.charge === FRT) {
      if (dataIndex === 'charge' || dataIndex === 'measureBy' || dataIndex === 'applicability') {
        return true;
      }
    }
    return false;
  };

  const isDisableInput = (): boolean => record.applicability === 'Included';

  let childNode = children;

  if (editable) {
    childNode = (
      <Form.Item
        style={{ margin: 0 }}
        name={dataIndex}
      >
        {(ContainerAllTypesArray.includes(dataIndex as CommonContainerTypes) || dataIndex === 'price') ? (
          <StyledInput
            onBlur={saveInput}
            defaultValue={record[dataIndex as keyof typeof record]}
            style={{ borderColor: (isError && !isNumber(record[dataIndex as keyof typeof record])) ? 'red' : '' }}
            disabled={isDisableInput()}
            value={isDisableInput() ? '0' : ''}
          />
        ) : (
          <StyledSelect
            options={options}
            defaultValue={record[dataIndex as keyof typeof record] || ''}
            disabled={isDisable()}
            showSearch={dataIndex === 'charge'}
            optionFilterProp="label"
            onSelect={saveInput}
            style={{ textAlign: 'left' }}
            className={isError && !record[dataIndex as keyof typeof record] ? 'ant-select-error' : ''}
          />
        )}
      </Form.Item>
    );
  }

  return (
    <td {...restProps}>
      <ChildrenWrapper>
        {childNode}
      </ChildrenWrapper>
    </td>
  );
};

export { EditableCellComponent };
