import {
  IsDefined,
  IsString,
  IsNumber,
  IsBoolean,
  ValidateNested,
  IsOptional,
  IsArray,
} from 'class-validator';
import { Type } from 'class-transformer';
import { DataNode } from 'antd/es/tree';
import { BaseDTM } from 'proto/BaseDTM';

import { EPageSortGeneral } from '../contracts';

export interface IAppFiltersMinMaxDTM {
  filterMin?: string
  filterMax?: string
  filterMinDefault?: number
  filterMaxDefault?: number
  limitMin?: number
  limitMax?: number
  disableReset?: boolean
  isUpdate?: boolean
  lastFilterMin?: string
  lastFilterMax?: string
}

export class AppFiltersMinMaxDTM extends BaseDTM<IAppFiltersMinMaxDTM> {
  @IsOptional()
  @IsString()
  filterMin?: string;

  @IsOptional()
  @IsString()
  filterMax?: string;

  @IsOptional()
  @IsNumber()
  filterMinDefault?: number;

  @IsOptional()
  @IsNumber()
  filterMaxDefault?: number;

  @IsOptional()
  @IsNumber()
  limitMin?: number;

  @IsOptional()
  @IsNumber()
  limitMax?: number;

  @IsOptional()
  @IsNumber()
  disableReset?: boolean;

  @IsOptional()
  @IsNumber()
  isUpdate?: boolean;

  @IsOptional()
  @IsString()
  lastFilterMin?: string;

  @IsOptional()
  @IsString()
  lastFilterMax?: string;
}

export interface IAppFiltersCheckboxGroupDTM {
  value: string
  name: string
  checked: boolean
  id?: string
  customId?: string
  // need divide 2 values: country.code and city
  // which will be merged in the name, and it will be necessary to transfer to the server separately
  value2?: string
  disabled?: boolean
}

export class AppFiltersCheckboxGroupDTM extends BaseDTM<IAppFiltersCheckboxGroupDTM> {
  @IsDefined()
  @IsString()
  value: string;

  @IsDefined()
  @IsString()
  name: string;

  @IsDefined()
  @IsBoolean()
  checked: boolean;

  @IsOptional()
  @IsString()
  id?: string;

  @IsOptional()
  @IsString()
  customId?: string;

  @IsOptional()
  @IsString()
  value2?: string;

  @IsOptional()
  @IsBoolean()
  disabled?: boolean;
}

export interface IAppFiltersCheckboxDTM {
  group?: IAppFiltersCheckboxGroupDTM[]
  lastGroup?: IAppFiltersCheckboxGroupDTM[]
  groupDefault?: IAppFiltersCheckboxGroupDTM[]
  disableReset?: boolean
  disabledModal?: boolean
  isUpdate?: boolean
}

export class AppFiltersCheckboxDTM extends BaseDTM<IAppFiltersCheckboxDTM> {
  @IsOptional()
  @ValidateNested({ each: true })
  @Type(() => AppFiltersCheckboxGroupDTM)
  group?: AppFiltersCheckboxGroupDTM[];

  @IsOptional()
  @ValidateNested({ each: true })
  @Type(() => AppFiltersCheckboxGroupDTM)
  lastGroup?: AppFiltersCheckboxGroupDTM[];

  @IsOptional()
  @ValidateNested({ each: true })
  @Type(() => AppFiltersCheckboxGroupDTM)
  groupDefault?: AppFiltersCheckboxGroupDTM[];

  @IsOptional()
  @IsBoolean()
  disableReset?: boolean;

  @IsOptional()
  @IsBoolean()
  disabledModal?: boolean;

  @IsOptional()
  @IsBoolean()
  isUpdate?: boolean;
}

export interface IAppFiltersRadioDTM {
  group?: IAppFiltersCheckboxGroupDTM[]
  groupDefault?: IAppFiltersCheckboxGroupDTM[]
  name?: string
  value?: string | EPageSortGeneral
  valueDefault?: string
  valueLast?: string
  isUpdate?: boolean
}

export class AppFiltersRadioDTM extends BaseDTM<IAppFiltersRadioDTM> {
  @IsOptional()
  @ValidateNested({ each: true })
  @Type(() => AppFiltersCheckboxGroupDTM)
  group?: AppFiltersCheckboxGroupDTM[];

  @IsOptional()
  @ValidateNested({ each: true })
  @Type(() => AppFiltersCheckboxGroupDTM)
  groupDefault?: AppFiltersCheckboxGroupDTM[];

  @IsOptional()
  @IsString()
  name?: string;

  @IsOptional()
  @IsString()
  value?: string;

  @IsOptional()
  @IsString()
  valueDefault?: string;

  @IsOptional()
  @IsString()
  valueLast?: string;

  @IsOptional()
  @IsBoolean()
  isUpdate?: boolean;
}

export interface IAppFiltersCheckboxTreeDTM {
  group?: IAppFiltersCheckboxGroupDTM[]
  lastGroup?: IAppFiltersCheckboxGroupDTM[]
  groupDefault?: IAppFiltersCheckboxGroupDTM[]
  disableReset?: boolean
  isUpdate?: boolean
  treeData?: DataNode[]
  treeDataIds?: string[]
}

export class AppFiltersCheckboxTreeDTM extends BaseDTM<IAppFiltersCheckboxTreeDTM> {
  @IsOptional()
  @ValidateNested({ each: true })
  @Type(() => AppFiltersCheckboxGroupDTM)
  group?: AppFiltersCheckboxGroupDTM[];

  @IsOptional()
  @ValidateNested({ each: true })
  @Type(() => AppFiltersCheckboxGroupDTM)
  lastGroup?: AppFiltersCheckboxGroupDTM[];

  @IsOptional()
  @ValidateNested({ each: true })
  @Type(() => AppFiltersCheckboxGroupDTM)
  groupDefault?: AppFiltersCheckboxGroupDTM[];

  @IsOptional()
  @IsBoolean()
  disableReset?: boolean;

  @IsOptional()
  @IsBoolean()
  isUpdate?: boolean;

  @IsOptional()
  @ValidateNested({ each: true })
  @IsArray()
  treeData?: DataNode[];

  @IsOptional()
  @IsString({ each: true })
  treeDataIds?: string[];
}
