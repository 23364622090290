import { CustomScrollbar } from 'app-wrapper/view/styles/mixins';
import { divFlex } from 'app-wrapper/view/themes/mixin';
import styled from 'styled-components';

export const TableList = styled.div.attrs({
  'data-class': 'TableList',
})`
  ${divFlex}
  background-color: rgba(255, 255, 255, 1);
  border-radius: 3px;
  min-width: calc(100% - 4px);
  height: fit-content;
  flex-direction: column;
  background-color: ${({ theme }) => theme.themesColors.neutralBranding1};
`;

export const TableListHeader = styled.div.attrs({
  'data-class': 'TableListHeader',
})`
  ${divFlex}

  height: 28px;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.themesColors.neutralBranding3};
  padding-bottom: 10px;
`;

export const TableListHeaderItem = styled.div.attrs((props: {
  align?: 'left' | 'center' | 'right',
  width?: string,
  paddingLeft?: string,
}) => ({
  align: props.align || 'center',
  width: props.width,
  paddingLeft: props.paddingLeft,
  'data-class': 'TableListHeaderItem',
}))`
  ${divFlex}
  align-items: center;
  justify-content: ${({ align }) => align};

  width: ${({ width }) => (width || 'auto')};
  padding-left: ${({ paddingLeft }) => (paddingLeft || '0')};

  color: ${({ theme }) => theme.themesColors.secondaryDot45};
  font-size: 12px;
  font-weight: 400;
`;

export const TableListContent = styled.div.attrs({
  'data-class': 'TableListContent',
})`
  ${divFlex}
  ${CustomScrollbar}
  flex-direction: column;
`;

export const TableListContentItem = styled.div.attrs({
  'data-class': 'TableListContentItem',
})`
  ${divFlex}
  flex-direction: column;
  width: 100%;
`;

export const TableListContentItemTop = styled.div.attrs({
  'data-class': 'TableListContentItemTop',
})`
  ${divFlex}

  height: 55px;
`;

export const TableListContentExpand = styled.div.attrs((props: {
  isActive?: boolean,
  isEmpty?: boolean,
}) => ({
  isActive: props.isActive,
  isEmpty: props.isEmpty,
  'data-class': 'TableListContentExpand',
}))`
  ${divFlex}
  align-items: center;
  width: 14px;

  cursor: pointer;
  height: 100%;
`;

export const TableListContentCell = styled.div.attrs(({ width, align }: {
  width: string,
  align?: 'left' | 'center' | 'right',
}) => ({
  align,
  width,
  'data-class': 'TableListContentCell',
}))`
  ${divFlex}
  width: calc((100% + 32px) * ${({ width }) => (width ? `${width} / 100` : '0.166')});
  align-items: center;
  justify-content: ${({ align }) => align || 'left'};
  gap: 3px;

  color: ${({ theme }) => theme.themesColors.characterBrandingTitle85};
  font-size: 14px;
  font-weight: 400;

  text-transform: capitalize;

  &:first-child, &:last-child {
    width: calc((100% + 32px) * ${({ width }) => (width ? `${width} / 100` : '0.166')} - 16px);
  }
`;

export const TableListContentItemBottom = styled.div.attrs((props: {
  isActive?: boolean,
}) => ({
  isActive: props.isActive,
  'data-class': 'CCContentTableContentItemBottom',
}))`
  ${divFlex}
  flex-direction: column;
  ${({ isActive }) => isActive && 'margin-top: -11px;'};

  .ant-collapse-header {
    display: none !important;
  }
  .ant-collapse-content-box {
    padding: 0;
  }
  .ant-collapse.ant-collapse-icon-position-left,
  .ant-collapse-content.ant-collapse-content-active,
  .ant-collapse-item.ant-collapse-item-active,
  .ant-collapse-item {
    border: none !important;
    background: none;
  }
`;
