import styled from 'styled-components';
import Typography from 'antd/es/typography';

export const Wrap = styled.div`
  padding: 0 24px;
  margin-top: 32px;
  display: flex;
  flex-direction: column;
`;

export const Title = styled(Typography)`
  margin-bottom: 14px;
  font-size: 16px;
  line-height: 18px;
  font-weight: 600;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Text = styled.div`
  margin: 0 1.5px;
  font-size: 12px;
  line-height: 16px;
  text-align: justify;
  display: block;
`;

export const Bold = styled(Text)`
  margin: 0 1.5px;
  font-weight: 600;
`;

export const Link = styled.a`
  margin: 0 1.5px;
  font-size: 12px;
  line-height: 16px;
  text-decoration: underline;
`;

export const Signatures = styled.div`
  margin-top: 80px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const Signature = styled.div`
  width: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const SignatureName = styled(Typography)`
  font-family: Caveat;
  margin-bottom: 4px;
  font-size: 20px;
  line-height: 24px;
  font-weight: 500;
  min-height: 24px;
`;

export const SignatureDivider = styled.div`
  width: 100%;
  height: 1px;
  background-color: rgba(0, 0, 0, 1);
`;

export const SignatureLabel = styled(Typography)`
  margin-top: 4px;
  font-size: 10px;
  line-height: 12px;
`;
