import React, { FC } from 'react';

import { WarningSvg } from 'app-wrapper/view/icons';
import { ENotificationCriticality } from 'app-wrapper/constants';

import {
  DatePriorityTitleHigh, DatePriorityTitleLow, DatePriorityTitleMedium, DatePriorityWrapHigh, DatePriorityWrapLow, DatePriorityWrapMedium,
} from './DatePriority.styled';

interface IDatePriorityProps {
  mode?: ENotificationCriticality;
  date: string;
  isWarning?: boolean;
}

export const DatePriority: FC<IDatePriorityProps> = (props) => {
  const { mode, date, isWarning } = props;

  if (!date) return null;

  const waringComponent = isWarning ? <WarningSvg /> : null;

  switch (mode) {
    case ENotificationCriticality.HIGH:
      return (
        <DatePriorityWrapHigh>
          {waringComponent}
          <DatePriorityTitleHigh>
            {date}
          </DatePriorityTitleHigh>
        </DatePriorityWrapHigh>
      );
    case ENotificationCriticality.MEDIUM:
      return (
        <DatePriorityWrapMedium>
          {waringComponent}
          <DatePriorityTitleMedium>
            {date}
          </DatePriorityTitleMedium>
        </DatePriorityWrapMedium>
      );
    case ENotificationCriticality.LOW:
      return (
        <DatePriorityWrapLow>
          {waringComponent}
          <DatePriorityTitleLow>
            {date}
          </DatePriorityTitleLow>
        </DatePriorityWrapLow>
      );
    default:
      return null;
  }
};
