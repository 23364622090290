import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { SkypaceLogo, SkypaceDarkLogo } from 'app-wrapper/view/icons';
import { SkypaceInformationDTM } from 'app-wrapper/constants';
import { AdminPublicInfoDTM } from 'user-management/models/dtm';

import {
  HeaderBoldValue, HeaderData, HeaderValue, Logo, HeaderWrapper, Separator,
} from './PrintRFQ.styled';

interface HeaderProps {
  light?: boolean
  company?: SkypaceInformationDTM
  relatedAdmin: AdminPublicInfoDTM | null
  isOriginOrDestinationUS: boolean
}

const Header: FC<HeaderProps> = ({
  light,
  company,
  relatedAdmin,
  isOriginOrDestinationUS,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <HeaderWrapper light={light}>
        <Logo>
          {light ? (
            <SkypaceDarkLogo style={{ width: '190px', height: '35px' }} />
          ) : (
            <SkypaceLogo style={{ width: '190px', height: '35px' }} />
          )}
        </Logo>
        <HeaderData>
          <div style={{ textAlign: 'right' }}>
            <HeaderBoldValue>
              {relatedAdmin ? (
                <>
                  {relatedAdmin.getIsAdminFromUS() || !isOriginOrDestinationUS ? company?.companyName : (
                    <>
                      {company?.companyName}
                      &nbsp;
                      {t('as agent of')}
                      <br />
                      {t('Maxton Shipping Inc DBA Skypace')}
                    </>
                  )}
                </>
              ) : company?.companyName}
            </HeaderBoldValue>

            <HeaderValue>
              {company?.companyAddress1}
            </HeaderValue>
            <HeaderValue>
              {company?.companyAddress2}
            </HeaderValue>
          </div>
          <div style={{ marginLeft: '80px', textAlign: 'right' }}>
            <HeaderValue>
              <HeaderBoldValue>{company?.companyDepartment}</HeaderBoldValue>
            </HeaderValue>
            <HeaderValue>
              {company?.companyPhone}
            </HeaderValue>
            <HeaderValue>
              {company?.companyEmail}
            </HeaderValue>
          </div>
        </HeaderData>
      </HeaderWrapper>
      {light && (
        <div style={{ width: '100%', padding: '0 24px' }}>
          <Separator />
        </div>
      )}
    </>
  );
};

export { Header };
