import moment, { Moment } from 'moment';
import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'antd/es/modal';

import { Button } from 'app-wrapper/view/components';
import { DateDtm } from 'app-wrapper/models/dtm';

import { ECarrierSCAC, CarrierSCACNames } from 'monetary/constants';
import { FreightSelectFieldDTM } from 'monetary/models/dtm';

import {
  OceanRateDownloadModalBody,
  OceanRateDownloadModalDatePicker,
  OceanRateDownloadModalFooter,
  OceanRateDownloadModalItem,
  OceanRateDownloadModalSelect,
  OceanRateDownloadModalTitle,
} from './OceanRateDownloadModal.styled';

const timeFormat = 'DD/MM/YYYY';

const carrierSCACOptions = Object.keys(ECarrierSCAC).map((ECarrierSCACItem) => {
  const item = ECarrierSCACItem as ECarrierSCAC;
  return {
    label: CarrierSCACNames[item],
    value: item,
  };
});

const modalWidth = 416;

interface IOceanRateItemComponentProps {
  isOpen: boolean
  isLoading: boolean
  isFormDataFilled: boolean
  carrierSCACList: ECarrierSCAC[]
  isOriginLocationSearchListLoading: boolean
  originLocationSearchList: FreightSelectFieldDTM[]
  isDestinationLocationSearchListLoading: boolean
  destinationLocationSearchList: FreightSelectFieldDTM[]
  effectiveDate?: DateDtm
  expirationDate?: DateDtm
  originLocationValue?: string
  destinationLocationValue?: string
  onEffectiveDateChange: (date: DateDtm | null) => void
  onEffectiveDateBlur: () => void
  onExpirationDateChange: (date: DateDtm | null) => void
  onExpirationDateBlur: () => void
  onOriginLocationChange: (code: string) => void
  onOriginLocationBlur: () => void
  onOriginLocationSearch: (searchValue: string) => void
  onDestinationLocationChange: (code: string) => void
  onDestinationLocationBlur: () => void
  onDestinationLocationSearch: (searchValue: string) => void
  onCarrierSCACChange: (scacList: ECarrierSCAC[]) => void
  onDownload: () => void
  onClose: () => void
}

export const OceanRateDownloadModalComponent: FC<IOceanRateItemComponentProps> = ({
  carrierSCACList,
  destinationLocationSearchList,
  destinationLocationValue,
  effectiveDate,
  expirationDate,
  isDestinationLocationSearchListLoading,
  isFormDataFilled,
  isLoading,
  isOpen,
  isOriginLocationSearchListLoading,
  onCarrierSCACChange,
  onClose,
  onDestinationLocationBlur,
  onDestinationLocationChange,
  onDestinationLocationSearch,
  onDownload,
  onEffectiveDateBlur,
  onEffectiveDateChange,
  onExpirationDateBlur,
  onExpirationDateChange,
  onOriginLocationBlur,
  onOriginLocationChange,
  onOriginLocationSearch,
  originLocationSearchList,
  originLocationValue,
}) => {
  const { t } = useTranslation();

  const onEffectiveDateChangeHandler = useCallback((date: Moment | null) => {
    const newDate = date ? DateDtm.fromPlain({
      date: date?.format(),
      offset: moment().utcOffset(),
    }) : null;

    onEffectiveDateChange(newDate);
  }, []);

  const onEffectiveDateBlurHandler = useCallback(() => {
    onEffectiveDateBlur();
  }, []);

  const onExpirationDateChangeHandler = useCallback((date: Moment | null) => {
    const newDate = date ? DateDtm.fromPlain({
      date: date?.format(),
      offset: moment().utcOffset(),
    }) : null;

    onExpirationDateChange(newDate);
  }, []);

  const onExpirationDateBlurHandler = useCallback(() => {
    onExpirationDateBlur();
  }, []);

  const onOriginLocationChangeHandler = useCallback((code: string) => {
    onOriginLocationChange(code);
  }, []);

  const onOriginLocationBlurHandler = useCallback(() => {
    onOriginLocationBlur();
  }, []);

  const onOriginLocationSearchHandler = useCallback((searchValue: string) => {
    onOriginLocationSearch(searchValue);
  }, []);

  const onDestinationLocationChangeHandler = useCallback((code: string) => {
    onDestinationLocationChange(code);
  }, []);

  const onDestinationLocationBlurHandler = useCallback(() => {
    onDestinationLocationBlur();
  }, []);

  const onDestinationLocationSearchHandler = useCallback((searchValue: string) => {
    onDestinationLocationSearch(searchValue);
  }, []);

  const onCarrierSCACChangeHandler = useCallback((values: string[]) => {
    onCarrierSCACChange(values as ECarrierSCAC[]);
  }, []);

  const onDownloadHandler = useCallback(() => {
    onDownload();
  }, []);

  const onCloseHandler = useCallback(() => {
    onClose();
  }, []);

  return (
    <Modal
      visible={isOpen}
      closable={false}
      width={modalWidth}
      onCancel={onCloseHandler}
      footer={(
        <OceanRateDownloadModalFooter>
          <Button
            type="ghost"
            disabled={isLoading}
            onClick={onCloseHandler}
          >
            {t('Cancel')}
          </Button>
          <Button
            onClick={onDownloadHandler}
            loading={isLoading}
            disabled={!isFormDataFilled}
          >
            {t('Download')}
          </Button>
        </OceanRateDownloadModalFooter>
      )}
    >
      <OceanRateDownloadModalBody>
        <OceanRateDownloadModalTitle>{t('Download Rates')}</OceanRateDownloadModalTitle>
        <OceanRateDownloadModalItem>
          <span>{t('From Date')} *</span>
          <OceanRateDownloadModalDatePicker
            disabled={isLoading}
            value={effectiveDate?.getDateAsMomentWithOffset() || null}
            format={timeFormat}
            // isError={!!effectiveDateError}
            onChange={onEffectiveDateChangeHandler}
            onBlur={onEffectiveDateBlurHandler}
            allowClear={false}
          />
        </OceanRateDownloadModalItem>
        <OceanRateDownloadModalItem>
          <span>{t('To Date')} *</span>
          <OceanRateDownloadModalDatePicker
            disabled={isLoading}
            value={expirationDate?.getDateAsMomentWithOffset() || null}
            format={timeFormat}
            onChange={onExpirationDateChangeHandler}
            onBlur={onExpirationDateBlurHandler}
            allowClear={false}
          />
        </OceanRateDownloadModalItem>
        <OceanRateDownloadModalItem>
          <span>{t('Origin Location UNLOCO')}</span>
          <OceanRateDownloadModalSelect
            showSearch
            loading={isOriginLocationSearchListLoading}
            disabled={isLoading}
            showArrow={false}
            filterOption={false}
            // isError={!!originLocationError}
            options={
              originLocationSearchList.map((item) => ({
                label: item.description || '',
                value: item.code || '',
              }))
            }
            value={originLocationValue}
            onSearch={onOriginLocationSearchHandler}
            onChange={onOriginLocationChangeHandler}
            onBlur={onOriginLocationBlurHandler}
          />
        </OceanRateDownloadModalItem>
        <OceanRateDownloadModalItem>
          <span>{t('Destination Location UNLOCO')}</span>
          <OceanRateDownloadModalSelect
            showSearch
            loading={isDestinationLocationSearchListLoading}
            disabled={isLoading}
            showArrow={false}
            filterOption={false}
            // isError={!!destinationLocationError}
            options={
              destinationLocationSearchList.map((item) => ({
                label: item.description || '',
                value: item.code || '',
              }))
            }
            value={destinationLocationValue}
            onSearch={onDestinationLocationSearchHandler}
            onChange={onDestinationLocationChangeHandler}
            onBlur={onDestinationLocationBlurHandler}
          />
        </OceanRateDownloadModalItem>
        <OceanRateDownloadModalItem>
          <span>{t('Carrier SCAC')}</span>
          <OceanRateDownloadModalSelect
            showSearch
            mode="multiple"
            disabled={isLoading}
            options={carrierSCACOptions}
            showArrow={false}
            filterOption={false}
            value={carrierSCACList}
            onChange={onCarrierSCACChangeHandler}
          />
        </OceanRateDownloadModalItem>
      </OceanRateDownloadModalBody>
    </Modal>
  );
};
