import React from 'react';
import { useSelector } from 'react-redux';

import { UC } from 'shipment-operations/controllers';
import { R } from 'shipment-operations/repository';

import { CustomerBookingConfirmationComponent } from './CustomerBookingConfirmation.component';

const CustomerBookingConfirmationContainer = () => {
  const shipment = useSelector(R.selectors.shipment.getShipment);
  const isWaitingAcceptFromCustomer = useSelector(R.selectors.overview.getIsWaitingAcceptFromCustomer);
  const isThereAnyMissMatches = useSelector(R.selectors.shipmentMissMatches.getIsThereAnyMissMatches);

  return (
    <CustomerBookingConfirmationComponent
      confirmedAt={shipment ? shipment.confirmedAt : null}
      isWaitingAcceptFromCustomer={isWaitingAcceptFromCustomer}
      onAccept={UC.overview.openTermsAgreementDrawer}
      onCancel={UC.overview.openCancelShipmentModal}
      showNraText={Boolean(shipment?.isUsShipmentOriginOrDestination())}
      hasAnyMissMatches={isThereAnyMissMatches}
    />
  );
};

export { CustomerBookingConfirmationContainer as CustomerBookingConfirmation };
