import { billOfLadingActions, billOfLadingReducer, billOfLadingSelectors } from './BillOfLading';
import { billOfLadingCommonActions, billOfLadingCommonReducer, billOfLadingCommonSelectors } from './BillOfLadingCommon';
import { cargoActions, cargoReducer, cargoSelectors } from './Cargo';
import { commodityReducer, commodityActions, commoditySelectors } from './Commodity';
import { createCreditNoteSelector } from './CreateCreditNote';
import { createInvoiceChargesReducer, createInvoiceChargesActions, createInvoiceChargesSelectors } from './CreateInvoiceCharges';
import { creditNoteActions, creditNoteReducers, creditNoteSelectors } from './CreditNote';
import { shipmentBillingInvoiceReducer, shipmentBillingInvoiceActions, shipmentBillingInvoiceSelectors } from './ShipmentBillingInvoice';
import { shipmentBillingReducer, shipmentBillingActions, shipmentBillingSelectors } from './ShipmentBilling';
import { shipmentContainersReducer, shipmentContainersActions, shipmentContainersSelectors } from './ShipmentContainers';
import { shipmentDocumentActions, shipmentDocumentReducer, shipmentDocumentSelectors } from './ShipmentDocument';
import { shipmentDocumentsHazmatActions, shipmentDocumentsHazmatReducer, shipmentDocumentsHazmatSelectors } from './ShipmentDocumentsHazmat';
import { shareInvoiceSelectors, shareInvoiceReducer, shareInvoiceActions } from './ShareInvoice';
import { bookingConfirmationPDFSelectors, bookingConfirmationPDFReducer, bookingConfirmationPDFActions } from './BookingConfirmationPDF';
import {
  shipmentDocumentsAllSelectors,
  shipmentDocumentsAllReducer,
  shipmentDocumentsAllActions,
} from './ShipmentDocumentsAll';
import {
  shipmentDocumentsSeaworthyCertificatesActions,
  shipmentDocumentsSeaworthyCertificatesReducer,
  shipmentDocumentsSeaworthyCertificatesSelectors,
} from './ShipmentDocumentsSeaworthyCertificates';
import { shipmentListReducer, shipmentListActions, shipmentListSelectors } from './ShipmentList';
import { shipmentNewContainerReducer, shipmentNewContainerActions, shipmentNewContainerSelectors } from './ShipmentNewContainer';
import { shipmentReducer, shipmentActions, shipmentSelectors } from './Shipment';
import { shipmentDrawerReducer, shipmentDrawerActions, shipmentDrawerSelectors } from './ShipmentDrawer';
import { shipmentMissMatchesSelectors, shipmentMissMatchesReducer, shipmentMissMatchesActions } from './ShipmentMissMatches';
import {
  shipmentTransportationLocationReducer,
  shipmentTransportationLocationActions,
  shipmentTransportationLocationSelectors,
} from './ShipmentTransportationLocation';
import {
  shipmentTransportationLocationDataReducer,
  shipmentTransportationLocationDataActions,
  shipmentTransportationLocationDataSelectors,
} from './ShipmentTransportationLocationData';
import { shippingPartiesActions, shippingPartiesReducer, shippingPartiesSelectors } from './ShippingParties';
import { temperatureControlReducer, temperatureControlActions, temperatureControlSelectors } from './TemperatureControl';
import { transportationOverviewReducer, transportationOverviewActions, transportationOverviewSelectors } from './TransportationOverview';
import { shipmentTransportationChargesReducer, shipmentTransportationChargesActions, shipmentTransportationChargesSelectors } from './ShipmentTransportationCharges';
import { shipmentTrackerReducer, shipmentTrackerActions, shipmentTrackerSelectors } from './ShipmentTracker';
import { overviewReducer, overviewActions, overviewSelectors } from './Overview';
import { exportClearanceReducer, exportClearanceActions, exportClearanceSelectors } from './ExportClearanace';
import { shipmentTrackerRoutesReducer, shipmentTrackerRoutesActions, shipmentTrackerRoutesSelectors } from './ShipmentTrackerRoutes';
import { makePaymentReducers, makePaymentActions, makePaymentSelectors } from './MakePayment';
import { shipmentChargesReducer, shipmentChargesActions, shipmentChargesSelectors } from './ShipmentCharges';
import { bookingWizardSelectors, bookingWizardReducer, bookingWizardActions } from './BookingWizard';
import { shipmentAccessorialActions, shipmentAccessorialSelectors, shipmentAccessorialReducer } from './ShipmentAccessorial';
import { shipmentFreeTimeActions, shipmentFreeTimeSelectors, shipmentFreeTimeReducer } from './ShipmentFreeTime';
import { manualBookingWizardActions, manualBookingWizardReducer, manualBookingWizardSelectors } from './ManualBookingWizard';
import { rollShipmentActions, rollShipmentReducer, rollShipmentWizardSelectors } from './RollShipmentWizard';
import {
  documentHBLPDFActions,
  documentHBLPDFReducer,
  documentHBLSelectors,
} from './HBLDocumentPDF';
import { shipmentActiveChargeActions, shipmentActiveChargeReducer, shipmentActiveChargeSelectors } from './ShipmentActiveCharge';
import { shipmentChangesActions, shipmentChangesReducer, shipmentChangesSelectors } from './ShipmentChanges';
import {
  shipmentPeopleReducer,
  shipmentPeopleSelectors,
  shipmentPeopleActions,
} from './ShipmentPeople';
import {
  addRatesSelectors, addRatesActions, addRatesReducer,
} from './AddRates';

export const actions = {
  overview: overviewActions,
  billOfLading: billOfLadingActions,
  billOfLadingCommon: billOfLadingCommonActions,
  exportClearance: exportClearanceActions,
  cargo: cargoActions,
  commodity: commodityActions,
  createInvoiceCharges: createInvoiceChargesActions,
  creditNote: creditNoteActions,
  shipment: shipmentActions,
  shipmentBilling: shipmentBillingActions,
  shipmentBillingInvoice: shipmentBillingInvoiceActions,
  shipmentContainers: shipmentContainersActions,
  shipmentDocument: shipmentDocumentActions,
  shipmentDocumentsAll: shipmentDocumentsAllActions,
  shipmentDocumentsHazmat: shipmentDocumentsHazmatActions,
  shipmentDocumentsSeaworthyCertificates: shipmentDocumentsSeaworthyCertificatesActions,
  shipmentDrawer: shipmentDrawerActions,
  shipmentList: shipmentListActions,
  shipmentNewContainer: shipmentNewContainerActions,
  shipmentTransportationCharges: shipmentTransportationChargesActions,
  shipmentTransportationLocation: shipmentTransportationLocationActions,
  shipmentTransportationLocationData: shipmentTransportationLocationDataActions,
  shippingParties: shippingPartiesActions,
  temperatureControl: temperatureControlActions,
  transportationOverview: transportationOverviewActions,
  shipmentTracker: shipmentTrackerActions,
  shipmentTrackerRoutes: shipmentTrackerRoutesActions,
  shipmentMissMatches: shipmentMissMatchesActions,
  makePayment: makePaymentActions,
  shipmentCharges: shipmentChargesActions,
  bookingWizard: bookingWizardActions,
  shipmentAccessorial: shipmentAccessorialActions,
  shipmentFreeTime: shipmentFreeTimeActions,
  manualBookingWizard: manualBookingWizardActions,
  rollShipmentWizard: rollShipmentActions,
  shareInvoice: shareInvoiceActions,
  bookingConfirmationPDF: bookingConfirmationPDFActions,
  documentHBLPDF: documentHBLPDFActions,
  shipmentActiveCharge: shipmentActiveChargeActions,
  shipmentChanges: shipmentChangesActions,
  shipmentPeople: shipmentPeopleActions,
  addRates: addRatesActions,
};

export const reducers = {
  overview: overviewReducer,
  billOfLading: billOfLadingReducer,
  billOfLadingCommon: billOfLadingCommonReducer,
  exportClearance: exportClearanceReducer,
  cargo: cargoReducer,
  commodity: commodityReducer,
  createInvoiceCharges: createInvoiceChargesReducer,
  creditNote: creditNoteReducers,
  shipment: shipmentReducer,
  shipmentBilling: shipmentBillingReducer,
  shipmentBillingInvoice: shipmentBillingInvoiceReducer,
  shipmentContainers: shipmentContainersReducer,
  shipmentDocument: shipmentDocumentReducer,
  shipmentDocumentsAll: shipmentDocumentsAllReducer,
  shipmentDocumentsHazmat: shipmentDocumentsHazmatReducer,
  shipmentDrawer: shipmentDrawerReducer,
  shipmentList: shipmentListReducer,
  shipmentNewContainer: shipmentNewContainerReducer,
  shipmentTransportationCharges: shipmentTransportationChargesReducer,
  shipmentTransportationLocation: shipmentTransportationLocationReducer,
  shipmentTransportationLocationData: shipmentTransportationLocationDataReducer,
  shippingParties: shippingPartiesReducer,
  temperatureControl: temperatureControlReducer,
  transportationOverview: transportationOverviewReducer,
  shipmentTracker: shipmentTrackerReducer,
  shipmentTrackerRoutes: shipmentTrackerRoutesReducer,
  makePayment: makePaymentReducers,
  shipmentMissMatches: shipmentMissMatchesReducer,
  shipmentCharges: shipmentChargesReducer,
  bookingWizard: bookingWizardReducer,
  shipmentAccessorial: shipmentAccessorialReducer,
  shipmentFreeTime: shipmentFreeTimeReducer,
  manualBookingWizard: manualBookingWizardReducer,
  rollShipmentWizard: rollShipmentReducer,
  shipmentDocumentsSeaworthyCertificates: shipmentDocumentsSeaworthyCertificatesReducer,
  shareInvoice: shareInvoiceReducer,
  bookingConfirmationPDF: bookingConfirmationPDFReducer,
  documentHBLPDF: documentHBLPDFReducer,
  shipmentActiveCharge: shipmentActiveChargeReducer,
  shipmentChanges: shipmentChangesReducer,
  shipmentPeople: shipmentPeopleReducer,
  addRates: addRatesReducer,
};

export const selectors = {
  overview: overviewSelectors,
  billOfLading: billOfLadingSelectors,
  billOfLadingCommon: billOfLadingCommonSelectors,
  exportClearance: exportClearanceSelectors,
  cargo: cargoSelectors,
  commodity: commoditySelectors,
  createCreditNote: createCreditNoteSelector,
  createInvoiceCharges: createInvoiceChargesSelectors,
  creditNote: creditNoteSelectors,
  shipment: shipmentSelectors,
  shipmentBilling: shipmentBillingSelectors,
  shipmentBillingInvoice: shipmentBillingInvoiceSelectors,
  shipmentContainers: shipmentContainersSelectors,
  shipmentDocuments: shipmentDocumentSelectors,
  shipmentDocumentsAll: shipmentDocumentsAllSelectors,
  shipmentDocumentsHazmat: shipmentDocumentsHazmatSelectors,
  shipmentDrawer: shipmentDrawerSelectors,
  shipmentList: shipmentListSelectors,
  shipmentNewContainer: shipmentNewContainerSelectors,
  shipmentTransportationCharges: shipmentTransportationChargesSelectors,
  shipmentTransportationLocation: shipmentTransportationLocationSelectors,
  shipmentTransportationLocationData: shipmentTransportationLocationDataSelectors,
  shippingParties: shippingPartiesSelectors,
  temperatureControl: temperatureControlSelectors,
  transportationOverview: transportationOverviewSelectors,
  shipmentTracker: shipmentTrackerSelectors,
  shipmentTrackerRoutes: shipmentTrackerRoutesSelectors,
  makePayment: makePaymentSelectors,
  shipmentMissMatches: shipmentMissMatchesSelectors,
  shipmentCharges: shipmentChargesSelectors,
  bookingWizard: bookingWizardSelectors,
  shipmentAccessorial: shipmentAccessorialSelectors,
  shipmentFreeTime: shipmentFreeTimeSelectors,
  manualBookingWizard: manualBookingWizardSelectors,
  rollShipmentWizard: rollShipmentWizardSelectors,
  shipmentDocumentsSeaworthyCertificates: shipmentDocumentsSeaworthyCertificatesSelectors,
  shareInvoice: shareInvoiceSelectors,
  bookingConfirmationPDF: bookingConfirmationPDFSelectors,
  documentHBLPDF: documentHBLSelectors,
  shipmentActiveCharge: shipmentActiveChargeSelectors,
  shipmentChanges: shipmentChangesSelectors,
  shipmentPeople: shipmentPeopleSelectors,
  addRates: addRatesSelectors,
};
