import React, { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { useAuthToken } from 'app-wrapper/hooks/useAuthToken';

import { R } from 'shipment-operations/repository';
import { UC } from 'shipment-operations/controllers';

import { SOCDocumentUploadComponent } from './SOCDocumentUpload.component';

interface ISOCDocumentUploadProps {
  containerId: string;
}

export const SOCDocumentUpload: FC<ISOCDocumentUploadProps> = ({ containerId }) => {
  const { seaworthyCertificate } = useSelector(R.selectors.bookingWizard.getContainerById(containerId));
  const { certificate } = useSelector(R.selectors.bookingWizard.getContainerErrorsById(containerId));
  const shipmentId = useSelector(R.selectors.bookingWizard.getShipmentId);
  const authToken = useAuthToken();

  const documents = useMemo(() => (seaworthyCertificate === null ? [] : [seaworthyCertificate]), [seaworthyCertificate]);

  return (
    <SOCDocumentUploadComponent
      error={!!certificate}
      authToken={authToken}
      containerDocument={documents}
      setContainerDocument={UC.bookingWizard.setContainerDocument}
      touchContainerField={() => null}
      containerId={containerId}
      downloadDocument={UC.bookingWizard.downloadContainerDocument}
      shipmentId={shipmentId}
    />
  );
};
