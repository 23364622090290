import styled from 'styled-components';

import {
  FlexCol,
  FlexRow,
  FlexCenter,
  BodyTextMedium,
  BodyTextRegular,
} from 'app-wrapper/view/guideline';

export const MemberMainInfo = styled(FlexRow)`
  width: 25%;
`;

export const Member = styled.div.attrs({
  'data-class': 'team-member-card',
})`
  padding: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-radius: 3px;
  background-color: ${({ theme }) => theme.themesColors.neutralBranding1};
  border: 1px solid ${({ theme }) => theme.themesColors.neutralBranding4};
  width: 100%;
  min-height: 76px;
  height: fit-content;
`;

export const NameLetter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.themesColors.primaryBranding1};
  color: ${({ theme }) => theme.themesColors.primaryBranding5};
  font-size: 14px;
  line-height: 22px;
`;

export const Info = styled(FlexCol)`
  margin-left: 16px;
`;

export const InfoFullName = styled.span`
  ${BodyTextRegular}
`;

export const RoleTitle = styled.span`
  ${BodyTextMedium}
`;

export const InfoJobTitle = styled.span`
  font-size: 12px;
  line-height: 20px;
  color: ${({ theme }) => theme.themesColors.secondaryDot45};
`;

export const Item = styled(FlexRow)`
  width: 25%;
`;

export const ItemIconWrap = styled(FlexCenter)`
  margin-right: 16px;
  height: 32px;
  width: 32px;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.themesColors.primaryBranding1};
  
  svg {
    color: ${({ theme }) => theme.themesColors.primaryBranding7};
  }
`;

export const ItemText = styled.span`
  font-size: 12px;
  line-height: 20px;
  color: ${({ theme }) => theme.themesColors.secondaryDot45};
`;
