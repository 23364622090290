import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'app-wrapper/createSlice';

import { TPasswordResetEmailSendState } from 'authentication/models/states';

export const initialState: TPasswordResetEmailSendState = {
  isLoading: false,
};

export const passwordResetEmailSendStore = createSlice({
  name: 'passwordResetEmailSend',
  initialState,
  reducers: {
    setEmail: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      email: payload,
    }),
    setEmailError: (state, { payload }: PayloadAction<Error>) => ({
      ...state,
      emailError: payload,
    }),
    clearEmailError: (state) => ({
      ...state,
      emailError: undefined,
    }),
    setLoading: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      isLoading: payload,
    }),
  },
});

export const passwordResetEmailSendActions = passwordResetEmailSendStore.actions;
export const passwordResetEmailSendReducer = passwordResetEmailSendStore.reducer;
