import styled from 'styled-components';

export const Members = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  font-size: 16px;
  color: ${({ theme }) => theme.themesColors.characterSecondaryDot45};
`;

export const Text = styled.div`
  font-size: 14px;
  line-height: 22px;
  color: initial;
`;
