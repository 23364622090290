import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { InputStyled } from './ShippingNameInput.styled';

interface ShippingNameInputComponentProps {
  shippingName: string;
  error: boolean
}

export const ShippingNameInputComponent: FC<ShippingNameInputComponentProps> = ({
  shippingName,
  error,
}) => {
  const { t } = useTranslation();

  return (
    <InputStyled
      placeholder={t('Enter Shipping Name')}
      value={shippingName}
      error={error}
      disabled
    />
  );
};
