import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { FlexRow } from 'app-wrapper/view/guideline';

import {
  Footer,
  InfoIcon,
  Text,
} from 'shipment-operations/view/pages/ShipmentBillOfLading/components/MBLFlowManagement/components/FooterInformation/FooterInformation.styled';

interface IFooterInformationProps {
  isCleared?: boolean;
}

export const FooterInformation: FC<IFooterInformationProps> = ({ isCleared }) => {
  const { t } = useTranslation();

  return (
    <Footer>
      {isCleared ? (
        <FlexRow>
          <Text>
            {t('Your HBL has been cleared and can be downloaded')}
          </Text>

          <InfoIcon />
        </FlexRow>
      ) : null}
    </Footer>
  );
};
