import { Button as BaseButton } from 'app-wrapper/view/components';
import styled from 'styled-components';
import AntSpin from 'antd/es/spin';

import { FlexRow, TitleText } from 'app-wrapper/view/guideline';

import { BaseContent } from 'shipment-operations/view/pages/ShipmentBillOfLading/components/MBLFlowManagement/MBLFlowManagement.styled';

export const Row = styled(FlexRow)`
  gap: 4px;
`;

export const InfoBlock = styled(BaseContent)`
  width: 100%;
  justify-content: space-between;
`;

export const InfoBlockItem = styled.div`
  width: 30%;
`;

export const DownloadBlock = styled(BaseContent)`
  width: fit-content;
`;

export const Button = styled(BaseButton)`
  min-width: 105px;
  ${TitleText}
  font-weight: 400;
  height: 40px;
`;

export const Spin = styled(AntSpin)`
  i {
    background-color: white;
  }
`;
