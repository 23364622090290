import {
  IsEnum,
  IsOptional,
} from 'class-validator';
import { EPermissionAttributePolicy } from 'monetary/constants';
import { BaseDTM } from 'proto/BaseDTM';

export type TRFQNewQuotaAvailabilityDTM = {
  quotaItemContractName?: EPermissionAttributePolicy
  rfqFormClientInformation?: EPermissionAttributePolicy
  newQuotaClientAdmin?: EPermissionAttributePolicy
  selfService?: EPermissionAttributePolicy
}

export class RFQNewQuotaAvailabilityDTM extends BaseDTM<TRFQNewQuotaAvailabilityDTM> {
  @IsOptional()
  @IsEnum(EPermissionAttributePolicy)
  quotaItemContractName: EPermissionAttributePolicy

  @IsOptional()
  @IsEnum(EPermissionAttributePolicy)
  rfqFormClientInformation: EPermissionAttributePolicy

  @IsOptional()
  @IsEnum(EPermissionAttributePolicy)
  newQuotaClientAdmin: EPermissionAttributePolicy

  @IsOptional()
  @IsEnum(EPermissionAttributePolicy)
  selfService: EPermissionAttributePolicy
}
