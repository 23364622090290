import styled from 'styled-components';

import { divFlex } from 'app-wrapper/view/themes/mixin';
import Table from 'antd/es/table';

export const Layout = styled.div.attrs({
  'data-class': 'Layout',
})`
  height: 100%;
  padding: 16px;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme?.themesColors?.neutralBranding1};
  border-radius: 3px;
`;

export const Title = styled.div`
  width: 100%;
  padding-bottom: 14px;
  font-size: 16px;
  font-weight: 500;
  border-bottom: 1px solid  ${({ theme }) => theme.themesColors.neutralBranding3};
`;

export const LoaderWrapper = styled.div.attrs({
  'data-class': 'LoaderWrapper',
})`
  ${divFlex}
  height: 100%;
  align-items: center;
  justify-content: center;
`;

export const Content = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;
`;

export const TableStyled = styled(Table)`
  height: 100%;

  table {
    padding-bottom: 140px;
  }

  .ant-table-thead > tr > th {
    background: white;
  }

  .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    display: none;
  }

  .ant-table-thead .ant-table-cell {
    color: #73819B;
    font-size: 12px;
    font-weight: 400;
  }
`;
