import message from 'antd/es/message';
import { BaseController, controller } from 'proto/BaseController';

import i18n from 'app-wrapper/i18n/i18n';
import { RouteNames } from 'app-wrapper/constants';

import { R } from 'authentication/repository';

@controller
export class PasswordResetNewPasswordController extends BaseController {
  public sendPassword = async () => {
    this.validateCode();
    this.validateNewPassword();
    this.validateConfirmNewPassword();

    const email = R.selectors.passwordResetNewPassword.getEmail(this.store.getState());
    const code = R.selectors.passwordResetNewPassword.getCode(this.store.getState());
    const newPassword = R.selectors.passwordResetNewPassword.getNewPassword(this.store.getState());
    const confirmNewPassword = R.selectors.passwordResetNewPassword.getConfirmNewPassword(this.store.getState());

    // we should have email here, for now we can get it only from previous screen form success (PasswordResetEmailSend screen)
    if (!email || !code || !newPassword || !confirmNewPassword) {
      return;
    }

    this.dispatch(R.actions.passwordResetNewPassword.setLoading(true));

    try {
      await R.services.auth.passwordResetNewPassword(email, code, newPassword);
    } catch (e) {
      if (e instanceof Error) {
        message.error(e.message);

        this.dispatch(R.actions.passwordResetNewPassword.setLoading(false));

        return;
      }

      // for other cases, when we have e as string or other
      this.dispatch(R.actions.passwordResetNewPassword.setLoading(false));

      return;
    }

    message.info(i18n.t('Password changed successfully'));

    this.dispatch(R.actions.passwordResetNewPassword.setLoading(false));

    this.navigate(RouteNames.SIGN_IN());
  }

  public setCode = (value: string) => {
    this.dispatch(R.actions.passwordResetNewPassword.setCode(value));
  }

  public onBlurCode = () => {
    this.validateCode();
  }

  public setNewPassword = (value: string) => {
    this.dispatch(R.actions.passwordResetNewPassword.setNewPassword(value));
  }

  public onBlurNewPassword = () => {
    this.validateNewPassword();
  }

  public setConfirmNewPassword = (value: string) => {
    this.dispatch(R.actions.passwordResetNewPassword.setConfirmNewPassword(value));
  }

  public onBlurConfirmNewPassword = () => {
    this.validateConfirmNewPassword();
  }

  public onClear = () => {
    this.dispatch(R.actions.passwordResetNewPassword.clear());
  }

  private validateCode = () => {
    const code = R.selectors.passwordResetNewPassword.getCode(this.store.getState());

    this.dispatch(R.actions.passwordResetNewPassword.clearCodeError());

    if (!code) {
      this.dispatch(R.actions.passwordResetNewPassword.setCodeError(new Error(i18n.t('basicErrors.REQUIRED_MESSAGE'))));
    }
  }

  private validateNewPassword = () => {
    const newPassword = R.selectors.passwordResetNewPassword.getNewPassword(this.store.getState());

    this.dispatch(R.actions.passwordResetNewPassword.clearNewPasswordError());

    if (!newPassword) {
      this.dispatch(R.actions.passwordResetNewPassword.setNewPasswordError(new Error(i18n.t('basicErrors.REQUIRED_MESSAGE'))));

      return;
    }

    if (newPassword.length < 12) {
      this.dispatch(R.actions.passwordResetNewPassword.setNewPasswordError(new Error(i18n.t('basicErrors.MIN_LENGTH', { count: 12 }))));
    }
  }

  private validateConfirmNewPassword = () => {
    const newPassword = R.selectors.passwordResetNewPassword.getNewPassword(this.store.getState());
    const confirmNewPassword = R.selectors.passwordResetNewPassword.getConfirmNewPassword(this.store.getState());

    this.dispatch(R.actions.passwordResetNewPassword.clearConfirmNewPasswordError());

    if (!confirmNewPassword) {
      this.dispatch(R.actions.passwordResetNewPassword.setConfirmNewPasswordError(new Error(i18n.t('basicErrors.REQUIRED_MESSAGE'))));

      return;
    }

    if (newPassword !== confirmNewPassword) {
      this.dispatch(R.actions.passwordResetNewPassword.setConfirmNewPasswordError(new Error(i18n.t('New password and confirm password should be equal'))));
    }
  }
}
