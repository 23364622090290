import React from 'react';
import { observer } from 'mobx-react-lite';

import { useMobxStore } from 'app-wrapper/mobxStores';
import { UC } from 'app-wrapper/controllers';

import { ShipmentCostChangesDrawerComponent } from './ShipmentCostChangesDrawer.component';

const ShipmentCostChangesDrawerContainer = observer(({ taskId }: { taskId: string }) => {
  const mobxStore = useMobxStore();
  const { isLoading, shipment, costChangesMetadata } = mobxStore.shipmentCostChangesDrawer.state;

  return (
    <ShipmentCostChangesDrawerComponent
      isLoading={isLoading}
      onClose={UC.shipmentCostChangesDrawer.closeDrawer}
      onAccept={UC.shipmentCostChangesDrawer.acceptShipmentCostChanges}
      shipment={shipment}
      init={UC.shipmentCostChangesDrawer.init}
      metadata={costChangesMetadata}
      taskId={taskId}
    />
  );
});

export { ShipmentCostChangesDrawerContainer as ShipmentCostChangesDrawer };
