import React, { FC, useCallback } from 'react';

import { Input } from 'app-wrapper/view/components';
import { MAX_LENGTH_20 } from 'app-wrapper/constants';

interface IShippingPartiesPhoneInputComponentProps {
  phone: string;
  setPhone: (phone: string) => void;
  error?: boolean;
}

export const PhoneInputComponent: FC<IShippingPartiesPhoneInputComponentProps> = ({
  phone,
  setPhone,
  error,
}) => {
  const handleChange = useCallback((e) => {
    setPhone(e.target.value);
  }, [setPhone]);

  return (
    <Input
      value={phone}
      onChange={handleChange}
      maxLength={MAX_LENGTH_20}
      error={error && !phone.length}
    />
  );
};
